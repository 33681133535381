import React, { useState, useEffect, useContext, useRef } from "react";
import BudgetService from "../../../services/api/budget";
import notify from "devextreme/ui/notify";
import _ from 'lodash'
import DataGrid, {
    Column,
    Button as GridButton,
    Grouping,
    Lookup,
    HeaderFilter,
    Summary,
    Selection,
    Toolbar,
    ColumnChooser,
    GroupItem,
    FilterPanel,
    Export, ColumnFixing, Scrolling, TotalItem, Paging, Editing
} from "devextreme-react/data-grid";
import RefService from "../../../services/api/refs";
import Button from "devextreme-react/button";
import { UserContext } from "../../../hooks/UserContext";
import { formatDate, numberWithCommas } from "../../../util";
import LoadPanelComponent from "../../budget/components/loadPanel";
import { Item } from "devextreme-react/tab-panel";
import { saveAs } from "file-saver-es";
import { BsFiletypeXls } from "react-icons/bs";
import { CgArrowsExpandRight, CgCompressRight, CgMaximize, CgMinimize } from "react-icons/cg";
import { Workbook } from "exceljs";
import { exportDataGrid } from "devextreme/excel_exporter";
import moment from "moment";
import EmptyData from "../components/empty";

export default function DataGridEza({ year, tabIndex, setDiff, diff, setDiffPerc, setPrevDiff, setPrevDiffPerc, setPrevBudget, setBudget, setForecastBudget }) {
    const [loadMe, setLoadMe] = useState(false);
    const { user } = useContext(UserContext);
    const [econCategory, setEconCategory] = useState([]);
    const [autoExpandAll, setAutoExpand] = useState(true);
    const [dataEcon2, setDataEcon2] = useState([]);
    const dataExpenseGridRef = useRef(null);
    const [fullscreen, setFullscreen] = useState(false)
    const [amount, setAmount] = useState({});
    const [percent, setPercent] = useState({})
    const [editedEcon, setEditedEcon] = useState({});
    const [editedEcon2, setEditedEcon2] = useState({});
    const [prevPerp, setPrevPerp] = useState(0)

    const loadAllBudget = async () => {
        try {
            setLoadMe(true);
            let r = await BudgetService.getBudgetTezEza(year, tabIndex, null, null);
            setDataEcon2(r.data.r1)
            let sumBatlagdsan = 0;
            let sumPrevBudget = 0;
            
            for (let i = 0; i < r.data.r1.length; i++) {
                sumBatlagdsan += parseFloat(r.data.r1[i].batlagdsan);
                sumPrevBudget += parseFloat(r.data.r1[i].prev_budget);
            }
            setBudget(sumBatlagdsan)
            setPrevBudget(sumPrevBudget)
            const groupedData = _.groupBy(r.data.r1, 'parent');
            const summedData = _.mapValues(groupedData, (group) => {
                return _.sumBy(group, item=>Number(item.batlagdsan_edit));
            });
            const cleanedData = _.mapKeys(summedData, (value, key) => key.trim());
            const sum = _.sum(_.values(cleanedData));
            setPrevPerp(sum)
            let result = await RefService.getRefEconomicCategory();
            setEconCategory(result.data);
            setLoadMe(false);
        } catch (e) {
            setLoadMe(false);
            notify(e.message, "error", 2000);
        }
    }
    useEffect(() => {
        loadAllBudget();
        setDiff(0)
        setDiffPerc(0)
        setPrevDiff(0)
        setPrevDiffPerc(0)
    }, [year, tabIndex])


    const onExporting = (e) => {
        const totalColumns = e.component.getVisibleColumns().length;
        const targetColumnIndices = [totalColumns - 5, totalColumns - 4, totalColumns-3];
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Main sheet');
        worksheet.columns = [
            { width: 10 }, { width: 100 }, { width: 30 }, { width: 30 }, { width: 15 }, { width: 15 }
        ];
        exportDataGrid({
            component: e.component,
            worksheet,
            autoFilterEnabled: true,
            keepColumnWidths: false,
            topLeftCell: { row: 6, column: 2 },
            customizeCell: ({ gridCell, excelCell }) => {
                if (gridCell.rowType === 'header') {
                    excelCell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'EEF0F4' } };
                    excelCell.font = { color: { argb: '15186A' }, name: 'Segoe UI', bold: true, size: 8 };
                    excelCell.alignment = {horizontal: 'center', vertical: 'middle'}
                }
                if (gridCell.rowType === 'group') {
                    excelCell.font = { color: { argb: 'F93A3A' }, name: 'Segoe UI', bold: true, size: 8 };
                    if (gridCell.groupIndex === 0) {
                        excelCell.font = { color: { argb: 'F93A3A' }, name: 'Segoe UI', bold: true, size: 8 };
                        if (excelCell._column._number !== 2) excelCell.alignment = { horizontal: 'right' };
                    }
                    if (gridCell.groupIndex === 1) {
                        excelCell.font = { color: { argb: '2C77FF' }, bold: true, name: 'Segoe UI', size: 8 };
                        if (excelCell._column._number !== 2) excelCell.alignment = { horizontal: 'right' };
                    }
                    else if (gridCell.groupIndex > 1) {
                        excelCell.alignment = { wrapText: false };
                        if (excelCell._column._number !== 2) excelCell.alignment = { horizontal: 'right' };
                        excelCell.font = { bold: true, name: 'Segoe UI', size: 8 };
                    }

                }
                if (gridCell.rowType === 'totalFooter' && excelCell.value) {
                    excelCell.font = { name: 'Segoe UI', size: 8, bold: true, italic: true };
                    excelCell.alignment = { vertical: 'bottom', horizontal: 'right' }
                    if (excelCell._column && excelCell._column._number === targetColumnIndices[0] - 1) {
                        excelCell.font = { name: 'Segoe UI', bold: true, italic: true, size: 8, color: { argb: diff < 0 ? '50c687' : 'f66160' } }
                        excelCell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: diff < 0 ? 'E2FBEE' : 'FFF2F2' } }
                        excelCell.border = {
                            top: { style: 'thin', color: { argb: diff < 0 ? '50c687' : 'f66160' } },
                            left: { style: 'thin', color: { argb: diff < 0 ? '50c687' : 'f66160' } },
                            bottom: { style: 'thin', color: { argb: diff < 0 ? '50c687' : 'f66160' } },
                            right: { style: 'thin', color: { argb: diff < 0 ? '50c687' : 'f66160' } }
                        }
                    }
                }
                if (gridCell.rowType === 'data') {
                    excelCell.alignment = { wrapText: false };
                    excelCell.font = { name: 'Segoe UI', size: 8 };
                    if (gridCell.column.dataField !== 'econ_category_id') {
                        excelCell.numFmt = '#,##0.00';
                        excelCell.alignment = {horizontal: 'right'}
                    }
                    if (excelCell._column && (excelCell._column._number === targetColumnIndices[1] || excelCell._column._number === targetColumnIndices[2])) {
                        excelCell.alignment = { horizontal: 'right' }
                        if (gridCell.value > 0) {
                            excelCell.font = { name: 'Segoe UI', size: 8, color: { argb: 'f66160' } }
                        } else if (gridCell.value < 0) {
                            excelCell.font = { name: 'Segoe UI', size: 8, color: { argb: '50c687' } }
                        }
                    }
                    if (excelCell._column && excelCell._column._number === targetColumnIndices[0]) {
                        const batlagdsan = parseFloat(gridCell.data.batlagdsan)
                        const batlagdsan_edit = parseFloat(gridCell.data.batlagdsan_edit)
                        if (batlagdsan < batlagdsan_edit) {
                            excelCell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFF2F2' } }
                            excelCell.font = { name: 'Segoe UI', size: 8, color: { argb: 'F66160' } }
                            excelCell.border = {
                                top: { style: 'thin', color: { argb: 'F66160' } },
                                left: { style: 'thin', color: { argb: 'F66160' } },
                                bottom: { style: 'thin', color: { argb: 'F66160' } },
                                right: { style: 'thin', color: { argb: 'F66160' } }
                            }
                        } else if (batlagdsan > batlagdsan_edit) {
                            excelCell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'E2FBEE' } }
                            excelCell.font = { name: 'Segoe UI', size: 8, color: { argb: '50C687' } }
                            excelCell.border = {
                                top: { style: 'thin', color: { argb: '50C687' } },
                                left: { style: 'thin', color: { argb: '50C687' } },
                                bottom: { style: 'thin', color: { argb: '50C687' } },
                                right: { style: 'thin', color: { argb: '50C687' } }
                            }
                        }
                    }
                }
                if (['econ_category_code_3', 'econ_category_code_4', 'econ_category_code_2', 'econ_category_code_1', 'econ_category_id'].includes(gridCell.column.dataField)) {
                    if (excelCell._row && excelCell._row._cells && excelCell._row._cells[1].model && excelCell._row._cells[1].model.value) {
                        excelCell._row._cells[1].model.value = excelCell._row._cells[1].model.value.replace(": ", "");
                    }

                }
                if (gridCell.column.dataField === 'econ_category_code_3' || gridCell.column.dataField === 'econ_category_code_4') {
                    if (excelCell._row && excelCell._row._cells && excelCell._row._cells[1].model && excelCell._row._cells[1].model.value === '') {
                        excelCell._row.hidden = true;
                    }
                }

                if (gridCell.rowType === 'group' && gridCell.groupIndex >= 1 && gridCell.groupIndex <= 3) {
                    if (excelCell._column._number !== 2) {
                        excelCell.alignment = { horizontal: 'right', wrapText: false };
                        excelCell._row._cells[1].model.value = ' '.repeat(gridCell.groupIndex) + excelCell._row._cells[1].model.value;
                    }
                }
                if (gridCell.rowType === 'data') {
                    excelCell._row._cells[1].model.value = `    ${excelCell._row._cells[1].model.value}`
                }

            },
        }).then(() => {
            Object.assign(worksheet.getRow(1).getCell(1), {
                value: 'Гарчиг:',
                font: { name: 'Arial', size: 8 },
            })
            Object.assign(worksheet.getRow(1).getCell(2), {
                value: `${year} оны төсвийн таамаглал (Эдийн засгийн ангиллаар)`,
                font: { name: 'Arial', size: 8, 'bold': true, color: { argb: '0070c0' } },
            })
            Object.assign(worksheet.getRow(2).getCell(1), {
                value: 'Байгууллага:',
                font: { name: 'Arial', size: 8 },
            })
            Object.assign(worksheet.getRow(2).getCell(2), {
                value: user.organization.name,
                font: { name: 'Arial', size: 8 },
            })
            Object.assign(worksheet.getRow(3).getCell(1), {
                value: 'Хэрэглэгч:',
                font: { name: 'Arial', size: 8 },
            })
            Object.assign(worksheet.getRow(3).getCell(2), {
                value: `${user.last_name.slice(0, 1)}. ${user.first_name}, ${user.position_name}`,
                font: { name: 'Arial', size: 8 },
            })
            Object.assign(worksheet.getRow(4).getCell(1), {
                value: 'Огноо:',
                font: { name: 'Arial', size: 8 },
            })
            Object.assign(worksheet.getRow(4).getCell(2), {
                value: new moment().locale('mn').format('lll'),
                font: { name: 'Arial', size: 8 },
            })
        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer], { type: 'application/octet-stream' }), "OG-" + user.organization.name + "-" + formatDate(new Date()) + ".xlsx");
            });
        });
        e.cancel = true;
    }
    const onCellPreparedHandler = (e) => {
        if (e.rowType === 'header') {
            e.cellElement.wordWrapEnabled = true;
            e.cellElement.setAttribute('style', `text-align: center; border-color: #d4dcec; font-size: 12px; font-family: 'Segoe UI'; background-color: #EEF0F4; color: #15186A; font-weight: bold; vertical-align: middle`)
        }
        else if (e.rowType === 'group') {
            e.cellElement.style.fontFamily = "Segoe UI"
            //e.cellElement.wordWrapEnabled = true;
            e.cellElement.style.color = (e.row.groupIndex === 0 ? "#F93A3A" : ((e.row.groupIndex && e.row.groupIndex === 1) ? "#2C77FF" : 'black'))
            e.cellElement.style.backgroundColor = "#fff"
            e.cellElement.style.fontWeight = "bold";
            e.cellElement.style.fontSize = "12px"
            e.cellElement.style.borderColor = "#D4DCEC"
        } else if (e.rowType === "data") {
            e.cellElement.style.fontFamily = 'Segoe UI'
            e.cellElement.style.fontWeight = 'normal'
            e.cellElement.style.color = "#000000"
            const totalColumns = e.component.getVisibleColumns().length;
            const targetColumnIndices = [totalColumns - 4, totalColumns - 3, totalColumns - 2];
            if (e.columnIndex === targetColumnIndices[0]) {
                const elemRowIndex = e.rowIndex.toString()
                if (Object.keys(percent).includes(elemRowIndex)) {
                    const budget = parseInt(e.data.batlagdsan)
                    const forecast = parseInt(e.data.batlagdsan_edit)
                    let backColor
                    if(forecast <= 0){
                        backColor = forecast > budget ? '#E2FBEE' : forecast < budget ? '#FFF2F2' : ''
                    } else {
                        backColor = forecast < budget ? '#E2FBEE' : forecast > budget ? '#FFF2F2' : ''
                    }
                    e.cellElement.style.backgroundColor = backColor
                }
                if (Object.keys(amount).includes(elemRowIndex)) {
                    const budget = parseInt(e.data.batlagdsan)
                    const forecast = parseInt(e.data.batlagdsan_edit)
                    const backColor = forecast < budget ? '#E2FBEE' : forecast > budget ? '#FFF2F2' : ''
                    e.cellElement.style.backgroundColor = backColor
                }

            }
            if (e.columnIndex === targetColumnIndices[1]) {
                const elemRowIndex = e.rowIndex.toString()
                if (Object.keys(percent).includes(elemRowIndex)) {
                    const color = e.data.percent < 0 ? '#50c687' : e.data.percent > 0 ? '#f66160' : ''
                    e.cellElement.style.color = color
                }
            }
            if (e.columnIndex === targetColumnIndices[2]) {
                const elemRowIndex = e.rowIndex.toString()
                if (Object.keys(amount).includes(elemRowIndex)) {
                    const color = e.data.amount < 0 ? '#50c687' : e.data.amount > 0 ? '#f66160' : ''
                    e.cellElement.style.color = color
                }
            }
        }
    }
    const groupCell = (e) => {
        return <div>{e.data.displayValue}</div>
    }
    const requestFullscreen = () => {
        if (document.fullscreenElement) document.exitFullscreen();
        else document.getElementById("wrapper").requestFullscreen();
        // setFullscreen(prev=>!prev)
    }
    const revertToOriginalState = () => {
        const revertedData = dataEcon2.map(row => ({
        ...row,
        batlagdsan_edit: row.batlagdsan,
        percent: 0, 
        amount: 0, 
        }));
        setDataEcon2(revertedData)
        setEditedEcon({})
        setEditedEcon2({})
        setDiffPerc(0)
        setDiff(0)
        setPrevDiff(0)
        setPrevDiffPerc(0)
        setForecastBudget(0)
      };
    const setPercentValue = async (newData, currentRowData, value) => {
        const rowIndex = dataExpenseGridRef.current.instance.getRowIndexByKey(value)
        if (currentRowData !== null) {
            newData.percent = currentRowData;
        }
        const confirmedBudget = parseFloat(value.batlagdsan);
        const forecastBudget = parseFloat(value.batlagdsan_edit);
        const checkBudget = confirmedBudget === forecastBudget;
        const same = value.amount === 0
        if (rowIndex !== -1) {
            let eEcon = editedEcon;
            eEcon[value['econ_category_code_4'] + '' + value['econ_category_name']] = rowIndex;
            setEditedEcon(eEcon)
        }

        foreCastingByPercent(rowIndex, value, currentRowData, checkBudget, same)
    }
    const setAmountValue = async (newData, currentRowData, value) => {
        const rowIndex = dataExpenseGridRef.current.instance.getRowIndexByKey(value)
        if (currentRowData !== null) {
            newData.amount = currentRowData
        }
        const confirmedBudget = parseFloat(value.batlagdsan);
        const forecastBudget = parseFloat(value.batlagdsan_edit);
        const checkBudget = confirmedBudget === forecastBudget;
        const same = value.percent === 0
        if (rowIndex !== -1) {
            let eEcon = editedEcon2;
            eEcon[value['econ_category_code_4'] + '' + value['econ_category_name']] = rowIndex;
            setEditedEcon2(eEcon)
        }
        foreCastingByAmount(rowIndex, value, currentRowData, checkBudget, same)
    }

    const foreCastingByPercent = (el, data, newPercent, checkDiff, same) => {
        const check = newPercent < 0;
        let i1 = el === -1 ?
            editedEcon[data['econ_category_code_4'] + '' + data['econ_category_name']]
                ? editedEcon[data['econ_category_code_4'] + '' + data['econ_category_name']]
                : editedEcon2[data['econ_category_code_4'] + '' + data['econ_category_name']]
            : el;

        if (!isNaN(newPercent)) {
            const absolutePercent = Math.abs(newPercent);
            const increaseFactor = check ? 1 - absolutePercent / 100 :  1 + newPercent /100;
            const oldInput = same && (checkDiff || !checkDiff) ? parseFloat(data.batlagdsan) : parseFloat(data?.batlagdsan) + data.amount;
            const currentInput = oldInput * increaseFactor;
            let val = data.amount === 0 && newPercent === 0 ? data.batlagdsan : currentInput

            dataExpenseGridRef.current.instance.cellValue(i1, 'batlagdsan_edit', val);
            dataExpenseGridRef.current.instance.saveEditData();

            let batlagdsn = _.sumBy((dataExpenseGridRef.current.props.dataSource), obj => Number(obj.batlagdsan))
            let batlagdsn_edit = _.sumBy((dataExpenseGridRef.current.props.dataSource), obj => Number(obj.batlagdsan_edit))
            let prev_budget = _.sumBy((dataExpenseGridRef.current.props.dataSource), obj => Number(obj.prev_budget))
            let dif = batlagdsn_edit - batlagdsn
            let perc = ((batlagdsn_edit * 100) / batlagdsn) - 100
            let prevDif = batlagdsn_edit - prev_budget
            let prevDifPerc = ((batlagdsn_edit * 100) / prev_budget) - 100
            setDiffPerc(perc)
            setDiff(dif)
            setPrevDiff(prevDif)
            setPrevDiffPerc(prevDifPerc)
            let p = percent;
            p[i1] = newPercent;
            setPercent(p);
        }
    }
    const foreCastingByAmount = (el, data, newAmount, checkDiff, same) => {
        const check = newAmount < 0;
        let i1 = el === -1 ?
            editedEcon2[data['econ_category_code_4'] + '' + data['econ_category_name']]
                ? editedEcon2[data['econ_category_code_4'] + '' + data['econ_category_name']]
                : editedEcon[data['econ_category_code_4'] + '' + data['econ_category_name']]
            : el;
        if (!isNaN(newAmount)) {
            let absolutePercent = Math.abs(data.percent)
            const increaseFactor = data.percent < 0 ? 1 - absolutePercent / 100 :  1 + data.percent /100;
            const oldInput = same && (checkDiff || !checkDiff) ? parseFloat(data.batlagdsan) : parseFloat(data?.batlagdsan) * increaseFactor;
            const currentInput = check ? oldInput - Math.abs(newAmount) : oldInput + newAmount;
            let val = data.percent === 0 && newAmount === 0 ? data.batlagdsan : currentInput

            dataExpenseGridRef.current.instance.cellValue(i1, 'batlagdsan_edit', val);
            dataExpenseGridRef.current.instance.saveEditData();
            let batlagdsn = _.sumBy((dataExpenseGridRef.current.props.dataSource), obj => Number(obj.batlagdsan))
            let batlagdsn_edit = _.sumBy((dataExpenseGridRef.current.props.dataSource), obj => Number(obj.batlagdsan_edit))
            let prev_budget = _.sumBy((dataExpenseGridRef.current.props.dataSource), obj => Number(obj.prev_budget))
            let dif = batlagdsn_edit - batlagdsn
            let perc = ((batlagdsn_edit * 100) / batlagdsn) - 100
            let prevDif = batlagdsn_edit - prev_budget
            let prevDifPerc = ((batlagdsn_edit * 100) / prev_budget) - 100
            setDiffPerc(perc)
            setDiff(dif)
            setPrevDiff(prevDif)
            setPrevDiffPerc(prevDifPerc)
            let p = amount;
            p[i1] = newAmount;
            setAmount(p);
        }
    }

    const textAreaOptions = { format: '#,##0.00' };
    const onZero = (e) => {
        let dataSource = dataExpenseGridRef.current.props.dataSource
        let index = dataSource.findIndex(row => row.econ_category_code === e.row.data.econ_category_code)
        let i1 = index === -1 ? 0 : index

        if (dataSource && dataSource.length > 0) {
            dataSource[i1].batlagdsan_edit = dataSource[i1].batlagdsan
            dataSource[i1].percent = 0;
            dataSource[i1].amount = 0;
        }
        dataExpenseGridRef.current.instance.cellValue(i1, 'batlagdsan_edit', e.row.data.batlagdsan);
        dataExpenseGridRef.current.instance.cellValue(i1, 'percent', 0);
        dataExpenseGridRef.current.instance.cellValue(i1, 'amount', 0);
        dataExpenseGridRef.current.instance.saveEditData();
    }

    const cellRender = (e) =>{
        let batlagdsan = e.data.batlagdsan
        let batlagdsan_edit = e.data.batlagdsan_edit
        let diffAmount = 0;
        if (batlagdsan || batlagdsan_edit) {
          diffAmount = batlagdsan_edit !== e.data.batlagdsan ? (batlagdsan_edit - batlagdsan) : 0;
        }
        let accountVal
        if(e.text && e.text.includes('-')){
            accountVal = e.text.replace(/-/g,'')
        } else {
            accountVal = e.text
        }
        if (diffAmount > 0) {
            const dd = batlagdsan * -1
            return (<div className={e && e.text.includes('-') || dd === diffAmount ? "dec customCell": 'inc customCell'} style={{ flexDirection: 'column', display: 'flex', justifyContent: 'space-between' }}>
              <div className="current-value" style={{ fontWeight: 'normal', color: '#000000' }}>{e && e.text.includes('-') ? `(${accountVal})` : e.text}</div>
              <div className="diff" style={{ color: e && e.text.includes('-') || dd === diffAmount ? '#3daa75' : '#f66160' }}>{numberWithCommas(diffAmount)}</div>
            </div>)
          } else if (diffAmount < 0) {
            const val = Math.abs(diffAmount)
            return <div className={e && e.text.includes('-') ?"inc customCell": 'dec customCell'} style={{ flexDirection: 'column', display: 'flex', justifyContent: 'space-between' }}>
              <div className="current-value" style={{ fontWeight: 'normal', color: '#000000' }}>{e && e.text.includes('-') ? `(${accountVal})` : e.text}</div>
              <div className="diff" style={{ color: e && e.text.includes('-') ? '#f66160' : '#3daa75' }}>({numberWithCommas(val)})</div>
            </div>
          } else {
            return <span style={{ fontWeight: 'normal', color: '#000000' }}>{e && e.text.includes('-') ? `(${accountVal})` : e.text}</span>
          }
    }
    const onRowPrepared = (args) => {
        if (args.rowType == 'group' && (args.values[args.groupIndex] == "" || args.values[args.groupIndex] == null)) {
            args.rowElement.hidden = true;
            if (!args.component.isRowExpanded(args.key))
                args.component.expandRow(args.key)
        }
    }
    let sumForecast = 0
    for (let i = 0; i < dataExpenseGridRef?.current?.props?.dataSource.length; i++) {
        sumForecast += parseFloat(dataExpenseGridRef?.current?.props?.dataSource[i].batlagdsan_edit);
    }
    setForecastBudget(sumForecast)
    return (
        <div className="budgetForecast" id="wrapper" height="100%" width="100%">
            <LoadPanelComponent visible={loadMe} position={{ of: 'wrapper' }} />
            {dataEcon2 && dataEcon2.length > 0 ?
                <DataGrid dataSource={dataEcon2} ref={dataExpenseGridRef} hoverStateEnabled={true}
                    columnAutoWidth={true}
                    allowColumnResizing={true}
                    height={"90vh"}
                    width={'100%'}
                    wordWrapEnabled={true}
                    columnResizingMode={'nextColumn'}
                    paging={{ enabled: false }}
                    style={{ marginTop: 5, overflowY: "auto", overflowX: "hidden", }}
                    showBorders={true}
                    showRowLines={true}
                    sorting={{ mode: 'none' }}
                    showColumnLines={true}
                    showColumnHeaders={true}
                    onCellPrepared={onCellPreparedHandler} 
                    onRowPrepared={onRowPrepared}
                    onExporting={onExporting}
                >
                    <ColumnFixing enabled={false} />
                    <FilterPanel
                        visible={false}
                        texts={{ clearFilter: "Шүүлтүүр цэвэрлэх", createFilter: "Шүүх" }}
                    />
                    <HeaderFilter visible={true} search={true} texts={{ cancel: 'Болих', ok: 'Шүүх', emptyValue: 'Хоосон утга' }} />
                    <Selection mode="single" />
                    <Export enabled={true} />
                    <Scrolling columnRenderingMode="virtual" />
                    <Toolbar>
                        <Item>
                            <Button onClick={revertToOriginalState} icon="revert" hint="Бүх өөрчлөлтийг буцаах" />
                        </Item>
                        <Item>
                            <div style={{ display: 'flex', justifyContent: 'space-between', gap: '5px' }}>
                                <Button
                                    onClick={(e) => setAutoExpand(prev => !prev)}
                                    render={() => {
                                        return autoExpandAll ? (
                                            <CgCompressRight size={"1rem"} color="#172B4D" />)
                                            :
                                            (
                                                <CgArrowsExpandRight size={"1rem"} color="#172B4D" />
                                            )
                                    }}
                                    hint={autoExpandAll ? 'Багасгах' : 'ЭЗА дэлгэрэнгүй харуулах'}
                                >
                                </Button>
                                <Button
                                    className="grid-button"
                                    onClick={() => {
                                        requestFullscreen()
                                        setFullscreen(prev => !prev)
                                    }}
                                    render={() => {
                                        return fullscreen ?
                                            (<CgMinimize size={"1rem"} color="#172B4D" />)
                                            :
                                            (<CgMaximize size={"1rem"} color="#172B4D" />)
                                    }}
                                    hint={fullscreen ? 'Багасгах' : 'Дэлгэцэнд бүтэн харуулах'}
                                >
                                </Button>
                            </div>
                        </Item>
                        <Item name="exportButton"
                            render={(e) => {
                                return <Button
                                    onClick={e.options.onClick}
                                    icon="xlsxfile"
                                    render={() => {
                                        return <BsFiletypeXls size={"1rem"} color="#172B4D" />
                                    }}
                                    hint="Export table to excel"
                                    style={{ color: "red" }}>
                                </Button>
                            }}
                        />
                        <Item name="columnChooserButton"
                            render={(e) => {
                                return <Button onClick={e.options.onClick} hint="Багана сонгох" size='1rem' id='columnChooserButton' icon="columnchooser" />
                            }}
                        />
                    </Toolbar>
                    <Grouping autoExpandAll={autoExpandAll}
                        contextMenuEnabled={false}
                        expandMode="buttonClick" />
                    <ColumnChooser
                        enabled={true} height={"100%"} search={true}
                        mode="select"
                        title={'Багана сонгох'}
                    />
                    <Column dataField="econ_category_code_1" caption="" groupIndex={0} cssClass={'dx-datagrid-group-space'} showInColumnChooser={false}
                        groupCellComponent={groupCell} width={'1'} allowEditing={false}>
                        <Lookup dataSource={econCategory} valueExpr="code" displayExpr={(item) => {
                            return item && `${item.code} - ${item.name}`
                        }} /></Column>
                    <Column dataField="econ_category_code_2" caption="" groupIndex={1} cssClass={'dx-datagrid-group-space'} showInColumnChooser={false}
                        groupCellComponent={groupCell} width={'1'} allowEditing={false}>
                        <Lookup dataSource={econCategory} valueExpr="code" displayExpr={(item) => {
                            return item && `${item.code} - ${item.name}`
                        }} /></Column>
                    <Column dataField="econ_category_code_3" caption="" groupIndex={2} cssClass={'dx-datagrid-group-space'} showInColumnChooser={false}
                        groupCellComponent={groupCell} width={'1'} allowEditing={false}>
                        <Lookup dataSource={econCategory} valueExpr="code" displayExpr={(item) => {
                            return item && `${item.code} - ${item.name}`
                        }} /></Column>
                    <Column dataField="econ_category_code_4" caption="" groupIndex={2} cssClass={'dx-datagrid-group-space'} showInColumnChooser={false}
                        groupCellComponent={groupCell} width={'1'} allowEditing={false}>
                        <Lookup dataSource={econCategory} valueExpr="code" displayExpr={(item) => {
                            return item && `${item.code} - ${item.name}`
                        }} /></Column>
                    <Column dataField="econ_category_code" caption="Үзүүлэлт" allowResizing={false} showInColumnChooser={false}
                        allowEditing={false}>
                        <Lookup dataSource={econCategory} valueExpr="code" displayExpr={(item) => {
                            return item && `${item.code} - ${item.name}`
                        }} /></Column>
                    <Column caption={`${year - 1}`} alignment="center" visible={false}>
                        <Column dataField={`prev_budget`} format="#,##0.00" alignment="right" dataType="number" allowEditing={false}
                            caption={`Төсөв`} allowFiltering={false} allowResizing={true} visible={false} cellRender={(e)=>{
                                let val = Math.abs(e.value)
                                if(e.value > 0){
                                    return `${numberWithCommas(e.value)}`
                                } else {
                                    return `(${numberWithCommas(val)})`
                                }
                            }}>
                        </Column>
                        <Column dataField={`p1_guitsetgel`} format="#,##0.00" alignment="right" dataType="number" allowEditing={false}
                            caption={`Гүйцэтгэл`} allowFiltering={false} allowResizing={true} visible={false} cellRender={(e)=>{
                                let val = Math.abs(e.value)
                                if(e.value > 0){
                                    return `${numberWithCommas(e.value)}`
                                } else {
                                    return `(${numberWithCommas(val)})`
                                }
                            }}>
                        </Column>
                    </Column>
                    <Column caption={`${year}`} alignment="center">
                        <Column dataField={`batlagdsan`} format="#,##0.00" alignment="right" dataType="number" allowEditing={false}
                            caption={`Төсөв`} on allowFiltering={false} allowResizing={true} cellRender={(e)=>{
                                let val = Math.abs(e.value)
                                if(e.value > 0){
                                    return `${numberWithCommas(e.value)}`
                                } else {
                                    return `(${numberWithCommas(val)})`
                                }
                            }}>
                        </Column>
                        <Column dataField={`guitsetgel`} format="#,##0.00" alignment="right" dataType="number" allowEditing={false}
                            caption={`Гүйцэтгэл`} on allowFiltering={false} allowResizing={true} visible={false} cellRender={(e)=>{
                                let val = Math.abs(e.value)
                                if(e.value > 0){
                                    return `${numberWithCommas(e.value)}`
                                } else {
                                    return `(${numberWithCommas(val)})`
                                }
                            }}>
                        </Column>
                    </Column>
                    <Column dataField={`batlagdsan_edit`} format="#,##0.00" alignment="right" dataType="number" editorOptions={textAreaOptions}
                        caption={`Таамаглал`} allowFiltering={false} allowResizing={true} allowEditing={false} cellRender={(e)=>cellRender(e)}>
                    </Column>

                    <Column dataField="percent" caption="Хувь" width={'5rem'} allowFiltering={false} allowSorting={false}
                        alignment="center"
                        allowEditing={true}
                        setCellValue={setPercentValue}
                        customizeText={(data) => {
                            if (data.value !== undefined) {
                                return `${data.value}%`
                          } 
                        }}
                    />
                    <Column dataField="amount" caption="Дүн" width={'12rem'} allowFiltering={false} allowSorting={false}
                        alignment="center"
                        allowEditing={true}
                        setCellValue={setAmountValue}
                        format="#,##0.00"
                    />
                    <Editing
                        mode="cell"
                        allowUpdating={true}
                        allowAdding={false}
                        allowDeleting={false}
                        confirmDelete={true}
                        selectTextOnEditStart={true}
                        texts={{
                            addRow: "нэмэх",
                            cancelAllChanges: "Болих бүгд",
                            cancelRowChanges: "Болих",
                            confirmDeleteMessage: "Энэ мэдээллийг устгах уу?",
                            confirmDeleteTitle: "Баталгаажуулах",
                            deleteRow: "Устгах",
                            editRow: "Өөрчлөх",
                            saveAllChanges: "Хадгалах бүгд",
                            saveRowChanges: "Хадгалах",
                            undeleteRow: "Буцаах",
                            validationCancelChanges: "Баталгаажуулах болих",
                        }}
                    />
                    <Column type="buttons" width={"3rem"} visible={true} showInColumnChooser={false}>
                        <GridButton text="Энэ мөрийг 0 болгох" icon={'undo'} onClick={onZero} cssClass="grid-button" />
                    </Column>
                    <Export enabled={true} allowExportSelectedData={false} />
                    <Summary recalculateWhileEditing={true} >
                        <GroupItem column="prev_budget"
                            summaryType="sum"
                            showInGroupFooter={false} alignByColumn={true}
                            customizeText={(data) => {
                                let val = Math.abs(data.value)
                                if(data.value > 0){
                                    return `${numberWithCommas(data.value)}`
                                } else {
                                    return `(${numberWithCommas(val)})`
                                }
                            }} />
                        <GroupItem column="p1_guitsetgel"
                            summaryType="sum"
                            showInGroupFooter={false} alignByColumn={true}
                            customizeText={(data) => {
                                let val = Math.abs(data.value)
                                if(data.value > 0){
                                    return `${numberWithCommas(data.value)}`
                                } else {
                                    return `(${numberWithCommas(val)})`
                                }
                            }} />
                        <GroupItem column="batlagdsan"
                            summaryType="sum"
                            showInGroupFooter={false} alignByColumn={true}
                            customizeText={(data) => {
                                let val = Math.abs(data.value)
                                if(data.value > 0){
                                    return `${numberWithCommas(data.value)}`
                                } else {
                                    return `(${numberWithCommas(val)})`
                                }
                            }} />
                        <GroupItem column="batlagdsan_edit"
                            summaryType="sum"
                            showInGroupFooter={false} alignByColumn={true}
                            customizeText={(data) => {
                                let val = Math.abs(data.value)
                                if(data.value > 0){
                                    return `${numberWithCommas(data.value)}`
                                } else {
                                    return `(${numberWithCommas(val)})`
                                }
                            }} />
                        <TotalItem column="prev_budget"
                            summaryType="sum"
                            showInGroupFooter={false} alignByColumn={true}
                            customizeText={(data) => {
                                return `${numberWithCommas(data.value)}`
                            }} />
                        <TotalItem column="p1_guitsetgel"
                            summaryType="sum"
                            showInGroupFooter={false} alignByColumn={true}
                            customizeText={(data) => {
                                return `${numberWithCommas(data.value)}`
                            }} />
                        <TotalItem column="batlagdsan"
                            summaryType="sum"
                            showInGroupFooter={false} alignByColumn={true}
                            customizeText={(data) => {
                                return `${numberWithCommas(data.value)}`
                            }} />
                        <TotalItem column="batlagdsan_edit"
                            summaryType="sum"
                            showInGroupFooter={false} alignByColumn={true}
                            customizeText={(data) => {
                                if(data.value === prevPerp){
                                    setPrevDiff(0)
                                    setPrevDiffPerc(0)
                                }
                                return `${numberWithCommas(data.value)}`
                            }} />

                    </Summary>
                </DataGrid>
                :
                <EmptyData />
            }
        </div>
    )
}

import React,{useEffect} from 'react';
import {
    DataGrid,
    Column,
    RequiredRule,
    Lookup,
    Editing,
    Popup,
    Form,
    Item,
    Summary,
    TotalItem, Paging,
    HeaderFilter,
    SearchPanel,
} from 'devextreme-react/data-grid';
import fcsService from "../../../services/api/fcs";
import _ from "lodash";
import notify from "devextreme/ui/notify";
import {numberWithCommas} from "../../../util";
import {LoadPanel} from "devextreme-react/load-panel";
import DropDownBoxComponent from './components/DropDownBoxComponent.js';
import LoadPanelComponent from '../../../components/LoadPanelComponent';

class ExpenseTxnDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataSource: [],
            dataFcsInv: [],
            dataFcsUnit: [],
            dataFcsMonth: [],
            myBabyRef:[],
            loader: true,
            dataSetInv:[],
            checkAmt: 0,
        };
        this.datSourceRecp = this.props.data.data;

    }

    componentDidMount = async () => {
       this.fcsInvIssList();
    }
     fcsInvIssList = async () => {
        // dataFcsInv: _.filter(result.data, {loctn_id: this.props.data.data.loctn_id}),
        const result= await fcsService.getFcsCurrentBalanceInv(this.props.data.data.loctn_id);
        this.setState({
            dataSource: await fcsService.getFcsInvIssByRecpId(this.props.data.data.id),
            dataFcsInv: result.data,
            dataFcsUnit: await fcsService.getAllFcsUnit(),
            dataFcsMonth: await fcsService.getAllFcsMonth('IN'),
            loader:  false
        });

    }
     customAddRow = (grid) => {
        grid.current.instance.addRow();
    }

     calculateAmt = (newData, value, currentRowData) => {
         newData.iss_qty = value;
         newData.iss_cost = currentRowData.unit_cost * value;

    }
    calculateAmt1 = (newData, value, currentRowData) => {
        newData.unit_cost=value;
        newData.iss_cost = currentRowData.iss_qty * value;
    }
     customizedSummary = (data) => {
        return `Нийт: ${numberWithCommas(data.value)}`
    }
     setExpenseTxnDetail = async (e) => {
        if (e.changes && e.changes.length !== 0 ) {
            this.setState({ loader: true});
            e.changes.forEach( data => {
            //yyyymm
            const date = new Date(this.datSourceRecp.date_recpt);//new e.changes[0].data.date_recpt;
            const year = date.getFullYear() * 1e4; // 1e4 gives us the the other digits to be filled later, so 20210000.
            const month = (date.getMonth() + 1) * 100;
            const yyyymm = year + month // `+ ''` to convert to string from number, 202206
            const fcsCurrentMonth=this.state.dataFcsMonth.map((item) =>{
                let date = item.year +'/'+ item.month
                let datetimenew = new Date(date)
                let year = datetimenew.getFullYear() * 1e4; // 1e4 gives us the the other digits to be filled later, so 20210000.
                let month = (datetimenew.getMonth() + 1) * 100;
                const yyyymm_month = year + month
                return yyyymm_month
            });

            if(yyyymm === fcsCurrentMonth[0]) {
                if (data.type === "update") {
                    let fcsInv = _.filter(this.state.dataFcsInv, function (o) {
                        return o.inv_id == data.data.inv_id
                    }) || [];
                    let fcsUnit = _.filter(this.state.dataFcsUnit, {id: data.data.unit_id});
                    if(fcsInv[0].last_cost < data.data.iss_cost)
                    {
                        this.setState({ loader: false});
                        return  notify('Бараа материалын дүнгээс хэтэрсэн дүн оруулсан байна', "error", 2000);

                    }
                    else {
                        try {
                            data.data.updated_user_id = this.datSourceRecp.updated_user_id;
                            data.data.org_id = this.datSourceRecp.org_id;
                            data.data.iss_date = this.datSourceRecp.date_recpt;
                            data.data.is_number = this.datSourceRecp.rs_number;
                            data.data.stnumber = fcsInv[0].stnumber;
                            data.data.stname = fcsInv[0].inv_name;
                            data.data.loctn_id = this.datSourceRecp.loctn_id;
                            data.data.chart_id = this.datSourceRecp.chart_id;
                            data.data.custorgn_id = this.datSourceRecp.custorgn_id;
                            data.data.acct_db = this.datSourceRecp.chart_model.acct;
                            data.data.org = this.datSourceRecp.cust_model.orgcode;
                            data.data.recvr = this.datSourceRecp.cust_model.name;
                            data.data.loctn_code = this.datSourceRecp.location_model.loctn_code;
                            data.data.loctn_name = this.datSourceRecp.location_model.loctn_name;
                            data.data.unit_name = fcsUnit[0].name;
                            data.data.inv_recp_id = this.datSourceRecp.id;
                            fcsService.editFcsInvIss(data.data);
                            this.fcsInvIssList();
                            notify('Амжилттай бараа материалын зарлагын гүйлгээ хадгалагдлаа', "success", 2000);

                        } catch (e) {
                            notify('Засварлалтыг хадгалах явцад алдаа гарлаа', "error", 2000);
                        }
                    }
                } else if (data.type === "insert") {
                    let fcsInv= _.filter(this.state.dataFcsInv, {inv_id: data.data.inv_id});
                    let fcsUnit= _.filter(this.state.dataFcsUnit, {id: data.data.unit_id})
                    if(fcsInv[0].last_cost < data.data.iss_cost)
                    {
                        this.setState({ loader: false});
                       return  notify('Бараа материалын дүнгээс хэтэрсэн дүн оруулсан байна', "error", 2000);

                    }
                    else {
                        try {
                            delete data.data.id;
                            delete data.data.updatedAt;
                            data.data.created_user_id = this.datSourceRecp.created_user_id;
                            data.data.org_id = this.datSourceRecp.org_id;
                            data.data.iss_date = this.datSourceRecp.date_recpt;
                            data.data.is_number = this.datSourceRecp.rs_number;
                            data.data.stnumber = fcsInv[0].stnumber;
                            data.data.stname = fcsInv[0].inv_name;
                            data.data.loctn_id = this.datSourceRecp.loctn_id;
                            data.data.chart_id = this.datSourceRecp.chart_id;
                            data.data.custorgn_id = this.datSourceRecp.custorgn_id;
                            data.data.acct_db = this.datSourceRecp.chart_model.acct;
                            data.data.org = this.datSourceRecp.cust_model.orgcode;
                            data.data.recvr = this.datSourceRecp.cust_model.orgcode;
                            data.data.loctn_code = this.datSourceRecp.location_model.loctn_code;
                            data.data.loctn_name = this.datSourceRecp.location_model.loctn_name;
                            data.data.unit_name = fcsUnit[0].name;
                            data.data.inv_recp_id = this.datSourceRecp.id;
                            fcsService.addFcsInvIss(data.data);
                            this.fcsInvIssList();
                            notify('Амжилттай бараа материалын зарлагын гүйлгээ бүртгэгдлээ', "success", 2000);
                        } catch (e) {
                            notify('Шинээр үүсэх явцад алдаа гарлаа', "error", 2000);
                        }
                    }

                }
                else if (data.type === "remove") {
                    try {
                        fcsService.removeFcsInvIss(data.key.id);
                        this.fcsInvIssList();
                        notify('Амжилттай устгагдлаа', "success", 2000);
                    } catch (e) {
                        notify('Устгах явцад алдаа гарлаа', "error", 2000);
                    }

                }
            }
            else {
                notify('Сонгосон огноо тухайн тайлант он сард биш байна', "error", 2000);
            }
            });
        }

    }

    onRowInserted(e) {
        e.component.navigateToRow(e.key);
    }

    setStateValue(rowData, value) {
        if(value!==undefined) {
            rowData.inv_id = value.inv_id;
            rowData.unit_cost = value.last_avg_unit;
            rowData.iss_qty = value.last_qty;
            rowData.unit_id = value.unit_id;
            rowData.iss_cost = value.last_qty * value.last_avg_unit;
          //  this.setState({ checkAmt: rowData.iss_cost});
        }
       // this.setState({ checkAmt: rowData.iss_cost});

    }

    render() {
        const { rs_number } = this.props.data.data;
        return (
            <React.Fragment>
                <LoadPanelComponent
                    shadingColor="rgba(0,0,0,0.4)"
                   // position={userListPosition}
                   showPane={false}
                    visible={this.state.loader}
                    message = ''
                />
                <div className="master-detail-caption">
                    {`${rs_number} - Падааны дугаар`}
                </div>
                <DataGrid
                    dataSource={this.state.dataSource}
                    showBorders={true}
                    columnAutoWidth={true}
                    ref={this.state.myBabyRef}
                    onSaved={this.setExpenseTxnDetail}
                    showBorders={true}
                    onRowInserted={this.onRowInserted}
                >
                    <Column
                        dataField="inv_id"
                        caption="Барааны дотоод код нэр"
                        editCellComponent={DropDownBoxComponent}
                        setCellValue={this.setStateValue}
                    >
                        <Lookup
                            valueExpr="inv_id"
                            displayExpr="inv_name"
                            dataSource={this.state.dataFcsInv} />
                        <RequiredRule message = 'Бараа оруулна уу'/>
                    </Column>
                    <Column
                        cssClass = "custom"
                        dataField="unit_id"
                        caption="Барааны х/н код нэр"
                        allowEditing = {true}
                        allowFiltering={true}
                    >
                        <RequiredRule
                            message = 'Бараа оруулна уу'
                        />
                        <Lookup
                            valueExpr="id"
                            displayExpr={(item) => {
                                return item && `${item.code} - ${item.name}`
                            }}
                            dataSource={this.state.dataFcsUnit}

                        />
                    </Column>
                    <Column
                        cssClass = "custom"
                        dataField="unit_cost"
                        caption="Нэгж үнэ"
                        allowEditing = {false}
                        setCellValue={this.calculateAmt1}
                    >
                        <RequiredRule
                            message = 'Нэгж үнэ оруулна уу'
                        />
                    </Column>
                    <Column
                        cssClass = "custom"
                        dataField="iss_qty"
                        caption="Тоо"
                        allowEditing = {true}
                        setCellValue={this.calculateAmt}
                    >
                        <RequiredRule
                            message = 'Тоо оруулна уу'
                        />
                    </Column>
                    <Column
                        cssClass = "custom"
                        dataField="iss_cost"
                        caption="Нийт дүн"
                        allowEditing = {false}
                    >
                        <RequiredRule/>
                    </Column>
                    <Paging enabled={true} defaultPageSize={15} />
                    <HeaderFilter visible={true} />
                    <SearchPanel visible={true} />
                    <Editing
                        mode="batch"
                        allowUpdating={true}
                        allowDeleting={true}
                        allowAdding={true}
                        useIcons = {true}
                        texts={{
                            cancelAllChanges: 'Болих бүгд',
                            cancelRowChanges: 'Болих',
                            confirmDeleteMessage: 'Энэ бараа материалын зарлагын гүйлгээ мэдээллийг устгах уу?',
                            confirmDeleteTitle: 'Баталгаажуулах',
                            deleteRow: 'Устгах',
                            editRow: 'Өөрчлөх',
                            saveAllChanges: 'Хадгалах бүгд',
                            saveRowChanges: 'Хадгалах',
                            undeleteRow: 'Буцаах',
                            validationCancelChanges: 'Баталгаажуулах болих',
                        }}
                    >
                        <Popup title="Бараа материалын орлогын гүйлгээ" showTitle={true} width={1000} height={440} />
                        <Form>
                            <Item itemType="group" colCount={2} colSpan={2}>
                                <Item dataField="inv_id" />
                                <Item dataField="unit_id" />
                                <Item dataField="unit_cost" />
                                <Item dataField="iss_qty" />
                                <Item dataField="iss_cost" />
                            </Item>
                        </Form>
                    </Editing>
                    <Summary recalculateWhileEditing={true}>
                        <TotalItem column="iss_qty" summaryType="sum" customizeText={this.customizedSummary}/>
                        <TotalItem column="iss_cost" summaryType="sum" customizeText={this.customizedSummary}/>
                    </Summary>
                </DataGrid>
            </React.Fragment>
        );
    }

}

export default ExpenseTxnDetail;

import {useState, createContext, useContext, useEffect} from "react"
import AuthService from "../services/api/auth"
import { useLocalStorage } from "./useLocalStorage"

const TranslationContext = createContext()

export const TranslationProvider = ({children}) => {
  const [language, setLanguage] = useLocalStorage("language", "mn")
  // const [translations, setTranslations] = useState({en: {}, mn: {}})

  // useEffect(() => {
  //   async function findUser() {
  //     return AuthService.getProfile()
  //       .then((res) => {
  //         console.log("resdata", res.data)
  //         organizeData(res.data?.menu)
  //       })
  //       .catch((e) => {
  //         console.log(e)
  //       })
  //   }
  //   findUser()
  // }, [])

  // const organizeData = (data) => {
  //   let translationsCopy = translations
  //   data.forEach((item) => {
  //     for (const key in item) {
  //       if (key.includes("eng")) {
  //         const propName = key.replace("_eng", "")
  //         translationsCopy.en[`${item.id}.${propName}`] = item[key]
  //       } else {
  //         translationsCopy.mn[`${item.id}.${key}`] = item[key]
  //       }
  //     }
  //   })
  //   setTranslations(translationsCopy)
  // }

  const t = (key) => {
    // if (!translations[language]?.[key]) {
    //   return key
    // }
    // return translations[language][key]
  }

  return (
    <TranslationContext.Provider value={{setLanguage, language, t}}>
      {children}
    </TranslationContext.Provider>
  )
}

export const useTranslation = () => {
  const {language, setLanguage, t} = useContext(TranslationContext)
  let isMongolian = language === "mn"
  return {setLanguage, language, t, isMongolian}
}

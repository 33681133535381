import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import OrganizationService from "../../services/api/organization";
import TopHeader from "../introduction/components/TopHeaderComponent";
import '../../assets/imgs/hzyam/hzyam.css';
import { Button } from "devextreme-react/button";
import backbtn from "../../assets/imgs/hzyam/back-button.png";
import './static/mojhaHome.css'
import { urlServer } from "../../shared/lib/request";
import LoadPanelComponent from "../../components/LoadPanelComponent";
import { BackButton } from '../../components/Buttons';

export default function OrgList() {
    let history = useHistory();
    const [list260org, setList260org] = useState([]);
    const location = useLocation();
    const { bannerFileName, orgId } = location.state;
    const [isLoading, setIsLoading] = useState(false);


    useEffect(() => {
        getData(orgId)
    }, []);

    const getData = async (id) => {
        try {
            setIsLoading(true);
            let a = await OrganizationService.get260Org(id);
            setList260org(a.data);
            setIsLoading(false);
        } catch (error) {
            console.log("error", error)
        }
    }

    const changePage = (id, name) => {
        if (id === 629) {
            getData(id)
        } else {
            const location = {
                pathname: '/ministryPage',
                state: {
                    isfirst: false,
                    toId: id,
                    orgName: name
                }
            };
            history.push(location)
        }
    }
    return (

        <div style={{ color: '#140b3b', marginLeft: 10 }}>
            <LoadPanelComponent
                position={{ of: '#filePo' }}
                visible={isLoading}
            />
            <TopHeader urlServer={urlServer} fileNameData={bannerFileName} />
            <BackButton onClick={() => history.goBack()} />
            <div className="card" style={{ flex: 1, borderColor: '#EEF0F4', marginTop: 15 }}>
                <div className='col-12 row'>
                    <div className='orgs-container'>
                        {list260org?.map((item, key) => (
                            <div style={{ background: "#919da9"}} className='org-card' key={key}
                                onClick={() => changePage(item.org_id, item.name)}>
                                {item.name && item.logo &&
                                    <div className='org-card-logo'><img alt={item.name} src={item.logo.value} /></div>}
                                {item.ministryBannerOrg?.value && <div className='org-card-bg'><img alt={item.name}
                                    src={urlServer + '/api/file/documents/' + item.ministryBannerOrg.value} />
                                </div>}
                                <div className='org-card-title'>{item.name}</div>
                                {item.definition &&
                                    <div className='org-card-text' dangerouslySetInnerHTML={{ __html: item.definition.value }}></div>}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

import React, {useState} from 'react'
import feedbackServices from '../../../services/api/feedback'
import DataGrid, { Column ,Lookup } from 'devextreme-react/data-grid'
import { Popup } from 'devextreme-react'
import orgPositionServices from '../../../services/api/orgPosition'

function ShowLog({fbId, popupVisible, setPopupVisible}) {
    const [log, setLog] = useState([])
    const [status, setStatus]= useState([])
    const [list, setList] = useState([])

    const getLog = async ()=>{
        try{
            const res = await feedbackServices.getLog(fbId)
            setLog(res.data)
        }catch(e){
            console.log(e);
        }
    }

    const fbStatus = async () =>{
        try {
            const res = await feedbackServices.getAllStatus()
            setStatus(res.data)
        } catch(err){
            console.log(err);
        }
    }

    const orgList = async () =>{
        try{
            const res = await orgPositionServices.getOrgListByParent()
            setList(res.data)
        } catch(e){
            console.log(e);
        }
    }

    const onShowing = () => {
        if (popupVisible) {
            getLog();
            fbStatus()
            orgList()
        }
    }

    const onHiding = () => {
        setPopupVisible(false);
    }
    const handleOnCellPrepared = (e) => {

        if (e.rowType === "header") {
          e.cellElement.style.cssText = 'color: black; background-color: #e8e8e8; text-align: center; font-family: Montserrat'
        }
        if (e.rowType === "data") {
          e.cellElement.style.cssText = "color: black; text-align: center; align-items:center; font-family: Montserrat"
        }
      }

  return (
    <Popup
        visible={popupVisible}
        dragEnabled={false}
        onShowing={onShowing}
        onHiding={onHiding}
        hideOnOutsideClick={true}
        showCloseButton={true}
        showTitle={true}
        title="Өөрчлөлтийн түүх харах"
        width="40%"
        height="30vh"
    >
        <DataGrid
            keyExpr="id"
            dataSource={log}
            showBorders={true}
            showRowLines={true}
            showColumnLines={true}
            hoverStateEnabled={true}
            style={{flexWrap: "wrap"}}
            noDataText="Мэдэгдэл байхгүй"
            focusedRowEnabled={true}
            wordWrapEnabled={true}
            onCellPrepared={handleOnCellPrepared}
        >   
             <Column
              caption="#"
              width='3rem'
              alignment='center'
              allowSorting={false}
              cellRender={(data) => {
                if (data.rowType === "data") {
                  return <span>{data.rowIndex + 1}</span>;
                }
                return null;
              }}
            />
            <Column caption='Төлөв' dataField='feedback_status' alignment='center'>
                <Lookup dataSource={status} valueExpr='id' displayExpr='status_name'/>
            </Column>
            <Column 
                dataField='createdAt' 
                alignment='center' 
                dataType='date'
                caption='Өөрчилсөн огноо'
                format="yyyy-MM-dd HH:mm:ss"
            />
            <Column caption='Шилжүүлсэн байгууллага' dataField='transferred_org_id' alignment='center'>
                <Lookup dataSource={list} valueExpr='id' displayExpr='organization_name'/>
            </Column>
        </DataGrid>
    </Popup>
  )
}

export default ShowLog
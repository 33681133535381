import React, { useState, useEffect } from "react";
import remainingService from "../../services/api/remainingService";
import DataGrid, { Column, Lookup, Paging, Scrolling } from "devextreme-react/data-grid";
import styled from "styled-components";
import TabPanel from 'devextreme-react/tab-panel';
import { useHistory } from "react-router-dom";
import "./govStyling.css";
import { BackButton } from "../../components/Buttons";
import { ScrollView } from "devextreme-react";
import { numberWithCommas } from "../../util";
import notify from "devextreme/ui/notify";
import EmptyData from "../budget/components/empty";

export const CustomTabPanel = styled.div`
  display: flex;
  gap: 1rem;
  .tab {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 0.8rem 0.5rem;
    background-color: #f8f9fb;
    border-radius: 15px;
    transition: 0.3s all ease-in;
    border: 2px solid transparent;
    .title {
      font-size: 1rem;
      font-weight: 700;
    }
    &:hover {
      background-color: white;
      border: 2px solid #eff0f3;
    }
  }
`

const FieldLabel = styled.div`
  text-align: left;
  font-weight: bold;
`;
const FieldValue = styled.div`
  padding: 8px 15px 9px 0;
  text-align: justify;
`;
const GovProjectDetail = (props) => {
  let cntGovPro =
    props.location &&
    props.location.state &&
    props.location.state.currentGovProject;

  let data = [
    // { id: 1, text: "Ерөнхий мэдээлэл" },
    { id: 1, text: "Төсөв" },
    { id: 2, text: "Бүрэлдэхүүн" },
    { id: 3, text: "Хийгдэх ажил" },
    { id: 4, text: "Гүйцэтгэгч" },
    { id: 5, text: "Шалгуур үзүүлэлт" },
  ];

  // const dataSourceOptions = {
  //   store: new ArrayStore({
  //     data,
  //     key: "id",
  //   }),
  // };

  const [detailGrid, setDetailGrid] = useState([]);
  const [selectedItem, setSelectedItem] = useState(2)
  const [currency, setCurrency] = useState([])


  useEffect(() => {
    handleListSelectionChange(2)
  }, [])
  useEffect(() => {
    handleListSelectionChange()
  }, [selectedItem])
  useEffect(() => {
    getCurrency()
  }, [])
  const getCurrency = async () => {
    try {
      let r = await remainingService.getCurrency()
      setCurrency(r)
    } catch (err) {
      notify(err, 'error', 2000)
    }
  }
  const handleListSelectionChange = async (e) => {
    if (e === 1) {
      setDetailGrid([]);
      let tusuv = await remainingService.getGovProjectEtc(cntGovPro.id);
      setDetailGrid(tusuv);
    } else if (e === 2) {
      setDetailGrid([]);
      let bureldehuuun = await remainingService.getProjectComponent(cntGovPro.id);
      setDetailGrid(bureldehuuun);
    }
    else if (e === 3) {
      setDetailGrid([]);
      let hiigdehAjil = await remainingService.getProjectToDo(cntGovPro.id);
      setDetailGrid(hiigdehAjil);
    } else if (e === 4) {
      setDetailGrid([]);
      let guitsetgegch = await remainingService.getProjectContractor(cntGovPro.id);
      setDetailGrid(guitsetgegch);
    } else if (e === 5) {
      setDetailGrid([]);
      let shalguur = await remainingService.getBudgetCriteria(cntGovPro.id);
      setDetailGrid(shalguur);
    }
  };

  const cstItmRenderer = (item) => {
    return (
      <span>{item.title}</span>
    )
  }

  const history = useHistory()

  const validValues = [
    'client_total',
    'client_flow',
    'client_asset',
    'client_payback',
    'approved_total',
    'approved_flow',
    'approved_asset',
    'aprroved_asset',
    'approved_payback',
    'aprroved_total',
    'issued_total_usage',
    'issued_flow_usage',
    'issued_payback_usage',
    'issued_total_spend',
    'issued_flow_spend',
    'issued_asset_spend',
  ];
  const onCellPrepared = (e) => {
    if (e.rowType === "header") {
      e.cellElement.wordWrapEnabled = true
      e.cellElement.setAttribute('style', `text-align: center; font-family: 'Segoe UI'; font-weight: bold; color: #15186A; background-color: #EEF0F4; vertical-align: middle`)
    }
    if (e.rowType === "data") {
      e.cellElement.style.fontFamily = "Segoe UI"
      e.cellElement.style.fontWeight = "semibold"
      e.cellElement.style.color = "#000000"
      e.cellElement.style.verticalAlign = "middle";
    }
  }
  const cstBdyRenderer = (item) => {
    return (
      item && item.data?.dataSource?.length !== 0 ?
      <div>

        <DataGrid
          dataSource={item.data.dataSource}
          // columnAutoWidth={true}
          // wordWrapEnabled={true}
          noDataText="Дата байхгүй байна."
          showRowLines={true}
          onCellPrepared={onCellPrepared}
          columnMinWidth={60}
          allowColumnResizing={true}
        >
          {item && item.index !== 0 &&
          <Scrolling columnRenderingMode="virtual" />
          }
          <Column dataField="component_code" width={'auto'} minWidth={'3rem'} alignment="center" caption={"№"} cellRender={(data) => {
              const adjustedIndex = data.rowIndex + 1 + data.component.pageIndex() * data.component.pageSize();
              return <span>{adjustedIndex}</span>;
            }} />
          {item && item.data?.columns?.map((element, index) => (
            <Column
              key={index}
              minWidth={item.index === 0 ? 270 : 60}
              width={'auto'}
              dataField={element.dataField}
              caption={element.caption}
              format={validValues.includes(element.dataField)
                ? {
                  type: 'fixedPoint',
                  precision: 2
                }
                : undefined
              }
              calculateCellValue={(rowData) => {
                if (validValues.includes(element.dataField) && rowData) {
                  const valueAsString = rowData[element.dataField];
                  const valueAsNumber = parseFloat(valueAsString);
                  if (!isNaN(valueAsNumber)) {
                    return valueAsNumber;
                  }
                }
                return rowData ? rowData[element.dataField] : null;
              }}
            />
          ))}
        </DataGrid>
      </div>
        :
        <EmptyData />
    )
  }

  const renderRemainingTabs = () => {
    return (
      detailGrid && detailGrid.length !== 0 &&
      <div className="govProjectDetail">
        <TabPanel
          height={'auto'}
          width={'100%'}
          dataSource={detailGrid}
          itemTitleRender={cstItmRenderer}
          itemComponent={cstBdyRenderer}
        />
      </div>
    )
  }


  const bottomComponentGrid = () => {
    return (
      detailGrid && detailGrid.length !== 0 ?
        <ScrollView width={'100%'} height={'100%'}>

          <DataGrid
            dataSource={detailGrid}
            showBorders={true}
            wordWrapEnabled={true}
            // columnAutowWidth={true}
            onCellPrepared={onCellPrepared}
            rowAlternationEnabled={true}
            showRowLines={true}
            columnMinWidth={60}
          >
            <Scrolling columnRenderingMode="virtual" />
            <Column dataField="component_code" width={'auto'} minWidth={'3rem'} alignment="center" caption={"№"} cellRender={(data) => {
              const adjustedIndex = data.rowIndex + 1 + data.component.pageIndex() * data.component.pageSize();
              return <span>{adjustedIndex}</span>;
            }} />
            <Column dataField="component_name" alignment="justify" caption={"Бүрэлдэхүүний нэр"} width={500} />
            <Column dataField="partner_name" alignment="justify" caption={"Хөгжлийн түнш"} width={'auto'} />
            {/* <Column dataField="economic_sourcename" alignment="justify" caption={"Санхүүжүүлтийн төрөл"} width={'auto'} /> */}
            <Column dataField="contract_name" caption={"Гэрээ"} width={'auto'} />
            <Column dataField="industry_name" caption={"Салбар"} width={'auto'} />
            <Column alignment="right" dataField="budget_amount" caption={"Бүрэлдэхүүний төсөв"} width={'auto'} customizeText={(arg) => numberWithCommas(parseFloat(arg.value))} />
            <Column alignment="right" dataField="task_amount" caption={"Гэрээ байгуулсан дүн"} width={'auto'} customizeText={(arg) => numberWithCommas(parseFloat(arg.value))} />
            <Column alignment="right" dataField="component_amount" caption={"Үлдэгдэл"} width={'auto'} customizeText={(arg) => numberWithCommas(parseFloat(arg.value))}/>
            <Column dataField="currency_code" caption={"Валют"} width={'4rem'} alignment="center">
              <Lookup dataSource={currency} valueExpr={'id'} displayExpr={'code'} width={'auto'} />
            </Column>
            <Column caption="Бүртгэсэн">
              <Column
                dataField="user_name"
                caption={"Хэрэглэгч"}
                minWidth={200}
                alignment="center"
              />
              <Column
                dataField="created_date"
                caption={"Огноо"}
                dataType="date"
                alignment="center"
                minWidth={100}
                format="yyyy-MM-dd"
              />
            </Column>
          </DataGrid>
        </ScrollView>

        :
        <EmptyData />
    );
  };

  const bottomToDoGrid = () => {
    return (
      detailGrid && detailGrid.length !== 0 ?
        <ScrollView height={'100%'} width={'100%'}>
          <DataGrid
            dataSource={detailGrid}
            showBorders={true}
            wordWrapEnabled={true}
            // columnAutoWidth={true}
            onCellPrepared={onCellPrepared}
            rowAlternationEnabled={true}
            showRowLines={true} n
            columnMinWidth={60}
            allowColumnResizing={true}
          >
            <Scrolling columnRenderingMode="virtual" />
            <Column width={'auto'} minWidth={'3rem'} alignment="center" caption={"№"} cellRender={(data) => {
              const adjustedIndex = data.rowIndex + 1 + data.component.pageIndex() * data.component.pageSize();
              return <span>{adjustedIndex}</span>;
            }} />
            <Column dataField="project_name" caption={"Төсөл"} width={300} />
            <Column
              dataField="component_name"
              width={'auto'}
              caption={"Бүрэлдэхүүний санхүүжүүлэгч"}
              alignment="justify"
              minWidth={300} />
            <Column dataField="taskname" caption={"Хийгдэх ажил"} width={400} alignment="justify" />
            <Column dataField="description" caption={"Тайлбар"} width={'auto'} alignment="justify" minWidth={100}/>
            <Column dataField="taskamount" caption={"Дүн"} width={'auto'} minWidth={100} alignment="right" customizeText={(arg) => numberWithCommas(parseFloat(arg.value))} />
            <Column dataField="currency_code" caption={"Валют"} alignment="center"  >
              <Lookup dataSource={currency} valueExpr={'id'} displayExpr={'code'} />
            </Column>
            {/* <Column dataField="taskname" caption={"Төрөл"} /> */}
            <Column caption="Хэрэгжих газар">
              <Column
                dataField="city_name"
                caption={"(Аймаг/хот)"}
                width={'auto'}
                alignment="center"
              />
              <Column
                dataField="district_name"
                caption={"(Сум/Дүүрэг)"}
                width={'auto'}
                alignment="center"
              />
            </Column>
            <Column caption="Бүртгэсэн">
              <Column
                dataField="user_name"
                width={'auto'}
                caption={"Хэрэглэгч"}
                alignment="center"
              />
              <Column
                dataField="created_date"
                caption={"Огноо"}
                dataType="date"
                format="yyyy-MM-dd"
                width={'auto'}
                alignment="center"
              />
            </Column>
          </DataGrid>
        </ScrollView>
        :
        <EmptyData />
    );
  };


  const bottomContractorGrid = () => {
    return (
      detailGrid && detailGrid.length !== 0 ?
        <DataGrid
          dataSource={detailGrid}
          wordWrapEnabled={true}
          showBorders={true}
          // columnAutowWidth={true}
          onCellPrepared={onCellPrepared}
          rowAlternationEnabled={true}
          showRowLines={true}
          columnMinWidth={100}
        >
          <Scrolling columnRenderingMode="virtual" />
          <Column dataField="component_code" width={'auto'} minWidth={'3rem'} alignment="center" caption={"№"} cellRender={(data) => {
              const adjustedIndex = data.rowIndex + 1 + data.component.pageIndex() * data.component.pageSize();
              return <span>{adjustedIndex}</span>;
            }} />
          <Column caption="Гэрээ">
            <Column dataField="contract_code" caption={"Дугаар"} width={'auto'} />
            <Column
              dataField="contract_name"
              caption={"Нэр"}
              width={'auto'}
              minWidth={300}
              cellRender={(data) => (
                <div className="scrollStyle" style={{ whiteSpace: 'normal', overflowX: 'auto', textAlign: 'justify', paddingRight: 5, maxHeight: '150px', height: '100%' }}>
                  {data.data.contract_name}
                </div>
              )}
            />
            <Column
              dataField="start_date"
              caption={"Эхлэх"}
              dataType="date"
              format="yyyy-MM-dd"
              minWidth={100}
            />
            <Column
              dataField="end_date"
              caption={"Дуусах"}
              dataType="date"
              format="yyyy-MM-dd"
              minWidth={100}
            />
            <Column dataField="contract_total_amount" caption={"Дүн"} width={'auto'} customizeText={(arg) => numberWithCommas(parseFloat(arg.value))} />
            <Column dataField="new_currency_value" caption={"Валют"} width={70} />
          </Column>
          <Column dataField="task_name" caption={"Ажлын нэр"} width={400} />
          <Column caption="Гүйцэтгэгч">
            <Column dataField="name" caption={"Нэр"} width={'auto'} />
            <Column dataField="register" caption={"Регистр"} />
          </Column>
          {/* <Column dataField="customer_id" caption={"Харилцагч"}/> */}
          <Column caption="Данс">
            <Column dataField="bank_name" caption={"Банк"} width={'auto'} />
            <Column dataField="customer_name" caption={"Нэр"} width={'auto'} />
            <Column dataField="account_number" caption={"Дугаар"} width={'auto'} />
          </Column>
          {/* <Column dataField="new_currency_value" caption={"Валют"} /> */}
          {/* <Column dataField="contract_id" caption={"Гэрээний дугаар"} /> */}
        </DataGrid>
        :
        <EmptyData />
    );
  };

  const bottomCriteriaGrid = () => {
    return (
      detailGrid && detailGrid.length !== 0 ?
        <DataGrid
          dataSource={detailGrid}
          wordWrapEnabled={true}
          showBorders={true}
          // columnAutowWidth={true}
          rowAlternationEnabled={true}
          onCellPrepared={onCellPrepared}
          showRowLines={true}
          columnMinWidth={100}
        >
          <Scrolling columnRenderingMode="virtual" />
          <Column dataField="component_code" width={'auto'} minWidth={'3rem'} alignment="center" caption={"№"} cellRender={(data) => {
              const adjustedIndex = data.rowIndex + 1 + data.component.pageIndex() * data.component.pageSize();
              return <span>{adjustedIndex}</span>;
            }} />
          <Column width={'auto'} dataField="criteria_number" caption={"Дугаар"} alignment="center" />
          <Column width={500} dataField="criteria_name" caption={"Шалгуур үзүүлэлт"} alignment="justify"/>
          <Column width={'auto'} alignment="center" dataField="criteria_level" caption={"Хамрах түвшин"} />
          <Column width={'auto'} alignment="center" dataField="criteria_type" caption={"Төрөл"} />
          <Column width={'auto'} alignment="center" dataField="value_type" caption={"Суурь үзүүлэлт"} />
          <Column width={'auto'} alignment="center" dataField="direction" caption={"Чиглэл"} />
          <Column width={'auto'} dataField="frequence" caption={"Давтамж"} />
          <Column width={'auto'} dataField="base_indicator" caption={"Суурь үзүүлэлт"} />
          <Column width={300} dataField="information_source" caption={"Эх сурвалж"} />
          <Column width={'auto'} dataField="evaluation_method" caption={"Тооцоолох\\арга"} />
          <Column width={'auto'} dataField="description" caption={"Тэмдэглэл"} />
          <Column dataField="created_date" caption={"Бүртгэсэн\\огноо"} dataType="date" format="yyyy-MM-dd" alignment="center" width={'auto'} />
          <Column dataField="user_name" caption={"Бүртгэсэн хэрэглэгч"} width={'auto'} />
        </DataGrid>
        :
        <EmptyData />
    );
  };

  return cntGovPro ? (
    <React.StrictMode>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ margin: 5 }}>
          <BackButton onClick={() => history.goBack()} />
        </div>
        <div className="dx-field" style={{ boxSizing: 'border-box', margin: 5 }}>
          <div className="col-12" >
            <div className="row" >
              <div className="col-6" >
                <div className="card" style={{ height: 300, padding: '1rem', marginLeft: 0, marginTop: 0 }}>
                  <div className="dx-fieldset" style={{ width: "100%" }}>
                    <div className="dx-field">
                      <FieldLabel className="dx-field-label">Төрөл:</FieldLabel>
                      <FieldValue className="dx-field-value">
                        {cntGovPro.project_type_name}
                      </FieldValue>
                    </div>
                    <div className="dx-field">
                      <FieldLabel className="dx-field-label">
                        Төслийн дугаар:
                      </FieldLabel>
                      <FieldValue className="dx-field-value">
                        {cntGovPro.project_code}
                      </FieldValue>
                    </div>
                    <div className="dx-field">
                      <FieldLabel className="dx-field-label">Төслийн нэр:</FieldLabel>
                      <FieldValue className="dx-field-value">
                        {cntGovPro.project_name}
                      </FieldValue>
                    </div>
                    <div className="dx-field">
                      <FieldLabel className="dx-field-label">Англи хэл:</FieldLabel>
                      <FieldValue className="dx-field-value">
                        {cntGovPro.project_englsih_name}
                      </FieldValue>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-6" >
                <div className="card" style={{ height: 300, padding: '1rem', marginTop: 0, marginRight: 0 }}>
                  <div className="dx-fieldset" style={{ width: "100%" }}>
                    <div className="dx-field">
                      <FieldLabel className="dx-field-label">Төлөв:</FieldLabel>
                      <FieldValue className="dx-field-value">
                        {cntGovPro.status === 1 ? 'Гэрээний шатанд байгаа' : (cntGovPro.status === 2 ? 'Хэрэгжиж байгаа' : (cntGovPro.status === 3 ? 'Хэрэгжиж дууссан' : (cntGovPro.status === 4 ? 'Хэрэгжиж эхэлсэн' : 'Хоосон')))}
                      </FieldValue>
                    </div>
                    <div className="dx-field">
                      <FieldLabel className="dx-field-label">Эхлэх огноо:</FieldLabel>
                      <FieldValue className="dx-field-value">
                        {cntGovPro.start_date.slice(0, 10)}
                      </FieldValue>
                    </div>
                    <div className="dx-field">
                      <FieldLabel className="dx-field-label">Дуусах огноо:</FieldLabel>
                      <FieldValue className="dx-field-value">
                        {cntGovPro.end_date}
                      </FieldValue>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12" >
            <div className="row" >
              <div className="col-6" >
                <div className="card scrollStyle" style={{ height: 300, overflowY: 'scroll', padding: '1rem', marginLeft: 0, marginBottom: 0 }}>
                  <div className="dx-fieldset" style={{ width: '90%' }}>
                    <div className="dx-field">

                      <div className="dx-field">
                        <FieldLabel className="dx-field-label">
                          Төслийн зорилго:
                        </FieldLabel>
                        <FieldValue className="dx-field-value">
                          {cntGovPro.project_objectives}
                        </FieldValue>
                      </div>
                      <div className="dx-field">
                        <FieldLabel className="dx-field-label">Үр дүн:</FieldLabel>
                        <FieldValue className="dx-field-value">
                          {cntGovPro.project_results}
                        </FieldValue>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
              <div className="col-6" >
                <div className="card" style={{ height: 300, padding: '1rem', marginRight: 0 }}>
                  <div className="dx-fieldset" style={{ width: "100%" }}>
                    <div className="dx-field">
                      <FieldLabel className="dx-field-label">
                        Хэрэгжүүлэгч байгууллага:
                      </FieldLabel>
                      <FieldValue className="dx-field-value">
                        {cntGovPro.customer_name}
                      </FieldValue>
                    </div>
                    <div className="dx-field">
                      <FieldLabel className="dx-field-label">
                        Хэрэгжих газар:
                      </FieldLabel>
                      <FieldValue className="dx-field-value">
                        {cntGovPro.implementation_place}
                      </FieldValue>
                    </div>
                    <div className="dx-field">
                      <FieldLabel className="dx-field-label">Салбар:</FieldLabel>
                      <FieldValue className="dx-field-value">
                        {cntGovPro.industry_name}
                      </FieldValue>
                    </div>
                    <div className="dx-field">
                      <FieldLabel className="dx-field-label">
                        Төсвийн ерөнхийлөн захирагч:
                      </FieldLabel>
                      <FieldValue className="dx-field-value">
                        {cntGovPro.user_name}
                      </FieldValue>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card col-12" style={{ padding: 10 }}>
          <div style={{ marginBottom: 10 }}>
            <CustomTabPanel>
              {data.map((e, i) => (
                <div
                  key={i}
                  className={`tab`}
                  style={{ fontWeight: 'bold', textAlign: 'center', backgroundColor: selectedItem === e.id && '#f2f6ff' }}
                  onClick={() => { setSelectedItem(e.id); handleListSelectionChange(e.id) }}
                >
                  {e.text}
                </div>
              ))}
            </CustomTabPanel>
          </div>

          {/* {selectedItem === 1 && renderEronhiiMedeelel()} */}
          {selectedItem === 1 && renderRemainingTabs()}
          {selectedItem === 2 && bottomComponentGrid()}
          {selectedItem === 3 && bottomToDoGrid()}
          {selectedItem === 4 && bottomContractorGrid()}
          {selectedItem === 5 && bottomCriteriaGrid()}

          <Paging enabled={true} />
        </div>
      </div>
    </React.StrictMode>
  ) : (
    <div>
      <h1>cannot access</h1>
    </div>
  );
};

export default GovProjectDetail;

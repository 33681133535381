import {useState, useContext} from 'react';
import {useHistory} from 'react-router-dom';
import AuthService from '../services/api/auth'
import {UserContext} from './UserContext';

export default function useAuth() {
  let history = useHistory();
  const {setUser} = useContext(UserContext);
  const [error, setError] = useState(null);

  //set user
  const setUserContext = async () => {
    AuthService.getProfile().then(res => {
      setUser(res.data);
      //console.log(res.data)
      history.push(res.data.username=== "02" ? '/budget' : (res.data?.roles?.[0]?.role_id == 1 || res.data?.roles?.[0]?.role_id == 0 ? '/generalInfo' : "/ministryPage"));
      
    }).catch(e => {
      console.log("e", e)
      setError(e.response.data);
    })
  }

  //register user
  const registerUser = async (data) => {
    const {username, email, password, passwordConfirm} = data;
    AuthService.registerUser({
      username,
      email,
      password,
      passwordConfirm
    })
      .then(async () => {
        await setUserContext();
      });
  };

  //login user
  const loginUser = async (data, isChecked, ipDetails) => {
    const { loginValue, password } = data;
    const { ip, city, country_code, country_name, region, longitude, latitude,offset,isp,mobile,proxy,hosting } = ipDetails;
    try {
      let res = await AuthService.login({loginValue, password, ip, city, country_code, country_name, region, longitude, latitude,offset,isp,mobile,proxy,hosting});
      localStorage.setItem("token", res.data.token);
      if (isChecked) {
        localStorage.setItem("LoginValue", loginValue )
      } else {
        if (localStorage.getItem("LoginValue"))
        {
          localStorage.removeItem("LoginValue")
        }
      }
      await setUserContext();
        let gom = await AuthService.loginForGoSmart( {loginValue, password} );
        localStorage.setItem("goToken", gom.data.token);
      return true;
    } catch (e) {
      return e.response.data.message
    }
    // console.log('res',res);
    // return res;
        // .then(async (res) => {
        //   localStorage.setItem("token", res.data.token)
        //   await setUserContext();
        //   console.log(res);
        //   return true;
        // }).catch(async e => {
        //   console.log(e.message)
        //   return e;
        // });
  };

  return {
    registerUser,
    loginUser,
    error
  }
}

import React, {useEffect, useState} from 'react';
import {nFormatter, percentPerformance} from "../../../util";
import _ from "lodash";
import Empty from '../components/empty';
import { ProgressBar } from 'devextreme-react/progress-bar';

function TopDisplayComponent(props) {
    //const style = props.colorType;
    const [confirmedList, setConfirmedList] = useState([]);
    const [confirmed, setConfirmed] = useState({});
    const [performance, setPerformance] = useState(0);
    const [percent, setPercent] = useState(0);
    //const popoverID = '#' + props.bodyID;

    useEffect(async () => {
        // console.log('props.data',props.data)
        let cd = _.filter(props.data, function (o) {
            return o.budget_status_id !== 7
        }) || [];

        let pe = _.filter(props.data, function (o) {
            return o.budget_status_id === 7
        }) || [];

        let o = cd[cd.length-1];

        let c = cd.length > 0 ? {...o, amount: o.amount*1} : {};
        let p = pe[0] ? pe[0].amount*1 : 0

        setConfirmedList(cd);
        setConfirmed(c);
        setPerformance(p);

        if (props.data.length > 0) {
            setPercent((p*100/c.amount).toFixed(1)*1);
        } else {
            setPercent(0);
        }

    },[props.data])

    /*const link = () => {
        const id = props.bodyID;
        return props.link(id);
    }

    const renderContent = () => {
        return (props.data[0] && props.data[0].law_info)
    };*/

    return (
        <div>
            {/*{props.data[0] && props.data[0].law_info &&*/}
            {/*    <Popover*/}
            {/*        width = {300}*/}
            {/*        showEvent="dxhoverstart"*/}
            {/*        hideEvent="dxhoverend"*/}
            {/*        target = {popoverID}*/}
            {/*        position = 'top'*/}
            {/*        contentRender={renderContent}*/}
            {/*    />*/}
            {/*}*/}

            <div className={'card-header'}>
                <span>{props.title}</span>
            </div>

            <div
                className='card-body'
                // style={cardBodyStyle}
            >
                <div className='row' id={props.bodyID}>{props.data.length === 0 ? <Empty color={props.textColor} height={props.height || 300} /> :
                    <div className={'col-12 ' + props.textColor} style={{textAlign: 'center', display: 'flex', flexDirection: 'column', flex: 1}}>
                        <div className={'row'} style={{alignItems: 'center', justifyContent: 'center', display: 'flex', flex: 1}}>
                            <div style={{position: 'absolute', top: 10, left: 10}}>
                                <ul style={{
                                    marginLeft: 30,
                                    fontSize: 14,
                                    listStyleType: 'decimal',
                                    color: '#7E909A'
                                }}>
                                    {confirmedList.map((v, i) => {
                                        return <li key={i}>{v.budget_status_name} - <span className={'dark-blue-text'}>{nFormatter(v.amount, 1)}</span></li>
                                    })}
                                </ul>
                            </div>
                            <div style={{height: props.height-80, display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
                                <div style={{fontSize: 40}}>
                                    {nFormatter(confirmed.amount, 2)}
                                </div>
                                <div>
                                    <ProgressBar
                                        id="top-data"
                                        // className={this.state.seconds === 0 ? 'complete' : '' }
                                        width="250"
                                        min={0}
                                        max={100}
                                        statusFormat={(value) => {
                                                return `Гүйцэтгэл: ${nFormatter(value * 100)}%`;
                                            }
                                        }
                                        value={percentPerformance(performance, confirmed.amount) || 0}
                                    />
                                </div>
                                {/*<div style={{color: '#7E909A'}}>*/}
                                {/*    <span>Гүйцэтгэл</span>*/}
                                {/*    <span>{nFormatter(performance, 2)}</span>*/}
                                {/*</div>*/}
                            </div>
                            {/*<CircularGauge*/}
                            {/*    id="triangleMarker"*/}
                            {/*    value={percent}*/}
                            {/*    subvalues={[percent]}*/}
                            {/*    style={{width: '50%', height: props.height-80 || 300}}*/}
                            {/*    // size={{height: 200}}*/}
                            {/*>*/}
                            {/*    <SubvalueIndicator*/}
                            {/*        type="triangleMarker"*/}
                            {/*        color={props.color}*/}
                            {/*    />*/}
                            {/*    <Geometry*/}
                            {/*        startAngle={180}*/}
                            {/*        endAngle={0}>*/}
                            {/*    </Geometry>*/}
                            {/*    <RangeContainer>*/}
                            {/*        <Range startValue={0} endValue={percent} color={props.color} />*/}
                            {/*    </RangeContainer>*/}
                            {/*    <Scale*/}
                            {/*        startValue={0}*/}
                            {/*        endValue={100}*/}
                            {/*        tickInterval={0}*/}
                            {/*    >*/}
                            {/*    </Scale>*/}
                            {/*</CircularGauge>*/}
                        </div>
                        <div className={'row'} style={{height: 70}}>
                            <div className={"col-6 " + props.textColor} style={{textAlign: 'center', marginTop: 10, fontWeight: 400}}>
                                <div style={{fontSize: 20}} className={props.textColor}>
                                    {nFormatter(confirmed.amount, 2)}
                                </div>
                                <div style={{fontSize: 15, color: '#7E909A'}}>
                                    {confirmed.budget_status_name}
                                </div>
                            </div>
                            <div className={"col-6 " + props.textColor} style={{textAlign: 'center', marginTop: 10, fontWeight: 400}}>
                                <div style={{fontSize: 20, color: '#EA6A47'}}>
                                    {nFormatter(performance, 2)}/{percentPerformance(performance, confirmed.amount) || 0}%
                                </div>
                                <div style={{fontSize: 15, color: '#7E909A'}}>
                                    Гүйцэтгэл
                                </div>
                            </div>
                        </div>
                    </div>}
                </div>
            </div>
        </div>
    );
};

export default TopDisplayComponent;

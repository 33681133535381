import React, {useState, useEffect} from "react";
import FinanceReportService from "../../../../services/api/financeReport";
import notify from "devextreme/ui/notify";
import {
    numberWithCommas,
    paletteEhUusver,
    paletteOrlogo,
    palettePayrollReport
} from "../../../../util";
import {useHistory} from "react-router-dom";
import LoadPanelComponent from "../../../budget/components/loadPanel";

const FinanceReportHeaderInfo = (props) => {
    const history = useHistory();
    const [horizontalData, setHorizontalData] = useState({});
    const [loader, setLoader] = useState(false);
    const loadHorizontalData = async () => {
        try {
            setLoader(true);
            let r = await FinanceReportService.getHeaderMiniData(props.year, props.reportType,props.selectedOrg, props.filterOrgText);
            setHorizontalData(r[0]);
            setLoader(false);
        } catch (e) {
            setLoader(false);
            notify(e.message, 'error', 2000);
        }
    }

    const locationHandler = () => {
        if (props.locationZarlaga) {
            history.push(props.locationZarlaga)
        }
    }

    useEffect(() => {
        loadHorizontalData();
    }, [props.year, props.reportType, props.filterOrgText, props.selectedOrg]);


    return (
        <div id="load5" style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', width: "100%"}}>
            <LoadPanelComponent position={{of: '#load5'}} visible={loader}/>

            {horizontalData.prev_amt !==undefined && horizontalData.prev_amt !== 0 && <div className="card" style={{
                flex: 1,
                backgroundImage: `linear-gradient(to right, ${paletteOrlogo[0]}, ${paletteOrlogo[1]})`,
                boxShadow: `0 0 5px ${paletteOrlogo[0]}`
            }} onClick={locationHandler}>
                <div className="card-header" style={econ2Styles.textAligner}><span
                    style={{color: '#fff', textAlign: "center"}}>{horizontalData.prev_amt_caption} /сая ₮/</span></div>
                <div className="card-body"
                     style={{...econ2Styles.textAlignerSpan, ...econ2Styles.numericFontSize}}><span
                    style={{color: '#fff'}}>{numberWithCommas(horizontalData.prev_amt)}</span>
                </div>
            </div>
            }
            {horizontalData.current_amt !==undefined && horizontalData.current_amt !== 0 && <div className="card" style={{
                flex: 1,
                backgroundImage: `linear-gradient(to right, ${paletteEhUusver[0]}, ${paletteEhUusver[1]})`,
                boxShadow: `0 0 5px ${paletteEhUusver[0]}`
            }} onClick={locationHandler}>
                <div className="card-header" style={econ2Styles.textAligner}><span
                    style={{color: '#fff', textAlign: "center"}}>{horizontalData.current_amt_caption} /сая ₮/</span></div>
                <div className="card-body"
                     style={{...econ2Styles.textAlignerSpan, ...econ2Styles.numericFontSize}}><span
                    style={{color: '#fff'}}>{numberWithCommas(horizontalData.current_amt)}</span>
                </div>
            </div>
            }
           {/* {horizontalData.prev_gov_capital_amt !==undefined && horizontalData.prev_gov_capital_amt !== 0 && <div className="card" style={{
                flex: 1,
                backgroundImage: `linear-gradient(to right, ${paletteZarlaga[0]}, ${paletteZarlaga[1]})`,
                boxShadow: `0 0 5px ${paletteZarlaga[0]}`
            }} onClick={locationHandler}>
                <div className="card-header" style={econ2Styles.textAligner}><span
                    style={{color: '#fff', textAlign: "center" ,fontSize: 9}}>{horizontalData.prev_gov_capital_amt_caption} /сая ₮/</span></div>
                <div className="card-body"
                     style={{...econ2Styles.textAlignerSpan, ...econ2Styles.numericFontSize}}><span
                    style={{color: '#fff'}}>{numberWithCommas(horizontalData.prev_gov_capital_amt)}</span>
                </div>
            </div>
            }
            {horizontalData.current_gov_capital_amt !==undefined && horizontalData.current_gov_capital_amt !== 0 && <div className="card" style={{
                flex: 1,
                backgroundImage: `linear-gradient(to right, ${paletteZarlaga[0]}, ${paletteZarlaga[1]})`,
                boxShadow: `0 0 5px ${paletteZarlaga[0]}`
            }} onClick={locationHandler}>
                <div className="card-header" style={econ2Styles.textAligner}><span
                    style={{color: '#fff', textAlign: "center",fontSize: 9}}>{horizontalData.current_gov_capital_amt_caption} /сая ₮/</span></div>
                <div className="card-body"
                     style={{...econ2Styles.textAlignerSpan, ...econ2Styles.numericFontSize}}><span
                    style={{color: '#fff'}}>{numberWithCommas(horizontalData.current_gov_capital_amt)}</span>
                </div>
            </div>
            }
            {horizontalData.prev_assess_amt !==undefined && horizontalData.prev_assess_amt !== 0 && <div className="card" style={{
                flex: 1,
                backgroundImage: `linear-gradient(to right, ${paletteZarlaga[0]}, ${paletteZarlaga[1]})`,
                boxShadow: `0 0 5px ${paletteZarlaga[0]}`
            }} onClick={locationHandler}>
                <div className="card-header" style={econ2Styles.textAligner}><span
                    style={{color: '#fff', textAlign: "center",fontSize: 9}}>{horizontalData.prev_assess_amt_caption} /сая ₮/</span></div>
                <div className="card-body"
                     style={{...econ2Styles.textAlignerSpan, ...econ2Styles.numericFontSize}}><span
                    style={{color: '#fff'}}>{numberWithCommas(horizontalData.prev_assess_amt)}</span>
                </div>
            </div>
            }
            {horizontalData.current_assess_amt !==undefined && horizontalData.current_assess_amt !== 0 && <div className="card" style={{
                flex: 1,
                backgroundImage: `linear-gradient(to right, ${paletteZarlaga[0]}, ${paletteZarlaga[1]})`,
                boxShadow: `0 0 5px ${paletteZarlaga[0]}`
            }} onClick={locationHandler}>
                <div className="card-header" style={econ2Styles.textAligner}><span
                    style={{color: '#fff', textAlign: "center",fontSize: 9}}>{horizontalData.current_assess_amt_caption} /сая ₮/</span></div>
                <div className="card-body"
                     style={{...econ2Styles.textAlignerSpan, ...econ2Styles.numericFontSize}}><span
                    style={{color: '#fff'}}>{numberWithCommas(horizontalData.current_assess_amt)}</span>
                </div>
            </div>
            }
            {horizontalData.prev_accum_amt !==undefined && horizontalData.prev_accum_amt !== 0 && <div className="card" style={{
                flex: 1,
                backgroundImage: `linear-gradient(to right, ${paletteZarlaga[0]}, ${paletteZarlaga[1]})`,
                boxShadow: `0 0 5px ${paletteZarlaga[0]}`
            }} onClick={locationHandler}>
                <div className="card-header" style={econ2Styles.textAligner}><span
                    style={{color: '#fff', textAlign: "center",fontSize: 9}}>{horizontalData.prev_accum_amt_caption} /сая ₮/</span></div>
                <div className="card-body"
                     style={{...econ2Styles.textAlignerSpan, ...econ2Styles.numericFontSize}}><span
                    style={{color: '#fff'}}>{numberWithCommas(horizontalData.prev_accum_amt)}</span>
                </div>
            </div>
            }
            {horizontalData.current_accum_amt !==undefined && horizontalData.current_accum_amt !== 0 && <div className="card" style={{
                flex: 1,
                backgroundImage: `linear-gradient(to right, ${paletteZarlaga[0]}, ${paletteZarlaga[1]})`,
                boxShadow: `0 0 5px ${paletteZarlaga[0]}`
            }} onClick={locationHandler}>
                <div className="card-header" style={econ2Styles.textAligner}><span
                    style={{color: '#fff', textAlign: "center",fontSize: 9}}>{horizontalData.current_accum_amt_caption} /сая ₮/</span></div>
                <div className="card-body"
                     style={{...econ2Styles.textAlignerSpan, ...econ2Styles.numericFontSize}}><span
                    style={{color: '#fff'}}>{numberWithCommas(horizontalData.current_accum_amt)}</span>
                </div>
            </div>
            }*/}
            {horizontalData.prev_gov_total_amt !==undefined && horizontalData.prev_gov_total_amt !== 0 && <div className="card" style={{
                flex: 1,
                backgroundImage: `linear-gradient(to right, ${paletteOrlogo[0]}, ${paletteOrlogo[1]})`,
                boxShadow: `0 0 5px ${paletteOrlogo[0]}`
            }} onClick={locationHandler}>
                <div className="card-header" style={econ2Styles.textAligner}><span
                    style={{color: '#fff', textAlign: "center"}}>{horizontalData.prev_gov_total_amt_caption} /сая ₮/</span></div>
                <div className="card-body"
                     style={{...econ2Styles.textAlignerSpan, ...econ2Styles.numericFontSize}}><span
                    style={{color: '#fff'}}>{numberWithCommas(horizontalData.prev_gov_total_amt)}</span>
                </div>
            </div>
            }
            {horizontalData.current_gov_total_amt !==undefined && horizontalData.current_gov_total_amt !== 0 && <div className="card" style={{
                flex: 1,
                backgroundImage: `linear-gradient(to right, ${palettePayrollReport[0]}, ${palettePayrollReport[1]})`,
                boxShadow: `0 0 5px ${palettePayrollReport[0]}`
            }} onClick={locationHandler}>
                <div className="card-header" style={econ2Styles.textAligner}><span
                    style={{color: '#fff', textAlign: "center"}}>{horizontalData.current_gov_total_amt_caption} /сая ₮/</span></div>
                <div className="card-body"
                     style={{...econ2Styles.textAlignerSpan, ...econ2Styles.numericFontSize}}><span
                    style={{color: '#fff'}}>{numberWithCommas(horizontalData.current_gov_total_amt)}</span>
                </div>
            </div>
            }
        </div>
    )
}

const econ2Styles = {
    textAligner: {
        justifyContent: 'center',
        display: 'flex',
        gap: "5%",
        fontWeight: 700,

    },
    textAlignerSpan: {
        justifyContent: 'space-evenly',
        display: 'flex',
        fontWeight: "700",
    },
    numericFontSize: {
        fontSize: '1rem'
    }
}

export default FinanceReportHeaderInfo

/*eslint-disable*/
import React, {useEffect, useRef} from "react";
import 'devextreme-react/text-area';
import {number} from "prop-types";
import ReactToPrint from "react-to-print";
import Button from "devextreme-react/button";

const Fa02 = (props) => {
    const data = props.reportData
    const componentRef = useRef();
    const docDate = data.docdate
    const orgName = data.organization_model.name
    const docno = data.docnum

    useEffect(() => {
        console.log("props.dataaa", props.data)
    }, [props.data])
    const style = {
        text: {
            color: "#2E4765", fontWeight: "700", fontSize: ".875rem"
        },
        box: {
            border: "none", backgroundColor: "#F6F6F6", borderRadius: ".5rem", padding: ".25rem .15rem", width: "100%"
        },
        box2: {
            border: "none",
            backgroundColor: "rgb(239, 239, 239)",
            borderRadius: ".5rem",
            padding: ".25rem .4rem",
            width: "100%"
        }
    }


    return (
        <div>

            <div className="row" style={{display: 'flex', marginTop: 30,}}>

                <Button type=""
                        icon='save'
                        className="saveButton" onClick={() => props.setReportShow(false)}> Буцах </Button>

                <ReactToPrint
                    trigger={() => <Button
                        type="default"
                        icon='save'
                        className="saveButton">Хэвлэх</Button>}
                    content={() =>
                        componentRef.current}
                />
            </div>


            <div ref={componentRef}
                 style={{display: "flex", justifyContent: "space-evenly", padding: "1.5rem", flexDirection: "column",}}>
                <div className="row" style={{display: 'flex', justifyContent: "space-between", alignItems: "center",}}>
                    <div style={{marginLeft: 10, marginRight: 10}}>
                        <div style={{display: 'flex', alignItems: 'left'}}>НХМаягт ҮХ-2</div>
                    </div>

                    <div style={{display: 'flex', fontSize: '40', alignItems: 'Left'}}>ҮНДСЭН ХӨРӨНГИЙН ӨРГӨТГӨЛ,
                        САЙЖРУУЛАЛТ, ИХ ЗАСВАРЫГ ХҮЛЭЭН АВАХ БАРИМТ №:
                    </div>
                    {/*ШИЛЖҮҮЛЭХ*/}
                    <div className='col-2' style={{marginLeft: 5,}}>
                        <div style={{display: 'flex', ...style.text}}>{docno}</div>
                    </div>

                </div>


                <div className="row" style={{display: 'flex', marginTop: "1rem"}}>
                    <div className='col-5'
                         style={{marginLeft: 5, marginRight: 10, display: "flex", justifyContent: "center"}}>
                        <div style={{display: 'flex', ...style.text}}>  {orgName}  </div>
                    </div>

                    <div className='col-5' style={{marginRight: 5, display: "flex", justifyContent: "space-evenly"}}>
                        <div style={{display: 'flex', fontSize: '20', alignItems: 'Right'}}>Огноо:</div>
                        <div style={{display: 'flex', ...style.text}}>{docDate}</div>
                    </div>
                </div>


                <div className="row" style={{display: 'flex', marginTop: 10,}}>
                    <div className='col-5'>
                        <div style={{display: 'flex', fontSize: '20', marginLeft: 10, alignItems: 'Right'}}>1. Цех,
                            тасаг,
                            хэсгийн нэр
                        </div>
                    </div>
                    <div className='col-4' style={{display: "flex", justifyContent: "center"}}>
                        <div style={{display: 'flex', ...style.text}}>{data.user_dept}</div>
                    </div>

                </div>

                <div className="row" style={{display: 'flex', marginTop: 10,}}>
                    <div className='col-5'>
                        <div style={{display: 'flex', fontSize: '20', marginLeft: 10, alignItems: 'Right'}}>2. Хөрөнгийн
                            нэр, төрөл, код
                        </div>
                    </div>
                    <div className='col-4' style={{display: "flex", justifyContent: "center"}}>
                        <div
                            style={{display: 'flex', ...style.text}}>{data.fa_name} ,{data.typename} ,{data.fatype} </div>
                    </div>

                </div>

                <div className="row" style={{display: 'flex', marginTop: 10,}}>
                    <div className='col-5'>
                        <div style={{display: 'flex', fontSize: '20', marginLeft: 10, alignItems: 'Right'}}>3.
                            Бүртгэлийн
                            дугаар
                        </div>
                    </div>
                    <div className='col-5' style={{display: "flex", justifyContent: "center"}}>
                        <div style={{display: 'flex', ...style.text}}>{data.fanumber}</div>
                    </div>

                </div>


                <div className="row" style={{display: 'flex', marginTop: 10,}}>
                    <div className='col-5'>
                        <div style={{display: 'flex', fontSize: '20', marginLeft: 10, alignItems: 'Right'}}> 4. Анхны
                            өртөг
                            (худалдан авсан үнэ)
                        </div>
                    </div>
                    <div className='col-5' style={{display: "flex", justifyContent: "center"}}>
                        <div style={{display: 'flex', ...style.text}}>{data.quantity * data.cost_org}</div>
                    </div>

                </div>


                <div className="row" style={{display: 'flex', marginTop: 10,}}>
                    <div className='col-10'>
                        <div style={{display: 'flex', fontSize: '20', marginLeft: 10, alignItems: 'Right'}}>5. 20 ...
                            оны ....-р сарын .... ны өдрөөс 20 ... оны ..... р сарын ....ны өдөр хүртэл ..........хоног
                        </div>

                    </div>


                </div>

                <div className="row" style={{display: 'flex', marginTop: 10,}}>
                    <div className='col-10'>
                        <div style={{display: 'flex', fontSize: '20', marginLeft: 10, alignItems: 'Right'}}> төлөвлөгөөт
                            (төлөвлөгөөт бус) их засвар (өргөтгөл, сайжруулалт)-ыг хийв.
                        </div>

                    </div>
                </div>
                <div className="row" style={{display: 'flex', marginTop: 10,}}>
                    <div className='col-10'>
                        <div style={{display: 'flex', fontSize: '20', marginLeft: 10, alignItems: 'Right'}}>
                            6. Энэ ажлыг хийсэн тухай тэмдэглэл (хэрэв бүрэн биш хийсэн бол шалтгааныг тодорхой бичнэ)
                        </div>
                    </div>
                </div>


                <div className="col-12" style={{
                    display: 'flex',
                    marginTop: 1,
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: 'center',
                    gap: 10
                }}>
                    <div style={{display: 'flex', ...style.text}}>

                    </div>

                    <div style={{display: 'flex'}}> . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
                        . .
                        . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
                    </div>
                    <div style={{display: 'flex'}}> . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
                        . .
                        . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
                    </div>
                    <div style={{display: 'flex'}}> . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
                        . .
                        . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
                    </div>
                </div>


                <div className="row" style={{display: 'flex', marginTop: 10,}}>
                    <div className='col-11'>
                        <div style={{display: 'flex', fontSize: '20', marginLeft: 10, alignItems: 'Right'}}>
                            7. өргөтгөл, сайжруулалт, их засвар хийсний дараа 20 .... оны .... сарын .... ны өдрөөс 20
                            ...
                        </div>
                    </div>

                </div>
                <div className="row" style={{display: 'flex', marginTop: 10,}}>
                    <div className='col-11'>
                        <div style={{display: 'flex', fontSize: '20', marginLeft: 10, alignItems: 'Right'}}>
                            оны .... сарын ...өдөр хүртэл туршилт хийж 20 .... оны ....сарын .... ны өдөр ашиглалтад
                            хүлээлгэн өгөв.
                        </div>
                    </div>

                </div>


                <div className="row" style={{display: 'flex', marginTop: 10,}}>
                    <div className='col-5'>
                        <div style={{display: 'flex', fontSize: '20', marginLeft: 10, alignItems: 'Right'}}>
                            1-3
                        </div>
                    </div>
                    <div className='col-6'>
                        <div style={{display: 'flex', fontSize: '20', marginLeft: 10}}>
                            Капиталчлагдах зардлын дүн
                        </div>
                    </div>
                </div>


                <div className="row" style={{display: 'flex', marginTop: 10,}}>
                    <div className='col-5'>
                        <div style={{display: 'flex', fontSize: '20', marginLeft: 10, alignItems: 'Right',...style.text}}>

                        </div>
                    </div>

                    <div className='col-2'>
                        <div style={{display: 'flex', fontSize: '20', marginLeft: 10, alignItems: 'Right'}}>
                            өргөтгөл
                        </div>
                    </div>
                    <div className='col-2'>
                        <div style={{display: 'flex', fontSize: '20', marginLeft: 10, alignItems: 'Right'}}>
                            сайжруулалт
                        </div>
                    </div>

                    <div className='col-2'>
                        <div style={{display: 'flex', fontSize: '20', marginLeft: 10, alignItems: 'Right'}}>
                            их засвар
                        </div>
                    </div>

                </div>



                <div className="row" style={{display: 'flex', marginTop: 10,}}>
                    <div className='col-3'>
                        <div style={{display: 'flex', fontSize: '20', marginLeft: 10, alignItems: 'Right'}}>
                            Гүйцэтгэсэн ажил
                        </div>
                    </div>
                    <div className='col-2'>
                        <div style={{display: 'flex', fontSize: '20', marginLeft: 10}}>
                            төлөвлөгөө
                        </div>
                    </div>
                </div>


                <div className="row" style={{display: 'flex', marginTop: 10,}}>
                    <div className='col-3'>
                        <div style={{display: 'flex', fontSize: '20', marginLeft: 10, alignItems: 'Right',...style.text}}>
                            {data.descr}
                        </div>
                    </div>
                    <div className='col-2'>
                        <div style={{display: 'flex', fontSize: '20', marginLeft: 10}}>
                            гүйцэтгэл
                        </div>
                    </div>
                    <div className='col-2'>
                        <div style={{display: 'flex', fontSize: '20', marginLeft: 10, alignItems: 'Right',...style.text}}>
                            { data.trtype==1  ?  data.cost_cap.toLocaleString():" "  }
                        </div>
                    </div>
                    <div className='col-2'>
                        <div style={{display: 'flex', fontSize: '20', marginLeft: 10, alignItems: 'Right',...style.text}}>
                            { data.trtype==2  ?  data.cost_cap.toLocaleString():" "  }
                        </div>
                    </div>

                    <div className='col-2'>
                        <div style={{display: 'flex', fontSize: '20', marginLeft: 10, alignItems: 'Right',...style.text}}>
                            { data.trtype==3  ?  data.cost_cap.toLocaleString():" "  }
                        </div>
                    </div>

                </div>

                <div className="row" style={{display: 'flex', marginTop: 1,}}>
                    <div className="row" style={{display: 'flex', marginLeft: 10, marginTop: 1,}}>Хүлээлгэн өгсөн:       <div
                        className='col-10'>
                    </div>
                    </div>
                </div>
                <div className="row" style={{display: 'flex', marginTop: 10,}}>
                    <div className='col-2'>
                        <div style={{display: 'flex', fontSize: '20', marginLeft: 10, alignItems: 'Right'}}></div>
                    </div>
                    <div className='col-3'>
                        <div style={{
                            display: 'flex',
                            fontSize: '20',
                            marginLeft: 10,
                            alignItems: 'Right'
                        }}>----------------------
                        </div>
                    </div>
                    <div className='col-3'>
                        <div style={{
                            display: 'flex',
                            fontSize: '20',
                            marginLeft: 10,
                            alignItems: 'Right'
                        }}>----------------------
                        </div>
                    </div>
                    <div className='col-3'>
                        <div style={{
                            display: 'flex',
                            fontSize: '20',
                            marginLeft: 10,
                            alignItems: 'Right'
                        }}>----------------------
                        </div>
                    </div>
                </div>
                <div className="row" style={{display: 'flex', marginTop: 1,}}>
                    <div className='col-2'>
                        <div style={{display: 'flex', fontSize: '20', marginLeft: 10, alignItems: 'Right'}}></div>
                    </div>
                    <div className='col-3'>
                        <div style={{display: 'flex', fontSize: '20', marginLeft: 10, alignItems: 'Right'}}>( албан
                            тушаал )
                        </div>
                    </div>
                    <div className='col-3'>
                        <div style={{display: 'flex', fontSize: '20', marginLeft: 10, alignItems: 'Right'}}>( овог нэр
                            )
                        </div>
                    </div>
                    <div className='col-3'>
                        <div style={{display: 'flex', fontSize: '20', marginLeft: 10, alignItems: 'Right'}}>( гарын үсэг
                            )
                        </div>
                    </div>
                </div>

                <div className="row" style={{display: 'flex', marginTop: 1,}}>
                    <div className="row" style={{display: 'flex', marginTop: 5,}}>
                        <div className='col-10'>Хүлээн авсан:
                        </div>
                    </div>
                </div>

                <div className="row" style={{display: 'flex', marginTop: 10,}}>
                    <div className='col-2'>
                        <div style={{display: 'flex', fontSize: '20', marginLeft: 10, alignItems: 'Right'}}></div>
                    </div>
                    <div className='col-3'>
                        <div style={{
                            display: 'flex',
                            fontSize: '20',
                            marginLeft: 10,
                            alignItems: 'Right'
                        }}>----------------------
                        </div>
                    </div>
                    <div className='col-3'>
                        <div style={{
                            display: 'flex',
                            fontSize: '20',
                            marginLeft: 10,
                            alignItems: 'Right'
                        }}>----------------------
                        </div>
                    </div>
                    <div className='col-3'>
                        <div style={{
                            display: 'flex',
                            fontSize: '20',
                            marginLeft: 10,
                            alignItems: 'Right'
                        }}>----------------------
                        </div>

                    </div>


                </div>

                <div className="row" style={{display: 'flex', marginTop: 1, justifyContent: "center"}}>

                </div>
                <div className="row" style={{display: 'flex', marginTop: 1, justifyContent: "center"}}>
                    <div className="row" style={{display: 'flex', marginTop: 5,}}>
                        <div className='col-10'></div>
                    </div>
                </div>

                <div className="row" style={{display: 'flex', marginTop: 1, justifyContent: "center"}}>
                    <div className="row" style={{display: 'flex', marginTop: 5,}}>
                        <div className='col-10'></div>
                    </div>
                </div>



            <div className="row" style={{display: 'flex', marginTop: 1, justifyContent: "center"}}>
                <div className="row" style={{display: 'flex', marginTop: 5,}}>
                    <div className='col-10'></div>
                </div>
            </div>

            </div>


        </div>
    )
        ;
};

export default Fa02
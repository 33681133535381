import React, { useState, useEffect } from "react";
import notify from "devextreme/ui/notify";
// import { ArgumentAxis, Chart } from 'devextreme-react/chart';
import remainingService from "../../services/api/remainingService";
import DataGrid, {
    Column,
    FilterRow,
    Grouping,
    HeaderFilter,
    Lookup,
    Paging,
    Scrolling
} from 'devextreme-react/data-grid';
import { Button, SelectBox, Template } from "devextreme-react";
//import _ from "lodash";
import { useHistory } from "react-router-dom";
import PieChart, {
    Legend,
    Series,
    Label,
    Connector,
    Tooltip,
    Export
} from 'devextreme-react/pie-chart';
import { chartGradient, getYears, numberWithCommas } from "../../util";
import { CURRENT_YEAR } from "../../shared/constants";
import LoadPanelComponent from "../../components/LoadPanelComponent";
import foreignOrgs from '../../assets/imgs/foreignOrgs.png'
import projects from '../../assets/imgs/projectsIcon.png'
import EmptyData from "../budget/components/empty";
import lawIcon from '../../assets/imgs/laaawIcon.svg'

const GovProject = () => {
    const history = useHistory();
    const [govProjectData, setGovProjectData] = useState([]);
    const [pieChart1, setPieChart1] = useState([]);
    const [pieChart2, setPieChart2] = useState([]);
    const [projectStatusWithAmount, setProjectStatusWithAmount] = useState([])
    const [projectTypeWithAmount, setProjectTypeWithAmount] = useState([])
    //const [chartData, setChartData] = useState([]);
    const [investor, setInvestor] = useState('ALL')
    const [investors, setInvestors] = useState([])
    const [dataSource, setDataSource] = useState([])
    const [selectedYear, setSelectedYear] = useState(CURRENT_YEAR)
    const [loading, setLoading] = useState(false)
    const [loadingProjectType, setLoadingProjectType] = useState(false)
    const [loadingProjectStatus, setLoadingProjectStatus] = useState(false)
    const [loadingAgenda, setLoadingAgenda] = useState(false)
    const [loadingTotalAmount, setLoadingTotalAmount] =useState(false)
    const [loadingProjectData, setLoadingProjectData] = useState(false)
    const [totalInvestment, setTotalInvestment] = useState([])
    const [investorsCount, setInvestorsCount] = useState(0)
    const [totalProjects, setTotalProjects] = useState()
    const [projectStatus, setProjectStatus] = useState([])
    const [projectType, setProjectType] = useState([])
    const [orgs, setOrgs] = useState([])
    const [columns, setColumns] = useState([])

    const getInvestors = async () => {
        try {
            setLoading(true)
            const r = await remainingService.getGovProjectInvestors(selectedYear) 
            setInvestorsCount(r.length)
            setInvestors(r && r.length === 1 ? r : [
                {
                    text_name: 'БҮГД',
                    value_name: 'ALL',
                },
                ...r
            ])
            r && r.length > 1 ? setInvestor('ALL') : setInvestor(r.length > 0 && r[0].value_name)
            setLoading(false)
        } catch (err) {
            setLoading(false)
            notify(err, 'error', 2000);
        }
    }
    const getProjectStatus = async () => {
        try {
            setLoading(true)
            let r = await remainingService.getProjectStatus()
            setProjectStatus(r)
            setLoading(false)
        } catch (err) {
            setLoading(false)
            notify(err, 'error', 2000)
        }
    }

    const getProjectType = async () => {
        try {
            setLoading(true)
            let r = await remainingService.getProjectType()
            setProjectType(r)
            setLoading(false)
        } catch (err) {
            setLoading(false)
            notify(err, 'error', 2000)
        }
    }

    const getProjectData = async () => {
        try {
            setLoadingProjectData(true)
            const r = await remainingService.getProjectsByInvestors(selectedYear, investor)
            setDataSource(r.data)
            setLoadingProjectData(false)
        } catch (err) {
            setLoadingProjectData(false)
            notify(err, 'error', 2000)
        }
    }

    const getTotalAmountByCurrency = async ()=>{
        try{
            setLoadingTotalAmount(true)
            const r = await remainingService.getTotalAmountByCurrency(selectedYear, investor)
            const keyMapping = {
                budget_amount: 'Бүрэлдэхүүний төсөв',
                task_amount: 'Гэрээ байгуулсан дүн',
                component_amount: 'Үлдэгдэл'
              };
            const transformedData = {
                'Бүрэлдэхүүний төсөв': {},
                'Гэрээ байгуулсан дүн': {},
                'Үлдэгдэл': {}
              };
              
              r.data.forEach(item => {
                Object.keys(keyMapping).forEach(key => {
                  const newName = keyMapping[key];
                  transformedData[newName][item.code] = item[key].toLocaleString(undefined, {maximuFractionDigits: 2});
                });
              });
              const rowData = Object.entries(transformedData).map(([key, value]) => ({
                Type: key,
                ...value
              }));
              const convertedColumn = [
                { dataField: 'type', caption: 'Төрөл'},
                ...Object.keys(rowData.reduce((acc, cur) => {
                  Object.keys(cur).forEach(key => {
                    if (key !== 'type') acc[key] = true;
                  });
                  return acc;
                }, {})).map(currencyCode => ({
                  dataField: currencyCode,
                  caption: currencyCode
                }))
              ];
            setColumns(convertedColumn)
            setTotalInvestment(rowData)
            setLoadingTotalAmount(false)
        } catch(err){
            setLoadingTotalAmount(false)
            notify(err, 'error', 2000)
        }
    }

    const getProjectCountType = async () =>{
        try{
            setLoadingProjectType(true)
            let r = await remainingService.getProjectCountByType(selectedYear, investor)
            setProjectTypeWithAmount(r.r)
            setPieChart1(r.r1)
            setLoadingProjectType(false)
        }catch(err){
            setLoadingProjectType(false)
            notify(err, 'error', 2000)
        }
    }
    const getProjectCountStatus = async () =>{
        try{
            setLoadingProjectStatus(true)
            let r = await remainingService.getProjectCountByStatus(selectedYear, investor)
            setProjectStatusWithAmount(r.r)
            setPieChart2(r.r1)
            setLoadingProjectStatus(false)
        }catch(err){
            setLoadingProjectStatus(false)
            notify(err, 'error', 2000)
        }
    }

    const loadMainGovProject = async () => {
        try {
            setLoadingAgenda(true)
            let data = await remainingService.getGovProjects(selectedYear, investor);
            setTotalProjects(data.r.length)
            setGovProjectData(data.r);
            setOrgs(data.r1)
            setLoadingAgenda(false)
        } catch (e) {
            setLoadingAgenda(false)
            notify(e, 'error', 2000);
        }
    }
    useEffect(() => {
        getProjectStatus()
        getProjectType()
    }, [])

    useEffect(()=>{
        getInvestors()
    },[selectedYear])
    useEffect(() => {
        getTotalAmountByCurrency()
        getProjectData()
        getProjectCountType()
        getProjectCountStatus()
        loadMainGovProject()
    }, [selectedYear, investor])

    const detailClickEvent = (data) => {
        const location = {
            pathname: '/govProjectDetail',
            state: { currentGovProject: data }
        }
        history.push(location);
    }
    const statusStyles = {
        statusContainer: {
            width: '100%',
            color: 'white',
            borderRadius: '.25rem',
            // cursor: 'pointer',
            padding: '.4rem',
            textAlign: 'center',
            border: 'none'
        },
        ger: {
            backgroundColor: '#CDEBFF',
            color: 'black',
            fontWeight: 'bold'
        },
        her: {
            backgroundColor: '#EBF1FF',
            color:'#5575C2',
            fontWeight: 'bold'
        },
        herDuussan: {
            backgroundColor: '#E2FBEE',
            color:'#50C687',
            fontWeight: 'bold'
        },
        herEhelsen: {
            backgroundColor: '#FFEEDF',
            color:'#F99D4B',
            fontWeight: 'bold'
        },
    };

    const renderCustomTmp = (e) => {
        return (
            <Button icon="detailslayout" stylingMode={'outlined'} type={'default'} onClick={() => detailClickEvent(e.data)}
                style={{ ...statusStyles.statusContainer, display: 'flex', gap: '.5rem', alignItems: 'center', justifyContent: 'center' }}></Button>
        )
    }


    /*const cellRender = (data) => {
        return (
            <span>{data.data.project_code}</span>
        )
    }*/

    const customizeText = (arg) => {
        return `${arg.valueText} - ${(arg.percent * 100).toFixed(2)}%`;
    }

    const customizeTooltipStatus = (arg) => {
        const filteredValues = projectStatusWithAmount.filter(item => item.name === arg.argumentText);
    if (filteredValues.length > 0) {

        let tooltipHtml = `<div class="tooltip-header">
                            <span class="tooltip-series-name">${arg.argumentText}: </span> 
                            <span>${arg.value}</span>
                            <span>(${(arg.percent * 100).toFixed(2)}%)</span>
                            </div>
                            <hr/>
                           `;
        filteredValues.forEach((matchedItem, index) => {
            if(filteredValues[index].code !== null || filteredValues[index].sum_budget !== '0' && filteredValues[index].sum_task !== '0' && filteredValues[index].sum_component !== '0'){
                tooltipHtml += `\n<span class='tooltip-header'>${filteredValues[index].code}:</span>
                              <span class='tooltip-series-name'>Бүрэлдэхүүний төсөв: </span> ${numberWithCommas(parseFloat(matchedItem.sum_budget))} <br/>
                              <span class='tooltip-series-name'>Гэрээ байгуулсан дүн: </span> ${numberWithCommas(parseFloat(matchedItem.sum_task))} <br/>
                              <span class='tooltip-series-name'>Үлдэгдэл: </span> ${numberWithCommas(parseFloat(matchedItem.sum_component))} <br/> <hr/>
                           `;
            } else {
                tooltipHtml += ``
            }
        });
        return {
            html: tooltipHtml
        };
    } else {
        return {
            html: `<span class="tooltip-series-name">${arg.argumentText}: </span>${arg.value} (${(arg.percent * 100).toFixed(2)}%)`
        };
    }
    }
    const customizeTooltipType = (arg) => {
        const filteredValues = projectTypeWithAmount.filter(item => item.name === arg.argumentText);
    if (filteredValues.length > 0) {

        let tooltipHtml = `<div class="tooltip-header">
                            <span class="tooltip-series-name">${arg.argumentText}: </span> 
                            <span>${arg.value}</span>
                            <span>(${(arg.percent * 100).toFixed(2)}%)</span>
                            </div>
                            <hr/>
                           `;
        filteredValues.forEach((matchedItem, index) => {
            if(filteredValues[index].code !== null || filteredValues[index].sum_budget !== '0' && filteredValues[index].sum_task !== '0' && filteredValues[index].sum_component !== '0'){
                tooltipHtml += `\n<span class='tooltip-header'>${filteredValues[index].code}:</span>
                              <span class='tooltip-series-name'>Бүрэлдэхүүний төсөв: </span> ${numberWithCommas(parseFloat(matchedItem.sum_budget))} <br/>
                              <span class='tooltip-series-name'>Гэрээ байгуулсан дүн: </span> ${numberWithCommas(parseFloat(matchedItem.sum_task))} <br/>
                              <span class='tooltip-series-name'>Үлдэгдэл: </span> ${numberWithCommas(parseFloat(matchedItem.sum_component))} <br/> <hr/>
                           `;
            } else {
                tooltipHtml += ``
            }
        });
        return {
            html: tooltipHtml
        };
    } else {
        return {
            html: `<span class="tooltip-series-name">${arg.argumentText}: </span>${arg.value} (${(arg.percent * 100).toFixed(2)}%)`
        };
    }
    }

    const centerRender = (pieChart) => {
        let totalValue = pieChart.getAllSeries()[0].getAllPoints().reduce((s, p) => s + p.originalValue, 0);
        return (
            <text textAnchor="middle" style={{ fontWeight: 600, fontFamily: 'Segoe UI', fontSize: 16 }}>
                <tspan x="0" dy="0">{totalValue} </tspan>
                <tspan x="0" dy="20">нийт </tspan>
            </text>
        );
    }

    const onCellPrepared = (e) => {
        let el = e.cellElement
        if (e.rowType === "header") {
            el.wordWrapEnabled = true
            el.setAttribute('style', `text-align: center; font-family: 'Segoe UI'; font-weight: bold; color: #15186A; background-color: #EEF0F4`)
        }
        if (e.rowType === "data") {
            el.style.fontFamily = "Segoe UI"
            el.style.fontWeight = "semibold"
            el.style.color = "#000000"
            el.style.verticalAlign = "middle";
        }
    }

    const onCellPreparedInv = (e) =>{
        let el = e.cellElement
        if (e.rowType === "header") {
            el.setAttribute('style', `text-align: right; font-family: 'Segoe UI'; font-weight: bold; color: #15186A`)
            if(e.columnIndex === 0){
                e.cellElement.innerHTML = '';
            }
        }
        if (e.rowType === "data") {
            el.style.fontFamily = "Segoe UI"
            el.style.fontWeight = "semibold"
            el.style.color = "#000000"
            el.style.verticalAlign = "middle";
            el.style.border = 'none'
            el.style.textAlign = 'right'
            if(e.columnIndex === 0) {
                el.style.textAlign = 'left'
                el.style.fontWeight = '700'
            }
            let val
            if(e.value){
                val = e?.value.replace(/,/g,'')
            }
            const numericValue = parseFloat(val);
            if (!isNaN(numericValue)) {
                const formattedValue = numericValue.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 });
                el.textContent = formattedValue;
                if(numericValue === 0) {
                    el.innerHTML = '-'
                }
            } else {
                el.textContent = e.value
            }
        }
    }
    const groupCell = (e) => {
        return <div style={{color: '#000', fontWeight: 700}}>{e.data.displayValue}</div>
    }

    return (
        <div >
            <LoadPanelComponent position={{ of: '#main' }} visible={loading} />
            <LoadPanelComponent position={{ of: '#projectData' }} visible={loadingProjectData} />
            <LoadPanelComponent position={{ of: '#projectType' }} visible={loadingProjectType} />
            <LoadPanelComponent position={{ of: '#projectStatus' }} visible={loadingProjectStatus} />
            <LoadPanelComponent position={{ of: '#projectAgenda' }} visible={loadingAgenda} />
            <LoadPanelComponent position={{ of: '#totalAmount' }} visible={loadingTotalAmount} />
            <div className="col-12" style={{ padding: 10 }} id="main">
                <div style={{ display: 'flex',justifyContent: 'space-between', alignItems:'center' }}>
                    <div style={{ display: 'flex',  alignItems:'baseline', gap: '1rem' }}>
                    <div>
                        <SelectBox
                            label="Төсвийн жил"
                            items={getYears(true)}
                            defaultValue={selectedYear}
                            onValueChanged={(e) => { setSelectedYear(e.value)}}
                            width="10rem"
                            placeholder="сонгох" style={{ borderRadius: 10 }} />
                    </div>
                    <div>
                        <SelectBox
                            items={investors}
                            label="Байгууллага"
                            value={investor}
                            disabled={investors && investors.length === 1 ? true : false}
                            valueExpr={'value_name'}
                            placeholder={investors && investors.length === 1 ? investor.text_name : 'БҮГД'}
                            displayExpr={'text_name'}
                            width={'auto'}
                            onValueChanged={(e) =>setInvestor(e.value) }
                            style={{ borderRadius: 10 }} />
                    </div>
                    <div style={{alignItems: "center", color: "#003696", fontWeight: 'bold', textTransform: 'uppercase'}}>
                        {selectedYear} оны гадаадын зээл тусламжийн төсөл, арга хэмжээ
                    </div>
                    </div>
                    <div style={{ height: 28.5 }}>
                            <Button
                                style={{ fontFamily: "Segoe UI", fontWeight: "bold" }}
                                icon={lawIcon}
                                id="lawIcon"
                                onClick={() => window.open('https://legalinfo.mn/mn/detail?lawId=11885', '_blank')}
                            />
                    </div>
                </div>
                
            </div>
            <div>

            </div>
            <div className={'row'}>
                <div id="totalAmount" style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <div className="card col-6" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px 10px', textAlign: 'center' }}>
                            <div className="card" style={{ padding: 10, width: '70px' }}>
                                <img src={foreignOrgs} alt="Олон улсын байгууллага" height={'40px'} width={'40px'} />
                            </div>
                            <span>Байгууллага</span>
                            <span style={{ fontSize: '1.5rem', fontWeight: 700 }}>{investor === 'ALL' ? investorsCount : investorsCount === 0 ? 0 : 1}</span>
                        </div>
                        <div className="card col-6" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px 10px', textAlign: 'center' }}>
                            <div className="card" style={{ padding: 10, width: '70px' }}>
                                <img src={projects} alt="төсөл" height={'40px'} width={'40px'} />
                            </div>
                            <span>Төсөл</span>
                            <span style={{ fontSize: '1.5rem', fontWeight: 700 }}>{totalProjects}</span>
                        </div>

                    </div>
                    <div style={{ margin: 5}}>
                        <div style={{ padding: 10, border: '1px solid #DEDEDE', borderRadius: 15, height: 165}}>

                        <DataGrid
                             noDataText="Мэдээлэл байхгүй байна"
                             dataSource={totalInvestment}
                             showBorders={false}
                             showColumnLines={false}
                             showRowLines={false}
                             onCellPrepared={onCellPreparedInv}
                             columnMinWidth={100}
                        >
                            <Scrolling columnRenderingMode="virtual"/>
                           {columns.map((item,key)=>{
                             <Column
                                key={key}
                                dataField={item.dataField}
                                minWidth={item.minWidth}
                                width={'auto'}
                                alignment={'right'}
                                caption={item.dataField === "type" ? '' : item.caption}
                           />
                           })}
                        </DataGrid>
                        </div>
                    </div>
                </div>
                <div className={'col-12 responsive-col'}>
                    <div className='card'>
                        <div className={'card-header'}>
                            <span style={{ fontWeight: "700" }}>1. САНХҮҮЖИЛТ БАЙГУУЛЛАГААР</span>
                        </div>
                        <div className="card-body" id="projectData">
                            {dataSource && dataSource.length !== 0 ?
                                <>
                                    <DataGrid
                                        noDataText="Мэдээлэл байхгүй байна"
                                        dataSource={dataSource}
                                        showBorders={true}
                                        wordWrapEnabled={true}
                                        showColumnLines={true}
                                        columnAutoWidth={true}
                                        showRowLines={true}
                                        onCellPrepared={onCellPrepared}
                                    >
                                        <Paging defaultPageSize={15} />
                                        <FilterRow visible={true} />
                                        <Grouping
                                            autoExpandAll={true}
                                            contextMenuEnabled={false}
                                            expandMode="rowClick"
                                            texts={{
                                                groupContinuesMessage: 'Дараагийн хуудас',
                                                groupContinuedMessage: 'Өмнөх хуудас'
                                            }}
                                        />
                                        <Column groupIndex={0} dataField="partner_name" caption="Санхүүжүүлэгч" groupCellComponent={groupCell} sortOrder='asc' />
                                        <Column
                                            dataField="total_budget"
                                            caption="Бүрэлдэхүүний төсөв"
                                            format="#,##0.00"
                                            alignment="right"
                                            cellRender={(e) => {
                                                return numberWithCommas(e.value)
                                            }}
                                        />
                                        <Column
                                            dataField="total_task_amount"
                                            caption="Гэрээ байгуулсан дүн"
                                            format="#,##0.00"
                                            alignment="right"

                                            cellRender={(e) => {
                                                return numberWithCommas(e.value)
                                            }}

                                        />
                                        <Column
                                            dataField="total_component_amount"
                                            caption="Үлдэгдэл"
                                            cellRender={(e) => {
                                                return numberWithCommas(e.value)
                                            }}
                                            format="#,##0.00"
                                            alignment="right"
                                        />
                                        <Column
                                            dataField="code"
                                            caption="Валют"
                                            alignment="center"
                                        />
                                    </DataGrid>
                            </>
                                :
                                <EmptyData />
                            }
                        </div>
                    </div>
                </div>
                
                <div className={'col-6 responsive-col'} id="projectType">
                    <div className='card'>
                        <div className={'card-header'}>
                            <span style={{ fontWeight: "700" }}>2. ТӨСЛИЙН ТӨРЛӨӨР</span>
                        </div>
                        <div className="card-body">
                            {pieChart1 && pieChart1.length === 0 ?

                                <EmptyData />
                                :
                                <PieChart
                                    id="pie"
                                    type="doughnut"
                                    palette={chartGradient}
                                    resolveLabelOverlapping="shift"
                                    dataSource={pieChart1}
                                    centerRender={centerRender}
                                    onLegendClick={({ points }) => points[0].isVisible() ? points[0].hide() : points[0].show()}
                                >
                                    <Export enabled={true}
                                    />
                                    <Legend
                                        orientation="horizontal"
                                        itemTextPosition="right"
                                        // margin={{ top: 150 }}
                                        horizontalAlignment="center"
                                        verticalAlignment="bottom"
                                        columnCount={3} />
                                    <Series argumentField="name"
                                        valueField="count">
                                        <Label visible={true} position="columns" customizeText={customizeText}>
                                            <Connector visible={true} />
                                        </Label>
                                    </Series>
                                    <Tooltip
                                        zIndex={1000}
                                        enabled={true}
                                        customizeTooltip={customizeTooltipType}
                                        font={{ color: '#fff' }}
                                        color="#555"
                                        border={{ visible: false }}
                                        location="edge"
                                    ></Tooltip>
                                </PieChart>
                            }
                        </div>
                    </div>
                </div>
                <div className={'col-6 responsive-col'} id="projectStatus">
                    <div className='card'>
                        <div className={'card-header'}>
                            <span style={{ fontWeight: "700" }}>3. ТӨСЛИЙН ТӨЛӨВӨӨР</span>
                        </div>
                        <div className="card-body">
                            {pieChart2 && pieChart2.length === 0 ?
                                <EmptyData />
                                :
                                <PieChart
                                    id="pie"
                                    type="doughnut"
                                    palette={chartGradient}
                                    dataSource={pieChart2}
                                    resolveLabelOverlapping="shift"
                                    centerRender={centerRender}
                                    onLegendClick={({ points }) => points[0].isVisible() ? points[0].hide() : points[0].show()}
                                >
                                    <Export enabled={true}/>
                                    <Legend
                                         orientation="horizontal"
                                         itemTextPosition="right"
                                         margin={{ top: 50 }}
                                         horizontalAlignment="center"
                                         verticalAlignment="bottom"
                                         columnCount={3} />
                                    <Series argumentField="name"
                                        valueField="count">
                                        <Label visible={true} position="columns" customizeText={customizeText}>
                                            <Connector visible={true} />
                                        </Label>
                                    </Series>
                                    <Tooltip
                                        zIndex={1000}
                                        enabled={true}
                                        customizeTooltip={customizeTooltipStatus}
                                        font={{ color: '#fff' }}
                                        color="#555"
                                        border={{ visible: false }}
                                        location="edge"
                                    ></Tooltip>
                                </PieChart>

                            }
                        </div>
                    </div>
                </div>

                <div className="col-12 responsive-col" id="projectAgenda">
                    <div className="card">
                        <div className="card-header">
                            <span style={{ fontWeight: "700" }}>4. ТӨСӨЛ ХӨТӨЛБӨРҮҮД</span>
                        </div>
                        <div className="card-body">
                            {govProjectData && govProjectData.length !== 0 ?
                                <DataGrid
                                    dataSource={govProjectData}
                                    wordWrapEnabled={true}
                                    showBorders={true}
                                    columnAutoWidth={true}
                                    hoverStateEnabled={true}
                                    allowColumnResizing={true}
                                    rowAlternationEnabled={true}
                                    showRowLines={true}
                                    onCellPrepared={onCellPrepared}
                                    paging={{
                                        pageSize: 5
                                    }}
                                >
                                    <FilterRow visible={true} />
                                    <HeaderFilter visible={true} search={true} texts={{ cancel: 'Болих', ok: 'Шүүх', emptyValue: 'Хоосон утга' }}/>
                                    <Column
                                        caption="№"
                                        alignment="center"
                                        allowSorting={false}
                                        allowFiltering={false}
                                        cellRender={(data) => {
                                            const adjustedIndex = data.rowIndex + 1 + data.component.pageIndex() * data.component.pageSize();
                                            return <span>{adjustedIndex}</span>;
                                        }}
                                    />
                                    <Column dataField="project_type_id" caption="Төслийн төрөл" allowFiltering={true}>
                                        <Lookup dataSource={projectType} valueExpr='id' displayExpr="name"/>
                                    </Column> 
                                    <Column allowFiltering={true} dataField="project_code" caption="Төслийн дугаар"  />
                                    <Column allowFiltering={true} dataField="project_name" caption="Төслийн нэр" />
                                    <Column allowFiltering={true} dataField="status" caption="Төлөв" cellRender={(data) => {
                                        const statusId = data.value
                                        let bgColor;
                                        let color
                                        switch (statusId) {
                                            case 1:
                                                bgColor = ' #CDEBFF'
                                                color = 'black'
                                                break
                                            case 2:
                                                bgColor = '#EBF1FF'
                                                color = '#5575C2'
                                                break
                                            case 3:
                                                bgColor = '#E2FBEE'
                                                color = '#50C687'
                                                break
                                            case 4:
                                                bgColor = '#FFEEDF'
                                                color = '#F99D4B'
                                                break
                                            case 5:
                                                bgColor = '#FFF2F2'
                                                color = '#F66160'
                                                break
                                            default:
                                                bgColor = ''
                                                break
                                        }
                                        return (
                                            <div style={{ textAlign: 'center', backgroundColor: bgColor, color: color, fontWeight: 'bold', padding: '5px', borderRadius: '5px' }}>{data.text}</div>
                                        )
                                    }}>
                                        <Lookup dataSource={projectStatus} valueExpr={'id'} displayExpr={'name'} />
                                    </Column>
                                    <Column allowFiltering={true} dataField="partner_ids" caption="Санхүүжүүлэгч байгууллага">
                                        <Lookup dataSource={orgs} valueExpr='id' displayExpr='name'/>
                                    </Column>
                                    <Column allowFiltering={true} dataField="customer_name" caption="Хэрэгжүүлэгч байгууллага" />
                                    <Column allowFiltering={true} dataField="start_date" caption="Эхлэх хугацаа" dataType="date" format="yyyy-MM-dd" />
                                    <Column allowFiltering={true} dataField="end_date" caption="Дуусах хугацаа" dataType="date" format="yyyy-MM-dd" />
                                    <Column allowFiltering={true} dataField="employee_names" caption="Хариуцсан албан хаагч" />                      
                                    <Column alignment="left" caption="Үйлдэл" cellTemplate="cmTmp" />
                                    <Template name="cmTmp" render={renderCustomTmp} />
                                </DataGrid>
                                :
                                <EmptyData />
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ padding: 10, display: 'flex', alignItems: 'center', justifyContent: 'center', boxShadow: '0px 0px 10px #DEDEDE', textAlign: 'center', gap: '10px', border: '1px solid #DEDEDE', borderRadius: '20px', margin: '5px' }}>
                <span style={{ color: "#2E4765", fontWeight: "bold" }}>Эх сурвалж:</span>
                <a style={{ fontWeight: 500 }} href='https://odamis.mof.gov.mn/login' rel="noreferrer" target='_blank'>ODAMIS</a>
            </div>
        </div>
    )
}

export default GovProject;

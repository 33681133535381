import React, {useEffect, useState,useContext} from 'react';
import {
    DataGrid,
    Column,
    RequiredRule,
    Lookup,
    Editing,
    Summary,
    TotalItem, GroupItem, Paging, HeaderFilter,
    SearchPanel, GroupPanel, Export
} from 'devextreme-react/data-grid';
import {Popup} from "devextreme-react/popup";
import fcsService from "../../../../services/api/fcs";
import _ from "lodash";
import {UserContext} from "../../../../hooks/UserContext";
import notify from "devextreme/ui/notify";
import {numberWithCommas} from "../../../../util";
import {Workbook} from "exceljs";
import {exportDataGrid} from "devextreme/excel_exporter";
import {saveAs} from "file-saver-es";
import {Selection} from "devextreme-react/tree-list";
import {Template} from "devextreme-react";
import Button from "devextreme-react/button";
import ScrollView from "devextreme-react/scroll-view";

import Inv01 from "../report/In01";
import LoadPanelComponent from "../../../../components/LoadPanelComponent";


const  InOrlineEdit = (props)=> {


        const  [dataSource,setDataSource]=useState([])

    const [dataFcsChart, setFcsChart] = useState([]);
    const [dataFcsCustOrgn, setFcsCustOrgn] = useState([]);
    const [dataFcsLocation, setFcsLocation] = useState([]);
    const [dataFcsMonth, setFcsMonth] = useState([]);
    const [yearValue, setYearValue] = useState(0);
    const [monthValue, setMonthValue] = useState(0);
    const [reportData,setReportData]=useState()
    const [reportShow,setReportShow]=useState(false)
    const [loader, setLoader] = useState(false);
    const userListPosition = {of: '#usersList'}
    const {user} = useContext(UserContext);

        useEffect( ()=> ( fcsInOrLinList())  ,[] )
    useEffect(() => {
        getYearMonth();
    }, [dataFcsMonth]);
    useEffect(() => {
        getInorlin();
    }, [monthValue]);

    const onExporting = (e) => {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Main sheet');

        exportDataGrid({
            component: e.component,
            worksheet,
            autoFilterEnabled: true,
        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer], {type: 'application/octet-stream'}),  'inor_'+yearValue+"_"+monthValue+'.xlsx'  );
            });
        });
        e.cancel = true;
    }
 const    fcsInOrLinList = async () => {
try {


     setLoader(true)

     const fcsChart = await fcsService.getAllFcsChart();
     setFcsChart(fcsChart);
     const fcsLocation = await fcsService.getAllFcsLocation();
     setFcsLocation(fcsLocation);
     const fcsCustOrgn = await fcsService.getAllFcsCustOrgn();
     setFcsCustOrgn(fcsCustOrgn);
     const fcsMonth = await fcsService.getAllFcsMonth('IN');
     setFcsMonth(fcsMonth);
     setLoader(false)
} catch (err) {
    setLoader(false)
    console.log(err)
}
 }

const  getInorlin = async  () => {

        if (Number(yearValue)>1990) {
            const     dataSource1= await fcsService.getAllFcsInOrLin(yearValue,monthValue)
            setDataSource(dataSource1)

        }

}
    const getYearMonth = () => {


        dataFcsMonth.map((item) => {
            setYearValue(item.year)
            setMonthValue(item.month)
        })
    }
  const   customAddRow = (grid) => {
        grid.current.instance.addRow();
    }

    const scheduleTransferDetailBtn = (options) => {
        return (
            <Button
                type="default"
                icon='save'
                className="saveButton" onClick={()=>printFa01(options.data) }>Харах</Button>
        )
    }
    const printFa01=(data)   =>{



        setReportShow(true)
        if (data.rs_number){

            let fcsInv = _.filter(dataSource, {rs_number: data.rs_number})

            setReportData(fcsInv)

            setReportShow(true)

        }




    }

 const    customizedSummary = (data) => {
        return ` ${numberWithCommas(data.value)}`
    }

 const   setIncomeTxnDetail = async (e) => {



        if (e.changes && e.changes.length !== 0) {
            e.changes.forEach(data => {
                //yyyymm
           //     console.log("dataaa",data)

                const date =new Date( data.key.date_recpt);
                const year = date.getFullYear() * 1e4; // 1e4 gives us the the other digits to be filled later, so 20210000.
                const month = (date.getMonth() + 1) * 100;
                const yyyymm = year + month // `+ ''` to convert to string from number, 202206
                const fcsCurrentMonth = dataFcsMonth.map((item) => {
                    let date = item.year + '/' + item.month
                    let datetimenew = new Date(date)
                    let year = datetimenew.getFullYear() * 1e4; // 1e4 gives us the the other digits to be filled later, so 20210000.
                    let month = (datetimenew.getMonth() + 1) * 100;
                    const yyyymm_month = year + month
                    return yyyymm_month
                });
                if (yyyymm === fcsCurrentMonth[0]) {
                    if (data.type === "update") {
                        try {



                            fcsService.editFcsInOrLin(data);
                            fcsInOrLinList();

                            notify('Амжилттай бараа материал гүйлгээ хадгалагдлаа', "success", 2000);

                        } catch (e) {
                            notify('Засварлалтыг хадгалах явцад алдаа гарлаа', "error", 2000);
                        }
                    } else if (data.type === "insert") {
                        try {
                            delete data.data.id;
                            delete data.data.updatedAt;
                            fcsService.addFcsInOrLin(data.data);
                            fcsInOrLinList();
                            notify('Амжилттай бараа материалын гүйлгээ бүртгэгдлээ', "success", 2000);
                        } catch (e) {
                            notify('Шинээр бараа материал үүсэх явцад алдаа гарлаа', "error", 2000);
                        }

                    } else if (data.type === "remove") {
                        try {
                            fcsService.removeFcsInOrLin(data.key.id);
                            fcsInOrLinList();
                            notify('Амжилттай устгагдлаа', "success", 2000);
                        } catch (e) {
                            notify('Устгах явцад алдаа гарлаа', "error", 2000);
                        }

                    }
                } else {
                    notify('Сонгосон огноо тухайн тайлант он сард биш байна', "error", 2000);
                }
            });
        }

    }



        return (
            <React.Fragment>

                <div className="col-12 row" style={{justifyContent: "space-between", gap: '2rem', padding: '5px 15px'}}>
                <span>
                    <div>Тайлант он {yearValue} сар {monthValue}</div>

                </span>
                    <span>
                   Бараа материалын орлогын гүйлгээ засварлах
                </span>
                </div>
                <LoadPanelComponent
                    shadingColor="rgba(0,0,0,0.4)"
                    position={userListPosition}
                    visible={loader}
                    message=''
                    showPane={false}
                />

                <Popup
                    width={500}
                    height={800}
                    minWidth={'80%'}
                    maxWidth={'100%'}
                    minHeight={'100%'}
                    onHiding={() => setReportShow(false)}
                    resizeEnabled={true}
                    dragEnabled={true}
                    showTitle={true}
                    hideOnOutsideClick={true}
                    visible={reportShow}

                    showCloseButton={true}

                    title="дэлгэрэнгүй" >
                    <ScrollView width='100%' height='100%'>
                        <div>

                            {reportData &&

                                <div>

                                    {
                                        <Inv01 setReportShow={setReportShow} reportData={reportData}   orgName={user.organization.name} />

                                    }

                                </div>

                            }



                        </div>
                    </ScrollView>
                </Popup>


                    <DataGrid
                    dataSource={dataSource}
              //      showBorders={true}
                    columnAutoWidth={true}
                    onSaved={setIncomeTxnDetail}
                    onExporting={onExporting}

                    >



                        >
                        <GroupPanel visible={true}/>
                        <Selection mode="single"/>
                        {/*<Grouping autoExpandAll={false}/>*/}




                        <Column

                            dataField="id"
                            caption="Дугаар"
                            allowEditing={false}
                            width={80}
                            alignment="center"
                            // editCellTemplate = {}
                            // cellRender = {customize}
                            cssClass="custom"
                            allowFiltering={true}
                        >
                        </Column>
                        <Column
                            cssClass="custom"
                            dataField="rs_number"
                            caption="Падаан №"
                            width={80}
                            alignment="center"
                            allowEditing={false}
                            allowCollapsing={false}
                            allowFiltering={true}
                            groupIndex={0}
                        >
                        </Column>
                        <Column
                            cssClass="custom"
                            dataField="date_recpt"
                            caption="Огноо"
                            allowEditing={true}
                            dataType="date"
                            width={80}
                            alignment="center"
                        >
                            <RequiredRule
                                message='Огноо оруулна уу'
                            />
                        </Column>

                        <Column
                            cssClass="custom"
                            dataField="loctn_id"
                            caption="Агуулах"
                            minWidth={120}
                            alignment="center"
                            allowEditing={true}
                            allowFiltering={true}
                        >
                            <RequiredRule
                                message='Агуулах оруулна уу'
                            />
                            <Lookup
                                valueExpr="id"
                                displayExpr={(item) => {
                                    return item && `${item.loctn_code} - ${item.loctn_name}`
                                }}
                                dataSource={dataFcsLocation}
                            />
                        </Column>

                        <Column
                        cssClass="custom"
                        dataField="stnumber"
                        caption="дотоод код "
                        allowEditing={true}
                        allowFiltering={true}
                    >
                        <RequiredRule message='Бараа оруулна уу'/>

                    </Column>
                    <Column
                        cssClass="custom"
                        dataField="stname"
                        caption="нэр"
                        allowEditing={false}
                        allowFiltering={true}
                    >
                        <RequiredRule
                            message='Бараа оруулна уу'
                        />

                    </Column>
                        <Column
                            cssClass="custom"
                            dataField="unit_name"
                            caption="х/нэгж"
                            allowEditing={false}
                            allowFiltering={true}
                        >
                            <RequiredRule
                                message='Бараа оруулна уу'
                            />

                        </Column>
                    <Column
                        cssClass="custom"
                        dataField="price_unit"
                        caption="Нэгж үнэ"
                        allowEditing={true}
               //         setCellValue={calculateAmt1}
                    >
                        <RequiredRule
                            message='Нэгж үнэ оруулна уу'
                        />
                    </Column>
                    <Column
                        cssClass="custom"
                        dataField="recpt_qty"
                        caption="Тоо"
                        allowEditing={true}
                      //  setCellValue={calculateAmt}
                    >
                        <RequiredRule
                            message='Тоо оруулна уу'
                        />
                    </Column>

                        <Column
                            cssClass="custom"
                            dataField="cost_allo"
                            caption="Зардал/хөнгөлөлт"
                            allowEditing={true}
                            //  setCellValue={calculateAmt}
                        >
                            <RequiredRule
                                message='Тоо оруулна уу'
                            />
                        </Column>



                        <Column
                        cssClass="custom"
                        dataField="amt"
                        caption="Нийт дүн"
                        allowEditing={false}
                    >
                    </Column>
                        <Column
                            cssClass="custom"
                            dataField="chart_id"
                            caption="Кредит данс"
                            minWidth={120}
                            alignment="center"
                            allowEditing={true}
                            allowFiltering={true}
                        >
                            <RequiredRule
                                message='Данс оруулна уу'
                            />
                            <Lookup
                                valueExpr="id"
                                displayExpr={(item) => {
                                    return item && `${item.acct} - ${item.descr}`
                                }}
                                dataSource={dataFcsChart}

                            />
                        </Column>
                        <Column
                            cssClass="custom"
                            dataField="custorgn_id"
                            caption="Xарилцагч код"
                            allowEditing={true}
                            minWidth={120}
                            alignment="center"
                            allowFiltering={true}
                        >
                            <RequiredRule
                                message='харилцагч код оруулна уу'
                            />
                            <Lookup
                                valueExpr="id"
                                displayExpr={(item) => {
                                    return item && `${item.orgcode} - ${item.name}`
                                }}
                                dataSource={dataFcsCustOrgn}

                            />
                        </Column>
                        <Column minWidth={90} alignment="center" caption="" cellTemplate="cc156"/>
                        <Template name="cc156" render={scheduleTransferDetailBtn}/>

                        <Paging enabled={true} defaultPageSize={15}/>
                    <HeaderFilter visible={true}/>
                    <SearchPanel visible={true}/>
                    <Editing
                        // mode="batch"
                        mode="row"
                        allowUpdating={false}
                        allowDeleting={true}
                        allowAdding={false}
                        useIcons={true}
                        texts={{
                            cancelAllChanges: 'Болих бүгд',
                            cancelRowChanges: 'Болих',
                            confirmDeleteMessage: 'Энэ бараа материалын орлогын гүйлгээ мэдээллийг устгах уу?',
                            confirmDeleteTitle: 'Баталгаажуулах',
                            deleteRow: 'Устгах',
                            editRow: 'Өөрчлөх',
                            saveAllChanges: 'Хадгалах бүгд',
                            saveRowChanges: 'Хадгалах',
                            undeleteRow: 'Буцаах',
                            validationCancelChanges: 'Баталгаажуулах болих',
                        }}
                    >
                        <Popup title="Бараа материалын орлогын гүйлгээ" showTitle={true} width={1000} height={440}/>

                    </Editing>
                    <Summary recalculateWhileEditing={true}>
                        < GroupItem column="recpt_qty"     showInGroupFooter={true}
                                    alignByColumn={true}  summaryType="sum" customizeText={customizedSummary}/>

                        <GroupItem column="amt" summaryType="sum"  showInGroupFooter={true}
                                   alignByColumn={true}  customizeText={customizedSummary}/>
                        <GroupItem column="cost_allo" summaryType="sum"  showInGroupFooter={true}
                                   alignByColumn={true}  customizeText={customizedSummary}/>


                        <TotalItem column="recpt_qty" summaryType="sum" customizeText={customizedSummary}/>
                        <TotalItem column="amt" summaryType="sum" customizeText={customizedSummary}/>
                    </Summary>
                        <Export enabled={true} allowExportSelectedData={false}/>
                </DataGrid>
            </React.Fragment>
        )


}

export default InOrlineEdit;

import React, { useState, useEffect } from "react";
import notify from "devextreme/ui/notify";
import BudgetService from "../../../services/api/budget";
import {
    Chart,
    Series,
    CommonSeriesSettings,
    ValueAxis,
    Label,
    Legend,
    Export,
    Tooltip,
    Crosshair, Point, ArgumentAxis, Strip, ScrollBar, ZoomAndPan
} from "devextreme-react/chart";
import { blueColorGradient, budgetPalettes, getStripValues, numberWithCommas, redColorGradient } from "../../../util";
import SelectBox from "devextreme-react/select-box";
import _ from "lodash";
import LoadPanelComponent from "../../../components/LoadPanelComponent";
import EmptyData from "../../budget/components/empty";

const UpdatedBudgetBarChart = (props) => {
    let palette = ["red", "green", "blue", "yellow", "orange", "cyan"];
    const mapperData = [
        { id: 1, valueField: 'e_mend_amount_bat', name: 'Батлагдсан - Эрүүл мэндийн даатгалын сангаас санхүүжих', stack: 'batlagdsan', color: 'red' },
        { id: 2, valueField: 'tosovt_amount_bat', name: 'Батлагдсан - Төсөв байгууллагын үйл ажиллагаанаас', stack: 'batlagdsan', color: 'blue' },
        { id: 3, valueField: 'tosovoos_amount_bat', name: 'Батлагдсан - Улсын төсвөөс санхүүжих', stack: 'batlagdsan', color: 'green' },
        { id: 4, valueField: 'e_mend_amount_guitsetgel', name: 'Гүйцэтгэл - Эрүүл мэндийн даатгалын сангаас санхүүжих', stack: 'guitsetgel', color: 'yellow' },
        { id: 5, valueField: 'tosovt_amount_guitsetgel', name: 'Гүйцэтгэл - Төсөв байгууллагын үйл ажиллагаанаас', stack: 'guitsetgel', color: 'orange' },
        { id: 6, valueField: 'tosovoos_amount_guitsetgel', name: 'Гүйцэтгэл - Улсын төсвөөс санхүүжих', stack: 'guitsetgel', color: 'cyan' },
    ]
    const [chartData, setChartData] = useState([]);
    const [mapper, setMapper] = useState(mapperData);
    const [loader, setLoader] = useState(true);
    const [selector, setSelector] = useState([
        {
            compareText: 'Эрүүл мэндийн даатгалын сангаас санхүүжих',
            ids: [1, 4],
        },
        {
            compareText: 'Улсын төсвөөс санхүүжих',
            ids: [3, 6]
        },
        {
            compareText: 'Төсөв байгууллагын үйл ажиллагаанаас',
            ids: [2, 5]
        },
        {
            compareText: 'Бүгд',
            ids: [1, 2, 3, 4, 5, 6]
        },
    ])

    const loadChartData = async () => {
        try {
            let r = await BudgetService.getTenYearsDataChart(props.currentType);
            // console.log("r and currentType==>", props.currentType,  r)
            setChartData(r);
        } catch (e) {
            notify(e.message, "error", 2000);
        } finally {
            setLoader(false)
        }
    }

    useEffect(() => {
        loadChartData();
    }, [props.currentType]);

    const renderLabel = (e) => {
        return `${e.percentText}`
    }

    const customizeTooltip = (arg) => {
        if (arg.seriesName === 'Төсвийн өөрчлөлт' || arg.seriesName === 'Төсөв') {
            let r = _.find(chartData, { 'budget_year': (arg.argument * 1 - 1).toString() });
            let q = ((arg.value - (r && r.todotgoson) > 0 ? arg.value / (r && r.todotgoson) : (r && r.todotgoson) / arg.value)).toFixed(2);
            let p = ((arg.value * 100 / (r && r.todotgoson)) - 100).toFixed(2);
            let d = (arg.value - (r && r.todotgoson));
            let f = d < 0 ? 'бага' : 'их';
            if (isFinite(q) && !isNaN(q)) {
                return {
                    html: `<div class="tooltip-header">
            <span>Төсөв ${arg.argumentText} он:</span>
            <span>${numberWithCommas(arg.value / 1000000)} </span>
            </div>
            <hr/>
            <b><i>Өмнөх жилээс</i></b><br/>
            <span class="tooltip-series-name">Зөрүү:</span> ${numberWithCommas(d / 1000000)}<br/> 
            <span class="tooltip-series-name">Хувь:</span> ${p}%<br/> 
            <span class="tooltip-series-name">Өөрчлөлт:</span> ${q} дахин ${f}`,
                }
            } else {
                return {
                    html: `<div class="tooltip-header">
          <span>Төсөв ${arg.argumentText} он:</span>
          <span>${numberWithCommas(arg.value / 1000000)} </span>
          </div>`,
                }
            }
        }
        else if (arg.seriesName === 'Гүйцэтгэлийн өөрчлөлт') {
            let r = _.find(chartData, { 'budget_year': (arg.argument * 1 - 1).toString() });
            let q = ((arg.value - (r && r.guitsetgel) > 0 ? arg.value / (r && r.guitsetgel) : (r && r.guitsetgel) / arg.value)).toFixed(2);
            let p = ((arg.value * 100 / (r && r.guitsetgel)) - 100).toFixed(2);
            let d = (arg.value - (r && r.guitsetgel));
            let f = d < 0 ? 'бага' : 'их';
            let r1 = _.find(chartData, { 'budget_year': (arg.argument * 1).toString() });
            let u = ((r1 && r1.todotgoson) - arg.value);
            let u1 = ((arg.value * 100 / (r1 && r1.todotgoson))).toFixed(2);
            if (isFinite(q) && !isNaN(q)) {
                return {
                    html: `<div class="tooltip-header">
                    <span>Гүйцэтгэл ${arg.argumentText} он:</span>
                    <span>${numberWithCommas(arg.value / 1000000)}</span>
                    </div>
                    <hr/>
                    <b><i>Төсвөөс </b></i><br/>
                    <span class="tooltip-series-name">Зөрүү: </span>${numberWithCommas(u / 1000000)} <br/>
                    <span class="tooltip-series-name" style={{marginBottom: 10}}>Xувь: </span>${u1}% <br/>
                    <hr/>
                    <b><i>Өмнөх жилээс </b></i><br/>
                    <span class="tooltip-series-name">Зөрүү: </span>${numberWithCommas(d / 1000000)} <br/>
                    <span class="tooltip-series-name">Хувь: </span>${p}% <br/>
                    <span class="tooltip-series-name">Өөрчлөлт: </span>${q} дахин ${f}<br/>`
                }
            } else {
                return {
                    html: `<div class="tooltip-header">
                    <span>Төсөв ${arg.argumentText} он:</span>
                    <span>${numberWithCommas(arg.value / 1000000)} </span>
                    </div>`
                }
            }
        } else if (arg.seriesName === "Гүйцэтгэл") {
            return {
                html: `<div class="tooltip-header">
              <span>Гүйцэтгэл ${arg.argumentText} он:</span>
              <span>${numberWithCommas(arg.value / 1000000)}</span>
              </div>
              <hr/>
              <b><i>Төсвөөс </b></i><br/>
              <span class="tooltip-series-name">Зөрүү: </span> ${numberWithCommas((arg.point.data.todotgoson - arg.value) / 1000000)}<br/> 
              <span class="tooltip-series-name">Хувь:</span> ${((arg.value / 1000000) / (arg.point.data.todotgoson / 1000000) * 100).toFixed(2)}%`
            }
        }
        else {
            return {
                html: `<div class="tooltip-header">
              <span>${arg.seriesName} ${arg.argumentText} он:</span>
              <span>${numberWithCommas(arg.value / 1000000)}</span>
              </div>
              <hr/>
              <b><i>Төсвөөс</b></i><br/>
              <span class="tooltip-series-name">Зөрүү:</span> ${numberWithCommas((arg.point.data.todotgoson - arg.value) / 1000000)}<br/>
              <span class="tooltip-series-name">Хувь: </span> ${(arg.percent * 100).toFixed(2)}% 
              `,
            }
        }
    }

    const customizeValueAxisLabel = (e) => {
        return `${e.value / 1000000000}`
    }

    const selectionChangeHandler = (e) => {
        let arr = [];
        e.value.ids.forEach(item => {
            arr.push(_.find(mapperData, { 'id': item }))
        })
        setMapper(arr);
    }

    if (chartData?.length === 0 && !loader) {
        return (<div style={{ display: "flex", justifyContent: "center", alignItems: "center", family: "Segoe UI" }}>
            <EmptyData />
        </div>)
    }

    function markerTemplate(item) {
        const color = item.series.isVisible() ? item.marker.fill : '#888';
        return (
            <svg>
                <rect x={0} y={0} width={12} height={12} fill={color}
                    rx={item.text.includes('өөрчлөлт') ? 6 : 0}
                    ry={item.text.includes('өөрчлөлт') ? 6 : 0}></rect>
            </svg>
        );
    }

    const handleLegend = (e) => {
        if (e.target.isVisible()) {
            e.target.hide();
        } else {
            e.target.show();
        }
    }

    function customizeColor(items) {
        if (props.currentType === 2) {
            if (items.seriesName === 'Гүйцэтгэл') {
                return { color: blueColorGradient[items.index] }
            } else if (items.seriesName === 'Төсөв') {
                return { color: redColorGradient[items.index] }
            } else if (items.seriesName === 'Төсвийн өөрчлөлт') {
                return { color: '#e12c31' }
            }
        }
    }

    return (
        <div style={{ height: '452px' }} id="load2">
            <LoadPanelComponent
                position={{ of: '#load2' }}
                visible={loader}
            />
            {props.currentType === 4 && <SelectBox
                placeholder="Сонгох"
                items={selector}
                displayExpr="compareText"
                onValueChanged={selectionChangeHandler}
            />}
            <Chart width="100%" dataSource={chartData} onLegendClick={handleLegend}
                customizePoint={customizeColor}
                palette={_.find(budgetPalettes, { 'id': props.currentType === 3 ? 4 : props.currentType }).color}>
                <CommonSeriesSettings
                    argumentField="budget_year"
                    type={props.currentType === 1 ? 'bar' : (props.currentType === 2 || props.currentType === 4 ? 'stackedBar' : 'bar')}
                    barOverlapGroup="zarlagaStack"
                    cornerRadius={6}
                >
                    <Label backgroundColor="none" visible={false} customizeText={renderLabel} font={{ family: "Segoe UI" }} />
                    <Point size={10} />
                </CommonSeriesSettings>
                <Crosshair enabled={true} label={true} />
                <ValueAxis valueType="numeric" allowDecimals={false}>
                    <Label format="#" customizeText={customizeValueAxisLabel} />
                </ValueAxis>
                <ArgumentAxis tickInterval={1} argumentType='numeric' visualRange={{ endValue: new Date().getFullYear() }}>
                    <Label format='#' argumentFormat={'format'} />
                    {getStripValues().map((e) => (
                        <Strip startValue={e.startValue} endValue={e.endValue} color="#f5f5f5" />
                    ))}
                </ArgumentAxis>
                <ScrollBar visible={true} width={5} opacity={0.5} />
                <ZoomAndPan argumentAxis="both" />
                {props.currentType === 1 && <Series valueField="todotgoson" name="Төсөв" stack="zarlagaStack" />}
                {props.currentType === 1 && <Series valueField="guitsetgel" name="Гүйцэтгэл" stack="zarlagaStack" barPadding="0.4" />}
                {props.currentType === 1 && <Series valueField="todotgoson" name="Төсвийн өөрчлөлт" visible={false} type="spline" color="#b83535" dashStyle="solid" />}

                {props.currentType === 2 && <Series valueField="amount" name="Төвлөрүүлэх орлого" />}
                {props.currentType === 2 && <Series valueField="amount" name="Өөрчлөлт" type="spline" dashStyle="solid" color="#b83535" />}
                {props.currentType === 4 && mapper.map((item, index) => (<Series key={index} valueField={item.valueField} name={item.name} stack={item.stack} />))}
                {props.currentType === 3 && <Series valueField="todotgoson" name="Төсөв" stack="zarlagaStack" />}
                {props.currentType === 3 && <Series valueField="guitsetgel" name="Гүйцэтгэл" stack="zarlagaStack" barPadding="0.4" />}
                {props.currentType === 3 && <Series valueField="todotgoson" name="Төсвийн өөрчлөлт" type="spline" color="#306ddb" dashStyle="solid" />}
                <Series valueField="guitsetgel" name="Гүйцэтгэлийн өөрчлөлт" type="spline" color="#264b96" dashStyle="solid" />
                <Legend visible={props.currentType === 1 ? true : (props.currentType === 4 ? false : true)} verticalAlignment="bottom" markerRender={markerTemplate} horizontalAlignment="center" font={{ family: "Segoe UI" }} />
                <Tooltip
                    zIndex={100000}
                    enabled={true}
                    customizeTooltip={customizeTooltip}
                    location="edge"
                    font={{ color: '#fff' }} color="#555"
                    cornerRadius={6}
                    border={{ visible: false }}
                />
            </Chart>
        </div>
    )
}

export default UpdatedBudgetBarChart;

import React, {useContext, useEffect, useState, useRef} from 'react';
import 'devextreme-react/text-area';
import DataGrid, {
    Editing,
    Pager,
    Paging,
    Column,
    FilterRow,
    RequiredRule,
    Lookup, Export, Form, Item,Popup
} from 'devextreme-react/data-grid';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver-es';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { LoadPanel } from "devextreme-react/load-panel";
import {UserContext} from "../../../hooks/UserContext";
import _, {concat} from "lodash";
import fcsService from "../../../services/api/fcs";
import Button from "devextreme-react/button";
import notify from "devextreme/ui/notify";
import LoadPanelComponent from "../../budget/components/loadPanel";

function InvCategoryReg(props) {
    const userListPosition = { of: '#usersList' }
    const {user} = useContext(UserContext);
    const [dataSource, setDataSource] = useState([]);
    const [loader, setLoader] = useState(false);
    const myBabyRef = useRef(null);
    const [dataFcsChart, setFcsChart] = useState([]);
    useEffect(() => {
        fcsInvCategoryList();
    }, [])

    const calculateAmt = (newData, value, currentRowData) => {

        if (Number(value) > 9) {
            newData.in_number = Number(value) * 100000000
            newData.category_no = value
        }
        currentRowData.fa_number = newData.fa_number
    }

    const fcsInvCategoryList = async () => {
        try {
            setLoader(true);
            const result = await fcsService.getAllFcsInvCategory();
            setDataSource(result)
            const fcsChart = await fcsService.getFcsChart('IN',0,0,0,0);
            setFcsChart(fcsChart);
            return setLoader(false)
        } catch (e) {
            console.error(e);
        }
    }
    const onEditorPreparing = (e) => {
        if (e.parentType === "dataRow" && e.dataField !== "password") {
            return;
        }
        if (e.row && e.row.isNewRow) {
            e.editorOptions.visible = true;
        }
        if (e.row && !e.row.isNewRow) {
            e.editorOptions.visible = false;
        }
    }
    const customAddRow = (grid) => {
        grid.current.instance.addRow();
    }
    const onInitNewRow = (e) => {
    }
    const setInvCategory = async (e) => {
        if (e.changes && e.changes.length !== 0 ) {


                if (e.changes[0].type === "update") {
                    try {
                        let r = _.filter(dataFcsChart, {id: e.changes[0].data.chart_id});
                        e.changes[0].data.updated_user_id = user.id;
                        e.changes[0].data.acct_code = r[0].acct;
                        await fcsService.editFcsInvCategory(e.changes[0].data);
                        await fcsInvCategoryList();
                        notify('Амжилттай хадгалагдлаа', "success", 2000);
                    } catch (e) {
                        notify('Засварлалтыг хадгалах явцад алдаа гарлаа', "error", 2000);
                    }
                } else if (e.changes[0].type === "insert") {
                    try {
                        delete e.changes[0].data.id;
                        let r = _.filter(dataFcsChart, {id: e.changes[0].data.chart_id});
                        e.changes[0].data.created_user_id = user.id;
                        e.changes[0].data.org_id = user.org_id;
                        e.changes[0].data.acct_code = r[0].acct
                        await fcsService.addFcsInvCategory(e.changes[0].data);
                        await fcsInvCategoryList();
                        notify('Амжилттай үүслээ', "success", 2000);
                    } catch (e) {
                        notify('Шинээр үүсэх явцад алдаа гарлаа', "error", 2000);
                    }

                }
                else if (e.changes[0].type === "remove") {
                    try {
                        await fcsService.removeFcsInvCategory(e.changes[0].key.id);
                        await fcsInvCategoryList();
                        notify('Амжилттай устгагдлаа', "success", 2000);
                    } catch (e) {
                        notify('Устгах явцад алдаа гарлаа', "error", 2000);
                    }

                }

        }

    }
    const onExporting = (e) => {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Main sheet');

        exportDataGrid({
            component: e.component,
            worksheet,
            autoFilterEnabled: true,
        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Purchase.xlsx');
            });
        });
        e.cancel = true;
    }
    const style={
        text: {
            color: "#2E4765", fontWeight: "600", fontSize: "18px"
        },
        box: {
            border: "none", backgroundColor: "#F6F6F6", borderRadius: ".5rem", padding: ".25rem .15rem", width: "100%"
        }
    }
    return (
        <div style={{padding: "0.5rem"}}>
            <LoadPanelComponent position={{of : '#password'}} visible={loader} />

            <div className="col-12 row" style={{justifyContent:"center", gap:'2rem', padding: '5px 15px'}}>
            <div> <h4 style={{marginLeft: 20, textTransform: "uppercase", marginTop: 5,...style.text}}>
                    Бараа материалын ангилалын бүртгэл
                    </h4>
                </div>
                {/* <Button  icon="add" text = "Нэмэх" onClick={() => customAddRow(myBabyRef)} width = {110} type = "default" style = {{alignment : "center", borderRadius: '7px'}}/> */}
            </div>
            <LoadPanelComponent position={userListPosition} visible={loader} />

            <div>
                <div className="card-body">
                    <DataGrid
                        onEditorPreparing={onEditorPreparing}
                        ref={myBabyRef}
                        dataSource={dataSource}
                        onSaved={setInvCategory}
                        keyExpr= {null}
                        onInitNewRow={onInitNewRow}
                        wordWrapEnabled={true}
                        allowColumnResizing={true}
                        onExporting={onExporting}>
                        onEditingStart={ e => {
                            console.log('qqqqqqqqqqqqqqqqq', e);
                        }}
                    >
                        <FilterRow
                            visible = {true}
                        />
                        <Pager
                            showPageSizeSelector={true}
                            // showInfo={true}
                            showNavigationButtons={true}
                        />
                        <Column
                            dataField="id"
                            caption="Дугаар"
                            allowEditing = {false}
                            // editCellTemplate = {}
                            width={60}
                            alignment="center"
                            // cellRender = {customize}
                            cssClass = "custom"
                            allowFiltering={true}
                        >
                        </Column>
                        <Column
                            dataField="organization_model.name"
                            caption="Байгууллага нэр"
                            allowEditing = {false}
                            minWidth={100}
                            alignment="center"
                            // editCellTemplate = {}
                            // cellRender = {customize}
                            cssClass = "custom"
                            allowFiltering={true}
                        >
                        </Column>
                        <Column
                            cssClass = "custom"
                            dataField="category_no"
                            caption="Ангилалын дугаар"
                            setCellValue={calculateAmt}
                            minWidth={100}
                            alignment="center"
                            allowEditing = {true}
                            allowFiltering={true}
                        >
                            <RequiredRule
                                message = 'Ангилалын дугаар оруулна уу'
                            />
                        </Column>
                        <Column
                            cssClass = "custom"
                            dataField="descriptn"
                            caption="Ангилалын нэр"
                            allowEditing = {true}
                            allowFiltering={true}
                        >
                            <RequiredRule
                                message = 'Ангилалын нэр оруулна уу'
                            />
                        </Column>

                        <Column
                            cssClass = "custom"
                            dataField="chart_id"
                            caption="Данс"
                            allowEditing = {true}
                            minWidth={100}
                            alignment="center"
                            allowFiltering={true}
                        >
                            <RequiredRule
                                message = 'Данс оруулна уу'
                            />
                            <Lookup
                                valueExpr="id"
                                displayExpr={(item) => {
                                    return item && `${item.acct} - ${item.descr}`
                                }}
                                dataSource={dataFcsChart}
                            />
                        </Column>
                        <Column
                            cssClass = "custom"
                            dataField="in_number"
                            caption="Дугаарлалт"
                            minWidth={100}
                            alignment="center"
                            allowEditing = {true}
                            allowFiltering={true}
                        >

                        </Column>
                        <Editing
                            mode="popup"
                            allowUpdating={true}
                            allowDeleting={true}
                            allowAdding = {true}
                            useIcons = {true}
                            texts={{
                                cancelAllChanges: 'Болих бүгд',
                                cancelRowChanges: 'Болих',
                                confirmDeleteMessage: 'Энэ ангилалын мэдээллийг устгах уу?',
                                confirmDeleteTitle: 'Баталгаажуулах',
                                deleteRow: 'Устгах',
                                editRow: 'Өөрчлөх',
                                saveAllChanges: 'Хадгалах бүгд',
                                saveRowChanges: 'Хадгалах',
                                undeleteRow: 'Буцаах',
                                validationCancelChanges: 'Баталгаажуулах болих',
                            }}
                        >
                            <Popup title="Бараа материалын ангилал" showTitle={true} width={800} height={250} />
                            <Form>
                                <Item itemType="group" colCount={2} colSpan={2}>

                                    <Item dataField="category_no" />
                                    <Item dataField="descriptn" />
                                    <Item dataField="chart_id" />
                                    <Item dataField="in_number" />
                                </Item>
                            </Form>
                        </Editing>
                        <Paging enabled={true} />
                        <Export enabled={true} allowExportSelectedData={false} />
                    </DataGrid>
                </div>
            </div>
        </div>
    )
}

export default InvCategoryReg;

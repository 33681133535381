import React from 'react'
import TblRender from './TblRender'

function StatisticLists({list, isOrgShown, orgId,  loading, setLoading, currentList, year, isLocal}) {
  return (
  <div>
        {list?.map((item, index) => (
                <TblRender
                  list={item}
                  isLocal={isLocal}
                  index={index}
                  key={index}
                  isOrgShown={isOrgShown}
                  orgId={orgId}
                  icon={item?.icon}
                  loading={loading}
                  year={year}
                  setLoading={setLoading}
                  currentList={currentList}
                />
            ))}
        </div>
    )
    }

export default StatisticLists
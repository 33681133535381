import React, {useState} from "react";
import TabPanel, { Item } from "devextreme-react/tab-panel";

// tab components
import AddNews from "../../news/component/addNews";
import FileManagerCustom from "../../../components/FileManager/FileManager";
import {BiNews} from 'react-icons/bi'
import {GoLaw} from 'react-icons/go'
import {FaRegFileWord} from 'react-icons/fa'
import {FaFolderOpen} from 'react-icons/fa'


import CrudGeneralInfo2 from "../../news/component/newAddNews";
import LawControl from "../../order/components/LawControl";
import OrganizationSelectComponent from "../../../components/OrganizationSelectComponent";
const iconStyle= {
  color: "#1C3BC2", fontWeight: "600", fontSize: "1.2rem", transform: "translateY(.2rem)", marginRight: ".25rem"
}
const customTitleRender=(item)=>{
  switch(item.title){
      case "Мэдээ, мэдээлэл":
      return(
          <span><BiNews style={iconStyle}/> {item.title} </span>
       )
       case "Хууль, эрх зүй":
          return(
              <span><GoLaw style={iconStyle}/> {item.title} </span>
           )
       case "Файлын сан":
          return(
              <span><FaRegFileWord  style={iconStyle}/> {item.title} </span>
          )
      case "Байгууллагын мэдээлэл":
              return(
                  <span><FaFolderOpen style={iconStyle}/> {item.title}</span>
               )
   default :
  }
 
}

const SettingsTab = () => {
    const [selectedData, setSelectedData] = useState({});
    return (
      <>
        <OrganizationSelectComponent
            selectedData={(d) => setSelectedData(d)}
            selectedYear={(d) => console.log(d)}
            showClearButton={false}
            showYear={false}
        />
        <div className="adminTab card">
            <TabPanel  itemTitleRender={customTitleRender}>
                <Item title="Мэдээ, мэдээлэл" icon="newspaper">
                <div>
                    <div className='card'>
                       <div className="card-body">
                       <AddNews />
                      </div>
                    </div>
                </div>

                </Item>
                <Item title="Хууль, эрх зүй" icon="newspaper">
                  {/*<UpdatedLawForIntro admin={true} />*/}
                    <LawControl selectedData={selectedData} />
                </Item>
                <Item title="Файлын сан">
                  <FileManagerCustom />
                </Item>
                {/* <Item title="Ерөнхий мэдээлэл удирдах">
                  <CrudGeneralInfo admin={true} />
                </Item> */}
                <Item title="Байгууллагын мэдээлэл">
                  <CrudGeneralInfo2 admin={true} />
                </Item>
            </TabPanel>
        </div>
      </>
    );
};

export default SettingsTab;

import request from '../../shared/lib/request'

const getOrgPosition = () => {
    return request({
        url: '/orgPosition/getOrgPosition',
        method: 'GET',
    })
}
const getStateStuctureView = () => {
    return request({
        url: '/orgPosition/getStateStuctureView',
        method: 'GET',
    })
}
const getOrganizationList = (type,year) => {
    return request({
        url: `/orgPosition/getOrganizationList?type=${type}&year=${year}`,
    })
}
const addOrgPosition = (body) => {
    return request({
        url: '/orgPosition/addOrgPosition',
        method: 'POST',
        data: body,
    })
}
const editOrgPosition = (body) => {
    return request({
        url: `/orgPosition/editOrgPosition`,
        method: 'POST',
        data: body,
    })
}
const removeOrgPosition = (id) => {
    return request({
        url: '/orgPosition/removeOrgPosition/'+id,
        method: 'POST',
    })
}
const submitPortal = (data) => {
    return request({
        url: '/portal/submitForum',
        method: 'POST', 
        data: data
    })
}
const getOrgListByParent = () =>{
    return request({
        url: '/orgPosition/getOrgListByParent',
        method: 'GET'
    })

}
const orgPositionServices = {
    getOrgPosition,
    addOrgPosition,
    editOrgPosition,
    removeOrgPosition,
    getStateStuctureView,
    getOrganizationList,
    submitPortal,
    getOrgListByParent
}

export default orgPositionServices;
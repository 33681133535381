import request from '../../shared/lib/request'

const getDocuments = () => {
    return request({
        url: '/document/',
        method: 'GET'
    })
}

const getByIdDocument = (id) => {
    return request({
        url: `/document/${id}`,
        method: 'GET'
    })
}

const addDocument = (body) => {
    return request({
        url: '/document/',
        method: 'POST',
        data: body
    })
}

const updateDocument = (id, body) => {
    return request({
        url: `/document/${id}`,
        method: 'PUT',
        data: body
    })
}

const removeDocument = (id) => {
    return request({
        url: `/document/${id}`,
        method: 'DELETE'
    })
}

const documentService = {
    getDocuments,
    getByIdDocument,
    addDocument,
    updateDocument,
    removeDocument
}

export default documentService;
import Profile from "../../../assets/imgs/profile_icon.png";

export default function Conversation(props) {
    let profileSrc = Profile;
    if (props.url && props.conversation?.file_name) {
        profileSrc = props.url + props.conversation?.file_name;
    }

    return (
        <div className='chat-user' style={{display: 'flex', flexDirection: 'row', alignItems: 'center', fontWeight: (props.conversation?.isSeen || props.conversation?.sender === props.currentUser?.id) ? 'normal' : 'bold'}}>
            <div>
                <img className='logo' style={{objectFit: 'cover', borderRadius: '50%'}} src={profileSrc} alt=""/>
            </div>
            <div style={{display: 'flex', position: 'relative', marginLeft: 10, flex: 1}}>
                <div style={{flex: 1}}>
                    <span className='name' style={{fontWeight: '550', fontSize: 14, color: '#555' }}>{ props.conversation?.last_name && props.conversation?.last_name?.charAt(0)?.toUpperCase() + '. '}{props.conversation?.first_name}</span>
                    <span className="orgNameFormat" style={{fontSize: 12, color: 'gray', fontWeight: 'normal'}}>{props.conversation.organization_name}</span>
                </div>
            </div>
        </div>
    )
}
import { ScrollView } from 'devextreme-react'
import React from 'react'

export const TermsCondition = () => {
    return (
        <ScrollView showScrollbar='always'>
            <div className='terms-condition'>
                <div>
                    <b>OPENGOV ТӨСВИЙН НЭГДСЭН СИСТЕМИЙН ҮЙЛЧИЛГЭЭНИЙ НӨХЦӨЛ</b>
                </div>
                <p>Энэхүү систем нь “Даталанд технологи” ХХК-ийн хөгжүүлдэг төсвийн ажиллагааг цахимжуулсан төсвийн нэгдсэн систем юм.<br />
                    Системд тусгай эрхээр нэвтрэх эрх бүхий албан тушаалтан байгууллагын төсвийн болон дотоод ажиллагааг цахимаар удирдах бөгөөд <br />Нийтийн мэдээллийн ил тод байдлын тухай хуульд заасан байнга ил тод, нээлттэй байх мэдээллийг OpenWeb-ээр дамжуулан нийтэд мэдээлнэ.  </p>
                <div>
                    <b>Нэг. Нэр томьёоны тодорхойлолт</b>
                    <div>
                        <p>1.1. “OpenGov” нь цаасан суурьтай төсвийн ажиллагааг цахимжуулсан төсвийн нэгдсэн систем юм.</p>
                        <p>1.2. “Openweb” нь Нийтэд байнга ил тод, нээлттэй байх мэдээллийг иргэдэд мэдээлэх нэгдсэн портал /вэбсайт/ юм.</p>
                        <p>1.3. “Хэрэглэгч” нь Бүх шатны төсвийн захирагч нар, Монгол улсын иргэд юм.</p>
                    </div>
                </div>
                <div>
                    <b>Хоёр. Төсвийн нэгдсэн систем /OpenGov/-ийн хэрэглэгч</b>
                    <div>
                        <p>2.1. Бүх шатны төсвийн захирагч нар</p>
                        <p>2.2. Монгол улсын иргэд</p>
                    </div>
                </div>
                <div>
                    <b>Гурав. Төсвийн нэгдсэн систем /OpenGov/-д нэвтрэх</b>
                    <div>
                        <p>3.1. Хэрэглэгч системд дараах байдлаар нэвтрэх боломжтой. Байгууллагын бүх ажилтан төсвийн нэгдсэн системд нэвтрэх эрхгүй бөгөөд байгууллагын удирдлага, санхүүгийн хэлтсийн дарга болон нягтлан бодогч, админ эрхээр мэдээлэл удирдах эрх бүхий албан тушаалтан системд хандах эрхтэй.</p>
                        <p>3.2. Системд ашиглагдах нэр, нууц үгийн нууцлал аюулгүй байдлыг хэрэглэгч хариуцна.</p>
                        <p>3.3. Системд нэвтрэх эрх, нууц үг зэргийг бусдад хууль бус зорилгоор дамжуулах, ашиглуулахыг хориглоно.</p>
                        <p>3.4. Системд нэвтрэх эрх, нууц үгээ бусдад алдсан бол нууц үг солих хэсэгт хандан даруй солих эсвэл системийн зүүн доод булан байрлах хөгжүүлэгч компанийн утас, мэйл хаягт хандан мэдэгдэх үүрэгтэй.</p>
                        <p>3.5. Хэрэглэгч өөрийн бүртгэл, мэдээллийн аюулгүй байдлыг хамгаалах үүднээс аливаа ажиллагааг хийж дууссан бол системээс гарах шаардлагатай. Хэрэглэгчийн өөрийн буруутай үйл ажиллагаанаас болж мэдээллийн аюулгүй байдалд учирсан аливаа эрсдэл, хохирлыг OpenGov систем хөгжүүлэгч компани хариуцахгүй.</p>
                        <p>3.6. Иргэд, олон нийт нийтэд байнга ил тод, нээлттэй байх мэдээллийг OpenWeb нэгдсэн портал /вэбсайт/-аас цаг алдалгүй авах боломжтой.</p>
                    </div>
                </div>
                <div>
                    <b>Дөрөв. Өгөгдөл, мэдээлэл</b>
                    <div>
                        <p>4.1. Системд мэдээлэл байршуулах,  байршсан мэдээлэлд хандсан байдал, өөрчлөлтийн түүх, мэдээллийн бүрэн бүтэн, үнэн зөв зэргийг хэрэглэгч өөрөө шалган баталгаажуулах боломжийг бүрдүүлнэ.</p>
                        <p>4.2. Байгууллагын алдаатай, дутуу, буруу оруулсан дата мэдээллээс шалтгаалсан үр дагавар үүссэн нөхцөлд “Даталанд технологи” ХХК хариуцлага хүлээхгүй.</p>
                        <p>4.3. Лицензийн хугацаа дууссан, гэрээ сунгагдаагүйгээс шалтгаалан системд нэвтрэх эрхийг түр хаах нь  системд үүссэн дата, мэдээллийг устгах үндэслэл болохгүй.</p>
                        <p>4.4. Системд үүссэн түүхэн биг дата мэдээлэл цахим архив хэлбэрээр хадгалагдана.</p>
                    </div>
                </div>
                <div>
                    <b>Тав. Хэрэглэгчийн эрх </b>
                    <div>
                        <p>5.1. Хэрэглэгч өөрийн байгууллагын мэдээллийг OpenGov систем хэрхэн ашиглаж байгаа талаар мэдээлэл гаргуулж авах эрхтэй.</p>
                        <p>5.2. Хэрэглэгч системийн үр дүнгийн талаар мэдээлэл авах эрхтэй.</p>
                        <p>5.3. Мэдээллийн нууцлал, аюулгүй байдал, найдвартай ажиллагааны талаар санал, гомдол гаргах эрхтэй.</p>
                    </div>
                </div>
                <div>
                    <b>Зургаа. OpenGov системийн эрх, үүрэг</b>
                    <div>
                        <p>6.1. OpenGov системийн хөгжүүлэлт, найдвартай , тасралтгүй ажиллагаа, мэдээллийн аюулгүй байдлыг хангах үүрэгтэй.</p>
                        <p>6.2. OpenGov системийн өгөгдлийн сангийн хэвийн ажиллагааг хангах үүрэгтэй. </p>
                        <p>6.3. Байгууллагын хэрэгжүүлэх төрийн чиг үүрэг, иргэд олон нийтэд нээлттэй мэдээлэх нэгдсэн вэбсайтыг хөгжүүлж нэвтрүүлэх үүрэгтэй.</p>
                    </div>
                </div>
                <div>
                    <b>Долоо. Мэдэгдэл</b>
                    <div>
                        <p>7.1. Төсвийн тухай хуулийн хүрээнд төсвийн санал боловсруулах, хуваарийн санал гаргах зэрэг мэдэгдлийг хуульд заасан хугацаанд системийн нүүр хэсэгт болон баруун дээд талд байрлах мэдэгдэл буланд харуулна.</p>
                        <p>7.2. Байгууллага, албан хаагчтай холбоотой санал хүсэлтийг баруун дээд талд байрлах мэдэгдэл буланд харуулна.</p>
                        <p>7.3. Салбарт болон  байгууллагат хамааралгүй мэдэгдэл хүлээн авсан тохиолдолд info@dataland.mn хаягт мэдэгдэх шаардлагатай.</p>
                        <p>7.4. Санал, хүсэлтийн шийдвэрлэлт хүсэлт гаргагчийн мэдээлэл хэсэгт бөглөсөн и-мэйл хаягт автоматаар илгээгдэнэ.</p>
                    </div>
                </div>
                <div>
                    <b>Найм. Хууль зөрчсөн, хориглосон хэрэглээ</b>
                    <div>
                        <p>8.1. Системд болон дэд цэсүүдэд халдах, хортой код илгээхийг оролдох, гэмтэл, саатал учруулах, ажиллагааг доголдуулах  зорилгоор ашиглахыг хориглоно. </p>
                        <p>8.2. Админ хэрэглэгчийн эрхээр мэдээлэл удирдахдаа хууль зөрчсөн, зөрчихийг уриалсан агуулга бүхий  мэдээ, мэдээлэл, уриалгыг Openweb-д нийтлэх, мэдэгдэл илгээхийг хориглоно.</p>
                        <p>8.3. Бусад хэрэглэгчийн эрхээр системд нэвтрэх, мэдээлэл удирдахыг хориглоно.</p>
                    </div>
                </div>
                <div>
                    <b>Ес. Систем хөгжүүлэгч компанийн үйлчилгээний нөхцөл</b>
                    <div>
                        <p>9.1. Хэрэглэгч байгууллагын гаргасан шийдвэрт OpenGov систем хариуцлага хүлээхгүй.</p>
                        <p>9.2. Батлагдсан төсвийн хуваарьт өөрчлөлт оруулах хүсэлтийн шийдвэрлэлт нь систем хөгжүүлэгч компанийн эрхлэх асуудал биш тул шийдвэртэй холбоотой хариуцлага хүлээхгүй.</p>
                        <p>9.3. Цар тахал, байгалийн давагдашгүй хүчин зүйл, нийтээр дагаж мөрдөх аливаа хуулийн зохицуулалт, нийтийг хамарсан халдлага, газар хөдлөлттэй холбоотой үүссэн тасалдал, доголдолд OpenGov систем нь хариуцлага хүлээх үндэслэл болохгүй.</p>
                    </div>
                </div>
                <div>
                    <b>Арав. Системийн зохиогчийн эрх</b>
                    <div>
                        <p>10.1. OpenGov системийн нэршил болон агуулгыг хуулбарлахыг хориглоно.</p>
                        <p>10.2. OpenGov системийн нэр, эх код, өгөгдлийн бүтэц оюуны өмчийн гэрчилгээтэй бөгөөд зохиогчийн эрхээр хамгаалагдана.</p>
                    </div>
                </div>
                <div>
                    <b>Арван нэг. Системийн үйлчилгээ</b>
                    <div>
                        <p>11.1. OpenGov систем нь хэзээ ч хаанаас ч хандах боломжтой, байнгын тасралтгүй ажиллагаагаар бүрэн хангагдаж ажиллана.</p>
                        <p>11.2. OpenGov систем нь засварлалт, сайжруулалт хийх зорилгоор хэсэгчилсэн болон түр зогсолт хийж болно. Зогсолт хийхдээ урьдчилан  мэдэгдэнэ.</p>
                        <p>11.3. Системд гарсан доголдлыг арилгах, шалтгааныг тогтоох техникийн багийг байгуулан шуурхай арга хэмжээ авч асуудлыг богино хугацаанд шийдвэрлэнэ.</p>
                        <p>11.4. Албан хаагчдад байгууллагын дотоод ажиллагааны цахимжилтыг удирдах  аргачлал, цэс бүрийн нарийвчилсан цогц мэдээлэл өгч цахим байгууллага болоход дэмжлэг үзүүлнэ.</p>
                        <p>11.5. Системийн цэс бүрийг удирдах гарын авлагыг системийн баруун дээд буланд PDF хэлбэрээр байршуулна.</p>
                    </div>
                </div>
                <div>
                    <b>Арван хоёр. Бусад</b>
                    <div>
                        <p>12.1. Энд заасан гарчгууд нь зөвхөн ойлгомжтой байх зориулалтаар ашиглагдсан ба тухайн заалтуудыг хязгаарлах нөхцөл болохгүй.</p>
                        <p>12.2. Системтэй холбоотой гомдол, асуудал, маргааныг талууд эв зүйгээр харилцан зөвшилцөх замаар шийдвэрлэнэ. Зөвшилцөж шийдвэрлэх боломжгүй бол Монгол улсын хууль тогтоомжид заасны дагуу шийдвэрлэнэ.</p>
                        <p>12.3. Ажлын өдрүүдэд 09:00-17:00 цагийн хооронд системтэй холбоотой тусламж, мэдээлэл өгнө.</p>
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center', margin: 20 }}>
                    <span>Холбоо барих утас: 7507-7171</span>
                    <span>Цахим шуудан: info@dataland.mn</span>
                </div>
            </div>
        </ScrollView>
    )
}

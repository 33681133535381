import React, {useEffect, useState, useContext} from 'react';
import {UserContext} from "../hooks/UserContext";
import OrganizationService from "../services/api/organization";

export default function TrainingPage() {
    const [tender, setTender] = useState(null);
    const {user} = useContext(UserContext);
    useEffect(() => {
        // getHeaderData(user.organization.id);
        // console.log('training:', getHeaderData);
    }, [user.organization.id]);
    // const getHeaderData = async (orgId) => {
    //     try {
    //         let result = await OrganizationService.getOrgContact(orgId);
    //         let tmptender = await result.data.find(element => element.name === 'tender');
    //         tmptender !== undefined ? setTender(tmptender.value.replaceAll('<p>', '').replaceAll('</p>', '')) : setTender(null);
    //     } catch (e) {
    //         console.error(e);
    //     }
    // }

    return (
        // window.open(
        //     `https://surgalt.opengov.mn/#/dl/${localStorage.getItem("goToken")}`,
        //     "_blank"
        // )

        <iframe width="100%" style={{height: '100vh'}}
                src={`https://surgalt.opengov.mn/#/dl/${localStorage.getItem("goToken")}`}
                allowFullScreen></iframe>
    )
}

import React, {useEffect, useState} from 'react';
import {useHistory,useLocation} from "react-router-dom";
import OrganizationService from "../../services/api/organization";
import _ from "lodash";
import '../../assets/imgs/hzyam/hzyam.css';
import link from '../../assets/imgs/icon/link.png'
import {Button} from "devextreme-react/button";
import backbtn from "../../assets/imgs/hzyam/back-button.png";
import {urlServer} from "../../shared/lib/request";


export default function StateStructureType(props) {
    let history = useHistory();
    const location = useLocation();
   // const { stateStructureId } = props;
    const { stateStructureId ,bannerStructure} = location.state;
    const [dataSource, setDataSource] = useState([]);
    const stateStructureType =  _.groupBy(dataSource, 'state_str_type_name');
  //  const [stateStructureType, setStateStructureType] = useState('');
    useEffect(async () => {
        let a = await OrganizationService.getStateStructureByType(stateStructureId);
        // console.log('list260org ', a.data);
        setDataSource(a.data);
      //  console.log('a1', a1);
       // setStateStructureType(a1);
    }, []);

    const changePage = (id, name) => {
        const location = {
            pathname: '/ministryPage',
            state: {
                isfirst: false,
                toId: id,
                orgName: name
            }
        };
        history.push(location)

    }

    const style = {
        textJustify: {
            textAlign: "justify",
            textJustify: "inter-word",
            fontSize: 14,
            justifyContent: 'left',
            alignItems: 'left',
        },
        profileCard: {
            width: '100%',
            minheight: 'auto',
            margin: 'auto',
            // boxShadow: '0px 8px 60px -10px rgba(13,28,39,0.6)',
            background: '#fff',
            padding: "1.5rem 2.5rem",
            borderRadius: '12px',
            maxWidth: 'auto',
            position: 'relative',
            textAlign: 'left',
            paddingLeft: 20,
            "&:hover,&:focus": {
                transform: "scale(1.5)",
            },
        },
        content: {
            fontFamily:"'Roboto'",fontWeight:'Medium',fontSize:".9rem",color: "#7692b5",justifyContent: "left" , textDecoration: "none"           ,
        // paddingRight: 20,
        },

        title: {
            fontFamily:"'Roboto'",fontWeight:'Bold',fontSize:"1.3rem",
            // color: 'black',
            color: '#2E4765',
            
            justifyContent: 'left',
            marginBottom:"1rem",
            paddingBottom:".25rem",
            marginLeft: 10,
            borderBottom: "2px solid #cdd4dc",


        },

        profileCardStyle: {
            padding: "1rem"
        }
    }

        return (

            <div style={{color: '#140b3b'}}>
                <img loading="lazy" src={urlServer +'/api/file/documents/' + bannerStructure} style={{width: '100%'}} alt={"banner"}/>
                <div style={{float: 'right', padding: 10}}>
                    <Button width={50} height={50} icon={backbtn} onClick={() => history.goBack()}
                            style={{float: 'right'}}/>
                </div>
                <div className='col-12 row' style={{display: 'flex', flexDirection: 'row'}}>
                    {Object.entries(stateStructureType).map(([dep, staff]) => {
                        return (
                            <div  className='col-6' style={style.profileCardStyle}>
                            <div style={style.profileCard} key={dep}>
                                <div style={style.title}>{dep}</div>
                                <div style={{display: "flex", flexDirection: "column", gap: ".3rem"}}>
                                {staff.map((person, index) => {
                                    return (
                                        <a href={'javascript:void(0)'}
                                           style={style.content}
                                           onClick={() => changePage(person.org_id, person.name)}>
                                            <div style={{display: "flex", alignItems: "center", gap: ".5rem"}}><img style={{height: ".875rem", width: ".875rem"}} src={link} alt="link"/>{person.name}</div>
                                        </a>
                                         /*   <div style={{color: 'blue',justifyContent: 'left'}} onClick={() => changePage(person.org_id)} >{person.name}</div>*/
                                    )
                                })}
                                </div>
                            </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        )

}

import React, {useEffect, useState, useRef, useContext} from 'react';
import _ from "lodash";
import SelectBox from 'devextreme-react/select-box';
import DropDownBox from 'devextreme-react/drop-down-box';
import TreeView from 'devextreme-react/tree-view';
import RefService from "../../../services/api/refs";
import {
    TreeList,
    Editing,
    Column,
    Scrolling,
} from 'devextreme-react/tree-list';
import ProposalService from "../../../services/api/proposal";
import {projectStatusIdEditAllowed, helperGroupByKey, budgetProjectStatusEnum} from "../static/data";
import {Button} from 'devextreme-react/button';
import notify from "devextreme/ui/notify";
import {UserContext} from "../../../hooks/UserContext";
import {list_to_tree, subtotal_tree} from "../../../util";
//import FileUploader from "devextreme-react/file-uploader";
import {Popup} from 'devextreme-react/popup';
import NumberBox from 'devextreme-react/number-box';
//import {PROPOSAL_FORMULA, PROPOSAL_FORMULA_FIELDS} from "../../../shared/constants";
import moment from "moment";
import {confirm} from "devextreme/ui/dialog";
import DateBox from 'devextreme-react/date-box';
import LoadPanelComponent from "../../budget/components/loadPanel";

export default function ProposalLoanCreate(props) {

    // const [economicCategoryArray, setEconomicCategoryArray] = useState([]);
    /*const amountData = [
        {
            name: 'USD - Доллар',
            id: 1,
            value: 1
        },
        {
            name: 'MNT - Төгрөг',
            id: 2,
            value: 2
        }
    ]*/
    const {user} = useContext(UserContext);
    const economicCategoryArray = useRef([]);
    const [agendaList, setAgendaList] = useState([]);
    const [eventList, setEventList] = useState([]);
    const [dataSource, setDataSource] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [govAgendaParentList, setGovAgendaParentList] = useState([]);
    //const [govAgendaChildList, setGovAgendaChildList] = useState([]);
    const [govAgendaList, setGovAgendaList] = useState([]);
    const [agendaIdList, setAgendaIdList] = useState([]);
    const [agendaSubList, setAgendaSubList] = useState(null);
    const [eventSubList, setEventSubList] = useState(null);
    const [treeViewDataList, setTreeViewDataList] = useState([]);
    const [tezEditing, setTezEditing] = useState(false);
    const [ttzEditing, setTtzEditing] = useState(false);
    const [tszEditing, setTszEditing] = useState(false);

    const [editingChanges, setEditingChanges] = useState([]);
    //asdasd
    //const [docFile, setDocFile] = useState([]);
    const [fileList, setFileList] = useState([]);
    //const [currentProposal, setCurrentProposal] = useState(null);
    const [popupVisible, setPopupVisible] = useState(false);
    const [fileArray, setFileArray] = useState([]);
    const [columnData, setColumnData] = useState([]);
    const [curType, setCurType] = useState([]);
    const [organizationSelected, setOrganizationSelected] = useState(null);
    const [currentDate, setCurrentDate] = useState(new Date());
    const [currency, setCurrency] = useState(null);
    const [currencyRate, setCurrencyRate] = useState(2810);

    function visible(isVisible) {
        if (isVisible === "true") {
            setPopupVisible(true)
        } else setPopupVisible(false)
    }

//asdasd
    const [preData, setPreData] = useState([]);
    const [agendaId, setAgendaId] = useState(null);
    const [eventId, setEventId] = useState(null);
    const [test, setTest] = useState(null);
    const [treeBoxValue, setTreeBoxValue] = useState([]);
    const [filePopupLoader, setFilePopupLoader] = useState(false);

    const valueA1 = useRef(null);
    const valueA2 = useRef(null);
    const valueE1 = useRef(null);
    const valueE2 = useRef(null);
    const valueGA = useRef(null);

    let canEdit = useRef(false);
    let agendaListAll = useRef(null);
    let eventListAll = useRef(null);
    let curProposalRef = useRef(null);
    let curDetailListRef = useRef(null);
    let govAgendaListAll = useRef(null);
    let treeViewData = null;

    let treeList = useRef(null);
    let projects = useRef([]);
    let projectsRemoved = useRef([]);

    //let mainDataSource = [];
    let myTreeList = useRef({});
    let mapProposal = useRef({});
    let mapIdArr = useRef({});
    let mapCodeArr = useRef({});
    let mapCodeArrData = useRef({});

    useEffect(() => {
        // getFileList();
        initVars();
        loadData();
        loadAndSetupAgenda();
        loadAndSetupEvent();
        roleChecker();
        checkData(props.agendaId);
        getCureType();
    }, []);

    const getCureType = async () => {
        try {
            const result = await ProposalService.getCureType();
            setCurType(result.data);
        } catch (e) {
            console.log(e);
        }
    }

    const checkData = async (agendaId) => {
        setTreeBoxValue([]);
        const {proposal} = props;
        if (agendaId && proposal.id) {
            const res = await ProposalService.getGovAgenda(proposal.id, agendaId);
            // console.log("Agenda Data: ", res.data);
            const list = [];
            const listData = [];
            const idList = [];
            if (res.data && res.data.length > 0) {
                res.data.forEach(element => {
                    list.push(element.gov_agenda_id);
                    idList.push(element.id);
                    const resData = {
                        id: element.gov_agenda_id,
                        level_number: 4
                    }
                    listData.push(resData);
                });
                setAgendaIdList(list);
                setTreeBoxValue(list);
                setPreData(idList);
                setTreeViewDataList(listData);
            }
        }
    }

    /*async function onClickTest() {
        loadAndSetupProposal();
    }*/

    const initVars = async () => {
        const {agendaId, eventId, proposal} = props;
        if (agendaId) setAgendaId(agendaId);
        if (eventId) setEventId(eventId);
        if (proposal) curProposalRef.current = proposal;

        if (proposal && projectStatusIdEditAllowed.includes(proposal.budget_project_status_id)){
            canEdit.current = true;
            setTszEditing(true);
        }

        // console.log("START", agendaId, eventId, curProposalRef.current, canEdit.current);
    };
    const roleChecker = async () => {
        const budgetTypeId = user.organization.org_budget_type_id;

        if (budgetTypeId === 1) {
            setTtzEditing(false);
            if (props.proposal && props.proposal.budget_project_status_id !== budgetProjectStatusEnum.ACCEPTED_TEZ){
                setTezEditing(true);
            }
        } else if (budgetTypeId === 2) {
            setTezEditing(false);
            if (props.proposal && props.proposal.budget_project_status_id !== budgetProjectStatusEnum.ACCEPTED_TTZ){
                setTtzEditing(true);
            }
        }
    };
    useEffect(() => {
        // console.log('TTZ TEZ EFFECT', ttzEditing);
        if (ttzEditing || tezEditing)
            canEdit.current = true;
    }, [ttzEditing, tezEditing])

    const loadAndSetupProposal = async () => {
        if (!agendaId || !eventId) return;
        // console.log('ON LOAD', agendaId, eventId);
        loadAndSetupProposalParam(agendaId, eventId);
    };
    const loadAndSetupProposalParam = async (aId, eId) => {
        const r1 = await ProposalService.getBudgetProject(curProposalRef.current.id);
        if (r1.data) {
            setOrganizationSelected(r1.data[0].org_id);
            setCurrency(r1.data[0].cur_type_id);
            setCurrencyRate(r1.data[0].cur_rate);
        }
        // console.log('r1: 12 ', r1);
        setIsLoading(true);
        const res = await ProposalService.getProposalDetailList(aId, eId, curProposalRef.current.id)
        // console.log('EDIT RESULT 1: ',res);
        const temp = await ProposalService.listVBudgetCompare(props.year, aId, eId);
        // console.log(temp);

        // convert
        let result = helperConvertProposals(temp.data, res.data);

        // convert tree list
        myTreeList.current = list_to_tree(result);
        updateSubTotal();
        setDataSource(result);
        setIsLoading(false);
    };

    const updateSubTotal = (index = -1) => {
        if (index === -1) {
            myTreeList.current.forEach((item) => {
                subtotal_tree(item);
                return item;
            });
        } else {
            if (typeof myTreeList.current[index] == 'undefined') {
                console.error('out of bounds baby')
            } else {
                let item = myTreeList.current[index];
                subtotal_tree(item);
            }
        }
    }

//asdasdasdasdadasdasd

   /* const refresh = (data) => {
        PopupInfo(data);
    }


    const submitFile = async (data) => {
        // console.log(data)
        const {agendaId, eventId, proposal} = props;
        if (docFile.length === 0) {
            return notify("Файл оруулна уу ", "warning", 2000);
        }
        let fd = new FormData();
        fd.append('budget_project_detail_id', data.detail_id);
        fd.append('additional_doc', docFile[0]);
        fd.append('budget_project_id', proposal.id);
        fd.append('description', 'file');

        let res = await ProposalService.uploadFiles(fd);
        if (res.code === 200) {
            setDocFile([]);
            // getFileList();
            notify('Файлыг амжилттай хадгаллаа', "success",2000);
        } else {
            notify("Файл хадгалахад алдаа гарлаа", "warning", 2000);
        }
    };
*/
    const deleteFile = async (id) => {
        try {
            let result = confirm("<i>Энэхүү файлыг устгахдаа итгэлтэй байна уу?</i>", "Баталгаажуулалт");

            result.then( async (dialogResult) => {
                // console.log(dialogResult);
                if ( dialogResult) {
                    setFilePopupLoader(true);
                    await ProposalService.deleteFile(id)
                    // await getFileList();
                    setFilePopupLoader(false);
                    return notify( `Файл устгагдлаа, Та 'Файлуудын жагсаалтыг шинэчлэх' товчийг дарна уу.`, 'success', 6000);
                }
            });

        } catch (e) {
            console.error(e);
            return notify(`Файл устгахад алдаа гарлаа `, 'error', 4400);
        }
    }

    /*const renderTitle = () => {
        return (
            <div style={{backgroundColor: 'red'}}>iebwibrwer</div>
        )
    }*/

    const PopupInfo = (data) => {
        let fileArray = [];
        if (data.data.detail_id.length > 0) {
            const filteredArray = fileList.filter((foundItem) => {
                if (foundItem.budget_project_detail_id && foundItem.budget_project_detail_id.length > 0) {
                    return foundItem.budget_project_detail_id === data.data.detail_id;
                }
            })
            fileArray = filteredArray;
        }
        setFileArray(fileArray)
    };

   /* const fileUpload = (data) => {

        if (data.data.code.length === 6 && (data.data.amount && data.data.detail_id !== null && data.data.detail_id !== undefined)) {
            // console.log('UUUUUUUUUUU', data.data.detail_id )
            async function onSelectedFilesChanged(e) {
                setCurrentProposal(data.data);
                await setDocFile(e.value);
                notify('Файл хавсаргагдлаа, Hогоон товчийг дарж хадгална уу. ', "info", 4600);
                // console.log('DOC FILE ->',docFile);
            }

            return (
                <div>
                    <LoadPanelComponent position={{of: 'filePo'}} visible={filePopupLoader} />
                    <div style={{display: 'flex', flexDirection: 'row',}}>
                        {canEdit.current &&
                        <div style={{display: 'flex', flexDirection: 'column',}}>
                            <div >
                                <FileUploader multiple={false}
                                              uploadMode = "instantly"
                                              accept='*'
                                              key={data.detail_id}
                                              id = {data.data.code}
                                              selectButtonText="Файл оруулах"
                                              labelText=""
                                              onValueChanged={onSelectedFilesChanged}
                                    // value={data.data.budget_project_detail_id}
                                />
                            </div>
                        </div>
                        }

                        <div style={{display: "flex", justifyContent: 'center'}}>
                            {canEdit.current &&
                            <Button
                                height={35}
                                icon='newfolder'
                                type="success"
                                onClick={() => {
                                    submitFile(data.data);
                                }}
                                style={{marginTop: '3px'}}
                            />
                            }
                            <div>
                                &nbsp;
                                &nbsp;
                                <Button
                                    height={35}
                                    icon='activefolder'
                                    type="success"
                                    onClick={() => {
                                        setColumnData(data);
                                        PopupInfo(data);
                                        setPopupVisible(true);
                                    }}
                                    style={{marginTop: '3px', backgroundColor: '#1453B5'}}
                                />
                            </div>
                        </div>
                    </div>
                    {/!*{data.data.detail_id &&*!/}
                    {/!*  fileArray.map(data => {*!/}
                    {/!*    return (*!/}
                    {/!*        <div className="flex" style={{"margin-top": 5}} key={data.file_path}>*!/}
                    {/!*          <a download href={window.location.origin + '/api/proposal/file/' + data.file_path} target="_blank">{data.file_path}</a>*!/}
                    {/!*          <br/>*!/}
                    {/!*          <span key={data.id}>{`Тайлбар - "${data.description}"`}</span>*!/}
                    {/!*        </div>*!/}
                    {/!*        // <div>{data.description}</div>*!/}
                    {/!*    )*!/}
                    {/!*  })*!/}
                    {/!*}*!/}


                </div>

            )
        }
    }*/

    const helperConvertProposals = (budgetsHistory, current) => {
        projectsRemoved.current = [];
        projects.current = [];
        let res = [];

        economicCategoryArray.current.forEach(e => {
            res.push({...e})
        });
        let currCopy = [...current];
        curDetailListRef.current = [...current]; // uses it later when we wanna save
        let groupedArr = helperGroupByKey(budgetsHistory, 'econ_category_id');
        const yearVal = new Date().getFullYear();

        groupedArr.forEach(item => {
            const temp = {};

            item.data.forEach(element => {
                temp[`year${yearVal - element['budget_year']}`] = element['amount'];
            });
            // if exist, find the current year proposal by econ_id
            const curDataIndex = currCopy.findIndex(el => el.econ_category_id === item.key);
            if (curDataIndex !== -1) {
                temp['amount'] = currCopy[curDataIndex]['amount'];
                temp['description'] = currCopy[curDataIndex]['description'];
                temp['detail_id'] = currCopy[curDataIndex]['id'];
                temp['created_user_id'] = currCopy[curDataIndex]['created_user_id'];
                temp['ttz_amount'] = currCopy[curDataIndex]['ttz_amount'];
                temp['tez_amount'] = currCopy[curDataIndex]['tez_amount'];
                currCopy.splice(curDataIndex, 1); // merge the current and history data, and make sure remove the data form the arrayy
                // so that we can add the rest of the currentData
            }
            let find = res.findIndex(el => el.id === item.key);
            res[find] = {
                ...res[find], ...temp,
                agenda_id: agendaId,
                event_id: eventId,
                budget_project_id: curProposalRef.current.id
            } // ATTENTIO, static for now
        });

        currCopy.forEach(item => {
            let find = res.findIndex(el => el.id === item.econ_category_id);
            const {agenda_id, event_id, budget_project_id, amount, description, created_user_id, ttz_amount, tez_amount,
                base_cur_amount, state_budget_amount, base_cur_amount_mn, amount_mn} = item;
            if (find !== -1) {
                let temp2 = {...res[find]};
                res[find] = {
                    ...temp2,
                    agenda_id,
                    event_id,
                    budget_project_id,
                    amount,
                    description,
                    created_user_id,
                    ttz_amount,
                    tez_amount,
                    base_cur_amount,
                    state_budget_amount,
                    base_cur_amount_mn,
                    amount_mn,
                    detail_id: item.id,
                };
                // console.log('udpatepd', find, res[find]);
            }
        });
        return res;
    };

    const loadData = async () => {
        const result = await RefService.getRefEconomicCategory();

        if (Array.isArray(result.data)) {

            result.data.forEach(o => {
                mapProposal.current[o.id] = {
                    amount: 0,
                    description: ""
                }
                mapCodeArr.current[o.code] = o.id;
                mapIdArr.current[o.id] = o.code;
                mapCodeArrData.current[o.code] = o;
            });
        }

        // console.log(mapCodeArr);
        economicCategoryArray.current = _.sortBy(result.data, ['code']).filter(ez => ez.is_mofa_foreign === 1);
        // created at talbar buruu boglogdoj baigaag zasah
        economicCategoryArray.current.forEach(e => {
            delete e.createdAt;
            delete e.updatedAt;
        });
        // setDataSource(result.data);

        const r1 = await RefService.refGovAgendaProp(props.proposal.org_id);
        const listData = [];
        r1.forEach(data => {
            if (!data.parent_id) {
                listData.push(data);
            }
        });
        setGovAgendaList(r1);
        setGovAgendaParentList(listData);
        govAgendaListAll.current = r1;

        // if (props.agendaId !== null  && props.eventId !== null ) loadAndSetupProposalParam(props.agendaId, props.eventId);
    };
    const loadAndSetupEvent = async () => {
        const result = await RefService.getEventsProps(props.proposal.org_id);
        eventListAll.current = _.sortBy(result.data, ['name']);
        // todo: check data.code === 200

        // get the parentId === null
        const filtered = eventListAll.current.filter(item => item.parent_id === null);
        setEventList(filtered);

        if (props.eventId) { // this is edit window, propagate the selected values
            const find = eventListAll.current.find(el => el.id === props.eventId);
            if (find && find.parent_id !== null) {
                // set the sublist
                const filteredSub = eventListAll.current.filter(el => el.parent_id === find.parent_id)
                setEventSubList(filteredSub);
                valueE2.current = props.eventId;
                valueE1.current = find.parent_id;
            } else if (find && find.parent_id === null) {
                valueE1.current = props.eventId;
            }

        }
        // TEST
        const temp = []
        filtered.map(item => temp.push(`(${item.code}) - ${item.name}`));
        setTest(temp);

    };
    const loadAndSetupAgenda = async () => {
        const result= await RefService.getAgendasProps(props.proposal.org_id);
        agendaListAll.current = _.sortBy(result.data, ['name']);

        // todo: check data.code === 200

        // get the parentId === null
        const filtered = agendaListAll.current.filter(item => item.parent_id === null);
        setAgendaList(filtered);

        // CASE: EDIT
        if (props.agendaId) { // this is edit window, propogate the selected values
            const find = agendaListAll.current.find(el => el.id === props.agendaId);
            if (find && find.parent_id !== null) {
                // set the sublist
                const filteredSub = agendaListAll.current.filter(el => el.parent_id === find.parent_id)
                setAgendaSubList(filteredSub);
                valueA2.current = props.agendaId;
                valueA1.current = find.parent_id;

            } else if (find && find.parent_id === null) {
                valueA1.current = props.agendaId;
            }

        }

    };
    const setData = async (e) => {
        // console.log('body: ', e.changes);
        if (!e.changes || e.changes.length === 0) return;

        e.changes.forEach(data => {
            // if(data.type === 'insert') {
            //   const temp = data.data;
            //   temp['agenda_id'] = agendaId;
            //   temp['event_id'] = eventId;
            //   temp['budget_project_id'] = curProposalRef.current.id; // static - temporary
            //   temp['is_insert'] = true;
            //   // projects.current.push(temp); // we dont need to push , it will automatically be pushed to the project.current
            // }

            if (data.type === 'update' && data.data.code && data.data.code.length === 6) {
                // autoSum()
                // if it is update, then just update the 'project.current'
                const updateIndex = projects.current.findIndex(el => el['id'] === data.data['id']);
                if (updateIndex !== -1) projects.current[updateIndex] = data.data;
                else projects.current.push(data.data);

            } else if (data.type === 'remove') {
                // project.current will be automatically updated
                if (data.key.id !== null && data.key.id !== undefined) { // if t.id exist , then this data is in the database,
                    projectsRemoved.current.push(data.key); // keep track of removed projects separately so we update the db later
                }
            }
        });
        // console.log(projects.current);

        // call the save function
        setDetailBulk(null);
    };

    const setDetailBulk = async (e) => {
        // if ()

        // if (!agendaIdList || agendaIdList.length === 0) {
        //     return notify(`Бодлогын баримт бичиг сонгоогүй байна`, 'warning', 5000);
        // } else {
            const list = [];
            treeViewDataList.forEach(element => {
                if (element.level_number === 4) {
                    list.push(element.id);
                }
            });
            const agenda = {
                preData: preData,
                budget_project_id: props.proposal.id,
                agenda_id: agendaId,
                gov_agenda_id_list_list: list,
                created_user_id: user.id
            }
            ProposalService.setGovAgenda(agenda).then(res => {
                checkData(agendaId);
                // console.log('res: ', res);
            });
        // }

        const sendData = [...projects.current];

        sendData.forEach(item => { // need to convert it to 'budget_project_detail'
            const econID = item.id;
            item['agenda_id'] = agendaId;
            item['event_id'] = eventId;
            item['budget_project_id'] = curProposalRef.current.id;
            item['econ_category_id'] = econID;
            // item['organization_id'] = organizationSelected;
            item['cur_type_id'] = currency;
            item['cur_rate'] = currencyRate;
            item['cur_date'] = currentDate;
            item.id = item.detail_id * 1;
        });

        projectsRemoved.current.forEach(item => {
            item['is_removed'] = 1;
            sendData.push(item);
        });

        // console.log('Re Project data 2: ', sendData);

        // save the data
        try {
            const res = await ProposalService.saveProposalLoan({data: sendData, govAgenda: valueGA.current});
            // console.log(res);
            if (res.success) {
                notify(`Амжилттай хадгаллаа`, 'success',);
                checkData(agendaId)
            }
            if (typeof props.onSavedCallBack === 'function') {
                props.onSavedCallBack(); // closes the popup
            }

        } catch (e) {
            console.error(e);
            notify(`Алдаа гарлаа`, 'danger',);
        }


    };


    const onEditingStart = (e) => {
        if (e.data.code.length !== 6) e.cancel = true;
    }

    const cellRenderEza = (data) => {
        return <span
            className={`proposal-eza with-code code-${data.data.code.length}`}><span>{data.data.code}</span> - {data.displayValue}</span>;
    }
    const onValueChanged = (e, originList, setSubList, setVarId, refObj = null) => {
        // check if the parent is selected
        // console.log(e.value);
        if (refObj) refObj.current = e.value;
        if (e.value === null || e.value === undefined) {
            // if parent is not selected then clear the sub list
            setVarId(null);
            if (setSubList !== null && typeof setSubList === 'function') setSubList([]);
            return;
        }


        // console.log(originList);
        // set the sublist
        const filtered = originList.filter(item => e.value === item.parent_id)

        // console.log(filtered);
        if (!filtered || filtered.length === 0) { // if there is no child - then set the variable as a parent_id
            setVarId(e.value);
        } else {
            if (setSubList !== null && typeof setSubList === 'function') setSubList(filtered);
        }


        // auto reset when agenda and event id are set,
        // checkData(agendaId);
    }

    useEffect(() => {
        // console.log("USE EFFECT", agendaId, eventId);
        if (agendaId !== null && eventId !== null) {
            loadAndSetupProposalParam(agendaId, eventId);
            checkData(agendaId);
        }
    }, [agendaId, eventId]);

    const onToolbarPreparing = (e) => {
        e.toolbarOptions.items.forEach(item => {
            item.showText = 'always'
        });
        // if (e.toolbarOptions.items && e.toolbarOptions.items.length > 0) {
        //     const button = {...e.toolbarOptions.items[0]};
        //     button.options = {...e.toolbarOptions.items[0].options};
        //     button.options.text = `Хуулах ${CURRENT_YEAR}`;
        //     button.options.disabled = !tszEditing;
        //     button.options.icon = 'copy';
        //     button.options.onClick = (e) => {
        //         // console.log(e);
        //         cloneColumn(dataSource, 'year0');
        //     };
        //     e.toolbarOptions.items.push(button);
        // }
        // e.toolbarOptions.items[0].showText = 'always';
        // e.toolbarOptions.items[1].showText = 'always';
        // e.toolbarOptions.visible = false
    };

    const syncTreeViewSelection = (e) => {
        let treeView = (e.component.selectItem && e.component) || (treeViewData && treeViewData.instance);

        if (treeView) {
            if (e.value === null) {
                treeView.unselectAll();
            } else {
                let values = e.value || treeBoxValue;
                values && values.forEach(function (value) {
                    treeView.selectItem(value);
                });
            }
        }

        if (e.value !== undefined) {
            setTreeBoxValue(e.value);
        }
    }

    const treeViewRender = () => {
        return (
            <TreeView dataSource={govAgendaList}
                      ref={(ref) => treeViewData = ref}
                      dataStructure="plain"
                      keyExpr="id"
                      selectNodesRecursive={true}
                      parentIdExpr="parent_id"
                      selectionMode="multiple"
                      showCheckBoxesMode="normal"
                      displayExpr="name"
                      selectByClick={true}
                      onContentReady={syncTreeViewSelection}
                      onItemSelectionChanged={treeView_itemSelectionChanged}
            />
        );
    }

    const treeView_itemSelectionChanged = (e) => {
        const data = e.component.getSelectedNodes();
        const list = [];
        data.forEach(el => {
            list.push(el.itemData);
        })
        setTreeViewDataList(list);
        setAgendaIdList(e.component.getSelectedNodeKeys());
        setTreeBoxValue(e.component.getSelectedNodeKeys());
    }
    /*
    clones srcField column from arr, to targetField collumn ,
    then calls callBack function - callBack = setDatasource
    * */
    /*const cloneColumn = (arr, srcField) => {
        // console.log(treeList.current);
        // console.log(dataSource);
        const temp = [];
        arr.forEach(item => {
            if (!item.code) return;
            if (item.code.length !== 6 || item[srcField] <= 0) return;

            const data = {amount: item[srcField]};
            temp.push({type: 'update', key: item.id, data});
        });
        setEditingChanges(temp);

    };*/

    /*const setCellValueAmount = (newData, value, currentRowData) => {
        let id = currentRowData.id;

        // console.log(currentRowData);
        // mapProposal.current[id].amount = value;
        newData.amount = value;

        updateFormula({...currentRowData, amount: value});
    }*/

    /*const updateFormula = (data) => {
        // console.log(data);
        if (data.code.length !== 6) return;
        if (data.code.substr(0, 4) !== '2101') return;


        let code = data.code;
        let amount = data.amount;
        let fn = PROPOSAL_FORMULA['210101'];


        // temp.push({type: 'update', key: mapCodeArr.current[code], data: {amount}});

        // console.log(mapCodeArrData.current[]);


        if (typeof fn === 'function') {

            let sum2101 = 0;
            PROPOSAL_FORMULA_FIELDS.forEach(cc => {
                // console.log(cc);
                // check if we are currently editing this row
                if (cc === code)
                    sum2101 += amount * 1;
                else {
                    const id = mapCodeArr.current[cc] // cc - calculate code

                    // check if we are already updated it (
                    const f = editingChanges.find(change => change.key === id)
                    if (f)
                        sum2101 += f.data.amount * 1;
                    else // else get the amount from datasource
                        sum2101 += dataSource.find(ds => ds.code === cc).amount * 1;
                }
            });
            // console.log('FN', sum2101);

            let items = fn(sum2101);

            // console.log(items);
            const temp = [...editingChanges];
            for (let k in items) {
                let val = items[k];
                let data = {amount: val};
                let id = mapCodeArr.current[k];

                // console.log(val, id, data);
                // make sure dont affect other changes
                const find = temp.find(el => el.key === id);
                if (find) {
                    find.data = data;
                } else {
                    temp.push({type: 'update', key: id, data});
                }
            }


            setEditingChanges(temp);
        }
    }*/

    /*const setCellValueDesc = (newData, value, currentRowData) => {
        let id = currentRowData.id;

        mapProposal.current[id].description = value;
        newData.description = value;
    }*/

    const changeCurrency = (e) => {
        setCurrencyRate(e.value);
    }

    const onValueChangedDate = (e) => {
        setCurrentDate(e.value);
    }

    return (
        <div className="row">
            <LoadPanelComponent
                shadingColor="rgba(0,0,0,0.4)"
                position={{of: '#dataGrid'}}
                visible={isLoading}
                message = ''
                 showPane={false}
            />

            <div className="col-6">

                <div className="dx-fieldset">

                    {/*<div className="dx-field">*/}
                    {/*    <div className="dx-field-label">Байгуулга</div>*/}
                    {/*    <div className="dx-field-value">*/}
                    {/*        <SelectBox dataSource={organizationList}*/}
                    {/*                   placeholder="Байгуулга сонгоно уу"*/}
                    {/*                   searchEnabled={true}*/}
                    {/*                   value={organizationSelected}*/}
                    {/*                   showClearButton={true}*/}
                    {/*                   displayExpr="name"*/}
                    {/*                   onValueChanged={(e) => setOrganizationSelected(e.value)}*/}
                    {/*                   valueExpr="id"/>*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    <div className="dx-field">
                        <div className="dx-field-label">Валют төрөл</div>
                        <div className="dx-field-value">
                            <SelectBox dataSource={curType}
                                       placeholder="Төрөл сонгоно уу"
                                       searchEnabled={true}
                                       value={currency}
                                       showClearButton={true}
                                       displayExpr="name"
                                       onValueChanged={(e) => setCurrency(e.value)}
                                       valueExpr="id"/>
                        </div>
                    </div>

                </div>
            </div>
            <div className="col-6">

                <div className="dx-fieldset">

                    <div className="dx-field">
                        <div className="dx-field-label">Валютын ханш</div>
                        <div className="dx-field-value">
                            <NumberBox format="₮ #,###.#" defaultValue={currencyRate} onValueChanged={changeCurrency} />
                        </div>
                    </div>

                    <div className="dx-field">
                        <div className="dx-field-label">Валютын ханшийн өдөр</div>
                        <div className="dx-field-value">
                            <DateBox
                                applyValueMode="useButtons"
                                value={currentDate}
                                type="date"
                                onValueChanged={onValueChangedDate}
                            />
                        </div>
                    </div>

                </div>
            </div>

            <div className="col-6">

                <div className="dx-fieldset">

                    <div className="dx-field">
                        <div className="dx-field-label">Хөтөлбөр</div>
                        <div className="dx-field-value">


                            <SelectBox dataSource={agendaList}
                                       placeholder="Хөтөлбөр сонгоно уу"
                                       searchEnabled={true}
                                       value={valueA1.current}
                                       showClearButton={true}
                                       displayExpr="name"
                                       onValueChanged={(e) => onValueChanged(e, agendaListAll.current, setAgendaSubList, setAgendaId, valueA1)}
                                       valueExpr="id"/>
                        </div>
                    </div>

                    {agendaSubList && agendaSubList.length > 0 &&
                    <div className="dx-field">
                        <div className="dx-field-label">Дэд хөтөлбөр</div>
                        <div className="dx-field-value">
                            <SelectBox dataSource={agendaSubList}
                                       placeholder="Дэд хөтөлбөр сонгоно уу"
                                       showClearButton={true}
                                       value={valueA2.current}
                                       displayExpr="name"
                                       onValueChanged={(e) => onValueChanged(e, [], null, setAgendaId, valueA2)} // make sure sublist = null, origin list = []
                                       valueExpr="id"/>
                        </div>
                    </div>}

                </div>
            </div>
            <div className="col-6">
                <div className="dx-fieldset">

                    <div className="dx-field">
                        <div className="dx-field-label">Зориулалт</div>
                        <div className="dx-field-value">
                            <SelectBox dataSource={eventList}
                                       placeholder="Зориулалт сонгоно уу"
                                       showClearButton={true}
                                       value={valueE1.current}
                                       displayExpr="name"
                                       onValueChanged={(e) => onValueChanged(e, eventListAll.current, setEventSubList, setEventId, valueE1)}
                                       valueExpr="id"/>
                        </div>
                    </div>

                    {eventSubList && eventSubList.length > 0 &&
                    <div className="dx-field">
                        <div className="dx-field-label">Арга хэмжээ</div>
                        <div className="dx-field-value">
                            <SelectBox dataSource={eventSubList}
                                       placeholder="Арга хэмжээ сонгоно уу"
                                       showClearButton={true}
                                       value={valueE2.current}
                                       displayExpr="name"
                                       onValueChanged={(e) => onValueChanged(e, [], null, setEventId, valueE2)}
                                       valueExpr="id"/>
                        </div>
                    </div>}

                </div>
            </div>
            <div className="col-12">

                <div className="dx-fieldset">

                    <div>
                        <div className="dx-field-label">Бодлогын баримт бичиг</div>
                        <div className="dx-field-value" style={{width: "100%"}}>
                            <DropDownBox
                                value={treeBoxValue}
                                valueExpr="id"
                                displayExpr="name"
                                placeholder="Бодлогын баримт бичиг сонгоно уу"
                                showClearButton={true}
                                dataSource={govAgendaList}
                                onValueChanged={syncTreeViewSelection}
                                contentRender={treeViewRender}
                            />
                        </div>
                    </div>

                </div>
            </div>
            <div className="col-12">
                <div className="my-3">
                </div>
            </div>
            <div className="col-12">
                <TreeList
                    ref={treeList}
                    headerFilter={true}
                    height={'58vh'}
                    id="dataGrid"
                    rowAlternationEnabled={true}
                    dataSource={dataSource}
                    onToolbarPreparing={onToolbarPreparing}
                    keyExpr="id"
                    hoverStateEnabled={true}
                    showBorders={true}
                    parentIdExpr="parent_id"
                    allowColumnResizing={true}
                    onEditingStart={onEditingStart}
                    // onEditorPrepared = {onEditorPrepared}
                    onSaved={setData}
                    rowUpdating={(e) => {
                        console.log(e);
                    }}
                >
                    <Column caption="2022 оны төсвийн санал" alignment="center" >
                        <Column
                            dataField="name"
                            width={300}
                            caption="Эдийн засгийн ангилал"
                            cellRender={cellRenderEza}
                            allowEditing={false}

                        >
                            {/*<Lookup dataSource={economicCategoryArray.current} valueExpr="id" displayExpr="name" />*/}

                        </Column>

                        <Column caption="Гадаад зээл/тусламжаас санхүүжих" alignment="center">
                            <Column
                                dataField="base_cur_amount"
                                dataType="number"
                                format="#,##0.00"
                                alignment="right"
                                caption="Үндсэн валют"
                                allowEditing={true}
                                // setCellValue={setCellValueAmount}
                            />
                            <Column
                                dataField="amount"
                                dataType="number"
                                format="#,##0.00"
                                caption="Төгрөг"
                                allowEditing={true}
                            />
                        </Column>

                        <Column
                            dataField="state_budget_amount"
                            dataType="number"
                            format="#,##0.00"
                            caption="Улсын төсвөөс санхүүжих /Төгрөг/"
                            // alignment="center"
                            allowEditing={true}
                        />

                        <Column caption="Гадаад зээл, тусламжийн эх үүсвэрээр хэрэгжих төслийн Монголын талын санхүүжилт" alignment="center">
                            <Column
                                dataField="base_cur_amount_mn"
                                format="#,##0.00"
                                alignment="right"
                                dataType="number"
                                caption="Үндсэн валют"
                                allowEditing={true}
                            />
                            <Column
                                dataField="amount_mn"
                                dataType="number"
                                format="#,##0.00"
                                caption="Төгрөг"
                                allowEditing={true}
                            />
                        </Column>
                    </Column>

                    <Column
                        dataField="year0"
                        caption="2021"
                        format="#,##0.00"
                        allowEditing={false}
                        alignment="center"
                    />
                    {/*<Column type="buttons" width={250} caption="Файл оруулах"*/}
                    {/*        cellRender={fileUpload}*/}
                    {/*>*/}
                    {/*</Column>*/}

                    {canEdit.current && <Editing
                        mode="batch"
                        changes={editingChanges}
                        onChangesChange={(changes) => {
                            // console.log(changes);
                            setEditingChanges(changes);
                        }}
                        allowUpdating={true}
                        allowAdding={false}
                        allowDeleting={false}
                        confirmDelete={true}
                        useIcons={true}
                        texts={{
                            addRow: 'Төсөл нэмэх',
                            cancelAllChanges: 'Болих',
                            cancelRowChanges: 'Болих',
                            confirmDeleteMessage: 'Энэ мэдээллийг устгах уу?',
                            confirmDeleteTitle: 'Баталгаажуулах',
                            deleteRow: 'Устгах',
                            editRow: 'Өөрчлөх',
                            saveAllChanges: 'Хадгалах',
                            saveRowChanges: 'Хадгалах',
                            undeleteRow: 'Буцаах',
                            validationCancelChanges: 'Баталгаажуулах болих',
                        }}
                    />}
                    <Scrolling mode='virtual'/>
                    {/*<Paging enabled={true} />*/}
                </TreeList>
            </div>

            <div>
                <Popup
                    visible={popupVisible}
                    onHiding={() => visible("false")}
                    dragEnabled={false}
                    hideOnOutsideClick={true}
                    showTitle={true}
                    title='Хавсралт файлууд'
                    width={1000}
                    // key={data.data.id}
                    // titleRender={renderTitle}
                    height={450}
                >
                    <div style={{display: 'flex', alignItems: 'flex-end', flexDirection: 'column'}}>
                        <Button
                            height={35}
                            icon='refresh'
                            type="success"
                            text='Файлуудын жагсаалтыг шинэчлэх'
                            style={{marginLeft: 'auto', backgroundColor: '#2C2E43', color: 'white'}}
                            onClick={() => PopupInfo(columnData)}
                        />
                        <div style={{width: '99.9%', marginTop: '0.6rem'}}>
                            <hr style={{
                                border: 0,
                                height: '1px',
                                background: '#1453B5',
                                backgroundImage: 'linear-gradient(to right, #ccc, #333, #ccc)',
                            }}/>
                        </div>
                    </div>
                    <div>
                        {fileArray.length > 0 &&
                        fileArray.map(data => {
                            return (
                                <div>
                                    <div style={{marginTop: 5, display: 'flex', flexDirection: 'row', marginBottom: '1rem'}}
                                         key={data.file_path}>
                                        <div>
                                            <div
                                                // style = {{fontWeight: '280', fontSize: '12px'}}
                                            ><h5>{moment(data.updatedAt).format("YYYY-MM-DD HH:mm:ss")}</h5></div>
                                            <span key={data.id}>{`Файлын нэр - "${data.file_path}"`}</span>
                                            {/*<span>{realData.data.detail_id}</span>*/}
                                        </div>

                                        {/*<div>*/}
                                        {/*  <span key={data.id}>{`Он сар-"${data.updatedAt}"`}</span>*/}
                                        {/*</div>*/}
                                        <div style={{marginLeft: 'auto', display: 'flex', flexDirection: 'row'}}>
                                            <a download href={window.location.origin + '/api/proposal/file/' + data.file_path}
                                               target="_blank" rel="noreferrer">
                                                <Button
                                                    height={35}
                                                    icon='download'
                                                    type="success"
                                                    text='Татах'
                                                    // onClick = {() => submitFile(data.data)}
                                                    style={{margin: '0 6px 0 6px',}}
                                                >

                                                </Button>
                                            </a>
                                            {canEdit.current ?
                                                <Button
                                                    height={35}
                                                    icon='remove'
                                                    type='success'
                                                    text='Устгах'
                                                    onClick={async () => {
                                                        await deleteFile(data);

                                                        // await getFileList();
                                                        // setTimeout(() => {
                                                        //   PopupInfo(columnData);
                                                        // }, 1500);
                                                    }}
                                                    style={{backgroundColor: '#D54C4C'}}
                                                />
                                                :
                                                <div></div>
                                            }
                                        </div>
                                    </div>
                                    <hr style={{
                                        border: 0,
                                        height: '1px',
                                        background: '#1453B5',
                                        backgroundImage: 'linear-gradient(to right, #ccc, #333, #ccc)',
                                    }}/>
                                </div>
                                // <div>{data.description}</div>
                            )
                        })
                        }
                    </div>
                </Popup>
            </div>
        </div>
    );

}

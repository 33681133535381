import React, { useState, useEffect, useContext } from "react";
import info from '../../assets/imgs/icon/iconInfo.png';
import link from '../../assets/imgs/icon/link.png';
import { UserContext } from "../../hooks/UserContext";
import { useHistory } from "react-router-dom";
import { Link } from 'react-router-dom';
import '../../assets/imgs/hzyam/hzyam.css';
import './static/ministryPage.css';
import OrganizationService from "../../services/api/organization";
import ScrollView from "devextreme-react/scroll-view";
import { Popup } from "devextreme-react/popup";
import UpdatedHome from "../introduction/updatedHome";
import UpdatedStructure from "../updated_structure";
import notificationUserService from "../../services/api/notificationUser";
import notify from "devextreme/ui/notify";
import { urlServer } from "../../shared/lib/request";
import LoadPanelComponent from "../../components/LoadPanelComponent";
import { Accordion, MultiView } from "devextreme-react";
import closeIcon from '../../assets/imgs/close.png';
import fileJpgIcon from "../../assets/imgs/file_jpg.png";
import filePngIcon from "../../assets/imgs/file_png.png";
import fileDocIcon from "../../assets/imgs/file_doc.png";
import fileXlsIcon from "../../assets/imgs/file_xls.png";
import filePptIcon from "../../assets/imgs/file_ppt.png";
import filePdfIcon from "../../assets/imgs/file_pdf.png";
import fileUnknownIcon from "../../assets/imgs/file_unknown.png";
import OrgContact from "../introduction/components/TopHeaderComponent";
import { Tooltip } from "devextreme-react/tooltip";
import moment from "moment";
import 'moment/locale/mn';
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import ImageViewer from "../../components/ImageViewer";
import info1 from "../../assets/imgs/icon/iconInfo1.png";
import logo from '../../assets/imgs/41367.svg'
import feedbackServices from "../../services/api/feedback";
import Form, { Item, Label, SimpleItem } from 'devextreme-react/form';
import { Button, DropDownBox } from "devextreme-react";
import DataGrid, { Column, FilterRow, GroupPanel, Grouping, Scrolling, SearchPanel, Selection } from "devextreme-react/data-grid";
import AuthService from "../../services/api/auth";
import { useLocation } from "react-router-dom";
import { Alert } from "antd";
import { TbUserCheck, TbUserQuestion } from "react-icons/tb";
import { TermsCondition } from "../TermsCondition";
import { PrivacyPolicy } from "../PrivacyPolicy";
import UserService from "../../services/api/user";
import { TabComponent } from "../updated-budget/components/horongo_oruulalt/nationalAsset";


export default function MinistryPage(props) {
    const { user, setUser } = useContext(UserContext);
    const [isLoading, setIsLoading] = useState(false);
    const [historyData, setHistoryData] = useState([]);
    const [whoweare, setWhoweare] = useState([]);
    const [whatwedoing, setWhatwedoing] = useState([]);
    const [howwedoing, setHowwedoing] = useState([]);
    const [finalId, setFinalId] = useState([]);
    const [modalVisibleImage, setModalVisibleImage] = useState(false);
    //const [isTimeLinePic, setIsTimeLinePic] = useState(false);
    const { innerHeight } = window;
    const [bannerStructure, setBannerStructure] = useState([]);
    const [toggle, setToggle] = useState(false)
    const [popup, setPopup] = useState(false)
    const [headInfoLinks, setHeadInfoLinks] = useState([]);
    const [id, setId] = useState(null);
    const [notificationUsers, setNotificationUsers] = useState([]);
    const [selectedIndex, setSelectedIndex] = useState();
    const [notificationPopupVisible, setNotificationPopupVisible] = useState(false);
    let history = useHistory();
    const [fileNameData, setFileNameData] = useState(null);
    const [imageType, setImageType] = useState(1);
    const [withAnimationVisible, setWithAnimationVisible] = useState(false);
    const [withAnimationVisible1, setWithAnimationVisible1] = useState(false);
    const [withAnimationVisible2, setWithAnimationVisible2] = useState(false);
    const [withAnimationVisible3, setWithAnimationVisible3] = useState(false);
    const [dataFirst, setDataFirst] = useState([]);
    const [mouseVisibleBackColor, setMouseVisibleBackColor] = useState(false);
    const [mouseVisibleType, setMouseVisibleType] = useState(null);
    const [isAdmin, setIsAdmin] = useState()
    const { state } = useLocation()
    const [workerPopup, setWorkerPopup] = useState(true);
    const [gridBoxValue, setGridBoxValue] = useState(null);
    const [workerData, setWorkerData] = useState([])
    const [isDropdownOpen, setIsDropdownOpen] = useState(false)
    const [selectedAccordian, setSelectedAccordion] = useState(0)

    const fetchData = async () => {
        try {
            setIsLoading(true);
            let sendId = (user.username !== "02" || props.location.state.name === undefined) ? ((props.location.state === undefined || props.location.state === null) ?
                user.organization.id :
                (props.location.state.isfirst !== undefined ? props.location.state.toId : user.organization.id)) : props.location.state.org_id
            setId(sendId)
            let headInfo = await OrganizationService.getOrgHeadInfo(sendId);
            setHeadInfoLinks(headInfo.data)
            let result = await OrganizationService.getOrgStateStructureInfo(sendId);
            setDataFirst(result?.data?.[0]);
            let resultImage = await OrganizationService.getFileNameStateStructure(sendId);
            resultImage?.data[0] !== undefined ? setFileNameData(resultImage?.data[0]) : setFileNameData([]);
            let tmphistory = await result.data.find(element => element.name === 'history')
            let tmpwhoweare = await result.data.find(element => element.name === 'whoweare')
            let tmpwhatwedoing = await result.data.find(element => element.name === 'whatwedoing')
            let tmphowwedoing = await result.data.find(element => element.name === 'howwedoing')
            tmphistory !== undefined ? setHistoryData(tmphistory) : setHistoryData([])
            tmpwhoweare !== undefined ? setWhoweare(tmpwhoweare) : setWhoweare([])
            tmpwhatwedoing !== undefined ? setWhatwedoing(tmpwhatwedoing) : setWhatwedoing([])
            tmphowwedoing !== undefined ? setHowwedoing(tmphowwedoing) : setHowwedoing([])

            resultImage?.data[0] !== undefined && resultImage?.data[0].banner_structure_file_name !== null ? setBannerStructure(resultImage?.data[0].banner_structure_file_name) : setBannerStructure([])
            props.location.state && resultImage?.data[0] && resultImage?.data[0].banner_structure_file_name !== null && setBannerStructure(resultImage?.data[0].banner_structure_file_name);
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        fetchData()
        systemAdmin()
    }, [id]);

    const changePage = (url, type) => {
        let location = {}
        if (url === "/stateStructureType") {
            location = {
                pathname: url,
                state: { stateStructureId: dataFirst.state_structure_id, bannerStructure: bannerStructure },
            };
        }
        if (url === "/OrgList") {
            location = {
                pathname: url,
                state: { orgId: id, bannerFileName: fileNameData },
            };
        } else {
            location = {
                pathname: url,
                state: { selected: true, type: type, bannerFileName: fileNameData },
            };
        }
        history.push(location);
    }

    const onClick = (url) => {
        //console.log("url=> sadsadsa")
        if (url === "/home") {
            setToggle(true)
        } else if (url === "/structure") {
            setPopup(true)
        }
    }
    const style = {
        textJustify: {
            paddingTop: 3,
        },
        iconStyle: {
            width: 'auto',
            height: '30px',
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto'
        },
        iconInfoStyle: {
            width: '20px',
            height: '20px',
        },
        iconLink: {
            width: 'auto',
            height: '15px',
        },
        textLink: {
            color: '#000000',
            cursor: "pointer",
            fontFamily: 'Montserrat',
            fontWeight: 'bold',
            textAlign: 'center',
            justifyContent: 'center',
            alignContent: 'center',
            padding: 5,
            fontSize: 12,
            margin: 0
        },
        card2: {
            paddingRight: "1rem"
        },
        cardStyle: {
            boxShadow: '2px 2px 7px -1px rgba(0,0,0,0.25)',
            minHeight: innerHeight - 130,
            paddingRight: "0rem",
            position: "relative"
        },
        ministryLogo: {
            width: 'auto',
            height: '100px',
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
            paddingTop: '25px'
        }
    }

    const openImageModal = async (type) => {
        setImageType(type);
        if (type == 1 && fileNameData !== undefined && fileNameData.history_file_name !== null) {
            setModalVisibleImage(true);
        } else if (type == 2 && fileNameData !== undefined && fileNameData.all_head_file_name !== null) {
            setModalVisibleImage(true);
        } else {
            return notify("Мэдээлэл байхгүй байна", "warning", 2000);
        }

    }

    const systemAdmin = async () => {
        try {
            const res = await feedbackServices.getSystemOrgId()
            setIsAdmin(res.data)
        } catch (e) {
            console.log(e);
        }
    }

    const getByIsSeenNotificationUsers = async () => {
        try {
            const res = await notificationUserService.getByPopupNotificationUsers();
            if (res && res.code === 200) {
                if (res.data.length > 0) {
                    setNotificationPopupVisible(true);
                }
                setNotificationUsers(res.data);
            }
        } catch (e) {
            console.log(e);
        }
    }

    const onSelectionChanged = async (args) => {
        let notificationUserObj = {};

        if (args.name === 'selectedIndex') {
            setSelectedIndex(args.value);

            notificationUserObj.is_seen = 1;

            if (notificationUsers && notificationUsers[selectedIndex] && notificationUsers[selectedIndex].id && notificationUserObj) {
                await notificationUserService.updateNotificationUser(notificationUsers[selectedIndex].id, notificationUserObj);
            }

        } else if (args.name === 'onOptionChanged') {
            notificationUserObj.is_seen = 1;

            if (notificationUsers && notificationUsers[selectedIndex] && notificationUsers[selectedIndex].id && notificationUserObj) {
                await notificationUserService.updateNotificationUser(notificationUsers[selectedIndex].id, notificationUserObj);
            }
        }
    }
    const notificationPopupTitle = () => {
        return (
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    borderBottom: "1px solid rgba(221, 221, 221, 0.618)",
                    margin: "0 2rem",
                    padding: "20px 0"
                }}
            >
                <div className="align-self-end">
                    <img
                        alt=""
                        onClick={onClickNotificationPopupClose}
                        style={{ width: 15, height: 15, float: "right", cursor: "pointer" }}
                        src={closeIcon}
                    />
                </div>
                <div style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                }}>
                    <img
                        src={notificationUsers[selectedIndex]?.notification.is_system === 1 || notificationUsers[selectedIndex]?.user?.org_info?.id === isAdmin?.org_id ? logo :
                            notificationUsers[selectedIndex]?.user?.file_path
                        }
                        style={{
                            width: 55,
                            height: "100%",
                            objectFit: "scale-down",
                            boxSizing: "content-box",


                        }}
                    />
                    <br />
                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        width: "70%",
                    }}>

                        <span style={{
                            fontFamily: "Montserrat",
                            fontSize: "14px",
                            display: 'flex',
                            marginRight: 'auto',
                            fontWeight: notificationUsers[selectedIndex]?.notification.is_system === 1 ? "bold" : "",
                            paddingLeft: '0.5rem'
                        }}>
                            {notificationUsers[selectedIndex]?.notification.is_system === 1 || notificationUsers[selectedIndex]?.user?.org_info?.id === isAdmin?.org_id ? "Системийн мэдэгдэл" : notificationUsers[selectedIndex]?.user?.org_info?.name}
                        </span>
                    </div>
                    <span
                        style={{
                            fontFamily: "Montserrat",
                            fontWeight: 500,
                            fontSize: "12px",
                            color: "#767676",
                            width: "20%",
                            marginLeft: "auto",
                            alignSelf: "flex-end",
                        }}
                    >
                        {new moment(notificationUsers[selectedIndex].createdAt).format("YYYY-MM-DD HH:mm")}
                    </span>
                </div>

            </div>
        );

    }

    const onClickNotificationPopupClose = () => {
        setNotificationPopupVisible(false);
    }

    const onClickLeftMultiView = async () => {
        if (selectedIndex) {
            setSelectedIndex(selectedIndex - 1);
        }
    }

    const onClickRightMultiView = async () => {
        if (notificationUsers.length !== selectedIndex + 1) {
            setSelectedIndex(selectedIndex + 1);
        }
    }


    const notificationIconSrc = (string) => {
        switch (string) {
            case "xlsx":
                return fileXlsIcon
            case "png":
                return filePngIcon
            case "jpg":
                return fileJpgIcon
            case "pptx":
                return filePptIcon
            case "pdf":
                return filePdfIcon
            case "docx":
                return fileDocIcon
            default:
                return fileUnknownIcon
        }
    }

    const multiviewItem = ({ data }) => {
        let notificationImages = [];
        let notificationFiles = [];
        if (data?.notification?.notification_files) {

            data?.notification?.notification_files?.map((item, index) => {
                switch (item.file.file_extention) {
                    case "jpg":
                        notificationImages.push(item.file);
                        break;
                    case "png":
                        notificationImages.push(item.file);
                        break;
                    case "docx":
                        notificationFiles.push(item);
                        break;
                    case "xlsx":
                        notificationFiles.push(item);
                        break;
                    case "pptx":
                        notificationFiles.push(item);
                        break;
                    case "pdf":
                        notificationFiles.push(item);
                        break;
                    default:
                        notificationFiles.push(item);
                        break;
                }
            }
            )
        }



        return (
            <div className="multiview-item" style={{ margin: 20, marginTop: 0 }}>
                <div style={{
                    fontWeight: 700,
                    fontSize: "1rem",
                }}>
                    {data.notification.is_news === 1 &&
                        data.notification.news_author
                    }
                </div>
                <div style={{ fontWeight: "bold" }}>
                    {data.notification.title.length > 0 ? data.notification.title : ''}
                </div>
                <br />
                <div style={{
                    fontWeight: 400,
                    fontSize: "15px",
                    paddingBottom: 20,
                    margin: "1rem 0",
                    borderBottom: "1px solid rgba(221, 221, 221, 0.618)"
                }}
                    dangerouslySetInnerHTML={{ __html: data.notification.notif_info === data.notification.title ? '' : data.notification.notif_info }} />
                {notificationImages && <ImageViewer array={notificationImages} width="31.5rem" height="19rem" filename="file_name" margin="10px" />}
                <div style={{ padding: "20px 1rem" }}>
                    {notificationFiles?.length ?
                        (notificationFiles?.map((item, index) => {
                            return <a target="_self" key={index}
                                href={urlServer + "/api/fileServices/getFile/" + item.file.id}
                                style={{
                                    display: "inline-table",
                                    marginRight: 20,
                                    cursor: "pointer",
                                    textAlign: "center",
                                    textDecoration: "none"
                                }}>
                                {(() => {
                                    return <div style={{
                                        display: "flex",
                                        gap: ".5rem",
                                        alignItems: "flex-end",
                                        justifyContent: "flex-end",
                                        marginTop: 10
                                    }}>
                                        <img id={"notification_image_" + item.file.id} style={{ height: 40 }}
                                            src={notificationIconSrc(item.file.file_extention)} />
                                        <span>{item.file.file_name}</span>
                                    </div>
                                })()}
                            </a>
                        })
                        ) : null
                    }
                </div>
            </div>
        )
    }
    const renderImageMenu = props => {
        return (
            <Popup
                width={800}
                height={500}
                minWidth={'80%'}
                maxWidth={'100%'}
                minHeight={'100%'}
                visible={modalVisibleImage}
                title={imageType == 1 ? 'Түүхэн замнал' : 'Үе үеийн удирдлагууд'}
                onHiding={() => setModalVisibleImage(false)}
                resizeEnabled={true}
                dragEnabled={true}
                showTitle={true}
                hideOnOutsideClick={true}
            >
                <ScrollView height="97%" width="100%">
                    <div className="widget-container">
                        {
                            fileNameData && modalVisibleImage && <img
                                src={imageType == 1 ? urlServer + '/api/file/documents/' + fileNameData.history_file_name : urlServer + '/api/file/documents/' + fileNameData.all_head_file_name}
                                width={'100%'} />
                        }

                    </div>
                </ScrollView>

            </Popup>


        )
    }

    const getWorker = async () => {
        try {
            const res = await OrganizationService.getOrgWorkerToConnect();
            setWorkerData(res.data);
            return setIsLoading(false)
        } catch (e) {
            console.error(e);
        }
    }

    const handleGridBoxValue = (args) => {
        setGridBoxValue(args.selectedRowsData[0]);
        setIsDropdownOpen(false);
    }

    const contentRender = () => {
        return (workerData &&
            <ScrollView >
                <DataGrid
                    dataSource={workerData}
                    hoverStateEnabled={true}
                    height={'25rem'}
                    onSelectionChanged={handleGridBoxValue}
                    focusedRowEnabled={true}
                    keyExpr="worker_id"
                    selectedRowKeys={gridBoxValue}
                    columnAutoWidth={true}
                >
                    <GroupPanel visible={true} />
                    <SearchPanel visible={true} />
                    <Grouping autoExpandAll={false} />
                    <FilterRow visible={true} />
                    <Column dataField="heltes_name" caption="Хэлтэс" groupIndex={1} />
                    <Column dataField="last_name" caption="Овог" />
                    <Column dataField="first_name" caption="Нэр" />
                    <Column dataField="position_name" caption="Албан тушаал" />
                    <Column dataField="email" caption="Имэйл хаяг" />
                    <Column dataField="mobile" caption="Утасны дугаар" width={'6rem'} />
                    <Scrolling mode="virtual" />
                    <Selection mode="single" />
                </DataGrid>
            </ScrollView>
        )
    }

    const setWorker = async () => {
        try {
            await AuthService.setOrgWorker(gridBoxValue);
            setWorkerPopup(false);
            notify({ message: 'Албан хаагчийн мэдээлэл холбогдлоо.', width: 'fit-content' }, "success", 2000);
            let res = await AuthService.getProfile()
            setUser(res.data)
        } catch (e) {
            console.log(e);
        }
    }

    useState(() => {
        getByIsSeenNotificationUsers()
        getWorker();
    });

    const checkTermsConditions = async (value) => {
        try {
            await UserService.approveTermsConditions(value)
            setUser({ ...user, terms_conditions: value })
        } catch (error) {
            console.log(error)
        }
    }

    const checkPrivacyPolicy = async (value) => {
        try {
            await UserService.approvePrivacyPolicy(value)
            setUser({ ...user, privacy_policy: value })
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <div style={{ fontFamily: 'Montserrat' }}>
            <LoadPanelComponent
                position={{ of: '#filePo' }}
                visible={isLoading}
            />
            {(whatwedoing && whatwedoing.worker_name !== null) && <Tooltip
                target="#worker1"
                position="left"
                // animation={animationConfig}
                visible={withAnimationVisible}
                hideOnOutsideClick={true}
            >
                <div style={{ display: 'flex', flexDirection: 'row', flex: 1, padding: 10 }}>
                    <div style={{ flex: 1, margin: 5, fontWeight: 'Bold' }}>Шинэчилсэн:</div>
                    <div style={{ flex: 1, margin: 5 }}>
                        <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
                            <div style={{ display: 'flex' }}>Огноо:&nbsp;<label
                                style={{ fontWeight: 'Bold' }}>{whatwedoing.approved_worker_date}</label></div>
                            <div style={{ display: 'flex' }}>Албан тушаал:&nbsp;<label
                                style={{ fontWeight: 'Bold' }}>{whatwedoing.position_name}</label></div>
                            <div style={{ display: 'flex' }}>Овог нэр:&nbsp;<label
                                style={{ fontWeight: 'Bold' }}>{whatwedoing.worker_name}</label></div>
                        </div>
                    </div>
                </div>
            </Tooltip>}
            {(historyData && historyData.worker_name !== null) && <Tooltip
                target="#worker2"
                position="left"
                // animation={animationConfig}
                visible={withAnimationVisible1}
                hideOnOutsideClick={true}
            >
                <div style={{ display: 'flex', flexDirection: 'row', flex: 1, padding: 10 }}>
                    <div style={{ flex: 1, margin: 5, fontWeight: 'Bold' }}>Шинэчилсэн:</div>
                    <div style={{ flex: 1, margin: 5 }}>
                        <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
                            <div style={{ display: 'flex' }}>Огноо:&nbsp;<label
                                style={{ fontWeight: 'Bold' }}>{historyData.approved_worker_date}</label></div>
                            <div style={{ display: 'flex' }}>Албан тушаал:&nbsp;<label
                                style={{ fontWeight: 'Bold' }}>{historyData.position_name}</label></div>
                            <div style={{ display: 'flex' }}>Овог нэр:&nbsp;<label
                                style={{ fontWeight: 'Bold' }}>{historyData.worker_name}</label></div>
                        </div>
                    </div>
                </div>
            </Tooltip>}
            {(howwedoing && howwedoing.worker_name !== null) && <Tooltip
                target="#worker3"
                position="left"
                // animation={animationConfig}
                visible={withAnimationVisible2}
                hideOnOutsideClick={true}
            >
                <div style={{ display: 'flex', flexDirection: 'row', flex: 1, padding: 10 }}>
                    <div style={{ flex: 1, margin: 5, fontWeight: 'Bold' }}>Шинэчилсэн:</div>
                    <div style={{ flex: 1, margin: 5 }}>
                        <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
                            <div style={{ display: 'flex' }}>Огноо:&nbsp;<label
                                style={{ fontWeight: 'Bold' }}>{howwedoing.approved_worker_date}</label></div>
                            <div style={{ display: 'flex' }}>Албан тушаал:&nbsp;<label
                                style={{ fontWeight: 'Bold' }}>{howwedoing.position_name}</label></div>
                            <div style={{ display: 'flex' }}>Овог нэр:&nbsp;<label
                                style={{ fontWeight: 'Bold' }}>{howwedoing.worker_name}</label></div>
                        </div>
                    </div>
                </div>
            </Tooltip>}
            {(whoweare && whoweare.worker_name !== null) && <Tooltip
                target="#worker4"
                position="left"
                // animation={animationConfig}
                visible={withAnimationVisible3}
                hideOnOutsideClick={true}
            >
                <div style={{ display: 'flex', flexDirection: 'row', flex: 1, padding: 10 }}>
                    <div style={{ flex: 1, margin: 5, fontWeight: 'Bold' }}>Шинэчилсэн:</div>
                    <div style={{ flex: 1, margin: 5 }}>
                        <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
                            <div style={{ display: 'flex' }}>Огноо:&nbsp;<label
                                style={{ fontWeight: 'Bold' }}>{whoweare.approved_worker_date}</label></div>
                            <div style={{ display: 'flex' }}>Албан тушаал:&nbsp;<label
                                style={{ fontWeight: 'Bold' }}>{whoweare.position_name}</label></div>
                            <div style={{ display: 'flex' }}>Овог нэр:&nbsp;<label
                                style={{ fontWeight: 'Bold' }}>{whoweare.worker_name}</label></div>
                        </div>
                    </div>
                </div>
            </Tooltip>}
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '0.4rem' }}>
                {
                    !isLoading && <OrgContact urlServer={urlServer} fileNameData={fileNameData && fileNameData.length === 0 ? null : fileNameData} orgId={id} />
                }
            </div>
            <div style={{ display: props.location.state === undefined ? 'none' : 'block' }}>
            </div>
            {
                whatwedoing &&
                <div className="card" style={{ flex: 1, borderColor: '#EEF0F4', fontFamily: 'Montserrat' }}>
                    {
                        whatwedoing.worker_name &&
                        <div style={{ position: "absolute", right: 10, top: -10 }}>
                            <img
                                id="worker1"
                                loading="lazy"
                                width={25}
                                height={25}
                                src={info1}
                                onMouseEnter={() => setWithAnimationVisible(!withAnimationVisible)}
                                onMouseLeave={() => setWithAnimationVisible(!withAnimationVisible)}
                                style={{ cursor: 'pointer' }}
                                alt={"info-button"} />
                        </div>
                    }
                    <div style={{ padding: 20 }}>
                        <label><b>{whatwedoing.description}</b></label>
                        <div
                            style={style.textJustify}
                            dangerouslySetInnerHTML={{ __html: whatwedoing.value }}
                        ></div>
                    </div>
                </div>
            }

            {
                whoweare &&
                <div className="card" style={{ flex: 1, marginTop: 20, borderColor: '#EEF0F4' }}>
                    {whoweare.worker_name &&
                        <div style={{ position: "absolute", right: 10, top: -10 }}>
                            <img
                                id="worker4"
                                loading="lazy"
                                width={25}
                                height={25}
                                src={info1}
                                onMouseEnter={() => setWithAnimationVisible3(!withAnimationVisible3)}
                                onMouseLeave={() => setWithAnimationVisible3(!withAnimationVisible3)}
                                style={{ cursor: 'pointer' }}
                                alt={"info-button"} />
                        </div>
                    }
                    <div style={{ padding: 20 }}>
                        <label><b>{whoweare.description}</b></label>
                        <div
                            style={style.textJustify}
                            dangerouslySetInnerHTML={{ __html: whoweare.value }}
                        ></div>
                        <br />
                        <div className="row">
                            {headInfoLinks && !isLoading && headInfoLinks.map((item, index) => (
                                <div id="whoWeAre1" className="card" style={{ padding: 5, backgroundColor: mouseVisibleType === 'whoWeAre1' + index && mouseVisibleBackColor ? '#EFF1F3' : '#fff', borderColor: '#EEF0F4' }} key={index}>
                                    <Link key={index} to={{
                                        pathname: '/org/head',
                                        state: {
                                            stateStructureId: dataFirst?.state_structure_id,
                                            bannerStructure: bannerStructure,
                                            workerInfo: item,
                                            bannerFileName: fileNameData
                                        }
                                    }} style={{ textDecoration: 'none' }}
                                    >
                                        <p style={style.textLink}
                                            onMouseEnter={() => { setMouseVisibleBackColor(true); setMouseVisibleType('whoWeAre1' + index); }}
                                            onMouseLeave={() => { setMouseVisibleBackColor(false); setMouseVisibleType(null); }}>{item.structure_name} {item.position_name}</p>
                                    </Link>
                                </div>

                            ))}
                            {
                                !isLoading &&
                                <>
                                    {
                                        dataFirst && dataFirst.is_sub_org == 1 &&
                                        <div id="whoWeAre2" className="card" style={{ cursor: "pointer", padding: 5, backgroundColor: mouseVisibleType === 'whoWeAre2' && mouseVisibleBackColor ? '#EFF1F3' : '#fff', borderColor: '#EEF0F4' }} onClick={() => changePage('/OrgList', 1)}>
                                            <p style={style.textLink} onMouseEnter={() => { setMouseVisibleBackColor(true); setMouseVisibleType('whoWeAre2'); }}
                                                onMouseLeave={() => { setMouseVisibleBackColor(false); setMouseVisibleType(null); }}>{user.start_of_ancestry === id && user.start_of_ancestry !== 642 ? 'Сайдын эрхлэх асуудлын хүрээний байгууллагууд' : 'Харьяа байгууллагууд'}</p>
                                        </div>
                                    }
                                    <div id="whoWeAre3" className="card" style={{ width: 150, cursor: "pointer", padding: 5, backgroundColor: mouseVisibleType === 'whoWeAre3' && mouseVisibleBackColor ? '#EFF1F3' : '#fff', borderColor: '#EEF0F4' }} onClick={() => onClick('/home')}>
                                        <p style={style.textLink} onMouseEnter={() => { setMouseVisibleBackColor(true); setMouseVisibleType('whoWeAre3'); }}
                                            onMouseLeave={() => setMouseVisibleBackColor(false)}>Эрхэм зорилго</p>
                                    </div>
                                    <div id="whoWeAre4" className="card" style={{ width: 100, cursor: "pointer", padding: 5, backgroundColor: mouseVisibleType === 'whoWeAre4' && mouseVisibleBackColor ? '#EFF1F3' : '#fff', borderColor: '#EEF0F4' }} onClick={() => onClick('/structure')}>
                                        <p style={style.textLink} onMouseEnter={() => { setMouseVisibleBackColor(true); setMouseVisibleType('whoWeAre4'); }}
                                            onMouseLeave={() => { setMouseVisibleBackColor(false); setMouseVisibleType(null); }}>Бүтэц</p>
                                    </div>
                                    {
                                        dataFirst && dataFirst.is_harya_negj == 1 &&
                                        <div id="whoWeAre5" className="card" style={{ textAlign: 'center', alignItems: 'center', alignSelf: 'center', alignContent: 'center', justifyContent: 'center', height: 50, width: 100, cursor: "pointer", padding: 5, backgroundColor: mouseVisibleType === 'whoWeAre5' && mouseVisibleBackColor ? '#EFF1F3' : '#fff', borderColor: '#EEF0F4' }} onClick={() => changePage('/stateStructureType', 1)}>
                                            <p style={style.textLink} onMouseEnter={() => { setMouseVisibleBackColor(true); setMouseVisibleType('whoWeAre5'); }}
                                                onMouseLeave={() => { setMouseVisibleBackColor(false); setMouseVisibleType(null); }}>Харьяа нэгж</p>
                                        </div>
                                    }

                                </>

                            }
                        </div>
                        <div>
                        </div>
                    </div>

                </div>
            }

            <div className="card" style={{ flex: 1, marginTop: 20, borderColor: '#EEF0F4' }}>
                {howwedoing.worker_name &&
                    <div style={{ position: "absolute", right: 10, top: -10 }}>
                        <img
                            id="worker3"
                            loading="lazy"
                            width={25}
                            height={25}
                            src={info1}
                            onMouseEnter={() => setWithAnimationVisible2(!withAnimationVisible2)}
                            onMouseLeave={() => setWithAnimationVisible2(!withAnimationVisible2)}
                            style={{ cursor: 'pointer' }}
                            alt={"info-button"} />
                    </div>
                }
                {howwedoing && <div style={{ padding: 20 }}>
                    <label><b>{howwedoing.description}</b></label>
                    <div
                        style={style.textJustify}
                        dangerouslySetInnerHTML={{ __html: howwedoing.value }}
                    ></div>
                    <br />
                    {finalId === user.organization.id &&
                        <div>
                            <Link to='/budget'><span className={'row'}><img alt="email"
                                className="ab-icon"
                                style={style.iconLink}
                                src={link} /><p
                                    style={style.textLink}> Төсөв</p></span></Link>
                        </div>
                    }

                </div>}
            </div>
            <div className="card" style={{ flex: 1, marginTop: 20, borderColor: '#EEF0F4' }}>
                {historyData.worker_name &&
                    <div style={{ position: "absolute", right: 10, top: -10 }}>
                        <img
                            id="worker2"
                            loading="lazy"
                            width={25}
                            height={25}
                            src={info1}
                            onMouseEnter={() => setWithAnimationVisible1(!withAnimationVisible1)}
                            onMouseLeave={() => setWithAnimationVisible1(!withAnimationVisible1)}
                            style={{ cursor: 'pointer' }}
                            alt={"info-button"} />
                    </div>
                }
                {!isLoading && historyData && <div style={{ padding: 20 }}>
                    <label><b>{historyData.description}</b></label>
                    <div
                        style={style.textJustify}
                        dangerouslySetInnerHTML={{ __html: historyData.value }}
                    ></div>
                    <br />
                    <div className="row" style={{ marginTop: 20, marginLeft: -10 }}>
                        {
                            fileNameData && fileNameData.history_file_name !== null &&
                            <div id="historyData1" className="card" style={{ cursor: "pointer", padding: 5, backgroundColor: mouseVisibleType === 'historyData1' && mouseVisibleBackColor ? '#EFF1F3' : '#fff', borderColor: '#EEF0F4' }}>

                                <span onClick={() => openImageModal(1)}>
                                    <p style={style.textLink} onMouseEnter={() => { setMouseVisibleBackColor(true); setMouseVisibleType('historyData1'); }}
                                        onMouseLeave={() => { setMouseVisibleBackColor(false); setMouseVisibleType(null); }}>Түүхэн замнал</p></span>

                                {/*   { user.start_of_ancestry === 144 && user.position_id === 1 ? <img loading="lazy" src={timeLinePic} style={{ width: '100%', height: '100%', "padding-right":10}}/> :'' }
                                        {user.start_of_ancestry === 144 && user.position_id === 1 && <a style={{ cursor: 'pointer' }} onClick={() => setIsTimeLinePic(true)}><span class={'row'}><img
                                            alt="email" className="ab-icon" style={style.iconLink} src={link}/><p style={{ ...style.textLink, borderBottom: '1px solid' }}>Түүхэн замнал</p></span></a>}*/}
                            </div>
                        }
                        {fileNameData && fileNameData.all_head_file_name !== null &&
                            <div id="historyData2" className="card" style={{ cursor: "pointer", padding: 5, backgroundColor: mouseVisibleType === 'historyData2' && mouseVisibleBackColor ? '#EFF1F3' : '#fff', borderColor: '#EEF0F4' }}>
                                <span onClick={() => openImageModal(2)}>
                                    <p style={style.textLink} onMouseEnter={() => { setMouseVisibleBackColor(true); setMouseVisibleType('historyData2'); }}
                                        onMouseLeave={() => { setMouseVisibleBackColor(false); setMouseVisibleType(null); }}>Үе үеийн удирдлагууд</p></span>
                            </div>
                        }
                    </div>
                </div>}
                {dataFirst && dataFirst.worker_name && !isLoading &&
                    <div style={{
                        flexDirection: 'row',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        marginTop: 30,
                        marginBottom: 20, marginRight: 20
                    }}>
                        <img
                            loading="lazy"
                            width={20}
                            height={20}
                            src={info}
                        />
                        <div style={{ display: 'flex', flexDirection: 'row', marginLeft: 10 }}>
                            <div style={{ display: 'flex', fontWeight: 'Bold' }}>Шинэчилсэн:</div>
                            <div style={{ display: 'flex', marginLeft: 10 }}>Огноо:&nbsp;<label
                                style={{ fontWeight: 'Bold' }}>{dataFirst.approved_worker_date}</label></div>
                            <div style={{ display: 'flex', marginLeft: 10 }}>Албан тушаал:&nbsp;<label
                                style={{ fontWeight: 'Bold' }}>{dataFirst.position_name}</label></div>
                            <div style={{ display: 'flex', marginLeft: 10 }}>Овог нэр:&nbsp;<label
                                style={{ fontWeight: 'Bold' }}>{dataFirst.worker_name}</label></div>
                        </div>
                    </div>
                }
            </div>
            {/* <Popup
                    visible={isTimeLinePic}
                    title={'Түүхэн замнал'}
                    onHiding={() => setIsTimeLinePic(false)}
                    resizeEnabled={true}
                    dragEnabled={true}
                    showTitle={true}
                    hideOnOutsideClick={true}
                >
                    <ScrollView>
                        <img loading="lazy" src={timeLinePic}
                             style={{width: '100%', height: '100%', paddingRight: 10}}/>
                    </ScrollView>
                </Popup>*/}
            {
                toggle && <Popup
                    width={800}
                    height={500}
                    minWidth={'80%'}
                    maxWidth={'100%'}
                    minHeight={'90%'}
                    visible={toggle}
                    title={user.username === "02" ? props.location.state.name : ""}
                    onHiding={() => setToggle(false)}
                    resizeEnabled={true}
                    dragEnabled={true}
                    showTitle={true}
                    hideOnOutsideClick={true}
                >
                    <ScrollView>
                        <UpdatedHome org_id={id} type={1} />
                    </ScrollView>
                </Popup>
            }
            {
                popup && <Popup
                    width={800}
                    height={500}
                    minWidth={'80%'}
                    maxWidth={'100%'}
                    minHeight={'90%'}
                    visible={popup}
                    title={user.username === "02" ? props.location.state.name : ""}
                    onHiding={() => setPopup(false)}
                    resizeEnabled={true}
                    dragEnabled={true}
                    showTitle={true}
                    hideOnOutsideClick={true}
                >
                    <ScrollView>
                        <UpdatedStructure org_id={id} />
                    </ScrollView>
                </Popup>
            }

            {/*  </div>*/}

            {notificationUsers.length ?
                (<Popup
                    visible={notificationPopupVisible}
                    setNotificationPopupVisible={setNotificationPopupVisible}
                    onVisibleChange={setNotificationPopupVisible}
                    dragEnabled={false}
                    hideOnOutsideClick={false}
                    showCloseButton={true}
                    showTitle={true}
                    titleComponent={notificationPopupTitle}
                    width="45rem"
                    height="auto"
                    maxHeight={"72vh"}
                // shading={true}
                // shadingColor="rgba(0, 0, 0, 0.2)"
                >
                    <div className="leftArrow" onClick={onClickLeftMultiView}><IoIosArrowBack /></div>
                    <div className="rightArrow" onClick={onClickRightMultiView}><IoIosArrowForward /></div>
                    <ScrollView height='100%' width='100%'>
                        <div style={{ marginLeft: 0 }}>
                            <MultiView
                                dataSource={notificationUsers}
                                selectedIndex={selectedIndex}
                                onOptionChanged={onSelectionChanged}
                                loop={false}
                                itemComponent={multiviewItem}
                                noDataText="Одоогоор мэдэгдэл байхгүй байна."
                                animationEnabled={true} />
                        </div>
                    </ScrollView>
                </Popup>) : null
            }
            {user.position_id !== 0 &&
                <Popup
                    visible={(window.location.host === 'localhost:3000' || window.location.host === 'staging.opengov.mn') ? false : user.worker_id === null ? true : false}
                    dragEnabled={false}
                    showCloseButton={true}
                    width={'40rem'}
                    height={'auto'}
                >
                    <div>
                        <div style={{ color: "#15186a", fontSize: "1rem", fontWeight: "bold", padding: '0 1rem 1rem', fontFamily: "Segoe UI" }}> АЛБАН ХААГЧ ХОЛБОХ: </div>
                        <div style={{ padding: '0 1rem 1rem', }}>
                            <Alert style={{ borderRadius: '0.35rem' }}
                                description={
                                    <div>
                                        <div style={{ fontWeight: 'bold', fontSize: '0.8rem', marginLeft: '2.5rem' }}>АНХААРУУЛГА</div>
                                        <div style={{ display: "flex", gap: '1rem' }}>
                                            <span><TbUserCheck color="#faad14" size={'1.5rem'} /></span>
                                            <p style={{ fontSize: '0.8rem' }}>Албан хаагчийн мэдээллээ системийн хэрэглэгчид холбосноор үндсэн цэс рүү шилжих боломжтой.</p>
                                        </div>
                                        <div style={{ display: "flex", gap: '1rem' }}>
                                            <span><TbUserQuestion color="#faad14" size={'1.5rem'} /></span>
                                            <p style={{ fontSize: '0.8rem' }}>Албан хаагчийн хувийн мэдээлэл системд бүртгэгдээгүй тохиолдолд байгууллагын Админ эрхээр хандан бүртгэх боломжтой ба бидэнтэй 7507-7171 утсаар холбогдоно уу!</p>
                                        </div>
                                    </div>}
                                type="warning"
                            />
                        </div>
                        <div style={{ padding: '0 1rem' }}>
                            <Form
                                formData={user}
                            >
                                <Item dataField="username" editorOptions={{ disabled: true, width: '50%' }}>
                                    <Label text="Нэвтрэх нэр" />
                                </Item>
                                <Item dataField="organization.name" editorOptions={{ disabled: true, width: '100%' }}>
                                    <Label text="Байгууллага" />
                                </Item>
                                <Item dataField="worker_id" editorType='dxSelectBox' >
                                    <Label text="Албан хаагч" />
                                    <DropDownBox
                                        value={gridBoxValue}
                                        openOnFieldClick={true}
                                        placeholder="Ажилтан сонгох..."
                                        displayExpr={() => {
                                            return gridBoxValue && ((gridBoxValue.last_name ? gridBoxValue.last_name.slice(0, 1) + '. ' : '') + gridBoxValue.first_name);
                                        }}
                                        valueExpr={"worker_id"}
                                        onOptionChanged={(e) => {
                                            if (e.name === 'opened') {
                                                setIsDropdownOpen(e.value)
                                            }
                                        }}
                                        opened={isDropdownOpen}
                                        validationMessagePosition="auto"
                                        dataSource={workerData}
                                        contentRender={contentRender}
                                        dropDownOptions={{ width: '50rem', maxWidth: '60rem', height: '25rem' }}
                                    />
                                </Item>
                                <SimpleItem>
                                    <Button
                                        onClick={setWorker}
                                        formMethod="submit"
                                        disabled={!gridBoxValue ? true : false}
                                        style={{
                                            backgroundColor: "#003696",
                                            color: "whitesmoke",
                                            border: "1px solid #003696",
                                            borderRadius: "10px",
                                            width: "10rem",
                                            height: "36px",
                                            textAlign: "center",
                                            fontFamily: "Segoe UI",
                                            fontSize: "12px",
                                            fontWeight: "600",
                                            float: 'right',
                                            margin: '1rem 0'
                                        }}
                                        text="Хадгалах"
                                    />
                                </SimpleItem>
                            </Form>
                        </div>
                    </div>
                </Popup>
            }
            {
                <Popup
                    visible={user.worker_id && (user.terms_conditions === false || user.privacy_policy === false)}
                    showCloseButton={false}
                    width={1000}
                    height={780}
                >
                    <div style={{ background: '#ffeedf', color: '#f99d4b', padding: 10, borderRadius: 5, margin: '0 5px 15px 5px' }}>
                        <strong>САНАМЖ: </strong>Та "OpenGov" төсвийн нэгдсэн системийн <strong>ҮЙЛЧИЛГЭЭНИЙ НӨХЦӨЛ</strong> болон <strong>НУУЦЛАЛЫН БОДЛОГО</strong>-той бүрэн танилцана уу!
                    </div>
                    <div>
                        <div className='card'>
                            <TabComponent>
                                {['Үйлчилгээний нөхцөл', 'Нууцлалын бодлого'].map((e, index) => (
                                    <div
                                        key={index}
                                        onClick={() => setSelectedAccordion(index)}
                                        className={`tab ${e.id} ${selectedAccordian === index && "active"}`}
                                    >
                                        <span className="title">{e}</span>
                                    </div>
                                ))}
                            </TabComponent>
                            <div style={{ margin: '20px 5px 20px 20px', height: 500 }}>
                                {selectedAccordian === 0 ? <TermsCondition /> : <PrivacyPolicy />}
                            </div>
                        </div>
                        {selectedAccordian === 0 ?
                            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '15px 5px'}}>
                                <div style={{ display: 'flex', gap: 5 }}>
                                    <input type="checkbox" id='terms_conditions' checked={user.terms_conditions} onClick={() => setSelectedAccordion(1)} onChange={(e) => checkTermsConditions(e.target.checked)} />
                                    <label for='terms_conditions'>Үйлчилгээний нөхцөлийг зөвшөөрч байна.</label>
                                </div>
                            </div>
                            : <div style={{margin: '15px 5px'}}>
                                <div style={{ display: 'flex', gap: 5 }}>
                                    <input type="checkbox" id='privacy_policy' checked={user.privacy_policy} onClick={() => setSelectedAccordion(0)} onChange={(e) => checkPrivacyPolicy(e.target.checked)} />
                                    <label for='privacy_policy'>Нууцлалын бодлогыг хүлээн зөвшөөрч байна.</label>
                                </div>
                            </div>
                        }
                    </div>
                </Popup>
            }
            {modalVisibleImage && renderImageMenu()}
        </div>
    )
}

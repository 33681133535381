import React, {useEffect, useState} from "react";
import '../introduction/css/styles.css';
import notify from "devextreme/ui/notify";
import OrganizationService from "../../services/api/organization";
import Button from "../introduction/components/ButtonComponent";
import TopHeader from '../introduction/components/TopHeaderComponent';
import {LoadPanel} from "devextreme-react/load-panel";
import LoadPanelComponent from "../budget/components/loadPanel";

const Operation = () => {
    
    const [mainData, setMainData] = useState([]);
    const [headerData, setDataHeader] = useState([]);
    const [selectedItem, setSelectedItem] = useState(0);
    const [loader, setLoader] = useState(true);

    const loadData = async () => {
        try {
            const r = await OrganizationService.retrieveOrganizationIntroduction();
            console.log('r -> ', r);
            setMainData(r.section2);
            setDataHeader(r.header);
            setLoader(false);
        } catch (e) {
            notify(e.message, "error", 2000);
        }
    }
    
    useEffect(() => {
        loadData();
    }, [])
    
    return (
        <div>
            <LoadPanelComponent position={{ of: "#dataGrid" }} visible={loader} />
            <TopHeader data={headerData}/>
            <div className='introduction-main-context'>
                <div className='introduction-multi-choice-1'>
                    {mainData.map((item, index) => {
                        return (
                            <Button
                                key={index}
                                innerText={item && item.description}
                                styles={`${index === selectedItem && 'intro-button-active'}`}
                                onClick={() => {
                                    setSelectedItem(index);
                                }}
                            />
                        )
                    })}
                </div>
                <div className = 'introduction-multi-choice-1-display'>
                    <div className = 'introduction-multi-choice-1-display-text'>
                        {
                            mainData.length > 0 && <div dangerouslySetInnerHTML={{ __html: mainData[selectedItem].value.replaceAll(';', '<br><br>') }}/>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Operation
import React, {useContext, useEffect, useState, useRef} from 'react';
import 'devextreme-react/text-area';
import 'devextreme-react/text-area';
import {
    TreeList, Editing, Column, Lookup, Form, Item, RowDragging, RequiredRule
} from 'devextreme-react/tree-list';
import notify from 'devextreme/ui/notify';
import {UserContext} from "../../hooks/UserContext";
import OrganizationServices from "../../services/api/organization";
import _ from "lodash";
import LoadPanelComponent from '../../components/LoadPanelComponent';
import StateStructureSelectComponent from "../../components/StateStructureSelectComponent";
import OrganizationService from "../../services/api/organization";
import WorkerDropDownBoxComponent from "../settings/components/WorkerDropDownBoxComponent";
import DataGrid, {
    FilterRow,
    Scrolling,
    Selection
} from "devextreme-react/data-grid";
import Button from "devextreme-react/button";
import ScrollView from "devextreme-react/scroll-view";
import {Popup} from "devextreme-react/popup";
import SelectBox from "devextreme-react/select-box";
import HtmlEditor, {ImageUpload, MediaResizing, TableResizing, Toolbar} from "devextreme-react/html-editor";
import iconUtga from "../../assets/imgs/icon/icon-utga.png";
import iconDelete from "../../assets/imgs/icon/icon-delete.png";
import {urlServer} from "../../shared/lib/request";
export const tabs = [
    {name: 'From This Device', value: ['file']},
    {name: 'From the Web', value: ['url']},
    {name: 'Both', value: ['file', 'url']},
];

const fontValues = [
    "Montserrat",
    "Arial",
    "Helvetica Neue",
    "Courier New",
    "Georgia",
    "Impact",
    "Lucida Console",
    "Tahoma",
    "Times New Roman",
    "Verdana",
];
const sizeValues = ["8pt", "10pt", "12pt", "14pt", "18pt", "24pt", "36pt"];
const headerValues = [false, 1, 2, 3, 4, 5];
const sizeOptions = {placeholder: "Хэмжээ"};
const fontOptions = {placeholder: "Фонт"};
const statusData = [
    {
        "id": 0,
        "name": "Идэвхгүй"
    },
    {
        "id": 1,
        "name": "Идэвхтэй"
    }];

export default function OrgStructure() {
    const {user} = useContext(UserContext);
    const [dataSource, setDataSource] = useState([]);
    const [loader, setLoader] = useState(false);
    const [stateList, setStateList] = useState([]);
    const [workerData, setWorkerData] = useState([])
    const [lookUpList, setLookUpList] = useState([]);
    const myBabyRef = useRef(null);
    const [popupAlba, setPopupAlba] = useState(false)
    const [selectedOrg, setSelectedOrg] = useState(null);
    const [selectedOrgName, setSelectedOrgName] = useState(null);
    const [orgStructureAttribute, setOrgStructureAttribute] = useState([]);
    const [valueNameList, setValueNameList] = useState([]);
    const [orderNo, setOrderNo] = useState(null);
    //const [valueName, setValueName] = useState(null);
    const [atrValue, setAtrValue] = useState(null);
    const [ssAtrValue, setSSAtrValue] = useState(null);
    const [valueNewLoader, setValueNewLoader] = useState(false);
    const HtmlRef = useRef(null);
    const myGridRef = useRef(null);
    const [selectedValueTypeName, setSelectedValueTypeName] = useState([]);
    const [valueChangeLoader, setValueChangeLoader] = useState(false);
    const [description, setDescription] = useState(null);
    useEffect(() => {
        getList();
    }, [])
    const animation = {
        show: {
            type: "slide",
            from: {
                left: 1000,
                opacity: 0.2
            },
            to: {
                opacity: 1
            },
            duration: 500
        }
        ,
        hide: {
            type: "slide",
            to: {
                opacity: 0,
                right: -1000
            },
            duration: 500
        }
    }
    const getList = async (refresh) => {
        try {
            setLoader(true);
            let getOrgStructureByState = OrganizationServices.getOrgStructureByState();
            let getParentOrgs = OrganizationServices.getParentOrgs();
            let getOrgWorker = refresh !== 1 ? OrganizationService.getOrgWorker(null,null,0,0,'') : [];
            Promise.all([getOrgStructureByState, getParentOrgs, getOrgWorker])
                .then((values) => {
                    let getOrgStructureByStateData = values[0];
                    let getParentOrgsData = values[1];
                    let getOrgWorkerData = values[2];

                    let r = _.filter(getOrgStructureByStateData.data, {parent_id: null});

                    setStateList(getParentOrgsData.data);
                    refresh !==1 && setWorkerData(getOrgWorkerData.data) ;
                    setLookUpList(r);
                    setDataSource(getOrgStructureByStateData.data);

                    return setLoader(false)
                })
                .catch(error => {
                    console.log(error)
                    return setLoader(false)
                })

            // let result = await OrganizationServices.getOrgStructureByState();
            // let r1 = await OrganizationServices.getParentOrgs();
            // let r2 = await OrganizationService.getOrgWorker();

        } catch (e) {
            console.error(e);
            setLoader(false)
        }
    }
    const setData = async (e) => {
        if (e.changes && e.changes.length !== 0) {
            if (e.changes[0].type === "update") {
                try {
                    await OrganizationServices.updateOrgStructure(e.changes[0].data);
                    //await getList();
                    notify('Амжилттай хадгалагдлаа', "success", 2000);
                } catch (e) {
                    notify('Засварлалтыг хадгалах явцад алдаа гарлаа', "error", 2000);
                }
            } else if (e.changes[0].type === "insert") {
                try {
                    delete e.changes[0].data.id;
                    await OrganizationServices.setOrgStructure(e.changes[0].data);
                    //await getList();
                    notify('Амжилттай үүслээ', "success", 2000);
                } catch (e) {
                    notify('Шинээр үүсэх явцад алдаа гарлаа', "error", 2000);
                }

            } else if (e.changes[0].type === "remove") {
                try {
                    await OrganizationServices.removeOrgStructure(e.changes[0].key);
                    //await getList();
                    notify('Амжилттай устгагдлаа', "success", 2000);
                } catch (e) {
                    notify('Энэхүү алба, хэлтэсд ажилтан бүртгэлтэй байгаа тул устгах боломжгүй!', "error", 5000);
                }

            }
        }

    }
    //Column custom renderings
    const statusRender = (data) => {
        return (data.value === 1 ? <div style={{
            backgroundColor: '#50CB93',
            width: '80%',
            color: 'white',
            borderRadius: '3px',
            cursor: 'pointer',
            margin: 'auto',
            textAlign: 'center'
        }}>Идэвхтэй</div> : <div style={{
            backgroundColor: '#FF616D',
            width: '80%',
            color: 'white',
            borderRadius: '3px',
            cursor: 'pointer',
            margin: 'auto',
            textAlign: 'center'
        }}>Идэвхгүй</div>)
    }
    const renderAlba = (data) => {
        return (
            <div style={{
                //backgroundColor: '#fff',
                width: '100%',
                color: '#0c0c0c',
                cursor: 'pointer',
                margin: 'auto',
                textAlign: 'left'
            }}>{data.value}</div>
        )
    }
    const onEditorPreparing = (e) => {
        if (e.parentType === "dataRow") {
            return;
        }
        if (e.row && e.row.isNewRow) {
            e.editorOptions.visible = true;
        }
        if (e.row && !e.row.isNewRow) {
            e.editorOptions.visible = false;
        }
    }
    const initNewRowEvent = (e) => {
        let r;
        //let r1;
        //console.log('e.data', e.data);
        if (e.data.parent_id === 0) {
            r = [];
        } else {
            r = _.filter(dataSource, {id: e.data.parent_id});
            //r1 = _.filter(stateList, {id: r[0].state_structure_id});
        }
        setLookUpList(r);
        //setStateList(r1);
    }

    const onEditingStartEvent = (e) => {
        let r;
        if (e.data.parent_id) {
            r = _.filter(dataSource, {id: e.data.parent_id});
        }
        setLookUpList(r);
    }
    const onReorder = async (e) => {
        const visibleRows = e.component.getVisibleRows();
        let sourceData = e.itemData;
        if (e.dropInsideItem) {
            sourceData = {...sourceData, parent_id: visibleRows[e.toIndex].key};
            await OrganizationServices.updateOrgStructure(sourceData);
            await getList(1);
            notify('Амжилттай хадгалагдлаа', "success", 2000);

        } else {
            const toIndex = e.fromIndex > e.toIndex ? e.toIndex - 1 : e.toIndex;
            let targetData = toIndex >= 0 ? visibleRows[toIndex].node.data : null;

            if (targetData && e.component.isRowExpanded(targetData.id)) {
                sourceData = {...sourceData, Head_ID: targetData.id};
                targetData = null;
                await OrganizationServices.updateOrgStructure(sourceData);
                await getList(1);
                notify('Амжилттай хадгалагдлаа', "success", 2000);
            } else {
                const headId = targetData ? targetData.parent_id : -1;
                if (sourceData.parent_id !== headId) {
                    sourceData = {...sourceData, parent_id: headId};
                    await OrganizationServices.updateOrgStructure(sourceData);
                    await getList(1);
                    notify('Амжилттай хадгалагдлаа', "success", 2000);
                }
            }
        }
    }
    const onDragChange = (e) => {
        const visibleRows = e.component.getVisibleRows();
        const sourceNode = e.component.getNodeByKey(e.itemData.id);
        let targetNode = visibleRows[e.toIndex].node;

        while (targetNode && targetNode.data) {
            if (targetNode.data.id === sourceNode.data.id) {
                e.cancel = true;
                break;
            }
            targetNode = targetNode.parent;
        }
    }

    /*  const onEditingStartEvent = (e) => {
          setIniitalAD(e.data);
      }*/

    const onCellPreparedHandler = (e) => {
        if (e.rowType === 'header') {
            e.cellElement.wordWrapEnabled = true;
            e.cellElement.style.fontFamily = 'Montserrat'
            e.cellElement.style.fontWeight = 'semibold'
            e.cellElement.style.color = "#15186A"
            e.cellElement.style.backgroundColor = "#EEF0F4"
        }
        if (e.rowType === 'data') {
            e.cellElement.style.fontFamily = 'Montserrat'
            e.cellElement.style.fontWeight = 'semibold'
            e.cellElement.style.color = "#000000"
        }
    }
    const onEditorPreparingAd = (e) => {
        if (e.parentType === "dataRow" && e.dataField !== "description") {
            return;
        }
        if (e.row && e.row.isNewRow) {
            e.editorOptions.visible = true;
        }
        if (e.row && !e.row.isNewRow) {
            // e.editorOptions.visible = false;
            e.editorOptions.disabled = true;
        }

    }

    const getOsAttributeTypeName = async (typeValue, osId) => {
        const result = await OrganizationService.getOsAttributeTypeName(typeValue, osId);
        setValueNameList(result.data);
    }
    const getOsAttribute = async (id) => {
        setLoader(true)
        let result = await OrganizationService.getOrgStrructureAttributeId(id);
        setOrgStructureAttribute(result);
        // console.log("result",result)
        setLoader(false)
    }
    const onStructure = async (e) => {
        if (e.columnIndex * 1 === 2 && e.rowType === 'data')
        {
            setLoader(true);
            setSelectedOrg(e.row.data.id);
            setSelectedOrgName(e.value);
            await getOsAttribute(e.row.data.id);
            await getOsAttributeTypeName(3, e.row.data.id);
            setPopupAlba(true);
            setLoader(false);
        } /*else {
            return notify(
                {
                    message: 'Байгууллага холбогдоогүй учир төсөв удирдах боломжгүй', width: 300, position: {
                        my: 'right top',
                        at: 'right top',
                        of: "#floating-div-2"
                    },
                    animation: animation
                }
                // error
                , "warning", 2000);
        }*/
    }
    const customDeleteRow = (grid, selectedRowIndex) => {
        grid.current.instance.deleteRow(selectedRowIndex);
    }
    const columnInfo = (data) => {
        // console.log('data', data);
        setAtrValue(data.data.value);
        //setSSAEdit(data.data.policy_desc === undefined ? data.data.policy_desc : null);
        setSSAtrValue(data.data);
        setOrderNo(null);
        setDescription(data.data.description);
        setValueChangeLoader(true);
    }
    const valueCell = (data) => {
        // console.log('daaaaaaa', data);
        return <div style={{display: 'flex', justifyContent: 'flex-start', flexDirection: 'row'}}>
            <Button
                icon={iconUtga}
                style={{
                    width: 'auto',
                    height: 'auto',
                    opacity: 1,
                    backgroundColor: "#2a4565"
                }}
                onClick={() => {
                    columnInfo(data)
                }}
            />
            <Button
                icon={iconDelete}
                style={{
                    width: 'auto',
                    height: 'auto',
                    opacity: 1,
                    backgroundColor: "#F44336"
                }}
                onClick={() => {
                    customDeleteRow(myGridRef, data.rowIndex)
                }}
            />
        </div>
    }

    const onValueChangeInfo = (e) => {
        HtmlRef.current = e.value
        //setAtrValue(e.value);
    }
    const onChangeValueTypeName = async (e) => {
        setSelectedValueTypeName(e);
    }
    const requestFullscreen=()=> {
        /* Get the element you want displayed in fullscreen */
        const element = document.getElementById("htmlEditorEdit");
        // Use a guard clause to exit out of the function immediately
        if (element.requestFullscreen) {
            element.requestFullscreen();
        } else if (element.webkitRequestFullscreen) {
            // Safari
            element.webkitRequestFullscreen();
        } else if (element.msRequestFullscreen) {
            // IE
            element.msRequestFullscreen();
        }
    }
    const toolbarButtonOptions = {
        icon: 'fullscreen',
        stylingMode: 'text',
        onClick: requestFullscreen,
    };
    const onSaveSSA = async () => {
        try {
            let data = {}
            data.name = valueChangeLoader ? ssAtrValue.name : selectedValueTypeName.name;
            data.value = HtmlRef.current !== null ? HtmlRef.current : atrValue;
            data.description = valueChangeLoader ? ssAtrValue.description : selectedValueTypeName.description;
            data.is_opendata = valueChangeLoader ? (ssAtrValue.is_opendata === true ? 1 : 0) : 0;
            data.org_structure_id = selectedOrg;
            data.created_user_id = user.id;
            data.updated_user_id = user.id;
            data.order_no = orderNo;
            let result = await OrganizationService.updateOrgStateAttribute(data);
            let r = await OrganizationService.getOrgStrructureAttributeId(selectedOrg);
            setOrgStructureAttribute(r);
            const r2 = await OrganizationService.getOsAttributeTypeName(3, selectedOrg);
            setValueNameList(r2.data);
            if (result?.code === 200) {
                await getOsAttributeTypeName(3, selectedOrg);
                setValueNewLoader(false);
                setValueChangeLoader(false);
                HtmlRef.current = null;
                return notify({
                    message: 'Амжилттай хадгаллаа', width: 300, position: {
                        my: 'right top',
                        at: 'right top',
                        of: "#floating-div-2"
                    },
                    animation: animation
                }, "success", 4000);

            }
        } catch (error) {
            console.log('Хадгалах үйлдэл амжилтгүй боллоо ', error)
            return notify(
                "Хадгалах үйлдэл амжилтгүй боллоо"
                // error
                , "warning", 4000);

        }
    }
    const onSavingEvent = async (e) => {
        if (e.changes[0].type === "remove") {
            try {
                setLoader(true);
                await OrganizationService.removeOSA(e.changes[0].key);
                await getOsAttributeTypeName(3,selectedOrg);
                setLoader(false);
                notify({
                    message: 'Амжилттай устгагдлаа', width: 300, position: {
                        my: 'right top',
                        at: 'right top',
                        of: "#floating-div-2"
                    },
                    animation: animation
                }, "success", 2000);
            } catch (e) {
                notify('Устгах явцад алдаа гарлаа', "error", 2000);
            }
        }
    }
    const renderValuePopup = (props) => {
        return (
            <Popup
                visible={valueChangeLoader}
                onHiding={() => setValueChangeLoader(false)}
                dragEnabled={false}
                hideOnOutsideClick={true}
                showTitle={true}
                title={description !== null ? description : "Мэдээлэл"}
                width="95%"
                // key={data.data.id}
                // titleRender={renderTitle}
                height="90%"
            >
                <ScrollView height="100%" width="100%" >
                    <div className="widget-container">
                        <div className="col-12 row" style={{
                            justifyContent:"flex-end",
                            gap: '2rem',
                            padding: '5px 15px'
                        }}>
                            <Button text="Хадгалах" onClick={onSaveSSA}
                                    width={150} type="default" icon={'save'} style={{color: '#fff', textAlign: "center",family: "'Montserrat', sans-serif",marginRight:10,backgroundColor: "#225D72"}}/>
                        </div>
                        {
                            <div height="100%" width="100%" id="htmlEditorEdit" style={{backgroundColor:"#fff"}}>
                                <HtmlEditor defaultValue={atrValue} onValueChanged={onValueChangeInfo} valueType="html">
                                    <MediaResizing enabled={true}/>
                                    <TableResizing enabled={true}/>
                                    <ImageUpload fileUploadMode="both"
                                                 tabs={tabs[2].value}
                                                 uploadUrl={urlServer + '/api/file/documents/'}
                                                 uploadDirectory="/"/>
                                    <Toolbar multiline={true}>
                                        <Item name="separator"/>
                                        <Item
                                            name="size"
                                            acceptedValues={sizeValues}
                                            options={sizeOptions}
                                        />
                                        <Item
                                            name="font"
                                            acceptedValues={fontValues}
                                            options={fontOptions}
                                        />
                                        <Item name="separator"/>
                                        <Item name="separator"/>
                                        <Item name="bold"/>
                                        <Item name="italic"/>
                                        <Item name="strike"/>
                                        <Item name="underline"/>
                                        <Item name="separator"/>
                                        <Item name="alignLeft"/>
                                        <Item name="alignCenter"/>
                                        <Item name="alignRight"/>
                                        <Item name="alignJustify"/>
                                        <Item name="separator"/>
                                        <Item name="orderedList"/>
                                        <Item name="bulletList"/>
                                        <Item name="separator"/>
                                        <Item
                                            name="header"
                                            acceptedValues={headerValues}
                                        />
                                        <Item name="separator"/>
                                        <Item name="color"/>
                                        <Item name="background"/>
                                        <Item name="separator"/>
                                        <Item name="link"/>
                                        <Item name="image"/>
                                        <Item name="separator"/>
                                        <Item name="clear"/>
                                        <Item name="codeBlock"/>
                                        <Item name="blockquote"/>
                                        <Item name="separator"/>
                                        <Item name="insertTable"/>
                                        <Item name="deleteTable"/>
                                        <Item name="insertRowAbove"/>
                                        <Item name="insertRowBelow"/>
                                        <Item name="deleteRow"/>
                                        <Item name="insertColumnLeft"/>
                                        <Item name="insertColumnRight"/>
                                        <Item name="deleteColumn"/>
                                        <Item name="separator"/>
                                        <Item
                                            widget={'dxButton'}
                                            options={toolbarButtonOptions}
                                        />
                                    </Toolbar>
                                </HtmlEditor>
                            </div>
                        }
                    </div>
                </ScrollView>
            </Popup>
        )
    }
    //New value create
    const renderValueNewPopup = (props) => {
        return (
            <Popup
                visible={valueNewLoader}
                onHiding={() => setValueNewLoader(false)}
                dragEnabled={false}
                hideOnOutsideClick={true}
                showTitle={true}
                title={"Шинэ мэдээлэл"}
                width="95%"
                height="90%"
            >
                <ScrollView height="100%" width="100%">
                    <div className="widget-container">
                        <div className="col-12 row" style={{
                            justifyContent: "space-between",
                            gap: '2rem',
                            marginBottom: 10
                        }}>
                            <SelectBox
                                items={_.filter(valueNameList, {exists_name: null})}
                                value={selectedValueTypeName}
                                style={{border: '1px solid #DDDDDD'}}
                                displayExpr="description"
                                onValueChanged={(e) => onChangeValueTypeName(e.value)}
                                placeholder="Төрөл сонгох"/>
                            <Button text="Хадгалах" onClick={onSaveSSA} width={150} type="default" icon={'save'}
                                    style={{color: '#fff', textAlign: "center",family: "'Montserrat', sans-serif",marginRight:10,backgroundColor: "#225D72"}}/>
                        </div>

                        <div height="100%" width="100%" id="htmlEditorEdit" style={{backgroundColor:"#fff"}}>
                            <HtmlEditor onValueChanged={onValueChangeInfo} valueType="html"
                                        placeholder={"Та шинэ мэдээлэлээ оруулаарай"}>
                                <MediaResizing enabled={true}/>
                                <TableResizing enabled={true}/>
                                <ImageUpload tabs={tabs[2].value} fileUploadMode="base64"/>
                                <Toolbar multiline={true}>
                                    <Item name="separator"/>
                                    <Item
                                        name="size"
                                        acceptedValues={sizeValues}
                                        options={sizeOptions}
                                    />
                                    <Item
                                        name="font"
                                        acceptedValues={fontValues}
                                        options={fontOptions}
                                    />
                                    <Item name="separator"/>
                                    <Item name="separator"/>
                                    <Item name="bold"/>
                                    <Item name="italic"/>
                                    <Item name="strike"/>
                                    <Item name="underline"/>
                                    <Item name="separator"/>
                                    <Item name="alignLeft"/>
                                    <Item name="alignCenter"/>
                                    <Item name="alignRight"/>
                                    <Item name="alignJustify"/>
                                    <Item name="separator"/>
                                    <Item name="orderedList"/>
                                    <Item name="bulletList"/>
                                    <Item name="separator"/>
                                    <Item
                                        name="header"
                                        acceptedValues={headerValues}
                                    />
                                    <Item name="separator"/>
                                    <Item name="color"/>
                                    <Item name="background"/>
                                    <Item name="separator"/>
                                    <Item name="link"/>
                                    <Item name="image"/>
                                    <Item name="separator"/>
                                    <Item name="clear"/>
                                    <Item name="codeBlock"/>
                                    <Item name="blockquote"/>
                                    <Item name="separator"/>
                                    <Item name="insertTable"/>
                                    <Item name="deleteTable"/>
                                    <Item name="insertRowAbove"/>
                                    <Item name="insertRowBelow"/>
                                    <Item name="deleteRow"/>
                                    <Item name="insertColumnLeft"/>
                                    <Item name="insertColumnRight"/>
                                    <Item name="deleteColumn"/>
                                    <Item name="separator"/>
                                    <Item
                                        widget={'dxButton'}
                                        options={toolbarButtonOptions}
                                    />
                                </Toolbar>
                            </HtmlEditor>
                        </div>
                    </div>
                </ScrollView>
            </Popup>
        )
    }
    const onChangeValueNew = (e) => {
        if(_.filter(valueNameList, {exists_name: null}).length >0) {
            setOrderNo(null);
            //setValueName(null);
            setAtrValue(null);
            setSSAtrValue(null);
            HtmlRef.current = null;
            setValueNewLoader(true);
        }
        else
        {
            return notify({
                message: 'Шинээр алба хэлтэсийн бүлгийн мэдээлэл оруулах боломжгүй админд хандана уу', width: 300, position: {
                    my: 'right top',
                    at: 'right top',
                    of: "#floating-div-2"
                },
                animation: animation
            }, "warning", 4000);
        }
    }
    const renderFixMenu = props => {
        return (
            <Popup
                visible={popupAlba}
                onHiding={() => setPopupAlba(false)}
                hideOnOutsideClick={true}
                showCloseButton={true}
                height="95vh"
                showTitle={true}
                title={selectedOrgName + "-Алба,хэлтэсийн мэдээлэл удирдах"}
            >
                <div className="col-12 row" style={{justifyContent: "flex-end", gap: '2rem', padding: '5px'}}>
                    <Button text="Мэдээлэл оруулах" onClick={onChangeValueNew} width={'auto'} type="default" icon={"add"}/>
                </div>
                <ScrollView height="97%" width="100%">
                    <LoadPanelComponent
                        position={{of: '#renderFixMenu1'}}
                        visible={loader}
                    />
                    <div>
                        <DataGrid dataSource={orgStructureAttribute}
                                  noDataText="Дата ороогүй байна."
                                  keyExpr="id"
                                  style={{ color: '#fff', textAlign: "center",family: "'Montserrat', sans-serif"}}
                                  onSaved={onSavingEvent}
                            //onEditingStart={onEditingStartEvent}
                                  onEditorPreparing={onEditorPreparingAd}
                                  wordWrapEnabled={true}
                                  showBorders={true}
                                  showColumnLines={true}
                                  showRowLines={true}
                                  focusedRowEnabled={true}
                                  ref={myGridRef}
                                  onCellPrepared={onCellPreparedHandler}
                        >
                            <Selection mode="single"/>
                            <Column dataField="description" caption="Талбарын нэр" dataType="string"/>
                            {/* <Column dataField="is_opendata" caption="Олон нийтэд харуулах эсэх" dataType="boolean">
                            </Column>*/}
                            <Column dataField="created_user_name" caption="Үүсгэсэн хэрэглэгч" dataType="string"/>
                            <Column dataField="updated_user_name" caption="Зассан хэрэглэгч" dataType="string"/>
                            <Column dataField="createdAt" dataType="date" format="yyyy-MM-dd hh:mm:ss"
                                    caption="Үүсгэсэн огноо"/>
                            <Column dataField="updatedAt" dataType="date" format="yyyy-MM-dd hh:mm:ss"
                                    caption="Зассан огноо"/>
                            <Column dataField="value" caption="Үйлдэл" cellRender={valueCell} width={100}/>
                            <Editing
                                allowUpdating={false}
                                allowDeleting={false}
                                allowAdding={false}
                                mode="popup"
                                useIcons={true}
                                texts={{
                                    cancelAllChanges: 'Болих',
                                    cancelRowChanges: 'Болих',
                                    confirmDeleteMessage: 'Энэ мэдээллийг устгах уу?',
                                    confirmDeleteTitle: 'Баталгаажуулах',
                                    deleteRow: 'Устгах',
                                    editRow: 'Өөрчлөх',
                                    saveAllChanges: 'Хадгалах',
                                    saveRowChanges: 'Хадгалах',
                                    undeleteRow: 'Буцаах',
                                    validationCancelChanges: 'Баталгаажуулах болих',
                                }}
                            >
                            </Editing>

                        </DataGrid>
                    </div>
                </ScrollView>
            </Popup>
        )
    }
    return (
        <div>
            <LoadPanelComponent
                shadingColor="rgba(0,0,0,0.4)"
                position={{of: '#password'}}
                visible={loader}
                message=''
                showPane={false}
            />
            <div>
                <div className="card-body">
                    <TreeList
                        dataSource={dataSource}
                        keyExpr="id"
                        parentIdExpr="parent_id"
                        rootValue={-1}
                        onEditorPreparing={onEditorPreparing}
                        ref={myBabyRef}
                        onSaved={setData}
                        onEditingStart={onEditingStartEvent}
                        onInitNewRow={initNewRowEvent}
                        showBorders={true}
                        showColumnLines={true}
                        showRowLines={true}
                        onCellClick={onStructure}
                        //rowAlternationEnabled={true}
                        noDataText='Дата байхгүй байна.'
                        onCellPrepared={onCellPreparedHandler}
                    >
                        <Selection mode="single"/>
                        <FilterRow visible={true}/>
                        <Scrolling mode="standard"/>
                        <RowDragging
                            onDragChange={onDragChange}
                            onReorder={onReorder}
                            allowDropInsideItem={true}
                            allowReordering={true}
                            showDragIcons={true}
                        />
                        <Editing
                            mode="popup"
                            allowUpdating={true}
                            allowDeleting={true}
                            confirmDelete={true}
                            useIcons={true}
                            confirmSave={true}
                            allowAdding={true}
                            texts={{
                                cancelAllChanges: 'Болих бүгд',
                                cancelRowChanges: 'Болих',
                                confirmDeleteMessage: 'Энэ байгууллагын алба хэлтэс мэдээллийг устгах уу?',
                                confirmDeleteTitle: 'Баталгаажуулах',
                                deleteRow: 'Устгах',
                                editRow: 'Өөрчлөх',
                                addRow: 'Нэмэх',
                                addRowToNode: 'Нэмэх',
                                saveAllChanges: 'Хадгалах бүгд',
                                saveRowChanges: 'Хадгалах',
                                undeleteRow: 'Буцаах',
                                validationCancelChanges: 'Баталгаажуулах болих',
                            }}>
                            <Popup title="Байгууллагын алба хэлтэс мэдээлэл" showTitle={true} width={800} height={600}/>
                            <Form>
                                <Item itemType="group" colCount={1} colSpan={2}>
                                    <Item dataField="state_structure_id"/>
                                    <Item dataField="parent_id"/>
                                    <Item dataField="name"/>
                                    <Item dataField="worker_id"/>
                                    <Item dataField="level"/>
                                    <Item dataField="order_no"/>
                                    <Item dataField="status"/>
                                </Item>
                            </Form>
                        </Editing>



                        <Column
                            dataField="state_structure_id"
                            caption="Байгууллага"
                            // width={500}
                            allowFiltering={true}
                            allowUpdating={true}
                            visible={true}
                            editCellComponent={StateStructureSelectComponent}
                        >
                            <RequiredRule
                                message='Байгууллага оруулна уу'
                            />
                            <Lookup
                                valueExpr="id"
                                displayExpr={(item) => {
                                    return item && `${item.name}`
                                }}
                                dataSource={stateList}

                            />
                        </Column>
                        <Column
                            dataField="name"
                            caption="Алба хэлтэс нэр"
                            allowEditing={true}
                            // editCellTemplate = {}
                            cellRender={renderAlba}
                            cssClass="custom"
                            allowFiltering={true}
                            width={400}
                        >
                            <RequiredRule
                                message='Алба хэлтэс нэр оруулна уу'
                            />
                        </Column>
                        <Column
                            dataField="parent_id"
                            caption="Алба хэлтэс" visible={false}>
                            <Lookup
                                allowClearing={true}
                                dataSource={lookUpList}
                                valueExpr="id"
                                displayExpr="name"/>
                        </Column>
                        <Column dataField="worker_id" caption="Удирдах албан хаагч" dataType="string"
                                editCellComponent={WorkerDropDownBoxComponent}>
                            <Lookup
                                valueExpr="wid"
                                displayExpr="first_name"
                                dataSource={workerData}
                            />
                        </Column>
                        <Column
                            dataField="level"
                            caption="Түвшин" dataType={"number"} width={70}>
                        </Column>
                        <Column
                            dataField="order_no"
                            caption="Эрэмбэ" dataType={"number"} width={70}>
                        </Column>
                        {/* <Column
                            dataField="status"
                            caption="Төлөв" visible={false}>
                            <RequiredRule
                                message='Төлөв оруулна уу'
                            />
                            <Lookup
                                allowClearing={true}
                                dataSource={statusData}
                                valueExpr="id"
                                displayExpr="name"/>
                        </Column> */}
                        <Column
                            cssClass="custom"
                            dataField="status"
                            caption="Төлөв"
                            allowEditing={true}
                            cellRender={statusRender}
                            allowFiltering={true}
                            width={100}>
                                 <Lookup
                                allowClearing={true}
                                dataSource={statusData}
                                valueExpr="id"
                                displayExpr="name"/>
                        </Column>
                        {/*<Column caption="Үйлдэл" type="buttons" alignment="left" fixed={true} fixedPosition="left">
                            <TreeButton icon="info" name="general" text="Ерөнхий мэдээлэл удирдах"
                                        onClick={onStructure}/>
                            <TreeButton icon="add" name="general" text="Нэмэх"
                                        onClick={customAddRow}/>
                            <TreeButton icon="edit" name="general" text="Засварлах"
                                        onClick={(data)=> {
                                            customEditRow(myBabyRef,data.row.rowIndex)}}/>
                        </Column>*/}
                        {/*<Paging enabled={true} />*/}
                    </TreeList>
                </div>
            </div>
            {popupAlba && renderFixMenu()}
            {valueChangeLoader && renderValuePopup()}
            {valueNewLoader && renderValueNewPopup()}
        </div>
    )
}


import React, {useEffect, useState} from "react"
import statsService from "../../../services/api/stat.service"
import notify from "devextreme/ui/notify"
import PivotGridDataSource from "devextreme/ui/pivot_grid/data_source"
import PivotGrid, {FieldChooser, FieldPanel, HeaderFilter} from "devextreme-react/pivot-grid"
import PopupDetailStatByYear from "./PopupDetailStatByYear"
import PopupDetailStatByMonth from "./PopupDetailStatByMonth"
import {Popup} from "devextreme-react"
import {numberWithCommas11} from "../../../util"

const NumberFive = (props) => {
  const [chartData, setChartData] = useState([])
  const [toggle, setToggle] = useState(false)
  const [titleHeaders, setTitleHeaders] = useState([])
  const [selectedCode, setSelectedCode] = useState("")
  const [tier, setTier] = useState(1)
  const [neverChangingData, setNeverChangingData] = useState([])

  const loadData = async () => {
    try {
      let res = await statsService.selectAllData(
        props.year,
        props.list_id,
        props.tbl_id
      )
      let isSingleAttribute = !res?.every(i => i?.SCR_MN1)
      setNeverChangingData(res)
      const dataSource = new PivotGridDataSource({
        fields: [
          {
            caption: "Аймаг",
            dataField: "SCR_MN",
            area: "row",
            width: 300,
            sortBy: "displayText",
          },
          {
            caption: "Аймаг",
            dataField: "CODE",
            area: "row",
            width: 300,
            sortBy: "displayText",
            visible: false,
          },
          {
            caption: "Төрөл",
            dataField: "SCR_MN1",
            area: "column",
            visible: !isSingleAttribute,
            sortBy: "displayText",
          },
          {
            caption: "Дүн ",
            dataField: "DTVAL_CO",
            dataType: "number",
            summaryType: "sum",
            area: "data",
            width: 100,
            customizeText: (e) => numberWithCommas11(e.value ?? 0),
          },
          {
            caption: "Эзлэх %",
            dataField: "DTVAL_CO",
            dataType: "number",
            width: 30,
            sortBy: "displayText",
            summaryType: "sum",
            summaryDisplayMode: "percentOfColumnGrandTotal",
            area: "data",
          },
        ],
        store: res,
      })
      setChartData(dataSource)
    } catch (e) {
      notify(e.message)
    }
  }

  useEffect(() => {
    loadData()
  }, [props.year, props.list_id, props.tbl_id])
 
  const cellClickHandler = (event) => {
    if (event.cell.columnType === "D") {

    }
    if (event.cell.columnType === "GT") {
      setTier(event.cell.rowPath.length)
      setToggle(true)
      setSelectedCode(
        neverChangingData.find((i) => i.SCR_MN === event.cell.rowPath[0]).CODE
      )
      setTitleHeaders(event.cell.rowPath)
    }
  }

  const renderTitle = () => {
    return (
      <div
        style={{
          paddingTop: "10px",
          display: "flex",
          gap: "10px",
          flexDirection: "row",
        }}
      >
        <div style={{flex: 1, display: "flex", flexDirection: "column"}}>
          {titleHeaders && titleHeaders.length !== 0 ? (
            titleHeaders.map((item, index) => (
              <span
                style={{
                  marginLeft: "10px",
                  border:
                    index === titleHeaders.length - 1
                      ? "1px solid green"
                      : "none",
                }}
                key={index}
              >
                {index + 1 + ". " + item}
              </span>
            ))
          ) : (
            <span></span>
          )}
          &nbsp;
        </div>
        <div style={{flex: 1, display: "flex", flexDirection: "column"}}>
          <span>Он: {props.year}</span>
        </div>
      </div>
    )
  }
  const onCellPrepared = (e) => {
    if (
      (e.cell.text !== undefined && e.cell.text.substr(6, 1) === "-") ||
      e.cell.text === ""
    ) {
      e.cell.expanded = true
      let cell = e.cellElement.querySelector(".dx-expand-icon-container")
      if (cell) cell.innerHTML = ""
    }

    if (e.area === "row") {
      e.cellElement.style.fontFamily = "Montserrat"
      e.cellElement.style.fontWeight = "semibold"
      e.cellElement.style.color = "#15186A"
      e.cellElement.style.backgroundColor = "#EEF0F4"
    }
    if (e.area === "data") {
      e.cellElement.style.fontFamily = "Montserrat"
      e.cellElement.style.fontWeight = "semibold"
      e.cellElement.style.color = "#000000"
      if (e.cell.type === "T") {
        e.cellElement.innerHTML = `<div class=\"dx-expand-icon-container\"><span class=\"dx-expand\"></span></div><span>${
          e.cell.path[e.columnIndex]
        }- Нийт</span>`
      }
    }
  }

  return (
    <div style={{width: "100%"}}>
      <Popup
        visible={toggle}
        hideOnOutsideClick={true}
        onHiding={() => {
          setToggle(false)
          setSelectedCode("")
        }}
        hideEmptySummaryCell={true}
        titleRender={renderTitle}   
      >
        <div id="spinner" style={{display: "flex", flexDirection: "row"}}>
          <div style={{flex: 1, height: "100%", width: "50%"}}>
            <PopupDetailStatByYear
              list_id={props.list_id}
              tbl_id={props.tbl_id}
              selectedCode={selectedCode}
              titleHeaders={titleHeaders}
              tier={tier}
            />
          </div>
          <div style={{flex: 1, height: "100%", width: "50%"}}>
            <PopupDetailStatByMonth
              list_id={props.list_id}
              tbl_id={props.tbl_id}
              year={props.year}
              selectedCode={selectedCode}
              tier={tier}
              titleHeaders={titleHeaders}
            />
          </div>
        </div>
      </Popup>
      <PivotGrid
        dataSource={chartData}
        style={{fontFamily: "'Montserrat', sans-serif"}}
        rowHeaderLayout={"tree"}
        allowSorting={true}
        showColumnTotals={true}
        allowSortingBySummary={true}
        scrolling={true}
        onCellPrepared={onCellPrepared}
        wordWrapEnabled={true}
        width={"100%"}
        onCellClick={cellClickHandler}
        texts={{grandTotal: "Нийт", dataNotAvailable: "Дата байхгүй байна.", noData: "Дата байхгүй байна."}}
      >
      <HeaderFilter allowSearch={true}/>
        <FieldChooser
          enabled={true}
          allowSearch={true}
          texts={{
            dataFields: "Дата талбар",
            columnFields: "Багана талбар",
            filterFields: "Шүүх талбар",
            rowFields: "Эгнээ талбар",
            allFields: "Бүх талбарууд",
            
          }}
        />
        <FieldPanel
          showColumnFields={true}
          showDataFields={true}
          showFilterFields={false}
          showRowFields={true}
          allowFieldDragging={true}
          visible={true}
        />
      </PivotGrid>
    </div>
  )
}

export default NumberFive

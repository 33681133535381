import request from '../../shared/lib/request'

// function get(referenceName) {
//     return request({
//         url:    `/message/${referenceName}`,
//         method: 'GET'
//     });
// }


function getRefEconomicCategory() {
    return request({
        url : '/refEconomicCategory',
        method: 'GET'
    });
}

function getPermissionMenuRole() {
    return request({
        url: '/rs/getPermissionMenuRole',
        method: 'GET'
    })
}

function getRoles() {
    return request({
        url: '/refs/getRoles',
        method: 'GET'
    })
}

function getMenu() {
    return request({
        url: '/refs/getMenu',
        method: 'GET'
    })
}

function getPermissions() {
    return request({
        url: '/refs/getPermissions',
        method: 'GET'
    })
}
function getAgendas() {
    return request({
        url: '/refs/refAgendaProp',
        method: 'GET'
    })
}
function getAgendasProps(org_id) {
    return request({
        url: '/refs/refAgendaProp/'+org_id,
        method: 'GET'
    })
}
function refGovAgendaProp(org_id) {
    return request({
        url: '/refs/refGovAgendaProp/'+org_id,
        method: 'GET'
    })
}
function getEventsProps(org_id) {
    return request({
        url: '/refs/refEventProp/'+org_id,
        method: 'GET'
    })
}
function getAnda() {
    return request({
        url: '/refs/refAgenda',
        method: 'GET'
    })
}

function getGovAgendas() {
    return request({
        url: '/refs/refGovAgenda',
        method: 'GET'
    })
}
function getEvents() {
    return request({
        url: '/refs/refEventProp',
        method: 'GET'
    })
}

function getRefEvent() {
    return request({
        url: '/refs/refEvent',
        method: 'GET'
    })
}

function getRefEconCategory() {
    return request({
        url: '/refs/refEconCategory',
        method: 'GET'
    })
}

function getAimag() {
    return request({
        url: '/refs/getAimag',
        method: 'GET'
    });
}
function loadRefOrg(){
    return request({
        url:'/refs/refOrgTypes',
        method:'GET'
    })
}

function getOrganization(aimagId) {
    return request({
        url: `/refs/getOrganization?aimagId=${aimagId}`,
        method: 'GET'
    })
}

function getBudget() {
    return request({
        url: '/refs/budget_status',
        method: 'GET'
    })
}

const RefService = {
    getRefEconomicCategory,
    getPermissionMenuRole,
    getRoles,
    getMenu,
    getAimag,
    loadRefOrg,
    getAgendas,
    getEvents,
    getPermissions,
    getOrganization,
    getGovAgendas,
    getBudget,
    getAnda,
    getAgendasProps,
    getEventsProps,
    refGovAgendaProp,
    getRefEvent,
    getRefEconCategory,
}

export default RefService;

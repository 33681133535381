import React, {useEffect, useState, useContext} from 'react';
import {UserContext} from "../hooks/UserContext";
import OrganizationService from "../services/api/organization";

export default function ProcurementPage() {
    const [tender, setTender] = useState(null);
    const {user} = useContext(UserContext);
    useEffect(() => {
        getHeaderData(user.organization.id);
    }, [user.organization.id]);
    const getHeaderData = async (orgId) => {
        try {
            let result = await OrganizationService.getOrgContact(orgId);
            let tmptender = await result.data.find(element => element.name === 'tender');
            tmptender !== undefined ? setTender(tmptender.value.replaceAll('<p>', '').replaceAll('</p>', '')) : setTender(null);
        } catch (e) {
            console.error(e);
        }
    }

    return (
        <iframe width="100%" style={{height: '100vh'}}
                src={tender && tender.length > 0 ? tender : "https://www.tender.gov.mn/mn/invitation?year=2023"}
                allowFullScreen></iframe>
    )
}

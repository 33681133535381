import React, {useState, useEffect, useContext} from "react";
import {Menu, ResponsiveBox} from "devextreme-react";
import {Col, Item, Location, Row} from "devextreme-react/responsive-box";
import notify from "devextreme/ui/notify";
import lawServices from "../../services/api/law";
import {UserContext} from "../../hooks/UserContext";
import './static/yolo.scss';
import List from "devextreme-react/list";
import DataGrid, {Column, FilterRow, Pager} from "devextreme-react/data-grid";
import {Template} from "devextreme-react/core/template";
import lawIcon1 from "../../assets/imgs/lawIcon2.png";
import {Popup} from "devextreme-react/popup";
import strategy_icon from "../../assets/imgs/structureIcon2.png";
import {urlServer} from "../../shared/lib/request";
import ScrollView from "devextreme-react/scroll-view";
import SelectBox from "devextreme-react/select-box";
import {getYears} from "../../util";
import LoadPanelComponent from "../budget/components/loadPanel";

const UpdatedLawPage = () => {
    const {user} = useContext(UserContext);
    const [lawMenu, setLawMenu] = useState([]);
    const [lawData, setLawData] = useState([]);
    const [chosenValue, setChosenValue] = useState(user.organization.obt_id == 1 ? user.organization.id : (user.organization.obt_id == 3 && user.organization.parent_id == user.start_of_ancestry ? user.organization.id : (user.organization.obt_id == 2 ? (user.start_of_ancestry !== user.organization.parent_id ? user.organization.parent_id : user.organization.id) : user.organization.parent_id)));
    const [selectedKey, setSelectedKey] = useState([]);
    const [pageIndex, setPageIndex] = useState(0);
    const [link, setLink] = useState(null);
    const [popUpModal, setPopUpModal] = useState(false);
    const [fileName, setFileName] = useState(null);
    const [fileExtention, setFileExtention] = useState(null);
    const [filePopUpModal, setFilePopUpModal] = useState(false);
    const [copyData, setCopyData] = useState([]);
    const [selectedText, setSelectedText] = useState('');
    const [orgs, setOrgs] = useState([]);
    const [loader, setLoader] = useState(false);
    const groupBy = (array, key) => {
        return array.reduce((result, currentValue) => {
            (result[currentValue[key]] = result[currentValue[key]] || []).push(currentValue)
            return result
        }, {});
    }

    const getLawOrgList = async () => {
        const lawOrgList = await lawServices.getFilteredParentOrg();
        setOrgs(lawOrgList.data);
    };

    const loadLawMenus = async (orgId) => {
        try {
            setLoader(true);
            const menus = (await lawServices.getDocTypes(user.organization.id)).data;
            setLawMenu(menus);
            let data = (await lawServices.getLawByGroup(user.organization.id)).data;
            let filteredData = await groupBy(data, "law_doc_type")
            setCopyData(filteredData);
            if (menus && menus[0] && menus[0].law_doc_type) {
                let m = await filteredData[menus[0].law_doc_type]
                setLawData(m);
                setSelectedText(menus[0].name);
                setSelectedKey(menus[0].law_doc_type)
            }
            setLoader(false);
        } catch (e) {
            setLoader(false);
            notify(e.message, "error", 2000);
        }
    }

    const orderRender = (data) => {
        if(data.data.org_id == user.organization.id)
            return <div style={{fontWeight: "bold", color: "#004596"}}>{pageIndex * 15 + data.rowIndex + 1}</div>;
        return <div style={{color: "#171010"}}>{pageIndex * 15 + data.rowIndex + 1}</div>;
    };

    const orderRender1 = (data) => {
        if(data.data.org_id == user.organization.id)
            return <div style={{color: "#004596"}}>{data.data.name}</div>;
        return <div style={{color: "#171010"}}>{data.data.name}</div>;

    };

    const handleOptionChange = (e) => {
        if (e.fullName === 'paging.pageIndex') {
            setPageIndex(e.value)
        }
    }

    const hideInfo = () => {
        setPopUpModal(false);
    };

    const openModal = (data) => {
        setLink(data);
        setPopUpModal(true);
    }

    const renderLinkTemplate = (options) => {
        return (
            <div
                className="law-bnt-style"
                onClick={() => openModal(options.data.legal_url)}
            >
                {
                    options.data.legal_url ? <img
                        loading="lazy"
                        src={lawIcon1}
                        style={{width: "auto", height: "20px"}}
                    /> : ''
                }
            </div>
        )
    }

    const columnInfo = (data) => {
        setFileName(data.data.file_name);
        setFileExtention(data.data.file_extention)
        setFilePopUpModal(true);
    }

    const downloadCell = (data) => {
        return (
            <div>
                {data.value ? (
                    <a href={"/api/file/" + data.value} download>
                        <svg
                            t="1646982531282"
                            className="icon"
                            viewBox="0 0 1024 1024"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            p-id="2170"
                            width="20"
                            height="20"
                        >
                            <path
                                d="M1024 896v128H0v-320h128v192h768v-192h128v192zM576 554.688L810.688 320 896 405.312l-384 384-384-384L213.312 320 448 554.688V0h128v554.688z"
                                fill="#1452B4"
                                p-id="2171"
                            ></path>
                        </svg>
                    </a>
                ) : (
                    <svg
                        t="1647336550633"
                        className="icon"
                        viewBox="0 0 1024 1024"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        p-id="2170"
                        width="20"
                        height="20"
                    >
                        <path
                            d="M1024 896v128H0v-320h128v192h768v-192h128v192zM576 554.688L810.688 320 896 405.312l-384 384-384-384L213.312 320 448 554.688V0h128v554.688z"
                            fill="#cdcdcd"
                            p-id="2171"
                        ></path>
                    </svg>
                )}
            </div>
        );
    };

    const renderGridFile = (data) => {
        return data.text && <div style={{textAlign: 'center', width: 60}}>
            <div
                className="law-bnt-style"
                style={{paddingRight: 20}}
                onClick={() => columnInfo(data)}
            >
                <img
                    loading="lazy"
                    src={strategy_icon}
                    style={{width: "auto", height: "20px"}}
                />
            </div>
        </div>
    }

    useEffect(() => {
        loadLawMenus();
        getLawOrgList();
    }, [])

    const itemClickWorld = async (e) => {
        const currentItem = e.itemData;
        const key = await currentItem.law_doc_type
        setSelectedText(currentItem.name);
        setSelectedKey(key)
        let cType = currentItem.law_doc_type
        let m = copyData[cType]
        setLawData(m);
        setPageIndex(0);
    }

    return (
        <div id="lawLoadPanel">
            <Popup
                visible={popUpModal}
                onHiding={hideInfo}
                dragEnabled={false}
                hideOnOutsideClick={true}
            >
                <iframe src={link} title="title" width="100%" height="100%"/>
            </Popup>
            {fileName &&
                <Popup
                    width={1200}
                    maxWidth={'100%'}
                    minHeight={'100%'}
                    height={'100%'}
                    title={fileName && fileName}
                    visible={filePopUpModal}
                    onHiding={() => {
                        setFilePopUpModal(false)
                    }}
                    dragEnabled={false}
                    hideOnOutsideClick={true}
                >
                    {
                        fileExtention == 'application/pdf' ? <object width="100%" height="100%"
                                                                     data={urlServer + '/api/file/documents/' + fileName}
                                                                     type="application/pdf"/>
                            :
                            <ScrollView> <img
                                src={urlServer + '/api/file/documents/' + fileName}
                                width={'100%'}/> </ScrollView>

                    }

                </Popup>}
            <LoadPanelComponent position={{of: '#lawLoadPanel'}} visible={loader}/>
            {/*<div className={'card'} style={{padding: 10, margin: '0 0 10px 0'}}>*/}
            {/*    <ResponsiveBox>*/}
            {/*        <Row/>*/}
            {/*        <Row/>*/}
            {/*        <Col ratio={0.1}/>*/}
            {/*        <Col ratio={0.35}/>*/}
            {/*        <Col ratio={0.55}/>*/}
            {/*        <Item>*/}
            {/*            <Location screen="md lg" row={0} col={0}/>*/}
            {/*            <Location screen="xs sm" row={0} col={0} colspan={3}/>*/}
            {/*            <div style={{display: 'flex', alignItems: 'center', flex: 1}}>*/}
            {/*                <span style={{fontWeight: 700}}>Байгууллага:</span>*/}
            {/*            </div>*/}
            {/*        </Item>*/}
            {/*        <Item>*/}
            {/*            <Location screen="md lg" row={0} col={1}/>*/}
            {/*            <Location screen="xs sm" row={1} col={0} colspan={3}/>*/}
            {/*            <div>*/}
            {/*                {orgs && orgs.length !== 0 &&*/}
            {/*                    <SelectBox*/}
            {/*                        items={orgs}*/}
            {/*                        defaultValue={chosenValue}*/}
            {/*                        valueExpr="value_name"*/}
            {/*                        displayExpr="text_name"*/}
            {/*                        onValueChanged={(e) => {*/}
            {/*                            const fetchData = async () => {*/}
            {/*                                await loadLawMenus(e.value);*/}
            {/*                            };*/}
            {/*                            try {*/}
            {/*                                fetchData();*/}
            {/*                            } catch (error) {*/}
            {/*                                notify(error.message, "error", 2000);*/}
            {/*                            }*/}
            {/*                        }}*/}
            {/*                        placeholder="сонгох" style={{borderRadius:10}}*/}
            {/*                        placeholder="сонгох"/>}*/}
            {/*            </div>*/}
            {/*        </Item>*/}
            {/*    </ResponsiveBox>*/}
            {/*</div>*/}
            <ResponsiveBox style={{backgroundColor: '#FFF', borderRadius: '15px'}}>
                <Row/>
                <Row/>
                <Col ratio={0.4}/>
                <Col ratio={0.6}/>
                <Col ratio={1}/>
                <Item>
                    <Location screen="md lg" row={0} col={0}/>
                    <Location screen="xs sm" row={0} col={0} colspan={3}/>
                    <div style={{fontFamily: 'Segoe UI'}}>
                        <h3 style={{margin: '10px 0 0 22px',fontWeight:'light',fontSize:19,color:'#000000'}}>ХУУЛЬ, ЭРХ ЗҮЙ</h3>
                        <List
                            className="lawList"
                            selectionMode="single"
                            dataSource={lawMenu}
                            onItemClick={itemClickWorld}
                            selectedItemKeys={[selectedKey]}
                            displayExpr="name"
                            keyExpr="law_doc_type"
                            style={{fontWeight:'regular',fontSize:14}}
                        />
                    </div>
                </Item>
                <Item style={{overflowX: 'auto'}}>
                    <Location screen="md lg" row={0} col={1} colspan={2}/>
                    <Location screen="xs sm" row={1} col={0} colspan={3}/>
                    <div>
                        <DataGrid
                            className="lawDataGrid"
                            dataSource={lawData}
                            showBorders={true}
                            showRowLines={true}
                            hoverStateEnabled={true}
                            noDataText="Дата байхгүй байна."
                            paging={{
                                pageSize: 15,
                            }}
                            wordWrapEnabled={true}
                            onOptionChanged={handleOptionChange}
                            style={{fontFamily: "Segoe UI", fontWeight:'regular',fontSize:12, color:'#000000', }}
                        >
                            <FilterRow visible={true}/>
                            <Pager showNavigationButtons={true}/>
                            <Column
                                caption="№"
                                width={'2rem'}
                                cellRender={orderRender}
                            />
                            <Column
                                dataField="name"
                                caption={selectedKey === 12 ? "Нэр томъёо" : "Актын нэр"}
                                cellRender={orderRender1}
                                minWidth={300}
                            />
                            <Column
                                dataField="description"
                                caption="Тайлбар"
                                visible={selectedKey === 12 ? true : false}
                            />
                            <Column
                                dataField="law_no"
                                caption="Актын дугаар"
                                alignment="center"
                                visible={selectedKey === 12 ? false : selectedKey === 10 ? false : selectedKey === 6 ? false : true}
                                width={100}
                            />
                            <Column
                                dataField="submitted_date"
                                caption={"Батлагдсан хугацаа"}
                                alignment="center"
                                visible={selectedKey === 12 ? false : true}
                                width={100}
                            />
                            <Column
                                dataField="follow_date"
                                alignment="center"
                                caption={"Дагаж мөрдөх хугацаа"}
                                visible={selectedKey === 12 ? false : true}
                                width={100}
                            />
                            <Column
                                dataField="legal_url"
                                caption="Хуулийн холбоос"
                                cellTemplate="lawLinkTemplate"
                                alignment="center"
                                allowFiltering={false}
                                width={80}
                            />
                            <Column
                                dataField="file_name"
                                caption="Файлын холбоос"
                                alignment="center"
                                cellRender={renderGridFile}
                                width={80}
                            />
                            <Template name="download" render={downloadCell}/>
                            <Template name="lawLinkTemplate" render={renderLinkTemplate}/>
                        </DataGrid>
                    </div>
                </Item>
            </ResponsiveBox>
        </div>
    )
}

export default UpdatedLawPage;

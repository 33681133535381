import React from 'react';
import './nav-style.css'
import { ScrollView } from 'devextreme-react';

export default function TusuvTimeline() {
    let currentYear = new Date().getFullYear()
    let currentMonth = new Date().getMonth() + 1;
    let currentDay = new Date().getDate();

    let today = new Date(currentYear + '-' + currentMonth + '-' + currentDay).getTime();

    const data1 = [{
        id: 1,
        month: 1,
        date: null,
        detail: 'Санхүү, төсвийн асуудал эрхэлсэн Засгийн газрын гишүүн улсын төсөв, Ирээдүйн өв сангийн төсөв, Нийгмийн даатгалын сангийн төсөв, Эрүүл мэндийн даатгалын сангийн, жилийн төсвийн хуваарийг жил бүрийн 01 дүгээр сарын 01-ний дотор батлах.',
        color: '#448EF7',
        dateFormat: `${currentYear}-01-01`
    }]

    const data2 = [{
        id: 2,
        month: 2,
        date: null,
        detail: null,
        color: '#EEF0F4',
        dateFormat: `${currentYear}-02-01`
    }]

    const data3 = [{
        id: 3,
        month: 3,
        date: '3 сарын 15​',
        detail: 'Төсвийн тогтвортой байдлын зөвлөл дунд хугацааны төсвийн хүрээний мэдэгдлийн төслийг боловсруулахад ашиглах макро эдийн засгийн үндсэн үзүүлэлт, төлөв байдлын таамаглалын тооцоог хийж, жил бүрийн 03 дугаар сарын 15-ны дотор санхүү, төсвийн болон эдийн засаг, хөгжлийн асуудал эрхэлсэн төрийн захиргааны төв байгууллагад хүргүүлэх;',
        color: '#003696',
        dateFormat: `${currentYear}-03-15`,
    },
    {
        id: 4,
        month: 4,
        date: '4 сарын 15​',
        detail: 'Санхүү, төсвийн асуудал эрхэлсэн төрийн захиргааны төв байгууллага Стратегийн баримт бичгийн төслийг, эдийн засаг, хөгжлийн асуудал эрхэлсэн төрийн захиргааны төв байгууллага дунд хугацааны төсвийн хүрээний мэдэгдлийн төслийг Төсвийн тогтвортой байдлын зөвлөлөөс ирүүлсэн макро эдийн засгийн үндсэн үзүүлэлт, төлөв байдлын таамаглалд үндэслэн боловсруулж, жил бүрийн​ 04 дүгээр сарын 15-ны дотор Засгийн газарт хүргүүлэх;',
        color: '#003696',
        dateFormat: `${currentYear}-04-15`
    },
    {
        id: 5,
        month: 5,
        date: null,
        detail: 'Засгийн газар Стратегийн баримт бичиг, дунд хугацааны төсвийн хүрээний мэдэгдлийн төслийг хэлэлцэн жил бүрийн 5 дугаар сарын 01-ний дотор Улсын Их Хуралд өргөн мэдүүлэх;',
        color: '#003696',
        dateFormat: `${currentYear}-05-01`
    },
    {
        id: 6,
        month: 6,
        date: null,
        detail: 'Улсын Их Хурал Стратегийн баримт бичиг, дунд хугацааны төсвийн хүрээний мэдэгдлийн төслийг жил бүрийн 6 дугаар сарын 01-ний дотор хэлэлцэж, батлах;',
        color: '#003696',
        dateFormat: `${currentYear}-06-01`
    }]

    const data4 = [{
        id: 7,
        month: null,
        date: '6 сарын 5​',
        detail: 'Улсын Их Хурал Стратегийн баримт бичиг, дунд хугацааны төсвийн хүрээний мэдэгдлийг баталснаас хойш ажлын долоон өдрийн дотор хэвлэн нийтлэх.​',
        color: '#F99D4B',
        dateFormat: `${currentYear}-06-05`
    },
    {
        id: 8,
        month: null,
        date: '6 сарын 10​',
        detail: 'Төсвийн ерөнхийлөн захирагч дунд хугацааны төсвийн хүрээний мэдэгдэлд суурилсан жилийн төсвийн хязгаарын саналаа санхүү, төсвийн асуудал эрхэлсэн төрийн захиргааны төв байгууллагад жил бүрийн 6 дугаар сарын 10-ны дотор хүргүүлэх;',
        color: '#F99D4B',
        dateFormat: `${currentYear}-06-10`
    },
    {
        id: 9,
        month: null,
        date: '6 сарын 20​',
        detail: 'Санхүү, төсвийн асуудал эрхэлсэн төрийн захиргааны төв байгууллага төсвийн ерөнхийлөн захирагчийн жилийн төсвийн хязгаарын саналыг нэгтгэн боловсруулж, жил бүрийн 6 дугаар сарын 20-ны дотор Засгийн газарт хүргүүлэх;​',
        color: '#F99D4B',
        dateFormat: `${currentYear}-06-20`
    },
    {
        id: 10,
        month: 7,
        date: null,
        detail: 'Засгийн газар төсвийн ерөнхийлөн захирагчийн жилийн төсвийн хязгаарын төслийг хэлэлцэн жил бүрийн 7 дугаар сарын 01-ний дотор батлах;​',
        color: '#F99D4B',
        dateFormat: `${currentYear}-07-01`
    },
    {
        id: 11,
        month: null,
        date: '7 сарын 5​',
        detail: 'Санхүү, төсвийн асуудал эрхэлсэн төрийн захиргааны төв байгууллага Засгийн газрын баталсан жилийн төсвийн хязгаарыг төсвийн удирдамжийн хамт жил бүрийн 7 дугаар сарын 5-ны дотор төсвийн ерөнхийлөн захирагчид хүргүүлэх.​',
        color: '#F99D4B',
        dateFormat: `${currentYear}-07-05`
    }];

    const data5 = [{
        id: 12,
        month: null,
        date: '7 сарын 9​',
        detail: 'Төсвийн төвлөрүүлэн захирагчид харьяалагддаг төсвийн шууд захирагч нь жилийн төсвийн төслөө жил бүрийн 7 дугаар сарын 09-ний дотор харьяалах төсвийн төвлөрүүлэн захирагчид хүргүүлэх;​',
        color: '#AAC8FF',
        dateFormat: `${currentYear}-07-09`
    },
    {
        id: 13,
        month: null,
        date: '7 сарын 20',
        detail: 'Төсвийн төвлөрүүлэн захирагч, төсвийн төвлөрүүлэн захирагчид харьяалагддаггүй төсвийн шууд захирагч жил бүрийн 07 дугаар сарын 20-ны дотор жилийн төсвийн төслөө харьяалагдах төсвийн ерөнхийлөн захирагчид хүргүүлэх;',
        color: '#AAC8FF',
        dateFormat: `${currentYear}-07-20`
    },
    {
        id: 14,
        month: null,
        date: '7 сарын 25​',
        detail: 'Төсвийн ерөнхийлөн захирагч жилийн төсвийн саналаа жил бүрийн 07 дугаар сарын 25-ны дотор санхүү, төсвийн асуудал эрхэлсэн төрийн захиргааны төв байгууллага, төрийн аудитын байгууллага, Улсын Их Хуралд хүргүүлж, нийтэд ил тод мэдээлэх;​',
        color: '#AAC8FF',
        dateFormat: `${currentYear}-07-25`
    },
    {
        id: 15,
        month: 8,
        date: '8 сарын 15​',
        detail: 'Санхүү, төсвийн асуудал эрхэлсэн төрийн захиргааны төв байгууллага энэ хуулийн 8.4.3-т заасан жилийн төсвийн саналыг дунд хугацааны төсвийн хүрээний мэдэгдэл, улсын хөгжлийн жилийн төлөвлөгөөнд нийцүүлэн нэгтгэн боловсруулж, эдийн засаг, хөгжлийн асуудал эрхэлсэн төрийн захиргааны төв байгууллагаар хянуулж, жил бүрийн 08 дугаар сарын 15-ны дотор Засгийн газарт өргөн мэдүүлж, нийтэд ил тод мэдээлэх;​',
        color: '#AAC8FF',
        dateFormat: `${currentYear}-08-15`
    },
    {
        id: 16,
        month: 9,
        date: null,
        detail: 'Засгийн газар жилийн төсвийн төслийг жил бүрийн 09 дүгээр сарын 01-ний дотор Төрийн аудитын байгууллагад хүргүүлж, Улсын Их Хуралд өргөн мэдүүлэх;​',
        color: '#AAC8FF',
        dateFormat: `${currentYear}-09-01`
    },
    {
        id: 17,
        month: null,
        date: null,
        detail: 'Засгийн газар жилийн төсвийн төслийг Улсын Их Хуралд өргөн мэдүүлснээс хойш ажлын гурван өдрийн дотор нийтэд мэдээлэх;',
        color: '#AAC8FF',
        dateFormat: `${currentYear}-09-04`
    },
    {
        id: 18,
        month: null,
        date: '9 сарын 20',
        detail: 'Төсвийн тогтвортой байдлын зөвлөл төсвийн төслийн талаарх дүгнэлтийг Улсын Их Хуралд жил бүрийн 09 дүгээр сарын 20-нд хүргүүлж, нийтэд мэдээлнэ;​',
        color: '#AAC8FF',
        dateFormat: `${currentYear}-09-20`
    },
    {
        id: 19,
        month: null,
        date: '9 сарын 25​',
        detail: 'Төрийн аудитын төв байгууллага жилийн төсвийн төслийн талаарх дүгнэлтийг Улсын Их Хуралд жил бүрийн 09 дүгээр сарын 25-нд хүргүүлж, нийтэд мэдээлэх;​',
        color: '#AAC8FF',
        dateFormat: `${currentYear}-09-25`
    },
    {
        id: 20,
        month: 10,
        date: null,
        detail: null,
        color: '#AAC8FF',
        dateFormat: `${currentYear}-10-01`
    },
    {
        id: 21,
        month: 11,
        date: '11 сарын 15',
        detail: 'Улсын Их Хурал жилийн төсвийн төслийг хэлэлцэн жил бүрийн намрын ээлжит чуулганаар хэлэлцэн 11 дүгээр сарын 15-ны дотор батлах.​',
        color: '#AAC8FF',
        dateFormat: `${currentYear}-11-15`
    }];

    const data6 = [{
        id: 22,
        month: 12,
        date: '12 сарын 15',
        detail: 'Төсвийн төвлөрүүлэн захирагч болон төсвийн төвлөрүүлэн захирагчид харъяалагддаггүй төсвийн шууд захирагч төсвийн хуваарийн саналаа жил бүрийн 12 дугаар сарын 15-ны дотор харьяалагдах төсвийн ерөнхийлөн захирагчид хүргүүлэх;​​',
        color: '#448EF7',
        dateFormat: `${currentYear}-12-15`
    },
    {
        id: 23,
        month: null,
        date: '12 сарын 25​',
        detail: 'Төсвийн ерөнхийлөн захирагч төсвийн хуваарийн саналаа жил бүрийн 12 дугаар сарын 25-ны дотор санхүү, төсвийн асуудал эрхэлсэн төрийн захиргааны төв байгууллагад хүргүүлэх;​',
        color: '#448EF7',
        dateFormat: `${currentYear}-12-25`
    }
    ]

    function renderTimeline(dataSets) {
        return dataSets.map((e, index) => {
            return (
                <div key={index} style={{ display: 'flex', gap: '1rem', paddingBottom: '0.5rem', width: '95%', float: 'right' }}>
                    <div style={{ width: '5%' }}>
                        <span className='color_line' style={{ borderColor: e.color }}></span>
                        <span className='vr_line'></span>
                    </div>
                    {e.month ?
                        <span className={`timeline_month month_span ${currentMonth === e.month && 'current_month'}`}>{e.month}</span> : <span className='month_span'></span>}
                    <div style={{ fontSize: 14, width: '80%' }}>
                        <div style={{ fontWeight: 'bold' }}>{e.date}</div>
                        <div style={{ color: 'black' }}>{e.detail}</div>
                    </div>
                </div>
            );
        });
    };

    return (
        <ScrollView
            height="100%"
            width="100%"
        >
            <div style={{ fontFamily: 'Segoe UI' }}>
                <div className='timeline-container' style={{ overflowY: 'hidden' }}>
                    <div style={{ position: 'relative' }}>
                        {renderTimeline(data1)}
                        {renderTimeline(data2)}
                        <div className='timeline_header' style={{ marginTop: 180 }}>
                            ДУНД ХУГАЦААНЫ ТӨСВИЙН ХҮРЭЭНИЙ МЭДЭГДЭЛ БОЛОВСРУУЛАХ, БАТЛАХ</div>
                        {renderTimeline(data3)}
                        <div className='timeline_header' style={{marginTop: 700 }}>
                            ТӨСВИЙН ХЯЗГААР БАТЛАХ </div>
                        {renderTimeline(data4)}
                        <div className='timeline_header' style={{ marginTop: 700 }}>
                            ТӨСВИЙН ТӨСӨЛ БОЛОВСРУУЛАХ, БАТЛАХ
                        </div>
                        {renderTimeline(data5)}
                        <div className='timeline_header' style={{ marginTop: 1300 }}>
                            ТӨСВИЙН ХУВААРИЙН САНАЛ ХҮРГҮҮЛЭХ,​ БАТЛАХ
                        </div>
                        {renderTimeline(data6)}
                    </div>
                </div>
            </div>
        </ScrollView>
    )
}


import React, { useState, useEffect, useContext, useRef } from "react";
import PlanService from "../../../services/api/plan";
import notify from "devextreme/ui/notify";
import DataGrid, {
    Column,
    Grouping,
    Lookup,
    HeaderFilter,
    Summary,
    Selection,
    Toolbar,
    ColumnChooser,
    GroupItem,
    FilterPanel,
    Export, ColumnFixing, Scrolling,TotalItem,Paging
} from "devextreme-react/data-grid";
import RefService from "../../../services/api/refs";
import Button from "devextreme-react/button";
import { UserContext } from "../../../hooks/UserContext";
import { formatDate, numberWithCommas } from "../../../util";
import LoadPanelComponent from "../../budget/components/loadPanel";
import TabPanel, { Item } from "devextreme-react/tab-panel";
import { saveAs } from "file-saver-es";
import { BsFiletypeXls } from "react-icons/bs";
import { CgArrowsExpandRight, CgCompressRight, CgMaximize, CgMinimize } from "react-icons/cg";
import { Workbook } from "exceljs";
import { exportDataGrid } from "devextreme/excel_exporter";
import loaderGif from "../../../assets/imgs/tube-spinner.svg";


const PlanOfferSheduleReport = (props) => {
    const [refAgenda, setRefAgenda] = useState([]);
    const [refEvent, setRefEvent] = useState([]);
    const [loadMe, setLoadMe] = useState(false);
    const { user } = useContext(UserContext);
    const [econCategory, setEconCategory] = useState([]);
    const [autoExpandAll, setAutoExpand] = useState(true);
    const [dataEcon2, setDataEcon2] = useState([]);
    const dataExpenseGridRef = useRef(null);
    const [fullscreen, setFullscreen] = useState(false)

    const loadAllBudget = async () => {
        try {
            setLoadMe(true);
            let allBpd = await PlanService.planScheduleOfferReport(user.organization.id,props.selectedYear,props.status);
            setDataEcon2(allBpd.data)
            let a = await RefService.getAgendas();
            let e = await RefService.getEvents();
            let result = await RefService.getRefEconomicCategory();
            setEconCategory(result.data);
            setRefAgenda(a.data);
            setRefEvent(e.data);
            setLoadMe(false);
        } catch (e) {
            setLoadMe(false);
            notify(e.message, "error", 2000);
        }
    }

    useEffect(() => {
        loadAllBudget();

    }, [props.selectedYear, props.status])

    const customizedBold = (data) => {
        return (
            <span style={{ fontWeight: 'bold', fontFamily: 'Segoe UI' }}>{data.text}</span>
        )
    }

    const onExporting = (e) => {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Main sheet');
        worksheet.columns = [
            { width: 10 },{ width: 100 },{ width: 18 },{ width: 18 },{ width: 18 },{ width: 18 },{ width: 18 },{ width: 18 },{ width: 18 },{ width: 18 },{ width: 18 },{ width: 18 },{ width: 18 },{ width: 18 },{ width: 18 }
        ];
        exportDataGrid({
            component: e.component,
            worksheet,
            autoFilterEnabled: true,
            keepColumnWidths: false,
            topLeftCell: { row: 4, column: 2 },
            customizeCell: ({ gridCell, excelCell }) => {
                if (gridCell.rowType === 'header') {
                    excelCell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'EEF0F4' } };
                    excelCell.font = { color: { argb: '15186A' },name: 'Segoe UI Semibold',size: 8};
                    /*excelCell.border = {
                        top: { style: 'thin', color: { argb: '2c2c2c' } },
                        left: { style: 'thin', color: { argb: '2c2c2c' } },
                        bottom: { style: 'thin', color: { argb: '2c2c2c' } },
                        right: { style: 'thin', color: { argb: '2c2c2c' } }
                    }*/
                }
                if (gridCell.rowType === 'group') {
                    if(gridCell.groupIndex ===0) {

                        excelCell.font = { color: { argb: 'F93A3A' },name: 'Segoe UI Semibold',size: 8};
                        excelCell.font.fontWeight = 'bold';
                        if(excelCell._column._number !== 2 ) excelCell.alignment = { horizontal: 'right' };
                       /* excelCell.border = {
                            top: { style: 'thin', color: { argb: '2c2c2c' } },
                            left: { style: 'thin', color: { argb: '2c2c2c' } },
                            bottom: { style: 'thin', color: { argb: '2c2c2c' } },
                            right: { style: 'thin', color: { argb: '2c2c2c' } }
                        }*/
                    }
                    if(gridCell.groupIndex ===1) {
                        excelCell.font = { color: { argb: '2C77FF' },fontWeight:'bold',name: 'Segoe UI Semibold',size: 8};
                        if(excelCell._column._number !== 2 ) excelCell.alignment = { horizontal: 'right' };
                       /* excelCell.border = {
                            top: { style: 'thin', color: { argb: '2c2c2c' } },
                            left: { style: 'thin', color: { argb: '2c2c2c' } },
                            bottom: { style: 'thin', color: { argb: '2c2c2c' } },
                            right: { style: 'thin', color: { argb: '2c2c2c' } }
                        }*/
                    }
                    if([2,3,4].includes(gridCell.groupIndex)) {
                        excelCell.font = { color: { argb: '021772' },fontWeight:'bold',name: 'Segoe UI Semibold',size: 8};
                        if(excelCell._column._number !== 2 ) excelCell.alignment = { horizontal: 'right' };
                    }
                    else if (gridCell.groupIndex >4){
                        excelCell.alignment = { wrapText: false };
                        /*excelCell.border = {
                            top: { style: 'thin', color: { argb: '2c2c2c' } },
                            left: { style: 'thin', color: { argb: '2c2c2c' } },
                            bottom: { style: 'thin', color: { argb: '2c2c2c' } },
                            right: { style: 'thin', color: { argb: '2c2c2c' } }
                        }*/
                        if(excelCell._column._number !== 2 ) excelCell.alignment = { horizontal: 'right' };
                        excelCell.font = { fontWeight:'bold',name: 'Segoe UI Semibold',size: 8};
                    }

                }
                if (gridCell.rowType === 'totalFooter' && excelCell.value) {
                    excelCell.font = {name: 'Segoe UI Semibold',size: 8};
                    excelCell.font.italic = true;
                }
                if (gridCell.rowType === 'data') {
                    excelCell.alignment = { wrapText: false };
                    excelCell.font = {name: 'Segoe UI',size: 8};
                    if(gridCell.column.dataField !== 'econ_category_id')
                    {
                        excelCell.numFmt = '#,##0.00';
                    }
                    /*excelCell.border = {
                        top: { style: 'thin', color: { argb: '2c2c2c' } },
                        left: { style: 'thin', color: { argb: '2c2c2c' } },
                        bottom: { style: 'thin', color: { argb: '2c2c2c' } },
                        right: { style: 'thin', color: { argb: '2c2c2c' } }
                    }*/
                }
                if(['agenda_id','event_id','econ_category_code_3','econ_category_code_4','econ_category_code_2','econ_category_code_1','econ_category_id','budget_project_status_name','parent_name','org_name'].includes(gridCell.column.dataField)) {
                    if(excelCell._row && excelCell._row._cells && excelCell._row._cells[1].model && excelCell._row._cells[1].model.value)
                    {
                        excelCell._row._cells[1].model.value = excelCell._row._cells[1].model.value.replace(": ", "");
                    }

                }
                if(gridCell.column.dataField === 'econ_category_code_3' || gridCell.column.dataField === 'econ_category_code_4' || gridCell.column.dataField === 'org_name' || gridCell.column.dataField === 'agenda_id' || gridCell.column.dataField === 'event_id') {
                   /* excelCell._row._cells[1].model.value = 'dddd';
                    excelCell._row.hidden = true;*/
                    if(excelCell._row && excelCell._row._cells && excelCell._row._cells[1].model && excelCell._row._cells[1].model.value === '')
                    {
                        excelCell._row.hidden = true;
                    }
                }

                if(gridCell.rowType === 'group' && gridCell.groupIndex === 1) {
                    if(excelCell._column._number !== 2 ) excelCell.alignment = { horizontal: 'right',wrapText: false };
                    excelCell._row._cells[1].model.value = ` ${excelCell._row._cells[1].model.value}`
                }
                if(gridCell.rowType === 'group' && gridCell.groupIndex === 2) {
                    if(excelCell._column._number !== 2 ) excelCell.alignment = { horizontal: 'right',wrapText: false };
                    excelCell._row._cells[1].model.value = `  ${excelCell._row._cells[1].model.value}`
                }
                if(gridCell.rowType === 'group' && gridCell.groupIndex === 3) {
                    if(excelCell._column._number !== 2 ) excelCell.alignment = { horizontal: 'right',wrapText: false };
                    excelCell._row._cells[1].model.value = `   ${excelCell._row._cells[1].model.value}`
                }
                if(gridCell.rowType === 'group' && gridCell.groupIndex === 4) {
                    if(excelCell._column._number !== 2 ) excelCell.alignment = { horizontal: 'right',wrapText: false };
                    excelCell._row._cells[1].model.value = `    ${excelCell._row._cells[1].model.value}`
                }
                if(gridCell.rowType === 'group' && gridCell.groupIndex === 5) {
                    if(excelCell._column._number !== 2 ) excelCell.alignment = { horizontal: 'right',wrapText: false };
                    excelCell._row._cells[1].model.value = `     ${excelCell._row._cells[1].model.value}`
                }
                if(gridCell.rowType === 'group' && gridCell.groupIndex === 6) {
                    if(excelCell._column._number !== 2 ) excelCell.alignment = { horizontal: 'right',wrapText: false };
                    excelCell._row._cells[1].model.value = `      ${excelCell._row._cells[1].model.value}`
                }
                if(gridCell.rowType === 'group' && gridCell.groupIndex === 7) {
                    if(excelCell._column._number !== 2 ) excelCell.alignment = { horizontal: 'right',wrapText: false };
                    excelCell._row._cells[1].model.value = `       ${excelCell._row._cells[1].model.value}`
                }
                if(gridCell.rowType === 'group' && gridCell.groupIndex === 8) {
                    if(excelCell._column._number !== 2 ) excelCell.alignment = { horizontal: 'right',wrapText: false };
                    excelCell._row._cells[1].model.value = `        ${excelCell._row._cells[1].model.value}`
                }
                if(gridCell.rowType === 'group' && gridCell.groupIndex === 9) {
                    if(excelCell._column._number !== 2 ) excelCell.alignment = { horizontal: 'right',wrapText: false };
                    excelCell._row._cells[1].model.value = `         ${excelCell._row._cells[1].model.value}`
                }
                if(gridCell.rowType === 'data') {
                    excelCell._row._cells[1].model.value = `          ${excelCell._row._cells[1].model.value}`
                }

            },
        }).then(() => {
            Object.assign(
                worksheet.getRow(2).getCell(5),

                {
                    value: user.organization.name + " хуваарийн саналын нэгтгэл тайлан",
                    font: { bold: true, size: 16, underline: 'double' }
                }
            );
        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer], { type: 'application/octet-stream' }),"OG-" + user.organization.name + "-" + formatDate(new Date()) + ".xlsx");
            });
        });
        e.cancel = true;
    }
    const onCellPreparedHandler = (e) => {
        if (e.rowType === 'header') {
            e.cellElement.wordWrapEnabled = true;
            e.cellElement.style.fontFamily = "Segoe UI";
            e.cellElement.style.fontWeight = "bold";
            e.cellElement.style.color = "#15186A";
            e.cellElement.style.alignment = "center";
            e.cellElement.style.backgroundColor = "#EEF0F4";
            e.cellElement.style.borderColor = "#D4DCEC";
            e.cellElement.style.fontSize = "12px";
        }
        else if (e.rowType === 'group') {
            // console.log(e);
            e.cellElement.style.fontFamily = "Segoe UI"
            //e.cellElement.wordWrapEnabled = true;
            switch (e.row.groupIndex) {
                case 0:
                    return e.cellElement.style.color = "#F93A3A"
                case 1:
                    return e.cellElement.style.color = "#2C77FF"
                case 2:
                    return e.cellElement.style.color ="#021772"
                case 3:
                    return e.cellElement.style.color ="#021772"
                case 4:
                    return e.cellElement.style.color ="#021772"
                 default:
                    return e.cellElement.style.color = "black"
            }
            //e.cellElement.style.color = (e.row.groupIndex === 0 ? "#F93A3A" : ((e.row.groupIndex && e.row.groupIndex===1) ? "#2C77FF" : 'black'))
            e.cellElement.style.backgroundColor = "#fff"
            e.cellElement.style.fontWeight = "bold";
            e.cellElement.style.fontSize = "12px"
            e.cellElement.style.borderColor = "#D4DCEC"
        } else if (e.rowType === "data") {
            e.cellElement.style.fontFamily = 'Segoe UI'
            e.cellElement.style.fontWeight = 'normal'
            e.cellElement.style.color = "#000000"

        }
    }
    const groupCell = (e) => {
        return <div>{e.data.displayValue}</div>
    }
    const onRowPrepared = (args) => {
        if (args.rowType == 'group' && (args.values[args.groupIndex] == "" || args.values[args.groupIndex] == null)) {
            args.rowElement.hidden = true;
            if (!args.component.isRowExpanded(args.key))
                args.component.expandRow(args.key)
        }
    }
    const requestFullscreen = () => {
        if (document.fullscreenElement) document.exitFullscreen();
        else document.getElementById("wrapper").requestFullscreen();
        // setFullscreen(prev=>!prev)
    }

    const calculateArea = (rowData,field,groupIndex) => {
        return  groupIndex === 0 ? (rowData.econ_category_code_1 === '2' && rowData.org_name === null && rowData[field]*1) : rowData.econ_category_code_1 === '2' && rowData[field]*1;
    }
    const calculateAreaTotal = (rowData,field) => {
        return  rowData.econ_category_code_1 === '2' && rowData.org_name === null && rowData[field]*1;
    }
    const calculateAreaSummary = (options) => {
        if (options.name === 'customSum') {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0;
            } else if (options.summaryProcess === 'calculate') {
                if(options.groupIndex < 5){
                    options.totalValue += calculateArea(options.value,'debit',options.groupIndex);
                } else {
                    options.totalValue += options.value.debit * 1
                }

            }
        }
        else if (options.name === 'totalSum') {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0;
            } else if (options.summaryProcess === 'calculate') {
                options.totalValue += calculateAreaTotal(options.value,'debit');

            }
        }
        else if (options.name === 'month1sum') {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0;
            } else if (options.summaryProcess === 'calculate') {
                options.totalValue += calculateAreaTotal(options.value,'month1');
            }
        }
        else if (options.name === 'month2sum') {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0;
            } else if (options.summaryProcess === 'calculate') {
                options.totalValue += calculateAreaTotal(options.value,'month2');
            }
        }
        else if (options.name === 'month3sum') {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0;
            } else if (options.summaryProcess === 'calculate') {
                options.totalValue += calculateAreaTotal(options.value,'month3');
            }
        }
        else if (options.name === 'month4sum') {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0;
            } else if (options.summaryProcess === 'calculate') {
                options.totalValue += calculateAreaTotal(options.value,'month4');
            }
        }
        else if (options.name === 'month5sum') {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0;
            } else if (options.summaryProcess === 'calculate') {
                options.totalValue += calculateAreaTotal(options.value,'month5');
            }
        }
        else if (options.name === 'month6sum') {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0;
            } else if (options.summaryProcess === 'calculate') {
                options.totalValue += calculateAreaTotal(options.value,'month6');
            }
        }
        else if (options.name === 'month7sum') {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0;
            } else if (options.summaryProcess === 'calculate') {
                options.totalValue += calculateAreaTotal(options.value,'month7');
            }
        }
        else if (options.name === 'month8sum') {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0;
            } else if (options.summaryProcess === 'calculate') {
                options.totalValue += calculateAreaTotal(options.value,'month8');
            }
        }
        else if (options.name === 'month9sum') {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0;
            } else if (options.summaryProcess === 'calculate') {
                options.totalValue += calculateAreaTotal(options.value,'month9');
            }
        }
        else if (options.name === 'month10sum') {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0;
            } else if (options.summaryProcess === 'calculate') {
                options.totalValue += calculateAreaTotal(options.value,'month10');
            }
        }
        else if (options.name === 'month11sum') {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0;
            } else if (options.summaryProcess === 'calculate') {
                options.totalValue += calculateAreaTotal(options.value,'month11');
            }
        }
        else if (options.name === 'month12sum') {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0;
            } else if (options.summaryProcess === 'calculate') {
                options.totalValue += calculateAreaTotal(options.value,'month12');
            }
        }
        else if (options.name === 'month1') {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0;
            } else if (options.summaryProcess === 'calculate') {
                if(options.groupIndex < 5){
                    options.totalValue += calculateArea(options.value,'month1',options.groupIndex);
                } else {
                    options.totalValue += options.value.month1 * 1
                }

            }
        }
        else if (options.name === 'month2') {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0;
            } else if (options.summaryProcess === 'calculate') {
                if(options.groupIndex < 5){
                    options.totalValue += calculateArea(options.value,'month2',options.groupIndex);
                } else {
                    options.totalValue += options.value.month2 * 1
                }

            }
        }
        else if (options.name === 'month3') {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0;
            } else if (options.summaryProcess === 'calculate') {
                if(options.groupIndex < 5){
                    options.totalValue += calculateArea(options.value,'month3',options.groupIndex);
                } else {
                    options.totalValue += options.value.month3 * 1
                }

            }
        }
        else if (options.name === 'month4') {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0;
            } else if (options.summaryProcess === 'calculate') {
                if(options.groupIndex < 5){
                    options.totalValue += calculateArea(options.value,'month4',options.groupIndex);
                } else {
                    options.totalValue += options.value.month4 * 1
                }

            }
        }
        else if (options.name === 'month5') {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0;
            } else if (options.summaryProcess === 'calculate') {
                if(options.groupIndex < 5){
                    options.totalValue += calculateArea(options.value,'month5',options.groupIndex);
                } else {
                    options.totalValue += options.value.month5 * 1
                }

            }
        }
        else if (options.name === 'month6') {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0;
            } else if (options.summaryProcess === 'calculate') {
                if(options.groupIndex < 5){
                    options.totalValue += calculateArea(options.value,'month6',options.groupIndex);
                } else {
                    options.totalValue += options.value.month6 * 1
                }

            }
        }
        else if (options.name === 'month7') {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0;
            } else if (options.summaryProcess === 'calculate') {
                if(options.groupIndex < 5){
                    options.totalValue += calculateArea(options.value,'month7',options.groupIndex);
                } else {
                    options.totalValue += options.value.month7 * 1
                }

            }
        }
        else if (options.name === 'month8') {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0;
            } else if (options.summaryProcess === 'calculate') {
                if(options.groupIndex < 5){
                    options.totalValue += calculateArea(options.value,'month8',options.groupIndex);
                } else {
                    options.totalValue += options.value.month8 * 1
                }

            }
        }
        else if (options.name === 'month9') {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0;
            } else if (options.summaryProcess === 'calculate') {
                if(options.groupIndex < 5){
                    options.totalValue += calculateArea(options.value,'month9',options.groupIndex);
                } else {
                    options.totalValue += options.value.month9 * 1
                }

            }
        }
        else if (options.name === 'month10') {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0;
            } else if (options.summaryProcess === 'calculate') {
                if(options.groupIndex < 5){
                    options.totalValue += calculateArea(options.value,'month10',options.groupIndex);
                } else {
                    options.totalValue += options.value.month10 * 1
                }

            }
        }
        else if (options.name === 'month11') {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0;
            } else if (options.summaryProcess === 'calculate') {
                if(options.groupIndex < 5){
                    options.totalValue += calculateArea(options.value,'month11',options.groupIndex);
                } else {
                    options.totalValue += options.value.month11 * 1
                }

            }
        }
        else if (options.name === 'month12') {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0;
            } else if (options.summaryProcess === 'calculate') {
                if(options.groupIndex < 5){
                    options.totalValue += calculateArea(options.value,'month12',options.groupIndex);
                } else {
                    options.totalValue += options.value.month12 * 1
                }

            }
        }
    }
    const textAreaOptions = { format: '#,##0.00' };
    return (
        <div id="loadMe" style={{ padding: '10px' }}>
            <LoadPanelComponent position={{ of: '#loadMe' }} visible={loadMe} />
            {
                <div className="offer" id="wrapper" height="100%" width="100%">
                    <DataGrid dataSource={dataEcon2} ref={dataExpenseGridRef} hoverStateEnabled={true}
                        columnAutoWidth={true}
                        //allowColumnReordering={true}
                        allowColumnResizing={true}
                        //columnWidth={'auto'}
                        loadPanel={{ enabled: true, text: '', shadingColor: "rgba(0,0,0,0.4)", showPane: false, indicatorSrc: loaderGif }}
                        height={"90vh"}
                        width={'100%'}
                        wordWrapEnabled={true}
                        columnResizingMode={'nextColumn'}
                              paging={{
                                  pageSize: 100,
                              }}
                        style={{ marginTop: 5, overflowY: "auto", overflowX: "hidden", }}
                        showBorders={true}
                        showRowLines={true}
                        twoWayBindingEnabled={true}
                        //selection={true}
                        sorting={{ mode: 'none' }}
                        showColumnLines={true} //focusedRowEnabled={true}
                        showColumnHeaders={true}
                        onCellPrepared={onCellPreparedHandler} onRowPrepared={onRowPrepared}
                        onExporting={onExporting}
                    >
                        <Paging enabled={true}/>
                        <ColumnFixing enabled={false} />
                        <FilterPanel
                            visible={false}
                            texts={{ clearFilter: "Шүүлтүүр цэвэрлэх", createFilter: "Шүүх" }}
                        />
                        <HeaderFilter visible={true} allowSearch={true} texts={{ cancel: 'Болих', ok: 'Шүүх', emptyValue: 'Хоосон утга' }} />
                        <Selection mode="single" />
                        <Export enabled={true} />
                        <Scrolling columnRenderingMode="virtual" />
                        <Toolbar>
                            <Item>
                                <div style={{ display: 'flex', justifyContent: 'space-between', gap: '5px' }}>
                                    <Button
                                        onClick={(e) => setAutoExpand(prev => !prev)}
                                        render={() => {
                                            return autoExpandAll ? (
                                                <CgCompressRight size={"1rem"} color="#172B4D" />)
                                                :
                                                (
                                                    <CgArrowsExpandRight size={"1rem"} color="#172B4D" />
                                                )
                                        }}
                                        hint={autoExpandAll ? 'Багасгах' : 'ЭЗА дэлгэрэнгүй харуулах'}
                                    >
                                    </Button>
                                    <Button
                                        className="grid-button"
                                        onClick={() => {
                                            requestFullscreen()
                                            setFullscreen(prev => !prev)
                                        }}
                                        render={() => {
                                            return fullscreen ?
                                                (<CgMinimize size={"1rem"} color="#172B4D" />)
                                                :
                                                (<CgMaximize size={"1rem"} color="#172B4D" />)
                                        }}
                                        hint={fullscreen ? 'Багасгах' : 'Дэлгэцэнд бүтэн харуулах'}
                                    >
                                    </Button>
                                </div>
                            </Item>
                            <Item name="exportButton"
                                render={(e) => {
                                    return <Button
                                        onClick={e.options.onClick}
                                        icon="xlsxfile"
                                        render={() => {
                                            return <BsFiletypeXls size={"1rem"} color="#172B4D" />
                                        }}
                                        style={{ color: "red" }}>
                                    </Button>
                                }}
                            />
                            <Item name="columnChooserButton"
                                render={(e) => {
                                    return <Button onClick={e.options.onClick} size='1rem' id='columnChooserButton' icon="columnchooser" />
                                }}
                            />
                        </Toolbar>
                        <Grouping autoExpandAll={autoExpandAll}
                            contextMenuEnabled={false}
                            expandMode="rowClick" />
                        <ColumnChooser
                            enabled={true} height={"100%"} allowSearch={true}
                            mode="select"
                            title={'Багана сонгох'}
                        />
                        <Column dataField="budget_project_status_name" caption="" allowEditing={false} sortOrder={'asc'}
                                groupIndex={0} groupCellComponent={groupCell} minWidth={'18rem'} width={'auto'} cssClass={'dx-datagrid-group-space'}>
                        </Column>
                        <Column dataField="parent_name" caption="" allowEditing={false} sortOrder={'asc'} sortingMethod={function (a, b) {
                            let index1 = a?.value;
                            let index2 = b?.value;
                            if (index1 === 'Нийт') {
                                return -1;
                            }
                            if (index2 === 'Нийт') {
                                return 1;
                            }
                            if (index1 > index2)
                                return 1;
                            if (index2 > index1)
                                return -1;
                            else
                                return 0;
                        }}
                                groupIndex={1} groupCellComponent={groupCell} minWidth={'18rem'} width={'auto'} cssClass={'dx-datagrid-group-space'}>
                        </Column>
                        <Column dataField="org_name" caption="" allowEditing={false} sortOrder={'asc'} showInColumnChooser={false}
                                groupIndex={2} groupCellComponent={groupCell} minWidth={'18rem'} width={'auto'} cssClass={'dx-datagrid-group-space'}>
                        </Column>
                        <Column dataField="agenda_id" caption="" allowEditing={false} sortOrder={'asc'} showInColumnChooser={false}
                            groupIndex={3} groupCellComponent={groupCell} minWidth={'18rem'} width={'auto'} cssClass={'dx-datagrid-group-space'}>
                            <Lookup dataSource={refAgenda} valueExpr="id" displayExpr={(item) => {
                                return item && `${item.code.substring(0,5)} - ${item.name}`
                            }}/></Column>
                        <Column dataField="event_id" caption="" allowEditing={false} cssClass={'dx-datagrid-group-space'} sortOrder={'asc'} showInColumnChooser={false}
                                groupCellComponent={groupCell} minWidth={'18rem'} width={'auto'} groupIndex={4} alignment={'left'}>
                            <Lookup dataSource={refEvent} valueExpr="id" displayExpr={(item) => {
                                return item && `${item.code.substring(0,5)} - ${item.name}`
                            }}/></Column>
                        <Column dataField="econ_category_code_1" caption="" groupIndex={5} cssClass={'dx-datagrid-group-space'} showInColumnChooser={false}
                            groupCellComponent={groupCell} width={'1'} allowEditing={false}>
                            <Lookup dataSource={econCategory} valueExpr="code" displayExpr={(item) => {
                                return item && `${item.code} - ${item.name}`
                            }} /></Column>
                        <Column dataField="econ_category_code_2" caption="" groupIndex={6} cssClass={'dx-datagrid-group-space'} showInColumnChooser={false}
                            groupCellComponent={groupCell} width={'1'} allowEditing={false}>
                            <Lookup dataSource={econCategory} valueExpr="code" displayExpr={(item) => {
                                return item && `${item.code} - ${item.name}`
                            }} /></Column>
                        <Column dataField="econ_category_code_3" caption="" groupIndex={7} cssClass={'dx-datagrid-group-space'} showInColumnChooser={false}
                            groupCellComponent={groupCell} width={'1'} allowEditing={false}>
                            <Lookup dataSource={econCategory} valueExpr="code" displayExpr={(item) => {
                                return item && `${item.code} - ${item.name}`
                            }} /></Column>
                        <Column dataField="econ_category_code_4" caption="" groupIndex={7} cssClass={'dx-datagrid-group-space'} showInColumnChooser={false}
                            groupCellComponent={groupCell} width={'1'} allowEditing={false}>
                            <Lookup dataSource={econCategory} valueExpr="code" displayExpr={(item) => {
                                return item && `${item.code} - ${item.name}`
                            }} /></Column>
                        <Column dataField="econ_category_id" caption="Үзүүлэлт" fixed={true} fixedPosition={'left'} allowResizing={false} minWidth={'18rem'} width={'auto'} showInColumnChooser={false}
                            allowEditing={false}>
                            <Lookup dataSource={econCategory} valueExpr="id" displayExpr={(item) => {
                                return item && `${item.code} - ${item.name}`
                            }} /></Column>
                        <Column dataField="debit" fixed={true} fixedPosition={'left'}
                            format="#,##0.00" alignment="right" dataType="number"
                            width={'auto'}
                            caption="Жилийн төлөвлөгөө" cellRender={customizedBold} allowFiltering={false} allowResizing={true}
                            allowEditing={false} />
                        <Column caption={'1-р улирал'} alignment={'center'} allowResizing={true} isBand={true}>
                            <Column dataField={`month1`} format="#,##0.00" alignment="right" dataType="number" //editorOptions={textAreaOptions}
                                caption={`1-p cap`} allowFiltering={false} allowResizing={true}>
                            </Column>
                            <Column dataField={`month2`} format="#,##0.00" alignment="right" dataType="number" //editorOptions={textAreaOptions}
                                caption={`2-p cap`} allowFiltering={false} allowResizing={true}>
                            </Column>
                            <Column dataField={`month3`} format="#,##0.00" alignment="right" dataType="number" //editorOptions={textAreaOptions}
                                caption={`3-p cap`} allowFiltering={false} allowResizing={true}>
                            </Column>
                        </Column>
                        <Column caption={'2-р улирал'} alignment={'center'} allowResizing={true}>
                            <Column dataField={`month4`} format="#,##0.00" alignment="right" dataType="number" //editorOptions={textAreaOptions}
                                caption={`4-p cap`} allowFiltering={false} allowResizing={true}>
                            </Column>
                            <Column dataField={`month5`} format="#,##0.00" alignment="right" dataType="number" //editorOptions={textAreaOptions}
                                caption={`5-p cap`} allowFiltering={false} allowResizing={true}>
                            </Column>
                            <Column dataField={`month6`} format="#,##0.00" alignment="right" dataType="number" //editorOptions={textAreaOptions}
                                caption={`6-p cap`} allowFiltering={false} allowResizing={true}>
                            </Column>
                        </Column>
                        <Column caption={'3-р улирал'} alignment={'center'} allowResizing={true}>
                            <Column dataField={`month7`} format="#,##0.00" alignment="right" dataType="number" //editorOptions={textAreaOptions}
                                caption={`7-p cap`} allowFiltering={false} allowResizing={true}>
                            </Column>
                            <Column dataField={`month8`} format="#,##0.00" alignment="right" dataType="number" //editorOptions={textAreaOptions}
                                caption={`8-p cap`} allowFiltering={false} allowResizing={true}>
                            </Column>
                            <Column dataField={`month9`} format="#,##0.00" alignment="right" dataType="number" //editorOptions={textAreaOptions}
                                caption={`9-p cap`} allowFiltering={false} allowResizing={true}>
                            </Column>
                        </Column>
                        <Column caption={'4-р улирал'} alignment={'center'} allowResizing={true}>
                            <Column dataField={`month10`} format="#,##0.00" alignment="right" dataType="number" //editorOptions={textAreaOptions}
                                caption={`10-p cap`} allowFiltering={false} allowResizing={true}>
                            </Column>
                            <Column dataField={`month11`} format="#,##0.00" alignment="right" dataType="number" //editorOptions={textAreaOptions}
                                caption={`11-p cap`} allowFiltering={false} allowResizing={true}>
                            </Column>
                            <Column dataField={`month12`} format="#,##0.00" alignment="right" dataType="number" //editorOptions={textAreaOptions}
                                caption={`12-p cap`} allowFiltering={false} allowResizing={true}>
                            </Column>
                        </Column>

                        <Export enabled={true} allowExportSelectedData={false} />
                        <Summary calculateCustomSummary={calculateAreaSummary}>
                            <GroupItem name="customSum"
                                       summaryType="custom"
                                //column="debit" summaryType="sum"
                                       showInGroupFooter={false} alignByColumn={true}
                                showInColumn="debit"
                                customizeText={(data) => {
                                    return `${numberWithCommas(data.value)}`
                                }} />
                            <GroupItem name="month1"
                                       summaryType="custom"//column="month1" summaryType="sum"
                                       showInGroupFooter={false} alignByColumn={true}
                                showInColumn="month1" customizeText={(data) => {
                                    return `${numberWithCommas(data.value)}`
                                }} />
                            <GroupItem name="month2"
                                       summaryType="custom"//column="month2" summaryType="sum"
                                       showInGroupFooter={false} alignByColumn={true}
                                showInColumn="month2" customizeText={(data) => {
                                    return `${numberWithCommas(data.value)}`
                                }} />
                            <GroupItem name="month3"
                                       summaryType="custom"//column="month3" summaryType="sum"
                                       showInGroupFooter={false} alignByColumn={true}
                                showInColumn="month3" customizeText={(data) => {
                                    return `${numberWithCommas(data.value)}`
                                }} />
                            <GroupItem name="month4"
                                       summaryType="custom"//column="month4" summaryType="sum"
                                       showInGroupFooter={false} alignByColumn={true}
                                showInColumn="month4" customizeText={(data) => {
                                    return `${numberWithCommas(data.value)}`
                                }} />
                            <GroupItem name="month5"
                                       summaryType="custom"//column="month5" summaryType="sum"
                                       showInGroupFooter={false} alignByColumn={true}
                                showInColumn="month5" customizeText={(data) => {
                                    return `${numberWithCommas(data.value)}`
                                }} />
                            <GroupItem name="month6"
                                       summaryType="custom"//column="month6" summaryType="sum"
                                       showInGroupFooter={false} alignByColumn={true}
                                showInColumn="month6" customizeText={(data) => {
                                    return `${numberWithCommas(data.value)}`
                                }} />
                            <GroupItem name="month7"
                                       summaryType="custom"//column="month7" summaryType="sum"
                                       showInGroupFooter={false} alignByColumn={true}
                                showInColumn="month7" customizeText={(data) => {
                                    return `${numberWithCommas(data.value)}`
                                }} />
                            <GroupItem name="month8"
                                       summaryType="custom"//column="month8" summaryType="sum"
                                       showInGroupFooter={false} alignByColumn={true}
                                showInColumn="month8" customizeText={(data) => {
                                    return `${numberWithCommas(data.value)}`
                                }} />
                            <GroupItem name="month9"
                                       summaryType="custom"//column="month9" summaryType="sum"
                                       showInGroupFooter={false} alignByColumn={true}
                                showInColumn="month9" customizeText={(data) => {
                                    return `${numberWithCommas(data.value)}`
                                }} />
                            <GroupItem name="month10"
                                       summaryType="custom" //column="month10" summaryType="sum"
                                       showInGroupFooter={false} alignByColumn={true}
                                showInColumn="month10" customizeText={(data) => {
                                    return `${numberWithCommas(data.value)}`
                                }} />
                            <GroupItem name="month11"
                                       summaryType="custom"
                                       showInGroupFooter={false} alignByColumn={true}
                                showInColumn="month11" customizeText={(data) => {
                                    return `${numberWithCommas(data.value)}`
                                }} />
                            <GroupItem name="month12"
                                       summaryType="custom"
                                       showInGroupFooter={false} alignByColumn={true}
                                showInColumn="month12" customizeText={(data) => {
                                    return `${numberWithCommas(data.value)}`
                                }} />
                            <TotalItem name="totalSum"
                                       summaryType="custom"
                                //column="debit" summaryType="sum"
                                       showInGroupFooter={false} alignByColumn={true}
                                       showInColumn="debit"
                                       customizeText={(data) => {
                                           return `${numberWithCommas(data.value)}`
                                       }} />
                            <TotalItem name="month1sum"
                                       summaryType="custom"//column="month1" summaryType="sum"
                                       showInGroupFooter={false} alignByColumn={true}
                                       showInColumn="month1" customizeText={(data) => {
                                return `${numberWithCommas(data.value)}`
                            }} />
                            <TotalItem name="month2sum"
                                       summaryType="custom"
                                       showInGroupFooter={false} alignByColumn={true}
                                       showInColumn="month2"
                                       customizeText={(data) => {
                                           return `${numberWithCommas(data.value)}`
                                       }} />
                            <TotalItem name="month3sum"
                                       summaryType="custom"
                                       showInGroupFooter={false} alignByColumn={true}
                                       showInColumn="month3"
                                       customizeText={(data) => {
                                           return `${numberWithCommas(data.value)}`
                                       }} />
                            <TotalItem name="month4sum"
                                       summaryType="custom"
                                       showInGroupFooter={false} alignByColumn={true}
                                       showInColumn="month4"
                                       customizeText={(data) => {
                                           return `${numberWithCommas(data.value)}`
                                       }} />
                            <TotalItem name="month5sum"
                                       summaryType="custom"
                                       showInGroupFooter={false} alignByColumn={true}
                                       showInColumn="month5"
                                       customizeText={(data) => {
                                           return `${numberWithCommas(data.value)}`
                                       }} />
                            <TotalItem name="month6sum"
                                       summaryType="custom"
                                       showInGroupFooter={false} alignByColumn={true}
                                       showInColumn="month6"
                                       customizeText={(data) => {
                                           return `${numberWithCommas(data.value)}`
                                       }} />
                            <TotalItem name="month7sum"
                                       summaryType="custom"
                                       showInGroupFooter={false} alignByColumn={true}
                                       showInColumn="month7"
                                       customizeText={(data) => {
                                           return `${numberWithCommas(data.value)}`
                                       }} />
                            <TotalItem name="month8sum"
                                       summaryType="custom"
                                       showInGroupFooter={false} alignByColumn={true}
                                       showInColumn="month8"
                                       customizeText={(data) => {
                                           return `${numberWithCommas(data.value)}`
                                       }} />
                            <TotalItem name="month9sum"
                                       summaryType="custom"
                                       showInGroupFooter={false} alignByColumn={true}
                                       showInColumn="month9"
                                       customizeText={(data) => {
                                           return `${numberWithCommas(data.value)}`
                                       }} />
                            <TotalItem name="month10sum"
                                       summaryType="custom"
                                       showInGroupFooter={false} alignByColumn={true}
                                       showInColumn="month10"
                                       customizeText={(data) => {
                                           return `${numberWithCommas(data.value)}`
                                       }} />
                            <TotalItem name="month11sum"
                                       summaryType="custom"
                                       showInGroupFooter={false} alignByColumn={true}
                                       showInColumn="month11"
                                       customizeText={(data) => {
                                           return `${numberWithCommas(data.value)}`
                                       }} />
                            <TotalItem name="month12sum"
                                       summaryType="custom"
                                       showInGroupFooter={false} alignByColumn={true}
                                       showInColumn="month12"
                                       customizeText={(data) => {
                                           return `${numberWithCommas(data.value)}`
                                       }} />
                        </Summary>
                    </DataGrid>
                </div>
            }
        </div>
    )
}

export default PlanOfferSheduleReport

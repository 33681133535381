import React, { useEffect, useState } from "react";
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.softblue.css';
import 'treeflex/dist/css/treeflex.css';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'

// import "bootstrap/dist/css/bootstrap.min.css";



import {
    faBell,
    faTrash,
    faCoins,
    faCity,
    faUser,
    faCogs,
    faHourglassHalf,
    faFolderOpen,
    faFileAlt,
    faNewspaper,
    faListOl,
    faGavel,
    faUserTie,
    faEnvelopeOpenText,
    faConciergeBell,
    faTable,
    faPercentage,
    faClipboardList,
    faDonate,
    faShoppingCart,
    faTasks, faChartBar, faTruck, faCashRegister, faBalanceScale,
    faFileDownload, faLink, faComments,
} from '@fortawesome/free-solid-svg-icons'
import MyInfo from "./pages/myInfo";
import BudgetDetail from './pages/budget/detail';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { UserContext } from './hooks/UserContext';
import PrivateRoute from './pages/PrivateRoute';
import Econ4 from './pages/budget/econ4';
import Proposal from "./pages/proposal";
import ProposalHome from "./pages/proposal/components/proposalHome";
import ProposalMinistry from "./pages/proposal/components/proposalMinistry";
import OrgTypeComponent from "./pages/home/components/orgType/orgtype";
import Detail from "./pages/home/detail";
import Worker from "./pages/home/worker"
import inProgress from './pages/inProgress';
import CreateOrgStructure from './pages/home/components/createOrgStructure';
//import News from "./pages/news/News";
import Chat from './pages/chat/index';
import NewsDetail from "./pages/news/component/newsDetail";
import PlanningPage from "./pages/plan";
//import PlanningInsertion from "./pages/plan/components/PlanningInsertion";
import PlanningOfferMerge from "./pages/plan/components/PlanningOfferMerge";
import PlanningOffer from "./pages/plan/components/PlanningOffer";
import NationalAsset from "./pages/updated-budget/components/horongo_oruulalt/nationalAsset";
import ProcurementPage from "./pages/ProcurementPage";
import TrainingPage from "./pages/TrainingPage";
import ShilenDans from "./pages/ShilenDans";
//import Introduction from './pages/introduction';
import UpdatedHome from './pages/introduction/updatedHome';
import Login from './pages/Login';
import Performance from './pages/performance';
import NotFound from './pages/NotFound';
import useFindUser from './hooks/useFindUser';
import MainLayout from "./Layouts/MainLayout";
import Law from "./pages/law";
import Operation from "./pages/operation";
import ControlPanel from "./pages/controlpanel";
import SettingsTabs from './pages/settings/sections/settingsTab';
import OrgChartTest from "./pages/home/components/orgType/orgChart/orgChartTest";
import Test from './pages/budget/test';
import Expense from './pages/budget/components/expense/expense'
import OrgFormBuilder from "./pages/organization/components/orgFormBuilder";
import OrgForms from "./pages/organization/components/orgForms";
import OrgFormsManager from "./pages/organization/components/orgFormsManager";
import UpdatedStructure from "./pages/updated_structure";
import GovProject from "./pages/gov_project";
import GovProjectDetail from "./pages/gov_project/govProjectDetail";
import BudgetReportPage from "./pages/budgetReportPage";
import BankBookHome from "./pages/finance/BankBook";
import GeneralLedgerHome from "./pages/finance/Gl/GeneralLedgerHome";
import AccountPayment from "./pages/finance/AccountPayment/AccountPaymentTab";
import AccountRecieve from "./pages/finance/AccountRecievable/AccountRecieve";
import Inventory from "./pages/finance/Inventory";
import Fa from "./pages/finance/Fa";
import './App.css';
import io from 'socket.io-client';
import FiManager from "./pages/FiManager";
import Hubabuba from "./pages/Hubabuba";
import Jasper from "./pages/finance/report/jasper";
import BudgetOrlogo from "./pages/updated-budget/budgetOrlogo";
import ScheduleEditRequest from "./pages/plan/components/ScheduleEditRequest";
import ScheduleRequestInsertionV2 from "./pages/plan/components/ScheduleRequestInsertionV2";
import MinistryPage from "./pages/mojhaHome/ministryPage";
import Minister from "./pages/mojhaHome/minister";
import OrgList from "./pages/mojhaHome/orgList";
import DeputyMinister from "./pages/mojhaHome/deputyMinister";
import OrgHeadInfo from "./pages/mojhaHome/orgHeadInfo";
import MainBudgetSource from "./pages/updated-budget/mainBudgetSource";
import StateStructureType from "./pages/mojhaHome/stateStructureType";
import BudgetTuswiinTusul from "./pages/updated-budget/budgetTuswiinTusul";
import Notifications from "./pages/notifications";
import BoBaBiPage from "./pages/bbb_page";
import MinistryList from "./pages/mojhaHome/MinistryList";
import BudgetTowloruuleh2 from "./pages/updated-budget/budgetTowloruuleh2";
import BudgetZarlaga2 from "./pages/updated-budget/budgetZarlaga2";
import BudgetEhUusver2 from "./pages/updated-budget/budgetEhUusver2";
import BudgetOtherInfo from "./pages/updated-budget/budgetOtherInfo";
import TestOrgDetailPage from "./pages/TestOrgDetailPage";
import LawControl1 from "./pages/order/components/LawControl1";
import AddNews from "./pages/news/component/addNews";
import CrudGeneralInfo2 from "./pages/news/component/newAddNews";
//import ForumPortal from "./pages/Landing";
import Document from "./pages/document";
import ProfileSection from "./pages/settings/sections/ProfileSection";
import User from "./pages/settings/sections/User";
import UserSettings from "./pages/settings/sections/userSettings";
import UserLogs from "./pages/settings/sections/UserLogs";
import ReferenceManager from "./pages/settings/sections/referenceManager";
import OrgPosition from "./pages/settings/sections/OrgPosition";
import PermissionSection from "./pages/settings/sections/PermissionSection";
import { wsServer } from "./shared/lib/request";
import StateStructure from "./pages/stateStructure/stateStructure";
import GeneralEditingPage from "./pages/generalInformation/generalEditingPage";
//import NewsArticle from "./pages/news/component/NewsArticle";
import LoginBanner from "./pages/settings/sections/LoginBanner";
import UserManual from "./pages/settings/sections/UserManual";
import Email from "./pages/email";
import FinanceReport from "./pages/finance/financeReport/financeReport";
import PayrollReport from "./pages/finance/financeReport/PayrollReport";
import UpdatedLawPage from "./pages/order/UpdatedLawPage";
import OrganizationGeneralInformation from "./pages/organization_general_information";
import Landing from "./pages/Landing";
import RequestInformation from "./pages/plan/components/RequestInformation";
import StatisticPage from "./pages/statistic";
import Statistics from "./pages/statistics";
import Statistic from "./pages/statistic/Statistics";
import ScheduleGridList from "./pages/plan/components/ScheduleGridList";
import ScheduleNegtgel from "./pages/plan/components/ScheduleNegtgel";
import StatisticsInsert from "./pages/statistics-insert/StatisticsInsert";
import ProjectDashboard from "./pages/proposal/components/projectDashboard";
import { TranslationProvider } from "./hooks/useTranslation";
import UpdatedNews from "./pages/updatedNews/UpdatedNews";
import Article from "./pages/updatedNews/Article";
import Feedbacks from "./pages/feedback";
import AgendaArrangement from "./pages/updated-budget/agendaArrangement";
import BudgetTezEza from "./pages/budget/budgetForecast/BudgetTezEza";
import GoogleAnalytics from "./pages/googleAnalytics/index";
import DynamicTable from "./pages/dynamicTable"
import DynamicTableList from "./pages/dynamicTable/dynamicTableList"
import DynamicTableDataList from "./pages/dynamicTable/dynamicTableDataList";
import DynamicTableCreate from "./pages/dynamicTable/createTable";
import DynamicTableEdit from "./pages/dynamicTable/editTable";
import DynamicTableDataCreate from "./pages/dynamicTable/dynamicTableDataCreate";
import DynamicTableDataDetail from "./pages/dynamicTable/dynamicTableDataDetail";
import BudgetStatement from "./pages/updated-budget/budgetStatement";
import { OrgDashboard } from "./pages/orgDashboard";
import BudgetCalculation from "./pages/budgetCalculation";
import BudgetCalculationData from "./pages/budgetCalculation/addEconData";
import Publication from "./pages/Publication/Publication";
import ScrollRestoration from "./ScrollRestoration";
import ScheduleMergeReport from "./pages/plan/components/ScheduleMergeReport";
import CitizenFeedback from "./pages/proposal/components/CitizenFeedback";
import BudgetTuswiinTaniltsuulga from "./pages/updated-budget/BudgetTuswiinTaniltsuulga";
import PayrollInfo from "./pages/finance/financeReport/PayrollInfo";
// import 'devextreme/dist/css/dx.generic.opengov-scheme.css';
// import Register from './pages/Register';
// import Landing from './pages/Landing';
// import Draught from './pages/draught';
// import Organizations from "./pages/organizations";
// import ForgetPasswordComponent from "./pages/forget";
// import ProposalHistory from "./pages/proposal/component/proposalHistory";
// import UserPosition from "./pages/home/component/userPosition";
// import OrgMyInfo from "./pages/organization/component/orgMyInfo";
// Importing Socket provider wrapper
// const renderWithLayout = (Component) => (props) => (
//     <MainLayout {...props}>
//         <Component {...props} />
//     </MainLayout>
// );


function App() {
    const [socket, setSocket] = useState(null);
    const [readChat, setReadChat] = useState(Date.now());
    const {
        user,
        setUser,
        isLoading
    } = useFindUser();

    useEffect(() => {
        if (socket) {
            socket.emit("add-user", user.id);

            socket.on('connect', () => {
                console.log('connected')
            })

            socket.on('disconnect', () => {
                console.log('disconnect')
            })

            socket.on('pong', () => {
                console.log(new Date().toISOString())
            })
        }
    }, [socket]);

    useEffect(() => {
        // console.log(user)
        if (user) {
            setSocket(io(wsServer));
        }
    }, [user]);

    library.add(fab, faCoins, faUser, faCity, faCogs, faHourglassHalf,
        faNewspaper, faFileAlt, faFolderOpen, faListOl, faGavel, faUserTie,
        faEnvelopeOpenText, faConciergeBell, faTable, faPercentage, faClipboardList,
        faDonate, faShoppingCart, faTasks, faChartBar, faTruck, faCashRegister, faBalanceScale, faFileDownload, faLink, faTrash, faBell, faComments);
    return (

        <Router>
            <ScrollRestoration/>
            <TranslationProvider>
                <UserContext.Provider value={{ user, setUser, isLoading }}>
                    <Switch>
                        {/* <Route path="/landing" component={LandingPage}/> */}
                        <Route exact path="/">
                            {user ? <Redirect to="/home" /> : <Redirect to="/login" />}
                        </Route>
                        <Route path="/login" component={Login} />
                        <MainLayout socket={socket} readChat={readChat}>
                            <PrivateRoute path="/home" component={UpdatedHome} />
                            <PrivateRoute path="/ministryPage" component={MinistryPage} />
                            <PrivateRoute path="/ministryList" component={MinistryList} />

                            <PrivateRoute path="/org/head" component={OrgHeadInfo} />

                            <PrivateRoute path="/Minister" component={Minister} />
                            <PrivateRoute path="/DeputyMinister" component={DeputyMinister} />

                            <PrivateRoute path="/OrgList" component={OrgList} />
                            <PrivateRoute path="/stateStructureType" component={StateStructureType} />
                            <PrivateRoute path="/orgFormBuilder" component={OrgFormBuilder} />
                            <PrivateRoute path="/orgForms" component={OrgForms} />
                            <PrivateRoute path="/orgFormsManager" component={OrgFormsManager} />
                            <PrivateRoute path="/performance" component={Performance} />
                            <PrivateRoute path="/plan" exact component={PlanningPage} />
                            <PrivateRoute path="/plan/schedule-edit" exact component={ScheduleEditRequest} />
                            <PrivateRoute path="/plan/schedule-list" exact component={ScheduleGridList} />
                            <PrivateRoute path="/plan/schedule-merge" exact component={ScheduleNegtgel} />
                            <PrivateRoute path="/plan/schedule-merge-report" exact component={ScheduleMergeReport} />
                            <PrivateRoute path="/plan/schedule/insertion/:reqId/:reqOrg/:status" exact component={ScheduleRequestInsertionV2} />
                            <PrivateRoute path="/plan/schedule/info/:reqId/:reqOrg" exact component={RequestInformation} />
                            {/*<PrivateRoute path="/plan/insertion" component={PlanningInsertion} />*/}
                            <PrivateRoute path="/plan/report" component={PlanningOfferMerge} />
                            <PrivateRoute path="/plan/offer" component={PlanningOffer} />
                            {/*      <PrivateRoute path="/settings" component={Settings}/>*/}
                            <PrivateRoute path="/nationalAsset" component={NationalAsset} />
                            <PrivateRoute path="/procurement" component={ProcurementPage} />
                            <PrivateRoute path="/training" component={TrainingPage} />
                            <PrivateRoute path="/electAccount" component={ShilenDans} />
                            <PrivateRoute path='/project'>
                                <Proposal socket={socket} />
                            </PrivateRoute>
                            <PrivateRoute path="/projectProposal" component={ProjectDashboard} />
                            <PrivateRoute path="/projectsMinistry" component={ProposalMinistry} />
                            <PrivateRoute path="/projects/detail" component={ProposalHome} />
                            <PrivateRoute path="/citizenFeedback" component={CitizenFeedback}/>
                            <PrivateRoute path="/lawControl" component={LawControl1} />
                            <PrivateRoute path="/newsControl" component={AddNews} />
                            <PrivateRoute path="/generalInfo" component={CrudGeneralInfo2} />
                            <PrivateRoute path="/myinfo" component={MyInfo} />
                            <PrivateRoute path="/hubabuba" component={Hubabuba} />
                            {/*OLD BUDGET MAIN*/}
                            {/*<PrivateRoute exact path="/budget" component={Budget}/>*/}
                            {/*2nd old budget*/}
                            {/*<PrivateRoute exact path="/budget" component={UpdatedBudget}/>*/}
                            <PrivateRoute exact path="/budget" component={MainBudgetSource} />
                            <PrivateRoute path={'/budget/test'} component={Test} />
                            <PrivateRoute path={'/budget/expense'} component={Expense} />
                            <PrivateRoute path={'/budget/detail'} component={BudgetDetail} />
                            {/*OLD BUDGET LIES HERE*/}
                            {/*<PrivateRoute path={'/budget/econ1'} component={Econ1}/>*/}
                            {/*<PrivateRoute path={'/budget/econ2'} component={Econ2}/>*/}
                            {/*<PrivateRoute path={'/budget/econ3'} component={Econ3}/>*/}
                            <PrivateRoute path={'/budget/econ1'} component={BudgetOrlogo} />
                            <PrivateRoute path={'/budget/econ2'} component={BudgetZarlaga2} />
                            <PrivateRoute path={'/budget/econ3'} component={BudgetEhUusver2} />
                            <PrivateRoute path={'/budget/econ4'} component={Econ4} />
                            <PrivateRoute path={'/budget/econ5'} component={BudgetTowloruuleh2} />
                            {/* <PrivateRoute path={'/budget/econ6'} component={BudgetTuswiinTusul} /> */}
                            <PrivateRoute path={'/budget/econ6'} component={BudgetTuswiinTaniltsuulga} />
                            <PrivateRoute path={'/budget/other'} component={BudgetOtherInfo} />
                            <PrivateRoute path={'/budgetReport'} component={BudgetReportPage} />
                            <PrivateRoute path={'/budget/agenda'} component={AgendaArrangement}/>
                            <PrivateRoute path={'/budgetStatement'} component={BudgetStatement}/>
                            <PrivateRoute path={'/budgetCalculation'} exact component={BudgetCalculation}/>
                            <PrivateRoute path={'/budgetCalculation/:econId'} exact component={BudgetCalculationData}/>
                            <PrivateRoute path='/dashboard/orgtype' component={OrgTypeComponent} />
                            <PrivateRoute path='/orgDashboard' component={OrgDashboard} />
                            <PrivateRoute path='/organization/detail' component={Detail} />
                            {/*<PrivateRoute path='/statistics' component={BoBaBiPage}/>*/}
                            <PrivateRoute path='/govAgenda' component={Statistics} />
                            <PrivateRoute path='/structure' component={UpdatedStructure} />
                            <PrivateRoute path='/portal' component={Landing} />

                            {/*Old version*/}
                            {/*<PrivateRoute path='/structure' component={Home}/>*/}
                            {/*<PrivateRoute path='/updatedStructure' component={UpdatedStructure}/>*/}
                            <PrivateRoute path='/worker' component={Worker} />
                            <PrivateRoute path='/inProgress' component={inProgress} />
                            <PrivateRoute path='/createStructure' component={CreateOrgStructure} />
                            <PrivateRoute path='/filemanager' component={FiManager} />
                            {/* <PrivateRoute path='/news' component={News} /> */}
                            <PrivateRoute path='/news' component={UpdatedNews}/>
                            <PrivateRoute path='/article/:id' component={Article}/>
                            {/* <PrivateRoute path='/article/:id' component={NewsArticle} /> */}
                            <PrivateRoute path='/law' component={Law} />
                            {/*<SocketProvider>*/}
                            <PrivateRoute path='/chat'>
                                <Chat socket={socket} readChat={() => {
                                    setReadChat(Date.now())
                                }} />
                            </PrivateRoute>
                            {/*</SocketProvider>*/}
                            <PrivateRoute path='/newsDetail' component={NewsDetail} />
                            <PrivateRoute path='/orgTestChart' component={OrgChartTest} />
                            <PrivateRoute path='/operation' component={Operation} />
                            {/*<PrivateRoute path={'/order'} component={UpdatedLawForIntro} />*/}
                            <PrivateRoute path={'/order'} component={UpdatedLawPage} />
                            <PrivateRoute path='/controlPanel' component={ControlPanel} />
                            <PrivateRoute path={'/adminTab'} component={SettingsTabs} />
                            <PrivateRoute path={'/govProject'} component={GovProject} />
                            <PrivateRoute path={'/govProjectDetail'} component={GovProjectDetail} />
                            <Route path='/vcs' component={() => {
                                const token = localStorage.getItem('token');
                                //console.log(token);
                                setTimeout(() => {
                                    window.location.href = "http://localhost:3000/?token=" + token
                                    // window.location.href = "https://vcs.dataland.mn/?token="+token
                                }, 1000);
                                return null;
                            }} />
                            {/*<Route path="/register" component={Register}/>*/}
                            {/* <Route path="/reset-password" component={ForgetPasswordComponent}/>*/}
                            {/*<PrivateRoute exact path="/dashboard" component={Home}/>*/}
                            {/*<PrivateRoute path="/account" component={Account}/>*/}
                            {/*<PrivateRoute path="/accounts/detail" component={AccountDetail}/>*/}
                            {/*<PrivateRoute path="/planning" component={PlanningPage}/>*/}
                            {/*<PrivateRoute path="/plan/insertion" component={PlanningInsertion}/>*/}
                            {/*<PrivateRoute path='/statistics' component={Statistics}/>*/}
                            <PrivateRoute path='/bankBook' component={BankBookHome} />


                            <PrivateRoute path='/generalLedger' component={GeneralLedgerHome} />
                            <PrivateRoute path='/accountRecievable' component={AccountRecieve} />
                            <PrivateRoute path='/accountPayment' component={AccountPayment} />
                            <PrivateRoute path='/inventory' component={Inventory} />
                            <PrivateRoute path='/fa' component={Fa} />
                            <PrivateRoute path='/financeReport' component={Jasper} />
                            {/*<PrivateRoute path='/budgetUpdated' component={UpdatedBudget}/>*/}
                            {/*<PrivateRoute path='/budgetUpdatedOrlogo' component={BudgetOrlogo}/>*/}
                            {/*<PrivateRoute path='/budgetUpdatedZarlaga' component={BudgetZarlaga}/>*/}
                            {/*<PrivateRoute path='/budgetUpdatedEhUusver' component={BudgetEhUusver}/>*/}
                            {/*<PrivateRoute path='/inventory' component={Inventry}/>*/}

                            <PrivateRoute path='/feedbacks' component={Feedbacks} />
                            <PrivateRoute path='/notifications' component={Notifications} />
                            <PrivateRoute path='/document' component={Document} />
                            <PrivateRoute path='/email' component={Email} />
                            <PrivateRoute path='/testOrgDetail' component={TestOrgDetailPage} />
                            <PrivateRoute path='/settingsOrgs' component={ProfileSection} />
                            <PrivateRoute path='/settingsUser' component={User} />
                            <PrivateRoute path='/settingsLogs' component={UserLogs} />
                            <PrivateRoute path='/settingsRole' component={PermissionSection} />
                            <PrivateRoute path='/settingsUserConfig' component={UserSettings} />
                            <PrivateRoute path='/settingsReference' component={ReferenceManager} />
                            <PrivateRoute path='/settingsPosition' component={OrgPosition} />
                            <PrivateRoute path="/settingsOrgForm" component={OrgFormsManager} />
                            <PrivateRoute path="/generalInformation" component={GeneralEditingPage} />
                            <PrivateRoute path="/orgGeneral" component={OrganizationGeneralInformation} />
                            <PrivateRoute path='/stateStructure' component={StateStructure} />
                            <PrivateRoute path='/settingsLoginBanner' component={LoginBanner} />
                            <PrivateRoute path='/publication' component={Publication} />
                            <PrivateRoute path='/settingsUserManual' component={UserManual} />
                            <PrivateRoute path='/financeReportOrg' component={FinanceReport} />
                            <PrivateRoute path='/PayrollReportOrg' component={PayrollReport} />
                            <PrivateRoute path='/PayrollInfo' component={PayrollInfo} />
                            <PrivateRoute path='/statistic/:isLocal' component={Statistic} />
                            <PrivateRoute path='/analytics' component={GoogleAnalytics} />
                            <PrivateRoute path='/statistic-insert' exact component={StatisticsInsert} />
                            <PrivateRoute path='/statistics/:list_id/:tbl_id/:year' component={StatisticPage} />
                            <PrivateRoute path='/budgetTezEza' component={BudgetTezEza} />
                            <PrivateRoute path={'/dynamicTable'} exact component={DynamicTableList}/>
                            <PrivateRoute path={'/dynamicTable/create'} exact component={DynamicTableCreate}/>
                            <PrivateRoute path={'/dynamicTable/:dynamicTableId/detail'} exact component={DynamicTable} />
                            <PrivateRoute path={'/dynamicTable/:dynamicTableId/:dynamicTableName/edit'} exact component={DynamicTableEdit}/>
                            <PrivateRoute path={'/dynamicTable/:dynamicTableId/:dynamicTableName/data/list'} exact component={DynamicTableDataList}/>
                            <PrivateRoute path={'/dynamicTable/:dynamicTableId/:dynamicTableName/data/create'} exact component={DynamicTableDataCreate}/>
                            <PrivateRoute path={'/dynamicTable/:dynamicTableId/:dynamicTableName/:dynamicTableDataId/data/detail'} exact component={DynamicTableDataDetail}/>

                        </MainLayout>
                        <Route component={NotFound} />
                    </Switch>
                </UserContext.Provider>
            </TranslationProvider>
        </Router>
    );
}

export default App;

import React, {useState, useEffect, useRef, useCallback} from "react";
import GeneralInfoService from "../../services/api/generalInfo";
import notify from "devextreme/ui/notify";
import TreeList, {Button, Column, Editing, RequiredRule, RowDragging} from "devextreme-react/tree-list";
import AdvancedBudgetLinker from "./components/AdvancedBudgetLinker";
import LawLinker from "./components/LawLinker";
import LoadPanelComponent from "../../components/LoadPanelComponent";
import OrganizationLinker from "./components/OrganizationLinker";
import {custom} from "devextreme/ui/dialog";

const GeneralEditingPage = (props) => {
    const [dataSource, setDataSource] = useState([]);
    const [loader, setLoader] = useState(false);
    const [reLoader, setReLoader] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const [selectedId, setSelectedId] = useState(null);
    const [chosenGeneralData, setChosenGeneralData] = useState({});
    const [selectedRow, setSelectedRow] = useState(null);
    const [visibleBudget, setVisibleBudget] = useState(false);
    const [visibleOrg, setVisibleOrg] = useState(false);
    const [title, setTitle] = useState(null);
    const divRef = useRef();

    const hideOnEdit = useCallback(({ row }) => !row.isEditing, [])

    const loadDataSource = async () => {
        try {
            setLoader(true);
            let r = await GeneralInfoService.loadOrganizationGeneralInfo();
            setDataSource(r);
            setLoader(false);
        } catch (e) {
            setLoader(false);
            notify(e.message, "error", 2000);
        }
    }

    useEffect(() => {
        loadDataSource();
    }, [reLoader])

    const saveHandler = async (e) => {
        try {
            setLoader(true);
            if (e.changes && e.changes.length !== 0 && e.changes[0].type === 'insert') {
                let obj = e.changes[0].data;
                delete obj.id
                if (obj.parent_id === 0){
                    obj.parent_id = null;
                }
                await GeneralInfoService.addGeneralInformation(obj);
                notify("Амжилттай", "success", 2000);
                setReLoader(!reLoader);
            } else if (e.changes && e.changes.length !== 0 && e.changes[0].type === 'update') {
                await GeneralInfoService.updateGeneralInformation(e.changes[0].data);
                notify("Амжилттай", "success", 2000);
                setReLoader(!reLoader);
            }
            setLoader(false);
        } catch (e) {
            setLoader(false);
            notify(e.message, "error", 2000);
        }
    }

    //FIXME: Just set selected row instead of using chosenGeneralId state
    const openLinker = (id) => {
        setChosenGeneralData(id);
        setVisibleBudget(false);
        setVisibleOrg(false);
        setIsVisible(true);
    }

    const closeLaw = () => {
        setIsVisible(false);
    }

    //FIXME: Just set selected row instead of using selectedId state
    const openBudgetLinker = (e) => {
        setTitle(e.row.data.val);
        setSelectedId(e.row.data.id);
        setIsVisible(false);
        setVisibleOrg(false);
        setVisibleBudget(true);
        divRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }


    const openOrganizationLinker = (e) => {
        setIsVisible(false);
        setVisibleBudget(false);
        setSelectedRow(e.row.data);
        setVisibleOrg(true);
    }

    const setFalser = () => {
        setVisibleBudget(false);
    }

    const closeOrg = () => {
        setVisibleOrg(false);
    }

    const hideHandler = () => {
        setVisibleBudget(false);
    }

    // TODO: i would not really recommend this function, we really shouldn't delete things.
    const deleteLinkedData = async (id) => {
        try {
            let cDialog = custom({
                title: "Баталгаажуулалт",
                messageHtml: "Энэхүү сонголтыг устгах уу?",
                buttons: [
                    {
                        text: "Тийм", onClick: async () => {
                            let r = await GeneralInfoService.generalInfoMultiFactorDelete({general_info_id: id});
                            notify(r.message, "success", 2000);
                        }
                    },
                    {
                        text: "Үгүй", onClick: () => {
                            return false;
                        }
                    }
                ]

            })
            await cDialog.show();
        } catch (e) {
            notify(e.message, "error", 2000);
        }
    }

    return (
        <div id="loadGeneralEditor">
            <LoadPanelComponent position={{of: '#loadGeneralEditor'}} visible={loader}/>
            <TreeList
                rowAlternationEnabled={true}
                showBorders={true}
                wordWrapEnabled={true}
                showColumnHeaders={false}
                dataSource={dataSource}
                keyExpr="id"
                parentIdExpr="parent_id"
                onSaved={saveHandler}
            >
                <Editing
                    mode="row"
                    useIcons={true}
                    allowAdding={true}
                    allowUpdating={true}
                />
                <RowDragging
                    allowReordering={true}
                />
                <Column type="buttons" width={150}>
                    <Button name="add" icon="plus" hint="Нэмэх"/>
                    <Button name="edit" hint="Засварлах"/>
                    <Button name="save" hint="Хадгалах"/>
                    <Button name="cancel" hint="Цуцлах"/>
                    <Button visible={hideOnEdit} onClick={(e) => openLinker(e.row.data)} hint="Хууль холбох" icon="doc"/>
                    <Button visible={hideOnEdit} onClick={(e) => openBudgetLinker(e)} icon="money" hint="Төсөв холбох"/>
                    <Button visible={hideOnEdit} onClick={(e) => openOrganizationLinker(e)} icon="home" hint="Байгууллага холбох"/>
                </Column>
                <Column dataField="val">
                    <RequiredRule message="must insert"/>
                </Column>
            </TreeList>
            <div ref={divRef}>
                {visibleBudget && <AdvancedBudgetLinker selectedId={selectedId} callback={setFalser} title={title} onHide={hideHandler} deleteLinkedData={deleteLinkedData}/>}
                {isVisible && <LawLinker generalData={chosenGeneralData} closeLaw={closeLaw} deleteLinkedData={deleteLinkedData}/>}
                {visibleOrg && <OrganizationLinker selectedRow={selectedRow} callback={closeOrg} deleteLinkedData={deleteLinkedData}/> }
            </div>
        </div>
    )
}

export default GeneralEditingPage;

import React, {useEffect, useState, useContext} from 'react';
import OrganizationService from "../services/api/organization";
import {UserContext} from "../hooks/UserContext";

export default function ShilenDans() {
    const [shilendans, setShilendans] = useState(null);
    const {user} = useContext(UserContext);
    useEffect(() => {
        getHeaderData(user.organization.id);
    }, [user.organization.id]);
    const getHeaderData = async (orgId) => {
        try {
            let result = await OrganizationService.getOrgContact(orgId);
            let tmpshilendans = await result.data.find(element => element.name === 'shilendans');
            tmpshilendans !== undefined ? setShilendans(tmpshilendans.value.replaceAll('<p>', '').replaceAll('</p>', '')) : setShilendans(null);
        } catch (e) {
            console.error(e);
        }
    }


    return (
        <iframe width="100%" style={{height: '100vh'}}
                src={shilendans && shilendans.length > 0 ? shilendans : "https://shilendans.gov.mn"}/>
    )
}


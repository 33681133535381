import request from '../../shared/lib/request'

const getAllAgendaRelation = () => {
    return request({
        url: '/agendaRelation/getAllAgendaRelation',
        method: 'GET',
    })
}

const updateAgendaRelation = (body) => {
    return request({
        url: '/agendaRelation/updateAgendaRelation',
        method: 'POST',
        data: body,
    })
}

const agendaRelationServices = {
    getAllAgendaRelation,
    updateAgendaRelation
}

export default agendaRelationServices;
import React, {useRef, useState, useEffect, useContext} from "react";
import SelectBox from "devextreme-react/select-box";
import {getYears} from "../../../../util";
import DataGrid, {
    Column,
    Export,
    Selection,
    Summary,
    TotalItem,
    Grouping,
    GroupPanel,
    SearchPanel,
    GroupItem,
    Toolbar,
    Item
} from "devextreme-react/data-grid";
import Editing from 'devextreme-react/data-grid'
import {Workbook} from "exceljs";
import {exportDataGrid} from "devextreme/excel_exporter";
import {saveAs} from "file-saver-es";
import fcsServices from "../../../../services/api/fcs";
import notify from "devextreme/ui/notify";
import Button from "devextreme-react/button";
import fcsService from "../../../../services/api/fcs";
import {LoadPanel} from "devextreme-react/load-panel";
import ScrollView from "devextreme-react/scroll-view";
import {Popup} from "devextreme-react/popup";
import BBSummary from "./bbSummary";
import DateBox from "devextreme-react/date-box";
import {UserContext} from "../../../../hooks/UserContext";
import {isDisabled} from "bootstrap/js/src/util";
import ArEndMonth from "../../AccountRecievable/report/arEndMonth";
import e from "cors";
import {Template} from "devextreme-react";
import ScheduleService from "../../../../services/api/scheduleService";
import LoadPanelComponent from "../../../../components/LoadPanelComponent";
import {GoSearch} from 'react-icons/go'
import { FilterRow } from "devextreme-react/tree-list";

const monthList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
export default function BbReport(props) {
    const goMonth = props.goMonth
    const userListPosition = {of: '#usersList'}
    const {user} = useContext(UserContext);
    const myRef = useRef(null);
    const componentRef = useRef();
    const [loader, setLoader] = useState(false);
    const [yearValue, setYearValue] = useState(null);
    const [monthValue, setMonthValue] = useState(null);
    const [dataSource, setDataSource] = useState([]);
    const [visableEndMonth, setVisableEndMonth] = useState(false)
    //const [selectedValue, setSelectedValue] = useState(null);
    const [visibleBbReport, setVisibleBbReport] = useState(false);
    const [reportTitle, setReportTitle] = useState(null);
    const [dataFcsBbClass, setFcsBbClass] = useState([]);
    const [selectedBbClass, setSelectedBbClass] = useState([]);
    const [reportGL, setReportGL] = useState([]);
    const [modalVisibleGL, setModalVisibleGL] = useState(false);
    const [modalVisibleGLSave, setModalVisibleGLSave] = useState(false);
    const [jrNo, setJrNo] = useState('');
    const [glDate, setGlDate] = useState();
    const [dataFcsGLTran, setFcsGLTran] = useState([]);
    const [dataBalMonth, setDataBalMonth] = useState([]);
    const [modalVisibleMonthSave, setModalVisibleMonthSave] = useState(false);
    const [showBB, setShowBB] = useState(false)
    const initBB = {year: 2022, month: 1, acct: "001"}
    const [BB, setBB] = useState({...initBB})
    const [bbMonth, setBBmonth] = useState([])
    const [selectedReportType, setSelectedReportType]= useState(2)
    useEffect(() => {
        fcsBbTranList();
        getReportGL();
    }, [])
    useEffect(async () => {
        const today = new Date();
        setYearValue(today.getFullYear());
        setMonthValue(today.getMonth() + 1);

    }, []);
    const fcsBbTranList = async () => {
        try {

            setLoader(true);
            const fcsBbClass = await fcsService.getAllFcsBBClass();
            setFcsBbClass(fcsBbClass);
            const fcsMonth = await fcsService.getAllFcsMonth('BB');
            setBBmonth(fcsMonth)
            if ( fcsMonth && fcsMonth.length < 1) notify("тохиргоо хийнэ үү  ", "warning")
            // if ( fcsMonth && fcsMonth.length < 1) setLoader(false)
            if ( fcsMonth && fcsMonth.length < 1) return setLoader(false)


            const resultGlTran = await fcsService.getFcsGlTran(bbMonth[0].year, bbMonth[0].month, 'BB') ;
            setFcsGLTran(resultGlTran);


            setLoader(false)


        } catch (e) {
            console.error(e);
            return setLoader(false)
        }

    }


    const onChangeBBClass = async (e) => {
        setSelectedBbClass(e);
    }
    const changeYear = (e) => {
        setYearValue(e);
        setBB((old) => ({...old, year: e}))
    }

    const changeMonth = (e) => {
        setMonthValue(e);
        setBB((old) => ({...old, month: e}))

    }
    const customizeDate = (data) => {
        return Number(parseFloat(data.value).toFixed(2)).toLocaleString("en", {
            minimumFractionDigits: 2,
        });
    }
    const getBbReport = async (txntype) => {
        if (yearValue && monthValue && selectedBbClass) {
            try {
                setLoader(true);
                const result = await fcsServices.getFcsBbTranReport(yearValue, monthValue, selectedBbClass.bbkey, txntype)
                setDataSource(result);
                setVisibleBbReport(true);
                txntype === 0 ? setReportTitle('Мөнгөн хөрөнгийн орлогын тайлан') : setReportTitle('Мөнгөн хөрөнгийн зарлагын тайлан')
                return setLoader(false);

            } catch (e) {
                notify(e.message, "error", 2000);
            }
        }
    };
    const getReportGL = async () => {
        setLoader(true);
        const resultGL = await fcsServices.getFcsGlMonthReport('BB');
        if (resultGL.data.length > 0) {
            setReportGL(resultGL.data);
            // setModalVisibleGL(true);
            return setLoader(false);
        } else {
            return setLoader(false);
        }
    };
    const getReportGLSave = async () => {
        const resultGL = await fcsServices.getFcsGlMonthReport('BB');
        if (resultGL.data.length > 0) {
            setReportGL(resultGL.data);
            setModalVisibleGLSave(true);
        }
    };
    const onExporting = (e) => {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Main sheet');

        exportDataGrid({
            component: e.component,
            worksheet,
            autoFilterEnabled: true,
        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                //`${item.acct} - ${item.descr}`
                saveAs(new Blob([buffer], {type: 'application/octet-stream'}), `${reportTitle}-${yearValue}-${monthValue}.xlsx`);
            });
        });
        e.cancel = true;
    }

    const jrChangeValue = (event) => {
        setJrNo(event.target.value);
    }
    const setGLData = async () => {
        if (jrNo === null) return notify("Журнал дугаар хоосон байна", "warning");
        if (jrNo !== null) {
            if (dataFcsGLTran.length === 0) {
                let jr_key = null;
                reportGL.forEach(element => {
                    jr_key = jrNo;
                });
                reportGL.map((item) => {
                    item.docdate = item.docdate;
                    item.jr_key = 'BB';
                    item.acct = item.account;
                    item.descr = item.description;
                    item.brn = item.docno;
                    item.docno = jrNo;
                    item.orgcode = item.cust_code;
                    item.orgname = item.cust_name;
                    item.created_user_id = user.id;
                })
                fcsBbTranList();
                await fcsService.addFcsGLTran(reportGL);

                notify('Амжилттай ерөнхий дэвтэрлүү шилжүүллээ', "success", 2000);

            } else {

                notify('Тухайн сард та ерөнхий дэвтэрлүү илгээсэн байна', "error", 2000);

            }
        } else {
            notify('Сонгосон огноо тайлант он сарын хугацаанд биш байна', "error", 2000);

        }
    }
    const getReportMonthSave = async () => {
        const resultMonth = await fcsServices.getFcsInvBalMonth();
        if (resultMonth.data.length > 0) {
            setDataBalMonth(resultMonth.data);
            setModalVisibleMonthSave(true);
        } else {
            notify("Сарын хаалт хийх боломжгүй", "warning");
        }
    };
    const style = {
        text: {
            color: "rgba(4,7,95)", fontWeight: "700", fontSize: "13px"
        },
        box: {
            border: "1px solid rgb(224,224,224)", backgroundColor: "white", borderRadius: "1rem", padding: "0 .15rem",color: "rgba(4,7,95)", fontSize: "13px"
        },
        purple: {
            backgroundColor: "rgba(85,88,217)", fontSize: "13px", fontWeight: 600, fontStretch: "expanded"
        }
    }
    const scheduleTransferDetailBtn = (options) => {
        return (
            <Button text="Дэлгэрэнгүй" type="default" onClick={() => scheduleTransferDetailModal(options.data)}/>
        )
    }

    const scheduleTransferDetailModal = async (data) => {
        //  setSelectedScheduleTransfer(data)
        let r = await ScheduleService.getRequestDetail(data.id);
        if (r)
            alert(r)
        //  setScheduleTransferDetailData(r)
        //setScheduleTransferDetailShow(true)
    }
    const onCellPrepared= (e)=>{
        let cell= e.cellElement
        if (e.rowType!=="header" && e.rowType !== "filter"){
            cell.style= "color: rgb(20, 23, 106); font-weight: 500; padding: .875rem 0; padding-left: 1rem"
        }else if (e.rowType==="header"){
            cell.style= `
            background-color: rgb(238, 240, 244); 
            border: none; padding-top: .875rem; 
            padding-left: 1rem; 
            color: rgb(20, 23, 106); 
            font-weight: 600; 
            font-size: 13px;
            margin-top: "2rem"
            `
        } else if (e.rowType=== "filter"){
            cell.style= "background-color: rgb(238, 240, 244); padding: .5rem; border: none; margin-bottom: 1rem"
        }

    }
    const handleSelectBoxChange = (e) => {
        // eslint-disable-next-line default-case
        switch (e.value) {
            case 0:
                setVisibleBbReport(false);
                setShowBB(false)
                getBbReport(0)
                setShowBB(false)
                break;
            case 1:
                setVisibleBbReport(false);
                setShowBB(false)
                getBbReport(1)
                break;
            case 2:
                setVisibleBbReport(false);
                setShowBB(true)
                break;
        }
    }

  
    return (
        <React.Fragment>
            <div className="bankBook" ref={myRef}>
                <LoadPanelComponent
                    shadingColor="rgba(0,0,0,0.4)"
                    position={userListPosition}
                    visible={loader}
                    showPane={false}
                    message=''
                />
                <div className="bankbookToolbarContainer" style={{display: "flex",background: "white", padding: "1rem .5rem", justifyContent: "space-between", alignItems: "center"}}>
                    <div className="card-body" style={{display: "flex",gap: ".5rem", alignItems: "center"}}>
                        <div style={{display: 'flex', marginRight: 20}}>
                        <div style={{marginRight: 10, display: 'flex', alignItems: 'center', ...style.text}}>Тайлант
                            он:
                        </div>
                        <div>
                            <SelectBox
                                width={100}
                                value={yearValue}
                                items={getYears(true)}
                                style={{width: "5rem" ,...style.box}}
                                defaultValue={yearValue}
                                onValueChanged={(e) => changeYear(e.value)}
                                placeholder="сонгох"/>
                        </div>
                    </div>
                    <div style={{display: 'flex', marginRight: 20,}}>
                        <div style={{marginRight: 10, display: 'flex', alignItems: 'center', ...style.text}}>Тайлант
                            сар:
                        </div>
                        <div>
                            <SelectBox
                                width={80}
                                value={monthValue}
                                items={monthList}
                                style={{width: "3.5rem",...style.box}}
                                defaultValue={monthValue}
                                onValueChanged={(e) => changeMonth(e.value)}
                                placeholder="сонгох"/>
                        </div>
                    </div>
                    <div style={{display: 'flex', marginRight: 20}}>
                        <div style={{marginRight: 10, display: 'flex', alignItems: 'center', ...style.text}}>Данс</div>
                        <SelectBox
                            items={dataFcsBbClass}
                            value={selectedBbClass}
                            searchEnabled={true}
                            style={style.box}
                            displayExpr={(item) => {
                                return item && `${item.bbkey} - ${item.descr}`
                            }}
                            onValueChanged={(e) => onChangeBBClass(e.value)}
                            placeholder="сонгох"/>
                    </div>
                    <div style={{display: 'flex', marginRight: 20}}>
                        <div style={{marginRight: 10, display: 'flex', alignItems: 'center', ...style.text}}>Тайлан
                        </div>
                        <SelectBox
                            items={[{value: 0, text: "Орлогын тайлан"}, {value: 1, text: "Зарлагын тайлан"}, {
                                value: 2,
                                text: "Хураангуй тайлан"
                            }, {value: 3, text: "Ерөнхий дэвтэр"}]}
                            valueExpr="value"
                            style={{width: "7rem" ,...style.box}}
                            displayExpr="text"
                            onValueChanged={handleSelectBoxChange}
                            placeholder="Сонгох"/>
                    </div>
                    <div >
                           <Button className="opButtons" style={{display: "flex", alignItems: "center"}}>
                                <GoSearch/>
                           </Button>
                    </div>
                    </div>
                    <div style={{display: "flex", gap: ".5rem", alignItems: "center"}}>
                        <div style={{marginLeft: 5, marginTop: 5}}>
                        <Button className="opButtons" disabled={dataFcsGLTran.length === 0 ? false : true}
                                text={dataFcsGLTran.length === 0 ? "Шилжүүлэх" : "Шилжүүлсэн"}
                                onClick={() => getReportGLSave()}
                                type={dataFcsGLTran.length === 0 ? "default" : "success"}
                                style={{alignment: "center", borderRadius: '1rem',...style.purple}}/>
                        </div>

                        <div style={{marginLeft: 5, marginTop: 5}}>

                            <Button className="opButtons" text="Сарын хаалт"
                                onClick={() => setVisableEndMonth(true)} type="default"
                                style={{alignment: "center", borderRadius: '1rem',...style.purple }}/>
                    </div>
                    </div>
                </div>
            </div>                            
            <div className="card" style={{marginTop: "1rem"}}>
                        <DataGrid
                                dataSource={reportGL}
                                showBorders={true}
                                allowColumnResizing={true}
                                hoverStateEnabled={true}
                                className="datagridBbReport"
                                showRowLines
                                keyExpr={null}
                                allowColumnReordering={true}
                                onCellPrepared={onCellPrepared}
                                columnAutoWidth={true}
                                noDataText='Дата байхгүй байна.'
                            >
                                <FilterRow visible={true} />
                                <Toolbar>
                                    <Item location={"before"} cssClass="datagridToolbarOrg" text={"Байгууллага: "}/>
                                    <Item location={"before"} cssClass="datagridToolbarOrganization" text={`${reportGL[0] ? reportGL[0].aimag_name: ""}-${reportGL[0] ? reportGL[0].org_name: ""}`}/>
                                    <Item location={"before"} text={"Тайлант он: "}/>
                                    <Item location={"before"}  cssClass="datagridToolbarDate"  text={`${reportGL[0]  ? reportGL[0].pyear: ""}-${reportGL[0] ? reportGL[0].pmonth: ""}`}/>
                                    <Item location={"after"} cssClass="datagridExportButton" name="exportButton"/>
                                    <Item location={"after"} name="searchPanel"/>
                                </Toolbar>
                                <Column caption="Ордер №" dataField="docno" alignment="center" dataType="string" width={80}/>
                                <Column caption="Дансны дугаар" dataField="account" dataType="string" width={120}/>
                                <Column caption="Дансны нэр" dataField="account_name" dataType="string"/>
                                <Column caption="Харилцагчийн код" dataField="cust_code" dataType="string" width={140}/>
                                <Column caption="Харилцагчийн нэр" dataField="cust_name" dataType="string"/>
                                <Column caption="Дебит" dataField="debit" dataType="number" format="#,##0.00"
                                        width={120}/>
                                <Column caption="Кредит" dataField="credit" dataType="number" format="#,##0.00"
                                        width={120}/>
                                <Column caption="СТ4" dataField="st4" dataType="string"/>
                                <Column caption="Гүйлгээний утга" dataField="description" dataType="string"/>

                                <Summary>
                                    <TotalItem
                                        column="debit"
                                        summaryType="sum"
                                        customizeText={customizeDate}/>
                                    <TotalItem
                                        column="credit"
                                        summaryType="sum"
                                        customizeText={customizeDate}/>
                                </Summary>
                                <Export enabled={true} allowExportSelectedData={false}/>
                            </DataGrid>
            </div>
            <ArEndMonth setVisableEndMonth={setVisableEndMonth}
                        goMonth={goMonth} visableEndMonth={visableEndMonth} jr_key={'BB'}

            />
            <Popup
                width={500}
                height={200}
                minWidth={'50%'}
                maxWidth={'50%'}
                minHeight={'40%'}
                visible={modalVisibleGLSave}
                title={'Ерөнхий дэвтэрлүү шилжүүлэх'}
                onHiding={() => setModalVisibleGLSave(false)}
                resizeEnabled={true}
                dragEnabled={true}
                showTitle={true}
                hideOnOutsideClick={true}
            >
                <ScrollView width='100%' height='100%'>
                    <div className={'card-body'}>
                        <div style={{marginTop: 10}}>
                            {reportGL[0] &&
                                <div style={{display: 'flex', flexDirection: 'row', marginBottom: 20}}>
                                    <div style={{display: 'flex', flexDirection: 'row', marginLeft: '10%'}}>
                                        <span style={{fontSize: 14, fontWeight: 500}}>Тайлант он,сар: </span>
                                        <span style={{
                                            fontSize: 14,
                                            fontWeight: 500,
                                            marginLeft: 10
                                        }}>{reportGL[0].pyear}-{reportGL[0].pmonth}</span>
                                    </div>

                                    <div style={{display: 'flex', flexDirection: 'row', marginLeft: '10%'}}>
                                        <span style={{fontSize: 14, fontWeight: 500}}>Байгууллага: </span>
                                        <span style={{
                                            fontSize: 14,
                                            fontWeight: 500,
                                            marginLeft: 10
                                        }}>{reportGL[0].aimag_name}-{reportGL[0].org_name}</span>
                                    </div>
                                </div>
                            }
                            <div style={{display: 'flex', flexDirection: 'row', marginBottom: 20}}>
                                <div style={{display: 'flex', marginLeft: 10, marginRight: 10, marginTop: 5}}>Журнал
                                    дугаар:
                                </div>
                                <input type='string' className='law-search-input'
                                       style={{width: '50%', backgroundColor: '#FFFFFF', border: '1px solid #DDDDDD'}}
                                       value={jrNo} onChange={jrChangeValue}/>

                            </div>
                            <div className="col-12 row"
                                 style={{justifyContent: "flex-end", gap: '2rem', padding: '5px 15px'}}>
                                <Button text="Шилжүүлэх" onClick={() => setGLData()} width={150} type="default"
                                        style={{alignment: "center", borderRadius: '7px'}}/>
                            </div>

                        </div>
                    </div>
                </ScrollView>
            </Popup>

            {showBB && <BBSummary bbSetup={BB}/>}
            {visibleBbReport &&
                <div style={{marginLeft: 5, marginTop: 10}}>
                    <div className='col-12' style={{
                        display: 'flex',
                        flex: 1,
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginTop: 20
                    }}><span>{reportTitle}</span></div>

                    <div style={{marginTop: 10}} ref={componentRef}>

                        <DataGrid
                            dataSource={dataSource}
                            showBorders={true}
                            // ref={apTranRef}
                            allowColumnResizing={true}
                            hoverStateEnabled={true}
                            keyExpr="id"
                            noDataText='Дата байхгүй байна.'
                            onExporting={onExporting}>
                            <GroupPanel visible={true}/>
                            <SearchPanel visible={true}/>
                            <Grouping autoExpandAll={true}/>
                            <Column caption="Дугаар" dataField="docno" dataType="number" width={60}/>
                            <Column caption="Огноо" dataField="docdate" dataType="date" width={80}/>
                            <Editing
                                mode="row"
                                useIcons={true}

                                allowUpdating={true}
                            />
                            <Column type="buttons" width={110}>
                                <Button name="edit"/>
                                <Button name="delete"/>
                                <Button hint="Clone" icon="save" visible={true} onClick={() => alert("pkkk")}/>
                            </Column>


                            <Column caption="Данс"
                                    dataField="acct"
                                    dataType="string" groupIndex={0}/>
                            <Column caption="Дансны нэр" dataField="acct_name" dataType="string"/>
                            <Column caption="Харилцагчын дугаар" dataField="cust" dataType="string" width={100}/>
                            <Column caption="Харилцагчын нэр" dataField="custname" dataType="string" width={150}/>
                            {
                                reportTitle == 'Мөнгөн хөрөнгийн орлогын тайлан' ?
                                    <Column caption="Дүн" dataField="dt" format="#,##0.00" dataType="number"
                                            width={100}/> :
                                    <Column caption="Дүн" dataField="cr" format="#,##0.00" dataType="number"
                                            width={100}/>
                            }
                            <Column caption="Тайлбар" dataField="descr" dataType="string"/>
                            <Column alignment="center" caption="Үйлдэл" cellTemplate="cc156"/>
                            <Template name="cc156" render={scheduleTransferDetailBtn}/>

                            <Summary>
                                {
                                    reportTitle == 'Мөнгөн хөрөнгийн орлогын тайлан' ?
                                        <GroupItem
                                            column="dt"
                                            summaryType="sum"
                                            format="#,##0.00"
                                            customizeText={customizeDate}
                                            showInGroupFooter={true}/>
                                        :
                                        <GroupItem
                                            column="cr"
                                            summaryType="sum"
                                            format="#,##0.00"
                                            customizeText={customizeDate}
                                            showInGroupFooter={true}/>
                                }
                                {
                                    reportTitle == 'Мөнгөн хөрөнгийн орлогын тайлан' ?
                                        <TotalItem
                                            column="dt"
                                            summaryType="sum"
                                            format="#,##0.00"
                                            customizeText={customizeDate}/> :
                                        <TotalItem
                                            column="cr"
                                            summaryType="sum"
                                            format="#,##0.00"
                                            customizeText={customizeDate}/>
                                }
                            </Summary>
                            <Export enabled={true} allowExportSelectedData={false}/>
                        </DataGrid>
                    </div>


                </div>
            }
        </React.Fragment>
    )

}
import React, { useEffect, useState } from "react";

// Components
import FileComponent from "./components/file";
import LawHeader from "./components/lawHeader";
import TopNavComponent from "./components/TopNavComponent";
import LawForm from "./components/lawForm";
// Images, logos
import docImage from "../../assets/imgs/law-component-document.svg";

// Styles
import "./css/styles.css";

// services
import lawServices from "../../services/api/law";

// devextreme
import { Popup } from "devextreme-react/popup";
import LoadPanelComponent from "../budget/components/loadPanel";

export default function Law() {
  const [docTypes, setDocTypes] = useState([]);
  const [laws, setLaws] = useState([]);
  const [lawsReserve, setLawsReserve] = useState([]);
  const [doctypeCode, setDoctypeCode] = useState("1");
  const [isLoading, setIsLoading] = useState(true);
  const [orderBy, setOrderBy] = useState("desc");
  const [visible, setVisible] = useState(false);
  const [dT, setDT] = useState(1);

  useEffect(() => {
    services();
  }, []);

  const services = async () => {
    setIsLoading(true);
    await getDocTypes();
    await getAllLaws(doctypeCode, orderBy);
    setIsLoading(false);
  };

  const getDocTypes = async () => {
    try {
      const docTypes = (await lawServices.getDocTypes()).data;
      setDocTypes(docTypes);
    } catch (e) {
      console.error(e);
    }
  };

  const getAllLaws = async (doctype, orderBy) => {
    setLaws([]);
    try {
      const allLaws = (await lawServices.getAllLaws(doctype, orderBy)).data;
      // console.log(allLaws, 'allLaws');
      setLaws(allLaws);
      setDT(allLaws[0].law_doc_type);
      setLawsReserve(allLaws);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      <LawHeader />

      <Popup
        visible={visible}
        onHiding={() => setVisible(false)}
        hideOnOutsideClick={true}
        width="80vw"
        height="100vh"
        showTitle={false}
      >
        <LawForm
          docTypes={docTypes}
          setVisible={setVisible}
          setLaws={setLaws}
          laws={laws}
          dT={dT}
          setIsLoading={setIsLoading}
        />
      </Popup>

      <div className="law-main-layout" id={`law-main-layout`}>
        <div className="law-inner-main-layout" id="mainLayout">
          <div className="law-top-nav">
            <TopNavComponent
              setVisible={setVisible}
              docTypes={docTypes}
              tabOnChange={getAllLaws}
              changeDocType={setDoctypeCode}
              isLoading={setIsLoading}
              currentDoctypeCode={doctypeCode}
              setFileOrderBy={setOrderBy}
              fileOrderBy={orderBy}
              reserveLawsForFilter={lawsReserve}
              setFilteredLawsForDisplay={setLaws}
            />
          </div>
          <div id={`law-files-list`} style={{ position: "relative" }}>
            <LoadPanelComponent position={{ of: "#mainLayout" }} visible={isLoading} />

            <div className="law-files-grid grid-big-row">
              {laws.length > 0 ? (
                laws.slice(0, 2).map((item, index) => {
                  return (
                    <FileComponent
                      law={item}
                      size="big"
                      docTypes={docTypes}
                      setLaws={setLaws}
                      setIsLoading={setIsLoading}
                    />
                  );
                })
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "#1352B5",
                  }}
                >
                  <img src={docImage} style={{ height: "3rem" }} />
                  <div
                    style={{
                      fontSize: "1.3rem",
                      fontWeight: "200",
                      marginTop: "2rem",
                    }}
                  >
                    Мэдээлэл алга байна.
                  </div>
                </div>
              )}
            </div>
            <div className="law-files-grid grid-small-row">
              {laws.length > 0 &&
                laws.slice(2).map((item, index) => {
                  return (
                    <FileComponent
                      law={item}
                      size="small"
                      docTypes={docTypes}
                      setLaws={setLaws}
                      setIsLoading={setIsLoading}
                    />
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

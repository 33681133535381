/*eslint-disable*/
import React, {useEffect, useRef} from "react";
import 'devextreme-react/text-area';
import {number} from "prop-types";
import ReactToPrint from "react-to-print";
import Button from "devextreme-react/button";

const Fa04 = (props) => {
    const data = props.reportData
    const componentRef = useRef();
    const docDate = data.docdate
    const orgName = data.organization_model.name
    const docno = data.docnum
    const formatDate = props.formatDate
    useEffect(() => {
        console.log("props.dataaa", props.data)
    }, [props.data])
    const style = {
        text: {
            color: "#2E4765", fontWeight: "700", fontSize: ".875rem"
        },
        box: {
            border: "none", backgroundColor: "#F6F6F6", borderRadius: ".5rem", padding: ".25rem .15rem", width: "100%"
        },
        box2: {
            border: "none",
            backgroundColor: "rgb(239, 239, 239)",
            borderRadius: ".5rem",
            padding: ".25rem .4rem",
            width: "100%"
        }
    }


    return (
        <div>

            <div className="row" style={{display: 'flex', marginTop: 30,}}>

                <Button type=""
                        icon='save'
                        className="saveButton" onClick={() => props.setReportShow(false)}> Буцах </Button>

                <ReactToPrint
                    trigger={() => <Button
                        type="default"
                        icon='save'
                        className="saveButton">Хэвлэх</Button>}
                    content={() =>
                        componentRef.current}
                />
            </div>


            <div ref={componentRef}
                  style={{
                      display: "flex",
                      justifyContent: "space-evenly",
                      padding: "1.5rem",
                      flexDirection: "column",
                  }}>
                <div className="row" style={{display: 'flex', justifyContent: "space-between", alignItems: "center",}}>
                    <div style={{marginLeft: 10, marginRight: 10}}>
                        <div style={{display: 'flex', alignItems: 'left'}}>НХМаягт ҮХ-4</div>
                    </div>

                    <div style={{display: 'flex', fontSize: '40', alignItems: 'Left'}}> ҮНДСЭН ХӨРӨНГИЙГ ДОТООДОД
                        ШИЛЖҮҮЛЭХ ДАГАЛДАХ ХУУДАС № :
                    </div>
                    {/*ШИЛЖҮҮЛЭХ*/}
                    <div className='col-2' style={{marginLeft: 5,}}>
                        <div style={{display: 'flex', ...style.text}}>{docno}</div>
                    </div>

                </div>


                <div className="row" style={{display: 'flex', marginTop: "1rem"}}>
                    <div className='col-5'
                         style={{marginLeft: 5, marginRight: 10, display: "flex", justifyContent: "center"}}>
                        <div style={{display: 'flex', ...style.text}}>  {orgName}  </div>
                    </div>

                    <div className='col-5' style={{marginRight: 5, display: "flex", justifyContent: "space-evenly"}}>
                        <div style={{display: 'flex', fontSize: '20', alignItems: 'Right'}}>Огноо:</div>
                        <div style={{display: 'flex', ...style.text}}>{docDate}</div>
                    </div>
                </div>


                <div className="row" style={{display: 'flex', marginTop: 30,}}>
                    <div className='col-5'>
                        <div style={{display: 'flex', fontSize: '20', marginLeft: 10, alignItems: 'Right'}}> 1. Цех,
                            тасаг, хэсгийн нэр
                        </div>
                    </div>
                    <div className='col-5'>
                        <div style={{
                            display: 'flex',
                            fontSize: '20',
                            marginLeft: 10,
                            alignItems: 'Right', ...style.text
                        }}>
                            {data.user_dept}
                        </div>
                    </div>
                </div>


                <div className="row" style={{display: 'flex', marginTop: 10,}}>
                    <div className='col-5'>
                        <div style={{display: 'flex', fontSize: '20', marginLeft: 10, alignItems: 'Right'}}>
                            2. Хөрөнгийн нэр, төрөл, код
                        </div>
                    </div>
                    <div className='col-5'>
                        <div style={{
                            display: 'flex',
                            fontSize: '20',
                            marginLeft: 10,
                            alignItems: 'Right', ...style.text
                        }}>
                            {data.fa_name} , {data.typename} , {data.fatype}
                        </div>
                    </div>
                </div>

                <div className="row" style={{display: 'flex', marginTop: 10,}}>
                    <div className='col-5'>
                        <div style={{display: 'flex', fontSize: '20', marginLeft: 10, alignItems: 'Right'}}>
                            3. Бүртгэлийн дугаар
                        </div>
                    </div>
                    <div className='col-5'>
                        <div style={{
                            display: 'flex',
                            fontSize: '20',
                            marginLeft: 10,
                            alignItems: 'Right', ...style.text
                        }}>
                            {data.fanumber}
                        </div>
                    </div>
                </div>

                <div className="row" style={{display: 'flex', marginTop: 10,}}>
                    <div className='col-5'>
                        <div style={{display: 'flex', fontSize: '20', marginLeft: 10, alignItems: 'Right'}}>
                            4. Анхны өртөг
                        </div>
                    </div>
                    <div className='col-5'>
                        <div style={{
                            display: 'flex',
                            fontSize: '20',
                            marginLeft: 10,
                            alignItems: 'Right', ...style.text
                        }}>
                            {(data.quantity * data.cost_org).toLocaleString()}
                        </div>
                    </div>
                </div>

                <div className="row" style={{display: 'flex', marginTop: 10,}}>
                        <div style={{display: 'flex', fontSize: '20', marginLeft: 10, alignItems: 'Right'}}>
                            5. Элэгдлийн дүн
                        </div>


                        <div style={{
                            display: 'flex',
                            fontSize: '20',
                            marginLeft: 10,
                            alignItems: 'Right', ...style.text
                        }}>
                            {data.acder_amt.toLocaleString()}

                    </div>


                        <div style={{display: 'flex', fontSize: '20', marginLeft: 10, alignItems: 'Right'}}>
                            төгрөг, үлдэгдэл өртөг
                        </div>



                        <div style={{
                            display: 'flex',
                            fontSize: '20',
                            marginLeft: 10,
                            alignItems: 'Right', ...style.text
                        }}>
                            {(data.quantity * data.cost_org - data.acder_amt).toLocaleString()}

                    </div>


                        <div style={{display: 'flex', fontSize: '20', marginLeft: 10, alignItems: 'Right'}}>
                            төгрөг байв.
                        </div>


                </div>


                <div className="row" style={{display: 'flex', marginTop: 30,}}>

                    <div className='col-3'>
                        <div style={{display: 'flex', fontSize: '20', marginLeft: 10, alignItems: 'Right'}}>
                            Үйлдвэрлэсэн улс /(байгууллага)/,он
                        </div>

                        <div style={{
                            display: 'flex',
                            fontSize: '20',
                            marginLeft: 10,
                            alignItems: 'Right', ...style.text
                        }}>
                            {data.manuf}
                        </div>
                    </div>
                    <div className='col-3'>
                        <div style={{display: 'flex', fontSize: '20', marginLeft: 10, alignItems: 'Right'}}>
                            Ашиглалтад орсон он, сар, өдөр
                        </div>

                        <div style={{display: 'flex',fontSize: '20', marginLeft: 10,alignItems: 'Right',...style.text }} >
                            {formatDate(data.date_acq)}
                        </div>
                    </div>

                    <div className='col-3'>
                        <div style={{display: 'flex', fontSize: '20', marginLeft: 10, alignItems: 'Right'}}>
                            Хөрөнгийн хувийн хэргийн дугаар
                        </div>

                    </div>



                </div>


                <div className="row" style={{display: 'flex', marginTop: 10,}}>
                    <div className="row" style={{display: 'flex', marginLeft: 10, marginTop: 0,}}>6. Шилжүүлэх үндэслэл:

                    </div>


                </div>


                <div className="col-12" style={{
                    display: 'flex',
                    marginTop: 1,
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: 'center',
                    gap: 10
                }}>
                    <div style={{display: 'flex', ...style.text}}>
                        {data.descr}
                    </div>
                    <div style={{display: 'flex'}}> . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
                        . .
                        . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
                    </div>

                    <div style={{display: 'flex'}}> . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
                        . .
                        . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
                    </div>
                    <div style={{display: 'flex'}}> . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
                        . .
                        . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
                    </div>
                </div>


                <div className="row" style={{display: 'flex', marginTop: 1,}}>
                    <div className="row" style={{display: 'flex', marginLeft: 10, marginTop: 5,}}>7. Хөрөнгийн байдлын тодорхойлолт: <div className='col-10'>
                        </div>
                    </div>
                </div>


                <div className="col-12" style={{
                    display: 'flex',
                    marginTop: 0,
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: 'center',
                    gap: 10
                }}>
                    <div style={{display: 'flex'}}> . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
                        . .
                        . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
                    </div>
                    <div style={{display: 'flex'}}> . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
                        . .
                        . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
                    </div>


                    <div style={{display: 'flex'}}> . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
                        . .
                        . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
                    </div>

                </div>

                <div className="row" style={{display: 'flex', marginTop: 1,}}>
                    <div className="row" style={{display: 'flex', marginLeft: 10, marginTop: 1,}}>Хүлээлгэн өгсөн:       <div
                        className='col-10'>
                    </div>
                    </div>
                </div>
                <div className="row" style={{display: 'flex', marginTop: 10,}}>
                    <div className='col-2'>
                        <div style={{display: 'flex', fontSize: '20', marginLeft: 10, alignItems: 'Right'}}></div>
                    </div>
                    <div className='col-3'>
                        <div style={{
                            display: 'flex',
                            fontSize: '20',
                            marginLeft: 10,
                            alignItems: 'Right'
                        }}>----------------------
                        </div>
                    </div>
                    <div className='col-3'>
                        <div style={{
                            display: 'flex',
                            fontSize: '20',
                            marginLeft: 10,
                            alignItems: 'Right'
                        }}>----------------------
                        </div>
                    </div>
                    <div className='col-3'>
                        <div style={{
                            display: 'flex',
                            fontSize: '20',
                            marginLeft: 10,
                            alignItems: 'Right'
                        }}>----------------------
                        </div>
                    </div>
                </div>
                <div className="row" style={{display: 'flex', marginTop: 1,}}>
                    <div className='col-2'>
                        <div style={{display: 'flex', fontSize: '20', marginLeft: 10, alignItems: 'Right'}}></div>
                    </div>
                    <div className='col-3'>
                        <div style={{display: 'flex', fontSize: '20', marginLeft: 10, alignItems: 'Right'}}>( албан
                            тушаал )
                        </div>
                    </div>
                    <div className='col-3'>
                        <div style={{display: 'flex', fontSize: '20', marginLeft: 10, alignItems: 'Right'}}>( овог нэр
                            )
                        </div>
                    </div>
                    <div className='col-3'>
                        <div style={{display: 'flex', fontSize: '20', marginLeft: 10, alignItems: 'Right'}}>( гарын үсэг
                            )
                        </div>
                    </div>
                </div>

                <div className="row" style={{display: 'flex', marginTop: 1,}}>
                    <div className="row" style={{display: 'flex', marginTop: 5,}}>
                        <div className='col-10'>Хүлээн авсан:
                        </div>
                    </div>
                </div>

                <div className="row" style={{display: 'flex', marginTop: 10,}}>
                    <div className='col-2'>
                        <div style={{display: 'flex', fontSize: '20', marginLeft: 10, alignItems: 'Right'}}></div>
                    </div>
                    <div className='col-3'>
                        <div style={{
                            display: 'flex',
                            fontSize: '20',
                            marginLeft: 10,
                            alignItems: 'Right'
                        }}>----------------------
                        </div>
                    </div>
                    <div className='col-3'>
                        <div style={{
                            display: 'flex',
                            fontSize: '20',
                            marginLeft: 10,
                            alignItems: 'Right'
                        }}>----------------------
                        </div>
                    </div>
                    <div className='col-3'>
                        <div style={{
                            display: 'flex',
                            fontSize: '20',
                            marginLeft: 10,
                            alignItems: 'Right'
                        }}>----------------------
                        </div>

                    </div>


                </div>
                <div className="row" style={{display: 'flex', marginTop: 10,}}>
                    <div className='col-2'>
                        <div style={{display: 'flex', fontSize: '20', marginLeft: 10, alignItems: 'Right'}}></div>
                    </div>
                    <div className='col-3'>
                        <div style={{
                            display: 'flex',
                            fontSize: '20',
                            marginLeft: 10,
                            alignItems: 'Right'
                        }}>----------------------
                        </div>
                    </div>
                    <div className='col-3'>
                        <div style={{
                            display: 'flex',
                            fontSize: '20',
                            marginLeft: 10,
                            alignItems: 'Right'
                        }}>----------------------
                        </div>
                    </div>
                    <div className='col-3'>
                        <div style={{
                            display: 'flex',
                            fontSize: '20',
                            marginLeft: 10,
                            alignItems: 'Right'
                        }}>----------------------
                        </div>

                    </div>


                </div>

                <div className="row" style={{display: 'flex', marginTop: 1, justifyContent: "center"}}>

                </div>
                <div className="row" style={{display: 'flex', marginTop: 1, justifyContent: "center"}}>
                    <div className="row" style={{display: 'flex', marginTop: 5,}}>
                        <div className='col-10'></div>
                    </div>
                </div>

                <div className="row" style={{display: 'flex', marginTop: 1, justifyContent: "center"}}>
                    <div className="row" style={{display: 'flex', marginTop: 5,}}>
                        <div className='col-10'></div>
                    </div>
                </div>

            </div>

            <div className="row" style={{display: 'flex', marginTop: 1, justifyContent: "center"}}>
                <div className="row" style={{display: 'flex', marginTop: 5,}}>
                    <div className='col-10'></div>
                </div>
            </div>
        </div>
    )
        ;
};

export default Fa04
import React, {useContext, useEffect, useState, useRef} from 'react';
import 'devextreme-react/text-area';
import DataGrid, {
    Editing,
    Pager,
    Paging,
    Column,
    FilterRow,
    RequiredRule,
    PatternRule,
    Lookup, Export, Form, Item,Popup
} from 'devextreme-react/data-grid';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver-es';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { LoadPanel } from "devextreme-react/load-panel";
import {UserContext} from "../../../hooks/UserContext";
import _, {concat} from "lodash";
import fcsService from "../../../services/api/fcs";
import Button from "devextreme-react/button";
import notify from "devextreme/ui/notify";
import LoadPanelComponent from '../../../components/LoadPanelComponent';

function InventoryReg(props) {
    const userListPosition = { of: '#usersList' }
    const {user} = useContext(UserContext);
    const [dataSource, setDataSource] = useState([]);
    const [loader, setLoader] = useState(false);
    const myBabyRef = useRef(null);
    const [dataFcsUnit, setFcsUnit] = useState([]);
    const [dataFcsInvCat, setFcsInvCat] = useState([]);
    useEffect(() => {
        fcsInventoryList();
    }, [])

    const fcsInventoryList = async () => {
        try {
            setLoader(true);
            const result = await fcsService.getAllFcsInventory();
            setDataSource(result)
            const fcsUnit = await fcsService.getAllFcsUnit();
            setFcsUnit(fcsUnit);
            const fcsInvCat = await fcsService.getAllFcsInvCategory();
            setFcsInvCat(fcsInvCat);
            return setLoader(false)
        } catch (e) {
            console.error(e);
        }
    }
    const orderRender = (data) => {
        return <div style={{color: '#171010'}}>{data.rowIndex + 1}</div>;
    }
    const onEditorPreparing = (e) => {
        if (e.parentType === "dataRow" && e.dataField !== "password") {
            return;
        }
        if (e.row && e.row.isNewRow) {
            e.editorOptions.visible = true;
        }
        if (e.row && !e.row.isNewRow) {
            e.editorOptions.visible = false;
        }
    }
    const customAddRow = (grid) => {
        grid.current.instance.addRow();
    }
    const onInitNewRow = (e) => {
    }
    const setInvCat  = (newData, value) =>  {
            newData.inv_category_id=value;
            let result = _.filter(dataFcsInvCat, {id: value});
            newData.acctcode = result[0].acct_code;

        newData.stnumber =  Number(result[0].in_number) + 1
    }


    const setInvUnit  = (newData, value) =>  {
        newData.unit_id=value;
        let result = _.filter(dataFcsUnit, {id: value});
        newData.unitiss = result[0].name;

    }


    const setInventory = async (e) => {
        if (e.changes && e.changes.length !== 0 ) {


                if (e.changes[0].type === "update") {
                    try {
                        e.changes[0].data.updated_user_id = user.id;

                        await fcsService.editFcsInventory(e.changes[0].data);
                        await fcsInventoryList();
                        notify('Амжилттай хадгалагдлаа', "success", 2000);
                    } catch (e) {
                        notify('Засварлалтыг хадгалах явцад алдаа гарлаа', "error", 2000);
                    }
                } else if (e.changes[0].type === "insert") {
                    try {
                        delete e.changes[0].data.id;
                        e.changes[0].data.created_user_id = user.id;
                        e.changes[0].data.org_id = user.org_id;

                        await fcsService.addFcsInventory(e.changes[0].data);
                        const result =  _.filter(dataFcsInvCat, {id: e.changes[0].data.inv_category_id});
                        result.map(e => {
                            e.in_number = Number(e.in_number) + 1
                        })
                        await fcsService.editFcsInvCategory(result[0]);
                        await fcsInventoryList();
                        notify('Амжилттай үүслээ', "success", 2000);
                    } catch (e) {
                        notify('Шинээр үүсэх явцад алдаа гарлаа', "error", 2000);
                    }

                }

        }

    }
    const onExporting = (e) => {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Main sheet');

        exportDataGrid({
            component: e.component,
            worksheet,
            autoFilterEnabled: true,
        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Purchase.xlsx');
            });
        });
        e.cancel = true;
    }
    const style={
        text: {
            color: "#2E4765", fontWeight: "600", fontSize: "18px"
        },
        box: {
            border: "none", backgroundColor: "#F6F6F6", borderRadius: ".5rem", padding: ".25rem .15rem", width: "100%"
        }
    }
    return (
        <div style={{padding: "0.5rem"}}>
            <LoadPanelComponent
                shadingColor="rgba(0,0,0,0.4)"
                position={{of : '#password'}}
                message = ''
                 showPane={false}
            />
            <div className="col-12 row" style={{justifyContent:"center", gap:'2rem', padding: '5px 15px'}}>
            <div> <h4 style={{marginLeft: 20, textTransform: "uppercase", marginTop: 5,...style.text}}>
                    Бараа материалын бүртгэл
                    </h4>
                </div>
                {/* <Button  icon="add" text = "Нэмэх" onClick={() => customAddRow(myBabyRef)} width = {110} type = "default" style = {{alignment : "center", borderRadius: '7px'}}/> */}
            </div>
            <LoadPanelComponent
                shadingColor="rgba(0,0,0,0.4)"
                position={userListPosition}
                visible={loader}
                showPane={false}
                message = ''
            />
            <div>
                <div className="card-body">
                    <DataGrid
                        onEditorPreparing={onEditorPreparing}
                        ref={myBabyRef}
                        dataSource={dataSource}
                        onSaved={setInventory}
                        keyExpr= {null}
                        wordWrapEnabled={true}
                        onInitNewRow={onInitNewRow}
                        allowColumnResizing={true}
                        onExporting={onExporting}>
                        onEditingStart={ e => {
                            console.log('qqqqqqqqqqqqqqqqq', e);
                        }}
                    >
                        <FilterRow
                            visible = {true}
                        />
                        <Pager
                            showPageSizeSelector={true}
                            // showInfo={true}
                            showNavigationButtons={true}
                        />
                        <Column
                            dataField="id"
                            caption="Дугаар"
                            allowEditing = {false}
                            // editCellTemplate = {}
                            // cellRender = {customize}
                            width={60}
                            alignment="center"
                            cssClass = "custom"
                            allowFiltering={true}
                        >
                        </Column>
                        <Column
                            dataField="organization_model.name"
                            caption="Байгууллага нэр"
                            allowEditing = {false}
                            // editCellTemplate = {}
                            // cellRender = {customize}
                            minWidth={120}
                            alignment="center"
                            
                            cssClass = "custom"
                            allowFiltering={true}
                        >
                        </Column>
                        <Column
                            cssClass = "custom"
                            dataField="stnumber"
                            caption="БМ-ын дугаар"
                            minWidth={80}
                            alignment="center"
                            
                            allowEditing = {true}
                            allowFiltering={true}
                        >
                            <RequiredRule
                                message = 'БМ-ын дугаар оруулна уу'
                            />
                        </Column>
                        <Column
                            cssClass = "custom"
                            dataField="inv_category_id"
                            caption="Ангилал"
                            allowEditing = {true}
                            width={80}
                            alignment="center"
                            
                            allowFiltering={true}
                            setCellValue={setInvCat}

                        >
                          {/*  <RequiredRule
                                message = 'Данс оруулна уу'
                            />*/}
                            <Lookup
                                valueExpr="id"
                                displayExpr={(item) => {
                                    return item && `${item.category_no} - ${item.descriptn}`
                                }}
                                dataSource={dataFcsInvCat}


                            />
                        </Column>
                        <Column
                            cssClass = "custom"
                            dataField="acctcode"
                            caption="Данс"
                            minWidth={80}
                            alignment="center"
                            
                            allowEditing = {false}
                        >
                        </Column>
                        <Column
                            cssClass = "custom"
                            dataField="unit_id"
                            caption="Хэмжих нэгж"
                            width={80}
                            alignment="center"
                            
                            allowEditing = {true}
                            allowFiltering={true}
                            setCellValue={setInvUnit}
                        >
                            <RequiredRule
                                message = 'Хэмжих нэгж оруулна уу'
                            />
                            <Lookup
                                valueExpr="id"
                                displayExpr={(item) => {
                                    return item && `${item.code} - ${item.name}`
                                }}
                                dataSource={dataFcsUnit}

                            />
                        </Column>

                        <Column
                            cssClass = "custom"
                            dataField="descr"
                            caption="Барааны нэр"
                            allowEditing = {true}
                            minWidth={80}
                            alignment="center"
                            
                            allowFiltering={true}
                        >
                            <RequiredRule
                                message = 'Барааны нэр оруулна уу'
                            />
                        </Column>
                        <Column
                            cssClass = "custom"
                            dataField="unitcost"
                            caption="Үнэ"
                            allowEditing = {true}
                            minWidth={80}
                            alignment="center"
                            
                            allowFiltering={true}
                        >
                            <RequiredRule
                                message = 'Үнэ оруулна уу'
                            />
                            <PatternRule pattern={/^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/} message='зөвхөн тоо оруулна уу!' />
                        </Column>
                        <Editing
                            mode="popup"
                            allowUpdating={true}
                            allowDeleting={false}
                            allowAdding = {true}
                            useIcons = {true}
                            texts={{
                                cancelAllChanges: 'Болих бүгд',
                                cancelRowChanges: 'Болих',
                                confirmDeleteMessage: 'Энэ бараа мэдээллийг устгах уу?',
                                confirmDeleteTitle: 'Баталгаажуулах',
                                deleteRow: 'Устгах',
                                editRow: 'Өөрчлөх',
                                saveAllChanges: 'Хадгалах бүгд',
                                saveRowChanges: 'Хадгалах',
                                undeleteRow: 'Буцаах',
                                validationCancelChanges: 'Баталгаажуулах болих',
                            }}
                        >
                            <Popup title="Бараа материал" showTitle={true} width={1000} height={350} />
                            <Form>
                                <Item itemType="group" colCount={2} colSpan={2}>
                                    <Item dataField="organization_model.name" />

                                    <Item dataField="inv_category_id" />
                                    <Item dataField="stnumber" />
                                    <Item dataField="acctcode" />
                                    <Item dataField="unit_id" />
                                    <Item dataField="descr" />
                                    <Item dataField="unitcost" />
                                </Item>
                            </Form>
                        </Editing>
                        <Paging enabled={true} />
                        <Export enabled={true} allowExportSelectedData={false} />
                    </DataGrid>
                </div>
            </div>
        </div>
    )
}

export default InventoryReg;

import request from '../../shared/lib/request'

const getAllLaws = (doc_type, orderBy) => {
    return request({
        url: `/law/getAllLaws/${doc_type}/${orderBy}`,
        method: 'GET'
    })
}

const getOrderLaws = () => {
    return request({
        url: `/law/getOrderLaws`,
        method: 'GET'
    })
}

const addLaw = (body,type) => {
    return request({
        url: `/law/addLaw?type=${type}`,
        data: body,
        method: 'POST'
    })
}

const getDocTypes = (org_id) => {
    return request({
        url: `/law/getDocTypes/` + org_id,
        method: 'GET'
    })
}

const getLawByGroup = (org_id) => {
    return request({
        url: `/law/getLawByGroup/` + org_id,
        method: 'GET'
    })
}

const getLawByGroupByType = (num) => {
    return request({
        url: `/law/getLawByGroupByType/${num}`,
        method: 'GET'
    })
}

const submitFile = (body) => {
    return request({
        url: '/law/submitFile',
        data: body,
        method: 'POST'
    })
}

const deleteLaw = (id) => {
    return request({
        url: '/law/deleteLaw/'+id,
        method: 'POST'
    })
};

const deleteLawFile = (body) => {
    return request({
        url: `/law/deleteLawFile`,
        data: body,
        method: 'DELETE'
    })
};

const updateLaw = (body) => {
    return request({
        url: '/law/updateLaw',
        data: body,
        method: 'POST'
    })
};

const getLawOrgList = () => {
    return request({
        url: `/law/getLawOrgList`,
        method: 'GET',
    })
}

const loadLaws = (data) => {
    return request({
        url: `/law/loadLaws?orgId=${data.orgId}&isParent=${data.isParent}`,
        method: 'GET',
    })
}

const loadLawDocs = () => {
    return request({
        url: `/law/loadLawDocs`,
        method: 'GET',
    })
}

const getLawSourcedOrgs = () => {
    return request({
        url: `/law/getLawSourcedOrgs`,
        method: 'GET',
    })
}

const lawInsertion = (body) => {
    return request({
        url: '/law/lawInsertion',
        data: body,
        method: 'POST'
    })
};

const getLawsByStartOfAncestry = () => {
    return request({
        url: `/law/getLawsByStartOfAncestry`,
        method: 'GET'
    })
}

const getFileType = () => {
    return request({
        url: `/law/getFileType`,
        method: 'GET'
    })
}

const getFilteredParentOrg = () => {
    return request({
        url: `/law/getFilteredParentOrg`,
        method: 'GET'
    })
}

const lawServices = {
    getLawOrgList,
    submitFile,
    getAllLaws,
    getDocTypes,
    getOrderLaws,
    addLaw,
    deleteLaw,
    updateLaw,
    deleteLawFile,
    getLawByGroup,
    getLawByGroupByType,
    loadLaws,
    loadLawDocs,
    lawInsertion,
    getLawSourcedOrgs,
    getLawsByStartOfAncestry,
    getFileType,
    getFilteredParentOrg,
}

export default lawServices;

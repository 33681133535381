import React, {useEffect, useState} from "react"
import statsService from "../../../services/api/stat.service"
import notify from "devextreme/ui/notify"
import _, {sum} from "lodash"
import {numberWithCommas, numberWithCommas11} from "../../../util"

const HeaderStatistic = (props) => {
  const [data, setData] = useState(null)
  const [sumVal, setSumVal] = useState(0)
  const [isColumn, setIsColumn] = useState(false)

  const loadData = async (year) => {
    try {
      let {data} = await statsService.getSumByYear(
        year,
        props.list_id,
        props.tbl_id
      )
      let r = await statsService.getData(year, props.list_id, props.tbl_id)
      if (r?.[0]?.is_column) setIsColumn(r?.[0]?.is_column)
      let sum = _.sumBy(r, "DTVAL_CO")
      setSumVal(sum)
      setData(data[0])
    } catch (e) {
      notify(e.message)
    }
  }

  useEffect(() => {
    loadData(props.year)
  }, [props.year, props.list_id, props.tbl_id])

  function extractConsecutiveCapitalizedWords(string) {
    if (!string) return null
    const words = string.split(", ")
    let result = ""

    for (let i = 0; i < words.length; i++) {
      const word = words[i]
      const firstChar = word.charAt(0)
      if (firstChar === firstChar.toUpperCase()) {
        if (result.length > 0) {
          result += ", "
        }
        result += word
      } else {
        break
      }
    }
    return result
  }
  function extractConsecutiveCapitalizedWords(string) {
    const capitalizedWords = string.match(
      /[\dА-ЯҮӨЁA-Z]+(?:\s+[\dА-ЯҮӨЁA-Z]+)*/g
    );
    const result = capitalizedWords ? capitalizedWords.join(" ") : "";
    return result.replace(/,\s*$/, "");
  }

  if (!data) return null

  return (
    <div className="card" style={{flex: 1}}>
      <div
        className="card-header"
        style={{display: "flex", justifyContent: "center"}}
      >
        <span
          style={{
            color: "#2f2f2f",
            fontSize: 15,
            fontWeight: "700",
          }}
        >
          {extractConsecutiveCapitalizedWords(data?.tbl_nm)}{" "}
          {data?.cd_nm && `/${data?.cd_nm}/`}
        </span>
      </div>
      <div
        className="card-body"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <span style={{color: "#003696", fontSize: 30, fontWeight: "bold"}}>
          {!isColumn && numberWithCommas11(sumVal)}
        </span>
      </div>
    </div>
  )
}

export default HeaderStatistic

import request from '../../shared/lib/request'

const getStateStructure = () => {
    return request({
        url: '/stateStructure/getStateStructure',
        method: 'GET',
    })
}

const getOrgList = () => {
    return request({
        url: '/stateStructure/getOrgList',
        method: 'GET',
    })
}

const getStateStructureType = () => {
    return request({
        url: '/stateStructure/getStateStructureType',
        method: 'GET',
    })
}

const addStateStructure = (body) => {
    return request({
        url: '/stateStructure/addStateStructure',
        method: 'POST',
        data: body,
    })
}
const editStateStructure = (body) => {
    return request({
        url: `/stateStructure/editStateStructure`,
        method: 'POST',
        data: body,
    })
}
const removeStateStructure = (body) => {
    return request({
        url: '/stateStructure/removeStateStructure',
        method: 'POST',
        data: body,
    })
}
const stateStructureServices = {
    getStateStructure,
    getOrgList,
    addStateStructure,
    editStateStructure,
    removeStateStructure,
    getStateStructureType,
}

export default stateStructureServices;
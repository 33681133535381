import React, { useState } from "react"
import Form, {
  Item as FormItem1,
  Label,
  RequiredRule,
} from "devextreme-react/form"
import {
  FileUploader,
  LoadIndicator,
  Popup,
  ScrollView,
  SelectBox,
} from "devextreme-react"
import notify from "devextreme/ui/notify"
import FileServices from "../../../services/api/file"
import { BsFillTrashFill } from "react-icons/bs"
import List, { ItemDragging } from "devextreme-react/list"
import { Button as DevExtremeButton } from "devextreme-react/button"
import { useEffect } from "react"
import { urlServer } from "../../../shared/lib/request"
import portalServices from "../../../services/api/portal"
import ImageViewer from "../../../components/ImageViewer"
import { custom } from "devextreme/ui/dialog";
import { BiDownload } from "react-icons/bi"
import CheckBox from "devextreme-react/check-box";
import styled from "styled-components"
import Compressor from "compressorjs"


const StyledList = styled(List)`
 .dx-list-item-content{
  padding: 0px;
 }
`
const allowedFileExtensions = [
  ".jpg",
  ".jpeg",
  ".png",
  ".webp",
  ".docx",
  ".pdf",
  ".xlsx",
]

function MediaManager({ orgId }) {
  const [isLoading, setIsLoading] = useState(false)
  const [type, setType] = useState(31)
  const [addPhotoToggle, setAddPhotoToggle] = useState(false)
  const [fileNames, setFileNames] = useState([])
  const [multiple, setMultiple] = useState([])


  const [warningToggle, setWarningToggle] = useState(false)
  const [selectedId, setSelectedId] = useState(null)
  const [hasChildren, setHasChildren] = useState(false)

  useEffect(() => {
    if (orgId) {
      fetchData()
    }
  }, [orgId, type])

  const fetchData = async () => {
    try {
      let result = await portalServices.getPortalFileStructureNames(orgId, type)
      setFileNames(result?.data)
    } catch (error) {
      console.log("error", error)
    }
  }

  const typeHandler = (e) => {
    setType(e.value)
  }
  const onMultiplePhotoChange = (e) => {
    setMultiple(e.value)
  }
  const ImageUploader = () => {
    return (
      <FileUploader
        allowedFileExtensions={allowedFileExtensions}
        onValueChanged={onMultiplePhotoChange}
        selectButtonText="Файлууд сонгох"
        multiple={true}
        value={multiple}
        labelText=""
        maxFileSize={1000000}
        invalidMaxFileSizeMessage={`1mb-ээс ихгүй зураг байх`}
        accept="image/*"
        uploadMode="useForm"
        width={300}
        className="fileUploader"
      />
    )
  }
  const handleAddPhoto = async () => {
    setIsLoading(true)
    try {
      let data = new FormData()
      let compressionPromises = [];
      for (let i = 0; i < multiple.length; i++) {
        if (multiple[i].size / 1024 > 500) {
          const compressionPromise = new Promise((resolve) => {
            new Compressor(multiple[i], {
              quality: 0.6,
              type: 'image/jpeg',
              convertTypes: ['image/png', 'image/webp'],
              convertSize: 500000,
              success: async (compressedResult) => {
                const arrayBuffer = await compressedResult.arrayBuffer();
                let compressedFile = new File([arrayBuffer], multiple[i].name, { type: multiple[i].type });
                data.append("file", compressedFile);
                resolve();
              },
            });
          })
          compressionPromises.push(compressionPromise)
        } else {
          data.append("file", multiple[i])
        }
      }
      await Promise.all(compressionPromises)
      const res = await FileServices.uploadPortalStateStructureFiles(
        data,
        orgId,
        type,
        hasChildren ? 1 : 0
      )
      if (res.code === 200) {
        setMultiple([])
        setAddPhotoToggle(false)
        setHasChildren(false)
        fetchData()
        return notify(res.message, "success", 4000)
      }
    } catch (error) {
      console.log("error", error)
      setIsLoading(false)
      return notify("Хадгалах үйлдэл амжилтгүй боллоо", "warning", 3000)
    } finally {
      setIsLoading(false)
    }
  }
  const handleDeleteFile = async (file_id) => {
    try {
      let result = await FileServices.deletePortalStructureFile(file_id)
      if (result.code === 200) {
        fetchData()
        setWarningToggle(false)
        return notify(result.message, "success", 4000)
      }
    } catch (error) {
      console.log("error", error)
      return notify("Устгах үйлдэл амжилтгүй боллоо", "warning", 3000)
    }
  }

  const saveReordering = async (list) => {
    setIsLoading(true)
    try {
      await portalServices.changeFileStructureOrder(
        orgId,
        type,
        list,
      )
      return notify("Амжилттай өөрчиллөө", "success", 4000)
    } catch (error) {
      console.log("error", error)
      setIsLoading(false)
      return notify("Хадгалах үйлдэл амжилтгүй боллоо", "warning", 3000)
    } finally {
      setIsLoading(false)
    }
  }
  const deleteHandler = async (id) => {
    const customConfirm = custom({
      title: "Баталгаажуулалт",
      messageHtml: "<i>Устгахдаа итгэлтэй байна үү?</i>",
      buttons: [
        {
          text: "Тийм",
          onClick: (e) => {
            return true
          },
        },
        {
          text: "Үгүй",
          onClick: (e) => {
            return false
          },
        },
      ],
    })

    customConfirm.show().then((dialogResult) => {
      setIsLoading(true)
      if (dialogResult) {
        handleDeleteFile(id)
      }
      setIsLoading(false)
    })
  }
  const renderWarning = () => {
    return (
      <Popup
        visible={warningToggle}
        onHiding={() => setWarningToggle(false)}
        hideOnOutsideClick={true}
        showCloseButton
        height="10rem"
        showTitle
        title={"Устгахдаа итгэлтэй байна уу?"}
        width="22rem"
        id="renderaddCategoryMenu"
      >
        <div className="widget-container">
          <DevExtremeButton
            width={120}
            text="Тийм"
            className="saveButton"
            type="default"
            onClick={() => {
              handleDeleteFile(selectedId)
            }}
          />
          <DevExtremeButton
            width={120}
            text="Болих"
            type="default"
            style={{ borderRadius: "1rem" }}
            onClick={() => {
              setWarningToggle(false)
            }}
          />
        </div>
      </Popup>
    )
  }
  const RenderCheckBox = () => {
    return (
      <CheckBox value={hasChildren} onInitialized={() => setHasChildren(false)} onValueChanged={(e) => { setHasChildren(e.value) }} />
    )
  }

  const renderAddPhoto = () => {
    return (
      <Popup
        visible={addPhotoToggle}
        onHiding={() => setAddPhotoToggle(false)}
        hideOnOutsideClick={true}
        showCloseButton
        height="auto"
        showTitle
        title={type === 31 && "Баннер зураг нэмэх"}
        width={350}
        id="renderaddCategoryMenu"
      >
        <ScrollView height="100%" width="100%">
          <div className="widget-container" style={{display: 'flex', flexDirection: 'column', gap: 14}}>
            <div className="note" style={{ marginTop: ".25rem" }}>Файлын өргөтгөл: <b>.jpg, .jpeg, .png</b></div>
            <div>Баннер хэмжээ: <b>1920 x 520 px</b></div>
            <Form>
              <FormItem1 style render={ImageUploader} cssClass="bannerModal">
                <Label text="Зурагнууд оруулах" />
                <RequiredRule message="Зураг оруулна уу!" />
              </FormItem1>
              <FormItem1 render={RenderCheckBox} cssClass="bannerModal">
                <Label render={() => <div>Харьяа байгууллагад <br /> оруулах эсэх?</div>}/>
                <RequiredRule message="Зураг оруулна уу!" />
              </FormItem1>
            </Form>
            <DevExtremeButton
              icon="plus"
              text={"Нийтлэх"}
              type="default"
              style={{ marginTop: "2rem", width: 150, alignSelf: 'center' }}
              className="saveButton"
              onClick={() => handleAddPhoto()}
            >
              <span className="dx-button-text">
                <LoadIndicator
                  style={{ height: 20, width: 20 }}
                  visible={isLoading}
                />
                {!isLoading && <span>Хадгалах</span>}
              </span>
            </DevExtremeButton>
          </div>
        </ScrollView>
      </Popup>
    )
  }
  function ImageUploaderCard(props) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: "1rem",
        }}
      >
        <div>{fileNames?.findIndex(i => i?.id === props?.id) + 1}.</div>
        <div className="portalImageCard">
          <div className="meta_data">
            <span className="file_type">{props?.file_extention}</span>
            <span className="file_size">
              {(props?.file_size / 1024).toFixed(2)} kb
            </span>
          </div>
          <ImageViewer array={[props]} filename="file_name" height="5rem" />
          <div className="text">{props?.file_name?.slice(14)}</div>

          <div className="controllers">
            <a href={`${urlServer}/api/file/${props?.file_name}`}>
              <BiDownload className="look" style={{ fontSize: "1.4rem" }} />
            </a>
            <BsFillTrashFill
              className="delete"
              onClick={() => {
                deleteHandler(props?.id)
                // setWarningToggle(true)
              }}
              style={{ fontSize: "1.2rem" }}
            />
          </div>
        </div>
      </div>
    )
  }
  const onDragStart = (e) => {
    // console.log("ondrag start", e)

  }
  const onReorder = async (e) => {
    let list = [...fileNames]
    let fromIndex = e.fromIndex
    let toIndex = e.toIndex
    if (fromIndex === toIndex) {
      return
    }
    // remove the item from the list
    let fromItem = list.splice(fromIndex, 1)
    // add back the item
    list.splice(toIndex, 0, fromItem[0])
    setFileNames(list)
    saveReordering(list)
  }
  const cardStyle = {
    margin: "0 1rem",
    padding: "1rem",
    border: "1px solid #eff0f3",
    borderTop: "none",
    borderBottomLeftRadius: "15px",
    borderBottomRightRadius: "15px",
  }
  return (
    <div style={cardStyle}>
      <div style={{ paddingRight: "1rem" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "1rem",
          }}
        >
          <span
            style={{ display: "flex", alignItems: "center", marginRight: "1rem" }}
          >
            <span style={{ marginRight: "1rem", fontWeight: 700 }}>Төрөл:</span>
            <SelectBox
              id="custom-templates"
              dataSource={[{ id: 31, name: "Нүүр хуудасны баннер зургууд" }]}
              displayExpr="name"
              valueExpr="id"
              value={type}
              onValueChanged={typeHandler}
            />
          </span>
          <DevExtremeButton
            width={200}
            text="Зураг нэмэх"
            className="saveButton"
            type="default"
            onClick={() => {
              setAddPhotoToggle(true)
            }}
          />
          {/* <a href="https://compressjpeg.com/" target="_blank" style={{marginTop:3,marginLeft:5}}>Энд дарж зургаа шахаад оруулна уу!</a> */}
        </div>
      </div>
      {renderAddPhoto()}
      {renderWarning()}
      <StyledList
        hoverStateEnabled={false}
        focusStateEnabled={false}
        noDataText={orgId ? "Зураг оруулаагүй байна." : "Байгууллага сонгоогүй байна."}
        activeStateEnabled={false}
        dataSource={fileNames}
        selectionMode="none"
        keyExpr="id"
        displayExpr="file_name"
        itemRender={ImageUploaderCard}
      >
        <ItemDragging
          allowReordering={true}
          group="tasks"
          data="plannedTasks"
          onDragStart={onDragStart}
          onReorder={onReorder}
        />
      </StyledList>
    </div>
  )
}

export default MediaManager

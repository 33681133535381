import React, {useState, useEffect, useRef} from "react";
import notify from "devextreme/ui/notify";
import BudgetService from "../../../../services/api/budget";
import PivotGridDataSource from "devextreme/ui/pivot_grid/data_source";
import {PivotGrid} from "devextreme-react";
import {Export} from "devextreme-react/pivot-grid";
import {numberWithCommas} from "../../../../util";
import LoadPanelComponent from "../../../budget/components/loadPanel";
import _ from 'lodash'
import EmptyData from "../../../budget/components/empty";

function TowloruulehOnlyPivotGrid(props) {
    const [loader, setLoader] = useState(false)
    const [pivotSource, setPivotSource] = useState([]);
    const [data, setData]= useState()
    let _pivotRef = useRef(null);

    const loadPivotData = async () => {
        try {
            setLoader(true);
            let r = await BudgetService.getTowloruulehPivotData(props.year, props.chosenValue, props.chosenMinistry);
            let pp=[]
            if (r && r.length>0){
                let sum= _.sumBy(r, item=> Number(item.tuluvluguu))
                r.forEach((item, index)=>{
                    item.guitsetgel_percent= (item.guitsetgel/sum) *100
                    pp.push(item)
                })

            }
            setData(pp);
            props.checkData(pp && pp.length > 0 ? true : false);
            const dataSource = new PivotGridDataSource({
                fields: [{
                    caption: 'econ_1',
                    dataField: 'economic_category_name',
                    area: 'row',
                    expanded: true
                }, {
                    caption: 'econ_2',
                    dataField: 'income_type_name',
                    area: 'row',
                    // expanded: true,
                },
                {
                    caption: 'econ_3',
                    dataField: 'child_income_type_name',
                    area: 'row',
                    // expanded: true,
                },
                {
                    caption: 'Төлөвлөгөө',
                    dataField: 'tuluvluguu',
                    dataType: 'number',
                    summaryType: 'sum',
                    customizeText: function (e) {
                        return `${numberWithCommas(e.value)}`
                    },
                    area: 'data',
                }, {
                    caption: 'Хувь',
                    dataField: 'tuluvluguu',
                    dataType: 'number',
                    summaryType: 'sum',
                    summaryDisplayMode: 'percentOfColumnGrandTotal',
                    area: 'data',
                        customizeText: function (e) {
                            return `${e.value.toFixed(2)}`
                        },
                }, {
                    caption: 'Гүйцэтгэл',
                    dataField: 'guitsetgel',
                    dataType: 'number',
                    summaryType: 'sum',
                    customizeText: function (e) {
                        return `${numberWithCommas(e.value)}`
                    },
                    area: 'data'
                }, {
                    caption: 'Хувь',
                    dataField: 'guitsetgel_percent',
                    dataType: 'number',
                    summaryType: 'sum',
                    area: 'data',
                    customizeText: function (e) {
                        return `${e.value.toFixed(2)}`
                    },
                }],
                store: pp
            });
            setPivotSource(dataSource);
            setLoader(false);
        } catch (e) {
            setLoader(false);
            notify(e.message, "error", 2000);
        }
    }

    useEffect(() => {
        loadPivotData();
    }, [props.year, props.chosenValue, props.chosenMinistry,props.checkData])

  return (
    <div id="load10" className="towloruulehOnlyPivotGrid">
            <LoadPanelComponent position={{of: '#load10'}} visible={loader} />
            {(data && data.length>0 && !loader) ? (
            <PivotGrid
                ref={_pivotRef}
                style={{ fontFamily: "Segoe UI" }}
                dataSource={pivotSource}
                allowSorting={true}
                rowHeaderLayout={'tree'}
                wordWrapEnabled={true}
                texts={{ grandTotal: 'Нийт' }}
            >
                <Export enabled={true}/>
            </PivotGrid>
                ):
                <div style={{display: "flex", justifyContent: "center", alignItems: "center", family: "Segoe UI" }}>
                    <EmptyData/>
                </div>
            }
    </div>
  )
}

export default TowloruulehOnlyPivotGrid
import React, {useEffect, useRef, useState} from "react";
import List, {ItemDragging} from 'devextreme-react/list';
import {formElements, formTemplate, optionsColumns, optionShowElemnts} from '../static/data.js';
import Button from "devextreme-react/button";
import _ from "lodash";
import Popup from 'devextreme-react/popup';
import TextBox from 'devextreme-react/text-box';
import SelectBox from 'devextreme-react/select-box';
import TextArea from 'devextreme-react/text-area';
import RadioGroup from "devextreme-react/radio-group";
import DateBox from 'devextreme-react/date-box';
import {DataGrid, Editing, Column} from 'devextreme-react/data-grid';
import { NumberBox } from 'devextreme-react/number-box';
import OrganizationService from "../../../services/api/organization";

export default class OrgFormBuilder extends React.Component {
  constructor(props) {
    super(props);
    // console.log(props);
    this.state = {
      formTemplateData: [],
      showAddDialog: false,
      optionsDs: [],
      formName: '',
      columnCount: 1,
    };
    this.draggedItem = {};
    this.isEdit = props.isEdit;
    this.editData = props.data;
    this.isEditElement = false;

    this.formElementRender = this.formElementRender.bind(this);
    this.chooseFE = this.chooseFE.bind(this);
    this.onRemove = this.onRemove.bind(this);
    this.onAdd = this.onAdd.bind(this);
    this.onClickCreate = this.onClickCreate.bind(this);
    this.onDialogHidden = this.onDialogHidden.bind(this);
    this.onDragEnd = this.onDragEnd.bind(this);
    this.onDragStart = this.onDragStart.bind(this);
    this.onReorder = this.onReorder.bind(this);
    this.onValueChanged = this.onValueChanged.bind(this);
    this.onReorder = this.onReorder.bind(this);
    this.setOptionsDs = this.setOptionsDs.bind(this);
    this.setOptionsDsSaving = this.setOptionsDsSaving.bind(this);
    this.onSaveForm = this.onSaveForm.bind(this);
  }

  componentDidMount(props) {
    this.initVars()
    this.getData();
  }

  initVars() {

  }

  async getData() {

    if (this.isEdit && this.editData) {

      if (this.editData.type === 'table') {

      }
      this.setState({
        formName: this.editData.name,
        jsonText: this.editData.value,
        columnCount: this.editData.columnCount,
        order: this.editData.order,
        formTemplateData: this.editData.value ? JSON.parse(this.editData.value) : [],
      })
    } else { // this is create
      // const res = await OrganizationService.orgBuilderList();
      // console.log(res);
      // this.editData = res.data[3];
    }


  }

  onDragStart(e) {
    // console.log(e, 'on start');
    e.itemData = formElements[e.fromIndex];
    // e.itemData = this.state[e.fromData][e.fromIndex];
  }

  onAdd(e) {
    // console.log(e, 'on add');
    // const tasks = this.state[e.toData];
    // this.setState({
    //   [e.toData]: [...tasks.slice(0, e.toIndex), e.itemData, ...tasks.slice(e.toIndex)]
    // });
  }

  onRemove(e) {
    // console.log(e, 'on remove');
    // const tasks = this.state[e.fromData];
    // this.setState({
    //   [e.fromData]: [...tasks.slice(0, e.fromIndex), ...tasks.slice(e.fromIndex + 1)]
    // });
  }

  onReorder(e) {
    // console.log('on reorder');
    this.onRemove(e);
    this.onAdd(e);
  }

  onDragEnd(e) {
    // console.log(e, ' on drag end');
    if (e.fromData === 'elements' && e.toData === 'forms') {
      this.draggedItem = e;
      this.setState({
        showAddDialog: true
      });
    }
  }

  onDialogHidden() {
    this.setState({
      showAddDialog: false
    });
  }

  setOptionsDs(e) {
    // console.log('body: ', e.changes);
    // console.log('test: ', this.state.optionsDs);
    if (!e.changes || e.changes.length === 0) return;
    // const tt = this.state.optionsDs;
    if ( this.draggedItem.itemData.type === 'table'){
      e.changes.forEach(data => {
        if (data.type === 'insert') {
          data.data['colField'] = `col_${this.state.optionsDs.length}`;
          // tt.push(data.data);
          // projects.current.push(temp); // we dont need to push , it will automatically be pushed to the project.current
        } else if (data.type === 'update') {
          // autoSum()
          // tt.push(data.data);
        } else if (data.type === 'remove') {
          // project.current will be automatically updated
        }
      });
    }
  }
  setOptionsDsSaving(e) {
    // console.log('body: ', e.changes);
    // console.log('test: ', this.draggedItem.itemData);
    // console.log('test: ', this.state.optionsDs);
    // if (!e.changes || e.changes.length === 0) return;
    // // const tt = this.state.optionsDs;
    // e.changes.forEach(data => {
    //   if (data.type === 'insert') {
    //     data.data['colField'] = `col_${this.state.optionsDs.length}`;
    //     // tt.push(data.data);
    //     // projects.current.push(temp); // we dont need to push , it will automatically be pushed to the project.current
    //   } else if (data.type === 'update') {
    //     // autoSum()
    //     // tt.push(data.data);
    //   } else if (data.type === 'remove') {
    //     // project.current will be automatically updated
    //   }
    // });
  }

  onClickCreate() {
    // console.log("checkerLoan setShowAddDialog");
    // console.log(this.state.optionsDs);
    // console.log(this.state.formTemplateData);
    if (!this.state.field) {
      return;
    }
    if (!this.state.label) {
      return;
    }
    const t = {
      ...this.draggedItem.itemData,
      label: this.state.label,
      field: this.state.field,
      options: [...this.state.optionsDs],
      metaData: [],
    };

    // check if is edit
    let temp = [];
    if (this.isEditElement) {
      temp = [...this.state.formTemplateData];
      // console.log(this.draggedItem.itemData);
      temp[this.draggedItem.itemData.id - 1] = t;
      this.isEditElement = false;
    } else {

      temp = [
        ...this.state.formTemplateData.slice(0, this.draggedItem.toIndex), t, ...this.state.formTemplateData.slice(this.draggedItem.toIndex)
      ];
    }

    let counter = 1;
    temp.forEach(item => item.id = counter++);
    this.setState({
      showAddDialog: false,
      formTemplateData: temp,
      jsonText: JSON.stringify(temp),
      field: null,
      label: null,
      optionsDs: [],
    });
  }

  formElementRender(listItem) {
    // console.log(listItem);
    // listItem.type
    return (
      <div className="d-flex align-item-center">
        <div className="dx-field flex-expand mb-0">
          <div className="dx-field-label">{listItem.label}</div>
          <div className="dx-field-value">
            {this.chooseFE(listItem)}
          </div>

        </div>
        <div className="action-icons d-flex mx-3">
          <Button icon="edit"
                  onClick={() => this.onClickAction(listItem, 'edit')}/>
          <Button icon="remove"
                  onClick={() => this.onClickAction(listItem, 'remove')}/>
          {/*  <button className="dx-icon-edit mr-3" onClick={(e) => console.log('test')}></button>*/}
          {/*  <button className="dx-icon-remove"></button>*/}
        </div>

      </div>

    );
  }

  chooseFE(item) {
    if (item.type === 'input') {
      return <TextBox value={this.state['input']} onValueChanged={(e) => this.onValueChanged(e, 'input')}/>;
    } else if (item.type === 'select') {

      return <SelectBox
        dataSource={item.options}
        displayExpr="name"
        valueExpr="value"
        value={this.state[item.field]}
        onValueChanged={(e) => this.onValueChanged(e, item.field)}/>;
    } else if (item.type === 'textarea') {
      return <TextArea value={this.state[item.field]}
                       onValueChanged={(e) => this.onValueChanged(e, item.field)}/>;
    } else if (item.type === 'radio') {
      return <RadioGroup value={this.state[item.field]} dataSource={item.options}
                         displayExpr="name" valueExpr="value"
                         onValueChanged={(e) => this.onValueChanged(e, item.field)}/>;
    } else if (item.type === 'date') {
      return <DateBox defaultValue={new Date()} type="date"/>;
    } else if (item.type === 'datetime') {
      return <DateBox defaultValue={new Date()} type="datetime"/>;
    } else if (item.type === 'time') {
      return <DateBox defaultValue={new Date()} type="time"/>;
    } else if (item.type === 'table') {
      // console.log(item.options);
      const columns = [];
      item.options.map( i => columns.push(i.name));
      return <DataGrid showBorders={true} dataSource={[]} columns={columns}>
        {/*<Editing*/}
        {/*mode="row"*/}
        {/*allowUpdating={true}*/}
        {/*allowDeleting={true}*/}
        {/*allowAdding={false}/>*/}
      </DataGrid>;
    }

    return <TextBox/>;
  }

  onClickAction(item, action) {
    // console.log(item);
    if (action === 'edit') {
      this.draggedItem.itemData = item;
      this.isEditElement = true;
      this.setState({
        showAddDialog: true,
        label: item.label,
        field: item.field,
        optionsDs: item.options,
        metaData: item.metaData,
      })
    } else if (action === 'remove') {

    }
  }

  onValueChanged(e, field) {
    this.setState({
      [field]: e.value
    });
  }

  async onSaveForm(e) {
    // console.log(e);
    // console.log(this.state);
    const data = {
      id: this.editData ? this.editData.id : null,
      name: this.state.formName,
      columnCount: this.state.columnCount,
      order: this.state.order,
      value: this.state.jsonText,
      org_w_form_type: 1,
    }
    // console.log(data);
    const res = await OrganizationService.orgBuilderCreate(data);
    // console.log(res);
  }

  render() {
    return (
      <div className="card">
        <div className="card-body p-3">
          <div className="row-fixer form-builder">
            <div className="row mb-3">
              <div className="col-4">
                <TextBox value={this.state.formName} onValueChanged={(e) => this.onValueChanged(e, 'formName')}
                         placeholder='Форм нэр оруулна уу'/>
              </div>
              <div className="col-4">
                <NumberBox  value={this.state.order} onValueChanged={(e) => this.onValueChanged(e, 'order')}
                         placeholder='Эрэмбэ оруулна уу'/>
              </div>
              <div className="col-4">
                <div className="row mt-0">
                  <div className="col-6">
                    <NumberBox value={this.state.columnCount} onValueChanged={(e) => this.onValueChanged(e, 'columnCount')}
                             placeholder='Багана тоо оруулна уу'/>
                  </div>
                  <div className="col-6">
                    <Button
                      icon='plus'
                      text="Хадгалах"
                      type="default"
                      onClick={this.onSaveForm}
                    />
                  </div>


                </div>
              </div>
            </div>


            <div className="row my-3">
              <div className="col-4">
                <List
                  className="form-builder-list"
                  dataSource={formElements}
                  keyExpr="id"

                  repaintChangesOnly={true}>
                  <ItemDragging
                    allowReordering={true}
                    group="tasks"
                    data="elements"
                    onDragStart={this.onDragStart}
                    onAdd={this.onAdd}
                    onDragEnd={this.onDragEnd}
                    onRemove={this.onRemove}
                    onReorder={this.onReorder}>
                  </ItemDragging>

                </List>
                <TextArea height={180} value={this.state['jsonText']}
                          onValueChanged={(e) => this.onValueChanged(e, 'jsonText')} readonly={true}/>
              </div>
              <div className="col-8">
                <List
                  className="form-preview no-handle"
                  dataSource={this.state.formTemplateData}
                  keyExpr="id"
                  itemRender={this.formElementRender}
                  repaintChangesOnly={false}>

                  <ItemDragging
                    allowReordering={true}
                    group="tasks"
                    data="forms"
                    // onDragStart={onDragStart}
                    // onAdd={onAdd}
                    // onDragEnd={onDragEnd}
                    // onRemove={onRemove}
                    // onReorder={onReorder}
                  >
                  </ItemDragging>
                </List>
              </div>
            </div>


            <Popup
              minWidth={'80%'}
              minHeight={'80%'}
              visible={this.state.showAddDialog}
              title={`Форм`}
              onHiding={() => this.onDialogHidden()}
              resizeEnabled={true}
              dragEnabled={true}
              showTitle={true}
              hideOnOutsideClick={true}
              >
              <div className="row-fixer">
                <div className="row mb-3">
                  <div className="col-6">

                    <div className="dx-field">
                      <div className="dx-field-label">Гарчиг</div>
                      <div className="dx-field-value">
                        <TextBox value={this.state.label} onValueChanged={(e) => this.onValueChanged(e, 'label')}/>
                      </div>
                    </div>

                  </div>
                  <div className="col-6">
                    <div className="dx-field">
                      <div className="dx-field-label">Хадгалах талбар</div>
                      <div className="dx-field-value">
                        <TextBox value={this.state.field} onValueChanged={(e) => this.onValueChanged(e, 'field')}/>
                      </div>
                    </div>
                  </div>

                </div>
                {this.draggedItem.itemData?.type && optionShowElemnts.includes(this.draggedItem.itemData.type) &&
                <div className="row mb-3">
                  <div className="col-12">
                    <DataGrid
                      dataSource={this.state.optionsDs}
                      keyExpr="id"
                      showBorders={true}
                      onSaved={this.setOptionsDs}
                      onSaving={this.setOptionsDsSaving}
                    >
                      <Column
                        dataType="text"
                        dataField="name"
                        caption="Сонголт тэкст"
                      />
                      <Column
                        dataType="text"
                        dataField="value"
                        caption="Сонголт утга"
                      />
                      <Editing
                        mode="row"
                        allowUpdating={true}
                        allowDeleting={true}
                        allowAdding={true}/>
                    </DataGrid>
                  </div>
                </div>}
                <Button
                  className="my-5"
                  icon='plus'
                  text="Элемент нэмэх"
                  type="default"
                  stylingMode="contained"
                  onClick={this.onClickCreate}
                />
              </div>

            </Popup>
          </div>
        </div>
      </div>
    )
  }
}

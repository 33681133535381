import request from '../../shared/lib/request'

const getAll = () => {
    return request({
        url: '/emailFile/',
        method: 'GET'
    })
}

const getByIdEmailFile = (id) => {
    return request({
        url: `/emailFile/${id}`,
        method: 'GET'
    })
}

const addEmailFile = (body) => {
    return request({
        url: '/emailFile/',
        method: 'POST',
        data: body
    })
}

const EmailFileService = {
    getAll,
    getByIdEmailFile,
    addEmailFile
}

export default EmailFileService;

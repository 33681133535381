import React, {useContext, useEffect, useState, useRef} from 'react';
import 'devextreme-react/text-area';
import DataGrid, {
  Editing,
  Pager,
  Paging,
  Column,
  FilterRow,
  RequiredRule,
  PatternRule,
  Lookup, Export, Form, Item, Popup, GroupPanel, SearchPanel, Grouping, Summary, GroupItem, TotalItem
} from 'devextreme-react/data-grid';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver-es';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { LoadPanel } from "devextreme-react/load-panel";
import {UserContext} from "../../../../hooks/UserContext";
import fcsService from "../../../../services/api/fcs";
import Button from "devextreme-react/button";
import notify from "devextreme/ui/notify";
import SelectBox from "devextreme-react/select-box";

import _ from "lodash";
import ArrayStore from "devextreme/data/array_store";
import List from "devextreme-react/list";
import LoadPanelComponent from '../../../../components/LoadPanelComponent';
const monthList = [0,1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]

function GlBal(props) {
  const [selectedId, setSelectedId] = useState(1);
  const userListPosition = { of: '#usersList' }
  const {user} = useContext(UserContext);
  const [dataSource, setDataSource] = useState([]);
  const [loader, setLoader] = useState(false);
  const myBabyRef = useRef(null);
  const [dataFcsChart, setFcsChart] = useState([]);
  const [dataFcsCustOrgn, setFcsCustOrgn] = useState([]);
  const [dataFcsMonth, setFcsMonth] = useState([]);
  const [dataFcsSt4New, setFcsSt4New] = useState([]);
  const [dataFcsBbClass, setFcsBbClass] = useState([]);
  const [yearValue, setYearValue] = useState(new Date().getFullYear());
  const [monthValue, setMonthValue] = useState(new Date().getMonth()+1);
  const [dataFcsApClass, setFcsApClass] = useState([]);
  const [dataFcsGLBalMon, setFcsGLBalMon] = useState([]);


  useEffect(() => {
    fcsBbClassList();



  }, [])

  const itemClickWorld = async (e) => {
    // console.log('e.itemData.id 0', e.itemData.id);
    setSelectedId(e.itemData.id);
    // console.log('selectedId 0', selectedId);

  }

  const renderListItem = (data) => {
    return (
        <div style={{display: 'flex', alignItems: 'center', padding: '5px', height: '25px'}}>
          <svg t="1644386339421" className="icon" viewBox="0 0 1024 1024" version="1.1"
               xmlns="http://www.w3.org/2000/svg" p-id="2286" width="25" height="25">
            <path
                d="M454.393489 253.760326h383.973958c15.00061 0 28.75117 5.000203 38.959918 13.125534 11.4588 9.16704 18.542421 22.084232 18.542421 36.459817v107.296032h-38.334893l-691.278128-1.041709H127.921872v-106.462665c0-14.583927 7.083622-27.292777 18.542421-36.459817 10.208749-8.125331 23.959308-13.125534 38.959919-13.125534h38.334893v-29.376196c0-10.625432 4.375178-20.209156 11.250458-27.292777 7.083622-7.083622 16.667345-11.4588 27.292777-11.458799h153.547914c10.625432 0 20.209156 4.375178 27.292778 11.458799 6.87528 7.083622 11.250458 16.667345 11.250457 27.292777v29.584538z"
                fill="#F9A825" p-id="2287"></path>
            <path
                d="M185.632553 320.013021h652.943235c15.625636 0 30.001221 6.250254 40.418312 16.459003 10.625432 10.208749 17.084028 24.584334 17.084028 40.20997v405.224822c0 15.625636-6.458596 29.792879-17.084028 40.209969-10.417091 10.208749-24.792675 16.459003-40.418312 16.459003H185.632553c-15.625636 0-30.001221-6.250254-40.418311-16.459003-10.625432-10.208749-17.084028-24.584334-17.084028-40.209969V376.681994c0-15.625636 6.458596-29.792879 17.084028-40.20997 10.417091-10.208749 24.584334-16.459003 40.418311-16.459003z"
                fill="#FBC02D" p-id="2288"></path>
          </svg>
          <span style={{marginLeft: '10px'}}>{data.text}</span>
        </div>
    )
  }
  const customizeDate = (data) => {
    return Number(parseFloat(data.value).toFixed(2)).toLocaleString("en", {
      minimumFractionDigits: 2,
    });
  }


  const fcsBbClassList = async () => {
    try {
      setLoader(true);
      const fcsChart = await fcsService.getAllFcsChart();
      setFcsChart(fcsChart);
      const fcsMonth = await fcsService.getAllFcsMonth('GL');
      setFcsMonth(fcsMonth);

      const fcsglbal = await fcsService.getAllFcsGLBalMon();
      setFcsGLBalMon(fcsglbal)


      return setLoader(false)
    } catch (e) {
      console.error(e);
    }
  }

  const changeYear = (e) => {
    setYearValue(e);
  }

  const changeMonth = (e) => {
    setMonthValue(e);
  }
  const onEditorPreparing = (e) => {
    if (e.parentType === "dataRow" && e.dataField !== "password") {
      return;
    }
    if (e.row && e.row.isNewRow) {
      e.editorOptions.visible = true;
    }
    if (e.row && !e.row.isNewRow) {
      e.editorOptions.visible = false;
    }
  }
  const calculateAmt = (newData, value, currentRowData) => {
    newData.qty=value;
    if(value > 0){
      newData.dt = currentRowData.unit * value;
      newData.cr = 0;
    }
    else {
      newData.cr = currentRowData.unit * value;
      newData.dt = 0;
    }

  }

  const setGLBalMonEffect = async (e) => {
    if (e.changes && e.changes.length !== 0) {
      if (e.changes[0].data.year === dataFcsMonth[0].year) {
        if (e.changes[0].type === "update") {
          try {

            e.changes[0].data.updated_user_id = user.id;

            e.changes[0].data.month = 0;

            await fcsService.editFcsGLBalMon(e.changes[0].data);
            await fcsBbClassList();
            notify('Амжилттай хадгалагдлаа', "success", 2000);
          } catch (e) {
            notify('Засварлалтыг хадгалах явцад алдаа гарлаа', "error", 2000);
          }
        } else if (e.changes[0].type === "insert") {
          try {
            delete e.changes[0].data.id;
            // delete e.changes[0].data.updatedAt;
            e.changes[0].data.created_user_id = user.id;
            e.changes[0].data.org_id = user.org_id;

            e.changes[0].data.month = 0;
            await fcsService.addFcsGLBalMon(e.changes[0].data);
            await fcsBbClassList();
            notify('Амжилттай үүслээ', "success", 2000);
          } catch (e) {
            notify('Шинээр үүсэх явцад алдаа гарлаа', "error", 2000);
          }

        }
      } else {
        notify('Сонгосон жил тухайн тайлант жилд биш байна', "error", 2000);
      }
    }

  }


  const onExporting = (e) => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Main sheet');

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'glBalance.xlsx');
      });
    });
    e.cancel = true;
  }
  const onInitNewRow = (e) => {
  }
  const customAddRow = (grid) => {
    grid.current.instance.addRow();
  }
  return (
      <div>
        <LoadPanelComponent
            shadingColor="rgba(0,0,0,0.4)"
            position={{of : '#password'}}
            message = ''
             showPane={false}
        />

        <div id={'firstBox'} className={'card-body'}>
          <div id={'lawTypeLoad'}>
            <div className="row">

              <div className="col-10">




                    <div style={{marginLeft: 20}}>
                      <div className="10 row"
                           style={{justifyContent: "space-between", gap: '2rem', padding: '5px 15px'}}>
                <span>
                   Эхний үлдэгдэл
                </span>
                        <Button icon="add" text="Нэмэх" onClick={() => customAddRow(myBabyRef)}
                                width={110} type="default"
                                style={{alignment: "center", borderRadius: '7px'}}/>
                      </div>
                      <div>
                        <div className="card-body">
                          <DataGrid
                          noDataText='Дата байхгүй байна.'
                              onEditorPreparing={onEditorPreparing}
                              ref={myBabyRef}
                              dataSource={dataFcsGLBalMon}
                              onSaved={setGLBalMonEffect}
                              keyExpr={null}
                              onInitNewRow={onInitNewRow}
                              allowColumnResizing={true}
                              onExporting={onExporting}>
                            <FilterRow
                                visible={true}
                            />
                            <Pager
                                showPageSizeSelector={true}
                                // showInfo={true}
                                showNavigationButtons={true}
                            />

                            <GroupPanel visible={true} />
                            <SearchPanel visible={true} />
                            <Grouping autoExpandAll={true} />

                            <Column
                                dataField="id"
                                caption="Дугаар"
                                allowEditing={false}
                                width={50}
                                // editCellTemplate = {}
                                // cellRender = {customize}
                                cssClass="custom"
                                allowFiltering={true}
                            >
                            </Column>
                            <Column
                                dataField="organization_model.name"
                                caption="Байгууллага нэр"
                                allowEditing={false}
                                width={200}
                                cssClass="custom"
                                allowFiltering={true}
                            >
                            </Column>

                            <Column
                                cssClass="custom"
                                dataField="year"
                                caption="Он"
                                dataType="number"
                                allowEditing={true}
                                allowFiltering={true}
                            >
                              <RequiredRule
                                  message='Жил оруулна уу'
                              />
                            </Column>
                            <Column
                                cssClass="custom"
                                dataField="month"
                                caption="Сар"
                                dataType="number"
                                allowEditing={false}
                                allowFiltering={true}
                            >
                            </Column>


                            <Column
                                cssClass="custom"
                                dataField="debit"
                                caption="Дебиt дүн"
                                dataType="number"
                                allowEditing={true}
                                allowFiltering={true}
                            >
                              <RequiredRule
                                  message='дебит дүн оруулна уу'
                              />
                            </Column>
                            <Column
                                cssClass="custom"
                                dataField="credit"
                                caption="кридет"
                                dataType="number"
                                allowEditing={true}
                                allowFiltering={true}
                            >
                              <RequiredRule
                                  message='кридет дүн оруулна уу'
                              />
                            </Column>

                            <Column
                                cssClass="custom"
                                dataField="acct"
                                caption="данс"
                                allowEditing={true}
                                allowFiltering={true}
                            >
                              <RequiredRule
                                  message=' данс оруулна уу'
                              />
                              <Lookup
                                  valueExpr="acct"
                                  displayExpr={(item) => {
                                    return item && `${item.acct} - ${item.descr}`
                                  }}
                                  dataSource={dataFcsChart}

                              />
                            </Column>

                            <Summary>


                              <GroupItem
                                  column="debit"
                                  summaryType="sum"
                                  format="#,##0.00"
                                  customizeText={customizeDate}
                                  showInGroupFooter={true} />



                              <GroupItem
                                  column="credit"
                                  summaryType="sum"
                                  format="#,##0.00"
                                  customizeText={customizeDate}
                                  showInGroupFooter={true} />



                              <TotalItem
                                  column="debit"
                                  summaryType="sum"
                                  format="#,##0.00"
                                  customizeText={customizeDate}/>

                              <TotalItem
                                  column="credit"
                                  summaryType="sum"
                                  format="#,##0.00"
                                  customizeText={customizeDate}/>


                            </Summary>




                            <Editing
                                mode="form"
                                allowUpdating={true}
                                allowDeleting={false}
                                // allowAdding = {adding}
                                useIcons={true}
                                texts={{
                                  cancelAllChanges: 'Болих бүгд',
                                  cancelRowChanges: 'Болих',
                                  confirmDeleteMessage: 'Энэ эхний үлдэгдэл устгах уу?',
                                  confirmDeleteTitle: 'Баталгаажуулах',
                                  deleteRow: 'Устгах',
                                  editRow: 'Өөрчлөх',
                                  saveAllChanges: 'Хадгалах бүгд',
                                  saveRowChanges: 'Хадгалах',
                                  undeleteRow: 'Буцаах',
                                  validationCancelChanges: 'Баталгаажуулах болих',
                                }}
                            >
                            </Editing>
                            <Paging enabled={true}/>
                            <Export enabled={true} allowExportSelectedData={false}/>
                          </DataGrid>
                        </div>
                      </div>
                    </div>




              </div>
            </div>
          </div>
        </div>


      </div>
  )
}

export default GlBal;

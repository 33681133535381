import React, { useState, useEffect, useContext } from "react";
import SelectBox from "devextreme-react/select-box";
import DataGrid, {
  Column,
  SearchPanel,
  Paging,
  Pager,
  FilterRow,
} from "devextreme-react/data-grid";
import notify from "devextreme/ui/notify";
import styles from "../settings.module.css";
import searchImg from "../../../assets/imgs/chat-search-input.svg";
import RemainingService from "../../../services/api/remainingService";
import YourChartComponent from "./UserLogsGraph";
import BudgetService from "../../../services/api/budget";
import UserLogCardComponent from "./UserLogCard";
import OrganizationService from "../../../services/api/organization";
import { UserContext } from "../../../hooks/UserContext";
import { Color } from "devextreme-react/chart";

let timeOut;

const UserLogs = () => {
  const { user } = useContext(UserContext);
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [yearList, setYearList] = useState([]);
  const [mainData, setMainData] = React.useState([]);
  const [tempData, setTempData] = React.useState([]);
  const [pageIndex, setPageIndex] = useState(0);
  const [searchInput, setSearchInput] = React.useState();
  const [yamList, setYamList] = useState();
  const [chosenYam, setChosenYam] = useState("ALL");
  const [currData, setCurrData] = React.useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [loader, setLoader] = useState(true)
  const [selectedPageSize, setSelectedPageSize] = useState(10);

  React.useEffect(async () => {
    try {
      const logs = await RemainingService.getAllLogs(currentYear, chosenYam);
      console.log("logs:     ", logs);
      setMainData(logs.data);
      setTempData(logs.data);
    } catch (e) {
      console.log(e, " -> logs");
      return notify("Хэрэглэгчдийн нэвтрэлтийн мэдээлэл авхад алдаа гарлаа!");
    }
  }, [currentYear, chosenYam]);

  useEffect(() => {
    loadYearList();
    loadMinistryList();
    loadData();
  }, []);

  const loadData = async () => {
    try {
      const logs = await RemainingService.getLoginLogByMonth(
        currentYear,
        chosenYam
      );
      setCurrData(logs.data);
      let totalCountValue =
        logs.data &&
        logs.data.reduce(
          (sum, current) => sum + parseInt(current.count, 10),
          0
        );
      setTotalCount(totalCountValue);
    } catch (e) {
      console.log(e, " -> logs");
    }finally{
      setLoader(false)
    }
  };

  useEffect(() => {
    loadData();
  }, [currentYear, chosenYam]);

  const loadMinistryList = async () => {
    try {
      let result = await OrganizationService.getCurrentMinistryList(currentYear);
      setYamList(
        result.data.length > 0 && result.data.length === 1
          ? result.data
          : [
              {
                name: "БҮГД",
                org_id: "ALL",
              },
              ...result.data,
            ]
      );
    } catch (error) {
      notify(error.message, "error", 2000);
    }
  };
  console.log('====================================');
  console.log(yamList);
  console.log('====================================');
  const loadYearList = async () => {
    try {
      let result = await BudgetService.getOrgBudgetYear();
      setYearList(result);
    } catch (error) {
      notify(error.message, "error", 2000);
    }
  };
  const action = (cellData) => {
    const act = cellData.data.action_type;
    return (
      <div className={styles.actionType}>
        <p
          className={
            act.toLowerCase() === "login" || act.toLowerCase() === "нэвтрэх"
              ? styles.green
              : styles.red
          }
        >
          {act}
        </p>
      </div>
    );
  };

  const orderRender = (data) => {
    return (
      <div style={{ color: "#171010" }}>
        {pageIndex * selectedPageSize + data.rowIndex + 1}
      </div>
    );
  };

  const time = (data) => {
    return (
      <div>{data.data.updatedAt + " " + data.data.createdAt.slice(0, 8)}</div>
    );
  };

  const handleOptionChange = (e) => {
    // console.log('handleOptionChange',e)
    if (e.fullName === "paging.pageIndex") {
      setPageIndex(e.value);
    }
    if (e.fullName === "paging.pageSize") {
      setSelectedPageSize(e.value);
    }
  };
  const onCellPreparedHandler = (e) => {
    if (e.rowType === "header") {
      e.cellElement.wordWrapEnabled = true;
      e.cellElement.style.fontFamily = "Montserrat";
      e.cellElement.style.fontWeight = "semibold";
      e.cellElement.style.color = "#15186A";
      e.cellElement.style.backgroundColor = "#EEF0F4";
    }
    if (e.rowType === "data") {
      e.cellElement.style.fontFamily = "Montserrat";
      e.cellElement.style.fontWeight = "semibold";
      e.cellElement.style.color = "#000000";
      e.cellElement.style.verticalAlign = 'middle'
    }
  };
  const allowedPageSizes = [5,10,20]
  return (
    <div
      style={{ padding: 10, display: "flex", gap: "1rem", flexWrap: "wrap" }}
    >
      <div>
        <SelectBox
          items={yearList}
          valueExpr="value"
          displayExpr="value"
          label="Жил"
          width="10rem"
          defaultValue={currentYear}
          onValueChanged={(e) => setCurrentYear(e.value)}
          placeholder="сонгох"
          style={{ borderRadius: 10 }}
        />
      </div>
      <div>
        <SelectBox
          items={yamList}
          label="Яам"
          defaultValue={chosenYam}
          width="15rem"
          valueExpr="org_id"
          displayExpr="name"
          onValueChanged={(e) => {
            setChosenYam(e.value);
          }}
          placeholder="сонгох"
          style={{ borderRadius: 10 }}
        />
      </div>
      <div>
        <div
          className="row d-flex responsive-flex"
          style={{ justifyContent: "flex-start", flexDirection: "row" }}
        >
          <div
            style={{ flex: 1, height: "33rem" }}
            className="card col-6 responsive-col"
          >
            <div className="card-header">
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "row",
                }}
              >
                <span style={{ fontWeight: "700" }}>1. СТАТИСТИК ҮЗҮҮЛЭЛТ</span>
              </div>
            </div>
            <div
              className="card-body col-6 responsive-col"
              style={{ padding: "0.5rem", paddingBottom: "0rem" }}
            >
              <UserLogCardComponent
                totalCount={totalCount}
                year={currentYear}
                yam={chosenYam}
              />
            </div>
          </div>
          <div
            className="card col-6 responsive-col"
            style={{ flex: 1, height: "33rem" }}
          >
            <div className="card-header">
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "row",
                }}
              >
                <span style={{ fontWeight: "700" }}>
                  2. ХАНДСАН ХЭРЭГЛЭГЧИЙН ТОО /сараар/
                </span>
              </div>
            </div>
            <div className="card-body" style={{ paddingBottom: "1.6rem" }}>
              <YourChartComponent currData={currData} />
            </div>
          </div>
        </div>
        <DataGrid
          noDataText="Дата байхгүй байна."
          dataSource={mainData}
          rowAlternationEnabled={true}
          allowColumnResizing={true}
          wordWrapEnabled={true}
          onOptionChanged={handleOptionChange}
          showBorders={true}
          showColumnLines={true}
          showRowLines={true}
          onCellPrepared={onCellPreparedHandler}
        >
          <SearchPanel visible={false} highlightSearchText={true} />
          <FilterRow visible={true} />
          <Paging defaultPageSize={selectedPageSize} />
          <Pager showPageSizeSelector={true} showNavigationButtons={true} allowedPageSizes={allowedPageSizes} />
          <Column
            cellRender={orderRender}
            caption="№"
            allowEditing={false}
            alignment="center"
            width={60}
          />
          <Column
            dataField="org_name"
            caption="Байгууллага"
            alignment="center"
            width={150}
          />
          <Column
            dataField="username"
            caption="Хэрэглэгчийн нэр"
            alignment="center"
          />
          <Column
            caption="Нэвтэрсэн огноо"
            alignment="center"
            cellRender={time}
            width={150}
          >
          </Column>
          <Column
            dataField="location_work"
            caption="Дотоодоос хандсан газар"
            alignment="center"
          />
          <Column dataField="country_name" caption="Улс" alignment="center" />
          <Column
            dataField="country_code"
            caption="Улс код"
            alignment="center"
            width={50}
            visible={false}
          />
          <Column dataField="city" caption="Хот" alignment="center" />
          <Column dataField="region" caption="Бүс" alignment="center" />
          <Column dataField="ipv4" caption="IP хаяг" alignment="center" />

          <Column dataField="latitude" caption="Уртраг" alignment="center" />
          <Column dataField="longitude" caption="Өргөрөг" alignment="center" />
          <Column dataField="isp" caption="ISP" alignment="center" />
          <Column
            caption="Төлөв"
            dataField="action_type"
            cellRender={action}
            alignment="center"
            width={100}
          />
        </DataGrid>
      </div>
    </div>
  );
};

export default UserLogs;

import request from '../../shared/lib/request'

const getAllFeedback = () => {
    return request({
        url: '/feedback',
        method: 'GET',
    })
}
const getTypeCount = () => {
    return request({
        url: '/feedback/getTypeCount',
        method: 'GET',
    })
}
const getStatusCount = () => {
    return request({
        url: '/feedback/getStatusCount',
        method: 'GET',
    })
}

const getFeedbackById = (id) => {
    return request({
        url: `/feedback/getFeedbackById?id=${id}`,
        method: 'GET'
    })
}
const createFeedback = (data) => {
    return request({
        url: '/feedback/createFeedback',
        method: 'POST',
        data: data
    })
}

const getAllStatus = () => {
    return request({
        url: '/feedback/getAllStatus',
        method: 'GET'
    })
}
const getAllFeedbackType = () => {
    return request({
        url: '/feedback/getAllFeedbackType',
        method: 'GET'
    })
}
const getSystemOrgId = () => {
    return request({
        url: '/feedback/getSystemOrgId',
        method: 'GET'
    })
}

const editFeedback = (body, id) => {
    return request({
        url: `/feedback/editFeedback?id=${id}`,
        method: 'PUT',
        data: body
    })
}

const sendEmail = (body) => {
  return request({
    url: '/feedback/sendEmail',
    method: 'POST',
    data: body
  })
}

const addNotification = (body)=>{
    return request({
        url: '/feedback/addNotification',
        method: 'POST',
        data: body
    })
}
const receiveNotification = (body)=>{
    return request({
        url: `/feedback/receiveNotification`,
        method: 'POST',
        data: body
    })
}
const passedFbNotif = (body)=>{
    return request({
        url: `/feedback/passedFbNotif`,
        method: 'POST',
        data: body
    })
}
const createLog = (id, body)=>{
    return request({
        url: `/feedback/createLog?feedback_id=${id}`,
        method: 'POST',
        data: body
    })
}
const getLog = (fbId) =>{
    return request({
        url: `/feedback/getLog?feedback_id=${fbId}`,
        method: 'GET',
        
    })
}
const feedbackResponse = (body)=>{
    return request({
        url: '/feedback/feedbackResponse',
        method: 'POST',
        data: body
    })
}

const feedbackServices = {
    getAllFeedback,
    createFeedback,
    getAllStatus,
    getAllFeedbackType,
    getFeedbackById,
    editFeedback,
    getTypeCount,
    getStatusCount,
    sendEmail,
    addNotification,
    receiveNotification,
    feedbackResponse,
    createLog,
    getLog,
    getSystemOrgId,
    passedFbNotif
}

export default feedbackServices;

import classes from "./SidebarMenu.module.css";
import {NavLink} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import SubLinks from "./SubLinks";
import { UserContext } from "../../hooks/UserContext";
import {useState, useContext} from "react";
import { useTranslation } from "../../hooks/useTranslation";

const BigLinks = ({item, keyCustom}) => {
    const [toggle, setToggle] = useState(false);
    const {user}= useContext(UserContext)
    const {isMongolian} = useTranslation()

    const toggleHandler = () => {
        if (hasChildMenu){
            setToggle(!toggle);
        }
    };
    
    const hasChildMenu = item.other.length >= 1 && item.other[0].have_parent === 'true';
    const isNew = ["ЗЭЭЛ, ТУСЛАМЖ", "ХЯНАХ САМБАР"].includes(item?.parent_name)

    const text = (item, type) =>{
        let itemOther = item?.other?.[0]
        switch(type){
            case "title":
                if (!isMongolian && itemOther?.parent_eng_name){
                    return itemOther?.parent_eng_name
                }
                return itemOther?.parent_name
            case "description":
                if (!isMongolian && itemOther?.parent_eng_description){
                    return itemOther?.parent_eng_description
                }
                return itemOther?.parent_description
            default:
                break;
        }
    }

    return (
        <>
            <div className={`${classes.bigLink} ${toggle && classes.silver}`}>
                <NavLink to={`/${item.other[0].parent_menu_url}`} onClick={toggleHandler} style={{ textDecoration: 'none', width: 220, height: "100%", display: "flex", alignItems: "center", pointerEvents: item.parent_name === 'БУСАД МОДУЛЬ' ? 'none' : 'fill'}}>
                    <div style={{display: 'flex', position: 'relative', width: "100%",flex:1,alignItems:'center', justifyContent:'space-between',flexDirection:'row'}} >
                        <img src={item.other[0].img_icon} className={toggle ? classes.bigLinkImgMargin : classes.bigLinkImg} />
                        <p className={`bigLinkLabel ${isNew && "new"} ${toggle ? classes.withMargin : classes.noMargin}`} id={`bigLinksCustomId_${item.order_id}`}>
                        {text(item, "title")}
                        </p>
                        {toggle && <p className={'bigLinkSubText'}>{text(item, "description")}</p>}
                        {/* {item.order_id === 14 && item.parent_name === 'ТӨСВИЙН САНАЛ' && <StartTourBeaconButton />} */}
                    </div>
                </NavLink>
                { hasChildMenu &&
                <div className={classes.customIconStyle} onClick={toggleHandler}>
                    <i className={`dx-icon ${toggle ? 'dx-icon-chevronup' : 'dx-icon-chevrondown'} `} ></i>
                    </div>
                }
            </div>
            { hasChildMenu && toggle &&
                <SubLinks setToggle={setToggle} toggle={toggle} key={keyCustom} user={user} buba={keyCustom} item={item.other} />
            }
        </>
    )
};

export default BigLinks;


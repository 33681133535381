import React, {Component} from "react";
import TabPanel, { Item } from 'devextreme-react/tab-panel';
import Agenda from "./Agenda";
import Event from "./Event";
import EconomicCategory from "./EconomicCategory";
import GovAgenda from "./GovAgenda";

export default class ReferenceManager extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showAddDialog: false,
      editData: {},
      isEdit: false,
      loader: false,
    };

  }

  render() {
    return (
      <div className='card'>
        <div className={'card-body'}>
          <TabPanel>
            <Item title="Хөтөлбөр удирдах" icon="doc">
              <div>
                <div className='card'>
                  <div className="card-body">
                    <Agenda></Agenda>
                  </div>
                </div>
              </div>
            </Item>
            <Item title="Зориулалт, арга хэмжээ удирдах" icon="event">
              <div>
                <div className='card'>
                  <div className="card-body">
                    <Event></Event>
                  </div>
                </div>
              </div>
            </Item>
            <Item title="ЭЗА удирдах" icon="chart">
              <div>
                <div className='card'>
                  <div className="card-body">
                    <EconomicCategory></EconomicCategory>
                  </div>
                </div>
              </div>
            </Item>
            <Item title="Бодлогын баримт бичиг удирдах" icon="textdocument">
              <div>
                <div className='card'>
                  <div className="card-body">
                    <GovAgenda></GovAgenda>
                  </div>
                </div>
              </div>
            </Item>
          </TabPanel>

          {/*<div className="card">*/}
          {/*  <div className="card-body">*/}
          {/*    <PositionSection></PositionSection>*/}
          {/*  </div>*/}
          {/*</div>*/}
        </div>
      </div>
    )
  }
}

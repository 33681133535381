import request from "../../shared/lib/request";

function loadOrganizationGeneralInfo() {
    return request({
        url:    `/generalInfo/retrieve`,
        method: 'GET'
    })
}

function addGeneralInformation(data) {
    return request({
        url:    `/generalInfo/addGeneralInformation`,
        data: data,
        method: 'POST'
    })
}

function linkDataOnGeneralInfo(data) {
    return request({
        url:    `/generalInfo/linkDataOnGeneralInfo`,
        data: data,
        method: 'POST'
    })
}

function getLinkedLaws(param) {
    return request({
        url:    `/generalInfo/getLinkedLaws/${param}`,
        method: 'GET'
    })
}

function getRefBudgetStatus() {
    return request({
        url:    `/budget/getRefBudgetStatus/`,
        method: 'GET'
    })
}

function budgetDataOnGeneralInfo(pageSize, pageIndex, formData) {
    return request({
        url:    `/generalInfo/budgetDataOnGeneralInfo/${pageSize}/${pageIndex}`,
        data: formData,
        method: 'PUT'
    })
}

function fetchOrganizationsForGeneralInfo() {
    return request({
        url:    `/generalInfo/fetchOrganizationsForGeneralInfo`,
        method: 'GET'
    })
}

function setGenInfoBudget(data) {
    return request({
        url: `/generalInfo/setGenInfoBudget`,
        data: data,
        method: 'POST'
    })
}

function setGenInfoOrg(data) {
    return request({
        url: `/generalInfo/setGenInfoOrg`,
        data: data,
        method: 'POST'
    })
}

//User organization services
function fetch() {
    return request({
        url:    `/generalInfo/fetchData`,
        method: 'GET'
    })
}

function fetchAdditionalData(data) {
    return request({
        url:    `/generalInfo/fetchAdditionalData`,
        data: data,
        method: 'PUT',
    })
}

function updateGeneralInformation(data) {
    return request({
        url:    `/generalInfo/updateGeneralInformation`,
        data: data,
        method: 'PUT',
    })
}

function fetchSavedBudgetData(generalId) {
    return request({
        url:    `/generalInfo/fetchSavedBudgetData/${generalId}`,
        method: 'GET',
    })
}

function fetchSavedOrgData(generalId) {
    return request({
        url:    `/generalInfo/fetchSavedOrgData/${generalId}`,
        method: 'GET',
    })
}


function generalInfoMultiFactorDelete(data) {
    return request({
        url:    `/generalInfo/generalInfoMultiFactorDelete`,
        data: data,
        method: 'POST',
    })
}


const GeneralInfoService = {
    loadOrganizationGeneralInfo,
    addGeneralInformation,
    linkDataOnGeneralInfo,
    getLinkedLaws,
    budgetDataOnGeneralInfo,
    getRefBudgetStatus,
    setGenInfoBudget,
    fetch,
    fetchAdditionalData,
    fetchSavedBudgetData,
    updateGeneralInformation,
    fetchOrganizationsForGeneralInfo,
    setGenInfoOrg,
    fetchSavedOrgData,
    generalInfoMultiFactorDelete,
}

export default GeneralInfoService

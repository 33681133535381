import React, {useContext, useEffect, useState, useRef} from 'react';
import 'devextreme-react/text-area';
import DataGrid, {
    Editing,
    Pager,
    Paging,
    Column,
    FilterRow,
    RequiredRule,
    PatternRule,
    Lookup, Export, Form, Item, Toolbar
} from 'devextreme-react/data-grid';
import {Popup} from "devextreme-react/popup";
import {Workbook} from 'exceljs';
import {saveAs} from 'file-saver-es';
import {exportDataGrid} from 'devextreme/excel_exporter';
import {LoadPanel} from "devextreme-react/load-panel";
import {UserContext} from "../../../hooks/UserContext";
import _ from "lodash";
import fcsService from "../../../services/api/fcs";
import Button from "devextreme-react/button";
import notify from "devextreme/ui/notify";
import SelectBox from "devextreme-react/select-box";
import {getYears} from "../../../util";
import fcsServices from "../../../services/api/fcs";
import ArrayStore from "devextreme/data/array_store";
import List from "devextreme-react/list";
import EditApSelect from "./editapselect";
import LoadPanelComponent from '../../../components/LoadPanelComponent';
import {HiPlus, HiSearch} from 'react-icons/hi';

import {ScrollView} from 'devextreme-react';
import DepositPopup from '../BankBook/DepositPopup';
import Purchase from './Purchase';
import ApSelect from './apselect';
import {FiSettings} from 'react-icons/fi';

const monthList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
export const tabs = [
    {
        id: 1,
        text: 'Худалдан авалт'
    }, {
        id: 5,
        text: 'Төлөлт'
    },
    {
        id: 3,
        text: 'Эхний үлдэгдэл'
    }
];
const tabData = new ArrayStore({
    key: 'id',
    data: tabs,
});

function EditPurchase(props) {
    const [selectedId, setSelectedId] = useState(1);
    const userListPosition = {of: '#usersList'}
    const {user} = useContext(UserContext);
    const [dataSource, setDataSource] = useState([]);
    const [loader, setLoader] = useState(false);
    const myBabyRef = useRef(null);
    const [dataFcsChart, setFcsChart] = useState([]);
    const [dataFcsCustOrgn, setFcsCustOrgn] = useState([]);
    const [dataFcsApClass, setFcsApClass] = useState([]);
    const [dataFcsMonth, setFcsMonth] = useState([]);
    const [yearValue, setYearValue] = useState(null);
    const [monthValue, setMonthValue] = useState(null);
    const [visibleApTran, setVisibleApTran] = useState(false);
    const [dataFcsGlBalOrg, setFcsGlBalOrg] = useState([]);
    const [popup, setPopup] = useState(false)


    useEffect(() => {
        // setDate();
        fcsAptranList();
    }, [])

    const setDate = async () => {

    }

    useEffect(() => {
        //   fcsGLBalOrgList();
    }, [])


    useEffect(async () => {
        selectedIdChange()
    }, [selectedId]);

    const listAptran = async () => {

        if (yearValue > 0) {
            const result = await fcsServices.getFcsAptranReport(yearValue, monthValue, 0)
            setDataSource(result)
        }

    }
    const fcsAptranList = async () => {
        try {
            setLoader(true);
            const fcsMonth = await fcsService.getAllFcsMonth('AP');
            setFcsMonth(fcsMonth);
            fcsMonth.map((item) => {
                setYearValue(item.year)
                setMonthValue(item.month)
            })
            const fcsChart = await fcsService.getAllFcsChart();
            setFcsChart(fcsChart);
            const fcsApClass = await fcsService.getFcsChart('AP', 0, 0, 0, 0);
            setFcsApClass(fcsApClass);
            const fcsCustOrgn = await fcsService.getAllFcsCustOrgn();
            setFcsCustOrgn(fcsCustOrgn);

            setVisibleApTran(true);
            return setLoader(false)
        } catch (e) {
            console.error(e);
        }
    }
    const itemClickWorld = async (e) => {
        setSelectedId(e.itemData.id);

    }

    const renderListItem = (data) => {
        return (
            <div style={{display: 'flex', alignItems: 'center', padding: '5px', height: '25px'}}>
                <span>{data.text}</span>
            </div>
        )
    }
    const fcsGLBalOrgList = async () => {
        try {
            setLoader(true);
            const result = await fcsService.getAllFcsGLBalOrg('AP');
            setFcsGlBalOrg(result)
            const fcsApClass = await fcsService.getFcsChart('AP', 0, 0, 0, 0);
            setFcsApClass(fcsApClass);
            const fcsCustOrgn = await fcsService.getAllFcsCustOrgn();
            setFcsCustOrgn(fcsCustOrgn);
            const fcsMonth = await fcsService.getAllFcsMonth('AP');
            setFcsMonth(fcsMonth);
            return setLoader(false)
        } catch (e) {
            console.error(e);
        }
    }

    const selectedIdChange = async () => {
        try {
            setLoader(true);


            if (selectedId == 1 || selectedId == 3) {

                //     const fcsChart = await fcsService.getAllFcsChart();
                //     setFcsChart(fcsChart);
            }


            return setLoader(false)
        } catch (e) {
            console.error(e);
        }
    }
    const setGlBalOrgEffect = async (e) => {
        if (e.changes && e.changes.length !== 0) {
            if (e.changes[0].data.year === dataFcsMonth[0].year) {
                if (e.changes[0].type === "update") {
                    try {
                        let cust = _.filter(dataFcsCustOrgn, {orgcode: e.changes[0].data.orgcode})
                        e.changes[0].data.updated_user_id = user.id;
                        e.changes[0].data.orgname = cust[0].name;
                        e.changes[0].data.debit = 0;
                        e.changes[0].data.month = 0;
                        e.changes[0].data.jr_key = 'AP';
                        await fcsService.editFcsGLBalOrg(e.changes[0].data);
                        await fcsGLBalOrgList();
                        notify('Амжилттай хадгалагдлаа', "success", 2000);
                    } catch (e) {
                        notify('Засварлалтыг хадгалах явцад алдаа гарлаа', "error", 2000);
                    }
                } else if (e.changes[0].type === "insert") {
                    try {
                        delete e.changes[0].data.id;
                        let cust = _.filter(dataFcsCustOrgn, {orgcode: e.changes[0].data.orgcode})
                        // delete e.changes[0].data.updatedAt;
                        e.changes[0].data.created_user_id = user.id;
                        e.changes[0].data.org_id = user.org_id;
                        e.changes[0].data.orgname = cust[0].name;
                        e.changes[0].data.debit = 0;
                        e.changes[0].data.month = 0;
                        e.changes[0].data.jr_key = 'AP';
                        await fcsService.addFcsGLBalOrg(e.changes[0].data);
                        await fcsGLBalOrgList();
                        notify('Амжилттай үүслээ', "success", 2000);
                    } catch (e) {
                        notify('Шинээр үүсэх явцад алдаа гарлаа', "error", 2000);
                    }

                }
            } else {
                notify('Сонгосон жил тухайн тайлант жилд биш байна', "error", 2000);
            }
        }

    }


    const onEditorPreparing = (e) => {
        if (e.parentType === "dataRow" && e.dataField !== "password") {
            return;
        }
        if (e.row && e.row.isNewRow) {
            e.editorOptions.visible = true;
        }
        if (e.row && !e.row.isNewRow) {
            e.editorOptions.visible = false;
        }
    }
    const onInitNewRow = (e) => {
        e.data.unit = 1;
        e.data.vat = 0;
    }
    const calculateAmt = (newData, value, currentRowData) => {
        newData.qty = value;
        newData.amt = currentRowData.unit * value;
    }
    const setPurchase = async (e) => {
        if (e.changes && e.changes.length !== 0) {
            //yyyymm
            const date = new Date(e.changes[0].data.docdate);//new e.changes[0].data.docdate;
            const year = date.getFullYear() * 1e4; // 1e4 gives us the the other digits to be filled later, so 20210000.
            const month = (date.getMonth() + 1) * 100;
            const yyyymm = year + month // `+ ''` to convert to string from number, 202206
            const fcsCurrentMonth = dataFcsMonth.map((item) => {
                let date = item.year + '/' + item.month
                let datetimenew = new Date(date)
                let year = datetimenew.getFullYear() * 1e4; // 1e4 gives us the the other digits to be filled later, so 20210000.
                let month = (datetimenew.getMonth() + 1) * 100;
                const yyyymm_month = year + month
                return yyyymm_month
            });

            if (yyyymm === fcsCurrentMonth[0]) {
                if (e.changes[0].type === "update") {
                    try {
                        e.changes[0].data.updated_user_id = user.id;
                        e.changes[0].data.acct_name = _.filter(dataFcsChart, {acct: e.changes[0].data.acct})[0].descr;
                        e.changes[0].data.apacct_name = _.filter(dataFcsApClass, {acct: e.changes[0].data.apacct})[0].descr;
                        e.changes[0].data.custname = _.filter(dataFcsCustOrgn, {orgcode: e.changes[0].data.cust})[0].name;
                        await fcsService.editFcsAptran(e.changes[0].data);
                        await fcsAptranList();
                        notify('Амжилттай хадгалагдлаа', "success", 2000);
                    } catch (e) {
                        notify('Засварлалтыг хадгалах явцад алдаа гарлаа', "error", 2000);
                    }
                }

            } else {
                notify('Сонгосон огноо тухайн тайлант он сард биш байна', "error", 2000);
            }
        }

    }
    const popupHandler = () => {
        console.log("popup is", popup)
        setPopup(true)
    }
    const onExporting = (e) => {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Main sheet');

        exportDataGrid({
            component: e.component,
            worksheet,
            autoFilterEnabled: true,
        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer], {type: 'application/octet-stream'}), 'Purchase.xlsx');
            });
        });
        e.cancel = true;
    }
    const customAddRow = (grid) => {
        grid.current.instance.addRow();
    }
    const onCellPrepared = (e) => {
        let cell = e.cellElement
        if (e.rowType !== "header" && e.rowType !== "filter") {
            cell.style = "color: rgb(20, 23, 106); font-weight: 500; padding: .875rem 0; padding-left: 1rem"
        } else if (e.rowType === "header") {
            cell.style = `
            background-color: rgb(238, 240, 244); 
            border: none; padding-top: .875rem; 
            padding-left: 1rem; 
            color: rgb(20, 23, 106); 
            font-weight: 600; 
            font-size: 13px;
            margin-top: "2rem"
            `
        } else if (e.rowType === "filter") {
            cell.style = "background-color: rgb(238, 240, 244); padding: .5rem; border: none; margin-bottom: 1rem"
        }

    }
    return (
        <div>
            <LoadPanelComponent
                shadingColor="rgba(0,0,0,0.4)"
                position={{of: '#password'}}
                message=''
                showPane={false}
            />
            <Popup
                width={"75vw"}
                height={"90vh"}
                onHiding={() => setPopup(false)}
                resizeEnabled={true}
                dragEnabled={true}
                // hideOnOutsideClick
                showTitle={false}
                showCloseButton={false}
                visible={popup}
                className="BbEditPopup"
            >
                <ScrollView width='100%' height='100%'>
                    <DepositPopup
                        children={[<Purchase monthValue={monthValue} yearValue={yearValue}/>,
                            <ApSelect monthValue={monthValue}/>]}
                        setPopup={setPopup}
                        title={["Худалдан авалт", "Төлөлт"]}
                    />
                </ScrollView>
            </Popup>
            <div id={'firstBox'}>
                <div style={{display: "flex", background: "white", padding: "1rem .5rem", alignItems: "center"}}>
                    <div style={{display: 'flex', marginRight: 20}}>
                        <div style={{marginRight: 10, display: 'flex', alignItems: 'center'}}>
                            <span style={{color: "#A3A3A3", marginRight: 10}}>Тайлант он:</span>
                            <span style={{fontWeight: "700"}}>{yearValue}</span>
                        </div>
                    </div>
                    <div style={{display: 'flex', marginRight: 20}}>
                        <div style={{marginRight: 10, display: 'flex', alignItems: 'center'}}>
                            <span style={{color: "#A3A3A3", marginRight: 10}}>Тайлант сар:</span>
                            <span style={{fontWeight: "700"}}>{monthValue}</span>
                        </div>
                    </div>




                    <div style={{display: 'flex', marginRight: 10}}>
                        <button className='customAddButton' onClick={popupHandler}>
                            <HiPlus style={{fontSize: "1.2rem"}}/> Нэмэх
                        </button>
                    </div>


                    <div>
                        <button className='customSettingsButton'>
                            <FiSettings style={{fontSize: "1.2rem"}}/>
                        </button>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">

                        <div>
                            <LoadPanelComponent
                                shadingColor="rgba(0,0,0,0.4)"
                                position={userListPosition}
                                visible={loader}
                                message=''
                                showPane={false}
                            />
                            <div>
                                <div className='card'>
                                    <DataGrid
                                    noDataText='Дата байхгүй байна.'
                                        onEditorPreparing={onEditorPreparing}
                                        ref={myBabyRef}
                                        wordWrapEnabled={true}
                                        dataSource={dataSource}
                                        onSaved={setPurchase}
                                        className="datagridBbReport"
                                        keyExpr="id"
                                        onCellPrepared={onCellPrepared}
                                        onInitNewRow={onInitNewRow}
                                        allowColumnResizing={true}
                                        onExporting={onExporting}>
                                        <Toolbar>
                                            <Item location={"before"} cssClass="datagridToolbarDate"
                                                  text={`Худалдан авалт`}/>
                                            <Item location='before'
                                                  widget='dxButton'
                                                  options={{icon:'refresh'}}
                                                  cssClass="datagridExportButton"
                                                  onClick={listAptran}
                                            />

                                            <Item location={"after"} cssClass="datagridExportButton"
                                                  name="exportButton"/>
                                            <Item location={"after"} name="searchPanel"/>
                                        </Toolbar>
                                        <FilterRow
                                            visible={true}
                                        />
                                        <Pager
                                            showPageSizeSelector={true}
                                            // showInfo={true}
                                            showNavigationButtons={true}
                                        />
                                        <Column
                                            dataField="id"
                                            caption="Дугаар"
                                            allowEditing={false}
                                            // editCellTemplate = {}
                                            width="60"
                                            alignment="center"
                                            // cellRender = {customize}
                                            cssClass="custom"
                                            allowFiltering={true}
                                        >
                                        </Column>


                                        <Column
                                            dataField="agenda_id"
                                            caption="Төсөл хөтөлбөр"
                                            allowEditing={false}
                                            cssClass="custom"
                                            width="70"
                                            alignment="center"

                                            allowFiltering={true}
                                        >
                                        </Column>

                                        <Column
                                            dataField="event_id"
                                            caption="Зориулалт"
                                            width="75"
                                            alignment="center"
                                            allowEditing={false}
                                            cssClass="custom"
                                            allowFiltering={true}
                                        >
                                        </Column>

                                        <Column
                                            dataField="econ_category_id"
                                            caption="Э/З ангилал"
                                            allowEditing={false}
                                            width="60"
                                            alignment="center"
                                            cssClass="custom"
                                            allowFiltering={true}
                                        >
                                        </Column>
                                        <Column
                                            cssClass="custom"
                                            dataField="brn"
                                            width="75"
                                            alignment="center"
                                            caption="Баримтын дугаар"
                                            allowEditing={true}
                                            allowFiltering={true}
                                        >
                                            <RequiredRule
                                                message='Баримтын дугаар оруулна уу'
                                            />
                                        </Column>
                                        <Column
                                            cssClass="custom"
                                            dataField="docno"
                                            caption="журнал №"
                                            width="70"
                                            alignment="center"
                                            allowEditing={false}
                                            allowCollapsing={false}
                                            allowFiltering={true}
                                        >
                                        </Column>
                                        <Column
                                            cssClass="custom"
                                            dataField="acct"
                                            caption="Данс"
                                            minWidth="70"
                                            alignment="center"
                                            allowEditing={true}
                                            allowFiltering={true}
                                        >
                                            <RequiredRule
                                                message='Данс оруулна уу'
                                            />
                                            <Lookup
                                                valueExpr="acct"
                                                displayExpr={(item) => {
                                                    return item && `${item.acct} - ${item.descr}`
                                                }}
                                                dataSource={dataFcsChart}

                                            />
                                        </Column>
                                        <Column
                                            cssClass="custom"
                                            dataField="descr"
                                            caption="Тайлбар"
                                            allowEditing={true}
                                            width="70"
                                            alignment="center"
                                            allowFiltering={true}
                                        >
                                            <RequiredRule
                                                message='Тайлбар оруулна уу'
                                            />
                                        </Column>

                                        <Column
                                            cssClass="custom"
                                            dataField="docdate"
                                            caption="Огноо"
                                            allowEditing={true}
                                            width="70"
                                            alignment="center"
                                            dataType="date"
                                        >
                                            <RequiredRule
                                                message='Огноо оруулна уу'
                                            />
                                        </Column>

                                        <Column
                                            cssClass="custom"
                                            dataField="qty"
                                            caption="Тоо"
                                            allowEditing={true}
                                            width="70"
                                            alignment="center"
                                            allowFiltering={true}
                                            setCellValue={calculateAmt}
                                            format="#,##0.00"
                                        >
                                            <RequiredRule
                                                message='Тоо оруулна уу'
                                            />
                                            <PatternRule pattern={/^\d+$/}
                                                         message='зөвхөн эерэг тоо оруулна уу!'/>
                                        </Column>
                                        <Column
                                            cssClass="custom"
                                            dataField="unit"
                                            caption="Ханш"
                                            width="70"
                                            alignment="center"
                                            allowEditing={true}
                                            allowFiltering={true}
                                        >
                                            <RequiredRule
                                                message='Ханш оруулна уу'
                                            />
                                            <PatternRule pattern={/^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/}
                                                         message='зөвхөн тоо оруулна уу!'/>
                                        </Column>
                                        <Column
                                            cssClass="custom"
                                            dataField="amt"
                                            caption="Нийт дүн"
                                            minWidth="70"
                                            alignment="center"
                                            allowEditing={false}
                                            format="#,##0.00"
                                        >
                                        </Column>
                                        <Column
                                            cssClass="custom"
                                            dataField="vat"
                                            caption="Үүнээс НӨАТ"
                                            allowEditing={true}
                                            width="70"
                                            alignment="center"
                                            allowFiltering={true}
                                            format="#,##0.00"
                                        >
                                            <RequiredRule
                                                message='НӨАТ оруулна уу'
                                            />
                                            <PatternRule pattern={/^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/}
                                                         message='зөвхөн тоо оруулна уу!'/>
                                        </Column>
                                        <Column
                                            cssClass="custom"
                                            dataField="cust"
                                            width="80"
                                            alignment="center"
                                            caption="Харилцагч код"
                                            allowEditing={true}
                                            allowFiltering={true}
                                        >
                                            <RequiredRule
                                                message='харилцагч код уу'
                                            />
                                            <Lookup
                                                valueExpr="orgcode"
                                                displayExpr={(item) => {
                                                    return item && `${item.orgcode} - ${item.name}`
                                                }}
                                                dataSource={dataFcsCustOrgn}

                                            />
                                        </Column>
                                        <Column
                                            cssClass="custom"
                                            dataField="apacct"
                                            minWidth="70"
                                            alignment="center"
                                            caption="Өглөгийн ангилал"
                                            allowEditing={true}
                                        >
                                            <RequiredRule
                                                message='apACCT уу'
                                            />
                                            <Lookup
                                                valueExpr="acct"
                                                displayExpr={(item) => {
                                                    return item && `${item.acct} - ${item.descr}`
                                                }}
                                                dataSource={dataFcsApClass}

                                            />
                                        </Column>
                                        <Editing
                                            mode="popup"
                                            allowUpdating={true}
                                            allowDeleting={false}
                                            // allowAdding = {adding}
                                            useIcons={true}
                                            texts={{
                                                cancelAllChanges: 'Болих бүгд',
                                                cancelRowChanges: 'Болих',
                                                confirmDeleteMessage: 'Энэ худалдан авалт мэдээллийг устгах уу?',
                                                confirmDeleteTitle: 'Баталгаажуулах',
                                                deleteRow: 'Устгах',
                                                editRow: 'Өөрчлөх',
                                                saveAllChanges: 'Хадгалах бүгд',
                                                saveRowChanges: 'Хадгалах',
                                                undeleteRow: 'Буцаах',
                                                validationCancelChanges: 'Баталгаажуулах болих',
                                            }}
                                        >
                                            <Popup title="Худалдан авалт" showTitle={true} width={1000}
                                                   height={440}/>
                                            <Form>
                                                <Item itemType="group" colCount={2} colSpan={2}>

                                                    <Item dataField="brn"/>
                                                    <Item dataField="docno"/>
                                                    <Item dataField="acct"/>
                                                    <Item dataField="docdate"/>
                                                    <Item dataField="qty"/>
                                                    <Item dataField="unit"/>
                                                    <Item dataField="vat"/>
                                                    <Item dataField="amt"/>
                                                    <Item dataField="cust"/>
                                                    <Item dataField="apacct"/>
                                                    <Item dataField="descr"/>
                                                </Item>
                                            </Form>
                                        </Editing>
                                        <Paging enabled={true}/>
                                        <Export enabled={true} allowExportSelectedData={false}/>
                                    </DataGrid>
                                </div>
                            </div>
                        </div>


                        <EditApSelect/>

                        {selectedId === 3 &&
                            <div style={{marginLeft: 20}}>

                                <div>
                                    <div className="card-body">
                                        <DataGrid
                                        noDataText='Дата байхгүй байна.'
                                            onEditorPreparing={onEditorPreparing}
                                            ref={myBabyRef}
                                            dataSource={dataFcsGlBalOrg}
                                            onSaved={setGlBalOrgEffect}
                                            keyExpr={null}
                                            onInitNewRow={onInitNewRow}
                                            allowColumnResizing={true}
                                            onExporting={onExporting}>
                                           
                                            <FilterRow
                                                visible={true}
                                            />
                                            <Pager
                                                showPageSizeSelector={true}
                                                // showInfo={true}
                                                showNavigationButtons={true}
                                            />
                                            <Column
                                                dataField="id"
                                                caption="Дугаар"
                                                allowEditing={false}
                                                width={50}
                                                // editCellTemplate = {}
                                                // cellRender = {customize}
                                                cssClass="custom"
                                                allowFiltering={true}
                                            >
                                            </Column>
                                            <Column
                                                dataField="organization_model.name"
                                                caption="Байгууллага нэр"
                                                allowEditing={false}
                                                minWidth={150}
                                                cssClass="custom"
                                                allowFiltering={true}
                                            >
                                            </Column>
                                            <Column
                                                cssClass="custom"
                                                dataField="acct"
                                                caption="Өглөгийн данс"
                                                allowEditing={true}
                                                minWidth={120}
                                                allowFiltering={true}
                                            >
                                                <RequiredRule
                                                    message='Өглөгийн данс оруулна уу'
                                                />
                                                <Lookup
                                                    valueExpr="acct"
                                                    displayExpr={(item) => {
                                                        return item && `${item.acct} - ${item.descr}`
                                                    }}
                                                    dataSource={dataFcsApClass}

                                                />
                                            </Column>
                                            <Column
                                                cssClass="custom"
                                                dataField="year"
                                                caption="Жил"
                                                width={60}
                                                alignment="center"
                                                dataType="number"
                                                allowEditing={true}
                                                allowFiltering={true}
                                            >
                                                <RequiredRule
                                                    message='Жил оруулна уу'
                                                />
                                            </Column>
                                            <Column
                                                cssClass="custom"
                                                dataField="month"
                                                caption="Сар"
                                                width={60}
                                                alignment="center"
                                                dataType="number"
                                                allowEditing={false}
                                                allowFiltering={true}
                                            >
                                            </Column>
                                            <Column
                                                cssClass="custom"
                                                dataField="debit"
                                                width={65}
                                                alignment="center"
                                                caption="Дебиt дүн"
                                                dataType="number"
                                                allowEditing={false}
                                                allowFiltering={true}
                                            >

                                            </Column>
                                            <Column
                                                cssClass="custom"
                                                dataField="credit"
                                                caption="Кредит дүн"
                                                width={80}
                                                alignment="center"
                                                dataType="number"
                                                allowEditing={true}
                                                allowFiltering={true}
                                            >
                                                <RequiredRule
                                                    message='Кредит оруулна уу'
                                                />
                                            </Column>


                                            <Column
                                                cssClass="custom"
                                                dataField="orgcode"
                                                width={100}
                                                alignment="center"
                                                caption="Харилцагч"
                                                allowEditing={true}
                                                allowFiltering={true}
                                            >
                                                <RequiredRule
                                                    message='Харилцагч оруулна уу'
                                                />
                                                <Lookup
                                                    valueExpr="orgcode"
                                                    displayExpr={(item) => {
                                                        return item && `${item.orgcode} - ${item.name}`
                                                    }}
                                                    dataSource={dataFcsCustOrgn}

                                                />
                                            </Column>

                                            <Editing
                                                mode="form"
                                                allowUpdating={true}
                                                allowDeleting={false}
                                                allowAdding={true}
                                                useIcons={true}
                                                texts={{
                                                    cancelAllChanges: 'Болих бүгд',
                                                    cancelRowChanges: 'Болих',
                                                    confirmDeleteMessage: 'Энэ эхний үлдэгдэл устгах уу?',
                                                    confirmDeleteTitle: 'Баталгаажуулах',
                                                    deleteRow: 'Устгах',
                                                    editRow: 'Өөрчлөх',
                                                    saveAllChanges: 'Хадгалах бүгд',
                                                    saveRowChanges: 'Хадгалах',
                                                    undeleteRow: 'Буцаах',
                                                    validationCancelChanges: 'Баталгаажуулах болих',
                                                }}
                                            >
                                            </Editing>
                                            <Paging enabled={true}/>
                                            <Export enabled={true} allowExportSelectedData={false}/>
                                        </DataGrid>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>

                </div>
            </div>
        </div>
    )
}

export default EditPurchase;

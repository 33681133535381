import SelectBox from "devextreme-react/select-box";
import {getYears, nFormatter} from "../../util";
import React, {useState, useEffect} from "react";
import {CURRENT_YEAR} from "../../shared/constants";
import BudgetService from "../../services/api/budget";
import Chart, {
    CommonSeriesSettings,
    Crosshair,
    Font,
    Label,
    Legend,
    SeriesTemplate
} from "devextreme-react/chart";
import {Connector} from "devextreme-react/pie-chart";
import LoadPanelComponent from "./components/loadPanel";
import Empty from "./components/empty";

function Econ(props) {
    const [year, setYear] = useState(CURRENT_YEAR);
    const [workerCount, setWorkerCount] = useState([]);
    const [pensionCount, setPensionCount] = useState([]);
    const [workerCountByYear, setWorkerCountByYear] = useState([]);
    const [pensionCountByYear, setPensionCountByYear] = useState([]);

    const [loading1, setLoading1] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [loading3, setLoading3] = useState(false);
    const [loading4, setLoading4] = useState(false);

    useEffect(() => {
        setWorkerCount([]);
        setPensionCount([]);
        setWorkerCountByYear([]);
        setPensionCountByYear([]);

        setLoading1(true);
        setLoading2(true);
        setLoading3(true);
        setLoading4(true);

        BudgetService.econOtherInfo(year, 42).then((data) => {
            let arr = data.data || [];
            arr.forEach((v, i) => {
                v.count = parseInt(v.count);
            })
            setWorkerCount(arr);
            setLoading1(false);
        }).catch((err) => {
            console.log(err);
            setWorkerCount([]);
            setLoading1(false);
        });

        BudgetService.econOtherInfo(year, 46).then((data) => {
            let arr = data.data || [];
            arr.forEach((v, i) => {
                v.count = parseInt(v.count);
            })
            setPensionCount(arr);
            setLoading2(false);
        }).catch((err) => {
            console.log(err);
            setPensionCount([]);
            setLoading2(false);
        });

        BudgetService.econOtherInfoByYear( 42).then((data) => {
            let arr = data.data || [];
            arr.forEach((v, i) => {
                v.budget_year = v.budget_year+'';
                v.count = parseInt(v.count);
            })
            setWorkerCountByYear(arr);
            setLoading3(false);
            // console.log("workerCountByYear end irne",arr)
        }).catch((err) => {
            console.log(err);
            setWorkerCountByYear([]);
            setLoading3(false);
        });


        BudgetService.econOtherInfoByYear( 46).then((data) => {
            let arr = data.data || [];
            arr.forEach((v, i) => {
                v.budget_year = v.budget_year+'';
                v.count = parseInt(v.count);
            })
            setPensionCountByYear(arr);
            setLoading4(false);
        }).catch((err) => {
            console.log(err);
            setPensionCountByYear([]);
            setLoading4(false);
        });
    }, [year]);

    return <>
        <LoadPanelComponent
            position = {{of: '#firstBox'}}
            loadPanelVisible = {loading1}
            shading = {true}
            showPane={false}
        />

        <LoadPanelComponent
            position = {{of: '#secondBox'}}
            loadPanelVisible = {loading2}
            shading = {true}
            showPane={false}
        />

        <LoadPanelComponent
            position = {{of: '#thirdBox'}}
            loadPanelVisible = {loading3}
            shading = {true}
            showPane={false}
        />

        <LoadPanelComponent
            position = {{of: '#fourthBox'}}
            loadPanelVisible = {loading4}
            shading = {true}
            showPane={false}
        />

        <div className={'card'} style={{padding: 10, marginBottom: 10}}>
            <div style={{display: 'flex'}}>
                <div style={{display: 'flex', alignItems: 'center', padding: '0 10px 0 10px'}}>Төсвийн жил:</div>
                <div style={{width: 100}}>
                    <SelectBox
                        items={getYears(true)}
                        defaultValue={year}
                        onValueChanged={(e) => setYear(e.value)}
                        placeholder="сонгох"/>
                </div>
            </div>
        </div>
        <div className={'row'}>
            <div className={'col-6'}>

                <div className={'card'}>
                    <div className={'card-header'}>
                        <span>Ажиллагсдын тоо</span>
                    </div>
                    <div className={'card-body'}>
                        <div id={'firstBox'}>
                            {workerCount.length === 0 ?
                                <Empty color={''} height={330}/>
                                :
                                <Chart
                                    palette = 'Soft Pastel'
                                    dataSource = {workerCount}
                                    // customizePoint = {customizePoint}
                                    style={{width: '100%', height: 330}}
                                >

                                    <CommonSeriesSettings
                                        argumentField={'org_name'}
                                        valueField={'count'}
                                        type={'bar'}
                                        minBarSize={20}
                                        ignoreEmptyPoints={true}
                                    >
                                        <Label
                                            visible={true}
                                            rotationAngle={-90}
                                            font={{
                                                size: 10
                                            }}
                                            format={nFormatter}
                                        >
                                            <Connector visible={true} />
                                        </Label>
                                    </CommonSeriesSettings>

                                    <SeriesTemplate
                                        nameField={'org_name'}/>

                                    <Crosshair
                                        enabled={true}
                                        color="#949494"
                                        width={3}
                                        dashStyle="dot"
                                    >
                                        <Label
                                            visible={true}
                                            backgroundColor="#949494"
                                        >
                                            <Font
                                                color="#fff"
                                                size={12}
                                            />
                                        </Label>
                                    </Crosshair>

                                    <Legend visible = {false}/>
                                </Chart>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className={'col-6'}>
                <div className={'card'}>
                    <div className={'card-header'}>
                        <span>Нийт тэтгэвэрт гарагч</span>
                    </div>
                    <div className={'card-body'}>
                        <div id={'secondBox'}>
                            {pensionCount.length === 0 ?
                                <Empty color={''} height={330}/>
                                :
                                <Chart
                                    palette = 'Soft Pastel'
                                    dataSource = {pensionCount}
                                    // customizePoint = {customizePoint}
                                    style={{width: '100%', height: 330}}
                                >

                                    <CommonSeriesSettings
                                        argumentField={'org_name'}
                                        valueField={'count'}
                                        type={'bar'}
                                        minBarSize={20}
                                        ignoreEmptyPoints={true}
                                    >
                                        <Label
                                            visible={true}
                                            rotationAngle={-90}
                                            font={{
                                                size: 10
                                            }}
                                            format={nFormatter}
                                        >
                                            <Connector visible={true} />
                                        </Label>
                                    </CommonSeriesSettings>

                                    <SeriesTemplate
                                        nameField={'org_name'}/>

                                    <Crosshair
                                        enabled={true}
                                        color="#949494"
                                        width={3}
                                        dashStyle="dot"
                                    >
                                        <Label
                                            visible={true}
                                            backgroundColor="#949494"
                                        >
                                            <Font
                                                color="#fff"
                                                size={12}
                                            />
                                        </Label>
                                    </Crosshair>

                                    <Legend visible = {false}/>
                                </Chart>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className={'col-6'}>
                <div className={'card'}>
                    <div className={'card-header'}>
                        <span>Ажиллагсдын тоо (Жилээр)</span>
                    </div>
                    <div className={'card-body'}>
                        <div id={'thirdBox'}>
                            {workerCountByYear.length === 0 ?
                                <Empty color={''} height={330}/>
                                :
                                <Chart
                                    palette = 'Soft Pastel'
                                    dataSource = {workerCountByYear}
                                    // customizePoint = {customizePoint}
                                    style={{width: '100%', height: 330}}
                                >

                                    <CommonSeriesSettings
                                        argumentField={'budget_year'}
                                        valueField={'count'}
                                        type={'bar'}
                                        minBarSize={20}
                                        ignoreEmptyPoints={true}
                                    >
                                        <Label
                                            visible={true}
                                            rotationAngle={-90}
                                            font={{
                                                size: 10
                                            }}
                                            format={nFormatter}
                                        >
                                            <Connector visible={true} />
                                        </Label>
                                    </CommonSeriesSettings>

                                    <SeriesTemplate
                                        nameField={'budget_year'}/>

                                    <Crosshair
                                        enabled={true}
                                        color="#949494"
                                        width={3}
                                        dashStyle="dot"
                                    >
                                        <Label
                                            visible={true}
                                            backgroundColor="#949494"
                                        >
                                            <Font
                                                color="#fff"
                                                size={12}
                                            />
                                        </Label>
                                    </Crosshair>

                                    <Legend visible = {false}/>
                                </Chart>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className={'col-6'}>
                <div className={'card'}>
                    <div className={'card-header'}>
                        <span>Нийт тэтгэвэрт гарагч (Жилээр)</span>
                    </div>
                    <div className={'card-body'}>
                        <div id={'fourthBox'}>
                            {pensionCountByYear.length === 0 ?
                                <Empty color={''} height={330}/>
                                :
                                <Chart
                                    palette = 'Soft Pastel'
                                    dataSource = {pensionCountByYear}
                                    // customizePoint = {customizePoint}
                                    style={{width: '100%', height: 330}}
                                >

                                    <CommonSeriesSettings
                                        argumentField={'budget_year'}
                                        valueField={'count'}
                                        type={'bar'}
                                        minBarSize={20}
                                        ignoreEmptyPoints={true}
                                    >
                                        <Label
                                            visible={true}
                                            rotationAngle={-90}
                                            font={{
                                                size: 10
                                            }}
                                            format={nFormatter}
                                        >
                                            <Connector visible={true} />
                                        </Label>
                                    </CommonSeriesSettings>

                                    <SeriesTemplate
                                        nameField={'budget_year'}/>

                                    <Crosshair
                                        enabled={true}
                                        color="#949494"
                                        width={3}
                                        dashStyle="dot"
                                    >
                                        <Label
                                            visible={true}
                                            backgroundColor="#949494"
                                        >
                                            <Font
                                                color="#fff"
                                                size={12}
                                            />
                                        </Label>
                                    </Crosshair>

                                    <Legend visible = {false}/>
                                </Chart>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>;
}

export default Econ;
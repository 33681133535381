import React from 'react';
import { SelectBox } from 'devextreme-react/select-box';

export default class StructureBoxComponent extends React.Component {
    constructor(props) {
        super(props);
        this.onValueChanged = this.onValueChanged.bind(this);
        this.onSelectionChanged = this.onSelectionChanged.bind(this);
    }

    onValueChanged(e) {
        this.props.data.setValue(e.value);
    }

    onSelectionChanged() {
        this.props.data.component.updateDimensions();
    }

    render() {
        return <SelectBox
            dataSource={this.props.data.column.lookup.dataSource}
            defaultValue={this.props.data.value}
            valueExpr="id"
            displayExpr="sur_name"
            searchEnabled={true}
            onValueChanged={this.onValueChanged}
            onSelectionChanged={this.onSelectionChanged}
            searchMode="contains"
            searchExpr="sur_name"
        />
    }
}

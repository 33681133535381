import React, { useState, useEffect } from "react";
import BudgetService from "../../../../services/api/budget";
import notify from "devextreme/ui/notify";
import { budgetPalettes, getStripValues, numberWithCommas } from "../../../../util";
import _ from "lodash";
import { ArgumentAxis, Chart, CommonSeriesSettings, Crosshair, HorizontalLine, ScrollBar, Strip, ValueAxis, ZoomAndPan } from "devextreme-react/chart";
import { Export, Label, Legend, Series, Tooltip } from "devextreme-react/pie-chart";
import LoadPanelComponent from "../../../budget/components/loadPanel";
import EmptyData from "../../../budget/components/empty";

const EhUusverJileer = (props) => {
    const [chartData, setChartData] = useState([]);
    const [loader, setLoader] = useState(true);

    const loadChartData = async () => {
        try {
            let r = await BudgetService.getEhUusverTenYears(props.chosenValue, props.chosenMinistry ? props.chosenMinistry : "ALL");
            setChartData(r);
        } catch (e) {
            notify(e.message, "error", 2000);
        } finally {
            setLoader(false)
        }
    }

    useEffect(() => {
        loadChartData();
    }, [props.chosenValue, props.chosenMinistry]);

    const renderLabel = (e) => {
        return `${e.percentText}`
    }
    const clickHandler = (e) => {
        const series = e.target;
        if (series.isVisible()) {
            series.hide();
        } else {
            series.show();
        }
    }
    const customizeTooltip = (arg) => {
        if (arg.seriesName === 'Төсвийн өөрчлөлт' || arg.seriesName === 'Төсөв') {
            let r = _.find(chartData, { 'budget_year': (arg.argument * 1 - 1).toString() });
            let q = ((arg.value - (r && r.todotgoson) > 0 ? arg.value / (r && r.todotgoson) : (r && r.todotgoson) / arg.value)).toFixed(2);
            let p = ((arg.value * 100 / (r && r.todotgoson)) - 100).toFixed(2);
            let d = (arg.value - (r && r.todotgoson));
            let f = d < 0 ? 'бага' : 'их';

            if (isFinite(q) && !isNaN(q)) {
                return {
                    html: `<div class="tooltip-header">
                    <span>Төсөв ${arg.argumentText} он:</span>
                    <span>${numberWithCommas(arg.value / 1000000)} </span>
                    </div>
                    <hr/>
                    <b><i>Өмнөх жилээс</i></b><br/>
                    <span class="tooltip-series-name">Зөрүү:</span> ${numberWithCommas(d / 1000000)}<br/> 
                    <span class="tooltip-series-name">Хувь:</span> ${p}%<br/> 
                    <span class="tooltip-series-name">Өөрчлөлт:</span> ${q} дахин ${f}`,
                }
            } else {
                return {
                    html: `<div class="tooltip-header">
                    <span>Төсөв ${arg.argumentText} он:</span>
                    <span>${numberWithCommas(arg.value / 1000000)} </span>
                    </div>`,
                }
            }
        }
        else if (arg.seriesName === 'Гүйцэтгэлийн өөрчлөлт') {
            let r = _.find(chartData, { 'budget_year': (arg.argument * 1 - 1).toString() });
            let q = ((arg.value - (r && r.guitsetgel) > 0 ? arg.value / (r && r.guitsetgel) : (r && r.guitsetgel) / arg.value)).toFixed(2);
            let p = ((arg.value * 100 / (r && r.guitsetgel)) - 100).toFixed(2);
            let d = (arg.value - (r && r.guitsetgel));
            let f = d < 0 ? 'бага' : 'их';
            let r1 = _.find(chartData, { 'budget_year': (arg.argument * 1).toString() });
            let u = ((r1 && r1.todotgoson) - arg.value);
            let u1 = ((arg.value * 100 / (r1 && r1.todotgoson))).toFixed(2);
            if (isFinite(q) && !isNaN(q)) {
                return {
                    html: `<div class="tooltip-header">
                    <span>Гүйцэтгэл ${arg.argumentText} он:</span>
                    <span>${numberWithCommas(arg.value / 1000000)}</span>
                    </div>
                    <hr/>
                    <b><i>Төсвөөс </b></i><br/>
                    <span class="tooltip-series-name">Зөрүү: </span>${numberWithCommas(u / 1000000)} <br/>
                    <span class="tooltip-series-name" style={{marginBottom: 10}}>Xувь: </span>${u1}% <br/>
                    <hr/>
                    <b><i>Өмнөх жилээс </b></i><br/>
                    <span class="tooltip-series-name">Зөрүү: </span>${numberWithCommas(d / 1000000)} <br/>
                    <span class="tooltip-series-name">Хувь: </span>${p}% <br/>
                    <span class="tooltip-series-name">Өөрчлөлт: </span>${q} дахин ${f}<br/> `,
                }
            } else {
                return {
                    html: `<div class="tooltip-header">
          <span>Гүйцэтгэл ${arg.argumentText} он:</span>
          <span>${numberWithCommas(arg.value / 1000000)}</span>
          </div>
          <hr/>
          <b><i>Төсвөөс </b></i><br/>
          <span class="tooltip-series-name">Зөрүү: </span>${numberWithCommas(u / 1000000)} <br/>
          <span class="tooltip-series-name" style={{marginBottom: 10}}>Xувь: </span>${u1}% <br/>`,
                }
            }
        } else if (arg.seriesName === "Гүйцэтгэл") {
            return {
                html: `<div class="tooltip-header">
              <span>Гүйцэтгэл ${arg.argumentText} он:</span>
              <span>${numberWithCommas(arg.value / 1000000)}</span>
              </div>
              <hr/>
              <b><i>Төсвөөс </b></i><br/>
              <span class="tooltip-series-name">Зөрүү: </span> ${numberWithCommas((arg.point.data.todotgoson - arg.value) / 1000000)}<br/> 
              <span class="tooltip-series-name">Хувь:</span> ${((arg.value / 1000000) / (arg.point.data.todotgoson / 1000000) * 100).toFixed(2)}%`
            }
        }
    }

    const customizeValueAxisLabel = (e) => {
        return `${e.value / 1000000000}`
    }
    if (chartData?.length === 0 && !loader) {
        return (<div style={{ display: "flex", justifyContent: "center", alignItems: "center", family: "Segoe UI" }}>
            <EmptyData />
        </div>)
    }

    function markerTemplate(item) {
        const color = item.series.isVisible() ? item.marker.fill : '#888';
        return (
            <svg>
                <rect x={0} y={0} width={12} height={12} fill={color}
                    rx={item.text.includes('өөрчлөлт') ? 6 : 0}
                    ry={item.text.includes('өөрчлөлт') ? 6 : 0}></rect>
            </svg>
        );
    }

    return (
        <div id="load5">
            <LoadPanelComponent position={{ of: '#load5' }} visible={loader} />
            <Chart onSeriesClick={clickHandler} dataSource={chartData} palette={_.find(budgetPalettes, { 'id': 4 }).color} paletteExtensionMode="alternate">
                <CommonSeriesSettings
                    argumentField="budget_year"
                    type="bar"
                    barOverlapGroup="ett"
                    cornerRadius={6}
                >
                    <Label backgroundColor="none" visible={false} customizeText={renderLabel} font={{ family: "Segoe UI" }} />
                </CommonSeriesSettings>
                <Crosshair enabled={true}>
                    <HorizontalLine visible={false} />
                    <Label visible={true} />
                </Crosshair>
                <ValueAxis>
                    <Label customizeText={customizeValueAxisLabel} />
                </ValueAxis>
                <ArgumentAxis tickInterval={1} argumentType='numeric' visualRange={{ endValue: new Date().getFullYear() }}>
                    {getStripValues().map((e) => (
                        <Strip startValue={e.startValue} endValue={e.endValue} color="#f5f5f5" />
                    ))}
                    <Label format='#' argumentFormat='format' />
                </ArgumentAxis>
                <ScrollBar visible={true} width={5} opacity={0.5} />
                <ZoomAndPan argumentAxis="both" />
                <Series stack="ett" valueField="todotgoson" name="Төсөв" />
                <Series stack="ett" valueField="guitsetgel" name="Гүйцэтгэл" barPadding="0.4" />
                <Series valueField="todotgoson" name="Төсвийн өөрчлөлт" visible={false} type="spline" dashStyle="solid" />
                <Series valueField="guitsetgel" name="Гүйцэтгэлийн өөрчлөлт" color="#264B96" type="spline" dashStyle="solid" />
                <Legend visible="true" verticalAlignment="bottom" horizontalAlignment="center" font={{ family: "Segoe UI" }} markerRender={markerTemplate} />
                <Tooltip
                    zIndex={100000}
                    enabled={true}
                    cornerRadius={6}
                    border={{ visible: false }}
                    customizeTooltip={customizeTooltip}
                    font={{ color: '#fff' }}
                    color="#555"
                    location="edge"
                >
                </Tooltip>
            </Chart>
        </div>
    )
}

export default EhUusverJileer;

import React, {useContext, useEffect, useState, useRef} from 'react';
import 'devextreme-react/text-area';
import DataGrid, {
    Editing,
    Pager,
    Paging,
    Column,
    FilterRow,
    RequiredRule,
    Export, Form, Item,  GroupPanel, SearchPanel, Grouping, Summary, GroupItem, TotalItem
} from 'devextreme-react/data-grid';
import {Popup} from "devextreme-react/popup";
import {Workbook} from 'exceljs';
import {saveAs} from 'file-saver-es';
import {exportDataGrid} from 'devextreme/excel_exporter';
import {LoadPanel} from "devextreme-react/load-panel";
import {UserContext} from "../../../../hooks/UserContext";
import fcsService from "../../../../services/api/fcs";
import Button from "devextreme-react/button";
import notify from "devextreme/ui/notify";
import {Template} from "devextreme-react";
import ScrollView from "devextreme-react/scroll-view";
import Fa03 from "../report/fa03";
import Fa04 from "../report/fa04";
import {Selection} from "devextreme-react/tree-list";
import LoadPanelComponent from '../../../../components/LoadPanelComponent';

function FaZarEdit(props) {

    const yearValue = props.yearValue || -1;
    const monthValue = props.monthValue || -1;
    const countFaZar = props.countFaZar ;
    const [reportShow,setReportShow]=useState(false)
    const userListPosition = {of: '#usersList'}
    const {user} = useContext(UserContext);
    const [reportData,setReportData]=useState()
    const [dataSource, setDataSource] = useState([]);
    const [loader, setLoader] = useState(false);
    const myBabyRef = useRef(null);
    useEffect(() => {
        fcsList();
    }, [yearValue, monthValue, countFaZar])


    const fcsList = async () => {
        try {
            setLoader(true);
            const result = await fcsService.getFcsFaZarReport(yearValue, monthValue);
            setDataSource(result)
            return setLoader(false)
        } catch (e) {
            console.error(e);
        }
    }
    function formatDate(date) {
        let d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }

    const   diff = (rowData) =>  {
        return rowData.quantity* rowData.cost_org+rowData.cost_cap;
    }
    const customizeDate = (data) => {
        return Number(parseFloat(data.value).toFixed(2)).toLocaleString("en", {
            minimumFractionDigits: 2,
        });
    }
    const scheduleTransferDetailBtn = (options) => {
        return (
            <Button
                type="default"
                icon='save'
                className="saveButton" onClick={()=>printFa(options.data) }>Харах</Button>
        )
    }
    const printFa=(data)   =>{

        setReportShow(true)
        if (data)

            setReportData(data)
        setReportShow(true)

    }

    const setEffect = async (e) => {
        if (e.changes && e.changes.length !== 0) {


            if (e.changes[0].type === "update") {
                try {
                    e.changes[0].data.updated_user_id = user.id;
                 //   await fcsService.editFcsMonth(e.changes[0].data);
                    await fcsList();
                    notify('Амжилттай хадгалагдлаа', "success", 2000);
                } catch (e) {
                    notify('Засварлалтыг хадгалах явцад алдаа гарлаа', "error", 2000);
                }
            } else if (e.changes[0].type === "insert") {
                try {
                    delete e.changes[0].data.id;
                    e.changes[0].data.created_user_id = user.id;
                    e.changes[0].data.org_id = user.org_id;
                  //  await fcsService.addFcsMonth(e.changes[0].data);
                    await fcsList();
                    notify('Амжилттай үүслээ', "success", 2000);
                } catch (e) {
                    notify('Шинээр үүсэх явцад алдаа гарлаа', "error", 2000);
                }

            } else if (e.changes[0].type === "remove") {
                try {
                    await fcsService.removeFcsFaZar(e.changes[0].key.id);
                    await fcsList();
                    notify('Амжилттай устгагдлаа', "success", 2000);
                } catch (e) {
                    notify('Устгах явцад алдаа гарлаа', "error", 2000);
                }

            }

        }

    }
    const onExporting = (e) => {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Main sheet');

        exportDataGrid({
            component: e.component,
            worksheet,
            autoFilterEnabled: true,
        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer], {type: 'application/octet-stream'}), 'FaZar' + yearValue + monthValue + '.xlsx');
            });
        });
        e.cancel = true;
    }
    return (
        <div>
            <LoadPanelComponent
                shadingColor="rgba(0,0,0,0.4)"
                position={{of: '#password'}}
                showPane={false}
                message=''
            />
            <div className="col-12 row" style={{justifyContent: "space-between", gap: '2rem', padding: '5px 15px'}}>
                <span>
                    Данснаас хассан үндсэн хөрөнгө
                </span>

            </div>
            <LoadPanelComponent
                shadingColor="rgba(0,0,0,0.4)"
                position={userListPosition}
                showPane={false}
                visible={loader}
                message=''
            />
            <div>
                <Popup
                    width={500}
                    height={800}
                    minWidth={'80%'}
                    maxWidth={'100%'}
                    minHeight={'100%'}
                    onHiding={() => setReportShow(false)}
                    resizeEnabled={true}
                    dragEnabled={true}
                    showTitle={true}
                    hideOnOutsideClick={true}
                    visible={reportShow}

                    showCloseButton={true}

                    title="дэлгэрэнгүй" >
                    <ScrollView width='100%' height='100%'>
                        <div>


                            {reportData &&

                                <div>
                                    {reportData.trtype==0 &&
                              <Fa03 formatDate= {formatDate} setReportShow={setReportShow} reportData={reportData} orgName={reportData.organization_model.name}
                                /> }

                                    {reportData.trtype==1 &&
                                <Fa04 formatDate= {formatDate} setReportShow={setReportShow} reportData={reportData}  orgName={reportData.organization_model.name}
                                />}
                                </div>




                             }


                        </div>
                    </ScrollView>
                </Popup>


                <div className="card-body">
                    <DataGrid
                        columnAutoWidth={true}
                        ref={myBabyRef}
                        dataSource={dataSource}
                        onSaved={setEffect}
                        keyExpr={null}
                        allowColumnResizing={true}
                        onExporting={onExporting}>

                        >

                        <GroupPanel visible={true}/>
                        <SearchPanel visible={true}/>
                        <Selection mode="single"/>
                        <Grouping autoExpandAll={true}/>
                        <FilterRow
                            visible={true}
                        />
                        <Pager
                            showPageSizeSelector={true}
                            // showInfo={true}
                            showNavigationButtons={true}
                        />



                        <Column
                            cssClass="custom"
                            dataField="trdescr"
                            caption="гүйлгээний төрөл"

                            allowEditing={true}
                            allowFiltering={true}
                            showInGroupFooter={true}
                        >

                        </Column>

                        <Column
                            cssClass="custom"
                            dataField="trtype"
                            caption="гүйлгээний код"
                            groupIndex={0}
                            allowEditing={true}
                            allowFiltering={true}
                            showInGroupFooter={true}
                        >

                        </Column>

                        <Column
                            cssClass="custom"
                            dataField="docnum"
                            caption="Падаан №"

                            allowEditing={true}
                            allowFiltering={true}
                            showInGroupFooter={true}
                        >

                        </Column>
                        <Column
                            cssClass="custom"
                            dataField="descr"
                            caption="Гүйлгээний утга"
                                                   allowEditing={true}
                            allowFiltering={true}
                        >

                        </Column>
                        <Column
                            cssClass="custom"
                            dataField="docdate"
                            caption="Огноо"
                            allowEditing={true}
                            allowFiltering={true}
                        >

                        </Column>



                        <Column
                            cssClass="custom"
                            dataField="date_acq"
                            dataType="date"
                            caption="Ашиглалтанд авсан"
                            allowEditing={true}
                            allowFiltering={true}
                        >

                        </Column>
                        <Column
                            cssClass="custom"
                            dataField="location"
                            caption="Байршил"
                            allowEditing={true}
                            allowFiltering={true}
                        >

                        </Column>


                        <Column
                            cssClass="custom"
                            dataField="manuf"
                            caption="Үйлдвэрлэгч"
                            allowEditing={true}
                            allowFiltering={true}
                        >

                        </Column>


                        <Column
                            cssClass="custom"
                            dataField="fa_name"
                            caption="хөрөнгийн нэр"
                            allowEditing={true}

                        >

                        </Column>
                        <Column
                            cssClass="custom"
                            dataField="fanumber"
                            caption="дугаар"
                            allowEditing={true}
                            allowFiltering={true}
                        >

                        </Column>

                        <Column
                            cssClass="custom"
                            dataField="unit_meas"
                            caption="х/н"
                            allowFiltering={true}
                        >
                        </Column>



                        <Column
                            cssClass="custom"
                            dataField="quantity"
                            caption="Тоо"
                            allowFiltering={true}
                        >
                        </Column>


                        <Column
                            cssClass="custom"
                            dataField="cost_org"
                            caption="Нэгж үнэ"
                            format="#,##0.00"
                            allowFiltering={true}
                        >
                        </Column>

                        <Column
                            cssClass="custom"
                            dataField="cost_cap"
                            caption="Хорогдуулах үнэ"
                            format="#,##0.00"
                            allowFiltering={true}
                        >
                        </Column>


                        <Column name={"tot"} calculateDisplayValue={diff}
                                caption="Нийт үнэ" width={80}
                                format="#,##0.00"
                                calculateCellValue={diff}
                        />



                        <Column
                            cssClass="custom"
                            dataField="acct_code"
                            caption="хөрөнгийн данс"
                            allowFiltering={true}
                        >
                        </Column>




                        <Column
                            cssClass="custom"
                            dataField="acder_amt"
                            caption="элэгдэл"
                            allowFiltering={true}
                        >
                        </Column>

                        <Column
                            cssClass="custom"
                            dataField="acder_acct"
                            caption="Элэгдлийн данс"
                            allowFiltering={true}
                        >
                        </Column>
                        <Column
                            cssClass="custom"
                            dataField="custname"
                            caption="Худалдан авагч"
                            allowFiltering={true}
                        >
                        </Column>
                        <Column
                            cssClass="custom"
                            dataField="cust"
                            caption="код"
                            allowFiltering={true}
                        >
                        </Column>

                        <Column
                            cssClass="custom"
                            dataField="dt_acct"
                            caption="дебит данс"
                            allowFiltering={true}
                        >
                        </Column>

                        <Column
                            cssClass="custom"
                            dataField="user_dept"
                            minWidth={80}
                            alignment="center"
                            caption="алба хэлтэс тасаг"
                            allowFiltering={true}
                        >
                        </Column>

                        <Column
                            cssClass="custom"
                            dataField="typename"
                            minWidth={80}
                            alignment="center"
                            caption="төрөл "
                            allowFiltering={true}
                        >
                        </Column>

                        <Column
                            cssClass="custom"
                            dataField="fatype"
                            minWidth={80}
                            alignment="center"
                            caption="төрөл код"
                            allowFiltering={true}
                        >
                        </Column>


                        <Column alignment="center" caption="" cellTemplate="cc156" width={70}/>
                        <Template name="cc156" render={scheduleTransferDetailBtn}/>
                        <Editing
                            mode="form"
                            allowDeleting={countFaZar<9998}
                            // allowAdding = {adding}
                            useIcons={true}
                            texts={{
                                cancelAllChanges: 'Болих бүгд',
                                cancelRowChanges: 'Болих',
                                confirmDeleteMessage: 'Энэ журнал мэдээллийг устгах уу?',
                                confirmDeleteTitle: 'Баталгаажуулах',
                                deleteRow: 'Устгах',
                                editRow: 'Өөрчлөх',
                                saveAllChanges: 'Хадгалах бүгд',
                                saveRowChanges: 'Хадгалах',
                                undeleteRow: 'Буцаах',
                                validationCancelChanges: 'Баталгаажуулах болих',
                            }}
                        >
                        </Editing>

                        <Summary>
                            <GroupItem
                                column="tot"
                                summaryType="sum"
                                customizeText={customizeDate}
                                showInGroupFooter={true}/>

                            <GroupItem
                                column="acder_amt"
                                summaryType="sum"
                                customizeText={customizeDate}
                                showInGroupFooter={true}/>
                            <TotalItem
                                column="acder_amt"
                                summaryType="sum"
                                customizeText={customizeDate}/>
                            <TotalItem
                                column="tot"
                                summaryType="sum"
                                customizeText={customizeDate}/>

                        </Summary>

                        <Paging enabled={true}/>



                        <Export enabled={true} allowExportSelectedData={false}/>
                    </DataGrid>
                </div>
            </div>
        </div>
    )
}

export default FaZarEdit;

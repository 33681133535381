import React, {useEffect, useState} from "react"
import ga4Services from "../../../services/api/googleAnalytics"
import {DataGrid} from "devextreme-react"
import {Column} from "devextreme-react/data-grid"
const numberWithCommas = (x) => {
  try {
    return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
  } catch (err) {
    throw err
  }
}
function GaEvents() {
    const [eventValues, setEventValues] = useState([])

  /*const cstmText = (e) => {
    return `<span style="color: #f7f7fa">${numberWithCommas(e.value)}<br/>${(
      e.percent * 100
    ).toFixed(2)}%</span>`
  }*/
  useEffect(()=>{
    fetchEventValues()
  }, [])
  const fetchEventValues = async ()=>{
    try {
      const {data} = await ga4Services.getEventValues();

      const orderedData = data.rows
      .map((item) => {
        return {
          name: item.dimensionValues[0].value,
          value: parseInt(item.metricValues[0].value),
        }
      })
      .sort((a, b) => b.value - a.value)
      setEventValues(orderedData)
    } catch (error) {
      console.log("error", error)
    }
  }
  return (
<div className="card">
            <div className="card-header">
              <span
                style={{
                  fontFamily: "'Montserrat', sans-serif",
                  fontWeight: "bold",
                }}
              >
                2. Хэрэглэгчдийн үйлдлүүд
              </span>
            </div>
            <div className="card-body" style={{paddingBottom: "1.6rem"}}>
              <DataGrid dataSource={eventValues} rowAlternationEnabled>
                <Column dataField={"name"} caption={"Талбарууд"} />
                <Column dataField={"value"} caption={"Тоо хэмжээ"} />
              </DataGrid>
            </div>
          </div>
  )
}

export default GaEvents

import React, {useContext, useEffect, useRef, useState, useCallback, useMemo} from "react";
// import './document.css'
import ReactToPrint, {PrintContextConsumer} from "react-to-print";
import OrganizationService from "../../services/api/organization";
import { UserContext } from "../../hooks/UserContext";
import { Button, Popup, TextArea, TextBox } from "devextreme-react";
import { useReactToPrint } from "react-to-print";
import ProposalService from "../../services/api/proposal"
import Html2Pdf from "html2pdf.js";
import HtmlEditor, {
    Toolbar, MediaResizing, ImageUpload, Item,
  } from 'devextreme-react/html-editor';

import A4 from './templates/a4'
import ScrollView from "devextreme-react/scroll-view";

  const sizeValues = ['8pt', '10pt', '12pt', '14pt', '18pt', '24pt', '36pt'];
  const fontValues = ['Arial', 'Courier New', 'Georgia', 'Impact', 'Lucida Console', 'Tahoma', 'Times New Roman', 'Verdana'];
  const headerValues = [false, 1, 2, 3, 4, 5];

const Document = ({approvePerson, requestCode, isOpen}) => {

    const documentRef = useRef(null)
    const [orgContact, setOrgContact] = useState(null)
    const currentDate = new Date()
    const {user} = useContext(UserContext);
    const [popup, setPopup] = useState(false)
    const [detail, setDetail] = useState('Хүсэлт гаргах тухай')
    const text = useRef('')
    const [reqApprovePerson, setReqApprovePerson] = useState(approvePerson)
    const tempPerson = useRef('Батлах хүний нэр')
    const requestContext = useRef(null)
    const orgName = user.organization.name ? user.organization.name.replace(/<[^>]*>/g, '') : 'байгууллагын нэр байхгүй'
    const orgAddress = orgContact?.address?.value ? orgContact?.address?.value.replace(/<[^>]*>/g, '') : 'хаяг байхгүй'
    const orgTelephone = orgContact?.telephone?.value ? orgContact?.telephone?.value.replace(/<[^>]*>/g, '') : 'утасны дугаар байхгүй'
    const date = `${currentDate.getFullYear()}/${currentDate.getMonth()+1}/${currentDate.getDate()}`
    useEffect(()=>{
        if(isOpen && orgContact === null)
            getOrgContact()
        if(!approvePerson){
            OrganizationService.getOrgHeadName().then((res)=>{
                //  console.log("org head resp", res)
                setReqApprovePerson(res.data?.[0]?.org_head)
            })
        }
        
    },[user.organization.id, isOpen])

    const getOrgContact = useCallback(async()=>{
        await OrganizationService.getOrgContact(user.organization.id)
            .then((res)=>{
                const tempContact = new Object()
                res.data.forEach((data)=>{
                    tempContact[data.name] = data
                })
                setOrgContact(tempContact)
                // console.log("setOrgContact", tempContact)
            })
            .catch((err)=>{
                console.log("err",err)
            })
    },[user.organization.id])


    const handleDownload = useReactToPrint({
        onPrintError: (error) => console.log(error),
        content: () => documentRef.current,
        removeAfterPrint: true,
        print: async (printIframe) => {
          const document = printIframe.contentDocument;
          if (document) {
            const html = document.getElementById("documentId");
            const options = {
                filename:'Албан бичиг_'+requestCode??'',
                image:{type:'png', quality:1.0},
                html2canvas: {scale:2},
                jsPDF: {unit:'mm', format:'a4', orientation:'portrait'},
            };
            const exporter = new Html2Pdf(html, options);
            exporter.getPdf(true);
          }
        },
      });

    return (
        <div style={{fontFamily: 'Segoe UI'}}>
            <Popup
                visible={popup}
                onHiding={()=>setPopup(false)}
                //position={'top'}
                height='95vh'
                width='40vw'
                style={{display:'flex'}}
                title="Хүсэлтийн албан бичиг засварлах"
                >
                <ScrollView width='100%' height='100%'>
                <TextArea
                    style={{marginLeft:'auto', marginRight:'auto', marginBottom:'0.5em'}}
                    onValueChange={(e)=>{text.current = e}}
                    // width='90%'
                    hint="Энэ хэсэгт хүсэлт гаргах тухай бичнэ үү"
                    label="Хүсэлт гаргах тухай"
                    height='15%'
                />
                {/* <span style={{marginLeft:"0.1em"}}></span> */}
                <HtmlEditor
                    height="60vh"
                    defaultValue={requestContext.current}
                    onValueChange={(e)=>{requestContext.current = e}}
                    placeholder="Албан бичгийн агуулга"
                    >
                    <MediaResizing enabled={true} />
                    <Toolbar multiline={true}>
                        <Item name="undo" />
                        <Item name="redo" />
                        <Item name="separator" />
                        <Item
                        name="size"
                        acceptedValues={sizeValues}
                        />
                        <Item
                        name="font"
                        acceptedValues={fontValues}
                        />
                        <Item name="separator" />
                        <Item name="bold" />
                        <Item name="italic" />
                        <Item name="strike" />
                        <Item name="underline" />
                        <Item name="separator" />
                        <Item name="alignLeft" />
                        <Item name="alignCenter" />
                        <Item name="alignRight" />
                        <Item name="alignJustify" />
                        <Item name="separator" />
                        <Item name="orderedList" />
                        <Item name="bulletList" />
                        <Item name="separator" />
                        <Item
                        name="header"
                        acceptedValues={headerValues}
                        />
                        <Item name="separator" />
                        <Item name="color" />
                        <Item name="background" />
                        <Item name="separator" />
                        {/* <Item name="link" /> */}
                        {/* <Item name="image" /> */}
                        <Item name="separator" />
                        <Item name="clear" />
                        <Item name="codeBlock" />
                        <Item name="blockquote" />
                        <Item name="separator" />
                        <Item name="insertTable" />
                        <Item name="deleteTable" />
                        <Item name="insertRowAbove" />
                        <Item name="insertRowBelow" />
                        <Item name="deleteRow" />
                        <Item name="insertColumnLeft" />
                        <Item name="insertColumnRight" />
                        <Item name="deleteColumn" />
                    </Toolbar>
                </HtmlEditor>
                {/* <TextArea
                    style={{marginLeft:'auto', marginRight:'auto', marginBottom:'2em'}}
                    onValueChange={(e)=>{requestContext.current = e}}
                    // width='90%'
                    hint="Энэ хэсэгт албан бичгийн агуулгаа бичнэ үү"
                    label="Албан бичгийн агуулга"
                    height='50%'
                /> */}
                <div style={{display:'flex',flexDirection:'column'}}>
                    <TextBox
                        value={reqApprovePerson ? reqApprovePerson : ''}
                        placeholder={reqApprovePerson}
                        // width="50%"
                        label="Төсвийн шууд захирагч"
                        onValueChange={(e)=>{tempPerson.current = e}}
                    />
                    <Button
                        style={{marginTop:'1em'}}
                        text="Хадгалах"
                        type="success"
                        icon="save"
                        onClick={()=>{
                            setPopup(false)
                            setDetail(text.current)
                            setReqApprovePerson(tempPerson.current)
                            }}
                        />
                </div>
                </ScrollView>
            </Popup>
            <div style={{display:'flex', flexDirection:'row-reverse', paddingRight:'1em', marginBottom:"1em", gap:'1em'}}>
            <Button text="Татаж авах" icon="download" onClick={handleDownload}/>
                <Button 
                    onClick={()=>{setPopup(true)}} 
                    text="Засварлах" 
                    icon="edit"/>
                <ReactToPrint documentTitle={'document_of_request_'+requestCode} content={() => documentRef.current}>
                    <PrintContextConsumer>
                        {({ handlePrint }) => (
                                <Button onClick={handlePrint} text="Хэвлэх" icon="print"/>
                            )}
                    </PrintContextConsumer> 
                </ReactToPrint>            
            </div>
            <A4 documentRef={documentRef}
                logo={user.logo}
                orgName={orgName}
                address={orgAddress}
                orgTelephone={orgTelephone}
                email={orgContact?.email?.value} 
                date={date} 
                requestCode={requestCode}
                detail={detail}
                parentOrgName={user?.organization?.parent_name} 
                context={requestContext.current}
                approvePerson={reqApprovePerson}/>
        </div>
    )
}

export default Document;
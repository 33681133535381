import React, { useState, useEffect } from "react";
import BudgetService from "../../../../services/api/budget";
import notify from "devextreme/ui/notify";
import { numberWithCommas } from "../../../../util";
//import { paletteErgenTologdoh } from "../../../../util";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import { useContext } from "react";
import { UserContext } from "../../../../hooks/UserContext";
import LoadPanelComponent from "../../../budget/components/loadPanel";
import OrganizationService from "../../../../services/api/organization";
import { Tooltip } from "devextreme-react/tooltip";
import info1 from "../../../../assets/imgs/icon/iconInfo1.png";

const EhUusverHeadInfo = (props) => {
    const history = useHistory();
    const [loader, setLoader] = useState(true);
    const [horizontalData, setHorizontalData] = useState({});
    const [sumVal, setSumVal] = useState(0);
    const { user } = useContext(UserContext);
    const [orgBudgetWorker, setOrgBudgetWorker] = useState([]);
    /*const [withAnimationVisible, setWithAnimationVisible] = useState(false);
    const [withAnimationVisible1, setWithAnimationVisible1] = useState(false);
    const [withAnimationVisible2, setWithAnimationVisible2] = useState(false);
    const [withAnimationVisible3, setWithAnimationVisible3] = useState(false);*/
    const [withAnimationVisibleDate, setWithAnimationVisibleDate] = useState(false);
    const [withAnimationVisibleDate1, setWithAnimationVisibleDate1] = useState(false);
    const [withAnimationVisibleDate2, setWithAnimationVisibleDate2] = useState(false);
    const [withAnimationVisibleDate3, setWithAnimationVisibleDate3] = useState(false);
    const loadHorizontalData = async () => {
        try {
            let r = await BudgetService.getSourceHeaderData(props.year, props.chosenValue, props.chosenMinistry ? props.chosenMinistry : "ALL");
            let s = await BudgetService.getYamsData(props.year, 3);
            let sum = _.sumBy(s, 'amount');

            setSumVal(sum);
            setHorizontalData(r[0]);
            let w = await OrganizationService.getOrgBudgetByWorker(props.year, props.chosenValue);
            setOrgBudgetWorker(w.data[0]);
            if (typeof props.rootDataSetter === 'function') {
                if (props.chosenValue === 'ALL') {
                    props.rootDataSetter(r[0], false);
                } else {
                    props.rootDataSetter(r[0], true)
                }
            }
        } catch (e) {
            notify(e.message, 'error', 2000);
        } finally {
            setLoader(false)
        }
    }

    const locationHandler = () => {
        if (props.locationErgenTologdoh) {
            history.push(props.locationErgenTologdoh)
        }
    }

    const calculatePercentage = () => {
        let percentage
        if (horizontalData && horizontalData.todotgoson2 !== 0 && horizontalData.guitsetgel !== 0) {

            percentage = ((100 * horizontalData.guitsetgel) / horizontalData.todotgoson2 ).toFixed(2)
            return `${numberWithCommas(horizontalData.guitsetgel / 1000000)} (${percentage}%)`;

        } else if (horizontalData && horizontalData.todotgoson !== 0 && horizontalData.guitsetgel !== 0) {

            percentage = (((100 * horizontalData.guitsetgel) / horizontalData.todotgoson).toFixed(2))
            return `${numberWithCommas(horizontalData.guitsetgel / 1000000)} (${percentage}%)`;

        } else if (horizontalData &&  horizontalData.batlagdsan !== 0 && horizontalData.guitsetgel !== 0) {

            percentage = (((100 * horizontalData.guitsetgel) / horizontalData.batlagdsan).toFixed(2))
            return `${numberWithCommas(horizontalData.guitsetgel / 1000000)} (${percentage}%)`;

        } else {
            return '-';
        }
    }
    useEffect(() => {
        loadHorizontalData();
    }, [props.year, props.chosenValue, props.chosenMinistry]);
    console.log(orgBudgetWorker);
    return (
        <div id="load1" className="row" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', flex: 1, minHeight: 106 }}>
            <LoadPanelComponent position={{ of: '#load1' }} visible={loader} />
            {!loader &&
                <>
                    {(horizontalData && horizontalData.batlagdsan_date !== null) && <Tooltip
                        target="#dateBudget1o"
                        position="bottom"
                        visible={withAnimationVisibleDate}
                        hideOnOutsideClick={true}
                    >
                        <div style={{ padding: 10, width: orgBudgetWorker === undefined || orgBudgetWorker.batlagdsan_approved_worker_date === null ? '16rem' : '23rem', fontFamily: 'Segoe UI', backgroundColor: '#555', color: '#fff', borderBottom: '1px solid #fff', borderRadius: 10  }}>
                            <div style={{ display: 'flex', gap: 5, justifyContent: 'center' }}>
                                <b style={{ margin: 0, width: orgBudgetWorker === undefined || orgBudgetWorker.batlagdsan_approved_worker_date === null ? '50%' : '40%', textAlign: 'end' }}>Шинэчлэгдсэн:</b>
                                <div style={{ display: 'flex', width: '60%' }}><span className="tooltip-series-name">Огноо:&nbsp;</span><label
                                    style={{ fontWeight: 'Bold' }}>{horizontalData.batlagdsan_date}</label></div>
                            </div>
                            <hr/>
                            {orgBudgetWorker && orgBudgetWorker?.batlagdsan_worker_name !==null &&
                            <div style={{ display: 'flex', flexDirection: 'row', gap: 5, justifyContent: 'center' }}>
                                <div style={{ fontWeight: 'Bold', width: '40%', textAlign: 'end' }}>Баталгаажуулсан:</div>
                                <div style={{ width: '60%' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <div style={{ display: 'flex' }}><span className="tooltip-series-name">Овог нэр:&nbsp;</span><label
                                            style={{ fontWeight: 'Bold' }}>{orgBudgetWorker?.batlagdsan_worker_name ? orgBudgetWorker?.batlagdsan_worker_name : '-'}</label></div>
                                    </div>
                                    <div style={{ display: 'flex' }}><span className="tooltip-series-name">Албан тушаал:&nbsp;</span><label
                                        style={{ fontWeight: 'Bold' }}>{orgBudgetWorker?.batlagdsan_position_name ? orgBudgetWorker?.batlagdsan_position_name : '-'}</label>
                                    </div>
                                    <div style={{ display: 'flex' }}><span className="tooltip-series-name">Огноо:&nbsp;</span><label
                                        style={{ fontWeight: 'Bold' }}>{orgBudgetWorker?.batlagdsan_approved_worker_date ? orgBudgetWorker?.batlagdsan_approved_worker_date : '-'}</label>
                                    </div>
                                </div>
                            </div>
                            }
                        </div>
                    </Tooltip>}
                    {(horizontalData && horizontalData.guitsetgel_date !== null) && <Tooltip
                        target="#dateBudget2o"
                        position="bottom"
                        visible={withAnimationVisibleDate1}
                        hideOnOutsideClick={true}
                    >
                        <div style={{ padding: 10, width: orgBudgetWorker === undefined  || orgBudgetWorker.performance_approved_worker_date === null ? '16rem' : '23rem', fontFamily: 'Segoe UI', backgroundColor: '#555', color: '#fff', borderBottom: '1px solid #fff', borderRadius: 10  }}>
                            <div style={{ display: 'flex', gap: 5, justifyContent: 'center' }}>
                                <b style={{ margin: 0, width: orgBudgetWorker === undefined  || orgBudgetWorker.performance_approved_worker_date === null ? '50%': '40%', textAlign: 'end' }}>Шинэчлэгдсэн:</b>
                                <div style={{ display: 'flex', width: '60%' }}><span className="tooltip-series-name">Огноо:&nbsp;</span><label
                                    style={{ fontWeight: 'Bold' }}>{horizontalData.guitsetgel_date}</label></div>
                            </div>
                            <hr/>
                            {orgBudgetWorker && orgBudgetWorker.performance_worker_name !== null &&
                            <div style={{ display: 'flex', flexDirection: 'row', gap: 5, justifyContent: 'center' }}>
                                <div style={{ fontWeight: 'Bold', width: '40%', textAlign: 'end' }}>Баталгаажуулсан:</div>
                                <div style={{ width: '60%' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <div style={{ display: 'flex' }}><span className="tooltip-series-name">Овог нэр:&nbsp;</span><label
                                            style={{ fontWeight: 'Bold' }}>{orgBudgetWorker?.performance_worker_name ? orgBudgetWorker?.performance_worker_name : '-'}</label></div>
                                    </div>
                                    <div style={{ display: 'flex' }}><span className="tooltip-series-name">Албан тушаал:&nbsp;</span><label
                                        style={{ fontWeight: 'Bold' }}>{orgBudgetWorker?.performance_position_name ? orgBudgetWorker?.performance_position_name : '-'}</label>
                                    </div>
                                    <div style={{ display: 'flex' }}><span className="tooltip-series-name">Огноо:&nbsp;</span><label
                                        style={{ fontWeight: 'Bold' }}>{orgBudgetWorker?.performance_approved_worker_date ? orgBudgetWorker?.performance_approved_worker_date : '-'}</label>
                                    </div>
                                </div>
                            </div>
                            }
                        </div>
                    </Tooltip>}
                    {(horizontalData && horizontalData.todotgoson_date !== null) && <Tooltip
                        target="#dateBudget3o"
                        position="bottom"
                        visible={withAnimationVisibleDate2}
                        hideOnOutsideClick={true}
                    >
                        <div style={{ padding: 10, width: orgBudgetWorker === undefined  || orgBudgetWorker.todotgoson_approved_worker_date === null ? '16rem' : '23rem', fontFamily: 'Segoe UI', backgroundColor: '#555', color: '#fff', borderBottom: '1px solid #fff', borderRadius: 10  }}>
                            <div style={{ display: 'flex', gap: 5, justifyContent: 'center' }}>
                                <b style={{ margin: 0, width: orgBudgetWorker === undefined  || orgBudgetWorker.todotgoson_approved_worker_date === null ? '50%' : '40%', textAlign: 'end' }}>Шинэчлэгдсэн:</b>
                                <div style={{ display: 'flex', width: '60%' }}><span className="tooltip-series-name">Огноо:&nbsp;</span><label
                                    style={{ fontWeight: 'Bold' }}>{horizontalData.todotgoson_date}</label></div>
                            </div>
                            <hr/>
                            {orgBudgetWorker && orgBudgetWorker.todotgoson_worker_name !== null &&
                            <div style={{ display: 'flex', flexDirection: 'row', gap: 5, justifyContent: 'center' }}>
                                <div style={{ fontWeight: 'Bold', width: '40%', textAlign: 'end' }}>Баталгаажуулсан:</div>
                                <div style={{ width: '60%' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <div style={{ display: 'flex' }}><span className="tooltip-series-name">Овог нэр:&nbsp;</span><label
                                            style={{ fontWeight: 'Bold' }}>{orgBudgetWorker?.todotgoson_worker_name ? orgBudgetWorker?.todotgoson_worker_name : '-'}</label></div>
                                    </div>
                                    <div style={{ display: 'flex' }}><span className="tooltip-series-name">Албан тушаал:&nbsp;</span><label
                                        style={{ fontWeight: 'Bold' }}>{orgBudgetWorker?.todotgoson_position_name ? orgBudgetWorker?.todotgoson_position_name : '-'}</label>
                                    </div>
                                    <div style={{ display: 'flex' }}><span className="tooltip-series-name">Огноо:&nbsp;</span><label
                                        style={{ fontWeight: 'Bold' }}>{orgBudgetWorker?.todotgoson_approved_worker_date ? orgBudgetWorker?.todotgoson_approved_worker_date : '-'}</label>
                                    </div>
                                </div>
                            </div>}
                        </div>
                    </Tooltip>}
                    {(horizontalData && horizontalData.todotgoson2_date !== null) && <Tooltip
                        target="#dateBudget4o"
                        position="bottom"
                        visible={withAnimationVisibleDate3}
                        hideOnOutsideClick={true}
                    >
                       <div style={{ padding: 10, width: orgBudgetWorker === undefined || orgBudgetWorker.todotgoson2_approved_worker_date === null ? '16rem' : '23rem', fontFamily: 'Segoe UI', backgroundColor: '#555', color: '#fff', borderBottom: '1px solid #fff', borderRadius: 10  }}>
                            <div style={{ display: 'flex', gap: 5, justifyContent: 'center' }}>
                                <b style={{ margin: 0, width: orgBudgetWorker === undefined || orgBudgetWorker.todotgoson2_approved_worker_date === null ? '50%' : '40%', textAlign: 'end' }}>Шинэчлэгдсэн:</b>
                                <div style={{ display: 'flex', width: '60%' }}><span className="tooltip-series-name">Огноо:&nbsp;</span><label
                                    style={{ fontWeight: 'Bold' }}>{horizontalData.todotgoson2_date}</label></div>
                            </div>
                            <hr/>
                            {orgBudgetWorker && orgBudgetWorker.todotgoson2_worker_name !==null &&
                            <div style={{ display: 'flex', flexDirection: 'row', gap: 5, justifyContent: 'center' }}>
                                <div style={{ fontWeight: 'Bold', width: '40%', textAlign: 'end' }}>Баталгаажуулсан:</div>
                                <div style={{ width: '60%' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <div style={{ display: 'flex' }}><span className="tooltip-series-name">Овог нэр:&nbsp;</span><label
                                            style={{ fontWeight: 'Bold' }}>{orgBudgetWorker?.todotgoson2_worker_name ? orgBudgetWorker?.todotgoson2_worker_name : '-'}</label></div>
                                    </div>
                                    <div style={{ display: 'flex' }}><span className="tooltip-series-name">Албан тушаал:&nbsp;</span><label
                                        style={{ fontWeight: 'Bold' }}>{orgBudgetWorker?.todotgoson2_position_name ? orgBudgetWorker?.todotgoson2_position_name : '-'}</label>
                                    </div>
                                    <div style={{ display: 'flex' }}><span className="tooltip-series-name">Огноо:&nbsp;</span><label
                                        style={{ fontWeight: 'Bold' }}>{orgBudgetWorker?.todotgoson2_approved_worker_date ? orgBudgetWorker?.todotgoson2_approved_worker_date : '-'}</label>
                                    </div>
                                </div>
                            </div>
                            }
                        </div>
                    </Tooltip>}
                    {(user.username === "02" && (props.chosenMinistry === "ALL" || props.chosenMinistry === undefined)) &&
                        <>
                            <div style={econ2Styles.bigDivStyle}>
                                <div className="col-2" />
                                <div className="col-8">
                                    <p style={{
                                        margin: '0',
                                        color: '#2f2f2f',
                                        fontSize: '1rem',
                                        fontWeight: "700",
                                    }}>Батлагдсан төсөв /сая ₮/</p>
                                </div>
                                <div className="col-2" style={{ margin: 0, display: 'flex', justifyContent: 'end' }}>
                                    <div
                                        style={sumVal ? dateStyle.divStyle : {}}
                                        id="dateBudget1o"
                                        onMouseEnter={() => setWithAnimationVisibleDate(true)}
                                        onMouseLeave={() => setWithAnimationVisibleDate(false)}
                                    >
                                        <p style={dateStyle.pStyle}>{horizontalData?.batlagdsan_date}</p>
                                        {sumVal ? <img style={dateStyle.iconStyle} src={info1} /> : <div />}
                                    </div>
                                </div>
                            </div>
                            <div
                                style={{ ...econ2Styles.textAlignerSpan, ...econ2Styles.numericFontSize }}>
                                <span
                                    id="dateBudget1o"
                                    style={{
                                        color: props.type === 'zarlaga' ? '#F66160' : '#003696', fontSize: '2rem', cursor: 'pointer'
                                    }}>{sumVal ? numberWithCommas(sumVal) : '-'}
                                </span>
                            </div>



                            <div className="card" style={{ flex: 1 }} onClick={locationHandler}>
                                <div style={econ2Styles.bigDivStyle}>
                                    <div className="col-2" />
                                    <div className="col-8">
                                        <p style={{
                                            margin: '0',
                                            color: '#2f2f2f',
                                            fontSize: '1rem',
                                            fontWeight: "700"
                                        }}>Төсвийн гүйцэтгэл /сая ₮/</p>
                                    </div>

                                    <div className="col-2" style={{ margin: 0, display: 'flex', justifyContent: 'end' }}>
                                        <div
                                            style={horizontalData?.guitsetgel_date ? dateStyle.divStyle : {}}
                                            id="dateBudget2o"
                                            onMouseEnter={() => setWithAnimationVisibleDate1(true)}
                                            onMouseLeave={() => setWithAnimationVisibleDate1(false)}
                                        >
                                            <p style={dateStyle.pStyle}>{horizontalData?.guitsetgel_date}</p>
                                            {horizontalData?.guitsetgel_date ? <img style={dateStyle.iconStyle} src={info1} /> : <div />}
                                        </div>
                                    </div>
                                </div>
                                <div
                                    style={{ ...econ2Styles.textAlignerSpan, ...econ2Styles.numericFontSize }}>
                                    <span
                                        id="dateBudget2o"
                                        style={{
                                            color: '#003696', fontSize: '2rem', cursor: 'pointer'
                                        }}>
                                        {calculatePercentage()}</span>

                                </div>
                            </div>
                        </>

                    }


                    {((props.chosenMinistry !== "ALL" && props.chosenMinistry !== undefined) || user.username !== "02") &&
                        <div className="card" style={{ flex: 1 }} onClick={locationHandler}>
                            <div style={econ2Styles.bigDivStyle}>
                                <div className="col-2" />
                                <div className="col-8">
                                    <p style={{
                                        margin: '0',
                                        color: '#2f2f2f',
                                        fontSize: '1rem',
                                        fontWeight: "700"
                                    }}>Батлагдсан төсөв /сая ₮/</p>
                                </div>
                                <div className="col-2" style={{ margin: 0, display: 'flex', justifyContent: 'end', cursor: 'default' }}>
                                    <div
                                        style={horizontalData?.batlagdsan_date ? dateStyle.divStyle : {}}
                                        id="dateBudget1o"
                                        onMouseEnter={() => setWithAnimationVisibleDate(true)}
                                        onMouseLeave={() => setWithAnimationVisibleDate(false)}
                                    >
                                        <p style={dateStyle.pStyle}>{horizontalData?.batlagdsan_date}</p>
                                        {horizontalData?.batlagdsan_date ? <img style={dateStyle.iconStyle} src={info1} /> : <div />}
                                    </div>
                                </div>
                            </div>
                            <div
                                style={{ ...econ2Styles.textAlignerSpan, ...econ2Styles.numericFontSize }}>
                                <span
                                    id="dateBudget1o"
                                    style={{
                                        color: props.type === 'zarlaga' ? '#F66160' : '#003696', fontSize: '2rem', cursor: 'pointer'
                                    }}>{horizontalData?.batlagdsan ? numberWithCommas(horizontalData?.batlagdsan / 1000000) : '-'}
                                </span>
                            </div>
                        </div>
                    }

                    {((props.chosenMinistry !== "ALL" && props.chosenMinistry !== undefined) || user.username !== "02") && horizontalData && horizontalData.todotgoson !== 0 &&
                        <div className="card" style={{ flex: 1 }} onClick={locationHandler}>
                            <div style={econ2Styles.bigDivStyle}>
                                <div className="col-2" />
                                <div className="col-8">
                                    <p style={{
                                        margin: '0',
                                        color: '#2f2f2f',
                                        fontSize: '1rem',
                                        fontWeight: "700"
                                    }}>Төсвийн тодотгол /сая ₮/</p>
                                </div>
                               
                                    <div className="col-2" style={{ margin: 0, display: 'flex', justifyContent: 'end', cursor: 'default' }}>
                                        <div
                                            style={horizontalData?.todotgoson_date ? dateStyle.divStyle : {}}
                                            id="dateBudget3o"
                                            onMouseEnter={() => setWithAnimationVisibleDate2(true)}
                                            onMouseLeave={() => setWithAnimationVisibleDate2(false)}
                                        >
                                            <p style={dateStyle.pStyle}>{horizontalData?.todotgoson_date}</p>
                                            {horizontalData?.todotgoson_date ? <img style={dateStyle.iconStyle} src={info1} /> : <div/>}
                                        </div>
                                    </div>
                            </div>
                            <div
                                style={{ ...econ2Styles.textAlignerSpan, ...econ2Styles.numericFontSize }}>
                                <span
                                    id="dateBudget3o"
                                    style={{
                                        color: props.type === 'zarlaga' ? '#F66160' : '#003696', fontSize: '2rem', cursor: 'pointer'
                                    }}>{horizontalData?.todotgoson ?numberWithCommas(horizontalData?.todotgoson / 1000000) : '-'}
                                </span>
                            </div>
                        </div>}

                    {((props.chosenMinistry !== "ALL" && props.chosenMinistry !== undefined) || user.username !== "02") && horizontalData && horizontalData.todotgoson2 !== 0 &&
                        <>
                            <div className="card" style={{ flex: 1 }} onClick={locationHandler}>
                                <div style={econ2Styles.bigDivStyle}>
                                    <div className="col-2" />
                                    <div className="col-8">
                                        <p style={{
                                            margin: '0',
                                            color: '#2f2f2f',
                                            fontSize: '1rem',
                                            fontWeight: "700"
                                        }}>Төсвийн тодотгол 2 /сая ₮/</p>
                                    </div>
                                    
                                        <div className="col-2" style={{ margin: 0, display: 'flex', justifyContent: 'end', cursor: 'default' }}>
                                            <div
                                                style={horizontalData?.todotgoson2_date ? dateStyle.divStyle : {}}
                                                id="dateBudget4o"
                                                onMouseEnter={() => setWithAnimationVisibleDate3(true)}
                                                onMouseLeave={() => setWithAnimationVisibleDate3(false)}
                                            >
                                                <p style={dateStyle.pStyle}>{horizontalData?.todotgoson2_date}</p>
                                               {horizontalData?.todotgoson2_date ? <img style={dateStyle.iconStyle} src={info1} /> : <div/>} 
                                            </div>
                                        </div>
                                </div>
                                <div
                                    style={{ ...econ2Styles.textAlignerSpan, ...econ2Styles.numericFontSize }}>
                                    <span
                                        id="dateBudget4o"
                                        style={{
                                            color: props.type === 'zarlaga' ? '#F66160' : '#003696', fontSize: '2rem', cursor: 'pointer'
                                        }}>{horizontalData?.todotgoson2 ? numberWithCommas(horizontalData?.todotgoson2 / 1000000) : '-'}
                                    </span>
                                </div>

                            </div>


                        </>}
                            <div className="card" style={{ flex: 1 }} onClick={locationHandler}>
                                <div style={econ2Styles.bigDivStyle}>
                                    <div className="col-2" />
                                    <div className="col-8">
                                        <p style={{
                                            margin: '0',
                                            color: '#2f2f2f',
                                            fontSize: '1rem',
                                            fontWeight: "700"
                                        }}>Төсвийн гүйцэтгэл /сая ₮/</p>
                                    </div>

                                    <div className="col-2" style={{ margin: 0, display: 'flex', justifyContent: 'end', cursor: 'default' }}>
                                        <div
                                            style={horizontalData?.guitsetgel_date ? dateStyle.divStyle : {}}
                                            id="dateBudget2o"
                                            onMouseEnter={() => setWithAnimationVisibleDate1(true)}
                                            onMouseLeave={() => setWithAnimationVisibleDate1(false)}
                                        >
                                            <p style={dateStyle.pStyle}>{horizontalData?.guitsetgel_date}</p>
                                            {horizontalData?.guitsetgel_date ? <img style={dateStyle.iconStyle} src={info1} /> : <div />}
                                        </div>
                                    </div>
                                </div>
                                <div
                                    style={{ ...econ2Styles.textAlignerSpan, ...econ2Styles.numericFontSize }}>
                                    <span
                                        id="dateBudget2o"
                                        style={{
                                            color: '#003696', fontSize: '2rem', cursor: 'pointer'
                                        }}>
                                        {calculatePercentage()}
                                    </span>
                                </div>
                            </div>
                </>
            }
        </div>
    )
}
const econ2Styles = {
    bigDivStyle: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        textAlign: 'center',
        width: '100%',
        height: '3rem',
        padding: 10
    },
    textAligner: {
        padding: '0',
        textAlign: 'center',
        fontWeight: 700,

    },
    textAlignerSpan: {
        textAlign: 'center',
        fontWeight: "700",
        marginBottom: 10
    },
    numericFontSize: {
        fontSize: '1rem'
    }
}
const dateStyle = {
    divStyle: {
        position: 'relative',
        backgroundColor: '#EBF1FF',
        padding: '5px',
        border: 'none',
        width: '6rem',
        borderRadius: '2rem'
    },
    pStyle: {
        margin: '0',
        paddingLeft: '5px',
        fontSize: '0.6rem',
        textAlign: 'start',
        fontWeight: 'bold'
    },
    iconStyle: {
        position: 'absolute',
        width: '2rem',
        height: '2rem',
        right: '-5px',
        top: '-4.5px'
    }
}
export default EhUusverHeadInfo;

import React, {useState, useRef, useEffect,useContext} from "react";
import Button from "devextreme-react/button";
import {GetTran} from "../../tran/Db";
import {Popup} from "devextreme-react/popup";
import ScrollView from "devextreme-react/scroll-view";
import DataGrid, {
    Column,
    Export, FilterRow, Grouping, GroupItem,
    GroupPanel,
    Item,
    SearchPanel,
    Selection,
    Summary,
    Toolbar,
    TotalItem
} from "devextreme-react/data-grid";
import {Workbook} from "exceljs";
import {exportDataGrid} from "devextreme/excel_exporter";
import {saveAs} from "file-saver-es";
import AP01 from "./AP01";

import {UserContext} from "../../../../hooks/UserContext";
import fcsService from "../../../../services/api/fcs";
import LoadPanelComponent from "../../../../components/LoadPanelComponent";

const position14 = {of: '#firstBox'};

const ApBalance = (props) => {

    const year = props.yearValue;
    const month = props.monthValue;
    const {user} = useContext(UserContext);

    const componentRef = useRef();
    const [accountValue, setAccountValue] = useState('');
    const [orgValue, setOrgValue] = useState('');

    const [acctDtlList, setAcctDtlList] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);
    const [selectedValue, setSelectedValue] = useState(null);
    const [loader, setLoader] = useState(false);
    //const ap = props.ap;
    const jr_key = props.jr_key;
   // const reportTitle = (jr_key == 'AP' ? 'Өглөг' : 'Авлага')
   // const classes = useStyles();
    const [beginBal, setBeginBal] = useState([]);
const [dataFcsCustOrgn,setFcsCustOrgn]=useState(null)
    const [Tran, setTran] = useState([]);
    const [report, setReport] = useState([]);
    //const [tranDescr, setTranDescr] = useState([]);
    //const [acctList, setAcctList] = useState([]);
    const [reportShow, setReportShow] = useState(false)
    const customizeDate = (data) => {
        return Number(parseFloat(data.value).toFixed(2)).toLocaleString("en", {
            minimumFractionDigits: 2,
        })
    }



    const diff = (rowData) => {
        const date = new Date(rowData.docdate)
        return date.getMonth() + 1;
    }
    const onExporting = (e) => {
        // console.log('eeeee--', e)
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Main sheet');

        exportDataGrid({
            component: e.component,
            worksheet,
            autoFilterEnabled: true,
        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer], {type: 'application/octet-stream'}), `${accountValue}-${year}-${month}.xlsx`);
            });
        });
        e.cancel = true;
    }

   /* const acctOrgCode = (rowData) => {
        return  rowData.orgCode+rowData.acct ;
    }*/

    const onSelectionChanged = ({selectedRowsData}) => {

    //    console.log("selectttt",selectedRowsData)
        setAccountValue(selectedRowsData[0].acct);
        setOrgValue(selectedRowsData[0].orgcode);
        setSelectedValue(selectedRowsData[0]);
    }

    const getCustOrg=async (orgcode) =>{
        const fcsCustOrgn = await fcsService.getFcsCustOrgn(orgcode);
        setFcsCustOrgn(fcsCustOrgn);
    }

    /*const getApLine = async (page) => {
        // setLoading(true);

        let p2 = "";

        jr_key == "AP"
            ? ((p2 = "26")
            (p2 = p2 + "&c1=c18&v1=" + year + "&c2=c19&v2=")
            (p2 = p2 + month + "&c3=c20&v3=" + selectApAcct.code))
            : ((p2 = "31")
            (p2 = p2 + "&c1=c18&v1=" + year + "&c2=c19&v2=")
            (p2 = p2 + month + "&c3=c20&v3=" + selectApAcct.code))

        await GetTran(p2, "0", "gl?")
            .then((result) => {
                // setLoading(false);
                setTran(result.data.data);

                //  setTranDescr(result.data.set.descr);
            })
            .catch((error) => {
                // setLoading(false);
                setTran([]);

                console.log(error);
            });
    };*/

    const getGlUS = async () => {
        let p1 = ""
        jr_key == "AP" ? p1 = "4" : p1 = "7"
        p1 = p1 + "&year=" + year + "&month=" + month + "&acct=1" ;
        // n/arreport/artran?year=2022&month=7&acct=1210-0001
        let urlAdd = ""
        jr_key == "AP" ? urlAdd = "apreport/aptran?" : urlAdd = "arreport/artran?"

        await GetTran(p1, 0, urlAdd)
            .then((result) => {
                //  setLoading(false);
                setBeginBal(result.data.data);
            })
            .catch((error) => {
                //   setLoading(false);

                setBeginBal([]);
                console.log(error);
            });
    };

    /*const getAcct = () => {
        // setLoading(true);

        GetTran(5, 0, "gl?")
            .then((result) => {
                //  setLoading(false);
                setAcctList(result.data.data);
            })
            .catch((error) => {
                //   setLoading(false);
                setAcctList([]);

                console.log(error);
            });
    };*/

    const calReportUS = () => {

        setTran([]);
        let array = [];

        let index = -1;
        beginBal.map((row,index) => {
          //  console.log("rowwww", row);
            let glRow = {acct: "",orgcode:"" ,descr: "", bd: 0, dt: 0, cr: 0, ed: 0};
            // (propertyValues[0] == "undefined" || propertyValues[0] == null)  ? ((glRow.acct = ""), alert(propertyValues[0]))
            // : (glRow.acct = propertyValues[0]);
            glRow.acct = row.acct
            glRow.orgcode = row.bbclass
            glRow.bd = row.bd;
            glRow.descr = row.orgname;
            (glRow.dt = row.dt);
            (glRow.cr = row.cr);
            glRow.key=index
            glRow.ed = Number(glRow.bd) + Number(glRow.dt) - Number(glRow.cr)
            array.push(glRow)
        });

        array.sort((a, b) => {
            let fa = a.acct.toLowerCase(),
                fb = b.acct.toLowerCase();

            if (fa < fb) {
                return -1;
            }
            if (fa > fb) {
                return 1;
            }
            return 0;
        });

        const array2 = array.filter(
            (item, index) =>
                item.bd != 0 || item.dt != 0 || item.cr != 0
        )
        setReport(array2);
    };

    const getDetails =async (id) => {

        setTran([])

        let p1 = ""

        jr_key == "AP" ? p1 = "5" : p1 = "8"

        p1 = p1 + "&year=" + year + "&month=" + month + "&acct=" + accountValue + "&orgcode=" + orgValue;
        //http://localhost:3002/fin/arreport/detial?year=2022&month=7&acct=1210-0001&orgcode=нэг01
        //fcs.mn:8081/fin/gl?p1=0&col=c19&value=10&match=%3C=&page=0
        let urlAdd = ""
        jr_key == "AP" ? urlAdd = "apreport/detial?" : urlAdd = "arreport/detial?"

      await  GetTran(p1, 0, urlAdd)
            .then((result) => {
                //  setLoading(false);
getCustOrg(orgValue)
                setAcctDtlList(result.data.data);
                id == 0 ? setModalVisible(true) : setReportShow(true)


            })
            .catch((error) => {
                //   setLoading(false);


                console.log(error);
            });
    };

    const tranDesc = [
        "#",
        "код",
        "нэр",
        "Эхний үлдэгдэл",
        jr_key == "AP" ? "Худалдан авалт" : "Борлуулалт",
        jr_key == "AP" ? "Төлөлт" : "Төлбөр",
        "Эцсийн үлдэгдэл",
    ];


    //const initOrgCode = {code: "", name: "", amount: 0}
    //const [orgCode, setOrgCode] = useState({...initOrgCode});


    //const myRef = useRef(null);

    //const executeScroll = () => myRef.current.scrollIntoView();
    // run this function from an event handler or an effect to execute scroll
    const initApAcct = {code: "", name: "", amount: 0};
    //const [selectApAcct, setSelectApAcct] = useState({...initApAcct});

    const onCellPrepared= (e)=>{
        let cell= e.cellElement
        if (e.rowType!=="header" && e.rowType !== "filter"){
            cell.style= "color: rgb(20, 23, 106); font-weight: 500; padding: .5rem 0; text-align: center"
        }else if (e.rowType==="header"){
            cell.style= `
            background-color: rgb(238, 240, 244); 
            border: none; padding-top: .875rem; 
            color: rgb(20, 23, 106); 
            text-align: center;
            font-weight: 600; 
            font-size: 12px;
            margin-top: "2rem"
            `
        } else if (e.rowType=== "filter"){
            cell.style= "background-color: rgb(238, 240, 244); padding: .5rem; border: none; margin-bottom: 1rem"
        }

    }
    return (
        <React.Fragment>

            {useEffect(() => calReportUS(), [beginBal])}
            <div className="row" style={{marginLeft: 20, marginTop: "20px"}}>

                <Button

                    id="btnSave"
                    className="opButtons"
                    onClick={() => getGlUS()}
                    type="default" style={{alignment: "center", borderRadius: '7px'}}
                >
                    Хураангуй
                </Button>

                <div style={{marginLeft: 30, marginTop: 8}}>
                    <input value={orgValue} type='string' className='law-search-input' placeholder='Дансны код'
                           disabled={true}/>
                </div>

                <Button
                    id="btnSave"
                    className="opButtons"
                    onClick={() => getDetails(0)}
                    type="default" style={{alignment: "center", borderRadius: '7px'}}
                >
                    Дэлгэрэнгүй
                </Button>

                <Button
                    id="btnSave"
                    className="opButtons"
                    onClick={() => getDetails(1)}
                    type="default" style={{marginLeft: 20, alignment: "center", borderRadius: '7px'}}
                    text="ТООЦООНЫ ҮЛДЭГДЛИЙН БАТАЛГАА"
                />
            </div>

            <Popup
                width={"75vw"}
                height={"90vh"}
                visible={modalVisible}
                title={'Дэлгэрэнгүй'}
                onHiding={() => setModalVisible(false)}
                resizeEnabled={true}
                dragEnabled={true}
                showTitle={true}
                hideOnOutsideClick={true}
            >
                <ScrollView width='100%' height='100%'>
                    <div className={'card'}>
                        <div className="col-12" style={{marginTop: 20}}>
                            {selectedValue &&
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    marginBottom: 30,
                                    marginLeft: '10%'
                                }}>
                                    {/*<span style={{ fontSize: 16, fontWeight: 500 }}>Эхний үлдэгдэл: </span>*/}
                                    <span style={{
                                        fontSize: 16,
                                        fontWeight: 500,
                                        marginLeft: 10
                                    }}>{selectedValue.descr}</span>
                                </div>
                            }

                            <DataGrid
                                dataSource={acctDtlList}
                                showBorders={true}
                                allowColumnResizing={true}
                                hoverStateEnabled={true}
                                onExporting={onExporting}
                                className="datagridBbReport"
                                keyExpr="acct"

                                noDataText='Дата байхгүй байна.'
                                onCellPrepared={onCellPrepared}
                            >

                                <GroupPanel visible={true}/>
                                <SearchPanel visible={true}/>
                                <Grouping autoExpandAll={true}/>

                                <Column caption="Гүйлгээний утга" dataField="descr"/>
                                <Column caption="дугаар" dataField="docno"/>
                                <Column caption="огноо" dataField="docdate" dataType="date"/>
                                <Column groupIndex={0} name={"sar"} calculateDisplayValue={diff}
                                        caption="сар" width={55}
                                        calculateCellValue={diff}
                                />
                                <Column caption="журнал" dataField="jr"/>
                                <Column caption="Данс" dataField="acct"/>
                                <Column caption={tranDesc[4]} dataField="dt" format="#,##0.00" dataType="number"/>
                                <Column caption={tranDesc[5]} dataField="cr" format="#,##0.00" dataType="number"/>
                                <Summary>
                                    <GroupItem
                                        column="dt"
                                        summaryType="sum"

                                        customizeText={customizeDate}
                                        showInGroupFooter={true}/>


                                    <GroupItem
                                        column="cr"
                                        summaryType="sum"
                                        customizeText={customizeDate}
                                        showInGroupFooter={true}/>


                                    <TotalItem
                                        column="dt"
                                        summaryType="sum"
                                        customizeText={customizeDate}/>
                                    <TotalItem
                                        column="cr"
                                        summaryType="sum"
                                        customizeText={customizeDate}/>
                                </Summary>
                                <Export enabled={true} allowExportSelectedData={false}/>
                            </DataGrid>
                            {selectedValue &&
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'flex-end',
                                    marginTop: 30,
                                    marginRight: '10%'
                                }}>
                                    <span style={{fontSize: 16, fontWeight: 500}}>Эцсийн үлдэгдэл: </span>
                                    <span style={{
                                        fontSize: 16,
                                        fontWeight: 500,
                                        marginLeft: 10
                                    }}> {Number(parseFloat(selectedValue.ed).toFixed(2)).toLocaleString("en", {
                                        minimumFractionDigits: 2,
                                    })}</span>
                                </div>
                            }
                        </div>
                    </div>
                </ScrollView>
            </Popup>

            <Popup
                width={"75vw"}
                height={"90vh"}
                onHiding={() => setReportShow(false)}
                resizeEnabled={true}
                dragEnabled={true}
                showTitle={true}
                hideOnOutsideClick={true}
                visible={reportShow}


                title="дэлгэрэнгүй">
                <ScrollView width='100%' height='100%'>
                    <div>
                        {(dataFcsCustOrgn && selectedValue)  && dataFcsCustOrgn.length>0 &&   <AP01    jr_key={  jr_key }   selectedValue={selectedValue} dataFcsCustOrgn={dataFcsCustOrgn}
                                                     orgName={user.organization.name} tranDesc={tranDesc}
                                                     data={acctDtlList} setReportShow={setReportShow}
                        />}


                   </div>
                </ScrollView>
            </Popup>
            <LoadPanelComponent
                position={position14}
                visible={loader}
                shading={true}
                showPane={false}
                showIndicator={true}
                message=''
                shadingColor="rgba(255,255,255, 0.8)"
            />

            <div className="card" style={{marginTop: 20, marginLeft: 20, marginRight: 20}} ref={componentRef}>
                <DataGrid
                    dataSource={report}
                    showBorders={true}
                    allowColumnResizing={true}
                    onSelectionChanged={onSelectionChanged}
                    hoverStateEnabled={true}
                    keyExpr="key"

                    noDataText='Дата байхгүй байна.'
                    className="datagridBbReport"
                    onExporting={onExporting}
                    onCellPrepared={onCellPrepared}
                    >
                    <Selection mode="single"/>
                       <FilterRow visible={true}/>
                    <GroupPanel visible={true}/>

                    <Toolbar>
                        <Item location={"before"}  cssClass="datagridToolbarDate"  text={`${year? year: ""} - ${month? month: ""}  `}/>
                        <Item location={"after"} cssClass="datagridExportButton" name="exportButton"/>
                        <Item location={"after"} name="searchPanel"/>
                    </Toolbar>

                    <Column caption="Данс" dataField="acct"
                           groupIndex={0}
                            dataType="string"/>
                    <Column caption="код" dataField="orgcode" dataType="string"/>

                    <Column caption="Нэр" dataField="descr" dataType="string"/>

                    <Column caption="Эхний үлдэгдэл" dataField="bd" format="#,##0.00"/>

                    <Column caption={tranDesc[4]} dataField="dt" format="#,##0.00" dataType="number"/>
                    <Column caption={tranDesc[5]} dataField="cr" format="#,##0.00" dataType="number"/>

                    <Column caption="Эцсийн үлдэгдэл" dataField="ed" format="#,##0.00" dataType="number"/>

                    <Summary>

                        <GroupItem
                            column="bd"
                            summaryType="sum"
                            showInGroupFooter={true}
                            customizeText={customizeDate}

                        />
                        <GroupItem
                            column="dt"
                            summaryType="sum"
                            showInGroupFooter={true}
                            customizeText={customizeDate}

                        />

                        <GroupItem
                            column="cr"
                            summaryType="sum"
                            showInGroupFooter={true}
                            customizeText={customizeDate}

                        />
                        <GroupItem
                            column="ed"
                            summaryType="sum"
                            showInGroupFooter={true}
                            customizeText={customizeDate}

                        />

                        <TotalItem
                            column="bd"
                            summaryType="sum"
                            customizeText={customizeDate}/>

                        <TotalItem
                            column="dt"
                            summaryType="sum"
                            customizeText={customizeDate}/>
                        <TotalItem
                            column="cr"
                            summaryType="sum"
                            customizeText={customizeDate}/>
                        <TotalItem
                            column="ed"
                            summaryType="sum"
                            customizeText={customizeDate}/>

                    </Summary>

                    <Export enabled={true} allowExportSelectedData={false}/>
                </DataGrid>
            </div>
        </React.Fragment>
    );
};

export default ApBalance;

import {
  Button,
  DataGrid,
  LoadIndicator,
  Lookup,
  TextBox,
} from "devextreme-react"
import React from "react"
import statsService from "../../services/api/stat.service"
import {AiOutlinePlus} from "react-icons/ai"
import {useState} from "react"
import Form, {
  SimpleItem,
  GroupItem,
  Label,
  ButtonItem,
} from "devextreme-react/form"
import {useEffect} from "react"
import {Column, GroupPanel, Grouping} from "devextreme-react/data-grid"
import notify from "devextreme/ui/notify"

function StatisticsInsert() {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [loading2, setLoading2] = useState(false)
  const [loading3, setLoading3] = useState(false)
  const [loading4, setLoading4] = useState(false)
  const [loading5, setLoading5] = useState(false)
  const [loading6, setLoading6] = useState(false)
  const [nsoThird, setNsoThird] = useState("")
  const [nsoSecond, setNsoSecond] = useState("")

  const setNsoDataThird = async () => {
    setLoading4(true)
    try {
      if (nsoThird === "") {
        notify("Tbl_id оруулна уу", "warning", 3000)
        return 0
      }
      let data = [{TBL_ID: nsoThird}]
      let result = await statsService.setNsoDataThird(data)
      if (result && result.message){
        return notify("NsoDataThird амжилттай татагдлаа", "success", 4000)
      }
    } catch (error) {
      setLoading4(false)
      notify(error.message, "error", 2000)
    } finally {
      setLoading4(false)
    }
  }
  const setNsoDataSecondOne = async () => {
    setLoading2(true)
    try {
      let result = await statsService.setNsoDataSecondOne()
      if (result && result.message){
        return notify("NsoDataSecondOne амжилттай татагдлаа", "success", 4000)
      }
    } catch (error) {
      notify(error.message, "error", 2000)
    } finally {
      setLoading2(false)
    }
  }
  const setNsoDataFourthOne = async () => {
    setLoading5(true)
    try {
      let result = await statsService.setNsoDataFourthOne()
      if (result && result.message){
        return notify("NsoDataFourthOne амжилттай татагдлаа", "success", 4000)
      }
    } catch (error) {
      notify(error.message, "error", 2000)
    } finally {
      setLoading5(false)
    }
  }
  const setNsoDataFourthSecond = async () => {
    setLoading6(true)
    try {
      let result = await statsService.setNsoDataFourthTwo()
      if (result && result.message){
        return notify("NsoDataFourthSecond амжилттай татагдлаа", "success", 4000)
      }
    } catch (error) {
      notify(error.message, "error", 2000)
    } finally {
      setLoading6(false)
    }
  }
  const setNsoDataFirst = async () => {
    setLoading(true)
    try {
      let result = await statsService.setNsoDataFirst()

      if (result && result.message){
        return notify("NsoDataFirst амжилттай татагдлаа", "success", 4000)
      }
    } catch (error) {
      notify(error.message, "error", 2000)
    } finally {
      setLoading(false)
    }
  }


  const setNsoDataSecondTwo = async () => {
    setLoading3(true)
    try {
      if (nsoSecond === "") {
        notify("Tbl_id оруулна уу", "warning", 3000)
        return 0
      }
      let result = await statsService.setNsoDataSecondTwo(nsoSecond)
      if (result && result.message){
        return notify("NsoDataSecondTwo амжилттай татагдлаа", "success", 4000)
      }
    } catch (error) {
      notify(error.message, "error", 2000)
    } finally {
      setLoading3(false)
    }
  }
  const handleValueChange1 = (e) => {
    setNsoThird(e.value)
  }
  const handleValueChange2 = (e) => {
    setNsoSecond(e.value)
  }

  return (
    <div className="card" style={{display: "flex", padding: "1rem", flexDirection: "column", gap: '1rem'}}>
    <h3>NSO data insertion</h3>
    <p>Олон удаа дарвал дата давхар орох магадлалтай тул амжилттай гэсэн мессежийг хүлээнэ үү</p>
    <div className="row">
        <div
          className="col-3"
          style={{
            display: "flex",
            justifyContent: "space-between",
            gap: "1rem",
            alignItems: "center",
          }}
        >
          <span style={{fontWeight: "bold"}}> NsoDataFirst татах: </span>
          <Button
            type="default"
            className="popupButton"
            onClick={setNsoDataFirst}
          >
            <span className="dx-button-text">
              <LoadIndicator
                style={{height: 20, width: 20}}
                visible={loading}
              />
                 {!loading && <span>Татах</span>}
            </span>
          </Button>
        </div>
      </div>
      <div className="row">
        <div
          className="col-3"
          style={{
            display: "flex",
            justifyContent: "space-between",
            gap: "1rem",
            alignItems: "center",
          }}
        >
          <span style={{fontWeight: "bold"}}> NsoDataSecondOne татах: </span>
          <Button
            type="default"
            className="popupButton"
            onClick={setNsoDataSecondOne}
          >
            <span className="dx-button-text">
              <LoadIndicator
                style={{height: 20, width: 20}}
                visible={loading2}
              />
                 {!loading2 && <span>Татах</span>}
            </span>
          </Button>
        </div>
      </div>
      <div className="row">
        <div
          className="col-12"
          style={{
            display: "flex",
            justifyContent: "space-between",
            gap: "1rem",
            alignItems: "center",
          }}
        >
          <span style={{fontWeight: "bold"}}> NsoDataSecondTwo татах: </span>
          <TextBox onValueChanged={handleValueChange2} style={{flex: 1}} />
          <Button
            type="default"
            className="popupButton"
            onClick={setNsoDataSecondTwo}
          >
            <span className="dx-button-text">
              <LoadIndicator
                style={{height: 20, width: 20}}
                visible={loading3}
              />
                 {!loading3 && <span>Татах</span>}
            </span>
          </Button>
        </div>
      </div>
      <div className="row">
        <div
          className="col-12"
          style={{
            display: "flex",
            justifyContent: "space-between",
            gap: "1rem",
            alignItems: "center",
          }}
        >
          <span style={{fontWeight: "bold"}}> NsoDataThird татах: </span>
          <TextBox onValueChanged={handleValueChange1} style={{flex: 1}} />
          <Button
            type="default"
            disabled={loading4}
            className="popupButton"
            onClick={setNsoDataThird}
          >
            <span className="dx-button-text">
              <LoadIndicator
                style={{height: 20, width: 20}}
                visible={loading4}
              />
                 {!loading4 && <span>Татах</span>}
            </span>
          </Button>
        </div>
      </div>
     
     
      <div className="row">
        <div
          className="col-3"
          style={{
            display: "flex",
            justifyContent: "space-between",
            gap: "1rem",
            alignItems: "center",
          }}
        >
          <span style={{fontWeight: "bold"}}> NsoDataFourthOne татах: </span>
          <Button
            type="default"
            className="popupButton"
            onClick={setNsoDataFourthOne}
          >
            <span className="dx-button-text">
              <LoadIndicator
                style={{height: 20, width: 20}}
                visible={loading5}
              />
                 {!loading5 && <span>Татах</span>}
            </span>
          </Button>
        </div>
      </div>
      <div className="row">
        <div
          className="col-3"
          style={{
            display: "flex",
            justifyContent: "space-between",
            gap: "1rem",
            alignItems: "center",
          }}
        >
          <span style={{fontWeight: "bold"}}> NsoDataFourthSecond татах: </span>
          <Button
            type="default"
            className="popupButton"
            onClick={setNsoDataFourthSecond}
          >
            <span className="dx-button-text">
              <LoadIndicator
                style={{height: 20, width: 20}}
                visible={loading6}
              />
                 {!loading6 && <span>Татах</span>}
            </span>
          </Button>
        </div>
      </div>
      
    </div>
  )
}

export default StatisticsInsert

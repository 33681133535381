import request from '../../shared/lib/request'


function create(newData) {
    return request({
        url: '/proposal/create',
        data: {...newData},
        method: 'POST'
    });
};
function listMinistry(year,org_id,status,selectedOrg) {
    return request({
        url: `/proposal/listMinistry?year=${year}&org_id=${org_id}&status=${status}&selectedOrg=${selectedOrg}`,
        method: 'get'
    });
};

function listConfirmedBudgetProject(year,bps,org_id) {
    return request({
        url: `/proposal/listConfirmedBudgetProject?year=${year}&bps=${bps}&org_id=${org_id}`,
        method: 'get'
    });
}

function statisticConfirmedBudgetProject(chosenDoc) {
    return request({
        url: `/proposal/statisticConfirmedBudgetProject/${chosenDoc}`,
        method: 'get'
    });
}

function statsOfGovAgenda(chosenDoc) {
    return request({
        url: `/proposal/statsOfGovAgenda/${chosenDoc}`,
        method: 'get'
    });
}

function deleteDetailBulk(data) {
    return request({
        url: '/proposal/detail/deleteBulk',
        data,
        method: 'POST'
    });
};

function saveProposalDetail(newData) {
    return request({
        url: '/proposal/saveProposalDetailBulk',
        data: {...newData},
        method: 'POST'
    });
};

function getProposalDetailByProjectIdSecond(projectId) {
    return request({
        url: `/proposal/detail/list/v2?filters.budget_project_id=${projectId}`,
        method: 'GET'
    });
};
function getProposalDetailByProjectId(projectId) {
    return request({
        url: `/proposal/detail/list?filters.budget_project_id=${projectId}`,
        method: 'GET'
    });
};
function listDetailMinistryOffer(year,org_id,selectedStatus,viewFormat,viewEcon,reportType,type) {
    return request({
        url: `/proposal/detail/listMinistryOffer?year=${year}&org_id=${org_id}&selectedStatus=${selectedStatus}&viewFormat=${viewFormat}&viewEcon=${viewEcon}&reportType=${reportType}&type=${type}`,
        method: 'GET'
    });
};
function getBudgetDetailedList(year,org_id,selectedStatus) {
    return request({
        url: `/proposal/detail/getBudgetDetailedList?year=${year}&org_id=${org_id}&selectedStatus=${selectedStatus}`,
        method: 'GET'
    });
};
function listDetailMinistry(year,org_id,selectedStatus,viewFormat,viewEcon,reportType) {
    return request({
        url: `/proposal/detail/listMinistry?year=${year}&org_id=${org_id}&selectedStatus=${selectedStatus}&viewFormat=${viewFormat}&viewEcon=${viewEcon}&reportType=${reportType}`,
        method: 'GET'
    });
};
function listDetailMinistryByOrg(orgId,year) {
    return request({
        url: `/proposal/detail/listMinistryByOrg?orgId=${orgId}&year=${year}`,
        method: 'GET'
    });
};

function getProposalDetailList(agendaId, eventId, projectId) {
    return request({
        url: `/proposal/detail/list?filters.agenda_id=${agendaId}&filters.event_id=${eventId}&filters.budget_project_id=${projectId}`,
        method: 'GET'
    });
};

function listVBudgetCompare(pYear,agendaId, eventId, org_id) {
    return request({
        url: `/proposal/vbudget/list/${pYear}?filters.agenda_id=${agendaId}&filters.event_id=${eventId}&filters.org_id=${org_id}`,
        method: 'GET'
    });
};
function listVBudgetCompareOrg(pYear,orgId) {
    return request({
        url: `/proposal/vbudget/list/${pYear}?filters.org_id=${orgId}`,
        method: 'GET'
    });
};
function listVBudgetCompareEza(pYear,orgId) {
    return request({
        url: `/proposal/vbudget/listEza/${pYear}?filters.org_id=${orgId}`,
        method: 'GET'
    });
};
function listFiltered(year) { // gets the budget_project by org_id
    return request({
        url: `/proposal/listFiltered/${year}`,
        method: 'GET'
    });
};

function getDefaultProposal(year) { // gets the budget_project by org_id
    return request({
        url: `/proposal/getDefaultProposal/${year}`,
        method: 'GET'
    });
};

function submitProposal(proposalId) {
    return request({
        url: `/proposal/submitById`,
        data: {id: proposalId},
        method: 'POST'
    });
};

function setProposalStatus(proposalId, action, org_id) {
    return request({
        url: `/proposal/setById/${org_id}`,
        data: {id: proposalId, action: action},
        method: 'POST'
    });
};

function setCommunication(data, proposal_org_id) {
    return request({
        url: `/proposal/setCommunication/${proposal_org_id}`,
        data: data,
        method: 'POST'
    });
};

function getCommunication(projectId) {
    return request({
        url: `/proposal/communication/list?filters.budget_project_id=${projectId}`,
        method: 'GET'
    });
};

function getProposals(year) {
    return request({
        url: `/proposal/list?filters.budget_year=${year}`,
        method: 'GET'
    });
};

function getProposalById(id) {
    return request({
        url: `/proposal/list?filters.id=${id}`,
        method: 'GET'
    });
};

function uploadFiles(newData) {
    return request({
        url: '/proposal/upload',
        headers: {
        },
        data: newData,
        method: 'POST'
    });
};

function uploadProfile(newData) {
    return request({
        url: '/proposal/uploadProfile',
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        data: newData,
        method: 'POST'
    })
}

function deleteFile(id) {
    return request({
        url: '/proposal/deleteFile',
        data: id,
        method: 'POST'
    })
}

function getFileList(projectId) {
    return request({
        url: `/proposal/getFileList?filters.budget_project_id=${projectId}`,
        method: 'GET'
    });
};

function getProgressProposal(year, status){
    return request(({
        url: `proposal/getProgressProposal/${year}/${status}`,
        method: 'GET'
    }))
};

function getProjectProgress(minister, year) {
    if (minister.org_id) {
        return request(({
            url: `proposal/getProjectProgress?ministry=${minister.type}&org_id=${minister.org_id}&year=${year}&selectedStatus=${minister.selectedStatus}`,
            method: 'GET'
        }))
    } else if (minister.budget_project_status_id) {
        return request(({
            url: `proposal/getProjectProgress?ministry=${minister.type}&budget_project_status_id=${minister.budget_project_status_id}&year=${year}&selectedStatus=${minister.selectedStatus}`,
            method: 'GET'
        }))
    } else if (minister.org_add){
        return request(({
            url: `proposal/getProjectProgress?ministry=${minister.type}&org_add=${minister.org_add}&org_add_id=${minister.org_add_id}&year=${year}&selectedStatus=${minister.selectedStatus}`,
            method: 'GET'
        }))
    } else {
        return request(({
            url: `proposal/getProjectProgress?ministry=${minister}&year=${year}`,
            method: 'GET'
        }))
    }
}
function getStatusCount(year) {
    return request(({
        url: `proposal/statusCount?year=${year}`,
        method: 'GET'
    }))
}

function getOrgListsByStatus(year, status){
    return request({
        url: `proposal/getOrgListsByStatus/${year}/${status}`,
        method: `GET`
    })
}




function setGovAgenda(data) {
    return request(({
        url: `/proposal/setGovAgenda`,
        data: data,
        method: 'POST'
    }))
}

function getGovAgenda(projectId, agendaId) {
    return request(({
        url: `/proposal/getGovAgenda?filters.budget_project_id=${projectId}&filters.agenda_id=${agendaId}`,
        method: 'GET'
    }))
}
function getHistory(proposalId) {
    return request(({
        url: `/proposal/getHistory/${proposalId}`,
        method: 'GET'
    }))
}

function getCureType() {
    return request(({
        url: `/proposal/getCureType`,
        method: 'GET'
    }))
}

function saveProposalLoan(newData) {
    return request({
        url: '/proposal/saveProposalLoan',
        data: {...newData},
        method: 'POST'
    });
};

function getProposalLoanByProjectId(projectId) {
    return request({
        url: `/proposal/loan/detail/list?filters.budget_project_id=${projectId}`,
        method: 'GET'
    });
};

function getProposalListByList(listId) { // gets the budget_project by org_id
    return request({
        url: `/proposal/getProposalListByList`,
        method: 'GET'
    });
};

function getBudgetProject(projectId) {
    return request({
        url: `/proposal/loan/getBudgetProject/list?filters.id=${projectId}`,
        method: 'GET'
    });
};

function getOrganizationByForiegn() {
    return request({
        url: `/proposal/loan/getOrganizationByForiegn`,
        method: 'GET'
    });
};

function uploadWorkerFile(newData) {
    return request({
        url: '/fileServices/uploadWorkerFile',
        headers: {
        },
        data: newData,
        method: 'POST'
    });
};

function getWorkerFile(id) {
    return request({
        url: `/fileServices/getWorkerFile?filters.id=${id}`,
        method: 'GET'
    });
};

function getFileById(id) {
    return request({
        url: `/fileServices/getFile/${id}`,
        method: 'GET',
    })
}

function getBudgetProjectStatus() {
    return request(({
        url: `/proposal/getBudgetProjectStatus`,
        method: 'GET'
    }))
}

function getBudgetProjectByYearAll() {
    return request(({
        url: `/proposal/getBudgetProjectByYearAll`,
        method: 'GET'
    }))
}

const getOrgHead = () => {
    return request(({
        url:'/proposal/getOrgHead',
        method: 'GET'
    }))
}

function getChartDetailByYear(orgId,agendaId,eventId,econCode,year) {
    //agendaId 0 bol buh hotolboroor eventId 0 bol buh zoriulaltaar
    return request(({
        url: `/proposal/getChartDetailByYear/${orgId}/${agendaId}/${eventId}/${econCode}?year=${year}`,
        method: 'GET'
    }))
}
function getProjectProposalOrg(year, status){
    return request(({
        url: `/proposal/getProjectProposalOrg/${year}/${status}`,
        method: 'GET'
    }))
}
function getProposalCalcEza(orgId,year){
    return request(({
        url: `/proposal/getProposalCalcEza/${orgId}/${year}`,
        method: 'GET'
    }))
}
function editProposalCalcEza(data) {
    return request(({
        url: `/proposal/editProposalCalcEza`,
        data: data,
        method: 'POST'
    }))
}
function geBudgetProjectDetailHistory(projectId){
    return request(({
        url: `/proposal/geBudgetProjectDetailHistory/${projectId}`,
        method: 'GET'
    }))
}
const planOfferProjectStatusList = (org_id,year)=> {
    return request({
        url: `/proposal/planOfferProjectStatusList?org_id=${org_id}&year=${year}`,
        method:'GET'
    });
}
function getProposalMergeOrgBox(year,status) {
    return request({
        url: `/proposal/getProposalMergeOrgBox?year=${year}&status=${status}`,
        method: 'GET'
    });
};
function getProposalReportFiscal(year,status,selectedOrg) {
    return request({
        url: `/proposal/getProposalReportFiscal?year=${year}&status=${status}&selectedOrg=${selectedOrg}`,
        method: 'GET'
    });
};

const ProposalService = {
    create,
    getProposalById,
    listMinistry,
    saveProposalDetail,
    getProposalDetailList,
    getProposalDetailByProjectId,
    getProposalDetailByProjectIdSecond,
    listVBudgetCompare,
    listVBudgetCompareOrg,
    listVBudgetCompareEza,
    submitProposal,
    listFiltered,
    getDefaultProposal,
    getProgressProposal,
    getProjectProgress,
    listDetailMinistry,
    listDetailMinistryByOrg,
    setProposalStatus,
    setCommunication,
    getCommunication,
    getProposals,
    uploadFiles,
    getFileList,
    getStatusCount,
    getOrgListsByStatus,
    setGovAgenda,
    deleteFile,
    getGovAgenda,
    deleteDetailBulk,
    getHistory,
    getCureType,
    saveProposalLoan,
    getProposalLoanByProjectId,
    getProposalListByList,
    getBudgetProject,
    getOrganizationByForiegn,
    uploadProfile,
    statisticConfirmedBudgetProject,
    listConfirmedBudgetProject,
    uploadWorkerFile,
    getWorkerFile,
    statsOfGovAgenda,
    getFileById,
    getBudgetProjectStatus,
    getBudgetProjectByYearAll,
    getOrgHead,
    getChartDetailByYear,
    getProjectProposalOrg,
    getProposalCalcEza,
    editProposalCalcEza,
    listDetailMinistryOffer,
    getBudgetDetailedList,
    geBudgetProjectDetailHistory,
    planOfferProjectStatusList,
    getProposalMergeOrgBox,
    getProposalReportFiscal
}

export default ProposalService;

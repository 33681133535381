import request from '../../shared/lib/request'

const getAllAgenda = () => {
    return request({
        url: '/agenda/getAllAgenda',
        method: 'GET',
    })
}

const addAgenda = (body) => {
    return request({
        url: '/agenda/addAgenda',
        method: 'POST',
        data: body,
    })
}
const editAgenda = (body) => {
    return request({
        url: `/agenda/EditAgenda`,
        method: 'POST',
        data: body,
    })
}
const removeAgenda = (body) => {
    return request({
        url: '/agenda/RemoveAgenda',
        method: 'POST',
        data: body,
    })
}
const agendaServices = {
    getAllAgenda,
    addAgenda,
    editAgenda,
    removeAgenda
}

export default agendaServices;
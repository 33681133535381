import React, { useState, useEffect, useRef, useContext } from "react";
import DataGrid, {
  Editing,
  Popup,
  Column,
  Form,
  FilterRow,
  Pager,
  Paging,
  Lookup,
} from 'devextreme-react/data-grid';
import { Item } from "devextreme-react/form"
import "devextreme-react/text-area"
import notify from "devextreme/ui/notify";
import feedbackServices from '../../services/api/feedback'
import LoadPanelComponent from '../../components/LoadPanelComponent'
import FeedbackStatus from "./components/FeedbackStatus";
import FeedbackType from "./components/FeedbackType";
import orgPositionServices from "../../services/api/orgPosition";
import { UserContext } from "../../hooks/UserContext";
import { Button } from "devextreme-react";
import ShowLog from "./components/ShowLog";


const editorOptions = {disabled: false}
const inputHeight = { height: '11rem' }

export default function Feedbacks() {

  const fbRef = useRef(null)
  const [feedbacks, setFeedbacks] = useState([]);
  const [status, setStatus] = useState([])
  const [feedbackType, setFeedbackType] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [orgList, setOrgList] = useState({})
  const [fbId, setFbId] = useState(0)
  const [popupVisible, setPopupVisible] = useState(false)
  const { user } = useContext(UserContext)

  const currentOrgId = user.org_id



  const getAllFeedbacks = async () => {
    try {
      setIsLoading(true)
      const res = await feedbackServices.getAllFeedback();

      // console.log(res.data);
      if (res && res.code === 200) {
        setFeedbacks(res.data);
      }
      setIsLoading(false)
    } catch (e) {
      console.log(e);
      setIsLoading(false)
    }
  }
  const getOrgList = async () => {
    try {
      const r = await orgPositionServices.getOrgListByParent()
      setOrgList(r.data)
    } catch (e) {
      console.log(e);
    }
  }

  const getAllStatus = async () => {
    try {
      setIsLoading(true)
      const res = await feedbackServices.getAllStatus();
      if (res && res.code === 200) {
        if(user.roles[0].role_id === 0){
          const filter = res.data.filter(item => item.id !== 2)
          setStatus(filter);
        } else {
          setStatus(res.data);
        }
      }
      setIsLoading(false)
    } catch (e) {
      console.log(e);
      setIsLoading(false)
    }
  }


  const getAllFeedbackType = async () => {
    try {
      setIsLoading(true)
      const res = await feedbackServices.getAllFeedbackType();
      if (res && res.code === 200) {
        setFeedbackType(res.data);
      }
      setIsLoading(false)
    } catch (e) {
      console.log(e);
      setIsLoading(false)
    }
  }

  const onFinish = async (e) => {
    // console.log("onFinish called");
    // console.log("e:", e);
    // console.log("eeee:", currentOrgId);
    if (e.changes && e.changes.length > 0) {
      if (e.changes[0].type === 'update') {
        try {
          setIsLoading(true)
          if (e.changes[0].data.feedback_status === 2 && e.changes[0].data.org_id !== currentOrgId) {
            let fb = {
              org_id: currentOrgId,
              feedback_status: e.changes[0].data.feedback_status,
              feedback_type_id: e.changes[0].data.feedback_type_id,
              question: e.changes[0].data.question,
              comment: e.changes[0].data.comment,
              user_id: e.changes[0].data.user_id,
              nu_name: e.changes[0].data.nu_name,
              nu_email: e.changes[0].data.nu_email,
              from_org_name: e.changes[0].data.from_org_name,
              is_org: e.changes[0].data.is_org,
              createdAt: e.changes[0].data.createdAt,
              updatedAt: e.changes[0].data.updatedAt
            }

            const res = await feedbackServices.editFeedback(fb, e.changes[0].key)
            if (res.data[1][0].user_id !== null) {
              await feedbackServices.feedbackResponse(res.data[1][0])
            }

            await feedbackServices.createLog(res.data[1][0].id, { comment: res.data[1][0].comment, feedback_status: res.data[1][0].feedback_status, transferred_org_id: e.changes[0].data.org_id })

            const newFb = await feedbackServices.createFeedback({
              org_id: e.changes[0].data.org_id,
              feedback_status: 1,
              feedback_type_id: e.changes[0].data.feedback_type_id,
              question: e.changes[0].data.question,
              comment: e.changes[0].data.comment,
              user_id: e.changes[0].data.user_id,
              nu_name: e.changes[0].data.nu_name,
              nu_email: e.changes[0].data.nu_email,
              from_org_name: e.changes[0].data.from_org_name,
              is_org: e.changes[0].data.is_org
            })
            await feedbackServices.createLog(newFb.data.id, { comment: newFb.data.comment, feedback_status: newFb.data.feedback_status })

            const result1 = await feedbackServices.passedFbNotif(newFb.data)
            if (result1 && result1.length > 0) {
              await feedbackServices.sendEmail(result1.data)
            }

            if (res && res.code === 200) {
              await getAllFeedbacks();
              setIsLoading(false);
              notify(
                {
                  message: "Хариуцсан байгууллага руу амжилттай илгээлээ",
                  width: 400,
                },
                "success",
                2500
              )
            }
          } else {
            if (e.changes[0].data.feedback_status === 2 && e.changes[0].data.org_id === currentOrgId) {
              setIsLoading(false);
              getAllFeedbacks()
              notify({
                message: "Өөрийнхөө байгууллага руу шилжүүлэх боломжгүй",
                width: 500,
              }, "warning", 3500);
              return;
            } else {
              const res1 = await feedbackServices.editFeedback(e.changes[0].data, e.changes[0].key)
              if (res1.data[1][0].user_id !== null) {
                await feedbackServices.feedbackResponse(res1.data[1][0])
              }
              await feedbackServices.createLog(res1.data[1][0].id, { comment: res1.data[1][0].comment, feedback_status: res1.data[1][0].feedback_status })

              if (res1 && res1.code === 200) {
                await getAllFeedbacks();
                setIsLoading(false);
                notify(
                  {
                    message: "Төлөв амжилттай өөрчлөгдлөө",
                    width: 400,
                  },
                  "success",
                  2500
                )
              }

            }

          }

        }
        catch (err) {
          // console.log(err);
          setIsLoading(false);
          notify({
            message: "Төлөв өөрчилхөд алдаа гарлаа",
            width: 400,
          },
            "warning",
            2500)
        }
      }

    }
  };

  const handleOnCellPrepared = (e) => {

    if (e.rowType === "header") {
      e.cellElement.style.cssText = 'color: #15186A; background-color: #EEF0F4; text-align: center; font-family: Montserrat'
    }
    if (e.rowType === "data") {
      e.cellElement.style.cssText = "color: black; text-align: center; font-family: Montserrat"
    }
  }
  useEffect(() => {
    getAllFeedbacks();
    getOrgList()
    getAllStatus();
    getAllFeedbackType()
  }, []);

  const cstmText = (e) => {
    return `<span style="color: #fff; font-weight: bold">${e.value} - ${(e.percent * 100).toFixed(2)}%</span>`
  }

  const customizeTooltip = (arg) => {
    return {
      text: `${arg.argumentText}: ${arg.value} <br/> ${(
        arg.percent * 100
      ).toFixed(2)}%`,
    }
  }

  const splitter = (str, index) => {
    const result = [str.slice(0, index), str.slice(index)]
    return result
  }

  const setStateValue = async (newData, value, currentRowData) => {
    newData.feedback_status = value
  }
  const setOrgValue = async (newData, value, currentRowData) => {
    newData.org_id = value
  }

  const customizeItem = (item) => {
    const dataGrid = fbRef.current.instance;
    const editRowKey = dataGrid.option('editing.editRowKey');
    const rowIndex = dataGrid.getRowIndexByKey(editRowKey);
    const changeDoctype = dataGrid.cellValue(rowIndex, "feedback_status");
    const dataSource = dataGrid.option('dataSource')
    const currentRowData = dataSource.filter((data) => data.id === editRowKey)

    if (item && changeDoctype === 2) {
      if (item.dataField === "org_id") {
        item.visible = true;
      }
    }
    if(item && currentRowData[0].feedback_status === 1 || currentRowData[0].feedback_status === 3){
      if(item.dataField === "org_id" || item.dataField === "feedback_status" || item.dataField === "comment"){
        item.disabled = false
      }
    }
    if(item && currentRowData[0].feedback_type_id === 3){
         if(item.dataField === "org_id" || item.dataField === "feedback_status" || item.dataField === "comment"){
        item.disabled = true
      }
    }
  }

  const showLog = (e) =>{
    const showPopup = async () => {
      setFbId(e?.key);
      const res = await feedbackServices.getLog(e?.key)
      if(res && res.code === 200){

      }
      setPopupVisible(true);
    }

  return <>
    <Button style={{border: 'none'}} icon="edit" onClick={() => fbRef.current.instance.editRow(e.rowIndex)}></Button>
    <Button style={{border: 'none'}} icon="eyeopen" onClick={showPopup}></Button>
  </>
  }

  return (
    <React.Fragment>
      <div className="card">
        <LoadPanelComponent position={{ of: '#dataGrid' }} visible={isLoading} />
        <div style={{ display: "table", width: "100%", padding: "1rem" }}>
          <h3 style={{ float: "left" }}>Санал хүсэлтүүд</h3>
        </div>
        <div className="card-body">
          <DataGrid
            ref={fbRef}
            columnAutoWidth={true}
            id="dataGrid"
            keyExpr="id"
            onSaved={onFinish}
            scrolling={true}
            dataSource={feedbacks}
            showBorders={true}
            showRowLines={true}
            showColumnLines={true}
            hoverStateEnabled={true}
            style={{ flexWrap: "wrap" }}
            noDataText="Мэдэгдэл байхгүй байна."
            // focusedRowEnabled={true}
            wordWrapEnabled={true}
            onCellPrepared={handleOnCellPrepared}
            summary={{
              totalItems: [{ column: "feedback_type_id", summaryType: "count", displayFormat: "Нийт: {0}" }]
            }}
          >
            <FilterRow visible={true} />

            <Paging
              defaultPageSize={10}
              defaultPageIndex={0} />

            <Pager
              showPageSizeSelector={false}
              visible={true}
              showNavigationButtons={true} />
            <Editing
              mode="popup"
              useIcons="edit"
              // allowUpdating={false}
              texts={{
                cancelRowChanges: 'Болих',
                saveRowChanges: 'Хадгалах'
            }}
            >
              <Popup title="Санал хүсэлтүүд удирдах" showTitle={true} width={700} height={600} />
              <Form customizeItem={customizeItem}>
                <Item editorOptions={editorOptions} itemType="group" colCount={1} colSpan={2}>
                  <Item disabled={true} dataField="question" editorOptions={inputHeight} editorType="dxTextArea" />
                  <Item disabled={true} dataField="feedback_status"/>
                  <Item disabled={true} dataField="org_id" visible={false}  />
                  <Item disabled={true} dataField="comment" editorOptions={inputHeight} editorType="dxTextArea" />
                </Item>
              </Form>
            </Editing>

            <Column
              caption="№"
              width='3rem'
              allowSorting={false}
              cellRender={(data) => {
                const adjustedIndex = data.rowIndex + 1 + data.component.pageIndex() * data.component.pageSize();
                return <span>{adjustedIndex}</span>;
              }}
            />

            <Column
              allowEditing={false}
              dataField="feedback_type_id"
              caption="Төрөл"
              alignment="center"
            >
              <Lookup dataSource={feedbackType} valueExpr="id" displayExpr="type_name" />
            </Column>

            <Column
              allowEditing={false}
              dataField={"nu_name"}
              caption="Хэрэглэгчийн нэр"
              dataType="string"
              alignment="center"
            />
            <Column
              allowEditing={false}
              dataField={"nu_email"}
              caption="Хэрэглэгчийн и-мэйл"
              dataType="string"
              alignment="center"
            />
            <Column
              allowEditing={false}
              dataField="from_org_name"
              caption="Байгууллагын нэр"
              dataType="string"
              alignment="center"
              customizeText={(e) => {
                if (e.value === null) {
                  return "хувь хүн"
                } else {
                  return e.value
                }
              }}
            />
            <Column
              allowEditing={false}
              dataField="question"
              caption="Агуулга"
              dataType="string"
              alignment="center"
              customizeText={(e) => {
                if (e.value.length > 30) {
                  return e.value.slice(0, 35) + "..."
                } else {
                  return e.value
                }
              }}
            />
            <Column
              allowEditing={false}
              dataField="createdAt"
              caption="Илгээсэн огноо"
              dataType="date"
              alignment="center"
              format="yyyy-MM-dd"
              width='6rem'
            />
            <Column
              allowEditing={false}
              dataField="updatedAt"
              caption="Шийдвэрлэсэн огноо"
              dataType="date"
              alignment="center"
              format="yyyy-MM-dd"
              width='6rem'
            />
            <Column
              allowEditing={true}
              dataField='feedback_status'
              caption="Төлөв"
              alignment="center"
              setCellValue={setStateValue}
              cellRender={(data) => {
                const statusId = data.value
                let bgColor;
                let color
                switch (statusId) {
                  case 1:
                    bgColor = ' #CDEBFF'
                    color = 'black'
                    break
                  case 2:
                    bgColor = '#EBF1FF'
                    color = '#5575C2'
                    break
                  case 3:
                    bgColor = '#FFEEDF'
                    color = '#F99D4B'
                    break
                  case 4:
                    bgColor = '#E2FBEE'
                    color = '#50C687'
                    break
                  case 5:
                    bgColor = '#FFF2F2'
                    color = '#F66160'
                    break
                  default:
                    bgColor = ''
                    break
                }
                return (
                  <div style={{ backgroundColor: bgColor, color: color, fontWeight: 'bold', padding: '5px', borderRadius: '5px' }}>{data.text}</div>
                )
              }}
            >

              <Lookup dataSource={status} valueExpr="id" displayExpr="status_name" />
            </Column>
            <Column dataField="org_id" setCellValue={setOrgValue} visible={false} caption='Байгууллагын нэр'>
              <Lookup dataSource={orgList} valueExpr="id" displayExpr="organization_name" />
            </Column>
            <Column
              allowSorting={false}
              dataField="comment"
              caption="Тэмдэглэл"
              alignment="center"
              visible={false}
            />
            <Column
              dataField="id"
              caption="Үйлдэл"
              width={'7rem'}
              type="button"
              allowSorting={false}
              alignment="center"
              cellRender={showLog}
            >

            </Column>
          </DataGrid>
        </div>
      </div>
      {popupVisible && (
                <ShowLog
                    popupVisible={popupVisible}
                    setPopupVisible={setPopupVisible}
                    fbId={fbId}
                />
            )}
      <div className="responsive-flex" >
        <div className="responsive-col col-6">
          <FeedbackType
            splitter={splitter}
            cstmText={cstmText}
            feedbacks={feedbacks}
            customizeTooltip={customizeTooltip}
          />
        </div>
        <div className="responsive-col col-6">
          <FeedbackStatus
            feedbacks={feedbacks}
            splitter={splitter}
            customizeTooltip={customizeTooltip}
          />
        </div>
      </div>
    </React.Fragment>
  );
}

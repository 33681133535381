import {difference} from "lodash"
import React, {useEffect} from "react"
import {BiTime} from "react-icons/bi"
import {useHistory} from "react-router-dom"
import {urlServer} from "../../../shared/lib/request"

function LatestNewsCard({createdAt, news_title, id, setSelected, file_name}) {
  const navigate = useHistory()

  const directPage = () => {
    navigate.replace(`/article/${id}`)
  }

 const getTimeDifference = (string) => {

    let date = new Date(createdAt).getTime()
    const seconds = Math.floor(date / 1000)
    const oldTimesStamp = Math.floor( new Date().getTime()/1000)
    const difference = oldTimesStamp- seconds 

    if (difference < 60) {
      return `${difference} секундын өмнө`
    } else if (difference < 3600) {
      return `${Math.floor(difference / 60)} минутын өмнө`
    } else if (difference < 86400) {
      return `${Math.floor(difference / 3600)} цагийн өмнө`
    } else if (difference < 2620800) {
      return `${Math.floor(difference / 86400)} өдрийн өмнө ago`
    } else if (difference < 31449600) {
      return `${Math.floor(difference / 2620800)} сарын өмнө`
    } else {
      return `${Math.floor(difference / 31449600)} жилийн өмнө`
    }
  }
  return (
    <div className="latestNews" onClick={directPage}>
      {/* <div className="img-container">
        {file_name && (
          <img src={`${urlServer}/api/file/${file_name}`} alt="bg" />
        )}
      </div> */}
      <div className="text-container">
        <div>
          {news_title.substring(0, 80)}
          {news_title?.length > 80 && "..."}
        </div>
        <p>
          <BiTime />
          {getTimeDifference()}
        </p>
      </div>
    </div>
  )
}

export default LatestNewsCard

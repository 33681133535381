import React, { useContext, useRef, useState } from "react"
import { formatDate, numberWithCommas } from "../../../../util"
import { DataGrid } from "devextreme-react"
import Button from "devextreme-react/button";
import { Column, ColumnChooser, ColumnFixing, Export, GroupItem, Grouping, Item, Summary, Toolbar, TotalItem } from "devextreme-react/data-grid"
import _ from "lodash"
import spinner from "../../../../assets/imgs/tube-spinner.svg"
import { UserContext } from "../../../../hooks/UserContext"
import moment from "moment";
import { Workbook } from "exceljs";
import { exportDataGrid } from "devextreme/excel_exporter";
import saveAs from "file-saver"

const BudgetDetailedList = ({ statusName, mainData, type, year, chosenOrganization }) => {
  const [expand, setExpand] = useState(false);
  const { user } = useContext(UserContext)
  const gridData = useRef([])
  const data = mainData;

  const sumFields = (items) => ({
    add_budget_amt: _.sumBy(items, 'add_budget_amt'),
    amount: _.sumBy(items, 'amount'),
    current_amt: _.sumBy(items, 'current_amt'),
    current_budget_amt: _.sumBy(items, 'current_budget_amt'),
    hyazgaar_amount: _.sumBy(items, 'hyazgaar_amount'),
    prev2_amt: _.sumBy(items, 'prev2_amt'),
    prev2_budget_amt: _.sumBy(items, 'prev2_budget_amt'),
    prev3_amt: _.sumBy(items, 'prev3_amt'),
    prev3_budget_amt: _.sumBy(items, 'prev3_budget_amt'),
    state_confirmed_amount: _.sumBy(items, 'state_confirmed_amount'),
    tez_amount: _.sumBy(items, 'tez_amount'),
    tosoolol_1_amt: _.sumBy(items, 'tosoolol_1_amt'),
    tosoolol_2_amt: _.sumBy(items, 'tosoolol_2_amt'),
    ttz_amount: _.sumBy(items, 'ttz_amount'),
  });

  if (type === 'eza') {
    const groupedData = _.groupBy(data, item => `${item.econ_category_name_1}|${item.econ_category_name_2}|${item.econ_category_name_3}|${item.econ_category_name_4}|${item.econ_name}`);
    const res = _.map(groupedData, (items, key) => {
      const [econ_category_name_1, econ_category_name_2, econ_category_name_3, econ_category_name_4, econ_name] = key.split('|');
      return {
        econ_category_name_1,
        econ_category_name_2,
        econ_category_name_3,
        econ_category_name_4,
        econ_name,
        ...sumFields(items)
      };
    });
    gridData.current = res;
  } else if (type === 'orlogo') {
    const filtered = _.filter(data, ['econ_category_name_1', '1-Нийт орлого ба тусламжийн дүн'])
    const groupedData = _.groupBy(filtered, chosenOrganization === 'ALL' ? 'org_name_2' : 'org_name');
    const summedData = _.map(groupedData, (items, key) => {
      return {
        org_name: key,
        ...sumFields(items)
      };
    });
    gridData.current = summedData;
  } else if (type === 'agendaEvent') {
    const filtered = _.filter(data, ['econ_category_name_1', '2-Нийт зарлага ба цэвэр зээлийн дүн'])
    const groupedData = _.groupBy(filtered, item => `${item.agenda_name}-${item.event_name}`);
    const res = _.map(groupedData, (items, key) => {
      const [agenda_name, event_name] = key.split('-');
      return {
        agenda_name,
        event_name,
        ...sumFields(items)
      };
    });
    gridData.current = res
  } else if (type === 'ursgal_org') {
    const filtered = _.filter(data, ['econ_category_name_2', '21-Урсгал зардал'])
    gridData.current = filtered;
  } else if (type === 'hurungu_org') {
    const filtered = _.filter(data, ['econ_category_name_2', '22-Хөрөнгийн зардал'])
    gridData.current = filtered;
  } else if (type === 'ergen_tulugduh') {
    const filtered = _.filter(data, ['econ_category_name_2', '23-Эргэж төлөгдөх төлбөрийг хассан цэвэр зээл'])
    gridData.current = filtered;
  }

  const groupCell = (e) => {
    return <div style={{ color: '#000', fontWeight: 700 }}>{e.data.displayValue}</div>
  }

  const onCellPreparedHandler = (e) => {
    const lastRow = e.component.getVisibleRows().length;
    const yearArr = ["hyazgaar_amount", "add_budget_amt", "amount", "ttz_amount", "tez_amount", "tosoolol_1_amt"];
    let elem = e.cellElement.style
    elem.setProperty('cursor', 'pointer', 'important');
    if (e.rowType === "header") {
      elem.fontWeight = "bold"
      elem.color = "#15186A"
      elem.backgroundColor = "#EEF0F4"
      elem.textAlign = 'center'
    } else if (e.rowType === 'group') {
      e.column.caption = ``
      elem.color = "#15186A"
      elem.borderColor = "#D4DCEC"
      elem.backgroundColor = "#fff"
      if(!yearArr.includes(e.column.dataField)){
        e.cellElement.classList.add('temp')
      }
    } else if (e.rowType === 'data' && e.columnIndex === 0) {
      elem.fontWeight = "bold"
      elem.color = "#000"
      elem.borderColor = "#D4DCEC"
    }
    if (e.rowType !== 'totalFooter') {
      if (e.cellElement.className === 'yearCapt') {
        elem.boxShadow = 'inset 0 3px 3px -3px #2c77ff, inset 3px 0 3px -3px #2c77ff, inset -3px 0 3px -3px #2c77ff'
      } else if (e.cellElement.className === 'hyazgaar_amount') {
        elem.boxShadow = 'inset 3px 0 3px -3px #2c77ff'
      } else if (e.cellElement.className === 'tosoolol_1_amt' || (type === 'ergen_tulugduh' && e.column.dataField === 'tez_amount') || e.cellElement.className === 'ergen_tulugduh') {
        elem.boxShadow = 'inset -3px 0 3px -3px #2c77ff'
      }
    }
    if (e.rowIndex === lastRow - 1) {
      elem.borderBottom = '2px solid rgba(44, 119, 255, 0.25)'
      if (!yearArr.includes(e.column.dataField)) {
        elem.borderBottom = 'transparent'
      }
    }
  }

  const onRowPrepared = (args) => {
    if (args.rowType === 'group' && (args.values[args.groupIndex] === "" || args.values[args.groupIndex] == null || args.values[args.groupIndex] === 'null')) {
      args.rowElement.hidden = true;
      if (!args.component.isRowExpanded(args.key))
        args.component.expandRow(args.key)
    }
  }
  const renderHeader = () => {
    switch (type) {
      case 'eza':
        return 'ЭДИЙН ЗАСГИЙН АНГИЛЛААР'
      case 'orlogo':
        return 'НИЙТ ОРЛОГО'
      case 'ursgal_org':
        return 'УРСГАЛ ЗАРДАЛ'
      case 'hurungu_org':
        return 'ХӨРӨНГИЙН ЗАРДАЛ'
      case 'ergen_tulugduh':
        return 'ЭРГЭЖ ТӨЛӨГДӨХ ЦЭВЭР ЗЭЭЛ'
      case 'agendaEvent':
        return 'ХӨТӨЛБӨР, АРГА ХЭМЖЭЭ'
      default:
        break;
    }
  }

  const onExporting = (e) => {
    const workbook = new Workbook()
    const worksheet = workbook.addWorksheet("Main sheet")
    const fieldArr = ['econ_category_name_1', 'econ_category_name_2', 'econ_category_name_3', 'econ_category_name_4', 'econ_name', 'org_name', 'org_name_2', 'agenda_name', 'event_name']
    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
      keepColumnWidths: true,
      topLeftCell: { row: 8, column: 2 },
      customizeCell: ({ gridCell, excelCell }) => {
        excelCell.font = { name: 'Arial', size: 8 }
        if (gridCell.rowType === 'header') {
          console.log(gridCell)
          excelCell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'd9d9d9' } };
          excelCell.font = { color: { argb: '00000' }, name: 'Arial', size: 8, 'bold': true };
        }
        if (gridCell.rowType === 'group') {
          excelCell.alignment = { wrapText: false };
          excelCell.font = { name: 'Arial', size: 8, 'bold': true }
          excelCell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '63C1FE' } };
        }
        if (gridCell.rowType === 'totalFooter' && excelCell.value) {
          excelCell.font.italic = true;
        }
        if (gridCell.rowType === "data") {
          excelCell.border = {
            top: { style: "thin", color: { argb: "2c2c2c" } },
            left: { style: "thin", color: { argb: "2c2c2c" } },
            bottom: { style: "thin", color: { argb: "2c2c2c" } },
            right: { style: "thin", color: { argb: "2c2c2c" } },
          }
        }
        if (fieldArr.includes(gridCell.column.dataField)) {
          if (excelCell._row && excelCell._row._cells && excelCell._row._cells[1].model) {
            if (excelCell._row._cells[1].model.value) {
              excelCell._row._cells[1].model.value = excelCell._row._cells[1].model.value.replace(": ", "");
            }
            if (excelCell._row._cells[1].model.value === '' || excelCell._row._cells[1].model.value === null || excelCell._row._cells[1].model.value === 'null') {
              excelCell._row.hidden = true;
            }
          }
        }
      },
    })
      .then(() => {
        Object.assign(worksheet.getRow(1).getCell(1), {
          value: 'Гарчиг:',
          font: { name: 'Arial', size: 8 },
        })
        Object.assign(worksheet.getRow(1).getCell(2), {
          value: `${year} оны Төсвийн төсөл - ${renderHeader()}`,
          font: { name: 'Arial', size: 8, 'bold': true, color: { argb: '0070c0' } },
        })
        Object.assign(worksheet.getRow(2).getCell(1), {
          value: 'Төлөв:',
          font: { name: 'Arial', size: 8 },
        })
        Object.assign(worksheet.getRow(2).getCell(2), {
          value: statusName,
          font: { name: 'Arial', size: 8 },
        })
        Object.assign(worksheet.getRow(3).getCell(1), {
          value: 'Байгууллага:',
          font: { name: 'Arial', size: 8 },
        })
        Object.assign(worksheet.getRow(3).getCell(2), {
          value: user.organization.name,
          font: { name: 'Arial', size: 8 },
        })
        Object.assign(worksheet.getRow(4).getCell(1), {
          value: 'Хэрэглэгч:',
          font: { name: 'Arial', size: 8 },
        })
        Object.assign(worksheet.getRow(4).getCell(2), {
          value: `${user.last_name.slice(0, 1)}. ${user.first_name}, ${user.position_name}`,
          font: { name: 'Arial', size: 8 },
        })
        Object.assign(worksheet.getRow(5).getCell(1), {
          value: 'Огноо:',
          font: { name: 'Arial', size: 8 },
        })
        Object.assign(worksheet.getRow(5).getCell(2), {
          value: new moment().locale('mn').format('lll'),
          font: { name: 'Arial', size: 8 },
        })
      })
      .then(() => {
        workbook.xlsx.writeBuffer().then((buffer) => {
          saveAs(
            new Blob([buffer], { type: "application/octet-stream" }),
            `${year} оны Төсвийн төсөл - ${renderHeader()} -` + formatDate(new Date()) + ".xlsx"
          )
        })
      })
    e.cancel = true
  }

  const calculateCustomSummary = (e) => {
    if (e.name === 'agendaSummary') {
      if (e.summaryProcess === 'start') {
        e.totalValue = new Set();
      } else if (e.summaryProcess === 'calculate') {
        e.totalValue.add(e.value.agenda_name);
      } else if (e.summaryProcess === 'finalize') {
        e.totalValue = e.totalValue.size;
      }
    } else if (e.name === 'ursgalSummary') {
      if (e.summaryProcess === 'start') {
        e.totalValue = new Set();
      } else if (e.summaryProcess === 'calculate') {
        if (chosenOrganization === 'ALL' && user.organization.obt_id === 1 && !expand) {
          if (e.value.org_name_2) {
            e.totalValue.add(e.value.org_name_2);
          }
        } else {
          if (e.value.org_name) {
            e.totalValue.add(e.value.org_name);
          }
        }
      } else if (e.summaryProcess === 'finalize') {
        e.totalValue = e.totalValue.size;
      }
    } else if (e.name === 'hurunguSummary') {
      if (e.summaryProcess === 'start') {
        e.totalValue = new Set();
      } else if (e.summaryProcess === 'calculate') {
        if (chosenOrganization === 'ALL' && user.organization.obt_id === 1 && !expand) {
          if (e.value.org_name_2) {
            e.totalValue.add(e.value.org_name_2);
          }
        } else {
          if (e.value.org_name) {
            e.totalValue.add(e.value.org_name);
          }
        }
      } else if (e.summaryProcess === 'finalize') {
        e.totalValue = e.totalValue.size;
      }
    }
  }

  return (
    <div>
      <DataGrid
        dataSource={gridData.current}
        noDataText='Дата байхгүй байна.'
        wordWrapEnabled={true}
        id="Taniltsuulga"
        scrolling={true}
        showRowLines={true}
        showBorders={true}
        showColumnLines={true}
        onCellPrepared={onCellPreparedHandler}
        onRowPrepared={onRowPrepared}
        hoverStateEnabled={true}
        loadPanel={{ enabled: true, text: '', showPane: false, indicatorSrc: spinner }}
        columnAutoWidth={false}
        allowColumnResizing={true}
        columnResizingMode=''
        paging={{
          pageSize: 20,
        }}
        twoWayBindingEnabled={true}
        sorting={{ mode: 'none' }}
        showColumnHeaders={true}
        onExporting={onExporting}
      >
        <Export enabled={true} allowExportSelectedData={false} texts={{ exportAll: 'Excel файл татах' }} />
        <Grouping contextMenuEnabled={false}
          autoExpandAll={expand}
          expandMode="rowClick"
          texts={{
            groupContinuesMessage: 'Дараагийн хуудас',
            groupContinuedMessage: 'Өмнөх хуудас'
          }}
        />
        <ColumnChooser emptyPanelText="Хоосон" enabled={true} height={300} allowSearch={true} search={true}
          mode="select"
          title={'Багана сонгох5'} />
        <ColumnFixing enabled={false} />
        <Toolbar>
          <Item location="after" visible={type !== 'ergen_tulugduh' && type !== 'orlogo'}>
            <div><Button icon={expand ? 'collapse' : 'expand'} onClick={() => setExpand(prev => !prev)} hint={expand ? 'Хураах' : 'Дэлгэх'} /></div>
          </Item>
          <Item name="exportButton" widget="dxButton" options={{ icon: "xlsxfile" }} />
          <Item name="columnChooserButton"
            render={(e) => {
              return <Button onClick={e.options.onClick} hint="Багана сонгох" size='1rem' id='columnChooserButton' icon="columnchooser" />
            }}
          />
        </Toolbar>
        {type === 'eza' && <Column dataField="econ_category_name_1" groupIndex={0} caption="" groupCellComponent={groupCell} visibleIndex={0} showInColumnChooser={false} />}
        {type === 'eza' && <Column dataField="econ_category_name_2" groupIndex={1} caption="" groupCellComponent={groupCell} visibleIndex={1} showInColumnChooser={false} />}
        {type === 'eza' && <Column dataField="econ_category_name_3" groupIndex={2} caption="" groupCellComponent={groupCell} visibleIndex={2} showInColumnChooser={false} />}
        {type === 'eza' && <Column dataField="econ_category_name_4" groupIndex={3} caption="" groupCellComponent={groupCell} visibleIndex={3} showInColumnChooser={false} />}
        {type === 'eza' && <Column dataField="econ_name" caption="ЭЗА" width={300} visibleIndex={4} fixed={true} allowResizing={false} showInColumnChooser={false}/>}

        {type === 'orlogo' && <Column dataField="org_name" caption="Байгууллага" width={300} showInColumnChooser={false}/>}

        {type === 'ursgal_org' && chosenOrganization === 'ALL' && user.organization.obt_id === 1 && <Column dataField="org_name_2" caption="" groupIndex={0} groupCellComponent={groupCell} showInColumnChooser={false} />}
        {type === 'ursgal_org' && <Column dataField="org_name" caption="" groupIndex={1} groupCellComponent={groupCell} showInColumnChooser={false} />}
        {type === 'ursgal_org' && <Column dataField="econ_category_name_1" caption="" groupIndex={2} groupCellComponent={groupCell} showInColumnChooser={false} />}
        {type === 'ursgal_org' && <Column dataField="econ_category_name_2" caption="" groupIndex={3} groupCellComponent={groupCell} showInColumnChooser={false} />}
        {type === 'ursgal_org' && <Column dataField="econ_category_name_3" caption="" groupIndex={4} groupCellComponent={groupCell} showInColumnChooser={false} />}
        {type === 'ursgal_org' && <Column dataField="econ_category_name_4" caption="" groupIndex={5} groupCellComponent={groupCell} showInColumnChooser={false} />}
        {type === 'ursgal_org' && <Column dataField="econ_name" caption="Урсгал зардал" width={300} showInColumnChooser={false}/>}

        {type === 'hurungu_org' && chosenOrganization === 'ALL' && user.organization.obt_id === 1 && <Column dataField="org_name_2" caption="" groupIndex={0} showInColumnChooser={false} groupCellComponent={groupCell} />}
        {type === 'hurungu_org' && <Column dataField="org_name" caption="" groupIndex={1} showInColumnChooser={false} groupCellComponent={groupCell} />}
        {type === 'hurungu_org' && <Column dataField="econ_category_name_1" caption="" groupIndex={2} showInColumnChooser={false} groupCellComponent={groupCell} />}
        {type === 'hurungu_org' && <Column dataField="econ_category_name_2" caption="" groupIndex={3} showInColumnChooser={false} groupCellComponent={groupCell} />}
        {type === 'hurungu_org' && <Column dataField="econ_category_name_3" caption="" groupIndex={4} showInColumnChooser={false} groupCellComponent={groupCell} />}
        {type === 'hurungu_org' && <Column dataField="econ_category_name_4" caption="" groupIndex={5} showInColumnChooser={false} groupCellComponent={groupCell} />}
        {type === 'hurungu_org' && <Column dataField="econ_name" caption="Хөрөнгийн зардал" width={300} showInColumnChooser={false}/>}

        {type === 'ergen_tulugduh' && <Column dataField="org_name" caption="Байгууллага" width={300} showInColumnChooser={false} />}

        {type === 'agendaEvent' && <Column dataField="agenda_name" caption="" groupIndex={0} groupCellComponent={groupCell} showInColumnChooser={false} />}
        {type === 'agendaEvent' && <Column dataField="event_name" caption="Хөтөлбөр, арга хэмжээ" width={300} showInColumnChooser={false} />}
        <Column caption={`${year - 3}`} minWidth={200} visible={false}>
          <Column caption="Төсөв" dataField="prev3_budget_amt" customizeText={(e) => numberWithCommas(e.value)} />
          <Column caption="Гүйцэтгэл" dataField="prev3_amt" customizeText={(e) => numberWithCommas(e.value)} />
        </Column>
        <Column caption={`${year - 2}`} minWidth={200}>
          <Column caption="Төсөв" dataField="prev2_budget_amt" customizeText={(e) => numberWithCommas(e.value)} />
          <Column caption="Гүйцэтгэл" dataField="prev2_amt" customizeText={(e) => numberWithCommas(e.value)} />
        </Column>
        <Column caption={`${year - 1}`} minWidth={200}>
          <Column caption="Төсөв" dataField="current_budget_amt" customizeText={(e) => numberWithCommas(e.value)} />
          <Column caption="Гүйцэтгэл" dataField="current_amt" customizeText={(e) => numberWithCommas(e.value)} />
        </Column>
        <Column caption={`${year}`} cssClass="yearCapt">
          <Column caption="Батлагдсан хязгаар" dataField="hyazgaar_amount" cssClass="hyazgaar_amount" customizeText={(e) => numberWithCommas(e.value)} />
          {type !== 'ergen_tulugduh' && <Column caption="Нэмэх санал" dataField="add_budget_amt" customizeText={(e) => numberWithCommas(e.value)} />}
          <Column caption="Төсвийн санал" cssClass={type === 'ergen_tulugduh' && 'ergen_tulugduh'}>
            <Column caption="ТШЗ" dataField="amount" customizeText={(e) => numberWithCommas(e.value)} />
            <Column caption="ТТЗ" dataField="ttz_amount" customizeText={(e) => numberWithCommas(e.value)} />
            <Column caption="ТЕЗ" dataField="tez_amount" customizeText={(e) => numberWithCommas(e.value)} />
          </Column>
          {type !== 'ergen_tulugduh' && <Column caption={`${year + 1} оны төсөөлөл`} dataField="tosoolol_1_amt" cssClass="tosoolol_1_amt" customizeText={(e) => numberWithCommas(e.value)} />}
        </Column>
        <Summary calculateCustomSummary={calculateCustomSummary}>
          <GroupItem column="prev3_budget_amt" summaryType="sum" alignByColumn={true} customizeText={(e) => numberWithCommas(e.value)} />
          <GroupItem column="prev3_amt" summaryType="sum" alignByColumn={true} customizeText={(e) => numberWithCommas(e.value)} />
          <GroupItem column="prev2_budget_amt" summaryType="sum" alignByColumn={true} customizeText={(e) => numberWithCommas(e.value)} />
          <GroupItem column="prev2_amt" summaryType="sum" alignByColumn={true} customizeText={(e) => numberWithCommas(e.value)} />
          <GroupItem column="current_budget_amt" summaryType="sum" alignByColumn={true} customizeText={(e) => numberWithCommas(e.value)} />
          <GroupItem column="current_amt" summaryType="sum" alignByColumn={true} customizeText={(e) => numberWithCommas(e.value)} />
          <GroupItem column="hyazgaar_amount" summaryType="sum" alignByColumn={true} customizeText={(e) => numberWithCommas(e.value)} />
          <GroupItem column="add_budget_amt" summaryType="sum" alignByColumn={true} customizeText={(e) => numberWithCommas(e.value)} />
          <GroupItem column="amount" summaryType="sum" alignByColumn={true} customizeText={(e) => numberWithCommas(e.value)} />
          <GroupItem column="ttz_amount" summaryType="sum" alignByColumn={true} customizeText={(e) => numberWithCommas(e.value)} />
          <GroupItem column="tez_amount" summaryType="sum" alignByColumn={true} customizeText={(e) => numberWithCommas(e.value)} />
          <GroupItem column="tosoolol_1_amt" summaryType="sum" alignByColumn={true} customizeText={(e) => numberWithCommas(e.value)} />

          {type !== 'eza' && <TotalItem column="prev3_budget_amt" summaryType="sum" alignByColumn={true} customizeText={(e) => numberWithCommas(e.value)} />}
          {type !== 'eza' && <TotalItem column="prev3_amt" summaryType="sum" alignByColumn={true} customizeText={(e) => numberWithCommas(e.value)} />}
          {type !== 'eza' && <TotalItem column="prev2_budget_amt" summaryType="sum" alignByColumn={true} customizeText={(e) => numberWithCommas(e.value)} />}
          {type !== 'eza' && <TotalItem column="prev2_amt" summaryType="sum" alignByColumn={true} customizeText={(e) => numberWithCommas(e.value)} />}
          {type !== 'eza' && <TotalItem column="current_budget_amt" summaryType="sum" alignByColumn={true} customizeText={(e) => numberWithCommas(e.value)} />}
          {type !== 'eza' && <TotalItem column="current_amt" summaryType="sum" alignByColumn={true} customizeText={(e) => numberWithCommas(e.value)} />}
          {type !== 'eza' && <TotalItem column="hyazgaar_amount" summaryType="sum" alignByColumn={true} customizeText={(e) => numberWithCommas(e.value)} />}
          {type !== 'eza' && <TotalItem column="add_budget_amt" summaryType="sum" alignByColumn={true} customizeText={(e) => numberWithCommas(e.value)} />}
          {type !== 'eza' && <TotalItem column="amount" summaryType="sum" alignByColumn={true} customizeText={(e) => numberWithCommas(e.value)} />}
          {type !== 'eza' && <TotalItem column="ttz_amount" summaryType="sum" alignByColumn={true} customizeText={(e) => numberWithCommas(e.value)} />}
          {type !== 'eza' && <TotalItem column="tez_amount" summaryType="sum" alignByColumn={true} customizeText={(e) => numberWithCommas(e.value)} />}
          {type !== 'eza' && <TotalItem column="tosoolol_1_amt" summaryType="sum" alignByColumn={true} customizeText={(e) => numberWithCommas(e.value)} />}

          {type === 'orlogo' && <TotalItem column="org_name" summaryType="count" displayFormat="Нийт {0} байгууллага" />}
          {type === 'ursgal_org' && <TotalItem name="ursgalSummary" summaryType="custom" displayFormat="Нийт {0} байгууллага" showInColumn="econ_name" />}
          {type === 'hurungu_org' && <TotalItem name="hurunguSummary" summaryType="custom" displayFormat="Нийт {0} байгууллага" showInColumn="econ_name" />}
          <TotalItem name="agendaSummary" summaryType="custom" displayFormat="Нийт {0} хөтөлбөр" showInColumn="event_name" />
        </Summary>
      </DataGrid>
    </div>
  )
}

export default BudgetDetailedList;
import React, {useContext} from "react"
import {useEffect} from "react"
import statsService from "../../../services/api/stat.service"
import {useState} from "react"
import {
  DataGrid,
  FileUploader,
  Popup,
  ScrollView,
  LoadIndicator,
} from "devextreme-react"
import {Column} from "devextreme-react/data-grid"
import styled from "styled-components"
import _ from "lodash"
import {UserContext} from "../../../hooks/UserContext"
import {Button as DevExtremeButton} from "devextreme-react/button"
import {ProjectButton} from "../../proposal"
import Form, {
  Item as FormItem1,
  Label,
  RequiredRule,
} from "devextreme-react/form"
import {GrDocumentUpload} from "react-icons/gr"
import notify from "devextreme/ui/notify"
import {urlServer} from "../../../shared/lib/request"

const TableRow = styled.div`
  display: flex;
  padding: 1rem;
  background-color: ${(props) => (props.index % 2 === 0 ? "#f8fbff" : "white")};
  font-weight: 600;
  align-items: center;
  > div {
    text-align: left;
    display: flex;
    justify-content: center;
  }
  > div:nth-child(1) {
    width: 5rem;
  }
  > div:nth-child(2) {
    flex: 1;
  }
  > div:nth-child(3) {
    width: calc(50% - 5rem);
  }
  > div:nth-child(4) {
    width: 3rem;
  }
`
let orderedList = [
  "Тодорхойлолт",
  "Аргачлал, арга зүйн нэр",
  "Тооцох аргачлал",
  "Үзүүлэлтийг тооцох давтамж",
  "Хэмжих нэгж",
  "Мэдээлэл шинэчилсэн хугацаа",
  "Эх сурвалж",
]

function StatisticFooter({list_id, tbl_id}) {
  const [metaData, setMetaData] = useState(null)
  const [popupVisible, setPopupVisible] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [multiple, setMultiple] = useState([])
  const [fileType, setFileType] = useState(1)
  const [fileStatistic, setFileStatistic] = useState(null)
  const {user} = useContext(UserContext)
  const isAdmin = _.find(user.roles, function (o) {
    return o.role_code === "2" || o.role_code === "1" || o.role_code === "0"
  })

  useEffect(() => {
    if (list_id && tbl_id) {
      getChosenTblData()
    }
  }, [list_id, tbl_id])
  useEffect(() => {
    fetchMetaFileData()
  }, [])

  const fetchMetaFileData = async () => {
    try {
      const result = await statsService.getFileStatistic(tbl_id, fileType)
      if (result?.code === 200) {
        setFileStatistic(result?.data?.file)
      }
    } catch (error) {
      console.log("error", error)
    }
  }
  const getChosenTblData = async () => {
    try {
      let {data} = await statsService.getMetaData(list_id, tbl_id)
      if (data?.length) {
        let obj = data?.[0]
        let keyValueArray = Object.keys(obj)
          .map((key) => ({
            name: key,
            value: obj[key],
          }))
          .filter(Boolean)
        keyValueArray.sort((a, b) => {
          return (
            orderedList.findIndex((i) => i === a?.name) -
            orderedList.findIndex((i) => i === b?.name)
          )
        })
        setMetaData([...keyValueArray, {name: "Маягт", value: null}])
      }
    } catch (error) {
      console.log("error is", error)
    }
  }

  // const customCellRender = e =>{
  //   return e.rowIndex + 1+"."
  // }
  
  const ImageUploader = () => {
    return (
      <div style={{}}>
        <FileUploader
          // allowedFileExtensions={allowedFileExtensions}
          onValueChanged={(e) => setMultiple(e.value)}
          selectButtonText="Файлууд сонгох"
          multiple={false}
          value={multiple}
          labelText=""
          accept="application/pdf"
          uploadMode="useForm"
        />
        <div className="note" style={{marginTop: ".25rem"}}>
          {"Файлын өргөтгөл: "}
          <span>.pdf</span>
        </div>
      </div>
    )
  }
  const handleAddPhoto = async () => {
    setIsLoading(true)
    try {
      if (multiple?.length === 0) {
        notify(`Файл оруулна уу!`, "warning", 3000)
        return 0
      }
      if (multiple?.[0]?.type !== "application/pdf") {
        notify(`PDF өргөтгөлтэй файл оруулна уу!`, "warning", 3000)
        return 0
      }

      let data = new FormData()
      data.append("file", multiple[0])
      const res = await statsService.uploadStatisticFile(data, tbl_id, fileType)
      if (res?.code === 200) {
        setMultiple([])
        setPopupVisible(false)
        fetchMetaFileData()
        return notify(res.message, "success", 4000)
      }
    } catch (error) {
      console.log("error", error)
      setIsLoading(false)
      return notify("Хадгалах үйлдэл амжилтгүй боллоо", "warning", 3000)
    } finally {
      setIsLoading(false)
    }
  }
  const renderAddPhoto = () => {
    return (
      <Popup
        visible={popupVisible}
        onHiding={() => setPopupVisible(false)}
        hideOnOutsideClick={true}
        showCloseButton
        height="auto"
        showTitle
        title={"Тушаал оруулах"}
        width="30rem"
        id="renderaddCategoryMenu"
      >
        <ScrollView height="100%" width="100%">
          <div className="widget-container">
        <Form>
              <FormItem1 render={ImageUploader}>
                <Label text="Файл оруулах" />
                <RequiredRule message="Зураг оруулна уу!" />
              </FormItem1>
            </Form>
            <DevExtremeButton
              icon="plus"
              text={"Нийтлэх"}
              type="default"
              style={{marginTop: "2rem"}}
              className="saveButton"
              onClick={() => handleAddPhoto()}
            >
              <span className="dx-button-text">
                <LoadIndicator
                  style={{height: 20, width: 20}}
                  visible={isLoading}
                />
                {!isLoading && <span>Хадгалах</span>}
              </span>
            </DevExtremeButton>
          </div>
        </ScrollView>
      </Popup>
    )
  }

  const FileUploadCell = (index) => {
    let file_type_id = index?.type === 1 ? 1 : 2
    return (
      <ProjectButton
        onClick={() => {
          setFileType(file_type_id)
          setPopupVisible(true)
        }}
      >
        <GrDocumentUpload />
      </ProjectButton>
    )
  }
  return (
    <>
      {renderAddPhoto()}
      <div
        className="card"
        style={{display: "flex", flexDirection: "column", padding: "1rem 10px"}}
      >
        <div style={{fontWeight: 700, paddingBottom: "1rem"}}>
          ЛАВЛАХ МЭДЭЭЛЭЛ
        </div>
        {metaData?.map((item, index) => (
          <TableRow index={index}>
            <div>
              <div>{index + 1}.</div>
            </div>
            <div>
              <div>
                {index === 1 && fileStatistic ? (
                  <a href={urlServer + "/api/file/" + fileStatistic?.file_name}>
                    <ProjectButton
                      hint={"Тушаал харах"}
                    >
                      <span style={{fontWeight: 600}}>{item.name}</span>
                    </ProjectButton>
                  </a>
                ) : (
                  item.name
                )}
              </div>
            </div>
            <div>
              <div>{item.value}</div>
            </div>
            <div>
              <div>
                {isAdmin && index === 1 && <FileUploadCell type={index} />}
                {isAdmin && index === 7 && <FileUploadCell type={index} />}
              </div>
            </div>
          </TableRow>
        ))}
        {/* <DataGrid dataSource={metaData} rowAlternationEnabled showColumnHeaders={false}>
          <Column alignment={"center"} cellRender={customCellRender} width={40}/>
          <Column alignment={"center"} dataField={"name"}/>
          <Column alignment={"center"} dataField={"value"} />
          {isAdmin && <Column cellRender={FileUploadCell} width={70}/>}
      </DataGrid> */}
        {/* <div
          className="row"
          style={{
            display: "flex",
            alignItems: "center",
            padding: ".5rem 0rem",
            marginTOp: "1rem",
            justifyContent: "center",
          }}
        >
          <span style={{color: "#2E4765", fontWeight: "bold"}}>
            Эх сурвалж:
          </span>
          <span style={{marginLeft: ".25rem", fontWeight: 500}}>
            Үндэсний Статистикийн хороо{" "}
            <a href="https://1212.mn/" target="blank">
              1212.mn
            </a>
          </span>
        </div> */}
      </div>
    </>
  )
}

export default StatisticFooter

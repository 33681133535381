import React, {Component, useEffect, useState} from "react";
import TabPanel, { Item } from 'devextreme-react/tab-panel';
import Purchase from "./Purchase";
import EditPurchase from "./EditPurchase";
import ApReport from "./report/ApReport";
import fcsServices from "../../../services/api/fcs";
import Apselect from "./apselect";
import {FaChartLine} from 'react-icons/fa'
import {RiLineChartFill} from 'react-icons/ri'
import { BiLineChart, BiLineChartDown } from "react-icons/bi";
import { BsGear } from "react-icons/bs";
import { CgFileDocument } from "react-icons/cg";
import CustomTabComponent from "../../../components/CustomTabComponent";

const  AccountPaymentTab= () =>  {
    const [yearValue, setYearValue] = useState(null);
    const [monthValue, setMonthValue] = useState(null);
    const iconStyle= {
        color: "#1C3BC2", fontWeight: "600", fontSize: "1.2rem", transform: "translateY(.2rem)", marginRight: ".25rem"
    }

    useEffect( () => {
        goMonth()
    } ,[]
    )

    const goMonth = async () => {
        const value = await fcsServices.getAllFcsMonth('AP');
        setMonthValue(value[0].month);
        setYearValue(value[0].year);
    }

        return (
        <div>
            <div className={"bankbook"}>
                <CustomTabComponent 
                    list={["Худалдан авалт", "Тайлан"]}
                    children={[
                        <EditPurchase/>, 
                        <ApReport goMonth={goMonth} />]}
                        titleWidth="15rem"
                />
            </div>
        </div>
        )


}


export default AccountPaymentTab
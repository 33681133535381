import React, {useState, useEffect, useContext} from "react"
import DataGrid, {
  Column,
  FilterRow,
  HeaderFilter,
  Paging,
  Summary,
  TotalItem,
} from "devextreme-react/data-grid"
import notify from "devextreme/ui/notify"
import BudgetService from "../../../services/api/budget"
import _ from "lodash"
import {numberWithCommas} from "../../../util"
import {UserContext} from "../../../hooks/UserContext"
import LoadPanelComponent from "../../../components/LoadPanelComponent"
import EmptyData from "../../budget/components/empty"

const UpdatedBudgetListWithOrgs = (props) => {
  const [dataSrc, setDataSrc] = useState([])
  const [sumVal, setSumVal] = useState(0)
  const [loader, setLoader] = useState(true)
  const {user} = useContext(UserContext)
  const currentColor = () => {
    const type = props.currentType
    switch (type) {
      case 2:
        return "#4F957D"
      case 1:
        return "#FB3938"
      case 3:
        return "#225175"
    }
  }
  const loadYamsData = async () => {
    try {
      let r = await BudgetService.getYamsData(
        props.currentYear,
        props.currentType
      )
      let sum = _.sumBy(r, "amount")
      setSumVal(sum)
      setDataSrc(r)
    } catch (e) {
      notify(e.message, "error", 2000)
    } finally {
      setLoader(false)
    }
  }

  const calcCellVal = (e) => {
    return ((e.amount * 100) / sumVal).toFixed(2)
  }

  const cellTemp = (cellElement, cellInfo) => {
    return cellElement.append(cellInfo.row.loadIndex + 1)
  }

  useEffect(() => {
    loadYamsData()
  }, [props.currentYear, props.currentType])

  const rowPreparedHandler = (e) => {
    if (
      e.data &&
      e.data.start_org_id === user.start_of_ancestry
    )
    {
      e.rowElement.style.border = `2px solid ${currentColor()}`
      e.rowElement.style.color = "#000"
      e.rowElement.style.fontWeight = "bolder"
    } /*else if (
      user.start_of_ancestry === 144 &&
      e.data &&
      e.data.name === "Хүнс, хөдөө аж ахуй, хөнгөн үйлдвэрийн сайд"
    ) {
      e.rowElement.style.border = `2px solid ${currentColor()}`
      e.rowElement.style.color = "#000"
      e.rowElement.style.fontWeight = "bolder"
    } */
    else if (e.data && user.organization.name === e.data.name) {
      e.rowElement.style.border = `2px solid ${currentColor()}`
      e.rowElement.style.color = "#000"
      e.rowElement.style.fontWeight = "bolder"
    } else if (e.data && user.organization.name.toUpperCase() === e.data.name) {
      e.rowElement.style.border = `2px solid ${currentColor()}`
      e.rowElement.style.color = "#000"
      e.rowElement.style.fontWeight = "bolder"
    } /*else if (
      user.start_of_ancestry === 642 &&
      e.data &&
      e.data.name === "Нийслэлийн засаг дарга"
    ) {
      e.rowElement.style.border = `2px solid ${currentColor()}`
      e.rowElement.style.color = "#000"
      e.rowElement.style.fontWeight = "bolder"
    }*/
  }
  const onCellPreparedHandler = (e) => {
    if (e.rowType === "header") {
      e.cellElement.wordWrapEnabled = true
      e.cellElement.style.fontFamily = "Segoe UI"
      e.cellElement.style.fontWeight = "bold"
      e.cellElement.style.color = "#15186A"
      e.cellElement.style.backgroundColor = "#EEF0F4"
    }
    if (e.rowType === "data") {
      e.cellElement.style.fontFamily = "Segoe UI"
      e.cellElement.style.fontWeight = "semibold"
      e.cellElement.style.color = "#000000"
    }
  }

  if (dataSrc?.length === 0 && !loader) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          family: "Segoe UI",
        }}
      >
        <EmptyData/>
      </div>
    )
  }
  return (
    <div id={`datagrid-${props.currentType}`}>
      <LoadPanelComponent
        position={{of: `#datagrid-${props.currentType}`}}
        visible={loader}
      />
      <DataGrid
        showBorders={true}
        rowAlternationEnabled={true}
        dataSource={dataSrc}
        onRowPrepared={rowPreparedHandler}
        noDataText="Дата байхгүй байна."
        onCellPrepared={onCellPreparedHandler}
        style={{
          fontFamily: "Segoe UI",
          fontWeight: "semibold",
        }}
      >
        <Paging defaultPageSize={9} />
        <FilterRow visible={true} />
        <HeaderFilter visible={true} />
        <Column
          caption="№"
          allowSorting={false}
          cellTemplate={cellTemp}
          alignment="center"
          width={40}
        />
        <Column
          dataField="name"
          allowSorting={false}
          caption="Байгууллага"
          alignment="left"
          minWidth={100}
        />
        <Column caption="Төсөв" alignment="center">
          <Column
            dataField="amount"
            allowSorting={true}
            format="#,##0.00"
            defaultSortOrder="desc"
            alignment="center"
            caption="Дүн"
            allowFiltering={false}
            width={120}
          />
          <Column
            caption="Хувь"
            allowSorting={true}
            calculateCellValue={calcCellVal}
            alignment="center"
            width={65}
          />
        </Column>

        <Summary>
          <TotalItem
            column="amount"
            summaryType="sum"
            customizeText={(data) => {
              return `${numberWithCommas(data.value)}`
            }}
          />
        </Summary>
      </DataGrid>
    </div>
  )
}

export default UpdatedBudgetListWithOrgs

import React, {useState} from "react";
import '../css/styles.css'

const ControlSectionOne = (props) => {
    const [image, setImage] = useState(null);
    // const img = props.data.filter(item => item.name === 'logo');
    // console.log('props.data -> ', props.data);

    return (
        <div>
            <div className="row">
                <div className="col-2" style={{ position: 'relative' }}>
                    <img className="custom-centering" alt={'logo-img'} src={props.data[10].value} style={{ height: '100%' }}/>
                </div>
                <div className="col-10">
                    <p><i className="dx-icon-tel"></i><span style={{ fontWeight: 'bold' }}>{props.data[25].value}</span></p>
                    <p><i className="dx-icon-email"></i>{props.data[4].value}</p>
                    <p><i className="dx-icon-globe"></i>{props.data[27].value}</p>
                    <p><i className="dx-icon-map"></i>{props.data[2].value}</p>
                </div>
            </div>
        </div>
    )
}

export default ControlSectionOne
import React, { useState, useEffect, useRef, useContext } from 'react'
import DataGrid, {
    Column,
    Summary,
    TotalItem
} from "devextreme-react/data-grid";
import _ from 'lodash'
import CitizenFeedbackService from '../../../services/api/citizenFeedback';
import { numberWithCommas } from '../../../util';
import LoadPanelComponent from '../../../components/LoadPanelComponent';
import { Button } from 'devextreme-react';
import notify from 'devextreme/ui/notify';


const CreateFeedbackProposal = (props) => {
    const { currentYear } = props

    const dataGridRef = useRef(null)
    const [projectData, setProjectData] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [hide, setHide] = useState(false)
    const [data, setData] = useState([])


    useEffect(() => {
        getBudgetByAgenda()
        isCreated()
    }, [currentYear])


    const getBudgetByAgenda = async () => {
        try{
            setIsLoading(true)
            const r = await CitizenFeedbackService.getBudgetByAgenda(currentYear)
            setProjectData(r.data)
            setIsLoading(false)
        } catch(err){
            setIsLoading(false)
            console.log("err");
        }
    }
    const isCreated = async () => {
        try{
            const r = await CitizenFeedbackService.createdCitizenProposal()
            setData(r.data)
        }catch(err){
            console.log("err");
        }
    }
   
    const cellPrepared = (e) => {
        if (e.rowType === "data" && e.columnIndex === 1 ) {
            e.cellElement.style.verticalAlign = 'middle'
        }
        if(e.rowType === "data" && e.rowIndex === 1){
            // console.log(e);
        }
        if (e.rowType === "header") {
            e.cellElement.style.verticalAlign = 'middle'
            e.cellElement.style.textAlign = 'center'
            e.cellElement.style.backgroundColor = '#EEF0F4'
            e.cellElement.style.fontWeight = 'bold'
        }
    }
    const CreateProposal = async () => {
        try{
            if((props.start_date && props.end_date) !== null){
                
                let data = {
                    start_date: props.start_date,
                    end_date: props.end_date
                }
                const res = await CitizenFeedbackService.createCitizenProposal(data)
                if(res.code === 200){
                    notify("Амжилттай үүсгэлээ", "success", 2000)
                    setHide(true)
                }
            }
        } catch(err){
            notify(err.message, "error", 2000)
        }
    }

    return (
        <div>
            <LoadPanelComponent visible={isLoading} position={{ of: '#CreateCFTable' }} />
            <div style={{ padding: 10 }}>
                <DataGrid
                    ref={dataGridRef}
                    height="100%"
                    id="CreateCFTable"
                    dataSource={projectData}
                    keyExpr="id"
                    key='id'
                    showBorders={true}
                    hoverStateEnabled={true}
                    showRowLines={true}
                    showColumnLines={true}
                    showColumnHeaders={true}
                    allowColumnResizing={true}
                    allowColumnReordering={true}
                    onCellPrepared={cellPrepared}
                    columnAutoWidth={true}
                    >
                    <Column
                        caption="№"
                        allowSorting={false}
                        allowFiltering={true}
                        alignment='center'
                        width={'2rem'}
                        cellRender={(data) => {
                            const adjustedIndex = data.rowIndex + 1 + data.component.pageIndex() * data.component.pageSize();
                            return <span>{adjustedIndex}</span>;
                        }}
                    />
                    <Column
                        dataField="agenda_name"
                        caption="Дэд хөтөлбөр"
                    >
                    </Column>
                    <Column
                        dataField="amount"
                        caption={`ТШЗ санал`}
                        alignment='end'
                        customizeText={(e)=>{
                            return numberWithCommas(e.value)
                        }}
                    />
                    <Column
                        dataField="ttz_amount"
                        caption={`ТТЗ санал`}
                        alignment='end'
                        customizeText={(e)=>{
                            return numberWithCommas(e.value)
                        }}
                    />
                    <Column
                        dataField="tez_amount"
                        caption={`ТЕЗ санал`}
                        alignment='end'
                        customizeText={(e)=>{
                            return numberWithCommas(e.value)
                        }}
                    />
                    <Summary>
                        <TotalItem summaryType="sum" column="amount" customizeText={(e)=>{ return numberWithCommas(e.value)}}/>
                        <TotalItem summaryType="sum" column="ttz_amount" customizeText={(e)=>{ return numberWithCommas(e.value)}}/>
                        <TotalItem summaryType="sum" column="tez_amount" customizeText={(e)=>{ return numberWithCommas(e.value)}}/>
                        <TotalItem column="agenda_name" displayFormat="Нийт" />
                    </Summary>
                </DataGrid>
                <div style={{marginTop: 10, textAlign: 'end'}}>
                    <Button
                     disabled={currentYear < new Date().getFullYear() || data.length> 0 ? true : false}
                      onClick={CreateProposal} type='success' style={{backgroundColor: "#50c687", fontWeight: 'bold'}} text='Үүсгэх' width={120}/>
                </div>
            </div>
        </div>
    )
}

export default CreateFeedbackProposal
import React, { useContext, useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router-dom"
import { UserContext } from "../../../hooks/UserContext"
import { urlServer } from "../../../shared/lib/request"
import {
  getYears,
  numberWithCommas,
} from "../../../util/index"
import LoadPanelComponent from "../../../components/LoadPanelComponent"
import ProposalService from "../../../services/api/proposal"
import _ from "lodash"
import { DataGrid, Popup, ScrollView, SelectBox } from "devextreme-react"
import notify from "devextreme/ui/notify";
import { Column, Grouping, GroupItem, HeaderFilter, Scrolling, Summary, TotalItem } from "devextreme-react/data-grid"
import { FaListUl } from "react-icons/fa";
import styled from 'styled-components';
import { ProposalTimeline } from "../static/proposalTimeline";

const CustomTabPanel = styled.div`
display: grid;
grid-template-columns: repeat(auto-fit, minmax(12rem, 1fr));
gap: 0.5rem;
.tab {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 0.5rem;
  background-color: #f8f9fb;
  border-radius: 1rem;
  transition: 0.3s all ease-in;
  border: 2px solid transparent;
  .title {
    font-size: 1rem;
    // font-weight: 700;
  }
  &:hover {
    background-color: white;
    border: 2px solid #eff0f3;
}
}
`

function ProjectDashboard() {

  let history = useHistory()
  const { user } = useContext(UserContext)
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear() +1)
  const [selectedStatus, setSelectedStatus] = useState(null)
  const [statusName, setStatusName] = useState('')
  const [negtgelData, setNegtgelData] = useState([])
  const [allOrgData, setAllOrgData] = useState([])
  const [logoOrgId, setLogoOrgId] = useState([])

  const [loader, setLoader] = useState(false)
  const [statusCounter, setStatusCounter] = useState([])
  const [selectedOrderId, setSelectedOrderId] = useState(0)
  const [sumStatus, setSumStatus] = useState(0)
  const [pieChartDataOrgList, setPieChartDataOrgList] = useState([]);
  const [showPopupPie, setShowPopupPie] = useState(false);
  const [pageIndex, setPageIndex] = useState(0);
  const dataGridPositionId = { of: "#dataGrid" }
  const budgetCalc = { of: "#budgetcalc" }

  useEffect(() => {
    getNegtgelData();
  }, [currentYear, selectedStatus])

  useEffect(() => {
    getStatus();
  }, [])

  const getStatus = async () => {
    const result = await ProposalService.getStatusCount(currentYear)
    setStatusCounter(result.data)
    setSelectedStatus(result.data[0].budget_project_status_id)
    setSumStatus(result.data.reduce((total, item) => total + parseFloat(item.cnt), 0))
  }

  const loadChartDataByOrgList = async (type) => {
    try {
      setLoader(true);
      setPageIndex(0);
      let result = await ProposalService.getOrgListsByStatus(currentYear, type);
      setPieChartDataOrgList(result.data)
      setShowPopupPie(true);
    } catch (e) {
      notify(e.response.data.message, 'error', 2000);
    } finally {
      setLoader(false)
    }
  }

  const getNegtgelData = async () => {
    try {
      setLoader(true)
      let res = await ProposalService.getProgressProposal(currentYear, selectedStatus);
      setLogoOrgId(res.data.result_state_structure)
      let orgNegtgelArr = JSON.parse(JSON.stringify(res.data.queryResult))

      // Yamnii niit

      let newArr = [];
      const newObj = {};
      res.data.queryResult.forEach((data) => {
        data.amount = parseFloat(data.amount)
        if (newObj.hasOwnProperty(data.econ_category_code_2)) {
          newObj[data.econ_category_code_2] += data.amount;
        } else {
          newObj[data.econ_category_code_2] = data.amount;
          newArr.push(data)
        }
      })
      newArr.forEach((data) => {
        data.amount = newObj[data.econ_category_code_2]
      })
      res.data.queryResult = newArr.sort((a, b) => a.econ_category_code_2.localeCompare(b.econ_category_code_2));

      let negtgelObj = _.groupBy(res.data.queryResult || [], 'econ_category_code_1')
      setNegtgelData(negtgelObj)

      // Hariya baiguullagaar

      let newArr1 = [];
      const newObj1 = {};

      orgNegtgelArr.forEach((data) => {
        data.amount = parseFloat(data.amount);
        const groupingKey = `${data.parent_name}_${data.econ_category_code_1}`;

        if (newObj1.hasOwnProperty(groupingKey)) {
          newObj1[groupingKey] += data.amount;
        } else {
          newObj1[groupingKey] = data.amount;
          newArr1.push(data);
        }
      });
      newArr1.forEach((data) => {
        const groupingKey = `${data.parent_name}_${data.econ_category_code_1}`;
        data.amount = newObj1[groupingKey];
      });
      let arrByOrg = _.groupBy(newArr1 || [], 'parent_name');
      setAllOrgData(arrByOrg);
    }
    catch (error) {
      console.log("error", error);
    } finally {
      setLoader(false)
    }
  };

  const scrollViewItem = useCallback((o) => {
    return (
      <ScrollView>
        <div>
          {negtgelData?.[o]?.map((a, id) => {
            return (
              <div
                key={id}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "1.25rem",
                }}
              >
                <span
                  style={{
                    textAlign: "right",
                    paddingRight: 30,
                    fontSize: ".875rem",
                    flex: 6,
                    color: "#33373B",
                  }}
                >
                  {a.econ_category_name_2}:
                </span>
                <span
                  style={{
                    fontSize: ".875rem",
                    flex: 3,
                    color: "#33373B",
                  }}
                >
                  {a.econ_category_code_1 === "4"
                    ? parseInt(a.amount)
                    : numberWithCommas(a.amount / 1000000) ||
                    0}
                </span>
              </div>
            )
          })}
        </div>
      </ScrollView>
    )
  }, [negtgelData])

  const changePage = (id, name) => {
    const location = {
      pathname: "/budget/econ6",
      state: {
        orgId: id,
        year: currentYear,
      },
    }
    history.push(location)
  }

  const handleOptionChange = (e) => {
    if (e.fullName === 'paging.pageIndex') {
      setPageIndex(e.value)
    }
  }

  const proposalDeadline = {
    1: `${currentYear - 1}-07-25`,
    2: `${currentYear - 1}-07-20`,
    3: `${currentYear - 1}-07-09`,
  };

  const calcDayDiff = (obt, date) => {
    const timeDifference = date - new Date(proposalDeadline[obt] + 'T23:59:59+08:00').getTime();
    const overdue = Math.ceil(timeDifference / (1000 * 3600 * 24));
    return overdue;
  };

  const deadlineRender = (e) => {
    let overdue;
    let today = new Date().getTime();

    if (selectedStatus === 1) {
      if (e.value === user.start_of_ancestry) {
        overdue = calcDayDiff(2, today);
      } else {
        new Date(proposalDeadline[3]);
        overdue = calcDayDiff(3, today);
      }
      return <div style={{ color: 'red' }}>{overdue < 0 ? 'Хугацаа дуусаагүй' : `${overdue} хоног`}</div>;
    }
    else {
      let sentDay = new Date(e.data.ilgeesen_ognoo).getTime();
      if (e.value === user.start_of_ancestry) {
        overdue = calcDayDiff(2, sentDay);
      } else {
        overdue = calcDayDiff(3, sentDay);
      }
      return (
        overdue < 0 ?
          <div>-</div>
          :
          <div style={{ color: 'red' }}>{overdue} хоног</div>
      )
    }
  };

  const onCellPreparedHandler = (e) => {
    if (e.rowType === 'header') {
      e.cellElement.wordWrapEnabled = true;
      e.cellElement.style.fontFamily = 'Segoe UI'
      e.cellElement.style.fontWeight = 'bold'
      e.cellElement.style.color = "#15186A"
      e.cellElement.style.backgroundColor = "#EEF0F4"
    }
    if (e.rowType === 'data') {
      e.cellElement.style.fontFamily = 'Segoe UI'
      e.cellElement.style.fontWeight = 'semibold'
      e.cellElement.style.color = "#000000"
    }
  }

  const orderRender = (data) => {
    return <div style={{ color: "#171010" }}>{pageIndex * 50 + data.rowIndex + 1}</div>;
  };

  const colorPalettes = ["#3DAA75", "#f66160", "#5E6DD0", "#000000"]
  const borderTabPanel = ['#fff', '#cdebff', '#5575c2', '#ebf1ff', '#f66160', '#fff2f2', '#50c687']
  const textTabPanel = ['#fff', '#000', '#fff', '#5575c2', '#fff', '#f66160', '#fff']
  const iconColorPanel = ['#fff', '#000', '#5575c2', '#5575c2', '#f66160', '#f66160', '#50c687']

  return (
    <>
      <LoadPanelComponent position={{ of: "#filePo" }} visible={loader} />
      <div style={{ display: 'flex', padding: 10, fontWeight: 700, justifyContent: 'space-between', flexWrap: 'wrap', alignItems: 'baseline' }}>
        <div>
            <SelectBox
              label="Төсвийн жил"
              items={getYears(true)}
              width='10rem'
              defaultValue={currentYear}
              onValueChanged={(e) => {
                setCurrentYear(e.value)
              }} placeholder="сонгох"
            />
        </div>
        {user.organization.obt_id !== 3 &&
          <div style={{ padding: '.5rem', fontWeight: 'bold', textAlign: 'end', fontSize: '1rem', fontFamily: 'Segoe UI' }}>Нийт байгууллага:
            <span style={{ border: '1px solid #ddd', borderRadius: '.25rem', padding: '.5rem 2rem', marginLeft: '1rem', color:'#2150A4' }}>{sumStatus}</span></div>
        }
      </div>
      <div>
        <div className="proposal-home ministry" id="dataGrid">
          <LoadPanelComponent position={dataGridPositionId} visible={loader} />
          <LoadPanelComponent position={budgetCalc} visible={loader} />
          {!loader && <div className="row">
            <div className="col-12" style={{ padding: 10 }}>
              <div>
                <ProposalTimeline />
              </div>
              <CustomTabPanel>
                {statusCounter?.map((item, index) => (
                  <div
                    key={index}
                    className={`tab`}
                    style={{
                      margin: 5,
                      background: selectedOrderId === index && `${borderTabPanel[selectedStatus]}`,
                      justifyContent: 'space-between',
                      fontFamily: 'Segoe UI'
                    }}
                    onClick={() => {
                      setSelectedOrderId(index)
                      setSelectedStatus(item.budget_project_status_id)
                    }}
                  >
                    <span>{""}</span>
                    <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center' }}>
                      <span className="title custom-title" style={{ color: selectedOrderId === index && `${textTabPanel[selectedStatus]}` }}>{item?.budget_project_status_name}</span>
                      <span className={`${selectedOrderId === index && 'title'}`}
                        style={{
                          fontSize: '0.875rem',
                          color: selectedOrderId === index && `${textTabPanel[selectedStatus]}`
                        }}>
                        {item.cnt} - {(item.cnt * 100 / sumStatus).toFixed(2)}%</span>
                    </div>
                    {selectedOrderId === index ?
                      <button onClick={() => {
                        loadChartDataByOrgList(selectedStatus);
                        setStatusName(item.budget_project_status_name)
                      }}
                        style={{ padding: '.6rem', borderRadius: '50%', border: 'none', display: 'flex', background: '#fff' }}
                      >
                        <FaListUl style={{ fontSize: "1rem", color: selectedOrderId === index && `${iconColorPanel[selectedStatus]}` }} />
                      </button> : <span>{""}</span>}
                  </div>
                ))}
              </CustomTabPanel>
              <div
                className="card mt-3"
                id="budgetCard"
                style={{ flex: 1, marginTop: '1rem' }}
              >
                <div style={{ display: "grid", gridTemplateColumns: "repeat(auto-fit, minmax(20rem, 1fr))", rowGap: ".5rem", alignItems: "center", justifyContent: "space-between", padding: "1rem 1rem 0 1rem" }}>
                  <div
                    style={{
                      fontSize: "1rem",
                      fontWeight: "700",
                      color: "black",
                      display: "flex",
                      justifyContent: "left",
                    }}
                  >
                    1. Төсвийн саналын нийт дүн /ЭЗА, сая ₮/
                  </div>
                </div>
                <div className="card-body" style={{
                  display: "grid", gridTemplateColumns: "repeat(auto-fit, minmax(20rem, 1fr))", gap: ".5rem", height: "auto", fontFamily: 'Segoe UI',
                }}>
                  {Object.keys(negtgelData)?.map((o, index) => {
                    let sumAmount = 0
                    negtgelData?.[o].length > 0 && negtgelData?.[o]?.map((a) => {
                      sumAmount += a.amount * 1
                    })
                    return (
                      <div
                        className="budgetEconCard"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          height: "fit-content",
                        }}
                        key={index}
                      >
                        <div
                          style={{
                            fontSize: "0.875rem",
                            color: `${colorPalettes[index]}`,
                            zIndex: 1,
                            width: "fit-content",
                            alignSelf: "flex-end",
                            fontWeight: "700",
                            transform: "translateY(1rem) translateX(-1rem)",
                            backgroundColor: 'white',
                            textAlign: "right",
                            textTransform: "uppercase",
                          }}
                        >
                          {negtgelData[o][0] &&
                            negtgelData[o][0].econ_category_name_1}{" "}
                          {negtgelData[o][0].econ_category_code_1 !== "4"}
                        </div>
                        <div className="card" style={{
                          display: "flex", flexDirection: "column", padding: "1.5rem .5rem", height: "20rem", boxShadow: '0 0 10px #ddd'
                        }}>
                          {scrollViewItem(o)}
                          <div style={{ display: "flex", flexDirection: "row" }}>
                            {index !== 3 && (
                              <span
                                style={{
                                  textAlign: "right",
                                  paddingRight: 30,
                                  margin: "1rem 0",
                                  fontWeight: "bold",
                                  
                                  color: colorPalettes[index],
                                  flex: 6,
                                }}
                              >
                                Нийт:
                              </span>
                            )}
                            {index !== 3 && (
                              <span
                                style={{
                                  marginTop: "1rem",
                                  fontWeight: "bold",
                                  color: colorPalettes[index],
                                  flex: 3,
                                }}
                              >
                                {numberWithCommas(sumAmount / 1000000)}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
              <div className="card" style={{ flex: 1, marginTop: '1.5rem' }}>
                <div style={{ display: "grid", rowGap: ".5rem", alignItems: "center", justifyContent: "space-between", padding: "1rem 1rem" }}>
                  <div
                    style={{
                      fontSize: "1rem",
                      fontWeight: "700",
                      color: "black",
                      display: "flex",
                      justifyContent: "left",
                    }}
                  >
                    2. Төсвийн саналын дүн харьяа байгууллагаар /ЭЗА, сая ₮/
                  </div>
                </div>
                <div className="col-12 row">
                  <div className="orgs-container" >
                  {Object.keys(allOrgData)?.map((item, index) => (
                      <div
                        style={{ background: "#919da9" }}
                        className="org-card" key={index}
                        onClick={() => {
                          logoOrgId?.map((lg) => (
                            ((allOrgData[item][0].org_id !== user.org_id && allOrgData[item][0].org_id === lg.parent_id) || allOrgData[item][0].org_id === lg.org_id) &&
                            changePage(lg.org_id)))
                        }}
                      >
                        {logoOrgId?.map((lg, logoIndex) => {
                          if ((allOrgData[item][0].org_id !== user.org_id && allOrgData[item][0].org_id === lg.parent_id) || allOrgData[item][0].org_id === lg.org_id) {
                            return (
                                lg.logo!==undefined && <div className="org-card-logo" key={logoIndex}>
                                <img alt={lg.name} src={lg?.logo?.value}/>
                              </div>
                            )
                          }
                        })
                        }
                        {logoOrgId?.map((lg, logoIndex) => {
                          if ((allOrgData[item][0].org_id !== user.org_id && allOrgData[item][0].org_id === lg.parent_id) || allOrgData[item][0].org_id === lg.org_id) {
                            return (
                                lg.logo!==undefined && <div className="org-card-bg" key={logoIndex} style={{ marginTop: '-1.25rem' }}>
                                <img
                                  alt={lg.name}
                                  src={
                                    urlServer +
                                    "/api/file/documents/" +
                                    lg?.ministryBannerOrg?.value
                                  }
                                />
                              </div>
                            )
                          }
                        })
                        }
                        <div className="org-card-title">{item}</div>
                        <div className="org-card-stat">
                          {allOrgData?.[item].length > 0 && allOrgData?.[item]?.map((e, id) => (
                            <div key={id}>
                              <span>{e.econ_category_name_1}</span>
                              <span> {e.econ_category_code_1 === "4" ? e.amount : numberWithCommas(e.amount / 1000000)} </span>
                            </div>
                          )
                          )}
                        </div>
                      </div>
                    )
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>}
          {showPopupPie &&
            <Popup
              minWidth={'80%'}
              minHeight={'80%'}
              visible={showPopupPie}
              title={`"${statusName}" байгууллагын жагсаалт`}
              onHiding={() => setShowPopupPie(false)}
              resizeEnabled={true}
              dragEnabled={true}
              showTitle={true}
              hideOnOutsideClick={true}>
              <ScrollView width='100%' height='100%'>
                <div style={{ height: "100%" }}>
                  <LoadPanelComponent position={{ of: '#loadPieOrgList' }} visible={loader} />
                  <DataGrid
                    dataSource={pieChartDataOrgList}
                    wordWrapEnabled={true}
                    keyExpr="org_name"
                    showBorders={true}
                    showColumnLines={true}
                    showRowLines={true}
                    focusedRowEnabled={true}
                    paging={{ pageSize: 50 }}
                    onCellPrepared={onCellPreparedHandler}
                    onOptionChanged={handleOptionChange}
                    style={{ color: '#fff', textAlign: "center", family: "Segoe UI" }}
                  >
                    <Scrolling mode="standard" />
                    <Grouping autoExpandAll={true} />
                    <HeaderFilter visible={true} allowSearch={true} />
                    <Column caption="№" width={30} cellRender={orderRender} />
                    <Column caption="ТТЗ байгууллага" dataField="parent_name" />
                    <Column caption="ТШЗ байгууллага" dataField="org_name" />
                    <Column
                      caption={`${(currentYear - 1) + ' оны төсөв'}`}
                      allowSorting={true}
                      dataField={`${selectedStatus === 1 ? 'sum' : 'batlagdsan_tusuv'}`}
                      customizeText={(e) => { return numberWithCommas(e.value) }}
                      format="#,##0.00"
                      defaultSortOrder="desc"
                      alignment="center" />
                    {selectedStatus !== 1 && <Column
                      caption={`${currentYear} оны ТШЗ санал`}
                      allowSorting={true}
                      dataField='tshz_sanal'
                      customizeText={(e) => { return numberWithCommas(e.value) }}
                      format="#,##0.00"
                      defaultSortOrder="desc"
                      alignment="center" />}
                    {(selectedStatus === 2 || selectedStatus === 4 || selectedStatus === 6) &&
                      <Column
                        caption={`${currentYear} оны ТТЗ санал`}
                        allowSorting={true}
                        dataField="ttz_sanal"
                        customizeText={(e) => { return numberWithCommas(e.value) }}
                        format="#,##0.00"
                        defaultSortOrder="desc"
                        alignment="center" />}
                    {selectedStatus === 6 &&
                      <Column
                        caption={`${currentYear} оны ТЕЗ санал`}
                        allowSorting={true}
                        dataField="tez_sanal"
                        customizeText={(e) => { return numberWithCommas(e.value) }}
                        format="#,##0.00"
                        defaultSortOrder="desc"
                        alignment="center" />
                    }
                    {selectedStatus !== 1 && <Column caption="Хүргүүлсэн огноо" allowSorting={true} dataField="ilgeesen_ognoo" alignment='center' width={130} type="date"
                      customizeText={(e) => { return e?.value?.slice(0, 10) }} />}
                    <Column caption="Хугацаа хэтэрсэн эсэх" dataField="parent_org_id"
                      cellRender={deadlineRender} alignment="center" />
                    <Summary>
                      <GroupItem
                        column={`${selectedStatus === 1 ? 'sum' : 'batlagdsan_tusuv'}`}
                        summaryType="sum"
                        customizeText={(data) => {
                          return `${numberWithCommas(data.value)}`
                        }}
                        showInGroupFooter={false}
                        alignByColumn={true}
                        alignment="center"
                      />
                      <GroupItem
                        column='tshz_sanal'
                        summaryType="sum"
                        customizeText={(data) => {
                          return `${numberWithCommas(data.value)}`
                        }}
                        showInGroupFooter={false}
                        alignByColumn={true}
                        alignment="center"
                      />
                      {(selectedStatus === 2 || selectedStatus === 4 || selectedStatus === 6) &&
                        <GroupItem
                          column='ttz_sanal'
                          summaryType="sum"
                          customizeText={(data) => {
                            return `${numberWithCommas(data.value)}`
                          }}
                          showInGroupFooter={false}
                          alignByColumn={true}
                          alignment="center"
                        />}
                      {selectedStatus === 6 &&
                        <GroupItem
                          column='tez_sanal'
                          summaryType="sum"
                          customizeText={(data) => {
                            return `${numberWithCommas(data.value)}`
                          }}
                          showInGroupFooter={false}
                          alignByColumn={true}
                          alignment="center"
                        />}
                      <TotalItem
                        column={`${selectedStatus === 1 ? 'sum' : 'batlagdsan_tusuv'}`}
                        summaryType="sum"
                        customizeText={(data) => {
                          return `${numberWithCommas(data.value)}`
                        }}
                      />
                      <TotalItem
                        column='tshz_sanal'
                        summaryType="sum"
                        customizeText={(data) => {
                          return `${numberWithCommas(data.value)}`
                        }}
                      />
                      {(selectedStatus === 2 || selectedStatus === 4 || selectedStatus === 6) &&
                        <TotalItem
                          column='ttz_sanal'
                          summaryType="sum"
                          customizeText={(data) => {
                            return `${numberWithCommas(data.value)}`
                          }}
                        />}
                      {
                        selectedStatus === 6 &&
                        <TotalItem
                          column='tez_sanal'
                          summaryType="sum"
                          customizeText={(data) => {
                            return `${numberWithCommas(data.value)}`
                          }}
                        />
                      }
                    </Summary>
                  </DataGrid>
                </div>
              </ScrollView>
            </Popup>
          }
        </div>
      </div>
    </>
  )
}

export default ProjectDashboard

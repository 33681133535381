import request from '../../shared/lib/request'

function getProfile() {
  return request({
    url: `/auth/me`,
    method: 'GET'
  });
}

function login({ loginValue, password, ip, city, country_code, country_name, region, longitude, latitude,isp}) {
  return request({
    url: '/auth/signin',
    method: 'POST',
    data: {
      loginValue, password, ip, city, country_code, country_name, region, longitude, latitude,isp
    }
  });
}

function loginForGoSmart({ loginValue, password }) {
  return request({
    url: '/auth/surgaltSign',
    method: 'POST',
    data: {
      loginValue,
      password,
    }
  });
}

function registerUser({ username, email, password, passwordConfirm }) {
  return request({
    url: '/auth/signin',
    method: 'POST',
    data: {
      username,
      email,
      password,
      passwordConfirm
    }
  });
}

function logout(body, ip, city, country_code, country_name, region, longitude, latitude,isp) {
  return request({
    url: '/auth/logout',
    data: { id: body.id, username: body.username, ip: ip, city: city, country_code: country_code, country_name: country_name, region: region, longitude: longitude, latitude: latitude ,isp: isp},
    method: 'POST'
  });
}

function changePassword(values) {
  return request({
    url: '/auth/changePassword',
    data: {
      ...values
    },
    method: 'POST'
  });
}

function forget(values, type = 1) {
  return request({
    url: '/auth/forget',
    data: {
      ...values
    },
    method: 'POST'
  });
}

function changeUserDetail(newInfo) {
  return request({
    url: '/users/changeInfo',
    data: { ...newInfo },
    method: 'POST'
  });
};

function setOrgWorker(obj) {
  return request({
    url: '/users/setOrgWorker',
    data: { ...obj },
    method: 'POST'
  });
};

function updateMyInfo(data) {
  return request({
    url: '/rs/updateMyInfo',
    data: { ...data },
    method: 'POST'
  })
}

const AuthService = {
  registerUser,
  getProfile,
  login,
  logout,
  changePassword,
  forget,
  updateMyInfo,
  changeUserDetail,
  setOrgWorker,
  loginForGoSmart,

  //, update, delete, etc. ...
}

export default AuthService;

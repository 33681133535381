import React, { useRef, useState, useEffect,useContext } from "react";
import SelectBox from "devextreme-react/select-box";
import {getYears} from "../../../../util";
import DataGrid, {
    Column,
    Export,
    Selection,
    Summary,
    TotalItem,
    Grouping,
    GroupPanel,
    SearchPanel,
    GroupItem,
    RequiredRule, Lookup, Toolbar, Item, FilterRow
} from "devextreme-react/data-grid";
import {Workbook} from "exceljs";
import {exportDataGrid} from "devextreme/excel_exporter";
import {saveAs} from "file-saver-es";
import fcsServices from "../../../../services/api/fcs";
import notify from "devextreme/ui/notify";
import Button from "devextreme-react/button";
import fcsService from "../../../../services/api/fcs";
import {LoadPanel} from "devextreme-react/load-panel";
import ScrollView from "devextreme-react/scroll-view";
import {Popup} from "devextreme-react/popup";
import DateBox from "devextreme-react/date-box";
import {UserContext} from "../../../../hooks/UserContext";
import {isDisabled} from "bootstrap/js/src/util";
import ApBalance from "../../AccountPayment/report/ApBalance";
import ArEndMonth from "./arEndMonth";
import LoadPanelComponent from "../../../../components/LoadPanelComponent";
import { GoSearch } from "react-icons/go";
const monthList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
export default function ArReport(props) {
const goMonth=props.goMonth
    const userListPosition = { of: '#usersList' }
    const {user} = useContext(UserContext);
    const myRef = useRef(null);
    const componentRef = useRef();
    const [loader, setLoader] = useState(false);
    const [yearValue, setYearValue] = useState(null);
    const [monthValue, setMonthValue] = useState(null);
    const [dataSource, setDataSource] = useState([]);
    //const [selectedValue, setSelectedValue] = useState(null);
    const [visibleArReport, setVisibleArReport] = useState(false);
    const [visibleBalReport, setVisibleBalReport] = useState(false);

    const [reportTitle, setReportTitle] = useState(null);

    const [reportGL, setReportGL] = useState([]);
    const [modalVisibleGL, setModalVisibleGL] = useState(false);
    const [modalVisibleGLSave, setModalVisibleGLSave] = useState(false);
    const [dataFcsChart, setFcsChart] = useState([]);
    const [jrNo, setJrNo] = useState('');
    const [glDate, setGlDate] = useState();
    const [visableEndMonth,setVisableEndMonth]=useState(false)
    const [dataFcsGLTran, setFcsGLTran] = useState([]);
    const [visibleArSelect, setVisibleArSelect] = useState(false);


    const [dataBalMonth, setDataBalMonth] = useState([]);
    const [modalVisibleMonthSave, setModalVisibleMonthSave] = useState(false);
    useEffect(() => {
        fcsArTranList();
        getBalReport()
    }, [])
    useEffect(async () => {
        const today = new Date();
        setYearValue(today.getFullYear());
        setMonthValue(today.getMonth() + 1);

    }, []);
    const setDate= async()=>{
        
    }
    const fcsArTranList = async () => {
        try {
            setLoader(true);

            const fcsChart = await fcsService.getAllFcsChart();
            setFcsChart(fcsChart);
            const fcsMonth = await fcsService.getAllFcsMonth('AR');

            if ( fcsMonth && fcsMonth.length < 1) notify(" тохиргоо хийнэ үү  ", "warning")
            // if ( fcsMonth && fcsMonth.length < 1) setLoader(false)
            if ( fcsMonth && fcsMonth.length < 1) return setLoader(false)


            const resultGlTran = await fcsService.getFcsGlTran(fcsMonth[0].year,fcsMonth[0].month,'AR');
            setFcsGLTran(resultGlTran);
            return setLoader(false)
        } catch (e) {
            console.error(e);
        }

    }

    const changeYear = (e) => {
        setYearValue(e);
    }

    const changeMonth = (e) => {
        setMonthValue(e);
    }
    const customizeDate = (data) => {
        return Number(parseFloat(data.value).toFixed(2)).toLocaleString("en", {
            minimumFractionDigits: 2,
        });
    }
    const getArReport = async () => {
        if(yearValue && monthValue ) {
            try {
                setLoader(true);
                const result = await fcsServices.getFcsArTranReport(yearValue,monthValue,0)
                setDataSource(result);
                setVisibleArReport(true);
                setVisibleArSelect(false)
                setVisibleBalReport(false)
                setReportTitle('Авлагын тайлан')
                return setLoader(false);

            } catch (e) {
                notify(e.message, "error", 2000);
            }
        }
    };
    const getReportGL = async () => {
        setLoader(true);
        const resultGL = await fcsServices.getFcsGlMonthReport('AR');
        if (resultGL.data.length>0) {
            setReportGL(resultGL.data);
            setModalVisibleGL(true);
            return setLoader(false);
        }
        else {
            return setLoader(false);
        }
    };
    const getReportGLSave = async () => {
        const resultGL = await fcsServices.getFcsGlMonthReport('AR');
        if (resultGL.data.length>0) {
            setReportGL(resultGL.data);
            setModalVisibleGLSave(true);
        }
    };
    const getArSelect = async () => {
        if (yearValue && monthValue ) {
            try {
                setLoader(true);
                const result = await fcsServices.getFcsArSelectReport(yearValue, monthValue, "0")
                setDataSource(result);
                setVisibleBalReport(false);
                setVisibleArReport(false);
                setVisibleArSelect(true);

                setReportTitle('ArSelect');
                return setLoader(false);

            } catch (e) {
                notify(e.message, "error", 2000);
            }
        }
    };


    const getBalReport = async () => {
        if(yearValue && monthValue){
            try {


                setVisibleArReport(false);
                setVisibleArSelect(false);
                setVisibleBalReport(true);
                setReportTitle('BalReport');

            } catch (e) {
                notify(e.message, "error", 2000);
            }
        }
    };
    const onExporting = (e) => {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Main sheet');

        exportDataGrid({
            component: e.component,
            worksheet,
            autoFilterEnabled: true,
        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                //`${item.acct} - ${item.descr}`
                saveAs(new Blob([buffer], { type: 'application/octet-stream' }),`${reportTitle}-${yearValue}-${monthValue}.xlsx`);
            });
        });
        e.cancel = true;
    }
    const jrChangeValue = (event) => {
        setJrNo(event.target.value);
    }
    const setGLData = async () => {
        if (jrNo === null) return notify("Журнал дугаар хоосон байна", "warning");
        if(jrNo !== null) {
            if(dataFcsGLTran.length === 0){
                let jr_key = null;
                reportGL.forEach(element => {
                    jr_key = jrNo;
                });
                reportGL.map((item) =>{
                    item.docdate=item.docdate;
                    item.jr_key='AR';
                    item.acct=item.account;
                    item.descr=item.description;
                    item.brn=item.docno;
                    item.docno=jrNo;
                    item.orgcode=item.cust_code;
                    item.orgname=item.cust_name;
                    item.created_user_id=user.id;
                })
                fcsArTranList();
                await fcsService.addFcsGLTran(reportGL);

                notify('Амжилттай ерөнхий дэвтэрлүү шилжүүллээ', "success", 2000);

            }
            else {

                notify('Тухайн сард та ерөнхйи дэвтэрлүү илгээсэн байна', "error", 2000);

            }
        }
        else {
            notify('Сонгосон огноо тайлант он сарын хугацаанд биш байна', "error", 2000);

        }
    }
    const getReportMonthSave = async () => {
        const resultMonth = await fcsServices.getFcsInvBalMonth();
        if (resultMonth.data.length>0) {
            setDataBalMonth(resultMonth.data);
            setModalVisibleMonthSave(true);
        }
        else {
            notify("Сарын хаалт хийх боломжгүй", "warning");
        }
    };
    const style={
        text: {
            color: "rgba(4,7,95)", fontWeight: "700", fontSize: "13px"
        },
        box: {
            border: "1px solid rgb(224,224,224)", backgroundColor: "white", borderRadius: "1rem", padding: "0 .15rem",color: "rgba(4,7,95)", fontSize: "13px"
        },
        purple: {
            backgroundColor: "rgba(85,88,217)", fontSize: "13px", fontWeight: 600, fontStretch: "expanded"
        }
    }
    const handleSelectBoxChange= (e)=>{
        // console.log("e event", e)
        // eslint-disable-next-line default-case
        switch(e.value){
            case 0:
            getArReport()
            break;
            case 1:
            getArSelect()
            break;
            case 2:
            getBalReport()
            break;
        }
    }
    const onCellPrepared= (e)=>{
        let cell= e.cellElement
        if (e.rowType!=="header" && e.rowType !== "filter"){
            cell.style= "color: rgb(20, 23, 106); font-weight: 500; padding: .875rem 0; padding-left: 1rem"
        }else if (e.rowType==="header"){
            cell.style= `
            background-color: rgb(238, 240, 244); 
            border: none; padding-top: .875rem; 
            padding-left: 1rem; 
            color: rgb(20, 23, 106); 
            font-weight: 600; 
            font-size: 13px;
            margin-top: "2rem"
            `
        } else if (e.rowType=== "filter"){
            cell.style= "background-color: rgb(238, 240, 244); padding: .5rem; border: none; margin-bottom: 1rem"
        }

    }
    return (
        <React.Fragment>
            <div ref={myRef}>
                <LoadPanelComponent
                    shadingColor="rgba(0,0,0,0.4)"
                    position={userListPosition}
                    visible={loader}
                    showPane={false}
                    message = ''
                />
                <div className="bankbookToolbarContainer" style={{display: "flex",background: "white", padding: "1rem .5rem", justifyContent: "space-between", alignItems: "center"}}>
                  <div className="card-body" style={{display: "flex",gap: ".5rem", alignItems: "center"}}>
                    <div style={{display: 'flex', marginRight: 20}}>
                        <div  style={{marginRight: 10, display: 'flex', alignItems: 'center',...style.text}}>Тайлант он:</div>
                            <SelectBox
                                style={{ textAlign: "center",...style.box}}
                                value={yearValue}
                                items={getYears(true)}
                                defaultValue={yearValue}
                                width={80}
                                onValueChanged={(e) => changeYear(e.value)}
                                placeholder="сонгох"/>
                    </div>
                    <div style={{display: 'flex', marginRight: 20}}>
                        <div style={{marginRight: 10, display: 'flex', alignItems: 'center',...style.text}}>Тайлант сар:</div>
                            <SelectBox
                                style={{...style.box}}
                                width={60}
                                value={monthValue}
                                items={monthList}
                                defaultValue={monthValue}
                                onValueChanged={(e) => changeMonth(e.value)}
                                placeholder="сонгох"/>
                    </div>

                    <div style={{display: 'flex', marginRight: 20}}>
                        <div style={{marginRight: 10, display: 'flex', alignItems: 'center',...style.text}}>Тайлан</div>
                        <SelectBox
                            items={[{value: 0, text:"Борлуулалт" },{value: 1, text: " Төлөлт "},{value: 2, text: "Үлдэгдэл"} ]}
                            valueExpr="value"
                            style={style.box}
                            width={130}
                            displayExpr="text"
                            onValueChanged={handleSelectBoxChange}
                            placeholder="Сонгох"/>
                            </div>
                            <div >
                           <Button className="opButtons" style={{display: "flex", alignItems: "center"}}>
                                <GoSearch/>
                           </Button>
                        </div>
                        </div>
                        
                        <div style={{display: "flex", gap: ".5rem", alignItems: "center"}}>
                            {/* <div style={{ marginLeft: 5, marginTop: 5 }}>
                                <Button text = "Ерөнхий дэвтэрлүү шилжүүлэх тайлан" onClick={() => getReportGL()} className="opButtons" type = "default" style = {{alignment : "center", borderRadius: '7px'}}/>
                            </div> */}
                            <div style={{ marginLeft: 5, marginTop: 5 }}>
                                <Button className="opButtons" disabled={dataFcsGLTran.length === 0 ? false : true} text = {dataFcsGLTran.length === 0 ? "Шилжүүлэх" : "Шилжүүлсэн"} onClick={() => getReportGLSave()}
                                type ={dataFcsGLTran.length === 0 ? "default" : "success"}  style = {{alignment : "center", borderRadius: '7px'}}/>
                            </div>
                            <div style={{ marginLeft: 5, marginTop: 5 }}>
                                <Button text = "Сарын хаалт хийх "
                                onClick={() => setVisableEndMonth(true)} className="opButtons" type = "default"
                                style = {{alignment : "center", borderRadius: '7px'}}/>
                            </div>
                        </div>
                    </div>
                    </div>
                    {/* <div>
                        <Button text = "Борлуулалт" onClick={() => getArReport()} width = {150} type = "default" style = {{alignment : "center", borderRadius: '7px'}}/>
                    </div>
                    <div>
                        <Button text = "Төлбөр" onClick={() => getArSelect()} width = {150} type = "default" style = {{alignment : "center", borderRadius: '7px'}}/>
                    </div>



                    <div style={{marginLeft:10}}>
                        <Button text = "Үлдэгдэл" onClick={() => getBalReport()} width = {95} type = "default" style = {{alignment : "center", borderRadius: '7px'}}/>
                    </div> */}
               
           

            <div>
                {visibleBalReport &&     <ApBalance

                    monthValue={monthValue}
                    yearValue={yearValue}
                    jr_key="AR"
                    // ap={ap}
                /> }
            </div>
            <div>
                <ArEndMonth setVisableEndMonth={setVisableEndMonth}
                            goMonth={goMonth}            visableEndMonth={visableEndMonth} jr_key={'AR'}  />
            </div>

            <Popup
                width={800}
                height={500}
                minWidth={'80%'}
                maxWidth={'100%'}
                minHeight={'100%'}
                visible={modalVisibleGL}
                title={'Ерөнхий дэвтэрлүү шилжүүлэх тайлан'}
                onHiding={() => setModalVisibleGL(false)}
                resizeEnabled={true}
                dragEnabled={true}
                showTitle={true}
                hideOnOutsideClick={true}
            >
                <ScrollView width='100%' height='100%'>
                    <div className={'card-body'}>
                        <div  style={{ marginTop: 10 }}>
                            {reportGL[0] &&
                                <div style={{ display: 'flex', flexDirection: 'row' ,marginBottom:20 }}>
                                    <div style={{ display: 'flex', flexDirection: 'row', marginLeft: '10%' }}>
                                        <span style={{ fontSize: 14, fontWeight: 500 }}>Тайлант он,сар: </span>
                                        <span style={{ fontSize: 14, fontWeight: 500, marginLeft: 10 }}>{reportGL[0].pyear}-{reportGL[0].pmonth}</span>
                                    </div>

                                    <div style={{ display: 'flex', flexDirection: 'row', marginLeft: '10%' }}>
                                        <span style={{ fontSize: 14, fontWeight: 500 }}>Байгууллага: </span>
                                        <span style={{ fontSize: 14, fontWeight: 500, marginLeft: 10 }}>{reportGL[0].aimag_name}-{reportGL[0].org_name}</span>
                                    </div>
                                </div>
                            }
                            <DataGrid
                                dataSource={reportGL}
                                showBorders={true}
                                allowColumnResizing={true}
                                hoverStateEnabled={true}
                                keyExpr={null}
                                allowColumnReordering={true}
                                noDataText='Дата байхгүй байна.'
                            >
                                <SearchPanel visible={true} />
                                <Column caption="Ордер №" dataField="docno" dataType="string" width={80}/>
                                <Column caption="Дансны дугаар" dataField="account" dataType="string" width={100}/>
                                <Column caption="Дансны нэр" dataField="account_name" dataType="string" />
                                <Column caption="Харилцагчын код" dataField="cust_code" dataType="string" width={120}/>
                                <Column caption="Харилцагчын нэр" dataField="cust_name" dataType="string" />
                                <Column caption="Дебит" dataField="debit" dataType="number" format="#,##0.00" width={120}/>
                                <Column caption="Кредит" dataField="credit" dataType="number" format="#,##0.00" width={120}/>
                                <Column caption="Гүйлгээний утга" dataField="description" dataType="string"/>

                                <Summary>
                                    <TotalItem
                                        column="debit"
                                        summaryType="sum"
                                        customizeText={customizeDate}/>
                                    <TotalItem
                                        column="credit"
                                        summaryType="sum"
                                        customizeText={customizeDate} />
                                </Summary>
                                <Export enabled={true} allowExportSelectedData={false} />
                            </DataGrid>
                        </div>
                    </div>
                </ScrollView>
            </Popup>
            <Popup
                width={500}
                height={200}
                minWidth={'50%'}
                maxWidth={'50%'}
                minHeight={'40%'}
                visible={modalVisibleGLSave}
                title={'Ерөнхий дэвтэрлүү шилжүүлэх'}
                onHiding={() => setModalVisibleGLSave(false)}
                resizeEnabled={true}
                dragEnabled={true}
                showTitle={true}
                hideOnOutsideClick={true}
            >
                <ScrollView width='100%' height='100%'>
                    <div className={'card-body'}>
                        <div  style={{ marginTop: 10 }}>
                            {reportGL[0] &&
                                <div style={{ display: 'flex', flexDirection: 'row' ,marginBottom:20 }}>
                                    <div style={{ display: 'flex', flexDirection: 'row', marginLeft: '10%' }}>
                                        <span style={{ fontSize: 14, fontWeight: 500 }}>Тайлант он,сар: </span>
                                        <span style={{ fontSize: 14, fontWeight: 500, marginLeft: 10 }}>{reportGL[0].pyear}-{reportGL[0].pmonth}</span>
                                    </div>

                                    <div style={{ display: 'flex', flexDirection: 'row', marginLeft: '10%' }}>
                                        <span style={{ fontSize: 14, fontWeight: 500 }}>Байгууллага: </span>
                                        <span style={{ fontSize: 14, fontWeight: 500, marginLeft: 10 }}>{reportGL[0].aimag_name}-{reportGL[0].org_name}</span>
                                    </div>
                                </div>
                            }
                            <div style={{ display: 'flex', flexDirection: 'row' ,marginBottom:20 }}>
                                <div style={{ display: 'flex', marginLeft: 10,marginRight: 10,marginTop:5}}>Журнал дугаар:</div>
                                <input type = 'string' className = 'law-search-input' style={{ width: '50%', backgroundColor: '#FFFFFF', border: '1px solid #DDDDDD' }}
                                       value={jrNo} onChange={jrChangeValue}/>

                            </div>
                            <div className="col-12 row" style={{justifyContent:"flex-end", gap:'2rem', padding: '5px 15px'}}>
                                <Button text = "Шилжүүлэх" onClick={() => setGLData()} width = {150} type = "default" style = {{alignment : "center", borderRadius: '7px'}}/>
                            </div>

                        </div>
                    </div>
                </ScrollView>
            </Popup>
            {visibleArReport &&
                    <div className="card" style={{ marginTop: 10 }} ref={componentRef}>
                        <DataGrid
                            dataSource={dataSource}
                            showBorders={true}
                            // ref={apTranRef}
                            allowColumnResizing={true}
                            hoverStateEnabled={true}
                            keyExpr="id"
                            className="datagridBbReport"
                            onCellPrepared={onCellPrepared}
                            noDataText='Дата байхгүй байна.'
                            onExporting={onExporting}>
                            <GroupPanel visible={true} />
                            <SearchPanel visible={true} />
                            <FilterRow visible={true}/>
                            <Grouping autoExpandAll={true} />
                            <Column caption="Дугаар" dataField="docno" dataType="number" width={60}/>
                            <Column caption="Огноо" dataField="docdate" dataType="date" width={80}/>
                            <Toolbar>
                                    <Item location={"before"} cssClass="datagridToolbarOrganization" text={reportTitle ? reportTitle : ""}/>
                                    <Item location={"after"} cssClass="datagridExportButton" name="exportButton"/>
                                    <Item location={"after"} name="searchPanel"/>
                                </Toolbar>
                            <Column
                                cssClass="custom"
                                dataField="acctdb"
                                caption="Данс "
                                groupIndex={0}
                                allowEditing={true}
                                allowFiltering={true}
                            >
                                <RequiredRule
                                    message='Данс оруулна уу'
                                />
                                <Lookup
                                    valueExpr="acct"
                                    displayExpr={(item) => {
                                        return item && `${item.acct} - ${item.descr}`
                                    }}
                                    dataSource={dataFcsChart}

                                />
                            </Column>
                            <Column
                                cssClass="custom"
                                dataField="acctcr"
                                caption="Данс кредит"
                                allowEditing={true}
                                allowFiltering={true}
                            >
                                <RequiredRule
                                    message='Данс оруулна уу'
                                />
                                <Lookup
                                    valueExpr="acct"
                                    displayExpr={(item) => {
                                        return item && `${item.acct} - ${item.descr}`
                                    }}
                                    dataSource={dataFcsChart}

                                />
                            </Column>


                            <Column caption="Харилцагчын дугаар" dataField="cust" dataType="string" width={100}/>
                            <Column caption="Харилцагчын нэр" dataField="custname" dataType="string" width={150}/>
                            <Column caption="Дүн" dataField= "amount" format="#,##0.00" dataType="number" width={100}/>
                            <Column caption="Үүнээс НӨАТ" dataField= "vat" format="#,##0.00" dataType="number" width={100}/>
                            <Column caption="Тайлбар" dataField="descr" dataType="string" />
                            <Summary>
                                <GroupItem
                                    column="amount"
                                    summaryType="sum"
                                    format="#,##0.00"
                                    customizeText={customizeDate}
                                    showInGroupFooter={true} />
                                <GroupItem
                                    column="vat"
                                    summaryType="sum"
                                    format="#,##0.00"
                                    customizeText={customizeDate}
                                    showInGroupFooter={true} />
                                <TotalItem
                                            column="amount"
                                            summaryType="sum"
                                            format="#,##0.00"
                                            customizeText={customizeDate}/> :
                                <TotalItem
                                            column="vat"
                                            summaryType="sum"
                                            format="#,##0.00"
                                            customizeText={customizeDate}/>
                            </Summary>
                            <Export enabled={true} allowExportSelectedData={false} />
                        </DataGrid>
                    </div>
            }

            {visibleArSelect &&
                    <div className="card" style={{marginTop: 10}} ref={componentRef}>
                        <DataGrid
                            dataSource={dataSource}
                            showBorders={true}
                            // ref={apTranRef}
                            allowColumnResizing={true}
                            hoverStateEnabled={true}
                            keyExpr="id"
                            noDataText='Дата байхгүй байна.'
                            className="datagridBbReport"
                            onCellPrepared={onCellPrepared}
                            onExporting={onExporting}>
                            <GroupPanel visible={true}/>
                            <FilterRow visible={true}/>
                            <Toolbar>
                                    <Item location={"before"} cssClass="datagridToolbarOrganization" text={"Төлбөрийн тайлан"}/>
                                    <Item location={"after"} cssClass="datagridExportButton" name="exportButton"/>
                                    <Item location={"after"} name="searchPanel"/>
                                </Toolbar>
                            <SearchPanel visible={true}/>
                            
                            <Grouping autoExpandAll={true}/>
                            <Column caption="Огноо" dataField="docdate" dataType="date"/>
                            <Column caption="Журнал дугаар" dataField="docno" dataType="number"/>
                            <Column caption="Баримт №" dataField="brn" dataType="number"/>
                            <Column caption="Авлагын данс"
                                    dataField="acctdb"

                                    dataType="string" groupIndex={0}/>

                            <Column caption="Мөнгөний данс" dataField="cash_acct"
                                    calculateCellValue={(item) => {
                                        return item && `${item.cash_acct} - ${item.cashacct_name}`
                                    }}
                                    dataType="string"/>


                            <Column caption="Тайлбар" dataField="descr" dataType="string"/>
                            <Column caption="Харилцагчын дугаар" dataField="cust" dataType="string"/>
                            <Column caption="Харилцагчын нэр" dataField="custname" dataType="string"/>
                            <Column caption="Дүн" dataField="amt" dataType="number" format="#,##0.00"/>

                            <Summary>
                                <GroupItem
                                    column="amt"
                                    summaryType="sum"
                                    customizeText={customizeDate}
                                    showInGroupFooter={true}/>
                                <GroupItem
                                    column="vat"
                                    summaryType="sum"
                                    customizeText={customizeDate}
                                    showInGroupFooter={true}/>
                                <TotalItem
                                    column="amt"
                                    summaryType="sum"
                                    customizeText={customizeDate}/>
                                <TotalItem
                                    column="vat"
                                    summaryType="sum"
                                    customizeText={customizeDate}/>
                            </Summary>
                            <Export enabled={true} allowExportSelectedData={false}/>
                        </DataGrid>
                    </div>
            }

        </React.Fragment>
    )

}
import { DataGrid } from 'devextreme-react'
import { Column, GroupItem, Grouping, Paging, Summary, TotalItem } from 'devextreme-react/data-grid'
import React, { useContext, useEffect, useState } from 'react'
import LoadPanelComponent from '../budget/components/loadPanel'
import OrganizationService from '../../services/api/organization'
import notify from 'devextreme/ui/notify'
import { UserContext } from '../../hooks/UserContext'

export const BudgetProjectInfo = (props) => {
    const { user } = useContext(UserContext)
    const [planData, setPlanData] = useState([]);
    const [loader, setLoader] = useState(true);

    useEffect(() => {
        loadPlanInfo();
    }, [props.chosenValue])

    const loadPlanInfo = async () => {
        try {
            let res = await OrganizationService.getProjectAndPlanInfo(props.chosenValue, 1);
            setPlanData(res)
        } catch (e) {
            notify(e.message, "error", 2000)
        } finally {
            setLoader(false)
        }
    }

    const onCellPreparedHandler = (e) => {
        let elem = e.cellElement;
        let statement = (user.organization.obt_id === 1 && props.chosenValue === 'БҮГД') ? 3 : 2
        elem.style.setProperty('cursor', 'pointer', 'important');

        if (e.rowType === "header") {
            elem.wordWrapEnabled = true
            elem.style.fontWeight = "bold"
            elem.style.color = "#15186A"
            elem.style.backgroundColor = "#EEF0F4"
        } else if (e.rowType === 'group') {
            elem.style.color = "#15186A"
            elem.style.backgroundColor = "#fff"
            elem.style.fontSize = "12px"
            elem.style.borderColor = "#D4DCEC"
        } else if (e.columnIndex === 1 && e.rowType === 'data') {
            elem.style.color = '#000'
        }

        if (e.columnIndex === statement && e.rowType === 'data') {
            elem.innerHTML = '';
            const iconContainer = document.createElement('span');
            const tempDiv = document.createElement('div');

            if (e.value === 1) {
                tempDiv.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 448 512"><path fill="#1fc75a" d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/></svg>`;
            } else {
                tempDiv.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 384 512"><path fill="#d70909" d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/></svg>`;
            }
            iconContainer.appendChild(tempDiv.firstChild);
            elem.appendChild(iconContainer);
        } else if (e.rowType === 'group' && e.columnIndex === statement) {
            elem.innerHTML = '';
            const iconContainer = document.createElement('span');
            const tempDiv = document.createElement('div');

            if (e.summaryItems[0].value === e.data.aggregates[0]) {
                tempDiv.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 448 512"><path fill="#1fc75a" d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/></svg>`;
            } else if (e.summaryItems[0].value === 0) {
                tempDiv.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 384 512"><path fill="#d70909" d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/></svg>`;
            } else {
                tempDiv.innerHTML = `<svg width="18px" height="18px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" fill="none" stroke="#ffd43b" stroke-width="0.0002"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path fill="#f5cc14" fill-rule="evenodd" d="M10 3a7 7 0 100 14 7 7 0 000-14zm-9 7a9 9 0 1118 0 9 9 0 01-18 0zm10.01 4a1 1 0 01-1 1H10a1 1 0 110-2h.01a1 1 0 011 1zM11 6a1 1 0 10-2 0v5a1 1 0 102 0V6z"></path> </g></svg>`;
            }
            iconContainer.appendChild(tempDiv.firstChild);
            elem.appendChild(iconContainer);
        }
    }

    return (
        <div id='load82'>
            <LoadPanelComponent position={{ of: "#load82" }} visible={loader} />
            <DataGrid
                rowAlternationEnabled={true}
                wordWrapEnabled={true}
                dataSource={planData}
                noDataText="Дата байхгүй байна"
                style={{ fontFamily: "Segoe UI", fontWeight: "semibold" }}
                onCellPrepared={onCellPreparedHandler}
                allowColumnReordering={true}
                columnAutoWidth={true}
                showColumnLines={true}
                showRowLines={true}
                showBorders={true}
                hoverStateEnabled={true}
                keyExpr="org_id"
                scrolling={true}
            >
                <Paging defaultPageSize={25} />
                <Grouping
                    autoExpandAll={false}
                    contextMenuEnabled={false}
                    expandMode="rowClick"
                    texts={{
                        groupContinuesMessage: 'Дараагийн хуудас',
                        groupContinuedMessage: 'Өмнөх хуудас'
                    }}
                />
                {
                    user.organization.obt_id === 1 && props.chosenValue === 'БҮГД' &&
                    <Column
                        dataField="org_name_2"
                        caption="ТТЗ"
                        dataType="text"
                        visible={user.organization.obt_id === 1}
                        // cellRender={cellRenderParentOrgName}
                        groupIndex={0}
                        allowFiltering={user.organization.obt_id === 1}
                    />
                }
                <Column
                    dataField="org_name"
                    allowSorting={false}
                    caption="Байгууллага"
                    alignment="left"
                    minWidth={500}
                />
                <Column
                    allowSorting={false}
                    dataField='shinechlegdsen'
                    caption="Шинэчлэгдсэн огноо"
                    alignment="center"
                    width="7rem"
                    dataType="date" 
                    format="yyyy-MM-dd"
                />
                <Column
                    dataField="tuluv"
                    allowSorting={false}
                    caption="2024"
                    alignment="center"
                    width="8rem"
                />
                <Summary>
                    <GroupItem
                        column="org_name"
                        summaryType="count"
                        displayFormat="{0}-Байгууллага" />
                    <GroupItem column='tuluv' summaryType='sum' displayFormat='{0}' alignByColumn={true} showInGroupFooter={false} />
                    <TotalItem
                        column="org_name"
                        summaryType="count"
                        displayFormat="Танд нийт {0} байгууллагын мэдээлэл харуулж байна" />
                </Summary>
            </DataGrid>
        </div>
    )
}

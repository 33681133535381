import styles from "./Sidebar.module.css"
import img from "../../assets/imgs/og-new-ui-logo.png"
import img1 from "../../assets/imgs/muzg.svg"
import {UserContext} from "../../hooks/UserContext"
import BigLinks from "./BigLinks"
import React, {useContext} from "react"
import {Link} from "react-router-dom"
import {useState, useEffect} from "react"
import OrganizationService from "../../services/api/organization"
import {useTranslation} from "../../hooks/useTranslation"

const Sidebar = (props) => {
  const {user} = useContext(UserContext)
  const {isMongolian} = useTranslation()
  const [src, setSrc] = useState()
  const [toggle1, setToggle1] = React.useState(true)
  const ifMinister =
    user && user.roles && user.roles[0] && user.roles[0].role_id === 22
  useEffect(() => {
    if (!ifMinister) {
      loadImg()
    } else {
      setSrc(img1)
    }
  }, [])

  const loadImg = async () => {
    try {
      let logo = await OrganizationService.getLogo()
      if (logo && logo[0] && logo[0].value) {
        setSrc(logo[0].value)
        user.logo ??= logo[0].value
      } else {
        setSrc(img1)
        user.logo ??= img1
      }
    } catch (error) {
      user.logo ??= img1
      setSrc(img1)
    }
    //    setToggle1(prev=>!prev)
  }
  return (
    <>
      <nav
        className={`sidebar ${styles.sidebar} ${
          props.toggle ? styles.show : styles.hide
        }`}
        style={{height: "98vh", borderRadius: 10, backgroundColor: "#fff"}}
      >
        <div className={styles.sidebarWrap}>
          <div
            style={{
              display: "flex",
              flex: 1,
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              cursor: 'default'
            }}
          >
            <Link to={user.username === "02" ? "/budget" : "/ministryPage"}>
              <img style={{cursor: 'default'}} src={src} alt="" className={styles.sidebarTopLogo} />
            </Link>
            <div
              style={{
                display: "flex",
                marginBottom: "3rem",
                color: "#2E3092",
                fontSize: 14,
                fontWeight: "bold",
                fontFamily: "Arial",
              }}
            >
              {!isMongolian && user?.organization?.eng_name
                ? user.organization?.eng_name
                : user.organization.name}
            </div>
          </div>
          {props.result.map((item, index) => {
            return <BigLinks item={item} key={index} keyCustom={index} />
          })}
        </div>
      </nav>
    </>
  )
}

export default Sidebar

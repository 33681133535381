import React, {useContext, useEffect, useState, useRef} from 'react';
import 'devextreme-react/text-area';
import DataGrid, {
    Editing,
    Pager,
    Paging,
    Column,
    FilterRow,
    RequiredRule,
    PatternRule,
    Lookup, Export, Form, Item, Toolbar, SearchPanel
} from 'devextreme-react/data-grid';
import {FiSettings} from "react-icons/fi"
import {HiPlus} from 'react-icons/hi'
import {Popup} from "devextreme-react/popup";
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver-es';
import { exportDataGrid } from 'devextreme/excel_exporter';
import {UserContext} from "../../../hooks/UserContext";
import fcsService from "../../../services/api/fcs";
import Button from "devextreme-react/button";
import notify from "devextreme/ui/notify";
import _ from "lodash";
import ArrayStore from "devextreme/data/array_store";
import {Selection} from "devextreme-react/tree-list";
import {Template} from "devextreme-react";
import ScrollView from "devextreme-react/scroll-view";
import BB01 from "./report/BB01";
import BB02 from "./report/BB02";
import {NumberToString} from "./report/bankp";
import LoadPanelComponent from '../../../components/LoadPanelComponent';
import DepositPopup from './DepositPopup';
import Withdraw from './Withdraw';
import Deposit from './Deposit';
export const tabs = [
    {
        id: 1,
        text: 'Гүйлгээ'
    },
    {
        id: 2,
        text: 'Мөнгөний данс'
    },
    {
        id: 3,
        text: 'Төрөл СТ4'
    },
    {
        id: 4,
        text: 'Эхний үлдэгдэл'
    }


];
const tabData = new ArrayStore({
    key: 'id',
    data: tabs,
});
function BbEdit(props) {
    const [selectedId, setSelectedId] = useState(1);
    const userListPosition = { of: '#usersList' }
    const {user} = useContext(UserContext);
    const [dataSource, setDataSource] = useState([]);
    const [loader, setLoader] = useState(false);
    const myBabyRef = useRef(null);
    const [dataFcsChart, setFcsChart] = useState([]);
    const [dataFcsCustOrgn, setFcsCustOrgn] = useState([]);
    const [dataFcsMonth, setFcsMonth] = useState([]);
    const [dataFcsSt4New, setFcsSt4New] = useState([]);
    const [dataFcsBbClass, setFcsBbClass] = useState([]);
    const [yearValue, setYearValue] = useState(new Date().getFullYear());
    const [monthValue, setMonthValue] = useState(new Date().getMonth()+1);
    const [dataFcsBBBalMon, setFcsBBBalMon] = useState([]);
    const [reportData,setReportData]=useState()
    const [reportShow,setReportShow]=useState(false)
    const [popup, setPopup]= useState(false)


    useEffect(() => {

      //  fcsBbClassList();
    //    fcsSt4NewList();



    }, [])

    useEffect(()=>{
        selectedIdCheanged()
    } ,[selectedId ])
const selectedIdCheanged=async  () => {

        if (selectedId==2 ) {

            const fcsChart = await fcsService.getFcsChart('BB',0,0,0,0);
            setFcsChart(fcsChart);
        }

    if (selectedId==1 ) {

        const fcsChart = await fcsService.getAllFcsChart();
        setFcsChart(fcsChart);
    }


}


    /*const itemClickWorld = async (e) => {

        setSelectedId(e.itemData.id);


    }

    const renderListItem = (data) => {
        return (
            <div style={{display: 'flex', alignItems: 'center', padding: '5px', height: '25px'}}>
                
                <span style={{marginLeft: '10px'}}>{data.text}</span>
            </div>
        )
    }*/
    const fcsBbClassList = async () => {
        try {
            setLoader(true);
            const result = await fcsService.getAllFcsBBClass();
            setFcsBbClass(result)
            const result2 = await fcsService.getAllFcsBBBalMon();
            setFcsBBBalMon(result2)


            return setLoader(false)
        } catch (e) {
            console.error(e);
        }
    }
    const fcsSt4NewList = async () => {
        try {
            setLoader(true);
            const result = await fcsService.getAllFcsSt4New('TSB',0,0);
            setFcsSt4New(result)
            return setLoader(false)
        } catch (e) {
            console.error(e);
        }
    }
    const setBbClassEffect = async (e) => {
        if (e.changes && e.changes.length !== 0 ) {


            if (e.changes[0].type === "update") {
                try {
                    e.changes[0].data.updated_user_id = user.id;
                    await fcsService.editBBClass(e.changes[0].data);
                    await fcsBbClassList();
                    notify('Амжилттай хадгалагдлаа', "success", 2000);
                } catch (e) {
                    notify('Засварлалтыг хадгалах явцад алдаа гарлаа', "error", 2000);
                }
            } else if (e.changes[0].type === "insert") {
                try {
                    delete e.changes[0].data.id;
                    e.changes[0].data.created_user_id = user.id;
                    e.changes[0].data.org_id = user.org_id;
                    await fcsService.addFcsBBClass(e.changes[0].data);
                    await fcsBbClassList();
                    notify('Амжилттай үүслээ', "success", 2000);
                } catch (e) {
                    notify('Шинээр үүсэх явцад алдаа гарлаа', "error", 2000);
                }

            }
            else if (e.changes[0].type === "remove") {
                try {
                    await fcsService.removeFcsBBClass(e.changes[0].key.id);
                    await fcsBbClassList();
                    notify('Амжилттай устгагдлаа', "success", 2000);
                } catch (e) {
                    notify('Устгах явцад алдаа гарлаа', "error", 2000);
                }

            }

        }

    }
    const setSt4NewEffect = async (e) => {
        if (e.changes && e.changes.length !== 0 ) {


            if (e.changes[0].type === "update") {
                try {
                    e.changes[0].data.updated_user_id = user.id;
                    await fcsService.editFcsSt4New(e.changes[0].data);
                    await fcsBbClassList();
                    notify('Амжилттай хадгалагдлаа', "success", 2000);
                } catch (e) {
                    notify('Засварлалтыг хадгалах явцад алдаа гарлаа', "error", 2000);
                }
            } else if (e.changes[0].type === "insert") {
                try {
                    delete e.changes[0].data.id;
                    e.changes[0].data.created_user_id = user.id;
                    e.changes[0].data.org_id = user.org_id;
                    await fcsService.addFcsSt4New(e.changes[0].data);
                    await fcsBbClassList();
                    notify('Амжилттай үүслээ', "success", 2000);
                } catch (e) {
                    notify('Шинээр үүсэх явцад алдаа гарлаа', "error", 2000);
                }

            }
            else if (e.changes[0].type === "remove") {
                try {
                    await fcsService.removeFcsSt4New(e.changes[0].key.id);
                    await fcsBbClassList();
                    notify('Амжилттай устгагдлаа', "success", 2000);
                } catch (e) {
                    notify('Устгах явцад алдаа гарлаа', "error", 2000);
                }

            }

        }

    }
    const fcsBbTranList = async () => {
        const res = await fcsService.getAllFcsMonth('BB');
        let date= {year: res[0].year, month: res[0].month}
        if(yearValue && monthValue) {
            try {

                setLoader(true);
                const result = await fcsService.getFcsBbTran(date.year,date.month,1);
                setDataSource(result);
                const fcsChart = await fcsService.getAllFcsChart();
                setFcsChart(fcsChart);
                const fcsCustOrgn = await fcsService.getAllFcsCustOrgn();
                setFcsCustOrgn(fcsCustOrgn);
                const fcsBbClass = await fcsService.getAllFcsBBClass();
                setFcsBbClass(fcsBbClass);
                const fcsSt4New = await fcsService.getAllFcsSt4New('TSB',0,0);
                setFcsSt4New(fcsSt4New);
                const fcsMonth = await fcsService.getAllFcsMonth('BB');
                setFcsMonth(fcsMonth);
                return setLoader(false)

                const fcsbbbal = await fcsService.getAllFcsBBBalMon();
                setFcsBBBalMon(fcsbbbal)

            } catch (e) {
                console.error(e);
            }
        }
    }
    const changeYear = (e) => {
        setYearValue(e);
    }

    const changeMonth = (e) => {
        setMonthValue(e);
    }
    const onEditorPreparing = (e) => {
        if (e.parentType === "dataRow" && e.dataField !== "password") {
            return;
        }
        if (e.row && e.row.isNewRow) {
            e.editorOptions.visible = true;
        }
        if (e.row && !e.row.isNewRow) {
            e.editorOptions.visible = false;
        }
    }
    const calculateAmt = (newData, value, currentRowData) => {
        newData.qty=value;
        if(value > 0){
            newData.dt = currentRowData.unit * value;
            newData.cr = 0;
        }
        else {
            newData.cr = currentRowData.unit * value;
            newData.dt = 0;
        }

    }
    const setBbTran = async (e) => {
        if (e.changes && e.changes.length !== 0 ) {
            //yyyymm
            const date = new Date(e.changes[0].data.docdate);//new e.changes[0].data.docdate;
            const year = date.getFullYear() * 1e4; // 1e4 gives us the the other digits to be filled later, so 20210000.
            const month = (date.getMonth() + 1) * 100;
            const yyyymm = year + month // `+ ''` to convert to string from number, 202206
            const fcsCurrentMonth=dataFcsMonth.map((item) =>{
                let date = item.year +'/'+ item.month
                let datetimenew = new Date(date)
                let year = datetimenew.getFullYear() * 1e4; // 1e4 gives us the the other digits to be filled later, so 20210000.
                let month = (datetimenew.getMonth() + 1) * 100;
                const yyyymm_month = year + month
                return yyyymm_month
            });

            if(yyyymm === fcsCurrentMonth[0]) {
                if (e.changes[0].type === "update") {
                    try {
                        e.changes[0].data.updated_user_id = user.id;
                     //   e.changes[0].data.bbclass= _.filter(dataFcsBbClass, {bbacct: e.changes[0].data.bankacct,org_id: e.changes[0].data.org_id})[0].bbkey;
                        e.changes[0].data.acct_name= _.filter(dataFcsChart, {acct: e.changes[0].data.acct})[0].descr;
                        e.changes[0].data.st4_name=  _.filter(dataFcsSt4New, {code: e.changes[0].data.st4})[0].descr;
                        e.changes[0].data.custname=  _.filter(dataFcsCustOrgn, {orgcode: e.changes[0].data.cust})[0].name;
                       e.changes[0].data.bankname= _.filter(dataFcsBbClass, {bbacct: e.changes[0].data.bankacct})[0].descr;
                        await fcsService.editFcsBbTran(e.changes[0].data);
                     //   await fcsBbTranList();
                        notify('Амжилттай хадгалагдлаа', "success", 2000);
                    } catch (e) {
                        console.log("Засварлалтыг хадгалах явцад алдаа гарлаа",e)
                        notify('Засварлалтыг хадгалах явцад алдаа гарлаа', "error", 2000);
                    }
                }
            }
            else {
                notify('Сонгосон огноо тухайн тайлант он сард биш байна', "error", 2000);
            }
        }

    }

    const setBBBalMonEffect = async (e) => {
        if (e.changes && e.changes.length !== 0) {
            if (e.changes[0].data.year === dataFcsMonth[0].year) {
                if (e.changes[0].type === "update") {
                    try {

                        e.changes[0].data.updated_user_id = user.id;

                        e.changes[0].data.month = 0;

                        await fcsService.editFcsBBBalMon(e.changes[0].data);
                        await fcsBbClassList();
                        notify('Амжилттай хадгалагдлаа', "success", 2000);
                    } catch (e) {
                        notify('Засварлалтыг хадгалах явцад алдаа гарлаа', "error", 2000);
                    }
                } else if (e.changes[0].type === "insert") {
                    try {
                        delete e.changes[0].data.id;
                        // delete e.changes[0].data.updatedAt;
                        e.changes[0].data.created_user_id = user.id;
                        e.changes[0].data.org_id = user.org_id;

                        e.changes[0].data.month = 0;
                        await fcsService.addFcsBBBalMon(e.changes[0].data);
                        await fcsBbClassList();
                        notify('Амжилттай үүслээ', "success", 2000);
                    } catch (e) {
                        notify('Шинээр үүсэх явцад алдаа гарлаа', "error", 2000);
                    }

                }
            } else {
                notify('Сонгосон жил тухайн тайлант жилд биш байна', "error", 2000);
            }
        }

    }
    /*const style={
        text: {
            color: "rgba(4,7,95)",  fontWeight: "700", fontSize: "13px"
        },
        box: {
            border:"1px solid rgb(224,224,224)", backgroundColor: "white",   borderRadius: "1rem", padding: "0 .15rem", color: "rgba(4,7,95)", fontSize: "13px"
        }
    }*/
    const scheduleTransferDetailBtn = (options) => {
        return (
            <Button
                type="default"
                icon='save'
                className="saveButton" onClick={()=>printFa01(options.data) }>Харах</Button>
        )
    }
   const  allowEditTran = (e) => {
        return  true //  !(e.row.data.gl);
    }
    const printFa01=(data)   =>{

        setReportShow(true)
        if (data)

            setReportData(data)
        setReportShow(true)

    }

    const onExporting = (e) => {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Main sheet');

        exportDataGrid({
            component: e.component,
            worksheet,
            autoFilterEnabled: true,
        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'BbTran.xlsx');
            });
        });
        e.cancel = true;
    }
    const onInitNewRow = (e) => {
    }
    const popupHandler= ()=>{
        setPopup(true)
    }
    const onCellPrepared= (e)=>{
        let cell= e.cellElement
        if (e.rowType!=="header" && e.rowType !== "filter"){
            cell.style= "color: rgb(20, 23, 106); font-weight: 500; padding: .5rem 0; text-align: center"
        }else if (e.rowType==="header"){
            cell.style= `
            background-color: rgb(238, 240, 244); 
            border: none; padding-top: .875rem; 
            color: rgb(20, 23, 106); 
            text-align: center;
            font-weight: 600; 
            font-size: 12px;
            margin-top: "2rem"
            `
        } else if (e.rowType=== "filter"){
            cell.style= "background-color: rgb(238, 240, 244); padding: .5rem; border: none; margin-bottom: 1rem"
        }

    }
    const customButtonRender= ()=>{
        return (
            <button className='customSettingsButton'>
                <FiSettings style={{fontSize: "1.2rem"}}/>
            </button>
        )
    }
    const customAddButton= ()=>{
        return (
            <button className='customAddButton' onClick={popupHandler}>
                <HiPlus style={{fontSize: "1.2rem"}}/> Нэмэх
            </button>
        )
    }
    return (
        <div>
            <LoadPanelComponent
                shadingColor="rgba(0,0,0,0.4)"
                position={{of : '#password'}}
                showPane={false}
                message = ''
            />
             <Popup
                width={"75vw"}
                height={"90vh"}
                onHiding={() => setPopup(false)}
                resizeEnabled={true}
                dragEnabled={true}
                // hideOnOutsideClick
                showTitle={false}
                showCloseButton={false}
                visible={popup}
                className="BbEditPopup"
                >
                      <ScrollView width='100%' height='100%'>
                        <DepositPopup setPopup={setPopup}
                        children={[<Deposit/>, <Withdraw/>]}
                        title={["Орлого", "Зарлага"]}/>
                      </ScrollView>
                </Popup>
            <Popup
                width={"75vw"}
                height={"90vh"}
                onHiding={() => setReportShow(false)}
                resizeEnabled={true}
                dragEnabled={true}
                showTitle={true}
                hideOnOutsideClick={true}
                visible={reportShow}
                showCloseButton={true}
                title="дэлгэрэнгүй" >
                <ScrollView width='100%' height='100%'>
                    <div>


                        {reportData &&

                            <div>

                                {(reportData.qty>0  ) &&
                                    <BB01 setReportShow={setReportShow}
                                          orgName={reportData.organization_model.name} docno={reportData.docno}
                                          custName={reportData.custname} descr={reportData.descr} amt={reportData.dt}
                                          strAmt={NumberToString(reportData.dt)}
                                          docDate={ reportData.docdate}   />


                                }



                                {(reportData.qty<0    )   &&
                                    <BB02 setReportShow={setReportShow}
                                          orgName={'name'} docno={reportData.docno}
                                          custName={reportData.custname} descr={reportData.descr} amt={reportData.cr}
                                          strAmt={NumberToString(reportData.cr)}
                                          docDate={ reportData.docdate}    />


                                }


                            </div>


                        }



                    </div>
                </ScrollView>
            </Popup>

            <div id={'firstBox'} >
                <div id={'lawTypeLoad'}>
                    <div className="row">
                        {/* <div className="col-2">
                            <List
                                selectionMode="single"
                                dataSource={tabData}
                                selectedItemKeys={selectedId}
                                onItemClick={itemClickWorld}
                                itemRender={renderListItem}
                            />
                        </div> */}
                        <div className="col-12">
                            {selectedId === 1 &&
                                <div>
                                    {/* <div className="row" style={{background: "white", padding: "1rem .5rem",}}>
                                        <div style={{display: 'flex', marginRight: 20}}>
                                            <div style={{marginRight: 10, display: 'flex', alignItems: 'center',...style.text}}>Тайлант он:</div>
                                            <div>
                                                <SelectBox
                                                    width={100}
                                                    items={getYears(true)}
                                                    defaultValue={yearValue}
                                                    style={style.box}
                                                    onValueChanged={(e) => changeYear(e.value)}
                                                    placeholder="сонгох"/>
                                            </div>
                                        </div>
                                        <div style={{display: 'flex', marginRight: 20}}>
                                            <div style={{marginRight: 10, display: 'flex', alignItems: 'center',...style.text}}>Тайлант сар:</div>
                                            <div>
                                                <SelectBox
                                                    width={80}
                                                    items={monthList}
                                                    defaultValue={monthValue}
                                                    style={style.box}
                                                    onValueChanged={(e) => changeMonth(e.value)}
                                                    placeholder="сонгох"/>
                                            </div>
                                        </div>
                                        <div>
                                            <Button  icon="search" text = "Хайх" onClick={() => fcsBbTranList()} width = {100} type = "default" style = {{alignment : "center", borderRadius: '7px'}}/>
                                        </div>
                                    </div> */}

                                    <LoadPanelComponent
                                        shadingColor="rgba(0,0,0,0.4)"
                                        position={userListPosition}
                                         showPane={false}
                                        visible={loader}
                                        message = ''
                                    />
                                        <div>
                                            <div className="card">
                                                <DataGrid
                                                noDataText='Дата байхгүй байна.'
                                                    onEditorPreparing={onEditorPreparing}
                                                    ref={myBabyRef}
                                                    dataSource={dataSource}
                                                    onSaved={setBbTran}
                                                    className="datagridBbReport edit"
                                                    wordWrapEnabled
                                                    showRowLines
                                                    keyExpr="id"
                                                    allowColumnResizing={true}
                                                    columnAutoWidth={true}
                                                    onExporting={onExporting}
                                                    onCellPrepared={onCellPrepared}

                                                >
                                                    <Selection mode="single"/>
                                                   
                                                    <FilterRow
                                                        visible={true}
                                                    />
                                                    <Pager
                                                        showPageSizeSelector={true}
                                                        // showInfo={true}
                                                        showNavigationButtons={true}
                                                    />
                                                       <Toolbar>

                                                            {/*selectedId*/}

                                                           <Item location='before'
                                                                 widget='dxButton'
                                                                 options={{icon:'refresh'}}
                                                                 cssClass="datagridExportButton"
                                                                 onClick={  fcsBbTranList}
                                                           />

                                                           <Item location='before'
                                                                 widget='dxButton'
                                                                 options={{icon:'render'}}
                                                                 cssClass="datagridExportButton"
                                                                 onClick={  () => {
                                                                 setSelectedId(2)
                                                                 }
                                                                 }
                                                           />

                                                           <Item   render={customAddButton}/>
                                                        <Item render={customButtonRender}/>

                                                        <Item location={"after"} cssClass="datagridExportButton" name="exportButton"/>
                                                        <Item location={"after"} name="searchPanel"/>
                                                        </Toolbar>
                                                    <Column
                                                        dataField="id"
                                                        caption="№"
                                                        allowEditing={false}
                                                        width={65}
                                                        alignment="center"
                                                        // editCellTemplate = {}
                                                        // cellRender = {customize}
                                                        cssClass="custom"
                                                        allowFiltering={true}
                                                    >
                                                    </Column>

                                                    <Column
                                                        cssClass="custom"
                                                        dataField="bankacct"
                                                        caption="Мөнгөний данс"
                                                        width={120}
                                                        allowEditing={true}
                                                        allowFiltering={true}
                                                    >
                                                        <RequiredRule
                                                            message='Данс оруулна уу'
                                                        />
                                                        <Lookup
                                                            valueExpr="bbacct"
                                                            displayExpr={(item) => {
                                                                return item && `${item.bbacct} - ${item.descr}`
                                                            }}
                                                            dataSource={dataFcsBbClass}

                                                        />
                                                    </Column>
                                                    <Column
                                                        cssClass="custom"
                                                        dataField="bbclass"
                                                        caption="Журнал №"
                                                        allowEditing={true}
                                                        width={120}
                                                        allowFiltering={true}
                                                    >
                                                        <RequiredRule
                                                            message='Журнал № оруулна уу'
                                                        />
                                                    </Column>
                                                    <Column
                                                        cssClass="custom"
                                                        dataField="docno"
                                                        caption="Ордер №"
                                                        allowEditing={false}
                                                        width={120}
                                                        allowCollapsing={false}
                                                        allowFiltering={true}
                                                    >
                                                    </Column>

                                                    <Column
                                                        cssClass="custom"
                                                        dataField="docdate"
                                                        caption="Огноо"
                                                        width={120}
                                                        allowEditing={true}
                                                        dataType="date"
                                                    >
                                                        <RequiredRule
                                                            message='Огноо оруулна уу'
                                                        />
                                                    </Column>
                                                    <Column
                                                        cssClass="custom"
                                                        dataField="st4"
                                                        caption="Төрөл СТ4"
                                                        width={120}
                                                        allowEditing={true}
                                                    >
                                                        <RequiredRule
                                                            message='st4 оруулна уу'
                                                        />
                                                        <Lookup
                                                            valueExpr="code"
                                                            displayExpr={(item) => {
                                                                return item && `${item.code} - ${item.descr}`
                                                            }}
                                                            dataSource={dataFcsSt4New}

                                                        />
                                                    </Column>
                                                    <Column
                                                        cssClass="custom"
                                                        dataField="cust"
                                                        width={120}
                                                        caption="Харилцагч код"
                                                        allowEditing={true}
                                                        allowFiltering={true}
                                                    >
                                                        <RequiredRule
                                                            message='харилцагч код уу'
                                                        />
                                                        <Lookup
                                                            valueExpr="orgcode"
                                                            displayExpr={(item) => {
                                                                return item && `${item.orgcode} - ${item.name}`
                                                            }}
                                                            dataSource={dataFcsCustOrgn}

                                                        />
                                                    </Column>
                                                    <Column
                                                        cssClass="custom"
                                                        dataField="descr"
                                                        caption="Тайлбар"
                                                        width={120}
                                                        allowEditing={true}
                                                        allowFiltering={true}
                                                    >
                                                        <RequiredRule
                                                            message='Гүйлгээний ута оруулна уу'
                                                        />
                                                    </Column>


                                                    <Column
                                                        cssClass="custom"
                                                        dataField="qty"
                                                        caption="Тоо"
                                                        width={120}
                                                        allowEditing={true}
                                                        allowFiltering={true}
                                                        format="#,##0.00"
                                                        setCellValue={calculateAmt}
                                                    >
                                                        <RequiredRule
                                                            message='Тоо оруулна уу'
                                                        />
                                                        <PatternRule pattern={/^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/}
                                                                     message='зөвхөн тоо оруулна уу!'/>
                                                    </Column>
                                                    <Column
                                                        cssClass="custom"
                                                        dataField="unit"
                                                        width={120}
                                                        caption="Ханш"
                                                        allowEditing={true}
                                                        allowFiltering={true}
                                                    >
                                                        <RequiredRule
                                                            message='Ханш оруулна уу'
                                                        />
                                                        <PatternRule pattern={/^\d+$/} message='зөвхөн эерэг тоо оруулна уу!'/>
                                                    </Column>
                                                    <Column
                                                        cssClass="custom"
                                                        dataField="dt"
                                                        caption="Дебит"
                                                        width={120}
                                                        format="#,##0.00"
                                                        allowEditing = {false}
                                                    >
                                                    </Column>
                                                    <Column
                                                        cssClass="custom"
                                                        dataField="cr"
                                                        caption="Кредит"
                                                        width={120}
                                                        format="#,##0.00"
                                                        allowEditing = {false}
                                                    >
                                                    </Column>
                                                    <Column
                                                        cssClass="custom"
                                                        dataField="acct"
                                                        caption="Данс Кт,Дт "
                                                        width={120}
                                                        allowEditing={true}
                                                        allowFiltering={true}
                                                    >
                                                        <RequiredRule
                                                            message='Данс оруулна уу'
                                                        />
                                                        <Lookup
                                                            valueExpr="acct"
                                                            displayExpr={(item) => {
                                                                return item && `${item.acct} - ${item.descr}`
                                                            }}
                                                            dataSource={dataFcsChart}

                                                        />
                                                    </Column>

                                                    <Column width={100}  caption={''} dataField="gl" allowEditing={false}
                                                              >

                                                    </Column>

                                                    <Column type="buttons" width={110}  caption="" >
                                                        {/*isIconDisabled*/}
                                                        <Button name="edit"    />
                                                    </Column>
                                                    <Column minWidth={90} alignment="center" caption="" cellTemplate="cc156"/>
                                                    <Template name="cc156" render={scheduleTransferDetailBtn}/>


                                                    <Editing
                                                        mode="popup"
                                                        allowUpdating={allowEditTran}
                                                        allowDeleting={false}
                                                        // allowAdding = {adding}
                                                        useIcons={true}
                                                        texts={{
                                                            cancelAllChanges: 'Болих бүгд',
                                                            cancelRowChanges: 'Болих',
                                                            confirmDeleteMessage: 'Энэ мөнгөн хөрөнгийн мэдээллийг устгах уу?',
                                                            confirmDeleteTitle: 'Баталгаажуулах',
                                                            deleteRow: 'Устгах',
                                                            editRow: 'Өөрчлөх',
                                                            saveAllChanges: 'Хадгалах бүгд',
                                                            saveRowChanges: 'Хадгалах',
                                                            undeleteRow: 'Буцаах',
                                                            validationCancelChanges: 'Баталгаажуулах болих',
                                                        }}
                                                    >


                                                        <Popup title="Мөнгөн хөрөнгө" showTitle={true} width={1000} height={440}/>
                                                        <Form>
                                                            <Item itemType="group" colCount={2} colSpan={2}>
                                                                <Item dataField="organization_model.name"/>
                                                                <Item dataField="bankacct"/>
                                                                <Item dataField="docdate"/>
                                                                <Item dataField="st4"/>
                                                                <Item dataField="cust"/>
                                                                <Item dataField="docno"/>
                                                                <Item dataField="descr"/>
                                                                <Item dataField="qty"/>
                                                                <Item dataField="unit"/>
                                                                <Item dataField="dt"/>
                                                                <Item dataField="cr"/>
                                                                <Item dataField="acct"/>
                                                                <Item dataField="gl"/>

                                                            </Item>
                                                        </Form>
                                                    </Editing>
                                                    <Paging enabled={true}/>
                                                    <Export enabled={true} allowExportSelectedData={false}/>
                                                </DataGrid>
                                            </div>
                                        </div>
                                </div>
                            }
                            {selectedId === 2 &&
                                <div style={{ marginLeft: 20 }}>
                                    {/* <div className="10 row" style={{justifyContent:"space-between", gap:'2rem', padding: '5px 15px'}}>
                <span>
                    Мөнгөний дансны бүртгэл
                </span>
                                        <Button  icon="add" text = "Нэмэх" onClick={() => customAddRow(myBabyRef)} width = {110} type = "default" style = {{alignment : "center", borderRadius: '7px'}}/>
                                    </div> */}
                                    <div>
                                        <div>
                                            <DataGrid
                                            noDataText='Дата байхгүй байна.'
                                                onEditorPreparing={onEditorPreparing}
                                                ref={myBabyRef}
                                                dataSource={dataFcsBbClass}
                                                onSaved={setBbClassEffect}
                                                keyExpr= {null}
                                                onInitNewRow={onInitNewRow}
                                                wordWrapEnabled={true}
                                                allowColumnResizing={true}
                                                onExporting={onExporting}>
                                                <FilterRow
                                                    visible = {true}
                                                />
                                                <Pager
                                                    showPageSizeSelector={true}
                                                    // showInfo={true}
                                                    showNavigationButtons={true}
                                                />
                                                <Column
                                                    dataField="id"
                                                    caption="Дугаар"
                                                    allowEditing = {false}
                                                    width={60}
                                                    alignment="center"
                                                    // editCellTemplate = {}
                                                    // cellRender = {customize}
                                                    cssClass = "custom"
                                                    allowFiltering={true}
                                                >
                                                </Column>
                                                <Column
                                                    dataField="organization_model.name"
                                                    caption="Байгууллага нэр"
                                                    allowEditing = {false}
                                                    minWidth={130}
                                                    cssClass = "custom"
                                                    allowFiltering={true}
                                                >
                                                </Column>
                                                <Column
                                                    cssClass = "custom"
                                                    dataField="bbkey"
                                                    caption="Мөнгөний данс код"
                                                    width={80}
                                                    allowEditing = {true}
                                                    allowFiltering={true}
                                                >
                                                    <RequiredRule
                                                        message = 'Мөнгөний данс оруулна уу'
                                                    />
                                                </Column>
                                                <Column
                                                    cssClass = "custom"
                                                    dataField="descr"
                                                    caption="Мөнгөний данс нэр"
                                                    minWidth={100}
                                                    allowEditing = {true}
                                                    allowFiltering={true}
                                                >
                                                    <RequiredRule
                                                        message = 'Мөнгөний данс оруулна уу'
                                                    />
                                                </Column>
                                                <Column
                                                    cssClass="custom"
                                                    dataField="bbacct"
                                                    caption="Данс"
                                                    minWidth={120}
                                                    allowEditing={true}
                                                    allowFiltering={true}
                                                >
                                                    <RequiredRule
                                                        message='Данс оруулна уу'
                                                    />
                                                    <Lookup
                                                        valueExpr="acct"
                                                        displayExpr={(item) => {
                                                            return item && `${item.acct} - ${item.descr}`
                                                        }}
                                                        dataSource={dataFcsChart}

                                                    />
                                                </Column>
                                                <Column
                                                    cssClass = "custom"
                                                    dataField="orid"
                                                    caption="Орлого №"
                                                    alignment="center"
                                                    width={70}
                                                    allowEditing = {true}
                                                    allowFiltering={true}
                                                >
                                                    <RequiredRule
                                                        message = 'Орлого № оруулна уу'
                                                    />
                                                </Column>
                                                <Column
                                                    cssClass = "custom"
                                                    dataField="zarid"
                                                    caption="Зарлага №"
                                                    alignment="center"
                                                    width={70}
                                                    allowEditing = {true}
                                                    allowFiltering={true}
                                                >
                                                    <RequiredRule
                                                        message = 'Зарлага № оруулна уу'
                                                    />
                                                </Column>

                                                <Editing
                                                    mode="form"
                                                    allowUpdating={true}
                                                    allowDeleting={true}
                                                    allowAdding = {true}
                                                    useIcons = {true}
                                                    texts={{
                                                        cancelAllChanges: 'Болих бүгд',
                                                        cancelRowChanges: 'Болих',
                                                        confirmDeleteMessage: 'Энэ журнал мэдээллийг устгах уу?',
                                                        confirmDeleteTitle: 'Баталгаажуулах',
                                                        deleteRow: 'Устгах',
                                                        editRow: 'Өөрчлөх',
                                                        saveAllChanges: 'Хадгалах бүгд',
                                                        saveRowChanges: 'Хадгалах',
                                                        undeleteRow: 'Буцаах',
                                                        validationCancelChanges: 'Баталгаажуулах болих',
                                                    }}
                                                >
                                                </Editing>
                                                <Paging enabled={true} />
                                                <Export enabled={true} allowExportSelectedData={false} />
                                            </DataGrid>
                                        </div>
                                    </div>
                                </div>
                            }
                            {selectedId === 3 &&
                                <div style={{ marginLeft: 20 }}>
                                    {/* <div className="10 row" style={{justifyContent:"space-between", gap:'2rem', padding: '5px 15px'}}>
                <span>
                    СТ-4 бүртгэл
                </span>
                                        <Button  icon="add" text = "Нэмэх" onClick={() => customAddRow(myBabyRef)} width = {110} type = "default" style = {{alignment : "center", borderRadius: '7px'}}/>
                                    </div> */}
                                    <div>
                                        <div>
                                            <DataGrid
                                            noDataText='Дата байхгүй байна.'
                                                onEditorPreparing={onEditorPreparing}
                                                ref={myBabyRef}
                                                dataSource={dataFcsSt4New}
                                                onSaved={setSt4NewEffect}
                                                keyExpr= {null}
                                                onInitNewRow={onInitNewRow}
                                                allowColumnResizing={true}
                                                onExporting={onExporting}>
                                                <FilterRow
                                                    visible = {true}
                                                />
                                                <Pager
                                                    showPageSizeSelector={true}
                                                    // showInfo={true}
                                                    showNavigationButtons={true}
                                                />
                                                <Column
                                                    dataField="id"
                                                    caption="Дугаар"
                                                    allowEditing = {false}
                                                    width={50}
                                                    // editCellTemplate = {}
                                                    // cellRender = {customize}
                                                    cssClass = "custom"
                                                    allowFiltering={true}
                                                >
                                                </Column>
                                                <Column
                                                    cssClass = "custom"
                                                    dataField="code"
                                                    caption="СТ-4 код"
                                                    width={100}
                                                    allowEditing = {true}
                                                    allowFiltering={true}
                                                >
                                                    <RequiredRule
                                                        message = 'СТ-4 оруулна уу'
                                                    />
                                                </Column>
                                                <Column
                                                    cssClass = "custom"
                                                    dataField="descr"
                                                    caption="СТ-4 нэр"
                                                    width={500}
                                                    allowEditing = {true}
                                                    allowFiltering={true}
                                                >
                                                    <RequiredRule
                                                        message = 'СТ-4 нэр оруулна уу'
                                                    />
                                                </Column>
                                                <Column
                                                    cssClass = "custom"
                                                    dataField="gr1"
                                                    caption="gr1"
                                                    allowEditing = {true}
                                                    allowFiltering={true}
                                                >
                                                    <RequiredRule
                                                        message = 'gr1 оруулна уу'
                                                    />
                                                </Column>
                                                <Column
                                                    cssClass = "custom"
                                                    dataField="gr2"
                                                    caption="gr2"
                                                    allowEditing = {true}
                                                    allowFiltering={true}
                                                >
                                                    <RequiredRule
                                                        message = 'gr2 оруулна уу'
                                                    />
                                                </Column>
                                                <Editing
                                                    mode="form"
                                                    allowUpdating={true}
                                                    allowDeleting={true}
                                                    allowAdding = {true}
                                                    useIcons = {true}
                                                    texts={{
                                                        cancelAllChanges: 'Болих бүгд',
                                                        cancelRowChanges: 'Болих',
                                                        confirmDeleteMessage: 'Энэ СТ4 мэдээллийг устгах уу?',
                                                        confirmDeleteTitle: 'Баталгаажуулах',
                                                        deleteRow: 'Устгах',
                                                        editRow: 'Өөрчлөх',
                                                        saveAllChanges: 'Хадгалах бүгд',
                                                        saveRowChanges: 'Хадгалах',
                                                        undeleteRow: 'Буцаах',
                                                        validationCancelChanges: 'Баталгаажуулах болих',
                                                    }}
                                                >
                                                </Editing>
                                                <Paging enabled={true} />
                                                <Export enabled={true} allowExportSelectedData={false} />
                                            </DataGrid>
                                        </div>
                                    </div>
                                </div>
                            }
                            {selectedId === 4 &&
                                <div style={{marginLeft: 20}}>
                                    {/* <div className="10 row"
                                         style={{justifyContent: "space-between", gap: '2rem', padding: '5px 15px'}}>
                <span>
                   Эхний үлдэгдэл
                </span>
                                        <Button icon="add" text="Нэмэх" onClick={() => customAddRow(myBabyRef)}
                                                width={110} type="default"
                                                style={{alignment: "center", borderRadius: '7px'}}/>
                                    </div> */}
                                    <div>
                                        <div>
                                            <DataGrid
                                            noDataText='Дата байхгүй байна.'
                                                onEditorPreparing={onEditorPreparing}
                                                ref={myBabyRef}
                                                dataSource={dataFcsBBBalMon}
                                                onSaved={setBBBalMonEffect}
                                                keyExpr={null}
                                                onInitNewRow={onInitNewRow}
                                                allowColumnResizing={true}
                                                onExporting={onExporting}>
                                                <FilterRow
                                                    visible={true}
                                                />
                                                <Pager
                                                    showPageSizeSelector={true}
                                                    // showInfo={true}
                                                    showNavigationButtons={true}
                                                />
                                                <Column
                                                    dataField="id"
                                                    caption="Дугаар"
                                                    allowEditing={false}
                                                    width={50}
                                                    // editCellTemplate = {}
                                                    // cellRender = {customize}
                                                    cssClass="custom"
                                                    allowFiltering={true}
                                                >
                                                </Column>
                                                <Column
                                                    dataField="organization_model.name"
                                                    caption="Байгууллага нэр"
                                                    allowEditing={false}
                                                    width={200}
                                                    cssClass="custom"
                                                    allowFiltering={true}
                                                >
                                                </Column>
                                                <Column
                                                    cssClass="custom"
                                                    dataField="bbkey"
                                                    caption="Мөнгөний данс"
                                                    allowEditing={true}
                                                    allowFiltering={true}
                                                >
                                                    <RequiredRule
                                                        message='Мөнгөний данс оруулна уу'
                                                    />
                                                    <Lookup
                                                        valueExpr="bbkey"
                                                        displayExpr={(item) => {
                                                            return item && `${item.bbkey} - ${item.descr}`
                                                         }}
                                                        dataSource={dataFcsBbClass}

                                                    />
                                                </Column>
                                                <Column
                                                    cssClass="custom"
                                                    dataField="year"
                                                    caption="Жил"
                                                    dataType="number"
                                                    allowEditing={true}
                                                    allowFiltering={true}
                                                >
                                                    <RequiredRule
                                                        message='Жил оруулна уу'
                                                    />
                                                </Column>
                                                <Column
                                                    cssClass="custom"
                                                    dataField="month"
                                                    caption="Сар"
                                                    dataType="number"
                                                    allowEditing={false}
                                                    allowFiltering={true}
                                                >
                                                </Column>
                                                <Column
                                                    cssClass="custom"
                                                    dataField="qty"
                                                    caption="Тоо"
                                                    dataType="number"
                                                    allowEditing={true}
                                                    allowFiltering={true}
                                                >
                                                    <RequiredRule
                                                        message='Тоо оруулна уу'
                                                    />
                                                </Column>

                                                <Column
                                                    cssClass="custom"
                                                    dataField="debit"
                                                    caption="Дебиt дүн"
                                                    dataType="number"
                                                    allowEditing={true}
                                                    allowFiltering={true}
                                                >

                                                </Column>




                                                <Editing
                                                    mode="form"
                                                    allowUpdating={true}
                                                    allowDeleting={false}
                                                    allowAdding = {true}
                                                    useIcons={true}
                                                    texts={{
                                                        cancelAllChanges: 'Болих бүгд',
                                                        cancelRowChanges: 'Болих',
                                                        confirmDeleteMessage: 'Энэ эхний үлдэгдэл устгах уу?',
                                                        confirmDeleteTitle: 'Баталгаажуулах',
                                                        deleteRow: 'Устгах',
                                                        editRow: 'Өөрчлөх',
                                                        saveAllChanges: 'Хадгалах бүгд',
                                                        saveRowChanges: 'Хадгалах',
                                                        undeleteRow: 'Буцаах',
                                                        validationCancelChanges: 'Баталгаажуулах болих',
                                                    }}
                                                >
                                                </Editing>
                                                <Paging enabled={true}/>
                                                <Export enabled={true} allowExportSelectedData={false}/>
                                            </DataGrid>
                                        </div>
                                    </div>
                                </div>
                            }



                        </div>
                    </div>
                </div>
            </div>


        </div>
    )
}

export default BbEdit;

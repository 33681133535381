import React, { useContext, useEffect, useState } from 'react'
import OrganizationService from '../../services/api/organization'
import notify from 'devextreme/ui/notify';
import { PivotGrid } from 'devextreme-react';
import PivotGridDataSource from 'devextreme/ui/pivot_grid/data_source';
import './dashboard.css';
import LoadPanelComponent from '../budget/components/loadPanel';
import _ from 'lodash';
import { UserContext } from '../../hooks/UserContext';

export const BudgetInfo = (props) => {
  const { user } = useContext(UserContext)
  const [pivotSource, setPivotSource] = useState([]);
  const [loader, setLoader] = useState(true)
  const [neverChangingData, setNeverChangingData] = useState([])

  useEffect(() => {
    loadInfo()
    setLoader(true);
  }, [props.chosenValue])

  const counts = {};

  _.forEach(neverChangingData, entry => {
    const parent_name = entry.parent_name;
    const org_name = entry.org_name;
    if (!counts[parent_name]) {
      counts[parent_name] = { unique: new Set() };
    }
    counts[parent_name].unique.add(org_name);
  });
  const newArr = _.map(counts, (value, key) => ({ parent_name: key, count: value.unique.size }));

  const loadInfo = async () => {
    try {
      let res = await OrganizationService.getBudgetInfo(props.chosenValue);
      setNeverChangingData(res)
      const dataSource = new PivotGridDataSource({
        fields: [{
          dataField: 'parent_name',
          area: 'row',
          width: 400,
          groupIndex: 0,
          visible: props.chosenValue === 'БҮГД' && user.organization.obt_id === 1 ? true : false
        }, {
          caption: 'Байгууллага',
          dataField: 'org_name',
          area: 'row',
          width: 300
        },
        {
          dataField: 'budget_year',
          area: 'column',
          sortOrder: 'desc',
          expanded: true,
        },
        {
          dataField: 'amt_type',
          area: 'column',
          expanded: true,
          sortOrder: 'desc'
        },
        {
          caption: 'Батлагдсан',
          dataField: 'batlagdsan',
          area: 'data',
        },
        {
          caption: 'Тодотгол-1',
          dataField: 'todotgol',
          area: 'data',
        },
        {
          caption: 'Тодотгол-2',
          dataField: 'todotgol2',
          area: 'data',
        },
        {
          caption: 'Гүйцэтгэл',
          dataField: 'guitsetgel',
          area: 'data'
        },
        ],
        store: res,
      });
      setPivotSource(dataSource);
    } catch (e) {
      notify(e.message, "error", 2000)
    } finally {
      setLoader(false)
    }
  }

  const onCellPrepared = ({ cell, area, cellElement }) => {
    cellElement.style.fontSize = '12px'
    if (area === 'column') {
      cellElement.style.color = "#15186A !important"
      cellElement.style.fontSize = "12px"
      cellElement.style.fontWeight = "bold"
      cellElement.style.backgroundColor = "#EEF0F4"
    }

    if (user.organization.obt_id === 1 && props.chosenValue === 'БҮГД') {
      if (area === 'row' && cell?.path?.length === 1 && !cell.isWhiteSpace && (cell.type === 'T' || cell.type === 'D')) {
        return newArr.map((i) => {
          if (i.parent_name === cell.text) {
            return cellElement.innerHTML =
              `<div class="dx-expand-icon-container"><span class="dx-expand"></span></div><span>ТТЗ: ${i.parent_name} (${i.count}-Байгууллага)</span>`
          }
        })
      }
      if (area === 'data' && cell.rowType === 'D' && cell?.rowPath?.length === 2) {
        if (cell.value === undefined) {
          cellElement.innerHTML = `-`
        } else if (cell.value === 0) {
          cellElement.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 384 512"><path fill="#d70909" d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/></svg>`
        } else if (cell.value === 1) {
          cellElement.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 448 512"><path fill="#1fc75a" d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/></svg>`
        }
      }
      if (area === 'data' && cell.rowPath.length === 1 && (cell.rowType === 'T' || cell.rowType === 'D')) {
        let filtered = _.filter(neverChangingData, function (i) {
          return i.budget_year === cell.columnPath[0] && i.amt_type === cell.columnPath[1] && i.parent_name === cell.rowPath[0]
        })
        if (cell.value === 0) {
          cellElement.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 384 512"><path fill="#d70909" d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/></svg>`
        } else if (cell.value === filtered.length) {
          cellElement.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 448 512"><path fill="#1fc75a" d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/></svg>`
        } else if (cell.value && cell.value !== filtered.length) {
          cellElement.innerHTML = `<svg width="18px" height="18px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" fill="none" stroke="#ffd43b" stroke-width="0.0002"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path fill="#f5cc14" fill-rule="evenodd" d="M10 3a7 7 0 100 14 7 7 0 000-14zm-9 7a9 9 0 1118 0 9 9 0 01-18 0zm10.01 4a1 1 0 01-1 1H10a1 1 0 110-2h.01a1 1 0 011 1zM11 6a1 1 0 10-2 0v5a1 1 0 102 0V6z"></path> </g></svg>`
        } else {
          cellElement.innerHTML = `-`
        }
      }
    } else if (area === 'data' && cell.rowType === 'D') {
      if (cell.value === undefined) {
        cellElement.innerHTML = `-`
      } else if (cell.value === 0) {
        cellElement.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 384 512"><path fill="#d70909" d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/></svg>`
      } else if (cell.value === 1) {
        cellElement.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 448 512"><path fill="#1fc75a" d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/></svg>`
      }
    }
  }

  return (
    <div id='load83'>
      <LoadPanelComponent position={{ of: "#load83" }} visible={loader} />
      <PivotGrid
        style={{ fontFamily: "Segoe UI" }}
        dataSource={pivotSource}
        wordWrapEnabled={true}
        allowSorting={true}
        fieldPanel={{ showRowFields: true, showDataFields: true }}
        loadPanel={{ indicatorSrc: loader, text: 'Уншиж байна.' }}
        rowHeaderLayout='tree'
        showTotalsPrior='column'
        allowSortingBySummary={true}
        showColumnTotals={false}
        showRowGrandTotals={false}
        showRowTotals={false}
        showColumnGrandTotals={false}
        allowFiltering={true}
        hoverStateEnabled={true}
        hideEmptySummaryCells={true}
        texts={{
          noData: 'Өгөгдөл байхгүй',
          showFieldChooser: 'Талбар сонголт харах',
          exportToExcel: 'Excel файл татах',
          total: '{0}',
          grandTotal: 'Гүйцэтгэл'
        }}
        onCellPrepared={onCellPrepared}
        fieldChooser={false}
        showBorders={true}

      >
      </PivotGrid>
    </div>
  )
}
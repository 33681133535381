import React, {useState, useRef} from "react";
import DropDownBox from "devextreme-react/drop-down-box";
import TreeView from "devextreme-react/tree-view";

const StateStructureSelectComponent = (props) => {
    const treeViewRef = useRef(null);
    const [treeData, setTreeData] = useState(props.data.value);

    const treeViewRender = () => {
        return (
            <TreeView
                dataSource={props.data.column.lookup.dataSource}
                ref={treeViewRef}
                dataStructure="plain"
                keyExpr="id"
                parentIdExpr="parent_id"
                selectionMode="single"
                showCheckBoxesMode="normal"
                selectNodesRecursive={false}
                searchEnabled={true}
                displayExpr="name"
                selectByClick={true}
                onContentReady={syncTreeViewSelection}
                onItemSelectionChanged={treeViewItemSelectionChanged}
                style={{fontFamily: "Segoe UI"}}
                noDataText={"Дата байхгүй байна"}
            />
        )
    }

    const treeViewItemSelectionChanged = (e) => {
        setTreeData(e.component.getSelectedNodeKeys());
        props.data.setValue(e.component.getSelectedNodeKeys()[0]);
    }

    const syncTreeViewSelection = (e) => {
        const treeView = (e.component.selectItem && e.component) || (treeViewRef && treeViewRef.current.instance);
        if (treeView) {
            if (e.value === null) {
                treeView.unselectAll();
            } else {
                const values = e.value || [treeData];
                // console.log(values)
                values && values.forEach((value) => {
                    treeView.selectItem(value)
                })
            }
        }

        if (e.value !== undefined) {
            if (e.value.length > 0) {
                props.data.setValue(e.value[0]);
            }
        }
    }

    return (
        <DropDownBox
            value={treeData}
            valueExpr={'id'}
            displayExpr={'name'}
            placeholder="сонгох"
            showClearButton={true}
            dataSource={props.data.column.lookup.dataSource}
            onValueChanged={syncTreeViewSelection}
            contentRender={treeViewRender}
        />
    )
}

export default StateStructureSelectComponent

import React, {useState, useEffect} from "react";
import notify from "devextreme/ui/notify";
import FinanceReportService from "../../../../services/api/financeReport";
import _ from "lodash";
import Chart, {CommonSeriesSettings, Label, Legend, Series, Tooltip, ValueAxis} from "devextreme-react/chart";
import {numberWithCommas, palettePayrollChart} from "../../../../util";
import LoadPanelComponent from "../../../budget/components/loadPanel";
import EmptyData from "../../../budget/components/empty";

const PayrollReportBarChart = (props) => {
    const [chartData, setChartData] = useState([]);
    const [loader, setLoader] = useState(false);

    const loadData = async () => {
        try {
            setLoader(true);
            let r = await FinanceReportService.getPayrollByMonthOrg(props.year, props.selectedOrg);
            setChartData(r);
            setLoader(false);
        } catch (e) {
            setLoader(false);
            notify(e.message, "error", 2000);
        }
    }

    const customizeTooltip = (arg) => {
        if (arg.seriesName === 'Бодогдсон цалин өөрчлөлт') {
            let r = _.find(chartData, {'budget_month': (arg.argument * 1 - 1).toString()});
            let q = (arg.value / (r && r.estimated_salary)).toFixed(2);
            if (isFinite(q) && !isNaN(q)) {
                return {
                    text: `Өмнөх сараас ${q} дахин`
                }
            } else {
                return {
                    text: `Тооцоолох боломжгүй`
                }
            }
        } 
        else if  (arg.seriesName=== 'Нэмэгдэл цалин өөрчлөлт'){
            let r = _.find(chartData, {'budget_month': (arg.argument * 1 - 1).toString()});
            let q = (arg.value / (r && r.additional_salary)).toFixed(2);
            if (isFinite(q) && !isNaN(q)) {
                return {
                    text: `Өмнөх сараас ${q} дахин`
                }
            } else {
                return {
                    text: `Тооцоолох боломжгүй`
                }
            }                           
        }
     /*   else if  (arg.seriesName=== 'Үндсэн цалин өсөлт'){
            let r = _.find(chartData, {'budget_month': (arg.argument * 1 - 1).toString()});
            let q = (arg.value / (r && r.basic_salary)).toFixed(2);
            if (isFinite(q) && !isNaN(q)) {
                return {
                    text: `Өмнөх сараас ${q} дахин`
                }
            } else {
                return {
                    text: `Тооцоолох боломжгүй`
                }
            }
        }*/
        else {
            return {
                text: `${arg.seriesName}: ${numberWithCommas(arg.value / 1000000)}`
            };
        }
    }

    const customizeValueAxisLabel = (e) => {
        return `${e.value / 1000000}`
    }

    useEffect(() => {
        loadData();
    }, [props.year, props.selectedOrg])

    return (
        <div id="load12">
            <LoadPanelComponent position={{of: '#load12'}} visible={loader} />
            {chartData.length > 0 ? (
            <Chart
                palette={palettePayrollChart}
                paletteExtensionMode="alternate"

                dataSource={chartData}
                // onPointClick={pointClickFilterHandler}
            >
                <CommonSeriesSettings
                    argumentField="budget_month"
                    type="bar"
                >
                    <Label visible={false}/>
                </CommonSeriesSettings>
                <ValueAxis>
                    <Label customizeText={customizeValueAxisLabel}/>
                </ValueAxis>
                {/*<Series valueField="basic_salary" name="Үндсэн цалин"/>
                <Series valueField="basic_salary" name="Үндсэн цалин өсөлт" color="red" type="spline" dashStyle="dash"/>*/}
                <Series valueField="estimated_salary" name="Бодогдсон цалин"/>
                <Series valueField="estimated_salary" name="Бодогдсон цалин өөрчлөлт" color="red" type="spline" dashStyle="dash"/>
                <Series valueField="additional_salary" name="Нэмэгдэл цалин"/>
                <Series valueField="additional_salary" name="Нэмэгдэл цалин өөрчлөлт" type="spline" color="#264b96" dashStyle="dash"/>
                <Legend visible={true} verticalAlignment="bottom" horizontalAlignment="center" font={{ family: "'Montserrat', sans-serif" }}/>
                <Tooltip zIndex={100000} enabled={true} customizeTooltip={customizeTooltip} font={{ family: "'Montserrat', sans-serif" }} location="edge">
                </Tooltip>
            </Chart>
                ):
                <div style={{display: "flex", justifyContent: "center", alignItems: "center", family: "'Montserrat', sans-serif" }}>
                    <EmptyData/>
                </div>
            }
        </div>
    )
}

export default PayrollReportBarChart;

import React, { useState, useEffect, useContext } from "react";
import SelectBox from "devextreme-react/select-box";
import { getYears} from "../../../util";
import notify from "devextreme/ui/notify";
import FinanceReportService from "../../../services/api/financeReport";
import PayrollReportHeaderInfo from "./components/PayrollReportHeaderInfo";
import PayrollWithOrgs from "./components/PayrollWithOrgs";
import PayrollReportList from "./components/PayrollReportList";
import Button from "devextreme-react/button";
import DateBox from "devextreme-react/date-box";
import _ from "lodash";
import FileUploader from "devextreme-react/file-uploader";
import LoadPanelComponent from "../../budget/components/loadPanel";
import { UserContext } from "../../../hooks/UserContext";
import ExampleImportData from "../../../assets/Цалингийн товчоо жишээ дата.xlsx";
import { Popup } from "devextreme-react/popup";
import PayrollReportPieNegtgel from "./components/PayrollReportPieNegtgel";
import PayrollReportBarChart from "./components/PayrollReportBarChart";
import PayrollWithOrgsMonth from "./components/PayrollWithOrgsMonth";
import paperPlaneSvg from "../../../assets/imgs/paper-plane.svg";
import { custom } from "devextreme/ui/dialog";
import PayrollReportPivotList from "./components/PayrollReportPivotList";

const Excel = require('exceljs');

const monthList = [
    { name: 'Бүгд', value: 0 },
    { name: '1', value: 1 },
    { name: '2', value: 2 },
    { name: '3', value: 3 },
    { name: '4', value: 4 },
    { name: '5', value: 5 },
    { name: '6', value: 6 },
    { name: '7', value: 7 },
    { name: '8', value: 8 },
    { name: '9', value: 9 },
    { name: '10', value: 10 },
    { name: '11', value: 11 },
    { name: '12', value: 12 },
]
const payrollViewList = [
    { name: 'PivotGrid -ээр харах', value: true },
    { name: 'DataGrid -ээр харах', value: false },
]
const PayrollReport = (props) => {
    const { user } = useContext(UserContext);
    const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
    const [chosenValue, setChosenValue] = useState('ALL');
    const [orgs, setOrgs] = useState([]);
    const [selectedOrg, setSelectedOrg] = useState(undefined);
    //const [fromMonth, setFromMonth] = useState([]);
    const [yearValue, setYearValue] = useState(null);
    const [monthValue, setMonthValue] = useState(null);
    const [currentDate, setCurrentDate] = useState();
    const [loader, setLoader] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const [excelData, setExcelData] = useState([]);
    const [modalImport, setModalImport] = useState(false);
    const [selectedMonth, setSelectedMonth] = useState(0);
    const [chosenViewList, setChosenViewList] = useState(true);
    const loadOrgs = async (year) => {
        try {
            let r = await FinanceReportService.getFilteredOrgPayroll(year);
            setOrgs([
                {
                    budget_year: year,
                    text_name: 'БҮГД',
                    value_name: 'ALL',
                },
                ...r
            ])
            setCurrentDate(new Date());

        } catch (e) {
            notify(e.message, "error", 2000)
        }
    }

    useEffect(() => {
        setChosenValue("ALL")
        loadOrgs(currentYear)


    }, [])
    const exportExcel = () => {
        window.open(
            ExampleImportData,
            "_blank"
        )
    };
    const onValueChangedDate = (e) => {
        setYearValue(e.value.getFullYear());
        setMonthValue(e.value.getMonth() + 1);
    }
    /*const customRender = (e) => {
        return numberWithCommas(e.text);
    }
    const fromMonthChange = (e) => {
        setFromMonth(e.value);
    }*/

    const uploadProgress = async (e) => {
        if (!yearValue && !monthValue) {
            notify(`Excel файл оруулах сараа сонгоно уу`, 'warning', 30000);
        } else {
            try {
                const workbook = new Excel.Workbook();
                const myEx = await workbook.xlsx.load(e.value[0]);
                const hello = myEx.getWorksheet(1);
                const excelDataList = [];
                await hello.eachRow((row, rowNumber) => {
                    if (row.getCell(1).value && row.getCell(1).value * 1) {
                        if (row.getCell(1).value) {
                            //const rowYearAmt = row.getCell(5).value;
                            try {
                                const data = {
                                    budget_year: yearValue,
                                    budget_month: monthValue,
                                    regno: row.getCell(1).value,
                                    employment_contract_code: row.getCell(2).value,
                                    employment_contract_name: row.getCell(3).value,
                                    worker_count: parseFloat((row.getCell(4).value === null ? 0 : (row.getCell(4).value.result ? row.getCell(4).value.result : (row.getCell(4).value.result === undefined ? row.getCell(4).value : 0))).toString().replace(/,/g, '')),
                                    basic_salary: parseFloat((row.getCell(5).value === null ? 0 : (row.getCell(5).value.result ? row.getCell(5).value.result : (row.getCell(5).value.result === undefined ? row.getCell(5).value : 0))).toString().replace(/,/g, '')),
                                    estimated_salary: parseFloat((row.getCell(6).value === null ? 0 : (row.getCell(6).value.result ? row.getCell(6).value.result : (row.getCell(6).value.result === undefined ? row.getCell(6).value : 0))).toString().replace(/,/g, '')),
                                    rank_increase: parseFloat((row.getCell(7).value === null ? 0 : (row.getCell(7).value.result ? row.getCell(7).value.result : (row.getCell(7).value.result === undefined ? row.getCell(7).value : 0))).toString().replace(/,/g, '')),
                                    many_years_increase: parseFloat((row.getCell(8).value === null ? 0 : (row.getCell(8).value.result ? row.getCell(8).value.result : (row.getCell(8).value.result === undefined ? row.getCell(8).value : 0))).toString().replace(/,/g, '')),
                                    special_increase: parseFloat((row.getCell(9).value === null ? 0 : (row.getCell(9).value.result ? row.getCell(9).value.result : (row.getCell(9).value.result === undefined ? row.getCell(9).value : 0))).toString().replace(/,/g, '')),
                                    professional_increase: parseFloat((row.getCell(10).value === null ? 0 : (row.getCell(10).value.result ? row.getCell(10).value.result : (row.getCell(10).value.result === undefined ? row.getCell(10).value : 0))).toString().replace(/,/g, '')),
                                    authorized_increase: parseFloat((row.getCell(11).value === null ? 0 : (row.getCell(11).value.result ? row.getCell(11).value.result : (row.getCell(11).value.result === undefined ? row.getCell(11).value : 0))).toString().replace(/,/g, '')),
                                    secret_increase: parseFloat((row.getCell(12).value === null ? 0 : (row.getCell(12).value.result ? row.getCell(12).value.result : (row.getCell(12).value.result === undefined ? row.getCell(12).value : 0))).toString().replace(/,/g, '')),
                                    performance_worker_count: parseFloat((row.getCell(13).value === null ? 0 : (row.getCell(13).value.result ? row.getCell(13).value.result : (row.getCell(13).value.result === undefined ? row.getCell(13).value : 0))).toString().replace(/,/g, '')),
                                    performance_increase: parseFloat((row.getCell(14).value === null ? 0 : (row.getCell(14).value.result ? row.getCell(14).value.result : (row.getCell(14).value.result === undefined ? row.getCell(14).value : 0))).toString().replace(/,/g, '')),
                                    command_add_worker_count: parseFloat((row.getCell(15).value === null ? 0 : (row.getCell(15).value.result ? row.getCell(15).value.result : (row.getCell(15).value.result === undefined ? row.getCell(15).value : 0))).toString().replace(/,/g, '')),
                                    command_add_increase: parseFloat((row.getCell(16).value === null ? 0 : (row.getCell(16).value.result ? row.getCell(16).value.result : (row.getCell(16).value.result === undefined ? row.getCell(16).value : 0))).toString().replace(/,/g, '')),
                                    command_removed_worker_count: parseFloat((row.getCell(17).value === null ? 0 : (row.getCell(17).value.result ? row.getCell(17).value.result : (row.getCell(17).value.result === undefined ? row.getCell(17).value : 0))).toString().replace(/,/g, '')),
                                    command_removed_increase: parseFloat((row.getCell(18).value === null ? 0 : (row.getCell(18).value.result ? row.getCell(18).value.result : (row.getCell(18).value.result === undefined ? row.getCell(18).value : 0))).toString().replace(/,/g, '')),
                                    vacation_worker_count: parseFloat((row.getCell(19).value === null ? 0 : (row.getCell(19).value.result ? row.getCell(19).value.result : (row.getCell(19).value.result === undefined ? row.getCell(19).value : 0))).toString().replace(/,/g, '')),
                                    vacation: parseFloat((row.getCell(20).value === null ? 0 : (row.getCell(20).value.result ? row.getCell(20).value.result : (row.getCell(20).value.result === undefined ? row.getCell(20).value : 0))).toString().replace(/,/g, '')),
                                    meal_allowance: parseFloat((row.getCell(21).value === null ? 0 : (row.getCell(21).value.result ? row.getCell(21).value.result : (row.getCell(21).value.result === undefined ? row.getCell(21).value : 0))).toString().replace(/,/g, '')),
                                    other_increase: parseFloat((row.getCell(22).value === null ? 0 : (row.getCell(22).value.result ? row.getCell(22).value.result : (row.getCell(22).value.result === undefined ? row.getCell(22).value : 0))).toString().replace(/,/g, '')),
                                    payroll_increase: parseFloat((row.getCell(23).value === null ? 0 : (row.getCell(23).value.result ? row.getCell(23).value.result : (row.getCell(23).value.result === undefined ? row.getCell(23).value : 0))).toString().replace(/,/g, '')),
                                    total_salary: parseFloat((row.getCell(24).value === null ? 0 : (row.getCell(24).value.result ? row.getCell(24).value.result : (row.getCell(24).value.result === undefined ? row.getCell(24).value : 0))).toString().replace(/,/g, '')),
                                    aos_emd: parseFloat((row.getCell(25).value === null ? 0 : (row.getCell(25).value.result ? row.getCell(25).value.result : (row.getCell(25).value.result === undefined ? row.getCell(25).value : 0))).toString().replace(/,/g, '')),
                                    created_user_id: user.id
                                }
                                excelDataList.push(data);
                            } catch (e) {
                                console.log(e);
                            }

                        }
                    }
                });
                setExcelData(excelDataList);
                setIsDisabled(true)
            } catch (e) {
                console.log('error exception -> ', e);
            }
        }
    }

    const onClick = async () => {
        setIsDisabled(true);
        let myDialog = custom({
            title: "Баталгаажуулалт",
            messageHtml: `<i>Та <b>${yearValue}</b> оны <b>${monthValue}</b> -р сарын цалингийн мэдээлэл илгээхдээ итгэлтэй байна уу?</i>`,
            buttons: [{
                text: "Тийм",
                onClick: (e) => {
                    return { buttonText: e.component.option("text") }
                }
            },
            {
                text: "Үгүй",
                onClick: (e) => {
                    return { buttonText: e.component.option("text") }
                }
            },
            ]
        });
        myDialog.show().then(async (dialogResult) => {
            if (dialogResult && yearValue && monthValue && dialogResult.buttonText === 'Тийм') {
                try {
                    setLoader(true);
                    let check = 0;
                    let sendData = _.chunk(excelData, 100);
                    //let last;
                    if (!yearValue) {
                        setLoader(false);
                        setIsDisabled(false);
                        return notify('Сараа сонгоно уу.', 'warning', 2000);
                    }
                    if (excelData.length === 0) {
                        setLoader(false);
                        setIsDisabled(false);
                        return notify('Excel файл аа оруулаагүй байна.', 'error', 2000);
                    }

                    for (let i = 0; i < sendData.length; i++) {
                        let r = await FinanceReportService.setExcelFileInputPayroll(sendData[i]);
                        if (r.code !== 200) {
                            check++;
                            setLoader(false);
                        }
                    }
                    notify('Цалингийн товчоо амжилттай илгээгдлээ', 'success');
                    setExcelData([]);
                    setIsDisabled(false);
                    setMonthValue(null);
                    setLoader(false);
                } catch (e) {
                    setLoader(false);
                    setIsDisabled(false);
                    return notify(`${monthValue} - ${e.response.data.message} `, 'error', 2000);
                }
            }
        });

    }
    const onChangeModalImport = (e) => {
        setModalImport(true);
    }

    const cellTemplate = (cellData, cellIndex, cellElement) => {
        switch (cellData.text) {
            case 'Jan':
                return '1 сар'
            case 'Feb':
                return '2 сар'
            case 'Mar':
                return '3 сар'
            case 'Apr':
                return '4 сар'
            case 'May':
                return '5 сар'
            case 'Jun':
                return '6 сар'
            case 'Jul':
                return '7 сар'
            case 'Aug':
                return '8 сар'
            case 'Sep':
                return '9 сар'
            case 'Oct':
                return '10 сар'
            case 'Nov':
                return '11 сар'
            case 'Dec':
                return '12 сар'
        }
    }
    const renderModalImport = props => {
        return (
            <Popup
                visible={modalImport}
                onHiding={() => {
                    setModalImport(false);
                    setExcelData([]);
                    setIsDisabled(false);
                    setMonthValue(null);
                }}
                hideOnOutsideClick={true}
                showCloseButton={true}
                height="55vh"
                width="50%"
                showTitle={true}
                title={"Цалингийн товчоо илгээх"}
            >
                <div className="card performance">
                    <div className=" performanceTabs" style={{ paddingTop: ".25rem", paddingBottom: "1rem" }}>
                        <div style={{ background: "#F5F6FA", height: ".25rem", width: "100%" }} />
                        <div className="card-body">
                            <div style={{ display: 'flex', justifyContent: "center", flexDirection: 'column' }}>
                                <Button icon="download"
                                    className="hzText-bold"
                                    text="Excel загвар татах "
                                    onClick={exportExcel} />
                                <br />
                                <DateBox applyValueMode="instantly"
                                    type='date'
                                    className="hzText-bold"
                                    defaultValue={monthValue}
                                    placeholder={"Та цалингийн товчоо оруулах сараа заавал сонгоно уу"}
                                    displayFormat="yyyy-MM"
                                    applyButtonText="Сонгох"
                                    cancelButtonText="Болих"
                                    invalidDateMessage={'Формат буруу байна жишээ нь 2023-01'}
                                    calendarOptions={{
                                        maxZoomLevel: 'year',
                                        minZoomLevel: 'century',
                                        max: new Date(),
                                        cellTemplate: cellTemplate
                                    }}
                                    onValueChanged={onValueChangedDate}
                                />
                                <br />
                                {yearValue && monthValue &&
                                    <FileUploader multiple={false}
                                        uploadMode="useForm"
                                        icon="upload"
                                        accept='.xlsx'
                                        className="hzText-bold"
                                        selectButtonText="Файл оруулах"
                                        labelText=""
                                        type="default"
                                        onValueChanged={uploadProgress}
                                        showFileList={true}
                                        maxFileSize={15000000}
                                    />
                                }
                                <br />
                                {excelData.length >= 0 &&
                                    <Button
                                        disabled={!isDisabled}
                                        icon={paperPlaneSvg}
                                        className="hzText-bold"
                                        text="Цалингийн товчоо илгээх"
                                        style={{
                                            borderRadius: '10px',
                                            flex: 1,
                                            color: '#fff', textAlign: "center",
                                            backgroundColor: `#4468E2`,
                                        }}
                                        onClick={onClick}
                                        activeStateEnabled={false}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </Popup>
        )
    }
    return (
        <React.Fragment>
            <LoadPanelComponent position={{ of: '#load2' }} visible={loader} />
            <div style={{ padding: 10 }}>
                <div style={{ display: 'flex', flexWrap: 'wrap', gap: '1rem', alignItems: "end" }}>
                    <div>
                        <SelectBox
                            items={getYears(true)}
                            defaultValue={currentYear}
                            label="Төсвийн жил"
                            width='10rem'
                            onValueChanged={(e) => {
                                setCurrentYear(e.value);
                                loadOrgs(e.value)
                            }}
                            placeholder="сонгох" />
                    </div>
                    <div>
                        <SelectBox
                            items={monthList}
                            valueExpr={'value'}
                            displayExpr={'name'}
                            label="Сар"
                            width='8rem'
                            defaultValue={selectedMonth}
                            onValueChanged={(e) => {
                                setSelectedMonth(e.value)
                            }}
                            placeholder="сонгох" />
                    </div>
                    <div>
                        <SelectBox
                            items={orgs}
                            label="Байгууллага"
                            width='22rem'
                            value={chosenValue}
                            valueExpr="value_name"
                            displayExpr="text_name"
                            onValueChanged={(e) => setChosenValue(e.value)}
                            placeholder={user.organization.obt_id !== 3 ? 'БҮГД' : user.organization.name}
                            />
                    </div>
                    <div>
                        <Button
                            onClick={onChangeModalImport}
                            style={{
                                borderRadius: 4,
                                flex: 1,
                                color: '#fff', 
                                textAlign: "center",
                                backgroundColor: `#4468E2`,
                            }}
                            icon={paperPlaneSvg}
                            stylingMode="text"
                            text="Цалингийн товчоо илгээх"
                        />
                    </div>
                </div>
            </div>
            <PayrollReportHeaderInfo year={currentYear}
                filterOrgText={selectedOrg !== undefined ? selectedOrg.name : chosenValue}
                selectedOrg={selectedOrg !== undefined ? selectedOrg.org_id : undefined}
                selectedMonth={selectedMonth} />
            <div style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
                {
                    selectedMonth !== 0 && <div style={{ "margin-bottom": 10, flex: 0.3, width: "30%" }}>
                        <div className="card" style={{ height: "100%" }}>
                            <div className="card-header">
                                <span className="hzText-bold" style={{
                                    fontWeight: "700",
                                    color: '#15186A'
                                }}>1. НЭГТГЭЛ - {selectedOrg !== undefined ? selectedOrg.name : chosenValue === 'ALL' ? 'Бүгд' : chosenValue}</span>
                            </div>
                            <div className="card-body">
                                <PayrollReportPieNegtgel year={currentYear} selectedMonth={selectedMonth}
                                    selectedOrg={selectedOrg !== undefined ? selectedOrg.org_id : undefined}
                                    filterOrgText={selectedOrg !== undefined ? selectedOrg.name : chosenValue} />
                            </div>
                        </div>
                    </div>
                }
                <div style={{
                    "margin-bottom": 10,
                    flex: selectedMonth === 0 ? 1 : 0.7,
                    width: selectedMonth === 0 ? "100%" : "70%"
                }}>
                    <div className="card" style={{ height: "100%" }}>
                        <div className="card-header">
                            <span className="hzText-bold" style={{
                                fontWeight: "700",
                                color: '#15186A'
                            }}>{selectedMonth === 0 ? "1." : 1.1} БАЙГУУЛЛАГААР /{selectedMonth === 0 ? 'жилээр' : 'сараар'}, сая ₮/</span>
                        </div>
                        <div className="card-body">
                            <PayrollWithOrgs year={currentYear} filterOrgText={chosenValue} selectedOrg={(data) => {
                                setSelectedOrg(data)
                            }} selectedMonth={selectedMonth} />
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ flexDirection: 'row', flex: 1 }} className="d-flex responsive-flex">
                <div style={{ "margin-bottom": 10, flex: 0.5 }} className="col-6 responsive-col">
                    <div className="card" style={{ height: "100%" }}>
                        <div className="card-header">
                            <span
                                className="hzText-bold" style={{
                                    fontWeight: "700",
                                    color: '#15186A'
                                }}>2. НЭГТГЭЛ /{'сараар'}, сая ₮/ - {selectedOrg !== undefined ? selectedOrg.name : chosenValue === 'ALL' ? 'Бүгд' : chosenValue}</span>
                        </div>
                        <div className="card-body">
                            <PayrollReportBarChart year={currentYear}
                                selectedOrg={selectedOrg !== undefined ? selectedOrg.org_id : undefined} />
                        </div>
                    </div>
                </div>
                <div style={{ "margin-bottom": 10, flex: 0.5 }} className="col-6 responsive-col">
                    <div className="card" style={{ height: "100%" }}>
                        <div className="card-header">
                            <span
                                className="hzText-bold" style={{ fontWeight: "700", color: '#15186A' }}>2.1 Цалингийн товчоо илгээсэн мэдээ /{'сараар'}, сая ₮/ - {selectedOrg !== undefined ? selectedOrg.name : chosenValue === 'ALL' ? 'Бүгд' : chosenValue}</span>
                        </div>
                        <div className="card-body">
                            <PayrollWithOrgsMonth year={currentYear}
                                selectedOrg={selectedOrg !== undefined ? selectedOrg.org_id : undefined} />
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div className="card" >
                    <div className="card-header" style={{ height: '45px' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <span className="hzText-bold" style={{ fontWeight: "700", color: '#15186A' }}>3. ЦАЛИНГИЙН ТОВЧОО /сая ₮/ - {selectedOrg !== undefined ? selectedOrg.name : chosenValue === 'ALL' ? 'Бүгд' : chosenValue}</span>
                            <div style={{ flexDirection: 'row', justifyContent: 'flex-end', display: 'flex' }}>
                                <div className="hzText-bold" style={{
                                    fontWeight: "700",
                                    color: '#15186A',
                                    display: 'flex',
                                    alignItems: 'center',
                                    padding: '0 10px 0 10px'
                                }}>Харах хэлбэр:
                                </div>
                                <div style={{ marginTop: 5 }}>
                                    <SelectBox
                                        items={payrollViewList}
                                        valueExpr={'value'}
                                        displayExpr={'name'}
                                        className="hzText-bold"
                                        style={{ borderRadius: '10px' }}
                                        defaultValue={chosenViewList}
                                        width={180}
                                        onValueChanged={(e) => {
                                            setChosenViewList(e.value)
                                        }}
                                        placeholder="сонгох" />
                                </div></div>

                        </div>
                    </div>
                    {
                        chosenViewList ?
                            <div className="card-body">
                                <PayrollReportPivotList year={currentYear}
                                    filterOrgText={selectedOrg !== undefined ? selectedOrg.name : chosenValue}
                                    selectedOrg={selectedOrg !== undefined ? selectedOrg.org_id : undefined} selectedMonth={selectedMonth} isPivot={chosenViewList} />
                            </div> :
                            <div className="card-body">
                                <PayrollReportList year={currentYear}
                                    filterOrgText={selectedOrg !== undefined ? selectedOrg.name : chosenValue}
                                    selectedOrg={selectedOrg !== undefined ? selectedOrg.org_id : undefined} selectedMonth={selectedMonth} isPivot={chosenViewList} />
                            </div>
                    }

                </div>
            </div>
            {modalImport && renderModalImport()}
        </React.Fragment>
    )
}

export default PayrollReport;

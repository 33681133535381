import React, {useState} from 'react'
import './components.scss'

function CustomTabComponent({ list,children, titleWidth, marginLeft}) {
    const [selectedIndex, setSelectedIndex]= useState(0)
  return (
    <div className='customTabComponent'>
        <div className='title-container' style={{width: titleWidth, marginLeft: marginLeft ? marginLeft : "5%"}}>
            {list.map((item, index)=>(
                <div className={`customTab-title ${index=== selectedIndex ? "active" : ""}`}>
                    <div className='customTab-title-text' onClick={()=>setSelectedIndex(index)}>
                        {item}
                    </div>
                </div>
            ))}
        </div>
        <div style={{width: `calc(${titleWidth} / ${children.length})`, marginLeft: marginLeft ? marginLeft : "5%", transform: `translateX(${selectedIndex * 100}%)`}} className={`underline`} />
        <div className='content-container'>
            {children[selectedIndex]}
        </div>
    </div>
  )
}

export default CustomTabComponent
import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../hooks/UserContext";
import RemainingService from "../../../services/api/remainingService";
import { TextBox, Button as TextBoxButton } from 'devextreme-react/text-box';
import { Popup } from "devextreme-react/popup";
import AuthService from "../../../services/api/auth";
import { AiFillCheckSquare, AiFillCloseSquare, AiOutlinePlus } from "react-icons/ai";
import { TbListDetails } from 'react-icons/tb'
import "../myInfo.module.css";
import "./RoleSection.css";
import Form, {
  GroupItem,
  Label,
  SimpleItem,
} from "devextreme-react/form";
import Validator, {
  RequiredRule,
  CompareRule,
  AsyncRule,
} from 'devextreme-react/validator';
import styles from "../myInfo.module.css";
import notify from "devextreme/ui/notify";
import LoadPanelComponent from "../../budget/components/loadPanel";
import Button from "devextreme-react/button";
import { renderUserDetail } from "..";
import { FaUserLock } from 'react-icons/fa';
import DataGrid, { Column } from "devextreme-react/data-grid";
import { BsCheck2, BsCheck2Circle, BsEye, BsEyeSlash } from "react-icons/bs";
import { RiErrorWarningLine } from "react-icons/ri";


function RoleSection({ popupVisible, setPopupVisible }) {
  const { user, setUser } = useContext(UserContext);
  const [isFocused, setIsFocused] = useState(true);
  const [inputValue, setInputValue] = useState("");
  const [popupChangePassword, setPopupChangePassword] = useState(false);
  const [isLoading, setIsloading] = useState(false);
  const [logDetail, setLogDetail] = useState(false);
  const [logData, setLogData] = useState([])
  const [pageIndex, setPageIndex] = useState(0);
  const [values, setValues] = useState(
    {
      currentPassword: '',
      newPassword: '',
      confirmPassword: ''
    }
  )
  const [is6characters, setIs6characters] = useState(false)
  const [isLowercaseLetter, setIsLowercaseLetter] = useState(false)
  const [isUppercaseLetter, setIsUppercaseLetter] = useState(false)
  const [isNumberOrSpecial, setIsNumberOrSpecial] = useState(false)
  const [passwordDoNotMatch, setPasswordDoNotMatch] = useState(false)
  const [isPassValid, setIsPassValid] = useState(false)
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const isNULL = () => {
    if (values.currentPassword === '' || values.newPassword === '' || values.confirmPassword === '') {
      notify({
        message: "Талбар хоосон байна!",
        width: 'fit-content'
      }, "warning", 2000);
    } else if (values.newPassword !== values.confirmPassword) {
      return setPasswordDoNotMatch(true)
    } else {
      if (is6characters && isLowercaseLetter && isUppercaseLetter && isNumberOrSpecial) {
        onSubmit();
      } else {
        setIsPassValid(true);
        notify({
          message: "Нууц үг шаардлага хангахгүй байна!",
          width: 'fit-content'
        }, "error", 2000);
      }
    }
  }

  const onSubmit = async () => {
    await AuthService.changePassword(values).then(res => {
      notify({
        message: "Нууц үг амжилттай солигдлоо.",
        width: 'fit-content'
      }, "success", 1000,);
      setPopupChangePassword(false);
    }).catch(e => {
      notify({ message: e.response.data.message, width: 'fit-content' }, "warning", 1000);
    })
  }

  useEffect(() => {
    setValues({
      currentPassword: '',
      newPassword: '',
      confirmPassword: ''
    })
    setIs6characters(false)
    setIsLowercaseLetter(false)
    setIsUppercaseLetter(false)
    setIsNumberOrSpecial(false)
    setPasswordDoNotMatch(false)
    setIsPassValid(false)
    setShowCurrentPassword(false)
    setShowNewPassword(false)
    setShowConfirmPassword(false)
  }, [popupChangePassword])

  useEffect(() => {
    (async () => {
      try {
        const logs = await RemainingService.getUserLogs();
        setLogData(logs.data);
      } catch (e) {
        console.log(e, ' -> logs');;
      }
    })()
  }, []);

  const onCellPreparedHandler = (e) => {
    if (e.rowType === 'header') {
      e.cellElement.wordWrapEnabled = true;
      e.cellElement.style.fontFamily = 'Segoe UI'
      e.cellElement.style.fontWeight = 'bold'
      e.cellElement.style.color = "#15186A"
      e.cellElement.style.backgroundColor = "#EEF0F4"
    }
    if (e.rowType === 'data') {
      e.cellElement.style.fontFamily = 'Segoe UI'
      e.cellElement.style.fontWeight = 'semibold'
      e.cellElement.style.color = "#000000"
    }
  }

  const handleNewPassword = (e) => {
    const newPassword = e.target.value
    const hasLowercaseLetter = /^(?=.*[a-z])/.test(newPassword);
    setIsLowercaseLetter(hasLowercaseLetter);
    const hasUppercaseLetter = /^(?=.*[A-Z])/.test(newPassword);
    setIsUppercaseLetter(hasUppercaseLetter);
    const hasNumberOrSpecial = /^(?=.*[1-9!@#$%^&*_])/.test(newPassword);
    setIsNumberOrSpecial(hasNumberOrSpecial);
    const has6characters = /^.{6,}$/.test(newPassword);
    setIs6characters(has6characters);
  };

  const position = { of: "#page" };
  return (
    <div className={styles.compBody} style={{ position: "relative" }}>
      <LoadPanelComponent position={position} visible={isLoading} />
      <div className="RoleHeader" style={{
        display: "flex",
        justifyContent: "space-between",
        fontWeight: "bold"
      }}>
        <div style={{ color: "#15186a", fontSize: "1rem" }}>
          СИСТЕМИЙН МЭДЭЭЛЭЛ
        </div>
        <button onClick={() => setPopupChangePassword(!popupChangePassword)} className={styles.actionBtns}>
          <FaUserLock />
          <span style={{fontSize: 14}}>Нууц үг солих</span>
        </button>
      </div>
      <div className="card-body" style={{ wordBreak: 'break-word' }}>
        {renderUserDetail('Нэвтрэх нэр', user.username)}
        {renderUserDetail('Нэвтрэх имэйл', user.email)}
        {renderUserDetail('Нэвтрэх утасны дугаар', user.mobile)}
        {user.worker_id !== null && ((user.email === null || user.email === '') && <div className={styles.row}>
          <div className={styles.columnRight}>Нэвтрэх имэйл:</div>
          <div className={styles.columnLeft}>
            <div className={styles.addBtnColumnLeft} onClick={() => setPopupVisible(!popupVisible)}><AiOutlinePlus /> Нэмэх</div>
          </div>
        </div>)}
        {user.worker_id !== null && ((user.mobile === null || user.mobile === '') && <div className={styles.row}>
          <div className={styles.columnRight}>Нэвтрэх утасны дугаар:</div>
          <div className={styles.columnLeft}>
            <div className={styles.addBtnColumnLeft} onClick={() => setPopupVisible(!popupVisible)}><AiOutlinePlus /> Нэмэх</div>
          </div>
        </div>)}
        {renderUserDetail('Системийн эрх', user.roles[0].name)}
        {renderUserDetail('Системийн эрх авсан хугацаа', user.start_date === null ? "Хоосон байна" : user.start_date)}
        {renderUserDetail('Системийн эрх дуусах хугацаа', user.end_date === null ? "Хоосон байна" : user.end_date)}
        {renderUserDetail('Төлөв', isFocused ? (
          <>
            <span>
              {user.status === 1 ? "Идэвхтэй" : "Идэвхгүй"}
              {
                user.status === 1 ?
                  <AiFillCheckSquare style={{ marginLeft: "3px", marginBottom: "-3px", color: "#50C687" }} />
                  :
                  <AiFillCloseSquare style={{ marginLeft: "3px", marginBottom: "-3px", color: "#ff0000" }} />
              }
            </span>
          </>
        ) : (
          <TextBox
            placeholder="text"
            valueChangeEvent="keyup"
            value={inputValue}
            onValueChanged={(e) => setInputValue(e.value)}
            stylingMode="filled"
          />
        ))}
        {<div>

          <div className={styles.row}>
            <div className={styles.columnRight}>Хандалтын мэдээлэл:</div>
            <div className={styles.columnLeft}>
              <div className={styles.addBtnColumnLeft} onClick={() => setLogDetail(!logDetail)}>Дэлгэрэнгүй <TbListDetails /></div>
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.columnRight}>Сүүлд нэвтэрсэн огноо:</div>
            <div className={styles.columnLeft}>
              <span>{logData[0]?.updatedAt + ' ' + logData[0]?.createdAt.slice(0, 8)}</span>
            </div>
          </div>
        </div>
        }
      </div>
      <Popup
        id="passwordChangePopup"
        visible={popupChangePassword}
        onHiding={() => setPopupChangePassword(false)}
        showCloseButton={true}
        dragEnabled={false}
        hideOnOutsideClick={true}
        width={'35rem'}
        height={'auto'}
      >
        <div style={{ color: "#15186a", fontSize: "1rem", fontWeight: "bold", padding: '0 1rem 1.5rem', fontFamily: "Segoe UI", }}>НЭВТРЭХ НУУЦ ҮГ СОЛИХ:</div>
        <>
          <div style={{ padding: '0 1rem 1.5rem' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '1rem', alignItems: 'center' }}>
              <label>Хуучин нууц үг:</label>
              <div className="inputDiv">
                <input
                  type={showCurrentPassword ? "text" : "password"}
                  value={values.currentPassword}
                  onChange={(e) => setValues({ ...values, currentPassword: e.target.value })}
                  className="passwordInput"
                />
                <button onClick={()=>setShowCurrentPassword(!showCurrentPassword)}>{showCurrentPassword ? <BsEye color="#1890ff" /> : <BsEyeSlash />}</button>
              </div>
            </div>
            <div>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '.5rem' }}>
                <label>Шинэ нууц үг:</label>
                <div className="inputDiv">
                  <input type={showNewPassword ? "text" : "password"} style={{ borderColor: (passwordDoNotMatch || isPassValid) && 'red' }}
                    value={values.newPassword}
                    onChange={(e) => {
                      setValues({ ...values, newPassword: e.target.value });
                      handleNewPassword(e)
                    }}
                    className="passwordInput"
                  />
                  <button onClick={()=>setShowNewPassword(!showNewPassword)}>{showNewPassword ? <BsEye color="#1890ff"/> : <BsEyeSlash />}</button>
                </div>
              </div>
              <div style={{ marginLeft: '14rem', fontSize: 12 }}>
                <div style={{ color: isLowercaseLetter ? 'green' : 'red', display: 'flex', alignItems: 'center', gap: 5 }}>{isLowercaseLetter && <BsCheck2 color="green" />}1 жижиг үсэг</div>
                <div style={{ color: isUppercaseLetter ? 'green' : 'red', display: 'flex', alignItems: 'center', gap: 5 }}>{isUppercaseLetter && <BsCheck2 color="green" />}1 том үсэг</div>
                <div style={{ color: is6characters ? 'green' : 'red', display: 'flex', alignItems: 'center', gap: 5 }}>{is6characters && <BsCheck2 color="green" />}6 болон түүнээс дээш урттай</div>
                <div style={{ color: isNumberOrSpecial ? 'green' : 'red', display: 'flex', alignItems: 'center', gap: 5 }}>{isNumberOrSpecial && <BsCheck2 color="green" />}1 тоо эсвэл 1 онцгой тэмдэгт агуулсан байх</div>
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '1rem', alignItems: 'center' }}>
              <label>Шинэ нууц үг давтах:</label>
              <div className="inputDiv">
                <input type={showConfirmPassword ? "text" : "password"} style={{ borderColor: (passwordDoNotMatch || isPassValid) && 'red' }}
                  value={values.confirmPassword}
                  onChange={(e) => setValues({ ...values, confirmPassword: e.target.value })}
                  className="passwordInput"
                />
                <button onClick={()=>setShowConfirmPassword(!showConfirmPassword)}>{showConfirmPassword ? <BsEye color="#1890ff"/> : <BsEyeSlash />}</button>
              </div>
            </div>
            {passwordDoNotMatch &&
              <div style={{ margin: '0.5rem 0 0 14rem', color: 'red', display: 'flex', alignItems: 'center', gap: 5 }}><RiErrorWarningLine color="red" />Нууц үг тохирохгүй байна.</div>}
          </div>
          <button
            onClick={isNULL}
            style={{
              backgroundColor: "#003696",
              color: "whitesmoke",
              border: "1px solid #003696",
              borderRadius: "10px",
              width: "10rem",
              height: "36px",
              textAlign: "center",
              fontFamily: "Segoe UI",
              fontSize: "12px",
              fontWeight: "600",
              float: 'right',
              margin: '0 1rem 1rem'
            }}
          >
            Шинэчлэх
          </button>
        </>
      </Popup>
      <Popup
        id="passwordChangePopup"
        visible={logDetail}
        onHiding={() => setLogDetail(false)}
        showCloseButton={true}
        dragEnabled={false}
        hideOnOutsideClick={true}
        height={'auto'}
      >
        <DataGrid
          dataSource={logData}
          rowAlternationEnabled={true}
          allowColumnResizing={true}
          wordWrapEnabled={true}
          paging={{ pageSize: 10 }}
          showBorders={true}
          showColumnLines={true}
          showRowLines={true}
          onCellPrepared={onCellPreparedHandler}
          columnAutoWidth={true}
          scrolling={true}
        >
          <Column
            cellRender={(data) => {
              return <div style={{ color: "#171010" }}>{pageIndex * 10 + data.rowIndex + 1}</div>;
            }
            }
            caption="№"
            allowEditing={false}
            alignment="center"
          />
          <Column
            dataField="org_name"
            caption="Байгууллага"
            alignment="center"
          />
          <Column
            dataField="username"
            caption="Нэвтрэх нэр"
            alignment="center"
          />
          <Column
            caption="Нэвтэрсэн огноо"
            alignment="center"
            cellRender={(val) => {
              return <div>{val.data.updatedAt + ' ' + val.data.createdAt.slice(0, 8)}</div>
            }}
          />
          <Column
            dataField="location_work"
            caption="Дотоодоос хандсан газар"
            alignment="center"
          />
          <Column
            dataField="country_name"
            caption="Улс"
            alignment="center"
          />
          <Column
            dataField="city"
            caption="Хот"
            alignment="center"
          />
          <Column
            dataField="ipv4"
            caption="IP хаяг"
            alignment="center"
          />
          <Column
            dataField="isp"
            caption="ISP"
            alignment="center"
            width={200}
          />
          <Column
            caption="Төлөв"
            dataField="action_type"
            cellRender={(cellData) => {
              const act = cellData.data.action_type;
              return (
                <div style={{ justifyContent: 'center' }}>
                  <p style={{ borderRadius: '1rem', margin: '1rem', fontWeight: 'bold', fontSize: 12, backgroundColor: act.toLowerCase() === 'login' || act.toLowerCase() === 'нэвтрэх' ? '#daf2e5' : '#fff2f2', color: act.toLowerCase() === 'login' || act.toLowerCase() === 'нэвтрэх' ? '#50c687' : '#fa4848' }}>{act}</p>
                </div>
              )
            }}
            alignment="center"
          />
        </DataGrid>
      </Popup>
    </div>
  );
}

export default RoleSection;
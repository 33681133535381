import React from "react"
import GaPopularPaths from "./components/GaPopularPaths"
import GaEvents from "./components/GaEvents"
import GaUserMetrics from "./components/GaUserMetrics"
import GaDailyWeeklyMonthly from "./components/GaDailyWeeklyMonthly"
// import {DateRangeBox} from "devextreme-react"
function GoogleAnalytics() {
  return (
    <div>
      <div className="card" style={{padding: 10}}>
        <div
          style={{
            display: "flex",
            marginBottom: 10,
            marginTop: "10",
            flexDirection: "row",
          }}
        >
          <div style={{display: "flex"}}>
            <div
              style={{
                display: "flex",
                fontWeight: "700",
                alignItems: "center",
                padding: "0 10px 0 10px",
              }}
            >
              Харуулах хугацааны хүрээ:
            </div>
            <div>
              {/* <DateRangeBox
                multiView={false}
                applyValueMode="useButtons"
                startDateText="Эхлэх өдөр"
                endDateText="Төгсөх өдөр"
                todayButtonText="Өнөөдөр"
                applyButtonText="Сонгох"
                cancelButtonText="Цуцлах"
                labelMode="floating"
                showClearButton
                calendarOptions={{firstDayOfWeek: 1}}
              /> */}
            </div>
          </div>
        </div>
      </div>

      <div style={{display: "flex", flexDirection: "row", flex: 1}}>
        <div style={{flex: 0.5}}>
          <GaPopularPaths />
        </div>
        <div style={{flex: 0.5}}>
          <GaEvents />
        </div>
      </div>
      <div style={{display: "flex", flexDirection: "row", flex: 1}}>
        <div style={{flex: 0.5}}>
          <GaUserMetrics />
        </div>
        <div style={{flex: 0.5}}>
          <GaDailyWeeklyMonthly />
        </div>
      </div>
    </div>
  )
}

export default GoogleAnalytics

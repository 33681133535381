import React, { useContext, useEffect, useState } from "react";
import "devextreme-react/text-area";
import styles from "../myInfo.module.css";
import UserService from "../../../services/api/user";
import { urlServer } from "../../../shared/lib/request";
import RemainingService from "../../../services/api/remainingService";
import { AiTwotoneEdit } from "react-icons/ai";
import { MdOutlineShare } from "react-icons/md";
import "./RoleSection.css"
import "../myInfo.module.css";
import { renderUserDetail } from "..";
import img from "../../../assets/imgs/userLogo.png";
import { isArray } from "lodash";
import { Button, DropDownBox, Popup, ScrollView } from "devextreme-react";
import Form, { Item, Label, SimpleItem } from 'devextreme-react/form';
import DataGrid, { Column, GroupPanel, Grouping, Scrolling, SearchPanel, Selection } from "devextreme-react/data-grid";
import OrganizationService from "../../../services/api/organization";
import AuthService from "../../../services/api/auth";
import { FilterRow } from "devextreme-react/tree-list";
import notify from "devextreme/ui/notify";
import Joyride from 'react-joyride';
import { connectWorkerStep, guideLocale } from "../../../components/SystemGuideSteps";
import { UserContext } from "../../../hooks/UserContext";


function UserSection({ visible }) {
  const { user, setUser } = useContext(UserContext);
  const [workerData, setWorkerData] = useState([])
  const [loader, setLoader] = useState(false);
  const [workerPopup, setWorkerPopup] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const [gridBoxValue, setGridBoxValue] = useState(null);

  useEffect(() => {
    getWorker()
  }, [workerPopup])

  const getWorker = async () => {
    try {
      const res = await OrganizationService.getOrgWorkerToConnect();
      setWorkerData(res.data);
      return setLoader(false)
    } catch (e) {
      console.error(e);
    }
  }

  const setWorker = async () => {
    try {
      await AuthService.setOrgWorker(gridBoxValue);
      setWorkerPopup(false);
      notify({ message: 'Албан хаагчийн мэдээлэл холбогдлоо.', width: 'fit-content' }, "success", 2000);
      let res = await AuthService.getProfile()
      setUser(res.data)
    } catch (e) {
      console.log(e);
    }
  }

  const onSelectionChanged = (args) => {
    setGridBoxValue(args.selectedRowsData[0]);
    setIsDropdownOpen(false);
  }

  const boxOptionChanged = (e) => {
    if (e.name === 'opened') {
      setIsDropdownOpen(e.value)
    }
  }

  const contentRender = () => {
    return (workerData &&
      <ScrollView >
        <DataGrid
          dataSource={workerData}
          hoverStateEnabled={true}
          height={'25rem'}
          onSelectionChanged={onSelectionChanged}
          focusedRowEnabled={true}
          keyExpr="worker_id"
          selectedRowKeys={gridBoxValue}
          columnAutoWidth={true}
        >
          <GroupPanel visible={true} />
          <SearchPanel visible={true} />
          <Grouping autoExpandAll={false} />
          <FilterRow visible={true} />
          <Column dataField="heltes_name" caption="Хэлтэс" groupIndex={1} />
          <Column dataField="last_name" caption="Овог" />
          <Column dataField="first_name" caption="Нэр" />
          <Column dataField="position_name" caption="Албан тушаал" />
          <Column dataField="email" caption="Имэйл хаяг" />
          <Column dataField="mobile" caption="Утасны дугаар" width={'6rem'}/>
          <Scrolling mode="virtual" />
          <Selection mode="single" />
        </DataGrid>
      </ScrollView>
    )
  }

  const gridBoxDisplayExpr = () => {
    return gridBoxValue && ((gridBoxValue.last_name ? gridBoxValue.last_name.slice(0, 1) + '. ' : '') + gridBoxValue.first_name);
  }

  return (
    <div style={{ fontFamily: "Segoe UI" }}>
      <div className={`${styles.compBody} orgSection`}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            fontWeight: "bold"
          }}
        >
          <div style={{ color: "#15186a", fontSize: "1rem" }}>
            ХУВИЙН МЭДЭЭЛЭЛ
          </div>
          {user.worker_id ?
            (<button onClick={() => visible("true")} className={styles.actionBtns} >
              <AiTwotoneEdit fontSize={'.8rem'} />
              <span style={{fontSize: 14}}>Мэдээлэл шинэчлэх</span>
            </button>
            ) : (
              <button id="connectOrgWorker" onClick={() => setWorkerPopup(true)} className={styles.actionBtns} >
                <MdOutlineShare style={{ padding: '' }} />
                <span style={{fontSize: 14}}>Албан хаагч холбох</span>
              </button>
            )}
        </div>
        <div className="card-body" style={{ wordBreak: 'break-word', position: 'relative' }}>
        {user.worker_id !== null &&
          <img alt=""
            className={
              user.file_path ? styles.customImg : styles.readyImg
            }
            style={{
              height: "6rem",
              width: "6rem",
              objectFit: "cover",
              borderRadius: "50%",
              position: 'absolute',
              top: '20%',
              left: '1rem'
            }}
            src={
              user.file_path == null && user.file_name == null
                ? img
                : user.file_path !== null
                  ? urlServer + "/api/file/" + user.file_path
                  : urlServer + "/api/file/documents/" + user.file_name
            }
          />
        
      }
      <div>
          {renderUserDetail('Овог', user.worker_id === null ? ' ' : user.last_name ? user.last_name : ' ')}
          {renderUserDetail('Нэр', user.worker_id === null ? ' ' : user.first_name ? user.first_name : ' ')}
          {renderUserDetail('Албан тушаал', user.worker_id === null ? ' ' : user.position_name ? user.position_name : ' ')}
          {renderUserDetail('Имэйл', user.worker_id === null ? ' ' : user.email ? user.email : ' ')}
          {renderUserDetail('Ажлын утас', user.worker_id === null ? ' ' : user.phone ? user.phone : ' ')}
          {renderUserDetail('Гар утас', user.worker_id === null ? ' ' : user.mobile ? user.mobile : ' ')}

      </div>
        </div>
      </div>
      {user.worker_id === null &&
        <Joyride
          steps={connectWorkerStep}
          run={true}
          locale={guideLocale}
          spotlightClicks={true}
        />}
      <Popup
        visible={workerPopup}
        onHiding={() => setWorkerPopup(false)}
        dragEnabled={false}
        showCloseButton={true}
        hideOnOutsideClick={true}
        width={'40rem'}
        height={'auto'}
      >
        <div>
          <div style={{ color: "#15186a", fontSize: "1rem", fontWeight: "bold", padding: '0 1rem 1.5rem ', fontFamily: "Segoe UI", }}>
            АЛБАН ХААГЧ ХОЛБОХ: </div>
          <div style={{ padding: '0 1rem' }}>
            <Form
              formData={user}
            >
              <Item dataField="username" editorOptions={{ disabled: true, width: '50%' }}>
                <Label text="Нэвтрэх нэр" />
              </Item>
              <Item dataField="organization.name" editorOptions={{ disabled: true, width: '100%' }}>
                <Label text="Байгууллага" />
              </Item>
              <Item dataField="worker_id" editorType='dxSelectBox' >
                <Label text="Албан хаагч" />
                <DropDownBox
                  value={gridBoxValue}
                  openOnFieldClick={true}
                  placeholder="Ажилтан сонгох..."
                  displayExpr={gridBoxDisplayExpr}
                  valueExpr={"worker_id"}
                  onOptionChanged={boxOptionChanged}
                  opened={isDropdownOpen}
                  validationMessagePosition="auto"
                  dataSource={workerData}
                  contentRender={contentRender}
                  dropDownOptions={{ width: '50rem', maxWidth: '60rem', height: '25rem' }}
                />
              </Item>
              <SimpleItem>
                <Button
                  onClick={setWorker}
                  formMethod="submit"
                  style={{
                    backgroundColor: "#003696",
                    color: "whitesmoke",
                    border: "1px solid #003696",
                    borderRadius: "10px",
                    width: "10rem",
                    height: "36px",
                    textAlign: "center",
                    fontFamily: "Segoe UI",
                    fontSize: "12px",
                    fontWeight: "600",
                    float: 'right',
                    margin: '1rem 0'
                  }}
                  text="Хадгалах"
                />
              </SimpleItem>
            </Form>
          </div>
        </div>
      </Popup>
    </div>
  );
}
export default UserSection;

import React, {useContext, useEffect, useState, useRef} from 'react';
import 'devextreme-react/text-area';
import DataGrid, {
    Editing,
    Column,
    RequiredRule,
    PatternRule,
    Lookup, Summary, TotalItem, SearchPanel, Export, GroupPanel, Grouping, GroupItem, Button as DevButton
} from 'devextreme-react/data-grid';
import {UserContext} from "../../../hooks/UserContext";
import _ from "lodash";
import fcsService from "../../../services/api/fcs";
import Button from "devextreme-react/button";
import notify from "devextreme/ui/notify";
import SelectBox from "devextreme-react/select-box";
import DateBox from "devextreme-react/date-box";
import {LoadPanel} from "devextreme-react/load-panel";
import {Popup} from "devextreme-react/popup";
import ScrollView from "devextreme-react/scroll-view";
import PivotGridDataSource from "devextreme/ui/pivot_grid/data_source";
import PivotGrid from "devextreme-react/pivot-grid";
import {Workbook} from "exceljs";
import {exportDataGrid} from "devextreme/excel_exporter";
import {saveAs} from "file-saver-es";
import CustorgParent from "../Gl/gledit/custorgParent";
import LoadPanelComponent from '../../../components/LoadPanelComponent';

function Purchase(props) {
    const monthValue = props.monthValue
    const userListPosition = {of: '#usersList'}
    const {user} = useContext(UserContext);
    const [listData, setListData] = useState([]);
    const [loader, setLoader] = useState(false);
    const myBabyRef = useRef(null);
    const [dataFcsChart, setFcsChart] = useState([]);
    // const [dataFcsCustOrgn, setFcsCustOrgn] = useState([]);
    const [dataFcsApClass, setFcsApClass] = useState([]);
    const [dataFcsMonth, setFcsMonth] = useState([]);
    const initCust={name:''}
    const [custValue, setCustValue] = useState({...initCust});
    const [apClassValue, setApClassValue] = useState(null);
    const [docDate, setDocDate] = useState();
    const [brn, setBrn] = useState();
    const [modalVisibleBudget, setModalVisibleBudget] = useState(false);
    const [eventBudgetYear, setEventBudgetYear] = useState([]);
    const [agendaBudgetYear, setAgendaBudgetYear] = useState([]);
    const [economicBudgetYear, setEconomicBudgetYear] = useState([]);
    const [eventValue, setEventValue] = useState(null);
    const [agendaValue, setAgendaValue] = useState(null);
    const [economicValue, setEconomicValue] = useState(null);
    const [data, setData] = useState([]);
    let _pivotGrid = useRef(null);
    const [drillDownDataSource, setDrillDownDataSource] = useState(null);
    const [popupVisible, setPopupVisible] = useState(false);
    const [popupTitle, setPopupTitle] = useState('');
    const [modalVisibleCustOrg,setModalVisibleCustOrg]=useState(false)
    useEffect(() => {
        fcsAptranList();
    }, [])
    useEffect(() => {
        setDocDate(new Date());

    }, []);


    const changeMonth = async () => {
        const fcsMonth = await fcsService.getAllFcsMonth('AP');
        setFcsMonth(fcsMonth);
    }
    const fcsAptranList = async () => {
        try {
            setLoader(true);
            const fcsMonth = await fcsService.getAllFcsMonth('AP');
            setFcsMonth(fcsMonth);

            if ( fcsMonth && fcsMonth.length < 1) notify(" тохиргоо хийнэ үү  ", "warning")

            if ( fcsMonth && fcsMonth.length < 1) {
                setLoader(false)
                return
            }

            const fcsChart = await fcsService.getAllFcsChart();
            setFcsChart(fcsChart);
            // const fcsApClass = await fcsService.getAllFcsApClass(true);
            // setFcsApClass(fcsApClass);


            const fcsApClass = await fcsService.getFcsChart('AP',0,0,0,0);
            setFcsApClass(fcsApClass);

            // const fcsCustOrgn = await fcsService.getAllFcsCustOrgn();
            // setFcsCustOrgn(fcsCustOrgn);

            getBudgetForm(fcsMonth);
            return setLoader(false)
        } catch (e) {
            console.error(e);
            setLoader(false);
        }
    }
    const calculateAmt = (newData, value, currentRowData) => {
        newData.qty = value;
        newData.amt = currentRowData.unit * value;
    }
    const calculateAmt1 = (newData, value, currentRowData) => {
        newData.unit = value;
        newData.amt = currentRowData.qty * value;
    }
    const setPurchase = async () => {
        if (listData.length === 0 || listData === null) {
            notify("Худалдан авалтын мэдээлэл хоосон байна", "warning");
        } else if (brn === undefined || brn === '') {
            notify("Баримтын дугаар хоосон байна", "warning");
        } else if (custValue.name.length< 2  || custValue === '') {
            notify("Харилцагч хоосон байна", "warning");
        } else if (docDate === undefined || docDate === null) {
            notify("Огноо хоосон байна", "warning");
        } else if (apClassValue === null || apClassValue === '') {
            notify("Өглөгийн ангилал хоосон байна", "warning");
        }
        if (listData.length !== 0 && brn && custValue && docDate && apClassValue) {
            setLoader(true);
            //yyyymm
            const date = new Date(docDate);//new e.changes[0].data.docdate;
            const year = date.getFullYear() * 1e4; // 1e4 gives us the the other digits to be filled later, so 20210000.
            const month = (date.getMonth() + 1) * 100;
            const yyyymm = year + month // `+ ''` to convert to string from number, 202206
            const fcsCurrentMonth = dataFcsMonth.map((item) => {
                let date = item.year + '/' + item.month
                let datetimenew = new Date(date)
                let year = datetimenew.getFullYear() * 1e4; // 1e4 gives us the the other digits to be filled later, so 20210000.
                let month = (datetimenew.getMonth() + 1) * 100;
                const yyyymm_month = year + month
                return yyyymm_month
            });
            if (yyyymm === fcsCurrentMonth[0]) {
                try {
                    const result = await fcsService.getAllFcsMonth('AP');
                    result.map(e => {
                        e.orid = e.orid + 1
                    })

                    listData.map((item) => {
                        item.docdate = docDate;
                        item.docno = parseInt(result[0].orid);
                        item.org_id = user.org_id;
                        item.created_user_id = user.id;
                        item.brn = brn;
                        item.created_user_id = user.id;
                        item.apacct = apClassValue.acct;
                        item.apacct_name = apClassValue.descr;
                        item.cust = custValue.orgcode;
                        item.custname = custValue.name;
                        item.acct_name = _.filter(dataFcsChart, {acct: item.acct})[0].descr;
                        agendaValue ? item.agenda_id = agendaValue.agenda_id : item.agenda_id = 0
                        eventValue ? item.event_id = eventValue.event_id : item.event_id = 0
                        economicValue ? item.econ_category_id = economicValue.econ_category_id : item.econ_category_id = 0

                    })
                    await fcsService.addFcsAptran(listData);
                    await fcsService.editFcsMonth(result[0]);
                    setListData([]);
                    setDocDate(null);
                    setBrn('');
                    setCustValue({...initCust});
                    setApClassValue([]);
                    setAgendaValue([]);
                    setEventValue([]);
                    setEconomicValue([]);

                    await fcsAptranList();
                    notify('Амжилттай гүйлгээ хийгдлээ', "success", 2000);
                    return setLoader(false)
                } catch (e) {
                    notify('Шинээр гүйлгээ хийх явцад алдаа гарлаа', "error", 2000);
                    return setLoader(false)
                }


            } else {
                notify('Сонгосон огноо тухайн тайлант он сард биш байна', "error", 2000);
                return setLoader(false)
            }
        }

    }

    const onChangeApClassValue = (e) => {
        setApClassValue(e);
    }
    const onValueChangedDate = (e) => {
        setDocDate(e.value);
    }
    const onChangeDocNoValue = (event) => {
        setBrn(event.target.value);
    }

    const clickPlus = (grid) => {
        grid.current.instance.addRow();
        let list = {};
        let t = {
            descr: '',
            acct: '',
            qty: '',
            unit: 1,
            amt: '',
            vat: 0
        }
        list.push(t);
        setListData(list);
    }
    const onInitNewRow = (e) => {
        e.data.unit = 1;
        e.data.vat = 0;
    }
    const onRowInserted  = (e) => {
        e.component.navigateToRow(e.key);
    }
    const saveDataGrid = async (e) => {
    }
    const onEditingStart = (e) => {
    }
    const customizeDate = (data) => {
        return Number(parseFloat(data.value).toFixed(2)).toLocaleString("en", {
            minimumFractionDigits: 2,
        });
    }

    const getBudgetForm = async (fcsMonth) => {
        setLoader(true);
        const agenda = await fcsService.getAgendaBudgetYear(fcsMonth[0].year);

        if (agenda.data.length > 0) {
            setAgendaBudgetYear(agenda);
            // setModalVisibleBudget(true);
            return setLoader(false);
        } else {
            return setLoader(false);
        }
        setLoader(false)
    };

    const onChangeEventValue = (e) => {
        setEventValue(e);
        getEconomicDataByAgendaEvent(agendaValue.agenda_id, e.event_id);

    }
    const getEventDataByAgenda = async (agenda_id) => {
        const result = await fcsService.getEventBudgetYear(dataFcsMonth[0].year, agenda_id);
        setEventBudgetYear(result.data);

    }
    const getEconomicDataByAgendaEvent = async (agenda_id, event_id) => {
        const result = await fcsService.getEconomicBudgetYear(dataFcsMonth[0].year, agenda_id, event_id,2);
        setEconomicBudgetYear(result.data);

    }
    const onChangeAgendaValue = (e) => {
        if (e) {
            setAgendaValue(e);
            getEventDataByAgenda(e.agenda_id);
        }

    }
    const onChangeEconomicValue = (e) => {
        setEconomicValue(e);
        getBudgetYear(dataFcsMonth[0].year,agendaValue.agenda_id,eventValue.event_id,e.econ_category_id,);
    }
    const getBudgetYear = async (budget_year,agenda_id, event_id,econ_category_id) => {
        setLoader(true);
        const result = await fcsService.getBudgetYear(budget_year, agenda_id, event_id,econ_category_id);
        setData(result.data);
         setLoader(false);
    }
    const sortException = "Гүйцэтгэл";
    const dataSource = new PivotGridDataSource({
        fields: [
           /* {
                caption: 'Төсөл хөтөлбөр',
                dataField: 'agenda_name',
                width: 200,
                area: 'row',
                sortBySummaryField: 'Total',
                expanded: true
            },
            {
                caption: 'Зориулалт арга хэмжээ',
                dataField: 'event_name',
                width: 200,
                area: 'row',
                sortBySummaryField: 'Total',
                expanded: true
            },*/
            {
                caption: 'Эдийн засгийн ангилал 1',
                dataField: 'econ_category_name_1',
                width: 200,
                area: 'row',
                sortBySummaryField: 'Total',
            },
            {
                caption: 'econ_category_name_2',
                dataField: 'econ_category_name_2',
                width: 150,
                area: 'row'
            },
            {
                caption: 'econ_category_name_3',
                dataField: 'econ_category_name_3',
                width: 150,
                area: 'row'
            },
            {
                caption: 'Эдийн засгийн ангилал 4',
                dataField: 'econ_category_name_4',
                // width: 200,
                area: 'row',
                sortBySummaryField: 'amt',
                sortOrder: 'desc'
            },
            {
                caption: 'Эдийн засгийн ангилал 5',
                dataField: 'econ_category_name',
                // width: 200,
                area: 'row',
                sortBySummaryField: 'amt',
                sortOrder: 'desc'
            },
            {
                dataField: 'budget_month',
                dataType: 'budget_month' ,
                area: 'column',
                sortOrder: 'asc',
                expanded: true
            }, {
                dataField: 'budget_status_name',
                dataType: 'budget_status_name',
                area: 'column',
                expanded: true,
                sortingMethod: function (a, b) {
                    var index1 = a.value;
                    var index2 = b.value;
                    if (index1  === sortException)
                    {return 1;}
                    if(index2 === sortException)
                    {return -1;}
                    if (index1 > index2)
                        return 1;
                    if (index2 > index1)
                        return -1;
                    else
                        return 0;
                }
            },
            {
                caption: 'Нийт',
                dataField: 'amt',
                dataType: 'number',
                summaryType: 'sum',
                format: {
                    type: 'fixedPoint',
                    precision: 2
                },
                area: 'data'
            }],
        store: data
    });
    const onCellClick = (e) => {
        console.log('e', e);
        if (e.area === 'data' && e.cell.columnPath[1] === 'Санхүү Гүйцэтгэл') {
            const pivotGridDataSource = e.component.getDataSource();
            const rowPathLength = e.cell.rowPath.length;
            const rowPathName = e.cell.rowPath[rowPathLength - 1];
            setPopupTitle(`${rowPathName || 'Total'}`);
            setDrillDownDataSource( pivotGridDataSource.createDrillDownDataSource(e.cell));
            setPopupVisible(true);
        }
    }
    const onHiding = () =>  {
        setPopupVisible(false);
    }

    const onShown = () => {
        this.props.data.component.updateDimensions();
        //this.dataGrid.updateDimensions();
    }
    const onExporting = (e) => {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Main sheet');

        exportDataGrid({
            component: e.component,
            worksheet,
            autoFilterEnabled: true,
        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                //`${item.acct} - ${item.descr}`
                saveAs(new Blob([buffer], {type: 'application/octet-stream'}), `${popupTitle}.xlsx`);
            });
        });
        e.cancel = true;
    }
    const cloneIconClick = async (e) => {
        const list = [...listData];
        const clonedItem = {...e.row.data};
        list.splice(e.row.rowIndex, 0, clonedItem);
        setListData(list);
        e.event.preventDefault();
    }
    const style={
        text: {
            color: "#2E4765", fontWeight: "700", fontSize: ".875rem"
        },
        box: {
            border: "none", backgroundColor: "#F6F6F6", borderRadius: ".5rem", padding: ".25rem .15rem", width: "100%"
        }
    }
    return (
        <React.Fragment>
            <div>
                <LoadPanelComponent
                    shadingColor="rgba(0,0,0,0.4)"
                    position={userListPosition}
                    visible={loader}
                    message = ''
                    showPane={false}
                />
                 <div style={{ display: 'flex', marginBottom: 10, marginTop: 10,marginLeft:10 }}>
                <span> {dataFcsMonth.map((item) => {
                    return <div><span style={{color : "#A3A3A3", marginRight: "1rem" }}>Тайлант он</span><span style={{fontWeight: "700"}}> {item.year} сар {item.month}</span></div>;
                })}
                </span>
            </div>
                <div className="row" style={{display: 'flex', marginBottom: 30, marginTop: 40}}>
                    <div className='col-3' style={{marginLeft: 10, marginRight: 10}}>
                        <div style={{display: 'flex', alignItems: 'center', ...style.text}}>Баримтын дугаар:</div>

                        <input type='number' className='law-search-input' 
                               style={{
                                   marginTop: 10, ...style.box
                               }}
                               value={brn} onChange={onChangeDocNoValue}/>

                    </div>
                    <div className='col-3' style={{marginLeft: 10, marginRight: 10,}}>
                        <div style={{display: 'flex', marginLeft: 10,alignItems: 'center', marginBottom: 10, ...style.text}}>Харилцагч:</div>
                        <div style={{marginLeft: 10}}>
                            <input type='string' className='law-search-input' 
                                    style={{...style.box}}
                                    width="100%"
                                   onClick={(e)=>setModalVisibleCustOrg(true)}
                                  value={custValue.name}
                            />

                        </div>




                    </div>
                </div>
                <div className="row" style={{display: 'flex', marginBottom: 30, marginTop: 40}}>
                    <div className='col-3' style={{marginLeft: 10, marginRight: 10}}>
                        <div style={{display: 'flex', alignItems: 'center', marginBottom: 10, ...style.text}}>Огноо:</div>
                        <DateBox value={docDate} type="date" style={{...style.box}}
                                 onValueChanged={onValueChangedDate}/>
                    </div>
                    <div style={{marginLeft: 20 }}>
                        <div style={{display: 'flex', alignItems: 'center', marginBottom: 10, ...style.text}}>Өглөгийн ангилал:</div>
                        <SelectBox
                            items={dataFcsApClass}
                            value={apClassValue}
                            style={{...style.box}}
                            displayExpr={(item) => {
                                return item && `${item.acct} - ${item.descr}`
                            }}
                            onValueChanged={(e) => onChangeApClassValue(e.value)}
                            placeholder="сонгох"/>
                    </div>
                </div>
                <div className='col-3' style={{marginLeft: 10}}>
                    <Button icon="chart" text="Төсвийн ангилал сонгох" onClick={() => setModalVisibleBudget(true)}
                            style={{alignment: "center", borderRadius: '7px'}}/>
                </div>
                {
                    agendaValue && eventValue && economicValue &&
                    <div style={{marginLeft: 10, marginTop: 10,display: 'flex', flexDirection: 'row',marginBottom:10}}>
                        <div style={{marginLeft: 10, marginRight: 10, width: 400}}>
                                <div style={{display: 'flex', alignItems: 'center',marginBottom:5}}>Сонгогдсон төсөл хөтөлбөр</div>
                            <SelectBox
                                items={agendaBudgetYear}
                                value={agendaValue}
                                style={{border: '2px solid #DDDDDD'}}
                                disabled={true}
                                displayExpr={(item) => {
                                    return item && `${item.agenda_code} - ${item.agenda_name}`
                                }}
                                placeholder="Төсөл хөтөлбөр сонгох"/>
                        </div>
                        <div style={{marginLeft: 10, marginRight: 10, width: 400}}>
                            <div style={{display: 'flex', alignItems: 'center',marginBottom:5}}>Сонгогдсон зориулалт</div>
                            <SelectBox
                                items={eventBudgetYear}
                                value={eventValue}
                                style={{border: '2px solid #DDDDDD'}}
                                displayExpr={(item) => {
                                    return item && `${item.event_code} - ${item.event_name}`
                                }}
                                disabled={true}
                                placeholder="Зориулалт сонгох"/>
                        </div>
                        <div style={{marginLeft: 10, marginRight: 10, width: 400}}>
                            <div style={{display: 'flex', alignItems: 'center',marginBottom:5}}>Сонгогдсон төсвийн ангилал</div>
                            <SelectBox
                                items={economicBudgetYear}
                                value={economicValue}
                                style={{...style.box}}
                                displayExpr={(item) => {
                                    return item && `${item.econ_category_code} - ${item.econ_category_name}`
                                }}
                                disabled={true}
                                placeholder="Төсвийн ангилал сонгох"/>
                        </div>
                    </div>

                }

                <div>
                    <Popup
                        width={500}
                        height={500}
                        minWidth={'80%'}
                        maxWidth={'80%'}
                        minHeight={'80%'}
                        visible={modalVisibleCustOrg}
                        title={'Байгууллага хувь хүмүүс сонгох'}
                        onHiding={() => setModalVisibleCustOrg(false)}
                        resizeEnabled={true}
                        dragEnabled={true}
                        showTitle={true}

                    >
                        <CustorgParent  setCustValue={setCustValue}  setModalVisibleCustOrg={setModalVisibleCustOrg} />

                    </Popup>
                </div>
                <div style={{display: 'flex', justifyContent: "flex-start", flexDirection: 'row', marginTop: 30, marginBottom: 30}}>
                    <Button icon="plus"
                            text=""
                            type="default"
                            onClick={() => clickPlus(myBabyRef)}/>
                    <div style={{marginLeft: 30}}>
                        <Button className='saveButton'
                                text="Хадгалах"
                                type="default"
                                onClick={() => setPurchase()}/>
                    </div>
                </div>
                <div className="col-12">
                    <DataGrid
                        dataSource={listData}
                        showBorders={true}
                        ref={myBabyRef}
                        hoverStateEnabled={true}
                        noDataText='Дата байхгүй байна.'
                        onEditingStart={onEditingStart}
                        onInitNewRow={onInitNewRow}
                        onSaved={saveDataGrid}
                        onRowInserted={onRowInserted}
                        // rowUpdating={(e) => {
                        //     console.log(e);
                        // }}
                    >
                        <Editing
                            mode="cell"
                            allowUpdating={true}
                            allowDeleting={true}
                            useIcons={true}
                            texts={{
                                cancelAllChanges: 'Болих бүгд',
                                cancelRowChanges: 'Болих',
                                confirmDeleteMessage: 'Энэ худалдан авалт мэдээллийг устгах уу?',
                                confirmDeleteTitle: 'Баталгаажуулах',
                                deleteRow: 'Устгах',
                                editRow: 'Өөрчлөх',
                                saveAllChanges: 'Хадгалах бүгд',
                                saveRowChanges: 'Хадгалах',
                                undeleteRow: 'Буцаах',
                                validationCancelChanges: 'Баталгаажуулах болих',
                            }}
                        />
                        <Column
                            cssClass="custom"
                            dataField="descr"
                            caption="Тайлбар"
                            width={300}
                            allowEditing={true}
                            allowFiltering={true}
                        >
                            <RequiredRule
                                message='Тайлбар оруулна уу'
                            />
                        </Column>
                        <Column
                            cssClass="custom"
                            dataField="acct"
                            caption="Данс"
                            allowEditing={true}
                            allowFiltering={true}
                            width={400}
                        >
                            <RequiredRule
                                message='Данс оруулна уу'
                            />
                            <Lookup
                                valueExpr="acct"
                                displayExpr={(item) => {
                                    return item && `${item.acct} - ${item.descr}`
                                }}
                                dataSource={dataFcsChart}

                            />
                        </Column>
                        <Column
                            cssClass="custom"
                            dataField="qty"
                            caption="Тоо"
                            allowEditing={true}
                            allowFiltering={true}
                            setCellValue={calculateAmt}
                            width={80}
                        >
                            <RequiredRule
                                message='Тоо оруулна уу'
                            />
                            <PatternRule pattern={/^\d+$/} message='зөвхөн эерэг тоо оруулна уу!'/>
                        </Column>
                        <Column
                            cssClass="custom"
                            dataField="unit"
                            caption="Ханш"
                            allowEditing={true}
                            allowFiltering={true}
                            setCellValue={calculateAmt1}
                            width={50}
                        >
                            <RequiredRule
                                message='Ханш оруулна уу'
                            />
                            <PatternRule pattern={/^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/}
                                         message='зөвхөн тоо оруулна уу!'/>
                        </Column>
                        <Column
                            cssClass="custom"
                            dataField="amt"
                            caption="Нийт дүн"
                            allowEditing={false}
                            width={120}
                        >
                        </Column>
                        <Column
                            cssClass="custom"
                            dataField="vat"
                            caption="Үүнээс НӨАТ"
                            allowEditing={true}
                            allowFiltering={true}
                            width={100}
                        >
                            <RequiredRule
                                message='НӨАТ оруулна уу'
                            />
                            <PatternRule pattern={/^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/}
                                         message='зөвхөн тоо оруулна уу!'/>
                        </Column>
                        <Column type="buttons" width={110}>
                            <DevButton name="delete"/>
                            <DevButton hint="Clone" icon="copy" visible={true} onClick={cloneIconClick}/>
                        </Column>
                        <Summary>
                            <TotalItem
                                column="qty"
                                summaryType="sum"
                                customizeText={customizeDate}/>
                            <TotalItem
                                column="amt"
                                summaryType="sum"
                                customizeText={customizeDate}/>
                        </Summary>
                    </DataGrid>
                </div>
                
            </div>
            <div>
                <Popup
                    minWidth={'80%'}
                    maxWidth={'100%'}
                    minHeight={'100%'}
                    visible={modalVisibleBudget}
                    title={'Төсвийн ангилал сонгох'}
                    onHiding={() => setModalVisibleBudget(false)}
                    resizeEnabled={true}
                    dragEnabled={true}
                    showTitle={true}
                    hideOnOutsideClick={true}
                >
                    <ScrollView width='100%' height='100%'>
                        <div className={'card-body'}>
                            <div style={{marginTop: 10}}>
                                <div style={{display: 'flex', flexDirection: 'row', marginBottom: 20}}>
                                    <div style={{marginLeft: 10, marginRight: 10, width: 400}}>
                                        <div style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            marginBottom: 10
                                        }}>Төсөл,хөтөлбөр
                                        </div>
                                        <SelectBox
                                            items={agendaBudgetYear}
                                            value={agendaValue}
                                            style={{border: '1px solid #DDDDDD'}}
                                            displayExpr={(item) => {
                                                return item && `${item.agenda_code} - ${item.agenda_name}`
                                            }}
                                            onValueChanged={(e) => onChangeAgendaValue(e.value)}
                                            placeholder="Төсөл хөтөлбөр сонгох"/>
                                    </div>
                                    <div style={{marginLeft: 10, marginRight: 10, width: 300}}>
                                        <div style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            marginBottom: 10
                                        }}>Зориулалт
                                        </div>
                                        <SelectBox
                                            items={eventBudgetYear}
                                            value={eventValue}
                                            style={{border: '1px solid #DDDDDD'}}
                                            displayExpr={(item) => {
                                                return item && `${item.event_code} - ${item.event_name}`
                                            }}
                                            onValueChanged={(e) => onChangeEventValue(e.value)}
                                            placeholder="Зориулалт сонгох"/>
                                    </div>
                                    <div style={{marginLeft: 10, marginRight: 10, width: 400}}>
                                        <div style={{display: 'flex', alignItems: 'center', marginBottom: 10}}>Төсвийн
                                            ангилал
                                        </div>
                                        <SelectBox
                                            items={economicBudgetYear}
                                            value={economicValue}
                                            style={{border: '1px solid #DDDDDD'}}
                                            displayExpr={(item) => {
                                                return item && `${item.econ_category_code} - ${item.econ_category_name}`
                                            }}
                                            onValueChanged={(e) => onChangeEconomicValue(e.value)}
                                            placeholder="Төсвийн ангилал сонгох"/>
                                    </div>
                                </div>
                                <PivotGrid
                                    style={{textAlign: "left !important"}}
                                    id="pivotgrid"
                                    ref={_pivotGrid}
                                    dataSource={dataSource}
                                    rowHeaderLayout={'tree'}
                                    allowSortingBySummary={true}
                                    sortingMethod={true}
                                    allowFiltering={true}
                                    showBorders={true}
                                    showColumnGrandTotals={false}
                                    showRowGrandTotals={true}
                                    showRowTotals={true}
                                    showColumnTotals={false}
                                    //cellPrepared={onCellPrepared}
                                    loadPanel={{enabled: false}}
                                    onCellClick={onCellClick}
                                    texts={{
                                        grandTotal: 'Нийт дүн',
                                        total: '{0} нийт дүн'
                                    }}
                                />
                                <Popup
                                    visible={popupVisible}
                                    width={800}
                                    height={500}
                                    title={popupTitle}
                                    onHiding={onHiding}
                                    onShown={onShown}
                                >
                                    <DataGrid
                                        width={750}
                                        height={400}
                                        dataSource={drillDownDataSource}
                                        allowColumnResizing={true}
                                        //ref={getDataGridInstance}
                                        onExporting={onExporting}>
                                        >
                                        <GroupPanel visible={true}/>
                                        <SearchPanel visible={true}/>
                                        <Grouping autoExpandAll={true}/>
                                        <Column dataField="org_name" caption="Байгууллага" groupIndex={0}/>
                                        <Column dataField="docno" caption="Журнал дугаар" width={100}
                                                allowCollapsing={false}
                                                allowFiltering={true}/>
                                        <Column dataField="acct_name" caption="Данс"
                                                allowCollapsing={false}
                                                allowFiltering={true}/>
                                        <Column dataField="apacct_name" caption="Ангилал"
                                                allowCollapsing={false}
                                                allowFiltering={true}/>
                                        <Column dataField="custname" caption="Харилцагч"
                                                allowCollapsing={false}
                                                allowFiltering={true}/>
                                        <Column dataField="descr" caption="Гүйлгээний утга"
                                                allowCollapsing={false}
                                                allowFiltering={true}/>
                                        <Column dataField="amt" caption="Дүн" dataType="number" format="#,##0.00" />
                                        <Summary>
                                            <GroupItem
                                                column="amt"
                                                summaryType="sum"
                                                customizeText={customizeDate}
                                                showInGroupFooter={true}/>
                                            <TotalItem
                                                column="amt"
                                                summaryType="sum"
                                                customizeText={customizeDate} />
                                        </Summary>
                                        <Export enabled={true} allowExportSelectedData={false}/>
                                    </DataGrid>
                                </Popup>
                            </div>
                        </div>
                    </ScrollView>
                </Popup></div>

        </React.Fragment>
    )
}

export default Purchase;

/*eslint-disable*/
import React, {useEffect, useRef} from "react";
import 'devextreme-react/text-area';
import {number} from "prop-types";
import ReactToPrint from "react-to-print";
import Button from "devextreme-react/button";
import DataGrid, {
    Column, Export,
    Grouping,
    GroupItem,
    GroupPanel,
    SearchPanel,
    Summary,
    TotalItem
} from "devextreme-react/data-grid";
import {Workbook} from "exceljs";
import {exportDataGrid} from "devextreme/excel_exporter";
import {saveAs} from "file-saver-es";
import {NumberToString} from "../../BankBook/report/bankp";

const AP01 = (props) => {
    const data = props.data
    const   jr_key = props.jr_key
    const tranDesc=props.tranDesc
    const selectedValue = props.selectedValue
    const org = props.dataFcsCustOrgn[0]
    const componentRef = useRef();

    const orgName = props.orgName


    const onExporting = (e) => {
        // console.log('eeeee--', e)
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Main sheet');

        exportDataGrid({
            component: e.component,
            worksheet,
            autoFilterEnabled: true,
        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer], {type: 'application/octet-stream'}), `${reportTitle}-${year}-${month}.xlsx`);
            });
        });
        e.cancel = true;
    }
    const customizeDate = (data) => {
        return Number(parseFloat(data.value).toFixed(2)).toLocaleString("en", {
            minimumFractionDigits: 2,
        })
    }

    const style = {
        text: {
            color: "#2E4765", fontWeight: "700", fontSize: ".875rem", marginLeft: 5, marginRight: 5
        },
        box: {
            border: "none", backgroundColor: "#F6F6F6", borderRadius: ".5rem", padding: ".25rem .15rem", width: "100%"
        },
        box2: {
            border: "none",
            backgroundColor: "rgb(239, 239, 239)",
            borderRadius: ".5rem",
            padding: ".25rem .4rem",
            width: "100%"
        }
    }


    return (
        <div>

            <div className="row" style={{display: 'flex', marginTop: 30,}}>

                <Button type=""
                        icon='save'
                        className="saveButton" onClick={() => props.setReportShow(false)}> Буцах </Button>

                <ReactToPrint
                    trigger={() => <Button
                        type="default"
                        icon='save'
                        className="saveButton">Хэвлэх</Button>}
                    content={() =>
                        componentRef.current}
                />
            </div>


            <div ref={componentRef}
                 style={{
                     marginLeft: 30,
                     marginRight: 20,
                     display: "flex",
                     justifyContent: "space-evenly",
                     padding: "1.5rem",
                     flexDirection: "column",
                 }}>
                <div className="row" style={{display: 'flex', justifyContent: "space-between", alignItems: "center",}}>
                    <div style={{marginLeft: 10, marginRight: 10}}>
                        <div style={{display: 'flex', alignItems: 'left'}}>НХМаягт T-3</div>
                    </div>
                    <div style={{display: 'flex', fontSize: '40', alignItems: 'Left'}}> ТООЦООНЫ ҮЛДЭГДЛИЙН БАТАЛГАА №
                    </div>
                </div>


                <div className="row" style={{display: 'flex', marginTop: "1rem"}}>
                    <div className='col-5'
                         style={{marginLeft: 5, marginRight: 10, display: "flex"}}>
                        <div style={{display: 'flex'}}> {  jr_key == "AP" ? ' Хариуцагч :' :' Нэхэмжлэгч : '   } </div>
                    </div>

                    <div className='col-5' style={{marginRight: 5, display: "flex"}}>
                        <div style={{display: 'flex', fontSize: '20', alignItems: 'Right'}}>
                            {  jr_key == "AP" ? ' Нэхэмжлэгч : ' :' Хариуцагч :'   }   </div>
                    </div>
                </div>


                <div className="row" style={{display: 'flex', marginTop: "1rem"}}>

                    <div className='col-5'
                         style={{marginLeft: 5, marginRight: 10, display: "flex"}}>
                        <div style={{display: 'flex', ...style.text}}>  {orgName}  </div>
                    </div>

                    <div className='col-5'
                         style={{marginLeft: 5, marginRight: 10, display: "flex"}}>
                        <div style={{display: 'flex', ...style.text}}>   {org.name} </div>
                    </div>

                    <div className="row" style={{display: 'flex', marginTop: 0}}>
                        <div className='col-5'
                             style={{marginLeft: 5, marginRight: 5, display: "flex"}}>
                            <div style={{display: 'flex'}}> Хаяг :</div>
                            <div style={{display: 'flex', ...style.text}}>    </div>
                        </div>
                        <div className='col-5'
                             style={{marginLeft: 5, marginRight: 10, display: "flex"}}>
                            <div style={{display: 'flex'}}> Хаяг :</div>
                            <div style={{display: 'flex', ...style.text}}> {org.address}</div>
                        </div>
                    </div>


                    <div className="row" style={{display: 'flex', marginTop: 0}}>
                        <div className='col-5'
                             style={{marginLeft: 5, marginRight: 5, display: "flex"}}>
                            <div style={{display: 'flex'}}> Утас, факс :</div>
                            <div style={{display: 'flex', ...style.text}}>   </div>
                        </div>
                        <div className='col-5'
                             style={{marginLeft: 5, marginRight: 10, display: "flex", justifyContent: "space-between"}}>
                            <div style={{display: 'flex'}}> Утас, факс :</div>
                            <div style={{display: 'flex', ...style.text}}> {org.phone} </div>
                        </div>
                    </div>


                    <div className="row" style={{display: 'flex', marginTop: 0}}>
                        <div className='col-5'
                             style={{marginLeft: 5, marginRight: 5, display: "flex"}}>
                            <div style={{display: 'flex'}}>Э-шуудан :</div>
                            <div style={{display: 'flex', ...style.text}}>    </div>
                        </div>
                        <div className='col-5'
                             style={{marginLeft: 5, marginRight: 10, display: "flex", justifyContent: "space-between"}}>
                            <div style={{display: 'flex'}}> Э-шуудан :</div>
                            <div style={{display: 'flex', ...style.text}}>{org.email} </div>
                        </div>
                    </div>
                    <div className="row" style={{display: 'flex', marginTop: 0}}>
                        <div className='col-5'
                             style={{marginLeft: 5, marginRight: 5, display: "flex"}}>
                            <div style={{display: 'flex'}}>Банкны нэр :</div>
                            <div style={{display: 'flex', ...style.text}}>     </div>
                        </div>
                        <div className='col-5'
                             style={{marginLeft: 5, marginRight: 10, display: "flex", justifyContent: "space-between"}}>
                            <div style={{display: 'flex'}}> Банкны нэр :</div>
                            <div style={{display: 'flex', ...style.text}}>{org.bankname}</div>
                        </div>
                    </div>

                    <div className="row" style={{display: 'flex', marginTop: 0}}>
                        <div className='col-5'
                             style={{marginLeft: 5, marginRight: 5, display: "flex"}}>
                            <div style={{display: 'flex'}}>Банкны дансны дугаар :</div>
                            <div style={{display: 'flex', ...style.text}}>    </div>
                        </div>
                        <div className='col-5'
                             style={{marginLeft: 5, marginRight: 10, display: "flex", justifyContent: "space-between"}}>
                            <div style={{display: 'flex'}}> Банкны дансны дугаар :</div>
                            <div style={{display: 'flex', ...style.text}}>{org.bankacct} </div>
                        </div>
                    </div>
                    <div className="row" style={{display: 'flex', marginTop: 0}}>
                        <div className='col-5'
                             style={{marginLeft: 5, marginRight: 5, display: "flex"}}>
                            <div style={{display: 'flex'}}>ТТД :</div>
                            <div style={{display: 'flex', ...style.text}}>    </div>
                        </div>
                        <div className='col-5'
                             style={{marginLeft: 5, marginRight: 10, display: "flex", justifyContent: "space-between"}}>
                            <div style={{display: 'flex'}}> ТТД :</div>
                            <div style={{display: 'flex', ...style.text}}>{org.rd} </div>
                        </div>
                    </div>

                    <div className="row" style={{display: 'flex', marginTop: 10}}>
                        <div className='col-5'
                             style={{marginLeft: 5, marginRight: 5, display: "flex"}}>
                            <div style={{display: 'flex'}}>ТООЦОО НИЙЛСЭН ДАНСНЫ № :</div>
                            <div style={{display: 'flex', ...style.text}}> {props.acctAp}    </div>
                        </div>

                    </div>

                    <div className="row" style={{display: 'flex', marginTop: 10}}>
                        <div className='col-10'
                             style={{marginLeft: 5, marginRight: 5, display: "flex"}}>
                            <div style={{display: 'flex'}}>Нэхэмжлэгчийн нэрийн өмнөөс _________________
                                ажилтай___________________ овогтой
                            </div>
                        </div>
                    </div>

                    <div className="row" style={{display: 'flex', marginTop: 5}}>
                        <div className='col-2'>
                            <div style={{display: 'flex', ...style.text}}>   {  jr_key == "AP" ? org.person :' '   }       </div>
                        </div>
                        <div className='col-8'
                             style={{marginLeft: 5, marginRight: 5, display: "flex"}}>
                            <div style={{display: 'flex'}}> нь төлөгчийн нэрийн өмнөөс _________________ажилтай</div>
                        </div>
                    </div>


                    <div className="row" style={{display: 'flex', marginTop: 10}}>
                        <div className='col-11'
                             style={{marginLeft: 5, marginRight: 5, display: "flex"}}>
                            <div style={{display: 'flex'}}>_________________ овогтой
                            </div>
                            <div style={{display: 'flex', ...style.text}}>   {  jr_key == "AP" ? ' ' : org.person    }       </div>

                            <div style={{display: 'flex'}}>  -тай тус
                                хоёр
                                байгууллага хооронд
                            </div>


                        </div>
                    </div>
                    <div className="row" style={{display: 'flex', marginTop: 10}}>
                        <div className='col-10'
                             style={{marginLeft: 5, marginRight: 5, display: "flex"}}>
                            <div style={{display: 'flex'}}>_____ оны ____сарын____өдрөөс _____ оны ____сарын____өдрийг
                                дуустал хугацаанд өгч
                            </div>
                        </div>
                    </div>
                    <div className="row" style={{display: 'flex', marginTop: 10}}>
                        <div className='col-10'
                             style={{marginLeft: 5, marginRight: 5, display: "flex"}}>
                            <div style={{display: 'flex'}}>
                                авалцсан зүйлээ хоёр байгууллагад хөтлөгдөж буй нягтлан бодох бүртгэлийн дэлгэрэнгүй
                            </div>
                        </div>
                    </div>
                    <div className="row" style={{display: 'flex', marginTop: 10}}>
                        <div className='col-10'
                             style={{marginLeft: 5, marginRight: 5, display: "flex"}}>
                            <div style={{display: 'flex'}}>
                                ба хураангуй бүртгэлийн бичилтээр нэг бүрчлэн нийлж үзэхэд
                            </div>
                            <div style={{display: 'flex', ...style.text}}>     { jr_key=='AP' ? orgName : org.name}    </div>
                        </div>
                    </div>

                    <div className="row" style={{display: 'flex', marginTop: 10}}>
                        <div className='col-10'
                             style={{marginLeft: 5, marginRight: 5, display: "flex"}}>
                            <div style={{display: 'flex'}}>
                                нь
                            </div>
                            <div style={{display: 'flex', ...style.text}}>
                                {Number(parseFloat(selectedValue.ed).toFixed(2)).toLocaleString("en", {
                                    minimumFractionDigits: 2,
                                })}
                            </div>

                            <div style={{display: 'flex'}}>
                                төгрөг
                            </div>
                            <div style={{display: 'flex',...style.text}}>
                                { NumberToString(  selectedValue.ed) }
                            </div>



                            <div style={{display: 'flex'}}>
                                 ийн
                            </div>


                        </div>
                    </div>

                    <div className="row" style={{display: 'flex', marginTop: 10}}>
                        <div className='col-10'
                             style={{marginLeft: 5, marginRight: 5, display: "flex"}}>
                            <div style={{display: 'flex'}}>
                                өртэй гарсныг харилцан батлав.
                            </div>
                        </div>
                    </div>

                    <div className="row" style={{display: 'flex', marginTop: 10}}>
                        <div className='col-10'
                             style={{marginLeft: 5, marginRight: 5, display: "flex"}}>
                            <div style={{display: 'flex'}}>
                                Энэхүү өрийн үлдэгдлийг ________________________________________________________
                                ___________________________________________ тооцооны хэлбэрээр нэхэмжлэн авахаар
                                тохиролцов.
                            </div>
                        </div>
                    </div>

                    <div className="row" style={{display: 'flex', marginTop: 20}}>
                        <div className='col-2'
                             style={{marginLeft: 5, marginRight: 5, display: "flex"}}>
                            <div style={{display: 'flex'}}>
                                (Тэмдэг)
                            </div>
                        </div>
                        <div className='col-8'
                             style={{marginLeft: 5, marginRight: 5, display: "flex"}}>
                            <div style={{display: 'flex'}}>
                                Нэхэмжлэгч байгууллагын нягтлан бодогч . . . . . . . . . . . . . / . . . . . . . . /
                            </div>
                        </div>
                    </div>
                    <div className="row" style={{display: 'flex', marginTop: 20}}>
                        <div className='col-2'
                             style={{marginLeft: 5, marginRight: 5, display: "flex"}}>
                            <div style={{display: 'flex'}}>
                                (Тэмдэг)
                            </div>
                        </div>
                        <div className='col-8'
                             style={{marginLeft: 5, marginRight: 5, display: "flex"}}>
                            <div style={{display: 'flex'}}>
                                Хариуцагч байгууллагын нягтлан бодогч . . . . . . . . . . . . . . . . / . . . . . . . .
                                . /
                            </div>
                        </div>
                    </div>


                    <div className="row" style={{display: 'flex', marginTop: 20}}>
                        <div className='col-10'
                             style={{marginLeft: 5, marginRight: 5, display: "flex"}}>
                            <div style={{display: 'flex'}}>
                                2. Тооцооны баримтын жагсаалт
                            </div>
                        </div>

                    </div>

                    <div style={{marginRight: 20}}>

                        <DataGrid
                            dataSource={data}
                            columnAutoWidth={true}
                            showBorders={true}
                            allowColumnResizing={true}
                            hoverStateEnabled={true}

                            keyExpr="acct"
                            noDataText='Дата байхгүй байна.'
                            style={{marginLeft: 20, marginRight: 20}}
                        >

                            <GroupPanel visible={true}/>
                            <SearchPanel visible={true}/>
                            <Grouping autoExpandAll={true}/>

                            <Column caption="Гүйлгээний утга" dataField="descr"/>
                            <Column caption="дугаар" dataField="docno"/>
                            <Column caption="огноо" dataField="docdate" dataType="date"/>
                            <Column groupIndex={0} name={"sar"}
                                //calculateDisplayValue={diff}
                                    caption="сар" width={55}
                                // calculateCellValue={diff}
                            />
                            <Column caption="журнал" dataField="jr"/>
                            <Column caption="Данс" dataField="acct"/>
                            <Column caption={tranDesc[4]} dataField="dt" format="#,##0.00" dataType="number"/>
                            <Column caption={tranDesc[5]} dataField="cr" format="#,##0.00" dataType="number"/>
                            <Summary>
                                <GroupItem
                                    column="dt"
                                    summaryType="sum"
                                    format="#,##0.00"
                                    customizeText={customizeDate}
                                    showInGroupFooter={true}/>


                                <GroupItem
                                    column="cr"
                                    summaryType="sum"
                                    format="#,##0.00"
                                    customizeText={customizeDate}
                                    showInGroupFooter={true}/>


                                <TotalItem
                                    column="dt"
                                    summaryType="sum"
                                    customizeText={customizeDate}/>
                                <TotalItem
                                    column="cr"
                                    summaryType="sum"
                                    customizeText={customizeDate}/>
                            </Summary>
                            <Export enabled={true} allowExportSelectedData={false}/>
                        </DataGrid>


                    </div>
                    <div className="row" style={{display: 'flex'}}>
                        <div className='col-6'>

                        </div>
                        {selectedValue &&
                            <div style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'flex-end',
                                marginTop: 10,
                                marginRight: '10%'
                            }}>
                                <span style={{fontSize: 16, fontWeight: 500, marginBottom: 10}}>Эцсийн үлдэгдэл: </span>
                                <span style={{
                                    fontSize: 16,
                                    fontWeight: 500,
                                    marginLeft: 10
                                }}> {Number(parseFloat(selectedValue.ed).toFixed(2)).toLocaleString("en", {
                                    minimumFractionDigits: 2,
                                })}</span>
                            </div>
                        }
                    </div>

                    <div style={{marginBottom: 20}}>


                    </div>
                </div>


            </div>

        </div>
    )
        ;
};

export default AP01
import React, { useContext, useEffect, useState, useRef, useCallback } from "react";
import { UserContext } from "../../../hooks/UserContext";
import { useHistory, useParams } from "react-router-dom";
import DataGrid, { Column, Editing, Grouping, GroupPanel, Summary, TotalItem, Selection, HeaderFilter, ColumnChooser, ColumnFixing, GroupItem, SearchPanel, Export, Toolbar, Item } from "devextreme-react/data-grid";
import notify from "devextreme/ui/notify";
import ScheduleService from "../../../services/api/scheduleService";
import { Popup, TextBox,ScrollView } from "devextreme-react";
import SelectBox from "devextreme-react/select-box";
import { NumberBox } from "devextreme-react/number-box";
import { numberWithCommas } from "../../../util";
import Button from "devextreme-react/button";
import OrganizationService from "../../../services/api/organization";
import { CURRENT_YEAR } from "../../../shared/constants";
import PlanService from "../../../services/api/plan";
import BudgetService from "../../../services/api/budget";
import ScheduleSubOrgRequests from "./ScheduleSubOrgRequests";
import { Workbook } from 'exceljs';
import saveAs from 'file-saver';
import { exportDataGrid } from "devextreme/excel_exporter";
import _ from "lodash";
import DataSource from 'devextreme/data/data_source';
import PlanServices from "../../../services/api/plan";
import spinner from '../../../assets/imgs/tube-spinner.svg'
import { BackButton } from "../../../components/Buttons";
import info1 from '../../../assets/imgs/icon/iconInfo1.png';
import styled from "styled-components";
import RequestInformation from "./RequestInformation";
import Joyride from 'react-joyride';
import { huvaariTabSteps, guideLocale } from "../../../components/SystemGuideSteps";
import RadioGroup from 'devextreme-react/radio-group';
const TabComponent = styled.div`
  display: flex;
  font-weight: bold;
  .tab {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 0.5rem;
    background-color: #f5f5f5;
    transition: 0.3s all ease-in;
    border-bottom: 1px solid #ddd;
    &:nth-child(1) {
      border-top-left-radius: 15px;
      border-right: 1px solid #ddd;
    }
    &:nth-last-child(1) {
      border-top-right-radius: 15px;
      border-left: 1px solid #ddd;
    }
    .title {
      font-size: 1rem;
    }
    &:hover {
      background-color: #ebf1ff;
    }
    &.active {
      color: black;
      background-color: white;
      font-weight: 700;
      border-color: transparent;
      text-decoration-line: underline;
    }
  }
`

export const ezaAncestry = [
    {
  code: '2',name:'Нийт зарлага ба цэвэр зээлийн дүн'
    },
  {
  code: '3',name:'Зардлыг санхүүжүүлэх эх үүсвэр'
  }
];

function ScheduleEditReqInsertionV2() {
  const { user } = useContext(UserContext);
  const history = useHistory();
  const { reqId, reqOrg, status } = useParams();
  const req_id = +reqId
  const req_org = +reqOrg
  const current = req_org === user.org_id
  const currentYear = CURRENT_YEAR;
  const approvePermission = user.permission.some(({ code }) => ["budgetProposalApprove", "scheduleApprove"].includes(code));
  const [selectedTab, setSelectedTab] = useState(0);
  const [orgList, setOrgList] = useState([]);
  const [gridData, setGridData] = useState([]);
  const [selectedBudget, setSelectedBudget] = useState(null);
  const afterAmt = useRef(null);
  const [toOrg, setToOrg] = useState(user.organization.obt_id === 3 ? user.org_id : null)
  const [toAgenda, setToAgenda] = useState(null)
  const [toAgendaData, setToAgendaData] = useState([]);
  const [toEvent, setToEvent] = useState(null)
  const [toEconCat, setToEconCat] = useState(null)
  const requestDetail = useRef([])

  const [toEventData, setToEventData] = useState([]);
  const [toEconData, setToEconData] = useState([]);
  const [toMonthData, setToMonthData] = useState(null)
  const [tmpSelectedBudget, setTmpSelectedBudget] = useState(null);
  const changeAmt = useRef(null);
  const packNumber = useRef(1)
  const [formData, setFormData] = useState([]);
  const month_arr = Array.from({ length: 12 }, (_, i) => i + 1)
  const [expand, setExpand] = useState(true);
  const [isVisible, setIsVisible] = useState(false);
  const [fullscreen, setFullscreen] = useState(false);
  const updatedDate = useRef('-')
  const [defaultOrg, setDefaultOrg] = useState(null)
  const [isDone, setIsDone] = useState(false)
  const [debtModal, setDebtModal] = useState(false);
  const [randomNum, setRandomNum] = useState(null);
  const [selectedEza, setSelectedEza] = useState('2');
  const rowNum = useRef(1);
  const tabs = ['Хуваарь', 'Хүсэлтийн мэдээлэл']
  current && user.organization.obt_id !== 3 && tabs.push('Харьяа байгууллагын хүсэлт')

  useEffect(() => {
    user.organization.obt_id !== 3 && loadOrgList();
    loadGridData(req_org);
    getUpdatedDate();
  }, [selectedEza])
/*  useEffect(() => {
    user.organization.obt_id !== 3 && loadOrgList();
    loadGridData(req_org);
  }, [selectedEza])*/
  const loadOrgList = async () => {
    try {
      let res = await OrganizationService.orgSettingsListForScheduleEdit(currentYear, current, req_org)
      const defaultOrg = res.data.find(({ id }) => id === req_org)
      setDefaultOrg(defaultOrg);
      switch (user.organization.obt_id) {
        case 2:
          return setOrgList(res.data);
        case 1:
          return setOrgList(new DataSource(
              {
                store: {
                  type: 'array',
                  data: res.data,
                  key: 'id',
                },
                group: 'org_name_2',
              }))
        default:
          break;
      }
    } catch (error) {
      console.log(error)
    }
  }

  const loadGridData = async (org_id) => {
    let result = await ScheduleService.getScheduleBudget(org_id, currentYear,selectedEza);
    if (isVisible) {
      let res = _.filter(result, item => {
        return item.rec_month1 * (1) + item.rec_month2 * (1) + item.rec_month3 * (1) + item.rec_month4 * (1) + item.rec_month5 * (1) + item.rec_month6 * (1) + item.rec_month7 * (1) + item.rec_month8 * (1) + item.rec_month9 * (1) + item.rec_month10 * (1) + item.rec_month11 * (1) + item.rec_month12 * (1) + item.send_month1 * (1) + item.send_month2 * (1) + item.send_month3 * (1) + item.send_month4 * (1) + item.send_month5 * (1) + item.send_month6 * (1) + item.send_month7 * (1) + item.send_month8 * (1) + item.send_month9 * (1) + item.send_month10 * (1) + item.send_month11 * (1) + item.send_month12 * (1) !== 0
      });
      setGridData(res);
    } else {
      setGridData(result);
    }
  }

  const getUpdatedDate = async () => {
    await PlanServices.getScheduleUpdatedDate(CURRENT_YEAR)
        .then((res) => updatedDate.current = res.data[0].max)
        .catch((err) => console.log('could not get plan updated date', err))
  }

  // sariin tosow songood modal neex
  const cellClick = async (e) => {
    const { rowType, data, column, value } = e;
    if (approvePermission || ![1, 2, 3].includes(+status) || e.column.dataField ==='total_year_amt')
      return;
    else if (rowType === 'data' && column.caption === 'Хуваарь') {
      if (data.econ_category_code.length === 6) {
        if (document.fullscreenElement) { document.exitFullscreen() }
        let sMonth = column.name.replace(/\D/g, "");
        afterAmt.current = e.data['month' + sMonth] - e.data['per_month' + sMonth] + (e.data['send_month' + sMonth] * (-1) + e.data['rec_month' + sMonth] * 1);
        setSelectedBudget({
          id:  data['id' + sMonth],
          org_id: data.org_id,
          agenda_id: data.agenda_id,
          event_id: data.event_id,
          econ_category_id: data.econ_category_id,
          econ_category_code_1: data.econ_category_code_1,
          org_name: data.org_name,
          agenda_data: data.agenda_data,
          event_data: data.event_data,
          econ_name: data.econ_name,
          budget_year: data.budget_year,
          selectedMonth: sMonth,
          selectedAmount: value || 0,
          perAmt: data['per_month' + sMonth] || 0
        });
        setDebtModal(true);
      } else {
        return notify({ message: 'Толгой эдийн засгийн ангилал байна!', width: 'fit-content' }, "warning", 5000);
      }
    }
  }

  useEffect(() => {
    selectedBudget && PlanService.retrieveByAgendaDetail(currentYear, toOrg, selectedBudget.econ_category_code_1)
        .then(res => setToAgendaData(res))
        .catch(e => notify(e.message, "error", 2000))
  }, [selectedBudget && toOrg])

  useEffect(() => {
    toAgenda && PlanService.retrieveByEventDetailByAgenda(currentYear, toOrg, toAgenda, selectedBudget.econ_category_code_1)
        .then(res => setToEventData(res))
        .catch(e => notify(e.message, "error", 2000))
  }, [toAgenda])

  useEffect(() => {
    toEvent && PlanService.retrieveEconCategoryByAgendaEvent(currentYear, toOrg, toAgenda, toEvent, selectedBudget.econ_category_code_1)
        .then(res => setToEconData(res))
        .catch(e => notify(e.message, "error", 2000))
  }, [toEvent])

  useEffect(() => {
    toEconCat && BudgetService.getBudgetScheduleInfo(currentYear, toOrg, toAgenda, toEvent, toEconCat)
        .then(res => setToMonthData(res))
        .catch(e => notify(e.message, "error", 2000))
  }, [toEconCat])


  const addToTmpDetail = async () => {
    if (changeAmt.current * 1 === 0 || isNaN(changeAmt.current)) {
      return notify({ message: `Дүн буруу байна!`, width: 'fit-content' }, "warning", 5000);
    }
    if (tmpSelectedBudget.after === undefined)
    {
      tmpSelectedBudget.after = tmpSelectedBudget.debit * 1;
    }
    let checkDiff = 0;
    checkDiff = (formData.length > 0 ? (_.sumBy(formData, "transferAmt") * 1 +  changeAmt.current * 1) : changeAmt.current)
    if (afterAmt.current < checkDiff) {
      return notify({ message: `Гүйцэтгэл гарсан сарын зөрүү дүнгээр шилжүүлэг хийх боломжтой`, width: 'fit-content' }, "warning", 5000);
    }
    afterAmt.current -= changeAmt.current;
    tmpSelectedBudget.after = tmpSelectedBudget.debit * 1 + checkDiff * 1
    await createDetail(tmpSelectedBudget)
  }

  const createDetail = async (budget) => {
    let type_name = "";
    if (budget.org_id !== selectedBudget.org_id) {
      type_name = 'Байгууллага хоорондын';
    } else if (budget.agenda_id !== selectedBudget.agenda_id) {
      type_name = 'Хөтөлбөр хоорондын';
    } else if (budget.event_id.toString() !== selectedBudget.event_id.toString()) {
      type_name = 'Зориулалт хоорондын';
    } else if (budget.econ_category_id.toString() !== selectedBudget.econ_category_id.toString()) {
      type_name = 'Бүлэг хоорондын';
    } else if (budget.budget_month.toString() === selectedBudget.selectedMonth.toString()) {
      notify({ message: `Сонгосон сар шалгана уу!`, width: 'fit-content' }, "error", 5000);
      return;
    } else {
      type_name = 'Сар хоорондын';
    }

    const {
      org_id: from_org_id,
      agenda_id: from_agenda_id,
      event_id: from_event_id,
      econ_category_id: from_econ_category_id,
      selectedMonth: from_month,
      id: id,
    } = selectedBudget;
    //console.log('selectedBudget', selectedBudget);
    const {
      org_id: to_org_id,
      agenda_id: to_agenda_id,
      event_id: to_event_id,
      econ_category_id: to_econ_category_id,
      budget_month: to_month,
      id: to_budget_id,
      huvaari,
      after,
    } = tmpSelectedBudget;

    const formDetail = {
      'row_num': rowNum.current,
      'orgName': budget.org_name,
      'agenda': budget.agenda,
      'event': budget.event,
      'econ_name': budget.econ_category_code + ' - ' + budget.econ_category_name,
      'budget_year': budget.budget_year,
      'month': budget.budget_month,
      'scheduleAmt': huvaari * 1,
      'transferAmt': changeAmt.current,
      'diffAmt': after,
      "transfer_group": type_name,
    }

    const updateData = {
      'row_num': rowNum.current,
      'request_id': req_id,
      'pack_no': "P" + packNumber.current.toString().padStart(3, 0),
      "transfer_group": type_name,
      "transfer_type": 1,
      transfer_amount: changeAmt.current,
      from_org: from_org_id,
      from_agenda: from_agenda_id,
      from_event: from_event_id,
      from_econ: from_econ_category_id,
      from_month: from_month,
      from_budget_id: + selectedBudget.id,
      to_org: to_org_id,
      to_agenda: to_agenda_id,
      to_event: to_event_id,
      to_econ: to_econ_category_id,
      to_month: to_month,
      to_budget_id: +to_budget_id,
    }
    rowNum.current = rowNum.current + 1
    //console.log('updateData', updateData);
    requestDetail.current.push(updateData)
    setFormData(prevState => [...prevState, formDetail]);
    //await nextTransaction()
  }

  const nextTransaction = async () => {
    //console.log('requestDetail.current', requestDetail.current);
    await ScheduleService.addRequestDetail(requestDetail.current)
        .then(() => {
          notify({ message: "Гүйлгээг амжилттай хадгаллаа", width: 'fit-content' }, "success", 5000)
          setIsDone(true)
        })
        .catch(() => {
          notify({ message: "Гүйлгээг хадгалах явцад алдаа гарлаа", width: 'fit-content' }, "warning", 5000)
        })
  }

  const clearValues = async () => {
    setDebtModal(false);
    requestDetail.current = [];
    setFormData([])
    setTmpSelectedBudget(null)
    setToAgenda(null)
    setToEvent(null)
    setToEconCat(null)
    packNumber.current += 1
    setToEventData([])
    setToEconData([])
    setToMonthData([])
    changeAmt.current = null
    setIsDone(false);
    setSelectedBudget( null);
    rowNum.current = 1;
    formData.length > 0 && await loadGridData(req_org);
  }

  const cellPrepared = ({ cellElement, rowType, row, data, column, columnIndex }) => {
    let elem = cellElement.style;
    if (rowType === 'header') {
      elem.fontWeight = "bold"
      elem.color = "#15186A"
      elem.backgroundColor = "#EEF0F4"
    } else if (rowType === 'group') {
      elem.color = (row.groupIndex === 0 ? "#F93A3A" : row.groupIndex === 1 ? "#2C77FF" : "#003696")
      elem.backgroundColor = "#fff"
      elem.fontWeight = "bold";
    }
    if (rowType === "data" && [0, 1, 2, 3].includes(columnIndex) && data.econ_category_code.length !== 6) {
      elem.color = "#000000"
      elem.fontWeight = "bold";
    } else if (rowType === "data" && data.econ_category_code.length === 6) {
      elem.fontWeight = "semibold"
      elem.color = "#000000"
      elem.backgroundColor = "#edf4ff"
    }
  }

  const cellPrepared2 = ({ rowType, cellElement }) => {
    if (rowType === 'header') {
      cellElement.style.fontWeight = "bold"
      cellElement.style.color = "#15186A"
      cellElement.style.backgroundColor = "#EEF0F4"
    } else if (rowType === "data") {
      cellElement.style.fontWeight = "semibold"
      cellElement.style.color = "#000000"
    }
  }

  const cellRenderSchedule = (e, month) => {
    //console.log('cellRenderSchedule');
    let value = 0;
    let value1 = 0;
    switch (month) {
      case 0:
        value = e.data.send_month1 * (-1) + e.data.send_month2 * (-1) + e.data.send_month3 * (-1) + e.data.send_month4 * (-1) + e.data.send_month5 * (-1) + e.data.send_month6 * (-1) + e.data.send_month7 * (-1) + e.data.send_month8 * (-1) + e.data.send_month9 * (-1) + e.data.send_month10 * (-1) + e.data.send_month11 * (-1) + e.data.send_month12 * (-1);
        value1 = e.data.rec_month1 * 1 + e.data.rec_month2 * 1 + e.data.rec_month3 * 1 + e.data.rec_month4 * 1 + e.data.rec_month5 * 1 + e.data.rec_month6 * 1 + e.data.rec_month7 * 1 + e.data.rec_month8 * 1 + e.data.rec_month9 * 1 + e.data.rec_month10 * 1 + e.data.rec_month11 * 1 + e.data.rec_month12 * 1;
        break;
      case 1:
        value = e.data.send_month1 * (-1);
        value1 = e.data.rec_month1;
        break;
      case 2:
        value = e.data.send_month2 * (-1);
        value1 = e.data.rec_month2;
        break;
      case 3:
        value = e.data.send_month3 !== null ? e.data.send_month3 * (-1) : 0;
        value1 = e.data.rec_month3;
        break;
      case 4:
        value = e.data.send_month4 * (-1);
        value1 = e.data.rec_month4;
        break;
      case 5:
        value = e.data.send_month5 * (-1);
        value1 = e.data.rec_month5;
        break;
      case 6:
        value = e.data.send_month6 * (-1);
        value1 = e.data.rec_month6;
        break;
      case 7:
        value = e.data.send_month7 * (-1);
        value1 = e.data.rec_month7;
        break;
      case 8:
        value = e.data.send_month8 * (-1);
        value1 = e.data.rec_month8;
        break;
      case 9:
        value = e.data.send_month9 * (-1);
        value1 = e.data.rec_month9;
        break;
      case 10:
        value = e.data.send_month10 * (-1);
        value1 = e.data.rec_month10;
        break;
      case 11:
        value = e.data.send_month11 !== null ? e.data.send_month11 * (-1) : 0;
        value1 = e.data.rec_month11;
        break;
      case 12:
        value = e.data.send_month12 * (-1);
        value1 = e.data.rec_month12;
        break;
      default:
        value = 0;
        value1 = 0;
        break;
    }

    let diff = 0;
    if (value || value1) {
      diff = value * 1 + value1 * 1;
    }
    if (diff > 0) {
      return (<div className="inc customCell">
        <div className="current-value" style={{ fontWeight: month === 0 ? '700' : 'normal', color: '#cc097c', cursor: month !== 0 ? 'pointer' : 'normal' }}>{e.text}</div>
        <div style={{ color: '#3daa75' }}>{numberWithCommas(diff)}</div>
      </div>)
    } else if (diff < 0) {
      return <div className="dec customCell">
        <div className="current-value" style={{ fontWeight: month === 0 ? '700' : 'normal', color: '#cc097c', cursor: month !== 0 ? 'pointer' : 'normal' }}>{e.text}</div>
        <div style={{ color: '#f66160' }}>{numberWithCommas(diff)}</div>
      </div>
    } else {
      return <span style={{ fontWeight: month === 0 ? '700' : 'normal', color: '#cc097c', cursor: month !== 0 ? 'pointer' : 'normal' }}>{e.text}</span>
    }
  }

  const calcCellVal = (e, month) => {
    //console.log('calcCellVal');
    let value = 0;
    let value1 = 0;
    let value2 = 0;
    let value3 = 0;
    switch (month) {
      case 0:
        value = e.data.total_year_amt === null ? 0 : e.data.total_year_amt;
        value1 = e.data.per_total_year_amt === null ? 0 : e.data.per_total_year_amt;
        value2 = e.data.send_month1 * (-1) + e.data.send_month2 * (-1) + e.data.send_month3 * (-1) + e.data.send_month4 * (-1) + e.data.send_month5 * (-1) + e.data.send_month6 * (-1) + e.data.send_month7 * (-1) + e.data.send_month8 * (-1) + e.data.send_month9 * (-1) + e.data.send_month10 * (-1) + e.data.send_month11 * (-1) + e.data.send_month12 * (-1);
        value3 = e.data.rec_month1 * 1 + e.data.rec_month2 * 1 + e.data.rec_month3 * 1 + e.data.rec_month4 * 1 + e.data.rec_month5 * 1 + e.data.rec_month6 * 1 + e.data.rec_month7 * 1 + e.data.rec_month8 * 1 + e.data.rec_month9 * 1 + e.data.rec_month10 * 1 + e.data.rec_month11 * 1 + e.data.rec_month12 * 1;
        break;
      case 1:
        value = e.data.month1 === null ? 0 : e.data.month1;
        value1 = e.data.per_month1 === null ? 0 : e.data.per_month1;
        value2 = e.data.send_month1 * (-1);
        value3 = e.data.rec_month1;
        break;
      case 2:
        value = e.data.month2;
        value1 = e.data.per_month2;
        value2 = e.data.send_month2 * (-1);
        value3 = e.data.rec_month2;
        break;
      case 3:
        value = e.data.month3;
        value1 = e.data.per_month3;
        value2 = e.data.send_month3 * (-1);
        value3 = e.data.rec_month3;
        break;
      case 4:
        value = e.data.month4;
        value1 = e.data.per_month4;
        value2 = e.data.send_month4 * (-1);
        value3 = e.data.rec_month4;
        break;
      case 5:
        value = e.data.month5;
        value1 = e.data.per_month5;
        value2 = e.data.send_month5 * (-1);
        value3 = e.data.rec_month5;
        break;
      case 6:
        value = e.data.month6;
        value1 = e.data.per_month6;
        value2 = e.data.send_month6 * (-1);
        value3 = e.data.rec_month6;
        break;
      case 7:
        value = e.data.month7;
        value1 = e.data.per_month7;
        value2 = e.data.send_month7 * (-1);
        value3 = e.data.rec_month7;
        break;
      case 8:
        value = e.data.month8;
        value1 = e.data.per_month8;
        value2 = e.data.send_month8 * (-1);
        value3 = e.data.rec_month8;
        break;
      case 9:
        value = e.data.month9;
        value1 = e.data.per_month9;
        value2 = e.data.send_month9 * (-1);
        value3 = e.data.rec_month9;
        break;
      case 10:
        value = e.data.month10;
        value1 = e.data.per_month10;
        value2 = e.data.send_month10 * (-1);
        value3 = e.data.rec_month10;
        break;
      case 11:
        value = e.data.month11;
        value1 = e.data.per_month11;
        value2 = e.data.send_month11 * (-1);
        value3 = e.data.rec_month11;
        break;
      case 12:
        value = e.data.month12;
        value1 = e.data.per_month12;
        value2 = e.data.send_month12 * (-1);
        value3 = e.data.rec_month12;
        break;
      default:
        value = 0;
        value1 = 0;
        break;
    }
    let percent = 0;
    let diff = 0;
    if (value) {
      percent = value1 !== 0 ? (value1 - value + value2 * 1 + value3 * 1) * 100 / value : 0;
      diff = value - value1 + value2 * 1 + value3 * 1;
    }
    if (percent > 0) {
      return (<div className="inc customCell">
        <div className="current-value">{numberWithCommas(diff)}</div>
        <div className="diff" style={{ color: '#f66160' }}>{numberWithCommas(percent)}%</div>
      </div>)
    } else if (percent) {
      return <div className="dec customCell">
        <div className="current-value">{numberWithCommas(diff)}</div>
        <div className="diff" style={{ color: '#3daa75' }}>{numberWithCommas(percent)}%</div>
      </div>
    } else {
      return <div className="customCell">
        <div className="current-value">{diff && numberWithCommas(diff)} </div>
      </div>
    }
  }

  const onExporting = (e) => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Main sheet');
    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
      keepColumnWidths: true,
      topLeftCell: { row: 4, column: 2 },
      customizeCell: ({ gridCell, excelCell }) => {
        if (gridCell.rowType === 'header') {
          excelCell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '7082F6' } };
          excelCell.border = {
            top: { style: 'thin', color: { argb: '2c2c2c' } },
            left: { style: 'thin', color: { argb: '2c2c2c' } },
            bottom: { style: 'thin', color: { argb: '2c2c2c' } },
            right: { style: 'thin', color: { argb: '2c2c2c' } }
          }
        }
        if (gridCell.rowType === 'group') {
          excelCell.alignment = { wrapText: false };
          excelCell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '63C1FE' } };
        }
        if (gridCell.rowType === 'totalFooter' && excelCell.value) {
          excelCell.font.italic = true;
        }
        if (gridCell.rowType === 'data') {
          excelCell.border = {
            top: { style: 'thin', color: { argb: '2c2c2c' } },
            left: { style: 'thin', color: { argb: '2c2c2c' } },
            bottom: { style: 'thin', color: { argb: '2c2c2c' } },
            right: { style: 'thin', color: { argb: '2c2c2c' } }
          }
        }
      },
    }).then(() => {
      Object.assign(
          worksheet.getRow(2).getCell(8),
          { value: defaultOrg?.organization_name || user.organization.name + "-ын хуваарийн тайлан", font: { bold: true, size: 16, underline: 'double' } }
      );
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), defaultOrg?.organization_name || user.organization.name + '.xlsx');
      });
    });
    e.cancel = true;
  }

  const requestFullscreen = () => {
    if (document.fullscreenElement) document.exitFullscreen();
    else document.getElementById("wrapper").requestFullscreen();
  }

  const sumRenderText = (data) => {
    return (
        data.value !== 0 ? (data.value > 0 ? "+" + numberWithCommas(data.value) : numberWithCommas(data.value)) : ""
    )
  }

  const handleOnCellHover = (e) => {
    if (approvePermission || ![1, 2, 3].includes(+status) || e.column.dataField ==='total_year_amt')
      return;
    else {
      if (e.rowType === 'data' && e.data.econ_category_code.length === 6 && e.column.caption === 'Хуваарь') {
        if (e.eventType === 'mouseover') {
          e.cellElement.style.boxShadow = '0px 0px 0px 3px #2C77FF inset'
          e.cellElement.setAttribute('title', 'Өөрчлөх');
        } else {
          e.cellElement.style.boxShadow = 'none'
          e.cellElement.removeAttribute('title');
        }
      }
    }
  }
  const executeWithLoader = async (asyncFunc) => {
    // setLoader(true)
    //setLoader(true)
    setTimeout(() => {
      asyncFunc()
    }, 1)
    // setLoader(false)
  }

  const setEffect = async (e) => {
    if (e.changes[0].type === "remove") {
      try {
        afterAmt.current =  afterAmt.current * 1 + e.changes[0].key.transferAmt*1;
        tmpSelectedBudget.after =  tmpSelectedBudget.after*1 - e.changes[0].key.transferAmt*1;
        setRandomNum(Math.random());
        notify('Амжилттай хасагдлаа', "success", 2000);
      } catch (e) {
        notify('Хасах явцад алдаа гарлаа', "error", 2000);
      }
    }
  }

  const removeTransferRow = useCallback( (row) =>{
    requestDetail.current = requestDetail.current.filter(item => item.row_num !== row.data.row_num);
  },[requestDetail.current]);
  const renderTransaction = () => {

    console.log('renderTransaction');

    return (
          <Popup
              onHidden={clearValues}
              visible={debtModal}
              dragEnabled={false}
              width={'70%'}
              height={700}
              hideOnOutsideClick={false}
              showCloseButton={true}
              showTitle={true}
              title="Хуваарийн өөрчлөлтийн хүсэлт"
          >
              <ScrollView width="100%" height="100%">
              <div style={{ display: 'flex', gap: 10, fontSize: '.8rem' }}>
                <div className="card col-8" >
                  <div style={{ borderBottom: '1px solid #ddd', padding: '10px 20px' }}><b>Шилжүүлэх хуваарийн мэдээлэл</b></div>
                  <div style={{ display: 'flex', flexDirection: 'column', gap: 5, padding: '10px 20px' }}>
                    <div>Байгууллага: <b style={{ float: 'right' }}>{selectedBudget.org_name}</b></div>
                    <div>Хөтөлбөр: <b style={{ float: 'right' }}>{selectedBudget.agenda_data}</b></div>
                    <div>Зориулалт: <b style={{ float: 'right' }}>{selectedBudget.event_data}</b></div>
                    <div>Эдийн засгийн ангилал: <b style={{ float: 'right' }}>{selectedBudget.econ_name}</b></div>
                  </div>
                </div>
                <div className="card col-4" style={{ padding: '10px 20px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                  <div>Төсөвт жил: <b style={{ float: 'right' }}>{selectedBudget.budget_year || '-'}</b></div>
                  <div>Сар: <b style={{ float: 'right' }}>{(selectedBudget.selectedMonth || '0.00')}</b></div>
                  <div>Хуваарь: <b style={{ float: 'right' }}>{(numberWithCommas(selectedBudget.selectedAmount) || '0.00') + ' ₮'}</b></div>
                  <div>Гүйцэтгэл: <b style={{ float: 'right' }}>{(numberWithCommas(selectedBudget.perAmt) || '0.00') + ' ₮'}</b></div>
                  <div id={`${randomNum}`}>Зөрүү: <b style={{ float: 'right', color: '#f99d4b' }}>{(numberWithCommas(afterAmt.current) || '0.00') + ' ₮'}</b></div>
                  <div>Хүсэлт гаргасан дүн: <b style={{ float: 'right', color: '#18166a' }}>{formData.length > 0 ? numberWithCommas(_.sumBy(formData, "transferAmt")) : '0.00'} ₮</b></div>
                </div>
              </div>
              <div className="card" style={{ marginTop: 10, height: 'auto' }}>
                <div style={{ borderBottom: '1px solid #ddd', padding: '10px 20px' }}><b>Хүлээн авах хуваарийн мэдээлэл</b></div>
                <div style={{ padding: 20, display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: 30 }}>
                  <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
                    {user.organization.obt_id !== 3 ?
                        <SelectBox
                            grouped={user.organization.obt_id === 1}
                            onValueChanged={(e) => setToOrg(e.value.id)}
                            dataSource={orgList}
                            displayExpr="organization_name"
                            searchEnabled
                            label="Байгууллага:"
                            noDataText="хайлт олдсонгүй"
                            placeholder="сонгох"
                        />
                        :
                        <TextBox
                            label="Байгууллага:"
                            placeholder={user.organization.name}
                            readOnly
                        />
                    }
                    {
                      <SelectBox
                          dataSource={toAgendaData}
                          noDataText="Хөтөлбөр хоосон байна"
                          onValueChanged={(e) => setToAgenda(e.value)}
                          placeholder="сонгох"
                          showDataBeforeSearch={false}
                          valueExpr={"id"}
                          label="Хөтөлбөр:"
                          displayExpr="agenda" />
                    }
                    {
                        toAgenda &&
                        <SelectBox
                            dataSource={toEventData}
                            noDataText="Зориулалт хоосон байна"
                            onValueChanged={(e) => setToEvent(e.value)}
                            label="Зориулалт:"
                            placeholder="сонгох"
                            valueExpr={"id"}
                            showDataBeforeSearch={false}
                            displayExpr="event" />
                    }
                    {toEvent &&
                        <SelectBox
                            dataSource={toEconData}
                            noDataText='Эдийн засгийн ангилал хоосон байна'
                            onValueChanged={(e) => setToEconCat(e.value)}
                            label="Эдийн засгийн ангилал:"
                            placeholder="сонгох"
                            valueExpr={"id"}
                            displayExpr="econ_name" />
                    }
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
                    {
                        toAgenda && toEvent && toEconCat &&
                        <SelectBox
                            dataSource={toMonthData}
                            onValueChanged={(e) => setTmpSelectedBudget(e.value)}
                            width={150}
                            label="Сар:"
                            noDataText={'Сар хоосон байна'}
                            placeholder="сонгох"
                            displayExpr="budget_month" />
                    }
                    {tmpSelectedBudget && <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
                      <NumberBox
                          value={tmpSelectedBudget.huvaari * 1}
                          type='number'
                          format="#,##0.## ₮;"
                          label="Хуваарь:"
                          readOnly={true}
                          maxLength={4000}
                      />
                      <NumberBox
                          value={tmpSelectedBudget.guitsetgel * 1}
                          type='number'
                          format="#,##0.## ₮;"
                          label="Гүйцэтгэл:"
                          readOnly={true}
                          maxLength={4000}
                      />
                      <NumberBox
                          value={tmpSelectedBudget.debit * 1}
                          type='number'
                          format="#,##0.## ₮;"
                          label="Зөрүү:"
                          readOnly={true}
                          maxLength={4000}
                      />
                    </div>}
                  </div>
                  {tmpSelectedBudget &&
                      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
                          <NumberBox
                              ref={changeAmt}
                              onValueChanged={(e) => changeAmt.current = Math.abs(e.value)}
                              format="#,##0.## ₮;#,##0.## ₮"
                              label="Гүйлгээний дүн:"
                              maxLength={4000}
                          />
                          <NumberBox
                              value={tmpSelectedBudget?.after}
                              type='number'
                              readOnly={true}
                              format="#,##0.## ₮;"
                              label="Өөрчлөлтийн дараах дүн:"
                              maxLength={4000}
                          />
                        </div>
                        <Button text='Шилжүүлэх' hint="Шилжүүлэх хуваарийн мэдээллээс ХАСАХ"
                                style={{ backgroundColor: '#fff2f2', color: '#f66160', fontWeight: 'bold', border: 'none' }}
                                onClick={() => addToTmpDetail()}
                                disabled={false}
                        />
                      </div>
                  }
                </div>

                {formData && formData.length> 0 &&
                    <div style={{ padding: 10 }}>
                      <DataGrid
                          dataSource={formData}
                          showBorders={true}
                          columnAutoWidth={false}
                          showColumnLines={true}
                          wordWrapEnabled={true}
                          noDataText="Дата олдсонгүй."
                          onSaved={setEffect}
                          onCellPrepared={cellPrepared2}
                          onRowRemoved={(row)=>removeTransferRow(row)}
                      >
                        <Column dataField="orgName" caption="Байгууллага" visible={user.organization.obt_id !== 3} alignment="center" />
                        <Column dataField="agenda" caption="Хөтөлбөр" />
                        <Column dataField="event" caption="Зориулалт" />
                        <Column dataField="econ_name" caption="ЭЗА" />
                        <Column dataField="transfer_group" caption="Шилжүүлгийн төрөл" />
                        <Column dataField="budget_year" caption="Жил" width={60} alignment='center' />
                        <Column dataField="month" caption="Сар" width={60} alignment='center' />
                        <Column dataField="scheduleAmt" caption="Хуваарь" format="#,##0.00" />
                        <Column dataField="transferAmt" caption="Өөрчлөлт" format="#,##0.00" cellRender={(e) => <span style={{ color: '#003696' }}>{numberWithCommas(e.value)}</span>} />
                        <Column dataField="diffAmt" caption="Өөрчлөлтийн дараах дүн" format="#,##0.00"
                        />
                        <Editing
                            mode="form"
                            allowDeleting={true}
                            // allowAdding = {adding}
                            useIcons = {true}
                            texts={{
                              cancelAllChanges: 'Болих бүгд',
                              cancelRowChanges: 'Болих',
                              confirmDeleteMessage: 'Энэ гүйлгээний мэдээллийг устгах уу?',
                              confirmDeleteTitle: 'Баталгаажуулах',
                              deleteRow: 'Устгах',
                              editRow: 'Өөрчлөх',
                              saveAllChanges: 'Хадгалах бүгд',
                              saveRowChanges: 'Хадгалах',
                              undeleteRow: 'Буцаах',
                              validationCancelChanges: 'Баталгаажуулах болих',
                            }}
                        >
                        </Editing>
                      </DataGrid>
                    </div>
                }
                <div className="row">
                  <div className="col-12"
                       style={{display: 'flex', flexDirection: 'row-reverse'}}>
                    <Button icon="save" disabled={requestDetail.current.length === 0}
                            text={'Хадгалах'} type={'success'} style={{margin: '10px',fontFamily:'Montserrat'}}
                            hint="Гүйлгээг хадгалах"
                            onClick={() => executeWithLoader(nextTransaction)}/>
                    {/*<Button icon={'close'} text={'Гарах'} type={'success'} style={{margin: '10px',fontFamily:'Montserrat'}} hint="Нэмэх" onClick={() => clearValues()}/>*/}
                  </div>
                </div>
              </div>
              </ScrollView>
          </Popup>


    );
  };
  return (
      <div>
        <div style={{ margin: 10 }}>
          <BackButton onClick={() => history.push("/plan/schedule-edit")} />
        </div>
        <div className="card" style={{ height: '100%', minHeight: '100vh' }}>
          <TabComponent>
            {tabs.map((e, index) => (
                <div
                    key={index}
                    onClick={() => setSelectedTab(index)}
                    className={`tab ${selectedTab === index && "active"}`}
                    id={`schedReqTab_${index}`}
                >
                  <span className="title">{e}</span>
                </div>
            ))
            }
          </TabComponent>
          {selectedTab === 0 &&
              <div id="wrapper" height="100%" width="100%" style={{ padding: 10 }}>
                <Joyride
                    run={true}
                    steps={huvaariTabSteps}
                    continuous
                    showSkipButton
                    styles={{
                      options: {
                        zIndex: 100,
                        display: 'none'
                      }
                    }}
                    locale={guideLocale}
                    disableScrolling={true}
                />
                {
                  !debtModal && <DataGrid
                        dataSource={gridData}
                        onCellPrepared={cellPrepared}
                        noDataText='Дата байхгүй байна.'
                        keyExpr={"row_number"}
                        loadPanel={{ enabled: true, showPane: false, text: '', indicatorSrc: spinner }}
                        hoverStateEnabled={true}
                        showBorders={true}
                        //columnAutoWidth={true}
                        wordWrapEnabled={true}
                        showRowLines={true}
                        activeStateEnabled={false}
                        onCellClick={cellClick}
                        repaintChangesOnly={true}
                        //height={fullscreen ? 'auto' : 700}
                        onCellHoverChanged={handleOnCellHover}
                        onExporting={onExporting}
                        paging={{ enabled: false }}
                    >
                      <Toolbar>
                        <Item location="before">
                          {user.organization.obt_id !== 3 ?
                              <SelectBox
                                  grouped={user.organization.obt_id === 1}
                                  onValueChanged={(e) => { if (e.value) loadGridData(e.value.id); setDefaultOrg(e.value) }}
                                  dataSource={orgList}
                                  value={defaultOrg}
                                  displayExpr="organization_name"
                                  width={500}
                                  searchEnabled
                                  label="Байгууллага:"
                                  noDataText="хайлт олдсонгүй"
                                  placeholder="сонгох"
                              />
                              :
                              <TextBox
                                  label="Байгууллага:"
                                  placeholder={user.organization.name}
                                  readOnly
                                  width={500}
                                  style={{ bottom: 5 }}
                              />
                          }
                        </Item>
                        <Item name="searchPanel" />
                        <Item name="columnChooserButton" widget="dxButton" options={{ icon: "columnchooser" }} />
                        <Item name="exportButton" widget="dxButton" options={{ icon: "xlsxfile" }} />
                        <Item>
                          <div style={{ display: 'flex', gap: 5 ,alignItems:'center'}}>
                            <Button icon={expand ? 'collapse' : 'expand'} onClick={() => setExpand(prev => !prev)} hint={expand ? 'Хураах' : 'Дэлгэх'} />
                            <Button icon={fullscreen ? 'expandform' : 'fullscreen'} hint={fullscreen ? 'Багасгах' : 'Дэлгэцэд бүтэн харуулах'} onClick={() => { requestFullscreen(); setFullscreen(prev => !prev) }} />
                            <Button icon={isVisible ? 'eyeclose' : 'eyeopen'} onClick={() => setIsVisible(prev => !prev)} hint={isVisible ? 'Бүх баганыг харуулах' : 'Өөрчлөгдсөн баганыг харуулах'} />
                            <Button icon={info1} style={{ border: 'none', backgroundColor: 'transparent', transform: 'scale(1.6)' }} hint={`Гүйцэтгэл шинэчлэгдсэн огноо: ${defaultOrg?.last_performance_date || '-'}\nХуваарь шинэчлэгдсэн огноо: ${updatedDate.current}`} />
                            <RadioGroup
                                items={ezaAncestry}
                                defaultValue={selectedEza}
                                value={selectedEza}
                                onValueChanged={(e) => {setSelectedEza(e.value)}}
                                valueExpr={'code'}
                                displayExpr={'name'}
                                layout="horizontal"
                            />
                          </div>
                        </Item>
                       {/* <Item>
                          <div className="dx-field">

                          </div>
                        </Item>*/}
                      </Toolbar>
                      <GroupPanel visible={true} allowColumnDragging={false} />
                      <SearchPanel visible={false} placeholder={'Хайх'} width={"300"} highlightSearchText={true} />
                      <Export enabled={true} texts={{ exportAll: 'Excel загвар татах' }} />
                      <ColumnChooser height={"100%"} search={{ enabled: true }} mode="select" title={'Багана сонгох'} />
                      <ColumnFixing enabled={true} />
                      <Grouping
                          autoExpandAll={expand}
                          contextMenuEnabled={false}
                          expandMode="rowClick"
                          texts={{
                            groupContinuesMessage: 'Дараагийн хуудас',
                            groupContinuedMessage: 'Өмнөх хуудас'
                          }}
                      />
                      <Selection mode="single" />
                      <HeaderFilter visible={false} search={{ enabled: true }} />
                      <Column dataField="agenda_data" groupIndex={0} groupCellComponent={(e) => e.data.displayValue} caption="" showWhenGrouped={true} visible={false} />
                      <Column dataField="event_data" groupIndex={1} groupCellComponent={(e) => e.data.displayValue} caption="" showWhenGrouped={true} visible={false} />
                      <Column dataField="econ_name" width={'25rem'} caption="Эдийн засгийн ангилал" dataType="text" fixed={true} />
                      <Column caption="Нийт дүн" alignment={'center'}>
                        <Column dataField="total_year_amt" format="#,##0.00" dataType="number" caption="Хуваарь" cellRender={(e) => cellRenderSchedule(e, 0)} allowFiltering={false} width={'130'}/>
                        <Column dataField="per_total_year_amt" format="#,##0.00" dataType="number" caption="Гүйцэтгэл" allowFiltering={false} width={'100'}/>
                        <Column caption="Зөрүү" format="#,##0.00" dataType="number" cellRender={(e) => calcCellVal(e, 0)} allowFiltering={false} width={'130'}/>
                      </Column>
                      {
                        month_arr.map((i) => (
                            <Column caption={`${i}-p cap`} alignment={'center'}>
                              <Column dataField={`month${i}`} format="#,##0.00" alignment="right" dataType="number" cssClass="makeChanges"
                                      caption="Хуваарь" cellRender={(e) => cellRenderSchedule(e, i)} allowFiltering={false} width={'100'}/>
                              <Column dataField={`per_month${i}`} format="#,##0.00" alignment="right" dataType="number"
                                      caption="Гүйцэтгэл" allowFiltering={false} width={'100'}/>
                              <Column caption="Зөрүү" format="#,##0.00" dataType="number" cellRender={(e) => calcCellVal(e, i)} alignment="right" allowFiltering={false} width={'100'}/>
                            </Column>
                        ))
                      }
                      {!isVisible &&
                          <Summary>
                            <GroupItem column="total_year_amt_econ" summaryType="sum" showInGroupFooter={false} alignByColumn={true} showInColumn="total_year_amt" customizeText={(data) => numberWithCommas(data.value)} />
                            <GroupItem column="total_year_diff_econ" summaryType="sum" showInGroupFooter={false} alignByColumn={true} showInColumn="total_year_amt" customizeText={sumRenderText} />
                            <GroupItem column="per_total_year_amt_econ" summaryType="sum" showInGroupFooter={false} alignByColumn={true} showInColumn="per_total_year_amt" customizeText={(data) => numberWithCommas(data.value)} />
                            <GroupItem column="diff_year_amt_econ" summaryType="sum" showInGroupFooter={false} alignByColumn={true} showInColumn={7} customizeText={(data) => numberWithCommas(data.value)} />

                            {
                              month_arr.map((i) => (
                                  <GroupItem column={`month${i}_econ`} summaryType="sum" showInGroupFooter={false} alignByColumn={true} showInColumn={`month${i}`} customizeText={(data) => numberWithCommas(data.value)} />
                              ))
                            }

                            {
                              month_arr.map((i) => (
                                  <GroupItem column={`diff_month${i}_econ`} summaryType="sum" showInGroupFooter={false} alignByColumn={true} showInColumn={`month${i}`} customizeText={sumRenderText}/>
                              ))
                            }
                            {
                              month_arr.map((i) => (
                                  <GroupItem column={`per_month${i}_econ`} summaryType="sum" showInGroupFooter={false} alignByColumn={true} showInColumn={`per_month${i}`} customizeText={(data) => numberWithCommas(data.value)} />
                              ))
                            }
                            {
                              month_arr.map((i) => (
                                  <GroupItem column={`diff_per_month${i}_econ`} summaryType="sum" showInGroupFooter={false} alignByColumn={true} showInColumn={i * 4 + 7} customizeText={(data) => numberWithCommas(data.value)} />
                              ))
                            }

                            <TotalItem column="total_year_amt_econ" summaryType="sum" showInGroupFooter={false} showInColumn="total_year_amt" customizeText={(data) => numberWithCommas(data.value)} />
                            <TotalItem column="total_year_diff_econ" summaryType="sum" showInGroupFooter={false} showInColumn="total_year_amt" customizeText={sumRenderText} />
                            <TotalItem column="per_total_year_amt_econ" summaryType="sum" showInGroupFooter={false} showInColumn="per_total_year_amt" customizeText={(data) => numberWithCommas(data.value)} />
                            <TotalItem column="diff_year_amt_econ" summaryType="sum" showInGroupFooter={false} showInColumn={7} customizeText={(data) => numberWithCommas(data.value)} />

                            {
                              month_arr.map((i) => (
                                  <TotalItem column={`month${i}_econ`} summaryType="sum" showInGroupFooter={false} alignByColumn={true} showInColumn={`month${i}`} customizeText={(data) => numberWithCommas(data.value)} />
                              ))
                            }

                            {
                              month_arr.map((i) => (
                                  <TotalItem column={`diff_month${i}_econ`} summaryType="sum" showInGroupFooter={false} alignByColumn={true} showInColumn={`month${i}`} customizeText={sumRenderText}/>
                              ))
                            }

                            {
                              month_arr.map((i) => (
                                  <TotalItem column={`per_month${i}_econ`} summaryType="sum" showInGroupFooter={false} alignByColumn={true} showInColumn={`per_month${i}`} customizeText={(data) => numberWithCommas(data.value)} />
                              ))
                            }
                            {
                              month_arr.map((i) => (
                                  <TotalItem column={`diff_per_month${i}_econ`} summaryType="sum" showInGroupFooter={false} alignByColumn={true} showInColumn={i * 4 + 7} customizeText={(data) => numberWithCommas(data.value)} />
                              ))
                            }
                          </Summary>
                      }
                    </DataGrid>
                }
              </div>
          }
          {debtModal && renderTransaction() }
          {selectedTab === 1 && <RequestInformation />}
          {selectedTab === 2 && <ScheduleSubOrgRequests />}
        </div>
      </div>
  )
}
export default ScheduleEditReqInsertionV2

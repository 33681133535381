import React, { useState, useEffect, useContext, useRef } from "react";
import BudgetService from "../../../services/api/budget";
import notify from "devextreme/ui/notify";
import _ from 'lodash'
import { SelectBox } from "devextreme-react";
import styled from "styled-components";
import DataGridEza from "./DataGridEza";
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import { numberWithCommas } from "../../../util";
import Agenda from "./agenda";
import Event from "./event";
import DataGrid, {
    Column,
    Button as GridButton,
    Lookup,
    HeaderFilter,
    Summary,
    Selection,
    Toolbar,
    ColumnChooser,
    GroupItem,
    FilterPanel,
    Export, ColumnFixing, Scrolling, TotalItem, Paging, Editing
} from "devextreme-react/data-grid";
import RefService from "../../../services/api/refs";
import Button from "devextreme-react/button";
import { UserContext } from "../../../hooks/UserContext";
import { formatDate } from "../../../util";
import LoadPanelComponent from "../../budget/components/loadPanel";
import { Item } from "devextreme-react/tab-panel";
import { saveAs } from "file-saver-es";
import { BsFiletypeXls } from "react-icons/bs";
import { Workbook } from "exceljs";
import { exportDataGrid } from "devextreme/excel_exporter";
import moment from "moment";

export const CustomTabPanel = styled.div`
  display: flex;
  gap: 1rem;
  .tab {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 0.5rem;
    background-color: #f8f9fb;
    border-radius: 15px;
    transition: 0.3s all ease-in;
    border: 2px solid transparent;
    .title {
      font-size: 1rem;
      font-weight: 700;
    }
    &.active {
      background-color: white;
      border: 2px solid #eff0f3;
    }
    &:hover {
      background-color: white;
      border: 2px solid #eff0f3;
    }
  }
`

const BudgetTezEza = () => {
    const [yearList, setYearList] = useState([])
    const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
    const [tabIndex, setTabIndex] = useState(0)

    //EZA states
    const [diff, setDiff] = useState(0)
    const [diffPerc, setDiffPerc] = useState(0)
    const [prevDiff, setPrevDiff] = useState(0)
    const [prevDiffPerc, setPrevDiffPerc] = useState(0)
    const [budget, setBudget] = useState(0)
    const [prevBudget, setPrevBudget] = useState(0)
    const [forecastBudget, setForecastBudget] = useState(0)

    //Хөтөлбөр states
    const [agendaBudget, setAgendaBudget] = useState(0)
    const [agendaBudgetForecast, setAgendaBudgetForecast] = useState(0)
    const [agendaBudgetDiff, setAgendaBudgetDiff] = useState(0)
    const [agendaBudgetPercent, setAgendaBudgetPercent] = useState(0)
    const [agendaDiff, setAgendaDiff] = useState(0)

    //арга хэмжээ states
    const [eventBudget, setEventBudget] = useState(0)
    const [eventBudgetForecast, setEventBudgetForecast] = useState(0)
    const [eventBudgetDiff, setEventBudgetDiff] = useState(0)
    const [eventBudgetPercent, setEventBudgetPercent] = useState(0)

    //main states
    const [loadMe, setLoadMe] = useState(false);
    const { user } = useContext(UserContext);
    const [refAgenda, setRefAgenda] = useState([]);
    const [refEvent, setRefEvent] = useState([]);
    const dataGridEditedRef = useRef(null);
    const [amount, setAmount] = useState({});
    const [percent, setPercent] = useState({})
    const [editedEcon, setEditedEcon] = useState({});
    const [editedEcon2, setEditedEcon2] = useState({});
    const [removeRow, setRemoveRow] = useState('')
    
    const [type,setType] = useState(1)
    const [newArr, setNewArr] = useState([])
    const [visible,setVisible] = useState(false)
    const items = [
        { label: 'Хөтөлбөр', key: 1 },
        { label: 'Арга хэмжээ', key: 2 }
    ]
    const loadYearList = async () => {
        try {
            let result = await (await BudgetService.getOrgBudgetYear());
            setYearList(result)
        } catch (error) {
            notify(error.message, "error", 2000)
        }

    }
    useEffect(() => {
        loadYearList()

    }, [])

    useEffect(()=>{
        setNewArr([])
        setAgendaBudget(0)
        setAgendaBudgetDiff(0)
        setAgendaBudgetForecast(0)
        setAgendaBudgetPercent(0)
        setEventBudget(0)
        setEventBudgetDiff(0)
        setEventBudgetForecast(0)
        setEventBudgetPercent(0)
    }, [currentYear])

    //---------------------------------------------//
    const loadAllBudget = async () => {
        try {
            setLoadMe(true);
            const r = await RefService.getAgendas()
            const r2 = await RefService.getEvents()
            setRefAgenda(r.data)
            setRefEvent(r2.data)
            setLoadMe(false);
        } catch (e) {
            setLoadMe(false);
            notify(e.message, "error", 2000);
        }
    }
    useEffect(() => {
        loadAllBudget();
    }, [])


    const onExporting = (e) => {
        const totalColumns = e.component.getVisibleColumns().length;
        const targetColumnIndices = [totalColumns - 2, totalColumns - 1, totalColumns];
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Main sheet');
        worksheet.columns = [
            { width: 10 }, { width: 100 }, { width: 30 }, { width: 30 }, { width: 30 }, { width: 30 }, { width: 15 }, { width: 30 }
        ];
        exportDataGrid({
            component: e.component,
            worksheet,
            autoFilterEnabled: true,
            keepColumnWidths: false,
            topLeftCell: { row: 6, column: 2 },
            customizeCell: ({ gridCell, excelCell }) => {
                if (gridCell.rowType === 'header') {
                    excelCell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'EEF0F4' } };
                    excelCell.font = { color: { argb: '15186A' }, name: 'Segoe UI Semibold', size: 8 };
                }
                if (gridCell.rowType === 'group') {
                    if (gridCell.groupIndex === 0) {

                        excelCell.font = { color: { argb: 'F93A3A' }, name: 'Segoe UI Semibold', size: 8 };
                        excelCell.font.fontWeight = 'bold';
                        if (excelCell._column._number !== 2) excelCell.alignment = { horizontal: 'right' };
                    }
                    if (gridCell.groupIndex === 1) {
                        excelCell.font = { color: { argb: '2C77FF' }, fontWeight: 'bold', name: 'Segoe UI Semibold', size: 8 };
                        if (excelCell._column._number !== 2) excelCell.alignment = { horizontal: 'right' };
                    }
                    else if (gridCell.groupIndex > 1) {
                        excelCell.alignment = { wrapText: false };
                        if (excelCell._column._number !== 2) excelCell.alignment = { horizontal: 'right' };
                        excelCell.font = { fontWeight: 'bold', name: 'Segoe UI Semibold', size: 8 };
                    }

                }
                if (gridCell.rowType === 'totalFooter' && excelCell.value) {
                    excelCell.font = { name: 'Segoe UI Semibold', size: 8 };
                    excelCell.font.italic = true;
                    excelCell.alignment = { vertical: 'bottom', horizontal: 'right' }
                    if (excelCell._column && excelCell._column._number === targetColumnIndices[0]) {
                        excelCell.font = { name: 'Segoe UI Semibold', bold: true, italic: true, size: 8, color: { argb: diff < 0 ? '50c687' : 'f66160' } }
                        excelCell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: diff < 0 ? 'E2FBEE' : 'FFF2F2' } }
                        excelCell.border = {
                            top: { style: 'thin', color: { argb: diff < 0 ? '50c687' : 'f66160' } },
                            left: { style: 'thin', color: { argb: diff < 0 ? '50c687' : 'f66160' } },
                            bottom: { style: 'thin', color: { argb: diff < 0 ? '50c687' : 'f66160' } },
                            right: { style: 'thin', color: { argb: diff < 0 ? '50c687' : 'f66160' } }
                        }
                    }
                }
                if (gridCell.rowType === 'data') {
                    excelCell.alignment = { wrapText: false };
                    excelCell.font = { name: 'Segoe UI', size: 8 };
                    if (gridCell.column.dataField !== 'econ_category_id') {
                        excelCell.numFmt = '#,##0.00';
                    }
                    if (excelCell._column && (excelCell._column._number === targetColumnIndices[1] || excelCell._column._number === targetColumnIndices[2])) {
                        excelCell.alignment = { horizontal: 'right' }
                        if (gridCell.value < 0) {
                            excelCell.font = { name: 'Segoe UI', size: 8, color: { argb: '50c687' } }
                        } else if (gridCell.value > 0) {
                            excelCell.font = { name: 'Segoe UI', size: 8, color: { argb: 'f66160' } }
                        }
                    }
                    if (excelCell._column && excelCell._column._number === targetColumnIndices[0]) {
                        const batlagdsan = parseFloat(gridCell.data.batlagdsan)
                        const batlagdsan_edit = parseFloat(gridCell.data.batlagdsan_edit)
                        if (batlagdsan < batlagdsan_edit) {
                            excelCell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFF2F2' } }
                            excelCell.font = { name: 'Segoe UI', size: 8, color: { argb: 'F66160' } }
                            excelCell.border = {
                                top: { style: 'thin', color: { argb: 'F66160' } },
                                left: { style: 'thin', color: { argb: 'F66160' } },
                                bottom: { style: 'thin', color: { argb: 'F66160' } },
                                right: { style: 'thin', color: { argb: 'F66160' } }
                            }
                        } else if (batlagdsan > batlagdsan_edit) {
                            excelCell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'E2FBEE' } }
                            excelCell.font = { name: 'Segoe UI', size: 8, color: { argb: '50C687' } }
                            excelCell.border = {
                                top: { style: 'thin', color: { argb: '50C687' } },
                                left: { style: 'thin', color: { argb: '50C687' } },
                                bottom: { style: 'thin', color: { argb: '50C687' } },
                                right: { style: 'thin', color: { argb: '50C687' } }
                            }
                        }
                    }
                }
                if (['parent_agenda_code', 'agenda_code', 'parent_event_code', 'event_code'].includes(gridCell.column.dataField)) {
                    if (excelCell._row && excelCell._row._cells && excelCell._row._cells[1].model && excelCell._row._cells[1].model.value) {
                        excelCell._row._cells[1].model.value = excelCell._row._cells[1].model.value.replace(": ", "");
                    }

                }
                if (gridCell.column.dataField === 'econ_category_code_3' || gridCell.column.dataField === 'econ_category_code_4') {
                    /* excelCell._row._cells[1].model.value = 'dddd';
                     excelCell._row.hidden = true;*/
                    if (excelCell._row && excelCell._row._cells && excelCell._row._cells[1].model && excelCell._row._cells[1].model.value === '') {
                        excelCell._row.hidden = true;
                    }
                }

                if (gridCell.rowType === 'group' && gridCell.groupIndex >= 1 && gridCell.groupIndex <= 6) {
                    if (excelCell._column._number !== 2) {
                        excelCell.alignment = { horizontal: 'right', wrapText: false };
                        excelCell._row._cells[1].model.value = ' '.repeat(gridCell.groupIndex) + excelCell._row._cells[1].model.value;
                    }
                }
                if (gridCell.rowType === 'data') {
                    excelCell._row._cells[1].model.value = `    ${excelCell._row._cells[1].model.value}`
                }

            },
        }).then(() => {
            Object.assign(worksheet.getRow(1).getCell(1), {
                value: 'Гарчиг:',
                font: { name: 'Arial', size: 8 },
            })
            Object.assign(worksheet.getRow(1).getCell(2), {
                value: `${currentYear} оны төсвийн таамаглал (хөтөлбөр арга, хэмжээгээр)`,
                font: { name: 'Arial', size: 8, 'bold': true, color: { argb: '0070c0' } },
            })
            Object.assign(worksheet.getRow(2).getCell(1), {
                value: 'Байгууллага:',
                font: { name: 'Arial', size: 8 },
            })
            Object.assign(worksheet.getRow(2).getCell(2), {
                value: user.organization.name,
                font: { name: 'Arial', size: 8 },
            })
            Object.assign(worksheet.getRow(3).getCell(1), {
                value: 'Хэрэглэгч:',
                font: { name: 'Arial', size: 8 },
            })
            Object.assign(worksheet.getRow(3).getCell(2), {
                value: `${user.last_name.slice(0, 1)}. ${user.first_name}, ${user.position_name}`,
                font: { name: 'Arial', size: 8 },
            })
            Object.assign(worksheet.getRow(4).getCell(1), {
                value: 'Огноо:',
                font: { name: 'Arial', size: 8 },
            })
            Object.assign(worksheet.getRow(4).getCell(2), {
                value: new moment().locale('mn').format('lll'),
                font: { name: 'Arial', size: 8 },
            })
        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer], { type: 'application/octet-stream' }), "OG-" + user.organization.name + "-" + formatDate(new Date()) + ".xlsx");
            });
        });
        e.cancel = true;
    }
    const onCellPreparedHandler = (e) => {
        if (e.rowType === 'header') {
            e.cellElement.wordWrapEnabled = true;
            e.cellElement.setAttribute('style', `text-align: center; border-color: #d4dcec; font-size: 12px; font-family: 'Segoe UI'; background-color: #EEF0F4; color: #15186A; font-weight: bold; vertical-align: middle`)

        }
        else if (e.rowType === 'group') {
            e.cellElement.style.fontFamily = "Segoe UI"
            //e.cellElement.wordWrapEnabled = true;
            e.cellElement.style.color = (e.row.groupIndex === 0 ? "#F93A3A" : ((e.row.groupIndex && e.row.groupIndex === 1) ? "#2C77FF" : 'black'))
            e.cellElement.style.backgroundColor = "#fff"
            e.cellElement.style.fontWeight = "bold";
            e.cellElement.style.fontSize = "12px"
            e.cellElement.style.borderColor = "#D4DCEC"
        } else if (e.rowType === "data") {
            e.cellElement.style.fontFamily = 'Segoe UI'
            e.cellElement.style.fontWeight = 'normal'
            e.cellElement.style.color = "#000000"
            const totalColumns = e.component.getVisibleColumns().length;
            const targetColumnIndices = [totalColumns - 4, totalColumns - 3, totalColumns - 2];
            if (e.columnIndex === targetColumnIndices[0]) {
                const elemRowIndex = e.rowIndex.toString()
                if (Object.keys(percent).includes(elemRowIndex)) {
                    const budget = parseInt(e.data.batlagdsan)
                    const forecast = parseInt(e.data.batlagdsan_edit)
                    const backColor = forecast > budget ? '#FFF2F2' : forecast < budget ? '#E2FBEE' : ''
                    e.cellElement.style.backgroundColor = backColor
                }
                if (Object.keys(amount).includes(elemRowIndex)) {
                    const budget = parseInt(e.data.batlagdsan)
                    const forecast = parseInt(e.data.batlagdsan_edit)
                    const backColor = forecast > budget ? '#FFF2F2' : forecast < budget ? '#E2FBEE' : ''
                    e.cellElement.style.backgroundColor = backColor
                }

            }
            if (e.columnIndex === targetColumnIndices[1]) {
                const elemRowIndex = e.rowIndex.toString()
                if (Object.keys(percent).includes(elemRowIndex)) {
                    const color = e.data.percent > 0 ? '#f66160' : e.data.percent < 0 ? '#50c687' : ''
                    e.cellElement.style.color = color
                }
            }
            if (e.columnIndex === targetColumnIndices[2]) {
                const elemRowIndex = e.rowIndex.toString()
                if (Object.keys(amount).includes(elemRowIndex)) {
                    const color = e.data.amount > 0 ? '#f66160' : e.data.amount < 0 ? '#50c687' : ''
                    e.cellElement.style.color = color
                }
            }
            if (e.rowType !== "data" || e.columnIndex != 0) {
                return;
            }
            const currentCellValue = e.data.parent;
            const nextRowData = e.component.getDataSource().items()[e.rowIndex - 1];
            const prevRowData = e.component.getDataSource().items()[e.rowIndex + 1];
            const prevCellValue = prevRowData ? prevRowData.parent : null
            const nextCellValue = nextRowData ? nextRowData.parent : null;
            if (prevCellValue !== currentCellValue) {
                e.cellElement.style.borderTop = '1px solid #DEDEDE'
            } else if (prevCellValue === currentCellValue) {
                e.cellElement.style.border = `none`
            } else {
                e.cellElement.style.borderTop = `1px solid #DEDEDE`
            }

            if (currentCellValue === nextCellValue) {
                const isFirstRow = e.rowIndex === 0 || currentCellValue !== e.component.getDataSource().items()[e.rowIndex - 1]?.parent;

                if (!isFirstRow) {
                    e.cellElement.rowSpan = 1;
                    e.cellElement.innerHTML = ``;
                    e.cellElement.style.borderTop = 'none'
                } else {
                    e.cellElement.style.borderBottom = '1px solid #DEDEDE'
                    e.cellElement.innerHTML = ``
                }
            }
            if (e.rowType === 'data' && e.rowIndex === 0) {
                e.cellElement.style.borderTop = 'none'
            }
        }
    }

    const revertToOriginalState = () => {
        newArr.splice(0, newArr.length)
        setNewArr([])
        setEditedEcon({})
        setEditedEcon2({})
        setRemoveRow('ALL')
    };

    const setPercentValue = async (newData, currentRowData, value) => {
        const rowIndex = dataGridEditedRef.current.instance.getRowIndexByKey(value)
        if (currentRowData !== null) {
            newData.percent = currentRowData;
        }
        const confirmedBudget = parseFloat(value.batlagdsan);
        const forecastBudget = parseFloat(value.batlagdsan_edit);
        const checkBudget = confirmedBudget === forecastBudget;
        const same = value.amount === 0
        if (rowIndex !== -1) {
            let eEcon = editedEcon;
            eEcon[value['parent'] + '' + value['child']] = rowIndex;
            setEditedEcon(eEcon)
        }
        foreCastingByPercent(rowIndex, value, currentRowData, checkBudget, same)
    }
    const setAmountValue = async (newData, currentRowData, value) => {
        const rowIndex = dataGridEditedRef.current.instance.getRowIndexByKey(value)
        if (currentRowData !== null) {
            newData.amount = currentRowData
        }
        const confirmedBudget = parseFloat(value.batlagdsan);
        const forecastBudget = parseFloat(value.batlagdsan_edit);
        const checkBudget = confirmedBudget === forecastBudget;
        const same = value.percent === 0
        if (rowIndex !== -1) {
            let eEcon = editedEcon2;
            eEcon[value['parent'] + '' + value['child']] = rowIndex;
            setEditedEcon2(eEcon)
        }
        foreCastingByAmount(rowIndex, value, currentRowData, checkBudget, same)
    }

    const foreCastingByPercent = (el, data, newPercent, checkDiff, same) => {

        const check = newPercent < 0;
        let i1 = el === -1 ?
            editedEcon[data['parent'] + '' + data['child']]
                ? editedEcon[data['parent'] + '' + data['child']]
                : editedEcon2[data['parent'] + '' + data['child']] 
                ? editedEcon2[data['parent'] + '' + data['child']] 
                : 0
            : el;
        if (!isNaN(newPercent)) {
            const absolutePercent = Math.abs(newPercent);
            const increaseFactor = check ? 1 - absolutePercent / 100 :  1 + newPercent /100;
            const oldInput = same && (checkDiff || !checkDiff) ? parseFloat(data.batlagdsan) : parseFloat(data?.batlagdsan) + data.amount;
            const currentInput = oldInput * increaseFactor;
            let val = data.amount === 0 && newPercent === 0 ? data.batlagdsan : currentInput
           
            dataGridEditedRef.current.instance.cellValue(i1, 'batlagdsan_edit',val);
            dataGridEditedRef.current.instance.saveEditData();

            let batlagdsan = _.sumBy((dataGridEditedRef.current.props.dataSource), obj => Number(obj.batlagdsan))
            let batlagdsan_edit = _.sumBy((dataGridEditedRef.current.props.dataSource), obj => Number(obj.batlagdsan_edit))
            let prev_budget = _.sumBy((dataGridEditedRef.current.props.dataSource), obj => Number(obj.prev_budget))

            let dif = batlagdsan_edit - batlagdsan
            let perc = ((batlagdsan_edit * 100) / batlagdsan) - 100
            let prevDif = batlagdsan_edit - prev_budget
            let prevPerc = ((batlagdsan_edit * 100) / prev_budget) - 100

            newArr.find((row)=>{
                if(row.child === data.child){
                    row.percent = newPercent
                }
            })
            if(newPercent === 0 && data.amount === 0){
                const index = newArr.findIndex((row) => {
                    if (row.child === data.child) {
                      return true
                    }
                    return false; 
                });
                
                if (index !== -1) {
                    newArr.splice(index, 1);
                }
            }
            setDiffPerc(perc)
            setDiff(dif)
            setPrevDiff(prevDif)
            setPrevDiffPerc(prevPerc)
            let p = percent;
            p[i1] = newPercent;
            setPercent(p);
        }
    }
    useEffect(()=>{
        let dif1 = agendaBudgetForecast - agendaBudget
        let perc = ((agendaBudgetForecast * 100) / agendaBudget) - 100
        setAgendaBudgetDiff(dif1)
        setAgendaBudgetPercent(perc)
    },[agendaBudgetForecast])
 
    const foreCastingByAmount = (el, data, newAmount, checkDiff, same) => {
        const check = newAmount < 0;
        let i1 = el === -1 ?
            editedEcon2[data['parent'] + '' + data['child']]
                ? editedEcon2[data['parent'] + '' + data['child']]
                : editedEcon[data['parent'] + '' + data['child']]
                ? editedEcon[data['parent'] + '' + data['child']] 
                : 0
            : el;

        if (!isNaN(newAmount)) {
            let absolutePercent = Math.abs(data.percent)
            const increaseFactor = data.percent < 0 ? 1 - absolutePercent / 100 :  1 + data.percent /100;
            const oldInput = same && (checkDiff || !checkDiff) ? parseFloat(data.batlagdsan) : parseFloat(data?.batlagdsan) * increaseFactor;

            const currentInput = check ? oldInput - Math.abs(newAmount) : oldInput + newAmount;
            let val = data.percent === 0 && newAmount === 0 ? data.batlagdsan : currentInput

            dataGridEditedRef.current.instance.cellValue(i1, 'batlagdsan_edit', val);
            dataGridEditedRef.current.instance.saveEditData();

            let batlagdsn = _.sumBy((dataGridEditedRef.current.props.dataSource), obj => Number(obj.batlagdsan))
            let batlagdsn_edit = _.sumBy((dataGridEditedRef.current.props.dataSource), obj => Number(obj.batlagdsan_edit))
            let prev_budget = _.sumBy((dataGridEditedRef.current.props.dataSource), obj => Number(obj.prev_budget))

            let dif = batlagdsn_edit - batlagdsn
            let perc = ((batlagdsn_edit * 100) / batlagdsn) - 100
            let prevDif = batlagdsn_edit - prev_budget
            let prevPerc = ((batlagdsn_edit * 100) / prev_budget) - 100

            newArr.find((row)=>{
                if(row.child === data.child){
                    row.amount = newAmount
                }
            })
            if( newAmount === 0 && data.percent === 0){
                const index = newArr.findIndex((row) => {
                    if (row.child === data.child) {
                        return true
                      }
                      return false; 
                });
                if (index !== -1) {
                    newArr.splice(index, 1);
                }
            }
            setDiffPerc(perc)
            setDiff(dif)
            setPrevDiff(prevDif)
            setPrevDiffPerc(prevPerc)

            let p = amount;
            p[i1] = newAmount;
            setAmount(p);
        }
    }

    const textAreaOptions = { format: '#,##0.00' };


    const onZero = (e) => {
        // const rowToRemove = newArr.find((row) => row.child === e.row.data.child);
        if (type === 2) {
            const index = newArr.findIndex((row) => row.child === e.row.data.child);
            let diff = dataGridEditedRef.current.props.dataSource[index].batlagdsan_edit - dataGridEditedRef.current.props.dataSource[index].batlagdsan

            if (diff > 0) {
                let val = agendaBudgetForecast - diff
                let dif = agendaBudgetDiff - diff
                setAgendaBudgetForecast(val)
                setAgendaBudgetDiff(dif)
            } else {
                let num = Math.abs(diff)
                let val1 = agendaBudgetForecast + num
                let dif = agendaBudgetDiff + num
                setAgendaBudgetForecast(val1)
                setAgendaBudgetDiff(dif)
            }
        }

            const updatedData = newArr.filter((row) => row.child !== e.row.data.child);
            setNewArr(updatedData);
            setRemoveRow(e.row.data.child)
        
    }
    useEffect(()=>{
        if(newArr.length === 0){
            setVisible(false)
        } 
    },[newArr])
    const cellRender = (e) => {
        let batlagdsan = e.data.batlagdsan
        let batlagdsan_edit = e.data.batlagdsan_edit
        let diffAmount = 0;
        if (batlagdsan || batlagdsan_edit && e.data.percent === 0 && e.data.amount !== 0) {
            diffAmount = batlagdsan_edit !== e.data.batlagdsan ? (batlagdsan_edit - batlagdsan) : 0;
        }
        if (diffAmount > 0) {
            return (<div className="inc customCell" style={{ flexDirection: 'column', display: 'flex', justifyContent: 'space-between' }}>
                <div className="current-value" style={{ fontWeight: 'normal', color: '#000000' }}>{e.text}</div>
                <div className="diff" style={{ color: '#f66160' }}>{numberWithCommas(diffAmount)}</div>
            </div>)
        } else if (diffAmount < 0) {
            const val = Math.abs(diffAmount)
            return <div className="dec customCell" style={{ flexDirection: 'column', display: 'flex', justifyContent: 'space-between' }}>
                <div className="current-value" style={{ fontWeight: 'normal', color: '#000000' }}>{e.text}</div>
                <div className="diff" style={{ color: '#3daa75' }}>({numberWithCommas(val)})</div>
            </div>
        } else {
            return <span style={{ fontWeight: 'normal', color: '#000000' }}>{e.text}</span>
        }
    }
    return (
        <div id="loadMe" style={{ padding: '10px' }}>

            <div>
                <SelectBox
                    items={yearList}
                    valueExpr="value"
                    displayExpr="value"
                    label="Төсвийн жил"
                    width='10rem'
                    defaultValue={currentYear}
                    onValueChanged={(e) => { 
                        setCurrentYear(e.value); 
                        // setNewArr([]) 
                        // setDiff(0);
                        // setDiffPerc(0)
                        // setPrevDiff(0)
                        // setPrevDiffPerc(0)
                        // setPrevBudget(0)
                        // setBudget(0)
                        // setForecastBudget(0)
                    }}
                    placeholder="сонгох" style={{ borderRadius: 10 }} />
            </div>
            {<div style={{ border: '1px solid #DEDEDE', borderRadius: '20px', padding: 10, margin: '10px 0' }}>
                <div>
                    <CustomTabPanel>
                        {[
                            "Хөтөлбөр, арга хэмжээгээр",
                            "Эдийн засгийн ангиллаар",
                        ].map((item, index) => (
                            <div
                                key={index}
                                className={`tab ${tabIndex === index && "active"
                                    }`} style={{ marginBottom: '1rem', fontFamily: 'Segoe UI' }}
                                onClick={() => {
                                    setTabIndex(index)
                                    setDiff(0);
                                    setDiffPerc(0)
                                    setPrevDiff(0)
                                    setPrevDiffPerc(0)
                                    setPrevBudget(0)
                                    setBudget(0)
                                    setForecastBudget(0)
                                }}
                            >
                                <span className="title">{item}</span>
                            </div>
                        ))}
                    </CustomTabPanel>
                </div>
                {tabIndex === 0 ?
                    <>
                        <div style={{ display: 'flex', gap: '1rem', marginBottom: 10 }} >
                            {/* ---Хөтөлбөр карт--- */}
                            <div className="col-6" style={{ border: '1px solid #DEDEDE', borderRadius: 15 }}>
                                <div style={{ borderBottom: '1px solid #DEDEDE', padding: 10 }}>
                                    <span style={{ fontWeight: 'bold' }}>Хөтөлбөр</span>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: 10, gap: 5 }}>
                                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 20 }}>
                                        <span style={{ width: '45%', textAlign: 'end' }}>Төсөв:</span>
                                        <span style={{ width: '45%' }}>
                                            {numberWithCommas(agendaBudget)}
                                        </span>
                                    </div>
                                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 20 }}>
                                        <span style={{ width: '45%', textAlign: 'end' }}>Таамаглал:</span>
                                        <span style={{ width: '45%' }}>
                                            {agendaBudget === agendaBudgetForecast ?
                                                <span>-</span>
                                                :
                                                <span>{numberWithCommas(agendaBudgetForecast)}</span>
                                            }
                                        </span>
                                    </div>
                                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 20 }}>
                                        <span style={{ width: '45%', textAlign: 'end' }}>Зөрүү дүн:</span>
                                        <span style={{ width: '45%', fontWeight: agendaBudgetDiff !== 0 ? 'bold' : '' }}>
                                            {agendaBudgetDiff < 0 ? (
                                                <span>({numberWithCommas(Math.abs(agendaBudgetDiff))})</span>
                                            ) : agendaBudgetDiff > 0 ? (
                                                <span>{numberWithCommas(agendaBudgetDiff)}</span>
                                            ) :
                                                <span>-</span>
                                            }
                                        </span>
                                    </div>
                                    <div style={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', gap: 20 }}>
                                        <span style={{ width: '45%', textAlign: 'end' }}>Хувь:</span>
                                        {agendaBudgetPercent < 0 ?
                                            <div style={{ width: '45%', display: 'flex', justifyContent: 'flex-start', gap: 5, fontWeight: 'bold', alignItems: 'center', color: agendaBudgetPercent < 0 ? '#3daa75' : '#f66160' }}>
                                                <span>{numberWithCommas(Math.abs(agendaBudgetPercent))}%</span>
                                                <FaArrowDown size={12} />
                                            </div>
                                            : agendaBudgetPercent > 0 ?
                                                <div style={{ width: '45%', display: 'flex', justifyContent: 'flex-start', gap: 5, fontWeight: 'bold', alignItems: 'center', color: agendaBudgetPercent < 0 ? '#3daa75' : '#f66160' }}>
                                                    <span>{numberWithCommas(agendaBudgetPercent)}%</span>
                                                    <FaArrowUp size={12} />
                                                </div>
                                                : <span style={{ width: '45%' }}>-</span>
                                        }
                                    </div>
                                </div>
                            </div>

                            {/* ---Арга хэмжээ--- */}
                            <div className="col-6" style={{ border: '1px solid #DEDEDE', borderRadius: 15 }}>
                                <div style={{ borderBottom: '1px solid #DEDEDE', padding: 10 }}>
                                    <span style={{ fontWeight: 'bold' }}>Арга хэмжээ</span>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: 10, gap: 5 }}>
                                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 20 }}>
                                        <span style={{ width: '45%', textAlign: 'end' }}>Төсөв:</span>
                                        <span style={{ width: '45%' }}>
                                            {numberWithCommas(eventBudget)} {agendaDiff > 0 && eventBudget > 0 && ('+ ' + '( ' + numberWithCommas(agendaDiff) + ' )')}
                                        </span>
                                    </div>
                                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 20 }}>
                                        <span style={{ width: '45%', textAlign: 'end' }}>Таамаглал:</span>
                                        <span style={{ width: '45%' }}>
                                            {eventBudget === eventBudgetForecast ?
                                                <span>-</span>
                                                :
                                                <span>{numberWithCommas(eventBudgetForecast)}</span>
                                            }
                                        </span>
                                    </div>
                                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 20 }}>
                                        <span style={{ width: '45%', textAlign: 'end' }}>Зөрүү дүн:</span>
                                        <span style={{ width: '45%', fontWeight: eventBudgetDiff !== 0 ? 'bold' : '' }}>
                                            {eventBudgetDiff < 0 ?
                                                <span>({numberWithCommas(Math.abs(eventBudgetDiff))})</span>
                                                : eventBudgetDiff > 0 ?
                                                    <span>{numberWithCommas(eventBudgetDiff)}</span>
                                                    : <span>-</span>
                                            }
                                        </span>
                                    </div>
                                    <div style={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', gap: 20 }}>
                                        <span style={{ width: '45%', textAlign: 'end' }}>Хувь:</span>
                                        {eventBudgetPercent < 0 ?
                                            <div style={{ width: '45%', display: 'flex', justifyContent: 'flex-start', gap: 5, alignItems: 'center', fontWeight: 'bold', color: eventBudgetPercent < 0 ? '#3daa75' : '#f66160' }}>
                                                <span>{numberWithCommas(Math.abs(eventBudgetPercent))}%</span>
                                                <FaArrowDown size={12} />
                                            </div>
                                            : eventBudgetPercent > 0 ?
                                                <div style={{ width: '45%', display: 'flex', justifyContent: 'flex-start', gap: 5, alignItems: 'center', fontWeight: 'bold', color: eventBudgetPercent < 0 ? '#3daa75' : '#f66160' }}>
                                                    <span>{numberWithCommas(eventBudgetPercent)}%</span>
                                                    <FaArrowUp size={12} />
                                                </div>
                                                : <span style={{ width: '45%' }}>-</span>
                                        }
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div>
                            {
                                <DataGrid dataSource={newArr} ref={dataGridEditedRef} hoverStateEnabled={true}
                                    columnAutoWidth={true}
                                    allowColumnResizing={true}
                                    height={"auto"}
                                    width={'100%'}
                                    id="editedDataGrid"
                                    visible={newArr && newArr.length > 0  ? true : false}
                                    wordWrapEnabled={true}
                                    columnResizingMode={'nextColumn'}
                                    paging={{ enabled: false }}
                                    style={{ marginTop: 5, overflowY: "auto", overflowX: "hidden", }}
                                    showBorders={true}
                                    showRowLines={true}
                                    sorting={{ mode: 'none' }}
                                    showColumnLines={true}
                                    showColumnHeaders={true}
                                    onCellPrepared={onCellPreparedHandler}
                                    onExporting={onExporting}
                                >
                                    <ColumnFixing enabled={false} />
                                    <FilterPanel
                                        visible={false}
                                        texts={{ clearFilter: "Шүүлтүүр цэвэрлэх", createFilter: "Шүүх" }}
                                    />
                                    <HeaderFilter visible={true} search={true} texts={{ cancel: 'Болих', ok: 'Шүүх', emptyValue: 'Хоосон утга' }} />
                                    <Selection mode="single" />
                                    <Export enabled={true} />
                                    <Scrolling columnRenderingMode="virtual" />
                                    <Toolbar>
                                        <Item>
                                            <Button onClick={revertToOriginalState} icon="revert" hint="Бүх өөрчлөлтийг буцаах" />
                                        </Item>
                                        <Item name="exportButton"
                                            render={(e) => {
                                                return <Button
                                                    onClick={e.options.onClick}
                                                    icon="xlsxfile"
                                                    render={() => {
                                                        return <BsFiletypeXls size={"1rem"} color="#172B4D" />
                                                    }}
                                                    hint="Export table to excel"
                                                    style={{ color: "red" }}>
                                                </Button>
                                            }}
                                        />
                                        <Item name="columnChooserButton"
                                            render={(e) => {
                                                return <Button onClick={e.options.onClick} hint="Багана сонгох" size='1rem' id='columnChooserButton' icon="columnchooser" />
                                            }}
                                        />
                                    </Toolbar>
                                    <ColumnChooser
                                        enabled={true} height={"100%"} search={true}
                                        mode="select"
                                        title={'Багана сонгох'}
                                    />
                                    <Column dataField="parent" caption={newArr && newArr[0]?.child.charAt(0) === '7' ? "Хөтөлбөр" : "Арга хэмжээ"} showInColumnChooser={false}
                                        allowEditing={false}>
                                        <Lookup dataSource={newArr && newArr[0]?.child.charAt(0) === '7' ? refAgenda : refEvent} valueExpr='code' displayExpr={(item) => {
                                            return item && `${item.code.slice(0, 5)} - ${item.name}`
                                        }} />
                                    </Column>
                                    <Column dataField="child" caption={newArr && newArr[0]?.child.charAt(0) === '7' ? "Дэд хөтөлбөр" : "Зориулалт"} showInColumnChooser={false}
                                        allowEditing={false} sortOrder="asc">
                                        <Lookup dataSource={newArr && newArr[0]?.child.charAt(0) === '7' ? refAgenda : refEvent} valueExpr='code' displayExpr={(item) => {
                                            return item && `${item.code.slice(0, 5)} - ${item.name}`
                                        }} />
                                    </Column>
                                    <Column caption={`${currentYear - 1}`} alignment="center" visible={false}>
                                        <Column dataField={`prev_budget`} format="#,##0.00" alignment="right" dataType="number" allowEditing={false}
                                            caption={`Төсөв`} allowFiltering={false} allowResizing={true} visible={false}>
                                        </Column>
                                        <Column dataField={`p1_guitsetgel`} format="#,##0.00" alignment="right" dataType="number" allowEditing={false}
                                            caption={`Гүйцэтгэл`} allowFiltering={false} allowResizing={true} visible={false}>
                                        </Column>
                                    </Column>
                                    <Column caption={`${currentYear}`} alignment="center">
                                        <Column dataField={`batlagdsan`} format="#,##0.00" alignment="right" dataType="number" allowEditing={false}
                                            caption={`Төсөв`} on allowFiltering={false} allowResizing={true}>
                                        </Column>
                                        <Column dataField={`guitsetgel`} format="#,##0.00" alignment="right" dataType="number" allowEditing={false}
                                            caption={`Гүйцэтгэл`} on allowFiltering={false} allowResizing={true} visible={false}>
                                        </Column>
                                    </Column>
                                    <Column dataField={`batlagdsan_edit`} format="#,##0.00" alignment="right" dataType="number" editorOptions={textAreaOptions}
                                        caption={`Таамаглал`} allowFiltering={false} allowResizing={true} allowEditing={false} cellRender={(e) => cellRender(e)}>
                                    </Column>

                                    <Column dataField="percent" caption="Хувь" width={'5rem'} allowFiltering={false} allowSorting={false}
                                        alignment="center"
                                        allowEditing={type !== 2 ? true : false}
                                        setCellValue={setPercentValue}
                                        customizeText={(data) => {
                                            if (data.value !== undefined) {
                                                return `${data.value}%`
                                            }
                                        }}
                                    />
                                    <Column dataField="amount" caption="Дүн" width={'12rem'} allowFiltering={false} allowSorting={false}
                                        alignment="center"
                                        allowEditing={type !== 2 ? true : false}
                                        setCellValue={setAmountValue}
                                        format="#,##0.00"
                                    />
                                    <Column type="buttons" width={"3rem"} visible={true} showInColumnChooser={false}>
                                        <GridButton text="Энэ мөрийг 0 болгох" icon={'return'}  onClick={onZero} cssClass="grid-button" />
                                    </Column>
                                    <Editing
                                        mode="cell"
                                        allowUpdating={true}
                                        allowAdding={false}
                                        allowDeleting={false}
                                        confirmDelete={true}
                                        selectTextOnEditStart={true}
                                        texts={{
                                            addRow: "нэмэх",
                                            cancelAllChanges: "Болих бүгд",
                                            cancelRowChanges: "Болих",
                                            confirmDeleteMessage: "Энэ мэдээллийг устгах уу?",
                                            confirmDeleteTitle: "Баталгаажуулах",
                                            deleteRow: "Устгах",
                                            editRow: "Өөрчлөх",
                                            saveAllChanges: "Хадгалах бүгд",
                                            saveRowChanges: "Хадгалах",
                                            undeleteRow: "Буцаах",
                                            validationCancelChanges: "Баталгаажуулах болих",
                                        }}
                                    />

                                    <Export enabled={true} allowExportSelectedData={false} />
                                    <Summary recalculateWhileEditing={true} >
                                        <GroupItem column="prev_budget"
                                            summaryType="sum"
                                            showInGroupFooter={false} alignByColumn={true}
                                            customizeText={(data) => {
                                                return `${numberWithCommas(data.value)}`
                                            }} />
                                        <GroupItem column="p1_guitsetgel"
                                            summaryType="sum"
                                            showInGroupFooter={false} alignByColumn={true}
                                            customizeText={(data) => {
                                                return `${numberWithCommas(data.value)}`
                                            }} />
                                        <GroupItem column="batlagdsan"
                                            summaryType="sum"
                                            showInGroupFooter={false} alignByColumn={true}
                                            customizeText={(data) => {
                                                return `${numberWithCommas(data.value)}`
                                            }} />
                                        <GroupItem column="guitsetgel"
                                            summaryType="sum"
                                            showInGroupFooter={false} alignByColumn={true}
                                            customizeText={(data) => {
                                                return `${numberWithCommas(data.value)}`
                                            }} />
                                        <GroupItem column="batlagdsan_edit"
                                            summaryType="sum"
                                            showInGroupFooter={false} alignByColumn={true}
                                            customizeText={(data) => {
                                                return `${numberWithCommas(data.value)}`
                                            }} />
                                        <TotalItem column="prev_budget"
                                            summaryType="sum"
                                            showInGroupFooter={false} alignByColumn={true}
                                            customizeText={(data) => {
                                                return `${numberWithCommas(data.value)}`
                                            }} />
                                        <TotalItem column="p1_guitsetgel"
                                            summaryType="sum"
                                            showInGroupFooter={false} alignByColumn={true}
                                            customizeText={(data) => {
                                                return `${numberWithCommas(data.value)}`
                                            }} />
                                        <TotalItem column="batlagdsan"
                                            summaryType="sum"
                                            showInGroupFooter={false} alignByColumn={true}
                                            customizeText={(data) => {
                                                setAgendaDiff(data.value)
                                                return `${numberWithCommas(data.value)}`
                                            }} />
                                        <TotalItem column="guitsetgel"
                                            summaryType="sum"
                                            showInGroupFooter={false} alignByColumn={true}
                                            customizeText={(data) => {
                                                return `${numberWithCommas(data.value)}`
                                            }} />
                                        <TotalItem column="batlagdsan_edit"
                                            summaryType="sum"
                                            showInGroupFooter={false} alignByColumn={true}
                                            customizeText={(data) => {
                                                return `${numberWithCommas(data.value)}`
                                            }} />

                                    </Summary>
                                </DataGrid>
                            }
                            <div style={{ margin: 5, display: 'flex', gap: 10 }}>
                                <SelectBox
                                    items={items}
                                    value={type}
                                    width={'10rem'}
                                    label="Төрөл"
                                    labelMode="static"
                                    valueExpr="key"
                                    displayExpr='label'
                                    onValueChange={(e)=>{
                                        if (e === 1){
                                            newArr.splice(0, newArr.length)
                                        }
                                    }}
                                    onValueChanged={(e) => {
                                        setType(e.value)
                                        // dataGridEditedRef.current.instance.clearFilter()
                                    }}
                                    style={{ borderRadius: 10 }} />
                            </div>


                            {type === 1 && <Agenda
                                year={currentYear}
                                tabIndex={tabIndex}
                                agendaBudget={agendaBudget}
                                agendaBudgetForecast={agendaBudgetForecast}
                                setAgendaBudget={setAgendaBudget}
                                setAgendaBudgetDiff={setAgendaBudgetDiff}
                                setAgendaBudgetForecast={setAgendaBudgetForecast}
                                setAgendaBudgetPercent={setAgendaBudgetPercent}
                                agendaBudgetDiff={agendaBudgetDiff}
                                newArr={newArr}
                                setVisible={setVisible}
                                setNewArr={setNewArr}
                                removeRow={removeRow}
                                setEventBudget={setEventBudget}
                                setEventBudgetDiff={setEventBudgetDiff}
                                setEventBudgetForecast={setEventBudgetForecast}
                                setEventBudgetPercent={setEventBudgetPercent}
                            />}
                            {type === 2 && <Event
                                year={currentYear}
                                tabIndex={tabIndex}
                                eventBudget={eventBudget}
                                setEventBudget={setEventBudget}
                                setEventBudgetDiff={setEventBudgetDiff}
                                setEventBudgetForecast={setEventBudgetForecast}
                                setEventBudgetPercent={setEventBudgetPercent}
                                eventBudgetDiff={eventBudgetDiff}
                                newArr={newArr}
                                setVisible={setVisible}
                                setNewArr={setNewArr}
                                removeRow={removeRow}
                                setRemoveRow={setRemoveRow}

                            />}
                        </div>
                    </>
                    :
                    <div style={{ display: 'flex', gap: '1rem', marginBottom: 10 }} >
                        <div className="col-6" style={{ border: '1px solid #DEDEDE', borderRadius: 15 }}>
                            <div style={{ borderBottom: '1px solid #DEDEDE', padding: 10 }}>
                                <span style={{ fontWeight: 'bold' }}>Батлагдсан төсвөөс</span>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: 10, gap: 5 }}>
                                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 20 }}>
                                    <span style={{ width: '45%', textAlign: 'end' }}>Төсөв:</span>
                                    <span style={{ width: '45%' }}>
                                        {numberWithCommas(budget)}
                                    </span>
                                </div>
                                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 20 }}>
                                    <span style={{ width: '45%', textAlign: 'end' }}>Таамаглал:</span>
                                    <span style={{ width: '45%' }}>
                                        {budget === forecastBudget ?
                                            <span>-</span>
                                            :
                                            <span>{numberWithCommas(forecastBudget)}</span>
                                        }
                                    </span>
                                </div>
                                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 20 }}>
                                    <span style={{ width: '45%', textAlign: 'end' }}>Зөрүү дүн:</span>
                                    <span style={{ width: '45%', fontWeight: diff !== 0 ? 'bold' : '' }}>
                                        {diff < 0 ? (
                                            <span>({numberWithCommas(Math.abs(diff))})</span>
                                        ) : diff > 0 ? (
                                            <span>{numberWithCommas(diff)}</span>
                                        ) :
                                            <span>-</span>
                                        }
                                    </span>
                                </div>
                                <div style={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', gap: 20 }}>
                                    <span style={{ width: '45%', textAlign: 'end' }}>Хувь:</span>
                                    {diffPerc < 0 ?
                                        <div style={{ width: '45%', display: 'flex', justifyContent: 'flex-start', gap: 5, fontWeight: 'bold', alignItems: 'center', color: diffPerc < 0 ? '#3daa75' : '#f66160' }}>
                                            <span>{numberWithCommas(Math.abs(diffPerc))}%</span>
                                            <FaArrowDown size={12} />
                                        </div>
                                        : diffPerc > 0 ?
                                            <div style={{ width: '45%', display: 'flex', justifyContent: 'flex-start', gap: 5, fontWeight: 'bold', alignItems: 'center', color: diffPerc < 0 ? '#3daa75' : '#f66160' }}>
                                                <span>{numberWithCommas(diffPerc)}%</span>
                                                <FaArrowUp size={12} />
                                            </div>
                                            : <span style={{ width: '45%' }}>-</span>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-6" style={{ border: '1px solid #DEDEDE', borderRadius: 15 }}>
                            <div style={{ borderBottom: '1px solid #DEDEDE', padding: 10 }}>
                                <span style={{ fontWeight: 'bold' }}>Өмнөх оноос</span>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: 10, gap: 5 }}>
                                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 20 }}>
                                    <span style={{ width: '45%', textAlign: 'end' }}>Төсөв:</span>
                                    <span style={{ width: '45%' }}>
                                        {numberWithCommas(prevBudget)}
                                    </span>
                                </div>
                                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 20 }}>
                                    <span style={{ width: '45%', textAlign: 'end' }}>Таамаглал:</span>
                                    <span style={{ width: '45%' }}>
                                        {budget === forecastBudget ?
                                            <span>-</span>
                                            :
                                            <span>{numberWithCommas(forecastBudget)}</span>
                                        }
                                    </span>
                                </div>
                                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 20 }}>
                                    <span style={{ width: '45%', textAlign: 'end' }}>Зөрүү дүн:</span>
                                    <span style={{ width: '45%', fontWeight: prevDiff !== 0 ? 'bold' : '' }}>
                                        {prevDiff < 0 ?
                                            <span>({numberWithCommas(Math.abs(prevDiff))})</span>
                                            : prevDiff > 0 ?
                                                <span>{numberWithCommas(prevDiff)}</span>
                                                : <span>-</span>
                                        }
                                    </span>
                                </div>
                                <div style={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', gap: 20 }}>
                                    <span style={{ width: '45%', textAlign: 'end' }}>Хувь:</span>
                                    {prevDiffPerc < 0 ?
                                        <div style={{ width: '45%', display: 'flex', justifyContent: 'flex-start', gap: 5, alignItems: 'center', fontWeight: 'bold', color: prevDiffPerc < 0 ? '#3daa75' : '#f66160' }}>
                                            <span>{numberWithCommas(Math.abs(prevDiffPerc))}%</span>
                                            <FaArrowDown size={12} />
                                        </div>
                                        : prevDiffPerc > 0 ?
                                            <div style={{ width: '45%', display: 'flex', justifyContent: 'flex-start', gap: 5, alignItems: 'center', fontWeight: 'bold', color: prevDiffPerc < 0 ? '#3daa75' : '#f66160' }}>
                                                <span>{numberWithCommas(prevDiffPerc)}%</span>
                                                <FaArrowUp size={12} />
                                            </div>
                                            : <span style={{ width: '45%' }}>-</span>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {tabIndex === 1 && <DataGridEza 
                                        year={currentYear} 
                                        tabIndex={tabIndex} 
                                        setDiff={setDiff} 
                                        diff={diff}
                                        setDiffPerc={setDiffPerc} 
                                        setPrevDiff={setPrevDiff} 
                                        setPrevDiffPerc={setPrevDiffPerc} 
                                        setPrevBudget={setPrevBudget}
                                        setBudget={setBudget}
                                        setForecastBudget={setForecastBudget}
                                    />
                  }

            </div>
            }
        </div>
    )
}

export default BudgetTezEza

import React, {useState} from 'react';

//styles
import '../css/topNavStyles.css';
import searchImg from '../../../assets/imgs/chat-search-input.svg';

// components
import {Button} from "devextreme-react/button";

export default function TopNavComponent ({docTypes, tabOnChange, changeDocType, isLoading, currentDoctypeCode, setFileOrderBy, fileOrderBy, reserveLawsForFilter,
                                             setFilteredLawsForDisplay, setVisible, dispatch}) {
    // assigning variable to setTimeout
    let searchPing;

    // Search text
    const [searchText, setSearchText] = useState();

    const [selected, setSelected] = useState('1');

    const changeSelectedTab = async (code) => {
        isLoading(true);
        setSelected(code);
        await changeDocType(code);
        await tabOnChange(code, fileOrderBy);
        isLoading(false);
    }

    const orderChange = async (e) => {
        let type = e.target.value;
        setFileOrderBy(type);
        isLoading(true);
        await tabOnChange(currentDoctypeCode, type);
        isLoading(false);
    }

    const filterByText = async (e) => {
        if (e.target.value.length === 0) {
            setFilteredLawsForDisplay(reserveLawsForFilter);
            return;
        }
        await clearTimeout(searchPing);

        let text = e.target.value;
        setSearchText(text);
        // console.log('text ->', text);

        searchPing = setTimeout(async () => {
            const result = await filterFunction(text);
            // console.log('filterr',result);
            setFilteredLawsForDisplay(result);
        },1000);
    }

    const filterFunction = async (text) => {
        return await reserveLawsForFilter.filter(item => {
            return item.law_no.includes(text);
        });
    };

    const onClickHandler = () => {
        setVisible(true);
    };

    return (
        <div className = 'law-top-nav-grid'>
            <div style = {{display: 'flex', flexDirection: 'row'}}>
                <input type = 'text' className = 'law-search-input' onChange={filterByText} placeholder='Дугаараар хайх...'/>
                <img src={searchImg} className = 'law-search-icon'/>
            </div>

            <div className = 'law-types-container'>
                {docTypes.length > 0 && docTypes.map((item, index) => {
                    return (
                        <div key= {index} className={`law-type-tab ${selected === item.code && 'law-type-tab-selected'}`}
                             onClick = {() => changeSelectedTab(item.code)}
                        >
                            {item.name}
                        </div>
                    )
                })}
            </div>

            <div className = 'law-filter-by-date'>
                <Button icon='add' style={{borderRadius:'20px', marginRight: '1rem'}} onClick={onClickHandler}/>

                <select className = 'law-select-filter' onChange={orderChange}>
                    <option value='desc'>Сүүлийнх</option>
                    <option value='asc'>Эхэнд нэмэгдсэн </option>
                </select>
            </div>
        </div>
    )
}
import React, {useContext, useEffect, useState, useRef} from "react";

import 'devextreme-react/text-area';
import DataGrid, {
    Editing,
    Column,
    RequiredRule,
    PatternRule,
    Lookup, Summary, TotalItem, SearchPanel, Export, GroupPanel, Grouping, GroupItem, Button as DevButton
} from 'devextreme-react/data-grid';


import Button from "devextreme-react/button";
import SelectBox from "devextreme-react/select-box";
import DateBox from "devextreme-react/date-box";
import fcsServices from "../../../services/api/fcs";
import notify from "devextreme/ui/notify";
import AccountComponent from "../AccountPayment/AcctComponent";
import {UserContext} from "../../../hooks/UserContext";
import Arline from "./Arline";
import fcsService from "../../../services/api/fcs";

import {LoadPanel} from "devextreme-react/load-panel";
import {Popup} from "devextreme-react/popup";
import ScrollView from "devextreme-react/scroll-view";
import PivotGridDataSource from "devextreme/ui/pivot_grid/data_source";
import PivotGrid from "devextreme-react/pivot-grid";
import {Workbook} from "exceljs";
import {exportDataGrid} from "devextreme/excel_exporter";
import {saveAs} from "file-saver-es";
import CustorgParent from "../Gl/gledit/custorgParent";
import LoadPanelComponent from "../../../components/LoadPanelComponent";
// import {useRef} from "@types/react";


const ArEntery = (props) => {
    const {user} = useContext(UserContext);
    const userListPosition = {of: '#usersList'}
    const jr_key = props.jr_key;
    const [organizationNo, setOrganizationNo] = useState(null);
    const [docno, setDocno] = useState(0);

    const [currentDate, setCurrentDate] = useState();
    const [listData, setListData] = useState([]);
    const [dataFcsMonth, setFcsMonth] = useState([]);

    const initCustValue = {name: '', orgcode: ''}
    const [custValue, setCustValue] = useState({...initCustValue});

    const [selectedChart, setSelectedChart] = useState([]);
    const [apList, setApList] = useState([]);
    const [apValue, setApValue] = useState(null);
    const [modalVisibleCustOrg, setModalVisibleCustOrg] = useState(false)
    const [accountSecondList, setAccountSecondList] = useState([]);
    const [loader, setLoader] = useState(false);


    const [modalVisibleBudget, setModalVisibleBudget] = useState(false);
    const [eventBudgetYear, setEventBudgetYear] = useState([]);
    const [agendaBudgetYear, setAgendaBudgetYear] = useState([]);
    const [economicBudgetYear, setEconomicBudgetYear] = useState([]);
    const [eventValue, setEventValue] = useState(null);
    const [agendaValue, setAgendaValue] = useState(null);
    const [economicValue, setEconomicValue] = useState(null);
    const [data, setData] = useState([]);


    let _pivotGrid = useRef(null);


    const [drillDownDataSource, setDrillDownDataSource] = useState(null);
    const [popupVisible, setPopupVisible] = useState(false);
    const [popupTitle, setPopupTitle] = useState('');


    useEffect(() => {
        setCurrentDate(new Date());
        getData();
    }, []);

    {useEffect(() => {
        sumCal()
    }, [listData])}
    const getData = async () => {
        setLoader(true);

        getDocno()


        const fcsArClass = await fcsService.getFcsChart("AR",0,0,0,0);
        setApList(fcsArClass);


        const result = await fcsServices.getAllFcsChart();
        setAccountSecondList(result);

        const fcsMonth = await fcsService.getAllFcsMonth('AR');

        if ( fcsMonth && fcsMonth.length < 1) notify("Авлагын тохиргоо хийнэ үү  ", "warning")
        // if ( fcsMonth && fcsMonth.length < 1) setLoader(false)
        if ( fcsMonth && fcsMonth.length < 1) return setLoader(false)

        setFcsMonth(fcsMonth);
        getBudgetForm(fcsMonth);
        return setLoader(false)
    }


    const getDocno = async () => {
        const fcsMonth = await fcsService.getAllFcsMonth('AR');

        if ( fcsMonth && fcsMonth.length < 1) notify("Авлагын тохиргоо хийнэ үү  ", "warning")
        // if ( fcsMonth && fcsMonth.length < 1) setLoader(false)
        if ( fcsMonth && fcsMonth.length < 1) return setLoader(false)

        setFcsMonth(fcsMonth);
        fcsMonth.map((item) => {
            setDocno(item.orid)

        })
    }
    const customizeDate = (data) => {
        return Number(parseFloat(data.value).toFixed(2)).toLocaleString("en", {
            minimumFractionDigits: 2,
        });
    }

    const getBudgetForm = async (fcsMonth) => {
        setLoader(true);
        const agenda = await fcsService.getAgendaBudgetYear(fcsMonth[0].year);

        if (agenda.data.length > 0) {
            setAgendaBudgetYear(agenda);
            // setModalVisibleBudget(true);
            return setLoader(false);
        } else {
            return setLoader(false);
        }
        setLoader(false);
    };


    const onChangeChart = async (e, index) => {

        let newArr = [...listData];


        if (e != undefined) (

            newArr[index].acctcr = e

        )


        setListData(newArr)



    }

    const onChangeCustValue = (e) => {
        setCustValue(e);
    }

    const onChangeApValue = (e) => {
        setApValue(e);
        //  setApDocNo(e.c11);
    }

    const handleChangeValue = (event) => {
        setOrganizationNo(event.target.value);
    }

    const onValueChangedDate = (e) => {
        setCurrentDate(e.value);
    }



    const clickMinus = (ind) => {
// alert(ind)
        setListData(listData.filter((item, index) => index !== ind));
    }
    const [sum, setSum] = useState({amount: 0, vat: 0})
    const updateFieldChanged = (index, e) => {
        let newArr = [...listData]; // copying the old datas array
        let val = e.target.value;

        if (e.target.name === "amount" && val <= 0) val = '';
        if (e.target.name === "vat" && val <= 0) val = '';


        newArr[index][e.target.name] = val;

        setListData(newArr);
    };

    function sumCal() {
        let sumAmount = 0;
        let sumVat = 0;
        listData.map((el) => {
            sumAmount = Number(sumAmount) + Number(el.amount);
            sumVat = Number(sumVat) + Number(el.vat);
        });

        setSum({
            amount: sumAmount, vat: Number(sumVat),
        });
    }

    const clickPlus = () => {

        if (custValue.name == undefined || custValue == null || custValue.name.length < 2) return notify("Харилцагч хоосон байна.", "warning");
        if (apValue === null) return notify("Авлагын анилал хоосон байна.", "warning");

        let list = [...listData];
        const data1 = {
            ar: "",
            arcode: "",
            docno: 1 + docno,
            descr: "",
            qty: 0,
            iss_cost: 1,
            amount: '',
            vat: '',
            docdate: currentDate,
            cust: custValue.orgcode,
            citytax: 0,
            acctdb: apValue.acct,
            custname: custValue.name,
            acctdb_name: apValue.descr,
            acctcr: "",
            acctcr_name: '',
            org_id: user.org_id,
            created_user_id: user.id,
        }
        list.push(data1);
        setListData(list);
    }

    const saveData = async () => {

        const date = new Date(currentDate);
        const year = date.getFullYear() * 1e4; // 1e4 gives us the the other digits to be filled later, so 20210000.
        const month = (date.getMonth() + 1) * 100;
        const yyyymm = year + month // `+ ''` to convert to string from number, 202206

        const fcsCurrentMonth = dataFcsMonth.map((item) => {
            let date = item.year + '/' + item.month
            let datetimenew = new Date(date)
            let year = datetimenew.getFullYear() * 1e4; // 1e4 gives us the the other digits to be filled later, so 20210000.
            let month = (datetimenew.getMonth() + 1) * 100;
            const yyyymm_month = year + month
            return yyyymm_month
        });


        if (yyyymm != fcsCurrentMonth[0]) {

            notify('Сонгосон огноо тухайн тайлант он сард биш байна', "error", 3000);

            return

        }


        // if(organizationNo == null) return notify("Баримтын дугаар хоосон байна.", "warning");

        if (custValue === undefined || custValue === null || custValue.orgcode.length < 2) return notify("Харилцагч хоосон байна.", "warning");
        if (apValue === null || apValue === undefined) return notify("Авлагын анилал хоосон байна.", "warning");

        if (listData.length === 0) return notify("Мэдээлэл байхгүй байна", "warning");


        try {

            let listData1 = listData


            let errorCount = 0;
            listData1.some((el) => {
                if (el.descr.trim().length< 1) {


                    errorCount = errorCount + 1;
                    return notify("Гүйлгээний утга оруулах", "warning");

                    return true;
                }

                if ( Number(el.amount )<= 0) {
                    errorCount = errorCount + 1;
                    return notify("дун = 0 ", "warning");

                    return true;
                }

                if (el.acctcr.length < 4) {
                    errorCount = errorCount + 1;
                    return notify("кредит данс сонгох !!!!!! ", "warning");

                    return true;
                }


                return false;
            });

            if (errorCount > 0) {
                return;
            }



            const result = await fcsService.getAllFcsMonth('AR');
            result.map(e => {
                e.orid = e.orid + 1
            })


            listData1.map((item) => {


                if (Number(item.amount) <= 0) item.amount = 0

                if (Number(item.vat) <= 0) item.vat = 0


                item.docdate = currentDate;
                item.docno = parseInt(result[0].orid);


                item.brn = organizationNo;
                item.acctdb = apValue.acct;
                item.acctdb_name = apValue.descr;
                item.cust = custValue.orgcode;
                item.custname = custValue.name;

                agendaValue ? item.agenda_id = agendaValue.agenda_id : item.agenda_id = 0
                eventValue ? item.event_id = eventValue.event_id : item.event_id = 0
                economicValue ? item.econ_category_id = economicValue.econ_category_id : item.econ_category_id = 0


            })


            await fcsService.addFcsArtran(listData1);
            await fcsService.editFcsMonth(result[0]);
            notify("Амжилттай хадгаллаа", "success");
            const list = [];
            setListData(list)
            setAgendaValue([])
            setEventValue([])
            setEconomicValue([])
            getDocno()
            setOrganizationNo('')
            setLoader(false)
        } catch (err) {
            console.log(err)
            setLoader(false)
            notify("Алдаа гарлаа", "error")
        }


    }


    const onChangeEventValue = (e) => {
        if (agendaValue.agenda_id && e.event_id) {

            if (e.event_id) {
                setEventValue(e);
                getEconomicDataByAgendaEvent(agendaValue.agenda_id, e.event_id);

            }
        }
    }
    const getEventDataByAgenda = async (agenda_id) => {
        if (agenda_id) {
            const result = await fcsService.getEventBudgetYear(dataFcsMonth[0].year, agenda_id);
            setEventBudgetYear(result.data);

        }

    }
    const getEconomicDataByAgendaEvent = async (agenda_id, event_id) => {
        if (agenda_id && event_id) {
            const result = await fcsService.getEconomicBudgetYear(dataFcsMonth[0].year, agenda_id, event_id, 3);
            setEconomicBudgetYear(result.data);
        }
    }
    const onChangeAgendaValue = (e) => {

        if (e.agenda_id) {
            setAgendaValue(e);
            getEventDataByAgenda(e.agenda_id);
        }

    }
    const onChangeEconomicValue = (e) => {

        if (agendaValue.agenda_id && eventValue.event_id && e.econ_category_id) {
            setEconomicValue(e);
            getBudgetYear(dataFcsMonth[0].year, agendaValue.agenda_id, eventValue.event_id, e.econ_category_id,);
        }
    }
    const getBudgetYear = async (budget_year, agenda_id, event_id, econ_category_id) => {

        const result = await fcsService.getBudgetYear(budget_year, agenda_id, event_id, econ_category_id);
        setData(result.data);
        return setLoader(false);
    }
    const sortException = "Гүйцэтгэл";
    const dataSource = new PivotGridDataSource({
        fields: [
            /* {
                 caption: 'Төсөл хөтөлбөр',
                 dataField: 'agenda_name',
                 width: 200,
                 area: 'row',
                 sortBySummaryField: 'Total',
                 expanded: true
             },
             {
                 caption: 'Зориулалт арга хэмжээ',
                 dataField: 'event_name',
                 width: 200,
                 area: 'row',
                 sortBySummaryField: 'Total',
                 expanded: true
             },*/
            {
                caption: 'Эдийн засгийн ангилал 1',
                dataField: 'econ_category_name_1',
                width: 200,
                area: 'row',
                sortBySummaryField: 'Total',
            },
            {
                caption: 'econ_category_name_2',
                dataField: 'econ_category_name_2',
                width: 150,
                area: 'row'
            },
            {
                caption: 'econ_category_name_3',
                dataField: 'econ_category_name_3',
                width: 150,
                area: 'row'
            },
            {
                caption: 'Эдийн засгийн ангилал 4',
                dataField: 'econ_category_name_4',
                // width: 200,
                area: 'row',
                sortBySummaryField: 'amt',
                sortOrder: 'desc'
            },
            {
                caption: 'Эдийн засгийн ангилал 5',
                dataField: 'econ_category_name',
                // width: 200,
                area: 'row',
                sortBySummaryField: 'amt',
                sortOrder: 'desc'
            },
            {
                dataField: 'budget_month',
                dataType: 'budget_month',
                area: 'column',
                sortOrder: 'asc',
                expanded: true
            }, {
                dataField: 'budget_status_name',
                dataType: 'budget_status_name',
                area: 'column',
                expanded: true,
                sortingMethod: function (a, b) {
                    var index1 = a.value;
                    var index2 = b.value;
                    if (index1 === sortException) {
                        return 1;
                    }
                    if (index2 === sortException) {
                        return -1;
                    }
                    if (index1 > index2)
                        return 1;
                    if (index2 > index1)
                        return -1;
                    else
                        return 0;
                }
            },
            {
                caption: 'Нийт',
                dataField: 'amt',
                dataType: 'number',
                summaryType: 'sum',
                format: {
                    type: 'fixedPoint',
                    precision: 2
                },
                area: 'data'
            }],
        store: data
    });
    const onCellClick = (e) => {
        // console.log('e', e);
        if (e.area === 'data' && e.cell.columnPath[1] === 'Санхүү Гүйцэтгэл') {
            const pivotGridDataSource = e.component.getDataSource();
            const rowPathLength = e.cell.rowPath.length;
            const rowPathName = e.cell.rowPath[rowPathLength - 1];
            setPopupTitle(`${rowPathName || 'Total'}`);
            setDrillDownDataSource(pivotGridDataSource.createDrillDownDataSource(e.cell));
            setPopupVisible(true);
        }
    }
    const onHiding = () => {
        setPopupVisible(false);
    }

    const onShown = () => {
        this.props.data.component.updateDimensions();
        //this.dataGrid.updateDimensions();
    }
    const onExporting = (e) => {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Main sheet');

        exportDataGrid({
            component: e.component,
            worksheet,
            autoFilterEnabled: true,
        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                //`${item.acct} - ${item.descr}`
                saveAs(new Blob([buffer], {type: 'application/octet-stream'}), `${popupTitle}.xlsx`);
            });
        });
        e.cancel = true;
    }
    const cloneIconClick = async (e) => {
        const list = [...listData];
        const clonedItem = {...e.row.data};
        list.splice(e.row.rowIndex, 0, clonedItem);
        setListData(list);
        e.event.preventDefault();
    }

    const style={
        text: {
            color: "#2E4765", fontWeight: "700", fontSize: ".875rem"
        },
        box: {
            border: "none", backgroundColor: "#F6F6F6", borderRadius: ".5rem", padding: ".25rem .15rem", width: "100%"
        }
    }
    return (<React.Fragment>


        <LoadPanelComponent
            shadingColor="rgba(0,0,0,0.4)"
            position={userListPosition}
            visible={loader}
            message=''
            showPane={false}
        />

        <div className={'card-body'} id='firstBox'>
            <div className="row" style={{display: 'flex', marginLeft: 20, marginTop: 20}}>
                <div className="col-4" style={{display: 'flex'}}>
                    <div style={{display: 'flex', alignItems: 'left'}}><span style={{color : "#A3A3A3", marginRight: "1rem" }}>Дугаар:</span><span style={{fontWeight: "700"}}>{1 + docno}</span></div>
                </div>
            </div>
            <div className="row" style={{ display: 'flex',marginTop: 40 }}>
                <div className="col-3" style={{display: 'flex', flexDirection: "column", marginLeft: 10}}>
                    <div
                        style={{marginLeft: 10, display: 'flex', alignItems: 'center', marginRight: 10,...style.text}}>Харилцагч:
                    </div>
                        <input type='string' className='law-search-input'
                               style={{marginLeft: 10,marginTop: 10,...style.box}}
                               onClick={(e) => setModalVisibleCustOrg(true)}
                               value={custValue.name}
                        />
                </div>
                <div className="col-3" style={{display: 'flex', flexDirection: "column", marginLeft: 10}}>
                    <div style={{marginLeft: 30, display: 'flex', alignItems: 'center', marginRight: 10,...style.text}}>Огноо:
                    </div>
                    <DateBox value={currentDate} style={{marginLeft: 30,marginTop: 10,...style.box}} type="date" onValueChanged={onValueChangedDate}/>
                </div>
            </div>
            <div className="row" style={{display: 'flex', marginLeft: 20, marginTop: 20}}>
                <div className="col-3" style={{display: 'flex', flexDirection: "column"}}>
                    <div style={{marginLeft: 10, display: 'flex', alignItems: 'center', marginRight: 10,...style.text}}>
                        {jr_key === "AP" ? "Өглөгийн ангилал" : "Авлагын ангилал"}</div>
                    <SelectBox
                        items={apList}
                        value={apValue}
                        searchEnabled={true}
                        style={{marginTop: 10,...style.box}}
                        displayExpr={(item) => {
                            return item && `${item.acct} - ${item.descr}`
                        }}
                        // displayExpr="aracct"
                        onValueChanged={(e) => onChangeApValue(e.value)}
                        placeholder="сонгох"/>

                </div>
                    <div className="col-3" style={{display: 'flex', flexDirection: "column"}}>
                        <div style={{display: 'flex', alignItems: 'center', marginLeft: 30,...style.text}}>Гэрээний дугаар:</div>
                        <input type='string' className='law-search-input' 
                               value={organizationNo} style={{marginLeft: 30,marginTop: 10,...style.box}} onChange={handleChangeValue}/>
                </div>
                </div>

            <div className="row" style={{marginLeft: 20, marginTop: 50, marginRight: 20}}>

                <div className="col-12" style={{display: 'flex', marginLeft: 20, marginTop: 20}}>

                    {/*<div className="col-4" style={{ display: 'flex' }}>*/}

                    {/*  <div style={{ marginLeft: 10 }}>*/}
                    {/*    <div style={{ display: 'flex', alignItems: 'center'}}>Гүйлгээний утга:</div>*/}

                    {/*  </div>*/}
                    {/*</div>*/}


                </div>
                <div style={{marginLeft: 10}}>
                    <Button icon="chart" text="Төсвийн ангилал сонгох" onClick={() => setModalVisibleBudget(true)}
                            style={{alignment: "center", borderRadius: '7px'}}/>
                </div>
                {
                    agendaValue && eventValue && economicValue &&
                    <div style={{
                        marginLeft: 10,
                        marginTop: 10,
                        display: 'flex',
                        flexDirection: 'row',
                        marginBottom: 10
                    }}>
                        <div style={{marginLeft: 10, marginRight: 10, width: 400}}>
                            <div style={{display: 'flex', alignItems: 'center', marginBottom: 5}}>Сонгогдсон төсөл
                                хөтөлбөр
                            </div>
                            <SelectBox
                                items={agendaBudgetYear}
                                value={agendaValue}
                                style={{border: '2px solid #DDDDDD'}}
                                disabled={true}
                                displayExpr={(item) => {
                                    return item && `${item.agenda_code} - ${item.agenda_name}`
                                }}
                                placeholder="Төсөл хөтөлбөр сонгох"/>
                        </div>
                        <div style={{marginLeft: 10, marginRight: 10, width: 400}}>
                            <div style={{display: 'flex', alignItems: 'center', marginBottom: 5}}>Сонгогдсон
                                Зориулалт
                            </div>
                            <SelectBox
                                items={eventBudgetYear}
                                value={eventValue}
                                style={{border: '2px solid #DDDDDD'}}
                                displayExpr={(item) => {
                                    return item && `${item.event_code} - ${item.event_name}`
                                }}
                                disabled={true}
                                placeholder="Зориулалт сонгох"/>
                        </div>
                        <div style={{marginLeft: 10, marginRight: 10, width: 400}}>
                            <div style={{display: 'flex', alignItems: 'center', marginBottom: 5}}>Сонгогдсон төсвийн
                                ангилал
                            </div>
                            <SelectBox
                                items={economicBudgetYear}
                                value={economicValue}
                                style={{border: '2px solid #DDDDDD'}}
                                displayExpr={(item) => {
                                    return item && `${item.econ_category_code} - ${item.econ_category_name}`
                                }}
                                disabled={true}
                                placeholder="Төсвийн ангилал сонгох"/>
                        </div>
                    </div>

                }


                {listData.map((el, index) => (<Arline key={index}
                    el={el}
                    clickMinus={clickMinus}
                    onChangeChart={onChangeChart}
                    updateFieldChanged={updateFieldChanged}
                    accountSecondList={accountSecondList}
                    selectedChart={selectedChart}
                    // showIndex={showIndex}
                    index={index}
                    //   calVat={calVat}
                />))}
                <div className="col-12" style={{display: 'flex', marginLeft: 20, marginTop: 20}}>

                    <div className="col-4" style={{display: 'flex'}}>

                        <div style={{marginLeft: 10}}>
                            <div style={{display: 'flex', alignItems: 'center'}}></div>

                        </div>
                    </div>


                    <div className="col-3" style={{display: 'flex'}}>

                        <div style={{marginLeft: 10}}>
                            <div style={{display: 'flex', alignItems: 'center'}}>{
                                Number(parseFloat(sum.amount).toFixed(2)).toLocaleString("en", {
                                    minimumFractionDigits: 2,
                                })
                            }</div>

                        </div>
                    </div>
                    <div className="col-4" style={{display: 'flex'}}>

                        <div style={{marginLeft: 10}}>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                {
                                    Number(parseFloat(sum.vat).toFixed(2)).toLocaleString("en", {
                                        minimumFractionDigits: 2,
                                    })
                                }</div>

                        </div>
                    </div>
                </div>
            </div>
            <div style={{display: 'flex', flexDirection: 'row', marginTop: 30, marginBottom: 30, marginLeft: 20}}>
                <Button icon="plus"
                        text=""
                        type="default" style={{alignment: "center", borderRadius: '7px'}}

                        onClick={() => clickPlus()}/>
                <div style={{marginLeft: 30}}>
                    <Button className="saveButton"
                            text="Хадгалах"

                            type="default" style={{alignment: "center", borderRadius: '7px'}}
                            onClick={() => saveData()}/>
                </div>
            </div>
        </div>

        <div>
            <Popup
                width={500}
                height={500}
                minWidth={'80%'}
                maxWidth={'80%'}
                minHeight={'80%'}
                visible={modalVisibleCustOrg}
                title={'Байгууллага хувь хүмүүс сонгох'}
                onHiding={() => setModalVisibleCustOrg(false)}
                resizeEnabled={true}
                dragEnabled={true}
                showTitle={true}

            >
                <CustorgParent setCustValue={setCustValue} setModalVisibleCustOrg={setModalVisibleCustOrg}/>

            </Popup>

        </div>
        <div>
            <Popup
                minWidth={'80%'}
                maxWidth={'100%'}
                minHeight={'100%'}
                visible={modalVisibleBudget}
                title={'Төсвийн ангилал сонгох'}
                onHiding={() => setModalVisibleBudget(false)}
                resizeEnabled={true}
                dragEnabled={true}
                showTitle={true}
                hideOnOutsideClick={true}
            >
                <ScrollView width='100%' height='100%'>
                    <div className={'card-body'}>
                        <div style={{marginTop: 10}}>
                            <div style={{display: 'flex', flexDirection: 'row', marginBottom: 20}}>
                                <div style={{marginLeft: 10, marginRight: 10, width: 400}}>
                                    <div style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        marginBottom: 10
                                    }}>Төсөл,хөтөлбөр
                                    </div>
                                    <SelectBox
                                        items={agendaBudgetYear}
                                        value={agendaValue}
                                        style={{border: '1px solid #DDDDDD'}}
                                        displayExpr={(item) => {
                                            return item && `${item.agenda_code} - ${item.agenda_name}`
                                        }}
                                        onValueChanged={(e) => onChangeAgendaValue(e.value)}
                                        placeholder="Төсөл хөтөлбөр сонгох"/>
                                </div>
                                <div style={{marginLeft: 10, marginRight: 10, width: 300}}>
                                    <div style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        marginBottom: 10
                                    }}>Зориулалт
                                    </div>
                                    <SelectBox
                                        items={eventBudgetYear}
                                        value={eventValue}
                                        style={{border: '1px solid #DDDDDD'}}
                                        displayExpr={(item) => {
                                            return item && `${item.event_code} - ${item.event_name}`
                                        }}
                                        onValueChanged={(e) => onChangeEventValue(e.value)}
                                        placeholder="Зориулалт сонгох"/>
                                </div>
                                <div style={{marginLeft: 10, marginRight: 10, width: 400}}>
                                    <div style={{display: 'flex', alignItems: 'center', marginBottom: 10}}>Төсвийн
                                        ангилал
                                    </div>
                                    <SelectBox
                                        items={economicBudgetYear}
                                        value={economicValue}
                                        style={{border: '1px solid #DDDDDD'}}
                                        displayExpr={(item) => {
                                            return item && `${item.econ_category_code} - ${item.econ_category_name}`
                                        }}
                                        onValueChanged={(e) => onChangeEconomicValue(e.value)}
                                        placeholder="Төсвийн ангилал сонгох"/>
                                </div>
                            </div>
                            <PivotGrid
                                style={{textAlign: "left !important"}}
                                id="pivotgrid"
                                ref={_pivotGrid}
                                dataSource={dataSource}
                                rowHeaderLayout={'tree'}
                                allowSortingBySummary={true}
                                sortingMethod={true}
                                allowFiltering={true}
                                showBorders={true}
                                showColumnGrandTotals={false}
                                showRowGrandTotals={true}
                                showRowTotals={true}
                                showColumnTotals={false}
                                //cellPrepared={onCellPrepared}
                                loadPanel={{enabled: false}}
                                onCellClick={onCellClick}
                                texts={{
                                    grandTotal: 'Нийт дүн',
                                    total: '{0} нийт дүн'
                                }}
                            />
                            <Popup
                                visible={popupVisible}
                                width={800}
                                height={500}
                                title={popupTitle}
                                onHiding={onHiding}
                                onShown={onShown}
                            >
                                <DataGrid
                                    width={750}
                                    height={400}
                                    dataSource={drillDownDataSource}
                                    allowColumnResizing={true}
                                    //ref={getDataGridInstance}
                                    onExporting={onExporting}>
                                    >
                                    <GroupPanel visible={true}/>
                                    <SearchPanel visible={true}/>
                                    <Grouping autoExpandAll={true}/>
                                    <Column dataField="org_name" caption="Байгууллага" groupIndex={0}/>
                                    <Column dataField="docno" caption="Журнал дугаар" width={100}
                                            allowCollapsing={false}
                                            allowFiltering={true}/>
                                    <Column dataField="acct_name" caption="Данс"
                                            allowCollapsing={false}
                                            allowFiltering={true}/>
                                    <Column dataField="apacct_name" caption="Ангилал"
                                            allowCollapsing={false}
                                            allowFiltering={true}/>
                                    <Column dataField="custname" caption="Харилцагч"
                                            allowCollapsing={false}
                                            allowFiltering={true}/>
                                    <Column dataField="descr" caption="Гүйлгээний утга"
                                            allowCollapsing={false}
                                            allowFiltering={true}/>
                                    <Column dataField="amt" caption="Дүн" dataType="number" format="#,##0.00"/>
                                    <Summary>
                                        <GroupItem
                                            column="amt"
                                            summaryType="sum"
                                            customizeText={customizeDate}
                                            showInGroupFooter={true}/>
                                        <TotalItem
                                            column="amt"
                                            summaryType="sum"
                                            customizeText={customizeDate}/>
                                    </Summary>
                                    <Export enabled={true} allowExportSelectedData={false}/>
                                </DataGrid>
                            </Popup>
                        </div>
                    </div>
                </ScrollView>
            </Popup></div>

    </React.Fragment>);
};

export default ArEntery;

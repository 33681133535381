import React, {useEffect, useState} from "react";
import notify from "devextreme/ui/notify";
import OrganizationService from "../services/api/organization";
import DataGrid, {Column, Editing, Popup, Lookup} from "devextreme-react/data-grid";
import TagBox from "devextreme-react/tag-box";
import RefService from "../services/api/refs";

const Hubabuba = (props) => {
    const [dara, setDara] = useState([]);
    const [lookSource, setLookSource] = useState([]);

    const loadData = async () => {
        try {
            let r = await OrganizationService.getHubabuba();
            let ags = await RefService.getAnda();
            setLookSource(ags.data);
            setDara(r);
        } catch (e) {
            notify(e.message, 'error', 3000);
        }
    }

    useEffect(() => {
        loadData();
    }, [])

    const EmployeeTagBoxComponent = (e) => {
        // console.log('eeee -> ', e);
        return (
            <TagBox
                dataSource={e.data.column.lookup.dataSource}
                defaultValue={e.data.value}
                valueExpr="agenda_id"
                displayExpr="dudeDoneItAlready.name"
                showSelectionControls={true}
                applyValueMode="useButtons"
                searchEnabled={true}
            />
        )
    }

    return (
        <div>
            <DataGrid
                dataSource={dara}
            >
                <Editing
                    mode="form"
                    allowUpdating={true}
                    allowAdding={true}
                    allowDeleting={false}>
                    <Popup title="Employee Info" showTitle={true} width={700} height={525} />
                </Editing>
                <Column dataField="name"/>
                <Column
                    dataField="agenda_relations"
                    visible={true}
                    editCellComponent={EmployeeTagBoxComponent}
                >
                    <Lookup
                        dataSource={lookSource}
                        valueExpr="id"
                        displayExpr="name"
                    />
                </Column>
            </DataGrid>
        </div>
    )
}

export default Hubabuba;

import request from '../../shared/lib/request'

const getReportType = () => {
    return request({
        url: `/financeReport/getReportType`,
        method: 'GET',
    })
}
const getFilteredOrganizations = (year,reportType) => {
    return request({
        url: `/financeReport/getFilteredOrganizations/${year}/${reportType}`,
        method: 'GET',
    })
}
const getHeaderMiniData = (year,reportType,selectedOrgID,filterOrg) => {
    return request({
        url: `/financeReport/getHeaderMiniData/${year}/${reportType}/${selectedOrgID}?filterOrg=${filterOrg}`,
        method: 'GET',
    })
}
const getYamsData = (year,reportType,filterOrg) => {
    return request({
        url: `/financeReport/getYamsData/${year}/${reportType}?filterOrg=${filterOrg}`,
        method: 'GET',
    })
}
const getYamsDataByYear = (reportType,selectedOrgID,filterOrg) => {
    return request({
        url: `/financeReport/getYamsDataByYear/${reportType}/${selectedOrgID}?filterOrg=${filterOrg}`,
        method: 'GET',
    })
}
const getChartByStartAncestry = (year,reportType,selectedOrgID,filterOrg) => {
    return request({
        url: `/financeReport/getChartByStartAncestry/${year}/${reportType}/${selectedOrgID}?filterOrg=${filterOrg}`,
        method: 'GET',
    })
}
const getChartByName = (year,reportType,chartId,selectedOrgID,filterOrg) => {
    return request({
        url: `/financeReport/getChartByName/${year}/${reportType}/${chartId}/${selectedOrgID}?filterOrg=${filterOrg}`,
        method: 'GET',
    })
}
const getChartAccountList = (year,reportType,selectedOrgID,filterOrg) => {
    return request({
        url: `/financeReport/getChartAccountList/${year}/${reportType}/${selectedOrgID}?filterOrg=${filterOrg}`,
        method: 'GET',
    })
}
const getChartDetailByYear = (reportType,selectedOrgID,acct,filterOrg) => {
    return request({
        url: `/financeReport/getChartDetailByYear/${reportType}/${selectedOrgID}/${acct}?filterOrg=${filterOrg}`,
        method: 'GET',
    })
}
function setExcelFileInputPayroll(data) {
    return request({
        url:'/financeReport/setExcelFileInputPayroll',
        data: data,
        method: 'POST'
    })
}

const getFilteredOrgPayroll = (year) => {
    return request({
        url: `/financeReport/getFilteredOrgPayroll/${year}`,
        method: 'GET',
    })
}
const getHeaderMiniDataPayroll = (year,selectedOrgID,filterOrg,month) => {
    return request({
        url: `/financeReport/getHeaderMiniDataPayroll/${year}/${selectedOrgID}?filterOrg=${filterOrg}&month=${month}`,
        method: 'GET',
    })
}
const getByOrgPayrollData = (year,filterOrg,month) => {
    return request({
        url: `/financeReport/getByOrgPayrollData/${year}?filterOrg=${filterOrg}&month=${month}`,
        method: 'GET',
    })
}
const getPayrollSendData = (year,selectedOrgID,filterOrg) => {
    return request({
        url: `/financeReport/getPayrollSendData/${year}/${selectedOrgID}?filterOrg=${filterOrg}`,
        method: 'GET',
    })
}
const getPayrollReport = (year,selectedOrgID,filterOrg,month) => {
    return request({
        url: `/financeReport/getPayrollReport/${year}/${selectedOrgID}?filterOrg=${filterOrg}&month=${month}`,
        method: 'GET',
    })
}
const getPayrollReportPivot = (year,selectedOrgID,filterOrg,month) => {
    return request({
        url: `/financeReport/getPayrollReportPivot/${year}/${selectedOrgID}?filterOrg=${filterOrg}&month=${month}`,
        method: 'GET',
    })
}
const getPayrollPieChart = (year,month,selectedOrgID,filterOrg) => {
    return request({
        url: `/financeReport/getPayrollPieChart/${year}/${month}/${selectedOrgID}?filterOrg=${filterOrg}`,
        method: 'GET',
    })
}
const getPayrollByMonthOrg = (year,selectedOrgID) => {
    return request({
        url: `/financeReport/getPayrollByMonthOrg/${year}/${selectedOrgID}`,
        method: 'GET',
    })
}
const getByOrgMonthPayrollData = (year,selectedOrgID) => {
    return request({
        url: `/financeReport/getByOrgMonthPayrollData/${year}/${selectedOrgID}`,
        method: 'GET',
    })
}
const getPayrollSendAndNot = (year,month,selectedOrgID,type,filterOrg) => {
    return request({
        url: `/financeReport/getPayrollSendAndNot/${year}/${month}/${selectedOrgID}/${type}?filterOrg=${filterOrg}`,
        method: 'GET',
    })
}

const financeReportServices = {
    getReportType,
    getFilteredOrganizations,
    getHeaderMiniData,
    getYamsData,
    getYamsDataByYear,
    getChartByStartAncestry,
    getChartByName,
    getChartAccountList,
    getChartDetailByYear,
    setExcelFileInputPayroll,
    getFilteredOrgPayroll,
    getHeaderMiniDataPayroll,
    getByOrgPayrollData,
    getPayrollSendData,
    getPayrollReport,
    getPayrollPieChart,
    getPayrollByMonthOrg,
    getByOrgMonthPayrollData,
    getPayrollSendAndNot,
    getPayrollReportPivot
}

export default financeReportServices;
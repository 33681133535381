import React, {useRef, useState} from "react";
import { DataGrid } from 'devextreme-react';
import Button from 'devextreme-react/button'
import Editing, {
    Column,
    FilterRow,
    Pager,
    Paging,
    Form as DataGridForm,
    Item as DataGridItem,
} from 'devextreme-react/data-grid';
import notificationService from "../../services/api/notification";
import AddNotification from "./components/addNotification";
import EditNotification from "./components/editNotification";
import CsvDownloader from 'react-csv-downloader';

export default function Notifications() {

    const [notifications, setNotifications] = useState([]);
    const allowedPageSizes = [20, 50, 100];
    const [addPopupVisible, setAddPopupVisible] = useState(false);
    const [editPopupVisible, setEditPopupVisible] = useState(false);
    const [editNotificationId, setEditNotificationId] = useState({});

    const getAllNotifications = async () => {
        try {
            const res = await notificationService.getAllNotifications();

            if (res && res.code === 200) {
                setNotifications(res.data);
            }
        } catch (e) {
            console.log(e);
        }
    }

    const showAddNotification = () => {
        setAddPopupVisible(true);
    }

    const showEditNotification = (value) => {
        const showEditPopup = async () => {
            setEditNotificationId(value.key);

            const res = await notificationService.getByIdNotification(value.key);

            if (res && res.code === 200) {
                //console.log(res.data);
            }

            setEditPopupVisible(true);
        }

        return <Button onClick={showEditPopup} text="Засах"></Button>
    }

    const handleOnCellPrepared = (e) => {
        if (e.rowType === "header") {
            e.cellElement.style.color = "black"
            e.cellElement.style.backgroundColor = "#E8E8E8"
            // elem.style.setProperty('color', 'green');
        }
        if (e.rowType === "data") {
            e.cellElement.style.color = "black"
            // elem.style.setProperty('color', 'green');
        }
    }

    const csvStyle = {
        backgroundColor: '#5ab0f2',
        color: 'white',
        border: 'none',
        padding: '10px',
        fontSize: '14px',
        borderRadius: '15px',
        float: 'right',
        marginRight: '10px',
        cursor: 'pointer'
    };

    const csvColumns = [
        { id: "id", displayName: "Id" },
        { id: "title_to_csv", displayName: "Title" },
        { id: "notif_info_to_csv", displayName: "Content" },
        { id: "sent_org_names_to_csv", displayName: "Organizations" },
        { id: "createdAt", displayName: "Created at" },
        { id: "updatedAt", displayName: "Updated at" }
    ]

    useState(() => {
        getAllNotifications();
    });

    return (
        <React.Fragment>
            <div className="card">
                <div  style={{ display: "table", width: "100%", padding: "1rem" }}>
                    <h3 style={{ float: "left" }}>Мэдэгдлүүд</h3>
                    <Button text="Мэдэгдэл үүсгэх" className="saveButton" onClick={showAddNotification} type="default" style={{ float: "right" }} />
                    <CsvDownloader style={csvStyle} text="Мэдэгдэл татах" filename="Notificaitons" datas={notifications} extension=".csv" columns={csvColumns} />
                </div>
                <div className="card-body">
                    <DataGrid
                        keyExpr="id"
                        dataSource={notifications}
                        showBorders={true}
                        showRowLines={true}
                        showColumnLines={true}
                        hoverStateEnabled={true}
                        style={{flexWrap: "wrap"}}
                        noDataText="Мэдэгдэл байхгүй"
                        focusedRowEnabled={true}
                        wordWrapEnabled={true}
                        onCellPrepared={handleOnCellPrepared}
                    >
                        <FilterRow visible={true} />
                        <Editing>
                            <DataGridForm>
                                <DataGridItem
                                    dataField="id"
                                    colSpan={2}
                                    dataType="number"
                                />
                                <Column
                                    dataField="title"
                                    colSpan={2}
                                    editorType="dxTextArea"
                                />
                                <Column
                                    dataField="sent_org_names"
                                    colSpan={2}
                                    editorType="dxTextArea"
                                />
                                <Column
                                    dataField="createdAt"
                                    colSpan={2}
                                    dataType="date"
                                />
                                <Column
                                    dataField="updatedAt"
                                    colSpan={2}
                                    dataType="date"
                                />
                            </DataGridForm>
                        </Editing>
                        <Paging
                            defaultPageSize={20}
                            defaultPageIndex={0}/>
                        <Pager
                            allowedPageSizes={allowedPageSizes}
                            showPageSizeSelector={true}
                            visible={true}
                            showNavigationButtons={true} />
                        <Column
                            caption="Д/Д"
                            dataType="number"
                            alignment="center"
                            width='3rem'
                            allowSorting={false}
                            cellRender={(data) => {
                              if (data.rowType === "data") {
                                return <span>{data.rowIndex + 1}</span>;
                              }
                              return null;
                            }}
                        />
                        <Column
                            dataField="title"
                            caption="Гарчиг"
                            dataType="string"
                            alignment="left"
                        />
                        <Column
                            dataField="sent_org_names"
                            caption="Илгээсэн газар"
                            dataType="string"
                            alignment="left"
                        />
                        <Column
                            dataField="createdAt"
                            caption="Илгээсэн огноо"
                            dataType="date"
                            alignment="center"
                            format="yyyy-MM-dd"
                            width={120}
                        />
                        <Column
                            dataField="updatedAt"
                            caption="Шинэчилсэн огноо"
                            dataType="date"
                            alignment="center"
                            format="yyyy-MM-dd"
                            width={120}
                        />
                        <Column
                            dataField="id"
                            allowSorting={false}
                            type="buttons"
                            caption="Үйлдэл"
                            alignment="center"
                            cellRender={showEditNotification}
                        />
                    </DataGrid>
                </div>
            </div>
            {addPopupVisible && (
                <AddNotification
                    popupVisible={addPopupVisible}
                    setPopupVisible={setAddPopupVisible}
                    setNotifications={setNotifications}
                />
            )}
            {editPopupVisible && (
                <EditNotification
                    popupVisible={editPopupVisible}
                    setPopupVisible={setEditPopupVisible}
                    notificationId={editNotificationId}
                    setNotifications={setNotifications}
                />
            )}
        </React.Fragment>
    );
}

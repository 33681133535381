import request from "../../shared/lib/request";

function changeStatusProject(body) {
    return request({
        url: `/plan/changeStatusProject`,
        method: 'POST',
        data: body
    })
}

const getBudgetProjectLog = (pId) => {
    return request({
        url: `/plan/getBudgetProjectLog/${pId}`,
        method: 'GET',
    })
}

const getParentObtOrg = (orgId) => {
    return request({
        url: `/plan/getParentObtOrg/${orgId}`,
        method: 'GET',
    })
}

function retrieveBudgetPlans(year,org_id) {
    return request({
        url: `/plan/retrieveBudgetPlans/${year}?org_id=${org_id}`,
        method: 'GET'
    });
}
function retrievePlanAgendaEvent(budgetYear, orgId) {
    return request({
        url: `/plan/retrievePlanAgendaEvent/${budgetYear}/${orgId}`,
        method: 'GET'
    });
}

function retrieveAllBudget(budgetYear, orgId, bpId,agendaEventId,order) {
    return request({
        url: `/plan/retrieveAllBudget/${budgetYear}/${orgId}/${bpId}/${agendaEventId}?order=${order}`,
        method: 'GET'
    });
}

function getScheduleOfferChartEconList(budgetYear, orgId,agendaEventId) {
    return request({
        url: `/plan/getScheduleOfferChartEconList/${budgetYear}/${orgId}/${agendaEventId}`,
        method: 'GET'
    });
}

function getScheduleOfferChart(budgetYear, orgId,agendaEventId,econ_code) {
    return request({
        url: `/plan/getScheduleOfferChart/${budgetYear}/${orgId}/${agendaEventId}/${econ_code}`,
        method: 'GET'
    });
}

function getSchedulePrevSuggestion(budgetYear, orgId,agendaEventId,econ_code) {
    return request({
        url: `/plan/getSchedulePrevSuggestion/${budgetYear}/${orgId}/${agendaEventId}/${econ_code}`,
        method: 'GET'
    });
}

function downloadBudgetPlanData(budgetYear, orgId, bpId) {
    return request({
        url: `/plan/downloadBudgetPlanData/${budgetYear}`,
        method: 'GET'
    });
}

function generatePlanExelData(budgetYear) {
    return request({
        url: `/plan/generatePlanExelData/${budgetYear}`,
        method: 'GET'
    });
}
function generateBudgetProjectDetailExelData(budgetYear, bpid) {
    return request({
        url: `/plan/generateBudgetProjectDetailExelData/${budgetYear}/${bpid}`,
        method: 'GET'
    });
}

const checkExpenseEditRequestAvailable = () => {
    return request({
        url: `/plan/checkExpenseEditRequestAvailable`,
        method: 'GET'
    })
}

function checkFulfilled(budgetYear, orgId, bpId) {
    return request({
        url: `/plan/checkFulfilled/${budgetYear}/${orgId}/${bpId}`,
        method: 'GET'
    });
}

function retrieveByAgenda(budgetYear, orgId) {
    return request({
        url: `/plan/retrieveByAgenda/${budgetYear}/${orgId}`,
        method: 'GET'
    });
}

function retrieveByAgendaDetail(budgetYear, orgId, econ_code_1) {
    return request({
        url: `/plan/retrieveByAgendaDetail/${budgetYear}/${orgId}?econ_code_1=${econ_code_1}`,
        method: 'GET'
    });
}

function retrieveByEventDetailByAgenda(budgetYear, orgId, agId, econ_code_1) {
    return request({
        url: `/plan/retrieveByEventDetailByAgenda/${budgetYear}/${orgId}/${agId}?econ_code_1=${econ_code_1}`,
        method: 'GET'
    });
}

function retrieveEconCategoryByAgendaEvent(budgetYear, orgId, agId, evId, econ_code_1) {
    return request({
        url: `/plan/retrieveEconCategoryByAgendaEvent/${budgetYear}/${orgId}/${agId}/${evId}?econ_code_1=${econ_code_1}`,
        method: 'GET'
    });
}

function retrieveBudgetDetail(bpId, agendaId, eventId, econCatId, budgetYear) {
    return request({
        url: `/plan/retrieveBudgetDetail/${bpId}/${agendaId}/${eventId}/${econCatId}/${budgetYear}`,
        method: 'GET'
    });
}

function uploadBudgetProjectDetail(body,projectId) {
    return request({
        url: `/plan/uploadBudgetProjectDetail?projectId=${projectId}`,
        method: 'POST',
        data: body
    });
}

function uploadBudgetProjectDetailWithPlanOffer(body, poId) {
    return request({
        url: `/plan/uploadBudgetProjectDetailWithPlanOffer/${poId}`,
        method: 'POST',
        data: body
    });
}

function setBudgetProjectNote(body, poId) {
    return request({
        url: `/plan/setBudgetProjectNote/${poId}`,
        method: 'POST',
        data: body
    });
}

function uploadPlanOffer(body) {
    return request({
        url: `/plan/uploadPlanOffer`,
        method: 'POST',
        data: body
    });
}

function uploadExpenseRequest(body) {
    return request({
        url: `/plan/uploadExpenseRequest`,
        method: 'POST',
        data: body
    });
}

function changeStatus(bpi) {
    return request({
        url: `/plan/changeStatus/${bpi}`,
        method: 'GET',
    });
}

function retrieveAllBudgetPlans(year) {
    return request({
        url: `/plan/retrieveAllBudgetPlans/${year}`,
        method: 'GET',
    });
}

function refOrgs() {
    return request({
        url: `/plan/refOrgs`,
        method: 'GET',
    });
}

function getCurrentMonthEditRequest(bpId, currentMonth) {
    return request({
        url: `/plan/getCurrentMonthEditRequest/${bpId}/${currentMonth}`,
        method: 'GET',
    });
}

function getHamaaguiEditRequest(bpId) {
    return request({
        url: `/plan/getHamaaguiEditRequest/${bpId}`,
        method: 'GET',
    });
}

function decideBudgetPlan(bpId, decision, body) {
    return request({
        url: `/plan/decideBudgetPlan/${bpId}/${decision}`,
        method: 'PUT',
        data: body,
    });
}

function updateExpenseRequestStatus(arr) {
    return request({
        url: `/plan/updateExpenseRequestStatus`,
        method: 'POST',
        data: arr,
    });
}

function retrieveBudgetDetailHistory(bpId) {
    return request({
        url: `/plan/retrieveBudgetDetailHistory/${bpId}`,
        method: 'GET',
    });
}

function retrievePieChartData(year) {
    return request({
        url: `/plan/getPieChartData/${year}`,
        method: 'GET',
    });
}

function retrieveBarChartData(year, org) {
    return request({
        url: `/plan/getBarChartData/${year}?filterOrg=${org}`,
        method: 'GET',
    });
}

function getBudgetPlanData(year, org) {
    return request({
        url: `/plan/getBudgetPlanData/${year}?filterOrg=${org}`,
        method: 'GET',
    });
}

function getActiveAgenda(year,org) {
    return request({
        url: `/plan/getActiveAgenda/${year}?filterOrg=${org}`,
        method: 'GET',
    });
}

function getAgendaMonthPlan(year, agenda, org) {
    return request({
        url: `/plan/getAgendaMonthPlan/${year}/${agenda}?filterOrg=${org}`,
        method: 'GET',
    });
}

function getPlanByEconCategory(year,org) {
    return request({
        url: `/plan/getPlanByEconCategory/${year}?filterOrg=${org}`,
        method: 'GET',
    });
}

function getBudgeDetailByAgenda(year, org) {
    return request({
        url: `/plan/getBudgeDetailByAgenda/${year}?filterOrg=${org}`,
        method: 'GET',
    });
}

function retrieveBarChartSpecific(it) {
    return request({
        url: `/plan/getBarChartSpecific/${it}`,
        method: 'GET',
    });
}

function retrieveAimagByOrgId(it) {
    return request({
        url: `/plan/getAimagByOrgId/${it}`,
        method: 'GET',
    });
}

function getPlanOfferByDetailedParams(bpId, aId, eId, ecId) {
    return request({
        url: `/plan/getPlanOfferByDetailedParams/${bpId}/${aId}/${eId}/${ecId}`,
        method: 'GET',
    });
}

function getPlanOffers(bpId) {
    return request({
        url: `/plan/getPlanOffers/${bpId}`,
        method: 'GET',
    });
}

function getPlanOfferProgress(bpId) {
    return request({
        url: `/plan/getPlanOfferProgress/${bpId}`,
        method: 'GET',
    });
}

function getExpenseRequestSentOrganizations() {
    return request({
        url: `/plan/getExpenseRequestSentOrganizations`,
        method: 'GET',
    });
}

function getExpenseRequestSentOrgDetail(data, approvement) {
    return request({
        url: `/plan/getExpenseRequestSentOrgDetail/${data}?approvement=${approvement}`,
        method: 'GET',
    });
}

function retrieveExpenseRequestEditData(data) {
    return request({
        url: `/plan/retrieveExpenseRequestEditData/${data.budget_project.id}/${data.budget.economic_category.id}`,
        method: 'GET',
    });
}

function updateBudgetByExpense(body) {
    return request({
        url: `/plan/updateBudgetByExpense`,
        method: 'POST',
        data: body
    });
}

const getScheduleUpdatedDate = (year) => {
    return request({
        url:`/plan/getScheduleUpdatedDate/${year}`,
        method:'GET'
    })
}

const getBudgetProjectFiles = (pId) => {
    return request({
        url:`/plan/getBudgetProjectFiles/${pId}`,
        method:'GET'
    })
}

const planOfferStatusList = (org_id,year)=> {
    return request({
        url: `/plan/planOfferStatusList?org_id=${org_id}&year=${year}`,
        method:'GET'
    });
}

const planOfferMergeList = (org_id,year,status)=> {
    return request({
        url: `/plan/planOfferMergeList?org_id=${org_id}&year=${year}&status=${status}`,
        method:'GET'
    });
}

const planOfferYearList = (org_id)=> {
    return request({
        url: `/plan/planOfferYearList?org_id=${org_id}`,
        method:'GET'
    });
}

const planScheduleOfferReport = (org_id,year,status)=> {
    return request({
        url: `/plan/planScheduleOfferReport?org_id=${org_id}&year=${year}&status=${status}`,
        method:'GET'
    });
}
const PlanService = {
    planOfferYearList,
    planOfferStatusList,
    planOfferMergeList,
    changeStatusProject,
    getBudgetProjectLog,
    retrieveBudgetPlans,
    retrievePlanAgendaEvent,
    retrieveAllBudget,
    retrieveByAgenda,
    retrieveByAgendaDetail,
    retrieveByEventDetailByAgenda,
    retrieveEconCategoryByAgendaEvent,
    retrieveBudgetDetail,
    uploadBudgetProjectDetail,
    checkFulfilled,
    checkExpenseEditRequestAvailable,
    changeStatus,
    retrieveAllBudgetPlans,
    refOrgs,
    decideBudgetPlan,
    downloadBudgetPlanData,
    generateBudgetProjectDetailExelData,
    generatePlanExelData,
    retrieveBudgetDetailHistory,
    retrievePieChartData,
    retrieveBarChartData,
    getBudgetPlanData,
    getActiveAgenda,
    getAgendaMonthPlan,
    getPlanByEconCategory,
    getBudgeDetailByAgenda,
    retrieveBarChartSpecific,
    retrieveAimagByOrgId,
    uploadPlanOffer,
    getPlanOfferByDetailedParams,
    getPlanOffers,
    getPlanOfferProgress,
    uploadBudgetProjectDetailWithPlanOffer,
    getCurrentMonthEditRequest,
    getHamaaguiEditRequest,
    uploadExpenseRequest,
    getExpenseRequestSentOrganizations,
    getExpenseRequestSentOrgDetail,
    updateExpenseRequestStatus,
    retrieveExpenseRequestEditData,
    updateBudgetByExpense,
    getScheduleUpdatedDate,
    getBudgetProjectFiles,
    setBudgetProjectNote,
    getScheduleOfferChart,
    getScheduleOfferChartEconList,
    getSchedulePrevSuggestion,
    getParentObtOrg,
    planScheduleOfferReport,
}

export default PlanService;

import React, {useState, useEffect } from "react";
import List from "devextreme-react/list";
import JasperService from "../../../services/api/jasper";
import _ from "lodash";
import LoadPanelComponent from "../../../components/LoadPanelComponent";

export default function Jasper() {
    const [dataSource, setDataSource] = useState([]);
    const [loader, setLoader] = useState(false);
    const [reportSelected, setReportSelected] = useState([]);

    useEffect(() => {
        jasperFetch();
    }, [])

    const jasperFetch = async () => {
        try {
            setLoader(true);
            const result = await JasperService.getJasperReport()
            let r= await _.filter(result, {jasper_report_type_id: 1});
            setDataSource(r);
            return setLoader(false)
        } catch (e) {
            console.error(e);
        }
    }

    const reportClick = async (e) => {
        setReportSelected(e.itemData.id);
    }

    const renderListItem = (data) => {
        return (
            <div style={{display: 'flex', alignItems: 'center', padding: '5px', height: '25px'}}>
                <svg t="1644386339421" className="icon" viewBox="0 0 1024 1024" version="1.1"
                     xmlns="http://www.w3.org/2000/svg" p-id="2286" width="25" height="25">
                    <path
                        d="M454.393489 253.760326h383.973958c15.00061 0 28.75117 5.000203 38.959918 13.125534 11.4588 9.16704 18.542421 22.084232 18.542421 36.459817v107.296032h-38.334893l-691.278128-1.041709H127.921872v-106.462665c0-14.583927 7.083622-27.292777 18.542421-36.459817 10.208749-8.125331 23.959308-13.125534 38.959919-13.125534h38.334893v-29.376196c0-10.625432 4.375178-20.209156 11.250458-27.292777 7.083622-7.083622 16.667345-11.4588 27.292777-11.458799h153.547914c10.625432 0 20.209156 4.375178 27.292778 11.458799 6.87528 7.083622 11.250458 16.667345 11.250457 27.292777v29.584538z"
                        fill="#F9A825" p-id="2287"></path>
                    <path
                        d="M185.632553 320.013021h652.943235c15.625636 0 30.001221 6.250254 40.418312 16.459003 10.625432 10.208749 17.084028 24.584334 17.084028 40.20997v405.224822c0 15.625636-6.458596 29.792879-17.084028 40.209969-10.417091 10.208749-24.792675 16.459003-40.418312 16.459003H185.632553c-15.625636 0-30.001221-6.250254-40.418311-16.459003-10.625432-10.208749-17.084028-24.584334-17.084028-40.209969V376.681994c0-15.625636 6.458596-29.792879 17.084028-40.20997 10.417091-10.208749 24.584334-16.459003 40.418311-16.459003z"
                        fill="#FBC02D" p-id="2288"></path>
                </svg>
                <span style={{marginLeft: '10px'}}>{data.report_name}</span>
            </div>
        )
    }

    return (
        <React.Fragment>
            <LoadPanelComponent
                shadingColor="rgba(0,0,0,0.4)"
                // position={userListPosition}
                visible={loader}
                showPane={false}
                message = ''
            />
            <div className={'card'}>
                <div className={'card-body'}>
                    <div id={'firstBox'} className={'card-body'}>
                                    <div className="row">
                                        <div className="col-2">
                                            <List
                                                selectionMode="single"
                                                dataSource={dataSource}
                                                selectedItemKeys={reportSelected}
                                                onItemClick={reportClick}
                                                itemRender={renderListItem}
                                            />
                                        </div>
                                        <div className="col-10">
                                            {dataSource.map((c, i) => (
                                                <div key={i} style={{ marginLeft: 20 }}>
                                                    { reportSelected === c.id &&
                                                        <iframe width="100%" style={{ height: '100vh' }}
        src={c.url+'/jasperserver/flow.html?_flowId=viewReportFlow&_flowId=viewReportFlow&ParentFolderUri=%2F'+c.parentfolderuri+'&reportUnit=%2F'+c.reportunit+'%2F'+c.resource_id+'&standAlone=true&j_username='+c.j_username+'&j_password='+c.j_password+'&sessionDecorator=no&decorate=no'} allowFullScreen ></iframe>
                                                    }
                                                </div>
                                            ))}

                                        </div>
                                    </div>

                            </div>

                </div>
            </div>
        </React.Fragment>
    );
}

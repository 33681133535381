import { Tabs, Popover, ConfigProvider, Tooltip, Drawer } from "antd"
import React from "react"
import { useState } from "react"
import styled from "styled-components"
import FeedbackForm from "./FeedbackForm"
import feedback from "../../assets/imgs/feedback.png"

const FeedbackContainer = styled.div`
  position: fixed;
  right: 2%;
  bottom: 3%;
  z-index: 2;
  display: flex;
  align-items: center;
  padding: 0;
  .customPopover {
    box-sizing: border-box;
    margin: 0;
    box-shadow: 0 6px 16px 0 rgba(0,0,0,.08), 0 3px 6px -4px rgba(0,0,0,.12), 0 9px 28px 8px rgba(0,0,0,.05);
    padding: 12px 16px;
    background-color: #fff;
    width: 22rem;
    height: auto;
    max-height: 25rem;
    line-height: 1.5715;
    list-style: none;
    position: fixed;
    bottom: 10%;
    right: 2%;
    z-index: 3;
    white-space: normal;
    text-align: left;
    cursor: pointer;
  }
.button {
  width: 3.5rem;
  height: 3.5rem;
  transition: all .5s ease;
  font-family: Montserrat;
  border-radius: 50%;
  border: none;
  box-shadow: 0 6px 16px 0 rgba(0,0,0,.08), 0 3px 6px -4px rgba(0,0,0,.12), 0 9px 28px 8px rgba(0,0,0,.05);
  background-color: #fff;
  color: #5575c2;
  display: flex;
  justify-content: center;
  align-items: center;
}
.button:hover {
  background-color: #f2f6ff;
  color: white;

  .img {
    transform
  }

}

`;

const FeedbackContent = ({setVisible}) => {
  const [selectedTab, setSelectedTab] = useState(1);
  const items = [
    {
      key: '1',
      label: 'Санал, хүсэлт',
      children: <FeedbackForm name={'name'} selectedTab={selectedTab} setVisible={setVisible}/>,
    },
    {
      key: '2',
      label: 'Гомдол',
      children: <FeedbackForm name={'name'} selectedTab={selectedTab} setVisible={setVisible}/>,
    },
    {
      key: '3',
      label: 'Талархал',
      children: <FeedbackForm name={'name'} selectedTab={selectedTab} setVisible={setVisible}/>,
    },
  ];

  const onChange = (key) => {
    setSelectedTab(key);
  };

  return (

    <Tabs
      className="tab"
      type="primary"
      style={{ width: '20rem' }}
      centered
      tabPosition="top"
      defaultActiveKey="1"
      items={items}
      onChange={onChange}
    />

  );
};

function Feedback() {
  const [visible, setVisible] = useState(false)

  return (
    <>
      <FeedbackContainer>
          <Tooltip color="#48A4DF" placement="left" title="Санал хүсэлт илгээх">
            <button className="button" onClick={()=>setVisible(true)}>
              <img className="img" src={feedback} width={30} height={30} />
            </button>
          </Tooltip>
        {/* <div className="customPopover" style={{ display: visible ? 'block' : 'none' }} > */}
        <Drawer
           width={"min-content"}
           headerStyle={{display: "none"}}
           maskClosable
           style={{borderTopLeftRadius: "1rem", borderBottomLeftRadius: "1rem"}}
           bodyStyle={{padding: ".5rem 1rem"}}
           placement="right"
           onClose={() => setVisible(false)}
           open={visible}
        >
          <FeedbackContent setVisible={setVisible}/>
        </Drawer>
        {/* </div> */}
      </FeedbackContainer>
    </>
  )
}

export default Feedback
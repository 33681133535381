import React, {useContext, useEffect, useState, useRef} from 'react';
import 'devextreme-react/text-area';
import DataGrid, {
    Editing,
    Pager,
    Paging,
    Column,
    FilterRow,
    RequiredRule,
    PatternRule,
    Lookup, Export, Form, Item, Popup, Toolbar
} from 'devextreme-react/data-grid';
import {Workbook} from 'exceljs';
import {saveAs} from 'file-saver-es';
import {exportDataGrid} from 'devextreme/excel_exporter';
import {LoadPanel} from "devextreme-react/load-panel";
import {UserContext} from "../../../hooks/UserContext";
import _, {once} from "lodash";
import fcsService from "../../../services/api/fcs";
import Button from "devextreme-react/button";
import notify from "devextreme/ui/notify";
import SelectBox from "devextreme-react/select-box";
import {getYears} from "../../../util";
import fcsServices from "../../../services/api/fcs";
import LoadPanelComponent from '../../../components/LoadPanelComponent';
import {HiSearch} from "react-icons/hi";

const monthList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];


function EditApSelect(props) {

    const userListPosition = {of: '#usersList'}
    const {user} = useContext(UserContext);
    const [dataSource, setDataSource] = useState([]);
    const [loader, setLoader] = useState(false);
    const myBabyRef = useRef(null);
    const [dataFcsChart, setFcsChart] = useState([]);
    const [dataFcsCustOrgn, setFcsCustOrgn] = useState([]);
    const [dataFcsApClass, setFcsApClass] = useState([]);
    const [dataFcsMonth, setFcsMonth] = useState([]);
    const [yearValue, setYearValue] = useState(null);
    const [monthValue, setMonthValue] = useState(null);
    const [dataFcsSt4New, setFcsSt4New] = useState([]);

    useEffect(() => {
        setDate();
        //   fcsApSelectLoad()
    }, [])

    useEffect(() => {
        //   fcsApSelectList();

    }, [])
    const setDate = async () => {
    }

    const fcsApSelectLoad = async () => {
        setLoader(true);

        const fcsMonth = await fcsService.getAllFcsMonth('AP');
        setFcsMonth(fcsMonth);

        fcsMonth.map((item) => {
            setYearValue(item.year)
            setMonthValue(item.month)


        })
        const result = await fcsServices.getFcsApSelectReport(fcsMonth[0].year, fcsMonth[0].month, 0)
        setDataSource(result)

        const fcsChart = await fcsService.getFcsChart('BB', 0, 0, 0, 0);
        setFcsChart(fcsChart);


        setLoader(false)

    }
    const fcsApSelectList = async () => {

        try {


            const fcsApClass = await fcsService.getFcsChart('AP', 0, 0, 0, 0);
            setFcsApClass(fcsApClass);

            const fcsCustOrgn = await fcsService.getAllFcsCustOrgn();
            setFcsCustOrgn(fcsCustOrgn);
            const fcsMonth = await fcsService.getAllFcsMonth('AP');
            setFcsMonth(fcsMonth);


            return setLoader(false)
        } catch (e) {
            console.error(e);
            return setLoader(false)
        }

    }


    const onEditorPreparing = (e) => {
        if (e.parentType === "dataRow" && e.dataField !== "password") {
            return;
        }


    }

    const calculateAmt = (newData, value, currentRowData) => {
        newData.qty = value;
        newData.amt = currentRowData.unit * value;
    }
    const calculateAmtVat = (newData, value, currentRowData) => {
        newData.unit = value;
        newData.amt = currentRowData.qty * value;
    }


    const setPurchase = async (e) => {
        if (e.changes && e.changes.length !== 0) {
            //yyyymm
            const date = new Date(e.changes[0].data.docdate);//new e.changes[0].data.docdate;
            const year = date.getFullYear() * 1e4; // 1e4 gives us the the other digits to be filled later, so 20210000.
            const month = (date.getMonth() + 1) * 100;
            const yyyymm = year + month // `+ ''` to convert to string from number, 202206
            const fcsCurrentMonth = dataFcsMonth.map((item) => {
                let date = item.year + '/' + item.month
                let datetimenew = new Date(date)
                let year = datetimenew.getFullYear() * 1e4; // 1e4 gives us the the other digits to be filled later, so 20210000.
                let month = (datetimenew.getMonth() + 1) * 100;
                const yyyymm_month = year + month
                return yyyymm_month
            });

            if (yyyymm === fcsCurrentMonth[0]) {
                if (e.changes[0].type === "update") {
                    try {
                        e.changes[0].data.updated_user_id = user.id;
                        //   e.changes[0].data.acct_name = _.filter(dataFcsChart, {apacct: e.changes[0].data.cash_acct})[0].descr;
                        //  e.changes[0].data.custname = _.filter(dataFcsCustOrgn, {orgcode: e.changes[0].data.cust})[0].name;
                        await fcsService.editFcsApSelect(e.changes[0].data);
                        await fcsApSelectLoad();
                        notify('Амжилттай хадгалагдлаа', "success", 2000);
                    } catch (e) {
                        notify('Засварлалтыг хадгалах явцад алдаа гарлаа', "error", 2000);
                    }
                }

            } else {
                notify('Сонгосон огноо тухайн тайлант он сард биш байна', "error", 2000);
            }
        }

    }
    const onExporting = (e) => {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Main sheet');

        exportDataGrid({
            component: e.component,
            worksheet,
            autoFilterEnabled: true,
        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer], {type: 'application/octet-stream'}), 'apselect.xlsx');
            });
        });
        e.cancel = true;
    }

    const onCellPrepared = (e) => {
        let cell = e.cellElement
        if (e.rowType !== "header" && e.rowType !== "filter") {
            cell.style = "color: rgb(20, 23, 106); font-weight: 500; padding: .875rem 0; padding-left: 1rem"
        } else if (e.rowType === "header") {
            cell.style = `
            background-color: rgb(238, 240, 244); 
            border: none; padding-top: .875rem; 
            padding-left: 1rem; 
            color: rgb(20, 23, 106); 
            font-weight: 600; 
            font-size: 13px;
            margin-top: "2rem"
            `
        } else if (e.rowType === "filter") {
            cell.style = "background-color: rgb(238, 240, 244); padding: .5rem; border: none; margin-bottom: 1rem"
        }

    }
    return (
        <div>
            <LoadPanelComponent
                shadingColor="rgba(0,0,0,0.4)"
                position={{of: '#password'}}
                message=''
                showPane={false}
            />
            <div id={'firstBox'}>
                <LoadPanelComponent
                    shadingColor="rgba(0,0,0,0.4)"
                    position={userListPosition}
                    visible={loader}
                    message=''
                    showPane={false}
                />
                <div className='card'>


                    <DataGrid
                    noDataText='Дата байхгүй байна.'
                        onEditorPreparing={onEditorPreparing}
                        ref={myBabyRef}
                        dataSource={dataSource}
                        onSaved={setPurchase}
                        keyExpr="id"
                        className="datagridBbReport"
                        onCellPrepared={onCellPrepared}
                        allowColumnResizing={true}
                        onExporting={onExporting}>
                        
                        <Toolbar>

                            <Item location={"before"} className='customAddButton' cssClass="datagridToolbarOrganization" text={"Төлөлт"}/>
                            <Item location='before'
                                  widget='dxButton'

                                  options={{icon:'refresh'}}
                                  cssClass="datagridExportButton"
                                  onClick={fcsApSelectLoad}
                            />


                            <Item location={"after"} cssClass="datagridExportButton" name="exportButton"/>
                            <Item location={"after"} name="searchPanel"/>
                        </Toolbar>
                        <FilterRow
                            visible={true}
                        />
                        <Pager
                            showPageSizeSelector={true}
                            // showInfo={true}
                            showNavigationButtons={true}
                        />
                        <Paging
                            defaultPageSize={7}
                            defaultPageIndex={1}
                        />
                        <Column
                            dataField="id"
                            caption="Дугаар"
                            allowEditing={false}
                            width="75"
                            alignment="center"
                            // editCellTemplate = {}
                            // cellRender = {customize}
                            cssClass="custom"
                            allowFiltering={true}
                        >
                        </Column>


                        <Column
                            dataField="agenda_id"
                            caption="Төсөл хөтөлбар"
                            allowEditing={false}
                            cssClass="custom"
                            width="75"
                            alignment="center"
                            allowFiltering={true}
                        >
                        </Column>

                        <Column
                            dataField="event_id"
                            caption="Зориулалт"
                            allowEditing={false}
                            cssClass="custom"
                            width="75"
                            alignment="center"
                            allowFiltering={true}
                        >
                        </Column>

                        <Column
                            dataField="econ_category_id"
                            caption="Э/З ангилал"
                            allowEditing={false}
                            cssClass="custom"
                            width="75"
                            alignment="center"
                            allowFiltering={true}
                        >
                        </Column>


                        <Column
                            cssClass="custom"
                            dataField="brn"
                            caption="Баримтын дугаар"
                            allowEditing={true}
                            width="80"
                            alignment="center"
                            allowFiltering={true}
                        >
                            <RequiredRule
                                message='Баримтын дугаар оруулна уу'
                            />
                        </Column>
                        <Column
                            cssClass="custom"
                            dataField="docno"
                            width="80"
                            alignment="center"
                            caption="Журнал №"
                            allowEditing={false}
                            allowCollapsing={false}
                            allowFiltering={true}
                        >
                        </Column>
                        <Column
                            cssClass="custom"
                            dataField="cash_acct"
                            minWidth="75"
                            alignment="center"
                            caption="Данс"
                            allowEditing={true}
                            allowFiltering={true}
                        >
                            <RequiredRule
                                message='Данс оруулна уу'
                            />
                            <Lookup
                                valueExpr="acct"
                                displayExpr={(item) => {
                                    return item && `${item.acct} - ${item.descr}`
                                }}
                                dataSource={dataFcsChart}

                            />
                        </Column>
                        <Column
                            cssClass="custom"
                            dataField="descr"
                            caption="Гүйлгээний утга"
                            minWidth="80"
                            alignment="center"
                            allowEditing={true}
                            allowFiltering={true}
                        >
                            <RequiredRule
                                message='Гүйлгээний утга'
                            />
                        </Column>

                        <Column
                            cssClass="custom"
                            dataField="docdate"
                            width="75"
                            alignment="center"
                            caption="Огноо"
                            allowEditing={true}
                            dataType="date"
                        >
                            <RequiredRule
                                message='Огноо оруулна уу'
                            />
                        </Column>

                        <Column
                            cssClass="custom"
                            dataField="qty"
                            caption="Тоо"
                            width="75"
                            alignment="center"
                            allowEditing={true}
                            allowFiltering={true}
                            setCellValue={calculateAmt}
                            format="#,##0.00"
                        >
                            <RequiredRule
                                message='Тоо оруулна уу'
                            />
                            <PatternRule pattern={/^\d+$/}
                                         message='зөвхөн эерэг тоо оруулна уу!'/>
                        </Column>
                        <Column
                            cssClass="custom"
                            dataField="unit"
                            caption="Ханш"
                            width="75"
                            alignment="center"
                            format="#,##0.00"
                            setCellValue={calculateAmtVat}
                            allowEditing={true}
                            allowFiltering={true}
                        >
                            <RequiredRule
                                message='Ханш оруулна уу'
                            />
                            <PatternRule pattern={/^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/}
                                         message='зөвхөн тоо оруулна уу!'/>
                        </Column>
                        <Column
                            cssClass="custom"
                            dataField="amt"
                            caption="Нийт дүн"
                            format="#,##0.00"
                            width="75"
                            alignment="center"
                            allowEditing={false}

                        >
                        </Column>

                        <Column
                            cssClass="custom"
                            dataField="cust"
                            minWidth="80"
                            alignment="center"
                            caption="Харилцагч "
                            allowEditing={false}
                            allowFiltering={true}
                        >

                            <Lookup
                                valueExpr="orgcode"
                                displayExpr={(item) => {
                                    return item && `${item.name}`
                                }}
                                dataSource={dataFcsCustOrgn}

                            />
                        </Column>
                        <Column
                            cssClass="custom"
                            dataField="st4"
                            caption="СТ4 "
                            allowEditing={true}
                            width={90}
                            alignment="center"
                            allowFiltering={true}
                        >

                            <Lookup
                                valueExpr="code"
                                displayExpr={(item) => {
                                    return item && `${item.code} - ${item.descr}`
                                }}
                                dataSource={dataFcsSt4New}

                            />
                        </Column>
                        <Column
                            cssClass="custom"
                            dataField="apacct"
                            width="75"
                            alignment="center"
                            caption="Өглөгийн ангилал"
                            allowEditing={false}
                        >

                            <Lookup
                                valueExpr="acct"
                                displayExpr={(item) => {
                                    return item && `${item.acct} - ${item.descr}`
                                }}
                                dataSource={dataFcsApClass}

                            />
                        </Column>
                        <Editing
                            mode="popup"
                            allowUpdating={true}
                            allowDeleting={false}
                            // allowAdding = {adding}
                            useIcons={true}
                            texts={{
                                cancelAllChanges: 'Болих бүгд',
                                cancelRowChanges: 'Болих',
                                confirmDeleteMessage: 'Энэ худалдан авалт мэдээллийг устгах уу?',
                                confirmDeleteTitle: 'Баталгаажуулах',
                                deleteRow: 'Устгах',
                                editRow: 'Өөрчлөх',
                                saveAllChanges: 'Хадгалах бүгд',
                                saveRowChanges: 'Хадгалах',
                                undeleteRow: 'Буцаах',
                                validationCancelChanges: 'Баталгаажуулах болих',
                            }}
                        >
                            <Popup title="Төлөлт" showTitle={true} width={1000}
                                   height={440}/>
                            <Form>
                                <Item itemType="group" colCount={2} colSpan={2}>

                                    <Item dataField="brn"/>
                                    <Item dataField="docno"/>
                                    <Item dataField="cash_acct"/>
                                    <Item dataField="docdate"/>
                                    <Item dataField="qty"/>
                                    <Item dataField="unit"/>

                                    <Item dataField="amt" format="#,##0.00"/>
                                    <Item dataField="cust"/>
                                    <Item dataField="apacct"/>
                                    <Item dataField="descr"/>
                                    <Item dataField="st4"/>

                                </Item>
                            </Form>
                        </Editing>
                        <Paging enabled={true}/>
                        <Export enabled={true} allowExportSelectedData={false}/>
                    </DataGrid>
                </div>
            </div>
        </div>
    )
}

export default EditApSelect;

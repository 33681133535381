import React, {Component} from "react";
import TabPanel, { Item } from 'devextreme-react/tab-panel';
import PositionSection from "../../home/components/userPosition"
import OrgForms from "./orgForms";
import OrgFormDataView from "./orgFormDataView";
import CreateOrgStructure from "../../home/components/createOrgStructure";

export default class OrgFormsManager extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showAddDialog: false,
      editData: {},
      isEdit: false,
      loader: false,
    };

  }

  render() {
    return (
      <div className='card'>
        <div className={'card-body'}>
          <TabPanel>
            <Item title="Ажилчдын форм" icon="comment">
              <div>
                <div className='card'>
                  <div className="card-header">
                    <span>Ажилчдын форм удирдах</span>
                  </div>
                  <div className="card-body">
                    <OrgForms></OrgForms>
                  </div>
                </div>
              </div>
            </Item>
            <Item title="Ерөнхий мэдээлэл" icon="search">
              <div>
                <div className='card'>
                  <div className="card-header">
                    <span>Ерөнхий мэдээлэл удирдах</span>
                  </div>
                  <div className="card-body">

                  </div>
                </div>
              </div>
            </Item>
            <Item title="Статистик мэдээлэл" icon="chart">
              <div>
                <div className='card'>
                  <div className="card-header">
                    <span>Статистик мэдээлэл удирдах</span>
                  </div>
                  <div className="card-body">
                    <OrgFormDataView></OrgFormDataView>
                  </div>
                </div>
              </div>
            </Item>
            <Item title="Бүтэц удирдах" icon="chart">
              <div>
                <div className='card'>
                  <div className="card-header">
                    <span>Бүтэц удирдах</span>
                  </div>
                  <div className="card-body">
                    <CreateOrgStructure></CreateOrgStructure>
                  </div>
                </div>
              </div>
            </Item>
          </TabPanel>

          {/*<div className="card">*/}
          {/*  <div className="card-body">*/}
          {/*    <PositionSection></PositionSection>*/}
          {/*  </div>*/}
          {/*</div>*/}
        </div>
      </div>
    )
  }
}

import React, { useState, useRef, useEffect } from "react";
import Button from "devextreme-react/button";
import { Popup, Position, ToolbarItem } from 'devextreme-react/popup';
import {GetTran} from "../../tran/Db";
import DataGrid, {
    Column,RowDragging,
    TotalItem,
    Summary,
    Selection,
    Export, Pager ,Paging, GroupPanel, SearchPanel, Grouping, GroupItem,
} from 'devextreme-react/data-grid';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver-es';
import { exportDataGrid } from 'devextreme/excel_exporter';
import {LoadPanel} from "devextreme-react/load-panel";
import ScrollView from "devextreme-react/scroll-view";
import LoadPanelComponent from "../../../../components/LoadPanelComponent";
import SelectBox from "devextreme-react/select-box";
import fcsService from "../../../../services/api/fcs";
import ArEndMonth from "../../AccountRecievable/report/arEndMonth";

const position14 = {of: '#firstBox'};

const GlAcctView = (props) => {

    const [beginBal, setBeginBal] = useState([]);
    const [dtCr, setDtCr] = useState([]);
    const [details, setDetails] = useState([]);
    const [structure, setStructure] = useState([]);
    const [tranDescrDetails, setTranDescrDetails] = useState([]);
    const initSum = { bd: 0, bc: 0, dt: 0, cr: 0, ed: 0, ec: 0 };
    const [sum, setSum] = useState({ ...initSum });
    const componentRef = useRef();
    const [report, setReport] = useState([]);
    const initAcctCode = { code: "", name: "", amount: 0 };
    const [selectAcct, setSelectAcct] = useState({ ...initAcctCode });
    const [acctList, setAcctList] = useState([]);
    const myRef = useRef(null);
    const [loader, setLoader] = useState(false);
    const [accountValue, setAccountValue] = useState('');
    const [acctDtlList, setAcctDtlList] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);
    const [selectedValue, setSelectedValue] = useState(null);
    const [dataFcsChart, setFcsChart] = useState([]);
    const [selectedChart, setSelectedChart] = useState([]);
    const [yearValue, setYearValue] = useState(null);
    const [monthValue, setMonthValue] = useState(null);
    const [visableEndMonth, setVisableEndMonth] = useState(false)

    const style = {
        text: {
            color: "#2E4765", fontWeight: "700", fontSize: ".875rem"
        },
        box: {
            border: "none", backgroundColor: "#F6F6F6", borderRadius: ".5rem", padding: ".25rem .15rem", width: "100%"
        },
        box2: {
            border: "none",
            backgroundColor: "rgb(239, 239, 239)",
            borderRadius: ".5rem",
            padding: ".25rem .4rem",
            width: "100%"
        }
    }

    useEffect(async () => {
        getAcct();
    }, []);

    useEffect(() => {
        setDate();

    }, [])

    const getAcct = async () => {
        setLoader(true);
        const fcsChart = await fcsService.getAllFcsChart();
        setFcsChart(fcsChart);


        setLoader(false);
    };

    const onChangeChart = async (e) => {
        setAcctDtlList("")
        setSelectedChart(e);
    }

    const calReport = async (beginBalList) => {
        setLoader(true);
        let array = [];

        let index = -1;
        await beginBalList.map((row) => {
            let glRow = { acct: "", descr: "", bd: 0, bc: 0, dt: 0, cr: 0 };
            glRow.bd = row.bd;
            glRow.bc = row.bc;
            glRow.dt = row.dt;
            glRow.cr = row.cr;
            glRow.acct = row.acct;
            glRow.descr = row.descr;

            if(glRow.bd - glRow.bc > 0) {
                glRow.bd = glRow.bd - glRow.bc;
                glRow.bc = 0;
            } else {
                glRow.bc = glRow.bc - glRow.bd;
                glRow.bd = 0
            }


            index = array.findIndex((x) => x.acct === glRow.acct);

            index < 0
                ? array.push(glRow)
                : ((array[index].bd = array[index].bd + glRow.bd)
                (array[index].bc = array[index].bc + glRow.bc));
        });

        await array.map((el, index3) => {
            const val0=el.bd - el.bc
            el.bd=0
            el.bc=0

            val0>0 ? el.bd=val0 :el.bc=-val0


            const val = el.bd - el.bc + el.dt - el.cr;
            el.ed = 0;
            el.ec = 0;
            val > 0 ? (el.ed = val) : (el.ec = -val);
            array[index3] = el;
        });


        await array.map((el5, index) => {
            const found = acctList.find(element => element.c10 === el5.acct);
            if (found) el5['name'] = found.c11;
        });

        let sumCal = { bd: 0, bc: 0, dt: 0, cr: 0, ed: 0, ec: 0 };
        await array.map((el) => {
            sumCal.bd = sumCal.bd + el.bd;
            sumCal.bc = sumCal.bc + el.bc;
            sumCal.dt = sumCal.dt + el.dt;
            sumCal.cr = sumCal.cr + el.cr;
            sumCal.ed = sumCal.ed + el.ed;
            sumCal.ec = sumCal.ec + el.ec;
        });
        setSum(sumCal);

        const array2 = array.filter(
            (item, index) =>
                item.bd != 0 || item.bc != 0 || item.dt != 0 || item.cr != 0
        );

        setReport(array2);
        setLoader(false);
    };

    // ******** Татах
    const setDate = async () => {
        const fcsMonth = await fcsService.getAllFcsMonth('GL');


        fcsMonth.map((item) => {
            setYearValue(item.year)
            setMonthValue(item.month)


        })
    }

    const getGlUS = async () => {
        setDetails([]);
        let p1 = "1&year=" +
            yearValue +
            "&month=" +
            monthValue;
        const result = await GetTran(p1, 0, "report/trialsview?");
        setBeginBal(result.data.data);
        calReport(result.data.data);
    };



    const getDetails = async () => {
        if (!selectedChart  ) return;

        let p1 = "0&year=" + yearValue + "&month=" + monthValue  + "&acct=" + accountValue;

        const result = await GetTran(p1,0, "report/glacctview?");

        if (result.success){
            setAcctDtlList(result.data.data);
            setModalVisible(true);
        }


    };

    const onSelectionChanged = ({ selectedRowsData }) => {
        setAccountValue(selectedRowsData[0].acct);
        setSelectedValue(selectedRowsData[0]);
    }

    const executeScroll = () => myRef.current.scrollIntoView();
    // run this function from an event handler or an effect to execute scroll

    const acctClick = (index) => {
        setSelectAcct({
            ...initAcctCode,
            code: report[index].acct,
            name: report[index].name,
            amount: report[index].bd - report[index].bc,
        });

        executeScroll();
    };

    const customizeDate = (data) => {
        return Number(parseFloat(data.value).toFixed(2)).toLocaleString("en", {
            minimumFractionDigits: 2,
        });
    }




    const   diff = (rowData) =>  {
        const date= new Date(rowData.docdate)
        return   date.getMonth()+1;
    }
    const onExporting = (e) => {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Main sheet');

        exportDataGrid({
            component: e.component,
            worksheet,
            autoFilterEnabled: true,
        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'DataGrid.xlsx');
            });
        });
        e.cancel = true;
    }

    return (
        <React.Fragment>
            {/*<Router>*/}
            {/*  <Switch>*/}
            {/*    <Route exact path="/geeks/second" component={AcctReport}></Route>*/}
            {/*  </Switch>*/}
            {/*</Router>*/}
            <div ref={myRef}></div>


            <LoadPanelComponent
                position = {position14}
                visible = {loader}
                shading = {true}
                showPane={false}
                showIndicator={true}
                message = ''
                shadingColor="rgba(255,255,255, 0.8)"
            />
            <div className={'card-body'}>
                <ArEndMonth setVisableEndMonth={setVisableEndMonth}
                            goMonth={setDate}
                            visableEndMonth={visableEndMonth} jr_key={'GL'}

                />
                <div className={'row'}>
                    <div style={{marginLeft: 10, marginTop: 10}}>
                        <span style={{color : "#A3A3A3", marginRight: "1rem" }}>Тайлант он:</span><span style={{fontWeight: "700"}}>{yearValue} сар {monthValue}</span>
                    </div>
                </div>
                <div className="row">

                    <Button

                        id="btnSave"
                        onClick={() => getGlUS()}
                        width={"200"}
                        type = "default" style = {{alignment : "center", borderRadius: '7px'}}
                    >
                        Ажлын шалгах баланс
                    </Button>


                     <div style={{ marginLeft: 30 ,marginRight: 30  }}>


                         <div style={{ marginLeft: 30 }}>
                             <input value={accountValue} type = 'string' className = 'law-search-input' placeholder='Дансны код' disabled={true}/>
                         </div>


                    </div>

                    <Button

                        onClick={() => getDetails()}
                        width={"140"}
                        type = "default" style = {{ marginRight :15 ,alignment : "center", borderRadius: '7px'}}
                    >
                        Ерөнхий дэвтэр
                    </Button>



                        <Button text="Сарын хаалт хийх "
                               onClick={() =>  setVisableEndMonth(true)}
                                width={170} type="default"
                                style={{alignment: "center", marginRight :15  ,  borderRadius: '7px'}}/>




                </div>



                {report.length>0 &&
                <ScrollView width='100%' height='100%'>


                    <div className="col-12" style={{ marginTop: 20 }} ref={componentRef}>
                        <DataGrid
                            dataSource={report}
                            showBorders={true}
                            allowColumnResizing={true}
                            onSelectionChanged={onSelectionChanged}
                            hoverStateEnabled={true}
                            columnAutoWidth={true}
                          //  columnHidingEnabled={true}
                            keyExpr="acct"
                            noDataText = 'Дата байхгүй байна.'
                            onExporting={onExporting}>
                            <Selection mode="single" />

                            <Column caption="Дансны код" dataField="acct" dataType="string" />

                            <Column caption="Нэр" dataField="descr"  />

                            <Column caption="Эхний үлдэгдэл">
                                <Column caption="Дебит" dataField="bd" format="#,##0.00" />
                                <Column caption="Кредит" dataField="bc" format="#,##0.00"/>
                            </Column>
                            <Column caption="Гүйлгээ">
                                <Column caption="Дебит" dataField="dt" dataType="number" format="#,##0.00" />
                                <Column caption="Кредит" dataField="cr" dataType="number" format="#,##0.00" />
                            </Column>

                            <Column caption="Эцсийн үлдэгдэл">
                                <Column caption="Дебит" dataField="ed" dataType="number" format="#,##0.00" />
                                <Column caption="Кредит" dataField="ec" dataType="number" format="#,##0.00" />
                            </Column>

                            <Summary>
                                <TotalItem
                                    column="bd"
                                    summaryType="sum"
                                    customizeText={customizeDate}/>
                                <TotalItem
                                    column="bc"
                                    summaryType="sum"
                                    customizeText={customizeDate} />
                                <TotalItem
                                    column="dt"
                                    summaryType="sum"
                                    customizeText={customizeDate} />
                                <TotalItem
                                    column="cr"
                                    summaryType="sum"
                                    customizeText={customizeDate} />
                                <TotalItem
                                    column="ed"
                                    summaryType="sum"
                                    customizeText={customizeDate} />
                                <TotalItem
                                    column="ec"
                                    summaryType="sum"
                                    customizeText={customizeDate} />
                            </Summary>
                            <Paging
                                defaultPageSize={100}
                            />
                            <Export enabled={true} allowExportSelectedData={true} />
                        </DataGrid>
                    </div>




                </ScrollView>
                }
              </div>

            <Popup
                width={850}
                height={650}
                minWidth={'50%'}
                maxWidth={'100%'}
                minHeight={'100%'}
                visible={modalVisible}
                title={'Дэлгэрэнгүй'}
                onHiding={() => setModalVisible(false)}
                resizeEnabled={true}
                dragEnabled={true}
                showTitle={true}
                hideOnOutsideClick={true}
            >
                <ScrollView width='100%' height='100%'>
                    <div className={'card-body'}>
                        <div className="col-12" style={{ marginTop: 20 }}>
                            {selectedValue &&
                                <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 30, marginLeft: '10%' }}>
                                    <span style={{ fontSize: 16, fontWeight: 500 }}>Эхний үлдэгдэл: </span>
                                    <span style={{ fontSize: 16, fontWeight: 500, marginLeft: 10 }}>{selectedValue.bd - selectedValue.bc}</span>
                                </div>
                            }
                            <DataGrid
                                dataSource={acctDtlList}
                                showBorders={true}
                                allowColumnResizing={true}
                                columnAutoWidth={true}
                                hoverStateEnabled={true}
                                keyExpr="acct"
                                noDataText = 'Дата байхгүй байна.'

                            >

                                <GroupPanel visible={true}/>
                                <SearchPanel visible={true}/>
                                <Grouping autoExpandAll={true}/>
                                <Column caption="Гүйлгээний утга" dataField="descr" />
                                <Column caption="дугаар" dataField="docno" />
                                <Column caption="огноо" dataField="docdate" dataType="date"/>

                                <Column  groupIndex={0}  name={"sar"} calculateDisplayValue={diff}
                                         caption="сар" width={55}
                                         calculateCellValue={diff}
                                />
                                <Column caption="журнал" dataField="jr" />

                                <Column caption="дебит" dataField="dt"  format="#,##0.00"/>
                                <Column caption="кредит" dataField="cr" format="#,##0.00" />
                                <Column caption=" данс" dataField="acct" />
                                <Column caption=" код" dataField="orgcode" />
                                <Column caption=" нэр" dataField="orgname" />
                                <Summary>
                                    <GroupItem
                                        column="dt"
                                        summaryType="sum"
                                        format="#,##0.00"
                                        customizeText={customizeDate}
                                        showInGroupFooter={true} />



                                    <GroupItem
                                        column="cr"
                                        summaryType="sum"
                                        format="#,##0.00"
                                        customizeText={customizeDate}
                                        showInGroupFooter={true} />


                                    <TotalItem
                                        column="dt"
                                        summaryType="sum"
                                        customizeText={customizeDate} />
                                    <TotalItem
                                        column="cr"
                                        summaryType="sum"
                                        customizeText={customizeDate} />
                                </Summary>
                                <Export enabled={true} allowExportSelectedData={false} />
                                {/*<Pager   allowedPageSizes={'100'}  />*/}
                                <Paging
                                    defaultPageSize={100}
                                /> {/* Shows the first page */}
                            </DataGrid>
                            {selectedValue &&
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginTop: 30, marginRight: '10%' }}>
                                    <span style={{ fontSize: 16, fontWeight: 500 }}>Эцсийн үлдэгдэл: </span>
                                    <span style={{ fontSize: 16, fontWeight: 500, marginLeft: 10 }}>
                    { Number(parseFloat(selectedValue.ed - selectedValue.ec).toFixed(2)).toLocaleString("en", {
                        minimumFractionDigits: 2,
                    }) }</span>
                                </div>
                            }
                        </div>
                    </div>

                </ScrollView>
            </Popup>

        </React.Fragment>
    );
};

export default GlAcctView;

import React, { useState, useEffect, useContext } from "react";
import SelectBox from "devextreme-react/select-box";
import { getYears } from "../../../util";
import notify from "devextreme/ui/notify";
import FinanceReportService from "../../../services/api/financeReport";
import FinanceReportHeaderInfo from "./components/FinanceReportHeaderInfo";
import ListWithOrgs from "./components/ListWithOrgs";
import ChartByYear from "./components/ChartByYear";
import FinanceReportPieChart from "./components/FinanceReportPieChart";
import FinanceReportTreeList from "./components/FinanceReportTreeList";
import UmchiinUurchlultList from "./components/UmchiinUurchlultList";
import { UserContext } from "../../../hooks/UserContext";

const FinanceReport = (props) => {
    const { user } = useContext(UserContext)
    const [reportTypeData, setReportTypeData] = useState([]);
    const [selectedReportType, setSelectedReportType] = useState(1);
    const [currentYear, setCurrentYear] = useState(new Date().getFullYear() - 2);
    const [chosenValue, setChosenValue] = useState('ALL');
    const [orgs, setOrgs] = useState([]);
    const [selectedOrg, setSelectedOrg] = useState(undefined);
    const loadOrgs = async (year) => {
        try {
            let r = await FinanceReportService.getFilteredOrganizations(year, selectedReportType);
            let r1 = await FinanceReportService.getReportType();
            // console.log('r', r);
            setReportTypeData(r1);
            setOrgs([
                {
                    budget_year: year,
                    text_name: 'БҮГД',
                    value_name: 'ALL',
                },
                ...r
            ])

        } catch (e) {
            notify(e.message, "error", 2000)
        }
    }

    useEffect(() => {
        setChosenValue("ALL")
        loadOrgs(currentYear)


    }, [])

    return (
        <React.Fragment>
            <div style={{ padding: 10 }}>
                <div style={{ display: 'flex', gap: '1rem', flexWrap: 'wrap' }}>
                    <div>
                        <SelectBox
                            items={getYears(true)}
                            defaultValue={currentYear}
                            label="Төсвийн жил"
                            width='10rem'
                            onValueChanged={(e) => { setCurrentYear(e.value); loadOrgs(e.value) }}
                            placeholder="сонгох" />
                    </div>
                    <div>
                        <SelectBox
                            items={orgs}
                            label="Байгууллага"
                            width='22rem'
                            value={chosenValue}
                            valueExpr="value_name"
                            displayExpr="text_name"
                            onValueChanged={(e) => setChosenValue(e.value)}
                            placeholder={user.organization.obt_id !== 3 ? 'БҮГД' : user.organization.name}
                        />
                    </div>
                    <div>
                        {reportTypeData && orgs.length !== 0 && <SelectBox
                            items={reportTypeData}
                            label="Тайлангийн төрөл"
                            width="18rem"
                            value={selectedReportType}
                            valueExpr="id"
                            displayExpr="name"
                            onValueChanged={(e) => setSelectedReportType(e.value)}
                            placeholder="сонгох" />}
                    </div>
                </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }} >
                <FinanceReportHeaderInfo year={currentYear} reportType={selectedReportType} filterOrgText={selectedOrg !== undefined ? selectedOrg.name : chosenValue} selectedOrg={selectedOrg !== undefined ? selectedOrg.org_id : undefined} />
                <div style={{ flexDirection: 'row', flex: 1 }} className="d-flex responsive-flex">
                    <div style={{ "margin-bottom": 10, flex: 0.5 }} className="col-6 responsive-col">
                        <div className="card" style={{ height: "100%" }}>
                            <div className="card-header">
                                <span style={{ fontWeight: "700" }}>1. БАЙГУУЛЛАГААР /{'Жилээр'}, сая ₮/</span>
                                {/*<Button style={{ border: '0px solid transparent', borderTopRightRadius: '15px', float:'right', paddingTop:'10px',paddingRight:'5px'}} icon={'<svg t="1659165394467" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2309" width="16" height="16"><path d="M504.576 603.428571a19.748571 19.748571 0 0 1-5.705143 13.129143l-189.696 189.696 82.285714 82.285715a36.425143 36.425143 0 0 1 10.861715 25.709714c0 20.004571-16.566857 36.571429-36.571429 36.571428h-256c-20.004571 0-36.571429-16.566857-36.571428-36.571428v-256c0-20.004571 16.566857-36.571429 36.571428-36.571429a36.205714 36.205714 0 0 1 25.709714 10.861715l82.285715 82.285714 189.696-189.696c3.437714-3.437714 8.557714-5.705143 13.129143-5.705143s9.728 2.304 13.129142 5.705143l65.133715 65.133714a19.602286 19.602286 0 0 1 5.705143 13.129143zM950.857143 109.714286v256c0 20.004571-16.566857 36.571429-36.571429 36.571428a36.205714 36.205714 0 0 1-25.709714-10.861714l-82.285714-82.285714-189.696 189.696c-3.437714 3.437714-8.557714 5.705143-13.129143 5.705143s-9.728-2.304-13.129143-5.705143l-65.133714-65.133715c-3.437714-3.437714-5.705143-8.557714-5.705143-13.129142s2.304-9.728 5.705143-13.129143l189.696-189.696-82.285715-82.285715a36.425143 36.425143 0 0 1-10.861714-25.709714c0-20.004571 16.566857-36.571429 36.571429-36.571428h256c20.004571 0 36.571429 16.566857 36.571428 36.571428z" fill="#515151" p-id="2310"></path></svg>'} onClick={() => setFirstChartVisible(true)}/>*/}
                            </div>
                            <div className="card-body">
                                <ListWithOrgs year={currentYear} reportType={selectedReportType} filterOrgText={chosenValue} selectedOrg={(data) => { setSelectedOrg(data) }} />
                            </div>
                        </div>
                    </div>
                    <div style={{ "margin-bottom": 10, flex: 0.5 }} className="col-6 responsive-col">
                        <div className="card" style={{ height: "100%" }}>
                            <div className="card-header">
                                <span style={{ fontWeight: "700" }}>2.ЖИЛЭЭР /жилээр, сая ₮/- {selectedOrg !== undefined ? selectedOrg.name : chosenValue === 'ALL' ? 'Бүгд' : chosenValue}</span>
                            </div>
                            <div className="card-body" style={{ paddingBottom: "1.6rem" }}>
                                <ChartByYear reportType={selectedReportType} filterOrgText={selectedOrg !== undefined ? selectedOrg.name : chosenValue} selectedOrg={selectedOrg !== undefined ? selectedOrg.org_id : undefined} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                selectedReportType !== 4 && <div style={{ flexDirection: 'row', flex: 1 }} className="d-flex responsive-flex">
                    <div style={{ "margin-bottom": 10, flex: 0.5 }} className="col-6 responsive-col">
                        <div className="card" style={{ height: "100%" }}>
                            <div className="card-header">
                                <span style={{ fontWeight: "700" }}>3. ДАНСНЫ АНГИЛЛААР /сая ₮/- {selectedOrg !== undefined ? selectedOrg.name : chosenValue === 'ALL' ? 'Бүгд' : chosenValue}</span>
                            </div>
                            <div className="card-body">
                                <FinanceReportPieChart year={currentYear} reportType={selectedReportType} filterOrgText={selectedOrg !== undefined ? selectedOrg.name : chosenValue} selectedOrg={selectedOrg !== undefined ? selectedOrg.org_id : undefined} />
                            </div>
                        </div>
                    </div>

                </div>
            }

            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div className="card">
                    <div className="card-header">
                        <span style={{ fontWeight: "700" }}>{selectedReportType === 4 ? '3.ҮЗҮҮЛЭЛТЭЭР /сая ₮/' : '4.ДАНСААР /сая ₮/'} - {selectedOrg !== undefined ? selectedOrg.name : chosenValue === 'ALL' ? 'Бүгд' : chosenValue} </span>
                    </div>
                    <div className="card-body">
                        {selectedReportType === 4 ? <UmchiinUurchlultList year={currentYear} reportType={selectedReportType} filterOrgText={selectedOrg !== undefined ? selectedOrg.name : chosenValue} selectedOrg={selectedOrg !== undefined ? selectedOrg.org_id : undefined} /> : <FinanceReportTreeList year={currentYear} reportType={selectedReportType} filterOrgText={selectedOrg !== undefined ? selectedOrg.name : chosenValue} selectedOrg={selectedOrg !== undefined ? selectedOrg.org_id : undefined} />}
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default FinanceReport;

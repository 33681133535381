import request from '../../shared/lib/request'

const addNotificationFile = (body) => {
    return request({
        url: '/notificationFiles/',
        method: 'POST',
        data: body
    })
}

const notificationFileService = {
    addNotificationFile
}

export default notificationFileService;
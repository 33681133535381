import React, {Component} from "react";
import Button from "devextreme-react/button";
import Popup from 'devextreme-react/popup';
import {Template} from 'devextreme-react/core/template';
import {
  DataGrid, Editing, Column,
  Scrolling,HeaderFilter,
  Selection, Paging, FilterRow,
} from 'devextreme-react/data-grid';
import {Item} from 'devextreme-react/form';
import OrganizationService from "../../../services/api/organization";
import OrgFormBuilder from "./orgFormBuilder";
import TreeList, {LoadPanel} from "devextreme-react";
import ScrollView from 'devextreme-react/scroll-view';
import notify from "devextreme/ui/notify";
import PositionSection from "../../home/components/userPosition"
import SelectBox from 'devextreme-react/select-box';
import DropDownBox from 'devextreme-react/drop-down-box';
import _ from "lodash";
import LoadPanelComponent from "../../budget/components/loadPanel";

const gridColumns = ['CompanyName', 'City', 'Phone'];
export default class OrgFormDataView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showAddDialog: false,
      editData: {},
      availableFields: [],
      isEdit: false,
      loader: false,
    };

    this.onClickShow = this.onClickShow.bind(this);
    this.createNewForm = this.createNewForm.bind(this);
    this.addButtonRender = this.addButtonRender.bind(this);
    this.onDialogHidden = this.onDialogHidden.bind(this);
    this.onToolbarPreparing = this.onToolbarPreparing.bind(this);
    this.onSaved = this.onSaved.bind(this);
    this.onValueChanged = this.onValueChanged.bind(this);
    this.dataGridRender = this.dataGridRender.bind(this);
    this.syncDataGridSelection = this.syncDataGridSelection.bind(this);
    this.dataGridRender = this.dataGridRender.bind(this);
    this.dataGrid_onSelectionChanged = this.dataGrid_onSelectionChanged.bind(this);
  }

  componentDidMount() {
    // this.initVars();
    this.getData();
  }

  // initVars() {
  // }
  onToolbarPreparing(e) {
    e.toolbarOptions.items.push({
      location: 'after',
      template: 'addButton'
    });
  };

  async getData() {
    this.setState({loader: true});
    const res = await OrganizationService.orgBuilderList();

    console.log(res);
    const temp = [];
    res.data.forEach(formItem => {
      let value = JSON.parse(formItem.value) || [];

      value.forEach(item => {
        // console.log(item);
        temp.push({name: item.label, value: item.field});
      });
      // console.log(temp);

    });
    const fData = await OrganizationService.getOrgWorkerFData();

    const data = await OrganizationService.getOrgWorker();
    this.mergeFormData(fData.data, data.data);


    this.setState({
      dataSource: data.data,
      loader: false,
      availableFields: temp,
    })


  }
  mergeFormData(formData, workerData){
    // console.log(formData, workerData);
    // check
    if(!formData || !workerData) return;

    // group by worker id
    const formDataGrouped = _.groupBy(formData, 'org_worker_id')
    const curWorkerIds = Object.keys(formDataGrouped);
    // for each worker with form data in it
    curWorkerIds.forEach(workerId => {
      // find the worker from the worker list
      const worker = workerData.find(item => item.id === workerId*1);

      if(!worker) return; // if worker not found, skip this one

      // for each form data item
      formDataGrouped[workerId].forEach(formDataItem => {
        // get the value
        let dataObj = formDataItem.value ? JSON.parse(formDataItem.value) : [];
        // put the value to the worker item.
        dataObj.forEach(item => {
          worker[item.name] = item.value;
        });

      })
    })
  }
  onClickShow(e) {
    this.setState({
      showAddDialog: true,
      editData: e.row.data,
      isEdit: true,
    });
  }

  onDialogHidden() {
    this.setState({
      showAddDialog: false
    });
    this.getData();
  }

  async onSaved(e) {
    if (!e.changes || e.changes.length === 0) return;
    e.changes.forEach(data => {
      if (data.type === 'insert') {
      } else if (data.type === 'update') {
        this.handleUpdate(data.data);
      } else if (data.type === 'remove') {
        this.handleDelete(data.key) // make sure it is 'data.key' when delete
      }
    });

  }
  async handleUpdate(data) {
    // console.log('update', data);
    // return;
    try {
      const res = await OrganizationService.orgFormUpdate(data);
      if (res.success) {
        notify(`${res.message}`, 'success',);
      } else {
        notify(`${res.message}`, 'error');
      }
    } catch (e) {
      console.log(e);
      notify(`Алдаа гарлаа`, 'error');
    }
  }
  async handleDelete(data) {
    try {
      const res = await OrganizationService.orgBuilderDelete(data.id);
      if (res.success) {
        notify(`${res.message}`, 'success',);
      } else {
        notify(`${res.message}`, 'error');
      }
    } catch (e) {
      console.log(e);
      notify(`Алдаа гарлаа`, 'error');
    }
  }

  addButtonRender() {
    return <Button
      onClick={this.createNewForm}
      icon="add"
      text="Тэст">
    </Button>;
  }

  createNewForm() {
    console.log(this.state);
  }
  onValueChanged(e, field) {
    this.setState({
      [field]: e.value
    });
  }

  dataGridRender() {
    return (
      <DataGrid
        height={350}
        dataSource={this.state.availableFields}
        hoverStateEnabled={true}
        selectedRowKeys={this.state['selectedFields']}
        onSelectionChanged={this.dataGrid_onSelectionChanged}>
        <Selection mode="multiple" />
        <Scrolling mode="virtual" />
        <Paging enabled={true} pageSize={10} />
        <FilterRow visible={true} />
      </DataGrid>
    );
  }
  syncDataGridSelection(e) {
    this.setState({
      selectedFields: e.value || []
    });
  }
  dataGrid_onSelectionChanged(e) {
    this.setState({
      selectedFields: e.selectedRowKeys.length && e.selectedRowKeys || []
    });
  }

  render() {
    return (
      <div className=''>
        <div className='card'>
          <LoadPanelComponent position={{of: '#formList'}} visible={this.state.loader} />
          <div className="card-header">
            <span>Форм</span>
          </div>
          <div className="card-body">
            <div className="row-fixer form-builder">
              {/*<div className="row mb-3">*/}
              {/*  <div className="col-5">*/}
              {/*    <TextBox value={this.state.formName} onValueChanged={(e) => this.onValueChanged(e, 'formName')} placeholder='Форм нэр оруулна уу'/>*/}
              {/*  </div>*/}
              {/*  <div className="col-7">*/}
              {/*    <Button*/}
              {/*      icon='plus'*/}
              {/*      text="Хадгалах"*/}
              {/*      type="default"*/}
              {/*      onClick={this.onSaveForm}*/}
              {/*    />*/}
              {/*  </div>*/}
              {/*</div>*/}

              <div className="row">
                <div className="col-12">
                  <DropDownBox
                    value={this.state['selectedFields']}
                    deferRendering={false}
                    displayExpr="name"
                    placeholder="Select a value..."
                    showClearButton={true}
                    dataSource={this.state.availableFields}
                    onValueChanged={this.syncDataGridSelection}
                    contentRender={this.dataGridRender}
                  />

                  {/*<SelectBox*/}
                  {/*  dataSource={this.state.availableFields}*/}
                  {/*  displayExpr="name"*/}
                  {/*  valueExpr="value"*/}
                  {/*  value={this.state['selectedFields']}*/}
                  {/*  onValueChanged={(e) => this.onValueChanged(e, 'selectedFields')}/>*/}
                </div>
                <div className="col-12 mt-2">
                  <DataGrid
                    height={'50vh'}
                    id="formList"
                    dataSource={this.state.dataSource}
                    rowAlternationEnabled={true}
                    showBorders={false}
                    onToolbarPreparing={this.onToolbarPreparing}
                    onSaved={this.onSaved}
                  >
                    <FilterRow visible={true} />
                    <HeaderFilter visible={true} />
                    <Column dataField="last_name" caption="Овог"/>
                    <Column dataField="first_name" caption="Нэр"/>
                    {/*<Column dataField="email" caption="Цахим шуудан"/>*/}
                    <Column dataField="org_position_name" caption="Албан тушаал"/>
                    <Column dataField="org_structure_name" caption="Алба нэгж"/>
                    {/*<Column dataField="phone" caption="Утас"/>*/}
                    {this.state.selectedFields && this.state.selectedFields.length &&
                    this.state.selectedFields.map(sf => <Column dataField={sf.value} caption={sf.name} />)}
                    <Template name="addButton" render={this.addButtonRender}/>
                    <Scrolling mode="virtual"></Scrolling>
                  </DataGrid>
                </div>


              </div>


            </div>
          </div>
          <Popup
            minWidth={'80%'}
            minHeight={'80%'}
            visible={this.state.showAddDialog}
            title={`Форм`}
            onHiding={() => this.onDialogHidden()}
            resizeEnabled={true}
            dragEnabled={true}
            showTitle={true}
            hideOnOutsideClick={true}
            >
            <ScrollView width='100%' height='100%'>
              <div>
                {this.state.showAddDialog && <OrgFormBuilder isEdit={this.state.isEdit} data={this.state.editData}/>}
              </div>
            </ScrollView>
          </Popup>
        </div>
      </div>
    )
  }
}

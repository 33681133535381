import React, {useContext, useEffect, useState, useRef} from 'react';
import 'devextreme-react/text-area';
import {UserContext} from "../../../hooks/UserContext";
import fcsService from "../../../services/api/fcs";
import Button from "devextreme-react/button";
import notify from "devextreme/ui/notify";
import SelectBox from "devextreme-react/select-box";
import DateBox from "devextreme-react/date-box";
import {LoadPanel} from "devextreme-react/load-panel";
import {AiOutlineEye} from 'react-icons/ai'
import {confirm} from "devextreme/ui/dialog";
import {Popup} from "devextreme-react/popup";
import CustorgParent from "../Gl/gledit/custorgParent";
import BB01 from "./report/BB01"

import ReactToPrint from "react-to-print";
import './report/print.css'


import {NumberToString} from "./report/bankp";
import ScrollView from "devextreme-react/scroll-view";
import BB02 from "./report/BB02";
import LoadPanelComponent from '../../../components/LoadPanelComponent';


function Deposit(props) {
    const componentRef = useRef();

    const monthValue = props.monthValue
    const userListPosition = {of: '#usersList'}
    const {user} = useContext(UserContext);
    const [loader, setLoader] = useState(false);
    const [dataFcsChart, setFcsChart] = useState([]);
    const [dataFcsCustOrgn, setFcsCustOrgn] = useState([]);
    const [dataFcsMonth, setFcsMonth] = useState([]);
    const initCustValue = {name: '', orgcode: ''}
    const [custValue, setCustValue] = useState({...initCustValue});
    const [modalVisibleCustOrg, setModalVisibleCustOrg] = useState(false)
    const [selectedBbClass, setSelectedBbClass] = useState([]);
    const [selectedChart, setSelectedChart] = useState([]);
    const [dataFcsBbClass, setFcsBbClass] = useState([]);
    const [selectedSt4New, setSelectedSt4New] = useState([]);
    const [dataFcsSt4New, setFcsSt4New] = useState([]);
    const [docDate, setDocDate] = useState();
    const [descr, setDescr] = useState();
    const [qty, setQty] = useState();
    const [unit, setUnit] = useState(1);
    const [dt, setDt] = useState();
    const [strAmt,setStrAmt]=useState('')
    const [reportShow,setReportShow]=useState(false)
    useEffect(() => {
        fcsBbClassList();
    }, [])
    useEffect(() => {
        changeMonth();
    }, [monthValue])

    useEffect(() => {
        setDocDate(new Date());

    }, []);

  Date.prototype.yyyymmdd = function() {
        var mm = this.getMonth() + 1; // getMonth() is zero-based
        var dd = this.getDate();

        return [this.getFullYear()+'-',
            (mm>9 ? '' : '0') + mm+'-',
            (dd>9 ? '' : '0') + dd
        ].join('');
    };

    const changeMonth = async () => {
        const fcsMonth = await fcsService.getAllFcsMonth('BB');
        setFcsMonth(fcsMonth);
    }

    const click253=()=>{
        let a=NumberToString(11000)
        alert(a)
    }
    const fcsBbClassList = async () => {
        try {

            setLoader(true);
            const fcsChart = await fcsService.getAllFcsChart();
            setFcsChart(fcsChart);
            const fcsCustOrgn = await fcsService.getAllFcsCustOrgn();
            setFcsCustOrgn(fcsCustOrgn);
            const fcsBbClass = await fcsService.getAllFcsBBClass();
            setFcsBbClass(fcsBbClass);
            const fcsSt4New = await fcsService.getAllFcsSt4New('TSB',0,1);
            setFcsSt4New(fcsSt4New);
            const fcsMonth = await fcsService.getAllFcsMonth('BB');
            setFcsMonth(fcsMonth);
            return setLoader(false)
        } catch (e) {
            console.error(e);
        }
    }
    const setBbTran = async () => {
// alert(user.organization.name)

        if (selectedBbClass.length === 0 || selectedBbClass === null) {
            notify("Мөнгөний данс хоосон байна", "warning");
        } else if (docDate === undefined || docDate === null) {
            notify("Огноо хоосон байна", "warning");
        } else if (selectedSt4New.length === 0) {
            notify("Төрөл СТ4 хоосон байна", "warning");
        } else if (custValue.length === 0) {
            notify("Харилцагч хоосон байна", "warning");
        } else if (descr === undefined || descr === '') {
            notify("Гүйлгээний утга хоосон байна", "warning");
        } else if (qty === undefined || qty === '') {
            notify("Тоо хоосон байна", "warning");
        } else if (unit === undefined || unit === '') {
            notify("Ханш хоосон байна", "warning");
        } else if (selectedChart.length === 0) {
            notify("Данс Кт хоосон байна", "warning");
        } else {
            let result = confirm("<i>Та орлогын гүйлгээ хийхдээ итгэлтэй байна уу?</i>", "Баталгаажуулалт");
            result.then(async (dialogResult) => {
                if (dialogResult && selectedBbClass && selectedSt4New && custValue && docDate && descr && qty && unit && dt) {

                    //yyyymm
                    const date = new Date(docDate);//new e.changes[0].data.docdate;
                    const year = date.getFullYear() * 1e4; // 1e4 gives us the the other digits to be filled later, so 20210000.
                    const month = (date.getMonth() + 1) * 100;
                    const yyyymm = year + month // `+ ''` to convert to string from number, 202206
                    const fcsCurrentMonth = dataFcsMonth.map((item) => {
                        let date = item.year + '/' + item.month
                        let datetimenew = new Date(date)
                        let year = datetimenew.getFullYear() * 1e4; // 1e4 gives us the the other digits to be filled later, so 20210000.
                        let month = (datetimenew.getMonth() + 1) * 100;
                        const yyyymm_month = year + month
                        return yyyymm_month
                    });
                    if (yyyymm === fcsCurrentMonth[0]) {
                        setLoader(true);
                        try {
                            let item = {};
                            item.docdate = docDate;
                            item.docno = selectedBbClass.orid + 1;
                            item.org_id = user.org_id;
                            item.created_user_id = user.id;
                            item.bbclass = selectedBbClass.bbkey;
                            item.bankacct = selectedBbClass.bbacct;
                            item.bankname = selectedBbClass.descr;
                            item.descr = descr;
                            item.qty = qty;
                            item.unit = unit;
                            item.dt = dt;
                            item.cr = 0;
                            item.acct = selectedChart.acct;
                            item.cust = custValue.orgcode;
                            item.custname = custValue.name;
                            item.jr = 'BB';
                            item.acct_name = selectedChart.descr;
                            item.st4 = selectedSt4New.code;
                            item.st4_name = selectedSt4New.descr;
                            item.updatedAt = null;
                            selectedBbClass.orid = selectedBbClass.orid + 1;
                            await fcsService.addFcsBbTran(item);
                            await fcsService.editBBClass(selectedBbClass);
                          //  setSelectedChart([]);
                            //setDocDate(null);
                          //  setDescr('');
                            setQty('');
                            setUnit('');
                            setDt('');
                            setStrAmt(NumberToString(0));
                          //  setCustValue([]);
                           // setSelectedSt4New([]);
                          //  setSelectedBbClass([]);
                           // await fcsBbClassList();
                            notify('Амжилттай орлогын гүйлгээ хийгдлэээ', "success", 2000);
                            return setLoader(false)
                        } catch (e) {
                            notify('Шинээр гүйлгээ хийх явцад алдаа гарлаа', "error", 2000);
                            return setLoader(false)
                        }


                    } else {
                        notify('Сонгосон огноо тухайн тайлант он сард биш байна', "error", 2000);
                        return setLoader(false)
                    }

                }
            });
        }
    }
    const onChangeCustValue = async (e) => {
        setCustValue(e);
    }
    const onChangeBBClass = async (e) => {
        setSelectedBbClass(e);
    }
    const onChangeChart = async (e) => {
        setSelectedChart(e);
    }
    const onChangeSt4New = async (e) => {
        setSelectedSt4New(e);
    }
    const onValueChangedDate = (e) => {
        setDocDate(e.value);
    }
    const onChangeDescr = (event) => {
        setDescr(event.target.value);
    }
    const onChangeUnit = (event) => {
        setUnit(event.target.value);
        let debit = qty * event.target.value
        setDt(debit)
        setStrAmt(NumberToString(debit));
    }
    const onChangeQty = (event) => {
        setQty(event.target.value);
        let debit = event.target.value * unit
        setDt(debit);
        setStrAmt(NumberToString(debit));
    }
    const style = {
        text: {
            color: "#2E4765", fontWeight: "700", fontSize: ".875rem"
        },
        box: {
            border: "none", backgroundColor: "rgb(238, 240, 244)", borderRadius: ".5rem", padding: ".25rem .15rem", width: "100%"
        },
        box2: {
            border: "none",
            backgroundColor: "rgb(228, 232, 235)",
            borderRadius: ".5rem",
            padding: ".25rem .4rem",
            width: "100%"
        }
    }
    return (
        <div>
            <LoadPanelComponent
                shadingColor="rgba(0,0,0,0.4)"
                position={userListPosition}
                visible={loader}
                message=''
                showPane={false}
            />
            <div style={{display: 'flex', marginBottom: 10, marginTop: "1.5rem", marginLeft: 10, justifyContent: "space-between", alignItems: "center", }}>
                <span> <div>
                        <span style={{color: "#A3A3A3", marginRight: ".25rem"}}>Тайлант он</span>
                        <span style={{fontWeight: "700"}}> {dataFcsMonth[0] ? dataFcsMonth[0].year : ""} </span>
                        <span style={{ marginRight: ".25rem"}}> сар</span> 
                        <span style={{fontWeight: "700"}}>{ dataFcsMonth[0] ? dataFcsMonth[0].month : ""}</span></div>
                </span>
                <button
                    className='customPrintButton'
                    style={{marginRight: "4.1rem"}}
                    onClick={() => setReportShow(true)}>
                        <AiOutlineEye style={{fontSize: "1.2rem", marginRight: ".25rem"}}/><span>Харах</span>
                </button>
            </div>
            <div className="row" style={{display: 'flex', marginTop: 40}}>
                <div className='col-3' style={{marginLeft: 10, marginRight: 10}}>
                    <div style={{display: 'flex', alignItems: 'center', marginBottom: 10, ...style.text}}>Мөнгөний
                        дансны дугаар
                    </div>
                    <SelectBox
                        items={dataFcsBbClass}
                        value={selectedBbClass}
                        searchEnabled={true}
                        style={style.box}
                        displayExpr={(item) => {
                            return item && `${item.bbacct} - ${item.descr}`
                        }}
                        onValueChanged={(e) => onChangeBBClass(e.value)}
                        placeholder="сонгох"/>
                </div>
                <div className='col-3' style={{marginLeft: 10, marginRight: 10}}>
                    <div style={{display: 'flex', alignItems: 'center', ...style.text}}>Мөнгөний дансны нэр</div>
                    <input value={selectedBbClass && selectedBbClass.descr} type='string' className='law-search-input'
                           style={{marginTop: 10, width: 400, ...style.box2}} disabled={true}/>
                </div>
            </div>
            <div className="row" style={{display: 'flex', marginTop: 10}}>
            <div className='col-3' style={{marginLeft: 10, marginRight: 10}}>
                    <div style={{display: 'flex', alignItems: 'center', marginBottom: 10, ...style.text}}>Огноо:</div>
                    <DateBox value={docDate} type="date" style={{...style.box}} onValueChanged={onValueChangedDate}/>
                </div>
                <div className="col-4" style={{marginLeft: 10, marginRight: 10}}>
                    <div style={{display: 'flex', alignItems: 'center', ...style.text}}>Журнал №</div>
                    <input value={selectedBbClass && selectedBbClass.bbkey} type='string' className='law-search-input'
                           style={{marginTop: 10, ...style.box2}} disabled={true}/>
                </div>
                
                <div className='col-4' style={{marginLeft: 10, marginRight: 10,}}>
                    <div style={{display: 'flex', alignItems: 'center', marginBottom: 10, ...style.text}}>Төрөл СТ4
                    </div>
                    <SelectBox
                        items={dataFcsSt4New}
                        value={selectedSt4New}
                        searchEnabled={true}
                        style={{...style.box}}
                        displayExpr={(item) => {
                            return item && `${item.code} - ${item.descr}`
                        }}
                        onValueChanged={(e) => onChangeSt4New(e.value)}
                        placeholder="сонгох"/>
                </div>
            </div>
            <div className="row" style={{display: 'flex', marginTop: 10}}>
                <div className='col-3' style={{marginRight: 10}}>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: 10,
                        marginLeft: 10, ...style.text
                    }}>Мөнгө тушаагчийн код
                    </div>
                    <input type='string' className='law-search-input'
                           style={{marginLeft: 10, ...style.box}}
                           onClick={(e) => setModalVisibleCustOrg(true)}
                           value={custValue.orgcode}
                    />
                </div>
                <div className='col-4' style={{marginLeft: 20, marginRight: 10}}>
                    <div style={{display: 'flex', alignItems: 'center', ...style.text}}>Мөнгө тушаагчийн нэр</div>
                    <input value={custValue && custValue.name} type='string' className='law-search-input'
                           style={{marginTop: 10, ...style.box2}} disabled={true}/>

                </div>
                <div className='col-4' style={{marginLeft: 10, marginRight: 20}}>
                    <div style={{display: 'flex', alignItems: 'center', ...style.text}}>Орлогын ордер №</div>
                    <input value={selectedBbClass && selectedBbClass.orid + 1} type='string'
                           className='law-search-input'
                           style={{marginTop: 10, ...style.box2}} disabled={true}/>
                </div>
            </div>
            <div className='col-6' style={{marginTop: 10, marginLeft: 10, marginRight: 10}}>
                <div style={{display: 'flex', alignItems: 'center', marginBottom: 10, ...style.text}}>Гүйлгээний утга
                </div>
                <input type='string' className='law-search-input'
                       style={{width: "120%", ...style.box}}
                       value={descr} onChange={onChangeDescr}/>
            </div>
            <div className="row" style={{display: 'flex', marginTop: 10}}>
                <div className='col-3' style={{marginLeft: 10, marginRight: 10}}>
                    <div style={{display: 'flex', alignItems: 'center', ...style.text}}>Тоо</div>
                    <input type='text' className='law-search-input'
                           style={{marginTop: 10, ...style.box}}
                           value={qty} onChange={onChangeQty}/>
                </div>
                <div className='col-4' style={{marginLeft: 10, marginRight: 10}}>
                    <div style={{display: 'flex', alignItems: 'center', ...style.text}}>Ханш</div>
                    <input type='number' className='law-search-input'
                           style={{marginTop: 10, ...style.box}}
                           value={unit} onChange={onChangeUnit}/>
                </div>
                <div className='col-4' style={{marginLeft: 10, marginRight: 10,}}>
                    <div style={{display: 'flex', alignItems: 'center', ...style.text}}>Нийт дүн</div>
                    <input type='number' className='law-search-input'
                           style={{marginTop: 10, ...style.box2}}
                           value={dt} disabled={true}/>
                </div>
            </div>
            <div>
                <Popup
                    width={500}
                    height={500}
                    minWidth={'80%'}
                    maxWidth={'80%'}
                    minHeight={'80%'}
                    visible={modalVisibleCustOrg}
                    title={'Байгууллага хувь хүмүүс сонгох'}
                    onHiding={() => setModalVisibleCustOrg(false)}
                    resizeEnabled={true}
                    dragEnabled={true}
                    showTitle={true}

                >
                    <CustorgParent setCustValue={setCustValue} setModalVisibleCustOrg={setModalVisibleCustOrg}/>

                </Popup>
            </div>
            <div className="row" style={{display: 'flex', marginTop: 10}}>
                <div className='col-3' style={{marginLeft: 10, marginRight: 10}}>
                    <div style={{display: 'flex', alignItems: 'center', marginBottom: 10, ...style.text}}>Данс Кт</div>
                    <SelectBox
                        items={dataFcsChart}
                        value={selectedChart}
                        searchEnabled={true}
                        style={{...style.box}}
                        displayExpr={(item) => {
                            return item && `${item.acct} - ${item.descr}`
                        }}
                        onValueChanged={(e) => onChangeChart(e.value)}
                        placeholder="  сонгох"/>
                </div>
                <div className='col-4' style={{marginLeft: 10, marginRight: 10}}>
                    <div style={{display: 'flex', alignItems: 'center', ...style.text}}>Данс Кт нэр</div>
                    <input value={selectedChart && selectedChart.descr} type='string' className='law-search-input'
                           style={{marginTop: 10, ...style.box2}} disabled={true}/>

                </div>
            </div>
            <div className="row">
                 <div className='col-11'  style={{display: 'flex', justifyContent: 'flex-end', marginTop: 20, marginBottom: 20, marginLeft: "50px"}}>
                 <Button
                    text="Хадгалах"
                    type="default"
                    className="saveButton"
                    onClick={() => setBbTran()}/>
                 </div>
                
            </div>


            <Popup
               width={"75vw"}
                height={"90vh"}
                onHiding={() => setReportShow(false)}
                resizeEnabled={true}
                dragEnabled={true}
                showTitle={true}
                hideOnOutsideClick={true}
                visible={reportShow}
                showCloseButton={true}
                title="">
                <ScrollView width='100%' height='100%'>
                    <div>
                        <BB01 setReportShow={setReportShow}  orgName={user.organization.name} docno={selectedBbClass && selectedBbClass.orid + 1}
                              custName={custValue.name} descr={descr} amt={dt} strAmt={strAmt}
                              docDate={ docDate && docDate.yyyymmdd()}/>
                    </div>
                </ScrollView>
            </Popup>




        </div>
    )
}

export default Deposit;

import React from 'react';
import DataGrid, {
    Column,
    Grouping,
    GroupPanel, Lookup,
    HeaderFilter,
    Selection,
    Button,
    Summary, GroupItem, TotalItem, Editing,
} from 'devextreme-react/data-grid';
import ProposalService from "../../../services/api/proposal";
import {projectStatusIdEditAllowed} from "../static/data";
import {CURRENT_YEAR} from '../../../shared/constants';
import { Popup } from 'devextreme-react/popup';
import {Button as BButton} from 'devextreme-react/button';
import {FilterRow, Scrolling} from "devextreme-react/tree-list";
import notify from "devextreme/ui/notify";
import {confirm} from "devextreme/ui/dialog";
import _ from "lodash";
import ProposalLoanCreate from "./proposalLoanCreate";
import ScrollView from "devextreme-react/scroll-view";
import SelectBox from "devextreme-react/select-box";
import LoadPanelComponent from "../../budget/components/loadPanel";

export default class ProposalLoan extends React.Component {

    constructor(props) {
        super(props);
        this.canEdit = false;
        this.dataGrid = null;
        this.economicCategoryArray = this.props.eza.filter(ez => ez.is_mofa_foreign === 1);
        this.econParentArray = this.economicCategoryArray.filter(el => el.parent_id === null);
        this.state = {
            selectedAgendaId: null,
            loader: false,
            dataSource: [],
            selectedRKeys: [],
            showAddDialog: false,
            agendaId: null,
            eventId: null,
        }
        this.agendaListAll = this.props.agenda;
        this.eventListAll = this.props.event;

        this.onSelectionChanged = this.onSelectionChanged.bind(this);
        this.onToolbarPreparing = this.onToolbarPreparing.bind(this);
        this.deleteDetail = this.deleteDetail.bind(this);
        this.onSelectionFilterChanged = this.onSelectionFilterChanged.bind(this);
        this.onClearButtonClicked = this.onClearButtonClicked.bind(this);
        this.onClickEdit = this.onClickEdit.bind(this);
        this.helperGetAncestor = this.helperGetAncestor.bind(this);
        this.onDialogShow = this.onDialogShow.bind(this);
        this.onDialogHidden = this.onDialogHidden.bind(this);
    }

    componentDidMount(error, errorInfo) {
        this.initVars();
        this.loadData();
    }

    onClearButtonClicked() {
        this.dataGrid.instance.clearSelection();
    }

    async onSelectionFilterChanged({value}) {
        this.selectionChangedBySelectBox = true;

        let prefix = value;
        console.log(value);
        if (prefix !== null && prefix !== undefined) {
            let filteredList = prefix === 0 ? this.state.dataSource : this.state.dataSource.filter(item => item['agenda_id'] === prefix);

            const selectedRowKeys = filteredList.map(it => it.id);
            // const selectedRowsData = await this.dataGrid.instance.getDataByKeys(selectedRowKeys);

            this.setState({
                selectedAgendaId: prefix,
                selectedRKeys: selectedRowKeys,
            });
        }
    }

    async initVars() {
        if (this.props.proposal && projectStatusIdEditAllowed.includes(this.props.proposal.budget_project_status_id))
            this.canEdit = true;
    }
    helperGetAncestor(econId){

        let parentId = this.economicCategoryArray.find(i => i.id === econId)?.parent_id;
        if (parentId === null || parentId === undefined){
            return econId;
        } else {
            return this.helperGetAncestor(parentId);
        }
    }
    helperGetEconName(econId){
        const temp = this.economicCategoryArray.find(i => i.id === econId);
        if (temp){
            return `${temp.code} - ${temp.name}`;
        }
        return 'Ангилагдаагүй';
    }
    async loadData() {
        // console.log(this);
        this.setState({
            loader: true
        });
        // console.log(props.proposal);
        const {agenda, event} = this.props;
        // console.log(agenda, event, eza)
        // const r = await RefService.getRefEconomicCategory();

        // this.agendaListAll = agenda;
        // this.eventListAll = event;

        const result = await ProposalService.getProposalDetailByProjectId(this.props.proposal.id);
        // console.log('listDetailByOrgId', result);
        const tt = [];
        result.data.forEach(item => {
            item['Хөтөлбөр'] = agenda.find(i => i.id === item.agenda_id)?.name
            item['Зориулалт'] = event.find(i => i.id === item.event_id)?.name
            item['econ_category_parent_id'] = this.helperGetAncestor(item.econ_category_id);
            item['econ_name'] = this.helperGetEconName(item.econ_category_id);

            if (!tt.find(el => el.id === item.agenda_id)) {
                tt.push({name: item['Хөтөлбөр'], id: item.agenda_id});
            }
        });
        result.data = _.sortBy(result.data, ['econ_name'], 'asc');

        const r2 = await ProposalService.listVBudgetCompareOrg(this.props.year, this.props.proposal.org_id);
        // helperConvertProposals(r2.data, result.data);
        this.helperConvertHistory(r2.data, result.data);

        this.setState({
            loader: false,
            dataSource: result.data,
            agendaFiltered: tt,
        });
    }

    helperConvertHistory(budgetHistory, currentDetail) {

        const historyGrouped = _.groupBy(budgetHistory, 'agenda_id');
        const currentGrouped = _.groupBy(currentDetail, 'agenda_id')

        const currentAgendaIds = Object.keys(currentGrouped);

        // groupd by agenda ids
        currentAgendaIds.forEach(agendaId => {
            // console.log(currentGrouped[agendaId]);

            if (historyGrouped[agendaId]){
                const currentEventIdGrouped = _.groupBy(currentGrouped[agendaId], 'event_id');
                const curEventIds = Object.keys(currentEventIdGrouped);
                const historyEventIdGrouped = _.groupBy(historyGrouped[agendaId], 'event_id');

                // grouped by event ids
                curEventIds.forEach(eventId => {
                    if (historyEventIdGrouped[eventId]) {
                        this.helperConvertHistoryEcon(historyEventIdGrouped[eventId], currentEventIdGrouped[eventId] )

                    }
                })
            }
        })
    }
    helperConvertHistoryEcon (historyList, curList ){
        // console.log(historyList, curList);
        const historyEconGrouped = _.groupBy(historyList, 'econ_category_id');

        curList.forEach(item => {
            // item.
            if (historyEconGrouped[item.econ_category_id]){
                historyEconGrouped[item.econ_category_id].forEach(his => {
                    item[`year${CURRENT_YEAR - his.budget_year}`] = his.amount;
                })
            }
        })

    }
    /*

      const groupCellTemplate = (groupCell, data) => {
        console.log(groupCell,data);
        //groupCell
        const formattedValue = data.column.caption + ": " + data.displayValue + " new format";
        groupCell.append(`${formattedValue}`);
        const html = `<div>${groupCell}</div>`;
        console.log(groupCell);
        console.log(groupCell.innerHTML);
        console.log(html);
        groupCell = html;
        return (<div>
          html
        </div>);

        // groupCell += `<div
        //   className="dx-select-checkbox dx-datagrid-checkbox-size dx-show-invalid-badge dx-checkbox dx-widget"
        //   role="checkbox" aria-checked="false" tabIndex="0"><input type="hidden" value="false">
        //   <div className="dx-checkbox-container"><span className="dx-checkbox-icon"></span></div></div>`
      }*/

    GroupCell(e) {
        return (
            <div>
                <div>{e.data.displayValue}</div>
            </div>
        );
    }
    onClickEdit(e) {
        this.setState({
            showAddDialog: true,
            agendaId: e.row.data.agenda_id,
            eventId: e.row.data.event_id
        });
    };

    onToolbarPreparing(e) {
        // console.log(e);
        //
        // const temp = {
        //   locateInMenu: "auto",
        //   location: "after",
        //   name: "deleteAll",
        //   options: {icon: "edit-button-save", disabled: true, text: "Хадгалах",},
        //   showText: "always",
        //   sortIndex: 21,
        //   widget: "dxButton",
        // }
        // if ( e.toolbarOptions.items && e.toolbarOptions.items.length > 0 ){
        //   const button = {...temp};
        //   button.options = {... e.toolbarOptions.items[0].options};
        //   button.options.text = `Устгах`;
        //   button.options.hint = `Бүх сонголтуудыг устгах`;
        //   button.options.disabled = false;
        //   button.options.icon = 'trash';
        //   button.options.onClick = this.deleteDetail;
        //   e.toolbarOptions.items.push(button);
        //
        // }
    };

    async deleteDetail(e) {
        // console.log('DEL', this.state.selectedRKeys);
        if (!this.state.selectedRKeys || !this.state.selectedRKeys.length) {
            notify('Устгах мэдээлэл сонгоно уу !', 'warning');
            return;
        }
        let result = confirm(`<i>Нийт <b>${this.state.selectedRKeys.length}</b> сонгосон мэдээллийг устгах уу?</i>`, "Баталгаажуулалт",);
        result.then(async (dialogResult) => {
            if (dialogResult) {
                try {
                    const res = await ProposalService.deleteDetailBulk(this.state.selectedRKeys);
                    if (res.success) {
                        notify(`${res.message}`, 'success',);
                        // maybe refresh data or smth.
                        this.loadData();
                    } else {
                        notify(`${res.message}`, 'error');
                    }
                } catch (e) {
                    console.log(e);
                    notify(`Алдаа гарлаа`, 'error');
                }
            }
        })

    }

    onDialogShow () {
        this.setState({
            showAddDialog: true
        });
    }

    onDialogHidden () {
        this.setState({
            showAddDialog: false,
            agendaId: null,
            eventId: null
        });
    }

    render() {
        const {selectedRKeys} = this.state;
        return (
            <div>
                <LoadPanelComponent position={{of: 'datagrid'}} visible={this.state.loader} />

                {this.canEdit && <div>
                    <BButton
                        iconStyle={{color: '#FFF'}}
                        style={{marginRight: 10, backgroundColor: '#1453B5', color: '#FFF'}}
                        onClick={this.onDialogShow}
                        stylingMode="contained"
                        icon="plus"
                        type="danger"
                        text="Нэмэх"
                    />
                    <SelectBox
                        width={150}
                        id="select-prefix"
                        style={{marginRight: 10, display: 'inline-block', verticalAlign: 'middle'}}
                        dataSource={this.state.agendaFiltered}
                        onValueChanged={this.onSelectionFilterChanged}
                        placeholder="Хөтөлбөр сонгоно уу"
                        value={this.state.selectedAgendaId}
                        valueExpr="id"
                        displayExpr="name"
                    />
                    <BButton
                        disabled={!selectedRKeys.length}
                        onClick={this.onClearButtonClicked}
                        icon="refresh"
                        style={{marginRight: 10}}

                        text="Сонголтыг цуцлах"
                    />
                    <BButton
                        //style={{backgroundColor: selectedRKeys && selectedRKeys.length ? 'red': 'transparent', color: selectedRKeys && selectedRKeys.length ? 'white': 'inherit'}}
                        disabled={!selectedRKeys.length}
                        onClick={this.deleteDetail}
                        stylingMode="contained"
                        icon="trash"
                        type="danger"
                        text="Сонголтыг устгах"
                    />
                </div>}

                {this.state.showAddDialog && <Popup
                    width={550}
                    height={350}
                    minWidth={'50%'}
                    maxWidth={'100%'}
                    minHeight={'100%'}
                    visible={this.state.showAddDialog}
                    title={this.props.titleName}
                    onShowing={() => console.log('onShowing')}
                    onShown={() => console.log('onShown')}
                    onHiding={() => this.onDialogHidden()}
                    resizeEnabled={true}
                    dragEnabled={true}
                    showTitle={true}
                    hideOnOutsideClick={true}>
                    <ScrollView width='100%' height='100%'>
                        <ProposalLoanCreate agendaId={this.state.agendaId} eventId={this.state.eventId} proposal={this.props.proposal} onSavedCallBack={(e)=>{this.setState({ showAddDialog: false }); this.onDialogHidden();}}/>
                    </ScrollView>

                </Popup>}

                <DataGrid
                    id="datagrid"
                    height={'60vh'}
                    minHeight={'500'}
                    ref={ref => this.dataGrid = ref}
                    dataSource={this.state.dataSource}
                    allowColumnReordering={true}
                    rowAlternationEnabled={true}
                    onSelectionChanged={this.onSelectionChanged}
                    selectedRowKeys={selectedRKeys}
                    keyExpr="id"
                    columnAutoWidth={false}
                    showBorders={true}
                >
                    <Selection mode="multiple"/>
                    <HeaderFilter visible={true}/>
                    <Selection mode="multiple"/>
                    <GroupPanel visible={true} emptyPanelText={'Энд багана чирж оруулна уу'}/>

                    {/*<SearchPanel visible={true} highlightCaseSensitive={true}/>*/}
                    <Grouping autoExpandAll={true} contextMenuEnabled={false} expandMode='rowClick'/>

                    <Column dataField="Хөтөлбөр" groupIndex={0}
                        // groupCellComponent={GroupCell}
                    >
                        {/*<Lookup dataSource={agendaListAll} valueExpr="id" displayExpr="name" />*/}
                    </Column>
                    <Column dataField="Зориулалт" groupIndex={1}>
                        {/*<Lookup dataSource={eventListAll} valueExpr="id" displayExpr="name" />*/}
                    </Column>
                    <Column
                        dataField="econ_category_parent_id"
                        groupIndex={2}
                        caption="ЭЗА"
                        groupCellComponent={this.GroupCell}
                        dataType="text"
                    >
                        <Lookup dataSource={this.econParentArray} valueExpr="id" displayExpr="name"/>
                    </Column>

                    <Column
                        dataField="econ_name"
                        width={300}
                        caption="Эдийн засгийн ангилал"
                        dataType="text"
                        allowGrouping={false}
                    >
                        {/*<Lookup dataSource={this.economicCategoryArray} valueExpr="id" displayExpr="name"/>*/}
                    </Column>

                    <Column caption="Гадаад зээл/тусламжаас санхүүжих">
                        <Column
                            dataField="base_cur_amount"
                            width={180}
                            caption="Үндсэн валют"
                            dataType="number"
                            allowGrouping={false}
                            format="#,##0.00"
                        />
                        <Column
                            dataField="amount"
                            width={180}
                            caption="Төгрөг"
                            dataType="number"
                            allowGrouping={false}
                            format="#,##0.00"
                        />
                    </Column>

                    <Column
                        dataField="state_budget_amount"
                        width={240}
                        caption="Улсын төсвөөс санхүүжих /Төгрөг/"
                        format="#,##0.00"
                        dataType="number"
                        allowGrouping={false}
                        allowEditing={false}
                    />

                    <Column caption="Гадаад зээл, тусламжийн эх үүсвэрээр хэрэгжих төслийн Монголын талын санхүүжилт" >
                        <Column
                            dataField="base_cur_amount_mn"
                            caption="Үндсэн валют"
                            dataType="number"
                            allowGrouping={false}
                            width={180}
                            format="#,##0.00"
                        />
                        <Column
                            dataField="amount_mn"
                            caption="Төгрөг"
                            dataType="number"
                            allowGrouping={false}
                            width={180}
                            // format="#,##0.00"
                            format="#,##0.00"
                        />
                    </Column>

                    {/*<Column*/}
                    {/*    dataField="year0"*/}
                    {/*    caption={`${CURRENT_YEAR} оны төлөвлөгөө`}*/}
                    {/*    format="#,##0.00"*/}
                    {/*    allowGrouping={false}*/}
                    {/*    allowEditing={false}*/}
                    {/*/>*/}
                    {/*<Column dataField="amount" caption="2022 оны төсвийн санал" alignment="right" dataType="number" minWidth={200}*/}
                    {/*        allowGrouping={false}*/}
                    {/*        format="#,##0.00 ₮"/>*/}

                    {this.canEdit &&
                    <Editing
                        mode="row"
                        allowUpdating={true}
                        allowAdding={false}
                        allowDeleting={true}
                        confirmDelete={true}
                        useIcons={true}
                        texts={{
                            addRow: 'Төсөл нэмэх',
                            cancelAllChanges: 'Болих бүгд',
                            cancelRowChanges: 'Болих',
                            confirmDeleteMessage: 'Энэ мэдээллийг устгах уу?',
                            confirmDeleteTitle: 'Баталгаажуулах',
                            deleteRow: 'Устгах',
                            editRow: 'Өөрчлөх',
                            saveAllChanges: 'Хадгалах бүгд',
                            saveRowChanges: 'Хадгалах',
                            undeleteRow: 'Буцаах',
                            validationCancelChanges: 'Баталгаажуулах болих',
                        }}
                    />}
                    <Column type="buttons" width={110}>
                        <Button name={'edit'} text={this.canEdit ? '' : 'Дэлгэрэнгүй'} hint="Засварлах" visible={true}
                                onClick={this.onClickEdit}/>
                    </Column>
                    <Summary>
                        <TotalItem
                            column="econ_category_id"
                            summaryType="count"
                            showInColumn="econ_category_id"
                            displayFormat="{0} төсөл"
                        />
                        <TotalItem
                            column="OrderDate"
                            summaryType="min"/>

                        <TotalItem
                            column="base_cur_amount"
                            summaryType="sum"
                            displayFormat="Нийт: {0}"
                            valueFormat="#,##0.00"/>

                        <TotalItem
                            column="amount"
                            summaryType="sum"
                            displayFormat="Нийт: {0}"
                            valueFormat="#,##0.00"/>

                        <TotalItem
                            column="state_budget_amount"
                            summaryType="sum"
                            displayFormat="Нийт: {0}"
                            valueFormat="#,##0.00"/>

                        <TotalItem
                            column="base_cur_amount_mn"
                            summaryType="sum"
                            displayFormat="Нийт: {0}"
                            valueFormat="#,##0.00"/>

                        <TotalItem
                            column="amount_mn"
                            summaryType="sum"
                            displayFormat="Нийт: {0}"
                            valueFormat="#,##0.00"/>

                        <GroupItem
                            column="agenda_id"
                            summaryType="count"
                            displayFormat="{0} мэдээлэл"/>
                        <GroupItem
                            column="amount"
                            summaryType="sum"
                            valueFormat="#,##0.00"
                            showInGroupFooter={false}
                            displayFormat="{0}"
                            alignByColumn={true}/>

                        <GroupItem
                            column="amount"
                            summaryType="sum"
                            valueFormat="#,##0.00"
                            displayFormat="{0}"
                            showInGroupFooter={true}/>
                    </Summary>

                    <Scrolling mode='virtual'/>
                </DataGrid>

            </div>

        );
    }

    onSelectionChanged(e) {
        console.log(e);
        const {selectedRowKeys, selectedRowsData} = e;
        console.log(selectedRowKeys, selectedRowsData);
        // testRef.current = selectedRowKeys;
        this.setState({
            selectedRKeys: selectedRowKeys,
        })
        // setSelectedRowKeys(selectedRowKeys);
        // console.log(testRef.current)

    };
}

import React, {useContext, useEffect, useState, useRef} from 'react';

import 'devextreme-react/text-area';
import 'devextreme-react/text-area';
import {
    TreeList, Editing, Column, Lookup, Form, Item, Popup,RowDragging
} from 'devextreme-react/tree-list';
import notify from 'devextreme/ui/notify';
import {UserContext} from "../../hooks/UserContext";
import StateStructureServices from "../../services/api/stateStructure";
import _ from "lodash";
import LoadPanelComponent from '../../components/LoadPanelComponent';
import OrganizationEditCellComponent from "../../components/OrganizationEditCellComponent";

const statusData = [
    {
        "id": 0,
        "name": "Идэвхгүй"
    },
    {
        "id": 1,
        "name": "Идэвхтэй"
    }];

export default function StateStructure() {
    const {user} = useContext(UserContext);
    const [dataSource, setDataSource] = useState([]);
    const [loader, setLoader] = useState(false);
    const [orgList, setOrgList] = useState([]);
    const [sstData, setSstData] = useState([]);
    const [lookUpList, setLookUpList] = useState([]);
    const myBabyRef = useRef(null);
    useEffect(() => {
        getList();
    }, [])

    const getList = async () => {
        try {
            setLoader(true);
            let result = await StateStructureServices.getStateStructure();
            let r = await _.filter(result.data, {parent_id: null});
            let r1 = await StateStructureServices.getOrgList();
            let r2 = await StateStructureServices.getStateStructureType();
            setOrgList(r1.data);
            setSstData(r2.data);
            setLookUpList(r);
            setDataSource(result.data);
            return setLoader(false)
        } catch (e) {
            console.error(e);
        }
    }
    const setData = async (e) => {
        if (e.changes && e.changes.length !== 0) {
            if (e.changes[0].type === "update") {
                try {
                    e.changes[0].data.name = e.changes[0].data.state_structure_name;
                    await StateStructureServices.editStateStructure(e.changes[0].data);
                    await getList();
                    notify('Амжилттай хадгалагдлаа', "success", 2000);
                } catch (e) {
                    notify('Засварлалтыг хадгалах явцад алдаа гарлаа', "error", 2000);
                }
            } else if (e.changes[0].type === "insert") {
                try {
                    delete e.changes[0].data.id;
                    e.changes[0].data.name = e.changes[0].data.state_structure_name;
                    await StateStructureServices.addStateStructure(e.changes[0].data);
                    await getList();
                    notify('Амжилттай үүслээ', "success", 2000);
                } catch (e) {
                    notify('Шинээр үүсэх явцад алдаа гарлаа', "error", 2000);
                }

            }
        }

    }
    //Column custom renderings
    const statusRender = (data) => {
        return (data.value === 1 ? <div style={{
            backgroundColor: '#50CB93',
            width: '80%',
            color: 'white',
            borderRadius: '3px',
            cursor: 'pointer',
            margin: 'auto',
            textAlign: 'center'
        }}>Идэвхтэй</div> : <div style={{
            backgroundColor: '#FF616D',
            width: '80%',
            color: 'white',
            borderRadius: '3px',
            cursor: 'pointer',
            margin: 'auto',
            textAlign: 'center'
        }}>Идэвхгүй</div>)
    }
    const onEditorPreparing = (e) => {
        if (e.parentType === "dataRow") {
            return;
        }
        if (e.row && e.row.isNewRow) {
            e.editorOptions.visible = true;
        }
        if (e.row && !e.row.isNewRow) {
            e.editorOptions.visible = false;
        }
    }
    const initNewRowEvent = (e) => {
        let r;
        if (e.data.parent_id === 0) {
            r = [];
        } else {
            r = _.filter(dataSource, {id: e.data.parent_id});
        }
        setLookUpList(r);
    }

    const onEditingStartEvent = (e) => {
        let r;
        if (e.data.parent_id) {
            r = _.filter(dataSource, {id: e.data.parent_id});
        }
        setLookUpList(r);
    }
    const onReorder = async (e)=> {
        const visibleRows = e.component.getVisibleRows();
        let sourceData = e.itemData;
        if (e.dropInsideItem) {
            sourceData = { ...sourceData, parent_id: visibleRows[e.toIndex].key };
            await StateStructureServices.editStateStructure(sourceData);
            await getList();
            notify('Амжилттай хадгалагдлаа', "success", 2000);

        }
    }
    const onDragChange = (e)=> {
        const visibleRows = e.component.getVisibleRows();
        const sourceNode = e.component.getNodeByKey(e.itemData.id);
        let targetNode = visibleRows[e.toIndex].node;

        while (targetNode && targetNode.data) {
            if (targetNode.data.id === sourceNode.data.id) {
                e.cancel = true;
                break;
            }
            targetNode = targetNode.parent;
        }
    }
    const onCellPreparedHandler = (e) => {
        if (e.rowType === 'header') {
            e.cellElement.style.color = "black"
            e.cellElement.style.backgroundColor = "#E8E8E8"
            // elem.style.setProperty('color', 'green');
        }
        if (e.rowType === 'data') {
            e.cellElement.style.color = "black"
            // elem.style.setProperty('color', 'green');
        }
    }
    return (
        <div>
            <LoadPanelComponent
                shadingColor="rgba(0,0,0,0.4)"
                position={{of: '#password'}}
                visible={loader}
                message=''
                showPane={false}
            />
            <div>
                <div className="card-body">
                    <TreeList
                        dataSource={dataSource}
                        keyExpr="id"
                        parentIdExpr="parent_id"
                        rootValue={null}
                        onEditorPreparing={onEditorPreparing}
                        ref={myBabyRef}
                        onSaved={setData}
                        onEditingStart={onEditingStartEvent}
                        onInitNewRow={initNewRowEvent}
                        showBorders={true}
                        showColumnLines={true}
                        showRowLines={true}
                        onCellPrepared={onCellPreparedHandler}
                    >
                        <RowDragging
                            onDragChange={onDragChange}
                            onReorder={onReorder}
                            allowDropInsideItem={true}
                            allowReordering={true}
                            showDragIcons={true}
                        />
                        <Editing
                            mode="popup"
                            allowUpdating={true}
                            // allowAdding={true}
                            allowDeleting={false}
                            confirmDelete={true}
                            useIcons={true}
                            confirmSave={true}
                            allowAdding={true}
                            texts={{
                                cancelAllChanges: 'Болих бүгд',
                                cancelRowChanges: 'Болих',
                                confirmDeleteMessage: 'Энэ төрийн байгууллагын мэдээллийг устгах уу?',
                                confirmDeleteTitle: 'Баталгаажуулах',
                                deleteRow: 'Устгах',
                                editRow: 'Өөрчлөх',
                                saveAllChanges: 'Хадгалах бүгд',
                                saveRowChanges: 'Хадгалах',
                                undeleteRow: 'Буцаах',
                                validationCancelChanges: 'Баталгаажуулах болих',
                            }}>
                            <Popup title="Төрийн байгууллагын мэдээлэл" showTitle={true} width={800} height={400}/>
                            <Form>
                                <Item itemType="group" colCount={1} colSpan={2}>
                                    <Item dataField="parent_id"/>
                                    <Item dataField="state_structure_name"/>
                                    <Item dataField="org_id"/>
                                    <Item dataField="state_str_type_id"/>
                                    <Item dataField="status"/>
                                </Item>
                            </Form>
                        </Editing>
                        {/*<FilterRow*/}
                        {/*    visible = {true}*/}
                        {/*/>*/}
                        {/*<Pager*/}
                        {/*    showPageSizeSelector={true}*/}
                        {/*    // showInfo={true}*/}
                        {/*    showNavigationButtons={true}*/}
                        {/*/>*/}

                        <Column
                            dataField="state_structure_name"
                            caption="Нэр"
                            allowEditing={true}
                            // editCellTemplate = {}
                            //   cellRender = {dateRender}
                            cssClass="custom"
                            allowFiltering={true}
                            width={550}
                        >
                            {/*<RequiredRule*/}
                            {/*    message = 'Төсөл хөтөлбөр нэр оруулна уу'*/}
                            {/*/>*/}
                        </Column>

                        <Column
                            dataField="parent_id"
                            caption="Төрийн байгууллага" visible={false}>
                            <Lookup
                                allowClearing={true}
                                dataSource={lookUpList}
                                valueExpr="id"
                                displayExpr="state_structure_name"/>
                        </Column>
                        <Column
                            dataField="org_id"
                            caption="Байгууллага"
                            // width={500}
                            allowFiltering={true}
                            allowUpdating={true}
                            visible={true}
                            editCellComponent={OrganizationEditCellComponent}
                        >
                            <Lookup
                                valueExpr="id"
                                displayExpr={(item) => {
                                    return item && `${item.aimag_name} - ${item.organization_name}`
                                }}
                                dataSource={orgList}

                            />
                        </Column>
                        <Column
                            dataField="state_str_type_id"
                            caption="Харьяа нэгжийн бүтэц төрөл" visible={false}>
                            <Lookup
                                allowClearing={true}
                                dataSource={sstData}
                                valueExpr="id"
                                displayExpr="name"/>
                        </Column>
                        <Column
                            dataField="status"
                            caption="Төлөв" visible={false}>
                            <Lookup
                                allowClearing={true}
                                dataSource={statusData}
                                valueExpr="id"
                                displayExpr="name"/>
                        </Column>
                        <Column
                            cssClass="custom"
                            dataField="status"
                            caption="Төлөв"
                            allowEditing={true}
                            cellRender={statusRender}
                            allowFiltering={true}
                            width={100}>

                        </Column>
                        {/*<Paging enabled={true} />*/}
                    </TreeList>
                </div>
            </div>

        </div>
    )
}


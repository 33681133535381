import React from 'react'
import Tabs, {Item} from 'devextreme-react/tabs';
import Deposit from './Deposit';
import { useState } from 'react';
import Withdraw from './Withdraw';

function DepositPopup({setPopup, children, title}) {
    const [selectedIndex, setSelectedIndex]= useState(0)
    const incomeRender= (index, item)=>{
        return (
            <div>
                {index=== 0 ? 
                <svg id="chat-arrow-grow" xmlns="http://www.w3.org/2000/svg" width="13.717" height="13.703" viewBox="0 0 13.717 13.703">
<path id="Path_34428" data-name="Path 34428" d="M15,3.976H13.232a.572.572,0,0,0-.4.976l.621.621h0L11.4,7.621a.585.585,0,0,1-.809,0l-.1-.1a1.755,1.755,0,0,0-2.425,0L5.154,10.438a.573.573,0,1,0,.812.81L8.88,8.333a.585.585,0,0,1,.809,0l.1.1a1.756,1.756,0,0,0,2.425,0l2.05-2.05h0L14.882,7a.572.572,0,0,0,.972-.405V4.833A.857.857,0,0,0,15,3.976Z" transform="translate(-2.136 -1.704)" fill="#44cf69"/>
<path id="Path_34429" data-name="Path 34429" d="M13.145,12.56H2.858a1.715,1.715,0,0,1-1.715-1.715V.572A.572.572,0,1,0,0,.572V10.845A2.861,2.861,0,0,0,2.858,13.7H13.145a.572.572,0,0,0,0-1.143Z" fill="#5558d9"/>
</svg>
                 :  <svg id="chat-arrow-down" xmlns="http://www.w3.org/2000/svg" width="13.717" height="13.703" viewBox="0 0 13.717 13.703">
<g id="Group_41895" data-name="Group 41895">
  <path id="Path_34426" data-name="Path 34426" d="M14.889,9.4l-.621.621h0l-2.049-2.05a1.756,1.756,0,0,0-2.424,0l-.1.1a.585.585,0,0,1-.808,0l-2.9-2.9a.571.571,0,1,0-.808.808l2.9,2.9a1.756,1.756,0,0,0,2.424,0l.1-.1a.585.585,0,0,1,.808,0l2.05,2.053h0l-.621.621a.572.572,0,0,0,.4.976h1.764a.857.857,0,0,0,.857-.857V9.8a.572.572,0,0,0-.976-.4Z" transform="translate(-2.148 -2.143)" fill="#ee1726"/>
  <path id="Path_34427" data-name="Path 34427" d="M13.145,12.56H2.858a1.715,1.715,0,0,1-1.715-1.715V.572A.572.572,0,1,0,0,.572V10.845A2.861,2.861,0,0,0,2.858,13.7H13.145a.572.572,0,0,0,0-1.143Z" fill="#5558d9"/>
</g>
</svg>}
                <span style={{marginLeft: "1rem", fontSize: "1rem", textTransform: "uppercase", fontWeight: 600}}>{item}</span>
            </div>
        )
    }
    const onClickHandler= (e)=>{
        setSelectedIndex(e.itemIndex)
    }
  return (
    <div>
        <div style={{display: "flex", justifyContent: "space-between"}}>
            <div/>
            <div style={{width: "40%"}}>
                <Tabs selectedIndex={selectedIndex} className='depositPopup' onItemClick={onClickHandler}>
                    {title.map((item, index)=>(
                        <Item  render={()=>incomeRender(index, item)}/>
                    ))}
                </Tabs>
            </div>
        <button onClick={()=> setPopup(false)} className="customCloseButton"><span>x</span></button>
        </div>
        
        {selectedIndex === 0? children[0]: children[1]}
    </div>
  )
}

export default DepositPopup
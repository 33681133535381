import React, {useState, useEffect} from "react";
import FileManager, {Permissions} from 'devextreme-react/file-manager';
import notify from "devextreme/ui/notify";
import FileServices from "../services/api/file";
import CustomFileSystemProvider from "devextreme/file_management/custom_provider";

const FiManager = () => {

    const getItems = async (e) => {
        try {
            return await FileServices.retrieveFileSystemByDir(e.relativeName);
        } catch (e) {
            notify(e.message, 'error', 2000)
        }
    }

    let fileSystemProvider = new CustomFileSystemProvider({
        getItems
    })

    useEffect(() => {
    }, [])

    return (
        <div>
            <FileManager fileSystemProvider={fileSystemProvider}>
                <Permissions
                    create={true}
                    copy={true}
                    move={true}
                    delete={true}
                    rename={true}
                    upload={true}
                    download={true}
                >
                </Permissions>
            </FileManager>
        </div>
    )
}

export default FiManager;
import React from 'react';
import DataGrid, {
    Column,
    Paging,
    Scrolling,
    Selection,
} from 'devextreme-react/data-grid';
import DropDownBox from 'devextreme-react/drop-down-box';
import _ from "lodash";
const dropDownOptions = { width: 800 };

export default class DropDownBoxComponent extends React.Component {
    constructor(props) {
        super(props);
        console.log('this.props.data ',this.props.data);
        this.state = {
            selectedRowKeys: [props.data.value],
            isDropDownOpened: false,
            dataSource: _.filter(this.props.data.column.lookup.dataSource, item => {
                return item.loctn_id !== null
            })

        };
        this.onSelectionChanged = this.onSelectionChanged.bind(this);
        this.contentRender = this.contentRender.bind(this);
        this.boxOptionChanged = this.boxOptionChanged.bind(this);
    }

    boxOptionChanged(e) {
        if (e.name === 'opened') {
            this.setState({
                isDropDownOpened: e.value,
            });
        }
    }
    componentDidMount() {
        this.setState({
            dataSource: _.filter(this.props.data.column.lookup.dataSource, item => {
                return item.loctn_id !== null
            })
        });
    }
    contentRender() {
        return (
            <React.Fragment>
            <DataGrid
                dataSource={this.state.dataSource}
                //remoteOperations={true}
                height={250}
                selectedRowKeys={this.state.selectedRowKeys}
                hoverStateEnabled={true}
                onSelectionChanged={this.onSelectionChanged}
             //   focusedRowEnabled={true}
             //   defaultFocusedRowKey={this.state.selectedRowKeys[0].inv_id}
            >
                <Column dataField="loctn_name" caption="Агуулахын нэр"/>
                <Column dataField="inv_code" caption="Барааны код"/>
                <Column dataField="inv_name" caption="Барааны нэр"/>
                <Column dataField="last_qty" caption="Тоо"/>
                <Column dataField="last_avg_unit" caption="Нэгж үнэ"/>
                <Paging enabled={true} defaultPageSize={10} />
                <Scrolling mode="virtual" />
                <Selection mode="single" />
            </DataGrid>
            </React.Fragment>
        );
    }

    onSelectionChanged(selectionChangedArgs) {
        this.setState({
            selectedRowKeys: selectionChangedArgs.selectedRowKeys,
            isDropDownOpened: false,
        });
        this.props.data.setValue(this.state.selectedRowKeys[0]);
        //console.log(' selectedRowKeys  ',this.state.selectedRowKeys);
    }

    render() {
        return (
            <DropDownBox
                onOptionChanged={this.boxOptionChanged}
                opened={this.state.isDropDownOpened}
                dropDownOptions={dropDownOptions}
                dataSource={this.state.dataSource}
                value={this.state.selectedRowKeys[0]}
                valueExpr="inv_id"
                displayExpr="inv_name"
                contentRender={this.contentRender}>
            </DropDownBox>
        );
    }
}

import React, {useState, useEffect} from "react";
import FinanceReportService from "../../../../services/api/financeReport";
import notify from "devextreme/ui/notify";
import {numberWithCommas,palettePayrollReport} from "../../../../util";
import {useHistory} from "react-router-dom";
import LoadPanelComponent from "../../../budget/components/loadPanel";

const PayrollReportHeaderInfo = (props) => {
    const history = useHistory();
    const [horizontalData, setHorizontalData] = useState({});
    const [loader, setLoader] = useState(false);
    const loadHorizontalData = async () => {
        try {
            setLoader(true);
            let r = await FinanceReportService.getHeaderMiniDataPayroll(props.year,props.selectedOrg, props.filterOrgText,props.selectedMonth);
            setHorizontalData(r[0]);
            setLoader(false);
        } catch (e) {
            setLoader(false);
            notify(e.message, 'error', 2000);
        }
    }

    const locationHandler = () => {
        if (props.locationZarlaga) {
            history.push(props.locationZarlaga)
        }
    }

    useEffect(() => {
        loadHorizontalData();
    }, [props.year, props.filterOrgText, props.selectedOrg, props.selectedMonth]);


    return (
        <div id="load5" className="row" style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly',flex:1}}>
            <LoadPanelComponent position={{of: '#load5'}} visible={loader}/>

            {horizontalData.basic_salary !==undefined && horizontalData.basic_salary !== 0 && <div className="card" style={{
                flex: 1,
                backgroundImage: `linear-gradient(to right, ${palettePayrollReport[0]}, ${palettePayrollReport[1]})`,
                boxShadow: `0 0 5px ${palettePayrollReport[0]}`
            }} onClick={locationHandler}>
                <div className="card-header" style={econ2Styles.textAligner}><span
                    style={{color: '#fff', textAlign: "center"}}>Үндсэн цалин</span></div>
                <div className="card-body"
                     style={{...econ2Styles.textAlignerSpan, ...econ2Styles.numericFontSize}}><span
                    style={{color: '#fff'}}>{numberWithCommas(horizontalData.basic_salary)}</span>
                </div>
            </div>
            }
            {horizontalData.estimated_salary !==undefined && horizontalData.estimated_salary !== 0 && <div className="card" style={{
                flex: 1,
                backgroundImage: `linear-gradient(to right, ${palettePayrollReport[0]}, ${palettePayrollReport[1]})`,
                boxShadow: `0 0 5px ${palettePayrollReport[0]}`
            }} onClick={locationHandler}>
                <div className="card-header" style={econ2Styles.textAligner}><span
                    style={{color: '#fff', textAlign: "center"}}>Бодогдсон цалин</span></div>
                <div className="card-body"
                     style={{...econ2Styles.textAlignerSpan, ...econ2Styles.numericFontSize}}><span
                    style={{color: '#fff'}}>{numberWithCommas(horizontalData.estimated_salary)}</span>
                </div>
            </div>
            }

            {horizontalData.total_salary !==undefined && horizontalData.total_salary !== 0 && <div className="card" style={{
                flex: 1,
                backgroundImage: `linear-gradient(to right, ${palettePayrollReport[0]}, ${palettePayrollReport[1]})`,
                boxShadow: `0 0 5px ${palettePayrollReport[0]}`
            }} onClick={locationHandler}>
                <div className="card-header" style={econ2Styles.textAligner}><span
                    style={{color: '#fff', textAlign: "center"}}>Нийт цалин нэмэгдэл</span></div>
                <div className="card-body"
                     style={{...econ2Styles.textAlignerSpan, ...econ2Styles.numericFontSize}}><span
                    style={{color: '#fff'}}>{numberWithCommas(horizontalData.total_salary)}</span>
                </div>
            </div>
            }
            {horizontalData.aos_emd !==undefined && horizontalData.aos_emd !== 0 && <div className="card" style={{
                flex: 1,
                backgroundImage: `linear-gradient(to right, ${palettePayrollReport[0]}, ${palettePayrollReport[1]})`,
                boxShadow: `0 0 5px ${palettePayrollReport[0]}`
            }} onClick={locationHandler}>
                <div className="card-header" style={econ2Styles.textAligner}><span
                    style={{color: '#fff', textAlign: "center"}}>АО-с ЭМД</span></div>
                <div className="card-body"
                     style={{...econ2Styles.textAlignerSpan, ...econ2Styles.numericFontSize}}><span
                    style={{color: '#fff'}}>{numberWithCommas(horizontalData.aos_emd)}</span>
                </div>
            </div>
            }
        </div>
    )
}

const econ2Styles = {
    textAligner: {
        justifyContent: 'center',
        display: 'flex',
        gap: "5%",
        fontWeight: 700,

    },
    textAlignerSpan: {
        justifyContent: 'space-evenly',
        display: 'flex',
        fontWeight: "700",
    },
    numericFontSize: {
        fontSize: '1rem'
    }
}

export default PayrollReportHeaderInfo

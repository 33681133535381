import React, { useState, useEffect, useContext } from "react"
import notify from "devextreme/ui/notify"
import BudgetService from "../../../../services/api/budget"
import _ from "lodash"
import DataGrid, {
    Column,
    Paging,
    Summary,
    TotalItem,
} from "devextreme-react/data-grid"
import { budgetPalettes, numberWithCommas } from "../../../../util"
import EmptyData from "../../../budget/components/empty"

const BudgetDetailByEvent = (props) => {
    const { year, orgFilterText, isParentAg, parentAgendaId, agendaId } = props;
    const [pageIndex, setPageIndex] = useState(0)
    const [loader3, setLoader3] = useState(false);
    const [chartData, setChartData] = useState([]);
    const [sumExpense, setSumExpense] = useState(0);
    const getBudgetDetailByEventLoad4 = async () => {
        try {
            setLoader3(true)
            let result = await BudgetService.getBudgetDetailByEvent(year, orgFilterText, parentAgendaId, agendaId);

            const groupedData = [];
            const groupedData1 = [];
            for (const entry of result) {
                const key = isParentAg ? entry.parent_event_name : entry.event_name;
                const amount = parseFloat(entry.amount);
                const guitsetgel = parseFloat(entry.guitsetgel);

                if (groupedData[key]) {
                    groupedData[key] += amount;
                    groupedData1[key] += guitsetgel;
                } else {
                    groupedData[key] = amount;
                    groupedData1[key] = guitsetgel;
                }
            }

            const resultArray = Object.keys(groupedData).map(key => ({
                event_name: key,
                amount: groupedData[key],
                guitsetgel: groupedData1[key]
            }));

            setChartData(resultArray);
            setSumExpense(_.sumBy(result, (e) => parseInt(e.amount)))
            setLoader3(false);

        } catch (e) {
            setLoader3(false);
            notify(e.message, "error", 2000)
        }
    };

    useEffect(() => {
        getBudgetDetailByEventLoad4()
    }, [year, orgFilterText, parentAgendaId, agendaId])

    const cellTemp = (cellElement, cellInfo) => {
        return cellElement.append(cellInfo.row.loadIndex + 1)
    }

    const onCellPreparedHandler = (e) => {
        if (e.rowType === "header") {
            e.cellElement.wordWrapEnabled = true
            e.cellElement.style.fontFamily = "Segoe UI"
            e.cellElement.style.fontWeight = "bold"
            e.cellElement.style.color = "#15186A"
            e.cellElement.style.backgroundColor = "#EEF0F4"
        }
        if (e.rowType === "data") {
            e.cellElement.style.fontFamily = "Segoe UI"
            e.cellElement.style.fontWeight = "semibold"
            e.cellElement.style.color = "#000000"
        }
        if (e.rowType === "data" && e.column.dataField === "name") {
            let elem = e.cellElement
            elem.style.setProperty("cursor", "pointer", "important")
        }
    }

    const cellFractionColumn = (e) => {
        return `${numberWithCommas(e.value / 1000000)}`
    }

    const calcCellVal = (e) => {
        // console.log(e.amount, sumExpense);
        return ((e.amount * 100) / sumExpense).toFixed(2)
    }

    if (chartData?.length === 0 && !loader3) {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    family: "Segoe UI",
                }}
            >
                <EmptyData />
            </div>
        )
    }

    return (
        <div id="load6">
            <DataGrid
                rowAlternationEnabled={true}
                dataSource={chartData}
                noDataText="Дата байхгүй байна."
                style={{ fontFamily: "Segoe UI", fontWeight: "semibold" }}
                onCellPrepared={onCellPreparedHandler}
            >
                <Paging defaultPageSize={10} onPageIndexChange={(e) => setPageIndex(e)} pageIndex={pageIndex} />
                <Column
                    caption="№"
                    allowSorting={false}
                    cellTemplate={cellTemp}
                    alignment="center"
                    width={40}
                />
                <Column
                    dataField="event_name"
                    allowSorting={false}
                    caption={isParentAg ? "Зориулалт" : "Арга хэмжээ"}
                    alignment="left"
                />
                <Column caption="Төсөв" alignment="center">
                    <Column
                        caption="Дүн"
                        alignment="center"
                        dataField="amount"
                        allowSorting={true}
                        cellRender={cellFractionColumn}
                        width={120}
                        sortOrder="desc"
                    />
                    <Column
                        caption="Хувь"
                        calculateCellValue={calcCellVal}
                        alignment="center"
                        width={80}
                    />
                </Column>
                <Column caption="Гүйцэтгэл" alignment="center">
                    <Column
                        caption="Дүн"
                        alignment="center"
                        dataField="guitsetgel"
                        cellRender={cellFractionColumn}
                        width={120}
                    />
                    <Column
                        caption="Хувь"
                        calculateCellValue={calcCellVal}
                        alignment="center"
                        width={80}
                    />
                </Column>
                <Summary>
                    <TotalItem
                        column="amount"
                        summaryType="sum"
                        customizeText={(data) => numberWithCommas(data.value / 1000000)}
                    />
                    <TotalItem
                        column="guitsetgel"
                        summaryType="sum"
                        customizeText={(data) => numberWithCommas(data.value / 1000000)}
                    />
                </Summary>
            </DataGrid>
        </div>
    )
}

export default BudgetDetailByEvent;

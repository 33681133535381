import React, { useState, useRef ,useEffect} from "react"
import notify from "devextreme/ui/notify";
import OrganizationService from "../../services/api/organization";
import TreeView from "devextreme-react/tree-view";
import { Popup } from "devextreme-react/popup";
import structureIcon from '../../assets/imgs/structureIcon.png';
import regIcon from '../../assets/imgs/regIcon.png';
// import historyIcon from '../../assets/imgs/historyIcon.png';
import { Tooltip } from "devextreme-react/tooltip";
import OrgChartTest from "../home/components/orgType/orgChart/orgChartTest";
import TreeList, {
    Column, Lookup, FilterRow, Scrolling, Paging, Pager, Button as FormButton
} from 'devextreme-react/tree-list';
import { Template } from 'devextreme-react/core/template';
import EmployeeCell from './EmployeeCell.js';
import Profile from "../../assets/imgs/profile_icon.png";
import ScrollView from "devextreme-react/scroll-view";
import { useContext } from "react";
import { UserContext } from "../../hooks/UserContext";
import LoadPanelComponent from '../../components/LoadPanelComponent'
import { urlServer } from "../../shared/lib/request";
import OrgContact from '../../pages/introduction/components/TopHeaderComponent'
import { Button } from "devextreme-react/button";
import DataGrid, { Grouping, GroupPanel, HeaderFilter } from "devextreme-react/data-grid";
import strategy_icon from "../../assets/imgs/structureIcon2.png";
import { numberWithCommas11, toCapitalize } from "../../util";
import StrucrureHeaderInfo from "./StrucrureHeaderInfo";
import loader from "../../assets/imgs/loader.gif";
import SelectBox from "devextreme-react/select-box";
import {CheckBox} from "devextreme-react/check-box";
import { Lookup as DevLookup, DropDownOptions } from 'devextreme-react/lookup';

const UpdatedStructure = (props) => {
    const treeView = useRef(null);
    // const [popupVisible, setPopupVisible] = useState(false);
    const [withAnimationVisible2, setWithAnimationVisible2] = useState(false);
    // const [withAnimationVisible3, setWithAnimationVisible3] = useState(false);
    const [withAnimationVisible4, setWithAnimationVisible4] = useState(false);
    const [historyVisible, setHistoryVisible] = useState(false);
    // const [regVisible, setRegVisible] = useState(false);
    // const [treeViewData, setTreeViewData] = useState([]);
    // const [orgDetail, setOrgDetail] = useState([]);
    const [hierarchyData, setHierarchyData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [workerData, setWorkerData] = useState([]);
    const [popWorker, setPopWorker] = useState(false);
    const [popLoader, setPopLoader] = useState(false);
    const [link, setLink] = useState(null);
    const [popUpModal, setPopUpModal] = useState(false);
    const [imagePopUpModal, setImagePopUpModal] = useState(false);
    // const changeVisible = () => setPopupVisible(!popupVisible);
    const structure_url = '/api/uploads/documents/'
    const [selectedOrgData, setSelectedOrgData] = useState(null);
    const { user } = useContext(UserContext)
    // const [banner, setBanner] = useState([]);
    const [fileNameData, setFileNameData] = useState(null);
    const [firstPopup, setFirstPopup] = useState(false);
    const [togglerFile, setTogglerFile] = useState(false);
    const [workerFileData, setWorkerFileData] = useState([]);
    const [filePopUpModal, setFilePopUpModal] = useState(false);
    const [fileExtention, setFileExtention] = useState(null);
    const [fileName, setFileName] = useState(null);
    const [orgSortType, setOrgSortType] = useState(null);
    // const [orgStructureStat, setOrgStructureStat] = useState({});
    const [orgStructureStatDetail, setOrgStructureStatDetail] = useState([]);
    const [showDetail, setShowDetail] = useState(false);
    const [selectedOrgId, setSelectedOrgId] = useState(user.organization.id);
    const [lastUpdateDate, setLastUpdateDate] = useState(null);
    const [showPositionDesc, setShowPositionDesc] = useState(null);
    const [orgStructureAttribute, setOrgStructureAttribute] = useState([]);
    const [loadingOSA, setLoadingOSA] = useState(false);
    const [hidePanel, setHidePanel] = useState(false);
    const [showOSAModal, setShowOSAModal] = useState(false);
    const [orgStructureHistoryDays, setOrgStructureHistoryDays] = useState([]);
    const [selectedOrgStructureHistoryDays, setSelectedOrgStructureHistoryDays] = useState(null);
    const [currentCheckBox, setCurrentCheckBox] = useState(false);
    const animation = {
        show: {
            type: "slide",
            from: {
                left: 2000,
                opacity: 0.2
            },
            to: {
                opacity: 1
            },
            duration: 800
        }
        ,
        hide: {
            type: "slide",
            to: {
                opacity: 0,
                right: -1000
            },
            duration: 800
        }
    }
    const customItemLabel = { 'aria-label': 'Custom Item Template' };
    const openImageModal = async () => {
        if (fileNameData && fileNameData.tushaal_file_name !== null) {
            setImagePopUpModal(true);
        } else {
            // notify('Бүтэц, орон тооны файл оруулаагүй байна!', "warning", 2000);
            notify({
                message: 'Бүтэц, орон тооны файл оруулаагүй байна!', width: 300, position: {
                    my: 'right top',
                    at: 'right top',
                    of: "#floating-div-2"
                },
                animation: animation,
            }, "warning", 2500);
        }

    }

    const hideInfo = () => {
        setLink(null);
        setPopUpModal(false);
    }

    const hideHistory = () => {
        setHistoryVisible(false);
    }

    const openRegister = (data) => {
        if (selectedOrgData && selectedOrgData.reg_number) {
            window.open(`http://opendata.burtgel.gov.mn/lesinfo/${selectedOrgData && selectedOrgData.reg_number}`, '_blank', 'noopener,noreferrer');
        } else {
            // notify('Хуулийн этгээдийн регистрийн дугаар оруулаагүй байна!', "warning", 2000);
            notify({
                message: 'Регистрийн дугаар оруулаагүй байна!', width: 300, position: {
                    my: 'right top',
                    at: 'right top',
                    of: "#floating-div-2"
                },
                animation: animation,
            }, "warning", 2500);
        }

    }

    const drawOrgStructure = async (id,days) => {
        //console.log('drawOrgStructure')
        let lastUpdateDateOrgAndWorker = OrganizationService.lastUpdateDateOrgAndWorker(id);
        let getOrgStructure = OrganizationService.getOrgStructure(id,days);
        //let getOrsStructureHistoryDays = OrganizationService.getOrsStructureHistoryDays();
        let values = await Promise.all([lastUpdateDateOrgAndWorker, getOrgStructure]);
        // console.log('values', values);
        if (values[0]['data'] && values[0]['data'].length > 0) {
            let lastUpdateDateData = values[0]['data'][0];
            setLastUpdateDate(lastUpdateDateData['lastupdatedate'])
        }

        let org_list = [];
        let worker_list = [];
        let data = values[1]['data'] || [];
        // let departmentServiceList = [];

        for (let i = 0; i < data.length; i++) {
            let element = data[i];
            element['pid'] = element.parent_id;
            if (element.file_name) {
                element.img = structure_url + element.file_name + `?height=100`;
            } else {
                element.img = Profile;
            }

            let fnd_org_duplicate = org_list.filter(d => d.id === 'org' + element['id']);


            if (fnd_org_duplicate.length === 0) {
                // departmentServiceList.push(OrganizationService.getOrgStructureAttribute(element['id']));

                if (element['parent_id']) {
                    let fnd = data.filter(d => d.id === element.parent_id)
                    let fnd_name = fnd.length > 0 ? fnd[0].name : '';
                    let fnd_id = fnd.length > 0 ? fnd[0].id : '';

                    let tag = (fnd_name.indexOf("газар") !== -1 || element['name'].indexOf("газар")) !== -1 ? 'sales-team' : 'main-team'

                    let tags = [tag, "department"];

                    if (element['level']) {
                        tags.push("level" + element['level'])
                    }

                    org_list.push({
                        id: 'org' + element['id'],
                        pid: 'org' + fnd_id,
                        tags: tags,
                        order_no: element['order_no'] || 999999,
                        name: element['name'],
                        test: 'hello'
                    })
                } else {
                    org_list.push({
                        id: 'org' + element['id'],
                        tags: ["top-management"],
                        order_no: element['order_no'] || 999999,
                        name: element['name'],
                        // goal: element['goal']
                    })
                }
            }

            if (element['worker_id']) {
                let fnd_name = element['name']
                let tag = (fnd_name.indexOf("газар") !== -1 || element['name'].indexOf("газар")) !== -1 ? 'sales-team-person' : 'main-team-person';

                if (!element['parent_id']) {
                    tag = 'top-management-person'
                }

                let first_name = toCapitalize(element['first_name']);
                let last_name = toCapitalize(element['last_name']);
                let name = (last_name || '').slice(0, 1) + '.' + first_name;

                let obj = {
                    id: element['worker_id'],
                    name: name,
                    first_name: first_name,
                    last_name: last_name,
                    img: element['img'],
                    position: element['position_name'],
                    position_file_name: element['position_file_name'],
                    order_no: element['position_rank'] || 999999,
                    phone: element['phone'],
                    mobile: element['mobile'],
                    email: element['email'],
                    tags: [tag]
                }

                if (element['worker_parent_id']) {
                    obj.pid = element['worker_parent_id']
                } else {
                    obj.stpid = 'org' + element['id']
                }
                worker_list.push(obj)
            }
        }

        // let orgStructureAttribute = await Promise.all(departmentServiceList);

        // for (let i=0; i<org_list.length; i++) {
        //     let data = orgStructureAttribute[i].data;
        //     for (let a=0; a<data.length; a++) {
        //         org_list[i][data[a]['name']] = data[a]['description']
        //     }
        // }

        data = [...org_list, ...worker_list];
        setHierarchyData(data);
        // console.log(hierarchyData);
    }

    // useEffect(() => {
    //     setOrgStructureAttribute([])
    //     if (selectedDepartmentId) {
    //         getOrgStructureAttribute(selectedDepartmentId)
    //     }
    // }, [selectedDepartmentId])

    // useEffect(() => {
    //
    // }, [selectedOrgStructureHistoryDays])

    const getOrgStructureAttribute = async (orgStructureId) => {
        setOrgStructureAttribute([])
        try {
            setLoadingOSA(true);
            let orgStructureAttribute = OrganizationService.getOrgStructureAttribute(orgStructureId);
            let orgStructureName = OrganizationService.getOrgStructureName(orgStructureId);

            let res = await Promise.all([orgStructureAttribute, orgStructureName])
            // console.log('res', res);

            let data = res[0].data || [];
            data.push({name: 'nameOrgStructure', description: res[1].data[0].name})
            // let nameData = res1.data;
            // if (nameData && nameData.length > 0) {
            //     // data.push({name: 'nameOrgStructure', description: nameData[0].name})
            // }
            // console.log(res.data)
            setOrgStructureAttribute(data);
            // console.log('getOrgStructureAttribute data', res)
        } catch (e) {
            console.log(e)
        }

        setLoadingOSA(false)
        setShowOSAModal(true);
    };

    const selectItem = async (e) => {
        console.log('selectItem',e)
        try {
            setSelectedOrgData(e.itemData);
            setSelectedOrgId(e.itemData.org_id)
            let resultImage = await OrganizationService.getFileNameStateStructure(e.itemData.org_id);
            setFileNameData(resultImage.data[0]);

            drawOrgStructure(e.itemData.id,selectedOrgStructureHistoryDays)
        } catch (e) {
            console.log('selectItem', 'catch')
            setFileNameData(null)
            setSelectedOrgData(null)
            notify(e.response.data.message, "error", 2000);
        }
    }
    const selectedItem = (e) => {
        if (e.node.selected) {
            e.itemElement.style.background = '#fff';
            e.itemElement.style.backgroundColor = '#fff';
            e.itemElement.style.color = '#000000';
            e.itemElement.style.fontWeight = '700';
        } else {
            e.itemElement.style.background = '#fff';
            e.itemElement.style.backgroundColor = '#fff';
            e.itemElement.style.color = '#000000';
            e.itemElement.style.fontWeight = 'normal';
        }

    }
    const callModal = async (id) => {
        try {
            setPopLoader(true);
            let r = await OrganizationService.getOrgWorkersByStructureId(id);
            r.forEach(element => {
                if (element.file_path === null) {
                    element.img = Profile
                } else {
                    element.img = structure_url + element.file_path + `?height=100`;
                }
            })
            setWorkerData(r);
            setPopWorker(true);
            setPopLoader(false);
        } catch (e) {
            setPopLoader(false);
            notify(e.message, 'error', 2000);
        }
    }

    const allowedPageSizes = [5, 10, 20];
    const onEditorPreparing = (e) => {
        if (e.dataField === 'id') {
            e.showAllText = 'Бүгд'
            e.editorOptions.showClearButton = true;
            /*       e.filterValue= null;
                   e.value = null;
                   e.editorOptions.value= null;
                   e.editorOptions.allowClearing= true;*/

        }
    }
    const onFileEvent = async (e) => {
        if (e.row.data) {
            setPopLoader(true);
            let result = await OrganizationService.getOrgWorkerFile(e.row.data.id);
            setWorkerFileData(result.data);
            setTogglerFile(true);
            setPopLoader(false);
        }
    }
    //Column custom renderings
    const statusRender = (data) => {
        if (data.displayValue && data.displayValue === "Шинэ") {
            return <div style={{ backgroundColor: '#50CB93', width: '80%', color: 'white', borderRadius: '3px', cursor: 'pointer', margin: 'auto', textAlign: 'center' }}>Шинэ</div>
        } else {
            return <div style={{ backgroundColor: '#FF616D', width: '80%', color: 'white', borderRadius: '3px', cursor: 'pointer', margin: 'auto', textAlign: 'center' }}>Хуучин</div>
        }
    }
    const columnInfo = (data) => {
        setFileName(data.data.file_name);
        setFileExtention(data.data.file_extention);
        setFilePopUpModal(true);

    }
    const renderGridFile = (data) => {
        return data.text && <div style={{ textAlign: 'center' }}>
            {data.data.file_extention === 'application/pdf' || data.data.file_extention === 'image/jpeg' || data.data.file_extention === 'image/jpg' || data.data.file_extention === 'image/png'
                ?
                <Button
                    //  style={{width: "100px", height: '25px'}}
                    icon={strategy_icon}
                    stylingMode="text"
                    style={{
                        width: 'auto',
                        height: '35px',
                        opacity: 1
                    }}
                    onClick={() => {
                        columnInfo(data)
                    }}
                />
                :
                <a href={urlServer + '/api/file/documents/' + data.data.file_name} target='_blank' rel='noreferrer'>Файл татах</a>
            }

        </div>
    }

    const orgSort = (ost) => {
        setOrgSortType(ost)

        setLoading(true)
        OrganizationService.updatedStateAttributes('')
            .then((response) => {
                // console.log('orgSortType1',ost)
                if (ost === 'desc') {
                    response.sort((a, b) => {
                        return a.state_structure_name.localeCompare(b.state_structure_name)
                    })
                } else if (ost === 'asc') {
                    response.sort((a, b) => {
                        return b.state_structure_name.localeCompare(a.state_structure_name)
                    })
                } else {
                    response.sort((a, b) => {
                        return a.order_no.toString().localeCompare(b.order_no.toString(), 'en', { numeric: true })
                    })
                }

                treeView.current.instance.option("items", response);
                setLoading(false)
            })
            .catch(() => {
                console.log('orgSortException' );
                treeView.current.instance.option("items", []);
                setLoading(false)
                throw new Error('Data Loading Error');
            })

        // treeView.current.instance.option("items", []);
    }

    const createChildren = (parent,selectedDays) => {
        //console.log('createChildren',selectedDays);
        const parentId = parent ? parent.itemData.id : '';

        if (!parent) {
            setLoading(true)
        }

        return OrganizationService.updatedStateAttributes(parentId,selectedDays)
            .then((response) => {

                if (orgSortType === 'desc') {
                    response.sort((a, b) => {
                        return a.state_structure_name.localeCompare(b.state_structure_name)
                    })
                } else if (orgSortType === 'asc') {
                    response.sort((a, b) => {
                        return b.state_structure_name.localeCompare(a.state_structure_name)
                    })
                } else {
                    response.sort((a, b) => {
                        return (a.order_no || 0).toString().localeCompare((b.order_no || 0).toString(), 'en', { numeric: true })
                    })
                }

                if (!parentId) {
                    let parent = response.filter((org) => org.parent === null)
                    if (parent.length > 0) {
                        //console.log('parentId', parent);
                        setSelectedOrgData(parent[0])
                        drawOrgStructure(parent[0].id,selectedDays)

                         OrganizationService.getFileNameStateStructure(parent[0].org_id)
                            .then((responseImage) => {
                                setFileNameData(responseImage.data[0]);
                            })
                            .catch(() => {
                                setFileNameData(null);
                            });
                         if(selectedDays=== undefined) {
                             OrganizationService.getOrsStructureHistoryDays()
                                 .then((res) => {
                                     //console.log('res', res);
                                     setOrgStructureHistoryDays(res.data);
                                 })
                                 .catch(() => {
                                     setOrgStructureHistoryDays([]);
                                 });
                         }
                    }
                    setLoading(false)
                }
                //console.log('response', response);
                parentId ==='' && treeView.current.instance.option("items", response);
            //treeView.current.instance.refresh()
                return response
            })
            .catch(() => {
                setLoading(false)
                throw new Error('Data Loading Error');
            });
    }

    let tshz = user && user.organization && user.organization.obt_id === 3;
    // console.log(tshz, user)

    //let orgStructureStat = {};
    const treeViewItemTemplate = (e) => {
        return (
            <div style={{ flex: 1, justifyContent: 'flex-start', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                 {e.icon && (
          <div
            style={{
              background: "#E7E6EB",
              minWidth: 9,
              minHeight: 9,
              borderRadius: "100%",
              // marginRight: 10
            }}
          />
        )}

                <div style={{marginLeft: e.icon ? 10 : 5, }}>
          {e.state_structure_name}
        </div>
            </div>
        )
        /*itemElement.append("<i>" + itemData.text + "</i>");*/
    }
    const lookupItemRender = (e) => {
        let picture = Profile;
        if (e.file_name) {
            picture = structure_url + e.file_name;
        }
        return (
            <div className="custom-item" style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                <img width={"80"} height={"80"}
                    //alt={e.first_name}
                    src={picture}
                />
                <div style={{marginLeft:15}}>{e.first_name}</div>
            </div>
        )
    }

    return (

        <div className={"row"} style={{ position: "relative", height: '100%', display: 'flex', flexDirection: 'column', flex: 1, margin: 0 }}>
            <div className={"col-12"} style={{ height: 100 }}>
                <div className={"card"} style={{ height: 96, borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}>
                    <OrgContact urlServer={urlServer} fileNameData={fileNameData}
                                orgId={selectedOrgData && selectedOrgId} />
                </div>
            </div>

            <Popup
                visible={popUpModal}
                onHiding={hideInfo}
                dragEnabled={false}
                hideOnOutsideClick={true}
            >
                <iframe src={link} title="wot" width="100%" height="100%"></iframe>
            </Popup>
            {fileNameData && fileNameData.tushaal_file_name !== null &&
                <Popup
                    width={1200}
                    maxWidth={'100%'}
                    minHeight={'100%'}
                    height={'100%'}
                    title={'Батлагдсан бүтэц орон тооны тушаал, шийдвэр'}
                    visible={imagePopUpModal}
                    onHiding={() => {
                        setImagePopUpModal(false)
                    }}
                    dragEnabled={false}
                    hideOnOutsideClick={true}
                >
                    {
                        fileNameData.tushaal_extension === 'application/pdf' ? <object width="100%" height="100%"
                            data={urlServer + '/api/file/documents/' + fileNameData.tushaal_file_name}
                            type="application/pdf" />
                            : <img
                                alt={'doc'}
                                src={urlServer + '/api/file/documents/' + fileNameData.tushaal_file_name}
                                width={'100%'} />
                    }

                </Popup>}

            <Popup
                minWidth={'80%'}
                minHeight={'80%'}
                visible={togglerFile}
                title={`Албан хаагчтай холбоотой файл хавсралт`}
                onHiding={() => setTogglerFile(false)}
                resizeEnabled={true}
                dragEnabled={true}
                showTitle={true}
                hideOnOutsideClick={true}
            >
                <ScrollView width='100%' height='100%'>
                    <DataGrid
                        dataSource={workerFileData}
                        keyExpr="id"
                        wordWrapEnabled={true}
                    >  <Scrolling mode="standard" />
                        <GroupPanel visible={true} emptyPanelText={"Хүссэн баганаа дээш зөөн бүлэглэх боломжтой"} />
                        <Grouping autoExpandAll={true} />
                        <HeaderFilter visible={true} allowSearch={true} />
                        <Column caption="Албан хаагчийн нэр" dataField="name" />
                        <Column caption="Төрөл" dataField="file_type_name" groupIndex={0} />
                        <Column caption="Файлын нэр" dataField="file_name" />
                        <Column
                            dataField="file_name"
                            caption="Файл"
                            editCellTemplate={"file_name"}
                            cellRender={renderGridFile}
                        />
                        <Column caption="Файлын өргөтгөл" dataField="file_extention" />
                        <Column caption="Файлын хэмжээ/MB/" dataField="file_size" />
                        <Column caption="Үүссэн огноо" dataField="createdAt" dataType="date" format="yyyy-MM-dd hh:mm:ss" />
                        <Column caption="Төлөв" dataField="statusname" cellRender={statusRender} />
                        <Paging enabled={true} />
                    </DataGrid>
                </ScrollView>

            </Popup>
            {fileName &&
                <Popup
                    width={1200}
                    maxWidth={'100%'}
                    minHeight={'100%'}
                    height={'100%'}
                    title={fileName && fileName}
                    visible={filePopUpModal}
                    onHiding={() => {
                        setFilePopUpModal(false)
                    }}
                    dragEnabled={false}
                    hideOnOutsideClick={true}
                >
                    {
                        fileExtention === 'application/pdf' ? <object width="100%" height="100%"
                            data={urlServer + '/api/file/documents/' + fileName}
                            type="application/pdf" />
                            :
                            <ScrollView >  <img
                                alt={'doc'}
                                src={urlServer + '/api/file/documents/' + fileName}
                                width={'100%'} /> </ScrollView>

                    }

                </Popup>}
            <Popup
                width={800}
                height={500}
                minWidth={'80%'}
                maxWidth={'100%'}
                minHeight={'100%'}
                title={'Түүхэн замнал'}
                visible={historyVisible}
                onHiding={hideHistory}
                resizeEnabled={true}
                dragEnabled={true}
                showTitle={true}
                hideOnOutsideClick={true}
            >
                <ScrollView height="97%" width="100%">
                    <div className="widget-container">
                        {fileNameData ? <img alt={'doc'} src={urlServer + '/api/file/documents/' + fileNameData.history_file_name}
                            width={'100%'} /> : null}
                    </div>
                </ScrollView>
            </Popup>

            <Popup
                visible={firstPopup}
                showTitle={false}
                height="95vh"
                hideOnOutsideClick={true}
                onHiding={() => {
                    setFirstPopup(false);
                }}
            >
                {hierarchyData && hierarchyData[0] && hierarchyData[0].regno &&
                    <iframe title={'opendata'} width="100%" style={{ height: '100vh' }}
                        src={`https://opendata.burtgel.gov.mn/lesinfo/${hierarchyData && hierarchyData[0] && hierarchyData[0].regno.replaceAll('<p>', '').replaceAll('</p>', '')}`} />
                }

            </Popup>

            <LoadPanelComponent
                position={{ of: '#treeList' }}
                visible={loading}
            />

            <LoadPanelComponent
                position={{ of: '#orgChart' }}
                visible={popLoader}
            />
            <Popup
                visible={popWorker}
                onHiding={() => setPopWorker(false)}
                hideOnOutsideClick={true}
                title={workerData && workerData.length !== 0 && workerData[0].org_structure_name ? workerData[0].org_structure_name : ''}
            >
                <ScrollView width='100%' height='100%'>
                    <TreeList
                        dataSource={workerData}
                        onEditorPreparing={onEditorPreparing}
                        wordWrapEnabled={true}
                    > <FilterRow visible={true} />
                        <Scrolling
                            mode="standard" />
                        <Paging
                            enabled={true}
                            defaultPageSize={10} />
                        <Pager
                            showPageSizeSelector={true}
                            allowedPageSizes={allowedPageSizes}
                            showInfo={true} />
                        <Column
                            dataField="id"
                            caption="Овог нэр"
                            minWidth={200}
                            cellTemplate="employeeTemplate"
                        >
                            <Lookup dataSource={workerData} displayExpr="name" valueExpr="id" />
                        </Column>
                        <Column dataField="org_position_name" caption="Албан тушаал" width={400} />
                        {/*<Column dataField="sur_name" caption="Овог Нэр"/>*/}
                        <Column dataField="email" caption="Имэйл" width={250} />
                        <Column dataField="phone" caption="Утас" width={100} />
                        <Column dataField="worker_status_name" caption="Албан хаагчийн төлөв" />
                        <Template name="employeeTemplate" render={EmployeeCell} />
                        <Column type="buttons" width={80} fixed={true} fixedPosition="right" caption='Үйлдэл'>
                            <FormButton hint="Файл" icon="doc" visible={true} onClick={onFileEvent} />
                        </Column>
                    </TreeList>
                </ScrollView>
            </Popup>

            <Popup
                visible={!!showPositionDesc}
                title={"Албан тушаалын тодорхойлолт [" + (showPositionDesc && showPositionDesc.name) + ' ' + (showPositionDesc && showPositionDesc.position) + "]"}
                hideOnOutsideClick={true}
                onHiding={() => setShowPositionDesc(null)}
            >
                {/*{showPositionDesc && showPositionDesc.position_name}*/}
                {/*{showPositionDesc && showPositionDesc.position_file_name}*/}
                {/*{showPositionDesc && showPositionDesc.updatedAt}*/}
                {showPositionDesc && showPositionDesc.id &&
                    <object width="100%" height="100%"
                        data={urlServer + '/api/file/documents/' + showPositionDesc.id}
                        type="application/pdf" />
                }
                {!showPositionDesc && <span>Албан тушаалын тодорхойлолт оруулаагүй байна</span>}
            </Popup>

            <Popup
                visible={showDetail}
                title={"Орон тооны мэдээлэл"}
                hideOnOutsideClick={true}
                onHiding={() => setShowDetail(false)}
            >
                <div style={{ display: 'flex', padding: '20px 40px', flexDirection: 'column' }}>
                    <div style={{ display: 'flex', flex: 1 }}>
                        <div style={{ flex: 1, fontWeight: 'bold', margin: 5 }}>Эдийн засгийн ангилал</div>
                        <div style={{ width: 160, textAlign: 'center', fontWeight: 'bold', margin: '5px 10px' }}>Орон тооны мэдээлэл</div>
                        <div style={{ width: 160, textAlign: 'center', fontWeight: 'bold', margin: '5px 10px' }}>Батлагдсан</div>
                    </div>

                    {orgStructureStatDetail.map((data, index) => {
                        return <div key={index} style={{ display: 'flex', flex: 1, margin: '5px 0', fontWeight: 500 }}>
                            <div style={{ flex: 1, border: '1px solid #ddd', borderRadius: 10, padding: '5px 15px', margin: 5, display: 'flex', alignItems: 'center' }}>
                                <div style={{ border: '1px solid #ddd', borderRadius: 5, padding: '2px 10px' }}>{data.econ_category_code}</div>
                                <div style={{ marginLeft: 10 }}>{data.econ_category_name}</div>
                            </div>
                            <div style={{ width: 160, border: '1px solid #ddd', borderRadius: 10, padding: '5px 15px', margin: '5px 10px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                0
                            </div>
                            <div style={{ width: 160, border: '1px solid #ddd', borderRadius: 10, padding: '5px 15px', margin: '5px 10px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                {numberWithCommas11(data.debit * 1 || 0)}
                            </div>
                        </div>
                    })}
                </div>
            </Popup>
            <StrucrureHeaderInfo org_id={selectedOrgId} />

            {/*<div className={"col-12"} style={{flex: 1}}>*/}
            <div className="row" style={{ flex: 1 }}>
                {!hidePanel ? <div className="col-3" id={'treeList'} style={{ display: tshz ? 'none' : 'flex'}}>
                    {/*<ScrollView height={'100%'}>*/}
                    <div className="card" style={{ flex: 1 }}>
                        <div className={'card-header'} style={{ display: 'flex', padding: "20px 10px" }}>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', alignSelf: 'center', flex: 1 }}>
                                <label style={{
                                    fontSize: 15,
                                    fontWeight: 'bold',
                                    textTransform: "uppercase"
                                }}>Байгууллагууд</label>
                                <div>
                                    <Button icon={orgSortType === 'desc' ? 'sortuptext' : orgSortType === 'asc' ? 'sortdowntext' : 'sorted'} stylingMode={'text'} onClick={() => orgSort(orgSortType === 'desc' ? 'asc' : orgSortType === 'asc' ? null : 'desc')} />
                                    <Button icon={hidePanel ? 'showpanel' : 'hidepanel'} stylingMode={'text'} onClick={() => { setHidePanel(true) }} />
                                </div>
                            </div>
                        </div>
                        <div className="card-body structureTreeList"
                            style={{ backgroundColor: '#fff', borderRadius: '25px', position: 'absolute', height: 'calc(100% - 50px)', overflowY: 'auto' }}>
                            <TreeView
                                ref={treeView}
                                id="id"
                                dataStructure="plain"
                                displayExpr={'state_structure_name'}
                                parentIdExpr={'parent'}
                                keyExpr={'id'}
                                rootValue=""
                                expandedExpr={'expand'}
                                expandNodesRecursive={false}
                                itemRender={treeViewItemTemplate}
                                //selectedItem={}
                                createChildren={createChildren}
                                noDataText='Дата байхгүй байна.'
                                onItemClick={selectItem}
                                selectByClick={true}
                                selectionMode={'single'}
                                onItemSelectionChanged={selectedItem}
                                style={{
                                    fontFamily: "Segoe UI", fontWeight: 'regular', fontSize: 12
                                }}
                            />

                        </div>

                    </div>
                    {/*</ScrollView>*/}
                </div> :
                    <div style={{ display: 'flex', alignItems: 'left', justifyContent: 'flex-start', alignSelf: 'left', flex: 1 }}>
                        <Button icon={hidePanel ? 'showpanel' : 'hidepanel'} stylingMode={'text'} onClick={() => { setHidePanel(false) }} />
                    </div>
                }

                <div className={tshz ? "col-12" : (hidePanel ? "col-12" : "col-9")} id="orgChart" style={{ display: 'flex'}}>
                    <div className="card" style={{ flex: '1 1 auto', boxSizing: 'border-box', overflowX: 'auto' }}>
                        {/*     <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <StrucrureHeaderInfo state_structure_id = {selectedStrId}/>
                            </div>*/}
                        <div className="card-body" style={{ display: 'flex', flexDirection: 'column' }}>
                            <div className="dx-field" style={{display:'flex',alignItems:'center',flexDirection:'row'}}>
                                <div style={{ display: 'flex', flex: 1, justifyContent: 'center', height: 40 }}>
                                    <div style={{ display: 'flex', flexDirection: 'row', position: 'absolute', left: 0, flex: 1 }}>
                                        {fileNameData && fileNameData.tushaal_file_name ? <div id="asdf2" style={{ width: 60 }}
                                            onMouseEnter={() => setWithAnimationVisible2(!withAnimationVisible2)}
                                            onMouseLeave={() => setWithAnimationVisible2(!withAnimationVisible2)}
                                            className="law-bnt-style"
                                            onClick={() => openImageModal()}>
                                            <img loading="lazy" alt={'lazy'} src={structureIcon} style={{ width: 'auto', height: 35, opacity: 1 }} />
                                        </div> :
                                            <div id="asdf2" style={{ width: 60 }}
                                                onMouseEnter={() => setWithAnimationVisible2(!withAnimationVisible2)}
                                                onMouseLeave={() => setWithAnimationVisible2(!withAnimationVisible2)}
                                                className="law-bnt-style">
                                                <img loading="lazy" alt={'lazy'} src={structureIcon} style={{ width: 'auto', height: 35, opacity: 0.3 }} />
                                            </div>}
                                        {
                                            orgStructureHistoryDays && orgStructureHistoryDays.length > 0 &&
                                            <div className="dx-field">
                                            <DevLookup
                                                items={orgStructureHistoryDays}
                                                displayExpr={"first_name"}
                                                //searchExpr={searchExpression}
                                                valueExpr="left_days"
                                                placeholder="ТЕЗ сонгох"
                                                itemRender={lookupItemRender}
                                                inputAttr={customItemLabel}
                                                showClearButton={true}
                                                clearButtonText="Цуцлах"
                                                label='ТЕЗ сонгох'
                                                width="20rem"
                                                searchPlaceholder="Хайх"
                                                searchEnabled={false}
                                                cancelButtonText={"Хаах"}
                                                noDataText="Түүх олдсонгүй"
                                                onValueChanged={(e) => {
                                                    setSelectedOrgStructureHistoryDays(e.value);
                                                    createChildren('',e.value=== null ? undefined : e.value);
                                                    //setCurrentCheckBox(true);
                                                }}
                                            >
                                                <DropDownOptions title="ТЕЗ сонгох" width="20rem"/>
                                            </DevLookup>
                                        </div>

                                        }
                                      {/*  { currentCheckBox &&
                                            <div style={{alignItems:'center',display:'flex'}}><span style={{marginRight: ".5rem"}}>{"Сонгосон бүтэцийг болиулах: "}</span>
                                                <CheckBox
                                                    value={currentCheckBox}
                                                    onValueChanged={(e) => {
                                                        setSelectedOrgStructureHistoryDays(null);
                                                        createChildren('');
                                                        setCurrentCheckBox(false)
                                                    }}
                                                />
                                            </div>
                                        }*/}
                                        {/*Улсын бүртгэлийн мэдээлэл*/}
                                        {selectedOrgData && selectedOrgData.reg_number ? <div id="asdf4" style={{ width: 60 }}
                                            onMouseEnter={() => setWithAnimationVisible4(!withAnimationVisible4)}
                                            onMouseLeave={() => setWithAnimationVisible4(!withAnimationVisible4)}
                                            className="law-bnt-style" onClick={() => openRegister()}>
                                            <img loading="lazy" alt={'lazy'} src={regIcon} style={{ width: 'auto', height: 35, opacity: 1 }} />

                                        </div> : <div id="asdf4" style={{ width: 60 }}
                                            onMouseEnter={() => setWithAnimationVisible4(!withAnimationVisible4)}
                                            onMouseLeave={() => setWithAnimationVisible4(!withAnimationVisible4)}
                                            className="law-bnt-style">
                                            <img loading="lazy" alt={'lazy'} src={regIcon} style={{ width: 'auto', height: 35, opacity: 0.3 }} />

                                        </div>}

                                    </div>
                                    {/*{selectedOrgData && <label style={{fontSize: 16, fontWeight: 'bold', height: 40, width: 400, textAlign: 'center'}}>*/}
                                    {/*    {selectedOrgData.state_structure_name}*/}
                                    {/*</label>}*/}
                                </div>


                            </div>
                            <div className="tf-tree"
                                style={{ display: 'flex', justifyContent: 'center', flex: 1 }}>
                                {/*{displayOtherHierarchySecondary(hierarchyData)}*/}
                                {/*<div>*/}
                                    {hierarchyData && hierarchyData.length > 0 ?
                                    <OrgChartTest nodes={hierarchyData}
                                        user={user}
                                        callBack={callModal} positionDesc={(data) => setShowPositionDesc(data)}
                                        showOrgStructureAttributes={(orgStructureId) => getOrgStructureAttribute(orgStructureId)} isHistory ={typeof selectedOrgStructureHistoryDays === 'number' ? true : false}
                                    /> : <div style={{ padding: '40px 20px',fontSize: 14, height: '600px' }}>
                                        Энэхүү байгууллагын бүтэц, орон тооны мэдээлэл оруулаагүй байна! Та АДМИН эрхээрээ орж мэдээллээ оруулна уу.
                                    </div>}
                                {/*</div>*/}
                            </div>

                            <Popup
                                visible={showOSAModal}
                                title={"Алба, хэлтэс [" + ((orgStructureAttribute.find((osa) => osa.name === 'nameOrgStructure') || {}).description || '')  + ']'}
                                maxWidth={"800px"}
                                hideOnOutsideClick={true}
                                onHiding={() => setShowOSAModal(false)}
                            >
                                <ScrollView width='100%' height='100%'>
                                    <div style={{ display: 'flex', padding: '20px 40px', flexDirection: 'column' }}>
                                        {loadingOSA && <img src={loader} width={30} height={30} style={{ alignSelf: 'center' }} />}
                                        {!loadingOSA && orgStructureAttribute.length === 0 && <span>Мэдээлэл байхгүй байна.</span>}
                                        {!loadingOSA && orgStructureAttribute.map((data) => {
                                            if (data.name !== 'nameOrgStructure') {
                                                return <>
                                                    <div style={{ display: 'flex', flex: 1 }}>
                                                        <div style={{ flex: 1, fontWeight: 'bold', margin: 5 }}>{data.d_name}</div>
                                                    </div>
                                                    <div style={{ display: 'flex', flex: 1, margin: 5 }}>
                                                        <span dangerouslySetInnerHTML={{__html: data.description}}></span>
                                                    </div>
                                                </>
                                            }
                                        })}
                                    </div>
                                </ScrollView>
                            </Popup>

                            <Tooltip
                                target="#asdf2"
                                position="top"
                                visible={withAnimationVisible2}
                                hideOnOutsideClick={true}
                            >
                                <div style={{ padding: 10 }}>Батлагдсан бүтэц харах</div>
                            </Tooltip>
                            <Tooltip
                                target="#asdf4"
                                position="top"
                                visible={withAnimationVisible4}
                                hideOnOutsideClick={true}
                            >

                                <div style={{ padding: 10 }}>Улсын бүртгэлийн мэдээлэл</div>
                                {hierarchyData && hierarchyData[0] && hierarchyData[0].regno ? 'Регистрийн дугаар: ' + hierarchyData[0].regno.replaceAll('<p>', '').replaceAll('</p>', '') : ''}
                            </Tooltip>

                            <div className={'row'} style={{
                                position: 'absolute',
                                bottom: 0,
                                padding: 10,
                                background: '#e8e9ff',
                                width: 200,
                                color: '#1c3ac2',
                                fontSize: 11,
                                borderRadius: 10,
                                borderBottomRightRadius: 0,
                                borderTopLeftRadius: 0,
                                marginLeft: -10
                            }}>
                                <span>Шинэчлэгдсэн: {lastUpdateDate}</span>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
            {/*</div>*/}

        </div>
    )
}

export default UpdatedStructure;

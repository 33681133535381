import React from "react"
import {urlServer} from "../shared/lib/request"
import Lightbox from "yet-another-react-lightbox"
import "yet-another-react-lightbox/styles.css"
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen"
import Zoom from "yet-another-react-lightbox/plugins/zoom"
import "yet-another-react-lightbox/plugins/thumbnails.css"
import {useState} from "react"

function ImageViewer({array, filename, height, width}) {
  const [selectedIndex, setSelectedIndex]= useState(0)

  const slides =  array.map((item) => {
    return {
      src: `${urlServer}/api/file/${item[filename]}`,
    }
  }) 
  const onClickHandler= (index)=>{
    setSelectedIndex(index)
    setOpen(true)

  }
  const [open, setOpen] = useState(false)
  return (
    <div>
      <div style={{display: "flex", width: "100%", justifyContent: "flex-start", gap: "5%",flexDirection:'row',overflowX: array.length>5 ? 'scroll' : 'hidden' }}>
        {slides &&
          slides.map((item, index) => (
              <img
              key={index}
                onClick={()=>onClickHandler(index)}
                alt=""
                style={{height: height? height : "100px", width: "100%", objectFit: "cover", cursor: "zoom-in", marginBottom: ".5rem"}}
                src={item.src}
              />
          ))}
      </div>
      
      <Lightbox
        open={open}
        zoom={{maxZoomPixelRatio: 2}}
        animation={{zoom: 600, swipe: 400, fade: 400}}
        carousel={{finite: slides.length === 1}}
        plugins={[Fullscreen, Zoom]}
        index={selectedIndex}
        toolbar={{buttons: ["close"]}}
        close={() => setOpen(false)}
        slides={slides}
      />
    </div>
  )
}

export default ImageViewer

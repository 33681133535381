import React from "react";
import logo from "../../assets/imgs/41367.svg";
import moment from "moment";
import "moment/locale/mn";


// const getTimeDifference = (string) => {
//   console.log("string", string);
//   const providedDate = new Date(string);
//   console.log("providedDate", providedDate);
//   const currentDate = new Date();
//   console.log("current date", currentDate);
//   const differenceInSeconds = Math.floor((currentDate - providedDate) / 1000);
//   console.log("differenceInSeconds", differenceInSeconds);
//   if (differenceInSeconds < 0) {
//     const timeZoneOffset = Math.abs(currentDate.getTimezoneOffset() / 60);
//     console.log("timeZoneOffset", timeZoneOffset);
//     const adjustedDifference = differenceInSeconds + timeZoneOffset * 3600;
//     console.log("adjustDifference", adjustedDifference);
//     return getTimeDifferenceFromSeconds(adjustedDifference);
//   } else {
//     return getTimeDifferenceFromSeconds(differenceInSeconds);
//   }
// };

// const getTimeDifferenceFromSeconds = (differenceInSeconds) => {
//   const intervals = [
//     { label: 'жилийн', seconds: 31449600 },
//     { label: 'сарын өмнө', seconds: 2620800 },
//     { label: 'өдрийн өмнө', seconds: 86400 },
//     { label: 'цагийн өмнө', seconds: 3600 },
//     { label: 'минутын өмнө', seconds: 60 },
//     { label: 'секундын өмнө', seconds: 1 }
//   ];

//   for (let i = 0; i < intervals.length; i++) {
//     const interval = intervals[i];
//     if (differenceInSeconds >= interval.seconds) {
//       const count = Math.floor(differenceInSeconds / interval.seconds);
//       console.log("count", count);
//       return `${count} ${interval.label}`;
//     }
//   }

//   return '0 секундын өмнө';
// };

function NotificationCard(data) {
  let title = data.notification.title.replace(/<\/?[^>]+(>|$)/g, "").replace(/["'`]/g,"")
  let notifInfo = document.createElement('div')
  notifInfo.innerHTML = data.notification.notif_info
  let notifInfoItem = notifInfo.textContent || notifInfo.innerText
  let notification = notifInfoItem.replace(/["'`]/g,"")
  return (
    <div
      style={{ display: "flex", alignItems: "center", justifyContent: 'space-between' }}
      className={`notification-card-container ${data.is_seen === 1 ? "seen" : "notSeen"
        }`}
      onClick={() => data.showNotificationPopup(data.id)}
    >
      {/* <div
        className={`notification-card-dot ${data.is_seen === 1 && "seen"}`}
      /> */}
      <div className="notification-card-img col-2" style={{ height: '70px', width: '70px' }}>
        <img
          src={data.notification.is_system === 1 || [2, 3].includes(data.notification.is_news) ? logo : data.user.file_path}
          alt="logo" />
      </div>
      <div className="notification-card-text col-10">
        <div className="notification-card-title">
          {title ?
            <span style={{ fontWeight: "normal", fontSize: "13px" }}>
               {
                title.length > 78 ?
                  (title.slice(0, 78).charAt(0).toUpperCase() +
                    title.slice(1, 78).toLowerCase() + "...") :
                  (title.charAt(0).toUpperCase() +
                    title.slice(1).toLowerCase())
              }
            </span>
            :
            <span style={{ fontWeight: "normal", fontSize: "13px" }}>
              {
                notification.length > 78 ?
                  (notification.slice(0, 78).charAt(0).toUpperCase() +
                    notification.slice(1, 78).toLowerCase() + "...") :
                  (notification.charAt(0).toUpperCase() +
                    notification.slice(1).toLowerCase())
              }</span>}
        </div>
        <div
          style={{ float: "left", textAlign: "start" }}
          className="notification-card-date "
        >
          {new moment(data.createdAt).format('YYYY-MM-DD HH:mm')}
        </div>
      </div>

    </div>
  );
}

export default NotificationCard;

import {useHistory} from 'react-router-dom';
import {useContext} from "react";
import {UserContext} from "./UserContext";
import AuthService from "../services/api/auth";
import Axios from "axios";

export default function useLogout(id) {
  const {setUser} = useContext(UserContext);
  let history = useHistory();

  const logoutUser = async (user) => {

    try {
      let ip;
      let city;
      let country_code;
      let country_name;
      let region;
      let longitude;
      let latitude;
      //let offset;
      let isp;
      /*let mobile;
      let proxy;
      let hosting;*/
      Axios.get('https://ipapi.co/json/').then(async (res) => {
        ip = res.data.ip;
        city = res.data.city;
        country_code = res.data.country_code;
        country_name = res.data.country_name;
        region = res.data.region;
        longitude = res.data.longitude;
        latitude = res.data.latitude;
       /* offset = res.data.offset;*/
        isp = res.data.org;
        /*mobile = res.data.mobile;
        proxy = res.data.proxy;
        hosting = res.data.hosting;*/
        await AuthService.logout(user,ip,city,country_code,country_name,region,longitude,latitude,isp).then(res => {
          setUser(null);
          localStorage.removeItem("token");
          localStorage.removeItem("goToken");
          history.push('/');
        })

      });


    } catch (err) {
      console.log(err);
    }
  }

  return {
    logoutUser
  }

}

import React, {useState, useContext, Component} from "react";
import {
    DataGrid, Column
} from 'devextreme-react/data-grid';
import { Button } from 'devextreme-react'
import "./dynamicTable.css"
import dynamicTableService from "../../services/api/dynamicTable";
import {Link, useHistory} from "react-router-dom";
import TreeView from 'devextreme-react/tree-view';
import {numberWithCommas} from "../../util";

class dynamicTableList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dynamicTableList: [],
        };
    }

    componentDidMount() {
        console.log('componentDidMount');
        this.getDynamicTableList();
    }

    getDynamicTableList = async () => {
        let res = await dynamicTableService.getDynamicTableList();
        // console.log('res', res)
        if (res && res.code === 200) {
            this.setState({dynamicTableList: res.data || []});
        } else {
            this.setState({dynamicTableList: []});
        }
    }

    detailData = (e) => {
        console.log('detail data', e.row.data);
    };

    buttonRender = (item) => {
        let {data} = item;
        // console.log('data',data)
        return <Link to={'/dynamicTable/detail/'+data.id}>
            Дэлгэрэнгүй
        </Link>;
    }

    addDataButtonRender = (item) => {
        let {data} = item;
        return <>
            <Link
                to={'/dynamicTable/'+data.id+'/'+data.name+'/edit'}>
                Засварлах
            </Link>
            {/*<br/>*/}
            {/*<Link*/}
            {/*    to={'/dynamicTable/'+data.id+'/'+data.name+'/data/list'}>*/}
            {/*    Data list*/}
            {/*</Link>*/}
        </>
    }

    createTemplate = () => {
        this.props.history.push('/dynamicTable/create');
    }

    cellUserRender = (e) => {
        let user = e.value || {};
        let first_name = user.first_name || '';
        let last_name = user.last_name || '';
        let userName = last_name && first_name && `${last_name.slice(0, 1)}.${first_name}` || '';
        return userName
    }

    render() {
        return <div className={"dynamicTable-container"}>
            {/*<h4>Тооцооллын загварын жагсаалт</h4>*/}

            <div className={'card'}>
                <div className={'card-body'}>
                    <Link to={'/dynamicTable'}>Тооцооллын загварын жагсаалт</Link>
                </div>
            </div>
            <div className={'card'}>
                <div className={'card-body'}>
                    <Button
                        text="Contained"
                        type="default"
                        stylingMode="contained"
                        // style={{marginRight: 10}}
                        onClick={() => this.createTemplate()}>Тооцооллын загвар үүсгэх</Button>
                    <br/><br/>
                    <DataGrid
                        id="dataGrid"
                        dataSource={this.state.dynamicTableList}
                        showBorders={true}
                    >
                        <Column dataField={'id'} caption={'№'} width={50} cellRender={(data) => {
                            return <div>{data.rowIndex+1}</div>
                        }}/>
                        <Column dataField={'name'} caption={'Нэр'}/>
                        <Column dataField={'updatedAt'} width={150} dataType={'datetime'} format={'yyyy/MM/dd HH:mm:ss'} caption={'Засвар хийсэн огноо'}/>
                        <Column dataField={'updatedUser'} cellRender={this.cellUserRender} caption={'Засвар хийсэн'}/>
                        <Column dataField={'createdAt'} width={150} dataType={'datetime'} format={'yyyy/MM/dd HH:mm:ss'} caption={'Үүсгэсэн огноо'}/>
                        <Column dataField={'createdUser'} cellRender={this.cellUserRender} caption={'Үүсгэсэн'}/>
                        <Column width={90} cellRender={this.addDataButtonRender}/>
                    </DataGrid>
                </div>
            </div>
        </div>
    }
}

export default dynamicTableList;

import React, {useRef, useState, useEffect, useContext} from "react";
import SelectBox from "devextreme-react/select-box";
import {getYears} from "../../../../util";
import DataGrid, {
    Column,
    Export,
    Selection,
    Summary,
    TotalItem,
    Grouping,
    GroupPanel,
    SearchPanel,
    FilterRow,
    GroupItem,
    Toolbar,
    Item
} from "devextreme-react/data-grid";
import {Workbook} from "exceljs";
import {exportDataGrid} from "devextreme/excel_exporter";
import {saveAs} from "file-saver-es";
import fcsServices from "../../../../services/api/fcs";
import notify from "devextreme/ui/notify";
import Button from "devextreme-react/button";
import {UserContext} from "../../../../hooks/UserContext";
import ApBal from "./ApBal";
import fcsService from "../../../../services/api/fcs";
import {Popup} from "devextreme-react/popup";
import ScrollView from "devextreme-react/scroll-view";
import {LoadPanel} from "devextreme-react/load-panel";
import ApBalance from "./ApBalance";
import ArEndMonth from "../../AccountRecievable/report/arEndMonth";
import LoadPanelComponent from "../../../../components/LoadPanelComponent";
import { GoSearch } from "react-icons/go";

const monthList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

export default function ApReport(props) {
    const goMonth = props.goMonth
    const myRef = useRef(null);
    const userListPosition = {of: '#usersList'}
    const {user} = useContext(UserContext);
    const componentRef = useRef();
    const [loader, setLoader] = useState(false);
    const [yearValue, setYearValue] = useState(null);
    const [monthValue, setMonthValue] = useState(null);
    const [dataSource, setDataSource] = useState([]);
    const [selectedValue, setSelectedValue] = useState(null);

    const [visibleGlReport, setVisibleGlReport] = useState(true);
    const [visibleApReport, setVisibleApReport] = useState(false);
    const [visibleApSelect, setVisibleApSelect] = useState(false);
    const [visibleBalReport, setVisibleBalReport] = useState(false);
    const apTranRef = useRef(null);
    const [reportTitle, setReportTitle] = useState(null);
    const [reportGL, setReportGL] = useState([]);
    const [modalVisibleGL, setModalVisibleGL] = useState(false);
    const [modalVisibleGLSave, setModalVisibleGLSave] = useState(false);
    const [jrNo, setJrNo] = useState('');
    const [glDate, setGlDate] = useState();
    const [dataFcsGLTran, setFcsGLTran] = useState([]);
    const [dataBalMonth, setDataBalMonth] = useState([]);
    const [modalVisibleMonthSave, setModalVisibleMonthSave] = useState(false);


    const [visableEndMonth, setVisableEndMonth] = useState(false)
    useEffect(async () => {
        const today = new Date();
        setYearValue(today.getFullYear());
        setMonthValue(today.getMonth() + 1);

    }, []);

    useEffect(() => {
        fcsApTranList();
        getReportGL()
    }, [])
    const fcsApTranList = async () => {
        try {
            setLoader(true);
            const fcsMonth = await fcsService.getAllFcsMonth('AP');
           if ( fcsMonth && fcsMonth.length < 1) notify("Өглөгийн тохиргоо хийнэ үү  ", "warning")
            // if ( fcsMonth && fcsMonth.length < 1) setLoader(false)
            if ( fcsMonth && fcsMonth.length < 1) return setLoader(false)


            const resultGlTran = await fcsService.getFcsGlTran(fcsMonth[0].year, fcsMonth[0].month, 'AP');
            setFcsGLTran(resultGlTran);



            return setLoader(false)
        } catch (e) {
            console.error(e);
            return setLoader(false)
        }

    }

    const changeYear = (e) => {
        setYearValue(e);
    }

    const changeMonth = (e) => {
        setMonthValue(e);
    }
    const customizeDate = (data) => {
        return Number(parseFloat(data.value).toFixed(2)).toLocaleString("en", {
            minimumFractionDigits: 2,
        });
    }
    const getApReport = async () => {
        if (yearValue && monthValue ) {
            try {
                setLoader(true);
                const result = await fcsServices.getFcsAptranReport(yearValue, monthValue, 0)
                setDataSource(result);
    setVisibleGlReport(false)
                setVisibleBalReport(false);
                setVisibleApSelect(false)
                setVisibleApReport(true);

                setReportTitle('ApReport');
                return setLoader(false);

            } catch (e) {
                notify(e.message, "error", 2000);
            }
        }
    };
    const getApSelect = async () => {
        if (yearValue && monthValue ) {
            try {
                setLoader(true);
                const result = await fcsServices.getFcsApSelectReport(yearValue, monthValue, "0")
                setDataSource(result);
                setVisibleGlReport(false)
                setVisibleGlReport(false)
                setVisibleBalReport(false);
                setVisibleApReport(false);
                setVisibleApSelect(true);

                setReportTitle('ApSelect');
                return setLoader(false);

            } catch (e) {
                notify(e.message, "error", 2000);
            }
        }
    };




    const getBalReport = async () => {
        if (yearValue && monthValue) {
            try {


                setVisibleApReport(false);
                setVisibleGlReport(false)
                setVisibleApSelect(false);
                setVisibleBalReport(true);

                setReportTitle('BalReport');

            } catch (e) {
                notify(e.message, "error", 2000);
            }
        }
    };
    const onExporting = (e) => {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Main sheet');

        exportDataGrid({
            component: e.component,
            worksheet,
            autoFilterEnabled: true,
        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                //`${item.acct} - ${item.descr}`
                saveAs(new Blob([buffer], {type: 'application/octet-stream'}), `${reportTitle}-${yearValue}-${monthValue}.xlsx`);
            });
        });
        e.cancel = true;
    }
    const getReportGL = async () => {
        setLoader(true);
        const resultGL = await fcsServices.getFcsGlMonthReport('AP');
        if (resultGL.data.length > 0) {
            setReportGL(resultGL.data);

            return setLoader(false);
        } else {
            return setLoader(false);
        }
    };
    const getReportGLSave = async () => {
        const resultGL = await fcsServices.getFcsGlMonthReport('AP');
        if (resultGL.data.length > 0) {
            setReportGL(resultGL.data);
            setModalVisibleGLSave(true);
        }
    };
    const jrChangeValue = (event) => {
        setJrNo(event.target.value);
    }
    const setGLData = async () => {
        if (jrNo === null) return notify("Журнал дугаар хоосон байна", "warning");
        if (jrNo !== null) {
            if (dataFcsGLTran.length === 0) {
                let jr_key = null;
                reportGL.forEach(element => {
                    jr_key = jrNo;
                });
                reportGL.map((item) => {
                    item.docdate = item.docdate;
                    item.jr_key = 'AP';
                    item.acct = item.account;
                    item.descr = item.description;
                    item.brn = item.docno;
                    item.docno = jrNo;
                    item.orgcode = item.cust_code;
                    item.orgname = item.cust_name;
                    item.created_user_id = user.id;
                })
                fcsApTranList();
                await fcsService.addFcsGLTran(reportGL);

                notify('Амжилттай ерөнхий дэвтэрлүү шилжүүллээ', "success", 2000);

            } else {

                notify('Тухайн сард та ерөнхйи дэвтэрлүү илгээсэн байна', "error", 2000);

            }
        } else {
            notify('Сонгосон огноо тайлант он сарын хугацаанд биш байна', "error", 2000);

        }
    }
    const style={
        text: {
            color: "rgba(4,7,95)", fontWeight: "700", fontSize: "13px"
        },
        box: {
            border: "1px solid rgb(224,224,224)", backgroundColor: "white", borderRadius: "1rem", padding: "0 .15rem",color: "rgba(4,7,95)", fontSize: "13px"
        },
        purple: {
            backgroundColor: "rgba(85,88,217)", fontSize: "13px", fontWeight: 600, fontStretch: "expanded"
        }
    }
    const handleSelectBoxChange= (e)=>{
        // console.log("e event", e)
        // eslint-disable-next-line default-case
        switch(e.value){
            case 0:
            getApReport()
            break;
            case 1:
            getApSelect()
            break;
            case 2:
            getBalReport()
            break;
        }
    }
    const onCellPrepared= (e)=>{
        let cell= e.cellElement
        if (e.rowType!=="header" && e.rowType !== "filter"){
            cell.style= "color: rgb(20, 23, 106); font-weight: 500; padding: .875rem 0; padding-left: 1rem"
        }else if (e.rowType==="header"){
            cell.style= `
            background-color: rgb(238, 240, 244); 
            border: none; padding-top: .875rem; 
            padding-left: 1rem; 
            color: rgb(20, 23, 106); 
            font-weight: 600; 
            font-size: 13px;
            margin-top: "2rem"
            `
        } else if (e.rowType=== "filter"){
            cell.style= "background-color: rgb(238, 240, 244); padding: .5rem; border: none; margin-bottom: 1rem"
        }

    }
    return (
        <React.Fragment>
            <div ref={myRef}>
                <LoadPanelComponent
                    shadingColor="rgba(0,0,0,0.4)"
                    position={userListPosition}
                    visible={loader}
                    message=""
                     showPane={false}
                />
                <div className="bankbookToolbarContainer" style={{display: "flex",background: "white", padding: "1rem .5rem", justifyContent: "space-between", alignItems: "center"}}>
                <div className="card-body" style={{display: "flex",gap: ".5rem", alignItems: "center"}}>
                    <div style={{display: 'flex', marginRight: 20}}>
                        <div style={{marginRight: 10, display: 'flex', alignItems: 'center',...style.text}}>Тайлант он:</div>
                            <div>
                                <SelectBox
                                value={yearValue}
                                style={{...style.box}}
                                items={getYears(true)}
                                defaultValue={yearValue}
                                onValueChanged={(e) => changeYear(e.value)}
                                placeholder="сонгох"/>
                            </div>
                    </div>
                    <div  style={{display: 'flex', marginRight: 20}}>
                        <div style={{marginRight: 10, display: 'flex', alignItems: 'center',...style.text}}>Тайлант сар:</div>
                        <div>
                            <SelectBox
                               width={60}
                                style={{...style.box}}
                                value={monthValue}
                                items={monthList}
                                defaultValue={monthValue}
                                onValueChanged={(e) => changeMonth(e.value)}
                                placeholder="сонгох"/>
                        </div>
                    </div>

                    <div  style={{display: 'flex', marginRight: 20}}>
                        <div style={{marginRight: 10, display: 'flex', alignItems: 'center',...style.text}}>Тайлан</div>
                        <SelectBox
                            items={[{value: 0, text:"Худалдан авалт" },{value: 1, text: " Төлөлт "},{value: 2, text: "Үлдэгдэл"}]}
                            valueExpr="value"
                            width={130}
                            style={style.box}
                            displayExpr="text"
                            onValueChanged={handleSelectBoxChange}
                            placeholder="Сонгох"/>
                    </div>
                    <div >
                           <Button className="opButtons" style={{display: "flex", alignItems: "center"}}>
                                <GoSearch/>
                           </Button>
                    </div>
                    </div>
                    <div style={{display: "flex", gap: ".5rem", alignItems: "center"}}>
                        <div style={{marginLeft:5, marginTop: 5}}>
                        <Button className="opButtons" disabled={dataFcsGLTran.length === 0 ? false : true}
                                text={dataFcsGLTran.length === 0 ? "Шилжүүлэх" : "Шилжүүлсэн"}
                                onClick={() => getReportGLSave()} 
                                type={dataFcsGLTran.length === 0 ? "default" : "success"}
                                style={{alignment: "center", borderRadius: '1rem',...style.purple}}/>
                    </div>

                    <div style={{marginLeft: 5, marginTop: 5}}>

                        <Button className="opButtons" text="Хаалт хийх "
                                onClick={() => setVisableEndMonth(true)} type="default"
                                style={{alignment: "center", borderRadius: '1rem',...style.purple}}/>
                    </div>
                    </div>
                </div>
                  
            </div>
            <div className="card" style={{marginTop: "1rem"}}>
                        <DataGrid
                                noDataText='Дата байхгүй байна.'
                                dataSource={reportGL}
                                showBorders={true}
                                allowColumnResizing={true}
                                hoverStateEnabled={true}
                                className="datagridBbReport"
                                visible={visibleGlReport}
                                keyExpr={null}
                                allowColumnReordering={true}
                                onCellPrepared={onCellPrepared}
                                
                            >
                                <FilterRow visible={true} />
                                <Toolbar>
                                    <Item location={"before"} cssClass="datagridToolbarOrg" text={"Байгууллага: "}/>
                                    <Item location={"before"} cssClass="datagridToolbarOrganization" text={`${reportGL[0] ? reportGL[0].aimag_name: ""}-${reportGL[0] ? reportGL[0].org_name: ""}`}/>
                                    <Item location={"before"} text={"Тайлант он: "}/>
                                    <Item location={"before"}  cssClass="datagridToolbarDate"  text={`${reportGL[0]  ? reportGL[0].pyear: ""}-${reportGL[0] ? reportGL[0].pmonth: ""}`}/>
                                    <Item location={"after"} cssClass="datagridExportButton" name="exportButton"/>
                                    <Item location={"after"} name="searchPanel"/>
                                </Toolbar>
                                <Column caption="Ордер №" dataField="docno" dataType="string" width={80}/>
                                <Column caption="Дансны дугаар" dataField="account" dataType="string" width={100}/>
                                <Column caption="Дансны нэр" dataField="account_name" dataType="string"/>
                                <Column caption="Харилцагчийн код" dataField="cust_code" dataType="string" width={120}/>
                                <Column caption="Харилцагчийн нэр" dataField="cust_name" dataType="string"/>
                                <Column caption="Дебит" dataField="debit" dataType="number" format="#,##0.00"
                                        width={120}/>
                                <Column caption="Кредит" dataField="credit" dataType="number" format="#,##0.00"
                                        width={120}/>
                                <Column caption="Гүйлгээний утга" dataField="description" dataType="string"/>

                                <Summary>
                                    <TotalItem
                                        column="debit"
                                        summaryType="sum"
                                        customizeText={customizeDate}/>
                                    <TotalItem
                                        column="credit"
                                        summaryType="sum"
                                        customizeText={customizeDate}/>
                                </Summary>
                                <Export enabled={true} allowExportSelectedData={false}/>
                            </DataGrid>
            </div>


            <ArEndMonth setVisableEndMonth={setVisableEndMonth}
                        goMonth={goMonth} visableEndMonth={visableEndMonth} jr_key={'AP'}

            />
            <Popup
                width={500}
                height={200}
                minWidth={'50%'}
                maxWidth={'50%'}
                minHeight={'40%'}
                visible={modalVisibleGLSave}
                title={'Ерөнхий дэвтэрлүү шилжүүлэх'}
                onHiding={() => setModalVisibleGLSave(false)}
                resizeEnabled={true}
                dragEnabled={true}
                showTitle={true}
                hideOnOutsideClick={true}
            >
                <ScrollView width='100%' height='100%'>
                    <div className={'card-body'}>
                        <div style={{marginTop: 10}}>
                            {reportGL[0] &&
                                <div style={{display: 'flex', flexDirection: 'row', marginBottom: 20}}>
                                    <div style={{display: 'flex', flexDirection: 'row', marginLeft: '10%'}}>
                                        <span style={{fontSize: 14, fontWeight: 500}}>Тайлант он,сар: </span>
                                        <span style={{
                                            fontSize: 14,
                                            fontWeight: 500,
                                            marginLeft: 10
                                        }}>{reportGL[0].pyear}-{reportGL[0].pmonth}</span>
                                    </div>

                                    <div style={{display: 'flex', flexDirection: 'row', marginLeft: '10%'}}>
                                        <span style={{fontSize: 14, fontWeight: 500}}>Байгууллага: </span>
                                        <span style={{
                                            fontSize: 14,
                                            fontWeight: 500,
                                            marginLeft: 10
                                        }}>{reportGL[0].aimag_name}-{reportGL[0].org_name}</span>
                                    </div>
                                </div>
                            }
                            <div style={{display: 'flex', flexDirection: 'row', marginBottom: 20}}>
                                <div style={{display: 'flex', marginLeft: 10, marginRight: 10, marginTop: 5}}>Журнал
                                    дугаар:
                                </div>
                                <input type='string' className='law-search-input'
                                       style={{width: '50%', backgroundColor: '#FFFFFF', border: '1px solid #DDDDDD'}}
                                       value={jrNo} onChange={jrChangeValue}/>

                            </div>
                            <div className="col-12 row"
                                 style={{justifyContent: "flex-end", gap: '2rem', padding: '5px 15px'}}>
                                <Button text="Шилжүүлэх" onClick={() => setGLData()} width={150} type="default"
                                        style={{alignment: "center", borderRadius: '7px'}}/>
                            </div>

                        </div>
                    </div>
                </ScrollView>
            </Popup>
            <div>
                {visibleBalReport && <ApBalance
                    yearValue={yearValue}
                    monthValue={monthValue}

                    jr_key="AP"
                    // ap={ap}
                />
                }

            </div>
            {visibleApReport &&
                <div style={{marginLeft: 5, marginTop: 10}}>
                    <div className='col-12' style={{
                        display: 'flex',
                        flex: 1,
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginTop: 20,
                        transform: "translateY(2.5rem)",
                        zIndex: 1000
                    }}><span style={{...style.text}}>Худалдан авалтын тайлан</span></div>

                    <div className="card" style={{marginTop: 10}} ref={componentRef}>

                        <DataGrid
                        noDataText='Дата байхгүй байна.'
                            dataSource={dataSource}
                            showBorders={true}
                            // ref={apTranRef}
                            className="datagridBbReport"
                            wordWrapEnabled={true}
                            allowColumnResizing={true}
                            hoverStateEnabled={true}
                            keyExpr="id"
                            onCellPrepared={onCellPrepared}
                            onExporting={onExporting}>
                            {/* <Toolbar>
                                    <Item location={"before"}  cssClass="datagridToolbarDate"   text={"Худалдан авалтын тайлан"} />
                                    <Item location={"after"} cssClass="datagridExportButton" name="exportButton"/>
                                    <Item location={"after"} name="searchPanel"/>
                                </Toolbar> */}
                            <GroupPanel visible={true}/>
                            <FilterRow visible={true}/>
                            <Grouping autoExpandAll={false}/>
                            <Toolbar>
                                    <Item location={"after"} cssClass="datagridExportButton" name="exportButton"/>
                                    <Item location={"after"} name="searchPanel"/>
                            </Toolbar>
                            <Column caption="Огноо" alignment="center" dataField="docdate" dataType="date"/>
                            <Column caption="Журнал дугаар" dataField="docno" width={130} alignment="center" dataType="number"/>
                            <Column caption="Баримт №" alignment="center" dataField="brn" dataType="number"/>
                            <Column caption="Өглөгийн данс"
                                    dataField="apacct"
                                    calculateCellValue={(item) => {
                                        return item && `${item.apacct} - ${item.apacct_name}`
                                    }}
                                    dataType="string" groupIndex={0}/>
                            <Column caption="Дансны дугаар" alignment="center" dataField="acct" dataType="string"/>
                            <Column caption="Дансны нэр" dataField="acct_name" dataType="string"/>
                            <Column caption="Тайлбар" dataField="descr" alignment="center" dataType="string"/>
                            <Column caption="Харилцагчийн дугаар" тinWidth={150} alignment="center" dataField="cust" dataType="string"/>
                            <Column caption="Харилцагчийн нэр"  тinWidth={120}  alignment="center" dataField="custname" dataType="string"/>
                            <Column caption="Нийт үнэ" alignment="center" dataField="amt" dataType="number" format="#,##0.00"/>
                            <Column caption="Үүнээс нөат" alignment="center" dataField="vat" dataType="number" format="#,##0.00"/>
                            <Summary>
                                <GroupItem
                                    column="amt"
                                    summaryType="sum"
                                    customizeText={customizeDate}
                                    showInGroupFooter={false}
                                    alignByColumn={true}  alignment="right"
                                />
                                <GroupItem
                                    column="amt"
                                    summaryType="count"

                                    showInGroupFooter={false}

                                />
                                <GroupItem
                                    column="vat"
                                    summaryType="sum"
                                    customizeText={customizeDate}
                                    showInGroupFooter={false}
                                    alignByColumn={true}  alignment="right"
                                />
                                <TotalItem
                                    column="amt"
                                    summaryType="sum"
                                    customizeText={customizeDate}/>
                                <TotalItem
                                    column="vat"
                                    summaryType="sum"
                                    customizeText={customizeDate}/>
                            </Summary>
                            <Export enabled={true} allowExportSelectedData={false}/>
                        </DataGrid>
                    </div>


                </div>
            }

            {visibleApSelect &&
                <div  style={{marginLeft: 5, marginTop: 10}}>
                    <div className='col-12' style={{
                        display: 'flex',
                        flex: 1,
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginTop: 20
                    }}></div>

                    <div className="card" style={{marginTop: 10}} ref={componentRef}>

                        <DataGrid
                            dataSource={dataSource}
                            showBorders={true}
                            // ref={apTranRef}
                            className="datagridBbReport"
                            allowColumnResizing={true}
                            hoverStateEnabled={true}
                            keyExpr="id"
                            noDataText='Дата байхгүй байна.'
                            onExporting={onExporting}
                            >
                              <Toolbar>
                                    <Item location={"before"}  cssClass="datagridToolbarDate"   text={"Төлөлтийн тайлан"} />
                                    <Item location={"after"} cssClass="datagridExportButton" name="exportButton"/>
                                    <Item location={"after"} name="searchPanel"/>
                                </Toolbar>
                            <GroupPanel visible={true}/>
                            <SearchPanel visible={true}/>
                            <Grouping autoExpandAll={true}/>
                            <Column caption="Огноо" dataField="docdate" dataType="date"/>
                            <Column caption="Журнал дугаар" dataField="docno" dataType="number"/>
                            <Column caption="Баримт №" dataField="brn" dataType="number"/>
                            <Column caption="Өглөгийн данс"
                                    dataField="apacct"

                                    dataType="string" groupIndex={0}/>

                            <Column caption="Мөнгөний данс" dataField="cash_acct"
                                    calculateCellValue={(item) => {
                                        return item && `${item.cash_acct} - ${item.acct_name}`
                                    }}
                                    dataType="string"/>


                            <Column caption="Тайлбар" dataField="descr" dataType="string"/>
                            <Column caption="Харилцагчын дугаар" dataField="cust" dataType="string"/>
                            <Column caption="Харилцагчын нэр" dataField="custname" dataType="string"/>
                            <Column caption="Дүн" dataField="amt" dataType="number" format="#,##0.00"
                                    />

                            <Summary>
                                <GroupItem
                                    column="amt"
                                    summaryType="sum"
                                    customizeText={customizeDate}
                                    showInGroupFooter={false}
                                    alignByColumn={true}  alignment="right"
                                    // showInGroupFooter={true}
                                    />
                                <GroupItem
                                    column="vat"
                                    summaryType="sum"
                                    customizeText={customizeDate}
                                    showInGroupFooter={true}/>
                                <TotalItem
                                    column="amt"
                                    summaryType="sum"
                                    customizeText={customizeDate}/>
                                <TotalItem
                                    column="vat"
                                    summaryType="sum"
                                    customizeText={customizeDate}/>
                            </Summary>
                            <Export enabled={true} allowExportSelectedData={false}/>
                        </DataGrid>
                    </div>


                </div>
            }
        </React.Fragment>
    )

}
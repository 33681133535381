import { Button } from "devextreme-react/button";
import React, { useState } from 'react';
import { AiOutlineLeft } from 'react-icons/ai';

export const PlusButton = (props) => {
    // use when using dev extreme form.
    return (
        <div>
            <div>
                <Button
                    onClick={props.onClick}
                    className={`${props.Class} plusButton`}
                    icon="add" />
            </div>
        </div>
    )
};

export const BackButton = (props) => {
    //Butsah button
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };
    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    const backButtonStyle = {
        backgroundColor: isHovered ? '#335EAB' : '#003696',
        color: 'white',
        fontSize: '14px',
        padding: '10px 20px',
        borderRadius: '5px',
        border: 'none',
        display: 'flex',
        alignItems: 'center',
        gap: 8,
        cursor: "pointer",
        transition: "0.3s all ease"
    };

    return (
        <button
            onClick={props.onClick}
            style={backButtonStyle}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}>
            <AiOutlineLeft style={{ color: "white" }} />Буцах</button>
    )
}

export const StartTourBeaconButton = (props) => {
    return (
            <button className="beaconButton" onClick={props.onClick}>
                <span className="beaconInner"></span>
                <span className="beaconOuter"></span>
            </button> 
    )
};

export const SystemGuideButton = (props) => {
    return (
        <button className="customGuideBtn" onClick={props.onClick}>ХӨТӨЧ АШИГЛАХ ?</button>
    )
}


import React, { useState, useEffect, useRef, useContext } from 'react'
import CitizenFeedbackService from '../../../services/api/citizenFeedback'
import DataGrid, { Column, Paging, HeaderFilter, Summary, TotalItem } from 'devextreme-react/data-grid'
import { Button, Popup, ScrollView, TextArea } from 'devextreme-react';
import { FaCommentDots } from "react-icons/fa";
import styled from 'styled-components';
import LoadPanelComponent from '../../../components/LoadPanelComponent';
import EmptyData from '../../budget/components/empty';
import { BiSolidDislike, BiSolidLike } from 'react-icons/bi';
import { chartGradient } from '../../../util';
import PieChart, {
    Legend,
    Series,
    Label,
    Connector,
    Tooltip,
    Export
} from 'devextreme-react/pie-chart';
import { UserContext } from '../../../hooks/UserContext';

const ProjectButton = styled(Button)`
 padding: 0.2rem !important;
  background-color: white !important;
  outline: none !important;
  cursor: pointer;
  border: none !important;
  border-radius: 6px !important;
  color: black !important;
  font-weight: 600;
 &.like {
    color: #DEDEDE !important;
    &.activated{
      color: #50c687 !important
    }

  }
  &.dislike {
    color: #DEDEDE !important;
    &.activated{
      color: #f66160 !important
    }
  }

  &.comment {
    color: #DEDEDE !important;
    &.activated{
      color: #5575c2 !important
    }
  }
  .dx-button-content {
    padding: 0 !important;
  }
  transition: 0.3s ease;
  svg {
    font-size: 1.2rem;
  }
  `

const CitizenFeedbackList = (props) => {
    const dataGridRef = useRef(null)
    const commentRef = useRef(null)
    const { year } = props
    const { user } = useContext(UserContext)
    const [citizens, setCitizens] = useState([])
    const [ctzComments, setCtzComments] = useState([])
    const [counts, setCounts] = useState([])
    const [agendas, setAgendas] = useState([])
    const [ctzCommentsAgenda, setCtzCommentsAgenda] = useState([])
    const [loading, setLoading] = useState(false)
    const [loading1, setLoading1] = useState(false)
    const [showPopup, setShowPopup] = useState(false)


    const getCitizens = async () => {
        try {
            setLoading(true)
            const r = await CitizenFeedbackService.getCitizenFeedbackByOrg(year, user.org_id)
            if (r.code === 200) {
                setCitizens(r.data)
            }
            setLoading(false)
        } catch (err) {
            setLoading(false)
            setCitizens([])
        }
    }

    const getLikes = async () => {
        try {
            setLoading(true)
            const r = await CitizenFeedbackService.getCountOfLikes( year)

            if (r.code === 200) {
                setCounts(r.data)
            }
            setLoading(false)
        } catch (err) {
            setLoading(false)
            console.log("err:");
        }
    }

    const getTopAgenda = async () => {
        try {
            setLoading(true)
            const r = await CitizenFeedbackService.getTopAgendas( year)
            if (r.code === 200) {
                setAgendas(r.data)
            }
            setLoading(false)
        } catch (err) {
            setLoading(false)
            console.log("err")
        }
    }
    
    const getCitizenFeedbacks = async (e) => {
        let phone = e.phone_number.length>0 ? e.phone_number : null
        let reg = e.reg_number.length>0 ? e.reg_number : null     
        try {
            setLoading1(true)
            const r = await CitizenFeedbackService.getCitizenFeedbacks(citizens[0].cp_id, e.first_name, phone, reg)
            if (r.code === 200) {
                setCtzComments(r.data.project)
                setCtzCommentsAgenda(r.data.agenda)
                setShowPopup(true)
            }
            setLoading1(false)
        } catch (err) {
            setLoading1(false)
            console.log("==> Error:", err);
        }
    }
    useEffect(() => {
        getCitizens()
        getLikes()
        getTopAgenda()
    }, [year])

    const onCellPrepared = (e) => {
        if (e.rowType === 'header') {
            e.cellElement.setAttribute('style', 'background-color: #EEF0F4; color: #15186A; text-align: center; height: 3rem; vertical-align: middle')
        }
    }
    const showComments = (e) => {
        const rowData = e.row.data
        return <div>
            <ProjectButton hint='Санал харах' className="comment" onClick={(e) => getCitizenFeedbacks(rowData)}><FaCommentDots size={18} /></ProjectButton>
        </div>
    }

    const centerRender = (pieChart) => {
        let totalValue = pieChart.getAllSeries()[0].getAllPoints().reduce((s, p) => s + p.originalValue, 0);
        return (
            <text textAnchor="middle" style={{ fontWeight: 600, fontFamily: 'Segoe UI', fontSize: 16 }}>
                <tspan x="0" dy="0">{totalValue} </tspan>
            </text>
        );
    }
    const showActionsAgenda = (rowData) => {
        const liked = rowData.data.is_liked

        return <div>
            <ProjectButton hint="Таалагдсан" className={`like ${liked === 1 ? 'activated' : ''}`} ><BiSolidLike size={18} /></ProjectButton>
            <ProjectButton hint="Таалагдаагүй" className={`dislike ${liked === 0 ? 'activated' : ''}`}><BiSolidDislike size={18} /></ProjectButton>
        </div>
    }
    const customizeTooltipType = (arg) => {
        return {
            html: `<span class="tooltip-series-name">${arg.argumentText}: </span>${arg.value}`
        };
    }
    function markerTemplate(item) {
        const color = item.marker.fill;
        return (
          <svg>
            <rect x={0} y={0} width={12} height={12} fill={color}
              rx={item.text.includes('Бусад') ? 6 : 0}
              ry={item.text.includes('Бусад') ? 6 : 0}></rect>
          </svg>
        );
      }
    return (
        <div>
            <LoadPanelComponent visible={loading} position={{ of: '#dataGrid' }} />
            <LoadPanelComponent visible={loading1} position={{ of: '#commentTable' }} />
            <div style={{ padding: 10 }}>
                {citizens.length > 0 ?

                    <DataGrid
                        ref={dataGridRef}
                        id='dataGrid'
                        showBorders={true}
                        showColumnLines={true}
                        showRowLines={true}
                        dataSource={citizens}
                        onCellPrepared={onCellPrepared}
                        wordWrapEnabled={true}
                    >
                        <Paging defaultPageSize={10} />

                        <Column
                            caption='№'
                            width={'3rem'}
                            alignment='center'
                            cellRender={(data) => {
                                const adjustedIndex = data.rowIndex + 1 + data.component.pageIndex() * data.component.pageSize();
                                return <span>{adjustedIndex}</span>;
                            }}
                        />
                        <Column dataField='last_name' caption='Овог' />
                        <Column dataField='first_name' caption='Нэр' />
                        <Column dataField='email' caption='И-мэйл' />
                        <Column dataField='phone_number' caption='Утас' customizeText={(e)=>{
                            if(e.value === ""){
                                return "ДАН-гаар нэвтэрсэн хэрэглэгч"
                            } else {
                                return e.value
                            }   
                        }}/>
                        <Column alignment='center' dataField='count' caption='Нийт саналын тоо' width={'5rem'} />
                        <Column type='buttons' caption='Дэлгэрэнгүй' width={'7rem'} cellRender={showComments} />
                        <Summary>
                            <TotalItem 
                                column='last_name' 
                                summaryType='count'
                                displayFormat="Нийт санал өгсөн хүний тоо: {0}"
                            />
                            <TotalItem 
                                column='count' 
                                summaryType='sum'
                                showInColumn='first_name'
                                customizeText={(data) => {
                                    return `Иргэдийн саналын тоо: ${data.value}`
                                }} 
                            />
                        </Summary>
                    </DataGrid>
                    :
                    <EmptyData />
                }
            </div>
            <div style={{ display: 'flex', margin: 5, justifyContent: 'space-between' }}>

                <div className="card" style={{ width: 'calc(50% - 5px)' }}>
                    {counts && counts.length === 0 ?

                        <EmptyData />
                        :
                        <PieChart
                            id="pie"
                            type="doughnut"
                            palette={chartGradient}
                            resolveLabelOverlapping="shift"
                            dataSource={counts}
                            title={'Нийт санал'}
                            centerRender={centerRender}
                        // onLegendClick={({ points }) => points[0].isVisible() ? points[0].hide() : points[0].show()}
                        >
                            <Export enabled={true}
                            />
                            <Legend
                                orientation="horizontal"
                                itemTextPosition="right"
                                // margin={{ top: 150 }}
                                horizontalAlignment="center"
                                verticalAlignment="bottom"
                                columnCount={3} 
                                markerRender={markerTemplate}
                                />
                            <Series argumentField="liked_status"
                                valueField='count'>
                                <Label visible={true} position="columns">
                                    <Connector visible={true} />
                                </Label>
                            </Series>
                            <Tooltip
                                zIndex={1000}
                                enabled={true}
                                customizeTooltip={customizeTooltipType}
                                font={{ color: '#fff' }}
                                color="#555"
                                border={{ visible: false }}
                                location="edge"

                            ></Tooltip>
                        </PieChart>
                    }

                </div>
                <div className='card' style={{ width: 'calc(50% - 5px)' }}>

                    {agendas && agendas.length === 0 ?

                        <EmptyData />
                        :
                        <PieChart
                            id="pie"
                            type="doughnut"
                            palette={chartGradient}
                            resolveLabelOverlapping="shift"
                            dataSource={agendas}
                            centerRender={centerRender}
                            title={'Нийт хөтөлбөрт өгсөн саналын тоо'}
                        // onLegendClick={({ points }) => points[0].isVisible() ? points[0].hide() : points[0].show()}
                        >
                            <Export enabled={true}
                            />
                            <Legend
                                orientation="horizontal"
                                itemTextPosition="right"
                                // margin={{ top: 150 }}
                                horizontalAlignment="center"
                                verticalAlignment="bottom"
                                columnCount={3} />
                            <Series argumentField="agenda_name"
                                valueField='count'>
                                <Label visible={true} position="columns">
                                    <Connector visible={true} />
                                </Label>
                            </Series>
                            <Tooltip
                                zIndex={1000}
                                enabled={true}
                                customizeTooltip={customizeTooltipType}
                                font={{ color: '#fff' }}
                                color="#555"
                                border={{ visible: false }}
                                location="edge"
                            ></Tooltip>
                        </PieChart>
                    }
                </div>
            </div>

            <Popup
                visible={showPopup}
                width='80vw'
                height='80vh'
                onHiding={() => {
                    setShowPopup(false);
                    // setCtzComments([])
                }}
                hideOnOutsideClick={true}
                showCloseButton={true}>
                <ScrollView>
                    <div>
                        <div id='commentTable' style={{ display: ctzCommentsAgenda.length > 0 ? 'block' : 'none' }}>
                            <h4>Хөтөлбөрт өгсөн саналууд:</h4>
                            <DataGrid
                                ref={commentRef}
                                showBorders={true}
                                showColumnLines={true}
                                showRowLines={true}
                                dataSource={ctzCommentsAgenda}
                                onCellPrepared={onCellPrepared}
                                columnMinWidth={60}
                                wordWrapEnabled={true}>
                                {/* <Scrolling columnRenderingMode="virtual" /> */}

                                <HeaderFilter visible={true} search={true} texts={{ cancel: 'Болих', ok: 'Шүүх', emptyValue: 'Хоосон утга' }} />
                                <Column dataField='name' caption='Төсөл арга хэмжээний нэр' alignment='center' />
                                <Column dataField='updatedAt' caption='Огноо' />
                                <Column dataField='comment' caption='Санал' minWidth={100} allowFiltering={false} cellRender={(data) => (
                                    <div style={{ whiteSpace: 'normal', overflowX: 'auto', textAlign: 'justify', paddingRight: 10, maxHeight: '150px', height: '100%' }}>
                                        {data.data.comment}
                                    </div>
                                )} />
                                <Column
                                    caption=""
                                    type="buttons"
                                    // width={"7rem"} 
                                    cellRender={showActionsAgenda}
                                />
                            </DataGrid>

                        </div>
                        <div style={{ display: ctzComments.length > 0 ? 'block' : 'none', marginTop: 20 }}>
                            <h4>Ерөнхий төсөлд өгсөн саналууд:</h4>
                            {ctzComments.map((e, i) => {
                                return (
                                    <div style={{ padding: 10 }} key={i}>
                                        <TextArea maxHeight={300} minHeight={100} readOnly={true} value={e.comment} label={`/${e.updatedAt}/`} />
                                    </div>
                                )
                            })}

                        </div>
                    </div>
                </ScrollView>

            </Popup>

        </div>
    )
}

export default CitizenFeedbackList
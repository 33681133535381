import React, { useState, useEffect, useContext, useRef } from "react";
import SelectBox from "devextreme-react/select-box";
import { getYears, numberWithCommas } from "../../util";
import notify from "devextreme/ui/notify";
import BudgetService from "../../services/api/budget";
import { UserContext } from "../../hooks/UserContext";
import AgendaGrid from "./components/hutulbur_arga_hemjee/AgendaGrid";
import { Button, PieChart, Popup } from "devextreme-react";
import { Label, Legend, Series, Tooltip } from "devextreme-react/pie-chart";
import PlanService from "../../services/api/plan";
import AgendaPivotGrid from "./components/hutulbur_arga_hemjee/AgendaPivotGrid";
import BudgetDetailByEvent from "./components/hutulbur_arga_hemjee/BudgetDetailByEvent";
import AgendaSplinechartJileer from "./components/hutulbur_arga_hemjee/AgendaSplineChart";
import _ from "lodash";

const AgendaArrangement = () => {
    const [yearList, setYearList] = useState([]);
    const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
    const [chosenValue, setChosenValue] = useState('ALL');
    const [orgs, setOrgs] = useState([]);
    const { user } = useContext(UserContext)
    const windowSize = useRef([window.innerWidth, window.innerHeight]);
    const [budgetDetailByAgenda, setBudgetDetailByAgenda] = useState([]);
    const [loader3, setLoader3] = useState(false);
    const [detailCount, setDetailCount] = useState({})
    const [isParentAg, setIsParentAg] = useState(true)
    const [parentAgendaId, setParentAgendaId] = useState(0)
    const [agendaId, setAgendaId] = useState(0)
    const [firstChartVisible, setFirstChartVisible] = useState(false);
    const [whichComponentMaxmized, setWhichComponentMaximized] = useState(null);

    useEffect(() => {
        loadYearList();
        setChosenValue("ALL")
        loadOrgs(currentYear)
        setParentAgendaId(0)
        setAgendaId(0)
        setIsParentAg(true)
    }, [])

    useEffect(() => {
        loadDetailCount()
        getBudgetDetailByAgendaLoad3()
        setParentAgendaId(0)
        setAgendaId(0)
    }, [currentYear, chosenValue])

    useEffect(() => {
        getBudgetDetailByAgendaLoad3()
        setParentAgendaId(0)
        setAgendaId(0)
    }, [isParentAg])

    const loadYearList = async () => {
        try {
            let result = await (await BudgetService.getOrgBudgetYear());
            setYearList(result)
        } catch (error) {
            notify(error.message, "error", 2000)
        }

    }

    const loadOrgs = async (year) => {
        try {
            let r = await BudgetService.getFilteredOrganizations(year);
            setOrgs(r.length > 0 && r.length === 1 ? r : [
                {
                    budget_year: year,
                    text_name: 'БҮГД',
                    value_name: 'ALL',
                },
                ...r
            ])
            r.length > 1 ? setChosenValue('ALL') : setChosenValue(r.length > 0 && r[0].value_name)
        } catch (e) {
            notify(e.message, "error", 2000)
        }
    }

    const loadDetailCount = async () => {
        try {
            let res = await BudgetService.getAgendaEventDetailCounts(currentYear, chosenValue)
            setDetailCount(res[0])
        } catch (e) {
            notify(e.message, "error", 2000)
        } finally {
            setLoader3(false)
        }
    }

    const getBudgetDetailByAgendaLoad3 = async () => {
        try {
            setLoader3(true)
            let result = await BudgetService.getBudgetDetailByAgenda(currentYear, chosenValue);
            const groupedData = [];

            for (const entry of result) {
                const key = isParentAg ? entry.parent_agenda_name : entry.agenda_name;
                const amount = parseFloat(entry.amount);

                if (groupedData[key]) {
                    groupedData[key].amount += amount;
                } else {
                    groupedData[key] = {
                        parent_agenda_id: entry.parent_agenda_id,
                        agenda_id: entry.agenda_id,
                        agenda_name: key,
                        amount: amount,
                    };
                }
            }

            const resultArray = Object.values(groupedData);
            setBudgetDetailByAgenda(resultArray);
            setLoader3(false);
        } catch (e) {
            setLoader3(false);
            notify(e.message, "error", 2000)
        }
    };

    const calculateTotal = (pieChart) => {
        let totalValue = pieChart.getAllSeries()[0].getAllPoints().reduce((s, p) => s + p.originalValue, 0);
        return `${numberWithCommas(totalValue / 1000000)}`;
    }

    const centerRender = (pieChart) => {
        return (
            <text textAnchor="middle" style={{ fontWeight: 600 }}>
                <tspan x="0" dy="-1em">Төсөв:</tspan>
                <tspan x="0" dy="1em">{calculateTotal(pieChart)}</tspan>
            </text>
        );
    }

    const customizeTooltipAgenda = (arg) => {
        return {
            html: `<span class="tooltip-series-name">${arg.argumentText}: </span> ${numberWithCommas(arg.value / 1000000)} /${(arg.percent * 100).toFixed(2)}%/`
        }
    }

    const popUpHandler = (trueFalser, value) => {
        setFirstChartVisible(trueFalser);
        setWhichComponentMaximized(value)
    }

    let maximizeIcon = '<svg t="1659165394467" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2309" width="16" height="16"><path d="M504.576 603.428571a19.748571 19.748571 0 0 1-5.705143 13.129143l-189.696 189.696 82.285714 82.285715a36.425143 36.425143 0 0 1 10.861715 25.709714c0 20.004571-16.566857 36.571429-36.571429 36.571428h-256c-20.004571 0-36.571429-16.566857-36.571428-36.571428v-256c0-20.004571 16.566857-36.571429 36.571428-36.571429a36.205714 36.205714 0 0 1 25.709714 10.861715l82.285715 82.285714 189.696-189.696c3.437714-3.437714 8.557714-5.705143 13.129143-5.705143s9.728 2.304 13.129142 5.705143l65.133715 65.133714a19.602286 19.602286 0 0 1 5.705143 13.129143zM950.857143 109.714286v256c0 20.004571-16.566857 36.571429-36.571429 36.571428a36.205714 36.205714 0 0 1-25.709714-10.861714l-82.285714-82.285714-189.696 189.696c-3.437714 3.437714-8.557714 5.705143-13.129143 5.705143s-9.728-2.304-13.129143-5.705143l-65.133714-65.133715c-3.437714-3.437714-5.705143-8.557714-5.705143-13.129142s2.304-9.728 5.705143-13.129143l189.696-189.696-82.285715-82.285715a36.425143 36.425143 0 0 1-10.861714-25.709714c0-20.004571 16.566857-36.571429 36.571429-36.571428h256c20.004571 0 36.571429 16.566857 36.571428 36.571428z" fill="#515151" p-id="2310"></path></svg>';


    return (
        <React.Fragment>
            <Popup
                visible={firstChartVisible}
                onHiding={() => setFirstChartVisible(false)}
                hideOnOutsideClick={true}
                showCloseButton={true}
                height={550}
                title={whichComponentMaxmized && whichComponentMaxmized === 1 ? 'НИЙТ ЗАРЛАГА' : (whichComponentMaxmized === 2 ? 'ТӨСӨВ, ГҮЙЦЭТГЭЛ /жилээр, сая ₮/' : (whichComponentMaxmized === 3 ? 'ЗАРЛАГЫН БҮТЭЦ /сая ₮/' : 'ТӨСӨВ, ГҮЙЦЭТГЭЛ /сараар, сая ₮/'))}
                showTitle={true}
            >
                {whichComponentMaxmized === 1 && <AgendaGrid year={currentYear} orgFilterText={chosenValue} />}
                {whichComponentMaxmized === 2 && <AgendaSplinechartJileer filterOrgText={chosenValue} />}
                {whichComponentMaxmized === 3 && <div className="card-body" style={{ padding: 10 }}>
                    <div style={{ display: 'flex', gap: '3rem', backgroundColor: '#EEF0F4', padding: 7 }}>
                        <div style={{ display: 'flex', gap: 5 }}>
                            <input type="radio" id="undsen_hutulbur" name="undsen_hutulbur" checked={isParentAg} onChange={() => setIsParentAg(true)} />
                            <label for="undsen_hutulbur" style={{ color: '#15186A', fontWeight: 'bold', fontSize: 12 }}>Үндсэн хөтөлбөрөөр</label>
                        </div>
                        <div style={{ display: 'flex', gap: 5 }}>
                            <input type="radio" id="ded_hutulbur" name="ded_hutulbur" checked={!isParentAg} onChange={() => setIsParentAg(false)} />
                            <label for="ded_hutulbur" style={{ color: '#15186A', fontWeight: 'bold', fontSize: 12 }}>Дэд хөтөлбөрөөр</label>
                        </div>
                    </div>
                    <div style={{ margin: '1rem 0' }}>
                        <PieChart
                            dataSource={budgetDetailByAgenda}
                            type="doughnut"
                            resolveLabelOverlapping={"hide"}
                            centerRender={centerRender}
                            onPointClick={(e) => { isParentAg ? setParentAgendaId(e.target.data.parent_agenda_id) : setAgendaId(e.target.data.agenda_id) }}
                            palette='Soft Pastel'
                            paletteExtensionMode='alternate'
                            onLegendClick={({ points }) => points[0].isVisible() ? points[0].hide() : points[0].show()}
                        >
                            <Series argumentField="agenda_name" valueField="amount" border={{ dashStyle: 'solid', width: 2, visible: true }} selectionMode="onlyPoint">
                                <Label position='inside' visible={true}
                                    customizeText={(e) => `${(e.percent * 100).toFixed(2)}%`}
                                    textOverflow="ellipsis"
                                    font={{ weight: 700, size: 14 }}
                                />
                            </Series>
                            <Legend
                                orientation="horizontal"
                                itemTextPosition="right"
                                horizontalAlignment="center"
                                verticalAlignment="bottom"
                                columnItemSpacing={10}
                                columnCount={4}
                                rowItemSpacing={10}
                                paddingLeftRight={0}
                                paddingTopBottom={0}
                                customizeItems={(items) => items.slice(0, 8)}
                                customizeText={(items) => items.pointName.length > 50 ? items.pointName.slice(0, 50) + `...` : items.pointName}
                            />
                            <Tooltip enabled={true} customizeTooltip={customizeTooltipAgenda} />
                        </PieChart>
                    </div>
                </div>}
                {whichComponentMaxmized === 4 && <BudgetDetailByEvent year={currentYear} orgFilterText={chosenValue} isParentAg={isParentAg} agendaId={agendaId} parentAgendaId={parentAgendaId} />}
            </Popup>
            <div style={{ padding: 10 }}>
                <div style={{ display: 'flex', flexWrap: 'wrap', gap: '1rem' }}>
                    <div>
                        <SelectBox
                            label="Төсвийн жил"
                            items={yearList}
                            valueExpr="value"
                            displayExpr="value"
                            defaultValue={currentYear}
                            width={windowSize.current[0] < 635 ? 'auto' : '10rem'}
                            onValueChanged={(e) => { setCurrentYear(e.value); loadOrgs(e.value) }}
                            placeholder="сонгох" style={{ borderRadius: 10 }} />
                    </div>
                    <div>
                        <SelectBox
                            label="Байгууллага"
                            items={user.organization.obt_id === 3 ? '' : orgs}
                            width={windowSize.current[0] < 635 ? 'auto' : '22rem'}
                            value={chosenValue}
                            valueExpr="value_name"
                            displayExpr="text_name"
                            disabled={orgs && orgs.length === 1 ? true : false}
                            onValueChanged={(e) => setChosenValue(e.value)}
                            placeholder={user.organization.obt_id !== 3 ? 'БҮГД' : user.organization.name}
                            style={{ borderRadius: 10 }} />
                    </div>
                </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', flex: 1, justifyContent: 'space-evenly' }}>
                <div id="load5" className="row" style={{ display: 'flex', flex: 1, fontWeight: 700, width: "100%", minHeight: 106 }}>
                    <div className="card" style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', color: '#333', }}>
                        <div style={{ fontSize: '.9rem', textAlign: 'center' }}>Үндсэн хөтөлбөр</div>
                        <div style={{ fontSize: '1.8rem' }}>{detailCount.undsen_hutulbur}</div>
                    </div>
                    <div className="card" style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                        <div style={{ fontSize: '.9rem' }}>Дэд хөтөлбөр</div>
                        <div style={{ fontSize: '1.8rem' }}>{detailCount.ded_hutulbur}</div>
                    </div>
                    <div className="card" style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                        <div style={{ fontSize: '.9rem' }}>Зориулалт</div>
                        <div style={{ fontSize: '1.8rem' }}>{detailCount.zoriulalt}</div>
                    </div>
                    <div className="card" style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                        <div style={{ fontSize: '.9rem' }}>Арга хэмжээ</div>
                        <div style={{ fontSize: '1.8rem' }}>{detailCount.arga_hemjee}</div>
                    </div>
                </div>
                <div className="row d-flex responsive-flex" style={{ justifyContent: 'flex-start', flexDirection: 'row' }} >
                    <div style={{ flex: 1, maxHeight: "37rem", minHeight: "33rem" }} className="card col-6 responsive-col">
                        <div className="card-header">
                            <div style={{ flex: 1, display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
                                <span style={{ fontWeight: "700" }}>1. БАЙГУУЛЛАГААР /тоо, сая ₮/</span>
                                <Button style={{ border: '0px solid transparent', borderTopRightRadius: '15px' }} icon={maximizeIcon} onClick={() => popUpHandler(true, 1)} />
                            </div>
                        </div>
                        <div className="card-body col-6 responsive-col" style={{ padding: "0.5rem", paddingBottom: "0rem" }}>
                            <AgendaGrid year={currentYear} orgFilterText={chosenValue} />
                        </div>
                    </div>
                    <div className="card col-6 responsive-col" style={{ flex: 1, maxHeight: "37rem", minHeight: "33rem" }}>
                        <div className="card-header">
                            <div style={{ flex: 1, display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
                                <span style={{ fontWeight: "700" }}>2. ХӨТӨЛБӨР, АРГА ХЭМЖЭЭ /жилээр/</span>
                                <Button style={{ border: '0px solid transparent', borderTopRightRadius: '15px' }} icon={maximizeIcon} onClick={() => popUpHandler(true, 2)} />
                            </div>
                        </div>
                        <div className="card-body" style={{ margin: '2rem 0' }}>
                            <AgendaSplinechartJileer filterOrgText={chosenValue} />
                        </div>
                    </div>
                </div>
                <div className="row d-flex responsive-flex" style={{ justifyContent: 'flex-start', flexDirection: 'row' }}>
                    <div className="card col-6 responsive-col" style={{ flex: 1, maxHeight: "37rem", minHeight: "33rem" }}>
                        <div className="card-header">
                            <div style={{ flex: 1, display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
                                <span style={{ fontWeight: "700" }}>3. ХӨТӨЛБӨР /сая ₮/</span>
                                <Button style={{ border: '0px solid transparent', borderTopRightRadius: '15px' }} icon={maximizeIcon} onClick={() => popUpHandler(true, 3)} />
                            </div>
                        </div>
                        <div className="card-body" style={{ padding: 10 }}>
                            <div style={{ display: 'flex', gap: '3rem', backgroundColor: '#EEF0F4', padding: 7 }}>
                                <div style={{ display: 'flex', gap: 5 }}>
                                    <input type="radio" id="undsen_hutulbur" name="undsen_hutulbur" checked={isParentAg} onChange={() => setIsParentAg(true)} />
                                    <label for="undsen_hutulbur" style={{ color: '#15186A', fontWeight: 'bold', fontSize: 12 }}>Үндсэн хөтөлбөрөөр</label>
                                </div>
                                <div style={{ display: 'flex', gap: 5 }}>
                                    <input type="radio" id="ded_hutulbur" name="ded_hutulbur" checked={!isParentAg} onChange={() => setIsParentAg(false)} />
                                    <label for="ded_hutulbur" style={{ color: '#15186A', fontWeight: 'bold', fontSize: 12 }}>Дэд хөтөлбөрөөр</label>
                                </div>
                            </div>
                            <div style={{ margin: '1rem 0' }}>
                                <PieChart
                                    dataSource={budgetDetailByAgenda}
                                    type="doughnut"
                                    resolveLabelOverlapping={"hide"}
                                    centerRender={centerRender}
                                    onPointClick={(e) => { isParentAg ? setParentAgendaId(e.target.data.parent_agenda_id) : setAgendaId(e.target.data.agenda_id) }}
                                    palette='Soft Pastel'
                                    paletteExtensionMode='alternate'
                                    onLegendClick={({ points }) => points[0].isVisible() ? points[0].hide() : points[0].show()}
                                >
                                    <Series argumentField="agenda_name" valueField="amount" border={{ dashStyle: 'solid', width: 2, visible: true }} selectionMode="onlyPoint">
                                        <Label position='inside' visible={true}
                                            customizeText={(e) => `${(e.percent * 100).toFixed(2)}%`}
                                            textOverflow="ellipsis"
                                            font={{ weight: 700, size: 14 }}
                                        />
                                    </Series>
                                    <Legend
                                        orientation="horizontal"
                                        itemTextPosition="right"
                                        horizontalAlignment="center"
                                        verticalAlignment="bottom"
                                        columnItemSpacing={10}
                                        columnCount={4}
                                        rowItemSpacing={10}
                                        paddingLeftRight={0}
                                        paddingTopBottom={0}
                                        customizeItems={(items) => items.slice(0, 8)}
                                        customizeText={(items) => items.pointName.length > 50 ? items.pointName.slice(0, 50) + `...` : items.pointName}
                                    />
                                    <Tooltip enabled={true} customizeTooltip={customizeTooltipAgenda}
                                        zIndex={100000}
                                        cornerRadius={6}
                                        border={{ visible: false }}
                                        font={{ color: '#fff' }}
                                        color="#555"
                                        location="edge"
                                    />
                                </PieChart>
                            </div>
                        </div>
                    </div>
                    <div className="card col-6 responsive-col" style={{ flex: 1, maxHeight: "37rem", minHeight: "33rem" }}>
                        <div className="card-header">
                            <div style={{ flex: 1, display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
                                <span style={{ fontWeight: "700" }}>4. ЗОРИУЛАЛТ, АРГА ХЭМЖЭЭ /сая ₮/</span>
                                <Button style={{ border: '0px solid transparent', borderTopRightRadius: '15px' }} icon={maximizeIcon} onClick={() => popUpHandler(true, 4)} />
                            </div>
                        </div>
                        <div className="card-body">
                            <BudgetDetailByEvent year={currentYear} orgFilterText={chosenValue} isParentAg={isParentAg} agendaId={agendaId} parentAgendaId={parentAgendaId} />
                        </div>
                    </div>
                </div>
                <div className="row" style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'row' }}>
                    <div className="card" style={{ height: "100%", width: '100%', flex: 1 }}>
                        <div className="card-header">
                            <span style={{ fontWeight: "700" }}>5. ДЭЛГЭРЭНГҮЙ /сая ₮/</span>
                        </div>
                        <div className="card-body">
                            <AgendaPivotGrid year={currentYear} chosenValue={chosenValue} />
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default AgendaArrangement;


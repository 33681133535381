import request from '../../shared/lib/request'

const getCitizenFeedbackByOrg = (year, orgId)=>{
    return request({
        url: `/citizenFeedback/getCitizenFeedbackByOrg/${year}/${orgId}`,
        method: 'GET'
    })
}

const getBudgetProject = (year) => {
    return request({
        url: `/citizenFeedback/getBudgetProject/${year}`,
        method: 'GET'
    })
}

const createCitizenProposal = (body, arr) => {
    return request({
        url: `/citizenFeedback/createProposal?arr=${arr}`,
        method: 'POST',
        data: body
    })
}


const getCitizenFeedbacks = (cpId, firstName, phone, regNum) => {
    return request({
        url: `/citizenFeedback/getCitizenFeedbacks/${cpId}/${firstName}/${phone}?reg=${regNum}`,
        method: 'GET'
    })
}

const getCountOfLikes = (year) => {
    return request({
        url: `/citizenFeedback/getCountOfLikes/${year}`,
        method: 'GET'
    })
}

const getTopAgendas = ( year) => {
    return request({
        url: `/citizenFeedback/getTop3FeedBacksOnAgenda/${year}`,
        method: 'GET'
    })
}

const getBudgetByAgenda = (year) => {
    return request({
        url: `/citizenFeedback/getBudgetByAgenda/${year}`,
        method: 'GET'
    })
}
const createdCitizenProposal = () => {
    return request({
        url: `/citizenFeedback/createdCitizenProposal`,
        method: 'GET'
    })
}

const CitizenFeedbackService = {
    getCitizenFeedbackByOrg,
    getBudgetProject,
    createCitizenProposal,
    getCitizenFeedbacks,
    getCountOfLikes,
    getTopAgendas,
    getBudgetByAgenda,
    createdCitizenProposal
}

export default CitizenFeedbackService;
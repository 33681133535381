import React, { useEffect, useState, useRef, useContext, useCallback } from 'react';
import DataGrid, {
  Column,
  Lookup,
  Paging,
  Button,
  MasterDetail,
  Editing, HeaderFilter, GroupPanel, Grouping, GroupItem, TotalItem, Summary, FilterPanel, Export,
} from 'devextreme-react/data-grid';
import ProposalService from "../../../services/api/proposal";
import DetailTemplate from "./proposalMinistryDetailTemplate";
import { projectStatusIdEditAllowed, budgetProjectStatusEnum, budgetProjectStatus, calculateDiff } from "../static/data";
import { useHistory } from "react-router-dom";
import notify from "devextreme/ui/notify";
import {custom } from "devextreme/ui/dialog";
import { UserContext } from "../../../hooks/UserContext";
//import notificationFunctions from "../../../components/notificationFunctions";
import LoadPanelComponent from "../../budget/components/loadPanel";
import notificationUserService from '../../../services/api/notificationUser';
import { Popup } from "devextreme-react/popup";
import ChartDetailByYear from "./ChartDetailByYear";
import ScrollView from "devextreme-react/scroll-view";
import { Workbook } from "exceljs";
import { exportDataGrid } from "devextreme/excel_exporter";
import saveAs from "file-saver"
import Joyride from 'react-joyride'
import { tusliinTusulSteps, guideLocale } from '../../../components/SystemGuideSteps';
import moment from "moment";
import {formatDate, HeaderFilterText,sortingText} from "../../../util";
//const pageSizes = [10, 25, 50, 100];

export default function ProposalMinistryMore(props) {

  const [dataSource, setDataSource] = useState([]);
  const [loader, setLoader] = useState(false);

  const { user } = useContext(UserContext);
  const history = useHistory();
  const dataGridRef = useRef(null)
  const [setExpand, setSetExpand] = useState(false)

  let isButtonVisibleArr = useRef([]);
  let canEdit = useRef(false);
  const [popupDetail, setPopupDetail] = useState(false);
  const [selectionData, setSelectionData] = useState(null);
  const targetRef = useRef(null)
  let defaultFilterValue = ['budget_project_status_id', '<>', 1];
  useEffect(() => {
    initVars();
    loadData();
  }, [props.year, props.selectedData, props.wordWrapFixer,props.status,props.selectedOrg]);

  const initVars = async () => {
    let temp = [budgetProjectStatusEnum.SUBMITTED_TEZ, budgetProjectStatusEnum.ACCEPTED_TTZ]; // CASE: TEZ
    if (user.organization.org_budget_type_id === 2) {
      temp = [budgetProjectStatusEnum.SUBMITTED_TTZ,];
    }
    isButtonVisibleArr.current = temp;

    if (props.proposal && projectStatusIdEditAllowed.includes(props.proposal.budget_project_status_id))
      canEdit.current = true;
  };
  const loadData = async () => {
    try {
      setLoader(true);
      const result = await ProposalService.listMinistry(props.year, props.selectedData.org_id,props.status,props.selectedOrg);
      setDataSource(result.data);
      // props.parentHandler();
      setLoader(false);
    } catch (e) {
      setLoader(false);
      // notify(`Алдаа гарлаа`, 'error');
    }
  };

  const onClickEdit = (e) => {
    const orgName = e.row.data.organization_name;
    const aimag_name = e.row.data.aimag_name;

    const location = {
      pathname: '/projects/detail',
      state: { proposalId: e.row.data.id, isMinistry: true, orgName: orgName, aimag_name: aimag_name, proposal: e.row.data, sYear: props.year,masterDetail: false,notOrg: e.row.data.isNotOrg }
    };
    history.push(location);
  };

  const pushNotif = useCallback(async (proposalId, action, proposalOrgId, year) => {
    await notificationUserService.proposalNotification({
      proposalId: proposalId,
      action: action,
      proposalOrgId: proposalOrgId,
      year: year
    })
  }, [])

  const onClickAction = async (e, action) => {
    let status = -1;
    let title, messageHtml;

    if (action === 'approve') {
      title = "Condition is true";
      messageHtml = "<p style='color: black;'>Та энэ хүсэлтийг <strong style='color: #50c787;'>ЗӨВШӨӨРӨХДӨӨ</strong> итгэлтэй байна уу?</p>";
    } else if (action === 'deny') {
      title = "Condition is false";
      messageHtml = "<p style='color: black;'>Та энэ хүсэлтийг <strong style='color: #F55A5A;'>ТАТГАЛЗАХДАА</strong> итгэлтэй байна уу?</p>";
    }

    if (action !== 'approve' && action !== 'deny') {
      notify(`Өгөгдөл дутуу байна`);
      return;
    }


    const customConfirm = custom({
      title: "Баталгаажуулалт",
      messageHtml: messageHtml,
      buttons: [
        {
          text: "Тийм",
          onClick: (e) => {
            return true
          },
        },
        {
          text: "Үгүй",
          onClick: (e) => {
            return false
          },
        },
      ],
    })

    customConfirm.show().then(async (dialogResult) => {
      if (dialogResult) {
        try {
          setLoader(true);
          // console.log('onclick action', e.row.data.id, action, e.row.data.org_id, e.row.data)
          const res = await ProposalService.setProposalStatus(e.row.data.id, action, e.row.data.org_id);
          if (res.success) {
            notify(`${res.message}`, 'success',);
            pushNotif(e.row.data.id, action, e.row.data.org_id, e.row.data.budget_year)
              .then((res) => {
                console.log('res', res)
              })
              .catch((err) => {
                console.log('err', err)
              })
            //maybe refresh data or smth.
            loadData();
          } else {
            setLoader(false);
            notify(`${res.message}`, 'error');
          }
          setLoader(false);
        } catch (e) {
          console.log(e);
          notify(`Алдаа гарлаа`, 'error');
        }
      }
    });
  };

  const setVisible = (e, name) => {
    // console.log(user);
    return isButtonVisibleArr.current.includes(e.row.data.budget_project_status_id);

  };
  const cellRenderStatus = (data) => {
    //console.log('cellRenderStatus', data);
    return <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
      <span className={`proposal-status-${data.data.budget_project_status_id}`}>{data.displayValue}</span>
    </div>
  };
  const cellRenderOrgName = (data) => {
    // console.log(data);
    return <div>
      <span className={`row-pr-org-name`}><b>{data.displayValue}</b></span>
      <span className="row-pr-org-aimag">{data.data.aimag_name}</span>
    </div>
  };
  const cellRenderParentOrgName = (data) => {
    // console.log(data);
    return <div>
      <span className={`row-pr-org-name`}><b>{data.displayValue}</b></span>
    </div>
  };
  const cellRenderValue = (data, type) => {
    // console.log(data);
    let diff = calculateDiff(data.data[type], data.data[`prev_${type}`]);

    // return <div className={`row-pr-value ${type} ${diff < 0 ? 'dec': 'inc'}`}>
    //   <span className={`row-pr`}>{data.text}</span>
    //   <span className={`diff`}>{Math.abs(diff).toFixed(2)}%</span>
    // </div>
    // return <span className={`row-pr`}>{data.text}</span>
    return <div>{Number(parseFloat(data.value / 1000000).toFixed(2)).toLocaleString("en", {
      minimumFractionDigits: 2,
    })}</div>
  };

  const cellTemp = (cellElement, cellInfo) => {
    return cellElement.append(cellInfo.row.loadIndex + 1);
  }
  const customizeDate = (data) => {
    return Number(parseFloat(data.value / 1000000).toFixed(2)).toLocaleString("en", {
      minimumFractionDigits: 2,
    });
  }
  const customizeOgnoo = (e) => {
    return `${moment(e.value).locale("mn").format("YYYY-MM-DD HH:mm")}`;
  }
  const onChartDetail = async (e) => {
    if (e.row.data) {
      setLoader(true);
      setSelectionData(e.row.data);
      setPopupDetail(true);
      setLoader(false);
    }
  }
  const renderDetail = props => {
    return (
      <Popup
        visible={popupDetail}
        onHiding={() => setPopupDetail(false)}
        hideOnOutsideClick={true}
        showCloseButton={true}
        height="100vh"
        width="90%"
        showTitle={true}
        title={selectionData.organization_name}
      >
        <ScrollView width="100%" height="100%">
          <div className="card-body" style={{ paddingBottom: "1.6rem" }}>
            <span style={{ fontWeight: "700", padding: 5, color: '#34B771' }}>1-Нийт орлого ба тусламжийн дүн /жилээр, сая ₮/</span>
            <ChartDetailByYear orgId={selectionData.org_id} agendaId={0} eventId={0} econCode={'1'} year={selectionData.budget_year} colorId={2} />
            <span style={{ fontWeight: "700", padding: 5, color: '#F93A3A' }}>2-Нийт зарлага ба цэвэр зээлийн дүн /жилээр, сая ₮/</span>
            <ChartDetailByYear orgId={selectionData.org_id} agendaId={0} eventId={0} econCode={'2'} year={selectionData.budget_year} colorId={1} />
            <span style={{ fontWeight: "700", padding: 5, color: '#2C77FF' }}>3-Зардлыг санхүүжүүлэх эх үүсвэр /жилээр, сая ₮/</span>
            <ChartDetailByYear orgId={selectionData.org_id} agendaId={0} eventId={0} econCode={'3'} year={selectionData.budget_year} colorId={3} />
            <span style={{ fontWeight: "700", padding: 5, color: '#51578E' }}>42-Ажиллагсдын тоо</span>
            <ChartDetailByYear orgId={selectionData.org_id} agendaId={0} eventId={0} econCode={'42'} year={selectionData.budget_year} colorId={1.1} />
            <span style={{ fontWeight: "700", padding: 5, color: '#003696' }}>46 - Нийт тэтгэвэрт гарагч</span>
            <ChartDetailByYear orgId={selectionData.org_id} agendaId={0} eventId={0} econCode={'46'} year={selectionData.budget_year} colorId={4.1} />
          </div>
        </ScrollView>
      </Popup>
    )
  }
  const onCellPreparedHandler = (e) => {
    e.cellElement.style.fontFamily = 'Segoe UI'
    if (e.rowType === 'header') {
      e.cellElement.style.color = "#15186A"
      e.cellElement.style.backgroundColor = "#EEF0F4"
      e.cellElement.style.fontWeight = "bold";
      e.cellElement.style.textAlign = 'center'
      e.cellElement.style.verticalAlign = 'middle'
    }
    if (e.rowType === 'group') {
      e.cellElement.style.backgroundColor = '#fff'
      e.cellElement.style.color = 'black'
      e.cellElement.style.fontFamily = 'Segoe UI'
      e.cellElement.style.fontSize = '12px'
      e.cellElement.style.verticalAlign = "middle";
      e.cellElement.style.fontWeight = "bold";
    }
    if (e.rowType === 'data') {
      e.cellElement.style.fontFamily = 'Segoe UI'
      e.cellElement.style.fontSize = '12px'
      e.cellElement.style.verticalAlign = "middle";
      e.cellElement.style.color = e.row.data.isNotOrg ? 'red' : '#2c2c2c'

    }
  }
  const onExporting = (e) => {
    const workbook = new Workbook()
    const worksheet = workbook.addWorksheet("Main sheet")
    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
      keepColumnWidths: true,
      topLeftCell: { row: 6, column: 2 },
      customizeCell: ({ gridCell, excelCell }) => {
        excelCell.font = {name: 'Arial', size: 8, color: {argb: '000000'}}
        if (gridCell.rowType === 'header') {
          excelCell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'd9d9d9' } };
          excelCell.border = {
            top: { style: 'thin', color: { argb: '2c2c2c' } },
            left: { style: 'thin', color: { argb: '2c2c2c' } },
            bottom: { style: 'thin', color: { argb: '2c2c2c' } },
            right: { style: 'thin', color: { argb: '2c2c2c' } }
          }
        }
        if (gridCell.rowType === 'group') {
          excelCell.alignment = { wrapText: false };
          excelCell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '63C1FE' } };
        }
        if (gridCell.rowType === 'totalFooter' && excelCell.value) {
          excelCell.font.italic = true;
        }
        if (gridCell.rowType === "data") {
          excelCell.border = {
            top: { style: "thin", color: { argb: "2c2c2c" } },
            left: { style: "thin", color: { argb: "2c2c2c" } },
            bottom: { style: "thin", color: { argb: "2c2c2c" } },
            right: { style: "thin", color: { argb: "2c2c2c" } },
          }
          if (typeof excelCell.value === 'number') {
            excelCell.value = excelCell.value / 1000000
          }

        }
      },
    })
      .then(() => {
        Object.assign(worksheet.getRow(1).getCell(1), {
          value: 'Гарчиг:',
          font: { name: 'Arial', size: 8 },
        })
        Object.assign(worksheet.getRow(1).getCell(2), {
          value: `${props.year} оны төсвийн төслийн нэгтгэл`,
          font: { name: 'Arial', size: 8, 'bold': true, color: { argb: '0070c0' } },
        })
        Object.assign(worksheet.getRow(2).getCell(1), {
          value: 'Байгууллага:',
          font: { name: 'Arial', size: 8 },
        })
        Object.assign(worksheet.getRow(2).getCell(2), {
          value: props.selectedData.org_name,
          font: { name: 'Arial', size: 8 },
        })
        Object.assign(worksheet.getRow(3).getCell(1), {
          value: 'Хэрэглэгч:',
          font: { name: 'Arial', size: 8 },
        })
        Object.assign(worksheet.getRow(3).getCell(2), {
          value: `${user.last_name.slice(0, 1)}. ${user.first_name}, ${user.position_name}`,
          font: { name: 'Arial', size: 8 },
        })
        Object.assign(worksheet.getRow(4).getCell(1), {
          value: 'Огноо:',
          font: { name: 'Arial', size: 8 },
        })
        Object.assign(worksheet.getRow(4).getCell(2), {
          value: new moment().locale('mn').format('lll'),
          font: { name: 'Arial', size: 8 },
        })
      })
      .then(() => {
        workbook.xlsx.writeBuffer().then((buffer) => {
          saveAs(
            new Blob([buffer], { type: "application/octet-stream" }),
              "OG-"+ props.year + " оны төсвийн төслийн нэгтгэл -" + formatDate(new Date()) + ".xlsx"
          )
        })
      })
    e.cancel = true
  }

  return (
    <div style={{ padding: 5 }}>
      <LoadPanelComponent position={{ of: 'ministry-data-grid-master' }} visible={loader} />
      <Joyride
        run={true}
        steps={tusliinTusulSteps}
        continuous
        showSkipButton
        styles={{
          options: {
            zIndex: 100,
          }
        }}
        locale={guideLocale}
      />
      {dataSource && dataSource.length > 0 && <DataGrid
        dataSource={dataSource}
        allowColumnReordering={true}
        rowAlternationEnabled={true}
        columnAutoWidth={true}
        showColumnLines={true}
        showRowLines={true}
        showBorders={true}
        hoverStateEnabled={true}
        keyExpr="id"
        wordWrapEnabled={true}
        onCellPrepared={onCellPreparedHandler}
        defaultFilterValue={defaultFilterValue}
        onExporting={onExporting}
        ref={dataGridRef}
        //columnWidth={'auto'}
        scrolling={true}
        sorting={sortingText}
      ><FilterPanel
          visible={true}
          texts={{ clearFilter: "Шүүлтүүр цэвэрлэх", createFilter: "Шүүх"}}
        />
        <GroupPanel visible={user.organization.obt_id === 1} />
        <HeaderFilter visible={true} texts={HeaderFilterText}/>
        <Grouping
          autoExpandAll={setExpand}
          contextMenuEnabled={false}
          expandMode="rowClick"
          texts={{
            groupContinuesMessage: 'Дараагийн хуудас',
            groupContinuedMessage: 'Өмнөх хуудас'
          }}
        />
        <MasterDetail
          enabled={true}
          component={DetailTemplate}
        />
        {/* <Column caption="№" allowSorting={false} cellTemplate={cellTemp} alignment="center" visible={false} width={30}/> */}
        {
          user.organization.obt_id===1 &&
          <Column
          dataField="parent_org_name"
          caption="ТТЗ"
          dataType="text"
          visible={user.organization.obt_id === 1}
          cellRender={cellRenderParentOrgName}
          groupIndex={0}
          allowFiltering={user.organization.obt_id===1}
          />
        }
        <Column
          dataField="organization_name"
          caption="Байгууллага"
          dataType="text"
          cellRender={cellRenderOrgName}
        />
        <Column dataField="budget_project_status_id" alignment="center" caption={'Төлөв'} cellRender={cellRenderStatus}
          defaultSortOrder="desc"
        >
          <Lookup dataSource={budgetProjectStatus} valueExpr="value" displayExpr="name" />
        </Column>
        <Column dataField="status_revenue" alignment="right" caption={'Нийт орлого ба тусламжийн дүн'} dataType="number" customizeText={customizeDate} /*cellRender={(e) => cellRenderValue(e, 'revenue')}*/ allowFiltering={false}>
        </Column>
        <Column dataField="status_expense" alignment="right" caption={'Нийт зарлага ба цэвэр зээлийн дүн'} dataType="number"
         customizeText={customizeDate} /* cellRender={(e) => cellRenderValue(e, 'expense')}*/ allowFiltering={false}>
        </Column>
        <Column dataField="status_expense_source" alignment="right" caption={'Зардлыг санхүүжүүлэх эх үүсвэр дүн'}
          dataType="number" customizeText={customizeDate} /*cellRender={(e) => cellRenderValue(e, 'expense_source')}*/ allowFiltering={false}>
        </Column>
        <Column dataField="max_history_date" alignment="right" caption="Төлөв шинэчлэгдсэн огноо" dataType="date" format="yyyy-MM-dd HH:mm" />
        {/*<Column dataField="differency" alignment="right" caption={'Зөрүү дүн'} dataType="number" format="#,##0.00 ₮"
          cellRender={(e) => cellRenderValue(e, 'difference')} allowFiltering={false} visible={false}>
        </Column>*/}
        <Editing
          mode="row"
          allowUpdating={true}
          allowAdding={false}
          allowDeleting={false}
          confirmDelete={true}
          useIcons={true}
          texts={{
            addRow: 'Төсөл нэмэх',
            cancelAllChanges: 'Болих бүгд',
            cancelRowChanges: 'Болих',
            confirmDeleteMessage: 'Энэ мэдээллийг устгах уу?',
            confirmDeleteTitle: 'Баталгаажуулах',
            deleteRow: 'Устгах',
            editRow: 'Өөрчлөх',
            saveAllChanges: 'Хадгалах бүгд',
            saveRowChanges: 'Хадгалах',
            undeleteRow: 'Буцаах',
            validationCancelChanges: 'Баталгаажуулах болих',
          }}
        />
        <Column type="buttons" fixedPosition={'right'} alignment="center" caption={'Үйлдэл'} ref={targetRef}>
          <Button icon="chart" text="Бүх жилээр төсвийн график харах" onClick={onChartDetail} />
          <Button name={'edit'} icon="detailslayout" hint="Дэлгэрэнгүй" visible={true} onClick={onClickEdit} />
          <Button hint="Зөвшөөрөх" icon="check" visible={(e) => setVisible(e, 'approve')}
            onClick={(e) => onClickAction(e, 'approve')} iconStyle={{ color: 'yellow' }} />
          <Button hint="Татгалзах" icon="close" visible={(e) => setVisible(e, 'deny')}
            onClick={(e) => onClickAction(e, 'deny')} />
        </Column>
        <Summary>
          <GroupItem
            column="status_revenue"
            summaryType="sum"
            customizeText={customizeDate}
            showInGroupFooter={false} alignByColumn={true} showInColumn="status_revenue" />
          <GroupItem
            column="status_expense"
            summaryType="sum"
            customizeText={customizeDate}
            sshowInGroupFooter={false} alignByColumn={true} showInColumn="status_expense" />
          <GroupItem
            column="status_expense_source"
            summaryType="sum"
            customizeText={customizeDate}
            showInGroupFooter={false} alignByColumn={true} showInColumn="status_expense_source"/>
          <GroupItem
              column="max_history_date"
              summaryType="max"
              customizeText={customizeOgnoo}
              showInGroupFooter={false} alignByColumn={true} showInColumn="max_history_date"/>
         {/* <GroupItem
            column="differency"
            summaryType="sum"
            customizeText={customizeDate}
            showInGroupFooter={true} />*/}
          {/*<GroupItem*/}
          {/*  column="organization_name"*/}
          {/*  summaryType="count"*/}
          {/*  displayFormat="{0}-Байгууллага" />*/}
          <TotalItem
            column="status_revenue"
            summaryType="sum"
            customizeText={customizeDate} />
          <TotalItem
            column="status_expense"
            summaryType="sum"
            customizeText={customizeDate} />
          <TotalItem
            column="status_expense_source"
            summaryType="sum"
            customizeText={customizeDate} />
          <TotalItem
              column="max_history_date"
              summaryType="max"
              customizeText={customizeOgnoo} />
          <TotalItem
            column="organization_name"
            summaryType="count"
            displayFormat="Танд нийт {0} байгууллагын мэдээлэл харуулж байна" />
          {/*<TotalItem
              column="differency"
              summaryType="sum"
              customizeText={customizeDate}/>*/}
        </Summary>
        {/*<Pager allowedPageSizes={pageSizes} showPageSizeSelector={true}/>*/}
        <Paging defaultPageSize={25} />
        <Export enabled={true} allowExportSelectedData={false} />
      </DataGrid>}
      {popupDetail && renderDetail()}
    </div>
  );

}

import React, {useContext, useEffect, useState, useRef} from 'react';
import 'devextreme-react/text-area';
import DataGrid, {
    Column, Export, GroupPanel, SearchPanel, Grouping, Summary, GroupItem, TotalItem
} from 'devextreme-react/data-grid';
import {Workbook} from 'exceljs';
import {saveAs} from 'file-saver-es';
import {exportDataGrid} from 'devextreme/excel_exporter';
import {LoadPanel} from "devextreme-react/load-panel";
import {UserContext} from "../../../hooks/UserContext";
import {Button} from "devextreme-react/button";
import fcsService from "../../../services/api/fcs";
import notify from "devextreme/ui/notify";
import ScrollView from "devextreme-react/scroll-view";
import DateBox from "devextreme-react/date-box";
import {Popup} from "devextreme-react/popup";
import {confirm} from "devextreme/ui/dialog";
import LoadPanelComponent from '../../../components/LoadPanelComponent';

function FaGLReport(props) {
    const userListPosition = {of: '#usersList'}
    const {user} = useContext(UserContext);
    const [reportGL, setReportGL] = useState([]);
    const [loader, setLoader] = useState(false);
    const myRef = useRef(null);
    const [dataFcsGLTran, setFcsGLTran] = useState([]);
    const [dataBalMonth, setDataBalMonth] = useState([]);
    const [dataFcsMonth, setFcsMonth] = useState([]);
    const [modalVisibleGLSave, setModalVisibleGLSave] = useState(false);
    const [modalVisibleMonthSave, setModalVisibleMonthSave] = useState(false);
    const [jrNo, setJrNo] = useState('');
    const [glDate, setGlDate] = useState();
    useEffect(async () => {
        glList();
    }, [])

    const glList = async () => {
        try {
            setLoader(true);
            const resultGL = await fcsService.getFcsGlMonthReport('FA');
            setReportGL(resultGL.data);
            const fcsMonth = await fcsService.getAllFcsMonth('FA');

            if ( fcsMonth.length < 1) {
                notify(" тохиргоо хийнэ үү  ", "warning");

            }
            if ( fcsMonth.length < 1) return setLoader(false)

            setFcsMonth(fcsMonth);

            const resultGlTran = await fcsService.getFcsGlTran(fcsMonth[0].year, fcsMonth[0].month, 'FA');
            setFcsGLTran(resultGlTran);
            return setLoader(false)
        } catch (e) {
            console.error(e);
        }
    }
    const onExporting = (e) => {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Main sheet');

        exportDataGrid({
            component: e.component,
            worksheet,
            autoFilterEnabled: true,
        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer], {type: 'application/octet-stream'}), 'FaGl.xlsx');
            });
        });
        e.cancel = true;
    }
    const customizeDate = (data) => {
        return Number(parseFloat(data.value).toFixed(2)).toLocaleString("en", {
            minimumFractionDigits: 2,
        })
    }
    const getReportGLSave = async () => {
        setLoader(true);
        if (reportGL) {
            setModalVisibleGLSave(true);
            return setLoader(false);
        } else {
            return setLoader(false);
        }
    };
    const getReportMonthSave = async () => {
        setLoader(true);
        const resultMonth = await fcsService.getFcsFaBalMonth();
        if (resultMonth.data.length >= 0) {
            setDataBalMonth(resultMonth.data);
            setModalVisibleMonthSave(true);
            return setLoader(false);
        } else {
            notify("Сарын хаалт хийх боломжгүй", "warning");
            return setLoader(false);

        }
    };
    const onValueChangedDate = (e) => {
        setGlDate(e.value);
    }
    const jrChangeValue = (event) => {
        setJrNo(event.target.value);
    };
    const procFcsFaUpdate = async () => {
        setLoader(true);
        if (dataFcsMonth[0].year && dataFcsMonth[0].month) {
            try {
                const result = await fcsService.procFcsFaUpdate(dataFcsMonth[0].year, dataFcsMonth[0].month + 1);
                if (result) {
                    glList();
                    notify('Амжилттай элэгдэл бодогдлоо', "success", 2000);
                    return setLoader(false);
                } else {
                    return setLoader(false);
                }
            } catch (e) {
                notify(e.message, "error", 2000);
            }
        }
    };
    const setFaBalData = async () => {
        let result = confirm("<i>Та сарын хаалт хийхдээ итгэлтэй байна уу?</i>", "Баталгаажуулалт");
        result.then(async (dialogResult) => {
            setLoader(true);

            if (dialogResult && dataBalMonth.length >= 0) {
                dataBalMonth.map((item) => {
                    item.created_user_id = user.id;
                })
                const fcsMonth = await fcsService.getAllFcsMonth('FA');
                fcsMonth.map(e => {
                    if (e.month < 13) {
                        e.month = e.month + 1
                    }
                })

                await fcsService.addFcsFaBal(dataBalMonth);
                await fcsService.editFcsMonth(fcsMonth[0]);

                notify('Амжилттай сарын хаалт хийлээ', "success", 2000);
                await glList();
                await procFcsFaUpdate();
                // setModalVisibleMonthSave(false);
                return setLoader(false);

            } else {
                return setLoader(false);
            }
        });
    }
    const setGLData = async () => {
        if (jrNo === null || glDate === null) return notify("хоосон байна", "warning");
        const date = new Date(glDate);//new e.changes[0].data.date_recpt;
        const year = date.getFullYear() * 1e4; // 1e4 gives us the the other digits to be filled later, so 20210000.
        const month = (date.getMonth() + 1) * 100;
        const yyyymm = year + month // `+ ''` to convert to string from number, 202206
        let date1 = reportGL[0].pyear + '/' + reportGL[0].pmonth
        let datetimenew = new Date(date1)
        let year1 = datetimenew.getFullYear() * 1e4; // 1e4 gives us the the other digits to be filled later, so 20210000.
        let month1 = (datetimenew.getMonth() + 1) * 100;
        const yyyymm_month = year1 + month1
        if (yyyymm === yyyymm_month && jrNo !== null) {
            if (dataFcsGLTran.length === 0) {
                let docdate = null
                let jr_key = null;
                reportGL.forEach(element => {
                    docdate = glDate;
                    jr_key = jrNo;
                });
                reportGL.map((item) => {
                    item.docdate = glDate;
                    item.jr_key = 'FA';
                    item.acct = item.account;
                    item.descr = item.description;
                    item.brn = item.docno;
                    item.docno = jrNo;
                    item.orgcode = item.cust_code;
                    item.created_user_id = user.id;
                })
                await fcsService.addFcsGLTran(reportGL);
                glList();
                notify('Амжилттай ерөнхий дэвтэрлүү шилжүүллээ', "success", 2000);
            } else {
                notify('Тухайн сард та ерөнхйи дэвтэрлүү илгээсэн байна', "error", 2000);
            }
        } else {
            notify('Сонгосон огноо тайлант он сарын хугацаанд биш байна', "error", 2000);
        }
    }
    const style = {
        text: {
            color: "#2E4765", fontWeight: "700", fontSize: ".875rem"
        },
        box: {
            border: "none", backgroundColor: "#F6F6F6", borderRadius: ".5rem", padding: ".25rem .15rem", width: "100%"
        },
        box2: {
            border: "none",
            backgroundColor: "rgb(239, 239, 239)",
            borderRadius: ".5rem",
            padding: ".25rem .4rem",
            width: "100%"
        }
    }
    return (
        <React.Fragment>
            <div style={{padding: "0.5rem"}} ref={myRef}>
                <div>
                    <LoadPanelComponent
                        shadingColor="rgba(0,0,0,0.4)"
                        position={userListPosition}
                        visible={loader}
                        showPane={false}
                        message=''
                    />
                    <div>
                        <div className="col-12 row"
                             style={{justifyContent: "center", gap: '2rem', padding: '5px 15px'}}>
                            <div style={{marginLeft: 20, textTransform: "uppercase", marginTop: 10, ...style.text}}>
                                <h4> ЕРӨНХИЙ ДЭВТЭРЛҮҮ ШИЛЖҮҮЛЭХ ТАЙЛАН </h4></div>

                        </div>
                        {reportGL[0] &&
                            <div style={{display: 'flex', flexDirection: 'row', marginBottom: 20, marginTop: 20}}>
                                <div style={{display: 'flex', flexDirection: 'row', marginLeft: 10}}>
                                    <span style={{color: "#A3A3A3", marginRight: "0.5rem"}}>Тайлант он сар </span>
                                    <span style={{fontWeight: "700"}}>{reportGL[0].pyear}-{reportGL[0].pmonth}</span>
                                </div>
                                <div style={{display: 'flex', flexDirection: 'row', marginLeft: 30}}>
                                    <span style={{color: "#A3A3A3"}}>Байгууллага: </span>
                                    <span style={{

                                        fontWeight: "700",
                                        marginLeft: 10
                                    }}>{reportGL[0].aimag_name}-{reportGL[0].org_name}</span>
                                </div>
                            </div>
                        }
                        <div style={{marginLeft: 5, marginTop: 5}}>
                            <Button disabled={dataFcsGLTran.length === 0 ? false : true}
                                    text={dataFcsGLTran.length === 0 ? "Ерөнхий дэвтэрлүү шилжүүлэх" : "Ерөнхий дэвтэрлүү шилжүүлсэн"}
                                    onClick={() => getReportGLSave()} width={250}
                                    type={dataFcsGLTran.length === 0 ? "default" : "success"}
                                    style={{alignment: "center", borderRadius: '7px'}}/>
                            <Button text="Сарын хаалт хийх" onClick={() => getReportMonthSave()} width={160}
                                    type="default" style={{alignment: "center", borderRadius: '7px', marginLeft: 10}}/>
                        </div>
                        <Popup
                            width={500}
                            height={200}
                            minWidth={'50%'}
                            maxWidth={'50%'}
                            minHeight={'40%'}
                            visible={modalVisibleGLSave}
                            title={'Ерөнхий дэвтэрлүү шилжүүлэх'}
                            onHiding={() => setModalVisibleGLSave(false)}
                            resizeEnabled={true}
                            dragEnabled={true}
                            showTitle={true}
                            hideOnOutsideClick={true}
                        >
                            <ScrollView width='100%' height='100%'>
                                <div className={'card-body'}>
                                    <div style={{marginTop: 10}}>
                                        {reportGL[0] &&
                                            <div style={{display: 'flex', flexDirection: 'row', marginBottom: 20}}>
                                                <div style={{display: 'flex', flexDirection: 'row', marginLeft: '10%'}}>
                                                    <span
                                                        style={{fontSize: 14, fontWeight: 500}}>Тайлант он,сар: </span>
                                                    <span style={{
                                                        fontSize: 14,
                                                        fontWeight: 500,
                                                        marginLeft: 10
                                                    }}>{reportGL[0].pyear}-{reportGL[0].pmonth}</span>
                                                </div>

                                                <div style={{display: 'flex', flexDirection: 'row', marginLeft: '10%'}}>
                                                    <span style={{fontSize: 14, fontWeight: 500}}>Байгууллага: </span>
                                                    <span style={{
                                                        fontSize: 14,
                                                        fontWeight: 500,
                                                        marginLeft: 10
                                                    }}>{reportGL[0].aimag_name}-{reportGL[0].org_name}</span>
                                                </div>
                                            </div>
                                        }
                                        <div style={{display: 'flex', flexDirection: 'row', marginBottom: 20}}>
                                            <div
                                                style={{display: 'flex', alignItems: 'center', marginRight: 10}}>Огноо:
                                            </div>
                                            <DateBox value={glDate} type="date" onValueChanged={onValueChangedDate}
                                                     width={200}/>
                                            <div style={{display: 'flex', marginLeft: 10, marginRight: 10}}>Журнал
                                                дугаар:
                                            </div>
                                            <input type='string' className='law-search-input' style={{
                                                width: '50%',
                                                backgroundColor: '#FFFFFF',
                                                border: '1px solid #DDDDDD'
                                            }}
                                                   value={jrNo} onChange={jrChangeValue}/>

                                        </div>

                                        <div className="col-12 row"
                                             style={{justifyContent: "flex-end", gap: '2rem', padding: '5px 15px'}}>
                                            <Button text="Шилжүүлэх" onClick={() => setGLData()} width={150}
                                                    type="default" style={{alignment: "center", borderRadius: '7px'}}/>
                                        </div>

                                    </div>
                                </div>
                            </ScrollView>
                        </Popup>
                        <Popup
                            width={500}
                            height={'80%'}
                            minWidth={'70%'}
                            maxWidth={'70%'}
                            minHeight={'40%'}
                            visible={modalVisibleMonthSave}
                            title={'Үндсэн хөрөнгийн сарын хаалт хийх'}
                            onHiding={() => setModalVisibleMonthSave(false)}
                            resizeEnabled={true}
                            dragEnabled={true}
                            showTitle={true}
                            hideOnOutsideClick={true}
                        >
                            <ScrollView width='100%' height='100%'>
                                <div className={'card-body'}>

                                    <div style={{marginTop: 10}}>
                                        {dataBalMonth[0] &&
                                            <div style={{display: 'flex', flexDirection: 'row', marginBottom: 20}}>
                                                <div style={{display: 'flex', flexDirection: 'row', marginLeft: '10%'}}>
                                                    <span
                                                        style={{fontSize: 14, fontWeight: 500}}>Тайлант он,сар: </span>
                                                    <span style={{
                                                        fontSize: 14,
                                                        fontWeight: 500,
                                                        marginLeft: 10
                                                    }}>{dataBalMonth[0].year}-{dataBalMonth[0].month}</span>
                                                </div>

                                                <div style={{display: 'flex', flexDirection: 'row', marginLeft: '10%'}}>
                                                    <span style={{fontSize: 14, fontWeight: 500}}>Байгууллага: </span>
                                                    <span style={{
                                                        fontSize: 14,
                                                        fontWeight: 500,
                                                        marginLeft: 10
                                                    }}>{dataBalMonth[0].aimag_name}-{dataBalMonth[0].org_name}</span>
                                                </div>
                                            </div>
                                        }
                                        <div style={{marginTop: 10}}>
                                            <DataGrid
                                                dataSource={dataBalMonth}
                                                showBorders={true}
                                                allowColumnResizing={true}
                                                hoverStateEnabled={true}
                                                keyExpr="fa_id"
                                                noDataText='Дата байхгүй байна.'
                                            >
                                                <Column caption="ҮХ-ийн код" dataField="fanumber" dataType="string"/>
                                                <Column caption="ҮХ-ийн нэр" dataField="fa_name" dataType="string"/>
                                                <Column caption="Үйлдвэрлэгч" dataField="manuf" dataType="string"/>
                                                <Column caption="Байршил" dataField="location" dataType="string"/>
                                                <Column caption="Эзэмшигч" dataField="username" dataType="string"/>
                                                <Column caption="Тоо" dataField="qty" dataType="number"
                                                        format="#,##0.00"/>
                                                <Column caption="Нийт дүн" dataField="cost_tot" dataType="number"
                                                        format="#,##0.00"/>
                                                <Column caption="Капиталжуулах дүн" dataField="capital"
                                                        dataType="number" format="#,##0.00"/>
                                                <Column caption="Элэгдэл +-" dataField="depreciation" dataType="number"
                                                        format="#,##0.00"/>
                                                <Column caption="Элэгдэл /сарын" dataField="sarel" dataType="number"
                                                        format="#,##0.00"/>
                                                <Summary>
                                                    <TotalItem
                                                        column="qty"
                                                        summaryType="sum"
                                                        customizeText={customizeDate}/>
                                                    <TotalItem
                                                        column="cost_tot"
                                                        summaryType="sum"
                                                        customizeText={customizeDate}/>
                                                    <TotalItem
                                                        column="capital"
                                                        summaryType="sum"
                                                        customizeText={customizeDate}/>
                                                    <TotalItem
                                                        column="depreciation"
                                                        summaryType="sum"
                                                        customizeText={customizeDate}/>
                                                    <TotalItem
                                                        column="sarel"
                                                        summaryType="sum"
                                                        customizeText={customizeDate}/>
                                                </Summary>
                                                <Export enabled={true} allowExportSelectedData={false}/>
                                            </DataGrid>
                                        </div>
                                        <div className="col-12 row"
                                             style={{justifyContent: "flex-end", gap: '2rem', padding: '5px 15px'}}>
                                            <Button text="Хаалт хийх" onClick={() => setFaBalData()} width={150}
                                                    type="default" style={{alignment: "center", borderRadius: '7px'}}/>
                                        </div>

                                    </div>
                                </div>
                            </ScrollView>
                        </Popup>
                        <div className="card-body">
                            <DataGrid
                                dataSource={reportGL}
                                showBorders={true}
                                allowColumnResizing={true}
                                hoverStateEnabled={true}
                                keyExpr={null}
                                allowColumnReordering={true}
                                noDataText='Дата байхгүй байна.'
                                onExporting={onExporting}>
                                <GroupPanel visible={true}/>
                                <SearchPanel visible={true}/>
                                <Grouping autoExpandAll={true}/>
                                <Column caption="Данс" dataField="account" dataType="string"/>
                                <Column caption="Гүйлгээний утга" dataField="description" dataType="string"
                                        groupIndex={0}/>
                                <Column caption="Дебит" dataField="debit" dataType="number" format="#,##0.00"/>
                                <Column caption="Кредит" dataField="credit" dataType="number" format="#,##0.00"/>
                                <Column caption="Харилцагч" dataField="cust_code" dataType="string"/>
                                <Summary>
                                    <GroupItem
                                        column="debit"
                                        summaryType="sum"
                                        customizeText={customizeDate}
                                        showInGroupFooter={true}/>
                                    <GroupItem
                                        column="credit"
                                        summaryType="sum"
                                        customizeText={customizeDate}
                                        showInGroupFooter={true}/>
                                    <TotalItem
                                        column="debit"
                                        summaryType="sum"
                                        customizeText={customizeDate}/>
                                    <TotalItem
                                        column="credit"
                                        summaryType="sum"
                                        customizeText={customizeDate}/>
                                </Summary>
                                <Export enabled={true} allowExportSelectedData={false}/>
                            </DataGrid>
                        </div>
                    </div>
                </div>
            </div>

        </React.Fragment>
    )
}

export default FaGLReport;

import React, {useState, useEffect, useRef, Component, useContext} from "react";
import TopDisplay from "./components/topDisplay";
import {DonutComponent} from "./components/donut";
import MiddleDisplay from "./components/middleDisplay";
import LoadPanelComponent from "./components/loadPanel";
import BudgetService from "../../services/api/budget";
import DataGrid, {
    Column,
    Summary,
    TotalItem
} from 'devextreme-react/data-grid';
import _ from "lodash";
import {Link, useHistory, useLocation, withRouter} from "react-router-dom";
import SelectBox from "devextreme-react/select-box";
import {getYears, nFormatter, numberWithCommas} from "../../util";
import {CURRENT_YEAR} from "../../shared/constants";
import PivotGridDataSource from 'devextreme/ui/pivot_grid/data_source';
import DataSource from 'devextreme/data/data_source';

//
import Chart, {
    ArgumentAxis,
    CommonSeriesSettings,
    Legend,
    Series,
    Format,
    Export,
    Tooltip,
    ValueAxis,
    ConstantLine,
    Label, SeriesTemplate, Crosshair, Font
} from 'devextreme-react/chart';
import PivotGrid, {
    FieldChooser,
} from 'devextreme-react/pivot-grid';
import {Connector} from "devextreme-react/pie-chart";
import Empty from "./components/empty";
import organization from "../../services/api/organization";
import SkeletonLoading from "../../components/SkeletonLoading/SkeletonLoading";
import {LoadPanel} from "devextreme-react/load-panel";
import {UserContext} from "../../hooks/UserContext";

function gridCellData(gridData) {
    return gridData.data[gridData.column.dataField.toLowerCase()];
}

function dataCell(cellData) {
    return (
        <div className={cellData.column.caption === 'Гүйцэтгэл' ? 'main-orange-text' : 'dark-blue-text'}>{nFormatter(gridCellData(cellData), 2)}</div>
    );
}

class Detail extends Component {

    static contextType = UserContext;

    constructor(props) {
        super(props);

        this.state = {
            year: CURRENT_YEAR,
            type: props.econ,
            // color: props.econ === '1' ? '#4CAE4C' : props.econ === '2' ? '#FF6961' : '#6CA0DC',
            color: '#1C4E80',
            colorClass: 'dark-blue-text',
            // colorClass: props.econ === '1' ? 'green-text' : props.econ === '2' ? 'red-text' : 'blue-text',
            text: props.econ === '1' ? 'Нийт орлого ба тусламжийн дүн' : props.econ === '2' ? 'Нийт зарлага ба цэвэр зээл' : 'Зардлыг санхүүжүүлэх эх үүсвэр',
            loading1: false,
            loading2: false,
            loading3: false,
            loading4: false,
            loading5: false,
            loading6: false,
            loading7: false,
            organizationSelected: false,
            budgetTotal: [],
            budgetSub: [],
            budgetTenYears: [],
            budgetTenHigh: {},
            budgetTenLow: {},
            budgetOrgListEcon: [],
            budgetOrgColumns: [],
            budgetOrgColumnsTotal: [],
            budgetOrgList: [],
            budgetSchedule: [],
            budgetOrgChart: [],
            budgetOrgEventAgenda: [],
            budgetAgendaConfirmed: [],
            budgetAgendaPerformance: [],
            orgList: [],
            reserveOrgList: [],
            organization: [],
            selectedOrgId: null,
            tenYearLoader: false,
            zeeliinErgenTololtEseh: false
        };
    };

    getBudgetTotal = (year, orgId) => {
        this.setState({
            loading1: true
        }, () => {
            BudgetService.econMainDataUpdated(year, this.state.type, orgId).then(data => {
                let budgetTotal = data.data || [];
                this.setState({
                    loading1: false,
                    budgetTotal: budgetTotal
                });
            }).catch(e => {
                this.setState({
                    loading1: false,
                    budgetTotal: []
                });
            });
        })
    }

    getBudgetSub = (year, orgId) => {
        this.setState({
            loading2: true
        }, () => {
            BudgetService.econ2Data(year, this.state.type, orgId).then(data => {
                let budgetSub = data.data || [];
                this.setState({
                    loading2: false,
                    budgetSub: budgetSub
                });
            }).catch(e => {
                this.setState({
                    loading2: false,
                    budgetSub: []
                });
            });
        });
    }

    getBudgetTenYear = async (orgId) => {
        this.setState({
            loading4: true,
            tenYearLoader: true,
        }, () => {
            if (this.state.type === '1') {
                BudgetService.econGraphOnlyOrlogo().then(data => {
                    let budgetTenYears = data.data || [];
                    this.setState({
                        budgetTenYears: budgetTenYears,
                        // budgetTenHigh: high,
                        // budgetTenLow: low,
                        loading4: false,
                        tenYearLoader: false
                    })
                    // console.log("ten years",budgetTenYears)
                }).catch(e => {
                    this.setState({
                        budgetTenYears: [],
                        budgetTenHigh: {},
                        budgetTenLow: {},
                        loading4: false,
                        tenYearLoader: false
                    })
                });
            } else {
                BudgetService.econGraph(this.state.type, orgId).then(data => {
                    let budgetTenYears = data.data || [];


                    // let low = _.minBy(budgetTenYears, function (o) {return o.amount}) || {};
                    // let high = _.maxBy(budgetTenYears, function (o) {return o.amount}) || {};

                    // console.log(high, low)
                    // console.log('budgetTenYears -> ', budgetTenYears);

                    this.setState({
                        budgetTenYears: budgetTenYears,
                        // budgetTenHigh: high,
                        // budgetTenLow: low,
                        loading4: false,
                        tenYearLoader: false
                    })
                    // console.log("ten years",budgetTenYears)
                }).catch(e => {
                    this.setState({
                        budgetTenYears: [],
                        budgetTenHigh: {},
                        budgetTenLow: {},
                        loading4: false,
                        tenYearLoader: false
                    })
                });
            }
        })
    }

    getBudgetOrgListEcon1 = (year, orgId) => {
        this.setState({
            loading3: true
        }, () => {
            BudgetService.getBudgetStatByOrgEcon1(this.state.type, year, orgId).then(data => {
                let budgetOrgListEcon = data.data || [];

                let formattedData = _.map(budgetOrgListEcon, (item, index) => {
                    return {
                        name: `${item.org_name} /${item.aimag_name}/`,
                        amount: item.amount*1
                    }
                });

                this.setState({
                    loading3: false,
                    budgetOrgListEcon: formattedData
                })
                // console.log('budgetOrgListEcon',budgetOrgListEcon);
            }).catch(e => {
                // console.log(e);
                this.setState({
                    loading3: false,
                    budgetOrgListEcon: []
                })
            })
        })
    }

    getBudgetOrgListEcon2 = (year, orgId) => {
        this.setState({
            loading5: true
        }, () => {
            BudgetService.getBudgetStatByOrgEcon2(this.state.type, year, orgId).then(data => {
                let budgetOrgList = data.data || [];
                let columns = [];
                let budgetOrgColumnsTotal = [];

                const formattedData = _.chain(budgetOrgList)
                    .groupBy((item) => {
                        return [item['aimag_name'], item['org_name']];
                    })
                    .map((value, key) => {
                        let obj = {
                            name: key
                        }

                        _.map(value, (item, index) => {
                            obj[`${item.econ_category_code_2}-${item.budget_status_id}`] = item.amount || 0;

                            const found = _.find(columns, (o) => {
                                return o.field === item.econ_category_code_2
                            });

                            if (!found) {
                                let name = 'Тодотгосон';
                                let status = 0;
                                if (item.budget_status_id === 5 ) {
                                    name = 'Тодотгосон';
                                    status = 5;
                                } else if (item.budget_status_id === 4) {
                                    name = 'Батлагдсан';
                                    status = 4;
                                } else if(item.budget_status_id === 9){
                                    name = 'Тодотгосон 2';
                                    status = 9;
                                }

                                budgetOrgColumnsTotal.push({
                                    name: name,
                                    field: `${item.econ_category_code_2}-${status}`
                                });

                                budgetOrgColumnsTotal.push({
                                    name: 'Гүйцэтгэл',
                                    field: `${item.econ_category_code_2}-7`
                                })

                                columns.push({
                                    name: item.econ_category_name_2,
                                    field: item.econ_category_code_2,
                                    sub: [
                                        {
                                            name: name,
                                            field: `${item.econ_category_code_2}-${status}`
                                        },
                                        {
                                            name: 'Гүйцэтгэл',
                                            field: `${item.econ_category_code_2}-7`
                                        }
                                    ]
                                })
                            }
                        });
                        return obj
                    })
                    .value();

                // console.log('columns', columns)

                this.setState({
                    loading5: false,
                    budgetOrgColumns: columns,
                    budgetOrgList: formattedData,
                    budgetOrgColumnsTotal
                });
            }).catch(e => {
                this.setState({
                    loading5: false,
                    budgetOrgColumns: [],
                    budgetOrgList: []
                });
            })
        })
    }

    getBudgetEventAgenda = (year, orgId) => {
        this.setState({
            loading6: true
        }, () => {
            BudgetService.getBudgetOrgEventAgenda(this.state.type, year, orgId).then(data => {
                let budgetOrgEventAgenda = data.data || [];

                this.setState({
                    loading6: false,
                    budgetOrgEventAgenda: budgetOrgEventAgenda
                })
            }).catch(e => {
                console.log(e);
                this.setState({
                    loading6: false,
                    budgetOrgEventAgenda: []
                })
            })
        })
    }

    getBudgetOrgList = () => {
        BudgetService.getBudgetOrgList().then(data => {
            let orgList = data.data || [];
            // console.log('data.data -> ', data.data)
            this.setState({
                orgList
            })
            this.setState({
                reserveOrgList: orgList,
            })
        }).catch(e => {
            console.log(e);
            this.setState({
                orgList: []
            })
        })
    }

    getBudgetOrganization = () => {
        BudgetService.getBudgetOrganization().then(data => {
            let organizationList = data.data || [];
            // console.log('data.data -> ', data.data);
            this.setState({
                organization: organizationList
            });
            // console.log(this.state.organization)
        }).catch(e => {
            console.log(e);
            this.setState({
                organization: []
            })
        })
    }

    getBudgetSchedule = (year, orgId) => {
        this.setState({
            loading7: true
        }, () => {
            BudgetService.getBudgetSchedule(this.state.type, year, orgId).then(data => {
                // console.log(data.data)
                let budgetSchedule = data.data || [];

                let formattedData = [];
                budgetSchedule.map((item) => {
                    let found = _.find(formattedData, {budget_month: item.budget_month});
                    if (found) {
                        if (item.budget_status_id === 6) {
                            found.schedule_amount = item.amount*1;
                        } else {
                            found.performance_amount = item.amount*1;
                        }
                    } else {
                        if (item.budget_status_id === 6) {
                            formattedData.push({
                                budget_month: item.budget_month+'-р сар',
                                schedule_amount: item.amount*1,
                                performance_amount: 0
                            })
                        } else {
                            formattedData.push({
                                budget_month: item.budget_month+'-р сар',
                                schedule_amount: 0,
                                performance_amount: item.amount*1
                            })
                        }
                    }

                })

                for (let i=0; i<formattedData.length; i++) {
                    if (formattedData[i].schedule_amount < 0) {
                        this.setState({ ...this.state, zeeliinErgenTololtEseh: true })
                        let o = formattedData[i]
                        delete Object.assign(o, { ['minus_amount']: o['schedule_amount']})['schedule_amount'];
                    }
                }

                this.setState({
                    loading7: false,
                    budgetSchedule: formattedData
                })
            }).catch(e => {
                console.log(e)
                this.setState({
                    loading7: false,
                    budgetSchedule: []
                })
            })
        })

    }

    getBudgetAgenda = (year, orgId) => {
        BudgetService.getBudgetByAgenda(this.state.type, year, orgId).then(res => {
            let budgetAgenda = res.data;

            let budgetAgendaConfirmed = _.filter(budgetAgenda, (o) => {
                return o.budget_status_id !== 7
            });
            // console.log("end budget agenda data irne",budgetAgendaConfirmed)

            let budgetAgendaPerformance = _.filter(budgetAgenda, (o) => {
                return o.budget_status_id === 7
            });

            this.setState({
                budgetAgendaConfirmed,
                budgetAgendaPerformance
            })
        }).catch(e => {
            console.log(e)
            this.setState({
                budgetAgendaConfirmed: [],
                budgetAgendaPerformance: []
            })
        });
    }

    componentDidMount() {
        this.getBudgetOrgList();
        this.getBudgetOrganization();
        this.getBudgetTotal(this.state.year, this.state.selectedOrgId);
        this.getBudgetSub(this.state.year, this.state.selectedOrgId);
        this.getBudgetTenYear(this.state.selectedOrgId);
        this.getBudgetOrgListEcon1(this.state.year, this.state.selectedOrgId);
        this.getBudgetOrgListEcon2(this.state.year, this.state.selectedOrgId);
        this.getBudgetEventAgenda(this.state.year, this.state.selectedOrgId);
        this.getBudgetSchedule(this.state.year, this.state.selectedOrgId);
        this.getBudgetAgenda(this.state.year, this.state.selectedOrgId);
    }

    selectYear = (year) => {
        this.setState({year: year}, () => {
            this.getBudgetTotal(year, this.state.selectedOrgId);
            this.getBudgetSub(year, this.state.selectedOrgId);
            this.getBudgetTenYear(this.state.selectedOrgId);
            this.getBudgetOrgListEcon1(year, this.state.selectedOrgId);
            this.getBudgetOrgListEcon2(year, this.state.selectedOrgId);
            this.getBudgetEventAgenda(year, this.state.selectedOrgId);
            this.getBudgetSchedule(this.state.year, this.state.selectedOrgId);
            this.getBudgetAgenda(this.state.year, this.state.selectedOrgId);
        });
    };

    selectOrg = (orgId) => {
        this.setState({selectedOrgId: orgId}, () => {
            // console.log(this.state.selectedOrgId)

            this.getBudgetTotal(this.state.year, this.state.selectedOrgId);
            this.getBudgetSub(this.state.year, this.state.selectedOrgId);
            this.getBudgetTenYear(this.state.selectedOrgId);
            this.getBudgetOrgListEcon1(this.state.year, this.state.selectedOrgId);
            this.getBudgetOrgListEcon2(this.state.year, this.state.selectedOrgId);
            this.getBudgetEventAgenda(this.state.year, this.state.selectedOrgId);
            this.getBudgetSchedule(this.state.year, this.state.selectedOrgId);
            this.getBudgetAgenda(this.state.year, this.state.selectedOrgId);
        })
    };

    loadTopPanels = (type) => {
        switch(type) {
            case 'load':
                this.setState({loading1: true});
                this.setState({loading2: true});
                this.setState({loading3: true});
                this.setState({loading4: true});
                break;
            case 'unload':
                this.setState({...this.state, loading1: false});
                this.setState({loading2: false});
                this.setState({loading3: false});
                this.setState({loading4: false});
                break;
            default:
                throw new Error('loading failed')
        }

    }

    selectLocation = async (org_code) => {
        this.setState({orgList: this.state.reserveOrgList});
        await this.loadTopPanels('load');
        if (org_code) {
            let filteredOrgList = this.state.orgList.filter(item => {
                return item.org_code === org_code;
            })
            this.setState({orgList: filteredOrgList});
            this.setState({organizationSelected: true});
            setTimeout( async () => {
                await this.loadTopPanels('unload');
            }, 500);
        } else {
            setTimeout( async () => {
                this.setState({organizationSelected: false});
                await this.getBudgetOrgList();
                await this.loadTopPanels('unload');
            }, 500);
        }
    }

    render() {

        const dataSource = new PivotGridDataSource({
            fields: [{
                caption: 'Харьяа',
                width: 200,
                dataField: 'aimag_name',
                area: 'row',
                sortBySummaryField: 'Total',
            }, {
                caption: 'Байгууллага',
                dataField: 'org_name',
                width: 200,
                area: 'row',
            }, {
                caption: 'Төсөл хөтөлбөр',
                dataField: 'agenda_name',
                // width: 200,
                area: 'row',
            }, {
                caption: 'Зориулалт',
                dataField: 'event_name',
                // width: 200,
                area: 'row',
            }, {
                caption: 'Эдийн засгийн ангилал',
                dataField: 'econ_category_name_3',
                area: 'row',
            }, {
                caption: 'Эдийн засгийн ангилал',
                dataField: 'econ_category_name_4',
                area: 'row',
            }, {
                caption: 'Эдийн засгийн ангилал',
                dataField: 'econ_category_name',
                area: 'row',
            }, {
                caption: 'budget_status_name',
                dataField: 'budget_status_name',
                sortOrder: 'desc',
                width: 150,
                area: 'column',
            }, {
                caption: 'Дүн',
                dataField: 'amount',
                width: 150,
                dataType: 'number',
                summaryType: 'sum',
                customizeText: function (cellInfo) {
                    return nFormatter(cellInfo.value || 0, 2);
                },
                area: 'data',
            }],
            store: this.state.budgetOrgEventAgenda,
        });

        function isDataCell(cell) {
            return (cell.area === 'data' && cell.rowType === 'D' && cell.columnType === 'D');
        }

        function isTotalCell(cell) {
            return (cell.type === 'T' || cell.type === 'GT' || cell.rowType === 'T' || cell.rowType === 'GT' || cell.columnType === 'T' || cell.columnType === 'GT');
        }

        function getCssStyles({ fill, font, bold }) {
            return {
                'background-color': `#${fill}`,
                'color': `#${font}`,
                'font-weight': bold ? 'bold' : undefined
            }
        }

        function getConditionalAppearance(cell) {
            const { columnPath } = cell;

            if (columnPath) {
                if (columnPath[0] === 'Гүйцэтгэл') {
                    return { font: 'EA6A47' }
                } else {
                    return { font: '1C4E80' }
                }
            }


            return {}
        }

        const onCellPrepared = ({ cell, area, cellElement }) => {
            cell.area = area;

            if (isDataCell(cell) || isTotalCell(cell)) {
                const appearance = getConditionalAppearance(cell);
                Object.assign(cellElement.style, getCssStyles(appearance))
            }
        };

        const displayHandler = (item) => {
            if (item) {
                if (this.state.organizationSelected) {
                    return `${item.aimag_name}`;
                } else {
                    return `${item.aimag_name} - ${item.org_name}`;
                }
            }
        }
        const labelTest = (e) => {
            return (
                <svg overflow="visible">
                    <text className="custom-bar-chart-text">{ nFormatter(e.value) }</text>
                </svg>
            )
        }

        const customFormat = (num) => {
            let isMinus = false;
            if (num < 0) {
                isMinus = true;
                num *= -1;
            }
            return num ? isMinus ? (num / 1e9).toFixed(2) * -1 : (num / 1e9).toFixed(2) : '';
        }

        const customizePoint = (pointInfo) => {
            return pointInfo.value < 0 ? {color: "#FBD6AA"} : {}
        }


        return <>
            <LoadPanelComponent position={{of: '#firstBox'}} visible={this.state.loading1} />
            <LoadPanelComponent position={{of: '#secondBox'}} visible={this.state.loading2} />
            <LoadPanelComponent position={{of: '#thirdBox'}} visible={this.state.loading3} />
            <LoadPanelComponent position={{of: '#fifthBox'}} visible={this.state.loading5} />
            <LoadPanelComponent position={{of: '#sixthBox'}} visible={this.state.loading6} />

            <div className={'card'}>
                <div className={'card-body'}>
                    <div className={'row'}>
                        <div className={'col-12 col-md-12 col-sm-12'} style={{display: 'flex'}}>
                            <div style={{display: 'flex', marginRight: 20}}>
                                <div style={{display: 'flex', alignItems: 'center', padding: '0 10px 0 10px'}}>Төсвийн жил:</div>
                                <div style={{width:100}}>
                                    <SelectBox
                                        items={getYears(true)}
                                        defaultValue={this.state.year}
                                        onValueChanged={(e) => this.selectYear(e.value)}
                                        placeholder="сонгох"/>
                                </div>
                            </div>
                            {
                                this.context && this.context.user && this.context.user.id === 8 &&
                                <div style={{display: 'flex'}}>
                                    <div style={{padding: 10,marginRight: 10, display: 'flex', alignItems: 'center'}}>Байгууллага:</div>
                                    <div style={{width:350}}>
                                        <SelectBox
                                            items={this.state.organization}
                                            defaultValue={this.state.selectedOrgId}
                                            displayExpr={(item) => {return item && `${item.org_name}`}}
                                            valueExpr="org_code"
                                            showClearButton={true}
                                            searchEnabled={true}
                                            searchMode={'contains'}
                                            searchExpr={['org_name']}
                                            searchTimeout={200}
                                            minSearchLength={0}
                                            onValueChanged={(e) => this.selectLocation(e.value)}
                                            placeholder="сонгох"/>
                                    </div>
                                    <div style={{marginRight: 10, display: 'flex', alignItems: 'center', marginLeft: 30}}>Харьяа:</div>
                                    <div style={{width:350}}>
                                        <SelectBox
                                            items={this.state.orgList}
                                            defaultValue={this.state.selectedOrgId}
                                            displayExpr={displayHandler}
                                            valueExpr="org_id"
                                            showClearButton={true}
                                            searchEnabled={true}
                                            searchMode={'contains'}
                                            searchExpr={['aimag_name', 'org_name']}
                                            searchTimeout={200}
                                            minSearchLength={0}
                                            onValueChanged={(e) => this.selectOrg(e.value)}
                                            placeholder="сонгох"/>
                                    </div>
                                </div>

                            }
                            {
                                this.context && this.context.user && this.context.user.id !== 8 &&
                                <div style={{display: 'flex'}}>
                                    <div style={{width: 120, display: 'flex', alignItems: 'center'}}>Байгууллага:</div>
                                    <div style={{width: 300}}>
                                        {
                                            this.state.organization && this.state.organization.length != 0 &&
                                            <SelectBox dataSource={this.state.organization}
                                                       defaultValue={this.state.organization[0].org_code}
                                                       displayExpr="org_name"
                                                       valueExpr="org_code"
                                                       disabled={true}/>
                                        }
                                    </div>
                                    <div style={{width: 80, display: 'flex', alignItems: 'center', marginLeft: 30}}>Харьяа:</div>
                                    <div style={{width: 300}}>
                                        {
                                            this.state.orgList && this.state.orgList.length != 0 &&
                                            <SelectBox dataSource={this.state.orgList}
                                                       defaultValue={this.state.orgList[0].org_id}
                                                       displayExpr={displayHandler}
                                                       valueExpr="org_id"
                                                       disabled={true}/>
                                        }
                                    </div>
                                </div>
                            }
                        </div>
                        <div className={'col-6 col-md-6 col-sm-12'}>

                        </div>
                    </div>
                </div>
            </div>
            <div className={'row'}>
                <div className={'col-12'}>
                    <div className='card'>
                        <div className={'card-body'}>
                            <h5>{this.state.text}</h5>
                        </div>
                    </div>
                </div>
            </div>

            <div className={'row'}>
                <div className={'col-4 col-md-6 col-sm-12'}>
                    <div className='card'>
                        <TopDisplay
                            key={0}
                            color={'#1452b4'}
                            title = 'Төсвийн нийт дүн'
                            height={300}
                            bodyID={'firstBox'}
                            buttonProps = 'card-header-button green-text'
                            textColor={'dark-blue-text'}
                            data = {this.state.budgetTotal}
                        />
                    </div>
                </div>

                <div className={'col-4 col-md-6 col-sm-12'}>
                    <div className='card'>
                        <DonutComponent
                            textColor={'dark-blue-text'}
                            title='Дэд ангиллаар'
                            bodyID={'secondBox'}
                            height={300}
                            buttonProps='card-header-button dark-blue-text'
                            data={this.state.budgetSub}
                        />
                    </div>
                </div>

                <div className={'col-4 col-md-12 col-sm-12'}>
                    <div className='card'>
                        <DonutComponent
                            textColor={this.state.colorClass}
                            title={'Байгууллагаар'}
                            bodyID={'thirdBox'}
                            height={300}
                            buttonProps={'card-header0button green-text'}
                            data={this.state.budgetOrgListEcon}
                        />
                    </div>
                </div>
            </div>

            <div className={'row'}>
                <div className={'col-12'}>
                    <div className='card'>
                        {/*<MiddleDisplay*/}
                        {/*    title = 'Жилээр'*/}
                        {/*    buttonProps = 'card-header-button green-text'*/}
                        {/*    color={'#0091D5'}*/}
                        {/*    height={400}*/}
                        {/*    bodyID={'fourthBox'}*/}
                        {/*    // link = {linkProceed}*/}
                        {/*    data={this.state.budgetTenYears}*/}
                        {/*    high={this.state.budgetTenHigh}*/}
                        {/*    low={this.state.budgetTenLow}*/}
                        {/*/>*/}
                        <div className={'card-header'}>
                            <span>Жилээр</span>
                        </div>
                        <LoadPanelComponent position={{of: '#tenYearsPane'}} visible={this.state.tenYearLoader} />

                        <div id={'tenYearsPane'}>
                        {this.state.budgetTenHigh.econ_category_code === 0 ?
                            <Empty color={''} height={400}/>
                            :
                            <Chart
                                // palette = 'Soft Pastel'
                                dataSource = {this.state.budgetTenYears}
                                style={{width: '100%', height: 400}}
                                // format={nFormatter}
                            >
                                <CommonSeriesSettings
                                    argumentField={'budget_year'}
                                    valueField={'amount_confirmed'}
                                    type={'bar'}
                                    minBarSize={10}
                                    ignoreEmptyPoints={true}
                                    name={'budget_status_name'}
                                    color="#0091D5"
                                >
                                    <Label
                                        visible={true}
                                        rotationAngle={0}
                                        font={{
                                            size: 10
                                        }}
                                        // format={nFormatter}
                                        format={customFormat}
                                    >
                                        <Connector visible={true} />
                                    </Label>
                                </CommonSeriesSettings>
                                <ValueAxis position="left">
                                    <Label render={labelTest}></Label>
                                </ValueAxis>

                                {/*<Series*/}
                                {/*    valueField="amount_confirmed"*/}
                                {/*    color="#1C4E80"*/}
                                {/*    name="Батлагдсан"*/}
                                {/*/>*/}
                                <Series
                                    valueField="amount"
                                    color="#1C4E80"
                                    name="Тодотгосон"
                                />
                                <Series
                                    valueField="amount_performance"
                                    color="#CF5E3F"
                                    name="Гүйцэтгэл"
                                />
                                <Tooltip
                                    enabled={true}
                                    location = 'edge'
                                    customizeTooltip = {(pointInfo) => {
                                        return {
                                            text: `${pointInfo.seriesName} - ${nFormatter(pointInfo.value, 2)}`
                                        }
                                    }}
                                />


                                <Crosshair
                                    enabled={true}
                                    color="#949494"
                                    width={3}
                                    dashStyle="dot"
                                >
                                    <Label
                                        visible={true}
                                        backgroundColor="#949494"
                                    >
                                        <Font
                                            color="#fff"
                                            size={12}
                                        />
                                    </Label>
                                </Crosshair>

                                <Legend verticalAlignment="bottom" horizontalAlignment="center" visible={true}/>

                            </Chart>
                        }
                        </div>
                    </div>
                </div>
            </div>
            {/*Орлого талд төсөл хөтөлбөрөөр задрахгүй*/}
            {/*<div className={'row'}>*/}
            {/*    <div className={'col-6 col-md-12 col-sm-12'}>*/}
            {/*        <div className={'card'}>*/}
            {/*            <DonutComponent*/}
            {/*                textColor={'dark-blue-text'}*/}
            {/*                title='Төсөл хөтөлбөр'*/}
            {/*                bodyID={'secondBox'}*/}
            {/*                height={300}*/}
            {/*                buttonProps='card-header-button dark-blue-text'*/}
            {/*                data={this.state.budgetAgendaConfirmed}*/}
            {/*            />*/}
            {/*            /!*<div className={'card-header'}>*!/*/}
            {/*            /!*    <span>Төсөл хөтөлбөр</span>*!/*/}
            {/*            /!*</div>*!/*/}
            {/*            /!*{this.state.budgetAgendaConfirmed.length === 0 ?*!/*/}
            {/*            /!*    <Empty color={''} height={330}/>*!/*/}
            {/*            /!*    :*!/*/}
            {/*            /!*    <Chart*!/*/}
            {/*            /!*        palette = 'Soft Pastel'*!/*/}
            {/*            /!*        dataSource = {this.state.budgetAgendaConfirmed}*!/*/}
            {/*            /!*        style={{width: '100%', height: 330}}*!/*/}
            {/*            /!*        // format={nFormatter}*!/*/}
            {/*            /!*    >*!/*/}
            {/*            /!*        <CommonSeriesSettings*!/*/}
            {/*            /!*            argumentField={'name'}*!/*/}
            {/*            /!*            valueField={'amount'}*!/*/}
            {/*            /!*            type={'bar'}*!/*/}
            {/*            /!*            minBarSize={10}*!/*/}
            {/*            /!*            ignoreEmptyPoints={true}*!/*/}
            {/*            /!*        >*!/*/}

            {/*            /!*            <Label*!/*/}
            {/*            /!*                visible={true}*!/*/}
            {/*            /!*                rotationAngle={-90}*!/*/}
            {/*            /!*                font={{*!/*/}
            {/*            /!*                    size: 10*!/*/}
            {/*            /!*                }}*!/*/}
            {/*            /!*                format={nFormatter}*!/*/}
            {/*            /!*            >*!/*/}
            {/*            /!*                <Connector visible={true} />*!/*/}
            {/*            /!*            </Label>*!/*/}
            {/*            /!*        </CommonSeriesSettings>*!/*/}
            {/*            /!*        <ValueAxis position="left">*!/*/}
            {/*            /!*            <Label render={labelTest}></Label>*!/*/}
            {/*            /!*        </ValueAxis>*!/*/}
            {/*            /!*        <SeriesTemplate*!/*/}
            {/*            /!*            nameField={'name'}/>*!/*/}

            {/*            /!*        <Crosshair*!/*/}
            {/*            /!*            enabled={true}*!/*/}
            {/*            /!*            color="#949494"*!/*/}
            {/*            /!*            width={3}*!/*/}
            {/*            /!*            dashStyle="dot"*!/*/}
            {/*            /!*        >*!/*/}
            {/*            /!*            <Label*!/*/}
            {/*            /!*                visible={true}*!/*/}
            {/*            /!*                backgroundColor="#949494"*!/*/}
            {/*            /!*            >*!/*/}
            {/*            /!*                <Font*!/*/}
            {/*            /!*                    color="#fff"*!/*/}
            {/*            /!*                    size={12}*!/*/}
            {/*            /!*                />*!/*/}
            {/*            /!*            </Label>*!/*/}
            {/*            /!*        </Crosshair>*!/*/}

            {/*            /!*        <Legend visible = {false}/>*!/*/}
            {/*            /!*    </Chart>*!/*/}
            {/*            /!*}*!/*/}
            {/*        </div>*/}
            {/*    </div>*/}

            {/*    <div className={'col-6 col-md-12 col-sm-12'}>*/}
            {/*        <div className={'card'}>*/}
            {/*            <DonutComponent*/}
            {/*                textColor={'dark-blue-text'}*/}
            {/*                title='Төсөл хөтөлбөр гүйцэтгэл'*/}
            {/*                bodyID={'secondBox'}*/}
            {/*                height={300}*/}
            {/*                buttonProps='card-header-button dark-blue-text'*/}
            {/*                data={this.state.budgetAgendaPerformance}*/}
            {/*            />*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}

            <div className={'row'}>
                <div className={'col-6 col-md-12 col-sm-12'}>
                    <div className={'card'}>
                        <div className='card-header'>
                            <span>Төсвийн ангиллаар</span>
                        </div>
                        <div className={'card-body'}>
                            <div id={'fifthBox'} style={{paddingRight: 10}}>
                                {this.state.budgetOrgList.length === 0 ? <Empty color={this.state.colorClass} height={400} /> :
                                    <DataGrid
                                        dataSource={this.state.budgetOrgList}
                                        allowColumnResizing={true}
                                        style={{
                                            width: '100%',
                                            height: 400
                                        }}
                                        paging={{
                                            pageSize: 7
                                        }}
                                        showBorders={true}
                                        rowAlternationEnabled={true}
                                        noDataText = 'Дата байхгүй байна.'
                                    >
                                        <Column
                                            key={-1}
                                            dataField="name"
                                            minWidth={300}
                                            caption="Байгууллага нэр"
                                        />

                                        {this.state.budgetOrgColumns.map((item, index) => {
                                            return <Column
                                                key={index}
                                                caption={item.name}
                                            >
                                                {item.sub.map((item_sub, index_sub) => {
                                                    return <Column
                                                        key={index_sub}
                                                        dataField={item_sub.field}
                                                        width={150}
                                                        caption={item_sub.name}
                                                        cellRender={dataCell}
                                                    />
                                                })}
                                            </Column>
                                        })}

                                        <Summary>
                                            {this.state.budgetOrgColumnsTotal.map((item, index) => {
                                                return <TotalItem
                                                    key={index}
                                                    column={item.field}
                                                    cssClass={'og-datagrid-total'}
                                                    summaryType="sum"
                                                    customizeText={(e) => {
                                                        return `Нийт: ${nFormatter(e.value, 2)}`
                                                    }}
                                                />
                                                })}
                                        </Summary>
                                    </DataGrid>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className={'col-6 col-md-12 col-sm-12'}>
                    <div className={'card'}>
                        <div className='card-header'>
                            <span>Хуваарь</span>
                        </div>
                        <div className={'card-body'}>
                            <div id={'sixthBox'}>
                                {this.state.budgetSchedule?.length === 0 ? <Empty color={this.state.colorClass} height={400} /> :
                                    <Chart id="chart"
                                           // customizePoint={customizePoint}
                                           // palette = 'Soft Pastel'
                                        label={{
                                            rotationAngle: 90
                                        }}
                                        dataSource={this.state.budgetSchedule}
                                    >
                                        <CommonSeriesSettings
                                            argumentField="budget_month"
                                            type="bar"
                                            hoverMode="allArgumentPoints"
                                            selectionMode="allArgumentPoints"
                                        >
                                            <Label
                                                visible={true}
                                                rotationAngle={0}
                                                font={{
                                                    size: 8
                                                }}
                                                // format={nFormatter}
                                                format={customFormat}
                                            >
                                                <Connector visible={true} />
                                            </Label>
                                        </CommonSeriesSettings>
                                        <ValueAxis position="left">
                                            <Label render={labelTest}></Label>
                                        </ValueAxis>
                                        <Series
                                            valueField="schedule_amount"
                                            color={this.state.color}
                                            name="Хуваарь"
                                        >

                                            <Label
                                                visible={true}
                                                rotationAngle={0}
                                                font={{
                                                    size: 8
                                                }}
                                                // format={nFormatter}
                                                format={customFormat}
                                            >
                                                <Connector visible={true} />
                                            </Label>
                                        </Series>
                                        <Series
                                            valueField="performance_amount"
                                            // color="#CF5E3F" // THISTHISTHIS
                                            color="#CF5E3F"
                                            name="Гүйцэтгэл"
                                        />
                                        {
                                            this.state.zeeliinErgenTololtEseh &&
                                            <Series
                                                valueField="minus_amount"
                                                color="#FBD6AA"
                                                name="Зээлийн эргэн төлөлт"
                                            />
                                        }

                                        <Tooltip
                                            enabled={true}
                                            location = 'edge'
                                            customizeTooltip = {(pointInfo) => {
                                                return {
                                                    text: `${pointInfo.seriesName} - ${nFormatter(pointInfo.value, 2)}`
                                                }
                                            }}
                                        />

                                        <ArgumentAxis>
                                            <Label
                                                rotationAngle={45}
                                                overlappingBehavior="rotate"
                                            />
                                        </ArgumentAxis>
                                        <Legend verticalAlignment="bottom" horizontalAlignment="center" visible={true}/>
                                        <Export enabled={false} />
                                    </Chart>
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={'row'}>
                <div className={'col-12'}>
                    <div className={'card'}>
                        <div className='card-header'>
                            <span>Хөтөлбөр, зориулалт</span>
                        </div>
                        <div className={'card-body'}>
                            {/*<div style={{marginBottom: 10}}>*/}
                            {/*    <span>Байгууллага сонгох:</span>*/}
                            {/*</div>*/}
                            <PivotGrid
                                id="pivotgrid"
                                dataSource={dataSource}
                                allowSortingBySummary={true}
                                allowFiltering={true}
                                showBorders={true}
                                showColumnTotals={false}
                                showColumnGrandTotals={false}
                                showRowTotals={true}
                                showRowGrandTotals={true}
                                onCellPrepared={onCellPrepared}
                                fieldChooser={{
                                    enabled: false
                                }}
                                texts={{
                                    grandTotal: 'Нийт дүн',
                                    total: '{0} нийт дүн'
                                }}
                                // ref={(ref) => { this.pivotGrid = ref.instance; }}
                            >
                                {/*<FieldChooser enabled={true} height={400} />*/}
                            </PivotGrid>
                        </div>
                    </div>
                </div>
            </div>
        </>;
    }
}

export default withRouter(Detail);

import React, {useState, useEffect, useRef} from "react";
import {
    DataGrid,
    Column,
    FilterRow,
    HeaderFilter,
    Paging,
    Export,
    GroupPanel,
    Grouping, ColumnFixing, Summary, GroupItem, TotalItem
} from "devextreme-react/data-grid";
import notify from "devextreme/ui/notify";
import FinanceReportService from "../../../../services/api/financeReport";
import LoadPanelComponent from "../../../../components/LoadPanelComponent";
import {Workbook} from 'exceljs';
import saveAs from 'file-saver';
import {Selection} from "devextreme-react/data-grid";
import {exportDataGrid} from "devextreme/excel_exporter";
import {formatDate, numberWithCommas, numberWithCommas11} from "../../../../util";
import EmptyData from "../../../budget/components/empty";

const PayrollReportList = (props) => {
    const [dataSrc, setDataSrc] = useState([]);
    const [loader, setLoader] = useState(false);
    const myGridRef = useRef(null);
    const loadData = async () => {
        try {
            setLoader(true);
            let r = await FinanceReportService.getPayrollReport(props.year, props.selectedOrg, props.filterOrgText, props.selectedMonth);
            setDataSrc(r);
            setLoader(false);
        } catch (e) {
            setLoader(false);
            notify(e.message, "error", 2000);
        }
    }

    useEffect(() => {
        loadData();
    }, [props.year, props.filterOrgText, props.selectedOrg, props.selectedMonth,props.isPivot])

    const onCellPreparedHandler = (e) => {
        if (e.rowType === 'header') {
            e.cellElement.wordWrapEnabled =true;
            e.cellElement.style.color = "#15186A"
            e.cellElement.style.backgroundColor= "#E3E7F1"
            //e.cellElement.style.backgroundImage = `linear-gradient(to right, ${palettePayrollReport[0]}, ${palettePayrollReport[1]})`
        }
        if (e.rowType === 'data') {
            e.cellElement.style.color = "#15186A"
        }
    }
    const onExporting = (e) => {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Main sheet');
        exportDataGrid({
            component: e.component,
            worksheet,
            autoFilterEnabled: true,
            keepColumnWidths: true,
            topLeftCell: {row: 4, column: 2},
            customizeCell: ({gridCell, excelCell}) => {
                if (gridCell.rowType === 'header') {
                    excelCell.fill = {type: 'pattern', pattern: 'solid', fgColor: {argb: '7082F6'}};
                    excelCell.border = {
                        top: {style: 'thin', color: {argb: '2c2c2c'}},
                        left: {style: 'thin', color: {argb: '2c2c2c'}},
                        bottom: {style: 'thin', color: {argb: '2c2c2c'}},
                        right: {style: 'thin', color: {argb: '2c2c2c'}}
                    }
                }
                if (gridCell.rowType === 'group') {
                    excelCell.fill = {type: 'pattern', pattern: 'solid', fgColor: {argb: '63C1FE'}};
                }
                if (gridCell.rowType === 'totalFooter' && excelCell.value) {
                    excelCell.font.italic = true;
                }
                if (gridCell.rowType === 'data') {
                    excelCell.border = {
                        top: {style: 'thin', color: {argb: '2c2c2c'}},
                        left: {style: 'thin', color: {argb: '2c2c2c'}},
                        bottom: {style: 'thin', color: {argb: '2c2c2c'}},
                        right: {style: 'thin', color: {argb: '2c2c2c'}}
                    }
                }
            },
        }).then(() => {
            Object.assign(
                worksheet.getRow(2).getCell(8),
                { value: (props.filterOrgText === 'ALL' ? 'БҮХ БАЙГУУЛЛАГЫН ' : props.filterOrgText+ " ") + props.year +" ОНЫ ЦАЛИНГИЙН ТОВЧОО", font: { bold: true, size: 16, underline: 'double' } }
            );
        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer], {type: 'application/octet-stream'}), "OG-"+ props.year + '-' + props.filterOrgText  +"-"+ formatDate(new Date()) + ".xlsx");
            });
        });
        e.cancel = true;
    }

    return (
        <div id="load1">
            <LoadPanelComponent
                position={{of: '#load1'}}
                visible={loader}
            />
            {(dataSrc && !loader) ? (
                    <DataGrid
                        showBorders={true}
                        rowAlternationEnabled={true}
                        dataSource={dataSrc}
                        noDataText='Дата байхгүй байна.'
                        style={{fontFamily: "'Montserrat', sans-serif", fontWeight: 'bold'}}
                        showRowLines={true}
                        showColumnLines={true}
                        showColumnHeaders={true}
                        allowColumnResizing={true}
                        onCellPrepared={onCellPreparedHandler}
                        onExporting={onExporting}
                        columnAutoWidth={true}
                        wordWrapEnabled={true}
                        ref={myGridRef}
                    >
                        <Paging defaultPageSize={100}/>
                        <GroupPanel visible={true}/>
                        <Grouping autoExpandAll={true}/>
                        <Selection mode="single"/>
                        <FilterRow visible={true}/>
                        <HeaderFilter visible={true}/>
                        <ColumnFixing enabled={true}/>
                        <Column
                            cssClass="custom"
                            caption="Байгууллага"
                            dataField="name"
                            allowFiltering={true}
                            groupIndex={0}
                        >
                        </Column>
                        <Column
                            cssClass="custom"
                            dataField="budget_month"
                            dataType={'integer'}
                            caption={'Сар'}
                            allowFiltering={true}
                            groupIndex={1}
                        >
                        </Column>
                        <Column
                            cssClass="custom"
                            caption="Алба хаагчид"
                            dataField="employment_contract_name"
                            allowFiltering={true}
                            width={200}
                            fixed={true}
                        >
                        </Column>
                        <Column
                            cssClass="custom"
                            dataField="worker_count"
                            dataType={'integer'}
                            caption={'Алба хаагчийн тоо'}
                            allowFiltering={true}
                            alignment={'right'}
                        >
                        </Column>
                        <Column
                            cssClass="custom"
                            dataField="basic_salary"
                            dataType={'number'}
                            caption={'Үндсэн цалин'}
                            format="#,##0.00"
                            allowFiltering={true}
                        >
                        </Column>
                        <Column
                            cssClass="custom"
                            dataField="estimated_salary"
                            dataType={'number'}
                            caption={'Бодогдсон цалин'}
                            format="#,##0.00"
                            allowFiltering={true}
                        >
                        </Column>
                        <Column caption="Нэмэгдэл" alignment={'center'}>
                            <Column
                                cssClass="custom"
                                dataField="rank_increase"
                                dataType={'number'}
                                caption={'Цолны нэмэгдэл'}
                                format="#,##0.00"
                                allowFiltering={true}
                            >
                            </Column>
                            <Column
                                cssClass="custom"
                                dataField="many_years_increase"
                                dataType={'number'}
                                caption={'Удаан жил ажилласны нэмэгдэл'}
                                format="#,##0.00"
                                allowFiltering={true}
                            >
                            </Column>
                            <Column
                                cssClass="custom"
                                dataField="special_increase"
                                dataType={'number'}
                                caption={'Онцгой албаны нэмэгдэл'}
                                format="#,##0.00"
                                allowFiltering={true}
                            >
                            </Column>
                            <Column
                                cssClass="custom"
                                dataField="professional_increase"
                                dataType={'number'}
                                caption={'Мэргэжлийн зэргийн нэмэгдэл'}
                                format="#,##0.00"
                                allowFiltering={true}
                            >
                            </Column>
                            <Column
                                cssClass="custom"
                                dataField="authorized_increase"
                                dataType={'number'}
                                caption={'ЭБАТ-ын нэмэгдэл'}
                                format="#,##0.00"
                                allowFiltering={true}
                            >
                            </Column>
                            <Column
                                cssClass="custom"
                                dataField="secret_increase"
                                dataType={'number'}
                                caption={'Нууцын нэмэгдэл'}
                                format="#,##0.00"
                                allowFiltering={true}
                            >
                            </Column>
                            <Column caption="Улирлын үр дүнгийн урамшуулал" alignment={'center'}>
                                <Column
                                    cssClass="custom"
                                    dataField="performance_worker_count"
                                    dataType={'integer'}
                                    caption={'Алба хаагчийн тоо'}
                                    allowFiltering={true}
                                >
                                </Column>
                                <Column
                                    cssClass="custom"
                                    dataField="performance_increase"
                                    dataType={'number'}
                                    caption={'Дүн'}
                                    format="#,##0.00"
                                    allowFiltering={true}
                                >
                                </Column>
                            </Column>
                            <Column caption="Тушаалаар нэмсэн" alignment={'center'}>
                                <Column
                                    cssClass="custom"
                                    dataField="command_add_worker_count"
                                    dataType={'integer'}
                                    caption={'Алба хаагчийн тоо'}
                                    allowFiltering={true}
                                >
                                </Column>
                                <Column
                                    cssClass="custom"
                                    dataField="command_add_increase"
                                    dataType={'number'}
                                    caption={'Дүн'}
                                    format="#,##0.00"
                                    allowFiltering={true}
                                >
                                </Column>
                            </Column>
                            <Column caption="Тушаалаар хассан" alignment={'center'}>
                                <Column
                                    cssClass="custom"
                                    dataField="command_removed_worker_count"
                                    dataType={'integer'}
                                    caption={'Алба хаагчийн тоо'}
                                    allowFiltering={true}
                                >
                                </Column>
                                <Column
                                    cssClass="custom"
                                    dataField="command_removed_increase"
                                    dataType={'number'}
                                    caption={'Дүн'}
                                    format="#,##0.00"
                                    allowFiltering={true}
                                >
                                </Column>
                            </Column>
                            <Column caption="Ээлжийн амралт" alignment={'center'}>
                                <Column
                                    cssClass="custom"
                                    dataField="vacation_worker_count"
                                    dataType={'integer'}
                                    caption={'Алба хаагчийн тоо'}
                                    allowFiltering={true}
                                >
                                </Column>
                                <Column
                                    cssClass="custom"
                                    dataField="vacation"
                                    dataType={'number'}
                                    caption={'Дүн'}
                                    format="#,##0.00"
                                    allowFiltering={true}
                                >
                                </Column>
                            </Column>
                            <Column
                                cssClass="custom"
                                dataField="meal_allowance"
                                dataType={'number'}
                                caption={'Унаа хоолны хөнгөлөлт'}
                                format="#,##0.00"
                                allowFiltering={true}
                            >
                            </Column>
                            <Column
                                cssClass="custom"
                                dataField="other_increase"
                                dataType={'number'}
                                caption={'Бусад нэмэгдэл'}
                                format="#,##0.00"
                                allowFiltering={true}
                            >
                            </Column>
                            <Column
                                cssClass="custom"
                                dataField="payroll_increase"
                                dataType={'number'}
                                caption={'Цалингийн сангаас олгосон бусад олговор /лист, илүү цаг г.м/'}
                                format="#,##0.00"
                                allowFiltering={true}
                            >
                            </Column>
                        </Column>
                        <Column
                            cssClass="custom"
                            dataField="total_salary"
                            dataType={'number'}
                            caption={'Нийт цалин нэмэгдлийн дүн'}
                            format="#,##0.00"
                            allowFiltering={true}
                        >
                        </Column>
                        <Column
                            cssClass="custom"
                            dataField="aos_emd"
                            dataType={'number'}
                            caption={'АО-с ЭМД'}
                            format="#,##0.00"
                            allowFiltering={true}
                        >
                        </Column>
                        <Summary>
                            <GroupItem
                                column="worker_count"
                                summaryType="sum"
                                customizeText={(data) => {
                                    return `${numberWithCommas11(data.value)}`
                                }}
                                showInGroupFooter={true}/>
                            <GroupItem
                                column="basic_salary"
                                summaryType="sum"
                                customizeText={(data) => {
                                    return `${numberWithCommas(data.value)}`
                                }}
                                showInGroupFooter={true}/>
                            <GroupItem
                                column="estimated_salary"
                                summaryType="sum"
                                customizeText={(data) => {
                                    return `${numberWithCommas(data.value)}`
                                }}
                                showInGroupFooter={true}/>
                            <GroupItem
                                column="rank_increase"
                                summaryType="sum"
                                customizeText={(data) => {
                                    return `${numberWithCommas(data.value)}`
                                }}
                                showInGroupFooter={true}/>
                            <GroupItem
                                column="many_years_increase"
                                summaryType="sum"
                                customizeText={(data) => {
                                    return `${numberWithCommas(data.value)}`
                                }}
                                showInGroupFooter={true}/>
                            <GroupItem
                                column="special_increase"
                                summaryType="sum"
                                customizeText={(data) => {
                                    return `${numberWithCommas(data.value)}`
                                }}
                                showInGroupFooter={true}/>
                            <GroupItem
                                column="professional_increase"
                                summaryType="sum"
                                customizeText={(data) => {
                                    return `${numberWithCommas(data.value)}`
                                }}
                                showInGroupFooter={true}/>
                            <GroupItem
                                column="authorized_increase"
                                summaryType="sum"
                                customizeText={(data) => {
                                    return `${numberWithCommas(data.value)}`
                                }}
                                showInGroupFooter={true}/>
                            <GroupItem
                                column="secret_increase"
                                summaryType="sum"
                                customizeText={(data) => {
                                    return `${numberWithCommas(data.value)}`
                                }}
                                showInGroupFooter={true}/>
                            <GroupItem
                                column="performance_worker_count"
                                summaryType="sum"
                                customizeText={(data) => {
                                    return `${numberWithCommas11(data.value)}`
                                }}
                                showInGroupFooter={true}/>
                            <GroupItem
                                column="performance_increase"
                                summaryType="sum"
                                customizeText={(data) => {
                                    return `${numberWithCommas(data.value)}`
                                }}
                                showInGroupFooter={true}/>
                            <GroupItem
                                column="command_add_worker_count"
                                summaryType="sum"
                                customizeText={(data) => {
                                    return `${numberWithCommas11(data.value)}`
                                }}
                                showInGroupFooter={true}/>
                            <GroupItem
                                column="command_add_increase"
                                summaryType="sum"
                                customizeText={(data) => {
                                    return `${numberWithCommas(data.value)}`
                                }}
                                showInGroupFooter={true}/>
                            <GroupItem
                                column="command_removed_worker_count"
                                summaryType="sum"
                                customizeText={(data) => {
                                    return `${numberWithCommas11(data.value)}`
                                }}
                                showInGroupFooter={true}/>
                            <GroupItem
                                column="command_removed_increase"
                                summaryType="sum"
                                customizeText={(data) => {
                                    return `${numberWithCommas(data.value)}`
                                }}
                                showInGroupFooter={true}/>
                            <GroupItem
                                column="vacation_worker_count"
                                summaryType="sum"
                                customizeText={(data) => {
                                    return `${numberWithCommas11(data.value)}`
                                }}
                                showInGroupFooter={true}/>
                            <GroupItem
                                column="vacation"
                                summaryType="sum"
                                customizeText={(data) => {
                                    return `${numberWithCommas(data.value)}`
                                }}
                                showInGroupFooter={true}/>
                            <GroupItem
                                column="meal_allowance"
                                summaryType="sum"
                                customizeText={(data) => {
                                    return `${numberWithCommas(data.value)}`
                                }}
                                showInGroupFooter={true}/>
                            <GroupItem
                                column="other_increase"
                                summaryType="sum"
                                customizeText={(data) => {
                                    return `${numberWithCommas(data.value)}`
                                }}
                                showInGroupFooter={true}/>
                            <GroupItem
                                column="payroll_increase"
                                summaryType="sum"
                                customizeText={(data) => {
                                    return `${numberWithCommas(data.value)}`
                                }}
                                showInGroupFooter={true}/>
                            <GroupItem
                                column="total_salary"
                                summaryType="sum"
                                customizeText={(data) => {
                                    return `${numberWithCommas(data.value)}`
                                }}
                                showInGroupFooter={true}/>
                            <GroupItem
                                column="aos_emd"
                                summaryType="sum"
                                customizeText={(data) => {
                                    return `${numberWithCommas(data.value)}`
                                }}
                                showInGroupFooter={true}/>
                            <TotalItem
                                column="worker_count"
                                summaryType="sum"
                                customizeText={(data) => {
                                    return `${numberWithCommas11(data.value)}`
                                }}
                            />
                            <TotalItem
                                column="basic_salary"
                                summaryType="sum"
                                customizeText={(data) => {
                                    return `${numberWithCommas(data.value)}`
                                }}
                            />
                            <TotalItem
                                column="estimated_salary"
                                summaryType="sum"
                                customizeText={(data) => {
                                    return `${numberWithCommas(data.value)}`
                                }}
                            />
                            <TotalItem
                                column="rank_increase"
                                summaryType="sum"
                                customizeText={(data) => {
                                    return `${numberWithCommas(data.value)}`
                                }}
                            />
                            <TotalItem
                                column="many_years_increase"
                                summaryType="sum"
                                customizeText={(data) => {
                                    return `${numberWithCommas(data.value)}`
                                }}
                            />
                            <TotalItem
                                column="special_increase"
                                summaryType="sum"
                                customizeText={(data) => {
                                    return `${numberWithCommas(data.value)}`
                                }}
                            />
                            <TotalItem
                                column="professional_increase"
                                summaryType="sum"
                                customizeText={(data) => {
                                    return `${numberWithCommas(data.value)}`
                                }}
                            />
                            <TotalItem
                                column="authorized_increase"
                                summaryType="sum"
                                customizeText={(data) => {
                                    return `${numberWithCommas(data.value)}`
                                }}
                            />
                            <TotalItem
                                column="secret_increase"
                                summaryType="sum"
                                customizeText={(data) => {
                                    return `${numberWithCommas(data.value)}`
                                }}
                            />
                            <TotalItem
                                column="performance_worker_count"
                                summaryType="sum"
                                customizeText={(data) => {
                                    return `${numberWithCommas11(data.value)}`
                                }}
                            />
                            <TotalItem
                                column="performance_increase"
                                summaryType="sum"
                                customizeText={(data) => {
                                    return `${numberWithCommas(data.value)}`
                                }}
                            />
                            <TotalItem
                                column="command_add_worker_count"
                                summaryType="sum"
                                customizeText={(data) => {
                                    return `${numberWithCommas11(data.value)}`
                                }}
                            />
                            <TotalItem
                                column="command_add_increase"
                                summaryType="sum"
                                customizeText={(data) => {
                                    return `${numberWithCommas(data.value)}`
                                }}
                            />
                            <TotalItem
                                column="command_removed_worker_count"
                                summaryType="sum"
                                customizeText={(data) => {
                                    return `${numberWithCommas11(data.value)}`
                                }}
                            />
                            <TotalItem
                                column="command_removed_increase"
                                summaryType="sum"
                                customizeText={(data) => {
                                    return `${numberWithCommas(data.value)}`
                                }}
                            />
                            <TotalItem
                                column="vacation_worker_count"
                                summaryType="sum"
                                customizeText={(data) => {
                                    return `${numberWithCommas11(data.value)}`
                                }}
                            />
                            <TotalItem
                                column="vacation"
                                summaryType="sum"
                                customizeText={(data) => {
                                    return `${numberWithCommas(data.value)}`
                                }}
                            />
                            <TotalItem
                                column="meal_allowance"
                                summaryType="sum"
                                customizeText={(data) => {
                                    return `${numberWithCommas(data.value)}`
                                }}
                            />
                            <TotalItem
                                column="other_increase"
                                summaryType="sum"
                                customizeText={(data) => {
                                    return `${numberWithCommas(data.value)}`
                                }}
                            />
                            <TotalItem
                                column="payroll_increase"
                                summaryType="sum"
                                customizeText={(data) => {
                                    return `${numberWithCommas(data.value)}`
                                }}
                            />
                            <TotalItem
                                column="total_salary"
                                summaryType="sum"
                                customizeText={(data) => {
                                    return `${numberWithCommas(data.value)}`
                                }}
                            />
                            <TotalItem
                                column="aos_emd"
                                summaryType="sum"
                                customizeText={(data) => {
                                    return `${numberWithCommas(data.value)}`
                                }}
                            />
                        </Summary>
                        <Export enabled={true} allowExportSelectedData={false}/>
                    </DataGrid>
                ) :
                <div style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    family: "'Montserrat', sans-serif"
                }}>
                   <EmptyData/>
                </div>
            }
        </div>
    )
}

export default PayrollReportList;

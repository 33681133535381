import React, {useState, useEffect, useContext, useRef} from 'react';
import {UserContext} from "../../hooks/UserContext";
import RefService from "../../services/api/refs";

import DocumentComponent from "./components/document";
import DataGridComponent from "./components/DataGridComponent";

import './styles.css';
import SelectBox from "devextreme-react/select-box";
function Statistics(props) {
    const { user } = useContext(UserContext);
    const [documents, setDocuments] = useState([]);
    const [documentName, setDocumentName] = useState();
    const [chosenDocument, setChosenDocument] = useState(null);
    const [economicCategoryArray, setEconomicCategoryArray] = useState([]);
    const [active, setActive] = useState({
        0: true,
        1: false,
        2: false,
        3: false,
        4: false,
    });

    const [govAgendaList, setGovAgendaList] = useState([]);
    const [govAgendaParentList, setGovAgendaParentList] = useState([]);
    const [govAgendaSecondList, setGovAgendaSecondList] = useState([]);
    const [govAgendaThirdList, setGovAgendaThirdList] = useState([]);
    const [govAgendaForthList, setGovAgendaForthList] = useState([]);
    const [govAgendaFifthList, setGovAgendaFifthList] = useState([]);
    const [secondValue, setSecondValue] = useState(false);
    const [thirdValue, setThirdValue] = useState(false);
    const [fourthValue, setFourthValue] = useState(false);
    const [fifthValue, setFifthValue] = useState(false);

    let agendaSelectedList = useRef([]);

    useEffect(async () => {
        const result = await RefService.getGovAgendas();
        const refEcon = await RefService.getRefEconomicCategory();
        const documents = result.data.filter(item => {
            return item.parent_id === null;
        })
        setEconomicCategoryArray(refEcon.data);
        setChosenDocument(documents[0].id);
        setDocuments(documents);
        console.log('barimt bichig -> ', documents);
        setDocumentName(documents && documents[0].name);

        const r1 = await RefService.getGovAgendas();
        // console.log(r1);
        const listData = [];
        r1.data.forEach(data => {
            if (!data.parent_id) {
                listData.push(data);
            }
        });
        setGovAgendaList(r1.data);
        setGovAgendaParentList(listData);
    },[]);

    const firstSelection = (data) => {
        // console.log("firstSelection: ", data.value);
        if (!data.value || data.value === null) {
            setThirdValue(false);
            setFourthValue(false);
            setFifthValue(false);
            setSecondValue(false);

        }
        const list = [];
        govAgendaList.forEach(el => {
            if (el.parent_id === data.value ) {
                list.push(el);
            }
        });

        if (list.length !== 0 && data.value) {
            setGovAgendaSecondList(list);
            setSecondValue(true);
        } else {
            govAgendaList.forEach(el => {
                if (el.id === data.value && el.level_number === 4) {
                    agendaSelectedList.current.push(el);
                }
            });
            setSecondValue(false);
            setThirdValue(false);
            setFourthValue(false);
            setFifthValue(false);
        }
    }

    const secondSelection = (data) => {
        // console.log("secondSelection: ", data.value);
        if (!data.value) {
            setThirdValue(false);
            setFourthValue(false);
            setFifthValue(false);
        }
        const list = [];
        govAgendaList.forEach(el => {
            if (el.parent_id === data.value ) {
                list.push(el);
            }
        });

        if (list.length !== 0 && data.value) {
            setGovAgendaThirdList(list);
            setThirdValue(true);
        } else {
            govAgendaList.forEach(el => {
                if (el.id === data.value && el.level_number === 4) {
                    agendaSelectedList.current.push(el);
                }
            });
            setThirdValue(false);
            setFourthValue(false);
            setFifthValue(false);
        }
    }

    const thirdSelection = (data) => {
        if (!data.value) {
            setFourthValue(false);
            setFifthValue(false);
        }
        const list = [];
        govAgendaList.forEach(el => {
            if (el.parent_id === data.value ) {
                list.push(el);
            }
        });

        if (list.length !== 0 && data.value) {
            setGovAgendaForthList(list);
            setFourthValue(true);
        } else {
            govAgendaList.forEach(el => {
                if (el.id === data.value && el.level_number === 4) {
                    agendaSelectedList.current.push(el);
                }
            });
            setFourthValue(false);
            setFifthValue(false);
        }
    }

    const fourthSelection = (data) => {
        if (!data.value) {
            setFourthValue(false);
            setFifthValue(true);
        }

        const list = [];
        govAgendaList.forEach(el => {
            if (el.parent_id === data.value ) {
                list.push(el);
            }
        });

        if (list.length !== 0 && data.value) {
            setGovAgendaFifthList(list);
            setFifthValue(true);
        } else {
            govAgendaList.forEach(el => {
                if (el.id === data.value && el.level_number === 4) {
                    agendaSelectedList.current.push(el);
                }
            });
            setFifthValue(false);
        }
    }

    return (
        <div>
            <div style = {{textAlign: 'center', color: '#1453B5', margin: '2rem 0'}} >
                {/*<h3>{user.organization.name}</h3>*/}
                <p style = {{fontSize: '1rem'}}>Илгээгдсэн төслүүд бодлогын баримт бичгээр ангилагдсан байдал.</p>
            </div>
            <div className = 'card' style={{padding: '1rem'}}>
                <div style={{display: 'flex', flex: 1, flexWrap: 'wrap', gap: '1rem'}}>
                    <div style = {{fontSize: '0.9rem', flex: 2, padding: '.5rem'}}>
                        <div style = {{margin: "0 auto 1.3rem auto", color: '#1453B5', textAlign: 'center'}}>
                            <h4>Бодлогын баримт бичгийн төрлүүд (5)</h4>
                        </div>
                        {
                            documents.map((item, index) => {
                                return (
                                    <div style={{}} className ={`documents ${active[index] && `activeButton`}`}
                                         onClick = {(e) => {
                                             setChosenDocument(item.id)
                                             e.preventDefault();
                                             setDocumentName(item.name);
                                             setActive(prev => {
                                                 prev = {
                                                     0: false,
                                                     1: false,
                                                     2: false,
                                                     3: false,
                                                     4: false,
                                                 }
                                                 return {
                                                     ...prev,
                                                     [index]: true
                                                 }
                                             });
                                         }}
                                    > {item.name}
                                    </div>
                                )
                            })
                        }

                        {/*<div>Бодогын баримт бичгийн загвар</div>*/}

                        <div className="dx-fieldset">
                            <div>
                                <div className="dx-field-label" style={{width: "100%"}}>Бодлогын баримт бичиг</div>
                                <div className="dx-field-label" style={{width: "100%"}}>Ангилал 1</div>
                                <div className="dx-field-value" style={{width: "100%"}}>
                                    <SelectBox dataSource={govAgendaParentList}
                                               placeholder="Бодлогын баримт бичиг сонгоно уу"
                                               showClearButton={true}
                                        // value={valueE1.current}
                                               width={"100%"}
                                               displayExpr={(item) => {
                                                   return item && `${item.id} - ${item.name}`
                                               }}
                                               onValueChanged={(e) => firstSelection(e)}
                                               valueExpr="id"/>
                                </div>
                                {secondValue && <div className="dx-field-label">Ангилал 2</div>}
                                <div className="dx-field-value" style={{width: "100%"}}>
                                    {secondValue &&
                                    <SelectBox dataSource={govAgendaSecondList}
                                               placeholder="Бодлогын баримт бичиг сонгоно уу"
                                               showClearButton={true}
                                        // value={valueE1.current}
                                               width={"100%"}
                                               displayExpr={(item) => {
                                                   return item && `${item.id} - ${item.name}`
                                               }}
                                               onValueChanged={(e) => secondSelection(e)}
                                               valueExpr="id"/>
                                    }
                                </div>
                                {thirdValue && <div className="dx-field-label">Ангилал 3</div>}
                                <div className="dx-field-value" style={{width: "100%"}}>
                                    {thirdValue &&
                                    <SelectBox dataSource={govAgendaThirdList}
                                               placeholder="Бодлогын баримт бичиг сонгоно уу"
                                               showClearButton={true}
                                        // value={valueE1.current}
                                               width={"100%"}
                                               displayExpr={(item) => {
                                                   return item && `${item.id} - ${item.name}`
                                               }}
                                               onValueChanged={(e) => thirdSelection(e)}
                                               valueExpr="id"/>
                                    }
                                </div>
                                {fourthValue && <div className="dx-field-label">Ангилал 4</div>}
                                <div className="dx-field-value" style={{width: "100%"}}>
                                    {fourthValue &&
                                    <SelectBox dataSource={govAgendaForthList}
                                               placeholder="Бодлогын баримт бичиг сонгоно уу"
                                               showClearButton={true}
                                        // value={valueE1.current}
                                               width={"100%"}
                                               displayExpr={(item) => {
                                                   return item && `${item.id} - ${item.name}`
                                               }}
                                               onValueChanged={(e) => fourthSelection(e)}
                                               valueExpr="id"/>
                                    }
                                </div>
                        
                                {fifthValue && <div className="dx-field-label">Ангилал 5</div>}
                                <div className="dx-field-value" style={{width: "100%"}}>
                                    {fifthValue &&
                                    <SelectBox dataSource={govAgendaFifthList}
                                               placeholder="Бодлогын баримт бичиг сонгоно уу"
                                               showClearButton={true}
                                        // value={valueE1.current}
                                               width={"100%"}
                                               displayExpr={(item) => {
                                                   return item && `${item.id} - ${item.name}`
                                               }}
                                               // onValueChanged={(e) => fifthSelection(e)}
                                               valueExpr="id"/>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style = {{ borderRadius: '0.3rem', padding: '1rem', border: '1px solid #1453B5', flex: 3}}>
                        <div className = 'doc-name'>
                            <h3>{documentName}</h3>
                        </div>
                        <div style = {{marginTop: '1rem', display: 'flex', justifyContent: 'center'}}>
                            <DocumentComponent chosenDocument={chosenDocument}/>
                        </div>
                    </div>
                </div>
            </div>
            <div className ='card' style={{padding: '1rem', position: 'relative'}}>
                <DataGridComponent chosenDocument={chosenDocument} econArr={economicCategoryArray}/>
            </div>
        </div>
    )
}

export default Statistics;
import React, { useState, useEffect } from "react";
import notify from "devextreme/ui/notify";
import BudgetService from "../../../../services/api/budget";
import PieChart from "devextreme-react/pie-chart";
import { Export, Label, Legend, Series, Tooltip, Connector } from "devextreme-react/chart";
import { budgetPalettes, numberWithCommas } from "../../../../util";
import _ from "lodash";
import LoadPanelComponent from "../../../budget/components/loadPanel";
import EmptyData from "../../../budget/components/empty";

const TowloruulehZadgaiSalbar = (props) => {
    const [doughnutData, setDoughnutData] = useState([]);
    const [loader, setLoader] = useState(true);

    const loadData = async () => {
        try {
            setLoader(true);
            let r = await BudgetService.getTowloruulehEdiinZasgiinAngilal(props.year, props.filterOrgText, props.chosenMinistry,props.orgId)
            setDoughnutData(r);
        } catch (e) {
            notify(e.message, "error", 2000);
        } finally {
            setLoader(false)
        }
    }
    // const pointClickHanlder = (e) => {
    //     props.pivotGridHanlder();
    // }
    const cstmText = (e) => {
        return `<span style="color: #f7f7fa">${numberWithCommas(e.value / 1000000)}<br/>${(e.percent * 100).toFixed(2)}%</span>`
    }
    const customizeTooltip = (arg) => {
        return {
            html: `<span class="tooltip-series-name">${arg.argumentText}: </span> ${numberWithCommas(arg.value / 1000000)} /${(arg.percent * 100).toFixed(2)}%/`
        };
    }

    const calculateTotal = (pieChart) => {
        let totalValue = pieChart.getAllSeries()[0].getAllPoints().reduce((s, p) => s + p.originalValue, 0);
        return `${numberWithCommas(totalValue / 1000000)}`;
    }

    const centerRender = (pieChart) => {
        return (
            <text textAnchor="middle" style={{ fontWeight: 600 }}>
                <tspan x="0" dy="-1em">Төсөв:</tspan>
                <tspan x="0" dy="1em">{calculateTotal(pieChart)}</tspan>
            </text>
        );
    }

    useEffect(() => {
        loadData();
    }, [props.year, props.filterMonthObject, props.filterOrgText, props.chosenMinistry,props.orgId])

    if (doughnutData?.length === 0 && !loader) {
        return <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                family: "Segoe UI",
            }}
        >
           <EmptyData/>
        </div>
    }
    return (
        <div id="load3">
            <LoadPanelComponent position={{ of: '#load3' }} visible={loader} />
            <PieChart
                type="doughnut"
                dataSource={doughnutData}
                // onPointClick={pointClickHanlder}
                resolveLabelOverlapping="hide"
                palette={_.find(budgetPalettes, { 'id': 2 }).color}
                centerRender={centerRender}
                onLegendClick={({ points }) => points[0].isVisible() ? points[0].hide() : points[0].show()}
            >
                <Series argumentField="econ_category_name_3" valueField="todotgoson">
                    <Label
                        visible={true}
                        position="columns"
                        customizeText={cstmText}>
                        <Connector visible={true} width={0.5} />
                    </Label>
                </Series>
                <Legend
                    orientation="horizontal"
                    itemTextPosition="right"
                    horizontalAlignment="center"
                    verticalAlignment="bottom"
                    columnCount={4} />
                <Tooltip
                    zIndex={10000}
                    enabled={true}
                    customizeTooltip={customizeTooltip}
                    font={{ color: '#fff' }} color="#555"
                    cornerRadius={6}
                    border={{ visible: false }}
                    location="edge"
                >
                </Tooltip>
            </PieChart>
        </div>
    )
}

export default TowloruulehZadgaiSalbar;

import React, { useState, useEffect, useRef, useCallback } from "react";
import notify from "devextreme/ui/notify";
import PlanService from "../../../services/api/plan";
import {
    Chart,
    Series,
    Tooltip,
    CommonSeriesSettings,
    Format,
    Label,
    ArgumentAxis,
    Legend, HorizontalLine, Crosshair
} from 'devextreme-react/chart';
import { numberWithCommas } from "../../../util";
import LoadPanelComponent from "../../budget/components/loadPanel";
import Toolbar, { Item } from 'devextreme-react/toolbar'
import { DropDownButton } from "devextreme-react";
import SelectBox from "devextreme-react/select-box";

const ScheduleOfferChart = (props) => {

    const [barChartData, setBarChartData] = useState([]);
    const [econData, setEconData] = useState([]);
    const [loader, setLoader] = useState(props.visibleChart);
    const chartRef = useRef(null);
    const [selectedEconCode, setSelectedEconCode] = useState('2');
    const [selectedEconName, setSelectedEconName] = useState('Нийт зарлага ба цэвэр зээлийн дүн');
    /*const paletteCollection = ['Material', 'Soft Pastel', 'Harmony Light', 'Pastel', 'Bright', 'Soft', 'Ocean', 'Office', 'Vintage', 'Violet', 'Carmine', 'Dark Moon', 'Soft Blue', 'Dark Violet', 'Green Mist'];
    const paletteLabel = { 'aria-label': 'Palette' };
    const [palette, setPalette] = useState('Soft Blue');*/
    //const [visibleChange, setVisibleChange] = useState(false);
    //const [visiblePerf, setVisiblePerf] = useState(false);
    const loadBarChartData = async () => {
        try {
            setLoader(true);
            //budgetYear, orgId,agendaEventId,econ_code
            let r = await PlanService.getScheduleOfferChart(props.budgetYear, props.orgId, props.agendaEventId, selectedEconCode);
            setBarChartData(r);
            setLoader(false);
        } catch (e) {
            setLoader(false);
            return notify(e.message, "warning", 3000);
        }
    }
    const loadEconData = async () => {
        try {
            setLoader(true);
            //budgetYear, orgId,agendaEventId,econ_code
            let r = await PlanService.getScheduleOfferChartEconList(props.budgetYear, props.orgId, props.agendaEventId);
            setEconData(r);
            setLoader(false);
        } catch (e) {
            setLoader(false);
            return notify(e.message, "warning", 3000);
        }
    }
    //props.econCode.substring(0,1)!=='4'
    const customizeTooltip = (arg) => {
        if (arg.seriesName === 'Өмнөх жилийн хуваарийн өөрчлөлт') {
            let r = barChartData[(arg.argument * 1 - 1) - 1];
            let q = ((arg.value - (r && r.prev_amt) > 0 ? arg.value / (r && r.prev_amt) : (r && r.prev_amt) / arg.value)).toFixed(2);
            let p = ((arg.value * 100 / (r && r.prev_amt)) - 100).toFixed(2);
            let d = (arg.value - (r && r.prev_amt));
            let f = d < 0 ? 'бага' : 'их';

            if (isFinite(q) && !isNaN(q)) {
                return {
                    text: `Хуваарь ${arg.argumentText}-р сар\n Өмнөх сараас\n\n Зөрүү: ${numberWithCommas(selectedEconCode && selectedEconCode.substring(0, 1) !== '4' ? d / 1000000 : d)}\n Хувь: ${p}%\n Өөрчлөлт: ${q} дахин ${f}`
                }
            } else {
                return {
                    text: `Тооцоолох боломжгүй`
                }
            }
        }
        else if (arg.seriesName === 'Өмнөх жилийн гүйцэтгэлийн өөрчлөлт') {
            let r = barChartData[(arg.argument * 1 - 1) - 1];
            let q = ((arg.value - (r && r.perf_amt) > 0 ? arg.value / (r && r.perf_amt) : (r && r.perf_amt) / arg.value)).toFixed(2);
            let p = ((arg.value * 100 / (r && r.perf_amt)) - 100).toFixed(2);
            let d = (arg.value - (r && r.prev_amt));
            let f = d < 0 ? 'бага' : 'их';

            if (isFinite(q) && !isNaN(q)) {
                return {
                    text: `Хуваарь ${arg.argumentText}-р сар\n Өмнөх сараас\n\n Зөрүү: ${numberWithCommas(selectedEconCode && selectedEconCode.substring(0, 1) !== '4' ? d / 1000000 : d)}\n Хувь: ${p}%\n Өөрчлөлт: ${q} дахин ${f}`
                }
            } else {
                return {
                    text: `Тооцоолох боломжгүй`
                }
            }
        }
        else if (arg.seriesName === 'Хуваарийн өөрчлөлт') {
            let r = barChartData[(arg.argument * 1 - 1) - 1];
            let q = ((arg.value - (r && r.amt) > 0 ? arg.value / (r && r.amt) : (r && r.amt) / arg.value)).toFixed(2);
            let p = ((arg.value * 100 / (r && r.amt)) - 100).toFixed(2);
            let d = (arg.value - (r && r.amt));
            let f = d < 0 ? 'бага' : 'их';
            let r1 = barChartData[(arg.argument * 1) - 1];
            let u = ((r1 && r1.prev_amt) - arg.value);
            let u1 = ((arg.value * 100 / (r1 && r1.prev_amt))).toFixed(2);
            if (isFinite(q) && !isNaN(q)) {
                return {
                    text: `Хуваарь ${arg.argumentText}-р сар\nЗөрүү: ${numberWithCommas(u / 1000000)}\n Xувь: ${u1}%\n--------------------\n Өмнөх сараас\n Зөрүү: ${numberWithCommas(selectedEconCode && selectedEconCode.substring(0, 1) !== '4' ? d / 1000000 : d)}\n Хувь: ${p}%\n Өөрчлөлт: ${q} дахин ${f}\n `
                }
            } else {
                return {
                    text: `Тооцоолох боломжгүй`
                }
            }
        }
        else {
            return {
                text: `${arg.seriesName}: ${numberWithCommas(selectedEconCode && selectedEconCode.substring(0, 1) !== '4' ? arg.value / 1000000 : arg.value)}`
            };
        }
    }


    const downloadChart = useCallback((format) => {
        chartRef.current.instance.exportTo('file', format.toLowerCase())
    }, [chartRef.current])

    const DropDownItem = useCallback((e) => {
        // console.log('test',e)
        return (
            // <div style={{paddingRight:'1em', paddingLeft:'1em', width:'150%'}}>{e.data.format}</div>
            <div>{e.data.format}</div>
        )
    }, [])

    const DownloadChartButton = useCallback(() => {
        return (
            <DropDownButton
                icon="download"
                items={[
                    {
                        format: 'PDF'
                    },
                    {
                        format: 'PNG'
                    },
                    {
                        format: 'SVG'
                    },
                    {
                        format: 'JPEG'
                    }
                ]}
                onItemClick={(e) => {
                    // console.log('clicked item: ',e)
                    downloadChart(e.itemData.format)

                }}
                displayExpr="format"
                itemComponent={DropDownItem}
                width={'5em'}
                style={{ paddingLeft: '1em', paddingRight: '1em', paddingTop: '0.5em' }}
                showArrowIcon={false}
            >

            </DropDownButton>
        )
    }, [DropDownItem])

    useEffect(() => {
        loadEconData();
        loadBarChartData();
    }, [props.budgetYear, props.orgId, props.agendaEventId, props.refresh])
    useEffect(() => {
        loadBarChartData();
    }, [selectedEconCode])

    /*const VisibleChangeButton = useCallback(() => {
        return (<Button
            onClick={(e) => setVisibleChange(prev => !prev)}
            render={() => {
                return visibleChange ? (
                    <CgCheckR size={"1rem"} color="#1a1976" />)
                    :
                    (
                        <CgBorderAll size={"1rem"} color="#1a1976" />
                    )
            }}
            hint={visibleChange ? 'Өөрчлөлт нуух' : 'Өөрчлөлт харах'}
        >
        </Button>)
    }, [visibleChange])*/

    /*const VisiblePerf = useCallback(() => {
        return (<Button
            onClick={(e) => setVisiblePerf(prev => !prev)}
            render={() => {
                return visiblePerf ? (
                    <CgCheckR size={"1rem"} color="#1a1976" />)
                    :
                    (
                        <CgBorderAll size={"1rem"} color="#1a1976" />
                    )
            }}
            hint={visiblePerf ? 'Гүйцэтгэл нуух' : 'Гүйцэтгэл харах'}
        >
        </Button>)
    }, [visiblePerf])*/

    const legendClickHandler = (e) => {
        const series = e.target;
        if (series.isVisible()) {
            series.hide();
        } else {
            series.show();
        }
    }
    function markerTemplate(item) {
        const color = item.series.isVisible() ? item.marker.fill : '#888';
        return (
            <svg>
                <rect x={0} y={0} width={12} height={12} fill={color}
                    rx={item.text.includes('өөрчлөлт') ? 6 : 0}
                    ry={item.text.includes('өөрчлөлт') ? 6 : 0}></rect>
            </svg>
        );
    }
    return (
        <div  style={{ padding: '5px', margin: 0 }} id="load" >
            <LoadPanelComponent position={{ of: '#load' }} visible={loader} />
            <Toolbar style={{ marginBottom: '10px' }}>
                {/*<Item location={'after'}>
                    <SelectBox
                        items={paletteCollection}
                        label={'Өнгө солих'}
                        inputAttr={paletteLabel}
                        defaultValue={palette}
                        onValueChanged={(e) => setPalette(e.value)}
                    />
                </Item>*/}
                {/*<Item location={'after'}>
                    <div style={{marginTop:'0.5em'}}><VisiblePerf/></div>
                </Item>
                <Item location={'after'}>
                    <div style={{marginTop:'0.5em'}}><VisibleChangeButton/></div>
                </Item>*/}
                <Item location={'after'}>
                    {
                        econData && econData.length !== 0 &&
                        <SelectBox items={econData}
                            width={600}
                            label={'ЭЗА'}
                            //labelMode={'hidden'}
                            //value={selectedEconCode}
                            //defaultValue={selectedEconCode}
                            displayExpr={(item) => {
                                return item && `${item.code} - ${item.name}`
                            }}
                            onValueChanged={(e) => { setSelectedEconCode(e.value.code); setSelectedEconName(e.value.name); }}
                            placeholder="сонгох"
                        />
                    }
                </Item>
                <Item
                    location={'after'}
                    render={DownloadChartButton}
                />
            </Toolbar>
            {
                barChartData && barChartData.length > 0 &&
                <Chart
                    ref={chartRef}
                    id="mySimpleBarChart"
                    dataSource={barChartData}
                    resolveLabelOverlapping="hide"
                    ignoreEmptyPoints={true}
                    onLegendClick={legendClickHandler}
                //palette={_.find(schedulePalettes, { 'id':   '0'}).color}
                >
                    <ArgumentAxis tickInterval={1}>
                        <Label format="fixedPoint" />
                    </ArgumentAxis>
                    <CommonSeriesSettings 
                        argumentField="budget_month" 
                        type="bar"
                        cornerRadius={8}
                        hoverMode="allArgumentPoints"
                        selectionMode="allArgumentPoints" ignoreEmptyPoints={true}>
                        <Label precision={0} visible={true} font={{ family: "Segoe UI" }} format={(value) => numberWithCommas(selectedEconCode && selectedEconCode.substring(0, 1) !== '4' ? value / 1000000 : value)}
                        >   
                       
                            <Format type="fixedPoint" precision={2} />
                        </Label>
                    </CommonSeriesSettings>
                    <Crosshair enabled={true}>
                        <HorizontalLine visible={false} />
                        <Label visible={true} />
                    </Crosshair>
                    <Series valueField="prev_amt" name={`${props.budgetYear - 1} - хуваарь`} barOverlapGroup="perfStack" color="#2C77FF" barPadding={0.2}/>
                    <Series valueField="prev_amt" name={`${props.budgetYear - 1} - хуваарийн өөрчлөлт`} type="spline" color="#2C77FF" dashStyle="solid" visible={false} />
                    <Series valueField="perf_amt" name={`${props.budgetYear - 1} - гүйцэтгэл`} barOverlapGroup="perfStack" color='#003696' visible={false} barPadding={0.6}/>
                    {/* <Series valueField="perf_amt" name={`${props.budgetYear - 1} - гүйцэтгэлийн өөрчлөлт`} type="spline" color="#264B96" dashStyle="solid" visible={false} /> */}
                    <Series valueField="amt" name={`${props.budgetYear} - хуваарь`} color="#FEA95E" barPadding={0.2}/>
                    <Series valueField="amt" name={`${props.budgetYear} - хуваарийн өөрчлөлт`} type="spline" color="#FEA95E" dashStyle="solid" visible={false} />

                    {/*<Tooltip enabled={true} location="edge" customizeTooltip={customizeTooltip}/>*/}
                    <Tooltip zIndex={100000} enabled={true} customizeTooltip={customizeTooltip} cornerRadius={6} font={{ family: "Segoe UI", color: '#fff' }} color="#555" location="edge">
                    </Tooltip>
                    <Legend verticalAlignment="bottom" horizontalAlignment="center" visible={true} markerRender={markerTemplate}/>
                    {/* <Title text={selectedEconName}/> */}
                </Chart>
            }

        </div>
    )
}

export default ScheduleOfferChart

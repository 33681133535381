import React from 'react';
import DropDownBox,{DropDownOptions} from 'devextreme-react/drop-down-box';
import TreeView from 'devextreme-react/tree-view';
import 'whatwg-fetch';

export default class TreeListComponent extends React.Component {
    constructor(props) {
        super(props);
        this.treeView = null;
        this.treeDataSource = this.props.data.column.lookup.dataSource;
        this.selectedRelations = this.props.data.value;
        this.state = {
            treeBoxValue: [],
            value: 'contains'
        };
        this.treeViewItemSelectionChanged = this.treeViewItemSelectionChanged.bind(this);
        this.syncTreeViewSelection = this.syncTreeViewSelection.bind(this);
        this.treeViewRender = this.treeViewRender.bind(this);
        // console.log('this.props.data.value',this.props.data.value);
        // console.log('treeDataSource  ',this.treeDataSource);
        // console.log('selectedAgendaRelations  ', this.selectedAgendaRelations);

    }

    componentDidMount() {
        // console.log("load treelist component")
        // selected: true

        // const treeView = this.treeView && this.treeView.instance;
        // treeView.unselectAll();
        // console.log(treeView)
        this.sortDataList();
    }

    async sortDataList() {
        this.setState({
            treeBoxValue: this.selectedRelations
        });

        await this.treeDataSource.forEach(el => {
            el["selected"] = false
            if (this.selectedRelations) {
                this.selectedRelations.forEach(data => {
                    //console.log('el.i', el);
                    //console.log('data.i', data);
                    if (el.id === data) {
                   /*     console.log('el.i', el.i);
                        console.log('data.i', data.i)*/
                        el["selected"] = true
                    }
                })
            }

        })
    }

    render() {
        return (
            <DropDownBox
                value={this.state.treeBoxValue}
                valueExpr="id"
                displayExpr={(item) => {
                    return item && `${item.code} - ${item.name}`
                }}
                placeholder="Сонголт хийх..."
                showClearButton={true}
                dataSource={this.treeDataSource}
                onValueChanged={this.syncTreeViewSelection}
                contentRender={this.treeViewRender}

            >
                <DropDownOptions
                    width={1000}
                    height={1000}>
                   {/* <Position
                        my="left"
                        at="left"
                        of="#targetElement"
                    />*/}
                </DropDownOptions>
                </DropDownBox>

        );
    }

    treeViewRender() {
        return (
            <React.Fragment>
            <TreeView dataSource={this.treeDataSource}
                      ref={(ref) => { this.treeView = ref; }}
                      dataStructure="plain"
                      keyExpr="id"
                      parentIdExpr="parent_id"
                      selectionMode="multiple"
                      showCheckBoxesMode="normal"
                      selectNodesRecursive={true}
                      displayExpr={(item) => {
                          return item && `${item.code.substring(0, 6)} - ${item.name}`
                      }}
                      height={"100%"}
                      searchMode={this.state.value}
                      searchEnabled={true}
                      selectByClick={true}
                      onContentReady={this.syncTreeViewSelection}
                      onItemSelectionChanged={this.treeViewItemSelectionChanged}
                     // defaultFocusedRowKey={this.props.data.value}

            />

            </React.Fragment>

        );
    }


    syncTreeViewSelection(e) {
        const treeView = (e.component.selectItem && e.component)
            || (this.treeView && this.treeView.instance);

        if (treeView) {
            if (e.value === null) {
                treeView.unselectAll();
            } else {
                const values = e.value || this.state.treeBoxValue;
                values && values.forEach((value) => {
                    treeView.selectItem(value);
                });
            }
        }


        if (e.value !== undefined) {
            this.setState({
                treeBoxValue: e.value,
            });
        }

        this.props.data.setValue(this.state.treeBoxValue);
      //  console.log('treeBoxValue sss ',this.state.treeBoxValue);
    }

    treeViewItemSelectionChanged(e) {
        this.setState({
            treeBoxValue: e.component.getSelectedNodeKeys(),
        });
    //    console.log('treeViewItemSelectionChanged ddd ',e);
    }
}

import React, {useEffect, useState} from 'react';
import achievment from '../../assets/imgs/icon/achievment.png';
import contact from '../../assets/imgs/icon/contact.png';
import email from '../../assets/imgs/icon/email.png';
import fb from '../../assets/imgs/icon/fb.png';
import phone from '../../assets/imgs/icon/phone.png';
import schools from '../../assets/imgs/icon/schools.png';
import Solongoo from '../../assets/imgs/hzyam/headImage/Solongoo.png';
import '../../assets/imgs/hzyam/hzyam.css';

export default function DeputyMinister() {

    useEffect(async () => {
    }, []);

    const style = {
        textJustify: {
            textAlign: "justify",
            textJustify: "inter-word",
            fontSize: 9
        },
        iconStyle: {
            width: 'auto',
            height: '25px',
            position: 'absolute',
            paddingLeft: '1.5%',
            marginLeft: 'auto',
            marginRight: 'auto',
        },
        iconLink: {
            width: 'auto',
            height: '12px',
            marginLeft: 'auto',
            marginRight: 'auto',
            paddingRight: '5px'

        },
        cardStyle: {
            boxShadow: '2px 2px 7px -1px rgba(0,0,0,0.25)',
            padding: '5% 10px 10px',
            height: '100%'
        },
        minister: {
            width: 'auto',
            height: 'auto',
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
            verticalAlign: 'middle',
            maxHeight: '100%'
        },
        frame :{
            height: '95%',
        },
        infoSpace: {
            paddingLeft: 5
        },
        rowSpace: {
            paddingTop: 10
        }
    }
    return (
        <div style={{color: '#140b3b'}}>

            <div calass="card" style={{height: '640px'}}>


                <div class="row card hzText" style={style.cardStyle}>
                    <div class="col-4" style={style.frame}>
                        <img loading="lazy" src={Solongoo} style={style.minister} alt={"text"}/>
                    </div>
                    <div class="col-8">
                        <div class="row">
                            <div class="col-1"/>
                            <div class="col-11 ">
                                <p>Хууль, зүй дотоод хэргийн дэд сайд</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-1"/>
                            <div class="col-11 hzText-bold">
                                <p style={{fontSize: 17, textTransform: 'uppercase'}}>Баярсайханы СОЛОНГОО</p>
                            </div>
                        </div>

                        <div class="row" style={style.rowSpace}>
                            <div class="col-1">
                                <img loading="lazy" src={schools} style={style.iconStyle} alt={"text"}/>
                            </div>
                            <div class="col-11 hzText-bold"><p>Төгссөн сургууль:</p></div>
                        </div>
                        <div class="row">
                            <div class="col-1"/>
                            <div class="col-11 hz-italic" style={style.textJustify}>
                                <table>
                                    <tr>
                                        <td width={60}>2000 онд</td>
                                        <td>Нийслэлийн 11 дүгээр сургууль</td>
                                    </tr>
                                    <tr>
                                        <td width={60}>2007 онд</td>
                                        <td>Унгар улсын Өөтвөш Лорандын шинжлэх ухааны их сургуулийн хууль зүй, улс
                                            төрийн шинжлэх ухааны сургууль,
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width={60}>2010 онд</td>
                                        <td>Их Британийн Дандийгийн их сургууль</td>
                                    </tr>
                                    <tr>
                                        <td width={60}>2015 онд</td>
                                        <td>АНУ-ын Харвардын хуулийн сургуулийг тус тус төгссөн</td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                        <div class="row" style={style.rowSpace}>
                            <div class="col-1">
                                <img loading="lazy" src={achievment} style={style.iconStyle} alt={"text"}/>
                            </div>
                            <div class="col-11 hzText-bold"><p>Ажлын туршлага:</p></div>
                        </div>
                        <div class="row">
                            <div class="col-1"/>
                            <div class="col-11 hz-italic" style={style.textJustify}>
                                <table>
                                    <tr>
                                        <td width={70}> 2011 2017 онд</td>
                                        <td>Хоган Ловеллс олон улсын хуулийн фирмд хуульч, олон улсын ахлах хуульч</td>
                                    </tr>
                                    <tr>
                                        <td width={70}> 2017-2020 онд</td>
                                        <td>Авинекс партнерс өмгөөллийн компанид гүйцэтгэх захирал</td>
                                    </tr>
                                    <tr>
                                        <td width={70}> 2020-2021 онд</td>
                                        <td>Засгийн газрын хэрэг эрхлэх газрын дэд дарга</td>
                                    </tr>
                                    <tr>
                                        <td width={70}> 2021 оноос</td>
                                        <td>Хууль зүй, дотоод хэргийн дэд сайд</td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                        <div class="row" style={style.rowSpace}>
                            <div class="col-1">
                                <img loading="lazy" src={contact} style={style.iconStyle} alt={"text"}/>
                            </div>
                            <div class="col-11 hzText-bold"><p>Холбоо барих:</p></div>
                        </div>
                        <div class="row">
                            <div class="col-1"/>
                            <div class="col-11" style={style.textJustify}>
                                <p>
                                    <a href={`tel:+260049`} id="number"
                                    >
                                        <img loading="lazy" src={phone} style={style.iconLink} alt={"text"}/>260049
                                    </a>
                                </p>
                                <a href={`mailto:solongoo@mojha.gov.mn`}>
                                    <p><img loading="lazy" src={email} style={style.iconLink} alt={"text"}/>solongoo@mojha.gov.mn
                                    </p>
                                </a>
                                <a href={'https://www.facebook.com/profile.php?id=100063629363008'} target={'_blank'}>
                                    <p><img loading="lazy" src={fb} style={style.iconLink} alt={"text"}/>facebook
                                    </p>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

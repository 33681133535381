import React, { useState, useEffect } from 'react'
import feedbackServices from '../../../services/api/feedback';
import PieChart from "devextreme-react/pie-chart";
import { Label, Legend, Series, Tooltip, Connector, Export } from "devextreme-react/chart";
import LoadPanelComponent from '../../../components/LoadPanelComponent';

export default function FeedbackType({ feedbacks, customizeTooltip, splitter }) {

  const [data, setData] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const getStatusCount = async () => {
    try {
      setIsLoading(true)
      const res = await feedbackServices.getStatusCount();
      if (res && res.code === 200) {
        setData(res.data);
      }
      setIsLoading(false)
    } catch (e) {
      console.log(e);
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getStatusCount()
  }, [feedbacks])
  const centerRender = () => {
    return (
      <text textAnchor="middle" style={{ display: 'flex', overflow: 'hidden', whiteSpace: 'nowrap', margin: 'auto', wordWrap: 'wrapped' }}>
        <tspan style={{ fontWeight: "bold" }}>Нийт: {feedbacks?.length}</tspan>
      </text>
    );
  }

  const cstmText = (e) =>{
    return `<span style="color: #FFF; font-weight: bold">${e.value} - ${(e.percent * 100).toFixed(2)}%</span>`
  }
  const palettes = ['#D2E2FF', '#AAC8FF','#5D96FF', '#2C77FF', '#003696']


  return (
    <div className='card'>
    <LoadPanelComponent visible={isLoading} position={{of: '#pie'}}/>
    <PieChart
      type='donut'
      id='pie'
      dataSource={data}
      palette={palettes}
      title='Төлвийн үзүүлэлтүүд'
      resolveLabelOverlapping={"hide"}
      centerRender={centerRender}
    >
      <Series argumentField="name" valueField="count">
        <Label
          font={{ family: "'Montserrat', sans-serif" }}
          position="inside"
          visible={true}
          customizeText={cstmText}
          backgroundColor="none"
        >
        </Label>

      </Series>
      <Export enabled={true} />
      <Legend
        visible={true}
        orientation="horizontal"
        itemTextPosition="right"
        horizontalAlignment="center"
        verticalAlignment="bottom"
        font={{
          family: "'Montserrat', sans-serif",
          color: "#2E4765",
          weight: "500",
          size: "12px",
        }}
        customizeText={(e) => {
          if (e.pointName.length > 60) {
            let textData = splitter(e.pointName, 28)
            return textData.join("<br>")
          }
          return e.pointName
        }}
      />
      <Tooltip
        zIndex={10000}
        enabled={true}
        customizeTooltip={customizeTooltip}
        font={{ family: "'Montserrat', sans-serif" }}
      ></Tooltip>
    </PieChart>
    </div>
  )
}

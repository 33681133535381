import React, {useState} from "react";
import FaType from "./FaType";
import List from "devextreme-react/list";
import ArrayStore from "devextreme/data/array_store";
import styles from "../../myInfo/myInfo.module.css";
import FaOr from "./FaOr";
import FaReg from "./FaReg";
import FaZar from "./FaZar";
import CurrentMonthByAcctReport from "./CurrentMonthByAcctReport";
import CurrentMonthDetial from "./CurrentMonthDetial";
import FaGLReport from "./FaGLReport";
import FaReportClose from "./FaReportClose";
import sideIcon from "../../../assets/imgs/sideIcon.svg";
import Fa03 from "./report/fa03";
function ItemTemplate(data) {
    return (
        <div>
            <div className="dx-list-item-icon-container">
                <i className={"dx-icon dx-list-item-icon dx-icon-" + data.icon}/>
            </div>
            <span>{data.text}</span>
        </div>
    );
}

export const txnTabs = [
    {
        id: 1,
        text: 'Бүртгэл',
        icon: "box",
        items: [{id: 11, text: 'Үндсэн хөрөнгийн бүртгэл', icon: "cart"}, {
            id: 12,
            text: 'Ангиллын бүртгэл',
            icon: "product"
        }]
    },
    {
        id: 2,
        text: 'Гүйлгээ',
        icon: "money",
        items: [{id: 21, text: 'Орлогын гүйлгээ', icon: "plus"}, {id: 22, text: 'Зарлагын гүйлгээ', icon: "minus"}]
    },
    {
        id: 3, text: 'Тайлант сар', icon: "textdocument", items:
            [{id: 31, text: 'Нэгдсэн тайлан / тайлант сар/', icon: "textdocument"},
                {id: 32, text: 'Дэлгэрэнгүй тайлан/ тайлант сар/', icon: "textdocument"},
                // {id: 35, text: 'Нэмэгдсэн / тайлант сар/', icon: "textdocument"},
                // {id: 36, text: 'Хасагдсан / тайлант сар/', icon: "textdocument"},

                {id: 33, text: 'Ерөнхий дэвтэрлүү шилжүүлэх тайлан', icon: "textdocument"},
                {id: 34, text: 'Өмнөх сарууд', icon: "textdocument"},

            ]
    },
]
const dataSourceTxn = new ArrayStore({
    key: 'id',
    data: txnTabs,
});
export default function Fa() {
    const [selectedId, setSelectedId] = useState(11);
    const [collapseMenu, setCollapseMenu] = useState(true);
    const handleListSelectionChange = async (e) => {
        setSelectedId(e.itemData.id);
    }
    const choosePanel = () => {
        switch (selectedId) {
            case 11:
                return FaReg;
            case 12:
                return FaType;
            case 21:
                return FaOr;
            case 22:
                return FaZar;
            case 31:
                return CurrentMonthByAcctReport;
            case 32:
                return CurrentMonthDetial;
            case 33:
                return FaGLReport;
            case 34:
                return FaReportClose;

        }

        return FaType;
    }
    const getCollapseMenu = async () => {
        setCollapseMenu(!collapseMenu);
    }

    let RightPanelHTML = choosePanel()

    return (
        <div className="undsen_hurungu">
            <div className="card-header" style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
            }}>

                <div className={"sideIcon-bnt-style"} onClick={getCollapseMenu}>
                    <span>Дэд цэс</span>
                    <img loading="lazy" src={sideIcon} style={{ width: 'auto', height: '15px',paddingLeft:20 }}/>
                </div>
            </div>

            <div style={{display: "grid", "grid-template-columns": "repeat(auto-fit, minmax(245px, 1fr))"}}>
                { collapseMenu && <div className={styles.leftContainer}>
                    <div className="card">
                        <div style={{textAlign: "left", fontSize: 'inherit'}}>
                            <List
                                className={styles.text}
                                dataSource={dataSourceTxn}
                                keyExpr="id"
                                selectionMode="single"
                                collapsibleGroups={true}
                                grouped={true}
                                groupRender={ItemTemplate}
                                onItemClick={handleListSelectionChange}
                            />
                        </div>

                    </div>
                </div> }

                <div className={styles.rightContainer}>
                    <div className="card" style={{height: "100%"}}>
                        <RightPanelHTML/>
                    </div>
                </div>

            </div>

        </div>
    );

}

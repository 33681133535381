import {
  DataGrid,
  FileUploader,
  List,
  LoadIndicator,
  Popup,
  ScrollView,
  SelectBox,
  TextBox,
  Validator,
} from "devextreme-react"
import {
  Column,
  Editing,
  FilterRow,
  Pager,
  Lookup,
  Paging,
  Button,
  SearchPanel,
  HeaderFilter,
  GroupPanel,
  Grouping,
} from "devextreme-react/data-grid"
import Form, {
  ButtonItem,
  Item as FormItem1,
  Label,
  RequiredRule,
} from "devextreme-react/form"
import React from "react"
import {useEffect} from "react"
import {useState} from "react"
import portalServices from "../../../services/api/portal"
import {Button as DevExtremeButton} from "devextreme-react/button"
import {BsFileEarmarkText, BsFillTrashFill} from "react-icons/bs"
import {BiDownload, BiLinkAlt} from "react-icons/bi"
import {AiFillEye, AiOutlinePaperClip} from "react-icons/ai"
import HtmlEditor, {
  Item,
  MediaResizing,
  Toolbar,
  ImageUpload,
} from "devextreme-react/html-editor"
import {useRef} from "react"
import notify from "devextreme/ui/notify"
import {useContext} from "react"
import {UserContext} from "../../../hooks/UserContext"
import FileServices from "../../../services/api/file"
import {urlServer} from "../../../shared/lib/request"
import styled from "styled-components"
import {BiSolidEditAlt} from "react-icons/bi"
import {custom} from "devextreme/ui/dialog"
const allowedFileExtensions = [
  ".jpg",
  ".jpeg",
  ".png",
  ".webp",
  ".docx",
  ".pdf",
  ".xlsx",
]
const allowedPictureExtension = [".jpg", ".jpeg", ".png", ".webp"]
const ProjectButton = styled(DevExtremeButton)`
  padding: 0.5rem !important;
  background-color: white !important;
  outline: none !important;
  cursor: pointer;
  border: 1px solid #e1e2e3 !important;
  border-radius: 6px !important;
  color: black !important;
  font-weight: 600;
  &.long {
    padding: 0.125rem 1rem !important;
  }
  &.delete {
    background-color: #f66160 !important;
    color: white !important;
    &:hover {
      background-color: #ff6564 !important;
    }
  }
  .dx-button-content {
    padding: 0 !important;
  }
  transition: 0.3s ease;

  &:hover {
    background-color: #cbcfd6 !important;
  }

  &:active {
    background-color: #cfd3db !important;
  }
  svg {
    font-size: 1.2rem;
  }
`
function PagesManager({orgId}) {
  const [pages, setPages] = useState([])
  const [categories, setCategories] = useState([])
  const [data, setData] = useState({})
  const [editToggle, setEditToggle] = useState(false)
  const [fileToggle, setFileToggle] = useState(false)
  const [addToggle, setAddToggle] = useState(false)
  const [content, setContent] = useState(null)
  const [title, setTitle] = useState("")
  const [categoryTitle, setCategoryTitle] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [selectedCategory, setSelectedCategory] = useState(null)
  const [addCategoryPopup, setAddCategoryPopup] = useState(false)
  const [showPage, setShowPage] = useState(false)
  const [menu, setMenu] = useState([])
  const {user} = useContext(UserContext)
  const [type, setType] = useState(null)
  const [addMenuToggle, setAddMenuToggle] = useState(false)
  const [menuTitle, setMenuTitle] = useState("")
  const [description, setDescription] = useState("")
  const [icon, setIcon] = useState(null)
  const [selectedMenuType, setSelectedMenuType] = useState(null)
  const [urlPath, setUrlPath] = useState("")
  const [manageMenuToggle, setManageMenuToggle] = useState(false)
  const [activeMenu, setActiveMenu] = useState([])
  const [chooseActiveMenuToggle, setChooseActiveMenuToggle] = useState(false)
  const [selectedActiveMenu, setSelectedActiveMenu] = useState(null)
  const [inactiveMenu, setInactiveMenu] = useState([])
  const [multiple, setMultiple] = useState([])
  const [fileNames, setFileNames] = useState([])
  const [warningToggle, setWarningToggle] = useState(false)
  const [selectedId, setSelectedId] = useState(null)
  const [setExpand, setSetExpand] = useState(false)
  const HtmlRef = useRef(null)
  const menuIcon = useRef(null)
  const photoRef = useRef(null)
  const urlRef = useRef(null)

  const typeHandler = (e) => {
    setType(e.value)
  }
  const onContentChange = (e) => {
    HtmlRef.current = e.value
  }
  useEffect(() => {
    if (orgId) {
      fetchData()
    }
  }, [type, orgId])

  const fetchData = async () => {
    try {
      fetchMenu()
      fetchCategories()
      fetchPages()
      fetchActiveMenu()
      fetchInactiveMenu()
    } catch (error) {
      console.log("error is", error)
    }
  }
  const menuType = (i) => {
    switch (i) {
      case 1:
        return "Үндсэн цэс"
      case 2:
        return "Салбар цэс"
      case 3:
        return "Статистик"
      case 4:
        return "Ерөнхий цэс"
    }
  }
  const fetchMenu = async () => {
    try {
      const result = await portalServices.getPortalMenu(orgId)
      if (type == null) {
        setType(result?.data?.[0]?.id)
      }
      setMenu(result?.data)
    } catch (error) {
      console.log("error is", error)
    }
  }
  const fetchPages = async () => {
    if (type) {
      try {
        const pages = await portalServices.getPortalPages(type, orgId)
        setPages(pages?.data)
      } catch (error) {
        console.log("error is", error)
      }
    }
  }
  const fetchActiveMenu = async () => {
    try {
      const activeMenu = await portalServices.getActivePortalMenu(orgId)
      setActiveMenu(activeMenu?.data)
    } catch (error) {
      console.log("error is", error)
    }
  }
  const fetchInactiveMenu = async () => {
    try {
      const getInactiveMenu = await portalServices.getInactivePortalMenu(orgId)
      setInactiveMenu(getInactiveMenu?.data)
    } catch (error) {
      console.log("error is", error)
    }
  }
  const fetchCategories = async () => {
    if (type) {
      try {
        const categories = await portalServices.getPortalCategories(type)
        setCategories(categories?.data)
      } catch (error) {
        console.log("error is", error)
      }
    }
  }

  const onRemovingEvent = async (id) => {
    try {
      let result = await portalServices.deletePortalPage(id)
      if (result?.code === 200) {
        fetchPages()
        return notify("Амжилттай устгалаа", "success", 4000)
      }
    } catch (error) {
      console.log("the error is", error)
      return notify("Устгах үйлдэл амжилтгүй боллоо", "warning", 3000)
    }
  }

  const handleOnCellPrepared = (e) => {
    let cell = e.cellElement
    let style = cell.style
    style.fontSize = "13px"
    if (e.rowType === "header") {
      style.color = "#15186A"
      style.backgroundColor = "#EEF0F4"
      style.fontWeight = "700"
    }
    if (e.rowType === "data") {
      style.color = "black"
    }
  }
  const headerValues = [false, 1, 2, 3, 4, 5]
  const sizeValues = ["8pt", "10pt", "12pt", "14pt", "18pt", "24pt", "36pt"]
  const fontValues = [
    "Arial",
    "Courier New",
    "Georgia",
    "Impact",
    "Lucida Console",
    "Tahoma",
    "Times New Roman",
    "Verdana",
    "Montserrat",
  ]
  const ImageUploaderTabs = [{name: "From This Device", value: ["file"]}]

  const fontOptions = {placeholder: "Фонт"}

  const onEditHandle = async (e) => {
    setData(e.row.data)
    await getFileData(e.row.key)
    setEditToggle(true)
  }

  const onCategoryTitleChange = (e) => {
    setCategoryTitle(e.value)
  }
  const onMenuTitleChange = (e) => {
    setMenuTitle(e.value)
  }
  const onUrlPathChange = (e) => {
    setUrlPath(e.value)
  }
  const onTitleChange = (e) => {
    setTitle(e.value)
  }
  const getFileData = async (id) => {
    try {
      const res = await portalServices.getPortalFileNames(id)
      setFileNames(await res.data)
    } catch (error) {
      console.log(error)
    }
  }
  const renderPage = (e) => {
    return (
      <div style={{display: "flex", justifyContent: "center"}}>
        <button
          onClick={() => {
            setContent(e?.data?.value)
            setShowPage(true)
          }}
          className="showButton"
        >
          <AiFillEye style={{marginRight: ".25rem"}} />{" "}
          <span>Хуудас харах</span>
        </button>
      </div>
    )
  }
  const renderIcon = (e) => {
    if (e.row.data?.file_name) {
      return (
        <img
          style={{
            width: "3rem",
            height: "3rem",
            objectFit: "cover",
            borderRadius: "50%",
          }}
          src={`${urlServer}/api/file/${e.row.data.file_name}`}
          alt=""
        />
      )
    } else {
      return null
    }
  }
  const handleAddPage = async () => {
    setIsLoading(true)
    try {
      let markup = HtmlRef.current

      if (selectedCategory == null) {
        notify("Дэд цэс сонгоно уу", "warning", 3000)
        return 0
      }
      if (markup == null) {
        notify("Хуудасны контент оруулна уу", "warning", 3000)
        return 0
      }

      if (title === "") {
        // console.log(title, "is null")
        notify("Гарчиг оруулна уу", "warning", 3000)
        return 0
      }

      let data = {
        portal_menu_category_id: selectedCategory,
        org_id: orgId,
        value: markup,
        status: 1,
        youtube_url: urlRef.current,
        created_user_id: user.id,
        title: title,
      }

      const res = await portalServices.addPortalPage(data)
      if (res?.code === 200) {
        if (res.data?.id && multiple.length > 0) {
          await multipleFileSubmit(multiple, res.data?.id, 1)
        }
        setAddToggle(false)
        HtmlRef.current = null
        setTitle("")
        fetchPages()
        return notify(res.message, "success", 4000)
      }
    } catch (error) {
      console.log("the error is", error)
      return notify("Хадгалах үйлдэл амжилтгүй боллоо", "warning", 3000)
    } finally {
      setIsLoading(false)
    }
  }
  const handleDeleteFile = async (file_id) => {
    try {
      let result = await FileServices.deletePortalStructureFile(file_id)
      if (result.code === 200) {
        setWarningToggle(false)
        await getFileData(data.id)
        return notify(result.message, "success", 4000)
      }
    } catch (error) {
      console.log("error", error)
      return notify("Устгах үйлдэл амжилтгүй боллоо", "warning", 3000)
    }
  }
  function FileUploaderCard(props) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: "1rem",
        }}
      >
        <div>{props.index + 1}.</div>
        <div className="portalImageCard">
          <div className="meta_data">
            <span className="file_type">
              {props.file_name
                .split(".")
                [props.file_name.split(".").length - 1].toUpperCase()}
            </span>
            <span className="file_size">
              {(props.file_size / 1024).toFixed(2)} kb
            </span>
          </div>
          {/* <img className="thumbnail" src={`${urlServer}/api/file/${props.file_name}`} alt="thumbnail"/> */}
          <div className="text">{props.file_name.slice(14)}</div>

          <div className="controllers">
            <a href={`${urlServer}/api/file/${props.file_name}`}>
              <BiDownload className="look" style={{fontSize: "1.4rem"}} />
            </a>
            <BsFillTrashFill
              className="delete"
              onClick={() => {
                setSelectedId(props.id)
                setWarningToggle(true)
              }}
              style={{fontSize: "1.2rem"}}
            />
          </div>
        </div>
      </div>
    )
  }

  function containsValidChars(str) {
    const latinChars = /^[A-Za-z0-9_.-]*$/
    return !latinChars.test(str)
  }

  const handleAddMenu = async () => {
    setIsLoading(true)
    try {
      if (menuTitle === "") {
        notify("Үндсэн цэсийн нэрийг оруулна уу", "warning", 3000)
        return 0
      }

      if (urlPath === "") {
        notify("Цэсийн зам оруулна уу", "warning", 3000)
        return 0
      }
      // if (description === "" && selectedMenuType === 2) {
      //   notify("Тайлбар оруулна уу", "warning", 3000)
      //   return 0
      // }
      // if (urlPath === "") {
      //   notify("Цэсний зам оруулна уу", "warning", 3000)
      //   return 0
      // }
      // if (selectedMenuType == null) {
      //   notify("Цэсний төрөл оруулна уу", "warning", 3000)
      //   return 0
      // }
      // if (photoRef.current == null && selectedMenuType === 2) {
      //   notify("Icon зураг оруулна уу", "warning", 3000)
      //   return 0
      // }
      if (containsValidChars(urlPath)) {
        notify(
          "Цэсийн замд зөвхөн англи үсэг, тоо оруулна уу! Мөн дундаа зай авахгүй байхыг анхаарна уу!",
          "warning",
          3000
        )
        return 0
      }

      const ifMenuExists = await portalServices.getPortalMenuByPath(urlPath)

      if (ifMenuExists?.code === 200) {
        notify("Цэсийн зам давхардаж байна", "warning", 3000)
        return 0
      }

      let data = {
        name: menuTitle,
        menu_path: urlPath,
        menu_type: [1],
        description: description,
        status: 1,
        created_user_id: user.id,
      }
      if (photoRef.current !== null && selectedMenuType === 2) {
        if (
          photoRef?.current[0].type === "image/png" ||
          photoRef?.current[0].type === "image/jpg" ||
          photoRef?.current[0].type === "image/jpeg" ||
          photoRef?.current[0].type === "image/webp"
        ) {
          data.icon_file_id = await fileSubmit(
            photoRef.current[0],
            32,
            30000000
          )
        } else {
          notify("PNG/JPG/JPEG өргөтгөлтэй зураг оруулна уу!", "warning", 3000)
          return 0
        }
      }
      const res = await portalServices.addPortalMenu(data)

      if (res?.code === 200) {
        setAddMenuToggle(false)
        setMenuTitle("")
        setUrlPath("")
        photoRef.current = null
        setDescription("")
        setSelectedMenuType(null)
        fetchData()
        return notify(res.message, "success", 4000)
      }
    } catch (error) {
      console.log("the error is", error)
      setIsLoading(false)
      return notify("Хадгалах үйлдэл амжилтгүй боллоо", "warning", 3000)
    } finally {
      setIsLoading(false)
    }
  }
  const handleEditPage = async () => {
    setIsLoading(true)
    let pageData = {}
    try {
      if (selectedCategory !== null) {
        pageData.portal_menu_category_id = selectedCategory
      }
      if (title !== "") {
        pageData.title = title
      }
      if (HtmlRef.current !== null) {
        pageData.value = HtmlRef.current
      }
      if(urlRef.current !== null) {
        pageData.youtube_url = urlRef.current
      }
      if (Object.keys(pageData).length === 0) {
        console.log("nothing has been edited")
        setEditToggle(false)
        return 0
      }
      let res = await portalServices.editPortalPage(pageData, data.id)

      if (res?.code === 200) {
        if (multiple.length > 0) {
          await multipleFileSubmit(multiple, data.id, 2)
        }
        setEditToggle(false)
        HtmlRef.current = null
        setTitle("")
        fetchData()

        return notify(res.message, "success", 4000)
      }
    } catch (error) {
      console.log("the error is", error)
      return notify("Хадгалах үйлдэл амжилтгүй боллоо", "warning", 3000)
    } finally {
      setIsLoading(false)
    }
  }
  const handleAddCategory = async () => {
    setIsLoading(true)
    try {
      let categoryTitl = categoryTitle
      if (categoryTitl === "")
        return notify("Гарчиг оруулна уу", "warning", 3000)
      let result = await portalServices.addPortalCategory(type, {
        text: categoryTitl,
      })
      if (result?.code === 200) {
        setAddCategoryPopup(false)
        setCategoryTitle("")
        fetchData()
        return notify(result.message, "success", 4000)
      }
    } catch (error) {
      console.log("the error is", error)
      return notify("Хадгалах үйлдэл амжилтгүй боллоо", "warning", 3000)
    } finally {
      setIsLoading(false)
    }
  }
  const onPhotoChange = (e) => {
    photoRef.current = e.value
  }

  const renderImageUploader = () => {
    return (
      <div style={{}}>
        <FileUploader
          allowedFileExtensions={allowedPictureExtension}
          onValueChanged={onPhotoChange}
          selectButtonText="Зураг сонгох"
          labelText=""
          accept="image/*"
          uploadMode="useForm"
        />
        <span className="note">
          {"Зургийн өргөтгөл: "}
          <span>.jpg, .jpeg, .png .webp</span>
        </span>
      </div>
    )
  }
  const MarkupRender = () => {
    return (
      <HtmlEditor
        height="100%"
        style={{minHeight: "35rem"}}
        //   width={}
        defaultValue={editToggle ? data.value : null}
        onValueChanged={onContentChange}
        valueType="html"
        id="htmlEditorEdit"
      >
        <MediaResizing enabled={true} />
        <ImageUpload fileUploadMode="base64" tabs={ImageUploaderTabs} />
        <Toolbar multiline={true}>
          <Item name="size" acceptedValues={sizeValues} />
          <Item name="separator" />
          <Item name="font" acceptedValues={fontValues} options={fontOptions} />
          <Item name="separator" />
          <Item name="bold" />
          <Item name="italic" />
          <Item name="strike" />
          <Item name="underline" />
          <Item name="separator" />
          <Item name="alignLeft" />
          <Item name="alignCenter" />
          <Item name="alignRight" />
          <Item name="alignJustify" />
          <Item name="separator" />
          <Item name="orderedList" />
          <Item name="bulletList" />
          <Item name="separator" />
          <Item name="header" acceptedValues={headerValues} />
          <Item name="separator" />
          <Item name="color" />
          <Item name="background" />
          <Item name="separator" />
          <Item name="link" />
          <Item name="image" />
          <Item name="separator" />
          <Item name="clear" />
          <Item name="codeBlock" />
          <Item name="blockquote" />
          <Item name="separator" />
          <Item name="insertTable" />
          <Item name="deleteTable" />
          <Item name="insertRowAbove" />
          <Item name="insertRowBelow" />
          <Item name="deleteRow" />
          <Item name="insertColumnLeft" />
          <Item name="insertColumnRight" />
          <Item name="deleteColumn" />
          <Item name="separator" />
          <Item widget={"dxButton"} options={toolbarButtonOptions} />
        </Toolbar>
      </HtmlEditor>
    )
  }
  const requestFullscreen = () => {
    /* Get the element you want displayed in fullscreen */
    const element = document.getElementById("htmlEditorEdit")
    // Use a guard clause to exit out of the function immediately
    if (element.requestFullscreen) {
      element.requestFullscreen()
    } else if (element.webkitRequestFullscreen) {
      // Safari
      element.webkitRequestFullscreen()
    } else if (element.msRequestFullscreen) {
      // IE
      element.msRequestFullscreen()
    }
  }
  const toolbarButtonOptions = {
    icon: "fullscreen",
    stylingMode: "text",
    onClick: requestFullscreen,
  }
  const onValueSelectBox = (e) => {
    setSelectedCategory(e.value)
  }
  const SelectBoxRender = () => {
    return (
      <SelectBox
        dataSource={categories}
        displayExpr="name"
        valueExpr="id"
        width={"100%"}
        onInitialized={selectBoxInit}
        value={selectedCategory}
        onValueChanged={onValueSelectBox}
        placeholder="Сонгох"
      />
    )
  }
  const onMenuTypesChange = (e) => {
    setSelectedMenuType(e.value)
  }
  const MenuTypesRender = () => {
    return (
      <SelectBox
        dataSource={[
          {text: "Үндсэн цэс", value: 1},
          {text: "Салбар цэс", value: 2},
          {text: "Статистик", value: 3},
        ]}
        displayExpr="text"
        valueExpr="value"
        // onInitialized={selectBoxInit}
        value={selectedMenuType}
        onValueChanged={onMenuTypesChange}
        placeholder="Сонгох"
      />
    )
  }
  const selectBoxInit = () => {
    if (editToggle) {
      setSelectedCategory(data.portal_menu_category_id)
    } else {
      setSelectedCategory(null)
    }
  }
  const addCategoryButtonOptions = {
    text: "Дэд цэс нэмэх",
    type: "success",
    icon: "add",
    onClick: function () {
      setAddCategoryPopup(true)
    },
  }
  const CategoryTitleRender = () => {
    return (
      <TextBox onValueChanged={onCategoryTitleChange} value={categoryTitle} />
    )
  }

  const MenuTitleRender = () => {
    return <TextBox onValueChanged={onMenuTitleChange} value={menuTitle} />
  }
  const onDescriptionChange = (e) => {
    setDescription(e.value)
  }
  const UrlPathRender = () => {
    return (
      <TextBox
        // placeholder="Англи үсгээр бичнэ үү"
        onValueChanged={onUrlPathChange}
        value={urlPath}
      />
    )
  }
  const DescriptionRender = () => {
    return <TextBox onValueChanged={onDescriptionChange} value={description} />
  }
  const TitleRender = () => {
    return (
      <TextBox
        onValueChanged={onTitleChange}
        defaultValue={editToggle ? data.title : null}
      />
    )
  }
  const renderHandleFile = () => {
    return (
      <Popup
        visible={fileToggle}
        onHiding={() => setFileToggle(false)}
        hideOnOutsideClick={true}
        showCloseButton
        height="auto"
        showTitle
        title="Хавсаргасан файл удирдах"
        width="60%"
        id="renderaddCategoryMenu"
      >
        <ScrollView height="100%" width="100%">
          <div className="widget-container">
            {fileNames?.length > 0 ? (
              fileNames?.map((item, index) => (
                <FileUploaderCard key={item.id} {...item} index={index} />
              ))
            ) : (
              <div
                style={{
                  display: "flex",
                  height: "10rem",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <span>Файл хавсаргаагүй байна.</span>
              </div>
            )}
          </div>
        </ScrollView>
      </Popup>
    )
  }
  const renderWarning = () => {
    return (
      <Popup
        visible={warningToggle}
        onHiding={() => setWarningToggle(false)}
        hideOnOutsideClick={true}
        showCloseButton
        height="10rem"
        showTitle
        title={"Устгахдаа итгэлтэй байна уу?"}
        width="22rem"
        id="renderaddCategoryMenu"
      >
        <div className="widget-container">
          <DevExtremeButton
            width={120}
            text="Тийм"
            className="saveButton"
            type="default"
            onClick={() => {
              handleDeleteFile(selectedId)
            }}
          />
          <DevExtremeButton
            width={120}
            text="Болих"
            type="default"
            style={{borderRadius: "1rem"}}
            onClick={() => {
              setWarningToggle(false)
            }}
          />
        </div>
      </Popup>
    )
  }

  const renderAddCategory = () => {
    return (
      <Popup
        visible={addCategoryPopup}
        onHiding={() => setAddCategoryPopup(false)}
        hideOnOutsideClick={true}
        showCloseButton
        height="auto"
        showTitle
        title="Дэд цэс нэмэх"
        width="30rem"
        id="renderaddCategoryMenu"
      >
        <ScrollView height="100%" width="100%">
          <div
            className="widget-container"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              height: "100%",
            }}
          >
            <Form>
              <FormItem1 dataField="news_title" render={CategoryTitleRender}>
                <Label text="Дэд цэсний нэр" />
                <RequiredRule message="Гарчиг оруулна уу!" />
              </FormItem1>
            </Form>
            <div
              style={{
                marginTop: "2rem",
                display: "flex",
                justifyContent: "flex-end",
                gap: "1rem",
              }}
            >
              <ProjectButton
                className="long"
                width={120}
                onClick={() => setAddCategoryPopup(false)}
              >
                Болих
              </ProjectButton>
              <DevExtremeButton
                icon="plus"
                text={"Нийтлэх"}
                type="default"
                className="saveButton"
                onClick={() => handleAddCategory()}
              >
                <span className="dx-button-text">
                  <LoadIndicator
                    style={{height: 20, width: 20}}
                    visible={isLoading}
                  />
                  {!isLoading && <span>Хадгалах</span>}
                </span>
              </DevExtremeButton>
            </div>
          </div>
        </ScrollView>
      </Popup>
    )
  }
  const deleteHandler = async (id) => {
    const customConfirm = custom({
      title: "Баталгаажуулалт",
      messageHtml: "<i>Устгахдаа итгэлтэй байна үү?</i>",
      buttons: [
        {
          text: "Тийм",
          onClick: (e) => {
            return true
          },
        },
        {
          text: "Үгүй",
          onClick: (e) => {
            return false
          },
        },
      ],
    })

    customConfirm.show().then((dialogResult) => {
      setIsLoading(true)
      if (dialogResult) {
        onRemovingEvent(id)
      }
      setIsLoading(false)
    })
  }

  const renderButton = (e, type) => {
    if (type === 1) {
      return (
        <ProjectButton
          hint="Хуудас харах"
          onClick={() => {
            setContent(e?.data?.value)
            setShowPage(true)
          }}
        >
          <AiFillEye />
        </ProjectButton>
      )
    } else if (type === 2) {
      return (
        <ProjectButton
          hint="Файл харах"
          onClick={async () => {
            setData(e.row.data)
            await getFileData(e.row.key)
            setFileToggle(true)
          }}
        >
          <BsFileEarmarkText />
        </ProjectButton>
      )
    } else if (type === 3) {
      return (
        <ProjectButton
          hint="Хуудас өөрчлөх"
          onClick={async () => {
            setData(e.row.data)
            await getFileData(e.row.key)
            setEditToggle(true)
          }}
        >
          <BiSolidEditAlt />
        </ProjectButton>
      )
    } else if (type === 4) {
      return (
        <ProjectButton
          hint="Хуудас устгах"
          className="delete"
          onClick={() => deleteHandler(e.data.id)}
        >
          <BsFillTrashFill />
        </ProjectButton>
      )
    }
  }
  const renderDeleteButton = (e) => {
    return (
      <ProjectButton
        hint="Хуудас устгах"
        className="delete"
        onClick={() => deleteHandler(e.data.id)}
      >
        <BsFillTrashFill />
      </ProjectButton>
    )
  }
  const onUrlChange = (e) => {
    urlRef.current = e.value
  }
  const UrlRender = () => {
    return (
      <TextBox
        label={"Хавсаргах бичлэг /Youtube link оруулна уу/"}
        onValueChanged={onUrlChange}
        defaultValue={editToggle ? data.youtube_url : ""}
      />
    )
  }

  const renderCreateMenu = () => {
    return (
      <Popup
        visible={addToggle}
        onHiding={() => setAddToggle(false)}
        hideOnOutsideClick
        showCloseButton
        height="90vh"
        showTitle
        title={"Хуудас нэмэх"}
        width="60%"
        id="renderCreateMenu"
      >
        <ScrollView height="100%" width="100%">
          <div className="widget-container">
            {addToggle && (
              <Form>
                <FormItem1 itemType={"group"} colCount={1} colSpan={2}>
                  <FormItem1 itemType={"group"} colCount={4}>
                    <FormItem1 render={SelectBoxRender} colSpan={3}>
                      <Label text="Дэд цэс сонгох" />
                      <RequiredRule message="Дэд цэс сонгоно уу!" />
                    </FormItem1>
                    <Item
                      itemType="button"
                      buttonOptions={addCategoryButtonOptions}
                    />
                  </FormItem1>
                  <FormItem1 dataField="title" render={TitleRender}>
                    <Label text="Хуудасны нэр" />
                    <RequiredRule message="Гарчиг оруулна уу!" />
                  </FormItem1>
                  <FormItem1 dataField="markup" render={MarkupRender}>
                    <Label text="Нийтлэл" />
                    <RequiredRule message="Нийтлэл оруулна уу!" />
                  </FormItem1>
                  <FormItem1 render={UrlRender}>
                    <Label text="Бичлэг хавсаргах"/>
                  </FormItem1>
                  <FormItem1 render={MultipleImageUploader}>
                    <Label text="Файл хавсаргах" />
                    <RequiredRule message="Зураг оруулна уу!" />
                  </FormItem1>
                </FormItem1>
              </Form>
            )}
            <DevExtremeButton
              icon="plus"
              text={"Нийтлэх"}
              type="default"
              style={{marginTop: "1rem"}}
              className="saveButton"
              onClick={() => handleAddPage()}
            >
              <span className="dx-button-text">
                <LoadIndicator
                  style={{height: 20, width: 20}}
                  visible={isLoading}
                />
                {!isLoading && <span>Хадгалах</span>}
              </span>
            </DevExtremeButton>
          </div>
        </ScrollView>
      </Popup>
    )
  }

  const renderAddMenu = () => {
    return (
      <Popup
        visible={addMenuToggle}
        onHiding={() => setAddMenuToggle(false)}
        hideOnOutsideClick
        showCloseButton
        height="auto"
        showTitle
        title={"Үндсэн цэс үүсгэх"}
        width="30rem"
        id="renderEditMenu"
      >
        <ScrollView height="100%" width="100%">
          <div className="widget-container">
            <Form>
              <FormItem1 dataField="name" render={MenuTitleRender}>
                <Label text="Үндсэн цэсний нэр" />
                <RequiredRule message="Гарчиг оруулна уу!" />
              </FormItem1>
              {/* <FormItem1 dataField="menu_type" render={MenuTypesRender}>
                  <Label text="Цэс төрөл" />
                  <RequiredRule message="Гарчиг оруулна уу!" />
                </FormItem1> */}
              <FormItem1 dataField="menu_path" render={UrlPathRender}>
                <Label text="Url path" />
                <RequiredRule message="Гарчиг оруулна уу!" />
              </FormItem1>

              {/* {selectedMenuType === 2 && (
                  <FormItem1 dataField="description" render={DescriptionRender}>
                    <Label text="Тайлбар" />
                    <RequiredRule message="Гарчиг оруулна уу!" />
                  </FormItem1>
                )}
                {selectedMenuType === 2 && (
                  <FormItem1
                    dataField="icon_value"
                    render={renderImageUploader}
                  >
                    <Label text="Icon сонгох" />
                    <RequiredRule message="Гарчиг оруулна уу!" />
                  </FormItem1>
                )} */}
            </Form>
            <DevExtremeButton
              icon="plus"
              text={"Нийтлэх"}
              type="default"
              style={{marginTop: "1rem"}}
              className="saveButton"
              onClick={() => handleAddMenu()}
            >
              <span className="dx-button-text">
                <LoadIndicator
                  style={{height: 20, width: 20}}
                  visible={isLoading}
                />
                {!isLoading && <span>Хадгалах</span>}
              </span>
            </DevExtremeButton>
          </div>
        </ScrollView>
      </Popup>
    )
  }
  const handleChooseActiveMenu = async () => {
    setIsLoading(true)
    try {
      if (selectedActiveMenu == null) {
        notify("Идэвхижүүлэх цэс сонгоно уу", "warning", 3000)
        return 0
      }
      let result = await portalServices.addActiveMenu(orgId, selectedActiveMenu)
      if (result?.code === 200) {
        setChooseActiveMenuToggle(false)
        setSelectedActiveMenu(null)
        fetchData()
        return notify("Амжилттай хадгаллаа", "success", 4000)
      }
    } catch (error) {
      console.log("error is", error)
      return notify("Хадгалах үйлдэл амжилтгүй боллоо", "warning", 3000)
    } finally {
      setIsLoading(false)
    }
  }
  const onListItemClick = (e) => {
    setSelectedActiveMenu(e?.itemData?.id)
  }
  const renderListItem = (e) => {
    return (
      <div className="renderListItem">
        <div>Нэр: {e.name}</div>
        <div>
          <span>Тайлбар:</span> <span>{e.description}</span>
        </div>
        <div>
          <span>Төрөл:</span> <span>{menuType(e.menu_type[0])}</span>
        </div>
        <div>
          <span>URL path:</span> <span>/{e.menu_path}</span>
        </div>
        <div className="icon">
          <span>Icon:</span>
          {e?.file_name ? (
            <img
              style={{
                width: "2rem",
                height: "2rem",
                objectFit: "cover",
                borderRadius: "50%",
              }}
              src={`${urlServer}/api/file/${e.file_name}`}
              alt=""
            />
          ) : (
            <div
              style={{
                width: "2rem",
                objectFit: "cover",
                borderRadius: "50%",
              }}
            />
          )}
        </div>
      </div>
    )
  }
  const renderChooseActiveMenu = () => {
    return (
      <Popup
        visible={chooseActiveMenuToggle}
        onHiding={() => setChooseActiveMenuToggle(false)}
        hideOnOutsideClick
        showCloseButton
        height="60vh"
        showTitle
        title={"Идэвхижүүлэх цэс сонгох"}
        width="80%"
        id="renderEditMenu"
      >
        <ScrollView height="100%" width="100%">
          <div className="widget-container">
            <div>
              <DevExtremeButton
                width={200}
                style={{marginLeft: "1rem", marginBottom: "1rem"}}
                text="Цэс үүсгэх"
                className="saveButton"
                type="default"
                onClick={() => {
                  setAddMenuToggle(true)
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                gap: "1rem",
              }}
            >
              <div className="inactiveMenu-container">
                <List
                  dataSource={inactiveMenu}
                  showSelectionControls={true}
                  selectionMode={"single"}
                  onItemClick={onListItemClick}
                  itemRender={renderListItem}
                />
              </div>
            </div>
            <DevExtremeButton
              icon="plus"
              text={"Нийтлэх"}
              type="default"
              style={{marginTop: "1rem"}}
              className="saveButton"
              onClick={() => handleChooseActiveMenu()}
            >
              <span className="dx-button-text">
                <LoadIndicator
                  style={{height: 20, width: 20}}
                  visible={isLoading}
                />
                {!isLoading && <span>Хадгалах</span>}
              </span>
            </DevExtremeButton>
          </div>
        </ScrollView>
      </Popup>
    )
  }

  const MultipleImageUploader = () => {
    return (
      <div style={{transform: "translateY(-.85rem)"}}>
        <div style={{display: "flex", position: "relative", fontWeight: 700}}>
          <AiOutlinePaperClip
            style={{
              zIndex: 10,
              position: "absolute",
              top: ".7rem",
              left: ".5rem",
              strokeWidth: 2
            }}
          />
          <FileUploader
            allowedFileExtensions={allowedFileExtensions}
            onValueChanged={onMultiplePhotoChange}
            selectButtonText="‎‎ Файл"
            multiple={true}
            labelText=""
            accept="*"
            uploadMode="useForm"
          />{" "}
        </div>
        <div className="note" style={{marginTop: ".25rem"}}>
          {"Файлын өргөтгөл: "}
          <span>.jpg, .jpeg, .png .pdf .docx .xlsx</span>
        </div>
      </div>
    )
  }
  const onMultiplePhotoChange = (e) => {
    setMultiple(e.value)
  }
  const multipleFileSubmit = async (fileData, id, type) => {
    try {
      let data = new FormData()
      for (let i = 0; i < fileData.length; i++) {
        data.append("file", fileData[i])
      }
      const res = await FileServices.uploadMultiplePortalFiles(data, id, type)
      if (res.code === 200) {
        setMultiple([])
        return res.data
      }
    } catch (error) {
      console.log("error", error)
      return notify("Хадгалах үйлдэл амжилтгүй боллоо", "warning", 3000)
    }
  }
  const fileSubmit = async (fileData, typeId, size) => {
    try {
      setIsLoading(true)

      if (fileData.size >= size) {
        notify(
          `Оруулсан файлын хэмжээ  ${size / 1000000}-MB-аас их байна.`,
          "warning",
          3000
        )
        return 0
      }
      let data = new FormData()
      data.append("file", fileData)
      const res = await FileServices.uploadSingleFile(data, typeId)
      if (res.code === 200) {
        photoRef.current = null
        return res.data
      }
    } catch (error) {
      console.log(error)
      return notify("Хадгалах үйлдэл амжилтгүй боллоо (Зураг)", "warning", 3000)
    }
  }
  const onRemovingActiveMenu = async (e) => {
    setIsLoading(true)
    try {
      let result = await portalServices.deleteActiveMenu(orgId, e.key)
      if (result?.code === 200) {
        fetchData()
        return notify("Амжилттай устгагдлаа", "success", 4000)
      }
    } catch (error) {
      console.log("error is", error)
      return notify("Устгах үйлдэл амжилтгүй боллоо", "warning", 3000)
    } finally {
      setIsLoading(false)
    }
  }
  const renderStatus = (e) => {
    let status = e?.data?.pmo_status
    let style = {
      padding: ".5rem 0",
      borderRadius: ".4rem",
      margin: "0 .5rem",
    }
    let activeStyle = {
      backgroundColor: "#50C687",
      color: "white",
    }
    let inactiveStyle = {
      backgroundColor: "#EEF0F4",
      color: "#CFCFCF",
    }
    if (status === 1) {
      return <div style={{...activeStyle, ...style}}>Идэвхитэй</div>
    }
    return <div style={{...inactiveStyle, ...style}}>Идэвхигүй</div>
  }

  const handleRowEdit = async (e) => {
    setIsLoading(true)
    try {
      let status = e.changes[0].data.pmo_status
      let id = e.changes[0].key
      let result = await portalServices.deleteActiveMenu(orgId, id, status)
      if (result?.code === 200) {
        fetchData()
        return notify("Амжилттай өөрчлөгдлөө", "success", 4000)
      }
    } catch (error) {
      console.log("error")
      return notify("Өөрчлөх хүсэлт амжилтгүй боллоо", "warning", 3000)
    } finally {
      setIsLoading(false)
    }
  }
  const updateHandler = async (data) => {
    let status = data.changes[0].data.pmo_status
    const customConfirm = custom({
      title: "Баталгаажуулалт",
      messageHtml: `<i> Цэс ${
        status === 1 ? "идэвхижүүлэхдээ" : "идэвхигүй болгохдоо"
      } итгэлтэй байна үү?</i>`,
      buttons: [
        {
          text: "Тийм",
          onClick: (e) => {
            return true
          },
        },
        {
          text: "Үгүй",
          onClick: (e) => {
            return false
          },
        },
      ],
    })

    customConfirm.show().then((dialogResult) => {
      setIsLoading(true)
      if (dialogResult) {
        handleRowEdit(data)
      }
      setIsLoading(false)
      fetchActiveMenu()
    })
  }
  const renderManageMenu = () => {
    return (
      <Popup
        visible={manageMenuToggle}
        onHiding={() => setManageMenuToggle(false)}
        hideOnOutsideClick
        showCloseButton
        height="auto"
        showTitle
        title={"Үндсэн цэс"}
        width="60rem"
        id="renderEditMenu"
      >
        <ScrollView height="100%" width="100%">
          <div className="widget-container">
            <div
              style={{
                marginBottom: "1rem",
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <ProjectButton
                hint="Хуудас харах"
                width={200}
                text="Үндсэн цэс үүсгэх"
                type="default"
                onClick={() => {
                  setAddMenuToggle(true)
                }}
              />
            </div>
            <DataGrid
              id="dataGrid"
              dataSource={activeMenu}
              keyExpr="id"
              wordWrapEnabled={true}
              grouping={true}
              noDataText="Дата байхгүй байна."
              onRowRemoved={onRemovingActiveMenu}
              showBorders={true}
              style={{flexWrap: "wrap"}}
              showRowLines={true}
              onSaved={updateHandler}
              onCellPrepared={handleOnCellPrepared}
              hoverStateEnabled={true}
              allowColumnResizing={true}
            >
              <FilterRow visible={true} />
              <HeaderFilter
                visible={true}
                texts={{
                  emptyValue: "Хоосон",
                  cancel: "Болих",
                }}
              />
              <Grouping />
              <Editing
                allowUpdating={true}
                allowDeleting={false}
                mode="cell"
                height="20rem"
                texts={{
                  cancelAllChanges: "Болих",
                  cancelRowChanges: "Болих",
                  confirmDeleteMessage: "Энэ хуудсыг устгах уу?",
                  confirmDeleteTitle: "Баталгаажуулах",
                  deleteRow: "Устгах",
                  saveAllChanges: "Хадгалах",
                  saveRowChanges: "Хадгалах",
                  undeleteRow: "Буцаах",
                  validationCancelChanges: "Баталгаажуулах болих",
                  trueText: "Тийм",
                  falseText: "Үгүй",
                }}
              >
                <Button name="save" text="Хадгалах" />
                <Button name="cancel" text="Цуцлах" />
                <Button />
              </Editing>
              <Pager
                allowedPageSizes={[10, 20, 50, 100]}
                showPageSizeSelector={true}
                showNavigationButtons={true}
                visible="true"
              />
              <Paging defaultPageSize={10} />

              <Column
                dataField="createdAt"
                dataType="date"
                colCount={1}
                colSpan={2}
                caption="Үүсгэсэн огноо"
                alignment={"center"}
                allowEditing={false}
                allowFiltering={true}
                width={"10rem"}
              />
              <Column
                dataField="created_user_name"
                colCount={1}
                allowEditing={false}
                colSpan={2}
                alignment="center"
                caption="Нийтлэсэн"
                allowFiltering={true}
              />
              <Column
                dataField="name"
                colCount={1}
                allowGrouping={true}
                allowEditing={false}
                colSpan={2}
                alignment={"center"}
                caption="Цэс"
              />
              <Column
                dataField="menu_path"
                colCount={1}
                colSpan={2}
                alignment={"center"}
                caption="Цэсийн зам"
                allowFiltering={true}
                cellRender={(e) => `/${e.value}`}
              />
              <Column
                colCount={1}
                colSpan={2}
                dataField="pmo_status"
                caption="Идэвхитэй эсэх"
                alignment="center"
                cellRender={renderStatus}
              >
                <Lookup
                  dataSource={[
                    {id: 1, status_name: "Идэвхитэй"},
                    {id: 0, status_name: "Идэвхигүй"},
                  ]}
                  valueExpr="id"
                  displayExpr="status_name"
                />
              </Column>
              {/* <Column type="buttons" caption={"Үйлдэл"}>
                <Button name="delete" icon="trash" />
                <Button name="save" text="Хадгалах" />
                <Button name="cancel" text="Цуцлах" />
                <Button />
              </Column> */}
            </DataGrid>
          </div>
        </ScrollView>
      </Popup>
    )
  }
  const renderEditMenu = () => {
    return (
      <Popup
        visible={editToggle}
        onHiding={() => setEditToggle(false)}
        hideOnOutsideClick
        showCloseButton
        height="90vh"
        showTitle
        title={"Хуудас засах"}
        width="65%"
        id="renderEditMenu"
      >
        <ScrollView height="100%" width="100%">
          <div className="widget-container">
            {editToggle && (
              <Form>
                <FormItem1 itemType={"group"} colCount={1} colSpan={2}>
                  <FormItem1 render={SelectBoxRender}>
                    <Label text="Дэд цэс сонгох" />
                    <RequiredRule message="Дэд цэс сонгоно уу!" />
                  </FormItem1>
                  <Item
                    itemType="button"
                    buttonOptions={addCategoryButtonOptions}
                  ></Item>
                  <FormItem1 dataField="title" render={TitleRender}>
                    <Label text="Гарчиг" />
                    <RequiredRule message="Гарчиг оруулна уу!" />
                  </FormItem1>
                  <FormItem1 dataField="markup" render={MarkupRender}>
                    <Label text="Нийтлэл" />
                    <RequiredRule message="Нийтлэл оруулна уу!" />
                  </FormItem1>
                  <FormItem1 render={UrlRender}>
                    <Label text="Бичлэг хавсаргах"/>
                  </FormItem1>
                  <FormItem1 render={MultipleImageUploader}>
                    <Label text="Файл хавсаргах" />
                    <RequiredRule message="Зураг оруулна уу!" />
                  </FormItem1>
                </FormItem1>
              </Form>
            )}
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-start",
                marginTop: "2rem",
                gap: ".25rem",
              }}
            >
              <span style={{width: 143}}>Хавсаргасан файлууд:</span>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  gap: ".5rem",
                }}
              >
                {fileNames &&
                  fileNames.map((item) => (
                    <a href={`${urlServer}/api/file/${item.file_name}`}>
                      {item.file_name.slice(14)}
                    </a>
                  ))}
              </div>
            </div>
            <DevExtremeButton
              icon="plus"
              text={"Нийтлэх"}
              type="default"
              style={{marginTop: "1rem"}}
              className="saveButton"
              onClick={() => handleEditPage()}
            >
              <span className="dx-button-text">
                <LoadIndicator
                  style={{height: 20, width: 20}}
                  visible={isLoading}
                />
                {!isLoading && <span>Хадгалах</span>}
              </span>
            </DevExtremeButton>
          </div>
        </ScrollView>
      </Popup>
    )
  }
  const onTypeInit = () => {
    setType(menu?.[0]?.id)
  }
  const renderShowPage = () => {
    return (
      <Popup
        visible={showPage}
        onHiding={() => setShowPage(false)}
        hideOnOutsideClick={true}
        showCloseButton
        height="90vh"
        showTitle
        title="Хуудас харах"
        width="65%"
        id="renderaddCategoryMenu"
      >
        <ScrollView height="100%" width="100%">
          <div className="widget-container">
            {content && (
              <div
                style={{width: "100%", height: "100%"}}
                dangerouslySetInnerHTML={{__html: content}}
              ></div>
            )}
          </div>
        </ScrollView>
      </Popup>
    )
  }
  const cardStyle = {
    margin: "0 1rem",
    padding: "1rem",
    border: "1px solid #eff0f3",
    borderTop: "none",
    borderBottomLeftRadius: "15px",
    borderBottomRightRadius: "15px",
  }
  return (
    <>
      <div style={cardStyle}>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            marginBottom: "1rem",
          }}
        >
          <span
            style={{display: "flex", alignItems: "center", marginRight: "1rem"}}
          >
            <span style={{marginRight: "1rem", fontWeight: 700}}>Цэс:</span>
            <SelectBox
              id="custom-templates"
              dataSource={menu}
              displayExpr="name"
              valueExpr="id"
              placeholder="Сонгох... "
              value={type}
              onValueChanged={typeHandler}
              onInitialized={onTypeInit}
            />
          </span>
          <div
            style={{display: "flex", justifyContent: "space-between", flex: 1}}
          >
            <ProjectButton
              hint="Хуудас харах"
              width={200}
              text="Үндсэн цэс нэмэх"
              type="default"
              onClick={() => {
                setManageMenuToggle(true)
              }}
            />
            <ProjectButton
              width={200}
              text="Хуудас нэмэх"
              className="projectButton"
              type="default"
              onClick={() => {
                setSelectedCategory(null)
                setContent(null)
                setAddToggle(true)
              }}
            />
          </div>
        </div>
        <DataGrid
          id="dataGrid"
          dataSource={pages}
          keyExpr="id"
          focusedRowEnabled={true}
          wordWrapEnabled={true}
          grouping={{autoExpandAll: true, expandMode: "rowClick"}}
          noDataText="Дата байхгүй байна."
          showBorders={true}
          style={{flexWrap: "wrap"}}
          showRowLines={true}
          onCellPrepared={handleOnCellPrepared}
          hoverStateEnabled={true}
          allowColumnResizing={true}
        >
          <FilterRow visible={true} />
          <GroupPanel
            visible={true}
            allowColumnDragging={true}
            emptyPanelText="Хүссэн баганаа чирж бүлэглэх боломжтой"
          />
          <HeaderFilter
            visible={true}
            texts={{
              emptyValue: "Хоосон",
              cancel: "Болих",
            }}
          />
          <Grouping
            autoExpandAll={setExpand}
            contextMenuEnabled={false}
            expandMode="rowClick"
            texts={{
              groupContinuesMessage: "Дараагийн хуудас",
              groupContinuedMessage: "Өмнөх хуудас",
            }}
          />
          <Editing
            allowUpdating={true}
            allowDeleting={true}
            mode="popup"
            height="20rem"
            texts={{
              cancelAllChanges: "Болих",
              cancelRowChanges: "Болих",
              confirmDeleteMessage: "Энэ хуудсыг устгах уу?",
              confirmDeleteTitle: "Баталгаажуулах",
              deleteRow: "Устгах",
              saveAllChanges: "Хадгалах",
              saveRowChanges: "Хадгалах",
              undeleteRow: "Буцаах",
              validationCancelChanges: "Баталгаажуулах болих",
              trueText: "Тийм",
              falseText: "Үгүй",
            }}
          >
            <Button name="save" text="Хадгалах" />
            <Button name="cancel" text="Цуцлах" />
            <Button />
          </Editing>
          <Pager
            allowedPageSizes={[10, 20, 50, 100]}
            showPageSizeSelector={true}
            showNavigationButtons={true}
            visible="true"
          />
          <Paging defaultPageSize={10} />
          <Column
            dataField="createdAt"
            dataType="date"
            colCount={1}
            colSpan={2}
            width="10rem"
            alignment={"center"}
            caption="Үүсгэсэн огноо"
            allowEditing={false}
            allowFiltering={true}
          />
          <Column
            dataField="updatedAt"
            dataType="date"
            alignment={"center"}
            colCount={1}
            colSpan={2}
            width="12rem"
            caption="Шинэчилсэн огноо"
            allowEditing={false}
          />
          <Column
            dataField="created_user_name"
            colCount={1}
            colSpan={2}
            alignment="center"
            caption="Нийтлэсэн"
            allowFiltering={true}
          />
          <Column
            dataField="category_name"
            colCount={1}
            colSpan={2}
            alignment={"center"}
            caption="Дэд цэс"
            defaultGroupIndex={0}
            allowFiltering={true}
          />

          {/* </Column> */}
          <Column
            dataField="title"
            colCount={1}
            colSpan={2}
            allowFiltering={true}
            alignment="center"
            caption="Гарчиг"
          />
          {/* <Column
            colCount={1}
            colSpan={2}
            caption="Хуудас"
            alignment="center"
            cellRender={renderPage}
          /> */}
          <Column type="buttons" caption={"Үйлдэл"}>
            <Button render={(e) => renderButton(e, 1)} />
            <Button render={(e) => renderButton(e, 2)} />
            <Button render={(e) => renderButton(e, 3)} />
            <Button render={(e) => renderButton(e, 4)} />
            <Button name="save" text="Хадгалах" />
            <Button name="cancel" text="Цуцлах" />

            <Button />
          </Column>
        </DataGrid>
      </div>
      {renderShowPage()}
      {renderEditMenu()}
      {renderCreateMenu()}
      {renderAddCategory()}
      {renderAddMenu()}
      {renderManageMenu()}
      {renderChooseActiveMenu()}
      {renderHandleFile()}
      {renderWarning()}
    </>
  )
}

export default PagesManager

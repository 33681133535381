import React, { useState, useEffect } from "react";
import LoadPanelComponent from "../budget/components/loadPanel";
import OrganizationService from "../../services/api/organization";
import notify from "devextreme/ui/notify";
import { numberWithCommas11 } from "../../util";
import Button from "devextreme-react/button";
import { Popup } from "devextreme-react/popup";
import ScrollView from "devextreme-react/scroll-view";
import mediumiconslayout from "../../assets/imgs/icon/mediumiconslayout.png";
import './updatedStructures.css'

const StructureHeaderInfo = (props) => {
    const [horizontalData, setHorizontalData] = useState({});
    const [loader, setLoader] = useState(false);
    const [orgStructureStatDetail, setOrgStructureStatDetail] = useState([]);
    const [showDetail, setShowDetail] = useState(false);
    const [detailTitle, setDetailTitle] = useState(null);
    const [detailField, setDetailField] = useState(null);
    const [isBranch, setIsBranch] = useState(null);
    const loadHorizontalData = async () => {
        try {
            setLoader(true);
            //console.log('dddd', props.org_id);
            let r = await OrganizationService.getStructureHeadInfo(props.org_id);
            //console.log('rrr', r);
            setHorizontalData(r.data[0]);

            setLoader(false);
        } catch (e) {
            setLoader(false);
            notify(e.message, 'error', 2000);
        }
    }
    useEffect(() => {
        loadHorizontalData();
    }, [props.org_id]);
    const organizationStructureStatDetail = async (org_id, econ_code, branch) => {

        setOrgStructureStatDetail([])
        let result = await OrganizationService.organizationStructureStatDetail(org_id, econ_code);
        if (result && result.code === 200) {
            //console.log(result.data);
            setOrgStructureStatDetail(result.data || []);
            setDetailTitle(result.data[0].econ_category_name_2);
            setDetailField(result.data[0].budget_status_name);
            setIsBranch(branch);
            setShowDetail(true);
        }
    }
    return (
        <div className={'row'} style={{ color: '#2f4864', fontFamily: 'Segoe UI', marginTop: 5 }}>
            <LoadPanelComponent position={{ of: '#load5' }} visible={loader} />
            {(horizontalData && (horizontalData.cnt_org > 1)) && <div className="card" style={{ flex: 1 }}>
                <Button icon={mediumiconslayout} stylingMode={'text'}
                    className="structure-detail-btn"
                    onClick={() => organizationStructureStatDetail(props.org_id, '41', 1)} />
                <div className='emp-count-cart'>
                    <div className='emp-cart-desc'> Харьяа байгууллага</div>
                    <div className='emp-cart-num'> {horizontalData.cnt_org === 206 ? numberWithCommas11(214) : numberWithCommas11(horizontalData.cnt_org)}</div>
                </div>
            </div>}
            {(horizontalData && (horizontalData.cnt_worker_all > 1 && horizontalData.cnt_org > 1 && horizontalData.cnt_worker_all !== horizontalData.cnt_worker)) &&
                <div className="card" style={{ flex: 1 }}>
                    <Button icon={mediumiconslayout} stylingMode={'text'}
                        className="structure-detail-btn"
                        onClick={() => organizationStructureStatDetail(props.org_id, '45', 1)} />
                    <div className='emp-count-cart'>
                        <div className='emp-cart-desc'> Харьяа байгууллагын албан хаагчид</div>
                        <div className='emp-cart-num'> {numberWithCommas11(horizontalData.cnt_worker_all)}</div>
                    </div>
                </div>}
            {(horizontalData && (horizontalData.cnt_tetgever_all > 1 && horizontalData.cnt_org > 1 && horizontalData.cnt_tetgever_all !== horizontalData.cnt_tetgever)) &&
                <div className="card" style={{ flex: 1 }}>
                    <Button icon={mediumiconslayout} stylingMode={'text'}
                        className="structure-detail-btn"
                        onClick={() => organizationStructureStatDetail(props.org_id, '46', 1)} />
                    <div className='emp-count-cart'>
                        <div className='emp-cart-desc'> Харьяа байгууллагын тэтгэвэрт гарагч</div>
                        <div className='emp-cart-num'> {numberWithCommas11(horizontalData.cnt_tetgever_all)}</div>
                    </div>
                </div>}
            {(horizontalData && (horizontalData.cnt_worker > 0)) &&
                <div className="card" style={{ flex: 1 }}>
                    <Button icon={mediumiconslayout} stylingMode={'text'}
                        className="structure-detail-btn"
                        onClick={() => organizationStructureStatDetail(props.org_id, '45', 0)} />
                    <div className='emp-count-cart'>
                        <div className='emp-cart-desc'> Байгууллагын албан хаагчид</div>
                        <div className='emp-cart-num'> {numberWithCommas11(horizontalData.cnt_worker)}</div>

                    </div>
                </div>}
            {(horizontalData && (horizontalData.cnt_tetgever > 0)) &&
                <div className="card" style={{ flex: 1 }}>
                    <Button icon={mediumiconslayout} stylingMode={'text'}
                        className="structure-detail-btn"
                        onClick={() => organizationStructureStatDetail(props.org_id, '46', 0)} />
                    <div className='emp-count-cart'>
                        <div className='emp-cart-desc'> Байгууллагын тэтгэвэрт гарагч</div>
                        <div className='emp-cart-num'> {numberWithCommas11(horizontalData.cnt_tetgever)}</div>
                    </div>
                </div>
            }
            <Popup
                visible={showDetail}
                title={detailTitle}
                hideOnOutsideClick={true}
                onHiding={() => setShowDetail(false)}
            >
                <ScrollView width='100%' height='100%'>
                    <div style={{ display: 'flex', padding: '20px 40px', flexDirection: 'column' }}>
                        <div style={{ display: 'flex', flex: 1 }}>
                            <div style={{ flex: 1, fontWeight: 'bold', margin: 5 }}>Эдийн засгийн ангилал</div>
                            {/*<div style={{width: 160, textAlign: 'center', fontWeight: 'bold', margin: '5px 10px'}}>Орон тооны мэдээлэл</div>*/}
                            <div style={{
                                width: 160,
                                textAlign: 'center',
                                fontWeight: 'bold',
                                margin: '5px 10px'
                            }}>{detailField}</div>
                        </div>

                        {orgStructureStatDetail.map((data, index) => {
                            return <div key={index}
                                style={{ display: 'flex', flex: 1, margin: '5px 0', fontWeight: 500 }}>
                                <div style={{
                                    flex: 1,
                                    border: '1px solid #ddd',
                                    borderRadius: 10,
                                    padding: '5px 15px',
                                    margin: 5,
                                    display: 'flex',
                                    alignItems: 'center'
                                }}>
                                    <div style={{
                                        border: '1px solid #ddd',
                                        borderRadius: 5,
                                        padding: '2px 10px'
                                    }}>{data.econ_category_code}</div>
                                    <div style={{ marginLeft: 10 }}>{data.econ_category_name}</div>
                                </div>
                                {/*          <div style={{width: 160, border: '1px solid #ddd', borderRadius: 10, padding: '5px 15px', margin: '5px 10px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                0
                            </div>*/}
                                <div style={{
                                    width: 160,
                                    border: '1px solid #ddd',
                                    borderRadius: 10,
                                    padding: '5px 15px',
                                    margin: '5px 10px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}>
                                    {numberWithCommas11(isBranch !== 1 ? data.cnt_own * 1 : data.cnt * 1 || 0)}
                                </div>
                            </div>
                        })}
                    </div>
                </ScrollView>
            </Popup>
        </div>
    )
}

export default StructureHeaderInfo

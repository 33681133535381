import React, {useState, useContext, Component} from "react";
import { Button, TextBox } from 'devextreme-react'
import { LoadIndicator } from 'devextreme-react/load-indicator';
import './dynamicTable.css'
import dynamicTableService from "../../services/api/dynamicTable";
import { Toast } from "devextreme-react/toast";
import { SelectBox, SelectBoxTypes } from "devextreme-react/select-box"
import { Popup, Position, ToolbarItem } from 'devextreme-react/popup';
import { Link } from "react-router-dom";
import budgetCalculationService from "../../services/api/budgetCalculation";
import notify from 'devextreme/ui/notify';
import Notify from "devextreme/ui/notify";

class createTable extends Component {

    constructor(props) {
        super(props);

        this.state = {
            height: 5,
            width: 5,
            actionType: 0,
            header: [],
            merge: false,
            edit: false,
            selectTD: [],
            selectedHeaders: {
                header: [],
                leftHeader: []
            },
            templateName: '',
            toastConfig: {
                isVisible: false,
                type: 'info',
                message: '',
            },
            loadingSave: false,
            initTemplateTable: true,
            row: 5,
            col: 5,

            popupVisible: false,
            positionOf: '',
            formula: '',
            economyList: [],
            economyId: null,
            economyName: '',
        }
    }

    componentDidMount() {
        this.getBudgetEconomyList();
    }

    getBudgetEconomyList = async () => {
        let res = await budgetCalculationService.getBudgetCalculationEconomyList();
        if (res.code === 200) {
            this.setState({
                economyList: res.data || []
            });
        } else {
            this.setState({
                economyList: []
            });
        }
    };

    select = (item, row, rowIndex, columnIndex) => {
        let selectTD = this.state.selectTD;
        let find = selectTD.find(v => v.id === item.id);
        let header = this.state.header;
        let sItem = header[rowIndex][columnIndex];
        switch (this.state.actionType) {
            case 1:
                if (find) {
                    selectTD = selectTD.filter(function(el) { return el.id !== item.id; });
                } else {
                    selectTD.push(item);
                }

                this.setState({selectTD: selectTD});
                break;
            case 2: break;
            case 3:
                let selectedHeaders = this.state.selectedHeaders;
                let shHeader = selectedHeaders.header;
                let shLeftHeader = selectedHeaders.leftHeader;
                let itemColSpan = item.colSpan;

                if (shHeader.length > 0) {
                    let found = -1;
                    let totalColSpan = 0;
                    let notSumColSpan = false;
                    for (let i=0; i<shHeader.length; i++) {
                        let subHeader = shHeader[i];
                        if (found === -1) {
                            totalColSpan = 0;
                            for (let i1=0; i1<subHeader.length; i1++) {
                                if (notSumColSpan === false) {
                                    totalColSpan += subHeader[i1].colSpan;
                                }

                                if (subHeader[i1].id === item.id) {
                                    found = i;
                                    notSumColSpan = true;
                                }
                            }
                        }
                    }

                    if (found === -1) {
                        shHeader.push(row);
                        shLeftHeader.shift();
                    } else {
                        let colSpan = 0;
                        let mainHeader = this.state.header;
                        shLeftHeader = [];
                        let rowSpans = [];

                        for (let i=0; i<mainHeader.length; i++) {
                            let subHeader = mainHeader[i];
                            let subColSpan = 0;
                            for (let i1=0; i1<subHeader.length; i1++) {
                                if (subHeader[i1].rowSpan > 1) {
                                    rowSpans.push({
                                        rowIndex: i,
                                        rowSpan: subHeader[i1].rowSpan,
                                        colIndex: i1,
                                        colSpan: subHeader[i1].colSpan
                                    });
                                }
                                if (totalColSpan > subColSpan) {
                                    if (colSpan === 0) {
                                        colSpan = subHeader[i1].colSpan;
                                    }

                                    // console.log('leftHeader[i]', leftHeader[i], i);

                                    if (shLeftHeader[i]) {
                                        shLeftHeader[i].push(subHeader[i1]);
                                    } else {
                                        shLeftHeader.push([subHeader[i1]]);
                                    }

                                    subColSpan += subHeader[i1].colSpan;

                                    for (let a=0; a<rowSpans.length; a++) {
                                        let tRowSpan = rowSpans[a];
                                        if (tRowSpan.rowIndex < i && tRowSpan.rowIndex+tRowSpan.rowSpan-1 >= i && tRowSpan.colIndex === i1) {
                                            subColSpan += tRowSpan.colSpan;
                                        }
                                    }
                                }
                            }
                        }
                        shLeftHeader.splice(0, shHeader.length);
                        selectedHeaders.leftHeader = shLeftHeader;
                    }
                } else {
                    shHeader.push(row);
                }
                this.setState({selectedHeaders});
                break;
            case 4:
                this.showPopup(item, rowIndex, columnIndex);
                // console.log('find', find);
                // console.log('4', item, row, rowIndex, columnIndex);
                // console.log('header', this.state.header[rowIndex][columnIndex])
                break;
            case 5:
                sItem.sumFormula = !sItem.sumFormula;
                this.setState({header});
                break;
            case 6:
                if (!sItem.formula && !sItem.configInput) {
                    sItem.config = !sItem.config;
                    this.setState({header})
                } else {
                    this.showNotif('Формула, Тохиргоо хийгдсэн багана дээр тохиргоо хийгдэх боломжгүй');
                    console.log('not possible is with formula column')
                }
                break;
            case 7:
                if (!sItem.formula && !sItem.config) {
                    sItem.configInput = !sItem.configInput;
                    this.setState({header});
                } else {
                    this.showNotif('Формула, Тохиргоо хийгдсэн багана дээр тохиргоо хийгдэх боломжгүй');
                    console.log('not possible is with formula column')
                }
                break;
            case 8:
                sItem.stringColumn = !sItem.stringColumn;
                this.setState({header});
                break;
            case 9:
                let foundIndex1 = null;
                let foundIndex2 = null;
                let maxId = 0;

                for (let i=0; i<header.length; i++) {
                    let subHeader = header[i]
                    for (let j=0; j<subHeader.length; j++) {
                        let subItemHeader = subHeader[j];
                        let intID = subItemHeader.id*1;

                        if (intID > maxId) {
                            maxId = intID;
                        }

                        if (sItem.id === subItemHeader.id) {
                            foundIndex1 = i;
                            foundIndex2 = j;
                        }
                    }
                }

                if (foundIndex1 !== null && foundIndex2 !== null) {
                    header[foundIndex1].splice(foundIndex2+1, 0, {id: `${maxId+1}`, name: `new col${maxId+1}`, rowSpan: 1, colSpan: 1});
                }

                this.setState({header});
                break;
            case 10:
                let maxID = 0;
                let totalColSpan = 0;

                for (let i=0; i<header.length; i++) {
                    let subHeader = header[i];
                    for (let j=0; j<subHeader.length; j++) {
                        let subItemHeader = subHeader[j];
                        let intID = subItemHeader.id*1;
                        if (i === 0) {
                            totalColSpan += (subItemHeader.colSpan || 0);
                        }
                        if (intID > maxID) {
                            maxID = intID;
                        }
                    }
                }

                let addRowData = [];
                Array.from({length: totalColSpan}).map((v, i) => {
                    let a = i+1;
                    addRowData.push({
                        id: `${maxID+a}`,
                        name: `new row${maxID+a}`,
                        rowSpan: 1,
                        colSpan: 1
                    });
                });

                header.push(addRowData);
                this.setState({header});
                break;
            case 11:
                header[rowIndex] = header[rowIndex].filter((v,i) => i !== columnIndex);
                // console.log('header', header, item, row, rowIndex, columnIndex);
                this.setState({header});
                break;
            case 12:
                sItem.numberColumn = !sItem.numberColumn;
                this.setState({header});
                break;
            case 13:
                sItem.configDefaultColumn = !sItem.configDefaultColumn;
                this.setState({header});
                break;
            default: break;
        }
    }

    checkSelected = (id) => {
        let find = this.state.selectTD.find(v => v.id === id);
        let cssClass = find ? 'selected' : '';
        // if (this.state.actionType === 3) {
        //     cssClass = find ? 'header' : ''
        // }
        return cssClass;
    }

    checkHeaderSelected = (id) => {
        let header = this.state.selectedHeaders.header;
        let lefHeader = this.state.selectedHeaders.leftHeader;

        // console.log('header',header, id);

        let find = null;
        for (let i=0; i<header.length; i++) {
            let subHeader = header[i];
            if (find === null) {
                for (let i1=0; i1<subHeader.length; i1++) {
                    if (subHeader[i1].id === id) {
                        find = subHeader[i1];
                    }
                }
                // find = subHeader.find(v => v.id === id);
            } else {
                // console.log('find', id, find)
            }
        }

        if (find === null) {
            for (let i=0; i<lefHeader.length; i++) {
                let subLeftHeader = lefHeader[i];
                for (let i1=0; i1<subLeftHeader.length; i1++) {
                    // console.log('left header', subLeftHeader[i1]);
                    if (subLeftHeader[i1].id === id) {
                        find = subLeftHeader[i1];
                    }
                }
            }
        }

        let cssClass = find ? 'headerSelected' : '';
        return cssClass;
    };

    editText = () => {
        let obj = {
            actionType: 2,
            edit: true
        }
        if (this.state.actionType === 2) {
            obj = {
                actionType: 0,
                edit: false
            }
        }
        this.setState(obj);
    }

    addColumn = () => {
        let obj = {
            actionType: 9,
            addColumn: true
        };
        if (this.state.actionType === 9) {
            obj = {
                actionType: 0,
                addColumn: false
            };
        }
        this.setState(obj);
    }

    addRow = () => {
        let obj = {
            actionType: 10,
            addRow: true
        }
        if (this.state.actionType === 10) {
            obj = {
                actionType: 0,
                addRow: false
            }
        }
        this.setState(obj)
    }

    removeCell = () => {
        let obj = {
            actionType: 11,
            removeCell: true
        }
        if (this.state.actionType === 11) {
            obj = {
                actionType: 0,
                removeCell: false
            }
        }
        this.setState(obj);
    }

    formula = () => {
        console.log('formula');
        let obj = {
            actionType: 4
        }

        if (this.state.actionType === 4) {
            obj = {
                actionType: 0
            }
        }
        this.setState(obj);
    }

    sumFormula = () => {
        console.log('sum formula')
        let obj = {
            actionType: 5
        }

        if (this.state.actionType === 5) {
            obj = {
                actionType: 0
            }
        }
        this.setState(obj);
    }

    selectConfigurationColumn = () => {
        let obj = {
            actionType: 6
        }

        if (this.state.actionType === 6) {
            obj = {
                actionType: 0
            }
        }
        this.setState(obj);
    }

    selectConfigurationDefaultColumn = () => {
        let obj = {
            actionType: 13
        }

        if (this.state.actionType === 13) {
            obj = {
                actionType: 0
            }
        }
        this.setState(obj);
    }

    inputConfigurationColumn = () => {
        let obj = {
            actionType: 7
        }

        if (this.state.actionType === 7) {
            obj = {
                actionType: 0
            }
        }
        this.setState(obj);
    }

    selectHeaders = () => {
        this.setState({actionType: this.state.actionType === 3 ? 0 : 3});
        if (this.state.actionType === 0) {
            let header = this.state.header;
            let selectHeader = this.state.selectedHeaders.header;
            let selectLeftHeader = this.state.selectedHeaders.leftHeader;
            // console.log('selectHeader', selectHeader, 'selectLeftHeader', selectLeftHeader);
            let tdIndex = 0;
            let trIndex = 0;
            for (let i=0; i<header.length; i++) {
                for (let b=0; b<header[i].length; b++) {
                    let found = false;
                    // console.log('header', header[i][b])
                    for (let a=0; a<selectHeader.length; a++) {
                        for (let c=0; c<selectHeader[a].length; c++) {
                            if (header[i][b].id === selectHeader[a][c].id) {
                                found = true;
                            }
                        }
                    }

                    for (let a=0; a<selectLeftHeader.length; a++) {
                        for (let c=0; c<selectLeftHeader[a].length; c++) {
                            if (header[i][b].id === selectLeftHeader[a][c].id) {
                                found = true;
                            }
                        }
                    }
                    // // console.log('found', found)
                    // if (!found) {
                    //     tdIndex+=1;
                    //     // trIndex
                    //     header[i][b].name = tdIndex;
                    // }
                }

            }

            this.setState({header})
        }
    }

    selectStringColumn = () => {
        let obj = {
            actionType: 8
        }

        if (this.state.actionType === 8) {
            obj = {
                actionType: 0
            }
        }
        this.setState(obj);
    }

    selectNumberColumn = () => {
        let obj = {
            actionType: 12
        };

        if (this.state.actionType === 12) {
            obj = {
                actionType: 0
            };
        }

        this.setState(obj)
    }

    merge = () => {
        let actionType = this.state.actionType;
        if (actionType === 1) {
            let header = this.state.header;
            let ids = this.state.selectTD;
            if (ids.length > 1) {
                let mergeCell = ids[0];
                let delCell = ids[1];
                let delCells = ids.slice(1);

                let cell1Row, cell2Row;
                for (let i=0; i<header.length; i++) {
                    header[i].find(v => {
                        if (v.id === mergeCell.id) {
                            cell1Row = i;
                        }

                        if (v.id === delCell.id) {
                            cell2Row = i;
                        }
                    });
                }

                for (let i=0; i<header.length; i++) {
                    let find = header[i].find(v => v.id === mergeCell.id);
                    if (find) {
                        if (cell1Row === cell2Row) {
                            find.colSpan = ids.length;
                        } else {
                            find.rowSpan = ids.length;
                        }
                    }
                    header[i] = header[i].filter(v => !delCells.includes(v));
                }

                this.setState({header})
            }

            this.setState({merge: false, selectTD: [], actionType: 0})
        } else {
            this.setState({actionType: 1});
        }
    }

    setName = (headerData, name) => {
        let header = this.state.header;
        for (let i=0; i<header.length; i++) {
            let find = header[i].find(v => v.id === headerData.id);
            if (find) {
                find.name = name.trim();
            }
        }

        this.setState({header})
    }

    saveTable = async () => {
        // console.log('this.state.selectedHeaders', this.state.selectedHeaders);
        // return;
        this.setState({loadingSave: true});
        let res = await dynamicTableService.setDynamicTable({
            name: this.state.templateName,
            data: this.state.selectedHeaders,
            econ_category_id: this.state.economyId
        });

        this.setState({
            toastConfig: {
                isVisible: true,
                type: 'info',
                message: 'Амжилттай хадгаллаа'
            },
            loadingSave: false,
        })

        console.log(res);
        this.props.history.push('/dynamicTable');
    }

    generateTable = () => {
        let row = this.state.row*1;
        let col = this.state.col*1;

        let header = [];
        for (let i=0; i<row; i++) {
            let headerRow = [];
            for (let i1=1; i1<=col; i1++) {
                headerRow.push({id: (i+1)+''+i1, name: (i+1)+''+i1, rowSpan: 1, colSpan: 1})
            }
            header.push(headerRow)
        }

        this.setState({header, initTemplateTable: false})
    }

    hidePopup = () => {
        this.setState({
            popupVisible: false
        })
    }

    saveFormula = () => {
        let header = this.state.header;
        let item = header[this.state.popupRowIndex][this.state.popupColumnIndex];
        item.formula = this.state.formula;

        this.setState({
            popupVisible: false,
            header
        })
    }

    showPopup = (item, rowIndex, columnIndex) => {
        console.log('item',item);

        let object = {
            popupItem: item,
            popupRowIndex: rowIndex,
            popupColumnIndex: columnIndex,
            positionOf: `#pop${item.id}`,
            popupVisible: true
        }

        if (item.formula) {
            object.formula = item.formula;
        } else {
            object.formula = '';
        }

        this.setState(object)
    }

    displayEconomy = (item) => {
        return item && item.code + ' - ' + item.name;
    }

    economyChanged = async (e) => {
        let economy = e.value || {};
        let econ = this.state.economyList.find((el) => el.id === economy.id);

        let res = await dynamicTableService.getDynamicTableByEcon(economy.id);
        console.log('res', res);
        if (res.data) {
            console.log('show toast');

            // notify(
            //     {
            //         message: "You have a new message",
            //         // width: 230,
            //         position: {
            //             at: "bottom",
            //             my: "top right",
            //             of: ".dynamicTable-container"
            //         }
            //     },
            //     'info',
            //     500
            // );
            // // this.setState({
            // //     toastConfig: {
            // //         isVisible: true,
            // //         type: 'warning',
            // //         message: 'Эдийн засгийн ангилал дээр загвар үүссэн байна.'
            // //     },
            // // })
            return;
        }
        this.setState({
            economyId: economy.id,
            economyName: econ.name
        })
    }

    addClass = (d) => {
        let className = '';
        let checkSelected = this.checkSelected(d.id);
        let checkHeaderSelected = this.checkHeaderSelected(d.id);
        if (checkSelected) {
            className += (checkSelected + ' ');
        }

        if (checkHeaderSelected) {
            className += (checkHeaderSelected + ' ');
        }

        if (d.formula) {
            className += 'withFormula ';
        }

        if (d.sumFormula) {
            className += 'sumFormula ';
        }

        if (d.config) {
            className += 'configColumn ';
        }

        if (d.configInput) {
            className += 'configInputColumn ';
        }

        if (d.configDefaultColumn) {
            className += 'configDefaultColumn';
        }

        if (d.stringColumn) {
            className += 'stringColumn ';
        }

        return className
    }

    showNotif = (text) => {
        Notify({
            message: text,
            height: 45,
            width: 300,
            minWidth: 300,
            type: 'warning',
            animation: {
                show: {
                    type: 'fade',
                    duration: 400,
                    from: 0,
                    to: 1,
                },
                hide: { type: 'fade', duration: 40, to: 0 },
            },
        }, {
            position: {
                top: 90,
                left: undefined,
                bottom: undefined,
                right: 30,
            },
            direction: 'down-push'
        });
    }

    render() {
        // console.log('this.state.header',this.state.header);
        return <div className={"dynamicTable-container"}>
            <Toast
                visible={this.state.toastConfig.isVisible}
                message={this.state.toastConfig.message}
                type={this.state.toastConfig.type}
                // onHiding={this.onHiding}
                displayTime={600}
            />
            {/*<h4>Template table create</h4>*/}


            <div className={'card'}>
                <div className={'card-body'}>
                    <Link to={'/dynamicTable'}>Тооцооллын загварын жагсаалт</Link> / <span>Тооцооллын загвар үүсгэх</span>
                </div>
            </div>

            {this.state.initTemplateTable && <div className={'card'}>
                <div className={'card-body'}>
                    <span>Баганы тоо:</span>
                    <TextBox width={200} value={this.state.col} onValueChanged={(e) => this.setState({col: e.value})}/>
                    <br/>
                    <span>Мөрийн тоо:</span>
                    <TextBox width={200} value={this.state.row} onValueChanged={(e) => this.setState({row: e.value})}/>
                    <br/>
                    <Button
                        type={'default'}
                        stylingMode={'contained'}
                        onClick={() => this.generateTable()}>
                        Загвар үүсгэх
                    </Button>
                </div>
            </div>}

            {!this.state.initTemplateTable && <div className={'card'}>
                <div className={'card-body'}>
                    <div style={{display: 'flex', gap: 10, flexDirection: 'column'}}>
                        <div style={{display: 'flex', gap: 10}}>
                            <Button
                                text="Contained"
                                type="default"
                                stylingMode="contained"

                                disabled={this.state.actionType !== 1 && this.state.actionType !== 0}
                                onClick={() => this.merge()}>{this.state.actionType === 1 ? 'Merge done' : 'Merge'}</Button>
                            <Button
                                text="Contained"
                                type="default"
                                stylingMode="contained"

                                disabled={this.state.actionType !== 2 && this.state.actionType !== 0}
                                onClick={() => this.editText()}>
                                {this.state.actionType === 2 ? 'Edit done' : 'Edit'}
                            </Button>
                            <Button
                                text={'Contained'}
                                type={'default'}
                                stylingMode={'contained'}
                                disabled={this.state.actionType !== 9 && this.state.actionType !== 0}
                                onClick={() => this.addColumn()}>
                                {this.state.actionType === 9 ? 'Add column done' : 'Add column'}
                            </Button>
                            <Button
                                text={'Contained'}
                                type={'default'}
                                stylingMode={'contained'}
                                disabled={this.state.actionType !== 10 && this.state.actionType !== 0}
                                onClick={() => this.addRow()}>
                                {this.state.actionType === 10 ? 'Add row done' : 'Add row'}
                            </Button>
                            <Button
                                text={'Contained'}
                                type={'default'}
                                stylingMode={'contained'}
                                disabled={this.state.actionType !== 11 && this.state.actionType !== 0}
                                onClick={() => this.removeCell()}>
                                {this.state.actionType === 11 ? 'Remove cell done' : 'Remove cell'}
                            </Button>
                        </div>

                        <div style={{display: 'flex', gap: 10}}>
                            <Button
                                text={'Contained'}
                                type={'default'}
                                stylingMode={'contained'}

                                disabled={this.state.actionType !== 4 && this.state.actionType !== 0}
                                onClick={() => this.formula()}>
                                {this.state.actionType === 4 ? 'Formula done' : 'Formula'}
                            </Button>
                            <Button
                                text={'Contained'}
                                type={'default'}
                                stylingMode={'contained'}

                                disabled={this.state.actionType !== 5 && this.state.actionType !== 0}
                                onClick={() => this.sumFormula()}>
                                {this.state.actionType === 5 ? 'Sum formula done' : 'Sum formula'}
                            </Button>
                        </div>

                        <div style={{display: 'flex', gap: 10}}>
                            <Button
                                text={'Contained'}
                                type={'default'}
                                stylingMode={'contained'}

                                disabled={this.state.actionType !== 6 && this.state.actionType !== 0 || this.state.selectedHeaders.header.length === 0}
                                onClick={() => this.selectConfigurationColumn()}>
                                {this.state.actionType === 6 ? 'Select config column done' : 'Select config column'}
                            </Button>
                            {/*<Button*/}
                            {/*    text={'Contained'}*/}
                            {/*    type={'default'}*/}
                            {/*    stylingMode={'contained'}*/}
                            {/*    */}
                            {/*    disabled={this.state.actionType !== 7 && this.state.actionType !== 0 || this.state.selectedHeaders.header.length === 0}*/}
                            {/*    onClick={() => this.inputConfigurationColumn()}>*/}
                            {/*    {this.state.actionType === 7 ? 'Input configuration column done' : 'Input configuration column'}*/}
                            {/*</Button>*/}

                            <Button
                                text={'Contained'}
                                type={'default'}
                                stylingMode={'contained'}
                                disabled={this.state.actionType !== 8 && this.state.actionType !== 0}
                                onClick={() => this.selectstringColumn()}>
                                {this.state.actionType === 8 ? 'Select string column' : 'Select string column done'}
                            </Button>
                            <Button
                                text={'Contained'}
                                type={'default'}
                                stylingMode={'contained'}
                                disabled={this.state.actionType !== 12 && this.state.actionType !== 0}
                                onClick={() => this.selectNumberColumn()}>
                                {this.state.actionType === 12 ? 'Select number column' : 'Select number column done'}
                            </Button>
                        </div>

                        <div style={{display: 'flex', gap: 10}}>
                            <Button
                                text="Contained"
                                type="default"
                                stylingMode="contained"
                                disabled={this.state.actionType !== 3 && this.state.actionType !== 0}
                                onClick={() => this.selectHeaders()}>
                                {this.state.actionType === 3 ? 'Select headers done' : 'Select headers'}
                            </Button>
                            <Button
                                text={'Contained'}
                                type={'default'}
                                stylingMode={'contained'}

                                disabled={this.state.actionType !== 0}
                                onClick={() => this.setState({selectedHeaders: {header: [], leftHeader: []}})}>
                                Clear selected headers
                            </Button>
                        </div>

                        <div style={{display: 'flex', gap: 10}}>
                            <Button
                                text={'Contained'}
                                type={'default'}
                                stylingMode={'contained'}

                                disabled={this.state.loadingSave || !this.state.templateName || !this.state.economyId || this.state.actionType !== 0}
                                onClick={(e) => this.saveTable(e)}>
                                {/*<LoadIndicator className="button-indicator" width={20} height={20} visible={true} />*/}
                                Save table template
                            </Button>
                        </div>
                    </div>
                    <br/><br/>

                    <div style={{display: 'flex', alignItems: 'center', gap: 10}}>
                        <span className={'withFormulaDiv'}/><span>Формула тохиргоо хийгдсэн багана.</span>
                    </div>
                    <br/>

                    <div style={{display: 'flex', alignItems: 'center', gap: 10}}>
                        <span className={'sumFormulaDiv'}/><span>Нийт дүн бодогдох багана.</span>
                    </div>
                    <br/>

                    <div style={{display: 'flex', alignItems: 'center', gap: 10}}>
                        <span className={'configColumnDiv'}/><span>Тохиргоо хийгдэх багана (Select).</span>
                    </div>
                    <br/>

                    <div style={{display: 'flex', alignItems: 'center', gap: 10}}>
                        <span className={'stringColumnDiv'}/><span>Text оруулах багана</span>
                    </div>
                    <br/>

                    <span>Загварын нэр:</span>
                    <TextBox width={400} value={this.state.templateName} onValueChanged={e => this.setState({templateName: e.value})} />
                    <br/>
                    <span>Эдийн засгийн ангилал сонгох:</span>
                    <SelectBox
                        dataSource={this.state.economyList}
                        displayExpr={this.displayEconomy}
                        width={400}
                        searchEnabled={true}
                        searchMode={'contains'}
                        searchTimeout={200}
                        minSearchLength={2}
                        placeholder={'сонгох'}
                        showDataBeforeSearch={true}
                        onValueChanged={this.economyChanged}
                    />
                    <br/><br/>
                    {/*<span style={{marginLeft: 50}}>Build template:</span>*/}

                    {/*<div style={{display: 'flex', marginLeft: 50}}>*/}
                    {/*    <table className={'tableIndex'}>*/}
                    {/*        <tbody>*/}
                    {/*            <tr>*/}
                    {/*                {this.state.header.length > 0 && this.state.header[this.state.header.length-1].map((d, i) => {*/}
                    {/*                    let i1 = i+1;*/}
                    {/*                    return <td key={i1}>{i1}</td>*/}
                    {/*                })}*/}
                    {/*            </tr>*/}
                    {/*        </tbody>*/}
                    {/*    </table>*/}
                    {/*</div>*/}
                    <div style={{display: 'flex', overflow: 'auto'}}>
                        <table className={'tableIndex'} style={{width: 50}}>
                            <tbody>
                                <tr>
                                    <td>0</td>
                                </tr>
                                {this.state.header.map((d, i) => {
                                    let i1 = i+1;
                                    return <tr>
                                        <td key={i1}>{i1}</td>
                                    </tr>
                                })}
                            </tbody>
                        </table>
                        <table className={'dynamicTable'}>
                            <thead>
                                <tr>
                                    {Array.from({length: (this.state.header[0] || []).reduce((acc,v) => {return acc+v.colSpan}, 0)}).map((d, i) => {
                                        let i1 = i+1;
                                        return <td key={i1} style={{borderTop: 0, borderLeft: 0, borderRight: 0, textAlign: 'center'}}>{i1}</td>
                                    })}
                                </tr>
                            </thead>
                            <tbody>
                            {
                                this.state.header.map((d, i) => {
                                    return <tr key={i}>
                                        {d.map((d1, i1) => {
                                            return <td
                                                key={i1}
                                                id={`pop${d1.id}`}
                                                contentEditable={this.state.edit}
                                                colSpan={d1.colSpan}
                                                rowSpan={d1.rowSpan}
                                                className={this.addClass(d1)}
                                                onBlur={e => this.setName(d1, e.target.innerText)}
                                                onClick={o => this.select(d1, d, i, i1)}
                                                style={{position: 'relative'}}>
                                                {/*{d1.formula && <div style={{height: 5, width: 10, borderRadius: 3, background: 'coral', position: 'absolute', right: 0}}/>}*/}
                                                {d1.name}
                                            </td>
                                        })}
                                    </tr>
                                })
                            }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>}

            <Popup
                visible={this.state.popupVisible}
                onHiding={this.hidePopup}
                dragEnabled={false}
                hideOnOutsideClick={true}
                showCloseButton={false}
                showTitle={true}
                title="Formula оруулах"
                container=".dx-viewport"
                width={300}
                height={220}
            >
                <Position
                    at="bottom"
                    my="center"
                    of={this.state.positionOf}
                    collision="fit"
                />
                <p>Формула жишээ нь/1+4+6/: </p>
                <TextBox width={257} value={this.state.formula} onValueChanged={(e) => this.setState({formula: e.value})}/>
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="before"
                    options={{
                        icon: 'save',
                        text: 'Хадгалах',
                        onClick: this.saveFormula
                    }}
                />
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={{
                        text: 'Хаах',
                        onClick: this.hidePopup
                    }}
                />
            </Popup>
        </div>
    }
}

export default createTable;

import React, { useContext, useState, useEffect } from "react";
import { UserContext } from "../hooks/UserContext";
import useLogout from "./../hooks/useLogout";
import {
    DropDownButton,
    Popover,
    Popup,
    ScrollView,
    Tooltip,
} from "devextreme-react";
import { pdfjs } from "react-pdf";
import "./nav-style.css";
import myInfo from "../assets/imgs/Profile.svg";
import powerOff from "../assets/imgs/power.svg";
import { Link } from "react-router-dom";
import chatService from "../services/api/chat";
import NotificationPopover from "./NotificationMenu/NotificationPopover.js";
import chatIcon from "../assets/imgs/Chat.svg";
import notifIcon from "../assets/imgs/bell.png";
import engIcon from '../assets/imgs/united-kingdom.png'
import mnIcon from '../assets/imgs/mongolia.png'
import notificationUserService from "../services/api/notificationUser";
import FileServices from "../services/api/file";
import fileJpgIcon from "../assets/imgs/file_jpg.png";
import filePngIcon from "../assets/imgs/file_png.png";
import fileDocIcon from "../assets/imgs/file_doc.png";
import fileXlsIcon from "../assets/imgs/file_xls.png";
import filePptIcon from "../assets/imgs/file_ppt.png";
import filePdfIcon from "../assets/imgs/file_pdf.png";
import fileUnknownIcon from "../assets/imgs/file_unknown.png";
import { urlServer } from "../shared/lib/request";
import usePushNotifications from "../usePushNotifications";
import closeIcon from "../assets/imgs/close.png";
import moment, { lang } from "moment";
import "moment/locale/mn";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import ImageViewer from "./ImageViewer";
import { useTranslation } from "../hooks/useTranslation";
import logo from "../assets/imgs/41367.svg";
import TusuvTimeline from "./TusuvTimeline.js";
import { RxCalendar } from "react-icons/rx";
import { Button, Drawer, Space } from "antd";
import lawIcon from '../assets/imgs/lawIcon.png'


export default function Header(props) {
    const { user } = useContext(UserContext);
    const { socket } = props;
    const { logoutUser } = useLogout();
    const [visibleNotification, setVisibleNotification] = useState(false);
    const [chatCount, setChatCount] = useState(0);
    const [notificationUsers, setNotificationUsers] = useState([]);
    //const [notifTooltip, setNotifTooltip] = useState(false);
    const [filePopUpModal, setFilePopUpModal] = useState(false);
    const [fileName, setFileName] = useState(null);
    const [fileNameText, setFileNameText] = useState(null);
    const [popupExit, setPopupExit] = useState(false);
    const { setLanguage, language } = useTranslation()
    const {
        userConsent,
        pushNotificationSupported,
        userSubscription,
        onClickAskUserPermission,
        onClickSusbribeToPushNotification,
        onClickSendSubscriptionToPushServer,
    } = usePushNotifications();

    const [dropDownItems, setDropDownItems] = useState([]);
    //const [dropdownItemExit, setDropdownItemExit] = useState([{ id: 1, text: "Гарах", icon: "export" }]);
    const [notificationUserObj] = useState({});
    const [notificationObj1, setNotificationObj1] = useState({});
    const [notificationObj1User, setNotificationObj1User] = useState({});
    const [notificationObj1File, setNotificationObj1File] = useState({});
    const [firstPopup, setFirstPopup] = useState(false);
    const [firstPopup1, setFirstPopup1] = useState(false);
    const [notificationPopupVisible, setNotificationPopupVisible] =
        useState(false);
    const visibleTypes = ["Popup", "Мэдэгдэл дотор"];
    const [countUnseenNotificationObj, setCountUnseenNotificationObj] =
        useState(0);
    const [selectedNotifId, setSelectedNotifId] = useState();
    const [notificationImages, setNotificationImages] = useState([]);
    const [activeIndex, setActiveIndex] = useState(0)
    const [pageIndex, setPageIndex] = useState(1);
    const [limit, setLimit] = useState(20);
    const [unseenCount, setUnseenCount] = useState()
    const [totalPage, setTotalPage] = useState()
    const [showTimeline, setShowTimeline] = useState(false);

    const getUserManual = async () => {
        try {
            if (user && user.id) {
                const res = await FileServices.getFileNameByType(29, user.id);
                if (res && res.code === 200) {
                    setDropDownItems(res.data);
                }
            }
        } catch (e) {
            console.log(e);
        }
    };

    const countUnseenMessages = async () => {
        try {
            const res = await chatService.countUnseenMessages();
            if (res && res.code === 200) {
                setChatCount(res.data?.count || 0);
            } else {
                setChatCount(0);
            }
        } catch (e) {
            setChatCount(0);
        }
    };
    const getAllNotificationUsers = async () => {
        try {
            const res = await notificationUserService.getNotificationUsers(2, pageIndex, limit);
            if (res && res.code === 200) {
                let filteredUsers = [];
                let count;
                if (activeIndex === 1) {
                    let res1 = await notificationUserService.getNotificationUsers(0, pageIndex, limit);
                    filteredUsers = res1.data.filter((user) => (user.notification.is_news === 1 || user.notification.is_news === null || user.notification.is_news === 0) && user.notification.is_system === 0);
                    count = res1.totalUnseenUser
                }
                else if (activeIndex === 2) {
                    let res2 = await notificationUserService.getNotificationUsers(1, pageIndex, limit);
                    filteredUsers = res2.data
                    count = res2.totalUnseenSystem
                }
                else {
                    filteredUsers = res.data
                    count = res.totalUnseen
                }
                setTotalPage(res.totalPage)
                setUnseenCount(res.totalUnseen)
                setNotificationUsers(filteredUsers);
                setCountUnseenNotificationObj(count)
            }
        } catch (e) {
            console.log(e);
        }
    };
    const loadMore = () => {
        if (pageIndex <= totalPage) {
            setPageIndex(prevCount => prevCount + 1);
        } else {
            console.log("no more data");
        }
    };

    const showNotification = async () => {
        try {
            await getAllNotificationUsers();
            setVisibleNotification(!visibleNotification);
        } catch (e) {
            console.log(e);
        }
        setActiveIndex(0)
    };

    const handleArrowClick = async (type) => {
        try {
            let index;
            if (type === "prev") {
                index = notificationUsers.findIndex((x) => x.id === selectedNotifId);
                if (index !== 0) {
                    index = index - 1;
                }
            } else {
                index = notificationUsers.findIndex((x) => x.id === selectedNotifId);
                if (index !== notificationUsers.length - 1) {
                    index = index + 1;
                }
            }

            let prevId = notificationUsers[index].id;
            setSelectedNotifId(prevId);
            let prevNotif = notificationUsers[index];
            setNotificationObj1(prevNotif.notification);
            setNotificationObj1User(prevNotif.user);
            notificationUserObj.is_seen = 1;

            let notificationImage = [];
            let notificationFile = [];

            prevNotif.notification.notification_files.map((item, index) => {
                switch (item.file.file_extention) {
                    case "jpg":
                        //notificationImage.push(urlServer + '/api/file/' + item.file.file_name);
                        notificationImage.push(item.file);
                        break;
                    case "png":
                        //notificationImage.push(urlServer + '/api/file/' + item.file.file_name);
                        notificationImage.push(item.file);
                        break;
                    case "docx":
                        notificationFile.push(item);
                        break;
                    case "xlsx":
                        notificationFile.push(item);
                        break;
                    case "pptx":
                        notificationFile.push(item);
                        break;
                    case "pdf":
                        notificationFile.push(item);
                        break;
                    default:
                        notificationFile.push(item);
                        break;
                }
            });

            setNotificationImages(notificationImage);
            setNotificationObj1File(notificationFile);
            // console.log("notification obj", notificationObj1)

            const res1 = await notificationUserService.updateNotificationUser(
                prevId,
                notificationUserObj
            );
            if (res1 && res1.code === 200) {
                await getAllNotificationUsers();
                // await countUnseenNotification();
            }
        } catch (error) {
        }
    };

    const showNotificationPopup = async (value) => {
        try {
            setSelectedNotifId(value);
            const res = await notificationUserService.getByIdNotificationUsers(value);

            if (res && res.code === 200) {
                if (res.data.notification.visible_type === 1) {
                    res.data.notification.visible_type = visibleTypes[0];
                } else {
                    res.data.notification.visible_type = visibleTypes[1];
                }

                setNotificationObj1(res.data.notification);
                // console.log("res.data", res.data);

                let notificationImage = [];
                let notificationFile = [];

                res.data.notification.notification_files.map((item, index) => {
                    switch (item.file.file_extention) {
                        case "jpg":
                            notificationImage.push(item.file);
                            break;
                        case "png":
                            notificationImage.push(item.file);
                            break;
                        case "docx":
                            notificationFile.push(item);
                            break;
                        case "xlsx":
                            notificationFile.push(item);
                            break;
                        case "pptx":
                            notificationFile.push(item);
                            break;
                        case "pdf":
                            notificationFile.push(item);
                            break;
                        default:
                            notificationFile.push(item);
                            break;
                    }
                });
                setNotificationImages(notificationImage);
                setNotificationObj1File(notificationFile);

                setNotificationObj1User(res.data.user);
            }

            setNotificationPopupVisible(true);

            notificationUserObj.is_seen = 1;

            const res1 = await notificationUserService.updateNotificationUser(
                value,
                notificationUserObj
            );

            if (res1 && res1.code === 200) {
                await getAllNotificationUsers();
                // await countUnseenNotification();
            }
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        countUnseenMessages();
    }, [props.readChat]);

    useEffect(() => {
        getAllNotificationUsers()
    }, [activeIndex, pageIndex])

    useEffect(() => {
        countUnseenMessages();
        if (userConsent === "default") {
            onClickAskUserPermission();
        }
    }, []);
    useEffect(() => {
        getUserManual();
        if (socket) {
            socket.on("msg-receive", async (data) => {
                countUnseenMessages();
            });
        }
    }, [socket]);

    const [chatNotification, setChatNotification] = useState(false);

    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    const dropDownFunc = (e) => {
        if (e.itemData.id === 1) {
            logoutUser(user);
        } else if (e.itemData.id === 2) {
            // setPopup(true);
        } else if (e.itemData.id === 6) {
            window.open(
                `https://surgalt.opengov.mn/#/dl/${localStorage.getItem("goToken")}`,
                "_blank"
            );
        } else {
            setFileName(e.itemData.file_name);
            setFileNameText(e.itemData.text);
            setFilePopUpModal(true);
        }
    };

    function toggle() {
        props.toggle();
    }
    const handleLanguageChange = () => {
        if (language === 'mn') {
            setLanguage('en');
        } else {
            setLanguage('mn');
        }
    }

    const dropDownItemRender = (data) => {
        return (
            // <div className={'item-dropdownbutton'} style={{ display: 'flex', alignItems: 'left'}}>
            <div className={'item-dropdownbutton'} style={{ display: 'flex', alignItems: 'left', textDecoration: 'underline' }}>
                {data.src ? (
                    <Link
                        style={{ color: "#2E4765", display: "inline-block", width: "100%", alignItems: 'left', flex: 1 }}
                        to={data.src}
                    >
                        <span className="dx-list-item-icon-container">
                            <i
                                style={{ color: "#2E4765" }}
                                className={"dropDownIcon dx-icon-" + data.icon}
                            // className="dropDownIcon dx-icon-"
                            />
                        </span>
                        {data.text}
                    </Link>
                ) : (
                    <div>
                        <div className="dx-list-item-icon-container">
                            <i
                                style={{ color: "#2E4765" }}
                                className={"dropDownIcon dx-icon-" + data.icon}
                            />
                        </div>
                        <span>{data.text}</span>
                    </div>
                )}
            </div>
        );
    };

    const notifTitle = ["Бүгд", "Хэрэглэгч", "Систем"]
    const handleClick = (index) => {
        setActiveIndex(index);
    }
    const popoverTitleRender = () => {
        return (
            <div>
                <div className="d-flex mt-4" >
                    {notifTitle.map((e, ind) => {
                        const isActive = activeIndex === ind
                        const showCount = isActive && countUnseenNotificationObj > 0
                        return (
                            <div className={`notifTitle d-flex col-4 w-100 ${isActive ? 'active' : ''}`} key={ind}
                                onClick={() => handleClick(ind)}>
                                <div className="notifItem" style={{ display: "flex", alignItems: "center", margin: "0 auto 5px auto", textTransform: "uppercase", fontSize: "0.8rem", fontWeight: "normal" }}>
                                    <p style={isActive ? { fontWeight: "bold" } : {}}>{e}</p>

                                    {showCount && (
                                        <div className="notifCount-card" key={ind}>
                                            {countUnseenNotificationObj}
                                        </div>
                                    )}
                                </div>
                            </div>
                        )
                    })}
                </div>

            </div>
        );
    };
    const HandleHover = (event) => {
        event.target.style.backgroundColor = '#EEF2F8';
    }
    const HandleHoverOut = (event) => {
        event.target.style.backgroundColor = '#fff';
    }
    // console.log("notificationObj1.notif_info", notificationObj1.notif_info.replace(/<\/?[^>]+(>|$)/g, ""));
    const readMore = (notificationObj1) => {

        const getRoute = () => {
            switch (notificationObj1.is_news) {
                case 1:
                    return `/article/${notificationObj1.news_id}`
                case 2:
                    return '/plan/schedule-edit'
                case 3:
                    return '/project'
                case 4:
                    return '/performance'
                default:
                    return ''
            }
        }

        let to = getRoute()

        return (
            <button
                className="moreButton"
            >
                <Link
                    style={{ color: "black", textDecoration: "none" }}
                    onClick={() => { setNotificationPopupVisible(false); setVisibleNotification(false) }}
                    to={to}
                >
                    Дэлгэрэнгүй
                </Link>
            </button>
        )
    }

    const onClickNotificationPopupClose = () => {
        setNotificationPopupVisible(false);
    };

    const notificationPopupTitle = () => {
        return (
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    borderBottom: "1px solid rgba(221, 221, 221, 0.618)",
                    margin: "0 2rem",
                    padding: "20px 0"
                }}
            >
                <div className="align-self-end">
                    <img
                        onClick={onClickNotificationPopupClose}
                        style={{ width: 15, height: 15, float: "right", cursor: "pointer" }}
                        src={closeIcon}
                    />
                </div>
                <div style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                }}>
                    <img
                        src={notificationObj1.is_system === 1 ? logo :
                            notificationObj1User?.file_path ? notificationObj1User?.file_path :
                                ""
                        }
                        style={{
                            width: 55,
                            height: "100%",
                            objectFit: "scale-down",
                            boxSizing: "content-box",


                        }}
                    />

                    <br />
                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        width: "70%",
                    }}>
                        <span style={{
                            fontFamily: "Roboto",
                            fontSize: "14px",
                            display: 'flex',
                            marginRight: 'auto',
                            fontWeight: notificationObj1.is_system === 1 ? "bold" : "",
                            paddingLeft: '0.5rem'
                        }}>
                            {notificationObj1.is_system === 1 ? "Системийн мэдэгдэл" : notificationObj1User?.org_info?.name}
                        </span>
                    </div>
                    <span
                        style={{
                            fontFamily: "Roboto",
                            float: 'right',
                            fontWeight: 500,
                            fontSize: "12px",
                            color: "#767676",
                            width: "20%",
                            display: 'flex',
                            justifyContent: 'end',
                            alignSelf: "flex-end",
                        }}
                    >
                        {new moment(notificationObj1.createdAt).locale('mn').format("YYYY-MM-DD HH:mm")}
                    </span>
                </div>

            </div>
        );
    };
    const notificationIconSrc = (string) => {
        switch (string) {
            case "xlsx":
                return fileXlsIcon;
            case "png":
                return filePngIcon;
            case "jpg":
                return fileJpgIcon;
            case "pptx":
                return filePptIcon;
            case "pdf":
                return filePdfIcon;
            case "docx":
                return fileDocIcon;
            default:
                return fileUnknownIcon;
        }
    };
    /*const notifClick = () => {
        onClickAskUserPermission();
    };*/
    useEffect(() => {
        // console.log("userConsent", userConsent, pushNotificationSupported)
        if (pushNotificationSupported && userConsent === "granted") {
            onClickSusbribeToPushNotification();
        }
    }, [userConsent]);

    let us = "";

    useEffect(() => {
        // console.log(us, userSubscription)
        if (userSubscription && us !== userSubscription) {
            us = userSubscription;
            // console.log('userSubscription', userSubscription)
            onClickSendSubscriptionToPushServer();
        }
    }, [userSubscription]);
    const onChangeExit = (e) => {
        setPopupExit(true);
    }
    const dropDownOptions = {
        wrapperAttr: {
            class: 'item-dropdownbutton'
        }
    };
    return (
        <div
            className="header-container"
            style={{ paddingLeft: props.isToggle ? "310px" : 14 }}
        >
            <div
                style={{
                    display: "flex",
                    flex: 1,
                    flexDirection: "row",
                    alignItems: "center",
                    paddingTop: 10,
                    paddingRight: 20,
                }}
            ></div>
            <button
                className={"timelineButton"}
                id="header_timeline"
                onClick={() => setShowTimeline(!showTimeline)}
                style={{
                    position: "relative",
                    width: "40px"
                }}
            >
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <RxCalendar style={{ width: 24, height: 24 }} color="#003696" />
                </div>
            </button>
            <DropDownButton
                className="custom-dropdownbutton"
                dropDownOptions={{ width: 300 }}
                text={'ГАРЫН АВЛАГА'}
                items={dropDownItems}
                noDataText={'Таны эрх дээр гарын авлага байхгүй байна'}
                stylingMode="text"
                onItemClick={dropDownFunc}
                useItemTextAsTitle={true}
                itemRender={dropDownItemRender}
                showArrowIcon={true}
                style={{
                    display: "flex",
                    fontFamily: 'Segoe UI',
                    alignItems: "center",
                    justifyContent: "center",
                    position: "relative",
                    cursor: "pointer",
                    marginRight: "8px"
                }}
            />
            <button
                className={`notificationButton`}
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
                onClick={handleLanguageChange}
            >
                <img
                    loading="lazy"
                    style={{ width: 20, height: 20 }}
                    src={language === "mn" ? engIcon : mnIcon}
                />
            </button>
            <Link to="/chat">
                <button
                    className={`notificationButton`}
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                    onClick={() => setChatNotification(false)}
                >
                    <img
                        loading="lazy"
                        style={{ width: 20, height: 20 }}
                        src={chatIcon}
                    />
                    {chatCount > 0 && (
                        <div className={"notificationCount"}>{chatCount}</div>
                    )}
                </button>
            </Link>
            <button
                className={"notificationButton"}
                id="header_notification"
                onClick={showNotification}
                style={{
                    position: "relative",
                    width: "40px",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <img loading="lazy" src={notifIcon} style={{ width: 20, height: 20 }} />
                    <div
                        style={{
                            position: "absolute",
                            top: 10,
                            right: 10,
                            borderRadius: "50%",
                            backgroundColor: "red",
                            color: "white",
                            width: ".5rem",
                            height: ".5rem",
                            border: "1px solid white",
                            visibility: unseenCount > 0 ? "visible" : "hidden"
                        }}
                    />
                </div>
            </button>
            <Link to="/myInfo">
                <button
                    className={`myInfo `}
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        position: 'relative'
                    }}
                >
                    <img alt="" loading="lazy" style={{ width: 20, height: 20 }} src={myInfo} />
                </button>
            </Link>
            <Drawer
                onClose={() => setShowTimeline(false)}
                open={showTimeline}
                title="УЛСЫН ТӨСВИЙН ЦАГЛАБАР"
                bodyStyle={{ padding: 12 }}
                extra={
                    <Space >
                        <Button id="law_link" type="link" style={{ padding: 0 }}
                            onClick={() => window.open("https://legalinfo.mn/mn/detail/12254")}>
                            <img src={lawIcon} alt='' width={24} />
                        </Button>
                        <Tooltip target="#law_link" showEvent="mouseenter" hideEvent="mouseleave" position={{ my: 'left', at: 'bottom left', collision: 'fit flip' }}
                            contentRender={() => (
                                <div className='subTitle'>Төсвийн тухай хууль</div>)}
                        >
                        </Tooltip>
                    </Space>
                }
            >
                <TusuvTimeline />
            </Drawer>
            <Popover
                target="#header_notification"
                titleRender={popoverTitleRender}
                showTitle={true}
                position="top"
                width={'25rem'}
                maxHeight='98%'
                visible={visibleNotification}
                setVisibleNotification={setVisibleNotification}
                hideOnOutsideClick={true}
                onVisibleChange={setVisibleNotification}
            >
                <NotificationPopover
                    loadMore={loadMore}
                    pageIndex={pageIndex}
                    totalPage={totalPage}
                    showNotificationPopup={showNotificationPopup}
                    notificationUsers={notificationUsers}
                />
            </Popover>
            <Popup
                visible={firstPopup}
                showTitle={false}
                height="95vh"
                hideOnOutsideClick={true}
                onHiding={() => {
                    setFirstPopup(false);
                }}
            >
                <iframe
                    width="100%"
                    style={{ height: "100vh" }}
                    src={`https://legalinfo.mn/mn/detail/12254`}
                />
            </Popup>
            <Popup
                visible={firstPopup1}
                // title=""
                showTitle={false}
                height="95vh"
                hideOnOutsideClick={true}
                onHiding={() => {
                    setFirstPopup1(false);
                }}
            >
                <iframe
                    width="100%"
                    style={{ height: "100vh" }}
                    src={`https://legalinfo.mn/mn/detail/503`}
                />
            </Popup>
            <Popup
                visible={notificationPopupVisible}
                setNotificationPopupVisible={setNotificationPopupVisible}
                onVisibleChange={setNotificationPopupVisible}
                dragEnabled={false}
                hideOnOutsideClick={true}
                className="notificationPopup"
                showCloseButton={true}
                showTitle={true}
                titleComponent={notificationPopupTitle}
                width="45rem"
                height="auto"
                maxHeight={"72vh"}
            >
                <>
                    <div className="leftArrow" onClick={() => handleArrowClick("prev")}>
                        <IoIosArrowBack />
                    </div>
                    <div className="rightArrow" onClick={() => handleArrowClick("next")}>
                        <IoIosArrowForward />
                    </div>
                    <ScrollView width="100%" height="100%" className="popup">
                        <div
                            style={{
                                fontFamily: "Roboto",
                                fontWeight: 700,
                                fontSize: "1rem",
                                margin: "0",
                            }}
                        >
                        </div>
                        <div style={{ fontWeight: 'bold', marginLeft: '1.3rem' }}>{notificationObj1.title}</div>
                        <br />
                        <div
                            style={{
                                fontFamily: "Roboto",
                                fontWeight: 400,
                                fontSize: "15px",
                                paddingBottom: 20,
                                margin: "0 1.3rem 1rem 1.3rem",
                                borderBottom: "1px solid rgba(221, 221, 221, 0.618)",

                            }}
                            dangerouslySetInnerHTML={{
                                __html:
                                    notificationObj1.is_news === 1
                                        ? null
                                        : notificationObj1.notif_info
                            }}
                        />
                        <div style={{ textAlign: "right", margin: "0" }}>
                            {notificationObj1.is_news === 1 &&
                                notificationObj1.notification_files &&
                                notificationObj1.notification_files.length > 0 &&
                                notificationObj1.notification_files[0].file && (
                                    <div style={{ padding: "0 1rem" }}>
                                        <ImageViewer
                                            array={[notificationObj1.notification_files[0].file]}
                                            filename="file_name"
                                            width="31.5rem"
                                            height="19rem"
                                            margin="10px"
                                        />
                                    </div>
                                )}
                            {notificationObj1.is_news === 1 || notificationObj1.is_news === 2 ? readMore(notificationObj1) : <div></div>}
                        </div>
                        {notificationImages && (
                            <div style={{ padding: "0 1rem" }}>
                                <ImageViewer
                                    array={notificationImages}
                                    filename="file_name"
                                    height="20rem"
                                    margin="10px"
                                />
                            </div>
                        )}
                        <div style={{ padding: "20px 1rem" }}>
                            {notificationObj1File.length && notificationObj1.is_news !== 1
                                ? notificationObj1File.map((item, index) => {
                                    return (
                                        <a
                                            target="_self"
                                            key={index}
                                            href={
                                                urlServer +
                                                "/api/fileServices/getFile/" +
                                                item.file.id
                                            }
                                            style={{
                                                display: "inline-table",
                                                marginRight: 20,
                                                cursor: "pointer",
                                                textAlign: "center",
                                                textDecoration: "none",
                                            }}
                                        >
                                            {(() => {
                                                return (
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            gap: ".5rem",
                                                            alignItems: "flex-end",
                                                            justifyContent: "flex-end",
                                                            marginTop: 10,
                                                        }}
                                                    >
                                                        <img
                                                            id={"notification_image_" + item.file.id}
                                                            style={{ height: 40 }}
                                                            src={notificationIconSrc(
                                                                item.file.file_extention
                                                            )}
                                                        />
                                                        <span>{item.file.file_name}</span>
                                                    </div>
                                                );
                                            })()}
                                        </a>
                                    );
                                })
                                : null}
                        </div>
                    </ScrollView>
                </>
            </Popup>
            {user?.worker_id !== null &&
                <div
                    className="navbarGreeting"
                    style={{
                        flexDirection: "row",
                        justifyContent: "space-evenly", marginLeft: 20
                    }}
                >
                    <div style={{ direction: "column" }}>
                        <div style={{ color: "#000000", fontSize: 11, fontFamily: 'Segoe UI', fontWeight: "medium" }}>Сайн байна уу?</div>
                        <div style={{ fontWeight: "bold", fontSize: 13, color: "#15186A", fontFamily: 'Roboto' }}>
                            {user &&
                                user.last_name &&
                                user.last_name.slice(0, 1) + "." + user.first_name}
                        </div>
                    </div>
                </div>}
            <div
                className="navbarDropDown"
                style={{
                    borderRadius: 20,
                    backgroundColor: "#fff",
                    marginBottom: ".8rem",
                }}
            >
                <img
                    style={{
                        marginRight: 5,
                        position: "relative",
                        cursor: "pointer",
                        width: "1.5rem",
                        paddingTop: 12,
                    }}
                    src={powerOff}
                    onClick={onChangeExit}
                />
            </div>
            {fileName && (
                <Popup
                    width={1200}
                    maxWidth={"100%"}
                    minHeight={"100%"}
                    height={"100%"}
                    title={fileNameText && fileNameText}
                    visible={filePopUpModal}
                    onHiding={() => {
                        setFilePopUpModal(false);
                    }}
                    dragEnabled={false}
                    hideOnOutsideClick={true}
                >
                    <object
                        width="100%"
                        height="100%"
                        data={urlServer + "/api/file/documents/" + fileName}
                        type="application/pdf"
                    />
                </Popup>
            )}
            {popupExit && (
                <Popup
                    width={"270"}
                    height={"130"}
                    title={''}
                    showTitle={false}
                    visible={popupExit}
                    onHiding={() => {
                        setPopupExit(false);
                    }}
                    dragEnabled={false}
                >
                    <div style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        marginBottom: 0,
                        width: '100%',
                        height: '100%',
                        borderColor: '#E8EDF6'
                    }}>
                        <div style={{
                            flex: 0.8,
                            display: 'flex',
                            justifyContent: 'center',
                            borderColor: "#E8EDF6",
                            flexDirection: 'column',
                            alignItems: 'center',
                            paddingTop: 28
                        }}>
                            <span style={{
                                backgroundColor: "#fff",
                                textAlign: 'center',
                                fontWeight: "700",
                                fontSize: '16px',
                                fontFamily: 'Segoe UI',
                                paddingLeft: 50,
                                color: "#000000",
                                paddingRight: 50
                            }}>
                                СИСТЕМЭЭС ГАРАХ
                            </span>
                        </div>
                        <div style={{ height: "50px", width: '100%', marginTop: '29px' }}>
                            <div style={{
                                display: 'flex',
                                height: "100%",
                                justifyContent: "space-between",
                                flexDirection: 'row',
                                borderTop: "2px solid #E8EDF6",
                                fontWeight: "600",
                                fontSize: '13px',
                                color: "#000000",
                                fontFamily: 'Segoe UI',
                            }}>
                                <div
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        flex: 0.5,
                                        backgroundColor: "#fff",
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        textAlign: 'center',
                                        borderRight: "2px solid #E8EDF6",
                                        borderBottomLeftRadius: '1rem',
                                        cursor: 'pointer',
                                        paddingTop: "15px",
                                    }} onClick={() => logoutUser(user)}
                                    onMouseOver={HandleHover} onMouseOut={HandleHoverOut} >
                                    ТИЙМ
                                </div>
                                <div onMouseOver={HandleHover} onMouseOut={HandleHoverOut} style={{
                                    width: '100%',
                                    height: '100%',
                                    flex: 0.5,
                                    backgroundColor: "#fff",
                                    borderBottomRightRadius: '1rem',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    textAlign: 'center',
                                    cursor: 'pointer',
                                    paddingTop: "15px"
                                }} onClick={() => { setPopupExit(false); }}>
                                    ҮГҮЙ

                                </div>
                            </div>
                        </div>
                    </div>
                </Popup>
            )}
        </div>
    );
}

import request from '../../shared/lib/request'

function budgetDetail(yearValue,filteredParent,filteredOrg) {
    return request({
        url:    `/budget/get/getDataForAccount/${yearValue}?filteredParent=${filteredParent}&filteredOrg=${filteredOrg}`,
        method: 'GET'
    });
}

function budgetNewDetails() {
    return request({
        url:    `/budget/get/budgetNewDetails`,
        method: 'GET'
    });
}

function budgetDetailView(filter) {
    const {
        yearValue,
        typeValue,
        budgetStatusValue,
        orgValue,
        econValue
    } = filter;

    return request({
        url:    `/budget/get/budgetDetailsView?year=${yearValue}&aimag=${typeValue}&status=${budgetStatusValue}&org=${orgValue}&econ=${econValue}`,
        method: 'GET'
    });
}


// Donut 1 data
function incomeChart(year, budgetStatus) {
    return request({
        url:    '/dashboard/stat/budget/income?year='+ year +'&budget_status=' + budgetStatus,
        method: 'GET'
    });
}

function expenseChart(selectedYear, selectedBudgetStatus, selectedExpense) {
    return request({
        url:    '/dashboard/stat/budget/expense?year='+selectedYear+'&budget_status='+selectedBudgetStatus+'&expense_type='+selectedExpense,
        method: 'GET'
    });
}

function econOtherInfo(selectedYear, econ2) {
    return request({
        url:    `/dashboard/stat/otherInfo?year=${selectedYear}&econ2=${econ2}`,
        method: `GET`
    });
}

function econOtherInfoByYear(econ2) {
    return request({
        url:    `/dashboard/stat/otherInfo/byYear?econ2=${econ2}`,
        method: `GET`
    })
}

function econData(selectedYear, selectedBudgetStatus) {
    return request({
        url:    '/dashboard/stat/list?year='+selectedYear+'&budget_status='+selectedBudgetStatus,
        method: 'GET'
    })
}

function econ2DataGuitsetgelUpdated(selectedYear, econ, orgId) {
    return request({
        url:    `/dashboard/stat/list/econ2/guitsetgel/updated?year=${selectedYear}&econ=${econ}&orgId=${orgId}`,
        method: `GET`
    })
}

function econ2Data(selectedYear, econ, orgId) {
    return request({
        url:    `/dashboard/stat/list/econ2?year=${selectedYear}&econ=${econ}&orgId=${orgId}`,
        method: `GET`
    })
}

function econ2DataUpdated(selectedYear, econ, orgId) {
    return request({
        url:    `/dashboard/stat/list/econ2/updated?year=${selectedYear}&econ=${econ}&orgId=${orgId}`,
        method: `GET`
    })
}

function econMainData(selectedYear, econ, orgId) {
    return request({
        url:    `/dashboard/stat/list/econ1?year=${selectedYear}&econ=${econ}&orgId=${orgId}`,
        method: 'GET'
    })
}

function econMainDataUpdated(selectedYear, econ, orgId) {
    return request({
        url:    `/dashboard/stat/list/updated/econ1?year=${selectedYear}&econ=${econ}&orgId=${orgId}`,
        method: 'GET'
    })
}

function econMainDataFirst(selectedYear, econ, orgId) {
    return request({
        url:    `/dashboard/stat/list/econ/first/updated?year=${selectedYear}&econ=${econ}&orgId=${orgId}`,
        method: 'GET'
    })
}

function econMainDataSecond(selectedYear, econ, orgId) {
    return request({
        url:    `/dashboard/stat/list/econ/second/updated?year=${selectedYear}&econ=${econ}&orgId=${orgId}`,
        method: 'GET'
    })
}


function econGraph(econ, orgId) {
    return request({
        url:    `/dashboard/stat/year?econ=${econ}&orgId=${orgId}`,
        mehotd: 'GET'
    })
}

function econGraphOnlyOrlogo(econ, orgId) {
    return request({
        url:    `/dashboard/onlyOrlogo/stat/year?econ=${econ}&orgId=${orgId}`,
        mehotd: 'GET'
    })
}

function budgetStatus() {
    return request({
        url:       '/refs/budget_status',
        method: 'GET'
    })
}

function econPerformance(selectedYear) {
    return request({
        url: '/dashboard/stat/list?year='+selectedYear+'&budget_status=7',
        method: 'GET'
    })
}

function econGraphData(selectedBudgetStatus) {
    return request({
        url: '/dashboard/stat/year?budget_status='+selectedBudgetStatus,
        method: 'GET'
    })
}

function econHigh(econCategory, budgetStatus) {
    return request({
        url: `/dashboard/stat/budget/high/${econCategory}/${budgetStatus}`,
        method: 'GET'
    })
}

function econEA(EA, year, budgetStatus, econ, orgId) {
    return request({
        url:    `/dashboard/stat/budget/ea/${EA}?year=${year}&budget_status=${budgetStatus}&econ=${econ}&orgId=${orgId}`,
        method: 'GET'
    })
}

function budgetOrganization(year, budgetStatus, econ, orgId) {
    return request({
        url:    `/dashboard/stat/organization?year=${year}&budget_status=${budgetStatus}&econ=${econ}&orgId=${orgId}`,
        method: 'GET'
    })
}

function budgetOrganizationYear(budgetStatus, econ) {
    return request({
        url:    `/dashboard/stat/organization/year?budget_status=${budgetStatus}&econ=${econ}`,
        method: 'GET'
    })
}

function budgetEconCategory(budgetStatus, econ, year, orgId) {
    return request({
        url:    `/dashboard/stat/econ_category?budget_status=${budgetStatus}&econ=${econ}&year=${year}&orgId=${orgId}`,
        method:  `GET`
    })
}

function budgetStat(year) {
    return request({
        url: `/budget/get/org/${year}`,
        method: 'GET'
    })
}

function setExcelFileInput(data,year,month,last) {
    return request({
        url:    `/organizations/setExcelFileInput?year=${year}&month=${month}&last=${last}`,
        data: data,
        method: 'POST'
    })
}

function insertData() {
    return request({
        url:       '/organizations/insertData',
        method: 'GET'
    })
}

function insertMonthlyAction(data) {
    return request({
        url:       '/budget/uploadMonthlyHuwaari',
        data: data,
        method: 'POST'
    })
}

function getMonthlyHuwaari(year, bpi, bpdi) {
    return request({
        url:       `/budget/getMonthlyHuwaari/${year}/${bpi}/${bpdi}`,
        method: 'GET'
    })
}

function changeHuwaariStatus(budgetProjectId) {
    return request({
        url:       `/budget/changeHuwaariStatus/${budgetProjectId}`,
        method: 'POST'
    })
}

function getEAByOrg(econ, year, ea, eaCode) {
    return request({
        url:    `/dashboard/stat/ea/byOrg?econ=${econ}&year=${year}&ea=${ea}&eaCode=${eaCode}`,
        method: `GET`
    })
}

function getBudgetStatByOrgEcon2(econ, year, orgId) {
    return request({
        url:    `/dashboard/stat/budget/orgList/byEcon2?econ=${econ}&year=${year}&orgId=${orgId}`,
        method: `GET`
    })
}

function getBudgetStatByOrgEcon1(econ, year, orgId) {
    return request({
        url:    `/dashboard/stat/budget/orgList/byEcon1?econ=${econ}&year=${year}&orgId=${orgId}`,
        method: `GET`
    })
}

function getBudgetOrgEventAgenda(econ, year, orgId) {
    return request({
        url:    `/dashboard/stat/budget/org/eventAgenda?econ=${econ}&year=${year}&orgId=${orgId}`,
        method: `GET`
    })
}

function getBudgetOrgList() {
    return request({
        url:    `/dashboard/stat/budget/orgList`, // this
        method: `GET`
    })
}

function getBudgetOrganization() {
    return request({
        url:    `/dashboard/stat/budget/organizationList`,
        method: `GET`
    })
}

function getBudgetSchedule(econ, year, orgId) {
    return request({
        url:    `/dashboard/stat/budget/schedule?econ=${econ}&year=${year}&orgId=${orgId}`,
        method: `GET`
    })
}

function getBudgetByAgenda(econ, year, orgId) {
    return request({
        url:    `/dashboard/stat/budget/agenda?econ=${econ}&year=${year}&orgId=${orgId}`,
        method: `GET`
    })
}

function getBudgetScheduleInfo(budgetYear, orgId, agId, eventId,econId) {
    return request({
        url:    `/budget/getBudgetScheduleInfo/${budgetYear}/${orgId}/${agId}/${eventId}/${econId}`,
        method: `GET`
    })
}

function getExecutionChart(year, month) {
    return request({
        url:    `/budget/getExecutionChart/${year}/${month}`,
        method: `GET`
    })
}
function getExecutionChartByOrgList(type,year, month) {
    return request({
        url:    `/budget/getExecutionChartByOrgList/${type}/${year}/${month}`,
        method: `GET`
    })
}
function getExecutionList(year) {
    return request({
        url:    `/budget/getExecutionList/${year}`,
        method: `GET`
    })
}

function getBudgetLawByYear(year) {
    return request({
        url:    `/budget/getBudgetLawByYear/${year}`,
        method: `GET`
    })
}

function getExecutionOrgList(year) {
    return request({
        url:    `/budget/getExecutionOrgList/${year}`,
        method: `GET`
    })
}

function getTenYearsDataChart(type) {
    return request({
        url:    `/budget/getTenYearsData/${type}`,
        method: `GET`
    })
}

function getIncomeTenYears(filterOrg, ministry, org_id) {
    return request({
        url:    `/budget/getIncomeTenYears?filterOrg=${filterOrg}&ministry=${ministry}&org_id=${org_id}`,
        method: `GET`
    })
}

function getDoughnutCharData(year, type) {
    return request({
        url:    `/budget/getDoughnutCharData/${year}/${type}`,
        method: `GET`
    })
}

function fourthChartData(year, type) {
    return request({
        url:    `/budget/fourthChartData/${year}/${type}`,
        method: `GET`
    })
}

function getYamsData(year, type) {
    return request({
        url:    `/budget/getYamsData/${year}/${type}`,
        method: `GET`
    })
}
function getIncomeAmount(year) {
    return request({
        url:    `/budget/getIncomeAmount/${year}`,
        method: `GET`
    })
}

function getIncomePieChartData(year) {
    return request({
        url:    `/budget/getIncomePieChartData/${year}`,
        method: `GET`
    })
}

function getIncomeBarChartData() {
    return request({
        url:    `/budget/getIncomeBarChartData`,
        method: `GET`
    })
}

function getIncomeMiddleSectionsData(year) {
    return request({
        url:    `/budget/getIncomeMiddleSectionsData/${year}`,
        method: `GET`
    })
}

function getIncomeBubbleChartData(year) {
    return request({
        url:    `/budget/getIncomeBubbleChartData/${year}`,
        method: `GET`
    })
}

function getIncomeByAgenda(year) {
    return request({
        url:    `/budget/getIncomeByAgenda/${year}`,
        method: `GET`
    })
}

function getExpenseMiniData(year, filterOrgText, ministry,org_id) {
    return request({
        url:    `/budget/getExpenseMiniData/${year}?filterOrg=${filterOrgText}&ministry=${ministry}&org_id=${org_id}`,
        method: `GET`
    })
}

function getExpenseHorizonData(year) {
    return request({
        url:    `/budget/getExpenseHorizonData/${year}`,
        method: `GET`
    })
}


function getExpenseSectionBarChart() {
    return request({
        url:    `/budget/getExpenseSectionBarChart`,
        method: `GET`
    })
}

function getExpenseMiddleSectionsData(year) {
    return request({
        url:    `/budget/getExpenseMiddleSectionsData/${year}`,
        method: `GET`
    })
}

function getExpenseBottomData(year) {
    return request({
        url:    `/budget/getExpenseBottomData/${year}`,
        method: `GET`
    })
}

function getExpenseBubbleChartData(year) {
    return request({
        url:    `/budget/getExpenseBubbleChartData/${year}`,
        method: `GET`
    })
}

function getSourceHeaderData(year, filterOrg, ministry) {
    return request({
        url:    `/budget/getSourceHeaderData/${year}?filterOrg=${filterOrg}&ministry=${ministry}`,
        method: `GET`
    })
}

function getSourceHorizonData(year) {
    return request({
        url:    `/budget/getSourceHorizonData/${year}`,
        method: `GET`
    })
}

function getSourceSectionBarChart() {
    return request({
        url:    `/budget/getSourceSectionBarChart`,
        method: `GET`
    })
}

function getSourceMiddleSectionsData(year) {
    return request({
        url:    `/budget/getSourceMiddleSectionsData/${year}`,
        method: `GET`
    })
}

function getSourceBubbleChartData(year) {
    return request({
        url:    `/budget/getSourceBubbleChartData/${year}`,
        method: `GET`
    })
}

function getSourceBottomData(year) {
    return request({
        url:    `/budget/getSourceBottomData/${year}`,
        method: `GET`
    })
}

function getExpenseZadgaiData(year, orgFilterText, ministry,org_id) {
    return request({
        url:    `/budget/getExpenseZadgaiData/${year}?filterOrg=${orgFilterText}&ministry=${ministry}&org_id=${org_id}`,
        method: `GET`
    })
}

function getAgendaEventDetailCounts(year, orgFilterText) {
    return request({
        url: `/budget/getAgendaEventDetailCounts/${year}?filterOrg=${orgFilterText}`,
        method: `GET`
    })
}

function getAgendaByOrgs(year, orgFilterText) {
    return request({
        url: `/budget/getAgendaByOrgs/${year}?filterOrg=${orgFilterText}`,
        method: `GET`
    })
}

function getAgendaCount(orgFilterText) {
    return request({
        url: `/budget/getAgendaCount?filterOrg=${orgFilterText}`,
        method: `GET`
    })
}

function getBudgetDetailByAgenda(year, org ) {
    return request({
        url: `/budget/getBudgetDetailByAgenda/${year}?filterOrg=${org}`,
        method: 'GET',
    });
}

function getBudgetDetailByEvent(year, orgFilterText, parentAgendaId, agendaId) {
    return request({
        url: `/budget/getBudgetDetailByEvent/${year}?filterOrg=${orgFilterText}&parentAgendaId=${parentAgendaId}&agendaId=${agendaId}`,
        method: `GET`
    })
}

function getExpenseZadgaiMonth(year, org_id,filterOrg,ministry) {
    return request({
        url:    `/budget/getExpenseZadgaiMonth/${year}?filterOrg=${filterOrg}&ministry=${ministry}&org_id=${org_id}`,
        method: `GET`
    })
}

function getOrlogoZadgaiData(year, type, org,org_id) {
    return request({
        url:    `/budget/getOrlogoZadgaiData/${year}?type=${type}&org=${org}&org_id=${org_id}`,
        method: `GET`
    })
}

function getOrlogoZadgaiDataGrid(year, type,org_id) {
    return request({
        url:    `/budget/getOrlogoZadgaiDataGrid/${year}?type=${type}&org_id=${org_id}`,
        method: `GET`
    })
}

function getExpenseZadgaiChilds(year, monthFilter, orgTextFilter) {
    return request({
        url:    `/budget/getExpenseZadgaiChilds/${year}?filterMonth=${monthFilter}&filterOrg=${orgTextFilter}`,
        method: `GET`
    })
}

function getOrlogoZadgaiChilds(year, monthFilter, orgTextFilter) {
    return request({
        url:    `/budget/getOrlogoZadgaiChilds/${year}?filterMonth=${monthFilter}&filterOrg=${orgTextFilter}`,
        method: `GET`
    })
}

function getExpenseLastService(orgTextFilter,org_id) {
    return request({
        url:    `/budget/getExpenseLastService?filterOrg=${orgTextFilter}&org_id=${org_id}`,
        method: `GET`
    })
}

function getExpenseByTodotgol(orgTextFilter,org_id) {
    return request({
        url:    `/budget/getExpenseByTodotgol?filterOrg=${orgTextFilter}&org_id=${org_id}`,
        method: `GET`
    })
}

function getSchedulePerformance(orgId, year) {
    return request({
        url:    `/budget/getSchedulePerformance?orgId=${orgId}&year=${year}`,
        method: `GET`
    })
}

function getOrlogoLastService(type,org_id) {
    return request({
        url:    `/budget/getOrlogoLastService?type=${type}&org_id=${org_id}`,
        method: `GET`
    })
}

function getBudgetOfferOneData(year, chosenOrganization,status) {
    return request({
        url:    `/budget/getBudgetOfferOneData/${year}?filterOrg=${chosenOrganization}&status=${status}`,
        method: `GET`
    })
}

function getBudgetOfferOneAndHalfData(year, chosenOrganization,status) {
    return request({
        url:    `/budget/getBudgetOfferOneAndHalfData/${year}?filterOrg=${chosenOrganization}&status=${status}`,
        method: `GET`
    })
}

function getBudgetOfferSecondData(year, chosenOrganization) {
    return request({
        url:    `/budget/getBudgetOfferSecondData/${year}?filterOrg=${chosenOrganization}`,
        method: `GET`
    })
}

function getBudgetOfferAgendaAndEvent(year, org) {
    return request({
        url:    `/budget/getBudgetOfferAgendaAndEvent/${year}/${org}`,
        method: `GET`
    })
}

function getBudgetOfferPensionOrVacancyChart(year, org, status) {
    return request({
        url:    `/budget/getBudgetOfferPensionOrVacancyChart/${year}/${org}?status=${status}`,
        method: `GET`
    })
}

function getBudgetVacancyDetail(year, org, status) {
    return request({
        url:    `/budget/getBudgetVacancyDetail/${year}/${org}?status=${status}`,
        method: `GET`
    })
}

function getBudgetOfferExpenseGroup(year, org) {
    return request({
        url:    `/budget/getBudgetOfferExpenseGroup/${year}/${org}`,
        method: `GET`
    })
}

/*function getBudgetOfferPensionOrVacancyList(year, org, type) {
    return request({
        url:    `/budget/getBudgetOfferPensionOrVacancyList/${year}/${org}/${type}`,
        method: `GET`
    })
}*/

function getBudgetOfferThirdData(year, chosenOrganization) {
    return request({
        url:    `/budget/getBudgetOfferThirdData/${year}?filterOrg=${chosenOrganization}`,
        method: `GET`
    })
}

function getBudgetOfferFourthData(year, chosenOrganization) {
    return request({
        url:    `/budget/getBudgetOfferFourthData/${year}?filterOrg=${chosenOrganization}`,
        method: `GET`
    })
}

function getEhUusverTenYears(filterOrg, ministry) {
    return request({
        url:    `/budget/getEhUusverTenYears?filterOrg=${filterOrg}&ministry=${ministry}`,
        method: `GET`
    })
}

function getEhUusverEdiinZasgiinAngilal(year, filterOrg, ministry) {
    return request({
        url:    `/budget/getEhUusverEdiinZasgiinAngilal/${year}?filterOrg=${filterOrg}&ministry=${ministry}`,
        method: `GET`
    })
}

function getOrlogoMiniData(year, type, org, ministry,org_id) {
    return request({
        url:    `/budget/getOrlogoMiniData/${year}?type=${type}&org=${org}&ministry=${ministry}&org_id=${org_id}`,
        method: `GET`
    })
}
function getTowloruulehMiniData(year, orgText, ministry,org_id) {
    return request({
        url:    `/budget/getTowloruulehMiniData/${year}?filterOrg=${orgText}&ministry=${ministry}&org_id=${org_id}`,
        method: `GET`
    })
}
function getTowloruulehMonthlyData(year, orgText, ministry,org_id) {
    return request({
        url:    `/budget/getTowloruulehMonthlyData/${year}?filterOrg=${orgText}&ministry=${ministry}&org_id=${org_id}`,
        method: `GET`
    })
}
function getEhUusverMonthlyData(year, filterOrg, ministry) {
    return request({
        url:    `/budget/getEhUusverMonthlyData/${year}?filterOrg=${filterOrg}&ministry=${ministry}`,
        method: `GET`
    })
}
function getTowloruulehDataGrid(year, orgText, ministry,org_id) {
    return request({
        url:    `/budget/getTowloruulehDataGrid/${year}?filterOrg=${orgText}&ministry=${ministry}&org_id=${org_id}`,
        method: `GET`
    })
}
function getEhUusverDataGrid(year, orgText, ministry) {
    return request({
        url:    `/budget/getEhUusverDataGrid/${year}?filterOrg=${orgText}&ministry=${ministry}`,
        method: `GET`
    })
}
function getTowloruulehEdiinZasgiinAngilal(year, orgText, ministry,org_id) {
    return request({
        url:    `/budget/getTowloruulehEdiinZasgiinAngilal/${year}?filterOrg=${orgText}&ministry=${ministry}&org_id=${org_id}`,
        method: `GET`
    })
}

function getExpensePivotData(year, filterOrg, ministry,econType,org_id) {
    return request({
        url:    `/budget/getExpensePivotData/${year}?filterOrg=${filterOrg}&ministry=${ministry}&econType=${econType}&org_id=${org_id}`,
        method: `GET`
    })
}
function getBudgetAgendaByYear(year, orgId) {
    return request({
        url: `/budget/getBudgetAgendaByYear/${year}/${orgId}`,
        method: `GET`
    })
}
function getEzaDescription(number) {
    return request({
        url:    `/budget/getEzaDescription?categoryNumber=${number}`,
        method: `GET`
    })
}

function getAgendaPivotData(year, filterOrg) {
    return request({
        url:    `/budget/getAgendaPivotData/${year}?filterOrg=${filterOrg}`,
        method: `GET`
    })
}
function getAgendaPerformanceByExpense(year, filterOrg, org_id) {
    return request({
        url:    `/budget/getAgendaPerformanceByExpense/${year}?filterOrg=${filterOrg}&org_id=${org_id}`,
        method: `GET`
    })
}
function getAgendaPerformanceByIncome(year, filterOrg, org_id) {
    return request({
        url:    `/budget/getAgendaPerformanceByIncome/${year}?filterOrg=${filterOrg}&org_id=${org_id}`,
        method: `GET`
    })
}

function getPivotDetailedData(tier, searchText,econType,org_id) {
    return request({
        url:    `/budget/getPivotDetailedData?tier=${tier}&searchText=${searchText}&econType=${econType}&org_id=${org_id}`,
        method: `GET`
    })
}

function getPivotDetailedDataByMonth(tier, searchText, year,econType,org_id) {
    return request({
        url:    `/budget/getPivotDetailedDataByMonth?tier=${tier}&searchText=${searchText}&year=${year}&econType=${econType}&org_id=${org_id}`,
        method: `GET`
    })
}
function getTowloruulehPivotData(year, filterOrg, ministry) {
    return request({
        url:    `/budget/getTowloruulehPivotData/${year}?filterOrg=${filterOrg}&ministry=${ministry}`,
        method: `GET`
    })
}
function getOrlogoPivotData(year, filterOrg, type,org_id) {
    return request({
        url:    `/budget/getOrlogoPivotData/${year}?filterOrg=${filterOrg}&type=${type}&org_id=${org_id}`,
        method: `GET`
    })
}
function getFilteredOrganizations(year,type) {
    return request({
        url:    `/budget/getFilteredOrganizations/${year}?type=${type}`,
        method: `GET`
    })
}
function getFilteredOrganizationsBySoa(year, soa) {
    return request({
        url:    `/budget/getFilteredOrganizations/${year}/${soa}`,
        method: `GET`
    })
}
function loadBudgetOfferOrgs(year) {
    return request({
        url:    `/budget/loadBudgetOfferOrgs?year=${year}`,
        method: `GET`
    })
}
function loadDetailedPivotData(year, filterOrg) {
    return request({
        url:    `/budget/loadDetailedPivotData/${year}?filterOrg=${filterOrg}`,
        method: `GET`
    })
}

function getBbbRate(year, org) {
    return request({
        url: `/budget/getBbbRate/${year}?org=${org}`,
        method: 'GET'
    })
}
function getBbbRateDoughnut() {
    return request({
        url: `/budget/getBbbRateDoughnut`,
        method: 'GET'
    })
}
function getBbbUnion(year, org) {
    return request({
        url: `/budget/getBbbUnion/${year}?org=${org}`,
        method: 'GET'
    })
}
function getBbbUnionDoughnut() {
    return request({
        url: `/budget/getBbbUnionDoughnut`,
        method: 'GET'
    })
}
function getBbbUnited(year, org) {
    return request({
        url: `/budget/getBbbUnited/${year}?org=${org}`,
        method: 'GET'
    })
}
function getBbbOrgs() {
    return request({
        url: `/budget/getBbbOrgs`,
        method: 'GET'
    })
}

function getBudgetOtherOne(year, org) {
    return request({
        url: `/budget/getBudgetOtherOne/${year}?org=${org}`,
        method: 'GET'
    })
}

function getBudgetOtherTwo(year, org) {
    return request({
        url: `/budget/getBudgetOtherTwo/${year}?org=${org}`,
        method: 'GET'
    })
}

function getBudgetOtherThree(org) {
    return request({
        url: `/budget/getBudgetOtherThree?org=${org}`,
        method: 'GET'
    })
}

function getBudgetOtherFour(year, org) {
    return request({
        url: `/budget/getBudgetOtherFour/${year}?org=${org}`,
        method: 'GET'
    })
}

function getBudgetOtherFive(org) {
    return request({
        url: `/budget/getBudgetOtherFive?org=${org}`,
        method: 'GET'
    })
}

function getBudgetOtherSixth(year, org) {
    return request({
        url: `/budget/getBudgetOtherSixth/${year}?org=${org}`,
        method: 'GET'
    })
}
const getExecuteBudgetParentOrg = (currentYear) => {
    return request({
        url: `/budget/getExecuteBudgetParentOrg/` + currentYear,
        method: 'GET'
    })
}
const getExecuteBudgetOrgList = (currentYear,filterOrg) => {
    return request({
        url: `/budget/getExecuteBudgetOrgList/` + currentYear + '/' + filterOrg,
        method: 'GET'
    })
}
const getOrgAttributeByAccount = (selectedOrgId) => {
    return request({
        url: `/budget/getOrgAttributeByAccount/` + selectedOrgId,
        method: 'GET',
    })
}
function getMofEhuulga(budget_period, account, budget_year) {
    return request({
        url: `/budget/getMofEhuulga/` + budget_period + '/' + account + '/' + budget_year,
        method: 'GET',
    })
}
function getOrgBudgetMaxId(budgetYear,type) {
    return request({
        url: `/budget/getOrgBudgetMaxId/` + budgetYear + '/' + type,
        method: 'GET',
    })
}
const getFilteredOrgList = (year,filterOrg) => {
    return request({
        url: `/budget/getFilteredOrgList/` + year + '/' + filterOrg,
        method: 'GET'
    })
}


function getBudgetOfferBatlagdsanData(year, chosenOrganization) {
    return request({
        url:    `/budget/getBudgetOfferBatlagdsanData/${year}/${chosenOrganization}`,
        method: `GET`
    })
}
function getBudgetOfferTuswiinSanal(year, chosenOrganization,status) {
    return request({
        url:    `/budget/getBudgetOfferTuswiinSanal/${year}/${chosenOrganization}?status=${status}`,
        method: `GET`
    })
}

function getOrgBudgetYear() {
    return request({
        url: `/budget/getOrgBudgetYear`,
        method: 'GET'
    })
}

function getBudgetFrameStatementYear() {
    return request({
        url:    `/budget/getBudgetFrameStatementYear`,
        method: `GET`
    })
}

function getBudgetFrameStatement(year){
    return request({
        url: `/budget/getBudgetFrameStatement/${year}`,
        method: 'GET'
    })
}

function getBudgetStatementEza(row_num){
    return request({
        url: `/budget/getBudgetStatementEza/${row_num}`,
        method: 'GET'
    })
}

function getBudgetSurplusOrDeficit(){
    return request({
        url: `/budget/getTotalBudgetSurplusOrDeficit`,
        method: 'GET'
    })
}

function getBudgetTezEza(year, tabIndex, type, arr){
    return request({
        url: `/budget/getBudgetTezEza/${year}/${tabIndex}/${type}?arr=${arr}`,
        method: 'POST'
    })
}
function getBudgetTezFilter(year, arr){
    return request({
        url: `/budget/getBudgetTezFilter/${year}?arr=${arr}`,
        method: 'POST'
    })
}
function getBudgetTezAgendas(year){
    return request({
        url: `/budget/getBudgetTezAgendas/${year}`,
        method: 'GET'
    })
}
function getBudgetTezEvents(year){
    return request({
        url: `/budget/getBudgetTezEvents/${year}`,
        method: 'GET'
    })
}

function getInvestYearList(budgetYear) {
    return request({
        url: `/budget/getInvestYearList/?budgetYear=${budgetYear}`,
        method: 'GET'
    })
}

function getInvestTypeCount(type, budgetYear, investYear) {
    return request({
        url: `/budget/getInvestTypeCount/?type=${type}&budgetYear=${budgetYear}&investYear=${investYear}`,
        method: 'GET'
    })
}

function getInvestData(budgetYear, investYear) {
    return request({
        url: `/budget/getInvestData/?budgetYear=${budgetYear}&investYear=${investYear}`,
        method: 'GET'
    })
}

function getInvestExecutionByYear() {
    return request({
        url: `/budget/getInvestExecutionByYear`,
        method: 'GET'
    })
}

function getInvestExecutionByMonth(budgetYear) {
    return request({
        url: `/budget/getInvestExecutionByMonth/?budgetYear=${budgetYear}`,
        method: 'GET'
    })
}

function getInvestExecutionByType(budgetYear) {
    return request({
        url: `/budget/getInvestExecutionByType/?budgetYear=${budgetYear}`,
        method: 'GET'
    })
}

function getHurungiinZardalDate(budgetYear) {
    return request({
        url: `/budget/getHurungiinZardalDate/?budgetYear=${budgetYear}`,
        method: 'GET'
    })
}

function pmisFundingList(id) {
    return request({
        url: `/budget/pmisFundingList/${id}`,
        method: 'POST'
    })
}

function pmisFundingLog(id) {
    return request({
        url: `/budget/pmisFundingLog/${id}`,
        method: 'GET'
    })
}

function pimisInvestFundingList(currentPage, searchValue) {
    return request({
        url: `/budget/pimisInvestFundingList/?pageIndex=${currentPage}&searchVal=${searchValue}`,
        method: 'POST'
    })
}

function pimisFunding(id) {
    return request({
        url: `/budget/pimisFunding/${id}`,
        method: 'GET'
    })
}

function pimisFundingListDetail(contractCode) {
    return request({
        url: `/budget/pimisFundingListDetail/?ccode=${contractCode}`,
        method: 'POST',
    })
}

const BudgetService = {
    budgetDetail,
    incomeChart,
    budgetDetailView,
    budgetNewDetails,
    expenseChart,
    econData,
    econMainData,
    econMainDataUpdated,
    econMainDataFirst,
    econMainDataSecond,
    econ2DataUpdated,
    econ2Data,
    econ2DataGuitsetgelUpdated,
    econGraph,
    budgetStatus,
    econPerformance,
    econGraphData,
    econHigh,
    econEA,
    econOtherInfo,
    econOtherInfoByYear,
    budgetOrganization,
    getBudgetFrameStatement,
    getBudgetStatementEza,
    getBudgetSurplusOrDeficit,
    budgetOrganizationYear,
    budgetEconCategory,
    budgetStat,
    setExcelFileInput,
    insertData,
    insertMonthlyAction,
    getMonthlyHuwaari,
    changeHuwaariStatus,
    getEAByOrg,
    getBudgetStatByOrgEcon2,
    getBudgetStatByOrgEcon1,
    getBudgetOrgEventAgenda,
    getBudgetOrgList,
    getBudgetSchedule,
    getBudgetScheduleInfo,
    getBudgetByAgenda,
    getBudgetOrganization,
    econGraphOnlyOrlogo,
    getExecutionChart,
    getExecutionChartByOrgList,
    getExecutionList,
    getExecutionOrgList,
    getTenYearsDataChart,
    getIncomeTenYears,
    getDoughnutCharData,
    getYamsData,
    getIncomeAmount,
    getIncomePieChartData,
    getIncomeBarChartData,
    getIncomeMiddleSectionsData,
    getIncomeBubbleChartData,
    getIncomeByAgenda,
    getExpenseMiniData,
    getExpenseHorizonData,
    getExpenseSectionBarChart,
    getExpenseMiddleSectionsData,
    getExpenseBottomData,
    getExpenseBubbleChartData,
    getSourceHeaderData,
    getSourceHorizonData,
    getSourceSectionBarChart,
    getSourceMiddleSectionsData,
    getSourceBubbleChartData,
    getSourceBottomData,
    getBudgetLawByYear,
    fourthChartData,
    getExpenseZadgaiData,
    getAgendaEventDetailCounts,
    getAgendaByOrgs,
    getAgendaCount,
    getBudgetDetailByAgenda,
    getBudgetDetailByEvent,
    getOrlogoMiniData,
    getOrlogoZadgaiData,
    getOrlogoZadgaiDataGrid,
    getExpenseZadgaiChilds,
    getOrlogoZadgaiChilds,
    getExpensePivotData,
    getEzaDescription,
    getAgendaPivotData,
    getAgendaPerformanceByExpense,
    getAgendaPerformanceByIncome,
    getExpenseLastService,
    getExpenseByTodotgol,
    getSchedulePerformance,
    getTowloruulehMiniData,
    getTowloruulehDataGrid,
    getEhUusverDataGrid,
    getTowloruulehMonthlyData,
    getOrlogoPivotData,
    getOrlogoLastService,
    getTowloruulehEdiinZasgiinAngilal,
    getFilteredOrganizations,
    getEhUusverTenYears,
    getEhUusverEdiinZasgiinAngilal,
    getEhUusverMonthlyData,
    getBudgetOfferOneData,
    getBudgetOfferOneAndHalfData,
    getBudgetOfferAgendaAndEvent,
    getBudgetOfferSecondData,
    getBudgetOfferPensionOrVacancyChart,
    getBudgetVacancyDetail,
    getBudgetOfferExpenseGroup,
    getBudgetOfferThirdData,
    getBudgetOfferFourthData,
    loadBudgetOfferOrgs,
    loadDetailedPivotData,
    getBbbRate,
    getBbbRateDoughnut,
    getBbbUnion,
    getBbbUnionDoughnut,
    getBbbUnited,
    getBbbOrgs,
    getFilteredOrganizationsBySoa,
    getBudgetOtherOne,
    getBudgetOtherTwo,
    getBudgetOtherThree,
    getBudgetOtherFour,
    getBudgetOtherFive,
    getBudgetOtherSixth,
    getTowloruulehPivotData,
    getExpenseZadgaiMonth,
    getPivotDetailedData,
    getPivotDetailedDataByMonth,
    getExecuteBudgetParentOrg,
    getExecuteBudgetOrgList,
    getOrgAttributeByAccount,
    getMofEhuulga,
    getOrgBudgetMaxId,
    getFilteredOrgList,
    getBudgetOfferBatlagdsanData,
    getBudgetOfferTuswiinSanal,
    getOrgBudgetYear,
    getBudgetFrameStatementYear,
    getBudgetTezEza,
    getBudgetTezFilter,
    getBudgetTezAgendas,
    getBudgetTezEvents,
    getInvestYearList,
    getInvestTypeCount,
    getInvestData,
    getInvestExecutionByYear,
    getInvestExecutionByMonth,
    getInvestExecutionByType,
    getHurungiinZardalDate,
    pmisFundingList,
    pmisFundingLog,
    getBudgetAgendaByYear,
    pimisInvestFundingList,
    pimisFunding,
    pimisFundingListDetail,
}

export default BudgetService;


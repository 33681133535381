import React from "react";
import styled from "styled-components";
import Counter from "./UserLogCounter";
import RemainingService from "../../../services/api/remainingService";
import { useEffect, useState } from "react";
// import LoadPanelComponent from "../../../budget/components/loadPanel";


const CardContainer = styled.div`
  .container {
    font-family: "Segoe UI";
    width:100%;
    margin:0;
    height:45vh;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
  }
  .sec-container {
    width:100%;
    height:100%;
    display: flex;
    gap: 10px;
  }
  .sec-container-left {
    width:100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .sec-container-right {
    width:100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .content-0 {
    width: 100%;
    display: flex;
    height: 40%;
    border-radius: 20px;
    border: 1.5px solid rgba(246,246,249,255);
  }
  .content-1 {
    width: 50%;
    height: 100%;
    padding: 20px 0 0 20px;
    border: 1px solid rgba(246,246,249,255);
    border-left:0;
    border-top:0;
    border-bottom:0
  }
  .content-2 {
    width: 50%;
    height: 100%;
    padding: 20px 0 0 20px;
    border: 1px solid rgba(246,246,249,255);
    border-right:0;
    border-top:0;
    border-bottom:0;
  }
  .content {
    height: 60%;
    padding: 10px 0 10px 20px;
    border-radius: 20px;
    border: 1.5px solid rgba(246,246,249,255);
  }
  span p{
    font-weight: normal;
    color:#595959;
  }
  h2{
    font-weight: bold;
    color:#0d0826;
  }
  p{
    font-weight: bold;
    color:#0d0826;
  }
`;

const UserLogCardComponent = (props) => {
  const [userData, setUserData] = useState([]);
  const [orgData, setOrgData] = useState([]);
  const [usersDotood, setUsersDotood] = useState([]);
  const [usersGadaad, setUsersGadaad] = useState([]);
  const [orgNameWithMaxCount, setOrgNameWithMaxCount] = useState("");
  const [orgNameWithMinCount, setOrgNameWithMinCount] = useState("");
  const [usernameWithMaxCount, setUsernameWithMaxCount] = useState("");
  const [usernameWithMinCount, setUsernameWithMinCount] = useState("");
  const [firstnameWithMin, setFirstnameWithMin] = useState('');
  const [firstnameWithMax, setFirstnameWithMax] = useState('');
  const [lastnameWithMax, setLastnameWithMax] = useState('');
  const [lastnameWithMin, setLastnameWithMin] = useState('');
  const [positionNameWithMax, setPositionNameWithMax] = useState('');
  const [positionNameWithMin, setPositionNameWithMin] = useState('');
  const [loader, setLoader] = useState(true);

  const getAllLogByDotood = async () => {
    try {
      const getUsersDotood = await RemainingService.getLoginLogByDotood(
        props.year,
        props.yam
      );
      let totalCountDotood = 
      getUsersDotood &&
      getUsersDotood.data.reduce(
        (sum, current) => sum + parseInt(current.count, 10),
        0
      );
    setUsersDotood(totalCountDotood);
    } catch (e) {
      console.log(e, " -> logs");
    }
  }

  const getAllLogByGadaad = async () => {
    try {
      const getUsersGadaad = await RemainingService.getLoginLogByGadaad(
        props.year,
        props.yam
      );
      let totalCountGadaad = 
      getUsersGadaad &&
      getUsersGadaad.data.reduce(
        (sum, current) => sum + parseInt(current.count, 10),
        0
      );
    setUsersGadaad(totalCountGadaad);
    } catch (e) {
      console.log(e, " -> logs");
    }
  }
  

  const getAllLogUsers = async () => {
    try {
      const getUsers = await RemainingService.getLoginAllLogUsers(
        props.year,
        props.yam
      );
      console.log(':::',getUsers)
      setUserData(getUsers.data);
      const maxCountObject = getUsers.data.reduce((max, current) =>
        parseInt(current.count, 10) > parseInt(max.count, 10) ? current : max
      );
      setUsernameWithMaxCount(maxCountObject.username);
      setFirstnameWithMax(maxCountObject.first_name);
      setLastnameWithMax(maxCountObject.last_name);
      setPositionNameWithMax(maxCountObject.position_name)
      const minCountObject = getUsers.data.reduce((min, current) =>
        parseInt(current.count, 10) < parseInt(min.count, 10) ? current : min
      );
      setUsernameWithMinCount(minCountObject.username);
      setFirstnameWithMin(minCountObject.first_name);
      setLastnameWithMin(minCountObject.last_name);
      setPositionNameWithMin(minCountObject.position_name)
    } catch (e) {
      console.log(e, " -> logs");
    }
  };


  const getAllLogOrgs = async () => {
    try {
      const getOrgs = await RemainingService.getLoginAllLogOrgs(
        props.year,
        props.yam
      );
      setOrgData(getOrgs.data);
      const maxCountObjectOrg = getOrgs.data.reduce((max, current) =>
        parseInt(current.count, 10) > parseInt(max.count, 10) ? current : max
      );
      setOrgNameWithMaxCount(maxCountObjectOrg.org_name);
      const minCountObjectOrg = getOrgs.data.reduce((min, current) =>
        parseInt(current.count, 10) < parseInt(min.count, 10) ? current : min
      );
      setOrgNameWithMinCount(minCountObjectOrg.org_name);
    } catch (e) {
      console.log(e, " -> logs");
    }
  };

  useEffect(() => {
    getAllLogUsers();
    getAllLogOrgs();
    getAllLogByDotood();
    getAllLogByGadaad();
  }, [props.year, props.yam]);

  return (
    <CardContainer>
      <div class="container">
        <div class="content-0">
            <div class="content-1">
            <span><p>Нийт хандсан хэрэглэгчдийн тоо:</p></span>
            <h2><Counter number={props.totalCount} /></h2>
            </div>
            <div class='content-2'>
            <span><p>Дотоодоос хандсан хэрэглэгчдийн тоо:</p></span>
            <h2><Counter number={usersDotood} /></h2>
            <span><p>Гаднаас хандсан хэрэглэгчдийн тоо:</p></span>
            <h2><Counter number={usersGadaad} /></h2>
            </div>
        </div>
        <div class="sec-container">
          <div class="sec-container-left">
              <div class="content">
                <span><p>Хамгийн их хандсан байгууллага:</p></span>
                <p>{orgNameWithMaxCount}</p>
              </div>
              <div class="content">
                <span><p>Хамгийн их хандсан хэрэглэгч:</p></span>
                <span><p>Овог нэр: </p></span><p>{lastnameWithMax} {firstnameWithMax}</p>
                <span><p>Нэвтрэх нэр: </p></span><p>{usernameWithMaxCount}</p>
                <span><p>Албан тушаал: </p></span><p>{positionNameWithMax}</p>
              </div>
          </div>
          <div class="sec-container-right">
              <div class="content">
                <span><p>Хамгийн бага хандсан байгууллага:</p></span>
                <p>{orgNameWithMinCount}</p>
              </div>
              <div class="content">
              <span><p>Хамгийн бага хандсан хэрэглэгч:</p></span>
              <span><p>Овог нэр: </p></span><p>{lastnameWithMin} {firstnameWithMin}</p>
                <span><p>Нэвтрэх нэр: </p></span><p>{usernameWithMinCount}</p>
                <span><p>Албан тушаал: </p></span><p>{positionNameWithMin}</p>
              </div>
          </div>
        </div>
      </div>
    </CardContainer>
  );
};

export default UserLogCardComponent;

import React, { useState, useEffect, useRef } from "react";
import notify from "devextreme/ui/notify";
import BudgetService from "../../../../services/api/budget";
import PivotGridDataSource from "devextreme/ui/pivot_grid/data_source";
import { PivotGrid, Toolbar } from "devextreme-react";
import { Export } from "devextreme-react/pivot-grid";
import { formatDate, numberWithCommas } from "../../../../util";
import '../../static/budget.css'
import LoadPanelComponent from "../../../budget/components/loadPanel";
import PopupDetailEconCatByYear from "./PopupDetailEconCatByYear";
import { Popup } from "devextreme-react/popup";
import PopupDetailEconCatByMonth from "./PopupDetailEconCatByMonth";
import { budgetPalettes } from "../../../../util";
import _ from "lodash";
import { Workbook } from 'exceljs';
import { saveAs } from "file-saver-es";
import { exportPivotGrid } from "devextreme/excel_exporter";
import EmptyData from "../../../budget/components/empty";

const ExpenseOnlyPivotGrid = (props) => {
    const [pivotSource, setPivotSource] = useState([]);
    const [neverChangingData, setNeverChangingData] = useState([]);
    const [loader, setLoader] = useState(false)
    //const [data, setData] = useState()
    let _pivotRef = useRef(null);
    const [tier, setTier] = useState(1);
    const [searchText, setSearchText] = useState('Нийт зарлага ба цэвэр зээлийн дүн');
    const [popper, setPopper] = useState(false);
    const [titleHeaders, setTitleHeaders] = useState([]);
    const windowSize = useRef([window.innerWidth, window.innerHeight]);
    const loadPivotData = async () => {
        try {
            setLoader(true);
            let r = await BudgetService.getExpensePivotData(props.year, props.chosenValue, props.chosenMinistry, props.econType,props.orgId);
            //setData(r)
            setNeverChangingData(r);
            const dataSource = new PivotGridDataSource({
                fields: [{
                    caption: 'econ_1',
                    dataField: 'econ_category_name_1',
                    area: 'row',
                    width: windowSize.current[0] < 920 ? 'auto' : 500,
                    expanded: true,
                    sortBy: 'displayText',
                    sortOrder: 'asc'
                }, {
                    caption: 'econ_2',
                    dataField: 'econ_category_name_2',
                    width: windowSize.current[0] < 920 ? 'auto' : 500,
                    area: 'row',
                    expanded: true,
                    sortBy: 'displayText',
                    sortOrder: 'asc'
                }, {
                    caption: 'econ_3',
                    dataField: 'econ_category_name_3',
                    width: windowSize.current[0] < 920 ? 'auto' : 500,
                    area: 'row',
                    sortBy: 'displayText',
                    sortOrder: 'asc'
                }, {
                    caption: 'econ_4',
                    dataField: 'econ_category_name_4',
                    width: windowSize.current[0] < 920 ? 'auto' : 500,
                    area: 'row',
                    sortBy: 'displayText',
                    sortOrder: 'asc'
                }, {
                    caption: 'econ_6',
                    dataField: 'econ_category_name',
                    width: windowSize.current[0] < 920 ? 'auto' : 500,
                    area: 'row',
                    sortBy: 'displayText',
                    sortOrder: 'asc'
                },
                {
                    caption: 'Төсөв',
                    dataField: 'amount',
                    dataType: 'number',
                    summaryType: 'sum',

                    customizeText: function (e) {
                        return `${numberWithCommas(e.value / 1000000)}`
                    },
                    area: 'data',
                }, {
                        caption: 'Ангилалд эзлэх хувь',
                        dataField: 'amount',
                        dataType: 'number',
                        width: 30,
                        summaryType: 'sum',
                        customizeText: function (e) {
                            return `${(e.value*100).toFixed(2)}`
                        },
                        summaryDisplayMode: 'percentOfColumnTotal',
                        area: 'data',
                    },
                    {
                    caption: 'Нийт төсөвд эзлэх хувь',
                    dataField: 'amount',
                    dataType: 'number',
                    width: 30,
                    summaryType: 'sum',
                    customizeText: function (e) {
                        return `${(e.value*100).toFixed(2)}`
                    },
                    summaryDisplayMode: 'percentOfColumnGrandTotal',
                    area: 'data',
                },

                    {
                    caption: 'Гүйцэтгэл',
                    dataField: 'guitsetgel',
                    dataType: 'number',
                    summaryType: 'sum',
                    customizeText: function (e) {
                        return `${numberWithCommas(e.value / 1000000)}`
                    },
                    area: 'data'
                },
                {
                    caption: 'Хувь',
                    dataField: 'guitsetgel',
                    dataType: 'number',
                    summaryType: 'sum',
                    customizeText: function (e) {
                        return `${numberWithCommas(e.value / 1000000)}`
                    },
                    area: 'data'
                }
                ],
                store: r
            });
            setPivotSource(dataSource);
            setLoader(false);
        } catch (e) {
            setLoader(false);
            notify(e.message, "error", 2000);
        }
    }
    

    useEffect(() => {
      loadPivotData();
    }, [props.year, props.chosenValue, props.chosenMinistry, props.econType,props.orgId])

    const cellClickHandler = (event) => {
        if (event.cell.columnType === "GT") {
            setPopper(true);
            setTier(event.cell.rowPath.length);
            setSearchText(event.cell.rowPath[event.cell.rowPath.length - 1]);
            setTitleHeaders(event.cell.rowPath);
        }
    }

    const renderTitle = () => {
        return <div style={{ paddingTop: '10px', display: 'flex', gap: '10px', flexDirection: 'row' }}>
            <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                {titleHeaders && titleHeaders.length !== 0 ? titleHeaders.map((item, index) => (
                    <span style={{
                        marginLeft: '10px',
                        border: index === titleHeaders.length - 1 ? '1px solid green' : 'none'
                    }} key={index}>{index + 1 + '. ' + item}</span>
                )) : <span></span>}
                &nbsp;
            </div>
            <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                <span>Он: {props.year}</span>
                <span>Байгууллага: {props.chosenValue && props.chosenValue === 'ALL' ? 'Бүгд' : props.chosenValue}</span>
                {props.calculatedPercentage && <span>Нийт төсвийн: <span
                    style={{ fontWeight: 'bold' }}>{props.calculatedPercentage.toFixed(2)}%</span></span>}
                {props.calculatedGuitestgelPercentage && <span>Нийт гүйцэтгэлийн: <span
                    style={{ fontWeight: 'bold' }}>{props.calculatedGuitestgelPercentage.toFixed(2)}%</span></span>}
            </div>
        </div>
    }
    const onCellPrepared = (e) => {
        if (e.cell.text !== undefined && e.cell.text.substr(6, 1) === '-' || e.cell.text === "") {
            e.cell.expanded = true;
            let cell = e.cellElement.querySelector(".dx-expand-icon-container");
            if (cell) cell.innerHTML = "";
        }
        if (e.area === 'row') {
            e.cellElement.style.fontFamily = 'Segoe UI'
            e.cellElement.style.fontWeight = 'semibold'
            e.cellElement.style.color = "#15186A"
            e.cellElement.style.backgroundColor = "#EEF0F4"
        }
        if (e.area === 'data') {
            e.cellElement.style.fontFamily = 'Segoe UI'
            e.cellElement.style.fontWeight = 'semibold'
            e.cellElement.style.color = "#000000"
            let { rowPath } = e.cell;
            let col = _.filter(neverChangingData, function (o) {
                return o.econ_category_name_1 === rowPath[0] && (rowPath[1] ? o.econ_category_name_2 === rowPath[1] : true) && (rowPath[2] ? o.econ_category_name_3 === rowPath[2] : true) && (rowPath[3] ? o.econ_category_name_4 === rowPath[3] : true) && (rowPath[4] ? o.econ_category_name === rowPath[4] : true);
            });
            let prev_debit = _.reduce(col, function (sum, n) {
                return sum + n.amount * 1;
            }, 0)
            let percent = 0;

            if (e.cell.value) {
                percent = prev_debit !== 0 ? (e.cell.value - prev_debit) * 100 / prev_debit : 0;
            }

            let v = rowPath.join('NV');
            let vr = v.replaceAll(' ', 'SP');
            vr = vr.replaceAll('-', 'MN');
            vr = vr.replaceAll(',', 'CM');
            vr = vr.replaceAll('/', 'SL');
            vr = vr.replaceAll('(', 'BS');
            vr = vr.replaceAll(')', 'BE');

            e.cellElement.onmouseenter = function (e) {
                e.preventDefault();
            }

            e.cellElement.onmouseleave = function (e) {
                e.preventDefault();
            }
            if (e.cell.dataIndex === 4) {
                if (percent > 0) {
                    e.cellElement.innerHTML = `<div id="${vr}YR${e.cell.dataIndex}" style="flex-direction: row;display: flex;justify-content: center">
                     <div style="margin-left: 10px;font-size: 14px;right: 10px;color: #f00;">${numberWithCommas(percent)}</div>
                 
                 </div>`;
                } else if (percent) {
                    e.cellElement.innerHTML = `<div id="${vr}YR${e.cell.dataIndex}" style="flex-direction: row;display: flex;justify-content: center">
                        <div style="margin-left: 10px;font-size: 14px;right: 10px;">${numberWithCommas(100 + percent)}</div>
                  
                  </div>`;
                } else {
                    e.cellElement.innerHTML = `<div class="customCell" id="${vr}YR${e.cell.dataIndex}">
  <div style="margin-left: 10px;font-size: 14px;right: 10px;">${numberWithCommas(0)}</div>
                  </div>`;
                }
            }
        }
    }

    const onExportingPivot = (e) => {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Төсвийн тайлан-Эдийн засгийн ангиллаар');

        worksheet.columns = [
            { width: 30 }, { width: 20 }, { width: 30 }, { width: 30 }, { width: 30 }, { width: 30 },
        ];

        exportPivotGrid({
            component: e.component,
            worksheet,
            topLeftCell: { row: 4, column: 1 },
            mergeRowFieldValues: true,
            keepColumnWidths: true,
            customizeCell: ({ pivotCell, excelCell }) => {
                const borderStyle = { style: 'thin', color: { argb: 'FF7E7E7E' } };
                excelCell.border = {
                    bottom: borderStyle,
                    left: borderStyle,
                    right: borderStyle,
                    top: borderStyle,
                };
            },
        }).then((cellRange) => {
            // Header
            const headerRow = worksheet.getRow(2);
            headerRow.height = 30;

            const columnFromIndex = worksheet.views[0].xSplit;
            const columnToIndex = columnFromIndex;
            worksheet.mergeCells(2, columnFromIndex, 2, columnToIndex);

            const headerCell = headerRow.getCell(columnFromIndex);
            headerCell.value = 'Төсвийн тайлан /Эдийн засгийн ангиллаар/';
            headerCell.font = { name: 'Calibri', size: 16, bold: true };
            headerCell.alignment = { horizontal: 'left', vertical: 'middle', wrapText: true };
        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'OG-Эдийн засгийн ангиллаар-' + formatDate(new Date()) + ".xlsx");
            });
        });
        e.cancel = true;
    };
    return (
        <div id="load9" className="expenseOnlyPivotGrid">
            {
                popper && <Popup
                visible={popper}
                hideOnOutsideClick={true}
                onHiding={() => {
                    setPopper(false)
                }}
                titleRender={renderTitle}
            >
                <div id="spinner" style={{ display: 'flex', flexDirection: 'row' }}>
                    <PopupDetailEconCatByYear tier={tier} searchText={searchText}
                        colorPalette={_.find(budgetPalettes, { 'id': props.econType * 1 === 2 ? 1 : 3 }).color}
                        econType={props.econType} org_id = {props.orgId}/>
                    <PopupDetailEconCatByMonth tier={tier} searchText={searchText} year={props.year}
                        colorPalette={_.find(budgetPalettes, { 'id': props.econType * 1 === 2 ? 1 : 3 }).color}
                        econType={props.econType} org_id = {props.orgId}/>
                </div>
            </Popup> }
            <LoadPanelComponent position={{ of: '#load9' }} visible={loader} />
            {(neverChangingData && neverChangingData.length > 0 && !loader) ? (

                <PivotGrid
                    ref={_pivotRef}
                    style={{ fontFamily: "Segoe UI" }}
                    dataSource={pivotSource}
                    allowSorting={true}
                    rowHeaderLayout={'tree'}
                    wordWrapEnabled={true}
                    showColumnTotals={false}
                    showRowGrandTotals={false}
                    showRowTotals={false}
                    texts={{
                        noData: 'Дата байхгүй байна.',
                        collapseAll: 'Бүгд хураах',
                        expandAll: 'Бүгд дэлгэх',
                        showFieldChooser: 'Талбар сонголт харах',
                        exportToExcel: 'Excel файл татах',
                        grandTotal: 'Төсөв, гүйцэтгэл',
                        total: '{0}'
                    }}
                    onCellPrepared={onCellPrepared}
                    onCellClick={cellClickHandler}
                    onExporting={onExportingPivot}
                    fieldChooser={false}
                >
                    <Export enabled={true} />
                </PivotGrid>
            ) : (!loader ?
                <div style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    family: "Segoe UI"
                }}>
                    <EmptyData/>
                </div> : null)
            }
        </div>
    )
}

export default ExpenseOnlyPivotGrid;

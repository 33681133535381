import request from '../../shared/lib/request'

const getAll = () => {
    return request({
        url: '/email/',
        method: 'GET'
    })
}

const getByIdEmail = (id) => {
    return request({
        url: `/email/${id}`,
        method: 'GET'
    })
}

const addEmail = (body) => {
    return request({
        url: '/email/',
        method: 'POST',
        data: body
    })
}

const sendEmail = (id) => {
    return request({
        url: `/email/send/${id}`,
        method: 'PATCH'
    })
}

const EmailService = {
    getAll,
    getByIdEmail,
    addEmail,
    sendEmail
}

export default EmailService;

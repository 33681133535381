import React from 'react'

function SourceFooter() {
  return (
    <div className="card" style={{padding: ".5rem 1rem"}}>
                <div className='row' style={{display: "flex", alignItems: "center", paddingBottom: ".5rem", justifyContent: "center"}}>
                                <span style={{color: "#2E4765", fontWeight: "bold"}}>Эх сурвалж:</span>
                                 {/*<span style={{marginLeft: ".25rem", fontWeight: 500}}>1. Төсвийн төлөвлөлтийн программ <a href="https://fiscal.mof.gov.mn/" target="blank">Fiscal.B</a></span>*/}
                                 <span style={{marginLeft: "1rem", fontWeight: 500}}>1. ГҮЙЦЭТГЭЛ - Төрийн сангийн систем <a href="https://ehuulga.mof.gov.mn/" target="blank">И-Хуулга</a></span>
                            </div>
                </div>
  )
}

export default SourceFooter
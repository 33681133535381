import {Tabs} from "antd"
import React from "react"
import styled from "styled-components"
import NewsList from "./components/NewsList"
import "./news.scss"
import { useLocation } from "react-router-dom"

const TabsContainer = styled.div`
  padding: 1.5rem 1rem;
  border: 3px solid transparent;
  font-family: Segoe UI;
  position: relative;
  .ant-tabs-tab-btn {
    max-width: 15rem;
    white-space: normal;
    text-align: justify;
  }
`
const CustomTabs = styled(Tabs)`
  min-height: 80vh;
  img {
    max-width: ${(props) => (props.width > 768 ? "50vw" : "none")};
  }
  .ant-list-item:first-child{
    padding-top: 0 !important;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
    color: ${(props) => props.color} !important;
  }
  :where(.css-dev-only-do-not-override-ixblex).ant-tabs-left
    > .ant-tabs-nav
    .ant-tabs-tab {
    padding: 8px 24px 8px 0px;
  }
  :where(.css-dev-only-do-not-override-ixblex).ant-tabs
    .ant-tabs-tab.ant-tabs-tab-active
    .ant-tabs-tab-btn {
    color: ${(props) => props.color} !important;
  }
  :where(.css-dev-only-do-not-override-ixblex).ant-tabs .ant-tabs-ink-bar {
    background-color: ${(props) => props.color} !important;
  }
  :where(.css-dev-only-do-not-override-ixblex).ant-tabs
    .ant-tabs-tab:hover
    .ant-tabs-tab-btn {
    color: ${(props) => props.color + "90"} !important;
  }
`
function UpdatedNews(props) {
  const {state} = useLocation()
  return (
    <TabsContainer>
      <div
        style={{
          fontSize: "1rem",
          fontWeight: 600,
          paddingBottom: "1.5rem",
          width: "100%",
          textAlign: "left",
          textTransform: "uppercase",
          paddingLeft: "1.5rem"
        }}
      >
        Мэдээ мэдээлэл
      </div>
      <CustomTabs
        className="customTabs"
        tabPosition="left"
        defaultActiveKey={state?.type}
        animated={{tabPane: true}}
        color="#1677ff"
        items={[
          {label: "Сүүлд нэмэгдсэн", children: <NewsList type={5} />, key: 5},
          {label: "Үйл явдал", children: <NewsList type={1} />, key: 1},
          {label: "Зар", children: <NewsList type={2} /> , key: 2},
          {label: "Төсвийн тойм", children: <NewsList type={3} />, key: 3},
          {label: "Инфографик", children: <NewsList type={4} />, key: 4},
          {label: "Фото мэдээ", children: <NewsList type={7} />, key: 6},
          {label: "Видео мэдээ", children: <NewsList type={8} />, key: 7},
        
        ]}
      />
      {props.children}
      
    </TabsContainer>
  )
}

export default UpdatedNews

import React, {useEffect, useState} from "react"
import statsService from "../../../services/api/stat.service"
import notify from "devextreme/ui/notify"
import Chart, {
  CommonSeriesSettings,
  Crosshair,
  Export,
  HorizontalLine,
  Legend,
  Series,
  ValueAxis,
} from "devextreme-react/chart"
import {Label, Tooltip} from "devextreme-react/pie-chart"
import {numberWithCommas, numberWithCommas11} from "../../../util"
import _ from "lodash"

const NumberFour = (props) => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)

  const loadData = async (year) => {
    try {
      let r = await statsService.getMonthData(year, props.list_id, props.tbl_id)
      setData(r)
    } catch (e) {
      setLoading(false)
      notify(e.message)
    }finally{
      setLoading(false)
    }
  }

  useEffect(() => {
    loadData(props.year)
  }, [props.year, props.list_id, props.tbl_id])

  const renderLabel = (e) => {
    return `${e.percentText}`
  }

  const customizeTooltip = (arg) => {
    if (arg.seriesName === "Өөрчлөлт") {
      let r1 = data[arg.argument * 1 - 1 - 1]
      let q = (
        arg.value - (r1 && r1.sum) > 0
          ? arg.value / (r1 && r1.sum)
          : (r1 && r1.sum) / arg.value
      ).toFixed(2)
      let p = ((arg.value * 100) / (r1 && r1.sum) - 100).toFixed(2)
      let d = arg.value - (r1 && r1.sum)
      let f = d < 0 ? "бага" : "их"
      if (isFinite(q) && !isNaN(q)) {
        return {
          text: `${arg.argumentText}-р сар\n\nӨмнөх сараас\n\n Зөрүү: ${numberWithCommas11(
            d
          )}\n Хувь: ${p}%\n Өөрчлөлт: ${q} дахин ${f}`,
        }
      } else {
        return {
          text: `Тооцоолох боломжгүй`,
        }
      }
    } else {
      return {
        text: `${arg.seriesName}: ${numberWithCommas11(arg.value)}`,
      }
    }
  }
  
  if (!loading && data.length === 0) return null

  return (
    <div style={{marginBottom: 10, flex: 0.5, width: "50%"}}>
      <div className="card" style={{height: "100%"}}>
        <div className="card-header">
          <span style={{fontWeight: "700"}}>4. ХУГАЦАА (УЛИРАЛ, САР)</span>
        </div>
        <div className="card-body" style={{padding: "1.8rem 0"}}>
          <div>
            <Chart
              dataSource={data}
              palette={["#003696", "#2C77FF", "#AAC8FF"]}
            >
              <Export enabled={true} />
              <CommonSeriesSettings argumentField="period_month" type="bar">
                <Label
                  backgroundColor="none"
                  visible={false}
                  font={{family: "'Montserrat', sans-serif"}}
                />
              </CommonSeriesSettings>
              <Crosshair enabled={true}>
                <HorizontalLine visible={false} />
                <Label visible={true} />
              </Crosshair>
              <ValueAxis>
                <Label customizeText={(e) => e.value} />
              </ValueAxis>
              <Series valueField="sum" name="Тоо" />
              <Series
                valueField="sum"
                name="Өөрчлөлт"
                type="spline"
                color="#f05b41"
              />
              <Legend
                visible={true}
                verticalAlignment="bottom"
                horizontalAlignment="center"
                font={{family: "'Montserrat', sans-serif"}}
              />
              <Tooltip
                zIndex={100000}
                customizeTooltip={customizeTooltip}
                enabled={true}
                font={{family: "'Montserrat', sans-serif"}}
                location="edge"
              ></Tooltip>
            </Chart>
          </div>
        </div>
      </div>
    </div>
    
  )
}

export default NumberFour

import React, { useState, useEffect, useRef } from "react"
import logo from "../assets/imgs/download.png"
import useForm from "./../hooks/useForm"
import useAuth from "./../hooks/useAuth"
import styled from "styled-components"
import loginBg from "../assets/imgs/loginBg.png"
import img from "../assets/imgs/logo_new.png"
import notify from "devextreme/ui/notify"
import styles from "./Login.module.css"
import { Popup } from "devextreme-react"
import MessengerCustomerChat from "react-messenger-customer-chat"
import FileServices from "../services/api/file"
import ScrollView from "devextreme-react/scroll-view"
import { urlServer } from "../shared/lib/request"
import { useTranslation } from "../hooks/useTranslation"
import LanguageSwitch from "../components/LanguageSwitch"
import Axios from "axios";
import { Alert, Button, Carousel, Form, Input, Tooltip } from "antd";
import { LiaUser } from 'react-icons/lia';
import { BsEyeSlash, BsEye } from 'react-icons/bs';
import { GoLock } from "react-icons/go";
import UserService from "../services/api/user";
import ReCAPTCHA from "react-google-recaptcha";
import { MdMail, MdOutlineAlternateEmail, MdOutlineEmail } from "react-icons/md"
import { IoMdPaperPlane } from "react-icons/io"
import datalandLogo from '../assets/imgs/dataland.png'
import { FaPhone } from "react-icons/fa"
import { TermsCondition } from "./TermsCondition"
import { PrivacyPolicy } from "./PrivacyPolicy"
let LoginContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  min-height: 100%;
  margin: 0;
  box-sizing: border-box;
  background: #fff;

  .language-switch {
    position: absolute;
    top: 5%;
    right: 20%;
  }
`

const Logo = styled.div`
  height: 6.5rem;
  width: 100%;
  background-image: url(${logo});
  background-repeat: no-repeat;
  background-size: 6.5rem;
  background-position: center;
  margin: 0 auto;
  margin-bottom: 3rem;
`

const style = {
  img: {
    height: "100%",
    width: "100%",
    objectFit: "cover",
  },
  div: {
    position: "absolute",
    width: "99%",
    height: "99%",
    margin: 0,
    zIndex: "0",
    top: 0,
    left: 0,
  },
}
const animation = {
  show: {
    type: "slide",
    from: {
      left: 1000,
      opacity: 0.2,
    },
    to: {
      opacity: 1,
    },
    duration: 500,
  },
  hide: {
    type: "slide",
    to: {
      opacity: 0,
      right: -1000,
    },
    duration: 500,
  },
}

export default function Login() {
  const [loading, setLoading] = useState(false)
  const { isMongolian } = useTranslation()
  const [isChecked, setIsChecked] = useState(true);
  const { values, handleChange } = useForm({
    initialValues: {
      loginValue: localStorage.getItem("LoginValue") || "",
      password: ""
    },
  })
  const { loginUser, } = useAuth()
  const [galleryDS, setGalleryDS] = useState([])
  const [ipDetails, setIpDetails] = useState([]);
  const [isHovering, setIsHovering] = useState(false);
  const [checkPasswordPopup, setCheckPasswordPopup] = useState(false);
  const [email, setEmail] = useState('');
  const [showWarning, setShowWarning] = useState(false);
  const [passwordSuccess, setPasswordSuccess] = useState(false);
  const [tooltip, setTooltip] = useState(false)
  const [show, setShow] = useState(true)
  const [termsCondition, setTermsCondition] = useState(false)
  const [privacyPolicy, setPrivacyPolicy] = useState(false)
  let captchaRef = useRef(null);

  useEffect(() => {
    clientInfo();
    galleryFetch()
  }, [])


  const clientInfo = async () => {
    try {
      let array = [];
      Axios.get('https://ipapi.co/json/').then((res) => {
        array.ip = res.data.ip;
        array.city = res.data.city;
        array.country_code = res.data.country_code;
        array.country_name = res.data.country_name;
        array.region = res.data.region;
        array.longitude = res.data.longitude;
        array.latitude = res.data.latitude;
        array.isp = res.data.org
        /*array.offset = res.data.offset;
        array.isp = res.data.isp;
        array.mobile = res.data.mobile;
        array.proxy = res.data.proxy;
        array.hosting = res.data.hosting;*/

      });
      setIpDetails(array);
    } catch (e) {
      console.error(e)
    }
  }
  const galleryFetch = async () => {
    try {
      const r = await FileServices.getFileNameByType(28, undefined)
      setGalleryDS(r.data)
    } catch (e) {
      console.error(e)
    }
  }
  const handleLogin = async () => {
    setLoading(true)
    if (values.loginValue && values.password) {
      let res = await loginUser(values, isChecked, ipDetails);
      if (res !== true) {
        // TODO find a more dynamic way of implementing notification in the entire codebase
        notify(
          {
            message: res,
            width: 300,
            position: {
              my: "right top",
              at: "right top",
              of: "#floating-div",
            },
            animation: animation,
          },
          "warning",
          2500
        )
        setLoading(false)
      } else {
        notify(
          {
            message: "Амжилттай нэвтэрлээ",
            width: 300,
            position: {
              my: "right top",
              at: "right top",
              of: "#floating-div-2",
            },
            animation: animation,
          },
          "success",
          2500
        )
      }
    } else {
      notify(
        {
          message: 'Аль нэг талбар дутуу байна.',
          width: 300,
          position: {
            my: "right top",
            at: "right top",
            of: "#floating-div",
          },
          animation: animation,
        },
        "warning",
        2500
      )
      setLoading(false)
    }
  }

  /*  const galleryDS = window.location.href === 'https://mojha.opengov.mn/login' ?
      [mojha_ny,c5, c3, congratz] : [ mofa_ny, mofaBanner];*/

  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  const checkAndSentEmail = async (e) => {
    e.preventDefault();

    const EmailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    const token = captchaRef.current.getValue();
    captchaRef.current.reset();
    setShowWarning(false);

    if (!EmailRegex.test(email)) {
      notify({
        message: "Имэйлийн бүтэц буруу байна: example@email.com",
        width: 'fit-content'
      }, "warning", 3000);
    } else {
      if (token) {
        setTooltip(false)
        let res = await UserService.checkResetEmail({ email: email, captchaToken: token })
        if (res.success) {
          setPasswordSuccess(true)
        } else {
          setShowWarning(true)
        }
      } else {
        setTooltip(true)
      }
    }
  }
  const jumpToUrl = () => {
    // Replace 'https://example.com' with the desired URL
    const url = 'https://www.dataland.mn';

    // Open a new tab/window with the specified URL
    window.open(url, '_blank');
  }

  return (
    <LoginContainer className="login-container">
      <div
        className={"login"}
        style={{
          display: "flex",
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          background: "#F9FAFF",
          position: "relative",
        }}
      >
        <div style={style.div}>
          <img style={style.img} src={loginBg} alt="" />
        </div>
        <div
          className="login-main-div"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            paddingBottom: 20,
            position: "relative"
          }}
        >
          <div className="language-switch">
            <LanguageSwitch />
          </div>
          <div />
          <div>
            {show ? <Logo /> : <button onClick={jumpToUrl} style={{ border: 'none', background: 'none', cursor: 'pointer' }}><img width={200} height={110} src={datalandLogo} /></button>}
            <p
              style={{
                color: "#0F2D52",
                textAlign: "center",
                fontSize: "1.5rem",
                marginTop: 40,
                fontWeight: 700,
                fontFamily: "Segoe UI"
              }}
            >
              {show ? (isMongolian ? "Төсвийн нэгдсэн систем" : "Integrated budget system") : ''}
            </p>
            {show ?
              <Form
                onFinish={handleLogin}
                style={{
                  paddingLeft: "20%",
                  paddingRight: "20%",
                  width: "100%",
                  minWidth: 320,
                  marginTop: 30,
                  textAlign: "center",
                }}
              >
                <Input size="large" type="text" name="loginValue" value={values.loginValue} onChange={handleChange}
                  placeholder={isMongolian ? "Нэвтрэх нэр, имэйл эсвэл утасны дугаар" : "Username, email or phone number"}
                  autoComplete='username'
                  prefix={<LiaUser fontSize='1.2rem' color="#92A0B1" style={{ marginRight: '.5rem' }} />}
                  style={{ borderRadius: '.35rem', borderColor: '#e8f0fe', padding: '1rem', fontSize: '0.85rem' }}
                />
                <br />
                <br />
                <Input.Password type="password" name="password" value={values.password} onChange={handleChange}
                  prefix={<GoLock fontSize='1rem' color="#92A0B1" style={{ marginRight: '.5rem' }} />}
                  placeholder={isMongolian ? "Нууц үг" : 'Password'}
                  autoComplete='current-password'
                  style={{ borderRadius: '.35rem', borderColor: '#e8f0fe', padding: '1rem', fontSize: '0.85rem' }}
                  iconRender={(visible) => (visible ? <BsEye color="#1890ff" fontSize='1.1rem' /> : <BsEyeSlash fontSize='1.1rem' />)}
                />
                <br />
                <br />
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline' }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <input type="checkbox" id="rememberMe" name="isChecked" value={isChecked} checked={isChecked} onChange={(e) => setIsChecked(e.target.checked)} />
                    <label for="rememberMe" style={{ color: '#0F2D52', fontSize: '0.8rem', marginLeft: 5 }}>{isMongolian ? 'Намайг сана' : 'Remember me'}</label>
                  </div>
                  <div
                    style={{ color: '#0F2D52', fontSize: '0.8rem', textDecoration: isHovering ? 'underline' : '' }}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    onClick={() => setCheckPasswordPopup(true)}
                  > {isMongolian ? 'Нууц үг мартсан?' : 'Forgot password?'}
                  </div>
                </div>
                <br />
                <Button
                  style={{ height: '3rem', color: "white", borderColor: '#02419a' }}
                  className="submitButton"
                  loading={loading}
                  htmlType="submit"
                > {isMongolian ? "Нэвтрэх" : "Login"}
                </Button>
              </Form>
              :
              <div style={{ marginTop: '1rem', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <span style={{ width: '60%', color: '#9B9A9A' }}>Улаанбаатар, Сүхбаатар дүүрэг, 1-р хороо, Олимпийн гудамж-5, Аюуд тауэр, 10 давхар.</span>
                <br />
                <div style={{display: 'flex', flexDirection: 'column', textAlign: 'center'}}>
                  <span>Утас: 7507-7171</span>
                  <span>Цахим шуудан: <a href="mailto:info@dataland.mn">info@dataland.mn</a></span>
                </div>
              </div>
            }
            {checkPasswordPopup &&
              <Popup
                visible={checkPasswordPopup}
                showCloseButton={true}
                onHiding={() => { setCheckPasswordPopup(false); setPasswordSuccess(false); setShowWarning(false) }}
                onHidden={() => { setPasswordSuccess(false); setShowWarning(false) }}
                width={'35rem'}
                height={passwordSuccess || showWarning ? '25rem' : '18rem'}
                hideOnOutsideClick={true}
              >
                <div style={{ fontFamily: 'Montserrat', padding: '0 1rem' }}>
                  <p style={{ color: '#15186A', fontWeight: 'bold', borderBottom: '1px solid #ebecf0', marginBottom: '1rem' }}>Имэйл хаягаар нууц үг сэргээх</p>
                  {passwordSuccess &&
                    <div style={{ marginBottom: '1rem' }}>
                      <Alert style={{ borderRadius: '0.35rem' }}
                        description={<div style={{ fontSize: '0.8rem' }}>Таны OPENGOV төсвийн нэгдсэн системийн нууц үг <strong>амжилттай</strong> солигдлоо. <br /> Нэвтрэх нууц үгийг имэйл хаяг руу илгээлээ.</div>}
                        type="success"
                        showIcon
                      />
                    </div>
                  }
                  {showWarning &&
                    <div style={{ marginBottom: '1rem' }}>
                      <Alert style={{ borderRadius: '0.35rem' }}
                        description={<div style={{ fontSize: '0.8rem' }}><strong>Анхааруулга</strong><br /> Имэйл хаягаар бүртгэл олдсонгүй.</div>}
                        type="warning"
                        showIcon
                      />
                    </div>
                  }
                  <Form
                    onFinish={checkAndSentEmail}
                  >
                    <div>
                      <Input prefix={<IoMdPaperPlane fontSize='1rem' color="#BCBCC0" style={{ marginRight: '.5rem' }} />}
                        style={{ borderRadius: '.35rem', borderColor: '#ebecf0', fontSize: '0.8rem', padding: '.8rem' }}
                        name="email"
                        disabled={passwordSuccess ? true : false}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Бүртгэлтэй имэйл хаяг"
                      />
                    </div>
                    <br />
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <div style={{ display: 'flex', position: 'relative' }}>
                        <ReCAPTCHA
                          ref={captchaRef}
                          sitekey="6LcBGK4oAAAAAPGJyviCsjnrvRrE8_uVzRRDBmPr"
                          hl="mn"
                        />
                        {tooltip && <span className="tooltiptext"
                          style={{
                            position: 'absolute', bottom: 0, right: '-130px', padding: '0.4rem 1rem',
                            background: 'black', color: 'white', fontSize: '0.8rem', borderRadius: '0.35rem', opacity: '0.75'
                          }}
                        >Заавал бөглөх</span>}
                      </div>
                      <div>
                        <Button
                          disabled={passwordSuccess ? true : false}
                          onClick={checkAndSentEmail}
                          style={{ background: '#02419a', color: 'white', fontSize: '0.8rem', borderRadius: '.35rem', borderColor: '#02419a', padding: '0 2rem' }}
                        > Сэргээх
                        </Button>
                      </div>
                    </div>
                  </Form>
                </div>
              </Popup>
            }
            {
              <Popup
                visible={termsCondition}
                onHiding={() => setTermsCondition(false)}
                showCloseButton
                hideOnOutsideClick
                width={1000}>
                <TermsCondition />
              </Popup>
            }
            {
              <Popup
                visible={privacyPolicy}
                onHiding={() => setPrivacyPolicy(false)}
                showCloseButton
                hideOnOutsideClick
                width={1000}>
                <PrivacyPolicy />
              </Popup>
            }
          </div>
          {show ?
            <div style={{ display: 'flex', flexDirection: 'column', margin: 0, fontSize: '.75rem', color: "#0F2D52", cursor: 'pointer' }}>
              <div style={{ display: 'flex', gap: 10, justifyContent: 'center' }}>
                <span onClick={() => setTermsCondition(true)} >Үйлчилгээний нөхцөл</span>
                <span>|</span>
                <span onClick={() => setPrivacyPolicy(true)}>Нууцлалын бодлого</span>
                <span>|</span>
                <a style={{ color: "#0F2D52" }} onClick={() => setShow(false)}>Холбоо барих</a>
              </div>
              <span style={{ paddingTop: 10 }}>
                {window.location.href === "https://auth-mojha.opengov.mn/login"
                  ? <span onClick={() => { window.open('https://mojha.opengov.mn', '_blank') }}>&#169;2022-{new Date().getFullYear()} Хууль зүй, дотоод хэргийн яам</span>
                  : (window.location.href === "https://meds.opengov.mn/login" || window.location.href === "https://staging.opengov.mn/login" ? <span>&#169;{new Date().getFullYear()} Боловсрол, шинжлэх ухааны яам</span>
                    : <span onClick={() => { window.open('https://mofa.opengov.mn', '_blank') }}>&#169;2021-{new Date().getFullYear()} Хүнс, хөдөө аж ахуй,хөнгөн үйлдвэрийн яам</span>)}
              </span>
            </div>
            :
            <span onClick={() => setShow(true)}><i className="dx-icon-arrowleft"></i> Буцах</span>
          } 
        </div>
      </div>
      <div style={{ overflow: 'hidden', width: '100%', height: '100%' }}>
        <Carousel autoplay effect="fade" speed={2000}>
          {galleryDS.map((e, index) => (
            <div key={index} style={{ height: '100vh' }}>
              <img
                src={urlServer + "/api/file/documents/" + e.file_name}
                alt=""
                style={{
                  width: "100%",
                  height: "100vh",
                  objectFit: "cover"
                }}
              />
            </div>
          ))}
        </Carousel>
      </div>
    </LoginContainer>
  )
}

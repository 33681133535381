import request from '../../shared/lib/request'

const getAll = (emailId) => {
    let query = '';

    if (emailId) {
        query = `?email_id=${emailId}`;
    }

    return request({
        url: '/emailWorker' + query,
        method: 'GET'
    })
}

const getByIdEmailWorker = (id) => {
    return request({
        url: `/emailWorker/${id}`,
        method: 'GET'
    })
}

const EmailWorkerService = {
    getAll,
    getByIdEmailWorker
}

export default EmailWorkerService;

import React from 'react';
import DataGrid, {
  Column,
  Grouping,
  GroupPanel,
  HeaderFilter,
  ColumnChooser,
  Selection,
  Button,
  FilterPanel,
  Scrolling,
  Summary,
  GroupItem,
  TotalItem,
  Editing,
  Export,Toolbar,
} from "devextreme-react/data-grid"
import { locale, loadMessages } from 'devextreme/localization';
import ProposalService from "../../../services/api/proposal"
import { projectStatusIdEditAllowed } from "../static/data"
import SelectBox from "devextreme-react/select-box"
import { Button as BButton } from "devextreme-react/button"
import notify from "devextreme/ui/notify"
import { confirm } from "devextreme/ui/dialog"
import _ from "lodash"
import LoadPanelComponent from "../../../components/LoadPanelComponent"
import { Workbook } from "exceljs"
import saveAs from "file-saver"
import { exportDataGrid } from "devextreme/excel_exporter"
import { numberWithCommas, formatDate } from "../../../util";
import {ScrollView, Tooltip} from "devextreme-react";
import { Popup } from "devextreme-react/popup";
import ChartDetailByYear from "./ChartDetailByYear";
import { UserContext } from '../../../hooks/UserContext';
import moment from 'moment';
import {Item} from "devextreme-react/tab-panel";
import {CgArrowsExpandRight, CgCompressRight} from "react-icons/cg";
import BudgetCalcDataByEcon from "../../budgetCalculation/budgetCalcDataByEcon";
export default class ProposalDetail extends React.Component {
  constructor(props) {
    super(props)
    this.canEdit = false
    this.dataGrid = null
    this.defaultFilterValue = ['econ_code_1', '=', '2'];
    /*this.economicCategoryArray = this.props.eza
    this.econParentArray = this.economicCategoryArray.filter(
      (el) => el.parent_id === null
    )*/
    // console.log(this.econParentArray);
    this.state = {
      selectedAgendaId: null,
      loader: false,
      dataSource: [],
      selectedRKeys: [],
      sumYear0: 0,
      sumYear1: 0,
      sumYear2: 0,
      sumYear3: 0,
      sumYear4: 0,
      sumYear5: 0,
      fullscreen: false,
      expand: false,
      sumYear100: 0,
      sumYear103: 0,
      popupDetail: false,
      selectionData: null,
      orgId: null,
      year: null,
      autoExpandAll: false,
      popupCalc: false,
    }
    this.obt = this.props.obt
    this.refresh = this.props.refresh
    this.onSelectionChanged = this.onSelectionChanged.bind(this)
    this.onToolbarPreparing = this.onToolbarPreparing.bind(this)
    this.deleteDetail = this.deleteDetail.bind(this)
    this.onSelectionFilterChanged = this.onSelectionFilterChanged.bind(this)
    this.onClearButtonClicked = this.onClearButtonClicked.bind(this)
    this.onClickEdit = this.onClickEdit.bind(this)
    //this.helperGetAncestor = this.helperGetAncestor.bind(this)
    this.editable = this.editable.bind(this)
    console.log('this.props.refresh', this.props.refresh);
    loadMessages({
      'mn': {
        'dxList-selectAll': 'Бүгдийг сонгох',
      },
    });
    locale('mn')
  }

  static contextType = UserContext
  componentDidMount() {
    this.dataGrid.instance.repaint()
    this.initVars()
    this.loadData()
  }

  onClearButtonClicked() {
    this.dataGrid.instance.clearSelection()
  }

  async onSelectionFilterChanged({ value }) {
    this.dataGrid.instance.clearSelection();
    this.selectionChangedBySelectBox = true
    let prefix = value
    if (prefix !== null && prefix !== undefined) {
      let filteredList =
        prefix === 0
          ? this.state.dataSource
          : this.state.dataSource.filter((item) => item["agenda_id"] === prefix)

      const selectedRowKeys = filteredList.map((it) => it.id)
      // const selectedRowsData = await this.dataGrid.instance.getDataByKeys(selectedRowKeys);

      this.setState({
        selectedAgendaId: prefix,
        selectedRKeys: selectedRowKeys,
      })
    }
  }

  async initVars() {
    if (
      this.props.proposal &&
      projectStatusIdEditAllowed.includes(
        this.props.proposal.budget_project_status_id
      )
    )
      this.canEdit = true
  }
  /*helperGetAncestor(econId) {
    let parentId = this.economicCategoryArray.find(
      (i) => i.id === econId
    )?.parent_id
    if (parentId === null || parentId === undefined) {
      return econId
    } else {
      return this.helperGetAncestor(parentId)
    }
  }*/
  /*helperGetEconName(econId) {
    const temp = this.economicCategoryArray.find((i) => i.id === econId)
    if (temp) {
      return `${temp.code} - ${temp.name}`
    }
    return "Ангилагдаагүй"
  }
  helperGetEconCode(econId) {
    const temp = this.economicCategoryArray.find((i) => i.id === econId)
    if (temp) {
      return `${temp.code}`
    }
    return "Ангилагдаагүй"
  }*/


  editable(status_id, obt) {
    //shine 1
    //ttz-ruu 3
    //tez-ruu 2
    //tez-tat 4
    //ttz-tat 5
    //console.log('obt:', obt, status_id);
    switch (obt) {
      case 1:
        return [1, 2, 4].includes(status_id)
      case 2:
        return [1, 4, 3].includes(status_id)
      case 3:
        return [1, 4, 5].includes(status_id)
      default:
        return true
    }
  }



  async loadData() {
    // console.log(this);
    this.setState({
      loader: true,
    })
    // console.log(props.proposal);
    const { agenda } = this.props
    // console.log(agenda, event, eza)
    // const r = await RefService.getRefEconomicCategory();

    // this.agendaListAll = agenda;
    // this.eventListAll = event;

    const result = await ProposalService.getProposalDetailByProjectId(
      this.props.proposal.id
    )
    //console.log('getProposalDetailByProjectId', result);
    const tt = []
    result.data.forEach((item) => {
      const temp_agenda = agenda.find((i) => i.id === item.agenda_id)
      item["Хөтөлбөр"] = temp_agenda?.code?.slice(0, 5) + " - " + temp_agenda?.name
      //const temp_event = event.find((i) => i.id === item.event_id)
      /*item["Зориулалт"] = temp_event?.code?.slice(0,5) + " - " +temp_event?.name
      item["econ_category_parent_id"] = this.helperGetAncestor(
        item.econ_category_id
      )*/
      /* item["econ_name"] = this.helperGetEconName(item.econ_category_id)
       item["econ_code"] = this.helperGetEconCode(item.econ_category_id)*/
      // item["Хөтөлбөр"] = item.
      // item["Зориулалт"] =
      if (!tt.find((el) => el.id === item.agenda_id)) {
        tt.push({ name: item["Хөтөлбөр"], id: item.agenda_id })
      }
    })
    //result.data = _.sortBy(result.data, ["econ_name"], "asc")
    //pYear,agendaId, eventId, org_id
    const r2 = await ProposalService.listVBudgetCompareOrg(this.props.year, this.props.proposal.org_id);
    this.helperConvertHistory(r2.data, result.data);
    let year0 = _.sumBy(result.data, r => {
      return r.year0 * (1);
    });
    let year1 = _.sumBy(result.data, r => {
      return r.year1 * (1);
    });
    let year2 = _.sumBy(result.data, r => {
      return r.year2 * (1);
    });
    let year3 = _.sumBy(result.data, r => {
      return (isNaN(r[`year-1`]) ? 0 : r[`year-1`]) * (1);
    });
    let year4 = _.sumBy(result.data, r => {
      return (isNaN(r[`year-2`]) ? 0 : r[`year-2`]) * (1);
    });
    let year5 = _.sumBy(result.data, r => {
      return (isNaN(r[`year-3`]) ? 0 : r[`year-3`]) * (1);
    });
    let year100 = _.sumBy(result.data, r => {
      return (isNaN(r[`year100`]) ? 0 : r[`year100`]) * (1);
    });
    let year103 = _.sumBy(result.data, r => {
      return (isNaN(r[`year103`]) ? 0 : r[`year103`]) * (1);
    });
    this.setState({
      loader: false,
      dataSource: result.data,
      agendaFiltered: tt,
      sumYear0: year0,
      sumYear1: year1,
      sumYear2: year2,
      sumYear3: year3,
      sumYear4: year4,
      sumYear5: year5,
      sumYear100: year100,
      sumYear103: year103,
      amtDiff: 0,
    })

  }

  helperConvertHistory(budgetHistory, currentDetail) {
    const historyGrouped = _.groupBy(budgetHistory, "agenda_id")
    const currentGrouped = _.groupBy(currentDetail, "agenda_id")
    const currentAgendaIds = Object.keys(currentGrouped)

    // groupd by agenda ids
    currentAgendaIds.forEach((agendaId) => {
      // console.log(currentGrouped[agendaId]);

      if (historyGrouped[agendaId]) {
        const currentEventIdGrouped = _.groupBy(
          currentGrouped[agendaId],
          "event_id"
        )
        const curEventIds = Object.keys(currentEventIdGrouped)
        const historyEventIdGrouped = _.groupBy(
          historyGrouped[agendaId],
          "event_id"
        )

        // grouped by event ids
        curEventIds.forEach((eventId) => {
          if (historyEventIdGrouped[eventId]) {
            this.helperConvertHistoryEcon(
              historyEventIdGrouped[eventId],
              currentEventIdGrouped[eventId]
            )
          }
        })
      }
    })
  }
  helperConvertHistoryEcon(historyList, curList) {
    // console.log(historyList, curList);
    const historyEconGrouped = _.groupBy(historyList, 'econ_category_id');

    curList.forEach(item => {
      // item.
      if (historyEconGrouped[item.econ_category_id]) {
        historyEconGrouped[item.econ_category_id].forEach(his => {
          if ((this.props.year - his.budget_year * 1) === 1 && ![7, 100, 101, 102, 103].includes(his.budget_status_id)) { item[`year${this.props.year - his.budget_year * 1 - 1}`] = his.amount }
          else if ((this.props.year - his.budget_year * 1) !== 1 && his.budget_status_id === 7) { item[`year${this.props.year - his.budget_year * 1 - 1}`] = his.amount }
          else if ((this.props.year - his.budget_year * 1) === 0 && his.budget_status_id === 10) { item[`year${this.props.year - his.budget_year * 1 - 1}`] = his.amount }
          else if ((this.props.year - his.budget_year * 1) === -1 && his.budget_status_id === 12) { item[`year${this.props.year - his.budget_year * 1 - 1}`] = his.amount }
          else if ((this.props.year - his.budget_year * 1) === -2 && his.budget_status_id === 12) { item[`year${this.props.year - his.budget_year * 1 - 1}`] = his.amount }
          else if ((this.props.year - his.budget_year * 1) === 1 && his.budget_status_id === 100) { item[`year${100}`] = his.amount * 1 }
          else if ((this.props.year - his.budget_year * 1) === 1 && his.budget_status_id === 101) { item[`year${101}`] = his.amount * 1 }
          else if ((this.props.year - his.budget_year * 1) === 1 && his.budget_status_id === 102) { item[`year${102}`] = his.amount * 1 }
          else if ((this.props.year - his.budget_year * 1) === 1 && his.budget_status_id === 103) { item[`year${103}`] = his.amount * 1 }
        })
      }
    })

  }

  componentDidUpdate(prevProps) {
    if (this.props.loadDetail !== prevProps.loadDetail || this.props.refresh !== prevProps.refresh) {
      this.loadData();
    }
  }

  /*

    const groupCellTemplate = (groupCell, data) => {
      console.log(groupCell,data);
      //groupCell
      const formattedValue = data.column.caption + ": " + data.displayValue + " new format";
      groupCell.append(`${formattedValue}`);
      const html = `<div>${groupCell}</div>`;
      console.log(groupCell);
      console.log(groupCell.innerHTML);
      console.log(html);
      groupCell = html;
      return (<div>
        html
      </div>);

      // groupCell += `<div
      //   className="dx-select-checkbox dx-datagrid-checkbox-size dx-show-invalid-badge dx-checkbox dx-widget"
      //   role="checkbox" aria-checked="false" tabIndex="0"><input type="hidden" value="false">
      //   <div className="dx-checkbox-container"><span className="dx-checkbox-icon"></span></div></div>`
    }*/

  GroupCell(e) {
    return <div>{e.data.displayValue}</div>
  }
  onClickEdit(e) {
    // console.log(e);
    const agendaId = e.row.data.agenda_id
    const eventId = e.row.data.event_id
    const canEditModal = this.editable(this.props.proposal.budget_project_status_id, this.obt);
    if (typeof this.props.onEditCallBack === "function") {
      this.props.onEditCallBack({ agendaId, eventId,canEditModal })
    }
  }
  onToolbarPreparing(e) {
    let toolbarItems = e.toolbarOptions.items;

    toolbarItems.forEach(function (item) {

    });
  };

  async deleteDetail(e) {
    if (!this.state.selectedRKeys || !this.state.selectedRKeys.length) {
      notify("Устгах мэдээлэл сонгоно уу !", "warning")
      return
    }
    let result = confirm(
      `<i>Нийт <b>${this.state.selectedRKeys.length}</b> сонгосон мэдээллийг устгах уу?</i>`,
      "Баталгаажуулалт"
    )
    result.then(async (dialogResult) => {
      if (dialogResult) {
        try {
          const res = await ProposalService.deleteDetailBulk(
            this.state.selectedRKeys
          )
          if (res.success) {
            notify(`${res.message}`, "success")
            // maybe refresh data or smth.
            this.loadData()
          } else {
            notify(`${res.message}`, "error")
          }
        } catch (e) {
          console.log(e)
          notify(`Алдаа гарлаа`, "error")
        }
      }
    })
  }
  onExporting = (e) => {
    const { user } = this.context
    const workbook = new Workbook()
    const worksheet = workbook.addWorksheet("Main sheet")
    worksheet.columns = [
      { width: 10 }, { width: 100 }, { width: 18 }, { width: 18 }, { width: 18 }, { width: 18 }, { width: 18 }, { width: 18 }, { width: 18 }, { width: 18 }, { width: 18 }, { width: 18 }, { width: 18 }, { width: 18 }, { width: 18 }
    ];
    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
      keepColumnWidths: true,
      topLeftCell: { row: 6, column: 2 },
      customizeCell: ({ gridCell, excelCell }) => {
        excelCell.font = { name: 'Arial', size: 8 }
        if (gridCell.rowType === 'header') {
          excelCell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'd9d9d9' } };
          excelCell.font = { name: 'Arial', size: 8, 'bold': true }
        }
        if (gridCell.rowType === 'group') {
          excelCell.font = { color: { argb: '0000' }, 'bold': true, name: 'Arial', size: 8 };
          excelCell.alignment = { wrapText: false };
          if (excelCell._column._number !== 2) excelCell.alignment = { horizontal: 'right' };

        }
        if (gridCell.rowType === 'totalFooter' && excelCell.value) {
          excelCell.font.italic = true;
        }
        if (gridCell.rowType === "data") {
          excelCell.numFmt = '#,##0.00'
          excelCell.alignment = { horizontal: 'right', wrapText: true }
        }
        if (gridCell.rowType === 'group' && gridCell.groupIndex === 1) {
          if (excelCell._column._number !== 2) excelCell.alignment = { horizontal: 'right', wrapText: false };
          excelCell._row._cells[1].model.value = ` ${excelCell._row._cells[1].model.value}`
        }
        if (gridCell.rowType === 'group' && gridCell.groupIndex === 2) {
          if (excelCell._column._number !== 2) excelCell.alignment = { horizontal: 'right', wrapText: false };
          excelCell._row._cells[1].model.value = `  ${excelCell._row._cells[1].model.value}`
        }
        if (gridCell.rowType === 'group' && gridCell.groupIndex === 3) {
          if (excelCell._column._number !== 2) excelCell.alignment = { horizontal: 'right', wrapText: false };
          excelCell._row._cells[1].model.value = `   ${excelCell._row._cells[1].model.value}`
        }
        if (gridCell.rowType === 'group' && gridCell.groupIndex === 4) {
          if (excelCell._column._number !== 2) excelCell.alignment = { horizontal: 'right', wrapText: false };
          excelCell._row._cells[1].model.value = `    ${excelCell._row._cells[1].model.value}`
        }
        if (gridCell.rowType === 'group' && gridCell.groupIndex === 5) {
          if (excelCell._column._number !== 2) excelCell.alignment = { horizontal: 'right', wrapText: false };
          excelCell._row._cells[1].model.value = `     ${excelCell._row._cells[1].model.value}`
        }
        if (gridCell.rowType === 'data') {
          excelCell._row._cells[1].model.value = `      ${excelCell._row._cells[1].model.value}`
        }
      },
    })
      .then(() => {
        Object.assign(worksheet.getRow(1).getCell(1), {
          value: 'Гарчиг:',
          font: { name: 'Arial', size: 8 },
        })
        Object.assign(worksheet.getRow(1).getCell(2), {
          value: `${this.props.year} оны төсвийн төсөл`,
          font: { name: 'Arial', size: 8, 'bold': true, color: { argb: '0070c0' } },
        })
        Object.assign(worksheet.getRow(2).getCell(1), {
          value: 'Байгууллага:',
          font: { name: 'Arial', size: 8 },
        })
        Object.assign(worksheet.getRow(2).getCell(2), {
          value: this.props.name,
          font: { name: 'Arial', size: 8 },
        })
        Object.assign(worksheet.getRow(3).getCell(1), {
          value: 'Хэрэглэгч:',
          font: { name: 'Arial', size: 8 },
        })
        Object.assign(worksheet.getRow(3).getCell(2), {
          value: `${user.last_name.slice(0, 1)}. ${user.first_name}, ${user.position_name}`,
          font: { name: 'Arial', size: 8 },
        })
        Object.assign(worksheet.getRow(4).getCell(1), {
          value: 'Огноо:',
          font: { name: 'Arial', size: 8 },
        })
        Object.assign(worksheet.getRow(4).getCell(2), {
          value: new moment().locale('mn').format('lll'),
          font: { name: 'Arial', size: 8 },
        })
      })
      .then(() => {
        workbook.xlsx.writeBuffer().then((buffer) => {
          saveAs(
            new Blob([buffer], { type: "application/octet-stream" }),
            "OG-" + this.props.year + " оны төсвийн төсөл -" + formatDate(new Date()) + ".xlsx"
          )
        })
      })
    e.cancel = true
  }


  onCellPreparedHandler = (e) => {
    e.cellElement.style.fontFamily = 'Segoe UI'
    if (e.rowType === 'header') {
      e.cellElement.style.color = "#15186A"
      e.cellElement.style.backgroundColor = "#EEF0F4"
      e.cellElement.style.fontWeight = "bold";
      e.cellElement.style.textAlign = 'center'
      e.cellElement.style.verticalAlign = 'middle'
    }
    if (e.rowType === 'group') {
      e.cellElement.style.backgroundColor = '#fff'
      e.cellElement.style.color = 'black'
      e.cellElement.style.fontFamily = 'Segoe UI'
      e.cellElement.style.fontSize = '12px'
      e.cellElement.style.verticalAlign = "middle";
      e.cellElement.style.cursor = "pointer"
      if (e.column.dataField === 'amount') {
        e.cellElement.classList.add('temp')
      }
      if (e.columnIndex === 2) {
        e.cellElement.style.color = '#F93A3A'
      }
      if (e.columnIndex === 3) {
        e.cellElement.style.color = '#2C77FF'
      }

    }
    if (e.column.caption === "ТШЗ санал" && e.column.dataField === 'amount') {
      e.cellElement.style.fontWeight = "bold"
      e.cellElement.style.color = (e.rowType === "data" && e.data.is_calculated === 1) ? "#3DAA75" : "#15186A"
    }
    if (e.rowType === 'data') {
      e.cellElement.style.fontFamily = 'Segoe UI'
      e.cellElement.style.fontSize = '12px'
      e.cellElement.style.verticalAlign = "middle";

    }
    if (e.rowType === 'totalFooter') {
      if (e.column.dataField === 'amount') {
        e.cellElement.classList.add('temp')
      }
    }
    if (e.rowType === 'groupFooter') {
      e.cellElement.style.display = 'none'
    }
  }

  cellRender = (e, type) => {
    let value = 0;
    let value1 = 0;
    let amount = 0;
    // console.log('e.data.year0', e.data);
    // this.props.proposal.budget_project_status_id
    switch (this.props.proposal.budget_project_status_id) {
      case 1:
        amount = e.data.amount * 1;
        break;
      case 2:
        amount = e.data.tez_amount * 1;
        break;
      case 3:
        amount = e.data.ttz_amount * 1;
        break;
      case 4:
        amount = e.data.amount * 1;
        break;
      case 5:
        amount = e.data.amount * 1;
        break;
      case 6:
        amount = e.data.tez_amount * 1;
        break;
      case 7:
        amount = e.data.ttz_amount * 1;
        break;
      default:
        amount = e.data.amount * 1;
        break;
    }

    switch (type) {
      case 'year0':
        value = amount * 1;
        value1 = e.data.year0 * 1;
        break;
      case 'year1':
        value = amount * 1;
        value1 = e.data.year1 * 1;
        break;
      case 'year2':
        value = amount * 1;
        value1 = e.data.year2 * 1;
        break;
      case 'year103':
        value = amount * 1;
        value1 = e.data.year103 * 1
        break;
      case 'year-1':
        value = amount * 1;
        value1 = e.data[`year-1`] * 1;
        break;
      case 'year-2':
        value = amount * 1;
        value1 = e.data[`year-2`] * 1;
        break;
      case 'year-3':
        value = amount * 1;
        value1 = e.data[`year-3`] * 1;
        break;
      case 'ttz':
        value = e.data.ttz_amount * 1;
        value1 = e.data.amount * 1;
        break;
      case 'tez':
        value = e.data.tez_amount * 1;
        value1 = e.data.ttz_amount * 1;
        break;
      default:
        value = 0;
        value1 = 0;
        break;
    }
    let percent = 0;
    if (value || value1) {
      percent = value1 !== 0 ? (value - value1) * 100 / value1 : 0;
    }

    if (percent > 0) {
      return (<div class="inc customCell" style={{ flexDirection: 'column', display: 'flex', justifyContent: 'space-between' }}>
        <div class="current-value" style={{ fontWeight: 'normal', color: '#000000' }}>{e.text ? e.text : '0.00'}</div>
        <div class="diff" style={{ color: '#f66160' }}>{numberWithCommas(percent)}%</div>
      </div>)
    } else if (percent < 0) {
      return <div class="dec customCell" style={{ flexDirection: 'column', display: 'flex', justifyContent: 'space-between' }}>
        <div class="current-value" style={{ fontWeight: 'normal', color: '#000000' }}>{e.text ? e.text : '0.00'}</div>
        <div class="diff" style={{ color: '#3daa75' }}>{numberWithCommas(percent)}%</div>
      </div>
    } else {
      return <span style={{ fontWeight: 'normal', color: '#000000' }}>{e.text ? e.text : '0.00'}</span>
    }
  }
  /*
  ExpandCollapseButton = useCallback(() => {
    return(<Button
        onClick={(e)=>this.setState({expand: !this.state.expand})}
        render={()=>{
          return this.state.expand ? (
                  <CgCompressRight size={"17px"} color="#1a1976"/>)
              :
              (
                  <CgArrowsExpandRight size={"17px"} color="#1a1976"/>
              )}}>
    </Button>)
  },[this.state.expand])
  
  FullscreenButton = useCallback(() => {
    return(
        <Button
            onClick ={()=>{
              this.requestFullscreen()
              this.setState({fullscreen: prev=>!prev})
            }}
            style={{marginLeft:"0.5em"}}
            render={()=>{
              return this.state.fullscreen ?
                  (<CgMinimize size={"17px"} color="#1a1976"/>)
                  :
                  (<CgMaximize size={"17px"} color="#1a1976"/>)
            }}
        >
        </Button>
    )
  },[this.state.fullscreen])*/

  renderHBHeader = () => {
    return <a style={{ color: '#909090' }} id="proposalHB_Header">{this.props.year - 1} оны ХБГ</a>;
  }

  customizedName = (data) => {
    return (
      <div style={{ flexDirection: 'column', display: 'flex', justifyContent: 'space-between' }}>
        <div>{data.text} </div>
        <div style={{ color: '#3daa75' }}>&nbsp;</div>
      </div>
    )
  }
  onChartDetail = async (e) => {
    if (e.row.data) {
      this.setState({
        loader: true,
        orgId: this.props.proposal.org_id,
        year: this.props.year,
        selectionData: e.row.data,
        popupDetail: true
      });
      this.setState({ loader: false });
    }
  }
  renderDetail = props => {
    return (
      <Popup
        visible={this.state.popupDetail}
        onHiding={() => this.setState({ popupDetail: false })}
        hideOnOutsideClick={true}
        showCloseButton={true}
        height="80vh"
        showTitle={true}
        title={this.state.selectionData.econ_name + (this.state.selectionData.econ_code.substring(0, 1) !== '4' ? /жилээр, сая ₮/ : '/жилээр/')}
      >
        <div className="card-body" style={{ paddingBottom: "1.6rem" }}>
          <ChartDetailByYear orgId={this.state.orgId} agendaId={this.state.selectionData.agenda_id} eventId={this.state.selectionData.event_id} econCode={this.state.selectionData.econ_code} year={this.state.year} />
        </div>
      </Popup>
    )
  }
  onRowPrepared = (args) => {
    if (args.rowType == 'group' && (args.values[args.groupIndex] == "" || args.values[args.groupIndex] == null)) {
      args.rowElement.hidden = true;
      if (!args.component.isRowExpanded(args.key))
        args.component.expandRow(args.key)
    }
  }
  onContentReady = (e) => {
    if (e.component.shouldSkipNextReady) {
      e.component.shouldSkipNextReady = false
    } else {
      e.component.shouldSkipNextReady = true;
      e.component.columnOption("command:select", "width", 50);
      e.component.columnOption("command:expand", "width", 20);
      e.component.updateDimensions();
    }
  }


  calculateArea = (rowData,field) => {
    return rowData?.econ_name_1 === '2-Нийт зарлага ба цэвэр зээлийн дүн' && rowData[`${field}`]*1;
  }
  calculateAreaSummary = (options) => {
    if(options.name.substring(0,3) === 'sum')   {
           if (options.summaryProcess === 'start') {
             options.totalValue = 0;
           } else if (options.summaryProcess === 'calculate') {
             options.totalValue += isNaN(this.calculateArea(options.value,options.name.split("sum_").pop())) ? 0 : this.calculateArea(options.value,options.name.split("sum_").pop()) ;
           }
    }
    else  {
      if (options.summaryProcess === 'start') {
        options.totalValue = 0;
      } else if (options.summaryProcess === 'calculate') {
        if(options.groupIndex < 2){
          options.totalValue += isNaN(this.calculateArea(options.value,options.name)) ? 0 : this.calculateArea(options.value,options.name) ;
        } else {
          options.totalValue += isNaN(options.value[`${options.name}`] * 1) ? 0 : options.value[`${options.name}`] * 1
        }

      }
    }

  }
   handleOnCellHover = (e) => {
    //console.log(e);
    if (e && e.column && e.column.dataField) {
      if (!e.column.dataField === 'amount')
        return;
      else {
        if (e.rowType === 'data' && e.column.dataField === 'amount' && e.row.data.is_calculated === 1) {
          if (e.eventType === 'mouseover') {
            //e.cellElement.style.boxShadow = '0px 0px 0px 3px #2C77FF inset'
            e.cellElement.setAttribute('title', 'Төсвийн тооцооллоос татагдсан талбар!');
          } else {
            //e.cellElement.style.boxShadow = 'none'
            e.cellElement.removeAttribute('title');
          }
        }
      }
    } else return;

  }
  onCalcDetail = async (e) => {
    if (e.row.data) {
      this.setState({
        loader: true,
        orgId: this.props.proposal.org_id,
        year: this.props.year,
        selectionData: e.row.data,
        popupCalc: true
      });
      this.setState({ loader: false });
    }
  }

   renderPopupCalculation = () => {
    return (
        <Popup
            visible={this.state.popupCalc}
            onHiding={() => this.setState({popupCalc: false})}
            hideOnOutsideClick={true}
            showCloseButton={true}
            height="auto"
            maxHeight={'90%'}
            width={'95%'}
            showTitle={true}
            title={this.state.selectionData.org_name + ' ' + this.state.selectionData.agenda_name + ' ' + this.state.selectionData.event_name + ' '+ this.state.selectionData.econ_name}
        >
          <ScrollView width="100%" height="100%">
            <div className="card-body">
              <BudgetCalcDataByEcon eventID={this.state.selectionData.event_id} agendaID={this.state.selectionData.agenda_id} econID={this.state.selectionData.econ_category_id}
                                    year={this.state.year - 1} orgID={this.state.orgId}/>
            </div>
          </ScrollView>
        </Popup>
    )
  }
   setVisibleCalc = (e) => {
    return e.row.data.is_calculated === 1 ? true : false;
  };
  render() {
    const { selectedRKeys } = this.state;
    return (
      <div style={{ flex: 1, overflow: "auto" }} id={this.props.refresh}>
        <LoadPanelComponent
          shadingColor="rgba(0,0,0,0.4)"
          position={{ of: "datagrid" }}
          visible={this.state.loader}
          showPane={false}
          message=""
        />

        <Tooltip
          target={"#proposalHB_Header"}
          showEvent="mouseenter"
          hideEvent="mouseleave"
          hideOnOutsideClick={false}
          position="top"
        >
          <div style={{ padding: "1em" }}>
            {this.props.year - 1} оны хүлээгдэж байгаа гүйцэтгэл нь тухайн жилийн нийт гүйцэтгэлээс илүү болон зөрүүтэй байх нь хуваарийн өөрчлөлт хийгдээгүй байж болно.
          </div>
        </Tooltip>
        <DataGrid
          id="datagridProposal"
          height={"90vh"}
          width={'100%'}
          // width={'80vw'}Math.min(this.state.dataSource.length * 6 + 10, 80) + 'vh'
          ref={(ref) => (this.dataGrid = ref)}
          dataSource={this.state.dataSource}
          allowColumnReordering={true}
          allowColumnResizing={true}
          //rowAlternationEnabled={true}
          wordWrapEnabled={true}
          onSelectionChanged={this.onSelectionChanged}
          selectedRowKeys={selectedRKeys}
          keyExpr="id"
          onToolbarPreparing={this.onToolbarPreparing}
          //defaultFilterValue={this.defaultFilterValue}
          columnAutoWidth={true}
          showBorders={true}
          showRowLines={true}
          onContentReady={this.onContentReady}
          onCellPrepared={this.onCellPreparedHandler}
          showColumnLines={true}
          showColumnHeaders={true}
          onExporting={this.onExporting}
          onRowPrepared={this.onRowPrepared}
          onCellHoverChanged={this.handleOnCellHover}
          noDataText={"Мэдээлэл байхгүй байна та төсвийн төсөлөө боловсруулаарай"}
        >
          <FilterPanel
            visible={false}
            texts={{ clearFilter: "Шүүлтүүр цэвэрлэх", createFilter: "Шүүх" }}
          />
          <HeaderFilter visible={true} allowSearch={true} />
          <ColumnChooser
            mode='select'
            enabled={true}
            position={{
              my:"right top",
              at:"right bottom",
              of:".dx-datagrid-column-chooser-button"
            }}
            emptyPanelText={"Энд нуух багана аа чирж оруулна уу"}
            title={"Багана сонгох"}
          />
          <Selection mode={this.canEdit ? "multiple" : "none"}/>
          <GroupPanel
            visible={true}
            emptyPanelText={"Энд багана чирж оруулна уу"}
          />

          {/*<SearchPanel visible={true} highlightCaseSensitive={true}/>*/}
          <Grouping autoExpandAll={this.state.autoExpandAll}
                    contextMenuEnabled={false}
                    expandMode="rowClick" />
          <Toolbar>
            <Item location={'before'}>
              <div className="row" style={{ alignItems: 'center' }}>
                {this.canEdit && (
                    <div style={{ display: "flex"}}>
                      <SelectBox
                          width={"30vw"}
                          id="select-prefix"
                          style={{
                            marginRight: 10,
                            display: "inline-block",
                            verticalAlign: "middle",
                          }}
                          dataSource={this.state.agendaFiltered}
                          onValueChanged={this.onSelectionFilterChanged}
                          placeholder="Хөтөлбөр сонгоно уу"
                          value={this.state.selectedAgendaId}
                          valueExpr="id"
                          displayExpr="name"
                      />
                      <div style={{ flex: 1, display: "flex", justifyContent: 'flex-start' }}>
                        <BButton
                            className='popupButton borderRadius'
                            disabled={!selectedRKeys.length}
                            visible={[1, 4, 5].includes(this.props.proposal.budget_project_status_id)}
                            onClick={this.onClearButtonClicked}
                            icon="close"
                            style={{ marginRight: 10 }}
                            text="Сонголтыг цуцлах"
                        />
                        <BButton
                            //style={{backgroundColor: selectedRKeys && selectedRKeys.length ? 'red': 'transparent', color: selectedRKeys && selectedRKeys.length ? 'white': 'inherit'}}
                            disabled={!selectedRKeys.length}
                            visible={[1, 4, 5].includes(this.props.proposal.budget_project_status_id)}
                            onClick={this.deleteDetail}
                            className='deleteButton borderRadius'
                            stylingMode="contained"
                            icon="trash"
                            type="danger"
                            text="Сонголтыг устгах"

                        />
                      </div>
                    </div>
                )}
              </div>
            </Item>
            <Item>
              <div style={{ display: 'flex', justifyContent: 'space-between', gap: '5px' }}>
                <BButton
                    onClick={(e) => this.setState({autoExpandAll: !this.state.autoExpandAll}) }
                    render={() => {
                      return this.state.autoExpandAll ? (
                              <CgCompressRight size={"1rem"} color="#172B4D" />)
                          :
                          (
                              <CgArrowsExpandRight size={"1rem"} color="#172B4D" />
                          )
                    }}
                    hint={this.state.autoExpandAll ? 'Багасгах' : 'ЭЗА дэлгэрэнгүй харуулах'}
                >
                </BButton>
              </div>
            </Item>
            <Item name="exportButton" />
            <Item name="columnChooserButton"/>
          </Toolbar>
          <Column
            dataField="agenda_name"
            //groupIndex={1}
             caption={'Хөтөлбөр'} groupIndex={0} //autoExpandGroup={true}
          groupCellComponent={this.GroupCell}
          >
            {/*<Lookup dataSource={agendaListAll} valueExpr="id" displayExpr="name" />*/}
          </Column>
          <Column dataField="event_name" groupIndex={1} //autoExpandGroup={false}
                  groupCellComponent={this.GroupCell}
             caption={'Арга хэмжээ'}>
            {/*<Lookup dataSource={eventListAll} valueExpr="id" displayExpr="name" />*/}
          </Column>
          <Column
            dataField="econ_name_1"
            groupIndex={2}
            caption="ЭЗА 1-р түвшин"
            //autoExpandGroup={true}
            groupCellComponent={this.GroupCell} allowFiltering={false}
            dataType="text"
          >
            {/*<Lookup dataSource={this.econParentArray} valueExpr="id" displayExpr="name" />*/}
          </Column>
          <Column
            dataField="econ_name_2"
            groupIndex={3}
            caption="ЭЗА 2-р түвшин" allowFiltering={false}
            groupCellComponent={this.GroupCell}
            dataType="text" 
            //autoExpandGroup={false}
          >
            {/*<Lookup dataSource={this.econParentArray} valueExpr="id" displayExpr="name" />*/}
          </Column>
          <Column
            dataField="econ_name_3"
            groupIndex={4}
            caption="ЭЗА 3-р түвшин"
            groupCellComponent={this.GroupCell} allowFiltering={false}
            dataType="text" 
            //autoExpandGroup={false}
          >
            {/*<Lookup dataSource={this.econParentArray} valueExpr="id" displayExpr="name" />*/}
          </Column>
          <Column
            dataField="econ_name_4"
            groupIndex={4}
            caption="ЭЗА 4-р түвшин"
            groupCellComponent={this.GroupCell} allowFiltering={false}
            dataType="text" 
            //autoExpandGroup={false}
          >
            {/*<Lookup dataSource={this.econParentArray} valueExpr="id" displayExpr="name" />*/}
          </Column>
          <Column
            dataField="econ_name"
            minWidth={'18rem'} //width={'auto'}
            caption="Үзүүлэлт"
            dataType="text"
            allowGrouping={false}
            cellRender={this.customizedName}
            //fixed={true}
          >
            {/*<Lookup dataSource={this.economicCategoryArray} valueExpr="id" displayExpr="name"/>*/}
          </Column>
          <Column caption={`${this.props.year - 3}`}
            visible={this.state.sumYear2 === 0 ? false : true}
          >
            <Column
              dataField="year2"
              caption={`Гүйцэтгэл`}
              format="#,##0.00"
              dataType="number"
              allowEditing={false}
              allowGrouping={false}
              allowFiltering={false}
              //width={120}
              cellRender={(e) => this.cellRender(e, 'year2')}
            />
          </Column>
          <Column caption={`${this.props.year - 2}`}
            visible={this.state.sumYear1 === 0 ? false : true}
          >
            <Column
              dataField="year1"
              caption={`Гүйцэтгэл`}
              format="#,##0.00"
              dataType="number"
              allowGrouping={false}
              allowEditing={false}
              allowFiltering={false}
              //width={120}
              cellRender={(e) => this.cellRender(e, 'year1')}
            />
          </Column>
          <Column caption={`${this.props.year - 1}`} 
          // visible={
          //   (this.state.sumYear0 === 0 || isNaN(this.state.sumYear0)) && 
          //   (this.state.sumYear103 === 0 || isNaN(this.state.sumYear103) ) && 
          //   (this.state.sumYear100 === 0 || isNaN(this.state.sumYear100)) ? false : true}
            >
            <Column
              dataField="year0"
              dataType="number"
              caption={`Төсөв`}
              format="#,##0.00"
              allowGrouping={false}
              allowEditing={false}
              allowFiltering={false}
              //width={120}
              cellRender={(e) => this.cellRender(e, 'year0')}
              // visible={this.state.sumYear0 === 0 ? false : true}
            />
            <Column
              dataField="year103"
              caption={`ХБГ`}
              format="#,##0.00"
              alignment='right'
              allowEditing={false}
              allowFiltering={false}
              //width={120}
              cellRender={(e) => this.cellRender(e, 'year103')}
              // headerCellRender={this.renderHBHeader}
              // visible={this.state.sumYear103 === 0 ? false : true}
            />
            <Column
              dataField="year100"
              caption={`ТШЗ санал`}
              format="#,##0.00"
              alignment='right'
              allowEditing={false}
              allowFiltering={false}
              //width={120}
              // visible={this.state.sumYear100 === 0 ? false : true}
              customizeText={(e)=>{
                return e.valueText === "" ? "0.00" : e.valueText
              }}
            />
            <Column
              dataField="year101"
              caption={`ТТЗ санал`}
              format="#,##0.00"
              allowEditing={false}
              allowFiltering={false}
              //cellRender={(e) =>cellRender(e,'year0')}
              visible={false}
            />
            <Column
              dataField="year102"
              caption={`ТЕЗ санал`}
              format="#,##0.00"
              allowEditing={false}
              allowFiltering={false}
              //cellRender={(e) =>cellRender(e,'year0')}
              visible={false}
            />
          </Column>
          <Column caption={`${this.props.year}`}>
            <Column
              dataField="amount"
              caption={`ТШЗ санал`}
              alignment="right"
              dataType="number"
              allowGrouping={false}
              format="#,##0.00"
              allowFiltering={false}
              //width={120}
            //cellRender={(e) =>this.cellRender(e,'tshz')}
            />
            <Column
              dataField="ttz_amount"
              caption={`ТТЗ санал`}
              alignment="right"
              dataType="number"
              allowGrouping={false}
              format="#,##0.00"
              allowFiltering={false}
              //width={120}
              cellRender={(e) => this.cellRender(e, 'ttz')}
            />
            <Column
              dataField="tez_amount"
              caption={`ТЕЗ санал`}
              alignment="right"
              dataType="number"
              allowGrouping={false}
              format="#,##0.00"
              allowFiltering={false}
              //width={120}
              cellRender={(e) => this.cellRender(e, 'tez')}
            />
          </Column>
          <Column
            dataField="year-1"
            caption={`${this.props.year} оны хязгаар`}
            format="#,##0.00"
            dataType="number"
            allowEditing={false}
            allowGrouping={false}
            allowFiltering={false}
            //width={120}
            cellRender={(e) => this.cellRender(e, 'year-1')}
            visible={this.state.sumYear3 === 0 ? false : true}
          />
          <Column
            dataField="year-2"
            caption={`${this.props.year + 1} оны төсөөлөл`}
            format="#,##0.00"
            dataType="number"
            allowEditing={false}
            allowGrouping={false}
            allowFiltering={false}
            //width={120}
            cellRender={(e) => this.cellRender(e, 'year-2')}
            visible={this.state.sumYear4 === 0 ? false : true}
          />
          <Column
            dataField="year-3"
            caption={`${this.props.year + 2} оны төсөөлөл`}
            format="#,##0.00"
            dataType="number"
            allowEditing={false}
            allowGrouping={false}
            allowFiltering={false}
            //width={120}
            cellRender={(e) => this.cellRender(e, 'year-3')}
            visible={this.state.sumYear5 === 0 ? false : true}
          />
          <Column type="buttons" caption={"Үйлдэл"} width={'90'}>
            <Button
              name={"edit"}
              icon={this.editable(this.props.proposal.budget_project_status_id, this.obt) ? "edit" : "detailslayout"}
              text={this.canEdit ? "" : "Дэлгэрэнгүй"}
              hint= {this.editable(this.props.proposal.budget_project_status_id, this.obt) ? "Засварлах" : "Дэлгэрэнгүй"}
              visible={true}
              //visible={this.editable(this.props.proposal.budget_project_status_id, this.obt)}
              onClick={this.onClickEdit}
            />
            <Button hint="Бүх жилээр төсвийн график харах" icon="chart" onClick={this.onChartDetail} />
            <Button icon="datafield" visible={this.setVisibleCalc} text="Төсвийн тооцоолол харах" onClick={this.onCalcDetail}/>
          </Column>

          {this.editable(this.props.proposal.budget_project_status_id, this.obt) && (
            <Editing
              mode="row"
              allowUpdating={true}
              allowAdding={false}
              allowDeleting={true}
              confirmDelete={true}
              useIcons={true}
              texts={{
                addRow: "Төсөл нэмэх",
                cancelAllChanges: "Болих бүгд",
                cancelRowChanges: "Болих",
                confirmDeleteMessage: "Энэ мэдээллийг устгах уу?",
                confirmDeleteTitle: "Баталгаажуулах",
                deleteRow: "Устгах",
                editRow: "Өөрчлөх",
                saveAllChanges: "Хадгалах бүгд",
                saveRowChanges: "Хадгалах",
                undeleteRow: "Буцаах",
                validationCancelChanges: "Баталгаажуулах болих",
              }}
            />
          )}
          <Summary calculateCustomSummary={this.calculateAreaSummary}>
            <TotalItem
              column="econ_category_id"
              summaryType="count"
              showInColumn="econ_category_id"
              displayFormat="{0} төсөл"
            />
            <TotalItem name="sum_amount"
                       summaryType="custom"
                       showInGroupFooter={false} alignByColumn={true}
                       showInColumn="amount"
                       customizeText={(data) => {
                         return `${numberWithCommas(data.value)}`
                       }}/>
            <TotalItem name="sum_ttz_amount"
                       summaryType="custom"
                       showInGroupFooter={false} alignByColumn={true}
                       showInColumn="ttz_amount" customizeText={(data) => {
              return `${numberWithCommas(data.value)}`
            }} />
            <TotalItem name="sum_tez_amount"
                       summaryType="custom"
                       showInGroupFooter={false} alignByColumn={true}
                       showInColumn="tez_amount" customizeText={(data) => {
              return `${numberWithCommas(data.value)}`
            }} />
            <TotalItem name="sum_year2"
                       summaryType="custom"
                       showInGroupFooter={false} alignByColumn={true}
                       showInColumn="year2" customizeText={(data) => {
              return `${numberWithCommas(data.value)}`
            }} />
            <TotalItem name="sum_year1"
                       summaryType="custom"
                       showInGroupFooter={false} alignByColumn={true}
                       showInColumn="year1" customizeText={(data) => {
              return `${numberWithCommas(data.value)}`
            }} />
            <TotalItem name="sum_year0"
                       summaryType="custom"
                       showInGroupFooter={false} alignByColumn={true}
                       showInColumn="year0" customizeText={(data) => {
              return `${numberWithCommas(data.value)}`
            }} />
            <TotalItem name="sum_year-1"
                       summaryType="custom"
                       showInGroupFooter={false} alignByColumn={true}
                       showInColumn="year-1" customizeText={(data) => {
              return `${numberWithCommas(data.value)}`
            }} />
            <TotalItem name="sum_year-2"
                       summaryType="custom"
                       showInGroupFooter={false} alignByColumn={true}
                       showInColumn="year-2" customizeText={(data) => {
              return `${numberWithCommas(data.value)}`
            }} />
            <TotalItem name="sum_year-3"
                       summaryType="custom"
                       showInGroupFooter={false} alignByColumn={true}
                       showInColumn="year-3" customizeText={(data) => {
              return `${numberWithCommas(data.value)}`
            }} />
            <TotalItem name="sum_year100"
                       summaryType="custom"
                       showInGroupFooter={false} alignByColumn={true}
                       showInColumn="year100" customizeText={(data) => {
              return `${numberWithCommas(data.value)}`
            }} />
            <TotalItem name="sum_year101"
                       summaryType="custom"
                       showInGroupFooter={false} alignByColumn={true}
                       showInColumn="year101" customizeText={(data) => {
              return `${numberWithCommas(data.value)}`
            }} />
            <TotalItem name="sum_year102"
                       summaryType="custom"
                       showInGroupFooter={false} alignByColumn={true}
                       showInColumn="year102" customizeText={(data) => {
              return `${numberWithCommas(data.value)}`
            }} />
            <TotalItem name="sum_year103"
                       summaryType="custom"
                       showInGroupFooter={false} alignByColumn={true}
                       showInColumn="year103" customizeText={(data) => {
              return `${numberWithCommas(data.value)}`
            }} />
            <GroupItem
              column="agenda_id"
              summaryType="count"
              displayFormat="{0} мэдээлэл"
            />
            <GroupItem name="ttz_amount"
                       summaryType="custom"
                       showInGroupFooter={false} alignByColumn={true}
                       showInColumn="ttz_amount" customizeText={(data) => {
              return `${numberWithCommas(data.value)}`
            }} />
            <GroupItem name="tez_amount"
                       summaryType="custom"
                       showInGroupFooter={false} alignByColumn={true}
                       showInColumn="tez_amount" customizeText={(data) => {
              return `${numberWithCommas(data.value)}`
            }} />
            <GroupItem name="year2"
                       summaryType="custom"
                       showInGroupFooter={false} alignByColumn={true}
                       showInColumn="year2" customizeText={(data) => {
              return `${numberWithCommas(data.value)}`
            }} />
            <GroupItem name="year1"
                       summaryType="custom"
                       showInGroupFooter={false} alignByColumn={true}
                       showInColumn="year1" customizeText={(data) => {
              return `${numberWithCommas(data.value)}`
            }} />
            <GroupItem name="year0"
                       summaryType="custom"
                       showInGroupFooter={false} alignByColumn={true}
                       showInColumn="year0" customizeText={(data) => {
              return `${numberWithCommas(data.value)}`
            }} />
            <GroupItem name="amount"
                       summaryType="custom"
                       showInGroupFooter={false} alignByColumn={true}
                       showInColumn="amount" customizeText={(data) => {
              return `${numberWithCommas(data.value)}`
            }} />
            <GroupItem name="year-1"
                       summaryType="custom"
                       showInGroupFooter={false} alignByColumn={true}
                       showInColumn="year-1" customizeText={(data) => {
              return `${numberWithCommas(data.value)}`
            }} />
            <GroupItem name="year-2"
                       summaryType="custom"
                       showInGroupFooter={false} alignByColumn={true}
                       showInColumn="year-2" customizeText={(data) => {
              return `${numberWithCommas(data.value)}`
            }} />
            <GroupItem name="year-3"
                       summaryType="custom"
                       showInGroupFooter={false} alignByColumn={true}
                       showInColumn="year-3" customizeText={(data) => {
              return `${numberWithCommas(data.value)}`
            }} />
            <GroupItem name="year100"
                       summaryType="custom"
                       showInGroupFooter={false} alignByColumn={true}
                       showInColumn="year100" customizeText={(data) => {
              return `${numberWithCommas(data.value)}`
            }} />
            <GroupItem name="year101"
                       summaryType="custom"
                       showInGroupFooter={false} alignByColumn={true}
                       showInColumn="year101" customizeText={(data) => {
              return `${numberWithCommas(data.value)}`
            }} />
            <GroupItem name="year102"
                       summaryType="custom"
                       showInGroupFooter={false} alignByColumn={true}
                       showInColumn="year102" customizeText={(data) => {
              return `${numberWithCommas(data.value)}`
            }} />
            <GroupItem name="year103"
                       summaryType="custom"
                       showInGroupFooter={false} alignByColumn={true}
                       showInColumn="year103" customizeText={(data) => {
              return `${numberWithCommas(data.value)}`
            }} />
          </Summary>
          <Scrolling mode="virtual" />
          <Export enabled={true} allowExportSelectedData={false} />

        </DataGrid>
        {this.state.popupDetail && this.renderDetail()}
        {this.state.popupCalc && this.renderPopupCalculation()}
      </div>
    )
  }

  onSelectionChanged(e) {
    // console.log(e);
    const { selectedRowKeys, selectedRowsData } = e
    // console.log(selectedRowKeys, selectedRowsData);
    // testRef.current = selectedRowKeys;
    this.setState({
      selectedRKeys: selectedRowKeys,
    })
    // setSelectedRowKeys(selectedRowKeys);
    // console.log(testRef.current)
  }
}

import React, {useContext} from "react";
import {UserContext} from "../hooks/UserContext";

const BudgetReportPage = () => {
    const { user } = useContext(UserContext);
    return (
        user.start_of_ancestry === 144 ?
            <iframe
                src="https://datastudio.google.com/embed/reporting/88ace783-d34e-4c7d-b4f6-4e0acce1e15e/page/p_t4fc91kotc"
                width="100%" style={{height: '100rem'}}/>
            :
            <iframe
                src="https://datastudio.google.com/embed/reporting/8b15e706-d88b-4bf8-8bda-922e80dac43e/page/p_3xion1n4vc"
                width="100%" style={{height: '100rem'}}/>
    )
}

export default BudgetReportPage;

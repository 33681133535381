import React, { useState, useEffect } from "react"
import notify from "devextreme/ui/notify";
import BudgetService from "../../../../services/api/budget";
import Chart, {
    ArgumentAxis,
    CommonSeriesSettings, Crosshair,
    Label,
    Legend,
    Point,
    Series,
    Strip,
    Tooltip,
    ValueAxis
} from "devextreme-react/chart";
import { getStripValues, numberWithCommas11 } from "../../../../util";
import _ from "lodash";

const BudgetOtherThree = (props) => {
    const [dataSource, setDataSource] = useState([]);

    const loadData = async () => {
        try {
            let r = await BudgetService.getBudgetOtherThree(props.chosenValue);
            setDataSource(r);
        } catch (e) {
            notify(e.message, "error", 2000);
        }
    }

    useEffect(() => {
        loadData();
    }, [props.chosenValue])

    const customizeTooltip = (arg) => {
        let r = _.find(dataSource, { 'budget_year': (arg.argument * 1 - 1).toString() });
        let q = ((arg.value - (r && r.todotgoson) > 0 ? arg.value / (r && r.todotgoson) : (r && r.todotgoson) / arg.value)).toFixed(2);
        let p = ((arg.value * 100 / (r && r.todotgoson)) - 100).toFixed(2);
        let d = (arg.value - (r && r.todotgoson));
        let f = d < 0 ? 'бага' : 'их';
        if (isFinite(q) && !isNaN(q)) {
            return {
                html: `<div class="tooltip-header">
                    <span>Ажиллагсдын тоо ${arg.argumentText} он:</span>
                    <span>${numberWithCommas11(arg.value)} </span>
                    </div>
                    <hr/>
                    <b><i>Өмнөх жилээс</i></b><br/>
                    <span class="tooltip-series-name">Зөрүү:</span> ${numberWithCommas11(d)}<br/> 
                    <span class="tooltip-series-name">Хувь:</span> ${p}%<br/> 
                    <span class="tooltip-series-name">Өөрчлөлт:</span> ${q} дахин ${f}`
            }
        } else {
            return {
                html: `<div class="tooltip-header">
                    <span>Ажиллагсдын тоо ${arg.argumentText} он:</span>
                    <span>${numberWithCommas11(arg.value)} </span>
                    </div>`
            }
        }
    }

    const customizeValueAxisLabel = (e) => {
        return (e.value * 1).toLocaleString()
    }

    const handleLegend = (e) => {
        if (e.target.isVisible()) {
            e.target.hide();
        } else {
            e.target.show();
        }
    }

    function handleMarker(item) {
        const color = item.series.isVisible() ? '#003696' : '#888';
        return (
            <svg>
                <rect x={0} y={0} width={12} height={12} fill={color}
                    rx={item.text.includes('өөрчлөлт') ? 6 : 0}
                    ry={item.text.includes('өөрчлөлт') ? 6 : 0}></rect>
            </svg>
        );
    }
    
    const color = ["#91b2eb", "#87a7ea", "#7d9cea", "#7391e9", "#6986e8", "#5f7be7", "#5570e6", "#4b65e5", "#415ae4", "#375fe3", "#2d54e2", "#2349e1", "#193ee0", "#0f33df", "#0528de", "#001ddd", "#0015d7", "#000ccd", "#0006c2", "#010691"]
    
    function customizeColor(items) {
        if (items.seriesName === 'Ажиллагсдын тоо') {
          return { color: color[items.index] }
        } else if (items.seriesName === 'Ажиллагсдын өөрчлөлт') {
          return { color: '#003696' }
        }
      }

    return (
        <div style={{ marginTop: '2rem' }}>
            <Chart
                dataSource={dataSource}
                onLegendClick={handleLegend}
                customizePoint={customizeColor}
            >
                <CommonSeriesSettings
                    argumentField="budget_year"
                    type="bar"
                >
                    <Label visible={false} />
                </CommonSeriesSettings>
                <Crosshair enabled={true} label={true} />
                <Series valueField="todotgoson" name="Ажиллагсдын тоо" cornerRadius={6} barPadding={0.2} />
                <Series valueField="todotgoson" name="Ажиллагсдын өөрчлөлт" type="spline" dashStyle="dash" color="#003696">
                    <Point size={10} color="#003696" />
                </Series>
                <ArgumentAxis tickInterval={1} argumentType='numeric' visualRange={{ endValue: new Date().getFullYear() }} >
                    {getStripValues().map((e) => (
                        <Strip startValue={e.startValue} endValue={e.endValue} color="#f5f5f5" />
                    ))}
                    <Label format='#' argumentFormat='format' />
                </ArgumentAxis>
                <ValueAxis>
                    <Label customizeText={customizeValueAxisLabel} />
                </ValueAxis>
                <Legend visible="true" verticalAlignment="bottom" horizontalAlignment="center" font={{ family: "Segoe UI" }} markerRender={handleMarker} />
                <Tooltip
                    zIndex={100000} enabled={true} cornerRadius={6} border={{ visible: false }}
                    customizeTooltip={customizeTooltip} font={{ color: '#fff' }} color="#555" location="edge">
                </Tooltip>
            </Chart>
        </div>
    )
}

export default BudgetOtherThree

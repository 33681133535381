import React, { useContext } from 'react';
import { DataGrid } from 'devextreme-react';
import { Column, GroupItem, Grouping, Paging, Summary, TotalItem } from 'devextreme-react/data-grid';
import LoadPanelComponent from '../budget/components/loadPanel';
import { UserContext } from '../../hooks/UserContext';

export const MainInfo = (props) => {
    const { user } = useContext(UserContext);

    const onCellPreparedHandler = (e) => {
        let elem = e.cellElement
        let statement = (user.organization.obt_id === 1 && props.chosenValue === 'БҮГД') ? [2, 3, 4, 5] : [1, 2, 3, 4]
        elem.style.setProperty('cursor', 'pointer', 'important');
        if (e.rowType === "header") {
            elem.wordWrapEnabled = true
            elem.style.fontWeight = "bold"
            elem.style.color = "#15186A"
            elem.style.backgroundColor = "#EEF0F4"
        } else if (e.rowType === 'group') {
            elem.style.color = "#15186A"
            elem.style.backgroundColor = "#fff"
            elem.style.fontSize = "12px"
            elem.style.borderColor = "#D4DCEC"
        } else if (e.columnIndex === 1 && e.rowType === 'data') {
            elem.style.color = '#000'
        }

        if (e.rowType === 'data' && statement.includes(e.columnIndex)) {
            elem.innerHTML = '';
            const iconContainer = document.createElement('span');
            const tempDiv = document.createElement('div');
            if (e.value === 1) {
                tempDiv.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 448 512"><path fill="#1fc75a" d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/></svg>`;
            } else {
                tempDiv.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 384 512"><path fill="#d70909" d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/></svg>`;
            }
            iconContainer.appendChild(tempDiv.firstChild);
            elem.appendChild(iconContainer);
        } else if (e.rowType === 'group' && statement.includes(e.columnIndex)) {
            elem.innerHTML = '';
            const iconContainer = document.createElement('span');
            const tempDiv = document.createElement('div');

            if (e.summaryItems[0].value === e.data.aggregates[0]) {
                tempDiv.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 448 512"><path fill="#1fc75a" d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/></svg>`;
            } else if (e.summaryItems[0].value === 0) {
                tempDiv.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 384 512"><path fill="#d70909" d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/></svg>`;
            } else {
                tempDiv.innerHTML = `<svg width="18px" height="18px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" fill="none" stroke="#ffd43b" stroke-width="0.0002"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path fill="#f5cc14" fill-rule="evenodd" d="M10 3a7 7 0 100 14 7 7 0 000-14zm-9 7a9 9 0 1118 0 9 9 0 01-18 0zm10.01 4a1 1 0 01-1 1H10a1 1 0 110-2h.01a1 1 0 011 1zM11 6a1 1 0 10-2 0v5a1 1 0 102 0V6z"></path> </g></svg>`;
            }
            iconContainer.appendChild(tempDiv.firstChild);
            elem.appendChild(iconContainer);
        }
    }

    const calcCellVal = (arg) => {
        const values = [arg.whatwedoing, arg.whoweare, arg.howwedoing, arg.history];
        const nonNull = values.filter(value => value).length;
        if (nonNull === values.length) {
            return '100%';
        } else {
            const percentage = (nonNull / values.length) * 100;
            return `${percentage.toFixed(0)}%`;
        }
    }

    return (
        <div id='load81'>
            <LoadPanelComponent position={{ of: "#load81" }} visible={props.loader} />
            <DataGrid
                dataSource={props.loadData}
                noDataText="Дата байхгүй байна"
                style={{ fontFamily: "Segoe UI" }}
                onCellPrepared={onCellPreparedHandler}
                wordWrapEnabled={true}
                allowColumnReordering={true}
                columnAutoWidth={true}
                showColumnLines={true}
                rowAlternationEnabled={true}
                showRowLines={true}
                showBorders={true}
                hoverStateEnabled={true}
                keyExpr="id"
                scrolling={true}
            >
                <Paging defaultPageSize={25} />
                <Grouping
                    autoExpandAll={false}
                    contextMenuEnabled={false}
                    expandMode="rowClick"
                    texts={{
                        groupContinuesMessage: 'Дараагийн хуудас',
                        groupContinuedMessage: 'Өмнөх хуудас'
                    }}
                />
                {
                    user.organization.obt_id === 1 && props.chosenValue === 'БҮГД' &&
                    <Column
                        dataField="org_name_2"
                        caption="ТТЗ"
                        dataType="text"
                        visible={user.organization.obt_id === 1}
                        groupIndex={0}
                        allowFiltering={user.organization.obt_id === 1}
                    />
                }
                <Column
                    dataField="org_name"
                    allowSorting={false}
                    caption="Байгууллага"
                    alignment="left"
                    minWidth={500}
                />
                <Column
                    dataField="whatwedoing"
                    allowSorting={false}
                    caption="Бид юу хийдэг вэ?"
                    alignment="center"
                    width="8rem"
                />
                <Column
                    dataField="whoweare"
                    allowSorting={false}
                    caption="Бид хэн бэ?"
                    alignment="center"
                    width="8rem"
                />
                <Column
                    dataField="howwedoing"
                    allowSorting={false}
                    caption="Бид хэрхэн ажилладаг вэ?"
                    alignment="center"
                    width="8rem"
                />
                <Column
                    dataField="history"
                    allowSorting={false}
                    caption="Бидний түүх"
                    alignment="center"
                    width="8rem"
                />
                <Column
                    allowSorting={false}
                    dataField='undsen_date'
                    caption="Шинэчлэгдсэн огноо"
                    alignment="center"
                    width="7rem"
                    dataType="date"
                    format="yyyy-MM-dd"
                />
                <Column
                    allowSorting={true}
                    caption="Гүйцэтгэл"
                    alignment="center"
                    width="6rem"
                    calculateCellValue={calcCellVal}
                />
                <Summary>
                    <GroupItem
                        column="org_name"
                        summaryType="count"
                        displayFormat="{0}-Байгууллага" />
                    <GroupItem column='whatwedoing' summaryType='sum' displayFormat='{0}' alignByColumn={true} showInGroupFooter={false} />
                    <GroupItem column='whoweare' summaryType='sum' displayFormat='{0}' alignByColumn={true} showInGroupFooter={false} />
                    <GroupItem column='howwedoing' summaryType='sum' displayFormat='{0}' alignByColumn={true} showInGroupFooter={false} />
                    <GroupItem column='history' summaryType='sum' displayFormat='{0}' alignByColumn={true} showInGroupFooter={false} />
                    <TotalItem
                        column="org_name"
                        summaryType="count"
                        displayFormat="Танд нийт {0} байгууллагын мэдээлэл харуулж байна" />

                </Summary>
            </DataGrid>
        </div>
    )
}

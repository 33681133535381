import React, {useEffect, useState} from "react";
import {Button} from "devextreme-react/button";
import notify from "devextreme/ui/notify";
import GeneralInfoService from "../../../services/api/generalInfo";
import DataGrid, {Column, FilterRow, Paging, Selection} from "devextreme-react/data-grid";
import _ from "lodash";
import {LoadIndicator} from "devextreme-react/load-indicator";
import {custom} from "devextreme/ui/dialog";

const OrganizationLinker = (props) => {
    const [orgs, setOrgs] = useState([]);
    const [chosenRow, setChosenRow] = useState(null);
    const [waitSubmit, setWaitSubmit] = useState(false);
    const [prevOrgId, setPrevOrgId] = useState(null);
    const [triggerRefresh, setTriggerRefresh] = useState(false);
    const [multiFactorId, setMultiFactorId] = useState(null);

    const loadOrgs = async () => {
        try {
            let r = await GeneralInfoService.fetchOrganizationsForGeneralInfo();
            setOrgs(r);
        } catch (e) {
            notify(e.message, "error", 2000);
        }
    }

    useEffect(() => {
        loadOrgs();
    }, [])

    useEffect(() => {
        loadSavedOrgData();
    }, [props.selectedRow, props.selectedRow.id, triggerRefresh])

    const loadSavedOrgData = async () => {
        try {
            let r = await GeneralInfoService.fetchSavedOrgData(props.selectedRow.id);
            if (r.length !== 0) {
                //FIXME: fix that typo
                setChosenRow(r[0].Organization);
                setPrevOrgId(r[0].table_id);
                setMultiFactorId(r[0].id);
            } else {
                setChosenRow(null);
                setPrevOrgId(null);
                setMultiFactorId(null);
            }
        } catch (e) {
            notify(e.message, "error", 2000);
        }
    }

    const onCloseClick = () => {
        if (typeof props.callback === 'function') {
            props.callback()
        }
    }

    const saveOrganization = async () => {
        try {
            setWaitSubmit(true);
            let customDialog = custom({
                title: "Баталгаажуулалт",
                messageHtml: `<span>${prevOrgId ? 'Өмнөх мэдээллийг шинэчлэх гэж байна.' : 'Оруулах'}</span>`,
                buttons: [
                    {
                        text: "Тийм", onClick: async () => {
                            await GeneralInfoService.setGenInfoOrg({ ...chosenRow, mainTableId: props.selectedRow.id, prevOrgId: prevOrgId })
                            notify("Амжилттай хадгаллаа", "success", 2000);
                            setTriggerRefresh(!triggerRefresh);
                            setWaitSubmit(false);
                        }
                    },
                    {
                        text: "Үгүй", onClick: () => {
                            setWaitSubmit(false);
                            return false;
                        }
                    }
                ]

            })
            await customDialog.show();
        } catch (e) {
            setWaitSubmit(false);
            notify(e.message, "error", 2000);
        }
    }

    const handleDelete = async () => {
        if (typeof props.deleteLinkedData === 'function') {
            setWaitSubmit(true);
            await props.deleteLinkedData(multiFactorId);
            setTriggerRefresh(!triggerRefresh);
            setWaitSubmit(false);
        }
    }

    return (
        <div>
            <div className="card">
                <div className="card-body" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <b>Байгууллага холбох</b>
                        <span>Сонгосон мэдээлэл: { props.selectedRow.val }</span>
                    </div>
                    <Button text="Хаах" icon="close" type="danger" onClick={onCloseClick}/>
                </div>
            </div>
            {chosenRow && !_.isEmpty(chosenRow) && <div className="card">
                <div className="card-body" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <span>Сонгосон байгууллага: {chosenRow.name}</span>
                    <div>
                        <Button disabled={waitSubmit} type="success" onClick={saveOrganization}>
                            <LoadIndicator style={{ height: '10px', width: '10px' }} className="button-indicator" visible={waitSubmit}/>
                            <span style={{ alignItems: 'center', justifyContent: 'center', verticalAlign: 'middle', display: 'inline-block' }} className="dx-button-text">Хадгалах</span>
                        </Button>
                        {multiFactorId && <Button disabled={waitSubmit} type="danger" onClick={handleDelete}>
                            <span style={{ alignItems: 'center', justifyContent: 'center', verticalAlign: 'middle', display: 'inline-block' }} className="dx-button-text">Устгах</span>
                        </Button>}
                    </div>
                </div>
            </div>}
            <div className="card">
                <div className="card-header"><span>Байгууллага</span></div>
                <div className="card-body">
                    <DataGrid
                        dataSource={orgs}
                        onSelectionChanged={(e) => {setChosenRow(e.selectedRowsData[0])}}
                    >
                        <Paging defaultPageSize={5}/>
                        <FilterRow visible={true}/>
                        <Selection mode="single"/>
                        <Column dataField="code" caption="Код"/>
                        <Column dataField="name" caption="Байгууллагын нэр"/>
                    </DataGrid>
                </div>
            </div>
        </div>
    )
}

export default OrganizationLinker;

import React from "react";
import { useContext, useEffect, useRef, useState } from "react";
import { Button } from 'devextreme-react/button'
import { UserContext } from "../../hooks/UserContext";
import chatService from '../../services/api/chat';
import userService from '../../services/api/user';
import Conversation from "./components/Conversation";
import Message from "./components/Message";
import ChatService from "../../services/api/chat";
import _ from "lodash"
import { TextBox } from "devextreme-react";
import { Autocomplete } from 'devextreme-react/autocomplete';
import Profile from "../../assets/imgs/profile_icon.png";
import paperPlaneSvg from '../../assets/imgs/paper-plane.svg';
import { HiChatAlt2 } from 'react-icons/hi'

export default function Chat(props) {
    const { socket } = props;
    const scrollRef = useRef();
    const { user } = useContext(UserContext);
    const [conversations, setConversations] = useState([]);
    const [currentChat, setCurrentChat] = useState(null);
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const [arrivalMessage, setArrivalMessage] = useState(null);
    const [onlineUsers, setOnlineUsers] = useState([]);
    const [findUserList, setFindUserList] = useState([]);
    const [selectedChatUser, setSelectedChatUser] = useState(null);
    const [typing, setTyping] = useState(false);
    const [activeUsers, setActiveUsers] = useState([]);
    const [changeConversations, setChangeConversations] = useState(Date.now());
    const url = '/api/uploads/documents/';

    const getConversations = async () => {
        const res = await chatService.getConversation();
        if (res && res.code === 200) {
            setChangeConversations(Date.now())
            setConversations(res.data || [])
        } else {
            setConversations([])
        }
    };

    const seenMessages = async () => {
        try {
            const res = await chatService.seenMessages(currentChat?.id);
            let tempConversation = [...conversations];
            let found = _.find(tempConversation, (o) => { return o.id === currentChat?.id });
            if (found) {
                found.isSeen = true;
                setConversations([...tempConversation]);
                setChangeConversations(Date.now())
                props.readChat();
            }

        } catch (e) {
            console.log(e)
        }
    }

    const onlyRead = async (conversationId) => {
        try {
            const res = await chatService.seenMessages(conversationId);
            let tempConversation = [...conversations];
            let found = _.find(tempConversation, (o) => { return o.id === conversationId });
            if (found) {
                found.isSeen = true;
                setConversations([...tempConversation]);
                setChangeConversations(Date.now())
                props.readChat();
            }
        } catch (e) {
            console.log(e)
        }
    }

    const checkActiveUsers = (user) => {
        // console.log(activeUsers, conversations, user)
        return true
    };

    useEffect(() => {
        if (socket) {
            findUser()

            socket.on('msg-receive', async (data) => {
                // console.log('msg-receive', data)
                await getConversations();
                props.readChat();
                setTyping(false);
                setArrivalMessage({
                    sender: data.from,
                    text: data.msg,
                    createdAt: new Date()
                })
            })

            let timer;

            socket.on('user-is-typing', async (data) => {
                setTyping(true)
                scrollRef.current?.scrollIntoView({ behavior: 'smooth' });

                clearTimeout(timer);

                timer = setTimeout(() => {
                    setTyping(false)
                    scrollRef.current?.scrollIntoView({ behavior: 'smooth' });
                }, 1000);
            })

            socket.on('active-users', async (data) => {
                // checkActiveUsers(activeUsers);
                setActiveUsers(data)
            })
        }

    }, [socket]);

    useEffect(() => {
        let tempConversations = [...conversations];
        if (activeUsers.length > 0 && tempConversations.length > 0) {
            for (let i = 0; i < tempConversations.length; i++) {
                let isOnline = activeUsers.filter(activeUser => activeUser.userId === tempConversations[i].user_id)
                tempConversations[i].isOnline = isOnline.length > 0;
            }

            setConversations(tempConversations)
        }
    }, [activeUsers, changeConversations])

    useEffect(() => {
        arrivalMessage && currentChat?.user_id === arrivalMessage.sender &&
            setMessages((prev) => [...prev, arrivalMessage]);
    }, [arrivalMessage, currentChat]);

    useEffect(() => {
        getConversations();
    }, [user.id]);

    useEffect(() => {
        const getMessages = async () => {
            try {
                const res = await chatService.getMessages(currentChat?.id);
                if (res && res.code === 200) {
                    setMessages(res.data || [])
                } else {
                    setMessages([])
                }
            } catch (e) {
                console.log(e);
                setMessages([])
            }
        }

        if (currentChat) {
            seenMessages();
            getMessages();
        }

    }, [currentChat]);

    const findUser = async () => {
        try {
            const res = await userService.findUser();
            setFindUserList(res.data || []);
        } catch (e) {
            console.log(e);
            setFindUserList([])
        }
    }

    const startConversation = async (chatUser) => {
        setSelectedChatUser(null)

        try {
            const res = await chatService.startConversation(chatUser.id);

            if (res.code === 200) {
                let tempConversation = {
                    id: res.data.id,
                    createdAt: '',
                    first_name: chatUser.first_name,
                    last_name: chatUser.last_name,
                    isSeen: false,
                    text: '',
                    user_id: chatUser.id
                };
                setConversations([...conversations, tempConversation]);
                setChangeConversations(Date.now())
                setCurrentChat(tempConversation)
            }
        } catch (e) {
            console.log(e)
        }
    }

    const handleTyping = async (e) => {
        const input = String.fromCharCode(e.event.keyCode);

        if (/[a-zA-Z0-9-_ ]/.test(input)) {
            const receiverId = currentChat?.user_id;
            socket.emit("user-is-typing", { toUser: receiverId, fromUser: user.id })
        }
    };

    const handleSubmit = async (e) => {
        // e.preventDefault();

        if (!newMessage) {
            return;
        }

        try {
            const res = await ChatService.addMessages(currentChat.id, currentChat.user_id, newMessage);
            const receiverId = currentChat?.user_id;

            let tempConversation = [...conversations];
            let found = _.find(tempConversation, (o) => { return o.id === currentChat?.id }) || {};
            found.text = newMessage;
            setConversations([...tempConversation]);
            setChangeConversations(Date.now())

            socket.emit("send-msg", {
                to: receiverId,
                from: user.id,
                msg: newMessage,
            });

            setMessages([...messages, res.data || {}]);
            setNewMessage('')
        } catch (e) {
            console.log(e)
        }
    };

    useEffect(() => {
        setTimeout(() => {
            scrollRef.current?.scrollIntoView({ block: 'start' });
        }, 100)
    }, [messages]);

    function highlightMatchingLetters(text, query) {
        const regex = new RegExp(`(${query})`, 'gi');
        return text.split(regex).map((part, index) => {
          if (part.match(regex)) {
            return <strong key={index}>{part}</strong>;
          } else {
            return part;
          }
        });
      }

    return (
        <div className={'row'}>
            <div className={'responsive-flex responsive-col col-3'} style={{ flexDirection: 'column' }}>
                <div>
                    <Autocomplete
                        dataSource={findUserList}
                        minSearchLength={2}
                        searchTimeout={200}
                        value={selectedChatUser}
                        valueExpr="first_name"
                        style={{ padding: '0.5rem 1rem', borderRadius: '15px', margin: 5 }}
                        onValueChanged={(e) => setSelectedChatUser(e.value)}
                        onItemClick={(e) => startConversation(e.itemData)}
                        placeholder="Хайх хүний нэр бичнэ үү..."
                        stylingMode="outlined"
                        showClearButton={true}
                        itemRender={(data) => {
                            const highlightedFirstName = highlightMatchingLetters(data.first_name, selectedChatUser);
                            let profileSrc = Profile;
                            if (data.file_name) {
                                profileSrc = url + data.file_name;
                            }
                            return <div style={{ display: 'flex', width: '100%' }}>
                                <div>
                                    <img style={{ width: 40, height: 40, borderRadius: 5, objectFit: 'cover' }} alt='' src={profileSrc} />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 10, flex: 1 }}>
                                    <span style={{ flex: 1 }}>{data.last_name && data.last_name?.charAt(0)?.toUpperCase() + '. '}{highlightedFirstName}</span>
                                    <div style={{ display: 'flex', color: '#999', flexDirection: 'column' }}>
                                        <div style={{ wordBreak: 'break-word', whiteSpace: 'pre-line', textTransform: 'lowercase' }}>{data.org_name}</div>
                                        <span style={{ flex: 1, fontStyle: 'italic' }}>{data.position_name}</span>
                                    </div>
                                </div>
                            </div>
                        }}
                    />
                </div>
                <div className={'card responsive-none responsive-flex'} style={{ flex: 1, flexDirection: 'column' }}>
                    <div style={{ fontSize: 12, fontWeight: '500', color: 'grey', textAlign: 'center', padding: 10, borderBottom: '1px solid #eff0f3' }}>
                        Харилцсан хэрэглэгчийн жагсаалт
                    </div>
                    <div className={'card-body user-container'} style={{ flex: 1, overflowY: 'auto' }}>
                        <div style={{ position: "absolute" }}>
                            {conversations.map((c, i) => {
                                return <div key={i} onClick={() => setCurrentChat(c)}>
                                    <Conversation conversation={c} currentUser={user} url={url} />
                                </div>
                            })}
                        </div>
                    </div>
                </div>
            </div>
            <div className={'responsive-col col-9'}>
                <div className={'card'}>
                    {currentChat && <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', borderBottom: '1px solid #eff0f3', padding: 10 }}>
                        <div style={{ textAlign: 'center', fontWeight: '550', fontSize: 14, color: '#333' }}>{currentChat.last_name && currentChat.last_name.charAt(0)?.toUpperCase() + '. '}{currentChat?.first_name}</div>
                        <div style={{ textAlign: 'center', fontSize: 12, color: 'gray' }}>{currentChat && currentChat?.organization_name}</div>
                    </div>}
                    <div className={'card-body'}>
                        <div className={'chat-container'} onClick={() => onlyRead(currentChat?.id)}>
                            {currentChat ? (
                                <>
                                    <div className={'chat-box-top'}>
                                        {messages.map((m, i) => (
                                            <div key={i}>
                                                <Message url={url} userImage={user.file_name} otherUserImage={currentChat.file_name} message={m} own={m.sender === user.id} />
                                            </div>
                                        ))}
                                        {typing &&
                                            <div key={'a141f1d1'}>
                                                <div className={'other'}>
                                                    <div className={'chat-detail'}>
                                                        <div className={'chat-user'}>
                                                            <img className={'logo'} alt="" src={currentChat.user_id === user.id ? (user.file_name ? url + user.file_name : Profile) : (currentChat.file_name ? url + currentChat.file_name : Profile)} />
                                                            <div className="chat-bubble">
                                                                <div className="typing">
                                                                    <div className="dot"></div>
                                                                    <div className="dot"></div>
                                                                    <div className="dot"></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        <div ref={scrollRef} />
                                    </div>
                                    <div className={'chat-box-bottom'}>
                                        <div className={'chat-sender'}>
                                            <TextBox
                                                className={'chat-textarea'}
                                                height={'2.5rem'}
                                                style={{ borderRadius: 10 }}
                                                value={newMessage}
                                                maxLength={400}
                                                onKeyDown={(e) => handleTyping(e)}
                                                onEnterKey={(e) => handleSubmit(e)}
                                                onValueChanged={(e) => setNewMessage(e.value)}
                                            />
                                            <div style={{ marginLeft: 10 }}>
                                                <Button
                                                    icon={paperPlaneSvg}
                                                    style={{ borderRadius: 10, border: 'none', color: 'white' }}
                                                    text='Илгээх'
                                                    className='acceptButton'
                                                    stylingMode="contained"
                                                    height={'2.5rem'}
                                                    onClick={handleSubmit}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <div className="no-conversation-text col-12">
                                    <div>Харилцах хүнээ сонгон чатаа эхлүүлнэ үү </div>
                                    <span style={{ fontSize: '2rem' }}><HiChatAlt2 /></span>
                                </div>
                            )}

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
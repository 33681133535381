import React, {useState, useEffect, useContext, useRef} from "react";
import ProposalService from "../../../services/api/proposal";
import notify from "devextreme/ui/notify";
import DataGrid, {
    Column,
    Grouping,
    HeaderFilter,
    Summary,
    Selection,
    Toolbar,
    ColumnChooser,
    GroupItem,
    FilterPanel,
    Export, ColumnFixing, Scrolling, TotalItem, Paging
} from "devextreme-react/data-grid";
import Button from "devextreme-react/button";
import {UserContext} from "../../../hooks/UserContext";
import {formatDate, numberWithCommas} from "../../../util";
import LoadPanelComponent from "../../budget/components/loadPanel";
import {Item} from "devextreme-react/tab-panel";
import {saveAs} from "file-saver-es";
import {BsFiletypeXls} from "react-icons/bs";
import {SiMicrosoftexcel} from "react-icons/si";
import {CgArrowsExpandRight, CgCompressRight, CgMaximize, CgMinimize} from "react-icons/cg";
import {Workbook} from "exceljs";
import {exportDataGrid} from "devextreme/excel_exporter";
import loaderGif from "../../../assets/imgs/tube-spinner.svg";

const ProposalMergeReport = (props) => {
    const [loadMe, setLoadMe] = useState(false);
    const {user} = useContext(UserContext);
    const [autoExpandAll, setAutoExpand] = useState(false);
    const [dataEcon2, setDataEcon2] = useState([]);
    const dataExpenseGridRef = useRef(null);
    const [fullscreen, setFullscreen] = useState(false)

    const loadAllBudget = async () => {
        try {
            setLoadMe(true);
            let allBpd = await ProposalService.getProposalReportFiscal(props.year, props.status, props.selectedOrg);
            setDataEcon2(allBpd);
            setLoadMe(false);
        } catch (e) {
            setLoadMe(false);
            notify(e.message, "error", 2000);
        }
    }

    useEffect(() => {
        loadAllBudget();
    }, [props.selectedOrg,props.status,props.year])

    const onExporting = (e) => {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Main sheet');
        const grid = e.component;
        worksheet.columns = [
            {width: 10}, {width: 100}, {width: 18}, {width: 18}, {width: 18}, {width: 18}, {width: 18}, {width: 18}, {width: 18}, {width: 18}, {width: 18}, {width: 18}, {width: 18}, {width: 18}, {width: 18}, {width: 18}, {width: 18}, {width: 18}, {width: 18}, {width: 18}, {width: 18}, {width: 18}, {width: 18}, {width: 18}, {width: 18}, {width: 18}, {width: 18}, {width: 18}, {width: 18}, {width: 18}, {width: 18}, {width: 18}, {width: 18}, {width: 18}, {width: 18}, {width: 18}, {width: 18}, {width: 18}, {width: 18}, {width: 18}
        ];
        exportDataGrid({
            component: e.component,
            worksheet,
            autoFilterEnabled: true,
            keepColumnWidths: false,
            topLeftCell: {row: 4, column: 2},
            customizeCell: ({gridCell, excelCell}) => {
                if (gridCell.rowType === 'header') {
                    excelCell.fill = {type: 'pattern', pattern: 'solid', fgColor: {argb: 'EEF0F4'}};
                    excelCell.font = {color: {argb: '15186A'}, name: 'Segoe UI Semibold', size: 8};
                }
                if (gridCell.rowType === 'group') {
                    if (gridCell.groupIndex === 0) {

                        excelCell.font = {color: {argb: 'F93A3A'}, name: 'Segoe UI Semibold', size: 8};
                        excelCell.font.fontWeight = 'bold';
                        if (excelCell._column._number !== 2) excelCell.alignment = {horizontal: 'right'};
                    }
                    if (gridCell.groupIndex === 1) {
                        excelCell.font = {
                            color: {argb: '2C77FF'},
                            fontWeight: 'bold',
                            name: 'Segoe UI Semibold',
                            size: 8
                        };
                        if (excelCell._column._number !== 2) excelCell.alignment = {horizontal: 'right'};
                    }
                    if ([2, 3, 4, 5].includes(gridCell.groupIndex)) {
                        excelCell.font = {
                            color: {argb: '021772'},
                            fontWeight: 'bold',
                            name: 'Segoe UI Semibold',
                            size: 8
                        };
                        if (excelCell._column._number !== 2) excelCell.alignment = {horizontal: 'right'};
                    } else if (gridCell.groupIndex > 5) {
                        excelCell.alignment = {wrapText: false};
                        if (excelCell._column._number !== 2) excelCell.alignment = {horizontal: 'right'};
                        excelCell.font = {fontWeight: 'bold', name: 'Segoe UI Semibold', size: 8};
                    }

                }
                if (gridCell.rowType === 'totalFooter' && excelCell.value) {
                    excelCell.font = {name: 'Segoe UI Semibold', size: 8};
                    excelCell.font.italic = true;
                }
                if (gridCell.rowType === 'data') {
                    excelCell.alignment = {wrapText: false};
                    excelCell.font = {name: 'Segoe UI', size: 8};
                    if (gridCell.column.dataField !== 'econ_category_id') {
                        excelCell.numFmt = '#,##0.00';
                        if(['E','C','D'].includes(excelCell._address[0])){
                            excelCell.alignment={horizontal: 'right'}
                        }
                    }
                }
                if (['agenda_name', 'event_name', 'econ_name_3', 'econ_name_4', 'econ_name_2', 'econ_name_1', 'econ_category_id', 'budget_project_status_name', 'min_name', 'org_name','org_name_1','org_name_2'].includes(gridCell.column.dataField)) {
                    if (excelCell._row && excelCell._row._cells && excelCell._row._cells[1].model && excelCell._row._cells[1].model.value) {
                        excelCell._row._cells[1].model.value = excelCell._row._cells[1].model.value.replace(": ", "");
                    }

                }
                if (gridCell.column.dataField === 'econ_name_3' || gridCell.column.dataField === 'econ_name_4' || gridCell.column.dataField === 'org_name' || gridCell.column.dataField === 'agenda_name' || gridCell.column.dataField === 'event_name') {
                    /* excelCell._row._cells[1].model.value = 'dddd';
                     excelCell._row.hidden = true;*/
                    if (excelCell._row && excelCell._row._cells && excelCell._row._cells[1].model && excelCell._row._cells[1].model.value === '') {
                        excelCell._row.hidden = true;
                    }
                }
                if(gridCell.rowType === 'group' && gridCell.groupIndex === 1) {
                    if(excelCell._column._number !== 2 ) excelCell.alignment = { horizontal: 'right',wrapText: false };
                    excelCell._row._cells[1].model.value = ` ${excelCell._row._cells[1].model.value}`
                }
                if(gridCell.rowType === 'group' && gridCell.groupIndex === 2) {
                    if(excelCell._column._number !== 2 ) excelCell.alignment = { horizontal: 'right',wrapText: false };
                    excelCell._row._cells[1].model.value = `  ${excelCell._row._cells[1].model.value}`
                }
                if(gridCell.rowType === 'group' && gridCell.groupIndex === 3) {
                    if(excelCell._column._number !== 2 ) excelCell.alignment = { horizontal: 'right',wrapText: false };
                    excelCell._row._cells[1].model.value = `   ${excelCell._row._cells[1].model.value}`
                }
                if(gridCell.rowType === 'group' && gridCell.groupIndex === 4) {
                    if(excelCell._column._number !== 2 ) excelCell.alignment = { horizontal: 'right',wrapText: false };
                    excelCell._row._cells[1].model.value = `    ${excelCell._row._cells[1].model.value}`
                }
                if(gridCell.rowType === 'group' && gridCell.groupIndex === 5) {
                    if(excelCell._column._number !== 2 ) excelCell.alignment = { horizontal: 'right',wrapText: false };
                    excelCell._row._cells[1].model.value = `     ${excelCell._row._cells[1].model.value}`
                }
                if(gridCell.rowType === 'group' && gridCell.groupIndex === 6) {
                    if(excelCell._column._number !== 2 ) excelCell.alignment = { horizontal: 'right',wrapText: false };
                    excelCell._row._cells[1].model.value = `      ${excelCell._row._cells[1].model.value}`
                }
                if(gridCell.rowType === 'group' && gridCell.groupIndex === 7) {
                    if(excelCell._column._number !== 2 ) excelCell.alignment = { horizontal: 'right',wrapText: false };
                    excelCell._row._cells[1].model.value = `       ${excelCell._row._cells[1].model.value}`
                }
                if(gridCell.rowType === 'group' && gridCell.groupIndex === 8) {
                    if(excelCell._column._number !== 2 ) excelCell.alignment = { horizontal: 'right',wrapText: false };
                    excelCell._row._cells[1].model.value = `        ${excelCell._row._cells[1].model.value}`
                }
                if(gridCell.rowType === 'group' && gridCell.groupIndex === 9) {
                    if(excelCell._column._number !== 2 ) excelCell.alignment = { horizontal: 'right',wrapText: false };
                    excelCell._row._cells[1].model.value = `         ${excelCell._row._cells[1].model.value}`
                }
                if(gridCell.rowType === 'data') {
                    excelCell._row._cells[1].model.value = `          ${excelCell._row._cells[1].model.value}`
                }

            },
        }).then(() => {
            Object.assign(
                worksheet.getRow(2).getCell(2),
                {
                    value: user.organization.name + ` ${props.year} оны төсвийн саналын нэгтгэл тайлан`,
                    font: {bold: true, size: 16, underline: 'double'}
                }
            );
        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer], {type: 'application/octet-stream'}), "OG-" + user.organization.name + "-" + formatDate(new Date()) + ".xlsx");
            });
        });
        e.cancel = true;

    }

    const onExportingFiscalB = (e) => {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Main sheet');
        const grid = e;
        let groupIndexesWithSingleItem = [];
        grid.beginUpdate();
        grid.columnOption('sep_code', 'visible', true);
        grid.columnOption('sep_name', 'visible', true);
        grid.columnOption('prg_code', 'visible', true);
        grid.columnOption('prg_name', 'visible', true);
        grid.columnOption('min_code', 'visible', true);
        grid.columnOption('min_name', 'visible', true);
        grid.columnOption('age_name', 'visible', true);

        grid.columnOption('pro_code', 'visible', true);
        grid.columnOption('pro_name', 'visible', true);
        grid.columnOption('org_code', 'visible', true);
        grid.columnOption('org_name_export', 'visible', true);

        grid.columnOption('ded_code', 'visible', true);
        grid.columnOption('ded_name', 'visible', true);
        grid.columnOption('econ_code', 'visible', true);
        grid.columnOption('eco_name', 'visible', true);

        grid.columnOption('budget_project_status_name', 'visible', false);
        grid.columnOption('org_name_1', 'visible', false);
        grid.columnOption('org_name_2', 'visible', false);
        grid.columnOption('org_name', 'visible', false);
        grid.columnOption('agenda_name', 'visible', false);
        grid.columnOption('event_name', 'visible', false);
        grid.columnOption('econ_name_1', 'visible', false);
        grid.columnOption('econ_name_2', 'visible', false);
        grid.columnOption('econ_name_3', 'visible', false);
        grid.columnOption('econ_name_4', 'visible', false);
        grid.columnOption('econ_name', 'visible', false);
        grid.columnOption('amount', 'visible', false);
        grid.columnOption('ttz_amount', 'visible', false);

        grid.columnOption('sep_code', 'visibleIndex', 11);
        grid.columnOption('min_code', 'visibleIndex', 12);
        grid.columnOption('pro_code', 'visibleIndex', 13);
        grid.columnOption('org_code', 'visibleIndex', 14);
        grid.columnOption('prg_code', 'visibleIndex', 15);
        grid.columnOption('ded_code', 'visibleIndex', 16);
        grid.columnOption('econ_code', 'visibleIndex', 17);
        grid.columnOption('sep_name', 'visibleIndex', 18);
        grid.columnOption('min_name', 'visibleIndex', 19);
        grid.columnOption('age_name', 'visibleIndex', 20);
        //age name add
        grid.columnOption('pro_name', 'visibleIndex', 21);
        grid.columnOption('prg_name', 'visibleIndex', 22);
        grid.columnOption('org_name_export', 'visibleIndex', 23);
        grid.columnOption('ded_name', 'visibleIndex', 24);
        grid.columnOption('eco_name', 'visibleIndex', 25);
        grid.columnOption('tez_amount', 'caption', 'prep111');
        worksheet.columns = [
            {width: 10}, {width: 10}, {width: 10}, {width: 15}, {width: 13}, {width: 14}, {width: 15}, {width: 13}, {width: 25}, {width: 25}, {width: 25}, {width: 25}, {width: 25}, {width: 25}, {width: 25},{width: 18}
        ];
        exportDataGrid({
            component: e,
            worksheet,
            autoFilterEnabled: false,
            keepColumnWidths: false,
            topLeftCell: {row: 1, column: 1},
            customizeCell: ({gridCell, excelCell}) => {
                if (gridCell.rowType === 'header') {
                    excelCell.fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: {argb: (['sep_name', 'min_name', 'pro_name', 'prg_name', 'org_name_export', 'ded_name', 'eco_name', 'age_name'].includes(gridCell.column.dataField) ? 'EEF0F4' : 'FFFF00')}
                    };
                    excelCell.font = {color: {argb: '15186A'}, name: 'Segoe UI Semibold', size: 8};
                    excelCell.border = {
                        top: {style: 'thin', color: {argb: '2c2c2c'}},
                        left: {style: 'thin', color: {argb: '2c2c2c'}},
                        bottom: {style: 'thin', color: {argb: '2c2c2c'}},
                        right: {style: 'thin', color: {argb: '2c2c2c'}}
                    }
                }
                if (gridCell.rowType === 'group') {
                    groupIndexesWithSingleItem.push(excelCell.fullAddress.row);
                }
                if (gridCell.rowType === 'data') {
                    excelCell.alignment = {wrapText: false};
                    excelCell.font = {name: 'Segoe UI', size: 8};
                    if (gridCell.column.dataField !== 'econ_name') {
                        excelCell.numFmt = '#,##0.00';
                    }

                }
                if (gridCell.rowType === 'totalFooter') {
                    excelCell.value = '';
                    worksheet.spliceRows(excelCell.row, 1);
                }
                //if(gridCell.rowType === "group"){ excelCell.hidden = { wrapText: false,hidden: true};}
            },
        }).then(() => {
            let uniqueIds = groupIndexesWithSingleItem.filter((element, index) => {
                return groupIndexesWithSingleItem.indexOf(element) === index;
            });
            //console.log(uniqueIds);
            uniqueIds.sort((a, b) => b - a);
            for (let i = 0; i < uniqueIds.length; i++) {
                worksheet.getRow(uniqueIds[i] + 1).outlineLevel = undefined;
                worksheet.spliceRows(uniqueIds[i], 1);
            }
            //worksheet.unMergeCells('A1:P1');
            worksheet.spliceRows(2, 0);
            //worksheet.spliceRows(totalFooterRowIdx-1, 1);
        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer], {type: 'application/octet-stream'}), "ProposalFiscalB" + formatDate(new Date()) + ".xlsx");

                grid.columnOption('sep_code', 'visible', false);
                grid.columnOption('sep_name', 'visible', false);
                grid.columnOption('prg_code', 'visible', false);
                grid.columnOption('prg_name', 'visible', false);

                grid.columnOption('min_code', 'visible', false);
                grid.columnOption('min_name', 'visible', false);
                grid.columnOption('age_name', 'visible', false);
                grid.columnOption('pro_code', 'visible', false);
                grid.columnOption('pro_name', 'visible', false);
                grid.columnOption('org_code', 'visible', false);
                grid.columnOption('org_name_export', 'visible', false);

                grid.columnOption('ded_code', 'visible', false);
                grid.columnOption('ded_name', 'visible', false);
                grid.columnOption('econ_code', 'visible', false);
                grid.columnOption('eco_name', 'visible', false);


                grid.columnOption('org_name_1', 'visible', true);
                grid.columnOption('org_name_2', 'visible', true);
                grid.columnOption('org_name', 'visible', true);
                grid.columnOption('agenda_name', 'visible', true);
                grid.columnOption('event_name', 'visible', true);
                grid.columnOption('econ_name_1', 'visible', true);
                grid.columnOption('econ_name_2', 'visible', true);
                grid.columnOption('econ_name_3', 'visible', true);
                grid.columnOption('econ_name_4', 'visible', true);
                grid.columnOption('econ_name', 'visible', true);
                grid.columnOption('amount', 'visible', true);
                grid.columnOption('ch_month1', 'visible', true);
                grid.columnOption('ttz_amount', 'visible', true);
                grid.columnOption('tez_amount', 'visible', true);
                grid.columnOption('sep_code', 'visibleIndex', 14);
                grid.columnOption('min_code', 'visibleIndex', 15);
                grid.columnOption('pro_code', 'visibleIndex', 16);
                grid.columnOption('org_code', 'visibleIndex', 17);
                grid.columnOption('prg_code', 'visibleIndex', 18);
                grid.columnOption('ded_code', 'visibleIndex', 19);
                grid.columnOption('econ_code', 'visibleIndex', 20);

                grid.columnOption('sep_name', 'visibleIndex', 21);
                grid.columnOption('min_name', 'visibleIndex', 22);
                grid.columnOption('age_name', 'visibleIndex', 23);
                //age name add
                grid.columnOption('pro_name', 'visibleIndex', 24);
                grid.columnOption('prg_name', 'visibleIndex', 25);
                grid.columnOption('org_name_export', 'visibleIndex', 26);
                grid.columnOption('ded_name', 'visibleIndex', 27);
                grid.columnOption('eco_name', 'visibleIndex', 28);



                grid.endUpdate();
            });


        });
        e.cancel = true;
    }

    const onCellPreparedHandler = (e) => {
        if (e.rowType === 'header') {
            e.cellElement.wordWrapEnabled = true;
            e.cellElement.style.fontFamily = "Segoe UI";
            e.cellElement.style.fontWeight = "bold";
            e.cellElement.style.color = "#15186A";
            e.cellElement.style.alignment = "center";
            e.cellElement.style.backgroundColor = "#EEF0F4";
            e.cellElement.style.borderColor = "#D4DCEC";
            e.cellElement.style.fontSize = "12px";
            e.cellElement.style.verticalAlign = "middle";
        } else if (e.rowType === 'group') {
            e.cellElement.style.fontFamily = "Segoe UI"
            //e.cellElement.wordWrapEnabled = true;
            switch (e.row.groupIndex) {
                case 0:
                    return e.cellElement.style.color = "#F93A3A"
                case 1:
                    return e.cellElement.style.color = "#2C77FF"
                case 2:
                    return e.cellElement.style.color ="#021772"
                case 3:
                    return e.cellElement.style.color ="#0ede12"
                case 4:
                    return e.cellElement.style.color ="#366691"
                 default:
                    return e.cellElement.style.color = "black"
            }
           //e.cellElement.style.color = (e.row.groupIndex === 0 ? "#F93A3A" : ((e.row.groupIndex && e.row.groupIndex === 1) ? "#2C77FF" : 'black'))
            //e.cellElement.style.color = (e.row.groupIndex === 0 ? "#F93A3A" : ((e.row.groupIndex && e.row.groupIndex===1) ? "#2C77FF" : 'black'))
            e.cellElement.style.backgroundColor = "#fff"
            e.cellElement.style.fontWeight = "bold";
            e.cellElement.style.fontSize = "12px"
            e.cellElement.style.borderColor = "#D4DCEC"
            /*if(e.column.dataField === "ch_month1") {
                e.cellElement.style.color= '#F93A3A'
            }*/

        } else if (e.rowType === "data") {
            e.cellElement.style.fontFamily = 'Segoe UI'
            e.cellElement.style.fontWeight = 'normal'
            e.cellElement.style.color = "#000000"
            e.cellElement.style.verticalAlign = "middle";
        }
    }
    const groupCell = (e) => {
        return <div>{e.data.displayValue}</div>
    }
    const onRowPrepared = (args) => {
        if (args.rowType == 'group' && (args.values[args.groupIndex] == "" || args.values[args.groupIndex] == null)) {
            args.rowElement.hidden = true;
            if (!args.component.isRowExpanded(args.key))
                args.component.expandRow(args.key)
        }
    }
    const requestFullscreen = () => {
        if (document.fullscreenElement) document.exitFullscreen();
        else document.getElementById("wrapper").requestFullscreen();
        // setFullscreen(prev=>!prev)
    }

    const calculateArea = (rowData, field) => {
        return rowData?.econ_name_1 === '2-Нийт зарлага ба цэвэр зээлийн дүн' && rowData[`${field}`]*1;
    }
    const calculateAreaSummary = (options) => {
        if(options.name.substring(0,3) === 'sum')   {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0;
            } else if (options.summaryProcess === 'calculate') {
                options.totalValue += isNaN(calculateArea(options.value,options.name.split("sum_").pop())) ? 0 : calculateArea(options.value,options.name.split("sum_").pop()) ;
            }
        }
        else {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0;
            } else if (options.summaryProcess === 'calculate') {
                if (options.groupIndex < 6) {
                    options.totalValue += isNaN(calculateArea(options.value, options.name)) ? 0 : calculateArea(options.value, options.name);
                } else {
                    options.totalValue += isNaN(options.value[`${options.name}`] * 1) ? 0 : options.value[`${options.name}`] * 1
                }

            }
        }
    }

    return (
        <div id="loadMe" style={{padding: '10px'}}>
            <LoadPanelComponent position={{of: '#loadMe'}} visible={loadMe}/>
            <div className="offer" id="wrapper" height="100%" width="100%">
                <DataGrid dataSource={dataEcon2} ref={dataExpenseGridRef} hoverStateEnabled={true}
                          columnWidth={true}
                          allowColumnResizing={true}
                          loadPanel={{
                              enabled: true,
                              text: '',
                              shadingColor: "rgba(0,0,0,0.4)",
                              showPane: false,
                              indicatorSrc: loaderGif
                          }}
                          height={"90vh"}
                          width={'100%'}
                          wordWrapEnabled={true}
                          columnResizingMode={'nextColumn'}
                          paging={{
                              pageSize: 200,
                          }}
                          style={{marginTop: 5, overflowY: "auto", overflowX: "hidden",}}
                          showBorders={true}
                          showRowLines={true}
                          twoWayBindingEnabled={true}
                          sorting={{mode: 'none'}}
                          showColumnLines={true}
                          showColumnHeaders={true}
                          onCellPrepared={onCellPreparedHandler} onRowPrepared={onRowPrepared}
                          onExporting={onExporting}
                >
                    <Paging enabled={true}/>
                    <ColumnFixing enabled={false}/>
                    <FilterPanel
                        visible={false}
                        texts={{clearFilter: "Шүүлтүүр цэвэрлэх", createFilter: "Шүүх"}}
                    />
                    <HeaderFilter visible={true} allowSearch={true}
                                  texts={{cancel: 'Болих', ok: 'Шүүх', emptyValue: 'Хоосон утга'}}/>
                    <Selection mode="single"/>
                    <Export enabled={true}/>
                    <Scrolling columnRenderingMode="virtual"/>
                    <Toolbar>
                        <Item location="before">
                            <span>Мөнгөн дүн: мян ₮</span>
                        </Item>
                        <Item>
                            <div style={{display: 'flex', justifyContent: 'space-between', gap: '5px'}}>
                                <Button
                                    onClick={(e) => setAutoExpand(prev => !prev)}
                                    render={() => {
                                        return autoExpandAll ? (
                                                <CgCompressRight size={"1rem"} color="#172B4D"/>)
                                            :
                                            (
                                                <CgArrowsExpandRight size={"1rem"} color="#172B4D"/>
                                            )
                                    }}
                                    hint={autoExpandAll ? 'Багасгах' : 'ЭЗА дэлгэрэнгүй харуулах'}
                                >
                                </Button>
                                <Button
                                    className="grid-button"
                                    onClick={() => {
                                        requestFullscreen()
                                        setFullscreen(prev => !prev)
                                    }}
                                    render={() => {
                                        return fullscreen ?
                                            (<CgMinimize size={"1rem"} color="#172B4D"/>)
                                            :
                                            (<CgMaximize size={"1rem"} color="#172B4D"/>)
                                    }}
                                    hint={fullscreen ? 'Багасгах' : 'Дэлгэцэнд бүтэн харуулах'}
                                >
                                </Button>
                            </div>
                        </Item>
                        <Item name="exportButton"
                              render={(e) => {
                                  return <Button
                                      //onOptionChanged={setExportType('FiscalB')}
                                      onClick={e.options.onClick}
                                      icon="xlsxfile"
                                      render={() => {
                                          return <BsFiletypeXls size={"1rem"} color="#172B4D"/>
                                      }}
                                      hint={'Excel татах'}
                                      style={{color: "red"}}>
                                  </Button>
                              }}
                        />
                        <Item>
                            <Button
                                className="grid-button"
                                onClick={() => {
                                    onExportingFiscalB(dataExpenseGridRef.current.instance)
                                }}
                                render={() => {
                                    return <SiMicrosoftexcel size={"1rem"} color="#172B4D"/>
                                }}
                                hint={'FiscalB форматаар төсвийн санал excel татах'}
                            >
                            </Button>
                        </Item>
                        <Item name="columnChooserButton"
                              render={(e) => {
                                  return <Button onClick={e.options.onClick} size='1rem' id='columnChooserButton'
                                                 icon="columnchooser"/>
                              }}
                        />
                    </Toolbar>
                    <Grouping autoExpandAll={autoExpandAll}
                              contextMenuEnabled={false}
                              expandMode="rowClick"/>
                    <ColumnChooser
                        enabled={true} height={"100%"} allowSearch={true}
                        mode="select"
                        title={'Багана сонгох'}
                    />
                    <Column dataField="budget_project_status_name" caption="" allowEditing={false} sortOrder={'asc'} visibleIndex={0}
                            groupIndex={0} groupCellComponent={groupCell} minWidth={'18rem'} width={'auto'} autoExpandGroup={true}
                            cssClass={'dx-datagrid-group-space'} showInColumnChooser={false}>
                    </Column>
                    <Column dataField="org_name_1" caption="" allowEditing={false} sortOrder={'asc'} visibleIndex={1}
                            groupIndex={1} groupCellComponent={groupCell} minWidth={'18rem'} width={'auto'} showInColumnChooser={false} autoExpandGroup={true}>
                    </Column>
                    <Column dataField="org_name_2" caption="" allowEditing={false} sortOrder={'asc'} visibleIndex={2}
                            groupIndex={2} groupCellComponent={groupCell} minWidth={'18rem'} width={'auto'} showInColumnChooser={false} autoExpandGroup={true}>
                    </Column>
                    <Column dataField="org_name" caption="" allowEditing={false} sortOrder={'asc'}
                            showInColumnChooser={false} visibleIndex={3}
                            groupIndex={3} groupCellComponent={groupCell} minWidth={'18rem'} width={'auto'}>
                    </Column>
                    <Column dataField="agenda_name" caption="" allowEditing={false} sortOrder={'asc'}
                            showInColumnChooser={false} visibleIndex={4}
                            groupIndex={4} groupCellComponent={groupCell} width={'1'}>
                    </Column>
                    <Column dataField="event_name" caption="" allowEditing={false} cssClass={'dx-datagrid-group-space'}
                            sortOrder={'asc'} showInColumnChooser={false} visibleIndex={5}
                            groupCellComponent={groupCell} width={'1'} groupIndex={5} alignment={'left'}>
                    </Column>
                    <Column dataField="econ_name_1" caption="" groupIndex={6}
                           showInColumnChooser={false} visibleIndex={6}
                            groupCellComponent={groupCell} width={'1'} allowEditing={false}>
                    </Column>
                    <Column dataField="econ_name_2" caption="" groupIndex={7}
                            showInColumnChooser={false} visibleIndex={7}
                            groupCellComponent={groupCell} width={'1'} allowEditing={false}>
                    </Column>
                    <Column dataField="econ_name_3" caption="" groupIndex={8}
                            showInColumnChooser={false} visibleIndex={8}
                            groupCellComponent={groupCell} width={'1'} allowEditing={false}>
                    </Column>
                    <Column dataField="econ_name_4" caption="" groupIndex={8}
                            showInColumnChooser={false} visibleIndex={9}
                            groupCellComponent={groupCell} width={'1'} allowEditing={false}>
                    </Column>
                    <Column dataField="econ_name" caption="Үзүүлэлт" fixed={true} fixedPosition={'left'}
                            allowResizing={false} minWidth={'18rem'} width={'auto'} showInColumnChooser={false}
                            visibleIndex={10}
                            allowEditing={false}>
                    </Column>
                    <Column dataField={`amount`} format="#,##0.00" alignment="right" dataType="number" visibleIndex={11} fixed={true}
                            caption={`ТШЗ санал`} allowFiltering={false} allowResizing={true}>
                    </Column>
                    <Column dataField={`ttz_amount`} format="#,##0.00" alignment="right" dataType="number" visibleIndex={12}
                            caption={`ТТЗ санал`} allowFiltering={false} allowResizing={true}>
                    </Column>
                    <Column dataField={`tez_amount`} format="#,##0.00" alignment="right" dataType="number" visibleIndex={13}
                            caption={`ТЕЗ санал`} allowFiltering={false} allowResizing={true}>
                    </Column>
                    <Column dataField={`sep_code`} caption={'sep_code'} alignment="right" dataType="string"
                            allowFiltering={false} allowResizing={false} visible={false} showInColumnChooser={false}
                            visibleIndex={14}/>
                    <Column dataField={`sep_name`} caption={'sep_name'} alignment="right" dataType="string"
                            allowFiltering={false} allowResizing={false} visible={false} showInColumnChooser={false}
                            visibleIndex={15}/>
                    <Column dataField={`min_code`} caption={'min_code'} alignment="right" dataType="string"
                            allowFiltering={false} allowResizing={false} visible={false} showInColumnChooser={false}
                            visibleIndex={16}/>
                    <Column dataField={`min_name`} caption={'min_name'} alignment="right" dataType="string"
                            allowFiltering={false} allowResizing={false} visible={false} showInColumnChooser={false}
                            visibleIndex={17}/>
                    <Column dataField={`pro_code`} caption={'pro_code'} alignment="right" dataType="string"
                            allowFiltering={false} allowResizing={false} visible={false} showInColumnChooser={false}
                            visibleIndex={18}/>
                    <Column dataField={`pro_name`} caption={'pro_name'} alignment="right" dataType="string"
                            allowFiltering={false} allowResizing={false} visible={false} showInColumnChooser={false}
                            visibleIndex={19}/>
                    <Column dataField={`org_code`} caption={'org_code'} alignment="right" dataType="string"
                            allowFiltering={false} allowResizing={false} visible={false} showInColumnChooser={false}
                            visibleIndex={20}/>
                    <Column dataField={`org_name_export`} caption={'org_name'} alignment="right" dataType="string"
                            allowFiltering={false} allowResizing={false} visible={false} showInColumnChooser={false}
                            visibleIndex={21}/>
                    <Column dataField={`prg_code`} caption={'prg_code'} alignment="right" dataType="string"
                            allowFiltering={false} allowResizing={false} visible={false} showInColumnChooser={false}
                            visibleIndex={22}/>
                    <Column dataField={`prg_name`} caption={'prg_name'} alignment="right" dataType="string"
                            allowFiltering={false} allowResizing={false} visible={false} showInColumnChooser={false}
                            visibleIndex={23}/>
                    <Column dataField={`ded_code`} caption={'ded_code'} alignment="right" dataType="string"
                            allowFiltering={false} allowResizing={false} visible={false} showInColumnChooser={false}
                            visibleIndex={24}/>
                    <Column dataField={`ded_name`} caption={'ded_name'} alignment="right" dataType="string"
                            allowFiltering={false} allowResizing={false} visible={false} showInColumnChooser={false}
                            visibleIndex={25}/>
                    <Column dataField={`econ_code`} caption={'eco_code'} alignment="right" dataType="string"
                            allowFiltering={false} allowResizing={false} visible={false} showInColumnChooser={false}
                            visibleIndex={26}/>
                    <Column dataField={`eco_name`} caption={'eco_name'} alignment="right" dataType="string"
                            allowFiltering={false} allowResizing={false} visible={false} showInColumnChooser={false}
                            visibleIndex={27}/>
                    <Column dataField={`age_name`} caption={'age_name'} alignment="right" dataType="string"
                            allowFiltering={false} allowResizing={false} visible={false} showInColumnChooser={false}
                            visibleIndex={28}/>
                    <Export enabled={true} allowExportSelectedData={false}/>
                    <Summary calculateCustomSummary={calculateAreaSummary}>
                        <GroupItem name="amount"
                                   summaryType="custom"
                                   showInGroupFooter={false} alignByColumn={true}
                                   showInColumn="amount"
                                   customizeText={(data) => {
                                       return `${numberWithCommas(data.value)}`
                                   }}/>
                        <GroupItem name="ttz_amount"
                                   summaryType="custom"//column="month1" summaryType="sum"
                                   showInGroupFooter={false} alignByColumn={true}
                                   showInColumn="ttz_amount" customizeText={(data) => {
                            return `${numberWithCommas(data.value)}`
                        }}/>
                        <GroupItem name="tez_amount"
                                   summaryType="custom"//column="month1" summaryType="sum"
                                   showInGroupFooter={false} alignByColumn={true}
                                   showInColumn="tez_amount" customizeText={(data) => {
                            return `${numberWithCommas(data.value)}`
                        }}/>
                        <TotalItem name="sum_amount"
                                   summaryType="custom"
                                   showInGroupFooter={false} alignByColumn={true}
                                   showInColumn="amount"
                                   customizeText={(data) => {
                                       return `${numberWithCommas(data.value)}`
                                   }}/>
                        <TotalItem name="sum_ttz_amount"
                                   summaryType="custom"//column="month1" summaryType="sum"
                                   showInGroupFooter={false} alignByColumn={true}
                                   showInColumn="ttz_amount" customizeText={(data) => {
                            return `${numberWithCommas(data.value)}`
                        }}/>
                        <TotalItem name="sum_tez_amount"
                                   summaryType="custom"//column="month1" summaryType="sum"
                                   showInGroupFooter={false} alignByColumn={true}
                                   showInColumn="tez_amount" customizeText={(data) => {
                            return `${numberWithCommas(data.value)}`
                        }}/>
                    </Summary>
                </DataGrid>
            </div>
        </div>
    )
}

export default ProposalMergeReport

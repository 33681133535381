import React, {useEffect, useState} from "react";
import ScrollView from "devextreme-react/scroll-view";
import Button from "devextreme-react/button";
import {Popup} from "devextreme-react/popup";
import fcsService from "../../../../services/api/fcs";
import notify from "devextreme/ui/notify";
import TextBox from "devextreme-react/text-box";

const ArEndMonth = (props) => {
    const goMonth = props.goMonth
    const jr_key = props.jr_key
    const [loader, setLoader] = useState(false);
    const [reportGL, setReportGL] = useState([]);
    const modalVisibleEndMonth = props.visableEndMonth

    const setModalVisibleEndMonth = props.setVisableEndMonth
    const [dataFcsMonth, setFcsMonth] = useState([]);
const [pass,setPass]=useState('')

    useEffect(() => {
        fcsMonthList();
    }, [])

    const fcsMonthList = async () => {
        try {
            setLoader(true);
            const fcsMonth = await fcsService.getAllFcsMonth(jr_key);
            setReportGL(fcsMonth);

            return setLoader(false)
        } catch (e) {
            console.error(e);
        }
    }
    const changePass = async (e) => {
        setPass(e)
    }
    const goEndMonth = async () => {

        const result = await fcsService.getAllFcsMonth(jr_key);

        try {
            let error = 0
            result.map(e => {

                (e.month < 13) ? (e.month = e.month + 1) : error = error + 1

            })

            if (error > 0) return notify("Жилийн хаалт хийнэ үү", "error")

            await fcsService.editFcsMonth(result[0]);

            setModalVisibleEndMonth(false);

            fcsMonthList()
            setPass('')
            notify("Амжилттай ", "success");
            goMonth()
        } catch (e) {
            console.log(e)
            notify("Алдаа гарлаа", "error")
        }


    }


    return (


        <div>

            <Popup
                width={500}
                height={200}
                minWidth={'50%'}
                maxWidth={'50%'}
                minHeight={'40%'}
                visible={modalVisibleEndMonth}
                title={'Сарын хаалт хийх'}
                onHiding={() => setModalVisibleEndMonth(false)}
                resizeEnabled={true}
                dragEnabled={true}
                showTitle={true}
                hideOnOutsideClick={true}
            >
                <ScrollView width='100%' height='100%'>
                    <div className={'card-body'}>
                        <div style={{marginTop: 10}}>
                            {reportGL[0] &&
                                <div>
                                    <div style={{display: 'flex', flexDirection: 'row', marginBottom: 20}}>
                                        <div style={{display: 'flex', flexDirection: 'row', marginLeft: '10%'}}>
                                            <span style={{fontSize: 14, fontWeight: 500}}>Хаалт хийх он,сар: </span>
                                            <span style={{fontSize: 14, fontWeight: 500, marginLeft: 10}}>
                                             {reportGL[0].year}-{reportGL[0].month}    </span>

                                            <span style={{fontSize: 14, fontWeight: 500, marginLeft: 10}}>
                                   Журнал : {reportGL[0].descr}

                                </span>
                                        </div>


                                    </div>
                                    <div style={{display: 'flex', flexDirection: 'row', marginLeft: '10%'}}>
                                        <span style={{fontSize: 14, fontWeight: 500}}>Байгууллага: </span>
                                        <span style={{fontSize: 14, fontWeight: 500, marginLeft: 5}}>
                                        {reportGL[0].organization_model.name}</span>

                                        <span>
                                    <div className="dx-field-value">
                                        <TextBox value={pass} style={{width:100}}
                                              //   mode="password"
                                                 placeholder="Нууц үг"
                                                 format={'*****'}
                                                 onValueChanged={(e) => changePass(e.value)}

                                        />

                                    </div>
                                            </span>
                                    </div>
                                </div>
                            }
                            <div style={{display: 'flex', flexDirection: 'row', marginBottom: 20}}>


                                <div style={{display: 'flex', marginLeft: 10, marginRight: 10, marginTop: 5}}>


                                </div>

                            </div>
                            <div className="col-12 row"
                                 style={{justifyContent: "flex-end", gap: '2rem', padding: '5px 15px'}}>
                                <Button text="Буцах" onClick={() =>
                                {
                                    setModalVisibleEndMonth(false)
                                    setPass('')
                                }

                                }
                                        icon="close" width={150} type="default"
                                        style={{alignment: "center", borderRadius: '7px'}}/>
                                <Button text="хаалт хийх" onClick={() => goEndMonth()}
                                     disabled={pass!='123'}   width={150} type="default" style={{alignment: "center", borderRadius: '7px'}}/>

                            </div>

                        </div>
                    </div>
                </ScrollView>
            </Popup>
        </div>


    )
}

export default ArEndMonth

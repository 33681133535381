import React, { useState, useEffect } from "react"
import notify from "devextreme/ui/notify"
import _ from "lodash"
import Chart, {
  ArgumentAxis,
  CommonSeriesSettings,
  Crosshair,
  Label,
  Legend,
  LoadingIndicator,
  Point,
  ScrollBar,
  Series,
  Strip,
  Tooltip,
  ValueAxis,
  ZoomAndPan,
} from "devextreme-react/chart"
import BudgetService from "../../../../../services/api/budget"
import { blueColorGradient, getStripValues, numberWithCommas, redColorGradient } from "../../../../../util"
import LoadPanelComponent from "../../../../budget/components/loadPanel"
import EmptyData from "../../../../budget/components/empty"

const InvestExecutionByYear = () => {
  const [chartData, setChartData] = useState([])
  const [loader, setLoader] = useState(true)

  useEffect(() => {
    loadData()
  }, [])

  const loadData = async () => {
    try {
      let res = await BudgetService.getInvestExecutionByYear()
      setChartData(res)
    } catch (e) {
      notify(e.message, "error", 2000)
    } finally {
      setLoader(false)
    }
  }

  const customizeTooltip = (arg) => {
    if (arg.seriesName === "Төсвийн өөрчлөлт") {
      let r = _.find(chartData, {
        budget_year: (arg.argument * 1 - 1).toString(),
      })
      let q = (
        arg.value - (r && r.tusuv) > 0
          ? arg.value / (r && r.tusuv)
          : (r && r.tusuv) / arg.value
      ).toFixed(2)
      let p = ((arg.value * 100) / (r && r.tusuv) - 100).toFixed(2)
      let d = arg.value - (r && r.tusuv)
      let f = d < 0 ? "бага" : "их"
      if (isFinite(q) && !isNaN(q)) {
        return {
          html: `<div class="tooltip-header">
            <span>Төсөв ${arg.argumentText} он:</span>
            <span>${numberWithCommas(arg.value / 1000000)} </span>
            </div>
            <hr/>
            <b><i>Өмнөх жилээс</i></b><br/>
            <span class="tooltip-series-name">Зөрүү:</span> ${numberWithCommas(d / 1000000)}<br/> 
            <span class="tooltip-series-name">Хувь:</span> ${p}%<br/> 
            <span class="tooltip-series-name">Өөрчлөлт:</span> ${q} дахин ${f}`,
        }
      } else {
        return {
          html: `<div class="tooltip-header">
          <span>Төсөв ${arg.argumentText} он:</span>
          <span>${numberWithCommas(arg.value / 1000000)} </span>
          </div>`,
        }
      }
    } else if (arg.seriesName === "Гүйцэтгэлийн өөрчлөлт") {
      let r = _.find(chartData, {
        budget_year: (arg.argument * 1 - 1).toString(),
      })
      let q = (
        arg.value - (r && r.guitsetgel) > 0
          ? arg.value / (r && r.guitsetgel)
          : (r && r.guitsetgel) / arg.value
      ).toFixed(2)
      let p = ((arg.value * 100) / (r && r.guitsetgel) - 100).toFixed(2)
      let d = arg.value - (r && r.guitsetgel)
      let f = d < 0 ? "бага" : "их"
      let r1 = _.find(chartData, { budget_year: (arg.argument * 1).toString() })
      let u = (r1 && r1.tusuv) - arg.value
      let u1 = ((arg.value * 100) / (r1 && r1.tusuv)).toFixed(2)
      if (isFinite(q) && !isNaN(q)) {
        return {
          html: `<div class="tooltip-header">
          <span>Гүйцэтгэл ${arg.argumentText} он:</span>
          <span>${numberWithCommas(arg.value / 1000000)}</span>
          </div>
          <hr/>
          <b><i>Төсвөөс </b></i><br/>
          <span class="tooltip-series-name">Зөрүү: </span>${numberWithCommas(u / 1000000)} <br/>
          <span class="tooltip-series-name" style={{marginBottom: 10}}>Xувь: </span>${u1}% <br/>
          <hr/>
          <b><i>Өмнөх жилээс </b></i><br/>
          <span class="tooltip-series-name">Зөрүү: </span>${numberWithCommas(d / 1000000)} <br/>
          <span class="tooltip-series-name">Хувь: </span>${p}% <br/>
          <span class="tooltip-series-name">Өөрчлөлт: </span>${q} дахин ${f}<br/> `,
        }
      } else {
        return {
          html: `<div class="tooltip-header">
          <span>Төсөв ${arg.argumentText} он:</span>
          <span>${numberWithCommas(arg.value / 1000000)} </span>
          </div>`,
        }
      }
    } else if (arg.seriesName === "Гүйцэтгэл") {
      return {
        html: `<div class="tooltip-header">
        <span>Гүйцэтгэл ${arg.argumentText} он:</span>
        <span>${numberWithCommas(arg.value / 1000000)}</span>
        </div>
        <hr/>
        <b><i>Төсвөөс </b></i><br/>
        <span class="tooltip-series-name">Зөрүү: </span> ${numberWithCommas((arg.point.data.tusuv - arg.value) / 1000000)}<br/> 
        <span class="tooltip-series-name">Хувь:</span> ${((arg.value / 1000000) / (arg.point.data.tusuv / 1000000) * 100).toFixed(2)}%`
      }
    }
    else {
      return {
        html: `<div class="tooltip-header">
        <span>${arg.seriesName} ${arg.argumentText} он:</span>
        <span>${numberWithCommas(arg.value / 1000000)}</span>
        </div>
        `,
      }
    }
  }

  if (chartData.length === 0 && !loader) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          family: "Segoe UI",
        }}
      >
        <EmptyData />
      </div>
    )
  }

  const handleLegend = (e) => {
    if (e.target.isVisible()) {
      e.target.hide();
    } else {
      e.target.show();
    }
  }

  function markerTemplate(item) {
    const color = item.series.isVisible() ? item.marker.fill : '#888';
    return (
      <svg>
        <rect x={0} y={0} width={12} height={12} fill={color}
          rx={item.text.includes('өөрчлөлт') ? 6 : 0}
          ry={item.text.includes('өөрчлөлт') ? 6 : 0}></rect>
      </svg>
    );
  }

  function customizeColor(items) {
    if (items.seriesName === 'Гүйцэтгэл') {
      return { color: blueColorGradient[items.index] }
    } else if (items.seriesName === 'Хөрөнгийн зардал') {
      return { color: redColorGradient[items.index] }
    }
  }

  return (
    <div id="load98">
      <LoadPanelComponent position={{ of: "#load98" }} visible={loader} />
      <Chart
        dataSource={chartData}
        onLegendClick={handleLegend}
        customizePoint={customizeColor}
      >
        <LoadingIndicator enabled={true} />
        <Crosshair enabled={true} label={true} />
        <ValueAxis valueType="numeric" allowDecimals={false}>
          <Label format='#' customizeText={(e) => e.value / 1000000000} />
        </ValueAxis>
        <ArgumentAxis tickInterval={1} argumentType='numeric' visualRange={{ endValue: new Date().getFullYear() }}>
          {getStripValues().map((e) => (
            <Strip startValue={e.startValue} endValue={e.endValue} color="#f5f5f5" />
          ))}
          <Label format='#' argumentFormat='format' />
        </ArgumentAxis>
        <ScrollBar visible={true} width={5} opacity={0.5} />
        <ZoomAndPan argumentAxis="both" />
        <CommonSeriesSettings argumentField="budget_year">
          <Label visible={false} />
        </CommonSeriesSettings>
        <Series type="stackedbar" valueField="tusuv" name="Хөрөнгийн зардал" cornerRadius={6} color="#f66160" border={{ color: 'white', width: 1, visible: true }} />
        <Series valueField="tusuv" name="Зардлын өөрчлөлт" color="#e12c31" type="spline" dashStyle="solid" visible={false}>
          <Point size={10} />
        </Series>
        <Series type="bar" barPadding={0.5} valueField="guitsetgel" name="Гүйцэтгэл" cornerRadius={6} color='#003696' />
        <Series valueField="guitsetgel" name="Гүйцэтгэлийн өөрчлөлт" color="#003696" type="spline" dashStyle="solid">
          <Point size={10} />
        </Series>
        <Legend
          visible={true}
          verticalAlignment="bottom"
          horizontalAlignment="center"
          font={{ family: "Segoe UI" }}
          markerRender={markerTemplate}
        />
        <Tooltip
          zIndex={100000}
          enabled={true}
          customizeTooltip={customizeTooltip}
          location="edge"
          font={{ color: '#fff' }} color="#555"
          cornerRadius={6}
          border={{ visible: false }}
        />
      </Chart>
    </div>
  )
}

export default InvestExecutionByYear;

import React from "react"
import {useEffect} from "react"
import {useState} from "react"
import styled from "styled-components"

const MenuItemContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  font-size: 1rem;
  text-transform: uppercase;
  border-radius: 0.5rem;
  font-weight: 600;
  cursor: pointer;
  color: ${(props) => (props.isActive ? "white" : "black")};
  padding: 1rem;
  overflow: hidden;
  position: relative;
  background-color: ${(props) => (props.isActive ? "#003696" : "white")};
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  &:hover {
    background-color: #1677ff;
    color: white;
  }
`
function MenuItem(props) {
  return (
    <MenuItemContainer
      isActive={props.isActive}
      onClick={() => props.setCurrentList(props.list_id)}
    >
      <span>{props?.list_nm}</span>
    </MenuItemContainer>
  )
}

export default MenuItem

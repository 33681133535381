import request from '../../shared/lib/request'

const getAllDocumentShared = () => {
    return request({
        url: '/documentShared/',
        method: 'GET'
    })
}

const getByIdDocumentShared = (id) => {
    return request({
        url: `/documentShared/${id}`,
        method: 'GET'
    })
}

const addDocumentShared = (body) => {
    return request({
        url: '/documentShared/',
        method: 'POST',
        data: body
    })
}

const updateDocumentShared = (id, body) => {
    return request({
        url: `/documentShared/${id}`,
        method: 'PUT',
        data: body
    })
}

const removeDocumentShared = (id) => {
    return request({
        url: `/documentShared/${id}`,
        method: 'DELETE'
    })
}

const documentSharedService = {
    getAllDocumentShared,
    getByIdDocumentShared,
    addDocumentShared,
    updateDocumentShared,
    removeDocumentShared
}

export default documentSharedService;
import React, {useContext, useRef, useState} from "react";

// dev extreme
import notify from "devextreme/ui/notify";
import Form, {RequiredRule as RequiredRuleForm, ButtonItem, Item, Label, PatternRule,} from 'devextreme-react/form';

// service
import lawServices from "../../../services/api/law";
import {UserContext} from "../../../hooks/UserContext";

const LawForm = ({law, docTypes, setIsLoading, setVisible, setLaws, laws, dT}) => {
    const edit = law !== undefined;
    const {user} = useContext(UserContext);
    const formRef = useRef(null);
    const [lawObj] = useState({...law});

    // handle form submission
    const formSubmitHandler = async (event) => {
        event.preventDefault();
        // console.log('formSubmitting');
        setVisible(false);
        setIsLoading(true);
        // if (edit) lawObj.id = law.id;
        lawObj.created_by = user.id;
        try {
            let res = await lawServices.addLaw(lawObj);
            formRef.current.instance.resetValues(); // clean form

            // get all laws again
            setLaws([]);
            const allNewLaws = await lawServices.getAllLaws(edit ? law.law_doc_type : dT, 'desc');
            setIsLoading(false);
            setLaws(allNewLaws.data);
            if (res.code === 200) {
                return notify(res.message, "success", 3000);
            } else {
                return notify(res.message, "warning", 3000);
            }
        } catch (e) {
            console.log(e);
        }
    };

    const nameOptions = {
        height: 70,
        placeholder: "Монгол улсын 2010 онд нэмэлтээр экспортлох, импортлох..."
    };

    const dateOptions = {
        placeholder: "1990-05-27",
        displayFormat: "yyyy-MM-dd",
    };

    const docTypeOptions = {
        displayExpr: "name",
        valueExpr: "id",
        items: docTypes,
    };

    const enforcedOptions = {
        items: [{enforced: true, text: "Идэвхитэй"}, {enforced: false, text: "Идэвхигүй"}],
        valueExpr: "enforced",
        displayExpr: "text",
    };

    const buttonOptions = {
        text: 'Хадгалах',
        method: 'submit',
        type: 'success',
        useSubmitBehavior: true,
    };

    // console.log(lawObj, ' lawOBJ');

    return(
        <>
            <form onSubmit={formSubmitHandler}>
                <div className="long-title law-form-title"><h3>Хууль, эрх зүй</h3></div>
                <Form
                    className="law-form"
                    showValidationSummary={false}
                    formData={lawObj} colCount={1} ref={formRef}>

                    <Item dataField="law_no" isRequired={true} editorOptions={{placeholder: "A-411..."}} >
                        <Label text="Актын дугаар"/>
                        <RequiredRuleForm
                            message='Актын дугаар оруулна уу'
                        />
                    </Item>

                    <Item dataField="name" isRequired={true} editorType="dxTextArea" colCount={2} editorOptions={nameOptions} >
                        <Label text="Актын нэр"/>
                        <RequiredRuleForm
                            message='Актын нэр оруулна уу'
                        />
                    </Item>

                    <Item dataField="register_no" isRequired={true} editorOptions={{placeholder: "0221..."}} >
                        <Label text="Бүртгэлийн дугаар"/>
                        <RequiredRuleForm
                            message='Бүртгэлийн дугаар оруулна уу'
                        />
                        <PatternRule
                            pattern = "^[0-9]+$"
                            message = "зөвхөн тоо оруулна уу"
                        />
                    </Item>

                    <Item dataField="submitted_date" editorType="dxDateBox" isRequired={true} editorOptions={dateOptions} >
                        <Label text="Батлагдсан огноо"/>
                    </Item>

                    <Item dataField="follow_date" editorType="dxDateBox" isRequired={true} editorOptions={dateOptions} >
                        <Label text="Дагаж мөрдөх огноо"/>
                    </Item>

                    <Item dataField="registration_date" editorType="dxDateBox" isRequired={false} editorOptions={dateOptions} >
                        <Label text="Бүргэлийн огноо"/>
                    </Item>

                    <Item dataField="law_doc_type" editorType="dxSelectBox" editorOptions={docTypeOptions} isRequired={true} >
                        <Label text="Хуулийн төрөл" />
                    </Item>

                    <Item dataField="legal_url" isRequired={true} editorOptions={{placeholder: "https://legalinfo.mn/mn"}} >
                        <Label text="Мэдээлэл авах хаяг"/>
                        <RequiredRuleForm
                            message='Нэр оруулна уу'
                        />
                    </Item>

                    <Item dataField="enforced" isRequired={false} editorType="dxSelectBox" editorOptions={enforcedOptions} >
                        <Label text="Хууль хүчинтэй байгаа эсэх"/>
                    </Item>

                    <ButtonItem horizontalAlignment="center" buttonOptions={buttonOptions} />
                </Form>
            </form>
        </>
    )
};

export default LawForm;
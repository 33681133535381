import React, {useRef, useState, useEffect, useCallback} from "react"
import FileServices from "../../../services/api/file"
import newsServices from "../../../services/api/news"
import Form, {
  Item as FormItem1,
  Label,
  RequiredRule,
} from "devextreme-react/form"
import {Popup} from "devextreme-react/popup"
import DataGrid, {
  Column,
  Editing,
  Item as DataGridItem,
  Form as DataGridForm,
  Popup as DataGridPopUp,
  Button,
  FilterRow,
  Lookup,
  Scrolling,
} from "devextreme-react/data-grid"
import "yet-another-react-lightbox/styles.css"
import "yet-another-react-lightbox/plugins/thumbnails.css"
import SelectBox from "devextreme-react/select-box"
import HtmlEditor, {
  Item,
  MediaResizing,
  Toolbar,
  ImageUpload,
} from "devextreme-react/html-editor"
import FileUploader from "devextreme-react/file-uploader"
import {CheckBox, LoadIndicator, TextBox} from "devextreme-react"
import ScrollView from "devextreme-react/scroll-view"
import notify from "devextreme/ui/notify"
import {Button as DevExtremeButton} from "devextreme-react/button"
import "../css/styles.css"
import LoadPanelComponent from "../../budget/components/loadPanel"
import ImageViewer from "../../../components/ImageViewer"
import {UserContext} from "../../../hooks/UserContext"
import {useContext} from "react"
import OrganizationSelectComponent from "../../../components/OrganizationSelectComponent"

import {BiLinkAlt, BiSolidEditAlt} from "react-icons/bi"
import YoutubePlayerComponent from "../../../components/YoutubePlayer"
import Compressor from "compressorjs"
import {Pagination} from "antd"
import {custom} from "devextreme/ui/dialog"
import {AiFillEye} from "react-icons/ai"
import {BsFileEarmarkText, BsFillTrashFill} from "react-icons/bs"
import styled from "styled-components"
const ProjectButton = styled(DevExtremeButton)`
  padding: 0.5rem !important;
  margin: 0 0.25rem;
  background-color: white !important;
  outline: none !important;
  cursor: pointer;
  border: 1px solid #e1e2e3 !important;
  border-radius: 6px !important;
  color: black !important;
  font-weight: 600;
  &.long {
    padding: 0.125rem 1rem !important;
  }
  &.delete {
    background-color: #f66160 !important;
    color: white !important;
    &:hover {
      background-color: #ff6564 !important;
    }
  }
  .dx-button-content {
    padding: 0 !important;
  }
  transition: 0.3s ease;

  &:hover {
    background-color: #cbcfd6 !important;
  }

  &:active {
    background-color: #cfd3db !important;
  }
  svg {
    font-size: 1.2rem;
  }
`
const AddNews = () => {
  const [allNews, setAllNews] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [content, setContent] = useState(null)
  const [editToggle, setEditToggle] = useState(false)
  const [isPrivate, setIsPrivate] = useState(null)
  const [isOpendata, setIsOpendata] = useState(null)
  const [multiple, setMultiple] = useState([])
  const [addedNews, setAddedNews] = useState(null)
  const [loading, setLoading] = useState(false)
  const [fileNames, setFileNames] = useState([])
  const [data, setData] = useState({})
  const [selectedData, setSelectedData] = useState({})
  const [toggleCreateMenu, setToggleCreateMenu] = useState(false)
  const {user} = useContext(UserContext)
  const [type, setNewsType] = useState(null)
  const [selectedUrl, setSelectedUrl] = useState("")
  const [videoPopup, setVideoPopup] = useState(false)
  const [searchText, setSearchText] = useState("")
  const [limit, setLimit] = useState(10)
  const [fullScreen, setFullScreen] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(25)
  const [totalNumber, setTotalNumber] = useState()
  const [showCheck, setShowCheck] = useState(false)
  //Refs

  const photoRef = useRef()
  const titleRef = useRef(null)
  const urlRef = useRef(null)
  const HtmlRef = useRef(null)
  const scriptRef = useRef(null)

  const sizeValues = ["8pt", "10pt", "12pt", "14pt", "18pt", "24pt", "36pt"]
  const fontValues = [
    "Montserrat",
    "Arial",
    "Courier New",
    "Georgia",
    "Impact",
    "Lucida Console",
    "Tahoma",
    "Times New Roman",
    "Verdana",
  ]

  const fontOptions = {placeholder: "Фонт"}
  const allowedPageSizes = [10, 20, 50, 100]
  const headerValues = [false, 1, 2, 3, 4, 5]

  const onRemovingEvent = async (id) => {
    try {
      let result = await newsServices.deleteNewsById(id)
      await newsServices.deleteNotificationById(id)
      if (result?.code === 200) {
        fetchData()
        return notify("Амжилттай устгалаа", "success", 4000)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }
  const deleteHandler = async (e) => {
    const id = e.data.id
    const customConfirm = custom({
      title: "Баталгаажуулалт",
      messageHtml: "<i>Устгахдаа итгэлтэй байна үү?</i>",
      buttons: [
        {
          text: "Тийм",
          onClick: (e) => {
            return true
          },
        },
        {
          text: "Үгүй",
          onClick: (e) => {
            return false
          },
        },
      ],
    })

    customConfirm.show().then((dialogResult) => {
      if (dialogResult) {
        onRemovingEvent(id)
      } else {
        fetchData()
      }
    })
  }
  const allowedFileExtensions = [".jpg", ".jpeg", ".png", ".webp"]

  //On Change events
  const onPhotoChange = (e) => {
    photoRef.current = e.value
  }
  const onMultiplePhotoChange = (e) => {
    setMultiple(e.value)
  }

  const onTitleChange = (e) => {
    titleRef.current = e.value
  }

  const onUrlChange = (e) => {
    urlRef.current = e.value
  }
  const onContentChange = (e) => {
    HtmlRef.current = e.value
  }
  const onScriptContentChange = (e) => {
    scriptRef.current = e.value
  }

  //Renders
  const ImageUploader = () => {
    return (
      <div style={{}}>
        <FileUploader
          allowedFileExtensions={allowedFileExtensions}
          onValueChanged={onPhotoChange}
          selectButtonText="Нүүр зураг сонгох"
          labelText=""
          accept="image/*"
          uploadMode="useForm"
        />
        <span className="note">
          {"Зургийн өргөтгөл: "}
          <span>.jpg, .jpeg, .png</span>
        </span>
      </div>
    )
  }
  const MultipleImageUploader = () => {
    return (
      <div style={{}}>
        <FileUploader
          allowedFileExtensions={allowedFileExtensions}
          onValueChanged={onMultiplePhotoChange}
          selectButtonText="Бусад зурагнууд сонгох"
          multiple={true}
          labelText=""
          accept="image/*"
          uploadMode="useForm"
        />
        <span className="note">
          {"Зургийн өргөтгөл: "}
          <span>.jpg, .jpeg, .png</span>
        </span>
      </div>
    )
  }
  const onPageChange = (e) => {
    setCurrentPage(e)
  }
  const onPageSizeChange = (e) => {
    console.log(e)
  }
  const multipleFileSubmit = async (fileData, id, type) => {
    try {
      let data = new FormData()
      let compressionPromises = []
      for (let i = 0; i < fileData.length; i++) {
        if (
          fileData[i].size / 1024 > 500 &&
          fileData[i].type.includes("image")
        ) {
          const compressionPromise = new Promise((resolve) => {
            new Compressor(fileData[i], {
              quality: 0.6,
              type: "image/jpeg",
              convertTypes: ["image/png", "image/webp"],
              convertSize: 500000,
              success: async (compressedResult) => {
                const arrayBuffer = await compressedResult.arrayBuffer()
                let compressedFile = new File([arrayBuffer], fileData[i].name, {
                  type: fileData[i].type,
                })
                data.append("file", compressedFile)
                resolve()
              },
            })
          })
          compressionPromises.push(compressionPromise)
        } else {
          data.append("file", multiple[i])
        }
      }
      await Promise.all(compressionPromises)
      const res = await FileServices.uploadMultipleNewsFiles(data, id, type)
      if (res.code === 200) {
        setMultiple([])
        return res.data
      }
    } catch (error) {
      console.log("error", error)
      return notify("Хадгалах үйлдэл амжилтгүй боллоо", "warning", 3000)
    }
  }
  const fileSubmit = async (fileData, typeId, size) => {
    try {
      if (fileData.size >= size) {
        notify(
          `Оруулсан файлын хэмжээ  ${size / 1000000}-MB-аас их байна.`,
          "warning",
          3000
        )
        return 0
      }
      let data = new FormData()
      if (fileData.size / 1024 > 500 && fileData.type.includes("image")) {
        await new Promise((resolve) => {
          new Compressor(fileData, {
            quality: 0.8,
            type: "image/jpeg",
            convertTypes: ["image/png", "image/webp"],
            convertSize: 500000,
            success: async (compressedResult) => {
              const arrayBuffer = await compressedResult.arrayBuffer()
              let compressedFile = new File([arrayBuffer], fileData.name, {
                type: fileData.type,
              })
              data.append("file", compressedFile)
              resolve()
            },
          })
        })
      } else {
        data.append("file", fileData)
      }
      const res = await FileServices.uploadSingleFile(data, typeId)
      if (res.code === 200) {
        photoRef.current = null
        return res.data
      }
    } catch (error) {
      console.log(error)
      return notify("Хадгалах үйлдэл амжилтгүй боллоо (Зураг)", "warning", 3000)
    }
  }
  const onEditNews = async () => {
    try {
      let news = {}
      if (titleRef.current !== null) {
        news.news_title = titleRef.current
      }
      if (HtmlRef.current !== null) {
        news.markup = HtmlRef.current
      }
      if (scriptRef.current !== null) {
        news.news_text = scriptRef.current
      }
      if (photoRef.current !== null && photoRef.current !== undefined) {
        news.file_id = await fileSubmit(photoRef.current[0], 26, 30000000)
      }
      if (selectedData.orgName !== null) {
        news.author = selectedData.orgName
      }
      if (urlRef.current !== null) {
        news.youtube_url = urlRef.current
      }
      if (isPrivate !== null) {
        news.is_private = isPrivate
      }
      if (isOpendata !== null) {
        news.is_opendata = isOpendata
      }
      if (type !== null) {
        news.news_type = type
      }
      if (selectedData.orgId !== null) {
        news.org_id = selectedData.orgId
      }
      if (Object.keys(news).length === 0 && multiple.length === 0) {
        setEditToggle(false)
        return 0
      } else {
        const result = await newsServices.updateNewsById(news, data.id)
        if (news.news_title && result && result.code === 200) {
          updateNotification({newsId: data.id, news_title: news.news_title})
        }
        if (multiple.length > 0) {
          let res = await multipleFileSubmit(multiple, data.id, 2)
        }
        titleRef.current = null
        photoRef.current = null
        HtmlRef.current = null
        scriptRef.current = null

        setEditToggle(false)
        fetchData()
        return notify(result.message, "success", 4000)
      }
    } catch (error) {
      console.log("error", error)
      return notify("Хадгалах үйлдэл амжилтгүй боллоо", "warning", 3000)
    } finally {
      setIsLoading(false)
    }
  }

  function processHtmlString(htmlString) {
    let tempDiv = document.createElement("div")
    tempDiv.innerHTML = htmlString
    let imgElements = tempDiv.querySelectorAll("img")
    imgElements.forEach(function (img) {
      if (
        !img.hasAttribute("width") ||
        img.getAttribute("width") !== "100%" ||
        !img.hasAttribute("height") ||
        img.getAttribute("height") !== "auto"
      ) {
        img.setAttribute("width", "100%")
        img.setAttribute("height", "auto")
      }
    })
    return tempDiv.innerHTML
  }

  const onSubmitNews = async () => {
    let data = {}
    data.news_title = titleRef.current
    data.news_text = scriptRef.current
    data.markup = processHtmlString(HtmlRef.current)
    data.org_id = selectedData.orgId
    data.author = selectedData.orgName
    data.is_private = isPrivate
    data.is_opendata = isOpendata
    data.news_type = type
    data.youtube_url = urlRef.current

    if (data.news_title == null) {
      notify(`Гарчиг оруулна уу!`, "warning", 3000)
      return 0
    }
    if (data.markup == null && data.news_text == null) {
      notify(`Нийтлэл оруулна уу!`, "warning", 3000)
      return 0
    }
    if (photoRef.current == null) {
      notify(`Зураг оруулна уу!`, "warning", 3000)
      return 0
    }
    if (data.author == null) {
      notify(`Байгууллага сонгоно уу!`, "warning", 3000)
      return 0
    }
    if (data.is_private == null) {
      notify(
        `Дээд шатны байгууллагад харуулах эсэхээ сонгоно уу!`,
        "warning",
        3000
      )
      return 0
    }
    if (data.is_opendata == null) {
      notify(`Олон нийтэд харуулах эсэхээ сонгоно уу!`, "warning", 3000)
      return 0
    }
    if (data.news_type == null) {
      notify(`Мэдээний ангилал сонгоно уу!`, "warning", 3000)
      return 0
    }
    try {
      setIsLoading(true)
      data.file_id = await fileSubmit(photoRef.current[0], 26, 30000000)
      let res = await newsServices.addNews(data)
      let id
      if (res && res.code && res.code === 200) {
        id = res.data.id
      }
      if (id && multiple.length > 0) {
        await multipleFileSubmit(multiple, id, 1)
      }
      if (res && res.data) {
        setAddedNews(res.data)
      }
      notify("Амжилттай хадгаллаа", "success", 3000)
      setIsLoading(false)
      setToggleCreateMenu(false)
      urlRef.current = null
      setContent(null)
      fetchData()
    } catch (error) {
      console.log(error)
      return notify("Хадгалах үйлдэл амжилтгүй боллоо", "warning", 3000)
    }
  }
  const addNotification = async () => {
    try {
      if (addedNews !== null) {
        await newsServices.addNotification(addedNews)
        setAddedNews(null)
      } else {
        return 0
      }
    } catch (error) {
      console.log("error", error)
    }
  }
  const updateNotification = async (data) => {
    try {
      if (data !== null) {
        await newsServices.updateNotification(data)
        setAddedNews(null)
      }
    } catch (error) {
      console.log(error)
    }
  }
  const onValueSelectBox = (e) => {
    setIsPrivate(e.value)
  }
  const onValueSelectBox2 = (e) => {
    setIsOpendata(e.value)
  }
  //type
  const onTypeSelectBox = (e) => {
    setNewsType(e.value)
  }

  const TitleRender = () => {
    return (
      <TextBox
        onValueChanged={onTitleChange}
        label={"Гарчиг"}
        defaultValue={editToggle ? data.news_title : ""}
      />
    )
  }
  const UrlRender = () => {
    return (
      <TextBox
        label={"Хавсаргах бичлэг /Youtube link оруулна уу/"}
        onValueChanged={onUrlChange}
        defaultValue={editToggle ? data.youtube_url : ""}
      />
    )
  }

  const OrgRender = () => {
    return (
      <div style={{marginLeft: "-15px"}}>
        <OrganizationSelectComponent
          selectedData={(d) => setSelectedData(d)}
          selectedYear={(d) => {}}
          showClearButton={false}
          hideLabel={true}
          labelMode={"floating"}
          defaultId={editToggle ? data.org_id : null}
          defaultAuthor={editToggle ? data.author : null}
          showYear={false}
          width="60rem"
        />
      </div>
    )
  }
  const selectBoxInit = () => {
    if (editToggle) {
      setIsPrivate(data.is_private)
    } else {
      setIsPrivate(null)
    }
  }
  const selectBoxInit2 = () => {
    if (editToggle) {
      setIsOpendata(data.is_opendata)
    } else {
      setIsOpendata(null)
    }
  }
  //type
  const selectTypeInit = () => {
    if (editToggle) {
      setNewsType(data.news_type)
    } else {
      setNewsType(null)
    }
  }
  const renderButton = (e, type) => {
    if (type === 1) {
      return (
        <ProjectButton
          hint="Хуудас харах"
          // onClick={() => {
          //   setContent(e?.data?.value)
          //   setShowPage(true)
          // }}
        >
          <AiFillEye />
        </ProjectButton>
      )
    } else if (type === 2) {
      return (
        <ProjectButton
          hint="Файл харах"
          onClick={async () => {
            // setData(e.row.data)
            // await getFileData(e.row.key)
            // setFileToggle(true)
          }}
        >
          <BsFileEarmarkText />
        </ProjectButton>
      )
    } else if (type === 3) {
      return (
        <ProjectButton
          hint="Хуудас өөрчлөх"
          onClick={() => {
            onEditHandle(e)
          }}
        >
          <BiSolidEditAlt />
        </ProjectButton>
      )
    } else if (type === 4) {
      return (
        <ProjectButton
          hint="Хуудас устгах"
          className="delete"
          onClick={() => deleteHandler(e)}
        >
          <BsFillTrashFill />
        </ProjectButton>
      )
    }
  }
  const SelectBoxRender = () => {
    return (
      <SelectBox
        items={[
          {text: "Тийм", value: 0},
          {text: "Үгүй", value: 1},
        ]}
        displayExpr="text"
        valueExpr={"value"}
        value={isPrivate}
        onInitialized={selectBoxInit}
        onValueChanged={onValueSelectBox}
        placeholder="Сонгох"
        label={"Дээд шатны байгууллагад харагдах эсэх"}
      />
    )
  }
  const SelectBoxRender2 = () => {
    return (
      <SelectBox
        items={[
          {text: "Тийм", value: 1},
          {text: "Үгүй", value: 0},
        ]}
        displayExpr="text"
        valueExpr={"value"}
        value={isOpendata}
        onInitialized={selectBoxInit2}
        onValueChanged={onValueSelectBox2}
        placeholder="Сонгох"
        label={"Олон нийтэд харагдах эсэх"}
      />
    )
  }
  const newsTypes =
    user.username === "00000"
      ? [
          {text: "Үйл явдал", value: 1},
          {text: "Зар", value: 2},
          {text: "Төсвийн тойм", value: 3},
          {text: "Инфографик", value: 4},
          {text: "Фото мэдээ", value: 7},
          {text: "Видео мэдээ", value: 8},
        ]
      : [
          {text: "Үйл явдал", value: 1},
          {text: "Зар", value: 2},
          {text: "Инфографик", value: 4},
          {text: "Фото мэдээ", value: 7},
          {text: "Видео мэдээ", value: 8},
        ]
  const SelectTypeRender = () => {
    return (
      <SelectBox
        items={newsTypes}
        displayExpr="text"
        valueExpr={"value"}
        value={type}
        onInitialized={selectTypeInit}
        onValueChanged={onTypeSelectBox}
        placeholder="Сонгох"
        label={"Мэдээний ангилал сонгох"}
      />
    )
  }
  const ImageUploaderTabs = [{name: "From This Device", value: ["file"]}]
  const requestFullscreen = () => {
    setFullScreen(!fullScreen)
    /* Get the element you want displayed in fullscreen */
    const element = document.getElementById("htmlEditorEdit")
    // Use a guard clause to exit out of the function immediately
    if (element.requestFullscreen) {
      element.requestFullscreen()
    } else if (element.webkitRequestFullscreen) {
      // Safari
      element.webkitRequestFullscreen()
    } else if (element.msRequestFullscreen) {
      // IE
      element.msRequestFullscreen()
    }
  }
  const toolbarButtonOptions = {
    icon: "fullscreen",
    stylingMode: "text",
    onClick: requestFullscreen,
  }
  const MarkupRender = () => {
    return (
      <div
        height="100%"
        width="100%"
        id="htmlEditorEdit"
        style={{backgroundColor: "#fff"}}
      >
        <HtmlEditor
          height={fullScreen ? "100%" : "25rem"}
          style={{marginTop: "1rem"}}
          defaultValue={editToggle ? data.markup : content}
          onValueChanged={onContentChange}
          valueType="html"
          placeholder={"Та шинэ нийтлэлээ оруулна уу. "}
        >
          <MediaResizing enabled={true} />
          <ImageUpload fileUploadMode="base64" tabs={ImageUploaderTabs} />
          <Toolbar multiline={true}>
            <Item name="size" acceptedValues={sizeValues} />
            <Item name="separator" />
            <Item
              name="font"
              acceptedValues={fontValues}
              options={fontOptions}
            />
            <Item name="separator" />
            <Item name="bold" />
            <Item name="italic" />
            <Item name="strike" />
            <Item name="underline" />
            <Item name="separator" />
            <Item name="alignLeft" />
            <Item name="alignCenter" />
            <Item name="alignRight" />
            <Item name="alignJustify" />
            <Item name="separator" />
            <Item name="orderedList" />
            <Item name="bulletList" />
            <Item name="separator" />
            <Item name="header" acceptedValues={headerValues} />
            <Item name="separator" />
            <Item name="color" />
            <Item name="background" />
            <Item name="separator" />
            <Item name="link" />
            <Item name="image" />
            <Item name="separator" />
            <Item name="clear" />
            <Item name="codeBlock" />
            <Item name="blockquote" />
            <Item name="separator" />
            <Item name="insertTable" />
            <Item name="deleteTable" />
            <Item name="insertRowAbove" />
            <Item name="insertRowBelow" />
            <Item name="deleteRow" />
            <Item name="insertColumnLeft" />
            <Item name="insertColumnRight" />
            <Item name="deleteColumn" />
            <Item name="separator" />
            <Item widget={"dxButton"} options={toolbarButtonOptions} />
          </Toolbar>
        </HtmlEditor>
      </div>
    )
  }
  const scriptCheckChange = () => {
    setShowCheck(!showCheck)
  }

  const ScripCheckRender = () => {
    return (
      <div>
        <span className="note" style={{marginRight: ".5rem"}}>
          {"Монгол бичгийн нийтлэл оруулах эсэх: "}
        </span>
        <CheckBox
          value={showCheck}
          onValueChanged={scriptCheckChange}
          // elementAttr={uncheckedLabel}
        />
      </div>
    )
  }
  const ScriptMarkupRender = () => {
    const uncheckedLabel = {"aria-label": "Unchecked"}
    return (
      <div
        height="100%"
        width="100%"
        id="htmlEditorEdit"
        style={{backgroundColor: "#fff"}}
      >
        <div>
          <span className="note" style={{marginRight: ".5rem"}}>
            {"Монгол бичгийн нийтлэл оруулах эсэх: "}
          </span>
          <CheckBox
            value={showCheck}
            onValueChanged={scriptCheckChange}
            elementAttr={uncheckedLabel}
          />
        </div>
        {showCheck && (
          <HtmlEditor
            height={fullScreen ? "100%" : "25rem"}
            style={{marginTop: "1rem"}}
            defaultValue={editToggle ? data.news_text : content}
            onValueChanged={onScriptContentChange}
            valueType="html"
            placeholder={"Та шинэ монгол бичгийн нийтлэлээ оруулна уу. "}
          >
            <MediaResizing enabled={true} />
            <ImageUpload fileUploadMode="base64" tabs={ImageUploaderTabs} />
            <Toolbar multiline={true}>
              <Item name="size" acceptedValues={sizeValues} />
              <Item name="separator" />
              <Item
                name="font"
                acceptedValues={fontValues}
                options={fontOptions}
              />
              <Item name="separator" />
              <Item name="bold" />
              <Item name="italic" />
              <Item name="strike" />
              <Item name="underline" />
              <Item name="separator" />
              <Item name="alignLeft" />
              <Item name="alignCenter" />
              <Item name="alignRight" />
              <Item name="alignJustify" />
              <Item name="separator" />
              <Item name="orderedList" />
              <Item name="bulletList" />
              <Item name="separator" />
              <Item name="header" acceptedValues={headerValues} />
              <Item name="separator" />
              <Item name="color" />
              <Item name="background" />
              <Item name="separator" />
              <Item name="link" />
              <Item name="image" />
              <Item name="separator" />
              <Item name="clear" />
              <Item name="codeBlock" />
              <Item name="blockquote" />
              <Item name="separator" />
              <Item name="insertTable" />
              <Item name="deleteTable" />
              <Item name="insertRowAbove" />
              <Item name="insertRowBelow" />
              <Item name="deleteRow" />
              <Item name="insertColumnLeft" />
              <Item name="insertColumnRight" />
              <Item name="deleteColumn" />
              <Item name="separator" />
              <Item widget={"dxButton"} options={toolbarButtonOptions} />
            </Toolbar>
          </HtmlEditor>
        )}
      </div>
    )
  }
  const onClickRenderUrl = (url) => {
    if (url) {
      setSelectedUrl(url)
      setVideoPopup(true)
    } else {
      return notify("Бичлэг хавсаргаагүй байна", "warning", 3000)
    }
  }
  const renderUrlLink = (e) => {
    let selectedRow = e?.row?.data
    return (
      <div
        onClick={() => {
          onClickRenderUrl(selectedRow?.youtube_url)
        }}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <ProjectButton>
          <BiLinkAlt />
        </ProjectButton>
      </div>
    )
  }
  const renderEditMenu = () => {
    return (
      <Popup
        visible={editToggle}
        onHiding={() => setEditToggle(false)}
        showCloseButton
        height="100%"
        showTitle
        title="Мэдээ засах"
        id="scrollViewEdit"
        width="65%"
      >
        <ScrollView height="100%" width="100%">
          {/* <LoadPanelComponent
            position={{of: "#scrollViewEdit"}}
            visible={isLoading}
          /> */}
          <div className="widget-container">
            {editToggle && (
              <Form labelMode={"floating"} labelLocation={"left"}>
                <FormItem1 itemType={"group"} colCount={1} colSpan={2}>
                  <FormItem1 dataField="org_id" render={OrgRender}>
                    <Label text="Байгууллага" />
                    <RequiredRule message="Байгууллага сонгоно уу!" />
                  </FormItem1>
                  <FormItem1 dataField="news_title" render={TitleRender}>
                    <Label text="Гарчиг" />
                    <RequiredRule message="Гарчиг оруулна уу!" />
                  </FormItem1>
                  <FormItem1 dataField="markup" render={MarkupRender}>
                    <Label text="Нийтлэл" />
                    <RequiredRule message="Нийтлэл оруулна уу!" />
                  </FormItem1>
                  <FormItem1
                    dataField="news_text"
                    render={ScriptMarkupRender}
                    label={"Нийтлэл"}
                  >
                    <Label text="Нийтлэл /монгол бичиг/" />
                    <RequiredRule message="Нийтлэл оруулна уу!" />
                  </FormItem1>
                  <FormItem1 itemType={"group"} colCount={2} colSpan={2}>
                    <FormItem1 render={ImageUploader}>
                      <Label text="Нүүр зураг" />
                      <RequiredRule message="Зураг оруулна уу!" />
                    </FormItem1>
                    <FormItem1 render={MultipleImageUploader}>
                      <Label text="Бусад зургууд" />
                      <RequiredRule message="Зураг оруулна уу!" />
                    </FormItem1>
                  </FormItem1>
                  <FormItem1 render={UrlRender}>
                    <Label text="Бичлэг хавсаргах /Youtube link оруулна уу/" />
                    <RequiredRule message="Зураг оруулна уу!" />
                  </FormItem1>
                  <FormItem1 itemType={"group"} colCount={3} colSpan={2}>
                    <FormItem1 render={SelectBoxRender}>
                      <Label text="Дээд шатны байгууллагад харагдах эсэх" />
                      <RequiredRule message="Нийтлэл оруулна уу!" />
                    </FormItem1>
                    <FormItem1 render={SelectBoxRender2}>
                      <Label text="Олон нийтэд харагдах эсэх" />
                      <RequiredRule message="Нийтлэл оруулна уу!" />
                    </FormItem1>
                    <FormItem1 render={SelectTypeRender}>
                      <Label text="Мэдээний ангилал сонгох" />
                      <RequiredRule message="Мэдээний ангилал сонгоно уу!" />
                    </FormItem1>
                  </FormItem1>
                </FormItem1>
              </Form>
            )}
            <div
              style={{
                width: "100%",
                display: "flex",
                height: "100%",
                justifyContent: "flex-start",
                marginTop: "2rem",
                gap: "3.5rem",
              }}
            >
              <span>Нийтлэлийн зураг:</span>{" "}
              <div style={{height: "8vw"}}>
                {data && data.file_name && (
                  <ImageViewer
                    array={[data]}
                    filename="file_name"
                    height="8vw"
                  />
                )}
              </div>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-start",
                marginTop: "2rem",
                gap: "3.8rem",
              }}
            >
              <span>Бусад зургууд:</span>{" "}
              {fileNames && (
                <ImageViewer
                  array={fileNames}
                  filename="file_name"
                  height="8vw"
                  margin="10px"
                />
              )}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginRight: 10,
                marginBottom: 10,
                marginTop: 10,
              }}
            >
              <DevExtremeButton
                icon="plus"
                text={"Нийтлэх"}
                type="default"
                className="saveButton"
                onClick={() => onEditNews()}
              >
                <span className="dx-button-text">
                  <LoadIndicator
                    style={{height: 20, width: 20}}
                    visible={isLoading}
                  />
                  {!isLoading && <span>Хадгалах</span>}
                </span>
              </DevExtremeButton>
            </div>
          </div>
        </ScrollView>
      </Popup>
    )
  }

  const renderCreateMenu = (props) => {
    return (
      <Popup
        visible={toggleCreateMenu}
        onHiding={() => setToggleCreateMenu(false)}
        // hideOnOutsideClick
        showCloseButton
        height="100%"
        showTitle
        title="Шинэ мэдээ нэмэх"
        width="65%"
        id="renderCreateMenu"
      >
        <ScrollView height="100%" width="100%">
          {/* <LoadPanelComponent
            position={{of: "#renderCreateMenu"}}
            visible={isLoading}
          /> */}
          <div className="widget-container">
            {toggleCreateMenu && (
              <Form labelMode={"floating"} labelLocation={"left"}>
                <FormItem1 itemType={"group"} colCount={1} colSpan={2}>
                  <FormItem1
                    dataField="org_id"
                    render={OrgRender}
                    label={"Байгууллага"}
                  >
                    <Label text="Байгууллага" />
                    <RequiredRule message="Байгууллага сонгоно уу!" />
                  </FormItem1>
                  <FormItem1
                    dataField="news_title"
                    render={TitleRender}
                    label={"Гарчиг"}
                  >
                    {/* <Label text="Гарчиг" />*/}
                    <RequiredRule message="Гарчиг оруулна уу!" />
                  </FormItem1>
                  <FormItem1 itemType={"group"} colCount={2} colSpan={2}>
                    <FormItem1 render={ImageUploader}>
                      <Label text="Нүүр зураг" />
                      <RequiredRule message="Зураг оруулна уу!" />
                    </FormItem1>
                    <FormItem1 render={MultipleImageUploader}>
                      <Label text="Бусад зурагнууд" />
                      <RequiredRule message="Зураг оруулна уу!" />
                    </FormItem1>
                  </FormItem1>
                  <FormItem1 dataField="youtube_url" render={UrlRender}>
                    <Label text="Хавсаргах бичлэг /Youtube link оруулна уу/" />
                    <RequiredRule message="Гарчиг оруулна уу!" />
                  </FormItem1>
                  <FormItem1
                    dataField="markup"
                    render={MarkupRender}
                    label={"Нийтлэл"}
                  >
                    <Label text="Нийтлэл" />
                    <RequiredRule message="Нийтлэл оруулна уу!" />
                  </FormItem1>
                  {/* <FormItem1 itemType={"group"} colCount={2} colSpan={2}>
                    <FormItem1 render={ScripCheckRender}>
                      <Label text="Олон нийтэд харагдах эсэх" />
                      <RequiredRule message="Нийтлэл оруулна уу!" />
                    </FormItem1>
                  </FormItem1> */}

                  <FormItem1
                    dataField="news_text"
                    render={ScriptMarkupRender}
                    label={"Нийтлэл"}
                  >
                    <Label text="Нийтлэл /монгол бичиг/" />
                    <RequiredRule message="Нийтлэл оруулна уу!" />
                  </FormItem1>

                  <FormItem1 itemType={"group"} colCount={3} colSpan={2}>
                    <FormItem1 render={SelectBoxRender}>
                      <Label text="Дээд шатны байгууллагад харагдах эсэх" />
                      <RequiredRule message="Нийтлэл оруулна уу!" />
                    </FormItem1>
                    <FormItem1 render={SelectBoxRender2}>
                      <Label text="Олон нийтэд харагдах эсэх" />
                      <RequiredRule message="Нийтлэл оруулна уу!" />
                    </FormItem1>
                    <FormItem1 render={SelectTypeRender}>
                      <Label text="Мэдээний ангилал сонгох" />
                      <RequiredRule message="Мэдээний ангилал сонгоно уу!" />
                    </FormItem1>
                  </FormItem1>
                </FormItem1>
              </Form>
            )}
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginRight: 10,
                marginBottom: 10,
                marginTop: 10,
              }}
            >
              <DevExtremeButton
                icon="plus"
                text={"Нийтлэх"}
                type="default"
                className="saveButton"
                onClick={submitHandler}
              >
                <span className="dx-button-text">
                  <LoadIndicator
                    style={{height: 20, width: 20}}
                    visible={isLoading}
                  />
                  {!isLoading && <span>Нийтлэх</span>}
                </span>
              </DevExtremeButton>
            </div>
          </div>
        </ScrollView>
      </Popup>
    )
  }
  const renderVideoPopup = () => {
    return (
      <Popup
        visible={videoPopup}
        onHiding={() => {
          setSelectedUrl("")
          setVideoPopup(false)
        }}
        hideOnOutsideClick={true}
        showCloseButton
        height="auto"
        showTitle
        title="Хавсаргасан бичлэг"
        width="60%"
        id="renderaddCategoryMenu"
      >
        <ScrollView height="100%" width="100%">
          <div className="widget-container">
            <YoutubePlayerComponent url={selectedUrl} />
          </div>
        </ScrollView>
      </Popup>
    )
  }
  const submitHandler = async () => {
    onSubmitNews()
    addNotification()
  }

  const lookUpList = [
    {is_private: 1, text: "Үгүй"},
    {is_private: 0, text: "Тийм"},
  ]
  const lookUpList2 = [
    {is_opendata: 1, text: "Тийм"},
    {is_opendata: 0, text: "Үгүй"},
  ]
  const lookUpType = [
    {news_type: 1, text: "Үйл явдал"},
    {news_type: 2, text: "Зар"},
    {news_type: 3, text: "Төсвийн тойм"},
    {news_type: 4, text: "Инфографик"},
  ]

  useEffect(() => {
    fetchData()
  }, [currentPage])

  useEffect(() => {
    addNotification()
  }, [addedNews])

  const fetchData = async () => {
    try {
      const getAllNews = async () => {
        let {data, total} = await newsServices.getAllNewsForAdmin(
          currentPage,
          pageSize,
          ""
        )
        setTotalNumber(total)
        setAllNews(data)
      }
      await getAllNews()
    } catch (error) {
      setAllNews([])
      setLoading(false)
      console.log(error)
    } finally {
      setLoading(false)
    }
  }
  const searchNews = async () => {
    try {
      const getAllNews = async () => {
        let {data, total} = await newsServices.getAllNewsForAdmin(
          currentPage,
          pageSize,
          searchText
        )
        setTotalNumber(total)
        setAllNews(data)
      }
      await getAllNews()
    } catch (error) {
      setAllNews([])
      setLoading(false)
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const getFileData = async (id) => {
    try {
      let res = await newsServices.getNewsFileNames(id)
      setFileNames(await res.data)
    } catch (error) {
      console.log(error)
    }
  }
  const handleOnCellPrepared = (e) => {
    if (e.rowType === "header") {
      e.cellElement.wordWrapEnabled = true
      e.cellElement.style.fontFamily = "Montserrat"
      e.cellElement.style.fontWeight = "semibold"
      e.cellElement.style.color = "#15186A"
      e.cellElement.style.backgroundColor = "#EEF0F4"
    }
    if (e.rowType === "data") {
      e.cellElement.style.fontFamily = "Montserrat"
      e.cellElement.style.fontWeight = "semibold"
      e.cellElement.style.color = "#000000"
    }
  }

  const onEditHandle = async (e) => {
    setData(e.row.data)
    await getFileData(e.row.key)
    setEditToggle(true)
  }

  var dataGrid

  const orderRender = (data) => {
    return (
      <div style={{color: "#171010"}}>
        {(currentPage - 1) * pageSize + data.rowIndex + 1}
      </div>
    )
  }
  const handleInputText = (event) => {
    console.log(
      "handle input original defualt value pringing in the console",
      event
    )
    if (event.event !== undefined) {
      setSearchText(event.value)
    }
  }

  return (
    <React.Fragment>
      <div className="card" style={{height: "100%"}}>
        <div className="card-body">
          <div className="news-data-grid-container">
            <LoadPanelComponent
              position={{of: "#dataGrid"}}
              visible={isLoading}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: 10,
                margin: 10,
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  flexDirection: "row",
                }}
              >
                <form
                  onSubmit={(e) => {
                    e.preventDefault()
                    searchNews()
                  }}
                  style={{display: "flex"}}
                >
                  <TextBox
                    width={500}
                    name="search"
                    style={{borderRadius: 15}}
                    showClearButton={true}
                    value={searchText}
                    onValueChanged={handleInputText}
                    placeholder="Гарчиг,нийтэлсэн байгууллага оруулах"
                  ></TextBox>
                  <DevExtremeButton
                    icon="search"
                    text="Хайх"
                    className="saveButton"
                    type="default"
                    style={{marginLeft: 10, borderRadius: 15}}
                    onClick={() => {
                      searchNews()
                    }}
                  />
                </form>
              </div>
              <DevExtremeButton
                text="Мэдээ нэмэх"
                className="saveButton"
                type="default"
                onClick={() => {
                  setToggleCreateMenu(true)
                }}
              />
            </div>
            <DataGrid
              id="dataGrid"
              dataSource={allNews}
              remoteOperations={true}
              keyExpr="id"
              wordWrapEnabled={true}
              noDataText="Дата байхгүй байна."
              onRowRemoved={deleteHandler}
              showBorders={true}
              showColumnLines={true}
              showRowLines={true}
              onCellPrepared={handleOnCellPrepared}
              hoverStateEnabled={true}
              allowColumnResizing={true}
              syncLookupFilterValues={false}
              scrolling={true}
              paging={{enabled: false}}
              onInitialized={function (e) {
                dataGrid = e.component
              }}
            >
              <Scrolling mode="standard" />
              <FilterRow visible={true} />

              <Editing
                allowUpdating={true}
                allowDeleting={true}
                confirmDelete={false}
                mode="popup"
                height="20rem"
                texts={{
                  cancelAllChanges: "Болих",
                  cancelRowChanges: "Болих",
                  confirmDeleteMessage: "Энэ мэдээллийг устгах уу?",
                  confirmDeleteTitle: "Баталгаажуулах",
                  deleteRow: "Устгах",
                  saveAllChanges: "Хадгалах",
                  saveRowChanges: "Хадгалах",
                  undeleteRow: "Буцаах",
                  validationCancelChanges: "Баталгаажуулах болих",
                  yes: "Тийм",
                  no: "Үгүй",
                }}
              >
                <Button name="save" text="Хадгалах" />
                <Button name="cancel" text="Цуцлах" />
                <Button />
                <DataGridPopUp
                  title="Мэдээ удирдах"
                  showTitle={true}
                  width={1000}
                  height={"90%"}
                />
                <DataGridForm>
                  <DataGridItem
                    dataField="createdAt"
                    colSpan={2}
                    dataType="date"
                  />
                  <DataGridItem
                    dataField="updatedAt"
                    colSpan={2}
                    dataType="date"
                  />
                  <DataGridItem
                    dataField="news_title"
                    colSpan={2}
                    editorType="dxTextArea"
                  />
                  <DataGridItem
                    dataField="news_text"
                    colSpan={2}
                    editorType="dxTextArea"
                  />
                </DataGridForm>
              </Editing>
              <Column
                caption="№"
                cssClass="custom"
                alignment="center"
                allowEditing={false}
                width={50}
                cellRender={orderRender}
              />

              <Column
                dataField="createdAt"
                dataType="date"
                colCount={1}
                colSpan={2}
                cssClass={"hideIconFilter"}
                width="8rem"
                allowFiltering={true}
                format="yyyy-MM-dd HH:mm"
                caption="Нийтлэсэн огноо"
                allowEditing={false}
              />
              <Column
                dataField="updatedAt"
                dataType="date"
                visible={true}
                cssClass={"hideIconFilter"}
                width="6rem"
                allowFiltering={true}
                caption="Шинэчилсэн огноо"
                allowEditing={false}
              />
              <Column
                dataField="news_title"
                colCount={1}
                colSpan={2}
                alignment={"center"}
                caption="Гарчиг"
                minWidth={"15rem"}
                allowFiltering={true}
              />
              <Column
                dataField="author"
                colCount={1}
                colSpan={2}
                minWidth={"15rem"}
                allowFiltering={false}
                alignment="center"
                caption="Нийтлэсэн"
              />
              <Column
                dataField="youtube_url"
                colCount={1}
                colSpan={2}
                width="5rem"
                allowFiltering={false}
                alignment="center"
                caption="Бичлэг"
                cellRender={renderUrlLink}
              />
              <Column
                dataField="is_private"
                colCount={1}
                allowFiltering={true}
                colSpan={2}
                width={120}
                caption="Дээд шатны байгууллагад харагдах эсэх"
                alignment="center"
              >
                <Lookup
                  allowClearing={true}
                  dataSource={lookUpList}
                  valueExpr="is_private"
                  displayExpr="text"
                />
              </Column>
              <Column
                dataField="is_opendata"
                colCount={1}
                allowFiltering={true}
                width={120}
                colSpan={2}
                caption="Олон нийтэд харагдах эсэх"
                alignment="center"
              >
                <Lookup
                  allowClearing={true}
                  dataSource={lookUpList2}
                  valueExpr="is_opendata"
                  displayExpr="text"
                />
              </Column>
              <Column
                dataField="news_type"
                colCount={1}
                colSpan={2}
                caption="Мэдээний ангилал"
                alignment="center"
                width={150}
                allowFiltering={true}
              >
                <Lookup
                  allowClearing={true}
                  dataSource={lookUpType}
                  valueExpr="news_type"
                  displayExpr="text"
                />
              </Column>

              <Column
                visible={false}
                dataField="markup"
                caption="Нийтлэл засах"
              />
              <Column type="buttons" width={"8rem"} caption={"Үйлдэл"}>
                <Button name="save" text="Хадгалах" />
                <Button name="cancel" text="Цуцлах" />
                <Button render={(e) => renderButton(e, 3)} />
                <Button render={(e) => renderButton(e, 4)} />
                <Button />
              </Column>
            </DataGrid>
            <Pagination
              style={{float: "right", marginTop: "1rem"}}
              total={totalNumber}
              pageSize={pageSize}
              showSizeChanger={false}
              locale={{
                page: "Хуудас",
                jump_to: "Очих",
                prev_page: "Өмнөх хуудас",
                next_page: "Дараагийн хуудас",
              }}
              current={currentPage}
              onChange={onPageChange}
              onShowSizeChange={onPageSizeChange}
            />
            {renderEditMenu()}
            {renderCreateMenu()}
            {renderVideoPopup()}
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default AddNews

import React, { useEffect, useState, useContext } from "react";
import OrganzationService from "../../../services/api/organization";
import "devextreme-react/text-area";
// import regIcon from "../../../assets/imgs/regIcon.png";
import { Tooltip } from "devextreme-react/tooltip";
import { Popup } from "devextreme-react/popup";
import LoadPanelComponent from "../../budget/components/loadPanel";
import RemainingService from "../../../services/api/remainingService";
import styles from "../myInfo.module.css";
import { UserContext } from "../../../hooks/UserContext";
import { renderUserDetail } from "..";

function ProfileSection(props) {
  const [data, setData] = useState(null);
  const [strData, setstrData] = useState(null);
  const [isLoading, setIsloading] = useState(true);
  const [withAnimationVisible4, setWithAnimationVisible4] = useState(false);
  const [firstPopup, setFirstPopup] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const result = await OrganzationService.orgDetailAttr();
    if (result.success) {
      setData(result.data);
      setIsloading(false);
    }

    const result1 = await RemainingService.stateStructureAtt();
    if (result1.success) {
      setstrData(result1.data);
      setIsloading(false);
    }
  };

  const position = { of: "#page" };

  return (
    <React.Fragment >
      <LoadPanelComponent position={position} visible={isLoading} />
      <Popup
        visible={firstPopup}
        showTitle={false}
        height="95vh"
        hideOnOutsideClick={true}
        onHiding={() => {
          setFirstPopup(false);
        }}
      >
        <iframe
          width="100%"
          style={{ height: "100vh" }}
          src={`//opendata.burtgel.gov.mn/lesinfo/${data && data?.[0]?.regno}`}
        />
      </Popup>
      <Tooltip
        target="#asdf4"
        position="bottom"
        visible={withAnimationVisible4}
        hideOnOutsideClick={true}
      >
        <div>Улсын бүртгэлийн мэдээлэл харах</div>
      </Tooltip>
      <div className={styles.profBody} >
        {!isLoading && (
          <div>
            <div style={{ color: "#15186a", fontSize: "1rem", fontWeight: "bold" }}>
              БАЙГУУЛЛАГЫН МЭДЭЭЛЭЛ
            </div>
            <div className="card-body" style={{ wordBreak: 'break-word' }}>
              {renderUserDetail('Байгууллагын нэр', data[0]?.organization_name)}
              {renderUserDetail('Регистрийн дугаар', data?.filter((el) => el.name === "reg_number").map((el) => el.value))}
              <div className={styles.row}>
                <div className={styles.columnRight}>
                  Ажлын утас:
                </div>
                <div className={styles.columnLeft}
                  dangerouslySetInnerHTML={{
                    __html: data
                      .filter((el) => el.name === "telephone")
                      .map((el) => el.value),
                  }} />
              </div>
              {renderUserDetail('OpenWeb', <a href={data.find(el => el.name === 'domain_name')?.value} target="_blank" rel="noopener">
                {data.map((el, index) => (
                  el.name === 'domain_name' ? (
                    <span key={index} dangerouslySetInnerHTML={{ __html: el.value }} />
                  ) : null
                ))}
              </a>)}
              {renderUserDetail('Вэб сайт', <a href={data.find(el => el.name === 'website')?.value} target="_blank" rel="noopener">
                {data.map((el, index) => (
                  el.name === 'website' ? (
                    <span key={index} dangerouslySetInnerHTML={{ __html: el.value }} />
                  ) : null
                ))}
              </a>)}
              {renderUserDetail('Имэйл', data.filter((el) => el.name === "email").map((el) => el.value))}
              <div className={styles.row}>
                <div className={styles.columnRight}>
                  Хаяг:
                </div>
                <div className={styles.columnLeft}
                  dangerouslySetInnerHTML={{
                    __html: data
                      .filter((el) => el.name === "address")
                      .map((el) => el.value),
                  }} />
              </div>
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );

}
export default ProfileSection;

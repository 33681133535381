import { ScrollView, LoadPanel } from "devextreme-react";
import React, { useState } from "react";
import NotificationCard from "./NotificationCard";
import debounce from 'lodash.debounce'

function ThreeDotSpinner() {
  return (
    <div className="spinner-container">
      <div className="dot"></div>
      <div className="dot"></div>
      <div className="dot"></div>
    </div>
  );
}

function NotificationPopover({ notificationUsers, showNotificationPopup, loadMore, totalPage, pageIndex }) {
  // console.log("notifications", notificationUsers);
  const [loading, setLoading] = useState(false)
  const handleScroll = debounce(async (e) => {
    if (e.reachedBottom === true) {
      setLoading(true)
      await loadMore()
    }
    if(pageIndex >= totalPage){
      setLoading(false)
    }
  }, 500)
  return (
    <ScrollView
      height="100%"
      width="100%"
      onScroll={handleScroll}
    >
      <div className="notificationPopover-container" style={{ display: 'flex', flexDirection: 'column', alignItems: "center", justifyContent: 'space-between' }}>
        {notificationUsers.map((item, key) => {
          return (
            <NotificationCard
              key={key}
              showNotificationPopup={showNotificationPopup}
              {...item}
            />
          )
        })}

        {loading && <ThreeDotSpinner/>}
      </div>

    </ScrollView>
  );

}


export default NotificationPopover;

import React, {useContext, useRef, useEffect, useState, useCallback, useMemo} from "react"
import {UserContext} from "../../../hooks/UserContext"

import SelectBox from "devextreme-react/select-box"
import LoadPanelComponent from "../../budget/components/loadPanel"
import {formatDate, getYears} from "../../../util";
import PlanService from "../../../services/api/plan";
import notify from "devextreme/ui/notify";
import DataGrid, {
    Button,
    Column, Editing, Export,
    FilterPanel,
    Grouping, GroupItem,
    GroupPanel,
    Toolbar,
    HeaderFilter, Lookup, 
    Paging, Summary, TotalItem
} from "devextreme-react/data-grid";
import { Item } from "devextreme-react/tab-panel";
import {Workbook} from "exceljs";
import {exportDataGrid} from "devextreme/excel_exporter";
import moment from "moment";
import {Button as ToolbarButton} from "devextreme-react/button";
import saveAs from "file-saver"
import {useHistory} from "react-router-dom";
import {custom} from "devextreme/ui/dialog";
import {Popup} from "devextreme-react/popup";
import ScrollView from "devextreme-react/scroll-view";
import ScheduleOfferChart from "./ScheduleOfferChart";
import {PlanTimeLine} from "./PlanTimeLine";
import TextBox from "devextreme-react/text-box";
import notificationUserService from "../../../services/api/notificationUser";
import Joyride from 'react-joyride';
import { guideLocale, huvaariinSanalSteps } from "../../../components/SystemGuideSteps";
import PlanOfferSheduleReport from "./PlanOfferSheduleReport";

export default function PlanningOfferMerge(props) {
    const [selectedStatus, setSelectedStatus] = useState(0);
    const [statusData, setStatusData] = useState([]);
    const [mergeData, setMergeData] = useState([]);
    const [year, setYear] = useState(new Date().getFullYear())
    const [loader, setLoader] = useState(false)
    const {user} = useContext(UserContext);
    const dataGridRef = useRef(null)
    const [setExpand, setSetExpand] = useState(false)
    const targetRef = useRef(null)
    const history = useHistory();
    //const [logComment, setLogComment] = useState(null)
    const sendMof = useMemo(() => [6], [])
    const approveConditions = useMemo(() => [8], [])
    const cancelConditions = useMemo(() => [2, 3, 8], [])
    const joinConditions = useMemo(() => [2, 3], [])
    const [selectionData, setSelectionData] = useState(null);
    const [popupDetail, setPopupDetail] = useState(false);
    const [modalReport, setModalReport] = useState(false);
    useEffect(() => {
        loadStatusList();
        loadMergeList();
    }, [props.deps, year, selectedStatus])

    const setVisibleJoin = (e) => {
        let edit = false;
        switch (user.organization.obt_id) {
            case 1:
                edit = [2, 8].includes(e.row.data.budget_project_status_id) ? true : false;
                break
            case 2:
                edit = [3].includes(e.row.data.budget_project_status_id) ? true : false;
                break
            case 3:
                edit = [1, 4, 5].includes(e.row.data.budget_project_status_id) ? true : false;
                break;
        }
        return edit && joinConditions.includes(e.row.data.budget_project_status_id);
    };
    const setVisibleApprove = (e) => {
        return user.organization.obt_id === 1 && approveConditions.includes(e.row.data.budget_project_status_id);
    };
    const setVisibleCancel = (e) => {
        let edit = false;
        switch (user.organization.obt_id) {
            case 1:
                edit = [2, 8].includes(e.row.data.budget_project_status_id) ? true : false;
                break
            case 2:
                edit = [3].includes(e.row.data.budget_project_status_id) ? true : false;
                break
            case 3:
                edit = [1, 4, 5].includes(e.row.data.budget_project_status_id) ? true : false;
                break;
        }

        return edit && cancelConditions.includes(e.row.data.budget_project_status_id);
    };
    const setVisibleSendMof = (e) => {
        return user.organization.obt_id === 1 && sendMof.includes(e.row.data.budget_project_status_id);
    };
    const loadStatusList = async () => {
        try {
            let r = await PlanService.planOfferStatusList(user.org_id, year);
            setStatusData(r.data);
        } catch (e) {
            notify(e.message, "error", 2000);
        }
    }
    const loadMergeList = async () => {
        try {
            setLoader(true);
            let r = await PlanService.planOfferMergeList(user.org_id, year, selectedStatus);
            console.log(r.data);
            setMergeData(r.data);
            setLoader(false);
        } catch (e) {
            setLoader(false);
            notify(e.message, "error", 2000);
        }
    }
    const customizeDate = (data) => {
        return Number(parseFloat(data.value / 1000000).toFixed(2)).toLocaleString("en", {
            minimumFractionDigits: 2,
        });
    }
    const onCellPreparedHandler = (e) => {
        if (e.rowType === 'header') {
            e.cellElement.wordWrapEnabled = true;
            e.cellElement.style.fontFamily = "Segoe UI";
            e.cellElement.style.fontWeight = "bold";
            e.cellElement.style.color = "#15186A";
            e.cellElement.style.alignment = "center";
            e.cellElement.style.backgroundColor = "#EEF0F4";
            e.cellElement.style.borderColor = "#D4DCEC";
            e.cellElement.style.fontSize = "12px";
        } else if (e.rowType === 'group') {
            e.cellElement.style.fontFamily = "Segoe UI"
            //e.cellElement.wordWrapEnabled = true;
            e.cellElement.style.color = "#15186A"
            e.cellElement.style.backgroundColor = "#fff"
            e.cellElement.style.fontWeight = "bold";
            e.cellElement.style.fontSize = "12px"
            e.cellElement.style.borderColor = "#D4DCEC"
        } else if (e.rowType === "data") {
            e.cellElement.style.fontFamily = 'Segoe UI'
            e.cellElement.style.fontWeight = 'normal'
            e.cellElement.style.color = "#000000"

        }
    }
    const onExporting = (e) => {
        const workbook = new Workbook()
        const worksheet = workbook.addWorksheet("Main sheet")
        exportDataGrid({
            component: e.component,
            worksheet,
            autoFilterEnabled: true,
            keepColumnWidths: true,
            topLeftCell: {row: 6, column: 2},
            customizeCell: ({gridCell, excelCell}) => {
                excelCell.font = {name: 'Arial', size: 8}
                if (gridCell.rowType === 'header') {
                    excelCell.font = {name: 'Arial',size: 8, 'bold': true}
                    excelCell.fill = {type: 'pattern', pattern: 'solid', fgColor: {argb: 'd9d9d9'}};
                    // excelCell.border = {
                    //     top: {style: 'thin', color: {argb: '2c2c2c'}},
                    //     left: {style: 'thin', color: {argb: '2c2c2c'}},
                    //     bottom: {style: 'thin', color: {argb: '2c2c2c'}},
                    //     right: {style: 'thin', color: {argb: '2c2c2c'}}
                    // }
                }
                if (gridCell.rowType === 'group') {
                    excelCell.alignment = {wrapText: false};
                    excelCell.fill = {type: 'pattern', pattern: 'solid', fgColor: {argb: '63C1FE'}};
                }
                if (gridCell.rowType === 'totalFooter' && excelCell.value) {
                    excelCell.font.italic = true;
                }
                if (gridCell.rowType === "data") {
                    excelCell.border = {
                        top: {style: "thin", color: {argb: "2c2c2c"}},
                        left: {style: "thin", color: {argb: "2c2c2c"}},
                        bottom: {style: "thin", color: {argb: "2c2c2c"}},
                        right: {style: "thin", color: {argb: "2c2c2c"}},
                    }
                    if (typeof excelCell.value === 'number') {
                        excelCell.value = excelCell.value / 1000000
                    }

                }
            },
        })
            .then(() => {
                Object.assign(worksheet.getRow(1).getCell(1), {
                    value: 'Гарчиг:',
                    font: { name: 'Arial', size: 8 },
                })
                Object.assign(worksheet.getRow(1).getCell(2), {
                    value: `${year} оны хуваарийн саналын нэгтгэл`,
                    font: { name: 'Arial', size: 8, 'bold': true, color: { argb: '0070c0' } },
                })
                Object.assign(worksheet.getRow(2).getCell(1), {
                    value: 'Байгууллага:',
                    font: { name: 'Arial', size: 8 },
                })
                Object.assign(worksheet.getRow(2).getCell(2), {
                    value: user.organization.name,
                    font: { name: 'Arial', size: 8 },
                })
                Object.assign(worksheet.getRow(3).getCell(1), {
                    value: 'Хэрэглэгч:',
                    font: { name: 'Arial', size: 8 },
                })
                Object.assign(worksheet.getRow(3).getCell(2), {
                    value: `${user.last_name.slice(0, 1)}. ${user.first_name}, ${user.position_name}`,
                    font: { name: 'Arial', size: 8 },
                })
                Object.assign(worksheet.getRow(4).getCell(1), {
                    value: 'Огноо:',
                    font: { name: 'Arial', size: 8 },
                })
                Object.assign(worksheet.getRow(4).getCell(2), {
                    value: new moment().locale('mn').format('lll'),
                    font: { name: 'Arial', size: 8 },
                })    
            })
            .then(() => {
                workbook.xlsx.writeBuffer().then((buffer) => {
                    saveAs(
                        new Blob([buffer], {type: "application/octet-stream"}),
                        `OG-${year} оны хуваарийн санал нэгтгэл-` + formatDate(new Date()) + ".xlsx"
                    )
                })
            })
        e.cancel = true
    }
    const cellRenderOrgName = (data) => {
        // console.log(data);
        return <div>
            <span className={`row-pr-org-name`}><b>{data.displayValue}</b></span>
            <span className="row-pr-org-aimag">{data.data.aimag_name}</span>
        </div>
    };
    const cellRenderParentOrgName = (data) => {
        // console.log(data);
        return <div>
            <span className={`row-pr-org-name`}><b>{data.displayValue}</b></span>
        </div>
    };
    const cellRenderStatus = (data) => {
        //console.log('cellRenderStatus', data);
        return <div style={{flex: 1, display: 'flex', flexDirection: 'column'}}>
            <span className={`proposal-status-${data.data.budget_project_status_id}`}>{data.displayValue}</span>
        </div>
    };
    const customizeOgnoo = (e) => {
        return `${moment(e.value).locale("mn").format("YYYY-MM-DD HH:mm")}`;
    }
    const goDetail = (e) => {
        //console.log('goDetail', e);
        const location = {
            pathname: '/plan/offer',
            state: {org_id: e.row.data.org_id, budget_year: e.row.data.budget_year,notOrg: e.row.data.isNotOrg}
        }
        history.push(location);
    }

    const modifyStatus = useCallback(async (isCancel, nextStatus, projectId, currentStatus) => {
        setLoader(true);
        //console.log("modifying request",request)
        //console.log('logComment', logComment);
        const req = {
            "budget_project_id": projectId,
            "current_status": currentStatus,
            "change_status": nextStatus,
            "comment": 'Тайлбар бичигдээгүй',
        }
        //console.log('req', req);
        await PlanService.changeStatusProject(req).then((res) => {
            //notify("Хуваарийн саналыг амжилттай илгээлээ", "success", 4000)
            //pushNotif(nextStatus)
        })
            .catch((e) => {
                setLoader(false);
                console.log("err->", e)
            })
            .finally(() => {
                //setLogComment(null);
                setLoader(false);
            })
    }, [])

    const getNextActionProceed = useCallback((status, isCancel, parentObtId) => {
        switch (status) {
            case 1:  //ТШЗ-Боловсруулж байгаа,
                return parentObtId === 1 ? 2 : 3;
            case 2:  //ТЕЗ-д хүргүүлсэн
                return isCancel ? 4 : 6;
            case 3:  //ТТЗ-д хүргүүлсэн
                return isCancel ? 5 : 7;
            case 4: //ТЕЗ-Татгалзсан
                return parentObtId === 1 ? 2 : 3;
            case 5: //ТТЗ-Татгалзсан
                return parentObtId === 1 ? 2 : 3;
            case 6: //ТЕЗ-Нэгтгэсэн
                return 8;
            case 7:  //ТТЗ-Нэгтгэсэн
                return 2;
            case 8:
                return 9;
            default:
                // console.log("err baihgui", status)
                return status
        }
    }, [])

    const getDescriptionPrev = useCallback((status) => {
        switch (status) {
            case 1:
                return `<p style='color: black;'>Та энэ саналыг <strong style='color: #50c787;'>төсвийн дараагийн захирагч руу илгээхдээ</strong> итгэлтэй байна уу?</p>`;
            case 4:
                return `<p style='color: black;'>Та энэ саналыг <strong style='color: #50c787;'>төсвийн дараагийн захирагч руу илгээхдээ</strong> итгэлтэй байна уу?</p>`;
            case 5:
                return `<p style='color: black;'>Та энэ саналыг <strong style='color: #50c787;'>төсвийн дараагийн захирагч руу илгээхдээ</strong> итгэлтэй байна уу?</p>`; // check
            case 3:
                return `<p style='color: black;'>Та энэ саналыг <strong style='color: #50c787;'>нэгтгэхдээ</strong> итгэлтэй байна уу?</p>`; //'nextOBT'
            case 7:
                return `<p style='color: black;'>Та энэ саналыг <strong style='color: #50c787;'>төсвийн ерөнхийлөн захирагч руу илгээхдээ</strong> итгэлтэй байна уу?</p>`;
            case 2:
                return `<p style='color: black;'>Та энэ саналыг <strong style='color: #50c787;'>нэгтгэхдээ</strong> итгэлтэй байна уу?</p>`; //'merge'
            case 6:
                return `<p style='color: black;'>Та энэ саналыг <strong style='color: #50c787;'>СЯ-руу хүргүүлэхдээ</strong> итгэлтэй байна уу?</p>`;//'merge'
            case 8:
                return `<p style='color: black;'>Та энэ саналыг <strong style='color: #50c787;'>батлахдаа</strong> итгэлтэй байна уу?</p>`;//'confirm'
            default:
                return 'Тодорхойлогдоогүй тайлбар' // 'not defined status code
        }
    }, [])


    const getDescription = useCallback((status) => {
        switch (status) {
            case 1:
                return 'төсвийн дараагийн захирагч руу илгээгдлээ';
            case 4:
                return 'төсвийн дараагийн захирагч руу илгээгдлээ';
            case 5:
                return 'төсвийн дараагийн захирагч руу илгээгдлээ'; // check
            case 3:
                return 'нэгтгэгдлээ'; //'nextOBT'
            case 7:
                return 'төсвийн ерөнхийлөн захирагч руу илгээгдлээ';
            case 2:
                return 'нэгтгэгдлээ' //'merge'
            case 6:
                return 'СЯ-руу хүргүүлсэн' //'merge'
            case 8:
                return 'батлагдлаа' //'confirm'
            default:
                return 'Тодорхойлогдоогүй тайлбар' // 'not defined status code
        }
    }, [])
    const onClickActionSendAll = async () => {
        let mergeDataCnt = mergeData.length;
        let checkCnt = user.organization.obt_id === 2 ? mergeData.filter(v => v.budget_project_status_id === 7).length : mergeData.filter(v => v.budget_project_status_id === 6).length;
        let isCancel = false;
        let status = user.organization.obt_id === 2 ? 7 : 6;
        let messageHtml = getDescriptionPrev(status);
        if(mergeDataCnt === 0) return notify(`Өгөгдөл байхгүй байна`,'warning',3000);
        if(mergeDataCnt !==checkCnt)
        {
            return notify(user.organization.obt_id === 2 ? `Доорх байгууллагууд бүгд ТТЗ-Нэгтгэсэн төлөвтэй байх ёстой` : `Доорх байгууллагууд бүгд ТЕЗ-Нэгтгэсэн төлөвтэй байх ёстой`,'warning',5000);
        }
        else {
            const customConfirm = custom({
                title: "Баталгаажуулалт",
                messageHtml: messageHtml,
                buttons: [
                    {
                        text: "Тийм",
                        onClick: (e) => {
                            return true
                        },
                    },
                    {
                        text: "Үгүй",
                        onClick: (e) => {
                            return false
                        },
                    },
                ],
            })
            customConfirm.show().then(async (dialogResult) => {
                if (dialogResult) {
                    try {
                        let description = getDescription(status);
                        let nextStatus = null
                        nextStatus = getNextActionProceed(status, isCancel,null);
                        // console.log('nextStatus', nextStatus);
                        for (let i=0; i<mergeData.length; i++) {
                            modifyStatus(isCancel, nextStatus, mergeData[i].id, status);
                            pushNotif(mergeData[i].id, 'send', mergeData[i].org_id, year, mergeData[i].budget_project_type);
                        }
                        await loadStatusList();
                        await loadMergeList();
                        notify(`${year} - оны ${user.organization.name} харьяа ${mergeDataCnt} байгууллагын хуваарийн саналын мэдээлэл ${description}`, "success", 4000);
                    } catch (e) {
                        console.log(e);
                        notify(`Алдаа гарлаа`, 'error');
                    }
                }
            });
        }

    }
    const onClickReport = () => {
        setModalReport(true);
    }
    const onClickAction = async (e, action) => {
        let status = e.row.data.budget_project_status_id;
        let isCancel = action === 'approve' ? false : true;
        let messageHtml;
        if (action === 'approve') {
            //title = "Condition is true";
            messageHtml = getDescriptionPrev(status);
        } else if (action === 'deny') {
            //title = "Condition is false";
            messageHtml = "<p style='color: black;'>Та энэ саналыг <strong style='color: #F55A5A;'>ТАТГАЛЗАХДАА</strong> итгэлтэй байна уу?</p>";
        }

        if (action !== 'approve' && action !== 'deny') {
            notify(`Өгөгдөл дутуу байна`);
            return;
        }

        const customConfirm = custom({
            title: "Баталгаажуулалт",
            messageHtml: messageHtml,
            buttons: [
                {
                    text: "Тийм",
                    onClick: (e) => {
                        return true
                    },
                },
                {
                    text: "Үгүй",
                    onClick: (e) => {
                        return false
                    },
                },
            ],
        })

        customConfirm.show().then(async (dialogResult) => {
            if (dialogResult) {
                try {
                    let description = isCancel ? 'цуцлагдлаа' : getDescription(status);
                    let nextStatus = null
                    switch (action) {
                        case "deny":
                            nextStatus = getNextActionProceed(status, isCancel, e.row.data.parent_obt_id);
                            break;
                        case "approve":
                            nextStatus = getNextActionProceed(status, isCancel, e.row.data.parent_obt_id);
                            break;
                        default:
                            break;
                    }
                    //console.log('nextStatus', nextStatus);
                    modifyStatus(isCancel, nextStatus, e.row.data.id, status);
                    await loadStatusList();
                    await loadMergeList();
                    notify(`${e.row.data.budget_year} - оны ${e.row.data.organization_name} байгууллагын хуваарийн саналын мэдээлэл ${description}`, "success", 2000);
                    pushNotif(e.row.data.id, action, e.row.data.org_id, e.row.data.budget_year, e.row.data.budget_project_type)
                } catch (e) {
                    console.log(e);
                    notify(`Алдаа гарлаа`, 'error');
                }
            }
        });
    };
    const onChartDetail = async (e) => {
        if (e.row.data) {
            setLoader(true);
            setSelectionData(e.row.data);
            setPopupDetail(true);
            setLoader(false);
        }
    }
    const renderDetail = props => {
        return (
            <Popup
                visible={popupDetail}
                onHiding={() => setPopupDetail(false)}
                hideOnOutsideClick={true}
                showCloseButton={true}
                height="100vh"
                width="90%"
                showTitle={true}
                title={selectionData.organization_name}
            >
                <ScrollView width="100%" height="100%">
                    <div className="card-body" style={{paddingBottom: "1.6rem"}}>
                        <ScheduleOfferChart budgetYear={selectionData.budget_year} orgId={selectionData.org_id} agendaEventId={0}/>
                    </div>
                </ScrollView>
            </Popup>
        )
    }
    const selectBoxFieldRender = (data) => {
        return (
            <div style={{flex: 1, display: 'flex', flexDirection: 'column'}}>
                <TextBox className={data && `proposal-status-${data.id}`}
                         inputAttr={{'aria-label': 'name'}}
                         defaultValue={data && data.name + `\n (${data.percent}%)`}
                         readOnly={true}/>
            </div>
        );
    }
    const selectBoxItemRender = (data) => {
        return (
            <div style={{flex: 1, display: 'flex', flexDirection: 'column'}}>
                <div className={data && `proposal-status-${data.id}`} style={{marginLeft:5,marginRight:5}}>{data && data.name} ({data.percent}%)</div>
            </div>
        );
    }

    const pushNotif = useCallback(async (proposalId, action, proposalOrgId, year, budget_project_type) => {
        await notificationUserService.proposalNotification({
          proposalId: proposalId,
          action: action,
          proposalOrgId: proposalOrgId,
          year: year,
          budget_project_type: budget_project_type
        })
      }, [])

    const baseCnt = parseInt(statusData[0]?.cnt)
    const statusData1 = statusData.map((e) => {
        const currentCnt = parseInt(e?.cnt)
        const percent = ((currentCnt / baseCnt) * 100).toFixed(1)
        return { ...e, percent }
    })

    let statusId;

    const status1 = mergeData.every(data => data.budget_project_status_id === 1) // ТШЗ боловсруулж байгаа //Бүгд//
    const status2 = mergeData.every(data => data.budget_project_status_id === 2) // TEZ хүргүүлсэн //Бүгд//
    const status3 = mergeData.every(data => data.budget_project_status_id === 3) // TТZ хүргүүлсэн //Бүгд//
    const status4 = mergeData.every(data => data.budget_project_status_id === 4) // TEZ tatgalzsan //Бүгд//
    const status5 = mergeData.every(data => data.budget_project_status_id === 5) // TТZ tatgalzsan //Бүгд//
    const status6 = mergeData.every(data => data.budget_project_status_id === 6) // ТЕЗ нэгтгэсэн //Бүгд//
    const status7 = mergeData.every(data => data.budget_project_status_id === 7) // ТТЗ нэгтгэсэн //Бүгд//
    const status8 = mergeData.every(data => data.budget_project_status_id === 8) // СЯ хүргүүлсэн //Бүгд//
    const status9 = mergeData.every(data => data.budget_project_status_id === 9) // СЯ баталсан //Бүгд//

    const deny1 = mergeData.some(data => data.budget_project_status_id === 1)
    const deny2 = mergeData.some(data => data.budget_project_status_id === 3) // TТZ хүргүүлсэн /зарим нь /
    const deny3 = mergeData.some(data => data.budget_project_status_id === 2) // TEZ хүргүүлсэн /зарим нь /
    const deny4 = mergeData.some(data => data.budget_project_status_id === 5) // TТZ tatgalzsan /зарим нь /
    const deny5 = mergeData.some(data => data.budget_project_status_id === 4) // TEZ tatgalzsan /зарим нь /
    const deny6 = mergeData.some(data => data.budget_project_status_id === 6) // ТЕЗ нэгтгэсэн /зарим нь /
    const deny7 = mergeData.some(data => data.budget_project_status_id === 7) // ТТЗ нэгтгэсэн /зарим нь/
    const deny8 = mergeData.some(data => data.budget_project_status_id === 8) // СЯ хүргүүлсэн /зарим нь /
    const deny9 = mergeData.some(data => data.budget_project_status_id === 9) // СЯ баталсан /зарим нь /

    if(user.organization.obt_id === 2){
        if (status9) {
            statusId = 9
        } else if(deny9){
            statusId = 8
        } else if (status8) {
            statusId = 8
        } else if (deny8) {
            statusId = 6
        } else if (status6) {
            statusId = 6
        } else if (deny6) {
            statusId = 2
        }  else if (status7) {
            statusId = 7
        } else if(deny7) {
            statusId = 3
        } else if (status4) {
            statusId = 4
        } else if (status5) {
            statusId = 5
        } else if (status2) {
            statusId = 2
        } else if (status3) {
            statusId = 3
        } else if (status1) {
            statusId = 1
        } else if (deny6) {
            statusId = 2
        } else if (deny5) {
            if (deny3) {
                statusId = 2
            } else if (deny5) {
                statusId = 2
            }
        } else if (deny4) {
            if (deny2) {
                statusId = 3
            }
        } else if (deny1) {
            statusId = 1
        } else if (deny2) {
            statusId = 3
        } 
    } else {
        if(status9){
            statusId = 9
        } else if (status8){
            statusId = 8
        } else if (status6){
            statusId = 6
        } else if (status2){
            statusId = 2
        } else if (status7){
            statusId = 7
        } else if (status3){
            statusId = 3
        } else if(deny1){
            statusId = 1
        }
    }

    const renderReport = props => {
        return (
            <Popup
                visible={modalReport}
                onHiding={() => setModalReport(false)}
                hideOnOutsideClick={true}
                showCloseButton={true}
                height="100vh"
                width="95%"
                showTitle={true}
                title={'Хуваарийн саналын нэгтгэл тайлан'}
            >
                <ScrollView width="100%" height="100%">
                    <PlanOfferSheduleReport selectedYear={year} status={selectedStatus}/>
                </ScrollView>
            </Popup>
        )
    }
    return (
        <div style={{ padding: '10px' }}>
            <LoadPanelComponent position={{of: "#loader"}} visible={loader}/>
            {user.organization.obt_id !== 3 &&
                <Joyride
                    run={true}
                    steps={huvaariinSanalSteps}
                    continuous
                    showSkipButton
                    styles={{
                        options: {
                            zIndex: 100,
                            display: 'none'
                        }
                    }}
                    locale={guideLocale}
                    disableScrolling={true}
                />}
            <div style={{alignItems: 'center'}}>
                <div className="row" style={{ display: "flex", justifyContent: 'space-between', alignItems: "baseline", flexWrap: 'wrap', gap: '1rem' }}>
                    <div style={{ display: "flex", alignItems: "baseline", flexWrap: 'wrap', gap: '1rem' }}>
                        <div>
                            <SelectBox
                                width='10rem'
                                items={getYears(true)}
                                label={'Төсвийн жил'}
                                defaultValue={year}
                                hint={'Хуваарийн саналын жил'}
                                onValueChanged={(e) => setYear(e.value)}
                                placeholder="сонгох"
                            />
                        </div>

                        <div style={{ alignItems: "center" }}>
                                <span className="proposal-org-title" style={{
                                    color: "#003696",
                                    textTransform: "uppercase",
                                    fontWeight: '700'
                                }}>{" "} {user.organization.name}</span>
                        </div>
                    </div>

                    <div style={{ alignItems: 'baseline' }}>
                        <ToolbarButton icon="check" text={user.organization.obt_id === 1 ? 'СЯ руу хүргүүлэх' : 'ТЕЗ рүү хүргүүлэх'} type={'success'}
                            onClick={onClickActionSendAll} style={{ backgroundColor: '#337AB7', color: '#fff', fontFamily: 'Segoe UI', borderColor: '#EEF0F4' }} />
                    </div>
                </div>
            </div>
            <div style={{ margin: '10px 0' }}>
                <PlanTimeLine statusId={statusId}/>
            </div>
            <div>
                <div>
                <DataGrid
                noDataText="Дата байхгүй байна."
                    dataSource={mergeData}
                    allowColumnReordering={true}
                    //rowAlternationEnabled={true}
                    columnAutoWidth={true}
                    showColumnLines={true}
                    showRowLines={true}
                    showBorders={true}
                    hoverStateEnabled={true}
                    keyExpr="id"
                    width={'100%'}
                    wordWrapEnabled={true}
                    onCellPrepared={onCellPreparedHandler}
                    onExporting={onExporting}
                    ref={dataGridRef}
                    //columnWidth={'auto'}
                    scrolling={true}
                ><FilterPanel
                    visible={true}
                    texts={{clearFilter: "Шүүлтүүр цэвэрлэх", createFilter: "Шүүх"}}
                />
                    <GroupPanel visible={user.organization.obt_id === 1}/>
                    <HeaderFilter visible={true}/>
                            <Toolbar>
                                <Item location='before'>
                                    <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                                        <span>Төлөв:</span>
                                        <SelectBox
                                            items={statusData1}
                                            // label={'Төлөв'}
                                            defaultValue={0}
                                            width={'16rem'}
                                            value={selectedStatus}
                                            valueExpr="id"
                                            displayExpr="name"
                                            inputAttr={{ 'aria-label': 'Templated Product' }}
                                            fieldRender={selectBoxFieldRender}
                                            itemRender={selectBoxItemRender}
                                            noDataText={'Тухайн жил дээр хуваарийн санал үүсгээгүй байна'}
                                            hint={'Хуваарийн саналын төлөв болон төлөв дээрх байгууллагуудын тоог харуулж байна'}
                                            onValueChanged={(e) => setSelectedStatus(e.value)}
                                            placeholder="сонгох"
                                        />
                                    </div>
                                </Item>
                                <Item location='before'>
                                    <div style={{alignItems: 'center',justifyContent:'flex-end'}}>
                                        <ToolbarButton hint="Нэгтгэл тайлан" icon="check" text={'Нэгтгэл тайлан'} type={'success'}
                                                       onClick={onClickReport} style={{backgroundColor:'green',color:'#fff'}}/>
                                    </div>
                                </Item>
                                <Item location='after'>
                                  
                                </Item>
                                <Item name="exportButton"
                                    // render={(e) => {
                                    //     return <ToolbarButton
                                    //         onClick={e.options.onClick}
                                    //         icon="xlsxfile"
                                    //         render={() => {
                                    //             return <BsFiletypeXls size={"1rem"} color="#172B4D" />
                                    //         }}
                                    //         style={{ color: "red" }}>
                                    //     </ToolbarButton>
                                    // }}
                                />
                            </Toolbar>
                    <Grouping
                        autoExpandAll={setExpand}
                        contextMenuEnabled={false}
                        expandMode="rowClick"
                        texts={{
                            groupContinuesMessage: 'Дараагийн хуудас',
                            groupContinuedMessage: 'Өмнөх хуудас'
                        }}
                    />

                    {
                        user.organization.obt_id === 1 &&
                        <Column
                            dataField="parent_org_name"
                            caption="ТТЗ"
                            dataType="text"
                            visible={user.organization.obt_id === 1}
                            cellRender={cellRenderParentOrgName}
                            groupIndex={0}
                            allowFiltering={user.organization.obt_id === 1}
                        />
                    }
                    {
                        user.organization.obt_id !== 1 &&
                        <Column
                            allowSorting={false}
                            alignment="center"
                            width={'2rem'}
                            cellRender={(data) => {
                                if (data.rowType === "data") {
                                  return <span>{data.rowIndex + 1}</span>;
                                }
                                return null;
                              }}
                        />
                    }
                    <Column
                        dataField="organization_name"
                        caption={user.organization.obt_id === 1 ? 'Төсвийн захирагч' : 'ТШЗ'}
                        dataType="text"
                        cellRender={cellRenderOrgName}
                    />
                    <Column dataField="budget_project_status_id" alignment="center" caption={'Төлөв'}
                            cellRender={cellRenderStatus} width={'10rem'}
                            defaultSortOrder="desc"
                    >
                        <Lookup dataSource={statusData} valueExpr="id" displayExpr="status_name"/>
                    </Column>
                    <Column dataField="revenue" alignment="right" caption={'Нийт орлого ба тусламжийн дүн'}
                            dataType="number" customizeText={customizeDate}  allowFiltering={false}>
                    </Column>
                    <Column dataField="expense" alignment="right" caption={'Нийт зарлага ба цэвэр зээлийн дүн'}
                            dataType="number" 
                            customizeText={customizeDate} /* cellRender={(e) => cellRenderValue(e, 'expense')}*/
                            allowFiltering={false}>
                    </Column>
                    <Column dataField="expense_source" alignment="right" caption={'Зардлыг санхүүжүүлэх эх үүсвэр дүн'}
                            dataType="number" customizeText={customizeDate} allowFiltering={false}>
                    </Column>
                    <Column dataField="max_history_date" alignment="right" caption="Төлөв шинэчлэгдсэн огноо"
                            dataType="date" format="yyyy-MM-dd HH:mm"/>
                    <Editing
                        mode="row"
                        allowUpdating={true}
                        allowAdding={false}
                        allowDeleting={false}
                        confirmDelete={true}
                        useIcons={true}
                        texts={{
                            addRow: 'Төсөл нэмэх',
                            cancelAllChanges: 'Болих бүгд',
                            cancelRowChanges: 'Болих',
                            confirmDeleteMessage: 'Энэ мэдээллийг устгах уу?',
                            confirmDeleteTitle: 'Баталгаажуулах',
                            deleteRow: 'Устгах',
                            editRow: 'Өөрчлөх',
                            saveAllChanges: 'Хадгалах бүгд',
                            saveRowChanges: 'Хадгалах',
                            undeleteRow: 'Буцаах',
                            validationCancelChanges: 'Баталгаажуулах болих',
                        }}
                    />
                    <Column type="buttons" fixedPosition={'right'} alignment="center" caption={'Үйлдэл'}
                            ref={targetRef}>
                        <Button icon="chart" text="Хуваарийн саналыг графикаар харах" onClick={onChartDetail} cssClass="grid-button"/>
                        <Button name={'edit'} icon="detailslayout" hint="Дэлгэрэнгүй" visible={true}
                                onClick={goDetail} cssClass="grid-button"/>
                        <Button hint="Нэгтгэх" icon="check" visible={setVisibleJoin} cssClass="approve"
                                onClick={(e) => onClickAction(e, 'approve')} iconStyle={{color: 'yellow'}}/>
                        <Button hint="Батлах" icon="check" visible={setVisibleApprove}
                                onClick={(e) => onClickAction(e, 'approve')} iconStyle={{color: 'yellow'}}/>
                        <Button hint="Сангийн яам руу илгээх" icon="check" visible={setVisibleSendMof}
                                onClick={(e) => onClickAction(e, 'approve')} iconStyle={{color: 'yellow'}}/>
                        <Button hint="Татгалзах" icon="close" visible={setVisibleCancel} cssClass="deny"
                                onClick={(e) => onClickAction(e, 'deny')}/>
                    </Column>
                    <Summary>
                        <GroupItem
                            column="revenue"
                            summaryType="sum"
                            customizeText={customizeDate}
                            showInGroupFooter={false} alignByColumn={true} showInColumn="status_revenue"/>
                        <GroupItem
                            column="expense"
                            summaryType="sum"
                            customizeText={customizeDate}
                            sshowInGroupFooter={false} alignByColumn={true} showInColumn="status_expense"/>
                        <GroupItem
                            column="expense_source"
                            summaryType="sum"
                            customizeText={customizeDate}
                            showInGroupFooter={false} alignByColumn={true} showInColumn="status_expense_source"/>
                        <GroupItem
                            column="max_history_date"
                            summaryType="max"
                            customizeText={customizeOgnoo}
                            showInGroupFooter={false} alignByColumn={true} showInColumn="max_history_date"/>
                        <GroupItem
                            column="organization_name"
                            summaryType="count"
                            displayFormat="{0}-Байгууллага"/>
                        <TotalItem
                            column="revenue"
                            summaryType="sum"
                            customizeText={customizeDate}/>
                        <TotalItem
                            column="expense"
                            summaryType="sum"
                            customizeText={customizeDate}/>
                        <TotalItem
                            column="expense_source"
                            summaryType="sum"
                            customizeText={customizeDate}/>
                        <TotalItem
                            column="max_history_date"
                            summaryType="max"
                            customizeText={customizeOgnoo}/>
                        <TotalItem
                            column="organization_name"
                            summaryType="count"
                            displayFormat="Танд нийт {0} байгууллагын мэдээлэл харуулж байна"/>
                    </Summary>
                    <Paging defaultPageSize={25}/>
                    <Export enabled={true} allowExportSelectedData={false}/>
                </DataGrid>
                </div>
            </div>
            {popupDetail && renderDetail()}
            {modalReport && renderReport()}
        </div>
    )

}

import request from '../../shared/lib/request'

const getAllGovAgenda = () => {
    return request({
        url: '/govAgenda/getAllGovAgenda',
        method: 'GET',
    })
}

const addGovAgenda = (body) => {
    return request({
        url: '/govAgenda/addGovAgenda',
        method: 'POST',
        data: body,
    })
}
const editGovAgenda = (body) => {
    return request({
        url: `/govAgenda/EditGovAgenda`,
        method: 'POST',
        data: body,
    })
}
const removeGovAgenda = (id) => {
    return request({
        url: '/govAgenda/RemoveGovAgenda/'+id,
        method: 'GET'
    })
}
const getOrgStartOfAncestry = () => {
    return request({
        url: '/govAgendaRelation/getOrgStartOfAncestry',
        method: 'GET',
    })
}

const updateGovAgendaRelation = (body) => {
    return request({
        url: '/govAgendaRelation/updateGovAgendaRelation',
        method: 'POST',
        data: body,
    })
}
const govAgendaServices = {
    getAllGovAgenda,
    addGovAgenda,
    editGovAgenda,
    removeGovAgenda,
    getOrgStartOfAncestry,
    updateGovAgendaRelation
}

export default govAgendaServices;
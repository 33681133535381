import request from '../../shared/lib/request'

function changeUserPermission(newInfo) {
    return request({
            url: "/rs/updatePermissionMenuRole",
        data: {...newInfo},
        method: 'POST'
    })
}

function getAllRoles() {
    return request({
        url: "/rs/retrieveUserRoles",
        method: 'GET'
    })
}

function getReferenceRoles() {
    return request({
        url: "/refs/getRoles",
        method: 'GET'
    })
}
function getRolesByOrg(obtId, soa) {
    return request({
        url: `/refs/getRolesByOrg?obtId=${obtId}&soa=${soa}`,
        method: 'GET',
    })
}

function getAllUsers() {
    return request({
        url: "/rs/retrieveAllUsers",
        method: 'GET'
    })
}

function loadMe(){
    return request({
        url:'/auth/me',
        method: 'GET'
    })
}
function changeUserRole(newUserInfo) {
    return request({
        url: '/rs/updateUserRole',
        data: {...newUserInfo},
        method: 'PUT'
    })
}

function getNotifications(userId) {
    return request({
        url: `/notificationsService/${userId}`,
        method: 'GET'
    })
}

function deleteNotification(userId, index) {
    return request({
        url: `/notificationDelete/${userId}/${index}`,
        method: 'POST'
    })
}

function postNotification(orgId, data) {
    return request({
        url: `/publishNotification/${orgId}`,
        data: data,
        method: 'POST'
    })
}

function stateStructureAtt(){
    return request({
        url:'/rs/stateStructureAtt',
        method: 'GET'
    })
}

function getAllLogs(year, yam, userId) {
    return request({
        url: `/rs/getAllLogs/${year}/${yam}?userId=${userId}`,
        method: 'GET'
    })
}

function getGovProjects(year, investor) {
    return request({
        url: `/project/getGovProject/${year}?investor=${investor}`,
        method: 'GET',
    })
}

function getFundingRequestChartData() {
    return request({
        url: '/rs/getFundingRequestChartData',
        method: 'GET'
    })
}


function getProjectComponent(govId) {
    return request({
        url: `/project/getProjectComponent/${govId}`,
        method: 'GET'
    })
}

function getProjectToDo(govId) {
    return request({
        url: `/project/getProjectToDo/${govId}`,
        method: 'GET'
    })
}

function getProjectContractor(govId) {
    return request({
        url: `/project/getProjectContractor/${govId}`,
        method: 'GET'
    })
}

function getBudgetCriteria(govId) {
    return request({
        url: `/project/getBudgetCriteria/${govId}`,
        method: 'GET'
    })
}

function getGovProjectEtc(govId) {
    return request({
        url: `/project/getGovProjectEtc/${govId}`,
        method: 'GET'
    })
}
function getPortalData() {
    return request({
        url: `/portal/getPortalData`,
        method: 'GET'
    })
}
function getLoginLogByMonth(year, yam) {
    return request({
        url: `/rs/getLoginLogByMonth/${year}/${yam}`,
        method:'GET'
    })
}
function getLoginLogByDotood(year, yam) {
    return request({
        url:`/rs/getLoginLogByDotood/${year}/${yam}`,
        method:'GET'
    })
}
function getLoginLogByGadaad(year, yam) {
    return request({
        url:`/rs/getLoginLogByGadaad/${year}/${yam}`,
        method:'GET'
    })
}
function getLoginAllLogUsers(year, yam) {
    return request({
        url: `/rs/getLoginAllLogUsers/${year}/${yam}`,
        method:'GET'
    })
}
function getLoginAllLogOrgs(year, yam) {
    return request({
        url: `/rs/getLoginAllLogOrgs/${year}/${yam}`,
        method:'GET'
    })
}
function getGovProjectInvestors(year) {
    return request({
        url: `/project/getGovProjectInvestors?year=${year}`,
        method: 'GET'
    })
}
function getProjectsByInvestors(year, investor) {
    return request({
        url: `/project/getProjectsByInvestors/${year}?investor=${investor}`,
        method: 'GET'
    })
}

function getCurrency(){
    return request({
        url: `/project/getCurrency`,
        method: 'GET'
    })
}

function getUserLogs() {
    return request({
        url: `/rs/getUserLogs`,
        method: 'GET'
    })
}

function getProjectStatus(){
    return request({
        url: `/project/getProjectStatus`,
        method: 'GET'
    })
}

function getProjectType(){
    return request({
        url: `/project/getProjectType`,
        method: 'GET'
    })
}

function getProjectCountByType(year, investor) {
    return request({
        url: `/project/getProjectCountByType/${year}?investor=${investor}`,
        method: 'GET'
    })
}

function getProjectCountByStatus(year, investor) {
    return request({
        url: `/project/getProjectCountByStatus/${year}?investor=${investor}`,
        method: 'GET'
    })
}

function getTotalAmountByCurrency(year, investor) {
    return request({
        url: `/project/getTotalAmountByCurrency/${year}?investor=${investor}`,
        method: 'GET'
    })
}

const RemainingService = {
    changeUserPermission,
    getAllRoles,
    getAllUsers,
    getReferenceRoles,
    changeUserRole,
    loadMe,
    getNotifications,
    deleteNotification,
    postNotification,
    stateStructureAtt,
    getAllLogs,
    getGovProjects,
    getFundingRequestChartData,
    getProjectComponent,
    getProjectToDo,
    getProjectContractor,
    getBudgetCriteria,
    getGovProjectEtc,
    getPortalData,
    getLoginLogByMonth,
    getRolesByOrg,
    getLoginAllLogUsers,
    getLoginAllLogOrgs,
    getLoginLogByDotood,
    getLoginLogByGadaad,
    getGovProjectInvestors,
    getProjectsByInvestors,
    getCurrency,
    getUserLogs,
    getProjectStatus,
    getProjectType,
    getProjectCountByType,
    getProjectCountByStatus,
    getTotalAmountByCurrency
}

export default RemainingService;
import request from '../../shared/lib/request'

function getConversation() {
    return request({
        url:    `/chatServices/conversation`,
        method: `GET`
    })
}

function seenConversation(conversationId, seen) {
    return request({
        url:    `/chatServices/conversation/${conversationId}/${seen}`,
        method: `PUT`
    })
}

function startConversation(chatUserId) {
    return request({
        url:    `/chatServices/conversation`,
        method: `POST`,
        data:   {
            chatUserId
        }
    })
}

function addMessages(conversationId, toUserId, text) {
    return request({
        url:    `/chatServices/messages`,
        method: `POST`,
        data:   {
            conversationId,
            toUserId,
            text
        }
    })
}

function seenMessages(conversationId) {
    return request({
        url:    `/chatServices/messages/seen`,
        method: `POST`,
        data:   {
            conversationId
        }
    })
}

function countUnseenMessages() {
    return request({
        url:    `/chatServices/messages/count/unseen`,
        method: `GET`
    })
}

function getMessages(conversationId) {
    return request({
        url:    `/chatServices/messages/${conversationId}`,
        method: `GET`
    })
}

const ChatService = {
    getConversation,
    seenConversation,
    startConversation,
    addMessages,
    seenMessages,
    countUnseenMessages,
    getMessages
}

export default ChatService;


import React, {useEffect, useState, useRef} from 'react';
import ProposalService from "../../../services/api/proposal";
import _ from "lodash";
import {list_to_tree, subtotal_tree} from "../../../util";
import {Column, TreeList} from "devextreme-react/tree-list";
import LoadPanelComponent from "../../budget/components/loadPanel";


function DataGridComponent(props) {
    const [dataSource, setDataSource] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    let myTreeList = useRef({});
    let economicCategoryArray = useRef({});

    const loadEza = async () => {
        setIsLoading(true);
        const r = await ProposalService.statisticConfirmedBudgetProject(props.chosenDocument);
        economicCategoryArray.current = _.sortBy(props.econArr, ['code']);

        const resp = helperConvertProposal(r.data);
        myTreeList.current = list_to_tree(resp);
        updateSubTotal();
        setDataSource(resp)
        setIsLoading(false);
    }

    useEffect(() => {
        loadEza();
    }, [props.chosenDocument])

    const helperConvertProposal = (current) => {
        let res = [];
        economicCategoryArray.current.forEach(e => {
            res.push({...e})
        });

        let currCopy = [...current];
        // make sure to get the total sum amount by econ category id
        const currentDetailGrouped = _.groupBy(currCopy, 'id');
        const currentDetailEconIds = Object.keys(currentDetailGrouped);
        const summedDetail = {};
        currentDetailEconIds.forEach(econCategoryId => {
            let temp = {econCategoryId, amount: 0, ttz_amount: 0, tez_amount: 0}
            currentDetailGrouped[econCategoryId].forEach(detail => {
                temp.amount += detail.amount * 1;
                temp.ttz_amount += detail.ttz_amount * 1;
                temp.tez_amount += detail.tez_amount * 1;
            });
            summedDetail[econCategoryId] = temp;
        });


        Object.keys(summedDetail).forEach(econCatId => {
            let find = res.findIndex(el => el.id === summedDetail[econCatId].econCategoryId * 1);
            const { amount, ttz_amount, tez_amount } = summedDetail[econCatId];
            if (find !== -1) {
                res[find] = {...res[find], amount, ttz_amount, tez_amount};
            }
        });

        return res;
    };

    const updateSubTotal = (index = -1) => {
        if (index === -1) {
            myTreeList.current.forEach((item) => {
                subtotal_tree(item);
                return item;
            });
        } else {
            if (typeof myTreeList.current[index] == 'undefined') {
                console.error('out of bounds baby')
            } else {
                let item = myTreeList.current[index];
                subtotal_tree(item);
            }
        }
    }

    return (
        <div id = {`data-grid-context`} style={{position: 'relative', minHeight: '200px'}}>
            <LoadPanelComponent position={{of: '#data-grid-context'}} visible={isLoading} />
            {/*<SkeletonLoading*/}
            {/*    position={{of: '#data-grid-context'}}*/}
            {/*    visible={isLoading}*/}
            {/* />*/}
            <TreeList
                id="ezaTreeList"
                dataSource={dataSource}
                keyExpr="id"
                parentIdExpr="parent_id"
                focusedRowEnabled = {true}
                rowAlternationEnabled = {true}
            >
                <Column
                    dataField="name"
                    caption="Эдийн засгийн ангилал"
                />
                <Column
                    dataField="amount"
                    format="#,##0.00"
                    alignment="right"
                    dataType="number"
                    caption="2022 оны төсвийн санал"
                />
                <Column
                    dataField="ttz_amount"
                    format="#,##0.00"
                    alignment="right"
                    dataType="number"
                    caption="ТТЗ"
                />
                <Column
                    dataField="tez_amount"
                    format="#,##0.00"
                    alignment="right"
                    dataType="number"
                    caption="ТЕЗ"
                />
            </TreeList>
        </div>
    )
}

export default DataGridComponent;
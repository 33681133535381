import React, {useState, useContext} from "react";
import {SelectBox, Button} from "devextreme-react";
import {Lookup, DropDownOptions} from 'devextreme-react/lookup';
import PropTypes from 'prop-types';

export default function BudgetFilter(props) {
    const [year, setYear] = useState(props.yearValue);
    const [aimag, setAimag] = useState(props.aimagValue);
    const [org, setOrg] = useState(props.orgValue);
    const [econ, setEcon] = useState(props.econValue);
    // const [typeValue, setType] = useState(null);
    const [budgetStatus, setBudgetStatus] = useState(props.budgetStatusValue);
    const selectAimag = (aimagId) => {
        setAimag(aimagId);
        props.selectAimag(aimagId)
    };

    return (
        <>
            <div className="card">
                <div className="card-header">
                    <span>Шүүлтүүр</span>
                </div>
                <div className="card-body">
                    <div style={{marginBottom: "10px"}}>
                        <span style={{fontWeight: "200"}}>Он:</span>
                        <SelectBox
                            items={props.yearArray}
                            defaultValue={year}
                            onValueChanged={(e) => setYear(e.value)}
                            placeholder="сонгох"/>
                    </div>

                    {props.econArray.length > 0 &&
                        <div style={{marginBottom: "10px"}}>
                            <span style={{fontWeight: "200"}}>Эдийн засгийн ангилал:</span>
                            <SelectBox
                                items={props.econArray}
                                defaultValue={econ}
                                onValueChanged={(e) => setEcon(e.value)}
                                valueExpr={"id"}
                                displayExpr={(item) => {
                                    return item && `${item.id} - ${item.name}`
                                }}
                                placeholder="сонгох"/>
                        </div>
                    }

                    {props.aimagArray.length > 0 &&
                        <div style={{marginBottom: "10px"}}>
                            <span style={{fontWeight: "200"}}>Харьяа:</span>
                            <SelectBox
                                dataSource={props.aimagArray}
                                defaultValue={aimag}
                                onValueChanged={(e) => selectAimag(e.value)}
                                valueExpr={"id"}
                                displayExpr={(item) => {
                                    return item && `${item.code} - ${item.name}`
                                }}
                                placeholder="сонгох"
                            />
                        </div>
                    }

                    {props.organizationArray.length > 0 &&
                        <div style={{marginBottom: "10px"}}>
                            <span style={{fontWeight: "200"}}>Байгууллага:</span>
                            <Lookup
                                dataSource={props.organizationArray}
                                placeholder="сонгох"
                                value={org}
                                onValueChanged={(e) => setOrg(e.value)}
                                valueExpr={"id"}
                                displayExpr={(item) => {
                                    return item && item.code + '-' + item.name;
                                }}
                                itemRender={(item) => {
                                    return (<div style={{borderBottom: '1px solid #f2f2f2', paddingBottom: 10}}>
                                            <div>{item.code} - {item.name}</div>
                                            <div style={{color: '#8a8a8a', fontSize: 12}}>{item.aimag_name}</div>
                                        </div>)
                                }}
                                searchPlaceholder="Байгууллага хайх"
                                cancelButtonText={'Хаах'}
                            >
                                <DropDownOptions
                                    width={600}
                                    hideOnOutsideClick={true}
                                    showTitle={false}
                                />
                            </Lookup>
                            {/*<span style={{fontWeight: "200"}}>gello {org}</span>*/}
                        </div>
                    }

                    <div style={{marginBottom: "10px"}}>
                        <span style={{fontWeight: "200"}}>Төлөв:</span>
                        <SelectBox
                            items={props.budgetStatusArray}
                            // selectedItem={budgetStatus}
                            onValueChanged={(e) => setBudgetStatus(e.value)}
                            defaultValue={budgetStatus}
                            valueExpr="id"
                            placeholder="сонгох"
                            displayExpr={(item) => {
                                return item && `${item.code} - ${item.name}`
                            }}
                        />
                    </div>

                    <div style={{marginBottom: "10px"}}>
                        <Button width={120} text={"хайх"} type={"default"} stylingMode={"contained"} onClick={() => props.search(year, aimag, org, budgetStatus, econ)}/>
                    </div>
                </div>
            </div>
        </>
    );
}

BudgetFilter.propTypes = {
    yearArray: PropTypes.array.isRequired,
    aimagArray: PropTypes.array.isRequired,
    organizationArray: PropTypes.array.isRequired,
    // typeArray: PropTypes.array.isRequired,
    budgetStatusArray: PropTypes.array.isRequired,
    yearValue: PropTypes.any,
    // typeValue: PropTypes.any.isRequired,
    budgetStatusValue: PropTypes.any,
    aimagValue: PropTypes.any,
    orgValue: PropTypes.any
}

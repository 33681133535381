import React, {useLayoutEffect, useState} from "react"
import NewsCard from "./NewsCard"
import newsServices from "../../../services/api/news"
import {List, Pagination, Empty} from "antd"
import LoadPanelComponent from "../../../components/LoadPanelComponent"


function NewsList({type = 1}) {
  const [news, setNews] = useState([])
  const [loading, setLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(6)
  const [totalNumber, setTotalNumber] = useState()

  useLayoutEffect(() => {
    fetchData()
  }, [currentPage])

  const fetchData = async () => {
    setLoading(true)
    let {data, total} = await newsServices.getAllNews(
      type,
      currentPage,
      pageSize
    )
    setTotalNumber(total)
    setNews(data)
    setLoading(false)
  }
  const onPageChange = (e) => {
    setCurrentPage(e)
  }
  const onPageSizeChange = (e) => {
    console.log(e)
  }
  return (
    <div id="load" style={{paddingLeft: "2rem"}}>
      <LoadPanelComponent position={{ of: 'load'}} visible={loading}/>
      <List
        itemLayout="vertical"
        split={false}
        locale={{emptyText:  <Empty
          style={{marginTop: "2rem"}}
          description="Мэдээ байхгүй байна"
       />}}
        dataSource={news}
        renderItem={(item, index) => (
          <List.Item style={{padding: "12px 0 12px 12px"}} key={index}>
            <NewsCard {...item} />
          </List.Item>
        )}
      ></List>
      <div>
      <Pagination
        style={{float: "right"}}
        total={totalNumber}
        pageSize={pageSize}
        showSizeChanger={false}
        locale={{
          page: "Хуудас",
          jump_to: "Очих",
          prev_page: "Өмнөх хуудас",
          next_page: "Дараагийн хуудас",
        }}
        current={currentPage}
        onChange={onPageChange}
        hideOnSinglePage
        onShowSizeChange={onPageSizeChange}
      />
      </div>
    </div>
  )
}

export default NewsList

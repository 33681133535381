import React, {useContext, useEffect, useState, useRef} from 'react';
import 'devextreme-react/text-area';
import DataGrid, {
    Editing,
    Pager,
    Paging,
    Column,
    FilterRow,
    RequiredRule,
    Export, Form, Item,Popup
} from 'devextreme-react/data-grid';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver-es';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { LoadPanel } from "devextreme-react/load-panel";
import {UserContext} from "../../../hooks/UserContext";
import fcsService from "../../../services/api/fcs";
import Button from "devextreme-react/button";
import notify from "devextreme/ui/notify";
import LoadPanelComponent from '../../../components/LoadPanelComponent';

function InvLocationReg(props) {
    const userListPosition = { of: '#usersList' }
    const {user} = useContext(UserContext);
    const [dataSource, setDataSource] = useState([]);
    const [loader, setLoader] = useState(false);
    const myBabyRef = useRef(null);
    useEffect(() => {
        fcsList();
    }, [])

    const fcsList = async () => {
        try {
            setLoader(true);
            const result = await fcsService.getAllFcsLocation();
            setDataSource(result)
            return setLoader(false)
        } catch (e) {
            console.error(e);
        }
    }
    const onEditorPreparing = (e) => {
        if (e.parentType === "dataRow" && e.dataField !== "password") {
            return;
        }
        if (e.row && e.row.isNewRow) {
            e.editorOptions.visible = true;
        }
        if (e.row && !e.row.isNewRow) {
            e.editorOptions.visible = false;
        }
    }
    const customAddRow = (grid) => {
        grid.current.instance.addRow();
    }
    const onInitNewRow = (e) => {
    }
    const setEffect = async (e) => {
        if (e.changes && e.changes.length !== 0 ) {


                if (e.changes[0].type === "update") {
                    try {
                        e.changes[0].data.updated_user_id = user.id;
                        await fcsService.editFcsLocation(e.changes[0].data);
                        await fcsList();
                        notify('Амжилттай хадгалагдлаа', "success", 2000);
                    } catch (e) {
                        notify('Засварлалтыг хадгалах явцад алдаа гарлаа', "error", 2000);
                    }
                } else if (e.changes[0].type === "insert") {
                    try {
                        delete e.changes[0].data.id;
                        e.changes[0].data.created_user_id = user.id;
                        e.changes[0].data.org_id = user.org_id;
                        await fcsService.addFcsLocation(e.changes[0].data);
                        await fcsList();
                        notify('Амжилттай үүслээ', "success", 2000);
                    } catch (e) {
                        notify('Шинээр үүсэх явцад алдаа гарлаа', "error", 2000);
                    }

                }
                else if (e.changes[0].type === "remove") {
                    try {
                        await fcsService.removeFcsLocation(e.changes[0].key.id);
                        await fcsList();
                        notify('Амжилттай устгагдлаа', "success", 2000);
                    } catch (e) {
                        notify('Устгах явцад алдаа гарлаа', "error", 2000);
                    }

                }

        }

    }
    const onExporting = (e) => {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Main sheet');

        exportDataGrid({
            component: e.component,
            worksheet,
            autoFilterEnabled: true,
        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Purchase.xlsx');
            });
        });
        e.cancel = true;
    }
    const style={
        text: {
            color: "#2E4765", fontWeight: "600", fontSize: "18px"
        },
    }
    return (
        <div style={{padding: "0.5rem"}}>
            <LoadPanelComponent
                shadingColor="rgba(0,0,0,0.4)"
                position={{of : '#password'}}
                message = ''
                showPane={false}
            />
            <div className="col-12 row" style={{justifyContent:"center", gap:'2rem', padding: '5px 15px'}}>
            <div> <h4 style={{marginLeft: 20, textTransform: "uppercase", marginTop: 5,...style.text}}>
                    Бараа материалын агуулахын бүртгэл
                    </h4>
                </div>
                {/* <Button  icon="add" text = "Нэмэх" onClick={() => customAddRow(myBabyRef)} width = {110} type = "default" style = {{alignment : "center", borderRadius: '7px'}}/> */}
            </div>
            <LoadPanelComponent
                shadingColor="rgba(0,0,0,0.4)"
                position={userListPosition}
                visible={loader}
                showPane={false}
                message = ''
            />
            <div>
                <div className="card-body">
                    <DataGrid
                        onEditorPreparing={onEditorPreparing}
                        ref={myBabyRef}
                        dataSource={dataSource}
                        onSaved={setEffect}
                        keyExpr= {null}
                        onInitNewRow={onInitNewRow}
                        allowColumnResizing={true}
                        onExporting={onExporting}>
                        onEditingStart={ e => {
                            console.log('qqqqqqqqqqqqqqqqq', e);
                        }}
                    >
                        <FilterRow
                            visible = {true}
                        />
                        <Pager
                            showPageSizeSelector={true}
                            // showInfo={true}
                            showNavigationButtons={true}
                        />
                        <Column
                            dataField="id"
                            caption="Дугаар"
                            allowEditing = {false}
                            width={60}
                            alignment="center"
                            // editCellTemplate = {}
                            // cellRender = {customize}
                            cssClass = "custom"
                            allowFiltering={true}
                        >
                        </Column>
                        <Column
                            dataField="organization_model.name"
                            caption="Байгууллага нэр"
                            allowEditing = {false}
                            // editCellTemplate = {}
                            // cellRender = {customize}
                            cssClass = "custom"
                            allowFiltering={true}
                        >
                        </Column>
                        <Column
                            cssClass = "custom"
                            dataField="loctn_code"
                            caption="Агуулахын код"
                            allowEditing = {true}
                            allowFiltering={true}
                        >
                            <RequiredRule
                                message = 'Агуулахын код оруулна уу'
                            />
                        </Column>
                        <Column
                            cssClass = "custom"
                            dataField="loctn_name"
                            caption="Агуулахын нэр"
                            allowEditing = {true}
                            allowFiltering={true}
                        >
                            <RequiredRule
                                message = 'Агуулахын нэр оруулна уу'
                            />
                        </Column>
                        <Editing
                            mode="form"
                            allowUpdating={true}
                            allowDeleting={true}
                            allowAdding = {true}
                            useIcons = {true}
                            texts={{
                                cancelAllChanges: 'Болих бүгд',
                                cancelRowChanges: 'Болих',
                                confirmDeleteMessage: 'Энэ агууулахын мэдээллийг устгах уу?',
                                confirmDeleteTitle: 'Баталгаажуулах',
                                deleteRow: 'Устгах',
                                editRow: 'Өөрчлөх',
                                saveAllChanges: 'Хадгалах бүгд',
                                saveRowChanges: 'Хадгалах',
                                undeleteRow: 'Буцаах',
                                validationCancelChanges: 'Баталгаажуулах болих',
                            }}
                        >
                            <Popup title="Бараа материалын агуулахын бүртгэл" showTitle={true} width={800} height={500} />
                            <Form>
                                <Item itemType="group" colCount={2} colSpan={2}>
                                    <Item dataField="organization_model.name" />
                                    <Item dataField="loctn_code" />
                                    <Item dataField="loctn_name" />
                                </Item>
                            </Form>
                        </Editing>
                        <Paging enabled={true} />
                        <Export enabled={true} allowExportSelectedData={false} />
                    </DataGrid>
                </div>
            </div>
        </div>
    )
}

export default InvLocationReg;

import React, {useEffect, useState} from "react"
import ga4Services from "../../../services/api/googleAnalytics"
import {DataGrid} from "devextreme-react"
import {Column} from "devextreme-react/data-grid"
/*import {budgetPalettes} from "../../../util"
import _ from "lodash"

const numberWithCommas = (x) => {
  try {
    return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
  } catch (err) {
    throw err
  }
}*/
function GaDailyWeeklyMonthly() {
    const [basicData, setBasicData] = useState([])

  useEffect(() => {
    fetchBasicData ()
  }, [])
  const fetchBasicData = async () => {
    try {
      let basicData = (await ga4Services.getDailyWeeklyMonthlyData()).data
      let daily = basicData.reports[0]?.rows[0]?.metricValues[0]?.value
      let weekly = basicData.reports[1]?.rows[0]?.metricValues[0]?.value
      let monthly = basicData.reports[2]?.rows[0]?.metricValues[0]?.value

      setBasicData([
        {name: "Өнөөдөр", value: daily},
        {name: "Энэ долоо хоногт", value: weekly},
        {name: "Энэ сард", value: monthly},
      ])
    } catch (error) {
      console.log("error", error)
    }
  }

  return (
    <div className="card">
      <div className="card-header">
        <span
          style={{
            fontFamily: "'Montserrat', sans-serif",
            fontWeight: "bold",
          }}
        >
          4. Өнөөдөр, энэ долоо хоногт, энэ сард
        </span>
      </div>
      <div className="card-body" style={{paddingBottom: "1.6rem"}}>
        <DataGrid dataSource={basicData}>
          <Column dataField={"name"} caption={"Талбарууд"} />
          <Column dataField={"value"} caption={"Тоо хэмжээ"} />
        </DataGrid>
      </div>
    </div>
  )
}

export default GaDailyWeeklyMonthly

import React, {useContext, useEffect, useState, useRef} from "react";

import 'devextreme-react/text-area';
import DataGrid, {
    Editing,
    Column,
    RequiredRule,
    PatternRule,
    Summary, TotalItem, Button as DevButton
} from 'devextreme-react/data-grid';


import Button from "devextreme-react/button";
import SelectBox from "devextreme-react/select-box";
import DateBox from "devextreme-react/date-box";
import fcsServices from "../../../../services/api/fcs";
import notify from "devextreme/ui/notify";
import {UserContext} from "../../../../hooks/UserContext";
import fcsService from "../../../../services/api/fcs";
import {Popup} from "devextreme-react/popup";
import CustorgParent from "../../Gl/gledit/custorgParent";
import LoadPanelComponent from "../../../../components/LoadPanelComponent";



const InvIssEntery = (props) => {
    const {user} = useContext(UserContext);

   //onst jr_key = props.jr_key;
    const [organizationNo, setOrganizationNo] = useState(null);
    const [docno, setDocno] = useState(0);

    const [currentDate, setCurrentDate] = useState();
    const [listData, setListData] = useState([]);
    const [dataFcsMonth, setFcsMonth] = useState([]);

    const initCustValue = {name: '', orgcode: ''}
    const [custValue, setCustValue] = useState({...initCustValue});

    const myBabyRef = useRef(null);
    const [apList, setApList] = useState([]);
    const [location, setLocation] = useState(null);
    const [modalVisibleCustOrg, setModalVisibleCustOrg] = useState(false)
    const [accountSecondList, setAccountSecondList] = useState([]);
    const [loader, setLoader] = useState(false);
    const userListPosition = {of: '#usersList'}
    const [dataFcsLocation, setFcsLocation] = useState([]);
    const [acctCr, setAcctCr] = useState([])
    const [dataFcsInv, setFcsInv] = useState([])
    const [selectedInv, setSelectedInv] = useState('');
    const [costTypeId,setCostTypeId]=useState(0)
    const [yearValue, setYearvalue] = useState(0)
    const [monthValue, setMonthvalue] = useState(0)
    const [recptQty, setRecptQty] = useState('')


    useEffect(() => {
        setCurrentDate(new Date());
        getData();
    }, []);

    useEffect(() => {

        getYearMonthValue()
    }, [dataFcsMonth]);

    useEffect(() => {

        calInvBalLocation()
    }, [location]);

    const getYearMonthValue = () => {
        dataFcsMonth.map((item) => {

            setYearvalue(item.year)
            setMonthvalue(item.month)
        })
    }


    const onRowInserted = (e) => {
        e.component.navigateToRow(e.key);
    }
    const getData = async () => {
        setLoader(true);

        getDocno()


        const fcsArClass = await fcsService.getAllFcsArClass(true);
        setApList(fcsArClass);

        //
        const result = await fcsServices.getAllFcsChart();
        setAccountSecondList(result);
        const fcsLocation = await fcsService.getAllFcsLocation();

        setFcsLocation(fcsLocation);
        const fcsMonth = await fcsService.getAllFcsMonth('IN');
        setFcsMonth(fcsMonth);

        return setLoader(false)
    }

    const getFcsInvBal = async (locationId, year, month,costTypeId) => {

        if (costTypeId==0 || costTypeId==2){
            const resultIss = await fcsService.getFcsInvissBalance(locationId, year, month);
            setFcsInv(resultIss);
        }
        if (costTypeId==1){
            const resultIss = await fcsService.getFcsInvissBalanceFirst(locationId, year, month);
            setFcsInv(resultIss);
        }




    }

    const calInvBalLocation = () => {

        setFcsInv([])
        setListData([])
        setRecptQty('')
        setSelectedInv('')
        location && yearValue > 0 && monthValue > 0 && (
            getFcsInvBal(location.id, yearValue, monthValue,costTypeId)


        )
    }


    const getDocno = async () => {
        const fcsMonth = await fcsService.getAllFcsMonth('IN');

        setFcsMonth(fcsMonth);
        fcsMonth.map((item) => {
            setDocno(item.zarid)

        })
    }
    const customizeDate = (data) => {
        return Number(parseFloat(data.value).toFixed(2)).toLocaleString("en", {
            minimumFractionDigits: 2,
        });
    }
    const onChangeInv = async (e) => {
        //    console.log('eeee',e)
        await setSelectedInv(e);
        setRecptQty('')

    }


    const saveDataGrid = async (e) => {
    }
    const onEditingStart = (e) => {
    }

    /*const onChangeCustValue = (e) => {
        setCustValue(e);
    }*/

    const onChangeLocation = (e) => {
        setLocation(e);
    }


    const onChangeAcctCr = (e) => {
        setAcctCr(e);
    }


    const onValueChangedDate = (e) => {
        setCurrentDate(e.value);
    }



    const qtyChange = (e) => {
        let qty = e.target.value;
        Number(qty) < 0 || Number(qty) > selectedInv.qty ? setRecptQty('') : setRecptQty(qty)
    }


    const clickPlus = () => {

        if (custValue.name == undefined || custValue == null || custValue.name.length < 2) return notify("Харилцагч хоосон байна.", "warning");
        if (location === null) return notify("Агуулах сонгох", "warning");
        if (selectedInv.stnumber == undefined) return notify("Бараа сонгох", "warning");
        if (Number(recptQty) <= 0) return notify("Too  0 ", "warning");

        let list = [...listData];
        const data1 = {

            docno: 1 + docno,

            iss_qty: recptQty,
            unit_cost: selectedInv.cost / selectedInv.qty,
            iss_cost: selectedInv.cost / selectedInv.qty * recptQty,
            stnumber: selectedInv.stnumber,
            stname: selectedInv.descr,
            inv_id: selectedInv.inv_id,
            or_ognoo: selectedInv.or_ognoo,
            inacct: selectedInv.acctcode,
            cust: custValue.orgcode,
            unit_name: selectedInv.unitiss,
            org_id: user.org_id,
            created_user_id: user.id,
        }
        list.push(data1);
        setListData(list);
        setSelectedInv('')
        setRecptQty('')

    }

    const saveData = async () => {

        const date = new Date(currentDate);
        const year = date.getFullYear() * 1e4; // 1e4 gives us the the other digits to be filled later, so 20210000.
        const month = (date.getMonth() + 1) * 100;
        const yyyymm = year + month // `+ ''` to convert to string from number, 202206

        const fcsCurrentMonth = dataFcsMonth.map((item) => {
            let date = item.year + '/' + item.month
            let datetimenew = new Date(date)
            let year = datetimenew.getFullYear() * 1e4; // 1e4 gives us the the other digits to be filled later, so 20210000.
            let month = (datetimenew.getMonth() + 1) * 100;
            const yyyymm_month = year + month
            return yyyymm_month
        });


        if (yyyymm != fcsCurrentMonth[0]) {

            notify('Сонгосон огноо тухайн тайлант он сард биш байна', "warning");

            return

        }


        // if(organizationNo == null) return notify("Баримтын дугаар хоосон байна.", "warning");

        if (custValue === undefined || custValue === null || custValue.orgcode.length < 2) return notify("Харилцагч хоосон байна.", "warning");
        if (location === null || location === undefined) return notify("Агуулах хоосон байна.", "warning");

        if (listData.length === 0) return notify("Мэдээлэл байхгүй байна", "warning");


        try {

            let listData1 = listData


            let errorCount = 0;
            listData1.some((el) => {


                return false;
            });

            if (errorCount > 0) {
                return;
            }


            const result = await fcsService.getAllFcsMonth('IN');
            result.map(e => {
                e.zarid = e.zarid + 1
            })


            listData1.map((item) => {


                if (Number(item.unit_cost) <= 0) item.amount = 0

                if (Number(item.iss_qty) <= 0) item.vat = 0


                item.docdate = currentDate;
                item.docno = parseInt(result[0].zarid);


                item.org = custValue.orgcode;
                item.orgname = custValue.name;

                delete item.id;
                delete item.updatedAt;
                // let fcsInv = _.filter(dataFcsInv, {id: item.inv_id})
                //   let fcsUnit = _.filter(dataFcsUnit, {id: item.unit_id})
                item.created_user_id = user.id;
                item.org_id = user.org_id;
                item.iss_date = currentDate;
                item.is_number = 1 + Number(docno);
                // item.stnumber = fcsInv[0].stnumber;
                //item.stname = 0].descr;
                item.loctn_id = location.id;
                item.chart_id = acctCr.id;
                item.custorgn_id = custValue.id;
                item.acct_db = acctCr.acct;
                item.org = custValue.orgcode;
                item.orgname = custValue.name;

                item.loctn_code = location.loctn_code;
                item.loctn_name = location.loctn_name;

                //  item.unit_name = fcsUnit[0].name;
                //item.acct = fcsInv[0].acctcode;


                //   getdata();


            })

            console.log(listData1)
            await fcsService.addFcsInvIss(listData1);
            await fcsService.editFcsMonth(result[0]);
            notify("Амжилттай хадгаллаа", "success");
            const list = [];
            setListData(list)
            getDocno()
            setOrganizationNo('')
            setLoader(false)
        } catch (err) {
            setLoader(false)
            console.log(err)
            setLoader(false)
            notify("Алдаа гарлаа", "error")
        }


    }

    const costType = [{'id': '0', descr: '1.Жинлэсэн дундаж'},
        {id: '1', descr: '2.Эхэлж авсныг эхэлж зарлагадах'}, {
            id: '2',
            descr: '3.Тусгай тэмдэглэгээний'
        },
    ]
    const onChangeCostType = async (e) => {
        await  setCostTypeId(e);
    }

    const style = {
        text: {
            color: "#2E4765", fontWeight: "600", fontSize: ".875rem"
        },
        box: {
            border: "none", backgroundColor: "#F6F6F6", borderRadius: ".5rem", padding: ".25rem .15rem", width: "100%"
        }
    }
    return (<div style={{padding: "0.5rem",}}>


        <LoadPanelComponent
            shadingColor="rgba(0,0,0,0.4)"
            position={userListPosition}
            visible={loader}
            message=''
            showPane={false}
        />

        <div className={'card-body'} id='firstBox'>
            <div className="col-12" style={{display: 'flex'}}>

            <div style={{display: 'flex', marginBottom: 10, marginTop: 10, marginLeft: 10}}>
                <span>
                    <div><span style={{color: "#A3A3A3", marginRight: ".5rem"}}>Тайлант он</span><span
                        style={{fontWeight: "700"}}> {yearValue} сар {monthValue}</span></div>

                </span>
            </div>


            <div className="col-4" style={{display: 'flex'}}>
                <span style={{fontWeight: "700" ,marginLeft:30}}>
                   Бараа материалын зарлагын гүйлгээ
                </span>
            </div>
                <div className="col-2" style={{display: 'flex'}}>
                    <div style={{display: 'flex', alignItems: 'left'}}><span
                        style={{color: "#A3A3A3", marginTop:10,marginRight: ".5rem"}}>Дугаар:</span></div>
                    <div style={{marginLeft: 5,marginTop:10}}>
                        <span style={{fontWeight: "700"}}>{1 + docno}</span>
                    </div>


                </div>
            </div>


            <div className="col-12" style={{display: 'flex', marginTop: 20}}>
                <div className="col-4" style={{display: 'flex', flexDirection: "column"}}>
                    <div style={{display: 'flex', alignItems: 'center', marginRight: 10, ...style.text}}>
                        Агуулах:
                    </div>
                    <SelectBox
                        items={dataFcsLocation}
                        searchEnabled={true}
                        disabled={listData.length > 0}
                        style={{marginTop: 10, ...style.box}}
                        displayExpr={(item) => {
                            return item && `${item.loctn_code} - ${item.loctn_name}`
                        }}
                        onValueChanged={(e) => onChangeLocation(e.value)}
                        placeholder="сонгох"/>
                </div>

                <div className='col-3' style={{marginLeft: 10, marginRight: 5}}>
                    <div style={{display: 'flex', alignItems: 'center', marginBottom: 10, ...style.text}}>Өртөг бодох арга
                    </div>
                    <SelectBox
                        items={costType}
                        defaultValue={'0'}
                        valueExpr='id'
                        searchEnabled={true}
                        style={{...style.box}}
                        displayExpr={(item) => {
                            return item && `${item.descr}` }}
                        onValueChanged={(e) => onChangeCostType(e.value )}
                        placeholder="сонгох"/>
                </div>

                <div style={{marginLeft: 20, marginTop: 30}}>
                    <Button icon="check"

                            type="normal"
                            onClick={() => calInvBalLocation()}
                    />
                </div>
                <div className="col-4" style={{display: 'flex', flexDirection: "column", marginLeft: 30}}>
                    <div style={{display: 'flex', alignItems: 'center', marginRight: 10, ...style.text}}>Огноо:
                    </div>
                    <DateBox value={currentDate} style={{marginTop: 10, ...style.box}} type="date"
                             onValueChanged={onValueChangedDate}/>
                </div>
            </div>

            <div className="col-12" style={{display: 'flex', marginTop: 20}}>

                <div className="col-4" style={{display: 'flex', flexDirection: "column"}}>
                    <div style={{display: 'flex', alignItems: 'center', marginRight: 10, ...style.text}}>
                        Дебит данс:
                    </div>
                    <SelectBox
                        items={accountSecondList}
                        placeholder="сонгох"
                        name={'cr'}
                        searchEnabled={true}
                        style={{marginTop: 10, ...style.box}}
                        displayExpr={(item) => {
                            return item && `${item.acct} - ${item.descr}`
                        }}
                        onValueChanged={(e) => onChangeAcctCr(e.value)}
                    />


                </div>


                <div className="col-4" style={{display: 'flex', flexDirection: "column", marginLeft: 30}}>
                    <div
                        style={{display: 'flex', alignItems: 'center', marginRight: 10, ...style.text}}>Харилцагч:
                    </div>

                    <input type='string' className='law-search-input'

                           style={{marginTop: 10, ...style.box}}
                           onClick={(e) => setModalVisibleCustOrg(true)}
                           value={custValue.name}

                    />
                </div>
            </div>

            <div className="col-12" style={{display: 'flex', marginTop: 20}}>

                <div className="col-3" style={{display: 'flex'}}>

                    <SelectBox

                        items={dataFcsInv}
                        placeholder="сонгох"
                        name={'inv_id'}
                        style={{backgroundColor: '#FFFFFF', border: '1px solid #DDDDDD'}}
                        showClearButton={true}
                        searchEnabled={true}
                        //  value={selectedInv?  selectedInv.stnumber : ""}

                        displayExpr={(item) => {
                            return item && `${item.stnumber} - ${item.descr} - ${item.or_ognoo}`
                        }}

                        onValueChanged={(e) => onChangeInv(e.value)}
                    />


                </div>


                <div className="col-1" style={{display: 'flex'}}>
                    <input type='text' className='law-search-input' placeholder='х/нэгж'
                           name={'unitiss'}
                           disabled={true}
                           style={{backgroundColor: '#FFFFFF', border: '1px solid #DDDDDD'}}

                           value={selectedInv ? selectedInv.unitiss : ""}

                    />
                </div>
                <div className="col-2" style={{display: 'flex'}}>
                </div>
                <div className="col-2" style={{display: 'flex'}}>
                    <input type='text' className='law-search-input' placeholder='өртөг'
                           name={'qty'}
                           disabled={true}
                           style={{backgroundColor: '#FFFFFF', border: '1px solid #DDDDDD'}}
                           value={selectedInv && selectedInv.qty > 0 ? selectedInv.cost / selectedInv.qty : ''}

                    />
                </div>

            </div>

            <div className="col-12" style={{display: 'flex', marginTop: 20}}>

                <input type='text' className='law-search-input' placeholder='үлдэгдэл'
                       name={'qty'}
                       disabled={true}
                       style={{backgroundColor: '#FFFFFF', border: '1px solid #DDDDDD'}}
                       value={selectedInv && selectedInv.qty}

                />

                <input type='number' className='law-search-input' placeholder='тоо'
                       name={'recptQty'}

                       style={{
                           backgroundColor: '#FFFFFF',
                           border: '1px solid #DDDDDD',
                           marginLeft: 20,
                           marginRight: 20
                       }}
                       value={recptQty}
                       onChange={(e) => qtyChange(e)}
                />


                <Button icon="plus"
                        text=""
                        type="default" style={{alignment: "center", borderRadius: '7px'}}
                        onClick={() => clickPlus()}
                        style={{alignment: "center", borderRadius: '7px', marginRight: 20}}
                />
                <Button className="saveButton"
                        text="Хадгалах"
                        type="default"
                        onClick={() => saveData()}/>
            </div>

        </div>

        <div className="col-12">
            <DataGrid
                dataSource={listData}
                showBorders={true}
                ref={myBabyRef}
                hoverStateEnabled={true}
                noDataText='Дата байхгүй байна.'
                onEditingStart={onEditingStart}
                columnAutoWidth={true}
                onSaved={saveDataGrid}
                onRowInserted={onRowInserted}
                rowUpdating={(e) => {
                    console.log(e);
                }}
            >
                <Editing
                    mode="cell"
                    allowUpdating={true}
                    allowDeleting={true}
                    useIcons={true}
                    texts={{
                        cancelAllChanges: 'Болих бүгд',
                        cancelRowChanges: 'Болих',
                        confirmDeleteMessage: 'Энэ мэдээллийг устгах уу?',
                        confirmDeleteTitle: 'Баталгаажуулах',
                        deleteRow: 'Устгах',
                        editRow: 'Өөрчлөх',
                        saveAllChanges: 'Хадгалах бүгд',
                        saveRowChanges: 'Хадгалах',
                        undeleteRow: 'Буцаах',
                        validationCancelChanges: 'Баталгаажуулах болих',
                    }}
                />
                <Column
                    cssClass="custom"
                    dataField="stname"
                    caption="Материалын үнэт зүйлийн нэр, зэрэг, дугаар"
                    width={100}
                    allowEditing={false}
                    allowFiltering={true}
                >

                </Column>
                <Column
                    cssClass="custom"
                    dataField="stnumber"
                    caption="Код"
                    allowEditing={false}
                    allowFiltering={true}

                >

                </Column>

                <Column
                    cssClass="custom"
                    dataField="unit_name"
                    caption="х/нэгж"
                    allowEditing={false}
                    allowFiltering={true}

                >

                </Column>


                <Column
                    cssClass="custom"
                    dataField="iss_qty"
                    caption="Тоо"
                    allowEditing={false}
                    allowFiltering={true}

                    width={80}>

                </Column>
                <Column
                    cssClass="custom"
                    dataField="unit_cost"
                    caption="Нэгжийн үнэ"
                    allowEditing={false}
                    allowFiltering={true}
                    width={50}
                >
                    <RequiredRule
                        message='Нэгжийн үнэ'
                    />
                    <PatternRule pattern={/^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/}
                                 message='зөвхөн тоо оруулна уу!'/>
                </Column>
                <Column
                    cssClass="custom"
                    dataField="iss_cost"
                    caption="Нийт дүн"
                    allowEditing={false}
                    width={120}
                >
                </Column>
                <Column type="buttons">
                    <DevButton name="delete"/>
                </Column>
                <Summary>
                    <TotalItem
                        column="qty"
                        summaryType="sum"
                        customizeText={customizeDate}/>
                    <TotalItem
                        column="iss_cost"
                        summaryType="sum"
                        customizeText={customizeDate}/>
                </Summary>
            </DataGrid>
        </div>

        <div>
            <Popup
                width={500}
                height={500}
                minWidth={'80%'}
                maxWidth={'80%'}
                minHeight={'80%'}
                visible={modalVisibleCustOrg}
                title={'Байгууллага хувь хүмүүс сонгох'}
                onHiding={() => setModalVisibleCustOrg(false)}
                resizeEnabled={true}
                dragEnabled={true}
                showTitle={true}

            >
                <CustorgParent setCustValue={setCustValue} setModalVisibleCustOrg={setModalVisibleCustOrg}/>

            </Popup>

        </div>


    </div>);
};

export default InvIssEntery;
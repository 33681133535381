import request from '../../shared/lib/request'

const getAllEconomicCategory = () => {
    return request({
        url: '/economicCategory/getAllEconomicCategory',
        method: 'GET',
    })
}
const getWorkerEconomic = () => {
    return request({
        url: '/economicCategory/getWorkerEconomic',
        method: 'GET',
    })
}
const addEconomicCategory = (body) => {
    return request({
        url: '/economicCategory/addEconomicCategory',
        method: 'POST',
        data: body,
    })
}
const editEconomicCategory = (body) => {
    return request({
        url: `/economicCategory/EditEconomicCategory`,
        method: 'POST',
        data: body,
    })
}
const removeEconomicCategory = (body) => {
    return request({
        url: '/economicCategory/RemoveEconomicCategory',
        method: 'POST',
        data: body,
    })
}
const updateEconomicCategoryRelation = (body) => {
    return request({
        url: '/economicCategory/updateEconomicCategoryRelation',
        method: 'POST',
        data: body,
    })
}
const economicCategoryServices = {
    getAllEconomicCategory,
    addEconomicCategory,
    editEconomicCategory,
    removeEconomicCategory,
    updateEconomicCategoryRelation,
    getWorkerEconomic
}

export default economicCategoryServices;
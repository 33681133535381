import { useContext } from "react";
import { UserContext } from "../hooks/UserContext";


const styles = {
  div: {
    textAlign: 'start',
    fontFamily: 'Segoe UI',
  },
  h4: {
    fontWeight: 'bold',
    marginBottom: '0.5rem',
    fontSize: 16
  },
  p: {
    marginTop: '0.5rem',
    marginBottom: 0,
    fontSize: 14
  },
  i: {
    border: '1px solid #959595',
    borderRadius: '5px',
    padding: 5
  },
  span: {
    fontSize: 12,
    marginTop: 10,
  },
  div1: {
    display: 'flex',
    alignItems: 'start',
    marginTop: '0.5rem',
  }
};

export const guideLocale =
{
  back: 'Буцах',
  close: 'Хаах',
  last: 'Дуусгах',
  next: 'Дараагийн',
  skip: 'Алгасах'
}

export const tusliinTusulSteps = [
  {
    target: '#projectProposal_12',
    content: (
      <div style={styles.div}>
        <h4 style={styles.h4}>ТӨСЛИЙН НЭГТГЭЛ</h4>
        <hr />
        <p style={styles.p}>Харьяа байгууллагуудын Төсвийн төслийг ТӨЛӨВ-р нь нэгтгэн харах боломжтой.</p>
      </div>
    ),
    placement: 'right',
    spotlightPadding: 10,
  },
  {
    target: '.dx-datagrid-group-closed',
    content: (
      <div style={styles.div}>
        <h4 style={styles.h4}>БАЙГУУЛЛАГА</h4>
        <hr />
        <div style={styles.div1}>
          <i class="dx-icon-spinright"
            style={{ border: '1px solid #959595', borderRadius: '5px', fontSize: '1.5rem', color: '#959595', marginRight: '0.5rem' }}>
          </i>
          <p > сумыг дарж дэлгэрэнгүй мэдээлэлтэй танилцах боломжтой.</p>
        </div>
      </div>
    ),
    disableBeacon: false,
    placement: 'right',
    spotlightPadding: 10,
  },
  {
    target: '.dx-command-edit',
    content: (
      <div style={styles.div}>
        <h4 style={styles.h4}>ҮЙЛДЭЛ</h4>
        <hr />
        <div style={{ display: 'flex', gap: '1rem', marginTop: '1rem' }}>
          <div>
            <i class="dx-icon-chart" style={styles.i}></i>
            <p style={styles.span}>өмнөх жилүүдийн төсвийн дүн</p></div>
          <div>
            <i class="dx-icon-detailslayout" style={styles.i}></i>
            <p style={styles.span}>саналын задаргаа зэрэг мэдээлэлтэй танилцана</p></div>
          <div>
            <i class="dx-icon-todo" style={styles.i}></i>
            <p style={styles.span}>ЗӨВШӨӨРӨХ</p></div>
          <div>
            <i class="dx-icon-close" style={styles.i}></i>
            <p style={styles.span}>ТАТГАЛЗАХ</p></div>
        </div>
      </div>
    ),
    placement: 'left',
    spotlightPadding: 10,
  },
];


export const tusliinNegtgelSteps = [
  {
    target: '#projectsMinistry_harahHelber',
    content: (
      <div style={styles.div}>
        <h4 style={styles.h4}>ХАРАХ ХЭЛБЭР</h4>
        <hr />
        <ul style={{ textAlign: 'start', margin: '0.5rem 1rem', }}>
          <li>Байгууллага, хөтөлбөр зориулалт</li>
          <li>Эдийн засгийн ангилал сонгож төслийн саналуудыг харах боломжтой.</li>
        </ul>
      </div>
    ),
    placement: 'top',
    spotlightPadding: 10,
  },
  {
    target: '.dx-pivotgrid-field-chooser-button',
    content: (
      <div style={styles.div}>
        <h4 style={styles.h4}>ТАЛБАР СОНГОЛТ</h4>
        <hr />
        <div style={styles.div1}>
          <i class="dx-icon-columnchooser"
            style={{ border: '1px solid #959595', borderRadius: '5px', fontSize: '1.2rem', marginRight: '0.5rem', padding: 5 }}></i>
          <p>товч дарж танд хэрэгтэй талбарын сонголтыг хийх боломжтой.</p>
        </div>
      </div>
    ),
    placement: 'right',
    spotlightPadding: 10,
  },
  {
    target: '.dx-pivotgrid-export-button',
    content: (
      <div style={styles.div}>
        <h4 style={styles.h4}>ФАЙЛ ТАТАХ</h4>
        <hr />
        <div style={styles.div1}>
          <i class="dx-icon-xlsxfile"
            style={{ border: '1px solid #959595', borderRadius: '5px', fontSize: '1.2rem', marginRight: '0.5rem', padding: 5 }}></i>
          <p>товч дарж талбарт харагдаж буй мэдээллийг excel файлаар хөрвүүлэн татах боломжтой.</p>
        </div>
      </div>
    ),
    placement: 'right',
    spotlightPadding: 10,
  },
];

export const connectWorkerStep = [
  {
    target: '#connectOrgWorker',
    content: (
      <div style={{ padding: '0 10px', textAlign: 'start' }}>
        <p style={styles.p}>Та хэрэглэгч холбох дарж <strong>Албан хаагч</strong> хэсгээс өөрийн мэдээллээ сонгож холбоно уу!</p>
      </div>
    ),
    disableBeacon: true,
    spotlightPadding: 10,
    placement: 'auto',
    offset: 0,
  }]

export const huvaariinSanalSteps = [
  {
    target: '#plan_61',
    content: (
      <div style={styles.div}>
        <h4 style={styles.h4}>Хуваарийн санал - НЭГТГЭЛ</h4>
        <hr />
        <p style={styles.p}>Харьяа төсвийн захирагч нарын Хуваарийн саналын хүсэлтийг ТӨЛӨВ-р нэгтгэн харах боломжтой.</p>
      </div>
    ),
    placement: 'right',
    spotlightPadding: 10,
  },
  {
    target: '.dx-datagrid-group-closed',
    content: (
      <div style={styles.div}>
        <h4 style={styles.h4}>БАЙГУУЛЛАГА</h4>
        <hr />
        <div style={styles.div1}>
          <i class="dx-icon-spinright"
            style={{ border: '1px solid #959595', borderRadius: '5px', fontSize: '1.5rem', color: '#959595', marginRight: '0.5rem' }}>
          </i>
          <p style={{ fontSize: 14 }}>сумыг дарж ТТЗ- д харьяалагдах ТШЗ нарын саналыг төлөвөөр харна.</p>
        </div>
      </div>
    ),
    disableBeacon: false,
    placement: 'right',
    spotlightPadding: 10,
  },
  {
    target: '.dx-command-edit',
    content: (
      <div style={styles.div}>
        <h4 style={styles.h4}>ҮЙЛДЭЛ</h4>
        <hr />
        <div style={{ display: 'flex', gap: '1rem', marginTop: '1rem' }}>
          <div>
            <i class="dx-icon-chart" style={styles.i}></i>
            <p style={styles.span}>Хуваарийн саналын дүнг графикаар харах</p></div>
          <div>
            <i class="dx-icon-detailslayout" style={styles.i}></i>
            <p style={styles.span}>Хуваарийн саналын дэлгэрэнгүй мэдээлэлтэй танилцах</p></div>
          <div>
            <i class="dx-icon-todo" style={styles.i}></i>
            <p style={styles.span}>Саналыг зөвшөөрөх</p></div>
          <div>
            <i class="dx-icon-close" style={styles.i}></i>
            <p style={styles.span}>Саналыг татгалзах</p></div>
        </div>
      </div>
    ),
    placement: 'left',
    spotlightPadding: 10,
  },
];

export const HuvaariinUurchlultSteps = () => {
  const { user } = useContext(UserContext);
  return ([
    {
      target: '#plan_59',
      content: (
        <div style={styles.div}>
          <h4 style={styles.h4}>Хуваарийн өөрчлөлт</h4>
          <hr />
          <p style={styles.p}>Батлагдсан хуваарьт өөрчлөлт оруулах хүсэлт боловсруулан харьяа дээд байгууллагат хүргүүлэх боломжтой.</p>
        </div>
      ),
      placement: 'right',
      spotlightPadding: 10,
    },
    {
      target: '#createSchedReq',
      content: (
        <div style={styles.div}>
          <p style={{ fontSize: 14 }}>{user.organization.obt_id !== 1 ? 'Тухайн сарын 25-ны дотор хуваарийн өөрчлөлтийн хүсэлт үүсгэх боломжтой.' : 'Хуваарийн өөрчлөлтийн хүсэлт шинээр үүсгэх.'}</p>
        </div>
      ),
      disableBeacon: false,
      placement: 'right',
      spotlightPadding: 10,
    },
    {
      target: '.scheduleTransferDetailBtn',
      content: (
        <div style={styles.div}>
          <h4 style={styles.h4}>ҮЙЛДЭЛ</h4>
          <hr />
          <div style={{ display: 'flex', gap: '1rem', marginTop: '1rem' }}>
            <div style={{ width: '50%' }}>
              <i class="dx-icon-edit" style={styles.i}></i>
              <p style={styles.span}>Татгалзсан хуваарийн өөрчлөлт засварлах.</p></div>
            <div style={{ width: '50%' }}>
              <i class="dx-icon-detailslayout" style={styles.i}></i>
              <p style={styles.span}>Хүсэлтийн дэлгэрэнгүйтэй танилцах ба хуваарийн өөрчлөлт хийх үндсэн талбарт шилжинэ.</p></div>
          </div>
        </div>
      ),
      placement: 'left',
      spotlightPadding: 10,
    },
  ])
}

export const huvaariTabSteps = [
  {
    target: '#schedReqTab_0',
    content: (
      <div style={styles.div}>
        <h4 style={styles.h4}>Хуваарь</h4>
        <hr />
        <p style={styles.p}>Төсвийн жилийн батлагдсан хуваариас сонгон өөрчилнө.</p>
      </div>
    ),
    placement: 'right',
    spotlightPadding: 0,
  },
  {
    target: '.dx-toolbar-after',
    content: (
      <div style={styles.div}>
        <h4 style={styles.h4}>Хуваарийн өөрчлөлт боловсруулахад таны ажлыг хөнгөвчлөх нэмэлт боломжууд:</h4>
        <hr />
        <div style={{ display: 'flex', gap: '1rem', marginTop: '1rem' }}>
          <div>
            <i class="dx-icon-columnchooser" style={styles.i}></i>
            <p style={styles.span}>Багана сонгох</p></div>
          <div>
            <i class="dx-icon-xlsxfile" style={styles.i}></i>
            <p style={styles.span}>Excel загвар татах</p></div>
          <div>
            <i class="dx-icon-expandform" style={styles.i}></i>
            <p style={styles.span}>ЭЗА хамгийн бага нэгж хүртэл задлах, хураах</p></div>
          <div>
            <i class="dx-icon-fullscreen" style={styles.i}></i>
            <p style={styles.span}>Дэлгэцэд бүтэн харуулах</p></div>
          <div>
            <i class="dx-icon-eyeopen" style={styles.i}></i>
            <p style={styles.span}>Өөрчлөгдсөн баганыг харуулах</p></div>
        </div>
      </div>
    ),
    placement: 'left',
    spotlightPadding: 5,
  },
  {
    target: '.makeChanges',
    content: (
      <div style={styles.div}>
        <h4 style={styles.h4}>Хуваарийн өөрчлөлт боловсруулах</h4>
        <hr />
        <p style={styles.p}>Хуваарь өөрчлөх буюу шилжүүлэг хийх сарын мөнгөн дүн дээр дарна.</p>
      </div>
    ),
    placement: 'left',
    spotlightPadding: 0,
  }
];

export const reqInfoTabSteps = [
  {
    target: '#schedReqTab_1',
    content: (
      <div style={styles.div}>
        <h4 style={styles.h4}>Хүсэлтийн мэдээлэл</h4>
        <hr />
        <p style={styles.p}>Хуваарийн өөрчлөлтийн хүсэлтийн ДЭЛГЭРЭНГҮЙ МЭДЭЭЛЭЛ харагдана.</p>
      </div>
    ),
    placement: 'right',
    spotlightPadding: 0,
  },
  {
    target: '#attachedInfos',
    content: (
      <div style={styles.div}>
        <p style={styles.p}>Хуваарийн өөрчлөлттэй холбоотой НЭМЭЛТ ТАЙЛБАР болон ФАЙЛЫГ заавал хавсаргана. </p>
      </div>
    ),
    placement: 'bottom',
    spotlightPadding: 5,
  },
  {
    target: '#changeStatusBtn',
    content: (
      <div style={styles.div}>
        <h4 style={styles.h4}>ҮЙЛДЭЛ</h4>
        <hr />
        <p style={styles.p}>Одоогийн хүсэлтийн төлвийг өөрчлөх</p>
      </div>
    ),
    placement: 'left',
    spotlightPadding: 0,
  }
];

export const SubOrgTabSteps = () => {
  const { user } = useContext(UserContext);
  return ([
    {
      target: '#schedReqTab_2',
      content: (
        <div style={styles.div}>
          <p style={styles.p}>Харьяа байгууллагаас ирсэн хүсэлтийн жагсаалт харагдах бөгөөд {user.organization.obt_id === 2 ? 'та өөрийн байгууллагын хүсэлттэй нэгтгэж тухайн сарын 25-ны дотор ТЕЗ-д хүргүүлнэ' : 'та тухайн сарын 25-с хойш харьяа байгууллагуудын хүсэлтийг нэгтгэж СЯ-д хүргүүлнэ.'}</p>
        </div>
      ),
      placement: 'left',
      spotlightPadding: 0,
    },
    {
      target: '.subOrgReqActions',
      content: (
        <div style={styles.div}>
          <h4 style={styles.h4}>ҮЙЛДЭЛ</h4>
          <hr />
          <div style={{ display: 'flex', gap: '1rem', marginTop: '1rem' }}>
            <div style={{width: '30%'}}>
              <i class="dx-icon-detailslayout" style={styles.i}></i>
              <p style={styles.span}>Хүсэлтийн дэлгэрэнгүйтэй танилцах</p></div>
            <div style={{width: '30%'}}>
              <i class="dx-icon-todo" style={styles.i}></i>
              <p style={styles.span}>Хүсэлт нэгтгэх</p></div>
            <div style={{width: '30%'}}>
              <i class="dx-icon-close" style={styles.i}></i>
              <p style={styles.span}>Хүсэлт цуцлах</p></div>
          </div>
        </div>
      ),
      placement: 'left',
      spotlightPadding: 0,
    }
  ])
};





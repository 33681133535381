import React, { useEffect, useState, useRef } from "react";
import BudgetService from "../../../../services/api/budget";
import notify from "devextreme/ui/notify";
import LoadPanelComponent from "../../../budget/components/loadPanel";
import RefService from "../../../../services/api/refs";
import { DataGrid } from "devextreme-react";
import {
  Column,
  GroupItem,
  HeaderFilter,
  Lookup,
  Scrolling,
  Summary,
} from "devextreme-react/data-grid";

const BudgetZarlagaAgenda = (props) => {
  const [agenda, setAgenda] = useState([]);
  const [pivotSource, setPivotSource] = useState([]);
  const [orgData, setOrgData] = useState([]);
  const [neverChangingData, setNeverChangingData] = useState([]);
  const [loader, setLoader] = useState(true);
  const RefZarlagaAgenda = useRef(null);
  const [checkVisible, setCheckVisible] = useState(null);
  const loadAgendaData = async () => {
    try {
      let r = await BudgetService.getBudgetAgendaByYear(
        props.year,
        props.orgId
      );
      const b = await RefService.getAgendas();
      let distinctOrgs = r.reduce((acc, curr) => {
        let existingOrg = acc.find(
          (org) => org.org_id === curr.org_id && org.org_name === curr.org_name
        );
        if (!existingOrg) {
          acc.push({ org_id: curr.org_id, org_name: curr.org_name });
        }
        return acc;
      }, []);

      setOrgData(distinctOrgs);
      setAgenda(b.data);
      setLoader(true);
      setNeverChangingData(r);
      setCheckVisible(r.every((r) => r.shalhuur_name !== null));
      setLoader(false);
    } catch (e) {
      notify(e.message, "error", 2000);
    } finally {
      setLoader(false);
    }
  };

  const onCellPrepared = (e) => {
    if (e.rowType === "group" && [1,2,3].includes(e.columnIndex)) {
      e.cellElement.style.color = 'black'
    }
    if (e.rowType === "header") {
      e.cellElement.wordWrapEnabled = true;
      e.cellElement.style.fontFamily = "Segoe UI";
      e.cellElement.style.fontWeight = "bold";
      e.cellElement.style.color = "#15186A";
      e.cellElement.style.backgroundColor = "#EEF0F4";
      e.cellElement.style.textAlign = "center";
      e.cellElement.style.verticalAlign = 'middle'
    }
    if (e.rowType === "data") {
      e.cellElement.style.fontFamily = "Segoe UI";
      e.cellElement.style.fontWeight = "semibold";
      e.cellElement.style.color = "#000000";
    }
  };
  const groupCell = (e) => {
    return <div>{e.data.displayValue}</div>;
  };
  useEffect(() => {
    loadAgendaData();
  }, [props.year, props.orgId]);
  return (
    <div id="load30">
      <LoadPanelComponent
        shadingColor="rgba(0,0,0,0.2)"
        position={{ of: "#load30" }}
        visible={loader}
        message=""
        showPane={false}
      />
      <DataGrid
        dataSource={neverChangingData}
        noDataText="Дата байхгүй байна"
        ref={RefZarlagaAgenda}
        style={{ fontFamily: "Segoe UI", fontWeight: "semibold" }}
        columnAutoWidth={true}
        showColumnLines={true}
        showRowLines={true}
        showBorders={true}
        allowColumnReordering={true}
        onCellPrepared={onCellPrepared}
        height={"500px"}
        columnMinWidth={"100px"}
        paging={{ enabled: false }}
        wordWrapEnabled={true}
      >
        <HeaderFilter
          visible={true}
          allowSearch={true}
          texts={{ cancel: "Болих", ok: "Шүүх", emptyValue: "Хоосон утга" }}
        />
        <Scrolling mode="standart" />
        <Column
          dataField="org_id"
          caption="Байгууллага"
          alignment="left"
          groupIndex={0}
          groupCellComponent={groupCell}
        >
          <Lookup
            dataSource={orgData}
            valueExpr="org_id"
            displayExpr={(e) => {
              return e && `${e.org_name}`;
            }}
          />
        </Column>
        <Column
          dataField="agenda_code"
          caption="Хөтөлбөр"
          groupIndex={1}
          minWidth={"200px"}
          groupCellComponent={groupCell}
        >
          <Lookup
            dataSource={agenda}
            valueExpr="code"
            displayExpr={(e) => {
              return e && `${e.code.slice(0, 5)} - ${e.name}`;
            }}
          />
        </Column>
        <Column
          dataField="zorilt_name"
          caption="Хөтөлбөрийн зорилт"
          alignment="left"
          groupIndex={2}
          width={500}
        />

        <Column
          dataField="shalhuur_name"
          caption="Шалгуур, үзүүлэлт"
          alignment="left"
          width={"auto"}
          visible={checkVisible}
          cellRender={(e) => {
            return e.value;
          }}
        />

        <Column
          dataField="measure_unit"
          caption="Хэмжих нэгж"
          alignment="center"
        />
        <Column dataField="base_year" caption="Cуурь жил" alignment="center" />
        <Column
          dataField="base_level"
          caption="Cуурь түвшин"
          alignment="center"
        />
        <Column
          dataField="target_level"
          caption={`${props.year} оны зорилтот түвшин`}
          alignment="center"
        />
        <Summary >
          <GroupItem column="measure_unit"
            summaryType="sum"
            showInGroupFooter={false} 
            alignByColumn={true}
            customizeText={(data) => {
              return ``
            }} />
          <GroupItem column="base_level"
            summaryType="sum"
            showInGroupFooter={false} alignByColumn={true}
            customizeText={(data) => {
              return ``
            }} />
          <GroupItem column="target_level"
            summaryType="sum"
            showInGroupFooter={false} alignByColumn={true}
            customizeText={(data) => {
            return ``
            }} />
          {/* <TotalItem column="prev_budget"
            summaryType="sum"
            showInGroupFooter={false} alignByColumn={true}
            customizeText={(data) => {
              return `${numberWithCommas(data.value)}`
            }} />
          <TotalItem column="p1_guitsetgel"
            summaryType="sum"
            showInGroupFooter={false} alignByColumn={true}
            customizeText={(data) => {
              return `${numberWithCommas(data.value)}`
            }} />
          <TotalItem column="batlagdsan"
            summaryType="sum"
            showInGroupFooter={false} alignByColumn={true}
            customizeText={(data) => {
              return `${numberWithCommas(data.value)}`
            }} />
          <TotalItem column="batlagdsan_edit"
            summaryType="sum"
            showInGroupFooter={false} alignByColumn={true}
            customizeText={(data) => {
              if (data.value === prevPerp) {
                setPrevDiff(0)
                setPrevDiffPerc(0)
              }
              return `${numberWithCommas(data.value)}`
            }} /> */}

        </Summary>
      </DataGrid>
    </div>
  );
};

export default BudgetZarlagaAgenda;

import React, {Component} from "react";
import { Button } from 'devextreme-react/button'
import TabPanel, { Item } from 'devextreme-react/tab-panel';
import {SelectBox} from "devextreme-react/select-box";
import dynamicTableService from "../../services/api/dynamicTable";
import fileService from "../../services/api/file";
import DataGrid, {
    Selection, Paging, FilterRow, Scrolling, Column
} from 'devextreme-react/data-grid';
import DropDownBox from 'devextreme-react/drop-down-box';
import Popup from 'devextreme-react/popup';
import TextBox from 'devextreme-react/text-box';
import { NumberBox } from 'devextreme-react/number-box';
import RadioGroup from 'devextreme-react/radio-group';
import Notify from 'devextreme/ui/notify';
import {custom} from "devextreme/ui/dialog";
import { LoadIndicator } from 'devextreme-react/load-indicator';
import budgetCalculationService from "../../services/api/budgetCalculation";
import { Lookup, DropDownOptions } from "devextreme-react/lookup"
import DataSource from 'devextreme/data/data_source';
import "./tableTemplate.css";
import LawIcon from '../../assets/imgs/lawIcon.png';
import DocumentIcon from '../../assets/imgs/law-component-document.svg'
import { Tooltip } from 'devextreme-react/tooltip';
import FileUploader from 'devextreme-react/file-uploader';
import _ from 'lodash';
import { Popover } from 'devextreme-react/popover';
// import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import Excel from 'exceljs';
import {saveAs} from "file-saver";

class tableTemplate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            header: [],
            leftHeader: [],
            length: 0,
            formattedTopHeader: [],
            formattedLeftHeader: [],
            leftTotalColSpan: 0,
            // econ_category_id: null,
            // econ_category_code: null,
            tariffList: [],
            budgetCalcHistory: [],
            data: [],
            initialData: [],
            totalValue: 0,
            popupVisible: false,
            lawType: 0,
            unitMeasureName: null,
            unitMeasure: '',
            unitMeasureValue: 0,
            value: {},
            calcOrgEconValue: {},
            unitMeasureList: [],
            template: {},
            loading: false,
            budgetCalcDetail: {},
            dynamicTableData: {},
            agendaList: [],
            eventList: [],
            files: [],
            loadingBudgetSave: false,
            isGridBoxOpened: {},
            selectedTabIndex: 0,
            pageSize: 20,
            pageIndex: 0,
            historyPageSize: 10,
            historyPageIndex: 0,
            popover: null,
            configureTitle: {
                'tariff_type.name': 'Тогтмол утгын төрөл',
                'amount': 'Дүн',
                'unit_measure_obj.name': 'Хэмжих нэгж',
                'value.car_mark': 'Марк',
                'value.car_model': 'Загвар',
                'value.engine_capacity': 'Хөдөлгүүрийн багтаамж',
                'value.fuel': 'Шатахууны төрөл',
                'value.continent': 'Тив',
                'value.country': 'Улс',
                'value.food': 'Хоолны зардал',
                'value.hotel': 'Байрны зардал'
            }
        };

        this.tableRef = React.createRef();
    }

    componentDidMount() {
        // console.log('did mount')
        this._ismounted = true;
        // this._loading = false;
        this._dataChanged = false;
        // console.log('componentDidMount', this._ismounted)
        if (this.props.template) {
            let template = this.props.template || {};
            let mainHeader = template.header || {};
            // console.log('template', template)

            // console.log('mainHeader.header', mainHeader.header)

            this.setState({
                template,
                header: mainHeader.header || [],
                leftHeader: mainHeader.leftHeader || [],
                length: 0,
                leftTotalColSpan: 0,
                data: [],
                initialData: [],
                formattedTopHeader: [],
                formattedLeftHeader: [],
                budgetCalcHistory: [],
                loading: true
            }, async () => {
                this.headerFormatter();

                let econ_category_id = template.econ_category_id;
                let tariffService = dynamicTableService.getRefTariffInfo(econ_category_id);
                let unitMeasureService = dynamicTableService.getUnitMeasure();
                let agendaService = dynamicTableService.getAgendaList();
                let eventService = dynamicTableService.getEventList();
                let calcOrgEconService = dynamicTableService.getCalcOrgEconValue(econ_category_id);
                let dataService = budgetCalculationService.getBudgetCalculationData(template.id);
                let budgetCalcHistoryService = budgetCalculationService.getBudgetCalcHistory(econ_category_id);

                let services = [tariffService, unitMeasureService, agendaService, eventService, calcOrgEconService, dataService, budgetCalcHistoryService];

                let responses = await Promise.all(services);

                let tariffList = [];
                let unitMeasureList = [];
                let agendaList = [];
                let eventList = [];
                let calcOrgEconValue = {};
                let budgetCalcHistory = [];
                let data = [];
                let dynamicTableData = {};
                let budgetCalcDetail = {};

                if (responses[0].code === 200) {
                    tariffList = responses[0].data || [];
                }

                if (responses[1].code === 200) {
                    unitMeasureList = responses[1].data || [];
                }

                if (responses[2].code === 200) {
                    agendaList = responses[2].data || [];
                }

                if (responses[3].code === 200) {
                    eventList = responses[3].data || [];
                }

                if (responses[4].code === 200) {
                    calcOrgEconValue = responses[4].data || {};
                }

                if (responses[5].code === 200) {
                    let d = responses[5].data || {};
                    dynamicTableData = d.dynamicTableData || {};
                    budgetCalcDetail = d.budgetCalcDetail || {};
                    data = dynamicTableData.data || [];

                    let groupByLeftName = Object.groupBy(data, ({ leftName }) => leftName);
                    let maxKey = Math.max(...(Object.keys(groupByLeftName) || [])) || 0;

                    let refFilter = data.filter(v => v.leftName === maxKey && !v.ref && v.value !== 0);

                    let calcOrgEconValue = this.state.calcOrgEconValue || {};
                    let values = calcOrgEconValue.value || [];

                    // console.log('refFilter', refFilter, maxKey)

                    if (refFilter.length > 0) {
                        values.map(v => {
                            let obj = {
                                leftName: maxKey+1,
                                value: v.amount,
                                refId: v.value,
                                ref: true
                            };

                            Object.keys(v).map((d, i1) => {
                                if (d.indexOf("topName") !== -1) {
                                    obj[d] = v[d]
                                }
                            });

                            data.push(obj);
                        });

                        data.push({
                            leftName: maxKey+1
                        })
                    } else if (refFilter.length === 0 && data.length === 0) {
                        if (values.length > 0) {
                            values.map(v => {
                                let obj = {
                                    leftName: 0,
                                    value: v.amount,
                                    refId: v.value,
                                    ref: true
                                };

                                Object.keys(v).map((d, i1) => {
                                    if (d.indexOf("topName") !== -1) {
                                        obj[d] = v[d]
                                    }
                                });

                                data.push(obj);
                            })
                        } else {
                            data.push({leftName: 0});
                        }
                    }
                }

                if (responses[6].code === 200) {
                    budgetCalcHistory = responses[6].data || [];
                }

                // console.log('data', data);

                this.setState({
                    tariffList,
                    unitMeasureList,
                    agendaList,
                    eventList,
                    calcOrgEconValue,
                    budgetCalcHistory,
                    data,
                    dynamicTableData,
                    budgetCalcDetail,
                    loading: false
                }, () => {
                    this.setDataStaticValue();
                });
            })
        }
    }

    componentWillMount() {
        // console.log('will mount', this.props.width)
    }

    componentWillUnmount() {
        this._ismounted = false;
        // console.log('componentWillUnmount',this._ismounted)
    }

    getAgendaList = async () => {
        const result = await dynamicTableService.getAgendaList();
        if (result.code === 200) {
            let data = result.data || [];
            this.setState({agendaList: data});
        } else {
            this.setState({agendaList: []});
        }
    }

    getEventList = async () => {
        const result = await dynamicTableService.getEventList();
        if (result.code === 200) {
            let data = result.data || [];
            this.setState({eventList: data});
        } else {
            this.setState({eventList: []});
        }
    }

    getData = async (dynamicTableId) => {
        console.log('getData')
        let result = await budgetCalculationService.getBudgetCalculationData(dynamicTableId);
        if (result.code === 200) {
            let d = result.data || {};
            let dynamicTableData = d.dynamicTableData || {};
            let budgetCalcDetail = d.budgetCalcDetail || {};
            let data = dynamicTableData.data || [];

            if (budgetCalcDetail.id) {
                this.getBudgetCalcHistory(this.props.econ_category_id);
            }

            let groupByLeftName = Object.groupBy(data, ({ leftName }) => leftName);
            let maxKey = Math.max(...(Object.keys(groupByLeftName) || [])) || 0;

            let refFilter = data.filter(v => v.leftName === maxKey && !v.ref && v.value !== 0);

            let calcOrgEconValue = this.state.calcOrgEconValue || {};
            let values = calcOrgEconValue.value || [];

            if (refFilter.length > 0) {
                values.map(v => {
                    let obj = {
                        leftName: maxKey+1,
                        value: v.amount,
                        refId: v.value,
                        ref: true
                    };

                    Object.keys(v).map((d, i1) => {
                        if (d.indexOf("topName") !== -1) {
                            obj[d] = v[d]
                        }
                    });

                    data.push(obj);
                })
            }

            if (refFilter.length === 0 && data.length === 0) {
                // console.log('values', values)
                if (values.length > 0) {
                    values.map(v => {
                        let obj = {
                            leftName: 0,
                            value: v.amount,
                            refId: v.value,
                            ref: true
                        };

                        Object.keys(v).map((d, i1) => {
                            if (d.indexOf("topName") !== -1) {
                                obj[d] = v[d]
                            }
                        });

                        data.push(obj);
                    })
                } else {
                    data.push({});
                }
            }

            // console.log('initialDat set');
            this.initialData = [...data];
            this.setState({
                data: [...data],
                initialData: _.cloneDeep(data),
                dynamicTableData,
                budgetCalcDetail
            })
        } else {
            this.setState({
                data: [],
                initialData: [],
                budgetCalcDetail: {},
                dynamicTableData: {}
            });
        }
    }

    getBudgetCalcHistory = async (econ_category_id) => {
        if (!econ_category_id) {
            return;
        }
        let result = await budgetCalculationService.getBudgetCalcHistory(econ_category_id);
        // console.log('result', result, budgetCalcDetailId)
        if (result.code === 200) {
            if (this._ismounted) {
                this.setState({budgetCalcHistory: result.data || []})
            }
        } else {
            if (this._ismounted) {
                this.setState({budgetCalcHistory: []});
            }
        }
    }

    getCalcOrgEconValue = async (econ_category_id) => {
        // console.log('getCalcOrgEconValue', econ_category_id);
        let result = await dynamicTableService.getCalcOrgEconValue(econ_category_id);
        if (result.code === 200) {
            this.setState({calcOrgEconValue: result.data || {}}, () => {
                this.setDataStaticValue();
            });
        } else {
            this.setState({calcOrgEconValue: {}}, () => {
                this.setDataStaticValue();
            });
        }
    }

    tariffInfo = async (template) => {
        let tariffList = [];
        try {
            if (template.econ_category_id) {
                let response = await dynamicTableService.getRefTariffInfo(template.econ_category_id);
                if (response.code === 200) {
                    tariffList = response.data || [];
                }
            }
        } catch (e) {
            console.log('tariffInfo error',e);
            tariffList = [];
        }

        // console.log('tariffList', tariffList)

        this.setState({tariffList})
    }

    getUnitMeasure = async () => {
        let unitMeasureList = [];
        try {
            let response = await dynamicTableService.getUnitMeasure();
            if (response.code === 200) {
                unitMeasureList = response.data || [];
            }
        } catch (e) {
            unitMeasureList = [];
        }

        this.setState({unitMeasureList})
    }

    headerFormatter = () => {
        let formattedTopHeader = [];
        let formattedLeftHeader = [];
        let leftTotalColSpan = 0;
        this.state.header.map((data, index) => {
            if (0 === index) {
                data.map((data1, index1) => {
                    for (let i=0; i<data1.colSpan; i++) {
                        let obj = {topName: data1.name, rowSpan: data1.rowSpan-1, colSpan: data1.colSpan};
                        if (data1.formula) {
                            obj.formula = data1.formula;
                        }

                        if (data1.sumFormula) {
                            obj.sumFormula = true;
                        }

                        if (data1.config) {
                            obj.config = true;
                        }

                        if (data1.configInput) {
                            obj.configInput = true;
                        }

                        if (data1.configDefaultColumn) {
                            obj.configDefaultColumn = data1.configDefaultColumn;
                        }

                        if (data1.stringColumn) {
                            obj.stringColumn = true;
                        }

                        if (data1.numberColumn) {
                            obj.numberColumn = true;
                        }

                        formattedTopHeader.push(obj)
                    }
                })
            } else {
                data.map((data1, index1) => {
                    for (let i=0; i<data1.colSpan; i++) {
                        let addedField = false;
                        formattedTopHeader.map((data2, index2) => {
                            if (!data2['index'+index+'Set']) {
                                if (data2.rowSpan > 0) {
                                    data2.rowSpan -= 1;
                                    data2['index'+index+'Set'] = true;
                                } else if (data2.rowSpan === 0 && !data2['topName'+index] && addedField === false) {
                                    data2['topName'+index] = data1.name;
                                    data2.rowSpan = data1.rowSpan-1;
                                    addedField = true;
                                    data2['index'+index+'Set'] = true;

                                    if (data1.formula) {
                                        data2.formula = data1.formula;
                                    }

                                    if (data1.sumFormula) {
                                        data2.sumFormula = true;
                                    }

                                    if (data1.config) {
                                        data2.config = true;
                                    }

                                    if (data1.configInput) {
                                        data2.configInput = true;
                                    }

                                    if (data1.configDefaultColumn) {
                                        data2.configDefaultColumn = data1.configDefaultColumn;
                                    }

                                    if (data1.stringColumn) {
                                        data2.stringColumn = true;
                                    }

                                    if (data1.numberColumn) {
                                        data2.numberColumn = true;
                                    }
                                }
                            }
                        })
                    }
                });
            }
        })

        let mainRowSpan = 0;
        let rowSpan = 0;
        this.state.leftHeader.map((data, index) => {
            let totalColSpan = 0;

            if (rowSpan === 0) {
                rowSpan = data[0].rowSpan;
                mainRowSpan = data[0].rowSpan;
            }

            if (rowSpan >= 1 && mainRowSpan > 1) {
                if (mainRowSpan === rowSpan) {
                    let flhData = [];
                    data.map((data1, index1) => {
                        totalColSpan += data1.colSpan;
                        if (index1 === 0) {
                            for (let i=0; i<data1.rowSpan; i++) {
                                let d = {leftName: data1.name, rowSpan: data1.rowSpan, colSpan: data1.colSpan};
                                flhData.push(d);
                            }
                        } else {
                            for (let i=0; i<mainRowSpan; i++) {
                                let si = index1-1;
                                if (i === 0) {
                                    si = index1;
                                }
                                flhData[i]['leftName'+index1] = this.state.leftHeader[index+i][si].name;
                            }
                        }
                    });
                    formattedLeftHeader = [...formattedLeftHeader, ...flhData]
                }

                rowSpan -= 1;
            } else if (rowSpan === 1 && mainRowSpan === 1) {
                let flhData = {};
                for (let i=0; i<data.length; i++) {
                    let data1 = data[i];
                    totalColSpan += data1.colSpan;
                    if (i === 0) {
                        flhData = {leftName: data1.name, rowSpan: data1.rowSpan, colSpan: data1.colSpan};
                    } else {
                        let tIndex = 0;
                        for (let i1=0; i1<i; i1++) {
                            tIndex += data[i1].colSpan;
                        }
                        flhData['leftName' + tIndex] = data1.name;
                    }
                }
                rowSpan = 0;
                formattedLeftHeader.push(flhData)
            }

            if (leftTotalColSpan < totalColSpan) {
                leftTotalColSpan = totalColSpan;
            }
        });

        this.setState({formattedTopHeader, formattedLeftHeader, leftTotalColSpan})
    }

    topSaveData = (field, fieldType, value) => {
        if (this.state.selectedTabIndex !== 0) {
            return;
        }

        // console.log('topSaveData', value)

        let dataChanged = false;
        let data = [...this.state.data];
        let fnd = data.find(d => {
            return Object.keys(field).every((key) => {
                return d[key] === field[key]
            })
        })

        if (fieldType !== 'String') {
            if (fieldType === 'Number') {
                value = Math.floor(value);
            } else if (fieldType === 'Amount') {
                value = (value || '').replace(",", "")*1 || 0;
            }
        }

        if (fnd) {
            if (fnd.value !== value) {
                fnd.value = value;
                dataChanged = true;
            }
        } else {
            let obj = {};
            Object.keys(field).map(key => {
                obj[key] = field[key];
            })
            obj.value = value;
            data.push(obj);
            dataChanged = true;
        }

        data = data.filter(v => v.leftName !== undefined);

        let result = Object.groupBy(data, ({ leftName }) => leftName);
        let maxKey = Math.max(...(Object.keys(result) || [])) || 0;
        let refFilter = data.filter(v => v.leftName === maxKey && !v.ref && v.value !== 0 && v.topName);

        let calcOrgEconValue = this.state.calcOrgEconValue || {};
        let values = calcOrgEconValue.value || [];

        if (refFilter.length > 0) {
            if (values.length > 0) {
                let key = maxKey+1;
                let agenda = data.filter((v) => v.leftName === maxKey && v.topName === 'Хөтөлбөр');
                let event = data.filter((v) => v.leftName === maxKey && v.topName === 'Арга хэмжээ');
                let d = data.filter((v) => v.leftName === maxKey && v.topName !== 'Хөтөлбөр' && v.topName !== 'Арга хэмжээ' && v.value !== 0 && v.value !== '' && !v.ref);
                if (d.length > 0) {
                    values.map(v => {
                        let obj = {};
                        Object.keys(v).map((d, i) => {
                            if (d.indexOf("topName") !== -1) {
                                obj[d] = v[d];
                            }
                        });

                        obj.leftName = maxKey+1;
                        obj.value = v.amount;
                        obj.refId = v.value;
                        obj.ref = true;
                        data.push(obj);
                    });

                    if (event.length > 0) {
                        data.push({leftName: key, topName: 'Арга хэмжээ', value: event[0].value});
                    }

                    if (agenda.length) {
                        data.push({leftName: key, topName: 'Хөтөлбөр', value: agenda[0].value});
                    }
                }

            } else {
                let key = maxKey+1;
                let agenda = data.filter((v) => v.leftName === maxKey && v.topName === 'Хөтөлбөр');
                let event = data.filter((v) => v.leftName === maxKey && v.topName === 'Арга хэмжээ');
                let d = data.filter((v) => v.leftName === maxKey && v.topName !== 'Хөтөлбөр' && v.topName !== 'Арга хэмжээ' && v.value !== 0 && v.value !== '' && !v.ref);
                // console.log('d',d)
                // console.log('data', data, event, agenda, d);

                if (d.length > 0) {
                    if (event.length > 0) {
                        data.push({leftName: key, topName: 'Арга хэмжээ', value: event[0].value});
                    }

                    if (agenda.length) {
                        data.push({leftName: key, topName: 'Хөтөлбөр', value: agenda[0].value});
                    }

                    if (event.length === 0 && agenda.length === 0) {
                        data.push({leftName: key});
                    }
                }
            }
        }

        values.map(v => {
            let obj = {};
            Object.keys(v).map((d, i1) => {
                if (d.indexOf("topName") !== -1) {
                    obj[d] = v[d]
                }
            });

            let fnd = data.find(d => {
                return Object.keys(obj).every((key) => {
                    return d[key] === obj[key]
                })
            })

            if (fnd) {
                fnd['value'] = v.amount;
                fnd['refId'] = v.value;
                fnd['ref'] = true;
            }
        })

        if (dataChanged) {
            if (this._ismounted) {
                // this._loading = true;
                // console.log('topSaveData set data');
                this._dataChanged = true;
                this.setState({
                    data: [...data],
                }, () => {
                    // console.log('data', data)
                    // this._loading = false;
                });
            }
        }

    }

    numberWithCommas = (x) => {
        let parts = x.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return parts.join(".");
    }

    objectsEqual = (o1, o2) => {
        console.log(o1, o2)
        if (o1 === null || o1 === undefined) {
            o1 = '';
        }
        if (o2 === null || o2 === undefined) {
            o2 = '';
        }

        return typeof o1 === 'object' && Object.keys(o1).length > 0
            ? Object.keys(o1).length === Object.keys(o2).length
            && Object.keys(o1).every(p => this.objectsEqual(o1[p], o2[p]))
            : o1 === o2;
    }

    saveBudgetData = async (totalValue) => {
        this.setState({loadingBudgetSave: true});
        let saveData = [];
        let dataGroupBy = Object.groupBy(this.state.data, ({leftName}) => leftName);
        let delIndex = [];
        Object.keys(dataGroupBy).map(key => {
            let filterWithoutZero = dataGroupBy[key].filter((obj) => obj.value > 0 && obj.value !== '0' && obj.value !== '0.00' && obj.value !== '');
            let filterWithoutEventAgenda = filterWithoutZero.filter((obj) => obj.topName !== 'Арга хэмжээ' && obj.topName !== 'Хөтөлбөр');
            if (filterWithoutEventAgenda.length === 0) {
                delIndex.push(key*1);
            }
            let checkRefData = filterWithoutZero.every((obj) => obj.ref === true);
            if (!checkRefData) {
                saveData = [...saveData, ...dataGroupBy[key]];
            }
        });

        saveData = saveData.filter((obj) => !delIndex.includes(obj.leftName));

        let date = new Date();
        let result = await budgetCalculationService.addBudgetCalculationData({
            budgetCalcDetailId: this.state.budgetCalcDetail.id,
            dynamicTableDataId: this.state.dynamicTableData.id,
            dynamicTableId: this.state.template.id,
            econCategoryId: this.props.econ_category_id,
            data: saveData,
            year: date.getFullYear(),
            month: date.getMonth()+1,
            totalAmount: totalValue
        })

        if (result.code === 200) {
            // console.log('result', result.data.dynamicTableId);
            let dynamicTableData = this.state.dynamicTableData || {};
            dynamicTableData.id = result.data.dynamicTableId;
            // console.log('saveBudgetData success')
            this.setState({initialData: _.cloneDeep(this.state.data), loadingBudgetSave: false, dynamicTableData});
            // if (this.state.budgetCalcDetail.id) {
            this.getBudgetCalcHistory(this.props.econ_category_id);
            // }
            this.props.savedData();
            Notify({
                message: 'Тооцооллыг амжилттай хадгаллаа',
                width: 300,
                type: 'success',
            }, {
                position: {
                    top: 90,
                    left: undefined,
                    bottom: undefined,
                    right: 30,
                },
                direction: 'down-push'
            });
        } else {
            this.setState({loadingBudgetSave: false});
            Notify({
                message: 'Тооцооллыг хадгалахад алдаа гарлаа',
                width: 300,
                type: 'warning',
            }, {
                position: {
                    top: 90,
                    left: undefined,
                    bottom: undefined,
                    right: 30,
                },
                direction: 'down-push'
            });
        }
    }

    displayHeader = (item) => {
        let headerName = '';
        if (item) {
            headerName = item.tariff_type.name + ' - ' + item.amount*1 + '' + item.unit_measure_obj.name;
            if (item.created_user_id === 3) {
                headerName += ' (Системээс үүсгэсэн)'
            }
        }
        return headerName
    }

    displayTypeHeader = (item) => {
        let headerName = '';
        if (item && item.tariff_type) {
            headerName = item.tariff_type.name;
        }
        return headerName;
    }

    displayMeasureHeader = (item) => {
        let headerName = '';
        if (item && item.unit_measure_obj) {
            headerName = item.unit_measure_obj.name;
        }
        return headerName;
    }

    headerValueChanged = async (e, value, type) => {
        let searchObject = {};
        let calcOrgEconValue = this.state.calcOrgEconValue || {};
        let calcOrgEconValueV = calcOrgEconValue.value || [];

        Object.keys(value).map((d3, i1) => {
            if (d3.indexOf("topName") !== -1) {
                searchObject[d3] = value[d3]
            }
        });

        let fnd = calcOrgEconValueV.find(d => {
            return Object.keys(searchObject).every((key) => {
                return d[key] === searchObject[key]
            })
        });

        let fndAmount = this.state.tariffList.find(d => d.id === e.value) || {};

        let v = e.value;
        let a = fndAmount.amount * 1;

        if (type === 'input') {
            v = null;
            a = e.value;
        }

        if (fnd) {
            fnd['value'] = v;
            fnd['amount'] = a;
        } else {
            if (a) {
                calcOrgEconValueV.push({...searchObject, type, value: v, amount: a});
                calcOrgEconValue.value = calcOrgEconValueV;
            }
        }

        this.setState({calcOrgEconValue}, async () => {
            if (this.state.calcOrgEconValue.value) {
                await dynamicTableService.addCalcOrgEconValue(this.state.calcOrgEconValue, this.props.econ_category_id);
                this.getCalcOrgEconValue(this.props.econ_category_id);
            }
        });
    }

    headerGetValue = (searchObject, type) => {
        let values = this.state.calcOrgEconValue.value || [];

        let fnd = values.find(d => {
            return Object.keys(searchObject).every((key) => {
                return d[key] === searchObject[key]
            })
        }) || {};

        if (type === 'select') {
            return fnd.value || null
        } else {
            return fnd.amount || null
        }
    }

    setDataStaticValue = () => {
        let calcOrgEconValue = this.state.calcOrgEconValue || {};
        let values = calcOrgEconValue.value || [];
        let data = this.state.data;

        let dataGroupByLeftName = Object.groupBy(data, ({ leftName }) => leftName);

        Object.keys(dataGroupByLeftName).map((key) => {
            values.map(v => {
                if (v.amount) {
                    let searchObject = {};
                    Object.keys(v).map((d, i1) => {
                        if (d.indexOf("topName") !== -1) {
                            searchObject[d] = v[d]
                        }
                    });

                    let fnd = dataGroupByLeftName[key].find(d => {
                        return Object.keys(searchObject).every((key) => {
                            return d[key] === searchObject[key]
                        })
                    });

                    if (fnd) {
                        fnd['refId'] = v.value;
                        fnd['value'] = v.amount;
                    } else {
                        let obj = {
                            leftName: key*1,
                            value: v.amount,
                            refId: v.value,
                            ref: true
                        }
                        Object.keys(v).map((d, i1) => {
                            if (d.indexOf("topName") !== -1) {
                                obj[d] = v[d]
                            }
                        });
                        data.push(obj);
                    }
                }
            });
        });

        this.setState({data: [...data]});
    };

    removeTariffInfo = async (data) => {
        // console.log('removeTariffInfo',data)
        let confirmDialog = custom({
            title: "Баталгаажуулалт",
            messageHtml: `<span>(${data.tariff_type.name} - ${data.amount})Тогтмол утгыг устгах уу?</span>`,
            buttons: [{
                    text: "Тийм",
                    onClick: (e) => {
                        return { buttonText: "yes" }
                    }
                },
                {
                    text: "Үгүй",
                    onClick: (e) => {
                        return { buttonText: "no" }
                    }
                }
            ]
        });
        let dialogResult = await confirmDialog.show();
        if (dialogResult.buttonText === "yes") {
            let response = await dynamicTableService.removeRefTariffInfo(data.id);
            if (response.code === 200) {
                Notify({
                    message: 'Тогтмол утга устгагдлаа',
                    width: 300,
                    type: 'success',
                }, {
                    position: {
                        top: 90,
                        left: undefined,
                        bottom: undefined,
                        right: 30,
                    },
                    direction: 'down-push'
                });
                this.removeTariffFromData(data);
                this.tariffInfo(this.props.template);
            } else {
                Notify({
                    message: 'Тогтмол утга устгахад алдаа гарлаа',
                    width: 300,
                    type: 'warning',
                }, {
                    position: {
                        top: 90,
                        left: undefined,
                        bottom: undefined,
                        right: 30,
                    },
                    direction: 'down-push'
                });
            }
        }
    }

    removeTariffFromData = (tariff) => {
        let data = this.state.data;
        let filteredData = data.filter((obj) => obj.refId !== tariff.id);
        let calcOrgEcon = this.state.calcOrgEconValue || {};
        let calcOrgEconV = calcOrgEcon.value || [];
        let filteredV = calcOrgEconV.filter((obj) => obj.value !== tariff.id);

        this.setState({data: [...filteredData], calcOrgEconValue: {...calcOrgEcon, value: filteredV}}, () => {
            dynamicTableService.addCalcOrgEconValue(this.state.calcOrgEconValue, this.props.econ_category_id);
        });
    }

    editTariffInfo = (data) => {
        this.setState({
            tariffId: data.id,
            tariffType: data.tariff_type_id,
            unitMeasure: data.unit_measure_id,
            unitMeasureName: data.unit_measure,
            unitMeasureValue: data.amount,
            popupVisible: true
        })
    }

    tariffButtonRender = (item) => {
        let {data} = item;
        if (data.created_user_id !== 3) {
            return <>
                <Button icon="edit" stylingMode="text" onClick={(e) => this.editTariffInfo(data)} />
                <Button icon="trash" stylingMode="text" onClick={(e) => this.removeTariffInfo(data)} />
            </>
        } else {
            return <></>
        }
    }

    cellUserRender = (e) => {
        let user = e.value || {};
        let first_name = user.first_name || '';
        let last_name = user.last_name || '';

        let userName = last_name ? `${last_name.slice(0, 1)}.${first_name || ''}` : `${first_name || ''}`
        return userName
    }

    lawRender = (e) => {
        let value = e.value || {};
        let data = e.data || {};
        // console.log('value', e, value);
        let url = '';
        let name = '';
        let width = 25;
        let height = 25;
        if (data.file) {
            let file = data.file || {};
            url = `/api/fileServices/budgetCalc/${file.file_name}`;
            name = file.file_name;
            width = 15;
            height = 20;
        } else if (data.law) {
            let law = data.law;
            url = law.legal_url;
            name = law.name;
        }

        return <div style={{textAlign: 'center'}}>
            {url && <a target={'_blank'} href={url}>
                <img id={`lawToolTip${e.rowIndex}`} src={data.file ? DocumentIcon : LawIcon} width={width} height={height}/>
                <Tooltip
                    target={`#lawToolTip${e.rowIndex}`}
                    showEvent="dxhoverstart"
                    hideEvent="dxhoverend"
                >
                    <div style={{padding: 10}}>
                        {name}
                    </div>
                </Tooltip>
            </a>}
        </div>
    }

    handlePopupHidden = () => {
        this.setState({
            popupVisible: false
        });
    }

    lawTypeChanged = (e) => {
        // console.log('lawTypeChanged',e.value);
        this.setState({
            lawType: e.value
        })
    }

    addTariff = async () => {
        let law_id = 1204;
        let tariff_id = this.state.tariffId;
        let tariff_type_id = this.state.tariffType;
        let unit_measure = this.state.unitMeasure;
        let unit_measure_name = this.state.unitMeasureName;
        let amount = this.state.unitMeasureValue;
        let econ_category_id = this.props.econ_category_id;
        let econ_category_code = this.props.econ_category_code;
        let law_link = null;
        if (this.state.lawType === 0) {
            law_link = this.state.lawURL || null;
        }

        let fileResponse;
        let file_id = null;
        if (this.state.lawType === 1) {
            if (this.state.files.length > 0) {
                let file = this.state.files[0];
                let data = new FormData();
                data.append('file', file);
                fileResponse = await fileService.uploadBudgetCalcFiles(data);
                // console.log('fileResponse', fileResponse);
                if (fileResponse.code === 200) {
                    file_id = fileResponse.data*1;
                }
            }
        }

        let value = null;
        if (Object.keys(this.state.value).length > 0) {
            value = this.state.value;
        }

        let response = await dynamicTableService.addRefTariffInfo(tariff_id, econ_category_id, econ_category_code, tariff_type_id, unit_measure, unit_measure_name, amount, law_link, file_id, value);
        if (response.code === 200) {
            this.setState({popupVisible: false});
            this.tariffInfo(this.props.template);
            Notify({
                message: 'Тогтмол утга хадгалагдлаа',
                width: 300,
                type: 'success',
            }, {
                position: {
                    top: 90,
                    left: undefined,
                    bottom: undefined,
                    right: 30,
                },
                direction: 'down-push'
            });
        }
        // console.log('response', response)
    }

    lookupRender = (data) => (data ? `${data.code.substring(0, 5)} - ${data.name}` : '');

    fileUpload = (e) => {
        console.log('fileUpload', e.value);
        this.setState({files: e.value});
    }

    dataGridRender = (searchObject, i) => {
        // console.log('this.state.tariffList', this.state.tariffList);
        let tariffList = this.state.tariffList;
        let columns = ['tariff_type.name', 'unit_measure_obj.name', 'amount'];
        let configureTitle = this.state.configureTitle;
        if (tariffList.length > 0 && tariffList[0].value) {
            let value = tariffList[0].value;
            Object.keys(value).map(key => {
                columns.push(`value.${key}`);
            })
        }

        return <DataGrid
            dataSource={tariffList}
            // columns={columns}
            columnAutoWidth={true}
            hoverStateEnabled={true}
            showBorders={true}
            selectedRowKeys={null}
            onSelectionChanged={(e) =>this.dataGridOnSelectionChanged(e, searchObject, i)}
            height="100%"
        >
            <Selection mode="single" />
            <Scrolling mode="virtual" />
            <Paging
                enabled={true}
                pageSize={10}
            />
            <FilterRow visible={true} />
            {columns.map((v, i) => {
                if (v === 'amount') {
                    return <Column key={i} dataField={v} caption={configureTitle[v]} format={'#,##0.##'}/>
                } else {
                    return <Column key={i} dataField={v} caption={configureTitle[v]}/>
                }
            })}
        </DataGrid>

    };

    onGridBoxOpened = (e, i) => {
        if (e.name === 'opened') {
            let isGridBoxOpened = this.state.isGridBoxOpened;
            isGridBoxOpened[i] = e.value;
            this.setState({isGridBoxOpened});
        }
    }

    selectConfig = (e, field) => {
        let value = e.value;
        // console.log('value', value);
        let data = [...this.state.data];
        let tariffList = this.state.tariffList;
        let headers = this.state.formattedTopHeader;

        let filter_result = data.filter(v => v.topName === field.topName && v.configDefaultColumn);
        let filter_tariff_result = tariffList.filter(v => v.id === value);
        let filter_header_result = headers.filter(v => v.configDefaultColumn);

        let found_tariff = {};
        if (filter_tariff_result.length > 0) {
            found_tariff = filter_tariff_result[0];
        }

        if (filter_result.length === 0) {
            filter_header_result.map(v => {
                let obj = {};
                obj.leftName = field.leftName;
                obj.topName = v.topName;
                obj.topName1 = v.topName1;
                let val = found_tariff[v.configDefaultColumn];
                let valueVal = (found_tariff['value'] || {})[v.configDefaultColumn];
                if (val) {
                    if (v.configDefaultColumn === 'amount') {
                        val = val*1;
                    } else if (v.configDefaultColumn === 'unit_measure') {
                        val = val.name;
                    }
                    obj.value = val;
                    data.push(obj);
                } else if (valueVal) {
                    obj.value = valueVal;
                    data.push(obj);
                }
            })
        }

        this.setState({
            data: [...data],
        });
    }

    dataGridOnSelectionChanged = (e, searchObject, i) => {
        // console.log('searchObject', searchObject)
        let keys = e.selectedRowKeys || [];
        let data = [...this.state.data];
        let headers = this.state.formattedTopHeader;
        let isGridBoxOpened = this.state.isGridBoxOpened;

        let filter_header_result = headers.filter(v => v.configDefaultColumn);

        if (keys.length > 0) {
            let value = keys[0] || {};

            let fnd = data.find(d => {
                return Object.keys(searchObject).every((key) => {
                    return d[key] === searchObject[key]
                })
            });

            if (!fnd) {
                let obj = {};
                Object.keys(searchObject).map(key => {
                    obj[key] = searchObject[key];
                })
                obj.value = value.id*1;
                data.push(obj);
            } else {
                fnd.value = value.id*1;
            }
        }
        // console.log('dataGridOnSelectionChanged', e.selectedRowKeys, data)
        isGridBoxOpened[i] = false;
        this.setState({isGridBoxOpened, data})
    }

    syncDataGridSelection = (e, searchObject) => {
        // console.log('syncDataGridSelection')
        let data = [...this.state.data];
        let headers = this.state.formattedTopHeader;
        let tariffList = this.state.tariffList;
        let value = e.value;
        // console.log('value', value, searchObject)

        let filter_header_result = headers.filter(v => v.configDefaultColumn);

        if (value) {

            let filter_tariff_result = tariffList.filter(v => v.id === value);

            let found_tariff = {};
            if (filter_tariff_result.length > 0) {
                found_tariff = filter_tariff_result[0];
            }

            let fnd = data.find(d => {
                return Object.keys(searchObject).every((key) => {
                    return d[key] === searchObject[key]
                })
            });

            if (fnd) {
                fnd.value = value;
            } else {
                let obj = {};
                Object.keys(searchObject).map(key => {
                    obj[key] = searchObject[key];
                })
                obj.value = value;
                data.push(obj);
            }

            filter_header_result.map(v => {
                let obj = {};
                Object.keys(v).map((d3, i1) => {
                    if (d3.indexOf("topName") !== -1) {
                        obj[d3] = v[d3]
                    }
                });
                obj.leftName = searchObject.leftName;
                let fnd1 = data.find(d => {
                    return Object.keys(obj).every((key) => {
                        return d[key] === obj[key]
                    });
                });

                let val = found_tariff[v.configDefaultColumn];
                let valueVal = (found_tariff['value'] || {})[v.configDefaultColumn];

                if (fnd1) {
                    if (val) {
                        if (v.configDefaultColumn === 'amount') {
                            val = val*1;
                        } else if (v.configDefaultColumn === 'unit_measure') {
                            val = val.name;
                        }
                        fnd1.value = val;
                    } else if (valueVal) {
                        fnd1.value = valueVal;
                    }
                } else {
                    if (val) {
                        if (v.configDefaultColumn === 'amount') {
                            val = val*1;
                        } else if (v.configDefaultColumn === 'unit_measure') {
                            val = val.name;
                        }
                        obj.value = val;
                        data.push(obj);
                    } else if (valueVal) {
                        obj.value = valueVal;
                        data.push(obj);
                    }
                }


            })
        } else {
            let filter_header_result_name = [];
            filter_header_result.map(v => {
                let obj = {
                    leftName: searchObject['leftName']
                };
                Object.keys(v).map((d3, i1) => {
                    if (d3.indexOf("topName") !== -1) {
                        obj[d3] = v[d3]
                    }
                })
                filter_header_result_name.push(obj);
            });
            filter_header_result_name.push(searchObject);

            data = data.filter(element => {
                return !Object.keys(searchObject).every((key) => {
                    return element[key] === searchObject[key]
                })
            });

            let a = [];
            data.map(item => {
                let b = filter_header_result_name.map(v => Object.keys(v).every((key) => item[key] === v[key])).includes(true);
                if (!b) {
                    a.push(item);
                }
            });
            data = a;
        }

        // console.log('setState')
        this.setState({data: [...data]});
    }

    addConfig = () => {
        this.setState({
            tariffId: null,
            tariffType: null,
            unitMeasure: null,
            unitMeasureValue: null,
            popupVisible: true
        })
    }

    handleOptionChange = (e) => {
        if (e.fullName === 'paging.pageSize') {
            this.setState({
                pageSize: e.value
            })
        }

        if (e.fullName === 'paging.pageIndex') {
            this.setState({
                pageIndex: e.value
            });
        }
    }

    historyHandleOptionChange = (e) => {
        if (e.fullName === 'paging.pageSize') {
            this.setState({
                historyPageSize: e.value
            })
        }

        if (e.fullName === 'paging.pageIndex') {
            this.setState({
                historyPageIndex: e.value
            });
        }
    }

    removeRow = (index) => {
        let data = this.state.data;

        let groupByLeftName = Object.groupBy(data, ({ leftName }) => leftName);
        let maxKey = Math.max(...(Object.keys(groupByLeftName) || [])) || 0;

        data = data.filter(item => item.leftName !== index);

        // console.log('maxKey', maxKey, groupByLeftName);
        for (let i=index+1; i<=maxKey; i++) {
            data.map(item => {
                if (item.leftName === i) {
                    item.leftName = i-1;
                }
            })
        }

        this.setState({popover: null}, () => {
            this.setState({
                data: data
            });
        });

        // console.log('remove',index, data);
    }

    hidePopover = () => {
        // console.log('hide popover')
        this.setState({
            popover: null
        })
    }

    showPopover = (index) => {
        // console.log('showPopOver', index)
        this.setState({
            popover: index
        })
    }

    getNextKey = (key) => {
        if (key === 'Z' || key === 'z') {
            return String.fromCharCode(key.charCodeAt() - 25) + String.fromCharCode(key.charCodeAt() - 25); // AA or aa
        } else {
            var lastChar = key.slice(-1);
            var sub = key.slice(0, -1);
            if (lastChar === 'Z' || lastChar === 'z') {
                // If a string of length > 1 ends in Z/z,
                // increment the string (excluding the last Z/z) recursively,
                // and append A/a (depending on casing) to it
                return this.getNextKey(sub) + String.fromCharCode(lastChar.charCodeAt() - 25);
            } else {
                // (take till last char) append with (increment last char)
                return sub + String.fromCharCode(lastChar.charCodeAt() + 1);
            }
        }
        return key;
    };

    valueChanged = (d, v) => {
        let value = this.state.value;
        value[d] = v;
        this.setState({value});
    }

    colName = (n) => {
        let ordA = 'a'.charCodeAt(0);
        let ordZ = 'z'.charCodeAt(0);
        let len = ordZ - ordA + 1;

        let s = "";
        while(n >= 0) {
            s = String.fromCharCode(n % len + ordA) + s;
            n = Math.floor(n / len) - 1;
        }
        return s;
    }

    downloadExcel = async (fileName) => {
        let header = this.state.header;
        let data = this.state.data;
        data = data.filter(item => Object.keys(item).length > 1);
        let dataGrouped = Object.groupBy(data, ({ leftName }) => leftName);
        let formattedTopHeader = this.state.formattedTopHeader;
        let agendaList = this.state.agendaList;
        let eventList = this.state.eventList;

        const workbook = new Excel.Workbook();
        const worksheet = workbook.addWorksheet('My Sheet');

        let colStartArr = {};
        let top = 0;

        header.map((item, i) => {
                let left = 1;
                let leftTemp = 1;
                top += 1;
                item.map((item1, i1) => {
                    for (let i2=0; i2<item1.rowSpan; i2++) {
                        if (colStartArr[i2+i]) {
                            let filterDiffIndex = colStartArr[i2+i].filter(fItem => fItem.mIndex !== i);
                            for (let i3=0; i3<filterDiffIndex.length; i3++) {
                                let csaItem = filterDiffIndex[i3];
                                if (csaItem.left === leftTemp) {
                                    leftTemp = (csaItem.left+csaItem.colSpan);
                                }
                            }

                            if (leftTemp > left) {
                                left = leftTemp;
                            }
                            colStartArr[i2+i].push({mIndex: i, name: item1.name, top, bottom: top+(item1.rowSpan-1), left, right: left+(item1.colSpan-1), colSpan: item1.colSpan, rowSpan: item1.rowSpan});
                        } else {
                            colStartArr[i2+i] = [{mIndex: i, name: item1.name, top, bottom: top+(item1.rowSpan-1), left, right: left+(item1.colSpan-1), colSpan: item1.colSpan, rowSpan: item1.rowSpan}];
                            if (left === 1) {
                                colStartArr[i2+i].push({mIndex: i, name: 'Хөтөлбөр', top, bottom: top+(item1.rowSpan-1), left: left+1, right: left+1, colSpan: 1, rowSpan: item1.rowSpan});
                                colStartArr[i2+i].push({mIndex: i, name: 'Арга хэмжээ', top, bottom: top+(item1.rowSpan-1), left: left+2, right: left+2, colSpan: 1, rowSpan: item1.rowSpan});
                            }
                        }
                    }

                    if (i1 === 0 && i === 0) {
                        left += 3;
                    } else {
                        left += item1.colSpan;
                    }
                });
        });

        Object.keys(colStartArr).map(key => {
            colStartArr[key*1].map((item, i) => {
                if (item.mIndex === key*1) {
                    worksheet.mergeCells(item.top, item.left, item.bottom, item.right);
                    worksheet.getCell(`${this.colName(item.left-1).toUpperCase()}${key*1+1}`).value = item.name;
                }
            })
        });

        Object.keys(dataGrouped).map(key => {
            let rowRawData = dataGrouped[key*1];
            let rowData = [];
            formattedTopHeader.map(hd => {
                let searchObject = {};
                if (hd['topName'] === "№") {
                    rowData.push(key*1+1);

                    let fndAgenda = rowRawData.find(d3 => d3.topName === 'Хөтөлбөр') || {};
                    let fndEvent = rowRawData.find(d3 => d3.topName === 'Арга хэмжээ') || {};
                    let fAgenda = '';
                    let fEvent = '';
                    if (fndAgenda.value) {
                        let fAgendaObj = agendaList.find(d3 => d3.id === fndAgenda.value) || {};
                        if (fAgendaObj.name) {
                            fAgenda = `${fAgendaObj.code} - ${fAgendaObj.name}`;
                        }
                    }

                    rowData.push(fAgenda);

                    if (fndEvent.value) {
                        let fEventObj = eventList.find(d3 => d3.id === fndEvent.value) || {};
                        if (fEventObj.name) {
                            fEvent = `${fEventObj.code} - ${fEventObj.name}`;
                        }
                    }

                    rowData.push(fEvent);
                } else {
                    Object.keys(hd).map((d3, i3) => {
                        if (d3.indexOf("topName") !== -1) {
                            searchObject[d3] = hd[d3]
                        }
                    });

                    let fnd = rowRawData.find(d3 => {
                        return Object.keys(searchObject).every((key) => {
                            return d3[key] === searchObject[key]
                        })
                    }) || {};

                    rowData.push(fnd.value || '');
                }
            });
            worksheet.addRow(rowData)
        });

        try {
            const buffer = await workbook.xlsx.writeBuffer();
            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            const fileExtension = '.xlsx';

            const blob = new Blob([buffer], {type: fileType});

            saveAs(blob, fileName + fileExtension);
        } catch (e) {
            console.log('error excel download', e)
        }

    }

    render() {
        // console.log('render')
        let totalValue = 0;
        let topHeaderOptions = this.state.formattedTopHeader.filter(header => header.config || header.configInput);

        // console.log('topHeaderOptions', this.state.formattedTopHeader, topHeaderOptions);

        let t = this.state.template;
        let ecID = t.econ_category_id;
        let width = this.props.width;

        let data = this.state.data;
        let initialData = this.state.initialData;

        const objectsEqual = (o1, o2) => {
            // console.log(o1, o2)
            if (o1 === null || o1 === undefined) {
                o1 = '';
            }
            if (o2 === null || o2 === undefined) {
                o2 = '';
            }

            return typeof o1 === 'object' && Object.keys(o1).length > 0
                ? Object.keys(o1).length === Object.keys(o2).length
                && Object.keys(o1).every(p => objectsEqual(o1[p], o2[p]))
                : o1 === o2;
        }

        const arraysEqual = (a1, a2) =>
            a1.length === a2.length && a1.every((o, idx) => objectsEqual(o, a2[idx]));

        let itemSame = arraysEqual(data, initialData);
        let loadingBudgetSave = this.state.loadingBudgetSave;

        let tariffList = this.state.tariffList;
        let tariffGrouped = Object.groupBy(tariffList, ({tariff_type_id}) => tariff_type_id);
        let tariffTypeList = Object.keys(tariffGrouped).map(v => {return {id: v*1, name: tariffGrouped[v][0]['tariff_type']['name']}});
        // console.log('tariffList', tariffGrouped, tariffTypeList);
        let columns = ['tariff_type.name', 'unit_measure_obj.name', 'amount'];
        let popUpColumns = [];
        if (tariffList.length > 0 && tariffList[0].value) {
            let value = tariffList[0].value;
            Object.keys(value).map(key => {
                columns.push(`value.${key}`);
                popUpColumns.push(`value.${key}`);
            })
        }

        let valueObject = this.state.value;

        let disableConfigure = false;
        // let disableConfigure = !popUpColumns.every(e => {
        //     let d = e.split('.')[1];
        //     return !!valueObject[d]
        // });
        //
        // if (!disableConfigure) {
        //     disableConfigure = !(this.state.tariffType && this.state.unitMeasure && this.state.unitMeasureValue);
        // }

        console.log('disableConfigure', disableConfigure)

        let configureTitle = this.state.configureTitle;
        let dataGrouped = Object.keys(Object.groupBy(this.state.data, ({ leftName }) => leftName));
        // console.log('dataGrouped', dataGrouped, dataGrouped.length, this.state.data);

        return <div className={'dynamicTable-container'}>
            {this.state.header.length > 0 && <>
                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 10}}>
                    <span>  <a style={{color: '#F99D4B'}}>Санамж: </a>Хөтөлбөр, арга хэмжээ сонгоогүй үед ЭЗА нийт дүн бодогдохгүй болохыг анхаарна уу!</span>
                    <div style={{display: 'flex', gap: 10}}>
                        {/*<ReactHTMLTableToExcel*/}
                        {/*    id="test-table-xls-button"*/}
                        {/*    className="tableToExcelBtn"*/}
                        {/*    table={'table-to-xls-'+ecID}*/}
                        {/*    filename={'Төсвийн тооцоолол'}*/}
                        {/*    sheet="tablexls"*/}
                        {/*    buttonText={<i class="dx-icon-xlsfile" style={{fontSize: 18}}/>}/>*/}
                        {/*<DownloadTableExcel*/}
                        {/*    filename={t.econ_category_code + ' - ' + t.econ_category_name}*/}
                        {/*    sheet="calculation"*/}
                        {/*    currentTableRef={this.tableRef.current}*/}
                        {/*>*/}
                        {/*    <Button*/}
                        {/*        className="excel"*/}
                        {/*        icon="xlsfile"*/}
                        {/*        style={{borderColor: '#eef0f4'}}*/}
                        {/*        onClick={() => this.downloadExcel()}*/}
                        {/*    />*/}
                        {/*</DownloadTableExcel>*/}
                        <Button
                            className="excel"
                            icon="xlsfile"
                            style={{borderColor: '#eef0f4'}}
                            onClick={() => this.downloadExcel(t.econ_category_code + ' - ' + t.econ_category_name)}
                        />
                        <Button
                            className="info"
                            icon="info"
                            style={{borderColor: '#eef0f4'}}
                            onClick={() => console.log('info')}
                        />
                        <Button
                            width={130}
                            type="success"
                            disabled={itemSame || loadingBudgetSave}
                            style={{backgroundColor: '#50c687'}}
                            onClick={() => this.saveBudgetData(totalValue)}
                        >
                            <span className={'dx-button-text'}>Хадгалах</span>
                        </Button>
                    </div>
                </div>
                <TabPanel
                    style={{flex: 1}}
                    onSelectedIndexChange={(index) => this.setState({selectedTabIndex: index})}
                >
                    <Item title="Тооцоолол">
                        {/*<div style={}></div>*/}
                        {
                            this.state.selectedTabIndex === 0 && <div style={{padding: 20, overflow: 'scroll', width: width ? (width-50) : '70vw'}}>
                                <table className={'dynamicTable'} id={'table-to-xls-'+ecID} ref={this.tableRef}>
                                    <thead key={110}>
                                        <tr>
                                            <td style={{border: 'none', textAlign: 'center', background: 'none', cursor: 'auto'}}/>
                                            {Array.from({length: (this.state.header[0] || []).reduce((acc,v) => {return acc+v.colSpan}, 0)}).map((d, i) => {
                                                let i1 = i+1;
                                                if (i === 0) {
                                                    return <td key={i1} colSpan={3} style={{borderTop: 0, borderLeft: 0, borderRight: 0, textAlign: 'center', background: '#f5f5f5', cursor: 'auto'}}>{i1}</td>
                                                } else {
                                                    return <td key={i1} style={{borderTop: 0, borderLeft: 0, borderRight: 0, textAlign: 'center', background: '#f5f5f5', cursor: 'auto'}}>{i1}</td>
                                                }
                                            })}
                                        </tr>
                                    </thead>
                                    <thead key={111}>
                                    {
                                        this.state.header.map((data, index) => {
                                            return <tr key={index}>
                                                <th style={{background: 'none', border: 'none'}}/>
                                                {data.map((data1, index1) => {
                                                    if (data1.name === '№') {
                                                        return <>
                                                            <th key={1001} style={{width: 50}} rowSpan={data1.rowSpan} colSpan={data1.colSpan}>{data1.name}</th>
                                                            <th key={1002} rowSpan={data1.rowSpan} colSpan={data1.colSpan}>Хөтөлбөр</th>
                                                            <th key={1003} rowSpan={data1.rowSpan} colSpan={data1.colSpan}>Арга хэмжээ</th>
                                                        </>
                                                    } else {
                                                        let formula = data1.formula;
                                                        let formulaText;
                                                        if (formula) {
                                                            formulaText = <span>
                                                                <i id={'formulaIcon'+index1} class="dx-icon-info" style={{fontSize: 12}}/>
                                                                <Tooltip
                                                                    target={"#formulaIcon"+index1}
                                                                    showEvent="dxhoverstart"
                                                                    hideEvent="dxhoverend"
                                                                >
                                                                    <div style={{padding: '5px 10px'}}>{formula}</div>
                                                                </Tooltip>
                                                            </span>
                                                        }


                                                        return <th key={index1} style={{width: 'auto'}} rowSpan={data1.rowSpan} colSpan={data1.colSpan}>
                                                            <div style={{display: 'flex', gap: 5}}>
                                                                {data1.name}{formulaText}
                                                            </div>
                                                        </th>
                                                    }
                                                })}
                                            </tr>
                                        })
                                    }
                                    </thead>
                                    <tbody>
                                        {this.state.loading && <tr key={1111}>
                                            <td style={{border: 'none'}}/>
                                            <td colSpan={this.state.formattedTopHeader.length} style={{textAlign: 'center'}}>
                                                <LoadIndicator
                                                    id="medium-indicator"
                                                    height={20}
                                                    width={20}
                                                />
                                            </td>
                                        </tr>}
                                        {this.state.loading === false && this.state.leftHeader.length === 0 && dataGrouped.map((d1, i1) => {
                                            return <tr key={i1}>
                                                {this.state.formattedTopHeader.length > 0 && this.state.formattedTopHeader.map((d2, i2) => {
                                                    let searchObject = {leftName: i1};

                                                    Object.keys(d2).map((d3, i1) => {
                                                        if (d3.indexOf("topName") !== -1) {
                                                            searchObject[d3] = d2[d3]
                                                        }
                                                    });

                                                    let mainValue = 0;
                                                    let formula = d2.formula;

                                                    if (formula) {
                                                        let formulaCharacters = formula.match(/[+-/*%]+|[0-9a-z]+/g);
                                                        let formulaVal = "";

                                                        for (let i=0; i<formulaCharacters.length; i++) {
                                                            let character = formulaCharacters[i];
                                                            let char = character*1;
                                                            let searchObject1 = {leftName: i1};
                                                            let specialChars = /[-+*\/]/;
                                                            let stringChars = /[a-z]/;

                                                            if (char) {
                                                                let topHeader = this.state.formattedTopHeader[char-1] || {};
                                                                Object.keys(topHeader).map((d3, i3) => {
                                                                    if (d3.indexOf("topName") !== -1) {
                                                                        searchObject1[d3] = topHeader[d3]
                                                                    }
                                                                });

                                                                let fnd = this.state.data.find(d3 => {
                                                                    return Object.keys(searchObject1).every((key) => {
                                                                        return d3[key] === searchObject1[key]
                                                                    })
                                                                }) || {};

                                                                let sumValue = (fnd.value || 0);
                                                                formulaVal += `${sumValue}`;
                                                            } else if (stringChars.test(character)) {
                                                                let numberOnly = character.replace(/\D/g, "");
                                                                formulaVal += numberOnly;
                                                            } else if (specialChars.test(character)) {
                                                                formulaVal += character;
                                                            }
                                                        }

                                                        mainValue = eval(formulaVal);

                                                        let id = ecID+"cellRow"+i1+'Col'+i2;
                                                        let element = document.getElementById(id);
                                                        mainValue = mainValue.toFixed(2);
                                                        if (element) {
                                                            let innerText = (element.innerText || "");
                                                            if (innerText !== mainValue) {
                                                                element.classList.add("changed");
                                                                setTimeout(() => {
                                                                    element.classList.remove("changed");
                                                                }, 700);
                                                                // if (this._dataChanged) {
                                                                //     this._dataChanged = false;
                                                                //     console.log('topSaveData', searchObject, mainValue)
                                                                    this.topSaveData(searchObject, 'Amount', mainValue);
                                                                // }
                                                            }
                                                        }
                                                    } else {
                                                        let fnd = this.state.data.find(d => {
                                                            return Object.keys(searchObject).every((key) => {
                                                                return d[key] === searchObject[key]
                                                            })
                                                        }) || {};

                                                        if (d2.stringColumn) {
                                                            mainValue = fnd.value || '';
                                                        } else {
                                                            if (d2.numberColumn) {
                                                                mainValue = Math.floor(fnd.value || 0);
                                                            } else if (d2.config) {
                                                                mainValue = fnd.value;
                                                            } else {
                                                                mainValue = (fnd.value || 0).toFixed(2);
                                                            }
                                                        }
                                                    }

                                                    if (d2.topName === '№') {
                                                        let agendaSearchObject = {...searchObject};
                                                        let eventSearchObject = {...searchObject};
                                                        agendaSearchObject.topName = 'Хөтөлбөр';
                                                        eventSearchObject.topName = 'Арга хэмжээ';

                                                        let fndAgenda = this.state.data.find(d => {
                                                            return Object.keys(agendaSearchObject).every((key) => {
                                                                return d[key] === agendaSearchObject[key]
                                                            })
                                                        }) || {};
                                                        let fndEvent = this.state.data.find(d => {
                                                            return Object.keys(eventSearchObject).every((key) => {
                                                                return d[key] === eventSearchObject[key]
                                                            })
                                                        }) || {};

                                                        return <>
                                                            <td style={{border: 'none'}} onClick={() => this.showPopover(i1)}>
                                                                {dataGrouped.length !== 0 && i1 !== dataGrouped[dataGrouped.length-1]*1 && <div>
                                                                    <i class="dx-icon-clear" id={this.state.popover === i1 && 'removeRow'} style={{fontSize: 14}}/>
                                                                </div>}
                                                            </td>
                                                            <td
                                                                key={i2+1100}
                                                                style={{width: 50}}
                                                                className={'disabledTD'}>
                                                                {i1+1}
                                                            </td>
                                                            <td key={i2} style={{width: 220}}>
                                                                <Lookup
                                                                    dataSource={new DataSource({
                                                                        store: this.state.agendaList,
                                                                        key: 'id',
                                                                        group: 'parent_name',
                                                                    })}
                                                                    placeholder={'Хөтөлбөр'}
                                                                    grouped={true}
                                                                    width={200}
                                                                    valueExpr={'id'}
                                                                    displayExpr={this.lookupRender}
                                                                    value={fndAgenda.value || null}
                                                                    onValueChanged={(changed) => {
                                                                        this.topSaveData(agendaSearchObject, 'Number', changed.value);
                                                                    }}
                                                                >
                                                                    <DropDownOptions
                                                                        hideOnOutsideClick={true}
                                                                        showTitle={false}
                                                                        width={400}
                                                                    />
                                                                </Lookup>
                                                            </td>
                                                            <td key={1101} style={{width: 220}}>
                                                                <Lookup
                                                                    dataSource={new DataSource({
                                                                        store: this.state.eventList,
                                                                        key: 'id',
                                                                        group: 'parent_name',
                                                                    })}
                                                                    placeholder={'Арга хэмжээ'}
                                                                    grouped={true}
                                                                    width={200}
                                                                    valueExpr={'id'}
                                                                    displayExpr={this.lookupRender}
                                                                    value={fndEvent.value || null}
                                                                    onValueChanged={(changed) => {
                                                                        // console.log('lookup onValueChanged', eventSearchObject, changed);
                                                                        this.topSaveData(eventSearchObject, 'Number', changed.value);
                                                                    }}
                                                                >
                                                                    <DropDownOptions
                                                                        hideOnOutsideClick={true}
                                                                        showTitle={false}
                                                                        width={400}
                                                                    />
                                                                </Lookup>
                                                            </td>
                                                        </>
                                                    } else if (d2.config) {
                                                        // console.log('d2', d2, mainValue)
                                                        return <td
                                                            key={i2}
                                                            rowSpan={1}
                                                            colSpan={1}
                                                            id={ecID+'cellRow'+i1+'Col'+i2}
                                                            style={{width: 220}}>
                                                            <DropDownBox
                                                                value={mainValue}
                                                                opened={this.state.isGridBoxOpened[i1] || false}
                                                                valueExpr="id"
                                                                deferRendering={false}
                                                                // inputAttr={ownerLabel}
                                                                displayExpr={(item) => item && `${item.tariff_type.name} <${item.unit_measure_obj.name}>`}
                                                                placeholder="Сонгох"
                                                                showClearButton={true}
                                                                dataSource={this.state.tariffList}
                                                                onValueChanged={(e) => this.syncDataGridSelection(e, searchObject)}
                                                                onOptionChanged={(e) => this.onGridBoxOpened(e, i1)}
                                                                dropDownOptions={{
                                                                    width: 'auto',
                                                                    // minWidth: 'auto'
                                                                }}
                                                                style={{width: 200}}
                                                                contentRender={() => this.dataGridRender(searchObject, i1)}
                                                            />
                                                            {/*<SelectBox*/}
                                                            {/*    items={this.state.tariffList}*/}
                                                            {/*    valueExpr={'id'}*/}
                                                            {/*    displayExpr={'tariff_type.name'}*/}
                                                            {/*    onValueChanged={(e) => this.selectConfig(e, searchObject)}*/}
                                                            {/*    style={{width: 200}}*/}
                                                            {/*/>*/}
                                                        </td>
                                                    } else {
                                                        return <td
                                                            key={i2}
                                                            rowSpan={1}
                                                            colSpan={1}
                                                            id={ecID+'cellRow'+i1+'Col'+i2}
                                                            className={(d2.formula || d2.config || d2.configInput || d2.configDefaultColumn) ? 'disabledTD' : ''}
                                                            contentEditable={!d2.formula && !d2.config && !d2.configInput && !d2.configDefaultColumn}
                                                            // tabIndex={1}
                                                            onFocus={e => {
                                                                let el = document.getElementById(ecID+'cellRow'+i1+'Col'+i2);
                                                                requestAnimationFrame(function() {
                                                                    let range = document.createRange();
                                                                    range.selectNodeContents(el);
                                                                    let sel = window.getSelection();
                                                                    sel.removeAllRanges();
                                                                    sel.addRange(range);
                                                                });
                                                            }}
                                                            suppressContentEditableWarning={true}
                                                            onKeyDown={(e) => {
                                                                if (!d2.numberColumn && !d2.stringColumn && !/^\d+$/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Delete' && e.key !== '.') {
                                                                    e.preventDefault();
                                                                }
                                                                if (d2.numberColumn && !d2.stringColumn && !/^\d+$/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Delete') {
                                                                    e.preventDefault();
                                                                }
                                                                if (e.keyCode === 13) {
                                                                    document.activeElement.blur();
                                                                }
                                                            }}
                                                            onBlur={(e) => {
                                                                // console.log('d2', d2)
                                                                if (!d2.config && !d2.configInput && !d2.configDefaultColumn) {
                                                                    let fieldType = 'Amount';
                                                                    if (d2.stringColumn) {
                                                                        fieldType = 'String';
                                                                    } else if (d2.numberColumn) {
                                                                        fieldType = 'Number';
                                                                    }

                                                                    // console.log('onBlur', searchObject, e.target.innerText)

                                                                    this.topSaveData(searchObject, fieldType, e.target.innerText);
                                                                }
                                                            }}>
                                                            {d2.stringColumn ? mainValue : this.numberWithCommas(mainValue)}
                                                        </td>
                                                    }

                                                })}
                                                {/*<td key={1000}/>*/}
                                            </tr>
                                        })}
                                        <tr key={1000}>
                                            {this.state.formattedTopHeader.length > 0 && this.state.formattedTopHeader.map((d2, i2) => {
                                                let value = '';
                                                if (i2 === 0) {
                                                    totalValue = 0;
                                                }

                                                if (d2.formula && d2.sumFormula) {
                                                    value = 0;
                                                    let searchObject = {};
                                                    Object.keys(d2).map((d3, i1) => {
                                                        if (d3.indexOf("topName") !== -1) {
                                                            searchObject[d3] = d2[d3]
                                                        }
                                                    });

                                                    value = (this.state.data.filter(element => {
                                                        return Object.keys(searchObject).every((key) => {
                                                            return element[key] === searchObject[key]
                                                        })
                                                    }) || []).reduce((acc, obj) => {
                                                        return acc+obj.value
                                                    }, 0).toFixed(2);

                                                    if (d2.sumFormula && searchObject['topName'] !== 'Жилийн нийт дүн') {
                                                        totalValue += (value*1);
                                                    }

                                                    let id = "cellFooter"+i2;
                                                    let element = document.getElementById(id);
                                                    if (element) {
                                                        // let innerText = element.innerText*1;
                                                        let innerText = (element.innerText || "").replace(",","")*1;
                                                        if (innerText !== value) {
                                                            element.classList.add("changed");
                                                            setTimeout(() => {
                                                                element.classList.remove("changed");
                                                            }, 700);
                                                        }
                                                    }
                                                }

                                                if (this.state.formattedTopHeader.length-1 === i2 && totalValue !== this.state.totalValue) {
                                                    // console.log('set totalValue', totalValue);
                                                    // totalValue = totalValue.toFixed(2);
                                                    // this.setState({totalValue}, () => {
                                                    let id = "totalValue";
                                                    let element = document.getElementById(id);
                                                    if (element) {
                                                        element.classList.add("changed");
                                                        setTimeout(() => {
                                                            element.classList.remove("changed");
                                                        }, 700);
                                                    }
                                                    // });
                                                }

                                                // console.log('footer', d2);
                                                if (d2.topName === '№') {
                                                    return <>
                                                        <td key={999} style={{background: 'none', border: 'none'}}/>
                                                        <td key={1000} className={'disabledTD'}/>
                                                        <td key={1001} className={'disabledTD'}/>
                                                        <td key={1002} className={'disabledTD'}/>
                                                    </>
                                                } else {
                                                    return <td key={i2} id={'cellFooter'+i2} className={'disabledTD'}>{this.numberWithCommas(value)}</td>
                                                }
                                            })}
                                            {/*<td key={1003} className={'disabledTD'} id={'totalValue'}>{this.numberWithCommas(totalValue)}</td>*/}
                                        </tr>
                                    </tbody>
                                </table>

                                <div className={'tableTemplatePanel'}>
                                    <div className={'tableTemplatePanelHeader'}>
                                        <span style={{color: '#aaa'}}>Тайлбар</span>
                                    </div>
                                    <div className={'tableTemplatePanelBody'}>
                                        <div className={'tableTemplateTag'}>
                                            <i className={'dx-icon-xlsfile'}/>
                                            <span>Төсвийн тухай хууль</span>
                                            <Button icon="close" stylingMode={'text'} className={'tableTemplateTagIcon'} height={30} width={30} onClick={() => console.log('remove')} />
                                        </div>
                                        <div className={'tableTemplateTag'}>
                                            <i className={'dx-icon-xlsfile'}/>
                                            <span>Судалгаа - 2023</span>
                                            <Button icon="close" stylingMode={'text'} className={'tableTemplateTagIcon'} height={30} width={30} onClick={() => console.log('remove')} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </Item>
                    {tariffList.length > 0 && <Item title="Тохиргоо">
                        <div style={{padding: 20}}>
                            <div className={'row'} style={{display: 'flex', flex: 1, justifyContent: 'flex-end'}}>
                                <Button text={'Тогтмол утга нэмэх'} width={200} onClick={() => this.addConfig()}/>
                            </div>
                            <div className={'row'}>
                                <div className={'col-12 col-md-12 col-sm-12'} style={{paddingLeft: 10, display: 'flex', flexDirection: 'column', gap: 10}}>
                                    <span>Тогтмол утгын жагсаалт:</span>
                                    <DataGrid
                                        dataSource={tariffList}
                                        columnAutoWidth={true}
                                        showBorders={true}
                                        onOptionChanged={this.handleOptionChange}
                                    >
                                        <Column cssClass={'verticalMiddle'} dataField={'id'} caption={'№'} width={50} cellRender={(data) => {
                                            return <div>{data.rowIndex+1+(this.state.pageIndex*this.state.pageSize)}</div>
                                        }}/>
                                        {columns.map(v => {
                                            // console.log('v',v)
                                            if (v === 'amount') {
                                                return <Column cssClass={'verticalMiddle'} caption={configureTitle[v]} dataField={v} dataType={'number'}/>
                                            } else if (v === 'tariff_type.name') {
                                                return <Column cssClass={'verticalMiddle'} caption={configureTitle[v]} dataField={v} width={200}/>
                                            } else {
                                                return <Column cssClass={'verticalMiddle'} caption={configureTitle[v]} dataField={v}/>
                                            }
                                        })}
                                        {/*<Column cssClass={'verticalMiddle'} dataField={'tariff_type.name'} caption={'Тогтмол утгын төрөл'}/>*/}
                                        {/*<Column cssClass={'verticalMiddle'} dataField={'amount'} dataType={'number'} width={50} caption={'Дүн'}/>*/}
                                        {/*<Column cssClass={'verticalMiddle'} dataField={'unit_measure_obj.name'} width={100} caption={'Хэмжих нэгж'}/>*/}
                                        <Column cssClass={'verticalMiddle'} dataField={'created_user'} cellRender={this.cellUserRender} caption={'Үүсгэсэн хэрэглэгч'}/>
                                        <Column cssClass={'verticalMiddle'} dataField={'law'} width={100} cellRender={this.lawRender} caption={'Хууль эрх зүй'}/>
                                        <Column cssClass={'verticalMiddle'} dataField={'description'} caption={'Тайлбар'}/>
                                        <Column width={90} cellRender={this.tariffButtonRender} caption={'Үйлдэл'}/>
                                        <Paging pageSize={this.state.pageSize} pageIndex={this.state.pageIndex}/>
                                    </DataGrid>
                                </div>
                            </div>
                        </div>
                    </Item>}
                    <Item title={"Тооцооллын түүх"}>
                        <div style={{padding: 20}}>
                            <DataGrid
                                dataSource={this.state.budgetCalcHistory}
                                showBorders={true}
                                onOptionChanged={this.historyHandleOptionChange}
                            >
                                <Column cssClass={'verticalMiddle'} dataField={'id'} caption={'№'} width={50} cellRender={(data) => {
                                    return <div>{data.rowIndex+1+((this.state.historyPageIndex*this.state.historyPageSize))}</div>
                                }}/>
                                <Column cssClass={'verticalMiddle'} dataField={'createdAt'} dataType={'date'} format={'yyyy-MM-dd HH:mm'} caption={'Шинэчлэгдсэн огноо'}/>
                                <Column cssClass={'verticalMiddle'} dataField={'created_user'} cellRender={this.cellUserRender} caption={'Шинэчилсэн хэрэглэгч'}/>
                                {/*<Column cssClass={'verticalMiddle'} dataField={'budget_calc_status.name'} dataType={'number'} caption={'Төлөв'}/>*/}
                                <Column cssClass={'verticalMiddle'} dataField={'budget_calc_amount'} dataType={'number'} format={'#,##0.##₮'} caption={'Нийт дүн'}/>
                                <Column cssClass={'verticalMiddle'} dataField={'budget_calc_status.name'} caption={'Үйлдэл'}/>
                                <Paging pageSize={this.state.historyPageSize} pageIndex={this.state.historyPageIndex}/>
                            </DataGrid>
                        </div>
                    </Item>
                </TabPanel>

                <Popup
                    width={500}
                    height={'auto'}
                    showTitle={true}
                    title={'Тооцоололд бодогдох тогтмол утга оруулах'}
                    dragEnabled={false}
                    hideOnOutsideClick={true}
                    visible={this.state.popupVisible}
                    onHiding={this.handlePopupHidden}
                    showCloseButton={true}
                >
                    <div className="dx-fieldset" style={{margin: 0, display: 'flex', height: '100%', flexDirection: 'column'}}>
                        <div className="dx-field">
                            <div className="dx-field-label">Тогтмол утгын төрөл:</div>
                            <div className="dx-field-value">
                                <SelectBox
                                    dataSource={tariffTypeList}
                                    displayExpr={'name'}
                                    searchEnabled={true}
                                    searchMode={'contains'}
                                    searchTimeout={200}
                                    minSearchLength={2}
                                    showDataBeforeSearch={true}
                                    valueExpr={'id'}
                                    placeholder={'Тогтмол утга'}
                                    value={this.state.tariffType}
                                    onValueChanged={(e) => {
                                        let f = this.state.tariffList.filter(v => v.tariff_type_id === e.value && v.created_user_id === 3);
                                        let obj = {tariffType: e.value};
                                        if (f.length > 0) {
                                            let um = f[0].unit_measure_obj || {};
                                            // console.log('f[0]',um)
                                            obj.unitMeasure = um.id;
                                            obj.unitMeasureName = um.name
                                        }
                                        this.setState(obj)
                                    }}
                                />
                            </div>
                        </div>
                        <div className="dx-field">
                            <div className="dx-field-label">Хэмжих нэгж:</div>
                            <div className="dx-field-value">
                                <SelectBox
                                    dataSource={this.state.unitMeasureList}
                                    displayExpr={'name'}
                                    searchEnabled={true}
                                    searchMode={'contains'}
                                    searchTimeout={200}
                                    minSearchLength={2}
                                    showDataBeforeSearch={true}
                                    valueExpr={'id'}
                                    disabled={true}
                                    placeholder={'Хэмжих нэгж сонгох'}
                                    value={this.state.unitMeasure}
                                    onValueChanged={(e) => this.setState({unitMeasure: e.value})}
                                />

                                {/*<TextBox*/}
                                {/*    value={this.state.unitMeasure}*/}
                                {/*    onValueChanged={(e) => this.setState({unitMeasure: e.value})}*/}
                                {/*/>*/}
                            </div>
                        </div>
                        <div className="dx-field">
                            <div className="dx-field-label">Дүн:</div>
                            <div className="dx-field-value">
                                <NumberBox
                                    value={this.state.unitMeasureValue}
                                    onValueChanged={e => this.setState({unitMeasureValue: e.value})}
                                />
                            </div>
                        </div>
                        {popUpColumns.map(v => {
                            let d = v.split(".")[1];
                            return <div className={'dx-field'}>
                                <div className={'dx-field-label'}>{configureTitle[v]}</div>
                                <div className={'dx-field-value'}>
                                    <TextBox value={this.state.value[d]} onValueChanged={e => this.valueChanged(d, e.value)}/>
                                </div>
                            </div>
                        })}
                        <div className="dx-field">
                            <div className="dx-field-label">Хууль эрх зүй:</div>
                            <div className="dx-field-value">
                                <div style={{marginBottom: 10}}>
                                    <RadioGroup
                                        items={[
                                            {id: 0, text: 'Тушаалын линк оруулах:'},
                                            {id: 1, text: 'Файл хавсаргах:'}
                                        ]}
                                        layout={'horizontal'}
                                        // value={this.state.lawType}
                                        defaultValue={this.state.lawType}
                                        valueExpr={'id'}
                                        displayExpr={'text'}
                                        onValueChanged={(e) => this.lawTypeChanged(e)}
                                    />
                                </div>
                                <div>
                                    {this.state.lawType === 0 && <TextBox
                                        value={this.state.lawURL}
                                        onValueChanged={e => this.setState({lawURL: e.value})}
                                    />}
                                    {this.state.lawType === 1 && <FileUploader
                                        multiple={false}
                                        accept={'*'}
                                        uploadMode={'useForm'}
                                        selectButtonText={'Файл сонгох'}
                                        labelText={'эсвэл энд зөөх'}
                                        readyToUploadMessage={'Файл хуулахад бэлэн боллоо'}
                                        onValueChanged={this.fileUpload}
                                    />}
                                </div>
                            </div>
                        </div>
                        {/*{this.state.tariffType + '-' + this.state.unitMeasure + '-' +this.state.unitMeasureValue}*/}
                        <div className="dx-field" style={{display: 'flex', flex: 1, alignItems: 'flex-end'}}>
                            <div className="dx-field-label"/>
                            <div className="dx-field-value">
                                <Button
                                    text={'Хадгалах'}
                                    type="success"
                                    disabled={disableConfigure}
                                    onClick={() => {
                                        this.addTariff();
                                    }}/>
                            </div>
                        </div>
                    </div>
                </Popup>
            </>}
            <Popover
                target={'#removeRow'}
                visible={this.state.popover !== null}
                onHiding={this.hidePopover}
                // showEvent="click"
                // hideEvent={'mouseleave'}
                animation={{
                    show: {
                        type: 'pop',
                        from: {
                            scale: 0,
                        },
                        to: {
                            scale: 1,
                        },
                    },
                    hide: {
                        type: 'fade',
                        from: 1,
                        to: 0,
                    },
                }}
                position="top"
                width={200}
            >
                <div style={{padding: 10, display: 'flex', gap: 10, flexDirection: 'column'}}>
                    <div style={{textAlign: 'center'}}>
                        {this.state.popover+1} дэх мөрийг хасах уу ?
                    </div>
                    <div style={{display: 'flex', justifyContent: 'center', gap: 10}}>
                        <Button type="default" text={'тийм'} style={{height: 20, width: 85}} onClick={() => this.removeRow(this.state.popover)}/>
                        {/*<Button type="danger" text={'үгүй'} style={{height: 20, width: 60}}/>*/}
                    </div>
                </div>
            </Popover>
        </div>
    }
}

export default tableTemplate;

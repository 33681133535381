import React from 'react';
import TabPanel, {Item} from "devextreme-react/tab-panel";
import UserPosition from "./components/userPosition";
import UpdatedStructure from "../updated_structure";
import OrgStructure from "../updated_structure/orgStructure";
import OrgPosition from "../settings/sections/OrgPosition";
function Home() {
    return (
        <React.Fragment>
            <div className='col-12'>
                <div className='card'>
                    <TabPanel>

                        <Item title="Байгууллагын бүтэц" icon={"chart"}>
                            <UpdatedStructure/>
                        </Item>
                        <Item title="Байгууллагын алба хэлтэс удирдах" icon={"hierarchy"}>
                            <OrgStructure/>
                        </Item>
                        <Item title="Байгууллагын албан тушаал удирдах" icon={"product"}>
                            <OrgPosition/>
                        </Item>
                        <Item title="Албан хаагчдын жагсаалт" icon={"card"}>

                            <UserPosition />

                        </Item>
                    </TabPanel>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Home;

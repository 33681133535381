import React, { useState, useEffect, useRef } from "react";
import SelectBox from "devextreme-react/select-box";
import { getYears, numberWithCommas, numberWithCommas11 } from "../../util";
import BudgetService from "../../services/api/budget";
import notify from "devextreme/ui/notify";
import BudgetOfferOne from "./components/budget_tuswiin_sanal/BudgetOfferOne";
import BudgetOfferTwo from "./components/budget_tuswiin_sanal/BudgetOfferTwo";
import BudgetOfferThree from "./components/budget_tuswiin_sanal/BudgetOfferThree";
import BudgetOfferFour from "./components/budget_tuswiin_sanal/BudgetOfferFour";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import BudgetOfferAgendaAndEvent from "./components/budget_tuswiin_sanal/BudgetOfferAgendaAndEvent";
import BudgetVacancyDetail from "./components/budget_tuswiin_sanal/BudgetVacancyDetail";
import BudgetOfferGroupExpense from "./components/budget_tuswiin_sanal/BudgetOfferGroupExpense";
import { Popup } from "devextreme-react/popup";
import BudgetEconSixDetailed from "./budgetEconSixDetailed";
import BudgetOfferOneAndHalf from "./components/budget_tuswiin_sanal/BudgetOfferOneAndHalf";
import { useLocation } from "react-router-dom";
import BudgetOfferEconTypeOne from "./components/budget_tuswiin_sanal/BudgetOfferEconTypeOne";
import BudgetOfferEconTypeTwo from "./components/budget_tuswiin_sanal/BudgetOfferEconTypeTwo";
import BudgetDetailedList from "./components/budget_tuswiin_sanal/BudgetDetailedList";
import _ from "lodash";
import { useContext } from "react";
import { UserContext } from "../../hooks/UserContext";
import ProposalService from "../../services/api/proposal";
import TextBox from "devextreme-react/text-box";
import soyombo from '../../assets/imgs/soyombo.png'
import LoadPanelComponent from "../budget/components/loadPanel";


const BudgetTuswiinTaniltsuulga = () => {
    const [currentYear, setCurrentYear] = useState(new Date().getFullYear() + 1);
    const [organizations, setOrganizations] = useState([]);
    const location = useLocation();
    const orgId = location.state?.orgId !== undefined ? location.state?.orgId : null;
    const [chosenOrganization, setChosenOrganization] = useState(location.state?.orgId !== undefined ? location.state?.orgId : null);
    const [chosenValue, setChosenValue] = useState(location.state?.orgId !== undefined ? location.state?.orgId : null)
    const [budgetStatusName, setBudgetStatusName] = useState('батлагдсан');
    const { user } = useContext(UserContext)
    const [selectedStatus, setSelectedStatus] = useState(0);
    const [selectedStatusName, setSelectedStatusName] = useState('');
    const [statusData, setStatusData] = useState([]);
    const [mainData, setMainData] = useState([]);
    const [loader, setLoader] = useState(true);
    const [chosenOrgName, setChosenOrgName] = useState(user.organization.name)
    const [values, setValues] = useState({
        nemegdeh_oron_too: 0,
        ursgal: 0,
        hurungiin: 0,
        nemegdeh_niit_zarlaga: 0,
        niit_oron_too: 0,
        niit_tusuv: 0
    })
    const [vacancyData, setVacancyData] = useState([])
    const [firstChartVisible, setFirstChartVisible] = useState(false);
    const [whichComponentMaximized, setWhichComponentMaximized] = useState(null);

    const loadOrganizations = async () => {
        try {
            let r = await BudgetService.loadBudgetOfferOrgs(currentYear);
            setBudgetStatusName(r[0].budget_status_name);
            setOrganizations([
                {
                    parent_id: 'ALL',
                    org_id: 'ALL',
                    name: 'БҮГД',
                    parent_name: 'БҮГД',
                },
                ...r
            ]);
        } catch (e) {
            console.log(e.message);
        }
    }

    useEffect(() => {
        loadStatusList()
        loadOrganizations();
    }, []);

    useEffect(() => {
        if (orgId) {
            setChosenValue(orgId)
            let id = _.find(organizations, (b) => b.org_id === orgId)?.parent_id;
            setChosenOrganization(id);
        } else {
            setChosenValue("ALL")
            setChosenOrganization("ALL");
        }
    }, [orgId && organizations && statusData])

    useEffect(() => {
        loadStatusList();
    }, [chosenOrganization, currentYear])

    const handleOrganisationChooser = (e) => {
        setChosenValue(e.value)
        let orgId = _.find(organizations, (b) => b.org_id === e.value);
        setChosenOrganization(orgId?.parent_id);
        setChosenOrgName(orgId?.name === 'БҮГД' ? user.organization.name : orgId?.name)
    }

    const orgIdBySoa = user.organization.obt_id === 1 ? chosenOrganization : chosenValue;

    const loadStatusList = async () => {
        try {
            let r = await ProposalService.planOfferProjectStatusList(location.state === null ? chosenOrganization : _.find(organizations, (b) => b.org_id === orgId)?.org_id, currentYear);
            r.data.length === 0 && setLoader(false)
            setSelectedStatus(r && r.data.length > 0 ? r.data[0].id : 0);
            setSelectedStatusName(r && r.data.length > 0 ? r.data[0].status_name : '');
            setStatusData(r.data);
        } catch (e) {
            notify(e.message, "error", 2000);
        }
    }
    const selectBoxFieldRender = (data) => {
        return (
            <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                <TextBox className={data && `proposal-status-${data.id}`}
                    inputAttr={{ 'aria-label': 'name' }}
                    defaultValue={data && data.name + `\n (${data.cnt})`}
                    readOnly={true} />
            </div>
        );
    }
    const selectBoxItemRender = (data) => {
        return (
            <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                <div className={data && `proposal-status-${data.id}`} style={{ marginLeft: 5, marginRight: 5 }}>{data && data.name} ({data.cnt})</div>
            </div>
        );
    }

    useEffect(() => {
        if (selectedStatus !== 0 && orgIdBySoa !== undefined) {
            chartData();
            loadVacancyData();
        }
    }, [currentYear, chosenOrganization, selectedStatus])

    const fieldHandler = () => {
        if (selectedStatus === 1) {
            return 'amount'
        } else if ([3, 5, 7].includes(selectedStatus) || user.organization.obt_id === 2 || (user.organization.obt_id === 1 && orgIdBySoa !== "ALL")) {
            return 'ttz_amount'
        } else {
            return 'tez_amount'
        }
    }

    const chartData = async () => {
        // let org_id = orgIdBySoa === "ALL" ? "null" : orgIdBySoa;
        const { data } = await ProposalService.getBudgetDetailedList(currentYear, orgIdBySoa, selectedStatus);
        let filtered = _.filter(data, ['econ_category_name_2', '42-Ажиллагсдын тоо'])
        let add = _.sumBy(filtered, 'add_budget_amt')
        let sanal = _.sumBy(filtered, fieldHandler())
        let ursgal1 = _.sumBy(_.filter(data, ['econ_category_name_2', '21-Урсгал зардал']), 'add_budget_amt')
        let hurungu = _.sumBy(_.filter(data, ['econ_category_name_2', '22-Хөрөнгийн зардал']), 'add_budget_amt')
        const niit_zarlaga = _.sumBy(_.filter(data, ['econ_category_name_1', '2-Нийт зарлага ба цэвэр зээлийн дүн']), fieldHandler())
        setValues({
            ursgal: ursgal1,
            hurungiin: hurungu,
            nemegdeh_niit_zarlaga: ursgal1 + hurungu,
            nemegdeh_oron_too: add,
            niit_oron_too: sanal,
            niit_tusuv: niit_zarlaga
        })
        setMainData(data);
        setLoader(false);
    }

    const loadVacancyData = async () => {
        try {
            let result = await BudgetService.getBudgetVacancyDetail(currentYear, orgIdBySoa, selectedStatus);
            setVacancyData(result);
            setLoader(false);
        } catch (e) {
            console.log('error ', e);
        }
    }

    const returnURL = () => {
        if (window.location.host === 'localhost:3000')
            return 'http://localhost:3000/home'
        else if (window.location.host === 'staging.opengov.mn')
            return 'https://staging.opengov.mn/home'
        else {
            if (user.start_of_ancestry === 144)
                return 'https://auth-mofa.opengov.mn/home'
            else if (user.start_of_ancestry === 260)
                return 'https://auth-mojha.opengov.mn/home'
        }
    }

    const popUpHandler = (trueFalser, value) => {
        setFirstChartVisible(trueFalser);
        setWhichComponentMaximized(value);
    };

    return (
        <div className="budgetTuswiinTusul">
            <LoadPanelComponent position={{ of: '#mainLayout' }} visible={loader} />
            <Popup
                visible={firstChartVisible}
                onHiding={() => setFirstChartVisible(false)}
                hideOnOutsideClick={true}
                showCloseButton={true}
                height={'auto'}
                title={
                    whichComponentMaximized && whichComponentMaximized === 1
                        ? "ОРОН ТОО"
                        : whichComponentMaximized === 2
                            ? "УРСГАЛ ЗАРДАЛ /сая₮/"
                            : whichComponentMaximized === 3
                                ? "ХӨРӨНГИЙН ЗАРДАЛ /сая₮/"
                                : "ЭДИЙН ЗАСГИЙН АНГИЛЛААР /сая ₮/"
                }
                showTitle={true}
            >
                <div className="scrollStyle" style={{ overflowY: 'scroll', height: 500 }}>
                    {whichComponentMaximized === 1 && (<BudgetVacancyDetail mainData={vacancyData} year={currentYear} chosenOrganization={orgIdBySoa} status={selectedStatus} statusName={selectedStatusName} />)}
                    {whichComponentMaximized === 2 && (<BudgetDetailedList statusName={selectedStatusName} mainData={mainData} year={currentYear} type={'ursgal_org'} chosenOrganization={orgIdBySoa} setValues={setValues} values={values} />)}
                    {whichComponentMaximized === 3 && (<BudgetDetailedList statusName={selectedStatusName} mainData={mainData} year={currentYear} type={'hurungu_org'} chosenOrganization={orgIdBySoa} setValues={setValues} values={values} />)}
                    {whichComponentMaximized === 4 && (<BudgetDetailedList statusName={selectedStatusName} mainData={mainData} year={currentYear} type={'eza'} setValues={setValues} values={values} />)}
                </div>
            </Popup>
            <div style={{ paddingBottom: 10, display: 'flex', gap: '1rem', flexWrap: 'wrap', margin: 5 }}>
                <div>
                    <SelectBox
                        label="Төсвийн жил"
                        items={getYears(true)}
                        width="10rem"
                        defaultValue={currentYear}
                        onValueChanged={(e) => setCurrentYear(e.value)}
                        placeholder="сонгох" />
                </div>
                <div>
                    <SelectBox
                        items={organizations}
                        defaultValue={orgId || "ALL"}
                        disabled={location.state === null ? false : true}
                        label="Байгууллага"
                        width='22rem'
                        valueExpr="org_id"
                        displayExpr={user.organization.obt_id === 1 ? "parent_name" : "name"}
                        onInitialized={() => setChosenValue}
                        onValueChanged={handleOrganisationChooser}
                        placeholder={user.organization.obt_id !== 3 ? 'БҮГД' : user.organization.name}
                    />
                </div>
                <div>
                    <SelectBox
                        items={statusData}
                        label={'Төлөв'}
                        defaultValue={selectedStatus}
                        width={'16rem'}
                        value={selectedStatus}
                        valueExpr="id"
                        displayExpr="name"
                        inputAttr={{ 'aria-label': 'Templated Product' }}
                        fieldRender={selectBoxFieldRender}
                        itemRender={selectBoxItemRender}
                        noDataText={'Тухайн жил дээр төсвийн санал үүсгээгүй байна'}
                        onValueChanged={(e) => setSelectedStatus(e.value)}
                        onSelectionChanged={(e) => setSelectedStatusName(e.selectedItem.status_name)}
                        placeholder="сонгох"
                    />
                </div>
            </div>
            <div>
                <div className="card" style={{ padding: '10px 15px', marginBottom: 15 }}>
                    <h5>{chosenOrgName.toUpperCase()} - {currentYear} ОНЫ ТӨСВИЙН ТАНИЛЦУУЛГА</h5>
                    <div>
                        {user.start_of_ancestry === 624 &&
                            <div style={{ textAlign: 'justify' }}>
                                Боловсролын сайдын эрхлэх асуудлын хүрээний агентлаг, байгууллагын {currentYear} оны үр дүнд суурилсан төсвийн төлөвлөгөөг салбарт батлагдсан хууль, Монгол Улсын Хөгжлийн бодлого төлөвлөлтийн баримт бичигтэй уялдуулан боловсролын салбарын бодлогыг зөв, оновчтой тодорхойлж, 
                                бодитой үр нөлөө үзүүлэх олон талт, тэгш, хүртээмжтэй, чанартай үйлчилгээг хэрэгжүүлэх замаар оюуны өндөр боловсролтой, ёс суртахууны төлөвшилтэй, эрүүл чийрэг, өрсөлдөх чадвартай хүний нөөцийг бэлтгэх, хөгжүүлэх үндсэн
                                чиг үүргийг хэрэгжүүлэхэд шаардлагатай <b>{numberWithCommas11(values?.nemegdeh_oron_too)}</b> орон тоо, 
                                <b> {numberWithCommas(values?.ursgal)}</b> /сая ₮/ <span className="cursorBased" onClick={() => popUpHandler(true, 2)}>урсгал зардал</span>, 
                                шинээр эхлэх болон үргэлжилж байгаа хөрөнгө оруулалтын төсөл арга хэмжээ, тоног төхөөрөмж, 
                                их засварт шаардлагатай <b>{numberWithCommas(values?.hurungiin)}</b> /сая ₮/ <span className="cursorBased" onClick={() => popUpHandler(true, 3)}>хөрөнгө оруулалтын зардал</span>,
                                нийт <b>{numberWithCommas(values?.nemegdeh_niit_zarlaga)}</b> /сая ₮/ төсвийг нэмж, Төсвийн ерөнхийлөн захирагчийн {currentYear} оны
                                <span className="cursorBased" onClick={() => popUpHandler(true, 4)}>төсвийн төслийг</span> <b>{numberWithCommas11(values?.niit_oron_too)}</b> <span className="cursorBased" onClick={() => popUpHandler(true, 1)}>орон тоо</span>,
                                бүхий <b>{numberWithCommas(values?.niit_tusuv)}</b> /сая ₮/ байхаар нэгтгэн, хянан боловсруулаад байна.
                            </div>}
                        {user.start_of_ancestry === 260 &&
                            <div style={{ textAlign: 'justify' }}>
                                Хууль зүй, дотоод хэргийн сайдын эрхлэх асуудлын хүрээний агентлаг, байгууллагын {currentYear} оны үр дүнд суурилсан төсвийн төлөвлөгөөг салбарт батлагдсан хууль,
                                Монгол Улсын Хөгжлийн бодлого төлөвлөлтийн баримт бичигтэй уялдуулан, Монгол Улсын тусгаар тогтнол, үндэсний аюулгүй байдлыг хамгаалах, нийтийн хэв журам,
                                олон нийтийн аюулгүй байдлыг хангах, гэмт хэрэг зөрчлөөс урьдчилан сэргийлэх, хууль боловсруулах, сахиулах, мөрдүүлэх үндсэн <a href={returnURL()} target='_blank' rel='noreferrer' style={{ color: '#2398fc', textDecoration: 'underline' }}>чиг үүргийг</a> хэрэгжүүлэхэд шаардлагатай <b>{numberWithCommas11(values?.nemegdeh_oron_too)}</b> орон тоо,
                                <b> {numberWithCommas(values?.ursgal)}</b> /сая ₮/ <span className="cursorBased" onClick={() => popUpHandler(true, 2)}>урсгал зардал</span>,
                                шинээр эхлэх болон үргэлжилж байгаа хөрөнгө оруулалтын төсөл арга хэмжээ, тоног төхөөрөмж,
                                их засварт шаардлагатай <b>{numberWithCommas(values?.hurungiin)}</b> /сая ₮/ <span className="cursorBased" onClick={() => popUpHandler(true, 3)}>хөрөнгө оруулалтын зардал</span>,
                                нийт <b>{numberWithCommas(values?.nemegdeh_niit_zarlaga)}</b> /сая ₮/ төсвийг нэмж,
                                Төсвийн ерөнхийлөн захирагчийн {currentYear} оны <span className="cursorBased" onClick={() => popUpHandler(true, 4)}>төсвийн төслийг</span> <b>{numberWithCommas11(values?.niit_oron_too)}</b> <span className="cursorBased" onClick={() => popUpHandler(true, 1)}>орон тоо</span>,
                                бүхий <b>{numberWithCommas(values?.niit_tusuv)}</b> /сая ₮/ байхаар нэгтгэн, хянан боловсруулаад байна.
                            </div>
                        }
                        {user.start_of_ancestry === 144 &&
                            <div style={{ textAlign: 'justify' }}>
                                Хүнс, хөдөө аж ахуй, хөнгөн үйлдвэрийн сайдын эрхлэх асуудлын хүрээний агентлаг, байгууллагын {currentYear} оны үр дүнд суурилсан төсвийн төлөвлөгөөг салбарт батлагдсан хууль,
                                Монгол Улсын Хөгжлийн бодлого төлөвлөлтийн баримт бичигтэй уялдуулан хүн амын хүнсний хангамж, аюулгүй байдлыг хангах, мал аж ахуй, газар тариалан хөгжүүлэх,
                                таримал болон бэлчээрийн ургамлыг хамгаалах үндсэн <a href={returnURL()} target='_blank' rel='noreferrer' style={{ color: '#2398fc', textDecoration: 'underline' }}> чиг үүргийг</a> хэрэгжүүлэхэд шаардлагатай <b>{numberWithCommas11(values?.nemegdeh_oron_too)}</b> орон тоо,
                                <b> {numberWithCommas(values?.ursgal)}</b> /сая ₮/ <span className="cursorBased" onClick={() => popUpHandler(true, 2)}>урсгал зардал</span>,
                                шинээр эхлэх болон үргэлжилж байгаа хөрөнгө оруулалтын төсөл арга хэмжээ, тоног төхөөрөмж,
                                их засварт шаардлагатай <b>{numberWithCommas(values?.hurungiin)}</b> /сая ₮/ <span className="cursorBased" onClick={() => popUpHandler(true, 3)}>хөрөнгө оруулалтын зардал</span>,
                                нийт <b>{numberWithCommas(values?.nemegdeh_niit_zarlaga)}</b> /сая ₮/ төсвийг нэмж,
                                Төсвийн ерөнхийлөн захирагчийн {currentYear} оны <span className="cursorBased" onClick={() => popUpHandler(true, 4)}>төсвийн төслийг</span> <b>{numberWithCommas11(values?.niit_oron_too)}</b> <span className="cursorBased" onClick={() => popUpHandler(true, 1)}>орон тоо</span>,
                                бүхий <b>{numberWithCommas(values?.niit_tusuv)}</b> /сая ₮/ байхаар нэгтгэн, хянан боловсруулаад байна.
                            </div>
                        }
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', gap: 10, width: 800, margin: 'auto', textAlign: 'center' }}>
                    <div className="triangle-up">
                        <a style={{ color: '#fff', width: 400, position: 'absolute', left: '-200px', bottom: '-140px', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 3 }} href="https://legalinfo.mn/mn/detail/15406" rel="noreferrer" target="_blank">
                            <img src={soyombo} width='30' alt="" />
                            <strong>АЛСЫН ХАРАА 2050</strong>
                            <span>МОНГОЛ УЛСЫН УРТ ХУГАЦААНЫ ХӨГЖЛИЙН БОДЛОГО​</span>
                            <span>(30 жил)</span></a>
                    </div>
                    <div style={{ background: '#103087', padding: 10 }}><a style={{ color: '#fff' }} href="https://legalinfo.mn/mn/detail?lawId=16390082532431&type=3" rel="noreferrer" target="_blank">ШИНЭ СЭРГЭЛТИЙН БОДЛОГО​</a></div>
                    <div style={{ background: '#103087', padding: 10 }}><a style={{ color: '#fff' }} href="https://legalinfo.mn/mn/detail/15584" rel="noreferrer" target="_blank">МОНГОЛ УЛСЫГ 2021-2025 ОНД ХӨГЖҮҮЛЭХ ТАВАН ЖИЛИЙН ҮНДСЭН ЧИГЛЭЛ​</a></div>
                    <div style={{ background: '#103087', padding: 10 }}><a style={{ color: '#fff' }} href="https://legalinfo.mn/mn/detail?lawId=15586" rel="noreferrer" target="_blank">МОНГОЛ УЛСЫН ЗАСГИЙН ГАЗРЫН 2020-2024 ОНЫ ҮЙЛ АЖИЛЛАГААНЫ ХӨТӨЛБӨР ​</a></div>
                    {user.start_of_ancestry === 260 && <div style={{ background: '#103087', padding: 10 }}><a style={{ color: '#fff' }} href="https://legalinfo.mn/mn/detail?lawId=16759684448041&showType=1" rel="noreferrer" target="_blank">МОНГОЛ УЛСЫН ХӨГЖЛИЙН 2025 ОНЫ ТӨЛӨВЛӨГӨӨ ​</a></div>}
                    {user.start_of_ancestry === 144 && <div style={{ background: '#103087', padding: 10 }}><a style={{ color: '#fff' }} href="https://legalinfo.mn/mn/detail?lawId=16530824651591" rel="noreferrer" target="_blank">ХҮНСНИЙ ХАНГАМЖ, АЮУЛГҮЙ БАЙДЛЫГ ХАНГАХ ТАЛААР АВАХ АРГА ХЭМЖЭЭНИЙ ТУХАЙ ​УЛСЫН ИХ ХУРЛЫН  36-р ТОГТООЛЫГ ХЭРЭГЖҮҮЛЭХ АРГА ХЭМЖЭЭ-НИЙ ТӨЛӨВЛӨГӨӨ​ ​</a></div>}
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', gap: 5, paddingTop: 10 }}>
                    <div className="card">
                        <div className="card-header" style={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between' }}>
                            <div style={{ fontWeight: "bold", paddingTop: 10 }}>1. ЭДИЙН ЗАСГИЙН АНГИЛЛААР /сая₮/</div>
                            <div className={`proposal-status-${selectedStatus}`}>{selectedStatusName}</div>
                        </div>
                        <div className="card-body">
                            <BudgetDetailedList statusName={selectedStatusName} mainData={mainData} year={currentYear} type={'eza'} setValues={setValues} values={values} />
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-header" style={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between' }}>
                            <div style={{ fontWeight: "bold", paddingTop: 10 }}>2. НИЙТ ОРЛОГО /сая₮/</div>
                            <div className={`proposal-status-${selectedStatus}`}>{selectedStatusName}</div>
                        </div>
                        <div className="card-body">
                            <BudgetDetailedList statusName={selectedStatusName} mainData={mainData} year={currentYear} type={'orlogo'} chosenOrganization={orgIdBySoa} />
                        </div>
                    </div>
                    <div style={{ display: 'flex', gap: 5 }}>
                        <div className="card" style={{ flex: 1 }}>
                            <div className="card-header" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                                <span style={{ fontWeight: 700 }}>3. {currentYear - 1} ОНЫ {budgetStatusName.toUpperCase()} ТӨСӨВ /сая₮/</span>
                            </div>
                            <div className="card-body" style={{ padding: '0 10px 10px 10px' }}>
                                {
                                    orgIdBySoa !== undefined && <BudgetOfferEconTypeOne year={currentYear - 1} chosenOrganization={orgIdBySoa} />
                                }
                            </div>
                        </div>
                        <div className="card" style={{ flex: 1 }}>
                            <div className="card-header" style={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between' }}>
                                <div style={{ fontWeight: "bold", paddingTop: 10 }}>{currentYear} ОНЫ ТӨСВИЙН ТАНИЛЦУУЛГА /сая₮/</div>
                                <div className={`proposal-status-${selectedStatus}`}>{selectedStatusName}</div>
                            </div>
                            <div className="card-body" style={{ padding: '0 10px 10px 10px' }}>
                                {
                                    selectedStatus !== 0 && orgIdBySoa !== undefined && <BudgetOfferEconTypeTwo year={currentYear} chosenOrganization={orgIdBySoa} status={selectedStatus} />
                                }
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-header" style={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between' }}>
                            <div style={{ fontWeight: "bold", paddingTop: 10 }}>4. УРСГАЛ ЗАРДАЛ /сая₮/</div>
                            <div className={`proposal-status-${selectedStatus}`}>{selectedStatusName}</div>
                        </div>
                        <div className="card-body">
                            {<BudgetDetailedList statusName={selectedStatusName} mainData={mainData} year={currentYear} type={'ursgal_org'} chosenOrganization={orgIdBySoa} setValues={setValues} values={values} />}
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-header" style={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between' }}>
                            <div style={{ fontWeight: "bold", paddingTop: 10 }}>5. ХӨРӨНГИЙН ЗАРДАЛ /сая₮/</div>
                            <div className={`proposal-status-${selectedStatus}`}>{selectedStatusName}</div>
                        </div>
                        <div className="card-body">
                            {<BudgetDetailedList statusName={selectedStatusName} mainData={mainData} year={currentYear} type={'hurungu_org'} chosenOrganization={orgIdBySoa} setValues={setValues} values={values} />}
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-header" style={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between' }}>
                            <div style={{ fontWeight: "bold", paddingTop: 10 }}>6. ОРОН ТОО</div>
                            <div className={`proposal-status-${selectedStatus}`}>{selectedStatusName}</div>
                        </div>
                        <div className="card-body">
                            {<BudgetVacancyDetail mainData={vacancyData} year={currentYear} chosenOrganization={orgIdBySoa} status={selectedStatus} statusName={selectedStatusName} />}
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-header" style={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between' }}>
                            <div style={{ fontWeight: "bold", paddingTop: 10 }}>7. ЭРГЭЖ ТӨЛӨГДӨХ ЦЭВЭР ЗЭЭЛ /сая₮/</div>
                            <div className={`proposal-status-${selectedStatus}`}>{selectedStatusName}</div>
                        </div>
                        <div className="card-body">
                            {<BudgetDetailedList statusName={selectedStatusName} mainData={mainData} year={currentYear} type={'ergen_tulugduh'} chosenOrganization={orgIdBySoa} />}
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-header" style={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between' }}>
                            <div style={{ fontWeight: "bold", paddingTop: 10 }}>8. ХӨТӨЛБӨР, АРГА ХЭМЖЭЭ /сая₮/</div>
                            <div className={`proposal-status-${selectedStatus}`}>{selectedStatusName}</div>
                        </div>
                        <div className="card-body">
                            {<BudgetDetailedList statusName={selectedStatusName} mainData={mainData} year={currentYear} type={'agendaEvent'} chosenOrganization={orgIdBySoa} />}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BudgetTuswiinTaniltsuulga;
import React, {useState, useEffect,useRef} from "react";
import {TreeList,Column, FilterRow, HeaderFilter, Paging, Toolbar, Item} from "devextreme-react/tree-list";
import notify from "devextreme/ui/notify";
import FinanceReportService from "../../../../services/api/financeReport";
import LoadPanelComponent from "../../../../components/LoadPanelComponent";
import { Button } from 'devextreme-react/button';
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import {Selection} from "devextreme-react/data-grid";
import {Popup} from "devextreme-react/popup";
import ChartDetailByYear from "./ChartDetailByYear";
import _ from "lodash";
import {formatDate, paletteFinanceReport} from "../../../../util";
import EmptyData from "../../../budget/components/empty";
const FinanceReportTreeList = (props) => {
    const [dataSrc, setDataSrc] = useState([]);
    const [loader, setLoader] = useState(false);
    const [expanded, setExpanded] = useState(false);
    const [expandedRowKeys, setExpandedRowKeys] = useState([]);
    const myGridRef = useRef(null);
    const [popupDetail, setPopupDetail] = useState(false);
    const [selectionData, setSelectionData] = useState(null);
    const [reportType,setReportType] = useState(props.reportType);
    const [filterOrgText,setFilterOrgText] = useState(props.filterOrgText);
    const [selectedOrg,setSelectedOrg] = useState(props.selectedOrg);
    const loadData = async () => {
        try {
            setLoader(true);
            let r = await FinanceReportService.getChartAccountList(props.year, props.reportType,props.selectedOrg, props.filterOrgText);
            let array =[];
            if(r.length > 0){
                for (var i = r.length - 1; i > -1; i--) {
                    var sum = 0;
                    var sum1 = 0
                    var parent = r[i].id;
                    for (var k = 0; k < r.length; k++) {
                        if (r[k].parent === parent) {
                            sum = Number(sum) + Number(r[k].amt);
                            sum1 = Number(sum1) + Number(r[k].prev_amt);
                        }
                    }
                    r[i].amt = Number(r[i].amt) + Number(sum);
                    r[i].prev_amt = Number(r[i].prev_amt) + Number(sum1);
                    if(r[i].amt+r[i].prev_amt === 0) {array.push(r[i].id)};
                }
            }
            let d = _.filter(r, (item) => {
                return _.indexOf(array, item.id) === -1
            })
            setDataSrc(d);
            setLoader(false);
        } catch (e) {
            setLoader(false);
            notify(e.message, "error", 2000);
        }
    }

    useEffect(() => {
        loadData();
    }, [props.year, props.reportType, props.filterOrgText, props.selectedOrg])
    const onOptionChanged = (e) => {
        if(e.name === 'expandedRowKeys') {
            setExpandedRowKeys(e.value);
        }
    };
    const onCellPreparedHandler = (e) => {
        if (e.rowType === 'header') {
            e.cellElement.wordWrapEnabled = true;
            e.cellElement.style.color = "#0c0c0c"
            e.cellElement.style.backgroundColor = "#E8E8E8"
            e.cellElement.style.fontWeight = "bold";
        }
        if (e.rowType === 'data') {
            e.cellElement.style.color = "#2c2c2c"
        }
    }

    function getHeader(columns) {
        let header = Array();
        for (let i = 0; i < columns.length; i++) {
            header[i] = columns[i].caption;
        }
        return header;
    }
    function getRow(node, columns) {
        let row = Array();
        for (let i = 0; i < columns.length; i++) {
            let cell = node.data[columns[i].dataField];
            if (i < 2)
                cell = '   '.repeat(node.level) + cell;

            row[i] = cell;
        }
        return row;
    }
    function getData(node, columns) {
        let rows = Array();
        if (node.level >= 0) {
            rows.push(getRow(node, columns));
        }
        for (var i = 0; i < node.children.length; i++) {
            getData(node.children[i], columns).map(row => rows.push(row));
        }
        return rows;
    }
    const ExportToFile =(name)=> {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet(name);

        const columns = myGridRef.current.instance.getVisibleColumns().filter(c => c.dataField);

        const header = getHeader(columns);
        worksheet.addRow(header);

        const data = getData(myGridRef.current.instance.getRootNode(), columns);
        data.map(row => worksheet.addRow(row));

        workbook.xlsx.writeBuffer().then((buffer) => {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }),  "OG-"+ name +"-"+ formatDate(new Date()) + ".xlsx");
        });
    }
    const onSelectionChanged = async ({selectedRowsData}) => {
        if (selectedRowsData[0].id !== undefined) {
            setLoader(true);
            setReportType(props.reportType);
            setFilterOrgText(props.filterOrgText);
            setSelectedOrg(props.selectedOrg);
            setSelectionData(selectedRowsData[0]);
            setPopupDetail(true);
            setLoader(false)
        } else
            return setLoader(false);

    }
    const renderDetail = props => {
        return (
            <Popup
                visible={popupDetail}
                onHiding={() => setPopupDetail(false)}
                hideOnOutsideClick={true}
                showCloseButton={true}
                height="80vh"
                showTitle={true}
                title={(filterOrgText==='ALL' ? 'Бүгд ' : filterOrgText + ' ') + '(' + selectionData.acct + '-' + selectionData.name + ' /жилээр, сая ₮/)'}
            >
                <div className="card-body" style={{paddingBottom: "1.6rem"}}>
                    <ChartDetailByYear reportType={reportType} filterOrgText={filterOrgText} selectedOrg = {selectedOrg} acct = {selectionData.acct} acctName = {selectionData.name}/>
                </div>
            </Popup>
        )
    }
    return (
        <div id="load1">
            <LoadPanelComponent
                position={{of: '#load1'}}
                visible={loader}
            />
            {(dataSrc && !loader) ? (
            <TreeList
                showBorders={true}
                rowAlternationEnabled={true}
                dataSource={dataSrc}
                noDataText = 'Дата байхгүй байна.'
                style={{ fontFamily: "'Montserrat', sans-serif", fontWeight: 'bold' }}
                keyExpr="id"
                parentIdExpr="parent"
                showRowLines={true}
                showColumnLines={true}
                showColumnHeaders={true}
                allowColumnResizing={true}
                autoExpandAll={expanded}
                expandedRowKeys={expandedRowKeys}
                onCellPrepared={onCellPreparedHandler}
                ref={myGridRef}
                onOptionChanged={onOptionChanged}
                onSelectionChanged={onSelectionChanged}
            >
                <Paging defaultPageSize={9}/>
                <Selection mode="single"/>
                <FilterRow visible={true}/>
                <HeaderFilter visible={true}/>
                <Column
                    cssClass="custom"
                    caption="Код"
                    dataField="acct"
                    allowCollapsing={false}
                    allowFiltering={true}
                    width={200}
                    //  cellRender = {dateRender}
                >
                </Column>
                <Column
                    cssClass="custom"
                    caption="Үзүүлэлт"
                    dataField="name"
                    allowCollapsing={false}
                    allowFiltering={true}
                >
                </Column>
                <Column
                    cssClass="custom"
                    dataField="prev_amt"
                    width={'200'}
                    dataType={'number'}
                    caption={props.reportType === 2 || props.reportType === 3 ? 'Өмнөх он' : 'Эхний үлдэгдэл'}
                    format="#,##0.00"
                    allowFiltering={true}
                >
                </Column>
                <Column
                    cssClass="custom"
                    dataField="amt"
                    width={'200'}
                    dataType={'number'}
                    caption={props.reportType === 2 || props.reportType === 3 ? 'Тайлант он' : 'Эцсийн үлдэгдэл'}
                    format="#,##0.00"
                    allowFiltering={true}
                >
                </Column>
                <Toolbar>
                    <Item location="after">
                        <Button
                            text={expanded ? 'Хураах' : 'Задлах'}
                            icon={expanded ? 'collapse' : 'expand'}
                            width={140}
                            style={{
                                color: '#fff', textAlign: "center",family: "'Montserrat', sans-serif",marginRight:10,
                                backgroundImage: `linear-gradient(to right, ${paletteFinanceReport[1]}, ${paletteFinanceReport[0]})`,
                            }}
                            onClick={() => {
                                setExpanded(prevExpanded => !prevExpanded)
                                setExpandedRowKeys([]);
                            }}
                        />
                        <Button
                            text={'Excel татах'}
                            icon={'download'}
                            width={140}
                            style={{
                                color: '#fff', textAlign: "center",family: "'Montserrat', sans-serif",
                                backgroundImage: `linear-gradient(to right, ${paletteFinanceReport[1]}, ${paletteFinanceReport[0]})`,
                            }}
                            onClick={(e) =>{ExportToFile(props.filterOrgText)}}
                        />
                    </Item>
                </Toolbar>
            </TreeList>
                ):
                <div style={{display: "flex", justifyContent: "center", alignItems: "center", family: "'Montserrat', sans-serif" }}>
                    <EmptyData/>
                </div>
            }
            {popupDetail && renderDetail()}
        </div>
    )
}

export default FinanceReportTreeList;

import React from "react"
import {LoadPanel} from "devextreme-react/load-panel"
// import loader from '../assets/imgs/opengov_logo.png'
import loader from "../assets/imgs/tube-spinner.svg"

function LoadPanelComponent(props) {
    if (!props.visible) return null
    
    return (
    <LoadPanel
      position={props.position}
      visible={props.visible}
    //   visible={true}
      message=""
      wrapperAttr={{class: "borderRound"}}
      shadingColor="#f5f6fa4f"
      indicatorSrc={loader}
      showPane={false}
    />
  )
}

export default LoadPanelComponent

import request from '../../shared/lib/request'

function getDynamicTableList() {
    return request({
        url:    `/dynamicTableServices/list/get`,
        method: `GET`
    })
}

function setDynamicTable(header) {
    return request({
        url:    `/dynamicTableServices/set`,
        method: `POST`,
        data:   header
    })
}

function getDynamicTable(dynamicTableId) {
    return request({
        url:    `/dynamicTableServices/get/${dynamicTableId}`,
        method: `GET`
    })
}

function getDynamicTableByEcon(econId) {
    return request({
        url:    `/dynamicTableServices/get/byEcon/${econId}`,
        method: `GET`
    })
}

function setDynamicTableData(name, dynamicTableId, data, year, month, day) {
    return request({
        url:    `/dynamicTableServices/data/set`,
        method: `POST`,
        data: {name, dynamicTableId, data, year, month, day}
    })
}

function getDynamicTableDataList(dynamicTableId) {
    return request({
        url:    `/dynamicTableServices/${dynamicTableId}/data/list`,
        method: `GET`
    });
}

function getDynamicTableData(dynamicTableDataId) {
    return request({
        url:    `/dynamicTableServices/data/get/${dynamicTableDataId}`,
        method: `GET`
    })
}

function getRefTariffInfo(econ_category_id, org_id) {
    return request({
        url:    `/dynamicTableServices/refTariffInfo/list?econ_category_id=${econ_category_id}&org_id=${org_id}`,
        method: `GET`
    })
}

function addRefTariffInfo(tariff_id, econ_category_id, econ_category_code, tariff_type_id, unit_measure, unit_measure_name, amount, law_link, file_id, value) {
    return request({
        url:    `/dynamicTableServices/refTariffInfo/add`,
        data:   {tariff_id, econ_category_id, econ_category_code, tariff_type_id, unit_measure, unit_measure_name, amount, law_link, file_id, value},
        method: `POST`
    })
}

function removeRefTariffInfo(id) {
    return request({
        url:    `/dynamicTableServices/refTariffInfo/remove/${id}`,
        method: `DELETE`
    })
}

function getRefTariffInfoByEcon(econId) {
    return request({
        url:    `/dynamicTableServices/refTariffInfo/byEcon/${econId}`,
        method: `GET`
    });
}

function getUnitMeasure() {
    return request({
        url:    `/dynamicTableServices/refUnitMeasure/list`,
        method: `GET`
    })
}

function getCalcOrgEconValue(econ_category_id) {
    return request({
        url:    `/dynamicTableServices/calcOrgEconValue/get?econ_category_id=${econ_category_id}`,
        method: `GET`
    })
}

function addCalcOrgEconValue(calcOrgEconValue, econ_category_id) {
    return request({
        url:    '/dynamicTableServices/calcOrgEconValue/add',
        data:   {econ_category_id, calcOrgEconValue},
        method: `POST`
    })
}

function getAgendaList() {
    return request({
        url:    `/dynamicTableServices/agenda/list/get`,
        method: `GET`
    })
}

function getEventList() {
    return request({
        url:    `/dynamicTableServices/event/list/get`,
        method: `GET`
    })
}

const dynamicTableService = {
    getDynamicTableList,
    setDynamicTable,
    getDynamicTable,
    getDynamicTableByEcon,
    setDynamicTableData,
    getDynamicTableDataList,
    getDynamicTableData,
    getRefTariffInfo,
    addRefTariffInfo,
    removeRefTariffInfo,
    getRefTariffInfoByEcon,
    getUnitMeasure,
    getCalcOrgEconValue,
    addCalcOrgEconValue,
    getAgendaList,
    getEventList
}

export default dynamicTableService;


import React, {useEffect, useState} from "react"
import {getYears} from "../../util"
import {SelectBox} from "devextreme-react/select-box"
import NumberOne from "./components/NumberOne"
import NumberTwo from "./components/NumberTwo"
import NumberThree from "./components/NumberThree"
import HeaderStatistic from "./components/HeaderStatistic"
import NumberFive from "./components/NumberFive"
import NumberFour from "./components/NumberFour"
import notify from "devextreme/ui/notify"
import ExampleImportData from "../../assets/DT_NSO_2300_003V1_-_2023-05-24_-_www.1212.mn.xlsx"
import paperPlaneSvg from "../../assets/imgs/paper-plane.svg"
import {custom} from "devextreme/ui/dialog"
import statsService from "../../services/api/stat.service"
import {useParams, useLocation} from "react-router-dom"
import {Accordion, Button, DateBox, FileUploader, Popup} from "devextreme-react"
import {useHistory} from "react-router-dom"
import _ from "lodash"
import {BackButton} from "../../components/Buttons"
import StatisticFooter from "./components/StatisticFooter"
import {ProjectButton} from "../proposal"
import {FaBalanceScale, FaFile} from "react-icons/fa"
import {GrDocumentText} from "react-icons/gr"
import {urlServer} from "../../shared/lib/request"
import MapComponent from "../home/components/mapComponent"

const Statistic = () => {
  const {list_id, tbl_id, year} = useParams()
  const { state } = useLocation(); 
  const [local, setLocal] = useState()
  const [currentYear, setCurrentYear] = useState(parseInt(year))
  const [currentList, setCurrentList] = useState(list_id)
  const [chosenTbl, setChosenTbl] = useState(tbl_id)
  const [tbls, setTbls] = useState([])
  const [pivotGridVisible, setPivotGridVisible] = useState(false)
  const [popupVisible, setPopupVisible] = useState(false)
  const [isDisabled, setIsDisabled] = useState(false)
  const [monthValue, setMonthValue] = useState(null)
  const [yearValue, setYearValue] = useState(null)
  const [excelData, setExcelData] = useState([])
  const [fileType, setFileType] = useState(2)
  const [modalVisible, setModalVisible] = useState(false)
  const [loader, setLoader] = useState(false)
  const [fileStatistic, setFileStatistic] = useState(null)
  const [popUpTushaal, setPopUpTushaal] = useState(false);
  const [selectedCode, setSelectedCode] = useState(null)
  const history = useHistory()

  useEffect(() => {
    if (currentList) {
      getTbls()
    }
  }, [currentList])

  useEffect(()=>{
    if (state?.isLocal){
      setLocal(state?.isLocal)
    }
  },[state])

  useEffect(() => {
    fetchMetaFileData()
  }, [chosenTbl])

  const fetchMetaFileData = async () => {
    try {
      const result = await statsService.getFileStatistic(chosenTbl, fileType)
      if (result?.code === 200) {
        setFileStatistic(result?.data?.file)
      }
    } catch (error) {
      console.log("error", error)
    }
  }
  const getTbls = async () => {
    try {
      let {data} = await statsService.getTbls(currentList)
      setTbls(data)
    } catch (error) {
      notify(error.message)
    }
  }
  const pivotGridToggler = () => {
    setPivotGridVisible(!pivotGridVisible)
  }
  const onValueChangedDate = (e) => {
    setYearValue(e.value.getFullYear())
    setMonthValue(e.value.getMonth() + 1)
  }
  const handleTblChange = (e) => {
    setChosenTbl(e.value)
    history.push(`/statistics/${list_id}/${e.value}/${currentYear}/`)
  }
  const cellTemplate = (cellData, cellIndex, cellElement) => {
    switch (cellData.text) {
      case "Jan":
        return "1 сар"
      case "Feb":
        return "2 сар"
      case "Mar":
        return "3 сар"
      case "Apr":
        return "4 сар"
      case "May":
        return "5 сар"
      case "Jun":
        return "6 сар"
      case "Jul":
        return "7 сар"
      case "Aug":
        return "8 сар"
      case "Sep":
        return "9 сар"
      case "Oct":
        return "10 сар"
      case "Nov":
        return "11 сар"
      case "Dec":
        return "12 сар"
    }
  }
  const onClick = async () => {
    setIsDisabled(true)
    let myDialog = custom({
      title: "Баталгаажуулалт",
      messageHtml: `<i>Та <b>${yearValue}</b> оны <b>${monthValue}</b> -р сарын статистикийн маягт илгээхдээ итгэлтэй байна уу?</i>`,
      buttons: [
        {
          text: "Тийм",
          onClick: (e) => {
            return {buttonText: e.component.option("text")}
          },
        },
        {
          text: "Үгүй",
          onClick: (e) => {
            return {buttonText: e.component.option("text")}
          },
        },
      ],
    })
    myDialog.show().then(async (dialogResult) => {
      if (
        dialogResult &&
        yearValue &&
        monthValue &&
        dialogResult.buttonText === "Тийм"
      ) {
        try {
          setLoader(true)
          let check = 0
          let sendData = _.chunk(excelData, 100)
          let last
          if (!yearValue) {
            setLoader(false)
            setIsDisabled(false)
            return notify("Сараа сонгоно уу.", "warning", 2000)
          }
          // if (excelData.length === 0) {
          //     setLoader(false);
          //     setIsDisabled(false);
          //     return notify('Excel файл аа оруулаагүй байна.', 'error', 2000);
          // }

          // for (let i = 0; i < sendData.length; i++) {
          //     let r = await FinanceReportService.setExcelFileInputPayroll(sendData[i]);
          //     if (r.code !== 200) {
          //         check++;
          //         setLoader(false);
          //     }
          // }
          notify("Статистикийн маягт амжилттай илгээгдлээ", "success")
          setExcelData([])
          setIsDisabled(false)
          setMonthValue(null)
          setLoader(false)
        } catch (e) {
          setLoader(false)
          setIsDisabled(false)
          return notify(
            `${monthValue} - ${e.response.data.message} `,
            "error",   
            2000
          )
        }
      }
    })
  }
  const uploadProgress = async (e) => {
    if (!yearValue && !monthValue) {
      notify(`Excel файл оруулах сараа сонгоно уу`, "warning", 30000)
    } else {
      try {
        const excelDataList = []
        setExcelData(excelDataList)
        setIsDisabled(true)
      } catch (e) {
        console.log("error exception -> ", e)
      }
    }
  }
  const renderModalImport = (props) => {
    return (
      <Popup
        visible={popupVisible}
        onHiding={() => {
          setPopupVisible(false)
          // setExcelData([])
          setIsDisabled(false)
          setMonthValue(null)
        }}
        hideOnOutsideClick={true}
        showCloseButton={true}
        height="55vh"
        width="50%"
        showTitle={true}
        className="hzText-bold"
        title={"Статистикийн маягт илгээх"}
      >
        <div className="card performance">
          <div
            className=" performanceTabs"
            style={{paddingTop: ".25rem", paddingBottom: "1rem"}}
          >
            <div
              style={{background: "#F5F6FA", height: ".25rem", width: "100%"}}
            />
            <div className="card-body">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <a href={ExampleImportData}>
                  <Button
                    icon="download"
                    className="hzText-bold"
                    text="Excel загвар татах "
                  />
                </a>
                <br />
                <DateBox
                  applyValueMode="instantly"
                  type="date"
                  className="hzText-bold"
                  defaultValue={monthValue}
                  placeholder={
                    "Та статистикийн маягт оруулах сараа заавал сонгоно уу"
                  }
                  displayFormat="yyyy-MM"
                  applyButtonText="Сонгох"
                  cancelButtonText="Болих"
                  invalidDateMessage={"Формат буруу байна жишээ нь 2023-01"}
                  calendarOptions={{
                    maxZoomLevel: "year",
                    minZoomLevel: "century",
                    max: new Date(),
                    cellTemplate: cellTemplate,
                  }}
                  onValueChanged={onValueChangedDate}
                />
                <br />
                {currentYear && (
                  <FileUploader
                    multiple={false}
                    uploadMode="useForm"
                    icon="upload"
                    accept=".xlsx"
                    className="hzText-bold"
                    selectButtonText="Файл оруулах"
                    labelText=""
                    type="default"
                    onValueChanged={uploadProgress}
                    showFileList={true}
                    maxFileSize={15000000}
                  />
                )}
                <br />
                {excelData.length >= 0 && (
                  <Button
                    disabled={!isDisabled}
                    icon={paperPlaneSvg}
                    className="hzText-bold"
                    text="Статистикийн маягт илгээх"
                    style={{
                      borderRadius: "10px",
                      flex: 1,
                      color: "#fff",
                      textAlign: "center",
                      backgroundColor: `#4468E2`,
                    }}
                    onClick={onClick}
                    activeStateEnabled={false}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </Popup>
    )
  }
  const renderItems = (e) => {
    return (
      <object
        width="100%"
        height="90vh"
        style={{height: "70vh"}}
        data={e.file}
        type="application/pdf"
      />
    )
  }

  const accordionData = [
    {
      id: 1,
      title: "",
      file: `${urlServer}/api/file/${fileStatistic?.file_name}`,
    },
    // { id: 1, title: "Гарын авлага", file: pdfFile },
    // { id: 2, title: "Төсвийн төсөл гарын авлага", file: pdfFile2 },
    // { id: 3, title: "Төслийн нэгтгэл цэс гарын авлага", file: pdfFile3 },
    // { id: 4, title: "Төслийн хуваарь оруулах гарын авлага", file: pdfFile4 },
  ]
  const hideTushaalModal = () => {
    setPopUpTushaal(false);
  }
  const onClickHandler = (e)=>{
    // console.log(e?.code)
    setSelectedCode(e?.code)
  }
  return (
    <>
      {/*Header*/}
      {modalVisible && (
        <Popup
          visible={modalVisible}
          showTitle={false}
          height="90vh"
          hideOnOutsideClick={true}
          onHiding={() => {
            setModalVisible(false)
          }}
        >
          <Accordion
            dataSource={accordionData}
            collapsible={false}
            multiple={false}
            itemRender={renderItems}
          />
        </Popup>
      )}

      <div
        className={"card"}
        style={{
          padding: 10,
          width: "100%",
          marginBottom: 10,
          display: "flex",
          flexDirection: "row",
        }}
      >
        <div style={{display: "flex", justifyContent: "space-between"}}>
          <BackButton onClick={() => history.goBack()} />
          <div
            style={{
              display: "flex",
              fontWeight: "700",
              alignItems: "center",
              padding: "0 10px 0 10px",
            }}
          >
            Жил:
          </div>
          <SelectBox
            items={getYears(false)}
            noDataText="Дата байхгүй байна."
            width={100}
            defaultValue={currentYear}
            onValueChanged={(e) => {
              setCurrentYear(e.value)
              history.push({pathname:`/statistics/${list_id}/${tbl_id}/${e.value}`, state: {isLocal: local}})
            }}
          />
        </div>
        <div style={{display: "flex"}}>
          <div
            style={{
              display: "flex",
              fontWeight: "700",
              alignItems: "center",
              padding: "0 10px 0 10px",
            }}
          >
            Төрөл:
          </div>
          <SelectBox
            items={tbls}
            defaultValue={chosenTbl}
            width={350}
            valueExpr="tbl_id"
            displayExpr="tbl_nm"
            onValueChanged={handleTblChange}
            placeholder="сонгох"
            style={{borderRadius: 10}}
          />
        </div>
        {popUpTushaal && (
            <Popup
                width={1200}
                maxWidth={'100%'}
                minHeight={'100%'}
                height={'100%'}
                visible={popUpTushaal}
                onHiding={hideTushaalModal}
                title="Тушаал"
                dragEnabled={false}
                hideOnOutsideClick={true}
            >
              <object width="100%" height="100%"
                      data={urlServer + "/api/file/" + fileStatistic?.file_name}
                      type="application/pdf" title={'Тушаал'}/>
            </Popup>
        )}
        {fileStatistic && (

          <div
            style={{display: "flex", alignItems: "center", marginLeft: "10px"}}
          >
            <ProjectButton
                 onClick={() => setPopUpTushaal(true)}
                id="budgetLaw"
                className="projectButton"
                 hint="Тушаал харах"
            >
              <GrDocumentText style={{fontSize: "1rem", color: "black"}} />
            </ProjectButton>
           {/* <a href={`${urlServer}/api/file/${fileStatistic?.file_name}`}>
            <ProjectButton
              // onClick={() => setModalVisible(true)}
              id="budgetLaw"
              className="projectButton"
            >
              <GrDocumentText style={{fontSize: "1rem", color: "black"}} />
            </ProjectButton>
            </a>*/}
          </div>
        )}
      </div>
      <div style={{display: "flex", flexDirection: "column"}}>
        <HeaderStatistic
          list_id={currentList}
          year={currentYear}
          tbl_id={chosenTbl}
        />
        <div style={{display: "flex", flexDirection: "row", flex: 1}}>
          <NumberOne
            list_id={currentList}
            year={currentYear}
            tbl_id={chosenTbl}
            isLocal={local}
          />
          <div style={{marginBottom: 10, flex: 0.5, width: "50%"}}>
            <div className="card" style={{height: "100%"}}>
              <div className="card-header">
                <span style={{fontWeight: "700"}}>2. ЖИЛЭЭР</span>
              </div>
              <div className="card-body" style={{padding: "1.8rem 0"}}>
                <NumberTwo list_id={currentList} tbl_id={chosenTbl} />
              </div>
            </div>
          </div>
        </div>
        {/*Second Row*/}
        <div style={{display: "flex", flexDirection: "row", flex: 1}}>
          <NumberThree
            pivotGridHanlder={pivotGridToggler}
            year={currentYear}
            list_id={currentList}
            tbl_id={chosenTbl}
          />
          <NumberFour
            year={currentYear}
            list_id={currentList}
            tbl_id={chosenTbl}
          />
        </div>
        {/*Third Row*/}
        {pivotGridVisible && (
          <div style={{display: "flex", flexDirection: "row", flex: 1}}>
            <div className="card" style={{height: "100%", width: "100%"}}>
              <div className="card-header">
                <span style={{fontWeight: "700"}}>5. ДЭЛГЭРЭНГҮЙ</span>
              </div>
              <div className="card-body" style={{padding: "1.8rem 0"}}>
                <NumberFive
                  year={currentYear}
                  list_id={currentList}
                  tbl_id={chosenTbl}
                />
              </div>
            </div>
          </div>
        )}
        <StatisticFooter list_id={list_id} tbl_id={tbl_id} />
        {/* {JSON.stringify(selectedCode)}
        <MapComponent onClick={onClickHandler}/> */}
      </div>
      {popupVisible && renderModalImport()}
    </>
  )
}

export default Statistic

import React, {useState, useEffect} from "react";
import BudgetService from "../../../../services/api/budget";
import notify from "devextreme/ui/notify";
import DataGrid, {Column, Paging, Summary, TotalItem} from "devextreme-react/data-grid";

const BudgetOtherFour = (props) => {
    const [dataSource, setDataSource] = useState([]);
    const {year, chosenValue} = props;

    const loadData = async () => {
        try {
            let r = await BudgetService.getBudgetOtherFour(year, chosenValue);
            setDataSource(r);
        } catch (e) {
            notify(e.message, "error", 2000);
        }
    }

    useEffect(() => {
        loadData();
    }, [year, chosenValue])

    const cellTemp = (cellElement, cellInfo) => {
        return cellElement.append(cellInfo.row.loadIndex + 1);
    }

    const customRender = (e) => {
        return e.value.toLocaleString();
    }
    const onCellPreparedHandler = (e) => {
        if (e.rowType === 'header') {
            e.cellElement.wordWrapEnabled = true;
            e.cellElement.style.fontFamily = 'Segoe UI'
            e.cellElement.style.fontWeight = 'bold'
            e.cellElement.style.color = "#15186A"
            e.cellElement.style.backgroundColor = "#EEF0F4"
            //e.cellElement.style.backgroundImage = `linear-gradient(to right, ${paletteZarlaga[1]}, ${paletteZarlaga[0]})`
        }
        if (e.rowType === 'data') {
            e.cellElement.style.fontFamily = 'Segoe UI'
            e.cellElement.style.fontWeight = 'semibold'
            e.cellElement.style.color = "#000000"
        }
    }
    return (
        <DataGrid
            rowAlternationEnabled={true}
            dataSource={dataSource}
            noDataText = 'Дата байхгүй байна.'
            onCellPrepared={onCellPreparedHandler}
        >
            <Paging defaultPageSize={10}/>
            <Column caption="№" allowSorting={false} cellTemplate={cellTemp} alignment="center" width={40}/>
            <Column caption="Байгууллага" dataField="name"/>
            <Column caption="Тоо" dataField="amount" cellRender={customRender} defaultSortOrder="desc" width={100}/>
            <Summary>
                <TotalItem
                    column="amount"
                    summaryType="sum"
                    customizeText={(data) => {return `${data.value.toLocaleString()}`}}
                />
            </Summary>
        </DataGrid>
    )
}

export default BudgetOtherFour;

import React, { useState, useEffect, useContext, useRef } from 'react';
import _ from "lodash";
import ExcelFileInput from "./sections/excelFileInputComponent";
import BudgetService from "../../services/api/budget";
import FileServices from "../../services/api/file";
import { CURRENT_YEAR } from "../../shared/constants";
import Button from "devextreme-react/button";
import notify from "devextreme/ui/notify";
import { UserContext } from "../../hooks/UserContext";
import { getYears, numberWithCommas } from "../../util";
import DataGrid, {
    Column,
    Grouping,
    GroupPanel,
    HeaderFilter,
    Toolbar
} from "devextreme-react/data-grid";
import SelectBox from "devextreme-react/select-box";
import TabPanel, { Item } from "devextreme-react/tab-panel";
import './style.scss'
import LoadPanelComponent from "../budget/components/loadPanel";
import Popup from "devextreme-react/popup"
import { read, utils } from 'xlsx';
import { custom } from "devextreme/ui/dialog";
import ScrollView from "devextreme-react/scroll-view";
import { Scrolling } from "devextreme-react/tree-list";
import PivotGridDataSource from "devextreme/ui/pivot_grid/data_source";
import { Radio } from 'antd';
import PerformanceByMonthsIncome from './sections/PerformanceByMonthsIncome';
import PerformanceByMonthsExpense from './sections/PerformanceByMonthsExpense';
import PerformanceByEzaExpense from './sections/PerformanceByEzaExpense';
import PerformanceByEzaIncome from './sections/PerformanceByEzaIncome';
import PerformanceByAgendaExpense from './sections/PerformanceByAgendaExpense';
import PerformanceByAgendIncome from './sections/PerformanceByAgendaIncome';
import EmptyData from '../budget/components/empty';
import SourceFooter from '../updated-budget/components/Source';
import OrganizationSelectComponent from '../../components/OrganizationSelectComponent';

const monthList = [
    { id: 0, name: 'Jan', value: 1 },
    { id: 1, name: 'Feb', value: 2 },
    { id: 2, name: 'Mar', value: 3 },
    { id: 3, name: 'Apr', value: 4 },
    { id: 4, name: 'May', value: 5 },
    { id: 5, name: 'Jun', value: 6 },
    { id: 6, name: 'Jul', value: 7 },
    { id: 7, name: 'Aug', value: 8 },
    { id: 8, name: 'Sep', value: 9 },
    { id: 9, name: 'Oct', value: 10 },
    { id: 10, name: 'Nov', value: 11 },
    { id: 11, name: 'Dec', value: 12 },
]


export default function  Performance(props) {
    const { user } = useContext(UserContext);
    const _exporter = React.createRef();
    const [yearValue, setYearValue] = useState(null);
    const [monthValue, setMonthValue] = useState(null);
    const [prvMonthValue, setPrvMonthValue] = useState(null);
    const [currentDate, setCurrentDate] = useState();
    const [excelData, setExcelData] = useState([]);
    const [loader, setLoader] = useState(false);
    const [pieChartData, setPieChartData] = useState([]);
    const [pieChartDataOrgList, setPieChartDataOrgList] = useState([]);
    const [execList, setExecList] = useState([]);
    const [execOrgList, setExecOrgList] = useState([]);
    const [isDisabled, setIsDisabled] = useState(false);
    const [getMonthList, setMonthList] = useState([]);
    const [chosenOrganization, setChosenOrganization] = useState('ALL');
    const [selectMonth, setSelectMonth] = useState([]);
    const [fromMonth, setFromMonth] = useState([]);
    const [selectedYear, setSelectedYear] = useState(CURRENT_YEAR);
    const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
    const [chosenMinistry, setChosenMinistry] = useState('ALL');
    const [isPivotData, setIsPivotData] = useState(false);
    const [showPopupPie, setShowPopupPie] = useState(false);
    const [typePie, setTypePie] = useState(null);
    let fileName = useRef(false)
    const [pageIndex, setPageIndex] = useState(0);
    const [orgs, setOrgs] = useState([]);
    const [chosenValue, setChosenValue] = useState('ALL');
    const [orgList, setOrgList] = useState([]);
    const [accountNumber, setAccountNumber] = useState([]);
    const [chosenAccountNumber, setChosenAccountNumber] = useState(null);
    const [isDisabledEhuulga, setIsDisabledEhuulga] = useState(false);
    const [showPopupEhuulga, setShowPopupEhuulga] = useState(false);
    const [ehuulgaList, setEhuulgaList] = useState([]);
    const [ehuulgaSendList, setEhuulgaSendList] = useState([]);
    const [pageIndex4, setPageIndex4] = useState(0);
    const [dataSource, setDataSource] = useState([]);
    const [parentOrgs, setParenOrgs] = useState([]);
    const [subOrgs, setSubOrgs] = useState([]);
    const [subOrgId, setSubOrgId] = useState(null);
    const [select, setSelect] = useState(2)
    const windowSize = useRef([window.innerWidth, window.innerHeight]);
    const [selectedData, setSelectedData] = useState({})


    useEffect(() => {
        fetchFileName();
    }, [])

    const handleChange = (e) => {
        setSelect(e.target.value)
    }
    const fetchFileName = async () => {
        try {
            await FileServices.getFileByType(29)
                .then(res => {
                    res.data.forEach((data) => {
                        if (data.file_name === "1672818742638-MOJHA - Гүйцэтгэл.pdf")
                            fileName.current = data.file_name;
                    })
                })
            return setLoader(false)
        } catch (e) {
            console.error(e);
        }
    }
    const loadDatas = async () => {
        try {
            for (let i = 1; i < 13; i++) {
                await setSelectMonth(prevState => [...prevState, { value: i, data: i + '-р сар' }])
            }

            let list = [];
            const today = new Date();
            const thisMonth = today.getMonth() + 1;
            monthList.forEach(element => {
                if (element.value < thisMonth) {
                    list.push(element);
                }
            });
            setIsPivotData(false);
            setMonthList(list);
            setCurrentDate(new Date());
        } catch (e) {
            notify(e.message, "error", 2000)
        }
    }
    useEffect(() => {
        loadDatas()
        loadExecOrgList()
        setPrvMonthValue(new Date().getMonth() + 1)
        if (user.organization.obt_id !== 3) {

            loadExecList();
        }
        loadParentOrgs(selectedYear)
        // else{
        //     loadExecOrgList();
        // }
        // setYearValue(CURRENT_YEAR);
    }, [selectedYear]);

    useEffect(() => {
        if (user.organization.obt_id !== 3) {
            loadChartData();
        }
    }, [selectedMonth, selectedYear]);

    const customizePoint = (point) => {
        switch (point.data['id']) {
            case 2:
                return { color: '#003696' }
            case 1:
                return { color: '#2C77FF' }
        }
    }

    const loadChartData = async () => {
        try {
            let r = await BudgetService.getExecutionChart(selectedYear, selectedMonth);
            setPieChartData(r);

        } catch (e) {
            notify(e.response.data.message, 'error', 2000);
        }
    }

    const loadExecList = async () => {
        try {
            let r = await BudgetService.getExecutionList(selectedYear);
            setExecList(r);
        } catch (e) {
            notify(e.response.data.message, 'error', 2000);
        }
    }

    const loadExecOrgList = async () => {
        try {
            let r = await BudgetService.getExecutionOrgList(selectedYear);
            setExecOrgList(r);
        } catch (e) {
            notify(e.response.data.message, 'error', 2000);
        }
    }
    const loadExecuteBudgetParentOrg = async (year) => {
        try {
            let r = await BudgetService.getExecuteBudgetParentOrg(year);
            setOrgs(r);
        } catch (e) {
            notify(e.response.data.message, 'error', 2000);
        }
    }
    const loadExecuteBudgetOrgList = async (year, filterOrg) => {
        try {
            let r = await BudgetService.getExecuteBudgetOrgList(year, filterOrg);
            setOrgList(r);
        } catch (e) {
            notify(e.response.data.message, 'error', 2000);
        }
    }
    const loadOrgAttributeByAccount = async (selectedOrgId) => {
        try {
            let r = await BudgetService.getOrgAttributeByAccount(selectedOrgId);
            setAccountNumber(r);
        } catch (e) {
            notify(e.response.data.message, 'error', 2000);
        }
    }
    const onValueChangedDate = (e) => {
        setYearValue(e.value.getFullYear());
        setMonthValue(e.value.getMonth() + 1);
    }
    const onValueChangedDateEhuulga = (e) => {
        setYearValue(e.value.getFullYear());
        setMonthValue(e.value.getMonth() + 1);
        loadExecuteBudgetParentOrg(e.value.getFullYear());

    }
    const customRender = (e) => {
        return numberWithCommas(e.text);
    }
    const animation = {
        show: {
            type: "slide",
            from: {
                left: 1000,
                opacity: 0.2
            },
            to: {
                opacity: 1
            },
            duration: 500
        }
        ,
        hide: {
            type: "slide",
            to: {
                opacity: 0,
                right: -1000
            },
            duration: 500
        }
    }
    const uploadProgress = async (e) => {
        if (!yearValue && !monthValue) {
            notify(`Excel файл оруулах сараа сонгоно уу`, 'warning', 30000);
        } else {
            try {
                setExcelData([]);
                setIsDisabled(false)
                const file = e.value[0];
                const reader = new FileReader();
                const excelDataList = [];
                reader.readAsArrayBuffer(file)
                reader.onload = (event) => {
                    const wb = read(event.target.result);
                    const sheets = wb.SheetNames;
                    if (sheets.length) {
                        const rows = utils.sheet_to_json(wb.Sheets[sheets[0]]);
                        let col = _.filter(rows, function (o) {
                            return o['ЭЗА'] !== undefined ? o['ЭЗА'] !== '' : o;
                        });
                        col.forEach((row, rowNumber) => {
                            if (row[monthValue + ' -р сарын гүйцэтгэл (цэвэр)'] === undefined) {
                                return notify(
                                    {
                                        message: `Эхний толгой мөрөн дээр ${monthValue} -р сарын гүйцэтгэл (цэвэр) гэсэн толгойтой таарахгүй байна та файлаа шалгана уу`,
                                        width: 500,
                                        position: {
                                            my: 'centered',
                                            at: 'centered',
                                            of: "#floating-div-2"
                                        },
                                        animation: animation
                                    }
                                    // error
                                    , "warning", 8000);
                            }
                            if (row['Дансаар'] === undefined) {
                                return notify(
                                    {
                                        message: 'Эхний толгой мөрөн дээр Дансаар гэсэн толгойтой таарахгүй байна та файлаа шалгана уу',
                                        width: 500,
                                        position: {
                                            my: 'centered',
                                            at: 'centered',
                                            of: "#floating-div-2"
                                        },
                                        animation: animation
                                    }
                                    // error
                                    , "warning", 8000);
                            }
                            if (row['ЭЗА'] === undefined) {
                                return notify(
                                    {
                                        message: `Эхний толгой мөрөн дээр ЭЗА гэсэн толгойтой таарахгүй байна та файлаа шалгана уу`,
                                        width: 500,
                                        position: {
                                            my: 'centered',
                                            at: 'centered',
                                            of: "#floating-div-2"
                                        },
                                        animation: animation
                                    }
                                    // error
                                    , "warning", 8000);
                            }
                            if (row['Зориулалт'] === undefined) {
                                return notify(
                                    {
                                        message: 'Эхний толгой мөрөн дээр Зориулалт гэсэн толгойтой таарахгүй байна та файлаа шалгана уу',
                                        width: 500,
                                        position: {
                                            my: 'centered',
                                            at: 'centered',
                                            of: "#floating-div-2"
                                        },
                                        animation: animation
                                    }
                                    // error
                                    , "warning", 8000);
                            }
                            if (row['Хөтөлбөрөөр'] === undefined) {
                                return notify(
                                    {
                                        message: 'Эхний толгой мөрөн дээр Хөтөлбөрөөр гэсэн толгойтой таарахгүй байна та файлаа шалгана уу',
                                        width: 500,
                                        position: {
                                            my: 'centered',
                                            at: 'centered',
                                            of: "#floating-div-2"
                                        },
                                        animation: animation
                                    }
                                    // error
                                    , "warning", 8000);
                            } else {
                                try {
                                    const data = {
                                        budget_year: yearValue,
                                        budget_month: monthValue,
                                        minister_code: '',
                                        code: row['_1'] !== undefined ? row['_1'] : '',
                                        econ_category_code: row['ЭЗА'],
                                        econ_category_name: '',
                                        budget_year_amt: parseFloat((row['Жилийн төсөв'] === null ? 0 : (row['Жилийн төсөв'].result ? row['Жилийн төсөв'].result : (row['Жилийн төсөв'].result === undefined ? row['Жилийн төсөв'] : 0))).toString().replace(/,/g, '')),
                                        scheduled_growth_month_amt: 0,
                                        executed_growth_month_amt: parseFloat((row[monthValue + ' -р сарын гүйцэтгэл (цэвэр)'] === null ? 0 : (row[monthValue + ' -р сарын гүйцэтгэл (цэвэр)'].result ? row[monthValue + ' -р сарын гүйцэтгэл (цэвэр)'].result : (row[monthValue + ' -р сарын гүйцэтгэл (цэвэр)'].result === undefined ? row[monthValue + ' -р сарын гүйцэтгэл (цэвэр)'] : 0))).toString().replace(/,/g, '')),
                                        account_number: row['Дансаар'],
                                        agenda_code: row['Хөтөлбөрөөр'],
                                        event_code: row['Зориулалтгээр'],
                                        record_source: 1,
                                        created_user_id: user.id
                                    }
                                    excelDataList.push(data);
                                    if (col.length === excelDataList.length) {
                                        setExcelData(excelDataList);
                                        //console.log('excelDataList', excelDataList);
                                        setIsDisabled(true)
                                    }
                                } catch (e) {
                                    console.log(e);
                                }
                            }
                        });
                    }
                }

            } catch (e) {
                console.log('error exception -> ', e);
            }
        }
    }

    const onClick = async () => {
        setIsDisabled(true);
        let myDialog = custom({
            title: "Баталгаажуулалт",
            messageHtml: `<i>Та <b>${yearValue}</b> оны <b>${monthValue}</b> -р сарын гүйцэтгэлийн мэдээлэл илгээхдээ итгэлтэй байна уу?</i>`,
            buttons: [{
                text: "Тийм",
                onClick: (e) => {
                    return { buttonText: e.component.option("text") }
                }
            },
            {
                text: "Үгүй",
                onClick: (e) => {
                    return { buttonText: e.component.option("text") }
                }
            },
            ]
        });
        myDialog.show().then(async (dialogResult) => {
            if (dialogResult && yearValue && monthValue && dialogResult.buttonText === 'Тийм') {
                try {
                    setLoader(true);
                    let check = 0;
                    let sendData = _.chunk(excelData, 100);
                    let last;
                    if (!yearValue) {
                        setLoader(false);
                        setIsDisabled(false);
                        return notify(
                            {
                                message: 'Сараа сонгоно уу.', width: 300, position: {
                                    my: 'centered',
                                    at: 'centered',
                                    of: "#floating-div-2"
                                },
                                animation: animation
                            }
                            // error
                            , "warning", 3000);
                    }
                    if (excelData.length === 0) {
                        setLoader(false);
                        setIsDisabled(false);
                        return notify(
                            {
                                message: 'Excel файл аа оруулаагүй байна.', width: 300, position: {
                                    my: 'centered',
                                    at: 'centered',
                                    of: "#floating-div-2"
                                },
                                animation: animation
                            }
                            // error
                            , "warning", 3000);
                    }

                    for (let i = 0; i < sendData.length; i++) {
                        if (i === sendData.length - 1) {
                            last = 'last';
                        }

                        let r = await BudgetService.setExcelFileInput(sendData[i], yearValue, monthValue, last);
                        if (r.code !== 200) {
                            check++;
                        }
                    }

                    const res = await BudgetService.insertData();
                    if (res.code === 200) {
                        setLoader(false);
                        notify(
                            {
                                message: `Гүйцэтгэл амжилттай илгээгдлээ`, width: 300, position: {
                                    my: 'centered',
                                    at: 'centered',
                                    of: "#floating-div-2"
                                },
                                animation: animation
                            }
                            // error
                            , "success", 5000);
                        loadDatas()
                        loadExecOrgList()
                        setPrvMonthValue(new Date().getMonth() + 1)
                        if (user.organization.obt_id !== 3) {

                            loadExecList();
                        }

                    }
                    if (check === 0) {
                        setSelectedYear(selectedYear);
                        setLoader(false);
                        notify(
                            {
                                message: `Гүйцэтгэл амжилттай илгээлээ`, width: 300, position: {
                                    my: 'centered',
                                    at: 'centered',
                                    of: "#floating-div-2"
                                },
                                animation: animation
                            }
                            // error
                            , "success", 5000);
                    }
                    setIsDisabled(false);
                    setMonthValue(null);
                    // let last = 'last';
                    // BudgetService.setExcelFileInput({text: 'qwe'}, last);
                    setLoader(false);
                } catch (e) {
                    console.log('eeererere', e);
                    setLoader(false);
                    setIsDisabled(false);
                    return notify(
                        {
                            message: `${monthValue} - ${e.response.data.message} `, width: 300, position: {
                                my: 'centered',
                                at: 'centered',
                                of: "#floating-div-2"
                            },
                            animation: animation
                        }
                        // error
                        , "error", 3000);
                }
            }
        });


    }

    const onYearValueChanged = (e) => {
        setIsPivotData(false);
        setSelectedYear(e.value);
    }
    const onCellPreparedHandler = (e) => {
        if (e.rowType === 'header') {
            e.cellElement.wordWrapEnabled = true;
            e.cellElement.style.fontFamily = 'Segoe UI'
            e.cellElement.style.fontWeight = 'bold'
            e.cellElement.style.color = "#15186A"
            e.cellElement.style.backgroundColor = "#EEF0F4"
        }
        if (e.rowType === 'data') {
            e.cellElement.style.fontFamily = 'Segoe UI'
            e.cellElement.style.fontWeight = 'semibold'
            e.cellElement.style.color = "#000000"
        }
    }
    const onPivotData = async (parent, org_id) => {
        setLoader(true);
        const sortException = "Гүйцэтгэл";
        await BudgetService.budgetDetail(selectedYear, (parent === 'ALL' || user.organization.obt_id === 2) ? parent : chosenValue, org_id).then(data => {
            const dataSource = new PivotGridDataSource({
                fields: [
                    {
                        caption: 'Эдийн засгийн ангилал 1',
                        dataField: 'econ_category_name_1',
                        area: 'row',
                        sortBySummaryField: 'Total',
                        expanded: true,
                        sortBy: 'displayText',
                        sortOrder: 'asc',
                        width: 300,
                    },
                    {
                        caption: 'Эдийн засгийн ангилал 2',
                        dataField: 'econ_category_name_2',
                        area: 'row',
                        sortBy: 'displayText',
                        sortOrder: 'asc',
                        expanded: true,
                        width: 300,
                    },
                    {
                        caption: 'Эдийн засгийн ангилал 3',
                        dataField: 'econ_category_name_3',
                        area: 'row',
                        sortBy: 'displayText',
                        sortOrder: 'asc',
                        width: 300,
                    },
                    {
                        caption: 'Эдийн засгийн ангилал 4',
                        dataField: 'econ_category_name_4',
                        width: 300,
                        area: 'row',
                        sortBy: 'displayText',
                        sortOrder: 'asc',
                    },
                    {
                        caption: 'Эдийн засгийн ангилал 5',
                        dataField: 'econ_category_name',
                        width: 300,
                        area: 'row',
                        sortBy: 'displayText',
                        sortOrder: 'asc',
                    },
                    {
                        caption: 'Сар',
                        dataField: 'budget_month',
                        dataType: 'budget_month',
                        area: 'column',
                        sortOrder: 'asc',
                        expanded: true,
                    }, {
                        caption: 'Төлөв',
                        dataField: 'budget_status_name',
                        dataType: 'budget_status_name',
                        area: 'column',
                        expanded: true,
                        sortingMethod: function (a, b) {
                            var index1 = a.value;
                            var index2 = b.value;
                            if (index1 === sortException) {
                                return 1;
                            }
                            if (index2 === sortException) {
                                return -1;
                            }
                            if (index1 > index2)
                                return 1;
                            if (index2 > index1)
                                return -1;
                            else
                                return 0;
                        }
                    },
                    {
                        caption: 'Нийт',
                        dataField: 'debit',
                        dataType: 'number',
                        summaryType: 'sum',
                        format: {
                            type: 'fixedPoint',
                            precision: 2
                        },
                        area: 'data',
                        //runningTotal: 'row',
                        allowCrossGroupCalculation: true,
                    }],
                store: data
            });
            setDataSource(dataSource);
            return setLoader(false);
        });
        setIsPivotData(true);
    }

    const loadOrgs = async (year) => {
        try {
            let r = user.username !== "02" ? await BudgetService.getFilteredOrganizations(year) : (chosenMinistry === "ALL" ? [] : await BudgetService.getFilteredOrganizationsBySoa(year, chosenMinistry));
            setOrgs(r.length > 0 && r.length === 1 ? r : [
                {
                    budget_year: year,
                    text_name: 'БҮГД',
                    value_name: 'ALL',
                },
                ...r
            ])
            r.length > 1 ? setChosenOrganization('ALL') : setChosenOrganization(r[0].value_name)
        } catch (e) {
            notify(e.message, "error", 2000)
        }
    }
    useEffect(() => {
        setChosenValue("ALL")
        loadOrgs(selectedYear)
    }, [chosenMinistry])

    const loadChartDataByOrgList = async (type) => {
        try {
            setLoader(true);
            let r = await BudgetService.getExecutionChartByOrgList(type, selectedYear, selectedMonth);
            setPieChartDataOrgList(r);
            return setLoader(false);
        } catch (e) {
            notify(e.response.data.message, 'error', 2000);
        }
    }
    
    const loadParentOrgs = async (year) => {
        try {
            let r = await BudgetService.getFilteredOrganizations(year);
            setParenOrgs([
                {
                    budget_year: year,
                    text_name: 'БҮГД',
                    value_name: 'ALL',
                },
                ...r
            ])
        } catch (e) {
            notify(e.message, "error", 2000)
        }
    }
    const loadSubOrgs = async (year, filterOrg) => {
        try {
            let r = await BudgetService.getFilteredOrgList(year, filterOrg);
            if (r.length === 1) {
                setSubOrgs(r);
            } else {
                setSubOrgs([
                    {
                        org_id: 0,
                        org_name: 'БҮГД',
                    },
                    ...r
                ])
            }

        } catch (e) {
            notify(e.message, "error", 2000)
        }
    }
    const renderEhuulga = () => {
        return (
            <div style={{ height: "100%" }}>
                <Popup
                    minWidth={'80%'}
                    minHeight={'80%'}
                    visible={showPopupEhuulga}
                    title={`Ehuulga - ${chosenAccountNumber} дансны ${yearValue} оны ${monthValue} -р сарын гүйцэтгэлийн жагсаалт`}
                    onHiding={() => setShowPopupEhuulga(false)}
                    resizeEnabled={true}
                    dragEnabled={true}
                    showTitle={true}
                    hideOnOutsideClick={true}
                >
                    <ScrollView width='100%' height='100%'>
                        <LoadPanelComponent position={{ of: '#loadEhuulga' }} visible={loader} />
                        <DataGrid
                            dataSource={ehuulgaList}
                            keyExpr={null}
                            wordWrapEnabled={true}
                            showBorders={true}
                            showColumnLines={true}
                            showRowLines={true}
                            onCellPrepared={onCellPreparedHandler}
                            paging={{
                                pageSize: 100,
                            }}
                            style={{ color: '#fff', textAlign: "center", family: "Segoe UI" }}
                        > <Scrolling mode="standard" />
                            <GroupPanel visible={true} emptyPanelText={"Хүссэн баганаа дээш зөөн бүлэглэх боломжтой"} />
                            <Grouping autoExpandAll={true} />
                            <HeaderFilter visible={true} allowSearch={true} />
                            <Column caption="Хөтөлбөр" dataField="hotolbor" />
                            <Column caption="Зориулалт" dataField="zoriulalt" />
                            <Column caption="ЭЗА код" dataField="num" width={80} />
                            <Column caption="ЭЗА нэр" dataField="name" />
                            <Column caption={`${monthValue} -р сарын гүйцэтгэл (цэвэр)`} dataField="col16" //width={180}
                                format="#,##0.00" alignment="center" />
                            <Toolbar>
                                <Item location="after">
                                    <Button
                                        onClick={onSendEhuulga}
                                        type="success"
                                        style={{
                                            flex: 1,
                                            marginBottom: 10,
                                            backgroundColor: '#50C687',
                                            color: '#fff',
                                            textAlign: "center",
                                            family: "Segoe UI",
                                            borderRadius: 10
                                        }}
                                        icon={'check'}
                                        text={"Ehuulga гүйцэтгэл илгээх"}
                                    />
                                </Item>
                            </Toolbar>
                        </DataGrid>
                    </ScrollView>
                </Popup>
            </div>
        )
    }
    const onSendEhuulga = async () => {
        let myDialog = custom({
            title: "Баталгаажуулалт",
            messageHtml: `<i>Та <b>${yearValue}</b> оны <b>${monthValue}</b> -р сарын гүйцэтгэлийн мэдээлэл илгээхдээ итгэлтэй байна уу?</i>`,
            buttons: [{
                text: "Тийм",
                onClick: (e) => {
                    return { buttonText: e.component.option("text") }
                }
            },
            {
                text: "Үгүй",
                onClick: (e) => {
                    return { buttonText: e.component.option("text") }
                }
            },
            ]
        });
        myDialog.show().then(async (dialogResult) => {
            if (dialogResult && yearValue && monthValue && dialogResult.buttonText === 'Тийм') {
                try {
                    setLoader(true);

                    let check = 0;
                    let sendData = _.chunk(ehuulgaSendList, 100);
                    let last;
                    if (ehuulgaSendList.length === 0) {
                        setLoader(false);
                        setIsDisabled(false);
                        return notify(
                            {
                                message: 'Ehuulga мэдээлэл хоосон байна.', width: 300, position: {
                                    my: 'centered',
                                    at: 'centered',
                                    of: "#floating-div-2"
                                },
                                animation: animation
                            }
                            // error
                            , "warning", 3000);
                    }

                    for (let i = 0; i < sendData.length; i++) {
                        if (i === sendData.length - 1) {
                            last = 'last';
                        }

                        let r = await BudgetService.setExcelFileInput(sendData[i], yearValue, monthValue, last);
                        if (r.code !== 200) {
                            check++;
                        }
                    }

                    const res = await BudgetService.insertData();
                    if (res.code === 200) {
                        setLoader(false);
                        notify(
                            {
                                message: `Гүйцэтгэл амжилттай илгээгдлээ`, width: 300, position: {
                                    my: 'centered',
                                    at: 'centered',
                                    of: "#floating-div-2"
                                },
                                animation: animation
                            }
                            // error
                            , "success", 5000);
                        loadDatas()
                        loadExecOrgList()
                        setPrvMonthValue(new Date().getMonth() + 1)
                        if (user.organization.obt_id !== 3) {

                            loadExecList();
                        }

                    }
                    if (check === 0) {
                        setSelectedYear(selectedYear);
                        setLoader(false);
                        notify(
                            {
                                message: `Гүйцэтгэл амжилттай илгээлээ`, width: 300, position: {
                                    my: 'centered',
                                    at: 'centered',
                                    of: "#floating-div-2"
                                },
                                animation: animation
                            }
                            // error
                            , "success", 5000);
                    }
                    //setIsDisabled(false);
                    //setMonthValue(null);
                    setShowPopupEhuulga(false);
                    setLoader(false);
                } catch (e) {
                    console.log('eeererere', e);
                    setLoader(false);
                    setIsDisabled(false);
                    return notify(
                        {
                            message: `${monthValue} - ${e.response.data.message} `, width: 300, position: {
                                my: 'centered',
                                at: 'centered',
                                of: "#floating-div-2"
                            },
                            animation: animation
                        }
                        // error
                        , "error", 3000);
                }
            }
        });


    }
    const onClickEhuulga = async () => {
        setIsDisabledEhuulga(true);
        try {
            setLoader(true);
            const dataEhuulga = [];
            let r = await BudgetService.getMofEhuulga(monthValue, chosenAccountNumber, yearValue);
            setEhuulgaList(r);
            let r1 = await _.filter(r, { levelNo: 12 });
            if (r.error !== undefined) {
                switch (r.error) {
                    case 'Not found':
                        r.error = <EmptyData/>;
                        break;
                    case 'Access denied':
                        r.error = 'Хандах эрхгүй байна та гараар оруулах хэсгээр гүйцэтгэлээ илгээнэ үү';
                        break;
                    default:
                        return;
                }
                notify(
                    {
                        message: `${r.error}`, width: 300, position: {
                            my: 'centered',
                            at: 'centered',
                            of: "#floating-div-2"
                        },
                        animation: animation
                    }
                    // error
                    , "error", 3000);
                return setLoader(false);
            }
            for (let i = 0; i < r1.length; i++) {
                const data = {
                    budget_year: yearValue,
                    budget_month: monthValue,
                    minister_code: '',
                    code: '',
                    econ_category_code: r1[i].num,
                    econ_category_name: r1[i].name,
                    budget_year_amt: r1[i].col1,
                    scheduled_growth_month_amt: 0,
                    executed_growth_month_amt: r1[i].col16,
                    account_number: chosenAccountNumber,
                    agenda_code: r1[i].hotolbor_code,
                    event_code: r1[i].zoriulalt_code,
                    record_source: 1,
                    created_user_id: user.id
                }
                dataEhuulga.push(data);
            }
            setEhuulgaSendList(dataEhuulga);
            setShowPopupEhuulga(true);
            setLoader(false);
        } catch (e) {
            console.log('eeererere', e);
            setLoader(false);
            setIsDisabled(false);
            return notify(
                {
                    message: `${monthValue} - ${e.response.data.message} `, width: 300, position: {
                        my: 'centered',
                        at: 'centered',
                        of: "#floating-div-2"
                    },
                    animation: animation
                }
                // error
                , "error", 3000);
        }
    }

    const renderPopupPerformance = () => {
        return (
            <div style={{ height: "100%" ,width:'100%'}}>
                {
                    isPivotData &&
                    <Popup
                        visible={isPivotData}
                        onHiding={() => setIsPivotData(false)}
                        hideOnOutsideClick={true} dragEnabled={true} resizeEnabled={true} dragOutsideBoundary={true} restorePosition={true}
                        showCloseButton={true}
                        height={"100%"}
                        width={"100%"}
                        showTitle={true}
                        title={selectedYear + " оны төсвийн хуваарь болон гүйцэтгэл /сая ₮/"}
                    > <ScrollView width='100%' height='100%'>
                            {
                                user.organization.obt_id !== 3 &&
                                <div style={{ display: 'flex' }}>
                                    <div>
                                        {parentOrgs && parentOrgs.length !== 0 && <SelectBox
                                            items={parentOrgs}
                                            width={350}
                                            value={chosenValue}
                                            valueExpr="value_name"
                                            displayExpr="text_name"
                                            label={user.organization.obt_id === 1 ? 'ТТЗ' : 'ТШЗ'}
                                            onValueChanged={(e) => {
                                                setChosenValue(e.value);
                                                loadSubOrgs(selectedYear, e.value);
                                                (e.value === 'ALL' || user.organization.obt_id === 2) && onPivotData(e.value, 0)
                                            }}
                                            placeholder="сонгох" style={{ borderRadius: 10 }} />}
                                    </div>
                                    <div style={{ marginLeft: 10 }}>
                                        {subOrgs && subOrgs.length !== 0 && chosenValue !== 'ALL' && user.organization.obt_id === 1 && <SelectBox
                                            items={subOrgs}
                                            width={350}
                                            value={subOrgId}
                                            valueExpr="org_id"
                                            displayExpr="org_name"
                                            label={'Байгууллага'}
                                            onValueChanged={(e) => { setSubOrgId(e.value); onPivotData(chosenValue, e.value) }}
                                            placeholder="сонгох" style={{ borderRadius: 10 }} />}
                                    </div>
                                </div>
                            }

                            <ExcelFileInput selectedYear={selectedYear} dataSource={dataSource} />
                        </ScrollView>
                    </Popup>
                }
            </div>
        )
    }
    return (

        <div style={{ height: "100%" }}>
            <LoadPanelComponent position={{ of: '#load2' }} visible={loader} />
            <div style={{ padding: 10 }}>
                <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem' ,alignItems:'center'}}>
                    <div>
                        <SelectBox
                            label="Төсвийн жил"
                            items={getYears(true)}
                            defaultValue={selectedYear}
                            onValueChanged={onYearValueChanged}
                            width="10rem"
                            placeholder="сонгох" style={{ borderRadius: 10 }} />
                    </div>
                    <div>
                        {/* <SelectBox
                            items={orgs}
                            label="Байгууллага"
                            value={chosenOrganization}
                            disabled={orgs && orgs.length === 1 ? true : false}
                            width={windowSize.current[0] < 635 ? 'auto' : '22rem'}
                            valueExpr="value_name"
                            displayExpr="text_name"
                            placeholder={user.organization.obt_id !== 3 ? 'БҮГД' : user.organization.name}
                            onValueChanged={(e) => setChosenOrganization(e.value)}
                            style={{ borderRadius: 10 }} /> */}
                        <OrganizationSelectComponent
                            selectedData={(d) => {
                                setSelectedData(d)
                            }}
                            selectedYear={(d) => d}
                            showClearButton={false}
                            showYear={false}
                            hideLabel={true}
                            labelMode = {'floating'}
                            width = {600}
                            type = {select === 2 ? 2 : 3}
                            year = {selectedYear}
                            defaultId = {(user.organization.obt_id === 2 && user.organization.parent_id !== user.organization.parent_org_id) ? user.organization.parent_id : user.organization.id}
                        />
                    </div>
                    <div style={{marginTop:'0.5rem'}}>
                        <Button icon={'doc'}
                                text={isPivotData ? selectedYear + " оны төсвийн хуваарь,гүйцэтгэл /сая ₮/" : selectedYear + " оны төсвийн хуваарь,гүйцэтгэл харах"}
                                onClick={(e) => onPivotData(chosenValue, 0)} style={{}} type={'normal'} />
                    </div>

                </div>
            </div>
            <div style={{ padding: 10 }}>
                <Radio.Group onChange={handleChange} value={select}>
                    <Radio.Button value={1}>Төсвийн орлого</Radio.Button>
                    <Radio.Button value={2}>Төсвийн зарлага</Radio.Button>
                </Radio.Group>
            </div>
            <div>
                {select === 1 ?
                    <PerformanceByMonthsIncome year={selectedYear} orgId={selectedData && selectedData.orgId ? selectedData.orgId[0] : user.org_id} chosenMinistry={chosenMinistry} filterOrgText={chosenOrganization} />
                    :
                    <PerformanceByMonthsExpense year={selectedYear} orgId={selectedData && selectedData.orgId ? selectedData.orgId[0] : user.org_id} chosenMinistry={chosenMinistry} filterOrgText={chosenOrganization} />
                }

            </div>
            <div className='card' style={{ padding: 10, marginBottom: '10px' }}>
                <div>
                    <span style={{ fontWeight: '700' }}>3. ЭДИЙН ЗАСГИЙН АНГИЛЛААР /сая ₮/</span>
                </div>
                <div style={{ padding: 10 }}>
                    {select === 1 ?
                        <PerformanceByEzaIncome year={selectedYear} chosenMinistry={chosenMinistry} filterOrgText={chosenOrganization} econType={1} orgId={selectedData && selectedData.orgId ? selectedData.orgId[0] : user.org_id}/>
                        :
                        <PerformanceByEzaExpense year={selectedYear} chosenMinistry={chosenMinistry} filterOrgText={chosenOrganization} econType={2} orgId={selectedData && selectedData.orgId ? selectedData.orgId[0] : user.org_id}/>
                    }
                </div>

            </div>
            <div className='card' style={{ padding: 10 }}>
                <div>
                    <span style={{ fontWeight: '700' }}>4. ХӨТӨЛБӨРӨӨР /сая ₮/</span>
                </div>
                <div style={{ padding: 10 }}>
                    {select === 1 ?
                        <PerformanceByAgendIncome year={selectedYear} filterOrgText={chosenOrganization} orgId={selectedData && selectedData.orgId ? selectedData.orgId[0] : user.org_id}/>
                        :
                        <PerformanceByAgendaExpense year={selectedYear} filterOrgText={chosenOrganization} orgId={selectedData && selectedData.orgId ? selectedData.orgId[0] : user.org_id}/>
                    }
                </div>

            </div>
            {/* <div className='card' style={{ padding: 10 }}>
                <div>
                    <span style={{ fontWeight: '700' }}>4. Зардлыг санхүүжүүлэх эх үүсвэрээр</span>
                </div>
                <div style={{padding: 10}}>
                    <PerformanceBySourceOfFunding year={selectedYear} chosenMinistry={chosenMinistry} filterOrgText={chosenOrganization} econType={3} />
                </div>
            </div> */}
            {showPopupEhuulga && renderEhuulga()}
            {isPivotData && renderPopupPerformance()}
            <SourceFooter />
        </div>
    )
}

export function getYears(future) {
  let length = 30 + (future ? 1 : 0);
  let curYear = (new Date()).getFullYear() + (future ? 1 : 0);
  let years = [];

  for(let i=0; i<length; i++) {
    years.push(curYear-i);
  }

  return years;
}

export function nFormatter(num, digits) {
  let isMinus = false;
  if (num < 0) {
    isMinus = true;
    num *= -1;
  }

  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: " мянга" },
    { value: 1e6, symbol: " сая" },
    { value: 1e9, symbol: " тэрбум" },
    { value: 1e12, symbol: " их наяд" },
    { value: 1e15, symbol: " тунамал" },
    { value: 1e18, symbol: " их ингүүмэл" }
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  let item = lookup.slice().reverse().find(function(item) {
    return num >= item.value;
  });

  return item ? (isMinus ? (num / item.value).toFixed(digits).replace(rx, "$1")*-1: (num / item.value).toFixed(digits).replace(rx, "$1")) + item.symbol : "0";
}


//chart-ууд дээр сонгуулийн жилийг ялгахад хэрэглэж байгаа
export const getStripValues = () => {
  const election = 1992; // Анхны сонгууль
  const currentYear = new Date().getFullYear();
  const result = [];
  for (let i = election; i < currentYear; i += 8) {
    const startValue = i;
    const endValue = i + 4;
    result.push({ startValue, endValue });
  }
  return result;
};

export function numberWithCommas(x) {
  if (!x && x !== 0) return null
  // let a = x < 0 ? x * -1 : x;
  let a = x;
  let nf = new Intl.NumberFormat('en-US',{ minimumFractionDigits: 2, maximumFractionDigits: 2 });
  return nf.format(a);
}

export function numberWithCommas11(x, mfd) {
  let a = x < 0 ? x * -1 : x;
  let nf = new Intl.NumberFormat('en-US',{ minimumFractionDigits: mfd });
  return nf.format(a);
}

export function percentPerformance(performance, total) {
  performance *= 1;
  total *= 1;
  return (performance*100/total).toFixed(1)*1
}

export function fixedInteger(value) {
  return value.toFixed(0)*1
}

export function list_to_tree(l) {
  var map = {}, node, roots = [], i;
  let list = [...l];

  for (i = 0; i < list.length; i += 1) {
    map[list[i].id] = i; // initialize the map
    list[i].children = []; // initialize the children
  }

  for (i = 0; i < list.length; i += 1) {
    node = list[i];
    if (node.parent_id !== null) {
      // if you have dangling branches check that map[node.parent_id] exists
      list[map[node.parent_id]].children.push(node);
    } else {
      roots.push(node);
    }
  }
  return roots;
}

export function subtotal_tree(n) {
  // өөр талбараар нийлбэрийг авах бол field нэмж зааж өгнө үү
  if (!("amount" in n)) n.amount = 0;
  if (!("tez_amount" in n)) n.tez_amount = 0;
  if (!("ttz_amount" in n)) n.ttz_amount = 0;
  if (!("year0" in n)) n.year0 = 0;
  if (!("year1" in n)) n.year1 = 0;
  if (!("year2" in n)) n.year2 = 0;
  if (!("amount_mn" in n)) n.amount_mn = 0;
  if (!("base_cur_amount_mn" in n)) n.base_cur_amount_mn = 0;
  if (!("state_budget_amount" in n)) n.state_budget_amount = 0;
  if (!("base_cur_amount" in n)) n.base_cur_amount = 0;
  if (!("month1" in n)) n.month1 = 0;
  if (!("month2" in n)) n.month2 = 0;
  if (!("month3" in n)) n.month3 = 0;
  if (!("month4" in n)) n.month4 = 0;
  if (!("month5" in n)) n.month5 = 0;
  if (!("month6" in n)) n.month6 = 0;
  if (!("month7" in n)) n.month7 = 0;
  if (!("month8" in n)) n.month8 = 0;
  if (!("month9" in n)) n.month9 = 0;
  if (!("month10" in n)) n.month10 = 0;
  if (!("month11" in n)) n.month11 = 0;
  if (!("month12" in n)) n.month12 = 0;

  if (!("year3" in n)) n.year3 = 0;
  if (!("year4" in n)) n.year4 = 0;
  if (!("year5" in n)) n.year5 = 0;
  //Өмнөх жилийн төсвийн тшз санал
  if (!("year100" in n)) n.year100 = 0;
  //Өмнөх жилийн төсвийн ттз санал
  if (!("year101" in n)) n.year101 = 0;
  //Өмнөх жилийн төсвийн тез санал
  if (!("year102" in n)) n.year102 = 0;
  //Тухайн жилийн ХБ гүйцэтгэл
  if (!("year103" in n)) n.year103 = 0;


  if (n.children !== undefined && n.children.length === 0) {
    return {
      amount: n.amount*1,
      tez_amount: n.tez_amount*1,
      ttz_amount: n.ttz_amount*1,
      year0: n.year0*1,
      year1: n.year1*1,
      year2: n.year2*1,
      amount_mn: n.amount_mn*1,
      base_cur_amount_mn: n.base_cur_amount_mn*1,
      state_budget_amount: n.state_budget_amount*1,
      base_cur_amount: n.base_cur_amount*1,
      month1: n.month1*1,
      month2: n.month2*1,
      month3: n.month3*1,
      month4: n.month4*1,
      month5: n.month5*1,
      month6: n.month6*1,
      month7: n.month7*1,
      month8: n.month8*1,
      month9: n.month9*1,
      month10: n.month10*1,
      month11: n.month11*1,
      month12: n.month12*1,
      year3: n.year3*1,
      year4: n.year4*1,
      year5: n.year5*1,
      year100: n.year100*1,
      year101: n.year101*1,
      year102: n.year102*1,
      year103: n.year103*1,
    };
  }

  if (n.children && n.children.length > 0) {
    n.amount = 0;
    n.tez_amount = 0;
    n.ttz_amount = 0;
    n.year0 = 0;
    n.year1 = 0;
    n.year2 = 0;
    n.amount_mn = 0;
    n.base_cur_amount_mn = 0;
    n.state_budget_amount = 0;
    n.base_cur_amount = 0;
    n.month1 = 0;
    n.month2 = 0;
    n.month3 = 0;
    n.month4 = 0;
    n.month5 = 0;
    n.month6 = 0;
    n.month7 = 0;
    n.month8 = 0;
    n.month9 = 0;
    n.month10 = 0;
    n.month11 = 0;
    n.month12 = 0;
    n.year3 = 0;
    n.year4 = 0;
    n.year5 = 0;
    n.year100 = 0;
    n.year101 = 0;
    n.year102 = 0;
    n.year103 = 0;
  }

  n.children.forEach(function(c){
    let {amount, tez_amount, ttz_amount, year0, year1, year2, amount_mn, base_cur_amount_mn, state_budget_amount, base_cur_amount, month1, month2, month3, month4, month5, month6, month7, month8, month9, month10, month11, month12,year3,year4,year5,year100,year101,year102,year103} = subtotal_tree(c);
    n.amount += amount;
    n.tez_amount += tez_amount;
    n.ttz_amount += ttz_amount;
    n.year0 += year0;
    n.year1 += year1;
    n.year2 += year2;
    n.amount_mn += amount_mn;
    n.base_cur_amount_mn += base_cur_amount_mn;
    n.state_budget_amount += state_budget_amount;
    n.base_cur_amount += base_cur_amount;
    n.month1 += month1;
    n.month2 += month2;
    n.month3 += month3;
    n.month4 += month4;
    n.month5 += month5;
    n.month6 += month6;
    n.month7 += month7;
    n.month8 += month8;
    n.month9 += month9;
    n.month10 += month10;
    n.month11 += month11;
    n.month12 += month12;
    n.year3 += year3;
    n.year4 += year4;
    n.year5 += year5;
    n.year100 += year100;
    n.year101 += year101;
    n.year102 += year102;
    n.year103 += year103;
  });

  return {
    amount: n.amount*1,
    tez_amount: n.tez_amount*1,
    ttz_amount: n.ttz_amount*1,
    year0: n.year0*1,
    year1: n.year1*1,
    year2: n.year2*1,
    amount_mn: n.amount_mn*1,
    base_cur_amount_mn: n.base_cur_amount_mn*1,
    state_budget_amount: n.state_budget_amount*1,
    base_cur_amount: n.base_cur_amount*1,
    month1: n.month1*1,
    month2: n.month2*1,
    month3: n.month3*1,
    month4: n.month4*1,
    month5: n.month5*1,
    month6: n.month6*1,
    month7: n.month7*1,
    month8: n.month8*1,
    month9: n.month9*1,
    month10: n.month10*1,
    month11: n.month11*1,
    month12: n.month12*1,
    year3: n.year3*1,
    year4: n.year4*1,
    year5: n.year5*1,
    year100: n.year100*1,
    year101: n.year101*1,
    year102: n.year102*1,
    year103: n.year103*1,
  };
}

export const budgetPalettes = [
  {id: 1, name: 'Зарлага', color: ['#F93A3A', '#4c5286', '#0469FF', '#FF4141']},
  {id: 1.1, name: 'Зарлага', color: ['#51578E','#F93A3A','#FFB5B5', '#FF4141']},
  {id: 2, name: 'Орлого', color: ['#34B771', '#205C7B', '#0469FF', '#FF4141']},
  {id: 2.1, name: 'Орлого', color: ['#205C7B','#34B771','#BAE9E0', '#F8FBE8']},
  {id: 3, name: 'Эх үүсвэр', color: ['#2C77FF', '#003696', '#0469FF', '#FF4141']},
  {id: 4, name: 'Зээл', color: ['#2C77FF', '#003696', '#0469FF', '#FF4141']},
  {id: 4.1, name: 'Зээл', color: ['#003696', '#2C77FF', '#AAC8FF', '#FF4141']},
  {id: 5, name: 'Хөтөлбөр, арга хэмжээ', color: ['#003696','#2c77ff','#FF4141', '#f58989']},
]

export const chartGradient = ["#003696","#3758b0","#5c7ccb","#81a1e5","#a8c7ff"]

export const schedulePalettes = [
  {id: '0', name: 'Санал', color: ['#2C77FF', '#003696', '#0469FF', '#FF4141']},
  {id: '2', name: 'Зарлага', color: ['#F93A3A', '#4c5286', '#0469FF', '#FF4141']},
  {id: '1', name: 'Орлого', color: ['#34B771', '#205C7B', '#0469FF', '#FF4141']},
  {id: '3', name: 'Эх үүсвэр', color: ['#2C77FF', '#003696', '#0469FF', '#FF4141']},
  {id: '4', name: 'Төсвийн бусад мэдээлэл', color: ['#2C77FF', '#003696', '#0469FF', '#FF4141']},
]

export const blueColorGradient = ['#717398', '#6f7198', '#6e7097', '#6c6e97', '#6a6d96', '#686b96', '#676995', '#656895', '#636694', '#626494', '#606393', '#5e6193', 
'#5d6092', '#5b5e92', '#595c91', '#575b91', '#565990', '#545890', '#52568f', '#50558e', '#4f538e', '#4d518d', '#4b508d', '#494e8c', '#474d8c', '#464b8b', '#444a8b', '#42488a', '#40478a', '#3e4589',
];

export const budgetStatementColorGradient = ["#2e77ff","#2c73f9","#2b70f3","#296ced","#2769e8","#2665e2","#2461dc","#225ed6","#205ad1",
"#1d57cb","#1b54c5","#1950c0","#164dba","#1449b5","#1146af","#0e43aa","#0a40a4","#073c9f","#033999","#003694",]

export const greenColorGradient = ['#6ce8a4','#6ae5a2','#67e29f','#65de9d','#62db9a','#60d898','#5ed596','#5bd293','#59cf91','#57cc8f',
'#54c98c','#52c58a','#50c288','#4dbf85','#4bbc83','#49b981','#46b67e','#44b37c','#42b07a','#3fad77','#3daa75'];

export const redColorGradient  = ['#ea8083','#eb7e81','#ec7c7f','#ec7a7d','#ed787b','#ee7679','#ee7476','#ef7274','#f07072','#f06e70','#f16c6e','#f16a6b',
'#f26869','#f26667','#f36465','#f36262','#f46060','#f45d5e','#f55b5b','#f55959','#f55756','#f55454','#f65252','#f64f4f','#f64d4d','#f64b4a','#f74848','#f74545','#f74343','#f74040',
];

export const paletteOrlogo = [
    '#4F957D', '#31B76F', '#26BB57', '#014118'
]
export const palettePayrollChart = [
  '#34D47E', '#34347E'
]
export const paletteErgenTologdoh = [
    '#225175', '#6C74E5', '#FFA200', '#EE3E00'
] 

export const paletteZarlaga = [
    '#FB3938', '#EE4A4A', '#D1FAD4', '#559C9E'
]

export const paletteEhUusver = [
  '#FFB350', '#FF5B5B', '#FDDFC4', '#F2855C',
]

export const palettePayrollReport = [
  '#5A8FC4', '#1370C2'
]

export const paletteFinanceReport = [
  '#5FE2B1', '#4EC2AE'
]

export function toCapitalize(text) {
  if (text) {
    return text.charAt(0) + text.slice(1)
  } else {
    return '';
  }
}

export function formatBytes(a,b=2){if(!+a)return"0 Bytes";const c=0>b?0:b,d=Math.floor(Math.log(a)/Math.log(1024));return`${parseFloat((a/Math.pow(1024,d)).toFixed(c))} ${["Bytes","KiB","MiB","GiB","TiB","PiB","EiB","ZiB","YiB"][d]}`}

export const InfoGroupNames = {
  "duty": 'чиг үүрэг',
  "strat": 'стратеги төлөвлөгөө',
  "oper": 'үйл ажиллагааны стратеги, зохион байгуулалтын бүтцийн өөрчлөлтийн хөтөлбөр'
}

export const InfoGroups = {
  "vision" : "strat",
  "purpose" : "strat",
  "purpose1" : "strat",
  "strategy_goal" : 'strat',
  "priorities" : 'oper',
  "duty1": "strat",
  "duty2" : "strat",
  "purpose2" : "oper",
  "priorities1" : "oper",
  "strategy" : "oper",
  "goal1" : "oper",
  "minister_goal" : "duty",
}
export const formatDate = (date = new Date())=> {
  const year = date.toLocaleString('default', {year: 'numeric'});
  const month = date.toLocaleString('default', {
    month: '2-digit',
  });
  const day = date.toLocaleString('default', {day: '2-digit'});
  const hour = date.toLocaleString('default', {hour: '2-digit'});
  const min = date.toLocaleString('default', {minute: '2-digit'});
  const second = date.toLocaleString('default', {second: '2-digit'});

  return [year, month, day,hour.substring(0,2),min,second].join('');
}

export function processHtmlString(htmlString) {
  let tempDiv = document.createElement('div');
  tempDiv.innerHTML = htmlString;
  let imgElements = tempDiv.querySelectorAll('img');
  
  imgElements.forEach(function (img) {
    const isFacebookEmoji = img.src.includes('https://static.xx.fbcdn.net/images/emoji.php');

    if (!isFacebookEmoji && (!img.hasAttribute('width') || img.getAttribute('width') !== '100%' || !img.hasAttribute('height') || img.getAttribute('height') !== 'auto')) {
      img.setAttribute('width', '100%');
      img.setAttribute('height', 'auto');
    }
  });

  return tempDiv.innerHTML;
}
export function processScriptString(htmlString) {
  let tempDiv = document.createElement('div');
  tempDiv.innerHTML = htmlString;

  let imgElements = tempDiv.querySelectorAll('img');
  imgElements.forEach(function (img) {
    const isFacebookEmoji = img.src.includes('https://static.xx.fbcdn.net/images/emoji.php');

    if (!isFacebookEmoji && (!img.hasAttribute('width') || img.getAttribute('width') !== '100%' || !img.hasAttribute('height') || img.getAttribute('height') !== 'auto')) {
      img.setAttribute('width', '100%');
      img.setAttribute('height', 'auto');
    }
  });

  let allElements = tempDiv.querySelectorAll('*');
  allElements.forEach(function (element) {
    if (element.style.fontFamily) {
      element.style.fontFamily = ''; 
    }
    if (element.style.fontSize){
      element.style.fontSize = "1rem"
    }
  });

  return tempDiv.innerHTML;
}
export const sortingText={ascendingText:'Өсөхөөр',descendingText:'Буурахаар',clearText:'Эрэмбэ цэвэрлэх'}
export const operationDescriptions={contains:'Агуулна',notContains:'Агуулахгүй',startsWith:'Эхлэлээр',endsWith:'Төгсгөлөөр',equal:'Тэнцүү',notEqual:'Тэнцүү биш',between:'Хооронд',greaterThan:'Их',greaterThanOrEqual:'Их буюу тэнцүү',lessThan:'Бага',lessThanOrEqual:'Бага буюу тэнцүү'}
export const resetOperationText='Цуцлах'
export const HeaderFilterText={ok:'Хайх',cancel:'Цуцлах',emptyValue:'Хоосон утга'}
export const exportAllText='Бүх өгөгдлийг Excel рүү экспортлох'
export const columnFixingText={fix:'Багана цоожлох',leftPosition:'Зүүн тал руу цоожлох',rightPosition:'Баруун тал руу цооожлох',unfix:'Багана цоожлолт цуцлах'}
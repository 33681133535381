import React from 'react';
import DataGrid, {
  Column,
  Paging,
  Scrolling,
  Selection,
} from 'devextreme-react/data-grid';
import DropDownBox from 'devextreme-react/drop-down-box';

const dropDownOptions = { width: 500 };

export default class AcctComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRowKeys: [props.data.value],
      isDropDownOpened: false,
    };
    this.onSelectionChanged = this.onSelectionChanged.bind(this);
    this.contentRender = this.contentRender.bind(this);
    this.boxOptionChanged = this.boxOptionChanged.bind(this);
  }

  boxOptionChanged(e) {
    if (e.name === 'opened') {
      this.setState({
        isDropDownOpened: e.value,
      });
    }
  }

  contentRender() {
    return (
      <DataGrid
        dataSource={this.props.data.column.lookup.dataSource}
        //remoteOperations={true}
        height={250}
        hoverStateEnabled={true}
        onSelectionChanged={this.onSelectionChanged}
        focusedRowEnabled={true}
      >
        <Column caption="данс" dataField="acct" />
        <Column caption="нэр" dataField="descr" />
        <Scrolling mode="virtual" />
        <Selection mode="single" />
      </DataGrid>
    );
  }

  onSelectionChanged(selectionChangedArgs) {
    if (selectionChangedArgs && selectionChangedArgs.selectedRowKeys.length > 0) {
      this.setState({
        selectedRowKeys: selectionChangedArgs.selectedRowKeys[0].acct,
        isDropDownOpened: false,
      });
    }
    this.props.data.setValue(this.state.selectedRowKeys);
  }

  render() {
    return (
      <DropDownBox
        onOptionChanged={this.boxOptionChanged}
        opened={this.state.isDropDownOpened}
        dropDownOptions={dropDownOptions}
        dataSource={this.props.data.column.lookup.dataSource}
        value={this.state.selectedRowKeys}
        displayExpr="acct"
        valueExpr="acct"
        contentRender={this.contentRender}>
      </DropDownBox>
    );
  }
}

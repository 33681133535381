import React, {useState, useEffect} from "react";
import notify from "devextreme/ui/notify";
import GeneralInfoService from "../../services/api/generalInfo";
import {Popup} from "devextreme-react/popup";
import OgTree, {createTreeStructure} from "./OgTreeList";
import LoadPanelComponent from "../budget/components/loadPanel";

const OrganizationGeneralInformation = () => {
    const [loading, setLoading] = useState(false);
    const [dataSource, setDataSource] = useState([]);
    const [additionalData, setAdditionalData] = useState({});
    const [visiblePop, setVisiblePop] = useState(false);

    const loadHandler = (bool) => {
        setLoading(bool);
    }

    const loadDataSource = async () => {
        try {
            setLoading(true);
            let result = await GeneralInfoService.fetch();
            setDataSource(createTreeStructure(result));
            setLoading(false);
        } catch (e) {
            setLoading(false);
            notify(e.message, "error", 2000);
        }
    }

    const additionalButtonClickHandler = async (e) => {
        setLoading(true);
        let additionalData = await GeneralInfoService.fetchAdditionalData(e);
        setAdditionalData(additionalData);
        if (additionalData.whichRender === "law") {
            setVisiblePop(true);
        }
        setLoading(false);
    }

    useEffect(async () => {
        await loadDataSource();
    }, [])

    return (
        <div id="bigOneLoad">
            <Popup
                style={{ zIndex: 99 }}
                visible={visiblePop}
                onHiding={() => {
                    setVisiblePop(false)
                }}
            >
                {additionalData && additionalData.whichRender === "law" && (
                    <iframe src={additionalData.legal_url} width="100%" style={{height: "100%"}}/>
                )}
            </Popup>
            <br/>
            <OgTree data={dataSource} additionalButtonClickHandler={additionalButtonClickHandler} loadHandler={loadHandler}/>
            <LoadPanelComponent
                position={{of: '#bigOneLoad'}}
                visible={loading}
            />
        </div>
    )
}

export default OrganizationGeneralInformation

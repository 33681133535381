import React, {useState, useEffect} from "react";
import BudgetService from "../../../../services/api/budget";
import _ from "lodash";
import notify from "devextreme/ui/notify";
import {numberWithCommas} from "../../../../util";
import DataGrid, {Column, Paging, Summary, TotalItem} from "devextreme-react/data-grid";
import LoadPanelComponent from "../../../budget/components/loadPanel";
import EmptyData from "../../../budget/components/empty";

const EhUusverDataGrid = (props) => {
    const [chartData, setChartData] = useState([]);
    const [sumVal, setSumVal] = useState(0);
    const [loader, setLoader] = useState(true);

    const loadData = async () => {
        try {
            let r = await BudgetService.getEhUusverDataGrid(props.year, props.chosenValue, props.chosenMinistry? props.chosenMinistry: "");
            let sum = _.sumBy(r, 'todotgoson');
            setSumVal(sum);
            setChartData(r);
        } catch (e) {
            notify(e.message, "error", 2000);
        }finally{
            setLoader(false)
        }
    }

    useEffect(() => {
        loadData();
    }, [props.year, props.chosenValue, props.chosenMinistry])

    const cellTemp = (cellElement, cellInfo) => {
        return cellElement.append(cellInfo.row.loadIndex + 1);
    }

    const cellFractionColumn = (e) => {
        return `${numberWithCommas(e.value / 1000000)}`
    }

    const calcCellVal = (e) => {
        return ((e.todotgoson * 100) / sumVal).toFixed(2)
    }

    const rowSelectionHandler = (e) => {
        props.callFunc(e.data.name)
    }
    const onCellPreparedHandler = (e) => {
        if (e.rowType === 'header') {
            e.cellElement.wordWrapEnabled = true;
            e.cellElement.style.fontFamily = 'Segoe UI'
            e.cellElement.style.fontWeight = 'bold'
            e.cellElement.style.color = "#15186A"
            e.cellElement.style.backgroundColor = "#EEF0F4"
            //e.cellElement.style.backgroundImage = `linear-gradient(to right, ${paletteZarlaga[1]}, ${paletteZarlaga[0]})`
        }
        if (e.rowType === 'data') {
            e.cellElement.style.fontFamily = 'Segoe UI'
            e.cellElement.style.fontWeight = 'semibold'
            e.cellElement.style.color = "#000000"
        }
        if (e.rowType === 'data' && e.column.dataField === 'name') {
            let elem = e.cellElement;
            elem.style.setProperty('cursor', 'pointer', 'important');
            // elem.style.setProperty('color', 'green');
        }
    }
    if (chartData?.length === 0 && !loader){
        return (<div style={{display: "flex", justifyContent: "center", alignItems: "center", family: "Segoe UI" }}>
        <EmptyData/>
    </div>)
    }
    return (
        <div id="load2">
            <LoadPanelComponent position={{of: '#load2'}} visible={loader} />
            <DataGrid
                rowAlternationEnabled={true}
                dataSource={chartData}
                wordWrapEnabled={true}
                noDataText = 'Дата байхгүй байна.'
                onCellPrepared={onCellPreparedHandler}
                style={{ fontFamily: "Segoe UI", fontWeight: 'semibold' }}
            >
                <Paging defaultPageSize={9}/>
                <Column caption="№" allowSorting={false} cellTemplate={cellTemp} alignment="center" width={30}/>
                <Column dataField="name" allowSorting={false} caption="Байгууллага" alignment="left" minWidth={120}/>
                <Column caption="Төсөв" alignment="center">
                    <Column dataField="todotgoson" allowSorting={true} cellRender={cellFractionColumn} defaultSortOrder="desc" alignment="center" caption="Дүн" allowFiltering={false} width={95}/>
                    <Column caption="Хувь" allowSorting={true} calculateCellValue={calcCellVal} alignment="center" width={60}/>
                </Column>
                <Summary>
                    <TotalItem
                        column="todotgoson"
                        summaryType="sum"
                        customizeText={(data) => {return `${numberWithCommas(data.value / 1000000)}`}}
                    />
                </Summary>
            </DataGrid>
        </div>
    )
}

export default EhUusverDataGrid

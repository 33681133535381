import React, {useState, useEffect, useContext} from "react";
import { Popup } from 'devextreme-react/popup';
import OrganzationService from "../../services/api/organization";
import DetailRightPanel from "./components/detail-right-panel";
import RefService from "../../services/api/refs";
import _ from "lodash";
import pattern1 from "../../assets/imgs/patternIdea2.jpg"
import pattern from "../../assets/imgs/patternIdea1.jpg"
import pattern2 from "../../assets/imgs/patternIdea3.jpg"
import Button from "devextreme-react/button";
import {UserContext} from "../../hooks/UserContext";

const {getList} = OrganzationService;

const Detail = (props) => {
    const [refOrgList, setRefOrgList] = useState([]);
    const [organizationList, setOrganizationList] = useState([]);
    const [popupVisible, setPopupVisible] = useState(false)
    const [orgListData, setOrgListData] = useState([]);
    const [passingData, setPassingData] = useState([]);
    const [orgBudget, setOrgBudget] = useState([]);
    const [orgDetail, setOrgDetail] = useState([]);
    const [teste, setTeste] = useState([]);
    const [teste1, setTeste1] = useState([]);
    const [teste2, setTeste2] = useState([]);
    const [teste3, setTeste3] = useState([]);
    const [orgAttr, setOrgAttr] = useState([]);
    const [selectedItemKeys, setSelectedItemKeys] = useState(1);
    const {user} = useContext(UserContext);

    useEffect(  () => {
        getOrganizationIntroductions();
        orgTypeWithQuery();

        getLoadRefOrg();
        // getOnOrgChosen();
        getOrganizationList();
        setPassingData(orgListData);
    },[]);

    const getOrganizationIntroductions = async () => {
        try {
            const result = (await OrganzationService.getOrganizationIntroductions());
            console.log('result intro', result);
        } catch (e) {
            console.error(e);
        }
    }

    const getOrganizationList = async () => {
        try {
            const orgListRes = await getList();
            console.log({orgListRes});
            setOrganizationList(orgListRes);
        } catch (e) {

        }
    }

    const getOnOrgChosen = async (org_id) =>{
        try{
            let res;
            let id;
            res = await OrganzationService.orgIdTake(org_id)
            setOrgBudget(org_id)
            let data = res.data || {attributes: []};
            console.log('TEMP SHUU ->', res);
            setOrgDetail(data)
            let tempAttributes = [];
            if (data.attributes){
                for(let i=0; i<data.attributes.length; i++){
                    let attr = data.attributes;
                    if (attr[i].name !== 'website' && attr[i].name !== 'email' && attr[i].name !== 'address' && attr[i].name !== 'telephone' && attr[i].name !== 'logo') {
                        tempAttributes.push(attr[i])
                }
            }
                setOrgAttr(tempAttributes)
                console.log('TEMP SHUU ->', tempAttributes);
                // const resultdata = _.groupBy(tempAttributes,'value')
                // console.log('tur rest awchy', resultdata)
        }

        }catch (e) {
            console.error(e)
        }

    }
    function visible (isVisible) {
        if( isVisible === "true") {
        } else {
            setPopupVisible(false)
        }
    }

    const getOrgBudget = async (orgId) => {
        try{
            const orgBudget =  await OrganzationService.OrgBudget(orgId)
            setOrganizationList(orgBudget)
            let data = orgBudget.data || [];
            let test = _.find(data, function(o){
                return o.econ_category_code === '35'
            }) || {};
            setTeste(test)
            let test1 = _.find(data, function(o){
                return o.econ_category_code === '370001'
            }) || {}
            setTeste1(test1)
            let test2 = _.find(data, function(o){
                return o.econ_category_code === '2'
            }) || {};
            setTeste2(test2)
            let test3 = _.find(data, function(o){
                console.log('test3 end garnada',teste3)
                return o.econ_category_code === '23'
            }) || {};
            setTeste3(test3)

            console.log('aaaaaaaaaaaaaaaaaa',test);

        }catch (e) {
            console.error(e)
        }
    }

    const getLoadRefOrg = async () => {
        try{
            const result = await RefService.loadRefOrg();
            let obj = [];
            for (let i=0; i<result.data.length; i++) {
                if (result.data[i].is_org) {
                    obj.push({
                        id: result.data[i].id,
                        name: result.data[i].name
                    })
                }
            }
            setRefOrgList(obj);
            // console.log('iiiiiiiiiiiiiiiiiiiii',refOrgList)
        }catch (e) {
            console.error(e)
        }
    }
    const orgTypeWithQuery = async () => {
        try {
            let type = props.location.search.substring(props.location.search.length - 1,);
            if(!type) {
                console.log("user: ", user.org_id);
               type = user.org_id;
               //  type = 1;
                console.log(type)
            }
            const result = await OrganzationService.orgTypeWithQuery(1);
            setOrgListData(result.data);
            console.log('DATATAA',result.data);
            // setSelectedOrganisation([result.data[0]])
            if(result.data.length) {
                // console.log('eeeeeeeeeeeek',selectedOrg);
                getSpecificOrg(result.data[0].org_id);
                getOrgBudget(result.data[0].org_id);
                getOnOrgChosen(result.data[0].org_id);
            }
        } catch (e) {
            console.error("gg aldaa", e);
        }
    }

    const getSpecificOrg = async (org_id) => {
        try {
            const result = await OrganzationService.orgDetailWithParam(org_id)
            console.log('RRRRRRRRRRRRRRRR', result);
            setPassingData(result.data.attributes);
        } catch (e) {
            console.error(e);
        }
    }
    return (
        <div style={{marginRight: -5, marginTop: -5}}>
            <div className="row" style={{marginTop: 0}}>
                <div className="col-12">
                        <DetailRightPanel
                            data = {passingData}
                        />
                    {/*<div className="row" id="budget">*/}
                    {/*    <div className="col-3">*/}
                    {/*        <div className="card">*/}
                    {/*            <div className="card-header green-background">*/}
                    {/*                <span style={{fontSize:'14px', lineHeight: 'normal',textAlign: "center",display: "flex", justifyContent: "center"}}>Харьяа төсөвт байгууллагын орлого</span>*/}
                    {/*            </div>*/}
                    {/*            <div className="card-body green-text" style={{fontWeight: "400",fontSize: "18px", textAlign: "center", height: "50px"}}>*/}
                    {/*                {nFormatter(teste.amount || 0, 1)}*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*    <div className="col-3">*/}
                    {/*        <div className="card">*/}
                    {/*            <div className="card-header orange-background">*/}
                    {/*                <span style={{fontSize:'14px', lineHeight: 'normal',display: "flex", justifyContent: "center"}}>Эргэн төлөгдөх төлбөр</span>*/}
                    {/*            </div>*/}
                    {/*            <div className="card-body green-text" style={{fontWeight: "400",fontSize: "18px", textAlign: "center", height: "50px"}}>*/}
                    {/*                {nFormatter(teste1.amount || 0, 1)}*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*    <div className="col-3">*/}
                    {/*        <div className="card">*/}
                    {/*            <div className="card-header red-background">*/}
                    {/*                <span style={{fontSize:'14px', lineHeight: 'normal',display: "flex", justifyContent: "center"}}>Зарлага</span>*/}
                    {/*            </div>*/}
                    {/*            <div className="card-body green-text" style={{fontWeight: "400",fontSize: "18px", textAlign: "center", height: "50px"}}>*/}
                    {/*                {nFormatter(teste2.amount || 0, 1)}*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*    <div className="col-3">*/}
                    {/*        <div className="card">*/}
                    {/*            <div className="card-header blue-background">*/}
                    {/*                <span style={{fontSize:'14px', lineHeight: 'normal',display: "flex", justifyContent: "center"}}>Эргэн төлөгдөх нөхцөлтэй олгох санхүүжилт</span>*/}
                    {/*            </div>*/}
                    {/*            <div className="card-body green-text" style={{fontWeight: "400",fontSize: "18px", textAlign: "center", height: "50px"}}>*/}
                    {/*                {nFormatter(teste3.amount || 0, 1)}*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}


                    {/*<div className="row" id="budget">*/}
                    {/*    <div className="col-3">*/}
                    {/*        <div className="card">*/}
                    {/*            <div className="card-header green-background">*/}
                    {/*                <span style={{fontSize:'14px', lineHeight: 'normal',textAlign: "center",display: "flex", justifyContent: "center"}}>Харьяа төсөвт байгууллагын орлого</span>*/}
                    {/*            </div>*/}
                    {/*            <div className="card-body green-text" style={{fontWeight: "400",fontSize: "18px", textAlign: "center", height: "50px"}}>*/}
                    {/*                {nFormatter(teste.amount || 0, 1)}*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*    <div className="col-3">*/}
                    {/*        <div className="card">*/}
                    {/*            <div className="card-header orange-background">*/}
                    {/*                <span style={{fontSize:'14px', lineHeight: 'normal',display: "flex", justifyContent: "center"}}>Эргэн төлөгдөх төлбөр</span>*/}
                    {/*            </div>*/}
                    {/*            <div className="card-body green-text" style={{fontWeight: "400",fontSize: "18px", textAlign: "center", height: "50px"}}>*/}
                    {/*                {nFormatter(teste1.amount || 0, 1)}*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*    <div className="col-3">*/}
                    {/*        <div className="card">*/}
                    {/*            <div className="card-header red-background">*/}
                    {/*                <span style={{fontSize:'14px', lineHeight: 'normal',display: "flex", justifyContent: "center"}}>Зарлага</span>*/}
                    {/*            </div>*/}
                    {/*            <div className="card-body green-text" style={{fontWeight: "400",fontSize: "18px", textAlign: "center", height: "50px"}}>*/}
                    {/*                {nFormatter(teste2.amount || 0, 1)}*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*    <div className="col-3">*/}
                    {/*        <div className="card">*/}
                    {/*            <div className="card-header blue-background">*/}
                    {/*                <span style={{fontSize:'14px', lineHeight: 'normal',display: "flex", justifyContent: "center"}}>Эргэн төлөгдөх нөхцөлтэй олгох санхүүжилт</span>*/}
                    {/*            </div>*/}
                    {/*            <div className="card-body green-text" style={{fontWeight: "400",fontSize: "18px", textAlign: "center", height: "50px"}}>*/}
                    {/*                {nFormatter(teste3.amount || 0, 1)}*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}




                    {/*<UserPosition*/}
                    {/*    data = {passingData}*/}
                    {/*/>*/}
                    <div className="row" style={{}}>
                        {/*<div className="col-2">*/}
                        {/*    <div className="card" style={{}}>*/}
                        {/*        <List*/}
                        {/*            noDataText="Хоосон байна"*/}
                        {/*            selectionMode = "single"*/}
                        {/*            hoverStateEnabled = {true}*/}
                        {/*            nextButtonText = "Үргэлжлүүлэх"*/}
                        {/*            dataSource = {testData}*/}
                        {/*            onSelectionChanged = {organizationSelect}*/}
                        {/*            itemRender={testDataName}*/}
                        {/*        />*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        <div className="col-12" >
                                {/*<ScrollView*/}
                                {/*    id="scrollview"*/}
                                {/*    scrollByContent={true}*/}
                                {/*    showScrollbar='onHover'*/}
                                {/*    height={500}*/}
                                {/*>*/}
                                    {
                                        selectedItemKeys === 1 ?
                                            <div className="" style={{marginLeft: 44, marginRight: 44, display: 'flex', flexDirection: "column", marginTop: 30}}>

                                                <div className="row" style={{height: 49, margin: 0}}>
                                                    <div className="home-menu-button" style={{backgroundColor: "#1921FA", color: "white",}}>
                                                        Алсын хараа
                                                    </div>
                                                    <div className="home-menu-button" style={{backgroundColor: "#EFF0FF", color: "#1921FA",}}>
                                                        Эрхэм зорилго
                                                    </div>
                                                    <div className="home-menu-button" style={{backgroundColor: "#EFF0FF", color: "#1921FA",}}>
                                                        Товч танилцуулга
                                                    </div>
                                                </div>
                                                <div style={{marginTop: 30, backgroundColor: "white", borderRadius: 20, padding: 30, color: "black", boxShadow: "0px 0px 20px 0px #5a5a5a21"}}>
                                                    Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32.
                                                    <br></br>
                                                    <br></br>
                                                    The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H. Rackham.
                                                </div>
                                                <div>
                                                    <div style={{marginTop: 30, display: "flex", flexDirection: "row"}}>
                                                        <div className="home-custom-tabpanel aaaa" style={{marginLeft: 17, color: "#1921FA"}}>
                                                            Стратегийн тэргүүлэх чиглэл
                                                        </div>
                                                        <div className="home-custom-tabpanel">
                                                            Стратегийн зорилт
                                                        </div>
                                                        <div className="home-custom-tabpanel">
                                                            Үйл ажиллагааны хөтөлбөр
                                                        </div>
                                                    </div>
                                                    <hr style={{marginTop: 13, color: "#D4D4D4"}}/>
                                                </div>
                                                <div style={{borderRadius: "20px", backgroundColor: "white", marginTop: 30, paddingLeft: 40, paddingTop: 30, paddingRight: 40, boxShadow: "0px 0px 20px 0px #5a5a5a21", paddingBottom: 30}}>
                                                    <div>
                                                        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
                                                    </div>
                                                    <div className="home-info-content">
                                                        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
                                                    </div>
                                                    <div className="home-info-content">
                                                        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
                                                    </div>
                                                    <div className="home-info-content">
                                                        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
                                                    </div>
                                                    <div className="home-info-content">
                                                        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
                                                    </div>
                                                </div>
                                                <div style={{marginTop: 30}}>
                                                    <div className="row" style={{height: 49, margin: 0}}>
                                                        <div className="home-menu-button" style={{backgroundColor: "#1921FA", color: "white",}}>
                                                            Түүхэн тойм
                                                        </div>
                                                    </div>
                                                </div>
                                                <div style={{marginTop: 30, backgroundColor: "white", borderRadius: 20,  paddingLeft: 48, paddingTop: 58, paddingRight: 48, display: "flex", flexDirection: "row"}}>
                                                    <div style={{display: "flex", flexDirection: "column"}}>
                                                        <div style={{backgroundColor: "red"}}>
                                                            wert
                                                        </div>
                                                        <div style={{backgroundColor: "green"}}>
                                                            wert
                                                        </div>
                                                    </div>
                                                    <div style={{marginLeft: 40}}>
                                                        Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32.
                                                    </div>
                                                    <div style={{backgroundColor: "cyan", marginLeft: 100}}>
                                                        IMAGE
                                                    </div>
                                                </div>
                                            {/*    <div className="" style={{marginLeft: "calc((100% - 80%) / 2)", marginRight: 5}}>*/}
                                            {/*        <div className="row" style={{ backgroundColor: "white"}}>*/}
                                            {/*            <div className="col-2" style={{display: "flex",justifyContent: "center", alignItems: "center", borderRadius: "5px", borderBottomRightRadius: 0, borderTopRightRadius: 0, borderLeft: "1px solid #1453B5", borderTop: "1px solid #1453B5",borderBottom: "1px solid #1453B5", borderRight: 0, padding: 15}}>*/}
                                            {/*                <p style={{fontSize: 21}}> Зорилго 1</p>*/}
                                            {/*            </div>*/}
                                            {/*            <div className="row col-10" style={{border: "1px solid #1453B5", borderRadius: 5,borderTopLeftRadius: 0, borderBottomLeftRadius: 0, display: "flex", alignItems: "center", marginTop: 0}}>*/}
                                            {/*                <div className="col-2" style={{padding: 15, display: "flex", justifyContent: "center"}}>*/}
                                            {/*                    <img className="" src={GoalIcon} style={{height: 45}}/>*/}
                                            {/*                </div>*/}
                                            {/*                <span class="col-7" style={{padding: 15}}>Хүнс, хөдөө аж ахуй, хөнгөн үйлдвэрийн яамны үйл ажиллагааны эрхэм зорилго нь салбарын бодлогыг оновчтой тодорхойлж хэрэгжүүлэх замаар түүхий эдийн нөөцийг зохистой ашиглах, импортыг орлох, экспортын баримжаатай бүтээгдэхүүний үйлдвэрлэл.</span>*/}
                                            {/*                <div className="col-3" style={{borderLeft: "1px solid #1453B5", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "Center"}}>*/}
                                            {/*                    <span style={{fontWeight: "bold", color: "#1453B5", fontSize: "40px"}}>86%</span>*/}
                                            {/*                    <span>Биелэлт</span>*/}
                                            {/*                </div>*/}
                                            {/*            </div>*/}
                                            {/*        </div>*/}
                                            {/*        <div className="row" style={{ marginTop: 50, marginLeft: 50, paddingRight: 50}}>*/}
                                            {/*            <div className="col-2" style={{backgroundColor: "white", display: "flex",justifyContent: "center", alignItems: "center", borderRadius: "5px", borderBottomRightRadius: 0, borderTopRightRadius: 0, borderLeft: "1px solid #1453B5", borderTop: "1px solid #1453B5",borderBottom: "1px solid #1453B5", borderRight: 0, padding: 15}}>*/}
                                            {/*                <p style={{fontSize: 21}}> Зорилт 1</p>*/}
                                            {/*            </div>*/}
                                            {/*            <div className="row col-10" style={{backgroundColor: "white", border: "1px solid #1453B5", borderRadius: 5,borderTopLeftRadius: 0, borderBottomLeftRadius: 0, display: "flex", alignItems: "center", marginTop: 0}}>*/}
                                            {/*                <div className="col-2" style={{padding: 15, display: "flex", justifyContent: "center"}}>*/}
                                            {/*                    <img className="" src={PurposeIcon} style={{height: 45}}/>*/}
                                            {/*                </div>*/}
                                            {/*                <span class="col-7" style={{padding: 15}}>Төрийн захиргааны удирдлагаар хангах, бүтцийн дотоод зохицуулалт, үйлчилгээг эрхлэх, салбарын хүний нөөцийн бодлогыг төлөвлөх, хэрэгжүүлэх, салбарын хууль, эрх зүйн орчныг боловсронгуй болгоход дэмжлэг үзүүлэх.</span>*/}
                                            {/*                <div className="col-3" style={{borderLeft: "1px solid #1453B5", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "Center"}}>*/}
                                            {/*                    <span style={{fontWeight: "bold", color: "#1453B5", fontSize: "35px"}}>45%</span>*/}
                                            {/*                    <span>Биелэлт</span>*/}
                                            {/*                </div>*/}
                                            {/*            </div>*/}
                                            {/*        </div>*/}
                                            {/*        <div className="row" style={{marginTop: 50, marginLeft: 50, paddingRight: 50}}>*/}
                                            {/*            <div className="col-2" style={{backgroundColor: "white", display: "flex",justifyContent: "center", alignItems: "center", borderRadius: "5px", borderBottomRightRadius: 0, borderTopRightRadius: 0, borderLeft: "1px solid #1453B5", borderTop: "1px solid #1453B5",borderBottom: "1px solid #1453B5", borderRight: 0, padding: 15}}>*/}
                                            {/*                <p style={{fontSize: 21}}> Зорилт 2</p>*/}
                                            {/*            </div>*/}
                                            {/*            <div className="row col-10" style={{backgroundColor: "white", border: "1px solid #1453B5", borderRadius: 5,borderTopLeftRadius: 0, borderBottomLeftRadius: 0, display: "flex", alignItems: "center", marginTop: 0}}>*/}
                                            {/*                <div className="col-2" style={{padding: 15, display: "flex", justifyContent: "center"}}>*/}
                                            {/*                    <img className="" src={PurposeIcon} style={{height: 45}}/>*/}
                                            {/*                </div>*/}
                                            {/*                <span class="col-7" style={{padding: 15}}>Төрийн захиргааны удирдлагаар хангах, бүтцийн дотоод зохицуулалт, үйлчилгээг эрхлэх, салбарын хүний нөөцийн бодлогыг төлөвлөх, хэрэгжүүлэх, салбарын хууль, эрх зүйн орчныг боловсронгуй болгоход дэмжлэг үзүүлэх.</span>*/}
                                            {/*                <div className="col-3" style={{borderLeft: "1px solid #1453B5", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "Center"}}>*/}
                                            {/*                    <span style={{fontWeight: "bold", color: "#1453B5", fontSize: "35px"}}>57%</span>*/}
                                            {/*                    <span>Биелэлт</span>*/}
                                            {/*                </div>*/}
                                            {/*            </div>*/}
                                            {/*        </div>*/}
                                            {/*        <div className="row" style={{ marginTop: 50, backgroundColor: "white"}}>*/}
                                            {/*            <div className="col-2" style={{display: "flex",justifyContent: "center", alignItems: "center", borderRadius: "5px", borderBottomRightRadius: 0, borderTopRightRadius: 0, borderLeft: "1px solid #1453B5", borderTop: "1px solid #1453B5",borderBottom: "1px solid #1453B5", borderRight: 0, padding: 15}}>*/}
                                            {/*                <p style={{fontSize: 21}}> Зорилго 2</p>*/}
                                            {/*            </div>*/}
                                            {/*            <div className="row col-10" style={{border: "1px solid #1453B5", borderRadius: 5,borderTopLeftRadius: 0, borderBottomLeftRadius: 0, display: "flex", alignItems: "center", marginTop: 0}}>*/}
                                            {/*                <div className="col-2" style={{padding: 15, display: "flex", justifyContent: "center"}}>*/}
                                            {/*                    <img className="" src={GoalIcon} style={{height: 45}}/>*/}
                                            {/*                </div>*/}
                                            {/*                <span class="col-7" style={{padding: 15}}>Хүнс, хөдөө аж ахуй, хөнгөн үйлдвэрийн яамны үйл ажиллагааны эрхэм зорилго нь салбарын бодлогыг оновчтой тодорхойлж хэрэгжүүлэх замаар түүхий эдийн нөөцийг зохистой ашиглах, импортыг орлох, экспортын баримжаатай бүтээгдэхүүний үйлдвэрлэл.</span>*/}
                                            {/*                <div className="col-3" style={{borderLeft: "1px solid #1453B5", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "Center"}}>*/}
                                            {/*                    <span style={{fontWeight: "bold", color: "#1453B5", fontSize: "40px"}}>86%</span>*/}
                                            {/*                    <span>Биелэлт</span>*/}
                                            {/*                </div>*/}
                                            {/*            </div>*/}
                                            {/*        </div>*/}
                                            {/*        <div className="row" style={{ marginTop: 50, marginLeft: 50, paddingRight: 50}}>*/}
                                            {/*            <div className="col-2" style={{backgroundColor: "white", display: "flex",justifyContent: "center", alignItems: "center", borderRadius: "5px", borderBottomRightRadius: 0, borderTopRightRadius: 0, borderLeft: "1px solid #1453B5", borderTop: "1px solid #1453B5",borderBottom: "1px solid #1453B5", borderRight: 0, padding: 15}}>*/}
                                            {/*                <p style={{fontSize: 21}}> Зорилт 1</p>*/}
                                            {/*            </div>*/}
                                            {/*            <div className="row col-10" style={{backgroundColor: "white", border: "1px solid #1453B5", borderRadius: 5,borderTopLeftRadius: 0, borderBottomLeftRadius: 0, display: "flex", alignItems: "center", marginTop: 0}}>*/}
                                            {/*                <div className="col-2" style={{padding: 15, display: "flex", justifyContent: "center"}}>*/}
                                            {/*                    <img className="" src={PurposeIcon} style={{height: 45}}/>*/}
                                            {/*                </div>*/}
                                            {/*                <span class="col-7" style={{padding: 15}}>Төрийн захиргааны удирдлагаар хангах, бүтцийн дотоод зохицуулалт, үйлчилгээг эрхлэх, салбарын хүний нөөцийн бодлогыг төлөвлөх, хэрэгжүүлэх, салбарын хууль, эрх зүйн орчныг боловсронгуй болгоход дэмжлэг үзүүлэх.</span>*/}
                                            {/*                <div className="col-3" style={{borderLeft: "1px solid #1453B5", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "Center"}}>*/}
                                            {/*                    <span style={{fontWeight: "bold", color: "#1453B5", fontSize: "35px"}}>45%</span>*/}
                                            {/*                    <span>Биелэлт</span>*/}
                                            {/*                </div>*/}
                                            {/*            </div>*/}
                                            {/*        </div>*/}
                                            {/*        <div className="row" style={{ marginTop: 50, marginLeft: 50, paddingRight: 50}}>*/}
                                            {/*            <div className="col-2" style={{backgroundColor: "white", display: "flex",justifyContent: "center", alignItems: "center", borderRadius: "5px", borderBottomRightRadius: 0, borderTopRightRadius: 0, borderLeft: "1px solid #1453B5", borderTop: "1px solid #1453B5",borderBottom: "1px solid blue", borderRight: 0, padding: 15}}>*/}
                                            {/*                <p style={{fontSize: 21}}> Зорилт 2</p>*/}
                                            {/*            </div>*/}
                                            {/*            <div className="row col-10" style={{backgroundColor: "white", border: "1px solid #1453B5", borderRadius: 5,borderTopLeftRadius: 0, borderBottomLeftRadius: 0, display: "flex", alignItems: "center", marginTop: 0}}>*/}
                                            {/*                <div className="col-2" style={{padding: 15, display: "flex", justifyContent: "center"}}>*/}
                                            {/*                    <img className="" src={PurposeIcon} style={{height: 45}}/>*/}
                                            {/*                </div>*/}
                                            {/*                <span class="col-7" style={{padding: 15}}>Төрийн захиргааны удирдлагаар хангах, бүтцийн дотоод зохицуулалт, үйлчилгээг эрхлэх, салбарын хүний нөөцийн бодлогыг төлөвлөх, хэрэгжүүлэх, салбарын хууль, эрх зүйн орчныг боловсронгуй болгоход дэмжлэг үзүүлэх.</span>*/}
                                            {/*                <div className="col-3" style={{borderLeft: "1px solid #1453B5", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "Center"}}>*/}
                                            {/*                    <span style={{fontWeight: "bold", color: "#1453B5", fontSize: "35px"}}>57%</span>*/}
                                            {/*                    <span>Биелэлт</span>*/}
                                            {/*                </div>*/}
                                            {/*            </div>*/}
                                            {/*        </div>*/}
                                            {/*    </div>*/}
                                            {/*</div>*/}






                                            {/*// <div className="row " style={{}}>*/}
                                            {/*//     <div className="col-5" style={{padding: 15, paddingTop: 0}}>*/}
                                            {/*//         <div style={{borderRadius: 5, backgroundColor: "white", padding: 20, boxShadow: "10px 10px 15px 0px #dad9d9"}}>*/}
                                            {/*//             <h1 style={{color: "black", fontWeight: 400}}>Товч танилцуулга</h1>*/}
                                            {/*//             <br/>*/}
                                            {/*//             <p>Хүнс, хөдөө аж ахуй, хөнгөн үйлдвэрийн яамны шинжлэх ухаан, технологийн салбар зөвлөлийг шинэчлэн байгуулах зорилгоор зөвлөлийн бүрэлдэхүүн, дүрмийг шинэчлэн боловсруулж Сайдын 2018 оны 03 дугаар сарын 28-ны өдрийн А-58 дүгээр тушаалаар батлуулсан бөгөөд байнгын үйл ажиллагаа явуулж ирсэн. Тус зөвлөл нь салбарт хэрэгжүүлэх шинжлэх ухаан, технологийн төслийн хүрээнд шийдвэрлүүлэх шаардлагатай асуудлыг тодорхойлон хэлэлцэж төслийн захиалгыг БСШУСЯ-д хүргүүлдэг. Мөн салбарт хэрэгжүүлж дууссан шинжлэх ухаан, технологийн болон цөм технологийн төслийн үр дүнг хэлэлцэн хүлээн авах асуудлыг шийдвэрлэж байна.</p>*/}
                                            {/*//         </div>*/}
                                            {/*//     </div>*/}
                                            {/*//     <div className="col-7">*/}
                                            {/*//         <div className="row mt-0">*/}
                                            {/*//             <div className="col-6" style={{padding: 20}}>*/}
                                            {/*//                 <div style={{ padding: 15, borderRadius: 5, textAlign: "center", }}>*/}
                                            {/*//                     <img src={GoalIcon} style={{height: 60, backgroundColor: "#336eb31f", borderRadius: 5, padding: 10}}/>*/}
                                            {/*//                     <p className="custom-card-title">Зорилго</p>*/}
                                            {/*//                     <br/>*/}
                                            {/*//                     <p>Хүнс, хөдөө аж ахуй, хөнгөн үйлдвэрийн яамны үйл ажиллагааны эрхэм зорилго нь салбарын бодлогыг оновчтой тодорхойлж хэрэгжүүлэх замаар түүхий эдийн нөөцийг зохистой ашиглах, импортыг орлох, экспортын баримжаатай бүтээгдэхүүний үйлдвэрлэл.</p>*/}
                                            {/*//                 </div>*/}
                                            {/*//             </div>*/}
                                            {/*//             <div className="col-6" style={{padding: 20}} >*/}
                                            {/*//                 <div style={{padding: 15, borderRadius: 5, textAlign: "center"}}>*/}
                                            {/*//                     <img src={VisionIcon} style={{height: 60, backgroundColor: "#336eb31f", borderRadius: 5, padding: 10}}/>*/}
                                            {/*//                     <p className="custom-card-title">Алсын хараа</p>*/}
                                            {/*//                     <br/>*/}
                                            {/*//                     <p style={{color: "#666666"}}>Хүнс, хөдөө аж ахуй, хөнгөн үйлдвэр, жижиг, дунд үйлдвэр, хоршоо, дотоод худалдаа, үйлчилгээний салбарыг олон улсын зах зээлд өрсөлдөхүйц хөгжлийн шинэ шатанд гаргаж эдийн засгийн өсөлтийг нэмэгдүүлнэ.</p>*/}
                                            {/*//                 </div>*/}
                                            {/*//             </div>*/}
                                            {/*//         </div>*/}
                                            {/*//         <div className="row mt-3">*/}
                                            {/*//             <div className="col-6" style={{padding: 20}}>*/}
                                            {/*//                 <div style={{ padding: 15, borderRadius: 5, textAlign: "center"}}>*/}
                                            {/*//                     <img src={PurposeIcon} style={{height: 60, backgroundColor: "#336eb31f", borderRadius: 5,padding: 10}}/>*/}
                                            {/*//                     <p className="custom-card-title">Зорилт</p>*/}
                                            {/*//                     <br/>*/}
                                            {/*//                     <p>Төрийн захиргааны удирдлагаар хангах, бүтцийн дотоод зохицуулалт, үйлчилгээг эрхлэх, салбарын хүний нөөцийн бодлогыг төлөвлөх, хэрэгжүүлэх, салбарын хууль, эрх зүйн орчныг боловсронгуй болгоход дэмжлэг үзүүлэх.</p>*/}
                                            {/*//                 </div>*/}
                                            {/*//             </div>*/}
                                            {/*//             <div className="col-6" style={{padding: 20}}>*/}
                                            {/*//                 <div style={{borderRadius: 5, padding: 15, textAlign: "center"}}>*/}
                                            {/*//                     <img src={PurposeIcon} style={{height: 60, backgroundColor: "#336eb31f", borderRadius: 5, padding: 10}}/>*/}
                                            {/*//                     <p className="custom-card-title">Зорилт</p>*/}
                                            {/*//                     <br/>*/}
                                            {/*//                     <p>Төрийн захиргааны болон хүний нөөцийн удирдлагаар хангах, салбарын хүний нөөцийн бодлогыг төлөвлөх, хэрэгжилтийг зохион байгуулах, төрийн албан хаагчдын ажиллах нөхцөл, нийгмийн баталгааг хангах, хууль тогтоомжшийдвэрийн биелэлтийн тайланг гаргах.</p>*/}
                                            {/*//                 </div>*/}
                                            {/*//             </div>*/}
                                            {/*//         </div>*/}
                                            {/*//     </div>*/}
                                            </div>










































                                            : selectedItemKeys === 2 ?
                                                <div  className='col-12' style={{display: 'flex', flexDirection:'row'}}>
                                                    <div className="col-4" style={{padding: 20}}>
                                                        <div style={{height:'550px',background:'white',borderRadius:25}}>
                                                            <img src={pattern1} style={{width:'100%',borderRadius:25,height:'45%'}}/>
                                                            <p style={{fontSize:26,fontWeight:600,color:'black',marginLeft:'25px',padding: 10}}>
                                                                Энд гарчиг байна
                                                                </p>
                                                            <div>
                                                                <p style={{marginLeft:'25px',padding: 5}}>
                                                                    Мэдээний талаарх товч мэдээлэл энд байрлана.
                                                                </p>
                                                                <p style={{padding:10}}>
                                                                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
                                                                </p>
                                                            </div>
                                                            <div style={{marginLeft:'215px',marginTop:'10px'}}>
                                                                <Button
                                                                    width={200}
                                                                    onClick={() => {setPopupVisible(true)}}
                                                                    text="Дэлгэрэнгүй"
                                                                    stylingMode="text"
                                                                    icon="chevrondoubleright"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-4" style={{padding: 20}}>
                                                        <div style={{height:'550px',background:'white',borderRadius:25}}>
                                                            <img src={pattern} style={{width:'100%',borderRadius:25,height:'45%'}}/>
                                                            <p style={{fontSize:26,fontWeight:600,color:'black',marginLeft:'25px',padding: 10}}>
                                                                Энд гарчиг байна
                                                            </p>
                                                            <div>
                                                                <p style={{marginLeft:'25px',padding: 5}}>
                                                                    Мэдээний талаарх товч мэдээлэл энд байрлана.
                                                                </p>
                                                                <p style={{padding:10}}>
                                                                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
                                                                </p>
                                                            </div>
                                                            <div style={{marginLeft:'215px',marginTop:'10px'}}>
                                                                <Button
                                                                    width={200}
                                                                    onClick={() => {setPopupVisible(true)}}
                                                                    text="Дэлгэрэнгүй"
                                                                    stylingMode="text"
                                                                    icon="chevrondoubleright"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                        <div className="col-4" style={{padding: 20}}>
                                                            <div style={{height:'550px',background:'white',borderRadius:25}}>
                                                                <img src={pattern2} style={{width:'100%',borderRadius:25,height:'45%'}}/>
                                                                <p style={{fontSize:26,fontWeight:600,color:'black',marginLeft:'25px',padding: 10}}>
                                                                    Энд гарчиг байна
                                                                </p>
                                                                <div>
                                                                    <p style={{marginLeft:'25px',padding: 5 }}>
                                                                        Мэдээний талаарх товч мэдээлэл энд байрлана.
                                                                    </p>
                                                                    <p style={{padding:10}}>
                                                                        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
                                                                    </p>
                                                                </div>
                                                                <div style={{marginLeft:'215px',marginTop:'10px'}}>
                                                                    <Button
                                                                        width={200}
                                                                        onClick={() => {setPopupVisible(true)}}
                                                                        text="Дэлгэрэнгүй"
                                                                        stylingMode="text"
                                                                        icon="chevrondoubleright"

                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    <Popup
                                                        visible = {popupVisible}
                                                        onHiding = {() => {
                                                            console.log('form: ')
                                                            visible("false");
                                                        }}
                                                        dragEnabled={false}
                                                        hideOnOutsideClick={true}
                                                        showTitle={true}
                                                        title="Мэдээний товчхон гарчиг энд байнаа"
                                                        width={1200}
                                                        height={800}
                                                    />
                                                    </div>



                                            // <div style={{maxWidth:'70%'}}>
                                            //     <DetailSlideComps>
                                            //         <div>
                                            //             <NewsBox/>
                                            //             <div style={{background:'#FFFFFF',width:"600px",marginLeft:'15px',textAlign:'left'}}>
                                            //                 <h1 style={{fontWeight:500,marginLeft:'30px'}}> Энд гарчиг байрлана.</h1>
                                            //                 <span>
                                            //                     Мэдээний товч тайлбар энд байрлана.(There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, )
                                            //                 </span>
                                            //                 {/*<span>asdad</span>*/}
                                            //             </div>
                                            //             {/*<LogoText>Мэдээлэл энд гарна</LogoText>*/}
                                            //         </div>
                                            //         <div>
                                            //             <NewsBox/>
                                            //             <div style={{background:'#FFFFFF',width:"600px",marginLeft:'15px',textAlign:'left'}}>
                                            //                 <h1 style={{fontWeight:500,marginLeft:'30px'}}> Энд гарчиг байрлана.</h1>
                                            //                 <span>
                                            //                     Мэдээний товч тайлбар энд байрлана.(There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, )
                                            //                 </span>
                                            //             </div>
                                            //             {/*<span>asdad</span>*/}
                                            //             {/*<LogoText>Мэдээлэл энд гарна</LogoText>*/}
                                            //         </div>
                                            //         <div>
                                            //             <NewsBox/>
                                            //             <div style={{background:'#FFFFFF',width:"600px",marginLeft:'15px',textAlign:'left'}}>
                                            //                 <h1 style={{fontWeight:500,marginLeft:'30px'}}> Энд гарчиг байрлана.</h1>
                                            //                 <span>
                                            //                     Мэдээний товч тайлбар энд байрлана.(There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, )
                                            //                 </span>
                                            //             </div>
                                            //             {/*<span>asdad</span>*/}
                                            //             {/*<LogoText>Мэдээлэл энд гарна</LogoText>*/}
                                            //         </div>
                                            //
                                            //         {/*<div>*/}
                                            //         {/*    <NewsBox/>*/}
                                            //         {/*    <div style={{background:'#FFFFFF',width:"600px",marginLeft:'15px',textAlign:'left',borderRadius:'1'}}>*/}
                                            //         {/*        <h1 style={{fontWeight:500,marginLeft:'30px'}}> Энд гарчиг байрлана.</h1>*/}
                                            //         {/*        <div style={{marginLeft:'30px'}}>*/}
                                            //         {/*            <span >*/}
                                            //         {/*                Мэдээний товч тайлбар энд байрлана.(There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, )*/}
                                            //         {/*            </span>*/}
                                            //         {/*        </div>*/}
                                            //         {/*    </div>*/}
                                            //         {/*    /!*<span>asdad</span>*!/*/}
                                            //         {/*    /!*<LogoText>Мэдээлэл энд гарна</LogoText>*!/*/}
                                            //         {/*</div>*/}
                                            //     </DetailSlideComps>
                                            // </div>
                                            :
                                                <div>
                                                    3
                                                </div>

                                    }
                                    {/*{*/}
                                    {/*    budgetDataType === "list" ?*/}
                                    {/*            <div className="" style={{display: ""}}>*/}
                                    {/*                {*/}
                                    {/*                    budgetData.length > 0 ? budgetData.map(x => {*/}
                                    {/*                        return (*/}
                                    {/*                                x.name === "purpose" ?*/}
                                    {/*                                    <div className="card col-12" style={{padding: "20px", textAlign: "center"}}>*/}
                                    {/*                                        <img src={PurposeIcon} style={{height: 100, width: 100, marginBottom: 10}}/>*/}
                                    {/*                                        <div style={{fontSize: 22, fontWeight: 500}}>{x.description}</div>*/}
                                    {/*                                        <div style={{fontSize: 16, color: "#666666", marginTop: 15}} dangerouslySetInnerHTML={{__html: x.value}} />*/}
                                    {/*                                    </div>*/}
                                    {/*                                :*/}
                                    {/*                                    <div className="card col-12" style={{padding: "20px"}}>*/}
                                    {/*                                        <div style={{fontSize: 22, fontWeight: 500, letterSpacing: 1}}>{x.description}</div>*/}
                                    {/*                                        <div style={{fontSize: 16, color: "#666666", marginTop: 15}} dangerouslySetInnerHTML={{__html: x.value}} />*/}
                                    {/*                                    </div>*/}
                                    {/*                        )*/}
                                    {/*                    }) : ""*/}
                                    {/*                }*/}
                                    {/*            </div>*/}
                                    {/*    :*/}
                                    {/*        <div className="" style={{display: "flex", flexWrap: "wrap"}}>*/}
                                    {/*            {*/}
                                    {/*                budgetData ? budgetData.map(x => {*/}
                                    {/*                    return (*/}

                                    {/*                        // selectedItemKeys === 1 ?*/}
                                    {/*                        //     <div className="row" style={{ padding: "5px", textAlign: "center", display: "flex", alignItems: "center"}}>*/}
                                    {/*                        //         <span className="col-2" style={{fontSize: 16, color: "#ababab"}} >{x.description}</span>*/}
                                    {/*                        //         <span className="col-3" style={{fontSize: 16, color: "black"}}>{x.value}</span>*/}
                                    {/*                        //     </div>*/}
                                    {/*                        //     :*/}
                                    {/*                                <div className="card pattern" style={{ padding: "20px", textAlign: "center"}}>*/}
                                    {/*                                    {*/}
                                    {/*                                        budgetData[0].name === "vision" ?*/}
                                    {/*                                            <img src={VisionIcon} style={{height: 100, width: 100, marginBottom: 10}}/>*/}
                                    {/*                                            : ""*/}
                                    {/*                                    }*/}
                                    {/*                                    <div style={{fontSize: 21, fontWeight: 500, color: "#ababab"}} >{x.description}</div>*/}
                                    {/*                                    <div style={{fontSize: 16, color: "black", marginTop: 10}} dangerouslySetInnerHTML={{__html: x.value}} />*/}
                                    {/*                                </div>*/}
                                    {/*                    )*/}
                                    {/*                }) : ""*/}
                                    {/*            }*/}
                                    {/*        </div>*/}
                                    {/*}*/}

                                {/*</ScrollView>*/}
                        </div>
                    </div>
                </div>


            </div>
        </div>
    )
}
export  default  Detail;
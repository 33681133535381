import OrganizationService from "../../../services/api/organization";
import React, {useRef, useState} from "react";
import DropDownBox from "devextreme-react/drop-down-box";
import TreeView from "devextreme-react/tree-view";
import DataGrid, {
    Column,
    FilterRow,
    Grouping,
    GroupPanel,
    HeaderFilter,
    Paging,
    Scrolling,
    Selection
} from "devextreme-react/data-grid";
import {FileUploader, SelectBox, TextBox} from "devextreme-react";
import HtmlEditor, {Item, Toolbar} from "devextreme-react/html-editor";
import LoadPanelComponent from "../../budget/components/loadPanel";
import ScrollView from "devextreme-react/scroll-view";
import Popup from "devextreme-react/popup";
import EmailService from "../../../services/api/email";
import fileTypeService from "../../../services/api/fileType";
import FileServices from "../../../services/api/file";
import EmailFileService from "../../../services/api/emailFile";
import Button from "devextreme-react/button";
import {RequiredRule, Validator} from "devextreme-react/validator";
import {urlServer} from "../../../shared/lib/request";

const AddEmail = ({ popupVisible, setPopupVisible, setEmails }) => {

    const [organizations, setOrganizations] = useState([]);
    const [workers, setWorkers] = useState([]);
    const [treeView, setTreeView] = useState(null);
    const [treeBoxValue, setTreeBoxValue] = useState(null);
    const [isTreeBoxOpened, setIsTreeBoxOpened] = useState(false);
    const [gridBoxValue, setGridBoxValue] = useState([]);
    const sizeValues = ["8pt", "10pt", "12pt", "14pt", "18pt", "24pt", "36pt"];
    const fontValues = [
        "Arial",
        "Courier New",
        "Georgia",
        "Impact",
        "Lucida Console",
        "Tahoma",
        "Times New Roman",
        "Verdana",
        "Montserrat"
    ];
    const fontOptions = {placeholder: "Фонт"};
    const headerValues = [false, 1, 2, 3, 4, 5];
    const htmlRef = useRef(null);
    const htmlRef1 = useRef(null);
    const fileRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [fileTypes, setFileTypes] = useState([]);
    const selectRef = useRef(null);
    const addFormRef = useRef(null);
    const addFormOrgValidatorRef = useRef(null);
    const addFormWorkerValidatorRef = useRef(null);
    const addFormTitleValidatorRef = useRef(null);
    const addFormContentValidatorRef = useRef(null);
    const imageRef = useRef(null);
    const [imagePopup, setImagePopup] = useState(false);
    const [images, setImages] = useState([]);
    const [fullScreenPopup, setFullScreenPopup] = useState(false);

    const getAll = async () => {
        try {
            const res = await EmailService.getAll();

            if (res && res.code === 200) {
                setEmails(res.data);
            }
        } catch (e) {
            console.log(e);
        }
    };

    const getOrganizationIncludeParent = async () => {
        try {
            const res = await OrganizationService.getOrganizationIncludeParent();

            if (res && res.code === 200) {
                setOrganizations(res.data);
            }
        } catch (e) {
            console.log(e);
        }
    }

    const getAllFileType = async () => {
        try {
            const res = await fileTypeService.getAllFileType();

            if (res && res.code === 200) {
                setFileTypes(res.data);
            }
        } catch (e) {
            console.log(e);
        }
    }

    const getWorker = async () => {
        const data = await OrganizationService.getOrgWorker(treeBoxValue,null,0,0,'');
        setWorkers(data.data);
    }

    const treeViewRender = () => {
        return (
            <TreeView dataSource={organizations}
                      ref={(ref) => { setTreeView(ref) }}
                      dataStructure="plain"
                      keyExpr="id"
                      parentIdExpr="parent"
                      selectionMode="multiple"
                      displayExpr="org_name"
                      selectByClick={true}
                      showCheckBoxesMode="normal"
                      selectNodesRecursive={false}
                      onContentReady={treeViewOnContentReady}
                      onItemClick={onTreeItemClick}
                      onItemSelectionChanged={treeViewItemSelectionChanged}
            />
        );
    }

    const syncTreeViewSelection = (e) => {
        setTreeBoxValue(e.value);

        if (!treeView) return;

        if (!e.value) {
            treeView.instance.unselectAll();
        } else {
            treeView.instance.selectItem(e.value);
        }
    }

    const treeViewItemSelectionChanged = (e) => {
        setTreeBoxValue(e.component.getSelectedNodeKeys());
    }

    const treeViewOnContentReady = (e) => {
        e.component.selectItem(treeBoxValue);
    }

    const onTreeItemClick = () => {
        setIsTreeBoxOpened(false);
    }

    const onTreeBoxOpened = (e) => {
        if (e.name === 'opened') {
            setIsTreeBoxOpened(e.value);
        }
    }

    const onTreeBoxClosed = () => {
        if (treeBoxValue !== null && treeBoxValue.length > 0) {
            getWorker();
        } else {
            setWorkers([]);
        }
    }

    const dataGridRender = () => {
        return (
            <DataGrid
                keyExpr="wid"
                columnAutoWidth={false}
                allowColumnResizing={true}
                wordWrapEnabled={true}
                showColumnLines={true}
                showRowLines={true}
                rowAlternationEnabled={true}
                height={500}
                dataSource={workers}
                hoverStateEnabled={false}
                selectedRowKeys={gridBoxValue}
                onSelectionChanged={dataGridOnSelectionChanged}>
                <Selection mode="multiple" />
                <Scrolling mode="virtual" />
                <GroupPanel visible={true} emptyPanelText={"Хүссэн баганаа дээш зөөн бүлэглэх боломжтой"}/>
                <Grouping autoExpandAll={false} />
                <HeaderFilter visible={true} allowSearch={true} />
                <Paging enabled={true} pageSize={10} />
                <FilterRow visible={true} />
                <Column
                    caption="Ерөнхий газар"
                    dataField="state_structure_name"
                    width={150}
                    groupIndex={0}
                />
                <Column
                    caption="Алба хэлтэс"
                    dataField="org_structure_name"
                    width={150}
                    groupIndex={1}
                />
                <Column
                    caption="Овог"
                    dataField="last_name"
                    width={150}
                />
                <Column
                    caption="Нэр"
                    dataField="first_name"
                    width={150}
                />
                <Column
                    caption="Цахим шуудан"
                    dataField="email"
                    width={200}
                />
                <Column
                    caption="Албан тушаал"org_position_name
                    dataField=""
                    width={150}
                />
                <Column
                    caption="Утас"
                    dataField="phone"
                    width={100}
                    alignment="center"
                />
            </DataGrid>
        );
    }

    const syncDataGridSelection = (e) => {
        setGridBoxValue(e.value || []);
    }

    const dataGridOnSelectionChanged = (e) => {
        setGridBoxValue((e.selectedRowKeys.length && e.selectedRowKeys) || []);
        // console.log(gridBoxValue);
    }

    const onSubmit = async (event) => {
        event.preventDefault();

        setIsLoading(true);

        const email = {};

        email.title = event.target.title.value;
        email.content = event.target.content.value;
        email.orgs = treeBoxValue;
        email.workers = gridBoxValue;

        if (event.target.file.files.length) {
            email.file_type_id = event.target.file_type.value;
        }

        const res = await EmailService.addEmail(email);

        if (res && res.code === 200) {
            for (let i = 0; i < event.target.file.files.length; i++) {
                let data = new FormData();

                data.append("file", event.target.file.files[i]);

                const res1 = await FileServices.uploadFileManagement(data, event.target.file_type.value);

                let emailFile = {};

                emailFile.file_id = res1.data.id;
                emailFile.email_id = res.data.id;

                await EmailFileService.addEmailFile(emailFile);
            }

            await EmailService.sendEmail(res.data.id);

            getAll();
        }

        setTreeBoxValue(null);
        setGridBoxValue(null);
        setOrganizations([]);
        addFormRef.current.reset();
        addFormOrgValidatorRef.current.instance.reset();
        addFormWorkerValidatorRef.current.instance.reset();
        addFormTitleValidatorRef.current.instance.reset();
        addFormContentValidatorRef.current.instance.reset();

        fileRef.current.instance.reset();
        selectRef.current.instance.reset();
        htmlRef.current.instance.reset();

        setPopupVisible(false);
        setIsLoading(false);
    }

    const onShowing = () => {
        getOrganizationIncludeParent();
        getAllFileType();
    }

    const onHiding = () => {
        setTreeBoxValue(null);
        setGridBoxValue(null);
        setOrganizations([]);
        addFormRef.current.reset();
        addFormOrgValidatorRef.current.instance.reset();
        addFormWorkerValidatorRef.current.instance.reset();
        addFormTitleValidatorRef.current.instance.reset();
        addFormContentValidatorRef.current.instance.reset();

        fileRef.current.instance.reset();
        selectRef.current.instance.reset();
        htmlRef.current.instance.reset();

        setPopupVisible(false);
    }

    const onValueChangedImage = (e) => {
        setImages(e.value);
    }

    const onHidingImagePopup = () => {
        imageRef.current.instance.reset();
        setImages([]);
        setImagePopup(false);
    }

    const onClickImageUpload = async () => {
        for (let i = 0; i < images.length; i++) {
            let data = new FormData();

            data.append("file", images[i]);

            const res = await FileServices.uploadFileManagement(data, null);

            if (res && res.code === 200) {
                const newContent = `<img src=${urlServer}/api/file/${res.data.file_name} width="100%" height="100%" />`;

                let htmlEditorInstance;

                if (fullScreenPopup) {
                    htmlEditorInstance = htmlRef1.current.instance;
                } else {
                    htmlEditorInstance = htmlRef.current.instance;
                }

                const currentValue = htmlEditorInstance.option('value');

                if (currentValue) {
                    htmlEditorInstance.option('value', currentValue + newContent);
                } else {
                    htmlEditorInstance.option('value', newContent);
                }
            }
        }

        imageRef.current.instance.reset();
        setImages([]);
        setImagePopup(false);
    }

    const toolbarImageOptions = {
        icon: 'image',
        stylingMode: 'icon',
        onClick: () => {
            setImagePopup(true);
        }
    };

    const toolbarFullScreenOptions = {
        icon: 'fullscreen',
        stylingMode: 'text',
        onClick: () => {
            if (fullScreenPopup) {
                const htmlEditorInstance1 = htmlRef1.current.instance;
                const currentValue = htmlEditorInstance1.option('value');

                const htmlEditorInstance = htmlRef.current.instance;

                htmlEditorInstance.option('value', currentValue);

                setFullScreenPopup(false);
            } else {
                const htmlEditorInstance = htmlRef.current.instance;
                const currentValue = htmlEditorInstance.option('value');

                const htmlEditorInstance1 = htmlRef1.current.instance;

                htmlEditorInstance1.option('value', currentValue);

                setFullScreenPopup(true);
            }
        },
    };

    const onHidingFullScreen = () => {
        const htmlEditorInstance1 = htmlRef1.current.instance;
        const currentValue = htmlEditorInstance1.option('value');

        const htmlEditorInstance = htmlRef.current.instance;

        htmlEditorInstance.option('value', currentValue);

        htmlRef1.current.instance.reset();
        setFullScreenPopup(false);
    }

    useState(() => {
        getOrganizationIncludeParent();
        getAllFileType();
    });

    return (
        <div>
            <Popup
                visible={popupVisible}
                onHiding={onHiding}
                onShowing={onShowing}
                dragEnabled={false}
                hideOnOutsideClick={true}
                showCloseButton={true}
                showTitle={true}
                title="Имэйл үүсгэх"
                width="65%"
                height="90vh"
            >
                <ScrollView width="100%" height="100%">
                    <LoadPanelComponent
                        position={{of: "#renderCreateMenu"}}
                        visible={isLoading}
                    />
                    <form ref={addFormRef} onSubmit={onSubmit}>
                        <div className="dx-fieldset">
                            <div className="dx-field">
                                <div className="dx-field-label" style={{width: "20%"}}>Байгууллага: <span style={{color: 'red'}}>*</span></div>
                                <div className="dx-field-value" style={{width: "80%"}}>
                                    <DropDownBox
                                        value={treeBoxValue}
                                        opened={isTreeBoxOpened}
                                        valueExpr="id"
                                        displayExpr="org_name"
                                        placeholder="Байгууллага сонгох..."
                                        showClearButton={false}
                                        dataSource={organizations}
                                        onValueChanged={syncTreeViewSelection}
                                        onOptionChanged={onTreeBoxOpened}
                                        onClosed={onTreeBoxClosed}
                                        contentRender={treeViewRender}
                                    >
                                        <Validator ref={addFormOrgValidatorRef}>
                                            <RequiredRule
                                                message="Байгууллага сонгоно уу!" />
                                        </Validator>
                                    </DropDownBox>
                                </div>
                            </div>
                            <div className="dx-field">
                                <div className="dx-field-label" style={{width: "20%"}}>Ажилтан: <span style={{color: 'red'}}>*</span></div>
                                <div className="dx-field-value" style={{width: "80%"}}>
                                    <DropDownBox
                                        value={gridBoxValue}
                                        valueExpr="wid"
                                        displayExpr={item => `[${item.last_name.charAt(0)}.${item.first_name}-${item.email}]`}
                                        showClearButton={true}
                                        dataSource={workers}
                                        deferRendering={false}
                                        onValueChanged={syncDataGridSelection}
                                        contentRender={dataGridRender}
                                    >
                                        <Validator ref={addFormWorkerValidatorRef}>
                                            <RequiredRule
                                                message="Ажилтан сонгоно уу!" />
                                        </Validator>
                                    </DropDownBox>
                                </div>
                            </div>
                            <div className="dx-field">
                                <div className="dx-field-label" style={{width: "20%"}}>Имэйлийн гарчиг: <span style={{color: 'red'}}>*</span></div>
                                <div className="dx-field-value" style={{width: "80%"}}>
                                    <TextBox name="title" placeholder="Имэйлийн гарчиг">
                                        <Validator ref={addFormTitleValidatorRef}>
                                            <RequiredRule
                                                message="Имэйлийн гарчиг бөглөнө үү!" />
                                        </Validator>
                                    </TextBox>
                                </div>
                            </div>
                            <div className="dx-field">
                                <HtmlEditor
                                    ref={htmlRef}
                                    height="500px"
                                    name="content"
                                    valueType="html"
                                >
                                    <Toolbar multiline={true}>
                                        <Item name="size" acceptedValues={sizeValues} />
                                        <Item name="separator" />
                                        <Item name="font" acceptedValues={fontValues} options={fontOptions} />
                                        <Item name="separator" />
                                        <Item name="bold" />
                                        <Item name="italic" />
                                        <Item name="strike" />
                                        <Item name="underline" />
                                        <Item name="separator" />
                                        <Item name="alignLeft" />
                                        <Item name="alignCenter" />
                                        <Item name="alignRight" />
                                        <Item name="alignJustify" />
                                        <Item name="separator" />
                                        <Item name="orderedList" />
                                        <Item name="bulletList" />
                                        <Item name="separator" />
                                        <Item name="header" acceptedValues={headerValues} />
                                        <Item name="separator" />
                                        <Item name="color" />
                                        <Item name="background" />
                                        <Item name="separator" />
                                        <Item name="link" />
                                        <Item
                                            widget="dxButton"
                                            options={toolbarImageOptions}
                                        />
                                        <Item name="separator" />
                                        <Item name="clear" />
                                        <Item name="codeBlock" />
                                        <Item name="blockquote" />
                                        <Item name="separator" />
                                        <Item name="insertTable" />
                                        <Item name="deleteTable" />
                                        <Item name="insertRowAbove" />
                                        <Item name="insertRowBelow" />
                                        <Item name="deleteRow" />
                                        <Item name="insertColumnLeft" />
                                        <Item name="insertColumnRight" />
                                        <Item name="deleteColumn" />
                                        <Item
                                            widget="dxButton"
                                            options={toolbarFullScreenOptions}
                                        />
                                    </Toolbar>
                                    <Validator ref={addFormContentValidatorRef}>
                                        <RequiredRule
                                            message="Агуулга бөглөнө үү!" />
                                    </Validator>
                                </HtmlEditor>
                            </div>
                            <div className="dx-field">
                                <div className="dx-field-label" style={{width: "20%"}}>Файлын төрөл: </div>
                                <div className="dx-field-value" style={{width: "80%"}}>
                                    <SelectBox items={fileTypes}
                                               ref={selectRef}
                                               displayExpr="name"
                                               valueExpr="id"
                                               name="file_type">
                                    </SelectBox>
                                </div>
                            </div>
                            <div className="dx-field">
                                <div className="dx-field-label" style={{width: "20%"}}>Файл: </div>
                                <div className="dx-field-value" style={{width: "80%"}}>
                                    <FileUploader name="file" ref={fileRef} selectButtonText="Файл сонгох" labelText="" multiple="true" accept="*" uploadMode="useForm" />
                                </div>
                            </div>
                            <div className="dx-field" style={{ textAlign: "center" }}>
                                <Button
                                    text="Имэйл илгээх"
                                    type="success"
                                    useSubmitBehavior={true}
                                >
                                </Button>
                            </div>
                        </div>
                    </form>
                </ScrollView>
                <Popup
                    visible={fullScreenPopup}
                    onHiding={onHidingFullScreen}
                    showCloseButton={true}
                    fullScreen={true}
                >
                    <HtmlEditor
                        ref={htmlRef1}
                        valueType="html"
                        width="100%"
                        height="100%"
                    >
                        <Toolbar multiline={true}>
                            <Item name="size" acceptedValues={sizeValues} />
                            <Item name="separator" />
                            <Item name="font" acceptedValues={fontValues} options={fontOptions} />
                            <Item name="separator" />
                            <Item name="bold" />
                            <Item name="italic" />
                            <Item name="strike" />
                            <Item name="underline" />
                            <Item name="separator" />
                            <Item name="alignLeft" />
                            <Item name="alignCenter" />
                            <Item name="alignRight" />
                            <Item name="alignJustify" />
                            <Item name="separator" />
                            <Item name="orderedList" />
                            <Item name="bulletList" />
                            <Item name="separator" />
                            <Item name="header" acceptedValues={headerValues} />
                            <Item name="separator" />
                            <Item name="color" />
                            <Item name="background" />
                            <Item name="separator" />
                            <Item name="link" />
                            <Item
                                widget="dxButton"
                                options={toolbarImageOptions}
                            />
                            <Item name="separator" />
                            <Item name="clear" />
                            <Item name="codeBlock" />
                            <Item name="blockquote" />
                            <Item name="separator" />
                            <Item name="insertTable" />
                            <Item name="deleteTable" />
                            <Item name="insertRowAbove" />
                            <Item name="insertRowBelow" />
                            <Item name="deleteRow" />
                            <Item name="insertColumnLeft" />
                            <Item name="insertColumnRight" />
                            <Item name="deleteColumn" />
                            <Item
                                widget="dxButton"
                                options={toolbarFullScreenOptions}
                            />
                        </Toolbar>
                    </HtmlEditor>
                </Popup>
            </Popup>
            <Popup
                visible={imagePopup}
                onHiding={onHidingImagePopup}
                dragEnabled={false}
                hideOnOutsideClick={true}
                showCloseButton={true}
                showTitle={true}
                title="Зураг оруулах"
                width="500"
                height="300"
            >
                <ScrollView width="100%" height="100%">
                    <FileUploader ref={imageRef} selectButtonText="Зураг сонгох" labelText="" multiple="true" accept="image/*" onValueChanged={onValueChangedImage} uploadMode="useForm" />
                    <div className="dx-field" style={{ textAlign: "center" }}>
                        <Button
                            text="Хадгалах"
                            type="success"
                            onClick={onClickImageUpload}
                        />
                    </div>
                </ScrollView>
            </Popup>
        </div>
    );
}

export default AddEmail;

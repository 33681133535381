import React, {useState, useEffect} from "react";
import DataGrid, {Column, HeaderFilter, Paging, Summary, TotalItem,Selection} from "devextreme-react/data-grid";
import notify from "devextreme/ui/notify";
import FinanceReportService from "../../../../services/api/financeReport";
import {numberWithCommas} from "../../../../util";
import LoadPanelComponent from "../../../../components/LoadPanelComponent";
import EmptyData from "../../../budget/components/empty";
const PayrollWithOrgsMonth = (props) => {
    const [dataSrc, setDataSrc] = useState([]);
    const [loader, setLoader] = useState(false);
    const loadYamsData = async () => {
        try {
            setLoader(true);
            let r = await FinanceReportService.getByOrgMonthPayrollData(props.year,props.selectedOrg);
            setDataSrc(r);
            setLoader(false);
        } catch (e) {
            setLoader(false);
            notify(e.message, "error", 2000);
        }
    }
    useEffect(() => {
        loadYamsData();
    }, [props.year, props.selectedOrg])
    const onCellPreparedHandler = (e) => {
        if (e.rowType === 'header') {
            e.cellElement.wordWrapEnabled =true;
            e.cellElement.style.color = "#15186A"
            e.cellElement.style.backgroundColor= "#E3E7F1"
            //e.cellElement.style.backgroundImage = `linear-gradient(to right, ${palettePayrollReport[0]}, ${palettePayrollReport[1]})`
        }
        if (e.rowType === 'data') {
            e.cellElement.style.color = "#15186A"
        }
    }
    return (
        <div id="load1">
            <LoadPanelComponent
                position={{of: '#load1'}}
                visible={loader}
            />
            {(dataSrc && !loader) ? (
            <DataGrid
                showBorders={true}
                rowAlternationEnabled={true}
                dataSource={dataSrc}
                noDataText = 'Дата байхгүй байна.'
                onCellPrepared={onCellPreparedHandler}
                wordWrapEnabled={true}
                style={{ fontFamily: "'Montserrat', sans-serif", fontWeight: 'bold' }}
            >
                <Paging defaultPageSize={9}/>
                <HeaderFilter visible={true}/>
                <Selection mode="single"/>
                <Column dataField="budget_month" allowSorting={false} caption="Сар" alignment="center" allowFiltering={false}/>
                <Column dataField="total_salary" format="#,##0.00" alignment="center" caption="Нийт цалин" allowFiltering={false}/>
                <Column dataField="basic_salary" format="#,##0.00" alignment="center" caption="Үндсэн цалин" allowFiltering={false}/>
                <Column dataField="estimated_salary" format="#,##0.00" alignment="center" caption="Бодогдсон цалин" allowFiltering={false}/>
                <Column dataField="additional_salary" format="#,##0.00" alignment="center" caption="Нэмэгдэл цалин" allowFiltering={false}/>
                <Column  dataField="send_date" caption="Илгээсэн огноо" allowSorting={false} alignment="center" allowFiltering={false}/>
                <Column  dataField="last_date" caption="Шинэчилсэн огноо" allowSorting={false} alignment="center" allowFiltering={false}/>
                <Summary>
                    <TotalItem
                        column="total_salary"
                        summaryType="sum"
                        customizeText={(data) => {return `${numberWithCommas(data.value)}`}}
                    />
                    <TotalItem
                        column="basic_salary"
                        summaryType="sum"
                        customizeText={(data) => {return `${numberWithCommas(data.value)}`}}
                    />
                    <TotalItem
                        column="estimated_salary"
                        summaryType="sum"
                        customizeText={(data) => {return `${numberWithCommas(data.value)}`}}
                    />
                    <TotalItem
                        column="additional_salary"
                        summaryType="sum"
                        customizeText={(data) => {return `${numberWithCommas(data.value)}`}}
                    />
                </Summary>
            </DataGrid>
                ):
                <div style={{display: "flex", justifyContent: "center", alignItems: "center", family: "'Montserrat', sans-serif" }}>
                   <EmptyData/>
                </div>
            }
        </div>
    )
}

export default PayrollWithOrgsMonth;

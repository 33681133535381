import React, {useState, useEffect} from "react";
import _ from "lodash";
import {budgetPalettes, numberWithCommas} from "../../../../util";
import Chart, {CommonSeriesSettings, Crosshair, HorizontalLine, Label, Legend, Point, Series, Tooltip, ValueAxis} from "devextreme-react/chart";
import notify from "devextreme/ui/notify";
import BudgetService from "../../../../services/api/budget";
import LoadPanelComponent from "../../../../components/LoadPanelComponent";
import EmptyData from "../../../budget/components/empty";

const TowloruulehMonthly = (props) => {
    const [chartData, setChartData] = useState([]);
    const [loader, setLoader] = useState(true);
    const loadFunc = async () => {
        try {
            setLoader(true);
            let r = await BudgetService.getTowloruulehMonthlyData(props.year, props.filterOrgText, props.ministry,props.orgId)
            setChartData(r);
        } catch (e) {
            notify(e.message, "error", 2000);
        } finally{
            setLoader(false)
        }
    }

    useEffect(() => {
        loadFunc();
    }, [props.year, props.filterOrgText, props.ministry,props.orgId])


    const customizeValueAxisLabel = (e) => {
        return `${e.value / 1000000000}`
    }

    const customizeTooltip = (arg) => {
        if (arg.seriesName === 'Төсвийн өөрчлөлт' || arg.seriesName === 'Төсөв') {
            let r = chartData[(arg.argument * 1 - 1)-1];
            let q = ((arg.value - (r && r.todotgoson) > 0 ? arg.value / (r && r.todotgoson) : (r && r.todotgoson) / arg.value)).toFixed(2);
            let p = ((arg.value * 100 / (r && r.todotgoson)) - 100).toFixed(2);
            let d = (arg.value - (r && r.todotgoson));
            let f = d < 0 ? 'бага' : 'их';

            if (isFinite(q) && !isNaN(q)) {
                return {
                    html: `<div class="tooltip-header">
                    <span>Төсөв ${arg.argumentText}-р сар:</span>
                    <span>${numberWithCommas(arg.value/1000000)}</span>
                    </div>
                    <hr/>
                    <b><i>Өмнөх сараас</i></b><br/>
                    <span class="tooltip-series-name">Зөрүү: </span> ${numberWithCommas(d / 1000000)}<br/>
                    <span class="tooltip-series-name">Хувь: </span> ${p}%<br/>
                    <span class="tooltip-series-name">Өөрчлөлт: </span> ${q} дахин ${f}<br/>
                    `
                }
            } else {
                return {
                    html: `<div class="tooltip-header">
                    <span>Төсөв ${arg.argumentText}-р сар:</span>
                    <span>${numberWithCommas(arg.value/1000000)}</span>
                    </div>`
                }
            }
        }else if  (arg.seriesName=== 'Гүйцэтгэлийн өөрчлөлт'){
            let r = chartData[(arg.argument * 1 - 1)-1];
            let q = ((arg.value - (r && r.guitsetgel) > 0 ? arg.value / (r && r.guitsetgel) : (r && r.guitsetgel) / arg.value)).toFixed(2);
            let p = ((arg.value * 100 / (r && r.guitsetgel)) - 100).toFixed(2);
            let d = (arg.value - (r && r.guitsetgel));
            let f = d < 0 ? 'бага' : 'их';
            let r1 = chartData[(arg.argument * 1)-1];
            let u = ((r1 && r1.todotgoson) - arg.value);
            let u1 = ((arg.value * 100 / (r1 && r1.todotgoson))).toFixed(2);
            if (isFinite(q) && !isNaN(q)) {
                return {
                    html: `<div class="tooltip-header">
                    <span>Гүйцэтгэл ${arg.argumentText}-р сар:</span>
                    <span>${numberWithCommas(arg.value/1000000)}</span>
                    </div>
                    <hr/>
                    <b><i>Төсвөөс</i></b><br/>
                    <span class="tooltip-series-name">Зөрүү: </span>${numberWithCommas(u / 1000000)} <br/>
                    <span class="tooltip-series-name" style={{marginBottom: 10}}>Xувь: </span>${u1}% <br/>
                    <hr/>
                    <b><i>Өмнөх сараас </b></i><br/>
                    <span class="tooltip-series-name">Зөрүү: </span>${numberWithCommas(d / 1000000)} <br/>
                    <span class="tooltip-series-name">Хувь: </span>${p}% <br/>
                    <span class="tooltip-series-name">Өөрчлөлт: </span>${q} дахин ${f}<br/>`
                }
            } else {
                return {
                    html: `<div class="tooltip-header">
                    <span>Гүйцэтгэл ${arg.argumentText}-р сар:</span>
                    <span>${numberWithCommas(arg.value/1000000)}</span>
                    </div>
                    <hr/>
                    <b><i>Төсвөөс</i></b><br/>
                    <span class="tooltip-series-name">Зөрүү: </span>${numberWithCommas(u / 1000000)} <br/>
                    <span class="tooltip-series-name" style={{marginBottom: 10}}>Xувь: </span>${u1}% <br/>`
                }
            }                           
        } else {
            return {
                html: `<div class="tooltip-header">
                    <span>${arg.seriesName} ${arg.argumentText}-р сар:</span>
                    <span>${numberWithCommas(arg.value/1000000)}</span>
                    </div>
                    <hr/>
                    <b><i>Төсвөөс</i></b><br/>
                    <span class="tooltip-series-name">Зөрүү:</span> ${numberWithCommas((arg.point.data.todotgoson-arg.value)/1000000)}<br/>
                    <span class="tooltip-series-name">Хувь: </span> ${((arg.value / 1000000) / (arg.point.data.todotgoson / 1000000) * 100).toFixed(2)}%
                `
            };
        }
    }
    if (chartData?.length === 0 && !loader) {
        return <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            family: "Segoe UI",
          }}
        >
        <EmptyData/>
        </div>
      }
      
      const handleLegend = (e) => {
        if (e.target.isVisible()) {
          e.target.hide();
        } else {
          e.target.show();
        }
      }

      function markerTemplate(item) {
        const color = item.series.isVisible() ? item.marker.fill : '#888';
        return (
            <svg>
                <rect x={0} y={0} width={12} height={12} fill={color}
                    rx={item.text.includes('өөрчлөлт') ? 6 : 0}
                    ry={item.text.includes('өөрчлөлт') ? 6 : 0}></rect>
            </svg>
        );
    }

    return (
        <div  id="load5">
            <LoadPanelComponent
                position={{of: '#load5'}}
                visible={loader}
            />
            <Chart
                dataSource={chartData}
                palette={_.find(budgetPalettes, { 'id': 2 }).color}
                paletteExtensionMode="alternate"
                onLegendClick={handleLegend}
            >
                <CommonSeriesSettings
                    argumentField="budget_month"
                    type="bar"
                    barOverlapGroup="orlogoStack"
                    cornerRadius={6}
                >
                    <Label visible={false}/>
                    <Point size={10} />
                </CommonSeriesSettings>
                <Crosshair enabled={true} label={true} />
                <ValueAxis>
                    <Label customizeText={customizeValueAxisLabel}/>
                </ValueAxis>
                <Series barPadding={0.2} stack="orlogoStack" valueField="todotgoson" name="Төсөв"/>
                <Series barPadding={0.6} stack="orlogoStack" valueField="guitsetgel"  name="Гүйцэтгэл" />
                <Series valueField="todotgoson" name="Төсвийн өөрчлөлт" visible={false} color="#4F957D" type="spline" dashStyle="solid"/>
                <Series valueField="guitsetgel"  name="Гүйцэтгэлийн өөрчлөлт" color="#264B96" type="spline" dashStyle="solid"/>
                <Legend visible={true} verticalAlignment="bottom" horizontalAlignment="center" font={{ family: "Segoe UI" }} markerRender={markerTemplate}/>
                <Tooltip zIndex={100000} enabled={true} cornerRadius={6} border={{visible: false}}
                            customizeTooltip={customizeTooltip} font={{ color: '#fff' }} color="#555" location="edge">
                    </Tooltip>
            </Chart>
        </div>
    )
}

export default TowloruulehMonthly;

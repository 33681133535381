import React, {useState, useEffect} from "react";
import notify from "devextreme/ui/notify";
import BudgetService from "../../../../services/api/budget";
import PivotGridDataSource from "devextreme/ui/pivot_grid/data_source";
import {numberWithCommas11} from "../../../../util";
import PivotGrid, {Export} from "devextreme-react/pivot-grid";
import { isNumber } from "lodash";

const BudgetOtherSix = (props) => {
    const [dataSource, setDataSource] = useState([]);
    const {year, chosenValue} = props;

    const loadData = async () => {
        try {
            let r = await BudgetService.getBudgetOtherSixth(year, chosenValue)
            const dataSource = new PivotGridDataSource({
                fields: [{
                    caption: 'Байгууллага',
                    dataField: 'name',
                    area: 'row',
                    width: "400"

                }, {
                    caption: 'agenda_name',
                    dataField: 'agenda_name',
                    area: 'row'
                }, {
                    caption: 'event_name',
                    dataField: 'event_name',
                    area: 'row'
                }, {
                    caption: 'econ_category_name_1',
                    dataField: 'econ_category_name_1',
                    area: 'row'
                }, {
                    caption: 'econ_category_name_2',
                    dataField: 'econ_category_name_2',
                    area: 'row'
                }, {
                    caption: 'econ_category_name_3',
                    dataField: 'econ_category_name_3',
                    area: 'row'
                }, {
                    caption: 'econ_category_name',
                    dataField: 'econ_category_name',
                    area: 'row'
                }, {
                    caption: 'Тоо',
                    dataField: 'amount',
                    dataType: 'number',
                    summaryType: 'sum',
                    area: 'data',
                }],
                store: r
            })
            setDataSource(dataSource);
        } catch (e) {
            notify(e.message, "error", 2000);
        }
    }

    useEffect(() => {
        loadData()
    }, [year, chosenValue])

    const onCellPrepared = (e) => {
        if (e.area === 'row' || e.area === 'data') {
            e.cellElement.style.fontFamily = 'Segoe UI';
            e.cellElement.style.fontWeight = 'semibold';
            e.cellElement.style.color = e.area === 'data' ? '#000000' : '#15186A';
            // console.log(e);
        }
        if (isNumber(e.cell.value))  
        {
            e.cellElement.innerHTML = `<span>${numberWithCommas11(e.cell.value)}</span>`
        }
    }

    return (
        <div>
            <PivotGrid
                dataSource={dataSource}
                style={{fontFamily: "Segoe UI"}}
                wordWrapEnabled={true}
                fieldChooser={false}
                onCellPrepared={onCellPrepared}
                texts={{
                    grandTotal: 'Нийт',
                    total: '{0} нийт'
                }}>
                <Export enabled={true}/>
            </PivotGrid>
        </div>
    )
}

export default BudgetOtherSix

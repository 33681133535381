import {NavLink} from "react-router-dom"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import classes from "./SidebarMenu.module.css"
import {useEffect} from "react"
import {faCircle} from "@fortawesome/free-solid-svg-icons"
import {useTranslation} from "../../hooks/useTranslation"

const SubLinks = ({item, buba, toggle, setToggle, user}) => {
  const {isMongolian} = useTranslation()
  const title = (sub) => {
    if (!isMongolian && sub?.sub_eng_name) {
      return sub?.sub_eng_name
    } else {
      return sub?.sub_name
    }
  }

  const content = item
    .sort((a, b) => a.sub_order - b.sub_order)
    .map((sub, i) => {
      return (
        <NavLink
          key={i}
          style={{
            pointerEvents: sub.parent_name === "БУСАД МОДУЛЬ" ? "none" : "fill"
          }}
          className={classes.subLink}
          to={`/${sub.sub_menu_url}`}
          activeStyle={{fontWeight: "bold"}}
          id={`${sub.parent_menu_url}_${sub.id}`}
        >
          <div
            className={`subIconContainer ${!toggle && classes.colorinvisible}`}
          >
            <FontAwesomeIcon
              icon={faCircle}
              height={"5"}
              width={"5"}
              style={{
                color: "#7b8493",
                "--fa-primary-color": "#7b8493",
                "--fa-primary-opacity": "1",
                "--fa-secondary-color": "#7b8493",
              }}
            />
          </div>
          <span
            style={{textAlign: "start" }}
            className={`subLinkLabel ${!toggle && classes.colorinvisible} ${["Хөрөнгийн зардал", "Төсвийн гүйцэтгэл", "Төсвийн тооцоолол", "Төсвийн таамаглал", "Иргэдийн оролцоо"].includes(sub.sub_name) && classes.underDev}`}
          >
            {title(sub)}
          </span>
        </NavLink>
      )
    })

  useEffect(() => {
    if (buba === 0) setToggle(true)
  }, [])

  return (
    <div
      className={`${toggle ? classes.visible : classes.invisible} ${
        toggle && classes.subContainer
      }`}
    >
      {content}
    </div>
  )
}
export default SubLinks

import React, {useContext, useEffect, useState, useRef} from 'react';
import 'devextreme-react/text-area';
import DataGrid, {
    Editing,
    Pager,
    Paging,
    Column,
    FilterRow,
    RequiredRule,
    Lookup, Export, Form, Item, Popup, PatternRule
} from 'devextreme-react/data-grid';
import {Workbook} from 'exceljs';
import {saveAs} from 'file-saver-es';
import {exportDataGrid} from 'devextreme/excel_exporter';
import {LoadPanel} from "devextreme-react/load-panel";
import {UserContext} from "../../../hooks/UserContext";
import _, {concat} from "lodash";
import fcsService from "../../../services/api/fcs";
import Button from "devextreme-react/button";
import notify from "devextreme/ui/notify";
import {number} from "prop-types";
import numberInput from "../../../components/NumberInput";
import {isVisible} from "bootstrap/js/src/util";
import LoadPanelComponent from '../../../components/LoadPanelComponent';

function FaType(props) {
    const userListPosition = {of: '#usersList'}
    const {user} = useContext(UserContext);
    const [dataSource, setDataSource] = useState([]);
    const [loader, setLoader] = useState(false);
    const myBabyRef = useRef(null);
    const [dataFcsChart, setFcsChart] = useState([]);
    useEffect(() => {
        fcsFaList();
    }, [])

    const fcsFaList = async () => {
        try {
            setLoader(true);
            const result = await fcsService.getAllFcsFaType();
            setDataSource(result)
            const fcsChart = await fcsService.getFcsChart('FA',0,'0','0','0');
            setFcsChart(fcsChart);
            return setLoader(false)
        } catch (e) {
            console.error(e);
        }
    }
    const calculateAmt = (newData, value, currentRowData) => {

        if (Number(value) > 9) {
            newData.fa_number = Number(value) * 100000000
            newData.fatype = value
        }
        currentRowData.fa_number = newData.fa_number
    }

    const onEditorPreparing = (e) => {
        // console.log('sdsd ', e);
        if (e.parentType === "dataRow" && e.dataField !== "password") {
            return;
        }
        if (e.row && e.row.isNewRow) {
            e.editorOptions.visible = true;
        }
        if (e.row && !e.row.isNewRow) {
            e.editorOptions.visible = false;
        }
    }
    const customAddRow = (grid) => {
        grid.current.instance.addRow();
    }
    const onInitNewRow = (e) => {

    }
    const setData = async (e) => {
        if (e.changes && e.changes.length !== 0) {
            // console.log('e.changes ', e.changes);
            if (e.changes[0].type === "update") {
                try {
                    let r1 = _.filter(dataFcsChart, {id: e.changes[0].data.acct_chart_id});
                    let r2 = _.filter(dataFcsChart, {id: e.changes[0].data.dep_chart_id});
                    let r3 = _.filter(dataFcsChart, {id: e.changes[0].data.acder_chart_id});
                    e.changes[0].data.updated_user_id = user.id;
                    e.changes[0].data.acct_code = r1[0].acct;
                    e.changes[0].data.dep_acct = r2[0].acct;
                    e.changes[0].data.acder_acct = r3[0].acct;
                    await fcsService.editFcsFaType(e.changes[0].data);
                    await fcsFaList();
                    notify('Амжилттай хадгалагдлаа', "success", 2000);
                } catch (e) {
                    notify('Засварлалтыг хадгалах явцад алдаа гарлаа', "error", 2000);
                }
            } else if (e.changes[0].type === "insert") {
                try {
                    delete e.changes[0].data.id;
                    let r1 = _.filter(dataFcsChart, {id: e.changes[0].data.acct_chart_id});
                    let r2 = _.filter(dataFcsChart, {id: e.changes[0].data.dep_chart_id});
                    let r3 = _.filter(dataFcsChart, {id: e.changes[0].data.acder_chart_id});
                    e.changes[0].data.created_user_id = user.id;
                    e.changes[0].data.org_id = user.org_id;
                    e.changes[0].data.acct_code = r1[0].acct;
                    e.changes[0].data.dep_acct = r2[0].acct;
                    e.changes[0].data.acder_acct = r3[0].acct;
                    await fcsService.addFcsFaType(e.changes[0].data);
                    await fcsFaList();
                    notify('Амжилттай үүслээ', "success", 2000);
                } catch (e) {
                    notify('Ангилалын код давхардлаа', "error", 2000);

                }

            } else if (e.changes[0].type === "remove") {
                try {
                    await fcsService.removeFcsFaType(e.changes[0].key.id);
                    await fcsFaList();
                    notify('Амжилттай устгагдлаа', "success", 2000);
                } catch (e) {
                    notify('Устгах явцад алдаа гарлаа', "error", 2000);
                }

            }

        }

    }
    const onExporting = (e) => {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Main sheet');

        exportDataGrid({
            component: e.component,
            worksheet,
            autoFilterEnabled: true,
        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer], {type: 'application/octet-stream'}), 'Purchase.xlsx');
            });
        });
        e.cancel = true;
    }
    const style={
        text: {
            color: "#2E4765", fontWeight: "700", fontSize: ".875rem"
        },
        box: {
            border: "none", backgroundColor: "#F6F6F6", borderRadius: ".5rem", padding: ".25rem .15rem", width: "100%"
        }
    }
    return (
        <div style={{padding: "0.5rem"}}>
            <LoadPanelComponent
                shadingColor="rgba(0,0,0,0.4)"
                position={{of: '#password'}}
                message=''
                showPane={false}
            />
            <div className="col-12 row" style={{justifyContent: "center", gap: '2rem', padding: '5px 15px'}}>
                <span style={{textTransform: "uppercase",...style.text}}>
                <h4>
                   Үндсэн хөрөнгийн ангиллын бүртгэл</h4>
                </span>
                {/* <Button icon="add" text="Нэмэх" onClick={() => customAddRow(myBabyRef)} width={110} type="default"
                        style={{alignment: "center", borderRadius: '7px'}}/> */}
            </div>
            <LoadPanelComponent
                shadingColor="rgba(0,0,0,0.4)"
                position={userListPosition}
                visible={loader}
                showPane={false}
                message=''
            />
            <div>
                <div className="card-body">
                    <DataGrid
                        onEditorPreparing={onEditorPreparing}
                        ref={myBabyRef}
                        dataSource={dataSource}
                        onSaved={setData}
                        keyExpr={null}
                        onInitNewRow={onInitNewRow}
                        wordWrapEnabled={true}
                        allowColumnResizing={true}
                        onExporting={onExporting}>
                     
                        <FilterRow
                            visible={true}
                        />
                        <Pager
                            showPageSizeSelector={true}
                            // showInfo={true}
                            showNavigationButtons={true}
                        />
                        <Column
                            dataField="id"
                            caption="Дугаар"
                            allowEditing={false}
                            width= {60}
                            alignment="center"
                            // editCellTemplate = {}
                            // cellRender = {customize}
                            cssClass="custom"
                            allowFiltering={true}
                        >
                        </Column>
                        <Column
                            dataField="organization_model.name"
                            caption="Байгууллага нэр"
                            allowEditing={false}
                            minWidth={130}
                            // editCellTemplate = {}
                            // cellRender = {customize}
                            cssClass="custom"
                            allowFiltering={true}
                        >
                        </Column>

                        <Column
                            cssClass="custom"
                            dataField="name"
                            caption="Ангилалын нэр"
                            minWidth={100}
                            alignment="center"
                            allowEditing={true}
                            allowFiltering={true}
                        >
                            <RequiredRule
                                message='Ангилалын нэр оруулна уу'
                            />
                        </Column>
                        <Column
                            cssClass="custom"
                            dataField="fatype"
                            caption="Код"
                            width={50}
                            setCellValue={calculateAmt}
                            minWidth={100}
                            alignment="center"
                            allowEditing={true}
                            allowFiltering={true}
                            format="##"

                        >
                            <RequiredRule
                                message='код оруулна уу'
                            />
                            <PatternRule pattern={/^[0-9][0-9]$/}
                                         message='Зөвхөн 2 оронтой тоо оруулна уу!'/>
                        </Column>
                        <Column
                            cssClass="custom"
                            minWidth={100}
                            alignment="center"
                            dataField="acct_chart_id"
                            caption="Хөрөнгийн данс"
                            allowEditing={true}
                            allowFiltering={true}
                        >
                            <RequiredRule
                                message='Үндсэн хөрөнгийн данс оруулна уу'
                            />
                            <Lookup
                                valueExpr="id"
                                displayExpr={(item) => {
                                    return item && `${item.acct} - ${item.descr}`
                                }}
                                dataSource={dataFcsChart}
                            />
                        </Column>
                        <Column
                            cssClass="custom"
                            dataField="dep_chart_id"
                            minWidth={100}
                            alignment="center"
                            caption="Эл/зардлын данс"
                            allowEditing={true}
                            allowFiltering={true}
                        >
                            <RequiredRule
                                message='Элэгдлийн зардлын данс оруулна уу'
                            />
                            <Lookup
                                valueExpr="id"
                                displayExpr={(item) => {
                                    return item && `${item.acct} - ${item.descr}`
                                }}
                                dataSource={dataFcsChart}
                            />
                        </Column>
                        <Column
                            cssClass="custom"
                            dataField="acder_chart_id"
                            caption="Хур/эл данс"
                            minWidth={100}
                            alignment="center"
                            allowEditing={true}
                            allowFiltering={true}
                        >
                            <RequiredRule
                                message='Хуримтлагдсан элэгдлийн данс оруулна уу'
                            />
                            <Lookup
                                valueExpr="id"
                                displayExpr={(item) => {
                                    return item && `${item.acct} - ${item.descr}`
                                }}
                                dataSource={dataFcsChart}
                            />
                        </Column>
                        <Column
                            cssClass="custom"
                            dataField="fa_number"
                            caption="дугаарлалт"
                            minWidth={100}
                            alignment="center"
                            allowEditing={true}
                            allowFiltering={true}
                        >
                            <RequiredRule
                                message='Ангилалын нэр оруулна уу'
                            />
                        </Column>
                        <Editing
                            mode="popup"
                            allowUpdating={true}
                            allowDeleting={true}
                            allowAdding = {true}
                            useIcons={true}
                            texts={{
                                cancelAllChanges: 'Болих бүгд',
                                cancelRowChanges: 'Болих',
                                confirmDeleteMessage: 'Энэ ангилалын мэдээллийг устгах уу?',
                                confirmDeleteTitle: 'Баталгаажуулах',
                                deleteRow: 'Устгах',
                                editRow: 'Өөрчлөх',
                                saveAllChanges: 'Хадгалах бүгд',
                                saveRowChanges: 'Хадгалах',
                                undeleteRow: 'Буцаах',
                                validationCancelChanges: 'Баталгаажуулах болих',
                            }}
                        >
                            <Popup title="Үндсэн хөрөнгийн ангилал" showTitle={true} width={1000} height={300}/>
                            <Form>
                                <Item dataField="name"/>
                                <Item dataField="fatype"/>

                                <Item dataField="acct_chart_id"/>
                                <Item dataField="dep_chart_id"/>
                                <Item dataField="acder_chart_id"/>
                                <Item dataField="fa_number"/>
                            </Form>
                        </Editing>
                        <Paging enabled={true}/>
                        <Export enabled={true} allowExportSelectedData={false}/>
                    </DataGrid>
                </div>
            </div>
        </div>
    )
}

export default FaType;

import React, {useEffect, useState} from "react"
import ga4Services from "../../../services/api/googleAnalytics"
import {DataGrid} from "devextreme-react"
import {Column} from "devextreme-react/data-grid"

const numberWithCommas = (x) => {
  try {
    return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
  } catch (err) {
    throw err
  }
}
function GaUserMetrics() {
    const [userData, setUserData] = useState([])

  useEffect(()=>{
    fetchData()
  }, [])
  const fetchData = async () => {
    try {
      let response = (await ga4Services.getViewerCounts()).data

      let report = {}

      response.rows.forEach((row) => {
        report["Идэвхитэй хэрэглэгчид"] = numberWithCommas(
          row.metricValues[0].value
        )
        report["Нэг өдрийн идэвхитэй хэрэглэгчид"] = numberWithCommas(
          row.metricValues[1].value
        )
        report["7 өдрийн идэвхитэй хэрэглэгчид"] = numberWithCommas(
          row.metricValues[2].value
        )
        report["28 өдрийн идэвхитэй хэрэглэгчид"] = numberWithCommas(
          row.metricValues[3].value
        )
        report["Шинэ хэрэглэгчид"] = numberWithCommas(row.metricValues[4].value)
        report["Нийт хэрэглэгчид"] = numberWithCommas(row.metricValues[5].value)
        report["7 хоногт зочилсон тоо"] = numberWithCommas(
          row.metricValues[6].value
        )
      })
      let keyValueList = Object.keys(report).map((key, index) => {
        return {
          name: key,
          value: report[key],
        }
      })
      setUserData(keyValueList)
    } catch (error) {
      console.log("error", error)
    }
  }

  return (
    <div className="card">
    <div className="card-header">
      <span
        style={{
          fontFamily: "'Montserrat', sans-serif",
          fontWeight: "bold",
        }}
      >
        3. Хэрэглэгчдийн тоон үзүүлэлт
      </span>
    </div>
    <div className="card-body" style={{paddingBottom: "1.6rem"}}>
      <DataGrid dataSource={userData} rowAlternationEnabled>
        <Column dataField={"name"} caption={"Талбарууд"} />
        <Column dataField={"value"} caption={"Тоо хэмжээ"} />
      </DataGrid>
    </div>
  </div>
  )
}

export default GaUserMetrics

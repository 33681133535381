import React, {useState, useEffect} from "react"
import notify from "devextreme/ui/notify"
import BudgetService from "../../../../services/api/budget"
import _ from "lodash"
import {numberWithCommas} from "../../../../util"
import DataGrid, {
  Column,
  Paging,
  Summary,
  TotalItem,
} from "devextreme-react/data-grid"
import LoadPanelComponent from "../../../../components/LoadPanelComponent"
import EmptyData from "../../../budget/components/empty"

const TowloruulehZadgaiGrid = (props) => {
  //const [neverChangingData, setNeverChangingData] = useState([])
  const [chartData, setChartData] = useState([])
  const [sumVal, setSumVal] = useState(0)
  const [loader, setLoader] = useState(true)

  const loadData = async () => {
    try {
      setLoader(true)
      let r = await BudgetService.getTowloruulehDataGrid(
        props.year,
        props.chosenValue,
        props.chosenMinistry,props.orgId
      )
      //setNeverChangingData(r)
      let sum = _.sumBy(r, "amount")
      setSumVal(sum)
      setChartData(r)
    } catch (e) {
      notify(e.message, "error", 2000)
    } finally {
      setLoader(false)
    }
  }

  useEffect(() => {
    loadData()
  }, [props.year, props.chosenValue, props.chosenMinistry,props.orgId])

  const cellTemp = (cellElement, cellInfo) => {
    return cellElement.append(cellInfo.row.loadIndex + 1)
  }

  const cellFractionColumn = (e) => {
    return `${numberWithCommas(e.value / 1000000)}`
  }

  const cellFractionColumn1 = (e) => {
    return `${numberWithCommas(e.value / 1000000)}`
  }

  const calcCellVal = (e) => {
    return ((e.amount * 100) / sumVal).toFixed(2)
  }

  const calcCellVal1 = (e) => {
    let r = (e.guitsetgel * 100) / e.amount
    let zero = 0
    if (isFinite(r)) {
      return r.toFixed(2)
    } else {
      return zero.toFixed(2)
    }
  }

  const rowSelectionHandler = (e) => {
    props.callFunc(e.data.name ? e.data.name : e.data.salbar)
  }

  const onCellPreparedHandler = (e) => {
    if (e.rowType === "header") {
      e.cellElement.wordWrapEnabled = true
      e.cellElement.style.fontFamily = "Segoe UI"
      e.cellElement.style.fontWeight = "bold"
      e.cellElement.style.color = "#15186A"
      e.cellElement.style.backgroundColor = "#EEF0F4"
    }
    if (e.rowType === "data") {
      e.cellElement.style.fontFamily = "Segoe UI"
      e.cellElement.style.fontWeight = "semibold"
      e.cellElement.style.color = "#000000"
    }
    if (e.rowType === "data" && e.column.dataField === "name") {
      let elem = e.cellElement
      elem.style.setProperty("cursor", "pointer", "important")
      // elem.style.setProperty('color', 'green');
    }
  }
  if (chartData?.length === 0 && !loader) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          family: "Segoe UI",
        }}
      >
        <EmptyData/>
      </div>
    )
  }
  return (
    <div id="load2">
      <LoadPanelComponent position={{of: "#load2"}} visible={loader} />
      <DataGrid
        rowAlternationEnabled={true}
        dataSource={chartData}
        style={{fontFamily: "Segoe UI", fontWeight: "semibold"}}
        // onRowClick={rowSelectionHandler}
        noDataText="Дата байхгүй байна."
        onCellPrepared={onCellPreparedHandler}
        onRowClick={rowSelectionHandler}
      >
        <Paging defaultPageSize={9} />
        <Column
          caption="№"
          allowSorting={false}
          cellTemplate={cellTemp}
          alignment="center"
          width={40}
        />
        <Column
          dataField="salbar"
          allowSorting={false}
          caption="Байгууллага"
          alignment="left"
          minWidth={120}
        />
        <Column caption="Төсөв" alignment="center">
          <Column
            dataField="amount"
            allowSorting={true}
            cellRender={cellFractionColumn}
            defaultSortOrder="desc"
            alignment="center"
            caption="Дүн"
            allowFiltering={false}
            width={105}
          />
          <Column
            caption="Хувь"
            allowSorting={true}
            calculateCellValue={calcCellVal}
            alignment="center"
            width={60}
          />
        </Column>
        <Column caption="Гүйцэтгэл" alignment="center">
          <Column
            dataField="guitsetgel"
            caption="Дүн"
            allowSorting={true}
            cellRender={cellFractionColumn1}
            alignment="center"
            width={95}
          />
          <Column
            caption="Хувь"
            allowSorting={true}
            calculateCellValue={calcCellVal1}
            alignment="center"
            width={60}
          />
        </Column>

        <Summary>
          <TotalItem
            column="amount"
            summaryType="sum"
            customizeText={(data) => {
              return `${numberWithCommas(data.value / 1000000)}`
            }}
          />

          <TotalItem
            column="guitsetgel"
            summaryType="sum"
            customizeText={(data) => {
              return `${numberWithCommas(data.value / 1000000)}`
            }}
          />
          <TotalItem column="Эзлэх %" displayFormat="100%" />
        </Summary>
      </DataGrid>
    </div>
  )
}

export default TowloruulehZadgaiGrid

import moment from "moment";
import Profile from "../../../assets/imgs/profile_icon.png";

export default function Message(props) {
    let profileSrc;
    if (props.own) {
        profileSrc = props.userImage ? props.url+props.userImage : Profile;
    } else {
        profileSrc = props.otherUserImage ? props.url+props.otherUserImage : Profile;
    }

    return (
        <div className={props.own ? 'me' : 'other'} style={{fontFamily: 'Montserrat'}}>
            <div className={'chat-detail'}>
                <div className={'chat-user'}>
                    <div>
                        <img className={'logo'} width={40} height={40} alt="" src={profileSrc} style={{ borderRadius: '50%'}} />
                    </div>
                    <div className={'chat-text'}>
                        <span>{props.message.text}</span>
                    </div>
                </div>
                <div className={'chat-time'}>
                    {moment(props.message.createdAt).format("YYYY-MM-DD HH:mm")}
                </div>
            </div>
        </div>
    )
}
import { Button, Popup, SelectBox, Tooltip } from 'devextreme-react'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { UserContext } from '../../hooks/UserContext'
import notify from 'devextreme/ui/notify';
import OrganizationService from '../../services/api/organization';
import { MainInfo } from './MainInfo';
import { GeneralInfo } from './GeneralInfo';
import { BudgetInfo } from './BudgetInfo';
import _ from 'lodash';
import { PlanInfo } from './PlanInfo';
import { BudgetProjectInfo } from './BudgetProjectInfo';
import { GeneralInfoStrategy } from './GeneralInfoStrategy';
import './dashboard.css'
import { OrgStructure } from './OrgStructure';
import { GoInfo } from 'react-icons/go';

export const OrgDashboard = () => {
  const { user } = useContext(UserContext);
  const windowSize = useRef([window.innerWidth, window.innerHeight]);
  const [chosenValue, setChosenValue] = useState('БҮГД');
  const [orgs, setOrgs] = useState([]);
  const [orgLogo, setOrgLogo] = useState([]);
  const [loadData, setLoadData] = useState([]);
  const [firstChartVisible, setFirstChartVisible] = useState(false);
  const [whichComponentMaxmized, setWhichComponentMaximized] = useState(null);
  const [loader, setLoader] = useState(true)

  useEffect(() => {
    loadOrgs();
  }, [])

  useEffect(() => {
    loadDataInfo();
    loadOrgLogo();
  }, [chosenValue])

  const loadOrgs = async () => {
    try {
      let res = await OrganizationService.getDashboardOrg();
      setOrgs(res.length > 0 && res.length === 1 ? res : [{ org_name: 'БҮГД' }, ...res])
      res.length > 1 ? setChosenValue('БҮГД') : setChosenValue(res.length > 0 && res[0].org_name)
    } catch (e) {
      notify(e.message, "error", 2000)
    }
  }

  const loadOrgLogo = async () => {
    try {
      let res = await OrganizationService.getOrgLogo(chosenValue);
      setOrgLogo(res);
    } catch (e) {
      notify(e.message, "error", 2000)
    }
  }

  const loadDataInfo = async () => {
    try {
      let res = await OrganizationService.getMainInfo(chosenValue);
      setLoadData(res)
    } catch (e) {
      notify(e.message, "error", 2000)
    } finally {
      setLoader(false)
    }
  }

  let maximizeIcon = '<svg t="1659165394467" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2309" width="16" height="16"><path d="M504.576 603.428571a19.748571 19.748571 0 0 1-5.705143 13.129143l-189.696 189.696 82.285714 82.285715a36.425143 36.425143 0 0 1 10.861715 25.709714c0 20.004571-16.566857 36.571429-36.571429 36.571428h-256c-20.004571 0-36.571429-16.566857-36.571428-36.571428v-256c0-20.004571 16.566857-36.571429 36.571428-36.571429a36.205714 36.205714 0 0 1 25.709714 10.861715l82.285715 82.285714 189.696-189.696c3.437714-3.437714 8.557714-5.705143 13.129143-5.705143s9.728 2.304 13.129142 5.705143l65.133715 65.133714a19.602286 19.602286 0 0 1 5.705143 13.129143zM950.857143 109.714286v256c0 20.004571-16.566857 36.571429-36.571429 36.571428a36.205714 36.205714 0 0 1-25.709714-10.861714l-82.285714-82.285714-189.696 189.696c-3.437714 3.437714-8.557714 5.705143-13.129143 5.705143s-9.728-2.304-13.129143-5.705143l-65.133714-65.133715c-3.437714-3.437714-5.705143-8.557714-5.705143-13.129142s2.304-9.728 5.705143-13.129143l189.696-189.696-82.285715-82.285715a36.425143 36.425143 0 0 1-10.861714-25.709714c0-20.004571 16.566857-36.571429 36.571429-36.571428h256c20.004571 0 36.571429 16.566857 36.571428 36.571428z" fill="#515151" p-id="2310"></path></svg>';

  const popUpHandler = (trueFalser, value) => {
    setFirstChartVisible(trueFalser);
    setWhichComponentMaximized(value)
  }

  const renderContent = () => {
    return (
      <div style={{ padding: 20, display: 'flex', flexDirection: 'column', gap: 10, background: '#555', color: 'white' }}>
        <div style={{ display: 'flex', gap: 10, alignItems: 'center' }}>
          <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 448 512"><path fill="#1fc75a" d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" /></svg>
          <span>Мэдээллээ бүрэн оруулсан</span>
        </div>
        <div style={{ display: 'flex', gap: 10, alignItems: 'center' }}>
          <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 384 512"><path fill="#d70909" d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" /></svg>
          <span>Мэдээллээ оруулаагүй</span>
        </div>
        <div style={{ display: 'flex', gap: 10, alignItems: 'center', visibility: user.organization.obt_id === 1 ? 'visible' : 'hidden' }}>
          <svg width="18px" height="18px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" fill="none" stroke="#ffd43b" stroke-width="0.0002"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path fill="#f5cc14" fill-rule="evenodd" d="M10 3a7 7 0 100 14 7 7 0 000-14zm-9 7a9 9 0 1118 0 9 9 0 01-18 0zm10.01 4a1 1 0 01-1 1H10a1 1 0 110-2h.01a1 1 0 011 1zM11 6a1 1 0 10-2 0v5a1 1 0 102 0V6z"></path> </g></svg>
          <span>Мэдээллээ дутуу оруулсан</span>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className='info_detail'>
        {[1, 2, 3, 4, 5, 6, 7].map((e) =>
          <Tooltip
            target={`#iconDetail_${e}`}
            showEvent="mouseenter"
            hideEvent="mouseleave"
            position={{ my: 'top', at: 'top', collision: 'fit flip', offset: '0 20' }}
            contentRender={renderContent}
          />)}
      </div>
      <Popup
        visible={firstChartVisible}
        onHiding={() => setFirstChartVisible(false)}
        hideOnOutsideClick={true}
        showCloseButton={true}
        height={550}
        title={whichComponentMaxmized &&
          whichComponentMaxmized === 1 ? 'ТӨСВИЙН МЭДЭЭЛЭЛ /байгууллагаар/' :
          whichComponentMaxmized === 2 ? 'ҮНДСЭН МЭДЭЭЛЭЛ /байгууллагаар/' :
            whichComponentMaxmized === 3 ? 'ЕРӨНХИЙ МЭДЭЭЛЭЛ /Үйл ажиллагааны стратеги, зохион байгуулалтын бүтцийн өөрчлөлтийн хөтөлбөр/' :
              whichComponentMaxmized === 4 ? 'ЕРӨНХИЙ МЭДЭЭЛЭЛ /Стратеги төлөвлөгөө/' :
                whichComponentMaxmized === 5 ? 'БАЙГУУЛЛАГЫН МЭДЭЭЛЭЛ /Бусад/' :
                  whichComponentMaxmized === 6 ? 'ТӨСВИЙН ТӨСӨЛ ХҮРГҮҮЛСЭН ЭСЭХ' :
                    whichComponentMaxmized === 7 ? 'ХУВААРИЙН САНАЛ БОЛОВСРУУЛСАН ЭСЭХ' : ''}
        showTitle={true}
      >
        {whichComponentMaxmized === 1 && <BudgetInfo chosenValue={chosenValue} />}
        {whichComponentMaxmized === 2 && <MainInfo chosenValue={chosenValue} loadData={loadData} loader={loader} />}
        {whichComponentMaxmized === 3 && <GeneralInfo chosenValue={chosenValue} loadData={loadData} loader={loader} />}
        {whichComponentMaxmized === 4 && <GeneralInfoStrategy chosenValue={chosenValue} loadData={loadData} loader={loader} />}
        {whichComponentMaxmized === 5 && <OrgStructure chosenValue={chosenValue} />}
        {whichComponentMaxmized === 6 && <BudgetProjectInfo chosenValue={chosenValue} />}
        {whichComponentMaxmized === 7 && <PlanInfo chosenValue={chosenValue} />}
      </Popup>
      <div style={{ padding: 10 }}>
        <SelectBox
          label="Байгууллага"
          items={user.organization.obt_id === 3 ? '' : orgs}
          width={windowSize.current[0] < 635 ? 'auto' : '22rem'}
          value={chosenValue}
          valueExpr="org_name"
          displayExpr="org_name"
          disabled={orgs && orgs.length === 1 ? true : false}
          onValueChanged={(e) => setChosenValue(e.value)}
          placeholder={user.organization.obt_id !== 3 ? 'БҮГД' : user.organization.name}
          style={{ borderRadius: 10 }} />
      </div>
      <div className='org-container' style={{ overflowX: 'auto', position: 'relative', marginBottom: 10 }}>
        <div style={{ display: 'flex', gap: 10, marginBottom: 5 }}>
          {
            orgLogo.map((e, index) =>
            (
              <div key={index} className='card hoverCard' id={`customLogoId_${index}`} onClick={() => setChosenValue(e.org_name)}
                style={{
                  borderColor: chosenValue === e.org_name ? 'rgb(24 144 255 / 0.6)' : '#DEDEDE',
                  boxShadow: chosenValue === e.org_name && '0 0 7px rgb(24 144 255 / 0.6)'
                }}>
                <div style={{ margin: "10px 5px", display: 'flex', flexDirection: 'column', gap: 10, alignItems: 'center', width: '140px' }}>
                  <img alt='' src={e.logo} width='80' style={{ objectFit: 'scale-down', height: 80 }} />
                  <span style={{ width: 140, height: 35, fontSize: 12, textAlign: 'center', border: 'none', outline: 'none', resize: 'none', overflow: 'hidden' }}>{e.org_name}</span>
                </div>
                <Tooltip target={`#customLogoId_${index}`}
                  position={{ my: 'top', at: 'bottom', collision: 'fit flip', offset: '0 -14' }}
                  showEvent="mouseenter"
                  hideEvent="mouseleave"
                  contentRender={() => (
                    <div className='subTitle'>{e.org_name}</div>)}
                >
                </Tooltip>
              </div>)
            )
          }
        </div>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
        <div className="card">
          <div className="card-header">
            <div style={{ flex: 1, display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
              <span style={{ fontWeight: "700" }}>1. ТӨСВИЙН МЭДЭЭЛЭЛ /байгууллагаар, жилээр/</span>
              <div style={{ display: 'flex', gap: 30, alignItems: 'self-start' }}>
                <div id="iconDetail_1" style={{ display: 'flex', gap: '5px', alignItems: 'center', cursor: 'default' }}><GoInfo color='#2196f3' /><span>Тэмдэглэгээний тайлбар</span></div>
                <Button style={{ border: '0px solid transparent', borderTopRightRadius: '15px' }} icon={maximizeIcon} onClick={() => popUpHandler(true, 1)} />
              </div>
            </div>
          </div>
          <div className="card-body" style={{ padding: "0.5rem", paddingTop: "1rem" }}>
            <BudgetInfo chosenValue={chosenValue} />
          </div>
        </div>
        <div className="card">
          <div className="card-header">
            <div style={{ flex: 1, display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
              <span style={{ fontWeight: "700" }}>2. ҮНДСЭН МЭДЭЭЛЭЛ /байгууллагаар/</span>
              <div style={{ display: 'flex', gap: 30, alignItems: 'self-start' }}>
                <div id="iconDetail_2" style={{ display: 'flex', gap: '5px', alignItems: 'center', cursor: 'default' }}><GoInfo color='#2196f3' /><span>Тэмдэглэгээний тайлбар</span></div>
                <Button style={{ border: '0px solid transparent', borderTopRightRadius: '15px' }} icon={maximizeIcon} onClick={() => popUpHandler(true, 2)} />
              </div>
            </div>
          </div>
          <div className="card-body" style={{ padding: "0.5rem", paddingTop: "1rem" }}>
            <MainInfo chosenValue={chosenValue} loadData={loadData} loader={loader} />
          </div>
        </div>
        {user.organization.obt_id !== 3 && <div className="card">
          <div className="card-header">
            <div style={{ flex: 1, display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
              <span style={{ fontWeight: "700" }}>3. ЕРӨНХИЙ МЭДЭЭЛЭЛ /Үйл ажиллагааны стратеги, зохион байгуулалтын бүтцийн өөрчлөлтийн хөтөлбөр/</span>
              <div style={{ display: 'flex', gap: 30, alignItems: 'self-start' }}>
                <div id="iconDetail_3" style={{ display: 'flex', gap: '5px', alignItems: 'center' }}><GoInfo color='#2196f3' /><span>Тэмдэглэгээний тайлбар</span></div>
                <Button style={{ border: '0px solid transparent', borderTopRightRadius: '15px' }} icon={maximizeIcon} onClick={() => popUpHandler(true, 3)} />
              </div>
            </div>
          </div>
          <div className="card-body" style={{ padding: "0.5rem", paddingTop: "1rem" }}>
            <GeneralInfo chosenValue={chosenValue} loadData={loadData} loader={loader} />
          </div>
        </div>}
        <div className="card">
          <div className="card-header">
            <div style={{ flex: 1, display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
              <span style={{ fontWeight: "700" }}>{user.organization.obt_id === 3 ? '3' : '4'}. ЕРӨНХИЙ МЭДЭЭЛЭЛ /Стратеги төлөвлөгөө/</span>
              <div style={{ display: 'flex', gap: 30, alignItems: 'self-start' }}>
                <div id="iconDetail_4" style={{ display: 'flex', gap: '5px', alignItems: 'center', cursor: 'default' }}><GoInfo color='#2196f3' /><span>Тэмдэглэгээний тайлбар</span></div>
                <Button style={{ border: '0px solid transparent', borderTopRightRadius: '15px' }} icon={maximizeIcon} onClick={() => popUpHandler(true, 4)} />
              </div>
            </div>
          </div>
          <div className="card-body" style={{ padding: "0.5rem", paddingTop: "1rem" }}>
            <GeneralInfoStrategy chosenValue={chosenValue} loadData={loadData} loader={loader} />
          </div>
        </div>
        <div className="card">
          <div className="card-header">
            <div style={{ flex: 1, display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
              <span style={{ fontWeight: "700" }}>{user.organization.obt_id === 3 ? '4' : '5'}. БАЙГУУЛЛАГЫН МЭДЭЭЛЭЛ /Бусад/</span>
              <div style={{ display: 'flex', gap: 30, alignItems: 'self-start' }}>
                <div id="iconDetail_5" style={{ display: 'flex', gap: '5px', alignItems: 'center' }}><GoInfo color='#2196f3' /><span>Тэмдэглэгээний тайлбар</span></div>
                <Button style={{ border: '0px solid transparent', borderTopRightRadius: '15px' }} icon={maximizeIcon} onClick={() => popUpHandler(true, 5)} />
              </div>
            </div>
          </div>
          <div className="card-body" style={{ padding: "0.5rem", paddingTop: "1rem" }}>
            <OrgStructure chosenValue={chosenValue} />
          </div>
        </div>
        <div className="row" style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'row' }}>
          <div className="card col-6 responsive-col" style={{ flex: 1 }}>
            <div className="card-header">
              <div style={{ flex: 1, display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
                <span style={{ fontWeight: "700" }}>{user.organization.obt_id === 3 ? '5' : '6'}. ТӨСВИЙН ТӨСӨЛ ХҮРГҮҮЛСЭН ЭСЭХ</span>
                <div style={{ display: 'flex', gap: 30, alignItems: 'self-start' }}>
                  <div id="iconDetail_6" style={{ display: 'flex', gap: '5px', alignItems: 'center', cursor: 'default' }}><GoInfo color='#2196f3' /><span>Тэмдэглэгээний тайлбар</span></div>
                  <Button style={{ border: '0px solid transparent', borderTopRightRadius: '15px' }} icon={maximizeIcon} onClick={() => popUpHandler(true, 6)} />
                </div>
              </div>
            </div>
            <div className="card-body" style={{ padding: "0.5rem", paddingTop: "1rem" }}>
              <BudgetProjectInfo chosenValue={chosenValue} />
            </div>
          </div>
          <div className="card col-6 responsive-col" style={{ flex: 1 }}>
            <div className="card-header">
              <div style={{ flex: 1, display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
                <span style={{ fontWeight: "700" }}>{user.organization.obt_id === 3 ? '6' : '7'}. ХУВААРИЙН САНАЛ БОЛОВСРУУЛСАН ЭСЭХ</span>
                <div style={{ display: 'flex', gap: 30, alignItems: 'self-start' }}>
                  <div id="iconDetail_7" style={{ display: 'flex', gap: '5px', alignItems: 'center', cursor: 'default' }}><GoInfo color='#2196f3' /><span>Тэмдэглэгээний тайлбар</span></div>
                  <Button style={{ border: '0px solid transparent', borderTopRightRadius: '15px' }} icon={maximizeIcon} onClick={() => popUpHandler(true, 7)} />
                </div>
              </div>
            </div>
            <div className="card-body" style={{ padding: "0.5rem", paddingTop: "1rem" }}>
              <PlanInfo chosenValue={chosenValue} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

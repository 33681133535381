
import OrganizationSelectComponent from "../../../components/OrganizationSelectComponent";
import LawControl from "./LawControl";
import React,{useState,useContext} from "react";
import {UserContext} from "../../../hooks/UserContext";

export default function LawControl1 (props) {
    const [selectedData, setSelectedData] = useState({});
    const {user} = useContext(UserContext);
    return  <>
        <OrganizationSelectComponent
            selectedData={(d) => {console.log('year', d);setSelectedData(d)}}
            selectedYear={(d) => console.log('year', d)}
            showClearButton={false}
            showYear={false}
            hideLabel={true}
            labelMode = {'floating'}
            width = {600}
            defaultId = {user.organization.id}
        />
        {
            selectedData && <LawControl selectedData={selectedData}/>
        }

    </>

}

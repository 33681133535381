import styled from "styled-components"
import { Button, Form, Input, message, Space, ConfigProvider, Select, Radio } from "antd"
import feedbackServices from "../../services/api/feedback"
import { useEffect, useState, useContext } from "react"
import { UserContext } from "../../hooks/UserContext"
import orgPositionServices from "../../services/api/orgPosition"
import LoadPanelComponent from '../LoadPanelComponent'
import './style.css'

const { TextArea } = Input

const Container = styled.div`
  padding: 0 1rem 1.5rem 1rem;
  font-family: Segoe UI;
  font-size: 12px;
  .description {
    color: lightgray;
    font-size: 13px;
    font-weight: 400;
    text-align: justify;
    margin-bottom: 0.5rem;
  }
`
function FeedbackForm({ selectedTab, setVisible }) {
  const [messageApi, contextHolder] = message.useMessage()
  const [form] = Form.useForm()
  const { user } = useContext(UserContext)
  const [list, setList] = useState([])
  const [select, setSelect] = useState(1)
  const [selectedOrg, setSelectedOrg] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [adminOrgId, setAdminOrgId] = useState(0)

  const tab = parseInt(selectedTab)
  const loadPosition = { of: '#feedback' }

  const getOrgList = async () => {
    try {
      const r = await orgPositionServices.getOrgListByParent();
      const filter = r.data.filter((org)=>org.id !== user.org_id)
      setList(filter)
    } catch (e) {
      console.log(e);
    }
  }

  const getSystemOrgId = async () =>{
    try{
      const orgId = await feedbackServices.getSystemOrgId();
      setAdminOrgId(orgId.data.org_id)
    } catch(err){
      console.log(err);
    }
  }

  useEffect(() => {
    getOrgList()
    getSystemOrgId()
  }, [])
  const handleOrgSelect = (value) => {
    setSelectedOrg(value);
  };

  const handleChange = (value) => {
    setSelect(value)
  }

  const onFinish = async (values) => {
    setIsLoading(true)
    try {
      if (values.question == null) {
        messageApi.open({
          type: "warning",
          content: "Санал оруулна уу",
        })
        return 0
      }
      const result = await feedbackServices.createFeedback({
        org_id: select === 1 ? selectedOrg : adminOrgId,
        nu_name: user.first_name,
        nu_email: user.email,
        feedback_type_id: tab,
        ...values
      })
      // console.log("result", result.data);
      if (result.data) {
        await feedbackServices.addNotification(result.data)
        await feedbackServices.createLog(result?.data?.id, { comment: null, feedback_status: 1 })
        const res = await feedbackServices.receiveNotification(result?.data)
 
        if (res.data && res.data.length > 0) {
          await feedbackServices.sendEmail(result?.data)
        }
      }
    
      if (result?.code === 200) {
        form.resetFields()
        setSelect(1)
        setSelectedOrg()
        setVisible(false)
        setIsLoading(false)

        messageApi.open({
          type: "success",
          content: "Амжилттай илгээлээ",
          duration: 6
        })
      }
    } catch (error) {
      messageApi.open({
        type: "error",
        content: "Санал хүсэлт илгээхэд алдаа гарлаа",
      })
      setIsLoading(false)

    }
  }
  let name = ''
  if (tab === 1) {
    name = "Санал, хүсэлт"
  } else if (tab === 2) {
    name = "Гомдол"
  } else if (tab === 3) {
    name = "Талархал"
  }
  // console.log("selectedOrg", selectedOrg);

  return (
    <Container>
      <LoadPanelComponent position={loadPosition} visible={isLoading} />
      {contextHolder}
      <Form
        form={form}
        onFinish={onFinish}
        layout="vertical"
        requiredMark={"required"}
        id="feedback"
        initialValues={{is_org: 1}}
      >
        <Form.Item
          name="is_org"
          label="Хаашаа явуулах аа сонгоно уу"
          rules={[{ required: true }]}
        >
          <Radio.Group
            onChange={(e) => handleChange(e.target.value)}
            defaultValue={1}
            value={select}
            style={{ display: "flex", justifyContent: "center" }}
          >
            {/* {userOrg} */}
            <Radio value={1}>Байгууллага</Radio>
            <Radio value={2}>Систем</Radio>
          </Radio.Group>
        </Form.Item>
        {select === 1 && (
          <Form.Item
            name="org_id"
            rules={[{ required: true, message: "Байгууллага сонгоно уу!" }]}
          >
            <Select
              showSearch
              style={{ width: "100%", zIndex: 4 }}
              value={selectedOrg}
              dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
              placeholder="Байгууллага сонгоно уу"
              onChange={handleOrgSelect}
              filterOption={(input, option) =>
                option.props.children
                  ? option.props.children
                    .toLowerCase()
                    .includes(input.toLowerCase())
                  : false
              }
            >
              {list.map((item) => (
                // console.log(item.length),
                <Select.Option key={item.id} value={item.id}>
                  {item.organization_name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}

        <Form.Item
          label={name}
          name="question"
          rules={[{ required: true, message: "Санал хүсэлтээ оруулна уу!" }]}
        >
          <TextArea
            autoSize={{ minRows: 15, maxRows: 15 }}
            style={{ height: "7rem" }}
          />
        </Form.Item>
        <Form.Item>
          <Space style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button onClick={() => setVisible(false)} type="default">
              Болих
            </Button>
            <ConfigProvider
              theme={{
                components: {
                  Button: {
                    colorPrimary: "#E8EDF6",
                    colorText: "black",
                    algorithm: true,
                  },
                },
              }}
            >
              <Button
                htmlType="submit"
                style={{ color: "black", fontWeight: 600 }}
                type="primary"
              >
                Илгээх
              </Button>
            </ConfigProvider>
          </Space>
        </Form.Item>
      </Form>
    </Container>
  );
}

export default FeedbackForm

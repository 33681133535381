import React, {useEffect, useState} from "react"
import './a4.css'

const A4 = ({documentRef, logo, orgName, address, orgTelephone, email, date, requestCode, detail, parentOrgName, context,approvePerson}) => {

    return( 
        <page className="A4Document" ref={documentRef}>
        <div id="documentId" className={"request-document"} style={{position:'relative', height:'inherit'}}>
        <div style={{display:'flex'}}>
            <div className="col" style={{width:'50%', marginRight:'1em'}}>
                <div style={{display:'flex', justifyContent:'center', marginBottom:"1em"}}>
                    <img className='org-logo' src={logo}/>
                </div>
                <div className='org-name' >{orgName}</div>
                <div style={{marginTop:'1em'}}>
                    <div className='org-address' style={{wordBreak:'break-word'}}>{address}</div>
                    <div className='org-address'>Утас/Факс:&nbsp;{orgTelephone}</div>
                    <div className='org-address'>Цахим шуудан:&nbsp;{email}</div>
                </div>
                <div style={{marginTop:'1em'}}>
                    <div className='request-info' style={{position:'absolute',overflowWrap:'normal', display:"flex"}}>
                        <p className="request-date" style={{width:'5em'}}></p>
                         &nbsp; №:<p className="request-code" style={{width:'10em'}}></p></div>
                    {/* <div className='request-info'>Дугаар &nbsp;{requestCode}</div> */}
                </div>    
                <div style={{marginTop:'3em'}}>
                    <div className='brief-explanation' style={{position:'relative'}}>
                        <div style={{position:'absolute', top:'0px', left:"0px"}}>┌</div>
                        <div style={{textAlign:'end'}}>┐</div>
                        <div style={{marginLeft:'0.4em', marginRight:'0.4em', wordBreak:'break-word', textAlign:'justify'}}>
                            {detail}
                        </div>
                    </div>
                </div>
            </div>
            <div className='col' style={{width:'50%', marginLeft:'1em', marginTop:"1cm"}}>
                {/* <div className='brief-explanation'></div> */}
                <div className='org-name' style={{position:"relative"}}>
                        <div style={{position:'absolute', top:'0px', left:"0px"}}>┌</div>
                        <div style={{textAlign:'end'}}>┐</div>
                        {parentOrgName}
                </div>
            </div>
        </div>
        <div className='request-note' style={{marginTop:"3em"}}>
        {/* {requestContext.current} */}
            <div dangerouslySetInnerHTML={{ __html: context }} style={{ textAlign: 'justify', width: "100%" }}/>
        </div>
        <div style={{position:"absolute", top:'24cm', right:'2cm', width:"80%", wordBreak:'break-word'}} className={'request-approve'}>
            <div>
                {approvePerson}
            </div>
        </div>
    </div>
</page>
)



}

export default A4
import React, {useEffect, useState} from "react"
import {useParams} from "react-router-dom"
import newsServices from "../../services/api/news"
import ImageViewer from "../../components/ImageViewer"
import SocialLinks from "../news/component/SocialLinks"
import YoutubePlayerComponent from "../../components/YoutubePlayer"
import { Breadcrumb } from "antd"
import {Link} from 'react-router-dom'
import { BsFillPersonFill } from "react-icons/bs"
import { BiTime } from "react-icons/bi"
import styled from "styled-components"
import RightContainer from "../news/component/RightContainer"
import { processHtmlString, processScriptString } from "../../util"
import NewsSay from "../news/component/NewsSay"

const StyledContainer = styled.div`
  display: flex;
  gap: 1rem;
  padding: 1rem;
  width: 80%;
  height: 100%;
  margin: 0 auto;
  .left{
    max-width: 850px;
  }
  .youtube-section{
    width: auto;
    overflow: hidden;
  }
  @media screen and (min-width: 1025px) {
    .newsArticle{
      width: calc(95% - 300px) ;
      margin: 0 auto;
    }
   
}
@media screen and (max-width: 1400px) {
  width: 90%;
}
  @media screen and (max-width: 1200px) {
    width: 95%;
    padding: 1rem;
    flex-direction: column;
    .newsArticle{
    width: 100%;
  }
  .right-container{
    width: 100% ;
  }
}
`


function Article(props) {
  const [newsType, setNewsType]= useState(5)
  const [news, setNews] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [fileNames, setFileNames] = useState([])
  const {id} = useParams()

  useEffect(() => {
    fetchData()
  }, [id, newsType])

  const fetchData = async () => {
    try {
      setIsLoading(true)
      const result = await newsServices.getNewsById(id)
      setNews(result?.[0])
      setIsLoading(false)
      getFileData(id)
    } catch (error) {
      // console.log("error", error)
    }
  }
  const getFileData = async (id) => {
    try {
      let res = await newsServices.getNewsFileNames(id)
      setFileNames(await res?.data)
    } catch (error) {
    }
  }
  const dateConverter = (string) => {
    return new Date(string)
      .toLocaleString("en-us", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      })
      .replace(/(\d+)\/(\d+)\/(\d+)/, "$3-$1-$2")
  }

  const typeText = (news_type) => {
    switch (news_type) {
      case 1:
        return "Үйл явдал"
      case 2:
        return "Зар"
      case 3:
        return "Төсвийн тойм"
      case 4:
        return "Инфографик"
      case 5:
        return "Сүүлд нэмэгдсэн"
      case 7:
        return "Фото мэдээ"
      case 8: 
      return "Видео мэдээ"
    }
  }
  return (
    <StyledContainer>
      <div className="newsArticle" style={{ padding: '1rem'}}>
          <div className="left">
            <Breadcrumb style={{marginBottom: "1rem"}}>
              <Breadcrumb.Item>
                <Link to={"/news"}>Мэдээ, мэдээлэл</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to={{pathname: "/news", state: {type: news?.news_type}}}>
                  {typeText(news?.news_type)}
                </Link>
              </Breadcrumb.Item>
              
            </Breadcrumb>
            <div>
              <div className="title">
                <div className="news_title">
                  {news && news.news_title && news.news_title}
                </div>
              </div>

              <div className="newsArticle_content">
                <div className="meta">
                  <div className="author">
                    <BsFillPersonFill />
                    <span className="name">
                      {news && news.author && news.author}
                    </span>
                  </div>
                  <div className="date">
                    <div className="created">
                      <BiTime />
                      {news && news.createdAt && dateConverter(news.createdAt)}
                    </div>
                  </div>
                </div>
                <div className="img-container">
                  {news && news.file_name && (
                    <ImageViewer
                      array={[news]}
                      filename="file_name"
                      height="100%"
                    />
                  )}
                </div>
                <div className="article">
                  {news?.markup && (
                    <div
                      style={{marginBottom: "1rem"}}
                      className="articleHtml"
                      dangerouslySetInnerHTML={{__html: processHtmlString(news.markup)}}
                    ></div>
                  )}
                  {news && news?.news_text && (
                    <div
                      style={{marginBottom: "1rem"}}
                      className="monScriptContainer"
                      dangerouslySetInnerHTML={{
                        __html: processScriptString(news?.news_text),
                      }}
                    ></div>
                  )}
              
                  {fileNames && (
                    <ImageViewer
                      array={fileNames}
                      filename="file_name"
                      height="8vw"
                      margin="10px"
                    />
                  )}
                  <div className="youtube-section">
                    {news?.youtube_url && (
                      <YoutubePlayerComponent url={news?.youtube_url} />
                    )}
                  </div>
                  <SocialLinks
                    title={news?.news_title}
                    style={{marginTop: "1rem"}}
                  />
                  <NewsSay
                      id={news?.id}
                  />
                </div>
              </div>
            </div>
          </div>
      </div>
        <RightContainer news_type={news?.news_type}/>
      </StyledContainer>
  )
}

export default Article

import React, {useState, useEffect, useRef, useContext} from "react";
import DataGrid, {
    Column,
    Editing,
    Grouping,
    Popup as DataGridPopup,
    FilterRow,
    Selection,
    Scrolling,
    Item as DataGridItem,
    Form as DataGridForm,
    Lookup, GroupPanel, RequiredRule,
} from "devextreme-react/data-grid";
import HtmlEditor, {
    Item,
    MediaResizing,
    Toolbar, ImageUpload, TableResizing
} from "devextreme-react/html-editor";
import FileServices from "../../../services/api/file";
import {Popup} from "devextreme-react/popup";
import notify from "devextreme/ui/notify";
import ScrollView from "devextreme-react/scroll-view";
import OrganizationService from "../../../services/api/organization";
import SelectBox from 'devextreme-react/select-box';
import Button from "devextreme-react/button";
import {UserContext} from "../../../hooks/UserContext";
import Form, {Item as FormItem1, Label} from 'devextreme-react/form';
import _ from "lodash";
import FileUploader from "devextreme-react/file-uploader";
import {urlServer} from "../../../shared/lib/request";
import LoadPanelComponent from "../../budget/components/loadPanel";
import LawService from "../../../services/api/law";
import LawDropDownBoxComponent from './LawDropDownBoxComponent.js';
import strategy_icon from "../../../assets/imgs/structureIcon2.png";
import {Template} from "devextreme-react/core/template";
import WorkerDropDownBoxComponent from "../../settings/components/WorkerDropDownBoxComponent";
import iconUtga from "../../../assets/imgs/icon/icon-utga.png";
import iconEdit from "../../../assets/imgs/icon/icon-edit.png";
import iconDelete from "../../../assets/imgs/icon/icon-delete.png";
import {
    TreeList, Column as TreeColumn, Button as TreeButton
} from 'devextreme-react/tree-list';
import TextBox from "devextreme-react/text-box";
import {paletteFinanceReport, paletteZarlaga} from "../../../util";
//Main High Order Component for landing section news text context, CRUD control dashboard.
//add dependency array on row ID change
export const tabs = [
    {name: 'From This Device', value: ['file']},
    {name: 'From the Web', value: ['url']},
    {name: 'Both', value: ['file', 'url']},
];

const fontValues = [
    "Montserrat",
    "Arial",
    "Helvetica Neue",
    "Courier New",
    "Georgia",
    "Impact",
    "Lucida Console",
    "Tahoma",
    "Times New Roman",
    "Verdana",
];
const sizeValues = ["8pt", "10pt", "12pt", "14pt", "18pt", "24pt", "36pt"];
const headerValues = [false, 1, 2, 3, 4, 5];
const sizeOptions = {placeholder: "Хэмжээ"};
const fontOptions = {placeholder: "Фонт"};
const allowedFileExtensions = ['.jpg', '.jpeg', '.png'];
const allowedFileExtensions2 = ['.jpeg', '.jpg', '.png', '.pdf'];

const CrudGeneralInfo2 = (props) => {
    const [parentOrgs, setParentOrgs] = useState([]);
    const [selectedOrg, setSelectedOrg] = useState(null);
    const [selectedOrgName, setSelectedOrgName] = useState(null);
    const [loader, setLoader] = useState(false)
    const [toggler, setToggler] = useState(false)
    const [togglerImage, setTogglerImage] = useState(false)
    const [attribute, setAttribute] = useState([])
    const [attributeDetails, setAttributeDetails] = useState([])
    const [atrValue, setAtrValue] = useState(null);
    const [ssAtrValue, setSSAtrValue] = useState(null);
    const [valueChangeLoader, setValueChangeLoader] = useState(false);
    const [valueNewLoader, setValueNewLoader] = useState(false);
    const [selectedValueTypeName, setSelectedValueTypeName] = useState([]);
    const [typeValue, setTypeValue] = useState(null);
    const [orderNo, setOrderNo] = useState(null);
    const [ssa, setSSA] = useState(null);
    const {user} = useContext(UserContext);
    const ImageRef = useRef();
    const ImageHeadRef = useRef();
    const ImageBannerRef = useRef();
    const ImageBannerStructureRef = useRef();
    const ImageFileTushaalRef = useRef();
    const ImageFileStrategyRef = useRef();
    const [ssaEdit, setSSAEdit] = useState(null);
    const [fileNameData, setFileNameData] = useState(null);
    const [lawData, setLawData] = useState([])
    const ImageFileRef = useRef();
    const [filePopUpModal, setFilePopUpModal] = useState(false);
    const [fileExtention, setFileExtention] = useState(null);
    const [fileName, setFileName] = useState(null);
    const [initialAD, setIniitalAD] = useState([]);
    const ImageMinistryOrgRef = useRef();
    const [workerData, setWorkerData] = useState([]);
    const [popupBudget, setPopupBudget] = useState(false);
    const [orgBudgetOrganization, setOrgBudgetOrganization] = useState([]);
    const [orgBudget, setOrgBudget] = useState([]);
    const [orgBudgetId, setOrgBudgetId] = useState(null);
    const myGridRef = useRef(null);
    const mySubGridRef = useRef(null);
    const [selectedOBT, setSelectedOBT] = useState(null);
    const [valueNameList, setValueNameList] = useState([]);
    const [valueNameListGeneral, setValueNameListGeneral] = useState([]);
    const [popupFAQ, setPopupFAQ] = useState(false)
    const [valueName, setValueName] = useState(null);
    const [description, setDescription] = useState(null);
    const HtmlRef = useRef(null)

    console.log(user);
    useEffect(() => {
        const fetchData = async () => {
            await getParentOrgs();
        }
        fetchData();
        props.dateNow && onFixEventGeneral();

    }, [props.org_id, props.org_name, props.type, props.dateNow])
    const getParentOrgs = async () => {
        setLoader(true);
        const Orgs = await OrganizationService.getParentOrgs()
        setParentOrgs(Orgs.data);
        setLoader(false);
    }
    const getAttributeTypeName = async (typeValue, org_id) => {
        const result = await OrganizationService.getAttributeTypeName(typeValue, org_id)
        if (typeValue !== 1) {
            setValueNameListGeneral(result.data);
        } else {
            setValueNameList(result.data);
        }
    }
    const randomRef = useRef("")
    randomRef.current = ""
    const animation = {
        show: {
            type: "slide",
            from: {
                left: 1000,
                opacity: 0.2
            },
            to: {
                opacity: 1
            },
            duration: 500
        }
        ,
        hide: {
            type: "slide",
            to: {
                opacity: 0,
                right: -1000
            },
            duration: 500
        }
    }
    const onOrgBudget = async (e) => {
        if (e.row.data.org_id !== null) {
            setLoader(true)
            setPopupBudget(true);
            setSelectedOrgName(e.row.data.name);
            setSelectedOrg(e.row.data.org_id);
            let data = await OrganizationService.getOrgWorker(e.row.data.org_id,null,0,0,'');
            setWorkerData(data.data);
            let result = await OrganizationService.getOrgBudgetOrganization(e.row.data.org_id);
            setOrgBudgetOrganization(result.data);
            let result1 = await OrganizationService.getOrgBudget();
            setOrgBudget(result1.data);
            setLoader(false)
        } else {
            return notify(
                {
                    message: 'Байгууллага холбогдоогүй учир төсөв удирдах боломжгүй', width: 300, position: {
                        my: 'right top',
                        at: 'right top',
                        of: "#floating-div-2"
                    },
                    animation: animation
                }
                // error
                , "warning", 2000);
        }
    }
    const getOrgBudget = async (org_id) => {
        const result = await OrganizationService.getOrgBudgetOrganization(org_id)
        setOrgBudgetOrganization(result.data);
    }
    const onFixEvent = async (e) => {
        if (e.row.data.org_id !== null) {
            //setLoader(true)
            setToggler(true);
            // console.log("event", e)
            setSelectedOrgName(e.row.data.name);
            setSelectedOrg(e.row.data.org_id);
            setTypeValue(1);
            let data = await OrganizationService.getOrgWorker(e.row.data.org_id,null,0,0,'');
            setWorkerData(data.data);
            await getAttribute(e.row.data.org_id, 1);
            await getAttributeTypeName(1, e.row.data.org_id);
            // console.log("selected org", selectedOrg)
            //setLoader(false)
        } else {
            return notify(
                {
                    message: 'Байгууллага холбогдоогүй учир үндсэн мэдээлэл удирдах боломжгүй', width: 300, position: {
                        my: 'right top',
                        at: 'right top',
                        of: "#floating-div-2"
                    },
                    animation: animation
                }
                // error
                , "warning", 2000);
        }
    }
    const onImageEvent = async (e) => {
        if (e.row.data.org_id !== null) {
            setLoader(true)
            setTogglerImage(true);
            setSelectedOrg(e.row.data.org_id);
            setSelectedOrgName(e.row.data.name);
            ImageRef.current = undefined;
            ImageHeadRef.current = undefined;
            ImageBannerRef.current = undefined;
            ImageBannerStructureRef.current = undefined;
            ImageFileTushaalRef.current = undefined;
            ImageFileStrategyRef.current = undefined;
            ImageMinistryOrgRef.current = undefined;
            let result = await OrganizationService.getFileNameStateStructure(e.row.data.org_id);
            //console.log('resultass', result);
            setFileNameData(result.data[0]);
            // console.log("selected org", selectedOrg)
            setLoader(false)
        } else {
            return notify(
                {
                    message: 'Байгууллага холбогдоогүй учир файл удирдах боломжгүй', width: 300, position: {
                        my: 'right top',
                        at: 'right top',
                        of: "#floating-div-2"
                    },
                    animation: animation
                }
                // error
                , "warning", 2000);
        }
    }
    const onFixEventGeneral = async (e) => {
        if (e.row.data.org_id !== null) {
            //setLoader(true)
            setToggler(true);
            /*  console.log("event", e)*/
            setSelectedOrg(props.org_id ? props.org_id : e.row.data.org_id);
            setSelectedOrgName(props.org_id ? props.org_name : e.row.data.name);
            setTypeValue(2);
            setSelectedOBT(e.row.data.org_budget_type_id);
            let data = await OrganizationService.getOrgWorker(e.row.data.org_id,null,0,0,'');
            setWorkerData(data.data);
            await getAttribute(props.org_id ? props.org_id : e.row.data.org_id, 2);
            await getLawsByStartOfAncestry();
            await getAttributeTypeName(2, e.row.data.org_id);
            // console.log("selected org", selectedOrg)
            //setLoader(false)
        } else {
            return notify(
                {
                    message: 'Байгууллага холбогдоогүй учир ерөнхий мэдээлэл удирдах боломжгүй', width: 300, position: {
                        my: 'right top',
                        at: 'right top',
                        of: "#floating-div-2"
                    },
                    animation: animation
                }
                // error
                , "warning", 2000);
        }
    }
    const onFAQ = async (e) => {
        if (e.row.data.org_id !== null) {
            setPopupFAQ(true);
            // console.log("event", e)
            setSelectedOrgName(e.row.data.name);
            setSelectedOrg(e.row.data.org_id);
            setTypeValue(3);
            await getAttribute(e.row.data.org_id, 3);
        } else {
            return notify(
                {
                    message: 'Байгууллага холбогдоогүй учир түгээмэл асуулт,хариулт удирдах боломжгүй',
                    width: 300,
                    position: {
                        my: 'right top',
                        at: 'right top',
                        of: "#floating-div-2"
                    },
                    animation: animation
                }
                // error
                , "warning", 2000);
        }
    }
    const getLawsByStartOfAncestry = async () => {
        setLoader(true)
        let result = await LawService.getLawsByStartOfAncestry()
        setLawData(await result.data);
        // console.log("result",result)
        setLoader(false)
    }
    const getAttribute = async (id, type) => {
        setLoader(true)
        let result = await OrganizationService.getStateStructureAttributeList(id, type)
        setAttribute(await result.data)
        // console.log("result",result)
        setLoader(false)
    }
    const onSelectionAttribute = async ({selectedRowsData}) => {
        if (selectedRowsData[0].id !== undefined) {
            setLoader(true);
            //console.log('selectedRowsData', selectedRowsData);
            // setAttributeDetails(selectedRowsData[0]);
            setSSA(selectedRowsData[0]);
            const result = await OrganizationService.getAttributeBySSI(selectedRowsData[0].id);
            //console.log('resdsss ', result);
            setAttributeDetails(result.data);
            setLoader(false)
        } else
            return setLoader(false);

    }
    const onEditingStartEvent = (e) => {
        setIniitalAD(e.data);
    }
    const onEditingStartEventBudget = (e) => {
        setOrgBudgetId(e.data.org_budget_id);
    }
    const onSavingEvent = async (e) => {
        if (e.changes.length === 0 && ImageFileRef.current[0]) {
            try {
                setLoader(true);
                let file = await saveFile(2);
                initialAD.updated_user_id = user.id;
                initialAD.org_id = selectedOrg;
                initialAD.file_id = file.data.id;
                initialAD.is_opendata =  e.changes[0] && e.changes[0].data.is_opendata === true ? 1 : 0;
                let result = await OrganizationService.updateStateAttribute(initialAD);
                await getAttribute(selectedOrg, typeValue);
                ImageFileRef.current = null;
                setLoader(false);
                if (result?.code === 200) {
                    //console.log(result)
                    return notify({
                        message: 'Амжилттай хадгаллаа', width: 300, position: {
                            my: 'right top',
                            at: 'right top',
                            of: "#floating-div-2"
                        },
                        animation: animation
                    }, "success", 4000);
                }
            } catch (error) {
                console.log('error', error);
                return notify(
                    "Хадгалах үйлдэл амжилтгүй боллоо"
                    // error
                    , "warning", 4000);

            }
        } else if (e.changes[0].type === "remove") {

            try {
                setLoader(true);
                await OrganizationService.removeSSA(e.changes[0].key);
                setLoader(false);
                notify({
                    message: 'Амжилттай устгагдлаа', width: 300, position: {
                        my: 'right top',
                        at: 'right top',
                        of: "#floating-div-2"
                    },
                    animation: animation
                }, "success", 2000);
            } catch (e) {
                notify('Устгах явцад алдаа гарлаа', "error", 2000);
            }
        } else {
            try {
                setLoader(true);
                let data = {}
                let r1 = _.filter(valueNameListGeneral, {name: e.changes[0].data.name});
                let file = await saveFile(2);
                data.name = e.changes[0].data.name;
                data.value = e.changes[0].data.value;
                data.description = typeValue === 1 ? e.changes[0].description : r1[0].description;
                data.is_opendata = e.changes[0].data.is_opendata === true ? 1 : 0;
                data.created_user_id = user.id;
                data.updated_user_id = user.id;
                data.org_id = selectedOrg;
                data.law_id = e.changes[0].data.law_id;
                data.file_id = file ? file.data.id : null;
                data.approved_worker_id = e.changes[0].data.approved_worker_id;
                let result = await OrganizationService.updateStateAttribute(data);
                await getAttribute(selectedOrg, typeValue);
                setLoader(false);
                ImageFileRef.current = null;
                if (result?.code === 200) {
                    // console.log(result)
                    return notify({
                        message: 'Амжилттай хадгаллаа', width: 300, position: {
                            my: 'right top',
                            at: 'right top',
                            of: "#floating-div-2"
                        },
                        animation: animation
                    }, "success", 4000);
                }
            } catch (error) {
                console.log('error', error);
                return notify(
                    "Хадгалах үйлдэл амжилтгүй боллоо"
                    // error
                    , "warning", 4000);

            }
        }

    }
    const onSaveOrgBudgetOrganization = async (e) => {
        if (e.changes.length === 0 && ImageFileRef.current) {
            try {
                setLoader(true);
                let result = await saveFileBudget(orgBudgetId, selectedOrg, 2);
                await getOrgBudget(selectedOrg);
                setLoader(false);
                ImageFileRef.current = null;
                if (result?.code === 200) {
                    return notify({
                        message: 'Амжилттай хадгаллаа', width: 300, position: {
                            my: 'right top',
                            at: 'right top',
                            of: "#floating-div-2"
                        },
                        animation: animation
                    }, "success", 4000);
                }
            } catch (error) {
                console.log('error', error);
                return notify(
                    "Хадгалах үйлдэл амжилтгүй боллоо"
                    // error
                    , "warning", 4000);

            }
        } else {
            try {
                setLoader(true);
                await saveFileBudget(e.changes[0].data.org_budget_id, selectedOrg, 2)
                let data = {}
                data.org_budget_id = e.changes[0].data.org_budget_id;
                data.org_id = selectedOrg;
                data.status =  e.changes[0].data.status === true ? 1 : 0;
                data.created_user_id = user.id;
                data.updated_user_id = user.id;
                data.approved_worker_id = e.changes[0].data.approved_worker_id;
                data.budget_year = e.changes[0].data.budget_year;
                let result = await OrganizationService.updateOrgBudgetOrganization(data);
                await getOrgBudget(selectedOrg);
                setLoader(false);
                ImageFileRef.current = null;
                if (result?.code === 200) {
                    return notify({
                        message: 'Амжилттай хадгаллаа', width: 300, position: {
                            my: 'right top',
                            at: 'right top',
                            of: "#floating-div-2"
                        },
                        animation: animation
                    }, "success", 4000);
                }
            } catch (error) {
                console.log('error', error);
                return notify(
                    "Хадгалах үйлдэл амжилтгүй боллоо"
                    // error
                    , "warning", 4000);

            }
        }

    }
    const onEventAttributeDetails = async (e) => {
        if (e.changes[0].type === "remove") {
            try {
                await OrganizationService.removeAttributeDetails(e.changes[0].key);
                notify({
                    message: 'Амжилттай устгагдлаа', width: 300, position: {
                        my: 'right top',
                        at: 'right top',
                        of: "#floating-div-2"
                    },
                    animation: animation
                }, "success", 2000);
            } catch (e) {
                notify('Устгах явцад алдаа гарлаа', "error", 2000);
            }
        } else {

            let data = {}
            data.id = e.changes[0].data.id;
            data.sattr_id = e.changes[0].data.sattr_id;
            data.order = e.changes[0].data.order;
            data.policy_desc = e.changes[0].data.policy_desc;
            data.description = e.changes[0].data.description;
            data.agency = e.changes[0].data.agency;
            data.branch = e.changes[0].data.branch;
            data.updated_user_id = user.id;
            data.is_single_field = e.changes[0].data.is_single_field;
            try {
                let result = await OrganizationService.updateAttributeDetails(data);
                if (result?.code === 200) {
                    return notify({
                        message: 'Амжилттай хадгаллаа', width: 300, position: {
                            my: 'right top',
                            at: 'right top',
                            of: "#floating-div-2"
                        },
                        animation: animation
                    }, "success", 4000);
                }
            } catch (error) {
                console.log('Хадгалах үйлдэл амжилтгүй боллоо ', error)
                return notify(
                    "Хадгалах үйлдэл амжилтгүй боллоо"
                    // error
                    , "warning", 4000);

            }
        }
    }

    const fileSubmit = async (fileData, typeId, size) => {
        try {
            setLoader(true)
            // console.log('fileData', fileData);
            if (fileData[0].size >= size) {
                notify(`Оруулсан файл хэмжээ ${size / 1000000}MB ээс их байна`, "warning", 3000);
                return 0;
            }
            let data = new FormData();
            data.append("file", fileData[0]);
            const res = await FileServices.uploadFileStructure(data, typeId, selectedOrg);
            setLoader(false)
            //console.log("respone file submit", res)
            notify({
                message: 'Амжилттай хадгаллаа', width: 300, position: {
                    my: 'right top',
                    at: 'right top',
                    of: "#floating-div-2"
                },
                animation: animation
            }, "success", 3000);
            setTogglerImage(false);
            return res.data;


        } catch (e) {
            console.log(e);
            return notify("Хадгалах үйлдэл амжилтгүй боллоо", "warning", 3000);
        }
    };
    const submitFile = async () => {
        //console.log('ImageHeadRef', ImageHeadRef);
        //console.log('ImageRef', ImageRef);
        ImageRef.current !== undefined && await fileSubmit(ImageRef.current, 11, 30000000);
        ImageHeadRef.current !== undefined && await fileSubmit(ImageHeadRef.current, 10, 30000000);
        ImageBannerRef.current !== undefined && await fileSubmit(ImageBannerRef.current, 7, 10000000);
        ImageBannerStructureRef.current !== undefined && await fileSubmit(ImageBannerStructureRef.current, 8, 10000000);
        ImageFileTushaalRef.current !== undefined && await fileSubmit(ImageFileTushaalRef.current, 2, 20000000);
        ImageFileStrategyRef.current !== undefined && await fileSubmit(ImageFileStrategyRef.current, 3, 20000000);
        ImageMinistryOrgRef.current !== undefined && await fileSubmit(ImageMinistryOrgRef.current, 18, 25000000);
    };
    const customEditRow = (grid, selectedRowIndex) => {
        grid.current.instance.editRow(selectedRowIndex);
    }
    const customDeleteRow = (grid, selectedRowIndex) => {
        grid.current.instance.deleteRow(selectedRowIndex);
    }
    const valueCell = (data) => {
        // console.log('daaaaaaa', data);
        return <div style={{display: 'flex', justifyContent: 'flex-start', flexDirection: 'row'}}>
            <Button
                //type="default"
                icon={iconUtga}
                //stylingMode="outlined"
                style={{
                    width: 'auto',
                    height: 'auto',
                    opacity: 1,
                    backgroundColor: "#2a4565"
                }}
                onClick={() => {
                    columnInfo(data)
                }}
            />
            <Button
                //type="default"
                icon={iconEdit}
                //stylingMode="outlined"
                style={{
                    width: 'auto',
                    height: 'auto',
                    opacity: 1,
                    backgroundColor: "#37C590"
                }}
                visible={typeValue === 3 ? false : (data.data.value === undefined && selectedOBT !== 1 ? false : true)}
                onClick={() => {
                    customEditRow((data.data.value !== undefined && typeValue !== 3) ? myGridRef : mySubGridRef, data.rowIndex)
                }}
            />
            <Button
                //type="default"
                icon={iconDelete}
                //stylingMode="outlined"
                //visible={typeValue == 1 && data.data.value ? false : true}
                style={{
                    width: 'auto',
                    height: 'auto',
                    opacity: 1,
                    backgroundColor: "#F44336"
                }}
                onClick={() => {
                    customDeleteRow(data.data.value !== undefined ? myGridRef : mySubGridRef, data.rowIndex)
                }}
            />
        </div>
    }
    const columnInfo = (data) => {
        setAtrValue(data.data.policy_desc === undefined ? data.data.value : data.data.policy_desc);
        //HtmlRef.current =  data.data.policy_desc === undefined ? data.data.value : data.data.policy_desc;
        setSSAEdit(data.data.policy_desc === undefined ? data.data.policy_desc : null);
        setSSAtrValue(data.data);
        setOrderNo(typeValue === 1 ? null : typeValue === 3 ? data.data.order_no : data.data.order);
        setDescription(data.data.policy_desc === undefined ? data.data.description : ssa.description);
        setValueChangeLoader(true);
    }
    const onValueChangeInfo = (e) => {
        HtmlRef.current = e.value
        //setAtrValue(e.value);
    }
    const onSaveSSA = async () => {
        let data = {}
        data.name = valueChangeLoader ? ssAtrValue.name : typeValue === 3 ? 'faq' + Date.now() : selectedValueTypeName.name;
        data.value = HtmlRef.current !== null ? HtmlRef.current : atrValue;
        data.description = valueChangeLoader ? ssAtrValue.description : typeValue === 3 ? valueName : selectedValueTypeName.description;
        data.is_opendata = valueChangeLoader ? (ssAtrValue.is_opendata === true ? 1 : 0) : 0;
        data.org_id = selectedOrg;
        data.created_user_id = user.id;
        data.updated_user_id = user.id;
        data.order_no = orderNo;
        try {
            let result = await OrganizationService.updateStateAttribute(data);
            await getAttribute(selectedOrg, typeValue);
            if (result?.code === 200) {
                setValueNewLoader(false);
                setValueChangeLoader(false);
                HtmlRef.current = null;
                return notify({
                    message: 'Амжилттай хадгаллаа', width: 300, position: {
                        my: 'right top',
                        at: 'right top',
                        of: "#floating-div-2"
                    },
                    animation: animation
                }, "success", 4000);

            }
        } catch (error) {
            console.log('Хадгалах үйлдэл амжилтгүй боллоо ', error)
            return notify(
                "Хадгалах үйлдэл амжилтгүй боллоо"
                // error
                , "warning", 4000);

        }
    }

    const onCreateAttributeDetails = async () => {
        let data = {}
        data.id = 0;
        data.sattr_id = ssa.id;
        data.order = orderNo;
        data.policy_desc = HtmlRef.current !== null ? HtmlRef.current : atrValue;
        data.created_user_id = user.id;
        try {
            let result = await OrganizationService.updateAttributeDetails(data);
            if (result?.code === 200) {
                HtmlRef.current = null;
                setValueNewLoader(false);
                setValueChangeLoader(false);
                return notify({
                    message: 'Амжилттай хадгаллаа', width: 300, position: {
                        my: 'right top',
                        at: 'right top',
                        of: "#floating-div-2"
                    },
                    animation: animation
                }, "success", 4000);
            }
        } catch (error) {
            console.log('Хадгалах үйлдэл амжилтгүй боллоо ', error)
            return notify(
                "Хадгалах үйлдэл амжилтгүй боллоо"
                // error
                , "warning", 4000);

        }
    }

    const onSaveAttributeDetails = async () => {
        let data = {}
        //console.log('ssAtrValue aaaa', ssAtrValue);
        data.id = ssAtrValue.id;
        data.sattr_id = ssAtrValue.sattr_id;
        data.order = orderNo;
        data.policy_desc = HtmlRef.current !== null ? HtmlRef.current : atrValue;
        data.updated_user_id = user.id;
        try {
            let result = await OrganizationService.updateAttributeDetails(data);
            let res = await OrganizationService.getAttributeBySSI(ssa.id);
            setAttributeDetails(res.data);
            if (result?.code === 200) {
                setValueNewLoader(false);
                setValueChangeLoader(false);
                return notify({
                    message: 'Амжилттай хадгаллаа', width: 300, position: {
                        my: 'right top',
                        at: 'right top',
                        of: "#floating-div-2"
                    },
                    animation: animation
                }, "success", 4000);
            }
        } catch (error) {
            console.log('Хадгалах үйлдэл амжилтгүй боллоо ', error)
            return notify(
                "Хадгалах үйлдэл амжилтгүй боллоо"
                // error
                , "warning", 4000);
        }
    }
    const onChangeValueNew = (e) => {
        setOrderNo(null);
        setValueName(null);
        setAtrValue(null);
        setSSAtrValue(null);
        HtmlRef.current = null;
        setValueNewLoader(true);
    }
    const onChangeValueTypeName = async (e) => {
        setSelectedValueTypeName(e);
    }
    const onChangeOrderNo = (event) => {
        setOrderNo(event);
    }
    const onChangeValueName = (event) => {
        setValueName(event);
    }
    const onEditorPreparing = (e) => {
        if (typeValue === 1 ? e.parentType === "dataRow" && e.dataField !== "description" : e.parentType === "dataRow" && e.dataField !== "name") {
            return;
        }
        if (e.row && e.row.isNewRow) {
            e.editorOptions.visible = true;
        }
        if (e.row && !e.row.isNewRow) {
            // e.editorOptions.visible = false;
            e.editorOptions.disabled = true;
        }

    }
    const notesEditorOptions = {height: 180};
    const columnInfoFile = (data) => {
        setFileName(data.data.file_name);
        setFileExtention(data.data.file_extention)
        setFilePopUpModal(true);
    }
    const renderGridFile = (data) => {
        return data.text && <div style={{textAlign: 'center'}}>
            <Button
                //  style={{width: "100px", height: '25px'}}
                icon={strategy_icon}
                stylingMode="text"
                style={{
                    width: 'auto',
                    height: '35px',
                    opacity: 1
                }}
                onClick={() => {
                    columnInfoFile(data)
                }}
            />
        </div>
    }
    const Template_upload = () => {
        //  console.log(cellData);
        return (
            <>
                <FileUploader
                    allowedFileExtensions={allowedFileExtensions2}
                    onValueChanged={ImageChangeFile}
                    selectButtonText="Файл сонгох"
                    labelText=""
                    accept="*"
                    uploadMode="useForm"
                />
                <span className="note">{'Файлын өргөтгөл: '}
                    <span>.pdf .jpg, .jpeg, .png</span>
                </span>
            </>
        );
    }
    const saveFile = async (typeId) => {
        //  console.log('typeID: ', typeId);
        if (ImageFileRef.current) {
            let data = new FormData();
            data.append("file", ImageFileRef.current[0]);
            const res = await FileServices.uploadFile(data, typeId);
            return res
        } else return null
    }

    const saveFileBudget = async (org_budget_id, org_id, typeId) => {
        //  console.log('typeID: ', typeId);
        if (ImageFileRef.current && org_budget_id && org_id) {
            let data = new FormData();
            data.append("file", ImageFileRef.current[0]);
            const res = await FileServices.uploadFile(data, typeId);
            const fileData = {
                file_id: res.data.id,
                org_budget_id: org_budget_id,
                org_id: org_id,
                file_type_id: typeId,
                created_user_id: user.id
            }
            await FileServices.setFileBudget(fileData);
            return res;
        } else return null
    }
    const renderGridCell = (data) => {
        return data.text && <a href={data.text} target='_blank' rel='noopener noreferrer'>Хуулийн линк</a>;
    }
    const customAddRow = (grid) => {
        grid.current.instance.addRow();
    }
    const onCellPreparedHandler = (e) => {
        if (e.rowType === 'header') {
            e.cellElement.wordWrapEnabled = true;
            e.cellElement.style.fontFamily = 'Montserrat'
            e.cellElement.style.fontWeight = 'semibold'
            e.cellElement.style.color = "#15186A"
            e.cellElement.style.backgroundColor = "#EEF0F4"
        }
        if (e.rowType === 'data') {
            e.cellElement.style.fontFamily = 'Montserrat'
            e.cellElement.style.fontWeight = 'semibold'
            e.cellElement.style.color = "#000000"
        }
    }
    const renderFixMenu = props => {
        return (
            <Popup
                visible={toggler}
                onHiding={() => setToggler(false)}
                hideOnOutsideClick={true}
                showCloseButton={true}
                height="95vh"
                showTitle={true}
                title={typeValue === 1 ? selectedOrgName + "-Үндсэн мэдээлэл удирдах" : selectedOrgName + "-Ерөнхий мэдээлэл удирдах"}
            >
                {
                    typeValue === 1 &&
                    <div className="col-12 row" style={{justifyContent: "flex-end", gap: '2rem', padding: '5px'}}>
                        <Button text="Утга оруулах" onClick={onChangeValueNew} width={180} type="default" icon={"add"}
                                style={{color: '#fff', textAlign: "center",family: "'Montserrat', sans-serif",marginRight:10,backgroundColor: "#225D72"}}/>
                    </div>
                }
                <ScrollView height="97%" width="100%">
                    <LoadPanelComponent
                        position={{of: '#renderFixMenu1'}}
                        visible={loader}
                    />
                    <div>
                        {fileName &&
                            <Popup
                                width={1200}
                                maxWidth={'100%'}
                                minHeight={'100%'}
                                height={'100%'}
                                title={fileName && fileName}
                                visible={filePopUpModal}
                                onHiding={() => {
                                    setFilePopUpModal(false)
                                }}
                                dragEnabled={false}
                                hideOnOutsideClick={true}
                            >
                                {
                                    fileExtention === 'application/pdf' ? <object width="100%" height="100%"
                                                                                  data={urlServer + '/api/file/documents/' + fileName}
                                                                                  type="application/pdf"/>
                                        :
                                        <ScrollView> <img
                                            src={urlServer + '/api/file/documents/' + fileName}
                                            width={'100%'}/> </ScrollView>

                                }

                            </Popup>}
                        {
                            typeValue !== 1 &&
                            <div className="col-12 row"
                                 style={{justifyContent: "flex-end", gap: '2rem', padding: '5px'}}>
                                <Button text="Гарчиг нэмэх" onClick={() => customAddRow(myGridRef)} width={180}
                                        type="default" icon={"add"}
                                        style={{color: '#fff', textAlign: "center",family: "'Montserrat', sans-serif",marginRight:10,backgroundColor: "#225D72"}}/>
                            </div>
                        }
                        <DataGrid dataSource={attribute}
                                  noDataText="Мэдээлэл ороогүй байна"
                                  keyExpr="id"
                                  style={{ color: '#fff', textAlign: "center",family: "'Montserrat', sans-serif"}}
                                  onSaved={onSavingEvent}
                                  onEditingStart={onEditingStartEvent}
                                  onSelectionChanged={typeValue === 1 ? null : onSelectionAttribute}
                                  onEditorPreparing={onEditorPreparing}
                                  wordWrapEnabled={true}
                                  showBorders={true}
                                  showColumnLines={true}
                                  showRowLines={true}
                                  focusedRowEnabled={true}
                                  ref={myGridRef}
                                  onCellPrepared={onCellPreparedHandler}
                        >
                            <Selection mode="single"/>
                            <Column dataField="id" caption="ID" visible={false}/>
                            {
                                typeValue === 1 ?
                                    <Column dataField="description" caption="Талбарын нэр" dataType="string"/> :
                                    <Column dataField="name" caption="Талбарын нэр" dataType="string" width={300}>
                                        <RequiredRule
                                            message='Талбарын нэр сонгоно уу'
                                        />
                                        <Lookup
                                            valueExpr="name"
                                            displayExpr="description"
                                            dataSource={valueNameListGeneral}
                                        />
                                    </Column>
                            }
                            <Column dataField="is_opendata" caption="Олон нийтэд харуулах эсэх" dataType="boolean">
                            </Column>
                            {
                                typeValue === 2 &&
                                <Column dataField="law_id" caption="Хууль" dataType="string"
                                        cellRender={renderGridCell}
                                        editCellComponent={LawDropDownBoxComponent}>
                                    <Lookup
                                        valueExpr="id"
                                        displayExpr="legal_url"
                                        dataSource={lawData}
                                    />
                                </Column>
                            }
                            {
                                typeValue === 2 &&
                                <Column
                                    dataField="file_id"
                                    caption="Файл"
                                    editCellTemplate={"file_name"}
                                    width={50}
                                    cellRender={renderGridFile}
                                />

                            }

                            {
                                typeValue === 2 && <Template name={"file_name"} render={Template_upload}/>
                            }
                            <Column dataField="created_user_name" caption="Үүсгэсэн хэрэглэгч" dataType="string"/>
                            <Column dataField="updated_user_name" caption="Зассан хэрэглэгч" dataType="string"/>
                            <Column dataField="createdAt" dataType="date" format="yyyy-MM-dd hh:mm:ss"
                                    caption="Үүсгэсэн огноо"/>
                            <Column dataField="updatedAt" dataType="date" format="yyyy-MM-dd hh:mm:ss"
                                    caption="Зассан огноо"/>
                            <Column dataField="approved_worker_id" caption="Зөвшөөрсөн" dataType="string"
                                    editCellComponent={WorkerDropDownBoxComponent}>
                            <Lookup
                                    valueExpr="wid"
                                    displayExpr="first_name"
                                    dataSource={workerData}
                                />
                            </Column>
                            <Column dataField="approved_worker_date" dataType="date" format="yyyy-MM-dd"
                                    caption="Зөвшөөрсөн огноо" hidingPriority={4}/>
                            <Column dataField="value" caption="Үйлдэл" cellRender={valueCell} width={130}/>
                            <Editing
                                allowUpdating={false}
                                allowDeleting={false}
                                allowAdding={false}
                                mode="popup"
                                useIcons={true}
                                texts={{
                                    cancelAllChanges: 'Болих',
                                    cancelRowChanges: 'Болих',
                                    confirmDeleteMessage: 'Энэ мэдээллийг устгах уу?',
                                    confirmDeleteTitle: 'Баталгаажуулах',
                                    deleteRow: 'Устгах',
                                    editRow: 'Өөрчлөх',
                                    saveAllChanges: 'Хадгалах',
                                    saveRowChanges: 'Хадгалах',
                                    undeleteRow: 'Буцаах',
                                    validationCancelChanges: 'Баталгаажуулах болих',
                                }}
                            >
                                <DataGridPopup title="Ерөнхий мэдээлэл удирдах" showTitle={true} width={1000}
                                               height={"70%"} closeOnOutsideClick={true}/>
                                <DataGridForm>
                                    <DataGridItem itemType="group" colCount={1} colSpan={2}>
                                        <DataGridItem dataField={typeValue === 1 ? "description" : "name"}/>
                                        <DataGridItem dataField="is_opendata"/>
                                        {typeValue === 2 && <DataGridItem dataField="law_id"/>}
                                        {typeValue === 2 && <DataGridItem dataField="file_id"/>}
                                        {typeValue === 1 &&
                                            <DataGridItem dataField="value" colSpan={2} editorType="dxTextArea"
                                                          editorOptions={notesEditorOptions}/>}
                                        <DataGridItem dataField="approved_worker_id"/>
                                    </DataGridItem>
                                </DataGridForm>
                            </Editing>

                        </DataGrid>
                    </div>
                    <div style={{marginTop: 10}}>
                        {
                            attributeDetails && typeValue !== 1 &&
                            <div>
                                <div className="col-12 row"
                                     style={{justifyContent: "flex-end", gap: '2rem', padding: '5px'}}>
                                    <Button text="Утга оруулах" onClick={onChangeValueNew} width={180} type="default"
                                            icon={"add"}
                                            style={{color: '#fff', textAlign: "center",family: "'Montserrat', sans-serif",marginRight:10,backgroundColor: "#225D72"}}/>
                                </div>
                                <DataGrid dataSource={attributeDetails}
                                          noDataText="Мэдээлэл ороогүй байна"
                                          style={{ color: '#fff', textAlign: "center",family: "'Montserrat', sans-serif"}}
                                          keyExpr="id"
                                          onSaved={onEventAttributeDetails}
                                          ref={mySubGridRef}
                                          onCellPrepared={onCellPreparedHandler}
                                          showBorders={true}
                                          showColumnLines={true}
                                          showRowLines={true}
                                >
                                    <Column dataField="id" caption="ID" visible={false}/>
                                    <Column dataField="order" caption="Эрэмбэ" dataType="number" width={100}/>
                                    <Column dataField="created_user_name" caption="Үүсгэсэн хэрэглэгч"
                                            dataType="string"/>
                                    <Column dataField="updated_user_name" caption="Зассан хэрэглэгч" dataType="string"/>
                                    <Column dataField="createdAt" dataType="date" format="yyyy-MM-dd hh:mm:ss"
                                            caption="Үүсгэсэн огноо"/>
                                    <Column dataField="updatedAt" dataType="date" format="yyyy-MM-dd hh:mm:ss"
                                            caption="Зассан огноо"/>
                                    {selectedOBT === 1 &&
                                        <Column dataField="description" caption="Хэрэгжүүлэх үйл ажиллагаа"
                                                dataType="string" hidingPriority={0}/>}
                                    {selectedOBT === 1 &&
                                        <Column dataField="agency" caption="Яамны хэрэгжүүлэх газар хэлтэс"
                                                dataType="string" hidingPriority={1}>
                                        </Column>}
                                    {selectedOBT === 1 && <Column dataField="branch"
                                                                  caption="Яамны харьяа хэрэгжүүлэх агентлаг бусад байгууллага"
                                                                  dataType="string" hidingPriority={2}/>}
                                    {selectedOBT === 1 &&
                                        <Column dataField="is_single_field" caption="Бусад талбаруудыг харуулах эсэх"
                                                dataType="boolean">
                                        </Column>}
                                    <Column dataField="policy_desc" caption="Үйлдэл" cellRender={valueCell}
                                            width={130}/>
                                    <Editing
                                        allowUpdating={false}
                                        allowAdding={false}
                                        allowDeleting={false}
                                        mode="popup"
                                        useIcons={true}
                                        texts={{
                                            cancelAllChanges: 'Болих',
                                            cancelRowChanges: 'Болих',
                                            confirmDeleteMessage: 'Энэ мэдээллийг устгах уу?',
                                            confirmDeleteTitle: 'Баталгаажуулах',
                                            deleteRow: 'Устгах',
                                            editRow: 'Өөрчлөх',
                                            saveAllChanges: 'Хадгалах',
                                            saveRowChanges: 'Хадгалах',
                                            undeleteRow: 'Буцаах',
                                            validationCancelChanges: 'Баталгаажуулах болих',
                                        }}
                                    >
                                        <DataGridPopup title="Ерөнхий мэдээлэл удирдах" showTitle={true} width={1000}
                                                       height={"100%"}/>
                                        <DataGridForm>
                                            <DataGridItem itemType="group" colCount={1} colSpan={2}>
                                                <DataGridItem dataField="order"/>
                                            </DataGridItem>
                                            <DataGridItem dataField="description" colSpan={2} editorType="dxTextArea"
                                                          editorOptions={notesEditorOptions}/>
                                            <DataGridItem dataField="agency" colSpan={2} editorType="dxTextArea"
                                                          editorOptions={notesEditorOptions}/>
                                            <DataGridItem dataField="branch" colSpan={2} editorType="dxTextArea"
                                                          editorOptions={notesEditorOptions}/>
                                            <DataGridItem dataField="is_single_field" colSpan={2}/>
                                        </DataGridForm>
                                    </Editing>

                                </DataGrid>
                            </div>

                        }
                    </div>
                </ScrollView>
            </Popup>
        )
    }
    const renderValuePopup = (props) => {
        return (
            <Popup
                visible={valueChangeLoader}
                onHiding={() => setValueChangeLoader(false)}
                dragEnabled={false}
                hideOnOutsideClick={true}
                showTitle={true}
                title={description !== null ? description : "Мэдээлэл"}
                width="95%"
                // key={data.data.id}
                // titleRender={renderTitle}
                height="90%"
            >
                <ScrollView height="100%" width="100%" >
                    <div className="widget-container">
                        {(ssaEdit !== undefined || typeValue !== 1) && <span style={{marginLeft: 10}}>Эрэмбэ</span>}
                        <div className="col-12 row" style={{
                            justifyContent: typeValue === 1 ? "flex-end" : "space-between",
                            gap: '2rem',
                            padding: '5px 15px'
                        }}>
                            {(ssaEdit !== undefined || typeValue !== 1) && <TextBox
                                width={'10%'}
                                mode={'number'}
                                labelMode={'floating'}
                                stylingMode='outlined'
                                placeholder='Эрэмбэ оруулах'
                                showClearButton={true}
                                value={orderNo} onValueChange={onChangeOrderNo}/>}
                            <Button text="Хадгалах" onClick={ssaEdit === undefined ? onSaveSSA : onSaveAttributeDetails}
                                    width={150} type="default" icon={'save'} style={{color: '#fff', textAlign: "center",family: "'Montserrat', sans-serif",marginRight:10,backgroundColor: "#225D72"}}/>
                        </div>
                        {
                            <div height="100%" width="100%" id="htmlEditorEdit" style={{backgroundColor:"#fff"}}>
                            <HtmlEditor defaultValue={atrValue} onValueChanged={onValueChangeInfo} valueType="html">
                                <MediaResizing enabled={true}/>
                                <TableResizing enabled={true}/>
                                <ImageUpload fileUploadMode="both"
                                             tabs={tabs[2].value}
                                             uploadUrl={urlServer + '/api/file/documents/'}
                                             uploadDirectory="/"/>
                                <Toolbar multiline={true}>
                                    <Item name="separator"/>
                                    <Item
                                        name="size"
                                        acceptedValues={sizeValues}
                                        options={sizeOptions}
                                    />
                                    <Item
                                        name="font"
                                        acceptedValues={fontValues}
                                        options={fontOptions}
                                    />
                                    <Item name="separator"/>
                                    <Item name="separator"/>
                                    <Item name="bold"/>
                                    <Item name="italic"/>
                                    <Item name="strike"/>
                                    <Item name="underline"/>
                                    <Item name="separator"/>
                                    <Item name="alignLeft"/>
                                    <Item name="alignCenter"/>
                                    <Item name="alignRight"/>
                                    <Item name="alignJustify"/>
                                    <Item name="separator"/>
                                    <Item name="orderedList"/>
                                    <Item name="bulletList"/>
                                    <Item name="separator"/>
                                    <Item
                                        name="header"
                                        acceptedValues={headerValues}
                                    />
                                    <Item name="separator"/>
                                    <Item name="color"/>
                                    <Item name="background"/>
                                    <Item name="separator"/>
                                    <Item name="link"/>
                                    <Item name="image"/>
                                    <Item name="separator"/>
                                    <Item name="clear"/>
                                    <Item name="codeBlock"/>
                                    <Item name="blockquote"/>
                                    <Item name="separator"/>
                                    <Item name="insertTable"/>
                                    <Item name="deleteTable"/>
                                    <Item name="insertRowAbove"/>
                                    <Item name="insertRowBelow"/>
                                    <Item name="deleteRow"/>
                                    <Item name="insertColumnLeft"/>
                                    <Item name="insertColumnRight"/>
                                    <Item name="deleteColumn"/>
                                    <Item name="separator"/>
                                    <Item
                                        widget={'dxButton'}
                                        options={toolbarButtonOptions}
                                    />
                                </Toolbar>
                            </HtmlEditor>
                            </div>
                        }
                    </div>
                </ScrollView>
            </Popup>
        )
    }
    //New value create
    const renderValueNewPopup = (props) => {
        return (
            <Popup
                visible={valueNewLoader}
                onHiding={() => setValueNewLoader(false)}
                dragEnabled={false}
                hideOnOutsideClick={true}
                showTitle={true}
                title={typeValue === 3 ? "Шинэ түгээмэл асуулт хариулт оруулах" : "Шинэ мэдээлэл"}
                width="95%"
                height="90%"
            >
                <ScrollView height="100%" width="100%">
                    <div className="widget-container">
                        {typeValue === 3 ?
                            <div className="col-12 row" style={{
                                justifyContent: "flex-start",
                                gap: '2rem',
                                marginBottom: 10,
                                width: '100%',
                                flex: 1,
                                display: 'flex'
                            }}>
                                {/*  <span style={{marginTop:5}}>Асуулт</span>*/}
                                <TextBox width={'70%'}
                                         placeholder='Асуултаа оруулаарай'
                                         showClearButton={true}
                                         value={valueName} onValueChange={onChangeValueName}/>
                                {/*  <span>Эрэмбэ</span>*/}
                                <TextBox
                                    width={'10%'}
                                    mode={'number'}
                                    labelMode={'floating'}
                                    stylingMode='outlined'
                                    placeholder='Эрэмбэ оруулах'
                                    showClearButton={true}
                                    value={orderNo} onValueChange={onChangeOrderNo}/>
                                <Button icon={'save'} text="Хадгалах" onClick={onSaveSSA} width={'15%'} type="default"
                                        style={{color: '#fff', textAlign: "center",family: "'Montserrat', sans-serif",marginRight:10,backgroundColor: "#225D72"}}/>
                            </div>

                            :

                            typeValue === 1 ? <div className="col-12 row" style={{
                                    justifyContent: "space-between",
                                    gap: '2rem',
                                    marginBottom: 10
                                }}>
                                    <SelectBox
                                        items={typeValue === 1 ? _.filter(valueNameList, {exists_name: null}) : _.filter(valueNameListGeneral, {exists_name: null})}
                                        value={selectedValueTypeName}
                                        style={{border: '1px solid #DDDDDD'}}
                                        displayExpr="description"
                                        onValueChanged={(e) => onChangeValueTypeName(e.value)}
                                        placeholder="Төрөл сонгох"/>
                                    <Button text="Хадгалах" onClick={onSaveSSA} width={150} type="default" icon={'save'}
                                            style={{color: '#fff', textAlign: "center",family: "'Montserrat', sans-serif",marginRight:10,backgroundColor: "#225D72"}}/>
                                </div> :
                                <div>
                                    {/*  <span style={{marginLeft: 10}}>Эрэмбэ</span>*/}
                                    <div className="col-12 row"
                                         style={{justifyContent: "space-between", gap: '2rem', padding: '5px 15px'}}>
                                        <TextBox
                                            width={'10%'}
                                            mode={'number'}
                                            labelMode={'floating'}
                                            stylingMode='outlined'
                                            placeholder='Эрэмбэ оруулах'
                                            showClearButton={true}
                                            value={orderNo} onValueChange={onChangeOrderNo}/>
                                        <Button text="Хадгалах"
                                                onClick={typeValue === 1 ? onSaveSSA : onCreateAttributeDetails}
                                                width={150} type="default" icon={'save'}
                                                style={{color: '#fff', textAlign: "center",family: "'Montserrat', sans-serif",marginRight:10,backgroundColor: "#225D72"}}/>
                                    </div>
                                </div>
                        }
                        <div height="100%" width="100%" id="htmlEditorEdit" style={{backgroundColor:"#fff"}}>
                        <HtmlEditor onValueChanged={onValueChangeInfo} valueType="html"
                                    placeholder={typeValue === 3 ? "Та түгээмэл хариултаа оруулаарай" : "Та шинэ мэдээлэлээ оруулаарай"}>
                            <MediaResizing enabled={true}/>
                            <TableResizing enabled={true}/>
                            <ImageUpload tabs={tabs[2].value} fileUploadMode="base64"/>
                            <Toolbar multiline={true}>
                                <Item name="separator"/>
                                <Item
                                    name="size"
                                    acceptedValues={sizeValues}
                                    options={sizeOptions}
                                />
                                <Item
                                    name="font"
                                    acceptedValues={fontValues}
                                    options={fontOptions}
                                />
                                <Item name="separator"/>
                                <Item name="separator"/>
                                <Item name="bold"/>
                                <Item name="italic"/>
                                <Item name="strike"/>
                                <Item name="underline"/>
                                <Item name="separator"/>
                                <Item name="alignLeft"/>
                                <Item name="alignCenter"/>
                                <Item name="alignRight"/>
                                <Item name="alignJustify"/>
                                <Item name="separator"/>
                                <Item name="orderedList"/>
                                <Item name="bulletList"/>
                                <Item name="separator"/>
                                <Item
                                    name="header"
                                    acceptedValues={headerValues}
                                />
                                <Item name="separator"/>
                                <Item name="color"/>
                                <Item name="background"/>
                                <Item name="separator"/>
                                <Item name="link"/>
                                <Item name="image"/>
                                <Item name="separator"/>
                                <Item name="clear"/>
                                <Item name="codeBlock"/>
                                <Item name="blockquote"/>
                                <Item name="separator"/>
                                <Item name="insertTable"/>
                                <Item name="deleteTable"/>
                                <Item name="insertRowAbove"/>
                                <Item name="insertRowBelow"/>
                                <Item name="deleteRow"/>
                                <Item name="insertColumnLeft"/>
                                <Item name="insertColumnRight"/>
                                <Item name="deleteColumn"/>
                                <Item name="separator"/>
                                <Item
                                    widget={'dxButton'}
                                    options={toolbarButtonOptions}
                                />
                            </Toolbar>
                        </HtmlEditor>
                        </div>
                    </div>
                </ScrollView>
            </Popup>
        )
    }
    const ImageChangeFile = (e) => {
        ImageFileRef.current = e.value;
    }
    const ImageChange = (e) => {
        ImageRef.current = e.value;
    }
    const ImageHeadChange = (e) => {
        ImageHeadRef.current = e.value;
    }
    const ImageBannerChange = (e) => {
        ImageBannerRef.current = e.value;
    }
    const ImageMinistryOrgChange = (e) => {
        ImageMinistryOrgRef.current = e.value;
    }
    const ImageBannerStructureChange = (e) => {
        ImageBannerStructureRef.current = e.value;
    }
    const ImageFileTushaalChange = (e) => {
        ImageFileTushaalRef.current = e.value;
    }
    const ImageFileStrategyChange = (e) => {
        ImageFileStrategyRef.current = e.value;
    }

    const ImageFile = () => {
        return (
            <div style={{borderBottom: '3px solid #ddd', paddingBottom: 5}}>
                <FileUploader
                    allowedFileExtensions={allowedFileExtensions}
                    onValueChanged={ImageChange}
                    selectButtonText="Зураг сонгох"
                    labelText=""
                    accept="image/*"
                    uploadMode="useForm"
                />
                {/* <span className="note">{'Зургийн өргөтгөл: '}
                    <span>.jpg, .jpeg, .png</span>
                </span>*/}
            </div>

        );
    };
    const ImageHeadFile = () => {
        return (
            <div style={{borderBottom: '3px solid #ddd', paddingBottom: 5}}>
                <FileUploader
                    allowedFileExtensions={allowedFileExtensions}
                    onValueChanged={ImageHeadChange}
                    selectButtonText="Зураг сонгох"
                    labelText=""
                    accept="*"
                    uploadMode="useForm"
                />
                {/*      <span className="note">{'Зургийн өргөтгөл: '}
                    <span>.jpg, .jpeg, .png</span>
                </span>*/}
            </div>
        );
    };
    const ImageBannerFile = () => {
        return (
            <div style={{borderBottom: '3px solid #ddd', paddingBottom: 5}}>
                <FileUploader
                    allowedFileExtensions={allowedFileExtensions}
                    onValueChanged={ImageBannerChange}
                    selectButtonText="Зураг сонгох"
                    labelText=""
                    accept="*"
                    uploadMode="useForm"
                />
                {/* <span className="note">{'Зургийн өргөтгөл: '}
                    <span>.jpg, .jpeg, .png</span>
                </span>*/}
            </div>
        );
    };
    const ImageBannerStructureFile = () => {
        return (
            <div style={{borderBottom: '3px solid #ddd', paddingBottom: 5}}>
                <FileUploader
                    allowedFileExtensions={allowedFileExtensions}
                    onValueChanged={ImageBannerStructureChange}
                    selectButtonText="Зураг сонгох"
                    labelText=""
                    accept="*"
                    uploadMode="useForm"
                />
                {/*<span className="note">{'Зургийн өргөтгөл: '}
                    <span>.jpg, .jpeg, .png</span>
                </span>*/}
            </div>
        );
    };
    const ImageFileTushaal = () => {
        return (
            <div style={{borderBottom: '3px solid #ddd', paddingBottom: 5}}>
                <FileUploader
                    allowedFileExtensions={allowedFileExtensions2}
                    onValueChanged={ImageFileTushaalChange}
                    selectButtonText="Файл сонгох"
                    labelText=""
                    accept="*"
                    uploadMode="useForm"
                />
                {/* <span className="note">{'Файлын өргөтгөл: '}
                    <span>.jpg, .jpeg, .png, .pdf</span>
                </span>*/}
            </div>
        );
    };
    const ImageFileStrategy = () => {
        return (
            <div style={{}}>
                <FileUploader
                    allowedFileExtensions={allowedFileExtensions2}
                    onValueChanged={ImageFileStrategyChange}
                    selectButtonText="Файл сонгох"
                    labelText=""
                    accept="*"
                    uploadMode="useForm"
                />
                {/*    <span className="note">{'Файлын өргөтгөл: '}
                    <span>.jpg, .jpeg, .png, .pdf</span>
                </span>*/}
            </div>
        );
    };
    const ImageMinistryOrgFile = () => {
        return (
            <div style={{borderBottom: '3px solid #ddd', paddingBottom: 5}}>
                <FileUploader
                    allowedFileExtensions={allowedFileExtensions}
                    onValueChanged={ImageMinistryOrgChange}
                    selectButtonText="Зураг сонгох"
                    labelText=""
                    accept="*"
                    uploadMode="useForm"
                />
                {/*  <span className="note">{'Зургийн өргөтгөл: '}
                    <span>.jpg, .jpeg, .png</span>
                </span>*/}
            </div>
        );
    };
    const renderImageMenu = props => {
        return (
            <Popup
                visible={togglerImage}
                onHiding={() => setTogglerImage(false)}
                hideOnOutsideClick={true}
                showCloseButton={true}
                height="95%"
                showTitle={true}
                title={selectedOrgName && selectedOrgName !== null ? selectedOrgName + "- Файл зураг удирдах" : "Файл зураг удирдах"}
                width="35%"
            >
                <ScrollView height="100%" width="100%">
                    <LoadPanelComponent
                        position={{of: '#renderImageMenu'}}
                        visible={loader}
                    />
                    <div className="widget-container" style={{padding: 10}}>
                        <div style={{marginBottom: 10}}>Файл зураг оруулах хэсэг</div>
                        {togglerImage &&
                            <Form style={{border: '3px solid #ddd', borderRadius: 5, padding: 5}} labelLocation={"top"}>
                                <FormItem1 itemType={"group"} colCount={1} colSpan={1}>
                                    <FormItem1 dataField="file_name" render={ImageFile}>
                                        <Label text="Түүхэн замнал зураг"/>
                                    </FormItem1>
                                    <FormItem1 dataField="file_name" render={ImageHeadFile}>
                                        <Label text="Үе үеийн удирдлагууд зураг"/>
                                    </FormItem1>
                                    <FormItem1 dataField="file_name" render={ImageBannerFile}>
                                        <Label text="Баннер зураг"/>
                                    </FormItem1>
                                    <FormItem1 dataField="file_name" render={ImageBannerStructureFile}>
                                        <Label text="Баннер бүтэц зураг"/>
                                    </FormItem1>
                                    <FormItem1 dataField="file_name" render={ImageMinistryOrgFile}>
                                        <Label text="Сайдын эрхлэх хүрээний байгууллагууд зураг"/>
                                    </FormItem1>
                                    <FormItem1 dataField="file_name" render={ImageFileTushaal}>
                                        <Label text="Батлагдсан бүтэц орон тооны тушаал файл"/>
                                    </FormItem1>
                                    <FormItem1 dataField="file_name" render={ImageFileStrategy}>
                                        <Label text="Стратеги төлөвлөгөөтэй холбоотой файл"/>
                                    </FormItem1>
                                </FormItem1>
                                {/*  <Button icon="plus" text={"Хадгалах"} type="default"
                                    onClick={() => submitFile()}/>*/}
                            </Form>}

                        <div style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            marginRight: 10,
                            marginBottom: 10,
                            marginTop: 10
                        }}>
                            <Button icon="plus" text={"Хадгалах"} type="default" style={{color: '#fff', textAlign: "center",family: "'Montserrat', sans-serif",marginRight:10,backgroundColor: "#225D72"}}
                                    onClick={() => submitFile()}/>
                        </div>
                        {fileNameData && <div style={{marginBottom: 10}}>Хадгалсан файлууд</div>}
                        <div style={fileNameData ? {border: '3px solid #ddd', borderRadius: 5, padding: 5} : null}>
                            {fileNameData && fileNameData.history_file_name !== null &&
                                <div style={{marginTop: 10}}>
                                    <div>Түүхэн замнал зураг</div>
                                    <a href={urlServer + '/api/file/documents/' + fileNameData.history_file_name}
                                       target="_blank"
                                       rel="noreferrer">
                                        {fileNameData.history_file_name}
                                    </a> <br></br>
                                </div>}
                            {fileNameData && fileNameData.all_head_file_name !== null &&
                                <div style={{marginTop: 10}}>
                                    <div>Үе үеийн удирдлагууд зураг</div>
                                    <a href={urlServer + '/api/file/documents/' + fileNameData.all_head_file_name}
                                       target="_blank"
                                       rel="noreferrer">
                                        {fileNameData.all_head_file_name}
                                    </a> <br></br>
                                </div>}
                            {fileNameData && fileNameData.banner_file_name !== null &&
                                <div style={{marginTop: 10}}>
                                    <div>Баннер зураг</div>
                                    <a href={urlServer + '/api/file/documents/' + fileNameData.banner_file_name}
                                       target="_blank"
                                       rel="noreferrer">
                                        {fileNameData.banner_file_name}
                                    </a> <br></br>
                                </div>}
                            {fileNameData && fileNameData.banner_structure_file_name !== null &&
                                <div style={{marginTop: 10}}>
                                    <div>Баннер бүтэц зураг</div>
                                    <a href={urlServer + '/api/file/documents/' + fileNameData.banner_structure_file_name}
                                       target="_blank"
                                       rel="noreferrer">
                                        {fileNameData.banner_structure_file_name}
                                    </a> <br></br>
                                </div>}
                            {fileNameData && fileNameData.banner_ministry_file_name !== null &&
                                <div style={{marginTop: 10}}>
                                    <div>Сайдын эрхлэх хүрээний байгууллагууд зураг</div>
                                    <a href={urlServer + '/api/file/documents/' + fileNameData.banner_ministry_file_name}
                                       target="_blank"
                                       rel="noreferrer">
                                        {fileNameData.banner_ministry_file_name}
                                    </a> <br></br>
                                </div>}
                            {fileNameData && fileNameData.tushaal_file_name !== null &&
                                <div style={{marginTop: 10}}>
                                    <div>Батлагдсан бүтэц орон тооны тушаал шийдвэртэй холбоотай файл</div>
                                    <a href={urlServer + '/api/file/documents/' + fileNameData.tushaal_file_name}
                                       target="_blank"
                                       rel='noopener noreferrer'>
                                        {fileNameData.tushaal_file_name}
                                    </a> <br></br>
                                </div>}
                            {fileNameData && fileNameData.strategy_file_name !== null &&
                                <div style={{marginTop: 10}}>
                                    <div>Стратеги төлөвлөгөөтэй холбоотой файл</div>
                                    <a href={urlServer + '/api/file/documents/' + fileNameData.strategy_file_name}
                                       target="_blank"
                                       rel="noopener noreferrer">
                                        {fileNameData.strategy_file_name}
                                    </a> <br></br>
                                </div>}
                        </div>

                    </div>
                </ScrollView>
            </Popup>
        )
    }
    const renderOrgBudgetOrganization = props => {
        return (
            <Popup
                visible={popupBudget}
                onHiding={() => setPopupBudget(false)}
                hideOnOutsideClick={true}
                showCloseButton={true}
                height="95%"
                showTitle={true}
                title={selectedOrgName && selectedOrgName !== null ? selectedOrgName + "- Төсөв удирдах" : "Төсөв удирдах"}
                width="65%"
            >
                <ScrollView height="100%" width="100%">
                    <LoadPanelComponent
                        position={{of: '#renderImageMenu'}}
                        visible={loader}
                    />
                    <div className="widget-container" style={{padding: 10}}>
                        {fileName &&
                            <Popup
                                width={1200}
                                maxWidth={'100%'}
                                minHeight={'100%'}
                                height={'100%'}
                                title={fileName && fileName}
                                visible={filePopUpModal}
                                onHiding={() => {
                                    setFilePopUpModal(false)
                                }}
                                dragEnabled={false}
                                hideOnOutsideClick={true}
                            >
                                {
                                    fileExtention === 'application/pdf' ? <object width="100%" height="100%"
                                                                                  data={urlServer + '/api/file/documents/' + fileName}
                                                                                  type="application/pdf"/>
                                        :
                                        <ScrollView> <img
                                            src={urlServer + '/api/file/documents/' + fileName}
                                            width={'100%'}/> </ScrollView>

                                }

                            </Popup>}
                        <DataGrid dataSource={orgBudgetOrganization}
                                  noDataText="Мэдээлэл ороогүй байна"
                                  keyExpr="id"
                                  onSaved={onSaveOrgBudgetOrganization}
                                  onEditingStart={onEditingStartEventBudget}
                                  style={{ color: '#fff', textAlign: "center",family: "'Montserrat', sans-serif"}}
                                  onCellPrepared={onCellPreparedHandler}
                                  wordWrapEnabled={true}
                                  showBorders={true}
                        > <GroupPanel visible={true}/>
                            <Grouping autoExpandAll={true}/>
                            <Column dataField="budget_year" caption="Жил" dataType="number" groupIndex={0}/>
                            <Column dataField="org_budget_id" caption="Төсвийн төрөл" dataType="string">
                                <Lookup
                                    valueExpr="id"
                                    displayExpr="name"
                                    dataSource={orgBudget}
                                />
                            </Column>
                            <Column dataField="approved_worker_id" caption="Зөвшөөрсөн" dataType="string"
                                    editCellComponent={WorkerDropDownBoxComponent}>
                                <Lookup
                                    valueExpr="wid"
                                    displayExpr="first_name"
                                    dataSource={workerData}
                                />
                            </Column>
                            <Column dataField="position_name" caption="Албан тушаал" dataType="string"/>
                            <Column dataField="approved_worker_date" dataType="date"
                                    caption="Зөвшөөрсөн огноо"/>
                            <Column
                                dataField="file_id"
                                caption="Тушаал шийдвэр"
                                editCellTemplate={"file_name"}
                                cellRender={renderGridFile}
                            />

                            <Template name={"file_name"} render={Template_upload}/>

                            <Column dataField="createdAt" dataType="date" caption="Үүсгэсэн огноо"/>
                            <Column dataField="updatedAt" dataType="date" caption="Зассан огноо"/>
                            <Column dataField="status" caption="Төлөв идэвхтэй эсэх" dataType="boolean">
                            </Column>
                            <Editing
                                allowUpdating={true}
                                allowDeleting={false}
                                allowAdding={true}
                                mode="popup"
                                useIcons={true}
                                texts={{
                                    cancelAllChanges: 'Болих',
                                    cancelRowChanges: 'Болих',
                                    confirmDeleteMessage: 'Энэ мэдээллийг устгах уу?',
                                    confirmDeleteTitle: 'Баталгаажуулах',
                                    deleteRow: 'Устгах',
                                    editRow: 'Өөрчлөх',
                                    saveAllChanges: 'Хадгалах',
                                    saveRowChanges: 'Хадгалах',
                                    undeleteRow: 'Буцаах',
                                    validationCancelChanges: 'Баталгаажуулах болих',
                                }}
                            >
                                <DataGridPopup title="Төсөв удирдах" showTitle={true} width={1000}
                                               height={"60%"}/>
                                <DataGridForm>
                                    <DataGridItem itemType="group" colCount={1} colSpan={2}>
                                        <DataGridItem dataField="org_budget_id"/>
                                        <DataGridItem dataField="approved_worker_id"/>
                                        <DataGridItem dataField="file_id"/>
                                        <DataGridItem dataField="status"/>
                                    </DataGridItem>
                                </DataGridForm>
                            </Editing>

                        </DataGrid>
                    </div>
                </ScrollView>
            </Popup>
        )
    }
    const renderFAQ = props => {
        return (
            <Popup
                visible={popupFAQ}
                onHiding={() => setPopupFAQ(false)}
                hideOnOutsideClick={true}
                showCloseButton={true}
                height="95vh"
                showTitle={true}
                title={selectedOrgName + "-Түгээмэл асуулт,хариулт удирдах"}
            >
                <div className="col-12 row" style={{justifyContent: "flex-end", gap: '2rem', padding: '5px'}}>
                    <Button text="Асуулт оруулах" onClick={onChangeValueNew} width={180} type="default" icon={"add"}
                            style={{color: '#fff', textAlign: "center",family: "'Montserrat', sans-serif",marginRight:10,backgroundColor: "#225D72"}}/>
                </div>
                <ScrollView height="97%" width="100%">
                    <LoadPanelComponent
                        position={{of: '#renderFixMenu1'}}
                        visible={loader}
                    />
                    <div>
                        <DataGrid dataSource={attribute}
                                  noDataText="Мэдээлэл ороогүй байна"
                                  keyExpr="id"
                                  onSaved={onSavingEvent}
                                  onEditingStart={onEditingStartEvent}
                                  onEditorPreparing={onEditorPreparing}
                                  wordWrapEnabled={true}
                                  showBorders={true}
                                  showColumnLines={true}
                                  showRowLines={true}
                                  focusedRowEnabled={true}
                                  ref={myGridRef}
                                  style={{ color: '#fff', textAlign: "center",family: "'Montserrat', sans-serif"}}
                                  onCellPrepared={onCellPreparedHandler}
                        >
                            <Selection mode="single"/>
                            <Column dataField="order_no" caption="Эрэмбэ" dataType="number" width={70}/>
                            <Column dataField="description" caption="Түгээмэл асуулт" dataType="string"
                                    width={500}/>
                            <Column dataField="created_user_name" caption="Үүсгэсэн хэрэглэгч" dataType="string"/>
                            <Column dataField="updated_user_name" caption="Зассан хэрэглэгч" dataType="string"/>
                            <Column dataField="createdAt" dataType="date" format="yyyy-MM-dd hh:mm:ss"
                                    caption="Үүсгэсэн огноо"/>
                            <Column dataField="updatedAt" dataType="date" format="yyyy-MM-dd hh:mm:ss"
                                    caption="Зассан огноо"/>
                            <Column dataField="value" caption="Үйлдэл" cellRender={valueCell} width={130}/>
                            <Editing
                                allowUpdating={false}
                                allowDeleting={false}
                                allowAdding={false}
                                mode="form"
                                useIcons={true}
                                texts={{
                                    cancelAllChanges: 'Болих',
                                    cancelRowChanges: 'Болих',
                                    confirmDeleteMessage: 'Энэ түгээмэл асуултыг устгах уу?',
                                    confirmDeleteTitle: 'Баталгаажуулах',
                                    deleteRow: 'Устгах',
                                    editRow: 'Өөрчлөх',
                                    saveAllChanges: 'Хадгалах',
                                    saveRowChanges: 'Хадгалах',
                                    undeleteRow: 'Буцаах',
                                    validationCancelChanges: 'Баталгаажуулах болих',
                                }}
                            >
                            </Editing>
                        </DataGrid>
                    </div>
                </ScrollView>
            </Popup>
        )
    }

    const requestFullscreen=()=> {
        /* Get the element you want displayed in fullscreen */
        const element = document.getElementById("htmlEditorEdit");
        // Use a guard clause to exit out of the function immediately
        if (element.requestFullscreen) {
            element.requestFullscreen();
        } else if (element.webkitRequestFullscreen) {
            // Safari
            element.webkitRequestFullscreen();
        } else if (element.msRequestFullscreen) {
            // IE
            element.msRequestFullscreen();
        }
    }
    const toolbarButtonOptions = {
        icon: 'fullscreen',
        stylingMode: 'text',
        onClick: requestFullscreen,
    };
    const setVisibleButton = (e) => {
        return e.row.data.org_id !== null ? true : false;
    };
    return (
        <div className="card" style={{padding: "1rem", fontWeight: "bold", height: "100vh"}}>
            <LoadPanelComponent
                position={{of: '#generalMenu'}}
                visible={loader}
            />
            {/*   <h3 style={{marginBottom: "1rem"}}>Ерөнхий Мэдээлэл Удирдах </h3>*/}
            <ScrollView width='100%' height='100%'>
                <TreeList dataSource={parentOrgs}
                          keyExpr="id"
                          parentIdExpr="parent_id"
                          style={{ color: '#fff', textAlign: "center",family: "'Montserrat', sans-serif"}}
                          allowColumnReordering={true}
                          rootValue={null}
                          showBorders={true}
                          showColumnLines={true}
                          showRowLines={true}
                          wordWrapEnabled={true}
                          //rowAlternationEnabled={true}
                          noDataText='Дата байхгүй байна.'
                          onCellPrepared={onCellPreparedHandler}>
                    <Selection mode="single"/>
                    <FilterRow visible={true}/>
                    <Scrolling mode="standard"/>
                    {/* <TreeColumn caption="№" allowSorting={false} cellTemplate={cellTemp} alignment="center" width={30}/>*/}
                    <TreeColumn caption="Байгууллагууд" dataField="name"></TreeColumn>
                    <TreeColumn caption="Үйлдэл" type="buttons">
                        <TreeButton icon="info" name="general" text="Ерөнхий мэдээлэл удирдах"
                                    onClick={onFixEventGeneral} visible={setVisibleButton}/>
                        <TreeButton icon="home" text="Үндсэн мэдээлэл удирдах" onClick={onFixEvent} visible={setVisibleButton}/>
                        <TreeButton icon="image" text="Файл зураг удирдах" onClick={onImageEvent} visible={setVisibleButton}/>
                        <TreeButton icon="money" text="Төсөв" onClick={onOrgBudget} visible={setVisibleButton}/>
                        <TreeButton icon="help" text="Түгээмэл асуулт,хариулт" onClick={onFAQ} visible={setVisibleButton}/>
                    </TreeColumn>
                </TreeList>
            </ScrollView>
            {toggler && renderFixMenu()}
            {valueChangeLoader && renderValuePopup()}
            {valueNewLoader && renderValueNewPopup()}
            {togglerImage && renderImageMenu()}
            {popupBudget && renderOrgBudgetOrganization()}
            {popupFAQ && renderFAQ()}
        </div>

    );

};
export default CrudGeneralInfo2;
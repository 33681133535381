import React, {useEffect, useState} from "react"
import TabPanel, {Item} from "devextreme-react/tab-panel"
import Deposit from "./Deposit"
import Withdraw from "./Withdraw"
import TransactionEdit from "./BbEdit"
import BbReport from "./report/BbReport"
import "../assets/css/bankbook.css"
import {BiLineChart, BiLineChartDown} from "react-icons/bi"
import {BsGear} from "react-icons/bs"
import {CgFileDocument} from "react-icons/cg"

import fcsServices from "../../../services/api/fcs"
import CustomTabComponent from "../../../components/CustomTabComponent"
const BankBookHome = () => {
  const [yearValue, setYearValue] = useState(null);
  const [monthValue, setMonthValue] = useState(null);

  useEffect(() => {
    goMonth()
  }, [])

  const goMonth = async () => {
    const value = await fcsServices.getAllFcsMonth("BB")
    setMonthValue(value[0].month)
    setYearValue(value[0].year)
  }

  return (
    <div className={"bankbook"}>
        <CustomTabComponent
            list={["Гүйлгээ", "Тайлан"]}
            children={[
                <TransactionEdit />,
                <BbReport goMonth={goMonth} />]}
                titleWidth="15rem"
        />
    </div>
  )
}

export default BankBookHome

// st1 st2 from gltran

import React, { useState, useRef, useEffect } from "react";
import Button from "devextreme-react/button";
import { Popup, Position, ToolbarItem } from 'devextreme-react/popup';
import {GetTran} from "../../tran/Db";
import DataGrid, {
  Column,
  TotalItem,
  Summary,
  Selection,
  Export, GroupPanel, SearchPanel, Grouping, GroupItem, Paging,
} from 'devextreme-react/data-grid';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver-es';
import { exportDataGrid } from 'devextreme/excel_exporter';
import {LoadPanel} from "devextreme-react/load-panel";
import ScrollView from "devextreme-react/scroll-view";
import LoadPanelComponent from "../../../../components/LoadPanelComponent";
import St1Tree from "./St1Tree";

const position14 = {of: '#firstBox'};

const GeneralLeader = (props) => {
  const glYearMN = props.glYearMN;
  const year = props.year;
  const year2 = props.year2;

  const month1 = props.month1;
  const month2 = props.month2;
  const descr1 = props.descr1;
  const descr2 = props.descr2;

  const [beginBal, setBeginBal] = useState([]);
  const [dtCr, setDtCr] = useState([]);
  const [details, setDetails] = useState([]);
  const [structure, setStructure] = useState([]);
  const [tranDescrDetails, setTranDescrDetails] = useState([]);
  const initSum = { bd: 0, bc: 0, dt: 0, cr: 0, ed: 0, ec: 0 };
  const [sum, setSum] = useState({ ...initSum });
  const componentRef = useRef();
  const [report, setReport] = useState([]);
  const initAcctCode = { code: "", name: "", amount: 0 };
  const [selectAcct, setSelectAcct] = useState({ ...initAcctCode });
  const [acctList, setAcctList] = useState([]);
  const myRef = useRef(null);
  const [loader, setLoader] = useState(false);
  const [accountValue, setAccountValue] = useState('');
  const [acctDtlList, setAcctDtlList] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedValue, setSelectedValue] = useState(null);
  const [stSelected, setStSelected] = useState(0);
  const [balance,setBalance]=useState(0)
  const [endBalance,setEndBalance]=useState(0)


  useEffect(async () => {
    getAcct();
  }, []);

  useEffect(async () => {
    setStSelected(0)
        setReport([])
    setAccountValue("")
    setSelectedValue([]);

  }, [year,year2 ,month1,month2]);





  const getAcct = async () => {
    setLoader(true);

    const result = await GetTran(6, 0, "gl?");
    if (result.success) {
      setAcctList(result.data.data);
    }
    setLoader(false);
  };
  const getSt1=()=>{
    setReport([])
    setStSelected(1)
  }
  const getSt2=()=>{
    setReport([])
    setStSelected(2)
  }
  const getSt3=()=>{
    setReport([])
    setStSelected(3)
  }

  const getSt4=()=>{
    setReport([])
    setStSelected(4)
  }

  const getSt1n=()=>{
    setReport([])
    setStSelected(5)
  }

  const getSt2n=()=>{
    setReport([])
    setStSelected(6)
  }


  const calReport = async (beginBalList) => {
    setLoader(true);
    let array = [];

    let index = -1;
    await beginBalList.map((row) => {
      let glRow = { acct: "", descr: "", bd: 0, bc: 0, dt: 0, cr: 0 };
      glRow.bd = row.bd;
      glRow.bc = row.bc;
      glRow.dt = row.dt;
      glRow.cr = row.cr;
      glRow.acct = row.acct;
      glRow.descr = row.descr;

      if(glRow.bd - glRow.bc > 0) {
        glRow.bd = glRow.bd - glRow.bc;
        glRow.bc = 0;
      } else {
        glRow.bc = glRow.bc - glRow.bd;
        glRow.bd = 0
      }


      index = array.findIndex((x) => x.acct === glRow.acct);

      index < 0
        ? array.push(glRow)
        : ((array[index].bd = array[index].bd + glRow.bd)
          (array[index].bc = array[index].bc + glRow.bc));
    });

    await array.map((el, index3) => {
      const val0=el.bd - el.bc
      el.bd=0
      el.bc=0

      val0>0 ? el.bd=val0 :el.bc=-val0


      const val = el.bd - el.bc + el.dt - el.cr;
      el.ed = 0;
      el.ec = 0;
      val > 0 ? (el.ed = val) : (el.ec = -val);
      array[index3] = el;
    });


    await array.map((el5, index) => {
      const found = acctList.find(element => element.c10 === el5.acct);
      if (found) el5['name'] = found.c11;
    });

    let sumCal = { bd: 0, bc: 0, dt: 0, cr: 0, ed: 0, ec: 0 };
    await array.map((el) => {
      sumCal.bd = sumCal.bd + el.bd;
      sumCal.bc = sumCal.bc + el.bc;
      sumCal.dt = sumCal.dt + el.dt;
      sumCal.cr = sumCal.cr + el.cr;
      sumCal.ed = sumCal.ed + el.ed;
      sumCal.ec = sumCal.ec + el.ec;
    });
    setSum(sumCal);

    const array2 = array.filter(
      (item, index) =>
        item.bd != 0 || item.bc != 0 || item.dt != 0 || item.cr != 0
    );

    setReport(array2);
    setLoader(false);
  };

  // ******** Татах

  const getGlUS = async () => {
    setDetails([]);
    setStSelected(0)
    let p1 = "1&year=" +
      year +
      "&month1=" +
      month1 +
      "&month2=" +
      month2;
    const result = await GetTran(p1, 0, "report/generalleader?");
    setBeginBal(result.data.data);
    calReport(result.data.data);
  };

  const getDetails = async (data) => {


    if ( data=='' ||   data.acct == '') return;

    setBalance(data.amt)
    setEndBalance(data.amt2)
    setAccountValue(data.acct)
    let p1 = "0&year=" + year + "&month1=" + month1 + "&month2=" + month2 + "&acct=" + data.acct;
   const result = await GetTran(p1,0, "report/gldetial?");
   // const result = await GetTran(p1,0, "report/glacctview?");

    if (result.success){
      setAcctDtlList(result.data.data);
      setModalVisible(true);
    }
  };

  const getList = async (data) => {


    setBalance(0)
    setEndBalance(0)

    let p1 = "0&year=" + year + "&month1=" + month1 + "&month2=" + month2 + "&acct=" + accountValue;
   // const result = await GetTran(p1,0, "report/gldetial?");
     const result = await GetTran(p1,0, "report/glacctviewN?");

    if (result.success){
      setAcctDtlList(result.data.data);
      setModalVisible(true);
    }
  };




  const executeScroll = () => myRef.current.scrollIntoView();
  // run this function from an event handler or an effect to execute scroll



  const customizeDate = (data) => {
    return Number(parseFloat(data.value).toFixed(2)).toLocaleString("en", {
      minimumFractionDigits: 2,
    });
  }

  const selectionDblClick= async (e)=>{

    let d = e.data
d.amt=e.data.bd-e.data.bc
d.amt2=e.data.ed-e.data.ec

getDetails(d)
  }


  const onSelectionChanged = ({ selectedRowsData }) => {
    setAccountValue(selectedRowsData[0].acct);
    // setSelectedValue(selectedRowsData[0]);
    // setBalance(selectedRowsData[0].bd-selectedRowsData[0].bc)
    // setEndBalance(selectedRowsData[0].ed-selectedRowsData[0].ec)

    console.log("selectedRowsData===",selectedRowsData)

  }
  const   diff = (rowData) =>  {
    const date= new Date(rowData.docdate)
    return   date.getMonth()+1;
  }
  const onExporting = (e) => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Main sheet');

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'DataGrid.xlsx');
      });
    });
    e.cancel = true;
  }

  return (
    <React.Fragment>
      {/*<Router>*/}
      {/*  <Switch>*/}
      {/*    <Route exact path="/geeks/second" component={AcctReport}></Route>*/}
      {/*  </Switch>*/}
      {/*</Router>*/}
      <div ref={myRef}></div>
      <Popup
        width={850}
        height={450}
        minWidth={'50%'}
        maxWidth={'100%'}
        minHeight={'100%'}
        visible={modalVisible}
        title={'Дэлгэрэнгүй'}
        onHiding={() => setModalVisible(false)}
        resizeEnabled={true}
        dragEnabled={true}
        showTitle={true}
        hideOnOutsideClick={true}
      >
        <ScrollView width='100%' height='100%'>
          <div className={'card-body'}>
            <div className="col-12" style={{ marginTop: 20 }}>
              {selectedValue &&
                <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 30, marginLeft: '10%' }}>
                  <span style={{ fontSize: 16, fontWeight: 500 }}>Эхний үлдэгдэл: </span>
                  <span style={{ fontSize: 16, fontWeight: 500, marginLeft: 10 }}> { Number(parseFloat(balance).toFixed(2)).toLocaleString("en", {
                    minimumFractionDigits: 2,
                  }) }</span>
                </div>
              }
              <DataGrid
                dataSource={acctDtlList}
                showBorders={true}
                allowColumnResizing={true}
                onExporting={onExporting}

              hoverStateEnabled={true}
                columnAutoWidth={true}
                keyExpr="acct"
                noDataText = 'Дата байхгүй байна.'

              >

                <GroupPanel visible={true}/>
                <SearchPanel visible={true}/>
                <Grouping autoExpandAll={true}/>
                <Column caption="Гүйлгээний утга" dataField="descr" />
                <Column caption="дугаар" dataField="docno" />
                <Column caption="огноо" dataField="docdate" dataType="date"/>

                <Column
                    // groupIndex={0}
                         name={"sar"} calculateDisplayValue={diff}
                        caption="сар" width={55}
                        calculateCellValue={diff}
                />
                <Column caption="журнал" dataField="jr_descr" />
                <Column caption="Данс" dataField="acct" />
                <Column caption="дебит" dataField="dt"  format="#,##0.00"/>
                <Column caption="кредит" dataField="cr" format="#,##0.00" />
                <Column caption=" код" dataField="orgcode" />
                <Column caption=" нэр" dataField="orgname" />
                <Column caption=" Харицсан данс" dataField="acct2" />
                <Summary>
                  <GroupItem
                      column="dt"
                      summaryType="sum"
                      format="#,##0.00"
                      customizeText={customizeDate}
                      showInGroupFooter={true} />



                  <GroupItem
                      column="cr"
                      summaryType="sum"
                      format="#,##0.00"
                      customizeText={customizeDate}
                      showInGroupFooter={true} />


                  <TotalItem
                    column="dt"
                    summaryType="sum"
                    customizeText={customizeDate} />
                  <TotalItem
                    column="cr"
                    summaryType="sum"
                    customizeText={customizeDate} />
                </Summary>
                <Export enabled={true}  />
              </DataGrid>
              {selectedValue &&
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginTop: 30, marginRight: '10%' }}>
                  <span style={{ fontSize: 16, fontWeight: 500 }}>Эцсийн үлдэгдэл: </span>
                  <span style={{ fontSize: 16, fontWeight: 500, marginLeft: 10 }}>
                    { Number(parseFloat(endBalance).toFixed(2)).toLocaleString("en", {
                      minimumFractionDigits: 2,
                    }) }</span>
                </div>
              }
            </div>
          </div>
        </ScrollView>
      </Popup>
      <LoadPanelComponent
        position = {position14}
        visible = {loader}
        shading = {true}
        showPane={false}
        showIndicator={true}
        message = ''
        shadingColor="rgba(255,255,255, 0.8)"
      />
      <div className={'card-body'}>
        <div className="row">

          <Button

              id="btnSave"
              onClick={() => getGlUS()}
              width={"200"}
              type = "default" style = {{alignment : "center", borderRadius: '7px'}}
          >
            Шалгах баланс / өссөн /
          </Button>
          <div style={{ marginTop: -5 }}>

          </div>
          <div style={{ marginLeft: 10 }}>
            <input value={accountValue} type = 'string' className = 'law-search-input' placeholder='Дансны код'
            //       disabled={true}
            />
          </div>

            <Button

              onClick={() => getDetails('')}
              width={"140"}
              type = "default" style = {{alignment : "center", borderRadius: '7px'}}
            >
              Ерөнхий дэвтэр
            </Button>


          <Button
              id="btnSave"
              onClick={() => getSt1()}
              width={"80"}
              type = "default" style = {{alignment : "center", borderRadius: '7px'}}
          >
            СТ-1
          </Button>
          <Button
              id="btnSave"
              onClick={() => getSt2()}
              width={"80"}
              type = "default" style = {{alignment : "center", borderRadius: '7px'}}
          >
            СТ-2
          </Button>
          <Button
              id="btnSave"
              onClick={() => getSt3()}
              width={"80"}
              type = "default" style = {{alignment : "center", borderRadius: '7px'}}
          >
            СТ-3
          </Button>

          <Button
              id="btnSave"
              onClick={() => getSt4()}
              width={"80"}
              type = "default" style = {{alignment : "center", borderRadius: '7px'}}
          >
            СТ-4
          </Button>

        </div>

        <div className="row">
          <Button
              id="btnSave"
              onClick={() => getSt1n()}
              width={"80"}
              type = "default" style = {{alignment : "center", borderRadius: '7px'}}
          >
            СТ-1н
          </Button>

          <Button
              id="btnSave"
              onClick={() => getSt2n()}
              width={"80"}
              type = "default" style = {{alignment : "center", borderRadius: '7px'}}
          >
            СТ-2н
          </Button>

          <Button
              id="btnSave"
              onClick={() => getList()}
              width={"80"}
              type = "default" style = {{alignment : "center", borderRadius: '7px'}}
          >
            Задгай
          </Button>




        </div>




        {stSelected==0 &&
        <div className="col-12" style={{ marginTop: 20 }} ref={componentRef}>
          <DataGrid
            dataSource={report}
            showBorders={true}
            allowColumnResizing={true}
            // onSelectionChanged={onSelectionChanged}
            onCellDblClick={selectionDblClick}
            hoverStateEnabled={true}
            keyExpr="acct"
            columnAutoWidth={true}
            noDataText = 'Дата байхгүй байна.'
            onExporting={onExporting}>
            <Selection mode="single" />
            <Column caption="Дансны код" dataField="acct" dataType="string" />

            <Column caption="Нэр" dataField="descr"  />

            <Column caption="Эхний үлдэгдэл">
              <Column caption="Дебит" dataField="bd" format="#,##0.00" />
              <Column caption="Кредит" dataField="bc" format="#,##0.00"/>
            </Column>
            <Column caption="Гүйлгээ">
              <Column caption="Дебит" dataField="dt" dataType="number" format="#,##0.00" />
              <Column caption="Кредит" dataField="cr" dataType="number" format="#,##0.00" />
            </Column>
            <Column caption="Эцсийн үлдэгдэл">
              <Column caption="Дебит" dataField="ed" dataType="number" format="#,##0.00" />
              <Column caption="Кредит" dataField="ec" dataType="number" format="#,##0.00" />
            </Column>
            <Summary>
              <TotalItem
                column="bd"
                summaryType="sum"
                customizeText={customizeDate}/>
              <TotalItem
                column="bc"
                summaryType="sum"
                customizeText={customizeDate} />
              <TotalItem
                column="dt"
                summaryType="sum"
                customizeText={customizeDate} />
              <TotalItem
                column="cr"
                summaryType="sum"
                customizeText={customizeDate} />
              <TotalItem
                column="ed"
                summaryType="sum"
                customizeText={customizeDate} />
              <TotalItem
                column="ec"
                summaryType="sum"
                customizeText={customizeDate} />
            </Summary>
            <Paging
                defaultPageSize={100}
            />
            <Export enabled={true} allowExportSelectedData={true} />
          </DataGrid>
        </div>
        }

        {stSelected>0 &&
            <div style={{marginLeft: 20}}>
              <St1Tree glYearMN={glYearMN} year={year}   year2={year2} getDetails= {getDetails}
                       setAccountValue={setAccountValue}   month1={month1} month2={month2}
                       stId={stSelected} descr1={descr1}   descr2={descr2}
                       setBalance={setBalance} setEndBalance={setEndBalance}
              />
            </div> }




      </div>
    </React.Fragment>
  );
};

export default GeneralLeader;

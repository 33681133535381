import { ScrollView } from 'devextreme-react'
import React, { useEffect, useState } from 'react'
import newsServices from '../../../services/api/news'
import LatestNewsCard from './LatestNewsCard'

function RightContainer({news_type}) {
  const [news, setNews] = useState([])

  useEffect(() => {
    if (news_type){
      fetchData()
    }
  }, [news_type])

  const fetchData = async () => {
    try {
      const {data} =  await newsServices.getAllNews(news_type, 1, 8)
      setNews(data)
    } catch (error) {
      // console.log("error", error)
    }
  }
  const handleScrollFetching = async (e) =>{
    try {
      let nextPage = news?.length / 8 + 1;
      const {data} =  await newsServices.getAllNews(news_type, nextPage, 8)
      setNews(prev =>{
        return [...prev, ...data]
      })
    } catch (error) {
      
    }
  }
 
  const typeText = () => {
    switch (news_type) {
      case 1:
        return "Үйл явдал"
      case 2:
        return "Зар"
      case 3:
        return "Төсвийн тойм"
      case 4:
        return "Инфографик"
      case 5:
        return "Сүүлд нэмэгдсэн"
      case 7:
        return "Фото мэдээ"
      case 8: 
      return "Видео мэдээ"
    }
  }
  return (
    <div className="right-container cards" style={{ margin: "1rem 0"}}>
          <div className="title">{typeText(news_type)}</div>
          <ScrollView onInitialized={true} bounceEnabled height={"inherit"} width={"100%"} 
          //  onReachBottom={handleScrollFetching}
           >
            <div className="cards">
              {news.map((item, index) => (
                <LatestNewsCard limit={60} key={index} {...item} />
              ))}
            </div>
          </ScrollView>
        </div>
  )
}

export default RightContainer

import React, {useContext, useEffect, useState, useRef} from 'react';
import 'devextreme-react/text-area';
import 'devextreme/dist/css/dx.light.css';

import DataGrid, {
    Editing,
    Pager,
    Paging,
    Column,
    FilterRow,
    RequiredRule,
    Export, Form, Item,Popup
} from 'devextreme-react/data-grid';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver-es';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { LoadPanel } from "devextreme-react/load-panel";
import {UserContext} from "../../../../hooks/UserContext";
import fcsService from "../../../../services/api/fcs";
import Button from "devextreme-react/button";
import notify from "devextreme/ui/notify";
import LoadPanelComponent from '../../../../components/LoadPanelComponent';

function Month(props) {
    const userListPosition = { of: '#usersList' }
    const {user} = useContext(UserContext);
    const [dataSource, setDataSource] = useState([]);
    const [loader, setLoader] = useState(false);
    const myBabyRef = useRef(null);
    useEffect(() => {
        fcsList();
    }, [])

    const fcsList = async () => {
        try {
            setLoader(true);
            const result = await fcsService.getFcsMonth();
            setDataSource(result)
            return setLoader(false)
        } catch (e) {
            console.error(e);
        }
    }
    const onEditorPreparing = (e) => {
        if (e.parentType === "dataRow" && e.dataField !== "password") {
            return;
        }
        if (e.row && e.row.isNewRow) {
            e.editorOptions.visible = true;
        }
        if (e.row && !e.row.isNewRow) {
            e.editorOptions.visible = false;
        }
    }
    const customAddRow = (grid) => {
        grid.current.instance.addRow();
    }
    const onInitNewRow = (e) => {
    }
    const setEffect = async (e) => {
        if (e.changes && e.changes.length !== 0 ) {


                if (e.changes[0].type === "update") {
                    try {
                        e.changes[0].data.updated_user_id = user.id;
                        await fcsService.editFcsMonth(e.changes[0].data);
                        await fcsList();
                        notify('Амжилттай хадгалагдлаа', "success", 2000);
                    } catch (e) {
                        notify('Засварлалтыг хадгалах явцад алдаа гарлаа', "error", 2000);
                    }
                } else if (e.changes[0].type === "insert") {
                    try {
                        delete e.changes[0].data.id;
                        e.changes[0].data.created_user_id = user.id;
                        e.changes[0].data.org_id = user.org_id;
                        await fcsService.addFcsMonth(e.changes[0].data);
                        await fcsList();
                        notify('Амжилттай үүслээ', "success", 2000);
                    } catch (e) {
                        notify('Шинээр үүсэх явцад алдаа гарлаа', "error", 2000);
                    }

                }
                else if (e.changes[0].type === "remove") {
                    try {
                        await fcsService.removeFcsMonth(e.changes[0].key.id);
                        await fcsList();
                        notify('Амжилттай устгагдлаа', "success", 2000);
                    } catch (e) {
                        notify('Устгах явцад алдаа гарлаа', "error", 2000);
                    }

                }

        }

    }
    const onExporting = (e) => {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Main sheet');

        exportDataGrid({
            component: e.component,
            worksheet,
            autoFilterEnabled: true,
        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Purchase.xlsx');
            });
        });
        e.cancel = true;
    }
    return (
        <div>
            <LoadPanelComponent
                shadingColor="rgba(0,0,0,0.4)"
                position={{of : '#password'}}
                showPane={false}
                message = ''
            />
            <div className="col-12 row" style={{justifyContent:"space-between", gap:'2rem', padding: '5px 15px'}}>
                <span>
                    Журналын тохиргоо бүртгэл
                </span>
                <Button  icon="add" text = "Нэмэх" onClick={() => customAddRow(myBabyRef)} width = {110} type = "default" style = {{alignment : "center", borderRadius: '7px'}}/>
            </div>
            <LoadPanelComponent
                shadingColor="rgba(0,0,0,0.4)"
                position={userListPosition}
                showPane={false}
                visible={loader}
                message = ''
            />
            <div>
                <div className="card-body">
                    <DataGrid
                    noDataText='Дата байхгүй байна.'
                        onEditorPreparing={onEditorPreparing}
                        ref={myBabyRef}
                        dataSource={dataSource}
                        useIcons={true}
                        onSaved={setEffect}
                        keyExpr= {null}
                        onInitNewRow={onInitNewRow}
                        allowColumnResizing={true}
                        onExporting={onExporting}>
                        onEditingStart={ e => {
                            console.log('qqqqqqqqqqqqqqqqq', e);
                        }}
                        <FilterRow
                            visible = {true}
                        />
                        <Pager
                            showPageSizeSelector={true}
                            // showInfo={true}
                            showNavigationButtons={true}
                        />
                        <Column
                            dataField="id"
                            caption="Дугаар"
                            allowEditing = {false}
                            width={50}
                            // editCellTemplate = {}
                            // cellRender = {customize}
                            cssClass = "custom"
                            allowFiltering={true}
                        >
                        </Column>
                        <Column
                            dataField="organization_model.name"
                            caption="Байгууллага нэр"
                            allowEditing = {false}
                            width={200}
                            cssClass = "custom"
                            allowFiltering={true}
                        >
                        </Column>
                        <Column
                            cssClass = "custom"
                            dataField="jr"
                            caption="Журналын код"
                            width={100}
                            allowEditing = {true}
                            allowFiltering={true}
                        >
                            <RequiredRule
                                message = 'Журналын код оруулна уу'
                            />
                        </Column>
                        <Column
                            cssClass = "custom"
                            dataField="descr"
                            caption="Журналын нэр"
                            width={200}
                            allowEditing = {true}
                            allowFiltering={true}
                        >
                            <RequiredRule
                                message = 'Журналын нэр оруулна уу'
                            />
                        </Column>
                        <Column
                            cssClass = "custom"
                            dataField="year"
                            caption="Жил"
                            allowEditing = {true}
                            allowFiltering={true}
                        >
                            <RequiredRule
                                message = 'Жил оруулна уу'
                            />
                        </Column>
                        <Column
                            cssClass = "custom"
                            dataField="month"
                            caption="Сар"
                            allowEditing = {true}
                            allowFiltering={true}
                        >
                            <RequiredRule
                                message = 'Сар оруулна уу'
                            />
                        </Column>
                        <Column
                            cssClass = "custom"
                            dataField="orid"
                            caption="Орлого №"
                            allowEditing = {true}
                            allowFiltering={true}
                        >
                            <RequiredRule
                                message = '№ оруулна уу'
                            />
                        </Column>
                        <Column
                            cssClass = "custom"
                            dataField="zarid"
                            caption="Зарлага №"
                            allowEditing = {true}
                            allowFiltering={true}
                        >
                            <RequiredRule
                                message = '№ оруулна уу'
                            />
                        </Column>
                        <Column
                            cssClass = "custom"
                            dataField="trid"
                            caption="Шилжүүлэг №"
                            allowEditing = {true}
                            allowFiltering={true}

                        >
                            <RequiredRule
                                message = '№ оруулна уу'
                            />
                        </Column>
                        <Editing

                            mode="row"
                            useIcons={true}
                            allowUpdating={true}
                            allowDeleting={true}
                            // allowAdding = {adding}
                            useIcons = {true}
                            texts={{
                                cancelAllChanges: 'Болих бүгд',
                                cancelRowChanges: 'Болих',
                                confirmDeleteMessage: 'Энэ журнал мэдээллийг устгах уу?',
                                confirmDeleteTitle: 'Баталгаажуулах',
                                deleteRow: 'Устгах',
                                editRow: 'Өөрчлөх',
                                saveAllChanges: 'Хадгалах бүгд',
                                saveRowChanges: 'Хадгалах',
                                undeleteRow: 'Буцаах',
                                validationCancelChanges: 'Баталгаажуулах болих',
                            }}
                        >
                        </Editing>

                        <Column type="buttons" width={110}>
                            <Button name="edit" />
                            <Button name="delete" />
                            <Button  cssClass="my-class" hint="Clone" text="ppprr"  icon="copy" visible={true}  />
                            </Column>

                        <Column type="adaptive" width={50} visible={true} />
                        <Paging enabled={true} />
                        <Export enabled={true} allowExportSelectedData={false} />
                    </DataGrid>
                </div>
            </div>
        </div>
    )
}

export default Month;

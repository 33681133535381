import request from '../../shared/lib/request'

const getAllEvent = () => {
    return request({
        url: '/event/getAllEvent',
        method: 'GET',
    })
}

const addEvent = (body) => {
    return request({
        url: '/event/addEvent',
        method: 'POST',
        data: body,
    })
}
const editEvent = (body) => {
    return request({
        url: `/event/EditEvent`,
        method: 'POST',
        data: body,
    })
}
const removeEvent = (body) => {
    return request({
        url: '/event/RemoveEvent',
        method: 'POST',
        data: body,
    })
}
const updateEventRelation = (body) => {
    return request({
        url: '/event/updateEventRelation',
        method: 'POST',
        data: body,
    })
}
const eventServices = {
    getAllEvent,
    addEvent,
    editEvent,
    removeEvent,
    updateEventRelation
}

export default eventServices;
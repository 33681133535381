import React, {useEffect, useState, useRef, useContext} from "react";
import SelectBox from "devextreme-react/select-box";
import DateBox from 'devextreme-react/date-box';
import DataGrid, {
    Column,
    Lookup,
    Editing, Summary, TotalItem, RequiredRule, Button as DevButton
} from 'devextreme-react/data-grid';
import Button from "devextreme-react/button";
import {LoadPanel} from "devextreme-react/load-panel";
import notify from "devextreme/ui/notify";
import fcsService from "../../../../services/api/fcs";
import {UserContext} from "../../../../hooks/UserContext";
import _ from "lodash";
import LoadPanelComponent from "../../../../components/LoadPanelComponent";

const position14 = {of: '#firstBox'};

const GlEntery = (props) => {
    const {user} = useContext(UserContext);
    const [month1Value, setMonth1Value] = useState('GL');
    const [listData, setListData] = useState([]);
    const [generalNumber, setGeneralNumber] = useState('');
    const [codeValue, setCodeValue] = useState('GL');
    const [generalList, setGeneralList] = useState([]);
    const [accountList, setAccountList] = useState([]);
    const [codeList, setCodeList] = useState([]);
    const [loader, setLoader] = useState(false);
    const myBabyRef = useRef(null);
    const [transValue, setTransValue] = useState('');
    const [docDate, setDocDate] = useState(new Date());
    useEffect(() => {
        getData();
    }, []);


    const cloneIconClick = async (e) => {
        const list = [...listData];
        const clonedItem = {...e.row.data};
        list.splice(e.row.rowIndex, 0, clonedItem);
        setListData(list);
        e.event.preventDefault();
    }

    const getData = async () => {

        try {

            setLoader(true);
            const data1 = await fcsService.getAllFcsMonth('GL');
    // if ( fcsMonth && fcsMonth.length < 1) setLoader(false)

            if ( data1 && data1.length < 1) notify(" тохиргоо хийнэ үү  ", "warning")

            if ( data1 && data1.length < 1) {
                setLoader(false)
                return
            }

            setGeneralNumber(data1[0].orid + 1);

            // const data2 = await fcsService.getFcsMonth();
            setMonth1Value(data1[0]);
            setCodeValue(data1[0].jr);
            setGeneralList(data1)

            const data3 = await fcsService.getAllFcsChart()
            setAccountList(data3);


            const data4 = await fcsService.getAllFcsCustOrgn()
            setCodeList(data4);
            setLoader(false);


        } catch (e) {
            console.log(e)

           notify(" тохиргоо хийнэ үү  ", "warning")

            setLoader(false)
        }

       }

    const onChangeValue = (e) => {
        setMonth1Value(e);
        setCodeValue(e.jr);
    }

    const onValueChangedDate = (e) => {
        setDocDate(e.value);
    }

    const handleChangeValue = (event) => {
        setTransValue(event.target.value);
    }

    const clickPlus = (grid) => {
        if (transValue === '') return notify("гүйлгээний утга хоосон байна", "warning");
        grid.current.instance.addRow();
        let list = {};
        let t = {
            descr: transValue,
            debit: 0,
            credit: 0,
            acct: '',
            orgcode: '',
            orgname: '',
            jr_key: codeValue,
            docdate: docDate,
            docno: generalNumber
        }
        list.push(t);
        setListData(list);
    }

    const onEditingStart = (e) => {
    }

    const setData = (e) => {

    }

    const customizeDate = (data) => {
        return ` ${data.value}`;
    }

    /*
      const setCellValue = (rowData, value) => {
        if(value !== undefined) {
          rowData.orgcode = value;
          codeList.forEach(el => {
            if (el.c10 === value) {
              rowData.bankName = el.c11
            }
          });
        }
      }
    */

    const setCellDepValue = (rowData, value) => {
        if (value !== undefined) {
            rowData.debit = value;
            rowData.credit = 0;
        }
    }

    const setCellCerValue = (rowData, value) => {
        if (value !== undefined) {
            rowData.debit = 0;
            rowData.credit = value;
        }
    }

    const saveData = async () => {
        if (listData.length === 0) return notify("хоосон байна", "warning");
        let deposit = 0;
        let credit = 0;
        listData.forEach(element => {
            deposit = _.toNumber(deposit) + _.toNumber(element.debit);
            credit = _.toNumber(credit) + _.toNumber(element.credit);
        });
        if (credit.toFixed(2) !== deposit.toFixed(2)) return notify("Үлдэгдэл тэнцэхгүй байна", "warning");

        if (listData.length !== 0 && generalNumber && codeValue && docDate) {
            setLoader(true);
            //yyyymm
            const date = new Date(docDate);//new e.changes[0].data.docdate;
            const year = date.getFullYear() * 1e4; // 1e4 gives us the the other digits to be filled later, so 20210000.
            const month = (date.getMonth() + 1) * 100;
            const yyyymm = year + month // `+ ''` to convert to string from number, 202206
            const fcsMonth = await fcsService.getAllFcsMonth('GL');

            const fcsCurrentMonth = fcsMonth.map((item) => {
                let date = item.year + '/' + item.month
                let datetimenew = new Date(date)
                let year = datetimenew.getFullYear() * 1e4; // 1e4 gives us the the other digits to be filled later, so 20210000.
                let month = (datetimenew.getMonth() + 1) * 100;
                const yyyymm_month = year + month
                return yyyymm_month
            });
            if (yyyymm === fcsCurrentMonth[0]) {
                let jr_key = null;
                listData.forEach(element => {
                    jr_key = generalNumber;
                });
                fcsMonth.map(e => {
                    e.orid = generalNumber
                })
                listData.map((item) => {
                    item.docdate = docDate;
                    item.jr_key = codeValue;
                    item.acct = item.acct;
                    item.descr = item.descr;
                    item.docno = generalNumber;
                    item.orgcode = item.orgcode;
                    item.created_user_id = user.id;
                })
                await fcsService.addFcsGLTran(listData);
                await fcsService.editFcsMonth(fcsMonth[0]);
                setDocDate(new Date());
                setCodeValue(null);
                setListData([])
                await getData();
                notify('Амжилттай ерөнхий дэвтэрийн гүйлгээ хийгдлээ', "success", 2000);
                return setLoader(false)
            } else {
                notify('Сонгосон огноо тайлант он сарын хугацаанд биш байна', "error", 2000);
                return setLoader(false)

            }
        }
    }
    const onInitNewRow = (e) => {
        e.data.descr = transValue;
    }
    const style={
        text: {
            color: "#2E4765", fontWeight: "700", fontSize: ".875rem"
        },
        box: {
            border: "none", backgroundColor: "#F6F6F6", borderRadius: ".5rem", padding: ".25rem .4rem", width: "100%"
        },
        box2: {
            border: "none", backgroundColor: "#e6e4e4", borderRadius: ".5rem", padding: ".25rem .4rem", width: "100%"
        }
    }
    return (
        <React.Fragment>
            <LoadPanelComponent
                position={position14}
                visible={loader}
                shading={true}
                showPane={false}
                showIndicator={true}
                message=''
                shadingColor="rgba(255,255,255, 0.8)"
            />
            <div className={'card-body'} id='firstBox'>
                <div className={'row'}>
                    <div style={{marginLeft: 10, marginTop: 10}}>
                        <span style={{color : "#A3A3A3", marginRight: "1rem" }}>Тайлант он:</span><span style={{fontWeight: "700"}}>{month1Value.year} сар {month1Value.month}</span>
                    </div>
                </div>
                
                <div className="row" style={{display: 'flex', marginBottom: 30, marginTop: 20}}>
                    <div className="col-3" style={{display: 'flex', marginLeft: 10, flexDirection: "column"}}>
                            <div style={{display: 'flex', alignItems: 'center',...style.text}}>Журнал дугаар:</div>
                            <input value={generalNumber} style={{marginTop: 10,...style.box}} type='string' className='law-search-input'
                                    disabled={true}/> 
                    </div>
                    <div className="col-3" style={{display: 'flex', marginLeft: 40, flexDirection: "column"}}>
                            <div style={{ display: 'flex', alignItems: 'center',...style.text}}>Код:</div>
                            <input value={codeValue} type='string'style={{marginTop: 10,...style.box}} className='law-search-input' 
                                   disabled={true}/>
                    </div>
                </div>
                <div className="row" style={{display: 'flex', marginBottom: 30, marginTop: 20}}>
                    <div className="col-3" style={{display: 'flex', marginLeft: 10, marginRight: 20, flexDirection: "column"}}>
                        <div style={{ display: 'flex', alignItems: 'center', marginRight: 10,...style.text}}>Нэр:</div>
                        <SelectBox
                            items={generalList}
                            value={month1Value}
                            displayExpr="descr"
                            style={{marginTop: 10,...style.box}}
                            onValueChanged={(e) => onChangeValue(e.value)}
                            placeholder="сонгох"/>
                    </div>
                    <div className="col-3" style={{display: 'flex', marginRight: 20, flexDirection: "column",marginLeft: 20,}}>
                        <div style={{ display: 'flex', alignItems: 'center', marginRight: 10,...style.text}}>Огноо:
                        </div>
                        <DateBox value={docDate} style={{marginTop: 10,...style.box}} type="date" onValueChanged={onValueChangedDate}/>
                    </div>
                   
                </div>
                <div className="row" style={{display: 'flex', marginBottom: 30}}>
                    <div className="col-6" style={{marginLeft: 10}}>
                    <div style={{display: 'flex', alignItems: 'center', marginRight: 10,...style.text}}>Гүйлгээний утга:</div>
                    <input type='string' className='law-search-input' 
                           style={{marginTop: 10,...style.box}}
                           value={transValue} onChange={handleChangeValue}/>
                           </div>
                </div>
                <div className="col-12">
                    <DataGrid
                        dataSource={listData}
                        showBorders={true}
                        hoverStateEnabled={true}
                        ref={myBabyRef}
                        noDataText='Дата байхгүй байна.'
                        onSaved={setData}
                        onInitNewRow={onInitNewRow}
                        rowUpdating={(e) => {

                        }}
                    >
                        <Editing
                            mode="cell"
                            allowUpdating={true}
                            allowDeleting={true}
                            allowAdding={false}
                            useIcons={true}
                            texts={{
                                cancelAllChanges: 'Болих бүгд',
                                cancelRowChanges: 'Болих',
                                confirmDeleteMessage: 'Энэ гүйлгээ мэдээллийг устгах уу?',
                                confirmDeleteTitle: 'Баталгаажуулах',
                                deleteRow: 'Устгах',
                                editRow: 'Өөрчлөх',
                                saveAllChanges: 'Хадгалах бүгд',
                                saveRowChanges: 'Хадгалах',
                                undeleteRow: 'Буцаах',
                                validationCancelChanges: 'Баталгаажуулах болих',
                            }}
                        />
                        <Column caption="Тайлбар" dataField="descr"/>
                        <Column caption="дебит" dataField="debit" setCellValue={setCellDepValue}/>
                        <Column caption="кредит" dataField="credit" setCellValue={setCellCerValue}/>
                        <Column
                            cssClass="custom"
                            dataField="acct"
                            caption="Данс"
                            allowEditing={true}
                            allowFiltering={true}
                            width={400}
                        >
                            <RequiredRule
                                message='Данс оруулна уу'
                            />
                            <Lookup
                                valueExpr="acct"
                                displayExpr={(item) => {
                                    return item && `${item.acct} - ${item.descr}`
                                }}
                                dataSource={accountList}

                            />
                        </Column>
                        <Column
                            cssClass="custom"
                            dataField="orgcode"
                            caption="Харилцагч"
                            allowEditing={true}
                            allowFiltering={true}
                            width={400}
                        >
                            <RequiredRule
                                message='Харилцагч оруулна уу'
                            />
                            <Lookup
                                valueExpr="orgcode"
                                displayExpr={(item) => {
                                    return item && `${item.orgcode} - ${item.name}`
                                }}
                                dataSource={codeList}

                            />
                        </Column>
                        <Column type="buttons" width={110}>
                            <DevButton name="delete"/>
                            <DevButton hint="Clone" icon="copy" visible={true} onClick={cloneIconClick}/>
                        </Column>
                        <Summary>
                            <TotalItem
                                column="debit"
                                summaryType="sum"
                                customizeText={customizeDate}/>
                            <TotalItem
                                column="credit"
                                summaryType="sum"
                                customizeText={customizeDate}/>
                        </Summary>
                    </DataGrid>
                </div>
                <div style={{display: 'flex', flexDirection: 'row', marginTop: 30, marginBottom: 30}}>
                    <Button icon="plus"
                            text=""
                            type="default"
                            onClick={() => clickPlus(myBabyRef)}/>
                    <div style={{marginLeft: 30}}>
                        <Button className="saveButton"
                                text="Хадгалах"
                                type="success"
                                onClick={() => saveData()}/>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default GlEntery;

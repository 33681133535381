import React, { useState, useEffect, useContext } from "react";
import SelectBox from "devextreme-react/select-box";
import { UserContext } from "../../hooks/UserContext";
import { getYears, paletteErgenTologdoh, paletteOrlogo, paletteZarlaga } from "../../util";
import { Button } from "devextreme-react/button";
import lawIcon from "../../assets/imgs/laaawIcon.svg";
import BudgetService from "../../services/api/budget";
import notify from "devextreme/ui/notify";
import { Popup } from "devextreme-react/popup";
import TowloruulehHeaderInfo from "./components/towloruuleh/TowloruulehHeaderInfo";
import UpdatedBudgetListWithOrgs from "./components/UpdatedBudgetListWithOrgs";
import UpdatedBudgetBarChart from "./components/UpdatedBudgetBarChart";
import ZarlagaHeaderInfo from "./components/zarlaga/ZarlagaHeaderInfo";
import EhUusverHeadInfo from "./components/eh-uusver/EhUusverHeadInfo";
import TowloruulehTenYears from "./components/towloruuleh/TowloruulehTenYears";
import { useHistory, Link } from "react-router-dom"
import logo from './static/MicrosoftTeams-image (27).png'
import banner from '../../assets/imgs/govlogobanner.png'
import { Accordion } from "devextreme-react";
import PlanGuide from "../../assets/imgs/Тайлан бэлдэх заавар аргачлал төсөл.pdf";
import Source from './components/Source'
import { Tooltip } from "devextreme-react/tooltip";
import tooltip1 from '../../assets/imgs/tooltip/tooltip1.png'
import tooltip2 from '../../assets/imgs/tooltip/tooltip2.png'

const MainBudgetSource = (props) => {
    let history = useHistory();
    const [currentYear, setCurrentYear] = useState(new Date().getFullYear())
    const [law, setLaw] = useState([])
    const [showLawModal, setShowLawModal] = useState(false);
    const [showOrderModal, setShowOrderModal] = useState(false)
    const [windowSize, setWIndowSize] = useState(getWindowSize())
    const [withAnimationVisible, setWithAnimationVisible] = useState(false);
    const [withAnimationVisible1, setWithAnimationVisible1] = useState(false);
    const [firstChartVisible, setFirstChartVisible] = useState(false);
    const [whichComponentMaxmized, setWhichComponentMaximized] = useState(null);

    useEffect(() => {
        function handleWindowResize() {
            setWIndowSize(getWindowSize())
        }
        window.addEventListener("resize", handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize)
        }
    }, [])
    const renderItems = (e) => {
        return (
            <object width="100%" height="90vh" style={{ height: "90vh" }} data={e.file} type="application/pdf" />
        );
    };

    const renderTitles = (e) => {
        return <span>{e.title}</span>;
    };

    const accordionData = [
        { id: 1, title: "", file: PlanGuide },
    ];
    function getWindowSize() {
        const { innerWidth, innerHeight } = window;
        return { innerWidth, innerHeight }
    }

    const loadLaw = async () => {
        try {
            let r = await BudgetService.getBudgetLawByYear(currentYear - 1);
            setLaw(r)
        } catch (e) {
            notify(e.message, 'error', 2000);
        }
    }

    const { user } = useContext(UserContext)

    const locationOrlogo = {
        pathname: '/budget/econ5',
    };
    const locationZarlaga = {
        pathname: '/budget/econ2'
    };
    const locationErgenTologdoh = {
        pathname: '/budget/econ3'
    }

    useEffect(() => {
        loadLaw()
    }, [currentYear])

    let maximizeIcon = '<svg t="1659165394467" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2309" width="16" height="16"><path d="M504.576 603.428571a19.748571 19.748571 0 0 1-5.705143 13.129143l-189.696 189.696 82.285714 82.285715a36.425143 36.425143 0 0 1 10.861715 25.709714c0 20.004571-16.566857 36.571429-36.571429 36.571428h-256c-20.004571 0-36.571429-16.566857-36.571428-36.571428v-256c0-20.004571 16.566857-36.571429 36.571428-36.571429a36.205714 36.205714 0 0 1 25.709714 10.861715l82.285715 82.285714 189.696-189.696c3.437714-3.437714 8.557714-5.705143 13.129143-5.705143s9.728 2.304 13.129142 5.705143l65.133715 65.133714a19.602286 19.602286 0 0 1 5.705143 13.129143zM950.857143 109.714286v256c0 20.004571-16.566857 36.571429-36.571429 36.571428a36.205714 36.205714 0 0 1-25.709714-10.861714l-82.285714-82.285714-189.696 189.696c-3.437714 3.437714-8.557714 5.705143-13.129143 5.705143s-9.728-2.304-13.129143-5.705143l-65.133714-65.133715c-3.437714-3.437714-5.705143-8.557714-5.705143-13.129142s2.304-9.728 5.705143-13.129143l189.696-189.696-82.285715-82.285715a36.425143 36.425143 0 0 1-10.861714-25.709714c0-20.004571 16.566857-36.571429 36.571429-36.571428h256c20.004571 0 36.571429 16.566857 36.571428 36.571428z" fill="#515151" p-id="2310"></path></svg>';

    const popUpHandler = (trueFalser, value) => {
        setFirstChartVisible(trueFalser);
        setWhichComponentMaximized(value)
    }

    const renderHeaderSection = () => {
        return user.username === ("02") ? "" : (
            <div >
                <Popup
                    visible={showLawModal}
                    onHiding={() => setShowLawModal(false)}
                    hideOnOutsideClick={true}
                    showTitle={false}
                >
                    <div style={{ height: '100%' }}>
                        {law && law.length != 0 && showLawModal && <iframe id="lawForBudget" width="100%" height="100%" src={law[0].legal_url}></iframe>}
                    </div>
                </Popup>
                <Popup
                    visible={showOrderModal}
                    onHiding={() => setShowOrderModal(false)}
                    hideOnOutsideClick={true}
                    height="90vh"
                    showTitle={false}
                >
                    <Accordion
                        dataSource={accordionData}
                        collapsible={false}
                        multiple={false}
                        itemTitleRender={renderTitles}
                        itemRender={renderItems}

                    />
                </Popup>
                <div style={{ padding: 10, display: 'flex', gap: '1rem', flexWrap: 'wrap', alignItems: 'center' }}>
                    <div>
                        <SelectBox
                            width='10rem'
                            label="Төсвийн жил"
                            items={getYears(true)}
                            defaultValue={currentYear}
                            onValueChanged={(e) => setCurrentYear(e.value)}
                            placeholder="сонгох" style={{ borderRadius: 10 }} />
                    </div>
                    {law && law.length !== 0 &&
                        <div style={{ height: 28.5 }}>
                            <Button style={{ fontFamily: "Segoe UI", fontWeight: 'bold' }} icon={lawIcon} onClick={() => setShowLawModal(true)} text={law[0].name} />
                        </div>
                    }
                    {/* <div style={{ height: 28.5 }}>
                        <Button style={{ fontFamily: "Segoe UI", fontWeight: 'bold' }} icon={strategy} onClick={() => setShowOrderModal(true)} text="ГҮЙЦЭТГЭЛ" />
                    </div> */}
                </div>
            </div>
        )
    }
    const renderOrlogoSection = (typeNumber) => {
        return (
            <div style={{ minHeight: "50vh" }}>
                <TowloruulehHeaderInfo chosenMinistry={"ALL"} year={currentYear} orlogoType="Бүгд" chosenValue="ALL" locationOrlogo={locationOrlogo} orgId = {(user.organization.obt_id === 2 && user.organization.parent_id !== user.organization.parent_org_id) ? user.organization.parent_id : user.organization.id} />
                <div style={{ flexDirection: "row", flex: 1 }} className="d-flex responsive-flex">
                    <div style={{ "marginBottom": 10, flex: 1 }} className="col-6 responsive-col">
                        <div className="card" style={{ height: "100%" }}>
                            <div className="card-header">
                                <div style={{ flex: 1, display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
                                    <span style={styles.montserratBoldText}>1.БАЙГУУЛЛАГААР /сая ₮/</span>
                                    <Button style={{ border: '0px solid transparent', borderTopRightRadius: '15px' }} icon={maximizeIcon} onClick={() => popUpHandler(true, 1)} />
                                </div>
                            </div>
                            <div className="card-body">
                                <UpdatedBudgetListWithOrgs currentYear={currentYear} currentType={typeNumber} />
                            </div>
                        </div>
                    </div>
                    <div style={{ "marginBottom": 10, flex: 1 }} className="col-6 responsive-col">
                        <div className="card" style={{ height: "100%" }}>
                            <div className="card-header">
                                <div style={{ flex: 1, display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
                                    <span style={styles.montserratBoldText}>2.НИЙТ ОРЛОГО, ГҮЙЦЭТГЭЛ /жилээр, сая ₮/</span>
                                    <Button style={{ border: '0px solid transparent', borderTopRightRadius: '15px' }} icon={maximizeIcon} onClick={() => popUpHandler(true, 2)} />
                                </div>
                            </div>
                            <div className="card-body">
                                <TowloruulehTenYears chosenValue={'ALL'} orgId = {user.organization.id}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const renderZarlagaSection = (typeNumber) => {
        return (
            <div style={{ width: "100%" }}>
                <ZarlagaHeaderInfo year={currentYear} filterOrgText={'ALL'} locationZarlaga={locationZarlaga} type='zarlaga' orgId = {(user.organization.obt_id === 2 && user.organization.parent_id !== user.organization.parent_org_id) ? user.organization.parent_id : user.organization.id} />
                <div style={{ flex: 1, flexDirection: "row" }} className="d-flex responsive-flex">
                    <div style={{ "marginBottom": 10, flex: 1 }} className="col-6 responsive-col">
                        <div className="card" style={{ height: "100%" }}>
                            <div className="card-header">
                                <div style={{ flex: 1, display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
                                    <span style={styles.montserratBoldText}>1.БАЙГУУЛЛАГААР /сая ₮/</span>
                                    <Button style={{ border: '0px solid transparent', borderTopRightRadius: '15px' }} icon={maximizeIcon} onClick={() => popUpHandler(true, 3)} />
                                </div>
                            </div>
                            <div className="card-body">
                                <UpdatedBudgetListWithOrgs currentYear={currentYear} currentType={typeNumber} />
                            </div>
                        </div>
                    </div>
                    <div style={{ "marginBottom": 10, flex: 1 }} className="col-6 responsive-col">
                        <div style={{ height: "100%" }} className="card">
                            <div className="card-header">
                                <div style={{ flex: 1, display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
                                    <span style={styles.montserratBoldText}>2.НИЙТ ЗАРЛАГА, ГҮЙЦЭТГЭЛ /жилээр, сая ₮/</span>
                                    <Button style={{ border: '0px solid transparent', borderTopRightRadius: '15px' }} icon={maximizeIcon} onClick={() => popUpHandler(true, 4)} />
                                </div>
                            </div>
                            <div className="card-body">
                                <UpdatedBudgetBarChart currentType={typeNumber} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const renderErgenTologdohSection = (typeNumber) => {
        return (
            <div>
                <EhUusverHeadInfo year={currentYear} chosenValue="ALL" locationErgenTologdoh={locationErgenTologdoh} />
                <div style={{ flexDirection: 'row', flex: 1 }} className="d-flex responsive-flex">
                    <div style={{ "marginBottom": 10, flex: 1 }} className="col-6 responsive-col">
                        <div className="card" style={{ height: "100%" }}>
                            <div className="card-header">
                                <div style={{ flex: 1, display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
                                    <span style={styles.montserratBoldText}>1.БАЙГУУЛЛАГААР /сая ₮/</span>
                                    <Button style={{ border: '0px solid transparent', borderTopRightRadius: '15px' }} icon={maximizeIcon} onClick={() => popUpHandler(true, 5)} />
                                </div>
                            </div>
                            <div className="card-body" style={{ paddingTop: "4.5rem", paddingBottom: "9.8rem" }}>
                                <UpdatedBudgetListWithOrgs currentYear={currentYear} currentType={typeNumber} />
                            </div>
                        </div>
                    </div>
                    <div style={{ "marginBottom": 10, flex: 1 }} className="col-6 responsive-col">
                        <div className="card" style={{ height: "100%" }}>
                            <div className="card-header">
                                <div style={{ flex: 1, display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
                                    <span style={styles.montserratBoldText}>2.НИЙТ ЭРГЭН ТӨЛӨГДӨХ ТӨЛБӨР /жилээр, сая ₮/</span>
                                    <Button style={{ border: '0px solid transparent', borderTopRightRadius: '15px' }} icon={maximizeIcon} onClick={() => popUpHandler(true, 6)} />
                                </div>
                            </div>
                            <div className="card-body">
                                <UpdatedBudgetBarChart currentType={typeNumber} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div style={{ position: "relative" }} className="mainBudgerSource">
            <Popup
                visible={firstChartVisible}
                onHiding={() => setFirstChartVisible(false)}
                hideOnOutsideClick={true}
                showCloseButton={true}
                height={560}
                title={whichComponentMaxmized && whichComponentMaxmized === 1 ? 'БАЙГУУЛЛАГААР /сая ₮/' : (whichComponentMaxmized === 2 ? 'ТӨСӨВ, ГҮЙЦЭТГЭЛ /жилээр, сая ₮/' : (whichComponentMaxmized === 3 ? 'ЭХ ҮҮСВЭРИЙН БҮТЭЦ /ЭЗА, сая ₮/' : 'ТӨСӨВ, ГҮЙЦЭТГЭЛ /сараар, сая ₮/'))}
                showTitle={true}
            >
                {whichComponentMaxmized === 1 && <UpdatedBudgetListWithOrgs currentYear={currentYear} currentType={2} />}
                {whichComponentMaxmized === 2 && <TowloruulehTenYears chosenValue={'ALL'} />}
                {whichComponentMaxmized === 3 && <UpdatedBudgetListWithOrgs currentYear={currentYear} currentType={1} />}
                {whichComponentMaxmized === 4 && <UpdatedBudgetBarChart currentType={1} />}
                {whichComponentMaxmized === 5 && <UpdatedBudgetListWithOrgs currentYear={currentYear} currentType={3} />}
                {whichComponentMaxmized === 6 && <UpdatedBudgetBarChart currentType={3} />}
            </Popup>
            <Tooltip
                target="#asdf"
                position="left"
                visible={withAnimationVisible}
                hideOnOutsideClick={false}
            >
                <img
                    id="asdf"
                    src={tooltip1}
                    alt="#"
                />
            </Tooltip>

            <Tooltip
                target="#asdf1"
                position="left"
                visible={withAnimationVisible1}
                hideOnOutsideClick={false}
            >
                <img
                    id="asdf1"
                    src={tooltip2}
                    alt="#"
                />
            </Tooltip>
            {user.roles[0].role_id === 22 && <Link to={"/ministryList"}><div className='ministryButton'><img alt='logo' src={logo} /></div></Link>}
            {user.username === "02" && (<div className='banner'><img src={banner} alt="#" /></div>)}
            {renderHeaderSection()}
            <div style={{ display: "flex", flexDirection: "column" }}>
                <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                    <div style={{ display: 'flex', justifyContent: 'center', width: "100%", alignItems: 'center' }} onClick={() => history.push(locationOrlogo)}>
                        <div className="card" style={{
                            flex: 1, border: '#E2E8F3', cursor: 'pointer',
                            backgroundImage: `linear-gradient(to right, ${paletteOrlogo[0]}, ${paletteOrlogo[1]})`,
                            boxShadow: `0 0 5px ${paletteOrlogo[0]}`
                        }}>
                            <div className="card-body" style={{ display: 'flex', justifyContent: 'center' }}>
                                <h2 style={{ fontFamily: "Segoe UI", fontWeight: 'bold', color: '#fff', fontSize: 20, marginBottom: 0 }}>1. ТӨСВИЙН ОРЛОГО</h2>
                            </div>
                        </div>
                    </div>
                    {renderOrlogoSection(2)}
                </div>
                {/*Орлого төгсгөл*/}
                {/*Зарлага эхлэл*/}
                <div style={{ width: "100%" }}>
                    <div style={{ display: 'flex', justifyContent: 'center', width: "100%", alignItems: 'center' }} onClick={() => history.push(locationZarlaga)}>
                        <div className="card" style={{
                            flex: 1, border: '#E2E8F3', cursor: 'pointer',
                            backgroundImage: `linear-gradient(to right, ${paletteZarlaga[0]}, ${paletteZarlaga[1]})`,
                            boxShadow: `0 0 5px ${paletteZarlaga[0]}`
                        }}>
                            <div className="card-body" style={{ display: 'flex', justifyContent: 'center' }}>
                                <h2 style={{ fontFamily: "Segoe UI", fontWeight: 'bold', color: '#fff', fontSize: 20, marginBottom: 0 }}>2. ТӨСВИЙН ЗАРЛАГА</h2>
                            </div>
                        </div>
                    </div>
                    {renderZarlagaSection(1)}
                </div>
            </div>
            {/*Зарлага төгсгөл*/}
            {/*Эргэн төлөгдөх эхлэл*/}
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={() => history.push(locationErgenTologdoh)}>
                <div className="card" style={{
                    flex: 1, border: '#E2E8F3', cursor: 'pointer',
                    backgroundImage: `linear-gradient(to right, ${paletteErgenTologdoh[0]}, ${paletteErgenTologdoh[1]})`,
                    boxShadow: `0 0 5px ${paletteErgenTologdoh[0]}`
                }}>
                    <div className="card-body" style={{ display: 'flex', justifyContent: 'center' }}>
                        <h2 style={{ fontFamily: "Segoe UI", fontWeight: 'bold', color: '#fff', fontSize: 20, marginBottom: 0 }}>3. ЭРГЭН ТӨЛӨГДӨХ ТӨЛБӨР</h2>
                    </div>
                </div>
            </div>
            {renderErgenTologdohSection(3)}
            <Source />
        </div>
    )
}

const styles = {
    montserratBoldText: {
        //fontFamily: "Segoe UI",
        fontWeight: "700",
    }
}

export default MainBudgetSource;

import {useState, useEffect} from 'react';
import AuthService from "../services/api/auth";


export default function useFindUser() {
  const [user, setUser] = useState(null);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    findUser();
  }, []);

  const findUser = async () => {
    try {
      const res = await AuthService.getProfile();
      setUser(res.data);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  }


  return {
    user,
    setUser,
    findUser,
    isLoading
  }
}
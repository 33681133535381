import React, {useContext, useEffect, useState, useRef} from "react";

import 'devextreme-react/text-area';
import DataGrid, {
    Editing,
    Column,
    RequiredRule,
    PatternRule,
    Summary, TotalItem,Button as DevButton
} from 'devextreme-react/data-grid';


import Button from "devextreme-react/button";
import SelectBox from "devextreme-react/select-box";
import DateBox from "devextreme-react/date-box";
import fcsServices from "../../../../services/api/fcs";
import notify from "devextreme/ui/notify";
import {UserContext} from "../../../../hooks/UserContext";

import fcsService from "../../../../services/api/fcs";

import {Popup} from "devextreme-react/popup";
/*
import {Workbook} from "exceljs";
import {exportDataGrid} from "devextreme/excel_exporter";
import {saveAs} from "file-saver-es";*/
import CustorgParent from "../../Gl/gledit/custorgParent";
import _ from "lodash";
import LoadPanelComponent from "../../../../components/LoadPanelComponent";
// import {useRef} from "@types/react";


const InEntery = (props) => {
    const {user} = useContext(UserContext);
    const userListPosition = {of: '#usersList'}
    const jr_key = props.jr_key;
    const [organizationNo, setOrganizationNo] = useState(null);
    const [docno, setDocno] = useState(0);

    const [currentDate, setCurrentDate] = useState();
    const [listData, setListData] = useState([]);
    const [dataFcsMonth, setFcsMonth] = useState([]);

    const initCustValue = {name: '', orgcode: ''}
    const [custValue, setCustValue] = useState({...initCustValue});


    const [location, setLocation] = useState(null);
    const [modalVisibleCustOrg, setModalVisibleCustOrg] = useState(false)
    const [accountSecondList, setAccountSecondList] = useState([]);
    const [loader, setLoader] = useState(false);
    const [dataFcsLocation, setFcsLocation] = useState([]);
    const [acctCr, setAcctCr] = useState([])
    //const [data, setData] = useState([]);
    const [dataFcsInv, setFcsInv] = useState([])
    const [selectedInv, setSelectedInv] = useState('');
    //let _pivotGrid = useRef(null);

    const myBabyRef = useRef(null);
    /*const [drillDownDataSource, setDrillDownDataSource] = useState(null);
    const [popupVisible, setPopupVisible] = useState(false);
    const [popupTitle, setPopupTitle] = useState('');*/
    const [recptQty, setRecptQty] = useState('')
    const [unitCost, setUnitCost] = useState('')
    const [costAllo, setCostAllo] = useState('')

    useEffect(() => {
        setCurrentDate(new Date());
        getData();
    }, []);


    const getData = async () => {
        setLoader(true);

        getDocno()

        const result = await fcsServices.getAllFcsChart();
        setAccountSecondList(result);
        const fcsInv = await fcsService.getAllFcsInventory();
        setFcsInv(fcsInv);
        const fcsLocation = await fcsService.getAllFcsLocation();
        setFcsLocation(fcsLocation);
        const fcsMonth = await fcsService.getAllFcsMonth('IN');
        setFcsMonth(fcsMonth);
        getBudgetForm(fcsMonth);
        return setLoader(false)
    }


    const getDocno = async () => {
        const fcsMonth = await fcsService.getAllFcsMonth('IN');

        setFcsMonth(fcsMonth);
        fcsMonth.map((item) => {
            setDocno(item.orid)

        })
    }
    const customizeDate = (data) => {
        return Number(parseFloat(data.value).toFixed(2)).toLocaleString("en", {
            minimumFractionDigits: 2,
        });
    }

    const calNemegdel = () => {
        //    REPLACE ALL inorsub.cost_allo WITH inorsub.recpt_qty*inorsub.price_unit*snem/s1
        let newArr = [...listData]
        let sumAmt = 0

        newArr.map((el) => (
            sumAmt = sumAmt + el.recpt_qty * el.price_unit
        ))

        if (sumAmt > 0) (
            newArr.map((el, index) => {
                    const el2 = {
                        ...el,
                        cost_allo: Math.floor(el.recpt_qty * el.price_unit * costAllo * 100 / sumAmt + 0.5) / 100
                    }
                    el2.amt = el2.cost_allo + el.recpt_qty * el.price_unit
                    newArr[index] = el2
                }
            )
        )

        setListData(newArr)

        setCostAllo('')

    }

    const getBudgetForm = async (fcsMonth) => {
        setLoader(true);


        setLoader(false);
    };


    const onChangeLocation = (e) => {
        setLocation(e);
        console.log("aguulah ", e)

    }

    const onChangeAcctCr = (e) => {
        setAcctCr(e);
        console.log("crrrr ", e)

    }


    const onValueChangedDate = (e) => {
        setCurrentDate(e.value);
    }


    /*const clickMinus = (ind) => {
        setListData(listData.filter((item, index) => index !== ind));
    }
    const [sum, setSum] = useState({amt: 0, qty: 0})*/

    const onRowInserted = (e) => {
        e.component.navigateToRow(e.key);
    }
    const qtyChange = (e) => {
        let qty = e.target.value;
        Number(qty) < 0 ? setRecptQty('') : setRecptQty(qty)
    }

    const costChange = (e) => {
        const cost = e.target.value;
        Number(cost) < 0 ? setUnitCost('') : setUnitCost(cost)
    }
    const costAlloChange = (e) => {
        const cost = e.target.value;
        Number(cost) == 0 ? setUnitCost('') : setCostAllo(cost)
    }

    const clickPlus = () => {

        if (custValue.name == undefined || custValue == null || custValue.name.length < 2) return notify("Харилцагч хоосон байна.", "warning");
        if (location === null) return notify("Агуулах сонгох", "warning");

        if (selectedInv.stnumber == undefined) return notify("Бараа сонгох", "warning");
        if (Number(recptQty) <= 0) return notify("Too  0 ", "warning");


        let list = [...listData];
        const data1 = {

            docno: 1 + docno,


            cr: "",
            recpt_qty: recptQty,

            price_unit: unitCost,
            amt: unitCost * recptQty,
            stnumber: selectedInv.stnumber,
            stname: selectedInv.descr,
            inv_id: selectedInv.id,
            acct: selectedInv.acctcode,
            cust: custValue.orgcode,
            unit_name: selectedInv.unitiss,
            org_id: user.org_id,
            created_user_id: user.id,
            cost_allo: 0,

        }
        list.push(data1);
        setListData(list);
        setSelectedInv('')
        setRecptQty('')
        setUnitCost('')

    }

    const saveData = async () => {

        const date = new Date(currentDate);
        const year = date.getFullYear() * 1e4; // 1e4 gives us the the other digits to be filled later, so 20210000.
        const month = (date.getMonth() + 1) * 100;
        const yyyymm = year + month // `+ ''` to convert to string from number, 202206

        const fcsCurrentMonth = dataFcsMonth.map((item) => {
            let date = item.year + '/' + item.month
            let datetimenew = new Date(date)
            let year = datetimenew.getFullYear() * 1e4; // 1e4 gives us the the other digits to be filled later, so 20210000.
            let month = (datetimenew.getMonth() + 1) * 100;
            const yyyymm_month = year + month
            return yyyymm_month
        });


        if (yyyymm != fcsCurrentMonth[0]) {

            notify('Сонгосон огноо тухайн тайлант он сард биш байна', "warning");

            return

        }


        // if(organizationNo == null) return notify("Баримтын дугаар хоосон байна.", "warning");

        if (custValue === undefined || custValue === null || custValue.orgcode.length < 2) return notify("Харилцагч хоосон байна.", "warning");
        if (location === null || location === undefined) return notify("Агуулах хоосон байна.", "warning");

        if (listData.length === 0) return notify("Мэдээлэл байхгүй байна", "warning");


        try {

            let listData1 = listData


            let errorCount = 0;
            listData1.some((el) => {


                if (Number(el.amt) < 0) {
                    errorCount = errorCount + 1;
                    return notify("дун < 0 ", "warning");

                    return true;
                }

                // if (el.stnumber.length < 3) {
                //     errorCount = errorCount + 1;
                //     return notify("Бараа сонгох !!!!!! ", "warning");
                //
                //     return true;
                // }


                return false;
            });

            if (errorCount > 0) {
                return;
            }


            const result = await fcsService.getAllFcsMonth('IN');
            result.map(e => {
                e.orid = e.orid + 1
            })


            listData1.map((item) => {


                if (Number(item.price_unit) <= 0) item.price_unit = 0

                if (Number(item.recpt_qty) <= 0) item.recpt_qty = 0
                if (Number(item.amt) <= 0) item.amt = 0


                item.docdate = currentDate;
                item.docno = parseInt(result[0].orid);


                //    item.brn = organizationNo;
                //   item.acct = apValue.aracct;
                //   item.acctdb_name = apValue.descr;
                item.org = custValue.orgcode;
                item.orgname = custValue.name;

                delete item.id;
                delete item.updatedAt;
                let fcsInv = _.filter(dataFcsInv, {id: item.inv_id})
                //   let fcsUnit = _.filter(dataFcsUnit, {id: item.unit_id})
                item.created_user_id = user.id;
                item.org_id = user.org_id;
                item.date_recpt = currentDate;
                item.rs_number = 1 + Number(docno);
                item.stnumber = fcsInv[0].stnumber;
                item.stname = fcsInv[0].descr;
                item.loctn_id = location.id;
                item.chart_id = acctCr.id;
                item.custorgn_id = custValue.id;
                item.cr = acctCr.acct;
                item.org = custValue.orgcode;
                item.orgname = custValue.name;

                item.loctn_code = location.loctn_code;
                item.loctn_name = location.loctn_name;

                //  item.unit_name = fcsUnit[0].name;
                item.acct = fcsInv[0].acctcode;


                //   getdata();


            })


            await fcsService.addFcsInOrLin(listData1);
            await fcsService.editFcsMonth(result[0]);
            notify("Амжилттай хадгаллаа", "success");
            const list = [];
            setListData(list)
            getDocno()
            setOrganizationNo('')
            setLoader(false)
        } catch (err) {
            console.log(err)
            console.log(listData)
            setLoader(false)
            notify("Алдаа гарлаа", "error")
        }


    }

/*
    const onCellClick = (e) => {
        console.log('e', e);
        if (e.area === 'data' && e.cell.columnPath[1] === 'Санхүү Гүйцэтгэл') {
            const pivotGridDataSource = e.component.getDataSource();
            const rowPathLength = e.cell.rowPath.length;
            const rowPathName = e.cell.rowPath[rowPathLength - 1];
            setPopupTitle(`${rowPathName || 'Total'}`);
            setDrillDownDataSource(pivotGridDataSource.createDrillDownDataSource(e.cell));
            setPopupVisible(true);
        }
    }
    const onHiding = () => {
        setPopupVisible(false);
    }

    const onShown = () => {
        this.props.data.component.updateDimensions();
        //this.dataGrid.updateDimensions();
    }
    const onExporting = (e) => {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Main sheet');

        exportDataGrid({
            component: e.component,
            worksheet,
            autoFilterEnabled: true,
        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                //`${item.acct} - ${item.descr}`
                saveAs(new Blob([buffer], {type: 'application/octet-stream'}), `${popupTitle}.xlsx`);
            });
        });
        e.cancel = true;
    }*/

    const saveDataGrid = async (e) => {
    }
    const onEditingStart = (e) => {
    }
    const onChangeInv = async (e) => {
        console.log('eeee', e)
        await setSelectedInv(e);
        setRecptQty('')
        setUnitCost('')

    }

    const cloneIconClick = async (e) => {
        const list = [...listData];
        const clonedItem = {...e.row.data};
        list.splice(e.row.rowIndex, 0, clonedItem);
        setListData(list);
        e.event.preventDefault();
    }

    const style = {
        text: {
            color: "#2E4765", fontWeight: "600", fontSize: ".875rem"
        },
        box: {
            border: "none", backgroundColor: "#F6F6F6", borderRadius: ".5rem", padding: ".25rem .15rem", width: "100%"
        }
    }
    return (<div style={{padding: "0.5rem",}}>


        <LoadPanelComponent
            shadingColor="rgba(0,0,0,0.4)"
            position={userListPosition}
            visible={loader}
            message=''
            showPane={false}
        />

        <div className={'card-body'} id='firstBox'>
            <div className="col-12" style={{display: 'flex'}}>
                <div style={{display: 'flex', marginBottom: 10, marginTop: 10, marginLeft: 10}}>
                <span> {dataFcsMonth.map((item) => {
                    return <div><span style={{color: "#A3A3A3", marginRight: ".5rem"}}>Тайлант он</span><span
                        style={{fontWeight: "700"}}> {item.year} сар {item.month}</span></div>;
                })}
                </span>
                </div>

                <div className="col-4" style={{display: 'flex'}}>
                <span style={{fontWeight: "700", marginLeft: 50}}>
                   Бараа материалын орлогын гүйлгээ
                </span>
                </div>
                <div style={{display: 'flex', alignItems: 'left', marginTop: 10}}><span
                    style={{color: "#A3A3A3", marginRight: ".5rem"}}>Дугаар:</span></div>
                <div style={{marginLeft: 5, marginTop: 10}}>
                    <span style={{fontWeight: "700"}}>{1 + docno}</span>
                </div>
            </div>


            <div className="col-12" style={{display: 'flex', marginTop: 5}}>
                <div className="col-4" style={{display: 'flex', flexDirection: "column"}}>
                    <div style={{display: 'flex', alignItems: 'center', marginRight: 10, ...style.text}}>
                        Агуулах:
                    </div>
                    <SelectBox
                        items={dataFcsLocation}

                        searchEnabled={true}
                        style={{marginTop: 10, ...style.box}}

                        displayExpr={(item) => {
                            return item && `${item.loctn_code} - ${item.loctn_name}`
                        }}

                        onValueChanged={(e) => onChangeLocation(e.value)}
                        placeholder="сонгох"/>
                </div>
                <div className="col-4" style={{display: 'flex', flexDirection: "column", marginLeft: 30}}>
                    <div style={{display: 'flex', alignItems: 'center', marginRight: 10, ...style.text}}>Огноо:
                    </div>
                    <DateBox value={currentDate} style={{marginTop: 5, ...style.box}} type="date"
                             onValueChanged={onValueChangedDate}/>
                </div>
            </div>

            <div className="col-12" style={{display: 'flex', marginTop: 5}}>

                <div className="col-4" style={{display: 'flex', flexDirection: "column"}}>
                    <div style={{display: 'flex', alignItems: 'center', marginRight: 10, ...style.text}}>
                        Кредит данс:
                    </div>
                    <SelectBox
                        items={accountSecondList}
                        placeholder="сонгох"
                        name={'cr'}

                        searchEnabled={true}
                        style={{marginTop: 10, ...style.box}}
                        displayExpr={(item) => {
                            return item && `${item.acct} - ${item.descr}`
                        }}

                        onValueChanged={(e) => onChangeAcctCr(e.value)}
                    />


                </div>


                <div className="col-4" style={{display: 'flex', flexDirection: "column", marginLeft: 30}}>
                    <div
                        style={{display: 'flex', alignItems: 'center', marginRight: 10, ...style.text}}>Харилцагч:
                    </div>

                    <input type='string' className='law-search-input'
                           style={{marginTop: 10, ...style.box}}
                           onClick={(e) => setModalVisibleCustOrg(true)}
                           value={custValue.name}
                    />
                </div>
            </div>


            <div className="col-12" style={{display: 'flex', marginTop: 20}}>

                <div className="col-3" style={{display: 'flex'}}>

                    <SelectBox

                        items={dataFcsInv}
                        placeholder="сонгох"
                        name={'inv_id'}
                        style={{backgroundColor: '#FFFFFF', border: '1px solid #DDDDDD'}}
                        showClearButton={true}
                        searchEnabled={true}
                        //  value={selectedInv?  selectedInv.stnumber : ""}

                        displayExpr={(item) => {
                            return item && `${item.stnumber} - ${item.descr}`
                        }}

                        onValueChanged={(e) => onChangeInv(e.value)}
                    />


                </div>


                <div className="col-1" style={{display: 'flex'}}>
                    <input type='text' className='law-search-input' placeholder='х/нэгж'
                           name={'unitiss'}
                           disabled={true}
                           style={{backgroundColor: '#FFFFFF', border: '1px solid #DDDDDD'}}

                           value={selectedInv ? selectedInv.unitiss : ""}

                    />
                </div>
                <div className="col-2" style={{display: 'flex'}}>
                </div>
                <div className="col-2" style={{display: 'flex'}}>
                    <input type='text' className='law-search-input' placeholder='өртөг'
                           name={'amt'}
                           disabled={true}
                           style={{backgroundColor: '#FFFFFF', border: '1px solid #DDDDDD'}}
                           value={recptQty * unitCost}

                    />
                </div>

            </div>

            <div className="col-12" style={{display: 'flex', marginTop: 20}}>


                <input type='number' className='law-search-input' placeholder='тоо'
                       name={'recptQty'}

                       style={{
                           backgroundColor: '#FFFFFF',
                           border: '1px solid #DDDDDD',
                           marginLeft: 0,
                           marginRight: 10
                       }}
                       value={recptQty}
                       onChange={(e) => qtyChange(e)}
                />

                <input type='text' className='law-search-input' placeholder='нэгж үнэ'
                       name={'qty'}
                       style={{backgroundColor: '#FFFFFF', border: '1px solid #DDDDDD'}}
                       value={unitCost}
                       onChange={(e) => costChange(e)}
                />


                <Button icon="plus"
                        text=""
                        type="default"
                        onClick={() => clickPlus()}
                        style={{marginLeft: 10, alignment: "center", borderRadius: '7px', marginRight: 20}}
                />

            </div>


            <div className="col-12" style={{display: 'flex', marginTop: 20}}>


                <input type='text' className='law-search-input' placeholder='Тээвэр,бэлтгэх зардал/хөнгөлөлт'
                       name={'cost_allo'}
                       style={{backgroundColor: '#FFFFFF', border: '1px solid #DDDDDD'}}
                       value={costAllo}
                       onChange={(e) => costAlloChange(e)}
                />

                <Button style={{marginLeft: 10, marginRight: 20, alignment: "center", borderRadius: '7px'}}
                        text="Зардал хувиарлах "
                        type="default"
                        onClick={() => calNemegdel()}/>
                <Button className="saveButton"
                        text="Хадгалах"
                        type="default"
                        onClick={() => saveData()}/>
            </div>

        </div>

        <div className="col-12">
            <DataGrid
                dataSource={listData}
                showBorders={true}
                ref={myBabyRef}
                hoverStateEnabled={true}
                noDataText='Дата байхгүй байна.'
                onEditingStart={onEditingStart}
                columnAutoWidth={true}
                onSaved={saveDataGrid}
                onRowInserted={onRowInserted}
                rowUpdating={(e) => {
                    console.log(e);
                }}
            >
                <Editing
                    mode="cell"
                    allowUpdating={true}
                    allowDeleting={true}
                    useIcons={true}
                    texts={{
                        cancelAllChanges: 'Болих бүгд',
                        cancelRowChanges: 'Болих',
                        confirmDeleteMessage: 'Энэ мэдээллийг устгах уу?',
                        confirmDeleteTitle: 'Баталгаажуулах',
                        deleteRow: 'Устгах',
                        editRow: 'Өөрчлөх',
                        saveAllChanges: 'Хадгалах бүгд',
                        saveRowChanges: 'Хадгалах',
                        undeleteRow: 'Буцаах',
                        validationCancelChanges: 'Баталгаажуулах болих',
                    }}
                />
                <Column
                    cssClass="custom"
                    dataField="stname"
                    caption="Материалын үнэт зүйлийн нэр, зэрэг, дугаар"

                    allowEditing={false}
                    allowFiltering={true}
                >

                </Column>
                <Column
                    cssClass="custom"
                    dataField="stnumber"
                    caption="Код"
                    allowEditing={false}
                    allowFiltering={true}

                >

                </Column>

                <Column
                    cssClass="custom"
                    dataField="unit_name"
                    caption="х/нэгж"
                    allowEditing={false}
                    allowFiltering={true}

                >

                </Column>


                <Column
                    cssClass="custom"
                    dataField="recpt_qty"
                    caption="Тоо"
                    allowEditing={false}
                    allowFiltering={true}

                    width={80}>

                </Column>
                <Column
                    cssClass="custom"
                    dataField="price_unit"
                    caption="Нэгжийн үнэ"
                    allowEditing={false}
                    allowFiltering={true}

                >
                    <RequiredRule
                        message='Нэгжийн үнэ'
                    />
                    <PatternRule pattern={/^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/}
                                 message='зөвхөн тоо оруулна уу!'/>
                </Column>

                <Column
                    cssClass="custom"
                    dataField="cost_allo"
                    caption="Нэмэгдүүлэх үнэ"
                    allowEditing={false}
                    allowFiltering={true}

                >
                    <RequiredRule
                        message='Нэмэгдүүлэх үнэ'
                    />
                    <PatternRule pattern={/^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/}
                                 message='зөвхөн тоо оруулна уу!'/>
                </Column>
                <Column
                    cssClass="custom"
                    dataField="amt"
                    caption="Нийт дүн"
                    allowEditing={false}

                    customizeText={customizeDate}
                >
                </Column>
                <Column type="buttons">
                    <DevButton name="delete"/>
                </Column>
                <Summary>
                    <TotalItem
                        column="recpt_qty"
                        summaryType="sum"
                        customizeText={customizeDate}/>
                    <TotalItem
                        column="cost_allo"
                        summaryType="sum"
                        customizeText={customizeDate}/>
                    <TotalItem
                        column="amt"
                        summaryType="sum"
                        customizeText={customizeDate}/>
                </Summary>
            </DataGrid>


        </div>

        <div>
            <Popup
                width={500}
                height={500}
                minWidth={'80%'}
                maxWidth={'80%'}
                minHeight={'80%'}
                visible={modalVisibleCustOrg}
                title={'Байгууллага хувь хүмүүс сонгох'}
                onHiding={() => setModalVisibleCustOrg(false)}
                resizeEnabled={true}
                dragEnabled={true}
                showTitle={true}

            >
                <CustorgParent setCustValue={setCustValue} setModalVisibleCustOrg={setModalVisibleCustOrg}/>

            </Popup>

        </div>


    </div>);
};

export default InEntery;
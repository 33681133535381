import React, {useEffect, useState, useRef } from "react";
import LoadPanelComponent from "../../budget/components/loadPanel";
import notify from "devextreme/ui/notify";
import scheduleService from "../../../services/api/scheduleService";
import SelectBox from "devextreme-react/select-box";
import PivotGridDataSource from "devextreme/ui/pivot_grid/data_source";
import ScrollView from "devextreme-react/scroll-view";
import { Button } from "devextreme-react/button";
import PivotGrid, { Export as ExportPivot, Scrolling, HeaderFilter, Search,FieldPanel} from "devextreme-react/pivot-grid";
import { Popup } from "devextreme-react/popup";
import DataGrid, {
    Column, Export,
    Grouping,
    GroupItem,
    GroupPanel,
    SearchPanel, Selection,
    Summary,
    TotalItem
} from "devextreme-react/data-grid";
import { Workbook } from 'exceljs';
import saveAs from 'file-saver';
import { exportPivotGrid } from "devextreme/excel_exporter";
import {formatDate} from "../../../util";
import { CgMaximize, CgMinimize } from "react-icons/cg";
import EmptyData from "../../budget/components/empty";
const mergeViewList = [
    { name: '1', value: 'default' },
    { name: '2', value: 'second' },
    { name: '3', value: 'third' },
]

const ScheduleNegtgel = (props) => {
    let _pivotGrid = useRef(null);
    const [year, setYear] = useState([]);
    const [selectedYear, setSelectedYear] = useState(null);
    const [month, setMonth] = useState([]);
    const [selectedMonth, setSelectedMonth] = useState(null);
    const [status, setStatus] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState(null);
    //const [orgs, setOrgs] = useState([]);
    const [selectedOrg, setSelectedOrg] = useState(null);
    //const [agenda, setAgenda] = useState([]);
    const [selectedAgenda, setSelectedAgenda] = useState(null);
    //const [event, setEvent] = useState([]);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [loader, setLoader] = useState(false);
    const [dataSource, setDataSource] = useState([]);
    const [drillDownDataSource, setDrillDownDataSource] = useState(null);
    const [popupVisible, setPopupVisible] = useState(false);
    const [popupTitle, setPopupTitle] = useState('');
    const [expanded, setExpanded] = useState(false);
    const [chosenViewList, setChosenViewList] = useState('second');
    const [fullscreen, setFullscreen] = useState(false);
    useEffect(() => {
        loadYear('year');
    }, [])
    const loadYear = async (paramType) => {
        try {
            setLoader(true);
            let r = await scheduleService.getFilteredScheduleParamNegtgel(paramType);
            r.length > 0 && setSelectedYear(r[0].budget_year);
            setYear(r);
            //await loadMonth('month',)
            return setLoader(false);
        } catch (e) {
            notify(e.response.data.message, 'error', 2000);
        }
    }
    const loadMonth = async (paramType, year) => {
        try {
            setLoader(true);
            let r = await scheduleService.getFilteredScheduleParamNegtgel(paramType, year);
            r.length > 0 && setSelectedMonth(r[r.length - 1].month);
            setMonth(r);
            return setLoader(false);
        } catch (e) {
            notify(e.response.data.message, 'error', 2000);
        }
    }
    const loadStatus = async (paramType, year, month) => {
        try {
            setLoader(true);
            let r = await scheduleService.getFilteredScheduleParamNegtgel(paramType, year, month);
            r.length > 0 && setSelectedStatus(r[r.length - 1].status);
            setStatus(r);
            return setLoader(false);
        } catch (e) {
            notify(e.response.data.message, 'error', 2000);
        }
    }
    /*const loadOrgs = async (paramType, year, month, status) => {
        try {
            setLoader(true);
            let r = await scheduleService.getFilteredScheduleParamNegtgel(paramType, year, month, status);
            setOrgs(r);
            return setLoader(false);
        } catch (e) {
            notify(e.response.data.message, 'error', 2000);
        }
    }*/
    /*const loadAgenda = async (paramType, year, month, status, org) => {
        try {
            setLoader(true);
            let r = await scheduleService.getFilteredScheduleParamNegtgel(paramType, year, month, status, org);
            setAgenda(r);
            return setLoader(false);
        } catch (e) {
            notify(e.response.data.message, 'error', 2000);
        }
    }
    const loadEvent = async (paramType, year, month, status, org, agenda) => {
        try {
            setLoader(true);
            let r = await scheduleService.getFilteredScheduleParamNegtgel(paramType, year, month, status, org, agenda);
            setEvent(r);
            return setLoader(false);
        } catch (e) {
            notify(e.response.data.message, 'error', 2000);
        }
    }*/
    const loadDataSource = async (year, month,status, mergeType) => {
        try {
            setLoader(true);
            await scheduleService.getFilteredScheduleNegtgel(year, month,status, mergeType).then(data => {
                const dataSource = new PivotGridDataSource({
                    fields: [
                        /*{
                            caption: 'Төрөл',
                            dataField: 'type_name',
                            area: 'row',
                            sortBySummaryField: 'Total',
                            expanded: true,
                            sortBy: 'displayText',
                            sortOrder: 'asc',
                            width: '18rem',
                        },*/
                        {
                            caption: 'ТТЗ',
                            dataField: 'from_org_name_2',
                            area: 'row',
                            sortBy: 'displayText',
                            sortOrder: 'asc',
                            expanded: true,
                            width: 300,
                        },
                        {
                            caption: 'Шилжүүлсэн ТТЗ',
                            dataField: 'from_org_name_2',
                            area: 'filter',
                        },
                        {
                            caption: 'ТШЗ',
                            dataField: 'from_org_name',
                            area: 'row',
                            sortBy: 'displayText',
                            sortOrder: 'asc',
                            expanded: true,
                            width: 300,
                        },
                        {
                            caption: 'Шилжүүлсэн ТШЗ',
                            dataField: 'from_org_name',
                            area: 'filter',
                        },
                        {
                            caption: 'Сар',
                            dataField: 'from_month',
                            area: 'row',
                            sortBy: 'displayText',
                            sortOrder: 'asc',
                            expanded: true,
                            width: 300,
                        },
                        {
                            caption: 'Шилжүүлсэн сар',
                            dataField: 'from_month',
                            area: 'filter',
                        },
                        {
                            caption: 'Шилжүүлгийн төрөл',
                            dataField: 'transfer_group',
                            width: 300,
                            area: 'row',
                            sortBy: 'displayText',
                            sortOrder: 'asc',
                        },
                        {
                            caption: 'Шилжүүлгийн төрөл',
                            dataField: 'transfer_group',
                            area: 'filter',
                        },
                        {
                            caption: 'Хөтөлбөр',
                            dataField: 'from_agenda_name',
                            width: 300,
                            area: 'row',
                            sortBy: 'displayText',
                            sortOrder: 'asc',
                        },
                        {
                            caption: 'Шилжүүлсэн хөтөлбөр',
                            dataField: 'from_agenda_name',
                            area: 'filter',
                        },
                        {
                            caption: 'Зориулалт',
                            dataField: 'from_event_name',
                            width: 300,
                            area: 'row',
                            sortBy: 'displayText',
                            sortOrder: 'asc',
                        },
                        {
                            caption: 'Шилжүүлсэн зориулалт',
                            dataField: 'from_event_name',
                            area: 'filter',
                        },
                        {
                            caption: 'ЭЗА',
                            dataField: 'from_econ_name',
                            area: 'row',
                            sortBy: 'displayText',
                            sortOrder: 'asc',
                            width: 300,
                        },
                        {
                            caption: 'Шилжүүлсэн ЭЗА',
                            dataField: 'from_econ_name',
                            area: 'filter',
                        },
                        {
                            caption: 'ТТЗ',
                            dataField: 'to_org_name_2',
                            area: 'column',
                            sortBy: 'displayText',
                            sortOrder: 'asc',
                            expanded: true,
                            width: '18rem',
                        },
                        {
                            caption: 'ТШЗ',
                            dataField: 'to_org_name',
                            area: 'column',
                            sortBy: 'displayText',
                            sortOrder: 'asc',
                            expanded: true,
                            width: '18rem',
                        },
                        {
                            caption: 'Сар',
                            dataField: 'to_month',
                            area: 'column',
                            sortBy: 'displayText',
                            sortOrder: 'asc',
                            expanded: true,
                        },
                        {
                            caption: 'Хөтөлбөр',
                            dataField: 'to_agenda_name',
                            width: '18rem',
                            area: 'column',
                            sortBy: 'displayText',
                            sortOrder: 'asc',
                        },
                        {
                            caption: 'Зориулалт',
                            dataField: 'to_event_name',
                            width: '18rem',
                            area: 'column',
                            sortBy: 'displayText',
                            sortOrder: 'asc',
                        },


                        {
                            caption: 'ЭЗА',
                            dataField: 'to_econ_name',
                            dataType: 'to_econ_name',
                            area: 'column',
                            expanded: true,
                            sortingMethod: function (a, b) {
                                var index1 = a.value;
                                var index2 = b.value;
                                if (index1 > index2)
                                    return 1;
                                if (index2 > index1)
                                    return -1;
                                else
                                    return 0;
                            }
                        },
                        {
                            caption: 'Дүн',
                            dataField: 'transfer_amount',
                            dataType: 'number',
                            summaryType: 'sum',
                            format: {
                                type: 'fixedPoint',
                                precision: 2
                            },
                            area: 'data',
                            allowCrossGroupCalculation: true,
                        }],
                    store: data
                });
                setDataSource(dataSource);
                return setLoader(false);
            });
        } catch (e) {
            notify(e.response.data.message, 'error', 2000);
        }
    }
    const loadDataSourceDefault = async (year, month,status, mergeType) => {
        try {
            setLoader(true);
            await scheduleService.getFilteredScheduleNegtgel(year, month,status, mergeType).then(data => {
                const dataSource = new PivotGridDataSource({
                    fields: [
                        {
                            caption: 'ТТЗ',
                            dataField: 'from_org_name_2',
                            area: 'row',
                            sortBy: 'displayText',
                            sortOrder: 'asc',
                            expanded: true,
                            width: 300,
                            wordWrapEnabled: true,
                        },
                        {
                            caption: 'ТТЗ',
                            dataField: 'from_org_name_2',
                            area: 'filter',
                        },
                        {
                            caption: 'ТШЗ',
                            dataField: 'from_org_name',
                            area: 'row',
                            sortBy: 'displayText',
                            sortOrder: 'asc',
                            width: 300,
                            wordWrapEnabled: true,
                            //filterValues: ['[Customer].[Country].&[United Kingdom]'],
                        },
                        {
                            caption: 'ТШЗ',
                            dataField: 'from_org_name',
                            area: 'filter',
                        },
                        {
                            caption: 'Хөтөлбөр',
                            dataField: 'from_agenda_name',
                            width: 300,
                            area: 'row',
                            sortBy: 'displayText',
                            sortOrder: 'asc',
                        },
                        {
                            caption: 'Хөтөлбөр',
                            dataField: 'from_agenda_name',
                            area: 'filter',
                        },
                        {
                            caption: 'Зориулалт',
                            dataField: 'from_event_name',
                            width: 300,
                            area: 'row',
                            sortBy: 'displayText',
                            sortOrder: 'asc',
                        },
                        {
                            caption: 'Зориулалт',
                            dataField: 'from_event_name',
                            area: 'filter',
                        },
                        {
                            caption: 'ЭЗА',
                            dataField: 'from_econ_name',
                            dataType: 'from_econ_name',
                            width:'100px',
                            area: 'column',
                            expanded: true,
                            sortingMethod: function (a, b) {
                                var index1 = a.value;
                                var index2 = b.value;
                                if (index1 > index2)
                                    return 1;
                                if (index2 > index1)
                                    return -1;
                                else
                                    return 0;
                            }
                        },
                        {
                            caption: 'Дүн',
                            dataField: 'transfer_amount',
                            dataType: 'number',
                            summaryType: 'sum',
                            format: {
                                type: 'fixedPoint',
                                precision: 2
                            },
                            area: 'data',
                            allowCrossGroupCalculation: true,
                        }],
                    store: data
                });
                setDataSource(dataSource);
                return setLoader(false);
            });
        } catch (e) {
            notify(e.response.data.message, 'error', 2000);
        }
    }
    const onCellClick = (e) => {
        if (e.area === 'data') {
            const pivotGridDataSource = e.component.getDataSource();
            const rowPathLength = e.cell.rowPath.length;
            const rowPathName = e.cell.rowPath[rowPathLength - 1];
            setPopupTitle(`${rowPathName || 'Total'}`);
            setDrillDownDataSource(pivotGridDataSource.createDrillDownDataSource(e.cell));
            setPopupVisible(true);
        }
    }
    const customizeDate = (data) => {
        return Number(parseFloat(data.value).toFixed(2)).toLocaleString("en", {
            minimumFractionDigits: 2,
        });
    }
    const onHiding = () => {
        setPopupVisible(false);
    }

    const onShown = () => {
        this.props.data.component.updateDimensions();
        //this.dataGrid.updateDimensions();
    }
    const onCellPrepared = (e) => {
        if (e.area === 'column') {
            e.cellElement.wordWrapEnabled = false;
            e.cellElement.style.fontFamily = "Segoe UI";
            e.cellElement.style.fontWeight = "bold";
            e.cellElement.style.color = "#15186A";
            e.cellElement.style.alignment = "center";
            e.cellElement.style.backgroundColor = "#EEF0F4";
            e.cellElement.style.borderColor = "#D4DCEC";
            e.cellElement.style.fontSize = "12px";
            e.cellElement.style.verticalAlign = "middle";
        }
        else if (e.area === 'row') {
            e.cellElement.wordWrapEnabled = true;
            e.cellElement.style.fontFamily = "Segoe UI";
            e.cellElement.style.fontWeight = "bold";
            e.cellElement.style.color = "#15186A";
            e.cellElement.style.alignment = "center";
            e.cellElement.style.backgroundColor = "#EEF0F4";
            e.cellElement.style.borderColor = "#D4DCEC";
            e.cellElement.style.fontSize = "12px";
            if (e.cell.text === "") {

                e.cell.expanded = true;
                let cell = e.cellElement.querySelector(".dx-expand-icon-container");
                if (cell) cell.innerHTML = "";
                delete e.cell;
            }
        }
        else if (e.area === 'data') {
            e.cellElement.style.fontFamily = 'Segoe UI'
            e.cellElement.style.fontWeight = 'normal'
            e.cellElement.style.color = "#000000"
            e.cellElement.style.fontSize = "12px"

        }
        /*if (e.area === 'column' && e.rowIndex === 0) {
            /!*e.cellElement.style.fontFamily = 'Segoe UI'
            e.cellElement.style.fontWeight = 'semibold'
            e.cellElement.style.color = "#15186A"
            e.cellElement.style.backgroundColor = "#EEF0F4"
            e.cell.expanded = true;*!/
            ///e.cellElement.style.padding=0
            if (e.cellElement.innerHTML) e.cellElement.innerHTML = `<div style="text-align: center;display: inline-block;font-weight: bold;height: 60px;max-width: 100px;background-color: #00c853;vertical-align: top;overflow-wrap: break-word;padding: 0 5px;inline-size: 100px ">${e.cell.text}</div>`;
            //if (e.cellElement.outerHTML) e.cellElement.outerHTML = `<div style="text-align: center;display: inline-block;font-weight: bold;height: 60px;max-width: 100px;background-color: #00c853;vertical-align: top;overflow-wrap: break-word;padding: 0 5px; ">${e.cell.text}</div>`;

        }*/
    }
    const onCellPreparedHandler = (e) => {
        if (e.rowType === 'header') {
            e.cellElement.wordWrapEnabled = true;
            e.cellElement.style.color = "#15186A"
            e.cellElement.style.backgroundColor = "#E3E7F1"
            //e.cellElement.style.backgroundImage = `linear-gradient(to right, ${palettePayrollReport[0]}, ${palettePayrollReport[1]})`
        }
        if (e.rowType === 'data') {
            e.cellElement.style.color = "#15186A"
        }
    }
    const onExportingPivot = (e) => {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Main Sheet');

        worksheet.columns = [
            { width: 30 }, { width: 20 }, { width: 30 }, { width: 30 }, { width: 30 }, { width: 30 },
        ];

        exportPivotGrid({
            component: e.component,
            worksheet,
            topLeftCell: { row: 4, column: 1 },
            mergeRowFieldValues: true,
            keepColumnWidths: true,
            customizeCell: ({ pivotCell, excelCell }) => {
                const borderStyle = { style: 'thin', color: { argb: 'FF7E7E7E' } };
                excelCell.border = {
                    bottom: borderStyle,
                    left: borderStyle,
                    right: borderStyle,
                    top: borderStyle,
                };
            },
        }).then((cellRange) => {
            // Header
            const headerRow = worksheet.getRow(2);
            headerRow.height = 30;

            const columnFromIndex = worksheet.views[0].xSplit + 1;
            const columnToIndex = columnFromIndex + 1;
            worksheet.mergeCells(2, columnFromIndex, 2, columnToIndex);

            const headerCell = headerRow.getCell(columnFromIndex);
            headerCell.value = selectedYear + " оны " + selectedMonth + "-р сарын " + selectedOrg + ` нэгтгэл /Хөтөлбөр - ${selectedAgenda === 0 ? 'Бүгд' : selectedAgenda} Зориулалт - ${selectedEvent === 0 ? 'Бүгд' : selectedEvent}/`;
            headerCell.font = { bold: true, size: 14, underline: 'double' };
            headerCell.alignment = { horizontal: 'left', vertical: 'middle', wrapText: true, align: 'center' };

        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'OG-' + selectedYear + '-' + selectedMonth + '-' + selectedOrg + '-' + formatDate(new Date()) + ".xlsx");
            });
        });
        e.cancel = true;
    };
    const changeState = (area, value) => {
        let pvDataSource = _pivotGrid.current.instance.getDataSource();
        _pivotGrid.current.instance.beginUpdate();
        let fields = value ? pvDataSource.fields() : pvDataSource.fields().slice().reverse();
        fields.forEach((f) => {
            if (f.area === 'row' || f.area === 'column') {
                // console.log('f',f);
                if (value) {
                    f.expanded = true;
                    pvDataSource.expandAll(f.dataField);
                }

                else {
                    f.expanded = false;
                    pvDataSource.collapseAll(f.dataField);
                }
            }
        });
        _pivotGrid.current.instance.endUpdate();
    }
    const cellRenderMonth = (e) => {
        return (
            <span>{e.data.direction === 'Шилжүүлсэн' ? e.data.from_month : e.data.to_month}</span>
        )
    }
    const cellRenderMonthTo = (e) => {
        //console.log('eeeee', e);
        return (
            <span>{e.data.direction === 'Хүлээн авсан' ? e.data.from_month : e.data.to_month}</span>
        )
    }
    const cellRenderOrg = (e) => {
        return (
            <span>{e.data.direction === 'Шилжүүлсэн' ? e.data.from_org_name : e.data.to_org_name}</span>
        )
    }
    const cellRenderOrgTo = (e) => {
        return (
            <span>{e.data.direction === 'Хүлээн авсан' ? e.data.from_org_name : e.data.to_org_name}</span>
        )
    }
    const cellRenderAgenda = (e) => {
        return (
            <span>{e.data.direction === 'Шилжүүлсэн' ? e.data.from_agenda_name : e.data.to_agenda_name}</span>
        )
    }
    const cellRenderAgendaTo = (e) => {
        return (
            <span>{e.data.direction === 'Хүлээн авсан' ? e.data.from_agenda_name : e.data.to_agenda_name}</span>
        )
    }
    const cellRenderEvent = (e) => {
        return (
            <span>{e.data.direction === 'Шилжүүлсэн' ? e.data.from_event_name : e.data.to_event_name}</span>
        )
    }
    const cellRenderEventTo = (e) => {
        return (
            <span>{e.data.direction === 'Хүлээн авсан' ? e.data.from_event_name : e.data.to_event_name}</span>
        )
    }
    const cellRenderEcon = (e) => {
        return (
            <span>{e.data.direction === 'Шилжүүлсэн' ? e.data.from_econ_name : e.data.to_econ_name}</span>
        )
    }
    const cellRenderEconTo = (e) => {
        return (
            <span>{e.data.direction === 'Хүлээн авсан' ? e.data.from_econ_name : e.data.to_econ_name}</span>
        )
    }
    const requestFullscreen = () => {
        if (document.fullscreenElement) document.exitFullscreen();
        else document.getElementById("wrapper").requestFullscreen();
    }

    return (
        <React.Fragment>
        <div style={{ height: "100%", fontFamily: 'Segoe UI' }}>
            <LoadPanelComponent position={{ of: '#loadNegtgel' }} visible={loader} />
            {/*<div style={{ padding: 10, display: 'flex', gap: '3rem' }}>*/}
                <div style={{ gap: '3rem' ,padding: 10,display: 'flex', justifyContent: 'flex-start', flexDirection: 'row',alignItems: 'center'}}>
                    <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'flex-start', flexDirection: 'row', marginBottom: '.5rem' }}>
                        <label style={{ fontWeight: '500', marginRight: 10, width: '40%' }}>Төсвийн жил<span style={{ color: 'red' }}>*</span></label>
                        <SelectBox
                            items={year}
                            value={selectedYear}
                            searchEnabled={false}
                            valueExpr="budget_year"
                            displayExpr="budget_year"
                            onValueChanged={(e) => {
                                setSelectedYear(e.value);
                                loadMonth('month', e.value);
                            }}
                            style={{ width: '60%' }}
                            placeholder="сонгох" />
                    </div>
                    <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'flex-start', flexDirection: 'row', marginBottom: '.5rem'  }}>
                        <label style={{ fontWeight: '500', marginRight: 10, width: '40%' }}>Сар<span style={{ color: 'red' }}>*</span></label>
                        <SelectBox
                            items={month}
                            value={selectedMonth}
                            disabled={year && year.length !== 0 && month && month.length !== 0 && false}
                            searchEnabled={false}
                            valueExpr="month"
                            displayExpr="month"
                            onValueChanged={(e) => {
                                setSelectedMonth(e.value);
                                setSelectedStatus(null);
                                setSelectedOrg(null);
                                setSelectedAgenda(null);
                                setSelectedEvent(null);
                                loadStatus('status', selectedYear, e.value);
                            }}
                            style={{ width: '60%' }}
                            placeholder="сонгох" />
                    </div>
                    <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'flex-start', flexDirection: 'row', marginBottom: '.5rem' }}>
                        <label style={{ fontWeight: '500', marginRight: 10, width: '20%' }}>Төлөв<span style={{ color: 'red' }}>*</span></label>
                        <SelectBox
                            items={status}
                            value={selectedStatus}
                            searchEnabled={false}
                            disabled={year && year.length !== 0 && month && month.length !== 0 && status && status.length !== 0 && false}
                            valueExpr="status"
                            displayExpr="status_name"
                            onValueChanged={(e) => {
                                setSelectedStatus(e.value);
                                setSelectedAgenda(null);
                                setSelectedEvent(null);
                                chosenViewList === 'second' ? loadDataSource(selectedYear,selectedMonth, e.value, chosenViewList) : loadDataSourceDefault(selectedYear,selectedMonth, e.value, chosenViewList);

                            }}
                            style={{ width: '75%' }}
                            placeholder="сонгох" />
                    </div>
                </div>
                {/*<div style={{ width: '30rem' }}>
                    <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'flex-start', flexDirection: 'row', marginBottom: '.5rem' }}>
                        <label style={{ fontWeight: '500', marginRight: 10, width: '20%' }}>Төлөв<span style={{ color: 'red' }}>*</span></label>
                        <SelectBox
                            items={status}
                            value={selectedStatus}
                            searchEnabled={false}
                            disabled={year && year.length !== 0 && month && month.length !== 0 && status && status.length !== 0 && false}
                            valueExpr="status"
                            displayExpr="status_name"
                            onValueChanged={(e) => {
                                setSelectedStatus(e.value);
                                setSelectedAgenda(null);
                                setSelectedEvent(null);
                                loadOrgs('org', selectedYear, selectedMonth, e.value);
                            }}
                            style={{ width: '75%' }}
                            placeholder="сонгох" />
                    </div>
                    <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'flex-start', flexDirection: 'row' }}>
                        <label style={{ fontWeight: '500', marginRight: 10, width: '20%' }}>Байгууллага<span style={{ color: 'red' }}>*</span></label>
                        <SelectBox
                            items={orgs}
                            value={selectedOrg}
                            searchEnabled={true}
                            disabled={year && year.length !== 0 && month && month.length !== 0 && status && status.length !== 0 && orgs && orgs.length !== 0 && false}
                            valueExpr="value_name"
                            displayExpr="text_name"
                            onValueChanged={(e) => {
                                setSelectedOrg(e.value);
                                setSelectedAgenda(null);
                                setSelectedEvent(null);
                                loadAgenda('agenda', selectedYear, selectedMonth, selectedStatus, e.value);
                            }}
                            style={{ width: '75%' }}
                            placeholder="сонгох" />
                    </div>
                </div>
                <div style={{ width: '30rem' }}>
                    <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'flex-start', flexDirection: 'row', marginBottom: '.5rem' }}>
                        <label style={{ fontWeight: '500', marginRight: 10, width: '20%' }}>Хөтөлбөр<span style={{ color: 'red' }}>*</span></label>
                        <SelectBox
                            items={agenda}
                            value={selectedAgenda}
                            disabled={year && year.length !== 0 && month && month.length !== 0 && status && status.length !== 0 && orgs && orgs.length !== 0 && agenda && agenda.length !== 0 && false}
                            searchEnabled={true}
                            valueExpr="agenda"
                            displayExpr="agenda_name"
                            onValueChanged={(e) => {
                                setSelectedAgenda(e.value);
                                setSelectedEvent(null);
                                loadEvent('event', selectedYear, selectedMonth, selectedStatus, selectedOrg, e.value);
                            }}
                            style={{ width: '75%' }}
                            placeholder="сонгох" />
                    </div>
                    <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'flex-start', flexDirection: 'row' }}>
                        <label style={{ fontWeight: '500', marginRight: 10, width: '20%' }}>Зориулалт<span style={{ color: 'red' }}>*</span></label>
                        <SelectBox
                            items={event}
                            value={selectedEvent}
                            disabled={year && year.length !== 0 && month && month.length !== 0 && status && status.length !== 0 && orgs && orgs.length !== 0 && agenda && agenda.length !== 0 && event && event.length !== 0 && false}
                            searchEnabled={true}
                            valueExpr="event"
                            displayExpr="event_name"
                            onValueChanged={(e) => {
                                setSelectedEvent(e.value);
                                chosenViewList === 'second' ? loadDataSource(selectedYear, selectedMonth, selectedStatus, selectedOrg, selectedAgenda, e.value, chosenViewList) : loadDataSourceDefault(selectedYear, selectedMonth, selectedStatus, selectedOrg, selectedAgenda, e.value, chosenViewList);
                            }}
                            style={{ width: '75%' }}
                            placeholder="сонгох" />
                    </div>
                </div>*/}
           {/* </div>*/}
            <div id="wrapper" height="100%" width="100%" style={{ padding: 10, backgroundColor: "#fff" }}>
                {(dataSource && !loader) ? (
                    <div>
                        {
                            month && month.length > 0 && year && year.length > 0 ?
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 10, alignItems: 'baseline'}}>
                                    <div style={{ flexDirection: 'row', justifyContent: 'flex-start', display: 'flex' }}>
                                        <SelectBox
                                            items={mergeViewList}
                                            valueExpr={'value'}
                                            displayExpr={'name'}
                                            style={{ borderRadius: '10px' }}
                                            defaultValue={chosenViewList}
                                            width={180}
                                            label="Харагдах хэлбэр"
                                            onValueChanged={(e) => {
                                                setChosenViewList(e.value);
                                                e.value === 'second' ? loadDataSource(selectedYear, selectedMonth,selectedStatus, e.value) : loadDataSourceDefault(selectedYear, selectedMonth,selectedStatus, e.value);
                                            }}
                                            placeholder="сонгох" />
                                    </div>
                                    <div>
                                        <Button
                                            onClick={() => {
                                                requestFullscreen()
                                                setFullscreen(prev => !prev)
                                            }}
                                            render={() => {
                                                return fullscreen ?
                                                    (<CgMinimize size={"18px"} color="#1a1976" />)
                                                    :
                                                    (<CgMaximize size={"18px"} color="#1a1976" />)
                                            }}
                                            hint={fullscreen ? 'Багасгах' : 'Дэлгэцэнд бүтэн харуулах'}
                                        >
                                        </Button>
                                        <Button
                                            type={'default'}
                                            style={{backgroundColor: '#1890ff', marginLeft: '0.5rem'}}
                                            text={expanded ? 'Хураах' : 'Задлах'}
                                            onClick={() => {
                                                setExpanded(!expanded);
                                                changeState("", !expanded)
                                            }}
                                        />
                                    </div>
                                </div>
                                : null
                        }
                        <PivotGrid
                            id="pivotgrid"
                            ref={_pivotGrid}
                            height={'80%'}
                            //dataFieldArea={'column'}
                            dataSource={dataSource}
                            rowHeaderLayout={'tree'}
                            allowSortingBySummary={true}
                            sortingMethod={true}
                            allowFiltering={true}
                            showBorders={true}
                            allowExpandAll={true}
                            showColumnGrandTotals={false}
                            showRowGrandTotals={true}
                            showRowTotals={true}
                            showColumnTotals={false}
                            onExporting={onExportingPivot}
                            wordWrapEnabled={true}
                            onCellPrepared={onCellPrepared}
                            fieldChooser={{
                                enabled: false
                            }}
                            texts={{
                                noData: 'Дата байхгүй байна.',
                                collapseAll: 'Бүгд хураах',
                                expandAll: 'Бүгд дэлгэх',
                                showFieldChooser: 'Талбар сонголт харах',
                                exportToExcel: 'Excel файл татах',
                                grandTotal: 'Нийт дүн',
                                total: '{0}'
                            }}
                            onCellClick={onCellClick}
                        >
                            <HeaderFilter
                                showRelevantValues={true}
                                width={300}
                                height={400}
                            >
                                <Search enabled={true} />
                            </HeaderFilter>
                            <FieldPanel visible={true} showRowFields={false} showDataFields={false}/>
                            <ExportPivot enabled={true} />
                            <Scrolling mode="standard" />
                        </PivotGrid>
                    </div>

                ) :
                    <div style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        family: "'Montserrat', sans-serif"
                    }}>
                        <EmptyData/>
                    </div>
                }
                {
                    popupVisible &&
                    <div className="card-body">
                        <Popup
                            visible={popupVisible}
                            width={'100%'}
                            height={'90%'}
                            title={popupTitle}
                            closeOnOutsideClick={true}
                            onHiding={onHiding}
                            onShown={onShown}
                        >
                            <ScrollView width='100%' height='100%'>
                                <DataGrid
                                    //width={1200}
                                    //height={800}
                                    dataSource={drillDownDataSource}
                                    noDataText='Дата байхгүй байна.'
                                    style={{ fontFamily: "Segoe UI", fontWeight: 'normal' }}
                                    showBorders={true}
                                    //rowAlternationEnabled={true}
                                    showRowLines={true}
                                    showColumnLines={true}
                                    showColumnHeaders={true}
                                    allowColumnResizing={true}
                                    columnAutoWidth={true}
                                    wordWrapEnabled={true}
                                    onCellPrepared={onCellPreparedHandler}
                            /*onExporting={onExporting}*/>

                                    <GroupPanel visible={true} />
                                    <SearchPanel visible={true} />
                                    <Grouping autoExpandAll={true} />
                                    <Selection mode="single" />
                                    <Column dataField="request_org_name" caption="Хүсэлт гаргасан байгууллага" />
                                    <Column dataField="request_code" caption="Хүсэлтийн дугаар" width={90}
                                        allowCollapsing={false}
                                        allowFiltering={true} />
                                    <Column dataField="transfer_group" caption="Шилжүүлгийн төрөл"
                                        allowCollapsing={false}
                                        allowFiltering={true} />
                                    <Column dataField="status_name" caption="Хүсэлтийн төлөв"
                                        allowCollapsing={false}
                                        allowFiltering={true} />
                                    <Column caption="Шилжүүлсэн" alignment={'center'}>
                                        <Column //dataField="from_month"
                                            caption="Сар"
                                            allowCollapsing={false} width={'3rem'}
                                            allowFiltering={true} cellRender={cellRenderMonth} />
                                        <Column //dataField="from_org_name"
                                            caption="Байгууллага" width={'6rem'}
                                            allowCollapsing={false}
                                            allowFiltering={true} cellRender={cellRenderOrg} />
                                        <Column //dataField="from_agenda_name"
                                            caption="Хөтөлбөр" width={'6rem'}
                                            allowCollapsing={false}
                                            allowFiltering={true} cellRender={cellRenderAgenda} />
                                        <Column //dataField="from_event_name"
                                            caption="Зориулалт" width={'6rem'}
                                            allowCollapsing={false}
                                            allowFiltering={true} cellRender={cellRenderEvent} />
                                        <Column //dataField="from_econ_name"
                                            caption="ЭЗА"
                                            allowCollapsing={false} width={'6rem'}
                                            allowFiltering={true} cellRender={cellRenderEcon} />
                                        <Column dataField="from_debit" caption="Төсвийн дүн" dataType="number" format="#,##0.00" />
                                    </Column>
                                    <Column caption="Хүлээн авсан" alignment={'center'}>
                                        <Column caption="Сар" width={'3rem'}
                                            allowCollapsing={false}
                                            allowFiltering={true} cellRender={cellRenderMonthTo} />
                                        <Column //dataField="to_org_name"
                                            caption="Байгууллага"
                                            allowCollapsing={false} width={'6rem'}
                                            allowFiltering={true} cellRender={cellRenderOrgTo} />
                                        <Column //dataField="to_agenda_name"
                                            caption="Хөтөлбөр"
                                            allowCollapsing={false} width={'6rem'}
                                            allowFiltering={true} cellRender={cellRenderAgendaTo} />
                                        <Column //dataField="to_event_name"
                                            caption="Зориулалт" width={'6rem'}
                                            allowCollapsing={false}
                                            allowFiltering={true} cellRender={cellRenderEventTo} />
                                        <Column //dataField="to_econ_name"
                                            caption="ЭЗА" width={'6rem'}
                                            allowCollapsing={false}
                                            allowFiltering={true} cellRender={cellRenderEconTo} />
                                        <Column dataField="to_debit" caption="Төсвийн дүн" dataType="number" format="#,##0.00" width={'6rem'} />
                                    </Column>
                                    <Column dataField="direction" caption="Гүйлгээний төрөл" dataType="text" />
                                    <Column dataField="transfer_amount" caption="Дүн" dataType="number" format="#,##0.00" />
                                    <Column dataField="request_note" caption="Хүсэлтийн тайлбар"
                                        allowCollapsing={false}
                                        allowFiltering={true} width={'18rem'} />
                                    <Summary>
                                        <GroupItem
                                            column="transfer_amount"
                                            summaryType="sum"
                                            customizeText={customizeDate}
                                            showInGroupFooter={true} />
                                        <TotalItem
                                            column="transfer_amount"
                                            summaryType="sum"
                                            customizeText={customizeDate} />
                                    </Summary>
                                    <Export enabled={true} allowExportSelectedData={false} />
                                </DataGrid>
                            </ScrollView>
                        </Popup>
                    </div>
                }
            </div>
        </div>
        </React.Fragment>
    )
}

export default ScheduleNegtgel

import React from "react"
import {BiTime} from "react-icons/bi"
import {BsFillPersonFill} from "react-icons/bs"
import {useHistory as useNavigate} from "react-router-dom"
import { urlServer  } from "../../../shared/lib/request"
import Paragraph from "antd/es/typography/Paragraph"

function NewsCard({
  createdAt,
  news_title,
  id,
  file_name,
  author,
  news_type,
}) {

  let date = new Date(createdAt)
    .toLocaleString("en-us", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    })
    .replace(/(\d+)\/(\d+)\/(\d+)/, "$3-$1-$2")
  const navigate = useNavigate()

  const typeText = () => {
    switch (news_type) {
      case 1:
        return "Үйл явдал"
      case 2:
        return "Зар"
      case 3:
        return "Төсвийн тойм"
      case 4:
        return "Инфографик"
    }
  }
  const directPage = () => {
    let location = {
      pathname: `/article/${id}`,
      state: { news_type, news_type_name: typeText(news_type) },
  };
    navigate.push(location)
  }
  return (
    <div className="newCard" onClick={directPage}>
      <div className="img-container">
        <div className="img">
          {/*<div className="type">{typeText()}</div>*/}
          {file_name && (
          <img loading="lazy" src={`${urlServer}/api/file/${file_name}?height=115`} alt={file_name}/>
          )}
        </div>
      </div>
      <div className="text-container">
        <div className="title" ><Paragraph ellipsis={{rows: 3 }}>
        {news_title}
      </Paragraph></div>
        <div className="meta">
          <div className="author">
            <BsFillPersonFill className="svg" />
            {author && author}
          </div>
          <div className="date">
            <BiTime />
            {date}
          </div>
        </div>
      </div>
    </div>
  )
}

export default NewsCard

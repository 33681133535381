import fileTypeService from "../../../services/api/fileType";
import notificationService from "../../../services/api/notification";
import FileServices from "../../../services/api/file";
import notificationFileService from "../../../services/api/notificationFile";
import notificationUserService from "../../../services/api/notificationUser";
import notify from "devextreme/ui/notify";
import React, {useRef, useState} from "react";
import OrganizationService from "../../../services/api/organization";
import TreeView from "devextreme-react/tree-view";
import {FileUploader, Popup, RadioGroup, ScrollView, SelectBox, TextBox} from "devextreme-react";
import LoadPanelComponent from "../../budget/components/loadPanel";
import DropDownBox from "devextreme-react/drop-down-box";
import {RequiredRule, Validator} from "devextreme-react/validator";
import HtmlEditor, {Item, Toolbar} from "devextreme-react/html-editor";
import Button from "devextreme-react/button";

const AddNotification = ({ popupVisible, setPopupVisible, setNotifications }) => {

    const [fileTypes, setFileTypes] = useState([]);
    const [organizations, setOrganizations] = useState(null);
    const visibleTypes = ['Popup', 'Мэдэгдэл дотор'];
    const [treeView, setTreeView] = useState(null);
    const [treeBoxValue, setTreeBoxValue] = useState(null);
    const [isTreeBoxOpened, setIsTreeBoxOpened] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const fileRef = useRef(null);
    const selectRef = useRef(null);
    const htmlRef = useRef(null);
    const addFormRef = useRef(null);
    const addFormOrgValidatorRef = useRef(null);
    const addFormTitleValidatorRef = useRef(null);
    const addFormContentValidatorRef = useRef(null);
    const sizeValues = ["8pt", "10pt", "12pt", "14pt", "18pt", "24pt", "36pt"];
    const fontValues = [
        "Arial",
        "Courier New",
        "Georgia",
        "Impact",
        "Lucida Console",
        "Tahoma",
        "Times New Roman",
        "Verdana",
        "Montserrat"
    ];
    const fontOptions = {placeholder: "Фонт"};
    const headerValues = [false, 1, 2, 3, 4, 5];

    const getAllNotifications = async () => {
        try {
            const res = await notificationService.getAllNotifications();

            if (res && res.code === 200) {
                setNotifications(res.data);
            }
        } catch (e) {
            console.log(e);
        }
    }

    const getNotificationFileType = async () => {
        try {
            const res = await fileTypeService.getNotificationFileType();

            if (res && res.code === 200) {
                setFileTypes(res.data);
            }
        } catch (e) {
            console.log(e);
        }
    }

    const getOrganizationIncludeParent = async () => {
        try {
            const res = await OrganizationService.getOrganizationIncludeParent();
            if (res && res.code === 200) {
                setOrganizations(res.data);
            }
        } catch (e) {
            console.log(e);
        }
    }

    const onSubmit = async (event) => {
        try {
            event.preventDefault();

            setIsLoading(true);

            let notificationObj = {};
            
            notificationObj.title = event.target.title.value;
            notificationObj.notif_info = event.target.notif_info.value;
            notificationObj.sent_org_ids = treeBoxValue;

            if (event.target.file.files.length) {
                notificationObj.file_type_id = event.target.file_type.value;
            }

            if (event.target.visible_type.value === 'Popup') {
                notificationObj.visible_type = 1;
            } else {
                notificationObj.visible_type = 2;
            }

            const res = await notificationService.addNotification(notificationObj);

            for (let i = 0; i < event.target.file.files.length; i++) {
                let data = new FormData();

                data.append("file", event.target.file.files[i]);

                const res1 = await FileServices.uploadFileManagement(data, event.target.file_type.value);

                let notificationFileObj = {};

                notificationFileObj.file_id = res1.data.id;
                notificationFileObj.notification_id = res.data.id;

                await notificationFileService.addNotificationFile(notificationFileObj);
            }

            let notificationUserObj = {};

            notificationUserObj.organization = treeBoxValue;
            notificationUserObj.notif_id = res.data.id;

            notificationUserService.addNotificationUser(notificationUserObj);

            if (res && res.code === 200) {
                await getAllNotifications();
                setOrganizations([]);
                setPopupVisible(false);
                notify("Амжилттай хадгаллаа", "success", 3000)
            }

            setIsLoading(false);
        } catch (e) {
            console.log(e);
            setIsLoading(false);
        }
    }

    const treeViewRender = (e) => {
        // console.log('tre re', e.component._dataSource._store._array)
        return (
            <TreeView 
                    // dataSource={e.component._dataSource._store._array}
                      dataSource={organizations}
                      ref={(ref) => { setTreeView(ref) }}
                      dataStructure="plain"
                      keyExpr="id"
                      parentIdExpr="parent"
                      selectionMode="multiple"
                      displayExpr="org_name"
                      selectByClick={true}
                      showCheckBoxesMode="normal"
                      selectNodesRecursive={false}
                      onContentReady={treeViewOnContentReady}
                      onItemsChange={treeViewOnContentReady}
                      onItemClick={onTreeItemClick}
                      onItemSelectionChanged={treeViewItemSelectionChanged}
            />
        );
    }

    const syncTreeViewSelection = (e) => {
        setTreeBoxValue(e.value);
        if (!treeView) return;

        if (!e.value) {
            treeView.instance.unselectAll();
        } else {
            treeView.instance.selectItem(e.value);
        }
    }

    const treeViewItemSelectionChanged = (e) => {
        setTreeBoxValue(e.component.getSelectedNodeKeys());
    }

    const treeViewOnContentReady = (e) => {
        e.component.selectItem(treeBoxValue);
    }

    const onTreeItemClick = () => {
        setIsTreeBoxOpened(false);
    }

    const onTreeBoxOpened = (e) => {
        if (e.name === 'opened') {
            setIsTreeBoxOpened(e.value);
        }
    }

    const onShowing = () => {
        getOrganizationIncludeParent();
        getNotificationFileType();
    }

    const onHiding = () => {
        addFormRef.current.reset();
        addFormOrgValidatorRef.current.instance.reset();
        addFormTitleValidatorRef.current.instance.reset();
        addFormContentValidatorRef.current.instance.reset();

        fileRef.current.instance.reset();
        selectRef.current.instance.reset();
        htmlRef.current.instance.reset();
        setTreeBoxValue(null);
        setOrganizations([]);
        setPopupVisible(false);
    }

    useState(() => {
        getOrganizationIncludeParent();
        getNotificationFileType();
    });
    const requestFullscreen=()=> {
        /* Get the element you want displayed in fullscreen */
        const element = document.getElementById("htmlEditorEdit");
        // Use a guard clause to exit out of the function immediately
        if (element.requestFullscreen) {
            element.requestFullscreen();
        } else if (element.webkitRequestFullscreen) {
            // Safari
            element.webkitRequestFullscreen();
        } else if (element.msRequestFullscreen) {
            // IE
            element.msRequestFullscreen();
        }
    }
    const toolbarButtonOptions = {
        icon: 'fullscreen',
        stylingMode: 'text',
        onClick: requestFullscreen,
    };
    return (
        <div>
            <Popup
                visible={popupVisible}
                onHiding={onHiding}
                onShowing={onShowing}
                dragEnabled={false}
                hideOnOutsideClick={true}
                showCloseButton={true}
                showTitle={true}
                title="Мэдэгдэл үүсгэх"
                width="65%"
                height="90vh"
            >
                <ScrollView width='100%' height='100%'>
                    <LoadPanelComponent
                        position={{of: "#renderCreateMenu"}}
                        visible={isLoading}
                    />
                    <form ref={addFormRef} onSubmit={onSubmit}>
                        <div className="dx-fieldset">
                            <div className="dx-field">
                                <div className="dx-field-label" style={{width: "25%"}}>Байгууллага: <span style={{color: 'red'}}>*</span></div>
                                <div className="dx-field-value" style={{width: "75%"}}>
                                    <DropDownBox
                                        value={treeBoxValue}
                                        opened={isTreeBoxOpened}
                                        valueExpr="id"
                                        displayExpr="org_name"
                                        placeholder="Байгууллага сонгох..."
                                        showClearButton={true}
                                        dataSource={organizations}
                                        onValueChanged={syncTreeViewSelection}
                                        onOptionChanged={onTreeBoxOpened}
                                        contentRender={treeViewRender}
                                    >
                                        <Validator ref={addFormOrgValidatorRef}>
                                            <RequiredRule
                                                message="Байгууллага сонгоно уу!" />
                                        </Validator>
                                    </DropDownBox>
                                </div>
                            </div>
                            <div className="dx-field">
                                <div className="dx-field-label" style={{width: "25%"}}>Мэдэгдлийн гарчиг:</div>
                                <div className="dx-field-value" style={{width: "75%"}}>
                                    <TextBox name="title" placeholder="Мэдэгдлийн гарчиг" ref={addFormTitleValidatorRef}>
                                        {/* <Validator ref={addFormTitleValidatorRef}>
                                            <RequiredRule
                                                message="Мэдэгдлийн гарчиг бөглөнө үү!" />
                                        </Validator> */}
                                    </TextBox>
                                </div>
                            </div>
                            <div className="dx-field" height="100%" width="100%" id="htmlEditorEdit" style={{backgroundColor:"#fff"}}>
                                <HtmlEditor
                                    ref={htmlRef}
                                    height="300px"
                                    name="notif_info"
                                    valueType="html"
                                >
                                    <Toolbar multiline={true}>
                                        <Item name="size" acceptedValues={sizeValues} />
                                        <Item name="separator" />
                                        <Item name="font" acceptedValues={fontValues} options={fontOptions} />
                                        <Item name="separator" />
                                        <Item name="bold" />
                                        <Item name="italic" />
                                        <Item name="strike" />
                                        <Item name="underline" />
                                        <Item name="separator" />
                                        <Item name="alignLeft" />
                                        <Item name="alignCenter" />
                                        <Item name="alignRight" />
                                        <Item name="alignJustify" />
                                        <Item name="separator" />
                                        <Item name="orderedList" />
                                        <Item name="bulletList" />
                                        <Item name="separator" />
                                        <Item name="header" acceptedValues={headerValues} />
                                        <Item name="separator" />
                                        <Item name="color" />
                                        <Item name="background" />
                                        <Item name="separator" />
                                        <Item name="link" />
                                        <Item name="separator" />
                                        <Item name="clear" />
                                        <Item name="codeBlock" />
                                        <Item name="blockquote" />
                                        <Item name="separator" />
                                        <Item name="insertTable" />
                                        <Item name="deleteTable" />
                                        <Item name="insertRowAbove" />
                                        <Item name="insertRowBelow" />
                                        <Item name="deleteRow" />
                                        <Item name="insertColumnLeft" />
                                        <Item name="insertColumnRight" />
                                        <Item name="deleteColumn" />
                                        <Item name="separator" />
                                        <Item
                                            widget={'dxButton'}
                                            options={toolbarButtonOptions}
                                        />
                                    </Toolbar>
                                    <Validator ref={addFormContentValidatorRef}>
                                        <RequiredRule
                                            message="Агуулга бөглөнө үү!" />
                                    </Validator>
                                </HtmlEditor>
                            </div>
                            <div className="dx-field">
                                <div className="dx-field-label" style={{width: "25%"}}>Файлын төрөл: </div>
                                <div className="dx-field-value" style={{width: "75%"}}>
                                    <SelectBox items={fileTypes}
                                               ref={selectRef}
                                               displayExpr="name"
                                               valueExpr="id"
                                               name="file_type">
                                    </SelectBox>
                                </div>
                            </div>
                            <div className="dx-field">
                                <div className="dx-field-label" style={{width: "25%"}}>Файл: </div>
                                <div className="dx-field-value" style={{width: "75%"}}>
                                    <FileUploader name="file" ref={fileRef} selectButtonText="Файл сонгох" labelText="" multiple="true" accept="*" uploadMode="useForm" />
                                </div>
                            </div>
                            <div className="dx-field">
                                <div className="dx-field-label" style={{width: "30%"}}>Мэдэгдэл харуулах хэлбэр: <span style={{color: 'red'}}>*</span></div>
                                <div className="dx-field-value" style={{width: "70%"}}>
                                    <RadioGroup name="visible_type" items={visibleTypes} defaultValue={visibleTypes[0]} layout="horizontal" />
                                </div>
                            </div>
                            <div className="dx-field" style={{ textAlign: "center" }}>
                                <Button
                                    text="Илгээх"
                                    type="success"
                                    useSubmitBehavior={true}
                                >
                                </Button>
                            </div>
                        </div>
                    </form>
                </ScrollView>
            </Popup>
        </div>
    );
}

export default AddNotification;

import React, {useState, useEffect} from "react";
import notify from "devextreme/ui/notify";
import ProposalService from "../../../services/api/proposal";
import _ from "lodash";
import {budgetPalettes, numberWithCommas} from "../../../util";
import Chart, {
    CommonSeriesSettings,
    Crosshair, Format, HorizontalLine,
    Label,
    Legend,
    Series,
    Tooltip,
    ValueAxis
} from "devextreme-react/chart";
import LoadPanelComponent from "../../budget/components/loadPanel";
import EmptyData from "../../budget/components/empty";

const ChartDetailByYear = (props) => {
    const [chartData, setChartData] = useState([]);
    const [loader, setLoader] = useState(false);
    const [sumTotalAsset, setSumTotalAsset] = useState(0);
    const loadData = async () => {
        try {
            setLoader(true);
            let r = await ProposalService.getChartDetailByYear(props.orgId, props.agendaId, props.eventId, props.econCode,props.year);
            //console.log('rrr', r);
           /* let array = _.sortBy(r, (e) => {
                return e.year
            })
            console.log('array', array);*/
            let sum = _.sumBy(r, 'amt');
            setSumTotalAsset(sum);
            setChartData(r);
            setLoader(false);
        } catch (e) {
            setLoader(false);
            notify(e.message, "error", 2000);
        }
    }

    useEffect(() => {
        loadData()
    }, [props.orgId, props.agendaId, props.eventId, props.econCode,props.year,props.colorId])

    const customizeTooltip = (arg) => {
        let regexProposal = /санал/;
        if (arg.seriesName ==='Төсвийн өөрчлөлт') {
            let r = _.find(chartData, {'year': (arg.argument * 1 - 1).toString()});
            let r1 = _.find(chartData, {'year': (arg.argument * 1 ).toString()});
            let q = (arg.value / (r && ((arg.argument ==='ТШЗ санал' ? r.guitsetgel : r.amt)))).toFixed(2);
            let p = ((arg.value * 100 / (r && (arg.argument ==='ТШЗ санал' ? r.guitsetgel : r.amt))) - 100).toFixed(2);
            let d = (arg.value - (r && (arg.argument ==='ТШЗ санал' ? r.guitsetgel : r.amt)));
            let f = d < 0 ? 'бага' : 'их';
            if (isFinite(q) && !isNaN(q)) {
                return {
                    text: `Төсөв ${arg.argumentText} он\nӨмнөх жилээс\n\n Зөрүү: ${numberWithCommas(d / 1000000)}\n Хувь: ${p}%\n Өөрчлөлт: ${q} дахин ${f}`
                }
            } else {
                return {
                    text: `${numberWithCommas(r1 && props.econCode.substring(0,1)!=='4' ? (r1.amt / 1000000) : r1.amt)}`
                }
            }
        }
       else if (arg.seriesName ==='Гүйцэтгэлийн өөрчлөлт') {
            let r = _.find(chartData, {'year': ((regexProposal.test(arg.argument) ? props.year : arg.argument * 1 ) - 1).toString()});
            let r1 = _.find(chartData, {'year': ((regexProposal.test(arg.argument) ? props.year : arg.argument * 1 )).toString()});
            let q = (arg.value / (r && r.guitsetgel)).toFixed(2);
            let p = ((arg.value * 100 / (r && r.guitsetgel)) - 100).toFixed(2);
            let d = (arg.value - (r && r.guitsetgel));
            let f = d < 0 ? 'бага' : 'их';
            if (isFinite(q) && !isNaN(q)) {
                return {
                    text: `${regexProposal.test(arg.argument) ? '' : 'Гүйцэтгэл'} ${arg.argumentText} ${regexProposal.test(arg.argument) ? '' : 'он'}\n${regexProposal.test(arg.argument) ? 'ХБГ-ээс' : 'Өмнөх жилээс'}\n\n Зөрүү: ${numberWithCommas(d / 1000000)}\n Хувь: ${p}%\n Өөрчлөлт: ${q} дахин ${f}`
                }
            } else {
                return {
                    text: `${numberWithCommas(r1 && props.econCode.substring(0,1)!=='4' ? (r1.amt / 1000000) : r1.amt)}`
                }
            }
        }
        else {
            return {

                text: `${numberWithCommas(props.econCode.substring(0,1)!=='4' ? (arg.value / 1000000) : arg.value)}`
            //orooch
            };
        }
    }

    const customizeValueAxisLabel = (e) => {
        return ` ${ props.econCode.substring(0,1)!=='4' ? (e.value / 1000000) : e.value}`
    }
    function customizePoint(arg) {
        if (arg.seriesName === 'Гүйцэтгэл' && arg.argument === props.year - 1) {
            return { color: '#AAC8FF', hoverStyle: { color: '#51578E' } };
        }
        else if (arg.seriesName === 'Гүйцэтгэл' && arg.argument === 'ТШЗ санал') {
            return { color: '#CDEBFF', hoverStyle: { color: '#ff7c7c' } };
        }
        else if (arg.seriesName === 'Гүйцэтгэл' && arg.argument === 'ТТЗ санал') {
            return { color: '#99ACDA', hoverStyle: { color: '#ff7c7c' } };
        }
        else if (arg.seriesName === 'Гүйцэтгэл' && arg.argument === 'ТЕЗ санал') {
            return { color: '#5575C2', hoverStyle: { color: '#ff7c7c' } };
        }
    }
    const customizeLabelText = (e) => {
        return  (e.seriesName === 'Гүйцэтгэл' || (e.seriesName === 'Гүйцэтгэл' &&( e.argument === 'ТЕЗ санал' || e.argument === 'ТТЗ санал' || e.argument === 'ТШЗ санал')))  &&  `${ props.econCode.substring(0,1)!=='4' ? numberWithCommas(e.value / 1000000) : numberWithCommas(e.value)}`
    }
    return (
        <div id="load11">
            <LoadPanelComponent position={{of: '#load11'}} visible={loader}/>
            {chartData.length > 0 ? (
                    <Chart
                        palette={_.find(budgetPalettes, { 'id': props.colorId === undefined ? 1 : props.colorId }).color}
                        paletteExtensionMode="alternate"
                        dataSource={chartData}
                        customizePoint = {customizePoint}
                    >
                        <CommonSeriesSettings
                            argumentField="year"
                            type="bar"
                        >
                            <Label customizeText={customizeLabelText} precision={0} visible={true} font={{ family: "'Montserrat', sans-serif" }}>
                                <Format type="fixedPoint" precision={2} />
                            </Label>
                        </CommonSeriesSettings>
                        <Crosshair enabled={true}>
                            <HorizontalLine visible={false}/>
                            <Label visible={true}/>
                        </Crosshair>
                        <ValueAxis>
                            <Label customizeText={customizeValueAxisLabel}/>
                        </ValueAxis>
                     {/*   <Series valueField="amt" name={props.econCode} visible={sumTotalAsset !== 0 ? true : false}/>
                        <Series valueField="amt" name={props.econCode + ' өсөлт'} color="red" type="spline" dashStyle="dash"
                                visible={sumTotalAsset !== 0 ? true : false}/>*/}
                        <Series valueField="amt" name="Төсөв"/>
                        <Series valueField="guitsetgel" name="Гүйцэтгэл"/>
                        <Series valueField="amt" name="Төсвийн өөрчлөлт" color="red" type="spline" dashStyle="dash"/>
                        <Series valueField="guitsetgel" name="Гүйцэтгэлийн өөрчлөлт" color="#264B96" type="spline" dashStyle="dash"/>
                        <Legend visible={true} verticalAlignment="bottom" horizontalAlignment="center" font={{ family: "'Montserrat', sans-serif" }}/>
                        <Tooltip zIndex={100000} enabled={true} customizeTooltip={customizeTooltip} font={{ family: "'Montserrat', sans-serif" }} location="edge">
                        </Tooltip>
                    </Chart>
                ) :
                <div style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    family: "'Montserrat', sans-serif"
                }}>
                    <EmptyData/>
                </div>
            }
        </div>
    )
}

export default ChartDetailByYear;

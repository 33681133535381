import React, {useEffect, useState, useRef, useContext} from "react";
import DropDownBox from "devextreme-react/drop-down-box";
import TreeView from "devextreme-react/tree-view";
import OrganizationService from "../services/api/organization";
import notify from "devextreme/ui/notify";
import { UserContext } from "../hooks/UserContext";
import UserService from "../services/api/user";
import orgPositionServices from "../services/api/orgPosition";

const OrganizationEditCellComponent = (props) => {
    const treeViewRef = useRef(null);
    const [treeData, setTreeData] = useState(props.data.value);
    const [orgList, setOrgList] = useState([]);
    const {user} = useContext(UserContext)

    useEffect(() => {
        getOrgList()
    }, [])

    const getOrgList = async () => {
        const r = await orgPositionServices.getOrganizationList();
        let result = r.data || [];
        if (result.length === 1) {
            result[0].parent = null;
        } else {
            let obt_id = user && user.organization && user.organization.obt_id;
            if (obt_id === 2) {
                result.filter(data => data.code === '')
                    .forEach(data => data.parent = null)
            }
        }
        setOrgList(result)
    }

    const getOrgId = async (e) =>{
        try{
            const result = await OrganizationService.getOrgUserAccessCount(e)
            const result1 = await UserService.checkOrgUserCount(e)
            const count = parseInt(result1.data[0].count)

            if( count >= result.data[0].user_access_count){
                notify('Тухайн байгууллага дээр хэрэглэгчийн эрх шинээр үүсгэх боломжгүй', "warning", 5000)
            }
        } catch(err){
            console.log(err);
        }
    }
    const treeItem = (item) => {
        return <div>
            <span>{item.organization_name}</span>
            <br/>
            <span style={{color: 'darkgray', fontSize: 12}}>{item.aimag_name}</span>
        </div>
    }

    const treeViewRender = () => {
        return (
            <TreeView
                dataSource={user.roles[0].role_id === 0 ? props.data.column.lookup.dataSource : orgList}
                ref={treeViewRef}
                dataStructure="plain"
                keyExpr="id"
                parentIdExpr="parent"
                selectionMode="single"
                showCheckBoxesMode="normal"
                selectNodesRecursive={false}
                searchEnabled={true}
                displayExpr={item => (item.organization_name)}
                itemRender={treeItem}
                selectByClick={true}
                onContentReady={syncTreeViewSelection}
                onItemSelectionChanged={treeViewItemSelectionChanged}
                noDataText={"Дата байхгүй байна."}
            />
        )
    }

    const treeViewItemSelectionChanged = (e) => {
        setTreeData(e.component.getSelectedNodeKeys());
        props.data.setValue(e.component.getSelectedNodeKeys()[0]);
    }

    const syncTreeViewSelection = (e) => {
        const treeView = (e.component.selectItem && e.component) || (treeViewRef && treeViewRef?.current?.instance);

        if (treeView) {
            if (e.value === null) {
                treeView.unselectAll();
            } else {
                const values =e.value || treeData;
                treeView.selectItem(values)
                // const values = e.value || [treeData];
                // values && values.forEach((value) => {
                //     treeView.selectItem(value)
                // })
            }
        }

        if (e.value !== undefined) {
            if (e.value.length > 0) {
                props.data.setValue(e.value[0]);
                getOrgId(e.value[0])
                setTreeData(e.value)
            }
        }
    }

    return (
        <DropDownBox
            value={treeData}
            valueExpr={'id'}
            displayExpr={(item) => {
                return item && `${item.organization_name}`
            }}
            placeholder="сонгох"
            showClearButton={true}
            dataSource={user.roles[0].role_id === 0 ? props.data.column.lookup.dataSource : orgList}
            onValueChanged={syncTreeViewSelection}
            contentRender={treeViewRender}
        />
    )
}

export default OrganizationEditCellComponent

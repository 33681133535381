import generalIcon1 from "../../assets/imgs/vision.png";
import generalIcon2 from "../../assets/imgs/goal.png";
import generalIcon3 from "../../assets/imgs/purpose.png";
import generalIcon4 from "../../assets/imgs/duty.png";
import generalIcon5 from "../../assets/imgs/duty1.png";
import generalIcon6 from "../../assets/imgs/duty2.png";
import generalIcon7 from "../../assets/imgs/operation.png";
import generalIcon8 from "../../assets/imgs/operation1.png";

import greeting from "../../assets/imgs/greeting.png";
import tangarag from "../../assets/imgs/tangarag.png";
import uria from "../../assets/imgs/uria.png";
import unetzuil from "../../assets/imgs/unetzuil.png";
import sigil from "../../assets/imgs/sigil.png";
import tsol from "../../assets/imgs/tsol.png";
import tug from "../../assets/imgs/tug.png";
import symbol from "../../assets/imgs/symbol.png";
import uniform from "../../assets/imgs/uniform.png";

export const customItem = (data) => {
        return (
            <div>
                {data.attribute_details.length != 0 &&
                    <div>
                        {data.attribute_details.map((item, index) => (
                            item.is_single_field ?
                            <div key={index} className="card" style={{ boxShadow: '2px 2px 7px -1px rgba(0,0,0,0.25)' }}>
                                <div className="card-header" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    <span style={{ textTransform: 'uppercase', fontSize: 'large', fontWeight: 'bold' }}>{index + 1 + '.  '} </span>
                                    <div dangerouslySetInnerHTML={{ __html: item.policy_desc }} style={{ //textAlign: 'justify',
                                        textTransform: 'uppercase', fontSize: 'large', fontWeight: 'bold', width: "100%" }}></div>
                                </div>
                                <div className="card-body" style={{width: "100%"}}>
                                    {item.description && <p><span
                                        style={{fontWeight: 'bold'}}>Хэрэгжүүлэх үйл ажиллагаа: </span><br/>
                                        <div dangerouslySetInnerHTML={{ __html: item.description }} style={{ textAlign: 'justify', width: "100%" }}></div>
                                    </p>}
                                    {item.agency && <p><span
                                        style={{fontWeight: 'bold'}}>Яамны хэрэгжүүлэх газар хэлтэс: </span><br/>
                                        <div dangerouslySetInnerHTML={{ __html: item.agency }} style={{ textAlign: 'justify', width: "100%" }}></div>
                                    </p>}
                                    {item.branch && <p><span style={{fontWeight: 'bold'}}>Яамны харьяа хэрэгжүүлэх агентлаг бусад байгууллага: </span><br/>
                                        <div style={{ textAlign: 'justify', width: "100%" }} dangerouslySetInnerHTML={{ __html: item.branch }}></div>
                                    </p>}
                                    {item.event_desc && <p><span style={{fontWeight: 'bold'}}>Төсөл хөтөлбөр: </span><br/><div style={{ textAlign: 'justify', width: "100%" }}
                                                                                                                               dangerouslySetInnerHTML={{__html: item.event_desc}}>
                                    </div>
                                    </p>}
                                </div>
                            </div>
                                :
                                <div key={index} className="card" style={{ boxShadow: '2px 2px 7px -1px rgba(0,0,0,0.25)' }}>
                                    <div className="card-body" >
                                        {/*<span style={{ textTransform: 'uppercase', fontSize: 'large', fontWeight: 'bold' }}>{index + 1 + '. '}</span>*/}
                                        <div dangerouslySetInnerHTML={{ __html: item.policy_desc }} style={{ //textAlign: 'justify',
                                            width: "100%", padding: ".25rem .6rem" }}></div>
                                    </div>
                                </div>
                        ))}
                    </div>
                }
            </div>
        )
}
const iconMap = {
    vision: generalIcon1,
    purpose: generalIcon3,
    purpose1: generalIcon3,
    purpose2: generalIcon3,
    duty: generalIcon4,
    goal: generalIcon2,
    goal1: generalIcon2,
    main_goal: generalIcon2,
    minister_goal: generalIcon2,
    strategy: generalIcon4,
    strategy_priorities: generalIcon4,
    ministry_priorities: generalIcon6,
    priorities: generalIcon6,
    priorities1: generalIcon6,
    operation: generalIcon7,
    operation1: generalIcon8,
    operation2: generalIcon8,
    org_operation: generalIcon8,
    duty1: generalIcon5,
    duty2: generalIcon6,
    event: generalIcon5,
    strategy_goal: generalIcon2,
    strategy_priorities1: generalIcon8,
    greeting: greeting,
    swear: tangarag,
    uria: uria,
    unetzuil: unetzuil,
    sigil: sigil,
    tsol: tsol,
    tug: tug,
    symbol: symbol,
    uniform: uniform,
  };
  
  export const titleIcon = (name) => {
    if (name in iconMap) {
      return (
        <img
          loading="lazy"
          src={iconMap[name]}
          style={{ width: 'auto', height: '35px', paddingRight: 10 }}
        />
      );
    }
    return null;
  };
import request from '../../shared/lib/request'

function getRequests(year) {
    return request({
        url: '/schedule/getRequests/'+year,
        method: 'GET'
    })
}
function getRequestsChartData(year) {
    return request({
        url: '/schedule/getRequestsChart/'+year,
        method: 'GET'
    })
}
function approvedRequestsGroupByMonth(year) {
    return request({
        url: '/schedule/approvedRequestsGroupByMonth/'+year,
        method: 'GET'
    })
}

function getRequestHistory(year, status) {
    return request({
        url: '/schedule/getRequests/history/'+year+'/'+status,
        method: 'GET'
    })
}
function getFileNameById(id) {
    return request({
        url: `/resources/file/records/${id}/show`,
        method: 'GET'
    })
}
function getScheduleBudget(org_id, year,econ_category_code_1) {
    return request({
        url: `/schedule/getScheduleBudget/${org_id}/${year}?econ_category_code_1=${econ_category_code_1}`,
        method: 'GET'
    })
}

function getScheduleBudgetNew(org_id,  year, request_id){
    return request({
        url: `/schedule/getScheduleBudgetNew/${org_id}/${year}/${request_id}`,
        method: 'GET'
    })
}

function getRequestDetail(id) {
    return request({
        url: '/schedule/getRequestDetail/'+id,
        method: 'GET'
    })
}

function getMergeRequest(id) {
    return request({
        url: '/schedule/getMergeRequest',
        method: 'GET'
    })
}
const addRequests = (body) => {
    return request({
        url: '/schedule/addRequests',
        method: 'POST',
        data: body,
    })
}

const setIsApprove = (id, body) => {
    return request({
        url: '/schedule/setApprove/'+id,
        method: 'POST',
        data: body,
    })
}

const doChangesOnBudged = (id) => {
    return request({
        url: '/schedule/editBudget/'+id,
        method: 'GET',
    })
}
const createRequest = (body) => {
    return request({
        url: '/schedule/createRequest',
        method: 'GET',
    })
}

const getRequestData = (id) => {
    return request({
        url: `/schedule/getRequestData/${id}`,
        method: 'GET',
    })
}
const getRequestDetail2 = (id) => {
    return request({
        url: `/schedule/getRequestDetail/${id}`,
        method: 'GET',
    })
}

const addRequestDetail = (body) => {
    return request({
      url: `/schedule/addRequestDetail`,
      method: "POST",
      data : body
    })
}
const checkParentReq = () => {
    return request({
        url: `/schedule/checkParentReq`,
        method:"GET",
    })
}

const requestList = (currentOrg,startDate,endDate,parentId,isNew) => {
    return request({
        url: `/schedule/requestList?currentOrg=${currentOrg}&startDate=${startDate}&endDate=${endDate}&parentId=${parentId}&isNew=${isNew}`,
        method:"GET",
    })
}
const requestListNegtgesen = (open,requestId) => {
    return request({
        url: `/schedule/requestListNegtgesen?open=${open}&requestId=${requestId}`,
        method:"GET",
    })
}
const getFilteredScheduleParamNegtgel = (paramType,year,month,status,filterOrg,agenda) =>{
    return request({
        url: `/schedule/getFilteredScheduleParamNegtgel/${paramType}?year=${year}&month=${month}&status=${status}&filterOrg=${filterOrg}&agenda=${agenda}`,
        method: 'GET'
    })
}

const getFilteredScheduleNegtgel = (year,month,status,mergeType) =>{
    return request({
        url: `/schedule/getFilteredScheduleNegtgel/${year}/${month}/${status}/${mergeType}`,
        method: 'GET'
    })
}

const removeDetail = (body) => {
    return request({
        url:`/schedule/removeDetail`,
        method: 'POST',
        data: body
    })
}

const changeStatus = (body) => {
    return request({
        url: `/schedule/changeStatus`,
        method: 'POST',
        data: body
    })
}

const getTEZscheduleReportParam = (type,obid) => {
    return request({
        url: `/schedule/getTEZscheduleReportParam/${type}?obid=${obid}`,
        method: 'GET',
    })
}

const getTEZscheduleReport = (obid,status,isChange) => {
    return request({
        url: `/schedule/getTEZscheduleReport/${obid}/${status}/${isChange}`,
        method: 'GET',
    })
}

const setRequestNote = (id, body) => {
    return request(({
        url: `/schedule/setRequestNote/${id}`,
        method: 'PUT',
        data: body
    }))
}

const getRequestLog = (requestId) => {
    return request({
        url: `/schedule/getRequestLog/${requestId}`,
        method: 'GET',
    })
}


const ScheduleService = {
    createRequest,
    getRequests,
    getRequestData,
    approvedRequestsGroupByMonth,
    getRequestsChartData,
    getRequestHistory,
    getScheduleBudget,
    getScheduleBudgetNew,
    getFileNameById,
    addRequests,
    addRequestDetail,
    getRequestDetail,
    getRequestDetail2,
    getMergeRequest,
    setIsApprove,
    doChangesOnBudged,
    checkParentReq,
    requestList,
    getFilteredScheduleParamNegtgel,
    getFilteredScheduleNegtgel,
    removeDetail,
    changeStatus,
    getRequestLog,
    requestListNegtgesen,
    setRequestNote,
    getTEZscheduleReport,
    getTEZscheduleReportParam,
}

export default ScheduleService;

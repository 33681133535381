import notify from 'devextreme/ui/notify'
import React, { useEffect, useState } from 'react'
import BudgetService from '../../../../services/api/budget'
import { Input, Pagination, Progress, Tooltip } from 'antd'
import { numberWithCommas } from '../../../../util'
import _ from 'lodash'
import info1 from '../../../../assets/imgs/icon/iconInfo1.png'
import moment from 'moment'
import { BackButton } from '../../../../components/Buttons'
import { Popup, ScrollView } from 'devextreme-react'
import { IoIosCheckmarkCircle, IoIosCloseCircle } from 'react-icons/io'
import { FiCircle } from 'react-icons/fi'
import { TbListDetails } from 'react-icons/tb'
import EmptyData from '../../../budget/components/empty'

export const Sanhuujilt = ({ budgetYear, investYear }) => {
    const [fundingList, setFundingList] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [selectedFunding, setSelectedFunding] = useState(null)
    const [fundingReqList, setFundingReqList] = useState([])
    const [fundingLog, setFundingLog] = useState([])
    const [popUpVisible, setPopUpVisible] = useState(false)
    const [searchValue, setSearchValue] = useState('')

    useEffect(() => {
        loadInvestFundingData();
    }, [currentPage, searchValue])

    const loadInvestFundingData = async () => {
        try {
            let res = await BudgetService.pimisInvestFundingList(currentPage, searchValue);
            setFundingList(res)
        } catch (error) {
            notify(error.message, "error", 2000)
        }
    }

    const loadDetailFunding = async (props) => {
        try {
            let res = await BudgetService.pimisFunding(props);
            setSelectedFunding(res)
            loadListByContractCode(res?.contractCode);
        } catch (error) {
            notify(error.message, "error", 2000)
        }
    }

    const loadListByContractCode = async (props) => {
        try {
            let res = await BudgetService.pimisFundingListDetail(encodeURIComponent(props));
            setFundingReqList(_.orderBy(res.data, ['logDtm', 'desc']))
        } catch (error) {
            notify(error.message, "error", 2000)
        }
    }

    const loadFundingLog = async (props) => {
        try {
            let res = await BudgetService.pmisFundingLog(props)
            setFundingLog(res)
            res.length > 0 && setPopUpVisible(true)
        } catch (error) {
            notify(error.message, 'error', 2000)
        }
    }

    const getProgressColor = (statusNm) => {
        switch (statusNm) {
            case 'sent':
            case 'saved':
                return { backgroundColor: 'rgba(24, 144, 255, 0.05)', strokeColor: '#1890ff' };
            case 'rejected':
                return { backgroundColor: 'rgba(255, 77, 79, 0.05)', strokeColor: '#ff4d4f' };
            case 'approved':
                return { backgroundColor: 'rgba(82, 196, 26, 0.05)', strokeColor: '#52c41a' };
            default:
                return { backgroundColor: '#fff', strokeColor: '#efefef' };
        }
    };

    const renderCard = (label, value, measNm) => (
        <div className="master-detail-card card" >
            <div style={{ fontSize: 14 }}>
                <span>{label}:</span><br />
                <b>{value ? value : `-`} {measNm && measNm.toLowerCase()}</b>
            </div>
        </div>
    );

    const renderStatusIcon = (status) => {
        switch (status) {
            case 'sent':
                return <IoIosCheckmarkCircle size={28} color='#52c41a' style={{ background: 'white' }} />;
            case 'saved':
                return <IoIosCheckmarkCircle size={28} color='#e5e7eb' style={{ background: 'white' }} />;
            case 'rejected':
                return <IoIosCloseCircle size={28} color='#ed1c24' style={{ background: 'white' }} />;
            case 'approved':
                return <IoIosCheckmarkCircle size={28} color='#52c41a' style={{ background: 'white' }} />;
            default:
                return <FiCircle size={28} color='#e5e7eb' style={{ background: 'white' }} />;
        }
    };

    const highlightText = (text, searchValue) => {
        const searchTerm = searchValue.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
        const regex = new RegExp(`(${searchTerm})`, 'ig');
        
        return text.split(regex).map((part, index) => (
            regex.test(part.toLowerCase()) ? (
                <span key={index} style={{ backgroundColor: 'yellow' }}>
                    {part}
                </span>
            ) : (
                part
            )
        ));
    };

    return (
        <div>
            {!selectedFunding ?
                <div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'start', padding: '10px 0 10px 5px', width: '100%' }}>
                        <div style={{ display: 'flex', width: '60%' }}>
                            <Input placeholder='Төслийн нэр, хуулийн дугаар, төслийн дугаар, гүйцэтгэгчээр хайх...' allowClear size='large'
                                onChange={(e) => setSearchValue(e.target.value)} style={{ borderRadius: '10px 0 0 10px' }} />
                            <button style={{ color: 'white', background: '#003696', width: '6rem', borderRadius: '0 10px 10px 0', border: 'none' }}>Хайх</button>
                        </div>
                        {fundingList?.data?.length > 0 && fundingList?.updatedAt &&
                            <div style={{ display: 'flex', justifyContent: 'end' }}>
                                <Tooltip title="Шинэчлэгдсэн огноо" placement='bottom'>
                                    <div style={{ cursor: 'default', backgroundColor: '#ebf1ff', width: '6rem', borderRadius: '2rem', position: 'relative', padding: 5 }}>
                                        <div style={{ fontSize: '.6rem', fontWeight: 'bold', paddingLeft: 5 }}>{moment(fundingList.updatedAt, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD')}</div>
                                        <img src={info1} alt='' width={32} height={32} style={{ position: 'absolute', right: -5, top: -5 }} />
                                    </div>
                                </Tooltip>
                            </div>
                        }
                    </div>
                    {fundingList?.data?.length > 0 ?
                        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', columnGap: 20, rowGap: 10 }}>
                            {
                                fundingList?.data?.map((e, index) => {
                                    const { strokeColor } = getProgressColor(e.statusNm);
                                    return (
                                        <div className='card' key={index} style={{ display: 'flex', width: '100%', borderColor: e.statusNm === 'rejected' && '#f5a2a4' }} onClick={() => loadDetailFunding(e.id)}>
                                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 20, borderRight: '1px solid #ededed', width: '25%', justifyContent: 'space-around', borderRadius: '15px 0 0 15px' }}>
                                                <b style={{ color: '#003696', fontWeight: 500 }}>Гүйцэтгэл</b>
                                                <Progress type='circle' strokeWidth={10} percent={e.progress} width={60} strokeColor={strokeColor} />
                                                <b>{searchValue === '' ? e.lawNo : highlightText(e.lawNo, searchValue)}</b>
                                            </div>
                                            <div style={{ padding: 20, width: '75%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                                <p><b>{searchValue === '' ? e.pipNm : highlightText(e.pipNm, searchValue)}</b></p>
                                                <div>
                                                    <div>Төслийн дугаар:<span style={{ float: 'right', fontWeight: 500 }}>{searchValue === '' ? e.utCode : highlightText(e.utCode, searchValue)}</span></div>
                                                    <div>Гүйцэтгэгч:<span style={{ float: 'right', fontWeight: 500 }}>{searchValue === '' ? e.supNm : highlightText(e.supNm, searchValue)}</span></div>
                                                    <div>Төлөв:<span style={{ float: 'right', fontWeight: 500, color: e.statusNm === 'rejected' && '#f66160' }}>{e.stepNm} {e.statusNm === 'rejected' && '/цуцалсан/'}</span></div>
                                                    <div>Дүн:<span style={{ float: 'right', fontWeight: 500 }}>{numberWithCommas(e.amount)}</span></div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        :
                        <EmptyData />
                    }
                    <Pagination
                        current={currentPage}
                        total={fundingList?.totalRows}
                        hideOnSinglePage={true}
                        showSizeChanger={false}
                        onChange={(e) => setCurrentPage(e)}
                        pageSize={30}
                        style={{ textAlign: 'end', padding: '20px 0' }}
                        showTotal={total => `Нийт ${total} санхүүжилтийн мэдээлэл харуулж байна`}
                    />
                </div>
                :
                <div>
                    <div style={{ margin: 5, display: 'flex', alignItems: 'baseline', gap: '1rem' }}>
                        <BackButton onClick={() => setSelectedFunding(null)} />
                        <div style={{ fontSize: 14, color: '#003696', textTransform: 'uppercase' }}><b>{selectedFunding.contractNm}</b></div>
                    </div>
                    <div style={{ marginTop: 50 }}>
                        <div style={{ fontSize: 14, color: '#003696', margin: 10 }}><b>ЕРӨНХИЙ МЭДЭЭЛЭЛ</b></div>
                        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(6, 1fr)', gap: 10, margin: '10px -10px 0px 0px' }}>
                            {renderCard("Хуулийн дугаар", selectedFunding.lawNo)}
                            {renderCard("Аймаг/Нийслэл", selectedFunding.amgNm)}
                            {renderCard("Сум/Дүүрэг", selectedFunding.sumNm)}
                            {renderCard("ТЕЗ", selectedFunding.tezNm)}
                            {renderCard("Эдийн засгийн ангилал", selectedFunding.ezNm)}
                            {renderCard("Хүчин чадал", selectedFunding.capacity, selectedFunding.measNm)}
                            {renderCard("Гэрээний дүн", numberWithCommas(selectedFunding.totalAmt / 1000000), ' сая ₮')}
                            {renderCard("Эхлэх огноо", selectedFunding.planYrStr.split(',')[0])}
                            {renderCard("Дуусах огноо", selectedFunding.planYrStr.split(',').slice(-1))}
                            {renderCard("Хянагч байгууллага", selectedFunding.superviserNm)}
                            {renderCard("Эрх шилжүүлсэн байгууллага", selectedFunding.consumerNm)}
                        </div>
                    </div>
                    <div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', margin: 10 }}>
                            <div style={{ fontSize: 14, color: '#003696' }}><b>САНХҮҮЖИЛТЫН ХҮСЭЛТҮҮД</b></div>
                            {fundingReqList.length > 0 &&
                                <div style={{ display: 'flex', justifyContent: 'end' }}>
                                    <Tooltip title="Шинэчлэгдсэн огноо" placement='bottomRight'>
                                        <div style={{ cursor: 'default', backgroundColor: '#ebf1ff', width: '6rem', borderRadius: '2rem', position: 'relative', padding: 5 }}>
                                            <div style={{ fontSize: '.6rem', fontWeight: 'bold', paddingLeft: 5 }}>{moment(_.maxBy(fundingReqList, "updatedAt").updatedAt, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD')}</div>
                                            <img src={info1} alt='' width={32} height={32} style={{ position: 'absolute', right: -5, top: -5 }} />
                                        </div>
                                    </Tooltip>
                                </div>
                            }
                        </div>
                        {fundingReqList.length > 0
                            ?
                            <div style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: 10, marginRight: -10 }}>
                                {fundingReqList.map((e, index) => {
                                    return (
                                        <div className="card" style={{ fontSize: 14, borderColor: e.status === 'rejected' && '#f5a2a4' }} key={index}>
                                            <div style={{ borderBottom: '1px solid #ddd', borderRadius: '15px 15px 0 0', padding: '1rem', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <div style={{ fontWeight: 'bold' }}>{e.typeNm}</div>
                                                <Tooltip title='Явцын дэлгэрэнгүй харах'>
                                                    <div className="log_detail_btn" onClick={() => loadFundingLog(e.id)}><TbListDetails fontSize={16} /></div>
                                                </Tooltip>
                                            </div>
                                            <div style={{ padding: '1rem', display: 'flex', flexDirection: 'column', gap: 10 }}>
                                                <div>Төсвийн жил:<b style={{ float: 'right' }}>{e.planYr ? e.planYr + ' он' : '-'}</b></div>
                                                <div>Төслийн дугаар:<b style={{ float: 'right' }}>{e.utCode ? e.utCode : '-'}</b></div>
                                                <div>Төлөв:<b style={{ float: 'right', color: e.status === 'rejected' && '#f66160' }}>{e.stepNm ? e.stepNm : '-'}{e.status === 'rejected' && ` /цуцалсан/`}</b></div>
                                                <hr style={{background: '#dedede', border: 'none', height: '1px'}}/>
                                                <div>Компанийн нэр:<b style={{ float: 'right' }}>{e.supNm ? e.supNm : '-'}</b></div>
                                                <div>Хүсэлт гаргасан огноо: <b style={{ float: 'right' }}>{e.logDtm}</b></div>
                                                <div>Хүссэн дүн: <b style={{ float: 'right' }}>{e.reqAmt ? numberWithCommas(e.reqAmt / 1000000) + ' сая ₮' : '-'}</b></div>
                                                <div>Олгосон дүн: <b style={{ float: 'right' }}>{e.amount && e.status !== 'rejected' ? numberWithCommas(e.amount / 1000000) + ' сая ₮' : '-'}</b></div>
                                                <div>Ажлын гүйцэтгэл: <b style={{ float: 'right' }}>{e.perfProg ? e.perfProg + '%' : '-'}</b></div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                            :
                            <p style={{padding: 10}}>Мэдээлэл байхгүй</p>
                        }
                    </div>
                </div>
            }
            {
                <Popup
                    visible={popUpVisible}
                    dragEnabled={false}
                    showCloseButton={true}
                    hideOnOutsideClick={true}
                    onHiding={() => setPopUpVisible(false)}
                    shading={true}
                    width={500}
                    maxHeight={600}
                    minHeight={200}
                >
                    <ScrollView>
                        <div style={{ fontSize: 16, marginBottom: 20 }}><b>Санхүүжилтын явц</b></div>
                        <div style={{ display: 'flex', flexDirection: 'column', gap: 40, margin: 20, position: 'relative' }}>
                            <div style={{ borderLeft: '3px solid #ddd', height: '100%', position: 'absolute', left: '8%' }}></div>
                            {fundingLog.map((e, index) => (
                                <div key={index} style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                                    <div style={{ zIndex: 10 }}>{e.status ? renderStatusIcon(e.status) : '-'}</div>
                                    <div className="col-10">
                                        <b style={{ marginBottom: 10 }}>{e.name ? e.name : '-'}</b>
                                        <div>Огноо:<span style={{ float: 'right' }}>{e.date ? e.date : '-'}</span></div>
                                        <div>Гүйцэтгэсэн:<span style={{ float: 'right' }}>{e.imp ? e.imp : '-'}</span></div>
                                        <div>Хугацаа:<span style={{ float: 'right' }}>{e.parsedDate ? e.parsedDate : '-'}</span></div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        {selectedFunding?.supReg && <a href={`http://opendata.burtgel.gov.mn/lesinfo/${selectedFunding.supReg}`} target="_blank" rel="noreferrer">
                            Гүйцэтгэгчийн мэдээлэл харах
                        </a>}
                    </ScrollView>
                </Popup>
            }
        </div>
    )
}


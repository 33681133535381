import React, { useEffect, useState } from "react";
import OrganzationService from "../../../services/api/organization";
import "devextreme-react/text-area";
import govLogo from "../../../assets/imgs/govLogo.png";

function ProfileSection(props) {
  const [data, setData] = useState([]);
  const [orgBudget, setOrgBudget] = useState([]);
  const [isLoading, setIsloading] = useState(true);
  const [numberz, setNumberz] = useState();
  const [mailz, setMailz] = useState();
  const [orgAttr, setOrgAttr] = useState([]);
  const [orgDetail, setOrgDetail] = useState([]);
  useEffect(() => {
    // const staticData = {
    //     "generalInfo": [],
    //     "contactInfo": [{name: 'phone', value: '99119911'}],
    //     "bgImage": [],
    // }
    getOnOrgChosen();
    OrganzationService.orgDetailAttr()
      .then((data) => {
        // console.log('SET DATA ?', data)
        setData(data.data || []);
      })
      .catch((err) => {
        console.error("====================>", err);
      })
      .finally(() => {
        setIsloading(false);
      });
    getInfo();
  }, []);
  const getInfo = () => {
    setNumberz(
      `tel:+976${
        props.data &&
        props.data.filter((el) => el.name === "telephone").map((el) => el.value)
      }`
    );
    setMailz(
      `mailto:${
        props.data &&
        props.data.filter((el) => el.name === "email").map((el) => el.value)
      }`
    );
  };

  const getOnOrgChosen = async (org_id) => {
    try {
      let res;
      let id;
      res = await OrganzationService.orgIdTake(org_id);
      setOrgBudget(org_id);
      let data = res.data || { attributes: [] };
      // console.log("TEMP SHUU ------------------->", res);
      setOrgDetail(data);
      let tempAttributes = [];
      if (data.attributes) {
        for (let i = 0; i < data.attributes.length; i++) {
          let attr = data.attributes;
          if (
            attr[i].name !== "operation_type" &&
            attr[i].name !== "reg_number" &&
            attr[i].name !== "website" &&
            attr[i].name !== "email" &&
            attr[i].name !== "address" &&
            attr[i].name !== "telephone" &&
            attr[i].name !== "logo" &&
            attr[i].name !== "org_type"
          ) {
            tempAttributes.push(attr[i]);
          }
        }
        setOrgAttr(tempAttributes);
        // console.log("TEMP SHUU ------------------->", tempAttributes);
      }
    } catch (e) {
      console.error(e);
    }
  };
  return (
    <div>
      {/*<LoadPanel*/}
      {/*    position={position}*/}
      {/*    visible={isLoading}*/}
      {/*    shading={true}*/}
      {/*    message = 'Түр хүлээнэ үү...'*/}
      {/*    shadingColor="rgba(0,0,0, 0.2)"*/}
      {/*/>*/}
      <div id="page">
        <div className="" style={{}}>
          <div style={{}}>
            <div
              className="row radial-background"
              style={{
                display: "flex",
                alignItems: "center",
                height: "100px",
                backgroundColor: "#73a2d9",
                marginTop: 0,
              }}
            >
              <div
                className="col-4"
                style={{ display: "flex", alignItems: "center" }}
              >
                <img
                  alt="image"
                  style={{
                    width: 65,
                    height: 65,
                    marginLeft: 26,
                    borderRadius: 2,
                  }}
                  src={govLogo}
                />
              </div>
              <div
                className="col-4"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <span
                  style={{
                    fontSize: 28,
                    color: "#062553",
                    display: "flex",
                    alignSelf: "center",
                  }}
                >
                  OPEN<span style={{ fontWeight: "bold" }}>GOV</span>
                </span>
              </div>
              {/*<div className="col-4" style={{display: "flex", justifyContent: "flex-end", paddingRight: 126  }}>*/}
              {/*    <Tooltip*/}
              {/*        target="#number"*/}
              {/*        position="left"*/}
              {/*        // animation={animationConfig}*/}
              {/*        visible={withAnimationVisible}*/}
              {/*           hideOnOutsideClick={true}*/}
              {/*    >*/}
              {/*        <div>{props.data && props.data.filter(el => el.name ==="telephone").map(el => el.value)}</div>*/}
              {/*    </Tooltip>*/}
              {/*    <a href={numberz} id="number"*/}
              {/*       onMouseEnter={toggleWithAnimation}*/}
              {/*       onMouseLeave={toggleWithAnimation}*/}
              {/*    >*/}
              {/*        <div className="ab-button">*/}
              {/*            <img className="ab-icon" style={{height: 10.93, width: 10.93}} src={PhoneIcon}/>*/}
              {/*        </div>*/}
              {/*    </a>*/}
              {/*    <a href={props.data && props.data.filter(el => el.name ==="website").map(el => el.value)} target="_blank">*/}
              {/*        <div className="ab-button">*/}
              {/*            <img className="ab-icon" style={{height: 12.89, width: 12.89}} src={WebIcon}/>*/}
              {/*        </div>*/}
              {/*    </a>*/}
              {/*    <a href={mailz}>*/}
              {/*        <div className="ab-button">*/}
              {/*            <img className="ab-icon" style={{width: 12.89, height: 9.38}} src={EmailIcon}/>*/}
              {/*        </div>*/}
              {/*    </a>*/}
              {/*</div>*/}
            </div>
          </div>
        </div>

        <div>
          {/*<List*/}
          {/*    dataSource={itemTitleRender}*/}
          {/*    selectionMode="single"*/}
          {/*    height={400}*/}
          {/*    // itemTitleRende={itemTitleRender}*/}
          {/*    noDataText="Хоосон байна"*/}

          {/*/>*/}
        </div>
      </div>
    </div>
  );
}
export default ProfileSection;

import React, {useEffect, useState} from "react";
import LoadPanelComponent from "../pages/budget/components/loadPanel";

const OgLoader = (props) => {

    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(props.isLoading)
    }, [props.isLoading])
    //
    // console.log('pororo -> ', props);
    // console.log('state -> ', loading)

    return (
        <LoadPanelComponent position={props.position} visible={loading} />
        // <LoadPanel
        //     shadingColor="rgba(0,0,0,0.4)"
        //     position={props.position}
        //     // onHiding={() => setLoading(!loading)}
        //     visible={loading}
        //     showIndicator={true}
        //     shading={true}
        //     showPane={true}
        //    hideOnOutsideClick={true}
        //     message={props.message}
        // />
    )
}

export default OgLoader
import React from 'react';
import {Steps} from 'antd';
import '../App.css'

export const StepProgressBar = (props) => {
  const {statusId, userDesc } = props
  // console.log(statusId);
  return (
    <div className='card' style={{padding: "20px" , margin: '0', fontFamily: "'Segoe UI', sans-serif", borderColor: '#E2E8F3', cursor: 'default'}}>
      {userDesc.obt_name === "ТШЗ" && userDesc.parent_obt_id === 2 && 
      <Steps
          current={statusId}
          status={(statusId === 4 || statusId === 5) ? 'error' : 'process' }
          items={[
            {
              title: 'ТШЗ',
              description: statusId === 1 ? 'Боловсруулж байгаа' : 'Боловсруулсан'
            },
            {
              title: statusId === 3 ? 'ТТЗ-д хүргүүлсэн' : 'ТТЗ',
              description: statusId === 1 ? 'Хүлээгдэж байгаа' : 
                (statusId === 5 ? 'Татгалзсан': 
                  statusId === 3 ? 'Хянаж байгаа' : 'Нэгтгэсэн' ),status: [2,4,6,8,9].includes(statusId) ? 'finish' : statusId === 3 || statusId === 7 ? 'process' : (statusId === 5 ? 'error' : 'wait')
            },
            {
              title: statusId === 2 ? 'ТЕЗ-д хүргүүлсэн' : 'ТЕЗ',
              description: statusId === 2 ? 'Хянаж байгаа' : 
               (statusId === 4 ? 'Татгалзсан':
                   [6,8,9].includes(statusId) ? 'Нэгтгэсэн' : 'Хүлээгдэж байгаа' ),status: [6,8,9].includes(statusId) ? 'finish' : statusId === 2 ? 'process' : (statusId === 4 ? 'error' : 'wait')
            },
            {
              title: 'Сангийн яам',
              description: statusId === 8 ? 'Хүргүүлсэн' : (statusId === 9 ? 'Баталсан' : 'Хүлээгдэж байгаа'),status: statusId === 8 ? 'process' : (statusId === 9 ? 'finish' : 'wait')
            }
           /* {
              title: 'Сангийн яам',
              description: statusId === 9 ? 'Баталсан' : 'Хянаж байгаа',status: statusId === 9 ? 'finish' : 'wait'
            }*/
          ]}
          />}
          {
            userDesc.obt_name === "ТШЗ" && userDesc.parent_obt_id === 1 &&
            <Steps
          current={statusId} //{statusId === 1 ? 0 : (statusId === 2 || statusId === 4 || statusId === 6 ? 1 : 2 )}
          status={(statusId === 4) ? 'error' : 'process' }
          items={[
            {
              title: 'ТШЗ',
              description: statusId === 1 ? 'Боловсруулж байгаа' : 'Боловсруулсан',
            },
            {
              title: statusId === 2 ? 'ТЕЗ-д хүргүүлсэн' : 'ТЕЗ',
              description: statusId === 2 ? 'Хянаж байгаа' :
                  (statusId === 4 ? 'Татгалзсан':
                      [6,8,9].includes(statusId) ? 'Нэгтгэсэн' : 'Хүлээгдэж байгаа' ),status: [6,8,9].includes(statusId) ? 'finish' : statusId === 2 ? 'process' : (statusId === 4 ? 'error' : 'wait')
            },
            {
              title: 'Сангийн яам',
              description: statusId === 8 ? 'Хүргүүлсэн' : (statusId === 9 ? 'Баталсан' : 'Хүлээгдэж байгаа'),status: statusId === 8 ? 'process' : (statusId === 9 ? 'finish' : 'wait')
            }
           /* {
              title: 'Сангийн яам',
              description: statusId === 9 ? 'Баталсан' : 'Хянаж байгаа',status: statusId === 9 ? 'finish' : 'wait'
            }*/
          ]}
          />
          }
          {
            userDesc.obt_name === "ТТЗ" && userDesc.parent_obt_id === 1 &&
            <Steps
          current={statusId}//{statusId === 1 ? 0 : (statusId === 3 || statusId === 5  ? 1 : 2 )}
          status={(statusId === 4) ? 'error' : 'process' }
          items={[
            {
              title: 'ТШЗ',
              description: statusId === 1 ? 'Боловсруулж байгаа' : 'Боловсруулсан',
            },
            {
              title: statusId === 3 ? 'ТТЗ-д хүргүүлсэн' : 'ТТЗ',
              description: statusId === 1 ? 'Хүлээгдэж байгаа' :
                  (statusId === 5 ? 'Татгалзсан':
                      statusId === 3 ? 'Хянаж байгаа' : 'Нэгтгэсэн' ),
                      status: [2,6,4,8,9].includes(statusId) ? 'finish' : statusId === 3 || statusId === 7 ? 'process' : (statusId === 5 ? 'error' : 'wait')
                      
                      
            },
            {
              title: statusId === 2 ? 'ТЕЗ-д хүргүүлсэн' : 'ТЕЗ',
              description: statusId === 2 ? 'Хянаж байгаа' :
                  (statusId === 4 ? 'Татгалзсан':
                      [6,8,9].includes(statusId) ? 'Нэгтгэсэн' : 'Хүлээгдэж байгаа' ),status: [6,8,9].includes(statusId) ? 'finish' : statusId === 2 ? 'process' : (statusId === 4 ? 'error' : 'wait')
            },
            {
              title: 'Сангийн яам',
              description: statusId === 8 ? 'Хүргүүлсэн' : (statusId === 9 ? 'Баталсан' : 'Хүлээгдэж байгаа'),status: statusId === 8 ? 'process' : (statusId === 9 ? 'finish' : 'wait')
            }
           /* {
              title: 'Сангийн яам',
              description: statusId === 9 ? 'Баталсан' : 'Хянаж байгаа',status: statusId === 9 ? 'finish' : 'wait'
            }*/
          ]}
          />
          }
           {
            userDesc.obt_name === "ТЕЗ" && userDesc.parent_obt_id === null &&
            <Steps
          current={statusId} //{statusId === 1 ? 0 : (statusId === 2 || statusId === 4 || statusId === 6 ? 1 : 2 )}
          status={(statusId === 4) ? 'error' : 'process' }
          items={[
            {
              title: 'Шинэ',
              description: statusId === 1 ? 'Боловсруулж байгаа' : 'Боловсруулсан',
            },
            {
              title: statusId === 2 ? 'ТЕЗ-д хүргүүлсэн' : 'ТЕЗ',
              description: statusId === 2 ? 'Хянаж байгаа' :
                  (statusId === 4 ? 'Татгалзсан':
                      [6,8,9].includes(statusId) ? 'Нэгтгэсэн' : 'Хүлээгдэж байгаа' ),status: [6,8,9].includes(statusId) ? 'finish' : statusId === 2 ? 'process' : (statusId === 4 ? 'error' : 'wait')
            },
            {
              title: 'Сангийн яам',
              description: statusId === 8 ? 'Хүргүүлсэн' : (statusId === 9 ? 'Баталсан' : 'Хүлээгдэж байгаа'),status: statusId === 8 ? 'process' : (statusId === 9 ? 'finish' : 'wait')
            }
           /* {
              title: 'Сангийн яам',
              description: statusId === 9 ? 'Баталсан' : 'Хянаж байгаа',status: statusId === 9 ? 'finish' : 'wait'
            }*/
          ]}
          />
          }
    </div>
  )
}
import React, {useEffect, useRef, useState, useContext} from 'react'
import DropDownBox from 'devextreme-react/drop-down-box';
import TreeView,{SearchEditorOptions} from "devextreme-react/tree-view";
import OrganizationService from '../../../services/api/organization';
import { UserContext } from '../../../hooks/UserContext';

const OrganizationPositionSelect = (props) => {
    const {user} = useContext(UserContext);
    const treeView = useRef(null);
    const [treeBoxValue, setTreeBoxValue] = useState(null);
    const [isParent, setIsParent] = useState(false);
    const [positionName, setPositionName]= useState(null)
    const [orgList, setOrgList] = useState([]);
    const [disabled, setDisabled] = useState(false);
    const [showClearButton, setShowClearButton] = useState(false);

    useEffect(() => {
        getOrgList(user.organization.id)
    }, [])
    useEffect(()=>{
        getOrgList(props?.selectedOrgId)
    }, [props?.selectedOrgId])
    useEffect(() => {
        setShowClearButton(props.showClearButton)
    }, [props.showClearButton])

    useEffect(() => {
        if (treeBoxValue) {
            props.selectedData({
                structureId: treeBoxValue,
                isParent,
                positionName 
            });
        }
    }, [treeBoxValue])

    const getOrgList = async (id) => {
        if(id){
            const r = await OrganizationService.getOrgStructureByStateFilter(id);
            let result = r.data || [];
            if (result.length === 1) {
                result[0].parent = null;
                setDisabled(true)
            } else {
                let obt_id = user && user.organization && user.organization.obt_id;
                if (obt_id === 2) {
                    result.filter(data => data.code === '')
                        .forEach(data => data.parent_id = null)
                }
            }
            setTreeBoxValue(r.data.id)
            setOrgList(result)
        }
    }

    const treeItem = (item) => {
        // console.log("🚀 ~ file: orgPosition.js:46 ~ treeItem ~ item:", item)
        return <div>
            <span>{item.name}</span>
            <br/>
            <span style={{color: 'darkgray', "font-size": 12}}>{item.state_structure_name}</span>
        </div>
    }

    const treeViewRender = () => {
        return <TreeView dataSource={orgList}
                 ref={treeView}
                 dataStructure="plain"
                 keyExpr="id"
                 parentIdExpr="parent_id" noDataText={'Мэдээлэл хоосон байна'}
                 selectionMode="single"
                 searchEnabled={true}
                 showCheckBoxesMode="normal"
                 displayExpr={item => (item.name)}
                 itemRender={treeItem}
                 selectByClick={true}
                 onContentReady={syncTreeViewSelection}
                 onItemSelectionChanged={treeViewItemSelectionChanged}
        >
            <SearchEditorOptions
                placeholder="Байгууллага хайх утга энд оруулна уу"
            />
        </TreeView>
    }

    const treeViewItemSelectionChanged = (e) => {
        setIsParent(e.itemData.code === '')
        setPositionName(e.itemData.name)
        setTreeBoxValue(e.component.getSelectedNodeKeys())
    }

    const syncTreeViewSelection = (e) => {
        // console.log("🚀 ~ file: orgPosition.js:80 ~ syncTreeViewSelection ~ e:", e)
        const tView = (e.component.selectItem && e.component)
            || (treeView.current && treeView.current.instance);
        if (tView) {
            if (e.value === null) {
                tView.unselectAll();
            } else {
                const values =e.value || treeBoxValue;
                tView.selectItem(values)
            }
        }

        if (e.value !== undefined) {
            setTreeBoxValue(e.value)
        }
    }

    return <div className={'card'} style={{border: "none"}}>
        <div className={'card-body'}>
            <div style={{display: 'flex', "columnGap": 10}}>
                <div style={{display: 'flex', "alignItems": 'center', "columnGap": 10}}>
                    {props.hideLabel ? "" : <label>Алба хэлтэс:</label>}
                    <DropDownBox
                        value={treeBoxValue}
                        valueExpr="id"
                        displayExpr={(item) => {
                            return item && `${item.name}`
                        }}
                        placeholder="сонгох" label={props.labelMode ==='floating' ? "Алба хэлтэс" : ""} labelMode={props.labelMode ==='floating' ? 'floating' : 'static'}
                        disabled={disabled}
                        showClearButton={showClearButton}
                        dataSource={orgList}
                        onValueChanged={syncTreeViewSelection}
                        contentRender={treeViewRender}
                        style={{width: props.width ? props.width : 400}}
                    />
                </div>
            </div>
        </div>
    </div>
}

export default OrganizationPositionSelect;
import React from 'react';
import DropDownBox from 'devextreme-react/drop-down-box';
import TreeView from 'devextreme-react/tree-view';
import 'whatwg-fetch';

export default class SingleTreeListComponent extends React.Component {
    constructor(props) {
        super(props);
        this.treeView = null;
        this.treeDataSource = this.props.data.column.lookup.dataSource;
        this.selectedRelations = this.props.data.value;
        this.state = {treeBoxValue: [], value: 'contain'};
        this.treeViewItemSelectionChanged = this.treeViewItemSelectionChanged.bind(this);
        this.syncTreeViewSelection = this.syncTreeViewSelection.bind(this);
        this.treeViewRender = this.treeViewRender.bind(this);
    }

    componentDidMount() {
        this.sortDataList();
    }

    async sortDataList() {
        this.setState({
            treeBoxValue: this.selectedRelations
        });

        await this.treeDataSource.forEach(el => {
            if (el.id === this.selectedRelations) {
                el["selected"] = true
            } else {
                el["selected"] = false
            }

        })
    }

    render() {
        return (
            <DropDownBox
                value={this.state.treeBoxValue}
                valueExpr="id"
                displayExpr={(item) => {
                    return item && `${item.state_structure_name}`
                }}
                placeholder="Сонголт хийх..."
                showClearButton={true}
                dataSource={this.treeDataSource}
                onValueChanged={this.syncTreeViewSelection}
                contentRender={this.treeViewRender}
            />

        );
    }

    treeViewRender() {
        return (
            <React.Fragment>
                <TreeView dataSource={this.treeDataSource}
                          ref={(ref) => {
                              this.treeView = ref;
                          }}
                          dataStructure="plain"
                          keyExpr="id"
                          parentIdExpr="parent"
                          selectionMode="single"
                          showCheckBoxesMode="normal"
                          selectNodesRecursive={false}
                          displayExpr="state_structure_name"
                    //searchMode={this.state.value}
                          searchEnabled={true}
                          selectByClick={true}
                          onContentReady={this.syncTreeViewSelection}
                          onItemSelectionChanged={this.treeViewItemSelectionChanged}
                    //defaultFocusedRowKey={this.props.data.value}
                />
            </React.Fragment>

        );
    }

    syncTreeViewSelection(e) {
        const treeView = (e.component.selectItem && e.component)
            || (this.treeView && this.treeView.instance);

        if (treeView) {
            if (e.value === null) {
                treeView.unselectAll();
            } else {
                const values = e.value || this.state.treeBoxValue;
                treeView.selectItem(e.value);
            }
        }
        if (e.value !== undefined) {
            this.setState({
                treeBoxValue: e.value,
            });
        }

        this.props.data.setValue(this.state.treeBoxValue);
    }

    treeViewItemSelectionChanged(e) {
        this.setState({
            treeBoxValue: e.component.getSelectedNodeKeys(),
        });
    }
}

import { SelectBox } from 'devextreme-react';
import React, { useState, useEffect, useContext } from 'react'
import styled from 'styled-components';
import CitizenFeedbackService from '../../../services/api/citizenFeedback';
import LoadPanelComponent from '../../../components/LoadPanelComponent';
import CreateFeedbackProposal from './createFeedbackProposal';
import CitizenFeedbackList from './citizenFeedbackList';
import { FaLongArrowAltRight } from 'react-icons/fa';
import { getYears } from '../../../util/index';
import { UserContext } from '../../../hooks/UserContext';


const TabComponent = styled.div`
  display: flex;
  width: 100%;
  font-family: 'Segoe UI';
  font-weight: bold;
  .tab {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 0.5rem;
    background-color: #f5f5f5;
    transition: 0.3s all ease-in;
    border-bottom: 1px solid #ddd;
    &:nth-child(2) {
      border-top-right-radius: 15px;
      border-bottom: 1px solid #ddd;
      border-left: 1px solid #ddd;
    }
    &:nth-child(1) {
      border-top-left-radius: 15px;
      border-bottom: 1px solid #ddd;
    }
    .title {
      font-size: 1rem;
    }
    &:hover {
      background-color: #ebf1ff;
    }
    &.active {
      color: black;
      background-color: white;
      font-weight: 700;
      border-color: transparent;
      text-decoration-line: underline;
    }
  }
  `
export const tabs = [
    {
        id: 0,
        text: 'Төсвийн төслийн санал үүсгэх'
    },
    {
        id: 1,
        text: 'Төсвийн төслийн саналын жагсаалт'
    },
];
function CitizenFeedback() {
    const [selectedIndex, setSelectedIndex] = useState(0)
    const {user} = useContext(UserContext)
    const [currentYear, setCurrentYear] = useState(new Date().getFullYear())

    //-----------------------------//
    const dateRange = [`${currentYear}-08-01`, `${currentYear}-08-${user.start_of_ancestry === 260 ? '15' : '13'}`]
    return (
        <div style={{ padding: 10 }}>
            <div style={{display: 'flex', alignItems: 'baseline', gap: 10}}>
                <SelectBox
                    items={getYears(true)}
                    label="Төсвийн жил"
                    width='10rem'
                    defaultValue={currentYear}
                    onValueChanged={(e) => {
                        setCurrentYear(e.value);
                    }}
                    placeholder="сонгох" style={{ borderRadius: 10 }} />
                <div style={{ display: selectedIndex === 0 ? 'flex' : 'none', gap: 5, alignItems: 'center' }}>
                    <span style={{ padding: '8px 20px', backgroundColor: '#FFEEDF', color: '#F99D4B', borderRadius: '5px' }}>Эхлэх огноо <span style={{ fontWeight: 'bold' }}>{dateRange[0]}</span></span>
                    <FaLongArrowAltRight />
                    <span style={{ padding: '8px 20px', backgroundColor: '#FFEEDF', color: '#F99D4B', borderRadius: '5px' }}>Дуусах огноо <span style={{ fontWeight: 'bold' }}>{dateRange[1]}</span></span>
                </div>
            </div>
            <div style={{ border: '1px solid #ddd', borderRadius: 15, position: 'relative', marginTop: 10 }}>
                <TabComponent>
                    {tabs.map((item, key) => (
                        <div
                            key={key}
                            onClick={() => setSelectedIndex(item.id)}
                            className={`tab ${item.id} ${selectedIndex === item.id && "active"
                                }`}
                        >
                            <span className="title">{item.text}</span>
                        </div>
                    ))}
                </TabComponent>
                {
                    selectedIndex === 0 && <CreateFeedbackProposal currentYear={currentYear} start_date={dateRange[0]} end_date={dateRange[1]}/>

                }
                {
                    selectedIndex === 1 && <CitizenFeedbackList year={currentYear} />
                }

            </div>
        </div>
    )
}

export default CitizenFeedback
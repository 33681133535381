import React, {useEffect,useState, useRef,useCallback} from "react";
import PivotGrid, {Export as ExportPivot,Scrolling} from "devextreme-react/pivot-grid";
import {formatDate, numberWithCommas} from "../../../util";
import _ from "lodash";
import {Popup} from "devextreme-react/popup";
import DataGrid, {
    Export,
    Grouping,
    GroupItem,
    GroupPanel,
    SearchPanel,
    Summary,
    TotalItem,
    Column
} from "devextreme-react/data-grid";
import {Workbook} from "exceljs";
import {exportDataGrid, exportPivotGrid} from "devextreme/excel_exporter";
import {saveAs} from "file-saver-es";
import LoadPanelComponent from "../../budget/components/loadPanel";
import BudgetService from "../../../services/api/budget";
import {Button} from "devextreme-react/button";
import EmptyData from "../../budget/components/empty";
import {CgArrowsExpandRight, CgCompressRight, CgMaximize, CgMinimize} from "react-icons/cg";

export default function ExcelFileInputComponent(props) {
    const [drillDownDataSource, setDrillDownDataSource] = useState(null);
    const [popupVisible, setPopupVisible] = useState(false);
    const [popupTitle, setPopupTitle] = useState('');
    const [loader, setLoader] = useState(false);
    let _pivotGrid = useRef(null);
    const [scheduleStatusId, setScheduleStatusId] = useState(null);
    //const sortException = "Гүйцэтгэл";
    //const [notExportReady, notSetExportReady] = useState(true);
    const [expanded, setExpanded] = useState(false);
    const [fullscreen, setFullscreen] = useState(false);
    useEffect(() => {
        const fetchData = async () => {
            await budgetDetail();
        }
        fetchData();
    }, [props.selectedYear,props.dataSource]);
    const budgetDetail = async () => {
        setLoader(true);
        // console.log('loader', loader);
        let maxScheduleId = await BudgetService.getOrgBudgetMaxId(props.selectedYear, 'schedule');
        setScheduleStatusId(maxScheduleId.id);
        return setLoader(false);


    }

    function isDataCell(cell) {
        return (cell.area === 'data' && cell.rowType === 'D' && cell.columnType === 'D');
    }

    function isTotalCell(cell) {
        return (cell.type === 'T' || cell.type === 'GT' || cell.rowType === 'T' || cell.rowType === 'GT' || cell.columnType === 'T' || cell.columnType === 'GT');
    }

    function getCssStyles({fill, font, bold}) {
        return {
            'background-color': `#${fill}`,
            'color': `#${font}`,
            'font-weight': bold ? 'bold' : undefined
        }
    }

    function getConditionalAppearance(cell) {
        const {columnPath} = cell;
        if (columnPath) {
            if (columnPath[1] === 'Гүйцэтгэл') {
                return {font: 'EA6A47'}
            } else {
                return {font: '1C4E80'}
            }
        }
        if (isTotalCell(cell)) {
            return {fill: 'F2F2F2', font: '3F3F3F', bold: true};
        }
    }

    const onCellPrepared = (e) => {
        if (e.cell.text !== undefined && e.cell.text.substr(6, 1) === '-' || e.cell.text === "") {
            e.cell.expanded = true;
            let cell = e.cellElement.querySelector(".dx-expand-icon-container");
            if (cell) cell.innerHTML = "";
        }

        if (e.area === 'row') {
            e.cellElement.style.fontFamily = 'Segoe UI'
            e.cellElement.style.fontWeight = 'semibold'
            e.cellElement.style.color = "#15186A"
            e.cellElement.style.backgroundColor = "#EEF0F4"
        }
        if (e.area === 'column' && e.rowIndex === 0) {
            e.cellElement.style.fontFamily = 'Segoe UI'
            e.cellElement.style.fontWeight = 'semibold'
            e.cellElement.style.color = "#15186A"
            e.cellElement.style.backgroundColor = "#EEF0F4"
            e.cell.expanded = true;
            if (e.cellElement.innerHTML) e.cellElement.innerHTML = `<div style="justify-content: center;flex: 1;display: flex;font-weight: bold">${e.cell.text && e.cell.text}${e.cell.text ==="" ? props.selectedYear+" он" : " -р сар"}</div>`;
        }
        /*if (e.area === 'column' && e.rowIndex === 1) {
            e.cellElement.wordWrapEnabled = true;
            e.cellElement.style.color = "#fff"
            e.cellElement.style.backgroundColor = "#76ade7"
        }*/
        if (isDataCell(e.cell) || isTotalCell(e.cell)) {
            const appearance = getConditionalAppearance(e.cell);
            Object.assign(e.cellElement.style, getCssStyles(appearance))
        }
        if (e.area === 'data' /*&& e.cell.rowType === 'D' && e.cell.columnType === 'D'*/) {
            e.cellElement.style.fontFamily = 'Segoe UI'
            e.cellElement.style.fontWeight = 'semibold'
            e.cellElement.style.color = "#000000"
            let {rowPath, columnPath} = e.cell;
            let col = _.filter(props.dataSource._store._dataSource._items, function (o) {
                return o.econ_category_name_1 === rowPath[0] && (rowPath[1] ? o.econ_category_name_2 === rowPath[1] : true) && (rowPath[2] ? o.econ_category_name_3 === rowPath[2] : true) && (rowPath[3] ? o.econ_category_name_4 === rowPath[3] : true) && (rowPath[4] ? o.econ_category_name === rowPath[4] : true) && o.budget_status_id === scheduleStatusId && o.budget_month === columnPath[0];
            });
            let prev_debit = _.reduce(col, function (sum, n) {
                return sum + n.debit * 1;
            }, 0)
            let percent = 0;

            if (e.cell.value) {
                percent = prev_debit !== 0 ? (e.cell.value - prev_debit) * 100 / prev_debit : 0;
            }

            let v = rowPath.join('NV');
            let vr = v.replaceAll(' ', 'SP');
            vr = vr.replaceAll('-', 'MN');
            vr = vr.replaceAll(',', 'CM');
            vr = vr.replaceAll('/', 'SL');
            vr = vr.replaceAll('(', 'BS');
            vr = vr.replaceAll(')', 'BE');

            e.cellElement.onmouseenter = function (e) {
                e.preventDefault();
            }

            e.cellElement.onmouseleave = function (e) {
                e.preventDefault();
            }
            if (columnPath[1] === 'Гүйцэтгэл') {
                if (percent > 0) {
                    e.cellElement.innerHTML = `<div class="inc customCell" id="${vr}YR${columnPath[0]}" style="font-family: 'Segoe UI';font-size: 12px;justify-content: center">
                    <div class="current-value" style="font-family: 'Segoe UI';font-size: 12px">${e.cell.text}</div>
                    <div class="diff" style="font-family: 'Segoe UI';font-size: 11px">${numberWithCommas(percent)}%</div>
                </div>`;
                } else if (percent) {
                    e.cellElement.innerHTML = `<div class="dec customCell" style="font-family: 'Segoe UI';justify-content: center" id="${vr}YR${columnPath[0]}">
                      <div class="current-value" style="font-family: 'Segoe UI';font-size: 12px">${e.cell.text}</div>
                      <div class="diff" v-if="percent" style="font-family: 'Segoe UI';font-size: 11px">${numberWithCommas(percent)}%</div>
                  </div>`;
                } else {
                    e.cellElement.innerHTML = `<div class="customCell" style="font-family: 'Segoe UI';font-size: 12px" id="${vr}YR${columnPath[0]}">
                      <div class="current-value" style="font-family: 'Segoe UI';font-size: 12px">${e.cell.text && e.cell.text} </div>
                  </div>`;
                }
            }
        }
    }
    const onCellClick = (e) => {
        if (e.area === 'column' && e.rowIndex === 0) {
            e.component.isRowExpanded(true);
        }
        if (e.area === 'data' && e.cell.columnPath[1] === 'Санхүү Гүйцэтгэл') {
            const pivotGridDataSource = e.component.getDataSource();
            const rowPathLength = e.cell.rowPath.length;
            const rowPathName = e.cell.rowPath[rowPathLength - 1];
            setPopupTitle(`${rowPathName || 'Total'}`);
            setDrillDownDataSource(pivotGridDataSource.createDrillDownDataSource(e.cell));
            setPopupVisible(true);
        }
    }
    const onHiding = () => {
        setPopupVisible(false);
    }

    const onShown = () => {
        this.props.data.component.updateDimensions();
        //this.dataGrid.updateDimensions();
    }
    const customizeDate = (data) => {
        return Number(parseFloat(data.value).toFixed(2)).toLocaleString("en", {
            minimumFractionDigits: 2,
        });
    }
    const onExporting = (e) => {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Main sheet');

        exportDataGrid({
            component: e.component,
            worksheet,
            autoFilterEnabled: true,
        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer], {type: 'application/octet-stream'}), `${popupTitle}.xlsx`);
            });
        });
        e.cancel = true;
    }

    function getExcelCellFormat({fill, font, bold}) {
        return {
            fill: {type: 'pattern', pattern: 'solid', fgColor: {argb: fill}},
            font: {color: {argb: font}, bold},
        };
    }
    const changeState =(area, value)=> {
        let pvDataSource = _pivotGrid.current.instance.getDataSource();
        _pivotGrid.current.instance.beginUpdate();
        let fields = value ? pvDataSource.fields() : pvDataSource.fields().slice().reverse();
        fields.forEach((f) => {
            if (f.area === 'row') {
                // console.log('f',f);
                if (value){
                    f.expanded=true;
                    pvDataSource.expandAll(f.dataField);
                }

            else {
                    f.expanded=false;
                    pvDataSource.collapseAll(f.dataField);
                }
            }
        });
        _pivotGrid.current.instance.endUpdate();
    }
    const onExportingPivot = (e) => {
            const workbook = new Workbook();
            const worksheet = workbook.addWorksheet('Төсвийн гүйцэтгэл');

            worksheet.columns = [
                {width: 30}, {width: 20}, {width: 30}, {width: 30}, {width: 30}, {width: 30},
            ];

            exportPivotGrid({
                component: e.component,
                worksheet,
                topLeftCell: {row: 4, column: 1},
                mergeRowFieldValues: true,
                keepColumnWidths: true,
                customizeCell: ({pivotCell, excelCell}) => {
                    if (isDataCell(pivotCell) || isTotalCell(pivotCell)) {
                        const appearance = getConditionalAppearance(pivotCell);
                        Object.assign(excelCell, getExcelCellFormat(appearance));
                    }

                    const borderStyle = {style: 'thin', color: {argb: 'FF7E7E7E'}};
                    excelCell.border = {
                        bottom: borderStyle,
                        left: borderStyle,
                        right: borderStyle,
                        top: borderStyle,
                    };
                },
            }).then((cellRange) => {
                // Header
                const headerRow = worksheet.getRow(2);
                headerRow.height = 30;

                const columnFromIndex = worksheet.views[0].xSplit + 6;
                const columnToIndex = columnFromIndex + 3;
                worksheet.mergeCells(2, columnFromIndex, 2, columnToIndex);

                const headerCell = headerRow.getCell(columnFromIndex);
                headerCell.value = 'Төсвийн гүйцэтгэлийн тайлан';
                headerCell.font = {name: 'Calibri', size: 20, bold: true};
                headerCell.alignment = {horizontal: 'left', vertical: 'middle', wrapText: true};
            }).then(() => {
                workbook.xlsx.writeBuffer().then((buffer) => {
                    saveAs(new Blob([buffer], {type: 'application/octet-stream'}), 'OG-Төсвийн гүйцэтгэл-'+ formatDate(new Date()) + ".xlsx");
                });
            });
            e.cancel = true;
    };
    const ExpandCollapseButton = useCallback((area) => {
        return (<Button
            onClick={(e) => {
                setExpanded(!expanded);
                changeState(area, !expanded)
            }}
            render={() => {
                return expanded ? (
                        <CgCompressRight size={"17px"} color="#1a1976"/>)
                    :
                    (
                        <CgArrowsExpandRight size={"17px"} color="#1a1976"/>
                    )
            }}
            hint={expanded ? 'Хураах' : 'Задлах'}
        >
        </Button>)
    }, [expanded])
    const requestFullscreen = () => {
        if (document.fullscreenElement) document.exitFullscreen();
        else document.getElementById("loadPerf").requestFullscreen();
        // setFullscreen(prev=>!prev)
    }
    const FullscreenButton = useCallback(() => {
        return (
            <Button
                onClick={() => {
                    requestFullscreen()
                    setFullscreen(prev => !prev)
                }}
                style={{marginLeft: "0.5em"}}
                render={() => {
                    return fullscreen ?
                        (<CgMinimize size={"17px"} color="#1a1976"/>)
                        :
                        (<CgMaximize size={"17px"} color="#1a1976"/>)
                }}
                hint={fullscreen ? 'Багасгах' : 'Дэлгэцэнд бүтэн харуулах'}
            >
            </Button>
        )
    }, [fullscreen])
    return (
        <div id="loadPerf" style={{backgroundColor:"#fff"}}>
                <LoadPanelComponent position={{of: '#loadPerf'}} visible={loader}/>
                {(props.dataSource && !loader) ? (
                    <div width='100%' height='100%'>
                        {/*<div style={{flex:1,justifyContent:'flex-end',flexDirection:'row',display:'flex',marginBottom:5,borderRadius:10}}>
                            <Button
                                type={'back'} hint={expanded ? 'Хураах' : 'Задлах'}
                            text={expanded ? 'Хураах' : 'Задлах'}
                            icon={expanded ? 'collapse' : 'expand'}
                            onClick={() => {
                                setExpanded(!expanded);
                                changeState("",!expanded)
                            }}
                        /></div>*/}
                        <div style={{
                            flex: 1,
                            justifyContent: 'flex-end',
                            flexDirection: 'row',
                            display: 'flex',
                            marginBottom: 5,
                            borderRadius: 10,
                            padding: 10
                        }}>
                            <ExpandCollapseButton/>
                            <FullscreenButton/>
                        </div>
                            <PivotGrid
                                style={{fontFamily: "Segoe UI"}}
                                id="pivotgrid"
                                ref={_pivotGrid}
                                dataSource={props.dataSource}
                                onCellPrepared={onCellPrepared}
                                rowHeaderLayout={'tree'}
                                allowSortingBySummary={true}
                                sortingMethod={true}
                                allowFiltering={true}
                                showBorders={true}
                                allowExpandAll={true}
                                showColumnGrandTotals={false}
                                showRowGrandTotals={false}
                                showRowTotals={true}
                                showColumnTotals={false}
                                onExporting={onExportingPivot}
                                fieldChooser={{
                                    enabled: false
                                }}
                                texts={{
                                    noData:'Дата байхгүй байна.',
                                    collapseAll:'Бүгд хураах',
                                    expandAll:'Бүгд дэлгэх',
                                    showFieldChooser:'Талбар сонголт харах',
                                    exportToExcel:'Excel файл татах',
                                    grandTotal: 'Нийт дүн',
                                    total: '{0}'
                                }}
                                onCellClick={onCellClick}>
                                <ExportPivot enabled={true}/>
                                <Scrolling mode="standard" />
                            </PivotGrid>
                    </div>
                    ) :
                    <div style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        family: "'Montserrat', sans-serif"
                    }}>
                        <EmptyData/>
                    </div>
                }

                {
                    popupVisible && <Popup
                        visible={popupVisible}
                        width={800}
                        height={500}
                        title={popupTitle}
                        closeOnOutsideClick={true}
                        onHiding={onHiding}
                        onShown={onShown}
                    >
                        <DataGrid
                        noDataText="Дата байхгүй байна."
                            width={750}
                            height={400}
                            dataSource={drillDownDataSource}
                            allowColumnResizing={true}
                            onExporting={onExporting}>
                            <GroupPanel visible={true}/>
                            <SearchPanel visible={true}/>
                            <Grouping autoExpandAll={true}/>
                            <Column dataField="org_name" caption="Байгууллага" groupIndex={0}/>
                            <Column dataField="docno" caption="Журнал дугаар" width={100}
                                    allowCollapsing={false}
                                    allowFiltering={true}/>
                            <Column dataField="acct_name" caption="Данс"
                                    allowCollapsing={false}
                                    allowFiltering={true}/>
                            <Column dataField="apacct_name" caption="Ангилал"
                                    allowCollapsing={false}
                                    allowFiltering={true}/>
                            <Column dataField="custname" caption="Харилцагч"
                                    allowCollapsing={false}
                                    allowFiltering={true}/>
                            <Column dataField="descr" caption="Гүйлгээний утга"
                                    allowCollapsing={false}
                                    allowFiltering={true}/>
                            <Column dataField="debit" caption="Дүн" dataType="number" format="#,##0.00"/>
                            <Summary>
                                <GroupItem
                                    column="debit"
                                    summaryType="sum"
                                    customizeText={customizeDate}
                                    showInGroupFooter={true}/>
                                <TotalItem
                                    column="debit"
                                    summaryType="sum"
                                    customizeText={customizeDate}/>
                            </Summary>
                            <Export enabled={true} allowExportSelectedData={false}/>
                        </DataGrid>
                    </Popup>
                }
        </div>
    )
}

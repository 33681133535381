import React, { useState, useEffect, useContext } from 'react'
import BudgetService from '../../../services/api/budget'
import notify from 'devextreme/ui/notify'
import _ from 'lodash'
import LoadPanelComponent from '../../budget/components/loadPanel'
import { IoIosArrowDown } from 'react-icons/io'


const PerformanceByAgendIncome = (props) => {

    const [data, setData] = useState([])
    const [data1, setData1] = useState([])
    const [loader, setLoader] = useState(false)
    const [selectedAgenda, setSelectedAgenda] = useState(null)
    const [selectedItemIndex, setSelectedItemIndex] = useState(null);

    const fetchData = async () => {
        try {
            setLoader(true);
            let r = await BudgetService.getAgendaPerformanceByIncome(props.year, props.filterOrgText,props.orgId);
            let pp = _(r)
                .groupBy('parent_agenda_name')
                .map((item, key) => {
                    const amountSum = _.sumBy(item, "amount");
                    const guitsetgelSum = _.sumBy(item, "guitsetgel");
                    const percent1 = amountSum !== 0 ? ((guitsetgelSum * 100) / amountSum).toFixed(2) : 0;
                    return {
                        agenda: key,
                        amount: amountSum / 1000000,
                        guitsetgel: guitsetgelSum / 1000000,
                        percent: percent1
                    }
                })
                .orderBy(['agenda'], ['asc'])
                .value();
            let pp1 = _(r)
                .groupBy('agenda_name')
                .map((item, key) => {
                    const amountSum = _.sumBy(item, "amount");
                    const guitsetgelSum = _.sumBy(item, "guitsetgel");
                    const percent1 = amountSum !== 0 ? ((guitsetgelSum * 100) / amountSum).toFixed(2) : 0;
                    return {
                        agenda: item[0].parent_agenda_name,
                        agenda1: key,
                        amount: amountSum / 1000000,
                        guitsetgel: guitsetgelSum / 1000000,
                        percent: percent1
                    }
                })
                .orderBy(['agenda1'], ['asc'])
                .value();
            setData(pp)
            setData1(pp1) 
            setLoader(false);
        } catch (e) {
            setLoader(false);
            notify(e.message, "error", 2000);
        }
    }


    useEffect(() => {
        fetchData()
        setSelectedAgenda(null)
    }, [props.year, props.orgId])

    const groupedData1 = _.groupBy(data1, 'agenda');

    const handleClick = (agenda) => {
        setSelectedAgenda(agenda)
    }
    const colors = ['#FCDDCA', '#CBF0E5', '#E5CBF1']

    return (
        <div id='loading' className='responsiveEza'>
            <LoadPanelComponent position={{ of: '#loading' }} visible={loader} />
            <div className='eza' style={{ display: 'flex', flexWrap: 'wrap', gap: '15px', marginBottom: '1rem' }}>
                {data && data.length > 0
                    ? data.map((e, index) => {
                        let percentage = e.percent
                        return <div className='mainContainerOfCard1' style={{backgroundImage: '#FFFFFF', border: `3px solid ${colors[index]}`, boxShadow: `0px 0px 5px ${colors[index]}`}} key={index} onClick={() => {
                            handleClick(e.agenda)
                            setSelectedItemIndex(index)
                        }}>
                            <div className='card-ball' style={{backgroundColor: `${colors[index]}`}}/>
                            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', textAlign: 'start', height: '80%' }}>
                                <h3 style={{ fontSize: '1rem' }}>{e.agenda.replace(/[0-9-]/g, '')}</h3>
                                <h2 style={{ fontWeight: 'bold', margin: '0 0 3px 0' }}>{e.amount.toLocaleString(undefined, { maximumFractionDigits: 2 })}</h2>
                                <span style={{ display: 'flex', gap: '5px', fontSize: '.875rem' }}>
                                    Гүйцэтгэл: <span style={{ fontWeight: 'bold',color: 'rgb(76, 82, 134)' }}> {e.guitsetgel.toLocaleString(undefined, { maximumFractionDigits: 2 })}</span>
                                    <span style={{ fontWeight: 'bold' }}> ({percentage}%)</span>
                                </span>
                            </div>
                            <div className='bouncePerformance'>
                                <IoIosArrowDown size={25} />
                            </div>
                        </div>
                    })
                    :
                    <div>Мэдээлэл байхгүй байна</div>
                }
            </div>
            {selectedAgenda && (
                <div className='eza1'>
                    <h3 style={{ fontSize: '1rem', fontWeight: 'bold' }}>{selectedAgenda}</h3>
                    <div style={{ display: 'flex', flexWrap: 'wrap', gap: '15px' }}>
                        {groupedData1[selectedAgenda]?.map((e, index) => {
                            let percentage = e.percent
                            return (
                                <div className='mainContainerOfCard' style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between',backgroundColor: '#FAFBFC', border: `2px solid ${colors[selectedItemIndex]}`, boxShadow: `0px 0px 5px ${colors[selectedItemIndex]}`}}  key={index}>
                                    <h3 style={{ fontSize: '1rem' }}>{e.agenda1.replace(/[0-9-]/g, '')}</h3>
                                    <h2 style={{ fontWeight: 'bold', margin: '0 0 3px 0' }}>{e.amount.toLocaleString(undefined, { maximumFractionDigits: 2 })}</h2>
                                    <span style={{ display: 'flex', gap: '5px', fontSize: '.875rem' }}>
                                        Гүйцэтгэл:  <span style={{ fontWeight: 'bold', color: 'rgb(76, 82, 134)' }}>{e.guitsetgel.toLocaleString(undefined, { maximumFractionDigits: 2 })}</span>
                                        <span style={{ fontWeight: 'bold' }}> ({percentage}%)</span>
                                    </span>
                                    <div className='backOfCard'>
                                        <h4>{e.agenda1.replace(/[0-9-]/g, '')}</h4>
                                    </div>
                                </div>
                            )
                        }
                        )}
                    </div>
                </div>
            )}
        </div>
    )
}

export default PerformanceByAgendIncome
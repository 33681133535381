import React, {useEffect, useRef, useState,useContext} from 'react';
import DataGrid, {
    Column,
    Editing,
    Scrolling,
    Paging,
    HeaderFilter,
    Export, GroupPanel, Grouping, Selection,Pager
} from 'devextreme-react/data-grid';
import Form, {
    Item as FormItem,
    Label,
    RequiredRule as RequiredRuleForm,
    EmailRule,
    PatternRule, ButtonItem
} from 'devextreme-react/form';
import SelectBox from "devextreme-react/select-box";
import OrganizationService from "../../../services/api/organization";
import OrgFormRenderer from "../../organization/components/orgFormRenderer"
import {Button} from 'devextreme-react/button';
import 'devextreme-react/text-area';
import moment from "moment";
import Popup from 'devextreme-react/popup';
import ScrollView from 'devextreme-react/scroll-view';
import {Button as FormButton} from "devextreme-react/data-grid";
import notify from "devextreme/ui/notify";
import FileUploader from "devextreme-react/file-uploader";
import ProposalService from "../../../services/api/proposal";
import {LoadIndicator} from "devextreme-react/load-indicator";
import FileServices from "../../../services/api/file";
import FileTypeService from "../../../services/api/fileType";
import img from "../../../assets/imgs/userLogo.png";
import styles from "../../myInfo/myInfo.module.css";
import {CheckBox} from "devextreme-react/check-box";
import {Workbook} from "exceljs";
import {exportDataGrid} from "devextreme/excel_exporter";
import {saveAs} from "file-saver-es";
import LoadPanelComponent from "../../budget/components/loadPanel";
import strategy_icon from "../../../assets/imgs/structureIcon2.png";
import {urlServer} from "../../../shared/lib/request";
import EconService from "../../../services/api/economicCategory";
import TreeView from "devextreme-react/tree-view";
import DropDownBox from "devextreme-react/drop-down-box";
import CustomStore from "devextreme/data/custom_store";
import {TextBox} from "devextreme-react";
import Compressor from 'compressorjs';
import OrganizationSelectComponent from "../../../components/OrganizationSelectComponent";
import {UserContext} from "../../../hooks/UserContext";
import { MdDelete } from 'react-icons/md';
import OrganizationPositionSelect from './orgPosition';
export default function PositionSection(props) {

    const [formData, setFormData] = useState({
        org_name: "Хууль зүйн, дотоод хэргийн яам",
        structure_name: '',
        organization: '',
        department: '',
        dep_name: '',
        position: '',
        last_name: '',
        first_name: '',
        reg_no: '',
        email: '',
        phone: '',
        mobile: '',
        isVisible: false,
        file_type: '',
        address: '',
        portrait_file_name: '',
        worker_file_name: '',
        worker_status_id: '',
        workerEcons: '',
    });
    //const [showAddDialog, setShowAddDialog] = useState(false);
    const [currentWorkerId, setCurrentWorkerId] = useState(null);
    const [vOrgId, setVOrgId] = useState(null);
    const [columnData, setColumnData] = useState([]);
    const [popupVisible, setPopupVisible] = useState(false);
    const [updateFilesLoad, setUpdateFilesLoad] = useState(false);
    const [fileArray, setFileArray] = useState([]);
    const [fileList, setFileList] = useState([]);
    const [workerList, setWorkerList] = useState([]);
   // const [realList, setRealList] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [orgStructure, setOrgStructure] = useState([]);
    const [structureList, setStructureList] = useState([]);
    const [organizationList, setOrganizationList] = useState([]);
    const [orgValue, setOrgValue] = useState(null);
    const [selectedStructureList, setSelectedStructureList] = useState([]);
    const [structureValue, setStructureValue] = useState(null);
    const [selectedOrgStructure, setSelectedOrgStructure] = useState([]);
    const [positionList, setPositionList] = useState([]);
    const [workerStatus, setWorkerStatus] = useState([]);
    const [stateStructureValue, setStateStructureValue] = useState(null);
    const [positionValue, setPositionValue] = useState(null);
    const [workerStatusValue, setWorkerStatusValue] = useState(null);
    const [fileType, setFileType] = useState([]);
    const [fileTypeValue, setFileTypeValue] = useState(19);
    const [isVisible, setIsVisible] = useState(true);
    const [dataIsEdit, setDataIsEdit] = useState(false);
    const [structure, setStructure] = useState(null);
    const [orgStructureTmp, setOrgStructureTmp] = useState(null);
    const fileRef = useRef();
    const portraitRef = useRef();
    const workerPicRef = useRef();
    const [showResumeDialog, setShowResumeDialog] = useState(false);
    const [pageIndex, setPageIndex] = useState(0);
    const [loader, setLoader] = useState(false);
    const [togglerFile, setTogglerFile] = useState(false);
    const [workerFileData, setWorkerFileData] = useState([]);
    const [filePopUpModal, setFilePopUpModal] = useState(false);
    const [fileExtention, setFileExtention] = useState(null);
    const [fileName, setFileName] = useState(null);
    const workerPosition = {of: '#workerList'}
    const [dataSourceEcon, setDataSourceEcon] = useState([]);
    const [econValue, setEconValue] = useState([]);
    const [treeBoxValue, setTreeBoxValue] = useState([]);
    const treeView = useRef();
    const treeDataSource = dataSourceEcon;
    const [limit, setLimit] = useState(50);
    const dataGridRef = useRef(null);
    const [searchText, setSearchText] = useState('');
    const [selectedData, setSelectedData] = useState({});
    const [selectedPosition, setSelectedPosition] = useState({})
    const {user} = useContext(UserContext);
    const treeViewRender = () => {
        return (
            <TreeView dataSource={treeDataSource}
                      ref={treeView}
                      dataStructure="plain"
                      keyExpr="id"
                      parentIdExpr="parent_id"
                      selectionMode="multiple"
                      showCheckBoxesMode="normal"
                      selectNodesRecursive={false}
                      displayExpr={(item) => {
                          return item && `${item.code} - ${item.name}`
                      }}
                      searchMode="contains"
                      searchEnabled={true}
                      selectByClick={true}
                      onContentReady={syncTreeViewSelection}
                      onItemSelectionChanged={treeViewItemSelectionChanged}
            />
        );
    }


    const syncTreeViewSelection = (e) => {
        const tree = (e.component.selectItem && e.component) || (treeView && treeView.instance);
        if (tree) {
            if (e.value === null) {
                tree.unselectAll();
            } else {
                const values = e.value || treeBoxValue;
                values && values.forEach((value) => {
                    //console.log('value', value);
                    tree.selectItem(value);
                });
            }
        }


        if (e.value !== undefined) {
            //console.log('e.value', e.value);
            setTreeBoxValue(e.value);
        }
        //this.data.setValue(treeBoxValue);
        //  console.log('treeBoxValue sss ',this.state.treeBoxValue);
    }

    const treeViewItemSelectionChanged = (e) => {
        //console.log('e.component.getSelectedNodeKeys()', e.component.getSelectedNodeKeys());
        setTreeBoxValue(e.component.getSelectedNodeKeys());
        setEconValue(e.component.getSelectedNodeKeys());
        //    console.log('treeViewItemSelectionChanged ddd ',e);
    }
    const selectEconomic = () => {
        return (
            <div>
                <DropDownBox
                    value={treeBoxValue}
                    valueExpr="id"
                    displayExpr={(item) => {
                        return item && `${item.code} - ${item.name}`
                    }}
                    placeholder="Сонголт хийх..."
                    showClearButton={true}
                    dataSource={treeDataSource}
                    onValueChanged={syncTreeViewSelection}
                    contentRender={treeViewRender}
                />
            </div>
        )
    }
    useEffect(() => {
        getData();
        getWorker();
    }, [user.organization.id]);
    useEffect(() => {
        getWorker();
    }, [pageIndex,selectedData && selectedData.orgId, selectedPosition && selectedPosition.structureId]);
    const getWorker = async () => {
        setLoader(true);
        //if((selectedData && selectedData.orgId) || searchText)  setPageIndex(0);
        setWorkerList({
            store: new CustomStore({
                key: 'wid',
                load: function () {
                    return OrganizationService.getOrgWorker(null,null,pageIndex,limit,searchText,selectedData?.orgId?.[0],selectedPosition?.structureId?.[0]=== undefined ? 0 :selectedPosition?.structureId?.[0])
                        .then((data) => {
                            return {
                                data: data.data,
                                totalCount: data.totalRows
                            }
                        })
                        .catch((e) => { notify(e.message, "error", 2000) })
                }
            })
        })
        const econ = await EconService.getWorkerEconomic();
        setDataSourceEcon(econ.data);
        let fileData = await FileTypeService.getWorkerFileType();
        const tempFileData = fileData.data.filter((data)=>{return data.id != "22"}) // removing Албан тушаалын тодорхойлолт
        setFileType(tempFileData);
        //sortData();
        return setLoader(false)
    }
    const getData = async () => {
        const result = await OrganizationService.getAllOrganizations();
        setOrgStructure(result.data.orgStructure);
        setStructureList(result.data.structure);
        /*    let org = {full_name: 'Нийт', id: 0, index: 0}
            result.data.organization.push(org);*/

        setOrganizationList(result.data.organization);
        const worker_status = await OrganizationService.getWorkStatus();
        setWorkerStatus(worker_status.data);
        let list = [];
        result.data.structure.forEach(el => {
            if (el.parent_id === result.data.organization[0].id || el.org_id === result.data.organization[0].id) {
                list.push(el);
            }
        });
        let list1 = [];
        result.data.orgStructure.forEach(el => {
            if (el.state_structure_id === list[0].state_structure_id) {
                list1.push(el);
            }
        });
        const position = await OrganizationService.getOrgPosition(list[0].state_structure_id);

        setPositionList(position.data);
        setSelectedOrgStructure(list);
        setOrgValue(result.data.organization[0]);
        setSelectedStructureList(list);
        setStructureValue(list[0]);
        setSelectedOrgStructure(list1);
        setStateStructureValue(list1[0]);


    }

    useEffect(() => {
        const list = [];
        if (props.structureId) {
            workerList.forEach(element => {
                if (element.structure_id === props.structureId) {
                    list.push(element);
                }
            });
        }
    }, [props.structureId]);

    /*const sortData = () => {
        const list = [];
        if (props.structureId) {
            workerList.forEach(element => {
                if (element.structure_id === props.structureId) {
                    list.push(element);
                }
            });
        }
    }*/

    const showResume = async (e) => {
        setCurrentWorkerId(currentWorkerId);
        setVOrgId(e.row.data.wid)
        setShowResumeDialog(true);
    }
    const onFileEvent = async (e) => {
        if (e.row.data) {
            setLoader(true);
            setCurrentWorkerId(e.row.data.wid);
            let result = await OrganizationService.getOrgWorkerFile(e.row.data.wid);
            setWorkerFileData(result.data);
            fileRef.current = undefined;
            setTogglerFile(true);
            setLoader(false);
        }
    }
    const onClickEdit = async (e) => {
        // console.log(e);
        let list = [];
        //console.log('e.row.data', e.row.data);
        if (e.row.data) {
            setLoader(true);
            setDataIsEdit(true);
            setCurrentWorkerId(e.row.data.wid);
            //    setTreeBoxValue([]);
            setEconValue(e.row.data.workerEcons);
            setTreeBoxValue([e.row.data.workerEcons]);
            await treeDataSource.forEach(el => {
                el["selected"] = false
                if (e.row.data.workerEcons) {
                    e.row.data.workerEcons.forEach(data => {
                        if (el.id === data.id) {
                            /*     console.log('el.i', el.i);
                                 console.log('data.i', data.i)*/
                            el["selected"] = true
                        }
                    })
                }

            })
            if (e.row.data.state_structure_id) {
                orgStructure.forEach(el => {
                    if (el.state_structure_id === e.row.data.state_structure_id) {
                        list.push(el);
                    }
                });
                setSelectedOrgStructure(list);

                const position = await OrganizationService.getOrgPosition(e.row.data.state_structure_id);
                setPositionList(position.data);
                const worker_status = await OrganizationService.getWorkStatus();
                setWorkerStatus(worker_status.data);

                position.data.forEach(el4 => {
                    if (el4.id === e.row.data.org_position_id) {
                        setPositionValue(el4);
                    }
                });
                worker_status.data.forEach(ws => {
                    if (ws.id === e.row.data.worker_status_id) {
                        setWorkerStatusValue(ws);
                    }
                });
            }
            if (e.row.data.id && e.row.data.org_id) {
                orgStructure.forEach(el1 => {
                    if (el1.id === e.row.data.id) {
                        setStateStructureValue(el1);
                    }
                });
                let id = '';
                structureList.forEach(el2 => {
                    if (el2.org_id === e.row.data.org_id) {
                        setStructureValue(el2);
                        id = el2.parent_id;
                    }
                });
                let list1 = [];
                structureList.forEach(el => {
                    if (el.parent_id === id) {
                        list1.push(el);
                    }
                });

                setSelectedStructureList(list1);
                organizationList.forEach(el3 => {
                    if (el3.id === id) {
                        setOrgValue(el3);
                    }
                });
            }
            e.row.data.is_visible === 1 ? setIsVisible(true) : setIsVisible(false);
            const data = {
                org_id: e.row.data.org_id,
                org_name: e.row.data.state_structure_name,
                org_position_id: e.row.data.org_position_id,
                state_structure_id: e.row.data.state_structure_id,
                structure_name: e.row.data.state_structure_name,
                organization: e.row.data.state_structure_name,
                department: e.row.data.org_structure_name,
                dep_name: e.row.data.state_structure_name,
                position: e.row.data.org_position_name,
                last_name: e.row.data.last_name,
                first_name: e.row.data.first_name,
                reg_no: e.row.data.regno,
                email: e.row.data.email,
                phone: e.row.data.phone,
                mobile: e.row.data.mobile,
                file_type: e.row.data.file_path,
                address: e.row.data.address,
                portrait_file_name: e.row.data.portrait_file_name,
                worker_file_name: e.row.data.worker_file_name,
                worker_status_id: e.row.data.worker_status_id,
                worker_status_name: e.row.data.worker_status_name,
                is_visible: e.row.data.is_visible,
            }
            setFormData(data);
            portraitRef.current = undefined;
            workerPicRef.current = undefined;
            setIsOpen(true);
            setLoader(false);

        }
    }

    const getFileList = async (id) => {
        const res = await ProposalService.getWorkerFile(id);
        setFileList(res.data);
    }

    const PopupInfo = (data) => {
        getFileList(data.data.id);
        let fileArray = [];
        if (data.data.id.length) {
            const filteredArray = fileList.filter((foundItem) => {
                if (foundItem.id) {
                    return foundItem.id === data.data.id;
                }
            });
            //fileArray = filteredArray;
        }
        setFileArray(fileList)
    };

    const fileChangeHandler = (e) => {
        fileRef.current = e.value;
    };

    const portraitChange = (e) => {
        portraitRef.current = e.value;
    }
    const workerPicChange = (e) => {
        workerPicRef.current = e.value;
    }

    const PortraitFile = () => {
        return (
            <div style={{}}>
                <FileUploader
                    onValueChanged={portraitChange}
                    selectButtonText="Portrait зураг сонгох"
                    labelText=""
                    accept="image/*"
                    uploadMode="useForm"
                />
            </div>
        );
    };
    const WorkerPicFile = () => {
        return (
            <div style={{}}>
                <FileUploader
                    onValueChanged={workerPicChange}
                    selectButtonText="Албан хаагчийн зураг сонгох"
                    labelText=""
                    accept="image/*"
                    uploadMode="useForm"
                />
            </div>
        );
    };
    const selectOrg = () => {
        //console.log('organizationList', organizationList);
        return (
            <div>
                <SelectBox items={organizationList} displayExpr='organization_name' value={orgValue}
                           placeholder="Сонгох"
                           onValueChanged={onChangedOrg}
                />
            </div>
        )
    }

    const onChangedOrg = async (e) => {
        //console.log('e.value', e.value);
        setOrgValue(e.value);
        let list = [];
        structureList.forEach(el => {
            if (el.parent_id === e.value.id || el.org_id === e.value.id) {
                list.push(el);
            }
        });
        setSelectedStructureList(list);
    }

    const selectStructure = () => {
        //console.log('selectedStructureList',selectedStructureList);
        return (
            <div>
                <SelectBox items={selectedStructureList} displayExpr='state_structure_name' value={structureValue}
                           placeholder="Сонгох"
                           onValueChanged={onChangeStructure}
                />
            </div>
        )
    }
    const positionChange = async (e) => {
        const position = await OrganizationService.getOrgPosition(e);
        setPositionList(position.data);
    }

    const onChangeStructure = (e) => {
        //('onChangeStructure', e.value);
        positionChange(e.value.state_structure_id)
        setStructureValue(e.value);
        let list = [];
        orgStructure.forEach(el => {
            if (el.state_structure_id === e.value.state_structure_id) {
                list.push(el);
            }
        });
        setSelectedOrgStructure(list);
    }

    const selectOrgStructure = () => {
        return (
            <div>
                <SelectBox items={selectedOrgStructure} displayExpr="name" placeholder="Сонгох"
                           onValueChanged={onChangeOrgStructure} value={stateStructureValue}/>
            </div>
        )
    }
    const onChangeOrgStructure = async (e) => {
        setStateStructureValue(e.value);
        const position = await OrganizationService.getOrgPosition(e.value.state_structure_id);
        setPositionList(position.data);
        const worker_status = await OrganizationService.getWorkStatus();
        setWorkerStatus(worker_status.data);
    }

    const selectPosition = () => {
        return (
            <div>
                <SelectBox searchEnabled={true} searchMode='startsWith' items={positionList} displayExpr="name" placeholder="Сонгох"
                           onValueChanged={onChangePosition} value={positionValue}/>
            </div>
        )
    }
    const onChangeWorkerStatus = (e) => {
        setWorkerStatusValue(e.value);
    }
    const selectWorkerStatus = () => {
        return (
            <div>
                <SelectBox items={workerStatus} displayExpr="name" placeholder="Сонгох"
                           onValueChanged={onChangeWorkerStatus} value={workerStatusValue}/>
            </div>
        )
    }

    const onChangePosition = (e) => {
        setPositionValue(e.value);
    }

    const onChangeFileType = (e) => {
        setFileTypeValue(e.value);
    }

    const fileSubmit = async (workerId, typeId, size) => {
        if (fileRef.current === undefined) {
            notify(`Файл сонгоогүй байна!`, "warning", 3000);
        }
        if (fileRef.current[0].size > size) {
            notify(`Оруулсан файл хэмжээ  ${size / 1000000}MB ээс их байна`, "warning", 3000);
            return 0;
        }
        if (fileRef.current && workerId) {
            // fileRef.current[0].name = `${fileRef.current[0].lastModified}-${fileRef.current[0].name}`;
            let data = new FormData();
            data.append("file", fileRef.current[0]);
            const res = await FileServices.uploadFile(data, typeId);
            const fileData = {
                file_id: res.data.id,
                worker_id: workerId,
                file_type_id: typeId
            }
            let orgFileId = await FileServices.setFileWorker(fileData);
            if (orgFileId.code === 200) {
                notify('Амжилттай хадгаллаа', "success", 2000);
                let result = await OrganizationService.getOrgWorkerFile(currentWorkerId);
                setWorkerFileData(result.data);
                fileRef.current = undefined;
            } else {
                notify('Алдаа гардаа', "error", 2000);
            }
        }
    };

    const savePortraitFile = async (workerId,size) => {
        if (portraitRef.current && workerId) {
            if (portraitRef.current[0].size > size) {
                notify(`Оруулсан зурагийн хэмжээ  ${size / 1000000}MB ээс их байна`, "warning", 3000);
                return 0;
            }
            let data = new FormData();
            if (portraitRef.current[0].size / 1024 > 500){
                await new Promise((resolve)=>{
                    new Compressor(portraitRef.current[0], {
                        quality: 0.8, 
                        type: 'image/jpeg',
                        convertTypes: ['image/png', 'image/webp'],
                        convertSize: 500000,
                        success: async (compressedResult) => {
                          const arrayBuffer = await compressedResult.arrayBuffer();
                          let compressedFile = new File([arrayBuffer], portraitRef.current[0].name, { type: portraitRef.current[0].type });
                          data.append("file", compressedFile);
                          resolve();
                        },
                      });
                })  
              }else{
                data.append("file", portraitRef.current[0])
              }
            const res = await FileServices.uploadFile(data, 6);
            const fileData = {
                file_id: res.data.id,
                worker_id: workerId,
                file_type_id: 6
            }
            let orgFileId = await FileServices.setFileWorker(fileData);
            return orgFileId
        } else return null
    }
    const saveWorkerPicFile = async (workerId,size) => {
        try {
            if (workerPicRef.current && workerId) {
                if (workerPicRef.current[0].size > size) {
                    notify(`Оруулсан зурагийн хэмжээ  ${size / 1000000}MB ээс их байна`, "warning", 3000);
                    return 0;
                }
                let data = new FormData();
                if (workerPicRef.current[0].size / 1024 > 500){
                    await new Promise((resolve)=>{
                        new Compressor(workerPicRef.current[0], {
                            quality: 0.8, 
                            type: 'image/jpeg',
                            convertTypes: ['image/png', 'image/webp'],
                            convertSize: 500000,
                            success: async (compressedResult) => {
                              const arrayBuffer = await compressedResult.arrayBuffer();
                              let compressedFile = new File([arrayBuffer], workerPicRef.current[0].name, { type: workerPicRef.current[0].type });
                              data.append("file", compressedFile);
                              resolve();
                            },
                          });
                    })
                }else{
                  data.append("file", workerPicRef.current[0])
                }
                const res = await FileServices.uploadFile(data, 5);
                const fileData = {
                    file_id: res.data.id,
                    worker_id: workerId,
                    file_type_id: 5
                }
                let orgFileId = await FileServices.setFileWorker(fileData);
                return orgFileId
            } else return null             
        } catch (error) {
            console.log("error", error)
        }finally{
            setLoader(false)
        }
    }
    const saveDate = async () => {
        if (!positionValue) return notify("Албан тушаал сонгоогүй байна", "warning", 3000);
        if (!workerStatusValue) return notify("Албан хаагчийн төлөв хоосон байна", "warning", 3000);

        if (dataIsEdit) {
            if (formData.first_name === null) return notify("Нэр оруулаагүй байна", "warning", 3000);
            if (formData.last_name === null) return notify("Овог оруулаагүй байна", "warning", 3000);
            /*        if (formData.reg_no===null) return notify("Регистэр оруулаагүй байна", "warning", 3000);
                    if (formData.email === null) return notify("Имэйл оруулаагүй байна", "warning", 3000);
                    if (formData.phone === null) return notify("Ажилын утас оруулаагүй байна", "warning", 3000);
                    if (formData.mobile === null) return notify("Гар утас оруулаагүй байна", "warning", 3000);*/
            if (workerStatusValue == []) return notify("Албан хаагчийн төлөв хоосон байна", "warning", 3000);
        } else {
            if (formData.first_name === '') return notify("Нэр оруулаагүй байна", "warning", 3000);
            if (formData.last_name === '') return notify("Овог оруулаагүй байна", "warning", 3000);
            /* if (formData.reg_no ==='') return notify("Регистэр оруулаагүй байна", "warning", 3000);
             if (formData.email === '') return notify("Имэйл оруулаагүй байна", "warning", 3000);
             if (formData.phone === '') return notify("Ажилын утас оруулаагүй байна", "warning", 3000);
             if (formData.mobile === '') return notify("Гар утас оруулаагүй байна", "warning", 3000);*/
            if (workerStatusValue == '') return notify("Албан хаагчийн төлөв хоосон байна", "warning", 3000);
        }
        let file_id;

        try {
            setLoader(true);
            /*if (fileRef.current) {
                file_id = await fileSubmit(fileRef.current, fileTypeValue, 5000000);
                if (file_id === 0) return;
            }*/
            let sendData;
            if (dataIsEdit) {
                let org_id;
                let structure_id;
                let state_structure_id;
                //console.log('stateStructureValue', stateStructureValue);
                if (structureValue) {
                    org_id = structureValue.org_id
                    structure_id = stateStructureValue.id
                    state_structure_id = stateStructureValue.state_structure_id
                } else {
                    org_id = orgValue.id
                    structure_id = orgStructureTmp.id
                    state_structure_id = structure.id
                }
                sendData = {
                    id: currentWorkerId,
                    first_name: formData.first_name,
                    last_name: formData.last_name,
                    email: formData.email === '' ? null : formData.email,
                    regno: formData.reg_no,
                    phone: formData.phone,
                    mobile: formData.mobile === '' ? null : formData.mobile,
                    org_id: org_id,
                    position_id: positionValue.id,
                    worker_status_id: workerStatusValue.id,
                    address: formData.address,
                    structure_id: structure_id,
                    state_structure_id: state_structure_id,
                    file_path: file_id,
                    is_visible: isVisible ? 1 : 0,
                    // parent_id: stateStructureValue.worker_id,
                }
                let portId = await savePortraitFile(currentWorkerId,20000000);
                let fileWorkerId = await saveWorkerPicFile(currentWorkerId,20000000);
                if (portId) {
                    sendData.portrait = portId.data.id
                }
                if (fileWorkerId) {
                    sendData.file_worker_id = fileWorkerId.data.id
                }
                const res = await OrganizationService.updateOrgWorker(sendData);
                if (econValue && typeof econValue[0] === 'number') {
                    const newInfo = {
                        economic_category_id: econValue,
                        worker_id: currentWorkerId
                    }
                    await OrganizationService.updateWorkerEconomicCategory(newInfo);
                }
                if (res.code === 200) {
                    notify('Амжилттай хадгаллаа', "success", 2000);
                    getWorker();

                    setIsOpen(false);
                } else {
                    notify('Алдаа гардаа', "error", 2000);
                }
            } else {
                sendData = {
                    first_name: formData.first_name,
                    last_name: formData.last_name,
                    email: formData.email === '' ? null : formData.email,
                    regno: formData.reg_no,
                    phone: formData.phone,
                    mobile: formData.mobile === '' ? null : formData.mobile,
                    org_id: structureValue.org_id,
                    position_id: positionValue.id,
                    worker_status_id: workerStatusValue.id,
                    address: formData.address,
                    structure_id: stateStructureValue.id,
                    state_structure_id: stateStructureValue.state_structure_id,
                    file_path: file_id,
                    is_visible: isVisible ? 1 : 0,
                    // parent_id: stateStructureValue.worker_id,
                }
                const res = await OrganizationService.setOrgWorker(sendData);
                if (econValue && typeof econValue[0] === 'number') {
                    const newInfo = {
                        economic_category_id: econValue,
                        worker_id: res.data.id
                    }
                    await OrganizationService.updateWorkerEconomicCategory(newInfo);
                }
                if (res.code === 200) {
                    notify('Амжилттай хадгаллаа', "success", 2000);
                    await setCurrentWorkerId(res.data.id)
                    let portId = await savePortraitFile(res.data.id,20000000);
                    let fileWorkerId = await saveWorkerPicFile(res.data.id,20000000);
                    if (portId) {
                        res.data.portrait = portId.data.id
                        await OrganizationService.updateOrgWorker(res.data);
                    }
                    if (fileWorkerId) {
                        res.data.file_worker_id = portId.data.id
                        await OrganizationService.updateOrgWorker(res.data);
                    }
                    setIsOpen(false);
                    getWorker();
                    portraitRef.current = undefined;
                    workerPicRef.current = undefined;
                    setLoader(false);
                } else {
                    setLoader(false);
                    notify('Алдаа гардаа', "error", 2000);
                }
            }
        } catch (e) {
            setLoader(false);
            notify('Алдаа гардаа и-иэйл хаяг эсвэл гар утас давхардаж байна', "error", 2000);
            console.log(e);
        }
    }

    const onValueChanged = (args) => {
        setIsVisible(args.value);
    }
    const phoneEditorOptions = {
        mask: '0000-0000',
        maskRules: {
            X: /[02-9]/,
        },
    };
    const buttonOptions = {
        text: dataIsEdit ? 'Мэдээлэл засах' : 'Албан хаагч нэмэх',
        type: 'default',
        useSubmitBehavior: true,
        onClick: saveDate
    };
    const orderRender = (data) => {
        return <div style={{color: "#171010"}}>{pageIndex * limit + data.rowIndex + 1}</div>;
    }
    const onExporting = (e) => {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Main sheet');

        exportDataGrid({
            component: e.component,
            worksheet,
            autoFilterEnabled: true,
        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer], {type: 'application/octet-stream'}), 'Worker.xlsx');
            });
        });
        e.cancel = true;
    }
    //Column custom renderings
    const statusRender = (data) => {
        if (data.displayValue && data.displayValue === "Шинэ") {
            return <div style={{
                backgroundColor: '#50CB93',
                width: '80%',
                color: 'white',
                borderRadius: '3px',
                cursor: 'pointer',
                margin: 'auto',
                textAlign: 'center'
            }}>Шинэ</div>
        } else {
            return <div style={{
                backgroundColor: '#FF616D',
                width: '80%',
                color: 'white',
                borderRadius: '3px',
                cursor: 'pointer',
                margin: 'auto',
                textAlign: 'center'
            }}>Хуучин</div>
        }
    }
    const columnInfo = (data) => {
        setFileName(data.data.file_name);
        setFileExtention(data.data.file_extention);
        setFilePopUpModal(true);

    }
    const renderGridFile = (data) => {
        return data.text && <div style={{textAlign: 'center'}}>
            {data.data.file_extention === 'application/pdf' || data.data.file_extention === 'image/jpeg' || data.data.file_extention === 'image/jpg' || data.data.file_extention === 'image/png'
                ?
                <Button
                    //  style={{width: "100px", height: '25px'}}
                    icon={strategy_icon}
                    stylingMode="text"
                    style={{
                        width: 'auto',
                        height: '35px',
                        opacity: 1
                    }}
                    onClick={() => {
                        columnInfo(data)
                    }}
                />
                :
                <a href={urlServer + '/api/file/documents/' + data.data.file_name} target='_blank' rel='noreferrer'>Файл
                    татах</a>
            }

        </div>
    }
    const onCellPreparedHandler = (e) => {
        if (e.rowType === 'header') {
            e.cellElement.wordWrapEnabled = true;
            e.cellElement.style.fontFamily = 'Montserrat'
            e.cellElement.style.fontWeight = 'semibold'
            e.cellElement.style.color = "#15186A"
            e.cellElement.style.backgroundColor = "#EEF0F4"
        }
        if (e.rowType === 'data') {
            e.cellElement.style.fontFamily = 'Montserrat'
            e.cellElement.style.fontWeight = 'semibold'
            e.cellElement.style.color = "#000000"
        }
    }
    function cellRender(data) {
        return data.value ?
            <React.Fragment>
                <img className={styles.customImg} style={{
                    //marginTop: "1rem",
                    //marginBottom: "1rem",
                    height: "4rem",
                    width: "4rem",
                    objectFit: "cover"
                }}  src={urlServer + '/api/file/documents/' + data.value + `?height=100`} />
            </React.Fragment> : 'Зураггүй'
    }
    const handleInputText = async (event) => {
        if (event.event !== undefined) {
            setSearchText(event.value);
        }
    }
    const removeData = async(dataId) => {
        setLoader(true)
        const data = workerFileData.filter(item=>item.id ===dataId.key)
        if(data.length > 1){
           const res = await OrganizationService.removeOrgWorkerFile(dataId.data.file_worker_id) 
           const result = await OrganizationService.getOrgWorkerFile(dataId.data.worker_id)
            if(res.code === 200){
                setLoader(false)
                setWorkerFileData(result.data);
                notify(res.message)
            } else{
                setLoader(false)
                notify('Устгахад алдаа гарлаа')
            }
        } else {
            const res = await OrganizationService.archiveOrgWorkerFile(dataId.data.file_worker_id)
           const result = await OrganizationService.getOrgWorkerFile(dataId.data.worker_id)
            if(res.code === 200){
                setLoader(false)
                setWorkerFileData(result.data)
                notify(res.message)
            } else {
                setLoader(false)
                notify('Алдаа гарлаа')
            }
        }
    }

    const buttonRender = (dataId)=>{
        return <MdDelete size={18} style={{cursor: 'pointer'}} color='#172B4D' onClick={()=>removeData(dataId)}/> 
    } 
    return (
        <React.Fragment>
            <LoadPanelComponent position={workerPosition} visible={loader}/>
            <div className="card">
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <OrganizationSelectComponent
                    selectedData={(d) => {
                        setSelectedData(d)
                        setSelectedPosition()
                    }}
                    selectedYear={(d) => d}
                    showClearButton={false}
                    showYear={false}
                    hideLabel={true}
                    labelMode = {'floating'}
                    width = {600}
                    defaultId = {user.organization.id}
                />
                <OrganizationPositionSelect 
                    selectedData={(d)=>setSelectedPosition(d)}
                    showClearButton={false}
                    showYear={false}
                    hideLabel={true}
                    labelMode = {'floating'}
                    width={400}
                    selectedOrgId={selectedData?.orgId?.[0]}
                    />
                </div>

                <div className="col-12" style={{marginTop: 30}}>
                    <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: 10,margin:10}}>
                        <div style={{display: 'flex', justifyContent: 'flex-start',flexDirection:'row'}}>
                        {/*<form onSubmit={(e)=>{
                                    //e.preventDefault();
                                    getWorker();
                                    }} style={{display: "flex"}}>

                        </form>*/}
                            <TextBox width={500} name="search" value={searchText} onValueChanged={handleInputText} placeholder="Нэр,овог,утас,и-мэйл хаяг оруулах">
                            </TextBox>
                            <Button icon="search" text="Хайх" type="default" style={{marginLeft:10}} onClick={() => {getWorker()}}/>
                        </div>

                        <Button icon="user" text="Албан хаагч нэмэх" type="default"
                                onClick={() => {
                                    const data = {
                                        org_name: '',
                                        structure_name: '',
                                        organization: '',
                                        department: '',
                                        dep_name: '',
                                        position: '',
                                        last_name: '',
                                        first_name: '',
                                        reg_no: '',
                                        email: '',
                                        phone: '',
                                        mobile: '',
                                        file_type: '',
                                        address: '',
                                        worker_status_id: '',
                                    }
                                    setFormData(data);
                                    //setSelectedStructureList(organizationList)
                                    //setSelectedOrgStructure(null)
                                    //setPositionList(null)
                                    setCurrentWorkerId(null)
                                    //setWorkerStatus(null)
                                    setIsOpen(true);
                                    setDataIsEdit(false);
                                }}/>
                    </div>
                    {
                        workerList &&
                        <DataGrid id="employees" dataSource={workerList} keyExpr="wid" ref={dataGridRef}
                                  columnAutoWidth={false}
                                   repaintChangesOnly={true}
                                  //onOptionChanged={handleOptionChange}
                                  allowColumnResizing={true}
                                  //wordWrapEnabled={true}
                                  remoteOperations={true}
                                  onExporting={onExporting}
                                  showBorders={true}
                                  showColumnLines={true}
                                  showRowLines={true}
                                  onCellPrepared={onCellPreparedHandler}
                                  >
                            <Scrolling mode="standard"/>
                            <Selection mode="single"/>
                            <GroupPanel visible={true} emptyPanelText={"Хүссэн баганаа дээш зөөн бүлэглэх боломжтой"}/>
                            <Grouping autoExpandAll={false}/>
                           {/* <HeaderFilter visible={false} allowSearch={true} />*/}
                            {/* <FilterRow visible={true}  /> */}
                            <Column
                                caption='№'
                                cssClass="custom"
                                width={50}
                                alignment="center"
                                allowEditing={false}
                                cellRender={orderRender}
                            />
                            <Column dataField="state_structure_name" caption="Байгууллага"/>
                            <Column dataField="org_structure_name" caption="Алба хэлтэс"/>
                            <Column dataField="org_position_name" caption="Албан тушаал"/>
                            <Column dataField="worker_file_name" allowSorting={false} cellRender={cellRender} caption="Зураг" width={"5rem"}/>
                            <Column dataField="last_name" caption="Овог" width={120}/>
                            <Column dataField="first_name" caption="Нэр" width={120}/>
                            <Column dataField="worker_status_name"  caption="Төлөв"/>
                            <Column dataField="email" caption="Имэйл"/>
                            <Column dataField="phone" caption="Ажлын утас" width={100}/>
                            <Column dataField="mobile" caption="Гар утас" width={100}/>
                            <Editing
                                allowUpdating={true}
                                allowDeleting={true}
                                allowAdding={false}
                                mode="row"
                                useIcons={true}
                                texts={{
                                    cancelAllChanges: 'Болих',
                                    cancelRowChanges: 'Болих',
                                    confirmDeleteMessage: 'Энэ мэдээллийг устгах уу?',
                                    confirmDeleteTitle: 'Баталгаажуулах',
                                    deleteRow: 'Устгах',
                                    editRow: 'Өөрчлөх',
                                    saveAllChanges: 'Хадгалах',
                                    saveRowChanges: 'Хадгалах',
                                    undeleteRow: 'Буцаах',
                                    validationCancelChanges: 'Баталгаажуулах болих',
                                }}
                            />
                            <Column type="buttons" fixedPosition="right" caption='Үйлдэл'>
                                <FormButton hint="Мэдээлэл Засах" name="edit" visible={true} onClick={onClickEdit}/>
                                <FormButton hint="Анкет засах" icon="user" visible={true} onClick={showResume}/>
                                <FormButton hint="Файл" icon="doc" visible={true} onClick={onFileEvent}/>
                            </Column>
                            <Export enabled={true} allowExportSelectedData={false}/>
                            <Paging
                                defaultPageSize={limit}
                                onPageSizeChange={(e) => {setLimit(e)}}
                                onPageIndexChange={(e) => {setPageIndex(e)}}
                            />
                            <Pager
                                visible={true}
                                showInfo={true}
                                allowedPageSizes={[5,10,25,50]}
                                showPageSizeSelector={true}
                                showNavigationButtons={true}
                                infoText={`${pageIndex + 1}-р хуудас (нийт {1} хуудас)  (${limit} өгөгдөл харуулж байна)` + ` Нийт серверийн {2} өгөгдөл`}
                            />
                        </DataGrid>
                    }

                </div>
            </div>

            <Popup
                visible={isOpen}
                title={dataIsEdit ? 'Мэдээлэл засах' : 'Албан хаагч нэмэх'}
                width="70%"
                height="100%"
                onHiding={() => setIsOpen(false)}
                dragEnabled={true}
                showTitle={true}
                hideOnOutsideClick={true}
            >
                <ScrollView height="100%" width="100%">
                    <div className="widget-container">
                        <div style={{display: 'flex', flex: 1, flexDirection: 'row', alignItems: 'center'}}>
                            {formData.portrait_file_name && <div>
                                <div>Портрайт зураг</div>
                                <img className={styles.customImg} alt="" style={{
                                    marginTop: "1rem",
                                    marginBottom: "1rem",
                                    height: "7rem",
                                    width: "7rem",
                                    objectFit: "cover"
                                }} src={urlServer + '/api/file/documents/' + formData.portrait_file_name}/></div>}
                            {formData.worker_file_name && <div style={{marginLeft: 50}}>
                                <div>Албан хаагчийн зураг</div>
                                <img className={styles.customImg} alt="" style={{
                                    marginTop: "1rem",
                                    marginBottom: "1rem",
                                    borderRadius: "50%",
                                    height: "7rem",
                                    width: "7rem",
                                    objectFit: "cover"
                                }} src={urlServer + '/api/file/documents/' + formData.worker_file_name}/></div>}
                        </div>
                        {
                            isOpen &&
                            <Form formData={formData}>
                                <FormItem itemType={"group"} colCount={1} colSpan={2} caption="Ерөнхий мэдээлэл">
                                    <FormItem dataField="structure_name" isRequired={true} render={selectOrg}>
                                        <Label text="Ерөнхий газар"/>
                                        <RequiredRuleForm message="Ерөнхий газар сонгоно уу"/>
                                    </FormItem>
                                    <FormItem dataField="organization" isRequired={true} render={selectStructure}>
                                        <Label text="Байгууллага"/>
                                        <RequiredRuleForm message="Байгууллага сонгоно уу"/>
                                    </FormItem>
                                    <FormItem dataField="department" isRequired={true} render={selectOrgStructure}>
                                        <Label text="Хэлтэс"/>
                                        <RequiredRuleForm message="Хэлтэс сонгоно уу"/>
                                    </FormItem>
                                    <FormItem itemType={"group"} colCount={2} colSpan={2}>
                                        <FormItem dataField="position" render={selectPosition}>
                                            <Label text="Албан тушаал"/>
                                            <RequiredRuleForm message="Албан тушаал сонгоно уу"/>
                                        </FormItem>
                                        <FormItem dataField="worker_status_id" render={selectWorkerStatus}>
                                            <Label text="Албан хаагчийн төлөв"/>
                                            <RequiredRuleForm message="Албан хаагчийн төлөв сонгоно уу"/>
                                        </FormItem>

                                        <FormItem dataField="last_name" isRequired={true}
                                                  editorOptions={{placeholder: "Овог"}}>
                                            <Label text="Овог"/>
                                            <RequiredRuleForm message="Овог оруулна уу"/>
                                        </FormItem>
                                        <FormItem dataField="first_name" isRequired={true}
                                                  editorOptions={{placeholder: "Нэр"}}>
                                            <Label text="Нэр"/>
                                            <RequiredRuleForm message="Нэр оруулна уу"/>
                                        </FormItem>
                                        <FormItem dataField="reg_no" isRequired={true}
                                                  editorOptions={{placeholder: "Регистрийн дугаар"}}>
                                            <Label text="Регистрийн дугаар"/>
                                            {/*  <RequiredRuleForm message="Регистрийн дугаар оруулна уу"/>*/}
                                        </FormItem>
                                        <FormItem dataField="email" isRequired={true}
                                                  editorOptions={{placeholder: "Имэйл хаяг"}}>
                                            <Label text="Имэйл"/>
                                            <EmailRule message="Имэйл хаяг буруу байна!"/>
                                            {/*  <RequiredRuleForm message="Имэйл хаяг оруулна уу"/>*/}
                                        </FormItem>
                                        <FormItem dataField="phone" isRequired={true}
                                                  editorOptions={phoneEditorOptions}>
                                            <Label text="Ажлын утас"/>
                                            {/*    <RequiredRuleForm message="Ажлын утасны дугаар оруулна уу"/>*/}
                                            <PatternRule
                                                pattern="^[0-9]+$"
                                                message="Зөвхөн тоо оруулна уу"
                                            />
                                        </FormItem>
                                        <FormItem dataField="mobile" isRequired={true}
                                                  editorOptions={phoneEditorOptions}>
                                            <Label text="Гар утас"/>
                                            {/*  <RequiredRuleForm message="Гар утасны дугаар оруулна уу"/>*/}
                                        </FormItem>
                                        <FormItem dataField="isVisible" isRequired={true}
                                                  editorOptions={{placeholder: "Үндсэн хуудас дээр харуулах"}}>
                                            <Label text="Үндсэн хуудас дээр харуулах эсэх"/>
                                            <CheckBox
                                                tabIndex={-1}
                                                name="or1"
                                                text={isVisible ? 'Харуулна' : 'Харуулахгүй'}
                                                value={isVisible}
                                                onValueChanged={onValueChanged}
                                            />
                                        </FormItem>
                                    </FormItem>
                                    <FormItem itemType={"group"} colCount={1} colSpan={1}
                                              caption="Төсвийн бусад мэдээлэллийн ангилал">
                                        <FormItem dataField="workerEcons" render={selectEconomic}>
                                            <Label text="Төсвийн бусад мэдээлэл"/>
                                            <RequiredRuleForm message="Econ сонгоно уу"/>
                                        </FormItem>
                                    </FormItem>
                                    <FormItem itemType={"group"} colCount={4} colSpan={2} caption="Файл оруулах">
                                        <FormItem dataField="portrait.file_name" render={PortraitFile}>
                                            <Label text="Portrait зураг" visible={false}/>
                                        </FormItem>
                                        <FormItem dataField="portrait.file_name" render={WorkerPicFile}>
                                            <Label text="Албан хаагчийн зураг" visible={false}/>
                                            {/*<RequiredRuleForm message="Ажилтны зураг сонгоно уу"/>*/}
                                        </FormItem>
                                    </FormItem>
                                </FormItem>
                                <ButtonItem horizontalAlignment="right"
                                            buttonOptions={buttonOptions}
                                />
                            </Form>
                        }
                    </div>
                </ScrollView>
            </Popup>

            <Popup
                minWidth={'80%'}
                minHeight={'80%'}
                visible={showResumeDialog}
                title={`Төрийн албан хаагчийн анкет`}
                onHiding={() => setShowResumeDialog(false)}
                resizeEnabled={true}
                dragEnabled={true}
                showTitle={true}
                hideOnOutsideClick={true}
            >
                <ScrollView width='100%' height='100%'>
                    <div>
                        {showResumeDialog && vOrgId &&
                            <OrgFormRenderer workerId={vOrgId}></OrgFormRenderer>}
                    </div>
                </ScrollView>

            </Popup>

            <Popup
                minWidth={'80%'}
                minHeight={'80%'}
                visible={togglerFile}
                title={`Албан хаагчтай холбоотой файл хавсралт`}
                onHiding={() => setTogglerFile(false)}
                resizeEnabled={true}
                dragEnabled={true}
                showTitle={true}
                hideOnOutsideClick={true}
            >
                <ScrollView width='100%' height='100%'>
                    <div style={{display: 'flex', flex: 1, flexDirection: 'row', marginRight: 10, marginBottom: 20}}>
                        <div style={{width: 300}}>
                            <SelectBox items={fileType} displayExpr='name' valueExpr="id"
                                       placeholder="Сонгох"
                                       value={fileTypeValue}
                                       onValueChanged={onChangeFileType}
                            />
                        </div>
                        <div style={{width: 150, marginLeft: 10}}>
                            {togglerFile && <FileUploader
                                onValueChanged={fileChangeHandler}
                                selectButtonText="Файл сонгох"
                                labelText=""
                                accept="*"
                                uploadMode="useForm"
                            />}

                        </div>
                        <div style={{width: 200, marginLeft: 10}}>
                            <Button icon="save" onClick={() => fileSubmit(currentWorkerId, fileTypeValue, 20000000)}
                                    text="Файл хадгалах"/>
                        </div>
                    </div>
                    <DataGrid
                        dataSource={workerFileData}
                        keyExpr="id"
                        wordWrapEnabled={true}
                    > <Scrolling mode="standard"/>
                        <GroupPanel visible={true} emptyPanelText={"Хүссэн баганаа дээш зөөн бүлэглэх боломжтой"}/>
                        <Grouping autoExpandAll={true}/>
                        <HeaderFilter visible={true} allowSearch={true}/>
                        <Column caption="Албан хаагчийн нэр" dataField="name"/>
                        <Column caption="Төрөл" dataField="file_type_name" groupIndex={0}/>
                        <Column caption="Файлын нэр" dataField="file_name"/>
                        <Column
                            dataField="file_name"
                            caption="Файл"
                            editCellTemplate={"file_name"}
                            cellRender={renderGridFile}
                        />
                        <Column caption="Файлын өргөтгөл" dataField="file_extention"/>
                        <Column caption="Файлын хэмжээ/MB/" dataField="file_size"/>
                        <Column caption="Үүссэн огноо" dataField="createdAt" dataType="date"
                                format="yyyy-MM-dd hh:mm:ss"/>
                        <Column caption="Төлөв" dataField="statusname" cellRender={statusRender}/>
                        <Column type='buttons' cellRender={buttonRender}/>
                        <Paging enabled={true}/>
                    </DataGrid>
                </ScrollView>

            </Popup>
            {fileName &&
                <Popup
                    width={1200}
                    maxWidth={'100%'}
                    minHeight={'100%'}
                    height={'100%'}
                    title={fileName && fileName}
                    visible={filePopUpModal}
                    onHiding={() => {
                        setFilePopUpModal(false)
                    }}
                    dragEnabled={false}
                    hideOnOutsideClick={true}
                >
                    {
                        fileExtention == 'application/pdf' ? <object width="100%" height="100%"
                                                                     data={urlServer + '/api/file/documents/' + fileName}
                                                                     type="application/pdf"/>
                            :
                            <ScrollView> <img
                                src={urlServer + '/api/file/documents/' + fileName}
                                width={'100%'}/> </ScrollView>

                    }

                </Popup>}

            <div>
                <Popup
                    visible={popupVisible}
                    onHiding={() => setPopupVisible(false)}
                    dragEnabled={false}
                    hideOnOutsideClick={true}
                    showTitle={true}
                    title='Хавсралт файлууд'
                    width={1000}
                    height={450}
                >
                    <div style={{display: 'flex', alignItems: 'flex-end', flexDirection: 'column'}}>
                        <Button
                            height={35}
                            icon='refresh'
                            type="success"
                            style={{marginLeft: 'auto', backgroundColor: '#2C2E43', color: 'white'}}
                            onClick={() => {
                                setUpdateFilesLoad(true);
                                PopupInfo(columnData);
                                // getFileList();
                                setTimeout(function () {
                                    setUpdateFilesLoad(false)
                                }, 300)
                            }}
                            disabled={updateFilesLoad}
                        >
              <span className="dx-button-text">
                <LoadIndicator style={{position: 'absolute', marginTop: 0, marginLeft: 100, height: 20, width: 40}}
                               visible={updateFilesLoad}/>
                <i className="dx-icon-refresh" style={{marginRight: '0.4rem'}}/>
                <span>Файлуудын жагсаалтыг шинэчлэх</span>
              </span>
                        </Button>

                        <div style={{width: '99.9%', marginTop: '0.6rem'}}>
                            <hr style={{
                                border: 0,
                                height: '1px',
                                background: '#1453B5',
                                backgroundImage: 'linear-gradient(to right, #ccc, #333, #ccc)',
                            }}/>
                        </div>
                    </div>
                    <div>
                        {fileArray.length > 0 &&
                            fileArray.map(data => {
                                return (
                                    <div>
                                        <div style={{
                                            marginTop: 5,
                                            display: 'flex',
                                            flexDirection: 'row',
                                            marginBottom: '1rem'
                                        }}
                                             key={data.file_path}>
                                            <div>
                                                <div
                                                ><h5>{moment(data.updatedAt).format("YYYY-MM-DD HH:mm:ss")}</h5></div>
                                                <span key={data.id}>{`Файлын нэр - "${data.file_path}"`}</span>
                                            </div>
                                            <div style={{marginLeft: 'auto', display: 'flex', flexDirection: 'row'}}>
                                                <a download
                                                   href={window.location.origin + '/api/proposal/file/' + data.file_path}
                                                   target="_blank" rel="noreferrer">
                                                    <Button
                                                        height={35}
                                                        icon='download'
                                                        type="success"
                                                        text='Татах'
                                                        style={{margin: '0 6px 0 6px',}}
                                                    >

                                                    </Button>
                                                </a>
                                                {/*{this.state.isEdit ?*/}
                                                <Button
                                                    height={35}
                                                    icon='remove'
                                                    type='success'
                                                    text='Устгах'
                                                    style={{backgroundColor: '#D54C4C'}}
                                                />
                                            </div>
                                        </div>
                                        <hr style={{
                                            border: 0,
                                            height: '1px',
                                            background: '#1453B5',
                                            backgroundImage: 'linear-gradient(to right, #ccc, #333, #ccc)',
                                        }}/>
                                    </div>
                                )
                            })
                        }
                    </div>
                </Popup>
            </div>

        </React.Fragment>
    )
}


import request from '../../shared/lib/request'

function getInitialData() {
    return request({
        url:    `/budgetCalculation/initialData`,
        method: `GET`
    })
}

function getBudgetCalculationEconomyList(budgetCalcType) {
    return request({
        url:    `/budgetCalculation/getEconomyList?budgetCalcType=${budgetCalcType}`,
        method: `GET`
    })
}

function getEconomyParent() {
    return request({
        url:    `/budgetCalculation/economy/parent/get`,
        method: `GET`
    })
}

function getBudgetCalculationData(dynamicTableId, org_id) {
    return request({
        url:    `/budgetCalculation/data/get/${dynamicTableId}?org_id=${org_id}`,
        method: `GET`
    })
}

function addBudgetCalculationData(data) {
    return request({
        url:    `/budgetCalculation/data/add`,
        data:   data,
        method: `POST`
    })
}

function getBudgetCalcHistory(econ_category_id) {
    return request({
        url:    `/budgetCalculation/history/get/${econ_category_id}`,
        method: `GET`
    })
}

function getBudgetSumValues() {
    return request({
        url:    `/budgetCalculation/sumValues/get`,
        method: `GET`
    })
}

function getBudgetCalculationStat() {
    return request({
        url:    `/budgetCalculation/stats`,
        method: `GET`
    })
}

function getBudgetCalculationTable() {
    return request({
        url:    `/budgetCalculation/table/list`,
        method: `GET`
    })
}

function getBudgetCalcByMonth() {
    return request({
        url:    `/budgetCalculation/result/month`,
        method: `GET`
    })
}

function getResultToBudgetList() {
    return request({
        url:    `/budgetCalculation/result/toBudget/list`,
        method: `GET`
    })
}

function getEconCategoryList() {
    return request({
        url:    `/budgetCalculation/econCategory/list`,
        method: `GET`
    });
}

function getBudgetCalcStatusList() {
    return request({
        url:    `/budgetCalculation/status/list`,
        method: `GET`
    })
}

function getBudgetCalcCalendar() {
    return request({
        url:    `/budgetCalculation/calendar`,
        method: `GET`
    })
}

function toBudget() {
    return request({
        url:    `/budgetCalculation/toBudget`,
        method: `POST`
    })
}

function getDynamicTableCheckEcon() {
    return request({
        url:    `/budgetCalculation/getDynamicTableCheckEcon`,
        method: `GET`
    })
}

function getProjectCheck() {
    return request({
        url:    `/budgetCalculation/project/check`,
        method: `GET`
    })
}

const BudgetService = {
    getInitialData,
    getBudgetCalculationEconomyList,
    getEconomyParent,
    getBudgetCalculationData,
    addBudgetCalculationData,
    getBudgetCalcHistory,
    getBudgetSumValues,
    getBudgetCalculationStat,
    getBudgetCalculationTable,
    getBudgetCalcByMonth,
    getResultToBudgetList,
    getEconCategoryList,
    getBudgetCalcStatusList,
    getBudgetCalcCalendar,
    toBudget,
    getDynamicTableCheckEcon,
    getProjectCheck
}

export default BudgetService;

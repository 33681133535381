import React, { useContext, useEffect, useState } from "react"
import ProposalService from "../../../services/api/proposal"
import _ from "lodash"
import { CURRENT_YEAR } from "../../../shared/constants"
import { numberWithCommas } from "../../../util"
import LoadPanelComponent from "../../budget/components/loadPanel"
import { UserContext } from "../../../hooks/UserContext"

export default function ProposalHistory({ currentProposal, crntPrps, year }) {
  useEffect(() => {
    getData()
    setupHistory()
  }, [currentProposal, year])
  const [budgetHistory, setBudgetHistory] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [budgetStatus, setBudgetStatus] = useState("")
  const [budgetCalculation, setBudgetCalculation] = useState({})
  const [budgetStatusId, setBudgetStatusId] = useState()
  //const current_year = CURRENT_YEAR
  //const dataGridPositionId = { of: "#proposalListGrid" }
  const budgetCard = { of: "#budgetCard" }
  const historycheck = { of: "#historyLoad" }
  const [amtDiff, setAmtDiff] = useState(0)
  const { user } = useContext(UserContext)
  const setupHistory = async () => {
    getHistoryLoad(currentProposal)
    // console.log("check currentProposal: ", currentProposal);
  }
  const getHistoryLoad = async (data) => {
    const res = await ProposalService.getHistory(data)
    //let ac = []
    let tt = res.data.sort(function (a, b) {
      let t = new Date(b.ognoo) - new Date(a.ognoo)
      return t
    })
    setBudgetHistory(tt)
  }
  const getData = async () => {
    setIsLoading(true)
    const res = await ProposalService.getProjectProgress(
      false,
      // crntPrps.budget_year
      year
    )
    // console.log("readsaf", res)
    // console.log("user", user.organization);
    if (res.data.length > 0) {
      setBudgetStatus(res.data[0].budget_project_status_name)
      setBudgetStatusId(res.data[0].budget_project_status_id)
      // setBudgetTypeId(res.data[0].org_budget_type_id)
    }
    setIsLoading(false)
    let bc = _.groupBy(res.data || [], "econ_category_code_1")
    //console.log('bc', bc);
    //Zardal amount
    let zardalAmt = _.sumBy(bc[2], (r) => {
      return r.amount * 1
    })
    //Orlogo amount
    let orlogoAmt = _.sumBy(bc[1], (r) => {
      return r.amount * 1
    })
    //EhUusver amount
    let ehUusverAmt = _.sumBy(bc[3], (r) => {
      return r.amount * 1
    })
    setAmtDiff(orlogoAmt + ehUusverAmt - zardalAmt)
    setBudgetCalculation(bc)
  }

  const colorPalettes = ["#3DAA75", "#f66160", "#5E6DD0", "#000000"]

  return (
    <div>
      <LoadPanelComponent position={budgetCard} visible={isLoading} />
      <LoadPanelComponent position={historycheck} visible={isLoading} />

      <div className="card" id="budgetCard" style={{ margin: 0}}>
        <div style={{ display: "flex", gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))", alignItems: "center", justifyContent: "space-between", padding: "1rem 1rem" }}>
          <div
            style={{
              fontSize: "1rem",
              fontWeight: "700",
              color: "black",
              display: "flex",
              textAlign: "left",
            }}
          >
            ТӨСЛИЙН НЭГТГЭЛ
          </div>
          <div
            style={{
              background: "#EEF0F4",
              borderRadius: "4px",
              gap: ".5rem",
              display: "flex",
              fontSize: "1rem",
              justifyContent: "center",
              padding: ".25rem"
            }}
          >
            <span>Зөрүү:</span>
            <span style={{ fontWeight: 700 }}>{numberWithCommas(amtDiff)}</span>
          </div>
        </div>
        {/* <div className="card">*/}
        {(budgetStatus === "" || budgetStatus === null) && !isLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              fontSize: "1.1rem",
              color: "green",

              padding: "20px",
            }}
          >
            {" "}
            Шинэ{" "}
          </div>
        ) : (
          <div style={{ textAlign: "left", padding: ".25rem 0.5rem" }}>
            {Object.keys(budgetCalculation).map((o, index) => {
              let sumAmount = 0
              return (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: "1rem",
                  }}
                  key={index}
                >
                  <div
                    style={{
                      fontSize: ".875rem",
                      color: `${colorPalettes[index]}`,
                      zIndex: 1,
                      width: "fit-content",
                      alignSelf: "flex-end",
                      fontWeight: "700",
                      transform: "translateY(1rem) translateX(-1rem)",
                      background: "white",
                      textAlign: "right",
                      textTransform: "uppercase",
                      // padding: "0 .5rem",
                    }}
                  >
                    {budgetCalculation[o][0].econ_category_name_1}
                  </div>

                  <div
                    className="card"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      padding: "1.5rem .5rem",
                      boxShadow: `0 0 10px #ddd` 
                    }}
                  >
                    {budgetCalculation[o].map((a, id) => {
                      sumAmount += a.amount * 1
                      // if (a.budget_project_status_id === 1) {
                      return (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginTop: "20px",
                          }}
                          key={id}
                        >
                          <span
                            style={{
                              textAlign: "right",
                              paddingRight: 30,
                              fontSize: "14px",
                              flex: 5,
                              color: "#33373B",
                            }}
                          >
                            {a.econ_category_name_2}:
                          </span>
                          <span
                            style={{
                              fontSize: "14px",
                              flex: 4,
                              color: "#33373B",
                            }}
                          >
                            {a.econ_category_code_1 !== "4"
                              ? numberWithCommas(a.amount) || 0
                              : parseInt(a.amount)}
                          </span>
                        </div>
                      )
                      // }
                    })}
                    {
                      budgetCalculation[o].every((b) => b.econ_category_code_1 !== '4') &&
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginTop: "20px",
                        }}
                      >
                        <span
                          style={{
                            textAlign: "right",
                            paddingRight: 20,
                            fontWeight: "bold",
                            color: colorPalettes[index],
                            flex: 5,
                          }}
                        >
                          Нийт:
                        </span>
                        <span
                          style={{
                            fontWeight: "bold",
                            color: colorPalettes[index],
                            flex: 4,
                          }}
                        >
                          {budgetCalculation[o].every(
                            (b) => b.econ_category_code_1 === "4"
                          )
                            ? parseInt(sumAmount)
                            : numberWithCommas(sumAmount)}
                          {/* {numberWithCommas(sumAmount)} */}
                        </span>
                      </div>
                    }
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      {budgetCalculation.econ_category_code === !4 && (
                        <span
                          style={{
                            paddingLeft: 40,
                            marginTop: "20px",
                            fontWeight: "bold",
                            color: "#8c8c8c",
                            flex: 6,
                          }}
                        >
                          нийт:
                        </span>
                      )}
                      {budgetCalculation.econ_category_code === !4 && (
                        <span
                          style={{
                            marginTop: "20px",
                            fontWeight: "bold",
                            color: "black",
                            flex: 3,
                          }}
                        >
                          {budgetCalculation[o].budget_project_status_id !== 3
                            ? numberWithCommas(sumAmount)
                            : parseInt(sumAmount)}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        )}
      </div>
    </div>
  )
}

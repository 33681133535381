import request from '../../shared/lib/request'

const getAllNews = (type, current, size) => {
    return request({
        url: `/news/getAllNews?type=${type}&current=${current}&size=${size}`,
        method: 'GET',
    })
}
const getAllPublications = (current, size, orgId) => {
    return request({
        url: `/news/getAllPublications?&current=${current}&size=${size}&orgId=${orgId}`,
        method: 'GET',
    })
}
const getAllNewsForAdmin = (current, size, searchText) => {
    return request({
        url: `/news/getAllNewsForAdmin?current=${current}&size=${size}&searchText=${searchText}`,
        method: 'GET',
    })
}
const getOrgNewsSay = (id) => {
    return request({
        url: `/news/getOrgNewsSay/${id}`,
        method: 'GET',
    })
}
const getNewsById = (id) => {
    return request({
        url: `/news/getNewsById/${id}`,
        method: 'GET',
    })
}
const getNewsFileNames = (id) => {
    return request({
        url: `/news/getNewsFileNames/${id}`,
        method: 'GET',
    })
}
const updateNewsById= (body, id)=>{
    return request({
        url: `/news/updateNews/${id}`,
        method: 'POST',
        data: body
    })
}
const deleteNewsById=(id)=>{
    return request({
        url: `/news/deleteNews/${id}`,
        method: 'POST',
    })
}

const addNews = (body) => {
    return request({
        url: '/news/addNews',
        method: 'POST',
        data: body,
    })
}
const addOrgNewsSay = (body) => {
    return request({
        url: '/news/addOrgNewsSay',
        method: 'POST',
        data: body,
    })
}
const addNotification = (body) => {
    return request({
        url: '/news/addNotification',
        method: 'POST',
        data: body,
    })
}

const getCrudData = () => {
    return request({
        url: '/generalInfo/crudSelect',
        method: 'GET',
    })
}
const getCrudDetail = (id) => {
    return request({
        url: `/generalInfo/crudSelectDetail/${id}`,
        method: 'GET',
    })
}

const updatePolicyDesc = (key,body) => {
    return request({
        url: `/generalInfo/updatePolicyDesc/${key}`,
        method: 'PUT',
        data: body,
    });
}
const updateNotification = (body) => {
    return request({
        url: `/news/updateNotification`,
        method: 'POST',
        data: body,
    });
}
const deleteNotificationById= (id)=>{
    return request({
        url: `/news/deleteNotificationById/${id}`,
        method: "POST"
    })
}

const newsServices = {
    getAllNews,
    addNews, 
    getCrudData,
    getCrudDetail,
    updatePolicyDesc,
    updateNewsById,
    deleteNewsById,
    getNewsFileNames,
    addNotification,
    updateNotification,
    deleteNotificationById,
    getNewsById,
    getAllNewsForAdmin,
    getAllPublications,
    getOrgNewsSay,
    addOrgNewsSay,
}

export default newsServices;

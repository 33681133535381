import React, { useState, useEffect, useContext } from "react";
import BudgetService from "../../../../services/api/budget";
import DataGrid, { Column, ColumnChooser, Export, Grouping, GroupItem, Item, Summary, Toolbar, TotalItem } from "devextreme-react/data-grid";
import { formatDate, numberWithCommas, numberWithCommas11 } from "../../../../util";
import spinner from "../../../../assets/imgs/tube-spinner.svg"
import { UserContext } from "../../../../hooks/UserContext";
import moment from "moment";
import { Workbook } from "exceljs";
import { exportDataGrid } from "devextreme/excel_exporter";
import saveAs from "file-saver"
import { Button } from "devextreme-react";
import _ from "lodash";

const BudgetVacancyDetail = (props) => {
  const [expand, setExpand] = useState(false);
  const { user } = useContext(UserContext)

  const onCellPreparedHandler = (e) => {
    const lastRow = e.component.getVisibleRows().length;
    let elem = e.cellElement.style;
    elem.setProperty('cursor', 'pointer', 'important');
    if (e.rowType === "header") {
      elem.fontWeight = "bold"
      elem.color = "#15186A"
      elem.backgroundColor = "#EEF0F4"
      elem.textAlign = 'center'
    } else if (e.rowType === 'group') {
      e.column.caption = ``
      elem.color = "#15186A"
      elem.backgroundColor = "#fff"
      elem.fontSize = "12px"
      elem.borderColor = "#D4DCEC"
    } else if (e.rowType === 'data' && e.columnIndex === 0) {
      elem.fontWeight = "bold"
      elem.color = "#000"
      elem.borderColor = "#D4DCEC"
    } else {
      elem.color = "#000"
    }
    if (e.rowType !== 'totalFooter') {
      if (e.cellElement.className === 'yearCapt') {
        elem.boxShadow = 'inset 0 3px 3px -3px #2c77ff, inset 3px 0 3px -3px #2c77ff, inset -3px 0 3px -3px #2c77ff'
      } else if (e.cellElement.className === 'hyazgaar_amt') {
        elem.boxShadow = 'inset 3px 0 3px -3px #2c77ff'
      }
      if (props.chosenOrganization === 'ALL' && user.organization.obt_id === 1) {
        if (e.cellElement.className === 'tailbarBorder') {
          elem.boxShadow = 'inset -3px 0 3px -3px #2c77ff'
        }
      } else {
        if (e.cellElement.className === 'sanalBorder') {
          elem.boxShadow = 'inset -3px 0 3px -3px #2c77ff'
        }
      }
    }
    if (e.rowIndex === lastRow - 1) {
      elem.borderBottom = '2px solid rgba(44, 119, 255, 0.25)'
      if (!["hyazgaar_amt", "add_oron_too", "budget_project_oron_too", "budget_project_tusuv", "tailbar"].includes(e.column.dataField)) {
        elem.borderBottom = 'transparent'
      }
    }
  }

  const onExporting = (e) => {
    const workbook = new Workbook()
    const worksheet = workbook.addWorksheet("Main sheet")
    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
      keepColumnWidths: true,
      topLeftCell: { row: 6, column: 2 },
      customizeCell: ({ gridCell, excelCell }) => {
        excelCell.font = { name: 'Arial', size: 8 }
        if (gridCell.rowType === 'header') {
          excelCell.font = { name: 'Arial', size: 8, 'bold': true }
          excelCell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'd9d9d9' } };
        }
        if (gridCell.rowType === 'group') {
          excelCell.alignment = { wrapText: false };
          excelCell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '63C1FE' } };
        }
        if (gridCell.rowType === 'totalFooter' && excelCell.value) {
          excelCell.font.italic = true;
        }
        if (gridCell.rowType === "data") {
          excelCell.border = {
            top: { style: "thin", color: { argb: "2c2c2c" } },
            left: { style: "thin", color: { argb: "2c2c2c" } },
            bottom: { style: "thin", color: { argb: "2c2c2c" } },
            right: { style: "thin", color: { argb: "2c2c2c" } },
          }
        }
      },
    })
      .then(() => {
        Object.assign(worksheet.getRow(1).getCell(1), {
          value: 'Гарчиг:',
          font: { name: 'Arial', size: 8 },
        })
        Object.assign(worksheet.getRow(1).getCell(2), {
          value: `${props.year} оны Төсвийн төсөл - ОРОН ТООНЫ мэдээлэл`,
          font: { name: 'Arial', size: 8, 'bold': true, color: { argb: '0070c0' } },
        })
        Object.assign(worksheet.getRow(2).getCell(1), {
          value: 'Төлөв:',
          font: { name: 'Arial', size: 8 },
        })
        Object.assign(worksheet.getRow(2).getCell(2), {
          value: props.statusName,
          font: { name: 'Arial', size: 8 },
        })
        Object.assign(worksheet.getRow(3).getCell(1), {
          value: 'Байгууллага:',
          font: { name: 'Arial', size: 8 },
        })
        Object.assign(worksheet.getRow(3).getCell(2), {
          value: user.organization.name,
          font: { name: 'Arial', size: 8 },
        })
        Object.assign(worksheet.getRow(4).getCell(1), {
          value: 'Хэрэглэгч:',
          font: { name: 'Arial', size: 8 },
        })
        Object.assign(worksheet.getRow(4).getCell(2), {
          value: `${user.last_name.slice(0, 1)}. ${user.first_name}, ${user.position_name}`,
          font: { name: 'Arial', size: 8 },
        })
        Object.assign(worksheet.getRow(5).getCell(1), {
          value: 'Огноо:',
          font: { name: 'Arial', size: 8 },
        })
        Object.assign(worksheet.getRow(5).getCell(2), {
          value: new moment().locale('mn').format('lll'),
          font: { name: 'Arial', size: 8 },
        })
      })
      .then(() => {
        workbook.xlsx.writeBuffer().then((buffer) => {
          saveAs(
            new Blob([buffer], { type: "application/octet-stream" }),
            `${props.year} оны Төсвийн төсөл - ОРОН ТООНЫ мэдээлэл-` + formatDate(new Date()) + ".xlsx"
          )
        })
      })
    e.cancel = true
  }

  const groupCell = (e) => {
    return <div style={{ color: '#000', fontWeight: 700 }}>{e.data.displayValue}</div>
  }

  const calculateCustomSummary = (e) => {
    if (e.name === 'customSummary') {
      if (e.summaryProcess === 'start') {
        e.totalValue = new Set();
      } else if (e.summaryProcess === 'calculate') {
        if (props.chosenOrganization === 'ALL' && user.organization.obt_id === 1 && !expand) {
          if (e.value.parent_org_name) {
            e.totalValue.add(e.value.parent_org_name);
          }
        } else {
          if (e.value.org_name) {
            e.totalValue.add(e.value.org_name);
          }
        }
      } else if (e.summaryProcess === 'finalize') {
        e.totalValue = e.totalValue.size;
      }
    }
  }

  return (
    <div className="budgetoffervacancy">
      <DataGrid
        dataSource={props.mainData}
        wordWrapEnabled={true}
        showBorders={true}
        columnAutoWidth={false}
        showColumnLines={true}
        showRowLines={true}
        onCellPrepared={onCellPreparedHandler}
        noDataText='Дата байхгүй байна.'
        scrolling={true}
        hoverStateEnabled={true}
        loadPanel={{ enabled: true, text: '', showPane: false, indicatorSrc: spinner }}
        columnWidth={true}
        allowColumnResizing={true}
        paging={{
          pageSize: 20,
        }}
        twoWayBindingEnabled={true}
        sorting={{ mode: 'none' }}
        showColumnHeaders={true}
        onExporting={onExporting}
      >
        <Export enabled={true} allowExportSelectedData={false} texts={{ exportAll: 'Excel файл татах' }} />
        <Toolbar>
          {user.organization.obt_id === 1 && <Item location="after">
            <div><Button icon={expand ? 'collapse' : 'expand'} onClick={() => setExpand(prev => !prev)} hint={expand ? 'Хураах' : 'Дэлгэх'} /></div>
          </Item>}
          <Item name="exportButton" widget="dxButton" options={{ icon: "xlsxfile" }} />
          <Item name="columnChooserButton"
            render={(e) => {
              return <Button onClick={e.options.onClick} hint="Багана сонгох" size='1rem' id='columnChooserButton' icon="columnchooser" />
            }}
          />
        </Toolbar>
        <Grouping contextMenuEnabled={false}
          autoExpandAll={expand}
          expandMode="rowClick"
          texts={{
            groupContinuesMessage: 'Дараагийн хуудас',
            groupContinuedMessage: 'Өмнөх хуудас'
          }}
        />
        <ColumnChooser emptyPanelText="Хоосон" enabled={true} height={300} allowSearch={true}
          mode="select"
          title={'Багана сонгох'} />
        {props.chosenOrganization === 'ALL' && user.organization.obt_id === 1 && <Column dataField="parent_org_name" caption="" groupIndex={0} groupCellComponent={groupCell} minWidth={200} showInColumnChooser={false} />}
        <Column dataField="org_name" caption="Байгууллага" width={300} minWidth={200} showInColumnChooser={false} />
        <Column caption={`${props.year - 3}`} visible={false}>
          <Column caption="Төсөв" dataField="prev_prev_prev_tusuv" customizeText={(e) => numberWithCommas(e.value)} />
          <Column caption="Орон тоо" dataField="prev_prev_prev_oron_too" format={"#,##0"} />
        </Column>
        <Column caption={`${props.year - 2}`} minWidth={200}>
          <Column caption="Төсөв" dataField="prev_prev_tusuv" customizeText={(e) => numberWithCommas(e.value)} />
          <Column caption="Орон тоо" dataField="prev_prev_oron_too" format={"#,##0"} />
        </Column>
        <Column caption={`${props.year - 1}`} minWidth={200}>
          <Column caption="Төсөв" dataField="prev_tusuv" customizeText={(e) => numberWithCommas(e.value)} />
          <Column caption="Орон тоо" dataField="prev_oron_too" format={"#,##0"} />
        </Column>
        <Column caption={`${props.year}`} cssClass="yearCapt" minWidth={400} >
          <Column caption="Батлагдсан орон тооны хязгаар" cssClass="hyazgaar_amt" dataField="hyazgaar_amt" format={"#,##0"} />
          <Column caption="Нэмэх орон тоо" dataField="add_oron_too" format={"#,##0"} />
          <Column caption="Орон тооны санал" dataField="budget_project_oron_too" customizeText={(e) => numberWithCommas11(e.value)} />
          <Column caption="Төсвийн санал" dataField="budget_project_tusuv" customizeText={(e) => numberWithCommas(e.value)} cssClass="sanalBorder" />
          {props.chosenOrganization === 'ALL' && user.organization.obt_id === 1 && <Column cssClass="tailbarBorder" caption="Тaйлбар" dataField="tailbar" customizeText={(e) => e.value || '-'} />}
        </Column>
        <Summary calculateCustomSummary={calculateCustomSummary}>
          <GroupItem column="prev_prev_prev_tusuv" summaryType="sum" alignByColumn={true} customizeText={(e) => numberWithCommas(e.value)} />
          <GroupItem column="prev_prev_prev_oron_too" summaryType="sum" alignByColumn={true} format={"#,##0"}
            customizeText={(e) => numberWithCommas11(e.value)} />
          <GroupItem column="prev_prev_tusuv" summaryType="sum" alignByColumn={true} customizeText={(e) => numberWithCommas(e.value)} />
          <GroupItem column="prev_prev_oron_too" summaryType="sum" alignByColumn={true} format={"#,##0"}
            customizeText={(e) => numberWithCommas11(e.value)} />
          <GroupItem column="prev_tusuv" summaryType="sum" alignByColumn={true} customizeText={(e) => numberWithCommas(e.value)} />
          <GroupItem column="prev_oron_too" summaryType="sum" alignByColumn={true} format={"#,##0"}
            customizeText={(e) => numberWithCommas11(e.value)} />
          <GroupItem column="hyazgaar_amt" summaryType="sum" alignByColumn={true} format={"#,##0"} customizeText={(e) => numberWithCommas11(e.value)} />
          <GroupItem column="add_oron_too" summaryType="sum" alignByColumn={true} customizeText={(e) => e.value < 0 ? e.value : numberWithCommas11(e.value)} />
          <GroupItem column="budget_project_oron_too" summaryType="sum" alignByColumn={true} customizeText={(e) => numberWithCommas11(e.value)} />
          <GroupItem column="budget_project_tusuv" summaryType="sum" alignByColumn={true} customizeText={(e) => numberWithCommas(e.value)} />

          <TotalItem column="prev_prev_prev_tusuv" summaryType="sum" alignByColumn={true} customizeText={(e) => numberWithCommas(e.value)} />
          <TotalItem column="prev_prev_prev_oron_too" summaryType="sum" alignByColumn={true} format={"#,##0"}
            customizeText={(e) => numberWithCommas11(e.value)} />
          <TotalItem column="prev_prev_tusuv" summaryType="sum" alignByColumn={true} customizeText={(e) => numberWithCommas(e.value)} />
          <TotalItem column="prev_prev_oron_too" summaryType="sum" alignByColumn={true} format={"#,##0"}
            customizeText={(e) => numberWithCommas11(e.value)} />
          <TotalItem column="prev_tusuv" summaryType="sum" alignByColumn={true} customizeText={(e) => numberWithCommas(e.value)} />
          <TotalItem column="prev_oron_too" summaryType="sum" alignByColumn={true} format={"#,##0"}
            customizeText={(e) => numberWithCommas11(e.value)} />
          <TotalItem column="hyazgaar_amt" summaryType="sum" alignByColumn={true} format={"#,##0"} customizeText={(e) => numberWithCommas11(e.value)} />
          <TotalItem column="add_oron_too" summaryType="sum" alignByColumn={true} customizeText={(e) => e.value < 0 ? e.value : numberWithCommas11(e.value)} />
          <TotalItem column="budget_project_oron_too" summaryType="sum" alignByColumn={true} customizeText={(e) => numberWithCommas11(e.value)} />
          <TotalItem column="budget_project_tusuv" summaryType="sum" alignByColumn={true} customizeText={(e) => numberWithCommas(e.value)} />
          <TotalItem name="customSummary" summaryType="custom" displayFormat="Нийт {0} байгууллага" showInColumn="org_name" />
        </Summary>
      </DataGrid>
    </div>
  )
}

export default BudgetVacancyDetail;

import React, { useContext, useEffect, useRef, useState, useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";
import DataGrid, { Column, Paging, Export, Grouping, FilterRow, GroupItem } from "devextreme-react/data-grid";
import { Summary, TotalItem } from "devextreme-react/data-grid";
import { numberWithCommas } from "../../../util";
import Button from "devextreme-react/button";
import { urlServer } from "../../../shared/lib/request";
import ScheduleService from "../../../services/api/scheduleService";
import { UserContext } from "../../../hooks/UserContext";
import { Popup, ScrollView, TextArea } from "devextreme-react";
import notify from "devextreme/ui/notify";
import FileServices from "../../../services/api/file";
import _ from "lodash";
import RequestDocument from "../../../components/document/document";
import notificationUserService from "../../../services/api/notificationUser";
import { Workbook } from "exceljs";
import { exportDataGrid } from "devextreme/excel_exporter";
import { saveAs } from "file-saver-es";
import './ScheduleGridList.css'
import { custom } from "devextreme/ui/dialog";
import Joyride from 'react-joyride';
import { guideLocale, reqInfoTabSteps } from "../../../components/SystemGuideSteps";

const RequestInformation = ({ popup, popRequest }) => {
    const { reqId, reqOrg, status } = useParams();
    const { user } = useContext(UserContext);
    const req_id = +reqId
    const req_org = +reqOrg
    const sts = +status
    const current = req_org === user.org_id

    const approvePermission = user.permission.some(({ code }) => ["budgetProposalApprove", "scheduleApprove"].includes(code));
    const createPermission = user.permission.some(({ code }) => ["budgetProposalCreate", "scheduleCreate"].includes(code));
    const saveConditions = useMemo(() => [1, 2, 3], []);
    const checkConditions = useMemo(() => [1, 2, 3], []);
    const approveConditions = useMemo(() => [4, 5, 6, 9], []);
    const cancelConditions = useMemo(() => [4, 5, 6, 9], []);
    const joinConditions = useMemo(() => [7, 8, 2, 3], []);
    const disjoinConditions = useMemo(() => [7, 8, 10, 11], []);
    const obt_1_approveConditions = useMemo(() => [9], []);
    const obt_1_disapproveConditions = useMemo(() => [9], []);
    const renewConditions = useMemo(() => [13, 14, 15], []);

    const [request, setRequest] = useState(null);
    const [requestList, setRequestList] = useState([]);
    const [requestNote, setRequestNote] = useState(null);
    const [allFiles, setAllFiles] = useState([]);
    const [currentFile, setCurrentFile] = useState(null);
    const [documentPopup, setDocumentPopup] = useState(false);
    const inputFilesRef = useRef(null);
    const uploadingFiles = useRef(null);
    const [requestLog, setRequestLog] = useState([]);
    const [requestListNegtgesen, setRequestListNegtgesen] = useState([]);
    const [parentIds, setParentIds] = useState(null);
    const [uniqueRequests, setUniqueRequests] = useState(null);
    const [subRequestFiles, setSubRequestFiles] = useState(null)

    const [actionPopup, setActionPopup] = useState(false)
    const isSave = useRef(false)
    const [currentAction, setCurrentAction] = useState(null)
    const [logComment, setLogComment] = useState(null)

    useEffect(() => {
        getRequestData();
        getRequestDetail();
        getRequestLog();
        getRequestFiles();
    }, [])

    const getRequestData = async () => {
        try {
            let res = await ScheduleService.getRequestData(popup ? popRequest?.id : reqId);
            setRequest(res[0])
            user.organization.obt_id < 3 && getListNegtgesen(res[0]);
        } catch (e) {
            console.log(e)
        }
    }

    const getRequestDetail = async () => {
        try {
            let res = await ScheduleService.getRequestDetail(popup ? popRequest?.id : reqId);
            setRequestList(res);
        } catch (e) {
            console.log(e)
        }
    }

    const getListNegtgesen = async ({ id, status, renew }) => {
        try {
            let r = [];
            let r1 = [];
            let r2 = [];
            if (renew) {
                r1 = ScheduleService.requestListNegtgesen((status === 2 || status === 3) && 'new', id);
                r2 = ScheduleService.requestListNegtgesen('parent', id);
                let values = await Promise.all([r1, r2]);
                r = values[0].concat(values[1]);
            } else {
                r = await ScheduleService.requestListNegtgesen((status === 2 || status === 3) ? 'new' : 'parent', id);
            }
            let a = [];
            for (let i = 0; i < r.length; i++) {
                a.push(r[i].request_id);
                a.push(r[i].parent_id);
            }
            let uniqueIds = a.filter((element, index) => {
                return a.indexOf(element) === index;
            });
            let joinedStr = uniqueIds.join(",").replace(',,', ',');
            joinedStr = joinedStr.endsWith(",") ? joinedStr.slice(0, -1) : joinedStr;
            setParentIds(joinedStr || null);
            const tempListNegtgesen = _.filter(r, { active_flag: 1 })
            setRequestListNegtgesen(tempListNegtgesen);
            if (user.organization.obt_id < 3) {
                const temp = [...new Set(tempListNegtgesen.map(item => item.request_id))]
                setUniqueRequests(temp)
            }
        } catch (e) {
            console.log(e)
        }
    }

    const loadFiles = async (req_id) => {
        try {
            let res = await FileServices.getRequestFiles(req_id)
            res.data.forEach((e) => e.downloaded ??= true)
            return res?.data
        } catch (e) {
            console.log(e)
        }
    }

    const getRequestFiles = async () => {
        await loadFiles(popup ? popRequest?.id : reqId)
            .then((data) => setAllFiles(data))
            .catch((e) => console.log(e))
    }

    const getRequestLog = async () => {
        try {
            let res = await ScheduleService.getRequestLog(popup ? popRequest?.id : reqId);
            setRequestLog(res);
        } catch (e) {
            console.log(e)
        }
    }

    const canDisjoin = () => {
        const nonNullStatus = request?.status_array.filter((status) => status !== null)
        switch (nonNullStatus[nonNullStatus.length - 1]) {
            case "ТТЗ-Боловсруулж байгаа":
            case "ТТЗ-Татгалзсан":
            case "ТТЗ-д хүргүүлсэн":
                return user.organization.obt_id === 2;
            case "ТЕЗ-Боловсруулж байгаа":
            case "ТЕЗ-Татгалзсан":
            case "ТЕЗ-д хүргүүлсэн":
                return user.organization.obt_id === 1;
            default:
                return false
        }
    }

    const isRenewable = () => {
        if (user.canRenew === undefined)
            return false
        const tempDate = new Date(request?.createdAt)
        const tempKey = tempDate.getFullYear().toString() + tempDate.getMonth().toString()
        return user?.canRenew[tempKey]
    }

    useEffect(() => {
        getSubRequestFiles()
    }, [uniqueRequests])

    const getSubRequestFiles = async () => {
        const reqids = uniqueRequests?.map(async (reqId) => {
            const res = await loadFiles(reqId)
            return res
        })
        if (reqids) {
            await Promise.all(reqids)
                .then((res) => {
                    let req_org = null
                    let file_index = 1
                    const subOrbRequestFiles = res.flat(1)
                    subOrbRequestFiles.forEach((file, index) => {
                        if (req_org !== file.schedule_transfer_request?.request_org) {
                            file_index = 1
                            req_org = file.schedule_transfer_request?.request_org
                        }
                        file.index = file_index
                        file_index++
                    })
                    setSubRequestFiles(subOrbRequestFiles)
                })
                .catch(err => console.log("err", err))
        }
    }

    const buttonStatus = () => {
        if (approvePermission) {
            switch (sts) {
                case 4: // ТШЗ хянаж байгаа
                case 5: // ТТЗ хянаж байгаа
                case 6: // ТЕЗ хянаж байгаа
                    return false  // ТЕЗ цуцалсан 15 нэгтгэсэн 11,
                case 9: return false;
                default:
                    return true
            }
        }
        else if (createPermission) {
            switch (sts) {
                case 1: // ТШЗ шинэ
                case 2: // ТТЗ шинэ
                case 3: // ТЕЗ шинэ
                    return false
                case 9: return false;
                default:
                    return true  // ТЕЗ хянаж байгаа 6, ТЕЗ цуцалсан 15 нэгтгэсэн 11,
            }
        }
    }

    const getNextActionRenew = (status) => {
        switch (status) {
            case 13:  //"ТШЗ цуцалсан",
            case 14:  //"ТТЗ цуцалсан",
            case 15:  //"ТЕЗ цуцалсан",
                if (user.organization.obt_id === 3)
                    return 1
                else if (user.organization.obt_id === 2)
                    return 2
                else if (user.organization.obt_id === 1)
                    return 3
                else
                    return status
            default:
                return status
        }
    }

    useEffect(() => {
        requestNote && requestNote !== undefined && updateRequestNote()
    }, [requestNote])

    const updateRequestNote = async () => {
        try {
            if (requestNote === request?.request_note) return
            await ScheduleService.setRequestNote(reqId, { request_note: requestNote })
            notify({ message: 'Нэмэлт тайлбар хадгалагдлаа', width: 'fit-content' }, "success", 5000);
        } catch (e) {
            console.log(e)
            notify({ message: 'Нэмэлт мэдээлэл хадгалах явцад алдаа гарлаа', width: 'fit-content' }, "error", 5000);
        }
    }

    const actionFunc = async (action) => {
        if (action === "check") {
            !requestNote ?
                notify({ message: "Хуваарийн өөрчлөлтийн нэмэлт тайлбарыг оруулна уу!", width: 'fit-content' }, "warning", 5000)
                :
                !allFiles.length ?
                    notify({ message: "Албан бичгийг хавсаргана уу!", width: 'fit-content' }, "warning", 5000)
                    :
                    setActionPopup(true)
        }
        else {
            setActionPopup(true)
        }
        setCurrentAction(action)
    }

    const pushNotif = async (status) => {
        await notificationUserService.createRequestNotification({
            requestId: request?.id,
            createdAt: request?.createdAt,
            status: status,
            isOwn: current,
            requestCode: request?.request_code,
        })
            .then((res) => {
                console.log('notification service', res)
            })
            .catch((err) => {
                console.log('notification service err', err)
            })
    }

    //** hyanuulah button darah uyed hiigdeh uildel */

    const modifyRequestStatus = async (isCancel, nextStatus) => {
        let note = sts === 1 ? requestNote : logComment
        const req = {
            "request_id": request?.id,
            "current_status": request?.status,
            "change_status": nextStatus,
            "comment": note,
            "parentIds": parentIds,
        }
        await ScheduleService.setRequestNote(request?.id, { request_note: note })
        await ScheduleService.changeStatus(req)
            .then((res) => {
                pushNotif(nextStatus)
            })
            .catch((e) => {
                console.log("err->", e)
            })
            .finally(() => {
                setActionPopup(false)
                user.current_request.status = nextStatus
            })
    }

    const verifyActiveFlag = async (id, activeFlag) => {
        let myDialog = custom({
            title: "Баталгаажуулалт",
            messageHtml: `<i>Та энэхүү шилжүүлгийг хасах уу?</i>`,
            buttons: [{
                type: "default",
                text: "Тийм",
                onClick: (e) => {
                    return { buttonText: e.component.option("text") }
                }
            },
            {
                text: "Үгүй",
                onClick: (e) => {
                    return { buttonText: e.component.option("text") }
                }
            },
            ]
        });
        myDialog.show().then(async (dialogResult) => {
            if (dialogResult?.buttonText === 'Тийм') {
                const req = {
                    "id": id,
                    "active_flag": activeFlag
                }
                await ScheduleService.removeDetail(req)
                    .then((res) => {
                        res.success && notify({ message: res.message, width: 'fit-content' }, "success", 5000);
                    })
                    .catch((err) => {
                        console.log('remove detial err->', err)
                    })
            }
        })
    }

    const renderCell1 = (e) => {
        const active = e.data.active_flag === 1
        if (e.data.id && e.data.transfer_group)
            return (
                <Button
                    icon={active ? 'minus' : 'plus'}
                    hint="Гүйлгээг хасах"
                    stylingMode="outlined"
                    style={{ border: 'none' }}
                    type={active ? 'danger' : 'normal'}
                    onClick={() => verifyActiveFlag(e.data.id, e.data.active_flag)} />
            )
        else
            return (<div></div>)
    }

    const cell_render = (e) => {
        if (e.value !== "0")
            return <span style={{ color: e.data.type1_amt !== "0" ? '#f66160' : '#3daa75' }}>{numberWithCommas(e.value) + ' ₮'}</span>
        return "-"
    }

    const getDescription = (requestStatus) => {
        switch (requestStatus) {
            case 1:
            case 2:
            case 3:
            case 10:
            case 11:
                return isSave.current ? 'хадгалагдлаа' : 'амжилттай илгээлээ' // check
            case 4:
            case 5:
                return 'төсвийн дараагийн захирагч руу илгээлээ' //'nextOBT'
            case 6:
                return 'төсвийн дараагийн захирагч руу илгээлээ' //'financeSend'
            case 7:
            case 8:
                return 'нэгтгэлээ' //'merge'
            case 9:
                return 'баталлаа' //'confirm'
            default:
                return 'Тодорхойлогдоогүй тайлбар' // 'not defined status code 
        }
    }

    //hyanuulah, batlah, tsutslah... zergiig darah uyed daraagiin statusiig todorhoiloh function
    const getNextActionProceed = (status, isCancel) => {
        switch (status) {
            case 1:  //"ТШЗ шинэ", --> 4 "ТШЗ хянаж байгаа",
                return 4;
            case 2:  //"ТТЗ шинэ", --> 5 "ТТЗ хянаж байгаа",
                return 5;
            case 3:  //"ТЕЗ шинэ", --> 6 "ТЕЗ хянаж байгаа",
                return 6;
            case 4:
                return isCancel ? 13 : user.organization.parent_obt_id === 1 ? 8 : 7; // ТШЗ цуцалсан 13, ТТЗ-рүү илгээсэн 7, ТЕЗ-рүү илгээсэн 8 // ТШЗ
            case 5:
                return user.organization.obt_id === 2 ? (isCancel ? 14 : 8) : 5;  // ТТЗ цуцалсан 14 ТЕЗ-рүү илгээсэн 8", // ТТЗ
            case 6:
                return user.organization.obt_id === 1 ? (isCancel ? 15 : 9) : 6;
            case 7:
                return user.organization.obt_id === 2 ? (isCancel ? 14 : 10) : 7;  // ТТЗ цуцалсан 14 нэгтгэсэн 10", // ТТЗ
            case 8:
                return user.organization.obt_id === 1 ? (isCancel ? 15 : 11) : 8;  //"ТЕЗ цуцалсан 15 нэгтгэсэн 11", // ТЕЗ
            case 9:
                return user.organization.obt_id === 1 ? (isCancel ? 15 : 12) : 9;  //СЯ баталсан
            case 10:
                return isCancel ? 14 : 5  //ТТЗ цуцалсан ТТЗ хянаж байгаа,
            default:
                return status
        }
    }

    const getNextActionSave = (status) => {
        // Харьяа байгууллагын хүсэлт байх үед
        switch (status) {
            case 1:
            case 2:
            case 3:
                return status
        }
    }

    const onClickNegtgeh = async (isCancel, action) => {
        try {
            if (action === "renew") {
                const nextStatus = getNextActionRenew(sts)
                await modifyRequestStatus(isCancel, nextStatus);
                return
            }
            const description = isCancel ? 'цуцаллаа' : getDescription(sts)
            let nextStatus = null
            switch (currentAction) {
                case "proceed":
                case "check":
                case "return":
                case "cancel":
                case "merge":
                case "join":
                case "disjoin":
                case "approve":
                    nextStatus = getNextActionProceed(sts, isCancel)
                    break;
                case "save":
                    nextStatus = getNextActionSave(sts)
                    break;
                case "renew":
                    nextStatus = getNextActionRenew(sts)
                    break;
                default:
                    nextStatus = sts
                    break;
            }

            modifyRequestStatus(isCancel, nextStatus)
            notify({ message: `${request?.request_code} - дугаартай хуваарийн өөрчлөлтийн хүсэлтийг ${description}`, width: 'fit-content' }, isCancel ? "info" : "success", 5000);
            await Promise.all([
                saveConditions.includes(sts) && uploadFiles(),
                getRequestDetail()
            ])
        }
        catch (e) {
            console.log(e)
        }
    }

    const handleUpload = async (e) => {
        e.preventDefault()
        uploadingFiles.current ??= new FormData()
        const files = Array.from(e.target.files)
        const uploading = allFiles.filter(file => !file.downloaded);
        const names = new Set(uploading.map(file => file.name));
        const filtered = files.filter(file => !names.has(file.name));
        filtered.forEach((data, index) => {
            data.downloaded = false
            data.file ??= { file_name: data.name }
            data.file.file_size = data.size
            data.file.file_extention = data.type
            data.file.content_name = data.name
        })
        if (files.length !== filtered.length)
            notify({ message: "Файл давхардсан ба давхардаагүй файлуудыг оруулав", width: 'fit-content' }, 'warning', 5000)
        filtered.forEach((file) => {
            uploadingFiles.current.append('files', file)
        })
        uploadFiles()
    }

    const uploadFiles = async () => {
        const length = Array.from(uploadingFiles.current.entries()).length
        if (length > 0) {
            await FileServices.uploadRequestFiles(request?.id, uploadingFiles.current)
                .then((res) => {
                    const afterUpload = allFiles.filter((file) => { return file.downloaded })
                    setAllFiles(afterUpload)
                    getRequestFiles()
                    uploadingFiles.current = new FormData()
                })
                .catch((err) => {
                    console.log('upload request files err', err)
                })
        }
    }

    const removeFile = useCallback((removingFile) => {
        setAllFiles(prev => prev.filter(file => file !== removingFile))
    }, [])

    const removeExistingFile = useCallback(async (removingFile) => {
        await FileServices.updateRequestFile(removingFile.file_id, { status: 0 })
            .then(() => {
                setAllFiles(prev => prev.filter(file => file !== removingFile))
                notify({ message: "Файлыг амжилттай устгалаа", width: 'fit-content' }, "success", 5000)
            }).catch((err) => {
                console.log(err)
                notify({ message: "Файлыг устгахад алдаа гарлаа", width: 'fit-content' }, "warning", 5000)
            })
    }, [])

    const removeCell = (row) => {
        if (row.data.downloaded) {
            return <Button
                type="danger"
                style={{ border: 'none' }}
                stylingMode="outlined"
                hint="Устгах"
                icon="trash"
                onClick={() => removeExistingFile(row.data)}
            />
        }
        return <Button icon="remove" onClick={() => removeFile(row.data)} />
    }

    const viewFileCell = (row) => {
        const popupFormats = ['pdf', 'png', 'jpeg', 'jpg', 'gif', 'webp'].some(format => row.data.file.file_name.endsWith(format))
        if (row.data.downloaded) {
            if (popupFormats)
                return <Button hint="Харах" icon="eyeopen" stylingMode="outlined" style={{ border: 'none' }} onClick={() => setCurrentFile(row.data)} />
            else
                return (
                    <Button icon="download" stylingMode="outlined" hint="Татах" style={{ border: 'none' }}
                        onClick={(e) => {
                            const link = document.createElement('a')
                            link.href = urlServer + '/api/file/documents/' + row.data?.file?.file_name
                            link.click()
                        }} />
                )
        } else
            return <></>
    }

    const cellRenderChangeAmt = (e) => {
        return <span style={{ color: '#003696' }}>{numberWithCommas(e.data.type1_amt !== "0" ? e.data.schedule_amt * 1 - e.data.type1_amt * 1 : e.data.schedule_amt * 1 + e.data.type0_amt * 1) + ' ₮'}</span>
    }

    const fileActionRender = (row) => {
        return (
            <div style={{ display: 'flex', justifyContent: "center" }}>
                {viewFileCell(row)}
                {saveConditions.includes(request?.status) && removeCell(row)}
            </div>
        )
    }

    const onExporting1 = (e) => {
        const workbook = new Workbook()
        const worksheet = workbook.addWorksheet('Main Sheet')
        exportDataGrid({
            component: e.component,
            worksheet: worksheet,
        })
            .then(() => {
                workbook.xlsx.writeBuffer()
                    .then((buffer) => {
                        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), request?.request_code + "_хүсэлтийн мэдээлэл.xlsx")
                    })
            })
    }

    const ActionButtons = () => {
        const buttons = []
        if (sts === 1)
            buttons.push(<Button onClick={() => onClickNegtgeh(false)} text="Тийм" type="default" width={98} />)
        if ([2, 3].includes(sts) || renewConditions.includes(sts) || (approveConditions.includes(sts) && currentAction === "approve") || (joinConditions.includes(sts) && currentAction === "join"))
            buttons.push(<Button onClick={() => logComment ? onClickNegtgeh(false) : notify({ message: 'Үйлдлийн тайлбар оруулна уу!', width: 'fit-content' }, 'warning', 5000)} text="Тийм" type="default" width={98} />)
        if ((cancelConditions.includes(sts) && currentAction === "cancel") || (disjoinConditions.includes(sts) && currentAction === "disjoin"))
            buttons.push(<Button onClick={() => logComment ? onClickNegtgeh(true) : notify({ message: 'Үйлдлийн тайлбар оруулна уу!', width: 'fit-content' }, 'warning', 5000)} text="Тийм" type="default" width={98} />)
        return buttons
    }

    const VerifyAction = () => {
        let verify = ''
        if (checkConditions.includes(sts))
            verify = 'Хуваарийн өөрчлөлтийн хүсэлт илгээх үү?'
        if (renewConditions.includes(sts))
            verify = 'Хуваарийн өөрчлөлтийн засварлах уу?'
        if (cancelConditions.includes(sts) && currentAction === "cancel")
            verify = 'Хуваарийн өөрчлөлтийн хүсэлтийг цуцлах уу?'
        if (approveConditions.includes(sts) && currentAction === "approve")
            verify = `${request?.request_code} дугаартай хуваарийн өөрчлөлтийн хүсэлтийг батлах уу?`
        if (joinConditions.includes(sts) && currentAction === "join")
            verify = 'Хуваарийн өөрчлөлтийг хүсэлтийг нэгтгэх үү?'
        if (disjoinConditions.includes(sts) && currentAction === "disjoin")
            verify = 'Хуваарийн өөрчлөлтийн хүсэлтийг цуцлах уу?'
        return verify
    }

    const onCellPreparedHandler = (e) => {
        if (e.rowType === "header") {
            e.cellElement.style.fontWeight = "bold";
            e.cellElement.style.color = "#15186A";
            e.cellElement.style.alignment = "center";
            e.cellElement.style.backgroundColor = "#EEF0F4";
        } else if (e.rowType === "data") {
            e.cellElement.style.fontWeight = "semibold"
            e.cellElement.style.verticalAlign = 'middle'
        } else if (e.rowType === 'group') {
            e.cellElement.style.color = "#15186A"
            e.cellElement.style.backgroundColor = "#fff"
            e.cellElement.style.fontWeight = "bold";
        }
    }

    return (
        <div>
            <div style={{ position: "absolute", right: 0, top: -48 }} >
                {current &&
                    <div id='changeStatusBtn'>
                        {(user.roles.some(e => e.role_code === '32') || createPermission) &&
                            <div style={{ display: 'flex', gap: 10, alignItems: 'center' }}>
                                {
                                    renewConditions.includes(sts) &&
                                    <Button type='success' text={"Хуваарийн өөрчлөлт засварлах"} disabled={!isRenewable()}
                                        style={{ backgroundColor: '#ffeedf', color: '#f99d4b', fontWeight: 'bold' }}
                                        onClick={() => {
                                            isSave.current = true
                                            setRequestNote(null)
                                            onClickNegtgeh(false, "renew")
                                        }} />
                                }
                                {
                                    checkConditions.includes(sts) &&
                                    <Button type='success' text="Хуваарийн өөрчлөлтийн хүсэлт илгээх"
                                        style={{ backgroundColor: '#e2fbee', color: '#50c687', fontWeight: 'bold' }}
                                        disabled={buttonStatus() || !(requestList.length > 0 || requestListNegtgesen.length > 0)
                                            // || (user.organization.obt_id === 1 && (moment().format('YYYY-MM-DD') < moment().date(25).format('YYYY-MM-DD')))
                                        }
                                        onClick={() => {
                                            isSave.current = false
                                            actionFunc('check')
                                        }} />
                                }
                            </div>
                        }
                        {
                            (user.roles.some(e => e.role_code === '32') || approvePermission) &&
                            <div style={{ display: 'flex', gap: 10 }}>
                                {approveConditions.includes(sts) && sts !== 9 &&
                                    <Button onClick={() => actionFunc('approve')} text="Батлах" type="success" disabled={buttonStatus()} style={{ backgroundColor: '#e2fbee', color: '#50c687', fontWeight: 'bold' }} />}
                                {cancelConditions.includes(sts) && sts !== 9 &&
                                    <Button onClick={() => actionFunc('cancel')} text="Цуцлах" type="danger" disabled={buttonStatus()} style={{ backgroundColor: '#fff2f2', color: '#f66160', fontWeight: 'bold' }} />}
                            </div>
                        }
                        {
                            (user.roles.some(e => e.role_code === '32') || createPermission) && user.organization.obt_id !== 3 && !current &&
                            <div style={{ display: 'flex', gap: 10 }}>
                                {joinConditions.includes(sts) && <Button type='success' onClick={() => actionFunc('join')} text="Нэгтгэх" style={{ backgroundColor: '#e2fbee', color: '#50c687', fontWeight: 'bold' }} />}
                                {disjoinConditions.includes(sts) && canDisjoin() &&
                                    <Button type='danger' onClick={() => actionFunc('disjoin')} text="Цуцлах" style={{ backgroundColor: '#fff2f2', color: '#f66160', fontWeight: 'bold' }} />}
                            </div>
                        }
                        {
                            // ТТЗ, ТЕЗ нябо хийх үйлдэл
                            cancelConditions.includes(sts) && user.organization.obt_id !== 3 && !current &&
                            <Button type='danger' style={{ backgroundColor: '#fff2f2', color: '#f66160', fontWeight: 'bold' }} onClick={() => onClickNegtgeh(true)} text="Цуцлах" />
                        }
                        {
                            //TODO: ttz, tez
                            user.organization.obt_id === 1 &&
                            <div style={{ display: 'flex', gap: 10 }}>
                                {obt_1_approveConditions.includes(sts) &&
                                    <Button type='success' onClick={() => actionFunc('approve')} disabled={buttonStatus()} text="СЯ Баталсан" style={{ backgroundColor: '#e2fbee', color: '#50c687', fontWeight: 'bold' }} />}
                                {obt_1_disapproveConditions.includes(sts) &&
                                    <Button type='danger' onClick={() => actionFunc('cancel')} disabled={buttonStatus()} text="СЯ Татгалзсан" style={{ backgroundColor: '#fff2f2', color: '#f66160', fontWeight: 'bold' }} />}
                            </div>
                        }
                    </div>
                }
            </div>
            <div className='card' style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: 10, padding: 15, margin: 10, height: 120 }}>
                <div>
                    <span className="mr-3">Хүсэлтийн дугаар:</span>
                    <b>{request?.request_code || '-'}</b>
                </div>
                <div>
                    <span className="mr-3">Төсвийн захирагчийн ангилал:</span>
                    <b>{request?.org_budget_type_name || '-'}</b>
                </div>
                <div>
                    <span className="mr-3">Хүсэлтийн төлөв:</span>
                    <b>{request?.status_name || '-'}</b>
                </div>
                <div>
                    <span className="mr-3">Төсвийн захирагчийн нэр:</span>
                    <b>{request?.budget_head_name || request?.organization_name || '-'}</b>
                </div>
                <div>
                    <span className="mr-3">Хүсэлтийн дүн:</span>
                    <b>{numberWithCommas(request?.tran_amount) || '0.00'} ₮</b>
                </div>
                <div>
                    <span className="mr-3">Дараагийн шатны төсвийн захирагч:</span>
                    <b>{request?.parent_org_name ? request?.next_budget_head_name : '-'}</b>
                </div>
            </div>
            <div>
                <div className="card-header">
                    <span style={{ fontWeight: 'bold' }}>Хуваарийн өөрчлөлтийн хүсэлт:</span>
                </div>
                <div className="card-body">
                    <DataGrid
                        noDataText="Дата байхгүй байна."
                        dataSource={requestList}
                        rowAlternationEnabled={true}
                        showBorders={true}
                        wordWrapEnabled={true}
                        allowColumnResizing={true}
                        allowColumnReordering={true}
                        onExporting={onExporting1}
                        onCellPrepared={onCellPreparedHandler}
                        export={{ texts: { exportAll: "Excel загвар татах" } }}
                    >
                        <FilterRow visible={true} />
                        <Export enabled={true} />
                        <Grouping
                            contextMenuEnabled={false}
                            expandMode="rowClick"
                            texts={{
                                groupContinuesMessage: 'Дараагийн хуудас',
                                groupContinuedMessage: 'Өмнөх хуудас'
                            }}
                        />
                        {user.organization.obt_id !== 3 && <Column groupIndex={0} dataField="org_name" alignment="left" groupCellComponent={(e) => e.data.displayValue} />}
                        <Column dataField="agenda_name" alignment="left" caption="Хөтөлбөр" />
                        <Column dataField="event_name" alignment="left" caption="Зориулалт" />
                        <Column dataField="econ_name" alignment="left" caption="ЭЗА" />
                        <Column dataField="month" alignment="center" width={80} caption="Сар" />
                        <Column dataField="transfer_group" alignment="left" caption="Шилжүүлгийн төрөл" />
                        <Column dataField="schedule_amt" alignment="right" caption="Хуваарийн дүн" cellRender={(e) => e.value !== "0" ? `${numberWithCommas(e.value) + ' ₮'}` : '-'} allowFiltering={false} />
                        <Column dataField="type0_amt" alignment="right" caption="Хүлээн авах дүн" cellRender={cell_render} allowFiltering={false} />
                        <Column dataField="type1_amt" alignment="right" caption="Шилжүүлэх дүн" cellRender={cell_render} allowFiltering={false} />
                        <Column caption="Өөрчлөлтийн дараах дүн" alignment="right" cellRender={cellRenderChangeAmt} allowFiltering={false} />
                        <Column alignment="center" width="5rem" caption="Үйлдэл" allowExporting={false} cellRender={renderCell1} visible={
                            approvePermission ||
                                (createPermission && ![1, 2, 3].includes(sts) && current) ||
                                (user.organization.obt_id !== 3 && current && createPermission) ||
                                (!current && ![7, 8].includes(sts)) ? false : true
                        } />
                        <Summary>
                            <TotalItem column="type1_amt" summaryType="sum" customizeText={(data) => numberWithCommas(data.value)} />
                            <TotalItem column="type0_amt" summaryType="sum" customizeText={(data) => numberWithCommas(data.value)} />
                        </Summary>
                        <Paging defaultPageIndex={0} pageSize={20} />
                    </DataGrid>
                </div>
            </div>
            {
                requestListNegtgesen.length > 0 &&
                <div>
                    <div className="card-header">
                        <span style={{ fontWeight: 'bold' }}>Харьяа байгууллагаас ирүүлсэн хүсэлтүүд:</span>
                    </div>
                    <div className="card-body">
                        <DataGrid
                            noDataText="Дата байхгүй байна."
                            dataSource={requestListNegtgesen}
                            rowAlternationEnabled={true}
                            wordWrapEnabled={true}
                            allowColumnResizing={true}
                            export={{ texts: { exportAll: "Excel загвар татах" } }}
                            onCellPrepared={onCellPreparedHandler}
                            showBorders={true}
                        >
                            <Grouping
                                autoExpandAll={true}
                                contextMenuEnabled={false}
                                expandMode="rowClick"
                                texts={{
                                    groupContinuesMessage: 'Дараагийн хуудас',
                                    groupContinuedMessage: 'Өмнөх хуудас'
                                }}
                            />
                            <FilterRow visible={true} />
                            <Export enabled={true} />
                            {user.organization.obt_id === 1 && <Column dataField="parent_req_org_name" groupCellComponent={(e) => e.data.displayValue} groupIndex={0} visible={user.organization.obt_id === 1} />}
                            <Column dataField="request_org_name" groupCellComponent={(e) => e.data.displayValue} groupIndex={user.organization.obt_id === 1 ? 1 : 0} />
                            <Column dataField="request_code" alignment="left" caption="Хүсэлтийн дугаар" width={90} />
                            <Column dataField="agenda_name" alignment="left" caption="Хөтөлбөр" />
                            <Column dataField="event_name" alignment="left" caption="Зориулалт" />
                            <Column dataField="econ_name" alignment="left" caption="ЭЗА" />
                            <Column dataField="month" alignment="center" width={"5em"} caption="Сар" />
                            <Column dataField="transfer_group" alignment="left" caption="Шилжүүлгийн төрөл" />
                            <Column dataField="schedule_amt" alignment="right" caption="Хуваарийн дүн" cellRender={(e) => e.value !== "0" ? `${numberWithCommas(e.value) + ' ₮'}` : '-'} allowFiltering={false} />
                            <Column dataField="type0_amt" alignment="right" caption="Хүлээн авах дүн" cellRender={cell_render} allowFiltering={false} />
                            <Column dataField="type1_amt" alignment="right" caption="Шилжүүлэх дүн" cellRender={cell_render} allowFiltering={false} />
                            <Column caption="Өөрчлөлтийн дараах дүн" alignment="right" cellRender={cellRenderChangeAmt} allowFiltering={false} />
                            <Paging defaultPageIndex={0} pageSize={10} />
                            <Summary>
                                <TotalItem column="type1_amt" summaryType="sum" customizeText={(data) => numberWithCommas(data.value)} />
                                <TotalItem column="type0_amt" summaryType="sum" customizeText={(data) => numberWithCommas(data.value)} />
                            </Summary>
                        </DataGrid>
                        {
                            current &&
                            <div>
                                <DataGrid
                                    noDataText="Одоогоор файл хавсаргаагүй байна"
                                    dataSource={subRequestFiles}
                                    onCellPrepared={onCellPreparedHandler}
                                    showBorders={true}
                                    columnAutoWidth={true}
                                    wordWrapEnabled={true}
                                >
                                    <Grouping
                                        contextMenuEnabled={false}
                                        expandMode="rowClick"
                                        texts={{
                                            groupContinuesMessage: 'Дараагийн хуудас',
                                            groupContinuedMessage: 'Өмнөх хуудас'
                                        }}
                                    />
                                    <Column dataField="schedule_transfer_request.Organization.name" autoExpandGroup={false} groupIndex={0} caption=""
                                        groupCellComponent={(e) => <div>{e.data.displayValue} ({e.data?.summaryItems[0].value + ' файл'})</div>} />
                                    <Column caption="Харьяа байгууллагын хавсаргасан файлууд" alignment="left" dataField="file.content_name" />
                                    <Column caption="Огноо" width='8rem' alignment="left" dataField="createdAt" dataType="date" format="yyyy-MM-dd" />
                                    <Column allowFiltering={false} caption="Хэмжээ" width='8rem' alignment="left" dataField="file.size" cellRender={(data) => {
                                        if (data.rowType === 'data') {
                                            let sizeMb;
                                            let sizeKb;
                                            if (data.data.file.file_size > 1048576) {
                                                sizeMb = (data.data.file.file_size / 1048576).toFixed(2) + 'MB'
                                            } else {
                                                sizeKb = (data.data.file.file_size / 1024).toFixed(2) + 'KB'
                                            }
                                            return <span>{sizeMb ? sizeMb : sizeKb}</span>
                                        }
                                    }} />
                                    <Column caption="Үйлдэл" alignment="center" width="10em" cellRender={viewFileCell} />
                                    <Paging defaultPageIndex={0} pageSize={8} />
                                    <Summary>
                                        <GroupItem summaryType='count' displayFormat='{0}' />
                                    </Summary>
                                </DataGrid>
                            </div>
                        }
                    </div>
                </div>
            }
            <div style={{ margin: 10 }} id='attachedInfos'>
                <div>
                    <TextArea
                        height={90}
                        value={requestNote ?? (request?.request_note)}
                        onValueChanged={(e) => setRequestNote(e.value)}
                        label="Нэмэлт тайлбар"
                        placeholder=''
                        disabled={approvePermission || buttonStatus()}
                    />
                </div>
            </div>
            <div>
                {
                    saveConditions.includes(sts) &&
                    <div style={{ display: "flex", gap: "1em", justifyContent: 'flex-end', margin: 10 }}>
                        <input type="file" name="files" style={{ display: "none" }} ref={inputFilesRef} multiple onChange={handleUpload} />
                        <Button text="Файл хавсаргах" icon="attach" style={{ fontWeight: 600 }} onClick={() => inputFilesRef.current.click()} />
                        <Button text="Албан бичиг боловсруулах" type="normal" style={{ fontWeight: 600 }} onClick={() => setDocumentPopup(true)}
                        />
                    </div>
                }
            </div>
            <div style={{ margin: 10 }}>
                <DataGrid dataSource={allFiles}
                    noDataText="Одоогоор файл хавсаргаагүй байна."
                    showBorders={true}
                    onCellPrepared={onCellPreparedHandler}>
                    <Column caption="Хавсаргасан файлууд" alignment="left" dataField="file.content_name" />
                    <Column caption="Огноо" width='8rem' alignment="left" dataField="createdAt" dataType="date" format="yyyy-MM-dd" />
                    <Column allowFiltering={false} caption="Хэмжээ" width='8rem' alignment="left" dataField="file.size" cellRender={(data) => {
                        if (data.rowType === 'data') {
                            let sizeMb;
                            let sizeKb;
                            if (data.data.file.file_size > 1048576) {
                                sizeMb = (data.data.file.file_size / 1048576).toFixed(2) + 'MB'
                            } else {
                                sizeKb = (data.data.file.file_size / 1024).toFixed(2) + 'KB'
                            }
                            return <span>{sizeMb ? sizeMb : sizeKb}</span>
                        }
                    }} />
                    <Column caption="Үйлдэл" alignment="center" width="8rem" cellRender={fileActionRender} />
                    <Paging defaultPageIndex={0} pageSize={8} />
                </DataGrid>
            </div>
            <div style={{ marginTop: 20 }}>
                <div className="card-header">
                    <span style={{ fontWeight: 'bold' }}>Шийдвэрлэлтийн түүх</span>
                </div>
                <div className="card-body">
                    <DataGrid
                        dataSource={requestLog}
                        onCellPrepared={onCellPreparedHandler}
                        noDataText="Дата байхгүй байна."
                        showBorders={true}
                        showRowLines={true}
                        showColumnLines={true}
                        columnAutoWidth={true}
                        wordWrapEnabled={true}>
                        <Column dataField="createdAt" alignment="left" caption="Шийдвэрлэлтийн огноо" dataType="date" format="yyyy-MM-dd HH:mm" />
                        <Column dataField="action_name" alignment="left" caption="Хүсэлтийн төлөв" cellRender={(e) => <div className={`schedule-transfer-status-${e.data.action}`}>{e.displayValue}</div>} />
                        <Column dataField="user_name" alignment="left" caption="Овог нэр" customizeText={(e) => e.value || '-'} />
                        <Column dataField="position_name" alignment="left" caption="Байгууллага, албан тушаал" customizeText={(e) => e.value || '-'} />
                        <Column dataField="comment" alignment="left" caption="Нэмэлт тайлбар" customizeText={(e) => e.value === 'null' ? '-' : e.value} />
                        <Paging defaultPageIndex={0} pageSize={8} />
                    </DataGrid>
                </div>
            </div>
            {
                <Joyride
                    run={true}
                    steps={reqInfoTabSteps}
                    continuous
                    showSkipButton
                    styles={{
                        options: {
                            zIndex: 100,
                            display: 'none',
                        }
                    }}
                    locale={guideLocale}
                    disableScrolling={true}
                />
            }
            {
                <Popup
                    height={'auto'}
                    width={request?.status === 1 ? 'auto' : 600}
                    visible={actionPopup}
                    title={'Баталгаажуулалт'}
                    onHiding={() => setActionPopup(false)}
                    resizeEnabled={true}
                    dragEnabled={true}
                    showTitle={true}>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: 20 }}>
                        <div><i><VerifyAction /></i></div>
                        {request?.status !== 1 &&
                            <textarea type="text" onChange={(e) => setLogComment(e.target.value)} required={true}
                                value={logComment}
                                placeholder="Үйлдлийн тайлбар..."
                                style={{ borderRadius: 4, borderColor: '#ddd', padding: 5, height: 120, outline: 'none' }}
                            />}
                        <div style={{ display: 'flex', justifyContent: 'center', gap: 10 }}>
                            <ActionButtons />
                            <Button onClick={() => setActionPopup(false)} text="Үгүй" width={98} />
                        </div>
                    </div>
                </Popup>
            }
            {
                <Popup
                    visible={documentPopup}
                    onHiding={() => setDocumentPopup(false)}
                    width={"70vw"}
                    height={"95vh"}
                    dragEnabled={true}
                    resizeEnabled={true}
                    fullScreen={false}
                    hideOnOutsideClick={true}
                    title={request?.request_code + " дугаартай хүсэлтийн албан бичиг"}
                >
                    <ScrollView>
                        <RequestDocument
                            approvePerson={request?.budget_head_name}
                            requestCode={request?.request_code}
                            isOpen={documentPopup}
                        />
                    </ScrollView>
                </Popup>
            }
            {
                <Popup
                    height="95vh"
                    width="60vw"
                    maxWidth="100vw"
                    visible={currentFile}
                    onHiding={() => setCurrentFile(null)}
                    title={`Файлын нэр: ${currentFile?.file?.content_name ?? '-'}`}
                    dragEnabled={false}
                    hideOnOutsideClick={true}
                >
                    {
                        currentFile?.file?.file_extention === 'application/pdf' ?
                            <object width="100%" height="100%" aria-label="file" data={urlServer + '/api/file/documents/' + currentFile?.file?.file_name} type="application/pdf" />
                            :
                            <img alt="" src={urlServer + '/api/file/documents/' + currentFile?.file?.file_name} width={'100%'} />
                    }
                </Popup>
            }
        </div>
    )
}

export default RequestInformation
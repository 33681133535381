import React, {useState, useEffect, useRef, useCallback, useContext} from "react";
import OrganizationService from "../../services/api/organization";
import Accordion from "devextreme-react/accordion";
import {Popup} from "devextreme-react/popup";
import './css/styles.css';
// Devextreme
import {Tooltip} from "devextreme-react/tooltip";
import {UserContext} from "../../hooks/UserContext";
import lawIcon1 from '../../assets/imgs/lawIcon2.png';
import strategy_icon from "../../assets/imgs/structureIcon2.png";
import notify from "devextreme/ui/notify";


import lawIcon from "../../assets/imgs/lawIcon.png";
import {urlServer} from "../../shared/lib/request";
import {useHistory} from "react-router-dom";
import OrgContact from "./components/TopHeaderComponent";
import editIcon from "../../assets/imgs/icon/edit.png";
import CrudGeneralInfo2 from "../../pages/news/component/newAddNews";
import ScrollView from "devextreme-react/scroll-view";
import LoadPanelComponent from "../budget/components/loadPanel";
import { titleIcon } from "../../components/accordion";
import { InfoGroupNames, InfoGroups } from "../../util";
import {CgScreen} from "react-icons/cg";
import OgForeCastPdf from '../../assets/pdfFiles/Танилцуулга.pdf';

const UpdatedHome = (props) => {
    const {user} = useContext(UserContext);
    const [selectedItems, SetSelectedItem] = useState(false);
    const [withAnimationVisible, setWithAnimationVisible] = useState(false);
    const [withAnimationVisible2, setWithAnimationVisible2] = useState(false);
    const [withAnimationVisibleEdit, setWithAnimationVisibleEdit] = useState(false);
    const [withAnimationVisible3, setWithAnimationVisible3] = useState(false);
    const [popUpModalOgForeCast, setPopUpModalOgForecast] = useState(false);
    const [popUpEditGeneral, setPopUpEditGeneral] = useState(false);
    const [dateNow, setDateNow] = useState(null);
    const [mainData, setMainData] = useState([]);
    const [link, setLink] = useState(null);
    const [orgInformation, setOrgInformation] = useState(null);
    const [popUpModal, setPopUpModal] = useState(false);
    const [popUpModalStrategy, setPopUpModalStrategy] = useState(false);
    const [popUpModalSSA, setPopUpModalFileSSA] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    //const [hasPdf, setHasPdf] = useState(false);
    const [fileNameData, setFileNameData] = useState(null);
    //const position = { of: '#fragment-id' }
    const [banner, setBanner] = useState([]);
    const [ssaFile, setFileSSA] = useState([]);
    const [generalInfo, setGeneralInfo] = useState()
    let history = useHistory();
    const accordionRef= useRef(null)
    const animation = {
        show: {
            type: "slide",
            from: {
                left: 2000,
                opacity: 0.2
            },
            to: {
                opacity: 1
            },
            duration: 800
        }
        ,
        hide: {
            type: "slide",
            to: {
                opacity: 0,
                right: -1000
            },
      ///////      duration: 800
        }
    }


// const base_image_url = 'http://localhost:3002/api/uploads/documents/';
    //const base_image_url = '/api/uploads/documents/';

    useEffect(()=>{
        if (history.location && history.location.state) {
            // console.log("istory.location.state.selected", history.location.state.selected)
            SetSelectedItem(history.location.state.type)
            // console.log("selected item", history.location.state.type)
        }

        loadStateStructure()
    },[props.org_id])
    const loadStateStructure = async () => {
        setIsLoading(true);
        // let r = await OrganizationService.getAttrsOrg();
        let reserve = props.org_id ? await OrganizationService.getUpdatedAttributes(props.org_id): await OrganizationService.getUpdatedAttributes(user.organization.id);
        setOrgInformation(reserve.lawData);


        const ungroupedInfo = []    
        const groupedInfo = {}
        // СТРАТЕГИ ТӨЛӨВЛӨГӨӨ​
        //     Алсын хараа ​
        //     Эрхэм зорилго​
        //     Стратегийн зорилго​
        //     Стратегийн зорилт ​
        //     Стратеги 
        // ҮЙЛ АЖИЛЛАГААНЫ СТРАТЕГИ, ЗОХИОН БАЙГУУЛАЛТЫН БҮТЦИЙН ӨӨРЧЛӨЛТИЙН ХӨТӨЛБӨР​
        //     Үйл ажиллагааны тэргүүлэх чиглэл​
        //     Үйл ажиллагааны эрхэм зорилго​
        //     Үйл ажиллагааны стратегийн зорилт​
        //     Үйл ажиллагааны гол зорилт
        // ЧИГ ҮҮРЭГ
            // Эрх Зүйн шинэтгэлийн бодлого
            // Хууль тогтоомжийн системчлэл, мэдээлэл эрх зүйн сургалт, сурталчилгаа, судалгаа
            // Эрх зүйн туслалцаа
            // улсын хил хамгааллын асуудал


        // console.log('main data', mainData)
        reserve.structureData.state_structure_attributes.forEach((data)=>{
            const name = data.name.toLowerCase()
            if(InfoGroups.hasOwnProperty(name)){
                if(groupedInfo.hasOwnProperty(InfoGroups[name])){
                    groupedInfo[InfoGroups[name]].push(data)
                }
                else{
                    groupedInfo[InfoGroups[name]] = [data]
                }
            }
            else{
                ungroupedInfo.push(data)
            }
        })
        setGeneralInfo(groupedInfo)
        // console.log("groupedInfo", groupedInfo)
        // setLink(orgInformation.legal_url);
        // setMainData(reserve.structureData && reserve.structureData.state_structure_attributes!==null ? reserve.structureData.state_structure_attributes || [] : []);
        setMainData(ungroupedInfo??[]);
        if (history.location && history.location.state && history.location.state.type){
            // console.log("index is ", history.location.state.type)
            expandCustomItem(history.location.state.type)
        } else if(props.type){
            expandCustomItem(props.type) 
        }
       

        // console.log('data', mainData);
        // if (user.start_of_ancestry === 144 && user.position_id === 1) {
        //     setMainData(prevState => [...prevState, {
        //         name: 'historyTimeline',
        //         description: 'Түүхэн замнал',
        //         attribute_details: [],
        //         value: 'timeline'
        //     }])
        // }



        // console.log("end yrunhii mdeelel data inree",r[2].value)



        // let sendId = (user.username!== "02" || props.location.state.name === undefined) ? ((props.location.state === undefined || props.location.state === null) ?
        //     user.organization.id :
        //     (props.location.state.isfirst !== undefined ?  props.location.state.toId : user.organization.id)): props.location.state.org_id
        // setId(sendId)

//Баннер дуудаж харуулах
       // let result = await OrganizationService.getOrgStateStructureInfo(user.organization.id);
        let banner = await OrganizationService.getFileNameStateStructure(user.organization.id);
        setBanner(banner.data[0]);
        let resultImage =await OrganizationService.getFileNameStateStructure(props.org_id !== undefined ? props.org_id : user.organization.id);
        setFileNameData(resultImage.data[0]);
        // console.log('resultImage.data[0]', resultImage.data[0]);
        // if(resultImage.data[0] && resultImage.data[0].strategy_file_name!==null) setWithAnimationVisible2(true);
        setIsLoading(false);
    }

    const customTitle = useCallback((data) => {
        return (
            <div style={{ display: 'flex', fontFamily: 'Montserrat'}}>
                {titleIcon(data?.name)}
                <div style={{fontSize: '15px', textTransform:"upperCase", fontWeight: "bold", paddingTop:5 ,justifyContent:"space-between",flexDirection: 'row',display: 'flex', flex: 1}}>
                    <div>{data.description}</div>
                    <div style={{display: 'flex'}}>
                        {
                            data.file_model &&
                            <div className="law-bnt-style" style={{paddingRight:5}} onClick={() => openModalFileSSA(data.file_model)}>
                                <img loading="lazy" src={strategy_icon} style={{ width: 'auto', height: '20px', paddingRight:10 ,marginTop: -15}}/>
                            </div>
                        }
                        {
                            data.law_model && <div className="law-bnt-style" style={{paddingRight:20}} onClick={() => openModal(data.law_model && data.law_model.legal_url)}>
                            <img loading="lazy" src={lawIcon} style={{ width: 'auto', height: '20px', paddingRight:10 ,marginTop: -15}}/>
                        </div>
                        }
                    </div>
                </div>
            </div>
        )
    },[])

    const hideInfo = () => {
        setLink(null);
        setPopUpModal(false);
    }
    // const expandHandler= useCallback(()=>{
    //     accordionRef.current.instance.expandItem(history.location.state.type)
    // },[])
    const hideStrategyModal = () => {
        setPopUpModalStrategy(false);
    }
    const hideOgForecastModal = () => {
        setPopUpModalOgForecast(false);
    }
    const hideFileSSAModal = () => {
        setPopUpModalFileSSA(false);
    }
    const openModalFileSSA = (data) => {
        if(data){
            setFileSSA(data);
            setPopUpModalFileSSA(true);
        } else {
            //     notify('Хууль эрх, зүйн мэдээлэл оруулаагүй байна!', "warning", 2000);
            notify({
                message: 'Файл мэдээлэл оруулаагүй байна!',  width: 300, position: {
                    my: 'right top',
                    at: 'right top',
                    of: "#floating-div-2"
                },
                animation: animation,
            }, "warning", 2500);
            setPopUpModalFileSSA(false)
        }


    }
    const openModal = (data) => {
        if(data){
            setLink(data);
            setPopUpModal(true);
        } else {
        //     notify('Хууль эрх, зүйн мэдээлэл оруулаагүй байна!', "warning", 2000);
            notify({
                message: 'Хууль эрх, зүйн мэдээлэл оруулаагүй байна!',  width: 300, position: {
                    my: 'right top',
                    at: 'right top',
                    of: "#floating-div-2"
                },
                animation: animation,
                }, "warning", 2500);
            setPopUpModal(false)
        }


    }
    const openModalStrategy = async () => {
        if(fileNameData && fileNameData.strategy_file_name !==null){
            setPopUpModalStrategy(true);
        } else {
            notify({
                message: 'Стратеги төлөвлөгөөтэй холбоотой файл оруулаагүй байна!',  width: 300, position: {
                    my: 'right top',
                    at: 'right top',
                    of: "#floating-div-2"
                },
                animation: animation,
            }, "warning", 2500);
            setPopUpModalStrategy(false)
        }
    }
    const openModalOgForeCast = async () => {
        setPopUpModalOgForecast(true);
    }
    const openEditGeneral = async () => {
        setDateNow(Date.now());
        setPopUpEditGeneral(true)
    }
    const customItem = useCallback((data) => {
        return (
            <div style={{fontFamily: "Montserrat"}}>
                {data.attribute_details.length != 0 &&
                    <>
                        {data.attribute_details.map((item, index) => (
                            item.is_single_field ?
                            <div key={index} className="card" style={{ boxShadow: '2px 2px 7px -1px rgba(0,0,0,0.25)', padding: '.5rem' }}>
                                <div className="card-header" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '.5rem' }}>
                                    <span style={{ textTransform: 'uppercase', fontSize: '1rem', fontWeight: 'bold' }}>{index + 1 + '.  '} </span>
                                    <div dangerouslySetInnerHTML={{ __html: item.policy_desc }} style={{
                                        textTransform: 'uppercase', fontSize: '1rem', fontWeight: 'bold', width: "100%" }}></div>
                                </div>
                                <div className="card-body" style={{width: "100%", paddingTop: 0}}>
                                    {item.description && <p><span
                                        style={{fontWeight: 'bold'}}>Хэрэгжүүлэх үйл ажиллагаа: </span><br/>
                                        <div dangerouslySetInnerHTML={{ __html: item.description }} style={{ textAlign: 'justify', width: "100%" }}></div>
                                    </p>}
                                    {item.agency && <p><span
                                        style={{fontWeight: 'bold'}}>Яамны хэрэгжүүлэх газар хэлтэс: </span><br/>
                                        <div dangerouslySetInnerHTML={{ __html: item.agency }} style={{ textAlign: 'justify', width: "100%" }}></div>
                                    </p>}
                                    {item.branch && <p><span style={{fontWeight: 'bold'}}>Яамны харьяа хэрэгжүүлэх агентлаг бусад байгууллага: </span><br/>
                                        <div style={{ textAlign: 'justify', width: "100%" }} dangerouslySetInnerHTML={{ __html: item.branch }}></div>
                                    </p>}
                                    {item.event_desc && <p><span style={{fontWeight: 'bold'}}>Төсөл хөтөлбөр: </span><br/><div style={{ textAlign: 'justify', width: "100%" }}
                                                                                                                               dangerouslySetInnerHTML={{__html: item.event_desc}}>
                                    </div>
                                    </p>}
                                </div>
                            </div>
                                :
                                <div key={index} className="card-body" style={{marginTop:5,marginBottom:5,borderRadius:15, boxShadow: '2px 2px 7px -1px rgba(0,0,0,0.25)' }}>
                                        {/*<span style={{ textTransform: 'uppercase', fontSize: 'large', fontWeight: 'bold' }}>{index + 1 + '. '}</span>*/}
                                        <div dangerouslySetInnerHTML={{ __html: item.policy_desc }} style={{ //textAlign: 'justify',
                                            width: "100%", padding: ".25rem .6rem" }}></div>
                                </div>
                        ))}
                    </>
                  /*  :
                    <div>
                        {data.value === 'timeline' ? <img loading="lazy" alt="history-time-line" src={timeLinePic} style={{ width: '100%' }}/> : <div dangerouslySetInnerHTML={{ __html:  data && data.value && data.value.replaceAll(';', '<br><br>') }}/>}
                    </div>*/
                }
            </div>
        )
    },[])

    const Carditem = useCallback((datasource, title) => {
        return(
            <div className={'card'}>
                {title&&
                <div className={'card-header'}>      
                    <div className='row'>
                        <span style={{color: "#2E4765", fontWeight: "bold", textTransform:"uppercase"}}>{title}</span>
                    </div>
                </div>
                }
                <div className={'card-body'}>
                    <Accordion
                        className='og-accordion'
                        // ref={accordionRef}
                        dataSource={datasource}
                        collapsible={true}
                        animationDuration={300}
                        noDataText='Дата байхгүй байна.'
                        itemTitleRender={customTitle}
                        itemRender={customItem}
                    />
                </div>
            </div>
        )
    },[customTitle, customItem])

    useEffect(() => {
        loadStateStructure()
    
 /*       let pdfList = [278, 334, 403, 420, 262, 404, 273]
        if(pdfList.includes(user.organization.id)){
            setHasPdf(true)
        }*/
    }, [])
    const expandCustomItem= useCallback((id)=>{
        accordionRef.current && accordionRef.current.instance.expandItem(id)
    },[])
    const tooltipStyle={
        padding: ".25rem .5rem",
        display: 'flex',
        alignItems: "center"
    }
    return (
        <div className={'row updatedHome'}>
                <div style={{width:"100%", marginTop:'0.5em'}}>
                    <LoadPanelComponent position={{of: '#home'}} visible={isLoading}/>
                    <Popup
                        width={'80%'}
                        height={'90%'}
                        minWidth={'80%'}
                        maxWidth={'100%'}
                        minHeight={'90%'}
                        visible={popUpEditGeneral}
                        onHiding={() => setPopUpEditGeneral(false)}
                        resizeEnabled={true}
                        dragEnabled={true}
                        showTitle={true}
                        hideOnOutsideClick={true}
                    >
                        <ScrollView>
                            <CrudGeneralInfo2 org_id={user.organization.id} org_name={user.organization.name} dateNow= {dateNow}/>
                        </ScrollView>
                    </Popup>
                    <Popup
                        visible={popUpModal}
                        onHiding={hideInfo}
                        dragEnabled={false}
                        hideOnOutsideClick={true}
                    >
                        <iframe src={link} title="wot" width="100%" height="100%"></iframe>
                    </Popup>
                    {ssaFile &&
                        <Popup
                            width={1200}
                            maxWidth={'100%'}
                            minHeight={'100%'}
                            height={'100%'}
                            visible={popUpModalSSA}
                            onHiding={hideFileSSAModal}
                            title="Файл"
                            dragEnabled={false}
                            hideOnOutsideClick={true}
                        >
                            {
                                ssaFile.file_extention == 'application/pdf' ?
                                    <object width="100%" height="100%"
                                            data={urlServer + '/api/file/documents/' + ssaFile.file_name}
                                            type="application/pdf"/> :
                                    <img
                                        src={urlServer + '/api/file/documents/' + ssaFile.file_name}
                                        width={'100%'}/>
                            }
                        </Popup>}
                    {fileNameData && fileNameData.strategy_file_name !==null &&
                    <Popup
                        width={1200}
                        maxWidth={'100%'}
                        minHeight={'100%'}
                        height={'100%'}
                        visible={popUpModalStrategy}
                        onHiding={hideStrategyModal}
                        title="Стратеги төлөвлөгөө"
                        dragEnabled={false}
                        hideOnOutsideClick={true}
                    >
                        {
                            fileNameData.strategy_extension == 'application/pdf' ?
                                <object width="100%" height="100%"
                                        data={urlServer + '/api/file/documents/' + fileNameData.strategy_file_name}
                                        type="application/pdf"/> :
                                <img
                                    src={urlServer + '/api/file/documents/' + fileNameData.strategy_file_name}
                                    width={'100%'}/>
                        }
                    </Popup>}
                    {popUpModalOgForeCast &&
                        <Popup
                            width={1200}
                            maxWidth={'100%'}
                            minHeight={'100%'}
                            height={'100%'}
                            visible={popUpModalOgForeCast}
                            onHiding={hideOgForecastModal}
                            title="Танилцуулга"
                            dragEnabled={false}
                            hideOnOutsideClick={true}
                        >
                            <object width="100%" height="100%"
                                    data={OgForeCastPdf}
                                    type="application/pdf" title={'Танилцуулга'}/>
                        </Popup>}
                    <div style={{width:'100%'}}>

                    {!isLoading && <OrgContact urlServer={urlServer} fileNameData={fileNameData && fileNameData.length === 0 ? null : fileNameData} orgId={props.org_id ? props.org_id : user.organization.id}/> }
                    {/* <div className="card-header"> */}
                        <div style={{ 'display': 'flex'}}>
                            {/* <div className='row'>
                                <span style={{color: "#2E4765", fontWeight: "bold"}}>ЕРӨНХИЙ МЭДЭЭЛЭЛ</span>
                            </div> */}
                            <div className='row' style={{
                                'display': 'flex',
                                flexDirection: 'row-reverse',
                                paddingTop: 10
                            }} >
                                {/*{ orgInformation && orgInformation.legal_url &&*/}

                                <a id="asdf"
                                   onMouseEnter={() => setWithAnimationVisible(!withAnimationVisible)}
                                   onMouseLeave={() => setWithAnimationVisible(!withAnimationVisible)}
                                >
                                    <div className="law-bnt-style" style={{paddingRight:20}} onClick={() => openModal(orgInformation && orgInformation.legal_url)}>
                                        <img loading="lazy" src={lawIcon1} style={{ width: 'auto', height: '25px'}}/>
                                    </div>
                                </a>
                                {/*}*/}

                                <div>
                                    <a id="asdf3"
                                       onMouseEnter={() => setWithAnimationVisible2(!withAnimationVisible2)}
                                       onMouseLeave={() => setWithAnimationVisible2(!withAnimationVisible2)}
                                    >
                                        <div className="law-bnt-style" onClick={() => openModalStrategy()}>
                                            <img loading="lazy" src={strategy_icon} style={{
                                                width: 'auto',
                                                height: '25px',
                                                // opacity: fileNameData && fileNameData.strategy_file_name !==null ? '1' : '0.3' 
                                                }}/>
                                        </div>
                                    </a>
                                </div>
                                <div>
                                    <a id="ogForecast"
                                       onMouseEnter={() => setWithAnimationVisible3(!withAnimationVisible3)}
                                       onMouseLeave={() => setWithAnimationVisible3(!withAnimationVisible3)}
                                    >
                                        <div className="law-bnt-style" onClick={() => openModalOgForeCast()}>
                                            <CgScreen size={"25px"} color="#909090" width={'auto'}/>
                                        </div>
                                    </a>
                                </div>
                                <div>
                                    <a id="editHome"
                                       onMouseEnter={() => setWithAnimationVisibleEdit(!withAnimationVisibleEdit)}
                                       onMouseLeave={() => setWithAnimationVisibleEdit(!withAnimationVisibleEdit)}
                                    >
                                        {
                                            user.roles[0].role_id == 1 &&
                                            <div className="law-bnt-style" onClick={() => openEditGeneral()}>
                                                <img loading="lazy" src={editIcon} style={{
                                                    width: '22px',
                                                    height: '22px',
                                                    opacity: 1 }}/>
                                            </div>
                                        }

                                    </a>
                                </div>

                            </div>
                        </div>
                    {/* </div> */}

                            
                    <Tooltip
                        target="#asdf"
                        position="bottom"
                        visible={withAnimationVisible}
                        hideOnOutsideClick={true}
                    >
                        {/*<div>Хууль, эрх зүйн: {orgInformation && orgInformation.name ? orgInformation.name : ' мэдээлэл оруулаагүй байна!'}</div>*/}
                        <span style={tooltipStyle}>Хууль, эрх зүй харах</span>
                    </Tooltip>

                    <Tooltip
                        target="#asdf3"
                        position="bottom"
                        visible={withAnimationVisible2}
                        hideOnOutsideClick={true}
                    >
                        <span style={tooltipStyle}>Стратеги төлөвлөгөө харах</span>
                    </Tooltip>
                        <Tooltip
                            target="#ogForecast"
                            position="bottom"
                            visible={withAnimationVisible3}
                            hideOnOutsideClick={true}
                        >
                            <span style={tooltipStyle}>Танилцуулга харах</span>
                        </Tooltip>

                    <Tooltip
                        target="#editHome"
                        position="bottom"
                        visible={withAnimationVisibleEdit}
                        hideOnOutsideClick={true}
                    >
                        <span>Ерөнхий мэдээлэл засах</span>
                    </Tooltip>
                    {/* <div className={'card-body'}> */}
                        <div id={'mainInfo'} style={{minHeight: 300}}>
                            {mainData && mainData.length > 0 && Carditem(mainData, 'ерөнхий мэдээлэл')}
                        {generalInfo &&
                                <div>
                                    {generalInfo?.['duty']&&Carditem(generalInfo['duty'], user.organization.obt_id===1?InfoGroupNames['duty']:false)}
                                    {generalInfo?.['oper']&&Carditem(generalInfo['oper'], InfoGroupNames['oper'])}
                                    {generalInfo?.['strat']&&Carditem(generalInfo['strat'], InfoGroupNames['strat'])}

                                </div>
                            }

                        </div>
                    {/* </div> */}
                </div>
            </div>
        </div>
    )
}

export default UpdatedHome;

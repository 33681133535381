import React, {Component} from 'react';
import BarChart from "./components/barChart";
import {Link} from "react-router-dom";
import Button from "devextreme-react/button";

class Test extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
    }

    render() {
        return (
            <>
                <div className='row'>
                    <div className='col-4'>
                        <div className='card'>
                            <div className='card-header'  style={{display: 'flex'}}>
                                <div style={{flex: 1}}>
                                    <span>title</span>
                                </div>

                                <Link to={''} style={{display: 'flex', width: 46, height: 46, alignItems: 'center', justifyContent: 'center'}}>
                                    <Button icon='detailslayout' className={''}/>
                                </Link>
                            </div>
                            <div className='card-body'>
                                <BarChart data={
                                    [
                                        {name: 'test', amount: 1200, amount_performance: 500},
                                        {name: 'test1', amount: 1200, amount_performance: 500},
                                        {name: 'test2', amount: 1200, amount_performance: 500},
                                        {name: 'test3', amount: 1200, amount_performance: 500},
                                        {name: 'test4', amount: 1200, amount_performance: 500}
                                    ]
                                }/>
                            </div>
                        </div>
                    </div>
                    <div className='col-4'>
                        <div className='card'>
                            <div className='card-header'  style={{display: 'flex'}}>
                                <div style={{flex: 1}}>
                                    <span>title</span>
                                </div>

                                <Link to={''} style={{display: 'flex', width: 46, height: 46, alignItems: 'center', justifyContent: 'center'}}>
                                    <Button icon='detailslayout' className={''}/>
                                </Link>
                            </div>
                            <div className='card-body'>
                                <BarChart data={
                                    [
                                        {name: 'test', amount: 1200, amount_performance: 500},
                                        {name: 'test1', amount: 1200, amount_performance: 500},
                                        {name: 'test2', amount: 1200, amount_performance: 500},
                                        {name: 'test3', amount: 1200, amount_performance: 500},
                                        {name: 'test4', amount: 1200, amount_performance: 500}
                                    ]
                                }/>
                            </div>
                        </div>
                    </div>
                    <div className='col-4'>
                        <div className='card'>
                            <div className='card-header'  style={{display: 'flex'}}>
                                <div style={{flex: 1}}>
                                    <span>title</span>
                                </div>

                                <Link to={''} style={{display: 'flex', width: 46, height: 46, alignItems: 'center', justifyContent: 'center'}}>
                                    <Button icon='detailslayout' className={''}/>
                                </Link>
                            </div>
                            <div className='card-body'>
                                <BarChart data={
                                    [
                                        {name: 'test', amount: 1200, amount_performance: 500},
                                        {name: 'test1', amount: 1200, amount_performance: 500},
                                        {name: 'test2', amount: 1200, amount_performance: 500},
                                        {name: 'test3', amount: 1200, amount_performance: 500},
                                        {name: 'test4', amount: 1200, amount_performance: 500}
                                    ]
                                }/>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default Test;
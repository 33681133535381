import React, { useRef, useState, useEffect,useContext } from "react";
import SelectBox from "devextreme-react/select-box";
import {getYears} from "../../../util";
import DataGrid, {Column, Export, Selection, Summary, TotalItem, Grouping, GroupPanel, SearchPanel,GroupItem} from "devextreme-react/data-grid";
import {Workbook} from "exceljs";
import {exportDataGrid} from "devextreme/excel_exporter";
import {saveAs} from "file-saver-es";
import fcsServices from "../../../services/api/fcs";
import notify from "devextreme/ui/notify";
import { Button } from 'devextreme-react/button';
import ScrollView from "devextreme-react/scroll-view";
import { Popup } from 'devextreme-react/popup';
import fcsService from "../../../services/api/fcs";
import DateBox from "devextreme-react/date-box";
import {UserContext} from "../../../hooks/UserContext";
import {confirm} from "devextreme/ui/dialog";
import LoadPanelComponent from "../../../components/LoadPanelComponent";
const monthList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

export default function InventoryReport() {
    const userListPosition = { of: '#usersList' }
    const [loader, setLoader] = useState(false);
    const myRef = useRef(null);
    const {user} = useContext(UserContext);
    const componentRef = useRef();
    const [yearValue, setYearValue] = useState(new Date().getFullYear());
    const [monthValue, setMonthValue] = useState(new Date().getMonth()+1);
    const [report, setReport] = useState([]);
    const [reportDetail, setReportDetail] = useState([]);
    const [locationValue, setlocationValue] = useState('');
    const [locationCodeValue, setlocationCodeValue] = useState('');
    const [selectedValue, setSelectedValue] = useState(null);
    const [modalVisible, setModalVisible] = useState(false);
    const [modalVisibleGL, setModalVisibleGL] = useState(false);
    const [modalVisibleGLSave, setModalVisibleGLSave] = useState(false);
    const [modalVisibleMonthSave, setModalVisibleMonthSave] = useState(false);
    const [dataBalMonth, setDataBalMonth] = useState([]);
    const [reportGL, setReportGL] = useState([]);
    const [jrNo, setJrNo] = useState('');
    const [glDate, setGlDate] = useState();
    const [dataFcsGLTran, setFcsGLTran] = useState([]);
    const [reportInvDetail, setReportInvDetail] = useState([]);
    const [invValue, setInvValue] = useState('');
    const [incomeTxn, setIncomeTxn] = useState([]);
    const [expenseTxn, setExpenseTxn] = useState([]);
    const [visibleIncomeTxn, setVisibleIncomeTxn] = useState(false);
    const [visibleExpenseTxn, setVisibleExpenseTxn] = useState(false);

    useEffect(async () => {
        getInventoryReport()
    }, []);

    const getInventoryReport = async () => {
        try {
            setLoader(true);
            const fcsMonth = await fcsService.getAllFcsMonth('IN');

            if ( fcsMonth && fcsMonth.length < 1) notify(" тохиргоо хийнэ үү  ", "warning")
            // if ( fcsMonth && fcsMonth.length < 1) setLoader(false)
            if ( fcsMonth && fcsMonth.length < 1) return setLoader(false)


            const result = await fcsServices.getFcsInvReportByLocation(new Date().getFullYear(),new Date().getMonth()+1);
            const resultGlTran = await fcsService.getFcsGlTran(fcsMonth[0].year,fcsMonth[0].month,'IN');
            setFcsGLTran(resultGlTran);
            setReport(result.data);
            return setLoader(false)
        } catch (e) {
            notify(e.message, "error", 2000);
        }
    }

    const changeYear = (e) => {
        setYearValue(e);
    }

    const changeMonth = (e) => {
        setMonthValue(e);
    }

    const onExporting = (e) => {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Main sheet');

        exportDataGrid({
            component: e.component,
            worksheet,
            autoFilterEnabled: true,
        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'DataGrid.xlsx');
            });
        });
        e.cancel = true;
    }

    const onSelectionChanged = ({ selectedRowsData }) => {
        setlocationValue(selectedRowsData[0].loctn_id);
        setlocationCodeValue(selectedRowsData[0].loctn_code);
        setSelectedValue(selectedRowsData[0]);
    }
    const onSelectionInvChanged = async ({ selectedRowsData }) => {
      //  setLoader(true);
        setInvValue(selectedRowsData[0]);
        const result = await fcsServices.getFcsFuncInvReportDetail(yearValue,monthValue,locationValue,selectedRowsData[0].inv_id);
        if (result.data.length>0){
            setReportInvDetail(result.data);
           // setSelectedInvValue(selectedRowsData[0]);
            return setLoader(false);
        }
        else {
            return setLoader(false);
        }

    }

    const customizeDate = (data) => {
        return Number(parseFloat(data.value).toFixed(2)).toLocaleString("en", {
            minimumFractionDigits: 2,
        })
    }
    const getReport = async () => {
        try {
            const result = await fcsServices.getFcsInvReportByLocation(yearValue,monthValue)
            setReport(result.data);
        } catch (e) {
            notify(e.message, "error", 2000);
        }
    };

    const getReportDetails = async () => {
        if (locationValue === '') return;
        setLoader(true);
        const result = await fcsServices.getFcsInvReportByLocationDetail(yearValue,monthValue,locationValue);
        if (result.data.length>0){
            setReportDetail(result.data);
            setModalVisible(true);
            return setLoader(false);
        } else {
            return setLoader(false);
        }
    };

    const getReportGL = async () => {
        setLoader(true);
        const resultGL = await fcsServices.getFcsGlMonthReport('IN');
        if (resultGL.data.length>0) {
            setReportGL(resultGL.data);
            setModalVisibleGL(true);
            return setLoader(false);
        }
        else {
            return setLoader(false);
        }
    };
    const getReportGLSave = async () => {
        setLoader(true);
        const resultGL = await fcsServices.getFcsGlMonthReport('IN');
        if (resultGL.data.length>0) {
            setReportGL(resultGL.data);
            setModalVisibleGLSave(true);
            return setLoader(false);
        }
        else {
            return setLoader(false);
        }
    };
    const getReportMonthSave = async () => {


        try {


        setLoader(true);
        const resultMonth = await fcsServices.getFcsInvBalMonth(2022,2);
        setLoader(false);

        if (resultMonth.data.length>=0) {
            setDataBalMonth(resultMonth.data);
            setModalVisibleMonthSave(true);
            return setLoader(false);
        }
        else {
            return setLoader(false);
            notify("Сарын хаалт хийх боломжгүй", "warning");


        }

        } catch (err)  {
    console.log(err)
            setLoader(false)
        }


         setLoader(false);
    };
    const getIncomeTxn = async () => {
        setLoader(true);
        if(yearValue && monthValue) {
            try {
                const result = await fcsServices.getAllFcsInOrLin(yearValue, monthValue)
                if (result) {
                    setIncomeTxn(result);
                    setVisibleIncomeTxn(true);
                    return setLoader(false);
                }
                else {
                    return setLoader(false);
                }

            } catch (e) {
                notify(e.message, "error", 2000);
            }
        }
    };
    const getExpenseTxn = async () => {
        setLoader(true);
        if(yearValue && monthValue) {
        try {
            const result = await fcsServices.getAllFcsInvIss(yearValue,monthValue)
            if (result) {
                setExpenseTxn(result);
                setVisibleExpenseTxn(true);
                return setLoader(false);
            }
            else {
                return setLoader(false);
            }

        } catch (e) {
            notify(e.message, "error", 2000);
        }
        }
    };

    const onValueChangedDate = (e) => {
        setGlDate(e.value);
    }
    const jrChangeValue = (event) => {
        setJrNo(event.target.value);
    }

    const setGLData = async () => {
        if (jrNo === null || glDate === null) return notify("хоосон байна", "warning");
        const date = new Date(glDate);//new e.changes[0].data.date_recpt;
        const year = date.getFullYear() * 1e4; // 1e4 gives us the the other digits to be filled later, so 20210000.
        const month = (date.getMonth() + 1) * 100;
        const yyyymm = year + month // `+ ''` to convert to string from number, 202206
        let date1 = reportGL[0].pyear +'/'+ reportGL[0].pmonth
        let datetimenew = new Date(date1)
        let year1 = datetimenew.getFullYear() * 1e4; // 1e4 gives us the the other digits to be filled later, so 20210000.
        let month1 = (datetimenew.getMonth() + 1) * 100;
        const yyyymm_month = year1 + month1
        if(yyyymm === yyyymm_month && jrNo !== null) {
            if(dataFcsGLTran.length === 0){
                let docdate = null
                let jr_key = null;
                reportGL.forEach(element => {
                    docdate = glDate;
                    jr_key = jrNo;
                });
                reportGL.map((item) =>{
                    item.docdate=glDate;
                    item.jr_key='IN';
                    item.acct=item.account;
                    item.descr=item.description;
                    item.brn=item.docno;
                    item.docno=jrNo;
                    item.orgcode=item.cust_code;
                    item.created_user_id=user.id;
                })
                getInventoryReport();
                await fcsService.addFcsGLTran(reportGL);

                notify('Амжилттай ерөнхий дэвтэрлүү шилжүүллээ', "success", 2000);
            }
            else {
                notify('Тухайн сард та ерөнхйи дэвтэрлүү илгээсэн байна', "error", 2000);
            }
        }
        else {
            notify('Сонгосон огноо тайлант он сарын хугацаанд биш байна', "error", 2000);
        }
    }

    const setInvBalData = async () => {
        let result = confirm("<i>Та сарын хаалт хийхдээ итгэлтэй байна уу?</i>", "Баталгаажуулалт");
        result.then(async (dialogResult) => {
            setLoader(true);
            if(dialogResult && dataBalMonth.length >= 0){
                dataBalMonth.map((item) =>{
                    item.created_user_id=user.id;
                })
                const fcsMonth = await fcsService.getAllFcsMonth('IN');
                fcsMonth.map(e => {
                    if(e.month < 13){
                        e.month = e.month + 1
                    }
                })
                await fcsService.addFcsInvBal(dataBalMonth);
                await fcsService.editFcsMonth(fcsMonth[0]);
                getInventoryReport();
                setModalVisibleMonthSave(false);

                notify('Амжилттай сарын хаалт хийлээ', "success", 2000);
                return setLoader(false);
            }
            else {return setLoader(false);}
        });
        }

        const style={
            text: {
                color: "#2E4765", fontWeight: "700", fontSize: ".875rem"
            },
            box: {
                border: "none", backgroundColor: "#F6F6F6", borderRadius: ".5rem", padding: ".25rem .15rem", width: "100%"
            }
        }
    return (
        <React.Fragment>
            <div ref={myRef} style={{padding: "1rem"}} >
                <Popup
                    width={1200}
                    height={350}
                    minWidth={'50%'}
                    maxWidth={'100%'}
                    minHeight={'100%'}
                    visible={modalVisible}
                    title={'Дэлгэрэнгүй'}
                    onHiding={() => setModalVisible(false)}
                    resizeEnabled={true}
                    dragEnabled={true}
                    showTitle={true}
                    hideOnOutsideClick={true}
                >
                    <ScrollView width='100%' height='100%'>
                        <div className={'card-body'}>
                            <div  style={{ marginTop: 20 }}>
                                {selectedValue &&

                                    <div style={{ display: 'flex', flexDirection: 'row'  }}>
                                        <span style={{ fontSize: 14, fontWeight: 500 }}>Агуулахын код,нэр: </span>
                                        <span style={{ fontSize: 14, fontWeight: 500, marginLeft: 10 }}>{selectedValue.loctn_code}-{selectedValue.loctn_name}</span>
                                        <div style={{ display: 'flex', flexDirection: 'row', marginLeft: '10%' }}>
                                            <span style={{ fontSize: 14, fontWeight: 500 }}>Тайлант он,сар: </span>
                                            <span style={{ fontSize: 14, fontWeight: 500, marginLeft: 10 }}>{yearValue}-{monthValue}</span>
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'row', marginLeft: '10%' }}>
                                            <span style={{ fontSize: 14, fontWeight: 500 }}>Байгууллага: </span>
                                            <span style={{ fontSize: 14, fontWeight: 500, marginLeft: 10 }}>{selectedValue.aimag_name}-{selectedValue.org_name}</span>
                                        </div>
                                    </div>
                                }
                                <DataGrid
                                    dataSource={reportDetail}
                                    showBorders={true}
                                    allowColumnResizing={true}
                                    onSelectionChanged={onSelectionInvChanged}
                                    hoverStateEnabled={true}
                                    keyExpr="inv_id"
                                    noDataText = 'Дата байхгүй байна.'
                                >
                                    <Selection mode="single" />
                                        <Column caption="Барааны код" dataField="stnumber" dataType="string" />
                                        <Column caption="Барааны нэр" dataField="inv_name" dataType="string" />
                                        <Column caption="Хэмжих нэгж" dataField="unit_name" dataType="string" />

                                    <Column caption="Эхний үлдэгдэл">
                                        <Column caption="Тоо" dataField="qty_balance" dataType="number"/>
                                        <Column caption="Нийт үнэ" dataField="cost_balance" dataType="number" format="#,##0.00"/>
                                    </Column>
                                    <Column caption="Орлого">
                                        <Column caption="Тоо" dataField="orlogo_qty" dataType="number"/>
                                        <Column caption="Нийт үнэ" dataField="orlogo_cost" dataType="number" format="#,##0.00"/>
                                    </Column>
                                    <Column caption="Зарлага">
                                        <Column caption="Тоо" dataField="zarlaga_qty" dataType="number"/>
                                        <Column caption="Нийт үнэ" dataField="zarlaga_cost" dataType="number" format="#,##0.00"/>
                                    </Column>
                                    <Column caption="Эцсийн үлдэгдэл">
                                        <Column caption="Тоо" dataField="last_qty" dataType="number"/>
                                        <Column caption="Нийт үнэ" dataField="last_cost" dataType="number" format="#,##0.00"/>
                                    </Column>
                                    <Summary>
                                        <TotalItem
                                            column="qty_balance"
                                            summaryType="sum"
                                            customizeText={customizeDate}/>
                                        <TotalItem
                                            column="cost_balance"
                                            summaryType="sum"
                                            customizeText={customizeDate} />
                                        <TotalItem
                                            column="orlogo_qty"
                                            summaryType="sum"
                                            customizeText={customizeDate} />
                                        <TotalItem
                                            column="orlogo_cost"
                                            summaryType="sum"
                                            customizeText={customizeDate} />
                                        <TotalItem
                                            column="zarlaga_qty"
                                            summaryType="sum"
                                            customizeText={customizeDate} />
                                        <TotalItem
                                            column="zarlaga_cost"
                                            summaryType="sum"
                                            customizeText={customizeDate} />
                                        <TotalItem
                                            column="last_qty"
                                            summaryType="sum"
                                            customizeText={customizeDate} />
                                        <TotalItem
                                            column="last_cost"
                                            summaryType="sum"
                                            customizeText={customizeDate} />
                                    </Summary>
                                    <Export enabled={true} allowExportSelectedData={false} />
                                </DataGrid>
                            </div>
                            <div  style={{ marginTop: 20 }}>
                                {invValue &&
                                    <div>
                                        <div style={{ display: 'flex', flexDirection: 'row'  }}>
                                            <span style={{ fontSize: 14, fontWeight: 500 }}>Барааны код,нэр: </span>
                                            <span style={{ fontSize: 14, fontWeight: 500, marginLeft: 10 }}>{invValue.stnumber}-{invValue.inv_name}</span>
                                            <div style={{ display: 'flex', flexDirection: 'row', marginLeft: '10%' }}>
                                                <span style={{ fontSize: 14, fontWeight: 500 }}>Тайлант он,сар: </span>
                                                <span style={{ fontSize: 14, fontWeight: 500, marginLeft: 10 }}>{yearValue}-01-ээс</span>
                                                <span style={{ fontSize: 14, fontWeight: 500, marginLeft: 10 }}>{yearValue}-{monthValue} хүртэл</span>
                                            </div>
                                        </div>
                                        <DataGrid
                                            dataSource={reportInvDetail}
                                            showBorders={true}
                                            allowColumnResizing={true}
                                            hoverStateEnabled={true}
                                            keyExpr="inv_id"
                                            noDataText = 'Дата байхгүй байна.'
                                        >
                                            <Column caption="Харилцагч код" dataField="org" dataType="string" />
                                            <Column caption="Харилцагч нэр" dataField="orgname" dataType="string" />
                                            <Column caption="Барааны код" dataField="stnumber" dataType="string" />
                                            <Column caption="Барааны нэр" dataField="stname" dataType="string" />
                                            <Column caption="Огноо" dataField="date_recpt" dataType="date" defaultSortOrder="asc" />
                                            <Column caption="Падаан дугаар" dataField="rs_number" dataType="string" />
                                            <Column caption="Тоо" dataField="recpt_qty" dataType="number"/>
                                            <Column caption="Нэгж үнэ" dataField="price_unit" dataType="number" format="#,##0.00"/>
                                            <Column caption="Нийт дүн" dataField="amt" dataType="number" format="#,##0.00"/>

                                            <Summary>
                                                <GroupItem
                                                    column="recpt_qty"
                                                    summaryType="sum"
                                                    showInGroupFooter={true}
                                                    customizeText={customizeDate}/>
                                                <GroupItem
                                                    showInGroupFooter={true}
                                                    column="amt"
                                                    summaryType="sum"
                                                    customizeText={customizeDate} />
                                                <TotalItem
                                                    column="recpt_qty"
                                                    summaryType="sum"
                                                    customizeText={customizeDate}/>
                                                <TotalItem
                                                    column="amt"
                                                    summaryType="sum"
                                                    customizeText={customizeDate} />
                                            </Summary>
                                            <Export enabled={true} allowExportSelectedData={false} />
                                            <GroupPanel visible={true}/>
                                        </DataGrid>
                                    </div>
                                }
                            </div>
                        </div>
                    </ScrollView>
                </Popup>

                <Popup
                    width={500}
                    height={300}
                    minWidth={'50%'}
                    maxWidth={'100%'}
                    minHeight={'100%'}
                    visible={modalVisibleGL}
                    title={'Ерөнхий дэвтэрлүү шилжүүлэх тайлан'}
                    onHiding={() => setModalVisibleGL(false)}
                    resizeEnabled={true}
                    dragEnabled={true}
                    showTitle={true}
                    hideOnOutsideClick={true}
                >
                    <ScrollView width='100%' height='100%'>
                        <div className={'card-body'}>
                            <div  style={{ marginTop: 10 }}>
                                {reportGL[0] &&
                                     <div style={{ display: 'flex', flexDirection: 'row' ,marginBottom:20 }}>
                                        <div style={{ display: 'flex', flexDirection: 'row', marginLeft: '10%' }}>
                                            <span style={{ fontSize: 14, fontWeight: 500 }}>Тайлант он,сар: </span>
                                            <span style={{ fontSize: 14, fontWeight: 500, marginLeft: 10 }}>{reportGL[0].pyear}-{reportGL[0].pmonth}</span>
                                        </div>

                                        <div style={{ display: 'flex', flexDirection: 'row', marginLeft: '10%' }}>
                                            <span style={{ fontSize: 14, fontWeight: 500 }}>Байгууллага: </span>
                                            <span style={{ fontSize: 14, fontWeight: 500, marginLeft: 10 }}>{reportGL[0].aimag_name}-{reportGL[0].org_name}</span>
                                        </div>
                                    </div>
                                }
                                <DataGrid
                                    dataSource={reportGL}
                                    showBorders={true}
                                    allowColumnResizing={true}
                                    hoverStateEnabled={true}
                                    keyExpr={null}
                                    allowColumnReordering={true}
                                    noDataText = 'Дата байхгүй байна.'
                                >
                                    <GroupPanel visible={true} />
                                    <SearchPanel visible={true} />
                                    <Grouping autoExpandAll={true} />
                                    <Column caption="Данс" dataField="account" dataType="string" />
                                    <Column caption="Гүйлгээний утга" dataField="description" dataType="string" groupIndex={0}/>
                                    <Column caption="Дебит" dataField="debit" dataType="number" format="#,##0.00"/>
                                    <Column caption="Кредит" dataField="credit" dataType="number" format="#,##0.00"/>
                                    <Column caption="Харилцагч" dataField="cust_code" dataType="string" />
                                    <Summary>
                                        <GroupItem
                                            column="debit"
                                            summaryType="sum"
                                            customizeText={customizeDate}
                                            showInGroupFooter={true} />
                                        <GroupItem
                                            column="credit"
                                            summaryType="sum"
                                            customizeText={customizeDate}
                                            showInGroupFooter={true} />
                                        <TotalItem
                                            column="debit"
                                            summaryType="sum"
                                            customizeText={customizeDate}/>
                                        <TotalItem
                                            column="credit"
                                            summaryType="sum"
                                            customizeText={customizeDate} />
                                    </Summary>
                                    <Export enabled={true} allowExportSelectedData={false} />
                                </DataGrid>
                            </div>
                        </div>
                    </ScrollView>
                </Popup>

                <Popup
                    width={500}
                    height={200}
                    minWidth={'50%'}
                    maxWidth={'50%'}
                    minHeight={'40%'}
                    visible={modalVisibleGLSave}
                    title={'Ерөнхий дэвтэрлүү шилжүүлэх'}
                    onHiding={() => setModalVisibleGLSave(false)}
                    resizeEnabled={true}
                    dragEnabled={true}
                    showTitle={true}
                    hideOnOutsideClick={true}
                >
                    <ScrollView width='100%' height='100%'>
                        <div className={'card-body'}>
                            <div  style={{ marginTop: 10 }}>
                                {reportGL[0] &&
                                    <div style={{ display: 'flex', flexDirection: 'row' ,marginBottom:20 }}>
                                        <div style={{ display: 'flex', flexDirection: 'row', marginLeft: '10%' }}>
                                            <span style={{ fontSize: 14, fontWeight: 500 }}>Тайлант он,сар: </span>
                                            <span style={{ fontSize: 14, fontWeight: 500, marginLeft: 10 }}>{reportGL[0].pyear}-{reportGL[0].pmonth}</span>
                                        </div>

                                        <div style={{ display: 'flex', flexDirection: 'row', marginLeft: '10%' }}>
                                            <span style={{ fontSize: 14, fontWeight: 500 }}>Байгууллага: </span>
                                            <span style={{ fontSize: 14, fontWeight: 500, marginLeft: 10 }}>{reportGL[0].aimag_name}-{reportGL[0].org_name}</span>
                                        </div>
                                    </div>
                                }
                                <div style={{ display: 'flex', flexDirection: 'row' ,marginBottom:20 }}>
                                <div style={{ display: 'flex', alignItems: 'center', marginRight: 10}}>Огноо:</div>
                                <DateBox value={glDate} type="date" onValueChanged={onValueChangedDate} width={200}/>
                                    <div style={{ display: 'flex', marginLeft: 10,marginRight: 10}}>Журнал дугаар:</div>
                                        <input type = 'string' className = 'law-search-input' style={{ width: '50%', backgroundColor: '#FFFFFF', border: '1px solid #DDDDDD' }}
                                               value={jrNo} onChange={jrChangeValue}/>

                                </div>

                                <div className="col-12 row" style={{justifyContent:"flex-end", gap:'2rem', padding: '5px 15px'}}>
                                    <Button text = "Шилжүүлэх" onClick={() => setGLData()} width = {150} type = "default" style = {{alignment : "center", borderRadius: '7px'}}/>
                                </div>

                            </div>
                        </div>
                    </ScrollView>
                </Popup>

                <Popup
                    width={500}
                    height={'80%'}
                    minWidth={'50%'}
                    maxWidth={'50%'}
                    minHeight={'40%'}
                    visible={modalVisibleMonthSave}
                    title={'Бараа материалын сарын хаалт хийх'}
                    onHiding={() => setModalVisibleMonthSave(false)}
                    resizeEnabled={true}
                    dragEnabled={true}
                    showTitle={true}
                    hideOnOutsideClick={true}
                >
                    <ScrollView width='100%' height='100%'>
                        <div className={'card-body'}>
                            <div  style={{ marginTop: 10 }}>
                                {dataBalMonth[0] &&
                                    <div style={{ display: 'flex', flexDirection: 'row' ,marginBottom:20 }}>
                                        <div style={{ display: 'flex', flexDirection: 'row', marginLeft: '10%' }}>
                                            <span style={{ fontSize: 14, fontWeight: 500 }}>Тайлант он,сар: </span>
                                            <span style={{ fontSize: 14, fontWeight: 500, marginLeft: 10 }}>{dataBalMonth[0].bal_year}-{dataBalMonth[0].bal_month}</span>
                                        </div>

                                        <div style={{ display: 'flex', flexDirection: 'row', marginLeft: '10%' }}>
                                            <span style={{ fontSize: 14, fontWeight: 500 }}>Байгууллага: </span>
                                            <span style={{ fontSize: 14, fontWeight: 500, marginLeft: 10 }}>{dataBalMonth[0].aimag_name}-{dataBalMonth[0].org_name}</span>
                                        </div>
                                    </div>
                                }
                <div style={{marginTop:10}}>
                    <DataGrid
                        dataSource={dataBalMonth}
                        showBorders={true}
                        allowColumnResizing={true}
                        hoverStateEnabled={true}
                        keyExpr="inv_id"
                        noDataText = 'Дата байхгүй байна.'
                    >
                        <Column caption="Агууллахын код" dataField="loctn_code" dataType="string" />
                        <Column caption="Агууллахын нэр" dataField="loctn_name" dataType="string" />
                        <Column caption="Барааны код" dataField="stnumber" dataType="string" />
                        <Column caption="Барааны нэр" dataField="stname" dataType="string" />
                        <Column caption="Тайлант жил" dataField="bal_year" dataType="number" />
                        <Column caption="Тайлант сар" dataField="bal_month" dataType="number" />
                        <Column caption="Тоо" dataField="qty" dataType="number" format="#,##0.00"/>
                        <Column caption="Нийт дүн" dataField="cost" dataType="number" format="#,##0.00"/>

                        <Summary>
                            <TotalItem
                                column="qty"
                                summaryType="sum"
                                customizeText={customizeDate}/>
                            <TotalItem
                                column="cost"
                                summaryType="sum"
                                customizeText={customizeDate} />
                        </Summary>
                        <Export enabled={true} allowExportSelectedData={false} />
                    </DataGrid>
                </div>
                                <div className="col-12 row" style={{justifyContent:"flex-end", gap:'2rem', padding: '5px 15px'}}>
                                    <Button text = "Хаалт хийх" onClick={() => setInvBalData()} width = {150} type = "default" style = {{alignment : "center", borderRadius: '7px'}}/>
                                </div>

                            </div>
                        </div>
                    </ScrollView>
                </Popup>
                <Popup
                    width={800}
                    height={300}
                    minWidth={'50%'}
                    maxWidth={'100%'}
                    minHeight={'100%'}
                    visible={visibleIncomeTxn}
                    title={'Орлогын гүйлгээний тайлан'}
                    onHiding={() => setVisibleIncomeTxn(false)}
                    resizeEnabled={true}
                    dragEnabled={true}
                    showTitle={true}
                    hideOnOutsideClick={true}
                >
                    <ScrollView width='100%' height='100%'>
                        <div className={'card-body'}>
                            <div  style={{ marginTop: 10 }}>

                                    <div style={{ display: 'flex', flexDirection: 'row' ,marginBottom:20 }}>
                                        <div style={{ display: 'flex', flexDirection: 'row', marginLeft: '10%' }}>
                                            <span style={{ fontSize: 14, fontWeight: 500 }}>Тайлант он,сар: </span>
                                            <span style={{ fontSize: 14, fontWeight: 500, marginLeft: 10 }}>{yearValue}-{monthValue}</span>
                                        </div>
                                    </div>

                                <DataGrid
                                    dataSource={incomeTxn}
                                    showBorders={true}
                                    columnAutoWidth={true}
                                    allowColumnResizing={true}
                                    hoverStateEnabled={true}
                                    keyExpr="id"
                                    allowColumnReordering={true}
                                    noDataText = 'Дата байхгүй байна.'
                                >
                                    <GroupPanel visible={true} />
                                    <SearchPanel visible={true} />
                                    <Grouping autoExpandAll={true} />
                                    <Column caption="Данс" dataField="acct" dataType="string" />
                                    <Column caption="Барааны код" dataField="stnumber" dataType="string"/>
                                    <Column caption="Барааны нэр" dataField="stname" dataType="string"/>
                                    <Column caption="падаан" dataField="rs_number" groupIndex={0}/>
                                    <Column caption="Хэмжих нэгж" dataField="unit_name" dataType="string"/>
                                    <Column caption="Тоо" dataField="recpt_qty" dataType="number" format="#,##0.00"/>
                                    <Column caption="Нэгж үнэ" dataField="price_unit" dataType="number" format="#,##0.00"/>
                                    <Column caption="Хувиарласан " dataField="cost_allo" dataType="number" format="#,##0.00"/>

                                    <Column caption="Нийт дүн" dataField="amt" dataType="number" format="#,##0.00"/>
                                    <Column caption="Огноо" dataField="date_recpt" dataType="date"/>
                                    <Column caption="Кредит данс" dataField="cr"
                                       dataType="string"  />
                                    <Column caption="Харилцагчын нэр" dataField="orgname" dataType="string" />
                                    <Summary>
                                        <GroupItem
                                            column="recpt_qty"
                                            summaryType="sum"
                                            customizeText={customizeDate}
                                            showInGroupFooter={true} />
                                        <GroupItem
                                            column="amt"
                                            summaryType="sum"
                                            customizeText={customizeDate}
                                            showInGroupFooter={true} />
                                        <TotalItem
                                            column="recpt_qty"
                                            summaryType="sum"
                                            customizeText={customizeDate}/>
                                        <TotalItem
                                            column="amt"
                                            summaryType="sum"
                                            customizeText={customizeDate} />
                                    </Summary>
                                    <Export enabled={true} allowExportSelectedData={false} />
                                </DataGrid>
                            </div>
                        </div>
                    </ScrollView>
                </Popup>

                <Popup
                    width={800}
                    height={300}
                    minWidth={'50%'}
                    maxWidth={'100%'}
                    minHeight={'100%'}
                    visible={visibleExpenseTxn}
                    title={'Зарлагын гүйлгээний тайлан'}
                    onHiding={() => setVisibleExpenseTxn(false)}
                    resizeEnabled={true}
                    dragEnabled={true}
                    showTitle={true}
                    hideOnOutsideClick={true}
                >
                    <ScrollView width='100%' height='100%'>
                        <div className={'card-body'}>
                            <div  style={{ marginTop: 10 }}>

                                <div style={{ display: 'flex', flexDirection: 'row' ,marginBottom:20 }}>
                                    <div style={{ display: 'flex', flexDirection: 'row', marginLeft: '10%' }}>
                                        <span style={{ fontSize: 14, fontWeight: 500 }}>Тайлант он,сар: </span>
                                        <span style={{ fontSize: 14, fontWeight: 500, marginLeft: 10 }}>{yearValue}-{monthValue}</span>
                                    </div>

                                    {/*<div style={{ display: 'flex', flexDirection: 'row', marginLeft: '10%' }}>
                                            <span style={{ fontSize: 14, fontWeight: 500 }}>Байгууллага: </span>
                                            <span style={{ fontSize: 14, fontWeight: 500, marginLeft: 10 }}>{incomeTxn[0].organization_model.name}</span>
                                        </div>*/}
                                </div>

                                <DataGrid
                                    dataSource={expenseTxn}
                                    showBorders={true}
                                    allowColumnResizing={true}
                                    hoverStateEnabled={true}
                                    keyExpr="id"
                                    allowColumnReordering={true}
                                    noDataText = 'Дата байхгүй байна.'
                                >
                                    <GroupPanel visible={true} />
                                    <SearchPanel visible={true} />
                                    <Grouping autoExpandAll={true} />
                                    <Column caption="Данс" dataField="inacct" dataType="string" />
                                    <Column caption="Барааны код" dataField="stnumber" dataType="string"/>
                                    <Column caption="падаан" dataField="is_number"  groupIndex={0}/>
                                    <Column caption="Барааны нэр" dataField="stname" dataType="string"/>
                                    <Column caption="Хэмжих нэгж" dataField="unit_name" dataType="string"/>
                                    <Column caption="Тоо" dataField="iss_qty" dataType="number"/>
                                    <Column caption="Нэгж үнэ" dataField="unit_cost" dataType="number" format="#,##0.00"/>
                                    <Column caption="Нийт дүн" dataField="iss_cost" dataType="number" format="#,##0.00"/>
                                    <Column caption="Огноо" dataField="iss_date" dataType="date"/>
                                    <Column caption="Дебит данс" dataField="acct_db"
                                            dataType="string" />
                                    <Column caption="Харилцагчын нэр" dataField="recvr" dataType="string" />
                                    <Summary>
                                        <GroupItem
                                            column="iss_qty"
                                            summaryType="sum"
                                            customizeText={customizeDate}
                                            showInGroupFooter={true} />
                                        <GroupItem
                                            column="iss_cost"
                                            summaryType="sum"
                                            customizeText={customizeDate}
                                            showInGroupFooter={true} />
                                        <TotalItem
                                            column="iss_qty"
                                            summaryType="sum"
                                            customizeText={customizeDate}/>
                                        <TotalItem
                                            column="iss_cost"
                                            summaryType="sum"
                                            customizeText={customizeDate} />
                                    </Summary>
                                    <Export enabled={true} allowExportSelectedData={false} />
                                </DataGrid>
                            </div>
                        </div>
                    </ScrollView>
                </Popup>

                   <div className="row" style={{display: 'flex'}} >
                            <div className="col-3" style={{display: 'flex', marginRight: 20}}>
                                <div style={{marginRight: 10, display: 'flex', alignItems: 'center', ...style.text}}>Тайлант он:</div>
                                <div>
                                    <SelectBox
                                        items={getYears(true)}
                                        defaultValue={yearValue}
                                        style={{...style.box}}
                                        onValueChanged={(e) => changeYear(e.value)}
                                        placeholder="сонгох"/>
                                </div>
                            </div>
                            <div className="col-3" style={{display: 'flex', marginRight: 20}}>
                                <div style={{marginRight: 10, display: 'flex', alignItems: 'center',...style.text}}>Тайлант сар:</div>
                                <div >
                                    <SelectBox
                                        items={monthList}
                                        style={{...style.box}}
                                        defaultValue={monthValue}
                                        onValueChanged={(e) => changeMonth(e.value)}
                                        placeholder="сонгох"/>
                                </div>
                            </div>
                            <div className="col-4" style={{display: 'flex', marginRight: 20}}>
                                <div style={{ marginLeft: 20,...style.text }}>Агуулахын код:</div>
                                <input value={locationCodeValue} type = 'string' style={{...style.box}} disabled={true} className='law-search-input'/>
                        </div>
                           
                    </div>
                   
                       
                       
                <div className="row" style={{ marginTop: 10 }}>
                    <div style={{ marginLeft: 5, marginTop: 5 }}>
                        <Button text = "Ерөнхий дэвтэрлүү шилжүүлэх тайлан" onClick={() => getReportGL()} width="100%" type = "default" style = {{alignment : "center", borderRadius: '7px', width: "100%"}}/>
                    </div>
                    <div style={{ marginLeft: 5, marginTop: 5 }}>
                        <Button disabled={dataFcsGLTran.length === 0 ? false : true} text = {dataFcsGLTran.length === 0 ? "Ерөнхий дэвтэрлүү шилжүүлэх" : "Ерөнхий дэвтэрлүү шилжүүлсэн"} onClick={() => getReportGLSave()} width="100%"
                                type ={dataFcsGLTran.length === 0 ? "default" : "success"}  style = {{alignment : "center", borderRadius: '7px', width: "100%"}}/>
                    </div>
                    <div style={{ marginLeft: 5, marginTop: 5 }}>
                        <Button text = "Орлогын гүйлгээний тайлан" onClick={() => getIncomeTxn()} type = "default" style = {{alignment : "center", borderRadius: '7px', width: "100%"}}/>
                    </div>
                    <div style={{ marginLeft: 5,marginTop:5 }}>
                        <Button text = "Зарлагын гүйлгээний тайлан" onClick={() => getExpenseTxn()}  type = "default" style = {{alignment : "center", borderRadius: '7px', width: "100%"}}/>
                    </div>
                    
                </div>
                <div className="row" style={{marginTop: 10}}>
                        <div  >
                           <Button text = "Хураангуй" onClick={() => getReport()} width = {110} type = "default" style = {{alignment : "center", borderRadius: '7px'}}/>
                       </div>
                       <div style={{ marginLeft: 10}}>
                           <Button text = "Дэлгэрэнгүй" onClick={() => getReportDetails()} width = {120} type = "default" style = {{alignment : "center", borderRadius: '7px'}}/>
                       </div>
                       <div style={{ marginLeft: 5, marginTop: 5 }}>
                        <Button text = "Сарын хаалт хийх" onClick={() => getReportMonthSave()} width = "100%" type = "default" style = {{alignment : "center", borderRadius: '7px'}}/>
                    </div>
                    </div>
                                    <div style={{ marginLeft: 5 }}>
                                        <div className='col-12' style={{display: 'flex', flex: 1, flexDirection: 'row', alignItems: 'center',justifyContent:'center',marginTop: 20}}>  <span>Бараа материалын хураангуй тайлан</span></div>
                                        <div style={{ marginTop: 10 }} ref={componentRef}>
                                            <LoadPanelComponent
                                                shadingColor="rgba(0,0,0,0.4)"
                                                position={userListPosition}
                                                visible={loader}
                                                 showPane={false}
                                                 message = ''
                                            />
                                            <DataGrid
                                                dataSource={report}
                                                showBorders={true}
                                                allowColumnResizing={true}
                                                onSelectionChanged={onSelectionChanged}
                                                hoverStateEnabled={true}
                                                keyExpr="loctn_id"
                                                noDataText = 'Дата байхгүй байна.'
                                                onExporting={onExporting}>
                                                <Selection mode="single" />
                                                <GroupPanel visible={true} />
                                                <SearchPanel visible={true} />
                                                <Grouping autoExpandAll={true} />
                                                <Column caption="Агуулах" dataField="loctn_code"
                                                        calculateCellValue={(item) => {
                                                            return item && `${item.loctn_code} - ${item.loctn_name}`
                                                        }} dataType="string"  groupIndex={0}/>
                                                <Column caption="Бараа материалын дансны нэр" dataField="acct" dataType="string" width={80}/>
                                                <Column caption="Бараа материалын дансны код" dataField="chart_name" dataType="string" width={150}/>

                                                <Column caption="Эхний үлдэгдэл">
                                                    <Column caption="Тоо" dataField="qty_balance" dataType="number" width={50}/>
                                                    <Column caption="Нийт үнэ" dataField="cost_balance" dataType="number" format="#,##0.00" width={100}/>
                                                </Column>
                                                <Column caption="Орлого">
                                                    <Column caption="Тоо" dataField="orlogo_qty" width={50} dataType="number"/>
                                                    <Column caption="Нийт үнэ" dataField="orlogo_cost" width={100} dataType="number" format="#,##0.00"/>
                                                </Column>
                                                <Column caption="Зарлага">
                                                    <Column caption="Тоо" dataField="zarlaga_qty" dataType="number" width={50}/>
                                                    <Column caption="Нийт үнэ" dataField="zarlaga_cost" width={100} dataType="number" format="#,##0.00"/>
                                                </Column>
                                                <Column caption="Эцсийн үлдэгдэл">
                                                    <Column caption="Тоо" dataField="last_qty" dataType="number" width={50}/>
                                                    <Column caption="Нийт үнэ" dataField="last_cost" width={100} dataType="number" format="#,##0.00"/>
                                                </Column>
                                                <Summary>
                                                    <TotalItem
                                                        column="qty_balance"
                                                        summaryType="sum"
                                                        customizeText={customizeDate}/>
                                                    <TotalItem
                                                        column="cost_balance"
                                                        summaryType="sum"
                                                        customizeText={customizeDate} />
                                                    <TotalItem
                                                        column="orlogo_qty"
                                                        summaryType="sum"
                                                        customizeText={customizeDate} />
                                                    <TotalItem
                                                        column="orlogo_cost"
                                                        summaryType="sum"
                                                        customizeText={customizeDate} />
                                                    <TotalItem
                                                        column="zarlaga_qty"
                                                        summaryType="sum"
                                                        customizeText={customizeDate} />
                                                    <TotalItem
                                                        column="zarlaga_cost"
                                                        summaryType="sum"
                                                        customizeText={customizeDate} />
                                                    <TotalItem
                                                        column="last_qty"
                                                        summaryType="sum"
                                                        customizeText={customizeDate} />
                                                    <TotalItem
                                                        column="last_cost"
                                                        summaryType="sum"
                                                        customizeText={customizeDate} />
                                                </Summary>
                                                <Export enabled={true} allowExportSelectedData={false} />
                                            </DataGrid>
                                        </div>


                            </div>
            </div>

        </React.Fragment>
    );

}
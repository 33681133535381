import React, {useEffect, useState, useRef} from 'react';
import 'devextreme-react/text-area';
import 'devextreme-react/text-area';
import {
    TreeList, Editing, Column, Lookup,
} from 'devextreme-react/tree-list';
import notify from 'devextreme/ui/notify';
import AgendaService from "../../../services/api/agenda";
import _ from "lodash";
import LoadPanelComponent from '../../../components/LoadPanelComponent';

function Agenda(props) {
    const [dataSource, setDataSource] = useState([]);
    const [loader, setLoader] = useState(false);
    const [lookUpListAgenda, setLookUpListAgenda] = useState([]);
    const myBabyRef = useRef(null);
    useEffect(() => {
        agendaFetch();
    }, [])

    const agendaFetch = async () => {
        try {
            setLoader(true);
            const result = await AgendaService.getAllAgenda();
            result.map(e => {
                // console.log("test: ", e.status)
                if (e.status === 1) {
                    e.statusName = "Идэвхтэй";
                } else {
                    e.statusName = "Идэвхгүй";
                }
                // console.log("aaa: ", e.statusName);
            })
            let r = await _.filter(result, {parent_id: null});
            setLookUpListAgenda(r);
            setDataSource(result)
            return setLoader(false)
        } catch (e) {
            console.error(e);
        }
    }
    const setAgenda = async (e) => {
        if (e.changes && e.changes.length !== 0) {
            if (e.changes[0].type === "update") {
                try {
                    await AgendaService.editAgenda(e.changes[0].data);
                    await agendaFetch();
                    notify('Амжилттай хадгалагдлаа', "success", 2000);
                } catch (e) {
                    notify('Засварлалтыг хадгалах явцад алдаа гарлаа', "error", 2000);
                }
            } else if (e.changes[0].type === "insert") {
                try {
                    delete e.changes[0].data.id;
                    await AgendaService.addAgenda(e.changes[0].data);
                    await agendaFetch();
                    notify('Амжилттай үүслээ', "success", 2000);
                } catch (e) {
                    notify('Шинээр үүсэх явцад алдаа гарлаа', "error", 2000);
                }

            }
        }

    }
    //Column custom renderings
    const statusRender = (data) => {
        return (data.value === 1 ? <div style={{
            backgroundColor: '#50CB93',
            width: '80%',
            color: 'white',
            borderRadius: '3px',
            cursor: 'pointer',
            margin: 'auto',
            textAlign: 'center'
        }}>Идэвхтэй</div> : <div style={{
            backgroundColor: '#FF616D',
            width: '80%',
            color: 'white',
            borderRadius: '3px',
            cursor: 'pointer',
            margin: 'auto',
            textAlign: 'center'
        }}>Идэвхгүй</div>)
    }
    const onEditorPreparing = (e) => {
        if (e.parentType === "dataRow") {
            return;
        }
        if (e.row && e.row.isNewRow) {
            e.editorOptions.visible = true;
        }
        if (e.row && !e.row.isNewRow) {
            e.editorOptions.visible = false;
        }
    }
    const initNewRowEvent = (e) => {
        let r;
        if (e.data.parent_id === 0) {
            r = [];
        } else {
            r = _.filter(dataSource, {id: e.data.parent_id});
        }
        setLookUpListAgenda(r);
    }

    const onEditingStartEvent = (e) => {
        let r;
        if (e.data.parent_id) {
            r = _.filter(dataSource, {id: e.data.parent_id});
        }
        setLookUpListAgenda(r);
    }
    const onCellPreparedHandler = (e) => {
        if (e.rowType === 'header') {
            e.cellElement.style.color = "black"
            e.cellElement.style.backgroundColor = "#E8E8E8"
            // elem.style.setProperty('color', 'green');
        }
        if (e.rowType === 'data') {
            e.cellElement.style.color = "black"
            // elem.style.setProperty('color', 'green');
        }
    }
    return (
        <div>
            <LoadPanelComponent
                shadingColor="rgba(0,0,0,0.4)"
                position={{of: '#password'}}
                visible={loader}
                message=''
                showPane={false}
            />
            <div>
                <div className="card-body">
                    <TreeList
                        dataSource={dataSource}
                        keyExpr="id"
                        parentIdExpr="parent_id"
                        onEditorPreparing={onEditorPreparing}
                        ref={myBabyRef}
                        onSaved={setAgenda}
                        onEditingStart={onEditingStartEvent}
                        onInitNewRow={initNewRowEvent}
                        onCellPrepared={onCellPreparedHandler}
                        rowAlternationEnabled={true}
                        showBorders={true}
                        showColumnLines={true}
                        showRowLines={true}
                    >
                        <Editing
                            mode="row"
                            allowUpdating={true}
                            // allowAdding={true}
                            allowDeleting={false}
                            confirmDelete={true}
                            useIcons={true}
                            confirmSave={true}
                            allowAdding={true}
                            texts={{
                                cancelAllChanges: 'Болих бүгд',
                                cancelRowChanges: 'Болих',
                                confirmDeleteMessage: 'Энэ хөтөлбөрийн мэдээллийг устгах уу?',
                                confirmDeleteTitle: 'Баталгаажуулах',
                                deleteRow: 'Устгах',
                                editRow: 'Өөрчлөх',
                                saveAllChanges: 'Хадгалах бүгд',
                                saveRowChanges: 'Хадгалах',
                                undeleteRow: 'Буцаах',
                                validationCancelChanges: 'Баталгаажуулах болих',
                            }}
                        />
                        {/*<FilterRow*/}
                        {/*    visible = {true}*/}
                        {/*/>*/}
                        {/*<Pager*/}
                        {/*    showPageSizeSelector={true}*/}
                        {/*    // showInfo={true}*/}
                        {/*    showNavigationButtons={true}*/}
                        {/*/>*/}

                        {/*<Column*/}
                        {/*    caption= '№'*/}
                        {/*    cssClass = "custom"*/}
                        {/*    width = {60}*/}
                        {/*    allowEditing = {false}*/}
                        {/*   cellRender = {orderRender}*/}
                        {/*/>*/}
                        <Column
                            cssClass="custom"
                            dataField="code"
                            caption="Код"
                            allowEditing={true}
                            allowCollapsing={false}
                            allowFiltering={true}
                            width={160}
                            //  cellRender = {dateRender}
                        >
                            {/*<RequiredRule*/}
                            {/*    message = 'Код оруулна уу'*/}
                            {/*/>*/}
                        </Column>
                        <Column
                            dataField="name"
                            caption="Төсөл хөтөлбөр нэр"
                            allowEditing={true}
                            // editCellTemplate = {}
                            //   cellRender = {dateRender}
                            cssClass="custom"
                            allowFiltering={true}
                            width={550}
                        >
                            {/*<RequiredRule*/}
                            {/*    message = 'Төсөл хөтөлбөр нэр оруулна уу'*/}
                            {/*/>*/}
                        </Column>

                        <Column
                            dataField="parent_id"
                            caption="Толгой хөтөлбөр">
                            <Lookup
                                allowClearing={true}
                                dataSource={lookUpListAgenda}
                                valueExpr="id"
                                displayExpr="name"/>
                        </Column>
                        <Column
                            cssClass="custom"
                            dataField="status"
                            caption="Төлөв"
                            allowEditing={true}
                            cellRender={statusRender}
                            allowFiltering={true}
                            width={100}>

                        </Column>


                        {/*<Paging enabled={true} />*/}
                    </TreeList>
                </div>
            </div>

        </div>
    )
}

export default Agenda;

import React, {useLayoutEffect, useState} from "react"
import styled from "styled-components"
import statsService from "../../../services/api/stat.service"
import notify from "devextreme/ui/notify"
import StatisticsCard from "../StatisticsCard"

const CardsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
  gap: 1rem;
  align-items: flex-start;
  row-gap: 1rem;
`
function TblRender({
  isOrgShown,
  orgId,
  list,
  currentList,
  icon,
  year,
  isLocal,
  // loading,
  // setLoading,
  index
}) {
  const {list_id, list_nm} = list
  const [tbls, setTbls] = useState([])
  const [loading, setLoading]= useState(false)
  const getTbls = async () => {
    setLoading(true)
    setTbls([])
    try {
      if (isOrgShown && orgId) {
        let {data} = await statsService.getTblsByOrg(orgId, list_id, year)
        let filteredData = data.filter(i=> i?.sum)
        setTbls(filteredData)
      } else if (!isOrgShown && currentList) {
        
        let {data} = await statsService.getTbls(currentList, year)
        setTbls(data)
      }
    } catch (error) {
      setLoading(false)
      notify(error.message)
    } 
    finally {
      setLoading(false)
    }
  }
  useLayoutEffect(() => {
    getTbls()
  }, [list_id, orgId, isOrgShown, currentList, year])

  if (tbls?.length === 0) return null

  return (
    <>
      {isOrgShown && !loading && (
        <div
          style={{
            margin: "1rem 0",
            fontWeight: 700,
            fontSize: "1rem",
            textTransform: "uppercase",
            display: "flex",
            alignItems: "center",
            gap: ".5rem",
          }}
        >
          {index + 1}.
          <span>{list_nm}</span>
        </div>
      )}
      <CardsContainer>
        {tbls.map((tblItem, tblIndex) => (
          <StatisticsCard
          isLocal={isLocal}
            list_id={isOrgShown ? list_id: currentList}
            key={tblIndex}
            loading={loading}
            {...tblItem}
          />
        ))}
      </CardsContainer>
    </>
  )
}

export default TblRender

import React, {useState, useEffect} from "react"
import notify from "devextreme/ui/notify"
import BudgetService from "../../../../services/api/budget"
import _ from "lodash"
import {numberWithCommas} from "../../../../util"
import DataGrid, {
  Column,
  Paging,
  Summary,
  TotalItem,
} from "devextreme-react/data-grid"
import LoadPanelComponent from "../../../budget/components/loadPanel"
import EmptyData from "../../../budget/components/empty"

const OrlogoZadgaiGrid = (props) => {
  const [chartData, setChartData] = useState([])
  const [sumVal, setSumVal] = useState(0)
  const [loader, setLoader] = useState(true)
  const [data, setData] = useState()

  const loadFunc = async () => {
    try {
      setLoader(true)
      let r = await BudgetService.getOrlogoZadgaiDataGrid(
        props.year,
        props.orlogoType,props.orgId
      )
      setData(r)
      let sum = _.sumBy(r, "batlagdsan")
      setSumVal(sum)
      setChartData(r)
    } catch (e) {
      notify(e.message, "error", 2000)
    } finally {
      setLoader(false)
    }
  }

  useEffect(() => {
    loadFunc()
  }, [props.year, props.orlogoType,props.orgId])

  const cellTemp = (cellElement, cellInfo) => {
    return cellElement.append(cellInfo.row.loadIndex + 1)
  }

  const cellFractionColumn = (e) => {
    return `${numberWithCommas(e.value / 1000000)}`
  }

  const cellFractionColumn1 = (e) => {
    return `${numberWithCommas(e.value / 1000000)}`
  }

  const calcCellVal1 = (e) => {
    return ((e.guitsetgel * 100) / e.batlagdsan).toFixed(2)
  }

  const calcCellVal = (e) => {
    return ((e.batlagdsan * 100) / sumVal).toFixed(2)
  }

  const rowSelectionHandler = (e) => {
    props.callFunc(e.data.name)
  }

  const onCellPreparedHandler = (e) => {
    if (e.rowType === "header") {
      e.cellElement.wordWrapEnabled = true
      e.cellElement.style.fontFamily = "Segoe UI"
      e.cellElement.style.fontWeight = "bold"
      e.cellElement.style.color = "#15186A"
      e.cellElement.style.backgroundColor = "#EEF0F4"
    }
    if (e.rowType === "data") {
      e.cellElement.style.fontFamily = "Segoe UI"
      e.cellElement.style.fontWeight = "semibold"
      e.cellElement.style.color = "#000000"
    }
    if (e.rowType === "data" && e.column.dataField === "name") {
      let elem = e.cellElement
      elem.style.setProperty("cursor", "pointer", "important")
    }
  }

  if (chartData?.length === 0 && !loader) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          family: "'Segoe UI', sans-serif",
        }}
      >
        <EmptyData/>
      </div>
    )
  }
  return (
    <div id="load2" style={{minHeight: 440}}>
      <LoadPanelComponent position={{of: "#load2"}} visible={loader} />
      <DataGrid
        rowAlternationEnabled={true}
        noDataText="Дата байхгүй байна."
        dataSource={chartData}
        style={{fontFamily: "'Segoe UI', sans-serif", fontWeight: "semibold"}}
        onRowClick={rowSelectionHandler}
        onCellPrepared={onCellPreparedHandler}
      >
        <Paging defaultPageSize={10} />
        <Column
          caption="№"
          allowSorting={false}
          cellTemplate={cellTemp}
          alignment="center"
          width={40}
        />
        <Column
          dataField="name"
          allowSorting={false}
          caption="Байгууллага"
          alignment="left"
          minWidth={120}
        />
        <Column caption="Төсөв" alignment="center">
          <Column
            dataField="batlagdsan"
            allowSorting={true}
            cellRender={cellFractionColumn}
            defaultSortOrder="desc"
            alignment="center"
            caption="Дүн"
            allowFiltering={false}
            width={105}
          />
          <Column
            caption="Хувь"
            allowSorting={true}
            calculateCellValue={calcCellVal}
            alignment="center"
            width={60}
          />
        </Column>
        <Column caption="Гүйцэтгэл" alignment="center">
          <Column
            dataField="guitsetgel"
            caption="Дүн"
            allowSorting={true}
            cellRender={cellFractionColumn1}
            alignment="center"
            width={95}
          />
          <Column
            caption="Хувь"
            allowSorting={false}
            calculateCellValue={calcCellVal1}
            alignment="center"
            width={60}
          />
        </Column>

        <Summary>
          <TotalItem
            column="batlagdsan"
            summaryType="sum"
            customizeText={(data) => {
              return `${numberWithCommas(data.value / 1000000)}`
            }}
          />
          <TotalItem
            column="guitsetgel"
            summaryType="sum"
            customizeText={(data) => {
              return `${numberWithCommas(data.value / 1000000)}`
            }}
          />
          <TotalItem column="Эзлэх %" displayFormat="100%" />
        </Summary>
      </DataGrid>
    </div>
  )
}

export default OrlogoZadgaiGrid

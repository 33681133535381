import React, { useEffect, useState } from 'react';
import achievment from '../../assets/imgs/icon/achievment.png';
import contact from '../../assets/imgs/icon/contact.png';
import email from '../../assets/imgs/icon/email.png';
import facebook from '../../assets/imgs/icon/fb.png';
import insta from '../../assets/imgs/icon/insta.png';
import phone from '../../assets/imgs/icon/phone.png';
import schools from '../../assets/imgs/icon/schools.png';
import twitter from '../../assets/imgs/icon/twitter.png';
import backbtn from '../../assets/imgs/hzyam/back-button.png';
import { useHistory, useLocation } from "react-router-dom";
import TopHeader from "../introduction/components/TopHeaderComponent";
import { Button } from "devextreme-react/button";
import OrganizationService from "../../services/api/organization";
import { urlServer } from "../../shared/lib/request";
import LoadPanelComponent from "../budget/components/loadPanel";
import { BackButton } from '../../components/Buttons';
export default function OrgHeadInfo() {
    const { innerWidth, innerHeight } = window;
    let history = useHistory();
    const location = useLocation();
    const { workerInfo, bannerFileName } = location.state;
    const base_image_url = '/api/uploads/documents/';
    const [headEdu, setHeadEdu] = useState([]);
    const [headExp, setHeadExp] = useState([]);
    const [headContact, setHeadContact] = useState([]);
    const [loader, setLoader] = useState(false);
    const [headCourse, setHeadCourse] = useState([]);
    const [headLang, setHeadLang] = useState([]);
    const loadData = async () => {
        setLoader(true);
        const headData = await OrganizationService.orgWorkerDataById(workerInfo.id);
        if (headData.data) {
            console.log('headData.data', headData.data);
            let a4Edu = await headData.data.filter(x => x.org_w_form_id === 4)
            let a8Exp = await headData.data.filter(x => x.org_w_form_id === 8)
            let a36Cont = await headData.data.filter(x => x.org_w_form_id === 10)
            let edu = await a4Edu[0] !== undefined ? a4Edu[0].value : null;

            let edu_table = edu ? await edu.filter(x => x.name === 'edu_table') : null;
            let course_table = edu ? await edu.filter(x => x.name === 'course_table') : null;
            let language_table = edu ? await edu.filter(x => x.name === 'language_table') : null;

            let exp = a8Exp[0] !== undefined ? await a8Exp[0].value : null;
            let exp_table = exp ? await exp.filter(x => x.name === "work_experience") : null;
            if (a36Cont && a36Cont[0]) {
                let tmpCntInfo = a36Cont[0].value
                let cntInfo = []
                tmpCntInfo.forEach(function (c) {
                    if (c.name === 'phone') { c.icon = phone; c.link = "tel:+" + c.value; c.showField = c.value }
                    if (c.name === 'cellPhone') { c.icon = phone; c.link = "tel:+" + c.value; c.showField = c.value }
                    if (c.name === 'email') { c.icon = email; c.link = "mailto:" + c.value; c.showField = c.value }
                    if (c.name === 'facebook') { c.icon = facebook; c.link = c.value; c.showField = 'facebook' }
                    if (c.name === 'twitter') { c.icon = twitter; c.link = c.value; c.showField = 'twitter' }
                    if (c.name === 'instagram') { c.icon = insta; c.link = c.value; c.showField = 'instagram' }
                    if (c.name === 'linkedin') { c.icon = contact; c.link = c.value; c.showField = 'linkedin' }
                    if (c.value) { cntInfo.push(c) }
                })
                await setHeadContact(cntInfo)
            }
            edu_table = edu_table ? edu_table[0].value : null;
            exp_table = exp_table ? exp_table[0].value : null;
            course_table = course_table ? course_table[0].value : null;
            language_table = language_table ? language_table[0].value : null;
            await setHeadEdu(edu_table)
            await setHeadExp(exp_table)
            await setHeadCourse(course_table)
            await setHeadLang(language_table)
        }
        return setLoader(false)
    }

    useEffect(async () => {
        await loadData();
            console.log(headContact);
        },
        []
    );
    const style = {
        textJustify: {
            textAlign: "justify",
            textJustify: "inter-word",
            fontSize: 14
        },
        iconStyle: {
            width: 'auto',
            height: '25px',
            position: 'absolute',
            paddingLeft: '1.5%',
            marginLeft: 'auto',
            marginRight: 'auto',
        },
        iconLink: {
            width: 'auto',
            height: '12px',
            marginLeft: 'auto',
            marginRight: 'auto',
            paddingRight: '5px'
        },

        minister: {
            marginLeft: 'auto',
            marginRight: 'auto',
            width: '100%',
            maxWidth: '400px',
            height: 'auto',
            borderRadius: '10%',
            display: 'table-cell',
            // verticalAlign: 'middle',
            marginTop: '10%',

        },
        cardStyle: {
            boxShadow: '2px 2px 7px -1px rgba(0,0,0,0.25)', "padding": 10,
            minHeight: innerHeight - 130
        },
        infoSpace: {
            paddingLeft: 5
        },
        rowSpace: {
            paddingTop: 10,
        }
    }

    return (
        <div className="card" >
            <LoadPanelComponent position={{ of: '#mainLayout' }} visible={loader} />
            <TopHeader urlServer={urlServer} fileNameData={bannerFileName} />
            <div style={style.cardStyle} >
                <BackButton onClick={() => history.goBack()} />
                <div className="row" >
                    <div className="col-4">
                        <img loading="lazy" src={base_image_url + workerInfo.file_name} style={style.minister} alt={"text"} />
                    </div>
                    {/*style={{backgroundColor: 'blue'}}*/}
                    <div className="col-8" style={{ paddingTop: '1%' }} >
                        <div className="row">
                            <div className="col-1" />
                            <div className="col-11">
                                <p>{workerInfo && workerInfo.structure_name.toUpperCase()} - {workerInfo && workerInfo.position_name.toUpperCase()} </p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-1" />
                            <div className="col-11 hzText-bold">
                                <p style={{ fontSize: 20, textTransform: 'uppercase' }}>{workerInfo.last_name} {workerInfo.first_name}</p>
                            </div>
                        </div>
                        <div className="row" style={style.rowSpace}>
                            <div className="col-1"/>
                            {/* <div className="col-1" >
                                <img loading="lazy" src={schools} style={style.iconStyle} alt={"text"} />
                            </div>*/}
                            <div className="col-11 hzText-bold"><p>Төгссөн сургууль:</p></div>
                        </div>
                        <div className="row">
                            <div className="col-1" />
                            <div className="col-11 hz-italic" style={style.textJustify}>
                                <table>
                                    {headEdu && headEdu.map((item) => (
                                        <tr>
                                            <td width={120}><span> {item['col-2'] && item['col-2']} {(item['col-3'] && item['col-2']) && '-'} {item['col-3'] && item['col-3'] + ' '}</span></td>
                                            <td>{item['col-1']} {item['col-1'] && item['col-0'] ?  ', ' + item['col-0'] :  item['col-0']} {item['col-4'] && ', ' + item['col-4']}</td>
                                        </tr>
                                    ))}
                                </table>
                            </div>
                        </div>
                        <div className="row" style={style.rowSpace} hidden={headCourse && headCourse.length > 0 ? false : true}>
                            <div className="col-1"/>
                            <div className="col-11 hzText-bold"><p>Курс дамжаа:</p></div>
                        </div>
                        <div className="row" hidden={headCourse ? false : true}>
                            <div className="col-1" />
                            <div className="col-11 hz-italic" style={style.textJustify}>
                                <table>
                                    {headCourse && headCourse.map((item) => (
                                        <tr>
                                            <td width={120}><span> {item['col-2'] && item['col-2']} {(item['col-3'] && item['col-2']) && '-'} {item['col-3'] && item['col-3'] + ' '}</span></td>
                                            <td>{item['col-1']} {item['col-1'] && item['col-0'] ?  ', ' + item['col-0'] :  item['col-0']} {item['col-4'] && ', ' + item['col-4']}</td>
                                        </tr>
                                    ))}
                                </table>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-1" />
                           {/* <div className="col-1">
                                <img loading="lazy" src={achievment} style={style.iconStyle} alt={"text"} />
                            </div>*/}
                            <div className="col-11 hzText-bold"><p>Ажлын туршлага:</p></div>
                        </div>
                        <div className="row">
                            <div className="col-1" />
                            <div className="col-11 hz-italic" style={style.textJustify}>
                                <table>
                                    {headExp && headExp.map((item) => (
                                        <tr>
                                            <td width={120}>
                                                <span> {item['col-1'] && item['col-1']} {(item['col-1'] && item['col-2']) && '-'} {item['col-2'] && item['col-2'] + ' '}</span>
                                            </td>
                                            <td>{item['col-0']} {item['col-3'] && ', ' + item['col-3']}</td>
                                        </tr>
                                    ))}
                                </table>

                            </div>
                        </div>
                        <div className="row" style={style.rowSpace} hidden={headLang && headLang.length > 0 ? false : true}>
                            <div className="col-1" />
                            <div className="col-11 hzText-bold"><p>Гадаад хэлний мэдлэг:</p></div>
                        </div>
                        <div className="row" hidden={headLang && headLang.length > 0 ? false : true}>
                            <div className="col-1" />
                            <div className="col-11 hz-italic" style={style.textJustify}>
                                <table>
                                    {headLang && headLang.map((item) => (
                                        <tr>
                                            <td width={120}><span> {item['col-2'] && item['col-2']} {(item['col-3'] && item['col-2']) && '-'} {item['col-3'] && item['col-3'] + ' '}</span></td>
                                            <td>{item['col-1']} {item['col-1'] && item['col-0'] ?  ', ' + item['col-0'] :  item['col-0']} {item['col-4'] && ', ' + item['col-4']}</td>
                                        </tr>
                                    ))}
                                </table>
                            </div>
                        </div>
                        {headContact &&
                            <div className="row" style={style.rowSpace} hidden={headContact && headContact.length > 0 ? false : true}>
                                <div className="col-1" />
                               {/* <div className="col-1">
                                    <img loading="lazy" src={contact} style={style.iconStyle} alt={"text"} />
                                </div>*/}
                                <div className="col-11 hzText-bold"><p>Холбоо барих:</p></div>
                            </div>
                        }
                        <div className="row">
                            <div className="col-1" />
                            <div className="col-11" style={style.textJustify}>
                                {headContact && headContact.map((item) => (
                                    <a href={item.link} target={'_blank'} rel="noreferrer">
                                        <p>
                                            <img loading="lazy" src={item.icon} style={style.iconLink}
                                                alt={"text"} />{item.showField}
                                        </p>
                                    </a>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

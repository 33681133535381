import React, {useState, useRef, useCallback,useContext} from "react";
import PivotGrid, {Export,FieldChooser} from 'devextreme-react/pivot-grid';
import {saveAs} from "file-saver-es";
import {Workbook} from "exceljs";
import {exportPivotGrid} from "devextreme/excel_exporter";
import {Button} from "devextreme-react/button";
import {CgArrowsExpandRight, CgCompressRight, CgMaximize, CgMinimize} from "react-icons/cg";
import { UserContext } from "../../../hooks/UserContext"
import {formatDate} from "../../../util";
import moment from "moment";
export default function MinistryGrid({dataSource,year,selectedReport,selectedOrg}) {
    let _pivotGrid = useRef(null);
    const [expanded, setExpanded] = useState(false);
    const [fullscreen, setFullscreen] = useState(false);
    const [groupValues, setGroupValues] = useState([])
    const { user } = useContext(UserContext)
    const getItems = (data, key)=> {
        let items = [];
        data.forEach(item => {
            if (item.key === key) items = item.items;
        });
        return items;
    }
    const onCellClick = (e) => {
        //prevent click on an empty field
        let item = groupValues.filter(i => i.key === e.cell.text)[0];
        if (groupValues.filter(i => i.key === e.cell.text).length > 0) {
            e.cancel = item.empty;
        }
    }
    const onCellPrepared = (e) => {
            /*if (e.area === 'row') {
                /!*e.cellElement.style.color = "#fff"
                e.cellElement.style.backgroundColor = "#E8E8E8";*!/
                if (e.cell.text !== undefined && e.cell.text.substr(6, 1) === '-' || e.cell.text === "") {
                    e.cell.expanded = true;
                    let cell = e.cellElement.querySelector(".dx-expand-icon-container");
                    if (cell) cell.innerHTML = "";
                }

            }*/
        if (e.area === 'row' && e.cell.path?.length === 1) {
            e.cellElement.style.color = "#003696 !important"
            e.cellElement.style.fontWeight = "bold"
        }
        if (e.area === 'row' && e.cell.path?.length === 4) {
            e.cellElement.style.fontWeight = "500"
        };

        if (e.area !== "row" || !e.cell.path) return;
        let item = groupValues.filter(i => i.key === e.cell.text)[0];
        if (item) {
            // cached value
            if (item.empty)  //e.cellElement.querySelector(".dx-expand-icon-container").innerHTML = "";

                //e.cell.expanded = true;
            //let cell = e.cellElement.querySelector(".dx-expand-icon-container");
            if (e.cellElement.querySelector(".dx-expand-icon-container")) e.cellElement.querySelector(".dx-expand-icon-container").innerHTML = "";
            return;
        }
        let drillDownDataSource = e.component
            .getDataSource()
            .createDrillDownDataSource(e.cell);
        // let fields = [];
        let fields = e.component
            .getDataSource()
            .getAreaFields("row")
            .map(f => f.dataField);

        drillDownDataSource.group(fields);
        drillDownDataSource.load().then(data => {
            let isEmpty = true;
            for (var i = 0; i < e.cell.path.length; i++) {
                let groupKey = e.cell.path[i];
                data = getItems(data, groupKey);
            }
            data.forEach(item => {
                if (item.key !== undefined) isEmpty = false;
            });
            //setGroupValues({ key: e.cell.text, empty: isEmpty })
            /*setGroupValues(prev => {
                return [...prev, { key: e.cell.text, empty: isEmpty}];
            })*/
            groupValues.push({ key: e.cell.text, empty: isEmpty });

            //console.log('groupValues', groupValues);
            if (isEmpty) {
                let cell = e.cellElement.querySelector(".dx-expand-icon-container");
                if (cell) cell.innerHTML = "";
                /*e.cell.expanded = true;
                let cell = e.cellElement.querySelector(".dx-expand-icon-container");
                if (cell) cell.innerHTML = "";*/
            }
        });

    }
    const onExportingPivot = (e) => {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Main Sheet');
        //console.log('onExportingPivot', e);
        exportPivotGrid({
            component: e.component,
            worksheet,
            topLeftCell: {row: 6, column: 2},
            mergeRowFieldValues: false,
            keepColumnWidths: true,
            customizeCell: ({pivotCell, excelCell}) => {
                excelCell.font = {name: 'Arial', size: 10, color: {argb: '000000'}}
                //console.log('pivotCell', pivotCell);
                if (pivotCell.area === 'column') {
                    excelCell.fill = {type: 'pattern', pattern: 'solid', fgColor: {argb: 'd9d9d9'}};
                    excelCell.border = {
                        top: {style: 'thin', color: {argb: '2c2c2c'}},
                        left: {style: 'thin', color: {argb: '2c2c2c'}},
                        bottom: {style: 'thin', color: {argb: '2c2c2c'}},
                        right: {style: 'thin', color: {argb: '2c2c2c'}}
                    }
                }
                if (pivotCell.area === 'row' && !isNaN(pivotCell.text.substring(0, 6) * 1) && pivotCell.text.substring(0, 6).length !== 6) {
                    Object.assign(excelCell, {
                        font: { bold: true },
                    });
                excelCell.showRowFields = false
                }
                const borderStyle = {style: 'thin', color: {argb: 'FF7E7E7E'}};
                excelCell.border = {
                    bottom: borderStyle,
                    left: borderStyle,
                    right: borderStyle,
                    top: borderStyle,
                };
            },
        }).then((cellRange) => {
            Object.assign(worksheet.getRow(1).getCell(1), {
                value: 'Гарчиг:',
                font: { name: 'Arial', size: 8 },
            })
            Object.assign(worksheet.getRow(1).getCell(2), {
                value: `${year} оны Төсвийн төслийн нэгтгэл тайлан`,
                font: { name: 'Arial', size: 8, 'bold': true, color: { argb: '0070c0' } },
            })
            Object.assign(worksheet.getRow(2).getCell(1), {
                value: 'Байгууллага:',
                font: { name: 'Arial', size: 8 },
            })
            Object.assign(worksheet.getRow(2).getCell(2), {
                value: selectedOrg.org_name,
                font: { name: 'Arial', size: 8 },
            })
            Object.assign(worksheet.getRow(3).getCell(1), {
                value: 'Хэрэглэгч:',
                font: { name: 'Arial', size: 8 },
            })
            Object.assign(worksheet.getRow(3).getCell(2), {
                value: `${user.last_name.slice(0, 1)}. ${user.first_name}, ${user.position_name}`,
                font: { name: 'Arial', size: 8 },
            })
            Object.assign(worksheet.getRow(4).getCell(1), {
                value: 'Огноо:',
                font: { name: 'Arial', size: 8 },
            })
            Object.assign(worksheet.getRow(4).getCell(2), {
                value: new moment().locale('mn').format('lll'),
                font: { name: 'Arial', size: 8 },
            })
        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer], {type: 'application/octet-stream'}), `OG-${year} оны төсвийн төслийн нэгтгэлийн дэлгэрэнгүй-` + formatDate(new Date()) + ".xlsx");
            });
        });
        e.cancel = true;
    };
    const changeState = (area, value) => {
        let pvDataSource = _pivotGrid.current.instance.getDataSource();
        //console.log('area', area);
        _pivotGrid.current.instance.beginUpdate();
        let fields = value ? pvDataSource.fields() : pvDataSource.fields().slice().reverse();
        fields.forEach((f) => {
            if (value) {
                //console.log('f.dataField', f.dataField);
                f.expanded = true;
                pvDataSource.expandAll(f.dataField);
            } else {
                f.expanded = false;
                pvDataSource.collapseAll(f.dataField);
            }
        });
        _pivotGrid.current.instance.endUpdate();
    }
    const ExpandCollapseButton = useCallback((area) => {
        return (<Button
            onClick={(e) => {
                setExpanded(!expanded);
                changeState(area, !expanded)
            }}
            render={() => {
                return expanded ? (
                        <CgCompressRight size={"17px"} color="#1a1976"/>)
                    :
                    (
                        <CgArrowsExpandRight size={"17px"} color="#1a1976"/>
                    )
            }}
            hint={expanded ? 'Багасгах' : 'ЭЗА дэлгэрэнгүй харуулах'}
            >
        </Button>)
    }, [expanded])

    const requestFullscreen = () => {
        if (document.fullscreenElement) document.exitFullscreen();
        else document.getElementById("wrapper").requestFullscreen();
        // setFullscreen(prev=>!prev)
    }
    const FullscreenButton = useCallback(() => {
        return (
            <Button
                onClick={() => {
                    requestFullscreen()
                    setFullscreen(prev => !prev)
                }}
                style={{marginLeft: "0.5em"}}
                render={() => {
                    return fullscreen ?
                        (<CgMinimize size={"17px"} color="#1a1976"/>)
                        :
                        (<CgMaximize size={"17px"} color="#1a1976"/>)
                }}
                hint={fullscreen ? 'Багасгах' : 'Дэлгэцэнд бүтэн харуулах'}
            >
            </Button>
        )
    }, [fullscreen])

    return (
        <React.Fragment>
            <div id="wrapper" style={{width: "100%", background: "white"}}>
                <div style={{
                    flex: 1,
                    justifyContent: 'flex-end',
                    flexDirection: 'row',
                    display: 'flex',
                    marginBottom: 5,
                    borderRadius: 10,
                    padding: 10
                }}>
                    <ExpandCollapseButton/>
                    <FullscreenButton/>
                </div>
                <PivotGrid
                    style={{textAlign: "left !important"}}
                    id="pivotgrid"
                    ref={_pivotGrid}
                    // showTotalsPrior={'both'}
                    height={'80%'}
                    dataSource={dataSource}
                    allowSortingBySummary={true}
                    rowHeaderLayout={'tree'}
                    allowFiltering={true}
                    // showBorders={true}
                    showColumnGrandTotals={true}
                    showRowGrandTotals={selectedReport === 'default' ? true : false}
                    showRowTotals={true}
                    wordWrapEnabled={true}
                    showColumnTotals={user.organization.obt_id === 1 ? true : false}
                    allowExpandAll={true}
                    onCellPrepared={onCellPrepared}
                    onExporting={onExportingPivot}
                    hideEmptySummaryCells={true}
                    loadPanel={{enabled: true,text:'Уншиж байна'}}
                    onCellClick={onCellClick}
                    texts={{
                        noData: 'Дата байхгүй байна.',
                        collapseAll: 'Бүгд хураах',
                        expandAll: 'Бүгд дэлгэх',
                        showFieldChooser: 'Талбар сонголт харах',
                        exportToExcel: 'Excel файл татах',
                        grandTotal: 'Нийт дүн',
                        total: '{0}',
                        sortRowBySummary:'Эрэмбэлэх {0}',
                        sortColumnBySummary: 'Эрэмбэлэх {0}',
                        removeAllSorting:'Бүх эрэмбүүдийг болих'
                    }}

                >     <FieldChooser title={'Талбар удирдах'} texts={{allFields: 'Бүх талбарууд',filterFields:'Шүүж харах талбар сонгох',rowFields:'Мөрүүд',columnFields:'Баганууд',dataFields: 'Өгөгдөл талбарууд'}}/>  {/*<HeaderFilter
                    allowSearch={true}
                    showRelevantValues={true}
                    width={300}
                    height={400}
                />
                    <FieldChooser allowSearch={true} />
                    <FieldPanel showFilterFields={true} showDataFields={false} showColumnFields={false} showRowFields={true} allowFieldDragging={false} visible={true} />
                   */}
                    <Export enabled={true}/>
                </PivotGrid>
            </div>
        </React.Fragment>
    )
}

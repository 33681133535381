import React, {useContext, useEffect, useState, useRef} from 'react';
import 'devextreme-react/text-area';
import DataGrid, {
    Editing,
    Pager,
    Paging,
    Column,
    FilterRow,
    Export, TotalItem, Summary, GroupPanel, SearchPanel, Grouping, GroupItem
} from 'devextreme-react/data-grid';
import {Workbook} from 'exceljs';
import {saveAs} from 'file-saver-es';
import {exportDataGrid} from 'devextreme/excel_exporter';
import {LoadPanel} from "devextreme-react/load-panel";
import {UserContext} from "../../../hooks/UserContext";
import fcsService from "../../../services/api/fcs";
import Button from "devextreme-react/button";
import fcsServices from "../../../services/api/fcs";
import notify from "devextreme/ui/notify";
import LoadPanelComponent from '../../../components/LoadPanelComponent';

function CurrentMonthDetial(props) {
    const yearValue = props.yearValue || -1;
    const monthValue = props.monthValue || -1;


    const userListPosition = {of: '#usersList'}
    const [dataSource, setDataSource] = useState([]);
    const [loader, setLoader] = useState(false);
    const myBabyRef = useRef(null);
    const [dataFcsMonth, setFcsMonth] = useState([]);
    useEffect(() => {

        // getList();
    }, [])

    useEffect(() => {

        getList();
    }, [yearValue,monthValue] )

    const getList = async () => {
        try {
            setLoader(true);


         if   ( yearValue < 2000) {
         const    fcsFa = await fcsService.getAllFcsFa()
                 setDataSource(fcsFa)

         }
            if   ( yearValue > 2000) {
            const   fcsFa1 = await fcsService.getFcsFaMonthReport(yearValue, monthValue)
                    setDataSource(fcsFa1.data[0])
            }


        const fcsMonth = await fcsService.getAllFcsMonth('FA');
        setFcsMonth(fcsMonth);
        return setLoader(false);

    }
catch
    (e)
    {
        console.error(e);
    }
}

const onEditorPreparing = (e) => {
    // console.log('sdsd ', e);
    if (e.parentType === "dataRow" && e.dataField !== "password") {
        return;
    }
    if (e.row && e.row.isNewRow) {
        e.editorOptions.visible = true;
    }
    if (e.row && !e.row.isNewRow) {
        e.editorOptions.visible = false;
    }
}
const customAddRow = (grid) => {
    grid.current.instance.addRow();
}
const onInitNewRow = (e) => {

}
const setData = async (e) => {
}
const onExporting = (e) => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Main sheet');

    exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
    }).then(() => {
        workbook.xlsx.writeBuffer().then((buffer) => {
            saveAs(new Blob([buffer], {type: 'application/octet-stream'}),yearValue <2000 ?  'CurrentMonthByAcctReport.xlsx' : 'fa'+yearValue+monthValue+'.xlsx'  );
        });
    });
    e.cancel = true;
}
const customizeDate = (data) => {
    return Number(parseFloat(data.value).toFixed(2)).toLocaleString("en", {
        minimumFractionDigits: 2,
    });
}

const endQty = (rowData) => {
    return Number(rowData.bbqty) + Number(rowData.orqty) - Number(rowData.zarqty);
}

const endAmt = (rowData) => {
    return Number(rowData.bbamt) + Number(rowData.oramt) + Number(rowData.orcap) - Number(rowData.zaramt);
}
const endel = (rowData) => {
    return Number(rowData.bbel) + Number(rowData.orel) + Number(rowData.sarel) - Number(rowData.zarel);
}
const endCost = (rowData) => {
    return Number(rowData.bbamt) + Number(rowData.oramt) + Number(rowData.orcap) - Number(rowData.zaramt) -
        (Number(rowData.bbel) + Number(rowData.orel) + Number(rowData.sarel) - Number(rowData.zarel));
}
const style={
    text: {
        color: "#2E4765", fontWeight: "700", fontSize: ".875rem"
    }
}
const procFcsFaUpdate = async () => {
    setLoader(true);
    if (dataFcsMonth[0].year && dataFcsMonth[0].month) {
        try {
            const result = await fcsServices.procFcsFaUpdate(dataFcsMonth[0].year, dataFcsMonth[0].month);
            if (result) {
                getList();
                notify('Амжилттай элэгдэл бодогдлоо', "success", 2000);
                return setLoader(false);
            } else {
                return setLoader(false);
            }
        } catch (e) {
            notify(e.message, "error", 2000);
        }
    }
};
return (
    <div style={{padding: "0.5rem"}}>
        <LoadPanelComponent
            shadingColor="rgba(0,0,0,0.4)"
            position={{of: '#password'}}
            message=''
            showPane={false}
        />
        <div className="col-12 row" style={{justifyContent: "center", gap: '2rem', padding: '5px 15px'}}>
        <div style={{marginLeft: 20, textTransform: "uppercase", marginTop: 10,...style.text}}><h4> Үндсэн хөрөнгийн дэлгэрэнгүй тайлан </h4></div>
  
        </div>

        <div className="col-12 row" style={{justifyContent: "space-between", gap: '2rem', padding: '5px 15px', marginTop: 10}}>
                           <span> {  yearValue<2000 &&   dataFcsMonth.map((item) => {
                               return <div><span style={{color : "#A3A3A3", marginRight: ".5rem" }}>Тайлант он</span><span style={{fontWeight: "700"}}>{item.year} сар {item.month}</span></div>;
                           })}
                </span>

            {
                yearValue<2000 && <Button text="Сарын элэгдэл бодуулах" onClick={() => procFcsFaUpdate()} width={200} type="default"
                                          style={{alignment: "center", borderRadius: '7px'}}/>
            }




        </div>

        <LoadPanelComponent
            shadingColor="rgba(0,0,0,0.4)"
            position={userListPosition}
            visible={loader}
            showPane={false}
            message=''
        />
        <div>
            <div className="card-body">
                <DataGrid
                    onEditorPreparing={onEditorPreparing}
                    ref={myBabyRef}
                    dataSource={dataSource}
                    onSaved={setData}
                    keyExpr={null}
                    onInitNewRow={onInitNewRow}
                    allowColumnResizing={true}
                    onExporting={onExporting}>
                    <GroupPanel visible={true}/>
                    <SearchPanel visible={true}/>
                    <Grouping autoExpandAll={false}/>
                    <Pager
                        showPageSizeSelector={true}
                        // showInfo={true}
                        showNavigationButtons={true}
                    />
                    <Column
                        groupIndex={0}
                        dataField="acct_code"
                        caption="Данс"
                        minWidth= {100}
                        alignment="center"
                        allowEditing={false}
                        cssClass="custom"
                        allowFiltering={true}
                    >

                    </Column>
                    <Column
                        dataField="name"
                        caption="Нэр"
                        allowEditing={false}
                        minWidth= {100}
                        alignment="center"
                        cssClass="custom"
                        allowFiltering={true}
                        width={100}
                    >

                    </Column>
                    <Column
                        dataField="fa_number"
                        caption="Дугаар"
                        allowEditing={false}
                        minWidth= {100}
                        alignment="center"
                        cssClass="custom"
                        allowFiltering={true}
                    >

                    </Column>

                    <Column caption="Эхний үлдэгдэл"
                    alignment="center"
                    >
                        <Column
                            cssClass="custom"
                            dataField="bbqty"
                            caption="Тоо"
                            minWidth= {60}
                        alignment="center"
                            dataType="number" format="#,##0.00"
                        >

                        </Column>

                        <Column
                            cssClass="custom"
                            dataField="bbamt"
                            minWidth= {80}
                        alignment="center"
                            caption="Дүн"
                            dataType="number" format="#,##0.00"
                        >

                        </Column>

                    </Column>


                    <Column caption="Нэмэгдсэн"
                    alignment="center"
                    >
                        <Column
                            cssClass="custom"
                            dataField="orqty"
                            minWidth= {60}
                        alignment="center"
                            caption="Тоо"
                            dataType="number" format="#,##0.00"
                        >

                        </Column>

                        <Column
                            cssClass="custom"
                            dataField="oramt"
                            minWidth= {80}
                        alignment="center"
                            caption="Дүн"
                            dataType="number" format="#,##0.00"
                        >

                        </Column>

                        <Column
                            cssClass="custom"
                            dataField="orcap"
                            minWidth= {100}
                        alignment="center"
                            caption="Капиталжуулалт"
                            dataType="number" format="#,##0.00"
                        >

                        </Column>


                    </Column>


                    <Column caption="Хасагдсан"
                    
                        alignment="center"
                    >
                        <Column
                            cssClass="custom"
                            minWidth= {60}
                        alignment="center"
                            dataField="zarqty"
                            caption="Тоо"
                            dataType="number" format="#,##0.00"
                        >

                        </Column>

                        <Column
                            cssClass="custom"
                            minWidth= {80}
                        alignment="center"
                            dataField="zaramt"
                            caption="Дүн"
                            dataType="number" format="#,##0.00"
                        >

                        </Column>


                    </Column>
                    <Column caption="Эцсийн үлдэгдэл" 
              
                        alignment="center">
                        <Column
                            cssClass="custom"
                            name="endQty"
                            calculateDisplayValue={endQty}
                            minWidth= {60}
                        alignment="center"
                            calculateCellValue={endQty}

                            caption="Тоо"
                            dataType="number" format="#,##0.00"
                        >

                        </Column>

                        <Column
                            cssClass="custom"
                            name="endAmt"
                            calculateDisplayValue={endAmt}
                            minWidth= {80}
                        alignment="center"
                            calculateCellValue={endAmt}
                            caption="Дүн"
                            dataType="number" format="#,##0.00"
                        >

                        </Column>


                    </Column>


                    <Column caption="Элэгдэл"
                 
                        alignment="center">
                        <Column
                            cssClass="custom"
                            dataField="bbel"
                            minWidth= {100}
                        alignment="center"
                            caption="Хуримтлагдсан"
                            dataType="number" format="#,##0.00"
                        >
                        </Column>
                        <Column
                            cssClass="custom"
                            dataField="orel"
                            minWidth= {100}
                        alignment="center"
                            caption="Нэмэгдсэн хөрөнгийн"
                            dataType="number" format="#,##0.00"
                        >
                        </Column>
                        <Column
                            cssClass="custom"
                            dataField="sarel"
                            minWidth= {100}
                        alignment="center"
                            caption="Сарын"
                            dataType="number" format="#,##0.00"
                        >
                        </Column>
                        <Column
                            cssClass="custom"
                            dataField="zarel"
                            minWidth= {100}
                        alignment="center"
                            caption="Хассан хөрөнгийн"
                            dataType="number" format="#,##0.00"
                        >
                        </Column>
                        <Column
                            cssClass="custom"
                            name={"endel"}
                            calculateDisplayValue={endel}
                            minWidth= {100}
                        alignment="center"
                            calculateCellValue={endel}

                            caption="Элэгдлийн дүн"
                            dataType="number" format="#,##0.00"
                        >
                        </Column>
                    </Column>
                    <Column
                        cssClass="custom"
                        name="endCost"
                        calculateDisplayValue={endCost}
                        minWidth= {100}
                        alignment="center"
                        calculateCellValue={endCost}

                        caption="Үлдэх өртөг"
                        dataType="number" format="#,##0.00"
                    >
                    </Column>
                    <Summary>

                        <GroupItem
                            column="bbamt"
                            summaryType="sum"
                            customizeText={customizeDate}
                            showInGroupFooter={false}
                            alignByColumn={true} alignment="right"
                        />


                        <GroupItem
                            column="oramt"
                            summaryType="sum"
                            customizeText={customizeDate}
                            showInGroupFooter={false}
                            alignByColumn={true} alignment="right"
                        />
                        <GroupItem
                            column="orcap"
                            summaryType="sum"
                            customizeText={customizeDate}
                            showInGroupFooter={false}
                            alignByColumn={true} alignment="right"
                        />


                        <GroupItem
                            column="zaramt"
                            summaryType="sum"
                            showInGroupFooter={false}
                            alignByColumn={true} alignment="right"
                            customizeText={customizeDate}/>

                        <GroupItem
                            column="endAmt"
                            summaryType="sum"
                            showInGroupFooter={false}
                            alignByColumn={true} alignment="right"
                            customizeText={customizeDate}/>

                        <GroupItem
                            column="bbel"
                            summaryType="sum"
                            showInGroupFooter={false}
                            alignByColumn={true} alignment="right"
                            customizeText={customizeDate}/>
                        <GroupItem
                            column="orel"
                            summaryType="sum"
                            showInGroupFooter={false}
                            alignByColumn={true} alignment="right"
                            customizeText={customizeDate}/>
                        <GroupItem
                            column="sarel"
                            summaryType="sum"
                            showInGroupFooter={false}
                            alignByColumn={true} alignment="right"
                            customizeText={customizeDate}/>
                        <GroupItem
                            column="zarel"
                            summaryType="sum"
                            showInGroupFooter={false}
                            alignByColumn={true} alignment="right"
                            customizeText={customizeDate}/>

                        <GroupItem
                            column="endel"
                            summaryType="sum"

                            showInGroupFooter={false}
                            alignByColumn={true} alignment="right"
                            customizeText={customizeDate}/>

                        <GroupItem
                            column="endCost"
                            summaryType="sum"
                            showInGroupFooter={false}
                            alignByColumn={true} alignment="right"
                            customizeText={customizeDate}/>

                        <TotalItem
                            column="bbamt"
                            summaryType="sum"
                            customizeText={customizeDate}/>


                        <TotalItem
                            column="oramt"
                            summaryType="sum"
                            customizeText={customizeDate}/>
                        <TotalItem
                            column="orcap"
                            summaryType="sum"
                            customizeText={customizeDate}/>
                        <TotalItem
                            column="zaramt"
                            summaryType="sum"
                            customizeText={customizeDate}/>
                        <TotalItem
                            column="endAmt"
                            summaryType="sum"
                            customizeText={customizeDate}/>
                        <TotalItem
                            column="bbel"
                            summaryType="sum"
                            customizeText={customizeDate}/>
                        <TotalItem
                            column="orel"
                            summaryType="sum"
                            customizeText={customizeDate}/>
                        <TotalItem
                            column="sarel"
                            summaryType="sum"
                            customizeText={customizeDate}/>
                        <TotalItem
                            column="zarel"
                            summaryType="sum"
                            customizeText={customizeDate}/>


                        <TotalItem
                            column="endel"
                            summaryType="sum"
                            customizeText={customizeDate}/>

                        <TotalItem
                            column="endCost"
                            summaryType="sum"
                            customizeText={customizeDate}/>
                    </Summary>
                    <Editing
                        mode="popup"
                        allowUpdating={false}
                        allowDeleting={false}
                        // allowAdding = {adding}
                        useIcons={true}
                        texts={{
                            cancelAllChanges: 'Болих бүгд',
                            cancelRowChanges: 'Болих',
                            confirmDeleteMessage: 'Энэ ангилалын мэдээллийг устгах уу?',
                            confirmDeleteTitle: 'Баталгаажуулах',
                            deleteRow: 'Устгах',
                            editRow: 'Өөрчлөх',
                            saveAllChanges: 'Хадгалах бүгд',
                            saveRowChanges: 'Хадгалах',
                            undeleteRow: 'Буцаах',
                            validationCancelChanges: 'Баталгаажуулах болих',
                        }}
                    >
                    </Editing>
                    <Paging enabled={true}/>
                    <Export enabled={true} allowExportSelectedData={false}/>
                </DataGrid>
            </div>
        </div>
    </div>
)
}

export default CurrentMonthDetial;

import React, { useContext } from "react"
import { Steps } from "antd"
import { UserContext } from "../../../hooks/UserContext"

export const ScheduleTransferProgressBar = ({ currentStatus }) => {
    const { user } = useContext(UserContext)

    const initialStep = () => {
        return !currentStatus || [1, 2, 3].includes(currentStatus) ? 'Шинэ' : user.organization.obt_name
    }

    const checkTEZ = () => {
        switch (currentStatus) {
            case 3:
                return 'Боловсруулж байгаа';
            case 6:
                return 'Хянаж байгаа';
            case 8:
                return 'Хүргүүлсэн'
            case 11:
                return 'Нэгтгэсэн';
            case 15:
                return 'Татгалзсан';
            case 9:
            case 12:
                return 'Боловсруулсан';
            default:
                return 'Хүлээгдэж байгаа';
        }
    }

    const setStatus = () => {
        if([10,11,12].includes(currentStatus)) return 'finish'
        else if([13, 14, 15].includes(currentStatus)) return 'error'
        else return 'process'
    }
    
    return (
        <div className='card' style={{ padding: 20, }}>
            <Steps
                current={
                    user.organization.obt_id === 1 ?
                        ([3, 6, 11, 15].includes(currentStatus) ? 0 : [9, 12].includes(currentStatus) ? 1 : 0)
                        :
                        user.organization.obt_id === 2 ?
                            ([2, 5, 7, 10, 14].includes(currentStatus) ? 0 : [3, 6, 8, 11, 15].includes(currentStatus) ? 1 : [9, 12].includes(currentStatus) ? 2 : 0)
                            :
                            (user.organization.obt_id === 3 && user.organization.parent_obt_id === 2) ?
                                ([1, 4, 13].includes(currentStatus) ? 0 : [2, 5, 7, 10, 14].includes(currentStatus) ? 1 : [3, 6, 8, 11, 15].includes(currentStatus) ? 2 : [9, 12].includes(currentStatus) ? 3 : 0)
                                :
                                (user.organization.obt_id === 3 && user.organization.parent_obt_id === 1) ?
                                    ([1, 4, 13].includes(currentStatus) ? 0 : [3, 6, 8, 11, 15].includes(currentStatus) ? 1 : [9, 12].includes(currentStatus) ? 2 : 0)
                                    :
                                    0
                }
                status={setStatus()}
                items={
                    user.organization.obt_id === 1
                        ?
                        [
                            { title: initialStep(), description: currentStatus === 3 ? 'Боловсруулж байгаа' : currentStatus === 6 ? 'Хянаж байгаа' : currentStatus === 11 ? 'Нэгтгэсэн' : currentStatus === 15 ? 'Татгалзсан' : 'Хүсэлт үүсгэх' },
                            { title: 'Сангийн яам', description: [3, 6, 11, 15].includes(currentStatus) ? 'Хүлээгдэж байгаа' : currentStatus === 12 ? 'Баталсан' : 'Хүргүүлсэн' }
                        ]
                        :
                        (user.organization.obt_id === 3 && user.organization.parent_obt_id === 2)
                            ?
                            [
                                { title: initialStep(), description: !currentStatus ? 'Хүсэлт үүсгэх' : currentStatus === 1 ? 'Боловсруулж байгаа' : currentStatus === 4 ? 'Хянаж байгаа' : currentStatus === 13 ? 'Татгалзсан' : 'Боловсруулсан' },
                                { title: 'ТТЗ', description: currentStatus === 7 ? 'Хүргүүлсэн' : currentStatus === 5 ? 'Хянаж байгаа' : currentStatus === 2 ? 'Боловсруулж байгаа' : currentStatus === 10 ? 'Нэгтгэсэн' : currentStatus === 14 ? 'Татгалзсан' : [1, 4, 13].includes(currentStatus) ? 'Хүлээгдэж байгаа' : 'Боловсруулсан'},
                                { title: 'ТЕЗ', description: checkTEZ() },
                                { title: 'Сангийн яам', description: currentStatus === 12 ? 'Баталсан' : currentStatus === 9 ? 'Хүргүүлсэн' : '' }
                            ]
                            : (user.organization.obt_id === 3 && user.organization.parent_obt_id === 1) ?
                                [
                                    { title: initialStep(), description: !currentStatus ? 'Хүсэлт үүсгэх' : currentStatus === 1 ? 'Боловсруулж байгаа' : currentStatus === 4 ? 'Хянаж байгаа' : currentStatus === 13 ? 'Татгалзсан' : 'Боловсруулсан' },
                                    { title: 'ТЕЗ', description: checkTEZ() },
                                    { title: 'Сангийн яам', description: currentStatus === 12 ? 'Баталсан' : currentStatus === 9 ? 'Хүргүүлсэн' : '' }
                                ]
                                :
                                [
                                    { title: initialStep(), description: !currentStatus ? 'Хүсэлт үүсгэх' : currentStatus === 2 ? 'Боловсруулж байгаа' : currentStatus === 5 ? 'Хянаж байгаа' : currentStatus === 7 ? 'Хүргүүлсэн' : currentStatus === 10 ? 'Нэгтгэсэн' : currentStatus === 14 ? 'Татгалзсан' : 'Боловсруулсан' },
                                    { title: 'ТЕЗ', description: checkTEZ() },
                                    { title: 'Сангийн яам', description: currentStatus === 12 ? 'Баталсан' : currentStatus === 9 ? 'Хүргүүлсэн' : '' },
                                ]
                }
            />
        </div>
    )
}
import request from '../../shared/lib/request'

const getViewerCounts = () => {
    return request({
        url: `/ga4/getViewerCounts`,
        method: 'GET',
    })
}
const getDailyWeeklyMonthlyData = () => {
    return request({
        url: `/ga4/getDailyWeeklyMonthlyData`,
        method: 'GET',
    })
}

const getPopularPaths = () => {
    return request({
        url: `/ga4/getPopularPaths`,
        method: 'GET',
    })
}

const getEventValues = () => {
    return request({
        url: `/ga4/getEventValues`,
        method: 'GET',
    })
}

const ga4Services = {
    getViewerCounts,
    getDailyWeeklyMonthlyData,
    getPopularPaths,
    getEventValues
}

export default ga4Services;

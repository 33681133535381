import React, { Component } from 'react';
import "./tableTemplate.css";
import dynamicTableService from "../../services/api/dynamicTable";
import {Tooltip} from "devextreme-react/tooltip";
import budgetCalculationService from "../../services/api/budgetCalculation";
import {DropDownOptions, Lookup} from "devextreme-react/lookup";
import DataSource from "devextreme/data/data_source";
import DropDownBox from "devextreme-react/drop-down-box";

class BudgetCalcDataByEcon extends Component {
    constructor(props) {
        super(props);

        this.state = {
            header: [],
            leftHeader: [],
            data: [],
            formattedTopHeader: [],
            formattedLeftHeader: [],
            leftTotalColSpan: 0,
            tariffList: []
        };
    }

    componentDidMount() {
        // console.log('component did mount', this.props.econID, this.props.year, this.props.eventID, this.props.agendaID, this.props.orgID);
        this.getDynamicTable(this.props.year, this.props.econID, this.props.eventID, this.props.agendaID, this.props.orgID);
    }

    getDynamicTable = async (year, econID, eventID, agendaID, orgID) => {
        let tariffService = dynamicTableService.getRefTariffInfo(econID, orgID);
        let tableService = dynamicTableService.getDynamicTableByEcon(econID);
        let agendaService = dynamicTableService.getAgendaList();
        let eventService = dynamicTableService.getEventList();
        let response = await Promise.all([tariffService, tableService, agendaService, eventService]);
        let header = [];
        let data = [];
        let tariffList = [];
        let eventList = [];
        let agendaList = [];
        let filteredData = [];

        if (response[0].code === 200) {
            tariffList = response[0].data || [];
        }

        if (response[2].code === 200) {
            agendaList = response[2].data || [];
        }

        if (response[3].code === 200) {
            eventList = response[3].data || [];
        }

        if (response[1].code === 200) {
            let d1 = response[1].data || {};
            let mainHeader = d1.header || {};
            header = mainHeader.header || [];

            let dynamicTableId = d1.id;

            let response1 = await budgetCalculationService.getBudgetCalculationData(dynamicTableId, orgID);
            let ia = 0;
            if (response1.code === 200) {
                let d = response1.data || {};
                let dynamicTableData = d.dynamicTableData || {};
                data = dynamicTableData.data || [];
                let dataGrouped = Object.keys(Object.groupBy(data, ({ leftName }) => leftName));
                for (let i=0; i<dataGrouped.length; i++) {
                    let filterAgenda = data.filter(obj => obj.topName === 'Хөтөлбөр' && obj.value === agendaID && obj.leftName === i);
                    let filterEvent = data.filter(obj => obj.topName === 'Арга хэмжээ' && obj.value === eventID && obj.leftName === i);
                    if (filterAgenda.length > 0 && filterEvent.length > 0) {
                        // console.log('a', i, filterAgenda, filterEvent);

                        let groupByLeftName = Object.keys(Object.groupBy(data, ({ leftName }) => leftName));
                        // console.log('groupByLeftName', groupByLeftName)
                        // let maxKey = Math.max(...(Object.keys(groupByLeftName) || [])) || 0;
                        let b = data.filter(obj => obj.leftName === i);
                        b.map(obj => obj.leftName = ia);
                        filteredData = [...filteredData, ...b];
                        ia+=1;
                    }
                }
                // console.log('data', data, filteredData)
            }
        }

        // console.log('data', data)

        this.setState({
            header,
            data: filteredData,
            tariffList,
            agendaList,
            eventList
        }, () => {
            this.headerFormatter(header);
        })
    }

    headerFormatter = (header) => {
        let formattedTopHeader = [];
        let formattedLeftHeader = [];
        let leftTotalColSpan = 0;
        header.map((data, index) => {
            if (0 === index) {
                data.map((data1, index1) => {
                    for (let i=0; i<data1.colSpan; i++) {
                        let obj = {topName: data1.name, rowSpan: data1.rowSpan-1, colSpan: data1.colSpan};
                        if (data1.formula) {
                            obj.formula = data1.formula;
                        }

                        if (data1.sumFormula) {
                            obj.sumFormula = true;
                        }

                        if (data1.config) {
                            obj.config = true;
                        }

                        if (data1.configInput) {
                            obj.configInput = true;
                        }

                        if (data1.configDefaultColumn) {
                            obj.configDefaultColumn = data1.configDefaultColumn;
                        }

                        if (data1.stringColumn) {
                            obj.stringColumn = true;
                        }

                        if (data1.numberColumn) {
                            obj.numberColumn = true;
                        }

                        formattedTopHeader.push(obj)
                    }
                })
            } else {
                data.map((data1, index1) => {
                    for (let i=0; i<data1.colSpan; i++) {
                        let addedField = false;
                        formattedTopHeader.map((data2, index2) => {
                            if (!data2['index'+index+'Set']) {
                                if (data2.rowSpan > 0) {
                                    data2.rowSpan -= 1;
                                    data2['index'+index+'Set'] = true;
                                } else if (data2.rowSpan === 0 && !data2['topName'+index] && addedField === false) {
                                    data2['topName'+index] = data1.name;
                                    data2.rowSpan = data1.rowSpan-1;
                                    addedField = true;
                                    data2['index'+index+'Set'] = true;

                                    if (data1.formula) {
                                        data2.formula = data1.formula;
                                    }

                                    if (data1.sumFormula) {
                                        data2.sumFormula = true;
                                    }

                                    if (data1.config) {
                                        data2.config = true;
                                    }

                                    if (data1.configInput) {
                                        data2.configInput = true;
                                    }

                                    if (data1.configDefaultColumn) {
                                        data2.configDefaultColumn = data1.configDefaultColumn;
                                    }

                                    if (data1.stringColumn) {
                                        data2.stringColumn = true;
                                    }

                                    if (data1.numberColumn) {
                                        data2.numberColumn = true;
                                    }
                                }
                            }
                        })
                    }
                });
            }
        })

        let mainRowSpan = 0;
        let rowSpan = 0;
        this.state.leftHeader.map((data, index) => {
            let totalColSpan = 0;

            if (rowSpan === 0) {
                rowSpan = data[0].rowSpan;
                mainRowSpan = data[0].rowSpan;
            }

            if (rowSpan >= 1 && mainRowSpan > 1) {
                if (mainRowSpan === rowSpan) {
                    let flhData = [];
                    data.map((data1, index1) => {
                        totalColSpan += data1.colSpan;
                        if (index1 === 0) {
                            for (let i=0; i<data1.rowSpan; i++) {
                                let d = {leftName: data1.name, rowSpan: data1.rowSpan, colSpan: data1.colSpan};
                                flhData.push(d);
                            }
                        } else {
                            for (let i=0; i<mainRowSpan; i++) {
                                let si = index1-1;
                                if (i === 0) {
                                    si = index1;
                                }
                                flhData[i]['leftName'+index1] = this.state.leftHeader[index+i][si].name;
                            }
                        }
                    });
                    formattedLeftHeader = [...formattedLeftHeader, ...flhData]
                }

                rowSpan -= 1;
            } else if (rowSpan === 1 && mainRowSpan === 1) {
                let flhData = {};
                for (let i=0; i<data.length; i++) {
                    let data1 = data[i];
                    totalColSpan += data1.colSpan;
                    if (i === 0) {
                        flhData = {leftName: data1.name, rowSpan: data1.rowSpan, colSpan: data1.colSpan};
                    } else {
                        let tIndex = 0;
                        for (let i1=0; i1<i; i1++) {
                            tIndex += data[i1].colSpan;
                        }
                        flhData['leftName' + tIndex] = data1.name;
                    }
                }
                rowSpan = 0;
                formattedLeftHeader.push(flhData)
            }

            if (leftTotalColSpan < totalColSpan) {
                leftTotalColSpan = totalColSpan;
            }
        });

        this.setState({formattedTopHeader, formattedLeftHeader, leftTotalColSpan})
    }

    numberWithCommas = (x) => {
        let parts = x.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return parts.join(".");
    }

    lookupRender = (data) => (data ? `${data.code.substring(0, 5)} - ${data.name}` : '');

    render() {
        let data = this.state.data;
        let dataGrouped = Object.keys(Object.groupBy(data, ({ leftName }) => leftName));
        let formattedTopHeader = this.state.formattedTopHeader;
        let ecID = this.props.econID;
        // console.log('dataGrouped', dataGrouped);
        // let a = data.filter(obj => obj.topName==='Хөтөлбөр' && obj.value===8 && obj.leftName===0);
        // console.log('a', a)

        return (<div className={'dynamicTable-container'} style={{overflow: 'scroll'}}>
            <table className={'dynamicTable'}>
                <thead key={110}>
                    <tr>
                        {Array.from({length: (this.state.header[0] || []).reduce((acc,v) => {return acc+v.colSpan}, 0)}).map((d, i) => {
                            let i1 = i+1;
                            if (i === 0) {
                                return <td key={i1} colSpan={3} style={{borderTop: 0, borderLeft: 0, borderRight: 0, textAlign: 'center', background: '#f5f5f5', cursor: 'auto'}}>{i1}</td>
                            } else {
                                return <td key={i1} style={{borderTop: 0, borderLeft: 0, borderRight: 0, textAlign: 'center', background: '#f5f5f5', cursor: 'auto'}}>{i1}</td>
                            }
                        })}
                    </tr>
                </thead>
                <thead>
                    {
                        this.state.header.map((data, index) => {
                            return <tr key={index}>
                                {data.map((data1, index1) => {
                                    if (data1.name === '№') {
                                        return <>
                                            <th key={1001} style={{width: 50}} rowSpan={data1.rowSpan} colSpan={data1.colSpan}>{data1.name}</th>
                                            <th key={1002} rowSpan={data1.rowSpan} colSpan={data1.colSpan}>Хөтөлбөр</th>
                                            <th key={1003} rowSpan={data1.rowSpan} colSpan={data1.colSpan}>Арга хэмжээ</th>
                                        </>
                                    } else {
                                        let formula = data1.formula;
                                        let formulaText;
                                        if (formula) {
                                            formulaText = <span>
                                                                <i id={'formulaIcon'+index1} className="dx-icon-info" style={{fontSize: 12}}/>
                                                                <Tooltip
                                                                    target={"#formulaIcon"+index1}
                                                                    showEvent="dxhoverstart"
                                                                    hideEvent="dxhoverend"
                                                                >
                                                                    <div style={{padding: '5px 10px'}}>{formula}</div>
                                                                </Tooltip>
                                                            </span>
                                        }


                                        return <th key={index1} style={{width: 'auto'}} rowSpan={data1.rowSpan} colSpan={data1.colSpan}>
                                            <div style={{display: 'flex', gap: 5}}>
                                                {data1.name}{formulaText}
                                            </div>
                                        </th>
                                    }
                                })}
                            </tr>
                        })
                    }
                </thead>
                <tbody>
                    {dataGrouped.map((d1, i1) => {
                        return <tr key={i1}>
                            {formattedTopHeader.length > 0 && formattedTopHeader.map((d2, i2) => {
                                let searchObject = {leftName: i1};

                                Object.keys(d2).map((d3, i1) => {
                                    if (d3.indexOf("topName") !== -1) {
                                        searchObject[d3] = d2[d3]
                                    }
                                });

                                let mainValue = 0;
                                let formula = d2.formula;

                                if (formula) {
                                    let formulaCharacters = formula.match(/[+-/*%]+|[0-9a-z]+/g);
                                    let formulaVal = "";

                                    for (let i=0; i<formulaCharacters.length; i++) {
                                        let character = formulaCharacters[i];
                                        let char = character*1;
                                        let searchObject1 = {leftName: i1};
                                        let specialChars = /[-+*\/]/;
                                        let stringChars = /[a-z]/;

                                        if (char) {
                                            let topHeader = this.state.formattedTopHeader[char-1] || {};
                                            Object.keys(topHeader).map((d3, i3) => {
                                                if (d3.indexOf("topName") !== -1) {
                                                    searchObject1[d3] = topHeader[d3]
                                                }
                                            });

                                            let fnd = this.state.data.find(d3 => {
                                                return Object.keys(searchObject1).every((key) => {
                                                    return d3[key] === searchObject1[key]
                                                })
                                            }) || {};

                                            let sumValue = (fnd.value || 0);
                                            formulaVal += `${sumValue}`;
                                        } else if (stringChars.test(character)) {
                                            let numberOnly = character.replace(/\D/g, "");
                                            formulaVal += numberOnly;
                                        } else if (specialChars.test(character)) {
                                            formulaVal += character;
                                        }
                                    }

                                    mainValue = eval(formulaVal);

                                    let id = ecID+"cellRow"+i1+'Col'+i2;
                                    let element = document.getElementById(id);
                                    mainValue = mainValue.toFixed(2);
                                    if (element) {
                                        let innerText = (element.innerText || "");
                                        if (innerText !== mainValue) {
                                            // this.topSaveData(searchObject, 'Amount', mainValue);
                                        }
                                    }
                                } else {
                                    let fnd = this.state.data.find(d => {
                                        return Object.keys(searchObject).every((key) => {
                                            return d[key] === searchObject[key]
                                        })
                                    }) || {};

                                    if (d2.stringColumn) {
                                        mainValue = fnd.value || '';
                                    } else {
                                        if (d2.numberColumn) {
                                            mainValue = Math.floor(fnd.value || 0);
                                        } else if (d2.config) {
                                            mainValue = fnd.value;
                                        } else {
                                            mainValue = (fnd.value || 0).toFixed(2);
                                        }
                                    }
                                }

                                if (d2.topName === '№') {
                                    let agendaSearchObject = {...searchObject};
                                    let eventSearchObject = {...searchObject};
                                    agendaSearchObject.topName = 'Хөтөлбөр';
                                    eventSearchObject.topName = 'Арга хэмжээ';

                                    let fndAgenda = this.state.data.find(d => {
                                        return Object.keys(agendaSearchObject).every((key) => {
                                            return d[key] === agendaSearchObject[key]
                                        })
                                    }) || {};
                                    let fndEvent = this.state.data.find(d => {
                                        return Object.keys(eventSearchObject).every((key) => {
                                            return d[key] === eventSearchObject[key]
                                        })
                                    }) || {};

                                    return <>
                                        <td
                                            key={i2+1100}
                                            style={{width: 50}}
                                            className={'disabledTD'}>
                                            {i1+1}
                                        </td>
                                        <td key={i2} style={{width: 220}}>
                                            <Lookup
                                                dataSource={new DataSource({
                                                    store: this.state.agendaList,
                                                    key: 'id',
                                                    group: 'parent_name',
                                                })}
                                                placeholder={'Хөтөлбөр'}
                                                grouped={true}
                                                width={200}
                                                valueExpr={'id'}
                                                disabled={true}
                                                displayExpr={this.lookupRender}
                                                value={fndAgenda.value || null}
                                            >
                                                <DropDownOptions
                                                    hideOnOutsideClick={true}
                                                    showTitle={false}
                                                    width={400}
                                                />
                                            </Lookup>
                                        </td>
                                        <td key={1101} style={{width: 220}}>
                                            <Lookup
                                                dataSource={new DataSource({
                                                    store: this.state.eventList,
                                                    key: 'id',
                                                    group: 'parent_name',
                                                })}
                                                placeholder={'Арга хэмжээ'}
                                                grouped={true}
                                                width={200}
                                                valueExpr={'id'}
                                                disabled={true}
                                                displayExpr={this.lookupRender}
                                                value={fndEvent.value || null}
                                            >
                                                <DropDownOptions
                                                    hideOnOutsideClick={true}
                                                    showTitle={false}
                                                    width={400}
                                                />
                                            </Lookup>
                                        </td>
                                    </>
                                } else if (d2.config) {
                                    // console.log('d2', d2, mainValue)
                                    return <td
                                        key={i2}
                                        rowSpan={1}
                                        colSpan={1}
                                        id={ecID+'cellRow'+i1+'Col'+i2}
                                        style={{width: 220}}>
                                        <DropDownBox
                                            value={mainValue}
                                            opened={false}
                                            valueExpr="id"
                                            deferRendering={false}
                                            disabled={true}
                                            displayExpr={(item) => item && `${item.tariff_type.name} <${item.unit_measure_obj.name}>`}
                                            placeholder="Сонгох"
                                            showClearButton={true}
                                            dataSource={this.state.tariffList}
                                            dropDownOptions={{
                                                width: 'auto',
                                            }}
                                            style={{width: 200}}
                                        />
                                    </td>
                                } else {
                                    return <td
                                        key={i2}
                                        rowSpan={1}
                                        colSpan={1}
                                        id={ecID+'cellRow'+i1+'Col'+i2}
                                        className={(d2.formula || d2.config || d2.configInput || d2.configDefaultColumn) ? 'disabledTD' : ''}
                                        suppressContentEditableWarning={true}>
                                        {d2.stringColumn ? mainValue : this.numberWithCommas(mainValue)}
                                    </td>
                                }
                            })}
                        </tr>
                    })}
                    <tr key={1000}>
                        {formattedTopHeader.length > 0 && formattedTopHeader.map((d2, i2) => {
                            let value = '';

                            if (d2.formula && d2.sumFormula) {
                                value = 0;
                                let searchObject = {};
                                Object.keys(d2).map((d3, i1) => {
                                    if (d3.indexOf("topName") !== -1) {
                                        searchObject[d3] = d2[d3]
                                    }
                                });

                                value = (data.filter(element => {
                                    return Object.keys(searchObject).every((key) => {
                                        return element[key] === searchObject[key]
                                    })
                                }) || []).reduce((acc, obj) => {
                                    return acc+obj.value
                                }, 0).toFixed(2);
                            }

                            if (d2.topName === '№') {
                                return <>
                                    <td key={1000} className={'disabledTD'}/>
                                    <td key={1001} className={'disabledTD'}/>
                                    <td key={1002} className={'disabledTD'}/>
                                </>
                            } else {
                                return <td key={i2} id={'cellFooter'+i2} className={'disabledTD'}>{this.numberWithCommas(value)}</td>
                            }
                        })}
                    </tr>
                </tbody>
            </table>
        </div>)
    }
}

export default BudgetCalcDataByEcon;

import React, {useState, useContext, Component} from "react";
import DataGrid, {
    Editing,
    Column
} from 'devextreme-react/data-grid';
import { Button } from 'devextreme-react/button';
import { LoadIndicator } from 'devextreme-react/load-indicator';
import { Toast } from 'devextreme-react/toast';
import './dynamicTable.css'
import dynamicTableService from "../../services/api/dynamicTable";

class calculate extends Component {

    constructor(props) {
        super(props);
        this.state = {
            header: [],
            leftHeader: [],
            data: [],
            formattedTopHeader: [],
            formattedLeftHeader: [],
            leftTotalColSpan: 0,
            loadIndicatorVisible: false,
            buttonText: 'Хадгалах',
            toastConfig: {
                isVisible: false,
                type: 'info',
                message: '',
            }
        }
    }

    componentDidMount() {
        this.getHeader(this.props.match.params.dynamicTableId);
    }

    getHeader = async (id) => {
        const res = await dynamicTableService.getDynamicTable(id);
        if (res && res.code === 200) {
            let data = res.data.header || {};
            console.log('data', data)
            // , data: data.data
            this.setState({header: data.header, leftHeader: data.leftHeader}, () => {
                this.headerFormatter(data.header, data.leftHeader);
            });
        } else {
            this.setState({header: [], leftHeader: [], data: []});
        }
    }

    headerFormatter = (header, leftHeader) => {
        let formattedTopHeader = [];
        let formattedLeftHeader = [];
        let leftTotalColSpan = 0;
        this.state.header.map((data, index) => {
            if (0 === index) {
                data.map((data1, index1) => {
                    for (let i=0; i<data1.colSpan; i++) {
                        formattedTopHeader.push({topName: data1.name, rowSpan: data1.rowSpan-1, colSpan: data1.colSpan})
                    }
                })
            } else {
                data.map((data1, index1) => {
                    for (let i=0; i<data1.colSpan; i++) {
                        let addedField = false;
                        formattedTopHeader.map((data2, index2) => {
                            if (!data2['index'+index+'Set']) {
                                if (data2.rowSpan > 0) {
                                    data2.rowSpan -= 1;
                                    data2['index'+index+'Set'] = true;
                                } else if (data2.rowSpan === 0 && !data2['topName'+index] && addedField === false) {
                                    data2['topName'+index] = data1.name;
                                    data2.rowSpan = data1.rowSpan-1;
                                    addedField = true;
                                    data2['index'+index+'Set'] = true;
                                }
                            }
                        })
                    }
                });
            }
        })

        let mainRowSpan = 0;
        let rowSpan = 0;
        this.state.leftHeader.map((data, index) => {
            let totalColSpan = 0;

            if (rowSpan === 0) {
                rowSpan = data[0].rowSpan;
                mainRowSpan = data[0].rowSpan;
            }

            if (rowSpan >= 1 && mainRowSpan > 1) {
                if (mainRowSpan === rowSpan) {
                    let flhData = [];
                    data.map((data1, index1) => {
                        totalColSpan += data1.colSpan;
                        if (index1 === 0) {
                            for (let i=0; i<data1.rowSpan; i++) {
                                let d = {leftName: data1.name, rowSpan: data1.rowSpan, colSpan: data1.colSpan};
                                flhData.push(d);
                            }
                        } else {
                            for (let i=0; i<mainRowSpan; i++) {
                                let si = index1-1;
                                if (i === 0) {
                                    si = index1;
                                }
                                flhData[i]['leftName'+index1] = this.state.leftHeader[index+i][si].name;
                            }
                        }
                    });
                    formattedLeftHeader = [...formattedLeftHeader, ...flhData]
                }

                rowSpan -= 1;
            } else if (rowSpan === 1 && mainRowSpan === 1) {
                let flhData = {};
                for (let i=0; i<data.length; i++) {
                    let data1 = data[i];
                    totalColSpan += data1.colSpan;
                    if (i === 0) {
                        flhData = {leftName: data1.name, rowSpan: data1.rowSpan, colSpan: data1.colSpan};
                    } else {
                        let tIndex = 0;
                        for (let i1=0; i1<i; i1++) {
                            tIndex += data[i1].colSpan;
                        }
                        flhData['leftName' + tIndex] = data1.name;
                    }
                }
                rowSpan = 0;
                formattedLeftHeader.push(flhData)
            }

            if (leftTotalColSpan < totalColSpan) {
                leftTotalColSpan = totalColSpan;
            }
        });

        this.setState({formattedTopHeader, formattedLeftHeader, leftTotalColSpan})
    }

    saveData = (field, value) => {
        let data = this.state.data;
        let fnd = this.state.data.find(d => {
            return Object.keys(field).every((key) => {
                return d[key] === field[key]
            })
        })

        if (fnd) {
            fnd.value = value*1;
        } else {
            let obj = {};
            Object.keys(field).map(key => {
                obj[key] = field[key];
            })
            obj.value = value*1;
            data.push(obj)
        }
        // console.log('saveData',fnd, data);
        this.setState({data: data})
    }

    saveDataToDB = async () => {
        this.setState({loadIndicatorVisible: true, buttonText: 'Түр хүлээнэ үү'})

        const res = await dynamicTableService.setDynamicTableData(5, this.state.data, 2023, 10, 24);
        if (res && res.code === 200) {
            this.setState({toastConfig: {...this.state.toastConfig, isVisible: true, type: 'success', message: 'Амжилттай хадгаллаа'}})
        } else {
            this.setState({toastConfig: {...this.state.toastConfig, isVisible: true, type: 'error', message: 'Амжилтгүй боллоо'}})
        }

        this.setState({loadIndicatorVisible: false, buttonText: 'Хадгалах'})
    }

    onHiding = () => {
        this.setState({toastConfig: {...this.state.toastConfig, isVisible: false}});
    }

    render() {
        let tableData = {column: [{name: 'no'}, {name: 'title'}], row: []}
        return <div className={'dynamicTable-container'}>
            <Toast
                visible={this.state.toastConfig.isVisible}
                message={this.state.toastConfig.message}
                type={this.state.toastConfig.type}
                onHiding={this.onHiding}
                displayTime={600}
            />
            <div className={'card'}>
                <div className={'card-body'}>
                    <Button
                        type="default"
                        stylingMode="contained"
                        onClick={() => this.saveDataToDB()}
                    >
                        <LoadIndicator className="button-indicator whiteIndicator" style={{marginRight: 10}} shadingColor={'white'} visible={this.state.loadIndicatorVisible} />
                        <span className="dx-button-text">{this.state.buttonText}</span>
                    </Button>
                    <br/>
                    <br/>
                    <table className={'dynamicTable'}>
                        <thead>
                        {
                            this.state.header.map((data, index) => {
                                return <tr>
                                    {data.map((data1, index1) => {
                                        return <th rowSpan={data1.rowSpan} colSpan={data1.colSpan}>{data1.name}</th>
                                    })}
                                </tr>
                            })
                        }
                        </thead>
                        <tbody>
                        {this.state.leftHeader.map((data, index) => {
                            let leftFormattedData = this.state.formattedLeftHeader[index];
                            // console.log('left header', data)
                            return <tr>
                                {data.map((data1, index1) => {
                                    return <th rowSpan={data1.rowSpan} colSpan={data1.colSpan}>{data1.name}</th>
                                })}
                                {this.state.formattedTopHeader.slice(this.state.leftTotalColSpan, this.state.formattedTopHeader.length).map((d,indexCol) => {
                                    let searchObject = {};
                                    let rValue = 0;
                                    let formula = data[0].formula;

                                    if (formula) {
                                        let searchObject1 = {};
                                        let formulaObject = {};
                                        let type = '+';
                                        formula.split(" ").map((v, fi) => {
                                            let formulaCharacters = v.match(/[+-/*]+|[0-9]+/g);
                                            for (let i=0; i<formulaCharacters.length; i++) {
                                                let item = formulaCharacters[i];
                                                let tIndex = item*1;

                                                if (tIndex) {
                                                    let leftFormattedData1 = this.state.formattedLeftHeader[tIndex-1];
                                                    Object.keys(leftFormattedData1).map((d1, i1) => {
                                                        if (d1.indexOf("leftName") !== -1) {
                                                            searchObject1[d1] = leftFormattedData1[d1];
                                                        }
                                                    })

                                                    Object.keys(d).map((d1, i1) => {
                                                        if (d1.indexOf("topName") !== -1) {
                                                            searchObject1[d1] = d[d1]
                                                        }
                                                    });

                                                    let fnd = this.state.data.find(data => {
                                                        return Object.keys(searchObject1).every((key) => {
                                                            return data[key] === searchObject1[key]
                                                        })
                                                    }) || {};
                                                    let sumValue = (fnd.value || 0);
                                                    // console.log('type',type)
                                                    switch (type) {
                                                        case '+':
                                                            rValue += sumValue;
                                                            break;
                                                        case '-':
                                                            rValue -= sumValue;
                                                            break;
                                                        case '/':
                                                            rValue /= sumValue;
                                                            break;
                                                        case '*':
                                                            rValue *= sumValue;
                                                            break;
                                                        default:
                                                            rValue += sumValue;
                                                            break;
                                                    }

                                                } else {
                                                    type = item;
                                                }
                                            }
                                            // console.log("", v.match(/[+-/*]+|[0-9]+/g))
                                        })

                                        //javascript add class
                                        let id = "cell"+index+''+indexCol;
                                        let element = document.getElementById(id);
                                        if (element) {
                                            let innerText = element.innerText*1;
                                            if (innerText !== rValue) {
                                                element.classList.add("changed");
                                                setTimeout(() => {
                                                    element.classList.remove("changed");
                                                }, 700);

                                                if (rValue > 0) {
                                                    let leftFormattedData = this.state.formattedLeftHeader[index];
                                                    Object.keys(leftFormattedData).map((d1, i1) => {
                                                        if (d1.indexOf("leftName") !== -1) {
                                                            formulaObject[d1] = leftFormattedData[d1];
                                                        }
                                                    })

                                                    Object.keys(d).map((d1, i1) => {
                                                        if (d1.indexOf("topName") !== -1) {
                                                            formulaObject[d1] = d[d1]
                                                        }
                                                    });

                                                    this.saveData(formulaObject, rValue)
                                                }
                                            }
                                        }
                                    } else {
                                        Object.keys(d).map((d1, i1) => {
                                            if (d1.indexOf("topName") !== -1) {
                                                searchObject[d1] = d[d1]
                                            }
                                        })

                                        Object.keys(leftFormattedData).map((d1, i1) => {
                                            if (d1.indexOf("leftName") !== -1) {
                                                searchObject[d1] = leftFormattedData[d1];
                                            }
                                        });

                                        let fnd = this.state.data.find(data => {
                                            return Object.keys(searchObject).every((key) => {
                                                return data[key] === searchObject[key]
                                            })
                                        }) || {};

                                        rValue = fnd.value || 0;
                                    }

                                    return <td rowSpan={1} colSpan={1} id={'cell'+index + '' + indexCol} className={data[0].formula ? 'disabledTD': ''} contentEditable={!data[0].formula} onBlur={(e) => this.saveData(searchObject, e.target.innerText)}>
                                        {rValue}
                                    </td>
                                })}
                            </tr>
                        })}

                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    }
}

export default calculate;

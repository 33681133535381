import React, { useState, useEffect } from "react";
import ProposalService from "../../../services/api/proposal";
import DataGrid, { Column } from "devextreme-react/data-grid";

const UpdatedProposalHistory = (props) => {
    const [budgetHistory, setBudgetHistory] = useState([]);

    const getHistoryLoad = async (data) => {
        const res = await ProposalService.getHistory(data);
        let ac = [];
        let tt = res.data.sort(function (a, b) {
            let t = new Date(b.ognoo) - new Date(a.ognoo);
            return t;
        });
        setBudgetHistory(tt);
    };

    useEffect(() => {
        getHistoryLoad(props.currentProposal)
    }, [props.currentProposal]);

    const uildelRender = (e) => {
        return (
            e.data.action_updated_type === 1
                ? 'Төлөв ' + e.data.status + ' боллоо'
                : (e.data.action_updated_type === 2 ? 'Төслийн дүн ' + e.data.amount + ' болж өөрчлөгдлөө'
                    : (e.data.action_updated_type === 3 ? 'Шинэ хэлэлцүүлэг нэмэгдсэн'
                        : (e.data.action_updated_type === 4 ? 'Ерөнхий мэдээлэл өөрчлөгдсөн' : '')))
        )
    }
    const onCellPreparedHandler = (e) => {
        if (e.rowType === 'header') {
            e.cellElement.style.color = "#161863"
            e.cellElement.style.backgroundColor = "#EFF0F4"
            e.cellElement.style.fontWeight = "bold";
            e.cellElement.style.padding = "1rem";
        }
        if (e.rowType === 'data') {
            e.cellElement.style.backgroundColor = "#fff"
            // e.cellElement.style.fontWeight = "bold";
            //e.cellElement.style.padding = "1rem";
        }
    }
    return (
        <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
            <div style={{ fontSize: "1rem", fontWeight: 700, marginBottom: "1rem" }}>Төслийн түүх</div>
            <DataGrid
                showBorders={true}
                dataSource={budgetHistory}
                noDataText='Дата байхгүй байна.'
                onCellPrepared={onCellPreparedHandler}
                showRowLines={true} columnAutoWidth={true}
            >
                <Column
                    caption="Огноо"
                   // width={'150'}
                    dataType="date"
                    dataField="ognoo"
                    format="yyyy-MM-dd HH:mm:ss"
                />
                <Column
                    caption="Овог нэр"
                    //width={'150'}
                    cellRender={(e) => (
                        e.data.last_name && `${e.data.last_name.substring(0, 1)}.${e.data.first_name}`
                        // e.data.first_name && `${e.data.first_name}`
                    )}
                />
                <Column
                    caption="Байгууллага, албан тушаал"
                    cellRender={(e) => (
                        e.data.organization_name && `${e.data.organization_name} - ${e.data.position_name}`
                        // e.data.first_name && `${e.data.first_name}`
                    )}
                />
                <Column
                    caption="Үйлдэл"
                    //width={'200'}
                    cellRender={uildelRender}
                />
            </DataGrid>
            {/*<ScrollView*/}
            {/*    id="scrollview"*/}
            {/*    scrollByContent={true}*/}
            {/*    showScrollbar='onHover'*/}
            {/*    height={700}*/}
            {/*>*/}
            {/*    <div>*/}
            {/*        {*/}
            {/*            Object.keys(budgetHistory && budgetHistory).map((e, index) => {*/}
            {/*                let b = budgetHistory[e];*/}
            {/*                return (*/}
            {/*                    <div style={{display: "flex", flexDirection: "row", margin: '5px'}} key={index}>*/}
            {/*                        <div style={{display: "flex", flexDirection: "row", alignItems: "center", flex: 1, padding: "10px"}} className="col-12">*/}
            {/*                            <div className="col-1" style={{marginRight:"7px", backgroundColor: "white", border: "2px solid", borderColor: (_.find(color_status.actionColor, { id: budgetHistory[e].action_updated_type }) || {}).color, width: 16, height: 16, borderRadius: "100%"}}></div>*/}
            {/*                            <span className="col-3" style={{padding: '0 0 0 7px'}}> {moment(b.ognoo).format("YYYY-MM-DD HH:mm:ss")} </span>*/}
            {/*                            <span className="col-3" style={{marginLeft: "7px", padding: "7px", borderRadius: "5px", border: "1px solid", borderColor: (_.find(color_status.actionColor, { id: budgetHistory[e].action_updated_type }) || {}).borcolor, backgroundColor: (_.find(color_status.actionColor, { id: budgetHistory[e].action_updated_type }) || {}).bgcolor, color: (_.find(color_status.actionColor, { id: budgetHistory[e].action_updated_type }) || {}).color }}>*/}
            {/*                          {*/}
            {/*                              budgetHistory[e].action_updated_type === 1*/}
            {/*                                  ? 'Төлөв ' + b.status + ' боллоо'*/}
            {/*                                  : (budgetHistory[e].action_updated_type === 2 ? 'Төслийн дүн ' + b.amount + ' болж өөрчлөгдлөө'*/}
            {/*                                      : (budgetHistory[e].action_updated_type === 3 ? 'Шинэ хэлэлцүүлэг нэмэгдсэн'*/}
            {/*                                          : (budgetHistory[e].action_updated_type === 4 ? 'Ерөнхий мэдээлэл өөрчлөгдсөн' : '')))*/}
            {/*                          }*/}
            {/*                        </span>*/}
            {/*                            <div style={{display: "flex", flexDirection: "column", padding: "10px"}} className="col-5">*/}
            {/*                                <span className="col-12" style={{paddingLeft: "7px"}}> {budgetHistory[e].organization_name}</span>*/}
            {/*                                <span className="col-12" style={{paddingLeft: "7px", color: (_.find(color_status.actionColor, { id: budgetHistory[e].action_updated_type }) || {}).color}}> {budgetHistory[e].position_name} </span>*/}
            {/*                                <span className="col-12" style={{paddingLeft: "7px"}}>{budgetHistory[e].last_name && budgetHistory[e].last_name.substring(0, 1)}.{budgetHistory[e].first_name}</span>*/}
            {/*                            </div>*/}
            {/*                        </div>*/}
            {/*                    </div>*/}
            {/*                )*/}
            {/*            })*/}
            {/*        }*/}
            {/*    </div>*/}
            {/*</ScrollView>*/}
        </div>
    )
}

export default UpdatedProposalHistory;

import React, {useContext, useEffect, useRef, useState} from "react"
import {UserContext} from "../../hooks/UserContext"
import Form, {
  Item as FormItem1,
  Label,
  RequiredRule,
} from "devextreme-react/form"
import OrganizationSelectComponent from "../../components/OrganizationSelectComponent"
import newsServices from "../../services/api/news"
import {Button as DevExtremeButton} from "devextreme-react/button"
import {custom} from "devextreme/ui/dialog"
import notify from "devextreme/ui/notify"
import styled from "styled-components"
import {
  DateBox,
  FileUploader,
  List,
  LoadIndicator,
  Popup,
  ScrollView,
  SelectBox,
  TextBox,
} from "devextreme-react"
import ImageViewer from "../../components/ImageViewer"
import {BiDownload, BiSolidEditAlt} from "react-icons/bi"
import {BsFillTrashFill} from "react-icons/bs"
import {urlServer} from "../../shared/lib/request"
import {DatePicker, Pagination} from "antd"
import FileServices from "../../services/api/file"
import FileCard from "./FileCard"
import moment from "moment"

const StyledList = styled(List)`
  .dx-list-item-content {
    padding: 0px;
  }
`
const allowedPhotoExtensions = [".jpg", ".jpeg", ".png", ".webp"]
const allowedFileExtensions = [".xls", ".pdf", ".docx", ".pptx"]
function Publication() {
  const {user} = useContext(UserContext)
  const [allNews, setAllNews] = useState([])
  const [orgId, setOrgId] = useState(user.org_id)
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(5)
  const [totalNumber, setTotalNumber] = useState()
  const [loading, setLoading] = useState(false)
  const [toggle, setToggle] = useState(false)
  const [editToggle, setEditToggle] = useState(false)
  const [year, setYear] = useState('')
  const [data, setData] = useState({})
  const titleRef = useRef(null)
  const photoRef = useRef(null)
  const fileRef = useRef(null)
  const markupRef = useRef(null)
  const yearRef = useRef(null)
  const yearList = Array.from({ length: 30 }, (_, i) => (new Date().getFullYear() - i).toString());
  const isAdmin = false

  useEffect(() => {
    if (orgId) {
      fetchData(orgId)
    }
  }, [currentPage, orgId])

  const onRemovingEvent = async (id) => {
    try {
      let result = await newsServices.deleteNewsById(id)
      await newsServices.deleteNotificationById(id)
      if (result?.code === 200) {
        fetchData()
        return notify("Амжилттай устгалаа", "success", 4000)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }
  const deleteHandler = async (id) => {
    const customConfirm = custom({
      title: "Баталгаажуулалт",
      messageHtml: "<i>Устгахдаа итгэлтэй байна үү?</i>",
      buttons: [
        {
          text: "Тийм",
          onClick: (e) => {
            return true
          },
        },
        {
          text: "Үгүй",
          onClick: (e) => {
            return false
          },
        },
      ],
    })

    customConfirm.show().then((dialogResult) => {
      if (dialogResult) {
        onRemovingEvent(id)
      } else {
        fetchData()
      }
    })
  }
  const fetchData = async () => {
    try {
      const getAllNews = async () => {
        let {data, total} = await newsServices.getAllPublications(
          currentPage,
          pageSize,
          orgId
        )
        setTotalNumber(total)
        setAllNews(data)
      }
      await getAllNews()
    } catch (error) {
      setAllNews([])
      setLoading(false)
      console.log(error)
    } finally {
      setLoading(false)
    }
  }
  const onTitleChange = (e) => {
    titleRef.current = e.value
  }
  const onMarkupChange = (e) => {
    markupRef.current = e.value
  }
  function getFileFormat(fileName) {
    const lastDotIndex = fileName.lastIndexOf(".")

    if (lastDotIndex === -1) {
      return null
    }

    const format = fileName.substring(lastDotIndex + 1).toLowerCase()
    return format
  }

  const editHandler = (e) => {
    setData(e)
    setEditToggle(true)
  }
  function ImageUploaderCard(props) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: "1rem",
        }}
      >
        <div>{allNews?.findIndex((i) => i?.id === props?.id) + 1}.</div>
        <div className="portalImageCard">
          <div className="meta_data">
            <span className="file_type">{getFileFormat(props.file_name)}</span>
            <span className="file_size">
              {(props?.file_size / 1024).toFixed(2)} kb
            </span>
          </div>
          <div className="thumbnail">
            <ImageViewer
              array={[props]}
              filename="front_profile_file_name"
              height="5rem"
            />
          </div>
          <div className="text">{props?.news_title}</div>

          <div className="controllers">
            <a href={`${urlServer}/api/file/${props?.file_name}`}>
              <BiDownload className="look" style={{fontSize: "1.4rem"}} />
            </a>
            <BiSolidEditAlt
              onClick={() => editHandler(props)}
              className="edit"
              style={{fontSize: "1.2rem"}}
            />
            <BsFillTrashFill
              className="delete"
              onClick={() => {
                deleteHandler(props.id)
              }}
              style={{fontSize: "1.2rem"}}
            />
          </div>
        </div>
      </div>
    )
  }
  const onPageChange = (e) => {
    setCurrentPage(e)
  }
  const TitleRender = () => {
    return (
      <TextBox
        onValueChanged={onTitleChange}
        label={"Гарчиг"}
        defaultValue={editToggle ? data.news_title : ""}
      />
    )
  }
  const onChange = (e) => {
   yearRef.current = e.value
  };
  const MarkupRender = () => {
    return                     <div style={{display: 'flex', "alignItems": 'center', "columnGap": 10}}>
    <SelectBox
        items={yearList}
        label="Он"
        defaultValue={editToggle ? data.news_text : null}
        onValueChanged={onChange}
        placeholder="сонгох"/>
</div>
    
  }
  const onPhotoChange = (e) => {
    photoRef.current = e.value
  }
  const onFileChange = (e) => {
    fileRef.current = e.value
  }
  const PhotoUploader = () => {
    return (
      <div style={{}}>
        <FileUploader
          allowedFileExtensions={allowedPhotoExtensions}
          onValueChanged={onPhotoChange}
          selectButtonText="Нүүр зураг сонгох"
          labelText=""
          accept="image/*"
          uploadMode="useForm"
        />
        <span className="note">
          {"Зургийн өргөтгөл: "}
          <span>.jpg, .jpeg, .png</span>
        </span>
      </div>
    )
  }
  const FileUpload = () => {
    return (
      <div>
        <div style={{display: "flex", position: "relative", fontWeight: 700}}>
          <FileUploader
            allowedFileExtensions={allowedFileExtensions}
            onValueChanged={onFileChange}
            selectButtonText="‎‎ Файл"
            multiple={false}
            labelText=""
            accept="*"
            uploadMode="useForm"
          />{" "}
        </div>
        <div className="note" style={{marginTop: ".25rem"}}>
          {"Файлын өргөтгөл: "}
          <span>".xls", ".pdf", ".docx", ".pptx"</span>
        </div>
      </div>
    )
  }
  const photoSubmit = async (fileData, typeId, size) => {
    try {
      if (fileData.size >= size) {
        notify(
          `Оруулсан файлын хэмжээ  ${size / 1000000}-MB-аас их байна.`,
          "warning",
          3000
        )
        return 0
      }
      let data = new FormData()

      data.append("file", fileData)
      const res = await FileServices.uploadSingleFile(data, typeId)
      if (res.code === 200) {
        photoRef.current = null
        return res.data
      }
    } catch (error) {
      console.log(error)
      return notify("Хадгалах үйлдэл амжилтгүй боллоо (Зураг)", "warning", 3000)
    }
  }
  const fileSubmit = async (fileData, news_id, type) => {
    try {
      let data = new FormData()
      data.append("file", fileData)
      const res = await FileServices.uploadMultipleNewsFiles(
        data,
        news_id,
        type
      )
      if (res.code === 200) {
        fileRef.current = null
        return res.data
      }
    } catch (error) {
      console.log(error)
      return notify("Хадгалах үйлдэл амжилтгүй боллоо (Зураг)", "warning", 3000)
    }
  }
  const onSubmit = async () => {
    let data = {}
    data.news_title = titleRef.current
    
    data.org_id = orgId
    data.author = orgId
    data.is_private = 0
    data.is_opendata = 1
    data.news_type = 6
    data.youtube_url = ""
    data.news_text = yearRef.current;

    if (data.news_title == null) {
      data.news_title = ""
    }
    if (data.markup == null) {
      data.markup = ""
    }
    if (photoRef.current == null) {
      notify(`Зураг оруулна уу!`, "warning", 3000)
      return 0
    }
    try {
      setLoading(true)
      data.file_id = await photoSubmit(photoRef.current[0], 26, 30000000)
      let res = await newsServices.addNews(data)
      let id
      if (res && res.code && res.code === 200) {
        id = res.data.id
      }
      await fileSubmit(fileRef.current[0], id, 1)

      notify("Амжилттай хадгаллаа", "success", 3000)
      setLoading(false)
      setToggle(false)
      fetchData(orgId)
    } catch (error) {
      console.log(error)
      return notify("Хадгалах үйлдэл амжилтгүй боллоо", "warning", 3000)
    }
  }
  const onEdit = async () => {
    try {
      let news = {}
      if (titleRef.current !== null) {
        news.news_title = titleRef.current
      }
  
      if (yearRef.current != null) {
        news.news_text = yearRef.current
      }
      if (photoRef.current !== null && photoRef.current !== undefined) {
        news.file_id = await photoSubmit(photoRef.current[0], 26, 30000000)
      }
      if (fileRef.current !== null && fileRef.current !== undefined) {
        await fileSubmit(fileRef.current[0], data.id, 2)
      }

      const result = await newsServices.updateNewsById(news, data.id)
      setEditToggle(false)
      fetchData()
      return notify(result.message, "success", 4000)
    } catch (error) {
      console.log("error", error)
      return notify("Хадгалах үйлдэл амжилтгүй боллоо", "warning", 3000)
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <div className="card" style={{padding: "1rem"}}>
        <div className="">
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              padding: "0 1rem",
            }}
          >
            <span style={{fontWeight: 700}}>Байгууллага: </span>
            <OrganizationSelectComponent
              selectedData={(d) => {
                setOrgId(d.orgId[0])
              }}
              selectedYear={(d) => {}}
              showClearButton={false}
              hideLabel={true}
              defaultId={user.org_id}
              showYear={false}
            />
            <DevExtremeButton
              width={200}
              text="Бүтээл нэмэх"
              className="saveButton"
              type="default"
              onClick={() => {
                setToggle(true)
              }}
            />
          </div>
        </div>
        <StyledList
          hoverStateEnabled={false}
          focusStateEnabled={false}
          noDataText={
            orgId ? "Бүтээл оруулаагүй байна." : "Байгууллага сонгоогүй байна."
          }
          activeStateEnabled={false}
          dataSource={allNews}
          selectionMode="none"
          keyExpr="id"
          displayExpr="file_name"
          itemRender={ImageUploaderCard}
        ></StyledList>
      </div>
      <Pagination
        style={{float: "right", marginTop: "1rem"}}
        total={totalNumber}
        pageSize={pageSize}
        showSizeChanger={false}
        locale={{
          page: "Хуудас",
          jump_to: "Очих",
          prev_page: "Өмнөх хуудас",
          next_page: "Дараагийн хуудас",
        }}
        current={currentPage}
        onChange={onPageChange}
        onPageSizeChange={() => {}}
      />

      {toggle && (
        <Popup
          visible={toggle}
          onHiding={() => {
            setToggle(false)
            titleRef.current = null
            yearRef.current = null
            fileRef.current = null
            photoRef.current = null
          }}
          showCloseButton
          height="auto"
          showTitle
          title="Бүтээл нэмэх"
          width="65%"
          id="renderCreateMenu"
        >
          <ScrollView height="100%" width="100%">
            <div className="widget-container">
              <Form labelMode={"floating"} labelLocation={"left"}>
                <FormItem1 itemType={"group"} colCount={1} colSpan={2}>
                  <FormItem1
                    dataField="news_title"
                    render={TitleRender}
                    label={"Гарчиг"}
                  >
                    <Label text="Гарчиг" />
                    <RequiredRule message="Гарчиг оруулна уу!" />
                  </FormItem1>
                  <FormItem1 itemType={"group"} colCount={2} colSpan={2}>
                    <FormItem1 render={PhotoUploader}>
                      <Label text="Нүүр зураг" />
                      <RequiredRule message="Зураг оруулна уу!" />
                    </FormItem1>
                    <FormItem1 render={FileUpload}>
                      <Label text="Бүтээл файл" />
                      <RequiredRule message="Зураг оруулна уу!" />
                    </FormItem1>
                  </FormItem1>
                  <FormItem1
                    dataField="markup"
                    render={MarkupRender}
                    label={"Нийтлэл"}
                  >
                    <Label text="Нийтлэл" />
                    <RequiredRule message="Нийтлэл оруулна уу!" />
                  </FormItem1>
                </FormItem1>
              </Form>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginRight: 10,
                  marginBottom: 10,
                  marginTop: 10,
                }}
              >
                <DevExtremeButton
                  icon="plus"
                  text={"Хадгалах"}
                  type="default"
                  className="saveButton"
                  onClick={onSubmit}
                >
                  <span className="dx-button-text">
                    <LoadIndicator
                      style={{height: 20, width: 20}}
                      visible={loading}
                    />
                    {!loading && <span>Хадгалах</span>}
                  </span>
                </DevExtremeButton>
              </div>
            </div>
          </ScrollView>
        </Popup>
      )}
      {editToggle && (
        <Popup
          visible={editToggle}
          onHiding={() => {
            setEditToggle(false);
            titleRef.current = null
            yearRef.current = null
            fileRef.current = null
            photoRef.current = null
          }}
          hideOnOutsideClick
          showCloseButton
          height="auto"
          showTitle
          title="Бүтээл засах"
          width="65%"
          id="renderCreateMenu"
        >
          <ScrollView height="100%" width="100%">
            <div className="widget-container">
              <Form labelMode={"floating"} labelLocation={"left"}>
                <FormItem1 itemType={"group"} colCount={1} colSpan={2}>
                  <FormItem1
                    dataField="news_title"
                    render={TitleRender}
                    label={"Гарчиг"}
                  >
                    <Label text="Гарчиг" />
                    <RequiredRule message="Гарчиг оруулна уу!" />
                  </FormItem1>
                  <FormItem1 itemType={"group"} colCount={2} colSpan={2}>
                    <FormItem1 render={PhotoUploader}>
                      <Label text="Нүүр зураг" />
                      <RequiredRule message="Зураг оруулна уу!" />
                    </FormItem1>
                    <FormItem1 render={FileUpload}>
                      <Label text="Бүтээл файл" />
                      <RequiredRule message="Зураг оруулна уу!" />
                    </FormItem1>
                  </FormItem1>
                  <FormItem1
                    dataField="markup"
                    render={MarkupRender}
                    label={"Нийтлэл"}
                  >
                    <Label text="Нийтлэл" />
                    <RequiredRule message="Нийтлэл оруулна уу!" />
                  </FormItem1>
                </FormItem1>
              </Form>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginRight: 10,
                  marginBottom: 10,
                  marginTop: 10,
                }}
              >
                <DevExtremeButton
                  icon="plus"
                  text={"Хадгалах"}
                  type="default"
                  className="saveButton"
                  onClick={onEdit}
                >
                  <span className="dx-button-text">
                    <LoadIndicator
                      style={{height: 20, width: 20}}
                      visible={loading}
                    />
                    {!loading && <span>Хадгалах</span>}
                  </span>
                </DevExtremeButton>
              </div>
              <div
              style={{
                width: "100%",
                display: "flex",
                height: "100%",
                justifyContent: "flex-start",
                marginTop: "2rem",
                gap: "3.5rem",
              }}
            >
              <span>Бүтээлийн зураг:</span>{" "}
              <div style={{height: "8vw"}}>
                {data && data.front_profile_file_name && (
                  <ImageViewer
                    array={[data]}
                    filename="front_profile_file_name"
                    height="8vw"
                  />
                )}
              </div>
            </div>

            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-start",
                marginTop: "2rem",
                gap: "3.8rem",
              }}
            >
              <span>Хавсаргасан файл:</span>{" "}
                  <FileCard file_name={data.file_name} text={data.file_name}/>
            </div>
            </div>
          </ScrollView>
        </Popup>
      )}
    </>
  )
}

export default Publication

import React from 'react'
import './proposal.css'
import { AiOutlineRight } from 'react-icons/ai'
import { deadlineTooltip } from "..";
import { Tooltip } from 'devextreme-react';


const list = [
    { deadline: '7 сарын 09', title: 'ТТЗ-д хүргүүлэх', },
    { deadline: '7 сарын 20', title: 'ТЕЗ-д хүргүүлэх', },
    { deadline: '7 сарын 25', title: 'Сангийн яаманд хүргүүлэх', },
]

export const ProposalTimeline = () => {
    return (
        <div className='timeline' style={{fontFamily: 'Segoe UI'}}>
            <div className='timeline-container'>
                {
                    list.map((e, i) => (
                        <div className={`timeline-stepcard`} id={`stepcard-${i}`}>
                            <div className='timeline-step-deadline'>{e.deadline}</div>
                            <div className='timeline-step-title'>{e.title}</div>
                            <div className='dot-border'>
                                <span className='timeline-dot'><AiOutlineRight style={{ fontSize: '1.5rem', color: 'white' }} /></span>
                            </div>
                        </div>
                    ))
                }
            </div>
            <hr className='timeline-hr-line' />
            {Object.values(deadlineTooltip[0]).reverse().map((e, i) => (
                <Tooltip
                    target={`#stepcard-${i}`}
                    showEvent="mouseenter"
                    hideEvent="mouseleave"
                    hideOnOutsideClick={false}
                    position="bottom"
                    width={'45rem'}
                    contentRender={() => (
                        <div style={{ padding: "1rem", backgroundColor: '#555', color: '#fff', borderRadius: 10, fontSize: '.875rem', whiteSpace: 'break-spaces', fontFamily: 'Segoe UI' }}>
                            {e}
                        </div>)}
                />
            ))}
        </div>
    )
}

import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import DataGrid, { Column, Selection } from "devextreme-react/data-grid";
import { useParams } from "react-router-dom";
import Button from "devextreme-react/button";
import ScheduleService from "../../../services/api/scheduleService";
import notify from "devextreme/ui/notify";
import ScrollView from "devextreme-react/scroll-view";
import { UserContext } from "../../../hooks/UserContext";
import { numberWithCommas } from "../../../util";
import { Popup } from "devextreme-react";
import RequestInformation from "./RequestInformation"
import notificationUserService from "../../../services/api/notificationUser";
import moment from "moment";
import Joyride from 'react-joyride';
import { SubOrgTabSteps, guideLocale } from "../../../components/SystemGuideSteps";
import spinner from '../../../assets/imgs/tube-spinner.svg'

const ScheduleSubOrgRequests = (props) => {
    const { user } = useContext(UserContext);
    const { reqId } = useParams();
    const [requests, setRequests] = useState(null);
    const [popupState, setPopupState] = useState(false);
    const [popRequest, setPopRequest] = useState(null);
    const [actionPopup, setActionPopup] = useState(false);
    const [logComment, setLogComment] = useState(null)
    const request = useRef(null);
    const [currentAction, setCurrentAction] = useState(null);

    useEffect(() => {
        getList()
    }, [])

    const getList = async () => {
        try {
            let res = await ScheduleService.requestList('undefined', moment(props?.createdAt).startOf('month').format('YYYY-MM-DD'), moment(props?.createdAt).date(25).format('YYYY-MM-DD'), reqId, 'new');
            setRequests(res);
        } catch (e) {
            console.log(e);
            notify(e.response.data.message, 'error', 5000);
        }
    }

    const requestClickHandler = async (reqCode, reqId) => {
        setPopupState(true)
        let req = requests.filter(e => e.request_code === reqCode)
        req && setPopRequest(req[0])
    }

    const requestRender = ({ value, data }) => {
        return <b onClick={() => requestClickHandler(value, data.id)} style={{ color: "blue", textDecorationLine: 'underline', cursor: 'pointer' }}>{value}</b>
    }

    const actionRender = ({ data }) => {
        return (
            <div style={{ display: 'flex' }}>
                <div>
                    <Button
                        style={{ border: 'none' }}
                        hint="Дэлгэрэнгүй"
                        disabled={![7, 8].includes(data.status)}
                        onClick={() => requestClickHandler(data.request_code, data.id)}
                        icon='detailslayout'
                        stylingMode='outlined'
                        type="normal"
                    />
                </div>
                <div>
                    <Button
                        style={{ border: 'none' }}
                        hint="Нэгтгэх"
                        disabled={![7, 8].includes(data.status) || (user.organization.obt_id === 1 && (moment().format('YYYY-MM-DD') < moment().date(25).format('YYYY-MM-DD'))) }
                        onClick={() => {
                            request.current = data
                            setCurrentAction('join')
                            setActionPopup(true)
                        }}
                        icon="check"
                        stylingMode='outlined'
                        type="success"
                    />
                </div>
                <div>
                    <Button
                        style={{ border: 'none' }}
                        className="deny"
                        hint="Цуцлах"
                        disabled={![7, 8].includes(data.status)}
                        onClick={() => {
                            request.current = data
                            setCurrentAction('cancel')
                            setActionPopup(true)
                        }}
                        icon="close"
                        stylingMode='outlined'
                        type="danger"
                    />
                </div>
            </div>
        )
    }

    const pushNotif = useCallback(async (status) => {
        await notificationUserService.addScheduleRequestNotification({
            requestId: request.current.id,
            createdAt: request.current.createdAt,
            status: status,
            isOwn: false,
        })
            .then((res) => {
                console.log('notification service', res)
            })
            .catch((err) => {
                console.log('notification service err', err)
            })

    }, [])

    const updateStatus = useCallback(async () => {
        const cancelNextStatus = {
            7: 14,
            8: 15,
        }
        const joinNextStatus = {
            7: 10,
            8: 11,
        }
        let nextStatus = ''
        let description = ''
        let type = ''
        if (currentAction === 'join') {
            nextStatus = joinNextStatus[request.current.status];
            description = 'нэгтгэлээ';
            type = 'success';
        } else {
            nextStatus = cancelNextStatus[request.current.status];
            description = 'цуцаллаа';
            type = 'info';
        }
        const req = {
            "request_id": request.current.id,
            "current_status": request.current.status,
            "change_status": nextStatus,
            "comment": logComment,
            "parentIds": user.sub_request_ids ?? [],
        }
        await ScheduleService.setRequestNote(request.current.id, { request_note: logComment })
        await ScheduleService.changeStatus(req)
            .then((res) => {
                notify({ message: `${request?.current?.request_code} - дугаартай хуваарийн өөрчлөлтийн хүсэлтийг ${description}`, width: 'fit-content' }, type, 5000)
                pushNotif(nextStatus)
            })
            .catch((e) => {
                console.log("err->", e)
            })
            .finally(() => {
                setLogComment(null)
                setActionPopup(false)
                getList()
            })
    }, [currentAction, logComment, user.sub_request_ids, pushNotif])


    const onCellPreparedHandler = (e) => {
        e.cellElement.style.verticalAlign = 'middle'
        if (e.rowType === "header") {
            e.cellElement.style.fontWeight = "bold"
            e.cellElement.style.color = "#15186A"
            e.cellElement.style.backgroundColor = "#EEF0F4"
        } else if (e.rowType === "data") {
            e.cellElement.style.fontWeight = "semibold"
            e.cellElement.style.color = "#000000"
        }
    }

    return (
        <div>
            <Joyride
                run={true}
                steps={SubOrgTabSteps()}
                continuous
                showSkipButton
                styles={{
                    options: {
                        zIndex: 100,
                        display: 'none',
                    }
                }}
                locale={guideLocale}
                disableScrolling={true}
            />
            <Popup
                height={'auto'}
                width={600}
                visible={actionPopup}
                title={'Баталгаажуулалт'}
                onHiding={() => setActionPopup(false)}
                resizeEnabled={true}
                dragEnabled={true}
                showTitle={true}>
                <div style={{ display: 'flex', flexDirection: 'column', gap: 20 }}>
                    <div>
                        <i>{request?.current?.request_code} {currentAction === "join" ? ' - дугаартай хуваарийн өөрчлөлтийг хүсэлтийг нэгтгэх үү?' : '- дугаартай хуваарийн өөрчлөлтийн хүсэлтийг цуцлах уу?'}</i>
                    </div>
                    <textarea type="text" onChange={(e) => setLogComment(e.target.value)} required={true}
                        placeholder="Үйлдлийн тайлбар..."
                        value={logComment}
                        style={{ borderRadius: 4, borderColor: '#ddd', padding: 5, height: 120, outline: 'none' }}
                    />
                    <div style={{ display: 'flex', justifyContent: 'center', gap: 10 }}>
                        <Button type='default' width={98} onClick={() => logComment ? updateStatus() : notify({ message: 'Үйлдлийн тайлбар оруулна уу!', width: 'fit-content' }, 'warning', 5000)} text='Тийм' />
                        <Button onClick={() => setActionPopup(false)} text="Үгүй" width={98} />
                    </div>
                </div>
            </Popup>
            <ScrollView height='100%' >
                <div style={{ padding: '1rem' }}>
                    <DataGrid dataSource={requests}
                        loadPanel={{ enabled: true, showPane: false, text: '', indicatorSrc: spinner }}
                        noDataText='Дата байхгүй байна.'
                        showBorders={true}
                        rowAlternationEnabled={true}
                        showRowLines={true}
                        showColumnLines={true}
                        showColumnHeaders={true}
                        allowColumnResizing={true}
                        columnAutoWidth={true}
                        wordWrapEnabled={true}
                        onCellPrepared={onCellPreparedHandler}
                    >
                        <Selection mode="single" />
                        <Column dataField="request_code" alignment="center" width='6rem' caption="Хүсэлтийн дугаар" cellRender={requestRender} />
                        <Column dataField="request_org_name" alignment="left" caption="Хүсэлт гаргасан байгууллага" />
                        <Column dataField="createdAt" alignment="left" width='7rem' caption="Үүссэн огноо" dataType="date" format="yyyy-MM-dd HH:mm" />
                        <Column dataField="updatedAt" alignment="left" width='7rem' caption="Шинэчлэгдсэн огноо" dataType="date" format="yyyy-MM-dd HH:mm" />
                        <Column dataField="status_array" alignment="left" caption="Хүсэлтийн төлөв" width='10rem' cellRender={(e) => <div className={`schedule-transfer-status-${e.data.status}`}>{e.displayValue[0]}</div>} />
                        <Column dataField="tran_group" alignment="left" caption="Шилжүүлгийн төрөл" />
                        <Column dataField="tran_amount" alignment="left" caption="Хүсэлтийн дүн" cellRender={(e) => { return numberWithCommas(e.text) }} />
                        <Column dataField="request_note" width="30em" alignment="left" caption="Нэмэлт тайлбар" />
                        <Column alignment="center" caption="Үйлдэл" cssClass="subOrgReqActions" cellRender={actionRender} />
                    </DataGrid>
                </div>
            </ScrollView>
            {(user.organization.obt_id === 1 && (moment().format('YYYY-MM-DD') < moment().date(25).format('YYYY-MM-DD'))) && <div style={{ textAlign: 'end', padding: 20 }}>Тухайн сарын 25 ны өдрөөс эхлэн хуваарийн өөрчлөлтийн хүсэлтийг нэгтгэнэ үү.</div>}
            {popupState &&
                <Popup
                    visible={popupState}
                    title={`${popRequest?.request_code} дугаартай хүсэлтийн мэдээлэл`}
                    animation={{
                        show: {
                            type: 'pop',
                            duration: 1,
                            from: {
                                scale: 1
                            }
                        },
                        hide: {
                            type: 'pop',
                            duration: 1,
                            to: {
                                opacity: 1,
                                scale: 1
                            },
                            from: {
                                opacity: 1,
                                scale: 1
                            }
                        }
                    }}
                    showTitle={true}
                    height={'90vh'}
                    width={'80vw'}
                    position={'center'}
                    deferRendering={false}
                    onHiding={() => setPopupState(false)}>
                    <ScrollView>
                        <div style={{ padding: 20 }}>
                            <RequestInformation popRequest={popRequest} popup={true} />
                        </div>
                    </ScrollView>
                </Popup>}
        </div>
    )
}

export default ScheduleSubOrgRequests
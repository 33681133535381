import React from 'react';
import {Chart, CommonSeriesSettings, Label, Legend, Series, Connector} from "devextreme-react/chart";
import {nFormatter} from "../../../util";

function barChart(props) {
    const greeting = 'Hello Function Component!';

    function labelFormat(pointInfo) {
        let amount;
        if (pointInfo < 0) {
            amount = '-'+nFormatter(pointInfo*-1, 1)
        } else {
            amount = nFormatter(pointInfo, 1);
        }
        return amount
    }

    return <>
        <Chart id="chart"
               rotated={props.rotate}
               palette = 'Soft Pastel'
               style = {{height: '100%', width: '100%'}}
               dataSource = {props.data}
               ignoreEmptyPoints={true}
        >
            <CommonSeriesSettings
                argumentField="name"
                type = 'bar'
                barWidth = {20}
                minBarSize = {10}
            />

            <Series
                name="Гүйцэтгэл"
                valueField="amount_performance"
                color="#dcc6e0"
            >
                <Label
                    visible={true}
                    font={{
                        size: 10
                    }}
                    format={labelFormat}
                >
                    <Connector visible={true} />
                </Label>
            </Series>

            <Series
                name="Батлагдсан"
                valueField="amount"
                color={props.color}
            >
                <Label
                    visible={true}
                    font={{
                        size: 10
                    }}
                    format={labelFormat}
                >
                    <Connector visible={true} />
                </Label>
            </Series>

            {/*<Tooltip*/}
            {/*    enabled={true}*/}
            {/*    location = 'edge'*/}
            {/*    customizeTooltip = {customize}*/}
            {/*/>*/}

            <Legend visible = {false}/>
        </Chart>
    </>;
}

export default barChart;
import React, {useState, useEffect} from "react";
import notify from "devextreme/ui/notify";
import lawServices from "../../../services/api/law";
import DataGrid, {Column, FilterRow, Paging, Selection} from "devextreme-react/data-grid";
import {Button} from "devextreme-react/button";
import _ from "lodash";
import GeneralInfoService from "../../../services/api/generalInfo";
import {custom} from "devextreme/ui/dialog";

const LawLinker = (props) => {
    const [lawData, setLawData] = useState([]);
    const [currentLaw, setCurrentLaw] = useState({});
    const [prevLawId, setPrevLawId] = useState(null);
    const [triggerRefresh, setTriggerRefresh] = useState(false);
    const [multiFactorId, setMultiFactorId] = useState(null);

    const loadLawData = async () => {
        try {
            let r = await lawServices.getLawsByStartOfAncestry();
            setLawData(r.data);
            if (props.generalData.id) {
                let m = await GeneralInfoService.getLinkedLaws(props.generalData.id);
                if (m.length !== 0) {
                    setCurrentLaw(m[0].law);
                    setPrevLawId(m[0].table_id);
                    setMultiFactorId(m[0].id);
                } else {
                    setCurrentLaw(null);
                    setPrevLawId(null);
                    setMultiFactorId(null);
                }
            }
        } catch (e) {
            notify(e.message, "error", 2000);
        }
    }

    useEffect(() => {
        loadLawData();
    }, [props.generalData.id, triggerRefresh]);

    const selectionHandler = (e) => {
        setCurrentLaw(e.selectedRowsData[0]);
    }

    const linkLaw = async () => {
        let obj = { generalId: props.generalData.id, linkTableName: 'law', linkTableId: currentLaw.id, prevLawId: prevLawId }
        try {
            let result = custom({
                title: "Баталгаажуулалт",
                messageHtml: `<span>${prevLawId ? 'Өмнөх мэдээллийг шинэчлэх гэж байна.' : 'Оруулах'}</span>`,
                buttons: [
                    {
                        text: "Тийм", onClick: async () => {
                            await GeneralInfoService.linkDataOnGeneralInfo(obj);
                            notify("Амжилттай", "success", 2000);
                            setTriggerRefresh(!triggerRefresh);
                        }
                    },
                    {
                        text: "Үгүй", onClick: () => {
                            return false;
                        }
                    }
                ]
            });
            await result.show();
        } catch (e) {
            notify(e.message, "error", 2000);
        }
    }

    const lawCloseHandler = () => {
        if (typeof props.closeLaw === 'function') {
            props.closeLaw();
        }
    }

    const handleDelete = async () => {
        if (typeof props.deleteLinkedData === 'function') {
            await props.deleteLinkedData(multiFactorId);
            setTriggerRefresh(!triggerRefresh);
        }
    }

    return (
        <div>
            <div className="card">
                <div className="card-body" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <b>Хууль оруулах</b>
                        <span>Сонгосон мэдээлэл: {props.generalData.val}</span>
                    </div>
                    <Button text="Хаах" icon="close" type="danger" onClick={lawCloseHandler}/>
                </div>
            </div>
            {currentLaw && !_.isEmpty(currentLaw) && <div className="card">
                <div className="card-body" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <span>Сонгосон хууль: {currentLaw.law_doc_type_name}&nbsp;{currentLaw.name}&nbsp;<a href={currentLaw.legal_url} target="_blank">{currentLaw.legal_url}</a></span>
                    <div>
                        <Button text="Холбох" icon="link" type="success" onClick={linkLaw}/>
                        {multiFactorId && <Button type="danger" onClick={handleDelete}>
                            <span style={{ alignItems: 'center', justifyContent: 'center', verticalAlign: 'middle', display: 'inline-block' }} className="dx-button-text">Устгах</span>
                        </Button>}
                    </div>
                </div>
            </div>}
            <div className="card">
                <div className="card-body">
                    <DataGrid
                        dataSource={lawData}
                        hoverStateEnabled={true}
                        onSelectionChanged={selectionHandler}
                        // onRowClick={rowClickHandler}
                        keyExpr="id"
                    >
                        <Selection mode="single"/>
                        <FilterRow visible={true}/>
                        <Paging
                            pageSize={10}
                        />
                        <Column dataField="law_doc_type_name" caption="Хуулийн төрөл"/>
                        <Column dataField="name" caption="Хуулийн нэр"/>
                        <Column dataField="legal_url" caption="Хуулийн холбоос"/>
                    </DataGrid>
                </div>
            </div>
        </div>
    )
}

export default LawLinker;

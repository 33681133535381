import React from "react";
import Chart, {
  ArgumentAxis,
  CommonSeriesSettings,
  Label,
  Legend,
  Series,
  Tooltip,
  ValueAxis,
  CommonAnnotationSettings,
  Annotation,
  Point,
  LoadingIndicator,
} from "devextreme-react/chart";

const YourChartComponent = (props) => {

  return (
    <Chart dataSource={props.currData}>
      <LoadingIndicator enabled={true} />
      <CommonSeriesSettings argumentField="month" />
      <ValueAxis allowDecimals={false} valueType="numeric">
        <Label format="#" />
      </ValueAxis>
      <Series valueField="count" type="bar" name="2024" />
      <Series valueField="count" type="spline" color="red" />
      <Legend visible={false} />
      <Tooltip enabled={true}
        zIndex={100000}
        location="edge"
        font={{ color: '#fff' }} color="#555"
        cornerRadius={6} 
        border={{visible: false}}
      />
    </Chart>
  );
};

export default YourChartComponent;
import React, {useContext, useEffect, useState, useRef} from 'react';
import 'devextreme-react/text-area';
import ScrollView from 'devextreme-react/scroll-view';

import DataGrid, {
    Editing,
    Pager,
    Paging,
    Column,
    FilterRow,
    RequiredRule,
    Export, Form, Item, Scrolling,  GroupPanel, SearchPanel, Grouping, Summary, GroupItem, TotalItem
} from 'devextreme-react/data-grid';
import {Workbook} from 'exceljs';
import {Popup} from "devextreme-react/popup";
import {saveAs} from 'file-saver-es';
import {exportDataGrid} from 'devextreme/excel_exporter';
import {LoadPanel} from "devextreme-react/load-panel";
import {UserContext} from "../../../../hooks/UserContext";
import fcsService from "../../../../services/api/fcs";
import Button from "devextreme-react/button";
import notify from "devextreme/ui/notify";
import {Template} from "devextreme-react";
import BB02 from "../../BankBook/report/BB02";
import Fa01 from "../report/Fa01";
import Fa02 from "../report/fa02";
import ReactToPrint from "react-to-print";
import ScheduleService from "../../../../services/api/scheduleService";
import ScheduleEditReqDetail from "../../../plan/components/ScheduleEditReqDetail";
import {Selection} from "devextreme-react/tree-list";
import Fa04 from "../report/fa04";
import LoadPanelComponent from '../../../../components/LoadPanelComponent';

function FaOrEdit(props) {

    const yearValue = props.yearValue || -1;
    const monthValue = props.monthValue || -1;
    const countFaOr = props.countFaOr

    const userListPosition = {of: '#usersList'}
    const {user} = useContext(UserContext);
    const [dataSource, setDataSource] = useState([]);
    const [loader, setLoader] = useState(false);
    const myBabyRef = useRef(null);
    const [reportData,setReportData]=useState()
    const [reportShow,setReportShow]=useState(false)

    useEffect(() => {
        fcsList();
    }, [yearValue, monthValue, countFaOr])

    const componentRef = useRef();

    const fcsList = async () => {
        try {
            setLoader(true);
            const result = await fcsService.getFcsFaOrReport(yearValue, monthValue);
            setDataSource(result)
            return setLoader(false)
        } catch (e) {
            console.error(e);
        }
    }
    const diff = (rowData) => {
        return rowData.quantity * rowData.cost_org;
    }
    const customizeDate = (data) => {
        return Number(parseFloat(data.value).toFixed(2)).toLocaleString("en", {
            minimumFractionDigits: 2,
        });
    }
    const setEffect = async (e) => {
        if (e.changes && e.changes.length !== 0) {


            if (e.changes[0].type === "update") {
                try {
                    e.changes[0].data.updated_user_id = user.id;
                    await fcsService.editFcsMonth(e.changes[0].data);
                    await fcsList();
                    notify('Амжилттай хадгалагдлаа', "success", 2000);
                } catch (e) {
                    notify('Засварлалтыг хадгалах явцад алдаа гарлаа', "error", 2000);
                }
            } else if (e.changes[0].type === "insert") {
                try {
                    delete e.changes[0].data.id;
                    e.changes[0].data.created_user_id = user.id;
                    e.changes[0].data.org_id = user.org_id;
                    await fcsService.addFcsMonth(e.changes[0].data);
                    await fcsList();
                    notify('Амжилттай үүслээ', "success", 2000);
                } catch (e) {
                    notify('Шинээр үүсэх явцад алдаа гарлаа', "error", 2000);
                }

            } else if (e.changes[0].type === "remove") {
                try {
                    await fcsService.removeFcsFaOr(e.changes[0].key.id);
                    await fcsList();
                    notify('Амжилттай устгагдлаа', "success", 2000);
                } catch (e) {
                    notify('Устгах явцад алдаа гарлаа', "error", 2000);
                }

            }

        }

    }
    const onEditorPreparing = (e) => {
        if (e.parentType === "dataRow" && e.dataField !== "password") {
            return;
        }

        if (e.row && e.row.isNewRow) {
            e.editorOptions.visible = true;
        }
        if (e.row && !e.row.isNewRow) {
            e.editorOptions.visible = false;
        }
    }

    const onExporting = (e) => {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Main sheet');

        exportDataGrid({
            component: e.component,
            worksheet,
            autoFilterEnabled: true,
        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer], {type: 'application/octet-stream'}), 'FaOr' + yearValue + monthValue + '.xlsx');
            });
        });
        e.cancel = true;
    }
    const scheduleTransferDetailBtn = (options) => {
        return (
           <Button
                    type="default"
                    icon='save'
                    className="saveButton" onClick={()=>printFa01(options.data) }>Харах</Button>
        )
    }
const printFa01=(data)   =>{

    setReportShow(true)
    if (data)

        setReportData(data)
    setReportShow(true)

}


    const style = {
        text: {
            color: "#2E4765", fontWeight: "700", fontSize: ".875rem"
        },
        box: {
            border: "none", backgroundColor: "#F6F6F6", borderRadius: ".5rem", padding: ".25rem .15rem", width: "100%"
        }
    }
    return (
        <div style={{padding: ".5rem"}}>
            <LoadPanelComponent
                shadingColor="rgba(0,0,0,0.4)"
                position={{of: '#password'}}
                showPane={false}
                message=''
            />
            <div className="col-12 row" style={{justifyContent: "space-between", gap: '2rem', padding: '5px 15px'}}>
                <span style={{...style.text}}>
                    Нэмэгдсэн үндсэн хөрөнгө
                </span>

            </div>
            <LoadPanelComponent
                shadingColor="rgba(0,0,0,0.4)"
                position={userListPosition}
                showPane={false}
                visible={loader}
                message=''
            />
            <Popup
                width={500}
                height={800}
                minWidth={'80%'}
                maxWidth={'100%'}
                minHeight={'100%'}
                onHiding={() => setReportShow(false)}
                resizeEnabled={true}
                dragEnabled={true}
                showTitle={true}
                hideOnOutsideClick={true}
                visible={reportShow}

                showCloseButton={true}

                title="дэлгэрэнгүй" >
                <ScrollView width='100%' height='100%'>
                <div>


                    {reportData &&

                    <div>

                        {(reportData.trtype==0  || reportData.trtype==4) &&
                            <Fa01 setReportShow={setReportShow} reportData={reportData}  orgName={reportData.organization_model.name} />


                        }



                        {(reportData.trtype==1 || reportData.trtype==2 || reportData.trtype==3  )   &&
                            <Fa02 setReportShow={setReportShow} reportData={reportData}  orgName={reportData.organization_model.name} />


                        }


                    </div>


                        }



                </div>
                </ScrollView>
            </Popup>



                    <DataGrid
                        columnAutoWidth={true}
                        onEditorPreparing={onEditorPreparing}
                        ref={myBabyRef}
                        dataSource={dataSource}
                        onSaved={setEffect}
                        keyExpr={null}
                        allowColumnResizing={true}
                        wordWrapEnabled={true}
                        onExporting={onExporting}>

                        >
                        <Scrolling columnRenderingMode="virtual" />
                        <Paging enabled={false} />
                        <GroupPanel visible={true}/>
                        <SearchPanel visible={true}/>
                        <Selection mode="single"/>
                        <Grouping autoExpandAll={true}/>
                        <FilterRow
                            visible={true}
                        />
                        <Pager
                            showPageSizeSelector={true}
                            // showInfo={true}
                            showNavigationButtons={true}
                        />


                        <Column
                            cssClass="custom"
                            dataField="docnum"
                            caption="Падаан №"
                            //      groupIndex={0}
                            allowEditing={true}
                            allowFiltering={true}
                        >

                        </Column>


                        <Column
                            cssClass="custom"
                            dataField="trdescr"
                            caption="гүйлгээний төрөл"

                            allowEditing={true}
                            allowFiltering={true}
                            showInGroupFooter={true}
                        >

                        </Column>

                        <Column
                            cssClass="custom"
                            dataField="trtype"
                            caption="гүйлгээний код"
                            groupIndex={0}
                            allowEditing={true}
                            allowFiltering={true}
                            showInGroupFooter={true}
                        >

                        </Column>



                        <Column
                            cssClass="custom"
                            dataField="descr"
                            caption="Гүйлгээний утга"
                            minWidth={130}
                            allowEditing={true}
                            allowFiltering={true}
                        >

                        </Column>
                        <Column
                            cssClass="custom"
                            dataField="docdate"
                            caption="Огноо"
                            width={80}
                            alignment="center"
                            allowEditing={true}
                            allowFiltering={true}
                        >

                        </Column>


                        <Column
                            cssClass="custom"
                            dataField="date_acq"
                            dataType="date"
                            caption="Ашиглалтанд авсан"
                            allowEditing={true}
                            allowFiltering={true}
                        >

                        </Column>
                        <Column
                            cssClass="custom"
                            dataField="location"
                            caption="Байршил"
                            allowEditing={true}
                            allowFiltering={true}
                        >

                        </Column>


                        <Column
                            cssClass="custom"
                            dataField="manuf"
                            caption="Үйлдвэрлэгч"
                            allowEditing={true}
                            allowFiltering={true}
                        >

                        </Column>


                        <Column
                            cssClass="custom"
                            dataField="fa_name"
                            caption="Хөрөнгийн нэр"
                            width={80}
                            alignment="center"
                            allowEditing={true}

                        >

                        </Column>
                        <Column
                            cssClass="custom"
                            dataField="fanumber"
                            caption="Дугаар"
                            width={80}
                            alignment="center"
                            allowEditing={true}
                            allowFiltering={true}
                        >

                        </Column>

                        <Column
                            cssClass="custom"
                            dataField="unit_meas"
                            width={60}
                            alignment="center"
                            caption="Х/н"
                            allowFiltering={true}
                        >
                        </Column>
                        <Column
                            cssClass="custom"
                            dataField="quantity"
                            width={50}
                            alignment="center"
                            caption="Тоо"

                            allowFiltering={true}
                        >
                        </Column>


                        <Column
                            cssClass="custom"
                            dataField="cost_org"
                            width={60}
                            alignment="center"
                            caption="Нэгж үнэ"
                            format="#,##0.00"
                            allowFiltering={true}
                        >
                        </Column>
                        <Column name={"tot"} calculateDisplayValue={diff}
                                caption="Нийт үнэ" width={60}
                                alignment="center"
                                format="#,##0.00"
                                calculateCellValue={diff}
                        />


                        <Column
                            cssClass="custom"
                            dataField="cost_cap"
                            width={80}
                            alignment="center"
                            caption="Нэмэгдүүлэх үнэ"
                            allowFiltering={true}
                        >
                        </Column>

                        <Column
                            cssClass="custom"
                            width={80}
                            alignment="center"
                            dataField="acct_code"
                            caption="Хөрөнгийн данс"
                            allowFiltering={true}
                        >
                        </Column>
                        <Column
                            cssClass="custom"
                            dataField="acder_amt"
                            width={80}
                            alignment="center"
                            caption="Элэгдэл"
                            allowFiltering={true}
                        >
                        </Column>

                        <Column
                            cssClass="custom"
                            dataField="acder_acct"
                            caption="Элэгдлийн данс"
                            minWidth={80}
                            alignment="center"
                            allowFiltering={true}
                        >
                        </Column>
                        <Column
                            cssClass="custom"
                            dataField="custname"
                            caption="Бэлтгэн нийлүүлэгч"
                            width={80}
                            alignment="center"
                            allowFiltering={true}
                        >
                        </Column>

                        <Column
                            cssClass="custom"
                            dataField="cust"
                            width={60}
                            alignment="center"
                            caption="Код"
                            allowFiltering={true}
                        >
                        </Column>

                        <Column
                            cssClass="custom"
                            dataField="cr_acct"
                            minWidth={80}
                            alignment="center"
                            caption="Кредит данс"
                            allowFiltering={true}
                        >
                        </Column>
                        <Column
                            cssClass="custom"
                            dataField="user_dept"
                            minWidth={80}
                            alignment="center"
                            caption="алба хэлтэс тасаг"
                            allowFiltering={true}
                        >
                        </Column>

                        <Column
                            cssClass="custom"
                            dataField="typename"
                            minWidth={80}
                            alignment="center"
                            caption="төрөл "
                            allowFiltering={true}
                        >
                        </Column>

                        <Column
                            cssClass="custom"
                            dataField="fatype"
                            minWidth={80}
                            alignment="center"
                            caption="төрөл код"
                            allowFiltering={true}
                        >
                        </Column>





                        <Column minWidth={90} alignment="center" caption="" cellTemplate="cc156"/>
                        <Template name="cc156" render={scheduleTransferDetailBtn}/>

                        <Editing
                            mode="row"
                            allowDeleting={countFaOr < 9998}

                            useIcons={true}
                            texts={{
                                cancelAllChanges: 'Болих бүгд',
                                cancelRowChanges: 'Болих',
                                confirmDeleteMessage: 'Энэ журнал мэдээллийг устгах уу?',
                                confirmDeleteTitle: 'Баталгаажуулах',
                                deleteRow: 'Устгах',
                                editRow: 'Өөрчлөх',
                                saveAllChanges: 'Хадгалах бүгд',
                                saveRowChanges: 'Хадгалах',
                                undeleteRow: 'Буцаах',
                                validationCancelChanges: 'Баталгаажуулах болих',
                            }}
                        >
                        </Editing>


                        <Summary>
                            <GroupItem
                                column="tot"
                                summaryType="sum"
                                customizeText={customizeDate}
                                showInGroupFooter={true}/>
                            <GroupItem
                                column="cost_cap"
                                summaryType="sum"
                                customizeText={customizeDate}
                                showInGroupFooter={true}/>
                            <GroupItem
                                column="acder_amt"
                                summaryType="sum"
                                customizeText={customizeDate}
                                showInGroupFooter={true}/>
                            <TotalItem
                                column="acder_amt"
                                summaryType="sum"
                                customizeText={customizeDate}/>
                            <TotalItem
                                column="tot"
                                summaryType="sum"
                                customizeText={customizeDate}/>
                            <TotalItem
                                column="cost_cap"
                                summaryType="sum"
                                customizeText={customizeDate}/>
                        </Summary>

                        <Paging enabled={true}/>


                        <Export enabled={true} allowExportSelectedData={false}/>
                    </DataGrid>






        </div>
    )
}

export default FaOrEdit;

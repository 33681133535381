export const formTemplate = [
  ];

export const formElements = [
  {id: 1, text: 'Input', type: 'input', label: 'Гарчиг', field: 'талбар'},
  {id: 2, text: 'Textarea', type: 'textarea', label: 'Гарчиг', field: 'талбар'},
  {id: 3, text: 'Date', type: 'date', label: 'Гарчиг', field: 'талбар'},
  {id: 4, text: 'Datetime', type: 'datetime', label: 'Гарчиг', field: 'талбар'},
  {id: 5, text: 'Time', type: 'time', label: 'Гарчиг', field: 'талбар'},
  {id: 6, text: 'Select', type: 'select', label: 'Гарчиг', field: 'талбар'},
  {id: 7, text: 'Radio', type: 'radio', label: 'Гарчиг', field: 'талбар'},
  {id: 8, text: 'Table', type: 'table', label: 'Гарчиг', field: 'талбар'},
  {id: 9, text: 'File', type: 'file', label: 'Гарчиг', field: 'талбар'},
];
export const optionsColumns = [
  'Name', 'Value'
];
export const optionShowElemnts = [
  'select', 'radio', 'table',
];
export const orgWorkerFormTypes = [
  {id: 1, name: 'Ажлын анкет'},
  {id: 2, name: 'Санал асуулга'},
  {id: 3, name: 'Албан тушаалын тодорхойлолт'},
];

import React, {useEffect, useState, useRef, useContext, useCallback} from "react";
import _ from "lodash";
import SelectBox from "devextreme-react/select-box";
import RefService from "../../../services/api/refs";
import {
    TreeList,
    Editing,
    Column,
    Scrolling, HeaderFilter, SearchPanel, Button as TreeButton, Toolbar, Item, ColumnFixing, CustomRule
} from "devextreme-react/tree-list";
import ProposalService from "../../../services/api/proposal";
import {
    projectStatusIdEditAllowed,
    helperGroupByKey,
    budgetProjectStatusEnum,
} from "../static/data";
import {Button} from "devextreme-react/button";
import notify from "devextreme/ui/notify";
import {UserContext} from "../../../hooks/UserContext";
import {
    list_to_tree,
    numberWithCommas,
    subtotal_tree,
    sortingText,
    columnFixingText,
    numberWithCommas11
} from "../../../util";
import {Popup} from "devextreme-react/popup";
import {
    PROPOSAL_FORMULA,
    PROPOSAL_FORMULA_FIELDS,
} from "../../../shared/constants";
import moment from "moment";
import {custom} from "devextreme/ui/dialog";
import DataGrid, {LoadPanel, Selection} from "devextreme-react/data-grid";
import LoadPanelComponent from "../../budget/components/loadPanel";
import {ScrollView, Tooltip} from "devextreme-react";
import {
    CgArrowsExpandRight,
    CgCalculator,
    CgCompressRight,
    CgMaximize,
    CgMinimize,
    CgBorderAll,
    CgCheckR
} from "react-icons/cg";
import ChartDetailByYear from "./ChartDetailByYear";
import {TextArea} from "devextreme-react";
import {urlServer} from "../../../shared/lib/request";
import loader from '../../../assets/imgs/loader.gif'
import OrgDutyAccordion from "../../../components/accordion/orgDuty"
import OrganizationService from "../../../services/api/organization";
import BudgetCalculationService from "../../../services/api/budgetCalculation";
import {MdDeleteForever, MdFileDownload} from "react-icons/md";
import BudgetCalcDataByEcon from "../../budgetCalculation/budgetCalcDataByEcon";

const animation = {
    show: {
        type: "slide",
        from: {
            left: 1000,
            opacity: 0.2,
        },
        to: {
            opacity: 1,
        },
        duration: 500,
    },
    hide: {
        type: "slide",
        to: {
            opacity: 0,
            right: -1000,
        },
        duration: 500,
    },
}

export default function ProposalCreate(props) {
    const {user} = useContext(UserContext);
    const economicCategoryArray = useRef([]);
    const [agendaList, setAgendaList] = useState([]);
    const [eventList, setEventList] = useState([]);
    const [dataSource, setDataSource] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    //const [govAgendaList, setGovAgendaList] = useState([]);
    const [agendaSubList, setAgendaSubList] = useState(null);
    const [eventSubList, setEventSubList] = useState(null);
    const [tezEditing, setTezEditing] = useState(false);
    const [ttzEditing, setTtzEditing] = useState(false);
    const [tszEditing, setTszEditing] = useState(false);

    const [editingChanges, setEditingChanges] = useState([]);
    //asdasd
    //const [docFile, setDocFile] = useState([]);
    const [fileList, setFileList] = useState([]);
    const [currentProposal, setCurrentProposal] = useState(null);
    const [popupVisible, setPopupVisible] = useState(false);
    const [fileArray, setFileArray] = useState([]);
    const [orgDuty, setOrgDuty] = useState([])
    const fileGridRef = useRef()

    function visible(isVisible) {
        if (isVisible === "true") {
            setPopupVisible(true);
        } else setPopupVisible(false);
    }
    const [agendaId, setAgendaId] = useState(null);
    const [eventId, setEventId] = useState(null);
    //const [test, setTest] = useState(null);
    //const [treeBoxValue, setTreeBoxValue] = useState([]);
    //const [filePopupLoader, setFilePopupLoader] = useState(false);
    const [expanded, setExpanded] = useState(false);
    const [expandedRowKeys, setExpandedRowKeys] = useState([]);
    const [desc, setDesc] = useState('')
    const inputFilesRef = useRef()

    const valueA1 = useRef(null);
    const valueA2 = useRef(null);
    const valueE1 = useRef(null);
    const valueE2 = useRef(null);
    const valueGA = useRef(null);

    let canEdit = useRef(false);
    let agendaListAll = useRef(null);
    let eventListAll = useRef(null);
    let curProposalRef = useRef(null);
    let curDetailListRef = useRef(null);
    //let govAgendaListAll = useRef(null);
    //let treeViewData = null;
    let treeList = useRef(null);
    let projects = useRef([]);
    let projectsRemoved = useRef([]);
    //let agendaSelectedList = useRef([]);
    let myTreeList = useRef({});
    let mapProposal = useRef({});
    let mapIdArr = useRef({});
    let mapCodeArr = useRef({});
    let mapCodeArrData = useRef({});
    const [amtDiff, setAmtDiff] = useState(0);
    const [ttzDiff, setTtzDiff] = useState(0);
    const [tezDiff, setTezDiff] = useState(0);
    const [sumYear0, setSumYear0] = useState(0);
    const [sumYear1, setSumYear1] = useState(0);
    const [sumYear2, setSumYear2] = useState(0);
    const [sumYear3, setSumYear3] = useState(0);
    const [sumYear4, setSumYear4] = useState(0);
    const [sumYear5, setSumYear5] = useState(0);
    const [sumYear100, setSumYear100] = useState(0);
    //const [sumYear101, setSumYear101] = useState(0);
    //const [sumYear102, setSumYear102] = useState(0);
    const [sumYear103, setSumYear103] = useState(0);
    const [fullscreen, setFullscreen] = useState(false);
    const [popupDetail, setPopupDetail] = useState(false);
    const [selectionData, setSelectionData] = useState(null);
    const [orgId, setOrgId] = useState(null);
    const [year, setYear] = useState(null);
    const [descPopup, setDescPopup] = useState(false)
    const [rowData, setRowData] = useState()
    const [detailId, setDetailId] = useState()
    const [showCalc, setShowCalc] = useState(false);
    const [proposalCalcEza, setProposalCalcEza] = useState([]);
    //const [propStatus, setPropStatus] = useState(null);
    const [isData, setIsData] = useState(false);
    const [popupCalc, setPopupCalc] = useState(false);
    let saveButton;
    //let revertButton;
    console.log('props.notOrg', props.notOrg);
    useEffect(() => {
        getFileList();
        initVars();
        loadData();
        loadAndSetupAgenda();
        loadAndSetupEvent();
        roleChecker();
        //checkData(props.agendaId);
        getOrgDuty();
        getProposalCalcEza();

    }, []);

    const getProposalCalcEza = async () => {
        await ProposalService.getProposalCalcEza(props.proposal.org_id, props.year)
            .then((res) => {
                setProposalCalcEza(res)
            })
            .catch((err) => {
                console.log("caught ProposalCalcEza->", err)
            })
    }

    const getOrgDuty = async () => {
        await OrganizationService.getOrganizationDuty(props.proposal.org_id)
            .then((res) => {
                setOrgDuty(res.data)

            })
            .catch((err) => {
                console.log("caught->", err)
            })
    }
    /*const checkData = async (agendaId) => {
        setTreeBoxValue([]);
        const { proposal } = props;
        if (agendaId && proposal.id) {
            const res = await ProposalService.getGovAgenda(proposal.id, agendaId);
            const r1 = await RefService.refGovAgendaProp(proposal.org_id);
            if (res.data && res.data.length > 0 && r1 && r1.length > 0) {
                const list = [];
                res.data.forEach((element) => {
                    const result = r1.find((i) => i.id === element.gov_agenda_id);
                    list.push(result);
                    // agendaSelectedList.current.push(result);
                });
                setGovAgendaDataGridList(list);
            }
        }
    };*/

    /*async function onClickTest() {
        loadAndSetupProposal();
    }*/

    const initVars = async () => {
        const {agendaId, eventId, proposal} = props;
        if (agendaId) setAgendaId(agendaId);
        if (eventId) setEventId(eventId);
        if (proposal) curProposalRef.current = proposal;

        if (
            proposal &&
            projectStatusIdEditAllowed.includes(proposal.budget_project_status_id)
        ) {
            canEdit.current = true;
            setTszEditing(true);
        }
    };
    const roleChecker = async () => {
        const budgetTypeId = user.organization.org_budget_type_id;
        if (budgetTypeId === 1) {
            setTtzEditing(false);
            if (
                props.proposal &&
                props.proposal.budget_project_status_id !==
                budgetProjectStatusEnum.ACCEPTED_TEZ
            ) {
                setTezEditing(true);
                canEdit.current = true
            }
        } else if (budgetTypeId === 2) {
            setTezEditing(false);
            if (
                props.proposal &&
                props.proposal.budget_project_status_id !==
                budgetProjectStatusEnum.ACCEPTED_TTZ
            ) {
                canEdit.current = true
                setTtzEditing(true);
            }
        }
    };
    /*useEffect(() => {
        console.log('ttzEditing', ttzEditing);
        if (ttzEditing || tezEditing) canEdit.current = true;
    }, [ttzEditing, tezEditing]);*/

    /*const loadAndSetupProposal = async () => {
        if (!agendaId || !eventId) return;
        loadAndSetupProposalParam(agendaId, eventId);
    };*/
    const loadAndSetupProposalParam = async (aId, eId, isDataVisible) => {
        /*console.log("agenda: ", aId);
        console.log("event: ", eId);
        console.log("current: ", props.proposal.org_id);*/
        setIsLoading(true);
        const res = await ProposalService.getProposalDetailList(
            aId,
            eId,
            curProposalRef.current.id
        );
        const temp = await ProposalService.listVBudgetCompare(props.year,
            aId,
            eId,
            props.proposal.org_id
        );

        // convert
        let result = helperConvertProposals(temp.data, res.data);

        // convert tree list
        myTreeList.current = list_to_tree(result);
        updateSubTotal();
        if (isDataVisible) {
            let res = _.filter(result, item => {
                return item.amount * (1) + item.ttz_amount * (1) + item.tez_amount * (1) !== 0
            });
            setDataSource(res);
        } else {
            setDataSource(result);
        }

        //console.log('result', result);
        let year0 = _.sumBy(result, r => {
            return r.year0 * (1);
        });
        let year1 = _.sumBy(result, r => {
            return r.year1 * (1);
        });
        let year2 = _.sumBy(result, r => {
            return r.year2 * (1);
        });
        let year3 = _.sumBy(result, r => {
            return r.year3 * (1);
        });
        let year4 = _.sumBy(result, r => {
            return r.year4 * (1);
        });
        let year5 = _.sumBy(result, r => {
            return r.year5 * (1);
        });
        let year100 = _.sumBy(result, r => {
            return r.year100 * (1);
        });
        /*let year101 = _.sumBy(result, r => {
            return r.year101 * (1);
        });*/
        /*let year102 = _.sumBy(result, r => {
            return r.year102 * (1);
        });
        */
        let year103 = _.sumBy(result, r => {
            return r.year103 * (1);
        });
        setSumYear0(year0);
        setSumYear1(year1);
        setSumYear2(year2);
        setSumYear3(year3);
        setSumYear4(year4);
        setSumYear5(year5);
        setSumYear100(year100);
        //setSumYear101(year101);
        //setSumYear102(year102);
        setSumYear103(year103);
        let res2 = _.filter(result, item => {
            return item.amount * 1 + item.tez_amount * 1 + item.ttz_amount * 1 !== 0 && item.code.length === 6 && item.code.substring(0, 1) === '2'
        });
        let res1 = _.filter(result, item => {
            return item.amount * 1 + item.tez_amount * 1 + item.ttz_amount * 1 !== 0 && item.code.length === 6 && item.code.substring(0, 1) === '1'
        });
        let res3 = _.filter(result, item => {
            return item.amount * 1 + item.tez_amount * 1 + item.ttz_amount * 1 !== 0 && item.code.length === 6 && item.code.substring(0, 1) === '3'
        });
        //Zardal amount
        let zardalAmt = _.sumBy(res2, r => {
            return r.amount * (1);
        });
        //Orlogo amount
        let orlogoAmt = _.sumBy(res1, r => {
            return r.amount * (1);
        });
        //EhUusver amount
        let ehUusverAmt = _.sumBy(res3, r => {
            return r.amount * (1);
        });

        //Zardal ttz
        let zardalTtz = _.sumBy(res2, r => {
            return r.ttz_amount * (1);
        });
        //Orlogo ttz
        let orlogoTtz = _.sumBy(res1, r => {
            return r.ttz_amount * (1);
        });
        //EhUusver ttz
        let ehUusverTtz = _.sumBy(res3, r => {
            return r.ttz_amount * (1);
        });

        //Zardal tez
        let zardalTez = _.sumBy(res2, r => {
            return r.tez_amount * (1);
        });
        //Orlogo tez
        let orlogoTez = _.sumBy(res1, r => {
            return r.tez_amount * (1);
        });
        //EhUusver tez
        let ehUusverTez = _.sumBy(res3, r => {
            return r.tez_amount * (1);
        });
        setAmtDiff((orlogoAmt+ehUusverAmt -zardalAmt).toFixed(0));
        setTtzDiff((orlogoTtz + ehUusverTtz - zardalTtz).toFixed(0));
        setTezDiff(orlogoTez + ehUusverTez - zardalTez.toFixed(0));
        setIsLoading(false);
    };

    const updateSubTotal = (index = -1) => {
        if (index === -1) {
            myTreeList.current.forEach((item) => {
                subtotal_tree(item);
                return item;
            });
        } else {
            if (typeof myTreeList.current[index] == "undefined") {
            } else {
                let item = myTreeList.current[index];
                subtotal_tree(item);
            }
        }
    };
    const setVisibleCalc = (e) => {
        return e.row.data.isCalcEcon === undefined ? false : true;
    };
    const submitFile = async (docFile2) => {
        const data = currentProposal
        // console.log('submit file', data)
        const {proposal} = props;
        // console.log('submit docFile',docFile2)
        // console.log('submit data',data)
        if (docFile2.value.length === 0) {
            return notify("Файл оруулна уу ", "warning", 2000);
        }
        const files = Array.from(docFile2.value)
        // console.log("e",files)
        if (files.some((file) => file.name.endsWith('.exe')))
            return notify(".exe өргөтгөлтэй файл явуулах боломжгүй", "warning", 2000);
        const result = files.map((file) => {
            let fd = new FormData();
            fd.append("budget_project_detail_id", detailId);
            fd.append("additional_doc", file);
            fd.append("budget_project_id", proposal.id);
            fd.append("description", data.code + ' - ' + data.name);
            return ProposalService.uploadFiles(fd)
        })

        await Promise.all(result)
            .then((res) => {
                // setDocFile([]);
                props.setFilesUpdated(prev => !prev)
            })
            .catch((err) => {
                console.log('file uploading err', err)
                notify("Файл хадгалахад алдаа гарлаа", "warning", 2000);
            })
        await getFileList();

        // let res = await ProposalService.uploadFiles(fd);
        // if (res.code === 200) {
        // 
        // notify("Файлыг амжилттай хадгаллаа", "success", 2000);
        // } else {
        // 
        // }
    };

    // const onSelectedFilesChanged = async (e, target) => {
    //
    //   // setCurrentProposal(e)
    //   setDocFile(e.value);
    //   // setDocFile(e.value);
    // };

    const getFileList = async () => {
        const {proposal} = props;
        // console.log('proposal', proposal)
        const res = await ProposalService.getFileList(proposal.id);
        setFileList(res.data);
        // console.log('res data', res.data)
        try {
            if (res.data.length >= 0) {
                const filteredArray = res.data.filter((foundItem) => {
                    if (
                        foundItem.budget_project_detail_id &&
                        foundItem.budget_project_detail_id.length > 0
                    ) {
                        // console.log('column data', columnData)
                        // console.log('detail ID', detailId)
                        return foundItem.budget_project_detail_id === detailId;
                        // return foundItem.budget_project_detail_id === columnData.data.detail_id;
                    }
                });
                // console.log("getting file list and filtered aray = ", filteredArray)
                setFileArray(filteredArray);
            }
        } catch (err) {
            setFileArray([])
            console.log('setting file array erro ', err)
        }
    }

    const deleteFile = async (id) => {
        try {
            const customConfirm = custom({
                title: "Баталгаажуулалт",
                messageHtml: "<i>Энэхүү файлыг устгахдаа итгэлтэй байна уу?</i>",
                buttons: [
                    {
                        text: "Тийм",
                        onClick: (e) => {
                            return true
                        },
                    },
                    {
                        text: "Үгүй",
                        onClick: (e) => {
                            return false
                        },
                    },
                ],
            })
            customConfirm.show().then(async (dialogResult) => {

                if (dialogResult) {
                    await ProposalService.deleteFile(id);
                    await getFileList();
                    return notify(
                        `Файл устгагдлаа.`,
                        "success",
                        6000
                    );
                }
            });
        } catch (e) {
            return notify(`Файл устгахад алдаа гарлаа `, "error", 4400);
        }
    };

    const PopupInfo = (data) => {
        let fileArray = [];
        if (data.data.detail_id.length > 0) {
            const filteredArray = fileList.filter((foundItem) => {
                if (
                    foundItem.budget_project_detail_id &&
                    foundItem.budget_project_detail_id.length > 0
                ) {
                    return foundItem.budget_project_detail_id === data.data.detail_id;
                }
            });
            fileArray = filteredArray;
        }
        setFileArray(fileArray);
    };
    const requestFullscreen = () => {
        if (document.fullscreenElement) document.exitFullscreen();
        else document.getElementById("wrapper").requestFullscreen();
        // setFullscreen(prev=>!prev)
    }
    const ExpandCollapseButton = useCallback(() => {
        return (<Button
            onClick={(e) => {
                setExpanded(prevExpanded => !prevExpanded);
                setExpandedRowKeys([]);
            }}
            render={() => {
                return expanded ? (
                        <CgCompressRight size={"17px"} color="#1a1976"/>)
                    :
                    (
                        <CgArrowsExpandRight size={"17px"} color="#1a1976"/>
                    )
            }}
            hint={expanded ? 'Багасгах' : 'ЭЗА дэлгэрэнгүй харуулах'}
        >
        </Button>)
    }, [expanded])

    const FullscreenButton = useCallback(() => {
        return (
            <Button
                onClick={() => {
                    requestFullscreen()
                    setFullscreen(!fullscreen)
                }}
                style={{marginLeft: "0.5em"}}
                render={() => {
                    return fullscreen ?
                        (<CgMinimize size={"17px"} color="#1a1976"/>)
                        :
                        (<CgMaximize size={"17px"} color="#1a1976"/>)
                }}
                hint={fullscreen ? 'Багасгах' : 'Дэлгэцэнд бүтэн харуулах'}
            >
            </Button>
        )
    }, [fullscreen])

    const ShowCalcRender = () => {
        return (
            <Button
                onClick={() => {
                    setShowCalc(true);
                    setOrgId(props.proposal.org_id);
                    //setPropStatus(props.proposal.budget_project_status_id);
                }}
                style={{marginLeft: "0.5em"}}
                render={() => {
                    return <CgCalculator id="proposalCalcEza" size={"17px"} color="#1a1976"/>
                }}
            >
            </Button>
        )
    }

    const IsDataButton = () => {
        return (<Button
            onClick={(e) => {
                setIsData(!isData);
                loadAndSetupProposalParam(props.agendaId ? props.agendaId : agendaId, props.eventId ? props.eventId : eventId, !isData);
            }}
            style={{marginLeft: "0.5em"}}
            render={() => {
                return isData ? (
                        <CgCheckR id="proposalIsDataAll" size={"17px"} color="#1a1976"/>)
                    :
                    (
                        <CgBorderAll id="proposalIsData" size={"17px"} color="#1a1976"/>
                    )
            }}>
        </Button>)
    }

    const onSelectedFilesChanged = useCallback(async (e) => {
        // setDocFile(e.value);
        // notify(
        // "Файл хавсаргагдлаа, Hогоон товчийг дарж хадгална уу. ",
        // "info",
        // 4600
        // );


        fileGridRef.current.instance.beginCustomLoading()
        await submitFile(e)
        await getFileList()
        fileGridRef.current.instance.endCustomLoading()
        // setUpdateFilesLoad(true);
        // PopupInfo(columnData);
        // setTimeout(() => {
        // setUpdateFilesLoad(false);
        // }, 500);
    }, [currentProposal])

    const fileUpload = (data) => {
        const handleButtonClick = (e) => {
            setRowData(data.data);
            //console.log('column data setting detail id', data);
            setDetailId(data?.data?.detail_id);
            //setColumnData(data);
            PopupInfo(data);
            setPopupVisible(true);
            setCurrentProposal(data.data);
        };

        if (
            data.data.code.length === 6 &&
            data.data.amount &&
            data.data.detail_id !== null &&
            data.data.detail_id !== undefined
        ) {
            return (
                <div>
                    <Button
                        key={data.data.id}
                        height={35}
                        icon="activefolder"
                        type={
                            data.data.is_file ? "success" : "default"
                        }
                        hint={data.data.is_file ? "Файл хавсаргасан байна" : "Файл хавсаргах"}
                        onClick={handleButtonClick}
                        stylingMode={'text'}
                        //style={{ marginTop: "3px", backgroundColor: "#1453B5" }}
                    />
                </div>
            );
        } else {
            return (<></>)
        }
    }

    const helperConvertProposals = (budgetsHistory, current) => {
        // reset some vars
        projectsRemoved.current = [];
        projects.current = [];
        let res = [];

        economicCategoryArray.current.forEach((e) => {
            res.push({...e});
        });
        let currCopy = [...current];
        curDetailListRef.current = [...current]; // uses it later when we wanna save
        // if (budgetsHistory.length > 0) {
        let groupedArr = helperGroupByKey(budgetsHistory, "econ_category_id");
        groupedArr.forEach((item) => {
            const temp = {};
            item.data.forEach((element) => {
                if ((props.year - element.budget_year * 1) !== 1 && element.budget_status_id === 7) {
                    temp[`year${props.year - element["budget_year"] - 1}`] =
                        element["amount"];
                } else if ((props.year - element.budget_year * 1) === 1 && ![7, 100, 101, 102, 103].includes(element.budget_status_id)) {
                    temp[`year${props.year - element["budget_year"] - 1}`] = element["amount"];
                } else if ((props.year - element.budget_year * 1) === 0 && element.budget_status_id === 10) {
                    temp[`year${props.year - element["budget_year"] + 3}`] = element["amount"];
                } else if ((props.year - element.budget_year * 1) === -1 && element.budget_status_id === 12) {
                    temp[`year${props.year - element["budget_year"] + 5}`] = element["amount"];
                } else if ((props.year - element.budget_year * 1) === -2 && element.budget_status_id === 12) {
                    temp[`year${props.year - element["budget_year"] + 7}`] = element["amount"];
                } else if ((props.year - element.budget_year * 1) === 1 && element.budget_status_id === 100) {
                    temp[`year${100}`] = element["amount"] * 1;
                } else if ((props.year - element.budget_year * 1) === 1 && element.budget_status_id === 101) {
                    temp[`year${101}`] = element["amount"] * 1;
                } else if ((props.year - element.budget_year * 1) === 1 && element.budget_status_id === 102) {
                    temp[`year${102}`] = element["amount"] * 1;
                } else if ((props.year - element.budget_year * 1) === 1 && element.budget_status_id === 103) {
                    temp[`year${103}`] = element["amount"] * 1;
                }
            });
            // if exist, find the current year proposal by econ_id
            const curDataIndex = currCopy.findIndex(
                (el) => el.econ_category_id === item.key
            );
            if (curDataIndex !== -1) {
                temp["amount"] = currCopy[curDataIndex]["amount"];
                temp["description"] = currCopy[curDataIndex]["description"];
                temp["detail_id"] = currCopy[curDataIndex]["id"];
                temp["created_user_id"] = currCopy[curDataIndex]["created_user_id"];
                temp["ttz_amount"] = currCopy[curDataIndex]["ttz_amount"];
                temp["tez_amount"] = currCopy[curDataIndex]["tez_amount"];
                temp["is_file"] = currCopy[curDataIndex]["is_file"];
                temp["is_calculated"] = currCopy[curDataIndex]["is_calculated"];
                currCopy.splice(curDataIndex, 1); // merge the current and history data, and make sure remove the data form the arrayy
                // so that we can add the rest of the currentData
            }
            let find = res.findIndex((el) => el.id === item.key);
            res[find] = {
                ...res[find],
                ...temp,
                agenda_id: agendaId,
                event_id: eventId,
                budget_project_id: curProposalRef.current.id,
            }; // ATTENTIO, static for now
        });
        // }
        // just to group them by parent id, not necessary
        currCopy.forEach((item) => {
            let find = res.findIndex((el) => el.id === item.econ_category_id);
            const {
                agenda_id,
                event_id,
                budget_project_id,
                amount,
                description,
                created_user_id,
                ttz_amount,
                tez_amount,
                is_file,
                is_calculated
            } = item;
            if (find !== -1) {
                let temp2 = {...res[find]};
                res[find] = {
                    ...temp2,
                    agenda_id,
                    event_id,
                    budget_project_id,
                    amount,
                    description,
                    created_user_id,
                    ttz_amount,
                    tez_amount,
                    detail_id: item.id,
                    is_file,
                    is_calculated
                };
            }
            // item['econ_parent_id'] = economicCategoryArray.current.find(el1 => el1.id === el.econ_category_id).parent_id;
            // item['id'] = counter++;
        });
        // res.push(...currCopy); // currCopy is new ones that client has added, (no history)
        // projects.current = res;

        return res;
    };

    const loadData = async () => {
        const result = await RefService.getRefEconomicCategory();
        const calcEconRes = await BudgetCalculationService.getDynamicTableCheckEcon();
        if (Array.isArray(result.data)) {
            result.data.forEach((o) => {
                mapProposal.current[o.id] = {
                    amount: 0,
                    description: "",
                };
                mapCodeArr.current[o.code] = o.id;
                mapIdArr.current[o.id] = o.code;
                mapCodeArrData.current[o.code] = o;
                calcEconRes.data.forEach((c) => {
                    if (c.econ_category_id === o.id) {
                        o.isCalcEcon = true;
                    }
                })
            });
        }

        economicCategoryArray.current = _.sortBy(result.data, ["code"]);
        // created at talbar buruu boglogdoj baigaag zasah
        economicCategoryArray.current.forEach((e) => {
            delete e.createdAt;
            delete e.updatedAt;
        });
        // setDataSource(result.data);
        /*const r1 = await RefService.refGovAgendaProp(props.proposal.org_id);
        const listData = [];
        r1.forEach((data) => {
            if (!data.parent_id) {
                listData.push(data);
            }
        });
        setGovAgendaList(r1);
        //setGovAgendaParentList(listData);
        govAgendaListAll.current = r1;*/

        // if (props.agendaId !== null  && props.eventId !== null ) loadAndSetupProposalParam(props.agendaId, props.eventId);
    };
    const loadAndSetupEvent = async () => {
        const result = await RefService.getEventsProps(props.proposal.org_id);
        eventListAll.current = _.sortBy(result.data, ["name"]);
        // todo: check data.code === 200

        // get the parentId === null
        const filtered = eventListAll.current.filter(
            (item) => item.parent_id === null
        );
        setEventList(filtered);

        if (props.eventId) {
            // this is edit window, propagate the selected values
            const find = eventListAll.current.find((el) => el.id === props.eventId);
            if (find && find.parent_id !== null) {
                // set the sublist
                const filteredSub = eventListAll.current.filter(
                    (el) => el.parent_id === find.parent_id
                );
                setEventSubList(filteredSub);
                valueE2.current = props.eventId;
                valueE1.current = find.parent_id;
            } else if (find && find.parent_id === null) {
                valueE1.current = props.eventId;
            }
        }
        // TEST
        /*const temp = [];
        filtered.map((item) => temp.push(`(${item.code}) - ${item.name}`));
        setTest(temp);*/
    };
    const loadAndSetupAgenda = async () => {
        //RefService.getAgendas();
        const result = await RefService.getAgendasProps(props.proposal.org_id);
        agendaListAll.current = _.sortBy(result.data, ["name"]);
        // todo: check data.code === 200

        // get the parentId === null
        const filtered = agendaListAll.current.filter(
            (item) => item.parent_id === null
        );
        setAgendaList(filtered);

        // CASE: EDIT
        if (props.agendaId) {
            // this is edit window, propogate the selected values
            const find = agendaListAll.current.find((el) => el.id === props.agendaId);
            if (find && find.parent_id !== null) {
                // set the sublist
                const filteredSub = agendaListAll.current.filter(
                    (el) => el.parent_id === find.parent_id
                );
                setAgendaSubList(filteredSub);
                valueA2.current = props.agendaId;
                valueA1.current = find.parent_id;
            } else if (find && find.parent_id === null) {
                valueA1.current = props.agendaId;
            }
        }
    };
    const setData = async (e) => {
        // console.log("setData", e)
        if (!e.changes || e.changes.length === 0) return;
        e.changes.forEach((data) => {
            // if(data.type === 'insert') {
            //   const temp = data.data;
            //   temp['agenda_id'] = agendaId;
            //   temp['event_id'] = eventId;
            //   temp['budget_project_id'] = curProposalRef.current.id; // static - temporary
            //   temp['is_insert'] = true;
            //   // projects.current.push(temp); // we dont need to push , it will automatically be pushed to the project.current
            // }

            if (
                data.type === "update" &&
                data.data.code &&
                data.data.code.length === 6
            ) {
                // autoSum()
                // if it is update, then just update the 'project.current'
                const updateIndex = projects.current.findIndex(
                    (el) => el["id"] === data.data["id"]
                );
                if (updateIndex !== -1) projects.current[updateIndex] = data.data;
                else projects.current.push(data.data);
            } else if (data.type === "remove") {
                // project.current will be automatically updated
                if (data.key.id !== null && data.key.id !== undefined) {
                    // if t.id exist , then this data is in the database,
                    projectsRemoved.current.push(data.key); // keep track of removed projects separately so we update the db later
                }
            }
        });

        // call the save function
        setDetailBulk(null);
    };

    const setDetailBulk = async (e) => {
        // const buttonText = e.component.option('text');

        // if (!govAgendaDataGridList || govAgendaDataGridList.length === 0) {
        //   return notify(`Бодлогын баримт бичиг сонгоогүй байна`, "warning", 5000);
        // } else {
        /*const list = [];
        govAgendaDataGridList.forEach((element) => {
          if (element.level_number === 4 || element.level_number === 3) {
            list.push(element.id);
          }
        });
        const agenda = {
          preData: preData,
          budget_project_id: props.proposal.id,
          agenda_id: agendaId,
          gov_agenda_id_list_list: list,
          created_user_id: user.id,
        };
        ProposalService.setGovAgenda(agenda).then((res) => {
          checkData(agendaId);
        });*/
        // list.forEach(id => {
        //   const agenda = {
        //     preData: preData,
        //     budget_project_id: props.proposal.id,
        //     agenda_id: agendaId,
        //     gov_agenda_id_list: id,
        //     created_user_id: user.id
        //   }
        //
        //   ProposalService.setGovAgenda(agenda).then(res => {
        //   });
        // });
        // }

        const sendData = [...projects.current];
        sendData.forEach((item) => {
            // need to convert it to 'budget_project_detail'
            item["agenda_id"] = agendaId;
            item["event_id"] = eventId;
            item["budget_project_id"] = curProposalRef.current.id;
            item["econ_category_id"] = item.id;
            item.id = item.detail_id;
            item["budget_year"] = props.year;
            item["budget_month"] = 0;
            // const temp = curDetailListRef.current.find(i => i.econ_category_id === item.id);
            // if ( temp ){
            //   item.id = temp.id;
            //   item['created_user_id'] = temp['created_user_id'];
            // } else {
            //   item.id = null;
            // }
        });

        // make sure deleted projects are included back in the variable, so that we take care of removing it in the backend
        projectsRemoved.current.forEach((item) => {
            item["is_removed"] = 1;
            sendData.push(item);
        });
        // save the data
        //console.log('sendData', sendData);
        try {
            const res = await ProposalService.saveProposalDetail({
                data: sendData,
                govAgenda: valueGA.current,
            });
            if (res.success) {
                setEditingChanges([]);
                //notify(`Амжилттай хадгаллаа`, "success");
                notify(
                    {
                        message: "Амжилттай хадгалагдлаа",
                        width: 300,
                        position: {
                            my: "right top",
                            at: "right top",
                            of: "#floating-div-2",
                        },
                        animation: animation,
                    },
                    "success",
                    3000
                )
                //checkData(agendaId);
            }

            // await loadAndSetupProposal();
            // instead of refresh
            // we just close the popup
            if (typeof props.onSavedCallBack === "function") {
                if (agendaId !== null && eventId !== null) {
                    loadAndSetupProposalParam(agendaId, eventId);
                    //checkData(agendaId);
                }
                props.onSavedCallBack({isChanged: true}); // closes the popup
            }
        } catch (e) {
            notify(`Алдаа гарлаа`, "danger");
        }

    };

    const onEditingStart = (e) => {
        if (e.data.code.length !== 6) e.cancel = true;

    };

    /*const cellRenderEza = (data) => {
        return (
            <span className={`proposal-eza with-code code-${data.data.code.length}`}>
                <span>{data.data.code}</span> - {data.displayValue}
            </span>
        );
    };*/
    const onValueChanged = (
        e,
        originList,
        setSubList,
        setVarId,
        refObj = null
    ) => {
        // check if the parent is selected
        if (refObj) refObj.current = e.value;
        if (e.value === null || e.value === undefined) {
            // if parent is not selected then clear the sub list
            setVarId(null);
            if (setSubList !== null && typeof setSubList === "function")
                setSubList([]);
            return;
        }

        // set the sublist
        const filtered = originList.filter((item) => e.value === item.parent_id);

        if (!filtered || filtered.length === 0) {
            // if there is no child - then set the variable as a parent_id
            setVarId(e.value);
        } else {
            if (setSubList !== null && typeof setSubList === "function")
                setSubList(filtered);
        }

        // auto reset when agenda and event id are set,
        // checkData(agendaId);
    };

    useEffect(() => {
        if (agendaId !== null && eventId !== null) {
            loadAndSetupProposalParam(agendaId, eventId);
            //checkData(agendaId);
        }
    }, [agendaId, eventId]);

    const onToolbarPreparing = (e) => {
        e.toolbarOptions.items.forEach((item) => {
            item.showText = "always";
            if (item.name === "saveButton") {
                Object.assign(item.options, {
                    disabled: true,
                    onContentReady: function (e) {
                        saveButton = e.component;
                    }
                });
            }
            /*if (item.name === "revertButton") {
                Object.assign(item.options, {
                    disabled: true,
                    onContentReady: function (e) {
                        revertButton = e.component;
                    }
                });
            }*/
        });
        // if (e.toolbarOptions.items && e.toolbarOptions.items.length > 0) {
        //   const button = {...e.toolbarOptions.items[0]};
        //   button.options = {...e.toolbarOptions.items[0].options};
        //   button.options.text = `Хуулах ${props.year}`;
        //   button.options.disabled = !tszEditing;
        //   button.options.icon = 'copy';
        //   button.options.onClick = (e) => {
        //     cloneColumn(dataSource, 'year0');
        //   };
        //   e.toolbarOptions.items.push(button);
        // }
        // e.toolbarOptions.items[0].showText = 'always';
        // e.toolbarOptions.items[1].showText = 'always';
        // e.toolbarOptions.visible = false
    };
    const setCellValueAmount = (newData, value, currentRowData) => {
        const checkCode = parseInt(currentRowData.code.charAt(0));
        newData.amount = value;
        if (checkCode > 3) {
            if (value < 0) {
                value = 0
            } else {
                updateFormula({...currentRowData, amount: value});
            }
        } else {
            updateFormula({...currentRowData, amount: value});
        }
    };
    const setCellValueAmountTEZ = (newData, value, currentRowData) => {
        const checkCode = parseInt(currentRowData.code.charAt(0))
        newData.tez_amount = value;
        if (checkCode > 3) {
            if (value < 0) {
                value = 0
            } else {
                updateFormula({...currentRowData, amount: value}, "tez_amount");
            }
        } else {
            updateFormula({...currentRowData, amount: value}, "tez_amount");
        }
    };
    const setCellValueAmountTTZ = (newData, value, currentRowData) => {
        const checkCode = parseInt(currentRowData.code.charAt(0));
        newData.ttz_amount = value;
        if (checkCode > 3) {
            if (value < 0) {
                value = 0
            } else {
                updateFormula({...currentRowData, amount: value}, "ttz_amount");
            }
        } else {
            updateFormula({...currentRowData, amount: value}, "ttz_amount");
        }
    };
    const updateFormula = (data, field = "amount") => {
        let temp = [...editingChanges];
        if (data.code.length !== 6) {
            return;
        }
        let checkCalc = _.sumBy(proposalCalcEza, r => {
            return (r.value * (1))
        });
        if (data.code.substr(0, 4) !== "2101" || (checkCalc === 0)
        ) {
            if (temp && temp.length > 0) {
                let r = _.find(temp, {key: data.id}) || {};
                if (!_.isEmpty(r)) {
                    r.data[`${field}`] = data.amount;
                } else {
                    temp.push({
                        type: "update",
                        key: data.id,
                        data: {[`${field}`]: data.amount},
                    });
                }
            } else {
                temp.push({
                    type: "update",
                    key: data.id,
                    data: {[`${field}`]: data.amount},
                });
            }
            setEditingChanges(temp);
            return;
        }

        let code = data.code;
        let amount = data.amount;
        let fn = PROPOSAL_FORMULA["210101"];

        // temp.push({type: 'update', key: mapCodeArr.current[code], data: {amount}});

        if (typeof fn === "function" && checkCalc !== 0
        ) {
            let sum2101 = 0;
            //let value = 0;
            PROPOSAL_FORMULA_FIELDS.forEach((cc) => {
                // check if we are currently editing this row
                if (cc === code) {
                    sum2101 += amount * 1;
                } else {
                    const id = mapCodeArr.current[cc]; // cc - calculate code

                    // check if we are already updated it (
                    const f = editingChanges.find((change) => change.key === id);
                    if (f && !!f.data[field]) {
                        sum2101 += f.data[field] * 1;
                    }
                    // else get the amount from datasource
                    else {
                        sum2101 += dataSource.find((ds) => ds.code === cc)[field] * 1;
                    }
                }
            });

            let items = fn(sum2101, proposalCalcEza);
            for (let k in items) {
                let val = items[k];
                let data = {};
                data[field] = val;
                let id = mapCodeArr.current[k];

                // make sure dont affect other changes
                const find = temp.find((el) => el.key === id);
                if (find) {
                    find.data = {...find.data, ...data};
                } else {
                    temp.push({type: "update", key: id, data});
                }
            }
            if (temp && temp.length > 0) {
                let r = _.find(temp, {key: data.id}) || {};
                if (!_.isEmpty(r)) {
                    r.data[`${field}`] = data.amount;
                } else {
                    temp.push({
                        type: "update",
                        key: data.id,
                        data: {[`${field}`]: data.amount},
                    });
                }
            } else {
                temp.push({
                    type: "update",
                    key: data.id,
                    data: {[`${field}`]: data.amount},
                });
            }
            setEditingChanges(temp);
            temp = [];
        }
    };

    const setCellValueDesc = (newValues, value, currentRowData) => {
        // console.log('set value desc',value, 'currentRowData',currentRowData)
        let temp = [...editingChanges];

        if (temp && temp.length > 0) {
            let r = _.find(temp, {key: currentRowData.id}) || {};
            if (!_.isEmpty(r)) {
                r.data.description = value;
            } else {
                temp.push({
                    type: "update",
                    key: currentRowData.id,
                    data: {description: value},
                });
            }
        } else {
            temp.push({
                type: "update",
                key: currentRowData.id,
                data: {description: value},
            });
        }
        setDesc(value)
        setEditingChanges(temp);
    };
    const onCellPreparedHandler = (e) => {
        if (e.rowType === 'header') {
            e.cellElement.style.fontFamily = 'Segoe UI'
            e.cellElement.wordWrapEnabled = true;
            e.cellElement.style.color = "#15186A"
            e.cellElement.style.backgroundColor = (props.year === e.column.caption) ? "#7ba9ff" : "#EEF0F4"
            e.cellElement.style.fontWeight = "bold";
            e.cellElement.style.textAlign = 'center'
            e.cellElement.style.verticalAlign = 'middle'
            e.cellElement.style.borderRightWidth = '2px'
            e.cellElement.style.borderLeftWidth = '2px'
        }
        if (e.rowType === "data" && (e.columnIndex === 0) && e.data.code.length !== 6) {
            e.cellElement.style.fontFamily = 'Segoe UI'
            e.cellElement.style.color = "#003696";
            e.cellElement.style.fontWeight = "bold";
        }
        if (e.rowType === "data") {
            e.cellElement.style.fontFamily = 'Segoe UI'
            e.cellElement.style.fontSize = '12px'
            e.cellElement.style.verticalAlign = "middle";
            if (e.column.dataField === 'ttz_amount' && !ttzEditing) {
                e.cellElement.innerHTML = cellRenderV2(e, 'ttz');
            } else if (e.column.dataField === 'tez_amount' && !tezEditing) {
                e.cellElement.innerHTML = cellRenderV2(e, 'tez');
            } else
                if (e.column.dataField === 'year0') {
                e.cellElement.innerHTML = cellRenderV2(e, 'year0');
            } else if (e.column.dataField === 'year1') {
                e.cellElement.innerHTML = cellRenderV2(e, 'year1');
            } else if (e.column.dataField === 'year2') {
                e.cellElement.innerHTML = cellRenderV2(e, 'year2');
            } else if (e.column.dataField === 'year3') {
                e.cellElement.innerHTML = cellRenderV2(e, 'year3');
            } else if (e.column.dataField === 'year4') {
                e.cellElement.innerHTML = cellRenderV2(e, 'year4');
            } else if (e.column.dataField === 'year5') {
                e.cellElement.innerHTML = cellRenderV2(e, 'year5');
            }
        }
        if (e.column.caption === "ТШЗ санал" && tszEditing) {
            /*if(e.rowType === "data" && e.data.code.length === 6) {
                console.log('eee', e);
            }*/
            e.cellElement.style.fontFamily = 'Segoe UI'
            //e.cellElement.style.fontFamily = "Montserrat"
            e.cellElement.style.fontWeight = "semibold"
            e.cellElement.style.color = (e.rowType === "data" && e.data.code.length === 6 && e.data.is_calculated === 1 && e.column.dataField === 'amount') ? "#3DAA75" : "#15186A"
            e.cellElement.style.backgroundColor = "#FAFDFF"
        }
        if (e.column.caption === "ТЕЗ санал" && tezEditing) {
            e.cellElement.style.fontFamily = 'Segoe UI'
            //e.cellElement.style.fontFamily = "Montserrat"
            e.cellElement.style.fontWeight = "semibold"
            e.cellElement.style.color = "#15186A"
            e.cellElement.style.backgroundColor = "#FAFDFF"
        }
        if (e.column.caption === "ТТЗ санал" && ttzEditing) {
            e.cellElement.style.fontFamily = 'Segoe UI'
            //e.cellElement.style.fontFamily = "Montserrat"
            e.cellElement.style.fontWeight = "semibold"
            e.cellElement.style.color = "#15186A"
            e.cellElement.style.backgroundColor = "#FAFDFF"
        }

    }
    const onOptionChanged = (e) => {
        if (e.name === 'expandedRowKeys') {
            setExpandedRowKeys(e.value);
        }
        if (e.name === "editing") {
            let changes = e.component.option("editing.changes");
            if (changes.length > 0) {
                saveButton.option("disabled", false);
                //revertButton.option("disabled", false);
            }
        }
    };
    const renderAmountHeader = () => {
        return <span
            style={{color: amtDiff*1 === 0 ? '#3DAA75' : '#f66160'}}>ТШЗ санал <br/>{numberWithCommas(amtDiff)}</span>;
    }
    const renderTtzHeader = () => {
        return <span
            style={{color: ttzDiff*1 === 0 ? '#3DAA75' : '#f66160'}}>ТТЗ санал<br/>{numberWithCommas(ttzDiff)}</span>;
    }
    const renderTezHeader = () => {
        return <span
            style={{color: tezDiff*1 === 0 ? '#3DAA75' : '#f66160'}}>ТЕЗ санал<br/>{numberWithCommas(tezDiff)}</span>;
    }
    const cellRenderV2 = (e, type) => {
        let value = 0;
        let value1 = 0;
        let amount = 0;
        //console.log('e.data.year0', e.data);
        // this.props.proposal.budget_project_status_id
        switch (props.proposal.budget_project_status_id) {
            case 1:
                amount = e.data.amount * 1;
                break;
            case 2:
                amount = e.data.tez_amount * 1;
                break;
            case 3:
                amount = e.data.ttz_amount * 1;
                break;
            case 4:
                amount = e.data.amount * 1;
                break;
            case 5:
                amount = e.data.amount * 1;
                break;
            case 6:
                amount = e.data.tez_amount * 1;
                break;
            case 7:
                amount = e.data.ttz_amount * 1;
                break;
            default:
                amount = e.data.amount * 1;
                break;
        }

        switch (type) {
            case 'year0':
                value = amount * 1;
                value1 = e.data.year0 * 1;
                break;
            case 'year1':
                value = amount * 1;
                value1 = e.data.year1 * 1;
                break;
            case 'year2':
                value = amount * 1;
                value1 = e.data.year2 * 1;
                break;
            case 'year3':
                value = amount * 1;
                value1 = e.data.year3 * 1;
                break;
            case 'year4':
                value = amount * 1;
                value1 = e.data.year4 * 1;
                break;
            case 'year5':
                value = amount * 1;
                value1 = e.data.year5 * 1;
                break;
            case 'ttz':
                value = e.data.ttz_amount * 1;
                value1 = e.data.amount * 1;
                break;
            case 'tez':
                value = e.data.tez_amount * 1;
                value1 = e.data.ttz_amount * 1;
                break;
            default:
                value = 0;
                value1 = 0;
                break;
        }
        let percent = 0;
        if (value || value1) {
            percent = value1 !== 0 ? (value - value1) * 100 / value1 : 0;
        }

        if (percent > 0) {
            return (`<div class="inc customCell"
                         style={{ flexDirection: 'column', display: 'flex', justifyContent: 'space-between' }}>
                <div class="current-value" style={{ fontWeight: 'normal', color: '#000000' }}>${e.text}</div>
                <div class="diff" style={{ color: '#f66160' }}>${numberWithCommas(percent)}%</div>
            </div>`)
        } else if (percent < 0) {
            return `<div class="dec customCell"
                        style={{ flexDirection: 'column', display: 'flex', justifyContent: 'space-between' }}>
                <div class="current-value" style={{ fontWeight: 'normal', color: '#000000' }}>${e.text}</div>
                <div class="diff" style={{ color: '#3daa75' }}>${numberWithCommas(percent)}%</div>
            </div>`
        } else {
            return `<span style={{ fontWeight: 'normal', color: '#000000' }}>${e.text}</span>`
        }
    }

    const cellRender = (e, type) => {
        if(type === 'ttz' && !ttzEditing){
            return;
        }
        else if (type === 'tez' && !tezEditing) {
            return;
        }
        else  {
            let value = 0;
            let value1 = 0;
            let amount = 0;
            //console.log('e.data.year0', e.data);
            // this.props.proposal.budget_project_status_id
            switch (props.proposal.budget_project_status_id) {
                case 1:
                    amount = e.data.amount * 1;
                    break;
                case 2:
                    amount = e.data.tez_amount * 1;
                    break;
                case 3:
                    amount = e.data.ttz_amount * 1;
                    break;
                case 4:
                    amount = e.data.amount * 1;
                    break;
                case 5:
                    amount = e.data.amount * 1;
                    break;
                case 6:
                    amount = e.data.tez_amount * 1;
                    break;
                case 7:
                    amount = e.data.ttz_amount * 1;
                    break;
                default:
                    amount = e.data.amount * 1;
                    break;
            }

            switch (type) {
                case 'ttz':
                    value = e.data.ttz_amount * 1;
                    value1 = e.data.amount * 1;
                    break;
                case 'tez':
                    value = e.data.tez_amount * 1;
                    value1 = e.data.ttz_amount * 1;
                    break;
                default:
                    value = 0;
                    value1 = 0;
                    break;
            }
            let percent = 0;
            if (value || value1) {
                percent = value1 !== 0 ? (value - value1) * 100 / value1 : 0;
            }

            if (percent > 0) {
                return (<div class="inc customCell"
                             style={{ flexDirection: 'column', display: 'flex', justifyContent: 'space-between' }}>
                    <div class="current-value" style={{ fontWeight: 'normal', color: '#000000' }}>{e.text}</div>
                    <div class="diff" style={{ color: '#f66160' }}>{numberWithCommas(percent)}%</div>
                </div>)
            } else if (percent < 0) {
                return <div class="dec customCell"
                            style={{ flexDirection: 'column', display: 'flex', justifyContent: 'space-between' }}>
                    <div class="current-value" style={{ fontWeight: 'normal', color: '#000000' }}>{e.text}</div>
                    <div class="diff" style={{ color: '#3daa75' }}>{numberWithCommas(percent)}%</div>
                </div>
            } else {
                return <span style={{ fontWeight: 'normal', color: '#000000' }}>{e.text}</span>
            }
        }
    }
    const renderHBHeader = () => {
        return <span style={{color: '#909090'}} id="proposalHB_Header1">{props.year - 1} оны ХБГ</span>;
    }

    const onChartDetail = async (e) => {
        if (e.row.data) {
            //console.log("e.row.data", e.row.data)
            setIsLoading(true);
            setOrgId(props.proposal.org_id)
            setYear(props.year);
            setSelectionData(e.row.data);
            setPopupDetail(true);
            setIsLoading(false);
        }
    }
    const onCalcDetail = async (e) => {
        if (e.row.data) {
            //console.log("e.row.data", e.row.data)
            setIsLoading(true);
            setOrgId(props.proposal.org_id)
            setYear(props.year);
            setSelectionData(e.row.data);
            setPopupCalc(true);
            setIsLoading(false);
        }
    }
    //props.orgId, props.agendaId, props.eventId, props.econCode
    const renderDetail = props => {
        return (
            <Popup
                visible={popupDetail}
                onHiding={() => setPopupDetail(false)}
                hideOnOutsideClick={true}
                showCloseButton={true}
                height="80vh"
                showTitle={true}
                title={selectionData.code + '-' + selectionData.name + (selectionData.code.substring(0, 1) !== '4' ? '/жилээр, сая ₮/' : '/жилээр/')}
            >
                <div className="card-body" style={{paddingBottom: "1.6rem"}}>
                    <ChartDetailByYear orgId={orgId} agendaId={agendaId} eventId={eventId} econCode={selectionData.code}
                                       year={year}/>
                </div>
            </Popup>
        )
    }
    const renderPopupCalculation = () => {
        return (
            <Popup
                visible={popupCalc}
                onHiding={() => setPopupCalc(false)}
                hideOnOutsideClick={true}
                showCloseButton={true}
                height="auto"
                maxHeight={'90%'}
                width={'95%'}
                showTitle={true}
                title={selectionData.code + '-' + selectionData.name}
            >
                <ScrollView width="100%" height="100%">
                    <div className="card-body">
                        <BudgetCalcDataByEcon eventID={eventId} agendaID={agendaId} econID={selectionData.id}
                                              year={year - 1} orgID={orgId}/>
                    </div>
                </ScrollView>
            </Popup>
        )
    }

    const setEffectCalcEza = async (e) => {
        //console.log('e.changes', e.changes);
        //if (e.changes[0].type === "update") {
        try {
            await ProposalService.editProposalCalcEza(e.changes);
            //await getProposalCalcEza();
            notify('Амжилттай өөрчлөгдлөө', "success", 2000);
        } catch (e) {
            notify('Өөрчлөлт хийх явцад алдаа гарлаа', "error", 2000);
        }
        //}
    }
    const renderCalcEza = props => {
        return (
            <Popup
                visible={showCalc}
                onHiding={() => {
                    setShowCalc(false);
                    loadData();
                    getProposalCalcEza();
                }}
                hideOnOutsideClick={true}
                showCloseButton={true}
                height="55vh"
                width="65vh"
                showTitle={true}
                title={'АОНД-д төлөх шимтгэл'}
            >
                <div className="card-body" style={{paddingBottom: "1.6rem"}}>
                    <DataGrid
                        id="dataGridOverview"
                        dataSource={proposalCalcEza}
                        rowAlternationEnabled={true}
                        columnAutoWidth={true}
                        allowColumnResizing={true}
                        showBorders={true}
                        showColumnLines={true}
                        showColumnHeaders={true}
                        showRowLines={true}
                        keyExpr="id"
                        onSaved={setEffectCalcEza}
                        onToolbarPreparing={onToolbarPreparing}
                        noDataText="Дата олдсонгүй."
                    >   {/*user.organization.id === orgId && ([1, 4, 5].includes(propStatus)) ? true : false*/}
                        <Editing mode="batch" //useIcons={true}
                                 allowUpdating={!tszEditing && !ttzEditing && !tezEditing ? false : true}
                                 allowAdding={false}
                                 allowDeleting={false} selectTextOnEditStart={true} texts={{
                            addRow: 'нэмэх',
                            cancelAllChanges: 'Болих',
                            cancelRowChanges: 'Болих',
                            confirmDeleteMessage: 'Энэ мэдээллийг устгах уу?',
                            confirmDeleteTitle: 'Баталгаажуулалт',
                            deleteRow: 'Хасах',
                            editRow: 'Өөрчлөх',
                            saveAllChanges: 'Хадгалах',
                            saveRowChanges: 'Хадгалах',
                            undeleteRow: 'Буцаах',
                            validationCancelChanges: 'Баталгаажуулах болих',
                        }}/>
                        <Column dataField="econ_code" caption={'Код'} allowEditing={false}> </Column>
                        <Column dataField="econ_name" caption={'Нэр'} allowEditing={false}> </Column>
                        <Column dataField="value" caption={'Хувь'} allowEditing={true} format="#,##0.00"
                                dataType="number"> </Column>
                        <Toolbar>
                            <Item visible={true} name="saveButton" widget="dxButton"
                                  options={{icon: "save", text: "Хадгалах"}}/>
                            <Item name="revertButton" widget="dxButton" options={{icon: "revert", text: "Болих"}}/>
                        </Toolbar>
                    </DataGrid>
                    <span style={{color: '#F66160'}}>АОНД-д төлөх багануудын бүх хувь талбарыг 0 болговол шимтгэл бодохгүй гараар шивэх боломжтой</span>
                </div>
            </Popup>
        )
    }
    const descCellRender = useCallback((data) => {
        return data?.displayValue ? (
                <div style={{display: 'flex', overflow: 'hidden', whiteSpace: 'nowrap', margin: 'auto'}}>
                    <p style={{
                        height: '2em',
                        overflow: 'hidden',
                        marginBottom: 'auto',
                        marginTop: 'auto',
                        textOverflow: 'ellipsis',
                        width: "40em",
                        marginRight: '1em'
                    }}>{data.displayValue}</p>
                    <Button onClick={(e) => {
                        setDescPopup(true)
                        setRowData(data.data)
                        setDesc(data.value)
                    }} hint="Тайлбар харах" type={'success'} stylingMode={'text'} height={35} width={35}
                            style={{marginLeft: 'auto'}}
                            icon="comment"/>
                </div>
            )
            : <span
                style={{color: '#9EA0A4'}}>{data.data.code.length === 6 ? 'Та энд тайлбараа оруулна уу 😀' : ''} </span>
    }, [])

    const fileActionRender = useCallback((row) => {
        return (
            <div
                style={{
                    marginLeft: "auto",
                    display: "flex",
                    flexDirection: "row",
                }}
            >
                <a
                    download
                    href={
                        urlServer +
                        "/api/proposal/file/" +
                        row.data.file_path
                    }
                    target="_blank" rel="noreferrer"
                >
                    <Button
                        height={35}
                        // icon="download"
                        // type="success"
                        // text="Татах"
                        // onClick = {() => submitFile(data.data)}
                        style={{marginLeft: "0.5em"}}
                        render={() => {
                            return (<MdFileDownload size={"1em"} color="#1a1976"/>)
                        }}
                    ></Button>
                </a>
                {canEdit.current ? (
                        <Button
                            height={35}
                            // icon="trash"
                            type="danger"
                            // text="Устгах"
                            onClick={() => {
                                deleteFile(row?.data);

                                // await getFileList();
                                // setTimeout(() => {
                                //   PopupInfo(columnData);
                                // }, 1500);
                            }}
                            style={{marginLeft: "0.5em"}}
                            render={() => {
                                return (<MdDeleteForever size={"1em"} color="#fffff"/>)
                            }}
                        />
                    )
                    :
                    (
                        <div></div>
                    )}
            </div>
        )
    }, [canEdit.current, detailId])
    const textAreaOptions = {format: '#,##0.00'};

    const RevertRender = () => {
        return (
            <Button visible={canEdit.current} icon={'revert'} onClick={() => {
                setEditingChanges([])
            }} text={'Болих'}>
            </Button>
        )
    }
    const onEditorPreparing = (e) => {
        if (e.dataField === "description" && e.parentType === "dataRow") {
            const defaultValueChangeHandler = e.editorOptions.onValueChanged;
            e.editorName = "dxTextArea"; // Change the editor's type
            e.editorOptions.maxSize = 200;
            e.editorOptions.onValueChanged = function (args) {  // Override the default handler
                // ...
                // Custom commands go here
                // ...
                // If you want to modify the editor value, call the setValue function:
                // e.setValue(newValue);
                // Otherwise, call the default handler:
                defaultValueChangeHandler(args);
            }
        }
        if (e.parentType === "dataRow" && e.dataField === "amount" && e.row.data.isCalcEcon && (!props.notOrg || props.notOrg === undefined) && user.start_of_ancestry !==144 ) {
            e.allowEditing = false;
            e.readOnly = true;
            e.editorOptions.readOnly = true;
            e.editorOptions.disabled = true;
        }
        if (e.parentType === "dataRow" && ['amount', 'ttz_amount', 'tez_amount'].includes(e.dataField) && e.row.data.code.substring(0, 1) === '4') {
            e.editorOptions.format = '#0';
        }
    }

    const handleOnCellHover = (e) => {
        //console.log(e);
        if (e && e.column && e.column.dataField) {
            if (!e.column.dataField === 'amount')
                return;
            else {
                if (e.rowType === 'data' && e.data.code.length === 6 && e.column.dataField === 'amount' && e.row.data.isCalcEcon) {
                    if (e.eventType === 'mouseover') {
                        //e.cellElement.style.boxShadow = '0px 0px 0px 3px #2C77FF inset'
                        e.cellElement.setAttribute('title', e.row.data.is_calculated === 1 ? 'Төсвийн тооцооллоос татагдсан талбар!' : 'Төсвийн тооцооллоос татагдах талбар!');
                    } else {
                        //e.cellElement.style.boxShadow = 'none'
                        e.cellElement.removeAttribute('title');
                    }
                }
            }
        } else return;

    }

    const customValidationDesc = (params) => {
        if (params.value !== undefined && params.value) {
            let s = params.value.length;
            params.rule.message = `Тайлбарын хэмжээ 500 тэмдэгтээс ихгүй байна`
            return s < 500
        } else {
            return 0 === 0
        }
    }
    return (
        <div height="100%" width="100%" style={{backgroundColor: "#fff"}} className="row">
            <LoadPanelComponent
                shadingColor="rgba(0,0,0,0.4)"
                position={'center'}
                showPane={false}
                visible={isLoading}
                message=""
            />
            {
                descPopup && <Popup
                    visible={descPopup}
                    onHiding={() => setDescPopup(false)}
                    dragEnabled={false}
                    hideOnOutsideClick={true}
                    showTitle={true}
                    width={1000}
                    title={rowData?.code + " - " + rowData?.name}
                    // key={data.data.id}
                    // titleRender={renderTitle}
                    height={450}
                >
                    <TextArea
                        height={360}
                        value={desc}
                        readOnly={true}
                        //onValueChanged={(e)=>{ setCellValueDesc(null,e.value,rowData) }}
                    >
                    </TextArea>
                </Popup>
            }
            {
                orgDuty && orgDuty.length > 0 && <OrgDutyAccordion data={orgDuty}/>
            }
            <div className="row" style={{
                display: "flex",
                justifyContent: 'space-between',
                marginTop: (orgDuty && orgDuty.length > 0) ? 10 : 0
            }}>
                <div style={{width: 'calc(100%/2 - 5px)'}}>
                    <SelectBox
                        dataSource={agendaList}
                        label={'Хөтөлбөр'}
                        placeholder="Хөтөлбөр сонгоно уу"
                        searchEnabled={true}
                        value={valueA1.current}
                        showClearButton={true}
                        displayExpr={(item) => {
                            return item && `${item.code.substring(0, 5)} - ${item.name}`
                        }}
                        onValueChanged={(e) =>
                            onValueChanged(
                                e,
                                agendaListAll.current,
                                setAgendaSubList,
                                setAgendaId,
                                valueA1
                            )
                        }
                        valueExpr="id"
                    />
                </div>

                {agendaSubList && agendaSubList.length > 0 && (
                    <div style={{width: 'calc(100%/2 - 5px)'}}>
                        {/*<div className="dx-field-label">Дэд хөтөлбөр</div>*/}
                        <SelectBox
                            dataSource={agendaSubList}
                            label={'Дэд хөтөлбөр'}
                            placeholder="Дэд хөтөлбөр сонгоно уу"
                            showClearButton={true}
                            value={valueA2.current}
                            displayExpr={(item) => {
                                return item && `${item.code.substring(0, 5)} - ${item.name}`
                            }}
                            onValueChanged={(e) =>
                                onValueChanged(e, [], null, setAgendaId, valueA2)
                            } // make sure sublist = null, origin list = []
                            valueExpr="id"
                        />
                    </div>
                )}
            </div>
            <div className="row" style={{justifyContent: 'space-between'}}>
                <div style={{width: 'calc(100%/2 - 5px)'}}>
                    <SelectBox
                        dataSource={eventList}
                        label={'Зориулалт'}
                        placeholder="Зориулалт сонгоно уу"
                        showClearButton={true}
                        value={valueE1.current}
                        displayExpr={(item) => {
                            return item && `${item.code.substring(0, 5)} - ${item.name}`
                        }}
                        onValueChanged={(e) =>
                            onValueChanged(
                                e,
                                eventListAll.current,
                                setEventSubList,
                                setEventId,
                                valueE1
                            )
                        }
                        valueExpr="id"
                    />
                </div>

                {eventSubList && eventSubList.length > 0 && (
                    <div style={{width: 'calc(100%/2 - 5px)'}}>
                        <SelectBox
                            dataSource={eventSubList}
                            label={'Арга хэмжээ'}
                            placeholder="Арга хэмжээ сонгоно уу"
                            showClearButton={true}
                            value={valueE2.current}
                            displayExpr={(item) => {
                                return item && `${item.code.substring(0, 5)} - ${item.name}`
                            }}
                            onValueChanged={(e) =>
                                onValueChanged(e, [], null, setEventId, valueE2)
                            }
                            valueExpr="id"
                        />
                    </div>
                )}
            </div>
            <Tooltip
                target={"#proposalHB_Header1"}
                showEvent="mouseenter"
                hideEvent="mouseleave"
                hideOnOutsideClick={false}
                position="top"
            >
                <div style={{padding: "1em"}}>
                    {props.year - 1} оны хүлээгдэж байгаа гүйцэтгэл нь тухайн жилийн нийт гүйцэтгэлээс илүү болон
                    зөрүүтэй байх нь хуваарийн өөрчлөлт хийгдээгүй байж болно.
                </div>
            </Tooltip>
            <Tooltip
                target={"#proposalCalcEza"}
                showEvent="mouseenter"
                hideEvent="mouseleave"
                hideOnOutsideClick={false}
                position="top"
            >
                <div style={{padding: "1em"}}>
                    {props.year} оны ажил олгогчоос нийгмийн даатгалд төлөх шимтгэл бодох хувь хэмжээ харах,тохируулах
                </div>
            </Tooltip>
            <Tooltip
                target={"#proposalIsData"}
                showEvent="mouseenter"
                hideEvent="mouseleave"
                hideOnOutsideClick={false}
                position="top"
            >
                <div style={{padding: "1em"}}>
                    {props.year} оны төсвийн санал дээр оруулсан эдийн засгийн ангилалтай өгөгдлүүдийг шүүж харуулна
                </div>
            </Tooltip>
            <Tooltip
                target={"#proposalIsDataAll"}
                showEvent="mouseenter"
                hideEvent="mouseleave"
                hideOnOutsideClick={false}
                position="top"
            >
                <div style={{padding: "1em"}}>
                    Нийт эдийн засгийн ангилалуудыг харуулна
                </div>
            </Tooltip>
            {
                dataSource && dataSource.length > 0 && //(!popupCalc && !popupDetail && !showCalc) &&
                <div className="col-12" style={{marginTop: "1rem"}} id="wrapper" height="97%" width="100%">
                    <TreeList
                        ref={treeList}
                        height={fullscreen ? "95vh" : "75vh"}
                        width={"100%"}
                        id="dataGrid"
                        showColumnHeaders={true}
                        noDataText='Дата байхгүй байна.'
                        onCellPrepared={onCellPreparedHandler}
                        autoExpandAll={expanded}
                        expandedRowKeys={expandedRowKeys}
                        onOptionChanged={onOptionChanged}
                        wordWrapEnabled={true}
                        showBorders={true}
                        showColumnLines={true}
                        showRowLines={true}
                        dataSource={dataSource}
                        onToolbarPreparing={onToolbarPreparing}
                        keyExpr="id"
                        hoverStateEnabled={true}
                        parentIdExpr="parent_id"
                        //repaintChangesOnly={true}
                        allowColumnResizing={true}
                        allowColumnReordering={true}
                        onEditingStart={onEditingStart}
                        onEditorPreparing={onEditorPreparing}
                        onCellHoverChanged={handleOnCellHover}
                        columnResizingMode={'widget'}
                        onSaved={setData}
                        sorting={sortingText}
                        columnChooser={{
                            enabled: true,
                            mode: "select",
                            title: "Багана нуух,харуулах",
                            height: '800px',
                            tooltip: "Багана сонгох",
                            width: "300px",
                            position: {
                                my: "center top",
                                at: "center bottom",
                                of: ".dx-treelist-column-chooser-button"
                            }
                        }}
                    >
                        {canEdit.current && (
                            <Editing
                                mode="batch"
                                changes={editingChanges}
                                /*onChangesChange={(changes) => {
                                  setEditingChanges(changes);
                                }}*/
                                allowUpdating={props.canEditModal}
                                allowAdding={false}
                                allowDeleting={false}
                                confirmDelete={true}
                                useIcons={true}
                                selectTextOnEditStart={true}
                                texts={{
                                    addRow: "Төсөл нэмэх",
                                    cancelAllChanges: "Болих",
                                    cancelRowChanges: "Болих",
                                    confirmDeleteMessage: "Энэ мэдээллийг устгах уу?",
                                    confirmDeleteTitle: "Баталгаажуулах",
                                    deleteRow: "Устгах",
                                    editRow: "Өөрчлөх",
                                    saveAllChanges: "Хадгалах",
                                    saveRowChanges: "Хадгалах",
                                    undeleteRow: "Буцаах",
                                    validationCancelChanges: "Баталгаажуулах болих",
                                }}
                            />
                        )}
                        <Toolbar>
                            <Item>
                                <div>
                                    <ExpandCollapseButton/>
                                    <FullscreenButton/>
                                    <ShowCalcRender/>
                                    {props.agendaId && props.eventId ? <IsDataButton/> : null}
                                </div>
                            </Item>
                            <Item visible={canEdit.current} name="saveButton" widget="dxButton"
                                  options={{icon: "save", text: "Хадгалах"}}/>
                            <Item visible={canEdit.current} disabled={editingChanges.length === 0 ? true : false}>
                                <RevertRender/>
                            </Item>
                            <Item name="columnChooserButton" widget="dxButton" options={{icon: "columnchooser"}}/>
                            <Item name="searchPanel"/>
                            {/*<Item name="addRowButton" />
                            <Item name="deleteRow" />*/}
                        </Toolbar>


                        <Selection mode="single"/>
                        <SearchPanel visible={true} placeholder={"Хайх"}/>
                        <HeaderFilter visible={false} allowSearch={true} texts={{emptyValue: "Илэрц байхгүй байна "}}/>
                        <ColumnFixing enabled={true} texts={columnFixingText}/>
                        {/*<Column*/}
                        {/*  dataField="name"*/}
                        {/*  width={50}*/}
                        {/*  caption="Aнгилал"*/}
                        {/*  allowEditing={false}*/}
                        {/*>*/}
                        {/*  /!*<Lookup dataSource={economicCategoryArray.current} valueExpr="id" displayExpr="name" />*!/*/}

                        {/*</Column>*/}
                        {/*<Column*/}
                        {/*  dataField="code"*/}
                        {/*  caption="Код"*/}
                        {/*  allowEditing={false}*/}
                        {/*></Column>*/}
                        <Column
                            name="name"
                            dataField="name"
                            width={'450'}
                            caption="Эдийн засгийн ангилал"
                            calculateCellValue={(data) => {
                                return data && `${data.code} - ${data.name}`
                            }}
                            //cellRender={cellRenderEza}
                            showInColumnChooser={false}
                            allowEditing={false} allowFixing={true}
                            allowFiltering={true} fixed={true}
                        >
                            {/*<Lookup dataSource={economicCategoryArray.current} valueExpr="id" displayExpr="name" />*/}
                        </Column>
                        <Column caption={`${props.year}`} fixed={true}>
                            <Column
                                name="amount"
                                dataField="amount"
                                //format="#,##0.00"
                                alignment="right"
                                dataType="number"
                                caption={`ТШЗ санал`}
                                allowEditing={tszEditing}
                                setCellValue={setCellValueAmount}
                                allowFiltering={false}
                                editorOptions={textAreaOptions}
                                headerCellRender={renderAmountHeader}
                                width={120}
                                calculateDisplayValue={(data) => {
                                    return data.code.substring(0, 1) === '4' ? parseInt(data.amount) : `${numberWithCommas(data.amount)}`
                                }}
                            />
                            <Column
                                name="code"
                                dataField="code"
                                allowEditing={false}
                                allowFiltering={true}
                                visible={false}
                            />
                            <Column
                                name="ttz_amount"
                                dataField="ttz_amount"
                                dataType="number"
                                calculateDisplayValue={(data) => {
                                    return data.code.substring(0, 1) === '4' ? parseInt(data.ttz_amount) : `${numberWithCommas(data.ttz_amount)}`
                                }}
                                alignment="right"
                                caption={`ТТЗ санал`}
                                allowEditing={ttzEditing}
                                setCellValue={setCellValueAmountTTZ}
                                allowFiltering={false}
                                headerCellRender={renderTtzHeader}
                                width={120}
                                editorOptions={textAreaOptions}
                                cellRender={(e) => cellRender(e, 'ttz')}
                            />
                            <Column
                                name="tez_amount"
                                dataField="tez_amount"
                                dataType="number"
                                calculateDisplayValue={(data) => {
                                    return data.code.substring(0, 1) === '4' ? parseInt(data.tez_amount) : `${numberWithCommas(data.tez_amount)}`
                                }}
                                caption={`ТЕЗ санал`}
                                allowEditing={tezEditing}
                                // allowEditing={false}
                                setCellValue={setCellValueAmountTEZ}
                                allowFiltering={false}
                                headerCellRender={renderTezHeader}
                                width={120}
                                editorOptions={textAreaOptions}
                                cellRender={(e) => cellRender(e, 'tez')}
                            />
                        </Column>
                        <Column
                            allowResizing={true}
                            renderAsync={false}
                            width={"60px"}
                            name="file"
                            caption="Файл"
                            cellRender={fileUpload}
                            allowFiltering={false}
                        ></Column>
                        <Column
                            name="description"
                            dataField="description"
                            dataType="text"
                            caption="Тайлбар"
                            minWidth={'200'}
                            width={'auto'}
                            setCellValue={setCellValueDesc}
                            cellRender={descCellRender}
                            //editCellRender={onDescription}
                            allowFiltering={false}
                            editorOptions={{height: 200, placeholder: 'Тайлбар бичих', mode: 'text'}}
                        >
                            <CustomRule
                                type="custom"
                                message={"Тайлбар хэмжээ 500 тэмдэгтээс ихгүй байна"}
                                validationCallback={customValidationDesc}
                            />
                        </Column>
                        <Column caption={`${props.year - 3}`}
                                visible={sumYear2 === 0 ? false : true}
                        >
                            <Column
                                name="year2"
                                dataField="year2"
                                caption={`Гүйцэтгэл`}
                                calculateDisplayValue={(data) => {
                                    return data.code.substring(0, 1) === '4' ? parseInt(data.year2) : `${numberWithCommas(data.year2)}`
                                }}
                                width={'120'}
                                allowEditing={false}
                                allowFiltering={false}
                                //cellRender={(e) => cellRender(e, 'year2')}
                            />
                        </Column>
                        <Column caption={`${props.year - 2}`}
                                visible={sumYear1 === 0 ? false : true}
                        >
                            <Column
                                name="year1"
                                dataField="year1"
                                caption={`Гүйцэтгэл`}
                                width={'120'}
                                calculateDisplayValue={(data) => {
                                    return data.code.substring(0, 1) === '4' ? parseInt(data.year1) : `${numberWithCommas(data.year1)}`
                                }}
                                allowEditing={false}
                                allowFiltering={false}
                                //cellRender={(e) => cellRender(e, 'year1')}
                            />
                        </Column>
                        <Column caption={`${props.year - 1}`}
                                visible={sumYear0 === 0 && sumYear103 === 0 && sumYear100 === 0 ? false : true}>
                            <Column
                                name="year0"
                                dataField="year0"
                                caption={`Төсөв`}
                                calculateDisplayValue={(data) => {
                                    return data.code.substring(0, 1) === '4' ? parseInt(data.year0) : `${numberWithCommas(data.year0)}`
                                }}
                                width={'120'}
                                allowEditing={false}
                                allowFiltering={false}
                                //cellRender={(e) => cellRender(e, 'year0')}
                                // visible={sumYear0 === 0 ? false : true}
                            />
                            <Column
                                name="year103"
                                dataField="year103"
                                caption={`ХБГ`}
                                calculateDisplayValue={(data) => {
                                    return data.code.substring(0, 1) === '4' ? parseInt(data.year103) : `${numberWithCommas(data.year103)}`
                                }}
                                width={'120'}
                                allowEditing={false}
                                allowFiltering={false}
                                headerCellRender={renderHBHeader}
                                // visible={sumYear103 === 0 ? false : true}
                            />
                            <Column
                                name="year100"
                                dataField="year100"
                                caption={`ТШЗ санал`}
                                width={'120'}
                                calculateDisplayValue={(data) => {
                                    return data.code.substring(0, 1) === '4' ? parseInt(data.year100) : `${numberWithCommas(data.year100)}`
                                }}
                                allowEditing={false}
                                allowFiltering={false}
                                //cellRender={(e) =>cellRender(e,'year0')}
                                // visible={sumYear100 === 0 ? false : true}
                            />
                            <Column
                                name="year101"
                                dataField="year101"
                                caption={`ТТЗ санал`}
                                width={'120'}
                                calculateDisplayValue={(data) => {
                                    return data.code.substring(0, 1) === '4' ? parseInt(data.year101) : `${numberWithCommas(data.year101)}`
                                }}
                                allowEditing={false}
                                allowFiltering={false}
                                //cellRender={(e) =>cellRender(e,'year0')}
                                visible={false}
                            />
                            <Column
                                name="year102"
                                dataField="year102"
                                caption={`ТЕЗ санал`}
                                width={'120'}
                                calculateDisplayValue={(data) => {
                                    return data.code.substring(0, 1) === '4' ? parseInt(data.year102) : `${numberWithCommas(data.year102)}`
                                }}
                                allowEditing={false}
                                allowFiltering={false}
                                //cellRender={(e) =>cellRender(e,'year0')}
                                visible={false}
                            />
                        </Column>

                        <Column
                            name="year3"
                            dataField="year3"
                            caption={`${props.year} оны хязгаар`}
                            calculateDisplayValue={(data) => {
                                return data.code.substring(0, 1) === '4' ? parseInt(data.year3) : `${numberWithCommas(data.year3)}`
                            }}
                            allowEditing={false}
                            allowFiltering={false}
                            width={'120'}
                            //cellRender={(e) => cellRender(e, 'year3')}
                            visible={sumYear3 === 0 ? false : true}
                        />
                        <Column
                            name="year4"
                            dataField="year4"
                            caption={`${props.year + 1} оны төсөөлөл`}
                            calculateDisplayValue={(data) => {
                                return data.code.substring(0, 1) === '4' ? parseInt(data.year4) : `${numberWithCommas(data.year4)}`
                            }}
                            allowEditing={false}
                            allowFiltering={false}
                            width={'120'}
                            //cellRender={(e) => cellRender(e, 'year4')}
                            visible={sumYear4 === 0 ? false : true}
                        />
                        <Column
                            name="year5"
                            dataField="year5"
                            caption={`${props.year + 2} оны төсөөлөл`}
                            calculateDisplayValue={(data) => {
                                return data.code.substring(0, 1) === '4' ? parseInt(data.year5) : `${numberWithCommas(data.year5)}`
                            }}
                            allowEditing={false}
                            allowFiltering={false}
                            width={'120'}
                            //cellRender={(e) => cellRender(e, 'year5')}
                            visible={sumYear5 === 0 ? false : true}
                        />
                        <Column caption="" name={'1'} type="buttons" width={80} showInColumnChooser={false}>
                            <TreeButton icon="chart" text="Бүх жилээр төсвийн график харах" onClick={onChartDetail}/>
                            <TreeButton icon="datafield" visible={setVisibleCalc} text="Төсвийн тооцоолол харах"
                                        onClick={onCalcDetail}/>
                        </Column>

                        <Scrolling useNative={false}
                                   scrollByContent={true}
                                   scrollByThumb={true}
                                   showScrollbar="onHover"/>
                        {/*<Paging enabled={true} />*/}
                    </TreeList>
                </div>
            }
            <div>
                {popupVisible && <Popup
                    visible={popupVisible}
                    onHiding={() => visible("false")}
                    dragEnabled={false}
                    hideOnOutsideClick={true}
                    showTitle={true}
                    title={`${rowData?.code} - ${rowData?.name}  Хавсралт файлууд`}
                    width={1000}
                    // key={data.data.id}
                    // titleRender={renderTitle}
                    height={500}
                >
                    <ScrollView>
                        <div>
                            <div>
                                {canEdit.current && (
                                    <div>
                                        <div>
                                            {/* <FileUploader

                    multiple={false}
                    // uploadMode="instantly"
                    accept="*"
                    // key={currentProposal.detail_id}
                    // id={currentProposal.code}
                    uploadFailedMessage=""
                    selectButtonText="Оруулах"
                    labelText=""
                    onValueChanged={onSelectedFilesChanged}
                    showFileList={true}
                    maxFileSize={15000000}
                  /> */}
                                            <input type="file" name="files" style={{display: "none"}}
                                                   ref={inputFilesRef} multiple onChange={(e) => {
                                                onSelectedFilesChanged({value: e.target.files})
                                            }}/>
                                            <Button text="Файл хавсаргах" icon="attach" onClick={() => {
                                                inputFilesRef.current.click()
                                            }} size={"2em"}/>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div style={{width: "99.9%", marginTop: "0.6rem"}}>
                                <hr
                                    style={{
                                        border: 0,
                                        height: "1px",
                                        background: "#1453B5",
                                        backgroundImage:
                                            "linear-gradient(to right, #ccc, #333, #ccc)",
                                    }}
                                />
                            </div>
                        </div>
                        <div>
                            <DataGrid
                                dataSource={fileArray}
                                noDataText="Одоогоор файл хавсаргаагүй байна"
                                style={{
                                    fontFamily: "'Montserrat', sans-serif",
                                    fontWeight: "normal",
                                }}
                                ref={fileGridRef}
                            >
                                <LoadPanel text={"Ачааллаж байна"} indicatorSrc={loader}/>
                                <Column
                                    caption="№"
                                    alignment="center"
                                    width={'5em'}
                                    cellRender={(row) => {
                                        return `${row.rowIndex + 1}`
                                    }}
                                />
                                <Column
                                    caption="Файлын нэр"
                                    dataField="file_path"
                                    cellRender={(row) => {
                                        const index = row.data?.file_path?.indexOf("-")
                                        const result = row.data?.file_path?.substring(
                                            index + 1,
                                            // row.data?.file_path?.lastIndexOf(".")
                                        )
                                        return result
                                    }
                                    }
                                />
                                <Column
                                    caption="Огноо"
                                    dataField="updatedAt"
                                    cellRender={(data) => {
                                        return moment(data.updatedAt).format(
                                            "YYYY-MM-DD HH:mm:ss"
                                        )
                                    }}
                                />
                                <Column
                                    width={"10em"}
                                    caption="Үйлдэл"
                                    dataField="file_path"
                                    cellRender={fileActionRender}
                                />
                            </DataGrid>
                        </div>
                    </ScrollView>
                </Popup>
                }
            </div>
            {popupDetail && renderDetail()}
            {showCalc && renderCalcEza()}
            {popupCalc && renderPopupCalculation()}
        </div>
    );
}

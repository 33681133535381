import React, {useState, useEffect} from "react";
import DataGrid, {Column, HeaderFilter, Paging, Summary, TotalItem,Selection,Toolbar,Item} from "devextreme-react/data-grid";
import notify from "devextreme/ui/notify";
import FinanceReportService from "../../../../services/api/financeReport";
import {numberWithCommas} from "../../../../util";
import LoadPanelComponent from "../../../../components/LoadPanelComponent";
import { Button } from 'devextreme-react/button';
import _ from "lodash";
import EmptyData from "../../../budget/components/empty";
const PayrollWithOrgs = (props) => {
    const [dataSrc, setDataSrc] = useState([]);
    const [loader, setLoader] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [sumVal, setSumVal] = useState(0);
    const loadYamsData = async () => {
        try {
            setLoader(true);
            let r = await FinanceReportService.getByOrgPayrollData(props.year, props.filterOrgText,props.selectedMonth);
            let pp = _(r)
                .groupBy('name')
                .map((item, key) => {
                    return {
                        name: key,
                        total_salary: _.sumBy(item,"total_salary")
                    }
                })
                .value();
            let sum = _.sumBy(pp, 'total_salary');
            setSumVal(sum);
            setDataSrc(r);
            setLoader(false);
        } catch (e) {
            setLoader(false);
            notify(e.message, "error", 2000);
        }
    }

    const cellTemp = (cellElement, cellInfo) => {
        return cellElement.append(cellInfo.row.loadIndex + 1);
    }
    const onSelectionChanged = (e) => {
        setSelectedRowKeys(e.selectedRowKeys);
        props.selectedOrg(e.selectedRowsData[0]);
    }
    useEffect(() => {
        loadYamsData();
    }, [props.year, props.filterOrgText,props.selectedMonth])
    const onClearButtonClicked =()=> {
        setSelectedRowKeys([]);
        props.selectedOrg(undefined);
       // this.dataGrid.instance.clearSelection();
    }
    const onCellPreparedHandler = (e) => {
        if (e.rowType === 'header') {
            e.cellElement.wordWrapEnabled =true;
            e.cellElement.style.color = "#15186A"
            e.cellElement.style.backgroundColor= "#E3E7F1"
           //e.cellElement.style.backgroundImage = `linear-gradient(to right, ${palettePayrollReport[0]}, ${palettePayrollReport[1]})`
        }
        if (e.rowType === 'data') {
            e.cellElement.style.color = "#15186A"
        }
    }
    const calcCellVal = (e) => {
        return ((e.total_salary * 100) / sumVal).toFixed(2)
    }
  /*  const onContentReady=(e)=> {
        var filteredIcons = e.element.find('.dx-header-row .dx-header-filter:not(.dx-header-filter-empty)');
        _.each(filteredIcons, function(ind, item) {
            item.closest('td').css('background-color', 'green');
        });
        console.log(filteredIcons);
    }*/
    return (
        <div id="load1">
            <LoadPanelComponent
                position={{of: '#load1'}}
                visible={loader}
            />
            {(dataSrc && !loader) ? (
            <DataGrid
                showBorders={true}
                rowAlternationEnabled={true}
                dataSource={dataSrc}
                noDataText ={'Дата байхгүй байна.'}
                showRowLines={true}
                showColumnLines={true}
                showColumnHeaders={true}
                allowColumnResizing={true}
                onSelectionChanged={onSelectionChanged}
                onCellPrepared={onCellPreparedHandler}
                wordWrapEnabled={true}
                style={{ fontFamily: "'Montserrat', sans-serif", fontWeight: 'bold' }}
            >
                <Paging defaultPageSize={9}/>
                <HeaderFilter visible={true}/>
                <Selection mode="single"/>
                <Column caption="№" allowSorting={false} cellTemplate={cellTemp} alignment="center" width={40}/>
                <Column dataField="name" allowSorting={false} caption="Нэр" alignment="left" minWidth={100}/>
                <Column dataField="total_salary" allowSorting={true} format="#,##0.00" defaultSortOrder="desc" alignment="center" caption="Нийт цалин нэмэгдэл" allowFiltering={false} width={150}/>
                <Column caption="Эзлэх %" allowSorting={false} calculateCellValue={calcCellVal} alignment="center"
                        //cellRender={PercentCell}
                        cssClass="bullet" width={100}/>
                <Column  dataField="send_date" caption="Илгээсэн огноо" allowSorting={false} alignment="center" width={150}/>
                <Column  dataField="last_date" caption="Шинэчилсэн огноо" allowSorting={false} alignment="center" width={150}/>
                <Summary>
                    <TotalItem
                        column="total_salary"
                        summaryType="sum"
                        customizeText={(data) => {return `${numberWithCommas(data.value)}`}}
                    />
                </Summary>
                <Toolbar>
                    <Item location="before">
                        <Button
                            disabled={!selectedRowKeys.length}
                            onClick={onClearButtonClicked}
                            type={'success'}
                            style={{
                                flex: 1,
                                color: '#fff', textAlign: "center",family: "'Montserrat', sans-serif",
                                backgroundColor: `#4468E2`,
                                borderRadius: '10px'
                            }}
                            icon={selectedRowKeys.length>0 ? 'clear' : 'check'}
                            text={selectedRowKeys.length>0 ? "Сонгосон байгууллага арилгах" : "Доорх жагсаалтаас байгууллага сонгох"}
                        />
                    </Item>
                </Toolbar>
            </DataGrid>
                ):
                <div style={{display: "flex", justifyContent: "center", alignItems: "center", family: "'Montserrat', sans-serif" }}>
                    <EmptyData/>
                </div>
            }
        </div>
    )
}

export default PayrollWithOrgs;

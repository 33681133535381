import "./static/portal.scss"
import {useState} from "react"
import OrganizationSelectComponent from "../../components/OrganizationSelectComponent"
import {useContext} from "react"
import {UserContext} from "../../hooks/UserContext"
import PagesManager from "./components/PagesManager"
import MediaManager from "./components/MediaManager"
import styled from "styled-components"

export const CustomTabPanel = styled.div`
  display: flex;
  margin: 0 1rem;
  .tab {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 0.875rem 0.5rem;
    background-color: #f8f9fb;
    transition: 0.3s all ease-in;
    border: 1px solid transparent;
    &:first-child{
      border-top-left-radius: 15px;
    }
    &:nth-child(2){
      border-top-right-radius: 15px;
    }
    .title {
      font-size: 1rem;
      font-weight: 700;
    }
    &.active {
      background-color: white;
      border: 1px solid #eff0f3;
    }
    &:hover {
      background-color: white;
      border: 1px solid #eff0f3;
    }
  }
`
const Home = () => {
  const {user} = useContext(UserContext)
  const [orgId, setOrgId] = useState(user.orgId)
  const [selectedIndex, setSelectedIndex] = useState(0)

  const handleSelect = (e) => {
    setOrgId(e.orgId[0])
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          padding: "0 1rem",
        }}
      >
        <span style={{fontWeight: 700}}>Байгууллага: </span>
        <OrganizationSelectComponent
          selectedData={handleSelect}
          selectedYear={(d) => {}}
          showClearButton={false}
          hideLabel={true}
          defaultId={user.org_id}
          showYear={false}
        />
      </div>
      <CustomTabPanel>
        {["Цэс удирдах", "Баннер удирдах"].map((item, index) => (
          <div
            key={index}
            className={`tab ${selectedIndex === index && "active"}`}
            onClick={() => setSelectedIndex(index)}
          >
            <span className="title">{item}</span>
          </div>
        ))}
      </CustomTabPanel>
      {selectedIndex === 0 && <PagesManager orgId={orgId} />}
      {selectedIndex === 1 && <MediaManager orgId={orgId} />}
    </>
  )
}

export default Home

import request from '../../shared/lib/request'

// function getUser(userId) {
//     return request({
//         url:    `/users/chatInfo/${userId}`,
//         method: `GET`
//     })
// }

function findUser(name) {
    return request({
        url:    `/users/find`,
        method: `GET`
    })
}

function userSubscription(subscription) {
    return request({
        url:    `/users/subscription`,
        data:   subscription,
        method: `POST`
    })
}

const getLastLoggedIn = async () => {
    return request({
        url: '/users/LastLoggedIn',
        method: 'GET'
    })
}

const checkResetEmail = async (data) => {
    return request({
        url: `/users/checkResetEmail`,
        method: 'POST',
        data: data
    })
}


function approveTermsConditions(isChecked) {
    return request({
      url: `/users/approveTermsConditions`,
      method: 'POST',
      data: { approved: isChecked }
    });
  };
  
  function approvePrivacyPolicy(isChecked) {
    return request({
      url: '/users/approvePrivacyPolicy',
      method: 'POST',
      data: { approved: isChecked }
    });
  };

const checkOrgUserCount = async (orgId) => {
    return request({
        url: `/users/checkOrgUserCount/${orgId}`,
        method: 'GET'
    })
}

const UserService = {
    // getUser,
    findUser,
    getLastLoggedIn,
    userSubscription,
    checkResetEmail,
    approveTermsConditions,
    approvePrivacyPolicy,
    checkOrgUserCount
}

export default UserService;


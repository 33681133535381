import React, {useState, useEffect, useRef, useContext} from "react";
import notify from "devextreme/ui/notify";
import lawServices from "../../../services/api/law";
import DataGrid, {
    Column,
    Editing,
    Lookup,
    Paging,
    RequiredRule,
    FilterRow, Form, Item, Popup as GridPopup, GroupPanel, SearchPanel, Grouping, ColumnChooser,
} from "devextreme-react/data-grid";
import LoadPanelComponent from "../../../components/LoadPanelComponent";
import {Template} from "devextreme-react/core/template";
import FileUploader from "devextreme-react/file-uploader";
import _ from "lodash";
import FileServices from "../../../services/api/file";
import {urlServer} from "../../../shared/lib/request";
import strategy_icon from "../../../assets/imgs/structureIcon2.png";
import Button from "devextreme-react/button";
import {Popup} from "devextreme-react/popup";
import ScrollView from "devextreme-react/scroll-view";
import orgPositionServices from "../../../services/api/orgPosition";
import {UserContext} from "../../../hooks/UserContext";
import OrganizationEditCellComponent from "../../../components/OrganizationEditCellComponent";

const LawControl = (props) => {
    const myBabyRef = useRef(null);
    const {user} = useContext(UserContext);
    const [dataSource, setDataSource] = useState([]);
    const [docTypes, setDocTypes] = useState([]);
    const [loader, setLoader] = useState(false);
    const [dame, setDame] = useState([]);
    const ImageFileRef = useRef(null);
    // const requireFileType = useRef({});
    const lastFocusedRow = useRef({});
    // const [requireFileType, setRequireFileType] = useState(false)
    const currentRowChanges = useRef({});
    const [currentLawId, setCurrentLawId] = useState(null);
    const [statArr, setStatArr] = useState([
        {
            id: 1,
            name: 'Тийм'
        },
        {
            id: 0,
            name: 'Үгүй'
        },
    ]);
    const [fileTypeData, setFileTypeData] = useState([]);
    const allowedFileExtensions = ['.jpeg', '.jpg', '.png', '.pdf'];
    const [filePopUpModal, setFilePopUpModal] = useState(false);
    const [fileExtention, setFileExtention] = useState(null);
    const [fileName, setFileName] = useState(null);
    const [pageIndex, setPageIndex] = useState(0);
    const loadDataSource = async () => {
        try {
            setLoader(true);
            setPageIndex(0);
            let r = await lawServices.loadLaws(props.selectedData);
            setLoader(false)
            setDataSource(r.data);
        } catch (e) {
            setLoader(false)
            console.log('Aldaa', e.error);
        }
    }

    const loadDocTypes = async () => {
        try {
            let r = await lawServices.loadLawDocs();
            // let m = await lawServices.getLawSourcedOrgs();
            let m = await orgPositionServices.getOrganizationList();

            let result = m.data || [];
            if (result.length === 1) {
                result[0].parent = null;
                // setDisabled(true)
            } else {
                let obt_id = user && user.organization && user.organization.obt_id;
                if (obt_id === 2) {
                    result.filter(data => data.code === '')
                        .forEach(data => data.parent = null)
                }
            }

            // console.log('getLawSourcedOrgs',m.data, result)
            let t = await lawServices.getFileType();
            setDame(result);
            setDocTypes(r);

            setFileTypeData(t.data)
            // console.log("fileTypeData: ",fileTypeData)
        } catch (e) {
            console.log('Aldaa', e.error);
        }
    }

    useEffect(() => {
        //     console.log(props.selectedData.orgId, props.selectedData)
        loadDataSource();
        loadDocTypes();
    }, [props.selectedData.orgId])

    // const onRowRemove = async (e) => {
    //     console.log("removing row: ",e)
    //     console.log("e changes", e.changes[0])
    //     try {
    //         if(e.data.id){

    //         }
    //     }
    //     catch(e){
    //         notify("Устгах явцад алдаа гарлаа","error",2000)
    //     }
    // }

    const onEditingStart = (e) => {
        // console.log("fileref change length=0: ", ImageFileRef)
        // console.log("focused e: ", e)
        let changes = [{}]
        changes[0].type = "update"
        changes[0].data = e.data
        currentRowChanges.current = {changes: changes}
        // lastFocusedRow.current = e.row.data
    }

    // const setFileType = (newData, value, currentRowData) =>{
    //     console.log("newData: ",newData)
    //     console.log("value: ",value)
    //     console.log("currentRowData: ",currentRowData)
    //     newData.file_type_id = value
    //     console.log("modified newData: ",newData)
    //     lastFocusedRow.current.file_type_id = value
    //     console.log("lastFocusedRow", lastFocusedRow.current)
    // }

    // const checkFileType = () => {
    //     console.log("checking file type", lastFocusedRow.current)
    //     if(lastFocusedRow.current.file_type_id===undefined || !lastFocusedRow.current.file_type_id)
    //         notify("select file type content ready","warning",2000)
    // }

    const handleSubmit = async (event) => {
        // let e = event;
        // if(event.changes.length==0){
        // console.log("event length equalss 0")
        // console.log("currentRowChanges", currentRowChanges)
        // e = currentRowChanges
        // console.log("e", e)
        // }
        // console.log("handling submit data",e.changes[0].data)
        // console.log("handling submit key",e.changes[0].key)
        //  if(ImageFileRef.current) e.changes[0].data.file_id = 0;
        //   console.log('e ', e);
        //console.log('e.changes[0] ', e.changes);
        const e = event.changes.length == 0 ? currentRowChanges.current : event
        try {
            setLoader(true);
            if (e.changes && e.changes.length !== 0) {
                if (e.changes[0].type !== "remove") {
                    try {
                        const result = _.filter(dame, {id: e.changes[0].data.org_id});
                        e.changes[0].data.start_of_ancestry = result[0].start_of_ancestry;
                        if (e.changes[0].data.law_doc_type !== 12) {
                            e.changes[0].data.description = ""
                        }
                        const res = await lawServices.addLaw(e.changes[0].data,e.changes[0].type);
                        let file_id = e.changes[0].data.file_id;
                        {
                          ImageFileRef && ImageFileRef.current !== null && await saveFile(e.changes[0].type == 'update' ? e.changes[0].data.id : res.data.id, e.changes[0].type == 'update' ? e.changes[0].data.file_type_id : e.changes[0].data.file_type_id, file_id);
                            //console.log("saveFile resp;", resp)
                        }


                        await loadDataSource();
                        ImageFileRef.current = null
                        notify('Амжилттай хадгалагдлаа', "success", 2000);
                        setLoader(false);
                    } catch (e) {
                        notify('Засварлалтыг хадгалах явцад алдаа гарлаа', "error", 2000);
                        setLoader(false);
                    }
                } else if (e.changes[0].type === "remove") {
                    await lawServices.deleteLaw(e.changes[0].key);
                    setLoader(false)
                    notify("Амжилттай устгалаа", "success", 2000)
                }
            }
            setLoader(false);
        } catch (e) {
            setLoader(false);
            notify(e.error, 'error', 2000);
        }
    }
    const columnInfo = (data) => {
        setFilePopUpModal(true);
        setFileName(data.data.file_name);
        setFileExtention(data.data.file_extention)
    }
    const renderGridCell = (data) => {
        return data.text && <a href={data.text} target='_blank' rel='noopener noreferrer'>Линк</a>;
    }
    const renderGridFile = (data) => {
        return data.text && <div style={{textAlign: 'center'}}>
            <Button
                //  style={{width: "100px", height: '25px'}}
                icon={strategy_icon}
                stylingMode="text"
                style={{
                    width: 'auto',
                    height: '35px',
                    opacity: 1
                }}
                onClick={() => {
                    columnInfo(data)
                }}
            />
        </div>
    }
    const ImageFile = (e) => {
        ImageFileRef.current = e.value;
        // setRequireFileType(true)
    }
    const Template_upload = () => {
        //  console.log(cellData);
        return (
            <div className="fileUploader">
                <FileUploader
                    allowedFileExtensions={allowedFileExtensions}
                    onValueChanged={ImageFile}
                    selectButtonText="Файл сонгох"
                    labelText=""
                    accept="*"
                    uploadMode="useForm"
                    // onValueChanged={()=>{notify("select file type content ready","warning",2000)}}
                    // onValueChanged={()=>{checkFileType()}}
                />
                <span className="note">{'Файлын өргөтгөл: '}
                    <span>.pdf .jpg, .jpeg, .png</span>
                </span>
            </div>
        );
    }
    const saveFile = async (lawId, typeId, fileId) => {
        if (ImageFileRef.current && lawId) {
            let data = new FormData();
            data.append("file", ImageFileRef.current[0]);
            const res = await FileServices.uploadFile(data, typeId);
            const fileData = {
                file_id: res.data.id,
                law_id: lawId,
                file_type_id: typeId
            }
            let orgFileId = await FileServices.setFileLaw(fileData);
            return orgFileId
        } else if (lawId) {
            // console.log("handling submit a: ",lawId, typeId, fileId)
            const updated_file = {
                id: fileId,
                file_type_id: typeId
            }
            await FileServices.updateFileType(updated_file).then((res) => {
                // console.log("updateFileType: ",res)
                return res;
            })
        } else return null
    }
    /*    const onRowUpdating = (e) => {
            console.log("onRowUpdating e",'eeee');
            e.newData.updatedAt = new Date();
        }*/

    const onInitNewRow = (e) => {
        e.data.org_id = user.org_id;
        e.data.status = 1;
    }
    const onCellPreparedHandler = (e) => {
        if (e.rowType === 'header') {
            e.cellElement.wordWrapEnabled = true;
            e.cellElement.style.fontFamily = 'Montserrat'
            e.cellElement.style.fontWeight = 'semibold'
            e.cellElement.style.color = "#15186A"
            e.cellElement.style.backgroundColor = "#EEF0F4"
        }
        if (e.rowType === 'data') {
            e.cellElement.style.fontFamily = 'Montserrat'
            e.cellElement.style.fontWeight = 'semibold'
            e.cellElement.style.color = "#000000"
        }
    }
    const handleToolbarPreparing = async (e) => {
        let addButton = e.toolbarOptions.items[0]
        let dataGrid = e.component;

        addButton = {
            ...addButton, showText: "always", options: {
                text: "Хууль нэмэх", type: "default", icon: 'add', onClick: function () {
                    dataGrid.addRow();
                }
            }
        }

        e.toolbarOptions.items[0] = addButton

    }
    const setStateValue = async (newData, value, currentRowData) => {newData.law_doc_type = value;}
    const setStateValueFileType = async (newData, value, currentRowData) => {newData.file_type_id = value;}
    const setStateValueCreatedAt = async (newData, value, currentRowData) => {newData.createdAt = value;}
    const customizeItem = (item) => {
        const dataGrid = myBabyRef.current.instance;
        const editRowKey = dataGrid.option('editing.editRowKey');
        const rowIndex = dataGrid.getRowIndexByKey(editRowKey);
        const changeDoctype = dataGrid.cellValue(rowIndex, "law_doc_type");
        const changeFileType = dataGrid.cellValue(rowIndex, "file_type_id");
        const changeCreatedAt = dataGrid.cellValue(rowIndex, "createdAt");
        if (
            item
            && changeDoctype === 12
        ) {
            if (item.dataField === "description") item.visible = true;
        }
        if (
            item
            && changeFileType
        ) {
            if (item.dataField === "file_name") item.visible = true;
        }
        if (
            item
            && changeCreatedAt
        ) {
            if (item.dataField === "createdAt") item.visible = true;
        }

    }
    const orderRender = (data) => {
        return <div style={{color: "#171010"}}>{pageIndex * 20 + data.rowIndex + 1}</div>;
    }
    return (
        <div className="card"
             style={{display: 'flex', flex: 1, padding: '10px', flexDirection: "column", alignItems: "center"}}
             id="loadThisLaw">
            {/*  <h3 className="html-editor-title">Хууль, эрх зүй</h3>*/}
            <LoadPanelComponent
                position={{of: '#loadThisLaw'}}
                visible={loader}
                message='Түр хүлээнэ үү'
                shadingColor="rgba(0,0,0, 0.2)"
            />
            {fileName && filePopUpModal &&
                <Popup
                    width={1200}
                    maxWidth={'100%'}
                    minHeight={'100%'}
                    height={'100%'}
                    title={fileName && fileName}
                    visible={filePopUpModal}
                    onHiding={() => {
                        setFilePopUpModal(false)
                    }}
                    dragEnabled={false}
                    hideOnOutsideClick={true}
                >
                    {
                        fileExtention == 'application/pdf' ? <object width="100%" height="100%"
                                                                     data={urlServer + '/api/file/documents/' + fileName}
                                                                     type="application/pdf"/>
                            :
                            <ScrollView> <img
                                src={urlServer + '/api/file/documents/' + fileName}
                                width={'100%'}/> </ScrollView>

                    }

                </Popup>}
            {/* <ScrollView width='97%' height='100%'>*/}
            <DataGrid
                keyExpr="id"
                allowColumnResizing={true}
                showBorders={true}
                showColumnLines={true}
                showRowLines={true}
                onCellPrepared={onCellPreparedHandler}
                rowAlternationEnabled={false}
                dataSource={dataSource}
                onToolbarPreparing={handleToolbarPreparing}
                //wordWrapEnabled={true}
                onSaved={handleSubmit}
                onInitNewRow={onInitNewRow}
                ref={myBabyRef}
                // onFocusedCellChanged={onFocusedCellChanged}
                // onEditingStart={(e)=>{console.log("editing started",e)}}
                onEditingStart={onEditingStart}
                // onRowRemoved={onRowRemove}
                // wordWrapEnabled={true}
                //  onRowUpdating={onRowUpdating}
            >  <GroupPanel visible={true} emptyPanelText={"Хүссэн баганаа дээш зөөн бүлэглэх боломжтой"}/>
                <ColumnChooser
                    enabled={true} height={"400"}
                    emptyPanelText={"Энд нуух багана аа чирж оруулна уу"}
                    title={"Багана сонгох"}
                />
                <SearchPanel visible={true} placeholder={'Хайх'}/>
                <Grouping autoExpandAll={false}/>
                <FilterRow visible={true}/>
                <Paging enabled={true} defaultPageSize={20} onPageIndexChange={(e) => {
                    setPageIndex(e)
                }}/>
                {/* <Toolbar>
                    <Item name="addRowButton" showText="always" >
                    <DevExtremeButton
                style={{marginBottom: 10}}
                text="Мэдээ нэмэх"
                className="saveButton"
                type="default"
                
              />
                    </Item>
                </Toolbar> */}
                <Editing
                    mode="popup"
                    useIcons={true}
                    allowAdding={true}
                    allowEditing={true}
                    allowUpdating={true}
                    allowDeleting={true}
                    texts={{
                        cancelAllChanges: 'Болих бүгд',
                        cancelRowChanges: 'Болих',
                        confirmDeleteTitle: 'Баталгаажуулах',
                        confirmDeleteMessage: "Энэ бүртгэлийг устгах уу?",
                        deleteRow: 'Устгах',
                        editRow: 'Өөрчлөх',
                        saveAllChanges: 'Хадгалах бүгд',
                        saveRowChanges: 'Хадгалах',
                        undeleteRow: 'Буцаах',
                        validationCancelChanges: 'Баталгаажуулах болих',
                    }}>
                    <GridPopup title="Хууль удирдах" showTitle={true} width={1200} height={520}/>
                    <Form customizeItem={customizeItem}>
                        <Item itemType="group" colCount={2} colSpan={2}>
                            <Item dataField="law_no"/>
                            <Item dataField="org_id"/>
                            <Item dataField="name"/>
                            <Item dataField="law_doc_type"/>
                            <Item dataField="legal_url"/>
                            <Item dataField="description" visible={false}/>
                            <Item dataField="register_no"/>
                            <Item dataField="registration_date"/>
                            <Item dataField="submitted_date"/>
                            <Item dataField="follow_date"/>
                            <Item dataField="createdAt" visible={false}/>
                            <Item dataField="status"/>

                            <Item dataField="file_type_id"/>
                            <Item dataField="file_name" visible={false}/>
                        </Item>
                    </Form>
                </Editing>
                <Column
                    caption='№'
                    cssClass="custom"
                    alignment="center"
                    allowEditing={false}
                    width={50}
                    cellRender={orderRender}
                />
                <Column
                    dataField="org_id"
                    caption="Байгууллага"
                    alignment="center"
                    editCellComponent={OrganizationEditCellComponent}
                >
                    <Lookup
                        dataSource={dame}
                        valueExpr="id"
                        displayExpr="organization_name"
                    />
                </Column>
                <Column dataField="law_doc_type" caption="Хуулийн төрөл" setCellValue={setStateValue}
                        alignment="center">
                    <RequiredRule message={'Хуулийн төрөл сонгоно уу'}/>
                    <Lookup
                        dataSource={docTypes}
                        valueExpr="id"
                        displayExpr="name"

                    />
                </Column>
                <Column dataField="law_no" caption="Актын дугаар" alignment="center" width={100}/>
                <Column dataField="name" caption="Актын нэр" alignment="center">
                    <RequiredRule message={'Актын нэр оруулна уу'}/>
                </Column>

                <Column dataField="legal_url" caption="Хуулийн линк" alignment="center" width={100} cellRender={renderGridCell}/>
                <Column dataField="register_no" caption="Бүртгэлийн дугаар" width={100} alignment="center"/>
                <Column dataField="registration_date" caption="Бүртгэлийн огноо" dataType="date" visible={false}/>
                <Column dataField="submitted_date" caption="Баталсан огноо" dataType="date" visible={false}/>
                <Column dataField="follow_date" caption="Дагаж мөрдөх огноо" dataType="date" visible={false}/>
                <Column dataType="date" dataField="createdAt" caption="Системд оруулсан огноо" allowEditing={false}
                        defaultSortOrder="desc" setCellValue={setStateValueCreatedAt}/>
                <Column dataField="status" caption="Хүчинтэй эсэх" alignment="center" width={80}>
                    <Lookup dataSource={statArr} valueExpr="id" displayExpr="name"/>

                </Column>
                <Column dataField="description" caption="Нэр томьёоны тайлбар" alignment="center" width={80} visible={false}>


                </Column>
                <Column dataField="file_type_id" caption="Файлын төрөл" alignment="center" width={80}
                        setCellValue={setStateValueFileType}
                >
                    {/* <RequiredRule/> */}
                    <Lookup dataSource={fileTypeData} valueExpr="id" displayExpr="name"/>
                </Column>
                <Column
                    dataField="file_name"
                    caption="Файл"
                    editCellTemplate={"file_name"}
                    alignment="center"
                    width={50}
                    cellRender={renderGridFile}
                >
                    <Template name={"file_name"} render={Template_upload}/>
                </Column>
                <Column dataField="law_order" caption="Эрэмбэ" alignment="center" width={80} visible={false}/>
            </DataGrid>
            {/*    </ScrollView>*/}
        </div>

    )
}

export default LawControl;

import React, {useState, useEffect} from "react";
import notify from "devextreme/ui/notify";
import FinanceReportService from "../../../../services/api/financeReport";
import _ from "lodash";
import {numberWithCommas, paletteFinanceReport} from "../../../../util";
import Chart, {CommonSeriesSettings, Label, Legend, Series, Tooltip, ValueAxis} from "devextreme-react/chart";
import LoadPanelComponent from "../../../budget/components/loadPanel";
import EmptyData from "../../../budget/components/empty";

const ChartDetailByYear = (props) => {
    const [chartData, setChartData] = useState([]);
    const [loader, setLoader] = useState(false);
    const [sumTotalAsset, setSumTotalAsset] = useState(0);
    const loadData = async () => {
        try {
            setLoader(true);
            let r = await FinanceReportService.getChartDetailByYear(props.reportType,props.selectedOrg,props.acct, props.filterOrgText);
            let array= _.sortBy(r, (e)=>{return e.year})
            let sum = _.sumBy(r, 'amt');
            setSumTotalAsset(sum);
            setChartData(array);
            setLoader(false);
        } catch (e) {
            setLoader(false);
            notify(e.message, "error", 2000);
        }
    }

    useEffect(() => {
        loadData()
    }, [props.reportType, props.filterOrgText, props.selectedOrg,props.acct,props.acctName])

    const customizeTooltip = (arg) => {
        if (arg.seriesName === props.acctName + ' өөрчлөлт') {
            let r = _.find(chartData, {'year': (arg.argument * 1 - 1).toString()});
            let r1 = _.find(chartData, {'year': (arg.argument * 1).toString()});
            let q = (arg.value / (r && r.amt)).toFixed(2);
            let p = ((arg.value * 100 / (r && r.amt)) - 100).toFixed(2);
            let d = (arg.value - (r && r.amt));
            let f = d < 0 ? 'бага' : 'их';
            if (isFinite(q) && !isNaN(q)) {
                return {
                    text: `${arg.argumentText} он\nӨмнөх жилээс\n\n Зөрүү: ${numberWithCommas(d / 1000000)}\n Хувь: ${p}%\n Өөрчлөлт: ${q} дахин ${f}`
                }
            } else {
                return {
                    text: `${numberWithCommas(r1 && r1.amt/ 1000000)}`
                }
            }
        }
        else {
            return {
                text: `${arg.seriesName}: ${numberWithCommas(arg.value / 1000000)}`
            };
        }
    }

    const customizeValueAxisLabel = (e) => {
        return `${e.value / 1000000000}`
    }

    return (
        <div id="load11">
            <LoadPanelComponent position={{of: '#load11'}} visible={loader} />
            {chartData.length > 0 ? (
            <Chart
                palette={paletteFinanceReport}
                paletteExtensionMode="alternate"
                dataSource={chartData}
            >
                <CommonSeriesSettings
                    argumentField="year"
                    type="bar"
                >
                    <Label visible={false}/>
                </CommonSeriesSettings>
                <ValueAxis>
                    <Label customizeText={customizeValueAxisLabel}/>
                </ValueAxis>
                <Series valueField="amt" name={props.acctName} visible={sumTotalAsset !==0 ? true : false}/>
                <Series valueField="amt" name={props.acctName + ' өөрчлөлт'} color="red" type="spline" dashStyle="dash" visible={sumTotalAsset !==0 ? true : false}/>
                <Legend visible={true} verticalAlignment="bottom" horizontalAlignment="center" font={{ family: "'Montserrat', sans-serif" }}/>
                <Tooltip zIndex={100000} enabled={true} customizeTooltip={customizeTooltip} font={{ family: "'Montserrat', sans-serif" }} location="edge">
                </Tooltip>
            </Chart>
                ):
                <div style={{display: "flex", justifyContent: "center", alignItems: "center", family: "'Montserrat', sans-serif" }}>
                    <EmptyData/>
                </div>
            }
        </div>
    )
}

export default ChartDetailByYear;

import React, {useEffect, useState} from 'react';
import List from 'devextreme-react/list';
import Chart, {
    CommonSeriesSettings,
    Legend,
    Series,
    Label
} from 'devextreme-react/chart';
import {Format} from "devextreme-react/chart";
import OrganizationService from "../../../../services/api/organization";


function OrgTypeComponent(props){
    // console.log('props: ', props.location.search.substring(6,));
    const extension = props.location.search.substr(6,);
     // const [orgTypeId, setorgTypeId] = useState();

    const [orgData, setOrgData] = useState();
    const [extraData, setExtraData] = useState();
    const [select, setSelect] = useState();
    const [loading, setLoading] = useState(false);

    useEffect( () =>{
        try{
            getOrganizationSelect();
            getLoadOrgTypeDetails();
            // console.log('listawy',orgData)
            // console.log('extraaa',extraData)
            // console.log('chart',select)
        }
        catch (e){
            console.error(e)
        }}, [] )
    function itemInfo(item) {
        return (
            <div>{item.organization_name}</div>
        )
    }
    const getLoadOrgTypeDetails = async () => {
        try {
           const result = await OrganizationService.loadOrgTypeDetails(extension);
           await setOrgData(result.data || []);
           await setExtraData(result.data[0]);
           return console.log(extension)
        } catch (e) {
            console.error(e);
        }
    }
    const getOrganizationSelect =async () =>{
        try {
            setLoading(true);
            const result = await OrganizationService.organizationSelect(extension);
            await setSelect(result.data)
            setLoading(false);

        }
        catch(e) {
            console.error(e);
        }
    }

    const organizationSelect= async (e) =>{
        try {
            if (e.addedItems[0]){
                setLoading(true);
                const result = await OrganizationService.organizationSelect(e.addedItems[0].id);
                await setSelect(result.data)
                setLoading(false);
            }
        }catch (e) {
            console.error(e);
        }

    }


    return(
        <div className='row'>
            <div className='col-4'>
                <div className='card'>
                    <div className='cardBody' style={{textAlign:'left'}}>
                        <List
                            dataSource={orgData}
                            height={810}
                            selectionMode="single"
                            selectedItems={extraData}
                            activeStateEnabled={true}
                            hoverStateEnabled={true}
                            focusStateEnabled={true}
                            itemRender={itemInfo}
                            nextButtonText="Үргэлжлүүлэх"
                            onSelectionChanged={organizationSelect}
                        />
                    </div>
                </div>
            </div>
                <div class='col-8'>
                    <div className='card'>
                        <div className='cardBody' id='myChart'>
                            {select &&
                                <Chart
                                    title="Хөтөлбөр Зориулалт"
                                    palette="Soft Pastel"
                                    dataSource={select.chartData}
                                >
                                <CommonSeriesSettings
                                    type='bar'
                                    argumentField="name"
                                    hoverMode="allArgumentPoints"
                                    selectionMode="allArgumentPoints"
                                >
                                    <Label visibility={true}>
                                        <Format
                                            precision={0}
                                            type="fixedPoint"
                                        />
                                    </Label>
                                </CommonSeriesSettings>
                                <Series
                                    valueField="year2019"
                                    name="2019"
                                />
                                <Series
                                    valueField="year2020"
                                    name="2020"
                                />
                                <Series
                                    valueField="year2021"
                                    name="2021"
                                />
                                <Legend
                                    verticalAlignment="bottom"
                                    horizontalAlignment="center"
                                />
                            </Chart>
                            }

                        </div>
                    </div>
                </div>
        </div>
    )
}


export default OrgTypeComponent;



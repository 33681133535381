import React from "react"
import {FacebookShareButton, FacebookIcon, TwitterShareButton} from "react-share"
import {ImFacebook, ImTwitter} from "react-icons/im"

function SocialLinks({title, href}) {
  return (
    <div style={{display: "flex", gap: ".3rem", marginTop: "1rem"}}>
      <FacebookShareButton
        className="socialShare facebook"
        quote={title && title}
        url={`${window.location.href}`}
      >
        <ImFacebook className="icon" /> <span className="text">Хуваалцах</span>
      </FacebookShareButton>
      <TwitterShareButton
        className="socialShare twitter"
        quote={title && title}
        url={`${window.location.href}`}
      >
        <ImTwitter className="icon" /> <span className="text">Жиргэх</span>
      </TwitterShareButton>

    </div>
  )
}

export default SocialLinks

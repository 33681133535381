import { Accordion } from 'devextreme-react'
import React from 'react'
import { titleIcon } from '.'

const title = (data) => {
  return(
    <div style={{ display: 'flex'}}>{titleIcon(data.name)}
      <div style={{textTransform:'uppercase', alignSelf:'center', fontWeight:'bold', fontFamily:'Segoe UI', fontSize: ".875rem"}}>{data.description}
      </div>
    </div>
    )
}

const item = (data)=>{
  return data.policy_desc_array.map((policy_desc)=>{
    return( 
      <div className="card" style={{ padding:'1rem 2rem', flex: 1, fontFamily: 'Segoe UI', margin: 10 }}
      dangerouslySetInnerHTML={{
        __html: policy_desc 
      }}>
      </div>  
    )
  })
}

const OrgDutyAccordion = ({data}) => {
  // console.log("org duty accordion", data)
  return (
      <Accordion
      className='og-accordion'
      collapsible={true} 
      width={"inherit"}
      defaultSelectedIndex={-1}
      dataSource={data}
      animationDuration={300}
      noDataText={'Дата байхгүй байна'}
      style={{border:'solid thin #dddddd'}}
      itemTitleRender={title}
      itemRender={item}
        />
  )
}

export default OrgDutyAccordion
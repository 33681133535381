import React, { useState, useEffect, useContext } from "react";
import SelectBox from "devextreme-react/select-box";
import { getYears } from "../../util";
import EhUusverHeadInfo from "./components/eh-uusver/EhUusverHeadInfo";
import BudgetService from "../../services/api/budget";
import notify from "devextreme/ui/notify";
import EhUusverDataGrid from "./components/eh-uusver/EhUusverDataGrid";
import EhUusverJileer from "./components/eh-uusver/EhUusverJileer";
import EhUusverPieEza from "./components/eh-uusver/EhUusverPieEza";
import EhUusverMonthly from "./components/eh-uusver/EhUusverMonthly";
import { UserContext } from "../../hooks/UserContext";
import OrganizationService from "../../services/api/organization";
import UpdatedBudgetListWithOrgs from "./components/UpdatedBudgetListWithOrgs";
import SourceFooter from "./components/Source";
import { Button, Popup } from "devextreme-react";
import EmptyData from "../budget/components/empty";
import lawIcon from "../../assets/imgs/laaawIcon.svg";
import PlanGuide from "../../assets/imgs/Тайлан бэлдэх заавар аргачлал төсөл.pdf";
import { Accordion } from "devextreme-react";

const BudgetEhUusver2 = (props) => {
  const [yearList, setYearList] = useState([]);
  const { user } = useContext(UserContext);
  const [law, setLaw] = useState([]);
  const [showLawModal, setShowLawModal] = useState(false);
  const [showOrderModal, setShowOrderModal] = useState(false);
  const [rootData, setRootData] = useState([]);
  const [calculatedPercentage, setCalculatedPercentage] = useState(null);
  const [calculatedGuitestgelPercentage, setCalculatedGuitsetgelPercentage] =
    useState(null);
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [orgs, setOrgs] = useState([]);
  const [ministry, SetMinistry] = useState(null);
  const [chosenValue, setChosenValue] = useState("ALL");
  const [chosenMinistry, setChosenMinistry] = useState("ALL");
  const [firstChartVisible, setFirstChartVisible] = useState(false);
  const [whichComponentMaxmized, setWhichComponentMaximized] = useState(null);
  const rootDataSetter = (root, handler) => {
    if (handler) {
      if (
        root &&
        rootData &&
        ((root.todotgoson2 && rootData.todotgoson2) ||
          (root.todotgoson && rootData.todotgoson) ||
          (root.batlagdsan && rootData.batlagdsan))
      ) {
        if (root.guitsetgel && rootData.guitsetgel) {
          setCalculatedGuitsetgelPercentage(
            (root.guitsetgel * 100) / rootData.guitsetgel
          );
        } else {
          setCalculatedGuitsetgelPercentage(null);
        }
        let a = root.todotgoson2
          ? root.todotgoson2
          : root.todotgoson
          ? root.todotgoson
          : root.batlagdsan;
        let b = rootData.todotgoson2
          ? rootData.todotgoson2
          : rootData.todotgoson
          ? rootData.todotgoson
          : rootData.batlagdsan;
        setCalculatedPercentage((a * 100) / b);
      } else {
        setCalculatedPercentage("");
        setCalculatedGuitsetgelPercentage("");
      }
    } else {
      setCalculatedPercentage(null);
      setCalculatedGuitsetgelPercentage(null);
      setRootData(root);
    }
  };

  const loadOrgs = async (year) => {
    try {
      let r =
        user.username !== "02"
          ? await BudgetService.getFilteredOrganizations(year, 23)
          : chosenMinistry === "ALL"
          ? []
          : await BudgetService.getFilteredOrganizationsBySoa(
              year,
              chosenMinistry
            );
      setOrgs(
        r.length > 0 && r.length === 1
          ? r
          : [
              {
                budget_year: year,
                text_name: "БҮГД",
                value_name: "ALL",
              },
              ...r,
            ]
      );
      r.length > 1
        ? setChosenValue("ALL")
        : setChosenValue(r.length > 0 && r[0].value_name);
    } catch (e) {
      notify(e.message, "error", 2000);
    }
  };
  const loadMinistry = async () => {
    try {
      let result = await (await OrganizationService.getMinistryList()).data;
      // console.log("ministry result===>", result)
      SetMinistry([
        {
          budget_year: currentYear,
          name: "БҮГД",
          org_id: "ALL",
        },
        ...result,
      ]);
      setChosenValue("ALL");
    } catch (error) {
      notify(error.message, "error", 2000);
    }
  };
  const loadLaw = async () => {
    try {
      let r = await BudgetService.getBudgetLawByYear(currentYear - 1);
      setLaw(r);
      console.log("::::", r);
    } catch (e) {
      notify(e.message, "error", 2000);
    }
  };
  const accordionData = [{ id: 1, title: "", file: PlanGuide }];
  const renderItems = (e) => {
    return (
      <object
        width="100%"
        height="90vh"
        style={{ height: "90vh" }}
        data={e.file}
        type="application/pdf"
      />
    );
  };

  const renderTitles = (e) => {
    return <span>{e.title}</span>;
  };

  useEffect(() => {
    loadYearList();
    loadMinistry();
    // setChosenValue(orgs[0]["value_name"])
  }, []);

  const loadYearList = async () => {
    try {
      let result = await await BudgetService.getOrgBudgetYear();
      setYearList(result);
    } catch (error) {
      notify(error.message, "error", 2000);
    }
  };

  useEffect(() => {
    setChosenValue("ALL");
    loadOrgs(currentYear);
  }, [chosenMinistry]);
  useEffect(() => {
    loadLaw();
  }, [currentYear]);

  const popUpHandler = (trueFalser, value) => {
    setFirstChartVisible(trueFalser);
    setWhichComponentMaximized(value);
  };

  let maximizeIcon =
    '<svg t="1659165394467" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2309" width="16" height="16"><path d="M504.576 603.428571a19.748571 19.748571 0 0 1-5.705143 13.129143l-189.696 189.696 82.285714 82.285715a36.425143 36.425143 0 0 1 10.861715 25.709714c0 20.004571-16.566857 36.571429-36.571429 36.571428h-256c-20.004571 0-36.571429-16.566857-36.571428-36.571428v-256c0-20.004571 16.566857-36.571429 36.571428-36.571429a36.205714 36.205714 0 0 1 25.709714 10.861715l82.285715 82.285714 189.696-189.696c3.437714-3.437714 8.557714-5.705143 13.129143-5.705143s9.728 2.304 13.129142 5.705143l65.133715 65.133714a19.602286 19.602286 0 0 1 5.705143 13.129143zM950.857143 109.714286v256c0 20.004571-16.566857 36.571429-36.571429 36.571428a36.205714 36.205714 0 0 1-25.709714-10.861714l-82.285714-82.285714-189.696 189.696c-3.437714 3.437714-8.557714 5.705143-13.129143 5.705143s-9.728-2.304-13.129143-5.705143l-65.133714-65.133715c-3.437714-3.437714-5.705143-8.557714-5.705143-13.129142s2.304-9.728 5.705143-13.129143l189.696-189.696-82.285715-82.285715a36.425143 36.425143 0 0 1-10.861714-25.709714c0-20.004571 16.566857-36.571429 36.571429-36.571428h256c20.004571 0 36.571429 16.566857 36.571428 36.571428z" fill="#515151" p-id="2310"></path></svg>';

  return (
    <div>
      <Popup
        visible={firstChartVisible}
        onHiding={() => setFirstChartVisible(false)}
        hideOnOutsideClick={true}
        showCloseButton={true}
        height={550}
        title={
          whichComponentMaxmized && whichComponentMaxmized === 1
            ? "БАЙГУУЛЛАГААР /сая ₮/"
            : whichComponentMaxmized === 2
            ? "ТӨСӨВ, ГҮЙЦЭТГЭЛ /жилээр, сая ₮/"
            : whichComponentMaxmized === 3
            ? "ЗАРЛАГЫН БҮТЭЦ /сая ₮/"
            : "ТӨСӨВ, ГҮЙЦЭТГЭЛ /сараар, сая ₮/"
        }
        showTitle={true}
      >
        {whichComponentMaxmized === 1 &&
          (user.username !== "02" ? (
            <EhUusverDataGrid year={currentYear} chosenValue={chosenValue} />
          ) : chosenValue === "ALL" && chosenMinistry === "ALL" ? (
            <UpdatedBudgetListWithOrgs
              currentYear={currentYear}
              currentType={3}
            />
          ) : (
            <EhUusverDataGrid
              year={currentYear}
              chosenMinistry={chosenMinistry}
              chosenValue={chosenValue}
            />
          ))}
        {whichComponentMaxmized === 2 && (
          <EhUusverJileer
            chosenMinistry={chosenMinistry}
            chosenValue={chosenValue}
          />
        )}
        {whichComponentMaxmized === 3 && (
          <EhUusverPieEza
            year={currentYear}
            chosenMinistry={chosenMinistry}
            chosenValue={chosenValue}
          />
        )}
        {whichComponentMaxmized === 4 && (
          <EhUusverMonthly
            chosenMinistry={chosenMinistry}
            year={currentYear}
            chosenValue={chosenValue}
          />
        )}
      </Popup>
      <Popup
        visible={showLawModal}
        onHiding={() => setShowLawModal(false)}
        hideOnOutsideClick={true}
        showTitle={false}
      >
        <div style={{ height: "100%" }}>
          {law && law.length != 0 && showLawModal && (
            <iframe
              id="lawForBudget"
              width="100%"
              height="100%"
              src={law[0].legal_url}
            ></iframe>
          )}
        </div>
      </Popup>
      <Popup
        visible={showOrderModal}
        onHiding={() => setShowOrderModal(false)}
        hideOnOutsideClick={true}
        height="90vh"
        showTitle={false}
      >
        <Accordion
          dataSource={accordionData}
          collapsible={false}
          multiple={false}
          itemTitleRender={renderTitles}
          itemRender={renderItems}
        />
      </Popup>
      <div style={{ padding: 10 }}>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "1rem",
            alignItems: "center",
          }}
        >
          <div>
            <SelectBox
              items={yearList}
              valueExpr="value"
              displayExpr="value"
              label="Төсвийн жил"
              defaultValue={currentYear}
              width="10rem"
              onValueChanged={(e) => {
                setCurrentYear(e.value);
                loadOrgs(e.value);
              }}
              placeholder="сонгох"
              style={{ borderRadius: 10 }}
            />
          </div>
          {user.username === "02" && ministry && ministry.length !== 0 && (
            <div>
              <SelectBox
                items={ministry}
                label="Яам"
                defaultValue={chosenMinistry}
                width="15rem"
                valueExpr="org_id"
                displayExpr="name"
                onValueChanged={(e) => {
                  setChosenMinistry(e.value);
                  setChosenValue(() => "ALL");
                }}
                placeholder="сонгох"
                style={{ borderRadius: 10 }}
              />
            </div>
          )}
          <div>
            <SelectBox
              items={orgs}
              label="Байгууллага"
              width="22rem"
              value={chosenValue}
              disabled={orgs && orgs.length === 1 ? true : false}
              valueExpr="value_name"
              displayExpr="text_name"
              onValueChanged={(e) => setChosenValue(e.value)}
              placeholder={
                user.organization.obt_id !== 3 ? "БҮГД" : user.organization.name
              }
              style={{ borderRadius: 10 }}
            />
          </div>
          {law && law.length !== 0 && (
            <div style={{ height: 28.5 }}>
              <Button
                style={{ fontFamily: "Segoe UI", fontWeight: "bold" }}
                icon={lawIcon}
                onClick={() => setShowLawModal(true)}
                text={law[0].name}
              />
            </div>
          )}
        </div>
        <div
          style={{
            display: "flex",
            flex: 1,
            alignItems: "center",
            justifyContent: "space-between",
            gap: "1rem",
            paddingLeft: 10,
          }}
        >
          {chosenValue !== "ALL" && (
            <span>
              Сонгосон байгууллага:{" "}
              <span style={{ fontWeight: "bold" }}>{chosenValue} </span>
            </span>
          )}
          <div>
            {calculatedPercentage && (
              <span style={{ fontWeight: "bold" }}>
                Нийт төсвийн:{" "}
                <span style={{ color: "#ff0000" }}>
                  {calculatedPercentage.toFixed(2)}%
                </span>
              </span>
            )}
            {calculatedGuitestgelPercentage && (
              <span style={{ fontWeight: "bold" }}>
                Нийт гүйцэтгэлийн:{" "}
                <span style={{ color: "#264b96" }}>
                  {calculatedGuitestgelPercentage.toFixed(2)}%
                </span>
              </span>
            )}
          </div>
        </div>
      </div>
      {chosenMinistry === "ALL" ||
      chosenMinistry === 144 ||
      chosenMinistry === 260 ? (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <EhUusverHeadInfo
            chosenMinistry={chosenMinistry}
            year={currentYear}
            rootDataSetter={rootDataSetter}
            chosenValue={chosenValue}
          />
          <div
            style={{ flexDirection: "row", flex: 1 }}
            className="d-flex responsive-flex"
          >
            <div
              style={{ "margin-bottom": 10, flex: 0.5 }}
              className="col-6 responsive-col"
            >
              <div className="card" style={{ height: "100%" }}>
                <div className="card-header">
                  <div
                    style={{
                      flex: 1,
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "row",
                    }}
                  >
                    <span style={{ fontWeight: "700" }}>
                      1. БАЙГУУЛЛАГААР /сая ₮/
                    </span>
                    <Button
                      style={{
                        border: "0px solid transparent",
                        borderTopRightRadius: "15px",
                      }}
                      icon={maximizeIcon}
                      onClick={() => popUpHandler(true, 1)}
                    />
                  </div>
                </div>
                <div className="card-body">
                  {user.username !== "02" ? (
                    <EhUusverDataGrid
                      year={currentYear}
                      chosenValue={chosenValue}
                    />
                  ) : chosenValue === "ALL" && chosenMinistry === "ALL" ? (
                    <UpdatedBudgetListWithOrgs
                      currentYear={currentYear}
                      currentType={3}
                    />
                  ) : (
                    <EhUusverDataGrid
                      year={currentYear}
                      chosenMinistry={chosenMinistry}
                      chosenValue={chosenValue}
                    />
                  )}
                </div>
              </div>
            </div>
            <div
              style={{ "margin-bottom": 10, flex: 0.5 }}
              className="col-6 responsive-col"
            >
              <div className="card" style={{ height: "100%" }}>
                <div className="card-header">
                  <div
                    style={{
                      flex: 1,
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "row",
                    }}
                  >
                    <span style={{ fontWeight: "700" }}>
                      2. ТӨСӨВ, ГҮЙЦЭТГЭЛ /жилээр, сая ₮/
                    </span>
                    <Button
                      style={{
                        border: "0px solid transparent",
                        borderTopRightRadius: "15px",
                      }}
                      icon={maximizeIcon}
                      onClick={() => popUpHandler(true, 2)}
                    />
                  </div>
                </div>
                <div className="card-body">
                  <EhUusverJileer
                    chosenMinistry={chosenMinistry}
                    chosenValue={chosenValue}
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            style={{ flexDirection: "row", flex: 1 }}
            className="d-flex responsive-flex"
          >
            {!(
              chosenMinistry === "ALL" &&
              chosenValue === "ALL" &&
              user.username === "02"
            ) && (
              <div
                style={{ "margin-bottom": 10, flex: 0.5 }}
                className="col-6 responsive-col"
              >
                <div className="card" style={{ height: "100%" }}>
                  <div className="card-header">
                    <div
                      style={{
                        flex: 1,
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: "row",
                      }}
                    >
                      <span style={{ fontWeight: "700" }}>
                        3. ЗАРЛАГЫН БҮРДЭЛ /ЭЗА, сая ₮/
                      </span>
                      <Button
                        style={{
                          border: "0px solid transparent",
                          borderTopRightRadius: "15px",
                        }}
                        icon={maximizeIcon}
                        onClick={() => popUpHandler(true, 3)}
                      />
                    </div>
                  </div>
                  <div className="card-body">
                    <EhUusverPieEza
                      year={currentYear}
                      chosenMinistry={chosenMinistry}
                      chosenValue={chosenValue}
                    />
                  </div>
                </div>
              </div>
            )}
            {!(
              chosenMinistry === "ALL" &&
              chosenValue === "ALL" &&
              user.username === "02"
            ) && (
              <div
                style={{ "margin-bottom": 10, flex: 0.5 }}
                className="col-6 responsive-col"
              >
                <div className="card" style={{ height: "100%" }}>
                  <div className="card-header">
                    <div
                      style={{
                        flex: 1,
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: "row",
                      }}
                    >
                      <span style={{ fontWeight: "700" }}>
                        4. ТӨСӨВ, ГҮЙЦЭТГЭЛ /сараар, сая ₮/
                      </span>
                      <Button
                        style={{
                          border: "0px solid transparent",
                          borderTopRightRadius: "15px",
                        }}
                        icon={maximizeIcon}
                        onClick={() => popUpHandler(true, 4)}
                      />
                    </div>
                  </div>
                  <div className="card-body">
                    <EhUusverMonthly
                      chosenMinistry={chosenMinistry}
                      year={currentYear}
                      chosenValue={chosenValue}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            family: "Segoe UI",
          }}
        >
          <EmptyData />
        </div>
      )}
      <SourceFooter />
    </div>
  );
};

export default BudgetEhUusver2;

import { PieChart } from "devextreme-react"
import EmptyData from "../../../budget/components/empty"
import { Connector, Label, Legend, Series, Tooltip } from "devextreme-react/pie-chart"
import BudgetService from "../../../../services/api/budget"
import { useEffect, useState } from "react"
import notify from "devextreme/ui/notify"
import { numberWithCommas } from "../../../../util"
import _ from "lodash"
import { Export } from "devextreme-react/chart"

export const EZAType = ({ filteredData, type, budgetYear, investYear }) => {
    const [pieData, setPieData] = useState([])
    const [sumAmount, setSumAmount] = useState(0)
    const [objectNm, setObjectNm] = useState([])

    useEffect(() => {
        loadByTypeData()
    }, [type, budgetYear, investYear])

    const loadByTypeData = async () => {
        try {
            let res = await BudgetService.getInvestTypeCount(type, budgetYear, investYear);
            setPieData(res);
            setSumAmount(_.sumBy(res, e => e.current_years))
        } catch (e) {
            notify(e.message, 'error', 2000)
        }
    }

    useEffect(() => {
        const countedData = _.countBy(filteredData, 'objNm');
        const orderedData = _.orderBy(
            Object.entries(countedData),
            ([objNm]) => objNm,
            'asc'
        );
        const orderedObject = _.fromPairs(orderedData);
        setObjectNm(orderedObject);
    }, [filteredData]);

    const customizeText = (arg) => {
        return `${arg.valueText} төсөл - ${(arg.percent * 100).toFixed(2)}% 
        ${numberWithCommas(arg.point.data.current_years)} сая ₮ - ${numberWithCommas((parseFloat(arg.point.data.current_years)) * 100 / sumAmount)}%`;
    }

    const centerRender = (pieChart) => {
        let totalValue = pieChart.getAllSeries()[0].getAllPoints().reduce((s, p) => s + p.originalValue, 0);
        return (
            <text textAnchor="middle" style={{ fontWeight: 600, fontSize: 14 }}>
                <tspan x="0" dy="-1em">{totalValue}</tspan>
                <tspan x="0" dy="1em">нийт</tspan>
            </text>
        );
    }

    const customizeTooltip = (arg) => {
        return {
            html: `<span class="tooltip-series-name">${arg.argumentText}: </span> <br/>
            <hr/>
            ${(arg.value)} төсөл - ${(arg.percent * 100).toFixed(2)}% <br/>
            ${numberWithCommas(arg.point.data.current_years)} сая ₮ - ${numberWithCommas((arg.point.data.current_years) * 100 / sumAmount)}%
            `,
        }
    }

    return (
        <div>
            {pieData && pieData.length > 0 ?
                <div>
                    <PieChart
                        style={{ fontFamily: 'Segoe UI' }}
                        loadingIndicator={{ enabled: true, text: 'Уншиж байна...' }}
                        type="doughnut"
                        palette={['#4c5286', '#F93A3A', '#f5a2a4', '#0469FF']}
                        dataSource={pieData}
                        redrawOnResize={true}
                        resolveLabelOverlapping="shift"
                        centerRender={centerRender}
                        onLegendClick={({ points }) => points[0].isVisible() ? points[0].hide() : points[0].show()}
                    >
                        <Export enabled={true} />
                        <Legend
                            orientation="horizontal"
                            itemTextPosition="right"
                            horizontalAlignment="center"
                            verticalAlignment="bottom"
                        />
                        <Series
                            argumentField="ezNm"
                            valueField="count"
                            border={{ dashStyle: 'solid', width: 2, visible: true }}>
                            <Label visible={true} position="columns"
                                customizeText={customizeText}
                            >
                                <Connector visible={true} />
                            </Label>
                        </Series>
                        <Tooltip enabled={true}
                            zIndex={10000}
                            font={{ color: '#fff' }} color="#555"
                            customizeTooltip={customizeTooltip}
                            cornerRadius={6}
                            border={{ visible: false }}
                            location="edge" />
                    </PieChart>
                    <div style={{display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', paddingTop: 10 }}>
                        {Object.entries(objectNm).map(([key, value]) => key !== 'null' && (
                            <div className="card" style={{padding: '5px 10px', height: 'auto', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                <span style={{fontSize: 14, fontWeight: 700}}>{value}</span>
                                <span style={{textAlign: 'center'}}>{key}</span>
                            </div>
                        ))}
                    </div>
                </div>
                :
                <EmptyData />
            }
        </div>
    )
}
import React, {useEffect, useState} from "react"
import statsService from "../../../services/api/stat.service"
import notify from "devextreme/ui/notify"
import Chart, {
  CommonSeriesSettings,
  Crosshair,
  Export,
  HorizontalLine,
  Legend,
  Series,
  ValueAxis,
} from "devextreme-react/chart"
import {Label, Tooltip} from "devextreme-react/pie-chart"
import {numberWithCommas11} from "../../../util"

const PopupDetailStatByYear = (props) => {
  const [data, setData] = useState([])

  const loadData = async () => {
    try {
      let {data} = await statsService.getDataYearsByCode(
        props.list_id,
        props.tbl_id,
        props.selectedCode,
        "ALL"
      )
      setData(data)
      // if (props.tier === 1) {
      //   let {data} = await statsService.getDataYearsByCode(
      //     props.list_id,
      //     props.tbl_id,
      //     props.selectedCode,
      //     "ALL"
      //   )
      //   setData(data)
      // } else if (props.tier === 2) {
      //   let {data} = await statsService.getDataYearsByCode(
      //     props.list_id,
      //     props.tbl_id,
      //     props.selectedCode,
      //     props.titleHeaders[props.tier - 1]
      //   )
      //   setData(data)
      // }
    } catch (e) {
      notify(e.message)
    }
  }

  useEffect(() => {
    if (props.selectedCode && props.tier) {
      loadData()
    }
  }, [props.tbl_id, props.list_id, props.selectedCode, props.tier])

  const renderLabel = (e) => {
    return `${e.percentText}`
  }

  const customizeTooltip = (arg) => {
    if (arg.seriesName === "Өөрчлөлт") {
      let r = data.find(
        (i) => parseInt(i.period_year) === parseInt(arg.argument) - 1
      )
      let q = (
        arg.value - (r && r.sum) > 0
          ? arg.value / (r && r.sum)
          : (r && r.sum) / arg.value
      ).toFixed(2)
      let p = ((arg.value * 100) / (r && r.sum) - 100).toFixed(2)
      let d = arg.value - (r && r.sum)
      let f = d < 0 ? "бага" : "их"
      if (isFinite(q) && !isNaN(q)) {
        return {
          text: `Өмнөх жилээс\n\n Зөрүү: ${numberWithCommas11(
            d
          )}\n Хувь: ${p}%\n Өөрчлөлт: ${q} дахин ${f}`,
        }
      } else {
        return {
          text: `Тооцоолох боломжгүй`,
        }
      }
    } else {
      return {
        text: `${arg.seriesName}: ${numberWithCommas11(arg.value)}`,
      }
    }
  }

  return (
    <div className="card" style={{flex: 1}}>
      <div className="card-header">
        <span>1. ЖИЛЭЭР</span>
      </div>
      <div className="card-body">
        <Chart dataSource={data}>
          <Export enabled={true} />
          <CommonSeriesSettings argumentField="period_year" type="bar">
            <Label
              backgroundColor="none"
              visible={false}
              customizeText={renderLabel}
              font={{family: "'Montserrat', sans-serif"}}
            />
          </CommonSeriesSettings>
          <Crosshair enabled={true}>
            <HorizontalLine visible={false} />
            <Label visible={true} />
          </Crosshair>
          <ValueAxis>
            <Label customizeText={(e) => e.value} />
          </ValueAxis>
          <Series valueField="sum" name="Тоо" color="#003696" />
          <Series
            valueField="sum"
            name="Өөрчлөлт"
            type="spline"
            color="#f05b41"
          />
          <Legend
            visible={true}
            verticalAlignment="bottom"
            horizontalAlignment="center"
            font={{family: "'Montserrat', sans-serif"}}
          />
          <Tooltip
            zIndex={100000}
            customizeTooltip={customizeTooltip}
            enabled={true}
            font={{family: "'Montserrat', sans-serif"}}
            location="edge"
          ></Tooltip>
        </Chart>
      </div>
    </div>
  )
}

export default PopupDetailStatByYear

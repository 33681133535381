import React, { useEffect, useState } from 'react';
//Components

//style
import '../css/topHeaderComponent.css';
import { Tooltip } from "devextreme-react/tooltip";
import PhoneIcon from "../../../assets/imgs/phone-2.png";
import WebIcon from "../../../assets/imgs/web-2.png";
import EmailIcon from "../../../assets/imgs/email-2.png";
import FbIcon from "../../../assets/imgs/fb-icon.png";
import AddressIcon from "../../../assets/imgs/address-icon.png";
import ShilenDansIcon from "../../../assets/imgs/shilendans-icon.png";
import TenderIcon from "../../../assets/imgs/tender-icon.png";
import OrganizationService from "../../../services/api/organization";
// import { boxSizing } from '@mui/system';

export default function Introduction({ data, urlServer, fileNameData, orgId }) {
  const [withAnimationVisible, setWithAnimationVisible] = useState(false);
  const [withAnimationVisible1, setWithAnimationVisible1] = useState(false);
  const [withAnimationVisible9, setWithAnimationVisible9] = useState(false);
  const [withAnimationVisible2, setWithAnimationVisible2] = useState(false);
  const [withAnimationVisible3, setWithAnimationVisible3] = useState(false);
  const [withAnimationVisible4, setWithAnimationVisible4] = useState(false);
  const [withAnimationVisible5, setWithAnimationVisible5] = useState(false);
  const [withAnimationVisible6, setWithAnimationVisible6] = useState(false);
  const [phone, setPhone] = useState(null);
  const [email, setEmail] = useState(null);
  const [domain_name, setDomain] = useState(null);
  const [website, setWebsite] = useState(null);
  const [address, setAddress] = useState(null);
  const [facebook, setFacebook] = useState(null);
  const [shilendans, setShilendans] = useState(null);
  const [tender, setTender] = useState(null);
  useEffect(() => {
    getHeaderData(orgId);
  }, [orgId]);
  const getHeaderData = async (orgId) => {

    try {
      let result = await OrganizationService.getOrgContact(orgId);
      let tmpwebsite = await result?.data.find(element => element.name === 'website');
      let tmptelephone = await result?.data.find(element => element.name === 'telephone');
      let tmpemail = await result?.data.find(element => element.name === 'email');
      let tmpaddress = await result?.data.find(element => element.name === 'address');
      let tmpfacebook = await result?.data.find(element => element.name === 'facebook');
      let tmpshilendans = await result?.data.find(element => element.name === 'shilendans');
      let tmptender = await result?.data.find(element => element.name === 'tender');
      let tmpdomain = await result?.data.find(element => element.name === 'domain_name');
      tmpwebsite !== undefined ? setWebsite(tmpwebsite.value.replaceAll('<p>', '').replaceAll('</p>', '')) : setWebsite(null);
      tmptelephone !== undefined ? setPhone(tmptelephone.value.replaceAll('<p>', '').replaceAll('</p>', '')) : setPhone(null);
      tmpemail !== undefined ? setEmail(tmpemail.value.replaceAll('<p>', '').replaceAll('</p>', '')) : setEmail(null);
      tmpaddress !== undefined ? setAddress(tmpaddress.value.replaceAll('<p>', '').replaceAll('</p>', '')) : setAddress(null);
      tmpfacebook !== undefined ? setFacebook(tmpfacebook.value.replaceAll('<p>', '').replaceAll('</p>', '')) : setFacebook(null);
      tmpshilendans !== undefined ? setShilendans(tmpshilendans.value.replaceAll('<p>', '').replaceAll('</p>', '')) : setShilendans(null);
      tmptender !== undefined ? setTender(tmptender.value.replaceAll('<p>', '').replaceAll('</p>', '')) : setTender(null);
      tmpdomain !== undefined ? setDomain(tmpdomain.value.replaceAll('<p>', '').replaceAll('</p>', '')) : setTender(null);
    } catch (e) {
      console.error(e);
    }
  }

  const bannerStyle = {
    color: "transparent",
    textIndent: "-100vw",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    borderTopLeftRadius: "1rem",
    borderTopRightRadius: "1rem",
    objectFit: 'cover',
  }
  return (
    <div style={{
      display: "flex",
      justifyContent: "end",
      alignItems: "center",
      width: "100%",
      height: "100%",
      position: "relative",
    }}>
      {(urlServer && fileNameData && fileNameData.banner_file_name !== null ) ?
        <img src=
          {urlServer + '/api/file/documents/' + fileNameData.banner_file_name}
          alt={fileNameData.banner_file_name}
          style={bannerStyle} /> : <img src={require('../../../assets/imgs/header-banner.webp')} style={bannerStyle} alt='' />
      }
      {!(urlServer && fileNameData && fileNameData.banner_file_name) ?
        <div className='opengov-title' style={{ fontSize: "28px", color: "rgb(6, 37, 83)" }}>
          <span>OPEN</span>
          <span style={{ fontWeight: "bold" }}>GOV</span>
        </div>
        : null}
      <div className='align-self-left topHeaderContacts'>
        {(phone && phone.length > 0) && <Tooltip
          target="#number"
          position="top"
          visible={withAnimationVisible}
          hideOnOutsideClick={true}
        >
          <div className='banner-tooltip' dangerouslySetInnerHTML={{ __html: 'Утас: ' + phone }}></div>
        </Tooltip>}
        {(email && email.length > 0) && <Tooltip
          target="#number1"
          position="top"
          // animation={animationConfig}
          visible={withAnimationVisible2}
          hideOnOutsideClick={true}
        >
          <div className='banner-tooltip'>Имэйл: {email}</div>
        </Tooltip>}
        {(domain_name && domain_name.length > 0) && <Tooltip
          target="#number9"
          position="top"
          // animation={animationConfig}
          visible={withAnimationVisible9}
          hideOnOutsideClick={true}
        >
          <div className='banner-tooltip'>OpenWeb: {domain_name}</div>
        </Tooltip>}

        {(website && website.length > 0) && <Tooltip
          target="#number2"
          position="top"
          // animation={animationConfig}
          visible={withAnimationVisible1}
          hideOnOutsideClick={true}
        >
          <div className='banner-tooltip'>Вэб: {website}</div>
        </Tooltip>}

        {(facebook && facebook.length > 0) && <Tooltip
          target="#number3"
          position="top"
          // animation={animationConfig}
          visible={withAnimationVisible3}
          hideOnOutsideClick={true}
        >
          <div className='banner-tooltip'>Фэйсбүүк: {facebook}</div>
        </Tooltip>}
        {(shilendans && shilendans.length > 0) && <Tooltip
          target="#number4"
          position="top"
          // animation={animationConfig}
          visible={withAnimationVisible4}
          hideOnOutsideClick={true}
        >
          <div className='banner-tooltip'>Шилэн данс: {shilendans}</div>
        </Tooltip>}
        {(address && address.length > 0) && <Tooltip
          target="#number5"
          position="top"
          // animation={animationConfig}
          visible={withAnimationVisible5}
          hideOnOutsideClick={true}
        >
          <div
            className='banner-tooltip'
            dangerouslySetInnerHTML={{ __html: 'Хаяг: ' + address }}
          ></div>
        </Tooltip>}
        {(tender && tender.length > 0) && <Tooltip
          target="#number6"
          position="top"
          // animation={animationConfig}
          visible={withAnimationVisible6}
          hideOnOutsideClick={true}
        >
          <div
            className='banner-tooltip'
            dangerouslySetInnerHTML={{ __html: 'Тендер: ' + tender }}
          ></div>
        </Tooltip>}

        {(phone && phone.length > 0) && <div className="ab-button" style={{ boxSizing: 'border-box' }} onMouseEnter={() => setWithAnimationVisible(!withAnimationVisible)}
          onMouseLeave={() => setWithAnimationVisible(!withAnimationVisible)}>
          <a href={`tel:+976${phone}`} id="number"
          >
            <img className="ab-icon" style={{ height: 10.93, width: 10.93 }} src={PhoneIcon} />

          </a>
        </div>}

        {(domain_name && domain_name.length > 0) && <div className="ab-button" onMouseEnter={() => setWithAnimationVisible9(!withAnimationVisible9)}
          onMouseLeave={() => setWithAnimationVisible9(!withAnimationVisible9)}>
          <a href={`https://${domain_name}`} target="_blank" id="number9"
            rel="noreferrer">
            <img className="ab-icon" style={{ height: 12.89, width: 12.89 }} src={WebIcon} />

          </a>
        </div>}

        {(website && website.length > 0) && <div className="ab-button" onMouseEnter={() => setWithAnimationVisible1(!withAnimationVisible1)}
          onMouseLeave={() => setWithAnimationVisible1(!withAnimationVisible1)}>
          <a href={`${website}`} target="_blank" id="number2"
            rel="noreferrer">
            <img className="ab-icon" style={{ height: 12.89, width: 12.89 }} src={WebIcon} />

          </a>
        </div>}

        {(email && email.length > 0) && <div className="ab-button" onMouseEnter={() => setWithAnimationVisible2(!withAnimationVisible2)}
          onMouseLeave={() => setWithAnimationVisible2(!withAnimationVisible2)}>
          <a href={`mailto:${email}`} id="number1"
          ><img className="ab-icon" style={{ width: 12.89, height: 9.38 }} src={EmailIcon} />

          </a>
        </div>}

        {(facebook && facebook.length > 0) && <div className="ab-button" onMouseEnter={() => setWithAnimationVisible3(!withAnimationVisible3)}
          onMouseLeave={() => setWithAnimationVisible3(!withAnimationVisible3)}>
          <a href={`${facebook}`} target="_blank" id="number3"
            rel="noreferrer">
            <img className="ab-icon" style={{ height: 15, width: 18 }} src={FbIcon} />

          </a>
        </div>}

        {(shilendans && shilendans.length > 0) && <div className="ab-button" onMouseEnter={() => setWithAnimationVisible4(!withAnimationVisible4)}
          onMouseLeave={() => setWithAnimationVisible4(!withAnimationVisible4)}>
          <a href={`${shilendans}`} target="_blank" id="number4"
            rel="noreferrer">
            <img className="ab-icon" style={{ height: 14, width: 14 }} src={ShilenDansIcon} />

          </a>
        </div>}

        {(tender && tender.length > 0) && <div className="ab-button" onMouseEnter={() => setWithAnimationVisible6(!withAnimationVisible6)}
          onMouseLeave={() => setWithAnimationVisible6(!withAnimationVisible6)}>
          <a href={`${tender}`} target="_blank" id="number6"
            rel="noreferrer">
            <img className="ab-icon" style={{ height: 14, width: 14 }} src={TenderIcon} />

          </a>
        </div>}

        {(address && address.length > 0) && <div className="ab-button" onMouseEnter={() => setWithAnimationVisible5(!withAnimationVisible5)}
          onMouseLeave={() => setWithAnimationVisible5(!withAnimationVisible5)}>
          <a id="number5"
          ><img className="ab-icon" style={{ width: 14, height: 14 }} src={AddressIcon} />

          </a>
        </div>}
      </div>
    </div>
  )
}

import React, { useState, useEffect } from "react";
import notify from "devextreme/ui/notify";
import _ from "lodash";
import Chart, { ArgumentAxis, CommonSeriesSettings, Crosshair, Label, Legend, LoadingIndicator, Point, Series, Tooltip, ValueAxis } from "devextreme-react/chart";
import BudgetService from "../../../../../services/api/budget";
import { numberWithCommas } from "../../../../../util";
import EmptyData from "../../../../budget/components/empty";
import LoadPanelComponent from "../../../../budget/components/loadPanel";

const InvestExecutionByMonth = (props) => {
    const [chartData, setChartData] = useState([]);
    const [loader, setLoader] = useState(true);

    useEffect(() => {
        loadData();
    }, [props.budgetYear])

    const loadData = async () => {
        try {
            let r = await BudgetService.getInvestExecutionByMonth(props.budgetYear);
            const filtered = Array.from({ length: 12 }, (_, index) => {
                const monthData = r.find(data => data.budget_month === (index + 1).toString()) || { budget_month: (index + 1).toString(), tusuv: 0, guitsetgel: 0 };
                return monthData;
            });
            r && r.length > 0 ? setChartData(filtered) : setChartData([])
        } catch (e) {
            notify(e.message, "error", 2000);
        } finally {
            setLoader(false)
        }
    }

    const customizeTooltip = (arg) => {
        if (arg.seriesName === 'Төсвийн өөрчлөлт') {
            let r = chartData[(arg.argument * 1 - 1) - 1];
            let q = ((arg.value - (r && r.tusuv) > 0 ? arg.value / (r && r.tusuv) : (r && r.tusuv) / arg.value)).toFixed(2);
            let p = ((arg.value * 100 / (r && r.tusuv)) - 100).toFixed(2);
            let d = (arg.value - (r && r.tusuv));
            let f = d < 0 ? 'бага' : 'их';

            if (isFinite(q) && !isNaN(q)) {
                return {
                    html: `<div class="tooltip-header">
                    <span>Төсөв ${arg.argumentText}-р сар:</span>
                    <span>${numberWithCommas(arg.value / 1000000)}</span>
                    </div>
                    <hr/>
                    <b><i>Өмнөх сараас</i></b><br/>
                    <span class="tooltip-series-name">Зөрүү: </span> ${numberWithCommas(d / 1000000)}<br/>
                    <span class="tooltip-series-name">Хувь: </span> ${p}%<br/>
                    <span class="tooltip-series-name">Өөрчлөлт: </span> ${q} дахин ${f}<br/>
                    `
                }
            } else {
                return {
                    html: `<div class="tooltip-header">
                    <span>Төсөв ${arg.argumentText}-р сар:</span>
                    <span>${numberWithCommas(arg.value / 1000000)}</span>
                    </div>`
                }
            }
        }
        else if (arg.seriesName === 'Гүйцэтгэлийн өөрчлөлт') {
            let r = chartData[(arg.argument * 1 - 1) - 1];
            let q = ((arg.value - (r && r.guitsetgel) > 0 ? arg.value / (r && r.guitsetgel) : (r && r.guitsetgel) / arg.value)).toFixed(2);
            let p = ((arg.value * 100 / (r && r.guitsetgel)) - 100).toFixed(2);
            let d = (arg.value - (r && r.guitsetgel));
            let f = d < 0 ? 'бага' : 'их';
            let r1 = chartData[(arg.argument * 1) - 1];
            let u = ((r1 && r1.tusuv) - arg.value);
            let u1 = ((arg.value * 100 / (r1 && r1.tusuv))).toFixed(2);
            if (isFinite(q) && !isNaN(q)) {
                return {
                    html: `<div class="tooltip-header">
                    <span>Гүйцэтгэл ${arg.argumentText}-р сар:</span>
                    <span>${numberWithCommas(arg.value / 1000000)}</span>
                    </div>
                    <hr/>
                    <b><i>Төсвөөс</i></b><br/>
                    <span class="tooltip-series-name">Зөрүү: </span>${numberWithCommas(u / 1000000)} <br/>
                    <span class="tooltip-series-name" style={{marginBottom: 10}}>Xувь: </span>${u1}% <br/>
                    <hr/>
                    <b><i>Өмнөх сараас </b></i><br/>
                    <span class="tooltip-series-name">Зөрүү: </span>${numberWithCommas(d / 1000000)} <br/>
                    <span class="tooltip-series-name">Хувь: </span>${p}% <br/>
                    <span class="tooltip-series-name">Өөрчлөлт: </span>${q} дахин ${f}<br/>`
                }
            } else {
                return {
                    html: `<div class="tooltip-header">
                    <span>Гүйцэтгэл ${arg.argumentText}-р сар:</span>
                    <span>${numberWithCommas(arg.value / 1000000)}</span>
                    </div>
                    <hr/>
                    <b><i>Төсвөөс</i></b><br/>
                    <span class="tooltip-series-name">Зөрүү: </span>${numberWithCommas(u / 1000000)} <br/>
                    <span class="tooltip-series-name" style={{marginBottom: 10}}>Xувь: </span>${isFinite(u1) && !isNaN(u1) ? u1 : `0.00`}% <br/>`
                }
            }
        }
        else {
            return {
                html: `<div class="tooltip-header">
                    <span>${arg.seriesName} ${arg.argumentText}-р сар:</span>
                    <span>${numberWithCommas(arg.value / 1000000)}</span>
                    </div>
                `
            };
        }
    }

    if (chartData.length === 0 && !loader) {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    family: "Segoe UI",
                }}
            >
                <EmptyData />
            </div>
        )
    }

    const handleLegend = (e) => {
        if (e.target.isVisible()) {
            e.target.hide();
        } else {
            e.target.show();
        }
    }

    function markerTemplate(item) {
        const color = item.series.isVisible() ? item.marker.fill : '#888';
        return (
            <svg>
                <rect x={0} y={0} width={12} height={12} fill={color}
                    rx={item.text.includes('өөрчлөлт') ? 6 : 0}
                    ry={item.text.includes('өөрчлөлт') ? 6 : 0}></rect>
            </svg>
        );
    }

    return (
        <div id="load99">
            <LoadPanelComponent position={{ of: '#load99' }} visible={loader} />
            <Chart
                paletteExtensionMode="alternate"
                dataSource={chartData}
                onLegendClick={handleLegend}
            >
                <LoadingIndicator enabled={true} />
                <Crosshair enabled={true} label={true} />
                <ValueAxis valueType="numeric" allowDecimals={false}>
                    <Label format='#' customizeText={(e) => e.value / 1000000000} />
                </ValueAxis>
                <ArgumentAxis tickInterval={1} argumentType='numeric' />
                <CommonSeriesSettings argumentField="budget_month">
                    <Label visible={false} />
                    <Point size={10} />
                </CommonSeriesSettings>
                <Series barPadding={0.2} type="stackedbar" valueField="tusuv" name="Хөрөнгийн зардал" cornerRadius={6} color="#f66160" />
                <Series valueField="tusuv" name="Зардлын өөрчлөлт" color="#b83535" type="spline" dashStyle="solid" visible={false} />
                <Series cornerRadius={6} barPadding={0.6} type="bar" valueField="guitsetgel" name="Гүйцэтгэл" color='#4c5286' />
                <Series valueField="guitsetgel" name="Гүйцэтгэлийн өөрчлөлт" color="#003696" type="spline" dashStyle="solid" />
                <Legend visible={true} verticalAlignment="bottom" horizontalAlignment="center" font={{ family: "Segoe UI" }} markerRender={markerTemplate} />
                <Tooltip zIndex={100000} enabled={true} cornerRadius={6} border={{ visible: false }}
                    customizeTooltip={customizeTooltip} font={{ color: '#fff' }} color="#555" location="edge">
                </Tooltip>
            </Chart>
        </div>
    )
}

export default InvestExecutionByMonth;

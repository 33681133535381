import React, {useState,  useEffect} from "react";
import ArrayStore from "devextreme/data/array_store";

import ArEntery from "./ArEntery";

import TabPanel, {Item} from "devextreme-react/tab-panel";

import List from "devextreme-react/list";
import ArReport from "./report/ArReport";
import fcsServices from "../../../services/api/fcs";
import Arbeginbal from "./arbeginbal";
import Arclassedit from "./arclassedit";
import Artranedit from "./Artranedit";
import Arselect from "./arselect";
import ArSelectEdit from "./ArSelectEdit";
import {BiLineChart, BiLineChartDown} from 'react-icons/bi'
import {BsGear} from 'react-icons/bs'
import {CgFileDocument} from 'react-icons/cg'
import notify from "devextreme/ui/notify";
import CustomTabComponent from "../../../components/CustomTabComponent";
import ArEdit from "./ArEdit";


export const tabs = [
    {
        id: 1,
        text: 'Борлуулалт'
    },
    {
        id: 5,
        text: 'Төлбөр'
    },
    {
        id: 2,
        text: 'Борлуулалтын ангилал'
    },
    {
        id: 4,
        text: 'Төлбөрийн ангилал'
    },
    {
        id: 3,
        text: 'Эхний үлдэгдэл '
    }
];

const monthList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

const dataSource = new ArrayStore({
    key: 'id',
    data: tabs,
});

const fileTabs = [
    {id: 1, text: 'Гүйлгээ'},
    {id: 2, text: 'Харилцагч'}
]
const iconStyle= {
    color: "#1C3BC2", fontWeight: "600", fontSize: "1.2rem", transform: "translateY(.2rem)", marginRight: ".25rem"
}
const customTitleRender=(item)=>{
    switch(item.title){
        case "БОРЛУУЛАЛТ":
        return(
            <span><BiLineChart style={iconStyle}/> {item.title} </span>
         )
         case "Төлбөр":
            return(
                <span><BiLineChartDown style={iconStyle}/> {item.title} </span>
             )
         case "ЗАСВАРЛАХ":
            return(
                <span><BsGear style={iconStyle}/> {item.title} </span>
            )
        case "ТАЙЛАН":
                return(
                    <span><CgFileDocument style={iconStyle}/> {item.title}</span>
                 )
     default :
    }
   
}

export default function AccountRecieve() {

    const [yearValue, setYearValue] = useState(null);
    const [monthValue, setMonthValue] = useState(null);

    const [selectedId, setSelectedId] = useState(1);
    const initYearMN = {year: (new Date()).getFullYear(), month: (new Date()).getMonth() + 1, name: ""};
    const [yearMN, setYearMN] = useState({...initYearMN});

    const itemClickWorld = async (e) => {
        setSelectedId(e.itemData.id);
    }

    useEffect(() => {
        goMonth();
    }, []);

    const goMonth = async () => {
        const value = await fcsServices.getAllFcsMonth('AR');
        if ( value && value.length < 1) notify(" тохиргоо хийнэ үү  ", "warning")
        // if ( fcsMonth && fcsMonth.length < 1) setLoader(false)
        if ( value && value.length < 1) return

        setMonthValue(value[0].month);
        setYearValue(value[0].year);
    }

    const renderListItem = (data) => {
        return (
            <div style={{display: 'flex', alignItems: 'center', padding: '5px', height: '25px'}}>
                <span style={{marginLeft: '10px'}}>{data.text}</span>
            </div>
        )
    }

    return (
        <React.Fragment>
            <div className={'accountRecievable bankbook'}>
                <CustomTabComponent 
                    list={["Борлуулалт", "Тайлан"]}
                    children={[
                        <ArEdit/>, 
                        <ArReport goMonth={goMonth}/>]}
                        titleWidth="15rem"
                /> 
                    {/* <TabPanel itemTitleRender={customTitleRender}>
                        <Item title="БОРЛУУЛАЛТ" icon="chart">
                            <div>
                                <ArEntery yearMN={yearMN}  year={yearValue} month={monthValue}/>
                            </div>
                        </Item>
                        <Item title="Төлбөр" icon="money">
                            <div>
                                <Arselect/>
                               </div>
                        </Item>
                        <Item title="ЗАСВАРЛАХ" icon="edit">
                            <div id={'firstBox'} className={'card'} style={{padding: '.25rem'}}>
                                <div id={'lawTypeLoad'}>
                                    <div className="row">
                                        <div className="col-2">
                                            <List
                                                selectionMode="single"
                                                dataSource={dataSource}
                                                selectedItemKeys={selectedId}
                                                onItemClick={itemClickWorld}
                                                itemRender={renderListItem}
                                            />
                                        </div>
                                        <div className="col-10">
                                            {selectedId === 1 &&
                                                <div style={{marginLeft: 20}}>
                                                    <Artranedit />
                                                </div>
                                            }
                                            {selectedId === 5 &&
                                                <div style={{marginLeft: 20}}>
                                                    <ArSelectEdit/>
                                                </div>
                                            }

                                            {selectedId === 2 &&
                                                <div style={{marginLeft: 20}}>
                                                    <Arclassedit artype={true}/>
                                                </div>
                                            }
                                            {selectedId === 4 &&
                                                <div style={{marginLeft: 20}}>
                                                    <Arclassedit artype={false}/>
                                                </div>
                                            }


                                            {selectedId === 3 &&
                                                <div style={{marginLeft: 20}}>
                                                    <Arbeginbal/>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Item>
                        <Item title="ТАЙЛАН" icon="doc">
                            <div id={'firstBox'} className={'card'}>
                                <div id={'lawTypeLoad'}>

                                    <div className="row">
                                        <ArReport goMonth={goMonth}/>


                                    </div>
                                </div>
                            </div>
                        </Item>

                    </TabPanel> */}
                </div>
        </React.Fragment>
    );
}

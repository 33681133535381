import React, {useRef} from "react";
import DataGrid, {
    Pager,
    Paging,
    Column,
    FilterRow,
    Export,
    Summary, Selection,
    TotalItem, GroupItem, GroupPanel, SearchPanel, Grouping
} from "devextreme-react/data-grid";
import {Workbook} from "exceljs";
import {saveAs} from "file-saver-es";
import {exportDataGrid} from "devextreme/excel_exporter";

const GridReport = (props) => {

    const arrType = ["text", "number", "date", "text", "text", "number"];

    const tranDescr = props.tranDescr;
    const structure = props.structure;
    const Tran = props.Tran;
    const tranF = props.tranF;

    const sumReport = props.sumReport;
const rowSelected=props.rowSelected
    //const [endSum, setEndSum] = useState([]);

    const customizeDate = (data) => {
        return Number(parseFloat(data.value).toFixed(2)).toLocaleString("en", {
            minimumFractionDigits: 2,
        });
    };

    const onExporting = (e) => {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet("Main sheet");

        exportDataGrid({
            component: e.component,
            worksheet,
            autoFilterEnabled: true,
        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(
                    new Blob([buffer], {type: "application/octet-stream"}),
                    "Purchase.xlsx"
                );
            });
        });
        e.cancel = true;
    };

    const componentRef = useRef();
    return (
        <div>

            <div>
                {" "}

            </div>

            <div
                ref={componentRef}
                style={{
                    width: "700px",
                    margin: "20px",

                }}
            >


                <div className="card-body">
                    <DataGrid
                        //  onEditorPreparing={onEditorPreparing}
                        columnAutoWidth={true}
                        showBorders={true}
                        dataSource={Tran}
                        keyExpr={""}
                        allowColumnResizing={true}
                        onExporting={onExporting}
                        onSelectionChanged={rowSelected}
                    >
                        <FilterRow visible={true}/>
                        <Pager
                            showPageSizeSelector={true}
                            // showInfo={true}
                            showNavigationButtons={true}
                        />
                        {tranDescr.map((el, index) => (
                            <Column
                                dataField={tranF[index]}
                                caption={tranDescr[index]}
                                type={arrType[structure[index]]}
                                format={structure[index] == 5 ? "#,##0.00" : ''}
                                allowEditing={false}
                                cssClass="custom"
                                allowFiltering={true}
                            ></Column>
                        ))}

                        <Paging enabled={true}/>
                        <Selection mode={'single'}/>
                        <GroupPanel visible={true}/>
                        <SearchPanel visible={true}/>
                        <Grouping autoExpandAll={true}/>
                        <Export enabled={true} allowExportSelectedData={false}/>
                        <Summary>
                            {sumReport.map((el) => (
                                <TotalItem
                                    column={tranF[el]}
                                    summaryType="sum"
                                    showInGroupFooter={true}
                                    customizeText={customizeDate}
                                />
                            ))}

                            {sumReport.map((el) => (
                                <GroupItem
                                    column={tranF[el]}
                                    summaryType="sum"
                                    showInGroupFooter={true}
                                    customizeText={customizeDate}
                                />
                            ))}


                        </Summary>
                    </DataGrid>
                </div>


            </div>
        </div>
    );
};

export default GridReport;

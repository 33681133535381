import React, { useEffect } from "react"
import {useState} from "react"
import styled from "styled-components"
import LoadPanelComponent from "../../budget/components/loadPanel"

const CustomTabPanel = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(12rem, 1fr));
  gap: 0.5rem;
  margin-bottom: 1rem;
  .tab {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 0.5rem;
    background-color: #f8f9fb;
    border-radius: 1rem;
    transition: 0.3s all ease-in;
    text-align: center;
    border: 2px solid transparent;
    .title {
      font-size: 1rem;
      font-weight: 700;
    }
    &:hover {
      background-color: white;
      border: 2px solid #eff0f3;
    }
    &.active {
      background-color: white;
      border: 2px solid #eff0f3;
      font-weight: 700;
    }
  }
`

const laboratoryList = [
    { name : 'Халдварт паразиттах өвчний оношилгоо, тандалтын лаборатори', url: 'https://lookerstudio.google.com/embed/u/0/reporting/5c89011a-7bdb-475a-8353-b90efabae1b1/page/kJMrD'},
    { name : 'Байгаль орчноос шалтгаалах өвчний оношилгоо, тандалтын лаборатори', url: 'https://lookerstudio.google.com/embed/u/0/reporting/be149d85-e2ee-48bf-ab95-1ab8aaec76ac/page/WCCrD'},
    {
        name: "Гоц халдварт өвчний оношлогоо, тандалтын лаборатори",
        url: "https://lookerstudio.google.com/embed/u/0/reporting/aacaf78c-618d-4d2d-b9ad-1f73cf23491c/page/anMrD",
    },
    { name : '7 хоногийн мэдээ', url: 'https://lookerstudio.google.com/embed/reporting/b7df3efd-1892-4fa9-bd82-33723821e3c5/page/j1YfC'},
    {
        name: "Хүнсний нян судлалын лаборатори",
        url: "https://lookerstudio.google.com/embed/reporting/cd0fd3e3-64e5-4f98-b72e-57cfadec4155/page/j1YfC",
    },

  /*  { name : '7 хоногийн мэдээ', url: 'https://lookerstudio.google.com/embed/reporting/b7df3efd-1892-4fa9-bd82-33723821e3c5/page/j1YfC'},
  {
    name: "Хүнсний нян судлалын лаборатори",
    url: "https://lookerstudio.google.com/embed/reporting/cd0fd3e3-64e5-4f98-b72e-57cfadec4155/page/j1YfC",
  },
  {
    name: "Гоц халдварт өвчний оношлогоо, тандалтын лаборатори",
    url: "https://lookerstudio.google.com/embed/reporting/d45aa6cd-cd82-433d-bc0a-32e2569d50e5/page/rltfD",
  },*/

]
function LaboratoryStatistics() {
  const [selectedTab, setSelectedTab] = useState(0)
  const [isLoading, setIsLoading ] = useState(false)

  useEffect(()=>{
    setIsLoading(true)
  }, [selectedTab])
  return (
    <div style={{width: "100%", height: "100%"}}>
      <CustomTabPanel>
        {laboratoryList?.map((item, index) => {
          return (
            <div
              className={`tab ${selectedTab === index && "active"}`}
              key={index}
              onClick={() => setSelectedTab(index)}
            >
              {item.name}
            </div>
          )
        })}
      </CustomTabPanel>
      <LoadPanelComponent
        position={{of: "#statistic-lab"}}
        visible={isLoading}
      />
      <div style={{width: "100%", height: "100%", margin: "auto"}}>
      
        <iframe
        id="statistic-lab"
          width="100%"
          onLoad={()=> setIsLoading(false)}
          style={{height: "100%", border: "none"}}
          src={laboratoryList[selectedTab].url}
          allowFullScreen
        ></iframe>
      </div>
    </div>
  )
}

export default LaboratoryStatistics

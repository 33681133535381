import React, { useState, useEffect } from "react";
import notify from "devextreme/ui/notify";
import BudgetService from "../../../../services/api/budget";
import _ from "lodash";
import Chart, { CommonSeriesSettings, Crosshair, Label, Legend, Point, Series, Tooltip, ValueAxis } from "devextreme-react/chart";
import { numberWithCommas } from "../../../../util";
import LoadPanelComponent from "../../../budget/components/loadPanel";
import EmptyData from "../../../budget/components/empty";

const ZarlagaZadgaiChart = (props) => {
    //const [neverChangingData, setNeverChangingData] = useState([]);
    const [chartData, setChartData] = useState([]);
    const [loader, setLoader] = useState(true);

    const loadData = async () => {
        try {
            let r = await BudgetService.getExpenseZadgaiMonth(props.year, props.orgId);
            // console.log("r is", r)
            //setNeverChangingData(r)
            let pp = _(r)
                .groupBy('budget_month')
                .map((item, key) => {
                    return {
                        budget_month: key,
                        batlagdsan: _.sumBy(item, "batlagdsan"),
                        ursgal_zardal: _.sumBy(item, "ursgal_zardal"),
                        hurungiin_zardal: _.sumBy(item, "hurungiin_zardal"),
                        guitsetgel: _.sumBy(item, "guitsetgel")
                    }
                })
                .value();
            setChartData(pp);
        } catch (e) {
            notify(e.message, "error", 2000);
        }finally{
            setLoader(false)
        }
    }

    const customizeTooltip = (arg) => {
        if (arg.seriesName === 'Төсвийн өөрчлөлт') {
            let r = chartData[(arg.argument * 1 - 1) - 1];
            let q = ((arg.value - (r && r.batlagdsan) > 0 ? arg.value / (r && r.batlagdsan) : (r && r.batlagdsan) / arg.value)).toFixed(2);
            let p = ((arg.value * 100 / (r && r.batlagdsan)) - 100).toFixed(2);
            let d = (arg.value - (r && r.batlagdsan));
            let f = d < 0 ? 'бага' : 'их';

            if (isFinite(q) && !isNaN(q)) {
                return {
                    html: `<div class="tooltip-header">
                    <span>Төсөв ${arg.argumentText}-р сар:</span>
                    <span>${numberWithCommas(arg.value/1000000)}</span>
                    </div>
                    <hr/>
                    <b><i>Өмнөх сараас</i></b><br/>
                    <span class="tooltip-series-name">Зөрүү: </span> ${numberWithCommas(d / 1000000)}<br/>
                    <span class="tooltip-series-name">Хувь: </span> ${p}%<br/>
                    <span class="tooltip-series-name">Өөрчлөлт: </span> ${q} дахин ${f}<br/>
                    `
                }
            } else {
                return {
                    html: `<div class="tooltip-header">
                    <span>Төсөв ${arg.argumentText}-р сар:</span>
                    <span>${numberWithCommas(arg.value/1000000)}</span>
                    </div>`
                }
            }
        }
        else if (arg.seriesName === 'Гүйцэтгэлийн өөрчлөлт') {
            let r = chartData[(arg.argument * 1 - 1) - 1];
            let q = ((arg.value - (r && r.guitsetgel) > 0 ? arg.value / (r && r.guitsetgel) : (r && r.guitsetgel) / arg.value)).toFixed(2);
            let p = ((arg.value * 100 / (r && r.guitsetgel)) - 100).toFixed(2);
            let d = (arg.value - (r && r.guitsetgel));
            let f = d < 0 ? 'бага' : 'их';
            let r1 = chartData[(arg.argument * 1) - 1];
            let u = ((r1 && r1.batlagdsan) - arg.value);
            let u1 = ((arg.value * 100 / (r1 && r1.batlagdsan))).toFixed(2);
            if (isFinite(q) && !isNaN(q)) {
                return {
                    html: `<div class="tooltip-header">
                    <span>Гүйцэтгэл ${arg.argumentText}-р сар:</span>
                    <span>${numberWithCommas(arg.value/1000000)}</span>
                    </div>
                    <hr/>
                    <b><i>Төсвөөс</i></b><br/>
                    <span class="tooltip-series-name">Зөрүү: </span>${numberWithCommas(u / 1000000)} <br/>
                    <span class="tooltip-series-name" style={{marginBottom: 10}}>Xувь: </span>${u1}% <br/>
                    <hr/>
                    <b><i>Өмнөх сараас </b></i><br/>
                    <span class="tooltip-series-name">Зөрүү: </span>${numberWithCommas(d / 1000000)} <br/>
                    <span class="tooltip-series-name">Хувь: </span>${p}% <br/>
                    <span class="tooltip-series-name">Өөрчлөлт: </span>${q} дахин ${f}<br/>`
                }
            } else {
                return {
                    html: `<div class="tooltip-header">
                    <span>Гүйцэтгэл ${arg.argumentText}-р сар:</span>
                    <span>${numberWithCommas(arg.value/1000000)}</span>
                    </div>
                    <hr/>
                    <b><i>Төсвөөс</i></b><br/>
                    <span class="tooltip-series-name">Зөрүү: </span>${numberWithCommas(u / 1000000)} <br/>
                    <span class="tooltip-series-name" style={{marginBottom: 10}}>Xувь: </span>${u1}% <br/>`
                }
            }
        }
        else {
            return {
                html: `<div class="tooltip-header">
                    <span>${arg.seriesName} ${arg.argumentText}-р сар:</span>
                    <span>${numberWithCommas(arg.value/1000000)}</span>
                    </div>
                    <hr/>
                    <b><i>Төсвөөс</i></b><br/>
                    <span class="tooltip-series-name">Зөрүү:</span> ${numberWithCommas((arg.point.data.batlagdsan-arg.value)/1000000)}<br/>
                    <span class="tooltip-series-name">Хувь: </span> ${((arg.value / 1000000) / (arg.point.data.batlagdsan / 1000000) * 100).toFixed(2)}% 
                `
            };
        }
    }

    const customizeValueAxisLabel = (e) => {
        return `${e.value / 1000000000}`
    }

    useEffect(() => {
        loadData();
    }, [props.year, props.chosenValue, props.chosenMinistry, props.orgId])

    const pointClickFilterHandler = (e) => {
        if (e.target.series.name === 'өөрчлөлт') return;
        let obj = {
            monthField: e.target.argument,
            valueField: e.target.series.name
        }
        props.callFunc(obj);
    }

    if (chartData.length === 0 && !loader){
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              family: "Segoe UI",
            }}
          >
            <EmptyData/>
          </div>
        )
      }

      const handleLegend = (e) => {
        if (e.target.isVisible()) {
          e.target.hide();
        } else {
          e.target.show();
        }
      }

      function markerTemplate(item) {
        const color = item.series.isVisible() ? item.marker.fill : '#888';
        return (
          <svg>
            <rect x={0} y={0} width={12} height={12} fill={color}
              rx={item.text.includes('өөрчлөлт') ? 6 : 0}
              ry={item.text.includes('өөрчлөлт') ? 6 : 0}></rect>
          </svg>
        );
      }

    return (
        <div id="load12">
            <LoadPanelComponent position={{ of: '#load12' }} visible={loader} />
                <Chart
                    paletteExtensionMode="alternate"
                    dataSource={chartData}
                    onLegendClick={handleLegend}
                >
                    <CommonSeriesSettings
                        argumentField="budget_month"
                    >
                        <Label visible={false} />
                        <Point size={10} />
                    </CommonSeriesSettings>
                    <Crosshair enabled={true} label={true} />
                    <ValueAxis>
                        <Label customizeText={customizeValueAxisLabel} />
                    </ValueAxis>
                    <Series barPadding={0.2} type="stackedbar" stack="tusuv" valueField="ursgal_zardal" name="Урсгал зардал" color="#F93A3A" border={{ color: 'white', width: 1, visible: true }}/>
                    <Series barPadding={0.2} type="stackedbar" stack="tusuv" valueField="hurungiin_zardal" name="Хөрөнгийн зардал" color="#f5a2a4" border={{ color: 'white', width: 1, visible: true }}/>
                    <Series valueField="batlagdsan" name="Төсвийн өөрчлөлт" color="#b83535" type="spline" dashStyle="solid" visible={false} />
                    <Series cornerRadius={6} barPadding={0.6} type="bar" valueField="guitsetgel" name="Гүйцэтгэл" color='#4c5286'/>
                    <Series valueField="guitsetgel" name="Гүйцэтгэлийн өөрчлөлт" color="#003696" type="spline" dashStyle="solid" />
                    <Legend visible={true} verticalAlignment="bottom" horizontalAlignment="center" font={{ family: "Segoe UI" }} markerRender={markerTemplate}/>
                    <Tooltip zIndex={100000} enabled={true} cornerRadius={6} border={{visible: false}}
                            customizeTooltip={customizeTooltip} font={{ color: '#fff' }} color="#555" location="edge">
                    </Tooltip>
                </Chart>
        </div>
    )
}

export default ZarlagaZadgaiChart;

import React, { useContext, useEffect, useState, useCallback, useRef } from "react"
import { useHistory } from "react-router-dom"
import notify from "devextreme/ui/notify"
import { UserContext } from "../../hooks/UserContext"
import ProposalService from "../../services/api/proposal"
import { budgetProjectStatus } from "./static/data"
import styled from "styled-components"
import { Accordion, Button, SelectBox } from "devextreme-react"
import _ from "lodash"
import RefService from "../../services/api/refs"
import UpdatedProposalHistory from "./components/updatedProposalHistory"
import LoadPanelComponent from "../budget/components/loadPanel"
import ProposalHistory from "./components/proposalHistory"
import { Popup } from "devextreme-react/popup"
import { FaBalanceScale } from "react-icons/fa"
import { BiCalendar } from "react-icons/bi"
import OrgDutyAccordion from "../../components/accordion/orgDuty"
import OrganizationService from "../../services/api/organization"
import { StepProgressBar } from "../../components/StepProgressBar"

export const ProjectButton = styled(Button)`
    display: flex;
    padding: 0.6rem;
    background-color: white;
    outline: none;
    cursor: pointer;
    border: 1px solid #ddd;
    border-radius: 0.5rem;

    .dx-button-content {
      padding: 0 !important;
    }

    transition: 0.3s ease;

    &:hover {
      background-color: #cbcfd6;
    }

    &:active {
      background-color: #cfd3db;
    }

    svg {
      font-size: 1.2rem;
    }
`

export const deadlineTooltip = [{
  1: '8.4.3.төсвийн ерөнхийлөн захирагч жилийн төсвийн саналаа жил бүрийн 07 дугаар сарын 25-ны дотор санхүү, төсвийн асуудал эрхэлсэн төрийн захиргааны төв байгууллага, төрийн аудитын байгууллага, Улсын Их Хуралд хүргүүлж, нийтэд ил тод мэдээлэх',
  2: '8.4.2.төсвийн төвлөрүүлэн захирагч, төсвийн төвлөрүүлэн захирагчид харьяалагддаггүй төсвийн шууд захирагч жил бүрийн 07 дугаар сарын 20-ны дотор жилийн төсвийн төслөө харьяалагдах төсвийн ерөнхийлөн захирагчид хүргүүлэх',
  3: '8.4.1.төсвийн төвлөрүүлэн захирагчид харьяалагддаг төсвийн шууд захирагч нь жилийн төсвийн төслөө жил бүрийн 7 дугаар сарын 09-ний дотор харьяалах төсвийн төвлөрүүлэн захирагчид хүргүүлэх'
}]

export default function Index(props) {
  const [title, setTitle] = useState(null)
  const [aimags, setAimags] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [proposalList, setProposalList] = useState([])
  const [budgetCalculation, setBudgetCalculation] = useState({})
  const [budgetStatus, setBudgetStatus] = useState("")
  const [budgetStatusId, setBudgetStatusId] = useState()
  const [opened, setOpened] = useState(false)
  const [currentProposal, setCurrentProposal] = useState(null)
  const [crntPrps, setCrntPrps] = useState(null)
  const [toggle, setToggle] = useState(true)
  const [year, setYear] = useState(new Date().getFullYear() + 1)
  const [yearData, setYearData] = useState()
  const { user } = useContext(UserContext)
  const history = useHistory()
  const [showLawModal, setShowLawModal] = useState(false)
  const [isIframeSupported, setIsIframeSupported] = useState(true)
  const [filePopupModal2, setFilePopupModal2] = useState(false)
  const [orgDuty, setOrgDuty] = useState()
  const yearSelectorRef = useRef()


  const getOrgDuty = useCallback(async () => {
    await OrganizationService.getOrganizationDuty(user?.organization?.id)
      .then((res) => {
        setOrgDuty(res.data)
      })
      .catch((err) => {
        console.log("caught->", err)
      })
  }, [user.organization.id])

  const handleIframeLoadError = () => {
    setIsIframeSupported(false)
  }

  const dataGridPositionId = { of: "#proposalListGrid" }
  //const budgetCard = { of: "#budgetCard" }

  useEffect(() => {
    loadYearData()
    getOrgDuty()
  }, [])

  useEffect(() => {
    loadAimag()
    setTitle(user.organization.name)
    loadAllProposals()
    state()
    getData()
    setupDefault()
  }, [year])

  const loadAimag = async () => {
    try {
      let r = await RefService.getAimag()
      setAimags(r.data)
    } catch (e) {
      notify(e.message, "error", 2000)
    }
  }
  const loadYearData = async () => {
    try {
      let r = await ProposalService.getBudgetProjectByYearAll()
      // setYear(r[0]?.year)
      setYearData(r.data)
      yearSelectorRef.current.instance.option('value', r.data?.[0]?.year)
      setYear(r.data?.[0]?.year)

    } catch (e) {
      notify(e.message, "error", 2000)
    }
  }

  const setupDefault = async () => {
    const result = await ProposalService.getDefaultProposal(year)
    if (result && result.data) {
      setCurrentProposal(result.data.id)
      setCrntPrps(result.data)
    } else {
      setCurrentProposal(null)
      setCrntPrps(null)
    }
  }

  const getData = async () => {
    setIsLoading(true)
    const res = await ProposalService.getProjectProgress(false, year)
    if (res.data.length > 0) {
      setBudgetStatus(res.data[0].budget_project_status_name)
      setBudgetStatusId(res.data[0].budget_project_status_id)
    }
    setIsLoading(false)

    let bc = _.groupBy(res.data || [], "econ_category_code_1")
    setBudgetCalculation(bc)
  }

  const loadAllProposals = async () => {
    const res = await ProposalService.listFiltered(year)
    setProposalList(res.data[0])
  }


  const onClickEdit = async (e) => {
    const location = {
      pathname: "/projects/detail",
      state: {
        proposal: proposalList,
        proposalId: proposalList?.id,
        sYear: year,
      },
    }
    history.push(location)
  }

  const state = () => {
    setOpened(true)
  }

  const proposalDeadline = {
    1: `${year - 1}-07-25`,
    2: `${year - 1}-07-20`,
    3: `${year - 1}-07-09`,
  }

  const lawAccordionData = [
    {
      title: "ХОЛБОГДОХ Эрх зүйн акт",
      subtitles:
        [
          "Монгол улсын хөгжлийн 2024 оны төлөвлөгөө",
          "Төсвийн тухай хууль",
          "Төрийн албаны зарим албан тушаалын цалингийн хэмжээ, доод хэмжээг шинэчлэн тогтоох тухай"
        ],
    }
  ];

  const lawAccordionTitle = (data) => {
    return (
      <div style={{ display: 'flex' }}>
        <div style={{ width: '2.2rem', height: '2.2rem', marginRight: '.8rem', border: '1px solid #ddd', borderRadius: '.25rem', padding: '.5rem' }}>
          <FaBalanceScale style={{ color: '#0814bd' }} /></div>
        <div style={{ textTransform: 'uppercase', alignSelf: 'center', fontWeight: 'bold', fontFamily: 'Segoe UI', fontSize: ".875rem" }}>
          {data.title}
        </div>
      </div>
    )
  };

  const lawAccordionItem = (data) => {
    return (
      <>
        {data.subtitles.map((subtitle, index) => (
          <div className="card accordian-list-item" key={index}
            style={{ padding: '1rem 2rem', flex: 1, margin: 10, fontWeight: 'bolder', textTransform: 'uppercase', cursor: 'pointer' }}
            onClick={() => lawActions(index)}>
            {subtitle}
          </div>
        ))}
      </>
    )
  };

  const lawActions = (props) => {
    switch (props) {
      case 0:
        setFilePopupModal2(true)
        break;
      case 1:
        window.open("https://legalinfo.mn/mn/detail/12254")
        break;
      case 2:
        window.open("https://legalinfo.mn/mn/detail?lawId=17089709745751")
        break;
      default:
        break;
    }
  }

  const deadlineTitle = () => {
    return (
      <div style={{ display: 'flex' }}>
        <div style={{ width: '2.2rem', height: '2.2rem', marginRight: '.8rem', border: '1px solid #ddd', borderRadius: '.25rem', padding: '.5rem' }}>
          <BiCalendar style={{ color: '#0814bd', fontSize: '1.2rem' }} />
        </div>
        <div style={{ textTransform: 'uppercase', alignSelf: 'center', fontWeight: 'bold', fontFamily: 'Segoe UI', fontSize: ".875rem" }}>
          ТА ТӨСВИЙН ТӨСЛӨӨ Хуульд заасан {""}
          <span style={{ color: '#f66160' }}>{proposalDeadline[user.organization.parent_obt_id === 1 ? 2 : user.organization.obt_id]}</span>
          {""} дотор хүргүүлнэ үү!
        </div>
      </div>
    )
  }

  const deadlineItem = () => {
    return (
      <div className="card accordian-list-item" style={{ padding: '1rem 2rem', flex: 1, margin: 10 }} >
        {deadlineTooltip[0][user.organization.parent_obt_id === 1 ? 2 : user.organization.obt_id]}
      </div>
    )
  }

  return (
    <>
      <div className="proposal-home" style={{ padding: 10, fontFamily: 'Segoe UI' }}>
        <LoadPanelComponent position={dataGridPositionId} visible={isLoading} />
        <Popup
          visible={showLawModal}
          onHiding={() => setShowLawModal(false)}
          hideOnOutsideClick={true}
          height="90vh"
          showTitle={false}
        >
          <Accordion
            // dataSource={accordionData}
            collapsible={false}
            multiple={false}
            itemTitleRender={({ title, key }) => (
              <div>
                {isIframeSupported ? (
                  <iframe
                    src="https://example.com"
                    width="100%"
                    height="100%"
                    title={title}
                    onError={handleIframeLoadError}
                  ></iframe>
                ) : (
                  <p>Sorry, this website does not support embedding.</p>
                )}
              </div>
            )}
            itemRender={({ key, content }) => <div>{content}</div>}
          />
        </Popup>
        <div
          className="col-12"
          style={{
            display: "flex",
            marginBottom: "1rem",
            flexWrap: 'wrap'
          }}
        >
          <div
            style={{
              display: "flex",
              gap: '1rem',
              alignItems: "baseline"
            }}
          >
            <div>
              <SelectBox
                items={yearData}
                onValueChanged={(e) => {
                  setYear(e.value)
                }}
                value={year}
                label="Төсвийн жил"
                width="10rem"
                ref={yearSelectorRef}
                defaultValue={year}
                valueExpr={"year"}
                displayExpr={"year"}
                placeholder="сонгох"
              />
            </div>
            <div style={{ color: "#003696", fontWeight: 700}}>{title}</div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: ".25rem",
              flex: 1,
              justifyContent: "flex-end",
            }}
          >
            <div
              style={{
                fontSize: '0.8rem',
                borderRadius: "8px",
                display: "flex",
                gap: ".5rem",
                alignItems: "center",
              }}
            >
              <button
                onClick={onClickEdit}
                className='createProject'
                style={{
                  background: proposalList?.budget_project_status_id === 1 ? '#003696' :
                    (proposalList?.budget_project_status_id === 4 || proposalList?.budget_project_status_id === 5) ? '#fff2f2' : '#cdebff',
                  color: proposalList?.budget_project_status_id === 1 ? 'white' :
                    (proposalList?.budget_project_status_id === 4 || proposalList?.budget_project_status_id === 5) ? '#f66160' : 'black',
                  cursor: 'pointer'
                }}
              >
                {proposalList?.budget_project_status_id === 1 ? 'Төсөл боловсруулах' :
                  (proposalList?.budget_project_status_id === 4 || proposalList?.budget_project_status_id === 5) ? 'Төсөл засварлах' : 'Төслийн дэлгэрэнгүй'}
              </button>
            </div>
          </div>
        </div>

        <StepProgressBar staticStatus={budgetProjectStatus} statusId={proposalList?.budget_project_status_id} userDesc={user?.organization} />
        <div className="responsive-flex" style={{ gap: '1rem', marginTop: '1rem' }}>
          {crntPrps && currentProposal && (
            <div className="col-4 responsive-col" style={{ marginBottom: '1rem' }}>
              <ProposalHistory
                currentProposal={currentProposal}
                crntPrps={crntPrps}
                year={year}
              />
            </div>
          )}
          {
            proposalList &&
            <div className="col-8 responsive-col">
              <div style={{
                background: '#f8f9fb', margin: 0, padding: '1rem', marginBottom: '1rem',
                display: 'flex', flexDirection: 'column', gap: 10, borderRadius: '1rem'
              }}>
                {orgDuty && orgDuty.length > 0 &&
                  <div>
                    <OrgDutyAccordion data={orgDuty} />
                  </div>
                }
                <div>
                  <Accordion
                    className='og-accordion'
                    collapsible={true}
                    width={"inherit"}
                    defaultSelectedIndex={-1}
                    dataSource={lawAccordionData}
                    animationDuration={300}
                    noDataText={'Дата байхгүй байна'}
                    style={{ border: 'solid thin #dddddd' }}
                    itemTitleRender={lawAccordionTitle}
                    itemRender={lawAccordionItem}
                  />
                </div>
                <div>
                  <Accordion
                    className='og-accordion'
                    collapsible={true}
                    width={"inherit"}
                    defaultSelectedIndex={-1}
                    dataSource={deadlineTooltip}
                    animationDuration={300}
                    noDataText={'Дата байхгүй байна'}
                    style={{ border: 'solid thin #dddddd' }}
                    itemTitleRender={deadlineTitle}
                    itemRender={deadlineItem}
                  />
                </div>
              </div>
              <div
                className={"card"} style={{ padding: '1rem', margin: 0 }}
              >
                {crntPrps && currentProposal && (
                  <UpdatedProposalHistory currentProposal={currentProposal} />
                )}
              </div>
            </div>
          }
        </div>
      </div>
      {filePopupModal2 && (
        <Popup
          width={"80%"}
          height={"95vh"}
          visible={filePopupModal2}
          onHiding={() => {
            setFilePopupModal2(false)
          }}
          dragEnabled={false}
          hideOnOutsideClick={true}
        >
          <object
            width="100%"
            height="100%"
            data={`https://cdn.greensoft.mn/uploads/users/3963/files/%D0%A5%D3%A9%D0%B3%D0%B6%D0%BB%D0%B8%D0%B9%D0%BD%20%D1%82%D3%A9%D0%BB%D3%A9%D0%B2%D0%BB%D3%A9%D0%B3%D3%A9%D3%A9%202024.pdf`}
            type="application/pdf"
          />
        </Popup>
      )}
    </>
  )
}

import React, {useState,useContext,useEffect} from "react";
import newsServices from "../../../services/api/news"
import {TextArea} from "devextreme-react";
import Validator, { RequiredRule } from 'devextreme-react/validator';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import ValidationGroup from 'devextreme-react/validation-group';
import {UserContext} from "../../../hooks/UserContext"
import { formatDate } from 'devextreme/localization';
import ScrollView from "devextreme-react/scroll-view";

const NewsSay = (props) => {
    const [note, setNote] = useState(null);
    const [sayData, setSayData] = useState(null);
    const {user} = useContext(UserContext);

    useEffect(() => {
        props.id !==undefined && fetchData()
    }, [props.id])

    const fetchData = async () => {
        try {
            const result = await newsServices.getOrgNewsSay(props.id);
            setSayData(result);
        } catch (error) {
            // console.log("error", error)
        }
    }
    const getTimeDifference = (ognoo) => {

        let date = new Date(ognoo).getTime()
        const seconds = Math.floor(date / 1000)
        const oldTimesStamp = Math.floor( new Date().getTime()/1000)
        const difference = oldTimesStamp- seconds

        if (difference < 60) {
            return `${difference} секундын өмнө`
        } else if (difference < 3600) {
            return `${Math.floor(difference / 60)} минутын өмнө`
        } else if (difference < 86400) {
            return `${Math.floor(difference / 3600)} цагийн өмнө`
        }
        else {
           return formatDate(new Date(ognoo), 'MM/dd/yyyy HH:mm')
        }
    }
    const Card = (note) => {
        let data = note.note;
        return (
            <div className='dx-card' style={{boxShadow: '0 4px 20px 0 rgba(0, 0, 0, 0.04)',
                border: '0.5px solid #DEDEDE',backgroundColor:' var(--base-bg)', padding: '10px',
                marginBottom: '10px'}}>
                <div style={{display: 'flex',flexDirection: 'row',justifyContent: 'space-between',fontSize: '12px',color: 'var(--texteditor-label-color)'}}>
                    <div>
                        {getTimeDifference(data.createdAt)} - {data.user_name}
                    </div>
                </div>
                <div style={{ paddingTop: '5px',lineHeight: '21px'}}>{data.info}</div>
            </div>
        );
    };
    const add =async (e)=> {
        try {
            if (!e.validationGroup.validate().isValid) {
                return;
            }
            let data = {};
            data.org_news_id = props.id;
            data.info = note;
            data.created_user_id = user.id;
            const result = await newsServices.addOrgNewsSay(data);
            if (result.code === 200) {
                //notify('', "success", 1000);
                e.validationGroup.reset();
                setNote(null);
                await fetchData();
            }
        } catch (error) {
            // console.log("error", error)
        }

    }
    return (
        <ValidationGroup>
            <div style={{display: 'flex',flexDirection: 'column',gap: ".3rem",marginTop: "1rem"}}>
            <TextArea
                autoResizeEnabled={true}
                minHeight={100}
                maxHeight={200}
                //height={90}
                width={"100%"}
                maxLength={1000}
                value={note}
                stylingMode={'outlined'}
                valueChangeEvent='keyup'
                label="Сэтгэгдэл"
                style={{fontFamily: "Segoe UI", padding: '10px'}}
                placeholder='Та сэтгэгдэлээ оруулна уу!'
                onValueChanged={(e) => {
                    setNote(e.value);
                }}
                >
                <Validator>
                    <RequiredRule />
                </Validator>
        </TextArea>
            <Toolbar>
                <Item
                    location='after'
                    widget='dxButton'
                    options={{
                        text: 'Нийтлэх',
                        stylingMode: 'contained',
                        type: 'default',
                        onClick: add,
                    }}
                />
            </Toolbar>
            </div>
            <div className='messages-content' style={{marginTop:10}}>
                <ScrollView>
                    <div className='message-list'>
                        {sayData?.map((note, index) => (
                            <Card note={note} key={index}/>
                        ))}
                    </div>
                </ScrollView>
            </div>
        </ValidationGroup>
    )
}

export default NewsSay

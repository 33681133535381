import React, { useContext, useState, useEffect } from "react";
import BudgetOtherOne from "./components/budget_other/BudgetOtherOne";
import SelectBox from "devextreme-react/select-box";
import { getYears } from "../../util";
import { UserContext } from "../../hooks/UserContext";
import BudgetService from "../../services/api/budget";
import OrganizationService from "../../services/api/organization";
import notify from "devextreme/ui/notify";
import BudgetOtherTwo from "./components/budget_other/BudgetOtherTwo";
import BudgetOtherThree from "./components/budget_other/BudgetOtherThree";
import BudgetOtherFour from "./components/budget_other/BudgetOtherFour";
import BudgetOtherFive from "./components/budget_other/BudgetOtherFive";
import BudgetOtherSix from "./components/budget_other/BudgetOtherSix";
import SourceFooter from './components/Source'
import { Button, Popup } from "devextreme-react";

const BudgetOtherInfo = (props) => {
    const { user } = useContext(UserContext);
    const [yearList, setYearList] = useState([]);
    const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
    const [chosenValue, setChosenValue] = useState('ALL');
    const [orgs, setOrgs] = useState([]);
    const [firstChartVisible, setFirstChartVisible] = useState(false);
    const [whichComponentMaxmized, setWhichComponentMaximized] = useState(null);

    const loadOrgs = async (year) => {
        try {
            let r = await BudgetService.getFilteredOrganizations(year,4);
            setOrgs(r.length > 0 && r.length === 1 ? r : [
                {
                    budget_year: year,
                    text_name: 'БҮГД',
                    value_name: 'ALL',
                },
                ...r
            ])
            r.length > 1 ? setChosenValue('ALL') : setChosenValue(r.length > 0 && r[0].value_name)
        } catch (e) {
            notify(e.message, "error", 2000)
        }
    }

    useEffect(() => {
        loadYearList();
        loadOrgs(currentYear)
    }, [])

    const loadYearList = async () => {
        try {
            let result = await (await BudgetService.getOrgBudgetYear());
            setYearList(result)
        } catch (error) {
            notify(error.message, "error", 2000)
        }

    }
    let maximizeIcon = '<svg t="1659165394467" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2309" width="16" height="16"><path d="M504.576 603.428571a19.748571 19.748571 0 0 1-5.705143 13.129143l-189.696 189.696 82.285714 82.285715a36.425143 36.425143 0 0 1 10.861715 25.709714c0 20.004571-16.566857 36.571429-36.571429 36.571428h-256c-20.004571 0-36.571429-16.566857-36.571428-36.571428v-256c0-20.004571 16.566857-36.571429 36.571428-36.571429a36.205714 36.205714 0 0 1 25.709714 10.861715l82.285715 82.285714 189.696-189.696c3.437714-3.437714 8.557714-5.705143 13.129143-5.705143s9.728 2.304 13.129142 5.705143l65.133715 65.133714a19.602286 19.602286 0 0 1 5.705143 13.129143zM950.857143 109.714286v256c0 20.004571-16.566857 36.571429-36.571429 36.571428a36.205714 36.205714 0 0 1-25.709714-10.861714l-82.285714-82.285714-189.696 189.696c-3.437714 3.437714-8.557714 5.705143-13.129143 5.705143s-9.728-2.304-13.129143-5.705143l-65.133714-65.133715c-3.437714-3.437714-5.705143-8.557714-5.705143-13.129142s2.304-9.728 5.705143-13.129143l189.696-189.696-82.285715-82.285715a36.425143 36.425143 0 0 1-10.861714-25.709714c0-20.004571 16.566857-36.571429 36.571429-36.571428h256c20.004571 0 36.571429 16.566857 36.571428 36.571428z" fill="#515151" p-id="2310"></path></svg>';

    const popUpHandler = (trueFalser, value) => {
        setFirstChartVisible(trueFalser);
        setWhichComponentMaximized(value)
    }

    return (
        <div>
            <Popup
                visible={firstChartVisible}
                onHiding={() => setFirstChartVisible(false)}
                hideOnOutsideClick={true}
                showCloseButton={true}
                height={550}
                title={whichComponentMaxmized && whichComponentMaxmized === 1 ? 'АЖИЛЛАГСДЫН ТОО /БАЙГУУЛЛАГААР/' : (whichComponentMaxmized === 2 ? 'АЖИЛЛАГСДЫН ТОО /жилээр/' : (whichComponentMaxmized === 3 ? 'НИЙТ ТЭТГЭВЭРТ ГАРАГЧ /БАЙГУУЛЛАГААР/' : 'НИЙТ ТЭТГЭВЭРТ ГАРАГЧ /жилээр/'))}
                showTitle={true}
            >
                {whichComponentMaxmized === 1 && <BudgetOtherTwo year={currentYear} chosenValue={chosenValue} />}
                {whichComponentMaxmized === 2 && <BudgetOtherThree chosenValue={chosenValue} />}
                {whichComponentMaxmized === 3 && <BudgetOtherThree chosenValue={chosenValue} />}
                {whichComponentMaxmized === 4 && <BudgetOtherFive chosenValue={chosenValue} />}
            </Popup>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ padding: 10 }}>
                    <div style={{ display: 'flex', flexWrap: 'wrap', gap: '1rem' }}>
                        <div>
                            <SelectBox
                                label="Төсвийн жил"
                                items={yearList}
                                valueExpr="value"
                                displayExpr="value"
                                width='10rem'
                                defaultValue={currentYear}
                                onValueChanged={(e) => { setCurrentYear(e.value) }}
                                placeholder="сонгох" style={{ borderRadius: 10 }} />
                        </div>
                        <div>
                            <SelectBox
                                label="Байгууллага"
                                valueExpr="value_name"
                                displayExpr="text_name"
                                items={orgs}
                                disabled={orgs && orgs.length === 1 ? true : false}
                                width='22rem'
                                value={chosenValue}
                                placeholder={user.organization.obt_id !== 3 ? 'БҮГД' : user.organization.name}
                                onValueChanged={(e) => setChosenValue(e.value)}
                                style={{ borderRadius: 10 }} />
                        </div>
                    </div>
                </div>
                <div style={{ flex: 1 }}>
                    <BudgetOtherOne year={currentYear} chosenValue={chosenValue} />
                </div>
                <div style={{ flex: 1, flexDirection: 'row' }} className="d-flex responsive-flex">
                    <div style={{ "margin-bottom": 10, flex: 0.5 }} className="col-6 responsive-col">
                        <div className="card" style={{ height: "34rem" }}>
                            <div className="card-header">
                                <div style={{ flex: 1, display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
                                    <span style={styles.title}>1. АЖИЛЛАГСДЫН ТОО /БАЙГУУЛЛАГААР/</span>
                                    <Button style={{ border: '0px solid transparent', borderTopRightRadius: '15px' }} icon={maximizeIcon} onClick={() => popUpHandler(true, 1)} />
                                </div>
                            </div>
                            <div className="card-body">
                                <BudgetOtherTwo year={currentYear} chosenValue={chosenValue} />
                            </div>
                        </div>
                    </div>
                    <div style={{ "margin-bottom": 10, flex: 0.5 }} className="col-6 responsive-col">
                        <div className="card" style={{ height: "34rem" }}>
                            <div className="card-header">
                                <div style={{ flex: 1, display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
                                    <span style={styles.title}>2. АЖИЛЛАГСДЫН ТОО /жилээр/</span>
                                    <Button style={{ border: '0px solid transparent', borderTopRightRadius: '15px' }} icon={maximizeIcon} onClick={() => popUpHandler(true, 2)} />
                                </div>
                            </div>
                            <div className="card-body">
                                <BudgetOtherThree chosenValue={chosenValue} />
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ flex: 1, flexDirection: 'row' }} className="d-flex responsive-flex">
                    <div style={{ "margin-bottom": 10, flex: 0.5 }} className="col-6 responsive-col">
                        <div className="card" style={{ height: "29rem" }}>
                            <div className="card-header">
                                <div style={{ flex: 1, display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
                                    <span style={styles.title}>3. НИЙТ ТЭТГЭВЭРТ ГАРАГЧ /БАЙГУУЛЛАГААР/</span>
                                    <Button style={{ border: '0px solid transparent', borderTopRightRadius: '15px' }} icon={maximizeIcon} onClick={() => popUpHandler(true, 3)} />
                                </div>
                            </div>
                            <div className="card-body">
                                <BudgetOtherFour year={currentYear} chosenValue={chosenValue} />
                            </div>
                        </div>
                    </div>
                    <div style={{ "margin-bottom": 10, flex: 0.5 }} className="col-6 responsive-col">
                        <div className="card" style={{ height: "29rem" }}>
                            <div className="card-header">
                                <div style={{ flex: 1, display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
                                    <span style={styles.title}>4. НИЙТ ТЭТГЭВЭРТ ГАРАГЧ /жилээр/</span>
                                    <Button style={{ border: '0px solid transparent', borderTopRightRadius: '15px' }} icon={maximizeIcon} onClick={() => popUpHandler(true, 4)} />
                                </div>
                            </div>
                            <div className="card-body">
                                <BudgetOtherFive chosenValue={chosenValue} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card">
                    <div className="card-header">
                        <span style={styles.title}>5. АЖИЛЛАГСДЫН ТОО /БАЙГУУЛЛАГА, ХӨТӨЛБӨРӨӨР/</span>
                    </div>
                    <div className="card-body">
                        <BudgetOtherSix year={currentYear} chosenValue={chosenValue} />
                    </div>
                </div>
                <SourceFooter />
            </div>
        </div>
    )
}
const styles = {
    title: {
        //fontFamily: "Segoe UI",
        fontWeight: "700",
    }
}
export default BudgetOtherInfo;

import React, { useEffect, useState } from "react";
import { numberWithCommas } from "../../../../util";
import BudgetService from "../../../../services/api/budget";
import { Label, Legend, Series, Tooltip} from "devextreme-react/chart";
import PieChart, { Export } from "devextreme-react/pie-chart";
import _ from 'lodash';
import { Button } from "devextreme-react";

function BudgetOfferEconTypeOne({ year, chosenOrganization }) {

  const [mainData, setMainData] = useState([])
  const [doughnutData, setDoughnutData] = useState([])
  const [titleArr, setTitleArr] = useState([])
  const [backCount, setBackCount] = useState(0)

  useEffect(() => {
    loadMainData();
    setTitleArr([]);
    setBackCount(0);
  }, [year, chosenOrganization])

  const loadMainData = async () => {
    try {
      let r = await BudgetService.getBudgetOfferBatlagdsanData(year, chosenOrganization)
      setMainData(r);
      let pp = _(r)
        .groupBy("econ_category_name_2")
        .map((row, key) => {
          return {
            name: key,
            amount: _.sumBy(row, "amount"),
          }
        })
        .value()
      setDoughnutData(pp)
    } catch (e) {
      console.log(e);
    }
  }

  const reducerFunc = (arg) => {
    return mainData.reduce((accumulator, currentItem) => {
      const categoryNames = [
        currentItem.econ_category_name_2,
        currentItem.econ_category_name_3,
        currentItem.econ_category_name_4,
        currentItem.econ_category_name,
      ];
      if (categoryNames.includes(arg)) {
        const key = categoryNames[categoryNames.indexOf(arg) + 1];
        setBackCount(categoryNames.indexOf(arg) + 1)
        if (key) {
          accumulator[key] = accumulator[key] || { name: key, amount: 0 };
          accumulator[key].amount += currentItem.amount;
        }
      }
      return accumulator
    }, {});
  }

  const handlePieClick = (e) => {
    const arg = e.target.argument;
    if (backCount < 4 && titleArr[titleArr.length - 1] !== arg) {
      setTitleArr([...titleArr, arg])
      const groupedData = reducerFunc(arg)
      const arr = Object.values(groupedData);
      setDoughnutData(arr)
    }
  }

  const handleBackData = () => {
    titleArr.pop()
    if (backCount === 1) {
      let pp = _(mainData)
        .groupBy("econ_category_name_2")
        .map((row, key) => {
          return {
            name: key,
            amount: _.sumBy(row, "amount"),
          }
        })
        .value()
      setDoughnutData(pp)
      setBackCount(0)
    } else {
      const arg = titleArr[titleArr.length - 1]
      const groupedData = reducerFunc(arg)
      const arr = Object.values(groupedData);
      setDoughnutData(arr)
      setBackCount(backCount - 1)
    }
  }

  const cstmText = (e) => {
    return `<span style="color: #f7f7fa">${numberWithCommas(e.value / 1000000)}<br/>${(e.percent * 100).toFixed(2)}%</span>`
  }

  const customizeTooltip = (arg) => {
    return {
      html: `<span class="tooltip-series-name">${arg.argumentText}: </span> ${numberWithCommas(arg.value / 1000000)} /${(arg.percent * 100).toFixed(2)}%/`
    }
  }

  const calculateTotal = (pieChart) => {
    let totalValue = pieChart.getAllSeries()[0].getAllPoints().reduce((s, p) => s + p.originalValue, 0);
    return `${numberWithCommas(totalValue / 1000000)}`;
  }

  const centerRender = (pieChart) => {
    return (
      <text textAnchor="middle" style={{ fontWeight: 600 }}>
        {!titleArr.length && <tspan x="0" dy="-1em">Төсөв</tspan>}
        <tspan x="0" dy="1em">{calculateTotal(pieChart)}</tspan>
      </text>
    );
  }

  return (
    <div style={{position: 'relative'}}>
      <Button className="button-container"
        text={`Буцах`}
        icon="chevronleft"
        visible={true}
        disabled={backCount === 0}
        onClick={handleBackData}
        style={{position: 'absolute', top: 10}}
      />
      <PieChart
        type="doughnut"
        dataSource={doughnutData}
        resolveLabelOverlapping={"hide"}
        centerRender={centerRender}
        onPointClick={handlePieClick}
        onLegendClick={({ points }) => points[0].isVisible() ? points[0].hide() : points[0].show()}
      >
        <Export enabled />
        <Series argumentField="name" valueField="amount" border={{ dashStyle: 'solid', width: 2, visible: true }}>
          <Label visible={true} customizeText={cstmText} connector={{ visible: true, width: 0.5 }} />
        </Series>
        <Legend
          visible={true}
          title={{ text: backCount > 0 && titleArr[titleArr.length - 1]?.slice(0, 30), font: { size: 14, weight: 600 } }}
          customizeText={(items) => items.pointName.length > 40 ? items.pointName.slice(0, 40) + `...` : items.pointName}
        />
        <Tooltip
          zIndex={10000}
          enabled={true}
          customizeTooltip={customizeTooltip}
          font={{ color: '#fff', family: 'Segoe UI' }}
          color="#555"
          cornerRadius={6}
          border={{ visible: false }}
          location="edge"
        />
      </PieChart>
    </div>
  )
}

export default BudgetOfferEconTypeOne

import React, { useEffect, useState } from "react";

import SelectBox from "devextreme-react/select-box";


import Button from "devextreme-react/button";


const Arline = (props) => {
  const index = props.index;
  const onChangeChart=props.onChangeChart
  const el = props.el;
   const removeTran = props.clickMinus;
   const accountSecondList=props.accountSecondList
    const selectedChart=props.selectedChart
  // const showIndex = props.showIndex;
   const updateFieldChanged = props.updateFieldChanged;
  //
  return (
    <div key={index}>


        <div className="col-12" style={{ display: 'flex', marginLeft: 10, marginTop: 1 }}>
            <Button
                icon="minus"
                text=""
                type="default" style={{alignment: "center", borderRadius: '9px'}}
                size="sm" onClick={() => removeTran(index)}>
            </Button>

          <div className="col-3" style={{ display: 'flex' }}>

            <input type = 'string'  name='descr' className = 'law-search-input' placeholder='Гүйлгээний утга'
                value={el.descr}   style={{  backgroundColor: '#FFFFFF', border: '1px solid #DDDDDD' }}
                   onChange={(e) => updateFieldChanged(index, e)}
            />
          </div>


          <div className="col-3" style={{ display: 'flex' }}>
            <input type = 'number' className = 'law-search-input' placeholder='Дүн'
                   name={'amount'}
                   style={{  backgroundColor: '#FFFFFF', border: '1px solid #DDDDDD' }}
                   value={el.amount}
                   onChange={(e) => updateFieldChanged(index, e)}


            />
          </div>
          <div className="col-2" style={{ display: 'flex' }}>
            <input
                name={'vat'}
                type = 'number' className = 'law-search-input' placeholder='нөт'
                   style={{  backgroundColor: '#FFFFFF', border: '1px solid #DDDDDD' }}
                   value={el.vat}
                   onChange={(e) => updateFieldChanged(index, e)}
            />
          </div>

          <div className="col-2" style={{ display: 'flex' }}>


            <SelectBox
                items={accountSecondList}
                value={el.acctcr}
                name={'acctcr'}
                valueExpr={(item) => {
                    return item && `${item.acct}`
                }}
                searchEnabled={true}
                style={{border: '1px solid #DDDDDD'}}s
                displayExpr={(item) => {
                  return item && `${item.acct} - ${item.descr}`
                }}

               onValueChanged={(e) => onChangeChart(e.value,index)}

                placeholder="кредит данс"/>


          </div>





        </div>









    </div>
  );
};

export default Arline;


import request from "../../../shared/lib/request";
// const axiosUrl = "https://fcs.mn:8081/fin/";

const axiosUrl = "//localhost:3002/api/";


export function PostTran(p1, urlPlus, updateModal) {

  let url=  axiosUrl + urlPlus

     if ( p1.length>0 ) ( url=url+ "p1=" + p1)

  return new Promise((resolve, reject) => {

    try {
      const result=  request({
        url: url,
        method: 'POST',
        data: updateModal,
      })
      resolve(result);
    }
    catch (e) {   reject(e)    }

  });
}


export function PutTran(p1, url, updateModal) {
  return new Promise((resolve, reject) => {
 //    url=  axiosUrl + url + "p1=" + p1
    url=   url + "p1=" + p1

    try {

      const result=  request({
        url: url,
        method: 'PUT',
        data: updateModal,
      })

      resolve(result);

    }

    catch (e) {
      reject(e)
    }

  })
  }

export function DeleteTran(p1, url, updateModal) {
  return new Promise((resolve, reject) => {

url=axiosUrl + url + "p1=" + p1


    try {

      const result=  request({
        url: url,
        method: 'DELETE',
        data: updateModal,
      })

      resolve(result);

    }

    catch (e) {
      reject(e)
    }



  });
}





export function GetTran(p1, page, urlPlus) {


  return new Promise((resolve, reject) => {
//    const url=axiosUrl + urlPlus + "p1=" + p1 + "&page=" + page
    const url= urlPlus + "p1=" + p1 + "&page=" + page


    try {

    const result=  request({
        url: url,
        method: 'GET'

      })

          resolve(result);

    }

    catch (e) {
      reject(e)
    }






  });
}





const Db = () => {
  return <div></div>;
};

export default Db;

import React, { useState, useEffect } from "react";
import SelectBox from "devextreme-react/select-box";
import OrlogoHeaderInfo from "./components/orlogo/OrlogoHeaderInfo";
import OrlogoZadgaiChart from "./components/orlogo/OrlogoZadgaiChart";
import OrlogoZadgaiGrid from "./components/orlogo/OrlogoZadgaiGrid";
import OrlogoPivotGird from "./components/orlogo/OrlogoPivotGrid";
import OrlogoLastChart from "./components/orlogo/OrlogoLastChart";
import { Button } from "devextreme-react/button";
import { Popup } from "devextreme-react/popup";
import BudgetService from "../../services/api/budget";
import notify from "devextreme/ui/notify";
import SourceFooter from "./components/Source";
import { UserContext } from "../../hooks/UserContext";
import ExpenseOnlyPivotGrid from "./components/zarlaga/ExpenseOnlyPivotGrid";
import { useContext } from "react";
import lawIcon from "../../assets/imgs/laaawIcon.svg";
import PlanGuide from "../../assets/imgs/Тайлан бэлдэх заавар аргачлал төсөл.pdf";
import { Accordion } from "devextreme-react";
import OrganizationSelectComponent from "../../components/OrganizationSelectComponent";
const onlyOrlogoTypes = [
  "Нийгмийн даатгалын сангийн төсвөөс санхүүжих",
  "Төсөвт байгууллагын үйл ажиллагаанаас",
  "Улсын төсвөөс санхүүжих",
  "Бүгд",
];

const BudgetOrlogo = () => {
  const { user } = useContext(UserContext);
  const [yearList, setYearList] = useState([]);
  const [law, setLaw] = useState([]);
  const [showLawModal, setShowLawModal] = useState(false);
  const [showOrderModal, setShowOrderModal] = useState(false);
  const [rootData, setRootData] = useState([]);
  const [calculatedPercentage, setCalculatedPercentage] = useState(null);
  const [calculatedGuitestgelPercentage, setCalculatedGuitsetgelPercentage] =
    useState(null);
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [selectedOrlogoType, setSelectedOrlogoTypes] = useState("Бүгд");
  const [chosenValue, setChosenValue] = useState("ALL");
  const [firstChartVisible, setFirstChartVisible] = useState(false);
  const [whichComponentMaxmized, setWhichComponentMaximized] = useState(null);
  const [pivotShower, setPivotShower] = useState(false);
  const [orgs, setOrgs] = useState([]);
  const [chosenDataGridFilter, setChosenDataGridFilter] = useState({
    monthField: "ALL",
    valueField: "Батлагдсан",
  });
  const [selectedData, setSelectedData] = useState({});
  const loadOrgs = async (year) => {
    try {
      let r = await BudgetService.getFilteredOrganizations(year, 3);
      setOrgs(
        r.length > 0 && r.length === 1
          ? r
          : [
              {
                budget_year: year,
                text_name: "БҮГД",
                value_name: "ALL",
              },
              ...r,
            ]
      );
      r.length > 1
        ? setChosenValue("ALL")
        : setChosenValue(r.length > 0 && r[0].value_name);
    } catch (e) {
      notify(e.message, "error", 2000);
    }
  };
  const loadLaw = async () => {
    try {
      let r = await BudgetService.getBudgetLawByYear(currentYear - 1);
      setLaw(r);
      console.log("::::", r);
    } catch (e) {
      notify(e.message, "error", 2000);
    }
  };
  const accordionData = [{ id: 1, title: "", file: PlanGuide }];
  const renderItems = (e) => {
    return (
      <object
        width="100%"
        height="90vh"
        style={{ height: "90vh" }}
        data={e.file}
        type="application/pdf"
      />
    );
  };

  const renderTitles = (e) => {
    return <span>{e.title}</span>;
  };
  useEffect(() => {
    loadYearList();
  }, []);

  useEffect(() => {
    loadOrgs(currentYear);
    loadLaw();
  }, [currentYear]);

  const loadYearList = async () => {
    try {
      let result = await await BudgetService.getOrgBudgetYear();
      setYearList(result);
    } catch (error) {
      notify(error.message, "error", 2000);
    }
  };
  const rootDataSetter = (root, handler) => {
    if (handler) {
      if (
        root &&
        rootData &&
        ((root.todotgoson2 && rootData.todotgoson2) ||
          (root.todotgoson && rootData.todotgoson) ||
          (root.batlagdsan && rootData.batlagdsan))
      ) {
        if (root.guitsetgel && rootData.guitsetgel) {
          setCalculatedGuitsetgelPercentage(
            (root.guitsetgel * 100) / rootData.guitsetgel
          );
        } else {
          setCalculatedGuitsetgelPercentage(null);
        }
        let a = root.todotgoson2
          ? root.todotgoson2
          : root.todotgoson
          ? root.todotgoson
          : root.batlagdsan;
        let b = rootData.todotgoson2
          ? rootData.todotgoson2
          : rootData.todotgoson
          ? rootData.todotgoson
          : rootData.batlagdsan;
        setCalculatedPercentage((a * 100) / b);
      } else {
        setCalculatedPercentage("");
        setCalculatedGuitsetgelPercentage("");
      }
    } else {
      setCalculatedPercentage(null);
      setCalculatedGuitsetgelPercentage(null);
      setRootData(root);
    }
  };

  const dataGridFilterHandler = (value) => {
    if (
      value.monthField === chosenDataGridFilter.monthField &&
      value.valueField === chosenDataGridFilter.valueField
    ) {
      setChosenDataGridFilter({
        monthField: "ALL",
        valueField: "Батлагдсан",
      });
    } else {
      setChosenDataGridFilter(value);
    }
  };

  const globalFilterHandler = (value) => {
    // if (orgs.length>1){
    //     if (value === chosenValue) {
    //         setChosenValue('ALL')
    // }
    //     else {
    //     setChosenValue(value);
    // }
    // } else{
    //     setChosenValue('ALL')
    // }
  };

  const pivotVisibleHandler = () => {
    setPivotShower(!pivotShower);
  };

  const popUpHandler = (trueFalser, value) => {
    setFirstChartVisible(trueFalser);
    setWhichComponentMaximized(value);
  };

  let maximizeIcon =
    '<svg t="1659165394467" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2309" width="16" height="16"><path d="M504.576 603.428571a19.748571 19.748571 0 0 1-5.705143 13.129143l-189.696 189.696 82.285714 82.285715a36.425143 36.425143 0 0 1 10.861715 25.709714c0 20.004571-16.566857 36.571429-36.571429 36.571428h-256c-20.004571 0-36.571429-16.566857-36.571428-36.571428v-256c0-20.004571 16.566857-36.571429 36.571428-36.571429a36.205714 36.205714 0 0 1 25.709714 10.861715l82.285715 82.285714 189.696-189.696c3.437714-3.437714 8.557714-5.705143 13.129143-5.705143s9.728 2.304 13.129142 5.705143l65.133715 65.133714a19.602286 19.602286 0 0 1 5.705143 13.129143zM950.857143 109.714286v256c0 20.004571-16.566857 36.571429-36.571429 36.571428a36.205714 36.205714 0 0 1-25.709714-10.861714l-82.285714-82.285714-189.696 189.696c-3.437714 3.437714-8.557714 5.705143-13.129143 5.705143s-9.728-2.304-13.129143-5.705143l-65.133714-65.133715c-3.437714-3.437714-5.705143-8.557714-5.705143-13.129142s2.304-9.728 5.705143-13.129143l189.696-189.696-82.285715-82.285715a36.425143 36.425143 0 0 1-10.861714-25.709714c0-20.004571 16.566857-36.571429 36.571429-36.571428h256c20.004571 0 36.571429 16.566857 36.571428 36.571428z" fill="#515151" p-id="2310"></path></svg>';

  return (
    <React.Fragment>
      <Popup
        visible={firstChartVisible}
        onHiding={() => setFirstChartVisible(false)}
        hideOnOutsideClick={true}
        showCloseButton={true}
        height={560}
        title={
          whichComponentMaxmized && whichComponentMaxmized === 1
            ? "БАЙГУУЛЛАГААР /сая ₮/"
            : whichComponentMaxmized === 2
            ? "ТӨСӨВ, ГҮЙЦЭТГЭЛ /жилээр, сая ₮/"
            : whichComponentMaxmized === 3
            ? "ЭХ ҮҮСВЭРИЙН БҮТЭЦ /ЭЗА, сая ₮/"
            : "ТӨСӨВ, ГҮЙЦЭТГЭЛ /сараар, сая ₮/"
        }
        showTitle={true}
      >
        {whichComponentMaxmized === 1 && selectedData.orgId && typeof selectedData.orgId[0] === "number" && (
         /* <UpdatedBudgetDoughnutChart
            currentYear={currentYear}
            currentType={4}
            callFunc={2}
            visibleLegend={true}
            orgId = {selectedData.orgId}
          />*/
            <OrlogoZadgaiGrid
                year={currentYear}
                orlogoType={selectedOrlogoType}
                callFunc={globalFilterHandler}
                orgId = {selectedData.orgId}
            />
        )}
        {whichComponentMaxmized === 2 && selectedData.orgId && typeof selectedData.orgId[0] === "number" && (
          <OrlogoLastChart
            orlogoType={selectedOrlogoType}
            filterOrgText={chosenValue}
            orgId = {selectedData.orgId}
          />
        )}
        {whichComponentMaxmized === 3 && selectedData.orgId && typeof selectedData.orgId[0] === "number" && (
          <OrlogoPivotGird
            year={currentYear}
            orlogoType={selectedOrlogoType}
            filterOrgText={chosenValue}
            pivotHandler={pivotVisibleHandler}
            orgId = {selectedData.orgId}
          />
        )}
        {whichComponentMaxmized === 4 && selectedData.orgId && typeof selectedData.orgId[0] === "number" && (
          <OrlogoZadgaiChart
            year={currentYear}
            orlogoType={selectedOrlogoType}
            chosenValue={chosenValue}
            callFunc={dataGridFilterHandler}
            orgId = {selectedData.orgId}
          />
        )}
      </Popup>
      <Popup
        visible={showLawModal}
        onHiding={() => setShowLawModal(false)}
        hideOnOutsideClick={true}
        showTitle={false}
      >
        <div style={{ height: "100%" }}>
          {law && law.length != 0 && showLawModal && (
            <iframe
              id="lawForBudget"
              width="100%"
              height="100%"
              src={law[0].legal_url}
            ></iframe>
          )}
        </div>
      </Popup>
      <Popup
        visible={showOrderModal}
        onHiding={() => setShowOrderModal(false)}
        hideOnOutsideClick={true}
        height="90vh"
        showTitle={false}
      >
        <Accordion
          dataSource={accordionData}
          collapsible={false}
          multiple={false}
          itemTitleRender={renderTitles}
          itemRender={renderItems}
        />
      </Popup>

      <div style={{ padding: 10 }}>
        <div
          style={{
            display: "flex",
            gap: "1rem",
            flexWrap: "wrap",
            alignItems: "center",
          }}
        >
          <div>
            <SelectBox
              label="Төсвийн жил"
              items={yearList}
              valueExpr="value"
              displayExpr="value"
              width="8rem"
              defaultValue={currentYear}
              onValueChanged={(e) => {
                setCurrentYear(e.value);
                loadOrgs(e.value);
              }}
              placeholder="сонгох"
              style={{ borderRadius: 10 }}
            />
          </div>
          <div>
            {/*<SelectBox
              items={orgs}
              label="Байгууллага"
              width="22rem"
              value={chosenValue}
              disabled={orgs && orgs.length === 1 ? true : false}
              valueExpr="value_name"
              displayExpr="text_name"
              onValueChanged={(e) => setChosenValue(e.value)}
              placeholder={
                user.organization.obt_id !== 3 ? "БҮГД" : user.organization.name
              }
              style={{ borderRadius: 10 }}
            />*/}
            <OrganizationSelectComponent
                selectedData={(d) => {
                  setSelectedData(d)
                }}
                selectedYear={(d) => d}
                showClearButton={false}
                showYear={false}
                hideLabel={true}
                labelMode = {'floating'}
                width = {600}
                type = {3}
                year = {currentYear}
                defaultId = {(user.organization.obt_id === 2 && user.organization.parent_id !== user.organization.parent_org_id) ? user.organization.parent_id : user.organization.id}
            />
          </div>
          <div>
            <SelectBox
              label="Эдийн засгийн ангилал"
              items={onlyOrlogoTypes}
              width="18rem"
              defaultValue={selectedOrlogoType}
              onValueChanged={(e) => setSelectedOrlogoTypes(e.value)}
              placeholder="сонгох"
              style={{ borderRadius: 10 }}
            />
          </div>
          {law && law.length !== 0 && (
            <div style={{ height: 28.5 }}>
              <Button
                style={{ fontFamily: "Segoe UI", fontWeight: "bold" }}
                icon={lawIcon}
                onClick={() => setShowLawModal(true)}
                text={law[0].name}
              />
            </div>
          )}
        </div>

        <div
          style={{
            display: "flex",
            flex: 1,
            alignItems: "center",
            gap: "1rem",
          }}
        >
          {calculatedPercentage && (
            <span style={{ fontWeight: "bold" }}>
              Нийт төсвийн:{" "}
              <span style={{ color: "#ff0000", paddingLeft: 10 }}>
                {calculatedPercentage.toFixed(2)}%
              </span>
            </span>
          )}
          {calculatedGuitestgelPercentage && (
            <span style={{ fontWeight: "bold" }}>
              Нийт гүйцэтгэлийн:{" "}
              <span style={{ color: "#264b96", paddingLeft: 10 }}>
                {calculatedGuitestgelPercentage.toFixed(2)}%
              </span>
            </span>
          )}
        </div>
      </div>

      <div style={{ display: "flex", flexDirection: "column" }}>
        {selectedData.orgId && typeof selectedData.orgId[0] === "number" && <OrlogoHeaderInfo
            year={currentYear}
            orlogoType={selectedOrlogoType}
            chosenOrgText={chosenValue}
            rootDataSetter={rootDataSetter}
            orgId = {selectedData.orgId}
        />
        }
        <div
          style={{ flexDirection: "row", flex: 1 }}
          className="d-flex responsive-flex"
        >
          <div
            style={{ "margin-bottom": 10, flex: 0.5 }}
            className="col-6 responsive-col"
          >
            <div className="card" style={{ height: "100%" }}>
              <div className="card-header">
                <div
                  style={{
                    flex: 1,
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "row",
                  }}
                >
                  <span style={{ fontWeight: "700" }}>
                    1. БАЙГУУЛЛАГААР /сая ₮/
                  </span>
                  <Button
                    style={{
                      border: "0px solid transparent",
                      borderTopRightRadius: "15px",
                    }}
                    icon={maximizeIcon}
                    onClick={() => popUpHandler(true, 1)}
                  />
                </div>
              </div>
              {
                selectedData.orgId && typeof selectedData.orgId[0] === "number" && <div className="card-body">
                <OrlogoZadgaiGrid
                  year={currentYear}
                  orlogoType={selectedOrlogoType}
                  callFunc={globalFilterHandler}
                  orgId = {selectedData.orgId}
                />
              </div>
              }
            </div>
          </div>
          <div
            style={{ "margin-bottom": 10, flex: 0.5 }}
            className="col-6 responsive-col"
          >
            <div className="card" style={{ height: "100%" }}>
              <div className="card-header">
                <div
                  style={{
                    flex: 1,
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "row",
                  }}
                >
                  <span style={{ fontWeight: "700" }}>
                    2. ТӨСӨВ, ГҮЙЦЭТГЭЛ /жилээр, сая ₮/
                  </span>
                  <Button
                    style={{
                      border: "0px solid transparent",
                      borderTopRightRadius: "15px",
                    }}
                    icon={maximizeIcon}
                    onClick={() => popUpHandler(true, 2)}
                  />
                </div>
              </div>
              {
                  selectedData.orgId && typeof selectedData.orgId[0] === "number" &&
                  <div className="card-body" style={{marginTop: "1rem"}}>
                    <OrlogoLastChart
                        orlogoType={selectedOrlogoType}
                        orgId = {selectedData.orgId}
                    />
                  </div>
              }
            </div>
          </div>
        </div>
        <div
          style={{ flexDirection: "row", flex: 1 }}
          className="d-flex responsive-flex"
        >
          <div
            style={{ "margin-bottom": 10, flex: 0.5 }}
            className="col-6 responsive-col"
          >
            <div className="card" style={{ height: "30rem" }}>
              <div className="card-header">
                <div
                  style={{
                    flex: 1,
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "row",
                  }}
                >
                  <span style={{ fontWeight: "700" }}>
                    3. ЭХ ҮҮСВЭРИЙН БҮРДЭЛ /ЭЗА, сая ₮/
                  </span>
                  <Button
                    style={{
                      border: "0px solid transparent",
                      borderTopRightRadius: "15px",
                    }}
                    icon={maximizeIcon}
                    onClick={() => popUpHandler(true, 3)}
                  />
                </div>
              </div>
              {
                  selectedData.orgId && typeof selectedData.orgId[0] === "number" && <div className="card-body">
                    <OrlogoPivotGird
                        year={currentYear}
                        orlogoType={selectedOrlogoType}
                        filterOrgText={chosenValue}
                        firstChartVisible={firstChartVisible}
                        pivotHandler={pivotVisibleHandler}
                        orgId = {selectedData.orgId}
                    />
                  </div>
              }
            </div>
          </div>
          <div
            style={{ "margin-bottom": 10, flex: 0.5 }}
            className="col-6 responsive-col"
          >
            <div className="card" style={{ height: "30rem" }}>
              <div className="card-header">
                <div
                  style={{
                    flex: 1,
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "row",
                  }}
                >
                  <span style={{ fontWeight: "700" }}>
                    4. ТӨСӨВ, ГҮЙЦЭТГЭЛ /сараар, сая ₮/{" "}
                    {chosenValue === "ALL" ? "" : chosenValue}
                  </span>
                  <Button
                    style={{
                      border: "0px solid transparent",
                      borderTopRightRadius: "15px",
                    }}
                    icon={maximizeIcon}
                    onClick={() => popUpHandler(true, 4)}
                  />
                </div>
              </div>
              {
                  selectedData.orgId && typeof selectedData.orgId[0] === "number" && <div className="card-body">
                    <OrlogoZadgaiChart
                        year={currentYear}
                        orlogoType={selectedOrlogoType}
                        chosenValue={chosenValue}
                        callFunc={dataGridFilterHandler}
                        orgId = {selectedData.orgId}
                    />
                  </div>
              }
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-header">
            <span style={{ fontWeight: "700" }}>
              5. ЭДИЙН ЗАСГИЙН АНГИЛЛААР /сая ₮/
            </span>
          </div>
          {
              selectedData.orgId && typeof selectedData.orgId[0] === "number" && <div className="card-body">
                <ExpenseOnlyPivotGrid
                    year={currentYear}
                    chosenMinistry={"ALL"}
                    chosenValue={chosenValue}
                    calculatedPercentage={calculatedPercentage}
                    calculatedGuitestgelPercentage={calculatedGuitestgelPercentage}
                    econType={3}
                    type="Бүгд"
                    orgId = {selectedData.orgId}
                />
              </div>
          }
        </div>
      </div>
      <SourceFooter />
    </React.Fragment>
  );
};

export default BudgetOrlogo;

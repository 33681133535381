import React, { useEffect, useState } from "react";
import BudgetService from "../../../../services/api/budget";
import _ from "lodash";
import notify from "devextreme/ui/notify";
import { blueColorGradient, budgetPalettes, getStripValues, greenColorGradient, numberWithCommas } from "../../../../util";
import { Label, Legend, Series, Tooltip } from "devextreme-react/pie-chart";
import { Chart, CommonSeriesSettings, Crosshair, ValueAxis, Point, ArgumentAxis, Strip, ScrollBar, ZoomAndPan } from "devextreme-react/chart";
import LoadPanelComponent from "../../../../components/LoadPanelComponent";
import EmptyData from "../../../budget/components/empty";

const TowloruulehTenYears = (props) => {
    let palette = ["red", "green", "blue", "yellow", "orange", "cyan"];
    const mapperData = [
        { id: 1, valueField: 'e_mend_amount_bat', name: 'Батлагдсан - Эрүүл мэндийн даатгалын сангаас санхүүжих', stack: 'batlagdsan', color: 'red' },
        { id: 2, valueField: 'tosovt_amount_bat', name: 'Батлагдсан - Төсөв байгууллагын үйл ажиллагаанаас', stack: 'batlagdsan', color: 'blue' },
        { id: 3, valueField: 'tosovoos_amount_bat', name: 'Батлагдсан - Улсын төсвөөс санхүүжих', stack: 'batlagdsan', color: 'green' },
        { id: 4, valueField: 'e_mend_amount_guitsetgel', name: 'Гүйцэтгэл - Эрүүл мэндийн даатгалын сангаас санхүүжих', stack: 'guitsetgel', color: 'yellow' },
        { id: 5, valueField: 'tosovt_amount_guitsetgel', name: 'Гүйцэтгэл - Төсөв байгууллагын үйл ажиллагаанаас', stack: 'guitsetgel', color: 'orange' },
        { id: 6, valueField: 'tosovoos_amount_guitsetgel', name: 'Гүйцэтгэл - Улсын төсвөөс санхүүжих', stack: 'guitsetgel', color: 'cyan' },
    ]
    const [chartData, setChartData] = useState([]);
    //const [mapper, setMapper] = useState(mapperData);
    const [loader, setLoader] = useState(true);
    /*const [selector, setSelector] = useState([
        {
            compareText: 'Эрүүл мэндийн даатгалын сангаас санхүүжих',
            ids: [1, 4],
        },
        {
            compareText: 'Улсын төсвөөс санхүүжих',
            ids: [3, 6]
        },
        {
            compareText: 'Төсөв байгууллагын үйл ажиллагаанаас',
            ids: [2, 5]
        },
        {
            compareText: 'Бүгд',
            ids: [1, 2, 3, 4, 5, 6]
        },
    ])*/


    const loadChartData = async () => {
        try {
            setLoader(true);
            // console.log("Getincometenyears==>", props.chosenMinistry)
            let r = await BudgetService.getIncomeTenYears(props.chosenValue, props.chosenMinistry ? props.chosenMinistry : "ALL",props.orgId);
            setChartData(r);
        } catch (e) {
            notify(e.message, "error", 2000);
        } finally {
            setLoader(false)
        }
    }

    useEffect(() => {
        loadChartData();
    }, [props.chosenValue, props.chosenMinistry,props.orgId]);

    const renderLabel = (e) => {
        return `${e.percentText}`
    }

    const customizeTooltip = (arg) => {
        if (arg.seriesName === 'Төсвийн өөрчлөлт' || arg.seriesName === 'Төсөв') {
            let r = _.find(chartData, { 'budget_year': (arg.argument * 1 - 1).toString() });
            let q = (arg.value / (r && r.todotgoson)).toFixed(2);
            let p = ((arg.value * 100 / (r && r.todotgoson)) - 100).toFixed(2);
            let d = (arg.value - (r && r.todotgoson));
            let f = d < 0 ? 'бага' : 'их';
            if (isFinite(q) && !isNaN(q)) {
                return {
                    html: `<div class="tooltip-header">
                    <span>Төсөв ${arg.argumentText} он:</span>
                    <span>${numberWithCommas(arg.value / 1000000)} </span>
                    </div>
                    <hr/>
                    <b><i>Өмнөх жилээс</i></b><br/>
                    <span class="tooltip-series-name">Зөрүү:</span> ${numberWithCommas(d / 1000000)}<br/> 
                    <span class="tooltip-series-name">Хувь:</span> ${p}%<br/> 
                    <span class="tooltip-series-name">Өөрчлөлт:</span> ${q} дахин ${f}`,
                }
            } else {
                return {
                    html: `<div class="tooltip-header">
                    <span>Төсөв ${arg.argumentText} он:</span>
                    <span>${numberWithCommas(arg.value / 1000000)} </span>
                    </div>`
                }
            }
        }
        else if (arg.seriesName === 'Гүйцэтгэлийн өөрчлөлт') {
            let r = _.find(chartData, { 'budget_year': (arg.argument * 1 - 1).toString() });
            let q = (arg.value / (r && r.guitsetgel)).toFixed(2);
            let p = ((arg.value * 100 / (r && r.guitsetgel)) - 100).toFixed(2);
            let d = (arg.value - (r && r.guitsetgel));
            let f = d < 0 ? 'бага' : 'их';
            let r1 = _.find(chartData, { 'budget_year': (arg.argument * 1).toString() });
            let u = ((r1 && r1.todotgoson) - arg.value);
            let u1 = ((arg.value * 100 / (r1 && r1.todotgoson))).toFixed(2);
            if (isFinite(q) && !isNaN(q)) {
                return {
                    html: `<div class="tooltip-header">
                    <span>Гүйцэтгэл ${arg.argumentText} он:</span>
                    <span>${numberWithCommas(arg.value / 1000000)}</span>
                    </div>
                    <hr/>
                    <b><i>Төсвөөс </b></i><br/>
                    <span class="tooltip-series-name">Зөрүү: </span>${numberWithCommas(u / 1000000)} <br/>
                    <span class="tooltip-series-name" style={{marginBottom: 10}}>Xувь: </span>${u1}% <br/>
                    <hr/>
                    <b><i>Өмнөх жилээс </b></i><br/>
                    <span class="tooltip-series-name">Зөрүү: </span>${numberWithCommas(d / 1000000)} <br/>
                    <span class="tooltip-series-name">Хувь: </span>${p}% <br/>
                    <span class="tooltip-series-name">Өөрчлөлт: </span>${q} дахин ${f}<br/> `,
                }
            } else {
                return {
                    html: `<div class="tooltip-header">
                    <span>Гүйцэтгэл ${arg.argumentText} он:</span>
                    <span>${numberWithCommas(arg.value / 1000000)}</span>
                    </div>
                    <hr/>
                    <b><i>Төсвөөс </b></i><br/>
                    <span class="tooltip-series-name">Зөрүү: </span>${numberWithCommas(u / 1000000)} <br/>
                    <span class="tooltip-series-name" style={{marginBottom: 10}}>Xувь: </span>${u1}% <br/>`
                }
            }
        } else if (arg.seriesName === "Гүйцэтгэл") {
            return {
                html: `<div class="tooltip-header">
              <span>Гүйцэтгэл ${arg.argumentText} он:</span>
              <span>${numberWithCommas(arg.value / 1000000)}</span>
              </div>
              <hr/>
              <b><i>Төсвөөс </b></i><br/>
              <span class="tooltip-series-name">Зөрүү: </span> ${numberWithCommas((arg.point.data.todotgoson - arg.value) / 1000000)}<br/> 
              <span class="tooltip-series-name">Хувь:</span> ${((arg.value / 1000000) / (arg.point.data.todotgoson / 1000000) * 100).toFixed(2)}%`
            }
        }
    }

    const customizeValueAxisLabel = (e) => {
        return `${e.value / 1000000000}`
    }

    /*const selectionChangeHandler = (e) => {
        let arr = [];
        e.value.ids.forEach(item => {
            arr.push(_.find(mapperData, { 'id': item }))
        })
        setMapper(arr);
    }*/
    if (chartData?.length === 0 && !loader) {
        return <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                family: "Segoe UI",
            }}
        >
            <EmptyData />
        </div>
    }

    const handleLegend = (e) => {
        if (e.target.isVisible()) {
            e.target.hide();
        } else {
            e.target.show();
        }
    }


    function markerTemplate(item) {
        const color = item.series.isVisible() ? item.marker.fill : '#888';
        return (
            <svg>
                <rect x={0} y={0} width={12} height={12} fill={color}
                    rx={item.text.includes('өөрчлөлт') ? 6 : 0}
                    ry={item.text.includes('өөрчлөлт') ? 6 : 0}></rect>
            </svg>
        );
    }

    function customizeColor(items) {
        if (items.seriesName === 'Гүйцэтгэл') {
            return { color: blueColorGradient[items.index] }
        } else if (items.seriesName === 'Төсөв') {
            return { color: greenColorGradient[items.index] }
        }
    }

    return (
        <div style={{ height: '452px', marginTop: '2rem' }} id="orlogoContainer">
            <LoadPanelComponent
                position={{ of: '#orlogoContainer' }}
                visible={loader}
            />
            <Chart
                dataSource={chartData}
                palette={_.find(budgetPalettes, { 'id': 2 }).color}
                paletteExtensionMode="alternate"
                onLegendClick={handleLegend}
                customizePoint={customizeColor}
            >
                <CommonSeriesSettings
                    argumentField="budget_year"
                    type="bar"
                    barOverlapGroup="orlogoStack"
                    cornerRadius={6}
                >
                    <Label backgroundColor="none" visible={false} customizeText={renderLabel} font={{ family: "Segoe UI" }} />
                    <Point size={10} />
                </CommonSeriesSettings>
                <Crosshair enabled={true} label={true} />
                <ValueAxis>
                    <Label customizeText={customizeValueAxisLabel} />
                </ValueAxis>
                <ArgumentAxis tickInterval={1} argumentType='numeric' visualRange={{ endValue: new Date().getFullYear() }}>
                    {getStripValues().map((e) => (
                        <Strip startValue={e.startValue} endValue={e.endValue} color="#f5f5f5" />
                    ))}
                    <Label format='#' argumentFormat="format" />
                </ArgumentAxis>
                <ScrollBar visible={true} width={5} opacity={0.5} />
                <ZoomAndPan argumentAxis="both" />
                <Series stack="orlogoStack" valueField="todotgoson" name="Төсөв" />
                <Series barPadding="0.4" stack="orlogoStack" valueField="guitsetgel" name="Гүйцэтгэл" />
                <Series valueField="todotgoson" name="Төсвийн өөрчлөлт" type="spline" color="#4F957D" dashStyle="solid" visible={false} />
                <Series valueField="guitsetgel" name="Гүйцэтгэлийн өөрчлөлт" type="spline" color="#264B96" dashStyle="solid" />
                <Legend visible="true" verticalAlignment="bottom" horizontalAlignment="center" font={{ family: "Segoe UI" }} markerRender={markerTemplate} />
                <Tooltip
                    zIndex={100000}
                    enabled={true}
                    customizeTooltip={customizeTooltip}
                    location="edge"
                    font={{ color: '#fff' }} color="#555"
                    cornerRadius={6}
                    border={{ visible: false }}
                />
            </Chart>
        </div>
    )
}

export default TowloruulehTenYears;

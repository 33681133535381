import React, {useContext, useEffect, useState, useRef} from 'react';
import 'devextreme-react/text-area';
import DataGrid, {
    Editing,
    Column,
    RequiredRule,
    PatternRule,
    Lookup, Summary, TotalItem, SearchPanel, Export, GroupPanel, Grouping, GroupItem, Button as DevButton
} from 'devextreme-react/data-grid';
import {UserContext} from "../../../hooks/UserContext";
import _ from "lodash";
import fcsService from "../../../services/api/fcs";
import Button from "devextreme-react/button";
import notify from "devextreme/ui/notify";
import SelectBox from "devextreme-react/select-box";
import DateBox from "devextreme-react/date-box";
import {LoadPanel} from "devextreme-react/load-panel";
import {Popup} from "devextreme-react/popup";

import CustorgParent from "../Gl/gledit/custorgParent";
import {GetTran} from "../tran/Db";
import LoadPanelComponent from '../../../components/LoadPanelComponent';

function ApSelect(props) {
    //  const monthValue = props.monthValue
    const userListPosition = {of: '#usersList'}
    const {user} = useContext(UserContext);
    const [listData, setListData] = useState([]);
    const [loader, setLoader] = useState(false);
    const myBabyRef = useRef(null);
    const [dataFcsChart, setFcsChart] = useState([]);
    const [dataFcsApClass, setFcsApClass] = useState([]);
    const [dataFcsMonth, setFcsMonth] = useState([]);
    const initCust = {name: ''}
    const [custValue, setCustValue] = useState({...initCust});
    const [apClassValue, setApClassValue] = useState(null);
    const [docDate, setDocDate] = useState();
    const [brn, setBrn] = useState();

    const [selectedSt4New, setSelectedSt4New] = useState([]);
    const [dataFcsSt4New, setFcsSt4New] = useState([]);
    const [modalVisibleCustOrg, setModalVisibleCustOrg] = useState(false)
    const [transValue, setTransValue] = useState('');
    const [docno, setDocno] = useState(0);
const [yearValue,setYearValue]=useState(0)
    const [monthValue,setMonthValue]=useState(0)


    useEffect(() => {
        fcsApSelectList();
    }, [])

    useEffect(() => {
        getOpenAmt();
    }, [custValue])

    useEffect(() => {
        setDocDate(new Date());

    }, []);


    const changeMonth = async () => {
        const fcsMonth = await fcsService.getAllFcsMonth('AP');
        setFcsMonth(fcsMonth);
    }
    const fcsApSelectList = async () => {
        try {
            setLoader(true);
            const fcsChart = await fcsService.getAllFcsChart();
            setFcsChart(fcsChart);
            const fcsSt4New = await fcsService.getAllFcsSt4New('TSB',0,2);
            setFcsSt4New(fcsSt4New);

            // const fcsApClass = await fcsService.getAllFcsApClass(false);
            //

            const fcsApClass = await fcsService.getFcsChart('BB',0,0,0,0);
            setFcsApClass(fcsApClass);


            getDocno()

            return setLoader(false)
        } catch (e) {
            console.error(e);
            setLoader(false);
        }
    }


    const getDocno = async () => {
        const fcsMonth = await fcsService.getAllFcsMonth('AP');

        setFcsMonth(fcsMonth);
        fcsMonth.map((item) => {
            setDocno(item.zarid)
            setYearValue(item.year)
            setMonthValue(item.month)

        })
    }
    const handleChangeValue = (event) => {
        setTransValue(event.target.value);
    }
    const onChangeSt4New = async (e) => {
        setSelectedSt4New(e);
    }

    const calculateAmt = (newData, value, currentRowData) => {
        if (value * currentRowData.unit > currentRowData.openamt) {
            newData.amt = 0
            newData.qty = 0
        } else {
            newData.qty = value;
            newData.amt = currentRowData.unit * value;
        }
        currentRowData.qty = newData.qty
        currentRowData.amt = newData.amt
    }
    const calculateAmt1 = (newData, value, currentRowData) => {
        if (value * currentRowData.qty > currentRowData.openamt) {
            newData.unit = 0
            newData.amt = 0
        } else {
            newData.unit = value;
            newData.amt = currentRowData.qty * value;

        }

        currentRowData.unit = newData.unit
        currentRowData.amt = newData.amt


    }
    const setApSelect = async () => {
        if (listData.length === 0 || listData === null) {
            notify("Төлөлтийн мэдээлэл хоосон байна", "warning");
        } else if (brn === undefined || brn === '') {
            notify("Баримтын дугаар хоосон байна", "warning");
        } else if (transValue === undefined || transValue === '') {
            notify("Гүйлгээний утга хоосон байна", "warning");
        } else if (custValue.name.length < 2 || custValue === '') {
            notify("Харилцагч хоосон байна", "warning");
        } else if (docDate === undefined || docDate === null) {
            notify("Огноо хоосон байна", "warning");
        } else if (apClassValue === null || apClassValue === '') {
            notify("Мөнгөний данс хоосон байна", "warning");
        }
        if (listData.length !== 0 && brn && custValue && docDate && apClassValue && transValue.length > 0) {
            setLoader(true);
            //yyyymm
            const date = new Date(docDate);//new e.changes[0].data.docdate;
            const year = date.getFullYear() * 1e4; // 1e4 gives us the the other digits to be filled later, so 20210000.
            const month = (date.getMonth() + 1) * 100;
            const yyyymm = year + month // `+ ''` to convert to string from number, 202206
            const fcsCurrentMonth = dataFcsMonth.map((item) => {
                let date = item.year + '/' + item.month
                let datetimenew = new Date(date)
                let year = datetimenew.getFullYear() * 1e4; // 1e4 gives us the the other digits to be filled later, so 20210000.
                let month = (datetimenew.getMonth() + 1) * 100;
                const yyyymm_month = year + month
                return yyyymm_month
            });
            if (yyyymm === fcsCurrentMonth[0]) {
                try {
                    const result = await fcsService.getAllFcsMonth('AP');
                    result.map(e => {
                        e.zarid = e.zarid + 1
                    })

                    listData.map((item, index) => {
                        item.docdate = docDate;
                        item.docno = parseInt(result[0].zarid);
                        item.org_id = user.org_id;
                        item.created_user_id = user.id;
                        item.brn = brn;
                        item.created_user_id = user.id;
                        item.cash_acct = apClassValue.acct;
                        item.acct_name = apClassValue.descr;
                        item.st4 = selectedSt4New.code;
                        item.descr = transValue
                        item.cust = custValue.orgcode;
                        item.custname = custValue.name;
                        // if (item.amt <= 0) {
                        //     delete listData[index]
                        // }

                    })


                    //    setTran(Tran.filter((item, index) => index !== ind));
                    var resultSave = _.filter(listData, function (user) {
                        return user.amt > 0;
                    });

                    await fcsService.addFcsApSelect(resultSave);
                    await fcsService.editFcsMonth(result[0]);
                    setListData([]);
                    await getDocno()
                    // setBrn('');

                    // setApClassValue([]);

                    // await fcsApSelectList();

                    notify('Амжилттай гүйлгээ хийгдлээ', "success", 2000);

                    return setLoader(false)
                } catch (e) {
                    notify('Шинээр гүйлгээ хийх явцад алдаа гарлаа', "error", 2000);
                    return setLoader(false)
                }


            } else {
                notify('Сонгосон огноо тухайн тайлант он сард биш байна', "error", 2000);
                return setLoader(false)
            }
        }

    }

    const onChangeApClassValue = (e) => {
        setApClassValue(e);

    }
    const onValueChangedDate = (e) => {
        setDocDate(e.value);
    }
    const onChangeDocNoValue = (event) => {
        setBrn(event.target.value);
    }


    const onInitNewRow = (e) => {
        e.data.unit = 1;
        e.data.vat = 0;
    }
    const onRowInserted = (e) => {
        e.component.navigateToRow(e.key);
    }
    const saveDataGrid = async (e) => {
    }
    const onEditingStart = (e) => {
    }
    const customizeDate = (data) => {
        return Number(parseFloat(data.value).toFixed(2)).toLocaleString("en", {
            minimumFractionDigits: 2,
        });
    }


    const getOpenAmt = () => {
        let urlAdd = '';
        (custValue.name.length < 2 || custValue === '') ?  urlAdd = "apreport/apselectall?":  urlAdd = "apreport/apselect?"

        let p1 = ""
        p1 = p1 + "&year="+ yearValue+ "&month="+monthValue+"&orgcode=" + custValue.orgcode;



        GetTran(p1, 0, urlAdd)
            .then((result) => {
                setListData(result.data.data);
            })
            .catch((error) => {
                setListData([]);
            });
    };

    const style={
        text: {
            color: "#2E4765", fontWeight: "700", fontSize: ".875rem"
        },
        box: {
            border: "none", backgroundColor: "#F6F6F6", borderRadius: ".5rem", padding: ".25rem .15rem", width: "100%"
        }
    }
    return (
        <React.Fragment>
            <div>
                <LoadPanelComponent
                    shadingColor="rgba(0,0,0,0.4)"
                    position={userListPosition}
                    visible={loader}
                    message=''
                    showPane={false}
                />

                <span>
                    return <div><span style={{color : "#A3A3A3", marginRight: "1rem" }}>
                    Тайлант он</span><span style={{fontWeight: "700"}}> {yearValue } сар {monthValue}</span></div>;

                </span>
                <div className="row" style={{display: 'flex', marginBottom: 10}}>

                    <div  className='col-6' style={{marginLeft: 10, marginRight: 10, display: "flex", flexDirection: "column"}}>
                        <div style={{display: 'flex', alignItems: 'center', marginBottom: 10,...style.text}}>Бэлтгэн
                        нийлүүлэгч:
                        </div>
                        <div style={{display: "flex",flexDirection: "row"}}>
                        <input type='string' className='law-search-input'
                           onClick={(e) => setModalVisibleCustOrg(true)}
                           style={{...style.box}}
                           value={custValue.name}
                        />
                        <div style={{marginLeft: 20}}>
                        <Button icon="check"

                                type="normal"
                                onClick={() => getOpenAmt()}/>
                    </div>
                        </div>
                        
                    
                    </div>
                </div>
                <div className="row" style={{display: 'flex', marginBottom: 10}}>
                    <div className='col-6' style={{marginLeft: 10, marginRight: 10, display: "flex", flexDirection: "row"}}>
                    <div style={{display: "flex", flexDirection: "column"}}>
                    <div style={{ display: 'flex', alignItems: 'center', marginRight: 10, marginBottom: 10,...style.text}}>Мөнгөний данс:
                    </div>
                    <SelectBox
                        items={dataFcsApClass}
                        value={apClassValue}
                        style={{...style.box}}
                        displayExpr={(item) => {
                            return item && `${item.acct} - ${item.descr}`
                        }}
                        onValueChanged={(e) => onChangeApClassValue(e.value)}
                        placeholder="сонгох"/>
                    </div>
                    <div  className='col-2' style={{marginLeft: 10, marginRight: 10, display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "flex-start"}}>
                        <div style={{display: 'flex', alignItems: 'center', marginLeft: 60, marginRight: 10,...style.text}}>Дугаар:</div>
                        <div >{1 + docno}</div>
                </div>
                </div>
                </div>
                


                <div className="row" style={{display: 'flex', marginBottom: 5}}>
                    <div className='col-3' style={{marginLeft: 10, marginRight: 10}}>
                    <div style={{ display: 'flex', alignItems: 'center',marginLeft:10, marginRight: 10, ...style.text}}>Гүйлгээний
                        утга:
                    </div>
                    <input type='string' className='law-search-input'
                           style={{marginTop: 10, ...style.box}}
                           value={transValue} onChange={handleChangeValue}
                    />
                    </div>
                    <div className='col-3' style={{marginLeft: 10, marginRight: 10}}>
                    <div style={{display: 'flex', alignItems: 'center', "marginRight": 5, ...style.text}}>Гүйлгээний
                        код:
                    </div>
                    <SelectBox
                        items={dataFcsSt4New}
                        value={selectedSt4New}
                        searchEnabled={true}
                        style={{marginTop: 10,...style.box}}
                        displayExpr={(item) => {
                            return item && `${item.code} - ${item.descr}`
                        }}
                        onValueChanged={(e) => onChangeSt4New(e.value)}
                        placeholder="сонгох"/>
                        </div>
                </div>


                <div className="row" style={{display: 'flex', marginBottom: 20}}>
                    <div className='col-3' style={{marginLeft: 10, marginRight: 10}}>

                        <div style={{...style.text}}>
                         Баримтын дугаар:
                         </div>
                        <input type='number' className='law-search-input'
                           style={{
                               marginTop: 10,...style.box
                           }}
                           value={brn} onChange={onChangeDocNoValue}/>
                        </div>
                        <div className='col-3' style={{marginLeft: 10, marginRight: 10}}>
                        <div style={{display: 'flex', alignItems: 'center', marginRight: 10,...style.text}}>Огноо:</div>
                            <DateBox value={docDate} type="date" style={{marginTop: 10,...style.box}}
                             onValueChanged={onValueChangedDate}/>
                        </div>
                </div>


                <div>
                    <Popup
                        width={500}
                        height={500}
                        minWidth={'80%'}
                        maxWidth={'80%'}
                        minHeight={'80%'}
                        visible={modalVisibleCustOrg}
                        title={'Байгууллага хувь хүмүүс сонгох'}
                        onHiding={() => setModalVisibleCustOrg(false)}
                        resizeEnabled={true}
                        dragEnabled={true}
                        showTitle={true}

                    >
                        <CustorgParent setCustValue={setCustValue} setModalVisibleCustOrg={setModalVisibleCustOrg}/>

                    </Popup>
                </div>
                <div className="col-12">
                    <DataGrid
                        dataSource={listData}
                        showBorders={true}
                        ref={myBabyRef}
                        hoverStateEnabled={true}
                        noDataText='Дата байхгүй байна.'
                        //   onEditingStart={onEditingStart}
                        //  onInitNewRow={onInitNewRow}
                        onSaved={saveDataGrid}
                        //  onRowInserted={onRowInserted}
                        keyExpr="apply_to"
                    >
                        <Editing
                            mode="cell"
                            allowUpdating={true}

                            useIcons={true}
                            texts={{
                                cancelAllChanges: 'Болих бүгд',
                                cancelRowChanges: 'Болих',
                                confirmDeleteMessage: 'Энэ худалдан авалт мэдээллийг устгах уу?',
                                confirmDeleteTitle: 'Баталгаажуулах',
                                deleteRow: 'Устгах',
                                editRow: 'Өөрчлөх',
                                saveAllChanges: 'Хадгалах бүгд',
                                saveRowChanges: 'Хадгалах',
                                undeleteRow: 'Буцаах',
                                validationCancelChanges: 'Баталгаажуулах болих',
                            }}
                        />

                        <Column
                            cssClass="custom"
                            dataField="apply_to"
                            caption="№"
                            width={50}
                            alignment="center"
                            allowEditing={false}
                            allowFiltering={true}
                        >
                        </Column>

                        <Column
                            cssClass="custom"
                            dataField="cust"
                            caption="Код"
                            width={60}
                            alignment="center"
                            allowEditing={false}
                            allowFiltering={true}
                        >
                        </Column>



                        <Column
                            cssClass="custom"
                            dataField="apacct"
                            caption="Данс"
                            minWidth={90}
                            alignment="center"
                            allowEditing={false}
                            allowFiltering={true}
                        >
                        </Column>

                        <Column
                            cssClass="custom"
                            dataField="agenda_id"
                            caption="Төсөл"
                            minWidth={60}
                            allowEditing={false}
                            allowFiltering={true}
                        >
                        </Column>


                        <Column
                            cssClass="custom"
                            dataField="event_id"
                            caption="Зориулалт"
                            minWidth={70}
                            allowEditing={false}
                            allowFiltering={true}
                        >
                        </Column>

                        <Column
                            cssClass="custom"
                            dataField="econ_category_id"
                            caption="Э/З ангилал"
                            minWidth={60}
                            allowEditing={false}
                            allowFiltering={true}
                        >
                        </Column>


                        <Column
                            cssClass="custom"
                            dataField="openqty"
                            caption="Төлөх тоо"
                            width={100}
                           
                            allowEditing={false}
                            allowFiltering={true}
                            format="#,##0.00"
                        >
                        </Column>

                        <Column
                            cssClass="custom"
                            dataField="openamt"
                            caption="Төлөх дүн"
                            minWidth={100}
                            allowEditing={false}
                            allowFiltering={true}
                            format="#,##0.00"
                        >
                        </Column>


                        <Column
                            cssClass="custom"
                            dataField="qty"
                            caption="Тоо"
                            allowEditing={true}
                            alignment="center"
                            allowFiltering={true}
                            setCellValue={calculateAmt}
                            width={100}
                            format="#,##0.00"
                        >
                            <RequiredRule
                                message='Тоо оруулна уу'
                            />
                            <PatternRule pattern={/^\d+$/} message='зөвхөн эерэг тоо оруулна уу!'/>
                        </Column>
                        <Column
                            cssClass="custom"
                            dataField="unit"
                            caption="Ханш"
                            allowEditing={false}
                            allowFiltering={true}
                            alignment="center"
                            //    setCellValue={calculateAmt1}
                            width={60}
                            format="#,##0.00"
                        >
                            <RequiredRule
                                message='Ханш оруулна уу'
                            />
                            <PatternRule pattern={/^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/}
                                         message='зөвхөн тоо оруулна уу!'/>
                        </Column>
                        <Column
                            cssClass="custom"
                            dataField="amt"
                            caption="Төлсөн дүн"
                            allowEditing={false}
                            width={120}
                            format="#,##0.00"
                        >
                        </Column>


                        <Summary>
                            <TotalItem
                                column="openamt"
                                summaryType="sum"
                                customizeText={customizeDate}/>
                            <TotalItem
                                column="qty"
                                summaryType="sum"
                                customizeText={customizeDate}/>
                            <TotalItem
                                column="amt"
                                summaryType="sum"
                                customizeText={customizeDate}/>
                        </Summary>
                    </DataGrid>
                </div>
                <div style={{display: 'flex', flexDirection: 'row', marginTop: 30, marginBottom: 30}}>

                    <div style={{marginLeft: 30}}>
                        <Button className='saveButton'
                                text="Хадгалах"
                                type="default"
                                onClick={() => setApSelect()}/>
                    </div>
                </div>
            </div>
            <div>


            </div>

        </React.Fragment>
    )
}

export default ApSelect;

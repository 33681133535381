import request from "../../shared/lib/request"

const setNsoDataThird = (data)=>{
  return request({
    url: `/statistic/setNsoDataThird`,
    method: "POST",
    data
  })
}
const setNsoDataSecondTwo = (tbl_id)=>{
  return request({
    url: `/statistic/setNsoDataSecondTwo/${tbl_id}`,
    method: "POST",
  })
}
const setNsoDataSecondOne = (tbl_id)=>{
  return request({
    url: `/statistic/setNsoDataSecondOne`,
    method: "POST",
  })
}
const setNsoDataFourthOne = ()=>{
  return request({
    url: `/statistic/setNsoDataFourthOne`,
    method: "POST",
  })
}
const setNsoDataFourthTwo = ()=>{
  return request({
    url: `/statistic/setNsoDataFourthTwo`,
    method: "POST",
  })
}
const setNsoDataFirst = ()=>{
  return request({
    url: `/statistic/setNsoDataFirst`,
    method: "POST",
  })
}

const getData = (year, list_id, tbl_id) => {
  return request({
    url: `/statistic/getData/${year}/${list_id}/${tbl_id}`,
    method: "GET",
  })
}
const getDataYears = (list_id, tbl_id) => {
  return request({
    url: `/statistic/getDataYears/${list_id}/${tbl_id}`,
    method: "GET",
  })
}
const getDataYearsByCode = (list_id, tbl_id, code, scr_mn1) => {
  return request({
    url: `/statistic/getDataYearsByCode/${list_id}/${tbl_id}/${code}/${scr_mn1}`,
    method: "GET",
  })
}
const getDataYearsDetail = (list_id, tbl_id) => {
  return request({
    url: `/statistic/getDataYears/${list_id}/${tbl_id}`,
    method: "GET",
  })
}

const getDataSingleCode = (year, list_id, tbl_id) => {
  return request({
    url: `/statistic/getDataSingleCode/${year}/${list_id}/${tbl_id}`,
    method: "GET",
  })
}

const getSumByYear = (year, list_id, tbl_id) => {
  return request({
    url: `/statistic/getSumByYear/${year}/${list_id}/${tbl_id}`,
    method: "GET",
  })
}
const getSumByMaxYear = (tbl_id) => {
  return request({
    url: `/statistic/getSumByMaxYear/${tbl_id}`,
    method: "GET",
  })
}

const getMonthData = (year, list_id, tbl_id) => {
  return request({
    url: `/statistic/getMonthData/${year}/${list_id}/${tbl_id}`,
    method: "GET",
  })
}

const getMonthDataByCode = (year, list_id, tbl_id, code, scr_mn1) => {
  return request({
    url: `/statistic/getMonthDataByCode/${year}/${list_id}/${tbl_id}/${code}/${scr_mn1}`,
    method: "GET",
  })
}
const getMonthDataDetail = (year, list_id, tbl_id) => {
  return request({
    url: `/statistic/getMonthData/${year}/${list_id}/${tbl_id}`,
    method: "GET",
  })
}

const selectAllData = (year, list_id, tbl_id) => {
  return request({
    url: `/statistic/selectAllData/${year}/${list_id}/${tbl_id}`,
    method: "GET",
  })
}
const getTbls = (list_id, year) => {
  return request({
    url: `/statistic/getTbls/${list_id}/${year}`,
    method: "GET",
  })
}

const getTblsByOrg = (org_id, list_id, year) => {
  return request({
    url: `/statistic/getTblsByOrg/${org_id}/${list_id}/${year}`,
    method: "GET",
  })
}
const getList = (isLocal, orgId) => {
  return request({
    url: `/statistic/getList/${isLocal}/${orgId}`,
    method: "GET",
  })
}
const getAllTbls = () => {
  return request({
    url: `/statistic/getAllTbls`,
    method: "GET",
  })
}

const getMetaData = (list_id, tbl_id) => {
  return request({
    url: `/statistic/getMetaData/${list_id}/${tbl_id}`,
    method: "GET",
  })
}
const uploadStatisticFile = (data, tbl_id, file_type)=>{
  return request({
    url: `/statistic/uploadStatisticFile/${tbl_id}/${file_type}`,
    method: "POST",
    data
  })
}
const uploadOrgFileStatistic = (data, orgId)=>{
  return request({
    url: `/statistic/uploadOrgFileStatistic/${orgId}`,
    method: "POST",
    data
  })
}

const getFileStatistic = (tbl_id, type) => {
  return request({
    url: `/statistic/getFileStatistic/${tbl_id}/${type}`,
    method: "GET",
  })
}
const getOrgFileStatistic = (orgId) => {
  return request({
    url: `/statistic/getOrgFileStatistic/${orgId}`,
    method: "GET",
  })
}

const statsService = {
  getData,
  getDataYears,
  getDataSingleCode,
  getSumByYear,
  selectAllData,
  getMonthData,
  getTbls,
  getList,
  getSumByMaxYear,
  getDataYearsDetail,
  getMonthDataDetail,
  getTblsByOrg,
  getAllTbls,
  getDataYearsByCode,
  getMonthDataByCode,
  setNsoDataThird,
  setNsoDataSecondTwo,
  setNsoDataSecondOne,
  setNsoDataFourthOne,
  setNsoDataFourthTwo,
  setNsoDataFirst,
  getMetaData,
  uploadStatisticFile,
  getFileStatistic,
  getOrgFileStatistic,
  uploadOrgFileStatistic
}

export default statsService

import React, { useState, useRef, useEffect } from 'react';
import UserSection from "./sections/UserSection";
import ProfileSection from "./sections/ProfileSection";
import RoleSection from "./sections/RoleSection";
import { useContext } from "react";
import { UserContext } from "../../hooks/UserContext";
import notify from "devextreme/ui/notify";
import OrganizationService from "../../services/api/organization";
import TreeView from "devextreme-react/tree-view";
import { urlServer } from "../../shared/lib/request";
import OrgContact from '../../pages/introduction/components/TopHeaderComponent'
import styles from "./myInfo.module.css";
import { Popup } from "devextreme-react/popup";
import Button from "devextreme-react/button";
import Form, { Item, Label, SimpleItem } from "devextreme-react/form";
import { RequiredRule, PatternRule, StringLengthRule, EmailRule } from 'devextreme-react/validator';
import FileUploader from "devextreme-react/file-uploader";
import img from "../../assets/imgs/userLogo.png";
import AuthService from '../../services/api/auth';
import ProposalService from "../../services/api/proposal";
import "./myInfo.module.css";
import feedbackServices from '../../services/api/feedback';


export function renderUserDetail(label, value) {
    if (!value) return ' ';
    return (
        <div className={styles.row}>
            <div className={styles.columnRight}>{label}:</div>
            <div className={styles.columnLeft}>
                <span>{value}</span>
            </div>
        </div>
    );
}

function Myinfo() {
    const treeView = useRef(null);
    const [selectedOrgData, setSelectedOrgData] = useState(null);
    const { user, setUser } = useContext(UserContext);
    const [fileNameData, setFileNameData] = useState(null);
    const [selectedOrgId, setSelectedOrgId] = useState(user.organization.id);
    const [popupVisible, setPopupVisible] = useState(false);
    const [verifyPopUpVisible, setverifyPopUpVisible] = useState(false);
    const [formUser, setFormUser] = useState(null);
    const [selectedFile, setSelectedFile] = useState([]);
    const [urlChangeHandler, setUrlChangeHandler] = useState(false);
    const [updateReq, setUpdateReq] = useState(false);
    const [render, setRender] = useState(false);
    const [loader, setLoader] = useState(false);

    const findUser = async () => {
        try {
            const res = await AuthService.getProfile();
            setUser(res.data);
            setFormUser(res.data);
            setLoader(false);
        } catch (e) {
            setLoader(false);
        }
    }

    useEffect(() => {
        findUser();
    }, [])

    const updateMyinfo = async (formUser) => {
        try {
            await AuthService.updateMyInfo({
                email: formUser.email,
                phone: formUser.phone,
                mobile: formUser.mobile,
            })
            setUser({ ...user, mobile: formUser.mobile, phone: formUser.phone, email: formUser.email });
            findUser();
            notify({
                message: "Амжилттай хадгалагдлаа.",
                width: 'fit-content'
            }, "success", 1000);
            verifyVisible("false")
            visible("false");
            State(true);
        } catch (e) {
            notify({
                message: e.response.data.message,
                width: 'fit-content'
            },
                "error", 1000);
            verifyVisible("false")
        }
    };
    const handleUpload = () => {
        if (selectedFile && selectedFile.length !== 0) {
            submitFile()
        }
    }

    const isNULL = async () => {
        const EmailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        const NumberRegex = /^[0-9]*$/;
        const { mobile, phone, email } = formUser;

        if(!mobile) {
            notify({
                message: "Утасны дугаар оруулах шаардлагатай!",
                width: 'fit-content'
            }, "error", 1000);
        } else if (mobile && !NumberRegex.test(mobile)) {
                notify({
                    message: "Утасны дугаар зөвхөн тоо агуулсан байх шаардлагатай!",
                    width: 'fit-content'
                }, "error", 1000);
        } else if (mobile && mobile.length !== 8) {
                notify({ message: "8 оронтой утасны дугаар оруулна уу!",
                width: 'fit-content'
                }, "error", 1000)
        } else if (phone && !NumberRegex.test(phone)) {
                notify({
                    message: "Ажлын дугаар зөвхөн тоо агуулсан байх шаардлагатай!",
                    width: 'fit-content'
                }, "error", 1000);
        } else if (phone && phone.length !== 8 && phone.length !== 6) {
                notify({ message: "Ажлын утасны дугаараа дахин шалгана уу!",
                width: 'fit-content'
                }, "error", 1000)
        } else if (email && !EmailRegex.test(email)) {
                notify({
                    message: "Имэйлийн бүтэц буруу байна: example@email.com",
                    width: 'fit-content'
                }, "error", 1000);
        } else {
            setverifyPopUpVisible(true);
        }
    }
    
    const selectItem = async (e) => {
        try {
            setSelectedOrgData(e.itemData);
            setSelectedOrgId(e.itemData.org_id)
            let resultImage = await OrganizationService.getFileNameStateStructure(e.itemData.org_id);
            setFileNameData(resultImage.data[0]);
        } catch (e) {
            setFileNameData(null)
            setSelectedOrgData(null)
            notify({ message: e.response.data.message, width: 'fit-content' }, "error", 2000);
        }
    }

    const HandleHover = (event) => {
        event.target.style.backgroundColor = '#EEF2F8';
    }
    const HandleHoverOut = (event) => {
        event.target.style.backgroundColor = '#fff';
    }

    const selectedItem = (e) => {
        if (e.node.selected) {
            e.itemElement.style.background = '#fff';
            e.itemElement.style.backgroundColor = '#fff';
            e.itemElement.style.color = '#000000';
            e.itemElement.style.fontWeight = '700';
        } else {
            e.itemElement.style.background = '#fff';
            e.itemElement.style.backgroundColor = '#fff';
            e.itemElement.style.color = '#000000';
            e.itemElement.style.fontWeight = 'normal';
        }

    }

    function State(is) {
        if (is === true) {
            setUpdateReq(true);
            // handler(prev=>!prev);
        } else {
            setUpdateReq(false);
        }
    }
    const createChildren = async (parent) => {
        const parentId = parent ? parent.itemData.id : '';
        return OrganizationService.updatedStateAttributes(parentId)
            .then((response) => {
                if (!parentId) {
                    let parent = response.filter((org) => org.parent === null)
                    if (parent.length > 0) {
                        setSelectedOrgData(parent[0])
                        OrganizationService.getFileNameStateStructure(parent[0].org_id)
                            .then((responseImage) => {
                                setFileNameData(responseImage.data[0]);
                            })
                            .catch(() => {
                                setFileNameData(null);
                            });
                    }
                }
                return response
            })
            .catch(() => {
                throw new Error('Data Loading Error');
            });
    }
    function verifyVisible(isVerify) {
        if (isVerify === true) {
            setverifyPopUpVisible(true);
        }
        else {
            setverifyPopUpVisible(false);
        }
    }
    function visible(isVisible) {
        if (isVisible === "true") {
            setFormUser({ ...user });
            setPopupVisible(true);
        } else {
            setPopupVisible(false);
            setFormUser({ ...user });
        }
    }
    const removePicture = () => {
        setSelectedFile('');
    };

    function changeHandler(e) {
        setSelectedFile(e.value);
    }
    const submitFile = async () => {
        let fd = new FormData();
        fd.append("additional_doc", selectedFile[0]);
        let res = await ProposalService.uploadProfile(fd);
        if (res.code === 200) {
            if (res.data.path && res.data.path.length !== 0) {
                setUser({ ...user, file_path: res.data.path })
                setUrlChangeHandler(res.data.path)
            }
        } else {
            notify({ message: res.message, width: 'fit-content' }, "warning", 500);
            verifyVisible("false");
        }
    };

    return (
        <div className="myInfoContainer">
            <div>
                <div className={"card"} style={{ height: 96, borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}>
                    {fileNameData ?
                        <OrgContact urlServer={urlServer} fileNameData={fileNameData}
                            orgId={selectedOrgData && selectedOrgData.org_id} />
                        :
                        <div className='background-government-image' />
                    }
                </div>
                <TreeView
                    ref={treeView}
                    id="id"
                    dataStructure="plain"
                    displayExpr={'state_structure_name'}
                    parentIdExpr={'parent'}
                    keyExpr={'id'}
                    rootValue=""
                    expandedExpr={'expand'}
                    expandNodesRecursive={false}
                    createChildren={createChildren}
                    onItemClick={selectItem}
                    onItemSelectionChanged={selectedItem}
                    style={{
                        display: "none"
                    }}
                />
                <UserSection visible={visible} setPopupVisible={setPopupVisible} setRender={setRender} />
                <RoleSection popupVisible={popupVisible} setPopupVisible={setPopupVisible} />
                <ProfileSection />
                <Popup
                    visible={popupVisible}
                    onHiding={() => {
                        visible("false");
                    }}
                    dragEnabled={false}
                    showCloseButton={true}
                    hideOnOutsideClick={true}
                    width={'50rem'}
                    height={'32rem'}
                >
                    <div style={{ color: "#15186a", fontSize: "1rem", fontWeight: "bold", padding: '0 1rem 1.5rem ', fontFamily: "Segoe UI" }}>ХУВИЙН МЭДЭЭЛЭЛ:</div>
                    <div className='col-12' style={{ display: 'flex', padding: '0 1rem', fontFamily: "Segoe UI"  }}>
                        <div className='col-3' style={{ borderRight: "1px solid #EBECF0", marginBottom: '4rem' }}>
                            <form method="post" >
                                <div style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    flex: 1,
                                    gap: '1rem',
                                    alignItems: 'center',
                                }}>
                                    <div>
                                        <img alt=""
                                            className={user.file_path ? styles.customImg : styles.readyImg}
                                            style={{
                                                height: "6rem",
                                                width: "6rem",
                                                objectFit: "cover",
                                                borderRadius: "50%"
                                            }}
                                            src={user.file_path === null && user.file_name === null
                                                ? img
                                                : user.file_path !== null
                                                    ? urlServer + "/api/file/" + user.file_path
                                                    : urlServer + "/api/file/documents/" + user.file_name}
                                        />
                                    </div>
                                    {selectedFile.length !== 0 ?
                                        <div style={{ fontSize: "13px", textAlign: 'center' }}>
                                            <span style={{ cursor: "pointer", color: "blue" }} onClick={removePicture}>
                                                Зураг арилгах
                                            </span>
                                        </div>
                                        : null
                                    }
                                    <div className='' style={{ marginLeft: '1.5rem' }}>
                                        <FileUploader
                                            className="pictureUpload"
                                            labelText=''
                                            accept="image/*"
                                            height={40}
                                            selectButtonText="Зураг оруулах"
                                            uploadMode="useForm"
                                            onValueChanged={changeHandler}
                                            value={selectedFile}
                                        />
                                    </div>

                                </div>
                            </form>
                        </div>
                        <div className='col-9' style={{ marginLeft: '2rem' }}>
                            <form
                                method="Post"
                                onSubmit={async (e) => {
                                    e.preventDefault();
                                    await updateMyinfo(formUser);
                                }}
                            >
                                <Form formData={formUser} className="simpleForm"
                                    showValidationSummary={true}
                                    onFormDataChange={user}
                                >
                                    <Item dataField="last_name" editorOptions={{ disabled: true, width: '40%' }}>
                                        <Label text="Овог" />
                                    </Item>
                                    <Item dataField="first_name" editorOptions={{ disabled: true, width: '40%' }}>
                                        <Label text="Нэр" />
                                    </Item>
                                    <Item dataField="position_name" editorOptions={{ disabled: true }} editorType="dxTextArea" >
                                        <Label text="Албан тушаал" />
                                    </Item>
                                    <SimpleItem name="email" dataField="email" editorOptions={{ width: '70%' }}>
                                        <Label text="Имэйл" />
                                        <EmailRule ignoreEmptyValue={true} message='Имэйл алдаатай байна: example@email.com'/>
                                        <PatternRule
                                            message="Имэйл алдаатай байна: example@email.com"
                                            pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
                                        />
                                    </SimpleItem>
                                    <SimpleItem name="phone" dataField="phone" editorOptions={{ width: '40%' }} editorType="dxTextBox" >
                                        <Label text="Ажлын дугаар" />
                                    </SimpleItem>
                                    <SimpleItem name="mobile" dataField="mobile" editorOptions={{ width: '40%' }} editorType='dxTextBox' isRequired={true}>
                                        <Label text="Утасны дугаар" />
                                        <RequiredRule message='Заавал бөглөх талбар!'/>
                                    </SimpleItem>
                                    <SimpleItem>
                                        <Button
                                            onClick={() => isNULL()}
                                            formMethod="submit"
                                            typeof="success"
                                            style={{
                                                backgroundColor: "#003696",
                                                color: "whitesmoke",
                                                border: "1px solid #003696",
                                                borderRadius: "10px",
                                                width: "10rem",
                                                height: "36px",
                                                textAlign: "center",
                                                fontFamily: "Segoe UI",
                                                fontSize: "12px",
                                                fontWeight: "600",
                                                float: 'right',
                                                margin: '1rem 0'
                                            }}
                                            text="Шинэчлэх"
                                        />
                                    </SimpleItem>
                                </Form>
                            </form>
                        </div>
                    </div>
                </Popup>
                <Popup visible={verifyPopUpVisible}
                    onHiding={() => {
                        verifyVisible("false");
                    }}
                    dragEnabled={false}
                    hideOnOutsideClick={true}
                    showCloseButton={true}
                    showTitle={false}
                    width={270}
                    height={160}
                    padding={0}
                >
                    <div style={{ position: 'absolute', left: 0, right: 0 }}>
                        <div style={{ color: "#15186a", fontWeight: "bold", textAlign: "center" }}>БАТАЛГААЖУУЛАЛТ</div>
                        <div style={{ textAlign: "center", fontSize: "12px", fontWeight: "bold", paddingBottom: 11 }}>
                            <br />
                            Та хувийн мэдээллээ <br /> шинэчлэх үү?
                        </div>
                        <br />
                        <div style={{
                            display: 'flex',
                            height: "100%",
                            justifyContent: "space-between",
                            flexDirection: 'row',
                            borderTop: "2px solid #E8EDF6",
                            fontWeight: "600",
                            fontSize: '13px',
                            color: "#000000",
                            fontFamily: 'Segoe UI',
                        }}>
                            <div
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    flex: 0.5,
                                    backgroundColor: "#fff",
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    textAlign: 'center',
                                    borderRight: "2px solid #E8EDF6",
                                    borderBottomLeftRadius: '1rem',
                                    cursor: 'pointer',
                                    padding: '10px 0'
                                }} onClick={() => {
                                    handleUpload()
                                    updateMyinfo(formUser);
                                }}
                                onMouseOver={HandleHover} onMouseOut={HandleHoverOut} >
                                ТИЙМ
                            </div>
                            <div onMouseOver={HandleHover} onMouseOut={HandleHoverOut} style={{
                                width: '100%',
                                height: '100%',
                                flex: 0.5,
                                backgroundColor: "#fff",
                                borderBottomRightRadius: '1rem',
                                alignItems: 'center',
                                justifyContent: 'center',
                                textAlign: 'center',
                                cursor: 'pointer',
                                padding: '10px 0'
                            }} onClick={() =>
                                verifyVisible("false")
                            }>
                                ҮГҮЙ

                            </div>
                        </div>
                    </div>
                </Popup>
            </div>
        </div>
    );
}

export default Myinfo;

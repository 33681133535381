import React, { useState, useEffect, useContext, useCallback, useRef, useMemo } from "react";
import PlanService from "../../../services/api/plan";
import notify from "devextreme/ui/notify";
import DataGrid, {
    Column,
    Grouping,
    Lookup,
    HeaderFilter,
    Paging,
    Summary,
    Selection,
    Toolbar,
    ColumnChooser,
    GroupItem,
    Editing,
    FilterPanel,
    Button as GridButton,
    RequiredRule,
    CustomRule, Export, ColumnFixing, Scrolling,TotalItem
} from "devextreme-react/data-grid";
import { ScrollView, TextArea, Tooltip } from "devextreme-react";

import RefService from "../../../services/api/refs";
import { Popup } from "devextreme-react/popup";
import Button from "devextreme-react/button";
import { UserContext } from "../../../hooks/UserContext";
import { formatDate, numberWithCommas } from "../../../util";
import LoadPanelComponent from "../../budget/components/loadPanel";
import PlanServices from "../../../services/api/plan";
import SelectBox from "devextreme-react/select-box";
import { Item } from "devextreme-react/tab-panel";
import { saveAs } from "file-saver-es";
import { CURRENT_YEAR } from "../../../shared/constants";
import { BsBarChartLine, BsBarChartLineFill } from "react-icons/bs";
import { CgArrowsExpandRight, CgEye, CgEyeAlt, CgCompressRight, CgMaximize, CgMinimize } from "react-icons/cg";
import { IoEye } from "react-icons/io5";
import { LuDownload } from "react-icons/lu"
import DataSource from "devextreme/data/data_source";
import FileServices from "../../../services/api/file";
import { urlServer } from "../../../shared/lib/request";
import { Workbook } from "exceljs";
import { exportDataGrid } from "devextreme/excel_exporter";
import _ from "lodash";
import { BackButton } from "../../../components/Buttons";
import { useHistory } from "react-router-dom";
import TextBox from "devextreme-react/text-box";
import loaderGif from "../../../assets/imgs/tube-spinner.svg";
import ScheduleOfferChart from "./ScheduleOfferChart";
import {budgetProjectStatus} from "../../proposal/static/data";
import { StepProgressBar } from "../../../components/StepProgressBar";
import RequestDocument from "../../../components/document/document";
import notificationUserService from "../../../services/api/notificationUser";
import moment from "moment";
import styled from "styled-components";

export const tabs = [
    {
        id: 0,
        text: 'Хуваарийн санал',
        icon: 'datafield',
        content: 'User tab content'
    },
    {
        id: 1,
        text: 'Ерөнхий мэдээлэл',
        icon: 'alignjustify',
        content: 'Comment tab content'
    },
];

const TabComponent = styled.div`
  display: flex;
  width: 100%;
  font-family: 'Segoe UI';
  font-weight: bold;
  .tab {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 0.5rem;
    background-color: #f5f5f5;
    transition: 0.3s all ease-in;
    border-bottom: 1px solid #ddd;
    &:nth-child(2) {
      border-top-right-radius: 15px;
      border-bottom: 1px solid #ddd;
      border-left: 1px solid #ddd;
    }
    &:nth-child(1) {
      border-top-left-radius: 15px;
      border-bottom: 1px solid #ddd;
    }
    .title {
      font-size: 1rem;
    }
    &:hover {
      background-color: #ebf1ff;
    }
    &.active {
      color: black;
      background-color: white;
      font-weight: 700;
      border-color: transparent;
      text-decoration-line: underline;
    }
  }
  `
const PlanningOffer = (props) => {
    const [refAgenda, setRefAgenda] = useState([]);
    const [refEvent, setRefEvent] = useState([]);
    const [loadMe, setLoadMe] = useState(false);
    const [loading, setLoading] = useState(false)
    //const [history, setHistory] = useState([]);
    const [selectedYear, setSelectedYear] = useState((props.location.state !== null && props.location.state.budget_year) ? props.location.state.budget_year : (CURRENT_YEAR));
    const [yearList, setYearList] = useState([]);
    const { user } = useContext(UserContext);
    const [selectedBudgetPlan, setSelectedBudgetPlan] = useState([]);
    const [econCategory, setEconCategory] = useState([]);
    const [autoExpandAll, setAutoExpand] = useState(true);
    const [dataEcon2, setDataEcon2] = useState([]);
    const [dataVisible, setDataVisble] = useState([]);
    const [agendaEventData, setAgendaEventData] = useState([]);
    const dataExpenseGridRef = useRef(null);
    const [fullscreen, setFullscreen] = useState(false)
    const [selectedAgendaEvent, setSelectedAgendaEvent] = useState(0);
    const [note, setNote] = useState(null)
    const inputFilesRef = useRef(null)
    const uploadingFiles = useRef(null)
    const [allFiles, setAllFiles] = useState([]);
    const [filePopupModal, setFilePopupModal] = useState(false)
    const [currentFile, setCurrentFile] = useState(null);
    const [logComment, setLogComment] = useState(null)
    const [actionPopup, setActionPopup] = useState(false)
    const [diffPopup, setDiffPopup] = useState(false)
    const [editable, setEditable] = useState(false)
    const [currentAction, setCurrentAction] = useState(null)
    const checkConditions = useMemo(() => [1, 4, 5], [])
    const sendMof = useMemo(() => [6], [])
    const approveConditions = useMemo(() => [8], [])
    const cancelConditions = useMemo(() => [2, 3, 8], [])
    const joinConditions = useMemo(() => [2, 3], [])
    const [budgetProjectLog, setBudgetProjectLog] = useState([])
    const [schedule2, setSchedule2] = useState(null);
    const [schedule3, setSchedule3] = useState(null);
    const [debit2, setDebit2] = useState(null);
    const [debit3, setDebit3] = useState(null);
    const historyProps = useHistory();
    const [visibleChart, setVisibleChart] = useState(false);
    const [pageIndex, setPageIndex] = useState(0);
    const [parentOrgInfo, setParentOrgInfo] = useState(null)
    const [randomNum, setRandomNum] = useState(null);
    const [documentPopup, setDocumentPopup] = useState(false)
    const [isVisible, setIsVisible] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(0)
    let saveButton;
    let revertButton;
    const Action  = async (action) => {
        //dataEcon2
        setLoadMe(true);
        let allBpd = await PlanService.retrieveAllBudget(selectedYear, selectedBudgetPlan.org_id, selectedBudgetPlan.id, 0, false)
        console.log(allBpd);
        let econ2 = _.filter(allBpd, item => {
            return item.econ_category_code_1 === '2'
        });
        let econ3 = _.filter(allBpd, item => {
            return item.econ_category_code_1 === '3'
        });
        let schedule2 = _.sumBy(econ2, r => {
            return r.month1 * (1) + r.month2 * (1) + r.month3 * (1) + r.month4 * (1) + r.month5 * (1) + r.month6 * (1) + r.month7 * (1) + r.month8 * (1) + r.month9 * (1) + r.month10 * (1) + r.month11 * (1) + r.month12 * (1);
        });
        let schedule3 = _.sumBy(econ3, r => {
            return r.month1 * (1) + r.month2 * (1) + r.month3 * (1) + r.month4 * (1) + r.month5 * (1) + r.month6 * (1) + r.month7 * (1) + r.month8 * (1) + r.month9 * (1) + r.month10 * (1) + r.month11 * (1) + r.month12 * (1);
        });
        let debit2 = _.sumBy(econ2, r => {
            return r.debit * (1);
        });
        let debit3 = _.sumBy(econ2, r => {
            return r.debit * (1);
        });
        if (schedule2.toFixed(2) === schedule3.toFixed(2) && schedule2.toFixed(2) === debit2.toFixed(2) && schedule3.toFixed(2) === debit3.toFixed(2)) {
            if (allFiles.length === 0) {
                setLoadMe(false)
                return notify(`Та хуваарийн саналтай холбоотой файл хавсаргаагүй тул файлаа хавсаргана уу!`, "warning", 3000);
            } else {
                setActionPopup(true)
                setCurrentAction(action)
                setLoadMe(false)
            }

        } else {
            setSchedule2(schedule2);
            setSchedule3(schedule3);
            setDebit2(debit2);
            setDebit3(debit3);
            setDiffPopup(true);
            setLoadMe(false)

        }
    }

    const getBudgetProjectLog = useCallback(async (id) => {
        try {
            setLoadMe(true);
            let r = await PlanService.getBudgetProjectLog(id);
            setBudgetProjectLog(r);
            // console.log("updating log history", r)
            return setLoadMe(false);
        } catch (e) {
            notify(e.response.data.message, 'error', 2000);
        }
    }, [selectedYear])

    const loadYear = async () => {
        try {
            let r = await PlanService.planOfferYearList((props.location.state !== null && props.location.state.org_id) ? props.location.state.org_id : user.organization.id);
            setYearList(r.data);
        } catch (e) {
            notify(e.message, "`error", 2000);
        }
    }

    const getData = useCallback(async (year) => {
        try {
            let r = await PlanServices.retrieveBudgetPlans((props.location.state !== null && props.location.state.budget_year) ? props.location.state.budget_year : year, (props.location.state !== null && props.location.state.org_id) ? props.location.state.org_id : user.organization.id);
            //console.log('r', r);
            setSelectedBudgetPlan(r[0]);
            r.length > 0 && getBudgetProjectLog(r[0].id);
            if (r.length > 0) {
                let p = await PlanServices.getParentObtOrg(r[0].org_id);
                let edit = false;
                switch (user.organization.obt_id) {
                    case 1:
                        edit = [2, 8].includes(r[0].budget_project_status_id) ? true : false;
                        break
                    case 2:
                        edit = [3].includes(r[0].budget_project_status_id) ? true : false;
                        break
                    case 3:
                        edit = [1, 4, 5].includes(r[0].budget_project_status_id) ? true : false;
                        break;
                }
                setParentOrgInfo(p[0]);
                setEditable(edit);
            }

            if (r.length === 0) {
                notify(`${selectedYear} онд хуваарийн төсөл үүсгээгүй байна`, "error", 2000);
                return
            }

            const result = await RefService.getRefEconomicCategory();
            setEconCategory(result.data);

        } catch (e) {
            notify(e.message, "error", 2000);
        }
    }, [])
    //Төлөв товч дарах үед хийгдэх үйлдэл
    const modifyStatus = useCallback(async (isCancel, nextStatus, description, comment) => {
        setLoadMe(true);
        //console.log("modifying request",request)
        const req = {
            "budget_project_id": selectedBudgetPlan.id,
            "current_status": selectedBudgetPlan?.budget_project_status_id,
            "change_status": nextStatus,
            "comment": logComment,
        }
        await PlanService.changeStatusProject(req).then((res) => {
            //notify("Хуваарийн саналыг амжилттай илгээлээ", "success", 4000)
            //pushNotif(nextStatus)
        })
            .catch((e) => {
                setLoadMe(false);
                console.log("err->", e)
            })
            .finally(() => {
                notify(`${selectedBudgetPlan?.budget_year} - оны хуваарийн саналын мэдээлэл ${description}`, "success", 2000);
                pushNotif()
                getData(selectedYear);
                getOtherData();
                setActionPopup(false)
                setLogComment(null);
                setLoadMe(false);
                /*  UpdateRequestNote(null) // no notification
                  setLogComment(null)
                  setUpdate(prev => !prev)
                  setActionPopup(false)
                  user.current_request.status = nextStatus*/
            })
    }, [logComment])
    //hyanuulah, batlah, tsutslah... zergiig darah uyed daraagiin statusiig todorhoiloh function
    const getNextActionProceed = useCallback((status, isCancel) => {
        switch (status) {
            case 1:  //ТШЗ-Боловсруулж байгаа,
                return parentOrgInfo.parent_obt_id === 1 ? (parentOrgInfo.obt_id === 2 ? 3 : 2) : 3;
            case 2:  //ТЕЗ-д хүргүүлсэн
                return isCancel ? 4 : 6;
            case 3:  //ТТЗ-д хүргүүлсэн
                return isCancel ? 5 : 2;
            case 4: //ТЕЗ-Татгалзсан
                return parentOrgInfo.parent_obt_id === 1 ? 2 : 3;
            case 5: //ТТЗ-Татгалзсан
                return parentOrgInfo.parent_obt_id === 1 ? 2 : 3;
            case 6: //ТЕЗ-Нэгтгэсэн
                return 8;
            case 8:
                return 9;
            default:
                // console.log("err baihgui", status)
                return status
        }
    }, [parentOrgInfo?.obt_id, parentOrgInfo?.parent_obt_id])

    const getDescription = useCallback((status) => {
        switch (status) {
            case 1:
                return 'төсвийн дараагийн захирагч руу илгээгдлээ';
            case 4:
                return 'төсвийн дараагийн захирагч руу илгээгдлээ';
            case 5:
                return 'төсвийн дараагийн захирагч руу илгээгдлээ'; // check
            case 3:
                return 'төсвийн ерөнхийлөн захирагч руу илгээгдлээ'; //'nextOBT'
            case 7:
                return 'нэгтгэгдлээ';
            case 2:
                return 'нэгтгэгдлээ' //'merge'
            case 6:
                return 'СЯ-руу хүргүүлсэн' //'merge'
            case 8:
                return 'батлагдлаа' //'confirm'
            default:
                return 'Тодорхойлогдоогүй тайлбар' // 'not defined status code
        }
    }, [])

    const onClickNegtgeh = (isCancel) => {
        try {
            if (logComment === null) return notify(`Та нэмэлт тайлбар оруулна уу`, "warning", 2000);

            let description = isCancel ? 'цуцлагдлаа' : getDescription(selectedBudgetPlan?.budget_project_status_id);

            let nextStatus = null
            switch (currentAction) {
                case "proceed":
                    nextStatus = getNextActionProceed(selectedBudgetPlan?.budget_project_status_id, isCancel)
                    break;
                case "check":
                    nextStatus = getNextActionProceed(selectedBudgetPlan?.budget_project_status_id, isCancel)
                    break;
                case "return":
                    nextStatus = getNextActionProceed(selectedBudgetPlan?.budget_project_status_id, isCancel)
                    break;
                case "cancel":
                    nextStatus = getNextActionProceed(selectedBudgetPlan?.budget_project_status_id, isCancel)
                    break;
                case "merge":
                    nextStatus = getNextActionProceed(selectedBudgetPlan?.budget_project_status_id, isCancel)
                    break;
                case "join":
                    nextStatus = getNextActionProceed(selectedBudgetPlan?.budget_project_status_id, isCancel)
                    break;
                case "disjoin":
                    nextStatus = getNextActionProceed(selectedBudgetPlan?.budget_project_status_id, isCancel)
                    break;
                case "approve":
                    nextStatus = getNextActionProceed(selectedBudgetPlan?.budget_project_status_id, isCancel)
                    break;
                default:
                    break;
            }
            //console.log('nextStatus', nextStatus);
            modifyStatus(isCancel, nextStatus, description);
        } catch (e) {
            console.log(e);
            notify(e.response.data.message, 'error', 2000);
        }
    }//, [currentAction, getNextActionProceed, getDescription,modifyStatus,logComment])

    const loadAgendaEvent = async () => {
        try {
            let r = await PlanService.retrievePlanAgendaEvent(selectedYear, selectedBudgetPlan.org_id);
            setAgendaEventData([
                {
                    id: 0,
                    agenda_name: 'Бүх хөтөлбөрөөр',
                    event_name: 'Бүх зориулалтаар харах',
                },
                ...r
            ])
        } catch (e) {
            notify(e.message, "error", 2000);
        }
    };
    const groupedAgendaEvent = new DataSource(
        {
            store: {
                type: 'array',
                data: agendaEventData,
                key: 'id',
            },
            group: 'agenda_name',
        });

    const loadAllBudget = async (agEvId) => {
        try {
            setLoadMe(true);
            let allBpd = await PlanService.retrieveAllBudget(selectedYear, selectedBudgetPlan.org_id, selectedBudgetPlan.id, agEvId, true)
            setDataEcon2(allBpd)
            setLoadMe(false);
        } catch (e) {
            setLoadMe(false);
            notify(e.message, "error", 2000);
        }
    }
    /*const loadHistory = async () => {
        try {
            let r = await PlanService.retrieveBudgetDetailHistory(selectedBudgetPlan.id);
            setHistory(r)
        } catch (e) {
            notify(e.message, "`error", 2000);
        }
    }*/
    const loadRefs = async () => {
        try {
            let a = await RefService.getAgendas();
            let e = await RefService.getEvents();
            setRefAgenda(a.data);
            setRefEvent(e.data);
        } catch (e) {
            notify(e.message, "error", 2000);
        }
    }

    const getOtherData = async () => {
        selectedBudgetPlan.id && getBpFiles();
        //selectedBudgetPlan?.budget_project_status_id && statusRender();
        loadAgendaEvent();
        loadRefs();
        loadAllBudget(selectedAgendaEvent);
        //loadHistory();

    }
    useEffect(() => {
        loadYear();
        selectedBudgetPlan && selectedBudgetPlan.id && getBpFiles();
        getData(selectedYear);

    }, [selectedYear, logComment, props.location.state])

    useEffect(() => {
        if (selectedBudgetPlan && selectedBudgetPlan.id) {
            loadAllBudget(selectedAgendaEvent);
        }
    }, [selectedAgendaEvent])

    useEffect(() => {
        if (selectedBudgetPlan && selectedBudgetPlan.id) {
            getOtherData();
        }
    }, [selectedBudgetPlan, selectedYear, props.location.state])

    const customizedBold = (data) => {
        return (
            <span style={{ fontWeight: 'bold', fontFamily: 'Segoe UI' }}>{data.text}</span>
        )
    }
    const onYearValueChanged = (e) => {
        setIsVisible(false);
        setNote(null);
        setSelectedYear(e.value);
    }
    /*const onAutoExpandAllChanged = (e) => {
        setAutoExpand(!autoExpandAll);
    }*/
    const onExporting = (e) => {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Main sheet');
        worksheet.columns = [
            { width: 10 },{ width: 100 },{ width: 18 },{ width: 18 },{ width: 18 },{ width: 18 },{ width: 18 },{ width: 18 },{ width: 18 },{ width: 18 },{ width: 18 },{ width: 18 },{ width: 18 },{ width: 18 },{ width: 18 }
        ];
        exportDataGrid({
            component: e.component,
            worksheet,
            autoFilterEnabled: true,
            keepColumnWidths: false,
            topLeftCell: { row: 6, column: 2 },
            customizeCell: ({ gridCell, excelCell }) => {
                //excelCell.font = {name: 'Arial', size: 8, color: {argb: '000000'}}
                if (gridCell.rowType === 'header') {
                    excelCell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'd9d9d9' } };
                    excelCell.font = { color: { argb: '00000' },name: 'Arial', size: 8, 'bold': true};

                }
                if (gridCell.rowType === 'group') {
                    if(gridCell.groupIndex ===0) {
                        excelCell.font = { color: { argb: 'F93A3A' },name: 'Arial',size: 8};
                        excelCell.font.fontWeight = 'bold';
                        if(excelCell._column._number !== 2 ) excelCell.alignment = { horizontal: 'right' };

                    }
                    if(gridCell.groupIndex ===1) {
                        excelCell.font = { color: { argb: '2C77FF' },name: 'Arial', size: 8, 'bold': true};
                        if(excelCell._column._number !== 2 ) excelCell.alignment = { horizontal: 'right' };

                    }
                    else if (gridCell.groupIndex >1){


                        excelCell.font = { name: 'Arial', size: 8, 'bold': true};
                        if(excelCell._column._number !== 2 ) excelCell.alignment = { horizontal: 'right' };
                    }

                }
                if (gridCell.rowType === 'totalFooter' && excelCell.value) {
                    excelCell.font = {name: 'Arial', size: 8, 'italic': true, 'bold': true};
                }
                if (gridCell.rowType === 'data') {
                    excelCell.font = { name: 'Arial', size: 8};
                    excelCell.alignment = { wrapText: false };
                    if(gridCell.column.dataField !== 'econ_category_id')
                    {
                        excelCell.numFmt = '#,##0.00';
                    }

                }
                if(['agenda_id','event_id','econ_category_code_3','econ_category_code_4','econ_category_code_2','econ_category_code_1','econ_category_id'].includes(gridCell.column.dataField)) {
                    if(excelCell._row && excelCell._row._cells && excelCell._row._cells[1].model && excelCell._row._cells[1].model.value)
                    {
                        excelCell._row._cells[1].model.value = excelCell._row._cells[1].model.value.replace(": ", "");
                    }

                }
                if(gridCell.column.dataField === 'econ_category_code_3' || gridCell.column.dataField === 'econ_category_code_4') {
                    /* excelCell._row._cells[1].model.value = 'dddd';
                     excelCell._row.hidden = true;*/
                    if(excelCell._row && excelCell._row._cells && excelCell._row._cells[1].model && excelCell._row._cells[1].model.value === '')
                    {
                        excelCell._row.hidden = true;
                    }
                }
                if(gridCell.rowType === 'group' && gridCell.groupIndex === 1) {
                    if(excelCell._column._number !== 2 ) excelCell.alignment = { horizontal: 'right',wrapText: false };
                    excelCell._row._cells[1].model.value = ` ${excelCell._row._cells[1].model.value}`
                }
                if(gridCell.rowType === 'group' && gridCell.groupIndex === 2) {
                    if(excelCell._column._number !== 2 ) excelCell.alignment = { horizontal: 'right',wrapText: false };
                    excelCell._row._cells[1].model.value = `  ${excelCell._row._cells[1].model.value}`
                }
                if(gridCell.rowType === 'group' && gridCell.groupIndex === 3) {
                    if(excelCell._column._number !== 2 ) excelCell.alignment = { horizontal: 'right',wrapText: false };
                    excelCell._row._cells[1].model.value = `   ${excelCell._row._cells[1].model.value}`
                }
                if(gridCell.rowType === 'group' && gridCell.groupIndex === 4) {
                    if(excelCell._column._number !== 2 ) excelCell.alignment = { horizontal: 'right',wrapText: false };
                    excelCell._row._cells[1].model.value = `    ${excelCell._row._cells[1].model.value}`
                }
                if(gridCell.rowType === 'group' && gridCell.groupIndex === 5) {
                    if(excelCell._column._number !== 2 ) excelCell.alignment = { horizontal: 'right',wrapText: false };
                    excelCell._row._cells[1].model.value = `     ${excelCell._row._cells[1].model.value}`
                }
                if(gridCell.rowType === 'data') {
                    excelCell._row._cells[1].model.value = `      ${excelCell._row._cells[1].model.value}`
                }
            },
        }).then(() => {
            Object.assign(worksheet.getRow(1).getCell(1), {
                value: 'Гарчиг:',
                font: {name: 'Arial', size: 8 },
            })
            Object.assign(worksheet.getRow(1).getCell(2), {
                value: `${selectedBudgetPlan.budget_year} оны хуваарийн санал`,
                font: {name: 'Arial', size: 8, 'bold': true, color: {argb: '0070c0'} },
            })
            Object.assign(worksheet.getRow(2).getCell(1), {
                value: 'Байгууллага:',
                font: {name: 'Arial', size: 8 },
            })
            Object.assign(worksheet.getRow(2).getCell(2), {
                value: selectedBudgetPlan.Organization.name,
                font: {name: 'Arial', size: 8 },
            })
            Object.assign(worksheet.getRow(3).getCell(1), {
                value: 'Хэрэглэгч:',
                font: {name: 'Arial', size: 8 },
            })
            Object.assign(worksheet.getRow(3).getCell(2), {
                value: `${user.last_name.slice(0,1)}. ${user.first_name}, ${user.position_name}`,
                font: {name: 'Arial', size: 8 },
            })
            Object.assign(worksheet.getRow(4).getCell(1), {
                value: 'Огноо:',
                font: {name: 'Arial', size: 8 },
            })
            Object.assign(worksheet.getRow(4).getCell(2), {
                value: new moment().locale('mn').format('lll'),
                font: {name: 'Arial', size: 8 },
            })
        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer], { type: 'application/octet-stream' }), selectedBudgetPlan.Organization.name !== null ? "OG-" + `${selectedBudgetPlan.budget_year} оны хуваарийн санал-` + formatDate(new Date()) + ".xlsx" : "OG-" + `${selectedBudgetPlan.budget_year} оны хуваарийн санал-` + formatDate(new Date()) + ".xlsx");
            });
        });
        e.cancel = true;
    }
    const onCellPreparedHandler = (e) => {
        if (e.rowType === 'header') {
            e.cellElement.wordWrapEnabled = true;
            e.cellElement.style.fontFamily = "Segoe UI";
            e.cellElement.style.fontWeight = "bold";
            e.cellElement.style.color = "#15186A";
            e.cellElement.style.alignment = "center";
            e.cellElement.style.backgroundColor = "#EEF0F4";
            e.cellElement.style.borderColor = "#D4DCEC";
            e.cellElement.style.fontSize = "12px";
        }
        else if (e.rowType === 'group') {
            // console.log(e);
            e.cellElement.style.fontFamily = "Segoe UI"
            //e.cellElement.wordWrapEnabled = true;
            e.cellElement.style.color = (e.row.groupIndex === 0 ? "#F93A3A" : ((e.row.groupIndex && e.row.groupIndex===1) ? "#2C77FF" : 'black'))
            e.cellElement.style.backgroundColor = "#fff"
            e.cellElement.style.fontWeight = "bold";
            e.cellElement.style.fontSize = "12px"
            e.cellElement.style.borderColor = "#D4DCEC"
        } else if (e.rowType === "data") {
            e.cellElement.style.fontFamily = 'Segoe UI'
            e.cellElement.style.fontWeight = 'normal'
            e.cellElement.style.color = "#000000"

        }
    }
    const groupCell = (e) => {
        return <div>{e.data.displayValue}</div>
    }
    const onRowPrepared = (args) => {
        if (args.rowType == 'group' && (args.values[args.groupIndex] == "" || args.values[args.groupIndex] == null)) {
            args.rowElement.hidden = true;
            if (!args.component.isRowExpanded(args.key))
                args.component.expandRow(args.key)
        }
    }
    const requestFullscreen = () => {
        if (document.fullscreenElement) document.exitFullscreen();
        else document.getElementById("wrapper").requestFullscreen();
        // setFullscreen(prev=>!prev)
    }
    const setSchedule = async (e) => {
        if (e.changes && e.changes.length !== 0 && e.changes[0].type === "update") {
            try {
                setLoadMe(true);
                await PlanService.uploadBudgetProjectDetail(e.changes, selectedBudgetPlan.id);
                await loadAgendaEvent();
                //await loadHistory();
                visibleChart && setRandomNum(Math.random());
                notify('Амжилттай хадгалагдлаа', "success", 2000);
                return setLoadMe(false);
            } catch (e) {
                setLoadMe(false);
                notify('Засварлалтыг хадгалах явцад алдаа гарлаа', "error", 2000);
            }
        }
    }

    const customValidation = (params) => {
        let s = (params.data.month1 === undefined ? 0 : params.data.month1 * 1) +
            (params.data.month2 === undefined ? 0 : params.data.month2 * 1) +
            (params.data.month3 === undefined ? 0 : params.data.month3 * 1) +
            (params.data.month4 === undefined ? 0 : params.data.month4 * 1) +
            (params.data.month5 === undefined ? 0 : params.data.month5 * 1) +
            (params.data.month6 === undefined ? 0 : params.data.month6 * 1) +
            (params.data.month7 === undefined ? 0 : params.data.month7 * 1) +
            (params.data.month8 === undefined ? 0 : params.data.month8 * 1) +
            (params.data.month9 === undefined ? 0 : params.data.month9 * 1) +
            (params.data.month10 === undefined ? 0 : params.data.month10 * 1) +
            (params.data.month11 === undefined ? 0 : params.data.month11 * 1) +
            (params.data.month12 === undefined ? 0 : params.data.month12 * 1);
        params.rule.message = (params.data.econ_category_code_1 !== '4' || params.data.econ_category_code_2 === '46') ? `Зөрүү ${numberWithCommas(params.data.debit * 1 - s)}` : `Бүх саруудын утга төсвийн ${numberWithCommas(params.data.debit * 1)} дүнтэй ижил байна`
        return (params.data.econ_category_code_1 !== '4' || params.data.econ_category_code_2 === '46') ? ((s * 1).toFixed(2) === (params.data.debit * 1).toFixed(2)) : (((s * 1) / 12).toFixed(2) === (params.data.debit * 1).toFixed(2));
    }
    const onEqualize = (e) => {
        //console.log(e);
        e.row.isEditing = true;
        e.row.isSelected = true;
        let eq = (e.row.data.debit * 1 / 12).toFixed(0) * 1;
        let eq4 = e.row.data.debit * 1;
        let debit = e.row.data.debit * 1;
        let d = eq * 12;
        let m1 = eq * 1 + (debit * 1 - d * 1);
        let index = 8 //+ (selectedAgendaEvent === 0 ? 2 : 0);
        if (e.row.data.econ_category_code_1 !== '4') {
            e.row.data.month1 = m1;
            e.row.values[index] = m1;
            e.row.data.month2 = eq;
            e.row.values[index + 1] = eq;
            e.row.data.month3 = eq;
            e.row.values[index + 2] = eq;
            e.row.data.month4 = eq;
            e.row.values[index + 3] = eq;
            e.row.data.month5 = eq;
            e.row.values[index + 4] = eq;
            e.row.data.month6 = eq;
            e.row.values[index + 5] = eq;
            e.row.data.month7 = eq;
            e.row.values[index + 6] = eq;
            e.row.data.month8 = eq;
            e.row.values[index + 7] = eq;
            e.row.data.month9 = eq;
            e.row.values[index + 8] = eq;
            e.row.data.month10 = eq;
            e.row.values[index + 9] = eq;
            e.row.data.month11 = eq;
            e.row.values[index + 10] = eq;
            e.row.data.month12 = eq;
            e.row.values[index + 11] = eq;
        } else {
            e.row.data.month1 = eq4;
            e.row.values[index] = eq4;
            e.row.data.month2 = eq4;
            e.row.values[index + 1] = eq4;
            e.row.data.month3 = eq4;
            e.row.values[index + 2] = eq4;
            e.row.data.month4 = eq4;
            e.row.values[index + 3] = eq4;
            e.row.data.month5 = eq4;
            e.row.values[index + 4] = eq4;
            e.row.data.month6 = eq4;
            e.row.values[index + 5] = eq4;
            e.row.data.month7 = eq4;
            e.row.values[index + 6] = eq4;
            e.row.data.month8 = eq4;
            e.row.values[index + 7] = eq4;
            e.row.data.month9 = eq4;
            e.row.values[index + 8] = eq4;
            e.row.data.month10 = eq4;
            e.row.values[index + 9] = eq4;
            e.row.data.month11 = eq4;
            e.row.values[index + 10] = eq4;
            e.row.data.month12 = eq4;
            e.row.values[index + 11] = eq4;
        }

        dataExpenseGridRef.current.instance.cellValue(e.row.rowIndex, index, e.row.data.econ_category_code_1 !== '4' ? m1 : eq4);
        dataExpenseGridRef.current.instance.cellValue(e.row.rowIndex, index + 1, e.row.data.econ_category_code_1 !== '4' ? eq : eq4);
        dataExpenseGridRef.current.instance.cellValue(e.row.rowIndex, index + 2, e.row.data.econ_category_code_1 !== '4' ? eq : eq4);
        dataExpenseGridRef.current.instance.cellValue(e.row.rowIndex, index + 3, e.row.data.econ_category_code_1 !== '4' ? eq : eq4);
        dataExpenseGridRef.current.instance.cellValue(e.row.rowIndex, index + 4, e.row.data.econ_category_code_1 !== '4' ? eq : eq4);
        dataExpenseGridRef.current.instance.cellValue(e.row.rowIndex, index + 5, e.row.data.econ_category_code_1 !== '4' ? eq : eq4);
        dataExpenseGridRef.current.instance.cellValue(e.row.rowIndex, index + 6, e.row.data.econ_category_code_1 !== '4' ? eq : eq4);
        dataExpenseGridRef.current.instance.cellValue(e.row.rowIndex, index + 7, e.row.data.econ_category_code_1 !== '4' ? eq : eq4);
        dataExpenseGridRef.current.instance.cellValue(e.row.rowIndex, index + 8, e.row.data.econ_category_code_1 !== '4' ? eq : eq4);
        dataExpenseGridRef.current.instance.cellValue(e.row.rowIndex, index + 9, e.row.data.econ_category_code_1 !== '4' ? eq : eq4);
        dataExpenseGridRef.current.instance.cellValue(e.row.rowIndex, index + 10, e.row.data.econ_category_code_1 !== '4' ? eq : eq4);
        dataExpenseGridRef.current.instance.cellValue(e.row.rowIndex, index + 11, e.row.data.econ_category_code_1 !== '4' ? eq : eq4);
        //dataExpenseGridRef.current.instance.saveEditData();
    }
    const onPrevYearOffer = async (e) => {
        console.log('e.row', e.row);
        //alert('Тун удахгүй')
        e.row.isEditing = true;
        e.row.isSelected = true;
        let r = await PlanService.getSchedulePrevSuggestion(e.row.data.budget_year, e.row.data.org_id, `${e.row.data.agenda_id}${e.row.data.event_id}`, e.row.data.econ_category_code);
        // console.log('r', r);
        let debit = e.row.data.debit * 1;
        let index = 8 //+ (selectedAgendaEvent === 0 ? 2 : 0);
        e.row.data.month1 = r[0].percent * debit / 100;
        e.row.values[index] = r[0].percent * debit / 100;
        e.row.data.month2 = r[1].percent * debit / 100;
        e.row.values[index + 1] = r[1].percent * debit / 100;
        e.row.data.month3 = r[2].percent * debit / 100;
        e.row.values[index + 2] = r[2].percent * debit / 100;
        e.row.data.month4 = r[3].percent * debit / 100;
        e.row.values[index + 3] = r[3].percent * debit / 100;
        e.row.data.month5 = r[4].percent * debit / 100;
        e.row.values[index + 4] = r[4].percent * debit / 100;
        e.row.data.month6 = r[5].percent * debit / 100;
        e.row.values[index + 5] = r[5].percent * debit / 100;
        e.row.data.month7 = r[6].percent * debit / 100;
        e.row.values[index + 6] = r[6].percent * debit / 100;
        e.row.data.month8 = r[7].percent * debit / 100;
        e.row.values[index + 7] = r[7].percent * debit / 100;
        e.row.data.month9 = r[8].percent * debit / 100;
        e.row.values[index + 8] = r[8].percent * debit / 100;
        e.row.data.month10 = r[9].percent * debit / 100;
        e.row.values[index + 9] = r[9].percent * debit / 100;
        e.row.data.month11 = r[10].percent * debit / 100;
        e.row.values[index + 10] = r[10].percent * debit / 100;
        e.row.data.month12 = r[11].percent * debit / 100;
        e.row.values[index + 11] = r[11].percent * debit / 100;
        dataExpenseGridRef.current.instance.cellValue(e.row.rowIndex, index, r[0].percent * debit / 100);
        dataExpenseGridRef.current.instance.cellValue(e.row.rowIndex, index + 1, r[1].percent * debit / 100);
        dataExpenseGridRef.current.instance.cellValue(e.row.rowIndex, index + 2, r[2].percent * debit / 100);
        dataExpenseGridRef.current.instance.cellValue(e.row.rowIndex, index + 3, r[3].percent * debit / 100);
        dataExpenseGridRef.current.instance.cellValue(e.row.rowIndex, index + 4, r[4].percent * debit / 100);
        dataExpenseGridRef.current.instance.cellValue(e.row.rowIndex, index + 5, r[5].percent * debit / 100);
        dataExpenseGridRef.current.instance.cellValue(e.row.rowIndex, index + 6, r[6].percent * debit / 100);
        dataExpenseGridRef.current.instance.cellValue(e.row.rowIndex, index + 7, r[7].percent * debit / 100);
        dataExpenseGridRef.current.instance.cellValue(e.row.rowIndex, index + 8, r[8].percent * debit / 100);
        dataExpenseGridRef.current.instance.cellValue(e.row.rowIndex, index + 9, r[9].percent * debit / 100);
        dataExpenseGridRef.current.instance.cellValue(e.row.rowIndex, index + 10, r[10].percent * debit / 100);
        dataExpenseGridRef.current.instance.cellValue(e.row.rowIndex, index + 11, r[11].percent * debit / 100);
    }
    const onZero = (e) => {
        e.row.isEditing = true;
        e.row.isSelected = true;
        let index = 8 //+ (selectedAgendaEvent === 0 ? 2 : 0);
        e.row.data.month1 = 0;
        e.row.values[index] = 0;
        e.row.data.month2 = 0;
        e.row.values[index + 1] = 0;
        e.row.data.month3 = 0;
        e.row.values[index + 2] = 0;
        e.row.data.month4 = 0;
        e.row.values[index + 3] = 0;
        e.row.data.month5 = 0;
        e.row.values[index + 4] = 0;
        e.row.data.month6 = 0;
        e.row.values[index + 5] = 0;
        e.row.data.month7 = 0;
        e.row.values[index + 6] = 0;
        e.row.data.month8 = 0;
        e.row.values[index + 7] = 0;
        e.row.data.month9 = 0;
        e.row.values[index + 8] = 0;
        e.row.data.month10 = 0;
        e.row.values[index + 9] = 0;
        e.row.data.month11 = 0;
        e.row.values[index + 10] = 0;
        e.row.data.month12 = 0;
        e.row.values[index + 11] = 0;
        dataExpenseGridRef.current.instance.cellValue(e.row.rowIndex, index, 0);
        dataExpenseGridRef.current.instance.cellValue(e.row.rowIndex, index + 1, 0);
        dataExpenseGridRef.current.instance.cellValue(e.row.rowIndex, index + 2, 0);
        dataExpenseGridRef.current.instance.cellValue(e.row.rowIndex, index + 3, 0);
        dataExpenseGridRef.current.instance.cellValue(e.row.rowIndex, index + 4, 0);
        dataExpenseGridRef.current.instance.cellValue(e.row.rowIndex, index + 5, 0);
        dataExpenseGridRef.current.instance.cellValue(e.row.rowIndex, index + 6, 0);
        dataExpenseGridRef.current.instance.cellValue(e.row.rowIndex, index + 7, 0);
        dataExpenseGridRef.current.instance.cellValue(e.row.rowIndex, index + 8, 0);
        dataExpenseGridRef.current.instance.cellValue(e.row.rowIndex, index + 9, 0);
        dataExpenseGridRef.current.instance.cellValue(e.row.rowIndex, index + 10, 0);
        dataExpenseGridRef.current.instance.cellValue(e.row.rowIndex, index + 11, 0);
    }
    const getFiles = useCallback(async (pId) => {
        return await PlanService.getBudgetProjectFiles(pId)
            .then((res) => {
                // console.log("requestFiles", requestId, res)
                res.data.forEach((data, index) => {
                    // data.index ??= index+1
                    data.downloaded ??= true
                })
                return res.data
            })
            .catch((err) => {
                notify(err.response.data.message, 'warning', 2000);
            })
    }, [])
    const getBpFiles = useCallback(async () => {
        await getFiles(selectedBudgetPlan.id).then((data) => {
            setAllFiles(data)
        })
    }, [selectedBudgetPlan, getFiles])
    const handleUpload = useCallback(async (e) => {
        e.preventDefault()
        uploadingFiles.current ??= new FormData()
        const files = Array.from(e.target.files)
        const uploading = allFiles.filter(file => !file.downloaded);
        const names = new Set(uploading.map(file => file.name));
        const filtered = files.filter(file => !names.has(file.name));
        filtered.forEach((data, index) => {
            // data.index = index+1
            data.downloaded = false
            data.file ??= { file_name: data.name }
            data.file.file_size = data.size
            data.file.file_extention = data.type
            data.file.content_name = data.name
        })
        if (files.length !== filtered.length)
            notify("Файл давхардсан ба давхардаагүй файлуудыг оруулав", 'warning', 3000)
        filtered.forEach((file) => {
            uploadingFiles.current.append('files', file)
        })
        uploadFiles()
    }, [allFiles])

    const uploadFiles = useCallback(async () => {
        const length = Array.from(uploadingFiles.current.entries()).length
        if (length > 0) {
            setLoading(true)
            await FileServices.uploadBudgetProjectFiles(selectedBudgetPlan.id, uploadingFiles.current)
                .then((res) => {
                    // console.log('upload request files success', res)
                    const afterUpload = allFiles.filter((file) => {
                        return file.downloaded
                    })
                    setAllFiles(afterUpload)
                    getBpFiles()
                    uploadingFiles.current = new FormData()
                    setLoading(false)
                })
                .catch((err) => {
                    setLoading(false)
                    console.log('upload request files err', err)
                })
        }
    }, [getBpFiles, selectedBudgetPlan?.id, allFiles])

    const viewFile = useCallback((file) => {
        setCurrentFile(file.data)   //file
        setFilePopupModal(true)     //popup
    }, [])

    const viewFileCell = useCallback((row) => {
        const popupFormats = ['pdf', 'png', 'jpeg', 'jpg', 'gif', 'webp'].some(format => row.data.file.file_name.endsWith(format))
        if (row.data.downloaded && popupFormats)
            return (<div>
                    <Button  //style={{color:'#15186A'}}
                        id={"viewFile" + row.data.id}
                        render={() => {
                            return <IoEye size={18} />
                        }}
                        onClick={(e) => {
                            viewFile(row)
                        }} />
                    <Tooltip
                        target={'#viewFile' + row.data.id}
                        showEvent="mouseenter"
                        hideEvent="mouseleave"
                        hideOnOutsideClick={false}
                        position='top'
                    >
                        <div style={{ padding: '1em' }}>
                            Харах
                        </div>
                    </Tooltip>
                </div>
            )
        else if (row.data.downloaded && !popupFormats) {
            return (
                <div>
                    <Button render={() => { return <LuDownload size={18} /> }} id={"downloadFile" + row.data.id} onClick={(e) => {
                        const link = document.createElement('a')
                        link.href = urlServer + '/api/file/documents/' + row.data?.file?.file_name
                        link.click()
                        // viewFile(row)
                    }} />
                    <Tooltip
                        target={'#downloadFile' + row?.data?.id}
                        showEvent="mouseenter"
                        hideEvent="mouseleave"
                        hideOnOutsideClick={false}
                        position='top'
                    >
                        <div style={{ padding: '1em' }}>
                            Татах
                        </div>
                    </Tooltip>
                </div>
            )
        } else
            return (
                <></>
            )
    }, [viewFile])

    const fileActionRender = (row) => {
        return (<div style={{ display: 'flex', gap: '1em', justifyContent: "center" }}>
                {viewFileCell(row)}
                {props.location.state === null && [1, 4, 5].includes(selectedBudgetPlan?.budget_project_status_id) && removeCell(row)}
            </div>
        )
    }
    const removeFile = useCallback((removingFile) => {
        setAllFiles(prev => prev.filter(file => file !== removingFile))
    }, [])
    const removeFileCell = useCallback((row) => {
        return (
            <Button icon="remove" onClick={() => {
                removeFile(row.data)
            }} />
        )
    }, [removeFile])
    const removeExistingFile = useCallback(async (removingFile) => {
        await FileServices.updateBudgetProjectFile(removingFile.id, { status: 0 })
            .then((res) => {
                setAllFiles(prev => prev.filter(file => file !== removingFile))
                notify("Файлыг амжилттай устгалаа", "success", 2000)
            }).catch((err) => {
                console.log(err)
                notify("Файлыг устгахад алдаа гарлаа", "warning", 2000)
            })
    }, [])
    const removeExistingFileCell = useCallback((row) => {
        return (
            <div>
                <Button
                    id={"removeFile" + row.data.id} type={'danger'}
                    icon="trash" onClick={(e) => {
                    removeExistingFile(row.data)
                }} />
                <Tooltip
                    target={'#removeFile' + row.data.id}
                    showEvent="mouseenter"
                    hideEvent="mouseleave"
                    hideOnOutsideClick={false}
                    position='top'
                >
                    <div style={{ padding: '1em' }}>Устгах</div>
                </Tooltip>
            </div>
        )
    }, [removeExistingFile])
    const removeCell = useCallback((row) => {
        if (row.data.downloaded) {
            return removeExistingFileCell(row)
        }
        return removeFileCell(row)
    }, [removeFileCell, removeExistingFileCell])
    // console.log("note", note);
    const updateNote = async () => {
        if (note === selectedBudgetPlan?.note) return
        try {
            if (props.location.state === null && [1, 4, 5].includes(selectedBudgetPlan?.budget_project_status_id)) {
                setLoadMe(true);
                let body = {};
                body.note = note;
                await PlanService.setBudgetProjectNote(body, selectedBudgetPlan.id);
                notify({message: 'Нэмэлт тайлбар хадгалагдлаа', width: 'fit-content'}, "success", 2000);
                return setLoadMe(false);
            }

        } catch (e) {
            setLoadMe(false);
            notify({message: 'Нэмэлт тайлбар хадгалах явцад алдаа гарлаа', width: 'fit-content'}, "error", 2000);
        }
    }
    useEffect(() => {
        note!==undefined && updateNote()
    }, [note])

    const pushNotif = async () => {
        // console.log("props year", props.year)
        await notificationUserService.proposalNotification({
            proposalId: selectedBudgetPlan.id,
            action: "propose",
            proposalOrgId: user.organization.id,
            year: selectedBudgetPlan.budget_year,
            budget_project_type: selectedBudgetPlan.budget_project_type
        })
    }

    const statusRender = () => {
        //console.log('data.displayValue', data.displayValue);
        let regexNew = /Боловсруулж байгаа/;
        let regexCheck = /хүргүүлсэн/;
        let regexSend = /илгээсэн/;
        let regexMerge = /Нэгтгэсэн/;
        let regexConfirm = /СЯ-руу хүргүүлсэн/;
        let regexCancel = /Татгалзсан/;
        //console.log('selectedBudgetPlan', selectedBudgetPlan);
        let str = selectedBudgetPlan?.budget_project_status.name;
        let backColor = '#50CB93';
        if (regexNew.test(str)) {
            backColor = '#50CB93'
        }
        if (regexCheck.test(str)) {
            backColor = '#ffeb3b'
        }
        if (regexSend.test(str)) {
            backColor = '#f57f17'
        }
        if (regexMerge.test(str)) {
            backColor = '#00bcd4'
        }
        if (regexConfirm.test(str)) {
            backColor = '#0d47a1'
        }
        if (regexCancel.test(str)) {
            backColor = '#FF616D'
        }

        return <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center' }}>
            <div style={{
                backgroundColor: `${backColor}`,
                fontFamily: 'Segoe UI',
                height: 32,
                border: 0,
                padding: '0rem 0.2rem',
                borderRadius: 0,
                alignItems: 'center',
                textAlign: 'center',
                textTransform: 'uppercase',
                display: 'flex',
                justifyContent: 'center',
                fontWeight: 'bold',
                letterSpacing: '1px',
                fontSize: '10', color: 'white'
            }}>{selectedBudgetPlan?.budget_project_status.name}</div>
        </div>

    }
    //Column custom renderings
    const statusRenderGrid = (data) => {
        let regexNew = /Боловсруулж байгаа/;
        let regexCheck = /хүргүүлсэн/;
        let regexTEZ = /ТЕЗ/;
        let regexMerge = /Нэгтгэсэн/;
        let regexConfirm = /СЯ-руу хүргүүлсэн/;
        let regexCancel = /Татгалзсан/;
        let str = data.displayValue;
        let backColor;
        let color = 'white';
        if (regexNew.test(str)) {
            backColor = '#CDEBFF'
            color = 'black'
        }
        if (regexCheck.test(str)) {
            if (regexTEZ.test(str)) {
                backColor = '#5575C2'
            } else {
                backColor = '#EBF1FF'
                color = '#5575C2'
            }
        }
        if (regexMerge.test(str)) {
            if (regexTEZ.test(str)) {
                backColor = '#50C687'
            } else {
                backColor = '#E2FBEE'
                color = '#50C687'
            }
        }
        if (regexConfirm.test(str)) {
            backColor = '#0d47a1'
        }
        if (regexCancel.test(str)) {
            if (regexTEZ.test(str)) {
                backColor = '#F66160'
            } else {
                backColor = '#FFF2F2'
                color = '#F66160'
            }
        }
        if (data.displayValue) {
            return <div style={{
                backgroundColor: `${backColor}`,
                border: 0, fontFamily: 'Segoe UI',
                padding: '5px',
                borderRadius: '5px',
                alignItems: 'center',
                textAlign: 'center',
                textTransform: 'uppercase',
                display: 'flex',
                justifyContent: 'center',
                fontWeight: 'bold',
                letterSpacing: '1px',
                fontSize: '0.6rem',
                color: `${color}`
            }}>{data.displayValue}</div>
        }
    }
    const selectBoxFieldRender = (data) => {
        return (
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                {
                    data && data.id !== 0 && data.econ1_debit !== null &&
                    <div style={{ color: data.econ1_debit * 1 === 100.00 ? 'green' : 'red', fontFamily: 'Segoe UI' }}>1-{data && data.econ1_debit * 1}%</div>
                }
                {
                    data && data.id !== 0 && data.econ2_debit !== null &&
                    <div style={{ marginLeft: 5, color: data.econ2_debit * 1 === 100.00 ? 'green' : 'red', fontFamily: 'Segoe UI' }}>2-{data && data.econ2_debit * 1}%</div>
                }
                {data && data.id !== 0 && data.econ3_debit !== null && <div style={{ marginLeft: 5, color: data.econ3_debit * 1 === 100 ? 'green' : 'red', fontFamily: 'Segoe UI' }}>3-{data && data.econ3_debit * 1}%</div>}
                <TextBox //className ='product-name'
                    inputAttr={{ 'aria-label': 'event_name' }}
                    defaultValue={data && data.event_name}
                    readOnly={true} />
            </div>
        );
    }
    const selectBoxItemRender = (data) => {
        return (
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                {
                    data && data.id !== 0 && data.econ1_debit !== null &&
                    <div style={{ color: data.econ1_debit * 1 === 100.00 ? 'green' : 'red', fontFamily: 'Segoe UI' }}>1-{data && data.econ1_debit * 1}%</div>
                }
                {
                    data && data.id !== 0 && data.econ2_debit !== null &&
                    <div style={{ marginLeft: 5, color: data.econ2_debit * 1 === 100.00 ? 'green' : 'red', fontFamily: 'Segoe UI' }}>2-{data && data.econ2_debit * 1}%</div>
                }
                {data && data.id !== 0 && data.econ3_debit !== null && <div style={{ marginLeft: 5, color: data.econ3_debit * 1 === 100 ? 'green' : 'red', fontFamily: 'Segoe UI' }}>3-{data && data.econ3_debit * 1}%</div>}
                <div style={{ marginLeft: 10 }}>{data.event_name}</div>
            </div>
        );
    }

    const textAreaOptions = { format: '#,##0.00' };

    const handleComment = useCallback((e) => {
        setLogComment(e.value)
    }, [logComment])

    const VisibleChart = () => {
        setLoadMe(true)
        setVisibleChart(!visibleChart)
        setLoadMe(false)

    }

    const handleOptionChange = (e) => {
        if (e.fullName === 'paging.pageIndex') {
            setPageIndex(e.value)
        }
    }

    const orderRender = (data) => {
        return <div style={{ color: "#171010" }}>{pageIndex * 5 + data.rowIndex + 1}</div>;
    };
    const setVisiblePrev = (e) => {
        return ['1', '2', '3'].includes(e.row.data.econ_category_code_1) ? true : false
    };
    const onValueChanged = (args) => {
        setLoadMe(true);
        setIsVisible(!isVisible);
        let res = _.filter(dataEcon2, item => {
            return item.month1 * (1) + item.month2 * (1) + item.month3 * (1) + item.month4 * (1) + item.month5 * (1) + item.month6 * (1) + item.month7 * (1) + item.month8 * (1) + item.month9 * (1) + item.month10 * (1) + item.month11 * (1) + item.month12 * (1) === 0
        });
        setDataVisble(res);
        notify(isVisible ? `Танд хуваарийн саналын бүх мөрийг харуулж байна` : `Танд хуваарийн санал оруулаагүй мэдээлэл харуулж байна`);
        setLoadMe(false);
        //selectedBudgetPlan && await loadAllBudget(selectedAgendaEvent,!isVisible)
    }
    const onToolbarPreparing = (e) => {
        let toolbarItems = e.toolbarOptions.items;

        toolbarItems.forEach(function (item) {
            if (item.name === "saveButton") {
                Object.assign(item.options, {
                    disabled: true,
                    onContentReady: function (e) {
                        saveButton = e.component;
                    }
                });
            }
            if (item.name === "revertButton") {
                Object.assign(item.options, {
                    disabled: true,
                    onContentReady: function (e) {
                        revertButton = e.component;
                    }
                });
            }
        });
    }
    const onOptionChanged = (e) => {
        if (e.name === "editing") {
            let changes = e.component.option("editing.changes");
            if (changes.length > 0) {
                saveButton.option("disabled", false);
                revertButton.option("disabled", false);
            }
        }
    }

    const calculateArea = (rowData,field) => {
        return rowData.econ_category_code_1 === '2' && rowData[field]*1;
    }

    const calculateAreaSummary = (options) => {
        if (options.name === 'customSum') {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0;
            } else if (options.summaryProcess === 'calculate') {
                if(options.groupIndex < 2){
                    options.totalValue += calculateArea(options.value,'debit');
                } else {
                    options.totalValue += options.value.debit * 1
                }

            }
        }
        else if (options.name === 'totalSum') {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0;
            } else if (options.summaryProcess === 'calculate') {
                options.totalValue += calculateArea(options.value,'debit');

            }
        }
        else if (options.name === 'month1sum') {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0;
            } else if (options.summaryProcess === 'calculate') {
                options.totalValue += calculateArea(options.value,'month1');
            }
        }
        else if (options.name === 'month2sum') {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0;
            } else if (options.summaryProcess === 'calculate') {
                options.totalValue += calculateArea(options.value,'month2');
            }
        }
        else if (options.name === 'month3sum') {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0;
            } else if (options.summaryProcess === 'calculate') {
                options.totalValue += calculateArea(options.value,'month3');
            }
        }
        else if (options.name === 'month4sum') {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0;
            } else if (options.summaryProcess === 'calculate') {
                options.totalValue += calculateArea(options.value,'month4');
            }
        }
        else if (options.name === 'month5sum') {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0;
            } else if (options.summaryProcess === 'calculate') {
                options.totalValue += calculateArea(options.value,'month5');
            }
        }
        else if (options.name === 'month6sum') {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0;
            } else if (options.summaryProcess === 'calculate') {
                options.totalValue += calculateArea(options.value,'month6');
            }
        }
        else if (options.name === 'month7sum') {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0;
            } else if (options.summaryProcess === 'calculate') {
                options.totalValue += calculateArea(options.value,'month7');
            }
        }
        else if (options.name === 'month8sum') {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0;
            } else if (options.summaryProcess === 'calculate') {
                options.totalValue += calculateArea(options.value,'month8');
            }
        }
        else if (options.name === 'month9sum') {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0;
            } else if (options.summaryProcess === 'calculate') {
                options.totalValue += calculateArea(options.value,'month9');
            }
        }
        else if (options.name === 'month10sum') {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0;
            } else if (options.summaryProcess === 'calculate') {
                options.totalValue += calculateArea(options.value,'month10');
            }
        }
        else if (options.name === 'month11sum') {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0;
            } else if (options.summaryProcess === 'calculate') {
                options.totalValue += calculateArea(options.value,'month11');
            }
        }
        else if (options.name === 'month12sum') {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0;
            } else if (options.summaryProcess === 'calculate') {
                options.totalValue += calculateArea(options.value,'month12');
            }
        }
        else if (options.name === 'month1') {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0;
            } else if (options.summaryProcess === 'calculate') {
                if(options.groupIndex < 2){
                    options.totalValue += calculateArea(options.value,'month1');
                } else {
                    options.totalValue += options.value.month1 * 1
                }

            }
        }
        else if (options.name === 'month2') {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0;
            } else if (options.summaryProcess === 'calculate') {
                if(options.groupIndex < 2){
                    options.totalValue += calculateArea(options.value,'month2');
                } else {
                    options.totalValue += options.value.month2 * 1
                }

            }
        }
        else if (options.name === 'month3') {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0;
            } else if (options.summaryProcess === 'calculate') {
                if(options.groupIndex < 2){
                    options.totalValue += calculateArea(options.value,'month3');
                } else {
                    options.totalValue += options.value.month3 * 1
                }

            }
        }
        else if (options.name === 'month4') {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0;
            } else if (options.summaryProcess === 'calculate') {
                if(options.groupIndex < 2){
                    options.totalValue += calculateArea(options.value,'month4');
                } else {
                    options.totalValue += options.value.month4 * 1
                }

            }
        }
        else if (options.name === 'month5') {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0;
            } else if (options.summaryProcess === 'calculate') {
                if(options.groupIndex < 2){
                    options.totalValue += calculateArea(options.value,'month5');
                } else {
                    options.totalValue += options.value.month5 * 1
                }

            }
        }
        else if (options.name === 'month6') {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0;
            } else if (options.summaryProcess === 'calculate') {
                if(options.groupIndex < 2){
                    options.totalValue += calculateArea(options.value,'month6');
                } else {
                    options.totalValue += options.value.month6 * 1
                }

            }
        }
        else if (options.name === 'month7') {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0;
            } else if (options.summaryProcess === 'calculate') {
                if(options.groupIndex < 2){
                    options.totalValue += calculateArea(options.value,'month7');
                } else {
                    options.totalValue += options.value.month7 * 1
                }

            }
        }
        else if (options.name === 'month8') {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0;
            } else if (options.summaryProcess === 'calculate') {
                if(options.groupIndex < 2){
                    options.totalValue += calculateArea(options.value,'month8');
                } else {
                    options.totalValue += options.value.month8 * 1
                }

            }
        }
        else if (options.name === 'month9') {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0;
            } else if (options.summaryProcess === 'calculate') {
                if(options.groupIndex < 2){
                    options.totalValue += calculateArea(options.value,'month9');
                } else {
                    options.totalValue += options.value.month9 * 1
                }

            }
        }
        else if (options.name === 'month10') {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0;
            } else if (options.summaryProcess === 'calculate') {
                if(options.groupIndex < 2){
                    options.totalValue += calculateArea(options.value,'month10');
                } else {
                    options.totalValue += options.value.month10 * 1
                }

            }
        }
        else if (options.name === 'month11') {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0;
            } else if (options.summaryProcess === 'calculate') {
                if(options.groupIndex < 2){
                    options.totalValue += calculateArea(options.value,'month11');
                } else {
                    options.totalValue += options.value.month11 * 1
                }

            }
        }
        else if (options.name === 'month12') {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0;
            } else if (options.summaryProcess === 'calculate') {
                if(options.groupIndex < 2){
                    options.totalValue += calculateArea(options.value,'month12');
                } else {
                    options.totalValue += options.value.month12 * 1
                }

            }
        }
    }

    return (
        <div id="loadMe" style={{ padding: '10px' }}>
            <LoadPanelComponent position={{ of: '#loadMe' }} visible={loadMe} />
            {<Popup
                height={300}
                width={700}
                visible={actionPopup}
                title={'Тайлбар:'}
                onHiding={() => setActionPopup(false)}
                resizeEnabled={true}
                dragEnabled={true}
                showTitle={true}>
                <TextArea
                    height={160}
                    value={logComment}
                    // placeholder="Үйлдлийн тайлбар"
                    onValueChanged={handleComment}
                >
                </TextArea>
                <div style={{
                    display: 'flex',
                    flexDirection: "row",
                    justifyContent: 'flex-end',
                    gap: "1em",
                    marginTop: "1em"
                }}>
                    <Button icon="revert" style={{ fontFamily: 'Segoe UI', borderColor: '#EEF0F4' }} onClick={() => {
                        setActionPopup(false)
                    }}
                            text="Болих" />
                    <div style={{ display: 'flex', flexDirection: "row", justifyContent: 'flex-end', gap: "1em" }}>
                        {
                            checkConditions.includes(selectedBudgetPlan?.budget_project_status_id) && currentAction === "check" &&
                            <Button icon="check" style={{ fontFamily: 'Segoe UI', borderColor: '#EEF0F4', backgroundColor: '#337AB7', color: '#fff' }}
                                    onClick={() => {
                                        onClickNegtgeh(false);
                                    }}
                                    text="Хүргүүлэх"
                                    type="success"
                            />
                        }

                        {
                            cancelConditions.includes(selectedBudgetPlan?.budget_project_status_id) && currentAction === "cancel" &&
                            <Button icon="check" style={{ fontFamily: 'Segoe UI', borderColor: '#EEF0F4' }}
                                    onClick={() => {
                                        onClickNegtgeh(true)
                                    }}
                                    text="Татгалзах"
                                    type="danger" />
                        }

                        {
                            approveConditions.includes(selectedBudgetPlan?.budget_project_status_id) && currentAction === "approve" &&
                            <Button icon="check" style={{ fontFamily: 'Segoe UI', borderColor: '#EEF0F4' }}
                                    onClick={() => onClickNegtgeh(false)}
                                    text="Батлах"
                                    type="success" />
                        }
                        {
                            joinConditions.includes(selectedBudgetPlan?.budget_project_status_id) && currentAction === "join" &&
                            <Button icon="check" style={{ fontFamily: 'Segoe UI', borderColor: '#3daa75' }}
                                    onClick={() => onClickNegtgeh(false)}
                                    text="Нэгтгэх"
                                    type="success" />
                        }
                        {
                            sendMof.includes(selectedBudgetPlan?.budget_project_status_id) && currentAction === "join" &&
                            <Button icon="check" style={{ fontFamily: 'Segoe UI', borderColor: '#EEF0F4', backgroundColor: '#337AB7' }}
                                    onClick={() => onClickNegtgeh(false)}
                                    text="СЯ-руу хүргүүлэх"
                                    type="success" />
                        }
                    </div>
                </div>
            </Popup>}
            {<Popup
                height={150}
                width={300}
                visible={diffPopup}
                showCloseButton={false}
                onHiding={() => setDiffPopup(false)}
                closeOnOutsideClick={true}
                resizeEnabled={true}
                dragEnabled={true}
                showTitle={false}>
                {/* <div > */}
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '10px', fontFamily: 'Segoe UI'}}>
                    <span style={{ textAlign:'center', borderBottom: '1px solid #DEDEDE', paddingBottom: '10px',}}><span style={{ fontWeight: 'bold', padding: 0 }}>{numberWithCommas(debit2 - schedule2)}</span> төгрөгийн Хуваарийн саналаа боловсруулаагүй байгаа тул энэхүү хүсэлтийг харьяа дээд байгууллагат илгээх боломжгүй.</span>
                    <span style={{cursor: 'pointer'}} onClick={()=>{setDiffPopup(false)}}>Хаах</span>
                </div>
            </Popup>}
            {
                <Popup
                    visible={documentPopup}
                    onHiding={() => setDocumentPopup(false)}
                    width={"70vw"}
                    height={"95vh"}
                    dragEnabled={true}
                    resizeEnabled={true}
                    fullScreen={false}
                    hideOnOutsideClick={true}
                    title={user.organization.name + " хуваарийн саналын албан бичиг"}
                >
                    <ScrollView>
                        <RequestDocument
                            approvePerson={null}
                            requestCode={user.organization.name}
                            isOpen={documentPopup}
                        />
                    </ScrollView>
                </Popup>
            }
            <div>
                <div className="responsive1" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div className="col-6" style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'baseline', gap: '1rem' }}>
                        {props.location.state !== null && props.location.state.org_id &&
                            <div><BackButton onClick={() => historyProps.goBack()} /></div>}
                        <div className="second" style={{width: '10rem'}}>
                            <SelectBox className="col-4" label="Төсвийн жил" width="inherit" items={yearList} defaultValue={selectedYear} disabled={props.location.state == null ? false : true}
                                       onValueChanged={onYearValueChanged} placeholder="сонгох" valueExpr={"budget_year"} displayExpr={"budget_year"} />
                        </div>
                        <div className="col-6 buttons">
                            {selectedBudgetPlan && selectedBudgetPlan.Organization && <span className="budget" style={{ fontFamily: 'Segoe UI', fontWeight: 'bold', color: '#003696', cursor: 'default' }}>{selectedBudgetPlan.Organization.name}
                            </span>}
                        </div>
                    </div>
                    <div className="col-6" style={{ display: 'flex', textAlign: 'end', justifyContent: 'end', alignItems: 'center', width: '35%' }}>
                        <div
                            className="col-12"
                            style={{
                                textAlign: 'right',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'flex-end',
                                alignItems: 'center',
                            }}>
                            {/*{
                                selectedBudgetPlan && selectedBudgetPlan.budget_project_status_id &&
                                <div style={{
                                    fontFamily: "'Montserrat', sans-serif",
                                    fontWeight: 'bold'
                                }}>{statusRender()}</div>
                            }*/}
                            {selectedBudgetPlan && selectedBudgetPlan.budget_project_status_id &&

                                // actionStatus || !(user?.current_request_list?.length !== 0 || requestListNegtgesen.length > 0) &&
                                // checkConditions.includes(selectedBudgetPlan?.budget_project_status_id) &&
                                ((props.location.state!==null && props.location.state.notOrg) || props.location.state === null) &&
                                <Button style={{ marginLeft: 5, fontFamily: 'Segoe UI', borderColor: '#EEF0F4', backgroundColor: '#337AB7' }} type='success' text="Хуваарийн санал хүргүүлэх"
                                        icon="check"
                                        disabled={!checkConditions.includes(selectedBudgetPlan?.budget_project_status_id)}
                                        onClick={() => {
                                            Action('check')
                                        }} />
                            }
                            {
                                cancelConditions.includes(selectedBudgetPlan?.budget_project_status_id) && props.location.state !== null && props.location.state.org_id && editable &&
                                <Button style={{ marginLeft: 5, fontFamily: 'Segoe UI', borderColor: '#EEF0F4' }} type={'danger'} icon="revert" onClick={() => {
                                    Action('cancel')
                                }}
                                        text="Татгалзах" />
                            }
                            {
                                joinConditions.includes(selectedBudgetPlan?.budget_project_status_id) && props.location.state !== null && props.location.state.org_id && editable &&
                                <Button style={{ marginLeft: 5, fontFamily: 'Segoe UI', borderColor: '#EEF0F4', backgroundColor: '#3daa75' }} type='success' icon="todo" onClick={() => {
                                    Action('join')
                                }}
                                        text="Нэгтгэх" />
                            }
                            {
                                sendMof.includes(selectedBudgetPlan?.budget_project_status_id) && props.location.state !== null && props.location.state.org_id && user.organization.obt_id === 1 &&
                                <Button style={{ marginLeft: 5, fontFamily: 'Segoe UI', borderColor: '#EEF0F4', backgroundColor: '#337AB7' }} type='success' icon="todo" onClick={() => {
                                    Action('join')
                                }}
                                        text="СЯ-руу хүргүүлэх" />
                            }
                            {
                                approveConditions.includes(selectedBudgetPlan?.budget_project_status_id) && props.location.state !== null && props.location.state.org_id && user.organization.obt_id === 1 &&
                                <Button style={{ marginLeft: 5, fontFamily: 'Segoe UI', borderColor: '#EEF0F4' }} type='success' icon="todo" onClick={() => {
                                    Action('approve')
                                }}
                                        text="Батлах" />
                            }
                        </div>
                        {/* </div> */}
                    </div>
                </div>
            </div>
            <div style={{ margin: '10px 0' }}>
                {parentOrgInfo && <StepProgressBar staticStatus={budgetProjectStatus} statusId={selectedBudgetPlan?.budget_project_status_id} userDesc={parentOrgInfo} />}
            </div>
            <div style={{ border: '1px solid #ddd', borderRadius: 15, position: 'relative' }}>
                <TabComponent>
                    {tabs.map((item) => (
                        <div
                            key={item.key}
                            onClick={() => setSelectedIndex(item.id)}
                            className={`tab ${item.id} ${selectedIndex === item.id && "active"
                                }`}
                        >
                            <span className="title">{item.text}</span>
                        </div>
                    ))}
                </TabComponent>
                <div style={{padding: '0 10px'}}>
                <div hidden={selectedIndex === 0 ? false : true} style={{ marginTop: '1.5rem' }}>
                    {
                        //selectedYear && selectedBudgetPlan && selectedAgendaEvent && selectedEconCode &&
                        visibleChart && selectedBudgetPlan && selectedBudgetPlan.org_id !== undefined && <ScheduleOfferChart budgetYear={selectedYear} visibleChart={visibleChart} orgId={selectedBudgetPlan.org_id} agendaEventId={selectedAgendaEvent} refresh={randomNum} />
                    }
                </div>
                {
                    <div className="offer" id="wrapper" height="100%" width="100%" hidden={selectedIndex === 0 ? false : true}>
                        <DataGrid dataSource={isVisible ? dataVisible : dataEcon2} ref={dataExpenseGridRef} hoverStateEnabled={true}
                            keyExpr={'id'}
                            columnAutoWidth={true}
                            //allowColumnReordering={true}
                            allowColumnResizing={true}
                            //columnWidth={'auto'}
                            loadPanel={{ enabled: true, text: '', shadingColor: "rgba(0,0,0,0.4)", showPane: false, indicatorSrc: loaderGif }}
                            height={"90vh"}
                            noDataText={isVisible ? "Хуваарийн саналын оруулаагүй мэдээлэл олдсонгүй" : "Батлагдсан хуваарийн мэдээлэл ороогүй байна"}
                            width={'100%'}
                            wordWrapEnabled={true}
                            columnResizingMode={'nextColumn'}
                            onSaved={setSchedule}
                            paging={{ enabled: false }}
                            style={{ marginTop: 5, marginBottom: 10, overflowY: "auto", overflowX: "hidden", }}
                            showBorders={true}
                            showRowLines={true}
                            twoWayBindingEnabled={true}
                            //selection={true}
                            sorting={{ mode: 'none' }}
                            showColumnLines={true} //focusedRowEnabled={true}
                            showColumnHeaders={true}
                            onCellPrepared={onCellPreparedHandler} onRowPrepared={onRowPrepared} onToolbarPreparing={onToolbarPreparing} onOptionChanged={onOptionChanged}
                            onExporting={onExporting}
                        >
                            <ColumnFixing enabled={false} />
                            <FilterPanel
                                visible={false}
                                texts={{ clearFilter: "Шүүлтүүр цэвэрлэх", createFilter: "Шүүх" }}
                            />
                            <HeaderFilter visible={true} allowSearch={true} texts={{ cancel: 'Болих', ok: 'Шүүх', emptyValue: 'Хоосон утга' }} />
                            <Selection mode="single" />
                            <Export enabled={true} />
                            {/* <GroupPanel visible={true}/>*/}
                            <Scrolling columnRenderingMode="virtual" />
                            <Toolbar>
                                <Item location={'before'}>
                                    <div className="row" style={{ alignItems: 'center' }}>
                                        <span style={{ fontSize: '0.875rem', marginLeft: 5, marginRight: 5, fontFamily: 'Segoe UI' }}>Хөтөлбөр, арга хэмжээ</span>
                                        <SelectBox dataSource={groupedAgendaEvent} searchEnabled={true}
                                            grouped={true}
                                            width={"39rem"}
                                            inputAttr={{ 'aria-label': 'Templated Product' }}
                                            defaultValue={selectedAgendaEvent}
                                            fieldRender={selectBoxFieldRender}
                                            itemRender={selectBoxItemRender}
                                            onValueChanged={(e) => {
                                                setIsVisible(false);
                                                setSelectedAgendaEvent(e.value.id);
                                                //e.value.id && loadAllBudget(e.value.id);
                                                notify(`Та "${e.value.event_name}" сонгосон байна.`);
                                            }}
                                            placeholder="Хөтөлбөр,арга хэмжээ сонгох"
                                            //label="Хөтөлбөр,арга хэмжээ:"
                                            displayExpr={(item) => {
                                                return item && `${item.event_name}`
                                            }} />
                                    </div>

                                </Item>
                                <Item
                                    render={() => {
                                        return <Button
                                            onClick={VisibleChart}
                                            render={() => {
                                                return visibleChart ? (
                                                    <BsBarChartLineFill size={"1rem"} color="#172B4D" />)
                                                    :
                                                    (
                                                        <BsBarChartLine size={"1rem"} color="#172B4D" />
                                                    )
                                            }}
                                            hint={visibleChart ? 'График нуух' : 'График харах'}
                                        />
                                    }}
                                />
                                <Item name="saveButton" cssClass='grid-button' widget="dxButton" options={{ icon: "save", text: "Хадгалах", disabled: ((props.location.state === null && selectedBudgetPlan) && [1, 4, 5].includes(selectedBudgetPlan.budget_project_status_id)) ? false : !editable }} />
                                <Item name="revertButton" cssClass='grid-button' widget="dxButton" options={{ icon: "revert", text: "Болих", disabled: ((props.location.state === null && selectedBudgetPlan) && [1, 4, 5].includes(selectedBudgetPlan.budget_project_status_id)) ? false : !editable }} />
                                <Item>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', gap: '5px' }}>
                                        {
                                            <Button
                                                onClick={onValueChanged}
                                                render={() => {
                                                    return isVisible ?
                                                        <CgEye size={"1rem"} color="#172B4D" />
                                                        :
                                                        <CgEyeAlt size={"1rem"} color="#172B4D" />
                                                }}
                                                hint={isVisible ? 'Бүх мөрийг харуулах' : 'Хуваарийн санал оруулаагүй мөрийг харуулах'}
                                            >
                                            </Button>
                                        }
                                        <Button
                                            onClick={(e) => setAutoExpand(prev => !prev)}
                                            render={() => {
                                                return autoExpandAll ? (
                                                    <CgCompressRight size={"1rem"} color="#172B4D" />)
                                                    :
                                                    (
                                                        <CgArrowsExpandRight size={"1rem"} color="#172B4D" />
                                                    )
                                            }}
                                            hint={autoExpandAll ? 'Багасгах' : 'ЭЗА дэлгэрэнгүй харуулах'}
                                        >
                                        </Button>
                                        <Button
                                            className="grid-button"
                                            onClick={() => {
                                                requestFullscreen()
                                                setFullscreen(prev => !prev)
                                            }}
                                            render={() => {
                                                return fullscreen ?
                                                    (<CgMinimize size={"1rem"} color="#172B4D" />)
                                                    :
                                                    (<CgMaximize size={"1rem"} color="#172B4D" />)
                                            }}
                                            hint={fullscreen ? 'Багасгах' : 'Дэлгэцэнд бүтэн харуулах'}
                                        >
                                        </Button>
                                    </div>
                                </Item>
                                <Item name="exportButton" />
                                <Item name="columnChooserButton"
                                    render={(e) => {
                                        return <Button onClick={e.options.onClick} size='1rem' id='columnChooserButton' icon="columnchooser" />
                                    }}
                                />
                            </Toolbar>
                            {/*<SearchPanel visible={true} placeholder={'Хайх'} width={"300"}/>*/}
                            {/*<GroupPanel
                            visible={true}
                            emptyPanelText={"Энд багана чирж оруулна уу"}
                        />*/}
                            <Grouping autoExpandAll={autoExpandAll}
                                contextMenuEnabled={false}
                                expandMode="rowClick" />
                            <ColumnChooser
                                enabled={true} height={"100%"} allowSearch={true}
                                mode="select"
                                title={'Багана сонгох'}
                            />
                            <Column dataField="agenda_id" caption="" allowEditing={false} sortOrder={'asc'} showInColumnChooser={false}
                                groupIndex={0} groupCellComponent={groupCell} minWidth={'18rem'} width={'auto'} cssClass={'dx-datagrid-group-space'}>
                                <Lookup dataSource={refAgenda} valueExpr="id" displayExpr={(item) => {
                                    return item && `${item.code.substring(0, 5)} - ${item.name}`
                                }} /></Column>
                            <Column dataField="event_id" caption="" allowEditing={false} cssClass={'dx-datagrid-group-space'} sortOrder={'asc'} showInColumnChooser={false}
                                groupCellComponent={groupCell} minWidth={'18rem'} width={'auto'} groupIndex={1} alignment={'left'}>
                                <Lookup dataSource={refEvent} valueExpr="id" displayExpr={(item) => {
                                    return item && `${item.code.substring(0, 5)} - ${item.name}`
                                }} /></Column>
                            <Column dataField="econ_category_code_1" caption="" groupIndex={2} cssClass={'dx-datagrid-group-space'} showInColumnChooser={false}
                                groupCellComponent={groupCell} width={'1'} allowEditing={false}>
                                <Lookup dataSource={econCategory} valueExpr="code" displayExpr={(item) => {
                                    return item && `${item.code} - ${item.name}`
                                }} /></Column>
                            <Column dataField="econ_category_code_2" caption="" groupIndex={3} cssClass={'dx-datagrid-group-space'} showInColumnChooser={false}
                                groupCellComponent={groupCell} width={'1'} allowEditing={false}>
                                <Lookup dataSource={econCategory} valueExpr="code" displayExpr={(item) => {
                                    return item && `${item.code} - ${item.name}`
                                }} /></Column>
                            <Column dataField="econ_category_code_3" caption="" groupIndex={4} cssClass={'dx-datagrid-group-space'} showInColumnChooser={false}
                                groupCellComponent={groupCell} width={'1'} allowEditing={false}>
                                <Lookup dataSource={econCategory} valueExpr="code" displayExpr={(item) => {
                                    return item && `${item.code} - ${item.name}`
                                }} /></Column>
                            <Column dataField="econ_category_code_4" caption="" groupIndex={4} cssClass={'dx-datagrid-group-space'} showInColumnChooser={false}
                                groupCellComponent={groupCell} width={'1'} allowEditing={false}>
                                <Lookup dataSource={econCategory} valueExpr="code" displayExpr={(item) => {
                                    return item && `${item.code} - ${item.name}`
                                }} /></Column>
                            <Column dataField="econ_category_id" caption="Үзүүлэлт" fixed={true} fixedPosition={'left'} allowResizing={false} minWidth={'18rem'} width={'auto'} showInColumnChooser={false}
                                allowEditing={false}>
                                <Lookup dataSource={econCategory} valueExpr="id" displayExpr={(item) => {
                                    return item && `${item.code} - ${item.name}`
                                }} /></Column>
                            <Column dataField="debit" fixed={true} fixedPosition={'left'}
                                format="#,##0.00" alignment="right" dataType="number"
                                width={'auto'}
                                caption="Жилийн төлөвлөгөө" cellRender={customizedBold} allowFiltering={false} allowResizing={true}
                                allowEditing={false} />
                            <Column caption={'1-р улирал'} alignment={'center'} allowResizing={true} isBand={true}>
                                <Column dataField={`month1`} format="#,##0.00" alignment="right" dataType="number" editorOptions={textAreaOptions}
                                    caption={`1-p cap`} allowFiltering={false} allowResizing={true}>
                                    <RequiredRule />
                                    <CustomRule
                                        type="custom"
                                        message={"Нийт төсвийн дүнтэй тэнцүү байх"}
                                        validationCallback={customValidation}
                                    />
                                </Column>
                                <Column dataField={`month2`} format="#,##0.00" alignment="right" dataType="number" editorOptions={textAreaOptions}
                                    caption={`2-p cap`} allowFiltering={false} allowResizing={true}>
                                    <RequiredRule />
                                    <CustomRule
                                        type="custom"
                                        message={"Нийт төсвийн дүнтэй тэнцүү байх"}
                                        validationCallback={customValidation}
                                    />
                                </Column>
                                <Column dataField={`month3`} format="#,##0.00" alignment="right" dataType="number" editorOptions={textAreaOptions}
                                    caption={`3-p cap`} allowFiltering={false} allowResizing={true}>
                                    <RequiredRule />
                                    <CustomRule
                                        type="custom"
                                        message={"Нийт төсвийн дүнтэй тэнцүү байх"}
                                        validationCallback={customValidation}
                                    />
                                </Column>
                            </Column>
                            <Column caption={'2-р улирал'} alignment={'center'} allowResizing={true}>
                                <Column dataField={`month4`} format="#,##0.00" alignment="right" dataType="number" editorOptions={textAreaOptions}
                                    caption={`4-p cap`} allowFiltering={false} allowResizing={true}>
                                    <RequiredRule />
                                    <CustomRule
                                        type="custom"
                                        message={"Нийт төсвийн дүнтэй тэнцүү байх"}
                                        validationCallback={customValidation}
                                    />
                                </Column>
                                <Column dataField={`month5`} format="#,##0.00" alignment="right" dataType="number" editorOptions={textAreaOptions}
                                    caption={`5-p cap`} allowFiltering={false} allowResizing={true}>
                                    <RequiredRule />
                                    <CustomRule
                                        type="custom"
                                        message={"Нийт төсвийн дүнтэй тэнцүү байх"}
                                        validationCallback={customValidation}
                                    />
                                </Column>
                                <Column dataField={`month6`} format="#,##0.00" alignment="right" dataType="number" editorOptions={textAreaOptions}
                                    caption={`6-p cap`} allowFiltering={false} allowResizing={true}>
                                    <RequiredRule />
                                    <CustomRule
                                        type="custom"
                                        message={"Нийт төсвийн дүнтэй тэнцүү байх"}
                                        validationCallback={customValidation}
                                    />
                                </Column>
                            </Column>
                            <Column caption={'3-р улирал'} alignment={'center'} allowResizing={true}>
                                <Column dataField={`month7`} format="#,##0.00" alignment="right" dataType="number" editorOptions={textAreaOptions}
                                    caption={`7-p cap`} allowFiltering={false} allowResizing={true}>
                                    <RequiredRule />
                                    <CustomRule
                                        type="custom"
                                        message={"Нийт төсвийн дүнтэй тэнцүү байх"}
                                        validationCallback={customValidation}
                                    />
                                </Column>
                                <Column dataField={`month8`} format="#,##0.00" alignment="right" dataType="number" editorOptions={textAreaOptions}
                                    caption={`8-p cap`} allowFiltering={false} allowResizing={true}>
                                    <RequiredRule />
                                    <CustomRule
                                        type="custom"
                                        message={"Нийт төсвийн дүнтэй тэнцүү байх"}
                                        validationCallback={customValidation}
                                    />
                                </Column>
                                <Column dataField={`month9`} format="#,##0.00" alignment="right" dataType="number" editorOptions={textAreaOptions}
                                    caption={`9-p cap`} allowFiltering={false} allowResizing={true}>
                                    <RequiredRule />
                                    <CustomRule
                                        type="custom"
                                        message={"Нийт төсвийн дүнтэй тэнцүү байх"}
                                        validationCallback={customValidation}
                                    />
                                </Column>
                            </Column>
                            <Column caption={'4-р улирал'} alignment={'center'} allowResizing={true}>
                                <Column dataField={`month10`} format="#,##0.00" alignment="right" dataType="number" editorOptions={textAreaOptions}
                                    caption={`10-p cap`} allowFiltering={false} allowResizing={true}>
                                    <RequiredRule />
                                    <CustomRule
                                        type="custom"
                                        message={"Нийт төсвийн дүнтэй тэнцүү байх"}
                                        validationCallback={customValidation}
                                    />
                                </Column>
                                <Column dataField={`month11`} format="#,##0.00" alignment="right" dataType="number" editorOptions={textAreaOptions}
                                    caption={`11-p cap`} allowFiltering={false} allowResizing={true}>
                                    <RequiredRule />
                                    <CustomRule
                                        type="custom"
                                        message={"Нийт төсвийн дүнтэй тэнцүү байх"}
                                        validationCallback={customValidation}
                                    />
                                </Column>
                                <Column dataField={`month12`} format="#,##0.00" alignment="right" dataType="number" editorOptions={textAreaOptions}
                                    caption={`12-p cap`} allowFiltering={false} allowResizing={true}>
                                    <RequiredRule />
                                    <CustomRule
                                        type="custom"
                                        message={"Нийт төсвийн дүнтэй тэнцүү байх"}
                                        validationCallback={customValidation}
                                    />
                                </Column>
                            </Column>
                            <Editing
                                mode="batch"
                                allowUpdating={((props.location.state !== null && props.location.state.notOrg) || props.location.state === null && selectedBudgetPlan && [1, 4, 5].includes(selectedBudgetPlan.budget_project_status_id)) ? true : editable}
                                allowAdding={false}
                                allowDeleting={false}
                                confirmDelete={true}
                                selectTextOnEditStart={true}
                                texts={{
                                    addRow: "нэмэх",
                                    cancelAllChanges: "Болих бүгд",
                                    cancelRowChanges: "Болих",
                                    confirmDeleteMessage: "Энэ мэдээллийг устгах уу?",
                                    confirmDeleteTitle: "Баталгаажуулах",
                                    deleteRow: "Устгах",
                                    editRow: "Өөрчлөх",
                                    saveAllChanges: "Хадгалах бүгд",
                                    saveRowChanges: "Хадгалах",
                                    undeleteRow: "Буцаах",
                                    validationCancelChanges: "Баталгаажуулах болих",
                                }}
                            />
                            <Column type="buttons" width={"auto"} caption={"Үйлдэл"} visible={((props.location.state !== null && props.location.state.notOrg) || props.location.state === null && selectedBudgetPlan && [1, 4, 5].includes(selectedBudgetPlan.budget_project_status_id)) ? true : editable} fixed={true} fixedPosition={'right'} showInColumnChooser={false}>
                                <GridButton name="edit" text="Хуваарь өөрчлөх" cssClass="grid-button" />
                                <GridButton name="cancel" text="Цуцлах" cssClass="grid-button" />
                                <GridButton text="Тэнцүү хуваах" icon={'equal'} onClick={onEqualize} cssClass="grid-button" />
                                <GridButton text="Өмнөх жилийн хуваарийн өөрчлөлтөөр хуваах" icon={'return'} onClick={onPrevYearOffer} cssClass="grid-button" visible={setVisiblePrev} />
                                <GridButton text="Энэ мөрийг 0 болгох" icon={'clear'} onClick={onZero} cssClass="grid-button" />
                            </Column>
                            <Export enabled={true} allowExportSelectedData={false} />
                            <Summary recalculateWhileEditing={true} calculateCustomSummary={calculateAreaSummary}>
                                <GroupItem name="customSum"
                                    summaryType="custom"
                                    //column="debit" summaryType="sum"
                                    showInGroupFooter={false} alignByColumn={true}
                                    showInColumn="debit"
                                    customizeText={(data) => {
                                        return `${numberWithCommas(data.value)}`
                                    }} />
                                <GroupItem name="month1"
                                    summaryType="custom"//column="month1" summaryType="sum"
                                    showInGroupFooter={false} alignByColumn={true}
                                    showInColumn="month1" customizeText={(data) => {
                                        return `${numberWithCommas(data.value)}`
                                    }} />
                                <GroupItem name="month2"
                                    summaryType="custom"//column="month2" summaryType="sum"
                                    showInGroupFooter={false} alignByColumn={true}
                                    showInColumn="month2" customizeText={(data) => {
                                        return `${numberWithCommas(data.value)}`
                                    }} />
                                <GroupItem name="month3"
                                    summaryType="custom"//column="month3" summaryType="sum"
                                    showInGroupFooter={false} alignByColumn={true}
                                    showInColumn="month3" customizeText={(data) => {
                                        return `${numberWithCommas(data.value)}`
                                    }} />
                                <GroupItem name="month4"
                                    summaryType="custom"//column="month4" summaryType="sum"
                                    showInGroupFooter={false} alignByColumn={true}
                                    showInColumn="month4" customizeText={(data) => {
                                        return `${numberWithCommas(data.value)}`
                                    }} />
                                <GroupItem name="month5"
                                    summaryType="custom"//column="month5" summaryType="sum"
                                    showInGroupFooter={false} alignByColumn={true}
                                    showInColumn="month5" customizeText={(data) => {
                                        return `${numberWithCommas(data.value)}`
                                    }} />
                                <GroupItem name="month6"
                                    summaryType="custom"//column="month6" summaryType="sum"
                                    showInGroupFooter={false} alignByColumn={true}
                                    showInColumn="month6" customizeText={(data) => {
                                        return `${numberWithCommas(data.value)}`
                                    }} />
                                <GroupItem name="month7"
                                    summaryType="custom"//column="month7" summaryType="sum"
                                    showInGroupFooter={false} alignByColumn={true}
                                    showInColumn="month7" customizeText={(data) => {
                                        return `${numberWithCommas(data.value)}`
                                    }} />
                                <GroupItem name="month8"
                                    summaryType="custom"//column="month8" summaryType="sum"
                                    showInGroupFooter={false} alignByColumn={true}
                                    showInColumn="month8" customizeText={(data) => {
                                        return `${numberWithCommas(data.value)}`
                                    }} />
                                <GroupItem name="month9"
                                    summaryType="custom"//column="month9" summaryType="sum"
                                    showInGroupFooter={false} alignByColumn={true}
                                    showInColumn="month9" customizeText={(data) => {
                                        return `${numberWithCommas(data.value)}`
                                    }} />
                                <GroupItem name="month10"
                                    summaryType="custom" //column="month10" summaryType="sum"
                                    showInGroupFooter={false} alignByColumn={true}
                                    showInColumn="month10" customizeText={(data) => {
                                        return `${numberWithCommas(data.value)}`
                                    }} />
                                <GroupItem name="month11"
                                    summaryType="custom"
                                    showInGroupFooter={false} alignByColumn={true}
                                    showInColumn="month11" customizeText={(data) => {
                                        return `${numberWithCommas(data.value)}`
                                    }} />
                                <GroupItem name="month12"
                                    summaryType="custom"
                                    showInGroupFooter={false} alignByColumn={true}
                                    showInColumn="month12" customizeText={(data) => {
                                        return `${numberWithCommas(data.value)}`
                                    }} />
                                <TotalItem name="totalSum"
                                    summaryType="custom"
                                    //column="debit" summaryType="sum"
                                    showInGroupFooter={false} alignByColumn={true}
                                    showInColumn="debit"
                                    customizeText={(data) => {
                                        return `${numberWithCommas(data.value)}`
                                    }} />
                                <TotalItem name="month1sum"
                                    summaryType="custom"//column="month1" summaryType="sum"
                                    showInGroupFooter={false} alignByColumn={true}
                                    showInColumn="month1" customizeText={(data) => {
                                        return `${numberWithCommas(data.value)}`
                                    }} />
                                <TotalItem name="month2sum"
                                    summaryType="custom"
                                    showInGroupFooter={false} alignByColumn={true}
                                    showInColumn="month2"
                                    customizeText={(data) => {
                                        return `${numberWithCommas(data.value)}`
                                    }} />
                                <TotalItem name="month3sum"
                                    summaryType="custom"
                                    showInGroupFooter={false} alignByColumn={true}
                                    showInColumn="month3"
                                    customizeText={(data) => {
                                        return `${numberWithCommas(data.value)}`
                                    }} />
                                <TotalItem name="month4sum"
                                    summaryType="custom"
                                    showInGroupFooter={false} alignByColumn={true}
                                    showInColumn="month4"
                                    customizeText={(data) => {
                                        return `${numberWithCommas(data.value)}`
                                    }} />
                                <TotalItem name="month5sum"
                                    summaryType="custom"
                                    showInGroupFooter={false} alignByColumn={true}
                                    showInColumn="month5"
                                    customizeText={(data) => {
                                        return `${numberWithCommas(data.value)}`
                                    }} />
                                <TotalItem name="month6sum"
                                    summaryType="custom"
                                    showInGroupFooter={false} alignByColumn={true}
                                    showInColumn="month6"
                                    customizeText={(data) => {
                                        return `${numberWithCommas(data.value)}`
                                    }} />
                                <TotalItem name="month7sum"
                                    summaryType="custom"
                                    showInGroupFooter={false} alignByColumn={true}
                                    showInColumn="month7"
                                    customizeText={(data) => {
                                        return `${numberWithCommas(data.value)}`
                                    }} />
                                <TotalItem name="month8sum"
                                    summaryType="custom"
                                    showInGroupFooter={false} alignByColumn={true}
                                    showInColumn="month8"
                                    customizeText={(data) => {
                                        return `${numberWithCommas(data.value)}`
                                    }} />
                                <TotalItem name="month9sum"
                                    summaryType="custom"
                                    showInGroupFooter={false} alignByColumn={true}
                                    showInColumn="month9"
                                    customizeText={(data) => {
                                        return `${numberWithCommas(data.value)}`
                                    }} />
                                <TotalItem name="month10sum"
                                    summaryType="custom"
                                    showInGroupFooter={false} alignByColumn={true}
                                    showInColumn="month10"
                                    customizeText={(data) => {
                                        return `${numberWithCommas(data.value)}`
                                    }} />
                                <TotalItem name="month11sum"
                                    summaryType="custom"
                                    showInGroupFooter={false} alignByColumn={true}
                                    showInColumn="month11"
                                    customizeText={(data) => {
                                        return `${numberWithCommas(data.value)}`
                                    }} />
                                <TotalItem name="month12sum"
                                    summaryType="custom"
                                    showInGroupFooter={false} alignByColumn={true}
                                    showInColumn="month12"
                                    customizeText={(data) => {
                                        return `${numberWithCommas(data.value)}`
                                    }} />
                            </Summary>
                        </DataGrid>
                    </div>
                }

                <div style={{ margin: '20px 0' }} hidden={selectedIndex === 1 ? false : true}>
                    {/* {
                    props.location.state === null && [1, 4, 5].includes(selectedBudgetPlan?.budget_project_status_id) &&
                    <div style={{
                        display: "flex", flex: 1, justifyItems: "center", justifyContent: 'flex-end', marginRight: '1em'
                    }}>
                        <Button type={'default'} text="Хадгалах" icon="save" onClick={updateNote} />
                    </div>
                } */}
                    <div style={{ display: 'flex' }}>
                        <TextArea
                            autoResizeEnabled={true}
                            minHeight={150}
                            maxHeight={300}
                            //height={90}
                            width={"100%"}
                            maxLength={1000}
                            value={
                                note ?? (selectedBudgetPlan && selectedBudgetPlan?.note)
                            } readOnly={((props.location.state !== null && props.location.state.notOrg) || props.location.state === null && selectedBudgetPlan && [1, 4, 5].includes(selectedBudgetPlan?.budget_project_status_id)) ? false : true}
                            label="Нэмэлт тайлбар"
                            style={{ fontFamily: "Segoe UI", padding: '10px' }}
                            placeholder='Та хуваарийн саналын нэмэлт тайлбараа оруулна уу!'
                            onValueChanged={(e) => {
                                // request.request_note = e.value
                                setNote(e.value);
                            }}
                        />
                    </div>
                </div>
                <div hidden={selectedIndex === 1 ? false : true}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <h5 style={{ fontSize: '.875rem', margin: 0, alignSelf: 'end', paddingBottom: '5px' }}>Хавсралт файлууд:</h5>
                        {
                            ((props.location.state !== null && props.location.state.notOrg) || props.location.state === null && [1, 4, 5].includes(selectedBudgetPlan?.budget_project_status_id)) && <div style={{
                                display: "flex",
                                gap: "1em",
                                justifyItems: "center",
                                justifyContent: 'flex-end',
                                alignItems: "center",
                                marginBottom: '1.25rem'
                            }}>
                                <input type="file" name="files" style={{ display: "none" }} ref={inputFilesRef} multiple
                                    onChange={handleUpload} />
                                <Button type={'normal'} text="Файл хавсаргах" icon="attach" onClick={() => {
                                    inputFilesRef.current.click()
                                }} size={"2em"} />
                                <Button
                                    icon="textdocument"
                                    text="Албан бичиг боловсруулах"
                                    type="nomral"
                                    onClick={() => { setDocumentPopup(true) }}
                                />
                            </div>
                        }
                    </div>
                    <div>
                        <LoadPanelComponent position={{ of: '#fileUpload' }} visible={loading} />
                        <DataGrid dataSource={allFiles}
                            noDataText="Одоогоор файл хавсаргаагүй байна"
                            //style={{fontFamily: "'Montserrat', sans-serif", fontWeight: 'normal'}}
                            showBorders={true}
                            columnAutoWidth={true}
                            showRowLines={true}
                            showColumnLines={true}
                            id="fileUpload"
                            showColumnHeaders={true} onCellPrepared={onCellPreparedHandler} onOptionChanged={handleOptionChange}>
                            <HeaderFilter visible={true} allowSearch={true} texts={{ cancel: 'Болих', ok: 'Шүүх', emptyValue: 'Хоосон утга' }} />
                            <Column caption="№" width={'4rem'} alignment="center"
                                cellRender={orderRender}
                            />
                            <Column caption="Файлын нэр" alignment="left" dataField="file.content_name" />
                            <Column allowFiltering={false} defaultSelectedFilterOperation="contains" dataField="createdAt" width={'8rem'} alignment="left" caption="Огноо"
                                dataType="date" format="yyyy-MM-dd HH:mm:ss" />
                            <Column allowFiltering={false} caption="Хэмжээ" width={'8rem'} alignment="left" dataField="file.size" cellRender={(data) => {
                                if (data.rowType === 'data') {
                                    let sizeMb;
                                    let sizeKb;
                                    if (data.data.file.file_size > 1048576) {
                                        sizeMb = (data.data.file.file_size / 1048576).toFixed(2) + 'MB'
                                    } else {
                                        sizeKb = (data.data.file.file_size / 1024).toFixed(2) + 'KB'
                                    }
                                    return <span>{sizeMb ? sizeMb : sizeKb}</span>
                                }
                            }} />
                            <Column caption="Үйлдэл" alignment="center" width="10em" cellRender={fileActionRender} />
                            <Paging defaultPageIndex={0} pageSize={5} />
                        </DataGrid>
                    </div>
                </div>
                {filePopupModal && currentFile &&
                    <Popup
                        width={1200}
                        maxWidth={'100%'}
                        minHeight={'100%'}
                        height={'100%'}
                        visible={filePopupModal}
                        onHiding={() => {
                            setFilePopupModal(false)
                            setCurrentFile(null)
                        }
                        }
                        title={`Файлын нэр: ${currentFile?.file?.content_name ?? 'content_name хоосон байна'}`}
                        dragEnabled={false}
                        hideOnOutsideClick={true}
                    >
                        {/* {console.log('currentFile',currentFile)} */}
                        {
                            currentFile?.file?.file_extention === 'application/pdf' ?
                                <object width="100%" height="100%"
                                    data={urlServer + '/api/file/documents/' + currentFile?.file?.file_name}
                                    type="application/pdf" /> :
                                <img
                                    src={urlServer + '/api/file/documents/' + currentFile?.file?.file_name}
                                    width={'100%'} />
                        }
                    </Popup>}
                <div style={{ margin: '10px 0' }} hidden={selectedIndex === 1 ? false : true}>
                    {/* <div className="card"> */}
                    <h5 style={{ fontSize: '0.875rem', margin: 0, paddingBottom: '5px' }}>Шийдвэрлэлтийн түүх:</h5>
                    <DataGrid dataSource={budgetProjectLog} noDataText="Дата олдсонгүй."
                        //style={{fontFamily: "Segoe UI", fontWeight: 'normal'}}
                        showBorders={true}
                        id="approvalHistory"
                        showRowLines={true}
                        showColumnLines={true}
                        showColumnHeaders={true}
                        allowColumnResizing={true}
                        columnAutoWidth={true}
                        wordWrapEnabled={true} onCellPrepared={onCellPreparedHandler}>
                        <Column alignment="center" width={'4rem'} caption="№" cellRender={(data) => {
                            if (data.rowType === "data") {
                                return <span>{data.rowIndex + 1}</span>;
                            }
                            return null;
                        }} />
                        <Column dataField="createdAt" alignment="left" caption="Шийдвэрлэлтийн огноо"
                            dataType="date" format="yyyy-MM-dd HH:mm" sortOrder="asc" />
                        <Column dataField="action_name" alignment="left" caption="Төлөв"
                            cellRender={statusRenderGrid} />
                        <Column dataField="user_name" alignment="left" caption="Овог, нэр" />
                        <Column dataField="position_name" alignment="left" caption="Байгууллага, албан тушаал" />
                        <Column dataField="comment" alignment="left" caption="Тайлбар"
                            customizeText={(cellData) => {
                                return cellData.value !== "null" ? cellData.value : "Тайлбар бичигдээгүй"
                            }} />
                        <Paging defaultPageIndex={0} pageSize={5} />
                    </DataGrid>
                    {/* </div> */}
                    {/* {history && history.length > 0 &&
                    <div className={'card'}>
                        <div className='card-header'>
                            <span style={{fontFamily:'Segoe UI',
                                fontWeight: 'bold'}}>Түүх</span>
                        </div>
                        <div>
                            <DataGrid
                                dataSource={history}
                                //rowAlternationEnabled={true}
                                allowColumnReordering={true}
                                columnAutoWidth={true}
                                style={{marginTop: 5}}
                                showBorders={true}
                                showRowLines={true}
                                showColumnLines={true}
                                showColumnHeaders={true} onCellPrepared={onCellPreparedHandler}
                            >
                                <Paging defaultPageSize={10}/>
                                <FilterRow visible={false}/>
                                <HeaderFilter visible={false}/>
                                <Column dataField="createdAt" dataType="date" format="yyyy-MM-dd hh:mm:ss"
                                        caption="Огноо"/>
                                <Column dataField="agenda.name" caption="Хөтөлбөр"/>
                                <Column dataField="event.name" caption="Зориулалт"/>
                                <Column dataField="economic_category.name" caption="Эдийн засгийн ангилал"/>
                                <Column dataField="user.first_name" caption="Үйлдэл хийсэн хэрэглэгч"/>
                                <Column dataField="description" caption="Үйлдэл"/>
                            </DataGrid>
                        </div>
                    </div>
                }*/}
                </div>
                </div>

                
            </div>
        </div>
    )
}

export default PlanningOffer

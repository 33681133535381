import OrganizationService from "../../../services/api/organization";
import React, {useRef, useState} from "react";
import DropDownBox from "devextreme-react/drop-down-box";
import TreeView from "devextreme-react/tree-view";
import DataGrid, {
    Column,
    FilterRow,
    Grouping,
    GroupPanel,
    HeaderFilter,
    Paging, RequiredRule,
    Scrolling,
    Selection
} from "devextreme-react/data-grid";
import {FileUploader, SelectBox, TextBox} from "devextreme-react";
import HtmlEditor, {Item, Toolbar} from "devextreme-react/html-editor";
import LoadPanelComponent from "../../budget/components/loadPanel";
import ScrollView from "devextreme-react/scroll-view";
import Popup from "devextreme-react/popup";
import EmailService from "../../../services/api/email";
import {urlServer} from "../../../shared/lib/request";
import fileXlsIcon from "../../../assets/imgs/file_xls.png";
import filePngIcon from "../../../assets/imgs/file_png.png";
import fileJpgIcon from "../../../assets/imgs/file_jpg.png";
import filePptIcon from "../../../assets/imgs/file_ppt.png";
import filePdfIcon from "../../../assets/imgs/file_pdf.png";
import fileDocIcon from "../../../assets/imgs/file_doc.png";
import fileUnknownIcon from "../../../assets/imgs/file_unknown.png";
import EmailWorkerService from "../../../services/api/emailWorker";

const ViewEmail = ({ popupVisible, setPopupVisible, emailId }) => {

    const [organizations, setOrganizations] = useState([]);
    const [workers, setWorkers] = useState([]);
    const [treeView, setTreeView] = useState(null);
    const [treeBoxValue, setTreeBoxValue] = useState(null);
    const [isTreeBoxOpened, setIsTreeBoxOpened] = useState(false);
    const [gridView, setGridView] = useState(null);
    const [gridBoxValue, setGridBoxValue] = useState([]);
    const sizeValues = ["8pt", "10pt", "12pt", "14pt", "18pt", "24pt", "36pt"];
    const fontValues = [
        "Arial",
        "Courier New",
        "Georgia",
        "Impact",
        "Lucida Console",
        "Tahoma",
        "Times New Roman",
        "Verdana",
    ];
    const fontOptions = {placeholder: "Фонт"};
    const headerValues = [false, 1, 2, 3, 4, 5];
    const viewFormRef = useRef(null);
    const htmlRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [email, setEmail] = useState({});
    const [selectedEmail, setSelectedEmail]= useState(false);
    const [fileTypes, setFileTypes] = useState([]);
    const selectRef = useRef(null);

    const getEmailById = async () => {
        try {
            setIsLoading(true);

            const res = await EmailService.getByIdEmail(emailId);

            if (res && res.code === 200) {
                setEmail(res.data);
                setFileTypes([res.data.file_type]);
                setSelectedEmail(true);
                const res1 = await OrganizationService.getOrganizationIncludeParent(res.data.orgs);

                if (res1 && res1.code === 200) {
                    setOrganizations(res1.data);
                }
                setTreeBoxValue(res.data.orgs);
                const res2 = await OrganizationService.getOrgWorker(false, res.data.workers,0,0,'');

                setGridBoxValue(res2.data.map(item => item.wid));

                const res3 = await EmailWorkerService.getAll(emailId);

                for (const orgWorker of res2.data) {
                    const emailWorker = res3.data.find(({ worker_id }) => worker_id === orgWorker.wid);

                    orgWorker.is_seen = emailWorker.is_seen;
                    orgWorker.is_sent = emailWorker.is_sent;
                }

                setWorkers(res2.data);
            }

            setIsLoading(false);
        } catch (e) {
            console.log(e);
            setIsLoading(false);
        }
    }

    const handleHtmlChange = (event) => {
        htmlRef.current= event.value;
    }

    const treeViewRender = () => {
        return (
            <TreeView dataSource={organizations}
                      ref={(ref) => { setTreeView(ref) }}
                      dataStructure="plain"
                      keyExpr="id"
                      parentIdExpr="parent"
                      selectionMode="multiple"
                      displayExpr="org_name"
                      selectByClick={true}
                      showCheckBoxesMode="normal"
                      selectNodesRecursive={false}
                      onContentReady={treeViewOnContentReady}
                      onItemClick={onTreeItemClick}
                      // onItemSelectionChanged={treeViewItemSelectionChanged}
            />
        );
    }

    const treeViewItemSelectionChanged = (e) => {
        setTreeBoxValue(e.component.getSelectedNodeKeys());
    }

    const treeViewOnContentReady = (e) => {
        e.component.selectAll();
    }

    const onTreeItemClick = () => {
        setIsTreeBoxOpened(false);
    }

    const onTreeBoxOpened = (e) => {
        if (e.name === 'opened') {
            setIsTreeBoxOpened(e.value);
        }
    }

    const dataGridRender = () => {
        return (
            <DataGrid
                keyExpr="wid"
                ref={(ref) => { setGridView(ref) }}
                columnAutoWidth={false}
                allowColumnResizing={true}
                wordWrapEnabled={true}
                showColumnLines={true}
                showRowLines={true}
                rowAlternationEnabled={true}
                height={500}
                dataSource={workers}
                hoverStateEnabled={false}
                selectedRowKeys={gridBoxValue}
                >
                <Selection mode="multiple" />
                <Scrolling mode="virtual" />
                <GroupPanel visible={true} emptyPanelText={"Хүссэн баганаа дээш зөөн бүлэглэх боломжтой"}/>
                <Grouping autoExpandAll={false} />
                <HeaderFilter visible={true} allowSearch={true} />
                <Paging enabled={true} pageSize={10} />
                <FilterRow visible={true} />
                <Column
                    caption="Ерөнхий газар"
                    dataField="state_structure_name"
                    width={100}
                    groupIndex={0}
                />
                <Column
                    caption="Алба хэлтэс"
                    dataField="org_structure_name"
                    width={100}
                    groupIndex={1}
                />
                <Column
                    caption="Овог"
                    dataField="last_name"
                    width={100}
                />
                <Column
                    caption="Нэр"
                    dataField="first_name"
                    width={100}
                />
                <Column
                    caption="Цахим шуудан"
                    dataField="email"
                    width={200}
                />
                <Column
                    caption="Утас"
                    dataField="phone"
                    width={100}
                    alignment="center"
                />
                <Column
                    caption="Албан тушаал"
                    dataField="org_position_name"
                    width={100}
                />
                <Column
                    caption="Илгээсэн эсэх"
                    dataField="is_sent"
                    width={100}
                    alignment="center"
                    cellRender={(e) => { return e.value === 1 ? "Тийм" : "Үгүй" }}
                />
                <Column
                    caption="Уншсан эсэх"
                    dataField="is_seen"
                    width={100}
                    alignment="center"
                    cellRender={(e) => { return e.value === 1 ? "Тийм" : "Үгүй" }}
                />
            </DataGrid>
        );
    }

    const onShowing = () => {
        if (popupVisible) {
            getEmailById();
        }
    }

    const onHiding = () => {
        viewFormRef.current.reset();
        setSelectedEmail(false);
        setFileTypes([]);
        setTreeBoxValue(null);
        setGridBoxValue(null);
        setOrganizations([]);
        setPopupVisible(false);
    }

    const notificationIconSrc = (string) => {
        switch (string) {
            case "xlsx":
                return fileXlsIcon
            case "png":
                return filePngIcon
            case "jpg":
                return fileJpgIcon
            case "pptx":
                return filePptIcon
            case "pdf":
                return filePdfIcon
            case "docx":
                return fileDocIcon
            default:
                return fileUnknownIcon
        }
    }

    useState(() => {

    });

    return (
        <div>
            <Popup
                visible={popupVisible}
                onHiding={onHiding}
                onShowing={onShowing}
                dragEnabled={false}
                hideOnOutsideClick={true}
                showCloseButton={true}
                showTitle={true}
                title="Имэйл харах"
                width="65%"
                height="90vh"
            >
                <ScrollView width='100%' height='100%'>
                    <LoadPanelComponent
                        position={{of: "#renderCreateMenu"}}
                        visible={isLoading}
                    />
                    <form ref={viewFormRef}>
                        {email && <div className="dx-fieldset">
                            <div className="dx-field">
                                <div className="dx-field-label" style={{width: "20%"}}>Байгууллага: <span style={{color: 'red'}}>*</span></div>
                                <div className="dx-field-value" style={{width: "80%"}}>
                                    <DropDownBox
                                        value={treeBoxValue}
                                        opened={isTreeBoxOpened}
                                        valueExpr="id"
                                        displayExpr="org_name"
                                        placeholder="Байгууллага сонгох..."
                                        showClearButton={false}
                                        dataSource={organizations}
                                        onOptionChanged={onTreeBoxOpened}
                                        contentRender={treeViewRender}
                                    />
                                </div>
                            </div>
                            <div className="dx-field">
                                <div className="dx-field-label" style={{width: "20%"}}>Ажилтан: <span style={{color: 'red'}}>*</span></div>
                                <div className="dx-field-value" style={{width: "80%"}}>
                                    <DropDownBox
                                        value={gridBoxValue}
                                        valueExpr="wid"
                                        deferRendering={false}
                                        displayExpr="first_name"
                                        placeholder="Хэрэглэгч сонгох..."
                                        showClearButton={false}
                                        dataSource={workers}
                                        contentRender={dataGridRender}
                                    />
                                </div>
                            </div>
                            <div className="dx-field">
                                <div className="dx-field-label" style={{width: "20%"}}>Имэйлийн гарчиг: <span style={{color: 'red'}}>*</span></div>
                                <div className="dx-field-value" style={{width: "80%"}}>
                                    <TextBox name="title" readOnly={true} value={email.title} placeholder="Имэйлийн гарчиг" />
                                </div>
                            </div>
                            <div className="dx-field">
                                {selectedEmail && <HtmlEditor
                                    ref={htmlRef}
                                    readOnly={true}
                                    height="500px"
                                    name="content"
                                    valueType="html"
                                    onValueChanged={handleHtmlChange}
                                    defaultValue={email.content}
                                >
                                    <Toolbar multiline={true}>
                                        <Item name="size" acceptedValues={sizeValues} />
                                        <Item name="separator" />
                                        <Item name="font" acceptedValues={fontValues} options={fontOptions} />
                                        <Item name="separator" />
                                        <Item name="bold" />
                                        <Item name="italic" />
                                        <Item name="strike" />
                                        <Item name="underline" />
                                        <Item name="separator" />
                                        <Item name="alignLeft" />
                                        <Item name="alignCenter" />
                                        <Item name="alignRight" />
                                        <Item name="alignJustify" />
                                        <Item name="separator" />
                                        <Item name="orderedList" />
                                        <Item name="bulletList" />
                                        <Item name="separator" />
                                        <Item name="header" acceptedValues={headerValues} />
                                        <Item name="separator" />
                                        <Item name="color" />
                                        <Item name="background" />
                                        <Item name="separator" />
                                        <Item name="link" />
                                        <Item name="image" />
                                        <Item name="separator" />
                                        <Item name="clear" />
                                        <Item name="codeBlock" />
                                        <Item name="blockquote" />
                                        <Item name="separator" />
                                        <Item name="insertTable" />
                                        <Item name="deleteTable" />
                                        <Item name="insertRowAbove" />
                                        <Item name="insertRowBelow" />
                                        <Item name="deleteRow" />
                                        <Item name="insertColumnLeft" />
                                        <Item name="insertColumnRight" />
                                        <Item name="deleteColumn" />
                                    </Toolbar>
                                </HtmlEditor>}
                            </div>
                            <div className="dx-field">
                                <div className="dx-field-label" style={{width: "20%"}}>Файлын төрөл: </div>
                                <div className="dx-field-value" style={{width: "80%"}}>
                                    {fileTypes && fileTypes.length && fileTypes[0] ?
                                        <SelectBox
                                            items={fileTypes}
                                            ref={selectRef}
                                            disabled={true}
                                            value={fileTypes[0].id}
                                            displayExpr="name"
                                            valueExpr="id"
                                            name="file_type">
                                        </SelectBox>
                                    : null}
                                </div>
                            </div>
                            <div style={{padding: "20px 1rem"}}>
                                {(email.email_files && email.email_files.length)
                                    ? email.email_files.map((item, index) => {
                                        return (
                                            <a
                                                target="_self"
                                                key={index}
                                                href={
                                                    urlServer +
                                                    "/api/fileServices/getFile/" +
                                                    item.file.id
                                                }
                                                style={{
                                                    display: "inline-table",
                                                    marginRight: 20,
                                                    cursor: "pointer",
                                                    textAlign: "center",
                                                    textDecoration: "none",
                                                }}
                                            >
                                                {(() => {
                                                    return (
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                gap: ".5rem",
                                                                alignItems: "flex-end",
                                                                justifyContent: "flex-end",
                                                                marginTop: 10,
                                                            }}
                                                        >
                                                            <img
                                                                id={"notification_image_" + item.file.id}
                                                                style={{height: 40}}
                                                                src={notificationIconSrc(
                                                                    item.file.file_extention
                                                                )}
                                                            />
                                                            <span>{item.file.file_name.substring(14)}</span>
                                                        </div>
                                                    )
                                                })()}
                                            </a>
                                        )
                                    })
                                    : null}
                            </div>
                        </div>}
                    </form>
                </ScrollView>
            </Popup>
        </div>
    );
}

export default ViewEmail;

import React, {useState, useEffect, useRef} from "react";
import DataGrid, {Column, FilterRow, Lookup, Pager, Paging, Selection} from "devextreme-react/data-grid";
import notify from "devextreme/ui/notify";
import GeneralInfoService from "../../../services/api/generalInfo";
import CustomStore from "devextreme/data/custom_store";
import RefService from "../../../services/api/refs";
import Form, {ButtonItem, Label, SimpleItem} from "devextreme-react/form";
import {getYears} from "../../../util";
import {Button} from "devextreme-react/button";
import _ from "lodash";
import {LoadIndicator} from "devextreme-react/load-indicator";
import {numberWithCommas} from "../../../util";
import {custom} from "devextreme/ui/dialog";

const AdvancedBudgetLinker = (props) => {
    const [pageSize, setPageSize] = useState(10);
    const [pageIndex, setPageIndex] = useState(0);
    const [dataSource, setDataSource] = useState([]);
    const [agendaLookup, setAgendaLookup] = useState([]);
    const [eventLookup, setEventLookup] = useState([]);
    const [econCatLookup, setEconCatLookup] = useState([]);
    const [budgetStatusLookup, setBudgetStatusLookup] = useState([]);
    const [chosenRow, setChosenRow] = useState({});
    const [callService, setCallService] = useState(false);
    const [formData, setFormData] = useState({})
    const [waitSubmit, setWaitSubmit] = useState(false);
    const _daRef = useRef();
    const [prevBudgetId, setPrevBudgetId] = useState(null);
    const [triggerRefresh, setTriggerRefresh] = useState(false);
    const [multiFactorId, setMultiFactorId] = useState(null);

    const loadData = async () => {
        try {
            setDataSource({
                store: new CustomStore({
                    key: 'id',
                    load: function () {
                        return GeneralInfoService.budgetDataOnGeneralInfo(pageSize, pageIndex, formData)
                            .then((data) => {
                                return {
                                    data: data.data,
                                    totalCount: data.totalCount
                                }
                            })
                            .catch((e) => { notify(e.message, "error", 2000) })
                    }
                })
            })
        } catch (e) {
            notify(e.message, "error", 2000);
        }
    }

    const loadRefs = async () => {
        try {
            let agenda = await RefService.getAnda();
            setAgendaLookup(agenda.data);
            let event = await RefService.getRefEvent();
            setEventLookup(event.data);
            let econCat = await RefService.getRefEconCategory();
            setEconCatLookup(econCat.data);
            let budgetStatus = await GeneralInfoService.getRefBudgetStatus();
            setBudgetStatusLookup(budgetStatus);
        } catch (e) {
            notify(e.message, "error", 2000);
        }
    }

    useEffect(() => {
        loadData();
    }, [pageSize, pageIndex, callService])

    useEffect(() => {
        loadRefs();
    }, [])

    useEffect(() => {
        loadRegisteredBudgetData();
    }, [props.selectedId, triggerRefresh])

    const loadRegisteredBudgetData = async () => {
        try {
            let r = await GeneralInfoService.fetchSavedBudgetData(props.selectedId);
            if (r.length !== 0) {
                setChosenRow(r[0].budget);
                setPrevBudgetId(r[0].table_id);
                setMultiFactorId(r[0].id);
            } else {
                setChosenRow(null);
                setPrevBudgetId(null);
                setMultiFactorId(null);
            }
        } catch (e) {
            notify(e.message, "error", 2000);
        }
    }

    const onFormSubmit = async (e) => {
        for (const property in formData) {
            if (!formData[property]) {
                delete formData[property]
            }
        }
        setCallService(!callService);
        e.preventDefault();
    }

    const onCloseClick = () => {
        if (typeof props.callback === 'function') {
            props.callback()
        }
    }

    const saveBudgetHandler = async () => {
        try {
            setWaitSubmit(true);
            let customDialog = custom({
                title: "Баталгаажуулалт",
                messageHtml: `<span>${prevBudgetId ? 'Өмнөх мэдээллийг шинэчлэх гэж байна.' : 'Оруулах'}</span>`,
                buttons: [
                    {
                        text: "Тийм", onClick: async () => {
                            await GeneralInfoService.setGenInfoBudget({...chosenRow, mainTableId: props.selectedId, prevBudgetId: prevBudgetId})
                            notify("Амжилттай хадгаллаа", "success", 2000);
                            setTriggerRefresh(!triggerRefresh);
                            setWaitSubmit(false);
                        }
                    },
                    {
                        text: "Үгүй", onClick: () => {
                            return false;
                        }
                    }
                ]
            })
            await customDialog.show();
        } catch (e) {
            setWaitSubmit(false);
            notify(e.message, "error", 2000);
        }
    }

    const handleDelete = async () => {
        if (typeof props.deleteLinkedData === 'function') {
            setWaitSubmit(true);
            await props.deleteLinkedData(multiFactorId);
            setTriggerRefresh(!triggerRefresh);
            setWaitSubmit(false);
        }
    }

    return (
        <div>
            <div className="card">
                <div className="card-body" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <b>Төсөв оруулах</b>
                        <span>Сонгосон мэдээлэл: { props.title }</span>
                    </div>
                    <Button text="Хаах" icon="close" type="danger" onClick={onCloseClick}/>
                </div>
            </div>
            <div className="card">
                <div className="card-header">
                    <span>Хайлт</span>
                </div>
                <div className="card-body">
                    <form onSubmit={onFormSubmit}>
                        <Form formData={formData}>
                            <SimpleItem
                                dataField="budget_year"
                                editorType="dxSelectBox"
                                editorOptions={{
                                    searchEnabled: true,
                                    dataSource: getYears(),
                                    showClearButton: true
                                }}
                            >
                                <Label visible={true} text="Он"/>
                            </SimpleItem>
                            <SimpleItem
                                dataField="budget_month"
                                editorType="dxSelectBox"
                                editorOptions={{
                                    searchEnabled: true,
                                    dataSource: Array.from(Array(12), (v, index) => index + 1),
                                    showClearButton: true
                                }}
                            >
                                <Label visible={true} text="Сар"/>
                            </SimpleItem>
                            <SimpleItem
                                dataField="econ_category_id"
                                editorType="dxSelectBox"
                                editorOptions={{
                                    searchEnabled: true,
                                    dataSource: econCatLookup,
                                    displayExpr: "name",
                                    valueExpr: "id",
                                    showClearButton: true
                                }}
                            >
                                <Label visible={true} text="Эдийн засгийн ангилал"/>
                            </SimpleItem>
                            <SimpleItem
                                dataField="agenda_id"
                                editorType="dxSelectBox"
                                editorOptions={{
                                    searchEnabled: true,
                                    dataSource: agendaLookup,
                                    displayExpr: "name",
                                    valueExpr: "id",
                                    showClearButton: true
                                }}
                            >
                                <Label visible={true} text="Хөтөлбөр"/>
                            </SimpleItem>
                            <SimpleItem
                                dataField="event_id"
                                editorType="dxSelectBox"
                                editorOptions={{
                                    searchEnabled: true,
                                    dataSource: eventLookup,
                                    displayExpr: "name",
                                    valueExpr: "id",
                                    showClearButton: true
                                }}
                            >
                                <Label visible={true} text="Үйл ажиллагаа"/>
                            </SimpleItem>
                            <SimpleItem
                                dataField="budget_status_id"
                                editorType="dxSelectBox"
                                editorOptions={{
                                    searchEnabled: true,
                                    dataSource: budgetStatusLookup,
                                    displayExpr: "name",
                                    valueExpr: "id",
                                    showClearButton: true
                                }}
                            >
                                <Label visible={true} text="Төлөв"/>
                            </SimpleItem>
                            <ButtonItem horizontalAlignment="center" buttonOptions={{ text: 'Хайх', type: 'success', useSubmitBehavior: true }}/>
                        </Form>
                    </form>
                </div>
            </div>
            {chosenRow && !_.isEmpty(chosenRow) && <div className="card">
                <div className="card-body" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <span>Сонгосон төсөв: {chosenRow.description}&nbsp;{chosenRow && chosenRow.debit && numberWithCommas(chosenRow.debit)}</span>
                    <div>
                        <Button disabled={waitSubmit} type="success" onClick={saveBudgetHandler}>
                            <LoadIndicator style={{ height: '10px', width: '10px' }} className="button-indicator" visible={waitSubmit}/>
                            <span style={{ alignItems: 'center', justifyContent: 'center', verticalAlign: 'middle', display: 'inline-block' }} className="dx-button-text">Хадгалах</span>
                        </Button>
                        {multiFactorId && <Button disabled={waitSubmit} type="danger" onClick={handleDelete}>
                            <span style={{ alignItems: 'center', justifyContent: 'center', verticalAlign: 'middle', display: 'inline-block' }} className="dx-button-text">Устгах</span>
                        </Button>}
                    </div>
                </div>
            </div>}
            {/*Энэ хэсэгт тухайн сонгогдсон ID-руу үсэргэх боловч өгөгдөл нь хуудаслалттай backend-талаас авчирч буй учир шууд дараагийн хуудасруу үсрэх боломжгүй*/}
            {/*<button onClick={() => {_daRef.current.instance.navigateToRow(208066)}}>navigate to row</button>*/}
            <div className="card">
                <div className="card-header"><span>Төсөв</span></div>
                <div className="card-body">
                    <DataGrid
                        ref={_daRef}
                        allowColumnResizing={true}
                        remoteOperations={true}
                        dataSource={dataSource}
                        onSelectionChanged={(e) => {setChosenRow(e.selectedRowsData[0])}}
                    >
                        <Paging
                            defaultPageSize={pageSize}
                            onPageSizeChange={(e) => {setPageSize(e)}}
                            onPageIndexChange={(e) => {setPageIndex(e)}}
                        />
                        <Pager
                            visible={true}
                            allowedPageSizes={[5,10,25,50]}
                            showPageSizeSelector={true}
                            showNavigationButtons={true}
                        />
                        <FilterRow visible={true}/>
                        <Selection mode="single"/>
                        <Column dataField="budget_year" caption="Он"/>
                        <Column dataField="budget_month" caption="Сар"/>
                        <Column dataField="debit" caption="Дүн" dataType="number"/>
                        <Column dataField="econ_category_id" caption="Эдийн засгийн ангилал">
                            <Lookup dataSource={econCatLookup} displayExpr="name" valueExpr="id"/>
                        </Column>
                        <Column dataField="agenda_id" caption="Хөтөлбөр, зориулалт">
                            <Lookup dataSource={agendaLookup} displayExpr="name" valueExpr="id"/>
                        </Column>
                        <Column dataField="event_id" caption="Үйл ажиллагаа">
                            <Lookup dataSource={eventLookup} displayExpr="name" valueExpr="id"/>
                        </Column>
                        <Column dataField="budget_status_id" caption="Төлөв">
                            <Lookup dataSource={budgetStatusLookup} displayExpr="name" valueExpr="id"/>
                        </Column>
                        <Column dataField="description" caption="Тайлбар"/>
                    </DataGrid>
                </div>
            </div>

        </div>
    )
}

export default AdvancedBudgetLinker;

import React, {useContext, useRef, useState} from "react";
import TreeView from "devextreme-react/tree-view";
import OrganizationService from "../../../services/api/organization";
import documentService from "../../../services/api/document";
import FileServices from "../../../services/api/file";
import documentSharedService from "../../../services/api/documentShared";
import FileManager, {
    ContextMenu,
    FileSelectionItem,
    Item, ItemView,
    Notifications,
    Permissions,
    Toolbar
} from "devextreme-react/file-manager";
import {RadioGroup} from "devextreme-react";
import DropDownBox from "devextreme-react/drop-down-box";
import Form, {ButtonItem} from "devextreme-react/form";
import {Popup} from "devextreme-react/popup";
import {urlServer} from "../../../shared/lib/request";
import { Toast } from 'devextreme-react/toast';
import {UserContext} from "../../../hooks/UserContext";
import fileTypeService from "../../../services/api/fileType";
import SelectBox from "devextreme-react/select-box";
import FileUploader from "devextreme-react/file-uploader";
import ScrollView from "devextreme-react/scroll-view";
import LoadPanelComponent from "../../budget/components/loadPanel";

const DocumentManager = () => {

    const rootFolderName = "Бүх хавтас"
    const [currentPath, setCurrentPath] = useState(rootFolderName);
    const [currentPathId, setCurrentPathId] = useState({});
    const [popupShareVisible, setPopupShareVisible] = useState(false);
    const [popupUploadVisible, setPopupUploadVisible] = useState(false);
    const [documents, setDocuments] = useState([]);
    const [fileTypes, setFileTypes] = useState([]);
    const [organizations, setOrganizations] = useState([]);
    const [treeView, setTreeView] = useState(null);
    const [treeBoxValue, setTreeBoxValue] = useState(null);
    const [isTreeBoxOpened, setIsTreeBoxOpened] = useState(false);
    const [permissionCreate, setPermissionCreate] = useState(false);
    const [permissionCopy, setPermissionCopy] = useState(false);
    const [permissionMove, setPermissionMove] = useState(false);
    const [permissionDelete, setPermissionDelete] = useState(false);
    const [permissionRename, setPermissionRename] = useState(false);
    const [permissionUpload, setPermissionUpload] = useState(false);
    const [permissionDownload, setPermissionDownload] = useState(false);
    const [permissionShare, setPermissionShare] = useState(false);
    const [isRootDirectory, setIsRootDirectory] = useState(true);
    const [isSharedDirectory, setIsSharedDirectory] = useState(false);
    const [isDirectory, setIsDirectory] = useState(true);
    const shareTypes = ['Тийм', 'Үгүй'];
    const [isShareTypeActive, setIsShareTypeActive] = useState(false);
    const shareTypeRef = useRef();
    const [isVisibleToast, setIsVisibleToast] = useState(false);
    const [toastMessage, setToastMessage] = useState();
    const [toastType, setToastType] = useState();
    const {user} = useContext(UserContext);
    const [selectedDocument, setSelectedDocument] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    let selectRef = useRef(null);
    let fileRef = useRef(null);

    const treeViewRender = () => {
        return (
            <TreeView dataSource={organizations}
                      ref={(ref) => { setTreeView(ref) }}
                      dataStructure="plain"
                      keyExpr="id"
                      parentIdExpr="parent"
                      selectionMode="single"
                      displayExpr="org_name"
                      selectByClick={true}
                      showCheckBoxesMode="normal"
                      selectNodesRecursive={false}
                      onContentReady={treeViewOnContentReady}
                      onItemClick={onTreeItemClick}
                      onItemSelectionChanged={treeViewItemSelectionChanged}
            />
        );
    }

    const syncTreeViewSelection = (e) => {
        setTreeBoxValue(e.value);

        if (!treeView) return;

        if (!e.value) {
            treeView.instance.unselectAll();
        } else {
            treeView.instance.selectItem(e.value);
        }
    }

    const treeViewItemSelectionChanged = (e) => {
        setTreeBoxValue(e.component.getSelectedNodeKeys());
    }

    const treeViewOnContentReady = (e) => {
        e.component.selectItem(treeBoxValue);
    }

    const onTreeItemClick = () => {
        setIsTreeBoxOpened(false);
    }

    const onTreeBoxOpened = (e) => {
        if (e.name === 'opened') {
            setIsTreeBoxOpened(e.value);
        }
    }

    const showPopupShare = () => {
        try {
            getOrganizationIncludeParent();

            setPopupShareVisible(true);
        } catch (e) {
            console.log(e);
        }
    }

    const showPopupUpload = () => {
        try {
            getFileTypes();

            setPopupUploadVisible(true);
        } catch (e) {
            console.log(e);
        }
    }

    const hidePopupShare = () => {
        document.getElementById('addSharedForm').reset();
        setTreeBoxValue(null);
        setOrganizations([]);
        setPopupShareVisible(false);
    }

    const hidePopupUpload = () => {
        document.getElementById('addUploadForm').reset();
        selectRef.current.instance.reset();
        fileRef.current.instance.reset();
        setSelectedDocument(null);
        setPopupUploadVisible(false);
    }

    const getOrganizationIncludeParent = async () => {
        try {
            const res = await OrganizationService.getOrganizationIncludeParent();

            if (res && res.code === 200) {
                setOrganizations(res.data);
            }
        } catch (e) {
            console.log(e);
        }
    }

    const getDocuments = async () => {
        try {
            const res = await documentService.getDocuments();

            if (res && res.code === 200) {
                setDocuments(res.data);
            }
        } catch (e) {
            console.log(e);
        }
    }

    const getFileTypes = async () => {
        try {
            const res = await fileTypeService.getAllFileType();

            if (res && res.code === 200) {
                setFileTypes(res.data);
            }
        } catch (e) {
            console.log(e);
        }
    }

    const onCurrentDirectoryChanged = (e) => {
        if (e.directory.dataItem !== null && e.directory.dataItem !== undefined) {
            if (e.directory.dataItem.isSharedDirectory) {
                setIsDirectory(false);
                setIsSharedDirectory(true);
                setPermissionCreate(false);
                setPermissionUpload(false);
                setPermissionMove(false);
                setPermissionDelete(false);
                setPermissionRename(false);
                setPermissionShare(false);

                if (!e.directory.dataItem.isRootDirectory) {
                    setCurrentPathId(e.directory.dataItem.id);
                    setIsRootDirectory(false);
                } else {
                    setPermissionDownload(true);
                    setIsRootDirectory(true);
                }
            } else {
                setIsDirectory(true);
                setIsSharedDirectory(false);
                setPermissionCreate(true);

                if (!e.directory.dataItem.isRootDirectory) {
                    if (e.directory.dataItem.isDirectory) {
                        setSelectedDocument(e.directory.dataItem);
                        setPermissionRename(true);
                    } else {
                        setPermissionRename(false);
                    }

                    if (user.roles[0].role_code === "0" || user.roles[0].role_code === "1") {
                        if (e.directory.dataItem.isDirectory) {
                            setPermissionShare(true);
                        } else {
                            setPermissionShare(false);
                        }
                    } else {
                        setPermissionShare(false);
                    }

                    setPermissionUpload(true);
                    setCurrentPathId(e.directory.dataItem.id);
                    setPermissionMove(true);
                    setPermissionDelete(true);
                    setPermissionDownload(true);
                    setIsRootDirectory(false);
                } else {
                    setPermissionUpload(false);
                    setIsRootDirectory(true);
                    setPermissionMove(false);
                    setPermissionDelete(false);
                    setPermissionRename(false);
                    setPermissionDownload(false);
                    setPermissionShare(false);
                }
            }
        } else {
            setIsDirectory(false);
            setIsRootDirectory(true);
            setPermissionCreate(false);
            setPermissionUpload(false);
            setPermissionShare(false);
            setCurrentPathId(null);
        }

        setCurrentPath(e.component.option('currentPath'));
    }

    const onFocusedItemChanged = (e) => {
        try {
            if (e.item.dataItem !== null && e.item.dataItem !== undefined) {
                // e.item.select(e.item.isSelected());
                if (e.item.dataItem.isSharedDirectory) {
                    setIsDirectory(false);
                    setIsSharedDirectory(true);
                    setPermissionCreate(false);
                    setPermissionUpload(false);
                    setPermissionMove(false);
                    setPermissionDelete(false);
                    setPermissionRename(false);
                    setPermissionShare(false);

                    if (!e.item.dataItem.isRootDirectory) {
                        setCurrentPathId(e.item.dataItem.id);
                        setIsRootDirectory(false);
                    } else {
                        setPermissionDownload(true);
                        setIsRootDirectory(true);
                    }
                } else {
                    setIsSharedDirectory(false);
                    setPermissionCreate(true);

                    if (!e.item.dataItem.isRootDirectory) {
                        if (e.item.dataItem.isDirectory) {
                            setSelectedDocument(e.item.dataItem);
                            setIsDirectory(true);
                            setPermissionRename(true);
                        } else {
                            setIsDirectory(false);
                            setPermissionRename(false);
                        }

                        if (user.roles[0].role_code === "0" || user.roles[0].role_code === "1") {
                            if (e.item.dataItem.isDirectory) {
                                setPermissionShare(true);
                            } else {
                                setPermissionShare(false);
                            }
                        } else {
                            setPermissionShare(false);
                        }

                        setPermissionUpload(true);
                        setCurrentPathId(e.item.dataItem.id);
                        setPermissionMove(true);
                        setPermissionDelete(true);
                        setPermissionDownload(true);
                        setIsRootDirectory(false);
                    } else {
                        setPermissionUpload(false);
                        setIsDirectory(true);
                        setIsRootDirectory(true);
                        setPermissionMove(false);
                        setPermissionDelete(false);
                        setPermissionRename(false);
                        setPermissionDownload(false);
                        setPermissionShare(false);
                    }
                }
            } else {
                setIsDirectory(false);
                setIsRootDirectory(true);
                setPermissionCreate(false);
                setPermissionUpload(false);
                setPermissionShare(false);
                setCurrentPathId(null);
            }
        } catch (error) {
            // console.log(error);
        }
    }

    const onContextMenuShowing = (e) => {
        if (e.fileSystemItem.dataItem !== null && e.fileSystemItem.dataItem !== undefined) {
            if (e.fileSystemItem.dataItem.isSharedDirectory) {
                setIsDirectory(false);
                setIsSharedDirectory(true);
                setPermissionCreate(false);
                setPermissionUpload(false);
                setPermissionMove(false);
                setPermissionDelete(false);
                setPermissionRename(false);
                setPermissionShare(false);

                if (!e.fileSystemItem.dataItem.isRootDirectory) {
                    setCurrentPathId(e.directory.dataItem.id);
                    setIsRootDirectory(false);
                } else {
                    setPermissionDownload(true);
                    setIsRootDirectory(true);
                }
            } else {
                setIsSharedDirectory(false);
                setPermissionCreate(true);

                if (!e.fileSystemItem.dataItem.isRootDirectory) {
                    if (e.fileSystemItem.dataItem.isDirectory) {
                        setSelectedDocument(e.fileSystemItem.dataItem);
                        setIsDirectory(true);
                        setPermissionRename(true);
                    } else {
                        setIsDirectory(false);
                        setPermissionRename(false);
                    }

                    if (user.roles[0].role_code === "0" || user.roles[0].role_code === "1") {
                        if (e.fileSystemItem.dataItem.isDirectory) {
                            setPermissionShare(true);
                        } else {
                            setPermissionShare(false);
                        }
                    } else {
                        setPermissionShare(false);
                    }

                    setPermissionUpload(true);
                    setPermissionMove(true);
                    setPermissionDelete(true);
                    setPermissionDownload(true);
                    setIsRootDirectory(false);
                } else {
                    setPermissionUpload(false);
                    setIsDirectory(true);
                    setIsRootDirectory(true);
                    setPermissionMove(false);
                    setPermissionDelete(false);
                    setPermissionRename(false);
                    setPermissionDownload(false);
                    setPermissionShare(false);
                }
            }
        } else {
            setIsDirectory(false);
            setIsRootDirectory(true);
            setPermissionCreate(false);
            setPermissionUpload(false);
            setPermissionShare(false);
            setCurrentPathId(null);
        }
    }

    const onDirectoryCreating = async (e) => {
        let document = {}

        document.name = e.name
        document.is_directory = 1

        if (e.parentDirectory.dataItem) {
            document.parent_id = e.parentDirectory.dataItem.id
        }

        const res = await documentService.addDocument(document)

        if (res && res.code === 200) {
            getDocuments()
        }
    }

    const onFileUploading = async (e) => {
        let formData = new FormData()

        formData.append("file", e.fileData)

        const res = await FileServices.uploadFileManagement(formData)

        if (res && res.code === 200) {
            let document = {}

            document.file_id = res.data.id
            document.name = res.data.file_name
            document.is_directory = 0

            if (e.destinationDirectory.dataItem) {
                document.parent_id = e.destinationDirectory.dataItem.id
            }

            const res1 = await documentService.addDocument(document)

            if (res1 && res1.code === 200) {
                getDocuments()
            }
        }
    }

    const onItemRenaming = async (e) => {
        let document = {}

        document.name = e.newName

        const res = await documentService.updateDocument(e.item.dataItem.id, document)

        if (res && res.code === 200) {
            getDocuments()
        }
    }

    const onItemMoving = async (e) => {
        let document = {}

        if (e.destinationDirectory.dataItem) {
            document.parent_id = e.destinationDirectory.dataItem.id
        } else {
            document.parent_id = null
        }

        const res = await documentService.updateDocument(e.item.dataItem.id, document)

        if (res && res.code === 200) {
            getDocuments()
        }
    }

    const onItemDeleting = async (e) => {
        const res = await documentService.removeDocument(e.item.dataItem.id)

        if (res && res.code === 200) {
            getDocuments()
        }
    }

    const onItemDownloading = async (e) => {
        e.cancel = true;
        window.open(urlServer + "/api/fileServices/getFile/" + e.item.dataItem.file_id, '_blank');
    }

    const onClickShare = () => {
        showPopupShare();
    };

    const onClickUpload = () => {
        showPopupUpload();
    };

    const shareDocumentOptions = {
        items: [
            {
                text: 'Хуваалцах',
                icon: 'arrowright'
            }
        ],
        onItemClick: onClickShare
    }

    const uploadDocumentOptions = {
        items: [
            {
                text: 'Файл оруулах',
                icon: 'dx-icon dx-icon-upload'
            }
        ],
        onItemClick: onClickUpload
    }

    const uploadDocumentTemplate = () => {
        return <div onClick={onClickUpload}><i className="dx-icon dx-icon-upload"/><span> Файл оруулах</span></div>
    }

    const addShared = async (event) => {
        try {
            event.preventDefault()

            setIsLoading(true);

            let documentShared = {}

            documentShared.document_id = currentPathId
            documentShared.is_share_type_active = isShareTypeActive

            if (!isShareTypeActive) {
                documentShared.org_id = treeBoxValue[0]
            }

            const res = await documentSharedService.addDocumentShared(documentShared)

            if (res && res.code === 200) {
                getDocuments()
                hidePopupShare()

                showToast("Хуваалцах үйлдэл амжилттай хийгдлээ", "success");
            }

            setIsLoading(false);
        } catch (e) {
            console.log(e)
        }
    }

    const addUpload = async (event) => {
        try {
            event.preventDefault();

            setIsLoading(true);

            if (event.target.file_type.value && event.target.file.files.length) {
                for (let i = 0; i < event.target.file.files.length; i++) {
                    let data = new FormData();

                    data.append("file", event.target.file.files[i]);

                    const res = await FileServices.uploadFileManagement(data, event.target.file_type.value);

                    if (res && res.code === 200) {
                        let document = {};

                        document.file_id = res.data.id;
                        document.name = res.data.file_name;
                        document.is_directory = 0;

                        if (selectedDocument) {
                            document.parent_id = selectedDocument.id;
                        }

                        await documentService.addDocument(document);
                    }
                }

                getDocuments();
                hidePopupUpload();

                showToast("Файл амжилттай хадгалагдлаа", "success");
            }

            setIsLoading(false);
        } catch (e) {
            console.log(e)
        }
    }

    const onValueChangedShareType = (e) => {
        if (e.value === shareTypes[0]) {
            setIsShareTypeActive(true);
        } else {
            setIsShareTypeActive(false);
        }
    }

    const onHidingToast = () => {
        setIsVisibleToast(false);
    }

    const showToast = (message, type) => {
        setToastMessage(message);
        setToastType(type);
        setIsVisibleToast(true);
    }

    const customizeIcon = (fileManagerItem) => {
        if (fileManagerItem.isDirectory) {
            return "directory.png";
        }

        const fileExtension = fileManagerItem.name;

        if (fileExtension.includes(".jpg")) {
            return "file_jpg.png";
        } else if (fileExtension.includes(".png")) {
            return "file_png.png";
        } else if (fileExtension.includes(".docx") || fileExtension.includes(".doc")) {
            return "file_doc.png";
        } else if (fileExtension.includes(".xlsx") || fileExtension.includes(".xls")) {
            return "file_xls.png";
        } else if (fileExtension.includes(".pptx") || fileExtension.includes(".ppt")) {
            return "file_ppt.png";
        } else if (fileExtension.includes(".pdf")) {
            return "file_pdf.png";
        } else {
            return "file_unknown.png";
        }
    }

    useState(() => {
        getDocuments();
    })

    return (
        <div>
            <FileManager
                currentPath={currentPath}
                customizeThumbnail={customizeIcon}
                fileSystemProvider={documents}
                onCurrentDirectoryChanged={onCurrentDirectoryChanged}
                onDirectoryCreating={onDirectoryCreating}
                onItemRenaming={onItemRenaming}
                onItemMoving={onItemMoving}
                onItemDeleting={onItemDeleting}
                onItemDownloading={onItemDownloading}
                onFocusedItemChanged={onFocusedItemChanged}
                onContextMenuShowing={onContextMenuShowing}
                selectionMode="single"
                rootFolderName={rootFolderName}>
                <ItemView
                    mode="thumbnails"
                />
                <Notifications showPanel={false} showPopupShare={false} showPopup={false}/>
                <Permissions
                    create={permissionCreate}
                    copy={permissionCopy}
                    move={permissionMove}
                    delete={permissionDelete}
                    rename={permissionRename}
                    download={permissionDownload}>
                </Permissions>
                <Toolbar>
                    <Item name="create" text="Шинэ хавтас үүсгэх"/>
                    <Item name="copy" text="Хуулах"/>
                    <Item name="move" text="Зөөх"/>
                    <Item name="delete" text="Устгах"/>
                    <Item name="rename" text="Нэр өөрчлөх"/>
                    <Item name="download" text="Файл татах"/>
                    {isDirectory && !isSharedDirectory && permissionUpload && <Item widget="dxMenu" options={uploadDocumentOptions}/>}
                    <Item name="refresh"/>
                    {!isRootDirectory && !isSharedDirectory && permissionShare && <Item widget="dxMenu" options={shareDocumentOptions}/>}
                    <FileSelectionItem name="copy" text="Хуулах"/>
                    <FileSelectionItem name="move" text="Зөөх"/>
                    <FileSelectionItem name="delete" text="Устгах"/>
                    <FileSelectionItem name="rename" text="Нэр өөрчлөх"/>
                    <FileSelectionItem name="download" text="Файл татах"/>
                    {isDirectory && !isSharedDirectory && permissionUpload && <FileSelectionItem widget="dxMenu" options={uploadDocumentOptions}/>}
                    {!isRootDirectory && !isSharedDirectory && permissionShare && <FileSelectionItem widget="dxMenu" options={shareDocumentOptions}/>}
                    <FileSelectionItem name="clearSelection" text="Сонголтыг болиулах"/>
                </Toolbar>
                <ContextMenu>
                    <Item name="create" text="Шинэ хавтас үүсгэх"/>
                    {isDirectory && !isSharedDirectory && permissionUpload && <Item render={uploadDocumentTemplate}/>}
                    <Item name="copy" text="Хуулах"/>
                    <Item name="move" text="Зөөх"/>
                    <Item name="delete" text="Устгах"/>
                    <Item name="rename" text="Нэр өөрчлөх"/>
                    <Item name="download" text="Файл татах"/>
                </ContextMenu>
            </FileManager>
            <Popup
                visible={popupShareVisible}
                setPopupShareVisible={setPopupShareVisible}
                onVisibleChange={setPopupShareVisible}
                dragEnabled={false}
                hideOnOutsideClick={true}
                showCloseButton={true}
                showTitle={true}
                title="Хуваалцах"
                width={600}
                height={300}
                onHiding={hidePopupShare}
            >
                <LoadPanelComponent
                    position={{of: "#renderCreateMenu"}}
                    visible={isLoading}
                />
                <form id="addSharedForm" onSubmit={addShared}>
                    <div className="dx-fieldset">
                        <div className="dx-field">
                            <div className="dx-field-label">Яам бүхэлдээ хуваалцах</div>
                            <div className="dx-field-value">
                                <RadioGroup name="share_type" onValueChanged={onValueChangedShareType} ref={shareTypeRef} items={shareTypes} defaultValue={shareTypes[1]} layout="horizontal" />
                            </div>
                        </div>
                        <div className="dx-field">
                            <div className="dx-field-label">Ерөнхий газарт хуваалцах</div>
                            <div className="dx-field-value">
                                <DropDownBox
                                    disabled={isShareTypeActive}
                                    value={treeBoxValue}
                                    opened={isTreeBoxOpened}
                                    valueExpr="id"
                                    displayExpr="org_name"
                                    placeholder="Ерөнхий газар сонгох..."
                                    showClearButton={true}
                                    dataSource={organizations}
                                    onValueChanged={syncTreeViewSelection}
                                    onOptionChanged={onTreeBoxOpened}
                                    contentRender={treeViewRender}
                                />
                            </div>
                        </div>
                        <Form>
                            <ButtonItem
                                horizontalAlignment="center"
                                buttonOptions={{
                                    text: 'Хуваалцах',
                                    type: 'success',
                                    useSubmitBehavior: true,
                                }}
                            >
                            </ButtonItem>
                        </Form>
                    </div>
                </form>
            </Popup>
            <Popup
                visible={popupUploadVisible}
                setPopupUploadVisible={setPopupUploadVisible}
                onVisibleChange={setPopupUploadVisible}
                dragEnabled={false}
                hideOnOutsideClick={true}
                showCloseButton={true}
                showTitle={true}
                title="Файл оруулах"
                width={600}
                height={300}
                onHiding={hidePopupUpload}
            >
                <ScrollView>
                    <LoadPanelComponent
                        position={{of: "#renderCreateMenu"}}
                        visible={isLoading}
                    />
                    <form id="addUploadForm" onSubmit={addUpload}>
                        <div className="dx-fieldset">
                            <div className="dx-field">
                                <div className="dx-field-label">Файлын төрөл сонгох</div>
                                <div className="dx-field-value">
                                    <SelectBox
                                        name="file_type"
                                        ref={selectRef}
                                        items={fileTypes}
                                        displayExpr="name"
                                        valueExpr="id"/>
                                </div>
                            </div>
                            <div className="dx-field">
                                <div className="dx-field-label">Файл сонгох</div>
                                <div className="dx-field-value">
                                    <FileUploader
                                        name="file"
                                        selectButtonText="Файл сонгох"
                                        ref={fileRef}
                                        labelText=""
                                        multiple={true}
                                        accept="*"
                                        uploadMode="useForm"/>
                                </div>
                            </div>
                            <Form>
                                <ButtonItem
                                    horizontalAlignment="center"
                                    buttonOptions={{
                                        text: 'Хадгалах',
                                        type: 'success',
                                        useSubmitBehavior: true,
                                    }}
                                >
                                </ButtonItem>
                            </Form>
                        </div>
                    </form>
                </ScrollView>
            </Popup>
            <Toast
                visible={isVisibleToast}
                message={toastMessage}
                type={toastType}
                onHiding={onHidingToast}
                displayTime={600}
            />
        </div>
    );
}

export default DocumentManager;

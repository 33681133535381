import React from 'react';
import {Empty} from "antd"

const EmptyData = (props) => {
    return (
        <Empty
            style={{margin:"1rem auto"}}
            description="Дата байхгүй байна"
         />
    )
};

export default EmptyData;
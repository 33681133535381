import React, {useEffect, useState, useRef} from 'react';

import PivotGridDataSource from 'devextreme/ui/pivot_grid/data_source';


import fcsServices from "../../../../services/api/fcs";
import notify from "devextreme/ui/notify";
import Button from "devextreme-react/button";
import {Workbook} from 'exceljs';
import {saveAs} from 'file-saver-es';
// Our demo infrastructure requires us to use 'file-saver-es'.
//  We recommend that you use the official 'file-saver' package in your applications.
import {exportPivotGrid} from 'devextreme/excel_exporter';
import fcsService from "../../../../services/api/fcs";
import {Column, Editing, FilterRow, Item, Lookup, Selection, Toolbar, TreeList} from "devextreme-react/tree-list";
import DataGrid, {Export, Paging, RequiredRule, Summary, TotalItem} from "devextreme-react/data-grid";
import {list_to_tree, subtotal_tree} from "../../../../util";

import ExcelJS from 'exceljs';
//import saveAs from 'file-saver';

const St1Tree = (props) => {
    const year = props.year
    const year2 = props.year2
    const month1 = props.month1
    const month2 = props.month2
    const descr1 = props.descr1
    const descr2 = props.descr2
    const getDetails=props.getDetails
    const stId = props.stId
    const setAccountValue = props.setAccountValue
    const setBalance = props.setBalance
    const setEndBalance = props.setEndBalance

    const [loader, setLoader] = useState(false);

    const [st1End, setFcsSt1] = useState([])
    const [st3End, setFcsSt3] = useState([])
    const myGridRef = useRef(null);
    const [dataPivot, setDataPivot] = useState(null)
    const myBabyRef = useRef(null);
    const [expanded,setExpanded] =useState(false)
    const [expandedRowKeys, setExpandedRowKeys] = useState([]);
    let myTreeList = useRef({});
    // const setTimeout = () => {
    //     dataSource.expandHeaderItem('row', ['North America']);
    //     dataSource.expandHeaderItem('column', [2013]);
    // };


    useEffect(() => {
        procFcsSt1Cal()
    }, [stId])

    const onOptionChanged = (e) => {
        if(e.name === 'expandedRowKeys') {
            setExpandedRowKeys(e.value);
        }
    };

    const getFcsSt3End = async () => {
        await fcsService.getFcsSt3Bal().then((result) => {
            setFcsSt3(result.data)
            console.log("st33333333===", result)
        }).catch((err) => {
            setFcsSt3([])
            console.log(err)

        })


    }

    const ExportToFile =(name)=> {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet(name);

        const columns = myGridRef.current.instance.getVisibleColumns().filter(c => c.dataField);

        const header = getHeader(columns);
        worksheet.addRow(header);

        const data = getData(myGridRef.current.instance.getRootNode(), columns);
        data.map(row => worksheet.addRow(row));

        workbook.xlsx.writeBuffer().then((buffer) => {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), name + '.xlsx');
        });
    }


    const getFcsSt1End = async () => {
        let resultIss = ""
        if (stId == 1) {
            resultIss = await fcsService.getFcsSt1Bal()
        }
        if (stId == 2) {
            resultIss = await fcsService.getFcsSt2Bal()
        }
        if (stId == 4) {
            resultIss = await fcsService.getFcsSt4Bal()
        }

        if (stId == 5) {
            resultIss = await fcsService.getFcsSt1Bal()
        }
        if (stId == 6) {
            resultIss = await fcsService.getFcsSt2Bal()
        }


        let result = resultIss.data
        // result.sort(function (a, b) {
        //     return (a.acct > b.acct) ? 1 : ((b.title > a.title) ? -1 : 0);
        // });
        for (var i = result.length - 1; i > -1; i--) {
            if (result[i].head == 1) {
                var sum = 0
                var sum2 = 0
                var parent = result[i].id
                for (var k = 0; k < result.length; k++) {
                    if (result[k].parent_id == parent) {
                        sum = Number(sum) + Number(result[k].amt)
                        sum2 = Number(sum2) + Number(result[k].amt2)

                    }
                }
                result[i].amt = Number(result[i].amt) + Number(sum)
                result[i].amt2 = Number(result[i].amt2) + Number(sum2)

            }
        }
        setFcsSt1(result);
    }

    const selectionDblClick=async (e)=>{

        const d = e.data
        console.log("Dblllll====", d)

        if (d.head == 0 )  {
            getDetails(d)
        //     await setAccountValue(d.acct) ;  await setBalance(d.amt)
        // ;        await setEndBalance(d.amt2)
        //
        } else  await setAccountValue("")



    }
    function getHeader(columns) {
        let header = Array();
        for (let i = 0; i < columns.length; i++) {
            header[i] = columns[i].caption;
        }
        return header;
    }
    function getRow(node, columns) {
        let row = Array();
        for (let i = 0; i < columns.length; i++) {
            let cell = node.data[columns[i].dataField];
            if (i < 2)
                cell = '   '.repeat(node.level) + cell;

            row[i] = cell;
        }
        return row;
    }
    function getData(node, columns) {
        let rows = Array();
        if (node.level >= 0) {
            rows.push(getRow(node, columns));
        }
        for (var i = 0; i < node.children.length; i++) {
            getData(node.children[i], columns).map(row => rows.push(row));
        }
        return rows;
    }
    const selectionChangeEvent = (e) => {

        const d = e.selectedRowsData[0]

      if (d.head == 0 )  { setAccountValue(d.acct) ;  setBalance(d.amt)
                 ;        setEndBalance(d.amt2)    } else  setAccountValue("")


    }


    const sumRow = (rowData) => {
        return Number(rowData.c1) + Number(rowData.c2) + Number(rowData.c3);
    }


    const acctHead = (rowData) => {
        return rowData.head !== 1 ? "*" : ""
    }

    const procFcsSt1Cal = async () => {

        if (stId && stId == 1) {

            setLoader(true);


            try {

                const result = await fcsServices.procFcsSt1Update(year, month1, year2, month2);

                if (result) {

                    notify('Амжилттай ', "success", 2000);
                    getFcsSt1End()
                    return setLoader(false);
                } else {
                    return setLoader(false);
                }
            } catch (e) {
                notify(e.message, "error", 2000);
            }

        }

        //  st1n

        if (stId && stId == 5) {

            setLoader(true);


            try {

                const result = await fcsServices.procFcsSt1nUpdate(year, month1, year2, month2);

                if (result) {

                    notify('Амжилттай ', "success", 2000);
                    getFcsSt1End()
                    return setLoader(false);
                } else {
                    return setLoader(false);
                }
            } catch (e) {
                notify(e.message, "error", 2000);
            }

        }


        if (stId && stId == 2) {

            setLoader(true);


            try {

                const result = await fcsServices.procFcsSt2Update(year, month1, year2, month2);
                if (result) {

                    notify('Амжилттай ', "success", 2000);
                    getFcsSt1End()
                    return setLoader(false);
                } else {
                    return setLoader(false);
                }
            } catch (e) {
                notify(e.message, "error", 2000);
            }

        }
// st2n
        if (stId && stId == 6) {

            setLoader(true);


            try {

                const result = await fcsServices.procFcsSt2nUpdate(year, month1);
                if (result) {

                    notify('Амжилттай ', "success", 2000);
                    getFcsSt1End()
                    return setLoader(false);
                } else {
                    return setLoader(false);
                }
            } catch (e) {
                notify(e.message, "error", 2000);
            }

        }


        if (stId && stId == 3) {

            setLoader(true);


            try {

                const result = await fcsServices.procFcsSt3Update(year, month2);
                if (result) {

                    notify('Амжилттай ', "success", 2000);
                    getFcsSt3End()
                    return setLoader(false);
                } else {
                    return setLoader(false);
                }
            } catch (e) {
                notify(e.message, "error", 2000);
            }

        }


        //

        if (stId && stId == 4) {

            setLoader(true);


            try {

                const result = await fcsServices.procFcsSt4Update(year, month1, year2, month2);
                if (result) {

                    notify('Амжилттай ', "success", 2000);
                    getFcsSt1End()
                    return setLoader(false);
                } else {
                    return setLoader(false);
                }
            } catch (e) {
                notify(e.message, "error", 2000);
            }

        }


    };


    const onCellPreparedHandler = (e) => {
        if (e.rowType === 'header') {
            e.cellElement.style.color = "black"
            e.cellElement.style.backgroundColor = "#E8E8E8"
            // elem.style.setProperty('color', 'green');
        }
        if (e.rowType === 'data') {
            e.cellElement.style.color = "black"
            // elem.style.setProperty('color', 'green');
        }
    }


    const onExporting = React.useCallback((e) => {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Sales');

        exportPivotGrid({
            component: e.component,
            worksheet,
        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer], {type: 'application/octet-stream'}), 'Sales.xlsx');
            });
        });
        e.cancel = true;
    })


    return (
        <React.Fragment>

            {
                (stId == 1 || stId == 2 || stId == 4 || stId == 5 || stId == 6) &&
                <div className="card-body">
                    <TreeList
                        dataSource={st1End}
                        autoExpandAll={expanded}
                        keyExpr="id"
                        parentIdExpr="parent_id"
                        allowColumnResizing={true}
                        //  onEditorPreparing={onEditorPreparing}


                        //   onSaved={setAgenda}
                        //   onEditingStart={onEditingStartEvent}
                        //   onInitNewRow={initNewRowEvent}
                        // onSelectionChanged={selectionChangeEvent}
                        onCellDblClick={selectionDblClick}
                        columnAutoWidth={true}
                        expandedRowKeys={expandedRowKeys}
                        onCellPrepared={onCellPreparedHandler}
                        ref={myGridRef}
                        onOptionChanged={onOptionChanged}
                     //   onSelectionChanged={onSelectionChanged}

                    >
                        <FilterRow visible={true}/>
                        <Selection mode="single"/>
                        <Editing
                            mode="row"
                            allowUpdating={false}
                            // allowAdding={true}
                            //  allowDeleting={true}
                            confirmDelete={true}
                            useIcons={true}
                            confirmSave={false}
                            allowAdding={false}
                            texts={{
                                cancelAllChanges: 'Болих бүгд',
                                cancelRowChanges: 'Болих',
                                confirmDeleteMessage: 'Энэ хөтөлбөрийн мэдээллийг устгах уу?',
                                confirmDeleteTitle: 'Баталгаажуулах',
                                deleteRow: 'Устгах',
                                editRow: 'Өөрчлөх',
                                saveAllChanges: 'Хадгалах бүгд',
                                saveRowChanges: 'Хадгалах',
                                undeleteRow: 'Буцаах',
                                validationCancelChanges: 'Баталгаажуулах болих',
                            }}
                        />
                        <Column
                            cssClass="custom"
                            dataField="id"
                            calculateDisplayValue={acctHead}
                            caption=""
                            allowEditing={false}
                            allowCollapsing={false}
                            allowFiltering={true}

                            //  cellRender = {dateRender}
                        >

                        </Column>


                        <Column
                            cssClass="custom"

                            caption="Данс"
                            dataField="acct"
                            allowEditing={true}
                            allowCollapsing={false}
                            allowFiltering={true}
                            width={100}
                            //  cellRender = {dateRender}
                        >

                        </Column>



                        <Column
                            dataField="descr"
                            caption="нэр"
                            allowEditing={true}
                            // editCellTemplate = {}
                            //   cellRender = {dateRender}
                            cssClass="custom"
                            allowFiltering={true}

                        >
                            <RequiredRule
                                message=' нэр оруулна уу'
                            />
                        </Column>


                        <Column
                            cssClass="custom"
                            dataField="amt"
                            dataType={'number'}
                            caption={descr1}

                            format="#,##0.00"
                            allowFiltering={true}
                        >

                        </Column>
                        <Column
                            cssClass="custom"
                            dataField="amt2"
                            dataType={'number'}
                            caption={descr2}

                            format="#,##0.00"
                            allowFiltering={true}
                        >

                        </Column>


                        <Column
                            cssClass="custom"
                            dataField="accttype"
                            dataType={'string'}
                            caption="төрөл"
                            width={100}
                            allowEditing={true}
                            allowFiltering={true}
                        >
                            {/*<RequiredRule*/}
                            {/*    message='Регистрийн дугаар оруулна уу'*/}
                            {/*/>*/}
                        </Column>


                        {/*<Paging enabled={true} />*/}

                        <Toolbar>
                            <Item location="after">
                                <Button
                                   // text={expanded ? 'Collapse All' : 'Expand All'}
                                    text={''}
                                    icon={expanded ? 'collapse' : 'expand'}
                                    width={30}
                                    onClick={() => {
                                        setExpanded(prevExpanded => !prevExpanded)
                                        setExpandedRowKeys([]);
                                    }}
                                />
                                <Button
                                    text={'Excel'}
                                    style={{marginLeft:10}}
                                    icon={'download'}
                                    width={100}
                                    onClick={(e) =>{ExportToFile("st_"+stId+"_"+year2)}}
                                />
                            </Item>
                        </Toolbar>

                    </TreeList>
                </div>

            }

            {
                stId == 3 && <div>

                    <DataGrid
                        dataSource={st3End}
                        showBorders={true}
                        allowColumnResizing={true}
                        hoverStateEnabled={true}
                        keyExpr="id"
                        columnAutoWidth={true}
                        noDataText='Дата байхгүй байна.'
                        onExporting={onExporting}>
                        <Selection mode="single"/>
                        <Column caption="Код" dataField="id" dataType="string"/>

                        <Column caption="Үзүүлэлт" dataField="descr"/>

                        <Column caption="Засгийн газрын оруулсан капитал" dataField="c1" dataType="number"
                                format="#,##0.00"/>
                        <Column caption="Дахин үнэлгээний нөөц" dataField="c2" dataType="number" format="#,##0.00"/>
                        <Column caption="Хуримтлагдсан үр дүн" dataField="c3" dataType="number" format="#,##0.00"/>
                        <Column caption="Засгийн газрын оруулсан капитал"
                                calculateDisplayValue={sumRow}
                                dataType="number" format="#,##0.00"/>


                        <Paging
                            defaultPageSize={100}
                        />
                        <Export enabled={true} allowExportSelectedData={true}/>
                    </DataGrid>

                </div>
            }


        </React.Fragment>
    );

}


const currencyFormatter = new Intl.NumberFormat(
    'en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
    },
);

function customizeTooltip(args) {
    const valueText = currencyFormatter.format(args.originalValue);
    return {
        html: `${args.seriesName} | Total<div class="currency">${valueText}</div>`,
    };
}


export default St1Tree;

import React, { useEffect, useState, useRef, useCallback, useContext } from 'react';
import _ from "lodash";
import {
  TreeList,
  Column,
  Scrolling, Toolbar, Item, Button as TreeButton
} from 'devextreme-react/tree-list';
import ProposalService from "../../../services/api/proposal";
import { helperGroupByKey, } from "../static/data";
import {formatDate, list_to_tree, numberWithCommas, subtotal_tree} from "../../../util";
import LoadPanelComponent from "../../budget/components/loadPanel";
import { Button } from 'devextreme-react/button';
import {Workbook} from 'exceljs';
import saveAs from 'file-saver';
import { CgArrowsExpandRight, CgCompressRight, CgMaximize, CgMinimize } from "react-icons/cg";
import { Tooltip } from "devextreme-react";
import { Popup } from "devextreme-react/popup";
import ChartDetailByYear from "./ChartDetailByYear";
import { UserContext } from '../../../hooks/UserContext';
import moment from 'moment';



export default function ProposalEza(props) {
  const economicCategoryArray = useRef([]);
  const [dataSource, setDataSource] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  let curProposalRef = useRef(null);
  let treeList = useRef(null);
  let myTreeList = useRef({});
  let mapProposal = useRef({});
  let mapIdArr = useRef({});
  let mapCodeArr = useRef({});
  let mapCodeArrData = useRef({});
  const [expanded, setExpanded] = useState(false);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [amtDiff, setAmtDiff] = useState(0);
  const [ttzDiff, setTtzDiff] = useState(0);
  const [tezDiff, setTezDiff] = useState(0);
  const [sumYear0, setSumYear0] = useState(0);
  const [sumYear1, setSumYear1] = useState(0);
  const [sumYear2, setSumYear2] = useState(0);
  const [sumYear3, setSumYear3] = useState(0);
  const [sumYear4, setSumYear4] = useState(0);
  const [sumYear5, setSumYear5] = useState(0);
  const [sumYear100, setSumYear100] = useState(0);
  const [sumYear101, setSumYear101] = useState(0);
  const [sumYear102, setSumYear102] = useState(0);
  const [sumYear103, setSumYear103] = useState(0);
  const [fullscreen, setFullscreen] = useState(false);
  const [popupDetail, setPopupDetail] = useState(false);
  const [selectionData, setSelectionData] = useState(null);
  const [orgId, setOrgId] = useState(null);
  const [year, setYear] = useState(null);
  const {user}=useContext(UserContext)
  useEffect(() => {
    initVars();
    loadData();
  }, []);


  const initVars = async () => {
    const { proposal } = props;
    if (proposal) curProposalRef.current = proposal;
  };

  const loadData = async () => {
    if (Array.isArray(props.eza)) {

      props.eza.forEach(o => {
        mapProposal.current[o.id] = {
          amount: 0,
          description: ""
        }
        mapCodeArr.current[o.code] = o.id;
        mapIdArr.current[o.id] = o.code;
        mapCodeArrData.current[o.code] = o;
      });
    }
    economicCategoryArray.current = _.sortBy(props.eza, ['code']);
    loadAndSetupProposalParam();
  };

  const loadAndSetupProposalParam = async () => {

    setIsLoading(true);
    const res = await ProposalService.getProposalDetailByProjectId(props.proposal.id)
    const temp = await ProposalService.listVBudgetCompareEza(props.year, props.proposal.org_id);
    // convert
    let result = helperConvertProposals(temp.data, res.data);

    // convert tree list
    // console.log("result", list_to_tree(result))
    myTreeList.current = list_to_tree(result);
    updateSubTotal();
    let array = [];
    //console.log('result', result);
    if (result.length > 0) {
      for (var i = result.length - 1; i > -1; i--) {
        var sum = 0;
        var sum1 = 0;
        var sum2 = 0
        var sum3 = 0
        var sum4 = 0
        var sum5 = 0
        var sum6 = 0
        var sum7 = 0
        var sum8 = 0
        var sum9 = 0
        var sum10 = 0
        var sum11 = 0
        var sum12 = 0
        var sum13 = 0
        var sum14 = 0
        var sum15 = 0
        var parent = result[i].id;
        for (var k = 0; k < result.length; k++) {
          if (result[k].parent_id === parent) {
            sum = Number(sum) + Number(result[k].amount);
            sum1 = Number(sum1) + Number(result[k].amount_mn);
            sum2 = Number(sum2) + Number(result[k].base_cur_amount);
            sum3 = Number(sum3) + Number(result[k].base_cur_amount_mn);
            sum4 = Number(sum4) + Number(result[k].tez_amount);
            sum5 = Number(sum5) + Number(result[k].ttz_amount);
            sum6 = Number(sum6) + Number(result[k].year0);
            sum7 = Number(sum7) + Number(result[k].year1);
            sum8 = Number(sum8) + Number(result[k].year2);
            sum9 = Number(sum9) + Number(result[k].year3);
            sum10 = Number(sum10) + Number(result[k].year4);
            sum11 = Number(sum11) + Number(result[k].year5);
            sum12 = Number(sum12) + Number(result[k].year100);
            sum13 = Number(sum13) + Number(result[k].year101);
            sum14 = Number(sum14) + Number(result[k].year102);
            sum15 = Number(sum15) + Number(result[k].year103);
            //console.log('result[k].year3', result[k].year3);
            //console.log('sum9',sum9);
            /*sum9= Number(sum8) + Number(result[k].year`-1`);
            sum10 = Number(sum8) + Number(result[k].year`-2`);
            sum11 = Number(sum8) + Number(result[k].year`-3`);*/
          }
        }
        result[i].name = result[i].code + '-' + result[i].name;
        if ((result[i].amount && result[i].amount !== undefined ? result[i].amount + Number(sum) : 0) +
          (result[i].amount_mn && result[i].amount_mn !== undefined ? result[i].amount_mn + Number(sum1) : 0) +
          (result[i].base_cur_amount && result[i].base_cur_amount !== undefined ? result[i].base_cur_amount + Number(sum2) : 0) +
          (result[i].base_cur_amount_mn && result[i].base_cur_amount_mn !== undefined ? result[i].base_cur_amount_mn + Number(sum3) : 0) +
          (result[i].tez_amount && result[i].tez_amount !== undefined ? result[i].tez_amount + Number(sum4) : 0) +
          (result[i].ttz_amount && result[i].ttz_amount !== undefined ? result[i].ttz_amount + Number(sum5) : 0) +
          (result[i].year0 && result[i].year0 !== undefined ? result[i].year0 + Number(sum6) : 0) +
          (result[i].year1 && result[i].year1 !== undefined ? result[i].year1 + Number(sum7) : 0) +
          (result[i].year2 && result[i].year2 !== undefined ? result[i].year2 + Number(sum8) : 0) +
          (result[i].year3 && result[i].year3 !== undefined ? result[i].year3 + Number(sum9) : 0) +
          (result[i].year4 && result[i].year4 !== undefined ? result[i].year4 + Number(sum10) : 0) +
          (result[i].year5 && result[i].year5 !== undefined ? result[i].year5 + Number(sum11) : 0) +
          (result[i].year100 && result[i].year100 !== undefined ? result[i].year100 + Number(sum12) : 0) +
          (result[i].year101 && result[i].year101 !== undefined ? result[i].year101 + Number(sum13) : 0) +
          (result[i].year102 && result[i].year102 !== undefined ? result[i].year102 + Number(sum14) : 0) +
          (result[i].year103 && result[i].year103 !== undefined ? result[i].year103 + Number(sum15) : 0) === 0
        ) { array.push(result[i].id) };
      }

    }
    let d = _.filter(result, (item) => {
      return _.indexOf(array, item.id) === -1
    })
    //console.log('dddd', d);
    let res2 = _.filter(d, item => {
      return item.amount * 1 + item.tez_amount * 1 + item.ttz_amount * 1 !== 0 && item.code.length === 6 && item.code.substring(0, 1) === '2'
    });
    let res1 = _.filter(d, item => {
      return item.amount * 1 + item.tez_amount * 1 + item.ttz_amount * 1 !== 0 && item.code.length === 6 && item.code.substring(0, 1) === '1'
    });
    let res3 = _.filter(d, item => {
      return item.amount * 1 + item.tez_amount * 1 + item.ttz_amount * 1 !== 0 && item.code.length === 6 && item.code.substring(0, 1) === '3'
    });

    //Zardal amount
    let zardalAmt = _.sumBy(res2, r => {
      return r.amount * (1);
    });
    //Orlogo amount
    let orlogoAmt = _.sumBy(res1, r => {
      return r.amount * (1);
    });
    //EhUusver amount
    let ehUusverAmt = _.sumBy(res3, r => {
      return r.amount * (1);
    });

    //Zardal ttz
    let zardalTtz = _.sumBy(res2, r => {
      return r.ttz_amount * (1);
    });
    //Orlogo ttz
    let orlogoTtz = _.sumBy(res1, r => {
      return r.ttz_amount * (1);
    });
    //EhUusver ttz
    let ehUusverTtz = _.sumBy(res3, r => {
      return r.ttz_amount * (1);
    });

    //Zardal tez
    let zardalTez = _.sumBy(res2, r => {
      return r.tez_amount * (1);
    });
    //Orlogo tez
    let orlogoTez = _.sumBy(res1, r => {
      return r.tez_amount * (1);
    });
    //EhUusver tez
    let ehUusverTez = _.sumBy(res3, r => {
      return r.tez_amount * (1);
    });

    setAmtDiff((orlogoAmt + ehUusverAmt) - zardalAmt);
    setTtzDiff((orlogoTtz + ehUusverTtz) - zardalTtz);
    setTezDiff((orlogoTez + ehUusverTez) - zardalTez);
    setDataSource(d);
    let year0 = _.sumBy(d, r => {
      return r.year0 * (1);
    });
    let year1 = _.sumBy(d, r => {
      return r.year1 * (1);
    });
    let year2 = _.sumBy(d, r => {
      return r.year2 * (1);
    });
    let year3 = _.sumBy(d, r => {
      return r.year3 * (1);
    });
    let year4 = _.sumBy(d, r => {
      return r.year4 * (1);
    });
    let year5 = _.sumBy(d, r => {
      return r.year5 * (1);
    });
    let year100 = _.sumBy(d, r => {
      return r.year100 * (1);
    });
    let year101 = _.sumBy(d, r => {
      return r.year101 * (1);
    });
    let year102 = _.sumBy(d, r => {
      return r.year102 * (1);
    });
    let year103 = _.sumBy(d, r => {
      return r.year103 * (1);
    });
    setSumYear0(year0);
    setSumYear1(year1);
    setSumYear2(year2);
    setSumYear3(year3);
    setSumYear4(year4);
    setSumYear5(year5);
    setSumYear100(year100);
    setSumYear101(year101);
    setSumYear102(year102);
    setSumYear103(year103);
    setIsLoading(false);
  };
  const helperConvertProposals = (budgetsHistory, current) => {
    // console.log(budgetsHistory, current);
    /* let res = [];
     economicCategoryArray.current.forEach(e => {
       res.push({...e})
     });
 
     let currCopy = [...current];
     // make sure to get the total sum amount by econ category id
     const currentDetailGrouped = _.groupBy(currCopy, 'econ_category_id');
     const currentDetailEconIds = Object.keys(currentDetailGrouped);
     const summedDetail = {};
     currentDetailEconIds.forEach(econCategoryId => {
       let temp = {econCategoryId, amount: 0, ttz_amount: 0, tez_amount: 0}
       currentDetailGrouped[econCategoryId].forEach(detail => {
         temp.amount += detail.amount * 1;
         temp.ttz_amount += detail.ttz_amount * 1;
         temp.tez_amount += detail.tez_amount * 1;
       });
       summedDetail[econCategoryId] = temp;
     });
 
 
     let historyGrouped = helperGroupByKey(budgetsHistory, 'econ_category_id');
     historyGrouped.forEach(item => {
 
       // make sure to get the total sum amount by econ category id
       const historyGroupedByYear = _.groupBy(item.data, 'budget_year');
       console.log('historyGroupedByYear', historyGroupedByYear);
       const summedByYear = {};
       Object.keys(historyGroupedByYear).forEach((budgetYear) => {
         let temp = {budgetYear,budgetStatus:null, amount: 0}
         historyGroupedByYear[budgetYear].forEach(detail => {
           if((props.year - detail.budget_year * 1) === 1 && !([7,100,101,102,103].includes(detail.budget_status_id))
           ) {    console.log('detail', detail); temp.amount += detail.amount * 1;temp.budgetStatus = 4 }
           else if((props.year - detail.budget_year * 1) !== 1 && detail.budget_status_id === 7 ) {temp.amount += detail.amount * 1;temp.budgetStatus = 7}
           else if((props.year - detail.budget_year * 1) === 0 && detail.budget_status_id === 10 ) {temp.amount += detail.amount * 1;temp.budgetStatus = 10}
           else if((props.year - detail.budget_year * 1) === -1 && detail.budget_status_id === 12 ) {temp.amount += detail.amount * 1;temp.budgetStatus = 12}
           else if((props.year - detail.budget_year * 1) === -2 && detail.budget_status_id === 12 ) {temp.amount += detail.amount * 1;temp.budgetStatus = 12}
           else if((props.year - detail.budget_year * 1) === 1 && detail.budget_status_id === 100) {temp.amount += detail.amount * 1;temp.budgetStatus = 100}
           else if((props.year - detail.budget_year * 1) === 1 && detail.budget_status_id === 101) {temp.amount += detail.amount * 1;temp.budgetStatus = 101}
           else if((props.year - detail.budget_year * 1) === 1 && detail.budget_status_id === 102) {temp.amount += detail.amount * 1;temp.budgetStatus = 102}
           else if((props.year - detail.budget_year * 1) === 1 && detail.budget_status_id === 103) {temp.amount += detail.amount * 1;temp.budgetStatus = 103}
         });
         summedByYear[budgetYear]= temp;
       });
       console.log('summedByYear', summedByYear);
       const temp = {};
       Object.keys(summedByYear).forEach(year => {
         console.log('props.year', year);
         //if (summedByYear[year]['budget_status_id'] !== 7) {
         if(props.year - year * 1 && summedByYear[year]['budgetStatus'] === 10 ) {
           temp[`year3`] = summedByYear[year]['amount']
         }
         else if(props.year - year * 1 === -1 && summedByYear[year]['budgetStatus'] === 12) {
           temp[`year4`] = summedByYear[year]['amount']
         }
         else if(props.year - year * 1 === -2 && summedByYear[year]['budgetStatus'] === 12) {
           temp[`year5`] = summedByYear[year]['amount']
         }
         else if(props.year - year * 1 === 1 && summedByYear[year]['budgetStatus'] === 100) {
           temp[`year100`] = summedByYear[year]['amount']
         }
         else if(props.year - year * 1 === 1 && summedByYear[year]['budgetStatus'] === 101) {
           temp[`year101`] = summedByYear[year]['amount']
         }
         else if(props.year - year * 1 === 1 && summedByYear[year]['budgetStatus'] === 102) {
           temp[`year102`] = summedByYear[year]['amount']
         }
         else if(props.year - year * 1 === 1 && summedByYear[year]['budgetStatus'] === 103) {
           temp[`year103`] = summedByYear[year]['amount']
         }
         else if(props.year - year * 1 === 1 && summedByYear[year]['budgetStatus'] === 4)
           temp[`year0`] = summedByYear[year]['amount']
         //}
       });
       let find = res.findIndex(el => el.id === item.key);
       res[find] = {...res[find], ...temp}
     });
 
     Object.keys(summedDetail).forEach(econCatId => {
       let find = res.findIndex(el => el.id === summedDetail[econCatId].econCategoryId * 1);
       const { amount, ttz_amount, tez_amount} = summedDetail[econCatId];
       if (find !== -1) {
         res[find] = {...res[find], amount, ttz_amount, tez_amount};
       }
     });
     return res;
 */
    let res = [];

    economicCategoryArray.current.forEach((e) => {
      res.push({ ...e });
    });
    let currCopy = [...current];
    // if (budgetsHistory.length > 0) {
    const currentDetailGrouped = _.groupBy(currCopy, 'econ_category_id');
    const currentDetailEconIds = Object.keys(currentDetailGrouped);
    const summedDetail = {};
    currentDetailEconIds.forEach(econCategoryId => {
      let temp = { econCategoryId, amount: 0, ttz_amount: 0, tez_amount: 0 }
      currentDetailGrouped[econCategoryId].forEach(detail => {
        temp.amount += detail.amount * 1;
        temp.ttz_amount += detail.ttz_amount * 1;
        temp.tez_amount += detail.tez_amount * 1;
      });
      summedDetail[econCategoryId] = temp;
    });
    let groupedArr = helperGroupByKey(budgetsHistory, "econ_category_id");
    groupedArr.forEach((item) => {
      const temp = {};
      item.data.forEach((element) => {
        if ((props.year - element.budget_year * 1) !== 1 && element.budget_status_id === 7) {
          temp[`year${props.year - element["budget_year"] - 1}`] =
            element["amount"];
        } else if ((props.year - element.budget_year * 1) === 1 && [4, 5, 9].includes(element.budget_status_id)) {
          temp[`year${0}`] = element.amount = element.amount * 1;
        }
        else if ((props.year - element.budget_year * 1) === 0 && element.budget_status_id === 10) { temp[`year${props.year - element["budget_year"] + 3}`] = element["amount"]; }
        else if ((props.year - element.budget_year * 1) === -1 && element.budget_status_id === 12) { temp[`year${props.year - element["budget_year"] + 5}`] = element["amount"]; }
        else if ((props.year - element.budget_year * 1) === -2 && element.budget_status_id === 12) { temp[`year${props.year - element["budget_year"] + 7}`] = element["amount"]; }
        else if ((props.year - element.budget_year * 1) === 1 && element.budget_status_id === 100) { temp[`year${100}`] = element["amount"] * 1; }
        else if ((props.year - element.budget_year * 1) === 1 && element.budget_status_id === 101) { temp[`year${101}`] = element["amount"] * 1; }
        else if ((props.year - element.budget_year * 1) === 1 && element.budget_status_id === 102) { temp[`year${102}`] = element["amount"] * 1; }
        else if ((props.year - element.budget_year * 1) === 1 && element.budget_status_id === 103) { temp[`year${103}`] = element["amount"] * 1; }
      });
      // if exist, find the current year proposal by econ_id

      let find = res.findIndex(el => el.id === item.key);
      res[find] = { ...res[find], ...temp }
    });
    Object.keys(summedDetail).forEach(econCatId => {
      let find = res.findIndex(el => el.id === summedDetail[econCatId].econCategoryId * 1);
      const { amount, ttz_amount, tez_amount } = summedDetail[econCatId];
      if (find !== -1) {
        res[find] = { ...res[find], amount, ttz_amount, tez_amount };
      }
    });

    /*  const curDataIndex = currCopy.findIndex(
          (el) => el.econ_category_id === item.key
      );

      if (curDataIndex !== -1) {
        temp["amount"] = currCopy[curDataIndex]["amount"];
        temp["ttz_amount"] = currCopy[curDataIndex]["ttz_amount"];
        temp["tez_amount"] = currCopy[curDataIndex]["tez_amount"];
        // so that we can add the rest of the currentData
      }
      let find = res.findIndex((el) => el.id === item.key);
        res[find] = {
          ...res[find],
          ...temp,
        }; // ATTENTIO, static for now
    });
    // res.push(...currCopy); // currCopy is new ones that client has added, (no history)
    // projects.current = res;
    currCopy.forEach((item) => {
      let find = res.findIndex((el) => el.id === item.econ_category_id);
      const {
        amount,
        ttz_amount,
        tez_amount,
      } = item;
      if (find !== -1) {
        let temp2 = { ...res[find] };
        res[find] = {
          ...temp2,
          amount,
          ttz_amount,
          tez_amount,
        };
      }
    });*/
    return res;
  };
  const updateSubTotal = (index = -1) => {
    if (index === -1) {
      myTreeList.current.forEach((item) => {
        subtotal_tree(item);
        return item;
      });
    } else {
      if (typeof myTreeList.current[index] == 'undefined') {
        console.error('out of bounds baby')
      } else {
        let item = myTreeList.current[index];
        subtotal_tree(item);
      }
    }
  }
  /*const cellRenderEza = (data) => {
    return <span
      className={`proposal-eza with-code code-${data.data.code.length}`}><span>{data.data.code}</span> - {data.displayValue}</span>;
  }*/
  function getHeader(columns) {
    let header = Array();
    for (let i = 0; i < columns.length; i++) {
      header[i] = columns[i].caption;
    }
    return header;
  }
  function getRow(node, columns) {
    let row = Array();
    for (let i = 0; i < columns.length; i++) {
      let cell = node.data[columns[i].dataField];
      if (i < 2)
        cell = '   '.repeat(node.level) + cell;

      row[i] = cell;
    }
    return row;
  }
  function getData(node, columns) {
    let rows = Array();
    if (node.level >= 0) {
      rows.push(getRow(node, columns));
    }
    for (var i = 0; i < node.children.length; i++) {
      getData(node.children[i], columns).map(row => rows.push(row));
    }
    return rows;
  }
  const ExportToFile = (name) => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet(name);
    worksheet.columns = [{ width: 50 }, { width: 30 }, { width: 30 }, { width: 30 }, { width: 30 }, { width: 30 }]
    const columns = treeList.current.instance.getVisibleColumns().filter(c => c.dataField);
    columns.keepColumnWidths = true;

    const header = getHeader(columns);
    for (let i = 0; i < 6; i++) {
      worksheet.addRow([]);
  } 
  worksheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {
    for (let i = 0; i < 6; i++) {
        row.getCell(i + 1).value = '';
    }
});

    const headerRow = worksheet.addRow(header);

    headerRow.eachCell((cell, colNumber)=>{
      cell.font = {'bold': true, size: 8, name: 'Arial'}
      cell.alignment = {horizontal: 'center'}
      cell.fill = {type: 'pattern', pattern: 'solid', fgColor: {argb: 'd9d9d9'}}
    })

    worksheet.views = [
      { state: 'split', topLeftCell: 'A8', activePane: 'topLeft' }
  ];
    const data = getData(treeList.current.instance.getRootNode(), columns);
    data.forEach((rowData, rowIndex) => {
      const dataRow = worksheet.addRow(rowData);
      const numbersArray = dataRow.values[1].match(/\d+/g)
      dataRow.eachCell((cell, colNumber) => {
        cell.font = { size: 8, name: 'Arial' };
        if (colNumber !== 1) {
          const numericValue = parseFloat(cell.value)
          cell.value = isNaN(numericValue) ? 0 : numericValue
          cell.numFmt = '#,##0.00'
          cell.alignment = { horizontal: 'right' }
        }
        if(numbersArray[0].length !== 6 && colNumber === 1){
          cell.font = {'bold': true, name: 'Arial', size: 8}
        }
      })
    })
      Object.assign(worksheet.getRow(1).getCell(1), {
          value: 'Гарчиг:',
          font: {name: 'Arial', size: 8 },
        })    
        Object.assign(worksheet.getRow(1).getCell(2), {
          value: `${props.year} оны төсвийн төсөл /ЭЗА/`,
          font: {name: 'Arial', size: 8, 'bold': true, color: {argb: '0070c0'} },
        }) 
        Object.assign(worksheet.getRow(2).getCell(1), {
          value: 'Байгууллага:',
          font: {name: 'Arial', size: 8 },
        })    
        Object.assign(worksheet.getRow(2).getCell(2), {
          value: props.name,
          font: {name: 'Arial', size: 8 },
        })    
        Object.assign(worksheet.getRow(3).getCell(1), {
          value: 'Хэрэглэгч:',
          font: {name: 'Arial', size: 8 },
        })    
        Object.assign(worksheet.getRow(3).getCell(2), {
          value: `${user.last_name.slice(0,1)}. ${user.first_name}, ${user.position_name}`,
          font: {name: 'Arial', size: 8 },
        }) 
      Object.assign(worksheet.getRow(4).getCell(1), {
          value: 'Огноо:',
          font: {name: 'Arial', size: 8 },
        })    
        Object.assign(worksheet.getRow(4).getCell(2), {
          value: new moment().locale('mn').format('lll'),
          font: {name: 'Arial', size: 8 },
        })    

    workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), name + '.xlsx');
      });
  }
  const onOptionChanged = (e) => {
    if (e.name === 'expandedRowKeys') {
      setExpandedRowKeys(e.value);
    }
  };
  const renderAmountHeader = () => {
    return <span style={{ color: amtDiff === 0 ? '#3DAA75' : '#f66160' }}>ТШЗ санал <br />{numberWithCommas(amtDiff)}</span>;
  }
  const renderTtzHeader = () => {
    return <span style={{ color: ttzDiff === 0 ? '#3DAA75' : '#f66160' }}>ТТЗ санал<br />{numberWithCommas(ttzDiff)}</span>;
  }
  const renderTezHeader = () => {
    return <span style={{ color: tezDiff === 0 ? '#3DAA75' : '#f66160' }}>ТЕЗ санал<br />{numberWithCommas(tezDiff)}</span>;
  }
  const renderHBHeader = () => {
    return <span style={{ color: '#909090' }} id="proposalHB_Header">{props.year - 1} оны ХБГ</span>;
  }

  const cellRender = (e, type) => {
    let value = 0;
    let value1 = 0;
    let amount = 0;
    //console.log('e.data.year0', e.data);
    // this.props.proposal.budget_project_status_id
    switch (props.proposal.budget_project_status_id) {
      case 1:
        amount = e.data.amount * 1;
        break;
      case 2:
        amount = e.data.tez_amount * 1;
        break;
      case 3:
        amount = e.data.ttz_amount * 1;
        break;
      case 4:
        amount = e.data.amount * 1;
        break;
      case 5:
        amount = e.data.amount * 1;
        break;
      case 6:
        amount = e.data.tez_amount * 1;
        break;
      case 7:
        amount = e.data.ttz_amount * 1;
        break;
      default:
        amount = e.data.amount * 1;
        break;
    }

    switch (type) {
      case 'year0':
        value = amount * 1;
        value1 = e.data.year0 * 1;
        break;
      case 'year1':
        value = amount * 1;
        value1 = e.data.year1 * 1;
        break;
      case 'year2':
        value = amount * 1;
        value1 = e.data.year2 * 1;
        break;
      case 'year3':
        value = amount * 1;
        value1 = e.data.year3 * 1;
        break;
      case 'year4':
        value = amount * 1;
        value1 = e.data.year4 * 1;
        break;
      case 'year5':
        value = amount * 1;
        value1 = e.data.year5 * 1;
        break;
      case 'ttz':
        value = e.data.ttz_amount * 1;
        value1 = e.data.amount * 1;
        break;
      case 'tez':
        value = e.data.tez_amount * 1;
        value1 = e.data.ttz_amount * 1;
        break;
      default:
        value = 0;
        value1 = 0;
        break;
    }
    let percent = 0;
    if (value || value1) {
      percent = value1 !== 0 ? (value - value1) * 100 / value1 : 0;
    }

    if (percent > 0) {
      return (<div class="inc customCell" style={{ flexDirection: 'column', display: 'flex', justifyContent: 'space-between' }}>
        <div class="current-value" style={{ fontWeight: 'normal', color: '#000000' }}>{e.text}</div>
        <div class="diff" style={{ color: '#f66160' }}>{numberWithCommas(percent)}%</div>
      </div>)
    } else if (percent < 0) {
      return <div class="dec customCell" style={{ flexDirection: 'column', display: 'flex', justifyContent: 'space-between' }}>
        <div class="current-value" style={{ fontWeight: 'normal', color: '#000000' }}>{e.text}</div>
        <div class="diff" style={{ color: '#3daa75' }}>{numberWithCommas(percent)}%</div>
      </div>
    } else {
      return <span style={{ fontWeight: 'normal', color: '#000000' }}>{e.text}</span>
    }
  }
  const requestFullscreen = () => {
    if (document.fullscreenElement) document.exitFullscreen();
    else document.getElementById("wrapper").requestFullscreen();
    // setFullscreen(prev=>!prev)
  }
  const ExpandCollapseButton = useCallback(() => {
    return (<Button
      onClick={(e) => { setExpanded(prev => !prev); setExpandedRowKeys([]); }}
      render={() => {
        return expanded ? (
          <CgCompressRight size={"17px"} color="#1a1976" />)
          :
          (
            <CgArrowsExpandRight size={"17px"} color="#1a1976" />
          )
      }}
      hint={expanded ? 'Багасгах' : 'ЭЗА дэлгэрэнгүй харуулах'}
    >
    </Button>)
  }, [expanded])

  const FullscreenButton = useCallback(() => {
    return (
      <Button
        onClick={() => {
          requestFullscreen()
          setFullscreen(prev => !prev)
        }}
        style={{ marginLeft: "0.5em" }}
        render={() => {
          return fullscreen ?
            (<CgMinimize size={"17px"} color="#1a1976" />)
            :
            (<CgMaximize size={"17px"} color="#1a1976" />)
        }}
        hint={fullscreen ? 'Багасгах' : 'Дэлгэцэнд бүтэн харуулах'}
      >
      </Button>
    )
  }, [fullscreen])

  const cellPrepared = (e) => {
    if(e.rowType === "header"){
      e.cellElement.style.backgroundColor = "#EEF0F4"
      e.cellElement.style.color = "#15186A"
      e.cellElement.style.fontWeight = "bold"
      e.cellElement.style.textAlign = 'center'
    }
    if (e.rowType === "data" && (e.columnIndex === 0) && e.data.code.length !== 6) {
      e.cellElement.style.color = "#003696";
      e.cellElement.style.fontWeight = "bold";
    }
    if(e.column.dataField === "amount" && e.rowType === "data"){
      e.cellElement.style.fontWeight = "bold"
    }
  }
  const onChartDetail = async (e) => {
    if (e.row.data) {
      //console.log("e.row.data", e.row.data)
      setIsLoading(true);
      setOrgId(props.proposal.org_id)
      setYear(props.year);
      setSelectionData(e.row.data);
      setPopupDetail(true);
      setIsLoading(false);
    }
  }
  const renderDetail = props => {
    return (
      <Popup
        visible={popupDetail}
        onHiding={() => setPopupDetail(false)}
        hideOnOutsideClick={true}
        showCloseButton={true}
        height="80vh"
        showTitle={true}
        title={selectionData.name + (selectionData.code.substring(0, 1) !== '4' ? '/жилээр, сая ₮/' : '/жилээр/')}
      >
        <div className="card-body" style={{ paddingBottom: "1.6rem" }}>
          <ChartDetailByYear orgId={orgId} agendaId={0} eventId={0} econCode={selectionData.code} year={year} />
        </div>
      </Popup>
    )
  }
  return (
    <div className="row">
      <LoadPanelComponent position={{ of: '#dataGrid' }} visible={isLoading} />
      <Tooltip
        target={"#proposalHB_Header"}
        showEvent="mouseenter"
        hideEvent="mouseleave"
        hideOnOutsideClick={false}
        position="top"
      >
        <div style={{ padding: "1em" }}>
          {props.year - 1} оны хүлээгдэж байгаа гүйцэтгэл нь тухайн жилийн нийт гүйцэтгэлээс илүү болон зөрүүтэй байх нь хуваарийн өөрчлөлт хийгдээгүй байж болно.
        </div>
      </Tooltip>
      <div className="col-12" id="wrapper" height="100%" width="100%" style={{ backgroundColor: "#fff" }}>
        <TreeList
          ref={treeList}
          headerFilter={true}
          height="100%"
          id="dataGrid"
          dataSource={dataSource}
          keyExpr="id"
          hoverStateEnabled={true}
          showRowLines={true}
          showColumnLines={true}
          showColumnHeaders={true}
          showBorders={true}
          autoExpandAll={expanded}
          expandedRowKeys={expandedRowKeys}
          parentIdExpr="parent_id"
          allowColumnResizing={true}
          allowColumnReordering={true}
          onOptionChanged={onOptionChanged}
          scrolling={'true'}
          onCellPrepared={cellPrepared}
          columnAutoWidth={'true'}
        // columnHidingEnabled={true}
        // columnChooser={{
        //   enabled: true,
        //   mode: "select",
        //   title: "",
        //   tooltip: "Багана сонгох"
        // }}
        >
          <Column
            dataField="name"
            caption="Эдийн засгийн ангилал"
          //cellRender={cellRenderEza}
          >
          </Column>
          <Column caption={`${props.year - 3}`}
          visible={sumYear2 === 0 ? false : true}>
          <Column
            dataField="year2"
            caption={`Гүйцэтгэл`}
            format="#,##0.00"
            allowEditing={false}
            cellRender={(e) => cellRender(e, 'year2')}
            
          />
          </Column>
          <Column caption={`${props.year - 2}`}>
          <Column
            dataField="year1"
            caption={`Гүйцэтгэл`}
            format="#,##0.00"
            allowEditing={false}
            cellRender={(e) => cellRender(e, 'year1')}
            visible={sumYear1 === 0 ? false : true}
          />
          </Column>
          <Column caption={`${props.year - 1}`}>
          <Column
            dataField="year0"
            caption={`Төсөв`}
            format="#,##0.00"
            allowEditing={false}
            cellRender={(e) => cellRender(e, 'year0')}
            visible={sumYear0 === 0 ? false : true}
          />
          <Column
            dataField="year103"
            caption={`ХБГ`}
            format="#,##0.00"
            allowEditing={false}
            allowFiltering={false}
            //cellRender={(e) =>cellRender(e,'year0')}
            headerCellRender={renderHBHeader}
            visible={sumYear103 === 0 ? false : true}
          />
          <Column
            dataField="year100"
            caption={`ТШЗ санал`}
            format="#,##0.00"
            allowEditing={false}
            allowFiltering={false}
            //cellRender={(e) =>cellRender(e,'year0')}
            visible={sumYear100 === 0 ? false : true}
          />
          <Column
            dataField="year101"
            caption={` ТТЗ санал`}
            format="#,##0.00"
            allowEditing={false}
            allowFiltering={false}
            //cellRender={(e) =>cellRender(e,'year0')}
            visible={false}
          />
          <Column
            dataField="year102"
            caption={`ТЕЗ санал`}
            format="#,##0.00"
            allowEditing={false}
            allowFiltering={false}
            //cellRender={(e) =>cellRender(e,'year0')}
            visible={false}
          />
          </Column>
          <Column caption={`${props.year}`}>
          <Column
            dataField="amount"
            format="#,##0.00"
            alignment="right"
            dataType="number"
            caption={`ТШЗ санал`}
            headerCellRender={renderAmountHeader}
          />
          <Column
            dataField="ttz_amount"
            dataType="number"
            format="#,##0.00"
            caption={`ТТЗ санал`}
            headerCellRender={renderTtzHeader}
            cellRender={(e) => cellRender(e, 'ttz')}
          />
          <Column
            dataField="tez_amount"
            dataType="number"
            format="#,##0.00"
            caption={`ТЕЗ санал`}
            headerCellRender={renderTezHeader}
            cellRender={(e) => cellRender(e, 'tez')}
          />
          </Column>
          <Column
            dataField="year3"
            caption={`${props.year} оны хязгаар`}
            format="#,##0.00"
            allowEditing={false}
            cellRender={(e) => cellRender(e, 'year3')}
            visible={sumYear3 === 0 ? false : true}
          />
          <Column
            dataField="year4"
            caption={`${props.year + 1} оны төсөөлөл`}
            format="#,##0.00"
            allowEditing={false}
            cellRender={(e) => cellRender(e, 'year4')}
            visible={sumYear4 === 0 ? false : true}
          />
          <Column
            dataField="year5"
            caption={`${props.year + 2} оны төсөөлөл`}
            format="#,##0.00"
            allowEditing={false}
            cellRender={(e) => cellRender(e, 'year5')}
            visible={sumYear5 === 0 ? false : true}
          />
          <Column caption="" type="buttons">
            <TreeButton icon="chart" text="Бүх жилээр төсвийн график харах" onClick={onChartDetail} />
          </Column>
          <Toolbar>
            <Item location="after">
              <ExpandCollapseButton />
              <FullscreenButton />
              <Button
                onClick={(e) => { ExportToFile('OG-'+ props.year + '-Төсвийн төсөл-ЭЗА-'+formatDate(new Date())) }}
                icon="xlsxfile"
                style={{ color: "#00ff00", marginLeft: 5 }}>
              </Button>
            </Item>
          </Toolbar>
          <Scrolling mode='virtual' />
        </TreeList>
      </div>
      {popupDetail && renderDetail()}
    </div>
  );

}

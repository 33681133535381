import React, { useContext, useEffect, useMemo } from "react";
import Header from "../components/Header";
import Sidebar from "../components/sidebar/Sidebar";
import Feedback from "../components/Feedback/Feedback";
import AssistDrawer from "../components/AssistDrawer/AssistDrawer";
import _ from "lodash";
import styled from "styled-components";
import { UserContext } from "../hooks/UserContext";
import "./mainLayout.css";
import AngleLeft from "../assets/imgs/angle-left.png";
import AngleRight from "../assets/imgs/angle-left-1.png";

const RouterView = styled.section`
  background-color: gray;
  padding: 5px;
`;

const MainLayout = (props) => {

  let { user } = useContext(UserContext);
  const { children } = props;
  const [toggle, setToggle] = React.useState(
    // user.username !=="02"? true: 
    true);
  const [result, setResult] = React.useState([]);


  function changeToggle() {
    setToggle(!toggle);
  }

  // create an event listener
  useEffect(() => {
    window.addEventListener("resize", resize)
  })

  const resize = () => {
    let currentHideNav = (window.innerWidth >= 1000);
    if (currentHideNav !== toggle) {
      changeToggle();
    }
  }

  useEffect(() => {
    if (!user) return;
    let tempresult = _(user.menu)
      .groupBy((x) => x.parent_name)
      .map((value, key) => ({
        parent_name: key,
        order_id: value[0].order_id,
        other: value,
      }))
      .value();
    let r = _.orderBy(tempresult, ['order_id', 'asc']);
    setResult(r);
  }, [user]);

  return (
    <React.Fragment>
      {user && result && result.length && (
        <div style={{ position: 'absolute', margin: 10 }}> <Sidebar
          changeToggle={changeToggle}
          result={result}
          toggle={toggle}
          setToggle={setToggle}
          menu={user.menu}
          // mouseOn={mouseOn}
          // setMouseOn={setMouseOn}
          path={props.location.pathname}
        /></div>

      )}
      {/*<SocketProvider>*/}
      <Header
        toggle={changeToggle}
        isToggle={toggle}
        socket={props.socket}
        readChat={props.readChat}
      />
      {user && user.first_name && !user.first_name.toLowerCase().includes("админ") && (
        <Feedback
          toggle={changeToggle}
          isToggle={toggle}
        />
      )}
      <div style={{
        position: 'fixed',
        border: '1px solid #DEDEDE',
        zIndex: 20,
        width: '30px',
        height: '30px',
        borderRadius: '15px',
        display: 'flex',
        top: '20px',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#fff',
        left: toggle ? '284px' : 10,
        cursor: 'pointer'
      }} onClick={changeToggle}>
        <img
          loading="lazy"
          src={toggle ? AngleLeft : AngleRight}
          style={{ width: "auto", height: "15px" }}
        />
      </div>
      {/*</SocketProvider>*/}
      <div style={{ width: "100%", position: "relative", zIndex: 1, display: 'flex', flex: 1, flexDirection: 'column' }}>
        <RouterView
          // width={'100%'}
          className="main-content"
          style={{ marginLeft: toggle ? 300 : 0, backgroundColor: "#FFFFFF", height: '100%' }}
        >
          <div>
            <AssistDrawer />
          </div>
          <>{children}</>
        </RouterView>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "auto",
          backgroundColor: "#fff",
          height: "3.2rem"
        }}
      >
        <span style={{ padding: 20, color: "#160B3D", backgroundColor: '#fff', fontSize: 14, fontFamily: 'Roboto' }}>
          DATALAND TECHNOLOGY LLC @{new Date().getFullYear()} v1.0  #1130             <span style={{ "paddingLeft": 40 }}>Утас: 75077171 Имэйл: info@dataland.mn</span>
        </span>
        {/* <span style={{ padding: 20, alignSelf: "flex-end" }}>
          Технологийн асуудлаар холбогдох утас: 75077171
        </span>*/}
      </div>
    </React.Fragment>
  );
};
export default MainLayout;

import React, {useState} from "react"
import DocumentManager from "./components/documentManager";

export default function Document() {
    return (
        <React.Fragment>
            <div>
                <DocumentManager/>
            </div>
        </React.Fragment>
    )
}
/*eslint-disable*/
import React, {useEffect, useRef} from "react";
import 'devextreme-react/text-area';
import {number} from "prop-types";
import ReactToPrint from "react-to-print";
import Button from "devextreme-react/button";

const Fa01 = (props) => {
    const data = props.reportData
    const componentRef = useRef();
    const docDate = data.docdate
    const orgName = data.organization_model.name
    const docno = data.docnum


    const style = {
        text: {
            color: "#2E4765", fontWeight: "700", fontSize: ".875rem"
        },
        box: {
            border: "none", backgroundColor: "#F6F6F6", borderRadius: ".5rem", padding: ".25rem .15rem", width: "100%"
        },
        box2: {
            border: "none",
            backgroundColor: "rgb(239, 239, 239)",
            borderRadius: ".5rem",
            padding: ".25rem .4rem",
            width: "100%"
        }
    }


    return (
        <div>

            <div className="row" style={{display: 'flex', marginTop: 30,}}>

                <Button type=""
                        icon='save'
                        className="saveButton" onClick={() => props.setReportShow(false)}> Буцах </Button>

                <ReactToPrint
                    trigger={() => <Button
                        type="default"
                        icon='save'
                        className="saveButton">Хэвлэх</Button>}
                    content={() =>
                        componentRef.current}
                />
            </div>




            <div ref={componentRef}
                 style={{display: "flex", justifyContent: "space-evenly", padding: "1.5rem", flexDirection: "column",}}>
                <div className="row" style={{display: 'flex', justifyContent: "space-between", alignItems: "center",}}>
                    <div style={{marginLeft: 10, marginRight: 10}}>
                        <div style={{display: 'flex', alignItems: 'left'}}>НХМаягт ҮХ-1</div>
                    </div>
                    {/*<div  style={{marginLeft: 20, marginRight: 10, alignment: "right"}}>*/}
                    {/*    <div style={{display: 'flex', alignment: 'Right',}}>Сангийн сайдын 2017 оны .... дугаар</div>*/}
                    {/*    <div style={{display: 'flex', alignment: 'Right'}}>сарын .. өдрийн .... тоот тушаалын хавсралт</div>*/}
                    {/*</div>*/}
                    <div style={{display: 'flex', fontSize: '40', alignItems: 'Left'}}>ҮНДСЭН ХӨРӨНГӨ
                        ХҮЛЭЭН АВАХ БАРИМТ №:
                    </div>
                    {/*ШИЛЖҮҮЛЭХ*/}
                    <div className='col-2' style={{marginLeft: 5,}}>
                        <div style={{display: 'flex', ...style.text}}>{docno}</div>
                    </div>

                </div>


                <div className="row" style={{display: 'flex', marginTop: "1rem"}}>
                    <div className='col-5'
                         style={{marginLeft: 5, marginRight: 10, display: "flex", justifyContent: "center"}}>
                        <div style={{display: 'flex', ...style.text}}>  {orgName}  </div>
                    </div>

                    <div className='col-5' style={{marginRight: 5, display: "flex", justifyContent: "space-evenly"}}>
                        <div style={{display: 'flex', fontSize: '20', alignItems: 'Right'}}>Огноо:</div>
                        <div style={{display: 'flex', ...style.text}}>{docDate}</div>
                    </div>
                </div>


                <div className="row" style={{display: 'flex', marginTop: 10,}}>
                    <div className='col-5'>
                        <div style={{display: 'flex', fontSize: '20', marginLeft: 10, alignItems: 'Right'}}>1. Цех,
                            тасаг,
                            хэсгийн нэр
                        </div>
                    </div>
                    <div className='col-4' style={{display: "flex", justifyContent: "center"}}>
                        <div style={{display: 'flex', ...style.text}}>{data.user_dept}</div>
                    </div>

                </div>

                <div className="row" style={{display: 'flex', marginTop: 10,}}>
                    <div className='col-5'>
                        <div style={{display: 'flex', fontSize: '20', marginLeft: 10, alignItems: 'Right'}}>2. Хөрөнгийн
                            нэр, төрөл, код
                        </div>
                    </div>
                    <div className='col-4' style={{display: "flex", justifyContent: "center"}}>
                        <div
                            style={{display: 'flex', ...style.text}}>{data.fa_name} ,{data.typename} ,{data.fatype} </div>
                    </div>

                </div>

                <div className="row" style={{display: 'flex', marginTop: 10,}}>
                    <div className='col-5'>
                        <div style={{display: 'flex', fontSize: '20', marginLeft: 10, alignItems: 'Right'}}>3.
                            Бүртгэлийн
                            дугаар
                        </div>
                    </div>
                    <div className='col-5' style={{display: "flex", justifyContent: "center"}}>
                        <div style={{display: 'flex', ...style.text}}>{data.fanumber}</div>
                    </div>

                </div>


                <div className="row" style={{display: 'flex', marginTop: 10,}}>
                    <div className='col-5'>
                        <div style={{display: 'flex', fontSize: '20', marginLeft: 10, alignItems: 'Right'}}> 4. Анхны
                            өртөг
                            (худалдан авсан үнэ)
                        </div>
                    </div>
                    <div className='col-5' style={{display: "flex", justifyContent: "center"}}>
                        <div style={{display: 'flex', ...style.text}}>{data.quantity * data.cost_org}</div>
                    </div>

                </div>


                <div className="row" style={{display: 'flex', marginTop: 10,}}>
                    <div className='col-10'>
                        <div style={{display: 'flex', fontSize: '20', marginLeft: 10, alignItems: 'Right'}}> 5. 20...
                            оны
                            ... сарын ... ны өдрийн ....... Тоот тушаал (гэрээ/шийдвэр) -ийг үндэслэн ...............
                        </div>

                    </div>


                </div>

                <div className="row" style={{display: 'flex', marginTop: 10,}}>
                    <div className='col-10'>
                        <div style={{display: 'flex', fontSize: '20', marginLeft: 10, alignItems: 'Right'}}> аас
                            ашиглалтанд
                            хүлээн авах (хүлээлгэн өгөх)
                            ...................................................................
                        </div>

                    </div>
                </div>
                <div className="row" style={{display: 'flex', marginTop: 10,}}>
                    <div className='col-3'>
                        <div style={{display: 'flex', fontSize: '20', marginLeft: 10, alignItems: 'Right'}}> үзлэг
                            шалгалт
                            хийв.
                        </div>
                    </div>
                </div>
                <div className="row" style={{display: 'flex', marginTop: 10,}}>
                    <div className='col-7'>
                        <div style={{display: 'flex', fontSize: '20', marginLeft: 10, alignItems: 'Right'}}> 6. Хүлээн
                            авах
                            (хүлээлгэн өгөх) үед үндсэн хөрөнгө
                        </div>
                    </div>
                    <div className='col-4' style={{display: "flex", justifyContent: "center"}}>
                        <div style={{display: 'flex', ...style.text}}>. . . . . . . . . . . . . . . . . . . . . .</div>
                    </div>
                </div>
                <div className="row" style={{display: 'flex', marginTop: 10,}}>
                    <div className='col-6'>
                        <div style={{
                            display: 'flex',
                            fontSize: '20',
                            marginLeft: 10,
                            alignItems: 'Right', ...style.text
                        }}>  {data.quantity} {data.unit_meas} байв.
                        </div>
                    </div>
                    <div className='col-5'>
                        <div style={{display: 'flex', fontSize: '20', marginLeft: 10, alignItems: 'Right'}}>(чанар
                            байдал,
                            тоог бичнэ)
                        </div>
                    </div>
                </div>

                <div className="row" style={{display: 'flex', marginTop: 10,}}>
                    <div className='col-3'>
                        <div style={{display: 'flex', fontSize: '20', marginLeft: 10, alignItems: 'Right'}}>7. Элэгдлийн
                            дүн
                        </div>
                    </div>
                    <div style={{
                        display: 'flex',
                        fontSize: '20',
                        marginLeft: 10,
                        alignItems: 'Right', ...style.text
                    }}>{data.acder_amt}    </div>

                    <div className='col-3'>
                        <div style={{display: 'flex', fontSize: '20', marginLeft: 10, alignItems: 'Right'}}> төгрөг,
                            үлдэгдэл өртөг
                        </div>
                    </div>

                    <div style={{
                        display: 'flex',
                        fontSize: '20',
                        marginLeft: 10,
                        alignItems: 'Right', ...style.text
                    }}>{data.quantity * data.cost_org - data.acder_amt}    </div>

                    <div className='col-3'>
                        <div style={{display: 'flex', fontSize: '20', marginLeft: 10, alignItems: 'Right'}}>төгрөг байв.
                        </div>
                    </div>
                </div>

                <div className="row" style={{display: 'flex', marginTop: 10,}}>

                    <div style={{display: 'flex', fontSize: '20', marginLeft: 10, alignItems: 'Right'}}>Үйлдвэрлэсэн
                        улс
                    </div>


                    <div style={{
                        display: 'flex',
                        fontSize: '20',
                        marginLeft: 10,
                        alignItems: 'Right', ...style.text
                    }}> {data.manuf}</div>


                    <div className='col-3'>
                        <div style={{display: 'flex', fontSize: '20', marginLeft: 10, alignItems: 'Right'}}>Ашиглалтад
                            орсон
                            огноо
                        </div>
                    </div>
                    <div className='col-3'>
                        <div style={{
                            display: 'flex',
                            fontSize: '20',
                            marginLeft: 10,
                            alignItems: 'Right', ...style.text
                        }}>{data.date_acq}</div>
                    </div>
                </div>

                <div className="row" style={{display: 'flex', marginTop: 10,}}>
                    <div className='col-3'>
                        <div style={{display: 'flex', fontSize: '20', marginLeft: 10, alignItems: 'Right'}}>Хөрөнгийн
                            хувийн
                            хэргийн дугаар
                        </div>
                    </div>
                    <div className='col-3'>
                        <div style={{
                            display: 'flex',
                            fontSize: '20',
                            marginLeft: 10,
                            alignItems: 'Right', ...style.text
                        }}>{data.fanumber} </div>
                    </div>


                    <div className='col-3'>
                        <div style={{display: 'flex', fontSize: '20', marginLeft: 10, alignItems: 'Right'}}>Элэгдэл
                            тооцсон
                            сүүлийн огноо
                        </div>
                    </div>
                    <div className='col-3'>
                        <div style={{display: 'flex', fontSize: '20', marginLeft: 10, alignItems: 'Right'}}></div>
                    </div>
                </div>


                <div className="row" style={{display: 'flex', marginTop: 0,}}>
                    <div className="row" style={{display: 'flex', marginTop: 0,}}>8. Хөрөнгийн шинж чанар, техникийн
                        үзүүлэлтийн тухай товч тодорхойлолт:

                    </div>


                </div>


                <div className="col-12" style={{
                    display: 'flex',
                    marginTop: 1,
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: 'center',
                    gap: 10
                }}>
                    <div style={{display: 'flex', ...style.text}}>
                        {data.descr}
                    </div>

                    <div style={{display: 'flex'}}> . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
                        . .
                        . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
                    </div>
                    <div style={{display: 'flex'}}> . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
                        . .
                        . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
                    </div>
                </div>
                <div className="row" style={{display: 'flex', marginTop: 0,}}>
                    <div className="row" style={{display: 'flex', marginTop: 0,}}>9. Комиссын эцсийн дүгнэлт,
                        шийдвэр: <div
                            className='col-10'>

                        </div>
                    </div>


                </div>


                <div className="col-12" style={{
                    display: 'flex',
                    marginTop: 5,
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: 'center',
                    gap: 10
                }}>
                    <div style={{display: 'flex'}}> . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
                        . .
                        . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
                    </div>
                    <div style={{display: 'flex'}}> . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
                        . .
                        . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
                    </div>
                    <div style={{display: 'flex'}}> . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
                        . .
                        . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
                    </div>

                    <div style={{display: 'flex'}}> . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
                        . .
                        . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
                    </div>
                    <div style={{display: 'flex'}}> . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
                        . .
                        . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
                    </div>

                </div>
                <div className="row" style={{display: 'flex', marginTop: 1,}}>
                    <div className="row" style={{display: 'flex', marginTop: 5,}}>10. Хавсралт (хөрөнгийн дагалдах
                        баримт
                        бичиг) <div className='col-10'>
                        </div>
                    </div>
                </div>


                <div className="col-12" style={{
                    display: 'flex',
                    marginTop: 0,
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: 'center',
                    gap: 10
                }}>
                    <div style={{display: 'flex'}}> . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
                        . .
                        . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
                    </div>
                    <div style={{display: 'flex'}}> . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
                        . .
                        . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
                    </div>
                    <div style={{display: 'flex'}}> . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
                        . .
                        . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
                    </div>


                    <div style={{display: 'flex'}}> . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
                        . .
                        . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
                    </div>

                </div>

                <div className="row" style={{display: 'flex', marginTop: 1,}}>
                    <div className="row" style={{display: 'flex', marginTop: 1,}}>Комиссын гишүүд: <div
                        className='col-10'>
                    </div>
                    </div>
                </div>
                <div className="row" style={{display: 'flex', marginTop: 10,}}>
                    <div className='col-2'>
                        <div style={{display: 'flex', fontSize: '20', marginLeft: 10, alignItems: 'Right'}}></div>
                    </div>
                    <div className='col-3'>
                        <div style={{
                            display: 'flex',
                            fontSize: '20',
                            marginLeft: 10,
                            alignItems: 'Right'
                        }}>----------------------
                        </div>
                    </div>
                    <div className='col-3'>
                        <div style={{
                            display: 'flex',
                            fontSize: '20',
                            marginLeft: 10,
                            alignItems: 'Right'
                        }}>----------------------
                        </div>
                    </div>
                    <div className='col-3'>
                        <div style={{
                            display: 'flex',
                            fontSize: '20',
                            marginLeft: 10,
                            alignItems: 'Right'
                        }}>----------------------
                        </div>
                    </div>
                </div>
                <div className="row" style={{display: 'flex', marginTop: -2,}}>
                    <div className='col-2'>
                        <div style={{display: 'flex', fontSize: '20', marginLeft: 10, alignItems: 'Right'}}></div>
                    </div>
                    <div className='col-3'>
                        <div style={{display: 'flex', fontSize: '20', marginLeft: 10, alignItems: 'Right'}}>( албан
                            тушаал )
                        </div>
                    </div>
                    <div className='col-3'>
                        <div style={{display: 'flex', fontSize: '20', marginLeft: 10, alignItems: 'Right'}}>( овог нэр
                            )
                        </div>
                    </div>
                    <div className='col-3'>
                        <div style={{display: 'flex', fontSize: '20', marginLeft: 10, alignItems: 'Right'}}>( гарын үсэг
                            )
                        </div>
                    </div>
                </div>
                <div className="row" style={{display: 'flex', marginTop: 10,}}>
                    <div className='col-2'>
                        <div style={{display: 'flex', fontSize: '20', marginLeft: 10, alignItems: 'Right'}}></div>
                    </div>
                    <div className='col-3'>
                        <div style={{
                            display: 'flex',
                            fontSize: '20',
                            marginLeft: 10,
                            alignItems: 'Right'
                        }}>----------------------
                        </div>
                    </div>
                    <div className='col-3'>
                        <div style={{
                            display: 'flex',
                            fontSize: '20',
                            marginLeft: 10,
                            alignItems: 'Right'
                        }}>----------------------
                        </div>
                    </div>
                    <div className='col-3'>
                        <div style={{
                            display: 'flex',
                            fontSize: '20',
                            marginLeft: 10,
                            alignItems: 'Right'
                        }}>----------------------
                        </div>
                    </div>
                </div>
                <div className="row" style={{display: 'flex', marginTop: 10,}}>
                    <div className='col-2'>
                        <div style={{display: 'flex', fontSize: '20', marginLeft: 10, alignItems: 'Right'}}></div>
                    </div>
                    <div className='col-3'>
                        <div style={{
                            display: 'flex',
                            fontSize: '20',
                            marginLeft: 10,
                            alignItems: 'Right'
                        }}>----------------------
                        </div>
                    </div>
                    <div className='col-3'>
                        <div style={{
                            display: 'flex',
                            fontSize: '20',
                            marginLeft: 10,
                            alignItems: 'Right'
                        }}>----------------------
                        </div>
                    </div>
                    <div className='col-3'>
                        <div style={{
                            display: 'flex',
                            fontSize: '20',
                            marginLeft: 10,
                            alignItems: 'Right'
                        }}>----------------------
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
        ;
};

export default Fa01
import React, {useState, useEffect} from "react";
import DataGrid, {
    Column,
    HeaderFilter,
    Paging,
    Summary,
    TotalItem,
    Selection,
    Export
} from "devextreme-react/data-grid";
import notify from "devextreme/ui/notify";
import FinanceReportService from "../../../../services/api/financeReport";
import {numberWithCommas} from "../../../../util";
import LoadPanelComponent from "../../../../components/LoadPanelComponent";
import {Workbook} from 'exceljs';
import saveAs from 'file-saver';
import {exportDataGrid} from "devextreme/excel_exporter";
import EmptyData from "../../../budget/components/empty";

const PayrollSendAndNotList = (props) => {
    const [dataSrc, setDataSrc] = useState([]);
    const [loader, setLoader] = useState(false);
    const [pageIndex, setPageIndex] = useState(0);
    const loadYamsData = async () => {
        try {
            setLoader(true);
            let r = await FinanceReportService.getPayrollSendAndNot(props.selectedYear, props.selectedMonth,props.selectedOrg,props.type,props.filterOrgText);
            setDataSrc(r);
            setLoader(false);
        } catch (e) {
            setLoader(false);
            notify(e.message, "error", 2000);
        }
    }
    useEffect(() => {
        loadYamsData();
    }, [props.selectedYear, props.selectedMonth,props.selectedOrg,props.type,props.filterOrgText])
    const onCellPreparedHandler = (e) => {
        if (e.rowType === 'header') {
            e.cellElement.wordWrapEnabled =true;
            e.cellElement.style.color = "#15186A"
            e.cellElement.style.backgroundColor= "#E3E7F1"
            //e.cellElement.style.backgroundImage = `linear-gradient(to right, ${palettePayrollReport[0]}, ${palettePayrollReport[1]})`
        }
        if (e.rowType === 'data') {
            e.cellElement.style.color = "#15186A"
        }
    }
    //Column custom renderings
    const statusRender = (data) => {
        if (data.displayValue && data.displayValue === "Илгээсэн") {
            return <div style={{color: '#2ECE73'}}>Илгээсэн</div>
        }
        else if (data.displayValue && data.displayValue === "Шинэчилсэн") {
            return <div style={{color: '#D4AF67'}}>Шинэчилсэн</div>
        }
        else {
            return <div style={{color: '#E83238'}}>Илгээгдээгүй</div>
        }
    }
    const handleOptionChange = (e) => {
        if (e.fullName === 'paging.pageIndex') {
            setPageIndex(e.value)
        }
    }
    const orderRender = (data) => {
        return <div style={{color: "#171010"}}>{pageIndex * 60 + data.rowIndex + 1}</div>;
    };
    const onExporting = (e) => {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Main sheet');
        exportDataGrid({
            component: e.component,
            worksheet,
            autoFilterEnabled: true,
            keepColumnWidths: true,
            topLeftCell: {row: 4, column: 2},
            customizeCell: ({gridCell, excelCell}) => {
                if (gridCell.rowType === 'header') {
                    excelCell.fill = {type: 'pattern', pattern: 'solid', fgColor: {argb: 'E3E7F1'}};
                    excelCell.border = {
                        top: {style: 'thin', color: {argb: '2c2c2c'}},
                        left: {style: 'thin', color: {argb: '2c2c2c'}},
                        bottom: {style: 'thin', color: {argb: '2c2c2c'}},
                        right: {style: 'thin', color: {argb: '2c2c2c'}}
                    }
                }
                if (gridCell.rowType === 'group') {
                    excelCell.fill = {type: 'pattern', pattern: 'solid', fgColor: {argb: '15186A'}};
                }
                if (gridCell.rowType === 'totalFooter' && excelCell.value) {
                    excelCell.font.italic = true;
                }
                if (gridCell.rowType === 'data') {
                    if (gridCell.column.caption === '№') {
                     /*   excelCell.value =gridCell.value;
                        //excelCell.numFmt = '##';
                        excelCell.alignment = { horizontal: 'center' };*/
                    }
                    excelCell.border = {
                        top: {style: 'thin', color: {argb: '2c2c2c'}},
                        left: {style: 'thin', color: {argb: '2c2c2c'}},
                        bottom: {style: 'thin', color: {argb: '2c2c2c'}},
                        right: {style: 'thin', color: {argb: '2c2c2c'}}
                    }
                }
            },
        }).then(() => {
            Object.assign(
                worksheet.getRow(2).getCell(3),
                { value: props.type === 1 ? props.selectedYear + ' оны ' +props.selectedMonth + '-р сарын илгээсэн байгууллагын жагсаалт' : props.selectedYear + ' оны ' +props.selectedMonth + '-р сарын илгээгээгүй байгууллагын жагсаалт', font: { bold: true, size: 16, underline: 'double' } }
            );
        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer], {type: 'application/octet-stream'}), props.selectedYear + '-' + props.selectedMonth + '-.xlsx');
            });
        });
        e.cancel = true;
    }
    return (
        <div id="load1">
            <LoadPanelComponent
                position={{of: '#load1'}}
                visible={loader}
            />
            {(dataSrc && !loader) ? (
            <DataGrid
                showBorders={true}
                rowAlternationEnabled={true}
                dataSource={dataSrc}
                noDataText = 'Дата байхгүй байна.'
                onCellPrepared={onCellPreparedHandler}
                wordWrapEnabled={true}
                onOptionChanged={handleOptionChange}
                onExporting={onExporting}
                style={{ fontFamily: "'Montserrat', sans-serif", fontWeight: 'bold' }}
            >
                <Paging defaultPageSize={60}/>
                <HeaderFilter visible={true}/>
                <Selection mode="single"/>
                <Column
                    caption="№"
                    width={40} alignment="center"
                    cellRender={orderRender}
                />
                <Column dataField="organization_name" allowSorting={false} caption="Байгууллага" allowFiltering={true}/>
                <Column dataField="total_salary" format="#,##0.00" alignment="center" caption="Нийт цалин" allowFiltering={false} visible={props.type === 1 ? true : false}/>
                <Column dataField="basic_salary" format="#,##0.00" alignment="center" caption="Үндсэн цалин" allowFiltering={false} visible={props.type === 1 ? true : false}/>
                <Column dataField="estimated_salary" format="#,##0.00" alignment="center" caption="Бодогдсон цалин" allowFiltering={false} visible={props.type === 1 ? true : false}/>
                <Column dataField="additional_salary" format="#,##0.00" alignment="center" caption="Нэмэгдэл цалин" allowFiltering={false} visible={props.type === 1 ? true : false}/>
                <Column dataField="user_name" allowSorting={false} caption="Хэрэглэгч" alignment="center" allowFiltering={false} visible={props.type === 1 ? true : false}/>
                <Column dataField="position_name" allowSorting={false} caption="Албан тушаал" alignment="center" allowFiltering={false} visible={props.type === 1 ? true : false}/>
                <Column dataField="status_name" allowSorting={false} caption="Төлөв" alignment="center" allowFiltering={true} cellRender={statusRender}/>
                <Column  dataField="ognoo" caption="Огноо" allowSorting={false} alignment="center" allowFiltering={false} visible={props.type === 1 ? true : false}/>
                <Summary>
                    <TotalItem
                        column="total_salary"
                        summaryType="sum"
                        customizeText={(data) => {return `${numberWithCommas(data.value)}`}}
                    />
                    <TotalItem
                        column="basic_salary"
                        summaryType="sum"
                        customizeText={(data) => {return `${numberWithCommas(data.value)}`}}
                    />
                    <TotalItem
                        column="estimated_salary"
                        summaryType="sum"
                        customizeText={(data) => {return `${numberWithCommas(data.value)}`}}
                    />
                    <TotalItem
                        column="additional_salary"
                        summaryType="sum"
                        customizeText={(data) => {return `${numberWithCommas(data.value)}`}}
                    />
                </Summary>
                <Export enabled={true} allowExportSelectedData={false}/>
            </DataGrid>
                ):
                <div style={{display: "flex", justifyContent: "center", alignItems: "center", family: "'Montserrat', sans-serif" }}>
                    <EmptyData/>
                </div>
            }
        </div>
    )
}

export default PayrollSendAndNotList;

import React, {useState, useEffect, useRef} from "react"
import notify from "devextreme/ui/notify"
import BudgetService from "../../../../services/api/budget"
import {PieChart} from "devextreme-react"
import {Export} from "devextreme-react/pivot-grid"
import {numberWithCommas} from "../../../../util"
import LoadPanelComponent from "../../../budget/components/loadPanel"
import _ from "lodash"
import {Tooltip} from "devextreme-react/pie-chart"
import {Legend, Series} from "devextreme-react/chart"
import {Label} from "devextreme-react/data-grid"
import EmptyData from "../../../budget/components/empty"

function TowloruulehEdiinZasagPieChart(props) {
  const [loader, setLoader] = useState(true)
  const [data, setData] = useState()

  const loadPivotData = async () => {
    try {
      setLoader(true)
      let r = await BudgetService.getTowloruulehPivotData(
        props.year,
        props.chosenValue,
        props.chosenMinistry
      )
      let pp = _(r)
        .groupBy("income_type_name")
        .map((row, key) => {
          return {
            name: key,
            amount: _.sumBy(row, (item) => Number(item.tuluvluguu)),
          }
        })
        .value()
      setData(pp)
      props.checkData(pp && pp.length > 0 ? true : false)
    } catch (e) {
      notify(e.message, "error", 2000)
    } finally {
      setLoader(false)
    }
  }
  useEffect(() => {
    loadPivotData()
  }, [props.year, props.chosenValue, props.chosenMinistry, props.checkData])

  const cstmText = (e) => {
    return `<span">${numberWithCommas(e.value)} ₮<br/>${(
      e.percent * 100
    ).toFixed(2)}%</span>`
  }

  const customizeTooltip = (arg) => {
    return {
      text: `${arg.argument} - ${numberWithCommas(arg.value * 1)} ₮ : ${(
        arg.percent * 100
      ).toFixed(2)}%`,
    }
  }

  if (data?.length === 0 && !loader) {
    return <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        family: "Segoe UI",
      }}
    >
      <EmptyData/>
    </div>
  }
  return (
    <div id="load11" className="towloruulehOnlyPivotGrid">
      <LoadPanelComponent position={{of: "#load11"}} visible={loader} />
      <PieChart
        type="doughnut"
        dataSource={data}
        resolveLabelOverlapping="hide"
        palette="Soft Pastel"
      >
        <Series argumentField="name" valueField="amount">
          <Label
            position="inside"
            visible={true}
            customizeText={cstmText}
            backgroundColor="none"
          ></Label>
        </Series>
        <Export enabled={true} />
        <Legend
          visible={false}
          horizontalAlignment="center"
          verticalAlignment="bottom"
        />
        <Tooltip
          zIndex={10000}
          enabled={true}
          customizeTooltip={customizeTooltip}
          font={{family: "Segoe UI", color: "black"}}
        />
      </PieChart>
    </div>
  )
}

export default TowloruulehEdiinZasagPieChart

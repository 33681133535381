import React, {useState, useEffect} from "react";
import notify from "devextreme/ui/notify";
import OrganizationService from "../services/api/organization";
import TreeList, {Column, Editing} from "devextreme-react/tree-list";

const TestOrgDetailPage = (props) => {
    const [dataSource, setDataSource] = useState([]);

    const loadData = async () => {
        try {
            let r = await OrganizationService.getTestOrgDetail();
            setDataSource(r);
        } catch (e) {
            notify(e.message, 'error', 2000);
        }
    }

    useEffect(() => {
        loadData();
    }, [])

    const customRender = (e) => {
        return <div dangerouslySetInnerHTML={{ __html: e.value }}></div>
    }

    return (
        <div>
            <TreeList
                rowAlternationEnabled={true}
                dataSource={dataSource}
                keyExpr="id"
                parentIdExpr="parent_id"
                showColumnHeaders={false}
                wordWrapEnabled={true}
            >
                <Column
                    cellRender={customRender}
                    dataField="value"
                />
                <Column
                    alignment="right"
                    dataField="statistics_field"
                />
            </TreeList>
        </div>
    )
}

export default TestOrgDetailPage;

import React, {useState} from "react"
import lawIcon from "../../assets/imgs/lawIcon2.png"
import './ogtreestyle.scss';
import {Popover} from "devextreme-react";
import GeneralInfoService from "../../services/api/generalInfo";

const buildTree = (id, nodes) => {
    const node = nodes.find((n) => n.id === id);
    return {
        id: node.id,
        ...node,
        children: nodes.filter((n) => n.parent_id === id).map((n) => buildTree(n.id, nodes)),
    }
}

export const createTreeStructure = (nodes) => {
    return nodes.filter((n) => n.parent_id === null).map((n) => buildTree(n.id, nodes));
}

const TreeNode = ({ label, childrens, connectedData, clickHandler, loadHandler }) => {
    const [target, setTarget] = useState('');
    const [visible, setVisible] = useState(false);
    const [bData, setBData] = useState(null);
    const actionHandler = (lawData) => {
        if (typeof clickHandler === 'function') {
            clickHandler(lawData);
        }
    }

    const loadBudgetInfo = async (budgetData) => {
        loadHandler(true)
        let r = await GeneralInfoService.fetchAdditionalData(budgetData);
        setBData(r);
        setTarget(`#budgetInfo${budgetData.table_id}`);
        setVisible(true);
        loadHandler(false)
    }

    const lawData = connectedData.find(x => x.table_name === 'law');
    const budgetData = connectedData.find(x => x.table_name === 'budget');
    const [expanded, setExpanded] = useState(false)
    const toggle = () => {
        setExpanded(!expanded);
    }
    return (
        <li className="listContainer">
            <div className="itemContainer">
                <div className="labelStyle">{label}</div>
                {lawData && <div className="lawLinkContainer">
                    <img alt="lawicon" src={lawIcon} onClick={() => actionHandler(lawData)} className="dx-icon-product collapseImgClass"></img>
                </div>}
                {budgetData && <div className="budgetStyle">
                    <span id={`budgetInfo${budgetData.table_id}`}>Төсөв: <a style={{ cursor: 'pointer' }} onClick={() => loadBudgetInfo(budgetData)}>******</a></span>
                </div>}
                {childrens && childrens.length !== 0 && <div className="toggleContainer" onClick={toggle}>
                    {!expanded ? <i className="dx-icon-expand collapseImgClass"></i> : <i className="dx-icon-collapse collapseImgClass"></i>}
                </div>}
            </div>
            {expanded && (
                <ul className="customListUlChild">
                    {childrens.map(child => (
                        <TreeNode key={child.id} label={child.val} childrens={child.children} connectedData={child.general_information_multi_factors} clickHandler={clickHandler} loadHandler={loadHandler}/>
                    ))}
                </ul>
            )}
            <Popover target={target} visible={visible} onHiding={() => {setVisible(false)}}>
                <div style={{ padding: '15px' }}>
                    {bData && <span>{bData.description} : {bData.debit}</span>}
                </div>
            </Popover>
        </li>
    );
};

const OgTree = ({ data, additionalButtonClickHandler, loadHandler }) => {
    return (
        <ul className="customListUlParent">
            {data.map(item => {
                return <TreeNode key={item.id}
                                 label={item.val}
                                 childrens={item.children}
                                 connectedData={item.general_information_multi_factors}
                                 clickHandler={additionalButtonClickHandler}
                                 loadHandler={loadHandler}
                />
            })}
        </ul>
    );
};

export default OgTree;

import React, { useState, useEffect } from "react";
import notify from "devextreme/ui/notify";
import _ from "lodash";
import Chart, { CommonSeriesSettings, Crosshair, Label, Legend, Tooltip, Series, ValueAxis, ArgumentAxis, Point, Font, Strip } from "devextreme-react/chart";
// import { Tooltip as ShowTooltip } from 'antd'
import BudgetService from "../../../services/api/budget";
import { budgetPalettes, numberWithCommas } from "../../../util";
import LoadPanelComponent from "../../budget/components/loadPanel";
// import { BarGauge } from 'devextreme-react/bar-gauge';
// import info1 from "../../../assets/imgs/icon/iconInfo1.png";
import { FaArrowUp, FaArrowDown } from "react-icons/fa6";

const PerformanceByMonthsExpense = (props) => {
    const [chartData, setChartData] = useState([]);
    const [budget, setBudget] = useState({})
    const [loader, setLoader] = useState(false);

    const loadData = async () => {
        try {
            setLoader(true);
            let r = await BudgetService.getExpenseZadgaiMonth(props.year,props.orgId);
            let s = await BudgetService.getExpenseMiniData(props.year, props.filterOrgText, props.chosenMinistry ? props.chosenMinistry : "ALL", props.orgId);
            let rr = r.map(item=> {
                return {
                    batlagdsan: item.batlagdsan,
                    guitsetgel: item.guitsetgel,
                    percent: ((item.guitsetgel * 100) / item.batlagdsan) - 100,
                    budget_month: item.budget_month
                }
            })
            let ss = {
                batlagdsan: s[0].batlagdsan / 1000000,
                batlagdsan_date: s[0].batlagdsan_date,
                budget_year: s[0].budget_year,
                guitsetgel: s[0].guitsetgel / 1000000,
                guitsetgel_date: s[0].guitsetgel_date,
                todotgoson: s[0].todotgoson / 1000000,
                todotgoson2: s[0].todotgoson2 / 1000000,
                todotgoson2_date: s[0].todotgoson2_date,
                todotgoson_date: s[0].todotgoson_date
            }
            setChartData(rr);
            setBudget(ss)
            setLoader(false);
        } catch (e) {
            setLoader(false);
            console.log("err:");
        }
    }

    const quarterlyPerformanceData = []
    const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    for (let i = 0; i < chartData.length; i += 3) {
        const quarterMonths = months.slice(i, i + 3);
        const quarterSum = chartData.filter(data => quarterMonths.includes(data.budget_month))
            .reduce((acc, data) => acc + data.guitsetgel, 0);
        quarterlyPerformanceData.push(quarterSum)
    }

    const quarterlyBudgetData = []
    for (let i = 0; i < chartData.length; i += 3) {
        const quarterMonths = months.slice(i, i + 3);
        const quarterSum = chartData.filter(data => quarterMonths.includes(data.budget_month))
            .reduce((acc, data) => acc + data.batlagdsan, 0);
        quarterlyBudgetData.push(quarterSum)
    }

    const percentage = []
    for (let i = 0; i < quarterlyBudgetData.length; i++) {
        const denominator = quarterlyBudgetData[i] !== 0 ? quarterlyBudgetData[i] : 1;
        percentage.push(((100 * quarterlyPerformanceData[i]) / denominator))
    }

    const quarterData = quarterlyBudgetData.map((budget, index) => ({
        quarter: index + 1,
        budget: (budget / 1000000),
        performance: (quarterlyPerformanceData[index] / 1000000),
        percentage: (percentage[index]).toFixed(2),
    }));

    //---------------------------------------------------------------------------------------------------//

    const halfYearPerformance = []
    for (let i = 0; i < chartData.length; i += 6) {
        const quarterMonths = months.slice(i, i + 6);
        const quarterSum = chartData.filter(data => quarterMonths.includes(data.budget_month))
            .reduce((acc, data) => acc + data.guitsetgel, 0);
        halfYearPerformance.push(quarterSum)
    }

    const halfYearBudget = []
    for (let i = 0; i < chartData.length; i += 6) {
        const quarterMonths = months.slice(i, i + 6);
        const quarterSum = chartData.filter(data => quarterMonths.includes(data.budget_month))
            .reduce((acc, data) => acc + data.batlagdsan, 0);
        halfYearBudget.push(quarterSum)
    }

    const percentage1 = []
    for (let i = 0; i < halfYearBudget.length; i++) {
        const denominator = halfYearBudget[i] !== 0 ? halfYearBudget[i] : 1;
        percentage1.push(((100 * halfYearPerformance[i]) / denominator))
    }
    const halfYearData = halfYearBudget.map((budget, index) => ({
        budget: (budget / 1000000),
        performance: (halfYearPerformance[index] / 1000000),
        percentage: (percentage1[index]).toFixed(2),
    }));

    let percent;
    let name;
    let date;
    let budget1;
    if (budget && budget.todotgoson2 !== 0) {
        name = 'Төсвийн тодотгол 2'
        budget1 = budget.todotgoson2
        date = budget.todotgoson2_date
        percent = ((100 * budget.guitsetgel) / budget.todotgoson2).toFixed(2)
    } else if (budget && budget.todotgoson !== 0) {
        name = 'Төсвийн тодотгол'
        budget1 = budget.todotgoson
        date = budget.todotgoson_date
        percent = ((100 * budget.guitsetgel) / budget.todotgoson).toFixed(2)
    } else if (budget && budget.batlagdsan !== 0) {
        name = 'Батлагдсан төсөв'
        budget1 = budget.batlagdsan
        date = budget.batlagdsan_date
        percent = ((100 * budget.guitsetgel) / budget.batlagdsan).toFixed(2)
    }

    useEffect(() => {
        loadData()
    }, [props.year, props.orgId])

    const customizeTooltip = (arg) => {
        if (arg.seriesName === 'Төсвийн өөрчлөлт' || arg.seriesName === 'Төсөв') {
            let r = chartData[(arg.argument * 1 - 1) - 1];
            let q = ((arg.value - (r && r.batlagdsan) > 0 ? arg.value / (r && r.batlagdsan) : (r && r.batlagdsan) / arg.value)).toFixed(2);
            let p = ((arg.value * 100 / (r && r.batlagdsan)) - 100).toFixed(2);
            let d = (arg.value - (r && r.batlagdsan));
            let f = d < 0 ? 'бага' : 'их';
            if (isFinite(q) && !isNaN(q)) {
                return {
                    html: `<div class="tooltip-header">
                    <span>Төсөв ${arg.argumentText}-р сар:</span>
                    <span>${numberWithCommas(arg.value / 1000000)}</span>
                    </div>
                    <hr/>
                    <b><i>Өмнөх сараас</i></b><br/>
                    <span class="tooltip-series-name">Зөрүү: </span> ${numberWithCommas(d / 1000000)}<br/>
                    <span class="tooltip-series-name">Хувь: </span> ${p}%<br/>
                    <span class="tooltip-series-name">Өөрчлөлт: </span> ${q} дахин ${f}<br/>
                    `
                }
            } else {
                return {
                    html: `<div class="tooltip-header">
                    <span>Төсөв ${arg.argumentText}-р сар:</span>
                    <span>${numberWithCommas(arg.value / 1000000)}</span>
                    </div>`
                }
            }
        }
        else if (arg.seriesName === 'Гүйцэтгэлийн өөрчлөлт') {
            let r = chartData[(arg.argument * 1 - 1) - 1];
            let q = ((arg.value - (r && r.guitsetgel) > 0 ? arg.value / (r && r.guitsetgel) : (r && r.guitsetgel) / arg.value)).toFixed(2);
            let p = ((arg.value * 100 / (r && r.guitsetgel)) - 100).toFixed(2);
            let d = (arg.value - (r && r.guitsetgel));
            let f = d < 0 ? 'бага' : 'их';
            let r1 = chartData[(arg.argument * 1) - 1];
            let u = ((r1 && r1.batlagdsan) - arg.value);
            let u1 = ((arg.value * 100 / (r1 && r1.batlagdsan))).toFixed(2);
            if (isFinite(q) && !isNaN(q)) {
                return {
                    html: `<div class="tooltip-header">
                    <span>Гүйцэтгэл ${arg.argumentText}-р сар:</span>
                    <span>${numberWithCommas(arg.value / 1000000)}</span>
                    </div>
                    <hr/>
                    <b><i>Төсвөөс</i></b><br/>
                    <span class="tooltip-series-name">Зөрүү: </span>${numberWithCommas(u / 1000000)} <br/>
                    <span class="tooltip-series-name" style={{marginBottom: 10}}>Xувь: </span>${u1}% <br/>
                    <hr/>
                    <b><i>Өмнөх сараас </b></i><br/>
                    <span class="tooltip-series-name">Зөрүү: </span>${numberWithCommas(d / 1000000)} <br/>
                    <span class="tooltip-series-name">Хувь: </span>${p}% <br/>
                    <span class="tooltip-series-name">Өөрчлөлт: </span>${q} дахин ${f}<br/>`
                }
            } else {
                return {
                    html: `<div class="tooltip-header">
                    <span>Гүйцэтгэл ${arg.argumentText}-р сар:</span>
                    <span>${numberWithCommas(arg.value / 1000000)}</span>
                    </div>
                    <hr/>
                    <b><i>Төсвөөс</i></b><br/>
                    <span class="tooltip-series-name">Зөрүү: </span>${numberWithCommas(u / 1000000)} <br/>
                    <span class="tooltip-series-name" style={{marginBottom: 10}}>Xувь: </span>${u1}% <br/>`
                }
            }
        }
        else {
            return {
                html: `<div class="tooltip-header">
                    <span>${arg.seriesName} ${arg.argumentText}-р сар:</span>
                    <span>${numberWithCommas(arg.value / 1000000)}</span>
                    </div>
                    <hr/>
                    <b><i>Төсвөөс</i></b><br/>
                    <span class="tooltip-series-name">Зөрүү:</span> ${numberWithCommas((arg.point.data.batlagdsan - arg.value) / 1000000)}<br/>
                    <span class="tooltip-series-name">Хувь: </span> ${((arg.value / 1000000) / (arg.point.data.batlagdsan / 1000000) * 100).toFixed(2)}% 
                `
            };
        }
    }

    const customizeValueAxisLabel = (e) => {
        return `${(e.value / 1000000000).toLocaleString(undefined)}`
    }

    const onLegendClick = ({ target: series }) => {
        if (series.isVisible()) {
            series.hide()
        } else {
            series.show()
        }
    }
    function markerTemplate(item) {
        const color = item.series.isVisible() ? item.marker.fill : '#888';
        return (
            <svg>
                <rect x={0} y={0} width={12} height={12} fill={color}
                    rx={item.text.includes('өөрчлөлт') ? 6 : 0}
                    ry={item.text.includes('өөрчлөлт') ? 6 : 0}></rect>
            </svg>
        );
    }
    return (
        <div id="load11" >
            <LoadPanelComponent position={{ of: '#load11' }} visible={loader} />
            <div className="d-flex responsivePerf" style={{ justifyContent: 'space-between' }}>
                <div className="performanceNews" style={{ position: 'relative', boxSizing: 'content-box', padding: '10px', margin: '5px', border: '1px solid #DEDEDE', borderRadius: '15px', boxShadow: '0 5px 5px rgba(0, 0, 0, 0.01)', width: '60%' }}>
                    <h5 style={{ fontSize: 14, marginBottom: '1rem' }}>1. ГҮЙЦЭТГЭЛИЙН МЭДЭЭ /сараар, сая ₮/</h5>
                    {chartData && chartData.length > 0 ?
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', gap: '30px' }}>
                            <Chart
                                palette={_.find(budgetPalettes, { 'id': 1 }).color}
                                paletteExtensionMode="alternate"
                                dataSource={chartData}
                                height={'100%'}
                                onLegendClick={onLegendClick}
                            >
                                <CommonSeriesSettings
                                    argumentField="budget_month"
                                    type="bar"
                                    barOverlapGroup="myStack"
                                    barWidth={15}
                                    cornerRadius={5}
                                >
                                    <Label visible={false} />
                                </CommonSeriesSettings>
                                <Crosshair horizontalLine={false} enabled={true} label={true}>
                                    <Label visible={true} backgroundColor="#555">
                                        <Font color="#fff" />
                                    </Label>
                                </Crosshair>
                                <ValueAxis>
                                    <Label customizeText={customizeValueAxisLabel} />
                                </ValueAxis>
                                <ArgumentAxis tickInterval={1}>
                                    <Strip startValue={0} endValue={3} color="#f5f5f5" />
                                    <Strip startValue={6} endValue={9} color="#f5f5f5" />
                                    <Strip startValue={12} color="#f5f5f5" />
                                    <Label format="fixedPoint" />
                                </ArgumentAxis>
                                <Series barWidth={25} stack="myStack" valueField="batlagdsan" name="Төсөв" />
                                <Series stack="myStack" valueField="guitsetgel" name="Гүйцэтгэл" />
                                <Series valueField="batlagdsan" name="Төсвийн өөрчлөлт" color="#b83535" type="spline" dashStyle="solid" visible={false}>
                                    <Point size={10} />
                                </Series>
                                <Series valueField="guitsetgel" name="Гүйцэтгэлийн өөрчлөлт" color="#003696" type="spline" dashStyle="solid">
                                    <Point size={10} />
                                </Series>
                                <Legend
                                    visible={true}
                                    verticalAlignment="bottom"
                                    horizontalAlignment="center"
                                    // margin={{ top: 100, left: 30 }}
                                    font={{ family: "Segoe UI" }}
                                    markerRender={markerTemplate}
                                />
                                <Tooltip zIndex={100000} enabled={true} cornerRadius={6} border={{ visible: false }}
                                    customizeTooltip={customizeTooltip} font={{ color: '#fff' }} color="#555" location="edge">
                                </Tooltip>
                            </Chart>
                        </div>
                        :
                        <div style={{ paddingLeft: '1rem', paddingBottom: '1.75rem' }}>Мэдээлэл байхгүй байна</div>
                    }
                </div>
                <div className="performanceReport" style={{ position: 'relative', display: 'flex', flexDirection: 'column', justifyContent: chartData && chartData.length <= 0 ? 'space-between' : '', padding: '10px', margin: '5px', border: '1px solid #DEDEDE', borderRadius: '15px', boxShadow: '0 5px 5px rgba(0, 0, 0, 0.01)', width: '40%' }}>
                    <h5 style={{ fontSize: 14, marginBottom: '1rem', height: '5%' }}>2. ГҮЙЦЭТГЭЛИЙН ТАЙЛАН /улирал, хагас жил, бүтэн жилээр, сая ₮/</h5>
                    {chartData && chartData.length > 0 ?
                        <div className="report" style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column', gap: 10, height: '95%' }}>
                            <div className="barGauge" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                                <div style={{ border: '1px solid #DEDEDE', borderRadius: '15px', padding: '20px 10px 20px 20px', width: '100%', fontSize: '1rem' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', gap: 10 }}>
                                        <span className="budget" style={{ fontFamily: 'Segoe UI', fontSize: 14 }}>Жил</span>
                                        <span className="budget" style={{ fontWeight: 'bold', fontSize: 18 }}>{budget1 ? numberWithCommas(budget1) : 0}</span>
                                        <div className="row" style={{ alignItems: 'center', fontSize: 14 }}>
                                            <span className="budget" style={{ fontFamily: 'Segoe UI' }}>Гүйцэтгэл: <span style={{ margin: 0, padding: '0 .5rem 0 0', fontWeight: 'bold', color: '#4c5286' }}>{budget && budget.guitsetgel ? numberWithCommas(budget?.guitsetgel) : 0}</span></span>
                                            <span className="budget" style={{ color: percent >= 100 ? '#34B771' : 'black', fontWeight: 'bold' }}>({percent ? numberWithCommas(percent) : 0}%)</span>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', gap: '10px' }}>
                                {halfYearData.map((e, i) => {
                                    return (
                                        <div key={i} style={{ border: i === 1 ? '2px solid #fdecda' : '2px solid #ebf1ff', borderRadius: '15px', padding: '20px 10px 20px 20px', width: 'calc(100%/2 - 5px)', fontSize: '1rem', display: 'flex', flexDirection: 'column' }}>
                                            <span className="budget" style={{ fontFamily: 'Segoe UI', fontSize: 14 }}>{i + 1}-р хагас жил</span>
                                            <span className="budget" style={{ fontWeight: 'bold', fontSize: 18 }}>{numberWithCommas(e.budget)}</span>
                                            <div className="row" style={{ alignItems: 'center', gap: 5, fontSize: 14 }}>
                                                <span >Гүйцэтгэл:</span>
                                                <span style={{ margin: 0, padding: '.5rem 0', fontWeight: 'bold', color: '#4c5286' }}>{numberWithCommas(e.performance)}</span>
                                                <span className="budget" style={{ color: e.percentage >= 100 ? '#34B771' : 'black', fontWeight: 'bold' }}>({e.percentage}%) </span>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                            <div className="quarter" style={{ width: '100%' }}>
                                <div className="quarters" style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', height: '100%' }}>
                                    {quarterData.map((e, i) => {
                                        return (
                                            <div key={i + 1} style={{ border: i === 1 || i === 0 ? '2px solid #ebf1ff' : '2px solid #fdecda', borderRadius: '15px', padding: '20px 10px 20px 20px', width: 'calc(100%/4 - 7px)', display: 'flex', flexDirection: 'column' }}>
                                                <span className="budget" style={{ fontSize: '0.875rem', fontFamily: 'Segoe UI' }}>{e?.quarter}-р улирал</span>
                                                <span style={{ fontSize: 18, fontWeight: 'bold' }}>{e.budget > 0 ? numberWithCommas(e.budget) : 0}</span>
                                                <span className="budget" style={{ fontSize: '1rem', fontWeight: 'bold', color: '#4c5286' }}>{numberWithCommas(e.performance)}</span>
                                                <span className="budget" style={{ color: e.percentage >= 100 ? '#34B771' : 'black' }}>({e.percentage}%)</span>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div> :
                        <div style={{ paddingLeft: '1rem', paddingBottom: '1.75rem' }}>Мэдээлэл байхгүй байна</div>
                    }
                </div>
            </div>
        </div>
    )
}
export default PerformanceByMonthsExpense;

import React, {useState, useEffect, useContext} from "react";
import notify from "devextreme/ui/notify";
import BudgetService from "../../../../services/api/budget";
import LoadPanelComponent from "../../../budget/components/loadPanel";
import OrganizationService from "../../../../services/api/organization";
import { UserContext } from "../../../../hooks/UserContext";
import { Tooltip } from "devextreme-react/tooltip";
import info1 from '../../../../assets/imgs/icon/iconInfo1.png'

const BudgetOtherOne = (props) => {
    const [horizontalData, setHorizontalData] = useState({});
    const [orgBudgetWorker, setOrgBudgetWorker] = useState([])
    const [loader, setLoader] = useState(false);
    const [withAnimationVisibleDate, setWithAnimationVisibleDate] = useState(false);
    const [withAnimationVisibleDate2, setWithAnimationVisibleDate2] = useState(false);
    const [withAnimationVisibleDate3, setWithAnimationVisibleDate3] = useState(false);
    const {user} = useContext(UserContext)
    const {year, chosenValue} = props;

    const loadData = async () => {
        try {
            setLoader(true);
            let r = await BudgetService.getBudgetOtherOne(year, chosenValue);
            if (r && r.length !== 0) {
                const batlagdsan = parseInt(r[0].batlagdsan, 10) || 0;
                const todotgoson = parseInt(r[0].todotgoson, 10) || 0;
                const todotgoson2 = parseInt(r[0].todotgoson2, 10) || 0;
                setHorizontalData({
                    batlagdsan,
                    todotgoson,
                    todotgoson2,
                    ...r[0]
                });
            } else {
                setHorizontalData({})
            }
            let w = await OrganizationService.getOrgBudgetByWorker(year, chosenValue);
            setOrgBudgetWorker(w.data[0]);
            setLoader(false);
        } catch (e) {
            notify(e.message, "error", 2000);
        }
    }
    useEffect(() => {
        loadData();
    }, [year, chosenValue])

    return (
        <div className="row" style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly',flex:1}}>
            <LoadPanelComponent position={{of: '#load1'}} visible={loader}/>
            {!loader &&
                <> 
                    {(horizontalData && horizontalData.batlagdsan_date !== null) && <Tooltip
                        target="#dateBudget1z"
                        position="bottom"
                        visible={withAnimationVisibleDate}
                        hideOnOutsideClick={true}
                    >
                        <div style={{ padding: 10, width: orgBudgetWorker === undefined || orgBudgetWorker.batlagdsan_approved_worker_date === null ? '16rem' : '23rem', fontFamily: 'Segoe UI', backgroundColor: '#555', color: '#fff', borderBottom: '1px solid #fff', borderRadius: 10 }}>
                            <div style={{ display: 'flex', gap: 5, justifyContent: 'center' }}>
                            <b style={{ margin: 0, width: '40%', textAlign: 'end' }}>Шинэчлэгдсэн:</b>
                                <div style={{ display: 'flex', width: '60%' }}><span className="tooltip-series-name">Огноо:&nbsp;</span><label
                                            style={{ fontWeight: 'Bold' }}>{horizontalData.batlagdsan_date}</label></div>
                            </div>
                            <hr/>
                            {orgBudgetWorker && orgBudgetWorker?.batlagdsan_worker_name !==null &&
                            <div style={{ display: 'flex', flexDirection: 'row', gap: 5, justifyContent: 'center' }}>
                                <div style={{ fontWeight: 'Bold', width: '40%', textAlign: 'end' }}>Баталгаажуулсан:</div>
                                <div style={{ width: '60%' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <div style={{ display: 'flex' }}><span className="tooltip-series-name">Овог нэр:&nbsp;</span><label
                                            style={{ fontWeight: 'Bold' }}>{orgBudgetWorker?.batlagdsan_worker_name ? orgBudgetWorker?.batlagdsan_worker_name : '-'}</label></div>
                                    </div>
                                    <div style={{ display: 'flex' }}><span className="tooltip-series-name">Албан тушаал:&nbsp;</span><label
                                        style={{ fontWeight: 'Bold' }}>{orgBudgetWorker?.batlagdsan_position_name ? orgBudgetWorker?.batlagdsan_position_name : '-'}</label>
                                    </div>
                                    <div style={{ display: 'flex' }}><span className="tooltip-series-name">Огноо:&nbsp;</span><label
                                        style={{ fontWeight: 'Bold' }}>{orgBudgetWorker?.batlagdsan_approved_worker_date ? orgBudgetWorker?.batlagdsan_approved_worker_date : '-'}</label>
                                    </div>
                                </div>
                            </div>
                            }
                        </div>

                    </Tooltip>}
                    {(horizontalData && horizontalData.todotgoson_date !== null) && <Tooltip
                        target="#dateBudget3z"
                        position="bottom"
                        visible={withAnimationVisibleDate2}
                        hideOnOutsideClick={true}
                    >
                        <div style={{ padding: 10, width: orgBudgetWorker === undefined || orgBudgetWorker.todotgoson_approved_worker_date === null ? '16rem' : '23rem', fontFamily: 'Segoe UI', backgroundColor: '#555', color: '#fff', borderBottom: '1px solid #fff', borderRadius: 10 }}>
                            <div style={{ display: 'flex', gap: 5, justifyContent: 'center' }}>
                            <b style={{ margin: 0, width: '40%', textAlign: 'end' }}>Шинэчлэгдсэн:</b>
                            <div style={{ display: 'flex', width: '60%' }}><span className="tooltip-series-name">Огноо:&nbsp;</span><label
                                            style={{ fontWeight: 'Bold' }}>{horizontalData.todotgoson_date}</label></div>
                            </div>
                            <hr/>
                            {orgBudgetWorker && orgBudgetWorker.todotgoson_worker_name !== null &&
                            <div style={{ display: 'flex', flexDirection: 'row', gap: 5, justifyContent: 'center' }}>
                                <div style={{ fontWeight: 'Bold', width: '40%', textAlign: 'end' }}>Баталгаажуулсан:</div>
                                <div style={{ width: '60%' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <div style={{ display: 'flex' }}><span className="tooltip-series-name">Овог нэр:&nbsp;</span><label
                                            style={{ fontWeight: 'Bold' }}>{orgBudgetWorker?.todotgoson_worker_name ? orgBudgetWorker?.todotgoson_worker_name : '-'}</label></div>
                                    </div>
                                    <div style={{ display: 'flex' }}><span className="tooltip-series-name">Албан тушаал:&nbsp;</span><label
                                        style={{ fontWeight: 'Bold' }}>{orgBudgetWorker?.todotgoson_position_name ? orgBudgetWorker?.todotgoson_position_name : '-'}</label>
                                    </div>
                                    <div style={{ display: 'flex' }}><span className="tooltip-series-name">Огноо:&nbsp;</span><label
                                        style={{ fontWeight: 'Bold' }}>{orgBudgetWorker?.todotgoson_approved_worker_date ? orgBudgetWorker?.todotgoson_approved_worker_date : '-'}</label>
                                    </div>
                                </div>
                            </div>}
                        </div>

                    </Tooltip>}
                    {(horizontalData && horizontalData.todotgoson2_date !== null) && <Tooltip
                        target="#dateBudget4z"
                        position="bottom"
                        visible={withAnimationVisibleDate3}
                        hideOnOutsideClick={true}
                    >
                         <div style={{ padding: 10, width: orgBudgetWorker === undefined || orgBudgetWorker.todotgoson2_approved_worker_date === null ? '16rem' : '23rem', fontFamily: 'Segoe UI', backgroundColor: '#555', color: '#fff', borderBottom: '1px solid #fff', borderRadius: 10 }}>
                            <div style={{ display: 'flex', gap: 5, justifyContent: 'center' }}>
                            <b style={{ margin: 0, width: '40%', textAlign: 'end' }}>Шинэчлэгдсэн:</b>
                            <div style={{ display: 'flex', width: '60%' }}><span className="tooltip-series-name">Огноо:&nbsp;</span><label
                                            style={{ fontWeight: 'Bold' }}>{horizontalData.todotgoson2_date}</label></div>
                            </div>
                            <hr/>
                            {orgBudgetWorker && orgBudgetWorker.todotgoson2_worker_name !==null &&
                            <div style={{ display: 'flex', flexDirection: 'row', gap: 5, justifyContent: 'center' }}>
                                <div style={{ fontWeight: 'Bold', width: '40%', textAlign: 'end' }}>Баталгаажуулсан:</div>
                                <div style={{ width: '60%' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <div style={{ display: 'flex' }}><span className="tooltip-series-name">Овог нэр:&nbsp;</span><label
                                            style={{ fontWeight: 'Bold' }}>{orgBudgetWorker?.todotgoson2_worker_name ? orgBudgetWorker?.todotgoson2_worker_name : '-'}</label></div>
                                    </div>
                                    <div style={{ display: 'flex' }}><span className="tooltip-series-name">Албан тушаал:&nbsp;</span><label
                                        style={{ fontWeight: 'Bold' }}>{orgBudgetWorker?.todotgoson2_position_name ? orgBudgetWorker?.todotgoson2_position_name : '-'}</label>
                                    </div>
                                    <div style={{ display: 'flex' }}><span className="tooltip-series-name">Огноо:&nbsp;</span><label
                                        style={{ fontWeight: 'Bold' }}>{orgBudgetWorker?.todotgoson2_approved_worker_date ? orgBudgetWorker?.todotgoson2_approved_worker_date : '-'}</label>
                                    </div>
                                </div>
                            </div>
                            }
                        </div>

                    </Tooltip>}

                    {(user.username === "02" && (chosenValue === "ALL" || chosenValue === undefined)) &&
                        <div className="card" style={{ flex: 1}}>
                            <div className="card-header" style={econ2Styles.textAligner}><span style={{
                                color: '#2f2f2f',
                                fontSize: '1rem',
                                textAlign: "center"
                            }}>Батлагдсан орон тоо</span>

                                <div style={{ padding: '.6rem', margin: 5 }}>
                                    <label style={{ fontWeight: 'normal' }}>{horizontalData?.batlagdsan_date}</label>
                                </div>
                            </div>
                            <div className="card-body"
                                style={{ ...econ2Styles.textAlignerSpan, ...econ2Styles.numericFontSize }}><span
                                    style={{
                                        color: props.type === 'zarlaga' ? '#F66160' : '#003696',
                                        fontSize: '2rem'
                                    }}>{horizontalData.batlagdsan ? (horizontalData.batlagdsan) : '-'}</span>
                            </div>
                        </div>

                    }
                    {(user.username !== "02") &&
                        <div className="card" style={{ flex: 1 }}>
                            <div style={econ2Styles.bigDivStyle}>
                                <div className="col-2" />
                                <div className="col-8">
                                    <p style={{
                                        margin: '0',
                                        color: '#2f2f2f',
                                        fontSize: '1rem',
                                        fontWeight: "700"
                                    }}>Батлагдсан орон тоо</p>
                                </div>
                                <div className="col-2" style={{ margin: 0, display: 'flex', justifyContent: 'end', cursor: 'default' }}>
                                    <div
                                        style={horizontalData?.batlagdsan_date ? dateStyle?.divStyle : {}}
                                        id="dateBudget1z"
                                        onMouseEnter={() => setWithAnimationVisibleDate(true)}
                                        onMouseLeave={() => setWithAnimationVisibleDate(false)}
                                    >
                                        <p style={dateStyle.pStyle}>{horizontalData?.batlagdsan_date}</p>
                                        {horizontalData?.batlagdsan_date ? <img style={dateStyle.iconStyle} src={info1} /> : <div/>}
                                    </div>
                                </div>
                            </div>
                            <div
                                style={{ ...econ2Styles.textAlignerSpan, ...econ2Styles.numericFontSize }}>
                                <span
                                    id="dateBudget1z"
                                    style={{
                                        color: props.type === 'zarlaga' ? '#F66160' : '#003696', fontSize: '2rem', cursor: 'pointer'
                                    }}>{horizontalData?.batlagdsan ? (horizontalData?.batlagdsan) : '-'}
                                </span>
                            </div>
                        </div>
                    }

                    {(user.username !== "02" && horizontalData && horizontalData.todotgoson !== 0) &&
                        <>
                            <div className="card" style={{ flex: 1}}>
                                <div style={econ2Styles.bigDivStyle}>
                                    <div className="col-2" />
                                    <div className="col-8">
                                        <p style={{
                                            margin: '0',
                                            color: '#2f2f2f',
                                            fontSize: '1rem',
                                            fontWeight: "700"
                                        }}>Тодотгосон орон тоо</p>
                                    </div>
                                        <div className="col-2" style={{ margin: 0, display: 'flex', justifyContent: 'end', cursor: 'default' }}>
                                            <div
                                                style={horizontalData?.todotgoson_date ? dateStyle.divStyle : {}}
                                                id="dateBudget3z"
                                                onMouseEnter={() => setWithAnimationVisibleDate2(true)}
                                                onMouseLeave={() => setWithAnimationVisibleDate2(false)}
                                            >
                                                <p style={dateStyle.pStyle}>{horizontalData?.todotgoson_date}</p>
                                               {horizontalData?.todotgoson_date ?  <img style={dateStyle.iconStyle} src={info1} /> : <div/>}
                                            </div>
                                        </div>
                                </div>
                                <div
                                    style={{ ...econ2Styles.textAlignerSpan, ...econ2Styles.numericFontSize }}>
                                    <span
                                        id="dateBudget3z"
                                        style={{
                                            color: props.type === 'zarlaga' ? '#F66160' : '#003696', fontSize: '2rem', cursor: 'pointer'
                                        }}>{ horizontalData?.todotgoson ? (horizontalData?.todotgoson) : '-'}
                                    </span>
                                </div>
                            </div>
                        </>
                    }
                    {(((chosenValue !== undefined && chosenValue !== "ALL") || user.username !== "02") && horizontalData && horizontalData.todotgoson2 !== 0) &&
                        <div className="card" style={{ flex: 1 }}>
                            <div style={econ2Styles.bigDivStyle}>
                                <div className="col-2" />
                                <div className="col-8">
                                    <p style={{
                                        margin: '0',
                                        color: '#2f2f2f',
                                        fontSize: '1rem',
                                        fontWeight: "700"
                                    }}>Тодотгосон орон тоо 2</p>
                                </div>
                             
                                    <div className="col-2" style={{ margin: 0, display: 'flex', justifyContent: 'end', cursor: 'default' }}>
                                        <div
                                            style={horizontalData?.todotgoson2_date ? dateStyle.divStyle : {}}
                                            id="dateBudget4z"
                                            onMouseEnter={() => setWithAnimationVisibleDate3(true)}
                                            onMouseLeave={() => setWithAnimationVisibleDate3(false)}
                                        >
                                            <p style={dateStyle.pStyle}>{horizontalData.todotgoson2_date}</p>
                                           {horizontalData?.todotgoson2_date ? <img style={dateStyle.iconStyle} src={info1} /> : <div/>} 
                                        </div>
                                    </div>
                            </div>
                            <div
                                style={{ ...econ2Styles.textAlignerSpan, ...econ2Styles.numericFontSize }}>
                                <span
                                    id="dateBudget4z"
                                    style={{
                                        color: props.type === 'zarlaga' ? '#F66160' : '#003696', fontSize: '2rem', cursor: 'pointer'
                                    }}>{horizontalData?.todotgoson2 ? (horizontalData.todotgoson2) : '-'}
                                </span>
                            </div>
                        </div>
                    }
                </>
            }
            {/* {!loader && dataSource &&
                <>
                    {dataSource.batlagdsan &&
                        <div className="card" style={{flex: 1}}>
                            <div className="card-header"><span style={{
                                display: 'flex',
                                justifyContent: 'center',
                                fontWeight: 700,
                                color: '#2f2f2f',
                                fontSize: '1rem'
                            }}>Батлагдсан орон тоо</span></div>
                            <div className="card-body"><span style={{
                                display: 'flex',
                                justifyContent: 'center',
                                color: '#003696',
                                fontSize: '2rem',
                                fontWeight: "700",
                            }}>{(dataSource.batlagdsan * 1).toLocaleString()}</span></div>
                        </div>
                    }
                    {
                        dataSource.todotgoson && <div className="card" style={{flex: 1}}>
                            <div className="card-header"><span style={{
                                display: 'flex',
                                justifyContent: 'center',
                                fontWeight: 700,
                                color: '#2f2f2f',
                                fontSize: '1rem',
                                textAlign: "center"
                            }}>Тодотгосон орон тоо</span></div>
                            <div className="card-body"><span style={{
                                display: 'flex',
                                justifyContent: 'center',
                                color: '#003696',
                                fontSize: '2rem',
                                fontWeight: "700",
                            }}>{(dataSource.todotgoson * 1).toLocaleString()}</span></div>
                        </div>
                    }
                    {
                        dataSource.todotgoson2 && <div className="card" style={{flex: 1}}>
                            <div className="card-header"><span style={{
                                display: 'flex',
                                justifyContent: 'center',
                                fontWeight: 700,
                                color: '#2f2f2f',
                                fontSize: '1rem',
                                textAlign: "center"
                            }}>Тодотгосон орон тоо 2</span></div>
                            <div className="card-body"><span style={{
                                display: 'flex',
                                justifyContent: 'center',
                                color: '#003696',
                                fontSize: '2rem',
                                fontWeight: "700",
                            }}>{(dataSource.todotgoson2 * 1).toLocaleString()}</span></div>
                        </div>
                    }
                </>
            } */}
        </div>
    )
}
const econ2Styles = {
    bigDivStyle: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        textAlign: 'center',
        width: '100%',
        height: '3rem',
        padding: 10
    },
    textAligner: {
        padding: '0',
        textAlign: 'center',
        fontWeight: 700
    },
    textAlignerSpan: {
        textAlign: 'center',
        fontWeight: "700",
        marginBottom: 10
    },
    numericFontSize: {
        fontSize: '1rem'
    }
}
const dateStyle = {
    divStyle: {
        position: 'relative',
        backgroundColor: '#EBF1FF',
        padding: '5px',
        border: 'none',
        width: '6rem',
        borderRadius: '2rem'
    },
    pStyle: {
        margin: '0',
        paddingLeft: '5px',
        fontSize: '0.6rem',
        textAlign: 'start',
        fontWeight: 'bold'
    },
    iconStyle: {
        position: 'absolute',
        width: '2rem',
        height: '2rem',
        right: '-5px',
        top: '-4.5px'
    }
}
export default BudgetOtherOne;

import React from 'react';

// static
import '../css/fileStyles.css';
import { FaEdit } from 'react-icons/fa';
import {AiOutlineFieldNumber} from 'react-icons/ai';

// components
import LawForm from "./lawForm";
import Button from "../../../pages/introduction/components/ButtonComponent";
import {Popup} from "devextreme-react/popup";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import styles from "../../order/static/OrderData.module.css";

export default function FileComponent({law, size, docTypes, setLaws, setIsLoading}) {
    const [visible, setVisible] = React.useState(false);

    let {
        legal_url,
        name,
        law_no,
        submitted_date,
        follow_date,
        registration_date,
        register_no,
        enforced,
        law_doc_type_name,
    } = law;

    return (
        <>
            <Popup
                visible={visible}
                onHiding={()=>setVisible(false)}
                hideOnOutsideClick={true}
                width={1100}
                height={700}
                showTitle={false}
                style={{position: 'absolute'}}
            >
                <LawForm
                    docTypes={docTypes}
                    law={law}
                    setLaws={setLaws}
                    setVisible={setVisible}
                    setIsLoading={setIsLoading}
                />
            </Popup>
            <a className = 'file-layout'>

                <div className ={`law-file-title-description ${size === 'small' && 'law-file-small-text'}`}>
                    {name}
                    {enforced === true && <span className = 'enforced active-green '>ХҮЧИНТЭЙ</span>}
                    {enforced === false && <span className = 'enforced inactive-red'>ХҮЧИНГҮЙ</span>}
                </div>



                <div className = {`${size === 'small' && 'law-file-description-small'} law-file-description`}>

                    <div>

                        <span className = 'specifier-text-green'>Актын  <AiOutlineFieldNumber className = 'num-icon'/>: </span><span className = 'white-bold-text law_no'>{law_no}</span>

                        <span className = 'specifier-text-green'>Хаяг:  </span><a target = '_blank'  href = {legal_url} rel="noreferrer"><FontAwesomeIcon icon="link" className = 'link-icon' /></a>

                        {register_no &&
                            <span>
                                <span  className = 'specifier-text-green'>
                                    Бүртгэлийн <AiOutlineFieldNumber className = 'num-icon'/>:
                                </span>

                                <span className = 'white-bold-text'> {register_no}</span>
                            </span>}

                    </div>
                    <div>

                        {submitted_date && <span><span className = 'specifier-text-green'>Батлагдсан: </span><span className = 'white-bold-text'>{submitted_date}</span></span>}

                        {follow_date && <span><span className = 'specifier-text-green'>Дагаж мөрдөх: </span><span className = 'white-bold-text'>{follow_date}</span></span>}

                        {size === 'big' && registration_date && <span><span className = 'specifier-text-green'>Бүртгэлийн огноо: </span><span className = 'white-bold-text'>{registration_date}</span></span>}
                    </div>

                    {size === 'small' && registration_date &&
                        <div>
                            <span className = 'specifier-text-green'>Бүртгэлийн огноо: </span><span className = 'white-bold-text'>{registration_date}</span>
                        </div>
                    }

                    <div className = 'edit-btn-div'>
                        <FaEdit className = {`${size === 'small' && 'law-edit-btn-sm'} law-edit-btn`} onClick={()=>setVisible(true)} />
                    </div>

                </div>
            </a>
        </>
    )
}
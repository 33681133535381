import React, {useEffect, useState, useRef} from 'react';
import 'devextreme-react/text-area';
import 'devextreme-react/text-area';
import {
    Editing, Column, Lookup,
} from 'devextreme-react/tree-list';
import notify from 'devextreme/ui/notify';
import orgPositionServices from "../../../services/api/orgPosition";
import FileServices from '../../../services/api/file';
import FileUploader from "devextreme-react/file-uploader";
import _ from "lodash";
import DataGrid, {
    Export,
    FilterRow, Form,
    Grouping,
    GroupPanel, Item,
    Paging,
    Popup as DataGridPopup,
    SearchPanel,
    Button as GridButton
} from "devextreme-react/data-grid";
import {Workbook} from "exceljs";
import {exportDataGrid} from "devextreme/excel_exporter";
import {saveAs} from "file-saver-es";
import SingleTreeListComponent from "../components/SingleTreeListComponent";
import LoadPanelComponent from '../../../components/LoadPanelComponent';
import Button from "devextreme-react/button";
import {Popup} from "devextreme-react/popup";
import strategy_icon from "../../../assets/imgs/structureIcon2.png";
import {urlServer} from "../../../shared/lib/request";
import styles from '../settings.module.css';

const status_data = [
    {
        "id": 0,
        "name": "Идэвхгүй"
    },
    {
        "id": 1,
        "name": "Идэвхтэй"
    }];

function OrgPosition(props) {
    const [dataSource, setDataSource] = useState([]);
    const [loader, setLoader] = useState(false);
    const myBabyRef = useRef(null);
    const [stateStructureData, setStateStructureData] = useState([]);
    const [pageIndex, setPageIndex] = useState(0);
    const fileRef = useRef();
    // const [currentPositionId, setCurrentPositionId] = useState(null);
    const [fileName, setFileName] = useState(null);
    const [filePopUpModal, setFilePopUpModal] = useState(false);

    useEffect(() => {
        listFetch();
    }, [])

    const listFetch = async () => {
        try {
            setLoader(true);
            const result = await orgPositionServices.getOrgPosition();
            result.data.map(e => {
                // console.log("test: ", e.status)
                if (e.status === 1) {
                    e.statusName = "Идэвхтэй";
                } else {
                    e.statusName = "Идэвхгүй";
                }
                // console.log("aaa: ", e.statusName);
            })
            setDataSource(result.data);
            const r = await orgPositionServices.getStateStuctureView();
            setStateStructureData(r.data);
            return setLoader(false)
        } catch (e) {
            console.error(e);
        }
    }
    const setData = async (e) => {
        setLoader(true);
        if (e.changes && e.changes.length !== 0) {
            if (e.changes[0].type === "update") {
                try {
                    // console.log('e.changes[0]', e.changes[0]);
                    let ssd = _.filter(stateStructureData, {id: e.changes[0].data.state_structure_id});
                    // console.log('ssd', ssd);
                    e.changes[0].data.org_id = ssd[0].org_id;
                    // console.log('e.changes[0].data', e.changes[0].data);
                    await orgPositionServices.editOrgPosition(e.changes[0].data);
                    await saveFile(e.changes[0].data.id)
                    await listFetch();
                    notify('Амжилттай хадгалагдлаа', "success", 2000);
                } catch (e) {
                    notify('Засварлалтыг хадгалах явцад алдаа гарлаа', "error", 2000);
                }
            } else if (e.changes[0].type === "insert") {
                try {
                    delete e.changes[0].data.id;
                    //   console.log('e.changes[0]', e.changes[0]);
                    let ssd = _.filter(stateStructureData, {id: e.changes[0].data.state_structure_id[0]});
                    // console.log('ssd', ssd);
                    e.changes[0].data.org_id = ssd[0].org_id;
                    //console.log('e.changes[0].data', e.changes[0].data);
                    const result = await orgPositionServices.addOrgPosition(e.changes[0].data);
                    await saveFile(result.data.id)
                    await listFetch();
                    notify('Амжилттай үүслээ', "success", 2000);
                } catch (e) {
                    notify('Шинээр үүсэх явцад алдаа гарлаа', "error", 2000);
                }

            } else if (e.changes[0].type === "remove") {

                try {
                    await orgPositionServices.removeOrgPosition(e.changes[0].key.id);
                    notify('Амжилттай устгагдлаа', "success", 2000);
                } catch (e) {
                    notify('Энэхүү албан тушаалд ажилтан бүртгэлтэй байгаа тул устгах боломжгүй!', "error", 2000);
                }
            }
        }
        setLoader(false)
    }
    //Column custom renderings
    const statusRender = (data) => {
        return (data.value === 1 ? <div style={{
            backgroundColor: '#50CB93',
            width: '80%',
            color: 'white',
            borderRadius: '3px',
            cursor: 'pointer',
            margin: 'auto',
            textAlign: 'center'
        }}>Идэвхтэй</div> : <div style={{
            backgroundColor: '#FF616D',
            width: '80%',
            color: 'white',
            borderRadius: '3px',
            cursor: 'pointer',
            margin: 'auto',
            textAlign: 'center'
        }}>Идэвхгүй</div>)
    }
    const onEditorPreparing = (e) => {
        if (e.parentType === "dataRow") {
            return;
        }
        if (e.row && e.row.isNewRow) {
            e.editorOptions.visible = true;
        }
        if (e.row && !e.row.isNewRow) {
            e.editorOptions.visible = false;
        }
    }
    const onInitNewRow = (e) => {

    }

    const onExporting = (e) => {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Main sheet');

        exportDataGrid({
            component: e.component,
            worksheet,
            autoFilterEnabled: true,
        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer], {type: 'application/octet-stream'}), 'OrgPosition.xlsx');
            });
        });
        e.cancel = true;
    }
    const handleOptionChange = (e) => {
        if (e.fullName === 'paging.pageIndex') {
            setPageIndex(e.value)
        }
    }

    const orderRender = (data) => {
        return <div style={{color: "#171010"}}>{pageIndex * 20 + data.rowIndex + 1}</div>;
    }
    const onCellPreparedHandler = (e) => {
        if (e.rowType === 'header') {
            e.cellElement.wordWrapEnabled = true;
            e.cellElement.style.fontFamily = 'Montserrat'
            e.cellElement.style.fontWeight = 'semibold'
            e.cellElement.style.color = "#15186A"
            e.cellElement.style.backgroundColor = "#EEF0F4"
        }
        if (e.rowType === 'data') {
            e.cellElement.style.fontFamily = 'Montserrat'
            e.cellElement.style.fontWeight = 'semibold'
            e.cellElement.style.color = "#000000"
            e.cellElement.style.verticalAlign = 'middle'
        }
    }

    const onChangeFile = (e) => {
        fileRef.current = e.value
    }

    const saveFile = async(id) => {
        if (fileRef.current && fileRef.current[0].type === 'application/pdf') {
            let data = new FormData();
            let fileData = {}
            data.append("file", fileRef.current[0]);
            await FileServices.uploadFile(data, 22)
                .then((res)=>{
                    fileData = {
                        file_id: res.data.id,
                        position_id: id,
                        file_type_id: 22
                      }
                }
                )
            // console.log("fileData: ", fileData)
            await FileServices.setFilePosition(fileData)
        }
        await listFetch()
    }
    const columnInfoFile = (data) => {
        setFilePopUpModal(true);
        setFileName(data.data.file_name);
    }
    const renderGridFile = (data) => {
        return data.text && <div style={{textAlign: 'center'}}>
            <Button
                icon={strategy_icon}
                stylingMode="text"
                style={{
                    width: 'auto',
                    height: '35px',
                    opacity: 1
                }}
                onClick={() => {
                    columnInfoFile(data)
                }}
            />
        </div>
    }
    return (
        <React.Fragment>
        <div>
            <LoadPanelComponent
                shadingColor="rgba(0,0,0,0.4)"
                position={{of: '#password'}}
                visible={loader}
                message=''
                showPane={false}
            />
            <div>
                <div className="card-body">
                    <DataGrid
                        onEditorPreparing={onEditorPreparing}
                        // onEditingStart={(e)=>{setCurrentPositionId(e.data.id)}}
                        ref={myBabyRef}
                        dataSource={dataSource}
                        onSaved={setData}
                        keyExpr={null}
                        showBorders={true}
                        showColumnLines={true}
                        showRowLines={true}
                        onCellPrepared={onCellPreparedHandler}
                        wordWrapEnabled={true}
                        onInitNewRow={onInitNewRow}
                        allowColumnResizing={true}
                        onOptionChanged={handleOptionChange}
                        paging={{
                            pageSize: 20,
                        }}
                        onExporting={onExporting}>
                        
                        <GroupPanel visible={true} emptyPanelText={"Хүссэн баганаа дээш зөөн бүлэглэх боломжтой"}/>
                        <SearchPanel visible={true}/>
                        <Grouping autoExpandAll={false}/>
                        <FilterRow
                            visible={true}
                        />
                        <Column
                            caption='№'
                            cssClass="custom"
                            width={60}
                            alignment="center"
                            allowEditing={false}
                            cellRender={orderRender}
                        />
                        <Column
                            dataField="state_structure_id"
                            caption="Байгууллага нэр"
                            allowFiltering={true}
                            allowUpdating={true}
                            visible={true}
                            editCellComponent={SingleTreeListComponent}
                        >
                            <Lookup
                                valueExpr="id"
                                displayExpr="state_structure_name"
                                dataSource={stateStructureData}

                            />
                        </Column>
                        <Column
                            dataField="name"
                            caption="Албан тушаал нэр"
                            allowEditing={true}
                            minWidth={130}
                            cssClass="custom"
                            allowFiltering={true}
                        >
                        </Column>
                        <Column
                            dataField="file_id"
                            caption="Албан тушаалын тодорхойлолт"
                            editCellTemplate={"file_name"}
                            width={100}
                            cellRender={renderGridFile}
                            />
                        <Column
                            cssClass="custom"
                            dataField="status"
                            caption="Төлөв"
                            allowEditing={true}
                            cellRender={statusRender}
                            allowFiltering={true}
                            width={100}>
                            <Lookup
                                valueExpr="id"
                                displayExpr="name"
                                dataSource={status_data}

                            />
                        </Column>
                        <Column dataField="createdAt" dataType="date" format="yyyy-MM-dd hh:mm:ss"
                                caption="Үүсгэсэн огноо" width={150} allowEditing={false}/>
                        <Column dataField="updatedAt" dataType="date" format="yyyy-MM-dd hh:mm:ss"
                                caption="Шинэчилсэн огноо" width={150} allowEditing={false}/>
                        <Column dataField="position_rank" dataType="number" caption="Эрэмбэ" width={60}/>
                        <Column caption='' width={'5rem'} type='buttons'>
                            <GridButton cssClass='grid-button' icon={'edit'} onClick={(e)=>{myBabyRef.current.instance.editRow(e.row.rowIndex)}}/>
                            <GridButton cssClass='grid-button' icon={'trash'} onClick={(e)=>myBabyRef.current.instance.deleteRow(e.row.rowIndex)}/>
                        </Column>
                        <Editing
                            mode="popup"
                            // allowUpdating={true}
                            // allowDeleting={true}
                            allowAdding={true}
                            useIcons={true}
                            texts={{
                                cancelAllChanges: 'Болих бүгд',
                                cancelRowChanges: 'Болих',
                                confirmDeleteMessage: 'Энэ албан тушаалыг устгах уу?',
                                confirmDeleteTitle: 'Баталгаажуулах',
                                deleteRow: 'Устгах',
                                editRow: 'Өөрчлөх',
                                saveAllChanges: 'Хадгалах бүгд',
                                saveRowChanges: 'Хадгалах',
                                undeleteRow: 'Буцаах',
                                validationCancelChanges: 'Баталгаажуулах болих',
                            }}
                        >
                            <DataGridPopup title="Байгууллагын албан тушаалын бүртгэл" showTitle={true} width={1000}
                                   height={550}/>
                            <Form>
                                <Item itemType="group" colCount={1} colSpan={2}>
                                    <Item dataField="state_structure_id"/>
                                    <Item dataField="name"/>
                                    <Item dataField="status"/>
                                    <Item dataField="position_rank"/>
                                    <Item>
                                        <div className={styles.fileUploaderButton}>
                                            <span className={styles.fileUploaderText}>
                                            Албан тушаалын тодорхойлолт:
                                            </span>
                                            <div>
                                                <FileUploader 
                                                    allowedFileExtensions={[".pdf"]}
                                                    onValueChanged={onChangeFile}
                                                    labelText=""
                                                    accept="*"
                                                    uploadMode="useForm"
                                                    selectButtonText='Файл Сонгох'
                                                    />
                                                <span className="note">{'Файлын өргөтгөл: '}
                                                    <span>.pdf</span>
                                                </span>
                                            </div>
                                        </div>
                                    </Item>
                                </Item>
                            </Form>
                        </Editing>
                        <Paging enabled={true} pageIndex={pageIndex}/>
                        <Export enabled={true} allowExportSelectedData={false}/>
                    </DataGrid>
                </div>
            </div>

        </div>
         {fileName && filePopUpModal &&
            <Popup
                width={1200}
                maxWidth={'100%'}
                minHeight={'100%'}
                height={'100%'}
                title={fileName && fileName}
                visible={filePopUpModal}
                onHiding={() => {
                    setFilePopUpModal(false)
                }}
                dragEnabled={false}
                hideOnOutsideClick={true}
            >
                <object width="100%" height="100%"
                        data={urlServer + '/api/file/documents/' + fileName}
                        type="application/pdf"/>
                </Popup>}
        </React.Fragment>

    )
}

export default OrgPosition;

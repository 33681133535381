import { useState } from "react";

// Hook
export function useLocalStorage(storage_key, initialValue) {
  const [storedValue, setStoredValue] = useState(() => {
    if (typeof window === "undefined") {
      return initialValue;
    }
    try {
      let item = null 
        item = window.localStorage.getItem(storage_key);
      return item ? JSON.parse(item) : initialValue;
    } 
    catch (error) {
      console.log(error);
      return initialValue;
    }
  });
  const setValue = (value) => {
    try {
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      if (typeof window !== "undefined") {
        let localStorage_value=JSON.stringify(valueToStore)
        let localStorage_key=storage_key
        window.localStorage.setItem(localStorage_key, localStorage_value)
      }
    } catch (error) {
      console.log(error);
    }
  };
  return [storedValue, setValue];
}
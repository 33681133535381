import React, {useEffect, useState} from 'react';
import achievment from '../../assets/imgs/icon/achievment.png';
import contact from '../../assets/imgs/icon/contact.png';
import email from '../../assets/imgs/icon/email.png';
import fb from '../../assets/imgs/icon/fb.png';
import insta from '../../assets/imgs/icon/insta.png';
import phone from '../../assets/imgs/icon/phone.png';
import schools from '../../assets/imgs/icon/schools.png';
import twitter from '../../assets/imgs/icon/twitter.png';
import Nyambaatar from '../../assets/imgs/hzyam/headImage/Nyambaatar.png';
export default function Minister() {

    useEffect(async () => {


    }, []);

    const style = {
        textJustify: {
            textAlign: "justify",
            textJustify: "inter-word",
            fontSize: 9
        },
        iconStyle: {
            width: 'auto',
            height: '25px',
            position: 'absolute',
            paddingLeft: '1.5%',
            marginLeft: 'auto',
            marginRight: 'auto',
        },
        iconLink: {
            width: 'auto',
            height: '12px',
            marginLeft: 'auto',
            marginRight: 'auto',
            paddingRight: '5px'
        },
        cardStyle: {
            boxShadow: '2px 2px 7px -1px rgba(0,0,0,0.25)',
            padding: '5% 10px 10px',
            height: '100%'
        },
        minister: {
            width: 'auto',
            height: 'auto',
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
            verticalAlign: 'middle',
            maxHeight: '100%'
        },
        frame :{
            height: '95%',
        },
        infoSpace: {
            paddingLeft: 5
        },
        rowSpace: {
            paddingTop: 10
        }
    }
    return (
        <div style={{color: '#140b3b'}}>

            <div calass="card" style={{height: '640px'}}>
                <div class="row card" style={style.cardStyle}>
                    <div class="col-4" style={style.frame}>
                        <img loading="lazy" src={Nyambaatar} style={style.minister} alt={"text"}/>
                    </div>
                    <div class="col-8">
                        <div class="row">
                            <div class="col-1"/>
                            <div class="col-11">
                                <p>Хууль, зүй дотоод хэргийн сайд</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-1"/>
                            <div class="col-11 hzText-bold">
                                <p style={{fontSize: 17, textTransform: 'uppercase'}}>Хишгээгийн Нямбаатар</p>
                            </div>
                        </div>

                        <div class="row" style={style.rowSpace}>
                            <div class="col-1">
                                <img loading="lazy" src={schools} style={style.iconStyle} alt={"text"}/>
                            </div>
                            <div class="col-11 hzText-bold"><p>Төгссөн сургууль:</p></div>
                        </div>
                        <div class="row">
                            <div class="col-1"/>
                            <div class="col-11 hz-italic" style={style.textJustify}>

                                <table>
                                    <tr>
                                        <td width={60}>1996 онд</td>
                                        <td>48 дугаар дунд сургууль</td>
                                    </tr>
                                    <tr>
                                        <td width={60}>2000 онд</td>
                                        <td>Орхон их сургууль, эрх зүйч /Бакалавр/</td>
                                    </tr>
                                    <tr>
                                        <td width={60}>2002 онд</td>
                                        <td>Орхон их сургууль, Боловсрол судлалын магистр</td>
                                    </tr>
                                    <tr>
                                        <td width={60}>2008 онд</td>
                                        <td>Цагдаагийн академи, /Хууль сахиулахын Их сургууль/ Хууль зүйн ухааны магистр
                                            /
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width={60}>2012 онд</td>
                                        <td>Удирдлагын Академи, Төрийн удирдлагын докторант</td>
                                    </tr>
                                    <tr>
                                        <td width={60}>2017 онд</td>
                                        <td>Хууль сахиулахын Их сургууль, Хууль зүйн ухааны докторант</td>
                                    </tr>
                                </table>

                            </div>
                        </div>
                        <div class="row">
                            <div class="col-1">
                                <img loading="lazy" src={achievment} style={style.iconStyle} alt={"text"}/>
                            </div>
                            <div class="col-11 hzText-bold"><p>Ажлын туршлага:</p></div>
                        </div>
                        <div class="row">
                            <div class="col-1"/>
                            <div class="col-11 hz-italic" style={style.textJustify}>

                                <table>
                                    <tr>
                                        <td width={70}> 2000-2007 онд</td>
                                        <td>Орхон их сургуульд багш</td>
                                    </tr>
                                    <tr>
                                        <td width={70}> 2005-2009 онд</td>
                                        <td>Монголын өмгөөлөгчдийн холбоонд өмгөөлөгч</td>
                                    </tr>
                                    <tr>
                                        <td width={70}> 2009 онд</td>
                                        <td>Сонгинохайрхан дүүргийн хуулийн зөвлөх</td>
                                    </tr>
                                    <tr>
                                        <td width={70}> 2009-2012 онд</td>
                                        <td>Сонгинохайрхан дүүргийн Төрийн захиргаа, удирдлагын хэлтсийн дарга</td>
                                    </tr>
                                    <tr>
                                        <td width={70}> 2012-2016 онд</td>
                                        <td>Сонгинохайрхан дүүргийн Иргэдийн төлөөлөгчдийн тэргүүлэгчдийн хурлын дарга
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width={70}> 2016-2020 онд</td>
                                        <td>Монгол Улсын Их Хурлын гишүүн</td>
                                    </tr>
                                    <tr>
                                        <td width={70}> 2019-2020 онд</td>
                                        <td>Монгол Улсын Их Хурлын Хууль зүйн байнгын хорооны дарга</td>
                                    </tr>
                                    <tr>
                                        <td width={70}> 2020 оноос</td>
                                        <td>Монгол Улсын Их Хурлын гишүүн</td>
                                    </tr>
                                    <tr>
                                        <td width={70}> 2020 оноос</td>
                                        <td>Монгол Улсын Засгийн газрын гишүүн, Хууль зүй, дотоод хэргийн сайд</td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                        <div class="row" style={style.rowSpace}>
                            <div class="col-1">
                                <img loading="lazy" src={contact} style={style.iconStyle} alt={"text"}/>
                            </div>
                            <div class="col-11 hzText-bold"><p>Холбоо барих:</p></div>
                        </div>

                        <div class="row">
                            <div class="col-1"/>
                            <div class="col-11" style={style.textJustify}>
                                <p>
                                    <a href={`tel:+261708`} id="number">
                                        <img loading="lazy" src={phone} style={style.iconLink} alt={"text"}/>261708
                                    </a>,
                                    <a href={`tel:+97699114118`} id="number">
                                        99114118
                                    </a>
                                </p>
                                <a href={`mailto:nyambaatar@mojha.gov.mn`}>
                                    <p><img loading="lazy" src={email} style={style.iconLink} alt={"text"}/>nyambaatar@mojha.gov.mn
                                    </p>
                                </a>
                                <a href={'https://www.facebook.com/Nyambaatar'} target={'_blank'}>
                                    <p><img loading="lazy" src={fb} style={style.iconLink} alt={"text"}/> Nyambaatar</p>
                                </a>
                                <a href={'https://twitter.com/KhNyambaatar'} target={'_blank'}>
                                    <p><img loading="lazy" src={twitter} style={style.iconLink} alt={"text"}/>@KhNyambaatar
                                    </p>
                                </a>
                                <a href={'https://www.instagram.com/nyambaatar_kh/'} target={'_blank'}>
                                    <p><img loading="lazy" src={insta} style={style.iconLink} alt={"text"}/>nyambaatar_kh
                                    </p>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

import React, { useState, useEffect } from 'react'
import BudgetService from '../../../services/api/budget'
import notify from 'devextreme/ui/notify'
import _ from 'lodash'
import LoadPanelComponent from '../../budget/components/loadPanel'
import { IoIosArrowDown } from 'react-icons/io'


const PerformanceByEzaExpense = (props) => {

    const [data, setData] = useState([])
    const [data1, setData1] = useState([])
    const [data2, setData2] = useState([])
    const [loader, setLoader] = useState(false)
    const [selectedEza, setSelectedEza] = useState(null)
    const [selectedEza1, setSelectedEza1] = useState(null)
    const [selectedItemIndex, setSelectedItemIndex] = useState(null);

    const fetchData = async () => {
        try {
            setLoader(true);
            let r = await BudgetService.getExpensePivotData(props.year, props.filterOrgText, props.chosenMinistry ? props.chosenMinistry : "ALL", props.econType, props.orgId);
            let desc = await BudgetService.getEzaDescription(2)
            let pp = _(r)
                .groupBy('econ_category_name_2')
                .map((item, key) => {
                    const amountSum = _.sumBy(item, "amount");
                    const guitsetgelSum = _.sumBy(item, "guitsetgel");
                    const percent1 = amountSum !== 0 ? ((guitsetgelSum * 100) / amountSum).toFixed(2) : 0;
                    return {
                        eza: key,
                        amount: amountSum / 1000000,
                        guitsetgel: guitsetgelSum / 1000000,
                        percent: percent1
                    }
                })
                .orderBy(['eza'], ['asc'])
                .value();

            let pp1 = _(r)
                .groupBy('econ_category_name_3')
                .map((item, key) => {
                    const amountSum = _.sumBy(item, "amount");
                    const guitsetgelSum = _.sumBy(item, "guitsetgel");
                    const percent1 = amountSum !== 0 ? ((guitsetgelSum * 100) / amountSum).toFixed(2) : 0;
                    return {
                        eza: item[0].econ_category_name_2,
                        eza1: key,
                        amount: amountSum / 1000000,
                        guitsetgel: guitsetgelSum / 1000000,
                        percent: percent1
                    }
                })
                .orderBy(['eza1'], ['asc'])
                .value();

            let pp2 = _(r)
                .groupBy('econ_category_name_4')
                .map((item, key) => {
                    const amountSum = _.sumBy(item, "amount");
                    const guitsetgelSum = _.sumBy(item, "guitsetgel");
                    const percent1 = amountSum !== 0 ? ((guitsetgelSum * 100) / amountSum).toFixed(2) : 0;
                    return {
                        eza1: item[0].econ_category_name_3,
                        eza2: key,
                        amount:amountSum / 1000000,
                        guitsetgel: guitsetgelSum / 1000000,
                        percent: percent1,
                        description: getDescriptionByKey(key, desc)
                    }
                })
                .orderBy(['eza2'], ['asc'])
                .value();
                function getDescriptionByKey(key, desc) {
                    const matchingDesc = desc.find(item => item.econ_category_name === key);
                    return matchingDesc ? matchingDesc.description : null;
                }
            setData(pp) // Эдийн засгийн ангилал 2-н дата (Урсгал зардал, Хөрөнгийн зардал г.м)
            setData1(pp1) // Эдийн засгийн ангилал 3-н дата (ЭЗА 2-н задаргаа)
            setData2(pp2) // Эдийн засгийн ангилал 4-н дата
            setLoader(false);
        } catch (e) {
            setLoader(false);
            notify(e.message, "error", 2000);
        }
    }
   
    useEffect(() => {
        fetchData()
        setSelectedEza(null)
        setSelectedEza1(null)
    }, [props.year, props.orgId])

    const groupedData1 = _.groupBy(data1, 'eza');
    const groupedData2 = _.groupBy(data2, 'eza1');
    
    const handleClick = (eza) => {
        setSelectedEza(eza)
        setSelectedEza1(null)
    }

    const handleClick1 = (eza1) => {
        setSelectedEza1(eza1)
    }

    const colors = ['#d7e3fc', '#CFF0FF']

    return (
        <div id='load' className='responsiveEza'>
            <LoadPanelComponent position={{ of: '#load' }} visible={loader} />
            <div className='eza' style={{ display: 'flex', flexWrap: 'wrap', gap: '15px', marginBottom: '1rem' }}>
                {data && data.length > 0 ?
                    data.map((e, index) => {
                        let percentage = e.percent
                        return <div className='mainContainerOfCard1' style={{backgroundImage: '#FFFFFF', border: `3px solid ${colors[index]}`, boxShadow: `0px 0px 5px ${colors[index]}`}} key={index} onClick={() => {
                            handleClick(e.eza)
                            setSelectedItemIndex(index)
                        }}>
                            <div className='card-ball' style={{backgroundColor: `${colors[index]}`}}/>
                            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', textAlign: 'start', height: '80%' }}>
                                <h3 className='h3' style={{ fontSize: '1rem' }}>{e.eza.replace(/[0-9-]/g, '')}</h3>
                                <h2 className='h2' style={{ fontWeight: 'bold', margin: '0 0 3px 0' }}>{e.amount.toLocaleString(undefined, { maximumFractionDigits: 2 })}</h2>
                                <span style={{ display: 'flex', fontWeight: '.875rem', gap: '5px', }}>
                                    Гүйцэтгэл: <span style={{ fontWeight: 'bold', color: 'rgb(76, 82, 134)' }}>{e.guitsetgel.toLocaleString(undefined, { maximumFractionDigits: 2 })}</span>
                                    <span style={{ fontWeight: 'bold'}}>({percentage}%)</span>
                                </span>
                            </div>

                            <div className='bouncePerformance'>
                                <IoIosArrowDown size={25} />
                            </div>


                        </div>

                    })
                    :
                    <div>Мэдээлэл байхгүй байна</div>
                }
            </div>
            {selectedEza && (
                <div className='eza1' style={{marginBottom: '1rem' }}>
                    <h3 style={{ fontSize: '1rem', fontWeight: 'bold' }}>{selectedEza}</h3>
                    <div style={{ display: 'flex', flexWrap: 'wrap', gap: '15px' }}>
                        {groupedData1[selectedEza].map((e, index) => {
                            let percentage = e.percent
                            return (
                                <div className='mainContainerOfCard1' style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', border: `2px solid ${colors[selectedItemIndex]}`, boxShadow: `0px 0px 5px ${colors[selectedItemIndex]}`}} key={index} onClick={()=>handleClick1(e.eza1)}>
                                    <div className='card-ball1' style={{backgroundColor: `${colors[selectedItemIndex]}`}}/>
                                    <div style={{ textAlign: 'start', justifyContent: 'space-between', height: '80%' }}>
                                        <h3 className='h3' style={{ fontSize: '1rem' }}>{e.eza1.replace(/[0-9-]/g, '')}</h3>
                                        <h2 className='h2' style={{ fontWeight: 'bold', margin: '0 0 3px 0' }}>{e.amount.toLocaleString(undefined, { maximumFractionDigits: 2 })}</h2>
                                        <span style={{ display: 'flex',fontWeight: '.875rem', gap: '5px', }}>
                                            Гүйцэтгэл:<span style={{ fontWeight: 'bold',color: 'rgb(76, 82, 134)' }}>{e.guitsetgel.toLocaleString(undefined, { maximumFractionDigits: 2 })} </span>
                                            <span style={{ fontWeight: 'bold'}}>({percentage}%)</span>
                                        </span>

                                    </div>
                                    <div className='bouncePerformance'>
                                        <IoIosArrowDown size={25} />
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            )}
             {selectedEza1 && (
                <div className='eza2' style={{borderTop: '1px solid black'}}>
                    <h3 style={{ fontSize: '1rem', fontWeight: 'bold' }}>{selectedEza1}</h3>
                    <div style={{ display: 'flex', flexWrap: 'wrap', gap: '15px'}}>
                        {groupedData2[selectedEza1].map((e, index) => {
                            let percentage = e.percent
                            return (
                                <div className='mainContainerOfCard' key={index} style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', backgroundColor: '#FAFBFC', border: `1px solid ${colors[selectedItemIndex]}`, boxShadow: `0px 0px 5px ${colors[selectedItemIndex]}`}}>
                                        {/* <div className='card-ball'/> */}
                                        <h3 className='h3' style={{ fontSize: '1rem' }}>{e.eza2.replace(/[0-9-]/g, '')}</h3>
                                        <h2 className='h2' style={{ fontWeight: 'bold', margin: '0 0 3px 0' }}>{e.amount.toLocaleString(undefined, { maximumFractionDigits: 2 })}</h2>
                                        <span style={{ display: 'flex', fontWeight: '.875rem', gap: '5px', }}>
                                            Гүйцэтгэл:<span style={{ fontWeight: 'bold',color: 'rgb(76, 82, 134)' }}>{e.guitsetgel.toLocaleString(undefined, { maximumFractionDigits: 2 })} </span>
                                            <span style={{ fontWeight: 'bold'}}>({percentage}%)</span>
                                        </span>
                                        <div className='backOfCard'>
                                        <p style={{fontSize: '12px', textAlign: 'justify'}}>{e.description}</p>
                                    </div>                            
                                </div>
                            )
                        })}
                    </div>
                </div>
            )}
        </div>
    )
}

export default PerformanceByEzaExpense
import React, { useState, useEffect} from 'react';

import Sankey, {
    Tooltip,
    Link,
    Node
} from 'devextreme-react/sankey';
import ProposalService from "../../../services/api/proposal";

function DocumentComponent(props) {
    const [mainData, setMainData] = useState([]);

    const loadMainData = async () => {
        const r = await ProposalService.statsOfGovAgenda(props.chosenDocument)
        setMainData(r.data);
    }

    useEffect(() => {
        loadMainData();
    }, [props.chosenDocument]);


    const customizeLinkTooltip = (info) => {
        // console.log('info -> ', info);
        return {
            html: `<b>From:</b> ${info.source}<br/><b>To:</b> ${info.target}<br/><b>Weight:</b> ${info.weight}`
        };
    }

    return (
        <div>
            <Sankey id="sankey"
                    dataSource={mainData}
                    sourceField="source"
                    targetField="target"
                    weightField="weight"
            >
                <Tooltip
                    enabled={true}
                    customizeLinkTooltip={customizeLinkTooltip}
                >
                </Tooltip>

                <Link
                    colorMode="gradient">
                </Link>
                <Node
                    width={10}
                    padding={60}>
                </Node>

            </Sankey>
        </div>
    );
};

export default DocumentComponent;
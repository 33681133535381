import React, {useState} from "react"
import {DataGrid} from "devextreme-react";
import Editing, {Column, FilterRow, Form as DataGridForm, Item as DataGridItem, Pager, Paging} from "devextreme-react/data-grid";
import EmailService from "../../services/api/email";
import Button from 'devextreme-react/button'
import AddEmail from "./components/addEmail";
import ViewEmail from "./components/viewEmail";
import CsvDownloader from 'react-csv-downloader';

export default function Email() {

    const [emails, setEmails] = useState([]);
    const allowedPageSizes = [20, 50, 100];
    const [addPopupVisible, setAddPopupVisible] = useState(false);
    const [viewPopupVisible, setViewPopupVisible] = useState(false);
    const [viewEmailId, setViewEmailId] = useState({});

    const getAll = async () => {
        try {
            const res = await EmailService.getAll();

            if (res && res.code === 200) {
                setEmails(res.data);
            }
        } catch (e) {
            console.log(e);
        }
    }

    const showAddEmail = () => {
        setAddPopupVisible(true);
    }

    const showViewEmail = (value) => {
        const showViewPopup = async () => {
            setViewEmailId(value.key);

            const res = await EmailService.getByIdEmail(value.key);

            if (res && res.code === 200) {
                //console.log(res.data);
            }

            setViewPopupVisible(true);
        }

        return <Button onClick={showViewPopup} text="Харах"></Button>
    }

    const handleOnCellPrepared = (e) => {
        if (e.rowType === "header") {
            e.cellElement.style.color = "black"
            e.cellElement.style.backgroundColor = "#E8E8E8"
            // elem.style.setProperty('color', 'green');
        }
        if (e.rowType === "data") {
            e.cellElement.style.color = "black"
            // elem.style.setProperty('color', 'green');
        }
    }

    const csvStyle = {
        backgroundColor: '#5ab0f2',
        color: 'white',
        border: 'none',
        padding: '10px',
        fontSize: '14px',
        borderRadius: '15px',
        float: 'right',
        marginRight: '10px',
        cursor: 'pointer'
    };

    const csvColumns = [
        { id: "id", displayName: "Id" },
        { id: "title_to_csv", displayName: "Title" },
        { id: "content_to_csv", displayName: "Content" },
        { id: "orgs_name_to_csv", displayName: "Organizations" },
        { id: "workers_name_to_csv", displayName: "Workers" },
        { id: "createdAt", displayName: "Created At" },
        { id: "updatedAt", displayName: "Updated At" }
    ];

    useState(() => {
        getAll();
    });

    return (
        <React.Fragment>
            <div className="card">
                <div  style={{ display: "table", width: "100%", padding: "1rem" }}>
                    <h3 style={{ float: "left" }}>Харилцагчид илгээсэн мэдээллийн жагсаалт</h3>
                    <Button text="Имэйл үүсгэх" className="saveButton" onClick={showAddEmail} type="default" style={{ float: "right" }} />
                    <CsvDownloader style={csvStyle} text="Имэйл татах" filename="Emails.csv" datas={emails} columns={csvColumns} separator="," />
                </div>
                <div className="card-body">
                    <DataGrid
                        keyExpr="id"
                        dataSource={emails}
                        showBorders={true}
                        showRowLines={true}
                        showColumnLines={true}
                        hoverStateEnabled={true}
                        style={{flexWrap: "wrap"}}
                        noDataText="Имэйл байхгүй байна."
                        focusedRowEnabled={true}
                        wordWrapEnabled={true}
                        onCellPrepared={handleOnCellPrepared}
                    >
                        <FilterRow visible={true} />
                        <Editing>
                            <DataGridForm>
                                <DataGridItem
                                    dataField="id"
                                    colSpan={2}
                                    dataType="number"
                                />
                                <DataGridItem
                                    dataField="title"
                                    colSpan={2}
                                    editorType="dxTextArea"
                                />
                                <DataGridItem
                                    dataField="createdAt"
                                    colSpan={2}
                                    dataType="date"
                                />
                            </DataGridForm>
                        </Editing>
                        <Paging
                            defaultPageSize={20}
                            defaultPageIndex={0}/>
                        <Pager
                            allowedPageSizes={allowedPageSizes}
                            showPageSizeSelector={true}
                            visible={true}
                            showNavigationButtons={true} />
                        <Column
                            dataField="id"
                            caption="Д/Д"
                            dataType="number"
                            alignment="center"
                            width={50}
                        />
                        <Column
                            dataField="title"
                            caption="Гарчиг"
                            dataType="string"
                            alignment="left"
                        />
                        <Column
                            dataField="orgs_name"
                            caption="Илгээсэн байгууллага"
                            dataType="string"
                            alignment="left"
                        />
                        <Column
                            dataField="workers_name"
                            caption="Илгээсэн ажилтан"
                            dataType="string"
                            alignment="left"
                        />
                        <Column
                            dataField="createdAt"
                            caption="Илгээсэн огноо"
                            dataType="date"
                            alignment="center"
                            format="yyyy-MM-dd HH:mm"
                            width={150}
                        />
                        <Column
                            dataField="id"
                            type="buttons"
                            caption="Үйлдэл"
                            alignment="center"
                            cellRender={showViewEmail}
                        />
                    </DataGrid>
                </div>
            </div>
            <AddEmail
                popupVisible={addPopupVisible}
                setPopupVisible={setAddPopupVisible}
                setEmails={setEmails}
            />
            <ViewEmail
                popupVisible={viewPopupVisible}
                setPopupVisible={setViewPopupVisible}
                emailId={viewEmailId}
                />
        </React.Fragment>
    )
}

import React, {useContext, useEffect, useState, useRef} from "react"
import "devextreme-react/text-area"
import DataGrid, {
  Editing,
  Pager,
  Paging,
  Column,
  FilterRow,
  RequiredRule,
  PatternRule,
  Lookup,
  Export,
  Form,
  Item,
  Popup, Toolbar
} from "devextreme-react/data-grid"
import {Workbook} from "exceljs"
import {saveAs} from "file-saver-es"
import {exportDataGrid} from "devextreme/excel_exporter"
import {LoadPanel} from "devextreme-react/load-panel"
import {UserContext} from "../../../hooks/UserContext"
import _ from "lodash"
import fcsService from "../../../services/api/fcs"
import Button from "devextreme-react/button"
import notify from "devextreme/ui/notify"
import SelectBox from "devextreme-react/select-box"
import {getYears} from "../../../util"
import fcsServices from "../../../services/api/fcs"
import ArrayStore from "devextreme/data/array_store"
import List from "devextreme-react/list"
import LoadPanelComponent from "../../../components/LoadPanelComponent"

function EditArtran(props) {
  const [selectedId, setSelectedId] = useState(1)
  const userListPosition = {of: "#usersList"}
  const {user} = useContext(UserContext)
  const [dataSource, setDataSource] = useState([])
  const [loader, setLoader] = useState(false)
  const myBabyRef = useRef(null)
  const [dataFcsChart, setFcsChart] = useState([])
  const [dataFcsCustOrgn, setFcsCustOrgn] = useState([])
  const [dataFcsApClass, setFcsApClass] = useState([])
  const [dataFcsMonth, setFcsMonth] = useState([])
  const [yearValue, setYearValue] = useState(null)
  const [monthValue, setMonthValue] = useState(null)

  const setDate = async () => {
    const fcsMonth = await fcsService.getAllFcsMonth("AR")
    setFcsMonth(fcsMonth)
    fcsMonth.map((item) => {
      setYearValue(item.year)
      setMonthValue(item.month)
    })
  }

  useEffect(() => {
    setDate()
    fcsAptranList()
  }, [])

  const fcsAptranList = async () => {
    if (yearValue && monthValue) {
      try {
        setLoader(true)
        const result = await fcsServices.getFcsArTranList(
          yearValue,
          monthValue,
          0
        )
        setDataSource(result)
        const fcsChart = await fcsService.getAllFcsChart()
        setFcsChart(fcsChart)
        const fcsApClass = await fcsService.getFcsChart("AR", 0, 0, 0, 0)
        setFcsApClass(fcsApClass)
        const fcsCustOrgn = await fcsService.getAllFcsCustOrgn()
        setFcsCustOrgn(fcsCustOrgn)
        const fcsMonth = await fcsService.getAllFcsMonth("AR")
        setFcsMonth(fcsMonth)

        return setLoader(false)
      } catch (e) {
        console.error(e)
      }
    }
  }
  const itemClickWorld = async (e) => {
    setSelectedId(e.itemData.id)
  }

  const onEditorPreparing = (e) => {
    if (e.parentType === "dataRow" && e.dataField !== "password") {
      return
    }
    if (e.row && e.row.isNewRow) {
      e.editorOptions.visible = true
    }
    if (e.row && !e.row.isNewRow) {
      e.editorOptions.visible = false
    }
  }
  const onInitNewRow = (e) => {
    e.data.unit = 1
    e.data.vat = 0
  }
  const calculateAmt = (newData, value, currentRowData) => {
    newData.qty = value
    newData.amount = currentRowData.iss_cost * value
  }
  const setPurchase = async (e) => {
    if (e.changes && e.changes.length !== 0) {
      //yyyymm
      const date = new Date(e.changes[0].data.docdate) //new e.changes[0].data.docdate;
      const year = date.getFullYear() * 1e4 // 1e4 gives us the the other digits to be filled later, so 20210000.
      const month = (date.getMonth() + 1) * 100
      const yyyymm = year + month // `+ ''` to convert to string from number, 202206
      const fcsCurrentMonth = dataFcsMonth.map((item) => {
        let date = item.year + "/" + item.month
        let datetimenew = new Date(date)
        let year = datetimenew.getFullYear() * 1e4 // 1e4 gives us the the other digits to be filled later, so 20210000.
        let month = (datetimenew.getMonth() + 1) * 100
        const yyyymm_month = year + month
        return yyyymm_month
      })

      if (yyyymm === fcsCurrentMonth[0]) {
        if (e.changes[0].type === "update") {
          try {
            e.changes[0].data.updated_user_id = user.id
            //  e.changes[0].data.acctcr_name = _.filter(dataFcsChart, {acct: e.changes[0].data.acct})[0].descr;
            //  e.changes[0].data.acctdb_name = _.filter(dataFcsApClass, {aracct: e.changes[0].data.aracct})[0].descr;
            e.changes[0].data.custname = _.filter(dataFcsCustOrgn, {
              orgcode: e.changes[0].data.cust,
            })[0].name
            await fcsService.editFcsArtran(e.changes[0].data)
            console.log("artrannnn", e.changes[0].data)
            await fcsAptranList()
            notify("Амжилттай хадгалагдлаа", "success", 2000)
          } catch (e) {
            notify("Засварлалтыг хадгалах явцад алдаа гарлаа", "error", 2000)
          }
        }
      } else {
        notify("Сонгосон огноо тухайн тайлант он сард биш байна", "error", 2000)
      }
    }
  }
  const onExporting = (e) => {
    const workbook = new Workbook()
    const worksheet = workbook.addWorksheet("Main sheet")

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(
          new Blob([buffer], {type: "application/octet-stream"}),
          "Purchase.xlsx"
        )
      })
    })
    e.cancel = true
  }
  const onCellPrepared = (e) => {
    let cell = e.cellElement
    if (e.rowType !== "header" && e.rowType !== "filter") {
      cell.style =
        "color: rgb(20, 23, 106); font-weight: 500; padding: .5rem 0; text-align: center"
    } else if (e.rowType === "header") {
      cell.style = `
        background-color: rgb(238, 240, 244); 
        border: none; padding-top: .875rem; 
        color: rgb(20, 23, 106); 
        text-align: center;
        font-weight: 600; 
        font-size: 12px;
        margin-top: "2rem"
        `
    } else if (e.rowType === "filter") {
      cell.style =
        "background-color: rgb(238, 240, 244); padding: .5rem; border: none; margin-bottom: 1rem"
    }
  }
  return (
    <div className="bankbook">
      <LoadPanelComponent
        shadingColor="rgba(0,0,0,0.4)"
        position={{of: "#password"}}
        showPane={false}
        message=""
      />
      <LoadPanelComponent
        shadingColor="rgba(0,0,0,0.4)"
        position={userListPosition}
        visible={loader}
        showPane={false}
        message=""
      />
      <div id={"firstBox"}>
        <div id={"lawTypeLoad"}>
          <div className="card">
            <DataGrid
            noDataText="Дата байхгүй байна."
              onEditorPreparing={onEditorPreparing}
              ref={myBabyRef}
              dataSource={dataSource}
              onSaved={setPurchase}
              className="datagridBbReport"
              keyExpr="id"
              onInitNewRow={onInitNewRow}
              wordWrapEnabled={true}
              allowColumnResizing={true}
              onExporting={onExporting}
              onCellPrepared={onCellPrepared}
            >
              <FilterRow visible={true} />
              <Toolbar>
                <Item location={"before"} cssClass="datagridToolbarDate" text="Борлуулалт"/>
                <Item
                  location={"after"}
                  cssClass="datagridExportButton"
                  name="exportButton"
                />
                <Item location={"after"} name="searchPanel" />
              </Toolbar>
              <Pager
                showPageSizeSelector={true}
                // showInfo={true}
                showNavigationButtons={true}
              />
              <Column
                dataField="id"
                caption="Дугаар"
                allowEditing={false}
                width={70}
                alignment="center"
                // editCellTemplate = {}
                // cellRender = {customize}
                cssClass="custom"
                allowFiltering={true}
              ></Column>
              <Column
                dataField="agenda_id"
                caption="Төсөл хөтөлбар"
                allowEditing={false}
                cssClass="custom"
                allowFiltering={true}
                width={70}
                alignment="center"
              ></Column>
              <Column
                dataField="event_id"
                caption="Зориулалт"
                allowEditing={false}
                cssClass="custom"
                width={90}
                alignment="center"
                allowFiltering={true}
              ></Column>
              <Column
                dataField="econ_category_id"
                caption="ЭЗА"
                allowEditing={false}
                cssClass="custom"
                width={70}
                alignment="center"
                allowFiltering={true}
              ></Column>
              <Column
                cssClass="custom"
                dataField="docno"
                caption="Журнал №"
                allowEditing={false}
                width={70}
                alignment="center"
                allowCollapsing={false}
                allowFiltering={true}
              ></Column>
              <Column
                cssClass="custom"
                dataField="acctcr"
                width={90}
                alignment="center"
                caption="Данс "
                allowEditing={true}
                allowFiltering={true}
              >
                <RequiredRule message="Данс оруулна уу" />
                <Lookup
                  valueExpr="acct"
                  displayExpr={(item) => {
                    return item && `${item.acct} - ${item.descr}`
                  }}
                  dataSource={dataFcsChart}
                />
              </Column>
              <Column
                cssClass="custom"
                dataField="descr"
                caption="Гүйлгээний утга"
                allowEditing={true}
                width={90}
                alignment="center"
                allowFiltering={true}
              >
                <RequiredRule message="Тайлбар оруулна уу" />
              </Column>
              <Column
                cssClass="custom"
                dataField="docdate"
                caption="Огноо"
                allowEditing={true}
                width={70}
                alignment="center"
                dataType="date"
              >
                <RequiredRule message="Огноо оруулна уу" />
              </Column>
              <Column
                cssClass="custom"
                dataField="amount"
                caption="Нийт дүн"
                allowEditing={false}
                allowEditing={true}
                width={70}
                alignment="center"
                format="#,##0.00"
              ></Column>
              <Column
                cssClass="custom"
                dataField="vat"
                caption="Үүнээс НӨАТ"
                allowEditing={true}
                allowFiltering={true}
                width={70}
                alignment="center"
                format="#,##0.00"
              >
                <PatternRule
                  pattern={/^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/}
                  message="зөвхөн тоо оруулна уу!"
                />
              </Column>
              <Column
                cssClass="custom"
                dataField="cust"
                caption="Харилцагч код"
                allowEditing={true}
                width={90}
                alignment="center"
                allowFiltering={true}
              >
                <RequiredRule message="харилцагч код" />
                <Lookup
                  valueExpr="orgcode"
                  displayExpr={(item) => {
                    return item && `${item.orgcode} - ${item.name}`
                  }}
                  dataSource={dataFcsCustOrgn}
                />
              </Column>
              <Column
                cssClass="custom"
                dataField="acctdb"
                caption="Авлагын данс"
                minWidth={90}
                alignment="center"
                allowEditing={true}
              >
                <RequiredRule message=" уу" />
                <Lookup
                  valueExpr="acct"
                  displayExpr={(item) => {
                    return item && `${item.acct} - ${item.descr}`
                  }}
                  dataSource={dataFcsApClass}
                />
              </Column>
              <Editing
                mode="popup"
                allowUpdating={true}
                allowDeleting={false}
                useIcons={true}
                texts={{
                  cancelAllChanges: "Болих бүгд",
                  cancelRowChanges: "Болих",
                  confirmDeleteMessage:
                    "Энэ худалдан авалт мэдээллийг устгах уу?",
                  confirmDeleteTitle: "Баталгаажуулах",
                  deleteRow: "Устгах",
                  editRow: "Өөрчлөх",
                  saveAllChanges: "Хадгалах бүгд",
                  saveRowChanges: "Хадгалах",
                  undeleteRow: "Буцаах",
                  validationCancelChanges: "Баталгаажуулах болих",
                }}
              >
                <Popup
                  title="Борлуулалт "
                  showTitle={true}
                  width={1000}
                  height={440}
                />
                <Form>
                  <Item itemType="group" colCount={2} colSpan={2}>
                    <Item dataField="docno" />
                    <Item dataField="acctcr" />
                    <Item dataField="docdate" />

                    <Item dataField="amount" />
                    <Item dataField="vat" />
                    <Item dataField="cust" />
                    <Item dataField="acctdb" />
                    <Item dataField="descr" />
                  </Item>
                </Form>
              </Editing>
              <Paging enabled={true} />
              <Export enabled={true} allowExportSelectedData={false} />
            </DataGrid>
          </div>
          <div className="row">
            <div className="col-10">
              <div style={{marginLeft: 20}}>
                <div className="row">
                  {/* <div style={{display: "flex", marginRight: 20}}>
                    <div
                      style={{
                        marginRight: 10,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <span style={{color: "#A3A3A3", marginRight: "1rem"}}>
                        Тайлант он:
                      </span>
                      <span style={{fontWeight: "700"}}>{yearValue}</span>
                    </div>
                  </div>
                  <div style={{display: "flex", marginRight: 20}}>
                    <div
                      style={{
                        marginRight: 10,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <span style={{color: "#A3A3A3", marginRight: "1rem"}}>
                        Тайлант сар:
                      </span>
                      <span style={{fontWeight: "700"}}>{monthValue}</span>
                    </div>
                  </div>
                  <div>
                    <Button
                      icon="search"
                      text="Хайх"
                      onClick={() => fcsAptranList()}
                      width={100}
                      type="default"
                      style={{alignment: "center", borderRadius: "7px"}}
                    />
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EditArtran

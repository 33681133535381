import React, {useState} from "react";
import InventoryReg from "./InventoryReg";

import ExpenseTxn from "./ExpenseTxn";
import InventoryReport from "./InventoryReport";
import InvCategoryReg from "./InvCategoryReg";
import List from "devextreme-react/list";
import ArrayStore from "devextreme/data/array_store";
import styles from "../../myInfo/myInfo.module.css";
import InvLocationReg from "./InvLocationReg";
import InvUnitReg from "./InvUnitReg";
import sideIcon from "../../../assets/imgs/sideIcon.svg";
import IncomeEnter from "./entery/IncomeEnter";
import InOrlineEdit from "./edit/InOrlineEdit";
import InvIssEntery from "./entery/inviss";

import InvissEdit from "./edit/Invissedit";
function ItemTemplate(data) {
    return (
        <div>
            <div className="dx-list-item-icon-container">
                <i className={"dx-icon dx-list-item-icon dx-icon-" + data.icon} />
            </div>
            <span>{data.text}</span>
        </div>
    );
}

export const txnTabs = [
    { id: 1, text: 'Бүртгэл',icon: "box",items: [{id:11,text:'Бараа материалын лавлах ',icon: "cart"}, {id:12,text:'Ангилал',icon: "product"},{id:13,text:'Агуулах',icon: "product"},{id:14,text:'Хэмжих нэгж',icon: "product"}]},
    { id: 2, text: 'Гүйлгээ',icon: "money",items: [{id:21,text:'Орлого ',icon: "plus"}, {id:22,text:'Зарлага ',icon: "minus"}] },
    { id: 4, text: 'Засварлах',icon: "money",items: [{id:41,text:'Орлогын гүйлгээ',icon: "plus"}, {id:42,text:'Зарлагын гүйлгээ',icon: "minus"}] },

    { id: 3, text: 'Тайлан',icon: "textdocument",items: [{id:31,text:'Тайлан',icon: "textdocument"}] }
]
const dataSourceTxn = new ArrayStore({
    key: 'id',
    data: txnTabs,
});
export default function Inventory() {
        const [selectedId, setSelectedId] = useState(11);
        const [collapseMenu, setCollapseMenu] = useState(true);
   const handleListSelectionChange= async (e) => {
        setSelectedId(e.itemData.id);
    }
    const choosePanel = () => {
        switch (selectedId) {
            case 11:
                return InventoryReg;
            case 12:
                return InvCategoryReg;
            case 21:
                return IncomeEnter;
            case 22:
               return InvIssEntery;
            case 23:
                return ExpenseTxn;

            case 41:
                return InOrlineEdit;
            case 42:
                return InvissEdit;

            case 31:
                return InventoryReport;
            case 13:
                return InvLocationReg;
            case 14:
                return InvUnitReg;
        }

        return InventoryReg;
    }
    const getCollapseMenu = async () => {
    setCollapseMenu(!collapseMenu);
    }

    let RightPanelHTML = choosePanel()
        return (
                <div className="baraa_material">
                    <div className="card-header" style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                    }}>

                        <div className={"sideIcon-bnt-style"} onClick={getCollapseMenu}>
                            <span>Дэд цэс</span>
                            <img loading="lazy" src={sideIcon} style={{ width: 'auto', height: '15px',paddingLeft:20 }}/>
                        </div>
                    </div>
                        <div style={{display:"grid","grid-template-columns": "repeat(auto-fit, minmax(245px, 1fr))"}} >
                            {
                                collapseMenu &&
                                <div className={styles.leftContainer}>
                                    <div className="card">
                                        <div style={{textAlign: "left", fontSize: 'inherit'}}>
                                            <List
                                                className={styles.text}
                                                dataSource={dataSourceTxn}
                                                keyExpr="id"
                                                selectionMode="single"
                                                collapsibleGroups={true}
                                                grouped={true}
                                                groupRender={ItemTemplate}
                                                onItemClick={handleListSelectionChange}
                                            />
                                        </div>


                                    </div>
                                </div>
                            }

                                <div className={styles.rightContainer} >
                                    <div className="card" style={{height:"100%"}}>
                                        <RightPanelHTML/>
                                    </div>
                                </div>

                        </div>

                </div>
        );

}

import { useState } from 'react';  

export default function useForm({ initialValues }) {
    const [values, setValues] = useState(initialValues || {});

    const handleChange = event => {
        const value = event.target.value; 
        const userInfo = event.target.name;

        setValues({
            ...values,
            [userInfo]: value
        }); 
    };
 
    return {
        handleChange,
        values,
    }
}
import React, { useContext, useState, useRef } from "react";
import { UserContext } from "../../../hooks/UserContext";
import ScheduleEditReqList from "./ScheduleEditReqList";
import Button from "devextreme-react/button";
import { useHistory } from "react-router-dom";
import ScheduleService from "../../../services/api/scheduleService";
import notify from "devextreme/ui/notify";
import { custom } from "devextreme/ui/dialog";
import moment from "moment";
import DateBox from "devextreme-react/date-box";
import { ScheduleTransferProgressBar } from "./ScheduleTransferProgressBar";
import Joyride from 'react-joyride';
import { HuvaariinUurchlultSteps, guideLocale } from "../../../components/SystemGuideSteps";
import { FaBalanceScale } from "react-icons/fa";
import { FiFileText } from "react-icons/fi";
import { Popup } from "devextreme-react";
import reqDiagram from '../../../../src/assets/imgs/request_diagram.jpg'
import './ScheduleGridList.css'


const ScheduleEditRequest = () => {
    const { user } = useContext(UserContext);
    const history = useHistory();
    const approvePermission = user.permission.some(({ code }) => ["budgetProposalApprove", "scheduleApprove"].includes(code));
    const [currentList, setCurrentList] = useState([]);
    const activeRequest = useRef([])
    const [startDate, setStartDate] = useState(moment().startOf('year').format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(moment().endOf('month').format('YYYY-MM-DD'));
    const [search, setSearch] = useState(false);
    const [disableBtn, setDisableBtn] = useState(false)
    const [processPopup, setProcessPopup] = useState(false)

    // Tuhain sard huselt uusgesen eseh
    // if (currentList.length > 0) {
    //     activeRequest.current = currentList.filter((e) => e.createdAt.includes(moment().format('YYYY-MM')))
    // }

    // useEffect(() => {
    //     user.organization.obt_id !== 1 && ScheduleService.checkParentReq()
    //         .then((res) => {
    //             if (res.length > 0) {
    //                 let filtered = res.filter((e) => e.createdAt.includes(moment().format('YYYY-MM')));
    //                 setDisableBtn(filtered.length > 0 ? filtered.every(e => ![2, 3].includes(e.status)) : false)
    //             }
    //         }).catch((err) => {
    //             console.log(err)
    //         });
    // }, [])

    const openRequest = async () => {
        if (activeRequest.current.length === 0) {
            let myDialog = custom({
                title: "Баталгаажуулалт",
                messageHtml: `<i>Та шинэ хүсэлт үүсгэхдээ итгэлтэй байна уу?</i>`,
                buttons: [{
                    type: "default",
                    text: "Тийм",
                    onClick: (e) => {
                        return { buttonText: e.component.option("text") }
                    }
                },
                {
                    text: "Үгүй",
                    onClick: (e) => {
                        return { buttonText: e.component.option("text") }
                    }
                },
                ]
            });
            myDialog.show().then(async (dialogResult) => {
                if (dialogResult && dialogResult.buttonText === 'Тийм') {
                    await ScheduleService.createRequest()
                        .then((res) => {
                            console.log(res.data.req.id, user.org_id)
                            history.push(`/plan/schedule/insertion/${res.data.req.id}/${user.org_id}/1`);
                        })
                        .catch((err) => {
                            console.log(err)
                            notify({ message: `Алдаа гарлаа!`, width: 'fit-content' }, "warning", 3000)
                        })
                }
            })
        }
    }

    const returnInfo = () => {
        if (!moment().isBetween(moment().startOf('month').startOf('day'), moment().date(24).endOf('day'))) {
            return <div>Энэ сарын хуваарийн өөрчлөлт хийх хугацаа дууссан байна.</div>
        } else if (activeRequest.current.length > 0) {
            return <div>Танд идэвхтэй хүсэлт байна.</div>
        } else if (disableBtn) {
            return <div> Дараагийн шатны төсвийн захирагч хуваарийн өөрчлөлтийн хүсэлтээ нэгтгэж дууссан байна. </div>
        }
    }

    return (
        <div>
            <Joyride
                run={true}
                steps={HuvaariinUurchlultSteps()}
                continuous
                showSkipButton
                styles={{ options: { zIndex: 100, display: 'none' } }}
                locale={guideLocale}
                disableScrolling={true}
            />
            <div style={{ display: 'flex', margin: 10, justifyContent: 'space-between', flexWrap: 'wrap', alignItems: 'end' }}>
                <div style={{ display: 'flex', gap: 10, alignItems: 'end' }}>
                    <DateBox value={startDate} type="date" onValueChanged={(e) => setStartDate(moment(e.value).format('YYYY-MM-DD'))} label="Эхлэх огноо" />
                    <DateBox value={endDate} type="date" onValueChanged={(e) => setEndDate(moment(e.value).format('YYYY-MM-DD'))} label="Дуусах огноо" />
                    <Button icon="search" type="default" onClick={() => setSearch(!search)} />
                </div>
                <div style={{ display: 'flex', gap: 10, alignItems: 'center' }}>
                    {user.organization.obt_id !== 1 && returnInfo()}
                    {(user.roles.some(e => e.role_code === '32') || !approvePermission) &&
                        <Button id='createSchedReq' type='default' text='Хүсэлт үүсгэх' width='9rem' style={{ fontWeight: '550', cursor: 'help' }}
                            hint={"ТЕЗ-д тухайн сарын 1-нээс 25-ныг хүртэл хуваарийн өөрчлөлт илгээх боломжтой."}
                            // disabled={
                            //     activeRequest.current.length > 0 || disableBtn || (user.organization.obt_id !== 1 && !moment().isBetween(moment().startOf('month').startOf('day'), moment().date(24).endOf('day')))
                            // }
                            onClick={() => openRequest()}
                        />
                    }
                    <Button hint="Хуваарийн өөрчлөлтийн ерөнхий процессийн зураглал" onClick={() => setProcessPopup(true)} render={() => <FiFileText color="#1a1976" fontSize={17} />} />
                    <Button hint="Төсвийн захирагчийн төсөвт зохицуулалт хийх журам" onClick={() => window.open("https://legalinfo.mn/mn/detail?lawId=208573")} render={() => <FaBalanceScale color="#1a1976" fontSize={16} />} />
                </div>
            </div>
            <ScheduleTransferProgressBar currentStatus={activeRequest.current[0]?.status || 0} />
            <div>
                <ScheduleEditReqList
                    currentOrg={true}
                    statusList={(data) => { if (data) { setCurrentList(data) } }}
                    startDate={startDate}
                    endDate={endDate}
                    search={search} />
            </div>
            {user.organization.obt_id !== 3 && !approvePermission &&
                <div>
                    <div style={{ marginLeft: 10, fontWeight: 'bold' }}>Харьяа байгууллагаас ирүүлсэн хүсэлтүүд</div>
                    <div>
                        <ScheduleEditReqList
                            currentOrg={false}
                            startDate={startDate}
                            endDate={endDate}
                            search={search}
                            parentId={0}
                            isNew='new' />
                    </div>
                </div>
            }
            {
                <Popup
                    visible={processPopup}
                    title="Хуваарийн өөрчлөлтийн ерөнхий процессийн зураглал"
                    onHiding={() => setProcessPopup(false)}
                    width={'100vw'}
                    height={'auto'}>
                    <img src={reqDiagram} alt="" width={'100%'} height={'100%'} />
                </Popup>
            }
        </div>

    )

}

export default ScheduleEditRequest
import React, {useContext, useEffect, useState, useRef} from 'react';

import 'devextreme-react/text-area';
import 'devextreme-react/text-area';
import {
    TreeList, Editing, Column, Lookup, FilterRow, Selection,
} from 'devextreme-react/tree-list';
import {LoadPanel} from "devextreme-react/load-panel";
import notify from 'devextreme/ui/notify';
import {UserContext} from "../../../../hooks/UserContext";
import fcsService from "../../../../services/api/fcs";
import _ from "lodash";
import {RequiredRule} from "devextreme-react/data-grid";
import LoadPanelComponent from '../../../../components/LoadPanelComponent';

function ChartParent(props) {
    const userListPosition = {of: '#usersList'}
    const {user} = useContext(UserContext);
    const [dataSource, setDataSource] = useState([]);
    const [loader, setLoader] = useState(false);
    const [popupVisible, setPopupVisible] = useState(false);

    const [lookUpListAgenda, setLookUpListAgenda] = useState([]);
    const myBabyRef = useRef(null);
    useEffect(() => {
        agendaFetch();
    }, [])

    const agendaFetch = async () => {
        try {
            setLoader(true);
            const result = await fcsService.getAllFcsChartEdit();
            result.map(e => {

            })
            let r = await _.filter(result, {head: 1});
            setLookUpListAgenda(r);
            setDataSource(result)
            return setLoader(false)
        } catch (e) {
            console.error(e);
        }
    }
    const setAgenda = async (e) => {
        if (e.changes && e.changes.length !== 0) {
            if (e.changes[0].type === "update") {
                try {

                    if (e.changes[0].data.id != e.changes[0].data.parent_id) {
                        await fcsService.editFcsChart(e.changes[0].data)
                        await agendaFetch()
                        notify('Амжилттай хадгалагдлаа', "success", 2000)
                    } else {
                        notify('Өөрийгөө сонгосон', "error", 2000);
                        await agendaFetch()
                    }



                } catch (e) {
                    notify('Засварлалтыг хадгалах явцад алдаа гарлаа', "error", 2000);
                }
            } else if (e.changes[0].type === "insert") {
                try {
                    delete e.changes[0].data.id;
                    e.changes[0].data.org_id = user.org_id;
                    await fcsService.addFcsChart(e.changes[0].data);
                    await agendaFetch();
                    notify('Амжилттай үүслээ', "success", 2000);
                } catch (e) {
                    notify('Шинээр үүсэх явцад алдаа гарлаа', "error", 2000);
                }

            }  else if (e.changes[0].type === "remove") {
                try {

                    await fcsService.removeFcsCustOrgn(e.changes[0].key);
                    await agendaFetch();

                    notify('Амжилттай устгагдлаа', "success", 2000);
                } catch (e) {
                    notify('Устгах явцад алдаа гарлаа', "error", 2000);
                }

            }
        }

    }
    //Column custom renderings
    const statusRender = (data) => {
        return (data.value === 1 ? <div style={{
            backgroundColor: '#50CB93',
            width: '80%',
            color: 'white',
            borderRadius: '3px',
            cursor: 'pointer',
            margin: 'auto',
            textAlign: 'center'
        }}>Идэвхтэй</div> : <div style={{
            backgroundColor: '#FF616D',
            width: '80%',
            color: 'white',
            borderRadius: '3px',
            cursor: 'pointer',
            margin: 'auto',
            textAlign: 'center'
        }}>Идэвхгүй</div>)
    }
    const onEditorPreparing = (e) => {
        if (e.parentType === "dataRow") {
            return;
        }
        if (e.row && e.row.isNewRow) {
            e.editorOptions.visible = true;
        }
        if (e.row && !e.row.isNewRow) {
            e.editorOptions.visible = false;
        }
    }
    const orderRender = (data) => {
        return <div style={{color: '#171010'}}>{data.rowIndex + 1}</div>;
    }

    const initNewRowEvent = (e) => {
        let r;
        if (e.data.parent_id === 0) {
            r = [];
        } else {
            r = _.filter(dataSource, {id: e.data.parent_id});
        }
        //  setLookUpListAgenda(r);
    }

    const onEditingStartEvent = (e) => {
        let r;
        if (e.data.parent_id) {
            r = _.filter(dataSource, {id: e.data.parent_id});
        }
        //   setLookUpListAgenda(r);
    }

    return (
        <div>
            <LoadPanelComponent
                shadingColor="rgba(0,0,0,0.4)"
                position={{of: '#password'}}
                showPane={false}
                visible={popupVisible}
                message = ''
            />
            <div>
                <div className="card-body">
                    <TreeList
                        dataSource={dataSource}
noDataText='Дата байхгүй байна.'
                        keyExpr="id"
                        parentIdExpr="parent_id"
                        allowColumnResizing={true}
                        onEditorPreparing={onEditorPreparing}
                        ref={myBabyRef}
                        onSaved={setAgenda}
                        onEditingStart={onEditingStartEvent}
                        onInitNewRow={initNewRowEvent}
                    >
                        <FilterRow visible={true} />
                        <Selection mode="single" />
                        <Editing
                            mode="row"
                            allowUpdating={true}
                            // allowAdding={true}
                            //  allowDeleting={true}
                            confirmDelete={true}
                            useIcons={true}
                            confirmSave={true}
                            allowAdding={true}
                            texts={{
                                cancelAllChanges: 'Болих бүгд',
                                cancelRowChanges: 'Болих',
                                confirmDeleteMessage: 'Энэ хөтөлбөрийн мэдээллийг устгах уу?',
                                confirmDeleteTitle: 'Баталгаажуулах',
                                deleteRow: 'Устгах',
                                editRow: 'Өөрчлөх',
                                saveAllChanges: 'Хадгалах бүгд',
                                saveRowChanges: 'Хадгалах',
                                undeleteRow: 'Буцаах',
                                validationCancelChanges: 'Баталгаажуулах болих',
                            }}
                        />
                        <Column
                            cssClass="custom"
                            dataField="id"
                            caption=""
                            allowEditing={false}
                            allowCollapsing={false}
                            allowFiltering={true}
                            width={160}
                            //  cellRender = {dateRender}
                        >

                        </Column>





                        <Column
                            cssClass="custom"
                            dataField="acct"
                            caption="Код"
                            allowEditing={true}
                            allowCollapsing={false}
                            allowFiltering={true}
                            width={160}
                            //  cellRender = {dateRender}
                        >
                            <RequiredRule
                                message = 'Код оруулна уу'
                            />
                        </Column>
                        <Column
                            dataField="descr"
                            caption="нэр"
                            allowEditing={true}
                            // editCellTemplate = {}
                            //   cellRender = {dateRender}
                            cssClass="custom"
                            allowFiltering={true}
                            width={250}
                        >
                            <RequiredRule
                                message = ' нэр оруулна уу'
                            />
                        </Column>

                        <Column
                            dataField="parent_id"
                            caption="бүлэг">
                            width={100}

                            <Lookup
                                allowClearing={true}
                                dataSource={lookUpListAgenda}
                                valueExpr="id"
                                displayExpr="descr"/>
                        </Column>
                        <Column
                            cssClass="custom"
                            dataField="head"
                            dataType={'string'}
                            caption="дэд/бүлэг"
                            width={100}
                            allowEditing={true}
                            allowFiltering={true}
                        >
                            {/*<RequiredRule*/}
                            {/*    message='Регистрийн дугаар оруулна уу'*/}
                            {/*/>*/}
                        </Column>

                        <Column
                            cssClass="custom"
                            dataField="accttype"
                            dataType={'string'}
                            caption="төрөл"
                            width={100}
                            allowEditing={true}
                            allowFiltering={true}
                        >
                            {/*<RequiredRule*/}
                            {/*    message='Регистрийн дугаар оруулна уу'*/}
                            {/*/>*/}
                        </Column>


                        {/*<Paging enabled={true} />*/}
                    </TreeList>
                </div>
            </div>

        </div>
    )
}

export default ChartParent;

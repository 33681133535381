import React, {useState, useContext, Component} from "react";
import {
    DataGrid, Column
} from 'devextreme-react/data-grid';
import { Button } from 'devextreme-react'
import "./dynamicTable.css"
import dynamicTableService from "../../services/api/dynamicTable";
import {Link, useHistory} from "react-router-dom";

class dynamicTableDataList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dynamicTableList: [],
        };
    }

    componentDidMount() {
        // console.log('componentDidMount', this.props.match.params.dynamicTableId, this.props.match.params.dynamicTableName);
        this.getDynamicTableDataList(this.props.match.params.dynamicTableId);
    }

    getDynamicTableDataList = async (templateId) => {
        let res = await dynamicTableService.getDynamicTableDataList(templateId);
        // console.log('res', res)
        if (res && res.code === 200) {
            this.setState({dynamicTableList: res.data || []});
        } else {
            this.setState({dynamicTableList: []});
        }
    }

    detailData = (e) => {
        console.log('detail data', e.row.data);
    };

    buttonRender = (item) => {
        let {data} = item;
        return <Link to={`/dynamicTable/${this.props.match.params.dynamicTableId}/${this.props.match.params.dynamicTableName}/${data.id}/data/detail`}>
            Дэлгэрэнгүй
        </Link>;
    }

    createTemplateData = () => {
        this.props.history.push(`/dynamicTable/${this.props.match.params.dynamicTableId}/${this.props.match.params.dynamicTableName}/data/create`)
    }

    render() {
        return <div className={"dynamicTable-container"}>
            <h4>Table template ( {this.props.match.params.dynamicTableName} ) data list</h4>

            <div className={'card'}>
                <div className={'card-body'}>
                    <Link to={'/dynamicTable'}>Template table list</Link> / <span>Data list</span>
                </div>
            </div>

            <div className={'card'}>
                <div className={'card-body'}>
                    <Button
                        text="Contained"
                        type="default"
                        stylingMode="contained"
                        onClick={() => this.createTemplateData()}>Insert data</Button>
                    <br/><br/>
                    <DataGrid
                        id="dataGrid"
                        dataSource={this.state.dynamicTableList}
                        showBorders={true}
                    >
                        <Column dataField={'id'} caption={'№'} width={50} cellRender={(data) => {
                            return <div>{data.rowIndex+1}</div>
                        }}/>
                        <Column dataField={'name'} caption={'Нэр'}/>
                        <Column dataField={'updatedAt'} width={150} dataType={'datetime'} format={'yyyy/MM/dd HH:mm:ss'} caption={'Засвар хийсэн огноо'}/>
                        <Column dataField={'updatedBy'} width={150} caption={'Засвар хийсэн'}/>
                        <Column dataField={'createdAt'} width={150} dataType={'datetime'} format={'yyyy/MM/dd HH:mm:ss'} caption={'Үүсгэсэн огноо'}/>
                        <Column dataField={'createdBy'} width={150} caption={'Үүсгэсэн'}/>
                        <Column width={90} cellRender={this.buttonRender}/>
                    </DataGrid>
                </div>
            </div>
        </div>
    }
}

export default dynamicTableDataList;

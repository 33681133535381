import React, { Component } from "react";
import budgetCalculationService from "../../services/api/budgetCalculation";
import './budgetCalculation.css';
import dynamicTableService from "../../services/api/dynamicTable";
import TableTemplate from "./tableTemplate";
import {
    SelectBox,
    TextBox,
    Button,
} from "devextreme-react";
import TabPanel from 'devextreme-react/tab-panel';
import Accordion, { Item } from "devextreme-react/accordion"
import infoIcon from '../../assets/imgs/icon/iconInfo.png';
import {SizeMe} from "react-sizeme";
import {
    Chart,
    Series,
    CommonSeriesSettings,
    Label,
    Format,
    Legend,
    Export,
} from 'devextreme-react/chart';
import Notify from "devextreme/ui/notify";
import { Popup, Position, ToolbarItem } from 'devextreme-react/popup';

class BudgetCalculation extends Component {
    constructor(props) {
        super(props);

        this.state = {
            economyList: [],
            economyListByParent: {},
            economyId: null,
            economyName: '',
            economyParentList: [],
            economyParentCodeGroup: {},
            selectedEcon: null,
            selectedEcon1: null,
            selectedEcon2: null,
            selectedEcon3: null,
            header: [],
            leftHeader: [],
            template: {},
            formattedTopHeader: [],
            formattedLeftHeader: [],
            leftTotalColSpan: 0,
            econ_category_id: null,
            econ_category_code: null,
            econ_category_amounts: {},
            econStat: {},
            initialData: {},
            budgetCalculationTable: [],
            selectedTab: 1,
            selectedAccordion: null,
            showType: 'table',
            resultData: {},
            searchEconCategoryIncome: '',
            searchEconCategoryExpense: '',
            budgetCalcStatusList: [],
            budgetCalcStatusIncomeId: null,
            budgetCalcStatusExpenseId: null,
            budgetCalcCalendarDay: -1,
            budgetCalcCalendarDate: '',
            incomeAccordionSelectedIndex: null,
            expenseAccordionSelectedIndex: null,
            loadingToBudget: false,
            toBudgetPopup: false,
            projectStatusList: [],
            toBudgetList: [],
        }
    }

    componentDidMount() {
        this.getAllData();
        // this.getInitialData();
        // this.getBudgetEconomyList();
        // this.getBudgetParent();
        // this.getBudgetSumValues();
        // this.getBudgetStats();
        // this.getBudgetCalculationTable();
        // this.getBudgetCalcByMonth();
        // this.getBudgetCalcStatusList();
    }

    getAllData = async () => {
        let initialDataService = budgetCalculationService.getInitialData();
        let economyListByParentService = budgetCalculationService.getEconCategoryList();
        let econCategoryAmountsService = budgetCalculationService.getBudgetSumValues();
        let econStatService = budgetCalculationService.getBudgetCalculationStat();
        let budgetCalculationTableService = budgetCalculationService.getBudgetCalculationTable();
        let resultDataService = budgetCalculationService.getBudgetCalcByMonth();
        let budgetCalcStatusListService = budgetCalculationService.getBudgetCalcStatusList();
        // let calendarService = await budgetCalculationService.getBudgetCalcCalendar();
        let projectStatusService = budgetCalculationService.getProjectCheck();
        let toBudgetListService = budgetCalculationService.getResultToBudgetList();

        let services = [initialDataService, economyListByParentService, econCategoryAmountsService, econStatService, budgetCalculationTableService, resultDataService, budgetCalcStatusListService, projectStatusService, toBudgetListService];
        let responses = await Promise.all(services);

        let initialData = [];
        let economyListByParent = {};
        let econ_category_amounts = {};
        let econStat = {};
        let budgetCalculationTable = [];
        let resultData = {};
        let budgetCalcStatusList = [];
        // let budgetCalcCalendarDay = 0;
        let budgetCalcCalendarDate = '';
        let projectStatusList = [];
        let toBudgetList = [];

        if (responses[0].code === 200) {
            initialData = responses[0].data || [];
        }

        if (responses[1].code === 200) {
            economyListByParent = responses[1].data || {};
        }

        if (responses[2].code === 200) {
            let data = responses[2].data || [];
            econ_category_amounts = data.reduce(
                (obj, item) => Object.assign(obj, { [item.econ_category_id]: {
                        amount: item.amount*1,
                        budget_calc_status: item.budget_calc_status
                    } }), {});
        }

        if (responses[3].code === 200) {
            econStat = responses[3].data || {};
        }

        if (responses[4].code === 200) {
            budgetCalculationTable = responses[4].data || [];
        }

        if (responses[5].code === 200) {
            resultData = responses[5].data || {};
        }

        if (responses[6].code === 200) {
            budgetCalcStatusList = responses[6].data || [];
        }

        if (responses[7].code === 200) {
            projectStatusList = responses[7].data || [];
        }

        if (responses[8].code === 200) {
            toBudgetList = responses[8].data || [];
        }

        // if (responses[7].code === 200) {
        //     let data = responses[7].data || {};
        //
        //     let oneDay = 24*60*60*1000;
        //     let firstDate;
        //     let secondDate;
        //     try {
        //         firstDate = new Date(this.state.initialData.date);
        //         secondDate = new Date(data.end_date);
        //     } catch (e) {
        //
        //     }
        //
        //     console.log()
        //
        //     budgetCalcCalendarDay = Math.round((secondDate-firstDate)/oneDay);
        //     budgetCalcCalendarDate = secondDate;
        // }

        this.setState({
            initialData,
            economyListByParent,
            econ_category_amounts,
            econStat,
            budgetCalculationTable,
            resultData,
            budgetCalcStatusList,
            // budgetCalcCalendarDay,
            budgetCalcCalendarDate,
            projectStatusList,
            toBudgetList
        }, () => {
            this.getBudgetCalcCalendar();
        })
    }

    getBudgetCalcCalendar = async () => {
        let res = await budgetCalculationService.getBudgetCalcCalendar();
        if (res.code === 200) {
            // console.log('getBudgetCalcCalendar', res.data);
            let data = res.data || {};

            let oneDay = 24*60*60*1000;
            let firstDate;
            let secondDate;
            try {
                firstDate = new Date(this.state.initialData.date);
                secondDate = new Date(data.end_date);
            } catch (e) {

            }

            let leftDay = Math.round((secondDate-firstDate)/oneDay);
            this.setState({budgetCalcCalendarDay: leftDay, budgetCalcCalendarDate: secondDate})
        } else {
            this.setState({budgetCalcCalendarDay: -1, budgetCalcCalendarDate: ''})
        }
    }

    getBudgetCalcStatusList = async () => {
        let res = await budgetCalculationService.getBudgetCalcStatusList();
        if (res.code === 200) {
            // console.log('getBudgetCalcStatusList',res.data)
            this.setState({budgetCalcStatusList: res.data || []});
        } else {
            this.setState({budgetCalcStatusList: []});
        }
    }

    getBudgetCalcByMonth = async () => {
        let res = await budgetCalculationService.getBudgetCalcByMonth();
        // console.log('getBudgetCalcByMonth', res)
        if (res.code === 200) {
            this.setState({resultData: res.data || {}});
        } else {
            this.setState({resultData: {}});
        }
    }

    getBudgetCalculationTable = async () => {
        let res = await budgetCalculationService.getBudgetCalculationTable();
        // console.log('getBudgetCalculationTable', res);
        if (res.code === 200) {
            this.setState({budgetCalculationTable: res.data || []});
        } else {
            this.setState({budgetCalculationTable: []});
        }
    }

    getInitialData = async () => {
        let res = await budgetCalculationService.getInitialData();
        if (res.code === 200) {
            this.setState({
                initialData: res.data || {}
            });
            this.getBudgetCalcCalendar();
        } else {
            this.setState({
                initialData: {}
            });
        }
    }

    getBudgetStats = async () => {
        let res = await budgetCalculationService.getBudgetCalculationStat();
        if (res.code === 200) {
            this.setState({
                econStat: res.data || {}
            })
        } else {
            this.setState({
                econStat: {}
            })
        }
        // console.log('getBudgetStats', res);
    }

    getBudgetEconomyList = async () => {
        let res = await budgetCalculationService.getEconCategoryList();
        if (res.code === 200) {
            // console.log('getEconCategoryList', res.data);
            this.setState({
                economyListByParent: res.data || {}
                // economyList: res.data || []
            });
        } else {
            this.setState({
                economyListByParent: {}
                // economyList: []
            });
        }
    };

    getToBudgetList = async () => {
        let res = await budgetCalculationService.getResultToBudgetList();
        if (res.code === 200) {
            this.setState({
                toBudgetList: res.data || []
            })
        } else {
            this.setState({
                toBudgetList: []
            })
        }}


    getBudgetParent = async (parent) => {
        let res = await budgetCalculationService.getEconomyParent(parent);
        if (res.code === 200) {
            let economyParentCodeGroup = Object.groupBy(res.data, ({parent_code}) => parent_code);
            this.setState({
                economyParentList: res.data || [],
                economyParentCodeGroup
            });
        } else {
            this.setState({
                economyParentList: [],
                economyParentCodeGroup: {}
            })
        }
    }

    getBudgetSumValues = async () => {
        let res = await budgetCalculationService.getBudgetSumValues();
        // console.log('res', res);
        if (res.code === 200) {
            let data = res.data || [];

            let object = data.reduce(
                (obj, item) => Object.assign(obj, { [item.econ_category_id]: {
                        amount: item.amount*1,
                        budget_calc_status: item.budget_calc_status
                    } }), {});

            this.setState({
                econ_category_amounts: object
            }, () => {
                this.setState({loadingToBudget: false});
            });
        } else {
            this.setState({econ_category_amounts: {}}, () => {
                this.setState({loadingToBudget: false});
            });
        }
    }

    getAmount = (amount) => {
        // console.log('getAmount', amount)
        let fixedAmount = amount.toFixed(2);
        return this.numberWithCommas(fixedAmount);
    };

    addBudgetCalc = () => {
        // console.log('addBudgetCalc');
        this.props.history.push('/budgetCalculation/'+this.state.economyId, {
            name: this.state.economyName
        });
    }

    getTemplate = async (econId, econCode) => {
        let response = await dynamicTableService.getDynamicTableByEcon(econId);
        // console.log('response', response);
        if (response.code === 200) {
            let data = response.data || {};
            let mainHeader = data.header || {};
            let header = mainHeader.header || [];
            let leftHeader = mainHeader.leftHeader || [];
            // console.log('getTemplate', data, mainHeader, header, leftHeader);
            this.setState({
                header,
                leftHeader,
                template: data,
                econ_category_id: econId,
                econ_category_code: econCode,
            })
        } else {
            this.setState({
                header: [],
                leftHeader: [],
                template: {},
                econ_category_id: null,
                econ_category_code: null
            });
        }
    }

    selectAccordion = (e) => {
        console.log('selectAccordion',e)
        // this.setState({
        //     selectedAccordion:
        // })
    }

    accordionTitle = (data) => {
        return <div style={{fontSize: 14}}>{data.econ_category_name}</div>
    }

    // accordionItem = (data) => {
    //     // console.log('accordionItem', data, data.econ_category_id, data.econ_category_code);
    //     this.getTemplate(data.econ_category_id, data.econ_category_code)
    //     return <div style={{overflow: 'auto'}}>
    //         <SizeMe>{({ size }) => <TableTemplate width={size.width} template={this.state.template} econ_category_id={this.state.econ_category_id} econ_category_code={this.state.econ_category_code} savedData={() => this.getBudgetSumValues()}/>}</SizeMe>
    //     </div>
    // }

    numberWithCommas = (x) => {
        // console.log('numberWithCommas', x)
        let parts = x.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return parts.join(".");
    }

    selectRender = (data) => {
        let statusColor;
        switch (data.id) {
            case 1: statusColor = '#CDEBFF'; break;
            case 2: statusColor = '#F99D4B'; break;
            case 3: statusColor = '#2C77FF'; break;
            default: statusColor = '#CDEBFF'; break;
        }
        return <div style={{display: 'flex', gap: 10, alignItems: 'center'}}>
            <div style={{width: 4, height: 40, background: statusColor}}/>
            {data.name}
        </div>
    }

    onBudgetStatusIncomeSelected = (e) => {
        // console.log('onBudgetStatusIncomeSelected',e.value);
        this.setState({
            budgetCalcStatusIncomeId: e.value
        })
    }

    onBudgetStatusExpenseSelected = (e) => {
        // console.log('onBudgetStatusExpenseSelected',e.value);
        this.setState({
            budgetCalcStatusExpenseId: e.value
        })
    }

    onSelectionChangeEconCategoryIncome = (e) => {
        console.log(e.selectedItem);
    }

    onSelectionChangeEconCategoryExpense = (e) => {
        console.log(e.selectedItem);
    }

    groupRender = (e, index) => {
        return e.key;
    }

    itemRender = (e, index) => {
        return index + 1 + '. ' + e.econ_category_code + ' - ' + e.econ_category_name;
    }

    budgetCalcToBudget = async () => {
        this.setState({loadingToBudget: true, toBudgetPopup: false});
        let response = await budgetCalculationService.toBudget();
        this.getBudgetSumValues();
        this.getBudgetStats();

        if (response.code === 200) {
            Notify({
                message: 'Тооцооллыг төсөлрүү татлаа',
                width: 300,
                type: 'success',
            }, {
                position: {
                    top: 90,
                    left: undefined,
                    bottom: undefined,
                    right: 30,
                },
                direction: 'down-push'
            });
        } else {
            console.log('error')
            Notify({
                message: 'Тооцооллыг төсөлрүү татхад алдаа гарлаа',
                width: 300,
                type: 'warning',
            }, {
                position: {
                    top: 90,
                    left: undefined,
                    bottom: undefined,
                    right: 30,
                },
                direction: 'down-push'
            });
        }
        this.setState({loadingToBudget: false});
    }

    render() {
        let budgetCalculationObject = this.state.econStat['budgetCalculationCount'] || {};
        let budgetCalculationIncomeCount = budgetCalculationObject['1'] || 0;
        let budgetCalculationExpenseCount = budgetCalculationObject['2'] || 0;

        let eventCount = this.state.econStat['eventCount'] || 0;
        let agendaCount = this.state.econStat['agendaCount'] || 0

        let budgetCalculationMakeCount = this.state.econStat['budgetCalculationMakeCount'] || 0;

        let lastUpdatedInfoObject = this.state.econStat['updatedInfo'] || {};
        let lastCalculatedInfoObject = this.state.econStat['calculatedInfo'] || {};

        let lastUpdatedDate;
        if (lastUpdatedInfoObject['last_updated_date']) {
            try {
                let lastUpdatedDateRaw = new Date(lastUpdatedInfoObject['last_updated_date']);
                lastUpdatedDate = `${lastUpdatedDateRaw.getFullYear()}-${(lastUpdatedDateRaw.getMonth()+1).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})}-${lastUpdatedDateRaw.getDate().toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})}`;
            } catch (e) {
                console.log('parseError', e);
            }
        }

        let lastUpdatedUser = lastUpdatedInfoObject['updated_user_last_name'] ? `${lastUpdatedInfoObject['updated_user_last_name'].slice(0,1)}.${lastUpdatedInfoObject['updated_user_first_name']}` : lastUpdatedInfoObject['updated_user_first_name'];

        let lastCalculatedDate = lastCalculatedInfoObject['calculated_date'] || '';
        let lastCalculatedUser = lastCalculatedInfoObject['calculated_user_last_name'] ? `${lastCalculatedInfoObject['calculated_user_last_name'].slice(0,1)}.${lastCalculatedInfoObject['calculated_user_first_name']}` : lastCalculatedInfoObject['calculated_user_first_name'];

        //TODO: budget calculation upload info toxiruulax (budget calculation upload bolson ued)
        let budgetUploadDate;
        let budgetUploadUser;
        let budgetCalculationleftDay = this.state.budgetCalcCalendarDay > 0 ? this.state.budgetCalcCalendarDay : 0;

        let budgetCalcCalendarDate;
        let bccd = this.state.budgetCalcCalendarDate;
        if (bccd) {
            try {
                let year = bccd.getFullYear();
                let month = (bccd.getMonth()+1).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false});
                let date = (bccd.getDate()).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false});
                budgetCalcCalendarDate = `${year}.${month}.${date}`;
            } catch (e) {
                budgetCalcCalendarDate = '';
            }
        }

        let resultData = this.state.resultData;
        // console.log('resultData', resultData)
        let resultIncomeByMonth = resultData['resultIncomeByMonth'] || {};
        let resultExpenseByMonth = resultData['resultExpenseByMonth'] || {};
        let resultIEGraph = [];
        let resultIncomeTotalYear = 0;
        let resultExpenseTotalYear = 0;

        for (let i=1; i<=12; i++) {
            resultIncomeTotalYear += (resultIncomeByMonth[`${i}`] || 0);
            resultExpenseTotalYear += (resultExpenseByMonth[`${i}`] || 0);
            resultIEGraph.push({
                month: `${i} сар`,
                incomeValue: resultIncomeByMonth[`${i}`] || 0,
                expenseValue: resultExpenseByMonth[`${i}`] || 0
            })
        }

        // console.log('resultIEGraph', resultIEGraph)

        // resultIncomeTotalYear = resultIncomeTotalYear.toFixed(2);
        // resultExpenseTotalYear = resultExpenseTotalYear.toFixed(2);

        // console.log(resultIncomeTotalYear, resultExpenseTotalYear);

        let budgetCalculation = this.state.budgetCalculationTable;
        // console.log('budgetCalculation', budgetCalculation, this.state.econ_category_amounts);
        budgetCalculation.map(v => {
            let econ_category_data = this.state.econ_category_amounts[v.econ_category_id] || {};
            // console.log('econ_category_data',econ_category_data)
            v.amount = econ_category_data.amount || 0;
            v.budget_calc_status = econ_category_data.budget_calc_status || 1;
        });

        let projectStatusList = this.state.projectStatusList;

        let budgetCalculationIncome = budgetCalculation.filter((budgetCalc) => budgetCalc.main_parent_code === '1');
        let budgetCalculationExpense = budgetCalculation.filter((budgetCalc) => budgetCalc.main_parent_code === '2');
        // console.log(budgetCalculation);

        let budgetCalcToBudgetDisable;
        console.log('this.state.budgetCalcCalendarDay', this.state.budgetCalcCalendarDay, this.state.loadingToBudget)
        if (this.state.loadingToBudget || this.state.budgetCalcCalendarDay < 0) {
            budgetCalcToBudgetDisable = true;
        } else {
            budgetCalcToBudgetDisable = !budgetCalculation.filter((budgetCalc) => budgetCalc.budget_calc_status === 2).length > 0;
        }

        if (projectStatusList.length === 0) {
            budgetCalcToBudgetDisable = true;
        }


        if (this.state.searchEconCategoryIncome) {
            let searchVal = this.state.searchEconCategoryIncome;
            let search = new RegExp(searchVal, 'i');
            budgetCalculationIncome = budgetCalculationIncome.filter(item => (search.test(item.econ_category_code) || search.test(item.econ_category_name)));
        }

        if (this.state.searchEconCategoryExpense) {
            let searchVal = this.state.searchEconCategoryExpense;
            let search = new RegExp(searchVal, 'i');
            budgetCalculationExpense = budgetCalculationExpense.filter(item => (search.test(item.econ_category_code) || search.test(item.econ_category_name)));
            // console.log('searchEconCategoryExpense', budgetCalculationExpense)
        }

        if (this.state.budgetCalcStatusIncomeId) {
            let searchVal = this.state.budgetCalcStatusIncomeId;
            budgetCalculationIncome = budgetCalculationIncome.filter(item => item.budget_calc_status === searchVal);
        }

        // console.log(this.state.budgetCalcStatusExpenseId, budgetCalculationExpense)
        if (this.state.budgetCalcStatusExpenseId) {
            let searchVal = this.state.budgetCalcStatusExpenseId;
            // console.log('searchVal', searchVal, {...budgetCalculationExpense[0]})
            budgetCalculationExpense = budgetCalculationExpense.filter(item => item.budget_calc_status === searchVal);
            // console.log('budgetCalculationExpense', budgetCalculationExpense)
        }

        let toBudgetList = this.state.toBudgetList;

        return (
            <div className={'budgetCalculation'}>
                <div className={'budgetRow'}>
                    <div className={'budgetPanel'}>
                        <div className={'panelTitle'}>
                            <span>Системд орсон</span>
                        </div>
                        <div className={'panelBody'}>
                            <div style={{display: 'flex', justifyContent: 'space-between', gap: 10}}>
                                <div className={'panelLabel'}>Нийт маягтын тоо:</div>
                                <span style={{fontWeight: 'bold', textAlign: 'right'}}>{budgetCalculationIncomeCount+budgetCalculationExpenseCount}</span>
                            </div>
                            <div style={{display: 'flex', justifyContent: 'space-between', gap: 10}}>
                                <div className={'panelLabel'}>Орлогын маягт:</div>
                                <span style={{fontWeight: 'bold', textAlign: 'right'}}>{budgetCalculationIncomeCount}</span>
                            </div>
                            <div style={{display: 'flex', justifyContent: 'space-between', gap: 10}}>
                                <div className={'panelLabel'}>Зарлагын маягт:</div>
                                <span style={{fontWeight: 'bold', textAlign: 'right'}}>{budgetCalculationExpenseCount}</span>
                            </div>
                        </div>
                    </div>

                    <div className={'budgetPanel'}>
                        <div className={'panelTitle'}>
                            <span>ТШЗ боловсруулсан</span>
                        </div>
                        <div className={'panelBody'}>
                            <div style={{display: 'flex', justifyContent: 'space-between', gap: 10}}>
                                <div className={'panelLabel'}>Хөтөлбөрийн тоо:</div>
                                <span style={{fontWeight: 'bold', textAlign: 'right'}}>{agendaCount}</span>
                            </div>
                            <div style={{display: 'flex', justifyContent: 'space-between', gap: 10}}>
                                <div className={'panelLabel'}>Арга хэмжээний тоо:</div>
                                <span style={{fontWeight: 'bold', textAlign: 'right'}}>{eventCount}</span>
                            </div>
                            <div style={{display: 'flex', justifyContent: 'space-between', gap: 10}}>
                                <div className={'panelLabel'}>Тооцоолол хийсэн маягтын тоо:</div>
                                <span style={{fontWeight: 'bold', textAlign: 'right'}}>{budgetCalculationMakeCount}</span>
                            </div>
                        </div>
                    </div>

                    <div className={'budgetPanel'}>
                        <div className={'panelBody'}>
                            <div style={{display: 'flex', justifyContent: 'space-between', gap: 10}}>
                                <div className={'panelLabel'}>Сүүлд шинэчлэгдсэн огноо:</div>
                                <span style={{fontWeight: 'bold', textAlign: 'right', minWidth: 100}}>{lastUpdatedDate}</span>
                            </div>
                            <div style={{display: 'flex', justifyContent: 'space-between', gap: 10}}>
                                <div className={'panelLabel'}>Сүүлд шинэчилсэн хэрэглэгч:</div>
                                <span style={{fontWeight: 'bold', textAlign: 'right', minWidth: 100}}>{lastUpdatedUser}</span>
                            </div>
                            <div style={{display: 'flex', justifyContent: 'space-between', gap: 10}}>
                                <div className={'panelLabel'}>Төсвийн төсөлрүү татсан огноо:</div>
                                <span style={{fontWeight: 'bold', textAlign: 'right', minWidth: 100}}>{lastCalculatedDate}</span>
                            </div>
                            <div style={{display: 'flex', justifyContent: 'space-between', gap: 10}}>
                                <div className={'panelLabel'}>Төсвийн төсөлрүү татсан хэрэглэгч:</div>
                                <span style={{fontWeight: 'bold', textAlign: 'right', minWidth: 100}}>{lastCalculatedUser}</span>
                            </div>
                        </div>
                    </div>
                    <div className={'budgetPanel warningPanel'}>
                        <div className={'panelBody'} style={{padding: 10, textAlign: 'center'}}>
                            <span>{new Date(this.state.initialData.date).getFullYear()+1 || ''} оны Төсвийн төсөл<br/>хүргүүлэх хугацаа <strong>{budgetCalcCalendarDate}</strong></span>
                            <span style={{fontSize: 25}}>
                                <strong>{budgetCalculationleftDay}</strong>
                            </span>
                            <span>
                                хоног үлдсэн байна.
                            </span>
                        </div>
                    </div>
                </div>
                <div className={'budgetRow'}>
                    <div style={{display: 'flex', flex: 1, justifyContent: 'flex-end', gap: 10}}>
                        <Button
                            text={'Төсвийн төсөлрүү татах'}
                            width={200}
                            type="success"
                            id={'toBudgetBtn'}
                            // disabled={budgetCalcToBudgetDisable}
                            // disabled={true}
                            style={{backgroundColor: '#2c77ff'}}
                            onClick={() => this.setState({toBudgetPopup: true})}
                        />
                    </div>
                </div>
                <div className={'budgetRow'}>
                    {/*<SizeMe>{({ size }) =>*/}
                        <TabPanel style={{flex: 1}}>
                            <Item title="Орлогын маягт">
                                <div style={{display: 'flex', padding: 20, flexDirection: 'column', gap: 20}}>
                                    <div style={{display: 'flex', gap: 10}}>
                                        <TextBox
                                            stylingMode={'outlined'}
                                            inputAttr={{ 'aria-label': 'economy' }}
                                            label="ЭЗА код болон нэрээр хайх"
                                            labelMode={'static'}
                                            value={this.state.searchEconCategoryIncome}
                                            onValueChange={(value) => {
                                                // console.log('e', value);
                                                this.setState({searchEconCategoryIncome: value})
                                            }}
                                            width={200}
                                        />
                                        <SelectBox
                                            id="state"
                                            items={this.state.budgetCalcStatusList}
                                            stylingMode={'outlined'}
                                            inputAttr={{ 'aria-label': 'status' }}
                                            placeholder="сонгох"
                                            label="Төлөв"
                                            labelMode={'static'}
                                            className={'budgetCalculationSelect'}
                                            itemRender={this.selectRender}
                                            dropDownOptions={{
                                                wrapperAttr: {
                                                    class: 'budgetCalculationSelect'
                                                }
                                            }}
                                            displayExpr={'name'}
                                            valueExpr={'id'}
                                            onValueChanged={(e) => this.onBudgetStatusIncomeSelected(e)}
                                            showClearButton={true}
                                            // validationMessagePosition="left"
                                            width={200}
                                        />
                                    </div>
                                    <div>
                                        <Accordion
                                            noDataText={"Мэдээлэл байхгүй байна."}
                                            className={'budgetCalcAccordion'}
                                            onSelectedIndexChange={(index) => this.setState({incomeAccordionSelectedIndex: index})}
                                            // style={{border: '1px solid lightgray', borderTop: 0, borderBottom: 0}}
                                            // repaintChangesOnly={true}
                                            selectedIndex={this.state.incomeAccordionSelectedIndex}
                                            collapsible={true}
                                        >
                                            {budgetCalculationIncome.map((v, i) => {
                                                let statusColor;
                                                switch (v.budget_calc_status) {
                                                    case 1: statusColor = '#CDEBFF'; break;
                                                    case 2: statusColor = '#F99D4B'; break;
                                                    case 3: statusColor = '#2C77FF'; break;
                                                    default: statusColor = '#CDEBFF'; break;
                                                }
                                                return <Item key={i} titleRender={() => (<div style={{display: 'flex', flex: 1, justifyContent: 'space-between', fontSize: 14,}}>
                                                    <div style={{padding: '9px 12px'}}>
                                                        <img src={v.icon} alt={i} style={{width: 15, height: 15}}/>
                                                        <span style={{marginLeft: 20}}>{v.econ_category_code}</span>
                                                        <span style={{marginLeft: 5}}>-</span>
                                                        <span style={{marginLeft: 5}}>{v.econ_category_name}</span>
                                                    </div>
                                                    <div style={{display: 'flex', gap: 10}}>
                                                        <div style={{padding: '9px 0'}}>
                                                            {v.amount !== 0 && <img alt={i} src={infoIcon} style={{width: 15, height: 15}}/>}
                                                        </div>
                                                        <div style={{display:'flex', justifyContent: 'flex-end', marginRight: 30, padding: '9px 0'}}>
                                                            <span>{this.getAmount(v.amount)}₮ / нийт</span>
                                                        </div>
                                                        <div style={{width: 4, height: 40, background: statusColor}}/>
                                                    </div>
                                                </div>)}>
                                                    <div style={{display: 'flex', flex: 1}}>
                                                        {/*width={size.width-70}*/}
                                                        {this.state.incomeAccordionSelectedIndex === i && <TableTemplate
                                                            key={i}
                                                            template={v}
                                                            econ_category_id={v.econ_category_id}
                                                            econ_category_code={v.econ_category_code}
                                                            // width={size.width-70}
                                                            savedData={() => {
                                                                this.getBudgetSumValues();
                                                                this.getBudgetCalcByMonth();
                                                                this.getBudgetStats();
                                                        }}/>}
                                                    </div>
                                                </Item>
                                            })}
                                        </Accordion>

                                        <div style={{padding: 20, paddingRight: 40, display: 'flex', justifyContent: 'space-between'}}>
                                            <div style={{display: 'flex', gap: 10}}>
                                                <div>Нийт ЭЗА тоо:</div><div style={{textAlign: 'right'}}>{this.numberWithCommas(budgetCalculationIncome.length)}</div>
                                            </div>
                                            <div style={{display: 'flex', gap: 10}}>
                                                <div style={{textAlign: 'right'}}>{this.numberWithCommas(resultIncomeTotalYear.toFixed(2))}₮ / нийт</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Item>
                            <Item title="Зарлагын маягт">
                                <div style={{display: 'flex', padding: 20, flexDirection: 'column', gap: 20}}>
                                    <div style={{display: 'flex', gap: 10}}>
                                        <TextBox
                                            stylingMode={'outlined'}
                                            inputAttr={{ 'aria-label': 'economy' }}
                                            label="ЭЗА код болон нэрээр хайх"
                                            labelMode={'static'}
                                            value={this.state.searchEconCategoryExpense}
                                            onValueChange={(value) => {
                                                // console.log('e', value);
                                                this.setState({searchEconCategoryExpense: value})
                                            }}
                                            width={200}
                                        />
                                        <SelectBox
                                            id="state"
                                            items={this.state.budgetCalcStatusList}
                                            stylingMode={'outlined'}
                                            inputAttr={{ 'aria-label': 'status' }}
                                            placeholder="сонгох"
                                            label="Төлөв"
                                            labelMode={'static'}
                                            className={'budgetCalculationSelect'}
                                            dropDownOptions={{
                                                wrapperAttr: {
                                                    class: 'budgetCalculationSelect'
                                                }
                                            }}
                                            itemRender={this.selectRender}
                                            displayExpr={'name'}
                                            valueExpr={'id'}
                                            onValueChanged={(e) => this.onBudgetStatusExpenseSelected(e)}
                                            showClearButton={true}
                                            // validationMessagePosition="left"
                                            width={200}
                                        />
                                    </div>
                                    <div>
                                        <Accordion
                                            noDataText={"Мэдээлэл байхгүй байна."}
                                            onSelectedIndexChange={(index) => this.setState({expenseAccordionSelectedIndex: index})}
                                            selectedIndex={this.state.expenseAccordionSelectedIndex}
                                            // repaintChangesOnly={true}
                                            className={'budgetCalcAccordion'}
                                            collapsible={true}
                                        >
                                            {budgetCalculationExpense.map((v, i) => {
                                                let statusColor;
                                                switch (v.budget_calc_status) {
                                                    case 1: statusColor = '#CDEBFF'; break;
                                                    case 2: statusColor = '#F99D4B'; break;
                                                    case 3: statusColor = '#2C77FF'; break;
                                                    default: statusColor = '#CDEBFF'; break;
                                                }
                                                return <Item key={i} titleRender={() => (<div style={{display: 'flex', flex: 1, justifyContent: 'space-between', fontSize: 14,}}>
                                                    <div style={{padding: '9px 12px'}}>
                                                        <img src={v.icon} alt={i} style={{width: 15, height: 15}}/>
                                                        <span style={{marginLeft: 20}}>{v.econ_category_code}</span>
                                                        <span style={{marginLeft: 5}}>-</span>
                                                        <span style={{marginLeft: 5}}>{v.econ_category_name}</span>
                                                    </div>
                                                    <div style={{display: 'flex', gap: 10}}>
                                                        <div style={{padding: '9px 0'}}>
                                                            {v.amount !== 0 && <img alt={i} src={infoIcon} style={{width: 15, height: 15}}/>}
                                                        </div>
                                                        <div style={{display:'flex', justifyContent: 'flex-end', marginRight: 30, padding: '9px 0'}}>
                                                            <span>{this.getAmount(v.amount)}₮ / нийт</span>
                                                        </div>
                                                        <div style={{width: 4, height: 40, background: statusColor}}/>
                                                    </div>
                                                </div>)}>
                                                    <div style={{display: 'flex', flex: 1}}>
                                                        {/*width={size.width-70}*/}
                                                        {this.state.expenseAccordionSelectedIndex === i && <TableTemplate
                                                            key={i}
                                                            template={v}
                                                            econ_category_id={v.econ_category_id}
                                                            econ_category_code={v.econ_category_code}
                                                            // width={size.width-70}
                                                            savedData={() => {
                                                                this.getBudgetSumValues()
                                                                this.getBudgetCalcByMonth();
                                                                this.getBudgetStats();
                                                        }}/>}
                                                    </div>
                                                </Item>
                                            })}
                                        </Accordion>

                                        <div style={{padding: '20px 45px 20px 50px', display: 'flex', justifyContent: 'space-between'}}>
                                            <div style={{display: 'flex', gap: 10}}>
                                                <div>Нийт ЭЗА тоо:</div><div style={{textAlign: 'right'}}>{this.numberWithCommas(budgetCalculationExpense.length)}</div>
                                            </div>
                                            <div style={{display: 'flex', gap: 10}}>
                                                <div style={{textAlign: 'right'}}>{this.numberWithCommas(resultExpenseTotalYear.toFixed(2))}₮ / нийт</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Item>
                            <Item title="Үр дүн">
                                <div style={{display: 'flex', padding: 20, flexDirection: 'column', gap: 10}}>
                                    <div style={{display: 'flex', flex: 1, flexDirection: 'column', gap: 10, alignItems: 'flex-end'}}>
                                        <div className={'budgetToggleButton'}>
                                            <div className={`budgetToggleButtonItem ${this.state.showType === 'table' && 'selected'}`} onClick={() => this.setState({showType: 'table'})}>Хүснэгтээр</div>
                                            <div className={`budgetToggleButtonItem ${this.state.showType === 'graph' && 'selected'}`} onClick={() => this.setState({showType: 'graph'})}>Графикаар</div>
                                        </div>
                                    </div>
                                    {this.state.showType === 'graph' && <div style={{display: 'flex', flex: 1, flexDirection: 'column', gap: 10}}>
                                        <Chart
                                            id="chart"
                                            dataSource={resultIEGraph}
                                        >
                                            <CommonSeriesSettings
                                                argumentField="month"
                                                type="bar"
                                                hoverMode="allArgumentPoints"
                                                selectionMode="allArgumentPoints"
                                            >
                                                <Label visible={true}>
                                                    <Format
                                                        type="fixedPoint"
                                                        precision={2}
                                                    />
                                                </Label>
                                            </CommonSeriesSettings>
                                            <Series
                                                argumentField="month"
                                                valueField="incomeValue"
                                                color={'#34B771'}
                                                name="Орлого"
                                            />
                                            <Series
                                                valueField="expenseValue"
                                                color={'#F93A3A'}
                                                name="Зарлага"
                                            />
                                            <Legend
                                                verticalAlignment="bottom"
                                                horizontalAlignment="center"
                                            ></Legend>
                                            <Export enabled={true} />
                                        </Chart>
                                    </div>}

                                    {this.state.showType === 'table' && <div style={{display: 'flex', gap: 10, flexDirection: 'column'}}>
                                        <div style={{display: 'flex', flex: 1, flexDirection: 'column', gap: 10}}>
                                            <span style={{fontWeight: 500}}>1. Орлого</span>
                                            <table className={'resultTable'}>
                                                <thead>
                                                <tr>
                                                    <th>1 сар</th>
                                                    <th>2 сар</th>
                                                    <th>3 сар</th>
                                                    <th>4 сар</th>
                                                    <th>5 сар</th>
                                                    <th>6 сар</th>
                                                    <th>7 сар</th>
                                                    <th>8 сар</th>
                                                    <th>9 сар</th>
                                                    <th>10 сар</th>
                                                    <th>11 сар</th>
                                                    <th>12 сар</th>
                                                    <th>Нийт</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr>
                                                    <td>{this.numberWithCommas((resultIncomeByMonth['1'] || 0).toFixed(2))}</td>
                                                    <td>{this.numberWithCommas((resultIncomeByMonth['2'] || 0).toFixed(2))}</td>
                                                    <td>{this.numberWithCommas((resultIncomeByMonth['3'] || 0).toFixed(2))}</td>
                                                    <td>{this.numberWithCommas((resultIncomeByMonth['4'] || 0).toFixed(2))}</td>
                                                    <td>{this.numberWithCommas((resultIncomeByMonth['5'] || 0).toFixed(2))}</td>
                                                    <td>{this.numberWithCommas((resultIncomeByMonth['6'] || 0).toFixed(2))}</td>
                                                    <td>{this.numberWithCommas((resultIncomeByMonth['7'] || 0).toFixed(2))}</td>
                                                    <td>{this.numberWithCommas((resultIncomeByMonth['8'] || 0).toFixed(2))}</td>
                                                    <td>{this.numberWithCommas((resultIncomeByMonth['9'] || 0).toFixed(2))}</td>
                                                    <td>{this.numberWithCommas((resultIncomeByMonth['10'] || 0).toFixed(2))}</td>
                                                    <td>{this.numberWithCommas((resultIncomeByMonth['11'] || 0).toFixed(2))}</td>
                                                    <td>{this.numberWithCommas((resultIncomeByMonth['12'] || 0).toFixed(2))}</td>
                                                    <td>{this.numberWithCommas(resultIncomeTotalYear.toFixed(2))}</td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>

                                        <div style={{display: 'flex', flex: 1, flexDirection: 'column', gap: 10}}>
                                            <span style={{fontWeight: 500}}>2. Зарлага</span>
                                            <table className={'resultTable'}>
                                                <thead>
                                                <tr>
                                                    <th>1 сар</th>
                                                    <th>2 сар</th>
                                                    <th>3 сар</th>
                                                    <th>4 сар</th>
                                                    <th>5 сар</th>
                                                    <th>6 сар</th>
                                                    <th>7 сар</th>
                                                    <th>8 сар</th>
                                                    <th>9 сар</th>
                                                    <th>10 сар</th>
                                                    <th>11 сар</th>
                                                    <th>12 сар</th>
                                                    <th>Нийт</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr>
                                                    <td>{this.numberWithCommas((resultExpenseByMonth['1'] || 0).toFixed(2))}</td>
                                                    <td>{this.numberWithCommas((resultExpenseByMonth['2'] || 0).toFixed(2))}</td>
                                                    <td>{this.numberWithCommas((resultExpenseByMonth['3'] || 0).toFixed(2))}</td>
                                                    <td>{this.numberWithCommas((resultExpenseByMonth['4'] || 0).toFixed(2))}</td>
                                                    <td>{this.numberWithCommas((resultExpenseByMonth['5'] || 0).toFixed(2))}</td>
                                                    <td>{this.numberWithCommas((resultExpenseByMonth['6'] || 0).toFixed(2))}</td>
                                                    <td>{this.numberWithCommas((resultExpenseByMonth['7'] || 0).toFixed(2))}</td>
                                                    <td>{this.numberWithCommas((resultExpenseByMonth['8'] || 0).toFixed(2))}</td>
                                                    <td>{this.numberWithCommas((resultExpenseByMonth['9'] || 0).toFixed(2))}</td>
                                                    <td>{this.numberWithCommas((resultExpenseByMonth['10'] || 0).toFixed(2))}</td>
                                                    <td>{this.numberWithCommas((resultExpenseByMonth['11'] || 0).toFixed(2))}</td>
                                                    <td>{this.numberWithCommas((resultExpenseByMonth['12'] || 0).toFixed(2))}</td>
                                                    <td>{this.numberWithCommas(resultExpenseTotalYear.toFixed(2))}</td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>}

                                    <div style={{display: 'flex', flex: 1, flexDirection: 'column', gap: 10}}>
                                        <span style={{fontWeight: 500}}>3. Төсвийн төсөлрүү татсан түүх</span>
                                        <table className={'resultTableHistory'}>
                                            <thead>
                                                <tr key={0}>
                                                    <th rowSpan="2">№</th>
                                                    <th rowSpan="2">Шинэчлэгдсэн огноо</th>
                                                    <th colSpan="2">Шинэчилсэн</th>
                                                    <th rowSpan="2">Нийт дүн</th>
                                                    <th rowSpan="2">Төсвийн жил</th>
                                                    <th rowSpan="2">Тооцоолол татсан модул</th>
                                                    <th rowSpan="2">Төлөв</th>
                                                </tr>
                                                <tr key={1}>
                                                    <th>Овог нэр</th>
                                                    <th>Албан тушаал</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {toBudgetList.map((item, i) => {
                                                    let date = '';
                                                    if (item.createdAt) {
                                                        try {
                                                            let fullDate = new Date(item.createdAt);
                                                            date = `${fullDate.getFullYear()}-${(fullDate.getMonth()+1).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})}-${fullDate.getDate().toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})} ${fullDate.getHours().toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})}:${fullDate.getMinutes().toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})}`;
                                                        } catch (e) {

                                                        }
                                                    }

                                                    return <tr key={i}>
                                                        <td>{i+1}</td>
                                                        <td>{date}</td>
                                                        <td>{(item.last_name || '').slice(0,1)}{item.last_name && '.'}{item.first_name}</td>
                                                        <td>{item.position_name}</td>
                                                        <td>{this.numberWithCommas((item.amount*1 || 0).toFixed(2))}</td>
                                                        <td>{item.budget_year}</td>
                                                        <td>Төсвийн төсөл</td>
                                                        <td>Татагдсан</td>
                                                    </tr>
                                                })}
                                                {/*<tr>*/}
                                                {/*    <td>1</td>*/}
                                                {/*    <td>2022-12-26 10:34:55</td>*/}
                                                {/*    <td>А.Мягмардорж</td>*/}
                                                {/*    <td>Нягтлан</td>*/}
                                                {/*    <td>2,500,200</td>*/}
                                                {/*    <td>2025</td>*/}
                                                {/*    <td>Төсвийн төсөл</td>*/}
                                                {/*    <td>Засвар хийсэн</td>*/}
                                                {/*</tr>*/}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </Item>
                        </TabPanel>
                    {/*// }</SizeMe>*/}

                    <Popup
                        visible={this.state.toBudgetPopup}
                        onHiding={() => this.setState({toBudgetPopup: false})}
                        dragEnabled={false}
                        hideOnOutsideClick={true}
                        showCloseButton={false}
                        showTitle={true}
                        title="Төсвийн төсөлрүү татах"
                        container=".dx-viewport"
                        width={350}
                        height={280}
                    >
                        <Position
                            at="bottom"
                            my="center"
                            of={'#toBudgetBtn'}
                            collision="fit"
                        />
                        <ToolbarItem
                            widget="dxButton"
                            toolbar="bottom"
                            location="before"
                            options={{
                                text: 'тийм',
                                stylingMode: 'contained',
                                onClick: () => this.budgetCalcToBudget()
                            }}
                        />
                        <ToolbarItem
                            widget="dxButton"
                            toolbar="bottom"
                            location="after"
                            options={{
                                text: 'үгүй',
                                stylingMode: 'outlined',
                                type: 'normal',
                                onClick: () => this.setState({toBudgetPopup: false}),
                            }}
                        />
                        <p>
                            Таны боловсруулсан тооцооллын маягтуудын НИЙТ ДҮН төсвийн төсөлрүү татагдах бөгөөд өмнө нь татагдсан дүнгүүд шинэчлэгдэх болно.
                        </p>
                        <p>
                            Та төсвийн төсөлрүү татахыг зөвшөөрч байна уу?
                        </p>
                    </Popup>
                </div>
            </div>
        )
    }
}

export default BudgetCalculation;

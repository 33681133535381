import React, {useEffect, useState} from "react"
import statsService from "../../../services/api/stat.service"
import notify from "devextreme/ui/notify"
import Chart, {
  CommonAxisSettings,
  CommonSeriesSettings,
  Crosshair,
  Export,
  HorizontalLine,
  Legend,
  Series,
  Grid,
  ValueAxis,
  ArgumentAxis,
  Format
} from "devextreme-react/chart"
import {Label, Tooltip} from "devextreme-react/pie-chart"
import {numberWithCommas11} from "../../../util"
import { Margin } from "devextreme-react/bar-gauge"

function removeDuplicates(array, key) {
    
  const seen = new Set();
  return array.filter(item => {
    const keyValue = key(item);
    if (!seen.has(keyValue)) {
      seen.add(keyValue);
      return true;
    }
    return false;
  });
}
const NumberTwo = (props) => {
  const [data, setData] = useState([])
  const [filteredData, setFilteredData] = useState([])
  const [isColumn, setIsColumn] = useState(false)
  const [referenceList, setReferenceList] = useState([])
  
  const loadData = async () => {
    try {
      let {data} = await statsService.getDataYears(props.list_id, props.tbl_id)
      let reference = removeDuplicates(data, (e) => e.SCR_MN1)
      setReferenceList(reference)
      const newArray = data.map((item) => ({
        [item.SCR_MN1]: item.sum,
        period_year: parseInt(item.period_year),
      }));
      setFilteredData(newArray)
      if (data?.[0]?.is_column) {
        setIsColumn(data?.[0]?.is_column)
      }
      setData(data)
    } catch (e) {
      notify(e.message)
    }
  }

  useEffect(() => {
    loadData()
  }, [props.tbl_id, props.list_id])

  const renderLabel = (e) => {
    return `${e.percentText}` 
  }

  const customizeTooltip = (arg) => {
    if (arg.seriesName === "Өөрчлөлт") {
      let r = data.find(
        (i) => parseInt(i.period_year) === parseInt(arg.argument) - 1
      )
      let q = (
        arg.value - (r && r.sum) > 0
          ? arg.value / (r && r.sum)
          : (r && r.sum) / arg.value
      ).toFixed(2)
      let p = ((arg.value * 100) / (r && r.sum) - 100).toFixed(2)
      let d = arg.value - (r && r.sum)
      let f = d < 0 ? "бага" : "их"
      if (isFinite(q) && !isNaN(q)) {
        return {
          text: `Тоо: ${numberWithCommas11(
            arg.value
          )}\n\nӨмнөх жилээс\n\n Зөрүү: ${numberWithCommas11(
            d
          )}\n Хувь: ${p}%\n Өөрчлөлт: ${q} дахин ${f}`,
        }
      } else {
        return {
          text: `Тооцоолох боломжгүй`,
        }
      }
    } else {
      return {
        text: `${arg.seriesName}: ${numberWithCommas11(arg.value)}`,
      }
    }
  }
 if (isColumn) return (
  <div>
             <Chart
        palette="Violet"
        dataSource={filteredData}
      >
        <CommonSeriesSettings
          argumentField="period_year"
          type={"spline"}
        />
        <CommonAxisSettings>
          <Grid visible={true} />
        </CommonAxisSettings>
        {
          referenceList?.map((item) => (
            <Series
              key={item.value}
              valueField={item.SCR_MN1}
              name={item.SCR_MN1}
            />
          ))
        }
        <Margin bottom={20} />
        <ArgumentAxis
          allowDecimals={false}
          axisDivisionFactor={60}
        >
          <Label>
            <Format type="decimal" />
          </Label>
        </ArgumentAxis>
        <Tooltip
          zIndex={100000}
          customizeTooltip={customizeTooltip}
          enabled={true}
          font={{family: "'Montserrat', sans-serif"}}
          location="edge"
        ></Tooltip>
        <Legend
          verticalAlignment="bottom"
          horizontalAlignment="center"
        />
        <Export enabled={true} />
        <Tooltip enabled={true} />
      </Chart>
  </div>
 )
  return (
    <div>
      <Chart dataSource={data}>
        <Export enabled={true} />
        <CommonSeriesSettings argumentField="period_year" type="bar">
          <Label
            backgroundColor="none"
            visible={false}
            customizeText={renderLabel}
            font={{family: "'Montserrat', sans-serif"}}
          />
        </CommonSeriesSettings>
        <Crosshair enabled={true}>
          <HorizontalLine visible={false} />
          <Label visible={true} />
        </Crosshair>
        <ValueAxis>
          <Label customizeText={(e) => e.value} />
        </ValueAxis>
        <Series valueField="sum" name="Тоо" color="#003696" />
        <Series
          valueField="sum"
          name="Өөрчлөлт"
          type="spline"
          color="#f05b41"
        />
        <Legend
          visible={true}
          verticalAlignment="bottom"
          horizontalAlignment="center"
          font={{family: "'Montserrat', sans-serif"}}
        />
        <Tooltip
          zIndex={100000}
          customizeTooltip={customizeTooltip}
          enabled={true}
          font={{family: "'Montserrat', sans-serif"}}
          location="edge"
        ></Tooltip>
      </Chart>
    </div>
  )
}

export default NumberTwo

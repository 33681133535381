import React, { useEffect, useState, useRef } from 'react';
import DataGrid, {
  Column
} from 'devextreme-react/data-grid';
import ProposalService from "../../../services/api/proposal";
import { projectStatusIdEditAllowed, calculateDiff } from "../static/data";
import RefService from "../../../services/api/refs";
import _ from "lodash";
import LoadPanelComponent from "../../budget/components/loadPanel";
import { numberWithCommas } from "../../../util";
import ProposalHome from "./proposalHome";

export default function ProposalMinistryDetailTemplate(props) {

  const [dataSource, setDataSource] = useState([]);
  const [overViewList, setOverViewList] = useState([]);
  const [loader, setLoader] = useState(false);

  let econParentArray = useRef([]);
  let canEdit = useRef(false);
  let econArray = useRef([]);
  let defaultFilterValue = useRef([['econ_category_parent_id', '=', '7'],]);

  useEffect(() => {
    initVars();
    loadData();
  }, []);

  const initVars = async () => {
    //console.log("DETAIL START", props);
    if (props.proposal && projectStatusIdEditAllowed.includes(props.proposal.budget_project_status_id))
      canEdit.current = true;

    // init tez ttz expense, revenue, difference
    let temp = [];
    // console.log(props);
    temp.push({ name: 'ТШЗ', ...props.data.data });
    temp.push({ name: 'ТТЗ', ...props.data.data, revenue: props.data.data.revenue_ttz_amount ? props.data.data.revenue_ttz_amount : 0, expense: props.data.data.expense_ttz_amount, expense_source: props.data.data.expense_source_ttz_amount, differency: props.data.data.differency_ttz_amount, revenue_tshz: props.data.data.revenue ? props.data.data.revenue : 0, expense_tshz: props.data.data.expense, expense_source_tshz: props.data.data.expense_source });
    temp.push({ name: 'ТЕЗ', ...props.data.data, revenue: props.data.data.revenue_tez_amount ? props.data.data.revenue_tez_amount : 0, expense: props.data.data.expense_tez_amount, expense_source: props.data.data.expense_source_tez_amount, differency: props.data.data.differency_tez_amount, });
    temp.push({ name: 'Хязгаар', ...props.data.data, revenue: props.data.data.revenue_limit_amount ? props.data.data.revenue_limit_amount : 0, expense: props.data.data.expense_limit_amount, expense_source: props.data.data.expense_source_limit_amount, differency: props.data.data.differency_limit_amount, });
    setOverViewList(temp);
  };
  const loadData = async () => {
    setLoader(true);

    const r = await RefService.getRefEconomicCategory();
    econArray.current = r.data;
    econParentArray.current = econArray.current.filter(el => el.parent_id === null);

    const result = await ProposalService.listDetailMinistryByOrg(props.data.data.org_id, props.data.data.budget_year);
    //console.log('listDetailMinistryByOrg', result);
    result.data.forEach(item => {
      item['Хөтөлбөр'] = item['agenda_name'];
      item['Арга хэмжээ'] = item['event_name'];
      item['econ_category_parent_id'] = helperGetAncestor(item.econ_category_id);
      item['econ_name_d'] = helperGetEconName(item.econ_category_id);
    });
    result.data = _.sortBy(result.data, ['econ_name_d'], 'asc');

    setDataSource(result.data);
    setLoader(false);
  };
  const helperGetAncestor = (econId) => {

    let parentId = econArray.current.find(i => i.id === econId)?.parent_id;
    if (parentId === null || parentId === undefined) {
      return econId;
    } else {
      return helperGetAncestor(parentId);
    }
  };
  const helperGetEconName = (econId) => {
    const temp = econArray.current.find(i => i.id === econId);
    if (temp) {
      return `${temp.code} - ${temp.name}`;
    }
    return 'Ангилагдаагүй';
  };
  const GroupCell = (e) => {
    return (
      <div><div>{e.data.displayValue}</div></div>
    );
  }

  const cellRenderValue = (data, type) => {
    //if(data.data.name === 'ТШЗ') return ;
    //console.log('cellRenderValue', data);
    let diff = 0;
    diff = calculateDiff(data.data[type], data.data[`${type}_${data.data.name === 'ТТЗ' ? 'tshz' : 'ttz_amount'}`]);
    if (isNaN(diff)) { diff = 0 };
    if (diff > 0 && (data.data.name !== 'ТШЗ' && data.data.name !== 'Хязгаар')) {
      return (<div className="inc customCell" style={{ flexDirection: 'column', display: 'flex', justifyContent: 'space-between' }}>
        <div className="current-value" style={{ fontWeight: 'bold', color: '#000000', fontSize: '11px' }}>{numberWithCommas(data.value / 1000000)}</div>
        <div className="diff fw-medium" style={{ color: '#f66160' }}>{numberWithCommas(diff)}</div>
      </div>)
      /*  return <div className={`row-pr-value ${type} ${diff < 0 ? 'dec': 'inc'}`}>
          <div>{Number(parseFloat(data.value ===undefined ? 0 : data.value / 1000000).toFixed(2)).toLocaleString("en", {
            minimumFractionDigits: 2,
          })} ₮</div>
          {data.data.name !== 'ТШЗ' && <span className={`diff`}>{Math.abs(diff).toFixed(2)}%</span>}
        </div>*/
    }
    else if (diff < 0 && (data.data.name !== 'ТШЗ' && data.data.name !== 'Хязгаар')) {
      return <div className="dec customCell" style={{ flexDirection: 'column', display: 'flex', justifyContent: 'space-between' }}>
        <div className="current-value" style={{ fontWeight: 'bold', color: '#000000', fontSize: '11px' }}>{numberWithCommas(data.value / 1000000)}</div>
        <div className="diff fw-medium" style={{ color: '#3daa75' }}>{numberWithCommas(diff)}</div>
      </div>
    }
    else {
      return <div>{Number(parseFloat(data.value === undefined ? 0 : data.value / 1000000).toFixed(2)).toLocaleString("en", {
        minimumFractionDigits: 2,
      })} ₮</div>
    }
  };

  const customizeDate = (data) => {
    return Number(parseFloat(data.value / 1000000).toFixed(2)).toLocaleString("en", {
      minimumFractionDigits: 2,
    });
  }
  const onCellPreparedHandler = (e) => {
    e.cellElement.style.fontFamily = 'Segoe UI'
    if (e.rowType === 'header') {
      e.cellElement.style.color = "#15186A"
      e.cellElement.style.backgroundColor = "#EEF0F4"
      e.cellElement.style.fontWeight = "bold";
      e.cellElement.style.textAlign = 'center'
      e.cellElement.style.verticalAlign = 'middle'
    }
    if (e.rowType === 'group') {
      e.cellElement.style.backgroundColor = '#fff'
      e.cellElement.style.color = 'black'
      e.cellElement.style.fontFamily = 'Segoe UI'
      e.cellElement.style.fontSize = '12px'
      e.cellElement.style.verticalAlign = "middle";
      e.cellElement.style.fontWeight = "bold";
    }
    if (e.rowType === 'data') {
      e.cellElement.style.fontFamily = 'Segoe UI'
      e.cellElement.style.fontSize = '12px'
      e.cellElement.style.verticalAlign = "middle";

    }
  }
  return (
    <div>
      <DataGrid
        id="dataGridOverview"
        dataSource={overViewList}
        allowColumnReordering={true}
        rowAlternationEnabled={true}
        columnAutoWidth={true}
        allowColumnResizing={true}
        showBorders={true}
        className="my-2"
        onCellPrepared={onCellPreparedHandler}
      >
        <Column dataField="name" caption={'Төрөл'}>
        </Column>
        <Column dataField="revenue" alignment="right" caption={'Нийт орлого ба тусламжийн дүн'} dataType="number" format="#,##0.00 ₮" cellRender={(e) => cellRenderValue(e, 'revenue')}>
        </Column>
        <Column dataField="expense" alignment="right" caption={'Нийт зарлага ба цэвэр зээлийн дүн'} dataType="number" format="#,##0.00 ₮" cellRender={(e) => cellRenderValue(e, 'expense')}>
        </Column>
        <Column dataField="expense_source" alignment="right" caption={'Зардлыг санхүүжүүлэх эх үүсвэр дүн'} dataType="number" format="#,##0.00 ₮" cellRender={(e) => cellRenderValue(e, 'expense_source')}>
        </Column>
        <Column dataField="differency" alignment="right" caption={'Зөрүү дүн'} dataType="number" format="#,##0.00 ₮" cellRender={(e) => cellRenderValue(e, 'difference')} visible={false}>
        </Column>

      </DataGrid>
      <LoadPanelComponent position={{ of: 'dataGridDetail' }} visible={loader} />
      <ProposalHome location={{state: { proposalId: props.data.data.id, isMinistry: true, orgName: props.data.data.organization_name, aimag_name: props.data.data.aimag_name, proposal: props.data.data, sYear: props.data.data.budget_year,masterDetail: true,notOrg: props.data.data.isNotOrg }}}/>
     {/* <DataGrid
        id="dataGridDetail"
        dataSource={dataSource}
        allowColumnReordering={true}
        rowAlternationEnabled={true}
        //columnAutoWidth={true}
        allowColumnResizing={true}
        defaultFilterValue={defaultFilterValue.current}
        showBorders={true}
        onCellPrepared={onCellPreparedHandler}
      >
        <Selection mode="single" />
        <FilterPanel visible={true} texts={{ clearFilter: 'Шүүлтүүр цэвэрлэх', createFilter: 'Шүүх' }} />
        <HeaderFilter visible={true} />
        <GroupPanel visible={true} emptyPanelText={'Энд багана чирж оруулна уу'} />

        <SearchPanel visible={true} highlightCaseSensitive={true}/>
        <Grouping autoExpandAll={false} texts={{
          groupByThisColumn: 'Энэ баганаар бүлэглэх',
          groupContinuedMessage: 'Цааш үргэлжилнэ',
          groupContinuesMessage: '1',
          ungroup: 'Болих',
          ungroupAll: 'Шууд харах',
        }} />

        <Column dataField="Хөтөлбөр" groupIndex={0} >
        </Column>
        <Column dataField="Арга хэмжээ" groupIndex={1}>
        </Column>
        <Column
          dataField="econ_category_parent_id"
          groupIndex={2}
          caption="ЭЗА"
          groupCellComponent={GroupCell}
          dataType="text"
        >
          <Lookup dataSource={econParentArray.current} valueExpr="id" displayExpr="name" />
        </Column>
        <Column
          dataField="econ_name_d"
          width={500}
          caption="Эдийн засгийн ангилал"
          dataType="text"
        >
          <Lookup dataSource={economicCategoryArray} valueExpr="id" displayExpr="name" />
        </Column>
        <Column dataField="amount" caption={props.data.data.budget_year + " оны төсвийн санал /сая ₮/"} alignment="right" dataType="number" format="#,##0.00" customizeText={customizeDate} />
        <Summary>
          <TotalItem
            column="econ_category_id"
            summaryType="count"
            showInColumn="econ_category_id"
            displayFormat="{0} төсөл"
          />
          <TotalItem
            column="OrderDate"
            summaryType="min" />
          <TotalItem
            column="amount"
            summaryType="sum"
            displayFormat="Нийт дүн: {0} "
            customizeText={customizeDate}
            valueFormat="#,##0.00" />

          <GroupItem
            column="agenda_id"
            summaryType="count"
            displayFormat="{0} мэдээлэл" />
          <GroupItem
            column="amount"
            summaryType="sum"
            valueFormat="#,##0.00"
            showInGroupFooter={false}
            customizeText={customizeDate}
            displayFormat="{0}"
            alignByColumn={true} />

          <GroupItem
            column="amount"
            summaryType="sum"
            valueFormat="#,##0.00"
            customizeText={customizeDate}
            displayFormat="{0}"
            showInGroupFooter={true} />
        </Summary>

        <Scrolling mode='virtual' />
      </DataGrid>*/}
    </div>
  );
}

import request from '../../shared/lib/request'

function getHubabuba() {
    return request({
        url:    `/organizations/settings/get`,
        method: 'GET',
    });
}

function getAllOrganizationInfo(type, value) {
    return request({
        url:    `/getAllOrganizationInfo/${type}/${value}`,
        method: 'GET',
    });
}

function submitOrgWorkerData(body) {
    return request({
        url:    'submitOrgWorkerData',
        method: 'POST',
        data: body,
    });
}

function getWorkerCount(stateId, Occupation) {
    return request({
        url:    `/getWorkerCount/${stateId}/${Occupation}`,
        method: 'GET',
    })
}

function get(id) {
    return request({
        url:    `/message/${id}`,
        method: 'GET'
    });
}

function getList() {
    return request({
        url:    `/organizations/list`,
        method: 'GET'
    });
}

function stat(filter) {
    return request({
        url:    `/org/stats/budget/${filter}`,
        method: 'POST',
        data: filter
    });
}

function create({subject, content}) {
    return request({
        url:    '/message/create',
        method: 'POST',
        data:   {
            subject,
            content
        }
    })
}
function orgDetailAttr() {
    return request({
        url: '/users/userOrgAttribute',
        method: 'GET'
    })
}

function usersList() {
    return request({
        url: '/organizations/editable/users/list',
        method: 'GET'
    })
}

function orgPersonalInfo() {
    return request({
        url : '/auth/me',
        method: 'GET'
    })
}

function getOrgBudgetType() {
    return request({
        url: '/organizations/settings/getOrgBudgetType',
        method: 'GET'
    })
}

function organizationsSettingList() {
    return request({
        url: '/organizations/settings/list',
        method: 'GET'
    })
}

function orgSettingsListForScheduleEdit(year, currentOrg, orgId) {
    return request({
        url: `/organizations/orgSettingsListForScheduleEdit?year=${year}&isCurrent=${currentOrg}&orgId=${orgId}`,
        method: 'GET'
    })
}

function getRefOrganization(){
    return request({
        url:'/refs/refOrgTypes',
        method:'GET'
    })
}


function getRefOrganization1(extension){
    return request({
        url: '/organizations/remainingChart/'+extension,
        method:'GET'
        }
    )
}
function organizationStat(){
    return request({
        url:'/organizations/stat',
        method:'GET'
    })
}

function organizationStatWithExtension(aimagId){
    return request({
        url:'/organizations/stat?aimagId='+aimagId,
        method:'GET'
    })
}

function loadOrgTypeDetails(extension){
    return request({
        url:'/organizations/type/detail/list/'+extension,
        method:'GET'
    })
}
function changeOrgType(e){
    return request({
        url:'/organizations/type/detail/list/'+e,
        method:'GET'
    })
}
function organizationSelect(orgTypeId){
    return request({
        url:'/organizations/type/detail/'+orgTypeId,
        method:'GET'
    })
}
function orgTypeWithQuery(e){
    return request({
        url:'/organizations/list/withParam/'+e,
        method:'GET'
    })
}

function orgListWithParam(id) {
    return request({
        url : `/organizations/list/withParam/${id}`,
        method: "GET"
    })
}

function orgDetailWithParam(id) {
    return request({
        url: '/organizations/detail/' + id,
        method : "GET"
    })
}
function orgIdTake(orgId) {
    return request({
        url: '/organizations/detail/' + orgId,
        method : "GET"
    })
}
function OrgBudget(org_id){
    return request({
        url:'/dashboard/stat/law/byOrg/'+org_id,
        method:'GET'
    })
}
function fetchOrgListByParentId(parentId){
    return request({
        url:'/org/listByParent/'+parentId,
        method:'GET'
    })
}

const fetchOrgListL1 = () => {
    return request({
        url: '/org/listL1',
        method: 'GET'
    })
}

const fetchOrgList = () => {
    return request({
        url: '/org/list',
        method: 'GET'
    })
}

const orgBuilderCreate = (data) => {
    return request({
        url: '/formb',
        method: 'POST',
        data: data
    })
}
const orgFormUpdate = (data) => {
    return request({
        url: `/formb/${data.id}`,
        method: 'PUT',
        data: data
    })
}
const orgBuilderList = () => {
    return request({
        url: '/formb/list',
        method: 'GET',
    })
}
const orgWorkerDataById= (workerId) => {
    return request({
        url: `/formb/data/list?filters.org_worker_id=${workerId}`,
        method: 'GET',
    })
}
const getOrgWorkerFData= (workerId) => {
    return request({
        url: `/formb/data/list`,
        method: 'GET',
    })
}
const orgBuilderDelete = (id) => {
    return request({
        url: `/formb/${id}`,
        method: 'DELETE',
    })
}

const orgWorkerDataSave = (data) => {
    return request({
        url: `/formb/data/bulkSave`,
        method: 'POST',
        data: data,
    })
}

const getCompanyStructure = () => {
    return request({
        url: '/organizations/getCompanyStructure',
        method: 'GET'
    })
}

const setOrgWorker = (data) => {
    return request({
        url: '/organizations/setOrgWorker',
        method: 'POST',
        data: data
    })
}

const updateOrgWorker = (data) => {
    return request({
        url: '/organizations/updateOrgWorker',
        method: 'POST',
        data: data
    })
}

const getOrgStructure = (org_id,days) => {
    return request({
        url:'/organizations/getOrgStructure?org_id='+org_id + `&days=${days}`,
        method: 'GET'
    })
}

const getOrgStructureName = (orgStructureId) => {
    return request({
        url:    `/organizations/getOrgStructureName?orgStructureId=`+orgStructureId,
        method: `GET`
    })
}

const getOrgStructureAttribute = (orgStructureId) => {
    return request({
        url:    `/organizations/getOrgStructureAttribute?orgStructureId=`+orgStructureId,
        mehotd: `GET`
    })
}

const getOrgWorker = (orgIds, workerIds,page,limit,searchText,orgId,structureId) => {
    let query = '';

    if (orgIds) {
        query = `&orgIds=${orgIds}`;
    } else if (workerIds) {
        query = `&workerIds=${workerIds}`;
    } else if (orgId){
        query = `&orgId=${orgId}&structureId=${structureId}`
    }

    return request({
        url: `/organizations/getOrgWorker?page=${page}&limit=${limit}&searchText=${searchText}` + query,
        method: 'GET'
    })
}

const getOrgPosition = (id) => {
    return request({
        url: '/organizations/getOrgPosition?state_structure_id='+id,
        method: 'GET'
    })
}

const setOrgStructure = (data) => {
    return request({
        url: '/organizations/setOrgStructure',
        method: 'POST',
        data: data
    })
}

const updateOrgStructure = (data) => {
    return request({
        url: '/organizations/updateOrgStructure',
        method: 'POST',
        data: data
    })
}

const orgAgendaDetail = (org_id) => {
    return request({
        url:'/organizations/orgAgendaDetail?org_id='+org_id,
        method: 'GET'
    });
}

const orgWorkerDetail = (org_id) => {
    return request({
        url:'/organizations/orgWorkerDetail?org_id='+org_id,
        method: 'GET'
    });
}

const getStateStructure = () => {
    return request({
        url:'/organizations/getStateStructure',
        method: 'GET'
    })
}
const getStateStructureByOrgId = (id) => {
    return request({
        url:'/organizations/getStateStructureByOrgId?org_id='+id,
        method: 'GET'
    })
}

const getStateStructurePicture = (type, id) => {
    return request({
        url:'/organizations/getOrganizationStructure/File/'+ type+ '/'+ id,
        method: 'GET'
    })
}

const getStateStructureDetail = (org_id) => {
    return request({
        url:'/organizations/getStateStructureDetail?org_id='+org_id,
        method: 'GET'
    })
}

const getStructureAttribute = (id) => {
    return request({
        url:'/organizations/getStructureAttribute?structure_id='+id,
        method: 'GET'
    })
}

const getStateStructureAnother = (org_id) => {
    return request({
        url:'/organizations/getStateStructureAnother?org_id='+org_id,
        method: 'GET'
    })
}

const getOrganizationIntroductions = () => {
    return request({
        url:'/organizations/getOrganizationIntroductions',
        method: 'GET'
    })
}

const getOrgContact = (org_id) => {
    return request({
        url:'/organizations/getOrgContact/'+org_id,
        method: 'GET'
    })
}

const getOrganizationHeaderData = () => {
    return request({
        url:'/organizations/getOrganizationHeaderData',
        method: 'GET'
    })
}

const retrieveOrganizationIntroduction = () => {
    return request({
        url:'/organizations/retrieveOrganizationIntroduction',
        method: 'GET'
    })
}

const getOrgEmployees = (id) => {
    return request({
        url:`/organizations/getOrgEmployees/${id}`,
        method: 'GET'
    })
}

const getOrgAttrs = () => {
    return request({
        url:'/organizations/getOrgAttrs',
        method: 'GET'
    })
}

const getOrgListForChat = () => {
    return request({
        url: '/organizations/getOrgListForChat',
        method: 'GET',
    })
}

const getOrgPositionDetail = (id) => {
    return request({
        url:'/organizations/getOrgPositionDetail?id='+id,
        method: 'GET'
    })
}

const getChatHistory = (user1, user2) => {
    return request({
        url:`/organizations/getChatHistory?user1=${user1}&user2=${user2}`,
        method: 'GET'
    })
}

const getAttrsOrg = () => {
    return request({
        url:`/organizations/getAttrsOrg`,
        method: 'GET'
    })
}

const getUpdatedAttributes = (org_id) => {
    return request({
        url: `/organizations/getUpdatedAttributes?org_id=${org_id}`,
        method: 'GET'
    })
}

const updatedStateAttributes = (org_id,days) => {
    return request({
        url: `/organizations/updatedStateAttributes?org_id=${org_id}&days=${days}`,
        method: 'GET'
    })
}

const lastUpdateDateOrgAndWorker = (org_id) => {
    return request({
        url:    `/organizations/lastUpdateDateOrgAndWorker/${org_id}`,
        method: `GET`
    })
}

const organizationStructureStat = (org_id, ss_id) => {
    return request({
        url:    `/organizations/structure/stat?org_id=${org_id}&ss_id=${ss_id}`,
        method: `GET`
    })
}

const organizationStructureStatDetail = (org_id, econ_code) => {
    return request({
        url:    `/organizations/structure/stat/detail?org_id=${org_id}&econ_code=${econ_code}`,
        method: `GET`
    })
}

const getLogo = (org_id) => {
    org_id = org_id || ''
    return request({
        url: `/organizations/getLogo?org_id=${org_id}`,
        method: 'GET'
    })
}

const getOrgWorkersByStructureId = (data) => {
    return request({
        url: `/organizations/getOrgWorkersByStructureId/${data}`,
        method: 'GET'
    })
}

const updateOrganizationSettingList = (body) => {
    return (request({
        url   : `/organizations/settings/updateList`,
        method: 'POST',
        data  :body
    }))
}
const getMinistries= ()=>{
    return request({
        url: `/organizations/getMinistries/`,
        method: 'GET'
    })
}
const getMinistryList= ()=>{
    return request({
        url: `/organizations/getMinistryList/`,
        method: 'GET'
    })
}
const getCurrentMinistryList= (year) => {
    return request({
        url: `/organizations/getCurrentMinistryList?year=${year}`,
        method: 'GET'
    })
}
const getParentOrgs= ()=>{
    return request({
        url: `/organizations/getParentOrgs/`,
        method: 'GET'
    })
}
const getChildOrgs= (id)=>{
    return request({
        url: `/organizations/getChildOrgs/${id}`,
        method: 'GET'
    })
}
const getOrgStateStructureInfo = (orgId) => {
    return (request({
        url   : `/organizations/stateStructureInfo/${orgId}`,
        method: 'GET'
    }))
}
const getOrgHeadInfo = (orgId) => {
    return (request({
        url   : `/organizations/orgHeadUrlInfo/${orgId}`,
        method: 'GET'
    }))
}

const get260Org = (orgId) => {
    return (request({
        url   : `/organizations/get260/` + orgId,
        method: 'GET'
    }))
}
const getStateStructureByType = (state_structure_id) => {
    return (request({
        url   : `/organizations/getStateStructureByType/${state_structure_id}`,
        method: 'GET'
    }))
}

const getOrganizationParentView = (number_of_ancestors) => {
    return (request({
        url   : `/organizations/getOrganizationParentView?number_of_ancestors=${number_of_ancestors}`,
        method: 'GET'
    }))
}
const getStateStructureAttributeList=(id,type)=>{
    return (request({
        url   : `/organizations/getStateStructureAttributeList/` +id + '/' + type,
        method: 'GET'
    }))
}
const getAttributeBySSI=(sattr_id)=>{
    return (request({
        url   : `/organizations/getAttributeBySSI/` +sattr_id,
        method: 'GET'
    }))
}
const updateOrgStateAttribute= (body)=>{
    return (request({
        url   : `/organizations/updateOrgStateAttribute/`,
        method: 'POST',
        data  :body
    }))
}
const updateStateAttribute= (body)=>{
    return (request({
        url   : `/organizations/updateStateAttribute/`,
        method: 'POST',
        data  :body
    }))
}
const updateStructureAndWorker = (mainID, toID, toOrgID) => {
    return request({
        url:    `/organizations/updateStructureAndWorker`,
        method: `POST`,
        data:   {mainID, toID, toOrgID}
    })
}

const updateAttributeDetails= (body)=>{
    return (request({
        url   : `/organizations/updateAttributeDetails/`,
        method: 'POST',
        data  :body
    }))
}

const getByIdOrganizationParentView = (id) => {
    return (request({
        url   : `/organizations/getByIdOrganizationParentView/${id}`,
        method: 'GET'
    }))
}

const getOrganizationParentViewByParentId = (number_of_ancestors, parent_id) => {
    return (request({
        url   : `/organizations/getOrganizationParentView?number_of_ancestors=${number_of_ancestors}&parent_id=${parent_id}`,
        method: 'GET'
    }))
}

const getAllOrganizations = () => {
    return (request({
        url: `/admin/getAllOrganizations/list`,
        method: 'GET'
    }))
}

const getFileTypes = () => {
    return(request({
        ulr: `/admin/getAllOrganizations/list`,
        method: 'GET'
    }))
}
const addGeneralInfo = (body) => {
    return request({
        url: '/organizations/addGeneralInfo',
        method: 'POST',
        data: body,
    })
}
const removeAttributeDetails = (id) => {
    return request({
        url: '/organizations/removeAttributeDetails/'+id,
        method: 'POST'
    })
}
const removeSSA = (id) => {
    return request({
        url: '/organizations/removeSSA/'+id,
        method: 'POST'
    })
}

const removeOSA = (id) => {
    return request({
        url: '/organizations/removeOSA/'+id,
        method: 'POST'
    })
}

const getTestOrgDetail = () => {
    return request({
        url: '/organizations/getTestOrgDetail',
        method: 'GET'
    })
}
const getFileNameStateStructure = (orgId) => {
    return (request({
        url   : `/organizations/getFileNameStateStructure/` + orgId,
        method: 'GET'
    }))
}
const getWorkStatus = () => {
    return request({
        url: `/organizations/getWorkStatus`,
        method: 'GET'
    })
}

const getOrganizationIncludeParent = (orgIds) => {

    let query = "";

    if (orgIds) {
        query = `?orgIds=${orgIds}`;
    }

    return request({
        url: `/organizations/getOrganizationIncludeParent${query}`,
        method: 'GET'
    });
}

const getAimagList = () => {
    return request({
        url: `/organizations/getAimagList`,
        method: 'GET'
    });
}

const getOrgWorkerFile = (workerId) => {
    return (request({
        url   : `/organizations/getOrgWorkerFile/` + workerId,
        method: 'GET'
    }))
}

const updateWorkerEconomicCategory = (body) => {
    return request({
        url: '/organizations/updateWorkerEconomicCategory',
        method: 'POST',
        data: body,
    })
}

const getOrgBudgetOrganization = (orgId) => {
    return (request({
        url   : `/organizations/getOrgBudgetOrganization/` + orgId,
        method: 'GET'
    }))
}

const getOrgBudget = () => {
    return request({
        url: `/organizations/getOrgBudget`,
        method: 'GET'
    });
}

const updateOrgBudgetOrganization= (body)=>{
    return (request({
        url   : `/organizations/updateOrgBudgetOrganization/`,
        method: 'POST',
        data  :body
    }))
}

const getOrgBudgetByWorker = (budget_year,orgName,orgId) => {
    return (request({
        url   : `/organizations/getOrgBudgetByWorker/` + budget_year + '/' + orgName + `?orgId=${orgId}`,
        method: 'GET'
    }))
}

const getAttributeTypeName = (typeValue,orgId) => {
    return (request({
        url   : `/organizations/getAttributeTypeName/` + typeValue + '/' + orgId,
        method: 'GET'
    }))
}

const getOrgStructureByState= ()=>{
    return request({
        url: `/organizations/getOrgStructureByState/`,
        method: 'GET'
    })
}

const getOrgStructureByStateFilter= (orgId)=>{
    return request({
        url: `/organizations/getOrgStructureByStateFilter?orgId=${orgId}`,
        method: 'GET'
    })
}

const removeOrgStructure = (id) => {
    return request({
        url: '/organizations/removeOrgStructure/' + id,
        method: 'POST'
    })
}

const getStructureHeadInfo = (org_id) => {
    return (request({
        url   : `/organizations/getStructureHeadInfo/${org_id}`,
        method: 'GET'
    }))
}

const getOrganizationDuty = (orgId) =>{
    return (request({
        url: `/organizations/getOrganizationDuty/${orgId}`,
        method: 'GET'
    }))
}

const getOrgStrructureAttributeId = (osId) =>{
    return (request({
        url: `/organizations/getOrgStrructureAttributeId/${osId}`,
        method: 'GET'
    }))
}

const getOsAttributeTypeName = (typeValue,osId) => {
    return (request({
        url   : `/organizations/getOsAttributeTypeName/` + typeValue + '/' + osId,
        method: 'GET'
    }))
}

const getOrgHeadName = () => {
    return (request({
        url: `/organizations/getOrgHeadName`,
        method: 'GET'
    }))
}

const getDashboardOrg = () => {
    return (request({
        url: `/dashboard/getOrgs`,
        method: 'GET'
    }))
}

const getOrgLogo = (chosenValue) => {
    return (request({
        url: `/dashboard/getOrgLogo/${chosenValue}`,
        method: 'GET'
    }))
}

const getMainInfo = (chosenValue) => {
    return (request({
        url: `/dashboard/getMainInfo/${chosenValue}`,
        method: 'GET'
    }))
}

const getOrgStructureInfo = (chosenValue) => {
    return (request({
        url: `/dashboard/getOrgStructureInfo/${chosenValue}`,
        method: 'GET'
    }))
}

const getBudgetInfo = (chosenValue) => {
    return (request({
        url: `/dashboard/getBudgetInfo/${chosenValue}`,
        method: 'GET'
    }))
}

const getProjectAndPlanInfo = (chosenValue, type) => {
    return (request({
        url: `/dashboard/getProjectAndPlanInfo/${chosenValue}/${type}`,
        method: 'GET'
    }))
}

const getOrgUserAccessCount = (id) => {
    return(request({
        url: `/organizations/getOrgUserAccessCount?id=${id}`,
        method: 'GET'
    }))
}
const getOrgWorkerToConnect = () => {
    return(request({
        url: `/organizations/getOrgWorkerToConnect`,
        method: 'GET'
    }))
}
const getOrsStructureHistoryDays = () => {
    return(request({
        url: `/organizations/getOrsStructureHistoryDays`,
        method: 'GET'
    }))
}
const removeOrgWorkerFile = (fileWorkerId) => {
    return (request({
        url   : `/organizations/removeOrgWorkerFile?fileWorkerId=${fileWorkerId}`,
        method: 'DELETE'
    }))
}

const archiveOrgWorkerFile = (fileId) => {
    return (request({
        url   : `/organizations/archiveOrgWorkerFile?fileId=${fileId}` ,
        method: 'PUT'
    }))
}

const OrganizationService = {
    get,
    orgListWithParam,
    getRefOrganization,
    orgIdTake,
    getRefOrganization1,
    changeOrgType,
    organizationStat,
    organizationSelect,
    create,
    stat,
    getList,
    orgTypeWithQuery,
    OrgBudget,
    orgDetailAttr,
    orgPersonalInfo,
    usersList,
    loadOrgTypeDetails,
    orgDetailWithParam,
    organizationsSettingList,
    orgSettingsListForScheduleEdit,
    organizationStatWithExtension,
    fetchOrgList,
    fetchOrgListByParentId,
    fetchOrgListL1,
    getCompanyStructure,
    //, update, delete, etc. ...
    orgBuilderCreate,
    orgBuilderList,
    orgBuilderDelete,
    orgFormUpdate,
    orgWorkerDataSave,
    setOrgWorker,
    orgWorkerDataById,
    getOrgStructure,
    getOrgStructureName,
    getOrgStructureAttribute,
    getOrgWorker,
    getOrgPosition,
    setOrgStructure,
    updateOrgStructure,
    updateOrgWorker,
    getOrgWorkerFData,
    orgAgendaDetail,
    orgWorkerDetail,
    getStateStructure,
    getStateStructureDetail,
    getStructureAttribute,
    getOrganizationIntroductions,
    getStateStructureAnother,
    getStateStructurePicture,
    getOrgContact,
    getOrganizationHeaderData,
    getOrgEmployees,
    retrieveOrganizationIntroduction,
    getOrgListForChat,
    getOrgPositionDetail,
    getOrgAttrs,
    getAllOrganizationInfo,
    submitOrgWorkerData,
    getWorkerCount,
    getChatHistory,
    getAttrsOrg,
    getUpdatedAttributes,
    updatedStateAttributes,
    organizationStructureStat,
    organizationStructureStatDetail,
    updateOrganizationSettingList,
    getOrgWorkersByStructureId,
    getOrgBudgetType,
    get260Org,
    getOrgStateStructureInfo,
    getOrgHeadInfo,
    getHubabuba,
    getStateStructureByType,
    getByIdOrganizationParentView,
    getOrganizationParentView,
    getOrganizationParentViewByParentId,
    getParentOrgs,
    getChildOrgs,
    getMinistries,
    getAllOrganizations,
    getFileTypes,
    getMinistryList,
    addGeneralInfo,
    getStateStructureAttributeList,
    updateStateAttribute,
    lastUpdateDateOrgAndWorker,
    updateStructureAndWorker,
    getAttributeBySSI,
    updateAttributeDetails,
    removeAttributeDetails,
    removeSSA,
    removeOSA,
    getTestOrgDetail,
    getStateStructureByOrgId,
    getFileNameStateStructure,
    getOrganizationIncludeParent,
    getWorkStatus,
    getAimagList,
    getLogo,
    getOrgWorkerFile,
    updateWorkerEconomicCategory,
    getOrgBudgetOrganization,
    getOrgBudget,
    updateOrgBudgetOrganization,
    getOrgBudgetByWorker,
    getAttributeTypeName,
    getOrgStructureByState,
    getOrgStructureByStateFilter,
    removeOrgStructure,
    getStructureHeadInfo,
    getOrganizationDuty,
    getOrgStrructureAttributeId,
    getOsAttributeTypeName,
    getOrgHeadName,
    updateOrgStateAttribute,
    getDashboardOrg,
    getOrgLogo,
    getMainInfo,
    getOrgStructureInfo,
    getBudgetInfo,
    getProjectAndPlanInfo,
    getCurrentMinistryList,
    getOrgUserAccessCount,
    getOrgWorkerToConnect,
    getOrsStructureHistoryDays,
    removeOrgWorkerFile,
    archiveOrgWorkerFile
    
}
export default OrganizationService;

import React, { useState, useEffect } from "react"
import notify from "devextreme/ui/notify"
import BudgetService from "../../../../services/api/budget"
import _ from "lodash"
import { budgetPalettes, blueColorGradient, numberWithCommas, redColorGradient, getStripValues } from "../../../../util"
import Chart, {
  ArgumentAxis,
  Border,
  CommonSeriesSettings,
  Crosshair,
  Grid,
  Label,
  Legend,
  LoadingIndicator,
  Point,
  ScrollBar,
  Series,
  Strip,
  Tooltip,
  ValueAxis,
  ZoomAndPan,
} from "devextreme-react/chart"
import LoadPanelComponent from "../../../budget/components/loadPanel"
import EmptyData from "../../../budget/components/empty"

const ZarlagaLastChart = (props) => {
  const [chartData, setChartData] = useState([])
  const [todotgolData, setTodotgolData] = useState([])
  const [loader, setLoader] = useState(true)
  const [defaultChart, setDefaultChart] = useState(true)

  const loadData = async () => {
    try {
      let r = await BudgetService.getExpenseLastService(props.filterOrgText,props.orgId)
      /*let array = _.sortBy(r, (e) => {
        return e.budget_year
      })*/
      setChartData(r)
    } catch (e) {
      notify(e.message, "error", 2000)
    } finally {
      setLoader(false)
    }
  }

  const loadData1 = async () => {
    try {
      let r = await BudgetService.getExpenseByTodotgol(props.filterOrgText,props.orgId)
      let array = _.sortBy(r, (e) => {
        return e.budget_year
      })
      setTodotgolData(array)
    } catch (e) {
      notify(e.message, "error", 2000)
    } finally {
      setLoader(false)
    }
  }

  useEffect(() => {
    loadData()
    loadData1()
  }, [props.filterOrgText, defaultChart,props.orgId])

  const customizeTooltip = (arg) => {
    if (arg.seriesName === "Төсвийн өөрчлөлт") {
      let r = _.find(chartData, {
        budget_year: (arg.argument * 1 - 1).toString(),
      })
      let q = (
        arg.value - (r && r.tusuv) > 0
          ? arg.value / (r && r.tusuv)
          : (r && r.tusuv) / arg.value
      ).toFixed(2)
      let p = ((arg.value * 100) / (r && r.tusuv) - 100).toFixed(2)
      let d = arg.value - (r && r.tusuv)
      let f = d < 0 ? "бага" : "их"
      if (isFinite(q) && !isNaN(q)) {
        return {
          html: `<div class="tooltip-header">
            <span>Төсөв ${arg.argumentText} он:</span>
            <span>${numberWithCommas(arg.value / 1000000)} </span>
            </div>
            <hr/>
            <b><i>Өмнөх жилээс</i></b><br/>
            <span class="tooltip-series-name">Зөрүү:</span> ${numberWithCommas(d / 1000000)}<br/> 
            <span class="tooltip-series-name">Хувь:</span> ${p}%<br/> 
            <span class="tooltip-series-name">Өөрчлөлт:</span> ${q} дахин ${f}`,
        }
      } else {
        return {
          html: `<div class="tooltip-header">
          <span>Төсөв ${arg.argumentText} он:</span>
          <span>${numberWithCommas(arg.value / 1000000)} </span>
          </div>`,
        }
      }
    } else if (arg.seriesName === "Гүйцэтгэлийн өөрчлөлт") {
      let r = _.find(chartData, {
        budget_year: (arg.argument * 1 - 1).toString(),
      })
      let q = (
        arg.value - (r && r.guitsetgel) > 0
          ? arg.value / (r && r.guitsetgel)
          : (r && r.guitsetgel) / arg.value
      ).toFixed(2)
      let p = ((arg.value * 100) / (r && r.guitsetgel) - 100).toFixed(2)
      let d = arg.value - (r && r.guitsetgel)
      let f = d < 0 ? "бага" : "их"
      let r1 = _.find(chartData, { budget_year: (arg.argument * 1).toString() })
      let u = (r1 && r1.tusuv) - arg.value
      let u1 = ((arg.value * 100) / (r1 && r1.tusuv)).toFixed(2)
      if (isFinite(q) && !isNaN(q)) {
        return {
          html: `<div class="tooltip-header">
          <span>Гүйцэтгэл ${arg.argumentText} он:</span>
          <span>${numberWithCommas(arg.value / 1000000)}</span>
          </div>
          <hr/>
          <b><i>Төсвөөс </b></i><br/>
          <span class="tooltip-series-name">Зөрүү: </span>${numberWithCommas(u / 1000000)} <br/>
          <span class="tooltip-series-name" style={{marginBottom: 10}}>Xувь: </span>${u1}% <br/>
          <hr/>
          <b><i>Өмнөх жилээс </b></i><br/>
          <span class="tooltip-series-name">Зөрүү: </span>${numberWithCommas(d / 1000000)} <br/>
          <span class="tooltip-series-name">Хувь: </span>${p}% <br/>
          <span class="tooltip-series-name">Өөрчлөлт: </span>${q} дахин ${f}<br/> `,
        }
      } else {
        return {
          html: `<div class="tooltip-header">
          <span>Төсөв ${arg.argumentText} он:</span>
          <span>${numberWithCommas(arg.value / 1000000)} </span>
          </div>`,
        }
      }
    } else if (arg.seriesName === "Гүйцэтгэл") {
      return {
        html: `<div class="tooltip-header">
        <span>Гүйцэтгэл ${arg.argumentText} он:</span>
        <span>${numberWithCommas(arg.value / 1000000)}</span>
        </div>
        <hr/>
        <b><i>Төсвөөс </b></i><br/>
        <span class="tooltip-series-name">Зөрүү: </span> ${numberWithCommas((arg.point.data.tusuv - arg.value) / 1000000)}<br/> 
        <span class="tooltip-series-name">Хувь:</span> ${((arg.value / 1000000) / (arg.point.data.tusuv / 1000000) * 100).toFixed(2)}%`
      }
    }
    else {
      return {
        html: `<div class="tooltip-header">
        <span>${arg.seriesName} ${arg.argumentText} он:</span>
        <span>${numberWithCommas(arg.value / 1000000)}</span>
        </div>
        <hr/>
        <b><i>Төсвөөс</b></i><br/>
        <span class="tooltip-series-name">Зөрүү:</span> ${numberWithCommas((arg.point.data.tusuv - arg.value) / 1000000)}<br/>
        <span class="tooltip-series-name">Хувь: </span> ${(arg.percent * 100).toFixed(2)}% 
        `,
      }
    }
  }

  const customizeTooltipTodotgol = (arg) => {
    if (arg.seriesName === "Батлагдсан") {
      let r = _.find(todotgolData, {
        budget_year: (arg.argument * 1 - 1).toString(),
      })
      if (r && r.batlagdsan) {
        let q = (
          arg.value - (r && r.batlagdsan)
            ? arg.value / (r && r.batlagdsan)
            : (r && r.batlagdsan) / arg.value
        ).toFixed(2)
        let p = ((arg.value * 100) / (r && r.batlagdsan) - 100).toFixed(2)
        let d = arg.value - (r && r.batlagdsan)
        let f = d < 0 ? "бага" : "их"
        if (isFinite(q) && !isNaN(q)) {
          return {
            html: `<div class="tooltip-header">
              <span>${arg.seriesName} ${arg.argumentText} он:</span>
              <span>${numberWithCommas(arg.value / 1000000)}</span>
              </div>
              <hr/>
              <b><i>Өмнөх жилээс</b></i><br/>
              <span class="tooltip-series-name">Зөрүү:</span> ${numberWithCommas(d / 1000000)}<br/> 
              <span class="tooltip-series-name">Хувь:</span> ${p}%<br/>
              <span class="tooltip-series-name">Өөрчлөлт:</span> ${q} дахин ${f}`,
          }
        }
      } else {
        return {
          html: `<div class="tooltip-header">
          <span>${arg.seriesName} ${arg.argumentText} он:</span>
          <span>${numberWithCommas(arg.value / 1000000)}</span>
          </div>`
        }
      }
    } else if (arg.seriesName === "Тодотгол") {
      if (arg.point.data.batlagdsan) {
        let a = arg.value - arg.point.data.batlagdsan
        let p = arg.point.data.batlagdsan ? ((arg.value * 100) / (arg.point.data.batlagdsan) - 100).toFixed(2) + `%` : '--'
        return {
          html: `<div class="tooltip-header">
          <span>${arg.seriesName} ${arg.argumentText} он:</span>
          <span>${numberWithCommas(arg.value / 1000000)}</span>
          </div>
          <hr/>
          <b><i>Батлагдсан төсвөөс </b></i><br/>
          <span class="tooltip-series-name">Зөрүү:</span> ${numberWithCommas(a / 1000000)}<br/>
          <span class="tooltip-series-name">Хувь:</span> ${p}`
        }
      } else {
        return {
          html: `<div class="tooltip-header">
          <span>${arg.seriesName} ${arg.argumentText} он:</span>
          <span>${numberWithCommas(arg.value / 1000000)}</span>
          </div>`
        }
      }
    } else if (arg.seriesName === "Тодотгол-2") {
      if (arg.point.data.todotgoson1) {
        let a = arg.value - arg.point.data.todotgoson1
        let p = arg.point.data.todotgoson1 ? ((arg.value * 100) / (arg.point.data.todotgoson1) - 100).toFixed(2) + `%` : '--'
        return {
          html: `<div class="tooltip-header">
          <span>${arg.seriesName} ${arg.argumentText} он:</span>
          <span>${numberWithCommas(arg.value / 1000000)}</span>
          </div>
          <hr/>
          <b><i>Тодотголоос </b></i><br/>
          <span class="tooltip-series-name">Зөрүү:</span> ${numberWithCommas(a / 1000000)}<br/>
          <span class="tooltip-series-name">Хувь:</span> ${p}`
        }
      } else {
        return {
          html: `<div class="tooltip-header">
          <span>${arg.seriesName} ${arg.argumentText} он:</span>
          <span>${numberWithCommas(arg.value / 1000000)}</span>
          </div>`
        }
      }
    }
  }

  const customizeValueAxisLabel = (e) => {
    return `${e.value / 1000000000}`
  }


  if (chartData.length === 0 && !loader) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          family: "Segoe UI",
        }}
      >
        <EmptyData />
      </div>
    )
  }

  const handleLegend = (e) => {
    if (e.target.isVisible()) {
      e.target.hide();
    } else {
      e.target.show();
    }
  }

  function markerTemplate(item) {
    const color = item.series.isVisible() ? item.marker.fill : '#888';
    return (
      <svg>
        <rect x={0} y={0} width={12} height={12} fill={color}
          rx={item.text.includes('өөрчлөлт') ? 6 : 0}
          ry={item.text.includes('өөрчлөлт') ? 6 : 0}></rect>
      </svg>
    );
  }

  function markerTemplateTodotgol(item) {
    const color = item.series.isVisible() ? item.marker.fill : '#888';
    return (
      <svg>
        <rect x={0} y={0} width={12} height={12} fill={color} rx={6} ry={6}></rect>
      </svg>
    );
  }

  function customizeColor(items) {
    if (items.seriesName === 'Гүйцэтгэл') {
      return { color: blueColorGradient[items.index] }
    } else if (items.seriesName === 'Урсгал зардал') {
      return { color: redColorGradient[items.index] }
    }
  }

  return (
    <div id="load11">
      <LoadPanelComponent position={{ of: "#load11" }} visible={loader} />
      <div style={{ display: 'flex', gap: '3rem', backgroundColor: '#EEF0F4', padding: 7, marginBottom: '2rem' }}>
        <div style={{ display: 'flex', gap: 5 }}>
          <input type="radio" id="guitsetgel" name="guitsetgel"
            checked={defaultChart} onChange={() => setDefaultChart(true)}
          />
          <label for="guitsetgel" style={{ color: '#15186A', fontWeight: 'bold', fontSize: 12 }}>Бүрдлээр</label>
        </div>
        <div style={{ display: 'flex', gap: 5 }}>
          <input type="radio" id="tusviin_todotgol" name="tusviin_todotgol"
            checked={!defaultChart} onChange={() => setDefaultChart(false)}
          />
          <label for="tusviin_todotgol" style={{ color: '#15186A', fontWeight: 'bold', fontSize: 12 }}>Төсвөөр</label>
        </div>
      </div>
      {defaultChart ?
        <Chart
          dataSource={chartData}
          onLegendClick={handleLegend}
          customizePoint={customizeColor}
        >
          <LoadingIndicator enabled={true} />
          <Crosshair enabled={true} label={true} />
          <ValueAxis valueType="numeric" allowDecimals={false}>
            <Label format='#' customizeText={customizeValueAxisLabel} />
          </ValueAxis>
          <ArgumentAxis tickInterval={1} argumentType='numeric' visualRange={{ endValue: new Date().getFullYear() }}>
            {getStripValues().map((e) => (
              <Strip startValue={e.startValue} endValue={e.endValue} color="#f5f5f5" />
            ))}
            <Label format='#' argumentFormat='format' />
          </ArgumentAxis>
          <ScrollBar visible={true} width={5} opacity={0.5} />
          <ZoomAndPan argumentAxis="both" />
          <CommonSeriesSettings
            argumentField="budget_year" >
            <Label visible={false} />
          </CommonSeriesSettings>
          <Series stack="tusuv" type="stackedbar" valueField="ursgal_zardal" name="Урсгал зардал" color="#F93A3A" border={{ color: 'white', width: 1, visible: true }} />
          <Series stack="tusuv" type="stackedbar" valueField="hurungiin_zardal" name="Хөрөнгийн зардал" color="#f5a2a4" border={{ color: 'white', width: 1, visible: true }} />
          <Series valueField="tusuv" name="Төсвийн өөрчлөлт" color="#e12c31" type="spline" dashStyle="solid" visible={false}>
            <Point size={10} />
          </Series>
          <Series type="bar" barPadding={0.5} valueField="guitsetgel" name="Гүйцэтгэл" cornerRadius={6} color='#003696' />
          <Series valueField="guitsetgel" name="Гүйцэтгэлийн өөрчлөлт" color="#003696" type="spline" dashStyle="solid">
            <Point size={10} />
          </Series>
          <Legend
            visible={true}
            verticalAlignment="bottom"
            horizontalAlignment="center"
            font={{ family: "Segoe UI" }}
            markerRender={markerTemplate}
          />
          <Tooltip
            zIndex={100000}
            enabled={true}
            customizeTooltip={customizeTooltip}
            location="edge"
            font={{ color: '#fff' }} color="#555"
            cornerRadius={6}
            border={{ visible: false }}
          />
        </Chart>
        :
        <Chart
          dataSource={todotgolData}
          onLegendClick={handleLegend}
        >
          <LoadingIndicator enabled={true} />
          <Crosshair enabled={true} label={true} />
          <ValueAxis valueType="numeric" allowDecimals={false}>
            <Label format='#' customizeText={customizeValueAxisLabel} />
          </ValueAxis>
          <ArgumentAxis tickInterval={1} argumentType='numeric' visualRange={{ endValue: new Date().getFullYear() }} >
            {getStripValues().map((e) => (
              <Strip startValue={e.startValue} endValue={e.endValue} color="#f5f5f5" />
            ))}
            <Label format='#' argumentFormat='format' />
          </ArgumentAxis>
          <ScrollBar visible={true} width={5} opacity={0.5} />
          <ZoomAndPan argumentAxis="both" />
          <CommonSeriesSettings
            argumentField="budget_year"
            ignoreEmptyPoints={true}
            type="spline"
          >
            <Point size={8} visible={true} />
            <Border visible={false} />
          </CommonSeriesSettings>
          <Series valueField="batlagdsan" name="Батлагдсан" color="#1976d2" />
          <Series valueField="todotgoson1" name="Тодотгол" color='#f78223' />
          <Series valueField="todotgoson2" name="Тодотгол-2" color='#F93A3A' />
          <Legend
            visible={true}
            verticalAlignment="bottom"
            horizontalAlignment="center"
            font={{ family: "Segoe UI" }}
            markerRender={markerTemplateTodotgol}
          />
          <Tooltip
            zIndex={100000}
            enabled={true}
            customizeTooltip={customizeTooltipTodotgol}
            location="edge"
            font={{ color: '#fff' }} color="#555"
            cornerRadius={6}
            border={{ visible: false }}
          />
        </Chart>}
    </div>
  )
}

export default ZarlagaLastChart

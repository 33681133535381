/*eslint-disable*/
import React, {useEffect, useRef} from "react";
import 'devextreme-react/text-area';
import {number} from "prop-types";
import ReactToPrint from "react-to-print";
import Button from "devextreme-react/button";

const Fa03a = (props) => {
    const data = props.reportData
    const componentRef = useRef();
    const docDate = data.docdate
    const orgName = data.organization_model.name
    const docno = data.docnum
    const formatDate=props.formatDate
    useEffect(() => {
        console.log("props.dataaa", props.data)
    }, [props.data])
    const style = {
        text: {
            color: "#2E4765", fontWeight: "700", fontSize: ".875rem"
        },
        box: {
            border: "none", backgroundColor: "#F6F6F6", borderRadius: ".5rem", padding: ".25rem .15rem", width: "100%"
        },
        box2: {
            border: "none",
            backgroundColor: "rgb(239, 239, 239)",
            borderRadius: ".5rem",
            padding: ".25rem .4rem",
            width: "100%"
        }
    }


    return (
        <div>

            <div className="row" style={{display: 'flex', marginTop: 30,}}>

                <Button type=""
                        icon='save'
                        className="saveButton" onClick={() => props.setReportShow(false)}> Буцах </Button>

                <ReactToPrint
                    trigger={() => <Button
                        type="default"
                        icon='save'
                        className="saveButton">Хэвлэх</Button>}
                    content={() =>
                        componentRef.current}
                />
            </div>


            <div ref={componentRef}
                 style={{display: "flex", justifyContent: "space-evenly", padding: "1.5rem", flexDirection: "column",}}>
                <div className="row" style={{display: 'flex', justifyContent: "space-between", alignItems: "center",}}>
                    <div style={{marginLeft: 10, marginRight: 10}}>
                        <div style={{display: 'flex', alignItems: 'left'}}>НХМаягт ҮХ-3</div>
                    </div>
                    {/*<div  style={{marginLeft: 20, marginRight: 10, alignment: "right"}}>*/}
                    {/*    <div style={{display: 'flex', alignment: 'Right',}}>Сангийн сайдын 2017 оны .... дугаар</div>*/}
                    {/*    <div style={{display: 'flex', alignment: 'Right'}}>сарын .. өдрийн .... тоот тушаалын хавсралт</div>*/}
                    {/*</div>*/}
                    <div style={{display: 'flex', fontSize: '40', alignItems: 'Left'}}> ҮНДСЭН ХӨРӨНГӨ АШИГЛАЛТААС ХАСАХ
                        БАРИМТ №:
                    </div>
                    {/*ШИЛЖҮҮЛЭХ*/}
                    <div className='col-2' style={{marginLeft: 5,}}>
                        <div style={{display: 'flex', ...style.text}}>{docno}</div>
                    </div>

                </div>


                <div className="row" style={{display: 'flex', marginTop: "1rem"}}>
                    <div className='col-5'
                         style={{marginLeft: 5, marginRight: 10, display: "flex", justifyContent: "center"}}>
                        <div style={{display: 'flex', ...style.text}}>  {orgName}  </div>
                    </div>

                    <div className='col-5' style={{marginRight: 5, display: "flex", justifyContent: "space-evenly"}}>
                        <div style={{display: 'flex', fontSize: '20', alignItems: 'Right'}}>Огноо:</div>
                        <div style={{display: 'flex', ...style.text}}>{docDate}</div>
                    </div>
                </div>


                <div className="row" style={{display: 'flex', marginTop: 30,}}>
                    <div className='col-10'>
                        <div style={{display: 'flex', fontSize: '20', marginLeft: 10, alignItems: 'Right'}}> 1. 20...
                            оны... сарын ... ны өдрийн ....... тоот тушаал (шийдвэр) -аар томилогдсон комисс бид
                        </div>
                    </div>
                </div>

                <div className="row" style={{display: 'flex', marginTop: 30, justifyContent: "center",}}>
                    <div style={{display: 'flex', fontSize: '20', marginLeft: 10, alignItems: 'left', ...style.text}}>
                        {data.fa_name} {data.quantity }  {data.unit_meas }
                    </div>

                    <div style={{display: 'flex', fontSize: '20', marginLeft: 1, alignItems: 'Right'}}>
                        -д үзлэг хийж ашиглалтаас хасахаар тогтов.
                    </div>


                </div>

                <div className="row" style={{display: 'flex', marginTop: 30,}}>


                    <div className='col-10'>
                        <div style={{display: 'flex', fontSize: '20', marginLeft: 10, alignItems: 'Right'}}> 2. ..........................................................................................................................
                        </div>
                    </div>
                </div>






                <div className="row" style={{display: 'flex', marginTop: 30,}}>
                    <div className='col-2'>
                        <div style={{display: 'flex', fontSize: '20', marginLeft: 10, alignItems: 'Right'}}>
                            Хөрөнгийн нэр төрөл , код
                        </div>

                        <div style={{display: 'flex', fontSize: '20', marginLeft: 10, alignItems: 'Right',...style.text}}>
                            {data.typename} ,  {data.fatype}, {data.fanumber}
                        </div>


                    </div>
                    <div className='col-2'>
                        <div style={{display: 'flex', fontSize: '20', marginLeft: 10, alignItems: 'Right'}}>
                            Үйлдвэрлэсэн улс /(байгууллага)/,он
                        </div>

                        <div style={{display: 'flex', fontSize: '20', marginLeft: 10, alignItems: 'Right',...style.text}}>
                            {data.manuf}
                        </div>


                    </div>
                    <div className='col-2'>
                        <div style={{display: 'flex', fontSize: '20', marginLeft: 10, alignItems: 'Right'}}>
                            Ашиглалтад орсон он, сар, өдөр
                        </div>

                        <div style={{display: 'flex', fontSize: '20', marginLeft: 10, alignItems: 'Right',...style.text}} dataType="date" format="shortDate">
                            {formatDate(  data.date_acq)}
                        </div>


                    </div>
                    <div className='col-2'>
                        <div style={{display: 'flex', fontSize: '20', marginLeft: 10, alignItems: 'Right'}}>
                            Анхны өртөг
                        </div>

                        <div style={{display: 'flex', fontSize: '20', marginLeft: 10, alignItems: 'Right',...style.text}}>
                            {data.quantity * data.cost_org}
                        </div>


                    </div>

                    <div className='col-2'>
                        <div style={{display: 'flex', fontSize: '20', marginLeft: 10, alignItems: 'Right'}}>
                            Хуримтлагдсан элэгдэл
                        </div>

                        <div style={{display: 'flex', fontSize: '20', marginLeft: 10, alignItems: 'Right',...style.text}}>
                            {data.acder_amt}
                        </div>


                    </div>
                </div>





                <div className="row" style={{display: 'flex', marginTop: 0,}}>
                    <div className="row" style={{display: 'flex', marginLeft: 10, marginTop: 0,}}>3. Техникийн байдал, ашиглалтаас хасах шалтгаан:

                    </div>


                </div>


                <div className="col-12" style={{
                    display: 'flex',
                    marginTop: 1,
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: 'center',
                    gap: 10
                }}>
                    <div style={{display: 'flex', ...style.text}}>
                        {data.descr}
                    </div>
                    <div style={{display: 'flex'}}> . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
                        . .
                        . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
                    </div>

                    <div style={{display: 'flex'}}> . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
                        . .
                        . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
                    </div>
                    <div style={{display: 'flex'}}> . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
                        . .
                        . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
                    </div>
                </div>


                <div className="row" style={{display: 'flex', marginTop: 1,}}>
                    <div className="row" style={{display: 'flex', marginLeft: 10, marginTop: 5,}}>4. Хавсаргасан баримт бичгийн жагсаалт <div className='col-10'>
                        </div>
                    </div>
                </div>


                <div className="col-12" style={{
                    display: 'flex',
                    marginTop: 0,
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: 'center',
                    gap: 10
                }}>
                    <div style={{display: 'flex'}}> . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
                        . .
                        . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
                    </div>
                    <div style={{display: 'flex'}}> . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
                        . .
                        . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
                    </div>



                    <div style={{display: 'flex'}}> . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
                        . .
                        . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
                    </div>

                </div>

                <div className="row" style={{display: 'flex', marginTop: 1,}}>
                    <div className="row" style={{display: 'flex', marginLeft: 10, marginTop: 1,}}>Комиссын гишүүд: <div
                        className='col-10'>
                    </div>
                    </div>
                </div>
                <div className="row" style={{display: 'flex', marginTop: 10,}}>
                    <div className='col-2'>
                        <div style={{display: 'flex', fontSize: '20', marginLeft: 10, alignItems: 'Right'}}></div>
                    </div>
                    <div className='col-3'>
                        <div style={{
                            display: 'flex',
                            fontSize: '20',
                            marginLeft: 10,
                            alignItems: 'Right'
                        }}>----------------------
                        </div>
                    </div>
                    <div className='col-3'>
                        <div style={{
                            display: 'flex',
                            fontSize: '20',
                            marginLeft: 10,
                            alignItems: 'Right'
                        }}>----------------------
                        </div>
                    </div>
                    <div className='col-3'>
                        <div style={{
                            display: 'flex',
                            fontSize: '20',
                            marginLeft: 10,
                            alignItems: 'Right'
                        }}>----------------------
                        </div>
                    </div>
                </div>
                <div className="row" style={{display: 'flex', marginTop: -2,}}>
                    <div className='col-2'>
                        <div style={{display: 'flex', fontSize: '20', marginLeft: 10, alignItems: 'Right'}}></div>
                    </div>
                    <div className='col-3'>
                        <div style={{display: 'flex', fontSize: '20', marginLeft: 10, alignItems: 'Right'}}>( албан
                            тушаал )
                        </div>
                    </div>
                    <div className='col-3'>
                        <div style={{display: 'flex', fontSize: '20', marginLeft: 10, alignItems: 'Right'}}>( овог нэр
                            )
                        </div>
                    </div>
                    <div className='col-3'>
                        <div style={{display: 'flex', fontSize: '20', marginLeft: 10, alignItems: 'Right'}}>( гарын үсэг
                            )
                        </div>
                    </div>
                </div>
                <div className="row" style={{display: 'flex', marginTop: 10,}}>
                    <div className='col-2'>
                        <div style={{display: 'flex', fontSize: '20', marginLeft: 10, alignItems: 'Right'}}></div>
                    </div>
                    <div className='col-3'>
                        <div style={{
                            display: 'flex',
                            fontSize: '20',
                            marginLeft: 10,
                            alignItems: 'Right'
                        }}>----------------------
                        </div>
                    </div>
                    <div className='col-3'>
                        <div style={{
                            display: 'flex',
                            fontSize: '20',
                            marginLeft: 10,
                            alignItems: 'Right'
                        }}>----------------------
                        </div>
                    </div>
                    <div className='col-3'>
                        <div style={{
                            display: 'flex',
                            fontSize: '20',
                            marginLeft: 10,
                            alignItems: 'Right'
                        }}>----------------------
                        </div>

                    </div>




                </div>
                <div className="row" style={{display: 'flex', marginTop: 1,}}>
                    <div className="row" style={{display: 'flex', marginTop: 5,}}>
                        <div className='col-10'>Үндсэн хөрөнгийн бүртгэлд данснаас хасалтыг бичсэн нягтлан бодогч .........................
                        </div>
                    </div>
                </div>
                <div className="row" style={{display: 'flex', marginTop: 1, justifyContent: "center"}}>
                    <div className="row" style={{display: 'flex', marginTop: 5,}}>
                        <div className='col-10'>20.... оны ..... сарын ....... өдөр    </div>
                    </div>
                </div>
                <div className="row" style={{display: 'flex', marginTop: 1, justifyContent: "center"}}>
                    <div className="row" style={{display: 'flex', marginTop: 5,}}>
                        <div className='col-10'>    </div>
                    </div>
                </div>

                <div className="row" style={{display: 'flex', marginTop: 1, justifyContent: "center"}}>
                    <div className="row" style={{display: 'flex', marginTop: 5,}}>
                        <div className='col-10'>    </div>
                    </div>
                </div>

            </div>

            <div className="row" style={{display: 'flex', marginTop: 1, justifyContent: "center"}}>
                <div className="row" style={{display: 'flex', marginTop: 5,}}>
                    <div className='col-10'>    </div>
                </div>
            </div>
        </div>
    )
        ;
};

export default Fa03a
import React, {useState, useEffect} from "react"
import statsService from "../../../services/api/stat.service"
import _ from "lodash"
import notify from "devextreme/ui/notify"
import DataGrid, {
  Column,
  Paging,
  Summary,
  TotalItem,
} from "devextreme-react/data-grid"
import {numberWithCommas, numberWithCommas11} from "../../../util"
import PivotGridDataSource from "devextreme/ui/pivot_grid/data_source"
import {PivotGrid} from "devextreme-react"
import {
  FieldChooser,
  FieldPanel,
  HeaderFilter,
  Scrolling,
} from "devextreme-react/pivot-grid"
import {useLocalStorage} from "../../../hooks/useLocalStorage"

const NumberOne = (props) => {
  const [data, setData] = useState([])
  const [sumVal, setSumVal] = useState(0)
  const [loading, setLoading] = useState(true)
  const [isColumn, setIsColumn] = useState(false)
  const [isLocal1, setIsLocal1] = useLocalStorage("islocal")
  const [dataSource, setDataSource] = useState([])
  const [scrType, setScrType] = useState(null)

  const loadData = async (year) => {
    try {
      let r = await statsService.getData(year, props.list_id, props.tbl_id)

      if (r?.[0]?.SCR_MN_TYPE) {
        setScrType(r?.[0]?.SCR_MN_TYPE)
      }
      if (r?.[0]?.is_column) {
        setIsColumn(true)
        const dataSource = new PivotGridDataSource({
          fields: [
            {
              caption: scrType ? scrType : "Төрөл",
              dataField: "SCR_MN1",
              area: "column",
              sortBy: "displayText",
            },
            {
              caption: scrType ? scrType : "Аймаг, нийслэлээр",
              dataField: "SCR_MN",
              area: "row",
              sortBy: "displayText",
              showTotals: false,
            },
            {
              caption: "Дүн ",
              dataField: "DTVAL_CO",
              dataType: "number",
              area: "data",
              summaryType: "sum",
              width: 100,
              showGrandTotals: true,
            },
            {
              caption: "Эзлэх %",
              dataField: "DTVAL_CO",
              dataType: "number",
              width: 30,
              sortBy: "displayText",
              summaryType: "sum",
              summaryDisplayMode: "percentOfColumnGrandTotal",
              area: "data",
            },
          ],
          store: r,
        })
        setDataSource(dataSource)
      }
      let sum = _.sumBy(r, "DTVAL_CO")
      setSumVal(sum)
      setData(r)
    } catch (e) {
      notify(e.message)
      setLoading(false)
    } finally {
      setLoading(false)
    }
  }
  const cellTemp = (cellElement, cellInfo) => {
    return cellElement.append(cellInfo.row.loadIndex + 1)
  }
  const calcCellVal = (e) => {
    return ((e.DTVAL_CO * 100) / sumVal).toFixed(2)
  }
  const calcCellVal2 = (e) => {
    return ((e.DTVAL_CO_1 * 100) / sumVal).toFixed(2)
  }
  const onCellPrepared = (e) => {
    if (
      (e.cell.text !== undefined && e.cell.text.substr(6, 1) === "-") ||
      e.cell.text === ""
    ) {
      e.cell.expanded = true
      let cell = e.cellElement.querySelector(".dx-expand-icon-container")
      if (cell) cell.innerHTML = ""
    }

    if (e.area === "row") {
      e.cellElement.style.fontFamily = "Montserrat"
      e.cellElement.style.fontWeight = "semibold"
      e.cellElement.style.color = "#15186A"
      e.cellElement.style.backgroundColor = "#EEF0F4"
    }
    if (e.area === "data") {
      e.cellElement.style.fontFamily = "Montserrat"
      e.cellElement.style.fontWeight = "semibold"
      e.cellElement.style.color = "#000000"
    }
  }

  useEffect(() => {
    loadData(props.year)
  }, [props.year, props.tbl_id])

  const title = () => {
    if (scrType) {
      return scrType
    }
    if (props.isLocal == 1) {
      return "ҮЗҮҮЛЭЛТ"
    }
    return "АЙМАГ, НИЙСЛЭЛЭЭР"
  }
  if (isColumn)
    return (
      <div style={{marginBottom: 10, flex: 0.5, width: "50%"}}>
        <div className="card" style={{height: "100%"}}>
          <div className="card-header">
            <span style={{fontWeight: "700"}}>1. {title()}</span>
          </div>
          <div className="card-body" style={{padding: "1.8rem 0"}}>
            <PivotGrid
              dataSource={dataSource}
              style={{fontFamily: "'Montserrat', sans-serif"}}
              rowHeaderLayout={"tree"}
              allowSorting={true}
              showColumnTotals={false}
              allowSortingBySummary={true}
              scrolling={true}
              showColumnGrandTotals={false}
              height={500}
              onCellPrepared={onCellPrepared}
              wordWrapEnabled={true}
              width={"100%"}
              texts={{
                grandTotal: "Нийт",
                dataNotAvailable: "Мэдээлэл байхгүй байна.",
                noData: "Дата байхгүй байна.",
              }}
            >
              <Scrolling mode="virtual" />
              <HeaderFilter allowSearch={true} />
              <FieldPanel
                showColumnFields={true}
                showDataFields={true}
                showFilterFields={false}
                showRowFields={true}
                allowFieldDragging={true}
                visible={true}
              />
            </PivotGrid>
          </div>
        </div>
      </div>
    )
  return (
    <div style={{marginBottom: 10, flex: 0.5, width: "50%"}}>
      <div className="card" style={{height: "100%"}}>
        <div className="card-header">
          <span style={{fontWeight: "700"}}>1. {title()}</span>
        </div>
        <div className="card-body" style={{padding: "1.8rem 0"}}>
          <DataGrid
            dataSource={data}
            showBorders={true}
            rowAlternationEnabled={true}
            noDataText="Дата байхгүй байна."
            style={{
              fontFamily: "'Montserrat', sans-serif",
              fontWeight: "semibold",
            }}
          >
            <Paging defaultPageSize={10} />
            <Column
              caption="№"
              allowSorting={false}
              cellTemplate={cellTemp}
              alignment="center"
              width={40}
            />
            <Column caption="Аймаг" dataField="SCR_MN" />
            <Column
              dataField="DTVAL_CO"
              allowSorting={true}
              format="#,##0"
              defaultSortOrder="desc"
              caption="Тоо"
              allowFiltering={false}
              width={100}
              alignment="center"
            />
            <Column
                allowSorting={true}
                caption="Эзлэх %"
                calculateCellValue={calcCellVal}
                alignment="center"
                width={100}
              />
            {props.tbl_id === "DT_976_U01_01" && (
              <Column
                dataField="DTVAL_CO_1"
                allowSorting={true}
                format="#,##0"
                defaultSortOrder="desc"
                caption="Эерэг дээж"
                allowFiltering={false}
                width={100}
                alignment="center"
              />
            )}
            {props.tbl_id === "DT_976_U01_01" && (
              <Column
                allowSorting={true}
                caption="Эзлэх %"
                calculateCellValue={calcCellVal2}
                alignment="center"
                width={100}
              />
            )}
            <Summary>
              <TotalItem
                column="DTVAL_CO"
                summaryType="sum"
                customizeText={(data) => {
                  return `${numberWithCommas11(data.value)}`
                }}
              />
            </Summary>
          </DataGrid>
        </div>
      </div>
    </div>
  )
}

export default NumberOne

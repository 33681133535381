import React, {useEffect, useState} from "react";
import './css/styles.css';
import OrganizationService from "../../services/api/organization";
import ControlSectionOne from "./components/ControlSectionOne";

const ControlPanel = () => {
    const [mainData, setMainData] = useState([]);

    const loadOrganizationAttributes = async () => {
        const r = await OrganizationService.getOrgAttrs();
        setMainData(r);
    }

    useEffect(async () => {
        await loadOrganizationAttributes()
    }, [])

    return (
        mainData.length > 0 &&
        <div>
            <div className="custom-row">
                <div className="inside-padding-control custom-col-sm-6">
                    <div className="each-component-style">
                        <ControlSectionOne data={mainData}/>
                    </div>
                </div>
                <div className="inside-padding-control custom-col-sm-3">
                    <div className="each-component-style"/>
                </div>
                <div className="inside-padding-control custom-col-sm-3">
                    <div className="each-component-style"/>
                </div>
            </div>
            <div className="custom-row">
                <div className="inside-padding-control custom-col-sm-3">
                    <div className="each-component-style"/>
                </div>
                <div className="inside-padding-control custom-col-sm-3">
                    <div className="each-component-style"/>
                </div>
                <div className="inside-padding-control custom-col-sm-3">
                    <div className="each-component-style"/>
                </div>
                <div className="inside-padding-control custom-col-sm-3">
                    <div className="each-component-style"/>
                </div>
            </div>
        </div>
    )
}

export default ControlPanel
import React from 'react';
import FilterComponent from "./filter";
import BudgetService from "../../../../services/api/budget";
import RefService from "../../../../services/api/refs";
import {getYears, nFormatter, numberWithCommas} from "../../../../util";
import _ from "lodash";
import TabPanel, {Item} from 'devextreme-react/tab-panel';
import './expense.css'
import {CURRENT_YEAR} from "../../../../shared/constants";
import {withRouter} from 'react-router-dom'

import Chart, {
    AdaptiveLayout,
    CommonSeriesSettings,
    Size,
    Tooltip,
} from 'devextreme-react/chart';

import PivotGrid, {
    FieldChooser
} from 'devextreme-react/pivot-grid';
import LoadPanelComponent from "../loadPanel";
import {UserContext} from "../../../../hooks/UserContext";

// Global variables

class Expense extends React.Component {
    static contextType = UserContext;

    constructor(props) {
        super(props);

        const qs = new URLSearchParams(this.props.location.search);
        let econ = qs.get('type') * 1

        this.state = {
            qs: {},
            color: '',
            filter: {
                yearValue: 'Бүгд',
                // typeValue: 1,
                aimagValue: 10,
                orgValue: 0,
                budgetStatusValue: 4,
                econValue: econ
            },
            aimagArray: [],
            yearArray: ['Бүгд', ...getYears(true)],
            organizationArray: [],
            typeArray: [],
            budgetStatusArray: [],
            econArray: [
                {
                    id: 0,
                    name: 'Бүгд'
                },
                {
                    id: 1,
                    name: 'Нийт орлого ба тусламжийн дүн'
                },
                {
                    id: 2,
                    name: 'Нийт зарлага ба цэвэр зээлийн дүн'
                },
                {
                    id: 3,
                    name: 'Зардлыг санхүүжүүлэх эх үүсвэр'
                }
            ],
            budgetStatList1: [],
            budgetStatList2: [],
            budgetStatList3: [],
            loadingGrid: true,
            dataSource: {
                fields: [{
                    caption: 'Эдийн засгийн ангилал 1',
                    dataField: 'econ_category_name_1',
                    width: 200,
                    area: 'row',
                    sortBySummaryField: 'Total'
                }, {
                    caption: 'Эдийн засгийн ангилал 2',
                    dataField: 'econ_category_name_2',
                    // width: 200,
                    area: 'row',
                    sortBySummaryField: 'debit',
                    sortBySummaryPath: [2021],
                    sortOrder: 'desc'
                }, {
                    caption: 'Эдийн засгийн ангилал 3',
                    dataField: 'econ_category_name_3',
                    // width: 200,
                    area: 'row',
                    sortBySummaryField: 'debit',
                    sortBySummaryPath: [2021],
                    sortOrder: 'desc'
                }, {
                    caption: 'Эдийн засгийн ангилал 4',
                    dataField: 'econ_category_name_4',
                    // width: 200,
                    area: 'row',
                    sortBySummaryField: 'debit',
                    sortBySummaryPath: [2021],
                    sortOrder: 'desc'
                }, {
                    caption: 'Эдийн засгийн ангилал 5',
                    dataField: 'econ_category_name',
                    // width: 200,
                    area: 'row',
                    sortBySummaryField: 'debit',
                    sortBySummaryPath: [2021],
                    sortOrder: 'desc'
                }, {
                    dataField: 'budget_year',
                    dataType: 'budget_year',
                    area: 'column',
                    sortOrder: 'desc'
                }, {
                    caption: 'Нийт',
                    dataField: 'debit',
                    dataType: 'number',
                    summaryType: 'sum',
                    format: {
                        type: 'fixedPoint',
                        precision: 2
                    },
                    area: 'data'
                }],
                store: []
            },
            isLevel1: false
        }
    }

    componentDidMount() {
        this.budgetDetail();
        this.getBudget();

        let isLevel1 = _.find(this.context.user.roles, function (o) {
            return o.role_code === '2' || o.role_code === '1' || o.role_code === '0'
        });

        if (isLevel1) {
            // this.getAimag();
            this.getOrganization();
            this.getBudgetStat();
        }

        this.setState({
            isLevel1: !!isLevel1
        })
    }

    budgetDetail = () => {
        this.setState({
            loadingGrid: true
        }, () => {
            BudgetService.budgetDetailView({
                yearValue: this.state.filter.yearValue,
                typeValue: this.state.filter.typeValue,
                budgetStatusValue: this.state.filter.budgetStatusValue,
                orgValue: this.state.filter.orgValue,
                econValue: this.state.filter.econValue
            }).then(res => {
                this.setState({
                    dataSource: {
                        store: res
                    },
                    loadingGrid: false
                });

                this._pivotGrid.bindChart(this._chart, {
                    dataFieldsDisplayMode: 'splitPanes',
                    alternateDataFields: false
                });
            }).catch(e => {
                this.setState({
                    budgetDetail: [],
                    loadingGrid: false
                });
            });
        });
    }

    getAimag = () => {
        RefService.getAimag().then(res => {
            this.setState({
                aimagArray: res.data || []
            });

            this.selectedAimag(this.state.filter.aimagValue);
        }).catch(e => {
            console.log(e);
            this.setState({
                aimagArray: []
            })
        })
    };

    selectedAimag = (aimagId) => {
        this.getOrganization(aimagId);
    };

    getOrganization = (aimagId) => {
        RefService.getOrganization(aimagId).then(res => {
            this.setState({
                organizationArray: [{id: 0, code: 0, name: 'Бүгд'}, ...res.data] || []
            })
        }).catch(e => {
            console.log(e);
            this.setState({
                organizationArray: []
            })
        })
    };

    getBudget = () => {
        RefService.getBudget().then(res => {
            this.setState({
                budgetStatusArray: res.data || []
            });
        }).catch(e => {
            console.log(e);
            this.setState({
                budgetStatusArray: []
            });
        })
    };

    getBudgetStat = () => {
        BudgetService.budgetStat(1).then(res => {
            this.setState({
                budgetStatList1: res || []
            })
        }).catch(e => {
            console.log(e);
            this.setState({
                budgetStatList1: []
            })
        });

        BudgetService.budgetStat(2).then(res => {
            this.setState({
                budgetStatList2: res || []
            })
        }).catch(e => {
            console.log(e);
            this.setState({
                budgetStatList2: []
            })
        })

        BudgetService.budgetStat(3).then(res => {
            this.setState({
                budgetStatList3: res || []
            })
        }).catch(e => {
            console.log(e);
            this.setState({
                budgetStatList3: []
            })
        })
    };

    search = (year, aimag, org, budgetStatus, econ) => {
        this.setState({
            filter: {
                yearValue: year,
                aimagValue: aimag,
                orgValue: org,
                budgetStatusValue: budgetStatus,
                econValue: econ
            }
        }, () => {
            this.budgetDetail()
        });
    }

    customizeTooltip = (args) => {
        return {
            html: `${args.seriesName} | Total<div class="currency">${nFormatter(args.originalValue, 1)}</div>`
        };
    }

    onCellPrepared = (e) => {
        let {cell} = e;
        let self = this;
        if (e.area === 'data' && cell.rowType === 'D' && cell.columnType === 'D') {
            let {rowPath, columnPath} = cell;

            // console.log(rowPath, columnPath[0]);

            let col = _.filter(this.state.dataSource.store, function (o) {
                return o.econ_category_name_1 === rowPath[0] && (rowPath[1] ? o.econ_category_name_2 === rowPath[1] : true) && (rowPath[2] ? o.econ_category_name_3 === rowPath[2] : true) && o.budget_year === columnPath[0] - 1;
            });

            let prev_debit = _.reduce(col, function (sum, n) {
                return sum + n.debit * 1;
            }, 0)
            let percent = 0;

            if (cell.value) {
                percent = prev_debit !== 0 ? (cell.value - prev_debit) * 100 / prev_debit : 0;
            }

            let v = rowPath.join('NV');
            let vr = v.replaceAll(' ', 'SP');
            vr = vr.replaceAll('-', 'MN');
            vr = vr.replaceAll(',', 'CM');
            vr = vr.replaceAll('/', 'SL');
            vr = vr.replaceAll('(', 'BS');
            vr = vr.replaceAll(')', 'BE');

            e.cellElement.onmouseenter = function (e) {
                e.preventDefault();
                // console.log(col[0] ? col[0].description : '');
                // self.showTooltip(e, col[0] ? col[0].description : '');
            }

            e.cellElement.onmouseleave = function (e) {
                e.preventDefault();
                // self.hideTooltip(e)
            }

            if (percent > 0) {
                e.cellElement.innerHTML = `<div class="inc customCell ${columnPath[0] == CURRENT_YEAR + 1 ? 'proposal' : ''}" id="${vr}YR${columnPath[0]}">
                    <div class="current-value">${cell.text}₮</div>
                    <div class="diff">${numberWithCommas(percent)}%</div>
                </div>`;
            } else if (percent) {
                e.cellElement.innerHTML = `<div class="dec customCell ${columnPath[0] == CURRENT_YEAR + 1 ? 'proposal' : ''}" id="${vr}YR${columnPath[0]}">
                    <div class="current-value">${cell.text}₮</div>
                    <div class="diff" v-if="percent">${numberWithCommas(percent)}%</div>
                </div>`;
            } else {
                e.cellElement.innerHTML = `<div class="customCell ${(columnPath[0] == CURRENT_YEAR + 1 && cell.text) ? 'proposal' : ''}" id="${vr}YR${columnPath[0]}">
                    <div class="current-value">${cell.text && cell.text + '₮'} </div>
                </div>`;
            }
        }
    }

    colorClass = (code) => {
        switch (code) {
            case '12':
                return 'green1';
            case '21':
                return 'red';
            case '22':
                return 'red';
            case '23':
                return 'blue';
            case '35':
                return 'green';
            case '31':
                return 'blue_purple';
            case '37':
                return 'orange';
            case '42':
                return 'dark_green';
            case '45':
                return 'dark_green';
            case '48':
                return 'dark_green';
            default:
                return 'gray';
        }
    }

    render() {
        return (
            <>
                <LoadPanelComponent
                    position={{of: '#grid'}}
                    loadPanelVisible={this.state.loadingGrid}
                    shading={true}
                    showPane={false}
                />
                <div className="expense-wrapper">
                    <div>
                        <FilterComponent
                            yearValue={this.state.filter.yearValue}
                            aimagValue={this.state.filter.aimagValue}
                            orgValue={this.state.filter.orgValue}
                            budgetStatusValue={this.state.filter.budgetStatusValue}
                            econValue={this.state.filter.econValue}

                            yearArray={this.state.yearArray}
                            aimagArray={this.state.aimagArray}
                            organizationArray={this.state.organizationArray}
                            budgetStatusArray={this.state.budgetStatusArray}
                            econArray={this.state.econArray}

                            selectAimag={(aimagId) => this.selectedAimag(aimagId)}

                            search={this.search}
                        />
                    </div>
                    <div>
                        {/*<ExpenseDetailComponent />*/}
                        <div className="card">
                            <div className="card-header">
                                <span>Дэлгэрэнгүй</span>
                            </div>
                            <div className="card-body" id={'grid'}>
                                <TabPanel>
                                    <Item title="Хүснэгт" icon="floppy">
                                        <div style={{margin: 10, width: '100%'}}>
                                            <Chart
                                                ref={(ref) => this._chart = ref && ref.instance}
                                            >
                                                <Size height={200}/>
                                                <Tooltip enabled={true} customizeTooltip={this.customizeTooltip}/>
                                                <CommonSeriesSettings type="bar" minBarSize={20}/>
                                                <AdaptiveLayout width={450}/>
                                            </Chart>

                                            <PivotGrid
                                                id="pivotgrid"
                                                dataSource={this.state.dataSource}
                                                allowSortingBySummary={true}
                                                allowFiltering={true}
                                                showBorders={true}
                                                showColumnTotals={false}
                                                showColumnGrandTotals={false}
                                                showRowTotals={true}
                                                showRowGrandTotals={true}
                                                texts={{
                                                    grandTotal: 'Нийт дүн',
                                                    total: '{0} нийт дүн'
                                                }}
                                                onCellPrepared={this.onCellPrepared}
                                                ref={(ref) => this._pivotGrid = ref && ref.instance}
                                            >
                                                <FieldChooser enabled={true} height={400}/>
                                            </PivotGrid>
                                        </div>
                                    </Item>


                                    {this.state.isLevel1 &&
                                    <Item title="Мэдээлэл" icon="comment">
                                        <div>
                                            <div style={{padding: 10}}>
                                                <span>Хамгийн өндөр төсөвтэй 10 байгууллага</span>
                                            </div>
                                            <div style={{display: 'flex', flexDirection: 'row'}}
                                                 className='expense-information'>
                                                <div style={{display: 'flex', flexDirection: 'column', margin: 10}}>
                                                    <h3 style={{margin: 10}}>2021 он</h3>

                                                    {this.state.budgetStatList1.map((org_stat, index) => {
                                                        return <div className="stat_back" key={index}>
                                                            <h4>{index + 1}. {org_stat.org_name}</h4>
                                                            {org_stat.budget.map((budget, i) => {
                                                                return <div
                                                                    className={'stat_detail ' + this.colorClass(budget.econ_category_code)}
                                                                    key={i}>
                                                                    <span
                                                                        className="title">{budget.econ_category_name}:</span>
                                                                    <span
                                                                        className="amount orange-text">{nFormatter(budget.amount)}</span>
                                                                </div>
                                                            })}
                                                        </div>
                                                    })}

                                                </div>
                                                <div style={{display: 'flex', flexDirection: 'column', margin: 10}}>
                                                    <h3 style={{margin: 10}}>2020 он</h3>

                                                    {this.state.budgetStatList2.map((org_stat, index) => {
                                                        return <div className="stat_back" key={index}>
                                                            <h4>{index + 1}. {org_stat.org_name}</h4>
                                                            {org_stat.budget.map((budget, i) => {
                                                                return <div
                                                                    className={'stat_detail ' + this.colorClass(budget.econ_category_code)}
                                                                    key={i}>
                                                                    <span
                                                                        className="title">{budget.econ_category_name}:</span>
                                                                    <span
                                                                        className="amount orange-text">{nFormatter(budget.amount)}</span>
                                                                </div>
                                                            })}
                                                        </div>
                                                    })}
                                                </div>

                                                <div style={{display: 'flex', flexDirection: 'column', margin: 10}}>
                                                    <h3 style={{margin: 10}}>2019 он</h3>

                                                    {this.state.budgetStatList3.map((org_stat, index) => {
                                                        return <div className="stat_back" key={index}>
                                                            <h4>{index + 1}. {org_stat.org_name}</h4>
                                                            {org_stat.budget.map((budget, i) => {
                                                                return <div
                                                                    className={'stat_detail ' + this.colorClass(budget.econ_category_code)}
                                                                    key={i}>
                                                                    <span
                                                                        className="title">{budget.econ_category_name}:</span>
                                                                    <span
                                                                        className="amount orange-text">{nFormatter(budget.amount)}</span>
                                                                </div>
                                                            })}
                                                        </div>
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                    </Item>
                                    }
                                </TabPanel>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default withRouter(Expense);

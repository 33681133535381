import React, { useState, useEffect, useContext, useCallback } from "react";
import PlanServices from "../../services/api/plan";
import notify from "devextreme/ui/notify";
import RefService from "../../services/api/refs";
import { UserContext } from "../../hooks/UserContext";
import WholeAmountBarChart from "./components/WholeAmountBarChart";
import { CURRENT_YEAR } from "../../shared/constants";
import { formatDate, getYears, numberWithCommas } from "../../util";
import SelectBox from "devextreme-react/select-box";
import DataGrid, {
    Column, ColumnChooser, Grouping,
    GroupItem,
    GroupPanel, Item,
    Paging,
    Scrolling, SearchPanel, Selection,
    Summary, Toolbar,
    TotalItem
} from "devextreme-react/data-grid";
import PieChart, { Label, Legend, Series, Tooltip } from "devextreme-react/pie-chart";
import { Chart, CommonSeriesSettings, Export, Format } from "devextreme-react/chart";
import PivotGridDataSource from "devextreme/ui/pivot_grid/data_source";
import BudgetService from "../../services/api/budget";
import ZarlagaHeaderInfo from "../updated-budget/components/zarlaga/ZarlagaHeaderInfo";
import LoadPanelComponent from "../budget/components/loadPanel";
import info1 from "../../assets/imgs/icon/iconInfo1.png";
import { Tooltip as Tooltip2 } from "devextreme-react/tooltip";
import Popup from "devextreme-react/popup";
import ScrollView from "devextreme-react/scroll-view";
import ExcelFileInput from "../performance/sections/excelFileInputComponent";
import Button from "devextreme-react/button";
import { CgArrowsExpandRight, CgCompressRight, CgMaximize, CgMinimize } from "react-icons/cg";
import { Workbook } from 'exceljs';
import saveAs from 'file-saver';
import { exportDataGrid } from "devextreme/excel_exporter";

const PlanningPage = () => {
    const { user } = useContext(UserContext);
    const [months, setMonths] = useState(Array.from(Array(12), (v, index) => index + 1));
    //const [budgetPlans, setBudgetPlans] = useState([]);
    const [budgeDetailByAgenda, setBudgeDetailByAgenda] = useState([]);
    const [approvedPlans, setApprovedPlans] = useState([]);
    const [activeAgenda, setActiveAgenda] = useState([]);
    const [defaultAgenda, setDefaultAgenda] = useState(0);
    const [agendaMonthPlan, setAgendaMonthPlan] = useState([]);
    const [economicCategoryArray, setEconomicCategoryArray] = useState([]);
    const [selectedYear, setSelectedYear] = useState(CURRENT_YEAR);
    const [chosenMinistry, setChosenMinistry] = useState('ALL');
    const [selectedOrg, setSelectedOrg] = useState('ALL');
    const [orgs, setOrgs] = useState([]);
    const [rootData, setRootData] = useState([]);
    const [calculatedPercentage, setCalculatedPercentage] = useState(null);
    const [calculatedGuitestgelPercentage, setCalculatedGuitsetgelPercentage] = useState(null);
    const [loader, setLoader] = useState(false);
    const [loader2, setLoader2] = useState(false);
    const [loader3, setLoader3] = useState(false);
    const [loader4, setLoader4] = useState(false);
    const [updatedDate, setUpdatedDate] = useState(false)
    const [subOrgs, setSubOrgs] = useState([]);
    const [subOrgId, setSubOrgId] = useState(null);
    const [isPivotData, setIsPivotData] = useState(false);
    const [dataSource, setDataSource] = useState([]);
    const [expand, setExpand] = useState(false);
    const [fullscreen, setFullscreen] = useState(false)
    const loadOrgs = async () => {
        try {
            setLoader(true);
            let r =
                user.username === "02" ? (chosenMinistry === "ALL" ? [] : await BudgetService.getFilteredOrganizationsBySoa(selectedYear, chosenMinistry)) :
                    await BudgetService.getFilteredOrganizations(selectedYear);
            setOrgs([
                {
                    budget_year: selectedYear,
                    text_name: 'БҮГД',
                    value_name: 'ALL',
                },
                ...r
            ])
            getScheduleUpdatedDate(CURRENT_YEAR)
            setLoader(false);
        } catch (e) {
            setLoader(false);
            notify(e.message, "error", 2000)
        }
    }
    const loadSubOrgs = async (year, filterOrg) => {
        try {
            let r = await BudgetService.getFilteredOrgList(year, filterOrg);
            if (r.length === 1) {
                setSubOrgs(r);
            } else {
                setSubOrgs([
                    {
                        org_id: 0,
                        org_name: 'БҮГД',
                    },
                    ...r
                ])
            }

        } catch (e) {
            notify(e.message, "error", 2000)
        }
    }
    const getScheduleUpdatedDate = async () => {
        await PlanServices.getScheduleUpdatedDate(selectedYear).then((res) => {
            // console.log('getScheduleUpdatedDate', res.data[0].max)
            setUpdatedDate(res.data[0].max)
        })
            .catch((err) => {
                console.log('could not get plan updated date', err)
            })
    }

    const getBudgetPlanData = async () => {
        try {
            setLoader2(true)
            let res = await PlanServices.getBudgetPlanData(selectedYear, selectedOrg);
            setApprovedPlans(res);
            setLoader2(false)
        } catch (e) {
            setLoader2(false);
            notify(e.message, "error", 2000)
        }
    };

    const getBudgeDetailByAgendaLoad3 = async () => {
        try {
            setLoader3(true)
            let result = await PlanServices.getBudgeDetailByAgenda(selectedYear, selectedOrg);
            result = result.sort((curr, next) => next.total - curr.total);
            //console.log('result', result);
            setBudgeDetailByAgenda(result);
            setLoader3(false)
        } catch (e) {
            setLoader3(false);
            notify(e.message, "error", 2000)
        }
    };

    // const getPlanByEconCategory = async () => {
    //     try {
    //         setLoader5(true)
    //         let byEconCategory = await PlanServices.getPlanByEconCategory(selectedYear, selectedOrg);
    //         const dataSource = new PivotGridDataSource({
    //             fields: [
    //                 {caption: 'ec_1', dataField: 'ec_1', width: 150, area: 'row',},
    //                 {caption: 'ec_2', dataField: 'ec_2', width: 150, area: 'row',},
    //                 {caption: 'ec_3', dataField: 'ec_3', width: 150, area: 'row',},
    //                 {caption: 'ec_4', dataField: 'ec_4', width: 150, area: 'row',},
    //                 {caption: 'ec', dataField: 'ec', width: 150, area: 'row',},
    //                 {caption: 'Төсөв', dataField: 'sum', dataType: 'number', summaryType: 'sum', format: {type: 'fixedPoint', precision: 2}, area: 'data', customizeText: function (e) { if(e.value < 1000000){ return `${numberWithCommas(e.value)}`} return `${numberWithCommas(e.value/1000000)}`},},
    //                 {caption: 'Хувь', dataField: 'total_budget', dataType: 'number', summaryType: 'sum', format: {type: 'fixedPoint', precision: 2}, area: 'data', customizeText: function (e) {return `${e.value.toFixed(2)}%`},},
    //                 {caption: 'Гүйцэтгэл', dataField: 'performance', dataType: 'number', summaryType: 'sum', format: {type: 'fixedPoint', precision: 2}, area: 'data', customizeText: function (e) { if(e.value < 1000000){ return `${numberWithCommas(e.value)}`} return `${numberWithCommas(e.value/1000000)}`},},
    //                 {caption: 'Хувь', dataField: 'total_performance', dataType: 'number', summaryType: 'sum', format: {type: 'fixedPoint', precision: 2}, area: 'data', customizeText: function (e) {return `${e.value.toFixed(2)}%`},},
    //             ],
    //             store: byEconCategory
    //         })
    //         setPlanByEconCategory(dataSource);
    //         setLoader5(false)
    //     } catch (e) {
    //         setLoader5(false);
    //         notify(e.message, "error", 2000)
    //     }
    // };

    const getActiveAgenda = async () => {
        setLoader4(true);
        let agenda = await PlanServices.getActiveAgenda(selectedYear, selectedOrg);
        setActiveAgenda([
            {
                agenda_id: 0,
                name: 'Бүгд'
            },
            ...agenda
        ])
        if (agenda.length > 0) {
            getAgendaMonthPlanLoad4(0)
            setLoader4(false);
        } else {
            setLoader4(false);
            setAgendaMonthPlan({});
        }

    };

/*    const loadBudgetPlans = async () => {
        try {
            let r = await PlanServices.retrieveBudgetPlans(selectedYear);
            //console.log('r', r);
            setBudgetPlans(r);
        } catch (e) {
            notify(e.message, "error", 2000);
        }
    }*/

    const getAgendaMonthPlanLoad4 = async (agenda) => {
        try {
            setLoader4(true);
            let data = await PlanServices.getAgendaMonthPlan(selectedYear, agenda, selectedOrg);
            setAgendaMonthPlan(data);
            setLoader4(false);
        } catch (e) {
            setLoader4(false);
            notify(e.message, "error", 2000);
        }

    }

    const loadRefEconomicCategory = async () => {
        setLoader(true);
        const result = await RefService.getRefEconomicCategory();
        setEconomicCategoryArray(result.data);
        setLoader(false);
    }

    const rootDataSetter = (root, handler) => {
        if (handler) {
            if (root && rootData && ((root.todotgoson2 && rootData.todotgoson2) || (root.todotgoson && rootData.todotgoson) || (root.batlagdsan && rootData.batlagdsan))) {
                if (root.guitsetgel && rootData.guitsetgel) {
                    setCalculatedGuitsetgelPercentage(root.guitsetgel * 100 / rootData.guitsetgel);
                } else {
                    setCalculatedGuitsetgelPercentage(null);
                }
                let a = root.todotgoson2 ? root.todotgoson2 : (root.todotgoson ? root.todotgoson : root.batlagdsan);
                let b = rootData.todotgoson2 ? rootData.todotgoson2 : (rootData.todotgoson ? rootData.todotgoson : rootData.batlagdsan);
                setCalculatedPercentage(a * 100 / b);
            } else {
                setCalculatedPercentage('')
                setCalculatedGuitsetgelPercentage('')
            }
        } else {
            setCalculatedPercentage(null);
            setCalculatedGuitsetgelPercentage(null);
            setRootData(root);
        }
    }

    useEffect(() => {
        loadOrgs();
        loadRefEconomicCategory();
        getActiveAgenda();
        //loadBudgetPlans();
        getBudgetPlanData()
        getBudgeDetailByAgendaLoad3()
        // getPlanByEconCategory()
    }, [selectedYear, selectedOrg]);


    const cstm12 = (pointInfo) => {
        return pointInfo.originalValue > 1000000 ? `${pointInfo.argumentText}- ${numberWithCommas(pointInfo.originalValue / 1000000)} - ${pointInfo.percentText}` : `${numberWithCommas(pointInfo.originalValue / 1000000)} - ${pointInfo.percentText}`
    }
    const customizeTooltipAgenda = (arg) => {
        return {
            text: `${arg.argumentText}  ${numberWithCommas(arg.value / 1000000)}  ${(
                arg.percent * 100
            ).toFixed(2)}%`,
        }
    }
    const calculateTotal = (pieChart) => {
        //console.log('pieChart', pieChart);
        let totalValue = pieChart.getAllSeries()[0].getAllPoints().reduce((s, p) => s + p.originalValue, 0);
        return `${numberWithCommas(totalValue / 1000000)}`;
    }
    const centerRender = (pieChart) => {
        return (
            <text textAnchor="middle" style={{ display: 'flex', overflow: 'hidden', whiteSpace: 'nowrap', margin: 'auto', wordWrap: 'wrapped' }}>
                <tspan style={{ fontWeight: 600 }}>{calculateTotal(pieChart)}</tspan>
            </text>
        );
    }
    // const customizeTooltip = (arg) => {
    //     return arg.value > 1000000 ?{text: `${arg.argumentText}-р сар ${numberWithCommas(arg.value/1000000)}`}: {text: `${arg.argumentText}-р сар ${numberWithCommas(arg.value/1000000)} `}
    // }

    const customizeTooltip = (arg) => {
        if (arg.seriesName === 'Хуваарийн өөрчлөлт') {
            let r = agendaMonthPlan[(arg.argument * 1 - 1) - 1];
            let q = ((arg.value - (r && r.budget) > 0 ? arg.value / (r && r.budget) : (r && r.budget) / arg.value)).toFixed(2);
            let p = ((arg.value * 100 / (r && r.budget)) - 100).toFixed(2);
            let d = (arg.value - (r && r.budget));
            let f = d < 0 ? 'бага' : 'их';

            if (isFinite(q) && !isNaN(q)) {
                return {
                    text: `Хуваарь ${arg.argumentText}-р сар\nӨмнөх сараас\n\n Зөрүү: ${numberWithCommas(d / 1000000)}\n Хувь: ${p}%\n Өөрчлөлт: ${q} дахин ${f}`
                }
            } else {
                return {
                    text: `Тооцоолох боломжгүй`
                }
            }
        }
        else if (arg.seriesName === 'Гүйцэтгэлийн өөрчлөлт') {
            let r = agendaMonthPlan[(arg.argument * 1 - 1) - 1];
            let q = ((arg.value - (r && r.performance) > 0 ? arg.value / (r && r.performance) : (r && r.performance) / arg.value)).toFixed(2);
            let p = ((arg.value * 100 / (r && r.performance)) - 100).toFixed(2);
            let d = (arg.value - (r && r.performance));
            let f = d < 0 ? 'бага' : 'их';
            let r1 = agendaMonthPlan[(arg.argument * 1) - 1];
            let u = ((r1 && r1.budget) - arg.value);
            let u1 = ((arg.value * 100 / (r1 && r1.budget))).toFixed(2);
            if (isFinite(q) && !isNaN(q)) {
                return {
                    text: `Гүйцэтгэл ${arg.argumentText}-р сар\nЗөрүү: ${numberWithCommas(u / 1000000)}\n Xувь: ${u1}%\n--------------------\n Өмнөх жилээс\n Зөрүү: ${numberWithCommas(d / 1000000)}\n Хувь: ${p}%\n Өөрчлөлт: ${q} дахин ${f}\n `
                }
            } else {
                return {
                    text: `Тооцоолох боломжгүй`
                }
            }
        }
        else {
            return {
                text: `${arg.seriesName}: ${numberWithCommas(arg.value / 1000000)}`
            };
        }
    }
    const ExpandCollapseButton = useCallback(() => {
        return (<Button
            onClick={(e) => setExpand(prev => !prev)}
            render={() => {
                return expand ? (
                    <CgCompressRight size={"1rem"} color="#1a1976" />)
                    :
                    (
                        <CgArrowsExpandRight size={"1rem"} color="#1a1976" />
                    )
            }}
            hint={expand ? 'Багасгах' : 'ЭЗА дэлгэрэнгүй харуулах'}
        >
        </Button>)
    }, [expand])
    const requestFullscreen = () => {
        if (document.fullscreenElement) document.exitFullscreen();
        else document.getElementById("wrapper").requestFullscreen();
        // setFullscreen(prev=>!prev)
    }
    const FullscreenButton = useCallback(() => {
        return (
            <Button
                onClick={() => {
                    requestFullscreen()
                    setFullscreen(prev => !prev)
                }}
                style={{ marginLeft: "0.5em" }}
                render={() => {
                    return fullscreen ?
                        (<CgMinimize size={"1rem"} color="#1a1976" />)
                        :
                        (<CgMaximize size={"1rem"} color="#1a1976" />)
                }}
                hint={fullscreen ? 'Багасгах' : 'Дэлгэцэнд бүтэн харуулах'}
            >
            </Button>
        )
    }, [fullscreen])
    const onCellPreparedHandler = (e) => {
        if (e.rowType === 'header') {
            e.cellElement.wordWrapEnabled = true;
            e.cellElement.style.fontFamily = 'Segoe UI'
            e.cellElement.style.fontWeight = 'bold'
            e.cellElement.style.color = "#15186A"
            e.cellElement.style.backgroundColor = "#EEF0F4"
        }
        if (e.rowType === 'group') {
            e.cellElement.style.fontFamily = "Segoe UI"
            //e.cellElement.wordWrapEnabled = true;
            e.cellElement.style.color = "#003696"
            e.cellElement.style.backgroundColor = "#fff"
            e.cellElement.style.fontWeight = "bold";
        }
        if (e.rowType === 'data') {
            e.cellElement.style.fontFamily = 'Segoe UI'
            e.cellElement.style.fontWeight = 'semibold'
            e.cellElement.style.color = "#000000"
        }
    }
    const onExporting = (e) => {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Main sheet');
        exportDataGrid({
            component: e.component,
            worksheet,
            autoFilterEnabled: true,
            keepColumnWidths: true,
            topLeftCell: { row: 4, column: 2 },
            customizeCell: ({ gridCell, excelCell }) => {
                if (gridCell.rowType === 'header') {
                    excelCell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '7082F6' } };
                    excelCell.border = {
                        top: { style: 'thin', color: { argb: '2c2c2c' } },
                        left: { style: 'thin', color: { argb: '2c2c2c' } },
                        bottom: { style: 'thin', color: { argb: '2c2c2c' } },
                        right: { style: 'thin', color: { argb: '2c2c2c' } }
                    }
                }
                if (gridCell.rowType === 'group') {
                    excelCell.alignment = { wrapText: false };
                    excelCell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '63C1FE' } };
                }
                if (gridCell.rowType === 'totalFooter' && excelCell.value) {
                    excelCell.font.italic = true;
                }
                /*if (gridCell.column.caption === 'Зөрүү') {
                    console.log('excelCell', excelCell);
                 //   excelCell.value = { text: gridCell.value};
                }*/
                if (gridCell.rowType === 'data') {
                    excelCell.border = {
                        top: { style: 'thin', color: { argb: '2c2c2c' } },
                        left: { style: 'thin', color: { argb: '2c2c2c' } },
                        bottom: { style: 'thin', color: { argb: '2c2c2c' } },
                        right: { style: 'thin', color: { argb: '2c2c2c' } }
                    }
                }
            },
        }).then(() => {
            Object.assign(
                worksheet.getRow(2).getCell(8),

                { value: selectedOrg !== null ? selectedOrg : user.organization.name + "-ын хуваарийн тайлан", font: { bold: true, size: 16, underline: 'double' } }
            );
        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer], { type: 'application/octet-stream' }), selectedOrg !== null ? "OG-" + selectedOrg + "-" + formatDate(new Date()) + ".xlsx" : "OG-" + user.organization.name + "-" + formatDate(new Date()) + ".xlsx");
            });
        });
        e.cancel = true;
    }
    const onPivotData = async (parent, org_id) => {
        setLoader(true);
        const sortException = "Гүйцэтгэл";
        await BudgetService.budgetDetail(selectedYear, (parent === 'ALL' || user.organization.obt_id === 2) ? parent : selectedOrg, org_id).then(data => {
            const dataSource = new PivotGridDataSource({
                fields: [
                    {
                        caption: 'Эдийн засгийн ангилал 1',
                        dataField: 'econ_category_name_1',
                        area: 'row',
                        sortBySummaryField: 'Total',
                        expanded: true,
                        sortBy: 'displayText',
                        sortOrder: 'asc',
                        width: 300,
                    },
                    {
                        caption: 'Эдийн засгийн ангилал 2',
                        dataField: 'econ_category_name_2',
                        area: 'row',
                        sortBy: 'displayText',
                        sortOrder: 'asc',
                        expanded: true,
                        width: 300,
                    },
                    {
                        caption: 'Эдийн засгийн ангилал 3',
                        dataField: 'econ_category_name_3',
                        area: 'row',
                        sortBy: 'displayText',
                        sortOrder: 'asc',
                        width: 300,
                    },
                    {
                        caption: 'Эдийн засгийн ангилал 4',
                        dataField: 'econ_category_name_4',
                        width: 300,
                        area: 'row',
                        sortBy: 'displayText',
                        sortOrder: 'asc',
                    },
                    {
                        caption: 'Эдийн засгийн ангилал 5',
                        dataField: 'econ_category_name',
                        width: 300,
                        area: 'row',
                        sortBy: 'displayText',
                        sortOrder: 'asc',
                    },
                    {
                        caption: 'Сар',
                        dataField: 'budget_month',
                        dataType: 'budget_month',
                        area: 'column',
                        sortOrder: 'asc',
                        expanded: true,
                    }, {
                        caption: 'Төлөв',
                        dataField: 'budget_status_name',
                        dataType: 'budget_status_name',
                        area: 'column',
                        expanded: true,
                        sortingMethod: function (a, b) {
                            var index1 = a.value;
                            var index2 = b.value;
                            if (index1 === sortException) {
                                return 1;
                            }
                            if (index2 === sortException) {
                                return -1;
                            }
                            if (index1 > index2)
                                return 1;
                            if (index2 > index1)
                                return -1;
                            else
                                return 0;
                        }
                    },
                    {
                        caption: 'Нийт',
                        dataField: 'debit',
                        dataType: 'number',
                        summaryType: 'sum',
                        format: {
                            type: 'fixedPoint',
                            precision: 2
                        },
                        area: 'data',
                        //runningTotal: 'row',
                        allowCrossGroupCalculation: true,
                    }],
                store: data
            });
            setDataSource(dataSource);
            return setLoader(false);
        });
        setIsPivotData(true);
    }
    const renderPopupPerformance = () => {
        return (
            <div style={{ height: "100%" }}>
                {
                    isPivotData &&
                    <Popup
                        visible={isPivotData}
                        onHiding={() => setIsPivotData(false)}
                        hideOnOutsideClick={true}
                        showCloseButton={true}
                        height={"100%"}
                        width={"100%"}
                        showTitle={true}
                        title={selectedYear + " оны төсвийн хуваарь болон гүйцэтгэл /сая ₮/"}
                    > <ScrollView width='100%' height='100%'>
                            {
                                user.organization.obt_id !== 3 &&
                                <div style={{ display: 'flex' }}>
                                    <div>
                                        {orgs && orgs.length !== 0 && <SelectBox
                                            items={orgs}
                                            width={350}
                                            value={selectedOrg}
                                            valueExpr="value_name"
                                            displayExpr="text_name"
                                            label={user.organization.obt_id === 1 ? 'ТТЗ' : 'ТШЗ'}
                                            onValueChanged={(e) => {
                                                setSelectedOrg(e.value);
                                                loadSubOrgs(selectedYear, e.value);
                                                (e.value === 'ALL' || user.organization.obt_id === 2) && onPivotData(e.value, 0)
                                            }}
                                            placeholder="сонгох" style={{ borderRadius: 10 }} />}
                                    </div>
                                    <div style={{ marginLeft: 10 }}>
                                        {subOrgs && subOrgs.length !== 0 && selectedOrg !== 'ALL' && user.organization.obt_id === 1 && <SelectBox
                                            items={subOrgs}
                                            width={350}
                                            value={subOrgId}
                                            valueExpr="org_id"
                                            displayExpr="org_name"
                                            label={'Байгууллага'}
                                            onValueChanged={(e) => { setSubOrgId(e.value); onPivotData(selectedOrg, e.value) }}
                                            placeholder="сонгох" style={{ borderRadius: 10 }} />}
                                    </div>
                                </div>
                            }

                            <ExcelFileInput selectedYear={selectedYear} dataSource={dataSource} />
                        </ScrollView>
                    </Popup>
                }
            </div>
        )
    }
    return (
        <div style={{}}>
            <div style={{ padding: 10 }}>
                <div style={{ display: 'flex', flexWrap: 'wrap', gap: '1rem' }}>
                    <div>
                        <SelectBox
                            items={getYears(true)}
                            width='8rem'
                            label="Төсвийн жил:"
                            defaultValue={selectedYear}
                            onValueChanged={(e) => setSelectedYear(e.value)}
                            placeholder="сонгох" />
                    </div>
                    {user.organization.obt_id !== 3 &&
                        <div>
                            <SelectBox
                                items={orgs}
                                label="Байгууллага"
                                width='22rem'
                                defaultValue={selectedOrg}
                                valueExpr="value_name"
                                displayExpr="text_name"
                                onValueChanged={(e) => { setSelectedOrg(e.value) }}
                                placeholder="сонгох" />
                        </div>}
                    <Button icon={'doc'}
                        text={isPivotData ? selectedYear + " оны төсвийн хуваарь,гүйцэтгэл /сая ₮/" : selectedYear + " оны төсвийн хуваарь,гүйцэтгэл харах"}
                        onClick={(e) => onPivotData(selectedOrg, 0)} style={{ borderRadius: 4 }} type={'default'} />
                </div>
            </div>
            <ZarlagaHeaderInfo showLess={true} chosenMinistry={chosenMinistry} year={selectedYear}
                filterOrgText={selectedOrg} rootDataSetter={rootDataSetter} />

            {/*{user.position_id === 1 &&*/}
            <div>
                <div className="row" >
                    <div className="col-12">
                        <div className={'card'}>
                            <div className={'card-header'}>
                                <span><b>1. Төсөв /сараар, сая ₮/</b></span>
                            </div>

                            <div className={'card-body'} style={{ position: 'relative' }}>
                                {
                                    updatedDate &&
                                    <div style={{ position: "absolute", right: '.6rem', top: '-3rem' }}>
                                        <img
                                            id={"wo2333"}
                                            loading="lazy"
                                            width={'1.5rem'}
                                            height={'1.5rem'}
                                            src={info1}
                                            style={{ cursor: 'pointer' }}
                                            alt={"info-button"} />
                                        <Tooltip2
                                            target={"#wo2333"}
                                            position="left"
                                            showEvent="mouseenter"
                                            hideEvent="mouseleave"
                                            hideOnOutsideClick={true}>
                                            <div style={{ padding: "1em" }}>Хуваарь шинэчлэгдсэн огноо: {updatedDate}</div>
                                        </Tooltip2>
                                    </div>
                                }
                                <WholeAmountBarChart selectedYear={selectedYear} selectedOrg={selectedOrg} />
                            </div>
                        </div>
                    </div>
                </div>
                {user.organization.obt_id !== 3 &&
                    <div className="row" id="load2" >
                        <LoadPanelComponent position={{ of: '#load2' }} visible={loader2} />
                        <div className="col-12" id="wrapper" height="100%" width="100%" style={{ backgroundColor: "#fff" }}>
                            <div className={'card'}>
                                <div className={'card-header'}>
                                    <span><b>2. Харьяа байгууллагаар /сая ₮/ </b></span>
                                </div>
                                <div className={'card-body'}>
                                    <DataGrid
                                    noDataText="Дата байхгүй байна."
                                     dataSource={approvedPlans} rowAlternationEnabled={true}
                                        onCellPrepared={onCellPreparedHandler}
                                        showBorders={true}
                                        wordWrapEnabled={true}
                                        showRowLines={true}
                                        showColumnLines={true}
                                        showColumnHeaders={true}
                                        allowColumnResizing={true} onExporting={onExporting}>
                                        <GroupPanel visible={true} />
                                        <Toolbar>
                                            <Item name="searchPanel" />
                                            <Item>
                                                <div>
                                                    <ExpandCollapseButton />
                                                    <FullscreenButton />
                                                </div>
                                            </Item>
                                            <Item name="exportButton"
                                                render={(e) => {
                                                    return <Button
                                                        onClick={e.options.onClick}
                                                        icon="xlsxfile"
                                                        style={{ color: "#00ff00" }}>
                                                    </Button>
                                                }}
                                            />
                                            <Item>
                                            </Item>
                                            <Item name="columnChooserButton" widget="dxButton" options={{ icon: "columnchooser" }} />
                                        </Toolbar>
                                        <Export enabled={true} />
                                        <SearchPanel visible={true} placeholder={'Хайх'} width={"300"} />
                                        <Grouping autoExpandAll={expand} contextMenuEnabled={false}
                                            expandMode="rowClick" />
                                        <Selection mode="single" />
                                        <Scrolling columnRenderingMode="virtual" />
                                        <ColumnChooser
                                            enabled={true} height={"100%"} allowSearch={true}
                                            mode="select"
                                            title={'Багана сонгох'}
                                        />
                                        <Paging defaultPageSize={10} />
                                        <Column dataField="org_name_2" caption="ТБ" width={'20rem'} groupIndex={0} visible={user.organization.obt_id === 3 ? false : true} />
                                        <Column dataField="org_name" caption="Байгууллага" width={'20rem'} />
                                        <Column dataField={'total'} format="#,##0.00" alignment="center" dataType="number" caption={`Нийт`} />
                                        {months.map((item, index) => (
                                            <Column key={index} dataField={`m${item}`} format="#,##0.00" alignment="center" dataType="number" caption={`${item}-p cap`} />))
                                        }
                                        <Summary>
                                            <GroupItem column="total" summaryType="sum" showInGroupFooter={false} alignByColumn={true} showInColumn="month3" customizeText={(data) => { return `${numberWithCommas(data.value)}` }} />
                                            <GroupItem column="m1" summaryType="sum" showInGroupFooter={false} alignByColumn={true} showInColumn="month3" customizeText={(data) => { return `${numberWithCommas(data.value)}` }} />
                                            <GroupItem column="m2" summaryType="sum" showInGroupFooter={false} alignByColumn={true} showInColumn="month3" customizeText={(data) => { return `${numberWithCommas(data.value)}` }} />
                                            <GroupItem column="m3" summaryType="sum" showInGroupFooter={false} alignByColumn={true} showInColumn="month3" customizeText={(data) => { return `${numberWithCommas(data.value)}` }} />
                                            <GroupItem column="m4" summaryType="sum" showInGroupFooter={false} alignByColumn={true} showInColumn="month3" customizeText={(data) => { return `${numberWithCommas(data.value)}` }} />
                                            <GroupItem column="m5" summaryType="sum" showInGroupFooter={false} alignByColumn={true} showInColumn="month3" customizeText={(data) => { return `${numberWithCommas(data.value)}` }} />
                                            <GroupItem column="m6" summaryType="sum" showInGroupFooter={false} alignByColumn={true} showInColumn="month3" customizeText={(data) => { return `${numberWithCommas(data.value)}` }} />
                                            <GroupItem column="m7" summaryType="sum" showInGroupFooter={false} alignByColumn={true} showInColumn="month3" customizeText={(data) => { return `${numberWithCommas(data.value)}` }} />
                                            <GroupItem column="m8" summaryType="sum" showInGroupFooter={false} alignByColumn={true} showInColumn="month3" customizeText={(data) => { return `${numberWithCommas(data.value)}` }} />
                                            <GroupItem column="m9" summaryType="sum" showInGroupFooter={false} alignByColumn={true} showInColumn="month3" customizeText={(data) => { return `${numberWithCommas(data.value)}` }} />
                                            <GroupItem column="m10" summaryType="sum" showInGroupFooter={false} alignByColumn={true} showInColumn="month3" customizeText={(data) => { return `${numberWithCommas(data.value)}` }} />
                                            <GroupItem column="m11" summaryType="sum" showInGroupFooter={false} alignByColumn={true} showInColumn="month3" customizeText={(data) => { return `${numberWithCommas(data.value)}` }} />
                                            <GroupItem column="m12" summaryType="sum" showInGroupFooter={false} alignByColumn={true} showInColumn="month3" customizeText={(data) => { return `${numberWithCommas(data.value)}` }} />
                                            <TotalItem column="total" summaryType="sum" displayFormat="{0}" valueFormat="#,##0.00" />
                                            <TotalItem column="m1" summaryType="sum" displayFormat="{0}" valueFormat="#,##0.00" />
                                            <TotalItem column="m2" summaryType="sum" displayFormat="{0}" valueFormat="#,##0.00" />
                                            <TotalItem column="m3" summaryType="sum" displayFormat="{0}" valueFormat="#,##0.00" />
                                            <TotalItem column="m4" summaryType="sum" displayFormat="{0}" valueFormat="#,##0.00" />
                                            <TotalItem column="m5" summaryType="sum" displayFormat="{0}" valueFormat="#,##0.00" />
                                            <TotalItem column="m6" summaryType="sum" displayFormat="{0}" valueFormat="#,##0.00" />
                                            <TotalItem column="m7" summaryType="sum" displayFormat="{0}" valueFormat="#,##0.00" />
                                            <TotalItem column="m8" summaryType="sum" displayFormat="{0}" valueFormat="#,##0.00" />
                                            <TotalItem column="m9" summaryType="sum" displayFormat="{0}" valueFormat="#,##0.00" />
                                            <TotalItem column="m10" summaryType="sum" displayFormat="{0}" valueFormat="#,##0.00" />
                                            <TotalItem column="m11" summaryType="sum" displayFormat="{0}" valueFormat="#,##0.00" />
                                            <TotalItem column="m12" summaryType="sum" displayFormat="{0}" valueFormat="#,##0.00" />
                                        </Summary>
                                    </DataGrid>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                <div className="row" id="load3" >
                    <LoadPanelComponent position={{ of: '#load3' }} visible={loader3} />
                    <div className="row col-12">
                        <div className="col-6 responsive-col">
                            <div className={'card'}>
                                <div className={'card-header'}>
                                    <span> <b>{user.organization.obt_id !== 3 ? 3 : 2}. Хөтөлбөр, арга хэмжээ /сая ₮/</b></span>
                                </div>
                                <div className={'card-body'}>
                                    <PieChart dataSource={budgeDetailByAgenda}
                                        type="doughnut"
                                        resolveLabelOverlapping={"hide"}
                                        centerRender={centerRender}
                                    //palette={["#003696", "#2c77ff", "#5d96ff", "#aacbff", "#d2e2ff", "#eef4ff"]}
                                    //   customizeLabel={customizeLabel}
                                    >
                                        <Legend
                                            verticalAlignment="bottom"
                                            horizontalAlignment="center"
                                            itemTextPosition="right"
                                            rowCount={2}
                                        />
                                        <Series argumentField="name" valueField="total">
                                            <Label position="inside" visible={true}
                                                radialOffset={45}
                                                customizeText={cstm12}
                                            >
                                                {/* <Font 
                                            // family={"Segoe UI"}
                                            color="red"/> */}
                                            </Label>
                                        </Series>
                                        <Tooltip enabled={true} customizeTooltip={customizeTooltipAgenda} />
                                    </PieChart>

                                </div>
                            </div>
                        </div>
                        <div className="col-6 responsive-col">
                            <div className={'card'} id="load4">
                                <LoadPanelComponent position={{ of: '#load4' }} visible={loader4} />

                                <div className={'row card-header'} style={{ display: 'flex', justifyContent: 'space-around', height: '3.5em' }}>
                                    <span><b>{user.organization.obt_id !== 3 ? 4 : 3}. Хөтөлбөр, арга хэмжээ /сараар, сая ₮/</b></span>
                                    <SelectBox items={activeAgenda}
                                        defaultValue={defaultAgenda}
                                        onValueChanged={(e) => { getAgendaMonthPlanLoad4(e.value.agenda_id) }}
                                        displayExpr={'name'}
                                        placeholder="сонгох"
                                        style={{ height: "2.5em", marginTop: "auto", marginBottom: "auto", marginLeft: "auto", marginRight: '1.5em' }} />
                                </div>
                                <div className={'card-body'}>

                                    <Chart id="mySimpleBarChart" dataSource={agendaMonthPlan} resolveLabelOverlapping="hide">
                                        <CommonSeriesSettings argumentField="budget_month" type="bar" hoverMode="allArgumentPoints" barOverlapGroup="perfStack" barPadding={0.5}
                                            selectionMode="allArgumentPoints">
                                            <Label precision={0} visible={true} font={{ family: "'Segoe UI', sans-serif" }} format={(value) => numberWithCommas(value / 1000000)}>
                                                <Format type="fixedPoint" precision={2} />
                                            </Label>
                                        </CommonSeriesSettings>
                                        <Series valueField="budget" name="Хуваарь" stack="budget" color="#2b77ff" stack="perfStack" barPadding={0.3} />
                                        <Series valueField="budget" name="Хуваарийн өөрчлөлт" type="spline" color="#2b77ff" dashStyle="dash" />
                                        <Series valueField="performance" name="Гүйцэтгэл" stack="performance" color="#003696" stack="perfStack" />
                                        <Series valueField="performance" name="Гүйцэтгэлийн өөрчлөлт" type="spline" color="#003696" dashStyle="dash" />
                                        <Tooltip zIndex={100000} enabled={true} customizeTooltip={customizeTooltip} font={{ family: "'Segoe UI', sans-serif" }} location="edge" />
                                        <Legend verticalAlignment="bottom" horizontalAlignment="center" visible={true} />
                                    </Chart>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                {/*<div className="row" id="load5" >*/}
                {/*    <LoadPanelComponent position={{of: '#load5'}} visible={loader5} />*/}
                {/*    <div className="col-12">*/}
                {/*        <div className={'card'}>*/}
                {/*            <div className={'card-header'}>*/}
                {/*                <span><b>{user.organization.obt_id !== 3 ?5 : 4 }. Эдийн засгийн ангилалаар /сая ₮/</b></span>*/}
                {/*            </div>*/}
                {/*            <div className={'card-body'}>*/}
                {/*                <PivotGrid*/}
                {/*                    style={{fontFamily: "'Segoe UI', sans-serif", fontSize: "12px", fontWeight: "700"}}*/}
                {/*                    showRowGrandTotals={true}*/}
                {/*                    showColumnGrandTotals={true}*/}
                {/*                    showColumnTotals={true}*/}
                {/*                    dataSource={planByEconCategory}*/}
                {/*                    texts={{*/}
                {/*                        grandTotal: `Нийт`,*/}
                {/*                    }}*/}
                {/*                >*/}
                {/*                    <Scrolling mode="virtual" />*/}
                {/*                </PivotGrid>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>
            {isPivotData && renderPopupPerformance()}
        </div>
    )
}
export default PlanningPage;

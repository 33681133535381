import React, {useState} from "react"
import {
  FileUploader,
  LoadIndicator,
  Popup,
  ScrollView,
  SelectBox,
} from "devextreme-react"
import styled from "styled-components"
import Form, {
  Item as FormItem1,
  Label,
  RequiredRule,
} from "devextreme-react/form"
import StatisticsCard from "./StatisticsCard"
import {Button as DevExtremeButton} from "devextreme-react/button"
import statsService from "../../services/api/stat.service"
import notify from "devextreme/ui/notify"
import MenuItem from "./components/MenuItem"
import {useLayoutEffect} from "react"
import TabComponent from "./components/TabComponent"
import {useContext} from "react"
import {UserContext} from "../../hooks/UserContext"
import TreeViewOrg from "./components/TreeViewOrg"
import {getYears} from "../../util"
import "./style.scss"
import TblRender from "./components/TblRender"
import LoadPanelComponent from "../budget/components/loadPanel"
import {useEffect} from "react"
import {useParams} from "react-router-dom"
import {Empty} from "antd"
import StatisticLists from "./components/StatisticLists"
import {useLocalStorage} from "../../hooks/useLocalStorage"
import {ProjectButton} from "../proposal"
import {FaBalanceScale} from "react-icons/fa"
import _ from "lodash"
import {GrDocumentUpload} from "react-icons/gr"
import {AiOutlineFileText} from "react-icons/ai"
import {urlServer} from "../../shared/lib/request"
import OgForeCastPdf from "../../assets/pdfFiles/Танилцуулга.pdf"
import LaboratoryStatistics from "./components/LaboratoryStatistics"

const ListContainer = styled.div`
  display: ${(props) => (props.isActive ? "flex" : "none")};
  flex-direction: column;
  padding: 1rem 0;
  align-items: center;
  gap: 1rem;
`
const OrgListContainer = styled.div`
  display: ${(props) => (props.isActive ? "flex" : "none")};
`
function Statistics() {
  const [list, setList] = useLocalStorage("list", [])
  const {isLocal} = useParams()
  const [currentList, setCurrentList] = useState("")
  const [isOrgShown, setIsOrgShown] = useState(true)
  const {user} = useContext(UserContext)
  const [loading, setLoading] = useState(true)
  const [orgId, setOrgId] = useState(user.organization.id)
  const [showLawModal, setShowLawModal] = useState(false)
  const [currentYear, setCurrentYear] = useState(2022)
  const [isLoading, setIsLoading] = useState(false)
  const [showFileModal, setShowFileModal] = useState(false)
  const [popupVisible, setPopupVisible] = useState(false)
  const [orgFile, setOrgFile] = useState(null)
  const [multiple, setMultiple] = useState([])
  const [popUpTushaal, setPopUpTushaal] = useState(false)
  const isAdmin = _.find(user.roles, function (o) {
    return o.role_code === "2" || o.role_code === "1" || o.role_code === "0"
  })

  const getList = async () => {
    setList([])
    try {
      let {data} = await statsService.getList(isLocal, orgId)
      if (data?.length === 0) {
        setCurrentList("")
        setList([])
        return
      }
      setCurrentList(data?.[0]?.list_id)
      setList(data)
    } catch (error) {
      notify(error.message)
    } finally {
      setLoading(false)
    }
  }
  useLayoutEffect(() => {
    getList()
  }, [orgId])

  useEffect(() => {
    setIsOrgShown(true)
    setList([])
    getList()
  }, [isLocal])

  useEffect(() => {
    setOrgFile(null)
    fetchMetaData()
  }, [orgId])

  const fetchMetaData = async () => {
    try {
      let result = await statsService.getOrgFileStatistic(orgId)
      setOrgFile(result?.data?.file)
    } catch (error) {
      console.log("error", error)
    }
  }
  const FileUploadCell = (index) => {
    return (
      <ProjectButton
        hint="Тушаал хавсаргах"
        onClick={() => {
          setPopupVisible(true)
        }}
      >
        <GrDocumentUpload />
      </ProjectButton>
    )
  }
  const showFileModalHandler = () => {
    if (orgFile) {
      setShowFileModal(true)
      return
    }
    notify(`Тушаал оруулаагүй байна!`, "warning", 3000)
    return
  }
  const handleAddPhoto = async () => {
    try {
      setIsLoading(true)
      if (multiple?.length === 0) {
        notify(`Файл оруулна уу!`, "warning", 3000)
        return 0
      }
      let data = new FormData()
      data.append("file", multiple[0])
      const res = await statsService.uploadOrgFileStatistic(data, orgId)
      if (res?.code === 200) {
        setMultiple([])
        setPopupVisible(false)
        fetchMetaData()
        return notify(res.message, "success", 4000)
      }
    } catch (error) {
      console.log("error", error)
      return notify("Хадгалах үйлдэл амжилтгүй боллоо", "warning", 3000)
    } finally {
      setIsLoading(false)
    }
  }
  const ImageUploader = () => {
    return (
      <div style={{}}>
        <FileUploader
          onValueChanged={(e) => setMultiple(e.value)}
          selectButtonText="Файлууд сонгох"
          multiple={false}
          value={multiple}
          labelText=""
          accept="*"
          uploadMode="useForm"
        />
        <div className="note" style={{marginTop: ".25rem"}}>
          {"Файлын өргөтгөл: "}
          <span>.pdf</span>
        </div>
      </div>
    )
  }
  const hideTushaalModal = () => {
    setPopUpTushaal(false)
  }

  return (
    <div className="statistics">
      <Popup
        visible={popupVisible}
        onHiding={() => setPopupVisible(false)}
        hideOnOutsideClick={true}
        showCloseButton
        height="auto"
        showTitle
        title={"Тушаал оруулах"}
        width="30rem"
        id="renderaddCategoryMenu"
      >
        <ScrollView height="100%" width="100%">
          <div className="widget-container">
            <Form>
              <FormItem1 render={ImageUploader}>
                <Label text="Файл оруулах" />
                <RequiredRule message="Зураг оруулна уу!" />
              </FormItem1>
            </Form>
            <DevExtremeButton
              icon="plus"
              text={"Нийтлэх"}
              type="default"
              style={{marginTop: "2rem"}}
              className="saveButton"
              onClick={() => handleAddPhoto()}
            >
              <span className="dx-button-text">
                <LoadIndicator
                  style={{height: 20, width: 20}}
                  visible={isLoading}
                />
                {!isLoading && <span>Хадгалах</span>}
              </span>
            </DevExtremeButton>
          </div>
        </ScrollView>
      </Popup>
      <Popup
        visible={showLawModal}
        onHiding={() => setShowLawModal(false)}
        hideOnOutsideClick={true}
        showTitle={false}
      >
        <div style={{height: "100%"}}>
          {showLawModal && (
            <iframe
              id="lawForBudget"
              width="100%"
              height="100%"
              src={`https://legalinfo.mn/mn/detail/461`}
            ></iframe>
          )}
        </div>
      </Popup>
      <Popup
        visible={showFileModal}
        onHiding={() => setShowFileModal(false)}
        hideOnOutsideClick={true}
        showTitle={false}
      >
        <div style={{height: "100%"}}>
          {showFileModal && (
            <iframe
              width="100%"
              height="100%"
              src={urlServer + "/api/file/" + orgFile?.file_name}
              type="application/pdf"
            />
          )}
        </div>
      </Popup>
      <LoadPanelComponent
        position={{of: "#statistic-content"}}
        visible={loading}
      />
      <div
        style={{
          padding: 10,
          justifyContent: "space-between",
          marginBottom: 10,
          // minHeight: "100vh",
        }}
      >
        <div className="row">
          <div
            className="card"
            style={{margin: 0, width: "25%", minWidth: "17.5rem"}}
          >
            <TabComponent
              setIsOrgShown={setIsOrgShown}
              isOrgShown={isOrgShown}
              children={[
                {title: "Байгууллагаар", isOrg: true},
                {title: "Ангиллаар", isOrg: false},
              ]}
            />
            <ScrollView width="100%" height="calc(100vh - 200px)">
              <OrgListContainer isActive={isOrgShown}>
                <TreeViewOrg setOrgId={setOrgId} orgId={orgId} />
              </OrgListContainer>
              <ListContainer isActive={!isOrgShown}>
                {list?.map((item, index) => (
                  <MenuItem
                    isOrgShown={isOrgShown}
                    isActive={item.list_id === currentList}
                    setCurrentList={setCurrentList}
                    key={index}
                    {...item}
                  />
                ))}
                {(list?.length === 0 && !loading) && (
                  <Empty
                    style={{marginTop: "2rem"}}
                    description="Ангилал байхгүй байна"
                  />
                )}
              </ListContainer>
            </ScrollView>
          </div>
          <div
            style={{
              paddingLeft: "1rem",
              margin: 0,
              maxWidth: "75%",
              flex: 1,
            }}
          >
            {orgId == 19 && isLocal == 1 ? (
             <LaboratoryStatistics/>
            ) : (
              <div
                style={{
                  width: "100%",
                  overflowY: "auto",
                  height: "calc(100vh - 140px)",
                  border: "1px solid #DEDEDE",
                  borderRadius: 15,
                  padding: "0 1rem",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    padding: "1rem 0",
                    justifyContent: "flex-start",
                    alignItems: 'end'
                  }}
                >
                  <div>
                    <SelectBox
                      label="Жил"
                      items={getYears(true)}
                      width={100}
                      height={37}
                      defaultValue={currentYear}
                      onValueChanged={(e) => {
                        setCurrentYear(e.value)
                      }}
                      placeholder="сонгох"
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginLeft: "10px",
                    }}
                  >
                    <ProjectButton
                      onClick={() => setShowLawModal(true)}
                      id="budgetLaw"
                      className="projectButton"
                    >
                      <FaBalanceScale
                        style={{fontSize: "1rem", color: "black"}}
                      />
                    </ProjectButton>
                  </div>
                  {popUpTushaal && (
                    <Popup
                      width={1200}
                      maxWidth={"100%"}
                      minHeight={"100%"}
                      height={"100%"}
                      visible={popUpTushaal}
                      onHiding={hideTushaalModal}
                      title="Тушаал"
                      dragEnabled={false}
                      hideOnOutsideClick={true}
                    >
                      <object
                        width="100%"
                        height="100%"
                        data={urlServer + "/api/file/" + orgFile?.file_name}
                        type="application/pdf"
                        title={"Тушаал"}
                      />
                    </Popup>
                  )}
                  {orgFile && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "10px",
                      }}
                    >
                      <ProjectButton
                        onClick={() => setPopUpTushaal(true)}
                        id="budgetLaw"
                        hint="Тушаал харах"
                        className="projectButton"
                      >
                        <AiOutlineFileText
                          style={{fontSize: "1rem", color: "black"}}
                        />
                      </ProjectButton>
                    </div>
                  )}
                  {isAdmin && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "10px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          fontWeight: 700,
                          paddingRight: "10px",
                        }}
                      >
                        Тушаал хавсаргах:
                      </div>
                      <FileUploadCell />
                    </div>
                  )}
                </div>
                <div id="statistic-content" style={{minHeight: "60vh"}}>
                  <StatisticLists
                    list={isOrgShown ? list : [currentList]}
                    isOrgShown={isOrgShown}
                    orgId={orgId}
                    year={currentYear}
                    isLocal={isLocal}
                    loading={loading}
                    setLoading={setLoading}
                    currentList={currentList}
                  />
                  {(list?.length === 0  && !loading) && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "60vh",
                        width: "100%",
                      }}
                    >
                      <Empty
                        style={{marginTop: "2rem"}}
                        description="Өгөгдөл байхгүй байна"
                      />
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {/*Footer*/}
      {isLocal == 0 && (
        <div
          className="row card"
          style={{
            display: "flex",
            alignItems: "center",
            padding: ".5rem 1rem",
            justifyContent: "center",
          }}
        >
          <span style={{color: "#2E4765", fontWeight: "bold"}}>
            Эх сурвалж:
          </span>
          <span style={{marginLeft: ".25rem", fontWeight: 500}}>
            Үндэсний Статистикийн хороо{" "}
            <a href="https://1212.mn/" target="blank">
              1212.mn
            </a>
          </span>
        </div>
      )}
    </div>
  )
}

export default Statistics

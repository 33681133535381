import request from '../../shared/lib/request'

const getByIdNotification = (id) => {
    return request({
        url: `/notifications/${id}`,
        method: 'GET'
    })
}

const getAllNotifications = () => {
    return request({
        url: '/notifications/',
        method: 'GET'
    })
}

const addNotification = (body) => {
    return request({
        url: '/notifications/',
        method: 'POST',
        data: body
    })
}

const updateNotification = (id, body) => {
    return request({
        url: `/notifications/${id}`,
        method: 'PUT',
        data: body
    })
}

const notificationService = {
    getByIdNotification,
    getAllNotifications,
    addNotification,
    updateNotification,
}

export default notificationService;
import React, {useEffect, useState} from "react"
import ga4Services from "../../../services/api/googleAnalytics"
import {DataGrid, PieChart, SelectBox} from "devextreme-react"
import {Column, Paging} from "devextreme-react/data-grid"
import {budgetPalettes} from "../../../util"
import _ from "lodash"
import {
  Export,
  Label,
  Series,
  Connector,
  Legend,
  Tooltip,
  SmallValuesGrouping,
} from "devextreme-react/pie-chart"
const selectionist = [
  {id: 1, text: "Дүрслэл 1", value: 1},
  {id: 2, text: "Дүрслэл 2", value: 2},
]
const numberWithCommas = (x) => {
  try {
    return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
  } catch (err) {
    throw err
  }
}
function GaPopularPaths() {
  const [popularPaths, setPopularPaths] = useState([])
  const [currentType, setCurrentType] = useState(1)
  const [threshold, setThreshold] = useState()

  const cstmText = (e) => {
    return `<span style="color: #f7f7fa">${numberWithCommas(e.value)}<br/>${(
      e.percent * 100
    ).toFixed(2)}%</span>`
  }
  useEffect(()=>{
    fetchPopularPaths()
  }, [])
  const fetchPopularPaths = async () => {
    try {
      let data = (await ga4Services.getPopularPaths()).data
      const orderedData = data.rows
        .map((item) => {
          return {
            name: item.dimensionValues[0].value,
            value: parseInt(item.metricValues[0].value),
          }
        })
        .sort((a, b) => b.value - a.value)
      const average =
        orderedData.reduce((prev, curr) => prev + curr.value, 0) /
        orderedData.length
      setThreshold(average)
      setPopularPaths(orderedData)
    } catch (error) {
      console.log("error", error)
    }
  }
  const customizeTooltip = (arg) => {
    return {
      text: `${arg.argumentText}  ${numberWithCommas(arg.value)}  ${(
        arg.percent * 100
      ).toFixed(2)}%`,
    }
  }
  return (
    <div className="card">
      <div className="card-header">
        <span
          style={{
            fontFamily: "'Montserrat', sans-serif",
            fontWeight: "bold",
          }}
        >
          1. Цэсүүдийн зочилсон тоо
        </span>
      </div>
      <div className="card-body" style={{paddingBottom: "1.6rem"}}>
        <div style={{width: "100%"}}>
          <SelectBox
            items={selectionist}
            valueExpr="id"
            displayExpr="text"
            onValueChanged={(e) => setCurrentType(e.value)}
            defaultValue={currentType}
            style={{borderRadius: 10}}
          />
        </div>
        {currentType === 1 ? (
          <PieChart
            type="doughnut"
            dataSource={popularPaths}
            resolveLabelOverlapping="hide"
            palette={_.find(budgetPalettes, {id: 1.1}).color}
          >
            <Series argumentField="name" valueField="value">
              <Label visible={true} position="columns" customizeText={cstmText}>
                <Connector visible={true} width={0.5} />
              </Label>
              <SmallValuesGrouping
                groupName={"Бусад"}
                threshold={threshold}
                mode="smallValueThreshold"
              />
            </Series>
            <Export enabled={true} />
            <Legend
              orientation="horizontal"
              itemTextPosition="right"
              horizontalAlignment="center"
              verticalAlignment="bottom"
              columnCount={4}
            />
            {/*<Legend visible={true} horizontalAlignment="center" verticalAlignment="bottom"/>*/}
            <Tooltip
              zIndex={10000}
              enabled={true}
              customizeTooltip={customizeTooltip}
              font={{family: "'Montserrat', sans-serif"}}
            ></Tooltip>
          </PieChart>
        ) : (
          <DataGrid dataSource={popularPaths}>
            <Paging defaultPageSize={10} />
            <Column dataField={"name"} caption={"Талбарууд"} />
            <Column dataField={"value"} caption={"Тоо хэмжээ"} />
          </DataGrid>
        )}
      </div>
    </div>
  )
}

export default GaPopularPaths

import React from "react"
import {useHistory} from "react-router-dom"
import styled from "styled-components"
import {numberWithCommas11} from "../../util"
import {LoadIndicator} from "devextreme-react"
import {Tooltip} from "antd"
import {BsInfo} from "react-icons/bs"
import info1 from "../../assets/imgs/icon/iconInfo.png";
const Container = styled.div`
  display: flex;
  padding: 1rem 1.5rem;
  padding-right: 2rem;
  border-radius: 0.5rem;
  border: 1px solid #eaecf0;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  flex-direction: column;
  min-height: 100%;
  height: 100%;
  height: auto;
  cursor: pointer;
  transition: all 0.3s ease;
  &:hover {
    background-color: #f9fafb;
    .text .sum {
      color: #1677ff;
    }
    .infoButton {
      pointer-events: all;
      opacity: 1;
    }
  }
  .text {
    text-decoration: none;
    text-align: left;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    gap: 0.85rem;
    .title {
      font-weight: 400;
      color: black;
      font-size: 0.875rem;
      span{
        font-weight: 600;
      }
    }
    .year {
      font-weight: 400;
      /* color: rgba(0, 0, 0, 0.45); */
      color: black;
      font-size: 0.875rem;
      span{
        /* color: rgba(0, 0, 0, 0.45); */
      }
    }
    .sum {
      font-size: 1.6rem;
      color: black;
      font-weight: 700;
      height: 32.56px;
    }
  }
  .infoButton {
    opacity: 0;
    pointer-events: none;
    position: absolute;
    bottom: 0.75rem;
    right: 0.5rem;
    background-color: #1677ff;
    color: white;
    height: 1.5rem;
    width: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: help;
    border-radius: 50%;
    border: none;
    transition: 0.3s all ease;
  }
  .dateButton {
    position: absolute;
    top: .75rem;
    right: 0.5rem;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: help;
    border-radius: 50%;
    border: none;
    transition: 0.3s all ease;
  }
`
function extractConsecutiveCapitalizedWords(string) {
  const capitalizedWords = string.match(/[\dА-ЯҮӨЁA-Z]+(?:\s+[\dА-ЯҮӨЁA-Z]+)*/g)
  const result = capitalizedWords ? capitalizedWords.join(" ") : ""
  return result.replace(/,\s*$/, "")
}

function StatisticsCard(props) {
  const {loading} = props
  const navigate = useHistory()

  const onClickHandler = () => {
    if (props?.tbl_id && props?.year && props?.list_id) {
      let location = {
        pathname: `/statistics/${props.list_id}/${props?.tbl_id}/${props?.year}`,
        state: { isLocal: props.isLocal},
    };
      navigate.push(location)
    }
  }
  function reformatDate(inputString) {
    const year = inputString.slice(0, 4)
    const month = inputString.slice(4, 6)
    const day = inputString.slice(6, 8)
    return `${year}-${month}-${day}`
  }
  if (!props?.sum) return null
  return (
    <>
      <Container onClick={onClickHandler}>
        {props?.description && (
          <Tooltip placement="topLeft" title={props?.description}>
            <button className="infoButton">
              <BsInfo style={{fontSize: "1.2rem"}} />
            </button>
          </Tooltip>
        )}
        <Tooltip placement="topLeft" title={`Шинэчлэгдсэн: ${reformatDate(props?.lst_chn_de)}`}>
          <img className="dateButton" src={info1} alt="" style={{height: "24px", width: "24px"}}/>
          </Tooltip>
        <div className="text">
          <div className="title">
            <span>{extractConsecutiveCapitalizedWords(props?.tbl_nm)}</span>{" "}
            {props?.cd_nm && `/${props?.cd_nm}/`}
          </div>
          <div className="sum">
            {props?.sum ? (
              numberWithCommas11(props?.sum)
            ) : (loading ?
              <LoadIndicator id="small-indicator" height={20} width={20} /> : null
            )}
          </div>
          <div className="year">Тайлант он: <span>{props?.year}</span></div>
        </div>
      </Container>
    </>
  )
}

export default StatisticsCard

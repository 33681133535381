import React from "react"
import styled from "styled-components"
import { urlServer } from "../../shared/lib/request"

const Container = styled.div`
  .xac-download .left-icon > img {
    position: absolute;
    top: 50%;
    margin-left: 25px;
    margin-top: -12px;
  }

  .xac-download .report-title {
    display: block;
    padding: 0 2rem;
    display: flex;
    align-items: center;
    height: 100%;
    font-size: 0.875rem;
  }
  .transition {
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
  }
  .left-icon > img {
    display: none;
    transition: all 0.2s ease-in;
  }
  .xac-download:hover .left-icon {
    width: 75px;
  }
  .xac-download:hover .left-icon > img {
    display: inline-block;
    width: 25px !important;
    transition: all 0.2s ease-out;
  }

  .xac-download:hover .report-title {
    padding-left: 107px;
   
  }

  .xac-download {
    position: relative;
    background-color: var(--color-background-primary);
    margin-bottom: 17px;
    display: block;
    cursor: pointer;
    font-weight: 500;
    width: 100%;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.095);
  }

  a.xac-download:hover {
    color: var(--color-text);
  }

  .xac-download .report-meta {
    padding: 0.875rem;
    float: right;
    font-size: 0.875rem;
    color: var(--color-text);
  }

  .xac-download .left-icon {
    width: 4px;
    background-color: #1677ff;
    position: absolute;
    top: 0px;
    bottom: 0px;
  }
`
function getFileFormat(fileName) {
  const lastDotIndex = fileName?.lastIndexOf(".")

  if (lastDotIndex === -1) {
    return null
  }

  const format = fileName?.substring(lastDotIndex + 1).toLowerCase()
  return format
}
function FileCard(props) {
  return (
    <Container
      style={{width: "100%", display: "flex", justifyContent: "center"}}
    >
      <a
        className="xac-download"
        style={{textDecoration: "none", color: "black"}}
        href={`${urlServer}/api/file/${props.file_name}`}
      >
        <span className="report-meta uk-hidden-small uk-text-uppercase">
          <span style={{fontWeight: 600,   color: "var(--color-text)"}}>{getFileFormat(props?.file_name)}</span> <br />
          {props.size}
        </span>
        <span className="left-icon transition">
          <img
            src="https://www.xacbank.mn/img/icons/download.png"

          />
        </span>
        <span className="report-title transition">
          <span style={{  color: "var(--color-text)"}}>{props?.text?.toUpperCase().slice(14)}</span>
        </span>
      </a>
    </Container>
  )
}

export default FileCard

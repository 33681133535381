import React, { useEffect, useState, useContext } from 'react'
import './style.css'
import BudgetService from '../../../../services/api/budget'
import notify from 'devextreme/ui/notify'
import { Button, DataGrid, Popup, SelectBox, Tooltip } from 'devextreme-react'
import { Column, Export, FilterPanel, FilterRow, GroupItem, Grouping, HeaderFilter, MasterDetail, Paging, Summary, TotalItem } from 'devextreme-react/data-grid'
import { EZAType } from './EZAType'
import _ from 'lodash'
import { formatDate, numberWithCommas } from '../../../../util'
import { ExpenseDetail } from './components/ExpenseDetail'
import InvestExecutionByMonth from './components/InvestExecutionByMonth'
import InvestExecutionByYear from './components/InvestExecutionByYear'
import info1 from "../../../../assets/imgs/icon/iconInfo1.png"
import { Workbook } from 'exceljs'
import { exportDataGrid } from 'devextreme/excel_exporter'
import { UserContext } from '../../../../hooks/UserContext'
import moment from 'moment'
import { saveAs } from "file-saver-es";
import styled from 'styled-components'
import { Sanhuujilt } from './Sanhuujilt'
import lawIcon from "../../../../assets/imgs/laaawIcon.svg"

export const TabComponent = styled.div`
  display: flex;
  font-weight: bold;
  .tab {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 0.5rem;
    background-color: #f5f5f5;
    transition: 0.3s all ease-in;
    &:nth-child(1) {
      border-top-left-radius: 15px;
      border-bottom: 1px solid #ddd;
    }
    &:nth-child(2) {
      border-top-right-radius: 15px;
      border-bottom: 1px solid #ddd;
    }
    .title {
      font-size: 1rem;
     
    }
    &:hover {
      background-color: #ebf1ff;
    }
    &.active {
      color: black;
      background-color: white;
      font-weight: 700;
      border-color: transparent;
      text-decoration-line: underline;
    }
  }
`

export const NationalAsset = () => {
  const { user } = useContext(UserContext)
  const [gridData, setGridData] = useState([])
  const [mainDataByEZA, setMainDataByEZA] = useState([]);
  const [mainDataByType, setMainDataByType] = useState([])
  const [budgetYearList, setBudgetYearList] = useState([])
  const [budgetYear, setBudgetYear] = useState(new Date().getFullYear());
  const [investYearList, setInvestYearList] = useState();
  const [investYear, setInvestYear] = useState('БҮГД');
  const [horizontalData, setHorizontalData] = useState({});
  const [firstChartVisible, setFirstChartVisible] = useState(false);
  const [whichComponentMaxmized, setWhichComponentMaximized] = useState(null);
  const [dateData, setDateData] = useState([])
  const [loader, setLoader] = useState(false)
  const [withAnimationVisibleDate, setWithAnimationVisibleDate] = useState(false);
  const [payAmtSum, setPayAmtSum] = useState(null);
  const [paymentAmtSum, setPaymentAmtSum] = useState(null);
  const [selectedTab, setSelectedTab] = useState(0)
  const [rowPopup, setRowPopup] = useState(false);
  const [detailData, setDetailData] = useState(null);

  useEffect(() => {
    loadData();
    loadBudgetYearList();
    loadInvestYearList();
  }, [])

  useEffect(() => {
    loadData();
  }, [budgetYear, investYear])

  useEffect(() => {
    loadInvestYearList();
    loadMainDateData();
    loadExecutionDateData();
  }, [budgetYear])

  const loadBudgetYearList = async () => {
    try {
      let result = await BudgetService.getOrgBudgetYear();
      setBudgetYearList(result)
    } catch (error) {
      notify(error.message, "error", 2000)
    }
  }

  const loadInvestYearList = async () => {
    try {
      let result = await BudgetService.getInvestYearList(budgetYear);
      setInvestYearList(['БҮГД', ...result]);
    } catch (error) {
      notify(error.message, "error", 2000)
    }
  }

  const loadData = async () => {
    try {
      setLoader(true)
      let res = await BudgetService.getInvestData(budgetYear, investYear);
      setGridData(res);
      setPayAmtSum(_.sumBy(res, entry => parseFloat(entry.payAmt)))
      setPaymentAmtSum(_.sumBy(res, entry => parseFloat(entry.paymentAmount)))

      // EZA-aar tusliin too bolon niit dung gargaj bga:
      const groupedEZAData = _.groupBy(res, 'shortCd');
      const result1 = _.map(groupedEZAData, (entries, eza) => {
        const count = entries.length;
        const payAmt = _.sumBy(entries, entry => parseFloat(entry.payAmt) || 0);
        const paymentAmount = _.sumBy(entries, entry => parseFloat(entry.paymentAmount) || 0);
        return { eza, ezaNm: entries[0].ezNm, count, payAmt: payAmt, paymentAmount: paymentAmount};
      });
      setMainDataByEZA(result1)

      // Turluur tusliin too bolon niit dung gargaj bga:
      const groupedTypeData = _.groupBy(res, 'statusNm');
      const result2 = _.map(groupedTypeData, (entries, type) => {
        const count = entries.length;
        const payAmt = _.sumBy(entries, entry => parseFloat(entry.payAmt) || 0);
        return { type, count, payAmt: payAmt };
      });
      setMainDataByType(result2)
      setLoader(false)
    } catch (e) {
      setLoader(false)
      notify(e.message, 'error', 2000)
    }
  }

  const loadMainDateData = async () => {
    try {
      setLoader(true)
      let res = await BudgetService.getHurungiinZardalDate(budgetYear);
      setHorizontalData(res[0]);
      setLoader(false)
    } catch (e) {
      setLoader(false)
      notify(e.message, 'error', 2000);
    }
  }

  const loadExecutionDateData = async () => {
    try {
      let res = await BudgetService.getInvestExecutionByType(budgetYear);
      setDateData(res);
    } catch (e) {
      notify(e.message, 'error', 2000)
    }
  }

  const onCellPreparedHandler = (e) => {
    let elem = e.cellElement
    elem.style.setProperty('cursor', 'pointer', 'important');
    if (e.rowType === "header") {
      elem.wordWrapEnabled = true
      elem.style.fontWeight = "bold"
      elem.style.color = "#15186A"
      elem.style.backgroundColor = "#EEF0F4"
    } else if (e.rowType === 'group') {
      e.column.caption = ``
      elem.style.color = "#15186A"
      elem.style.backgroundColor = "#fff"
      elem.style.fontSize = "12px"
      elem.style.borderColor = "#D4DCEC"
    } else if (e.columnIndex === 1 && e.rowType === 'data') {
      elem.style.color = '#000'
    } else if (e.columnIndex === 3) {
      elem.style.color = '#15186a'
    }
  }

  const groupCell = (e) => {
    return <div>{e.data.displayValue}: {e.data?.summaryItems[0].value + ' төсөл'}</div>
  }

  const onExporting = (e) => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Main sheet');
    worksheet.columns = [
      { width: 12 }, { width: 35 }, { width: 100 }, { width: 18 }, { width: 18 }, { width: 18 }, { width: 20 }, { width: 13 }, { width: 13 }, { width: 18 }, { width: 18 }
    ];
    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
      keepColumnWidths: false,
      topLeftCell: { row: 6, column: 2 },
      customizeCell: ({ gridCell, excelCell }) => {
        if (gridCell.rowType === 'header') {
          excelCell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'd9d9d9' } };
          excelCell.font = { color: { argb: '00000' }, name: 'Arial', size: 8, 'bold': true };
        }
        if (gridCell.rowType === 'group') {
          if (gridCell.groupIndex === 0) {
            excelCell.font = { color: { argb: 'F93A3A' }, name: 'Arial', size: 8 };
            excelCell.font.fontWeight = 'bold';
          }
          if (gridCell.groupIndex === 1) {
            excelCell.font = { color: { argb: '2C77FF' }, name: 'Arial', size: 8, 'bold': true };
          }
        }
        if (gridCell.rowType === 'totalFooter' && excelCell.value) {
          excelCell.font = { name: 'Arial', size: 8, 'italic': true, 'bold': true };
        }
        if (gridCell.rowType === 'data') {
          excelCell.font = { name: 'Arial', size: 8 };
          excelCell.alignment = { wrapText: true };
        }
        if (gridCell.column.dataField === 'lawNo') {
          excelCell.alignment = { horizontal: 'center' };
        }
      },
    }).then(() => {
      Object.assign(worksheet.getRow(1).getCell(1), {
        value: 'Гарчиг:',
        font: { name: 'Arial', size: 8 },
      })
      Object.assign(worksheet.getRow(1).getCell(2), {
        value: `${budgetYear} оны Төсөл, арга хэмжээний ерөнхий төлөвлөгөө`,
        font: { name: 'Arial', size: 8, 'bold': true, color: { argb: '0070c0' } },
      })
      Object.assign(worksheet.getRow(2).getCell(1), {
        value: 'Байгууллага:',
        font: { name: 'Arial', size: 8 },
      })
      Object.assign(worksheet.getRow(2).getCell(2), {
        value: user.organization.name,
        font: { name: 'Arial', size: 8 },
      })
      Object.assign(worksheet.getRow(3).getCell(1), {
        value: 'Хэрэглэгч:',
        font: { name: 'Arial', size: 8 },
      })
      Object.assign(worksheet.getRow(3).getCell(2), {
        value: `${user.last_name.slice(0, 1)}. ${user.first_name}, ${user.position_name}`,
        font: { name: 'Arial', size: 8 },
      })
      Object.assign(worksheet.getRow(4).getCell(1), {
        value: 'Огноо:',
        font: { name: 'Arial', size: 8 },
      })
      Object.assign(worksheet.getRow(4).getCell(2), {
        value: new moment().locale('mn').format('lll'),
        font: { name: 'Arial', size: 8 },
      })
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `OG-${budgetYear} оны Төсөл, арга хэмжээний ерөнхий төлөвлөгөө-` + formatDate(new Date()) + ".xlsx");
      });
    });
    e.cancel = true;
  }

  let maximizeIcon = '<svg t="1659165394467" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2309" width="16" height="16"><path d="M504.576 603.428571a19.748571 19.748571 0 0 1-5.705143 13.129143l-189.696 189.696 82.285714 82.285715a36.425143 36.425143 0 0 1 10.861715 25.709714c0 20.004571-16.566857 36.571429-36.571429 36.571428h-256c-20.004571 0-36.571429-16.566857-36.571428-36.571428v-256c0-20.004571 16.566857-36.571429 36.571428-36.571429a36.205714 36.205714 0 0 1 25.709714 10.861715l82.285715 82.285714 189.696-189.696c3.437714-3.437714 8.557714-5.705143 13.129143-5.705143s9.728 2.304 13.129142 5.705143l65.133715 65.133714a19.602286 19.602286 0 0 1 5.705143 13.129143zM950.857143 109.714286v256c0 20.004571-16.566857 36.571429-36.571429 36.571428a36.205714 36.205714 0 0 1-25.709714-10.861714l-82.285714-82.285714-189.696 189.696c-3.437714 3.437714-8.557714 5.705143-13.129143 5.705143s-9.728-2.304-13.129143-5.705143l-65.133714-65.133715c-3.437714-3.437714-5.705143-8.557714-5.705143-13.129142s2.304-9.728 5.705143-13.129143l189.696-189.696-82.285715-82.285715a36.425143 36.425143 0 0 1-10.861714-25.709714c0-20.004571 16.566857-36.571429 36.571429-36.571428h256c20.004571 0 36.571429 16.566857 36.571428 36.571428z" fill="#515151" p-id="2310"></path></svg>';

  const popUpHandler = (trueFalser, value) => {
    setFirstChartVisible(trueFalser);
    setWhichComponentMaximized(value);
  };

  const renderCard = (label, value, measNm) => (
    <div className="master-detail-card" >
      <div style={{ fontSize: 14 }}>
        <span>{label}:</span><br />
        <b>{value ? value : `-`} {measNm && measNm.toLowerCase()}</b>
      </div>
    </div>
  );

  const handleRowClick = async (e) => {
    if (e.columnIndex > 2 && e.rowType === 'data') {
      setDetailData(e.data);
      setRowPopup(true);
    }
  };

  return (
    <div style={{ fontFamily: "Segoe UI" }}>
      <Popup
        visible={firstChartVisible}
        onHiding={() => setFirstChartVisible(false)}
        hideOnOutsideClick={true}
        showCloseButton={true}
        height={550}
        title={whichComponentMaxmized && whichComponentMaxmized === 1 ? "ХӨРӨНГИЙН ЗАРДАЛ, ГҮЙЦЭТГЭЛ /жилээр, сая ₮/" : "ХӨРӨНГИЙН ЗАРДАЛ, ГҮЙЦЭТГЭЛ /сараар, сая ₮/"}
        showTitle={true}
      >
        {whichComponentMaxmized === 1 && <InvestExecutionByYear />}
        {whichComponentMaxmized === 2 && <InvestExecutionByMonth budgetYear={budgetYear} />}
      </Popup>
      {horizontalData && <Tooltip
        target="#investExclusionDateId"
        position={{ my: 'top', at: 'top', collision: 'fit flip', offset: '0 10' }}
        visible={withAnimationVisibleDate}
        hideOnOutsideClick={true}
      >
        <div style={{ padding: 16, textAlign: 'start', width: '15rem', backgroundColor: '#555', color: '#fff', borderRadius: 10 }}>
          <b style={{ opacity: 0.6 }}>Шинэчлэгдсэн огноо:</b>
          <hr />
          <div>
            <div>Хөрөнгийн зардал: <span style={{ float: 'right' }}>{horizontalData.zardal_date ? horizontalData.zardal_date : '-'}</span></div>
            <div>Гүйцэтгэл: <span style={{ float: 'right' }}>{horizontalData.guitsetgel_date ? horizontalData.guitsetgel_date : '-'}</span></div>
          </div>
        </div>
      </Tooltip>
      }
      <div style={{ padding: 10, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div style={{ display: "flex", alignItems: "baseline", flexWrap: 'wrap', gap: '1rem' }}>
          <div>
            <SelectBox
              items={budgetYearList}
              valueExpr="value"
              displayExpr="value"
              label="Төсвийн жил"
              width='10rem'
              defaultValue={budgetYear}
              onValueChanged={(e) => setBudgetYear(e.value)}
              placeholder="сонгох" style={{ borderRadius: 10 }} />
          </div>
          <div>
            <SelectBox
              items={investYearList}
              label="Төслийн жил"
              width='10rem'
              defaultValue={investYear}
              onValueChanged={(e) => setInvestYear(e.value)}
              placeholder="БҮГД" style={{ borderRadius: 10 }} />
          </div>
          <div style={{ alignItems: "center", color: "#003696", fontWeight: 'bold' }}>
            {budgetYear} ОНЫ ХӨРӨНГӨ ОРУУЛАЛТЫН ТӨСӨЛ, АРГА ХЭМЖЭЭ
          </div>
        </div>
        <div style={{ height: 28.5 }}>
          <Button
            style={{ fontWeight: "bold" }}
            icon={lawIcon}
            id="lawIcon"
            hint='Төсвийн тухай хууль'
            onClick={() => window.open('https://legalinfo.mn/mn/detail/12254', '_blank')}
          />
        </div>
      </div>
      <div className='card'>
        <TabComponent>
          {['Төсөл, арга хэмжээ', 'Санхүүжилт'].map((e, index) => (
            <div
              key={index}
              onClick={() => setSelectedTab(index)}
              className={`tab ${e.id} ${selectedTab === index && "active"
                }`}
            >
              <span className="title">{e}</span>
            </div>
          ))}
        </TabComponent>
        {selectedTab === 0 ?
          <div style={{ padding: '10px 5px' }}>
            {<div style={{ display: 'flex' }}>
              <div className="card" style={{ flex: 1, position: 'relative', border: '1px solid #f5a2a4' }}>
                <div style={{ padding: '1rem', borderBottom: '1px solid #dedede', display: 'flex', justifyContent: 'space-between' }}>
                  <div style={{ color: '#f5a2a4', fontWeight: 'bold' }}>ХӨРӨНГИЙН ЗАРДАЛ</div>
                  <div id="investExclusionDateId" onMouseEnter={() => setWithAnimationVisibleDate(true)} onMouseLeave={() => setWithAnimationVisibleDate(false)} >
                    <img style={{ position: 'absolute', right: 10, top: 10 }} alt='' width={32} height={32} src={info1} />
                  </div>
                </div>
                <div style={{ padding: '1rem', display: 'flex', flexDirection: 'column', gap: 10 }}>
                  <div>Нийт төсөл: <b style={{ float: 'right' }}>{gridData.length > 0 ? gridData.length : '-'}</b></div>
                  <div>Нийт төсөвт өртөг /сая ₮/: <b style={{ float: 'right' }}>{payAmtSum ? numberWithCommas(payAmtSum) : horizontalData?.zardal ? numberWithCommas(horizontalData.zardal / 1000000) : '-'}</b></div>
                  <div>Гүйцэтгэл /сая ₮/: <b style={{ float: 'right' }}>{horizontalData?.zardal && horizontalData?.guitsetgel ? numberWithCommas(horizontalData.guitsetgel / 1000000) + ` (${((100 * horizontalData.guitsetgel) / horizontalData.zardal).toFixed(2)}%)` : `-`}</b></div>
                  <hr/>
                  <b>PIMIS:</b>
                  <div>Нийт олгосон дүн /сая ₮/: <b style={{ float: 'right' }}>{paymentAmtSum ? numberWithCommas(paymentAmtSum/1000000) : '-'}</b></div>
                  <div>Зөрүү /сая ₮/: <b style={{ float: 'right', color: 'red' }}>{horizontalData?.zardal && horizontalData?.guitsetgel && paymentAmtSum ? numberWithCommas((horizontalData.guitsetgel-paymentAmtSum)/1000000) : '-'}</b></div>
                </div>
                <div className="col-2" style={{ margin: 0, display: 'flex', justifyContent: 'end', cursor: 'default', position: 'absolute', right: 0, top: 0 }}>
                </div>
              </div>
              {mainDataByEZA.map((data, index) => {
                const find = dateData.find(item => item.econ_category_code === data.eza);
                return (
                  <div key={index} className='card' style={{ flex: 1 }}>
                    <div style={{ textTransform: 'uppercase', padding: '1rem', fontWeight: 'bold', borderBottom: '1px solid #dedede' }}>{data.ezaNm}</div>
                    <div style={{ padding: '1rem', display: 'flex', flexDirection: 'column', gap: 10 }}>
                      <div>Төсөл: <b style={{ float: 'right' }}>{data.count}</b></div>
                      <div>Төсөвт өртөг /сая ₮/: <b style={{ float: 'right' }}>{numberWithCommas(data.payAmt)}</b></div>
                      <div>Гүйцэтгэл /сая ₮/: <b style={{ float: 'right' }}>{find?.guitsetgel ? numberWithCommas(find.guitsetgel) + ` (${(find.guitsetgel * 100 / data.payAmt).toFixed(2)}%)` : '-'}</b></div>
                      <hr/>
                      <b>PIMIS:</b>
                      <div>Нийт олгосон дүн /сая ₮/: <b style={{ float: 'right' }}>{numberWithCommas(data.paymentAmount/1000000)}</b></div>
                      <div>Зөрүү /сая ₮/: <b style={{ float: 'right', color: 'red' }}>{find?.guitsetgel && data.paymentAmount ? (numberWithCommas(find.guitsetgel-(data.paymentAmount/1000000))) : '-'}</b></div>
                    </div>
                  </div>
                )
              })}
            </div>
            }
            <div style={{ display: 'flex', width: '100%' }}>
              {mainDataByType.map((e, i) => (
                <div key={i} className={`card responsive-col`} style={{ width: `calc(100%/${mainDataByType.length})` }}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid #DEDEDE', padding: 16, fontSize: 14 }}>
                    <b style={{ textTransform: 'uppercase' }}>{i + 1}. {e.type}</b>
                    <div style={{ display: 'flex', gap: 16 }}>
                      <span>{e.count} ТӨСӨЛ</span>
                      <span>|</span>
                      <b style={{ color: '#64748B' }}>{numberWithCommas(e.payAmt)} САЯ ₮ </b>
                    </div>
                  </div>
                  <div style={{ padding: 10 }}>
                    <EZAType filteredData={_.filter(gridData, { statusNm: e.type })} type={e.type} budgetYear={budgetYear} investYear={investYear} />
                  </div>
                </div>
              ))}
            </div>
            <div style={{ margin: 5 }}>
              <DataGrid
                dataSource={gridData}
                noDataText='Дата байхгүй байна'
                wordWrapEnabled={true}
                columnAutoWidth={true}
                scrolling={true}
                showRowLines={true}
                showBorders={true}
                showColumnLines={true}
                onCellPrepared={onCellPreparedHandler}
                hoverStateEnabled={true}
                rowAlternationEnabled={true}
                onExporting={onExporting}
                onCellClick={handleRowClick}
              >
                <MasterDetail enabled={true} component={ExpenseDetail}/>
                <FilterPanel visible={true} texts={{ clearFilter: 'Шүүлтүүр цэвэрлэх', createFilter: 'Шүүх' }} />
                <HeaderFilter visible={true} />
                <FilterRow visible={true} />
                <Export enabled={true} />
                <Paging defaultPageSize={25} />
                <Grouping
                  contextMenuEnabled={false}
                  expandMode="rowClick"
                  texts={{
                    groupContinuesMessage: 'Дараагийн хуудас',
                    groupContinuedMessage: 'Өмнөх хуудас'
                  }}
                />
                <Column groupIndex={0} dataField="statusNm" autoExpandGroup={true} sortOrder='desc' groupCellComponent={groupCell} />
                <Column groupIndex={1} dataField="ezNm" autoExpandGroup={false} groupCellComponent={groupCell} />
                <Column dataField='lawNo' caption='Хуулийн дугаар' />
                <Column dataField='pipNm' caption='Төсөл, арга хэмжээний нэр, хүчин чадал, байршил' />
                <Column dataField='utCode' caption='Төслийн код' />
                <Column dataField='amgNm' caption='Аймаг/Нийслэл' />
                <Column dataField='objNm' caption='Объект' />
                <Column dataField='sumNm' caption='Сум/Дүүрэг' />
                <Column dataField='strDt' caption='Эхлэх' alignment='center' />
                <Column dataField='endDt' caption='Дуусах' alignment='center' />
                <Column dataField='contractor' caption='Гүйцэтгэгч байгууллага' allowFiltering={true} alignment='left' allowSearch={true} />
                <Column dataField='amount' caption='Санхүүжих дүн /сая ₮/' alignment='left' allowFiltering={false} customizeText={(e) => numberWithCommas(e.value)} />
                <Column dataField='paymentAmount' caption='Олгосон дүн /сая ₮/' alignment='left' allowFiltering={false} customizeText={(e) => numberWithCommas(e.value/1000000)} />
                <Column dataField='payAmt' caption={budgetYear + ` оны төсөвт өртөг /сая ₮/`} allowFiltering={false} alignment='left' customizeText={(e) => numberWithCommas(e.value)} />
                <Summary>
                  <GroupItem summaryType='count' displayFormat='{0}-Төсөл' />
                  <GroupItem
                    column='amount'
                    summaryType="sum"
                    alignByColumn={true}
                    customizeText={(data) => {
                      return `${numberWithCommas(data.value)}`
                    }}
                  />
                  <GroupItem
                    column='paymentAmount'
                    summaryType="sum"
                    alignByColumn={true}
                    customizeText={(data) => {
                      return `${numberWithCommas(data.value/1000000)}`
                    }}
                  />
                  <GroupItem
                    column="payAmt"
                    alignByColumn={true}
                    summaryType="sum"
                    customizeText={(data) => {
                      return `${numberWithCommas(data.value)}`
                    }}
                  />
                  <TotalItem
                    column='amount'
                    summaryType="sum"
                    customizeText={(data) => {
                      return `${numberWithCommas(data.value)}`
                    }}
                  />
                  <TotalItem
                    column='paymentAmount'
                    summaryType="sum"
                    customizeText={(data) => {
                      return `${numberWithCommas(data.value/1000000)}`
                    }}
                  />
                  <TotalItem
                    column="payAmt"
                    summaryType="sum"
                    customizeText={(data) => {
                      return `${numberWithCommas(data.value)}`
                    }}
                  />
                  <TotalItem column="Эзлэх %" displayFormat="100%" />
                  <TotalItem
                    column="pipNm"
                    summaryType="count"
                    displayFormat="Нийт {0} төслийн мэдээлэл харуулж байна" />
                </Summary>
              </DataGrid>
            </div>
            <div className="row d-flex responsive-flex" style={{ justifyContent: "flex-start", flexDirection: "row" }}>
              <div className="card col-6 responsive-col" style={{ flex: 1 }} >
                <div className="card-header">
                  <div style={{ flex: 1, display: "flex", justifyContent: "space-between", flexDirection: "row" }}>
                    <span style={{ fontWeight: "700" }}>ХӨРӨНГИЙН ЗАРДАЛ, ГҮЙЦЭТГЭЛ /сараар, сая ₮/</span>
                    <Button style={{ border: "0px solid transparent", borderTopRightRadius: "15px", }} icon={maximizeIcon} onClick={() => popUpHandler(true, 2)} />
                  </div>
                </div>
                <div style={{ margin: '16px 10px' }}>
                  <InvestExecutionByMonth budgetYear={budgetYear} />
                </div>
              </div>
              <div className="card col-6 responsive-col" style={{ flex: 1 }} >
                <div className="card-header">
                  <div style={{ flex: 1, display: "flex", justifyContent: "space-between", flexDirection: "row" }} >
                    <span style={{ fontWeight: "700" }}>ХӨРӨНГИЙН ЗАРДАЛ, ГҮЙЦЭТГЭЛ /жилээр, сая ₮/</span>
                    <Button style={{ border: "0px solid transparent", borderTopRightRadius: "15px" }} icon={maximizeIcon} onClick={() => popUpHandler(true, 1)} />
                  </div>
                </div>
                <div style={{ margin: '16px 10px' }}>
                  <InvestExecutionByYear />
                </div>
              </div>
            </div>
          </div>
          :
          <div style={{ padding: '10px 15px 10px 5px' }}>
            <Sanhuujilt budgetYear={budgetYear} investYear={investYear} />
          </div>}
      </div>
      {
        <Popup
          visible={rowPopup}
          dragEnabled={false}
          showCloseButton={true}
          hideOnOutsideClick={true}
          onHiding={() => {setRowPopup(false); setDetailData(null) }}
          onHidden={() => setDetailData(null)}
          shading={true}
          title='Төслийн ерөнхий мэдээлэл'
        >
          <div>
            <div style={{ color: '#15186A', fontSize: 16, fontWeight: 'bold', width: '40%', textAlign: 'center', margin: '0 auto' }}>{detailData?.pipNm}</div>
            <div style={{ display: 'grid', gridTemplateColumns: 'repeat(5, 1fr)', gap: 20, marginTop: 20 }}>
              {renderCard("Хуулийн дугаар", detailData?.lawNo)}
              {renderCard("Төслийн дугаар", detailData?.utCode)}
              {renderCard("ТЕЗ", detailData?.tezNm)}
              {renderCard("Ангилал", detailData?.secNm)}
              {renderCard("Дэд ангилал", detailData?.subSecNm)}
              {renderCard("Аймаг/Нийслэл", detailData?.amgNm)}
              {renderCard("Сум/Дүүрэг", detailData?.sumNm)}
              {renderCard("Эрх шилжүүлсэн байгууллага", detailData?.consumerNm)}
              {renderCard("Хянагч байгууллага", detailData?.supervisorNm)}
              {renderCard("Эдийн засгийн ангилал", detailData?.comCdNm)}
              {renderCard("Объект", detailData?.objNm)}
              {renderCard("Хүчин чадал", detailData?.capacity, detailData?.measNm)}
              {renderCard("Төлөв", detailData?.statusNm)}
              {renderCard("ХАА зарласан эсэх", detailData?.haaReleaseEseh)}
              {renderCard("ХАА зарласан хугацаа", detailData?.haaReleaseDate)}
              {renderCard("Зураг төсвийн мэдээлэл", detailData?.zuragTusuvInfo)}
              {renderCard("Зураг бэлэн болох хугацаа", detailData?.zuragReadyDate)}
              {renderCard("Гүйцэтгэгч байгууллага", detailData?.contractor)}
              {renderCard("Эхлэх огноо", detailData?.strDt)}
              {renderCard("Дуусах огноо", detailData?.endDt)}
              {renderCard("Нийт санхүүжих дүн", numberWithCommas(detailData?.amount), 'сая ₮')}
              {renderCard("Энэ оны төсөвт өртөг", numberWithCommas(detailData?.payAmt), 'сая ₮')}
              {renderCard("Энэ онд олгосон дүн", numberWithCommas(detailData?.paymentAmount/1000000), 'сая ₮')}
            </div>
          </div>
        </Popup>
      }
      <div className="card" style={{ padding: 10, display: "flex", justifyContent: "center", alignItems: 'center', gap: 5, fontWeight: 500 }}>
        <span>Эх сурвалж:</span>
        <a href="https://publicinvestment.gov.mn/" target="blank">PIMIS</a>
      </div>
    </div>
  )
}

export default NationalAsset
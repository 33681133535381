import React, { useContext, useEffect, useState, useRef, useCallback } from "react";
import DataGrid, {
    Column,
    Grouping,
    GroupPanel,
    Summary,
    TotalItem, Selection, HeaderFilter, ColumnChooser, GroupItem, Toolbar, Item, SearchPanel
} from "devextreme-react/data-grid";
import notify from "devextreme/ui/notify";
import ScheduleService from "../../../services/api/scheduleService";
import { Export } from "devextreme-react/chart";
import { CgArrowsExpandRight, CgCompressRight, CgMaximize, CgMinimize } from 'react-icons/cg'
import { Popup } from "devextreme-react/popup";
import SelectBox from "devextreme-react/select-box";
import { formatDate, numberWithCommas } from "../../../util";
import Button from "devextreme-react/button";
import OrganizationService from "../../../services/api/organization";
import { CURRENT_YEAR } from "../../../shared/constants";
import { UserContext } from "../../../hooks/UserContext";
import LoadPanelComponent from "../../../components/LoadPanelComponent";
import { Workbook } from 'exceljs';
import saveAs from 'file-saver';
import { exportDataGrid } from "devextreme/excel_exporter";
import strategy_icon from "../../../assets/imgs/structureIcon2_updated.png";
import { urlServer } from "../../../shared/lib/request";
import moment from "moment";
import _ from "lodash";
import DataSource from 'devextreme/data/data_source';
import "./ScheduleGridList.css";
import icon1 from '../../../assets/imgs/icon/iconInfo1.png'

const ScheduleGridList = (props) => {

    const [scheduleBudget, setScheduleBudget] = useState([]);
    const [defaultOrg, setDefaultOrg] = useState(null)
    const [organizationList, setOrganizationList] = useState([]);
    const [toOrg, setToOrg] = useState(null)
    const { user } = useContext(UserContext);
    const current_year = CURRENT_YEAR;
    const _dataRefGrid = useRef(null);
    const [loader, setLoader] = useState(false)
    const [selectBoxOrg, setSelectBoxOrg] = useState(null)
    const [expand, setExpand] = useState(false);
    const [lastPerfDate, setLastPerfDate] = useState(null);
    const [fileTushaalName, setFileTushaalName] = useState(null);
    const [popUpModalTushaal, setPopUpModalTushaal] = useState(false);
    const [currentMonth, setCurrentMonth] = useState(new moment().month() + 1);
    const [isVisible, setIsVisible] = useState(false);
    const [scMonth1, setScMonth1] = useState(0);
    const [scMonth2, setScMonth2] = useState(0);
    const [scMonth3, setScMonth3] = useState(0);
    const [scMonth4, setScMonth4] = useState(0);
    const [scMonth5, setScMonth5] = useState(0);
    const [scMonth6, setScMonth6] = useState(0);
    const [scMonth7, setScMonth7] = useState(0);
    const [scMonth8, setScMonth8] = useState(0);
    const [scMonth9, setScMonth9] = useState(0);
    const [scMonth10, setScMonth10] = useState(0);
    const [scMonth11, setScMonth11] = useState(0);
    const [scMonth12, setScMonth12] = useState(0);
    const [fullscreen, setFullscreen] = useState(false)

    useEffect(() => {
        loadFormData();
    }, [props.deps])

    const onOrganizationChange = async (type, org_id, visible) => {
        //console.log("user", user)
        let result = await getScheduleBudgetData(org_id, current_year)
        if (type === 1) {
            setLoader(true);
            if (visible) {
                let res = _.filter(result, item => {
                    return item.rec_month1 * (1) + item.rec_month2 * (1) + item.rec_month3 * (1) + item.rec_month4 * (1) + item.rec_month5 * (1) + item.rec_month6 * (1) + item.rec_month7 * (1) + item.rec_month8 * (1) + item.rec_month9 * (1) + item.rec_month10 * (1) + item.rec_month11 * (1) + item.rec_month12 * (1) + item.send_month1 * (1) + item.send_month2 * (1) + item.send_month3 * (1) + item.send_month4 * (1) + item.send_month5 * (1) + item.send_month6 * (1) + item.send_month7 * (1) + item.send_month8 * (1) + item.send_month9 * (1) + item.send_month10 * (1) + item.send_month11 * (1) + item.send_month12 * (1) !== 0
                });
                //console.log('visibleRes', res);
                let sMonth1 = _.sumBy(res, r => {
                    return r.rec_month1 * (1) + r.send_month1 * (1);
                });
                let sMonth2 = _.sumBy(res, r => {
                    return r.rec_month2 * (1) + r.send_month2 * (1);
                });
                let sMonth3 = _.sumBy(res, r => {
                    return r.rec_month3 * (1) + r.send_month3 * (1);
                });
                let sMonth4 = _.sumBy(res, r => {
                    return r.rec_month4 * (1) + r.send_month4 * (1);
                });
                let sMonth5 = _.sumBy(res, r => {
                    return r.rec_month5 * (1) + r.send_month5 * (1);
                });
                let sMonth6 = _.sumBy(res, r => {
                    return r.rec_month6 * (1) + r.send_month6 * (1);
                });
                let sMonth7 = _.sumBy(res, r => {
                    return r.rec_month7 * (1) + r.send_month7 * (1);
                });
                let sMonth8 = _.sumBy(res, r => {
                    return r.rec_month8 * (1) + r.send_month8 * (1);
                });
                let sMonth9 = _.sumBy(res, r => {
                    return r.rec_month9 * (1) + r.send_month9 * (1);
                });
                let sMonth10 = _.sumBy(res, r => {
                    return r.rec_month10 * (1) + r.send_month10 * (1);
                });
                let sMonth11 = _.sumBy(res, r => {
                    return r.rec_month11 * (1) + r.send_month11 * (1);
                });
                let sMonth12 = _.sumBy(res, r => {
                    return r.rec_month12 * (1) + r.send_month12 * (1);
                });
                setScheduleBudget(res);
                setScMonth1(sMonth1);
                setScMonth2(sMonth2);
                setScMonth3(sMonth3);
                setScMonth4(sMonth4);
                setScMonth5(sMonth5);
                setScMonth6(sMonth6);
                setScMonth7(sMonth7);
                setScMonth8(sMonth8);
                setScMonth9(sMonth9);
                setScMonth10(sMonth10);
                setScMonth11(sMonth11);
                setScMonth12(sMonth12);
            }
            else {
                setScheduleBudget(result)
            }

            setLoader(false);
            //console.log("scheduleBudget", scheduleBudget)
        }

    }

    const getScheduleBudgetData = async (org_id) => {
        return ScheduleService.getScheduleBudget(org_id, current_year);
    }

    const fromUngroupedData = new DataSource(
        user.organization.obt_id === 1 &&
        {
            store: {
                type: 'array',
                data: organizationList,
                key: 'id',
            },
            group: 'org_name_2',
        });

    const loadFormData = async () => {
        try {
            const req1 = OrganizationService.orgSettingsListForScheduleEdit(current_year)
            const req2 = onOrganizationChange(1, user.org_id)
            await Promise.all([
                req1, req2
            ])
                .then((res) => {
                    const resultOrg = res[0]
                    setOrganizationList(resultOrg.data);
                    //console.log("setOrganizationOnChangeTo",resultOrg.data)
                    //ТШЗ үед автоматаар сонгоно
                    if (user.organization.obt_id === 3) {
                        setOrganizationOnChangeTo({ value: resultOrg.data[0] })
                    }
                    const temp_defaultOrg = resultOrg.data.filter(element => {
                        return element.id === user.organization.id;
                    });
                    setLastPerfDate(temp_defaultOrg[0] ? temp_defaultOrg[0].last_performance_date : null);
                    setFileTushaalName(temp_defaultOrg[0] ? temp_defaultOrg[0].file_name : null);
                    setDefaultOrg(temp_defaultOrg[0])
                    //console.log("setDefaultOrg",temp_defaultOrg[0])
                    //setOrganizationListTo(resultOrg.data);
                })

        } catch (e) {
            return notify(e.message, "warning", 3000);
        }
    }

    const setOrganizationOnChangeTo = async (e) => {
        setToOrg(e.value)

    }
    const customizedName = (data) => {
        return (
            <div style={{ flexDirection: 'column', display: 'flex', justifyContent: 'space-between' }}>
                <div>{data.text} </div>
                <div style={{ color: '#3daa75' }}>&nbsp;</div>
            </div>
        )
    }
    const customizedBoldPerf = (data) => {
        return (
            <span style={{ fontWeight: '700', color: '#003696' }}>{data.text}</span>
        )
    }
    const styles = {
        inputStyle: {
            display: 'inline-block', verticalAlign: 'middle', float: 'left', width: '100%', marginLeft: "0em",
        },
        inputStyle2: {
            display: 'inline-block', verticalAlign: 'middle', float: 'left', width: '32%', marginLeft: "1em",
        },
        inputStyle3: {
            display: 'inline-block', verticalAlign: 'middle', float: 'left', width: '11%', marginLeft: "1em",
        }
    }

    const cellPrepared = (e) => {
        if (e.rowType === 'header') {
            e.cellElement.wordWrapEnabled = true
            e.cellElement.style.fontFamily = "Segoe UI"
            e.cellElement.style.fontWeight = "bold"
            e.cellElement.style.color = "#15186A"
            e.cellElement.style.backgroundColor = "#EEF0F4"
        }
        if (e.rowType === 'group') {
            e.cellElement.style.fontFamily = "Segoe UI"
            //e.cellElement.wordWrapEnabled = true;
            e.cellElement.style.color = "#003696"
            e.cellElement.style.backgroundColor = "#fff"
            e.cellElement.style.fontWeight = "bold";
        }
        if (e.rowType === "data" && (e.columnIndex === 2 || e.columnIndex === 3 || e.columnIndex === 1 || e.columnIndex === 0) && e.data.econ_category_code.length !== 6) {
            e.cellElement.style.fontFamily = "Segoe UI"
            e.cellElement.style.color = "#000000"
            e.cellElement.style.fontWeight = "bold";
        }
        else if (e.rowType === "data" && e.data.econ_category_code.length === 6) {
            e.cellElement.style.fontFamily = "Segoe UI"
            e.cellElement.style.fontWeight = "semibold"
            e.cellElement.style.color = "#000000"
            //e.cellElement.style.backgroundColor = "rgb(216,230,255)"
        }
    }
    const cellRenderSchedule = (e, month) => {
        let value = 0;
        let value1 = 0;
        //console.log('e.data', e);
        switch (month) {
            case 0:
                value = e.data.send_month1 * (-1) + e.data.send_month2 * (-1) + e.data.send_month3 * (-1) + e.data.send_month4 * (-1) + e.data.send_month5 * (-1) + e.data.send_month6 * (-1) + e.data.send_month7 * (-1) + e.data.send_month8 * (-1) + e.data.send_month9 * (-1) + e.data.send_month10 * (-1) + e.data.send_month11 * (-1) + e.data.send_month12 * (-1);
                value1 = e.data.rec_month1 * 1 + e.data.rec_month2 * 1 + e.data.rec_month3 * 1 + e.data.rec_month4 * 1 + e.data.rec_month5 * 1 + e.data.rec_month6 * 1 + e.data.rec_month7 * 1 + e.data.rec_month8 * 1 + e.data.rec_month9 * 1 + e.data.rec_month10 * 1 + e.data.rec_month11 * 1 + e.data.rec_month12 * 1;
                break;
            case 1:
                value = e.data.send_month1 * (-1);
                value1 = e.data.rec_month1;
                break;
            case 2:
                value = e.data.send_month2 * (-1);
                value1 = e.data.rec_month2;
                break;
            case 3:
                value = e.data.send_month3 !== null ? e.data.send_month3 * (-1) : 0;
                value1 = e.data.rec_month3;
                break;
            case 4:
                value = e.data.send_month4 * (-1);
                value1 = e.data.rec_month4;
                break;
            case 5:
                value = e.data.send_month5 * (-1);
                value1 = e.data.rec_month5;
                break;
            case 6:
                value = e.data.send_month6 * (-1);
                value1 = e.data.rec_month6;
                break;
            case 7:
                value = e.data.send_month7 * (-1);
                value1 = e.data.rec_month7;
                break;
            case 8:
                value = e.data.send_month8 * (-1);
                value1 = e.data.rec_month8;
                break;
            case 9:
                value = e.data.send_month9 * (-1);
                value1 = e.data.rec_month9;
                break;
            case 10:
                value = e.data.send_month10 * (-1);
                value1 = e.data.rec_month10;
                break;
            case 11:
                value = e.data.send_month11 !== null ? e.data.send_month11 * (-1) : 0;
                value1 = e.data.rec_month11;
                break;
            case 12:
                value = e.data.send_month12 * (-1);
                value1 = e.data.rec_month12;
                break;
            default:
                value = 0;
                value1 = 0;
                break;
        }
        let diff = 0;
        if (value || value1) {
            diff = value * 1 + value1 * 1;
        }
        if (diff > 0) {
            return (<div class="inc customCell" style={{ flexDirection: 'column', display: 'flex', justifyContent: 'space-between' }}>
                <div class="current-value" style={{ fontWeight: month === 0 ? '700' : 'normal', color: '#000000' }}>{e.text}</div>
                <div style={{ color: '#3daa75' }}>+{numberWithCommas(diff)}</div>
            </div>)
        } else if (diff < 0) {
            return <div class="dec customCell" style={{ flexDirection: 'column', display: 'flex', justifyContent: 'space-between' }}>
                <div class="current-value" style={{ fontWeight: month === 0 ? '700' : 'normal', color: '#000000' }}>{e.text}</div>
                <div style={{ color: '#f66160' }}>{numberWithCommas(diff)}</div>
            </div>
        } else {
            return <span style={{ fontWeight: month === 0 ? '700' : 'normal', color: '#000000' }}>{e.text}</span>
        }
    }
    const cellRenderPerf = (data) => {
        return (
            <span style={{ color: '#003696' }}>{data.text}</span>
        )
    }
    const calcCellVal = (e, month) => {
        let value = 0;
        let value1 = 0;
        let value2 = 0;
        let value3 = 0;
        //console.log('e.data', e.data);
        switch (month) {
            case 0:
                value = e.data.total_year_amt === null ? 0 : e.data.total_year_amt;
                value1 = e.data.per_total_year_amt === null ? 0 : e.data.per_total_year_amt;
                value2 = e.data.send_month1 * (-1) + e.data.send_month2 * (-1) + e.data.send_month3 * (-1) + e.data.send_month4 * (-1) + e.data.send_month5 * (-1) + e.data.send_month6 * (-1) + e.data.send_month7 * (-1) + e.data.send_month8 * (-1) + e.data.send_month9 * (-1) + e.data.send_month10 * (-1) + e.data.send_month11 * (-1) + e.data.send_month12 * (-1);
                value3 = e.data.rec_month1 * 1 + e.data.rec_month2 * 1 + e.data.rec_month3 * 1 + e.data.rec_month4 * 1 + e.data.rec_month5 * 1 + e.data.rec_month6 * 1 + e.data.rec_month7 * 1 + e.data.rec_month8 * 1 + e.data.rec_month9 * 1 + e.data.rec_month10 * 1 + e.data.rec_month11 * 1 + e.data.rec_month12 * 1;

                break;
            case 1:
                value = e.data.month1 === null ? 0 : e.data.month1;
                value1 = e.data.per_month1 === null ? 0 : e.data.per_month1;
                value2 = e.data.send_month1 * (-1);
                value3 = e.data.rec_month1;
                break;
            case 2:
                value = e.data.month2;
                value1 = e.data.per_month2;
                value2 = e.data.send_month2 * (-1);
                value3 = e.data.rec_month2;
                break;
            case 3:
                value = e.data.month3;
                value1 = e.data.per_month3;
                value2 = e.data.send_month3 * (-1);
                value3 = e.data.rec_month3;
                break;
            case 4:
                value = e.data.month4;
                value1 = e.data.per_month4;
                value2 = e.data.send_month4 * (-1);
                value3 = e.data.rec_month4;
                break;
            case 5:
                value = e.data.month5;
                value1 = e.data.per_month5;
                value2 = e.data.send_month5 * (-1);
                value3 = e.data.rec_month5;
                break;
            case 6:
                value = e.data.month6;
                value1 = e.data.per_month6;
                value2 = e.data.send_month6 * (-1);
                value3 = e.data.rec_month6;
                break;
            case 7:
                value = e.data.month7;
                value1 = e.data.per_month7;
                value2 = e.data.send_month7 * (-1);
                value3 = e.data.rec_month7;
                break;
            case 8:
                value = e.data.month8;
                value1 = e.data.per_month8;
                value2 = e.data.send_month8 * (-1);
                value3 = e.data.rec_month8;
                break;
            case 9:
                value = e.data.month9;
                value1 = e.data.per_month9;
                value2 = e.data.send_month9 * (-1);
                value3 = e.data.rec_month9;
                break;
            case 10:
                value = e.data.month10;
                value1 = e.data.per_month10;
                value2 = e.data.send_month10 * (-1);
                value3 = e.data.rec_month10;
                break;
            case 11:
                value = e.data.month11;
                value1 = e.data.per_month11;
                value2 = e.data.send_month11 * (-1);
                value3 = e.data.rec_month11;
                break;
            case 12:
                value = e.data.month12;
                value1 = e.data.per_month12;
                value2 = e.data.send_month12 * (-1);
                value3 = e.data.rec_month12;
                break;
            default:
                value = 0;
                value1 = 0;
                break;
        }
        let percent = 0;
        let diff = 0;
        if (value) {
            percent = value1 !== 0 ? (value1 - value + value2 * 1 + value3 * 1) * 100 / value : 0;
            diff = value - value1 + value2 * 1 + value3 * 1;
        }

        if (percent > 0) {
            return (<div class="inc customCell" style={{ flexDirection: 'column', display: 'flex', justifyContent: 'space-between' }}>
                <div class="current-value">{numberWithCommas(diff)}</div>
                <div class="diff" style={{ color: '#f66160' }}>{numberWithCommas(percent)}%</div>
            </div>)
        } else if (percent) {
            return <div class="dec customCell" style={{ flexDirection: 'column', display: 'flex', justifyContent: 'space-between' }}>
                <div class="current-value">{numberWithCommas(diff)}</div>
                <div class="diff" style={{ color: '#3daa75' }}>{numberWithCommas(percent)}%</div>
            </div>
        } else {
            return <div class="customCell">
                <div class="current-value">{diff && numberWithCommas(diff)} </div>
            </div>
        }
    }
    const onExporting = (e) => {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Main sheet');
        exportDataGrid({
            component: e.component,
            worksheet,
            autoFilterEnabled: true,
            keepColumnWidths: true,
            topLeftCell: { row: 4, column: 2 },
            customizeCell: ({ gridCell, excelCell }) => {
                if (gridCell.rowType === 'header') {
                    excelCell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '7082F6' } };
                    excelCell.border = {
                        top: { style: 'thin', color: { argb: '2c2c2c' } },
                        left: { style: 'thin', color: { argb: '2c2c2c' } },
                        bottom: { style: 'thin', color: { argb: '2c2c2c' } },
                        right: { style: 'thin', color: { argb: '2c2c2c' } }
                    }
                }
                if (gridCell.rowType === 'group') {
                    excelCell.alignment = { wrapText: false };
                    excelCell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '63C1FE' } };
                }
                if (gridCell.rowType === 'totalFooter' && excelCell.value) {
                    excelCell.font.italic = true;
                }
                /*if (gridCell.column.caption === 'Зөрүү') {
                    console.log('excelCell', excelCell);
                 //   excelCell.value = { text: gridCell.value};
                }*/
                if (gridCell.rowType === 'data') {
                    excelCell.border = {
                        top: { style: 'thin', color: { argb: '2c2c2c' } },
                        left: { style: 'thin', color: { argb: '2c2c2c' } },
                        bottom: { style: 'thin', color: { argb: '2c2c2c' } },
                        right: { style: 'thin', color: { argb: '2c2c2c' } }
                    }
                }
            },
        }).then(() => {
            Object.assign(
                worksheet.getRow(2).getCell(8),

                { value: selectBoxOrg !== null ? selectBoxOrg.organization_name : user.organization.name + "-ын хуваарийн тайлан", font: { bold: true, size: 16, underline: 'double' } }
            );
        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer], { type: 'application/octet-stream' }), selectBoxOrg !== null ? "OG-" + selectBoxOrg.organization_name + "-" + formatDate(new Date()) + ".xlsx" : "OG-" + user.organization.name + "-" + formatDate(new Date()) + ".xlsx");
            });
        });
        e.cancel = true;
    }
    const animation = {
        show: {
            type: "slide",
            from: {
                left: 2000,
                opacity: 0.2
            },
            to: {
                opacity: 1
            },
            duration: 800
        }
        ,
        hide: {
            type: "slide",
            to: {
                opacity: 0,
                right: -1000
            },
            ///////      duration: 800
        }
    }

    const hideTushaalModal = () => {
        setPopUpModalTushaal(false);
    }

    const openModalTushaal = async () => {
        if (fileTushaalName !== null) {
            setPopUpModalTushaal(true);
        } else {
            notify({
                message: 'Тушаал шийдвэртэй холбоотой файл оруулаагүй байна!', width: 300, position: {
                    my: 'right top',
                    at: 'right top',
                    of: "#floating-div-2"
                },
                animation: animation,
            }, "warning", 2500);
            setPopUpModalTushaal(false)
        }
    }

    const requestFullscreen = () => {
        if (document.fullscreenElement) document.exitFullscreen();
        else document.getElementById("wrapper").requestFullscreen();
        // setFullscreen(prev=>!prev)
    }
    const sumRenderText = (data) => {
        return (
            data.value !== 0 ? (data.value > 0 ? "+" + numberWithCommas(data.value) : numberWithCommas(data.value)) : ""
        )
    }
    const onValueChanged = async (args) => {
        setIsVisible(!isVisible);
        //console.log('selectBoxOrg', selectBoxOrg);
        await onOrganizationChange(1, (selectBoxOrg && selectBoxOrg.id !== undefined) ? selectBoxOrg.id : user.org_id, !isVisible)
    }

    const ExpandCollapseButton = useCallback(() => {
        return (<Button
            onClick={(e) => setExpand(prev => !prev)}
            render={() => {
                return expand ? (
                    <CgCompressRight size={"1rem"} color="#1a1976" />)
                    :
                    (
                        <CgArrowsExpandRight size={"1rem"} color="#1a1976" />
                    )
            }}
            hint={expand ? 'Багасгах' : 'ЭЗА дэлгэрэнгүй харуулах'}
        >
        </Button>)
    }, [expand])

    const FullscreenButton = useCallback(() => {
        return (
            <Button
                onClick={() => {
                    requestFullscreen()
                    setFullscreen(prev => !prev)
                }}
                style={{ marginLeft: "0.5em" }}
                render={() => {
                    return fullscreen ?
                        (<CgMinimize size={"1rem"} color="#1a1976" />)
                        :
                        (<CgMaximize size={"1rem"} color="#1a1976" />)
                }}
                hint={fullscreen ? 'Багасгах' : 'Дэлгэцэнд бүтэн харуулах'}
            >
            </Button>
        )
    }, [fullscreen])

    return (
        <>
            <LoadPanelComponent position={{ of: '#loadSchedule' }} visible={loader} />
            {/*<ScrollView id="hereYouLoad">*/}
            <div id="wrapper" height="100%" width="100%" style={{ backgroundColor: "#fff" }}>
                {fileTushaalName !== null &&
                    <Popup
                        width={'75rem'}
                        maxWidth={'100%'}
                        minHeight={'100%'}
                        height={'100%'}
                        visible={popUpModalTushaal}
                        onHiding={hideTushaalModal}
                        title="Батлагдсан хуваарийн тушаал,шийдвэр"
                        dragEnabled={false}
                    //hideOnOutsideClick={true}
                    >
                        <object width="100%" height="100%"
                            data={urlServer + '/api/file/documents/' + fileTushaalName}
                            type="application/pdf" />
                    </Popup>}
                <div style={{ padding: 10, position: 'relative' }}>
                    <div className="col-sm-12">
                        <div className="row" style={{ display: 'flex', gap: '1rem', position: 'absolute', top: 5, left: 10, zIndex: 9 }}>
                                <div>
                                    <SelectBox dataSource={user.organization.obt_id === 1 ? fromUngroupedData : organizationList} searchEnabled={true}
                                        style={styles.inputStyle}
                                        grouped={user.organization.obt_id === 1 ? true : false}
                                        width={"500"}
                                        defaultValue={defaultOrg}
                                        onValueChanged={(e) => {
                                            setSelectBoxOrg(e.value);
                                            setIsVisible(false);
                                            setLastPerfDate(e ? e.value.last_performance_date : null);
                                            setFileTushaalName(e ? e.value.file_name : null);
                                            onOrganizationChange(1, e.value.id);
                                            notify(`Та "${e.value.organization_name}" байгууллага сонгосон байна.`);
                                        }}
                                        placeholder="Байгууллага сонгох"
                                        label="Байгууллага:"
                                        disabled={user.organization.obt_id === 3 ? true : false}
                                        displayExpr={(item) => {
                                            return item && `${item.organization_name}`
                                        }} />
                                </div>
                                <div style={{marginTop: 15}}>
                                    <input type="radio" onChange={onValueChanged} value={isVisible} />
                                    <label style={{marginLeft: 5}} for={isVisible}>{isVisible ? 'Бүх баганыг харуулах' : 'Зөвхөн өөрчлөгдсөн баганыг харуулах'}</label>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                scheduleBudget && scheduleBudget.length > 0 &&
                <div style={{ paddingLeft: 10}}>
                    <DataGrid dataSource={scheduleBudget} ref={_dataRefGrid} hoverStateEnabled={true}
                        allowColumnResizing={true}
                        keyExpr={"row_number"}
                        showBorders={true}
                        wordWrapEnabled={true}
                        showRowLines={true}
                        showColumnLines={true}
                        showColumnHeaders={true}
                        height={'700'}
                        paging={false}
                        columnAutoWidth={true}
                        onCellPrepared={cellPrepared} onExporting={onExporting}>
                        <GroupPanel visible={true} />
                        <Toolbar>
                            <Item name="searchPanel" />
                            <Item>
                                <div>
                                    <ExpandCollapseButton />
                                    <FullscreenButton />
                                </div>
                            </Item>
                            <Item name="exportButton"
                                render={(e) => {
                                    return <Button
                                        onClick={e.options.onClick}
                                        icon="xlsxfile"
                                        style={{ color: "#00ff00" }}>
                                    </Button>
                                }}
                            />
                            <Item>
                                <Button
                                    onClick={() => openModalTushaal()}
                                    id="approvedSchedule"
                                    render={() => <img loading="lazy" src={strategy_icon} alt="" style={{
                                        width: '1.2rem',
                                        height: '1.2rem',
                                        opacity: fileTushaalName !== null ? '1' : '0.3'
                                    }} />} 
                                    hint="Батлагдсан хуваарийн тушаал,шийдвэр харах"
                                    />
                            </Item>
                            <Item name="columnChooserButton" widget="dxButton" options={{ icon: "columnchooser" }} />
                            <Item>
                                <Button
                                    render={() => <img loading="lazy" src={icon1} alt="" style={{
                                        width: '1.2rem',
                                        height: '1.2rem',
                                        transform: 'scale(1.5)'
                                    }} />} 
                                    hint={`Гүйцэтгэл шинэчлэгдсэн огноо: ${lastPerfDate}`}
                                    />
                            </Item>
                        </Toolbar>
                        <Export enabled={true} />
                        <SearchPanel visible={true} placeholder={'Хайх'} width={"300"} />
                        <Grouping autoExpandAll={expand} contextMenuEnabled={false}
                            expandMode="rowClick" />
                        <Selection mode="single" />
                        <HeaderFilter visible={true} allowSearch={true} />
                        <ColumnChooser
                            enabled={true} height={"100%"} allowSearch={true}
                            mode="select"
                            title={'Багана сонгох'}
                        />
                        <Column dataField="agenda_data" caption="Хөтөлбөр" groupIndex={0} />
                        <Column dataField="event_data" caption="Зориулалт" groupIndex={1} />
                        <Column dataField="econ_category_code" caption="ЭЗА код" dataType="number"
                            alignment={'left'} width={'3.75rem'} fixed={true} cellRender={customizedName} sortIndex={0} />
                        <Column dataField="econ_category_name" caption="Эдийн засгийн ангилал" dataType="text"
                            width={'19rem'} fixed={true} />
                        <Column caption="Нийт дүн" alignment={'center'}>
                            <Column dataField="total_year_amt" format="#,##0.00" alignment="right"
                                dataType="number" caption="Хуваарь" width={'7.5rem'} cellRender={(e) => cellRenderSchedule(e, 0)} allowFiltering={false} />
                            <Column dataField="per_total_year_amt" format="#,##0.00" alignment="right"
                                dataType="number" caption="Гүйцэтгэл" width={'7.5rem'} cellRender={customizedBoldPerf} allowFiltering={false} />
                            <Column caption="Зөрүү" format="#,##0.00" dataType="number" minWidth={'6.25rem'} width={'6.25rem'} cellRender={(e) => calcCellVal(e, 0)} alignment="right" allowFiltering={false} />
                        </Column>
                        <Column caption="1-p cap" alignment={'center'} visible={isVisible && scMonth1 === 0 ? false : true}>
                            <Column dataField="month1" format="#,##0.00" alignment="right" dataType="number"
                                caption="Хуваарь" cellRender={(e) => cellRenderSchedule(e, 1)} allowFiltering={false} />
                            <Column dataField="per_month1" format="#,##0.00" alignment="right" dataType="number"
                                caption="Гүйцэтгэл" cellRender={cellRenderPerf} visible={currentMonth < 1 ? false : true} allowFiltering={false} />
                            <Column caption="Зөрүү" format="#,##0.00" dataType="number" minWidth={'6.25rem'} width={'6.25rem'} visible={currentMonth < 1 ? false : true} cellRender={(e) => calcCellVal(e, 1)} alignment="right" allowFiltering={false} />
                        </Column>
                        <Column caption="2-p cap" alignment={'center'} visible={isVisible && scMonth2 === 0 ? false : true}>
                            <Column dataField="month2" format="#,##0.00" alignment="right" dataType="number"
                                caption="Хуваарь" cellRender={(e) => cellRenderSchedule(e, 2)} allowFiltering={false} />
                            <Column dataField="per_month2" format="#,##0.00" alignment="right" dataType="number"
                                caption="Гүйцэтгэл" cellRender={cellRenderPerf} visible={currentMonth < 2 ? false : true} allowFiltering={false} />
                            <Column caption="Зөрүү" format="#,##0.00" dataType="number" minWidth={'6.25rem'} width={'6.25rem'} visible={currentMonth < 2 ? false : true} cellRender={(e) => calcCellVal(e, 2)} alignment="right" allowFiltering={false} />
                        </Column>
                        <Column caption="3-p cap" alignment={'center'} visible={isVisible && scMonth3 === 0 ? false : true}>
                            <Column dataField="month3" format="#,##0.00" alignment="right" dataType="number"
                                caption="Хуваарь" cellRender={(e) => cellRenderSchedule(e, 3)} allowFiltering={false} />
                            <Column dataField="per_month3" format="#,##0.00" alignment="right" dataType="number"
                                caption="Гүйцэтгэл" cellRender={cellRenderPerf} visible={currentMonth < 3 ? false : true} allowFiltering={false} />
                            <Column caption="Зөрүү" format="#,##0.00" dataType="number" minWidth={100} width={100} visible={currentMonth < 3 ? false : true} cellRender={(e) => calcCellVal(e, 3)} alignment="right" allowFiltering={false} />
                        </Column>
                        <Column caption="4-p cap" alignment={'center'} visible={isVisible && scMonth4 === 0 ? false : true}>
                            <Column dataField="month4" format="#,##0.00" alignment="right" dataType="number"
                                caption="Хуваарь" cellRender={(e) => cellRenderSchedule(e, 4)} allowFiltering={false} />
                            <Column dataField="per_month4" format="#,##0.00" alignment="right" dataType="number"
                                caption="Гүйцэтгэл" cellRender={cellRenderPerf} visible={currentMonth < 4 ? false : true} allowFiltering={false} />
                            <Column caption="Зөрүү" format="#,##0.00" dataType="number" width={100} visible={currentMonth < 4 ? false : true} cellRender={(e) => calcCellVal(e, 4)} alignment="right" allowFiltering={false} />
                        </Column>
                        <Column caption="5-p cap" alignment={'center'} visible={isVisible && scMonth5 === 0 ? false : true}>
                            <Column dataField="month5" format="#,##0.00" alignment="right" dataType="number"
                                caption="Хуваарь" cellRender={(e) => cellRenderSchedule(e, 5)} allowFiltering={false} />
                            <Column dataField="per_month5" format="#,##0.00" alignment="right" dataType="number"
                                caption="Гүйцэтгэл" cellRender={cellRenderPerf} visible={currentMonth < 5 ? false : true} allowFiltering={false} />
                            <Column caption="Зөрүү" format="#,##0.00" dataType="number" width={100} visible={currentMonth < 5 ? false : true} cellRender={(e) => calcCellVal(e, 5)} alignment="right" allowFiltering={false} />
                        </Column>
                        <Column caption="6-p cap" alignment={'center'} visible={isVisible && scMonth6 === 0 ? false : true}>
                            <Column dataField="month6" format="#,##0.00" alignment="right" dataType="number"
                                caption="Хуваарь" cellRender={(e) => cellRenderSchedule(e, 6)} allowFiltering={false} />
                            <Column dataField="per_month6" format="#,##0.00" alignment="right" dataType="number"
                                caption="Гүйцэтгэл" cellRender={cellRenderPerf} visible={currentMonth < 6 ? false : true} allowFiltering={false} />
                            <Column caption="Зөрүү" format="#,##0.00" dataType="number" minWidth={100} width={100} visible={currentMonth < 6 ? false : true} cellRender={(e) => calcCellVal(e, 6)} alignment="right" allowFiltering={false} />
                        </Column>
                        <Column caption="7-p cap" alignment={'center'} visible={isVisible && scMonth7 === 0 ? false : true}>
                            <Column dataField="month7" format="#,##0.00" alignment="right" dataType="number"
                                caption="Хуваарь" cellRender={(e) => cellRenderSchedule(e, 7)} allowFiltering={false} />
                            <Column dataField="per_month7" format="#,##0.00" alignment="right" dataType="number"
                                caption="Гүйцэтгэл" cellRender={cellRenderPerf} visible={currentMonth < 7 ? false : true} allowFiltering={false} />
                            <Column caption="Зөрүү" format="#,##0.00" dataType="number" minWidth={100} width={100} visible={currentMonth < 7 ? false : true} cellRender={(e) => calcCellVal(e, 7)} alignment="right" allowFiltering={false} />
                        </Column>
                        <Column caption="8-p cap" alignment={'center'} visible={isVisible && scMonth8 === 0 ? false : true}>
                            <Column dataField="month8" format="#,##0.00" alignment="right" dataType="number"
                                caption="Хуваарь" cellRender={(e) => cellRenderSchedule(e, 8)} allowFiltering={false} />
                            <Column dataField="per_month8" format="#,##0.00" alignment="right" dataType="number"
                                caption="Гүйцэтгэл" cellRender={cellRenderPerf} visible={currentMonth < 8 ? false : true} allowFiltering={false} />
                            <Column caption="Зөрүү" format="#,##0.00" dataType="number" minWidth={100} width={100} visible={currentMonth < 8 ? false : true} cellRender={(e) => calcCellVal(e, 8)} alignment="right" />
                        </Column>
                        <Column caption="9-p cap" alignment={'center'} visible={isVisible && scMonth9 === 0 ? false : true}>
                            <Column dataField="month9" format="#,##0.00" alignment="right" dataType="number"
                                caption="Хуваарь" cellRender={(e) => cellRenderSchedule(e, 9)} />
                            <Column dataField="per_month9" format="#,##0.00" alignment="right" dataType="number"
                                caption="Гүйцэтгэл" cellRender={cellRenderPerf} visible={currentMonth < 9 ? false : true} />
                            <Column caption="Зөрүү" format="#,##0.00" dataType="number" minWidth={100} width={100} visible={currentMonth < 9 ? false : true} cellRender={(e) => calcCellVal(e, 9)} alignment="right" allowFiltering={false} />
                        </Column>
                        <Column caption="10-p cap" alignment={'center'} visible={isVisible && scMonth10 === 0 ? false : true}>
                            <Column dataField="month10" format="#,##0.00" alignment="right" dataType="number"
                                caption="Хуваарь" cellRender={(e) => cellRenderSchedule(e, 10)} allowFiltering={false} />
                            <Column dataField="per_month10" format="#,##0.00" alignment="right" dataType="number"
                                caption="Гүйцэтгэл" cellRender={cellRenderPerf} visible={currentMonth < 10 ? false : true} allowFiltering={false} />
                            <Column caption="Зөрүү" format="#,##0.00" dataType="number" minWidth={100} width={100} visible={currentMonth < 10 ? false : true} cellRender={(e) => calcCellVal(e, 10)} alignment="right" allowFiltering={false} />
                        </Column>
                        <Column caption="11-p cap" alignment={'center'} visible={isVisible && scMonth11 === 0 ? false : true}>
                            <Column dataField="month11" format="#,##0.00" alignment="right" dataType="number"
                                caption="Хуваарь" cellRender={(e) => cellRenderSchedule(e, 11)} allowFiltering={false} />
                            <Column dataField="per_month11" format="#,##0.00" alignment="right" dataType="number"
                                caption="Гүйцэтгэл" cellRender={cellRenderPerf} visible={currentMonth < 11 ? false : true} allowFiltering={false} />
                            <Column caption="Зөрүү" format="#,##0.00" dataType="number" minWidth={100} width={100} visible={currentMonth < 11 ? false : true} cellRender={(e) => calcCellVal(e, 11)} alignment="right" allowFiltering={false} />
                        </Column>
                        <Column caption="12-p cap" alignment={'center'} visible={isVisible && scMonth12 === 0 ? false : true}>
                            <Column dataField="month12" format="#,##0.00" alignment="right" dataType="number"
                                caption="Хуваарь" cellRender={(e) => cellRenderSchedule(e, 12)} allowFiltering={false} />
                            <Column dataField="per_month12" format="#,##0.00" alignment="right" dataType="number"
                                caption="Гүйцэтгэл" cellRender={cellRenderPerf} visible={currentMonth < 12 ? false : true} allowFiltering={false} />
                            <Column caption="Зөрүү" format="#,##0.00" dataType="number" minWidth={100} width={100} visible={currentMonth < 12 ? false : true} cellRender={(e) => calcCellVal(e, 12)} alignment="right" allowFiltering={false} />
                        </Column>
                        {/*<Scrolling columnRenderingMode="virtual" />*/}
                        <Export enabled={true} allowExportSelectedData={false} />
                        {!isVisible && <Summary>
                            <GroupItem column="total_year_amt_econ" summaryType="sum" showInGroupFooter={false} alignByColumn={true} showInColumn="total_year_amt" customizeText={(data) => { return `${numberWithCommas(data.value)}` }} />
                            <GroupItem column="total_year_diff_econ" summaryType="sum" showInGroupFooter={false} alignByColumn={true} showInColumn="total_year_amt" customizeText={sumRenderText} />
                            <GroupItem column="per_total_year_amt_econ" summaryType="sum" showInGroupFooter={false} alignByColumn={true} showInColumn="per_total_year_amt" customizeText={(data) => { return `${numberWithCommas(data.value)}` }} />
                            <GroupItem column="diff_year_amt_econ" summaryType="sum" showInGroupFooter={false} alignByColumn={true} showInColumn={7} customizeText={(data) => { return `${numberWithCommas(data.value)}` }} />
                            <GroupItem column="month1_econ" summaryType="sum" showInGroupFooter={false} alignByColumn={true} showInColumn="month1" customizeText={(data) => { return `${numberWithCommas(data.value)}` }} />
                            <GroupItem column="diff_month1_econ" summaryType="sum" showInGroupFooter={false} alignByColumn={true} showInColumn="month1" customizeText={sumRenderText} />

                            <GroupItem column="month2_econ" summaryType="sum" showInGroupFooter={false} alignByColumn={true} showInColumn="month2" customizeText={(data) => { return `${numberWithCommas(data.value)}` }} />
                            <GroupItem column="diff_month2_econ" summaryType="sum" showInGroupFooter={false} alignByColumn={true} showInColumn="month2" customizeText={sumRenderText} />

                            <GroupItem column="month3_econ" summaryType="sum" showInGroupFooter={false} alignByColumn={true} showInColumn="month3" customizeText={(data) => { return `${numberWithCommas(data.value)}` }} />
                            <GroupItem column="diff_month3_econ" summaryType="sum" showInGroupFooter={false} alignByColumn={true} showInColumn="month3" customizeText={sumRenderText} />

                            <GroupItem column="month4_econ" summaryType="sum" showInGroupFooter={false} alignByColumn={true} showInColumn="month4" customizeText={(data) => { return `${numberWithCommas(data.value)}` }} />
                            <GroupItem column="diff_month4_econ" summaryType="sum" showInGroupFooter={false} alignByColumn={true} showInColumn="month4" customizeText={sumRenderText} />

                            <GroupItem column="month5_econ" summaryType="sum" showInGroupFooter={false} alignByColumn={true} showInColumn="month5" customizeText={(data) => { return `${numberWithCommas(data.value)}` }} />
                            <GroupItem column="diff_month5_econ" summaryType="sum" showInGroupFooter={false} alignByColumn={true} showInColumn="month5" customizeText={sumRenderText} />

                            <GroupItem column="month6_econ" summaryType="sum" showInGroupFooter={false} alignByColumn={true} showInColumn="month6" customizeText={(data) => { return `${numberWithCommas(data.value)}` }} />
                            <GroupItem column="diff_month6_econ" summaryType="sum" showInGroupFooter={false} alignByColumn={true} showInColumn="month6" customizeText={sumRenderText} />

                            <GroupItem column="month7_econ" summaryType="sum" showInGroupFooter={false} alignByColumn={true} showInColumn="month7" customizeText={(data) => { return `${numberWithCommas(data.value)}` }} />
                            <GroupItem column="diff_month7_econ" summaryType="sum" showInGroupFooter={false} alignByColumn={true} showInColumn="month7" customizeText={sumRenderText} />

                            <GroupItem column="month8_econ" summaryType="sum" showInGroupFooter={false} alignByColumn={true} showInColumn="month8" customizeText={(data) => { return `${numberWithCommas(data.value)}` }} />
                            <GroupItem column="diff_month8_econ" summaryType="sum" showInGroupFooter={false} alignByColumn={true} showInColumn="month8" customizeText={sumRenderText} />

                            <GroupItem column="month9_econ" summaryType="sum" showInGroupFooter={false} alignByColumn={true} showInColumn="month9" customizeText={(data) => { return `${numberWithCommas(data.value)}` }} />
                            <GroupItem column="diff_month9_econ" summaryType="sum" showInGroupFooter={false} alignByColumn={true} showInColumn="month9" customizeText={sumRenderText} />

                            <GroupItem column="month10_econ" summaryType="sum" showInGroupFooter={false} alignByColumn={true} showInColumn="month10" customizeText={(data) => { return `${numberWithCommas(data.value)}` }} />
                            <GroupItem column="diff_month10_econ" summaryType="sum" showInGroupFooter={false} alignByColumn={true} showInColumn="month10" customizeText={sumRenderText} />

                            <GroupItem column="month11_econ" summaryType="sum" showInGroupFooter={false} alignByColumn={true} showInColumn="month11" customizeText={(data) => { return `${numberWithCommas(data.value)}` }} />
                            <GroupItem column="diff_month11_econ" summaryType="sum" showInGroupFooter={false} alignByColumn={true} showInColumn="month11" customizeText={sumRenderText} />

                            <GroupItem column="month12_econ" summaryType="sum" showInGroupFooter={false} alignByColumn={true} showInColumn="month12" customizeText={(data) => { return `${numberWithCommas(data.value)}` }} />
                            <GroupItem column="diff_month12_econ" summaryType="sum" showInGroupFooter={false} alignByColumn={true} showInColumn="month12" customizeText={sumRenderText} />

                            <GroupItem column="per_month1_econ" summaryType="sum" showInGroupFooter={false} alignByColumn={true} showInColumn="per_month1" customizeText={(data) => { return `${numberWithCommas(data.value)}` }} />
                            <GroupItem column="per_month2_econ" summaryType="sum" showInGroupFooter={false} alignByColumn={true} showInColumn="per_month2" customizeText={(data) => { return `${numberWithCommas(data.value)}` }} />
                            <GroupItem column="per_month3_econ" summaryType="sum" showInGroupFooter={false} alignByColumn={true} showInColumn="per_month3" customizeText={(data) => { return `${numberWithCommas(data.value)}` }} />
                            <GroupItem column="per_month4_econ" summaryType="sum" showInGroupFooter={false} alignByColumn={true} showInColumn="per_month4" customizeText={(data) => { return `${numberWithCommas(data.value)}` }} />
                            <GroupItem column="per_month5_econ" summaryType="sum" showInGroupFooter={false} alignByColumn={true} showInColumn="per_month5" customizeText={(data) => { return `${numberWithCommas(data.value)}` }} />
                            <GroupItem column="per_month6_econ" summaryType="sum" showInGroupFooter={false} alignByColumn={true} showInColumn="per_month6" customizeText={(data) => { return `${numberWithCommas(data.value)}` }} />
                            <GroupItem column="per_month7_econ" summaryType="sum" showInGroupFooter={false} alignByColumn={true} showInColumn="per_month7" customizeText={(data) => { return `${numberWithCommas(data.value)}` }} />
                            <GroupItem column="per_month8_econ" summaryType="sum" showInGroupFooter={false} alignByColumn={true} showInColumn="per_month8" customizeText={(data) => { return `${numberWithCommas(data.value)}` }} />
                            <GroupItem column="per_month9_econ" summaryType="sum" showInGroupFooter={false} alignByColumn={true} showInColumn="per_month9" customizeText={(data) => { return `${numberWithCommas(data.value)}` }} />
                            <GroupItem column="per_month10_econ" summaryType="sum" showInGroupFooter={false} alignByColumn={true} showInColumn="per_month10" customizeText={(data) => { return `${numberWithCommas(data.value)}` }} />
                            <GroupItem column="per_month11_econ" summaryType="sum" showInGroupFooter={false} alignByColumn={true} showInColumn="per_month11" customizeText={(data) => { return `${numberWithCommas(data.value)}` }} />
                            <GroupItem column="per_month12_econ" summaryType="sum" showInGroupFooter={false} alignByColumn={true} showInColumn="per_month12" customizeText={(data) => { return `${numberWithCommas(data.value)}` }} />

                            <GroupItem column="diff_per_month1_econ" summaryType="sum" showInGroupFooter={false} alignByColumn={true} showInColumn={11} customizeText={(data) => { return `${numberWithCommas(data.value)}` }} />
                            <GroupItem column="diff_per_month2_econ" summaryType="sum" showInGroupFooter={false} alignByColumn={true} showInColumn={15} customizeText={(data) => { return `${numberWithCommas(data.value)}` }} />
                            <GroupItem column="diff_per_month3_econ" summaryType="sum" showInGroupFooter={false} alignByColumn={true} showInColumn={19} customizeText={(data) => { return `${numberWithCommas(data.value)}` }} />
                            <GroupItem column="diff_per_month4_econ" summaryType="sum" showInGroupFooter={false} alignByColumn={true} showInColumn={23} customizeText={(data) => { return `${numberWithCommas(data.value)}` }} />
                            <GroupItem column="diff_per_month5_econ" summaryType="sum" showInGroupFooter={false} alignByColumn={true} showInColumn={27} customizeText={(data) => { return `${numberWithCommas(data.value)}` }} />
                            <GroupItem column="diff_per_month6_econ" summaryType="sum" showInGroupFooter={false} alignByColumn={true} showInColumn={31} customizeText={(data) => { return `${numberWithCommas(data.value)}` }} />
                            <GroupItem column="diff_per_month7_econ" summaryType="sum" showInGroupFooter={false} alignByColumn={true} showInColumn={35} customizeText={(data) => { return `${numberWithCommas(data.value)}` }} />
                            <GroupItem column="diff_per_month8_econ" summaryType="sum" showInGroupFooter={false} alignByColumn={true} showInColumn={39} customizeText={(data) => { return `${numberWithCommas(data.value)}` }} />
                            <GroupItem column="diff_per_month9_econ" summaryType="sum" showInGroupFooter={false} alignByColumn={true} showInColumn={43} customizeText={(data) => { return `${numberWithCommas(data.value)}` }} />
                            <GroupItem column="diff_per_month10_econ" summaryType="sum" showInGroupFooter={false} alignByColumn={true} showInColumn={47} customizeText={(data) => { return `${numberWithCommas(data.value)}` }} />
                            <GroupItem column="diff_per_month11_econ" summaryType="sum" showInGroupFooter={false} alignByColumn={true} showInColumn={51} customizeText={(data) => { return `${numberWithCommas(data.value)}` }} />
                            <GroupItem column="diff_per_month12_econ" summaryType="sum" showInGroupFooter={false} alignByColumn={true} showInColumn={55} customizeText={(data) => { return `${numberWithCommas(data.value)}` }} />

                            <TotalItem column="total_year_amt_econ" summaryType="sum" showInGroupFooter={false} showInColumn="total_year_amt" customizeText={(data) => { return `${numberWithCommas(data.value)}` }} />
                            <TotalItem column="total_year_diff_econ" summaryType="sum" showInGroupFooter={false} showInColumn="total_year_amt" customizeText={sumRenderText} />
                            <TotalItem column="per_total_year_amt_econ" summaryType="sum" showInGroupFooter={false} showInColumn="per_total_year_amt" customizeText={(data) => { return `${numberWithCommas(data.value)}` }} />
                            <TotalItem column="diff_year_amt_econ" summaryType="sum" showInGroupFooter={false} showInColumn={7} customizeText={(data) => { return `${numberWithCommas(data.value)}` }} />
                            <TotalItem column="month1_econ" summaryType="sum" showInGroupFooter={false} showInColumn="month1" customizeText={(data) => { return `${numberWithCommas(data.value)}` }} />
                            <TotalItem column="diff_month1_econ" summaryType="sum" showInGroupFooter={false} showInColumn="month1" customizeText={sumRenderText} />

                            <TotalItem column="month2_econ" summaryType="sum" showInGroupFooter={false} showInColumn="month2" customizeText={(data) => { return `${numberWithCommas(data.value)}` }} />
                            <TotalItem column="diff_month2_econ" summaryType="sum" showInGroupFooter={false} showInColumn="month2" customizeText={sumRenderText} />

                            <TotalItem column="month3_econ" summaryType="sum" showInGroupFooter={false} showInColumn="month3" customizeText={(data) => { return `${numberWithCommas(data.value)}` }} />
                            <TotalItem column="diff_month3_econ" summaryType="sum" showInGroupFooter={false} showInColumn="month3" customizeText={sumRenderText} />


                            <TotalItem column="month4_econ" summaryType="sum" showInGroupFooter={false} showInColumn="month4" customizeText={(data) => { return `${numberWithCommas(data.value)}` }} />
                            <TotalItem column="diff_month4_econ" summaryType="sum" showInGroupFooter={false} showInColumn="month4" customizeText={sumRenderText} />

                            <TotalItem column="month5_econ" summaryType="sum" showInGroupFooter={false} showInColumn="month5" customizeText={(data) => { return `${numberWithCommas(data.value)}` }} />
                            <TotalItem column="diff_month5_econ" summaryType="sum" showInGroupFooter={false} showInColumn="month5" customizeText={sumRenderText} />

                            <TotalItem column="month6_econ" summaryType="sum" showInGroupFooter={false} showInColumn="month6" customizeText={(data) => { return `${numberWithCommas(data.value)}` }} />
                            <TotalItem column="diff_month6_econ" summaryType="sum" showInGroupFooter={false} showInColumn="month6" customizeText={sumRenderText} />

                            <TotalItem column="month7_econ" summaryType="sum" showInGroupFooter={false} showInColumn="month7" customizeText={(data) => { return `${numberWithCommas(data.value)}` }} />
                            <TotalItem column="diff_month7_econ" summaryType="sum" showInGroupFooter={false} showInColumn="month7" customizeText={sumRenderText} />

                            <TotalItem column="month8_econ" summaryType="sum" showInGroupFooter={false} showInColumn="month8" customizeText={(data) => { return `${numberWithCommas(data.value)}` }} />
                            <TotalItem column="diff_month8_econ" summaryType="sum" showInGroupFooter={false} showInColumn="month8" customizeText={sumRenderText} />

                            <TotalItem column="month9_econ" summaryType="sum" showInGroupFooter={false} showInColumn="month9" customizeText={(data) => { return `${numberWithCommas(data.value)}` }} />
                            <TotalItem column="diff_month9_econ" summaryType="sum" showInGroupFooter={false} showInColumn="month9" customizeText={sumRenderText} />

                            <TotalItem column="month10_econ" summaryType="sum" showInGroupFooter={false} showInColumn="month10" customizeText={(data) => { return `${numberWithCommas(data.value)}` }} />
                            <TotalItem column="diff_month10_econ" summaryType="sum" showInGroupFooter={false} showInColumn="month10" customizeText={sumRenderText} />

                            <TotalItem column="month11_econ" summaryType="sum" showInGroupFooter={false} showInColumn="month11" customizeText={(data) => { return `${numberWithCommas(data.value)}` }} />
                            <TotalItem column="diff_month11_econ" summaryType="sum" showInGroupFooter={false} showInColumn="month11" customizeText={sumRenderText} />

                            <TotalItem column="month12_econ" summaryType="sum" showInGroupFooter={false} showInColumn="month12" customizeText={(data) => { return `${numberWithCommas(data.value)}` }} />
                            <TotalItem column="diff_month12_econ" summaryType="sum" showInGroupFooter={false} showInColumn="month12" customizeText={sumRenderText} />

                            <TotalItem column="per_month1_econ" summaryType="sum" showInGroupFooter={false} showInColumn="per_month1" customizeText={(data) => { return `${numberWithCommas(data.value)}` }} />
                            <TotalItem column="per_month2_econ" summaryType="sum" showInGroupFooter={false} showInColumn="per_month2" customizeText={(data) => { return `${numberWithCommas(data.value)}` }} />
                            <TotalItem column="per_month3_econ" summaryType="sum" showInGroupFooter={false} showInColumn="per_month3" customizeText={(data) => { return `${numberWithCommas(data.value)}` }} />
                            <TotalItem column="per_month4_econ" summaryType="sum" showInGroupFooter={false} showInColumn="per_month4" customizeText={(data) => { return `${numberWithCommas(data.value)}` }} />
                            <TotalItem column="per_month5_econ" summaryType="sum" showInGroupFooter={false} showInColumn="per_month5" customizeText={(data) => { return `${numberWithCommas(data.value)}` }} />
                            <TotalItem column="per_month6_econ" summaryType="sum" showInGroupFooter={false} showInColumn="per_month6" customizeText={(data) => { return `${numberWithCommas(data.value)}` }} />
                            <TotalItem column="per_month7_econ" summaryType="sum" showInGroupFooter={false} showInColumn="per_month7" customizeText={(data) => { return `${numberWithCommas(data.value)}` }} />
                            <TotalItem column="per_month8_econ" summaryType="sum" showInGroupFooter={false} showInColumn="per_month8" customizeText={(data) => { return `${numberWithCommas(data.value)}` }} />
                            <TotalItem column="per_month9_econ" summaryType="sum" showInGroupFooter={false} showInColumn="per_month9" customizeText={(data) => { return `${numberWithCommas(data.value)}` }} />
                            <TotalItem column="per_month10_econ" summaryType="sum" showInGroupFooter={false} showInColumn="per_month10" customizeText={(data) => { return `${numberWithCommas(data.value)}` }} />
                            <TotalItem column="per_month11_econ" summaryType="sum" showInGroupFooter={false} showInColumn="per_month11" customizeText={(data) => { return `${numberWithCommas(data.value)}` }} />
                            <TotalItem column="per_month12_econ" summaryType="sum" showInGroupFooter={false} showInColumn="per_month12" customizeText={(data) => { return `${numberWithCommas(data.value)}` }} />

                            <TotalItem column="diff_per_month1_econ" summaryType="sum" showInGroupFooter={false} showInColumn={11} customizeText={(data) => { return `${numberWithCommas(data.value)}` }} />
                            <TotalItem column="diff_per_month2_econ" summaryType="sum" showInGroupFooter={false} showInColumn={15} customizeText={(data) => { return `${numberWithCommas(data.value)}` }} />
                            <TotalItem column="diff_per_month3_econ" summaryType="sum" showInGroupFooter={false} showInColumn={19} customizeText={(data) => { return `${numberWithCommas(data.value)}` }} />
                            <TotalItem column="diff_per_month4_econ" summaryType="sum" showInGroupFooter={false} showInColumn={23} customizeText={(data) => { return `${numberWithCommas(data.value)}` }} />
                            <TotalItem column="diff_per_month5_econ" summaryType="sum" showInGroupFooter={false} showInColumn={27} customizeText={(data) => { return `${numberWithCommas(data.value)}` }} />
                            <TotalItem column="diff_per_month6_econ" summaryType="sum" showInGroupFooter={false} showInColumn={31} customizeText={(data) => { return `${numberWithCommas(data.value)}` }} />
                            <TotalItem column="diff_per_month7_econ" summaryType="sum" showInGroupFooter={false} showInColumn={35} customizeText={(data) => { return `${numberWithCommas(data.value)}` }} />
                            <TotalItem column="diff_per_month8_econ" summaryType="sum" showInGroupFooter={false} showInColumn={39} customizeText={(data) => { return `${numberWithCommas(data.value)}` }} />
                            <TotalItem column="diff_per_month9_econ" summaryType="sum" showInGroupFooter={false} showInColumn={43} customizeText={(data) => { return `${numberWithCommas(data.value)}` }} />
                            <TotalItem column="diff_per_month10_econ" summaryType="sum" showInGroupFooter={false} showInColumn={47} customizeText={(data) => { return `${numberWithCommas(data.value)}` }} />
                            <TotalItem column="diff_per_month11_econ" summaryType="sum" showInGroupFooter={false} showInColumn={51} customizeText={(data) => { return `${numberWithCommas(data.value)}` }} />
                            <TotalItem column="diff_per_month12_econ" summaryType="sum" showInGroupFooter={false} showInColumn={55} customizeText={(data) => { return `${numberWithCommas(data.value)}` }} />

                        </Summary>}
                    </DataGrid>
                </div>
            }
        </>
    )
}
export default ScheduleGridList

import React, { useState, useEffect, useContext,useRef} from "react";
import ScheduleService from "../../../services/api/scheduleService";
import notify from "devextreme/ui/notify";
import DataGrid, {
    Column,
    Grouping,
    Lookup,
    HeaderFilter,
    Summary,
    Selection,
    Toolbar,
    ColumnChooser,
    GroupItem,
    FilterPanel,
    Export, ColumnFixing, Scrolling,TotalItem,Paging
} from "devextreme-react/data-grid";
import RefService from "../../../services/api/refs";
import Button from "devextreme-react/button";
import { UserContext } from "../../../hooks/UserContext";
import { formatDate, numberWithCommas } from "../../../util";
import LoadPanelComponent from "../../budget/components/loadPanel";
import { Item } from "devextreme-react/tab-panel";
import { saveAs } from "file-saver-es";
import { BsFiletypeXls } from "react-icons/bs";
import { SiMicrosoftexcel } from "react-icons/si";
import {CgArrowsExpandRight, CgCompressRight, CgEye, CgEyeAlt, CgMaximize, CgMinimize} from "react-icons/cg";
import { Workbook } from "exceljs";
import { exportDataGrid } from "devextreme/excel_exporter";
import loaderGif from "../../../assets/imgs/tube-spinner.svg";
import SelectBox from "devextreme-react/select-box";

const ScheduleMergeReport = (props) => {
    const [refAgenda, setRefAgenda] = useState([]);
    const [refEvent, setRefEvent] = useState([]);
    const [loadMe, setLoadMe] = useState(false);
    const { user } = useContext(UserContext);
    const [econCategory, setEconCategory] = useState([]);
    const [autoExpandAll, setAutoExpand] = useState(true);
    const [dataEcon2, setDataEcon2] = useState([]);
    const dataExpenseGridRef = useRef(null);
    const [fullscreen, setFullscreen] = useState(false)
    const [isVisible, setIsVisible] = useState(true);
    const [year, setYear] = useState([]);
    const [selectedYear, setSelectedYear] = useState(null);
    const [status, setStatus] = useState([]);
    const [selectedStatus, setselectedStatus] = useState(null);

    const loadAllBudget = async () => {
        try {
            setLoadMe(true);
            let allBpd = await ScheduleService.getTEZscheduleReport(selectedYear,selectedStatus,isVisible);
            setDataEcon2(allBpd);
            setLoadMe(false);
        } catch (e) {
            setLoadMe(false);
            notify(e.message, "error", 2000);
        }
    }

    const loadRef = async () => {
        try {
            setLoadMe(true);
            let a = await RefService.getAgendas();
            let e = await RefService.getEvents();
            let result = await RefService.getRefEconomicCategory();
            setEconCategory(result.data);
            setRefAgenda(a.data);
            setRefEvent(e.data);
            setLoadMe(false);
        } catch (e) {
            setLoadMe(false);
            notify(e.message, "error", 2000);
        }
    }

    const loadYear = async () => {
        try {
            setLoadMe(true);
            let r = await ScheduleService.getTEZscheduleReportParam('year',null);
            r.length > 0 && setSelectedYear(r[0].org_budget_id);
            setYear(r);
            return setLoadMe(false);
        } catch (e) {
            notify(e.response.data.message, 'error', 2000);
        }
    }
    const loadStatus = async (param) => {
        try {
            setLoadMe(true);
            let r = await ScheduleService.getTEZscheduleReportParam('status',param);
            r.length > 0 && setselectedStatus(r[0].id);
            setStatus(r);
            return setLoadMe(false);
        } catch (e) {
            notify(e.response.data.message, 'error', 2000);
        }
    }

    useEffect(() => {
        //loadAllBudget();
        loadRef();
        loadYear();

    }, [])
    useEffect(() => {
        selectedYear && selectedStatus && loadAllBudget();
    }, [isVisible,selectedStatus,selectedYear])

    const customizedBold = (data) => {
        return (
            <span style={{ fontWeight: 'bold', fontFamily: 'Segoe UI' }}>{data.text}</span>
        )
    }

    const onExporting = (e) => {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Main sheet');
        const grid = e.component;
            grid.beginUpdate();
            grid.columnOption('prg_code', 'visible', true);
            grid.columnOption('prg_name', 'visible', true);
            grid.columnOption('min_code', 'visible', true);
            grid.columnOption('min_name', 'visible', true);

            grid.columnOption('pro_code', 'visible', true);
            grid.columnOption('pro_name', 'visible', true);
            grid.columnOption('org_code', 'visible', true);
            grid.columnOption('org_name_export', 'visible', true);

            grid.columnOption('ded_code', 'visible', true);
            grid.columnOption('ded_name', 'visible', true);
            grid.columnOption('eco_coden', 'visible', true);
            grid.columnOption('eco_name', 'visible', true);
            worksheet.columns = [
                { width: 10 },{ width: 100 },{ width: 18 },{ width: 18 },{ width: 18 },{ width: 18 },{ width: 18 },{ width: 18 },{ width: 18 },{ width: 18 },{ width: 18 },{ width: 18 },{ width: 18 },{ width: 18 },{ width: 18 },{ width: 18 },{ width: 18 },{ width: 18 },{ width: 18 },{ width: 18 },{ width: 18 },{ width: 18 },{ width: 18 },{ width: 18 },{ width: 18 },{ width: 18 },{ width: 18 },{ width: 18 },{ width: 18 },{ width: 18 },{ width: 18 },{ width: 18 },{ width: 18 },{ width: 18 },{ width: 18 },{ width: 18 },{ width: 18 },{ width: 18 },{ width: 18 },{ width: 18 }
            ];
            exportDataGrid({
                component: e.component,
                worksheet,
                autoFilterEnabled: true,
                keepColumnWidths: false,
                topLeftCell: { row: 4, column: 2 },
                customizeCell: ({ gridCell, excelCell }) => {
                    if (gridCell.rowType === 'header') {
                        excelCell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'EEF0F4' } };
                        excelCell.font = { color: { argb: '15186A' },name: 'Segoe UI Semibold',size: 8};
                        /*excelCell.border = {
                            top: { style: 'thin', color: { argb: '2c2c2c' } },
                            left: { style: 'thin', color: { argb: '2c2c2c' } },
                            bottom: { style: 'thin', color: { argb: '2c2c2c' } },
                            right: { style: 'thin', color: { argb: '2c2c2c' } }
                        }*/
                    }
                    if (gridCell.rowType === 'group') {
                        if(gridCell.groupIndex ===0) {

                            excelCell.font = { color: { argb: 'F93A3A' },name: 'Segoe UI Semibold',size: 8};
                            excelCell.font.fontWeight = 'bold';
                            if(excelCell._column._number !== 2 ) excelCell.alignment = { horizontal: 'right' };
                            /* excelCell.border = {
                                 top: { style: 'thin', color: { argb: '2c2c2c' } },
                                 left: { style: 'thin', color: { argb: '2c2c2c' } },
                                 bottom: { style: 'thin', color: { argb: '2c2c2c' } },
                                 right: { style: 'thin', color: { argb: '2c2c2c' } }
                             }*/
                        }
                        if(gridCell.groupIndex ===1) {
                            excelCell.font = { color: { argb: '2C77FF' },fontWeight:'bold',name: 'Segoe UI Semibold',size: 8};
                            if(excelCell._column._number !== 2 ) excelCell.alignment = { horizontal: 'right' };
                            /* excelCell.border = {
                                 top: { style: 'thin', color: { argb: '2c2c2c' } },
                                 left: { style: 'thin', color: { argb: '2c2c2c' } },
                                 bottom: { style: 'thin', color: { argb: '2c2c2c' } },
                                 right: { style: 'thin', color: { argb: '2c2c2c' } }
                             }*/
                        }
                        if([2,3,4].includes(gridCell.groupIndex)) {
                            excelCell.font = { color: { argb: '021772' },fontWeight:'bold',name: 'Segoe UI Semibold',size: 8};
                            if(excelCell._column._number !== 2 ) excelCell.alignment = { horizontal: 'right' };
                        }
                        else if (gridCell.groupIndex >4){
                            excelCell.alignment = { wrapText: false };
                            /*excelCell.border = {
                                top: { style: 'thin', color: { argb: '2c2c2c' } },
                                left: { style: 'thin', color: { argb: '2c2c2c' } },
                                bottom: { style: 'thin', color: { argb: '2c2c2c' } },
                                right: { style: 'thin', color: { argb: '2c2c2c' } }
                            }*/
                            if(excelCell._column._number !== 2 ) excelCell.alignment = { horizontal: 'right' };
                            excelCell.font = { fontWeight:'bold',name: 'Segoe UI Semibold',size: 8};
                        }

                    }
                    if (gridCell.rowType === 'totalFooter' && excelCell.value) {
                        excelCell.font = {name: 'Segoe UI Semibold',size: 8};
                        excelCell.font.italic = true;
                    }
                    if (gridCell.rowType === 'data') {
                        excelCell.alignment = { wrapText: false };
                        excelCell.font = {name: 'Segoe UI',size: 8};
                        if(gridCell.column.dataField !== 'econ_category_id')
                        {
                            excelCell.numFmt = '#,##0.00';
                        }
                        /*excelCell.border = {
                            top: { style: 'thin', color: { argb: '2c2c2c' } },
                            left: { style: 'thin', color: { argb: '2c2c2c' } },
                            bottom: { style: 'thin', color: { argb: '2c2c2c' } },
                            right: { style: 'thin', color: { argb: '2c2c2c' } }
                        }*/
                    }
                    if(['agenda_id','event_id','econ_category_code_3','econ_category_code_4','econ_category_code_2','econ_category_code_1','econ_category_id','budget_project_status_name','parent_name','org_name'].includes(gridCell.column.dataField)) {
                        if(excelCell._row && excelCell._row._cells && excelCell._row._cells[1].model && excelCell._row._cells[1].model.value)
                        {
                            excelCell._row._cells[1].model.value = excelCell._row._cells[1].model.value.replace(": ", "");
                        }

                    }
                    if(gridCell.column.dataField === 'econ_category_code_3' || gridCell.column.dataField === 'econ_category_code_4' || gridCell.column.dataField === 'org_name' || gridCell.column.dataField === 'agenda_id' || gridCell.column.dataField === 'event_id') {
                        /* excelCell._row._cells[1].model.value = 'dddd';
                         excelCell._row.hidden = true;*/
                        if(excelCell._row && excelCell._row._cells && excelCell._row._cells[1].model && excelCell._row._cells[1].model.value === '')
                        {
                            excelCell._row.hidden = true;
                        }
                    }

                    /*if(gridCell.rowType === 'group' && gridCell.groupIndex === 1) {
                        if(excelCell._column._number !== 2 ) excelCell.alignment = { horizontal: 'right',wrapText: false };
                        excelCell._row._cells[1].model.value = ` ${excelCell._row._cells[1].model.value}`
                    }*/
                    /*if(gridCell.rowType === 'group' && gridCell.groupIndex === 2) {
                        if(excelCell._column._number !== 2 ) excelCell.alignment = { horizontal: 'right',wrapText: false };
                        excelCell._row._cells[1].model.value = ` ${excelCell._row._cells[1].model.value}`
                    }*/
                    if(gridCell.rowType === 'group' && gridCell.groupIndex === 3) {
                        if(excelCell._column._number !== 2 ) excelCell.alignment = { horizontal: 'right',wrapText: false };
                        excelCell._row._cells[1].model.value = ` ${excelCell._row._cells[1].model.value}`
                    }
                    if(gridCell.rowType === 'group' && gridCell.groupIndex === 4) {
                        if(excelCell._column._number !== 2 ) excelCell.alignment = { horizontal: 'right',wrapText: false ,alignment:'right'};
                        excelCell._row._cells[1].model.value = `  ${excelCell._row._cells[1].model.value}`
                    }
                    // if(gridCell.rowType === 'group' && gridCell.groupIndex === 5) {
                    //     if(excelCell._column._number !== 2 ) excelCell.alignment = { horizontal: 'right',wrapText: false };
                    //     excelCell._row._cells[1].model.value = `     ${excelCell._row._cells[1].model.value}`
                    // }
                    /*if(gridCell.rowType === 'group' && gridCell.groupIndex === 6) {
                        if(excelCell._column._number !== 2 ) excelCell.alignment = { horizontal: 'right',wrapText: false };
                        excelCell._row._cells[1].model.value = ` ${excelCell._row._cells[1].model.value}`
                    }
                    if(gridCell.rowType === 'group' && gridCell.groupIndex === 7) {
                        if(excelCell._column._number !== 2 ) excelCell.alignment = { horizontal: 'right',wrapText: false };
                        excelCell._row._cells[1].model.value = `  ${excelCell._row._cells[1].model.value}`
                    }
                    if(gridCell.rowType === 'group' && gridCell.groupIndex === 8) {
                        if(excelCell._column._number !== 2 ) excelCell.alignment = { horizontal: 'right',wrapText: false };
                        excelCell._row._cells[1].model.value = `   ${excelCell._row._cells[1].model.value}`
                    }
                    if(gridCell.rowType === 'group' && gridCell.groupIndex === 9) {
                        if(excelCell._column._number !== 2 ) excelCell.alignment = { horizontal: 'right',wrapText: false };
                        excelCell._row._cells[1].model.value = `    ${excelCell._row._cells[1].model.value}`
                    }*/
                    if(gridCell.rowType === 'data') {
                        excelCell._row._cells[1].model.value = ` ${excelCell._row._cells[1].model.value}`
                    }

                },
            }).then(() => {
                Object.assign(
                    worksheet.getRow(2).getCell(5),

                    {
                        value: user.organization.name + " ХУВААРИЙН ӨӨРЧЛӨЛТИЙН ТАЙЛАН",
                        font: { bold: true, size: 16, underline: 'double' }
                    }
                );

                //worksheet.getCell('A1').value = 'a1 data';

            }).then(() => {
                workbook.xlsx.writeBuffer().then((buffer) => {
                    saveAs(new Blob([buffer], { type: 'application/octet-stream' }),"OG-" + user.organization.name + "-" + formatDate(new Date()) + ".xlsx");
                });
                grid.columnOption('prg_code', 'visible', false);
                grid.columnOption('prg_name', 'visible', false);

                grid.columnOption('min_code', 'visible', false);
                grid.columnOption('min_name', 'visible', false);

                grid.columnOption('pro_code', 'visible', false);
                grid.columnOption('pro_name', 'visible', false);
                grid.columnOption('org_code', 'visible', false);
                grid.columnOption('org_name_export', 'visible', false);

                grid.columnOption('ded_code', 'visible', false);
                grid.columnOption('ded_name', 'visible', false);
                grid.columnOption('eco_coden', 'visible', false);
                grid.columnOption('eco_name', 'visible', false);
                grid.endUpdate();
            });
            e.cancel = true;

    }

    const onExportingFiscalB = (e) => {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Main sheet');
        const grid = e;
        let groupIndexesWithSingleItem = [];
        grid.beginUpdate();
        grid.columnOption('sep_code', 'visible', true);
        grid.columnOption('sep_name', 'visible', true);
            grid.columnOption('prg_code', 'visible', true);
            grid.columnOption('prg_name', 'visible', true);
            grid.columnOption('min_code', 'visible', true);
            grid.columnOption('min_name', 'visible', true);
        grid.columnOption('age_name', 'visible', true);

            grid.columnOption('pro_code', 'visible', true);
            grid.columnOption('pro_name', 'visible', true);
            grid.columnOption('org_code', 'visible', true);
                grid.columnOption('org_name_export', 'visible', true);

            grid.columnOption('ded_code', 'visible', true);
            grid.columnOption('ded_name', 'visible', true);
            grid.columnOption('eco_coden', 'visible', true);
            grid.columnOption('eco_name', 'visible', true);

            grid.columnOption('org_name_1', 'visible', false);
            grid.columnOption('org_name_2', 'visible', false);
            grid.columnOption('org_name', 'visible', false);
            grid.columnOption('agenda_id', 'visible', false);
            grid.columnOption('event_id', 'visible', false);
            grid.columnOption('econ_category_code_1', 'visible', false);
            grid.columnOption('econ_category_code_2', 'visible', false);
            grid.columnOption('econ_category_code_3', 'visible', false);
            grid.columnOption('econ_category_code_4', 'visible', false);
            grid.columnOption('econ_category_id', 'visible', false);
            //grid.columnOption('debit', 'visible', false);
            grid.columnOption('month1', 'visible', false);
            grid.columnOption('ch_month1', 'visible', false);
            grid.columnOption('month2', 'visible', false);
            grid.columnOption('ch_month2', 'visible', false);
            grid.columnOption('month3', 'visible', false);
            grid.columnOption('ch_month3', 'visible', false);
            grid.columnOption('month4', 'visible', false);
            grid.columnOption('ch_month4', 'visible', false);
            grid.columnOption('month5', 'visible', false);
            grid.columnOption('ch_month5', 'visible', false);
            grid.columnOption('month6', 'visible', false);
            grid.columnOption('ch_month6', 'visible', false);
            grid.columnOption('month7', 'visible', false);
            grid.columnOption('ch_month7', 'visible', false);
            grid.columnOption('month8', 'visible', false);
            grid.columnOption('ch_month8', 'visible', false);
            grid.columnOption('month9', 'visible', false);
            grid.columnOption('ch_month9', 'visible', false);
            grid.columnOption('month10', 'visible', false);
            grid.columnOption('ch_month10', 'visible', false);
            grid.columnOption('month11', 'visible', false);
            grid.columnOption('ch_month11', 'visible', false);
            grid.columnOption('month12', 'visible', false);
            grid.columnOption('ch_month12', 'visible', false);

        grid.columnOption('debit', 'fixed', false);
        grid.columnOption('sep_code', 'visibleIndex', 10);
        grid.columnOption('min_code', 'visibleIndex', 11);
        grid.columnOption('pro_code', 'visibleIndex', 12);
        grid.columnOption('org_code', 'visibleIndex', 13);
        grid.columnOption('prg_code', 'visibleIndex', 14);
        grid.columnOption('ded_code', 'visibleIndex', 15);
        grid.columnOption('eco_coden', 'visibleIndex', 16);
        grid.columnOption('sep_name', 'visibleIndex', 17);
        grid.columnOption('min_name', 'visibleIndex', 18);
        grid.columnOption('age_name', 'visibleIndex', 19);
        //age name add
        grid.columnOption('pro_name', 'visibleIndex', 20);
        grid.columnOption('prg_name', 'visibleIndex', 21);
        grid.columnOption('org_name_export', 'visibleIndex', 22);
        grid.columnOption('ded_name', 'visibleIndex', 23);
        grid.columnOption('eco_name', 'visibleIndex', 24);


        //after
        grid.columnOption('debit', 'visibleIndex', 25);
        grid.columnOption('after_month1', 'visibleIndex', 26);
        grid.columnOption('after_month2', 'visibleIndex', 27);
        grid.columnOption('after_month3', 'visibleIndex', 28);
        grid.columnOption('after_month4', 'visibleIndex', 29);
        grid.columnOption('after_month5', 'visibleIndex', 30);
        grid.columnOption('after_month6', 'visibleIndex', 31);
        grid.columnOption('after_month7', 'visibleIndex', 32);
        grid.columnOption('after_month8', 'visibleIndex', 33);
        grid.columnOption('after_month9', 'visibleIndex', 34);
        grid.columnOption('after_month10', 'visibleIndex', 35);
        grid.columnOption('after_month11', 'visibleIndex', 36);
        grid.columnOption('after_month12', 'visibleIndex', 37);

        //grid.columnOption('after_month1', 'isBand', undefined);

        grid.columnOption('debit', 'caption', 'prep111');
        grid.columnOption('after_month1', 'caption', 'allo1');
        grid.columnOption('after_month2', 'caption', 'allo2');
        grid.columnOption('after_month3', 'caption', 'allo3');
        grid.columnOption('after_month4', 'caption', 'allo4');
        grid.columnOption('after_month5', 'caption', 'allo5');
        grid.columnOption('after_month6', 'caption', 'allo6');
        grid.columnOption('after_month7', 'caption', 'allo7');
        grid.columnOption('after_month8', 'caption', 'allo8');
        grid.columnOption('after_month9', 'caption', 'allo9');
        grid.columnOption('after_month10', 'caption', 'allo10');
        grid.columnOption('after_month11', 'caption', 'allo11');
        grid.columnOption('after_month12', 'caption', 'allo12');
            worksheet.columns = [
             { width: 10 },{ width: 10 },{ width: 10 },{ width: 15 },{ width: 13 },{ width: 14 },{ width: 15 },{ width: 18 },{ width: 18 },{ width: 18 },{ width: 18 },{ width: 18 },{ width: 18 },{ width: 18 },{ width: 18 },{ width: 18 },{ width: 18 },{ width: 18 },{ width: 18 },{ width: 18 },{ width: 18 },{ width: 18 },{ width: 18 },{ width: 18 },{ width: 18 },{ width: 18 },{ width: 18 },{ width: 18 },{ width: 18 },{ width: 18 },{ width: 18 },{ width: 18 },{ width: 18 },{ width: 18 },{ width: 18 },{ width: 18 },{ width: 18 },{ width: 18 }
            ];
            exportDataGrid({
                component: e,
                worksheet,
                autoFilterEnabled: false,
                keepColumnWidths: false,
                topLeftCell: { row: 1, column: 1 },
                customizeCell: ({ gridCell, excelCell }) => {
                    if (gridCell.rowType === 'header') {
                        excelCell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: ( ['sep_name','min_name','pro_name','prg_name','org_name_export','ded_name','eco_name','age_name'].includes(gridCell.column.dataField) ? 'EEF0F4' : 'FFFF00') } };
                        excelCell.font = { color: { argb: '15186A' },name: 'Segoe UI Semibold',size: 8};
                        excelCell.border = {
                            top: { style: 'thin', color: { argb: '2c2c2c' } },
                            left: { style: 'thin', color: { argb: '2c2c2c' } },
                            bottom: { style: 'thin', color: { argb: '2c2c2c' } },
                            right: { style: 'thin', color: { argb: '2c2c2c' } }
                        }
                        for (let i =1; i<13;i++) {
                            if(excelCell.value === `${i}-р сар`){excelCell.value = `allo${i}`}
                        }
                    }
                    if (gridCell.rowType === 'group') { groupIndexesWithSingleItem.push(excelCell.fullAddress.row);
                    }
                    if (gridCell.rowType === 'data') {
                        excelCell.alignment = { wrapText: false };
                      for (let i =1; i<13;i++) {
                          if(gridCell.column.dataField === `after_month${i}` && gridCell.data[`ch_month${i}`] * 1 !== 0)
                          {
                              //Object.assign( worksheet.getCell(excelCell._address),{note: 'hahahaha'});
                              excelCell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb:  gridCell.data[`ch_month${i}`] * 1 < 0 ? 'FABF8F' : 'B8CCE4' } };
                              //worksheet.getCell(excelCell.address).note = 'haha haha';
                              excelCell.note = "s";
                          }
                      }

                        excelCell.font = {name: 'Segoe UI',size: 8};
                        if(gridCell.column.dataField !== 'econ_category_id')
                        {
                            excelCell.numFmt = '#,##0.00';
                        }

                    }
                    if (gridCell.rowType === 'totalFooter') {
                        /*excelCell.font = {name: 'Segoe UI Semibold',size: 8};
                        excelCell.font.italic = true;*/
                        excelCell.value = '';
                        worksheet.spliceRows(excelCell.row, 1);
                    }
                    //if(gridCell.rowType === "group"){ excelCell.hidden = { wrapText: false,hidden: true};}
                },
            }).then(() => {
                let uniqueIds = groupIndexesWithSingleItem.filter((element, index) => {
                    return groupIndexesWithSingleItem.indexOf(element) === index;
                });
                //console.log(uniqueIds);
                uniqueIds.sort((a, b) => b - a);
                for (let i = 0; i < uniqueIds.length; i++) {
                    worksheet.getRow(uniqueIds[i] + 1).outlineLevel = undefined;
                    worksheet.spliceRows(uniqueIds[i], 1);
                }
                worksheet.unMergeCells('A1:P1');
                worksheet.spliceRows(2, 1);
                //worksheet.spliceRows(totalFooterRowIdx-1, 1);
            }).then(() => {
                workbook.xlsx.writeBuffer().then((buffer) => {
                    saveAs(new Blob([buffer], { type: 'application/octet-stream' }),"FiscalB" + formatDate(new Date()) + ".xlsx");

                    grid.columnOption('sep_code', 'visible', false);
                    grid.columnOption('sep_name', 'visible', false);
                    /*grid.columnOption(-1, 'visibleIndex', 46);
                    grid.columnOption(-2, 'visibleIndex', 47);*/

                    grid.columnOption('prg_code', 'visible', false);
                    grid.columnOption('prg_name', 'visible', false);

                    grid.columnOption('min_code', 'visible', false);
                    grid.columnOption('min_name', 'visible', false);
                    grid.columnOption('age_name', 'visible', false);
                    grid.columnOption('pro_code', 'visible', false);
                    grid.columnOption('pro_name', 'visible', false);
                    grid.columnOption('org_code', 'visible', false);
                    grid.columnOption('org_name_export', 'visible', false);

                    grid.columnOption('ded_code', 'visible', false);
                    grid.columnOption('ded_name', 'visible', false);
                    grid.columnOption('eco_coden', 'visible', false);
                    grid.columnOption('eco_name', 'visible', false);


                    grid.columnOption('org_name_1', 'visible', true);
                    grid.columnOption('org_name_2', 'visible', true);
                    grid.columnOption('org_name', 'visible', false);
                    grid.columnOption('agenda_id', 'visible', true);
                    grid.columnOption('event_id', 'visible', true);
                    grid.columnOption('econ_category_code_1', 'visible', true);
                    grid.columnOption('econ_category_code_2', 'visible', true);
                    grid.columnOption('econ_category_code_3', 'visible', true);
                    grid.columnOption('econ_category_code_4', 'visible', true);
                    grid.columnOption('econ_category_id', 'visible', true);
                    //grid.columnOption('debit', 'visible', true);
                    grid.columnOption('month1', 'visible', true);
                    grid.columnOption('ch_month1', 'visible', true);
                    grid.columnOption('month2', 'visible', true);
                    grid.columnOption('ch_month2', 'visible', true);
                    grid.columnOption('month3', 'visible', true);
                    grid.columnOption('ch_month3', 'visible', true);
                    grid.columnOption('month4', 'visible', true);
                    grid.columnOption('ch_month4', 'visible', true);
                    grid.columnOption('month5', 'visible', true);
                    grid.columnOption('ch_month5', 'visible', true);
                    grid.columnOption('month6', 'visible', true);
                    grid.columnOption('ch_month6', 'visible', true);
                    grid.columnOption('month7', 'visible', true);
                    grid.columnOption('ch_month7', 'visible', true);
                    grid.columnOption('month8', 'visible', true);
                    grid.columnOption('ch_month8', 'visible', true);
                    grid.columnOption('month9', 'visible', true);
                    grid.columnOption('ch_month9', 'visible', true);
                    grid.columnOption('month10', 'visible', true);
                    grid.columnOption('ch_month10', 'visible', true);
                    grid.columnOption('month11', 'visible', true);
                    grid.columnOption('ch_month11', 'visible', true);
                    grid.columnOption('month12', 'visible', true);
                    grid.columnOption('ch_month12', 'visible', true);

                    grid.columnOption('debit', 'fixed', true);
                    grid.columnOption('sep_code', 'visibleIndex', 46);
                    grid.columnOption('min_code', 'visibleIndex', 48);
                    grid.columnOption('pro_code', 'visibleIndex', 50);
                    grid.columnOption('org_code', 'visibleIndex', 52);
                    grid.columnOption('prg_code', 'visibleIndex', 54);
                    grid.columnOption('ded_code', 'visibleIndex', 56);
                    grid.columnOption('eco_coden', 'visibleIndex', 58);

                    grid.columnOption('sep_name', 'visibleIndex', 47);
                    grid.columnOption('min_name', 'visibleIndex', 49);
                    grid.columnOption('age_name', 'visibleIndex', 60);
                    //age name add
                    grid.columnOption('pro_name', 'visibleIndex', 51);
                    grid.columnOption('prg_name', 'visibleIndex', 55);
                    grid.columnOption('org_name_export', 'visibleIndex', 53);
                    grid.columnOption('ded_name', 'visibleIndex', 57);
                    grid.columnOption('eco_name', 'visibleIndex', 59);

                    //after
                    grid.columnOption('debit', 'visibleIndex', 10);
                    grid.columnOption('after_month1', 'visibleIndex', 13);
                    grid.columnOption('after_month2', 'visibleIndex', 16);
                    grid.columnOption('after_month3', 'visibleIndex', 19);
                    grid.columnOption('after_month4', 'visibleIndex', 22);
                    grid.columnOption('after_month5', 'visibleIndex', 25);
                    grid.columnOption('after_month6', 'visibleIndex', 28);
                    grid.columnOption('after_month7', 'visibleIndex', 31);
                    grid.columnOption('after_month8', 'visibleIndex', 34);
                    grid.columnOption('after_month9', 'visibleIndex', 36);
                    grid.columnOption('after_month10', 'visibleIndex', 39);
                    grid.columnOption('after_month11', 'visibleIndex', 42);
                    grid.columnOption('after_month12', 'visibleIndex', 45);

                    grid.columnOption('debit', 'caption', 'Нийт төсөв');
                    grid.columnOption('after_month1', 'caption', 'Дараах');
                    grid.columnOption('after_month2', 'caption', 'Дараах');
                    grid.columnOption('after_month3', 'caption', 'Дараах');
                    grid.columnOption('after_month4', 'caption', 'Дараах');
                    grid.columnOption('after_month5', 'caption', 'Дараах');
                    grid.columnOption('after_month6', 'caption', 'Дараах');
                    grid.columnOption('after_month7', 'caption', 'Дараах');
                    grid.columnOption('after_month8', 'caption', 'Дараах');
                    grid.columnOption('after_month9', 'caption', 'Дараах');
                    grid.columnOption('after_month10', 'caption', 'Дараах');
                    grid.columnOption('after_month11', 'caption', 'Дараах');
                    grid.columnOption('after_month12', 'caption', 'Дараах');


                    grid.endUpdate();
                });




            });
            e.cancel = true;
    }

    const onCellPreparedHandler = (e) => {
        if (e.rowType === 'header') {
            e.cellElement.wordWrapEnabled = true;
            e.cellElement.style.fontFamily = "Segoe UI";
            e.cellElement.style.fontWeight = "bold";
            e.cellElement.style.color = "#15186A";
            e.cellElement.style.alignment = "center";
            e.cellElement.style.backgroundColor = "#EEF0F4";
            e.cellElement.style.borderColor = "#D4DCEC";
            e.cellElement.style.fontSize = "12px";
            e.cellElement.style.verticalAlign = "middle";
        }
        else if (e.rowType === 'group') {
            e.cellElement.style.fontFamily = "Segoe UI"
            //e.cellElement.wordWrapEnabled = true;
            /*switch (e.row.groupIndex) {
                case 0:
                    return e.cellElement.style.color = "#F93A3A"
                case 1:
                    return e.cellElement.style.color = "#2C77FF"
                case 2:
                    return e.cellElement.style.color ="#021772"
                case 3:
                    return e.cellElement.style.color ="#021772"
                case 4:
                    return e.cellElement.style.color ="#021772"
                 default:
                    return e.cellElement.style.color = "black"
            }*/
            e.cellElement.style.color = (e.row.groupIndex === 0 ? "#F93A3A" : ((e.row.groupIndex && e.row.groupIndex===1) ? "#2C77FF" : 'black'))
            //e.cellElement.style.color = (e.row.groupIndex === 0 ? "#F93A3A" : ((e.row.groupIndex && e.row.groupIndex===1) ? "#2C77FF" : 'black'))
            e.cellElement.style.backgroundColor = "#fff"
            e.cellElement.style.fontWeight = "bold";
            e.cellElement.style.fontSize = "12px"
            e.cellElement.style.borderColor = "#D4DCEC"
            /*if(e.column.dataField === "ch_month1") {
                e.cellElement.style.color= '#F93A3A'
            }*/

        } else if (e.rowType === "data") {
            e.cellElement.style.fontFamily = 'Segoe UI'
            e.cellElement.style.fontWeight = 'normal'
            e.cellElement.style.color = "#000000"
            e.cellElement.style.verticalAlign = "middle";
        }
    }
    const groupCell = (e) => {
        return <div>{e.data.displayValue}</div>
    }
    const onRowPrepared = (args) => {
        if (args.rowType == 'group' && (args.values[args.groupIndex] == "" || args.values[args.groupIndex] == null)) {
            args.rowElement.hidden = true;
            if (!args.component.isRowExpanded(args.key))
                args.component.expandRow(args.key)
        }
    }
    const requestFullscreen = () => {
        if (document.fullscreenElement) document.exitFullscreen();
        else document.getElementById("wrapper").requestFullscreen();
        // setFullscreen(prev=>!prev)
    }

    const calculateArea = (rowData,field,groupIndex) => {
        return  groupIndex === 0 ? (rowData.econ_category_code_1 === '2' && rowData[field]*1) : rowData.econ_category_code_1 === '2' && rowData[field]*1;
    }
    const calculateAreaTotal = (rowData,field) => {
        return  rowData.econ_category_code_1 === '2' && rowData[field]*1
    }
    const calculateAreaSummary = (options) => {
        if (options.name === 'customSum') {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0;
            } else if (options.summaryProcess === 'calculate') {
                if(options.groupIndex < 5){
                    options.totalValue += calculateArea(options.value,'debit',options.groupIndex);
                } else {
                    options.totalValue += options.value.debit * 1
                }

            }
        }
        else if (options.name === 'totalSum') {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0;
            } else if (options.summaryProcess === 'calculate') {
                options.totalValue += calculateAreaTotal(options.value,'debit');

            }
        }
        else if (options.name === 'month1sum') {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0;
            } else if (options.summaryProcess === 'calculate') {
                options.totalValue += calculateAreaTotal(options.value,'month1');
            }
        }
        else if (options.name === 'ch_month1sum') {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0;
            } else if (options.summaryProcess === 'calculate') {
                options.totalValue += calculateAreaTotal(options.value,'ch_month1');
            }
        }
        else if (options.name === 'month1') {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0;
            } else if (options.summaryProcess === 'calculate') {
                if(options.groupIndex < 5){
                    options.totalValue += calculateArea(options.value,'month1',options.groupIndex);
                } else {
                    options.totalValue += options.value.month1 * 1
                }

            }
        }
        else if (options.name === 'ch_month1') {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0;
            } else if (options.summaryProcess === 'calculate') {
                if(options.groupIndex < 5){
                    options.totalValue += calculateArea(options.value,'ch_month1',options.groupIndex);
                } else {
                    options.totalValue += options.value.ch_month1 * 1;
                }

            }
        }
        else if (options.name === 'ch_month2sum') {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0;
            } else if (options.summaryProcess === 'calculate') {
                options.totalValue += calculateAreaTotal(options.value,'ch_month2');
            }
        }
        else if (options.name === 'month2sum') {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0;
            } else if (options.summaryProcess === 'calculate') {
                options.totalValue += calculateAreaTotal(options.value,'month2');
            }
        }
        else if (options.name === 'month2') {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0;
            } else if (options.summaryProcess === 'calculate') {
                if(options.groupIndex < 5){
                    options.totalValue += calculateArea(options.value,'month2',options.groupIndex);
                } else {
                    options.totalValue += options.value.month1 * 1
                }

            }
        }
        else if (options.name === 'ch_month2') {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0;
            } else if (options.summaryProcess === 'calculate') {
                if(options.groupIndex < 5){
                    options.totalValue += calculateArea(options.value,'ch_month2',options.groupIndex);
                } else {
                    options.totalValue += options.value.ch_month2 * 1
                }

            }
        }

        else if (options.name === 'month3sum') {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0;
            } else if (options.summaryProcess === 'calculate') {
                options.totalValue += calculateAreaTotal(options.value,'month3');
            }
        }
        else if (options.name === 'ch_month3sum') {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0;
            } else if (options.summaryProcess === 'calculate') {
                options.totalValue += calculateAreaTotal(options.value,'ch_month3');
            }
        }
        else if (options.name === 'month3') {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0;
            } else if (options.summaryProcess === 'calculate') {
                if(options.groupIndex < 5){
                    options.totalValue += calculateArea(options.value,'month3',options.groupIndex);
                } else {
                    options.totalValue += options.value.month3 * 1
                }

            }
        }
        else if (options.name === 'ch_month3') {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0;
            } else if (options.summaryProcess === 'calculate') {
                if(options.groupIndex < 5){
                    options.totalValue += calculateArea(options.value,'ch_month3',options.groupIndex);
                } else {
                    options.totalValue += options.value.ch_month3 * 1
                }

            }
        }
        else if (options.name === 'month4sum') {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0;
            } else if (options.summaryProcess === 'calculate') {
                options.totalValue += calculateAreaTotal(options.value,'month4');
            }
        }
        else if (options.name === 'ch_month4sum') {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0;
            } else if (options.summaryProcess === 'calculate') {
                options.totalValue += calculateAreaTotal(options.value,'ch_month4');
            }
        }
        else if (options.name === 'month4') {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0;
            } else if (options.summaryProcess === 'calculate') {
                if(options.groupIndex < 5){
                    options.totalValue += calculateArea(options.value,'month4',options.groupIndex);
                } else {
                    options.totalValue += options.value.month4 * 1
                }

            }
        }
        else if (options.name === 'ch_month4') {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0;
            } else if (options.summaryProcess === 'calculate') {
                if(options.groupIndex < 5){
                    options.totalValue += calculateArea(options.value,'ch_month4',options.groupIndex);
                } else {
                    options.totalValue += options.value.ch_month4 * 1
                }

            }
        }
        else if (options.name === 'month5sum') {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0;
            } else if (options.summaryProcess === 'calculate') {
                options.totalValue += calculateAreaTotal(options.value,'month5');
            }
        }
        else if (options.name === 'ch_month5sum') {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0;
            } else if (options.summaryProcess === 'calculate') {
                options.totalValue += calculateAreaTotal(options.value,'ch_month5');
            }
        }
        else if (options.name === 'month5') {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0;
            } else if (options.summaryProcess === 'calculate') {
                if(options.groupIndex < 5){
                    options.totalValue += calculateArea(options.value,'month5',options.groupIndex);
                } else {
                    options.totalValue += options.value.month5 * 1
                }

            }
        }
        else if (options.name === 'ch_month5') {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0;
            } else if (options.summaryProcess === 'calculate') {
                if(options.groupIndex < 5){
                    options.totalValue += calculateArea(options.value,'ch_month5',options.groupIndex);
                } else {
                    options.totalValue += options.value.ch_month5 * 1
                }

            }
        }
        else if (options.name === 'month6sum') {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0;
            } else if (options.summaryProcess === 'calculate') {
                options.totalValue += calculateAreaTotal(options.value,'month6');
            }
        }
        else if (options.name === 'ch_month6sum') {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0;
            } else if (options.summaryProcess === 'calculate') {
                options.totalValue += calculateAreaTotal(options.value,'ch_month6');
            }
        }
        else if (options.name === 'month6') {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0;
            } else if (options.summaryProcess === 'calculate') {
                if(options.groupIndex < 5){
                    options.totalValue += calculateArea(options.value,'month6',options.groupIndex);
                } else {
                    options.totalValue += options.value.month6 * 1
                }

            }
        }
        else if (options.name === 'ch_month6') {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0;
            } else if (options.summaryProcess === 'calculate') {
                if(options.groupIndex < 5){
                    options.totalValue += calculateArea(options.value,'ch_month6',options.groupIndex);
                } else {
                    options.totalValue += options.value.ch_month6 * 1
                }

            }
        }
        else if (options.name === 'month7sum') {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0;
            } else if (options.summaryProcess === 'calculate') {
                options.totalValue += calculateAreaTotal(options.value,'month7');
            }
        }
        else if (options.name === 'ch_month7sum') {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0;
            } else if (options.summaryProcess === 'calculate') {
                options.totalValue += calculateAreaTotal(options.value,'ch_month7');
            }
        }
        else if (options.name === 'month7') {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0;
            } else if (options.summaryProcess === 'calculate') {
                if(options.groupIndex < 5){
                    options.totalValue += calculateArea(options.value,'month7',options.groupIndex);
                } else {
                    options.totalValue += options.value.month7 * 1
                }

            }
        }
        else if (options.name === 'ch_month7') {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0;
            } else if (options.summaryProcess === 'calculate') {
                if(options.groupIndex < 5){
                    options.totalValue += calculateArea(options.value,'ch_month7',options.groupIndex);
                } else {
                    options.totalValue += options.value.ch_month7 * 1
                }

            }
        }
        else if (options.name === 'month8sum') {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0;
            } else if (options.summaryProcess === 'calculate') {
                options.totalValue += calculateAreaTotal(options.value,'month8');
            }
        }
        else if (options.name === 'ch_month8sum') {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0;
            } else if (options.summaryProcess === 'calculate') {
                options.totalValue += calculateAreaTotal(options.value,'ch_month8');
            }
        }
        else if (options.name === 'month8') {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0;
            } else if (options.summaryProcess === 'calculate') {
                if(options.groupIndex < 5){
                    options.totalValue += calculateArea(options.value,'month8',options.groupIndex);
                } else {
                    options.totalValue += options.value.month8 * 1
                }

            }
        }
        else if (options.name === 'ch_month8') {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0;
            } else if (options.summaryProcess === 'calculate') {
                if(options.groupIndex < 5){
                    options.totalValue += calculateArea(options.value,'ch_month8',options.groupIndex);
                } else {
                    options.totalValue += options.value.ch_month8 * 1
                }

            }
        }
        else if (options.name === 'month9sum') {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0;
            } else if (options.summaryProcess === 'calculate') {
                options.totalValue += calculateAreaTotal(options.value,'month9');
            }
        }
        else if (options.name === 'ch_month9sum') {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0;
            } else if (options.summaryProcess === 'calculate') {
                options.totalValue += calculateAreaTotal(options.value,'ch_month9');
            }
        }
        else if (options.name === 'month9') {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0;
            } else if (options.summaryProcess === 'calculate') {
                if(options.groupIndex < 5){
                    options.totalValue += calculateArea(options.value,'month9',options.groupIndex);
                } else {
                    options.totalValue += options.value.month9 * 1
                }

            }
        }
        else if (options.name === 'ch_month9') {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0;
            } else if (options.summaryProcess === 'calculate') {
                if(options.groupIndex < 5){
                    options.totalValue += calculateArea(options.value,'ch_month9',options.groupIndex);
                } else {
                    options.totalValue += options.value.ch_month9 * 1
                }

            }
        }
        else if (options.name === 'month10sum') {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0;
            } else if (options.summaryProcess === 'calculate') {
                options.totalValue += calculateAreaTotal(options.value,'month10');
            }
        }
        else if (options.name === 'ch_month10sum') {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0;
            } else if (options.summaryProcess === 'calculate') {
                options.totalValue += calculateAreaTotal(options.value,'ch_month10');
            }
        }
        else if (options.name === 'month10') {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0;
            } else if (options.summaryProcess === 'calculate') {
                if(options.groupIndex < 5){
                    options.totalValue += calculateArea(options.value,'month10',options.groupIndex);
                } else {
                    options.totalValue += options.value.month10 * 1
                }

            }
        }
        else if (options.name === 'ch_month10') {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0;
            } else if (options.summaryProcess === 'calculate') {
                if(options.groupIndex < 5){
                    options.totalValue += calculateArea(options.value,'ch_month10',options.groupIndex);
                } else {
                    options.totalValue += options.value.ch_month10 * 1
                }

            }
        }
        else if (options.name === 'month11sum') {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0;
            } else if (options.summaryProcess === 'calculate') {
                options.totalValue += calculateAreaTotal(options.value,'month11');
            }
        }
        else if (options.name === 'ch_month11sum') {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0;
            } else if (options.summaryProcess === 'calculate') {
                options.totalValue += calculateAreaTotal(options.value,'ch_month11');
            }
        }
        else if (options.name === 'month11') {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0;
            } else if (options.summaryProcess === 'calculate') {
                if(options.groupIndex < 5){
                    options.totalValue += calculateArea(options.value,'month11',options.groupIndex);
                } else {
                    options.totalValue += options.value.month11 * 1
                }

            }
        }
        else if (options.name === 'ch_month11') {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0;
            } else if (options.summaryProcess === 'calculate') {
                if(options.groupIndex < 5){
                    options.totalValue += calculateArea(options.value,'ch_month11',options.groupIndex);
                } else {
                    options.totalValue += options.value.ch_month11 * 1
                }

            }
        }
        else if (options.name === 'month12sum') {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0;
            } else if (options.summaryProcess === 'calculate') {
                options.totalValue += calculateAreaTotal(options.value,'month12');
            }
        }
        else if (options.name === 'ch_month12sum') {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0;
            } else if (options.summaryProcess === 'calculate') {
                options.totalValue += calculateAreaTotal(options.value,'ch_month12');
            }
        }
        else if (options.name === 'month12') {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0;
            } else if (options.summaryProcess === 'calculate') {
                if(options.groupIndex < 5){
                    options.totalValue += calculateArea(options.value,'month12',options.groupIndex);
                } else {
                    options.totalValue += options.value.month12 * 1
                }

            }
        }
        else if (options.name === 'ch_month12') {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0;
            } else if (options.summaryProcess === 'calculate') {
                if(options.groupIndex < 5){
                    options.totalValue += calculateArea(options.value,'ch_month12',options.groupIndex);
                } else {
                    options.totalValue += options.value.ch_month12 * 1
                }

            }
        }

        else if (options.name === 'after_month1') {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0;
            } else if (options.summaryProcess === 'calculate') {
                if(options.groupIndex < 5){
                    options.totalValue += calculateArea(options.value,'after_month1',options.groupIndex);
                } else {
                    options.totalValue += options.value.after_month1 * 1
                }

            }
        }
        else if (options.name === 'after1sum') {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0;
            } else if (options.summaryProcess === 'calculate') {
                options.totalValue += calculateAreaTotal(options.value,'after_month1');
            }
        }

        else if (options.name === 'after_month2') {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0;
            } else if (options.summaryProcess === 'calculate') {
                if(options.groupIndex < 5){
                    options.totalValue += calculateArea(options.value,'after_month2',options.groupIndex);
                } else {
                    options.totalValue += options.value.after_month2 * 1
                }

            }
        }
        else if (options.name === 'after2sum') {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0;
            } else if (options.summaryProcess === 'calculate') {
                options.totalValue += calculateAreaTotal(options.value,'after_month2');
            }
        }
        else if (options.name === 'after_month3') {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0;
            } else if (options.summaryProcess === 'calculate') {
                if(options.groupIndex < 5){
                    options.totalValue += calculateArea(options.value,'after_month3',options.groupIndex);
                } else {
                    options.totalValue += options.value.after_month3 * 1
                }

            }
        }
        else if (options.name === 'after3sum') {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0;
            } else if (options.summaryProcess === 'calculate') {
                options.totalValue += calculateAreaTotal(options.value,'after_month3');
            }
        }
        else if (options.name === 'after_month4') {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0;
            } else if (options.summaryProcess === 'calculate') {
                if(options.groupIndex < 5){
                    options.totalValue += calculateArea(options.value,'after_month4',options.groupIndex);
                } else {
                    options.totalValue += options.value.after_month4 * 1
                }

            }
        }
        else if (options.name === 'after4sum') {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0;
            } else if (options.summaryProcess === 'calculate') {
                options.totalValue += calculateAreaTotal(options.value,'after_month4');
            }
        }
        else if (options.name === 'after_month5') {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0;
            } else if (options.summaryProcess === 'calculate') {
                if(options.groupIndex < 5){
                    options.totalValue += calculateArea(options.value,'after_month5',options.groupIndex);
                } else {
                    options.totalValue += options.value.after_month5 * 1
                }

            }
        }
        else if (options.name === 'after5sum') {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0;
            } else if (options.summaryProcess === 'calculate') {
                options.totalValue += calculateAreaTotal(options.value,'after_month5');
            }
        }
        else if (options.name === 'after_month6') {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0;
            } else if (options.summaryProcess === 'calculate') {
                if(options.groupIndex < 5){
                    options.totalValue += calculateArea(options.value,'after_month6',options.groupIndex);
                } else {
                    options.totalValue += options.value.after_month6 * 1
                }

            }
        }
        else if (options.name === 'after6sum') {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0;
            } else if (options.summaryProcess === 'calculate') {
                options.totalValue += calculateAreaTotal(options.value,'after_month6');
            }
        }
        else if (options.name === 'after_month7') {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0;
            } else if (options.summaryProcess === 'calculate') {
                if(options.groupIndex < 5){
                    options.totalValue += calculateArea(options.value,'after_month7',options.groupIndex);
                } else {
                    options.totalValue += options.value.after_month7 * 1
                }

            }
        }
        else if (options.name === 'after7sum') {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0;
            } else if (options.summaryProcess === 'calculate') {
                options.totalValue += calculateAreaTotal(options.value,'after_month7');
            }
        }
        else if (options.name === 'after_month8') {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0;
            } else if (options.summaryProcess === 'calculate') {
                if(options.groupIndex < 5){
                    options.totalValue += calculateArea(options.value,'after_month8',options.groupIndex);
                } else {
                    options.totalValue += options.value.after_month8 * 1
                }

            }
        }
        else if (options.name === 'after8sum') {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0;
            } else if (options.summaryProcess === 'calculate') {
                options.totalValue += calculateAreaTotal(options.value,'after_month8');
            }
        }
        else if (options.name === 'after_month9') {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0;
            } else if (options.summaryProcess === 'calculate') {
                if(options.groupIndex < 5){
                    options.totalValue += calculateArea(options.value,'after_month9',options.groupIndex);
                } else {
                    options.totalValue += options.value.after_month9 * 1
                }

            }
        }
        else if (options.name === 'after9sum') {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0;
            } else if (options.summaryProcess === 'calculate') {
                options.totalValue += calculateAreaTotal(options.value,'after_month9');
            }
        }
        else if (options.name === 'after_month10') {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0;
            } else if (options.summaryProcess === 'calculate') {
                if(options.groupIndex < 5){
                    options.totalValue += calculateArea(options.value,'after_month10',options.groupIndex);
                } else {
                    options.totalValue += options.value.after_month10 * 1
                }

            }
        }
        else if (options.name === 'after10sum') {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0;
            } else if (options.summaryProcess === 'calculate') {
                options.totalValue += calculateAreaTotal(options.value,'after_month10');
            }
        }
        else if (options.name === 'after_month11') {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0;
            } else if (options.summaryProcess === 'calculate') {
                if(options.groupIndex < 5){
                    options.totalValue += calculateArea(options.value,'after_month11',options.groupIndex);
                } else {
                    options.totalValue += options.value.after_month11 * 1
                }

            }
        }
        else if (options.name === 'after11sum') {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0;
            } else if (options.summaryProcess === 'calculate') {
                options.totalValue += calculateAreaTotal(options.value,'after_month11');
            }
        }
        else if (options.name === 'after_month12') {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0;
            } else if (options.summaryProcess === 'calculate') {
                if(options.groupIndex < 5){
                    options.totalValue += calculateArea(options.value,'after_month12',options.groupIndex);
                } else {
                    options.totalValue += options.value.after_month12 * 1
                }

            }
        }
        else if (options.name === 'after12sum') {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0;
            } else if (options.summaryProcess === 'calculate') {
                options.totalValue += calculateAreaTotal(options.value,'after_month12');
            }
        }
    }
    const textAreaOptions = { format: '#,##0.00' };

    const onValueChanged = async (args) => {
        setIsVisible(!isVisible);
    }
    const CheckBoxButton  = () =>{
        return (
            <Button
                onClick={onValueChanged}
                style={{ marginLeft: "0.5em" }}
                render={() => {
                    return isVisible ?
                        (<CgEye size={"18px"} color="#1a1976" />)
                        :
                        (<CgEyeAlt size={"18px"} color="#1a1976" />)
                }}
                hint={isVisible ? 'Бүх мөрийг харуулах' : 'Өөрчлөгдсөн мөрийг харуулах'}
            >
            </Button>
        )
    }
    const cellRender = (data) => {
        return (
            <div style={{color: data.value=== 0 ? '#0c0c0c' : (data.value > 0 ? 'green' : 'red')}}>{data.text}</div>
        )
    }
    const cellRenderAfter = (data,month) => {
        return (
            <div style={{color: data.data['month' + month] *1 === data.value *1 ? '#0c0c0c' : (data.value > data.data['month' + month] ? 'green' : 'red')}}>{data.text}</div>
        )
    }
    return (
        <div id="loadMe" style={{ padding: '10px' }}>
            <LoadPanelComponent position={{ of: '#loadMe' }} visible={loadMe} />
                <div style={{ gap: '3rem' ,padding: 10,display: 'flex', justifyContent: 'flex-start', flexDirection: 'row',alignItems: 'center'}}>
                    <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'flex-start', flexDirection: 'row', marginBottom: '.5rem' }}>
                        <label style={{ fontWeight: '500', marginRight: 10 }}>Төсвийн жил<span style={{ color: 'red' }}>*</span></label>
                        <SelectBox
                            items={year}
                            value={selectedYear}
                            searchEnabled={false}
                            valueExpr="org_budget_id"
                            width={'250px'}
                            displayExpr="name"
                            onValueChanged={(e) => {
                                setSelectedYear(e.value);
                                loadStatus(e.value);
                            }}
                            style={{ width: '250px' }}
                            placeholder="сонгох" />
                    </div>
                    <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'flex-start', flexDirection: 'row', marginBottom: '.5rem' }}>
                        <label style={{ fontWeight: '500', marginRight: 10 }}>Төлөв<span style={{ color: 'red' }}>*</span></label>
                        <SelectBox
                            items={status}
                            value={selectedStatus}
                            searchEnabled={false}
                            valueExpr="id"
                            width={'200px'}
                            displayExpr="name"
                            onValueChanged={(e) => {
                                setselectedStatus(e.value);
                            }}
                            style={{ width: '200px' }}
                            placeholder="сонгох" />
                    </div>
                </div>
                <div className="offer" id="wrapper" height="100%" width="100%">
                    <DataGrid dataSource={dataEcon2} ref={dataExpenseGridRef} hoverStateEnabled={true}
                        columnAutoWidth={true}
                        //allowColumnReordering={true}
                        allowColumnResizing={true}
                        //columnWidth={'auto'}
                        loadPanel={{ enabled: true, text: '', shadingColor: "rgba(0,0,0,0.4)", showPane: false, indicatorSrc: loaderGif }}
                        height={"90vh"}
                        width={'100%'}
                        wordWrapEnabled={true}
                        columnResizingMode={'nextColumn'}
                              paging={{
                                  pageSize: 200,
                              }}
                        style={{ marginTop: 5, overflowY: "auto", overflowX: "hidden", }}
                        showBorders={true}
                        showRowLines={true}
                        twoWayBindingEnabled={true}
                        //selection={true}
                        sorting={{ mode: 'none' }}
                        showColumnLines={true} //focusedRowEnabled={true}
                        showColumnHeaders={true}
                        onCellPrepared={onCellPreparedHandler} onRowPrepared={onRowPrepared}
                        onExporting={onExporting}
                    >
                        <Paging enabled={true}/>
                        <ColumnFixing enabled={false} />
                        <FilterPanel
                            visible={false}
                            texts={{ clearFilter: "Шүүлтүүр цэвэрлэх", createFilter: "Шүүх" }}
                        />
                        <HeaderFilter visible={true} allowSearch={true} texts={{ cancel: 'Болих', ok: 'Шүүх', emptyValue: 'Хоосон утга' }} />
                        <Selection mode="single" />
                        <Export enabled={true}/>
                        <Scrolling columnRenderingMode="virtual" />
                        <Toolbar>
                            <Item>
                                <div style={{ display: 'flex', justifyContent: 'space-between', gap: '5px' }}>
                                    <Button
                                        onClick={(e) => setAutoExpand(prev => !prev)}
                                        render={() => {
                                            return autoExpandAll ? (
                                                <CgCompressRight size={"1rem"} color="#172B4D" />)
                                                :
                                                (
                                                    <CgArrowsExpandRight size={"1rem"} color="#172B4D" />
                                                )
                                        }}
                                        hint={autoExpandAll ? 'Багасгах' : 'ЭЗА дэлгэрэнгүй харуулах'}
                                    >
                                    </Button>
                                    <Button
                                        className="grid-button"
                                        onClick={() => {
                                            requestFullscreen()
                                            setFullscreen(prev => !prev)
                                        }}
                                        render={() => {
                                            return fullscreen ?
                                                (<CgMinimize size={"1rem"} color="#172B4D" />)
                                                :
                                                (<CgMaximize size={"1rem"} color="#172B4D" />)
                                        }}
                                        hint={fullscreen ? 'Багасгах' : 'Дэлгэцэнд бүтэн харуулах'}
                                    >
                                    </Button>
                                    <CheckBoxButton />
                                </div>
                            </Item>
                            <Item name="exportButton"
                                render={(e) => {
                                    return <Button
                                        //onOptionChanged={setExportType('FiscalB')}
                                        onClick={e.options.onClick}
                                        icon="xlsxfile"
                                        render={() => {
                                            return <BsFiletypeXls size={"1rem"} color="#172B4D" />
                                        }}
                                        hint={'Excel татах'}
                                        style={{ color: "red" }}>
                                    </Button>
                                }}
                            />
                            <Item>
                                <Button
                                    className="grid-button"
                                    onClick={() => {
                                        onExportingFiscalB(dataExpenseGridRef.current.instance)
                                    }}
                                    render={() => {
                                        return <SiMicrosoftexcel size={"1rem"} color="#172B4D" />
                                    }}
                                    hint={'FiscalB форматаар excel татах'}
                                >
                                </Button>
                            </Item>
                            <Item name="columnChooserButton"
                                render={(e) => {
                                    return <Button onClick={e.options.onClick} size='1rem' id='columnChooserButton' icon="columnchooser" />
                                }}
                            />
                        </Toolbar>
                        <Grouping autoExpandAll={autoExpandAll}
                            contextMenuEnabled={false}
                            expandMode="rowClick" />
                        <ColumnChooser
                            enabled={true} height={"100%"} allowSearch={true}
                            mode="select"
                            title={'Багана сонгох'}
                        />
                        <Column dataField="org_name_1" caption="" allowEditing={false} sortOrder={'asc'} visibleIndex={0}
                                groupIndex={0} groupCellComponent={groupCell} minWidth={'18rem'} width={'auto'} cssClass={'dx-datagrid-group-space'}>
                        </Column>
                        <Column dataField="org_name_2" caption="" allowEditing={false} sortOrder={'asc'} visibleIndex={1}
                                groupIndex={1} groupCellComponent={groupCell} minWidth={'18rem'} width={'auto'} cssClass={'dx-datagrid-group-space'}>
                        </Column>
                        <Column dataField="org_name" caption="" allowEditing={false} sortOrder={'asc'} showInColumnChooser={false} visibleIndex={2}
                                groupIndex={2} groupCellComponent={groupCell} minWidth={'18rem'} width={'auto'} cssClass={'dx-datagrid-group-space'}>
                        </Column>
                        <Column dataField="agenda_id" caption="" allowEditing={false} sortOrder={'asc'} showInColumnChooser={false} visibleIndex={3}
                            groupIndex={3} groupCellComponent={groupCell}  width={'1'} cssClass={'dx-datagrid-group-space'}>
                            <Lookup dataSource={refAgenda} valueExpr="id" displayExpr={(item) => {
                                return item && `${item.code.substring(0,5)} - ${item.name}`
                            }}/></Column>
                        <Column dataField="event_id" caption="" allowEditing={false} cssClass={'dx-datagrid-group-space'} sortOrder={'asc'} showInColumnChooser={false} visibleIndex={4}
                                groupCellComponent={groupCell} width={'1'} groupIndex={4} alignment={'left'}>
                            <Lookup dataSource={refEvent} valueExpr="id" displayExpr={(item) => {
                                return item && `${item.code.substring(0,5)} - ${item.name}`
                            }}/></Column>
                        <Column dataField="econ_category_code_1" caption="" groupIndex={5} cssClass={'dx-datagrid-group-space'} showInColumnChooser={false} visibleIndex={5}
                            groupCellComponent={groupCell} width={'1'} allowEditing={false}>
                            <Lookup dataSource={econCategory} valueExpr="code" displayExpr={(item) => {
                                return item && `${item.code} - ${item.name}`
                            }} /></Column>
                        <Column dataField="econ_category_code_2" caption="" groupIndex={6} cssClass={'dx-datagrid-group-space'} showInColumnChooser={false} visibleIndex={6}
                            groupCellComponent={groupCell} width={'1'} allowEditing={false}>
                            <Lookup dataSource={econCategory} valueExpr="code" displayExpr={(item) => {
                                return item && `${item.code} - ${item.name}`
                            }} /></Column>
                        <Column dataField="econ_category_code_3" caption="" groupIndex={7} cssClass={'dx-datagrid-group-space'} showInColumnChooser={false} visibleIndex={7}
                            groupCellComponent={groupCell} width={'1'} allowEditing={false}>
                            <Lookup dataSource={econCategory} valueExpr="code" displayExpr={(item) => {
                                return item && `${item.code} - ${item.name}`
                            }} /></Column>
                        <Column dataField="econ_category_code_4" caption="" groupIndex={7} cssClass={'dx-datagrid-group-space'} showInColumnChooser={false} visibleIndex={8}
                            groupCellComponent={groupCell} width={'1'} allowEditing={false}>
                            <Lookup dataSource={econCategory} valueExpr="code" displayExpr={(item) => {
                                return item && `${item.code} - ${item.name}`
                            }} /></Column>
                        <Column dataField="econ_category_id" caption="Үзүүлэлт" fixed={true} fixedPosition={'left'} allowResizing={false} minWidth={'18rem'} width={'auto'} showInColumnChooser={false} visibleIndex={9}
                            allowEditing={false}>
                            <Lookup dataSource={econCategory} valueExpr="id" displayExpr={(item) => {
                                return item && `${item.code} - ${item.name}`
                            }} /></Column>
                        <Column dataField="debit" fixed={true} fixedPosition={'left'}
                            format="#,##0.00" alignment="right" dataType="number"
                            width={'auto'}
                            caption="Нийт төсөв" cellRender={customizedBold} allowFiltering={false} allowResizing={true} visibleIndex={10}
                            allowEditing={false} />
                        <Column caption={'1-р сар'} alignment={'center'} allowResizing={true}>
                            <Column dataField={`month1`} format="#,##0.00" alignment="right" dataType="number" visibleIndex={11}
                                caption={`Хуваарь`} allowFiltering={false} allowResizing={true}>
                            </Column>
                            <Column dataField={`ch_month1`} format="#,##0.00" alignment="right" dataType="number" visibleIndex={12}
                                caption={`Өөрчлөлт`} allowFiltering={false} allowResizing={true} cellRender={cellRender}>
                            </Column>
                            <Column dataField={`after_month1`} format="#,##0.00" alignment="right" dataType="number" visibleIndex={13}
                                    caption={`Дараах`} allowFiltering={false} allowResizing={true} cellRender={(e) => cellRenderAfter(e, 1)}>
                            </Column>
                        </Column>
                        <Column caption={'2-р сар'} alignment={'center'} allowResizing={true}>
                            <Column dataField={`month2`} format="#,##0.00" alignment="right" dataType="number" visibleIndex={14}
                                    caption={`Хуваарь`} allowFiltering={false} allowResizing={true}>
                            </Column>
                            <Column dataField={`ch_month2`} format="#,##0.00" alignment="right" dataType="number" visibleIndex={15}
                                    caption={`Өөрчлөлт`} allowFiltering={false} allowResizing={true} cellRender={cellRender}>
                            </Column>
                            <Column dataField={`after_month2`} format="#,##0.00" alignment="right" dataType="number" visibleIndex={16}
                                    caption={`Дараах`} allowFiltering={false} allowResizing={true} cellRender={(e) => cellRenderAfter(e, 2)}>
                            </Column>
                        </Column>
                        <Column caption={'3-р сар'} alignment={'center'} allowResizing={true}>
                            <Column dataField={`month3`} format="#,##0.00" alignment="right" dataType="number" visibleIndex={17}
                                    caption={`Хуваарь`} allowFiltering={false} allowResizing={true}>
                            </Column>
                            <Column dataField={`ch_month3`} format="#,##0.00" alignment="right" dataType="number" visibleIndex={18}
                                    caption={`Өөрчлөлт`} allowFiltering={false} allowResizing={true} cellRender={cellRender}>
                            </Column>
                            <Column dataField={`after_month3`} format="#,##0.00" alignment="right" dataType="number" visibleIndex={19}
                                    caption={`Дараах`} allowFiltering={false} allowResizing={true} cellRender={(e) => cellRenderAfter(e, 3)}>
                            </Column>
                        </Column>
                        <Column caption={'4-р сар'} alignment={'center'} allowResizing={true}>
                            <Column dataField={`month4`} format="#,##0.00" alignment="right" dataType="number" visibleIndex={20}
                                    caption={`Хуваарь`} allowFiltering={false} allowResizing={true}>
                            </Column>
                            <Column dataField={`ch_month4`} format="#,##0.00" alignment="right" dataType="number" visibleIndex={21}
                                    caption={`Өөрчлөлт`} allowFiltering={false} allowResizing={true} cellRender={cellRender}>
                            </Column>
                            <Column dataField={`after_month4`} format="#,##0.00" alignment="right" dataType="number" visibleIndex={22}
                                    caption={`Дараах`} allowFiltering={false} allowResizing={true} cellRender={(e) => cellRenderAfter(e, 4)}>
                            </Column>
                        </Column>
                        <Column caption={'5-р сар'} alignment={'center'} allowResizing={true}>
                            <Column dataField={`month5`} format="#,##0.00" alignment="right" dataType="number" visibleIndex={23}
                                    caption={`Хуваарь`} allowFiltering={false} allowResizing={true}>
                            </Column>
                            <Column dataField={`ch_month5`} format="#,##0.00" alignment="right" dataType="number" visibleIndex={24}
                                    caption={`Өөрчлөлт`} allowFiltering={false} allowResizing={true} cellRender={cellRender}>
                            </Column>
                            <Column dataField={`after_month5`} format="#,##0.00" alignment="right" dataType="number" visibleIndex={25}
                                    caption={`Дараах`} allowFiltering={false} allowResizing={true} cellRender={(e) => cellRenderAfter(e, 5)}>
                            </Column>
                        </Column>
                        <Column caption={'6-р сар'} alignment={'center'} allowResizing={true}>
                            <Column dataField={`month6`} format="#,##0.00" alignment="right" dataType="number" visibleIndex={26}
                                    caption={`Хуваарь`} allowFiltering={false} allowResizing={true}>
                            </Column>
                            <Column dataField={`ch_month6`} format="#,##0.00" alignment="right" dataType="number" visibleIndex={27}
                                    caption={`Өөрчлөлт`} allowFiltering={false} allowResizing={true} cellRender={cellRender}>
                            </Column>
                            <Column dataField={`after_month6`} format="#,##0.00" alignment="right" dataType="number" visibleIndex={28}
                                    caption={`Дараах`} allowFiltering={false} allowResizing={true} cellRender={(e) => cellRenderAfter(e, 6)}>
                            </Column>
                        </Column>
                        <Column caption={'7-р сар'} alignment={'center'} allowResizing={true}>
                            <Column dataField={`month7`} format="#,##0.00" alignment="right" dataType="number" visibleIndex={29}
                                    caption={`Хуваарь`} allowFiltering={false} allowResizing={true}>
                            </Column>
                            <Column dataField={`ch_month7`} format="#,##0.00" alignment="right" dataType="number" visibleIndex={30}
                                    caption={`Өөрчлөлт`} allowFiltering={false} allowResizing={true} cellRender={cellRender}>
                            </Column>
                            <Column dataField={`after_month7`} format="#,##0.00" alignment="right" dataType="number" visibleIndex={31}
                                    caption={`Дараах`} allowFiltering={false} allowResizing={true} cellRender={(e) => cellRenderAfter(e, 7)}>
                            </Column>
                        </Column>
                        <Column caption={'8-р сар'} alignment={'center'} allowResizing={true}>
                            <Column dataField={`month8`} format="#,##0.00" alignment="right" dataType="number" visibleIndex={32}
                                    caption={`Хуваарь`} allowFiltering={false} allowResizing={true}>
                            </Column>
                            <Column dataField={`ch_month8`} format="#,##0.00" alignment="right" dataType="number" visibleIndex={33}
                                    caption={`Өөрчлөлт`} allowFiltering={false} allowResizing={true} cellRender={cellRender}>
                            </Column>
                            <Column dataField={`after_month8`} format="#,##0.00" alignment="right" dataType="number" visibleIndex={34}
                                    caption={`Дараах`} allowFiltering={false} allowResizing={true} cellRender={(e) => cellRenderAfter(e, 8)}>
                            </Column>
                        </Column>
                        <Column caption={'9-р сар'} alignment={'center'} allowResizing={true}>
                            <Column dataField={`month9`} format="#,##0.00" alignment="right" dataType="number" visibleIndex={35}
                                    caption={`Хуваарь`} allowFiltering={false} allowResizing={true}>
                            </Column>
                            <Column dataField={`ch_month9`} format="#,##0.00" alignment="right" dataType="number" visibleIndex={35}
                                    caption={`Өөрчлөлт`} allowFiltering={false} allowResizing={true} cellRender={cellRender}>
                            </Column>
                            <Column dataField={`after_month9`} format="#,##0.00" alignment="right" dataType="number"  visibleIndex={36}
                                    caption={`Дараах`} allowFiltering={false} allowResizing={true} cellRender={(e) => cellRenderAfter(e, 9)}>
                            </Column>
                        </Column>
                        <Column caption={'10-р сар'} alignment={'center'} allowResizing={true}>
                            <Column dataField={`month10`} format="#,##0.00" alignment="right" dataType="number"  visibleIndex={37}
                                    caption={`Хуваарь`} allowFiltering={false} allowResizing={true}>
                            </Column>
                            <Column dataField={`ch_month10`} format="#,##0.00" alignment="right" dataType="number"  visibleIndex={38}
                                    caption={`Өөрчлөлт`} allowFiltering={false} allowResizing={true} cellRender={cellRender}>
                            </Column>
                            <Column dataField={`after_month10`} format="#,##0.00" alignment="right" dataType="number"  visibleIndex={39}
                                    caption={`Дараах`} allowFiltering={false} allowResizing={true} cellRender={(e) => cellRenderAfter(e, 10)}>
                            </Column>
                        </Column>
                        <Column caption={'11-р сар'} alignment={'center'} allowResizing={true}>
                            <Column dataField={`month11`} format="#,##0.00" alignment="right" dataType="number"  visibleIndex={40}
                                    caption={`Хуваарь`} allowFiltering={false} allowResizing={true}>
                            </Column>
                            <Column dataField={`ch_month11`} format="#,##0.00" alignment="right" dataType="number" visibleIndex={41}
                                    caption={`Өөрчлөлт`} allowFiltering={false} allowResizing={true} cellRender={cellRender}>
                            </Column>
                            <Column dataField={`after_month11`} format="#,##0.00" alignment="right" dataType="number" visibleIndex={42}
                                    caption={`Дараах`} allowFiltering={false} allowResizing={true} cellRender={(e) => cellRenderAfter(e, 11)}>
                            </Column>
                        </Column>
                        <Column caption={'12-р сар'} alignment={'center'} allowResizing={true}>
                            <Column dataField={`month12`} format="#,##0.00" alignment="right" dataType="number" visibleIndex={43}
                                    caption={`Хуваарь`} allowFiltering={false} allowResizing={true}>
                            </Column>
                            <Column dataField={`ch_month12`} format="#,##0.00" alignment="right" dataType="number" visibleIndex={44}
                                    caption={`Өөрчлөлт`} allowFiltering={false} allowResizing={true} cellRender={cellRender}>
                            </Column>
                            <Column dataField={`after_month12`} format="#,##0.00" alignment="right" dataType="number" visibleIndex={45}
                                    caption={`Дараах`} allowFiltering={false} allowResizing={true} cellRender={(e) => cellRenderAfter(e, 12)}>
                            </Column>
                        </Column>
                        <Column dataField={`sep_code`} caption={'sep_code'} alignment="right" dataType="string" allowFiltering={false} allowResizing={false} visible={false} showInColumnChooser={false} visibleIndex={46}/>
                        <Column dataField={`sep_name`} caption={'sep_name'} alignment="right" dataType="string" allowFiltering={false} allowResizing={false} visible={false} showInColumnChooser={false} visibleIndex={47}/>
                        <Column dataField={`min_code`} caption={'min_code'} alignment="right" dataType="string" allowFiltering={false} allowResizing={false} visible={false} showInColumnChooser={false} visibleIndex={48}/>
                        <Column dataField={`min_name`} caption={'min_name'} alignment="right" dataType="string" allowFiltering={false} allowResizing={false} visible={false} showInColumnChooser={false} visibleIndex={49}/>
                        <Column dataField={`pro_code`} caption={'pro_code'} alignment="right" dataType="string" allowFiltering={false} allowResizing={false} visible={false} showInColumnChooser={false} visibleIndex={50}/>
                        <Column dataField={`pro_name`} caption={'pro_name'} alignment="right" dataType="string" allowFiltering={false} allowResizing={false} visible={false} showInColumnChooser={false} visibleIndex={51}/>
                        <Column dataField={`org_code`} caption={'org_code'} alignment="right" dataType="string" allowFiltering={false} allowResizing={false} visible={false} showInColumnChooser={false} visibleIndex={52}/>
                        <Column dataField={`org_name_export`} caption={'org_name'} alignment="right" dataType="string" allowFiltering={false} allowResizing={false} visible={false} showInColumnChooser={false} visibleIndex={53}/>
                        <Column dataField={`prg_code`} caption={'prg_code'} alignment="right" dataType="string" allowFiltering={false} allowResizing={false} visible={false} showInColumnChooser={false} visibleIndex={54}/>
                        <Column dataField={`prg_name`} caption={'prg_name'} alignment="right" dataType="string" allowFiltering={false} allowResizing={false} visible={false} showInColumnChooser={false} visibleIndex={55}/>
                        <Column dataField={`ded_code`} caption={'ded_code'} alignment="right" dataType="string" allowFiltering={false} allowResizing={false} visible={false} showInColumnChooser={false} visibleIndex={56}/>
                        <Column dataField={`ded_name`} caption={'ded_name'} alignment="right" dataType="string" allowFiltering={false} allowResizing={false} visible={false} showInColumnChooser={false} visibleIndex={57}/>
                        <Column dataField={`eco_coden`} caption={'eco_code'} alignment="right" dataType="string" allowFiltering={false} allowResizing={false} visible={false} showInColumnChooser={false} visibleIndex={58}/>
                        <Column dataField={`eco_name`} caption={'eco_name'} alignment="right" dataType="string" allowFiltering={false} allowResizing={false} visible={false} showInColumnChooser={false} visibleIndex={59}/>
                        <Column dataField={`age_name`} caption={'age_name'} alignment="right" dataType="string" allowFiltering={false} allowResizing={false} visible={false} showInColumnChooser={false} visibleIndex={60}/>
                        <Export enabled={true} allowExportSelectedData={false} />
                        <Summary calculateCustomSummary={calculateAreaSummary}>
                            <GroupItem name="customSum"
                                       summaryType="custom"
                                //column="debit" summaryType="sum"
                                       showInGroupFooter={false} alignByColumn={true}
                                showInColumn="debit"
                                customizeText={(data) => {
                                    return `${numberWithCommas(data.value)}`
                                }} />
                            <GroupItem name="month1"
                                       summaryType="custom"//column="month1" summaryType="sum"
                                       showInGroupFooter={false} alignByColumn={true}
                                showInColumn="month1" customizeText={(data) => {
                                    return `${numberWithCommas(data.value)}`
                                }} />
                            <GroupItem name="month2"
                                       summaryType="custom"//column="month1" summaryType="sum"
                                       showInGroupFooter={false} alignByColumn={true}
                                       showInColumn="month2" customizeText={(data) => {
                                return `${numberWithCommas(data.value)}`
                            }} />
                            <GroupItem name="ch_month1"
                                       summaryType="custom"//column="month2" summaryType="sum"
                                       showInGroupFooter={false} alignByColumn={true}
                                showInColumn="ch_month1" customizeText={(data) => {
                                    return `${numberWithCommas(data.value)}`
                                }} />
                            <GroupItem name="ch_month2"
                                       summaryType="custom"//column="month3" summaryType="sum"
                                       showInGroupFooter={false} alignByColumn={true}
                                showInColumn="ch_month2" customizeText={(data) => {
                                    return `${numberWithCommas(data.value)}`
                                }} />
                            <GroupItem name="month3"
                                       summaryType="custom"//column="month1" summaryType="sum"
                                       showInGroupFooter={false} alignByColumn={true}
                                       showInColumn="month3" customizeText={(data) => {
                                return `${numberWithCommas(data.value)}`
                            }} />
                            <GroupItem name="ch_month3"
                                       summaryType="custom"//column="month2" summaryType="sum"
                                       showInGroupFooter={false} alignByColumn={true}
                                       showInColumn="ch_month3" customizeText={(data) => {
                                return `${numberWithCommas(data.value)}`
                            }} />
                            <GroupItem name="month4"
                                       summaryType="custom"//column="month1" summaryType="sum"
                                       showInGroupFooter={false} alignByColumn={true}
                                       showInColumn="month4" customizeText={(data) => {
                                return `${numberWithCommas(data.value)}`
                            }} />
                            <GroupItem name="ch_month4"
                                       summaryType="custom"//column="month2" summaryType="sum"
                                       showInGroupFooter={false} alignByColumn={true}
                                       showInColumn="ch_month4" customizeText={(data) => {
                                return `${numberWithCommas(data.value)}`
                            }} />
                            <GroupItem name="month5"
                                       summaryType="custom"//column="month1" summaryType="sum"
                                       showInGroupFooter={false} alignByColumn={true}
                                       showInColumn="month5" customizeText={(data) => {
                                return `${numberWithCommas(data.value)}`
                            }} />
                            <GroupItem name="ch_month5"
                                       summaryType="custom"//column="month2" summaryType="sum"
                                       showInGroupFooter={false} alignByColumn={true}
                                       showInColumn="ch_month5" customizeText={(data) => {
                                return `${numberWithCommas(data.value)}`
                            }} />
                            <GroupItem name="month6"
                                       summaryType="custom"//column="month1" summaryType="sum"
                                       showInGroupFooter={false} alignByColumn={true}
                                       showInColumn="month6" customizeText={(data) => {
                                return `${numberWithCommas(data.value)}`
                            }} />
                            <GroupItem name="ch_month6"
                                       summaryType="custom"//column="month2" summaryType="sum"
                                       showInGroupFooter={false} alignByColumn={true}
                                       showInColumn="ch_month6" customizeText={(data) => {
                                return `${numberWithCommas(data.value)}`
                            }} />
                            <GroupItem name="month7"
                                       summaryType="custom"//column="month1" summaryType="sum"
                                       showInGroupFooter={false} alignByColumn={true}
                                       showInColumn="month7" customizeText={(data) => {
                                return `${numberWithCommas(data.value)}`
                            }} />
                            <GroupItem name="ch_month7"
                                       summaryType="custom"//column="month2" summaryType="sum"
                                       showInGroupFooter={false} alignByColumn={true}
                                       showInColumn="ch_month7" customizeText={(data) => {
                                return `${numberWithCommas(data.value)}`
                            }} />
                            <GroupItem name="month8"
                                       summaryType="custom"//column="month1" summaryType="sum"
                                       showInGroupFooter={false} alignByColumn={true}
                                       showInColumn="month8" customizeText={(data) => {
                                return `${numberWithCommas(data.value)}`
                            }} />
                            <GroupItem name="ch_month8"
                                       summaryType="custom"//column="month2" summaryType="sum"
                                       showInGroupFooter={false} alignByColumn={true}
                                       showInColumn="ch_month8" customizeText={(data) => {
                                return `${numberWithCommas(data.value)}`
                            }} />
                            <GroupItem name="month9"
                                       summaryType="custom"//column="month1" summaryType="sum"
                                       showInGroupFooter={false} alignByColumn={true}
                                       showInColumn="month9" customizeText={(data) => {
                                return `${numberWithCommas(data.value)}`
                            }} />
                            <GroupItem name="ch_month9"
                                       summaryType="custom"//column="month2" summaryType="sum"
                                       showInGroupFooter={false} alignByColumn={true}
                                       showInColumn="ch_month9" customizeText={(data) => {
                                return `${numberWithCommas(data.value)}`
                            }} />
                            <GroupItem name="month10"
                                       summaryType="custom"//column="month1" summaryType="sum"
                                       showInGroupFooter={false} alignByColumn={true}
                                       showInColumn="month10" customizeText={(data) => {
                                return `${numberWithCommas(data.value)}`
                            }} />
                            <GroupItem name="ch_month10"
                                       summaryType="custom"//column="month2" summaryType="sum"
                                       showInGroupFooter={false} alignByColumn={true}
                                       showInColumn="ch_month10" customizeText={(data) => {
                                return `${numberWithCommas(data.value)}`
                            }} />
                            <GroupItem name="month11"
                                       summaryType="custom"//column="month1" summaryType="sum"
                                       showInGroupFooter={false} alignByColumn={true}
                                       showInColumn="month11" customizeText={(data) => {
                                return `${numberWithCommas(data.value)}`
                            }} />
                            <GroupItem name="ch_month11"
                                       summaryType="custom"//column="month2" summaryType="sum"
                                       showInGroupFooter={false} alignByColumn={true}
                                       showInColumn="ch_month11" customizeText={(data) => {
                                return `${numberWithCommas(data.value)}`
                            }} />
                            <GroupItem name="month12"
                                       summaryType="custom"//column="month1" summaryType="sum"
                                       showInGroupFooter={false} alignByColumn={true}
                                       showInColumn="month12" customizeText={(data) => {
                                return `${numberWithCommas(data.value)}`
                            }} />
                            <GroupItem name="ch_month12"
                                       summaryType="custom"//column="month2" summaryType="sum"
                                       showInGroupFooter={false} alignByColumn={true}
                                       showInColumn="ch_month12" customizeText={(data) => {
                                return `${numberWithCommas(data.value)}`
                            }} />
                            <GroupItem name="after_month1"
                                       summaryType="custom"
                                       showInGroupFooter={false} alignByColumn={true}
                                       showInColumn="after_month1" customizeText={(data) => {
                                return `${numberWithCommas(data.value)}`
                            }} />
                            <GroupItem name="after_month2"
                                       summaryType="custom"
                                       showInGroupFooter={false} alignByColumn={true}
                                       showInColumn="after_month2" customizeText={(data) => {
                                return `${numberWithCommas(data.value)}`
                            }} />
                            <GroupItem name="after_month3"
                                       summaryType="custom"
                                       showInGroupFooter={false} alignByColumn={true}
                                       showInColumn="after_month3" customizeText={(data) => {
                                return `${numberWithCommas(data.value)}`
                            }} />
                            <GroupItem name="after_month4"
                                       summaryType="custom"
                                       showInGroupFooter={false} alignByColumn={true}
                                       showInColumn="after_month4" customizeText={(data) => {
                                return `${numberWithCommas(data.value)}`
                            }} />
                            <GroupItem name="after_month5"
                                       summaryType="custom"
                                       showInGroupFooter={false} alignByColumn={true}
                                       showInColumn="after_month5" customizeText={(data) => {
                                return `${numberWithCommas(data.value)}`
                            }} />
                            <GroupItem name="after_month6"
                                       summaryType="custom"
                                       showInGroupFooter={false} alignByColumn={true}
                                       showInColumn="after_month6" customizeText={(data) => {
                                return `${numberWithCommas(data.value)}`
                            }} />
                            <GroupItem name="after_month7"
                                       summaryType="custom"
                                       showInGroupFooter={false} alignByColumn={true}
                                       showInColumn="after_month7" customizeText={(data) => {
                                return `${numberWithCommas(data.value)}`
                            }} />
                            <GroupItem name="after_month8"
                                       summaryType="custom"
                                       showInGroupFooter={false} alignByColumn={true}
                                       showInColumn="after_month8" customizeText={(data) => {
                                return `${numberWithCommas(data.value)}`
                            }} />
                            <GroupItem name="after_month9"
                                       summaryType="custom"
                                       showInGroupFooter={false} alignByColumn={true}
                                       showInColumn="after_month9" customizeText={(data) => {
                                return `${numberWithCommas(data.value)}`
                            }} />
                            <GroupItem name="after_month10"
                                       summaryType="custom"
                                       showInGroupFooter={false} alignByColumn={true}
                                       showInColumn="after_month10" customizeText={(data) => {
                                return `${numberWithCommas(data.value)}`
                            }} />
                            <GroupItem name="after_month11"
                                       summaryType="custom"
                                       showInGroupFooter={false} alignByColumn={true}
                                       showInColumn="after_month11" customizeText={(data) => {
                                return `${numberWithCommas(data.value)}`
                            }} />
                            <GroupItem name="after_month12"
                                       summaryType="custom"
                                       showInGroupFooter={false} alignByColumn={true}
                                       showInColumn="after_month12" customizeText={(data) => {
                                return `${numberWithCommas(data.value)}`
                            }} />
                            <TotalItem name="totalSum"
                                       summaryType="custom"
                                //column="debit" summaryType="sum"
                                       showInGroupFooter={false} alignByColumn={true}
                                       showInColumn="debit"
                                       customizeText={(data) => {
                                           return `${numberWithCommas(data.value)}`
                                       }} />
                            <TotalItem name="month1sum"
                                       summaryType="custom"//column="month1" summaryType="sum"
                                       showInGroupFooter={false} alignByColumn={true}
                                       showInColumn="month1" customizeText={(data) => {
                                return `${numberWithCommas(data.value)}`
                            }} />
                            <TotalItem name="month2sum"
                                       summaryType="custom"//column="month1" summaryType="sum"
                                       showInGroupFooter={false} alignByColumn={true}
                                       showInColumn="month2" customizeText={(data) => {
                                return `${numberWithCommas(data.value)}`
                            }} />
                            <TotalItem name="ch_month1sum"
                                       summaryType="custom"//column="month1" summaryType="sum"
                                       showInGroupFooter={false} alignByColumn={true}
                                       showInColumn="ch_month1" customizeText={(data) => {
                                return `${numberWithCommas(data.value)}`
                            }} />
                            <TotalItem name="ch_month2sum"
                                       summaryType="custom"
                                       showInGroupFooter={false} alignByColumn={true}
                                       showInColumn="ch_month2"
                                       customizeText={(data) => {
                                           return `${numberWithCommas(data.value)}`
                                       }} />
                            <TotalItem name="month3sum"
                                       summaryType="custom"//column="month1" summaryType="sum"
                                       showInGroupFooter={false} alignByColumn={true}
                                       showInColumn="month3" customizeText={(data) => {
                                return `${numberWithCommas(data.value)}`
                            }} />
                            <TotalItem name="ch_month3sum"
                                       summaryType="custom"//column="month1" summaryType="sum"
                                       showInGroupFooter={false} alignByColumn={true}
                                       showInColumn="ch_month3" customizeText={(data) => {
                                return `${numberWithCommas(data.value)}`
                            }} />
                            <TotalItem name="month4sum"
                                       summaryType="custom"//column="month1" summaryType="sum"
                                       showInGroupFooter={false} alignByColumn={true}
                                       showInColumn="month4" customizeText={(data) => {
                                return `${numberWithCommas(data.value)}`
                            }} />
                            <TotalItem name="ch_month4sum"
                                       summaryType="custom"//column="month1" summaryType="sum"
                                       showInGroupFooter={false} alignByColumn={true}
                                       showInColumn="ch_month4" customizeText={(data) => {
                                return `${numberWithCommas(data.value)}`
                            }} />
                            <TotalItem name="month5sum"
                                       summaryType="custom"//column="month1" summaryType="sum"
                                       showInGroupFooter={false} alignByColumn={true}
                                       showInColumn="month5" customizeText={(data) => {
                                return `${numberWithCommas(data.value)}`
                            }} />
                            <TotalItem name="ch_month5sum"
                                       summaryType="custom"//column="month1" summaryType="sum"
                                       showInGroupFooter={false} alignByColumn={true}
                                       showInColumn="ch_month5" customizeText={(data) => {
                                return `${numberWithCommas(data.value)}`
                            }} />
                            <TotalItem name="month6sum"
                                       summaryType="custom"//column="month1" summaryType="sum"
                                       showInGroupFooter={false} alignByColumn={true}
                                       showInColumn="month6" customizeText={(data) => {
                                return `${numberWithCommas(data.value)}`
                            }} />
                            <TotalItem name="ch_month6sum"
                                       summaryType="custom"//column="month1" summaryType="sum"
                                       showInGroupFooter={false} alignByColumn={true}
                                       showInColumn="ch_month6" customizeText={(data) => {
                                return `${numberWithCommas(data.value)}`
                            }} />
                            <TotalItem name="month7sum"
                                       summaryType="custom"//column="month1" summaryType="sum"
                                       showInGroupFooter={false} alignByColumn={true}
                                       showInColumn="month7" customizeText={(data) => {
                                return `${numberWithCommas(data.value)}`
                            }} />
                            <TotalItem name="ch_month7sum"
                                       summaryType="custom"//column="month1" summaryType="sum"
                                       showInGroupFooter={false} alignByColumn={true}
                                       showInColumn="ch_month7" customizeText={(data) => {
                                return `${numberWithCommas(data.value)}`
                            }} />
                            <TotalItem name="month8sum"
                                       summaryType="custom"//column="month1" summaryType="sum"
                                       showInGroupFooter={false} alignByColumn={true}
                                       showInColumn="month8" customizeText={(data) => {
                                return `${numberWithCommas(data.value)}`
                            }} />
                            <TotalItem name="ch_month8sum"
                                       summaryType="custom"//column="month1" summaryType="sum"
                                       showInGroupFooter={false} alignByColumn={true}
                                       showInColumn="ch_month8" customizeText={(data) => {
                                return `${numberWithCommas(data.value)}`
                            }} />
                            <TotalItem name="month9sum"
                                       summaryType="custom"//column="month1" summaryType="sum"
                                       showInGroupFooter={false} alignByColumn={true}
                                       showInColumn="month9" customizeText={(data) => {
                                return `${numberWithCommas(data.value)}`
                            }} />
                            <TotalItem name="ch_month9sum"
                                       summaryType="custom"//column="month1" summaryType="sum"
                                       showInGroupFooter={false} alignByColumn={true}
                                       showInColumn="ch_month9" customizeText={(data) => {
                                return `${numberWithCommas(data.value)}`
                            }} />
                            <TotalItem name="month10sum"
                                       summaryType="custom"//column="month1" summaryType="sum"
                                       showInGroupFooter={false} alignByColumn={true}
                                       showInColumn="month10" customizeText={(data) => {
                                return `${numberWithCommas(data.value)}`
                            }} />
                            <TotalItem name="ch_month10sum"
                                       summaryType="custom"//column="month1" summaryType="sum"
                                       showInGroupFooter={false} alignByColumn={true}
                                       showInColumn="ch_month10" customizeText={(data) => {
                                return `${numberWithCommas(data.value)}`
                            }} />
                            <TotalItem name="month11sum"
                                       summaryType="custom"//column="month1" summaryType="sum"
                                       showInGroupFooter={false} alignByColumn={true}
                                       showInColumn="month11" customizeText={(data) => {
                                return `${numberWithCommas(data.value)}`
                            }} />
                            <TotalItem name="ch_month11sum"
                                       summaryType="custom"//column="month1" summaryType="sum"
                                       showInGroupFooter={false} alignByColumn={true}
                                       showInColumn="ch_month11" customizeText={(data) => {
                                return `${numberWithCommas(data.value)}`
                            }} />
                            <TotalItem name="month12sum"
                                       summaryType="custom"//column="month1" summaryType="sum"
                                       showInGroupFooter={false} alignByColumn={true}
                                       showInColumn="month12" customizeText={(data) => {
                                return `${numberWithCommas(data.value)}`
                            }} />
                            <TotalItem name="ch_month12sum"
                                       summaryType="custom"//column="month1" summaryType="sum"
                                       showInGroupFooter={false} alignByColumn={true}
                                       showInColumn="ch_month12" customizeText={(data) => {
                                return `${numberWithCommas(data.value)}`
                            }} />
                            <TotalItem name="after1sum"
                                       summaryType="custom"//column="month1" summaryType="sum"
                                       showInGroupFooter={false} alignByColumn={true}
                                       showInColumn="after_month1" customizeText={(data) => {
                                return `${numberWithCommas(data.value)}`
                            }} />
                            <TotalItem name="after2sum"
                                       summaryType="custom"//column="month1" summaryType="sum"
                                       showInGroupFooter={false} alignByColumn={true}
                                       showInColumn="after_month2" customizeText={(data) => {
                                return `${numberWithCommas(data.value)}`
                            }} />
                            <TotalItem name="after3sum"
                                       summaryType="custom"//column="month1" summaryType="sum"
                                       showInGroupFooter={false} alignByColumn={true}
                                       showInColumn="after_month3" customizeText={(data) => {
                                return `${numberWithCommas(data.value)}`
                            }} />
                            <TotalItem name="after4sum"
                                       summaryType="custom"//column="month1" summaryType="sum"
                                       showInGroupFooter={false} alignByColumn={true}
                                       showInColumn="after_month4" customizeText={(data) => {
                                return `${numberWithCommas(data.value)}`
                            }} />
                            <TotalItem name="after5sum"
                                       summaryType="custom"//column="month1" summaryType="sum"
                                       showInGroupFooter={false} alignByColumn={true}
                                       showInColumn="after_month5" customizeText={(data) => {
                                return `${numberWithCommas(data.value)}`
                            }} />
                            <TotalItem name="after6sum"
                                       summaryType="custom"//column="month1" summaryType="sum"
                                       showInGroupFooter={false} alignByColumn={true}
                                       showInColumn="after_month6" customizeText={(data) => {
                                return `${numberWithCommas(data.value)}`
                            }} />
                            <TotalItem name="after7sum"
                                       summaryType="custom"//column="month1" summaryType="sum"
                                       showInGroupFooter={false} alignByColumn={true}
                                       showInColumn="after_month7" customizeText={(data) => {
                                return `${numberWithCommas(data.value)}`
                            }} />
                            <TotalItem name="after8sum"
                                       summaryType="custom"//column="month1" summaryType="sum"
                                       showInGroupFooter={false} alignByColumn={true}
                                       showInColumn="after_month8" customizeText={(data) => {
                                return `${numberWithCommas(data.value)}`
                            }} />
                            <TotalItem name="after9sum"
                                       summaryType="custom"//column="month1" summaryType="sum"
                                       showInGroupFooter={false} alignByColumn={true}
                                       showInColumn="after_month9" customizeText={(data) => {
                                return `${numberWithCommas(data.value)}`
                            }} />
                            <TotalItem name="after10sum"
                                       summaryType="custom"//column="month1" summaryType="sum"
                                       showInGroupFooter={false} alignByColumn={true}
                                       showInColumn="after_month10" customizeText={(data) => {
                                return `${numberWithCommas(data.value)}`
                            }} />
                            <TotalItem name="after11sum"
                                       summaryType="custom"//column="month1" summaryType="sum"
                                       showInGroupFooter={false} alignByColumn={true}
                                       showInColumn="after_month11" customizeText={(data) => {
                                return `${numberWithCommas(data.value)}`
                            }} />
                            <TotalItem name="after12sum"
                                       summaryType="custom"//column="month1" summaryType="sum"
                                       showInGroupFooter={false} alignByColumn={true}
                                       showInColumn="after_month12" customizeText={(data) => {
                                return `${numberWithCommas(data.value)}`
                            }} />
                        </Summary>
                    </DataGrid>
                </div>
        </div>
    )
}

export default ScheduleMergeReport

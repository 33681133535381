import React, {useContext, useEffect, useState} from 'react';
import 'devextreme-react/text-area';
import {UserContext} from "../../../hooks/UserContext";
import fcsService from "../../../services/api/fcs";
import Button from "devextreme-react/button";
import notify from "devextreme/ui/notify";
import SelectBox from "devextreme-react/select-box";
import DateBox from "devextreme-react/date-box";
import {LoadPanel} from "devextreme-react/load-panel";
import {confirm} from "devextreme/ui/dialog";
import {Popup} from "devextreme-react/popup";
import CustorgParent from "../Gl/gledit/custorgParent";
import FaOrEdit from "./edit/FaOrEdit";
import LoadPanelComponent from '../../../components/LoadPanelComponent';

function FaOr(props) {

    const userListPosition = {of: '#usersList'}
    const {user} = useContext(UserContext);
    const [loader, setLoader] = useState(false);
    const [dataFcsChart, setFcsChart] = useState([]);
    const [yearValue, setYearValue] = useState(0)
    const [monthValue, setMonthValue] = useState(0)
    const [countFaOr, setCountFaOr] = useState(0)

    const [dataFcsMonth, setFcsMonth] = useState([]);
    const initCustValue = {name: '', orgcode: ''}
    const [custValue, setCustValue] = useState({...initCustValue});
    const [modalVisibleCustOrg, setModalVisibleCustOrg] = useState(false)
    const [selectedFA, setSelectedFA] = useState([]);
    const [selectedTr, setSelectedTr] = useState(0);

    const [selectedChart, setSelectedChart] = useState([]);
    const [dataFcsFA, setFcsFA] = useState([]);


    const [docDate, setDocDate] = useState();

    const initRow = {
        docnum: '',
        descr: '',
        fanumber: '',
        quantity: 0,

        cost_org: 0,
        cost_cap: 0,
        acder_amt: 0
    }
    const [row, setRow] = useState({...initRow})
    useEffect(() => {
        fcsFaList();
    }, [])


    useEffect(() => {
        setDocDate(new Date());

    }, []);

    useEffect(() => {
        changeMonth();

    }, [dataFcsMonth]);


    const changeMonth = async () => {

        dataFcsMonth.map((item) => {

            setYearValue(item.year)
            setMonthValue(item.month)

        })


    }


    const fcsFaList = async () => {
        try {

            setLoader(true);
            const fcsChart = await fcsService.getAllFcsChart();
            setFcsChart(fcsChart);

            const fcsFa = await fcsService.getAllFcsFa();
            setFcsFA(fcsFa);

            const fcsMonth = await fcsService.getAllFcsMonth('FA');
            setFcsMonth(fcsMonth);
            return setLoader(false)
        } catch (e) {
            console.error(e);
        }
    }

    const trData = [{'trtype': '0', trdescr: 'ХҮЛЭЭН АВАХ БҮРТГЭХ'},
        {'trtype': '1', trdescr: 'ӨРГӨТГӨЛ'}, {
        'trtype': '2',
        'trdescr': 'САЙЖРУУЛАЛТ'
    }, {
        'trtype': '3',
        'trdescr': 'ИХ ЗАСВАР '
    }, {'trtype': '4', 'trdescr': 'ДОТООДОД ШИЛЖҮҮЛЭХ '}
        , {'trtype': '5', 'trdescr': 'ДАХИН ҮНЭЛГЭЭНИЙ НЭМЭГДЭЛ  '}]

    const setFaTran = async () => {

        if (selectedFA.length === 0 || selectedFA === null) {
            notify("хөрөнгө хоосон байна", "warning");
        } else if (row.docnum == undefined || row.docnum === '' || Number(row.docnum) < 1) {
            notify("Падаан № хоосон байна", "warning");
        } else if (docDate === undefined || docDate === null) {
            notify("Огноо хоосон байна", "warning");
        } else if (custValue.length === 0) {
            notify("Харилцагч хоосон байна", "warning");
        } else if (row.descr === undefined || row.descr === '') {
            notify("Гүйлгээний утга хоосон байна", "warning");
        } else if (Number(row.quantity * row.cost_org) + Number(row.cost_cap) <= 0) {
            notify("Дүн хоосон байна", "warning");
        } else if (Number(row.quantity * row.cost_org) + Number(row.cost_cap) - Number(row.acder_amt) < 0) {
            notify("Үлдэх өртөг < 0 ", "warning");
        } else if (selectedChart.length === 0) {
            notify("Данс Кт хоосон байна", "warning");
        } else {
            let result = confirm("<i>Та орлогын гүйлгээ хийхдээ итгэлтэй байна уу?</i>", "Баталгаажуулалт");
            result.then(async (dialogResult) => {
                if (dialogResult && selectedFA && custValue && docDate) {

                    //yyyymm
                    const date = new Date(docDate);//new e.changes[0].data.docdate;
                    const year = date.getFullYear() * 1e4; // 1e4 gives us the the other digits to be filled later, so 20210000.
                    const month = (date.getMonth() + 1) * 100;
                    const yyyymm = year + month // `+ ''` to convert to string from number, 202206
                    const fcsCurrentMonth = dataFcsMonth.map((item) => {
                        let date = item.year + '/' + item.month
                        let datetimenew = new Date(date)
                        let year = datetimenew.getFullYear() * 1e4; // 1e4 gives us the the other digits to be filled later, so 20210000.
                        let month = (datetimenew.getMonth() + 1) * 100;
                        const yyyymm_month = year + month
                        return yyyymm_month
                    });
                    if (yyyymm === fcsCurrentMonth[0]) {
                        setLoader(true);
                        try {
                            let item = row;

                            item.docdate = docDate;
                            item.org_id = user.org_id;
                            item.created_user_id = user.id;
                            item.fa_id = selectedFA.id
                            item.fanumber = selectedFA.fa_number;
                            item.fa_name = selectedFA.name;

                            item.date_acq = selectedFA.date_acq;
                            item.manuf = selectedFA.manuf;
                            item.location = selectedFA.location;

                            item.user_dept = selectedFA.user_dept;
                            item.typename = selectedFA.fatype_model.name;
                            item.fatype = selectedFA.fatype_model.fatype;

                           item.trtype = selectedTr;
                            item.trdescr = trData[selectedTr].trdescr

                            item.acct_code = selectedFA.fatype_model.acct_code;
                            item.acder_acct = selectedFA.fatype_model.acder_acct;
                            item.cr_acct = selectedChart.acct;
                            item.cr_acct_id = selectedChart.id;

                            item.unit_meas = selectedFA.unit_model.name
                            item.unit_id = selectedFA.unit_model.id
                            //  item.cost_tot = Math.round(Number(row.cost_org) * Number(row.quantity) * 100) / 100
                            item.cust = custValue.orgcode;
                            item.custname = custValue.name;




                            item.updatedAt = null;

                            await fcsService.addFcsFaOr(item);
                            // setSelectedChart([]);
                            //   setDocDate(null);
                            setRow({...initRow})

                            setSelectedFA([]);
                            await fcsFaList();
                            setCountFaOr(countFaOr + 1)
                            notify('Амжилттай орлогын гүйлгээ хийгдлэээ', "success", 2000);
                            return setLoader(false)
                        } catch (e) {
                            console.log(e)
                            notify('Шинээр гүйлгээ хийх явцад алдаа гарлаа', "error", 2000);
                            return setLoader(false)
                        }


                    } else {
                        notify('Сонгосон огноо тухайн тайлант он сард биш байна', "error", 2000);
                        return setLoader(false)
                    }

                }
            });
        }
    }
    const onChangeCustValue = async (e) => {
        setCustValue(e);
    }
    const onChangeFA = async (e) => {

        setSelectedFA(e);

    }
    const onChangeTr = async (e) => {

         setSelectedTr(e);
    }


    const onChangeChart = async (e) => {
        setSelectedChart(e);
    }
    const onValueChangedDate = (e) => {
        setDocDate(e.value);
    }
    const onChangeRow = (e) => {
        const name = e.target.name;
        const value = Number(e.target.value) < 0 ? '' : e.target.value;

        setRow((old) => ({
            ...old, [name]: value
        }))
    }
    const style = {
        text: {
            color: "#2E4765", fontWeight: "700", fontSize: ".875rem"
        },
        box: {
            border: "none", backgroundColor: "#F6F6F6", borderRadius: ".5rem", padding: ".25rem .50rem", width: "100%"
        },
        box2: {
            border: "none",
            backgroundColor: "rgb(239, 239, 239)",
            borderRadius: ".5rem",
            padding: ".25rem .4rem",
            width: "100%"
        }
    }
    return (
        <div style={{padding: "0.5rem"}}>
            <LoadPanelComponent
                shadingColor="rgba(0,0,0,0.4)"
                position={userListPosition}
                visible={loader}
                message=''
                showPane={false}
            />
            <div className='row' style={{display: 'flex', justifyContent: "center", marginBottom: 10, marginLeft: 10}}>
                <div style={{marginLeft: 20, textTransform: "uppercase", marginTop: 10, ...style.text}}><h4> Үндсэн
                    хөрөнгийн орлого </h4></div>
            </div>
            <div><span style={{marginLeft: 15, color: "#A3A3A3", marginRight: "1rem"}}>Тайлант он</span> <span
                style={{fontWeight: "700"}}> {yearValue} сар {monthValue}</span></div>

            <div className="row" style={{display: 'flex', marginTop: 15}}>
                <div className='col-2' style={{marginLeft: 10, marginRight: 5,}}>
                    <div style={{display: 'flex', alignItems: 'center', ...style.text}}>Падаан №</div>
                    <input type='number' className='law-search-input'

                           name='docnum'
                           onChange={(e) => onChangeRow(e)}
                           value={row.docnum}

                           style={{marginTop: 10, ...style.box}}
                    />
                </div>


                <div className='col-3' style={{marginLeft: 10, marginRight: 5}}>
                    <div style={{display: 'flex', alignItems: 'center', marginBottom: 10, ...style.text}}>Гүйлгээний
                        төрөл
                    </div>
                    <SelectBox
                        items={trData}
                        defaultValue={'0'}
                        valueExpr='trtype'
                        searchEnabled={true}
                        style={{...style.box}}
                        displayExpr={(item) => {
                            return item && `${item.trdescr}` }}
                        onValueChanged={(e) => onChangeTr(e.value )}
                        placeholder="сонгох"/>
                </div>


                <div className='col-5' style={{marginLeft: 10}}>
                    <div style={{display: 'flex', alignItems: 'center', marginBottom: 10, ...style.text}}>Гүйлгээний
                        утга
                    </div>
                    <input type='string' className='law-search-input'
                           name='descr'
                           value={row.descr} onChange={(e) => onChangeRow(e)}
                           style={{...style.box}}

                    />
                </div>


            </div>

            <div className="row" style={{display: 'flex', marginTop: 20}}>
                <div className='col-3' style={{marginLeft: 10, marginRight: 5}}>
                    <div style={{display: 'flex', alignItems: 'center', marginBottom: 10, ...style.text}}>Огноо:</div>
                    <DateBox value={docDate} type="date" style={{...style.box}}
                             onValueChanged={onValueChangedDate}/>
                </div>
                <div className='col-6' style={{marginLeft: 20, marginRight: 10}}>
                    <div style={{display: 'flex', alignItems: 'center', marginBottom: 10, ...style.text}}>Дугаар</div>
                    <SelectBox
                        items={dataFcsFA}
                        value={selectedFA}
                        searchEnabled={true}
                        style={{...style.box}}
                        displayExpr={(item) => {
                            return item && `${item.fa_number} - ${item.name}`
                        }}
                        onValueChanged={(e) => onChangeFA(e.value)}
                        placeholder="сонгох"/>
                </div>

            </div>
            <div className='row' style={{display: 'flex', marginTop: 20}}>
                <div className='col-3' style={{marginLeft: 10, marginRight: 10,}}>
                    <div style={{display: 'flex', alignItems: 'center', ...style.text}}>Хэмжих нэгж</div>
                    <input value={selectedFA.unit_model && selectedFA.unit_model.name} type='string'
                           className='law-search-input'
                           style={{marginTop: 10, ...style.box2}}
                           disabled={true}/>

                </div>
                <div className='col-3' style={{marginLeft: 15, marginRight: 10,}}>
                    <div style={{display: 'flex', alignItems: 'center', ...style.text}}>Данс</div>
                    <input value={selectedFA.fatype_model && selectedFA.fatype_model.acct_code} type='string'
                           className='law-search-input'
                           style={{marginTop: 10, ...style.box2}} placeholder='данс'
                           disabled={true}/>

                </div>
                <div className='col-3' style={{marginLeft: 10, marginRight: 10,}}>
                    <div style={{display: 'flex', alignItems: 'center', ...style.text}}>Хур/эл данс</div>
                    <input value={selectedFA.fatype_model && selectedFA.fatype_model.acder_acct} type='string'
                           className='law-search-input'
                           style={{marginTop: 10, ...style.box2}}
                           disabled={true}/>

                </div>
            </div>
            <div className="row" style={{display: 'flex', marginTop: 10}}>
                <div className='col-3' style={{marginLeft: 10, marginRight: 10,}}>
                    <div style={{display: 'flex', alignItems: 'center', ...style.text}}>Тоо</div>
                    <input type='number' className='law-search-input' placeholder='Тоо'
                           style={{marginTop: 10, ...style.box}}
                           name='quantity'
                           onChange={(e) => onChangeRow(e)}
                           value={row.quantity}/>
                </div>
                <div className='col-3' style={{marginLeft: 15, marginRight: 10}}>
                    <div style={{display: 'flex', alignItems: 'center', ...style.text}}>Нэгж үнэ</div>
                    <input type='number' className='law-search-input' placeholder='Нэгж үнэ'
                           style={{marginTop: 10, ...style.box}}
                           name='cost_org'
                           onChange={(e) => onChangeRow(e)}
                           value={row.cost_org}/>
                </div>
                <div className='col-3' style={{marginLeft: 15, marginRight: 10}}>
                    <div style={{display: 'flex', alignItems: 'center', ...style.text}}>Бүгд үнэ</div>

                    <div style={{marginTop: 15, marginRight: 10, ...style.box2}}>
                        {Number(parseFloat((Number(row.cost_org * row.quantity)).toString()).toFixed(2)).toLocaleString("en", {minimumFractionDigits: 2,})}

                    </div>
                </div>
            </div>

            <div className="row" style={{display: 'flex', marginTop: 10}}>
                <div className='col-4' style={{marginLeft: 10, marginRight: 10,}}>
                    <div style={{display: 'flex', alignItems: 'center', ...style.text}}>Капиталжуулах/нэмэгдүүлэх дүн</div>
                    <input type='number' className='law-search-input' placeholder='Тоо'
                           style={{marginTop: 10, ...style.box}}
                           name='cost_cap'
                           onChange={(e) => onChangeRow(e)}
                           value={row.cost_cap}/>
                </div>


                <div className='col-4' style={{marginLeft: 10, marginRight: 10,}}>
                    <div style={{display: 'flex', alignItems: 'center', ...style.text}}>Элэгдэл</div>
                    <input type='number' className='law-search-input' placeholder='Элэгдэл'
                           style={{marginTop: 10, ...style.box}}
                           name='acder_amt' value={row.acder_amt} onChange={(e) => onChangeRow(e)}/>
                </div>
                <div style={{marginLeft: 10, marginRight: 10}}>
                    <div style={{display: 'flex', alignItems: 'center', ...style.text}}>Үлдэх өртөг</div>
                    <div style={{marginTop: 15, marginRight: 10}}>
                        {Number(parseFloat((Number(row.cost_org * row.quantity) + Number(row.cost_cap) - Number(row.acder_amt)).toString()).toFixed(2)).toLocaleString("en", {minimumFractionDigits: 2,})}

                    </div>

                </div>


            </div>
            <div className="row" style={{display: 'flex', marginTop: 10}}>


            </div>


            <div className="row" style={{display: 'flex', marginTop: 10}}>
                <div className='col-4' style={{marginLeft: 10, marginRight: 10}}>
                    <div style={{display: 'flex', alignItems: 'center', marginBottom: 10, ...style.text}}>Бэлтгэн
                        нийлүүлэгч
                    </div>
                    <input type='string' className='law-search-input' placeholder='код'
                           style={{marginLeft: 0, width: 200}}
                           onClick={(e) => setModalVisibleCustOrg(true)}
                           value={custValue.orgcode + "-" + custValue.name}
                    />

                </div>

                <div className='col-4' style={{marginLeft: 10, marginRight: 10}}>
                    <div style={{alignItems: 'center', marginBottom: 10, ...style.text}}>Данс кредит</div>
                    <SelectBox
                        items={dataFcsChart}
                        value={selectedChart}
                        searchEnabled={true}
                        style={{...style.box}}
                        displayExpr={(item) => {
                            return item && `${item.acct} - ${item.descr}`
                        }}
                        onValueChanged={(e) => onChangeChart(e.value)}
                        placeholder="сонгох"/>
                </div>


            </div>


            <div>
                <Popup
                    width={500}
                    height={500}
                    minWidth={'80%'}
                    maxWidth={'80%'}
                    minHeight={'80%'}
                    visible={modalVisibleCustOrg}
                    title={'Байгууллага хувь хүмүүс сонгох'}
                    onHiding={() => setModalVisibleCustOrg(false)}
                    resizeEnabled={true}
                    dragEnabled={true}
                    showTitle={true}

                >
                    <CustorgParent setCustValue={setCustValue} setModalVisibleCustOrg={setModalVisibleCustOrg}/>

                </Popup>
            </div>

            <div style={{
                display: 'flex',
                justifyContent: 'flex-start',
                marginTop: 20,
                marginBottom: 20,
                marginLeft: 10
            }}>
                <Button className="saveButton"
                        text="Хадгалах"
                        type="default"
                        onClick={() => setFaTran()}/>
            </div>

            <div className="card-body">
                <FaOrEdit yearValue={yearValue} monthValue={monthValue} countFaOr={countFaOr}/>
            </div>
        </div>
    )
}

export default FaOr;

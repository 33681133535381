import React from "react"
import styled from "styled-components"

const Container = styled.div`
  display: flex;
  border-radius: 1rem;
  position: relative;
  &::after {
    content: "";
    position: absolute;
    height: 2px;
    width: 100%;
    bottom: 0;
    background-color: #E8EDF6;
    transition: all 0.3s cubic-bezier(.075,.82,.165,1);
    z-index: 0;
  }
  .underline {
    position: absolute;
    height: 2px;
    width: 50%;
    left: ${(props) => (props.isOrgShown ? "0%" : "50%")};
    bottom: 0;
    background-color: black;
    transition: all 0.3s cubic-bezier(.075,.82,.165,1);
    z-index: 1;
  }
  .line {
    position: absolute;
    height: 3px;
    width: 100%;
    bottom: 0%;
    left: 0;
    background-color: gray;
    transition: all 0.3s cubic-bezier(.075,.82,.165,1);;
  }
`
const TabItemContainer = styled.div`
  padding: 1rem;
  width: 50%;
  font-size: 1rem;
  text-align: center;
  text-transform: uppercase;
  transition: all 0.3s ease;
  color: ${(props) =>
    props.isOrg === props.isOrgShown ? "black" : "rgba(0, 0, 0, 0.45)"};
  font-weight: 700;
  cursor: pointer;
  &:active {
    background-color: #0037962c;
  }
`
function TabComponent({isOrgShown, setIsOrgShown, children}) {
  return (
    <Container isOrgShown={isOrgShown}>
      {children?.map((item, index) => (
        <TabItem
          key={index}
          setIsOrgShown={setIsOrgShown}
          isOrgShown={isOrgShown}
          {...item}
        />
      ))}
      <div className="underline" />
    </Container>
  )
}

export default TabComponent

function TabItem({isOrgShown, title, setIsOrgShown, isOrg}) {
  return (
    <TabItemContainer
      isOrg={isOrg}
      isOrgShown={isOrgShown}
      onClick={() => setIsOrgShown(isOrg)}
    >
      <div className="title">{title}</div>
    </TabItemContainer>
  )
}

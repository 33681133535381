/*eslint-disable*/
import React, {useRef} from "react";
import 'devextreme-react/text-area';
import Button from "devextreme-react/button";
import ReactToPrint from "react-to-print";

const BB02 = (props) => {
    const componentRef = useRef();
    const docno = props.docno;
    const docDate = props.docDate;
    const orgName = props.orgName
    const custName = props.custName
    const descr = props.descr
    const amt = props.amt
    const strAmt = props.strAmt

    const style = {
        text: {
            color: "#2E4765", fontWeight: "700", fontSize: ".875rem"
        },
        box: {
            border: "none", backgroundColor: "#F6F6F6", borderRadius: ".5rem", padding: ".25rem .15rem", width: "100%"
        },
        box2: {
            border: "none",
            backgroundColor: "rgb(239, 239, 239)",
            borderRadius: ".5rem",
            padding: ".25rem .4rem",
            width: "100%"
        }
    }


    return (
        <div>
        <div style={{display: "flex", justifyContent: "space-evenly", padding: "1.5rem", flexDirection: "column",}}>
            <div className="row" style={{display: 'flex', marginTop: 30, gap: "1rem"}}>

                <Button type=""
                        icon='save'
                        style={{color: "white"}}
                        className="saveButton" onClick={() => props.setReportShow(false)}> Буцах </Button>

                <ReactToPrint
                    trigger={() => <Button
                        type="default"
                        icon='save'
                        className="saveButton">Хэвлэх</Button>}
                    content={() =>
                        componentRef.current}
                />
            </div>
        </div>

        <div style={{justifyContent: "center", width: "100%", display: "flex"}}>
            <div className="react-to-print-template" ref={componentRef}>
                <div className="row" style={{justifyContent: "flex-start", marginBottom: "2rem"}}>
                    <div className="col-3">
                        <div style={{textAlign: 'left', fontWeight: 600}}>НХМаягт МХ-2</div>
                    </div>
                </div>
                <div className="row" style={{justifyContent: "center", marginBottom: "2rem"}}>
                    <div className="col-5">
                        <div style={{fontSize: '1rem', fontWeight: 600}}>Бэлэн мөнгөний
                            зарлагын баримт
                        </div>
                    </div>
                </div>
                <div className="row" style={{marginBottom: "1rem"}}>
                    <div className="col-6" style={{textAlign: "start"}}>
                        <span>Дугаар:</span>
                        <span>{docno}</span>
                    </div>
                    <div className="col-6" style={{textAlign: "end"}}>
                        <span>Огноо:</span>
                        <span>{docDate}</span>
                    </div>
                </div>
                <div className="row">
                    <div className="col-3">
                        <span >Байгууллагын нэр:</span>
                    </div>
                    <div className="col-9" style={{textAlign: "center", overflow: "hidden"}}>
                        <span style={{fontWeight: 600}}>  {orgName? orgName: "………………………………………………………………………………………………………	"}  </span>
                    </div>
                </div>
                <div className="row" style={{marginBottom: "1rem"}}>
                    <div className="col-3"/>
                    <div className="col-9">
                        <span>хүлээн авагчийн</span>
                    </div>
                </div>
                <div className="row" style={{marginBottom: "2rem"}}>
                    <div className="col-3">
                         Мөнгө хүлээн авагч
                    </div>
                    <div className="col-3"  style={{textAlign: "center", overflow: "hidden"}}>
                        …………………………………………………………………………………………………… 
                    </div>
                    <div className="col-1">
                        овогтой
                    </div>
                    <div className="col-4"  style={{textAlign: "center", overflow: "hidden"}}>
                        {custName? custName: "……………………………………………………………………………………………………"}
                    </div>
                    <div className="col-1">
                        - нээс
                    </div>
                </div>
                <div className="row" style={{marginBottom: descr? "2rem" : "0rem"}}>
                    <div className="col-2">
                        Гүйлгээний утга
                    </div>
                    <div className="col-10" style={{textAlign: "center", overflow: "hidden"}}>
                        <span style={{fontWeight: descr && 600}}>{descr? descr: "……………………………………………………………………………………………………"}</span>
                    </div>
                </div>
                {!descr && (
                    <>
                        <div className="row">
                            <div className="col-12" style={{textAlign: "left", overflow: "hidden"}}>
                                …………………………………………………………………………………………………………………………………………………………………………………………………………
                                
                            </div>
                        </div>
                        <div className="row" style={{marginBottom: "2rem"}}>
                            <div className="col-12" style={{textAlign: "left", overflow: "hidden"}}>
                                …………………………………………………………………………………………………………………………………………………………………………………………………………
                               
                            </div>
                        </div>
                    </>
                )
                }
                <div className="row" style={{marginBottom: "1rem"}}>
                    <div className="col-4" >
                        Олгосон мөнгөний дүн
                    </div>
                    <div className="col-8">
                    {Number(parseFloat(Number(amt).toString()).toFixed(2)).toLocaleString("en", {minimumFractionDigits: 2,})}
                    </div>
                </div>
                <div className="row" style={{marginBottom: "2rem"}}>
                    <div className="col-3">
                        Хавсаргасан баримт
                    </div>
                    <div className="col-9" style={{textAlign: "left", overflow: "hidden"}}>
                    …………………………………………………………………………………………………………………………………………………………………………………………………………
                    </div>
               </div>
               <div className="row" style={{justifyContent: "center", marginBottom: "1rem"}}>
                    <div className="col-10">
                    Захирал/Дарга ……………………………………/……...…………………/
                    </div>
               </div>
               <div className="row" style={{justifyContent: "center", marginBottom: "1rem"}}>
                    <div className="col-10">
                    Нягтлан бодогч ……………………………………/……...…………………/
                    </div>
               </div>
               <div className="row" style={{justifyContent: "center", marginBottom: "1rem"}}>
                    <div className="col-10">
                    Хүлээлгэн өгсөн …………………………………/……...…………………/
                    </div>
               </div>
               <div className="row" style={{justifyContent: "center", marginBottom: "1rem"}}>
                    <div className="col-10">
                    Мөнгө тушаагч ……………………………………/……...…………………/
                    </div>
               </div>

                </div>
            </div>
        </div>
    );
};

export default BB02
import React, {useState, useEffect} from "react";
import notify from "devextreme/ui/notify";
import BudgetService from "../../../../services/api/budget";
import Chart, {CommonSeriesSettings, Label, Legend, Series, Tooltip, ValueAxis} from "devextreme-react/chart";
import {numberWithCommas} from "../../../../util";
import LoadPanelComponent from "../../../budget/components/loadPanel";
import _ from "lodash";

const PopupDetailEconCatByYear = (props) => {
    const [dataSource, setDataSource] = useState([]);
    const [spinner, setSpinner] = useState(false);

    const loadData = async () => {
      try {
          setSpinner(true);
          let r = await BudgetService.getPivotDetailedData(props.tier, props.searchText,props.econType,props.org_id);
          let chartData = _(r).groupBy('budget_year')
              .map((row, key) => {
                  return {
                      budget_year: key,
                      amount: _.sumBy(row, 'amount'),
                      guitsetgel: _.sumBy(row, 'guitsetgel')
                  }
              }).value()
          setDataSource(chartData);
          setSpinner(false)
      } catch (e) {
          setSpinner(false)
          notify(e.message, "error", 2000);
      }
    }

    useEffect(() => {
        loadData()
    }, [props.tier, props.searchText,props.econType,props.org_id])

    const customizeValueAxisLabel = (e) => {
        return `${e.value / 1000000000}`
    }

    const customizeTooltip = (arg) => {
        if (arg.seriesName === 'Төсвийн өөрчлөлт') {

            let r = _.find(dataSource, {'budget_year': (arg.argument * 1 - 1).toString()});
            let q = ((arg.value - (r && r.amount) > 0 ? arg.value / (r && r.amount) : (r && r.amount) / arg.value)).toFixed(2);
            let p = ((arg.value * 100 / (r && r.amount)) - 100).toFixed(2);
            let d = (arg.value - (r && r.amount));
            let f = d < 0 ? 'бага' : 'их';

            if (isFinite(q) && !isNaN(q)) {
                return {
                    text: `Төсөв ${arg.argumentText} он\n Өмнөх жилээс\n\n Зөрүү: ${numberWithCommas(d / 1000000)}\n Хувь: ${p}%\n Өөрчлөлт: ${q} дахин ${f}`
                }
            } else {
                return {
                    text: `Тооцоолох боломжгүй`
                }
            }
        }
        else if  (arg.seriesName === 'Гүйцэтгэлийн өөрчлөлт'){
            let r = _.find(dataSource, {'budget_year': (arg.argument * 1 - 1).toString()});
            let q = ((arg.value - (r && r.guitsetgel) > 0 ? arg.value / (r && r.guitsetgel) : (r && r.guitsetgel) / arg.value)).toFixed(2);
            let p = ((arg.value * 100 / (r && r.guitsetgel)) - 100).toFixed(2);
            let d = (arg.value - (r && r.guitsetgel));
            let f = d < 0 ? 'бага' : 'их';
            let r1 = _.find(dataSource, {'budget_year': (arg.argument * 1).toString()});
            let u = ((r1 && r1.amount) - arg.value);
            let u1 = ((arg.value * 100 / (r1 && r1.amount))).toFixed(2);
            if (isFinite(q) && !isNaN(q)) {
                return {
                    text: `Гүйцэтгэл ${arg.argumentText} он\n \nЗөрүү: ${numberWithCommas(u / 1000000)}\n Xувь: ${u1}%\n--------------------\n Өмнөх жилээс\n Зөрүү: ${numberWithCommas(d / 1000000)}\n Хувь: ${p}%\n Өөрчлөлт: ${q} дахин ${f}\n `
                }
            } else {
                return {
                    text: `Тооцоолох боломжгүй`
                }
            }
        }
        else {
            return {
                text: `${arg.seriesName}: ${numberWithCommas(arg.value / 1000000)}`
            };
        }
    }

    return (
        <div className="card" style={{ flex: 1 }}>
            <LoadPanelComponent position={{ of: '#spinner' }} visible={spinner}/>
            <div className="card-header">
                <span>ТӨСӨВ, ГҮЙЦЭТГЭЛ /жилээр, сая ₮/</span>
            </div>
            <div className="card-body">
                <Chart
                    palette={props.colorPalette}
                    paletteExtensionMode="alternate"
                    dataSource={dataSource}
                >
                    <CommonSeriesSettings argumentField="budget_year" type="bar" barOverlapGroup="perfStack" barPadding={0.5}>
                        <Label visible={false}/>
                    </CommonSeriesSettings>
                    <ValueAxis>
                        <Label customizeText={customizeValueAxisLabel}/>
                    </ValueAxis>
                    <Series valueField="amount" name="Төсөв" stack="perfStack" barPadding={0.3}/>
                    <Series valueField="amount" name="Төсвийн өөрчлөлт" color="red" type="spline" dashStyle="dash"/>
                    <Series valueField="guitsetgel" name="Гүйцэтгэл" stack="perfStack"/>
                    <Series valueField="guitsetgel" name="Гүйцэтгэлийн өөрчлөлт" type="spline" color="#264b96" dashStyle="dash"/>
                    <Legend visible={true} verticalAlignment="bottom" horizontalAlignment="center"/>
                    <Tooltip zIndex={100000} enabled={true} customizeTooltip={customizeTooltip} font={{ family: "'Montserrat', sans-serif" }} location="edge"/>
                </Chart>
            </div>
        </div>
    )
}

export default PopupDetailEconCatByYear;

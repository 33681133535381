import React, {useEffect, useRef, useState, useContext} from 'react'
import DropDownBox from 'devextreme-react/drop-down-box';
import TreeView,{SearchEditorOptions} from "devextreme-react/tree-view";
import orgPositionServices from "../services/api/orgPosition";
import SelectBox from "devextreme-react/select-box";
import {UserContext} from "../hooks/UserContext";

const OrganizationSelectComponent = (props) => {
    const {user} = useContext(UserContext);
    const treeView = useRef(null);
    const [treeBoxValue, setTreeBoxValue] = useState(null);
    const [isParent, setIsParent] = useState(false);
    const [orgName, setOrgName]= useState(null)
    const [orgList, setOrgList] = useState([]);
    const [yearList] = useState(Array.from({ length: 5 }, (_, i) => new Date().getFullYear() - i));
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
    const [disabled, setDisabled] = useState(false);
    const [showClearButton, setShowClearButton] = useState(false);
    const [showYear, setShowYear] = useState(false);

    useEffect(() => {
        getOrgList()
        // console.log("props are", props)
    }, [props.type,props.year])

    useEffect(() => {
        setSelectedYear(props.selectedYear)
    }, [props.selectedYear])

    useEffect(() => {
        setShowYear(props.showYear)
    }, [props.showYear])

    useEffect(() => {
        props.selectedYear(selectedYear)
    }, [selectedYear])

    useEffect(() => {
        setShowClearButton(props.showClearButton)
    }, [props.showClearButton])

    useEffect(() => {
        if (treeBoxValue && treeBoxValue.length> 0) {
            props.selectedData({
                orgId: treeBoxValue,
                isParent,
                orgName 
            });
        }
    }, [treeBoxValue])

    const getOrgList = async () => {
        const r = await orgPositionServices.getOrganizationList(props.type,props.year);
        let result = r.data || [];
        if (result.length === 1) {
            result[0].parent = null;
            setDisabled(true)
        } else {
            let obt_id = user && user.organization && user.organization.obt_id;
            if (obt_id === 2) {
                result.filter(data => data.code === '')
                    .forEach(data => data.parent = null)
            }
        }

        let userOrgID = user && user.organization && user.organization.id;
        setOrgName(props.defaultAuthor ? props.defaultAuthor : user.organization.name)
        setTreeBoxValue([props.defaultId!== null ? props.defaultId : userOrgID])
        setOrgList(result)
    }

    const treeItem = (item) => {
        return <div>
            <span>{item.organization_name}</span>
            <br/>
            <span style={{color: 'darkgray', "fontSize": 12}}>{item.aimag_name}</span>
        </div>
    }

    const treeViewRender = () => {
        return <TreeView dataSource={orgList}
                 ref={treeView}
                 dataStructure="plain"
                 keyExpr="id"
                 parentIdExpr="parent" noDataText={'Мэдээлэл хоосон байна'}
                 selectionMode="single"
                 searchEnabled={true}
                 showCheckBoxesMode="normal"
                 displayExpr={item => (item.organization_name)}
                 itemRender={treeItem}
                 selectByClick={true}
                 onContentReady={syncTreeViewSelection}
                 onItemSelectionChanged={treeViewItemSelectionChanged}
        >
            <SearchEditorOptions
                placeholder="Байгууллага хайх утга энд оруулна уу"
            />
        </TreeView>
    }

    const treeViewItemSelectionChanged = (e) => {
        setIsParent(e.itemData.code === '')
        setOrgName(e.itemData.organization_name)
        setTreeBoxValue(e.component.getSelectedNodeKeys())
    }

    const syncTreeViewSelection = (e) => {
        const tView = (e.component.selectItem && e.component)
            || (treeView.current && treeView.current.instance);
        if (tView) {
            if (e.value === null) {
                tView.unselectAll();
            } else {
                const values =e.value || treeBoxValue;
                tView.selectItem(values)
            }
        }

        if (e.value !== undefined) {
            setTreeBoxValue(e.value)
        }
    }

    const onYearValueChanged = (e) => {
        setSelectedYear(e.value || new Date().getFullYear())
    }

    return <div className={'card'} style={{border: "none"}}>
        <div className={'card-body'}>
            <div style={{display: 'flex', "columnGap": 10}}>
                <div style={{display: 'flex', "alignItems": 'center', "columnGap": 10}}>
                    {props.hideLabel ? "" : <label>Байгууллага:</label>}
                    <DropDownBox
                        value={treeBoxValue}
                        valueExpr="id"
                        displayExpr={(item) => {
                            return item && `${item.organization_name}`
                        }}
                        placeholder="сонгох" label={props.labelMode ==='floating' ? "Байгууллага" : ""} labelMode={props.labelMode ==='floating' ? 'floating' : 'static'}
                        disabled={disabled}
                        showClearButton={showClearButton}
                        dataSource={orgList}
                        onValueChanged={syncTreeViewSelection}
                        contentRender={treeViewRender}
                        style={{width: props.width ? props.width : 400}}
                    />
                </div>
                {showYear &&
                    <div style={{display: 'flex', "alignItems": 'center', "columnGap": 10}}>
                        <label>Он:</label>
                        <SelectBox
                            items={yearList}
                            value={selectedYear}
                            onValueChanged={onYearValueChanged}
                            placeholder="сонгох"/>
                    </div>
                }
            </div>
        </div>
    </div>
}

export default OrganizationSelectComponent;

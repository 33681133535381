import React, {useState, useEffect} from "react";
import DataGrid, {Column, FilterRow, HeaderFilter, Paging, Summary, TotalItem,Selection,Toolbar,Item} from "devextreme-react/data-grid";
import notify from "devextreme/ui/notify";
import FinanceReportService from "../../../../services/api/financeReport";
import {numberWithCommas} from "../../../../util";
import LoadPanelComponent from "../../../../components/LoadPanelComponent";
import _ from "lodash";
import { Button } from 'devextreme-react/button';
import EmptyData from "../../../budget/components/empty";
const ListWithOrgs = (props) => {
    const [dataSrc, setDataSrc] = useState([]);
    const [loader, setLoader] = useState(false);
    const [sumTotalAsset, setSumTotalAsset] = useState(0);
    const [sumDeptAll, setSumDeptAll] = useState(0);
    const [sumTotalResult, setSumTotalResult] = useState(0);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const loadYamsData = async () => {
        try {
            setLoader(true);
            let r = await FinanceReportService.getYamsData(props.year, props.reportType, props.filterOrgText);
            let sum = _.sumBy(r, 'total_asset');
            setSumTotalAsset(sum);
            let sum1 = _.sumBy(r, 'dept_all');
            setSumDeptAll(sum1);
            let sum2 = _.sumBy(r, 'total_result');
            setSumTotalResult(sum2);
            setDataSrc(r);
            setLoader(false);
        } catch (e) {
            setLoader(false);
            notify(e.message, "error", 2000);
        }
    }

    const cellTemp = (cellElement, cellInfo) => {
        return cellElement.append(cellInfo.row.loadIndex + 1);
    }
    const onSelectionChanged = (e) => {
        setSelectedRowKeys(e.selectedRowKeys);
        props.selectedOrg(e.selectedRowsData[0]);
    }
    useEffect(() => {
        loadYamsData();
    }, [props.year, props.reportType, props.filterOrgText])
    const onClearButtonClicked =()=> {
        setSelectedRowKeys([]);
        props.selectedOrg(undefined);
       // this.dataGrid.instance.clearSelection();
    }
    const onCellPreparedHandler = (e) => {
        if (e.rowType === 'header') {
            e.cellElement.wordWrapEnabled = true;
            e.cellElement.style.color = "#0c0c0c"
            e.cellElement.style.backgroundColor = "#E8E8E8"
            e.cellElement.style.fontWeight = "bold";
        }
        if (e.rowType === 'data') {
            e.cellElement.style.color = "#2c2c2c"
        }
    }
    return (
        <div id="load1">
            <LoadPanelComponent
                position={{of: '#load1'}}
                visible={loader}
            />
            {(dataSrc && !loader) ? (
            <DataGrid
                showBorders={true}
                rowAlternationEnabled={true}
                dataSource={dataSrc}
                noDataText = 'Дата байхгүй байна.'
                onSelectionChanged={onSelectionChanged}
                onCellPrepared={onCellPreparedHandler}
                style={{ fontFamily: "'Montserrat', sans-serif", fontWeight: 'bold' }}
            >
                <Paging defaultPageSize={9}/>
                <FilterRow visible={true}/>
                <HeaderFilter visible={true}/>
                <Selection mode="single"/>
                <Column caption="№" allowSorting={false} cellTemplate={cellTemp} alignment="center" width={40}/>
                <Column dataField="name" allowSorting={false} caption="Нэр" alignment="left" minWidth={100}/>
                <Column dataField="total_asset" visible={sumTotalAsset !==0 ? true : false} allowSorting={true} format="#,##0.00" defaultSortOrder="desc" alignment="center" caption="НИЙТ ХӨРӨНГӨ" allowFiltering={false} width={200}/>
               {/* <Column dataField="dept_all" visible={sumDeptAll !==0 ? true : false} allowSorting={true} format="#,##0.00" defaultSortOrder="desc" alignment="center" caption="ӨР ТӨЛБӨР, ЦЭВЭР ХӨРӨНГӨ/ӨМЧИЙН ДҮН/сая ₮/" allowFiltering={false} width={100}/>*/}
                <Column dataField="total_result" visible={sumTotalResult !==0 ? true : false} allowSorting={true} format="#,##0.00" defaultSortOrder="desc" alignment="center" caption={props.reportType ===3 ? "НИЙТ ЦЭВЭР МӨНГӨН ГҮЙЛГЭЭ" : props.reportType ===4 ? "ЗГ-ын хувь оролцооны нийт дүн" : "НИЙТ ҮР ДҮН (VII=III+VI)"}  allowFiltering={false} width={200}/>
                <Summary>
                    <TotalItem
                        column="total_asset"
                        summaryType="sum"
                        customizeText={(data) => {return `${numberWithCommas(data.value)}`}}
                    />
                    <TotalItem
                        column="dept_all"
                        summaryType="sum"
                        customizeText={(data) => {return `${numberWithCommas(data.value)}`}}
                    />
                    <TotalItem
                        column="total_result"
                        summaryType="sum"
                        customizeText={(data) => {return `${numberWithCommas(data.value)}`}}
                    />
                </Summary>
                <Toolbar>
                    <Item location="before">
                        <Button
                            disabled={!selectedRowKeys.length}
                            onClick={onClearButtonClicked}
                            style={{
                                flex: 1,
                                color: '#0c0c0c', textAlign: "center",family: "'Montserrat', sans-serif",
                                // backgroundImage: `linear-gradient(to right, ${paletteFinanceReport[1]}, ${paletteFinanceReport[0]})`,
                            }}
                            icon={selectedRowKeys.length>0 ? 'clear' : 'check'}
                            text={selectedRowKeys.length>0 ? "Сонгосон байгууллага арилгах" : "Доорх жагсаалтаас байгууллага сонгох"}
                        />
                    </Item>
                </Toolbar>
            </DataGrid>
                ):
                <div style={{display: "flex", justifyContent: "center", alignItems: "center", family: "'Montserrat', sans-serif" }}>
                    <EmptyData/>
                </div>
            }
        </div>
    )
}

export default ListWithOrgs;

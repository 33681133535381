import request from '../../shared/lib/request';

const uploadFile = (body, typeId) => {
    return request({
        url: '/fileServices/uploadFile?typeId='+typeId,
        method: 'POST',
        data: body,
    })
}
const uploadFileManagement = (body, fileTypeId) => {
    if (fileTypeId) {
        return request({
            url: '/fileServices/uploadFileManagement?file_type_id=' + fileTypeId,
            method: 'POST',
            data: body,
            headers: {
                "Content-Type": "multipart/form-data"
            }
        })
    } else {
        return request({
            url: '/fileServices/uploadFileManagement',
            method: 'POST',
            data: body,
            headers: {
                "Content-Type": "multipart/form-data"
            }
        })
    }
}
const uploadFileLaws = (body, typeId) => {
    return request({
        url: '/fileServices/uploadFileLaws?typeId='+typeId,
        method: 'POST',
        data: body,
    })
}
const uploadFileStructure = (body, typeId, org_id) => {
    return request({
        url: `/fileServices/uploadFileStructure?typeId=${typeId}&org_id=${org_id}`,
        method: 'POST',
        data: body,
    })
}

const uploadSingleFile = (body, typeId) => {
    return request({
        url: `/fileServices/uploadSingleFile?typeId=${typeId}`,
        method: 'POST',
        data: body,
    })
}
const uploadMultipleNewsFiles = (body, id, type) => {
    return request({
        url: `/fileServices/uploadMultipleNewsFiles/${id}/${type}`,
        method: 'POST',
        data: body,
    })
}
const uploadMultiplePortalFiles = (body, id, type) => {
    return request({
        url: `/fileServices/uploadMultiplePortalFiles/${id}/${type}`,
        method: 'POST',
        data: body,
    })
}
const uploadPortalStateStructureFiles = (body, orgId, type, hasChildren) => {
    return request({
        url: `/fileServices/uploadPortalStateStructureFiles/${orgId}/${type}/${hasChildren}`,
        method: 'POST',
        data: body,
    })
}
const uploadImage = (body, config) => {
    const {width, height} = config;
    // console.log(width, height, 'width and height')
    return request({
        url: `/fileServices/uploadImage/${width}/${height}`,
        method: 'POST',
        data: body,
    })
}
const uploadBannerPicture= (body, org_id)=>{
    return request({
        url: `/fileServices/uploadBannerPicture?id=${org_id}`,
        method: "POST",
        data: body,
    })
}

const getAllFilesFromUpload = () => {
    return request({
        url: '/fileServices/getAllFilesFromUpload',
        method: 'GET',
    })
}

const getFileById = (id) => {
    return request({
        url: `/fileServices/getFile/${id}`,
        method: 'GET',
    })
}

const getFileByFileName = (path) => {
    return request({
        url: `/fileServices/getFileByFileName/${path}`,
        method: 'GET',
    })
}

const renameFile = (old_name, new_name) => {
    return request({
        url: `/fileServices/renameFile/${old_name}/${new_name}`,
        method: 'GET',
    })
}

const deleteFile = (file_path) => {
    return request({
        url: `/fileServices/deleteFile/${file_path}`,
        method: 'DELETE',
    })
}

const fileSystem = () => {
    return request({
        url: `/fileService/fileSystem`,
        method: 'GET'
    })
}

const retrieveFileSystemByDir = (pathname) => {
    return request({
        url: `/fileService/retrieveFileSystemByDir?pathname=${pathname}`,
        method: 'GET'
    })
}

const setFileWorker= (body)=>{
    return request({
        url: `/fileServices/setFileWorker`,
        method: "POST",
        data: body,
    })
}
const setFilePosition = (body) => {
    return request({
        url:`/fileServices/setFilePosition`,
        method: "POST",
        data: body,
    })
}

const setFileBudget= (body)=>{
    return request({
        url: `/fileServices/setFileBudget`,
        method: "POST",
        data: body,
    })
}

const setFileLaw= (body)=>{
    return request({
        url: `/fileServices/setFileLaw`,
        method: "POST",
        data: body,
    })
}

const getFileByType = (typeId) => {
    return request({
        url: `/fileService/getFileByType/` + typeId,
        method: 'GET'
    })
}
const getFileNameByType = (typeId,userId) => {
    return request({
        url: `/fileService/getFileNameByType/` + typeId + '/' + userId,
        method: 'GET'
    })
}
const updateFile= (body)=>{
    return request({
        url: `/fileServices/updateFile`,
        method: "POST",
        data: body,
    })
}
const updateFileType = (body) => {
    return request({
        url: `/fileServices/updateFileType`,
        method: "POST",
        data: body,
    })
}
const deletePortalStructureFile = (file_id) =>{
    return request({
        url: `/fileServices/deletePortalStructureFile/${file_id}`,
        method: "DELETE",
    })
}
function changeFileRole(newFileInfo) {
    return request({
        url: '/fileServices/updateFileRole',
        data: {...newFileInfo},
        method: 'PUT'
    })
}

const uploadRequestFiles = (requestId, body) => {
    // console.log("uploading with body", body)
    return request({
        url: `/fileServices/uploadRequestFiles/${requestId}`,
        method: 'POST',
        data: body,
    })
}

const uploadBudgetProjectFiles = (pId, body) => {
    // console.log("uploading with body", body)
    return request({
        url: `/fileServices/uploadBudgetProjectFiles/${pId}`,
        method: 'POST',
        data: body,
    })
}

const getRequestFiles = (requestId) => {
    return request({
        url: `/fileServices/getRequestFiles/${requestId}`,
        method: 'GET',
    })
}

const updateRequestFile = (fileId, body) => {
    return request({
        url: `/fileServices/updateRequestFile/${fileId}`,
        method:'PUT',
        data:body
    })
}

const updateBudgetProjectFile = (id, body) => {
    return request({
        url: `/fileServices/updateBudgetProjectFile/${id}`,
        method:'POST',
        data:body
    })
}

const uploadBudgetCalcFiles = (body) => {
    return request({
        url:    `/fileServices/uploadBudgetCalcFile`,
        method: `POST`,
        data:   body
    })
}

const FileServices = {
    uploadFile,
    uploadFileManagement,
    getAllFilesFromUpload,
    getFileById,
    getFileByFileName,
    renameFile,
    uploadImage,
    deleteFile,
    fileSystem,
    retrieveFileSystemByDir,
    uploadBannerPicture,
    setFileWorker,
    setFilePosition,
    uploadFileStructure,
    uploadFileLaws,
    setFileLaw,
    setFileBudget,
    uploadSingleFile,
    uploadMultipleNewsFiles,
    getFileByType,
    getFileNameByType,
    updateFile,
    updateFileType,
    changeFileRole,
    uploadMultiplePortalFiles,
    uploadPortalStateStructureFiles,
    deletePortalStructureFile,
    uploadRequestFiles,
    getRequestFiles,
    updateRequestFile,
    uploadBudgetProjectFiles,
    updateBudgetProjectFile,
    uploadBudgetCalcFiles
};

export default FileServices;

import React, {useState, useEffect,useRef} from "react";
import {DataGrid, Column, FilterRow, HeaderFilter, Paging, Export} from "devextreme-react/data-grid";
import notify from "devextreme/ui/notify";
import FinanceReportService from "../../../../services/api/financeReport";
import LoadPanelComponent from "../../../../components/LoadPanelComponent";
import {Workbook} from 'exceljs';
import saveAs from 'file-saver';
import {Selection} from "devextreme-react/data-grid";
import {exportDataGrid} from "devextreme/excel_exporter";
import {formatDate} from "../../../../util";
import EmptyData from "../../../budget/components/empty";
const UmchiinUurchlultList = (props) => {
    const [dataSrc, setDataSrc] = useState([]);
    const [loader, setLoader] = useState(false);
    const myGridRef = useRef(null);
    const loadData = async () => {
        try {
            setLoader(true);
            let r = await FinanceReportService.getChartAccountList(props.year, props.reportType,props.selectedOrg, props.filterOrgText);
            setDataSrc(r);
            setLoader(false);
        } catch (e) {
            setLoader(false);
            notify(e.message, "error", 2000);
        }
    }

    useEffect(() => {
        loadData();
    }, [props.year, props.reportType, props.filterOrgText, props.selectedOrg])

    const onCellPreparedHandler = (e) => {
        if (e.rowType === 'header') {
            e.cellElement.wordWrapEnabled = true;
            e.cellElement.style.color = "#0c0c0c"
            e.cellElement.style.backgroundColor = "#E8E8E8"
            e.cellElement.style.fontWeight = "bold";
        }
        if (e.rowType === 'data') {
            e.cellElement.style.color = "#2c2c2c"
        }
    }

    const orderRender = (data) => {
        return <div style={{color: "#171010"}}>{data.rowIndex + 1}</div>;
    }
    const onExporting = (e) => {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Main sheet');

        exportDataGrid({
            component: e.component,
            worksheet,
            autoFilterEnabled: true,
        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer], {type: 'application/octet-stream'}), 'OG-ӨмчийнӨөрчлөлтТайлан-'+ formatDate(new Date()) + ".xlsx");
            });
        });
        e.cancel = true;
    }
    return (
        <div id="load1">
            <LoadPanelComponent
                position={{of: '#load1'}}
                visible={loader}
            />
            {(dataSrc && !loader) ? (
            <DataGrid
                showBorders={true}
                rowAlternationEnabled={true}
                dataSource={dataSrc}
                noDataText = 'Мэдээлэл байхгүй'
                style={{ fontFamily: "'Montserrat', sans-serif", fontWeight: 'bold' }}
                showRowLines={true}
                showColumnLines={true}
                showColumnHeaders={true}
                allowColumnResizing={true}
                wordWrapEnabled={true}
                onCellPrepared={onCellPreparedHandler}
                onExporting={onExporting}
                ref={myGridRef}
                //onOptionChanged={onOptionChanged}
                //onSelectionChanged={onSelectionChanged}
            >
                <Paging defaultPageSize={100}/>
                <Selection mode="single"/>
                <FilterRow visible={true}/>
                <HeaderFilter visible={true}/>
                <Column
                    caption='№'
                    cssClass="custom"
                    alignment="center"
                    allowEditing={false}
                    //width={50}
                    cellRender={orderRender}
                />
                <Column
                    cssClass="custom"
                    caption="Үзүүлэлт"
                    dataField="description"
                    allowFiltering={true}
                >
                </Column>
                <Column
                    cssClass="custom"
                    dataField="gov_capital_amt"
                    dataType={'number'}
                    caption={'Засгийн газрын оруулсан капитал'}
                    format="#,##0.00"
                    allowFiltering={true}
                >
                </Column>
                <Column
                    cssClass="custom"
                    dataField="assess_amt"
                    dataType={'number'}
                    caption={'Дахин үнэлгээний нөөц'}
                    format="#,##0.00"
                    allowFiltering={true}
                >
                </Column>
                <Column
                    cssClass="custom"
                    dataField="accum_amt"
                    dataType={'number'}
                    caption={'Хуримтлагдсан дүн'}
                    format="#,##0.00"
                    allowFiltering={true}
                >
                </Column>
                <Column
                    cssClass="custom"
                    dataField="gov_total_amt"
                    dataType={'number'}
                    caption={'Засгийн газрын хувь оролцооний нийт дүн'}
                    format="#,##0.00"
                    allowFiltering={true}
                >
                </Column>
                <Export enabled={true} allowExportSelectedData={false}/>
            </DataGrid>
                ):
                <div style={{display: "flex", justifyContent: "center", alignItems: "center", family: "'Montserrat', sans-serif" }}>
                 <EmptyData/>
                </div>
            }
        </div>
    )
}

export default UmchiinUurchlultList;

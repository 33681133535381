import React, {useRef, useState, useEffect, useContext} from "react";
import SelectBox from "devextreme-react/select-box";
import {getYears} from "../../../../util";
import DataGrid, {
    Column,
    Export,
    Selection,
    Summary,
    TotalItem,
    Grouping,
    GroupPanel,
    SearchPanel,
    GroupItem
} from "devextreme-react/data-grid";
import {Workbook} from "exceljs";
import {exportDataGrid} from "devextreme/excel_exporter";
import {saveAs} from "file-saver-es";
import fcsServices from "../../../../services/api/fcs";
import notify from "devextreme/ui/notify";
import Button from "devextreme-react/button";

import fcsService from "../../../../services/api/fcs";
import {LoadPanel} from "devextreme-react/load-panel";
import ScrollView from "devextreme-react/scroll-view";
import {Popup} from "devextreme-react/popup";

import DateBox from "devextreme-react/date-box";
import {UserContext} from "../../../../hooks/UserContext";
import {isDisabled} from "bootstrap/js/src/util";
import LoadPanelComponent from "../../../../components/LoadPanelComponent";
import GridReport from "../../report/GridReport";

const monthList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
export default function GlTranReport(props) {

    const glTranType=props.glTranType
    const userListPosition = {of: '#usersList'}
    const {user} = useContext(UserContext);
    const myRef = useRef(null);
    const componentRef = useRef();
    const [loader, setLoader] = useState(false);


    const [dataSource, setDataSource] = useState([]);
    const [dataSourceJr, setDataSourceJr] = useState([]);

    //const [selectedValue, setSelectedValue] = useState(null);

    const [reportTitle, setReportTitle] = useState(null);
    const [dataFcsBbClass, setFcsBbClass] = useState([]);
    const [selectedBbClass, setSelectedBbClass] = useState([]);
    const [reportGL, setReportGL] = useState([]);
    const [modalVisibleGL, setModalVisibleGL] = useState(false);
    const [modalVisibleGLSave, setModalVisibleGLSave] = useState(false);
    const [jrNo, setJrNo] = useState('');
    const [glDate, setGlDate] = useState();

    const [dataBalMonth, setDataBalMonth] = useState([]);
    const [modalVisibleMonthSave, setModalVisibleMonthSave] = useState(false);
    const [yearValue, setYearValue] = useState(null);
    const [monthValue, setMonthValue] = useState(null);
const [Tran,setTran]=useState([])
    useEffect(async () => {
        const today = new Date();
        setYearValue(today.getFullYear());
        setMonthValue(today.getMonth() + 1);

    }, []);


    useEffect(() => {
        getGlsum(0,0)
    }, [])

    const onChangeBBClass = async (e) => {

        setSelectedBbClass(e);
    }


    const customizeDate = (data) => {
        return Number(parseFloat(data.value).toFixed(2)).toLocaleString("en", {
            minimumFractionDigits: 2,
        });
    }

    const customizeDiff = (data) => {

        return (data.value != 0 ? "Зөрүү " + data.value : 0)
    }


    const diff = (rowData) => {
        return rowData.debit - rowData.credit;
    }

    const getGlsum = async (year,month) => {
       try {
                setDataSource([])
                setLoader(true);
                const result = await fcsServices.getFcsGlSum(year, month)
                setTran(result.data);

                return setLoader(false);

            } catch (e) {
                notify(e.message, "error", 2000);
                setDataSourceJr([])
                return setLoader(false);
            }

    };


    const getBbReport = async (year,month,jr_key) => {

        if (year && month ) {
            try {
                setDataSourceJr([])
                setLoader(true);
                const result = await fcsServices.getFcsGlTran(year, month, jr_key)
                setDataSource(result);

                return setLoader(false);

            } catch (e) {
                notify(e.message, "error", 2000);
                setDataSource([])
                return setLoader(false);

            }
        }
    };

    const changeYear = (e) => {
        setYearValue(e);
    }
    const changeMonth = (e) => {
        setMonthValue(e);

    }
    const rowSelected=(row)=>{


     if(  row.selectedRowsData.length>0) {
         setYearValue( row.selectedRowsData[0].year)
         setMonthValue(row.selectedRowsData[0].month)
         setSelectedBbClass(row.selectedRowsData[0].jr_key)
         if (row.selectedRowsData[0].jr_key == ''){
             getGlsum( row.selectedRowsData[0].year,row.selectedRowsData[0].month)
         } else { getBbReport( row.selectedRowsData[0].year, row.selectedRowsData[0].month,row.selectedRowsData[0].jr_key )

         }

     }

    }
    const onExporting = (e) => {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Main sheet');

        exportDataGrid({
            component: e.component,
            worksheet,
            autoFilterEnabled: true,
        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                //`${item.acct} - ${item.descr}`
                saveAs(new Blob([buffer], {type: 'application/octet-stream'}), `${selectedBbClass.jr}_${yearValue}_${monthValue}.xlsx`);
            });
        });
        e.cancel = true;
    }

    return (
        <React.Fragment>
            <div ref={myRef}>
                <LoadPanelComponent
                    shadingColor="rgba(0,0,0,0.4)"
                    position={userListPosition}
                    visible={loader}
                    showPane={false}
                    message=''
                />


                <div className="row" style={{display: 'flex', marginBottom: 20}}>

                    <div style={{display: 'flex', marginRight: 125}}>
                    </div>
                    <div style={{display: 'flex', marginRight: 20}}>

                        <div
                            style={{marginRight: 10, display: 'flex', alignItems: 'center'}}> Он:
                        </div>
                        <div>
                            <SelectBox
                                items={getYears(true)}
                                value={yearValue}
                                onValueChanged={(e) => changeYear(e.value)}
                                placeholder="сонгох"/>
                        </div>
                        <div>
                            <Button text="Сараар"
                                    onClick={() => getGlsum(yearValue,0)}
                                    width={150} type="default"
                                    style={{marginLeft: 20, alignment: "center", borderRadius: '7px'}}/>
                        </div>
                    </div>
                </div>

                <div className="row" style={{display: 'flex', marginBottom: 20}}>

                    <div style={{display: 'flex', marginRight: 122}}>
                    </div>
                    <div style={{display: 'flex', marginRight: 20}}>
                        <div style={{marginRight: 10, display: 'flex', alignItems: 'center'}}> Сар:
                        </div>
                        <div>
                            <SelectBox
                                items={monthList}
                                value={monthValue}
                                onValueChanged={(e) => changeMonth(e.value)}
                                placeholder="сонгох"/>
                        </div>
                        <div>
                            <Button text="Журналаар"
                                    onClick={() => getGlsum(yearValue,monthValue)}
                                    width={150} type="default"
                                    style={{marginLeft: 20, alignment: "center", borderRadius: '7px'}}/>
                        </div>
                    </div>

                    <div style={{display: 'flex', marginRight: 20}}>

                    </div>

                    <div>

                    </div>
                </div>


                <div className="row">


                    <div style={{display: 'flex', marginRight: 20}}>

                        <div style={{display: 'flex', marginRight: 100}}>
                        </div>
                        <div style={{marginRight: 10, display: 'flex', alignItems: 'center'}}> Журнал</div>
                        <SelectBox
                            items={glTranType}
                            value={selectedBbClass}
                            searchEnabled={true}
                            style={{border: '1px solid #DDDDDD'}}
                            valueExpr={'key'}
                            displayExpr={(item) => {
                                return item && `${item.key} - ${item.descr}`
                            }}


                            onValueChanged={(e) => onChangeBBClass(e.value)}
                            placeholder="сонгох"/>
                    </div>
                    <div>
                        <Button text="Дэлгэрэнгүй" onClick={() => getBbReport(yearValue,monthValue,selectedBbClass)} width={150} type="default"
                                style={{alignment: "center", borderRadius: '7px'}}/>
                    </div>


                </div>

            </div>
            <GridReport
                Tran={Tran}
                tranDescr={['он','сар','журнал','дебит','Кредит',]}
                tranF={["year", "month",'jr_key', "debit", "credit"]}
                structure={[1, 1,0,5, 5, ]}
                sumReport={[3, 4]}
                rowSelected={rowSelected}


            />

            {dataSource.length > 0 &&
                <div style={{marginLeft: 5, marginTop: 10}}>


                    <div className='col-12' style={{
                        display: 'flex',
                        flex: 1,
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginTop: 20
                    }}><span>{reportTitle}</span></div>

                    <div style={{marginTop: 10}} ref={componentRef}>

                        <DataGrid
                            dataSource={dataSource}
                            showBorders={true}
                            // ref={apTranRef}
                            allowColumnResizing={true}
                            columnAutoWidth={true}
                            hoverStateEnabled={true}
                            keyExpr="id"
                            noDataText='Дата байхгүй байна.'
                            onExporting={onExporting}>
                            <GroupPanel visible={true}/>
                            <SearchPanel visible={true}/>
                            <Grouping autoExpandAll={true}/>
                            <Column caption="Дугаар" groupIndex={0} dataField="docno" dataType="number" width={60}/>
                            <Column caption="Данс"
                                    dataField="acct" width={80}
                                    dataType="string"/>
                            <Column caption=""
                                    dataField="brn" width={33}
                                    dataType="string"/>
                            <Column caption="жр" dataField="jr_key" width={33}
                                    dataType="string"/>

                            <Column caption="Гүйлгээний утга" dataField="descr" dataType="string"/>
                            <Column caption="Огноо" dataField="docdate" dataType="date" width={80}/>


                            <Column caption="Дебит" dataField="debit" format="#,##0.00" dataType="number" width={100}/>
                            <Column caption="Кредит" dataField="credit" format="#,##0.00" dataType="number"
                                    width={100}/>
                            <Column dataField="orgcode"
                                    caption="Харилцагч"
                                    width={50}
                            />

                            <Column name={"zoruu"}
                                    caption="Харилцагчын нэр"
                                    calculateDisplayValue={"orgname"}
                                    calculateCellValue={diff}
                                    dataType="string" width={100}/>


                            <Column dataField="st4"
                                    caption="СТ4"
                            />
                            <Column caption=" Харицсан данс" dataField="acct2"/>
                            <Column caption=" " dataField="jr_descr"/>


                            <Summary>


                                <GroupItem
                                    column="zoruu"
                                    summaryType="sum"
                                    customizeText={customizeDiff}
                                    showInGroupFooter={true} style={{color: 'red'}}/>

                                <GroupItem
                                    column="debit"
                                    summaryType="sum"
                                    format="#,##0.00"
                                    customizeText={customizeDate}
                                    showInGroupFooter={true}/>


                                <GroupItem
                                    column="credit"
                                    summaryType="sum"
                                    format="#,##0.00"
                                    customizeText={customizeDate}
                                    showInGroupFooter={true}/>


                                <TotalItem
                                    column="debit"
                                    summaryType="sum"
                                    format="#,##0.00"
                                    customizeText={customizeDate}/>
                                <TotalItem
                                    column="credit"
                                    summaryType="sum"
                                    format="#,##0.00"
                                    customizeText={customizeDate}/>

                                <TotalItem
                                    column="zoruu"
                                    summaryType="sum"

                                    customizeText={customizeDiff}
                                    showInGroupFooter={true} style={{color: "#f00"}}/>


                            </Summary>
                            <Export enabled={true} allowExportSelectedData={false}/>
                        </DataGrid>
                    </div>


                </div>
            }
            {dataSourceJr.length > 0 &&
                <div style={{marginLeft: 5, marginTop: 10}}>


                    <div className='col-12' style={{
                        display: 'flex',
                        flex: 1,
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginTop: 20
                    }}><span>{reportTitle}</span></div>

                    <div style={{marginTop: 10}} ref={componentRef}>

                        <DataGrid
                            dataSource={dataSourceJr}
                            showBorders={true}
                            // ref={apTranRef}
                            allowColumnResizing={true}
                            columnAutoWidth={true}
                            hoverStateEnabled={true}
                            keyExpr={monthValue == 0 ? "month" : "jr_key"}
                            noDataText='Дата байхгүй байна.'
                            onExporting={onExporting}>
                            <GroupPanel visible={true}/>
                            <SearchPanel visible={true}/>
                            <Grouping autoExpandAll={true}/>

                            <Column caption="Он"
                                    dataField="year"
                                    dataType="string"/>
                            <Column caption="Сар"
                                    dataField="month"
                                    dataType="string"/>
                            <Column caption="жр" dataField="jr_key"
                                    dataType="string"/>


                            <Column caption="Дебит" dataField="debit" format="#,##0.00" dataType="number"/>
                            <Column caption="Кредит" dataField="credit" format="#,##0.00" dataType="number"/>

                            <Column name={"zoruu"}
                                    caption=""
                                    calculateDisplayValue={diff}
                                    calculateCellValue={diff}
                                    dataType="string"/>


                            <Summary>


                                <GroupItem
                                    column="zoruu"
                                    summaryType="sum"
                                    customizeText={customizeDiff}
                                    showInGroupFooter={true} style={{color: 'red'}}/>

                                <GroupItem
                                    column="debit"
                                    summaryType="sum"
                                    format="#,##0.00"
                                    customizeText={customizeDate}
                                    showInGroupFooter={true}/>


                                <GroupItem
                                    column="credit"
                                    summaryType="sum"
                                    format="#,##0.00"
                                    customizeText={customizeDate}
                                    showInGroupFooter={true}/>


                                <TotalItem
                                    column="debit"
                                    summaryType="sum"
                                    format="#,##0.00"
                                    customizeText={customizeDate}/>
                                <TotalItem
                                    column="credit"
                                    summaryType="sum"
                                    format="#,##0.00"
                                    customizeText={customizeDate}/>

                                <TotalItem
                                    column="zoruu"
                                    summaryType="sum"

                                    customizeText={customizeDiff}
                                    showInGroupFooter={true} style={{color: "#f00"}}/>


                            </Summary>
                            <Export enabled={true} allowExportSelectedData={false}/>
                        </DataGrid>
                    </div>


                </div>
            }


        </React.Fragment>
    )

}
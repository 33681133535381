import React from "react"
import {Switch as DevextremeSwitch} from "devextreme-react/switch"
import { Switch } from "antd"
import {useTranslation} from "../hooks/useTranslation"


function LanguageSwitch() {
  const {setLanguage, isMongolian} = useTranslation()
  const handleValueChange = (e)=>{
    if (e){
        setLanguage("en")
    }else{
        setLanguage("mn")
    }
  }
  return (
    <div style={{display: "flex", alignItems: "center"}}>
      <Switch checkedChildren="MN" unCheckedChildren="EN" style={{background: "#A8C7ED"}} defaultChecked={!isMongolian} onChange={handleValueChange} />
    </div>
  )
}

export default LanguageSwitch

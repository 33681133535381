import React, {useContext, useEffect, useState, useRef} from 'react';
import 'devextreme-react/text-area';
import DataGrid, {
    Editing,
    Pager,
    Paging,
    Column,
    FilterRow,
    RequiredRule,
    Lookup, Export, Form, Item, Popup, MasterDetail
} from 'devextreme-react/data-grid';
import {Workbook} from 'exceljs';
import {saveAs} from 'file-saver-es';
import {exportDataGrid} from 'devextreme/excel_exporter';
import {UserContext} from "../../../hooks/UserContext";
import fcsService from "../../../services/api/fcs";
import Button from "devextreme-react/button";
import notify from "devextreme/ui/notify";
import ExpenseTxnDetail from "./ExpenseTxnDetail";
import LoadPanelComponent from '../../../components/LoadPanelComponent';

function ExpenseTxn(props) {
    const userListPosition = {of: '#usersList'}
    const {user} = useContext(UserContext);
    const [dataSource, setDataSource] = useState([]);
    const [loader, setLoader] = useState(false);
    const myBabyRef = useRef(null);
    const [dataFcsChart, setFcsChart] = useState([]);
    const [dataFcsCustOrgn, setFcsCustOrgn] = useState([]);
    const [dataFcsLocation, setFcsLocation] = useState([]);
    const [dataFcsMonth, setFcsMonth] = useState([]);
    const [yearValue, setYearValue] = useState(0);
    const [monthValue, setMonthValue] = useState(0);
    useEffect(() => {
        fcsIncRecpList();
    }, [])
    useEffect(() => {
        getYearMonth();
    }, [dataFcsMonth]);
    const getInvRecp = async  ()=>{
        const result = await fcsService.getAllFcsInvRecp(1, yearValue, monthValue);
        setDataSource(result)

    }
    const fcsIncRecpList = async () => {
        try {
            setLoader(true);
             const fcsChart = await fcsService.getAllFcsChart();
            setFcsChart(fcsChart);
            const fcsLocation = await fcsService.getAllFcsLocation();
            setFcsLocation(fcsLocation);
            const fcsCustOrgn = await fcsService.getAllFcsCustOrgn();
            setFcsCustOrgn(fcsCustOrgn);
            const fcsMonth = await fcsService.getAllFcsMonth('IN');

            setFcsMonth(fcsMonth);
            return setLoader(false)
        } catch (e) {
            console.error(e);
        }
    }
    const getYearMonth = () => {
        dataFcsMonth.map((item) => {
            setYearValue(item.year)
            setMonthValue(item.month)
        })
    }
    const onEditorPreparing = (e) => {
        if (e.parentType === "dataRow" && e.dataField !== "password") {
            return;
        }
        if (e.row && e.row.isNewRow) {
            e.editorOptions.visible = true;
        }
        if (e.row && !e.row.isNewRow) {
            e.editorOptions.visible = false;
        }
    }
    /*const changeYear = (e) => {
        setYearValue(e);
    }

    const changeMonth = (e) => {
        setMonthValue(e);
    }
    const customAddRow = (grid) => {
        grid.current.instance.addRow();
    }*/
    const onInitNewRow = (e) => {
    }
    const setExpenseTxn = async (e) => {
        if (e.changes && e.changes.length !== 0) {
            if (e.changes[0].type === "remove") {
                const date = new Date(e.changes[0].key.date_recpt);//new e.changes[0].data.date_recpt;
                const year = date.getFullYear() * 1e4; // 1e4 gives us the the other digits to be filled later, so 20210000.
                const month = (date.getMonth() + 1) * 100;
                const yyyymm = year + month // `+ ''` to convert to string from number, 202206
                const fcsCurrentMonth = dataFcsMonth.map((item) => {
                    let date = item.year + '/' + item.month
                    let datetimenew = new Date(date)
                    let year = datetimenew.getFullYear() * 1e4; // 1e4 gives us the the other digits to be filled later, so 20210000.
                    let month = (datetimenew.getMonth() + 1) * 100;
                    const yyyymm_month = year + month
                    return yyyymm_month
                });
                if (yyyymm === fcsCurrentMonth[0]) {
                    try {
                        fcsService.removeFcsInvRecp(e.changes[0].key.id);
                        await fcsIncRecpList();
                        notify('Амжилттай устгагдлаа', "success", 2000);
                    } catch (e) {
                        notify('Устгах явцад алдаа гарлаа', "error", 2000);
                    }

                } else {
                    notify('Тухайн тайлант он сард биш тул уг гүйлгээг устгаж болохгүй', "error", 2000);
                }

            } else {
                const date = new Date(e.changes[0].data.date_recpt);//new e.changes[0].data.date_recpt;
                const year = date.getFullYear() * 1e4; // 1e4 gives us the the other digits to be filled later, so 20210000.
                const month = (date.getMonth() + 1) * 100;
                const yyyymm = year + month // `+ ''` to convert to string from number, 202206
                const fcsCurrentMonth = dataFcsMonth.map((item) => {
                    let date = item.year + '/' + item.month
                    let datetimenew = new Date(date)
                    let year = datetimenew.getFullYear() * 1e4; // 1e4 gives us the the other digits to be filled later, so 20210000.
                    let month = (datetimenew.getMonth() + 1) * 100;
                    const yyyymm_month = year + month
                    return yyyymm_month
                });

                if (yyyymm === fcsCurrentMonth[0]) {
                    if (e.changes[0].type === "update") {
                        try {
                            e.changes[0].data.updated_user_id = user.id;
                            await fcsService.editFcsInvRecp(e.changes[0].data);
                            await fcsIncRecpList();
                            notify('Амжилттай хадгалагдлаа', "success", 2000);
                        } catch (e) {
                            notify('Засварлалтыг хадгалах явцад алдаа гарлаа', "error", 2000);
                        }
                    } else if (e.changes[0].type === "insert") {
                        try {
                            delete e.changes[0].data.id;
                            const result = await fcsService.getAllFcsMonth('IN');
                            result.map(e => {
                                e.zarid = e.zarid + 1
                            })
                            e.changes[0].data.created_user_id = user.id;
                            e.changes[0].data.org_id = user.org_id;
                            e.changes[0].data.rs_number = parseInt(result[0].zarid);
                            e.changes[0].data.txn_type = 1;
                            await fcsService.addFcsInvRecp(e.changes[0].data);
                            await fcsService.editFcsMonth(result[0]);
                            await fcsIncRecpList();
                            notify('Амжилттай үүслээ', "success", 2000);
                        } catch (e) {
                            notify('Шинээр үүсэх явцад алдаа гарлаа', "error", 2000);
                        }

                    }
                } else {
                    notify('Сонгосон огноо тухайн тайлант он сард биш байна', "error", 2000);
                }
            }
        }
    }
    const onExporting = (e) => {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Main sheet');

        exportDataGrid({
            component: e.component,
            worksheet,
            autoFilterEnabled: true,
        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer], {type: 'application/octet-stream'}), 'Expense.xlsx');
            });
        });
        e.cancel = true;
    }
    const style={
        text: {
            color: "#2E4765", fontWeight: "700", fontSize: ".875rem"
        }, title:{
            color: "#2E4765",  fontSize: "18px"
        }}
       
    return (
        <div style={{padding: "0.5rem"}}>
            <LoadPanelComponent
                shadingColor="rgba(0,0,0,0.4)"
                position={{of: '#password'}}
                showPane={false}
                message = ''
            />
            <div className="col-12 row" style={{justifyContent: "center", gap: '2rem', padding: '5px 15px', marginBottom: 20}}>
            <div> <h4 style={{marginLeft: 20, textTransform: "uppercase", marginTop: 5,...style.title}}>
            Бараа материалын зарлагын гүйлгээ
                </h4>
                </div>
               
                {/* <Button icon="add" text="Нэмэх" onClick={() => customAddRow(myBabyRef)} width={110} type="default"
                        style={{alignment: "center", borderRadius: '7px'}}/> */}
            </div>
            <div className="col-12 row" style={{justifyContent: "space-between", gap: '2rem', padding: '5px 15px'}}>
                <span> {dataFcsMonth.map((item) => {
                    return <div><span style={{color : "#A3A3A3", marginRight: ".5rem" }}>Тайлант он </span><span style={{fontWeight: "700"}}> {item.year} сар {item.month}</span></div>;
                })}
                </span>
                <div>
                    <Button icon="search" text="Хайх" onClick={() => getInvRecp()} width={100} type="default"
                            style={{alignment: "center", borderRadius: '7px'}}/>
                </div>
                {/* <Button icon="add" text="Нэмэх" onClick={() => customAddRow(myBabyRef)} width={110} type="default"
                        style={{alignment: "center", borderRadius: '7px'}}/> */}

            </div>
            
            <div className="row" style={{display: 'flex', marginLeft: 20}}>
                <div style={{display: 'flex', marginRight: 20}}>

                </div>
                <div style={{display: 'flex', marginRight: 20}}>

                </div>
               
            </div>
            <LoadPanelComponent
                shadingColor="rgba(0,0,0,0.4)"
                position={userListPosition}
                visible={loader}
                showPane={false}
                message = ''
            />
            <div>
                <div className="card-body">
                    <DataGrid
                        onEditorPreparing={onEditorPreparing}
                        ref={myBabyRef}
                        dataSource={dataSource}
                        onSaved={setExpenseTxn}
                        keyExpr={null}
                        wordWrapEnabled={true}
                        onInitNewRow={onInitNewRow}
                        allowColumnResizing={true}
                        onExporting={onExporting}>

                        >
                        <FilterRow
                            visible={true}
                        />
                        <Pager
                            showPageSizeSelector={true}
                            // showInfo={true}
                            showNavigationButtons={true}
                        />
                        <Column
                            dataField="id"
                            caption="Дугаар"
                            allowEditing={false}
                            width={60}
                            alignment="center"
                            // editCellTemplate = {}
                            // cellRender = {customize}
                            cssClass="custom"
                            allowFiltering={true}
                        >
                        </Column>
                        <Column
                            dataField="organization_model.name"
                            caption="Байгууллага нэр"
                            allowEditing={false}
                            minWidth={100}
                            alignment="center"
                            // editCellTemplate = {}
                            // cellRender = {customize}
                            cssClass="custom"
                            allowFiltering={true}
                        >
                        </Column>
                        <Column
                            cssClass="custom"
                            dataField="rs_number"
                            caption="Падаан №"
                            allowEditing={false}
                            width={70}
                            alignment="center"
                            allowCollapsing={false}
                            allowFiltering={true}
                        >
                        </Column>
                        <Column
                            cssClass="custom"
                            dataField="date_recpt"
                            caption="Огноо"
                            allowEditing={true}
                            width={70}
                            alignment="center"
                            dataType="date"
                        >
                            <RequiredRule
                                message='Огноо оруулна уу'
                            />
                        </Column>

                        <Column
                            cssClass="custom"
                            dataField="chart_id"
                            caption="Дебит данс"
                            minWidth={100}
                            alignment="center"
                            allowEditing={true}
                            allowFiltering={true}
                        >
                            <RequiredRule
                                message='Дебит данс оруулна уу'
                            />
                            <Lookup
                                valueExpr="id"
                                displayExpr={(item) => {
                                    return item && `${item.acct} - ${item.descr}`
                                }}
                                dataSource={dataFcsChart}

                            />
                        </Column>
                        <Column
                            cssClass="custom"
                            dataField="loctn_id"
                            minWidth={90}
                            alignment="center"
                            caption="Агуулах"
                            allowEditing={true}
                            allowFiltering={true}
                        >
                            <RequiredRule
                                message='Агуулах оруулна уу'
                            />
                            <Lookup
                                valueExpr="id"
                                displayExpr={(item) => {
                                    return item && `${item.loctn_code} - ${item.loctn_name}`
                                }}
                                dataSource={dataFcsLocation}
                            />
                        </Column>
                        <Column
                            cssClass="custom"
                            dataField="custorgn_id"
                            caption="Хүлээн авагч"
                            minWidth={100}
                            alignment="center"
                            allowEditing={true}
                            allowFiltering={true}
                        >
                            <RequiredRule
                                message='харилцагч код оруулна уу'
                            />
                            <Lookup
                                valueExpr="id"
                                displayExpr={(item) => {
                                    return item && `${item.orgcode} - ${item.name}`
                                }}
                                dataSource={dataFcsCustOrgn}

                            />
                        </Column>
                        <Editing
                            mode="popup"
                            allowUpdating={false}
                            allowDeleting={true}
                            allowAdding = {true}
                            useIcons={true}
                            texts={{
                                cancelAllChanges: 'Болих бүгд',
                                cancelRowChanges: 'Болих',
                                confirmDeleteMessage: 'Энэ бараа материалын зарлагын гүйлгээ мэдээллийг устгах уу?',
                                confirmDeleteTitle: 'Баталгаажуулах',
                                deleteRow: 'Устгах',
                                editRow: 'Өөрчлөх',
                                saveAllChanges: 'Хадгалах бүгд',
                                saveRowChanges: 'Хадгалах',
                                undeleteRow: 'Буцаах',
                                validationCancelChanges: 'Баталгаажуулах болих',
                            }}
                        >
                            <Popup title="Бараа материалын орлогын гүйлгээ" showTitle={true} width={1000} height={300}/>
                            <Form>
                                <Item itemType="group" colCount={2} colSpan={2}>
                                    <Item dataField="organization_model.name"/>
                                    <Item dataField="rs_number"/>
                                    <Item dataField="date_recpt"/>
                                    <Item dataField="loctn_id"/>
                                    <Item dataField="custorgn_id"/>
                                    <Item dataField="chart_id"/>
                                </Item>
                            </Form>
                        </Editing>
                        <Paging enabled={true}/>
                        <Export enabled={true} allowExportSelectedData={false}/>
                        <MasterDetail
                            enabled={true}
                            component={ExpenseTxnDetail}
                        />
                    </DataGrid>
                </div>
            </div>
        </div>
    )
}

export default ExpenseTxn;

import React, { useContext } from "react";
import "../../proposal/static/proposal.css";
import { Steps, Tooltip } from "antd";
import '../../../App.css'
import { UserContext } from "../../../hooks/UserContext";

const deadlineTooltip = [
    {
        title: '',
        key: 1
    },
    {
        title:
            "",
        key: 2,
    },
    {
        title:
            "Төсвийн төвлөрүүлэн захирагч болон төсвийн төвлөрүүлэн захирагчид харъяалагддаггүй төсвийн шууд захирагч төсвийн хуваарийн саналаа жил бүрийн 12 дугаар сарын 15-ны дотор харьяалагдах төсвийн ерөнхийлөн захирагчид хүргүүлэх",
        key: 3,
    },
    {
        title:
            "Төсвийн ерөнхийлөн захирагч төсвийн хуваарийн саналаа жил бүрийн 12 дугаар сарын 25-ны дотор санхүү, төсвийн асуудал эрхэлсэн төрийн захиргааны төв байгууллагад хүргүүлэх",
        key: 4,
    }
];


export const PlanTimeLine = ({ statusId }) => {

    const { user } = useContext(UserContext)
    const status = [
        {
            key: 1,
            title: 'ТШЗ',
            description: statusId === 1 ? 'Боловсруулж байгаа' : 'Боловсруулсан',
            status: statusId !== 1 ? 'finish' : 'process'
        },
        {
            key: 2,
            title: statusId === 3 ? 'ТТЗ-д хүргүүлсэн' : 'ТТЗ',
            description: statusId === 1 ? 'Хүлээгдэж байгаа' :
                (statusId === 5 ? 'Татгалзсан' :
                    [3].includes(statusId) ? 'Хянаж байгаа' : 'Нэгтгэсэн'),
            status: [2, 4, 6, 8, 9].includes(statusId) ? 'finish' : statusId === 3 || statusId === 7 ? 'process' : (statusId === 5 ? 'error' : 'wait')
        },
        {
            key: 3,
            title: statusId === 2 ? 'ТЕЗ-д хүргүүлсэн' : 'ТЕЗ',
            description: statusId === 2 ? 'Хянаж байгаа' :
                (statusId === 4 ? 'Татгалзсан' :
                    [6, 8, 9].includes(statusId) ? 'Нэгтгэсэн' : 'Хүлээгдэж байгаа'),
            status: [6, 8, 9].includes(statusId) ? 'finish' : statusId === 2 ? 'process' : (statusId === 4 ? 'error' : 'wait')
        },
        {
            key: 4,
            title: 'Сангийн яам',
            description: statusId === 8 ? 'Хүргүүлсэн' : (statusId === 9 ? 'Баталсан' : 'Хүлээгдэж байгаа'),
            status: statusId === 8 ? 'process' : (statusId === 9 ? 'finish' : 'wait')
        }
    ]
    const status1 = [
        {
            key: 1,
            title: 'ТШЗ',
            description: statusId === 1 ? 'Боловсруулж байгаа' : 'Боловсруулсан',
            status: statusId !== 1 ? 'finish' : 'process'
        },
        {
            key: 2,
            title: statusId === 3 ? 'ТТЗ-д хүргүүлсэн' : 'ТТЗ',
            description: statusId === 1 ? 'Хүлээгдэж байгаа' :
                (statusId === 5 ? 'Татгалзсан' :
                    [3].includes(statusId) ? 'Хянаж байгаа' : 'Нэгтгэсэн'),
            status: [2, 4, 6, 8, 9].includes(statusId) ? 'finish' : statusId === 3 || statusId === 7 ? 'process' : (statusId === 5 ? 'error' : 'wait')
        },
        {
            key: 3,
            title: statusId === 2 ? 'ТЕЗ-д хүргүүлсэн' : 'ТЕЗ',
            description: statusId === 2 ? 'Хянаж байгаа' :
                (statusId === 4 ? 'Татгалзсан' :
                    [6, 8, 9].includes(statusId) ? 'Нэгтгэсэн' : 'Хүлээгдэж байгаа'),
            status: [6, 8, 9].includes(statusId) ? 'finish' : statusId === 2 ? 'process' : (statusId === 4 ? 'error' : 'wait')
        },
        {
            key: 4,
            title: 'Сангийн яам',
            description: statusId === 8 ? 'Хүргүүлсэн' : (statusId === 9 ? 'Баталсан' : 'Хүлээгдэж байгаа'),
            status: statusId === 8 ? 'process' : (statusId === 9 ? 'finish' : 'wait')
        }
    ]
    return (
        <>
            {user.organization.obt_id === 2 ?
                <div className="card" style={{ padding: "20px", margin: 0, fontFamily: "Segoe UI', sans-serif", borderColor: '#E2E8F3', cursor: 'default' }}>
                    <Steps>
                        {status.map((e, i) => (
                            <Steps.Step
                                key={i}
                                status={e.status}
                                description={e.description}
                                title={
                                    <Tooltip key={deadlineTooltip[i].key} title={deadlineTooltip[i].title} overlayInnerStyle={{ padding: 20, textAlign: 'justify' }}>
                                        {e.title}
                                    </Tooltip>
                                }
                            >
                            </Steps.Step>
                        ))}
                    </Steps>
                </div>
                : 
                <div className="card" style={{ padding: "20px", margin: 0, fontFamily: "Segoe UI', sans-serif", borderColor: '#E2E8F3', cursor: 'default' }}>
                    <Steps>
                        {status1.map((e, i) => (
                            <Steps.Step
                                key={i}
                                status={e.status}
                                description={e.description}
                                title={
                                    <Tooltip key={deadlineTooltip[i].key} title={deadlineTooltip[i].title} overlayInnerStyle={{ padding: 20, textAlign: 'justify' }}>
                                        {e.title}
                                    </Tooltip>
                                }
                            >
                            </Steps.Step>
                        ))}
                    </Steps>
                </div>
            }
        </>
    );
};

import React, { useState, useEffect, useRef } from "react";
import _ from "lodash";
import BudgetService from "../../../../services/api/budget";
import { budgetPalettes, numberWithCommas } from "../../../../util";
import notify from "devextreme/ui/notify";
import { Export } from "devextreme-react/pivot-grid";
import PieChart from "devextreme-react/pie-chart";
import { Label, Legend, Series, Tooltip, Connector } from "devextreme-react/chart";
import SelectBox from "devextreme-react/select-box";
import LoadPanelComponent from "../../../budget/components/loadPanel";
import EmptyData from "../../../budget/components/empty";
const selectionist = [
    { id: 1, text: '1-р түвшин', value: 'econ_category_name_1' },
    { id: 2, text: '2-р түвшин', value: 'econ_category_name_2' },
    { id: 3, text: '3-р түвшин', value: 'econ_category_name_3' },
    { id: 4, text: '4-р түвшин', value: 'econ_category_name' },
]

const OrlogoPivotGird = (props) => {
    const [neverChangingData, setNeverChangingData] = useState([]);
    const [doughnutData, setDoughnutData] = useState([])
    const [selector, setSelector] = useState(2);
    const [loader, setLoader] = useState(true);

    const loadPivotData = async () => {
        try {
            setLoader(true);
            let r = await BudgetService.getOrlogoPivotData(props.year, props.filterOrgText, props.orlogoType,props.orgId);
            setNeverChangingData(r);
            let pp = _(r).groupBy('econ_category_name_2')
                .map((row, key) => {
                    return {
                        name: key,
                        amount: _.sumBy(row, 'amount')
                    }
                }).value()
            setDoughnutData(pp);
        } catch (e) {
            notify(e.message, "error", 2000);
        } finally {
            setLoader(false)
        }
    }

    useEffect(() => {
        loadPivotData();
    }, [props.year, props.filterOrgText, props.orlogoType,props.orgId])

    const cstmText = (e) => {
        return `<span style="color: #f7f7fa">${numberWithCommas(e.value / 1000000)}<br/>${(e.percent * 100).toFixed(2)}%</span>`
    }

    const customizeTooltip = (arg) => {
        return {
            html: `<span class="tooltip-series-name">${arg.argumentText}: </span> ${numberWithCommas(arg.value / 1000000)} /${(arg.percent * 100).toFixed(2)}%/`
        };
    }

    const eventHandler = (e) => {
        let r = _(neverChangingData).groupBy(_.find(selectionist, { id: e.value }).value)
            .map((row, key) => {
                return {
                    name: key,
                    amount: _.sumBy(row, 'amount')
                }
            }).value()
        setDoughnutData(r);
    }

    if (doughnutData?.length === 0 && !loader) {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    family: "Segoe UI",
                }}
            >
                <EmptyData/>
            </div>
        )
    }

    const calculateTotal = (pieChart) => {
        let totalValue = pieChart.getAllSeries()[0].getAllPoints().reduce((s, p) => s + p.originalValue, 0);
        return `${numberWithCommas(totalValue / 1000000)}`;
    }

    const centerRender = (pieChart) => {
        return (
            <text textAnchor="middle" style={{ fontWeight: 600 }}>
                <tspan x="0" dy="-1em">Төсөв:</tspan>
                <tspan x="0" dy="1em">{calculateTotal(pieChart)}</tspan>
            </text>
        );
    }

    return (
        <div id="load5">
            <LoadPanelComponent position={{ of: '#load5' }} visible={loader} />
            <div style={{ position: 'absolute', top: props.firstChartVisible === undefined ? 40 : 0, right: 10 }}>
                <SelectBox
                    items={selectionist}
                    valueExpr="id"
                    width={'10rem'}
                    label={'ЭЗА'}
                    displayExpr="text"
                    onValueChanged={eventHandler}
                    defaultValue={selector}
                />
            </div>
            <PieChart
                type="doughnut"
                dataSource={doughnutData}
                resolveLabelOverlapping="hide"
                palette={_.find(budgetPalettes, { 'id': 4.1 }).color}
                onPointClick={() => props.pivotHandler()}
                centerRender={centerRender}
                onLegendClick={({ points }) => points[0].isVisible() ? points[0].hide() : points[0].show()}
            >
                <Series argumentField="name" valueField="amount">
                    <Label
                        visible={true}
                        position="columns"
                        customizeText={cstmText}>
                        <Connector visible={true} width={0.5} />
                    </Label>
                </Series>
                <Legend
                    orientation="horizontal"
                    itemTextPosition="right"
                    horizontalAlignment="center"
                    verticalAlignment="bottom"
                    columnCount={4} />
                <Tooltip zIndex={10000}
                    enabled={true}
                    customizeTooltip={customizeTooltip}
                    font={{ color: '#fff' }} color="#555"
                    cornerRadius={6}
                    border={{ visible: false }}
                    location="edge"
                >
                </Tooltip>
            </PieChart>
        </div>
    )
}

export default OrlogoPivotGird;

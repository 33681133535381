import React, {useContext, useEffect, useState} from 'react';
import TreeList, {Column, Editing, Lookup, RowDragging} from 'devextreme-react/tree-list';
import {Button as FormButton} from "devextreme-react/data-grid";
import OrganizationService from "../../../services/api/organization";
import StructureBoxComponent from "./structureBoxComponent";
import {UserContext} from "../../../hooks/UserContext";

export default function CreateOrgStructure(props) {

    const [worker, setWorker] = useState([]);
    const [workerList, setWorkerList] = useState([]);
    const expandedRowKeys = [1];
    const {user} = useContext(UserContext);

    useEffect(() => {
        getData();
        getWorkerData();
    }, []);

    const getData = async () => {
        const data = await OrganizationService.getOrgStructure(user.org_id);
        setWorker(data.data);
    }

    const getWorkerData = async () => {
        const data = await OrganizationService.getOrgWorker(null,null,0,0,'');
        setWorkerList(data.data);
    }

    const onDragChange = (e) => {
        let visibleRows = e.component.getVisibleRows(),
            sourceNode = e.component.getNodeByKey(e.itemData.id),
            targetNode = visibleRows[e.toIndex].node;

        while (targetNode && targetNode.data) {
            if (targetNode.data.id === sourceNode.data.id) {
                e.cancel = true;
                break;
            }
            targetNode = targetNode.parent;
        }
    }

    const onReorder = (e) => {
        const visibleRows = e.component.getVisibleRows();
        let sourceData = e.itemData;
        let employees = worker;
        const sourceIndex = employees.indexOf(sourceData);

        if (e.dropInsideItem) {
            sourceData = { ...sourceData, parent_id: visibleRows[e.toIndex].key };
            employees = [...employees.slice(0, sourceIndex), sourceData, ...employees.slice(sourceIndex + 1)];
            updateData(sourceData);
        } else {
            const toIndex = e.fromIndex > e.toIndex ? e.toIndex - 1 : e.toIndex;
            let targetData = toIndex >= 0 ? visibleRows[toIndex].node.data : null;

            if (targetData && e.component.isRowExpanded(targetData.id)) {
                sourceData = { ...sourceData, parent_id: targetData.id };
                targetData = null;
            } else {
                const headId = targetData ? targetData.parent_id : -1;
                if(sourceData.parent_id !== headId) {
                    sourceData = { ...sourceData, parent_id: headId };
                }
            }

            employees = [...employees.slice(0, sourceIndex), ...employees.slice(sourceIndex + 1)];

            const targetIndex = employees.indexOf(targetData) + 1;
            employees = [...employees.slice(0, targetIndex), sourceData, ...employees.slice(targetIndex)];
        }
        setWorker(employees);
        // this.setState({
        //     employees
        // });
    }

    const setData = (e) => {
        e.changes.forEach(data => {
            if (data.type === 'insert') {
                const res = {
                    parent_id: null,
                    name: data.data.name,
                    status: 1,
                    worker_id: data.data.worker_id,
                    org_id: user.org_id
                }
                sendData(res);
            } else if (data.type === 'update') {
                updateData(data.data);
            } else if (data.type === 'remove') {
                console.log("remove data", data.data);
            }
        });
    }

    const sendData = async (data) => {
        const res = await OrganizationService.setOrgStructure(data);
        if (res.data) {
            getData();
        }
    }

    const updateData = async (data) => {
        const res = {
            id: data.id,
            parent_id: data.parent_id,
            name: data.name,
            status: 1,
            worker_id: data.worker_id,
            org_id: data.org_id
        }
        const result = await OrganizationService.updateOrgStructure(res)
    }

    const calculateSortValue = (data) => {
    }

    return (
        <div className='col-12' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: 30 }}>
            <div style={{ alignItems: 'center', justifyContent: 'center', width: '90%' }}>
                {/*{*/}
                {/*    worker.length > 0 && workerList.length > 0 &&*/}
                <TreeList
                    id="employees"
                    dataSource={worker}
                    rootValue={-1}
                    keyExpr="id"
                    showRowLines={true}
                    showBorders={true}
                    parentIdExpr="parent_id"
                    defaultExpandedRowKeys={expandedRowKeys}
                    columnAutoWidth={true}
                    onSaved={setData}
                >
                    <RowDragging
                        onDragChange={onDragChange}
                        onReorder={onReorder}
                        allowDropInsideItem={true}
                        allowReordering={true}
                        showDragIcons={true}
                    />
                    <Column dataField="name" caption="Нэр" />
                    <Column dataField="worker_id" caption="Ахлах ажилтан"
                            allowSorting={false}
                            editCellComponent={StructureBoxComponent}
                            calculateSortValue={calculateSortValue} >
                        <Lookup
                            dataSource={workerList}
                            valueExpr="id"
                            displayExpr="sur_name"
                        />
                    </Column>
                    <Editing
                        allowUpdating={true}
                        allowDeleting={true}
                        allowAdding={true}
                        mode="row"
                        useIcons={true}
                        texts={{
                            addRow: 'Албан хаагч нэмэх',
                            cancelAllChanges: 'Болих',
                            cancelRowChanges: 'Болих',
                            confirmDeleteMessage: 'Энэ мэдээллийг устгах уу?',
                            confirmDeleteTitle: 'Баталгаажуулах',
                            deleteRow: 'Устгах',
                            editRow: 'Өөрчлөх',
                            saveAllChanges: 'Хадгалах',
                            saveRowChanges: 'Хадгалах',
                            undeleteRow: 'Буцаах',
                            validationCancelChanges: 'Баталгаажуулах болих',
                        }}
                    />
                    <Column type="buttons" width={160} fixed={true} fixedPosition="right">
                        <FormButton name="edit"/>
                        <FormButton name="delete"/>
                    </Column>
                </TreeList>
                {/*}*/}
            </div>
        </div>
    )
}
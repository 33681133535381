import React, {useState,useEffect, useContext} from "react";
import Button from "devextreme-react/button";
import DataGrid, {
    Editing,
    Pager,
    Paging,
    Column,
    FilterRow,
    Lookup, Export, Form, Item,Popup
} from 'devextreme-react/data-grid';
import SelectBox from "devextreme-react/select-box";
import {getYears} from "../../../util";
import notify from "devextreme/ui/notify";
import fcsServices from "../../../services/api/fcs";
import {GetTran} from "./Db";
import {UserContext} from "../../../hooks/UserContext";
const monthList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

const TranOg = (props) => {
    const {user} = useContext(UserContext);
    const [yearValue, setYearValue] = useState(null);
    const [monthValue, setMonthValue] = useState(null);
    const [listData, setListData] = useState(null);
    const [org, setOrg] = useState([]);
    const [apList, setApList] = useState([]);
    const [accountSecondList, setAccountSecondList] = useState([]);
    const [listDataAn, setListDataAn] = useState([]);
    useEffect(async () => {
        const today = new Date();
        setYearValue(today.getFullYear());
        setMonthValue(today.getMonth() + 1);

    }, []);

    useEffect(() => {
        geMonth();
        if (props.p1 === "29") {
            getData();
        }

        if (props.p1 === "16") {
            getAnother();
        }
    }, [props.p1]);

    const getData = async () => {
        const orgList = await fcsServices.getAllFcsCustOrgn();
        setOrg(orgList);
        const apList = await GetTran("16", 0, "gl?");
        setApList(apList.data.data);
        const result = await fcsServices.getAllFcsChart();
        setAccountSecondList(result);
    }

    const getAnother = async () => {
        const result = await fcsServices.getAllFcsArClass();
        setListDataAn(result);
        console.log("result data: ", result);
    }

    const geMonth = async () => {
        const today = new Date();
        setYearValue(today.getFullYear());
        setMonthValue(today.getMonth() + 1)
    }

    const changeYear = (e) => {
        setYearValue(e);
    }

    const changeMonth = (e) => {
        setMonthValue(e);
    }

    const fcsAptranList = async () => {
        if (yearValue === null) return notify("Тайлант он хоосон байна", "warning");
        if (monthValue === null) return notify("Тайлант сар хоосон байна", "warning");

        const result = await fcsServices.getFcsArTranList(yearValue, monthValue);
        setListData(result);
        console.log("result data: ", result);
    }

    const setData = async (e) => {
        const data = {
            id: e.changes[0].data.id,
            ar: "",
            arcode: "",
            docno: e.changes[0].data.docno,
            descr: e.changes[0].data.descr,
            qty: 0,
            iss_cost: 1,
            amount: e.changes[0].data.amount,
            docdate: e.changes[0].data.docdate,
            cust: e.changes[0].data.cust,
            citytax: e.changes[0].data.citytax,
            acctdb: e.changes[0].data.acctdb,
            custname: e.changes[0].data.custname,
            acctdb_name: e.changes[0].data.acctdb_name,
            acctcr: e.changes[0].data.acctcr,
            acctcr_name: e.changes[0].data.acctcr_name,
            org_id: user.org_id,
            created_user_id: user.id,
        }

        const result = await fcsServices.editFcsArtran(data);
        console.log("result data: ", result);
    }

    return (
      <React.Fragment>
          {props.p1 === "29" && <div className={'card-body'} id='firstBox'>
              <div className="row">
                  <div style={{display: 'flex', alignItems: 'center'}}>
                      <div style={{marginRight: 10, alignItems: 'center'}}>Тайлант он:</div>
                      <SelectBox
                        items={getYears(true)}
                        defaultValue={yearValue}
                       value={yearValue}
                        onValueChanged={(e) => changeYear(e.value)}
                        placeholder="сонгох"/>
                  </div>
                  <div style={{display: 'flex', marginLeft: 30, alignItems: 'center'}}>
                      <div style={{marginRight: 10, display: 'flex', alignItems: 'center'}}>Тайлант сар:</div>
                      <SelectBox
                        items={monthList}
                        defaultValue={monthValue}
                        value={monthValue}
                        onValueChanged={(e) => changeMonth(e.value)}
                        placeholder="сонгох"/>
                  </div>
                  <div style={{display: 'flex', marginLeft: 30}}>
                      <Button icon="search" text="Хайх" onClick={() => fcsAptranList()} width={100} type="default"
                              style={{alignment: "center", borderRadius: '7px'}}/>
                  </div>
              </div>
              <div className="col-12" style={{marginTop: 30}}>
                  <DataGrid
                    dataSource={listData}
                    showBorders={true}
                    hoverStateEnabled={true}
                    noDataText='Дата байхгүй байна.'
                    onSaved={setData}
                    rowUpdating={(e) => {
                        console.log(e);
                    }}
                  >
                      <FilterRow
                        visible={true}
                      />
                      <Pager
                        showPageSizeSelector={true}
                        showNavigationButtons={true}
                      />
                      <Column
                        dataField="id"
                        caption="Дугаар"
                        allowEditing={false}
                        cssClass="custom"
                        allowFiltering={true}
                      />
                      <Column
                        dataField="docno"
                        caption="Баримтын дугаар"
                        allowEditing={true}
                        cssClass="custom"
                        allowFiltering={true}
                      />
                      <Column
                        dataField="amount"
                        caption="Дүн"
                        allowEditing={true}
                        cssClass="custom"
                        allowFiltering={true}
                        format="#,##0.00"
                      />
                      <Column
                        dataField="citytax"
                        caption="НӨАТ"
                        allowEditing={true}
                        cssClass="custom"
                        allowFiltering={true}
                        format="#,##0.00"
                      />
                      <Column
                        dataField="acctcr"
                        caption="Кредит данс"
                        allowEditing={true}
                        cssClass="custom"
                        allowFiltering={true}
                      >
                          <Lookup
                            valueExpr="acct"
                            displayExpr={(item) => {
                                return item && `${item.acct} - ${item.descr}`
                            }}
                            dataSource={accountSecondList}
                          />
                      </Column>
                      <Column
                        dataField="custname"
                        caption="Харилцагч"
                        allowEditing={true}
                        cssClass="custom"
                        allowFiltering={true}
                      >
                          <Lookup
                            valueExpr="name"
                            displayExpr={(item) => {
                                return item && `${item.orgcode} - ${item.name}`
                            }}
                            dataSource={org}
                          />
                      </Column>
                      <Column
                        dataField="acctdb"
                        caption="Авлагын ангилал"
                        allowEditing={true}
                        cssClass="custom"
                        allowFiltering={true}
                      >
                          <Lookup
                            valueExpr="c11"
                            displayExpr={(item) => {
                                return item && `${item.c10} - ${item.c11}`
                            }}
                            dataSource={apList}
                          />
                      </Column>
                      <Column
                        dataField="docdate"
                        caption="Огноо"
                        dataType="date"
                        allowEditing={true}
                        cssClass="custom"
                        allowFiltering={true}
                      />

                      <Editing
                        mode="popup"
                        allowUpdating={true}
                        allowDeleting={false}
                        // allowAdding = {adding}
                        useIcons={true}
                        texts={{
                            cancelAllChanges: 'Болих бүгд',
                            cancelRowChanges: 'Болих',
                            confirmDeleteMessage: 'Энэ худалдан авалт мэдээллийг устгах уу?',
                            confirmDeleteTitle: 'Баталгаажуулах',
                            deleteRow: 'Устгах',
                            editRow: 'Өөрчлөх',
                            saveAllChanges: 'Хадгалах бүгд',
                            saveRowChanges: 'Хадгалах',
                            undeleteRow: 'Буцаах',
                            validationCancelChanges: 'Баталгаажуулах болих',
                        }}
                      >
                          <Popup title="" showTitle={true} width={1000} height={440}/>
                          <Form>
                              <Item itemType="group" colCount={2} colSpan={2}>
                                  <Item dataField="docno"/>
                                  <Item dataField="amount"/>
                                  <Item dataField="citytax"/>
                                  <Item dataField="acctcr"/>
                                  <Item dataField="custname"/>
                                  <Item dataField="acctdb"/>
                                  <Item dataField="docdate"/>
                              </Item>
                          </Form>
                      </Editing>
                      <Paging enabled={true}/>
                      <Export enabled={true} allowExportSelectedData={false}/>
                  </DataGrid>
              </div>
          </div>}
          {props.p1 === "16" &&
              <div className="col-12" style={{marginTop: 30}}>
                  <DataGrid
                    dataSource={listDataAn}
                    showBorders={true}
                    hoverStateEnabled={true}
                    noDataText='Дата байхгүй байна.'
                    onSaved={setData}
                    rowUpdating={(e) => {
                        console.log(e);
                    }}
                  >
                      <FilterRow
                        visible={true}
                      />
                      <Pager
                        showPageSizeSelector={true}
                        showNavigationButtons={true}
                      />
                      <Column
                        dataField="id"
                        caption="Дугаар"
                        allowEditing={false}
                        cssClass="custom"
                        allowFiltering={true}
                      />
                      <Column
                        dataField="aracct"
                        caption="Авлагын ангилалын данс"
                        allowEditing={true}
                        cssClass="custom"
                        allowFiltering={true}
                      />
                      <Column
                        dataField="descr"
                        caption="Авлагын ангилалын нэр"
                        allowEditing={true}
                        cssClass="custom"
                        allowFiltering={true}
                      />
                      <Column
                        dataField="createdAt"
                        caption="Огноо"
                        dataType="date"
                        allowEditing={true}
                        cssClass="custom"
                        allowFiltering={true}
                      />

                      <Editing
                        mode="popup"
                        allowUpdating={true}
                        allowDeleting={false}
                        // allowAdding = {adding}
                        useIcons={true}
                        texts={{
                            cancelAllChanges: 'Болих бүгд',
                            cancelRowChanges: 'Болих',
                            confirmDeleteMessage: 'Энэ худалдан авалт мэдээллийг устгах уу?',
                            confirmDeleteTitle: 'Баталгаажуулах',
                            deleteRow: 'Устгах',
                            editRow: 'Өөрчлөх',
                            saveAllChanges: 'Хадгалах бүгд',
                            saveRowChanges: 'Хадгалах',
                            undeleteRow: 'Буцаах',
                            validationCancelChanges: 'Баталгаажуулах болих',
                        }}
                      >
                          <Popup title="" showTitle={true} width={1000} height={440}/>
                          <Form>
                              <Item itemType="group" colCount={2} colSpan={2}>
                                  <Item dataField="id"/>
                                  <Item dataField="aracct"/>
                                  <Item dataField="descr"/>
                                  <Item dataField="createdAt"/>
                              </Item>
                          </Form>
                      </Editing>
                      <Paging enabled={true}/>
                      <Export enabled={true} allowExportSelectedData={false}/>
                  </DataGrid>
              </div>
          }
      </React.Fragment>
    );
};

export default TranOg;

import React from 'react'
import CountUp from 'react-countup'

const Counter = ({number}) => {
  return (
    <div>
        <CountUp duration={2} end={number}/>
    </div>
  )
}

export default Counter;
import React, { useState, useEffect, useContext } from "react";
import notify from "devextreme/ui/notify";
import DataGrid, { Column, FilterRow, Paging, Grouping } from "devextreme-react/data-grid";
import { Template } from "devextreme-react";
import Button from "devextreme-react/button";
import { numberWithCommas } from "../../../util";
import ScheduleService from "../../../services/api/scheduleService";
import { useHistory } from "react-router-dom";
import { UserContext } from "../../../hooks/UserContext";

const ScheduleEditReqList = ({ currentOrg, startDate, endDate, search, parentId, isNew, statusList }) => {
    const [scheduleRequests, setScheduleRequests] = useState([]);
    const history = useHistory();
    const { user } = useContext(UserContext);
    const approvePermission = user.permission.some(({ code }) => ["budgetProposalApprove", "scheduleApprove"].includes(code));

    useEffect(() => {
        ScheduleService.requestList(currentOrg ? user.organization.id : 'undefined', startDate, endDate, parentId, isNew)
            .then(res => {
                setScheduleRequests(res);
                currentOrg && statusList(res);
            })
            .catch(e => notify(e.message, "error", 2000))
    }, [search, parentId])

    const modifyRequest = async ({ id, request_org, status }) => {
        const statusArr = {
            1: 3,
            2: 2,
            3: 1,
        };
        const req = {
            "request_id": id,
            "current_status": status,
            "change_status": statusArr[user.organization.obt_id],
            "comment": null,
            "parentIds": null
        }
        try {
            await ScheduleService.changeStatus(req);
            history.push(`/plan/schedule/insertion/${id}/${request_org}/${statusArr[user.organization.obt_id]}`);
        } catch (err) {
            console.error(err);
        }
    }

    const handleNav = (reqId, reqOrg, status) => {
        history.push(`/plan/schedule/insertion/${reqId}/${reqOrg}/${status}`)
    }

    const requestRender = ({ data, value }) => {
        return <b onClick={() => handleNav(data.id, data.request_org, data.status)} style={{ color: "blue", textDecoration: 'underline', cursor: 'pointer' }}>{value}</b>
    }

    const scheduleTransferDetailBtn = ({ data }) => {
        return (
            <div>
                {!approvePermission && [13, 14, 15].includes(data.status) &&
                    <Button hint="Хуваарийн өөрчлөлт засварлах" icon="edit" style={{ border: 'none' }}
                        onClick={() => modifyRequest(data)}
                    />
                }
                <Button hint="Дэлгэрэнгүй" icon='detailslayout' style={{ border: 'none' }}
                    onClick={() => handleNav(data.id, data.request_org, data.status)} />
            </div>
        )
    }

    const onCellPreparedHandler = (e) => {
        e.cellElement.style.fontSize = "12px";
        if (e.rowType === "header") {
            e.cellElement.style.fontWeight = "bold";
            e.cellElement.style.color = "#15186A";
            e.cellElement.style.alignment = "center";
            e.cellElement.style.backgroundColor = "#EEF0F4";
        }
        if (e.rowType === "data") {
            e.cellElement.style.fontWeight = "semibold"
            e.cellElement.style.color = "#000000"
            e.cellElement.style.verticalAlign = 'middle'
        }
        if (e.rowType === 'group' && e.column.dataField === 'request_org_name') {
            e.cellElement.style.color = "#15186A"
            e.cellElement.style.backgroundColor = "#fff"
            e.cellElement.style.fontWeight = "bold";
        }
    }

    return (
        <div className="card-body">
            <DataGrid
                noDataText="Дата байхгүй байна."
                dataSource={scheduleRequests}
                showBorders={true}
                showRowLines={true}
                showColumnLines={true}
                showColumnHeaders={true}
                allowColumnResizing={true}
                columnAutoWidth={true}
                wordWrapEnabled={true}
                onCellPrepared={onCellPreparedHandler}
                rowAlternationEnabled={true}
            >
                <Paging defaultPageSize={10} />
                <Grouping
                    contextMenuEnabled={false}
                    expandMode="rowClick"
                    texts={{
                        groupContinuesMessage: 'Дараагийн хуудас',
                        groupContinuedMessage: 'Өмнөх хуудас'
                    }}
                />
                <FilterRow visible={true} applyFilter={true} />
                {user.organization.obt_id !== 3 &&
                    <Column groupIndex={0} dataField="request_org_name" caption="" groupCellComponent={(e) => e.data.displayValue} visible={!currentOrg} />
                }
                <Column dataField="request_code" alignment="center" caption="Хүсэлтийн дугаар" width={90} cellRender={requestRender} />
                <Column dataField="createdAt" caption="Үүссэн огноо" dataType="date" format="yyyy-MM-dd HH:mm" width={120} />
                <Column dataField="updatedAt" caption="Шинэчлэгдсэн огноо" dataType="date" format="yyyy-MM-dd HH:mm" width={120} />
                <Column dataField="status_array" caption="Хүсэлтийн төлөв" cellRender={(e) => <div className={`schedule-transfer-status-${e.data.status}`}>{e.displayValue[0]}</div>} width={180} />
                <Column dataField="tran_group" caption="Шилжүүлгийн төрөл" width={300} />
                <Column dataField="tran_amount" alignment="right" caption="Хүсэлтийн дүн" cellRender={(e) => e.value !== "0" ? numberWithCommas(e.value) : '-'} width={110} />
                <Column dataField="request_note" caption="Нэмэлт тайлбар" hidingPriority={0} />
                <Column alignment="center" cssClass="scheduleTransferDetailBtn" caption="Үйлдэл" width={100} cellTemplate="cc156" visible={currentOrg} />
                <Template name="cc156" render={scheduleTransferDetailBtn} />
            </DataGrid>
        </div>
    )
}


export default ScheduleEditReqList

import React from 'react'
import {useState, useEffect, useContext} from 'react'
import {useHistory} from "react-router-dom";
import OrganizationService from "../../services/api/organization";
import './static/MinistryList.css'
import banner from '../../assets/imgs/govlogobanner.png'
import suld from './static/MicrosoftTeams-image (26).png'
import {Button} from "devextreme-react/button";
import backbtn from "../../assets/imgs/hzyam/back-button.png";
import notify from 'devextreme/ui/notify';
import LoadPanelComponent from "../budget/components/loadPanel";


function MinistryList() {
    const history= useHistory()
    const {innerWidth, innerHeight} = window;
    const [list, setList]= useState([])
    const [loader, setLoader] = useState(false);
    const [isMinistry, setIsMinistry]= useState(true)

    const redirect= (id, name)=>{
        // console.log("id is===>", id)
        if (id!==null){            
        history.push({pathname: "ministryPage", state: {org_id: id, name: name}})
        }
    else if(id===null)return notify("Мэдээлэл байхгүй байна!", "warning", 3000);

    }
    const backgroundPicker= (index)=>{
        switch(index){
            case 0:
            return "#C5FBD0"
            case 1:
            return "#FFBCFD"
            case 2:
            return "#C9C4FB"
            case  3:
            return "#E6E6E6"
            case 4:
            return "#DFFBC4"
            case 5:
            return "#FBECC3"
            case 6:
            return "#78AAE1"
            case 7:
            return "#6BA889"
            case 8:
            return "#82E397"
            case 9:
            return "#FCEC1A"
            case 10:
            return "#E8B487"
            case 11:
            return "#45D49B"
            case 12:
            return "#CBCCD0"
            case 13:
            return "#697DFF"
            case 14:
            return "#697DFF"
            case 15:
            return "#2DAD93"
            
        default: 
            return
        }
    }


    useEffect(()=>{
        const fetchData=async()=>{
            setLoader(true)
            let result = await OrganizationService.getMinistries();
            // console.log(result.data)
            setList(result.data)
            setLoader(false)
        }
        fetchData()

    },[])
    const changePage = (url) => {
        history.push(url);
    }
    const style = {
        cardStyle:{
            boxShadow: '2px 2px 7px 2px rgba(0,0,0,0.25)',
            minHeight: innerHeight -130,
            padding: "0rem",
            width: "100%"
        },
        ministryLogo:{width: 'auto', height: '100px' , display: 'block', marginLeft: 'auto', marginRight: 'auto', paddingTop: '25px'}
    }
    const titleRender=()=>{
        return (
            <div className='title-container'>
                <div className='title'>
                    <div className='title-texts'>
                        <div onClick={()=>setIsMinistry(true)}>
                            <div className={`title-text ${isMinistry&& "active"}`}>ЯАМ</div>
                            <div className={`underline ${isMinistry&& "active"}`}/>
                        </div>
                        <div onClick={()=>setIsMinistry(false)}>
                            <div className={`title-text ${!isMinistry&& "active"}`}>АГЕНТЛАГУУД</div>
                            <div className={`underline  ${!isMinistry&& "active"}`}/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
  return (
    <div style={{padding: 5}}>
    <div className='card ministryList' id='ministryList' style={style.cardStyle}>
        <LoadPanelComponent position={{of: '#ministryList'}} visible={loader} />
    <div className='banner'><img alt="banner" src={banner}/></div>
        <div style={{zIndex: 99999, width: "100%", marginTop: ".4rem"}}>
                        <Button width={50} height={50}  icon={backbtn} onClick={() => history.goBack()} style={{float: 'right', marginRight: "0.4rem",}} />
        </div>
    {titleRender()}
    <div className='grid-container'>
    {list.map((item, index)=>(
        <div onClick={()=> redirect(item.org_id, item.name)}  className="grid-item" key={index}>
            <div className='grid-item-logo-container'><div className='grid-item-logo'><img alt="suld" src={suld}/></div></div>
            <div style={{background: `linear-gradient(90deg, ${backgroundPicker(index)}, white)`, width: "100%"}} className='grid-item-text'>
                <h4>{item.name}</h4>
                {/* <p style={{color: "#160B3D"}}>{item.value}</p> */}
            </div>
        </div>
    ))}
    </div>
    </div>
    </div>
    
  )
}

export default MinistryList
import React from "react"
import {useContext, useState, useRef, useEffect} from "react"
import {UserContext} from "../../../hooks/UserContext"
import OrganizationService from "../../../services/api/organization"
import notify from "devextreme/ui/notify"
import TreeView from "devextreme-react/tree-view"

function TreeViewOrg({setOrgId, orgId}) {
  const {user} = useContext(UserContext)
  const [orgSortType, setOrgSortType] = useState(null)
  const [loading, setLoading] = useState(false)
  const [orgStructureAttribute, setOrgStructureAttribute] = useState([])
  const [selectedDepartmentId, setSelectedDepartmentId] = useState(null)
  const [loadingOSA, setLoadingOSA] = useState(false)
  const treeView = useRef(null)

  useEffect(() => {
    setOrgStructureAttribute([])
    if (selectedDepartmentId) {
      getOrgStructureAttribute(selectedDepartmentId)
    }
  }, [selectedDepartmentId])

  const getOrgStructureAttribute = async (orgStructureId) => {
    try {
      setLoadingOSA(true)
      let res = await OrganizationService.getOrgStructureAttribute(
        orgStructureId
      )
      setOrgStructureAttribute(res.data || [])
    } catch (e) {
      console.log(e)
      setOrgStructureAttribute([])
    }

    setLoadingOSA(false)
  }

  const createChildren = (parent) => {
    const parentId = parent ? parent.itemData.id : ""
    if (!parent) {
      setLoading(true)
    }
    return OrganizationService.updatedStateAttributes(parentId)
      .then((response) => {
        if (orgSortType === "desc") {
          response.sort((a, b) => {
            return a.state_structure_name.localeCompare(b.state_structure_name)
          })
        } else if (orgSortType === "asc") {
          response.sort((a, b) => {
            return b.state_structure_name.localeCompare(a.state_structure_name)
          })
        } else {
          response.sort((a, b) => {
            return (a.order_no || 0)
              .toString()
              .localeCompare((b.order_no || 0).toString(), "en", {
                numeric: true,
              })
          })
        }

        if (!parentId) {
          let parent = response.filter((org) => org.parent === null)
          if (parent.length > 0) {
          }

          setLoading(false)
        }
        return response
      })
      .catch(() => {
        setLoading(false)
        throw new Error("Data Loading Error")
      })
  }

  let tshz = user && user.organization && user.organization.obt_id === 3

  const selectItem = async (e) => {
    try {
      setOrgId(e.itemData.org_id)
    } catch (e) {
      notify(e.response.data.message, "error", 2000)
    }
  }

  const treeViewItemTemplate = (e) => {
    
    return (
      <div
        style={{
          flex: 1,
          justifyContent: "flex-start",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        {e.icon && (
          <div
            style={{
              background: "#E7E6EB",
              minWidth: 9,
              minHeight: 9,
              borderRadius: "100%",
              // marginRight: 10
            }}
          />
        )}

        <div style={{marginLeft: e.icon ? 10 : 5, }}>
          {e.state_structure_name}
        </div>
      </div>
    )
  }

  return (
    <div style={{display: "flex", width: "100%"}}>
      <div style={{flex: 1, width: "100%"}}>
        <div
          className="card-body"
          style={{
            backgroundColor: "#fff",
            borderRadius: "25px",
          }}
        >
          <TreeView
            ref={treeView}
            id="id"
            dataStructure="plain"
            displayExpr={"state_structure_name"}
            parentIdExpr={"parent"}
            keyExpr={"id"}
            rootValue=""
            expandedExpr={"expand"}
            // expandNodesRecursive={false}
            width={"100%"}
            itemRender={treeViewItemTemplate}
            searchEnabled={true}
            searchEditorOptions={{
              placeholder: "Хайх",
            }}
            createChildren={createChildren}
            noDataText={"Дата байхгүй байна."}
            onItemClick={selectItem}
            selectByClick={true}
            selectionMode={"single"}
          />
        </div>
      </div>
    </div>
  )
}

export default TreeViewOrg

import request from '../../shared/lib/request'

const getByIdFileType = (id) => {
    return request({
        url: `/fileType/${id}`,
        method: 'GET'
    })
}

const getAllFileType = () => {
    return request({
        url: '/fileType/',
        method: 'GET'
    })
}

const addFileType = (body) => {
    return request({
        url: '/fileType/',
        method: 'POST',
        data: body
    })
}

const updateFileType = (id, body) => {
    return request({
        url: `/fileType/${id}`,
        method: 'PUT',
        data: body
    })
}

const deleteFileType = (id) => {
    return request({
        url: `/fileType/${id}`,
        method: 'DELETE'
    })
}

const getWorkerFileType = () => {
    return request({
        url: '/fileType/getWorkerFileType',
        method: 'GET'
    })
}

const getNotificationFileType = () => {
    return request({
        url: '/fileType/getNotificationFileType',
        method: 'GET'
    })
}

const fileTypeService = {
    getByIdFileType,
    getAllFileType,
    addFileType,
    updateFileType,
    deleteFileType,
    getWorkerFileType,
    getNotificationFileType
}

export default fileTypeService;

import React, { useState, useEffect } from "react"
import notify from "devextreme/ui/notify"
import BudgetService from "../../../../services/api/budget"
import _ from "lodash"
import { budgetPalettes, getStripValues } from "../../../../util"
import Chart, {
  ArgumentAxis,
  CommonSeriesSettings,
  Crosshair,
  HorizontalLine,
  Label,
  Legend,
  Point,
  Series,
  Strip,
  Tooltip,
  ValueAxis,
} from "devextreme-react/chart";
import LoadPanelComponent from "../../../budget/components/loadPanel";
import EmptyData from "../../../budget/components/empty"

const AgendaSplinechartJileer = (props) => {
  const [chartData, setChartData] = useState([])
  const [loader, setLoader] = useState(true)

  const loadData = async () => {
    try {
      let r = await BudgetService.getAgendaCount(props.filterOrgText)
      setChartData(r)
    } catch (e) {
      notify(e.message, "error", 2000)
    } finally {
      setLoader(false)
    }
  }

  useEffect(() => {
    loadData()
  }, [props.filterOrgText])

  if (chartData.length === 0 && !loader) {
    return (
      <div
        className="card"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          family: "Segoe UI",
        }}
      >
        <EmptyData/>
      </div>
    )
  }

  const customizeTooltip = (arg) => {
    return {
      html: `<div class="tooltip-header">
      <span>${arg.seriesName} ${arg.argumentText} он:</span>
      <span>${arg.value} </span>
      </div>`
    }
  }

  const handleLegend = (e) => {
    if (e.target.isVisible()) {
      e.target.hide();
    } else {
      e.target.show();
    }
  }

  function handleMarker(item) {
    const color = item.series.isVisible() ? item.marker.fill : '#888';
    return (
      <svg>
        <rect x={0} y={0} width={12} height={12} fill={color} rx={6} ry={6}></rect>
      </svg>
    );
  }


  return (
    <div id="load11">
      <LoadPanelComponent position={{ of: "#load11" }} visible={loader} />
      <Chart
        palette={_.find(budgetPalettes, { id: 5 }).color}
        paletteExtensionMode="alternate"
        dataSource={chartData}
        onLegendClick={handleLegend}
      >
        <CommonSeriesSettings argumentField="budget_year" type="spline" width={3} >
          <Point size={7} />
        </CommonSeriesSettings>
        <Crosshair enabled={true}>
          <HorizontalLine visible={false} />
          <Label visible={true} />
        </Crosshair>
        <ValueAxis valueType="numeric" allowDecimals={false}>
          <Label format='#' />
        </ValueAxis>
        <ArgumentAxis tickInterval={1} argumentType='numeric' visualRange={{ endValue: new Date().getFullYear() }}>
          {getStripValues().map((e) => (
            <Strip startValue={e.startValue} endValue={e.endValue} color="#f5f5f5" />
          ))}
          <Label format='#' argumentFormat='format' />
        </ArgumentAxis>
        <Series valueField="undsen_hutulbur" name="Үндсэн хөтөлбөр" />
        <Series valueField="ded_hutulbur" name="Дэд хөтөлбөр" />
        <Series valueField="zoriulalt" name="Зориулалт" />
        <Series valueField="arga_hemjee" name="Арга хэмжээ" />
        <Legend
          visible={true}
          verticalAlignment="bottom"
          horizontalAlignment="center"
          font={{ family: "Segoe UI" }}
          margin={{ top: 16 }}
          orientation='horizontal'
          markerRender={handleMarker}
        />
        <Tooltip
          zIndex={100000}
          enabled={true}
          cornerRadius={6}
          border={{ visible: false }}
          customizeTooltip={customizeTooltip}
          font={{ color: '#fff' }}
          color="#555"
          location="edge"
        ></Tooltip>
      </Chart>
    </div>
  )
}

export default AgendaSplinechartJileer;

import React, {useEffect, useState} from 'react';

import PivotGridDataSource from 'devextreme/ui/pivot_grid/data_source';

import Chart, {
    AdaptiveLayout,
    CommonSeriesSettings,
    Size,
    Tooltip,
} from 'devextreme-react/chart';

import PivotGrid, {
    FieldChooser, Export,
} from 'devextreme-react/pivot-grid';


import fcsServices from "../../../../services/api/fcs";
import notify from "devextreme/ui/notify";
import Button from "devextreme-react/button";
import {Workbook} from 'exceljs';
import {saveAs} from 'file-saver-es';
// Our demo infrastructure requires us to use 'file-saver-es'.
//  We recommend that you use the official 'file-saver' package in your applications.
import {exportPivotGrid} from 'devextreme/excel_exporter';
import fcsService from "../../../../services/api/fcs";

const St1Pivot = () => {
    const [loader, setLoader] = useState(false);
    const [yearValue, setYearValue] = useState(0)
    const [monthValue, setMonthValue] = useState(0)
    const [st1End, setFcsSt1] = useState([])
    const [dataPivot, setDataPivot] = useState(null)

    // const setTimeout = () => {
    //     dataSource.expandHeaderItem('row', ['North America']);
    //     dataSource.expandHeaderItem('column', [2013]);
    // };
    const getFcsSt1End = async () => {
        const resultIss = await fcsService.getFcsSt1Bal();
        console.log("st1endddd===", resultIss)
        setFcsSt1(resultIss.data);

    }

    useEffect( ()=> getPivot() ,[st1End])

    const getPivot = () =>{
        let dataSource = new PivotGridDataSource({
            fields: [{
                caption: '',
                width: 270,
                dataField: 'c0',
                area: 'row',
                sortBySummaryField: 'Total',
            }, {
                caption: '',
                width: 270,
                dataField: 'c1',
                area: 'row',
                sortBySummaryField: 'Total',
            },{
                caption: '',
                width: 270,
                dataField: 'c2',
                area: 'row',
                sortBySummaryField: 'Total',
            }, {
                caption: '',
                width: 270,
                dataField: 'c3',
                area: 'row',
                sortBySummaryField: 'Total',
            },  {
                caption: '',
                width: 270,
                dataField: 'c4',
                area: 'row',
                sortBySummaryField: 'Total',
            }, {
                caption: 'dans',
                dataField: 'descr',
                width: 270,
                area: 'row',
            }, {
                dataField: 'year',

                area: 'column',
            }, {
                caption: 'Total',
                dataField: 'amt',
                dataType: 'number',
                summaryType: 'sum',
                format: 'currency',
                area: 'data',
            }],
            store: st1End,
        })

setDataPivot(dataSource)



    }
    const procFcsSt1Cal = async () => {
        setLoader(true);


        try {

            const result = await fcsServices.procFcsSt1Update(2022, 1);
            if (result) {

                notify('Амжилттай ', "success", 2000);
                getFcsSt1End()
                return setLoader(false);
            } else {
                return setLoader(false);
            }
        } catch (e) {
            notify(e.message, "error", 2000);
        }

    };

    const onExporting = React.useCallback((e) => {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Sales');

        exportPivotGrid({
            component: e.component,
            worksheet,
        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer], {type: 'application/octet-stream'}), 'Sales.xlsx');
            });
        });
        e.cancel = true;
    })




    return (
        <React.Fragment>
            <Button text="ST1" onClick={() => procFcsSt1Cal()} width={200} type="default"
            />

            <PivotGrid
                id="pivotgrid"
                dataSource={dataPivot}
                allowExpandAll={true}
                allowSortingBySummary={false}
                allowFiltering={true}
                showBorders={true}
                rowHeaderLayout="tree"
                showColumnTotals={false}
                showColumnGrandTotals={false}
                showRowTotals={false}
                showRowGrandTotals={false}
                onExporting={onExporting}
                // ref={(ref) => {
                //     pivotGrid = ref.instance;
                // }}
            >
                <FieldChooser enabled={true} height={400}/>
                <Export enabled={true}/>
            </PivotGrid>
        </React.Fragment>
    );

}

const sales = [{
    id: 10248,
    region: 'North America',
    country: 'United States of America',
    city: 'New York',
    amount: 1740,
    date: new Date('2013-01-06'),
}, {
    id: 10249,
    region: 'North America',
    country: 'United States of America',
    city: 'Los Angeles',
    amount: 850,
    date: new Date('2013-01-13'),
}, {
    id: 10250,
    region: 'North America',
    country: 'United States of America',
    city: 'Denver',
    amount: 2235,
    date: new Date('2015-01-07'),
}, {
    id: 10253,
    region: 'South America',
    country: 'Brazil',
    city: 'Rio de Janeiro',
    amount: 5260,
    date: new Date('2013-01-17'),
}, {
    id: 10267,
    region: 'North America',
    country: 'Canada',
    city: 'Edmonton',
    amount: 2910,
    date: new Date('2013-01-23'),
}]


const currencyFormatter = new Intl.NumberFormat(
    'en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
    },
);

function customizeTooltip(args) {
    const valueText = currencyFormatter.format(args.originalValue);
    return {
        html: `${args.seriesName} | Total<div class="currency">${valueText}</div>`,
    };
}


export default St1Pivot;

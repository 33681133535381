import React, { useState, useEffect, useMemo } from "react";
import BudgetService from "../../../../services/api/budget";
import _ from "lodash";
import notify from "devextreme/ui/notify";
import { numberWithCommas } from "../../../../util";
import { useHistory } from "react-router-dom";
import { useContext } from "react";
import { UserContext } from "../../../../hooks/UserContext";
import LoadPanelComponent from "../../../budget/components/loadPanel";
import OrganizationService from "../../../../services/api/organization";
import { Tooltip } from "devextreme-react/tooltip";
import info1 from "../../../../assets/imgs/icon/iconInfo1.png";

const TowloruulehHeaderInfo = (props) => {
    const history = useHistory();
    const [horizontalData, setHorizontalData] = useState({});
    const [loader, setLoader] = useState(false);
    const [sumVal, setSumVal] = useState(0);
    const { user } = useContext(UserContext);
    const [orgBudgetWorker, setOrgBudgetWorker] = useState([]);
    const [withAnimationVisibleDate, setWithAnimationVisibleDate] = useState(false);
    const [withAnimationVisibleDate1, setWithAnimationVisibleDate1] = useState(false);

    const tooltipAttributes = useMemo(() => {
        return {
            id: 'elementId',
            class: 'class-name'
        }
    }, []);
    const loadHorizontalData = async () => {
        try {
            setLoader(true);
            let r = await BudgetService.getTowloruulehMiniData(props.year, props.chosenValue, props.chosenMinistry ? props.chosenMinistry : "ALL",props.orgId);
            if (user.username === "02" && (props.chosenMinistry === "ALL" || props.chosenMinistry === undefined)) {
                let s = await BudgetService.getYamsData(props.year, 2);
                let sum = _.sumBy(s, 'amount');
                setSumVal(sum);
            }

            let w = await OrganizationService.getOrgBudgetByWorker(props.year, props.chosenValue,props.orgId);

            setHorizontalData(r[0]);
            setOrgBudgetWorker(w.data[0]);
            if (typeof props.rootDataSetter === 'function') {
                if (props.chosenValue === 'ALL') {
                    props.rootDataSetter(r[0], false);
                } else {
                    props.rootDataSetter(r[0], true)
                }
            }
            setLoader(false);
        } catch (e) {
            notify(e.message, 'error', 2000);
        }
    }

    const calculatePercentage = () => {
        if (horizontalData && horizontalData.amount !== 0 && horizontalData.guitsetgel !== 0) {
            const percentage = ((100 * horizontalData.guitsetgel) / horizontalData.amount).toFixed(2);
            return `${numberWithCommas(horizontalData.guitsetgel / 1000000)} (${percentage}%)`;
        } else {
            return '-'
        }
    }

    const locationHandler = () => {
        if (props.locationOrlogo) {
            history.push(props.locationOrlogo)
        }
    }

    useEffect(() => {
        loadHorizontalData();
    }, [props.year, props.orlogoType, props.chosenValue, props.chosenMinistry,props.orgId]);

    return (
        <div id="load150" className="row" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', flex: 1, minHeight: 106 }}>
            <LoadPanelComponent position={{ of: '#load150' }} visible={loader} />
            {!loader &&
                <>
                    {(horizontalData && horizontalData.amount_date !== null) && <Tooltip
                        target="#dateBudget1"
                        position="bottom"
                        visible={withAnimationVisibleDate}
                        hideOnOutsideClick={true}
                    >
                        <div style={{ padding: 10, width: orgBudgetWorker === undefined ? '16rem' : '23rem', fontFamily: 'Segoe UI', backgroundColor: '#555', color: '#fff', borderBottom: '1px solid #fff', borderRadius: 10 }}>
                            <div style={{ display: 'flex', gap: 5, justifyContent: 'center' }}>
                                <b style={{ margin: 0, width: orgBudgetWorker === undefined ? '50' : '40%', textAlign: 'end' }}>Шинэчлэгдсэн:</b>
                                <div style={{ display: 'flex', width: '60%' }}><span className="tooltip-series-name">Огноо:&nbsp;</span><label
                                    style={{ fontWeight: 'Bold' }}>{horizontalData.amount_date}</label></div>

                            </div>
                            <hr />
                            {orgBudgetWorker && orgBudgetWorker.budget_worker_name !== null &&
                                <div style={{ display: 'flex', flexDirection: 'row', gap: 5, justifyContent: 'center' }}>
                                    <div style={{ fontWeight: 'Bold', width: '40%', textAlign: 'end' }}>Баталгаажуулсан:</div>
                                    <div style={{ width: '60%' }}>
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <div style={{ display: 'flex' }}><span className="tooltip-series-name">Овог нэр:&nbsp;</span><label
                                                style={{ fontWeight: 'Bold' }}>{orgBudgetWorker?.budget_worker_name ? orgBudgetWorker?.budget_worker_name : '-'}</label></div>
                                        </div>
                                        <div style={{ display: 'flex' }}><span className="tooltip-series-name">Албан тушаал:&nbsp;</span><label
                                            style={{ fontWeight: 'Bold' }}>{orgBudgetWorker?.budget_position_name ? orgBudgetWorker?.budget_position_name : '-'}</label>
                                        </div>
                                        <div style={{ display: 'flex' }}><span className="tooltip-series-name">Огноо:&nbsp;</span><label
                                            style={{ fontWeight: 'Bold' }}>{orgBudgetWorker?.budget_approved_worker_date ? orgBudgetWorker?.budget_approved_worker_date : '-'}</label>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </Tooltip>}
                    {(horizontalData && horizontalData.guitsetgel_date !== null) && <Tooltip
                        target="#dateBudget2"
                        position="bottom"
                        visible={withAnimationVisibleDate1}
                        hideOnOutsideClick={true}
                    >
                        <div style={{ padding: 10, width: orgBudgetWorker === undefined ? '16rem' : '23rem', fontFamily: 'Segoe UI', backgroundColor: '#555', color: '#fff', borderBottom: '1px solid #fff', borderRadius: 10 }}>
                            <div style={{ display: 'flex', gap: 5, justifyContent: 'center' }}>
                                <b style={{ margin: 0, width: orgBudgetWorker === undefined ? '50%' : '40%', textAlign: 'end' }}>Шинэчлэгдсэн:</b>
                                <div style={{ display: 'flex', width: '60%' }}><span className="tooltip-series-name">Огноо:&nbsp;</span><label
                                    style={{ fontWeight: 'Bold' }}>{horizontalData.guitsetgel_date}</label></div>
                            </div>
                            <hr />
                            {orgBudgetWorker && orgBudgetWorker.performance_worker_name !== null &&
                                <div style={{ display: 'flex', flexDirection: 'row', gap: 5, justifyContent: 'center' }}>
                                    <div style={{ fontWeight: 'Bold', width: '40%', textAlign: 'end' }}>Баталгаажуулсан:</div>
                                    <div style={{ width: '60%' }}>
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <div style={{ display: 'flex' }}><span className="tooltip-series-name">Овог нэр:&nbsp;</span><label
                                                style={{ fontWeight: 'Bold' }}>{orgBudgetWorker?.performance_worker_name ? orgBudgetWorker?.performance_worker_name : '-'}</label></div>
                                        </div>
                                        <div style={{ display: 'flex' }}><span className="tooltip-series-name">Албан тушаал:&nbsp;</span><label
                                            style={{ fontWeight: 'Bold' }}>{orgBudgetWorker?.performance_position_name ? orgBudgetWorker?.performance_position_name : '-'}</label>
                                        </div>
                                        <div style={{ display: 'flex' }}><span className="tooltip-series-name">Огноо:&nbsp;</span><label
                                            style={{ fontWeight: 'Bold' }}>{orgBudgetWorker?.performance_approved_worker_date ? orgBudgetWorker?.performance_approved_worker_date : '-'}</label>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>

                    </Tooltip>}
                    {(user.username === "02" && (props.chosenMinistry === "ALL" || props.chosenMinistry === undefined)) &&
                        <>
                            <div className="card" style={{ flex: 1 }} onClick={locationHandler}>
                                <div className="card-header" style={econ2Styles.textAligner}>
                                    <span style={{
                                        color: '#2f2f2f',
                                        fontSize: '1rem',
                                        fontWeight: "700",
                                        margin: 0
                                    }}>Төсвийн орлого /сая ₮/</span>
                                </div>
                                <div className="card-body"
                                    style={{ ...econ2Styles.textAlignerSpan, ...econ2Styles.numericFontSize }}><span
                                        style={{ color: '#3DAA75', fontSize: '2rem' }}>{sumVal ? numberWithCommas(sumVal) : '-'}</span>
                                </div>
                            </div>
                            <div className="card" style={{ flex: 1 }} onClick={locationHandler}>
                                <div className="card-header" style={econ2Styles.textAligner}><span
                                    style={{ color: '#2f2f2f', fontSize: '1rem', fontWeight: "700" }}>Төсвийн гүйцэтгэл /сая ₮/</span>
                                </div>
                                <div className="card-body"
                                    style={{ ...econ2Styles.textAlignerSpan, ...econ2Styles.numericFontSize }}><span
                                        style={{
                                            color: '#003696',
                                            fontSize: '2rem'
                                        }}>{calculatePercentage()}</span>
                                </div>
                            </div>
                        </>}


                    {((props.chosenMinistry !== "ALL" && props.chosenMinistry !== undefined) || user.username !== "02") &&
                        <>
                            <div className="card" style={{ flex: 1 }} onClick={locationHandler}>
                                <div style={econ2Styles.bigDivStyle}>
                                    <div className="col-2" />
                                    <div className="col-8">
                                        <p style={{
                                            margin: '0',
                                            color: '#2f2f2f',
                                            fontSize: '1rem',
                                            fontWeight: "700",
                                        }}>Төсвийн орлого /сая ₮/</p>
                                    </div>
                                    <div className="col-2" style={{ margin: 0, display: 'flex', justifyContent: 'end', cursor: 'default' }}>

                                        <div
                                            style={horizontalData?.amount_date ? dateStyle.divStyle : {}}
                                            id="dateBudget1"
                                            onMouseEnter={() => setWithAnimationVisibleDate(true)}
                                            onMouseLeave={() => setWithAnimationVisibleDate(false)}
                                        >
                                            <p style={dateStyle.pStyle}>{horizontalData?.amount_date}</p>
                                            {horizontalData?.amount_date ? <img style={dateStyle.iconStyle} src={info1} /> : <div />}
                                        </div>
                                    </div>
                                </div>

                                <div
                                    style={{ ...econ2Styles.textAlignerSpan, ...econ2Styles.numericFontSize }}>
                                    <span
                                        style={{
                                            color: '#3DAA75',
                                            fontSize: '2rem',
                                            cursor: "pointer"
                                        }}>{horizontalData?.amount ? numberWithCommas(horizontalData?.amount / 1000000) : '-'}
                                    </span>
                                </div>
                            </div>
                            <div className="card" style={{ flex: 1 }} onClick={locationHandler}>
                                <div style={econ2Styles.bigDivStyle}>
                                    <div className="col-2" />
                                    <div className="col-8">
                                        <p style={{ color: '#2f2f2f', fontSize: '1rem', fontWeight: "700", margin: '0' }}>Төсвийн гүйцэтгэл /сая ₮/</p>
                                    </div>
                                    <div className="col-2" style={{ margin: 0, display: 'flex', justifyContent: 'end', cursor: 'default' }}>

                                        <div
                                            style={horizontalData?.guitsetgel_date ? dateStyle.divStyle : {}}
                                            id="dateBudget2"
                                            onMouseEnter={() => setWithAnimationVisibleDate1(true)}
                                            onMouseLeave={() => setWithAnimationVisibleDate1(false)}
                                        >
                                            <p style={dateStyle.pStyle}>{horizontalData?.guitsetgel_date}</p>
                                            {horizontalData?.guitsetgel_date ? <img style={dateStyle.iconStyle} src={info1} /> : <div />}
                                        </div>
                                    </div>
                                </div>
                                <div
                                    style={{ ...econ2Styles.textAlignerSpan, ...econ2Styles.numericFontSize }}>
                                    <span
                                        style={{
                                            color: '#003696',
                                            fontSize: '2rem',
                                            cursor: "pointer"
                                        }}>{calculatePercentage()}
                                    </span>
                                </div>
                            </div>
                        </>}
                </>}
        </div>
    )
}

const econ2Styles = {
    bigDivStyle: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        textAlign: 'center',
        width: '100%',
        height: '3rem',
        padding: 10
    },
    textAligner: {
        padding: '0',
        textAlign: 'center',
        fontWeight: 700
    },
    textAlignerSpan: {
        textAlign: 'center',
        fontWeight: "700",
        marginBottom: 10
    },
    numericFontSize: {
        fontSize: '1rem'
    }
}

const dateStyle = {
    divStyle: {
        position: 'relative',
        backgroundColor: '#EBF1FF',
        padding: '5px',
        border: 'none',
        width: '6rem',
        borderRadius: '2rem'
    },
    pStyle: {
        margin: '0',
        paddingLeft: '5px',
        fontSize: '0.6rem',
        textAlign: 'start',
        fontWeight: 'bold'
    },
    iconStyle: {
        position: 'absolute',
        width: '2rem',
        height: '2rem',
        right: '-5px',
        top: '-4.5px'
    }
}

export default TowloruulehHeaderInfo

import React, {useState, useEffect} from "react";
import FinanceReportService from "../../../../services/api/financeReport";
import {Format, Legend, Series, Tooltip} from "devextreme-react/chart";
import PieChart from "devextreme-react/pie-chart";
import LoadPanelComponent from "../../../budget/components/loadPanel";
import {Popup} from "devextreme-react/popup";
import PayrollSendAndNotList from "./PayrollSendAndNotList";
import ScrollView from "devextreme-react/scroll-view";
import EmptyData from "../../../budget/components/empty";

const PayrollReportPieNegtgel = (props) => {
    const [doughnutData, setDoughnutData] = useState([])
    const [loader, setLoader] = useState(false)
    const [popupDetail, setPopupDetail] = useState(false);
    const [selectedYear,setSelectedYear] = useState(props.year);
    const [selectedMonth,setSelectedMonth] = useState(props.selectedMonth);
    const [filterOrgText,setFilterOrgText] = useState(props.filterOrgText);
    const [selectedOrg,setSelectedOrg] = useState(props.selectedOrg);
    const [type,setType] = useState(null);
    const loadPivotData = async () => {
        try {
            setLoader(true);
            let r = await FinanceReportService.getPayrollPieChart(props.year, props.selectedMonth,props.selectedOrg,props.filterOrgText);
            setDoughnutData(r);
            setLoader(false);
        } catch (e) {
            setLoader(false);
            console.log('error PayrollReportPieNegtgel', e.message);
        }
    }

    useEffect(() => {
        loadPivotData();
    }, [props.year,props.selectedMonth,props.selectedOrg,props.filterOrgText])

    const customizeTooltip = (arg) => {
        return {
            text: `${arg.argumentText} - ${arg.originalValue}`,
        };
    }
    const pointClickHanlder = (e) => {
        setType(e.target.argument === 'Илгээгээгүй' ? 2 : 1);
        setFilterOrgText(props.filterOrgText);
        setSelectedOrg(props.selectedOrg);
        setSelectedMonth(props.selectedMonth);
        setSelectedYear(props.year);
        setPopupDetail(true);
    }
    const customizePoint = (point) => {
        switch (point.data['id']) {
            case 2:
                return {color: '#34347E'}
            case 1:
                return {color: '#34D47E'}
        }
    }
   /* const cstm12 = (pointInfo) => {
        return `${pointInfo.argumentText} (${pointInfo.originalValue} - ${pointInfo.percentText} )`
    }*/
    const renderDetail = props => {
        return (
            <Popup
                visible={popupDetail}
                onHiding={() => setPopupDetail(false)}
                hideOnOutsideClick={true}
                showCloseButton={true}
                height="80vh"
                showTitle={true}
                title={type === 1 ? selectedYear + ' оны ' +selectedMonth + '-р сарын илгээсэн байгууллагын жагсаалт' : selectedYear + ' оны ' +selectedMonth + '-р сарын илгээгээгүй байгууллагын жагсаалт'}
            >      <ScrollView width='100%' height='100%'>
                <div style={{height: "100%"}}>
                <div className="card-body" style={{paddingBottom: "1.6rem"}}>
                    <PayrollSendAndNotList selectedYear={selectedYear} selectedMonth={selectedMonth} selectedOrg={selectedOrg} type={type} filterOrgText={filterOrgText}/>
                </div>
                </div>
            </ScrollView>
            </Popup>
        )
    }
    return (
        <div id="load10">
            <LoadPanelComponent position={{of: '#load10'}} visible={loader} />
            {doughnutData.length > 0 ? (
                    <PieChart
                        type="doughnut"
                        palette="Soft Pastel"
                        dataSource={doughnutData}
                        customizePoint={customizePoint}
                        onPointClick={pointClickHanlder}
                    >
                        <Series argumentField="name" valueField="value">
                           {/* <SmallValuesGrouping mode="topN" topCount={3}/>*/}
                            {/*<Label position="columns" wordWrap={true} visible={true}  customizeText={cstm12} format="fixedPoint">
                                <Connector visible={true} width={0.5}/>
                            </Label>*/}
                        </Series>
                        <Legend
                            verticalAlignment="bottom"
                            horizontalAlignment="center"
                            itemTextPosition="right"
                            rowCount={2}
                        />
                        <Tooltip enabled={true} customizeTooltip={customizeTooltip}>
                            <Format type="millions"/>
                        </Tooltip>
                    </PieChart>
                ):
                <div style={{display: "flex", justifyContent: "center", alignItems: "center", family: "'Montserrat', sans-serif" }}>
                    <EmptyData/>
                </div>
            }
            {popupDetail && renderDetail()}
        </div>
    )
}

export default PayrollReportPieNegtgel;

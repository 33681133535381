import React, { useEffect, useState, useContext } from 'react';
import Button from "devextreme-react/button";
import notify from "devextreme/ui/notify";
import TextArea from 'devextreme-react/text-area';
import { UserContext } from "../../../hooks/UserContext";
import ProposalService from "../../../services/api/proposal";
import moment from "moment";
import LoadPanelComponent from "../../budget/components/loadPanel";
import paperPlaneSvg from "../../../assets/imgs/paper-plane.svg";

export default function Communication(props) {
    const { user } = useContext(UserContext);
    const [value, setValue] = useState(null);
    const [listData, setListData] = useState([]);
    const dataGridPositionId = { of: '#Grid' }
    const [loader, setLoader] = useState(false);
    const [chatMembers, setChatMembers] = useState([]);

    useEffect(() => {
        setLoader(true);
        loadData();
    }, []);

    useEffect(() => {
        filterMembers();
    }, [listData])

    /*const iconStyle = {
        fill: 'red', // Replace 'red' with the desired color value
    };*/

    const loadData = async () => {
        try {
            const res = await ProposalService.getCommunication(props.proposal.id);
            // console.log("dataL ", res.data);
            setLoader(false);
            setListData(res.data);
        } catch (e) {
            setLoader(false);
            console.error(e);
        }
    }

    const onEnterKey = (e) => {
        // onSubmit();
    }

    const onTextAreaValueChanged = (e) => setValue(e.value);

    const onSubmit = async (e) => {
        const data = {
            budget_project_id: props.proposal.id,
            description: value,
            created_user_id: user.id,
        }
        const date = new Date();
        /*const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const day = date.getDate();*/

        const valueData = {
            budget_project_id: props.proposal.id,
            description: value,
            created_user_id: user.id,
            // createdAt: `${year}-${month}-${day}`,
            createdAt: date,
            user_name: user.first_name
        }

        try {
            const res = await ProposalService.setCommunication(data, props.proposal.org_id);
            if (res.success) {
                setListData(listData.concat([valueData]));
                setValue('');

                // // TODO: Notification service
                // await notificationFunctions.postNotification(props.proposal.org_id, user, 3);

                notify(`${res.message}`, 'success');
            }
        } catch (e) {
            console.error(e);
            notify(`Алдаа гарлаа`, 'error');
        }
    };

    const filterMembers = () => {
        const newArr = [];
        listData.forEach((e) => {
            if (!newArr.some((item) => item.created_user_id === e.created_user_id)) {
                newArr.push(e);
            }
        });
        setChatMembers(newArr);
    }

    return (
        <React.Fragment>
            <LoadPanelComponent position={dataGridPositionId} visible={loader} />
            <div style={{ display: 'flex', gap: '1rem', justifyContent: 'space-between', width: '100%', height: '70vh' }}>
                <div style={{ border: '1px solid #eaeaea', borderRadius: 10, overflowY: 'auto' }} className='col-3 responsive-width responsive-none'>
                    <div style={{ fontSize: '12px', fontWeight: '500', color: 'grey', textAlign: 'center', padding: 10, borderBottom: '1px solid #eff0f3' }}>Хэлэлцүүлгэнд оролцсон хэрэглэгчид</div>
                    <div style={{ padding: 20 }}>
                        {Array.isArray(chatMembers) && chatMembers.map(data => {
                            return (
                                < div style={{ display: 'flex', gap: '1rem', marginBottom: 20 }}>
                                    <img src={data?.img ? data?.img : 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png'} style={{ width: '2.5rem', height: '2.5rem', borderRadius: '50%' }} />
                                    <div>
                                        <p style={{ fontWeight: 'bold', margin: 0 }}>{data.user_name ? data.user_name : 'Хэрэглэгч'}</p>
                                        <p className="orgNameFormat" style={{ fontSize: "11px", color: 'grey', margin: 0 }}>{data.org_name} {data.position_name ? '- ' + data.position_name : ''}</p>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div style={{ overflowY: 'auto', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }} className='col-9 responsive-col'>
                    <div style={{}}>
                        {Array.isArray(listData) && listData.map(data => {
                            return (
                                <div style={{ display: 'flex', margin: 20, gap: 100 }}>
                                    <div style={{ display: 'flex', flexDirection: 'row', flex: 1, gap: '1rem' }}>
                                        {data.created_user_id != user.id &&
                                            <>
                                                <img src={data?.img ? data?.img : 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png'} style={{ width: '2.5rem', height: '2.5rem', borderRadius: '50%' }} />
                                                <div>
                                                    <div style={{ fontSize: 10, color: 'grey', marginBottom: 5 }}>{data.user_name ? data.user_name : 'Хэрэглэгч'}</div>
                                                    <div style={{ border: '1px solid #eaeaea', flex: 1, borderRadius: 10, padding: 15, boxShadow: '8px 8px 10px rgba(229, 231, 233, 0.5)' }}>
                                                        <span style={{ fontSize: '14px' }}>{data.description}</span>
                                                    </div>
                                                    <div style={{ fontSize: 10, color: 'grey', marginTop: '0.5rem' }}> {moment(data.createdAt).format("YYYY-MM-DD HH:mm")}</div>
                                                </div>
                                            </>
                                        }
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'row', flex: 1, gap: '1rem', justifyContent: 'end' }}>
                                        {data.created_user_id === user.id &&
                                            <>
                                                <div>
                                                    <div style={{ flex: 1, border: '1px solid #E8EDF6', borderRadius: 10, padding: 15, background: '#E8EDF6', boxShadow: '8px 8px 10px rgba(229, 231, 233, 0.5) ' }}>
                                                        <span style={{ fontSize: '14px' }}>{data.description}</span>
                                                    </div>
                                                    <div style={{ fontSize: 10, color: 'grey', display: 'block', textAlign: 'end', marginTop: '0.5rem' }}> {moment(data.createdAt).format("YYYY-MM-DD HH:mm")}</div>
                                                </div>
                                                <img src={user?.file_path ? user.file_path : 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png'} style={{ width: '2.5rem', height: '2.5rem', borderRadius: '50%' }} />
                                            </>
                                        }
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <div className="row" style={{ display: 'flex', background: 'white', justifyContent: 'space-between', position: 'sticky', bottom: 0 }}>
                        <div style={{ flex: 1 }}>
                            <TextArea
                                height={'2.5rem'}
                                style={{ borderRadius: 10 }}
                                value={value}
                                readonly={true}
                                onEnterKey={onEnterKey}
                                onValueChanged={onTextAreaValueChanged} />
                        </div>
                        <div style={{ marginLeft: 10 }}>
                            {/* <button className='acceptButton' style={{ display: 'flex', alignItems: 'center', gap: '5px' }} onClick={onSubmit}><FiSend /> Илгээх </button> */}
                            <Button
                                icon={paperPlaneSvg}
                                style={{ borderRadius: 10, border: 'none', color: 'white' }}
                                text='Илгээх'
                                className='acceptButton'
                                height={'2.5rem'}
                                onClick={onSubmit}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment >
    );
}

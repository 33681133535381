import { Fragment } from 'react';
import { useHistory } from "react-router-dom";

import classes from './inProgress.module.css';

function Progress() {
    const history = useHistory()
    const goBack = () => {
        history.goBack();
    }
    return (
        <Fragment>
            <div className={classes.container}>
                <div className={classes['mini-container']}>
                    <h1 className={classes.header}>
                        <span className={classes['header-main']}>Хөгжүүлэлт</span>
                        <span className={classes['header-sub']}>хийгдэж байна</span>
                    </h1>
                    <a onClick={goBack} href='#' className={`${classes.btn} ${classes.btnWhite} ${classes.btnAnimated}`}>Буцах</a>
                </div>
            </div>
        </Fragment>
    )
}

export default Progress;
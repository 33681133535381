import React, { useState, useEffect, useContext } from "react";
import SelectBox from "devextreme-react/select-box";
import { getYears } from "../../util";
import TowloruulehHeaderInfo from "./components/towloruuleh/TowloruulehHeaderInfo";
import TowloruulehZadgaiGrid from "./components/towloruuleh/TowloruulehZadgaiGrid";
import TowloruulehZadgaiSalbar from "./components/towloruuleh/TowloruulehZadgaiSalbar";
import TowloruulehTenYears from "./components/towloruuleh/TowloruulehTenYears";
import UpdatedBudgetListWithOrgs from "./components/UpdatedBudgetListWithOrgs";
import { Popup } from "devextreme-react/popup";
import { Button } from "devextreme-react/button";
import TowloruulehMonthly from "./components/towloruuleh/TowloruulehMonthly";
import notify from "devextreme/ui/notify";
import BudgetService from "../../services/api/budget";
import { UserContext } from "../../hooks/UserContext";
import OrganizationService from "../../services/api/organization";
import SourceFooter from "./components/Source";
import TowloruulehOnlyPivotGrid from "./components/towloruuleh/TowloruulehOnlyPivotGrid";
import TowloruulehEdiinZasagPieChart from "./components/towloruuleh/TowloruulehEdiinZasagPieChart";
import ExpenseOnlyPivotGrid from "./components/zarlaga/ExpenseOnlyPivotGrid";
import EmptyData from "../budget/components/empty";
import lawIcon from "../../assets/imgs/laaawIcon.svg";
import PlanGuide from "../../assets/imgs/Тайлан бэлдэх заавар аргачлал төсөл.pdf";
import { Accordion } from "devextreme-react";
import OrganizationSelectComponent from "../../components/OrganizationSelectComponent";

const BudgetTowloruuleh2 = (props) => {
  const { user } = useContext(UserContext);
  const [law, setLaw] = useState([]);
  const [showLawModal, setShowLawModal] = useState(false);
  const [showOrderModal, setShowOrderModal] = useState(false);
  const [yearList, setYearList] = useState([]);
  const [rootData, setRootData] = useState([]);
  const [calculatedPercentage, setCalculatedPercentage] = useState(null);
  const [calculatedGuitestgelPercentage, setCalculatedGuitsetgelPercentage] =
    useState(null);
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [chosenValue, setChosenValue] = useState("ALL");
  const [chosenMinistry, setChosenMinistry] = useState("ALL");
  const [orgs, setOrgs] = useState([]);
  const [ministry, SetMinistry] = useState(null);
  const [firstChartVisible, setFirstChartVisible] = useState(false);
  const [whichComponentMaxmized, setWhichComponentMaximized] = useState(null);
  const [pivotGridVisible, setPivotGridVisible] = useState(false);
  const [checkData, setCheckData] = useState(true);
  const [checkData1, setCheckData1] = useState(true);
  const [chosenDataGridFilter, setChosenDataGridFilter] = useState({
    monthField: "ALL",
    valueField: "Батлагдсан",
  });
  const [selectedData, setSelectedData] = useState({});
  const loadOrgs = async (year) => {
    try {
      let r =
        user.username !== "02"
          ? await BudgetService.getFilteredOrganizations(year, 1)
          : chosenMinistry === "ALL"
          ? []
          : await BudgetService.getFilteredOrganizationsBySoa(
              year,
              chosenMinistry
            );
      setOrgs(
        r.length > 0 && r.length === 1
          ? r
          : [
              {
                budget_year: year,
                text_name: "БҮГД",
                value_name: "ALL",
              },
              ...r,
            ]
      );
      r.length > 1
        ? setChosenValue("ALL")
        : setChosenValue(r.length > 0 && r[0].value_name);
    } catch (e) {
      notify(e.message, "error", 2000);
    }
  };
  const loadMinistry = async () => {
    try {
      let result = await (await OrganizationService.getMinistryList()).data;
      SetMinistry([
        {
          budget_year: currentYear,
          name: "БҮГД",
          org_id: "ALL",
        },
        ...result,
      ]);
      setChosenValue("ALL");
    } catch (error) {
      notify(error.message, "error", 2000);
    }
  };
  const loadLaw = async () => {
    try {
      let r = await BudgetService.getBudgetLawByYear(currentYear - 1);
      setLaw(r);
      console.log("::::", r);
    } catch (e) {
      notify(e.message, "error", 2000);
    }
  };
  const accordionData = [{ id: 1, title: "", file: PlanGuide }];
  const renderItems = (e) => {
    return (
      <object
        width="100%"
        height="90vh"
        style={{ height: "90vh" }}
        data={e.file}
        type="application/pdf"
      />
    );
  };

  const renderTitles = (e) => {
    return <span>{e.title}</span>;
  };
  const rootDataSetter = (root, handler) => {
    if (handler) {
      if (root && rootData && root.amount && rootData.amount) {
        if (root.guitsetgel && rootData.guitsetgel) {
          setCalculatedGuitsetgelPercentage(
            (root.guitsetgel * 100) / rootData.guitsetgel
          );
        } else {
          setCalculatedGuitsetgelPercentage(null);
        }
        setCalculatedPercentage((root.amount * 100) / rootData.amount);
      } else {
        setCalculatedPercentage("");
        setCalculatedGuitsetgelPercentage("");
      }
    } else {
      setCalculatedPercentage(null);
      setCalculatedGuitsetgelPercentage(null);
      setRootData(root);
    }
  };

  useEffect(() => {
    loadYearList();
    loadMinistry();
    // setChosenValue(orgs[0]["value_name"])
  }, []);

  const loadYearList = async () => {
    try {
      let result = await await BudgetService.getOrgBudgetYear();
      setYearList(result);
    } catch (error) {
      notify(error.message, "error", 2000);
    }
  };
  useEffect(() => {
    setChosenValue("ALL");
    loadOrgs(currentYear);
  }, [chosenMinistry]);
  useEffect(() => {
    loadLaw();
  }, [currentYear]);

  const popUpHandler = (trueFalser, value) => {
    setFirstChartVisible(trueFalser);
    setWhichComponentMaximized(value);
  };
  const globalFilterHandler = (value) => {
    // if (orgs.length>1){
    //     if (value === chosenValue) {
    //         setChosenValue('ALL')
    //     } else {
    //         setChosenValue(value);
    //     }
    // } else{
    //     setChosenValue('ALL')
    // }
  };
  const pivotGridToggler = () => {
    setPivotGridVisible(!pivotGridVisible);
  };

  let maximizeIcon =
    '<svg t="1659165394467" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2309" width="16" height="16"><path d="M504.576 603.428571a19.748571 19.748571 0 0 1-5.705143 13.129143l-189.696 189.696 82.285714 82.285715a36.425143 36.425143 0 0 1 10.861715 25.709714c0 20.004571-16.566857 36.571429-36.571429 36.571428h-256c-20.004571 0-36.571429-16.566857-36.571428-36.571428v-256c0-20.004571 16.566857-36.571429 36.571428-36.571429a36.205714 36.205714 0 0 1 25.709714 10.861715l82.285715 82.285714 189.696-189.696c3.437714-3.437714 8.557714-5.705143 13.129143-5.705143s9.728 2.304 13.129142 5.705143l65.133715 65.133714a19.602286 19.602286 0 0 1 5.705143 13.129143zM950.857143 109.714286v256c0 20.004571-16.566857 36.571429-36.571429 36.571428a36.205714 36.205714 0 0 1-25.709714-10.861714l-82.285714-82.285714-189.696 189.696c-3.437714 3.437714-8.557714 5.705143-13.129143 5.705143s-9.728-2.304-13.129143-5.705143l-65.133714-65.133715c-3.437714-3.437714-5.705143-8.557714-5.705143-13.129142s2.304-9.728 5.705143-13.129143l189.696-189.696-82.285715-82.285715a36.425143 36.425143 0 0 1-10.861714-25.709714c0-20.004571 16.566857-36.571429 36.571429-36.571428h256c20.004571 0 36.571429 16.566857 36.571428 36.571428z" fill="#515151" p-id="2310"></path></svg>';

  return (
    <React.Fragment>
      <Popup
        visible={firstChartVisible}
        onHiding={() => setFirstChartVisible(false)}
        hideOnOutsideClick={true}
        height={550}
        title={
          whichComponentMaxmized && whichComponentMaxmized === 1
            ? "НИЙТ ОРЛОГО"
            : whichComponentMaxmized === 2
            ? "ОРЛОГО /нэр төрлөөр, сая ₮/"
            : whichComponentMaxmized === 3
            ? "ТӨСӨВ, ГҮЙЦЭТГЭЛ /жилээр, сая ₮/"
            : "ТӨСӨВ, ГҮЙЦЭТГЭЛ /сараар, сая ₮/"
        }
        showTitle={true}
      >
        {whichComponentMaxmized === 1 && selectedData.orgId && typeof selectedData.orgId[0] === "number" && (
          <TowloruulehZadgaiGrid
            chosenMinistry={chosenMinistry}
            year={currentYear}
            chosenValue={chosenValue}
            orgId = {selectedData.orgId}
          />
        )}
        {whichComponentMaxmized === 2 && selectedData.orgId && typeof selectedData.orgId[0] === "number" && (
          <TowloruulehZadgaiSalbar
            year={currentYear}
            chosenMinistry={chosenMinistry}
            filterMonthObject={chosenDataGridFilter}
            filterOrgText={chosenValue}
            legendVisible={true}
            orgId = {selectedData.orgId}
          />
        )}
        {whichComponentMaxmized === 3 && selectedData.orgId && typeof selectedData.orgId[0] === "number" && (
          <TowloruulehTenYears
            chosenMinistry={chosenMinistry}
            chosenValue={chosenValue}
            orgId = {selectedData.orgId}
          />
        )}
        {whichComponentMaxmized === 4 && selectedData.orgId && typeof selectedData.orgId[0] === "number" && (
          <TowloruulehMonthly
            chosenMinistry={chosenMinistry}
            year={currentYear}
            filterOrgText={chosenValue}
            orgId = {selectedData.orgId}
          />
        )}
      </Popup>
      <Popup
        visible={showLawModal}
        onHiding={() => setShowLawModal(false)}
        hideOnOutsideClick={true}
        showTitle={false}
      >
        <div style={{ height: "100%" }}>
          {law && law.length != 0 && showLawModal && (
            <iframe
              id="lawForBudget"
              width="100%"
              height="100%"
              src={law[0].legal_url}
            ></iframe>
          )}
        </div>
      </Popup>
      <Popup
        visible={showOrderModal}
        onHiding={() => setShowOrderModal(false)}
        hideOnOutsideClick={true}
        height="90vh"
        showTitle={false}
      >
        <Accordion
          dataSource={accordionData}
          collapsible={false}
          multiple={false}
          itemTitleRender={renderTitles}
          itemRender={renderItems}
        />
      </Popup>
      <div
        style={{
          padding: 10,
          display: "flex",
          gap: "1rem",
          flexWrap: "wrap",
          alignItems: "center",
        }}
      >
        <div>
          <SelectBox
            items={yearList}
            valueExpr="value"
            displayExpr="value"
            width="10rem"
            label="Төсвийн жил"
            defaultValue={currentYear}
            onValueChanged={(e) => {
              setCurrentYear(e.value);
              loadOrgs(e.value);
            }}
            placeholder="сонгох"
            style={{ borderRadius: 10 }}
          />
        </div>
        {user.username === "02" && (
          <div>
            {ministry && ministry.length !== 0 && (
              <SelectBox
                items={ministry}
                defaultValue={chosenMinistry}
                label="Яам"
                width={250}
                valueExpr="org_id"
                displayExpr="name"
                onValueChanged={(e) => {
                  setChosenMinistry(e.value);
                  setChosenValue(() => "ALL");
                }}
                placeholder="сонгох"
              />
            )}
          </div>
        )}
        <div>
          <OrganizationSelectComponent
              selectedData={(d) => {
                setSelectedData(d)
              }}
              selectedYear={(d) => d}
              showClearButton={false}
              showYear={false}
              hideLabel={true}
              labelMode = {'floating'}
              width = {600}
              type = {1}
              year = {currentYear}
              defaultId = {(user.organization.obt_id === 2 && user.organization.parent_id !== user.organization.parent_org_id) ? user.organization.parent_id : user.organization.id}
          />
        {/*  <SelectBox
            items={orgs}
            value={chosenValue}
            disabled={orgs && orgs.length === 1 ? true : false}
            width="22rem"
            label="Байгууллага"
            valueExpr="value_name"
            displayExpr="text_name"
            onValueChanged={(e) => setChosenValue(e.value)}
            placeholder={
              user.organization.obt_id !== 3 ? "БҮГД" : user.organization.name
            }
            style={{ borderRadius: 10 }}
          />*/}
        </div>
        {law && law.length !== 0 && (
          <div style={{ height: 28.5 }}>
            <Button
              style={{ fontFamily: "Segoe UI", fontWeight: "bold" }}
              icon={lawIcon}
              onClick={() => setShowLawModal(true)}
              text={law[0].name}
            />
          </div>
        )}
        <div
          style={{
            display: "flex",
            flex: 1,
            alignItems: "center",
            justifyContent: "flex-end",
            gap: "1rem",
          }}
        >
          {calculatedPercentage && (
            <span style={{ fontWeight: "bold" }}>
              {" "}
              Нийт төсвийн:{" "}
              <span style={{ fontWeight: "bold", color: "#FF0000" }}>
                {calculatedPercentage.toFixed(2)}%
              </span>
            </span>
          )}
          {calculatedGuitestgelPercentage && (
            <span style={{ fontWeight: "bold" }}>
              {" "}
              Нийт гүйцэтгэлийн:{" "}
              <span style={{ fontWeight: "bold", color: "#264b96" }}>
                {calculatedGuitestgelPercentage.toFixed(2)}%
              </span>
            </span>
          )}
        </div>
      </div>
      {chosenMinistry === "ALL" ||
      chosenMinistry === 144 ||
      chosenMinistry === 260 ? (
        <div style={{ display: "flex", flexDirection: "column" }}>
          { selectedData.orgId && typeof selectedData.orgId[0] === "number" && <TowloruulehHeaderInfo
              year={currentYear}
              orlogoType="Бүгд"
              chosenMinistry={chosenMinistry}
              chosenValue={chosenValue}
              rootDataSetter={rootDataSetter}
              orgId = {selectedData.orgId}
          />
          }
          <div
            style={{ flexDirection: "row", flex: 1 }}
            className="d-flex responsive-flex"
          >
            <div
              style={{ marginBottom: 10, flex: 0.5 }}
              className="col-6 responsive-col"
            >
              <div className="card" style={{ height: "33rem" }}>
                <div className="card-header">
                  <div
                    style={{
                      flex: 1,
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "row",
                    }}
                  >
                    <span style={{ fontWeight: "700" }}>
                      1. БАЙГУУЛЛАГААР /сая ₮/
                    </span>
                    <Button
                      style={{
                        border: "0px solid transparent",
                        borderTopRightRadius: "15px",
                      }}
                      icon={maximizeIcon}
                      onClick={() => popUpHandler(true, 1)}
                    />
                  </div>
                </div>
                {
                    selectedData.orgId && typeof selectedData.orgId[0] === "number" && <div className="card-body" style={{ padding: "1.8rem 0" }}>
                      {user.username !== "02" ? (
                          <TowloruulehZadgaiGrid
                              callFunc={globalFilterHandler}
                              year={currentYear}
                              chosenValue={chosenValue} orgId = {selectedData.orgId}
                          />
                      ) : chosenValue === "ALL" && chosenMinistry === "ALL" ? (
                          <UpdatedBudgetListWithOrgs
                              currentYear={currentYear}
                              currentType={2}
                          />
                      ) : (
                          <TowloruulehZadgaiGrid
                              year={currentYear}
                              chosenMinistry={chosenMinistry}
                              chosenValue={chosenValue} orgId = {selectedData.orgId}
                          />
                      )}
                    </div>
                }

              </div>
            </div>
            <div
              style={{ marginBottom: 10, flex: 0.5 }}
              className="col-6 responsive-col"
            >
              <div className="card" style={{ height: "33rem" }}>
                <div className="card-header">
                  <div
                    style={{
                      flex: 1,
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "row",
                    }}
                  >
                    <span style={{ fontWeight: "700" }}>
                      2. ТӨСӨВ, ГҮЙЦЭТГЭЛ /жилээр, сая ₮/
                    </span>
                    <Button
                      style={{
                        border: "0px solid transparent",
                        borderTopRightRadius: "15px",
                      }}
                      icon={maximizeIcon}
                      onClick={() => popUpHandler(true, 3)}
                    />
                  </div>
                </div>
                {
                    selectedData.orgId && typeof selectedData.orgId[0] === "number" && <div className="card-body">
                      <TowloruulehTenYears
                          chosenMinistry={chosenMinistry}
                          chosenValue={chosenValue} orgId = {selectedData.orgId}
                      />
                    </div>
                }
              </div>
            </div>
          </div>
          <div
            style={{ flexDirection: "row", flex: 1 }}
            className="d-flex responsive-flex"
          >
            {!(
              chosenMinistry === "ALL" &&
              chosenValue === "ALL" &&
              user.username === "02"
            ) && (
              <div
                style={{ marginBottom: 10, flex: 0.5 }}
                className="col-6 responsive-col"
              >
                <div className="card" style={{ height: "33rem" }}>
                  <div className="card-header">
                    <div
                      style={{
                        flex: 1,
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: "row",
                      }}
                    >
                      <span style={{ fontWeight: "700" }}>
                        3. ОРЛОГЫН БҮРДЭЛ /ЭЗА, сая ₮/
                      </span>
                      <Button
                        style={{
                          border: "0px solid transparent",
                          borderTopRightRadius: "15px",
                        }}
                        icon={maximizeIcon}
                        onClick={() => popUpHandler(true, 2)}
                      />
                    </div>
                  </div>
                  {
                      selectedData.orgId && typeof selectedData.orgId[0] === "number" && <div className="card-body" style={{ marginTop: "2rem" }}>
                        <TowloruulehZadgaiSalbar
                            pivotGridHanlder={pivotGridToggler}
                            year={currentYear}
                            chosenMinistry={chosenMinistry}
                            filterMonthObject={chosenDataGridFilter}
                            filterOrgText={chosenValue}
                            legendVisible={false}
                            orgId = {selectedData.orgId}
                        />
                      </div>
                  }
                </div>
              </div>
            )}
            {!(
              chosenMinistry === "ALL" &&
              chosenValue === "ALL" &&
              user.username === "02"
            ) && (
              <div
                style={{ marginBottom: 10, flex: 0.5 }}
                className="col-6 responsive-col"
              >
                <div className="card" style={{ height: "33rem" }}>
                  <div className="card-header">
                    <div
                      style={{
                        flex: 1,
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: "row",
                      }}
                    >
                      <span style={{ fontWeight: "700" }}>
                        4. ТӨСӨВ, ГҮЙЦЭТГЭЛ /сараар, сая ₮/
                      </span>
                      <Button
                        style={{
                          border: "0px solid transparent",
                          borderTopRightRadius: "15px",
                        }}
                        icon={maximizeIcon}
                        onClick={() => popUpHandler(true, 4)}
                      />
                    </div>
                  </div>
                  {
                      selectedData.orgId && typeof selectedData.orgId[0] === "number" &&
                      <div className="card-body" style={{marginTop: "2rem"}}>
                        <TowloruulehMonthly
                            year={currentYear}
                            ministry={chosenMinistry}
                            filterOrgText={chosenValue}
                            orgId = {selectedData.orgId}
                        />
                      </div>
                  }
                </div>
              </div>
            )}
          </div>
          {!(
            chosenMinistry === "ALL" &&
            chosenValue === "ALL" &&
            user.username === "02"
          ) &&
            pivotGridVisible && (
              <div
                style={{ flexDirection: "row", flex: 1 }}
                className="d-flex responsive-flex"
              >
                {checkData && (
                  <div
                    style={{ marginBottom: 10, flex: 0.5 }}
                    className="col-6 responsive-col"
                  >
                    <div className="card">
                      <div className="card-header">
                        <span style={{ fontWeight: "700" }}>
                          5. ОРЛОГЫН ЗАДАРГАА /нэр төрлөөр, сая ₮/
                        </span>
                      </div>
                      <div className="card-body">
                        <TowloruulehOnlyPivotGrid
                          year={currentYear}
                          chosenMinistry={chosenMinistry}
                          chosenValue={chosenValue}
                          checkData={(data) => {
                            setCheckData(data);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                )}
                {checkData && (
                  <div
                    style={{ marginBottom: 10, flex: 0.5 }}
                    className="col-6 responsive-col"
                  >
                    <div className="card">
                      <div className="card-header">
                        <span style={{ fontWeight: "700" }}>
                          6. ОРЛОГЫН ЗАДАРГАА /нэр төрлөөр, сая ₮/
                        </span>
                      </div>
                      <div className="card-body">
                        <TowloruulehEdiinZasagPieChart
                          year={currentYear}
                          chosenMinistry={chosenMinistry}
                          chosenValue={chosenValue}
                          checkData={(data) => {
                            setCheckData1(data);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            family: "Segoe Ui",
          }}
        >
          <EmptyData />
        </div>
      )}
      <div className="card">
        <div className="card-header">
          <span style={{ fontWeight: "700" }}>
            5. ЭДИЙН ЗАСГИЙН АНГИЛЛААР /сая ₮/
          </span>
        </div>
        {
            selectedData.orgId && typeof selectedData.orgId[0] === "number" && <div className="card-body">
              <ExpenseOnlyPivotGrid
                  year={currentYear}
                  chosenMinistry={"ALL"}
                  chosenValue={chosenValue}
                  calculatedPercentage={calculatedPercentage}
                  calculatedGuitestgelPercentage={calculatedGuitestgelPercentage}
                  econType={1}
                  type="Бүгд"
                  orgId = {selectedData.orgId}
              />
            </div>
        }
      </div>
      <SourceFooter />
    </React.Fragment>
  );
};

export default BudgetTowloruuleh2;

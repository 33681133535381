import ReactPlayer from 'react-player/youtube'

function YoutubePlayerComponent({url}) {
  function extractVideoId(url) {
    let videoId = null;  
    if (url.includes("youtube.com")) {
      try {
        const startIdx = url.indexOf("v=");
        let temp = url.substring(startIdx + 2);
        const ampersandIdx = temp.indexOf("&");
        if (ampersandIdx !== -1) {
          temp = temp.substring(0, ampersandIdx);
        }
  
        videoId = temp;
      } catch (error) {}
    } else if (url.includes("youtu.be")) {
      try {
        const startIdx = url.lastIndexOf("/");
        videoId = url.substring(startIdx + 1);
      } catch (error) {}
    }
  
    return videoId;
  }
  
  return <ReactPlayer url={url}  style={{minHeight: "35vh", minWidth: "100%"}} controls={true}/>
}

export default YoutubePlayerComponent

import React, {useState, useEffect, useContext, useRef, useCallback} from "react";
import notify from "devextreme/ui/notify";
import PlanService from "../../../services/api/plan";
import {
    Chart,
    Series,
    Tooltip,
    CommonSeriesSettings,
    Format,
    Label,
    Legend, HorizontalLine, Crosshair, ArgumentAxis
} from 'devextreme-react/chart';
import {numberWithCommas} from "../../../util";
import LoadPanelComponent from "../../budget/components/loadPanel";
import Toolbar, { Item } from 'devextreme-react/toolbar'
import { DropDownButton } from "devextreme-react";

const WholeAmountBarChart = (props) => {

    const [barChartData, setBarChartData] = useState([]);
    const [totalAmount, setTotalAmount] = useState(0);
    const [loader, setLoader] = useState(false);
    const chartRef = useRef(null)
    const loadBarChartData = async () => {
        try {
            setLoader(true);
            let r = await PlanService.retrieveBarChartData(props.selectedYear, props.selectedOrg);
            let susu = await r.reduce((a,b) => a+(b.sum || 0), 0);
            setTotalAmount(susu);
            setBarChartData(r);
            setLoader(false);
        } catch (e) {
            setLoader(false);
            return notify(e.message, "warning", 3000);
        }
    }

    // const customizeTooltip = (arg) => {
    //     return arg.value > 1000000000 ?{text: `${arg.argumentText}-р сар ${numberWithCommas(arg.value/1000000000)} Тэрбум ₮ `}: {text: `${arg.argumentText}-р сар ${numberWithCommas(arg.value/1000000)} Сая ₮ `}
    // }

    const customizeTooltip = (arg) => {
        if (arg.seriesName === 'Хуваарийн өөрчлөлт') {
            let r = barChartData[(arg.argument * 1 - 1)-1];
            let q = ((arg.value - (r && r.budget) > 0 ? arg.value / (r && r.budget) : (r && r.budget) / arg.value)).toFixed(2);
            let p = ((arg.value * 100 / (r && r.budget)) - 100).toFixed(2);
            let d = (arg.value - (r && r.budget));
            let f = d < 0 ? 'бага' : 'их';

            if (isFinite(q) && !isNaN(q)) {
                return {
                    text: `Хуваарь ${arg.argumentText}-р сар\n Өмнөх сараас\n\n Зөрүү: ${numberWithCommas(d / 1000000)}\n Хувь: ${p}%\n Өөрчлөлт: ${q} дахин ${f}`
                }
            } else {
                return {
                    text: `Тооцоолох боломжгүй`
                }
            }
        }
        else if  (arg.seriesName === 'Гүйцэтгэлийн өөрчлөлт'){
            let r = barChartData[(arg.argument * 1 - 1)-1];
            let q = ((arg.value - (r && r.performance) > 0 ? arg.value / (r && r.performance) : (r && r.performance) / arg.value)).toFixed(2);
            let p = ((arg.value * 100 / (r && r.performance)) - 100).toFixed(2);
            let d = (arg.value - (r && r.performance));
            let f = d < 0 ? 'бага' : 'их';
            let r1 = barChartData[(arg.argument * 1)-1];
            let u = ((r1 && r1.budget) - arg.value);
            let u1 = ((arg.value * 100 / (r1 && r1.budget))).toFixed(2);
            if (isFinite(q) && !isNaN(q)) {
                return {
                    text: `Гүйцэтгэл ${arg.argumentText}-р сар\nЗөрүү: ${numberWithCommas(u / 1000000)}\n Xувь: ${u1}%\n--------------------\n Өмнөх сараас\n Зөрүү: ${numberWithCommas(d / 1000000)}\n Хувь: ${p}%\n Өөрчлөлт: ${q} дахин ${f}\n `
                }
            } else {
                return {
                    text: `Тооцоолох боломжгүй`
                }
            }
        }
        else {
            return {
                text: `${arg.seriesName}: ${numberWithCommas(arg.value / 1000000)}`
            };
        }
    }

    const downloadChart = useCallback((format) => {
            chartRef.current.instance.exportTo('file',format.toLowerCase())
    },[chartRef.current])

    const DropDownItem = useCallback((e) => {
        // console.log('test',e)
        return (
            // <div style={{paddingRight:'1em', paddingLeft:'1em', width:'150%'}}>{e.data.format}</div>
            <div>{e.data.format}</div>
        )
    },[])

    const DownloadChartButton = useCallback(() => {
        return(
            <DropDownButton
                icon="download"
                items={[
                    {
                        format:'PDF'
                    },
                    {
                        format:'PNG'
                    },
                    {
                        format:'SVG'
                    },
                    {
                        format:'JPEG'
                    }
                ]}
                onItemClick={(e)=>{
                    // console.log('clicked item: ',e)
                    downloadChart(e.itemData.format)
        
                }}
                displayExpr="format"
                itemComponent={DropDownItem}
                width={'5em'}
                style={{paddingLeft:'1em', paddingRight:'1em'}}
                showArrowIcon={false}
            >

            </DropDownButton>
        )
    },[DropDownItem])

    useEffect(() => {
        loadBarChartData();
    }, [props.selectedYear,props.selectedOrg])

    return (
        <div id="load" >
            <LoadPanelComponent position={{of: '#load'}} visible={loader} />
            <Toolbar>
                <Item
                location={'after'}
                    render={DownloadChartButton}
                    />
            </Toolbar>
            <Chart ref={chartRef} id="mySimpleBarChart" dataSource={barChartData} resolveLabelOverlapping="hide">
                <ArgumentAxis tickInterval={1}>
                    <Label format="fixedPoint" />
                </ArgumentAxis>
                <CommonSeriesSettings argumentField="budget_month" type="bar" hoverMode="allArgumentPoints" barOverlapGroup="perfStack" barPadding={0.5}
                                      selectionMode="allArgumentPoints">
                    <Label precision={0} visible={true} font={{ family: "'Montserrat', sans-serif" }} format={(value) => numberWithCommas(value / 1000000)}>
                        <Format type="fixedPoint" precision={2} />
                    </Label>
                </CommonSeriesSettings>
                <Crosshair enabled={true}>
                    <HorizontalLine visible={false}/>
                    <Label visible={true}/>
                </Crosshair>
                <Series valueField="budget" name="Хуваарь" stack="perfStack" color="#2b77ff" barPadding={0.3}/>
                <Series valueField="budget" name="Хуваарийн өөрчлөлт" type="spline" color="#2b77ff" dashStyle="dash"/>
                <Series valueField="performance" name="Гүйцэтгэл" stack="perfStack"  color="#003696"/>
                <Series valueField="performance" name="Гүйцэтгэлийн өөрчлөлт" type="spline"  color="#003696" dashStyle="dash"/>

                {/*<Tooltip enabled={true} location="edge" customizeTooltip={customizeTooltip}/>*/}
                <Tooltip zIndex={100000} enabled={true} customizeTooltip={customizeTooltip} font={{ family: "'Montserrat', sans-serif" }} location="edge">
                </Tooltip>
                <Legend verticalAlignment="bottom" horizontalAlignment="center" visible={true}/>
            </Chart>
        </div>
    )
}

export default WholeAmountBarChart

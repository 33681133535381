import React, {useState, useEffect} from "react";
import notify from "devextreme/ui/notify";
import FinanceReportService from "../../../../services/api/financeReport";
import _ from "lodash";
import {paletteFinanceReport, numberWithCommas} from "../../../../util";
import Chart, {CommonSeriesSettings, Label, Legend, Series, Tooltip, ValueAxis} from "devextreme-react/chart";
import LoadPanelComponent from "../../../budget/components/loadPanel";
import EmptyData from "../../../budget/components/empty";

const ChartByYear = (props) => {
    const [chartData, setChartData] = useState([]);
    const [loader, setLoader] = useState(false);
    const [sumTotalAsset, setSumTotalAsset] = useState(0);
    const [sumDeptAll, setSumDeptAll] = useState(0);
    const [sumTotalResult, setSumTotalResult] = useState(0);
    const loadData = async () => {
        try {
            setLoader(true);
            let r = await FinanceReportService.getYamsDataByYear(props.reportType,props.selectedOrg, props.filterOrgText);
            let array= _.sortBy(r, (e)=>{return e.year})
            let sum = _.sumBy(r, 'total_asset');
            setSumTotalAsset(sum);
            let sum1 = _.sumBy(r, 'dept_all');
            setSumDeptAll(sum1);
            let sum2 = _.sumBy(r, 'total_result');
            setSumTotalResult(sum2);
            setChartData(array);
            setLoader(false);
        } catch (e) {
            setLoader(false);
            notify(e.message, "error", 2000);
        }
    }

    useEffect(() => {
        loadData()
    }, [props.reportType, props.filterOrgText, props.selectedOrg])

    const customizeTooltip = (arg) => {
        if (arg.seriesName === 'Өөрчлөлт') {
            let r = _.find(chartData, {'year': (arg.argument * 1 - 1).toString()});
            let r1 = _.find(chartData, {'year': (arg.argument * 1).toString()});
            let q = (arg.value / (r && r.total_asset)).toFixed(2);
            let p = ((arg.value * 100 / (r && r.total_asset)) - 100).toFixed(2);
            let d = (arg.value - (r && r.total_asset));
            let f = d < 0 ? 'бага' : 'их';

            if (isFinite(q) && !isNaN(q)) {
                return {
                    text: `${arg.argumentText} он\nӨмнөх жилээс\n\n Зөрүү: ${numberWithCommas(d / 1000000)}\n Хувь: ${p}%\n Өөрчлөлт: ${q} дахин ${f}`
                }
            } else {
                return {
                    text: `${numberWithCommas(r1 && r1.total_asset/ 1000000)}`
                }
            }
        } 
        else if  (arg.seriesName=== 'Өр төлбөр, цэвэр хөрөнгө/өмчийн дүн өөрчлөлт'){
            let r = _.find(chartData, {'year': (arg.argument * 1 - 1).toString()});
            let r1 = _.find(chartData, {'year': (arg.argument * 1).toString()});
            let q = (arg.value / (r && r.dept_all)).toFixed(2);
            let p = ((arg.value * 100 / (r && r.dept_all)) - 100).toFixed(2);
            let d = (arg.value - (r && r.dept_all));
            let f = d < 0 ? 'бага' : 'их';
            if (isFinite(q) && !isNaN(q)) {
                return {
                    text: `${arg.argumentText} он\n Өмнөх жилээс\n\n Зөрүү: ${numberWithCommas(d / 1000000)}\n Хувь: ${p}%\n Өөрчлөлт: ${q} дахин ${f}`
                }
            } else {
                return {
                    text: `${numberWithCommas(r1 && r1.total_asset/ 1000000)}`
                }
            }                           
        }
        else if  (arg.seriesName=== 'Нийт үр дүн (VII=III+VI) өөрчлөлт'){
            let r = _.find(chartData, {'year': (arg.argument * 1 - 1).toString()});
            let r1 = _.find(chartData, {'year': (arg.argument * 1).toString()});
            let q = (arg.value / (r && r.total_result)).toFixed(2);
            let p = ((arg.value * 100 / (r && r.total_result)) - 100).toFixed(2);
            let d = (arg.value - (r && r.total_result));
            let f = d < 0 ? 'бага' : 'их';
            if (isFinite(q) && !isNaN(q)) {
                return {
                    text: `${arg.argumentText} он\n Өмнөх жилээс\n\n Зөрүү: ${numberWithCommas(d / 1000000)}\n Хувь: ${p}%\n Өөрчлөлт: ${q} дахин ${f}`
                }
            } else {
                return {
                    text: `${numberWithCommas(r1 && r1.total_asset/ 1000000)}`
                }
            }
        }
        else if  (arg.seriesName=== 'Нийт цэвэр мөнгөн гүйлгээ өөрчлөлт'){
            let r = _.find(chartData, {'year': (arg.argument * 1 - 1).toString()});
            let r1 = _.find(chartData, {'year': (arg.argument * 1).toString()});
            let q = (arg.value / (r && r.total_result)).toFixed(2);
            let p = ((arg.value * 100 / (r && r.total_result)) - 100).toFixed(2);
            let d = (arg.value - (r && r.total_result));
            let f = d < 0 ? 'бага' : 'их';
            if (isFinite(q) && !isNaN(q)) {
                return {
                    text: `${arg.argumentText} он\n Өмнөх жилээс\n\n Зөрүү: ${numberWithCommas(d / 1000000)}\n Хувь: ${p}%\n Өөрчлөлт: ${q} дахин ${f}`
                }
            } else {
                return {
                    text: `${numberWithCommas(r1 && r1.total_asset/ 1000000)}`
                }
            }
        }
        else if  (arg.seriesName=== 'ЗГ-ын хувь оролцооны нийт дүн өөрчлөлт'){
            let r = _.find(chartData, {'year': (arg.argument * 1 - 1).toString()});
            let r1 = _.find(chartData, {'year': (arg.argument * 1).toString()});
            let q = (arg.value / (r && r.total_result)).toFixed(2);
            let p = ((arg.value * 100 / (r && r.total_result)) - 100).toFixed(2);
            let d = (arg.value - (r && r.total_result));
            let f = d < 0 ? 'бага' : 'их';
            if (isFinite(q) && !isNaN(q)) {
                return {
                    text: `${arg.argumentText} он\n Өмнөх жилээс\n\n Зөрүү: ${numberWithCommas(d / 1000000)}\n Хувь: ${p}%\n Өөрчлөлт: ${q} дахин ${f}`
                }
            } else {
                return {
                    text: `${numberWithCommas(r1 && r1.total_asset/ 1000000)}`
                }
            }
        }
        else {
            return {
                text: `${arg.seriesName}: ${numberWithCommas( props.reportType === 3 ? arg.value : arg.value / 1000000)}`
            };
        }
    }

    const customizeValueAxisLabel = (e) => {
        return props.reportType === 3 ? `${e.value}` : `${e.value}` / 1000000
    }

    return (
        <div id="load11">
            <LoadPanelComponent position={{of: '#load11'}} visible={loader} />
            {chartData.length > 0 ? (
            <Chart
                palette={paletteFinanceReport}
                paletteExtensionMode="alternate"
                dataSource={chartData}
            >
                <CommonSeriesSettings
                    argumentField="year"
                    type="bar"
                >
                    <Label visible={false}/>
                </CommonSeriesSettings>
                <ValueAxis>
                    <Label customizeText={customizeValueAxisLabel}/>
                </ValueAxis>
                {props.reportType === 1 &&  <Series valueField="total_asset" name="Нийт хөрөнгө" visible={sumTotalAsset !==0 ? true : false}/>}
                {props.reportType === 1 && <Series valueField="total_asset" name="Өөрчлөлт" color="red" type="spline" dashStyle="dash" visible={sumTotalAsset !==0 ? true : false}/>}
                {/*<Series valueField="dept_all" name="Өр төлбөр, цэвэр хөрөнгө/өмчийн дүн" visible={sumDeptAll !==0 ? true : false}/>
                <Series valueField="dept_all" name="Өр төлбөр, цэвэр хөрөнгө/өмчийн дүн өсөлт" type="spline" color="#264b96" dashStyle="dash" visible={sumDeptAll !==0 ? true : false}/>*/}
                {props.reportType !== 1 && <Series valueField="total_result" name={props.reportType === 3 ? 'Нийт цэвэр мөнгөн гүйлгээ' : props.reportType === 4 ? "ЗГ-ын хувь оролцооны нийт дүн" : "Нийт үр дүн (VII=III+VI)"}  visible={sumTotalResult !==0 ? true : false}/>}
                {props.reportType !== 1 && <Series valueField="total_result" name={props.reportType === 3 ? 'Нийт цэвэр мөнгөн гүйлгээ өөрчлөлт' : props.reportType === 4 ? "ЗГ-ын хувь оролцооны нийт дүн өөрчлөлт" : "Нийт үр дүн (VII=III+VI) өөрчлөлт"} type="spline" color="#264b96" dashStyle="dash" visible={sumTotalResult !==0 ? true : false}/>}
                <Legend visible={true} verticalAlignment="bottom" horizontalAlignment="center" font={{ family: "'Montserrat', sans-serif" }}/>
                <Tooltip zIndex={100000} enabled={true} customizeTooltip={customizeTooltip} font={{ family: "'Montserrat', sans-serif" }} location="edge">
                </Tooltip>
            </Chart>
                ):
                <div style={{display: "flex", justifyContent: "center", alignItems: "center", family: "'Montserrat', sans-serif" }}>
                   <EmptyData/>
                </div>
            }
        </div>
    )
}

export default ChartByYear;

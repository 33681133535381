import React, {useEffect, useRef, useState} from 'react';
import 'devextreme-react/text-area';
import DataGrid, {
    Editing,
    Pager,
    Paging,
    Column,
    FilterRow,
    RequiredRule,
    Lookup, Form, Item, Popup
} from 'devextreme-react/data-grid';
import notify from "devextreme/ui/notify";
import TreeListComponent from "../components/TreeListComponent";
import {
    TreeList
} from 'devextreme-react/tree-list';
import GovAgendaService from "../../../services/api/govAgenda";
import _ from "lodash";
import LoadPanelComponent from '../../../components/LoadPanelComponent';

function GovAgenda(props) {
    const [dataSource, setDataSource] = useState([]);
    const [loadingVisible, setLoadingVisible] = useState(false);
    const [dataSourceGovAgenda, setDataSourceGovAgenda] = useState([]);
    const [lookUpListGovAgenda, setLookUpListGovAgenda] = useState([]);
    const myBabyRef = useRef(null);
    const [dataSource1, setDataSource1] = useState([]);
    useEffect(() => {
        getOrgStartOfAncestryList();
        govAgendaFetch();
    }, []);

    const govAgendaFetch = async () => {
        try {
            setLoadingVisible(true);
            const result = await GovAgendaService.getAllGovAgenda();
            result.map(e => {
                if (e.status === 1) {
                    e.statusName = "Идэвхтэй";
                } else {
                    e.statusName = "Идэвхгүй";
                }
                //console.log("aaa: ", e.statusName);
            })
            let r = await _.filter(result, {parent_id: null});
            setLookUpListGovAgenda(r);
            setDataSource1(result)
            return setLoadingVisible(false);
        } catch (e) {
            console.error(e);
        }
    }

    const getOrgStartOfAncestryList = async () => {
        try {
            setLoadingVisible(true);
            const result = await GovAgendaService.getOrgStartOfAncestry();
            const allGovAgendaList = await GovAgendaService.getAllGovAgenda();
            setDataSourceGovAgenda(allGovAgendaList);
            //setLoadingVisible(false);
            setDataSource(result);
            return setLoadingVisible(false)
        } catch (e) {
            setLoadingVisible(false);
            notify(e.response.data.message, "warning");
        }
    }

    const setGovAgenda = async (e) => {
        if (e.changes && e.changes.length !== 0) {
            if (e.changes[0].type === "update") {
                try {
                    await GovAgendaService.editGovAgenda(e.changes[0].data);
                    await govAgendaFetch();
                    notify('Амжилттай хадгалагдлаа', "success", 2000);
                } catch (e) {
                    notify('Засварлалтыг хадгалах явцад алдаа гарлаа', "error", 2000);
                }
            } else if (e.changes[0].type === "insert") {
                try {
                    delete e.changes[0].data.id;
                    await GovAgendaService.addGovAgenda(e.changes[0].data);
                    await govAgendaFetch();
                    notify('Амжилттай үүслээ', "success", 2000);
                } catch (e) {
                    notify('Шинээр үүсэх явцад алдаа гарлаа', "error", 2000);
                }

            } else if (e.changes[0].type === "remove") {
                try {
                    // console.log(e.changes[0].key);
                    await GovAgendaService.removeGovAgenda(e.changes[0].key);
                    await govAgendaFetch();
                    notify('Амжилттай устгагдлаа', "success", 2000);
                } catch (e) {
                    notify('Устгах явцад алдаа гарлаа', "error", 2000);
                }

            }
        }

    }


    const onRowUpdated = async (data) => {
        try {
            setLoadingVisible(true);
            // console.log('data.changes ', data);
            if (data && data.changes && data.changes.length > 0) {
                if (data.changes[0].data.govAgendaRelations.govAgendaRelations && typeof data.changes[0].data.govAgendaRelations.govAgendaRelations[0] === 'number') {
                    const newInfo = {
                        gov_agenda_id: data.changes[0].data.govAgendaRelations.govAgendaRelations,
                        start_of_ancestry_org_id: data.changes[0].key.start_of_ancestry_org_id
                    }
                    await GovAgendaService.updateGovAgendaRelation(newInfo);
                }
            }

            notify("Амжилттай өөрчиллөө!", "success", 5000);

            setLoadingVisible(false);

        } catch (e) {
            setLoadingVisible(false);
            notify("Өөрчилөлт хийхэд алдаа гарлаа!", "warning", 5000);
        }
    }
    //Column custom renderings
    const statusRender = (data) => {
        return (data.value === 1 ? <div style={{
            backgroundColor: '#50CB93',
            width: '80%',
            color: 'white',
            borderRadius: '3px',
            cursor: 'pointer',
            margin: 'auto',
            textAlign: 'center'
        }}>Идэвхтэй</div> : <div style={{
            backgroundColor: '#FF616D',
            width: '80%',
            color: 'white',
            borderRadius: '3px',
            cursor: 'pointer',
            margin: 'auto',
            textAlign: 'center'
        }}>Идэвхгүй</div>)
    }

    const onEditorPreparing = (e) => {
        if (e.parentType === "dataRow") {
            return;
        }
        if (e.row && e.row.isNewRow) {
            e.editorOptions.visible = true;
        }
        if (e.row && !e.row.isNewRow) {
            e.editorOptions.visible = false;
        }
    }
    const listPosition = {of: '#dataGrid'};
    const initNewRowEvent = (e) => {
        let r;
        if (e.data.parent_id === 0) {
            r = [];
        } else {
            r = _.filter(dataSource1, {id: e.data.parent_id});
        }
        setLookUpListGovAgenda(r);
    }

    const onEditingStartEvent = (e) => {
        let r;
        if (e.data.parent_id) {
            r = _.filter(dataSource1, {id: e.data.parent_id});
        }
        setLookUpListGovAgenda(r);
    }
    const onCellPreparedHandler = (e) => {
        if (e.rowType === 'header') {
            e.cellElement.style.color = "black"
            e.cellElement.style.backgroundColor = "#E8E8E8"
            // elem.style.setProperty('color', 'green');
        }
        if (e.rowType === 'data') {
            e.cellElement.style.color = "black"
            // elem.style.setProperty('color', 'green');
        }
    }
    return (
        <>
            <LoadPanelComponent
                shadingColor="rgba(0,0,0,0.4)"
                position={listPosition}
                visible={loadingVisible}
                message=''
                showPane={false}
            />

            <div className="card-body">
                <DataGrid
                    dataSource={dataSource}
                    showBorder={true}
                    allowColumnReordering={true}
                    onSaved={onRowUpdated}
                    ref={myBabyRef}
                    keyExpr={null}
                    onCellPrepared={onCellPreparedHandler}
                    rowAlternationEnabled={true}
                    showBorders={true}
                    showColumnLines={true}
                    showRowLines={true}
                >
                    <FilterRow
                        visible={true}
                    />
                    <Pager
                        showPageSizeSelector={true}
                        // showInfo={true}
                        showNavigationButtons={true}
                    />
                    <Column
                        dataField="govAgendaRelations.govAgendaRelations"
                        caption="Бодлогын баримт бичиг"
                        width={1500}
                        allowFiltering={true}
                        allowUpdating={true}
                        visible={false}
                        editCellComponent={TreeListComponent}
                    >

                        <Lookup
                            valueExpr="id"
                            displayExpr={(item) => {
                                return item && `${item.code} - ${item.name}`
                            }}
                            dataSource={dataSourceGovAgenda}

                        />
                    </Column>
                    <Column
                        dataField="organization_name"
                        caption="Байгууллагын нэр"
                        allowFiltering={true}
                        allowEditing={false}
                    />

                    <Editing
                        mode="popup"
                        allowUpdating={true}
                        allowDeleting={false}
                        // allowAdding = {adding}
                        useIcons={true}

                        texts={{
                            addRow: 'Төсөл нэмэх',
                            cancelAllChanges: 'Болих бүгд',
                            cancelRowChanges: 'Болих',
                            confirmDeleteMessage: 'Энэхүү мэдээллийг устгах уу?',
                            confirmDeleteTitle: 'Баталгаажуулах',
                            deleteRow: 'Устгах',
                            editRow: 'Өөрчлөх',
                            saveAllChanges: 'Хадгалах бүгд',
                            saveRowChanges: 'Хадгалах',
                            undeleteRow: 'Буцаах',
                            validationCancelChanges: 'Баталгаажуулах болих',
                        }}>
                        <Popup title="Бодлогын баримт бичиг тохируулах" showTitle={true} width={1000} height={250}/>
                        <Form>
                            <Item itemType="group" colCount={1} colSpan={2}>
                                <Item dataField="organization_name"/>
                                <Item dataField="govAgendaRelations.govAgendaRelations"/>
                            </Item>
                        </Form>

                    </Editing>


                    <Paging enabled={true}/>
                </DataGrid>

            </div>

            <div>
                <div className="card-body">
                    <TreeList
                        dataSource={dataSource1}
                        keyExpr="id"
                        parentIdExpr="parent_id"
                        onEditorPreparing={onEditorPreparing}
                        ref={myBabyRef}
                        onSaved={setGovAgenda}
                        onEditingStart={onEditingStartEvent}
                        onInitNewRow={initNewRowEvent}
                        onCellPrepared={onCellPreparedHandler}
                        rowAlternationEnabled={true}
                        showBorders={true}
                        showColumnLines={true}
                        showRowLines={true}
                    >
                        <Editing
                            mode="row"
                            allowUpdating={true}
                            allowAdding={true}
                            allowDeleting={true}
                            confirmDelete={true}
                            useIcons={true}
                            confirmSave={true}
                            texts={{
                                cancelAllChanges: 'Болих бүгд',
                                cancelRowChanges: 'Болих',
                                confirmDeleteMessage: 'Энэ хөтөлбөрийн мэдээллийг устгах уу?',
                                confirmDeleteTitle: 'Баталгаажуулах',
                                deleteRow: 'Устгах',
                                editRow: 'Өөрчлөх',
                                saveAllChanges: 'Хадгалах бүгд',
                                saveRowChanges: 'Хадгалах',
                                undeleteRow: 'Буцаах',
                                validationCancelChanges: 'Баталгаажуулах болих',
                            }}
                        />
                        <FilterRow
                            visible={true}
                        />
                        <Column
                            cssClass="custom"
                            dataField="agenda_no"
                            caption="Код"
                            width={100}
                            allowEditing={true}
                            allowCollapsing={false}
                            allowFiltering={true}
                            //  cellRender = {dateRender}
                        >
                            {/*<RequiredRule*/}
                            {/*    message = 'Код оруулна уу'*/}
                            {/*/>*/}
                        </Column>
                        <Column
                            dataField="name"
                            caption="Төсөл хөтөлбөр нэр"
                            allowEditing={true}
                            // editCellTemplate = {}
                            //   cellRender = {dateRender}
                            cssClass="custom"
                            allowFiltering={true}
                            width={250}
                        >
                            <RequiredRule
                                message='Нэр оруулна уу'
                            />
                        </Column>

                        <Column
                            dataField="parent_id"
                            caption="Толгой хөтөлбөр">
                            width ={100}
                            <Lookup
                                allowClearing={true}
                                dataSource={lookUpListGovAgenda}
                                valueExpr="id"
                                displayExpr="name"/>
                        </Column>
                        <Column
                            cssClass="custom"
                            dataField="start_year"
                            caption="Эхлэх жил"
                            allowEditing={true}
                            allowCollapsing={false}
                            allowFiltering={true}
                            width={60}
                            //  cellRender = {dateRender}
                        >
                        </Column>
                        <Column
                            cssClass="custom"
                            dataField="end_year"
                            caption="Дуусах жил"
                            allowEditing={true}
                            allowCollapsing={false}
                            allowFiltering={true}
                            width={60}
                            //  cellRender = {dateRender}
                        >
                        </Column>
                        <Column
                            cssClass="custom"
                            dataField="status"
                            caption="Төлөв"
                            allowEditing={true}
                            cellRender={statusRender}
                            allowFiltering={true}
                            width={100}>
                            <RequiredRule
                                message='Төлөв оруулна уу'
                            />
                        </Column>
                        {/*<Paging enabled={true} />*/}
                    </TreeList>
                </div>
            </div>
        </>

    )


}

export default GovAgenda;

import {FileUploader, Popup, RadioGroup, ScrollView, SelectBox, TextBox} from "devextreme-react";
import LoadPanelComponent from "../../budget/components/loadPanel";
import DropDownBox from "devextreme-react/drop-down-box";
import {RequiredRule, Validator} from "devextreme-react/validator";
import HtmlEditor, {Item, Toolbar} from "devextreme-react/html-editor";
import Button from "devextreme-react/button";
import notify from "devextreme/ui/notify";
import React, {useRef, useState} from "react";
import notificationService from "../../../services/api/notification";
import TreeView from "devextreme-react/tree-view";
import OrganizationService from "../../../services/api/organization";
import fileTypeService from "../../../services/api/fileType";

const EditNotification = ({ popupVisible, setPopupVisible, notificationId, setNotifications }) => {

    const [fileTypes, setFileTypes] = useState([]);
    const [notificationObj, setNotificationObj] = useState({});
    const [organizations, setOrganizations] = useState([]);
    const visibleTypes = ['Popup', 'Мэдэгдэл дотор'];
    const [selectedNotif, setSelectedNotif]= useState(false);
    const [treeView, setTreeView] = useState(null);
    const [treeBoxValue, setTreeBoxValue] = useState(null);
    const [isTreeBoxOpened, setIsTreeBoxOpened] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const htmlRef1 = useRef(null);
    const editFormRef = useRef(null);
    const editFormTitleValidatorRef = useRef(null);
    const editFormContentValidatorRef = useRef(null);
    const sizeValues = ["8pt", "10pt", "12pt", "14pt", "18pt", "24pt", "36pt"];
    const fontValues = [
        "Arial",
        "Courier New",
        "Georgia",
        "Impact",
        "Lucida Console",
        "Tahoma",
        "Times New Roman",
        "Verdana",
        "Montserrat"
    ];
    const fontOptions = {placeholder: "Фонт"};
    const headerValues = [false, 1, 2, 3, 4, 5];

    const getAllNotifications = async () => {
        try {
            const res = await notificationService.getAllNotifications();

            if (res && res.code === 200) {
                setNotifications(res.data);
            }
        } catch (e) {
            console.log(e);
        }
    }

    const getOrganizationIncludeParent = async () => {
        try {
            const res = await OrganizationService.getOrganizationIncludeParent();

            if (res && res.code === 200) {
                setOrganizations(res.data);
            }
        } catch (e) {
            console.log(e);
        }
    }

    const getNotificationFileType = async () => {
        try {
            const res = await fileTypeService.getNotificationFileType();

            if (res && res.code === 200) {
                setFileTypes(res.data);
            }
        } catch (e) {
            console.log(e);
        }
    }

    const getNotificationById = async () => {
        try {
            await getOrganizationIncludeParent();
            await getNotificationFileType();

            const res = await notificationService.getByIdNotification(notificationId);

            if (res && res.code === 200) {
                if (res.data.visible_type === 1) {
                    res.data.visible_type = visibleTypes[0];
                } else {
                    res.data.visible_type = visibleTypes[1];
                }

                setNotificationObj(res.data);
                setTreeBoxValue(res.data.sent_org_ids);
                setSelectedNotif(true);
            }
        } catch (e) {
            console.log(e);
        }
    }

    const handleInputChange = async (event) => {
        if (event.event !== undefined) {
            setNotificationObj(values => ({...values, [event.event.currentTarget.name]: event.value}))
        }
    }

    const handleHtmlChange = (event) => {
        htmlRef1.current = event.value;
    }

    const handleRadioChange = async (event) => {
        if (event.event !== undefined) {
            setNotificationObj(values => ({...values, visible_type: event.value}))
        }
    }

    const handleValueChanged = async (event) => {
        if (event.event !== undefined) {
            setNotificationObj(values => ({...values, file_type_id: event.value}))
        }
    }

    const onSubmit = async (event) => {
        try {
            event.preventDefault();

            setIsLoading(true);

            let notificationObj2 = {};

            notificationObj2.title = notificationObj.title ? notificationObj.title : "";
            notificationObj2.notif_info = htmlRef1.current
            if (notificationObj.file_type_id) {
                notificationObj2.file_type_id = notificationObj.file_type_id;
            }

            if (notificationObj.visible_type === 'Popup') {
                notificationObj2.visible_type = 1;
            } else {
                notificationObj2.visible_type = 2;
            }

            const res = await notificationService.updateNotification(notificationObj.id, notificationObj2);

            
            if (res && res.code === 200) {
                await getAllNotifications();
                setPopupVisible(false);
                setIsLoading(false);
                notify(
                    {
                      message: "Амжилттай өөрчлөгдлөө",
                      width: 400,
                    },
                    "success",
                    2500
                  )
            }

        } catch (e) {
            console.log(e);
            setIsLoading(false);
            notify(
                {
                  message: "Өөрчлөх явцад алдаа гарлаа",
                  width: 400,
                },
                "error",
                2500
              )
        }
    }

    const treeViewRender = () => {
        return (
            <TreeView dataSource={organizations}
                      ref={(ref) => { setTreeView(ref) }}
                      dataStructure="plain"
                      keyExpr="id"
                      parentIdExpr="parent"
                      selectionMode="single"
                      displayExpr="org_name"
                      selectByClick={true}
                      showCheckBoxesMode="normal"
                      selectNodesRecursive={false}
                      onContentReady={treeViewOnContentReady}
                      onItemClick={onTreeItemClick}
                      onItemSelectionChanged={treeViewItemSelectionChanged}
            />
        );
    }

    const syncTreeViewSelection = (e) => {
        setTreeBoxValue(e.value);

        if (!treeView) return;

        if (!e.value) {
            treeView.instance.unselectAll();
        } else {
            treeView.instance.selectItem(e.value);
        }
    }

    const treeViewItemSelectionChanged = (e) => {
        setTreeBoxValue(e.component.getSelectedNodeKeys());
    }

    const treeViewOnContentReady = (e) => {
        e.component.selectItem(treeBoxValue);
    }

    const onTreeItemClick = () => {
        setIsTreeBoxOpened(false);
    }

    const onTreeBoxOpened = (e) => {
        if (e.name === 'opened') {
            setIsTreeBoxOpened(e.value);
        }
    }

    const onShowing = () => {
        if (popupVisible) {
            getNotificationById();
        }
    }

    const onHiding = () => {
        editFormRef.current.reset();
        editFormTitleValidatorRef.current.instance.reset();
        editFormContentValidatorRef.current.instance.reset();

        setNotificationObj({});
        setTreeBoxValue(null);
        setOrganizations([]);
        setFileTypes([]);
        setSelectedNotif(false);
        setPopupVisible(false);
    }

    useState(() => {

    });
    const requestFullscreen=()=> {
        /* Get the element you want displayed in fullscreen */
        const element = document.getElementById("htmlEditorEdit");
        // Use a guard clause to exit out of the function immediately
        if (element.requestFullscreen) {
            element.requestFullscreen();
        } else if (element.webkitRequestFullscreen) {
            // Safari
            element.webkitRequestFullscreen();
        } else if (element.msRequestFullscreen) {
            // IE
            element.msRequestFullscreen();
        }
    }
    const toolbarButtonOptions = {
        icon: 'fullscreen',
        stylingMode: 'text',
        onClick: requestFullscreen,
    };
    return (
        <div>
            <Popup
                visible={popupVisible}
                onHiding={onHiding}
                onShowing={onShowing}
                dragEnabled={false}
                hideOnOutsideClick={true}
                showCloseButton={true}
                showTitle={true}
                title="Мэдэгдэл засах"
                width="65%"
                height="90vh"
            >
                <ScrollView width='100%' height='100%'>
                    <LoadPanelComponent
                        position={{of: "#renderCreateMenu"}}
                        visible={isLoading}
                    />
                    <form ref={editFormRef} onSubmit={onSubmit}>
                        <div className="dx-fieldset">
                            <div className="dx-field">
                                <div className="dx-field-label" style={{width: "25%"}}>Байгууллага: <span style={{color: 'red'}}>*</span></div>
                                <div className="dx-field-value" style={{width: "75%"}}>
                                    <DropDownBox
                                        disabled={true}
                                        value={treeBoxValue}
                                        opened={isTreeBoxOpened}
                                        valueExpr="id"
                                        displayExpr="org_name"
                                        placeholder="Байгууллага сонгох..."
                                        showClearButton={true}
                                        dataSource={organizations}
                                        onValueChanged={syncTreeViewSelection}
                                        onOptionChanged={onTreeBoxOpened}
                                        contentRender={treeViewRender}
                                    />
                                </div>
                            </div>
                            <div className="dx-field">
                                <div className="dx-field-label" style={{width: "25%"}}>Мэдэгдлийн гарчиг:</div>
                                <div className="dx-field-value" style={{width: "75%"}}>
                                    <TextBox  name="title" ref={editFormTitleValidatorRef} value={notificationObj.title} onValueChanged={handleInputChange} placeholder="Мэдэгдлийн гарчиг">
                                        {/* <Validator ref={editFormTitleValidatorRef}>
                                            <RequiredRule
                                                message="Мэдэгдлийн гарчиг бөглөнө үү!" />
                                        </Validator> */}
                                    </TextBox>
                                </div>
                            </div>
                            <div className="dx-field" height="100%" width="100%" id="htmlEditorEdit" style={{backgroundColor:"#fff"}}>
                                {selectedNotif && <HtmlEditor
                                    // ref={htmlRef1}
                                    height="300px"
                                    name="notif_info"
                                    onValueChanged={handleHtmlChange}
                                    defaultValue={notificationObj.notif_info}
                                    valueType="html"
                                >
                                    <Toolbar multiline={true}>
                                        <Item name="size" acceptedValues={sizeValues} />
                                        <Item name="separator" />
                                        <Item name="font" acceptedValues={fontValues} options={fontOptions} />
                                        <Item name="separator" />
                                        <Item name="bold" />
                                        <Item name="italic" />
                                        <Item name="strike" />
                                        <Item name="underline" />
                                        <Item name="separator" />
                                        <Item name="alignLeft" />
                                        <Item name="alignCenter" />
                                        <Item name="alignRight" />
                                        <Item name="alignJustify" />
                                        <Item name="separator" />
                                        <Item name="orderedList" />
                                        <Item name="bulletList" />
                                        <Item name="separator" />
                                        <Item name="header" acceptedValues={headerValues} />
                                        <Item name="separator" />
                                        <Item name="color" />
                                        <Item name="background" />
                                        <Item name="separator" />
                                        <Item name="link" />
                                        <Item name="separator" />
                                        <Item name="clear" />
                                        <Item name="codeBlock" />
                                        <Item name="blockquote" />
                                        <Item name="separator" />
                                        <Item name="insertTable" />
                                        <Item name="deleteTable" />
                                        <Item name="insertRowAbove" />
                                        <Item name="insertRowBelow" />
                                        <Item name="deleteRow" />
                                        <Item name="insertColumnLeft" />
                                        <Item name="insertColumnRight" />
                                        <Item name="deleteColumn" />
                                        <Item name="separator" />
                                        <Item
                                            widget={'dxButton'}
                                            options={toolbarButtonOptions}
                                        />
                                    </Toolbar>
                                    <Validator ref={editFormContentValidatorRef}>
                                        <RequiredRule
                                            message="Агуулга бөглөнө үү!" />
                                    </Validator>
                                </HtmlEditor>}
                            </div>
                            <div className="dx-field">
                                <div className="dx-field-label" style={{width: "25%"}}>Файлын төрөл: </div>
                                <div className="dx-field-value" style={{width: "75%"}}>
                                    <SelectBox items={fileTypes}
                                               value={notificationObj.file_type_id}
                                               displayExpr="name"
                                               valueExpr="id"
                                               name="file_type"
                                               onValueChanged={handleValueChanged}>
                                    </SelectBox>
                                </div>
                            </div>
                            <div className="dx-field">
                                <div className="dx-field-label" style={{width: "25%"}}>Файл: </div>
                                <div className="dx-field-value" style={{width: "75%"}}>
                                    <FileUploader name="file" selectButtonText="Файл сонгох" labelText="" multiple="true" accept="*" uploadMode="useForm" />
                                </div>
                            </div>
                            <div className="dx-field">
                                <div className="dx-field-label" style={{width: "30%"}}>Мэдэгдэл харуулах хэлбэр: <span style={{color: 'red'}}>*</span></div>
                                <div className="dx-field-value" style={{width: "70%"}}>
                                    <RadioGroup name="visible_type" value={notificationObj.visible_type} onValueChanged={handleRadioChange} items={visibleTypes} layout="horizontal" />
                                </div>
                            </div>
                            <div className="dx-field" style={{ textAlign: "center" }}>
                                <Button
                                    text="Шинэчлэх"
                                    type="success"
                                    useSubmitBehavior={true}
                                >
                                </Button>
                            </div>
                        </div>
                    </form>
                </ScrollView>
            </Popup>
        </div>
    );
}

export default EditNotification;

import request from '../../shared/lib/request'

const getJasperReport = () => {
    return request({
        url: '/jasper/getJasperReport',
        method: 'GET',
    })
}
const jasperServices = {
    getJasperReport
}

export default jasperServices;
import React from "react";
import Button from "devextreme-react/button";
import TextBox from 'devextreme-react/text-box';
import SelectBox from 'devextreme-react/select-box';
import TextArea from 'devextreme-react/text-area';
import RadioGroup from "devextreme-react/radio-group";
import DateBox from 'devextreme-react/date-box';
import {DataGrid, Editing, Column} from 'devextreme-react/data-grid';
import OrganizationService from "../../../services/api/organization";
import notify from "devextreme/ui/notify";

export default class OrgFormRenderer extends React.Component {
  constructor(props) {
    super(props);
    // console.log(props);
    this.state = {
      formList: [],
      formTemplateData: [],
      showAddDialog: false,
      optionsDs: [],
      formName: '',
      tableDs: [],
    };
    this.formDataList = [];
    this.isEdit = props.isEdit;
    this.editData = props.data;

    this.onSave = this.onSave.bind(this);
  }

  componentDidMount(props) {
    this.initVars()
    this.getData();
  }

  initVars() {

  }

  async getData() {

    if (this.isEdit && this.editData) {
      // not used this

      // this.setState({
      //   formName: this.editData.name,
      //   jsonText: this.editData.value,
      //   formTemplateData: this.editData.value ? JSON.parse(this.editData.value) : [],
      // })
    } else { // this is create
      const builderList = OrganizationService.orgBuilderList();
      const builderList2 = OrganizationService.orgWorkerDataById(this.props.workerId);

      Promise.all([builderList, builderList2])
        .then((values) => {
          // console.log(values);

          const res = values[0]; // forms
          const res1 = values[1]; // data
          this.formDataList = res1.data;
          // console.log(res);
          const temp = {}; // used for initializing the state variable,
          // console.log(res.data);
          res.data.forEach(formItem => {
            let value = formItem.value ? formItem.value : [];
            let formData = res1.data.find(i => i.org_w_form_id === formItem.id);
            if (formData){
              let dataObj = formData.value ? formData.value : []
              dataObj.forEach(item => {
                // console.log(item);
                temp[`form_${formItem.id}_${item.name}`] = item.value;
              })
            } else {

            }
            if(value){
              value = JSON.parse(value)
              value.forEach(item => {
                if (item.type === 'table') {
                  if (!temp[`form_${formItem.id}_${item.field}`]) {
                    temp[`form_${formItem.id}_${item.field}`] = [];
                  }
                  // temp[item.field] = [];
                }
              })
            }
          });
          this.setState({
            formList: res.data,
            ...temp,
          })


        })
        .catch(err => {
          console.log(err);
          notify(`Алдаа гарлаа`, 'error');
        });


      // console.log(this.state);
      // this.editData = res.data[3];
    }


  }


  formElementRender(listItem, prefix = '') {
    // console.log(listItem);
    // listItem.type
    return (
      <div className={`d-flex org-form-item align-item-center ${listItem.type === 'table' ? 'w-100' : ''}`}>
        {listItem.type === 'table' && <div>
          <div className="org-form-label">{listItem.label}</div>
          {this.chooseFE(listItem, prefix)}
        </div>}
        {listItem.type !== 'table' && <div className="dx-field flex-expand mb-0">
          <div className="dx-field-label">{listItem.label}</div>
          <div className="dx-field-value">
            {this.chooseFE(listItem, prefix)}
          </div>

        </div>}
      </div>
    );
  }

  chooseFE(item, prefix) {
    const stateFieldName = prefix + item.field;
    if (item.type === 'input') {
      return <TextBox value={this.state[stateFieldName]} onValueChanged={(e) => this.onValueChanged(e, stateFieldName)}/>;
    } else if (item.type === 'select') {

      return <SelectBox
        dataSource={item.options}
        displayExpr="name"
        valueExpr="value"
        value={this.state[stateFieldName]}
        onValueChanged={(e) => this.onValueChanged(e, stateFieldName)}/>;
    } else if (item.type === 'textarea') {
      return <TextArea value={this.state[stateFieldName]}
                       onValueChanged={(e) => this.onValueChanged(e, stateFieldName)}/>;
    } else if (item.type === 'radio') {
      return <RadioGroup value={this.state[stateFieldName]} dataSource={item.options}
                         displayExpr="name"
                         valueExpr="value"
                         onValueChanged={(e) => this.onValueChanged(e, stateFieldName)}/>;
    } else if (item.type === 'date') {
      return <DateBox value={this.state[stateFieldName]} onValueChanged={(e) => this.onValueChanged(e, stateFieldName)} type="date"/>;
    } else if (item.type === 'datetime') {
      return <DateBox value={this.state[stateFieldName]} onValueChanged={(e) => this.onValueChanged(e, stateFieldName)} type="datetime"/>;
    } else if (item.type === 'time') {
      return <DateBox value={this.state[stateFieldName]} onValueChanged={(e) => this.onValueChanged(e, stateFieldName)} type="time"/>;
    } else if (item.type === 'table') {
      // console.log(item.options);
      const columns = [];
      let counter = 0;
      item.options.map(i => columns.push({name: i.name, field: `col-${counter++}`}));
      return <DataGrid showBorders={true} dataSource={this.state[stateFieldName]}>
        {
          columns.map(col =>
            <Column dataField={`${col.field}`} caption={`${col.name}`}/>
          )
        }
        <Editing
          mode="row"
          allowUpdating={true}
          allowDeleting={true}
          allowAdding={true}/>
      </DataGrid>;
    }

    return <TextBox/>;
  }

  onValueChanged(e, field) {
    this.setState({
      [field]: e.value
    });
  }

  async onSave(e) {
    // console.log(this.state);
    //
    const sendData = [];
    this.state.formList.forEach(formItem => {

      const temp = []; // temporary variable for saving json field
      let value = formItem.value ? JSON.parse(formItem.value) : [];
      value.forEach(item => {
        // state variable has form id in it, to make sure no duplication
        temp.push({name: item.field, value: this.state[`form_${formItem.id}_${item.field}`]});
      });
      const formData = this.formDataList.find(i => i.org_w_form_id === formItem.id);
      const data = {
        id: formData ? formData.id : null,
        name: formItem.name,
        org_worker_id: this.props.workerId,
        value: temp,
        org_w_form_id: formItem.id,
      };
      sendData.push(data);
    });

    try {
      // console.log(sendData);
      const res = await OrganizationService.orgWorkerDataSave(sendData);
      // console.log(res);
      if (res.success) {
        notify(`${res.message}`, 'success',);
      } else {
        notify(`${res.message}`, 'error');
      }
    } catch (e) {
      console.log(e);
      notify(`Алдаа гарлаа`, 'error');
    }

  }

  render() {
    let {formList} = this.state;
    // console.log('rendered aggain', this.props.workerId);
    return (

      <div className="card org-form-renderer">
        <div className="card-body p-3">

          {formList.map(formItemData => {

              let value = formItemData.value ? JSON.parse(formItemData.value) : [];
              return (
                <div className={`grid-${formItemData.columnCount}`}>
                  <h5 className="mb-3">{formItemData.name}</h5>
                  <div className="d-flex org-form-item-wrapper">
                    {value.map( valueItem =>this.formElementRender(valueItem, `form_${formItemData.id}_`) )}
                  </div>
                  {/*<List*/}
                  {/*  className=""*/}
                  {/*  dataSource={value}*/}
                  {/*  keyExpr="id"*/}
                  {/*  itemRender={(e) => }>*/}

                  {/*</List>*/}
                </div>
              )
            }
          )}

        </div>
        <Button icon="save"
                text="Хадгалах"
                onClick={this.onSave}
        />

      </div>
    )
  }
}

import React, {useContext, useEffect, useState, useRef} from 'react';

import 'devextreme-react/text-area';
import 'devextreme-react/text-area';
import {
    TreeList,ColumnChooser ,Editing, Column, Lookup, FilterRow, Selection, Scrolling
} from 'devextreme-react/tree-list';
import {LoadPanel} from "devextreme-react/load-panel";
import notify from 'devextreme/ui/notify';
import {UserContext} from "../../../../hooks/UserContext";
import fcsService from "../../../../services/api/fcs";
import _ from "lodash";
import {RequiredRule} from "devextreme-react/data-grid";
import {alert} from "devextreme/ui/dialog";
import LoadPanelComponent from '../../../../components/LoadPanelComponent';

function CustOrgParent(props) {
    const userListPosition = {of: '#usersList'}
    const setCustValue = props.setCustValue
    const setModalVisibleCustOrg=props.setModalVisibleCustOrg
    const {user} = useContext(UserContext);
    const [dataSource, setDataSource] = useState([]);
    const [loader, setLoader] = useState(false);
    const [popupVisible, setPopupVisible] = useState(false);

    const [lookUpListAgenda, setLookUpListAgenda] = useState([]);
    const myBabyRef = useRef(null);
    useEffect(() => {
        custOrgFetch();
    }, [])

    const custOrgFetch = async () => {
        try {
            setPopupVisible(true);
            const result = await fcsService.getAllFcsCustOrgn();
            result.map(e => {

            })

            let r = await _.filter(result, {head: 1});
            setLookUpListAgenda(r);
            setDataSource(result)
            return setPopupVisible(false)
        } catch (e) {
            return setPopupVisible(false)
            console.error(e);
        }
    }


    const setAgenda = async (e) => {
        if (e.changes && e.changes.length !== 0) {
            if (e.changes[0].type === "update") {
                try {

                    if (e.changes[0].data.orgcode != e.changes[0].data.parent_id) {
                        await fcsService.editFcsCustOrgn(e.changes[0].data)
                        await custOrgFetch()
                        notify('Амжилттай хадгалагдлаа', "success", 2000)
                    } else {
                        notify('Өөрийгөө сонгосон', "error", 2000);
                        await custOrgFetch()
                    }


                } catch (e) {
                    notify('Засварлалтыг хадгалах явцад алдаа гарлаа', "error", 2000);
                }
            } else if (e.changes[0].type === "insert") {
                try {
                    delete e.changes[0].data.id;
                    e.changes[0].data.org_id = user.org_id;
                    await fcsService.addFcsCustOrgn(e.changes[0].data);
                    await custOrgFetch();
                    notify('Амжилттай үүслээ', "success", 2000);
                } catch (e) {
                    notify('Шинээр үүсэх явцад алдаа гарлаа', "error", 2000);
                }

            } else if (e.changes[0].type === "remove") {
                try {

                    await fcsService.removeFcsCustOrgn(e.changes[0].key);
                    await custOrgFetch();

                    notify('Амжилттай устгагдлаа', "success", 2000);
                } catch (e) {
                    notify('Устгах явцад алдаа гарлаа', "error", 2000);
                }

            }
        }

    }
    //Column custom renderings
    const statusRender = (data) => {
        return (data.value === 1 ? <div style={{
            backgroundColor: '#50CB93',
            width: '80%',
            color: 'white',
            borderRadius: '3px',
            cursor: 'pointer',
            margin: 'auto',
            textAlign: 'center'
        }}>Идэвхтэй</div> : <div style={{
            backgroundColor: '#FF616D',
            width: '80%',
            color: 'white',
            borderRadius: '3px',
            cursor: 'pointer',
            margin: 'auto',
            textAlign: 'center'
        }}>Идэвхгүй</div>)
    }
    const onEditorPreparing = (e) => {
        if (e.parentType === "dataRow") {
            return;
        }
        if (e.row && e.row.isNewRow) {
            e.editorOptions.visible = true;
        }
        if (e.row && !e.row.isNewRow) {
            e.editorOptions.visible = false;
        }
    }
    const orderRender = (data) => {
        return <div style={{color: '#171010'}}>{data.rowIndex + 1}</div>;
    }

      function maxCode(id)   {
        let r = _.filter(dataSource, {parent_id: id.orgcode});
        let max = 0
        r.map((el) => {
            if (Number(el.orgcode )> max) (max = Number(el.orgcode))
        })
       return max+1

    }

    const initNewRowEvent = (e) => {

        //  let r;
        if (e.data.parent_id === 0) {
            //  r = [];
        } else {
            const max = maxCode(e.data.parent_id)
            e.data.orgcode = max
            // r = _.filter(dataSource, {id: e.data.parent_id});
        }
        //  setLookUpListAgenda(r);
    }
    const selectionChangeEvent=(e)=>{

       const d= e.selectedRowsData[0]
        if (d.head == 0 ) {
            setCustValue(d)
            setModalVisibleCustOrg(false)
        }
    }

    const onEditingStartEvent = (e) => {
        // let r;
        // if (e.data.parent_id) {
        // r = _.filter(dataSource, {id: e.data.parent_id});
        // }
        //  setLookUpListAgenda(r);
    }

    return (
        <div>
            <LoadPanelComponent
                shadingColor="rgba(0,0,0,0.4)"
                position={{of: '#password'}}
                visible={popupVisible}
                showPane={false}
                message="Түр хүлээнэ үү..."
            />
            <div>
                <div className="card-body">
                    <TreeList
                        dataSource={dataSource}
noDataText='Дата байхгүй байна'
                        keyExpr="orgcode"
                        parentIdExpr="parent_id"
                        allowColumnResizing={true}
                        onEditorPreparing={onEditorPreparing}
                        ref={myBabyRef}
                        onSaved={setAgenda}
                        onEditingStart={onEditingStartEvent}
                        onInitNewRow={initNewRowEvent}
                        onSelectionChanged={selectionChangeEvent}

                        columnAutoWidth={true}
                    >
                        <FilterRow visible={true}/>
                        <ColumnChooser
                            enabled={true}
                            mode="select"

                        />
                        <Selection mode="single"/>
                        <Editing
                            mode="row"
                            allowUpdating={true}
                            // allowAdding={true}
                            //  allowDeleting={true}
                            confirmDelete={true}
                            useIcons={true}
                            confirmSave={true}
                            allowAdding={true}
                            texts={{
                                cancelAllChanges: 'Болих бүгд',
                                cancelRowChanges: 'Болих',
                                confirmDeleteMessage: 'Энэ хөтөлбөрийн мэдээллийг устгах уу?',
                                confirmDeleteTitle: 'Баталгаажуулах',
                                deleteRow: 'Устгах',
                                editRow: 'Өөрчлөх',
                                saveAllChanges: 'Хадгалах бүгд',
                                saveRowChanges: 'Хадгалах',
                                undeleteRow: 'Буцаах',
                                validationCancelChanges: 'Баталгаажуулах болих',
                            }}
                        />


                            <Column
                                cssClass="custom"
                                dataField="orgcode"
                                caption="Код"
                                allowEditing={true}
                                allowCollapsing={false}
                                allowFiltering={true}
                                width={160}
                                //  cellRender = {dateRender}
                            >
                                {/*<RequiredRule*/}
                                {/*    message = 'Код оруулна уу'*/}
                                {/*/>*/}
                            </Column>
                            <Column
                                dataField="name"
                                caption="нэр"
                                allowEditing={true}
                                // editCellTemplate = {}
                                //   cellRender = {dateRender}
                                cssClass="custom"
                                allowFiltering={true}

                            >
                                <RequiredRule
                                    message=' нэр оруулна уу'
                                />
                            </Column>

                            <Column
                                dataField="parent_id"

                                caption="төрөл"
                            >


                                <Lookup
                                    allowClearing={true}
                                    dataSource={lookUpListAgenda}
                                    valueExpr="orgcode"
                                    displayExpr="name"/>
                            </Column>

                        <Column
                            dataField="head"
                            caption="бүлэг "
                        ></Column>

                            <Column
                                cssClass="custom"
                                dataField="rd"
                                dataType={'string'}
                                caption="Регистр"

                                allowEditing={true}
                                allowFiltering={true}
                            >

                            </Column>
                        <Column
                            cssClass="custom"
                            dataField="address"
                            dataType={'string'}
                            caption="Хаяг"

                            allowEditing={true}
                            allowFiltering={true}
                        >

                        </Column>
                        <Column
                            cssClass="custom"
                            dataField="email"
                            dataType={'string'}
                            caption="Э-шуудан"

                            allowEditing={true}
                            allowFiltering={true}
                        >

                        </Column>

                        <Column
                            cssClass="custom"
                            dataField="bankname"
                            dataType={'string'}
                            caption="Банкны нэр"

                            allowEditing={true}
                            allowFiltering={true}
                        >

                        </Column>
                        <Column
                                cssClass="custom"
                                dataField="bankacct"
                                dataType={'string'}
                                caption="Данс"

                                allowEditing={true}
                                allowFiltering={true}
                            >

                            </Column>
                        <Column
                            cssClass="custom"
                            dataField="person"
                            dataType={'string'}
                            caption="Албан хаагч"

                            allowEditing={true}
                            allowFiltering={true}
                        >
                            {/*<RequiredRule*/}
                            {/*    message='Регистрийн дугаар оруулна уу'*/}
                            {/*/>*/}
                        </Column>
                        <Column
                                cssClass="custom"
                                dataField="phone"
                                dataType={'string'}
                                caption="Утас №"

                                allowEditing={true}
                                allowFiltering={true}
                            >
                                {/*<RequiredRule*/}
                                {/*    message='Регистрийн дугаар оруулна уу'*/}
                                {/*/>*/}
                            </Column>


                            <Column
                                cssClass="custom"
                                dataField="vat"
                                dataType="boolean"
                                caption="НӨАТ"
                                allowEditing={true}
                                allowFiltering={true}
                            >
                            </Column>

                        </TreeList>
                    </div>
                </div>

            </div>
        )
    }

    export default CustOrgParent;

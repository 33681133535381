import React, { useContext, useState } from 'react'
import { UserContext } from '../../../hooks/UserContext';

const PayrollInfo = () => {
    const {user} = useContext(UserContext);
    return (<>
        {user && user.org_id === 624 ? 
            <iframe width="100%" style={{height: '100vh'}}
            src={"https://app.powerbi.com/view?r=eyJrIjoiNjk5OGI4NjEtOGU4Mi00NWY5LWFlZDMtZGZmMmE2YTE3MWQ2IiwidCI6ImMzNDMwZTM0LWE2NzgtNGM0Yi04NjdmLTUzN2IwMWFkZjMwZSIsImMiOjEwfQ%3D%3D"}
            allowFullScreen></iframe>
            :
            window.location.href = `${window.location.origin}/ministryPage`
        }
        </>
    )
}

export default PayrollInfo
import React, {useRef, useState, useEffect, useContext} from "react";
import SelectBox from "devextreme-react/select-box";
import {getYears} from "../../../util";
import DataGrid, {
    Column, Export, Selection, Summary, TotalItem, Grouping, GroupPanel, SearchPanel, GroupItem
} from "devextreme-react/data-grid";
import {Workbook} from "exceljs";
import {exportDataGrid} from "devextreme/excel_exporter";
import {saveAs} from "file-saver-es";

import {LoadPanel} from "devextreme-react/load-panel";
import Button from "devextreme-react/button";
import FaOrEdit from "./edit/FaOrEdit";
import FaZarEdit from "./edit/FaZarEdit";
import CurrentMonthDetial from "./CurrentMonthDetial";
import fcsService from "../../../services/api/fcs";
import LoadPanelComponent from "../../../components/LoadPanelComponent";

const monthList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

export default function FaReportClose(props) {

    const myRef = useRef(null);
    const userListPosition = {of: '#usersList'}

    const [loader, setLoader] = useState(false);
    const [yearValue, setYearValue] = useState(null);
    const [monthValue, setMonthValue] = useState(null);
    const [yearCurrent, setYearCurrent] = useState(null);
    const [monthCurrent, setMonthCurrent] = useState(null);


    const [reportId, setReportId] = useState(0)
    const [reportTitle, setReportTitle] = useState(null);


    useEffect(() => {
        fcsApTranList();
    }, [])

    useEffect(() => {
        setYearMonth()
    }, [yearCurrent, monthCurrent])


    const fcsApTranList = async () => {
        try {
            setLoader(true);


            const fcsMonth = await fcsService.getAllFcsMonth('FA');
            fcsMonth.map((item) => {
                setYearCurrent(item.year);
                setMonthCurrent(item.month);

            })


            return setLoader(false)
        } catch (e) {
            console.error(e);
        }

    }

    const setYearMonth = () => (

        Number(monthCurrent) > 1 ? (setYearValue(yearCurrent),
                setMonthValue(Number(monthCurrent) - 1)
        ) : (setYearValue(Number(yearCurrent) - 1),
            setMonthValue(12))

    )

    const changeYear = (e) => {
        setReportId(0)
        if (e > yearCurrent) setYearMonth()
        if (e < yearCurrent) setYearValue(e);
        if (e == yearCurrent) {
            setYearValue(e);
            if (monthValue >= monthCurrent) setYearMonth()
        }
    }

    const changeMonth = (e) => {
setReportId(0)
        if (yearValue > yearCurrent) setYearMonth()
        if (yearValue < yearCurrent) setMonthValue(e)
        if (yearValue == yearCurrent) {
            if (e >= monthCurrent) setYearMonth()
            if (e < monthCurrent) setMonthValue(e)
        }

    }


    const onExporting = (e) => {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Main sheet');

        exportDataGrid({
            component: e.component, worksheet, autoFilterEnabled: true,
        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                //`${item.acct} - ${item.descr}`
                saveAs(new Blob([buffer], {type: 'application/octet-stream'}), `${reportTitle}-${yearValue}-${monthValue}.xlsx`);
            });
        });
        e.cancel = true;
    }


    return (<React.Fragment>
        <div ref={myRef}>
            <LoadPanelComponent
                shadingColor="rgba(0,0,0,0.4)"
                position={userListPosition}
                visible={loader}
                message="Түр хүлээнэ үү..."
                showPane={false}
            />
            <div className="row">
                <div style={{display: 'flex', marginRight: 20, marginLeft: 20}}>
                    <div style={{marginRight: 10, marginLeft: 10, display: 'flex', alignItems: 'center'}}>Өмнөх
                        он:
                    </div>
                    <div>
                        <SelectBox
                            value={yearValue}
                            width={100}
                            items={getYears(true)}
                            defaultValue={yearValue}
                            onValueChanged={(e) => changeYear(e.value)}
                            placeholder="сонгох"/>
                    </div>
                </div>
                <div style={{display: 'flex', marginRight: 20}}>
                    <div style={{marginRight: 10, display: 'flex', alignItems: 'center'}}>сар:</div>
                    <div>
                        <SelectBox
                            width={80}
                            value={monthValue}
                            items={monthList}
                            defaultValue={monthValue}
                            onValueChanged={(e) => changeMonth(e.value)}
                            placeholder="сонгох"/>
                    </div>
                </div>
            </div>


            <div className="row" style={{marginTop: 10}}>

                <div style={{marginLeft: 10}}>
                    <Button text="Нэмэгдсэн"
                        //  onClick={() => getApReport()}
                            width={140} type="default"
                            onClick={(e) => (setReportId(1))}
                            style={{alignment: "center", borderRadius: '7px'}}/>
                </div>
                <div style={{marginLeft: 10}}>
                    <Button text="Хасагдсан"
                            onClick={() => setReportId(2)}
                            width={140} type="default"
                            style={{alignment: "center", borderRadius: '7px'}}/>
                </div>
                {/*<div style={{marginLeft: 10}}>*/}
                {/*    <Button text="Хураангуй"*/}
                {/*            onClick={() => setReportId(3)}*/}
                {/*            width={140} type="default"*/}
                {/*            style={{alignment: "center", borderRadius: '7px'}}/>*/}
                {/*</div>*/}
                <div style={{marginLeft: 10}}>
                    <Button text="Дэлгэрэнгүй"
                            onClick={() => setReportId(4)}
                            width={140} type="default"
                            style={{alignment: "center", borderRadius: '7px'}}/>
                </div>
            </div>
            {reportId == 1 && <FaOrEdit yearValue={yearValue} monthValue={monthValue} countFaOr={9999}/>}
            {reportId == 2 && <FaZarEdit yearValue={yearValue} monthValue={monthValue} countFaZar={9999}/>}
            {reportId == 4 && <CurrentMonthDetial yearValue={yearValue} monthValue={monthValue} countFaZar={9999}/>}


        </div>


        <div>


        </div>


    </React.Fragment>)

}
/*eslint-disable*/
import React, {useEffect, useRef} from "react";
import 'devextreme-react/text-area';
import {number} from "prop-types";
import ReactToPrint from "react-to-print";
import Button from "devextreme-react/button";
import DataGrid, {
    Column, Export,
    Grouping,
    GroupItem,
    GroupPanel,
    SearchPanel,
    Summary,
    TotalItem
} from "devextreme-react/data-grid";
import {Workbook} from "exceljs";
import {exportDataGrid} from "devextreme/excel_exporter";
import {saveAs} from "file-saver-es";
import {NumberToString} from "../../BankBook/report/bankp";
import {dataSource} from "../../../updated-budget/components/dummydata";
function formatDate(date) {
    let d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}

const Inv03 = (props) => {
    const data = props.reportData

    const componentRef = useRef();
    const orgName = props.orgName

    const customizeDate = (data) => {
        return Number(parseFloat(data.value).toFixed(2)).toLocaleString("en", {
            minimumFractionDigits: 2,
        })
    }
    Date.prototype.yyyymmdd = function() {
        var mm = this.getMonth() + 1; // getMonth() is zero-based
        var dd = this.getDate();

        return [this.getFullYear()+'-',
            (mm>9 ? '' : '0') + mm+'-',
            (dd>9 ? '' : '0') + dd
        ].join('');
    };

    const   amt = (rowData) =>  {
        return rowData.iss_qty* rowData.unit_cost;
    }
    const style = {
        text: {
            color: "#2E4765", fontWeight: "700", fontSize: ".875rem", marginLeft: 5, marginRight: 5
        },
        box: {
            border: "none", backgroundColor: "#F6F6F6", borderRadius: ".5rem", padding: ".25rem .15rem", width: "100%"
        },
        box2: {
            border: "none",
            backgroundColor: "rgb(239, 239, 239)",
            borderRadius: ".5rem",
            padding: ".25rem .4rem",
            width: "100%"
        }
    }


    return (
        <div>

            <div className="row" style={{display: 'flex', marginTop: 30,}}>

                <Button type=""
                        icon='save'
                        className="saveButton" onClick={() => props.setReportShow(false)}> Буцах </Button>

                <ReactToPrint
                    trigger={() => <Button
                        type="default"
                        icon='save'
                        className="saveButton">Хэвлэх</Button>}
                    content={() =>
                        componentRef.current}
                />
            </div>


            <div ref={componentRef}
                 style={{
                     marginLeft: 30,
                     marginRight: 20,
                     display: "flex",
                     justifyContent: "space-evenly",
                     padding: "1.5rem",
                     flexDirection: "column",
                 }}>
                <div className="row" style={{justifyContent: "space-between", alignItems: "center",}}>
                    <div style={{marginLeft: 10, marginRight: 10}}>
                        <div style={{alignItems: 'left'}}>НХМаягт БМ-3</div>
                    </div>
                    <div style={{alignItems: 'Left'}}> ЗАРЛАГЫН БАРИМТ №_
                    </div>
                    <div style={{...style.text}}>
                        {data[0].is_number}
                    </div>
                    <div style={{...style.text}}>
                    </div>

                </div>


                <div className="row" style={{display: 'flex', marginTop: "1rem"}}>

                    <div className='col-5'
                         style={{marginLeft: 5, marginRight: 10, display: "flex"}}>
                        <div style={{display: 'flex', ...style.text}}>  {orgName}  </div>
                    </div>


                        <div className='col-5'
                             style={{marginLeft: 5, marginRight: 5, display: "flex"}}>
                            <div style={{display: 'flex'}}> Худалдан авагч :</div>
                            <div style={{display: 'flex', ...style.text}}>  {data[0].cust_model.name} - {data[0].org} </div>
                        </div>

                    <div className="row" style={{display: 'flex', marginTop: "1rem"}}>

                        <div className='col-5'
                             style={{marginLeft: 5, marginRight: 10, display: "flex"}}>
                            <div style={{display: 'flex' }}> Регистрийн дугаар :  </div>
                        </div>


                        <div className='col-5'
                             style={{marginLeft: 5, marginRight: 5, display: "flex"}}>
                            <div style={{display: 'flex'}}> Регистрийн дугаар :</div>
                            <div style={{display: 'flex', ...style.text}}>  {data[0].cust_model.rd}  </div>
                        </div>
                    </div>


                        <div className="row" style={{display: 'flex', marginTop: 10}}>
                        <div className='col-6'
                             style={{marginLeft: 5, marginRight: 10, display: "flex"}}>
                            <div style={{display: 'flex'}}> огноо :</div>
                            <div style={{display: 'flex', ...style.text}}>   { formatDate( data[0].iss_date)} </div>
                        </div>

                    </div>


                    <div style={{marginRight: 20, marginTop: 10}}>

                        <DataGrid
                            dataSource={data}
                            columnAutoWidth={true}
                            showBorders={true}
                            allowColumnResizing={true}
                            hoverStateEnabled={true}
                            allowColumnResizing={true}
                            wordWrapEnabled={true}
                            keyExpr="id"
                            noDataText='Дата байхгүй байна.'
                            style={{marginLeft: 20, marginRight: 20}}
                        >


                            <Column caption="Материалын үнэт зүйлийн нэр, зэрэг, дугаар " dataField="stname"/>
                            <Column caption="Код" dataField="stnumber"/>
                            <Column caption="Хэмжих нэгж" dataField="unit_name"/>
                            <Column caption="Худалдах">
                                <Column caption="Тоо" dataField="iss_qty" format="#,##0.00" dataType="number"/>
                                <Column caption="Нэгжийн үнэ" dataField="unit_cost" format="#,##0.00"
                                        dataType="number"/>
                                <Column name={"amt"} calculateDisplayValue={amt}
                                        caption="Нийт дүн"
                                        calculateCellValue={amt}
                                />


                            </Column>
                            <Summary>


                                <TotalItem
                                    column="amt"
                                    summaryType="sum"
                                    customizeText={customizeDate}/>
                            </Summary>
                            {/*<Export enabled={true} allowExportSelectedData={false}/>*/}
                        </DataGrid>


                    </div>
                    <div className="row" style={{display: 'flex', marginTop: "1rem"}}>

                        <div className='col-2'>
                        </div>

                        <div className='col-8'
                             style={{marginLeft: 5, marginRight: 10, display: "flex"}}>
                            <div style={{display: 'flex'}}> Хүлээн авсан . . . . . . . . . . . . . . . . . . . . . . /.
                                . . . . . . . . . . . . . . . . . . . . /
                            </div>
                        </div>
                    </div>


                    <div className="row" style={{display: 'flex', marginTop: 5}}>

                        <div className='col-1'>
                        </div>
                        <div className='col-2'>Тэмдэг
                        </div>

                    </div>
                    <div className="row" style={{display: 'flex', marginTop: 5}}>

                        <div className='col-2'>
                        </div>

                        <div className='col-9'
                             style={{marginLeft: 5, marginRight: 10, display: "flex"}}>
                            <div style={{display: 'flex'}}> Хүлээлгэн өгсөн . . . . . . . . . . . . . . . . . . . . .

                            </div>
                            <div style={{display: 'flex', ...style.text}}>   {data[0].loctn_name} </div>
                        </div>
                    </div>


                    <div className="row" style={{display: 'flex'}}>
                        <div className='col-6'>

                        </div>

                    </div>

                    <div style={{marginBottom: 20}}>


                    </div>
                </div>


            </div>

        </div>
    )
        ;
};

export default Inv03
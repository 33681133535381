import React, {useEffect, useRef, useState} from 'react';

import DataGrid, {Column, Editing, Paging, Lookup, RequiredRule, Pager, FilterRow} from "devextreme-react/data-grid";
import SelectBox from "devextreme-react/select-box";
import RemainingService from "../../../services/api/remainingService";
import notify from "devextreme/ui/notify";
import RoleTagBoxComponent from "../components/RoleTagBoxComponent";
import LoadPanelComponent from "../../budget/components/loadPanel";

function UserSettingsSection() {
  const[loader, setLoader] = useState(false);

  const[mainData, setMainData] = useState([]);
  const[refRoles, setRefRoles] = useState([]);

  const dataGridPosition = { of: '#usersList'}

  useEffect(async () => {
    try {
      setLoader(true);
      await getAllRoles();
      await getReferenceRoles();
      // await getPermissions();
      setLoader(false);
    } catch (e) {
      console.error(e);
    }
  },[]);

  const dataGridRef = useRef(null);

  const getAllRoles = async () => {
    try {
      const result = await RemainingService.getAllRoles();
      // console.log({result});
      return setMainData(result.data);
    } catch (e) {
      console.error(e);
    }
  }

  const cellTemplate = (container, options) => {
    let noBreakSpace = '\u00A0',
        text = (options.value || []).map(element => {
          return options.column.lookup.calculateCellValue(element);
        }).join(', ');
    // console.log('text', text);
    container.textContent = text || noBreakSpace;
    container.title = text;
  }

  const calculateFilterExpression = (filterValue, selectedFilterOperation, target) => {
    if(target === 'filterRow' && typeof (filterValue) === 'number') {
      return ['role_id', 'contains', filterValue];
    }
    return function(data) {
      return (data.user_role || []).indexOf(filterValue) !== -1;
    };
  }

  //Services
  const getReferenceRoles = async () => {
    try {
      const result = await RemainingService.getReferenceRoles();
      // console.log('GET REFERENCE ROLES',result);
      return setRefRoles(result.data);
    } catch (e) {
      console.error(e);
    }
  }


  // const getPermissions = async () => {
  //   try {
  //     const result = await RefService.getPermissions();
  //     // console.log({users});
  //     return setPermissions(result.data);
  //   } catch (e) {
  //     console.error(e);
  //   }
  // }

  // Select Box functions
  function onValueChanged(cell, e) {
    cell.setValue(e.value);
  }

  function itemRender(data) {
    if (data != null) {
      return <div>
        <span className="middle">{data.name}</span>
      </div>;
    } else {
      return <span>(All)</span>;
    }
  }

  function statusEditorRender(cell) {
    let onValueChangedVar = onValueChanged.bind(this, cell);
    return <SelectBox
      defaultValue={cell.value}
      {...cell.column.lookup}
      onValueChanged={onValueChangedVar}
      itemRender={itemRender}
    />;
  }

  //Editing permission status.
  const updatePermissionMenuRole = async (e) => {
    try {
      setLoader(true);
      const newInfo = e.changes[0].key;
      // console.log('newInfo sdsdsds bat ',newInfo);
      await RemainingService.changeUserRole(newInfo);
      await getAllRoles();
      await getReferenceRoles();
      setLoader(false);
      notify('Амжилттай хадгалагдлаа', "success", 2000);
    } catch (e) {
      setLoader(false);
      notify('Засварлалтыг хадгалах явцад алдаа гарлаа', "error", 2000);
    }
  }

  //Adding new row of information.
  const addRow = (grid) => {
    grid.current.instance.addRow();
  }

  const orderRender = (data) => {
    return <div>{data.rowIndex + 1}</div>
  }
  const onCellPreparedHandler = (e) => {
    if (e.rowType === 'header') {
      e.cellElement.style.color = "black"
      e.cellElement.style.backgroundColor = "#E8E8E8"
      // elem.style.setProperty('color', 'green');
    }
    if (e.rowType === 'data') {
      e.cellElement.style.color = "black"
      // elem.style.setProperty('color', 'green');
    }
  }
  return (
    <div>
      <LoadPanelComponent position={dataGridPosition} visible={loader} />

      <div>
       {/* <div className="card-header">
          <span>Ерөнхий мэдээлэл</span>
        </div>*/}
        <div className="card-body">
          {/*<dev style={{display: 'flex', margin: '0 0 1rem 0'}}>*/}
          {/*  <Button onClick = {() => {addRow(dataGridRef)}} text = "Нэмэх" type = 'success' width = {150}  style={{borderRadius: '7px', marginLeft: 'auto'}}/>*/}
          {/*</dev>*/}
          <DataGrid
            dataSource={mainData}
            showBorders={true}
            showColumnLines={true}
            showRowLines={true}
            onCellPrepared={onCellPreparedHandler}
            onSaved={updatePermissionMenuRole}
            // focusedRowEnabled={true}
            wordWrapEnabled={true}
            keyExpr= {null}
            ref = {dataGridRef}
          >
            <FilterRow
              visible = {true}
            />
            <Pager
              showPageSizeSelector={true}
              // showInfo={true}
              showNavigationButtons={true}
            />
            <Column
              caption="№"
              cellRender = {orderRender}
              width = {60}
              allowEditing = {false}
            />

            <Column
                dataField="last_name"
                caption="Овог"
                allowFiltering={true}
                allowEditing = {false}
            >
              <RequiredRule />
            </Column>
            <Column
                dataField="first_name"
                caption="Нэр"
                allowFiltering={true}
                allowEditing = {false}
            >
              <RequiredRule />
            </Column>

            <Column
              dataField="username"
              caption="Нэвтрэх нэр"
              allowFiltering={true}
              allowEditing = {false}
            >
              <RequiredRule />
            </Column>

            <Column
              dataField="role_id"
              caption="Системийн эрх"
              allowFiltering={true}
              editCellComponent={RoleTagBoxComponent}
              cellTemplate={cellTemplate}
              calculateFilterExpression={calculateFilterExpression}
            >
              <Lookup
                dataSource={refRoles}
                displayExpr="name"
                valueExpr="id"
              />
              <RequiredRule />
            </Column>
            {/*<Column*/}
            {/*  dataField="role_id"*/}
            {/*  caption="Хандалтын түвшин"*/}
            {/*  editCellRender={statusEditorRender}*/}
            {/*  allowFiltering={true}*/}
            {/*>*/}
            {/*  <Lookup*/}
            {/*    dataSource={refRoles}*/}
            {/*    displayExpr="name"*/}
            {/*    valueExpr="id"*/}
            {/*  />*/}
            {/*  <RequiredRule />*/}
            {/*</Column>*/}
            <Editing
              mode="row"
              allowUpdating={true}
              // allowAdding={true}
              // allowDeleting={true}
              confirmDelete={true}
              useIcons={true}
              texts={{
                addRow: 'Төсөл нэмэх',
                cancelAllChanges: 'Болих бүгд',
                cancelRowChanges: 'Болих',
                confirmDeleteMessage: 'Энэхүү мэдээллийг устгах уу?',
                confirmDeleteTitle: 'Баталгаажуулах',
                deleteRow: 'Устгах',
                editRow: 'Өөрчлөх',
                saveAllChanges: 'Хадгалах бүгд',
                saveRowChanges: 'Хадгалах',
                undeleteRow: 'Буцаах',
                validationCancelChanges: 'Баталгаажуулах болих',
              }}
              // confirmSave = {true}
            />
            <Paging enabled={true} />
          </DataGrid>
        </div>
      </div>
    </div>
  )
}

export default UserSettingsSection;

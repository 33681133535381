import React, {useEffect} from 'react';

import {nFormatter, numberWithCommas} from "../../../util";
import Empty from "./empty";

import PieChart, {
    Legend,
    Series,
    Tooltip,
    Format,
    Label,
    Connector,
} from 'devextreme-react/pie-chart';

export const DonutComponent = React.memo((props) => {

    // console.log('render donut comp')

    useEffect(() => {
        let array = props.data;

        array.forEach((v, i) => {
            array[i].label = v.amount;
            array[i].amount = v.amount < 0 ? v.amount*-1 : v.amount;
        });
    }, [props.data]);

    let paletteGreen = ['#4cae4c', '#4cae97', '#a1ae4c', '#61a660', '#447443'];
    let paletteRed = ['#e06565', '#be7d4e', '#b467c9', '#c96767', '#e06565'];
    let paletteBlue = ['#6CA0DC', '#6cdcc2', '#736cdc', '#6c9ddc', '#6CA0DC'];

    let paletteGreenSoft = ['rgba(96,166,159,0.7)', 'rgba(86,122,121,0.7)', 'rgba(96,115,166,0.7)', 'rgba(97,166,96,0.7)', '#447443'];
    let paletteRedSoft = ['rgba(188,137,122,0.7)', 'rgba(177,142,131,0.7)', 'rgba(188,156,122,0.7)', 'rgba(188,122,147,0.7)', '#845567'];
    let paletteBlueSoft = ['rgba(120,182,217,0.7)', 'rgba(117,150,159,0.7)', 'rgba(126,140,217,0.7)', 'rgba(120,217,144,0.7)', '#549865'];

    let palette = ['#1C4E80', '#0091D5', '#EA6A47', '#A5D8DD'];

    const customizeTooltip = (pointInfo) => {
        let text = '';
        if (pointInfo.seriesName === 'performance') {
            text = 'гүйцэтгэл - ';
        }

        return {
            text: `${pointInfo.argumentText} ( ${text}${numberWithCommas(pointInfo.originalValue)}₮ - ${(pointInfo.percent * 100).toFixed(2)}% )`
        };
    }

    const customizeLabel = (point) => {
        return `${point.argumentText }: ${ nFormatter(point.value,2)}`;
    }

    const customizePoint = (arg) => {
        if (arg.seriesName === 'performance') {
            return {color: props.textColor === 'green-text' ? paletteGreenSoft[arg.index] : props.textColor === 'red-text' ? paletteRedSoft[arg.index] : paletteBlueSoft[arg.index]}
        }
    }

    return(
        <div>
            {props.title && <div className='card-header'>
                <span>{props.title}</span>
            </div>}

            {/*Donut graph*/}
            <div className='card-body'>
                <div className={'row'} id={props.bodyID}>
                    {props.data.length === 0 ? <Empty color={props.textColor} height={props.height} /> :
                        <PieChart
                            id="pie"
                            // type="doughnut"
                            // palette={props.textColor === 'green-text' ? paletteGreen : props.textColor === 'red-text' ? paletteRed : paletteBlue}
                            palette={palette}
                            dataSource={props.data}
                            resolveLabelOverlapping={'hide'}
                                // height={props.height}
                            style={{width: '100%', height: Math.round(props.height)}}
                            customizePoint={customizePoint}
                        >

                            {props.withPerformance && <Series name={'performance'} argumentField={'name'} valueField={'amount_performance'}/>}

                            <Series name={'confirmed'} argumentField="name" valueField="amount">
                                {/*<SmallValuesGrouping mode="topN" topCount={3} />*/}
                                <Label
                                    visible={true}
                                    format="fixedPoint"
                                    backgroundColor="none"
                                    customizeText={customizeLabel}
                                >
                                    <Connector visible={true} width={1} />
                                </Label>
                            </Series>

                            <Legend
                                visible={false}
                                margin={0}
                                horizontalAlignment="center"
                                verticalAlignment="bottom"
                            />

                            <Tooltip
                                enabled={true}
                                customizeTooltip = {customizeTooltip}
                            >
                                <Format type='millions' />
                            </Tooltip>

                        </PieChart>
                    }

                </div>
            </div>

            {/*<div style={{padding: '10px', textAlign: 'center'}}>*/}
            {/*    <span className={props.textColor} style={{fontSize: '1.4rem', fontWeight: 400}}>Нийт: <span>{nFormatter(props.data.total, 1)}</span></span>*/}
            {/*</div>*/}
        </div>
    )
});

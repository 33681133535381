/*eslint-disable*/
import React, { useState } from "react";




 export function NumberToString(Pra1)  {
     const  aaa=(X) => {
         let C = X - parseInt(X);
         let ret = 0;

         C > 0.5 ? (ret = X - C + 1) : (ret = X - C);

         return ret;
     }

     const  men1=(pra1)=> {
         let ment = "";

         let ar14 = "000" + pra1.toString();
         ar14 = ar14.slice(ar14.length - 3, ar14.length);

         let Ar = Number(ar14.substring(2, 3));

         switch (Ar) {
             case 0:
                 break;
             case 1:
                 ment = " нэгэн" + ment;
                 break;
             case 2:
                 ment = " хоёр" + ment;
                 break;
             case 3:
                 ment = " гурван" + ment;
                 break;
             case 4:
                 ment = " дөрвөн" + ment;
                 break;
             case 5:
                 ment = " таван" + ment;
                 break;
             case 6:
                 ment = " зургаан" + ment;
                 break;
             case 7:
                 ment = " долоон" + ment;
                 break;
             case 8:
                 ment = " найман" + ment;
                 break;
             case 9:
                 ment = " есөн" + ment;

                 break;
         }

         Ar = Number(ar14.substring(1, 2));

         switch (Ar) {
             case 0:
                 break;
             case 1:
                 ment = " арван" + ment;
                 break;
             case 2:
                 ment = " хорин" + ment;
                 break;
             case 3:
                 ment = " гучин" + ment;
                 break;
             case 4:
                 ment = " дөчин" + ment;
                 break;
             case 5:
                 ment = " тавин" + ment;
                 break;
             case 6:
                 ment = " жаран" + ment;
                 break;
             case 7:
                 ment = " далан" + ment;
                 break;
             case 8:
                 ment = " наян" + ment;
                 break;
             case 9:
                 ment = " ерэн" + ment;
                 break;
         }

         Ar = Number(ar14.substring(0, 1));

         if (Ar > 0) ment = " зуун" + ment;

         switch (Ar) {
             case 0:
                 break;
             case 1:
                 ment = " нэг" + ment;
                 break;
             case 2:
                 ment = " хоёр" + ment;
                 break;
             case 3:
                 ment = " гурван" + ment;
                 break;
             case 4:
                 ment = " дөрвөн" + ment;
                 break;
             case 5:
                 ment = " таван" + ment;
                 break;
             case 6:
                 ment = " зургаан" + ment;
                 break;
             case 7:
                 ment = " долоон" + ment;
                 break;
             case 8:
                 ment = " найман" + ment;
                 break;
             case 9:
                 ment = " есөн" + ment;
                 break;
         }

         if (pra1 == 1) ment = " нэг";

         return ment;
     }


     const valut1 = "төгрөг";
     const valut2 = "мөнгө";


     let Pra = aaa(Pra1 * 100) / 100;
    let Ar99 = Pra - Number(parseInt(Pra));

    Ar99 = Ar99 * 100;
    Ar99 = aaa(Ar99);
    let Men = "";

    Ar99 > 0
        ? (Men = men1(Ar99) + " " + valut2 + ".")
        : (Men = " тэг " + valut2 + ".");

    let negj = 0;

    let ar12 = "000000000000000" + parseInt(Pra);

    ar12 = ar12.slice(ar12.length - 15, ar12.length);

    Number(ar12) > 0
        ? (Men = " " + valut1 + Men)
        : (Men = " тэг " + valut1 + Men);

    let ar13 = Number(ar12.substring(12, 15));
    const ar14 = Number(ar12.substring(12, 15))
    ar13 > 0 ? (Men = men1(ar13) + Men) : (negj = 1);

    ar13 = Number(ar12.substring(9, 12));


     if (ar13 > 0) Men = men1(ar13) + (ar14 > 0? " мянга": " мянган"  ) + Men;

    ar13 = Number(ar12.substring(6, 9));
    if (ar13 > 0) Men = men1(ar13) + " сая" + Men;
    ar13 = Number(ar12.substring(3, 6));
    if (ar13 > 0) Men = men1(ar13) + " тэрбум" + Men;
    ar13 = Number(ar12.substring(0, 3));
    if (ar13 > 0) Men = men1(ar13) + "  их наяд" + Men;
    let Men2 =Men.trim().substring(0, 1).toUpperCase() + Men.trim().substring(1, Men.length - 1);

    return Men2;
}
const DDDD= ()=>{
     return <div></div>
}
export default DDDD;



import { useEffect, useState } from "react";
import { numberWithCommas } from "../../../../../util";
import BudgetService from "../../../../../services/api/budget";
import notify from "devextreme/ui/notify";
import { Popup, ScrollView } from "devextreme-react";
import { Tooltip } from "antd";
import _ from "lodash";
import moment from "moment";
import info1 from "../../../../../assets/imgs/icon/iconInfo1.png"
import { FiCircle } from "react-icons/fi";
import { IoIosCheckmarkCircle, IoIosCloseCircle } from "react-icons/io";
import { DataGrid, Button } from 'devextreme-react'
import { Column, FilterRow, GroupItem, Grouping, Paging, Summary, TotalItem } from 'devextreme-react/data-grid'

export const ExpenseDetail = ({ data }) => {
    const [sanhuujiltReq, setSanhuujiltReq] = useState([])
    const [popUpVisible, setPopUpVisible] = useState(false)
    const [fundingLog, setFundingLog] = useState([])
    const [guitsetgegch, setGuitsetgegch] = useState(null)
    const [updatedAt, setUpdatedAt] = useState(null)
    const [typeNm, setTypeNm] = useState('')
    const [loader, setLoader] = useState(true)

    useEffect(() => {
        loadFundingList();
    }, [])

    const loadFundingList = async () => {
        try {
            let res = await BudgetService.pmisFundingList(data.data.id)
            setSanhuujiltReq(_.orderBy(_.filter(res.data, item => item.status === 'approved'), ['logDtm', 'desc']));
            setUpdatedAt(_.max(res.data, "updatedAt"))
        } catch (error) {
            notify(error.message, 'error', 2000)
        } finally {
            setLoader(false)
        }
    }

    const loadFundingLog = async (props) => {
        try {
            let res = await BudgetService.pmisFundingLog(props)
            setFundingLog(res)
            res.length > 0 && setPopUpVisible(true)
        } catch (error) {
            notify(error.message, 'error', 2000)
        }
    }

    const renderStatusIcon = (status) => {
        switch (status) {
            case 'sent':
                return <IoIosCheckmarkCircle size={28} color='#52c41a' style={{ background: 'white' }} />;
            case 'saved':
                return <IoIosCheckmarkCircle size={28} color='#e5e7eb' style={{ background: 'white' }} />;
            case 'rejected':
                return <IoIosCloseCircle size={28} color='#ed1c24' style={{ background: 'white' }} />;
            case 'approved':
                return <IoIosCheckmarkCircle size={28} color='#52c41a' style={{ background: 'white' }} />;
            default:
                return <FiCircle size={28} color='#e5e7eb' style={{ background: 'white' }} />;
        }
    };

    const onCellPreparedHandler = (e) => {
        let elem = e.cellElement
        elem.style.setProperty('cursor', 'pointer', 'important');
        elem.style.fontSize = "12px"
        if (e.rowType === "header") {
            elem.wordWrapEnabled = true
            elem.style.fontWeight = "bold"
            elem.style.color = "#15186A"
            elem.style.backgroundColor = "#EEF0F4"
        } else if (e.rowType === 'group') {
            elem.style.backgroundColor = "white"
            elem.style.color = "#000"
        }
    }

    const detailButton = (e) => {
        return (
            <Button
                hint='Явцын дэлгэрэнгүй харах'
                stylingMode='outlined'
                style={{ border: 'none' }}
                icon='detailslayout'
                onClick={() => { loadFundingLog(e.data.id); setGuitsetgegch(e.data.supReg); setTypeNm(e.data.typeNm) }}
            />
        )
    }

    return (
        <div style={{ width: '90%', margin: '1rem auto', fontFamily: 'Segoe UI' }}>
            {loader && <span style={{ color: '#15186a' }}>Уншиж байна...</span>}
            {!loader &&
                <div style={{ marginTop: 10 }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div style={{ fontSize: 14, color: '#15186a', marginBottom: 10 }}><b>Санхүүжилтийн хүсэлтүүд</b></div>
                        {updatedAt &&
                            <div style={{ display: 'flex', justifyContent: 'end', paddingBottom: 10 }}>
                                <Tooltip title="Шинэчлэгдсэн огноо" placement='bottomRight'>
                                    <div style={{ cursor: 'default', backgroundColor: '#ebf1ff', width: '6rem', borderRadius: '2rem', position: 'relative', padding: 5 }}>
                                        <div style={{ fontSize: '.6rem', fontWeight: 'bold', paddingLeft: 5 }}>{moment(updatedAt.updatedAt, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD')}</div>
                                        <img src={info1} alt='' width={32} height={32} style={{ position: 'absolute', right: -5, top: -5 }} />
                                    </div>
                                </Tooltip>
                            </div>
                        }
                    </div>
                    <DataGrid
                        dataSource={sanhuujiltReq}
                        noDataText='Санхүүжилтийн мэдээлэл байхгүй'
                        wordWrapEnabled={true}
                        columnAutoWidth={true}
                        scrolling={true}
                        showRowLines={true}
                        showBorders={true}
                        showColumnLines={true}
                        onCellPrepared={onCellPreparedHandler}
                        hoverStateEnabled={true}
                        allowColumnReordering={false}
                        height={'auto'}
                    >
                        <FilterRow visible={true} />
                        <Paging defaultPageSize={20} />
                        <Grouping
                            contextMenuEnabled={false}
                            expandMode="rowClick"
                            texts={{
                                groupContinuesMessage: 'Дараагийн хуудас',
                                groupContinuedMessage: 'Өмнөх хуудас'
                            }}
                        />
                        <Column groupIndex={0} dataField="planYr" autoExpandGroup={true} caption="" groupCellComponent={(e) => e.data.displayValue + ' он:'} showInColumnChooser={true} />
                        <Column dataField="logDtm" caption="Хүсэлт гаргасан огноо" alignment="right" width={350} />
                        <Column dataField="reqAmt" caption="Хүсэлтийн дүн /сая ₮/" alignment="right" customizeText={(e) => numberWithCommas(e.value / 1000000)} />
                        <Column dataField="paymentAmount" caption="Олгосон дүн /сая ₮/" alignment="right" customizeText={(e) => numberWithCommas(e.value / 1000000)} />
                        <Column dataField="perfProg" caption="Тухайн үеийн гүйцэтгэл /%/" alignment="right" width={150} cellRender={(e) => e.value || e.value !== 0 ? e.value : '-'} />
                        <Column dataField="description" caption="Тайлбар" alignment="left" width={400} />
                        <Column caption="Явц харах" width={100} cellRender={detailButton} allowSearch={false} alignment="center" />
                        <Summary>
                            <GroupItem
                                column='reqAmt'
                                summaryType="sum"
                                alignByColumn={true}
                                customizeText={(data) => {
                                    return `${numberWithCommas(data.value / 1000000)}`
                                }}
                            />
                            <GroupItem
                                column="paymentAmount"
                                summaryType="sum"
                                alignByColumn={true}
                                customizeText={(data) => {
                                    return `${numberWithCommas(data.value / 1000000)}`
                                }}
                            />
                            <TotalItem
                                column='reqAmt'
                                summaryType="sum"
                                customizeText={(data) => {
                                    return `${numberWithCommas(data.value / 1000000)}`
                                    // const filteredData = sanhuujiltReq.filter(item => item.status !== 'rejected');
                                    // const sum = filteredData.reduce((acc, item) => acc + item.reqAmt, 0);
                                    // return numberWithCommas(sum / 1000000) + ' сая ₮';
                                }}
                            />
                            <TotalItem
                                column="paymentAmount"
                                summaryType="sum"
                                customizeText={(data) => {
                                    return `${numberWithCommas(data.value / 1000000)}`
                                    // const filteredData = sanhuujiltReq.filter(item => item.status !== 'rejected');
                                    // const sum = filteredData.reduce((acc, item) => acc + item.amount, 0);
                                    // return numberWithCommas(sum / 1000000) + ' сая ₮';
                                }}
                            />
                            <TotalItem
                                column="logDtm"
                                summaryType="count"
                                displayFormat="Нийт {0} хүсэлтийн мэдээлэл харуулж байна" />
                        </Summary>
                    </DataGrid>
                </div>}
            {
                <Popup
                    visible={popUpVisible}
                    dragEnabled={false}
                    showCloseButton={true}
                    hideOnOutsideClick={true}
                    onHiding={() => setPopUpVisible(false)}
                    shading={true}
                    width={500}
                    maxHeight={700}
                    minHeight={200}
                >
                    <ScrollView>
                        <div style={{ fontSize: 16, marginBottom: 20 }}><b>Санхүүжилтын явц</b> {typeNm && `/${typeNm}/`}</div>
                        <div style={{ display: 'flex', flexDirection: 'column', gap: 40, margin: 20, position: 'relative' }}>
                            <div style={{ borderLeft: '3px solid #ddd', height: '100%', position: 'absolute', left: '8%' }}></div>
                            {fundingLog.map((e, index) => (
                                <div key={index} style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                                    <div style={{ zIndex: 10 }}>{e.status ? renderStatusIcon(e.status) : '-'}</div>
                                    <div className="col-10">
                                        <b style={{ marginBottom: 10 }}>{e.name ? e.name : '-'}</b>
                                        <div>Огноо:<span style={{ float: 'right' }}>{e.date ? e.date : '-'}</span></div>
                                        <div>Гүйцэтгэсэн:<span style={{ float: 'right' }}>{e.imp ? e.imp : '-'}</span></div>
                                        <div>Хугацаа:<span style={{ float: 'right' }}>{e.parsedDate ? e.parsedDate : '-'}</span></div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <a href={`http://opendata.burtgel.gov.mn/lesinfo/${guitsetgegch}`} target="_blank" rel="noreferrer">
                            Гүйцэтгэгчийн мэдээлэл харах
                        </a>
                    </ScrollView>
                </Popup>
            }
        </div>
    );
};

// <div style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: 10 }}>
//     {sanhuujiltReq.map((e, index) => {
//         return (
//             <div className="card" style={{ fontSize: 14 }} key={index} >
//                 <div style={{ borderBottom: '1px solid #ddd', borderRadius: '15px 15px 0 0', padding: '1rem', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
//                     <div style={{ fontWeight: 'bold' }}>{e.typeNm}</div>
//                     <Tooltip title='Явцын дэлгэрэнгүй харах'>
//                         <div className="log_detail_btn" onClick={() => {loadFundingLog(e.id); setGuitsetgegch(e.supReg); setTypeNm(e.typeNm)}}><TbListDetails fontSize={16} /></div>
//                     </Tooltip>
//                 </div>
//                 <div style={{ padding: '1rem', display: 'flex', flexDirection: 'column', gap: 10 }}>
//                     <div>Хүсэлт гаргасан огноо: <b style={{ float: 'right' }}>{e.logDtm}</b></div>
//                     <div>Хүссэн дүн: <b style={{ float: 'right' }}>{e.reqAmt ? numberWithCommas(e.reqAmt / 1000000) + ' сая ₮' : '-'}</b></div>
//                     <div>Олгосон дүн: <b style={{ float: 'right' }}>{e.amount && e.status !== 'rejected' ? numberWithCommas(e.amount / 1000000) + ' сая ₮' : '-'}</b></div>
//                     <div>Тухайн үеийн гүйцэтгэл: <b style={{ float: 'right' }}>{e.perfProg ? e.perfProg + '%' : '-'}</b></div>
//                 </div>
//             </div>
//         )
//     })}
// </div>
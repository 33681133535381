import React, { useContext, useEffect, useState } from "react"
import TabPanel, { Item } from "devextreme-react/tab-panel"
import { UserContext } from "../../../hooks/UserContext"
import ProposalService from "../../../services/api/proposal"
import MinistryGrid from "./proposalMinistryGrid"
import ProposalMinistryMore from "./proposalMinistryMore"
import PivotGridDataSource from "devextreme/ui/pivot_grid/data_source"
import "../static/proposal.css"
//import ProposalSlideBudget from "./proposalSlideBdget"
import { getYears } from "../../../util"
import { Lookup, DropDownOptions } from "devextreme-react/lookup"
import SelectBox from "devextreme-react/select-box"
import LoadPanelComponent from "../../budget/components/loadPanel"
import { Popup, TagBox, Tooltip } from "devextreme-react"
import { ProjectButton } from ".."
import { BsFileEarmarkText } from "react-icons/bs"
import { FaBalanceScale, FaBook } from "react-icons/fa"
import Joyride from "react-joyride"
import { BiSearch } from "react-icons/bi"
import { AiOutlineProfile } from 'react-icons/ai'
import { tusliinNegtgelSteps, guideLocale } from '../../../components/SystemGuideSteps'
import styled from "styled-components"
import ProposalMergeReport from "./ProposalMergeReport";

const ProposalDate = styled.div`
    color: #f66160;
    font-weight: 700;
    border: 1px solid #f66160;
    padding: 0.6rem 1rem;
    border-radius: 0.5rem;
    transition: 0.3s all ease;
    cursor: default;
    &:hover {
      background-color: #f66160;
      color: white;
    }
`
const deadlineTooltip = {
  1: '8.4.3.төсвийн ерөнхийлөн захирагч жилийн төсвийн саналаа жил бүрийн 07 дугаар сарын 25-ны дотор санхүү, төсвийн асуудал эрхэлсэн төрийн захиргааны төв байгууллага, төрийн аудитын байгууллага, Улсын Их Хуралд хүргүүлж, нийтэд ил тод мэдээлэх',
  2: '8.4.2.төсвийн төвлөрүүлэн захирагч, төсвийн төвлөрүүлэн захирагчид харьяалагддаггүй төсвийн шууд захирагч жил бүрийн 07 дугаар сарын 20-ны дотор жилийн төсвийн төслөө харьяалагдах төсвийн ерөнхийлөн захирагчид хүргүүлэх',
  3: '8.4.1.төсвийн төвлөрүүлэн захирагчид харьяалагддаг төсвийн шууд захирагч нь жилийн төсвийн төслөө жил бүрийн 7 дугаар сарын 09-ний дотор харьяалах төсвийн төвлөрүүлэн захирагчид хүргүүлэх'
}
const mergeViewList = [
  { name: 'Байгууллага,хөтөлбөр арга хэмжээгээр', value: 'default' },
  { name: 'Эдийн засгийн ангиллаар', value: 'second' },
]
const viewFormatList = [
  { name: 'Саячилж харах', value: 'default' },
  { name: 'Мянгачилж харах', value: 'second' },
  { name: 'Суурь задгайгаар харах', value: 'third' },
]
const viewEconStart = [
  { name: 'Нийт орлого ба тусламжийн дүн', value: '1' },
  { name: 'Нийт зарлага ба цэвэр зээлийн дүн', value: '2' },
  { name: 'Зардлыг санхүүжүүлэх эх үүсвэр', value: '3' },
  { name: 'Төсвийн бусад мэдээллийн ангилал', value: '4' },
]
export default function ProposalMinistry(props) {
  //const [economicCategoryArray, setEconomicCategoryArray] = useState([])
  const [dataSource, setDataSource] = useState([])
  const [loader, setLoader] = useState(false)
  //const [tabs, setTabs] = useState([])
  const [title, setTitle] = useState(null)
  //const [currentProposal, setCurrentProposal] = useState(null)
  const { user } = useContext(UserContext)
  //const [budgetCalculation, setBudgetCalculation] = useState({})
  //const [statusCounter, setStatusCounter] = useState({})
  //const agendaId = useRef(null)
  //const eventId = useRef(null)
  //const [useData, setUseData] = useState([])
  const [dropDown, setDropDown] = useState([])
  //const [groupedXlsData, setGroupedXlsData] = useState([])
  //const [dsData, setDsData] = useState([])
  //const [dataFetch, setDataFetch] = useState([])
  //const [useFlex, setUseFlex] = useState([])
  //const [defaultValue, setDefaultValue] = useState([])
  //const [budgetHistory, setBudgetHistory] = useState([])
  //const [loader2, setLoader2] = useState(false)
  const [year, setYear] = useState(new Date().getFullYear()+1)
  const [filePopupModal2, setFilePopupModal2] = useState(false)
  //const [statusChange, setStatusChange] = useState()
  //const [statusChangeBoolean, setStatusChangeBoolean] = useState(false)

  const [orgChange, setOrgChange] = useState()
  //const [orgChangeBoolean, setOrgChangeBoolean] = useState(false)
  //const [allOrgData, setAllOrgData] = useState([])
  //const [checker, setChecker] = useState(1)
  const [wordWrapFixer, setWordWrapFixer] = useState(true)
  const [selectedType, setSelectedType] = useState(null)
  const [selectedOrgData, setSelectedOrgData] = useState(null)
  const [width, setWidth] = useState(false)
  /*const dataGridPositionId = {of: "#dataGrid"}
  const historyPosition = {of: "historyz"}
  const budgetCalc = {of: "#budgetcalc"}*/
  const [selectedStatus, setSelectedStatus] = useState([1,2,3,4,5,6,7,8,9]);
  //const [icon, setIcon] = useState('hidepanel')
  const [chosenViewList, setChosenViewList] = useState('second');
  const [chosenViewFormatList, setChosenViewFormatList] = useState('default');
  const [chosenViewEcon, setChosenViewEcon] = useState('2');
  const [selectedIndex, setSelectedIndex] = useState(0)

  useEffect(() => {
    const data = { org_id: null, org_name: "Бүгд" }
    setSelectedOrgData(data)
    setTitle(user.organization.name)
    //changeStatusId()
    // loadStatData();
    //loadRefEconomicCategory()
    // getData();
    getStatus()
    //chartData(data, selectedStatus, chosenViewList,chosenViewFormatList,chosenViewEcon)
  }, [year])
  let obj = []

  /*const loadRefEconomicCategory = async () => {
    setLoader(true)
    const result = await RefService.getRefEconomicCategory()
    setEconomicCategoryArray(result.data)
    setLoader(false)
  }*/

  const getStatus = async () => {
    setLoader(true)
    const result = await ProposalService.getStatusCount(year);
    // const array = result.data.filter(item => {
    //   return (item.budget_project_status_id === 7 || item.budget_project_status_id === 5 || item.budget_project_status_id === 6)
    // })
    result.data.map((item) => {
      if (
          item.budget_project_status_id === 8 ||
          item.budget_project_status_id === 9 ||
        item.budget_project_status_id === 7 ||
        item.budget_project_status_id === 5 ||
        item.budget_project_status_id === 6 ||
        item.budget_project_status_id === 1 ||
        item.budget_project_status_id === 2 ||
        item.budget_project_status_id === 3 ||
        item.budget_project_status_id === 4
      ) {
        obj.push(item)
      }
    })
    setDropDown(obj)
    //setStatusCounter(result.data)
    setSelectedType(obj[0])
    setSelectedStatus('1,2,3,4,5,6,7,8,9');
    await loadData('1,2,3,4,5,6,7,8,9')

    setLoader(false)
  }
  /*  const setupDefault = async () => {
      const result = await ProposalService.getDefaultProposal(year)
      setCurrentProposal(result.data.id)
    }
  
    const loadStatData = async (data = null) => {
      // TODO: Create Pie chart by budget_project_status_id
      setLoader(true)
      const result = await ProposalService.getProposals(CURRENT_YEAR + 1)
      setLoader(false)
    }
  
    const getData = async () => {
      const res = await ProposalService.getProjectProgress(false)
    }*/

  /*const changeStatusId = async () => {
    try {
      let object = {
        type: false,
        budget_project_status_id: 1,
      }

      const res = await ProposalService.getProjectProgress(object, year)
      let bc = await _.groupBy(res.data || [], "budget_project_status_id")
      let bois = await _.groupBy(res.data || [], "econ_category_code_1")
      //await setUseData(bc)
      await setAllOrgData(bois)
      //await setDefaultValue(bc[6])

      let flex = _.groupBy(bc[6] || [], "econ_category_code_1")
      //setUseFlex(flex)

      //setBudgetCalculation(flex)
    } catch (e) {
      console.error(e)
    }
  }*/

  const proposalDeadline = {
    1: `${year - 1}-07-25`,
    2: `${year - 1}-07-20`,
    3: `${year - 1}-07-09`,
  }

  const loadData = async (id) => {
    const result = await ProposalService.getProposalMergeOrgBox(year,id && id.length >0 ? id : '1,2,3,4,5,6,7,8,9');
    let tempObj = []
    tempObj.push(result);
    tempObj = [{ org_id: null, org_name: "Бүгд" }].concat(result)
    setOrgChange(tempObj)
    setLoader(false)
  }
  /*const customizeDate = (data) => {
    console.log("data s0", data)
    return Number(parseFloat(data.value / 1000000).toFixed(2)).toLocaleString(
      "en",
      {
        minimumFractionDigits: 2,
      }
    )
  }*/
  const chartData = async (data, status, viewType, viewFormat, viewEcon) => {
    if (data === null) { data.org_id = null; data.org_name = 'Бүгд' }
    //console.log('data', data);
    //selectedStatus={selectedStatus === null ? selectedType?.budget_project_status_id : selectedStatus}
    if (viewType !== 'default') { viewEcon = 'all' }
    setLoader(true)
    const anotherList = await ProposalService.listDetailMinistry(
      year,
      data.org_id, status === null ? selectedType?.budget_project_status_id : status, viewFormat, viewEcon, viewType
    )
    let ds;
    if (viewType === 'default') {
      ds = new PivotGridDataSource({
        fields: [
          {
            caption: "ТТЗ",
            width: '18rem',
            dataField: "org_name_2",
            area: "row",
            allowFiltering: true,
            headerFilter: true,
            visible: user.organization.obt_id === 1 ? true : false
          },
          {
            caption: "ТШЗ",
            width: '18rem',
            height: '26rem',
            dataField: "org_name",
            area: "row",
            //expanded: true,
          },
          {
            caption: "Хөтөлбөр",
            width: '18rem',
            dataField: "agenda_name",
            area: "row",
            allowFiltering: false,
            headerFilter: false,
            //expanded: true,
          },
          {
            caption: "Арга хэмжээ",
            width: '18rem',
            dataField: "event_name",
            area: "row",
            //summaryType: "max",
            //expanded: true,
          },
          {
            caption: 'Эдийн засгийн ангилал 1',
            dataField: 'econ_category_name_1',
            area: 'row',
            sortBySummaryField: 'Total',
            //expanded: true,
            sortBy: 'displayText',
            sortOrder: 'asc',
            width: '18rem',
          },
          {
            caption: 'Эдийн засгийн ангилал 2',
            dataField: 'econ_category_name_2',
            area: 'row',
            sortBy: 'displayText',
            sortOrder: 'asc',
            //expanded: true,
            width: '18rem',
          },
          {
            caption: 'Эдийн засгийн ангилал 3',
            dataField: 'econ_category_name_3',
            area: 'row',
            sortBy: 'displayText',
            sortOrder: 'asc',
            width: '18rem',
          },
          {
            caption: 'Эдийн засгийн ангилал 4',
            dataField: 'econ_category_name_4',
            width: '18rem',
            area: 'row',
            sortBy: 'displayText',
            sortOrder: 'asc',
          },
          {
            caption: "Эдийн засгийн ангилал",
            width: '18rem',
            dataField: "econ_name",
            area: "row",
          },
          {
            caption: `${year - 3} оны гүйцэтгэл`,
            dataField: "prev3_amt",
            dataType: "number",
            summaryType: "sum",
            format: {
              type: 'fixedPoint',
              precision: 2
            },
            allowCrossGroupCalculation: true,
            area: "data",
          },
          {
            caption: `${year - 2} оны гүйцэтгэл`,
            dataField: "prev2_amt",
            dataType: "number",
            summaryType: "sum",
            format: {
              type: 'fixedPoint',
              precision: 2
            },
            allowCrossGroupCalculation: true,
            area: "data",
          },
          {
            caption: `${year - 1} оны төсөв`,
            dataField: "current_budget_amt",
            dataType: "number",
            summaryType: "sum",
            format: {
              type: 'fixedPoint',
              precision: 2
            },
            allowCrossGroupCalculation: true,
            area: "data",
          },
          {
            caption: "ТШЗ санал",
            dataField: "amount",
            dataType: "number",
            summaryType: "sum",
            format: {
              type: 'fixedPoint',
              precision: 2
            },
            allowCrossGroupCalculation: true,
            area: "data",
            /*customizeText: function (cellInfo) {
              return Number(
                  parseFloat(cellInfo.value / 1000000).toFixed(2)
              ).toLocaleString("en", {
                minimumFractionDigits: 2,
              })
            },*/
          },
          {
            caption: "ТТЗ санал",
            dataField: "ttz_amount",
            dataType: "number",
            summaryType: "sum",
            format: {
              type: 'fixedPoint',
              precision: 2
            },
            allowCrossGroupCalculation: true,
            area: "data",
            /*customizeText: function (cellInfo) {
              return Number(
                  parseFloat(cellInfo.value / 1000000).toFixed(2)
              ).toLocaleString("en", {
                minimumFractionDigits: 2,
              })
            },*/
          },
          {
            caption: "ТЕЗ санал",
            dataField: "tez_amount",
            dataType: "number",
            summaryType: "sum",
            format: {
              type: 'fixedPoint',
              precision: 2
            },
            allowCrossGroupCalculation: true,
            area: "data",
            /*customizeText: function (cellInfo) {
              return Number(
                  parseFloat(cellInfo.value / 1000000).toFixed(2)
              ).toLocaleString("en", {
                minimumFractionDigits: 2,
              })
            },*/
          },
          {
            caption: `${year} оны хязгаар`,
            dataField: "hyazgaar_amount",
            dataType: "number",
            summaryType: "sum",
            format: {
              type: 'fixedPoint',
              precision: 2
            },
            visible: false,
            allowCrossGroupCalculation: true,
            area: "data",
          },
          {
            caption: `${year + 1} оны төсөөлөл`,
            dataField: "tosoolol_1_amt",
            dataType: "number",
            summaryType: "sum",
            format: {
              type: 'fixedPoint',
              precision: 2
            },
            allowCrossGroupCalculation: true,
            area: "data",
            /*customizeText: function (cellInfo) {
              return Number(
                  parseFloat(cellInfo.value / 1000000).toFixed(2)
              ).toLocaleString("en", {
                minimumFractionDigits: 2,
              })
            },*/
          },

          {
            caption: `${year + 2} оны төсөөлөл`,
            dataField: "tosoolol_2_amt",
            dataType: "number",
            summaryType: "sum",
            visible: false,
            format: {
              type: 'fixedPoint',
              precision: 2
            },
            allowCrossGroupCalculation: true,
            area: "data",
            /*customizeText: function (cellInfo) {
              return Number(
                  parseFloat(cellInfo.value / 1000000).toFixed(2)
              ).toLocaleString("en", {
                minimumFractionDigits: 2,
              })
            },*/
          },
          // {
          //   caption: 'ТТЗ дүн',
          //   dataField: 'ttz_amount',
          //   dataType: 'number',
          //   visible: false,
          //   summaryType: 'sum',
          //   format: '#,##0.00',
          //   area: 'data'
          // },
        ],
        store: anotherList.data,
      })
    }
    else {
      ds = new PivotGridDataSource({
        fields: [
          {
            caption: 'Эдийн засгийн ангилал 1',
            dataField: 'econ_category_name_1',
            area: 'row',
            sortBySummaryField: 'Total',
            //expanded: true,
            sortBy: 'displayText',
            sortOrder: 'asc',
            width: '18rem',
          },
          {
            caption: 'Эдийн засгийн ангилал 2',
            dataField: 'econ_category_name_2',
            area: 'row',
            sortBy: 'displayText',
            sortOrder: 'asc',
            //expanded: true,
            width: '18rem',
          },
          {
            caption: 'Эдийн засгийн ангилал 3',
            dataField: 'econ_category_name_3',
            area: 'row',
            sortBy: 'displayText',
            sortOrder: 'asc',
            width: '18rem',
          },
          {
            caption: 'Эдийн засгийн ангилал 4',
            dataField: 'econ_category_name_4',
            width: '18rem',
            area: 'row',
            sortBy: 'displayText',
            sortOrder: 'asc',
          },
          {
            caption: "Эдийн засгийн ангилал",
            width: '18rem',
            dataField: "econ_name",
            area: "row",
          },
          {
            caption: "ТТЗ",
            width: '18rem',
            dataField: "org_name_2",
            area: "column",
            sortOrder: 'asc',
            visible: user.organization.obt_id === 1 ? true : false
            //expanded: true,
          },
          {
            caption: "ТШЗ",
            width: '18rem',
            dataField: "org_name",
            area: "column",
            sortOrder: 'asc',
            //expanded: true,
          },
          {
            caption: `${year - 3} оны гүйцэтгэл`,
            dataField: "prev3_amt",
            dataType: "number",
            summaryType: "sum",
            format: {
              type: 'fixedPoint',
              precision: 2
            },
            allowCrossGroupCalculation: true,
            area: "data",
          },
          {
            caption: `${year - 2} оны гүйцэтгэл`,
            dataField: "prev2_amt",
            dataType: "number",
            summaryType: "sum",
            format: {
              type: 'fixedPoint',
              precision: 2
            },
            allowCrossGroupCalculation: true,
            area: "data",
          },
          {
            caption: `${year - 1} оны төсөв`,
            dataField: "current_budget_amt",
            dataType: "number",
            summaryType: "sum",
            format: {
              type: 'fixedPoint',
              precision: 2
            },
            allowCrossGroupCalculation: true,
            area: "data",
          },
          {
            caption: "ТШЗ санал",
            dataField: "amount",
            dataType: "number",
            summaryType: "sum",
            area: "data",
            format: {
              type: 'fixedPoint',
              precision: 2
            },
            allowCrossGroupCalculation: true,
            /*customizeText: function (cellInfo) {
              return Number(
                  parseFloat(cellInfo.value / 1000000).toFixed(2)
              ).toLocaleString("en", {
                minimumFractionDigits: 2,
              })
            },*/
          },
          {
            caption: "ТТЗ санал",
            dataField: "ttz_amount",
            dataType: "number",
            summaryType: "sum",
            area: "data",
            format: {
              type: 'fixedPoint',
              precision: 2
            },
            allowCrossGroupCalculation: true,
            /*customizeText: function (cellInfo) {
              return Number(
                  parseFloat(cellInfo.value / 1000000).toFixed(2)
              ).toLocaleString("en", {
                minimumFractionDigits: 2,
              })
            },*/
          },
          {
            caption: "ТЕЗ санал",
            dataField: "tez_amount",
            dataType: "number",
            summaryType: "sum",
            area: "data",
            format: {
              type: 'fixedPoint',
              precision: 2
            },
            allowCrossGroupCalculation: true,
            /*customizeText: function (cellInfo) {
              return Number(
                  parseFloat(cellInfo.value / 1000000).toFixed(2)
              ).toLocaleString("en", {
                minimumFractionDigits: 2,
              })
            },*/
          },
          {
            caption: "Нэмэх төсөв",
            dataField: "add_budget_amt",
            dataType: "number",
            summaryType: "sum",
            area: "data",
            format: {
              type: 'fixedPoint',
              precision: 2
            },
            allowCrossGroupCalculation: true,
          },
          {
            caption: `${year} оны хязгаар`,
            dataField: "hyazgaar_amount",
            dataType: "number",
            summaryType: "sum",
            area: "data",
            visible: false,
            format: {
              type: 'fixedPoint',
              precision: 2
            },
            allowCrossGroupCalculation: true,
            /*customizeText: function (cellInfo) {
              return Number(
                  parseFloat(cellInfo.value / 1000000).toFixed(2)
              ).toLocaleString("en", {
                minimumFractionDigits: 2,
              })
            },*/
          },
          {
            caption: `${year + 1} оны төсөөлөл`,
            dataField: "tosoolol_1_amt",
            dataType: "number",
            summaryType: "sum",
            area: "data",
            format: {
              type: 'fixedPoint',
              precision: 2
            },
            allowCrossGroupCalculation: true,
            /*customizeText: function (cellInfo) {
              return Number(
                  parseFloat(cellInfo.value / 1000000).toFixed(2)
              ).toLocaleString("en", {
                minimumFractionDigits: 2,
              })
            },*/
          },
          {
            caption: `${year + 2} оны төсөөлөл`,
            dataField: "tosoolol_2_amt",
            dataType: "number",
            summaryType: "sum",
            visible: false,
            area: "data",
            format: {
              type: 'fixedPoint',
              precision: 2
            },
            allowCrossGroupCalculation: true,
            /*customizeText: function (cellInfo) {
              return Number(
                  parseFloat(cellInfo.value / 1000000).toFixed(2)
              ).toLocaleString("en", {
                minimumFractionDigits: 2,
              })
            },*/
          },
          // {
          //   caption: 'ТТЗ дүн',
          //   dataField: 'ttz_amount',
          //   dataType: 'number',
          //   visible: false,
          //   summaryType: 'sum',
          //   format: '#,##0.00',
          //   area: 'data'
          // },
        ],
        store: anotherList.data,
      })
    }
    //console.log('dataSource', anotherList.data);
    setDataSource(ds)
    setLoader(false)
    /*if (ds._data) {
      setDsData(ds._data.rows)
    }*/
  }

  /*const customColor = (pointInfo) => {
    if (pointInfo.tag === 7) {
      return { color: "#2ab71b" }
    } else if (pointInfo.tag === 6) {
      return { color: "#2ab71b" }
    } else if (pointInfo.tag === 5) {
      return { color: "#f96f6f" }
    } else if (pointInfo.tag === 4) {
      return { color: "#f96f6f" }
    } else if (pointInfo.tag === 3) {
      return { color: "#FBD6AA" }
    } else if (pointInfo.tag === 2) {
      return { color: "#FBD6AA" }
    } else if (pointInfo.tag === 1) {
      return { color: "#98DDCA" }
    }
  }

  const customizeTooltip = (args) => {
    const valueText = args.originalValue

    return {
      html: `${args.argumentText} - ${Math.floor(args.percent * 10000) / 100}%`,
    }
  }*/

  /*const handleListSelectionChange = async (e) => {
    const item = e.target.outerText
    switch (item) {
      case "Ерөнхий мэдээлэл":
        setChecker(1)
        break
      case "Дэлгэрэнгүй":
        setChecker(2)
        break
      case "Эдийн засгийн ангилал":
        setChecker(3)
        break
      default:
        throw new Error("aldaa zaalaaa meedkue")
    }
  }*/

  const onValueChangedVar = (e) => {
    try {
      // console.log('datafetch', dataFetch)
      e.value.length ===0 ? setSelectedStatus('1,2,3,4,5,6,7,8,9') : setSelectedStatus(e.value);
      //let result = _.groupBy(useData[e.value] || [], "econ_category_code_1")
      //setBudgetCalculation(result)
      /*let res = _.filter(dataFetch, (item) => {
        return item.budget_project_status_id === e.value
      })*/
      // res = [{ org_id: null, org_name: "Бүгд" }].concat(res);
      // setOrgChange(res)
      //setOrgChangeBoolean(true);
      loadData(e.value);
      selectedIndex ===1 && chartData(selectedOrgData, e.value, chosenViewList, chosenViewFormatList, chosenViewEcon);
    } catch (e) {
      console.error(e)
    }
  }

  /*const handleClick = () => {
    setIcon(icon === "hidepanel" ? "showpanel" : "hidepanel")
    setWidth(!width)
  }*/

  /*const toolbarItems = [
    {
      widget: "dxButton",
      location: "before",
      options: {
        icon: icon,
        onClick: handleClick,
      },
    },
  ]*/
  const organizationSelection = async (e) => {
    setSelectedOrgData(e.value)
    selectedIndex ===1 && await chartData(e.value, selectedStatus, chosenViewList, chosenViewFormatList, chosenViewEcon)
  }

  /* const customPercent = (args) => {
     return `${args.valueText} - <b>${Math.floor(args.percent * 10000) / 100
       }%</b>`
   }
   const colorPalettes = ["#60A69F", "#CFBA63", "#BC897A", "#78B6D9"]*/
  return (
    <div className="proposal-home ministry" id="dataGrid" style={{ padding: 10 }}>
      <LoadPanelComponent position={{ of: "dataGrid" }} visible={loader} />
      <div className="row" style={{ display: "flex", gap: '1rem', flexWrap: 'wrap' }}>
        <div>
          <SelectBox
            items={getYears(true)}
            label='Төсвийн жил'
            width='8rem'
            defaultValue={year}
            onValueChanged={(e) => setYear(e.value)}
            placeholder="сонгох"
          />
        </div>
        <div>
          <Lookup
            id="baiguullagadrop"
            onValueChanged={organizationSelection}
            dataSource={orgChange}
            displayExpr="org_name"
            showClearButton={false}
            clearButtonText="Цуцлах"
            label='Байгууллага'
            width="25rem"
            placeholder="Сонгох"
            searchPlaceholder="Хайх"
            cancelButtonText={"Хаах"}
            noDataText="Харгалзах төлөвт байгууллага олдсонгүй"
          >
            <DropDownOptions
              width='22rem'
              hideOnOutsideClick={true}
              showTitle={false}
            />
          </Lookup>
        </div>
        {/*<div style={{ display: "flex", alignItems: "center", marginLeft: 30 }}>
            {selectedType && dropDown.length > 0 && <span>Төлөв:</span>}
          </div>*/}
        <div>
          {selectedType && dropDown.length > 0 && (
            <TagBox
              label="Төлөв"
              width="15rem"
              height={36}
              multiline={false}
              items={dropDown}
              showSelectionControls={true}
              applyValueMode="useButtons"
              displayExpr='budget_project_status_name'
              valueExpr='budget_project_status_id'
              onValueChanged={onValueChangedVar}
              placeholder="Төлөв сонгох"
              selectAllText="Бүгдийг сонгох"
            />
          )}
        </div>
        <div style={{ flex: 1, display: "flex", gap: ".5rem", justifyContent: "flex-end", alignItems: "center" }}>
          <ProjectButton
            className="projectButton"
            id="devPlan"
            onClick={() => setFilePopupModal2(true)}
          >
            <BsFileEarmarkText
              style={{ fontSize: "1.2rem", color: "#1a1976" }}
            />
          </ProjectButton>
          <ProjectButton
            onClick={() =>
              window.open("https://legalinfo.mn/mn/detail/12254")
            }
            id="budgetLaw"
            className="projectButton"
          >
            <FaBalanceScale style={{ fontSize: "1.2rem", color: "#1a1976" }} />
          </ProjectButton>
          <ProjectButton
            onClick={() =>
              window.open("https://legalinfo.mn/mn/detail?lawId=17089709745751")
            }
            id="budgetLawSalary"
            // hint="ТӨРИЙН АЛБАНЫ ЗАРИМ АЛБАН ТУШААЛЫН ЦАЛИНГИЙН ХЭМЖЭЭ, ДООД ХЭМЖЭЭГ ШИНЭЧЛЭН ТОГТООХ ТУХАЙ"
            className="projectButton"
          >
            <FaBook style={{ fontSize: "1.2rem", color: "#1a1976" }} />
          </ProjectButton>
          <ProposalDate id="proposalDate">
            {proposalDeadline[user.organization.parent_obt_id === 1 ? 2 : user.organization.obt_id]}
          </ProposalDate>
        </div>
        <Tooltip
          target={"#budgetLaw"}
          showEvent="mouseenter"
          hideEvent="mouseleave"
          hideOnOutsideClick={false}
          position="top"
        >
          <div style={{ padding: "1em" }}>Төсвийн тухай хууль</div>
        </Tooltip>
        <Tooltip
          target={"#budgetLawSalary"}
          showEvent="mouseenter"
          hideEvent="mouseleave"
          hideOnOutsideClick={false}
          position="top"
        >
          <div style={{ padding: "1em" }}>ТӨРИЙН АЛБАНЫ ЗАРИМ АЛБАН ТУШААЛЫН ЦАЛИНГИЙН ХЭМЖЭЭ, ДООД ХЭМЖЭЭГ ШИНЭЧЛЭН ТОГТООХ ТУХАЙ</div>
        </Tooltip>
        <Tooltip
          target={"#devPlan"}
          showEvent="mouseenter"
          hideEvent="mouseleave"
          hideOnOutsideClick={false}
          position="top"
        >
          <div style={{ padding: "1em" }}>
            Монгол улсын хөгжлийн 2024 оны төлөвлөгөө
          </div>
        </Tooltip>
        <Tooltip
          target={"#proposalDate"}
          showEvent="mouseenter"
          hideEvent="mouseleave"
          hideOnOutsideClick={false}
          position="top"
        >
          <div style={{ padding: "1em" }}>
            {deadlineTooltip[user.organization.obt_id]}
          </div>
        </Tooltip>
        {filePopupModal2 && (
          <Popup
            width={"80%"}
            height={"95vh"}
            visible={filePopupModal2}
            onHiding={() => {
              setFilePopupModal2(false)
            }}
            dragEnabled={false}
            hideOnOutsideClick={true}
          >
            <object
              width="100%"
              height="100%"
              data={`https://cdn.greensoft.mn/uploads/users/3963/files/%D0%A5%D3%A9%D0%B3%D0%B6%D0%BB%D0%B8%D0%B9%D0%BD%20%D1%82%D3%A9%D0%BB%D3%A9%D0%B2%D0%BB%D3%A9%D0%B3%D3%A9%D3%A9%202024.pdf`}
              type="application/pdf"
            />
          </Popup>
        )}
      </div>
      <div className="col-12">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            margin: '0.5rem 0',
            fontFamily: 'Segoe UI',justifyContent:'center'
          }}
        >
          <p style={{ color: "#000000" }}>
            {year} оны төслийн нэгтгэл -
            <span
              className="proposal-org-title"
              style={{ color: "#003696", textTransform: "uppercase", fontWeight: '700' }}
            >
              {" "}
              {title}
            </span>
          </p>
        </div>
      </div>
      <div className="d-flex" style={{ gap: '20' }}>
        {/*{width && (
          <div className="col-4">
            <ProposalSlideBudget
              year={year}
              setWidth={setWidth}
              selectedData={selectedOrgData}
              //allOrgData={allOrgData}
              selectedStatus={selectedStatus === null ? selectedType?.budget_project_status_id : selectedStatus}
            />
          </div>
        )}*/}
        <div className={` ${width ? "col-8" : "col-12"}`} style={{ borderRadius: '1rem', marginTop: '-0.25rem' }} >
          <TabPanel
            onTitleClick={(e) => { setSelectedIndex(e.itemIndex); e.itemIndex === 1 && chartData(selectedOrgData, selectedStatus, chosenViewList, chosenViewFormatList, chosenViewEcon) }} >
            <Item title="Төсвийн төсөл" icon="find"
              tabRender={(e) => {
                return (<div style={{ display: 'flex', gap: 5, alignItems: 'center' }} id="joyride_step">
                  <BiSearch /><span>Харьяа газруудын саналын жагсаалт</span>
                </div>)
              }}>
              {selectedIndex ===0 && <ProposalMinistryMore
                year={year}
                wordWrapFixer={wordWrapFixer}
                selectedData={selectedOrgData}
                status={selectedStatus} selectedOrg={selectedOrgData && selectedOrgData.org_id!==undefined ? selectedOrgData.org_id : 'null'}
              />}
            </Item>
            <Item
              style={{ borderRadius: "1rem" }}
              icon="rowfield"
              tabRender={(e) => {
                return (
                  <div style={{ display: 'flex', gap: 5, alignItems: 'center' }} id="stepStart">
                    <AiOutlineProfile /><span>Саналын нэгтгэл тайлан</span>
                  </div>)
              }}
            >
              <Joyride
                run={true}
                steps={tusliinNegtgelSteps}
                continuous
                showSkipButton
                styles={{
                  options: {
                    zIndex: 100,
                  }
                }}
                locale={guideLocale}
              />
              <div style={{ flexDirection: 'row', justifyContent: 'flex-start', display: 'flex', padding: 10 }}>
                <div id="projectsMinistry_harahHelber">
                  <SelectBox
                    items={mergeViewList}
                    valueExpr={'value'}
                    displayExpr={'name'}
                    label={'Харах хэлбэр'}
                    className="hzText-bold"
                    style={{ borderRadius: '.5rem' }}
                    defaultValue={chosenViewList}
                    width={300}
                    onValueChanged={(e) => {
                      setChosenViewList(e.value);
                      e.value === 'second' ? chartData(selectedOrgData, selectedStatus, e.value, chosenViewFormatList, 'all') : chartData(selectedOrgData, selectedStatus, 'default', chosenViewFormatList, chosenViewEcon);
                    }}
                    placeholder="сонгох" />
                </div>
                <div style={{ marginLeft: 5 }}>
                  <SelectBox
                    items={viewFormatList}
                    valueExpr={'value'}
                    displayExpr={'name'}
                    className="hzText-bold"
                    label={'Дүнгийн формат'}
                    style={{ borderRadius: '.5rem' }}
                    defaultValue={chosenViewFormatList}
                    width={300}
                    onValueChanged={(e) => {
                      setChosenViewFormatList(e.value);
                      e.value === 'second' ? chartData(selectedOrgData, selectedStatus, chosenViewList, e.value, 'all') : chartData(selectedOrgData, selectedStatus, chosenViewList, e.value);
                    }}
                    placeholder="сонгох" />
                </div>
                {
                  chosenViewList === 'default' &&
                  <div style={{ marginLeft: 5 }}>
                    <SelectBox
                      items={viewEconStart}
                      valueExpr={'value'}
                      displayExpr={'name'}
                      className="hzText-bold"
                      label={'ЭЗА'}
                      style={{ borderRadius: '.5rem' }}
                      defaultValue={chosenViewEcon}
                      width={'18rem'}
                      onValueChanged={(e) => {
                        setChosenViewEcon(e.value);
                        chartData(selectedOrgData, selectedStatus, chosenViewList, chosenViewFormatList, e.value);
                      }}
                      placeholder="сонгох" />
                  </div>
                }
              </div>
              {selectedIndex === 1 && <MinistryGrid dataSource={dataSource} year={year} selectedReport={chosenViewList} selectedOrg={selectedOrgData} />}
            </Item>
            <Item
                style={{ borderRadius: "1rem" }}
                icon="rowfield"
                tabRender={(e) => {
                  return (
                      <div style={{ display: 'flex', gap: 5, alignItems: 'center' }} id="fiscalReport">
                        <AiOutlineProfile /><span>Нэгтэл тайлан-фискал формат</span>
                      </div>)
                }}
            >
              { selectedIndex === 2 && <ProposalMergeReport year={year} status={selectedStatus} selectedOrg={selectedOrgData.org_id}/>}
            </Item>
            {/*<Item title="Тайлан - Эдийн засгийн ангиллаар" icon="hierarchy">
              <ProjectUnionEconCategory
                econArr={economicCategoryArray}
                year={year}
                selectedData={selectedOrgData}
              />
            </Item>*/}
          </TabPanel>
          {/*<div className="container" style={{ marginBottom: 20 }}>*/}
          {/*  {tabItems.map((item, index) => {*/}
          {/*    return (*/}
          {/*      <div key={index} className="custom-stuff" style={{ backgroundColor: index + 1 === checker ? '#2C93D8' : 'white', color: index + 1 === checker ? 'white' : '#000'}} onClick={handleListSelectionChange}>*/}
          {/*        <span>{item}</span>*/}
          {/*      </div>*/}
          {/*    );*/}
          {/*  })}*/}
          {/*</div>*/}

          {/*{checker === 2 &&*/}
          {/*  <MinistryGrid year={year} dataSource={dataSource}/>*/}
          {/*}*/}
          {/*{checker === 1 &&*/}
          {/*  <ProposalMinistryMore year={year} wordWrapFixer={wordWrapFixer} selectedData={selectedOrgData}/>*/}
          {/*}*/}
          {/*{checker === 3 && (*/}
          {/*  <ProjectUnionEconCategory econArr={economicCategoryArray} year={year} />*/}
          {/*)}*/}
        </div>
      </div>
    </div>
  )
}


import React, { useState, useRef, useEffect } from "react";
import Button from "devextreme-react/button";
import { makeStyles } from "@material-ui/core/styles";

import {GetTran} from "../../tran/Db"
import styles from "../../assets/jss/material-dashboard-pro-react/views/loginPageStyle";
import DataGrid, {Column, Export, Selection, Summary, TotalItem} from "devextreme-react/data-grid";
import {Workbook} from "exceljs";
import {exportDataGrid} from "devextreme/excel_exporter";
import {saveAs} from "file-saver-es";


const useStyles = makeStyles(styles);
const BBSummary= (props) => {
    const glYearMN = props.bbSetup;
   
   

  const classes = useStyles();
  const [beginBal, setBeginBal] = useState([]);
  const [dtCr, setDtCr] = useState([]);
  const [details, setDetails] = useState([]);
  const [structure, setStructure] = useState([]);
  const [tranDescrDetails, setTranDescrDetails] = useState([]);
  const customizeDate = (data) => {
    return Number(parseFloat(data.value).toFixed(2)).toLocaleString("en", {
      minimumFractionDigits: 2,
    })


  }
  const onExporting = (e) => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Main sheet');

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'DataGrid.xlsx');
      });
    });
    e.cancel = true;
  }

  const [report, setReport] = useState([]);
  const calReport = () => {
    let array = [];

    let index = -1;

     array=beginBal

    array.map((el, index3) => {
      const val = el.bd + el.dt - el.cr;
      el.ed = 0;
      
       (el.ed = val) ;
      array[index3] = el;
    });



    setReport(array);
  };

  // ******** Татах

  
  const getGlUS = () => {
    setBeginBal([])
    if(glYearMN.year<2021 || glYearMN.month<1) return

    let p1 =
      "2&year=" +
      glYearMN.year +
      "&month=" +
      glYearMN.month    ;
   
    GetTran(p1, 0, "bbreport?")
      .then((result) => {
        //  setLoading(false);
        setBeginBal(result.data.data);
      })
      .catch((error) => {
        //   setLoading(false);

        setBeginBal([]);
        console.log(error);
      });
  };

  const getDetails = () => {
    if (acct.trim().length < 1) return;
    const p1 =
      "1&p2=" +
      glYearMN.year +
      "&p3=" +
      glYearMN.month +
      "&p4=" +
      glYearMN.month2 +
      "&p5=" +
      acct +
      "&p6=*";

    GetTran(p1, 0, "chart/report?")
      .then((result) => {
        //  setLoading(false);
        setDetails(result.data.data);
        setTranDescrDetails(result.data.set.descr);
        setStructure(result.data.set.structure);
        // console.log(result.data);
      })
      .catch((error) => {
        //   setLoading(false);

        setDetails([]);
        console.log(error);
      });
  };


  const [acct, setAcct] = useState("");
  const [acctList, setAcctList] = useState([]);
  const getAcct = () => {
    // setLoading(true);

    GetTran(11, 0, "gl?")
      .then((result) => {
        //  setLoading(false);
        setAcctList(result.data.data);
      })
      .catch((error) => {
        //   setLoading(false);
        setAcctList([]);

        console.log(error);
      });
  };

  const myRef = useRef(null);

  const executeScroll = () => myRef.current.scrollIntoView();
  // run this function from an event handler or an effect to execute scroll

  const acctClick = (index) => {
    setAcct(report[index].acct);
    executeScroll();
  };

  return (
    <div>
      
 {useEffect(() => calReport(), [beginBal, dtCr])}

      {useEffect(() => getGlUS(), [glYearMN.year,glYearMN.month])}


      {useEffect(() => getAcct(), [])}

      <div ref={myRef}></div>

      <Button text = "Хураангуй" onClick={() => getGlUS()}  width = {150} type = "default" style = {{alignment : "center", borderRadius: '7px'}}/>




      <div className={'card-body'}>

        <div className="col-12" style={{ marginTop: 20 }} >
          <DataGrid
              dataSource={report}
              showBorders={true}
              allowColumnResizing={true}

              hoverStateEnabled={true}
              keyExpr="acct"
              noDataText = 'Дата байхгүй байна.'
              onExporting={onExporting}>
            <Selection mode="single" />
            <Column caption="Kод" dataField="bbclass" dataType="string" />
            <Column caption="Данс" dataField="acct" dataType="string" />
            <Column caption="Нэр" dataField="descr" dataType="string" />


            <Column caption="Эхний үлдэгдэл" dataField="bd" />

            <Column caption="Орлого" dataField="dt" dataType="number" />
            <Column caption="Зарлага" dataField="cr" dataType="number" />

            <Column caption="Эцсийн үлдэгдэл" dataField="ed" dataType="number" />

            <Summary>
              <TotalItem
                  column="bd"
                  summaryType="sum"
                  customizeText={customizeDate}/>

              <TotalItem
                  column="dt"
                  summaryType="sum"
                  customizeText={customizeDate} />
              <TotalItem
                  column="cr"
                  summaryType="sum"
                  customizeText={customizeDate} />
              <TotalItem
                  column="ed"
                  summaryType="sum"
                  customizeText={customizeDate} />

            </Summary>
            <Export enabled={true} allowExportSelectedData={true} />
          </DataGrid>
        </div>
      </div>



    </div>
  );
};

export default BBSummary;

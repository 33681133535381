import React, {Component} from "react";
import Button from "devextreme-react/button";
import Popup from 'devextreme-react/popup';
import {Template} from 'devextreme-react/core/template';
import {
  DataGrid, Editing, Column,
  Popup as FormPopup, Form,
  Button as FormButton,
  Lookup
} from 'devextreme-react/data-grid';
import {Item} from 'devextreme-react/form';
import OrganizationService from "../../../services/api/organization";
import OrgFormBuilder from "./orgFormBuilder";
import {LoadPanel} from "devextreme-react";
import ScrollView from 'devextreme-react/scroll-view';
import notify from "devextreme/ui/notify";
import PositionSection from "../../home/components/userPosition"

import {orgWorkerFormTypes} from "../static/data";
import LoadPanelComponent from "../../budget/components/loadPanel";

export default class OrgForms extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showAddDialog: false,
      editData: {},
      isEdit: false,
      loader: false,
    };

    this.onClickShow = this.onClickShow.bind(this);
    this.createNewForm = this.createNewForm.bind(this);
    this.addButtonRender = this.addButtonRender.bind(this);
    this.onDialogHidden = this.onDialogHidden.bind(this);
    this.onToolbarPreparing = this.onToolbarPreparing.bind(this);
    this.onSaved = this.onSaved.bind(this);
  }

  componentDidMount() {
    // this.initVars();
    this.getData();
  }

  // initVars() {
  // }
  onToolbarPreparing(e) {
    e.toolbarOptions.items.push({
      location: 'after',
      template: 'addButton'
    });
  };

  async getData() {
    this.setState({loader: true});
    const res = await OrganizationService.orgBuilderList();
    // console.log(res);
    this.setState({
      dataSource: res.data,
      loader: false,
    })
  }

  onClickShow(e) {
    this.setState({
      showAddDialog: true,
      editData: e.row.data,
      isEdit: true,
    });
  }

  onDialogHidden() {
    this.setState({
      showAddDialog: false
    });
    this.getData();
  }

  async onSaved(e) {
    if (!e.changes || e.changes.length === 0) return;
    e.changes.forEach(data => {
      if (data.type === 'insert') {
      } else if (data.type === 'update') {
        this.handleUpdate(data.data);
      } else if (data.type === 'remove') {
        this.handleDelete(data.key) // make sure it is 'data.key' when delete
      }
    });

  }
  async handleUpdate(data) {
    // console.log('update', data);
    // return;
    try {
      const res = await OrganizationService.orgFormUpdate(data);
      if (res.success) {
        notify(`${res.message}`, 'success',);
      } else {
        notify(`${res.message}`, 'error');
      }
    } catch (e) {
      console.log(e);
      notify(`Алдаа гарлаа`, 'error');
    }
  }
  async handleDelete(data) {
    try {
      const res = await OrganizationService.orgBuilderDelete(data.id);
      if (res.success) {
        notify(`${res.message}`, 'success',);
      } else {
        notify(`${res.message}`, 'error');
      }
    } catch (e) {
      console.log(e);
      notify(`Алдаа гарлаа`, 'error');
    }
  }

  addButtonRender() {
    return <Button
      onClick={this.createNewForm}
      icon="add"
      text="Шинэ форм үүсгэх">
    </Button>;
  }

  createNewForm() {
    this.setState({
      showAddDialog: true,
      editData: null,
      isEdit: false,
    });
  }

  render() {
    return (
      <div className=''>
        <div className='card'>
          <LoadPanelComponent position={{of: '#formList'}} visible={this.state.loader} />
          <div className="card-header">
            <span>Форм</span>
          </div>
          <div className="card-body">
            <div className="row-fixer form-builder">
              {/*<div className="row mb-3">*/}
              {/*  <div className="col-5">*/}
              {/*    <TextBox value={this.state.formName} onValueChanged={(e) => this.onValueChanged(e, 'formName')} placeholder='Форм нэр оруулна уу'/>*/}
              {/*  </div>*/}
              {/*  <div className="col-7">*/}
              {/*    <Button*/}
              {/*      icon='plus'*/}
              {/*      text="Хадгалах"*/}
              {/*      type="default"*/}
              {/*      onClick={this.onSaveForm}*/}
              {/*    />*/}
              {/*  </div>*/}
              {/*</div>*/}

              <div className="row">
                <div className="col-12">
                  <DataGrid
                    id="formList"
                    dataSource={this.state.dataSource}
                    rowAlternationEnabled={true}
                    showBorders={false}
                    onToolbarPreparing={this.onToolbarPreparing}
                    onSaved={this.onSaved}
                  >
                    <Column dataField="name" caption="Нэр"
                      // groupCellComponent={GroupCell}
                    />
                    <Column dataField="org_w_form_type" caption="Төрөл">
                      <Lookup
                        dataSource={orgWorkerFormTypes}
                        valueExpr="id"
                        displayExpr="name"
                      />
                    </Column>
                    {/*<Column dataField="org_id" caption="Байгууллага"*/}
                    {/*  // groupCellComponent={GroupCell}*/}
                    {/*/>*/}
                    <Column dataField="order" caption="Эрэмбэ"
                      // groupCellComponent={GroupCell}
                    />
                    <Editing
                      mode="popup"
                      allowUpdating={true}
                      allowAdding={false}
                      confirmDelete={true}
                      useIcons={true}
                      allowDeleting={true}
                      texts={{
                        addRow: 'Төсөл нэмэх',
                        cancelAllChanges: 'Болих бүгд',
                        cancelRowChanges: 'Болих',
                        confirmDeleteMessage: 'Энэ мэдээллийг устгах уу?',
                        confirmDeleteTitle: 'Баталгаажуулах',
                        deleteRow: 'Устгах',
                        editRow: 'Өөрчлөх',
                        saveAllChanges: 'Хадгалах бүгд',
                        saveRowChanges: 'Хадгалах',
                        undeleteRow: 'Буцаах',
                        validationCancelChanges: 'Баталгаажуулах болих',
                      }}>
                      <FormPopup title="Формын ерөнхий мэдээлэл оруулах" showTitle={true} width={700} height={525}/>
                      <Form>
                        <Item itemType="group" colCount={2} colSpan={2}>
                          <Item dataField="name" colSpan={1}/>
                          <Item dataField="order" colSpan={1}/>
                          <Item
                            dataField="org_w_form_type"
                            // editorType="dxTextArea"
                            colSpan={2}
                            // editorOptions={{height: 100}}
                          />
                        </Item>

                        {/*<Item itemType="group" caption="Home Address" colCount={2} colSpan={2}>*/}
                        {/*  <Item dataField="StateID"/>*/}
                        {/*  <Item dataField="Address"/>*/}
                        {/*</Item>*/}
                      </Form>
                    </Editing>
                    <Column type="buttons" width={160} fixed={true} fixedPosition="right">
                      <FormButton name="edit"/>
                      <FormButton name="delete"/>
                      <FormButton hint="Дэлгэрэнгүй" icon="repeat" visible={true} onClick={this.onClickShow}/>
                    </Column>
                    <Template name="addButton" render={this.addButtonRender}/>
                  </DataGrid>
                </div>
              </div>
            </div>
          </div>
          <Popup
            minWidth={'80%'}
            minHeight={'80%'}
            visible={this.state.showAddDialog}
            title={`Форм`}
            onHiding={() => this.onDialogHidden()}
            resizeEnabled={true}
            dragEnabled={true}
            showTitle={true}
            hideOnOutsideClick={true}
            >
            <ScrollView width='100%' height='100%'>
              <div>
                {this.state.showAddDialog && <OrgFormBuilder isEdit={this.state.isEdit} data={this.state.editData}/>}
              </div>
            </ScrollView>
          </Popup>
        </div>
      </div>
    )
  }
}

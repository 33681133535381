import request from '../../shared/lib/request'

const getPortalPages = (type, org_id) => {
    return request({
        url: `/portal/getPortalPages/${type}/${org_id}`,
        method: 'GET',
    })
}
const getPortalMenu = (orgId) => {
    return request({
        url: `/portal/getPortalMenu/${orgId}`,
        method: 'GET',
    })
}
const getActivePortalMenu = (orgId) => {
    return request({
        url: `/portal/getActivePortalMenu/${orgId}`,
        method: 'GET',
    })
}
const getInactivePortalMenu = (orgId) => {
    return request({
        url: `/portal/getInactivePortalMenu/${orgId}`,
        method: 'GET',
    })
}
const getPortalCategories = (type) => {
    return request({
        url: `/portal/getPortalCategories/${type}`,
        method: 'GET',
    })
}
const getPortalFileNames = (id) => {
    return request({
        url: `/portal/getPortalFileNames/${id}`,
        method: 'GET',
    })
}
const getPortalFileStructureNames = (orgId, type) => {
    return request({
        url: `/portal/getPortalFileStructureNames/${orgId}/${type}`,
        method: 'GET',
    })
}
const addPortalCategory= (type, title)=>{
    return request({
        url: `/portal/addPortalCategory/${type}`,
        method: "POST",
        data: title
    })
}
const addPortalPage= (data)=>{
    return request({
        url: `/portal/addPortalPage`,
        method: "POST",
        data,
    })
}
const changeFileStructureOrder = (orgId, type, data)=>{
    return request({
        url: `/portal/changeFileStructureOrder/${orgId}/${type}`,
        method: "POST",
        data
     })
}
const addActiveMenu= (orgId, menuId)=>{
    return request({
        url: `/portal/addActiveMenu/${orgId}/${menuId}`,
        method: "POST",
    })
}
const deleteActiveMenu= (orgId, menuId, status)=>{
    return request({
        url: `/portal/deleteActiveMenu/${orgId}/${menuId}/${status}`,
        method: "POST",
    })
}
const addPortalMenu= (data)=>{
    return request({
        url: `/portal/addPortalMenu`,
        method: "POST",
        data,
    })
}
const editPortalPage = (data, id)=>{
    return request({
        url: `/portal/editPortalPage/${id}`,
        method: "POST",
        data,
    })
}
const deletePortalPage = (id)=>{
    return request({
        url: `/portal/deletePortalPage/${id}`,
        method: "POST",
    })
}
const getPortalMenuByPath = (url) => {
    return request({
        url: `/portal/getPortalMenuByPath/${url}`,
        method: 'GET',
    })
}

const portalServices = {
    getPortalPages,
    addPortalCategory,
    getPortalCategories,
    getPortalMenu,
    addPortalPage,
    editPortalPage,
    deletePortalPage,
    addPortalMenu,
    getActivePortalMenu,
    getInactivePortalMenu,
    addActiveMenu,
    deleteActiveMenu,
    getPortalMenuByPath,
    getPortalFileNames,
    getPortalFileStructureNames,
    changeFileStructureOrder
}

export default portalServices;

import { DataGrid, SelectBox } from 'devextreme-react';
import _ from 'lodash'
import React, { useState, useEffect, useContext } from 'react'
import BudgetService from '../../services/api/budget';
import notify from 'devextreme/ui/notify';
import LoadPanelComponent from '../../components/LoadPanelComponent';
import { Column, Export } from 'devextreme-react/data-grid';
import { UserContext } from '../../hooks/UserContext';
import moment from 'moment';
import { Workbook } from 'exceljs';
import { exportDataGrid } from "devextreme/excel_exporter";
import { saveAs } from "file-saver-es";
import { blueColorGradient, formatDate } from '../../util';
import { budgetStatementColorGradient } from '../../util';
import { numberWithCommas } from '../../util';
import Chart, {
    ArgumentAxis,
    CommonSeriesSettings,
    Legend,
    Series,
    Tooltip,
    ValueAxis,
    Label,
    Crosshair,
    Strip,
} from 'devextreme-react/chart';
import { FaBalanceScale, FaBalanceScaleLeft, FaBalanceScaleRight } from "react-icons/fa";
import { Button, Space } from 'antd';
import { Tooltip as ButtonTooltip } from 'devextreme-react';



function BudgetStatement() {
    const [data, setData] = useState([])
    const [budgetStatementEza, setBudgetStatementEza] = useState([])
    const [budgetBalance, setBudgetBalance] = useState([])
    const [loading, setLoading] = useState(false)
    const [clickedRow, setClickedRow] = useState(3)
    const [yearList, setYearList] = useState([])
    const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
    const { user } = useContext(UserContext)
    const [link, setLink] = useState('')

    const getData = async (year) => {
        try {
            setLoading(true)
            const result = await BudgetService.getBudgetFrameStatement(year)
            if (result.code === 200) {
                const formattedData = result.data.map(item => ({
                    ...item,
                    predict_amount1: parseFloat(item.predict_amount1) || 0,
                    predict_amount2: parseFloat(item.predict_amount2) || 0,
                    amount: parseFloat(item.amount) || 0, // Convert to number or default to 0 if not a valid number
                }));
                setData(formattedData)
                setLink(result.data[0].link)
                setLoading(false)
            }
        } catch (err) {
            setLoading(false)
            console.log(err);
        }
    }

    const getBudgetStatementEza = async (e) => {
        try {
            setLoading(true)
            const result = await BudgetService.getBudgetStatementEza(e)
            if (result.code === 200) {
                const formattedData = result.data.map((item, index) => {
                    const isHuviIncluded = item.econ_name.includes('хувь');
                    return {
                        ...item,
                        amount: isHuviIncluded ? undefined : parseFloat(item.amount) || 0,
                        prev_amount: isHuviIncluded ? undefined : parseFloat(item.prev_amount) || 0,
                        percent: isHuviIncluded ? parseFloat(item.amount) : undefined,
                        prev_percent: isHuviIncluded ? isNaN(parseFloat(item.prev_amount)) ? undefined : parseFloat(item.prev_amount) : undefined,
                    };
                });

                setBudgetStatementEza(formattedData);
                setLoading(false);
            }
        } catch (err) {
            console.log(err);
            setLoading(false)
        }
    }

    const getBudgetBalance = async () => {
        try {
            setLoading(true)
            const result = await BudgetService.getBudgetSurplusOrDeficit()
            const updatedData = result.data.map(item => ({
                ...item,
                amount: parseFloat(item.amount),
                income: parseFloat(item.income),
                expenses: parseFloat(item.expenses),
                balance: parseFloat(item.balance)
            }))
            setBudgetBalance(updatedData)
            setLoading(false)
        } catch (err) {
            setLoading(false)
            console.log(err);
        }
    }

    const loadYearList = async () => {
        try {
            setLoading(true)
            let result = await (await BudgetService.getBudgetFrameStatementYear());
            const formattedYear = result.filter(item => item.value >= 2012)
            setYearList(formattedYear)
            setLoading(false)
        } catch (error) {
            setLoading(false)
            notify(error.message, "error", 2000)
        }

    }

    useEffect(() => {
        loadYearList()
        getBudgetBalance()
        getBudgetStatementEza(3)
    }, [])

    useEffect(() => {
        getData(currentYear)
    }, [currentYear])

    const onCellPrepared = (e) => {
        if (e.row && e.row.data.row_num === clickedRow) {
            e.cellElement.style.border = '2px solid #333333'
            e.cellElement.style.fontWeight = 'bold'
        } else if (e.rowType === 'header') {
            e.cellElement.wordWrapEnabled = true;
            e.cellElement.style.fontFamily = "Segoe UI";
            e.cellElement.style.fontWeight = "bold";
            e.cellElement.style.color = "#15186A";
            e.cellElement.style.textAlign = "center";
            e.cellElement.style.backgroundColor = "#EEF0F4";
            e.cellElement.style.borderColor = "#D4DCEC";
            e.cellElement.style.fontSize = "12px";
            if (e.columnIndex === 1) {
                e.cellElement.style.textTransform = 'uppercase'
            }
        } else if (e.rowType === "data") {
            e.cellElement.style.fontFamily = 'Segoe UI'
            e.cellElement.style.fontWeight = 'normal'
            e.cellElement.style.color = "#000000"
            e.cellElement.style.alignment = "right"
        }
    }

    const onExporting = (e) => {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Main sheet');
        worksheet.columns = [
            { width: 10 }, { width: 33 }, { width: 4 }, { width: 70 }, { width: 18 }, { width: 18 }, { width: 18 }, { width: 18 }, { width: 18 }, { width: 18 }, { width: 18 }, { width: 18 }, { width: 18 }, { width: 18 }, { width: 18 }
        ];
        const mergedCells = new Set();
        exportDataGrid({
            component: e.component,
            worksheet,
            autoFilterEnabled: true,
            keepColumnWidths: false,
            topLeftCell: { row: 6, column: 3 },
            customizeCell: ({ gridCell, excelCell }) => {
                if (gridCell.rowType === 'header') {
                    excelCell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'd9d9d9' } };
                    excelCell.font = { color: { argb: '00000' }, name: 'Arial', size: 8, 'bold': true };

                }
                if (gridCell.rowType === 'data') {
                    excelCell.numFmt = '#,##0.00'
                    excelCell.font = { color: { argb: '00000' }, name: 'Arial', size: 8, 'bold': gridCell.data.econ_name.includes('хувь') || gridCell.data.econ_name.includes('түвшин') ? false : true };
                    if (gridCell.column.caption === '№') {
                        excelCell.numFmt = '#'
                        const currentRowNum = gridCell.data.row_num;

                        if (!mergedCells.has(currentRowNum)) {
                            const rowCount = e.component.getVisibleRows().filter(row => row.data.row_num === currentRowNum).length;
                            if (rowCount > 1) {
                                worksheet.mergeCells(excelCell.address, worksheet.getCell(excelCell.row + rowCount - 1, excelCell.col).address);
                                mergedCells.add(currentRowNum);
                            }
                        }
                        excelCell.value = currentRowNum;
                        worksheet.getColumn('C').alignment = { vertical: 'top', horizontal: 'center' }
                    }
                }
            },
        }).then(() => {

            Object.assign(worksheet.getRow(1).getCell(1), {
                value: 'Гарчиг:',
                font: { name: 'Arial', size: 8 },
            })
            Object.assign(worksheet.getRow(1).getCell(2), {
                value: `${currentYear} оны Төсвийн хүрээний мэдэгдэл`,
                font: { name: 'Arial', size: 8, 'bold': true, color: { argb: '0070c0' } },
            })
            Object.assign(worksheet.getRow(2).getCell(1), {
                value: 'Хэрэглэгч:',
                font: { name: 'Arial', size: 8 },
            })
            Object.assign(worksheet.getRow(2).getCell(2), {
                value: `${user.last_name.slice(0, 1)}. ${user.first_name}, ${user.position_name}`,
                font: { name: 'Arial', size: 8 },
            })
            Object.assign(worksheet.getRow(3).getCell(1), {
                value: 'Огноо:',
                font: { name: 'Arial', size: 8 },
            })
            Object.assign(worksheet.getRow(3).getCell(2), {
                value: new moment().locale('mn').format('lll'),
                font: { name: 'Arial', size: 8 },
            })
        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `OG-Монгол Улсын Нэгдсэн төсвийн ${currentYear} төсвийн хүрээний мэдэгдэл-${formatDate(new Date())}.xlsx`);
            });
        });
        e.cancel = true;
    }

    const handleLegend = (e) => {
        if (e.target.isVisible()) {
            e.target.hide();
        } else {
            e.target.show();
        }
    }
    const customizeTooltip = (arg) => {

        if (arg.seriesName === "ДНБ-д эзлэх хувь") {
            let q = (
                arg.value - arg.point.data.prev_percent > 0
                    ? arg.value / arg.point.data.prev_percent
                    : arg.point.data.prev_percent / arg.value
            ).toFixed(2)
            let b = q.replace('-', '')
            let d = arg.value - arg.point.data.prev_percent
            let f = d < 0 ? "бага" : "их"
            if (isFinite(q) && !isNaN(q)) {
                return {
                    html: `<div class="tooltip-header">
                <span>ДНБ-д эзлэх хувь ${arg.argumentText} он:</span>
                <span>${numberWithCommas(arg.value)} %</span>
                </div>
                <hr/>
                <b><i>Өмнөх жилээс</i></b><br/>
                <span class="tooltip-series-name">Зөрүү:</span> ${numberWithCommas(d)}<br/> 
                <span class="tooltip-series-name">Өөрчлөлт:</span> ${b} дахин ${f}`,
                }
            } else {
                return {
                    html: `<div class="tooltip-header">
              <span>ДНБ-д эзлэх хувь ${arg.argumentText} он:</span>
              <span>${numberWithCommas(arg.value)} </span>
              </div>`,
                }
            }
        } else {
            let r = _.find(budgetStatementEza, {
                statement_year: (arg.argument * 1 - 1),
            })
            let q = (
                arg.value - (r && r.amount) > 0
                    ? arg.value / (r && r.amount !== undefined ? r.amount : 1)
                    : (r && r.amount !== undefined ? r.amount : 1) / arg.value
            ).toFixed(2)
            let b = q.replace('-', '')
            let d = arg.value - (r && r.amount !== undefined ? r.amount : 1)
            let f = d < 0 ? "бага" : "их"
            return {
                html: `<div class="tooltip-header">
                <span>Төсвийн хүрээний мэдэгдэл ${arg.argumentText} он:</span>
                <span>${numberWithCommas(arg.value)}</span>
                </div>
                <hr/>
                <b><i>Өмнөх жилээс</i></b><br/>
                <span class="tooltip-series-name">Зөрүү:</span> ${numberWithCommas(d)}<br/> 
                <span class="tooltip-series-name">Өөрчлөлт:</span> ${b} дахин ${f}<br/>
                <span class="tooltip-series-name">Хувь:</span> ${q} дахин ${f}`
            }
        }

    }

    const customizeTooltipTotal = (arg) => {
        if (arg.seriesName === "Нийт орлого") {
            let name = "Нэгдсэн төсвийн тэнцвэржүүлсэн орлогын хэмжээ "
            let r = _.find(budgetBalance, {
                statement_year: (arg.argument * 1 - 1),
                econ_name: name
            })
            let q = (
                arg.value - (r && r.income) > 0
                    ? arg.value / (r && r.income)
                    : (r && r.income) / arg.value
            ).toFixed(2)
            let b = q.replace('-', '')
            let d = arg.value - (r && r.income)
            let p = ((arg.value * 100) / (r && (r.income)) - 100).toFixed(2)
            let f = d < 0 ? "бага" : "их"
            if (isFinite(q) && !isNaN(q)) {
                return {
                    html: `<div class="tooltip-header">
                <span>Нийт орлого ${arg.argumentText} он:</span>
                <span>${numberWithCommas(arg.value)}</span>
                </div>
                <hr/>
                <b><i>Өмнөх жилээс</i></b><br/>
                <span class="tooltip-series-name">Зөрүү:</span> ${numberWithCommas(d)}<br/> 
                <span class="tooltip-series-name">Өөрчлөлт:</span> ${b} дахин ${f}<br/>
                <span class="tooltip-series-name">Хувь:</span> ${p} %`
                }
            } else {
                return {
                    html: `<div class="tooltip-header">
                <span>Нийт орлого ${arg.argumentText} он:</span>
                <span>${numberWithCommas(arg.value)}</span>
                </div>
                <hr/>
                <b><i>Өмнөх жилээс</i></b><br/>
                <span class="tooltip-series-name">Зөрүү:</span> 0<br/> 
                <span class="tooltip-series-name">Өөрчлөлт:</span> 0<br/>
                <span class="tooltip-series-name">Хувь:</span> 0 %`
                }
            }
        } else if (arg.seriesName === "Нийт зарлага") {
            let name = "Нэгдсэн төсвийн нийт зарлагын дээд хэмжээ "
            let r = _.find(budgetBalance, {
                statement_year: (arg.argument * 1 - 1),
                econ_name: name
            })
            let q = (
                (arg.value * (-1)) - (r && (r.expenses * (-1))) > 0
                    ? (arg.value * (-1)) / (r && (r.expenses * (-1)))
                    : (r && (r.expenses * (-1))) / (arg.value * (-1))
            ).toFixed(2)
            let b = q.replace('-', '')
            let d = (arg.value * (-1)) - (r && r.expenses * (-1))
            let p = ((arg.value * (-100)) / (r && (r.expenses * (-1))) - 100).toFixed(2)
            let f = d < 0 ? "бага" : "их"
            if (isFinite(q) && !isNaN(q)) {

                return {
                    html: `<div class="tooltip-header">
                <span>Нийт зарлага ${arg.argumentText} он:</span>
                <span>${numberWithCommas(arg.value * (-1))}</span>
                </div>
                <hr/>
                <b><i>Өмнөх жилээс</i></b><br/>
                <span class="tooltip-series-name">Зөрүү:</span> ${numberWithCommas(d)}<br/> 
                <span class="tooltip-series-name">Өөрчлөлт:</span> ${b} дахин ${f}<br/>
                <span class="tooltip-series-name">Хувь:</span> ${p} %`
                }
            } else {
                return {
                    html: `<div class="tooltip-header">
                <span>Нийт зарлага ${arg.argumentText} он:</span>
                <span>${numberWithCommas(arg.value * (-1))}</span>
                </div>
                <hr/>
                <b><i>Өмнөх жилээс</i></b><br/>
                <span class="tooltip-series-name">Зөрүү:</span> 0<br/> 
                <span class="tooltip-series-name">Өөрчлөлт:</span> 0<br/>
                <span class="tooltip-series-name">Хувь:</span> 0 %`
                }
            }
        } else if (arg.seriesName === 'Тэнцвэржүүлсэн тэнцэл') {
            let name = "Нэгдсэн төсвийн тэнцвэржүүлсэн тэнцэл "
            let r = _.find(budgetBalance, {
                statement_year: (arg.argument * 1 - 1),
                econ_name: name
            })

            let q = (
                arg.value - (r && r.balance) > 0
                    ? arg.value / (r && r.balance)
                    : (r && r.balance) / arg.value
            ).toFixed(2)
            let b = q.replace('-', '')
            let d = arg.value - (r && r.balance)
            let p = ((arg.value * 100) / (r && r.balance) - 100).toFixed(2)
            let f = d > 0 ? "бага" : "их"
            if (isFinite(q) && !isNaN(q)) {

                return {
                    html: `<div class="tooltip-header">
                <span>Тэнцвэржүүлсэн тэнцэл ${arg.argumentText} он:</span>
                <span>${numberWithCommas(arg.value)}</span>
                </div>
                <hr/>
                <b><i>Өмнөх жилээс</i></b><br/>
                <span class="tooltip-series-name">Зөрүү:</span> ${numberWithCommas(d)}<br/> 
                <span class="tooltip-series-name">Өөрчлөлт:</span> ${b} дахин ${f}<br/>
                <span class="tooltip-series-name">Хувь:</span> ${p} %`
                }
            } else {
                return {
                    html: `<div class="tooltip-header">
                <span>Тэнцвэржүүлсэн тэнцэл ${arg.argumentText} он:</span>
                <span>${numberWithCommas(arg.value)}</span>
                </div>
                <hr/>
                <b><i>Өмнөх жилээс</i></b><br/>
                <span class="tooltip-series-name">Зөрүү:</span>0<br/> 
                <span class="tooltip-series-name">Өөрчлөлт:</span> 0<br/>
                <span class="tooltip-series-name">Хувь:</span> 0 %`
                }
            }
        }

    }
    function customizeColor(items) {
        if (items.seriesName === 'Нийт орлого') {
            return { color: '#3DAA75' }
        } else if (items.seriesName === 'Нийт зарлага') {
            return { color: '#F66160' }
        } else if (items.seriesName === 'Тэнцвэржүүлсэн тэнцэл') {
            return { color: '#ffba08' }
        } else if (items.seriesName === 'ДНБ-д эзлэх хувь') {
            return { color: '#f66160' }
        }
        else {
            return { color: budgetStatementColorGradient[items.index] }
        }
    }

    function handleRowClick(e) {
        getBudgetStatementEza(e.row_num)
        setClickedRow(e.row_num)
    }

    const customizeValueAxisLabel = (e) => {
        let value = e.value < 0 ? e.value * (-1) : e.value
        return `${value}`
    }

    function markerTemplate(item) {
        let color = '';
        if(item.text === 'Төсвийн хүрээний мэдэгдэл') {
            color = item.series.isVisible() ? '#1950c0' : '#888'
        } else {
            color = item.series.isVisible() ? item.marker.fill : '#888'
        }
        return (
            <svg>
                <rect x={0} y={0} width={12} height={12} style={{ fill: color }}
                    rx={item.text.includes('хувь') || item.text.includes('тэнцэл') ? 6 : 0}
                    ry={item.text.includes('хувь') || item.text.includes('тэнцэл') ? 6 : 0}></rect>
            </svg>
        );
    }
    let formattedTitle = ``
    const name = budgetStatementEza.some(item => item.econ_name.includes('хувь'))
    const name1 = budgetStatementEza.some(item => item.econ_name === 'Дотоодын нийт бүтээгдэхүүний бодит өсөлтийн хэмжээ /хувь/')
    if (name1) {
        formattedTitle = `${budgetStatementEza[0]?.econ_name}`
    } else if (name) {
        formattedTitle = `${budgetStatementEza[0]?.econ_name}, ДНБ-д эзлэх хувь /жилээр тэрбум ₮/`
    }
    else {
        formattedTitle = `${budgetStatementEza[0]?.econ_name}`
    }

    return (
        <div id='statement' style={{ padding: 10, fontFamily: 'Segoe UI' }}>
            <LoadPanelComponent position={{ of: '#statement' }} visible={loading} />
            <div>
                <SelectBox
                    label="Төсвийн жил"
                    items={yearList}
                    valueExpr="value"
                    displayExpr="value"
                    width='10rem'
                    defaultValue={currentYear}
                    onValueChanged={(e) => { setCurrentYear(e.value); getData(e.value); }}
                    placeholder="сонгох" style={{ borderRadius: 10 }} />
            </div>

            <div style={{ padding: 10, display: 'flex', flexDirection: 'column', gap: '10px', border: '1px solid #DEDEDE', borderRadius: '20px', boxShadow: '0px 0px 10px #DEDEDE', margin: '10px 0' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <span style={{ fontWeight: 'bold' }}>1. Монгол улсын нэгдсэн төсвийн <span style={{ color: 'red' }}>{data[0]?.statement_year}</span>  оны Төсвийн хүрээний мэдэгдэл <span style={{ color: '#003696' }}>{data[0]?.predict_year1}-{data[0]?.predict_year2}</span>  оны төсвийн төсөөллийн тухай /жилээр, тэрбум ₮/</span>
                    <Space>
                        <Button
                            id='law_link'
                            onClick={() => window.open(`${link}`, '_blank')}
                           icon={<FaBalanceScale style={{marginTop: 4}} color='#0814bd' />}
                        >

                        </Button>
                        <ButtonTooltip target="#law_link" showEvent="mouseenter" hideEvent="mouseleave" position={{ my: 'left', at: 'top left', collision: 'fit flip' }}
                            contentRender={() => (
                                <div className='subTitle'>МОНГОЛ УЛСЫН НЭГДСЭН ТӨСВИЙН {data[0]?.statement_year} ОНЫ ТӨСВИЙН ХҮРЭЭНИЙ МЭДЭГДЭЛ {data[0]?.predict_year1}-{data[0]?.predict_year2} ОНЫ ТӨСВИЙН ТӨСӨӨЛЛИЙН ТУХАЙ</div>)}
                        >
                        </ButtonTooltip>

                    </Space>
                </div>

                <div style={{ display: 'flex' }}>
                    <DataGrid
                        dataSource={data}
                        wordWrapEnabled={true}
                        keyExpr={'id'}
                        columnAutoWidth={true}
                        noDataText='Дата байхгүй байна.'
                        showBorders={true}
                        showRowLines={true}
                        height={'100%'}
                        width={'60%'}
                        onRowClick={(e) => {
                            handleRowClick(e.data)
                        }}
                        onCellPrepared={onCellPrepared}
                        onExporting={onExporting}
                    >
                        <Export enabled={true} />
                        <Column
                            caption='№'
                            alignment='center'
                            width='2.5rem'
                            allowSorting={false}
                            cellRender={(d) => {
                                const currentRowNum = d.data.row_num;
                                let id = d.data.id;
                                let findIndex = data.findIndex(v => v.id === id);
                                let prevRowNum = (data[findIndex-1] || {}).row_num || -1;

                                if (currentRowNum === prevRowNum) {
                                    return <span/>;
                                } else {
                                    return <span>{currentRowNum}</span>;
                                }

                                // if (isSameAsPrevious) {
                                //     // if (data.cellElement) {
                                //     //     data.cellElement.style.display = 'none';
                                //     //     const previousRowSpan = data.cellElement.parentElement.rowSpan;
                                //     //     data.cellElement.parentElement.rowSpan = previousRowSpan + 1;
                                //     // }
                                // } else {
                                //     data.component._prevRowNum = currentRowNum;
                                //     if (data.cellElement) {
                                //         data.cellElement.style.display = '';
                                //     }
                                //     return <span>{currentRowNum}</span>;
                                // }

                                // return <span>{currentRowNum}</span>;
                            }}
                        />
                        <Column
                            caption='Эдийн засгийн үндсэн үзүүлэлт'
                            dataField='econ_name'
                            allowSorting={false}
                        />
                        <Column caption='Төсвийн хүрээний мэдэгдэл' alignment='center' wordWrapEnabled={true}>
                            <Column
                                dataField='amount'
                                width={'10rem'}
                                caption={`${data[0]?.statement_year}`}
                                allowSorting={false}
                                format='#,##0.00'
                            />
                        </Column>
                        <Column caption='Төсвийн төсөөлөл' alignment='center'>
                            <Column
                                dataField='predict_amount1'
                                caption={`${data[0]?.predict_year1}`}
                                format='#,##0.00'
                            />
                            <Column
                                dataField='predict_amount2'
                                caption={`${data[0]?.predict_year2}`}
                                format='#,##0.00'
                            />
                        </Column>
                    </DataGrid>
                    <div style={{ width: '35%', position: 'relative', margin: '0 auto' }}>
                        <h4 style={{ textTransform: 'uppercase', textAlign: 'center', fontSize: '.8rem', marginBottom: 20 }}>{currentYear} оны Монгол улсын нэгдсэн төсөв</h4>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>

                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', gap: 10, width: '45%', border: '2px solid #3DAA75', boxShadow: '0px 0px 5px #3DAA75', borderRadius: '20px', padding: '10px 20px' }}>
                                <svg id="chat-arrow-grow" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 13.717 13.703">
                                    <path id="Path_34428" data-name="Path 34428" d="M15,3.976H13.232a.572.572,0,0,0-.4.976l.621.621h0L11.4,7.621a.585.585,0,0,1-.809,0l-.1-.1a1.755,1.755,0,0,0-2.425,0L5.154,10.438a.573.573,0,1,0,.812.81L8.88,8.333a.585.585,0,0,1,.809,0l.1.1a1.756,1.756,0,0,0,2.425,0l2.05-2.05h0L14.882,7a.572.572,0,0,0,.972-.405V4.833A.857.857,0,0,0,15,3.976Z" transform="translate(-2.136 -1.704)" fill="#44cf69" />
                                    <path id="Path_34429" data-name="Path 34429" d="M13.145,12.56H2.858a1.715,1.715,0,0,1-1.715-1.715V.572A.572.572,0,1,0,0,.572V10.845A2.861,2.861,0,0,0,2.858,13.7H13.145a.572.572,0,0,0,0-1.143Z" fill="#5558d9" />
                                </svg>
                                <span style={{ fontSize: '1rem', textTransform: 'uppercase', fontWeight: 'bold', color: '#3DAA75' }}>Нийт орлого</span>
                                <b style={{ fontSize: '1rem', textAlign: 'center', width: '100%' }}>{numberWithCommas(data[2]?.amount)}</b>
                            </div>

                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', gap: 10, width: '45%', border: '2px solid #F66160', boxShadow: '0px 0px 5px #F66160', borderRadius: '20px', padding: '10px 20px' }}>
                                <svg id="chat-arrow-down" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 13.717 13.703">
                                    <g id="Group_41895" data-name="Group 41895">
                                        <path id="Path_34426" data-name="Path 34426" d="M14.889,9.4l-.621.621h0l-2.049-2.05a1.756,1.756,0,0,0-2.424,0l-.1.1a.585.585,0,0,1-.808,0l-2.9-2.9a.571.571,0,1,0-.808.808l2.9,2.9a1.756,1.756,0,0,0,2.424,0l.1-.1a.585.585,0,0,1,.808,0l2.05,2.053h0l-.621.621a.572.572,0,0,0,.4.976h1.764a.857.857,0,0,0,.857-.857V9.8a.572.572,0,0,0-.976-.4Z" transform="translate(-2.148 -2.143)" fill="#ee1726" />
                                        <path id="Path_34427" data-name="Path 34427" d="M13.145,12.56H2.858a1.715,1.715,0,0,1-1.715-1.715V.572A.572.572,0,1,0,0,.572V10.845A2.861,2.861,0,0,0,2.858,13.7H13.145a.572.572,0,0,0,0-1.143Z" fill="#5558d9" />
                                    </g>
                                </svg>
                                <span style={{ fontSize: '1rem', textTransform: 'uppercase', fontWeight: 'bold', color: '#F66160' }}>Нийт зарлага</span>
                                <b style={{ fontSize: '1rem', textAlign: 'center', width: '100%' }}>{numberWithCommas(data[4]?.amount)}</b>
                            </div>
                        </div>

                        <div style={{ position: 'absolute', top: '100px', left: '30%', display: 'flex', marginTop: 20, flexDirection: 'column', alignItems: 'center', textAlign: 'center', gap: 10, width: '40%', border: '2px solid #ffba08', boxShadow: '0px 0px 5px #ffba08', borderRadius: '40px', backgroundColor: '#fff', padding: '10px 20px' }}>
                            {data[8]?.amount > 0 ?
                                <FaBalanceScaleLeft color='green' size={30} /> :
                                <FaBalanceScaleRight color='red' size={30} />}
                            <span style={{ fontSize: '1rem', textTransform: 'uppercase', color: '#ffba08', fontWeight: 'bold' }}>Тэнцвэржүүлсэн тэнцэл</span>
                            <b style={{ fontSize: '1rem', textAlign: 'center', width: '100%' }}>{numberWithCommas(data[8]?.amount)}</b>
                        </div>
                        <div style={{ border: '1px solid #DEDEDE', borderRadius: '20px', boxShadow: '0px 0px 10px #DEDEDE', padding: 20, marginTop: 130, display: 'flex', textAlign: 'justify', flexDirection: 'column',  gap: 15, fontSize: '.7rem', fontFamily: 'Segoe UI' }}>
                            <h4>Төсвийн тусгай шаардлага:</h4>
                            <span>&nbsp;6.1. Төсөвт дараахь тусгай шаардлагууд нэгэн зэрэг хангагдсан байна:</span>
                            <div style={divStyle.divSize}><span>6.1.1. нэгдсэн төсвийн <span style={{ fontWeight: 'bold' }}>орлогыг тэнцвэржүүлсэн журмаар тооцдог</span>  байх;</span></div>
                            <div style={divStyle.divSize}><span>6.1.2. нэгдсэн төсвийн тэнцвэржүүлсэн тэнцэл нь тухайн төсвийн жилийн дотоодын нийт бүтээгдэхүүний <span style={{ fontWeight: 'bold' }}>хоёр хувиас илүүгүй алдагдалтай, эсхүл ашигтай байх;</span>
                                <br />
                                <span>&nbsp;&nbsp;/төсвөөр дамжуулан их хэмжээний алдагдал гаргахгүй. Гадаад өрөө нэмэхгүй гэсэн тааз, хориг юм./</span> </span></div>
                            <div style={divStyle.divSize}><span>6.1.3. тухайн жилийн <span style={{ fontWeight: 'bold' }}>нэгдсэн төсвийн нийт зарлагын өсөлтийн хувь нь тухайн жилийн</span> эрдэс баялгийн бус <span style={{ fontWeight: 'bold' }}>дотоодын нийт бүтээгдэхүүний өсөлтийн хувь, тухайн жилийн өмнөх дараалсан 12 жилийн</span> эрдэс баялгийн бус <span style={{fontWeight: 'bold'}}>дотоодын нийт бүтээгдэхүүний өсөлтийн дунджийн аль ихээс хэтрэхгүй байх;</span></span></div>
                            <div style={divStyle.divSize}><span>6.1.4. <span style={{ fontWeight: 'bold' }}>Засгийн газрын өр</span>ийн өнөөгийн үнэ цэнээр илэрхийлэгдсэн <span style={{ fontWeight: 'bold' }}>үлдэгдэл</span> нь тухайн жилийн оны үнээр тооцсон <span style={{ fontWeight: 'bold' }}>дотоодын нийт бүтээгдэхүүний 60 хувиас хэтрэхгүй байх.</span></span></div>
                        </div>
                    </div>
                </div>


            <div style={{ padding: 10, display: 'flex', flexDirection: 'column', gap: '10px', margin: '10px 0' }}>
                <span style={{ fontWeight: 'bold' }}>2. {formattedTitle}</span>

                <Chart
                    dataSource={budgetStatementEza}
                    onLegendClick={handleLegend}
                    customizePoint={customizeColor}
                >
                    <Crosshair enabled={true} label={true} />
                    <ValueAxis name='amount' position='left'>
                        <Label format='#' customizeText={customizeValueAxisLabel} />
                    </ValueAxis>
                    <ArgumentAxis>
                        <Strip startValue={0} endValue={2014} color="#f5f5f5" />
                        <Strip startValue={2017} endValue={2020} color="#f5f5f5" />
                        <Strip startValue={2023} color="#f5f5f5" />
                        <Label format='#' />
                    </ArgumentAxis>
                    <Legend
                        visible={true}
                        verticalAlignment="bottom"
                        horizontalAlignment="center"
                        font={{ family: "Segoe UI" }}
                        markerRender={markerTemplate}
                    />
                    <ValueAxis name='percent' position='right' tickInterval={5} />
                    <CommonSeriesSettings
                        argumentField="statement_year" ignoreEmptyPoints={true} type='bar' cornerRadius={10}>
                        <Label visible={false} />
                    </CommonSeriesSettings>
                    <Tooltip
                        zIndex={100000}
                        enabled={true}
                        customizeTooltip={customizeTooltip}
                        location="edge"
                        font={{ color: '#fff' }} color="#555"
                        cornerRadius={6}
                        border={{ visible: false }}
                    />
                    {budgetStatementEza.some(item => item.amount !== null && item.amount !== undefined) &&
                        <Series valueField='amount' name='Төсвийн хүрээний мэдэгдэл' axis='amount' />
                    }
                    {budgetStatementEza.some(item => item.percent !== null && item.percent !== undefined) &&
                        <Series valueField='percent' type='spline' name='ДНБ-д эзлэх хувь' axis='percent' color={'#f66160'} />
                    }
                </Chart>
            </div>
            </div>
            <div style={{ padding: 10, display: 'flex', flexDirection: 'column', gap: '10px', border: '1px solid #DEDEDE', boxShadow: '0px 0px 10px #DEDEDE', borderRadius: '20px', margin: '10px 0' }}>
                <span style={{ fontWeight: 'bold' }}>3. Монгол улсын нэгдсэн төсвийн орлого, зарлага ба тэнцэл /жилээр, тэрбум ₮/</span>
                <Chart
                    dataSource={budgetBalance}
                    onLegendClick={handleLegend}
                    customizePoint={customizeColor}
                >
                    <Crosshair enabled={true} label={true} />
                    {/* <ZoomAndPan argumentAxis="both" /> */}
                    <ValueAxis name='amount' position='left'>
                        <Label format='#' customizeText={customizeValueAxisLabel} />
                    </ValueAxis>
                    <ArgumentAxis>
                        <Strip startValue={0} endValue={2014} color="#f5f5f5" />
                        <Strip startValue={2017} endValue={2020} color="#f5f5f5" />
                        <Strip startValue={2023} color="#f5f5f5" />
                        <Label format='#' />
                    </ArgumentAxis>
                    <Legend
                        visible={true}
                        verticalAlignment="bottom"
                        horizontalAlignment="center"
                        font={{ family: "Segoe UI" }}
                        markerRender={markerTemplate}
                    />
                    <CommonSeriesSettings
                        argumentField="statement_year" barOverlapGroup='one' ignoreEmptyPoints={true} type='bar' cornerRadius={5}>
                        <Label visible={false} />
                    </CommonSeriesSettings>
                    <Tooltip
                        zIndex={100000}
                        enabled={true}
                        customizeTooltip={customizeTooltipTotal}
                        location="edge"
                        font={{ color: '#fff' }} color="#555"
                        cornerRadius={6}
                        border={{ visible: false }}
                    />
                    <Series stack='one' valueField='income' name='Нийт орлого' color={'#3DAA75'} />
                    <Series stack='one' valueField='expenses' name='Нийт зарлага' color={'#F66160'} />
                    <Series stack='one' valueField='balance' type='spline' name='Тэнцвэржүүлсэн тэнцэл' color={'#ffba08'} />
                </Chart>
            </div>
            <div style={{ padding: 10, display: 'flex', alignItems: 'center', justifyContent: 'center', boxShadow: '0px 0px 10px #DEDEDE', textAlign: 'center', gap: '10px', border: '1px solid #DEDEDE', borderRadius: '20px', margin: '10px 0' }}>
                <span style={{ color: "#2E4765", fontWeight: "bold" }}>Эх сурвалж:</span>
                <a style={{ fontWeight: 500 }} href='https://legalinfo.mn/mn/detail/503' rel="noreferrer" target='_blank'>Төсвийн тогтвортой байдлын тухай хууль</a>
            </div>
        </div>
    )
}
const divStyle = {
    divSize: {
        width: '90%',
        marginLeft: 20
    }
}
export default BudgetStatement

import React, {useEffect, useReducer, useState} from 'react';

// services
import FileServices from "../../services/api/file";

// components
import { Popup } from "devextreme-react/popup";
import { ActionSheet } from "devextreme-react";
import { Button } from "devextreme-react/button";
import OgLoader from "../../util/OgLoader";

// static
import styles from './FileManager.module.css';

// const base_url = "http://localhost:3002/api/file/getImage/";
const base_url = "/api/file/getImage/";

const actionSheetItems = [
    { text: 'Татах' },
    { text: 'Засах' },
    { text: 'Устгах' },
];


const FileManagerCustom = () => {

    const [initialItems, setInitialItems] = useState([]);
    const [selectedItem, setSelectedItem] = useState('');
    const [isVisible, setIsVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [singleClicked, setSingleClicked] = useState(false);
    const [rename, setRename] = useState(false);
    const [selectedItemTmp, setSelectedItemTmp] = useState('');

    useEffect(async () => {
        await getInitialItems();
    }, []);

    const getInitialItems = async () => {
        setIsLoading(true);
        try {
            const initialItemsData = await FileServices.getAllFilesFromUpload();
            setInitialItems(initialItemsData.data);
        } catch (e) {
            console.log(e, ' -> error');
        }
        setIsLoading(false);
    };
    const reducer = async (state, action) => {
        switch (action.type) {
            case 'Устгах':
                await deleteItemHandler();
                break;
            case 'Засах':
                handleRename();
                break;
            case 'Татах':
                const link = document.createElement('a');
                link.href = base_url + selectedItem;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            default:
                return;
        }
    };

    const deleteItemHandler = async () => {
        setIsLoading(true);
        try {
            const res = await FileServices.deleteFile(selectedItem);
            await getInitialItems();
        } catch (e) {
            console.log(e, ' err')
        }
        setIsLoading(false);
    }

    const renameHandler = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setRename(false);
        try {
            await FileServices.renameFile(selectedItem, selectedItemTmp);
            await getInitialItems();
        } catch (e) {
            console.log(e, ' err')
        }
        setIsLoading(false);
    }

    const handleRename = () => {
        setRename(true);
    }

    const handleItemSelection = (item) => {
        setSelectedItem(item);
        setIsVisible(true);
    }

    const handleSingleClickSelection = (file_path) => {
        setSelectedItem(file_path);
        setSelectedItemTmp(file_path);
        setSingleClicked(true);
    }

    const hidePopup = () => {
        setIsVisible(false);
    }

    const hideRenamePopup = () => {
        setRename(false);
    }

    const onActionSheetItemClick = (e) => {
        setSingleClicked(false);
        dispatch({type: e.itemData.text})
    }
    //
    const onVisibleChange = (isVisible) => {
        if (isVisible !== singleClicked) {
            setSingleClicked(isVisible);
        }
    }

    const initialState = '';

    const [state, dispatch] = useReducer(reducer, initialState);


    return (
        <div className={styles.main}>
            <OgLoader isLoading={isLoading} />
            <Button width={200} height={40} icon="refresh" text="Шинэчлэх" onClick={getInitialItems} />
            <div className={styles.gridDiv}>
                {initialItems.map((item, index) => {
                    return (
                        <div key={index} onDoubleClick={() => handleItemSelection(item)}
                             onClick={() => handleSingleClickSelection(item)}
                             className={`${styles.fileItemContainer} ${selectedItem === item && styles.selected}`}>
                            <svg t="1647511412643" className={`icon ${styles.icon}`} viewBox="0 0 1024 1024" version="1.1"
                                 xmlns="http://www.w3.org/2000/svg" p-id="2844" width="50" height="50">
                                <path
                                    d="M960.64 256c0-52.928-43.072-96-96-96l-185.6 0c-0.8 0-1.472 0.416-2.272 0.448-3.424 0.224-6.848 0.704-10.08 2.048-0.448 0.192-0.8 0.576-1.248 0.8-3.168 1.472-6.272 3.136-8.928 5.696-16.576 16.032-25.088 47.904-34.528 87.936-1.312 5.536-2.4 10.336-3.52 14.496-1.44 5.76-4.064 16.48-27.264 16.48C588.416 287.904 578.624 288 576 288L160 288c-52.928 0-96 43.072-95.904 98.464L96 800c0 52.928 43.072 96 96 96l640 0c52.928 0 96-43.072 95.904-93.536M864 800c0 17.664-14.336 32-32 32L192 832c-17.632 0-32-14.336-32.096-34.464L128 384c0-17.632 14.368-32 32-32l416 0c2.784 0 12.576-0.096 15.2-0.096 45.92 0 79.328-24.352 89.216-64.512 1.056-4 2.368-9.472 3.872-15.808C686.72 261.216 692.288 237.632 697.056 224l167.584 0c17.664 0 32 14.368 32 32l0 2.272c0 0.128-0.064 0.224-0.064 0.352L864 800z"
                                    p-id="2845" fill="#2c2c2c"></path>
                                <path
                                    d="M128 224l416 0c17.696 0 32-14.336 32-32s-14.304-32-32-32L128 160C110.336 160 96 174.336 96 192S110.336 224 128 224z"
                                    p-id="2846" fill="#2c2c2c"></path>
                            </svg>
                            <p className={styles.fileName}>{item}</p>
                        </div>
                    )
                })}
            </div>
            <Popup
                visible={rename}
                onHiding={hideRenamePopup}
                showCloseButton={true}
                hideOnOutsideClick={true}
                width={300}
                height={180}
            >
                <div className={styles.renameContainer}>
                    <form onSubmit={renameHandler}>
                        <input className={styles.input} type='text' value={selectedItemTmp} onChange={(e) => setSelectedItemTmp(e.target.value)} /><br />
                        <Button  text='Хадгалах' useSubmitBehavior={true} />
                    </form>
                </div>
            </Popup>
            <Popup
                visible={isVisible}
                onHiding={hidePopup}
                showCloseButton={true}
                hideOnOutsideClick={true}
                width={1300}
                height={800}
            >
                <img className={styles.detailImage} src={base_url + selectedItem} />
                <ActionSheet
                    width={700}
                    title="Үйлдлүүд"
                    target={selectedItem}
                    visible={singleClicked}
                    items={actionSheetItems}
                    onItemClick={onActionSheetItemClick}
                    onVisibleChange={onVisibleChange}
                />
                <div className="button" style={{"text-align": "center"}}>
                    <Button width="30%" height={40} text="Үйлдлүүд!" onClick={()=>{setSingleClicked(true)}} />
                </div>
            </Popup>
        </div>
    )
};

export default FileManagerCustom;
import React, {
  useContext,
  useRef,
  useEffect,
  useState,
  useCallback,
} from "react"
import RefService from "../../../services/api/refs"
import DataGrid, { Column, Paging,FilterRow,HeaderFilter } from "devextreme-react/data-grid"
import {
  budgetProjectStatus,
  budgetProjectStatusEnum,
  tabs1 as dataTabs,
  //tabs2 as loanTabs,
} from "../static/data"
import Proposal from "./proposal"
import ProposalCreate from "./proposalCreate"
import Communication from "./communication"
import ProposalLoan from "./proposalLoan"
import Button from "devextreme-react/button"
import notify from "devextreme/ui/notify"
import { useHistory } from "react-router-dom"
import Popup from "devextreme-react/popup"
import ScrollView from "devextreme-react/scroll-view"
import { UserContext } from "../../../hooks/UserContext"
import ProposalService from "../../../services/api/proposal"
import {custom } from "devextreme/ui/dialog"
import ProposalEza from "./proposalEza"
import LoadPanelComponent from "../../budget/components/loadPanel"
import { urlServer } from "../../../shared/lib/request"
import { Tooltip } from "devextreme-react"
import notificationUserService from "../../../services/api/notificationUser"
import { BsFileEarmarkText } from "react-icons/bs"
import { FaBalanceScale, FaBook } from "react-icons/fa"
import styled from "styled-components"
import ProposalDocument from '../../../components/document/document'
import { BackButton } from "../../../components/Buttons"
import {GrClose} from "react-icons/gr"
import UpdatedProposalHistory from "./updatedProposalHistory";
//import PlanService from "../../../services/api/plan";

const ProjectButton = styled(Button)`
  display: flex;
  padding: 0.6rem;
  background-color: white;
  outline: none;
  cursor: pointer;
  border: 1px solid #ddd;
  border-radius: 0.5rem;

  .dx-button-content {
    padding: 0 !important;
  }

  transition: 0.3s ease;

  &:hover {
    background-color: #cbcfd6;
  }

  &:active {
    background-color: #cfd3db;
  }

  svg {
    font-size: 1.2rem;
  }
`
const TabComponent = styled.div`
  display: flex;
  .tab {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 0.5rem;
    background-color: #f5f5f5;
    transition: 0.3s all ease-in;
    &:nth-child(4) {
      border-top-right-radius: 15px;
      border-bottom: 1px solid #ddd;
    }
    &:nth-child(1) {
      border-top-left-radius: 15px;
      border-bottom: 1px solid #ddd;
    }
    &:nth-child(2) {
      border-left: 1px solid #ddd;
      border-right: 1px solid #ddd;
      border-bottom: 1px solid #ddd;
    }
    &:nth-child(3) {
      border-right: 1px solid #ddd;
      border-bottom: 1px solid #ddd;
    }
    .title {
      font-size: 1rem;
     
    }
    &:hover {
      background-color: #ebf1ff;
    }
    &.active {
      color: black;
      background-color: white;
      font-weight: 700;
      border: none;
      text-decoration-line: underline;
    }
  }
`
export const CustomTabPanel = styled.div`
  display: flex;
  gap: 1rem;
  .tab {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 0.5rem;
    background-color: #f8f9fb;
    border-radius: 15px;
    transition: 0.3s all ease-in;
    border: 2px solid transparent;
    .title {
      font-size: 1rem;
      font-weight: 700;
    }
    &.active {
      background-color: white;
      border: 2px solid #eff0f3;
    }
    &:hover {
      background-color: white;
      border: 2px solid #eff0f3;
    }
  }
`

const OnHoverBtn = styled.div`
width: 34;
height: 34 !important;
padding: 0.5rem;
&:hover {
  background-color: rgba(232, 232, 232, 0.4);
  border-radius: 4px;
}
`
export default function  ProposalHome(props) {
  const [economicCategoryArray, setEconomicCategoryArray] = useState([])
  const [currentProposal, setCurrentProposal] = useState(null)
  const [showAddDialog, setShowAddDialog] = useState(false)
  const [agendaListAll, setAgendaListAll] = useState(null)
  const [eventListAll, setEventListAll] = useState(null)
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [selectedIndex2, setSelectedIndex2] = useState(0)
  const [budgetYear, setBudgetYear] = useState(null)
  //const [aimags, setAimags] = useState(null)
  const [loader, setLoader] = useState(false)
  //const [loader1, setLoader1] = useState(false)
  //const [accept, setAccept] = useState("*")
  const [title, setTitle] = useState(null)
  //const [isMinistry, setIsMinistry] = useState(null)
  //const [tabs, setTabs] = useState([])
  //const [desc, setDesc] = useState("")
  const { user } = useContext(UserContext)
  const [filePopupModal, setFilePopupModal] = useState(false)
  const [filePopupModal2, setFilePopupModal2] = useState(false)
  const [currentFile, setCurrentFile] = useState(null)

  //const [docFile, setDocFile] = useState([])
  const [fileList, setFileList] = useState([])

  //const [budgetCalculation, setBudgetCalculation] = useState({})
  //const [budgetStatus, setBudgetStatus] = useState("")
  //const [budgetStatusId, setBudgetStatusId] = useState()
  const inputFilesRef = useRef(null)
  const [files, setFiles] = useState([])
  const proposalId = useRef(null)

  const agendaId = useRef(null)
  const eventId = useRef(null)
  const canEditModal = useRef(null)

  const history = useHistory()
  const [canSend, setCanSend] = useState(false)
  const [canAdd, setCanAdd] = useState(true)
  const [statusText, setStatusText] = useState(false)
  //const [budgetHistory, setBudgetHistory] = useState([])
  let statusButtonColor = useRef("orange")

  const dataGridPositionId = { of: "#dataGrid" }
  //const budgetCard = { of: "#budgetCard" }
  //const historyz = { of: "#historyz" }

  const [checkerLoan, setCheckerLoan] = useState(false)
  const [organization, setOrganization] = useState(null)
  const [documentPopup, setDocumentPopup] = useState(false)
  const [filesUpdated, setFilesUpdated] = useState(false)
  const [proposalDetail, loadProposalDetail] = useState(false)
  const [historyDetail, setHistoryDetail] = useState([]);
  const [closeRenderButton, setCloseRenderButton] = useState(null)
  const [isChanged, setIsChanged] = useState(false)
  useEffect(() => {
    //loadAimag()
    initVars()
    loadData()
    getFileList()
    //getData()
  }, [])
  useEffect(() => {
    selectedIndex ===3 && loadHistory();
  }, [selectedIndex])
  useEffect(() => {
    getFileList()
  }, [filesUpdated])

  const getFileList = async () => {
    const res = await ProposalService.getFileList(
      props.location.state.proposalId
    )
    res.data.forEach((file, numbering) => {
      // console.log('file', file)
      file.order = numbering + 1
      const index = file?.file_path?.indexOf("-")
      const result = file?.file_path?.substring(
        index + 1,
        file?.file_path?.lastIndexOf(".")
      )
      file.file_name = result
    })
    setFileList(res.data)
  }

  /*const loadAimag = async () => {
    try {
      let r = await RefService.getAimag()
      setAimags(r.data)
    } catch (e) {
      notify(e.message, "error", 2000)
    }
  }*/

  /*const getHistoryLoad = async (data) => {
    setLoader1(true)
    const res = await ProposalService.getHistory(data)
    let ac = []
    let tt = res.data.sort(function (a, b) {
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      let t = new Date(b.ognoo) - new Date(a.ognoo)
      return t
    })
    // ac = _.orderBy(res.data, [res.data.id], ['desc']);
    setBudgetHistory(tt)
    setLoader1(false)
  }*/

  const initVars = async () => {
    let counter = 0

    const org = await ProposalService.getOrganizationByForiegn()
    org.data.forEach((el) => {
      if (el.id === props.location.state.proposal.org_id) {
        counter++
        setOrganization(el)
      }
    })

    if (counter === 0) {
      setCheckerLoan(true)
      //setTabs(dataTabs)
    } else {
      setCheckerLoan(false)
      //setTabs(loanTabs)
    }
    // setTabs(dataTabs); // this is static
    const curProposalId = props.location.state
      ? props.location.state.proposalId
      : null
    /*const isMinistry = props.location.state
      ? props.location.state.isMinistry
      : false*/
    const orgName = props.location.state
      ? props.location.state.orgName
        ? props.location.state.orgName
        : user.organization.name
      : user.organization.name
    const aimag_name = props.location.state
      ? props.location.state.aimag_name
      : ""

    if (aimag_name !== undefined) {
      setTitle(orgName + " /" + aimag_name + "/")
    } else {
      setTitle(orgName)
    }

    //setIsMinistry(isMinistry)

    if (curProposalId) {
      const r = await ProposalService.getProposalById(curProposalId)
      if (r.data && r.data.length > 0) {
        setCurrentProposal(r.data[0])
        proposalId.current = r.data[0].id
        //getHistoryLoad(r.data[0].id)
        setBudgetYear(r.data[0].budget_year)
      }
    } else {
      // proposal param was not passed in through props variable
      setBudgetYear(new Date().getFullYear() + 1)
      setupDefault()
    }
  }

  useEffect(() => {
    if (!currentProposal) return
    let statusText = null
    if (
      currentProposal.budget_project_status_id ===
      budgetProjectStatusEnum.ACCEPTED_TEZ
    ) {
      setCanAdd(false)
    }
    if (
      currentProposal.budget_project_status_id ===
      budgetProjectStatusEnum.CREATED ||
      currentProposal.budget_project_status_id ===
      budgetProjectStatusEnum.DENIED_TEZ ||
      currentProposal.budget_project_status_id ===
      budgetProjectStatusEnum.DENIED_TTZ
    ) {
      setCanSend(true)
    } else {
      setCanSend(false)
    }
    if (currentProposal.budget_project_status_id !== 1) {
      statusText = budgetProjectStatus.find(
        (el) => el.value === currentProposal.budget_project_status_id
      )?.name
      if (
        currentProposal.budget_project_status_id === 2 ||
        currentProposal.budget_project_status_id === 3
      ) {
        statusButtonColor.current = "#5575C2"
      } else if (
        currentProposal.budget_project_status_id === 4 ||
        currentProposal.budget_project_status_id === 5
      ) {
        statusButtonColor.current = "#F6605F"
        statusText = null
      } else if (
        currentProposal.budget_project_status_id === 6 ||
        currentProposal.budget_project_status_id === 7
      ) {
        statusButtonColor.current = "#50C787"
      }

      setStatusText(statusText)
    }
    //getData()
  }, [currentProposal])

  // only called when 'props' variable does not have 'proposal' object
  const setupDefault = async () => {
    const result = await ProposalService.getDefaultProposal()
    setCurrentProposal(result.data)
    proposalId.current = result.data.id

    // loadData(result.data);
  }

  const loadData = async (data = null) => {
    // setLoader(true);
    const r = await RefService.getRefEconomicCategory()
    setEconomicCategoryArray(r.data)
    const agenda = await RefService.getAgendas()
    setAgendaListAll(agenda.data)
    const event = await RefService.getEvents()
    setEventListAll(event.data)
    // setLoader(false);
  }
  const onClickCreate = (e) => {
    agendaId.current = null
    eventId.current = null
    canEditModal.current = true
    setShowAddDialog(true) // show the create dialog
    //setCloseRenderButton(true);
    // notify(`The button was clicked`);
  }
  const onClickSubmit = async (e) => {
    const customConfirm = custom({
      title: "Баталгаажуулалт",
      messageHtml: "<i>Төсвийн төслийн санал илгээхдээ итгэлтэй байна үү?</i>",
      buttons: [
        {
          text: "Тийм",
          onClick: (e) => {
            return true
          },
        },
        {
          text: "Үгүй",
          onClick: (e) => {
            return false
          },
        },
      ],
    })

    customConfirm.show().then(async (dialogResult) => {
      setLoader(true)
      if (dialogResult) {
        try {

          const res = await ProposalService.submitProposal(currentProposal.id)
          if (res.success) {
            notify(`Төсвийн төсөл амжилттай илгээгдлээ`, "success");
            pushNotif()
            res.data &&
              res.data.length > 1 &&
              setCurrentProposal(res.data[1][0])
            proposalId.current = res.data.length > 1 ? res.data.id : null
            // ATTENTION

            // // TODO: Add notification Service here
            // await notificationFunctions.postNotification(user.org_id, user, 0);
            loadProposalDetail(prev => !prev)
          } else {

            notify(`${res.message}`, "warning", 5000)
          }
        } catch (e) {
          notify(`Алдаа гарлаа`, "error")
        }
      }
      setLoader(false)
    })
  }

  /*const onTabsSelectionChanged = (arg) => {
    if (arg && arg.name === "selectedIndex") {
      setSelectedIndex(arg.value)
    }
  }*/
  // this fucntion is being called from child component: 'Proposal'
  const onEditHandler = (arg) => {
    agendaId.current = arg.agendaId
    eventId.current = arg.eventId
    canEditModal.current = arg.canEditModal
    setShowAddDialog(true) // show the create dialog
  }
  const onDialogHidden = (e) => {
    setShowAddDialog(false);
    if(isChanged){
      setCloseRenderButton(!closeRenderButton)
      setIsChanged(false);
    }

    // gets stat data
    //getData()
  }

  const CustomPopupTitle = () => (
      <div>
        <Tooltip
            target={"#budgetCalculation"}
            showEvent="mouseenter"
            hideEvent="mouseleave"
            hideOnOutsideClick={false}
            position="top"
        >
          <div style={{ padding: "1em" }}>
            Та төсвийн тооцоолол цэс рүү шилжих бол дарна уу 😀
          </div>
        </Tooltip>
        <Tooltip
            target={"#closeButton"}
            showEvent="mouseenter"
            hideEvent="mouseleave"
            hideOnOutsideClick={false}
            position="top"
        >
          <div style={{ padding: "1em" }}>
            Төсвийн төсөл боловсруулах цонхноос гарах 😌
          </div>
        </Tooltip>
        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'baseline'}}>
          <div style={{paddingLeft: '0.5rem'}}>
      <span style={{ color: "#F99D4B" }}>
        Санамж 1:
      </span>
            <span style={{ color: "#003696", "padding-left": '5px' }}>
        Та төсвийн төслийн саналаа ЗАДГАЙ дүнгээр оруулна уу! - <span style={{color:'#003696',fontWeight:'bold'}}>{title} - {budgetYear} оны төсвийн төсөл</span>
      </span>
          </div>
          <OnHoverBtn onClick={() => onDialogHidden()}><GrClose style={{height: 18, width: 18,cursor:'pointer'}} id={'closeButton'}/></OnHoverBtn>
        </div>
        <div style={{paddingLeft: '0.5rem',paddingRight:'10px'}}>
      <span style={{ color: "#F99D4B" }}>
        Санамж 2:
      </span>
          <span style={{ color: "#003696", "padding-left": '5px' }}>
       Төсвийн тооцоололын маягт холбогдсон ЭЗА-д гараас санал оруулах боломжгүй бөгөөд та <a id={'budgetCalculation'} href={ `/budgetCalculation` } target='_blank' rel='noopener noreferrer' style={{color:'#003696',fontWeight:'bold',textDecoration: 'underline', cursor: 'pointer'}}>Төсвийн тооцоолол</a> цэсээр орж маягтын дагуу төсвийн саналаа боловсруулан "Төсвийн төсөлрүү татах" үйлдэл хийхийг анхаарна уу!
      </span>
        </div>
      </div>

  );

  /*const submitFile = async () => {
    if (!desc) {
      return notify("Файлын тайлбар оруулна уу!", "warning", 3000)
    }
    if (docFile.length === 0) {
      return notify("Файл оруулна уу!", "warning", 3000)
    }
    setLoader(true)
    let fd = new FormData()
    fd.append("additional_doc", docFile[0])
    fd.append("budget_project_id", proposalId.current)
    fd.append("description", desc)

    let res = await ProposalService.uploadFiles(fd)
    if (res.code === 200) {
      setDocFile([])
      setDesc("")
      setLoader(false)
      getFileList()
      notify(res.message, "success", 500)
    } else {
      setLoader(false)
      notify(res.message, "warning", 500)
    }
  }*/

  /*const getData = async () => {
    setLoader1(true)
    let object = {
      type: false,
    }

    if (currentProposal) {
      object["org_id"] = currentProposal.org_id
    }

    const res = await ProposalService.getProjectProgress(
      object,
      props.location.state.proposal.budget_year
    )
    if (res.data.length > 0) {
      setBudgetStatus(res.data[0].budget_project_status_name)
      setBudgetStatusId(res.data[0].budget_project_status_id)
    }
    setLoader1(false)

    let bc = _.groupBy(res.data || [], "econ_category_code_1")
    //setBudgetCalculation(bc)
  }*/

  /*const onClickBack = (e) => {
    if (isMinistry) {
      history.goBack()
    }
  }*/

  const deleteFile = useCallback(async (id) => {
    try {
      const customConfirm = custom({
        title: "Баталгаажуулалт",
        messageHtml: "<i>Энэхүү файлыг устгахдаа итгэлтэй байна уу?</i>",
        buttons: [
          {
            text: "Тийм",
            onClick: (e) => {
              return true
            },
          },
          {
            text: "Үгүй",
            onClick: (e) => {
              return false
            },
          },
        ],
      })
      customConfirm.show()
        .then(async (dialogResult) => {
          if (dialogResult) {
            await ProposalService.deleteFile(id)
            await getFileList()
            return notify(`Файл устгалт амжилттай`, "success", 3300)
          }
        })
    } catch (e) {
      return notify(`Файл устгахад алдаа гарлаа `, "error", 4400)
    }
  }, [fileList])

  const fileActionRender = (row) => {
    return (
      <div style={{ display: "flex", gap: "1em", justifyContent: "center" }}>
        {viewFileCell(row)}
        {removeFileCell(row)}
      </div>
    )
  }

  const viewFile = useCallback((file) => {
    // console.log("file", file.data)
    setCurrentFile(file.data) //file
    setFilePopupModal(true) //popup
  }, [])

  const removeFileCell = useCallback(
    (row) => {
      return (
        <div>
          <Button
            icon="trash"
            id={"removeFile" + row.data.id}
            onClick={() => {
              // console.log('remove file: ',row.data)
              deleteFile(row.data)
            }}
          />
          <Tooltip
            target={"#removeFile" + row.data.id}
            showEvent="mouseenter"
            hideEvent="mouseleave"
            hideOnOutsideClick={false}
            position="top"
          >
            <div style={{ padding: "1em" }}>Устгах</div>
          </Tooltip>
        </div>
      )
    },
    [deleteFile, files]
  )

  const viewFileCell = useCallback(
    (row) => {
      const popupFormats = ["pdf", "png", "jpeg", "jpg", "gif", "webp"].some(
        (format) => row.data.file_path.endsWith(format)
      )
      if (popupFormats)
        return (
          <div>
            <Button
              id={"viewFile" + row.data.id}
              icon="file"
              onClick={(e) => {
                viewFile(row)
              }}
            />
            <Tooltip
              target={"#viewFile" + row.data.id}
              showEvent="mouseenter"
              hideEvent="mouseleave"
              hideOnOutsideClick={false}
              position="top"
            >
              <div style={{ padding: "1em" }}>Харах</div>
            </Tooltip>
          </div>
        )
      else {
        return (
          <div>
            <Button
              icon="download"
              id={"downloadFile" + row.data.id}
              onClick={(e) => {
                const link = document.createElement("a")
                link.href =
                  urlServer + "/api/proposal/file/" + row.data?.file_path
                link.click()
                // viewFile(row)
              }}
            />
            <Tooltip
              target={"#downloadFile" + row.data.id}
              showEvent="mouseenter"
              hideEvent="mouseleave"
              hideOnOutsideClick={false}
              position="top"
            >
              <div style={{ padding: "1em" }}>Татах</div>
            </Tooltip>
          </div>
        )
      }
    },
    [viewFile]
  )

  const handleUpload = useCallback(
    async (e) => {
      e.preventDefault()
      // uploadingFiles.current ??= new FormData()
      const files = Array.from(e.target.files)
      // const uploading = files.filter(file => !file.downloaded);
      // const names = new Set(uploading.map(file => file.name));
      // const filtered = files.filter(file => !names.has(file.name));
      const files_res = files.map((file) => {
        let fd = new FormData()
        fd.append("additional_doc", file)
        fd.append("budget_project_id", proposalId.current)
        fd.append("description", "Ерөнхий мэдээлэл")
        return ProposalService.uploadFiles(fd)
      })
      await Promise.all(files_res)
        .then((res) => {
          // console.log('res', res)
        })
        .catch((err) => {
          // console.log('err', err)
        })
      getFileList()
      // filtered.forEach((file)=>{
      // uploadingFiles.current.append('files',file)
      // })
      // setNewFiles((prevFiles)=>[...prevFiles,...filtered])
      // setAllFiles((prevFiles)=>[...prevFiles,...filtered])
      // uploadFiles()
    },
    [files]
  )

  const pushNotif = async () => {
    // console.log("props year", props.year)

    await notificationUserService.proposalNotification({
      proposalId: proposalId.current,
      action: "propose",
      proposalOrgId: user.organization.id,
      year: props.location.state.proposal.budget_year,
    })
  }

  // console.log('current proposal', currentProposal)

  const loadHistory = async () => {
    try {
      let r = await ProposalService.geBudgetProjectDetailHistory(currentProposal.id);
      setHistoryDetail(r)
    } catch (e) {
      notify(e.message, "`error", 2000);
    }
  }
  const onCellPreparedHandler = (e) => {
    if (e.rowType === 'header') {
      e.cellElement.wordWrapEnabled = true;
      //e.cellElement.style.fontFamily = "Segoe UI";
      e.cellElement.style.fontWeight = "bold";
      e.cellElement.style.color = "#15186A";
      e.cellElement.style.alignment = "center";
      e.cellElement.style.backgroundColor = "#EEF0F4";
      e.cellElement.style.borderColor = "#D4DCEC";
      e.cellElement.style.fontSize = "12px";
    }
    else if (e.rowType === 'group') {
      // console.log(e);
      //e.cellElement.style.fontFamily = "Segoe UI"
      //e.cellElement.wordWrapEnabled = true;
      e.cellElement.style.color = (e.row.groupIndex === 0 ? "#F93A3A" : ((e.row.groupIndex && e.row.groupIndex===1) ? "#2C77FF" : 'black'))
      e.cellElement.style.backgroundColor = "#fff"
      e.cellElement.style.fontWeight = "bold";
      e.cellElement.style.fontSize = "12px"
      e.cellElement.style.borderColor = "#D4DCEC"
    } else if (e.rowType === "data") {
      //e.cellElement.style.fontFamily = 'Segoe UI'
      e.cellElement.style.fontWeight = 'normal'
      e.cellElement.style.color = "#000000"

    }
  }
  const renderHistoryDetail = props => {
    return (
        <div style={{padding:10}}>
         <div style={{ fontSize: "1rem", fontWeight: 700, marginBottom: "1rem" }}>Дэлгэрэнгүй түүх</div>
        <div>
          <DataGrid
              dataSource={historyDetail}
              //rowAlternationEnabled={true}
              allowColumnReordering={true}
              columnAutoWidth={true}
              style={{marginTop: 5}}
              showBorders={true}
              showRowLines={true}
              showColumnLines={true}
              showColumnHeaders={true} onCellPrepared={onCellPreparedHandler}
          >
            <Paging defaultPageSize={10}/>
            <FilterRow visible={false}/>
            <HeaderFilter visible={true}/>
            <Column dataField="createdAt" dataType="date" format="yyyy-MM-dd hh:mm:ss"
                    caption="Огноо"/>
            <Column dataField="agenda_name" caption="Хөтөлбөр"/>
            <Column dataField="event_name" caption="Зориулалт"/>
            <Column dataField="econ_name" caption="Эдийн засгийн ангилал"/>
            <Column
                dataField="amount"
                caption={`ТШЗ санал`}
                alignment="right"
                dataType="number"
                allowGrouping={false}
                format="#,##0.00"
                allowFiltering={false}
                //width={120}
            />
            <Column
                dataField="ttz_amount"
                caption={`ТТЗ санал`}
                alignment="right"
                dataType="number"
                allowGrouping={false}
                format="#,##0.00"
                allowFiltering={false}
                //width={120}
            />
            <Column
                dataField="tez_amount"
                caption={`ТЕЗ санал`}
                alignment="right"
                dataType="number"
                allowGrouping={false}
                format="#,##0.00"
                allowFiltering={false}
                //width={120}
            />
            <Column dataField="user_name" caption="Үйлдэл хийсэн хэрэглэгч"/>
            <Column dataField="description" caption="Үйлдэл"/>
          </DataGrid>
        </div>
        </div>
    )
  }
  return (
    <div className="proposal-home" id="dataGrid" style={{ padding: 10 }}>
      <Popup
        visible={documentPopup}
        onHiding={() => setDocumentPopup(false)}
        width={"70vw"}
        height={"95vh"}
        dragEnabled={true}
        resizeEnabled={true}
        fullScreen={false}
        hideOnOutsideClick={true}
        title={"Төсвийн төслийн албан бичиг"}
      >
        <ScrollView>
          <ProposalDocument
            // approvePerson={requestData?.budget_head_name}
            // approvePerson={false}
            requestCode={currentProposal?.description}
            isOpen={documentPopup}
          />
        </ScrollView>
      </Popup>
      {filePopupModal && currentFile && (
        <Popup
          width={1200}
          maxWidth={"100%"}
          minHeight={"100%"}
          height={"100%"}
          visible={filePopupModal}
          onHiding={() => {
            setFilePopupModal(false)
            setCurrentFile(null)
          }}
          title={`Файлын нэр: ${currentFile?.file_path ?? "content_name хоосон байна"
            }`}
          dragEnabled={false}
          hideOnOutsideClick={true}
        >
          {currentFile?.file_path.endsWith(".pdf") ? (
            <object
              width="100%"
              height="100%"
              data={urlServer + "/api/proposal/file/" + currentFile?.file_path}
              type="application/pdf"
            />
          ) : (
            <img
              src={urlServer + "/api/proposal/file/" + currentFile?.file_path}
              width={"100%"}
            />
          )}
        </Popup>
      )}
      <LoadPanelComponent position={dataGridPositionId} visible={loader} />
      {/*<LoadPanelComponent position={budgetCard} visible={loader1} />
      <LoadPanelComponent position={historyz} visible={loader1} />*/}
      {filePopupModal2 && (
        <Popup
          width={"80%"}
          height={"95vh"}
          visible={filePopupModal2}
          onHiding={() => {
            setFilePopupModal2(false)
          }}
          dragEnabled={false}
          hideOnOutsideClick={true}
        >
          <object
            width="100%"
            height="100%"
            data={`https://cdn.greensoft.mn/uploads/users/3963/files/%D0%A5%D3%A9%D0%B3%D0%B6%D0%BB%D0%B8%D0%B9%D0%BD%20%D1%82%D3%A9%D0%BB%D3%A9%D0%B2%D0%BB%D3%A9%D0%B3%D3%A9%D3%A9%202024.pdf`}
            type="application/pdf"
          />
        </Popup>
      )}
      <div style={{ display: 'flex', flexWrap: "wrap", marginBottom: 20, fontFamily: 'Segoe UI' }}>
        {!props.location.state.masterDetail && <BackButton onClick={() => history.goBack()} />}
        <div
          style={{ display: "flex", alignItems: "center", marginLeft: "1rem" }}
        >
          <a
            style={{
              // fontWeight: 500,
              marginLeft: ".5rem",
              color: "black",
            }}
          >
            {budgetYear} оны төсвийн төсөл -
            <span style={{ color: "#003696", fontWeight: 700 }}> {title}</span>
          </a>
        </div>
        <div
          style={{
            display: "flex",
            gap: ".5rem",
            flex: 1,
            justifyContent: "flex-end",
          }}
        >
          <ProjectButton
            id="editButton"
            className="projectButton"
            hint="Монгол улсын хөгжлийн 2024 оны төлөвлөгөө"
            onClick={() => setFilePopupModal2(true)}
          >
            <BsFileEarmarkText
              style={{ fontSize: "1.2rem", color: "#1a1976" }}
            />
          </ProjectButton>
          <ProjectButton
            onClick={() =>
              window.open("https://legalinfo.mn/mn/detail/12254")
            }
            id="editButton"
            hint="Төсвийн тухай хууль"
            className="projectButton"
          >
            <FaBalanceScale style={{ fontSize: "1.2rem", color: "#1a1976" }} />
          </ProjectButton>
          <ProjectButton
            onClick={() =>
              window.open("https://legalinfo.mn/mn/detail?lawId=17089709745751")
            }
            id="budgetLawSalary"
            hint="ТӨРИЙН АЛБАНЫ ЗАРИМ АЛБАН ТУШААЛЫН ЦАЛИНГИЙН ХЭМЖЭЭ, ДООД ХЭМЖЭЭГ ШИНЭЧЛЭН ТОГТООХ ТУХАЙ"
            className="projectButton"
          >
           <FaBook style={{ fontSize: "1.2rem", color: "#1a1976" }}/>
          </ProjectButton>
          {currentProposal && canAdd && checkerLoan && (
            <Button
              icon="plus"
              text="Төсөл нэмэх"
              type="success"
              style={{ backgroundColor: '#E8EDF6' }}
              stylingMode="contained"
              className="popupButton"
              disabled={
                ![1, 4, 5].includes(
                  currentProposal?.budget_project_status_id
                ) || !(props.location.state!==null && props.location.state.notOrg) && currentProposal?.org_id !== user.org_id
              }
              onClick={onClickCreate}
            />
          )}
          <Tooltip
            target={"#budgetLawSalary"}
            showEvent="mouseenter"
            hideEvent="mouseleave"
            hideOnOutsideClick={false}
            position="top"
          >
            <div style={{ padding: "1em" }}>ТӨРИЙН АЛБАНЫ ЗАРИМ АЛБАН ТУШААЛЫН ЦАЛИНГИЙН ХЭМЖЭЭ, ДООД ХЭМЖЭЭГ ШИНЭЧЛЭН ТОГТООХ ТУХАЙ</div>
          </Tooltip>
          {currentProposal && canSend && (
            <Button
              icon="message"
              text="Төсөл хүргүүлэх"
              type="default"
              className="acceptButton"
              disabled={
                ![1, 4, 5].includes(
                  currentProposal?.budget_project_status_id
                ) || !(props.location.state!==null && props.location.state.notOrg) && currentProposal?.org_id !== user.org_id
              }
              onClick={onClickSubmit}
            />
          )}

          {currentProposal && statusText && (
            <Button
              text={`${statusText}`}
              // disabled={true}
              type="default"
              style={{ backgroundColor: statusButtonColor.current, fontWeight: 'bold' }}
              stylingMode="contained"
            />
          )}
        </div>
      </div>
      {/*<ProjectDetailSlideComp year={props.location.state.proposal.budget_year}/>*/}
      <div className="row">
        <div className="col-12" style={{ display: "flex" }}>
            <div className={"row"}>
              <div className={"col-12"}>
                <div className="card" style={{ width: "100%", margin: 0 }}>
                  <TabComponent>
                    {dataTabs.map((item) => (
                      <div
                        key={item.key}
                        onClick={() => setSelectedIndex(item.id)}
                        style={{fontFamily: 'Segoe UI'}}
                        className={`tab ${item.id} ${selectedIndex === item.id && "active"
                          }`}
                      >
                        <span className="title">{item.text}</span>
                      </div>
                    ))}
                  </TabComponent>

                  {selectedIndex === 0 && checkerLoan && (
                    <div
                      style={{
                        padding: "1rem",
                        border: "1px solid #eff0f3",
                        borderTop: "none",
                        borderBottomLeftRadius: "15px",
                        borderBottomRightRadius: "15px",
                      }}
                    >
                      {currentProposal &&
                        agendaListAll &&
                        eventListAll &&
                        economicCategoryArray && (
                          <>
                            <CustomTabPanel>
                              {[
                                "Хөтөлбөр, арга хэмжээгээр",
                                "Эдийн засгийн ангиллаар",
                              ].map((item, index) => (
                                <div
                                  key={index}
                                  className={`tab ${selectedIndex2 === index && "active"
                                    }`} style={{ marginBottom: '1rem', fontFamily: 'Segoe UI' }}
                                  onClick={() => {
                                    setSelectedIndex2(index);
                                  }}
                                >
                                  <span className="title">{item}</span>
                                </div>
                              ))}
                            </CustomTabPanel>
                            {selectedIndex2 === 0 && (
                              <div style={{ padding: 10 }}>
                                <Proposal refresh={closeRenderButton}
                                  name={title}
                                  obt={user.organization.obt_id}
                                  onEditCallBack={onEditHandler}
                                  proposal={currentProposal}
                                  //eza={economicCategoryArray}
                                  agenda={agendaListAll}
                                  //event={eventListAll}
                                  year={props.location.state.sYear}
                                  loadDetail={proposalDetail}
                                />
                              </div>
                            )}
                            {selectedIndex2 === 1 && (
                              <div className={'card'} style={{ padding: 10 }}>
                                <ProposalEza
                                  name={title}
                                  onEditCallBack={onEditHandler}
                                  proposal={currentProposal}
                                  eza={economicCategoryArray}
                                  year={props.location.state.sYear}
                                />
                              </div>
                            )}

                          </>
                        )}
                    </div>
                  )}

                  {selectedIndex === 0 && !checkerLoan && (
                    <div className="card-body" style={{ width: "100%" }}>
                      {currentProposal &&
                        agendaListAll &&
                        eventListAll &&
                        economicCategoryArray && (
                          <ProposalLoan
                            onEditCallBack={onEditHandler}
                            proposal={currentProposal}
                            eza={economicCategoryArray}
                            agenda={agendaListAll}
                            event={eventListAll}
                            titleName={organization.name}
                          />
                        )}
                    </div>
                  )}

                  {selectedIndex === 1 && (
                    <div style={{
                      padding: "1rem 1.5rem",
                      border: "1px solid #eff0f3",
                      borderTop: "none",
                      borderBottomLeftRadius: "15px",
                      borderBottomRightRadius: "15px",
                      width: '100%',
                      fontFamily: 'Segoe UI'
                    }}>
                      {/*{ currentProposal && <Proposal onEditCallBack={onEditHandler} proposal={currentProposal}/> }*/}
                      <div style={{ padding: "1rem 0" }}>
                        <span>
                          Төсвийн төсөлд холбоо, хамаарал бүхий файлуудыг
                          оруулна уу!
                        </span>
                      </div>
                      <input
                        type="file"
                        name="files"
                        style={{ display: "none" }}
                        ref={inputFilesRef}
                        multiple
                        onChange={handleUpload}
                      />
                      {
                        // currentProposal&&currentProposal?.budget_project_status === 1 && 
                        <div style={{ marginBottom: '1rem', display: 'flex', gap: '1em' }}>
                          <Button
                            text="Файл хавсаргах"
                            icon="attach"
                            onClick={() => {
                              inputFilesRef.current.click()
                            }}
                            size={"2em"}
                          />
                          <Button
                            icon="textdocument"
                            text="Албан бичиг боловсруулах"
                            type="nomral"
                            onClick={() => { setDocumentPopup(true) }}
                          />
                        </div>
                      }
                      {/* <Button
                            icon="textdocument"
                            text="Албан бичиг боловсруулах"
                            type="nomral"
                            onClick={()=>{
                              // setDocumentPopup(true)
                            }}
                        /> */}
                      {fileList.length > 0 && (
                        <div>
                          {/*<div className="card-header" style={{marginTop: 10}}>*/}
                          {/*  <span>Оруулсан файлууд</span>*/}
                          {/*</div>*/}
                          <DataGrid
                            dataSource={fileList}
                            noDataText="Одоогоор файл хавсаргаагүй байна"
                            style={{
                              fontFamily: "'Montserrat', sans-serif",
                              fontWeight: "normal",
                            }}
                          >
                            <Column
                              caption="№"
                              width="8em"
                              alignment="center"
                              dataField="order"
                            />
                            <Column
                              caption="Эдийн засгийн ангилал"
                              width="36em"
                              alignment="left"
                              dataField="description"
                            />
                            <Column
                              caption="Файлын нэр"
                              alignment="left"
                              dataField="file_name"
                            />
                            <Column
                              dataType="date"
                              format="yyyy-MM-dd hh:mm:ss"
                              caption="Огноо"
                              alignment="left"
                              dataField="createdAt"
                            />
                            <Column
                              caption="Үйлдэл"
                              alignment="center"
                              width="10em"
                              cellRender={fileActionRender}
                            />
                            {/* <Column caption="Харах" alignment="center" width="5em" cellRender={viewFileCell}/> */}
                            {/* <Column visible={saveConditions.includes(request?.status)} caption="Устгах" alignment="center" width="5em" cellRender={removeCell}/> */}
                            <Paging defaultPageIndex={0} pageSize={10} />
                          </DataGrid>
                        </div>
                      )}
                    </div>
                  )}

                  {selectedIndex === 2 && (
                    <div style={{
                      padding: "1rem",
                      border: "1px solid #eff0f3",
                      borderTop: "none",
                      // borderBottomLeftRadius: "15px",
                      // borderBottomRightRadius: "15px",
                      position: 'relative'
                    }}>

                      {currentProposal && (
                        <Communication
                          onEditCallBack={onEditHandler}
                          proposal={currentProposal}
                        />
                      )}
                    </div>
                  )}
                  {selectedIndex === 3 && (
                      <div  style={{padding:10}}>
                      <UpdatedProposalHistory currentProposal={currentProposal.id} />
                      </div>
                  )}
                  {selectedIndex === 3 && (
                      renderHistoryDetail()
                  )}
                  {/*{selectedIndex === 3 && !checkerLoan &&*/}
                  {/*  <div className="card-body">*/}
                  {/*    {currentProposal && agendaListAll && eventListAll && economicCategoryArray*/}
                  {/*    && <ProposalLoan onEditCallBack={onEditHandler}*/}
                  {/*                 proposal={currentProposal}  eza={economicCategoryArray}*/}
                  {/*                 agenda={agendaListAll} event={eventListAll} titleName={organization.name}/>}*/}
                  {/*  </div>*/}
                  {/*}*/}
                </div>
              </div>
          </div>
        </div>
      </div>
      {
        showAddDialog && currentProposal && (
          <Popup
            visible={showAddDialog}
            // title={`САНАМЖ: Та төсвийн төслийн саналын дүнгээ ЗАДГАЙ дүнгээр оруулна уу!`}
            titleRender={CustomPopupTitle}
            width={"100vw"}
            height={"100vh"}
            fullScreen={true}
            onHiding={() => onDialogHidden()}
            resizeEnabled={true}
            dragEnabled={true}
            showTitle={true}
            hideOnOutsideClick={true}
          >
            <ScrollView width="100%" height="100%">
              <ProposalCreate
                agendaId={agendaId.current}
                eventId={eventId.current}
                canEditModal = {canEditModal.current}
                proposal={currentProposal}
                setFilesUpdated={setFilesUpdated}
                notOrg = {props.location.state !== null && props.location.state.notOrg}
                onSavedCallBack={(e) => {
                  setShowAddDialog(true)
                 e.isChanged && setIsChanged(true)
                }}
                year={props.location.state.sYear}
              />
            </ScrollView>
          </Popup>
        )
      }
    </div >
  )
}

import React, {useState, useEffect} from "react";
//import notify from "devextreme/ui/notify";
import {Export} from "devextreme-react/pivot-grid";
import {paletteFinanceReport, numberWithCommas} from "../../../../util";
import FinanceReportService from "../../../../services/api/financeReport";
import {Label, Legend, Series, Tooltip} from "devextreme-react/chart";
import PieChart from "devextreme-react/pie-chart";
import SelectBox from "devextreme-react/select-box";
import LoadPanelComponent from "../../../budget/components/loadPanel";
import EmptyData from "../../../budget/components/empty";

const FinanceReportPieChart = (props) => {
    const [selector, setSelector] = useState(null);
    const [selectionData, setSelectionData] = useState([]);
    const [doughnutData, setDoughnutData] = useState([])
    const [loader, setLoader] = useState(false)

    const loadPivotData = async () => {
        try {
            setLoader(true);
            let r = await FinanceReportService.getChartByStartAncestry(props.year, props.reportType,props.selectedOrg,props.filterOrgText);
            let r1 = await FinanceReportService.getChartByName(props.year, props.reportType,selector ? selector : r[0].id,props.selectedOrg,props.filterOrgText);
            setSelector(r[0].id);
            setSelectionData(r);
            setDoughnutData(r1);
            setLoader(false);
            // console.log('setSelectionData:', selectionData);
            // console.log('DoughnutData:', doughnutData);
        } catch (e) {
            setLoader(false);
            console.log('error FinanceReportPieChart', e.message);
        }
    }

    useEffect(() => {
        loadPivotData();
    }, [props.year, props.reportType,props.selectedOrg,props.filterOrgText])

    const cstmText = (e) => {
        return `<span style="color: #f7f7fa">${numberWithCommas(e.value / 1000000)}<br/>${(e.percent * 100).toFixed(2)}%</span>`
    }

    const customizeTooltip = (arg) => {
        return {
            text: `${arg.argumentText}  ${numberWithCommas(arg.value / 1000000)}  ${(arg.percent * 100).toFixed(2)}%`
        };
    }

    const eventHandler = async (e) => {
        let r = await FinanceReportService.getChartByName(props.year, props.reportType,e.value,props.selectedOrg,props.filterOrgText);
        setDoughnutData(r);
    }

   /* const pointClickHanlder = (e) => {
        props.pivotGridHanlder();
    }*/

    return (
        <div id="load10">
            <LoadPanelComponent position={{of: '#load10'}} visible={loader} />
            <SelectBox
            items={selectionData}
            valueExpr="id"
            displayExpr="name"
            onValueChanged={eventHandler}
            defaultValue={selector}/>
            {doughnutData.length > 0 ? (
            <PieChart
                type="doughnut"
                dataSource={doughnutData}
                resolveLabelOverlapping="hide"
                palette={paletteFinanceReport}
                //onPointClick={pointClickHanlder}
            >
                <Series argumentField="name" valueField="amt">
                    <Label position="inside" visible={true} customizeText={cstmText} backgroundColor="none">
                    </Label>
                </Series>
                <Export enabled={true}/>
                <Legend visible={false}/>
                <Tooltip zIndex={10000} enabled={true} customizeTooltip={customizeTooltip} font={{ family: "'Montserrat', sans-serif" }}>
                </Tooltip>
            </PieChart>
                ):
                <div style={{display: "flex", justifyContent: "center", alignItems: "center", family: "'Montserrat', sans-serif" }}>
                    <EmptyData/>
                </div>
            }
        </div>
    )
}

export default FinanceReportPieChart;

import React, {useEffect, useState, useRef} from 'react';

import 'devextreme-react/text-area';
import 'devextreme-react/text-area';
import {
    Editing, Column, Lookup,
} from 'devextreme-react/tree-list';
import notify from 'devextreme/ui/notify';
import DataGrid, {
    FilterRow, Form,Item,Paging,
    Scrolling,Popup as DataGridPopup
} from "devextreme-react/data-grid";
import LoadPanelComponent from '../../../components/LoadPanelComponent';
import FileUploader from "devextreme-react/file-uploader";
import {Button} from "devextreme-react/button";
import FileServices from "../../../services/api/file";
import strategy_icon from "../../../assets/imgs/structureIcon2.png";
import {urlServer} from "../../../shared/lib/request";
import ScrollView from "devextreme-react/scroll-view";
import img from "../../../assets/imgs/userLogo.png";
import Popup from 'devextreme-react/popup';
import {Gallery} from "devextreme-react";
import Compressor from 'compressorjs';

const allowedFileExtensions = ['.jpg', '.jpeg', '.png'];
function LoginBanner(props) {
    const [loader, setLoader] = useState(false);
    const [filePopUpModal, setFilePopUpModal] = useState(false);
    const [fileName, setFileName] = useState(null);
    const [fileData, setFileData] = useState([]);
    const [fileNameBanner, setFileNameBanner] = useState([]);
    const fileRef = useRef();
    const [galleryModal, setGalleryModal] = useState(false);
    useEffect(() => {
        listFetch();
    }, [])

    const listFetch = async () => {
        try {
            setLoader(true);
              
            const r = await FileServices.getFileByType(28);
            const r1 = await FileServices.getFileNameByType(28,undefined);
            setFileData(r.data);
            setFileNameBanner(r1.data);
            return setLoader(false)
        } catch (e) {
            console.error(e);
        }
    }

    //Column custom renderings
    const statusRender = (data) => {
        return (data.value === 1 ? <div style={{
            backgroundColor: '#50CB93',
            width: '80%',
            color: 'white',
            borderRadius: '3px',
            cursor: 'pointer',
            margin: 'auto',
            textAlign: 'center'
        }}>Харагдана</div> : <div style={{
            backgroundColor: '#FF616D',
            width: '80%',
            color: 'white',
            borderRadius: '3px',
            cursor: 'pointer',
            margin: 'auto',
            textAlign: 'center'
        }}>Харагдахгүй</div>)
    }
    const onCellPreparedHandler = (e) => {
        if (e.rowType === 'header') {
            e.cellElement.wordWrapEnabled = true;
            e.cellElement.style.fontFamily = 'Montserrat'
            e.cellElement.style.fontWeight = 'semibold'
            e.cellElement.style.color = "#15186A"
            e.cellElement.style.backgroundColor = "#EEF0F4"
        }
        if (e.rowType === 'data') {
            e.cellElement.style.fontFamily = 'Montserrat'
            e.cellElement.style.fontWeight = 'semibold'
            e.cellElement.style.color = "#000000"
        }
    }
    const fileChangeHandler = (e) => {
        fileRef.current = e.value;
    };
    const fileSubmit = async (typeId, size) => {
        if (fileRef.current === undefined) {
            notify(`Зураг сонгоогүй байна!`, "warning", 3000);
        }
        if (fileRef.current) {
            setLoader(true);
            let data = new FormData();
            if (fileRef.current[0].size / 1024 > 500){
                await new Promise((resolve)=>{
                    new Compressor(fileRef.current[0], {
                        quality: 0.8, 
                        type: 'image/jpeg',
                        convertTypes: ['image/png', 'image/webp'],
                        convertSize: 500000,
                        success: async (compressedResult) => {
                          const arrayBuffer = await compressedResult.arrayBuffer();
                          let compressedFile = new File([arrayBuffer], fileRef.current[0].name, { type: fileRef.current[0].type });
                          data.append("file", compressedFile);
                          resolve();
                        },
                      });
                })  
              }else{
                data.append("file", fileRef.current[0])
              }
            const res = await FileServices.uploadFile(data, typeId);
            if (res.code === 200) {
                notify('Зураг амжилттай хадгаллаа', "success", 2000);
                await listFetch();
              //  let result = await OrganizationService.getOrgWorkerFile(currentWorkerId);
               // setWorkerFileData(result.data);
                fileRef.current = undefined;
                setLoader(false);
            } else {
                notify('Алдаа гардаа', "error", 2000);
                setLoader(false);
            }
        }
    };
    const columnInfo = (data) => {
        setFileName(data.data.file_name);
        setFilePopUpModal(true);

    }
    const renderGridFile = (data) => {
        return data.text && <div style={{textAlign: 'center'}}>
            <Button
                //  style={{width: "100px", height: '25px'}}
                icon={strategy_icon}
                stylingMode="text"
                style={{
                    width: 'auto',
                    height: '35px',
                    opacity: 1
                }}
                onClick={() => {
                    columnInfo(data)
                }}
            />

        </div>
    }
    const renderGalleryItem = (itemData) => {
        return (
                <ScrollView width='100%' height='100%'>
                    <div className="widget-container">
                        <img src={urlServer + '/api/file/documents/' + itemData.file_name} width={'100%'}/>
                    </div>

                </ScrollView>
        );
    }
    const setData = async (e) => {
        if (e.changes && e.changes.length !== 0) {
            if (e.changes[0].type === "update") {
                try {
                    setLoader(true);
                    e.changes[0].data.status = e.changes[0].data.status === true ? 1 : 0;
                    await FileServices.updateFile(e.changes[0].data);
                    await listFetch();
                    notify('Амжилттай хадгалагдлаа', "success", 2000);
                    setLoader(false);
                } catch (e) {
                    notify('Засварлалтыг хадгалах явцад алдаа гарлаа', "error", 2000);
                    setLoader(false);
                }
            }
        }
    }
    return (
        <div>
            <LoadPanelComponent
                shadingColor="rgba(0,0,0,0.4)"
                position={{of: '#password'}}
                visible={loader}
                message=''
                showPane={false}
            />
            <ScrollView height="100%" width="100%">
            <div className="card" style={{padding:10}}>
            <div style={{flex:1, flexDirection: 'row',justifyContent:'flex-start',display:'flex', marginBottom: 20}}>
                {/* <a href="https://compressjpeg.com/" target="_blank" style={{marginTop:5}}>Энд дарж зургаа шахаад оруулна уу!</a> */}
                <FileUploader
                    allowedFileExtensions={allowedFileExtensions}
                    onValueChanged={fileChangeHandler}
                    selectButtonText="Зураг сонгох"
                    labelText=""
                    accept="image/*" width={'120'} style={{marginLeft:5}}
                    uploadMode="useForm"
                    maxFileSize={1000000}
                    invalidMaxFileSizeMessage={`1mb ээс ихгүй зураг байна`}
                />
                <div style={{marginLeft:5}}>
                    <Button icon="save" onClick={() => fileSubmit( 28, 1000000)}
                            text="Зураг хадгалах"/>
                </div>
                <div style={{marginLeft:5}}>
                    <Button icon="image" onClick={() => setGalleryModal(true)}
                            text="Зураг харах"/>
                </div>

            </div>

            <DataGrid
                dataSource={fileData}
                keyExpr="id" columnAutoWidth={true}
                wordWrapEnabled={true}
                onCellPrepared={onCellPreparedHandler}
                rowAlternationEnabled={true}
                showBorders={true}
                showColumnLines={true}
                showRowLines={true}
                onSaved={setData}
            > <Scrolling mode="standard"/>
                <FilterRow
                    visible={true}
                />
                <Column caption="Эрэмбэ" dataField="order_no" dataType={"number"}/>
                <Column caption="Файлын нэр" dataField="content_name"/>
                <Column
                    dataField="file_name"
                    caption="Файл"
                    editCellTemplate={"file_name"}
                    cellRender={renderGridFile}
                />
                <Column caption="Файлын өргөтгөл" dataField="file_extention"/>
                <Column caption="Файлын хэмжээ" dataField="file_size_pretty"/>
                <Column caption="Үүссэн огноо" dataField="createdAt" dataType="date"
                        format="yyyy-MM-dd hh:mm:ss"/>
                <Column caption="Өөрчилсөн огноо" dataField="updatedAt" dataType="date"
                        format="yyyy-MM-dd hh:mm:ss"/>
                <Column caption="Харагдах эсэх" dataField="status" dataType="boolean">
                </Column>
                <Editing
                    mode="popup"
                    allowUpdating={true}
                    useIcons={true}
                    texts={{
                        cancelAllChanges: 'Болих бүгд',
                        cancelRowChanges: 'Болих',
                        confirmDeleteMessage: 'Энэ албан тушаалыг устгах уу?',
                        confirmDeleteTitle: 'Баталгаажуулах',
                        deleteRow: 'Устгах',
                        editRow: 'Өөрчлөх',
                        saveAllChanges: 'Хадгалах бүгд',
                        saveRowChanges: 'Хадгалах',
                        undeleteRow: 'Буцаах',
                        validationCancelChanges: 'Баталгаажуулах болих',
                    }}
                >
                    <DataGridPopup title="Зураг удирдах" showTitle={true} width={500} height={300} closeOnOutsideClick={true}/>
                    <Form>
                        <Item itemType="group" colCount={1} colSpan={2}>
                            <Item dataField="content_name"/>
                            <Item dataField="order_no"/>
                            <Item dataField="status"/>
                        </Item>
                    </Form>
                </Editing>
                <Paging enabled={true}/>
            </DataGrid>

                {fileName &&
                    <Popup
                        width={1200}
                        maxWidth={'100%'}
                        minHeight={'100%'}
                        height={'100%'}
                        title={fileName && fileName}
                        visible={filePopUpModal}
                        onHiding={() => {
                            setFilePopUpModal(false)
                        }}
                        dragEnabled={false}
                        hideOnOutsideClick={true}
                    >
                        <ScrollView> <img
                            src={urlServer + '/api/file/documents/' + fileName}
                            width={'100%'}/> </ScrollView>

                    </Popup>}
                {
                    galleryModal &&
                    <Popup
                        width={800}
                        height={500}
                        minWidth={'80%'}
                        maxWidth={'100%'}
                        minHeight={'100%'}
                        title={"Нэвтрэх хэсэг харагдах зургийн сан"}
                        visible={galleryModal}
                        onHiding={() => {
                            setGalleryModal(false)
                        }}
                        dragEnabled={false}
                        hideOnOutsideClick={true}
                    >
                        <div className="widget-container">
                            <Gallery
                                id="gallery"
                                stretchImages={true}
                                dataSource={fileNameBanner}
                                loop={true}
                                slideshowDelay={2000}
                                height="100%"
                                width="100%"
                                showIndicator={true}
                                itemRender={renderGalleryItem}
                                showNavButtons={true}
                            />
                        </div>

                    </Popup>
                }
            </div>

            </ScrollView>
        </div>
    )
}

export default LoginBanner;

import React, {useContext, useEffect, useRef, useState} from 'react';
import 'devextreme-react/text-area';
import {
    TreeList, Editing, Column, Lookup, Pager, Paging, FilterRow, RowDragging
} from 'devextreme-react/tree-list';
import {UserContext} from "../../../hooks/UserContext";
import OrganizationService from "../../../services/api/organization";
import notify from "devextreme/ui/notify";
import AgendaService from "../../../services/api/agenda";
import TreeListComponent from "../components/TreeListComponent";
import AgendaRelation from "../../../services/api/agendaRelation";
import EventService from "../../../services/api/event";
import EconService from "../../../services/api/economicCategory";
import ReferenceService from "../../../services/api/refs";
import {Form, Item, Popup, RequiredRule} from "devextreme-react/data-grid";
import LoadPanelComponent from "../../budget/components/loadPanel";
import OrganizationEditCellComponent from "../../../components/OrganizationEditCellComponent";
import _ from "lodash";

function ProfileSection(props) {
    const is_foriegn_data = [
        {
            "id": 0,
            "name": "Биш"
        },
        {
            "id": 1,
            "name": "Гадаадын зээл тусламжаар хэрэгжүүлж байгаа төсөл"
        }];

    const {user} = useContext(UserContext);
    const [dataSource, setDataSource] = useState([]);
    const [orgBudgetTypeList, setOrgBudgetTypeList] = useState([]);
    const [loadingVisible, setLoadingVisible] = useState(false);
    const [dataSourceAgenda, setDataSourceAgenda] = useState([]);
    const [dataSourceEvent, setDataSourceEvent] = useState([]);
    const [dataSourceEcon, setDataSourceEcon] = useState([]);
    const [orgTypeList, setOrgTypeList] = useState([]);
    const [isForiegnList, setIsForiegnList] = useState([]);
    const [lookUpDs, setLookUpDs] = useState([]);
    const [aimagList, setAimagList] = useState([]);
    const myBabyRef = useRef(null);
    useEffect(() => {
        getOrganizationSettingList();
    }, []);

    const getOrganizationSettingList = async () => {
        try {
            setLoadingVisible(true);

            const result = await OrganizationService.organizationsSettingList();
            const orgBudgetTypeList = await OrganizationService.getOrgBudgetType();
            const orgTypeList = await ReferenceService.loadRefOrg();
            const isForiegnList = await is_foriegn_data;
            const aimag = await OrganizationService.getAimagList();
           // console.log('aimag', aimag);
            setAimagList(aimag.data);
            setOrgBudgetTypeList(orgBudgetTypeList);
            setOrgTypeList(orgTypeList);
            setIsForiegnList(isForiegnList);
             setLoadingVisible(false);
             setDataSource(result.data);
             let r = await _.filter(result.data, {parent: null});
             setLookUpDs(r);
            const agenda = await AgendaService.getAllAgenda();
            setDataSourceAgenda(agenda);
            const event = await EventService.getAllEvent();
            setDataSourceEvent(event);
            const econ = await EconService.getAllEconomicCategory();
            setDataSourceEcon(econ);
            return setLoadingVisible(false)
        } catch (e) {
            setLoadingVisible(false);
            notify(e.response.data.message, "warning");
        }
    }

    const onRowUpdated = async (data) => {
        try {
            setLoadingVisible(true);

            if (data && data.changes && data.changes.length > 0) {
                if (data.changes[0].type === "insert") delete data.changes[0].data.id ;
                if (data.changes[0].type === "update") delete data.changes[0].data.parent;
                const res = await OrganizationService.updateOrganizationSettingList(data.changes[0].data);
                if (data.changes[0].data.agendaRelations && typeof data.changes[0].data.agendaRelations[0] === 'number') {
                    const newInfo = {
                        agenda_id: data.changes[0].data.agendaRelations,
                        org_id: data.changes[0].type === "insert" ? res.data.id : data.changes[0].data.id
                    }
                    await AgendaRelation.updateAgendaRelation(newInfo);
                }
                if (data.changes[0].data.eventRelations && typeof data.changes[0].data.eventRelations[0] === 'number') {
                    const newInfo = {
                        event_id: data.changes[0].data.eventRelations,
                        org_id: data.changes[0].type === "insert" ? res.data.id : data.changes[0].data.id
                    }
                    await EventService.updateEventRelation(newInfo);
                }
                if (data.changes[0].data.econRelations && typeof data.changes[0].data.econRelations[0] === 'number') {
                    const newInfo = {
                        economic_category_id: data.changes[0].data.econRelations,
                        org_id: data.changes[0].type === "insert" ? res.data.id : data.changes[0].data.id
                    }
                    await EconService.updateEconomicCategoryRelation(newInfo);
                }

            }

            notify("Амжилттай өөрчиллөө!", "success", 5000);

            setLoadingVisible(false);

        } catch (e) {
            setLoadingVisible(false);
            notify("Өөрчилөлт хийхэд алдаа гарлаа!", "warning", 5000);
        }
    }
    const listPosition = {of: '#dataGrid'};

    const initNewRowEvent = (e) => {
        let r;
        // console.log('eeee', e);
        if (e.data.parent === 0) {
            r = [];
        } else {
             r = _.filter(dataSource, {id: e.data.parent});
        }
         setLookUpDs(r);
    }

    const onEditingStartEvent = (e) => {
        let r;
        if (e.data.parent) {
             r = _.filter(dataSource, {id: e.data.parent});
        }
         setLookUpDs(r);
    }
    const onReorder = async (e)=> {
        const visibleRows = e.component.getVisibleRows();
        let sourceData = e.itemData;
        if (e.dropInsideItem) {
            sourceData = { ...sourceData, parent: visibleRows[e.toIndex].key };
            let list={
                id: sourceData.id,
                parent: sourceData.parent,
                updated_user_id:user.organization.id
            };
            await OrganizationService.updateOrganizationSettingList(list);
            await getOrganizationSettingList();
            notify("Амжилттай өөрчиллөө!", "success", 5000);

        }
    }
    const onDragChange = (e)=> {
        const visibleRows = e.component.getVisibleRows();
        const sourceNode = e.component.getNodeByKey(e.itemData.id);
        let targetNode = visibleRows[e.toIndex].node;

        while (targetNode && targetNode.data) {
            if (targetNode.data.id === sourceNode.data.id) {
                e.cancel = true;
                break;
            }
            targetNode = targetNode.parent;
        }
    }
    const onCellPreparedHandler = (e) => {
        if (e.rowType === 'header') {
            e.cellElement.wordWrapEnabled = true;
            e.cellElement.style.fontFamily = 'Montserrat'
            e.cellElement.style.fontWeight = 'semibold'
            e.cellElement.style.color = "#15186A"
            e.cellElement.style.backgroundColor = "#EEF0F4"
        }
        if (e.rowType === 'data') {
            e.cellElement.style.fontFamily = 'Montserrat'
            e.cellElement.style.fontWeight = 'semibold'
            e.cellElement.style.color = "#000000"
        }
    }
    return (
        <>
    {/*        <div className="card-header">
                <span>Ерөнхий мэдээлэл</span>
            </div>*/}
            <div className="col-12 row" style={{justifyContent: "flex-end", gap: '2rem', padding: '5px 15px',}}>

                {/*<TextBox placeholder = "Байгууллагын нэрээ оруулна уу"  showClearButton={true} style={{borderRadius: '7px', borderColor: '#1453B5'}}/>*/}
                {/*<TextBox placeholder = "Регистерийн дугаараа оруулна уу" showClearButton={true} style={{borderRadius: '7px', borderColor: '#1453B5'}}/>*/}
                {/*<Button text = "Хайх"  width = {150} style = {{alignment:"center", color: 'white', borderRadius: '7px', borderColor: '#1453B5', backgroundColor: '#1453b5'}} />*/}
              {/*  {user.roles[0].role_id === 0 && <Button text="Нэмэх" onClick={() => {
                    customAddRow(myBabyRef)
                }} width={150} type="success" style={{alignment: "center", borderRadius: '7px'}}/>}*/}
            </div>
            <LoadPanelComponent position={listPosition} visible={loadingVisible}/>

            <div className="card" style={{padding:10}}>
                <TreeList
                    dataSource={dataSource}
                    allowColumnReordering={true}
                    onSaved={onRowUpdated}
                    ref={myBabyRef}
                    keyExpr="id"
                    parentIdExpr="parent"
                    onEditingStart={onEditingStartEvent}
                    onInitNewRow={initNewRowEvent}
                    rootValue={null}
                    showBorders={true}
                    showColumnLines={true}
                    showRowLines={true}
                    onCellPrepared={onCellPreparedHandler}
                >
                    <RowDragging
                    onDragChange={onDragChange}
                    onReorder={onReorder}
                    allowDropInsideItem={true}
                    allowReordering={false}
                    showDragIcons={true}
                />
                    <FilterRow
                        visible={true}
                    />
                    {/*  <StateStoring enabled={true} type="localStorage" storageKey="treeListStorage" />*/}
                    <Pager
                        showPageSizeSelector={true}
                        // showInfo={true}
                        showNavigationButtons={true}
                    />
                    <Column
                        dataField="aimag_id"
                        caption="Байршил"
                        allowFiltering={true}
                        width={150}
                        allowEditing={true}
                    >
                        <Lookup
                            allowClearing={true}
                            dataSource={aimagList}
                            valueExpr="id"
                            displayExpr="name"/>
                    </Column>
                    <Column
                        dataField="parent"
                        caption="ТТЗ" visible= {false}>

                        <Lookup
                            allowClearing={true}
                            dataSource={lookUpDs}
                            valueExpr="id"
                            displayExpr="organization_name"/>
                    </Column>
                    <Column
                        dataField="organization_name"
                        caption="ТШЗ"
                        allowFiltering={true}
                        width={500}
                    />

                    <Column
                        dataField="code"
                        caption='Регистрийн дугаар'
                        width={100}
                    >
                        <RequiredRule
                            message='Регистрийн дугаар оруулна уу'
                        />
                    </Column>
                    <Column
                        dataField="org_budget_type_id"
                        caption="Төсвийн төрөл">
                        <Lookup
                            allowClearing={true}
                            dataSource={orgBudgetTypeList.data}
                            valueExpr="id"
                            displayExpr="name"/>
                    </Column>
                    <Column
                        dataField="org_type_id"
                        caption="Байгууллагын төрөл">
                        <Lookup
                            allowClearing={true}
                            dataSource={orgTypeList.data}
                            valueExpr="id"
                            displayExpr="name"/>
                    </Column>
                    <Column
                        dataField="user_access_count"
                        caption="Хэрэглэгчийн эрхийн тоо"
                        visible={false}
                    />
                    <Column
                        dataField="is_foriegn"
                        caption="ГЗТ хэрэгжүүлж байгаа төсөл эсэх">
                        <Lookup
                            allowClearing={true}
                            dataSource={isForiegnList}
                            valueExpr="id"
                            displayExpr="name"/>
                    </Column>
                    <Column
                        dataField="parent_org_id"
                        caption="Төсвийн санал болон хуваарь очих байгууллага"
                        // width={500}
                        allowFiltering={true}
                        allowUpdating={true}
                        visible={false}
                        editCellComponent={OrganizationEditCellComponent}
                    >
                        <Lookup
                            valueExpr="id"
                            displayExpr={(item) => {
                                return item && `${item.aimag_name} - ${item.organization_name}`
                            }}
                            dataSource={dataSource}

                        />
                    </Column>
                    <Column
                        dataField="agendaRelations"
                        caption="Төсөл хөтөлбөр"
                        // width={500}
                        allowFiltering={true}
                        allowUpdating={true}
                        visible={false}
                        editCellComponent={TreeListComponent}
                    >
                        <Lookup
                            valueExpr="id"
                            displayExpr={(item) => {
                                return item && `${item.code} - ${item.name}`
                            }}
                            dataSource={dataSourceAgenda}

                        />
                    </Column>
                    <Column
                        dataField="eventRelations"
                        caption="Зориулалт"
                        // width={500}
                        allowFiltering={true}
                        allowUpdating={true}
                        visible={false}
                        editCellComponent={TreeListComponent}
                    >
                        <Lookup
                            valueExpr="id"
                            displayExpr="name"
                            dataSource={dataSourceEvent}

                        />
                    </Column>
                    <Column
                        dataField="econRelations"
                        caption="Эдийн засгийн ангилал"
                        // width={500}
                        allowFiltering={true}
                        allowUpdating={true}
                        visible={false}
                        editCellComponent={TreeListComponent}
                    >
                        <Lookup
                            valueExpr="id"
                            displayExpr="name"
                            dataSource={dataSourceEcon}

                        />

                    </Column>
                    <Column
                        dataField="isNotOrg"
                        caption="Нэвтрэх хэрэглэгчгүй байгууллага" visible={false} dataType={'boolean'}>
                    </Column>
                    <Editing
                        mode="popup"
                        allowUpdating={true}
                        allowDeleting={false}
                        allowAdding = {true}
                        useIcons={true}
                        confirmSave={true}
                        texts={{
                            addRow: 'Төсөл нэмэх',
                            cancelAllChanges: 'Болих бүгд',
                            cancelRowChanges: 'Болих',
                            confirmDeleteMessage: 'Энэхүү мэдээллийг устгах уу?',
                            confirmDeleteTitle: 'Баталгаажуулах',
                            deleteRow: 'Устгах',
                            editRow: 'Өөрчлөх',
                            saveAllChanges: 'Хадгалах бүгд',
                            saveRowChanges: 'Хадгалах',
                            undeleteRow: 'Буцаах',
                            validationCancelChanges: 'Баталгаажуулах болих',
                        }}>
                        <Popup title="Байгууллагын мэдээлэл" showTitle={true} width={"90%"} height={600}/>
                        <Form>
                            <Item itemType="group" colCount={2} colSpan={2}>
                                <Item dataField="parent"/>
                                <Item dataField="aimag_id"/>
                                <Item dataField="organization_name"/>
                                <Item dataField="code"/>
                                <Item dataField="org_budget_type_id"/>
                                <Item dataField="org_type_id"/>
                                <Item dataField="user_access_count" disabled={user.roles[0].role_id !== 0 ? true : false}/>
                                <Item dataField="isNotOrg"/>
                            </Item>
                            <Item itemType="group" caption="Төсвийн санал тохируулах хэсэг" colCount={1} colSpan={2}>
                                <Item dataField="is_foriegn" colSpan={2}/>
                                <Item dataField="parent_org_id" colSpan={2}/>
                                <Item dataField="agendaRelations" colSpan={2}/>
                                <Item dataField="eventRelations" colSpan={2}/>
                                <Item dataField="econRelations" colSpan={2}/>
                            </Item>
                        </Form>

                    </Editing>
                    <Paging enabled={true}/>
                </TreeList>

            </div>

        </>

    )


}

export default ProfileSection;

import React, {useEffect, useRef, useState} from 'react';

import DataGrid, {Column, Editing, Paging, Lookup, RequiredRule, Pager, FilterRow} from "devextreme-react/data-grid";
import SelectBox from "devextreme-react/select-box";

import RefService from "../../../services/api/refs";
import RemainingService from "../../../services/api/remainingService";
import Button from "devextreme-react/button";
import notify from "devextreme/ui/notify";
import LoadPanelComponent from "../../budget/components/loadPanel";

function PermissionSection() {

    const[mainData, setMainData] = useState([]);
    const[loader, setLoader] = useState(false);
    const[refRoles, setRefRoles] = useState([]);
    const[refPermission, setPermissions] = useState([]);
    const[refMenu, setRefMenu] = useState([]);

    const dataGridPosition = { of: '#usersList'}

    useEffect(async () => {
        try {
            setLoader(true);
            await getPermissionMenuRole();
            await getRoles();
            await getMenu();
            await getPermissions();
            setLoader(false);
        } catch (e) {
            console.error(e);
        }
    },[]);

    const dataGridRef = useRef(null);

    const getPermissionMenuRole = async () => {
        try {
            const result = await RefService.getPermissionMenuRole();
            await setMainData(result.data);
        } catch (e) {
            console.error(e);
        }
    }

    //Services
    const getRoles = async () => {
        try {
            const result = await RefService.getRoles();
            // console.log({refRoles})
            return setRefRoles(result.data);
        } catch (e) {
            console.error(e);
        }
    }

    const getMenu = async () => {
        try {
            const result = await RefService.getMenu();
            // console.log({result});
            return setRefMenu(result.data);
        } catch (e) {
            console.error(e);
        }
    }

    const getPermissions = async () => {
        try {
            const result = await RefService.getPermissions();
            // console.log({refPermission});
            return setPermissions(result.data);
        } catch (e) {
            console.error(e);
        }
    }

    // Select Box functions
    function onValueChanged(cell, e) {
        cell.setValue(e.value);
    }

    function itemRender(data) {
        if (data != null) {
            return <div>
                <span className="middle">{data.name}</span>
            </div>;
        } else {
            return <span>(All)</span>;
        }
    }

    function statusEditorRender(cell) {
        let onValueChangedVar = onValueChanged.bind(this, cell);
        return <SelectBox
            defaultValue={cell.value}
            {...cell.column.lookup}
            onValueChanged={onValueChangedVar}
            itemRender={itemRender}
        />;
    }

    //Editing permission status.
    const updatePermissionMenuRole = async (e) => {
        try {
            setLoader(true);
            // console.log('body: ', e.changes);
            const obj = {
                data: e.changes[0].key,
                actionType: e.changes[0].type
            }
            await RemainingService.changeUserPermission(obj);
            await getPermissionMenuRole();
            await getRoles();
            await getMenu();
            await getPermissions();
            setLoader(false);
            notify('Амжилттай хадгалагдлаа', "success", 2000);
        } catch (e) {
            console.error(e);
            setLoader(false);
            notify('Засварлалтыг хадгалах явцад алдаа гарлаа', "error", 2000);
        }
    }

    //Adding new row of information.
    const addRow = (grid) => {
        grid.current.instance.addRow();
    }

    const orderRender = (data) => {
        return <div>{data.rowIndex + 1}</div>
    }
    const onCellPreparedHandler = (e) => {
        if (e.rowType === 'header') {
            e.cellElement.style.color = "black"
            e.cellElement.style.backgroundColor = "#E8E8E8"
            // elem.style.setProperty('color', 'green');
        }
        if (e.rowType === 'data') {
            e.cellElement.style.color = "black"
            // elem.style.setProperty('color', 'green');
        }
    }
    return (
        <div >
            <LoadPanelComponent position={dataGridPosition} visible={loader} />

            <div>
          {/*      <div className="card-header">
                    <span>Ерөнхий мэдээлэл</span>
                </div>*/}
                <div className="card-body">
                    <div style={{display: 'flex', margin: '0 0 1rem 0'}}>
                        <Button onClick = {() => {addRow(dataGridRef)}} text = "Нэмэх" type = 'success' width = {150}  style={{borderRadius: '7px', marginLeft: 'auto'}}/>
                    </div>
                    <DataGrid
                        dataSource={mainData}
                        showBorders={true}
                        onSaved={updatePermissionMenuRole}
                        // focusedRowEnabled={true}
                        ref = {dataGridRef}
                        wordWrapEnabled={true}
                        keyExpr= {null}
                        showBorders={true}
                        showColumnLines={true}
                        showRowLines={true}
                        onCellPrepared={onCellPreparedHandler}
                    >
                        <FilterRow
                          visible = {true}
                        />

                        <Pager
                          showPageSizeSelector={true}
                          // showInfo={true}
                          showNavigationButtons={true}
                        />
                        <Column
                          caption="№"
                          cellRender = {orderRender}
                          width = {60}
                          allowEditing = {false}
                        />
                        <Column
                            dataField="menu_id"
                            caption="Цэс"
                            allowFiltering={true}

                        >
                            <Lookup
                                dataSource={refMenu}
                                displayExpr="name"
                                valueExpr="id"
                            />
                            <RequiredRule />
                        </Column>

                        <Column
                            dataField="menu_id"
                            caption="Цэс холбоос"
                            allowFiltering={true}
                            allowEditing = {false}
                        >
                            <Lookup
                                dataSource={refMenu}
                                displayExpr="menu_url"
                                valueExpr="id"
                            />
                            <RequiredRule />
                        </Column>

                        <Column
                            dataField="permission_id"
                            caption="Системийн эрх"
                            allowFiltering={true}
                        >
                            <Lookup
                                dataSource={refPermission}
                                displayExpr="description"
                                valueExpr="id"
                            />
                            <RequiredRule />
                        </Column>
                        <Column
                            dataField="role_id"
                            caption="Хандалтын түвшин"
                            editCellRender={statusEditorRender}
                            allowFiltering={true}
                        >
                            <Lookup
                                dataSource={refRoles}
                                displayExpr="name"
                                valueExpr="id"
                            />
                            <RequiredRule />
                        </Column>
                        <Editing
                            mode="form"
                            allowUpdating={true}
                            // allowAdding={true}
                            allowDeleting={true}
                            confirmDelete={true}
                            useIcons={true}
                            texts={{
                                addRow: 'Төсөл нэмэх',
                                cancelAllChanges: 'Болих бүгд',
                                cancelRowChanges: 'Болих',
                                confirmDeleteMessage: 'Энэхүү мэдээллийг устгах уу?',
                                confirmDeleteTitle: 'Баталгаажуулах',
                                deleteRow: 'Устгах',
                                editRow: 'Өөрчлөх',
                                saveAllChanges: 'Хадгалах бүгд',
                                saveRowChanges: 'Хадгалах',
                                undeleteRow: 'Буцаах',
                                validationCancelChanges: 'Баталгаажуулах болих',
                            }}
                            // confirmSave = {true}
                        />
                        <Paging enabled={true} />
                    </DataGrid>
                </div>
            </div>
        </div>
    )
}

export default PermissionSection;

import React, {useContext, useState, useEffect} from "react";
import GlBal from "./glbalmon/GlBal";
import ArrayStore from "devextreme/data/array_store";
import Tran from "../tran/TranOg";
import GeneralLeader from "./report/GeneralLeader";
import TabPanel, {Item} from "devextreme-react/tab-panel";
import Deposit from "../BankBook/Deposit";
import Withdraw from "../BankBook/Withdraw";
import TransactionEdit from "../BankBook/BbEdit";
import List from "devextreme-react/list";
import SelectBox from "devextreme-react/select-box";
import {getYears} from "../../../util";
import GlEntery from "./glEntry/GlEntery";
import CustOrgn from "./gledit/CustOrgn";
import Chart from "./gledit/Chart";
import Month from "./gledit/Month";
import GlTranReport from "./report/GltranReport";
import fcsService from "../../../services/api/fcs";
import CustorgParent from "./gledit/custorgParent";
import ChartParent from "./gledit/ChartParent";
import {BiLineChart} from 'react-icons/bi'
import {BsGear} from 'react-icons/bs'
import {BsFileEarmarkArrowDown} from 'react-icons/bs'
import {CgFileDocument} from 'react-icons/cg'
import GlAcctView from "./report/GlAcctView";
import TextBox from 'devextreme-react/text-box';
import St1Pivot from "./report/St1Pivot";
import St1Tree from "./report/St1Tree";
import Button from "devextreme-react/button";
import St4Parent from "./gledit/St4";
import notify from "devextreme/ui/notify";

export const tabs = [{
    id: 1, text: 'Гүйлгээ'
}, {
    id: 2, text: 'Харилцагч'
}, {
    id: 3, text: 'Дансны жагсаалт'
}, {
    id: 4, text: 'Дансны эхний үлдэгдэл'
}, {
    id: 7, text: 'Журналын тохиргоо'
}, {
    id: 8, text: 'СТ-4'
}];

const monthList = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

const dataSource = new ArrayStore({
    key: 'id', data: tabs,
});


const reportTabs = [{id: 1, text: 'Ажлын шалгах баланс'}, {id: 2, text: 'Журналууд'}, {
    id: 3, text: 'Шалгах баланс  / өссөн / '
}, {
    id: 4, text: 'Санхүүгийн тайлан'
}, {id: 5, text: '5'}, {id: 6, text: '6'}]

const reportDataSource = new ArrayStore({
    key: 'id', data: reportTabs,
});
const iconStyle = {
    color: "#1C3BC2", fontWeight: "600", fontSize: "1.2rem", transform: "translateY(.2rem)", marginRight: ".25rem"
}
export default function GeneralLedgerHome() {

    const [menuId, setMenuId] = useState(0);
    const initYearMN = {year: 0, month: 0, name: ""};
    const [yearMN, setYearMN] = useState({...initYearMN});
    const [selectedId, setSelectedId] = useState(1);


    const [reportSelected, setReportSelected] = useState(1);
    const [yearValue, setYearValue] = useState(null);
    const [yearValue2, setYearValue2] = useState(null);

    const [month1Value, setMonth1Value] = useState(null);
    const [month2Value, setMonth2Value] = useState(null);


    const [descr1, setDescr1] = useState('')
    const [descr2, setDescr2] = useState('')
    const glTranType = [{key: 'GL', descr: 'Ерөнхий журнал'}, {key: 'BB', descr: 'Мөнгөний орлого'}
        , {key: 'BZ', descr: 'Мөнгөний зарлага'}, {key: 'IN', descr: 'Бараа орлого'}
        , {key: 'IZ', descr: 'Бараа зарлага'},{key:'FA',descr:'Үндсэн хөрөнгө орлого'}
        ,{key:'FZ',descr:'Үндсэн хөрөнгө  зарлага'},{key:'PR',descr:'Бодогдсон цалин'}
        ,{key:'PZ',descr:'Цалингийн суутгал'},{key:'AP',descr:'Өглөг'}
        ,{key:'AZ',descr:'Өглөгийн төлөлт'},{key:'AR',descr:'Борлуулалт'}
        ,{key:'RZ',descr:'Борлуулалтын төлбөр'}]
    useEffect(async () => {

        const fcsMonth = await fcsService.getAllFcsMonth('GL');
        if (fcsMonth && fcsMonth.length < 1) notify(" тохиргоо хийнэ үү  ", "warning")
        // if ( fcsMonth && fcsMonth.length < 1) setLoader(false)
        if (fcsMonth && fcsMonth.length < 1) return

        setYearValue(fcsMonth[0].year);
        setYearValue2(fcsMonth[0].year);

        setMonth1Value(0);
        setMonth2Value(fcsMonth[0].month);


        setDescr1(formatDate((new Date(fcsMonth[0].year, 0, 1))).toString())

        setDescr2(formatDate((new Date(fcsMonth[0].year, fcsMonth[0].month, 0))).toString())

    }, []);

    const getYearMN = () => {
        setYearMN({
            year: "2022", month: "06", month2: "05",
        });
    };

    const changeGl = (e) => {
        setGlYearMN((prevState) => ({
            ...prevState, [e.target.name]: e.target.value,
        }));
    };
    const [glYearMN, setGlYearMN] = useState({
        year: 0, month: 0, month2: 0,
    });
    const getGlYearMN = () => {
        setGlYearMN({...yearMN});
    };

    const itemClickWorld = async (e) => {
        setSelectedId(e.itemData.id);
    }

    const reportClick = async (e) => {
        setReportSelected(e.itemData.id);
    }

    function formatDate(date) {
        var d = (date), month = '' + (d.getMonth() + 1), day = '' + d.getDate(), year = d.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        return [year, month, day].join('.');
    }

    const renderListItem = (data) => {
        return (<div style={{display: 'flex', alignItems: 'center', padding: '5px', height: '25px'}}>
            <span style={{marginLeft: '10px'}}>{data.text}</span>
        </div>)
    }

    const changeYear = async (e) => {
        setYearValue(e);

        month1Value == 0 ? setDescr1(formatDate((new Date(e, month1Value, 1))).toString()) : setDescr1(formatDate((new Date(e, month1Value, 0))).toString())

    }
    const changeYear2 = (e) => {
        setYearValue2(e);
        month2Value == 0 ? setDescr2(formatDate((new Date(e, month2Value, 1))).toString()) : setDescr2(formatDate((new Date(e, month2Value, 0))).toString())

    }


    const changeMonth1 = (e) => {
        setMonth1Value(e);
        e == 0 ? setDescr1(formatDate((new Date(yearValue, e, 1))).toString()) : setDescr1(formatDate((new Date(yearValue, e, 0))).toString())

    }

    const changeMonth2 = (e) => {
        setMonth2Value(e);
        e == 0 ? setDescr2(formatDate((new Date(yearValue2, e, 1))).toString()) : setDescr2(formatDate((new Date(yearValue2, e, 0))).toString())

    }


    const changeDescr1 = async (e) => {
        setDescr1(e)
    }
    const changeDescr2 = async (e) => {
        setDescr2(e)
    }


    const customTitleRender = (item) => {
        switch (item.title) {
            case "ГҮЙЛГЭЭ":
                return (<span><BiLineChart style={iconStyle}/> {item.title} </span>)
            case "ЗАСВАРЛАХ":
                return (<span><BsGear style={iconStyle}/> {item.title} </span>)
            case "ТАЙЛАН":
                return (<span><CgFileDocument style={iconStyle}/> {item.title} </span>)
            case "САНХҮҮГИЙН ТАЙЛАН":
                return (<span><BsFileEarmarkArrowDown style={iconStyle}/> {item.title}</span>)
            case "ЖУРНАЛУУД":
                return (<span><BsFileEarmarkArrowDown style={iconStyle}/> {item.title}</span>)
            default :
        }

    }
    return (<React.Fragment>
        {useEffect(() => getGlYearMN(), [yearMN])}
        {useEffect(() => getYearMN(), [])}
        <div className={'card generalLedger'}>
            <TabPanel itemTitleRender={customTitleRender}>
                <Item title="ГҮЙЛГЭЭ">
                    <GlEntery yearValue={yearValue} monthValue={month1Value}/>
                </Item>
                <Item title="ЗАСВАРЛАХ">
                    <div id={'firstBox'} className={'card-body'}>
                        <div id={'lawTypeLoad'}>
                            <div className="row">
                                <div className="col-2">
                                    <List
                                        selectionMode="single"
                                        dataSource={dataSource}
                                        selectedItemKeys={selectedId}
                                        onItemClick={itemClickWorld}
                                        itemRender={renderListItem}
                                    />
                                </div>
                                <div className="col-10">
                                    {selectedId === 1 && <div style={{marginLeft: 20}}>
                                        <Tran p1="0" yearMN={yearMN}/>
                                    </div>}
                                    {selectedId === 2 && <div style={{marginLeft: 20}}>
                                        <CustorgParent/>
                                    </div>}
                                    {selectedId === 3 && <div style={{marginLeft: 20}}>
                                        <ChartParent/>
                                    </div>}
                                    {selectedId === 4 && <div style={{marginLeft: 20}}>
                                        <GlBal/>
                                    </div>}
                                    {selectedId === 7 && <div style={{marginLeft: 20}}>
                                        <Month/>
                                    </div>}

                                    {selectedId === 8 && <div style={{marginLeft: 20}}>
                                        <St4Parent/>
                                    </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </Item>
                <Item title="ТАЙЛАН">
                    <div id={'firstBox'} className={'card-body'}>
                        <div id={'lawTypeLoad'}>

                            <div className="row">


                                <div style={{marginLeft: 20}}>
                                    <GlAcctView/>
                                </div>

                            </div>
                        </div>
                    </div>
                </Item>
                <Item title="САНХҮҮГИЙН ТАЙЛАН">
                    <div id={'firstBox'} className={'card-body'}>
                        <div id={'lawTypeLoad'}>
                            <div className="row" style={{display: 'flex', marginBottom: 20}}>
                                <div style={{display: 'flex', marginRight: 20}}>
                                    <div
                                        style={{marginRight: 10, display: 'flex', alignItems: 'center'}}>Өмнөх
                                        он:
                                    </div>
                                    <div>
                                        <SelectBox
                                            items={getYears(true)}
                                            defaultValue={yearValue}
                                            onValueChanged={(e) => changeYear(e.value)}
                                            placeholder="сонгох"/>
                                    </div>
                                </div>
                                <div style={{display: 'flex', marginRight: 20}}>
                                    <div style={{marginRight: 10, display: 'flex', alignItems: 'center'}}>сар:
                                    </div>
                                    <div>
                                        <SelectBox
                                            items={monthList}
                                            defaultValue={month1Value}
                                            onValueChanged={(e) => changeMonth1(e.value)}
                                            placeholder="сонгох"/>
                                    </div>
                                    <div className="dx-field-value">
                                        <TextBox value={descr1}

                                                 onValueChanged={(e) => changeDescr1(e.value)}

                                        />
                                    </div>

                                </div>


                                <div>

                                </div>
                            </div>
                            <div className="row" style={{display: 'flex', marginBottom: 20}}>
                                <div style={{display: 'flex', marginRight: 20}}>
                                    <div
                                        style={{marginRight: 10, display: 'flex', alignItems: 'center'}}>Тайлант
                                        он:
                                    </div>
                                    <div>
                                        <SelectBox
                                            items={getYears(true)}
                                            defaultValue={yearValue2}
                                            onValueChanged={(e) => changeYear2(e.value)}
                                            placeholder="сонгох"/>
                                    </div>

                                </div>

                                <div style={{display: 'flex', marginRight: 20}}>
                                    <div style={{marginRight: 10, display: 'flex', alignItems: 'center'}}> сар:
                                    </div>
                                    <div>
                                        <SelectBox
                                            items={monthList}
                                            defaultValue={month2Value}
                                            onValueChanged={(e) => changeMonth2(e.value)}
                                            placeholder="сонгох"/>
                                    </div>
                                    <div className="dx-field-value">
                                        <TextBox value={descr2}
                                                 onValueChanged={(e) => changeDescr2(e.value)}

                                        />
                                    </div>
                                </div>

                                <div style={{display: 'flex', marginRight: 20}}>

                                </div>

                                <div>

                                </div>
                            </div>

                            <div style={{marginLeft: 20}}>
                                <GeneralLeader glYearMN={glYearMN} year={yearValue} year2={yearValue2}
                                               month1={month1Value} month2={month2Value} descr1={descr1}
                                               descr2={descr2}
                                />
                            </div>

                        </div>
                    </div>
                </Item>
                <Item title="ЖУРНАЛУУД">

                    <div id={'firstBox'} className={'card-body'}>
                        <div id={'lawTypeLoad'}>


                            <div className="row">
                                <GlTranReport  glTranType={glTranType} yearValue={yearValue2} monthValue={month2Value}/>
                            </div>
                        </div>
                    </div>
                </Item>
            </TabPanel>


        </div>
    </React.Fragment>);
}

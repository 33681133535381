import React, { useState, useEffect, useContext } from "react"
import notify from "devextreme/ui/notify"
import BudgetService from "../../../../services/api/budget"
import _ from "lodash"
import DataGrid, {
    Column,
    Paging,
    Summary,
    TotalItem,
} from "devextreme-react/data-grid"
import LoadPanelComponent from "../../../../components/LoadPanelComponent"
import { numberWithCommas } from "../../../../util"
import { UserContext } from "../../../../hooks/UserContext"
import EmptyData from "../../../budget/components/empty"

const AgendaGrid = (props) => {
    const [chartData, setChartData] = useState([])
    const [loader, setLoader] = useState(true)
    const [sumExpense, setSumExpense] = useState(0)
    const [pageIndex, setPageIndex] = useState(0)
    const { user } = useContext(UserContext);

    const loadData = async () => {
        try {
            setPageIndex(0)
            let r = await BudgetService.getAgendaByOrgs(
                props.year,
                props.orgFilterText
            )
            setChartData(r)
            setSumExpense(_.sumBy(r, (e) => parseInt(e.amount)))
        } catch (e) {
            notify(e.message, "error", 2000)
        } finally {
            setLoader(false)
        }
    }

    useEffect(() => {
        loadData()
    }, [props.year, props.orgFilterText])

    const cellTemp = (cellElement, cellInfo) => {
        return cellElement.append(cellInfo.row.loadIndex + 1)
    }

    const onCellPreparedHandler = (e) => {
        if (e.rowType === "header") {
            e.cellElement.wordWrapEnabled = true
            e.cellElement.style.fontFamily = "Segoe UI"
            e.cellElement.style.fontWeight = "bold"
            e.cellElement.style.color = "#15186A"
            e.cellElement.style.backgroundColor = "#EEF0F4"
        }
        if (e.rowType === "data") {
            e.cellElement.style.fontFamily = "Segoe UI"
            e.cellElement.style.fontWeight = "semibold"
            e.cellElement.style.color = "#000000"
        }
        if (e.rowType === "data" && e.column.dataField === "name") {
            let elem = e.cellElement
            elem.style.setProperty("cursor", "pointer", "important")
        }
    }

    const cellFractionColumn = (e) => {
        return `${numberWithCommas(parseInt(e.value )/ 1000000)}`
    }

    const calcCellVal = (e) => {
        return ((e.amount * 100) / sumExpense).toFixed(2)
    }

    if (chartData?.length === 0 && !loader) {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    family: "Segoe UI",
                }}
            >
                <EmptyData/>
            </div>
        )
    }

    return (
        <div id="load6">
            <LoadPanelComponent
                shadingColor="rgba(0,0,0,0.2)"
                position={{ of: "#load6" }}
                visible={loader}
                message=""
                showPane={false}
            />
            <DataGrid
                rowAlternationEnabled={true}
                dataSource={chartData}
                noDataText="Дата байхгүй байна."
                style={{ fontFamily: "Segoe UI", fontWeight: "semibold" }}
                onCellPrepared={onCellPreparedHandler}
                wordWrapEnabled={true}
            >
                <Paging defaultPageSize={10} />
                <Column
                    caption="№"
                    allowSorting={false}
                    cellTemplate={cellTemp}
                    alignment="center"
                    width={'2rem'}
                />
                <Column
                    dataField="name"
                    allowSorting={false}
                    caption="Байгууллага"
                    alignment="left"
                />
                <Column caption="Хөтөлбөр" alignment="center">
                    <Column
                        dataField="undsen_hutulbur"
                        caption="Үндсэн"
                        alignment="center"
                        width={'4rem'}
                        allowSorting={false}
                    />
                    <Column
                        dataField="ded_hutulbur"
                        caption="Дэд"
                        alignment="center"
                        width={'4rem'}
                        allowSorting={false}
                    />
                </Column>
                <Column
                    caption="Зориулалт"
                    alignment="center"
                    dataField="zoriulalt"
                    width={'5rem'}
                    allowSorting={false}
                />
                <Column
                    caption="Арга хэмжээ"
                    alignment="center"
                    dataField="arga_hemjee"
                    width={'4rem'}
                    allowSorting={false}
                />
                <Column caption="Төсөв" alignment="center">
                    <Column
                        caption="Дүн"
                        alignment="center"
                        dataField="amount"
                        allowSorting={true}
                        cellRender={cellFractionColumn}
                        width={'6rem'}
                        defaultSortOrder="desc"
                    />
                    <Column
                        caption="Хувь"
                        calculateCellValue={calcCellVal}
                        alignment="center"
                        width={'4rem'}
                    />
                </Column>
                <Summary>
                    {user.organization.obt_id === 1 && props.orgFilterText === 'ALL' &&
                        <TotalItem
                            column="undsen_hutulbur"
                            summaryType="sum"
                            customizeText={(data) => data.value}
                        />
                    }
                    {user.organization.obt_id === 1 && props.orgFilterText === 'ALL' &&
                        < TotalItem
                            column="ded_hutulbur"
                            summaryType="sum"
                            customizeText={(data) => data.value}
                        />
                    }
                    {user.organization.obt_id === 1 && props.orgFilterText === 'ALL' &&
                        <TotalItem
                            column="zoriulalt"
                            summaryType="sum"
                            customizeText={(data) => data.value}
                        />
                    }
                    {user.organization.obt_id === 1 && props.orgFilterText === 'ALL' &&
                        < TotalItem
                            column="arga_hemjee"
                            summaryType="sum"
                            customizeText={(data) => data.value}
                        />
                    }
                    <TotalItem
                        column="amount"
                        summaryType="sum"
                        customizeText={(data) => numberWithCommas(data.value / 1000000)}
                    />
                </Summary>
            </DataGrid>
        </div>
    )
}

export default AgendaGrid;

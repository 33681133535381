import React from "react";

// components
import { Popup } from "devextreme-react/popup";
import ScrollView from "devextreme-react/scroll-view";

// static
import styles from "../css/newsItem.module.css";


const NewsDetail = ({ visible, data, setVisibleDetail, url }) => {
  // console.log(data)
  return (
    <div>
      <Popup
        visible={visible}
        onHiding={() => setVisibleDetail(false)}
        hideOnOutsideClick={true}
        showTitle={true}
        showCloseButton={true}
        height="95vh"
        width="85vw"
      >
        <ScrollView width="100%" height="100%">
          <h5 className={styles.newsDetailTitle}>{data.news_title}</h5>

          <p className={styles.newsDetailText}>
            {data.news_text && data.news_text}
          </p>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              className={styles.newsDetailImg}
              src={url + data.file.file_name}
              style={{ width: "50vw", height: "60vh", objectFit: "cover" }}
              alt={data.file_name}
            />
          </div>

          {data && (
            <div
              className={styles.markupDiv}
              dangerouslySetInnerHTML={{ __html: data.markup }}
            ></div>
          )}

          {/*<p className={styles.newsDetailText}>Нийтэлсэн:  <span style={{fontWeight: 'lighter', textDecoration: 'underline'}}>{data.first_name + ' ' + data.last_name}</span></p>*/}
          <p className={styles.newsDetailText}>
            Нийтэлсэн огноо:{" "}
            <span
              style={{ fontWeight: "lighter", textDecoration: "underline" }}
            >
              {data.createdAt}
            </span>
          </p>
        </ScrollView>
      </Popup>
    </div>
  );
};

export default NewsDetail;

import React, {useState, useEffect, useRef} from "react";
import notify from "devextreme/ui/notify";
import FinanceReportService from "../../../../services/api/financeReport";
import LoadPanelComponent from "../../../../components/LoadPanelComponent";
import {Workbook} from 'exceljs';
import saveAs from 'file-saver';
import {exportPivotGrid} from "devextreme/excel_exporter";
import PivotGridDataSource from "devextreme/ui/pivot_grid/data_source";
import PivotGrid, {FieldPanel, Export, HeaderFilter} from "devextreme-react/pivot-grid";
import {formatDate} from "../../../../util";
import EmptyData from "../../../budget/components/empty";

const PayrollReportPivotList = (props) => {
    const [dataSrc1, setDataSrc1] = useState([]);
    const [loader, setLoader] = useState(false);
    let _pivotGrid = useRef(null);

    const loadData = async () => {
        try {
            setLoader(true);
            //console.log('props.isPivot', props.isPivot);
            let r = await FinanceReportService.getPayrollReportPivot(props.year, props.selectedOrg, props.filterOrgText, props.selectedMonth);
            const dataSource = new PivotGridDataSource({
                fields: [
                    {
                        caption: 'Байгууллага',
                        dataField: 'name',
                        area: 'row',
                        sortBySummaryField: 'Total',
                        expanded: true,
                        sortBy: 'displayText',
                        sortOrder: 'asc',
                        //width: 300,
                    },
                    {
                        caption: 'Сар',
                        dataField: 'budget_month',
                        area: 'row',
                        sortBy: 'displayText',
                        sortOrder: 'asc',
                        expanded: true,
                        //width: 100,
                    },
                    {
                        caption: 'Алба хаагчид',
                        dataField: 'employment_contract_name',
                        area: 'row',
                        sortBy: 'displayText',
                        sortOrder: 'asc',
                        expanded: true,
                        //width: 200,
                    },
                    {
                        caption: 'Багана 1',
                        dataField: 'field_name_nemegdel',
                        dataType: 'field_name_nemegdel',
                        area: 'column',
                        visible: true,
                        allowFiltering:false,
                        headerFilter:false,
                        /*customizeText: function (cellInfo) {
                            console.log('cellInfo', cellInfo);
                            if(cellInfo.value) this.visible=true; else this.visible=false;
                        },*/
                        sortOrder: 'asc',
                        sortingMethod: function (a, b) {
                            var index1 = a.value;
                            var index2 = b.value;
                            if (index1 === 'Үндсэн') {
                                return 0;
                            }
                            if (index2 === 'Нэмэгдэл') {
                                return 1;
                            }
                            if (index1 > index2)
                                return 0;
                            if (index2 > index1)
                                return 1;
                            else
                                return 0;
                        },
                        expanded: true,

                    }, {
                        caption: 'Багана 2',
                        dataField: 'field_name_sub1',
                        dataType: 'field_name_sub1',
                        allowFiltering:false,
                        headerFilter:false,
                        area: 'column',
                        sortOrder: 'asc',
                        sortingMethod: function (a, b) {
                            var index1 = a.value;
                            var index2 = b.value;
                            if (index1 === 'Улирлын үр дүнгийн урамшуулал') {
                                return 0;
                            }
                            if (index2 === 'Тушаалаар нэмсэн') {
                                return 1;
                            }
                            if (index1 > index2)
                                return 0;
                            if (index2 > index1)
                                return 1;
                            else
                                return 0;
                        },
                        expanded: true,

                    },
                    {
                        caption: 'Багана 3',
                        dataField: 'field_name',
                        dataType: 'field_name',
                        area: 'column',
                        sortOrder: 'asc',
                        sortingMethod: function (a, b) {
                            var index1 = a.value;
                            var index2 = b.value;
                            if (index1 === 'Улирлын үр дүнгийн урамшуулал') {
                                return 0;
                            }
                            if (index2 === 'Тушаалаар нэмсэн') {
                                return 1;
                            }
                            if (index1 > index2)
                                return 0;
                            if (index2 > index1)
                                return 1;
                            else
                                return 0;
                        },
                        expanded: true,
                    },
                    {
                        caption: 'Нийт',
                        dataField: 'value',
                        dataType: 'number',
                        summaryType: 'sum',
                        allowFiltering: false,
                        headerFilter: false,
                        format: {
                            type: 'fixedPoint',
                            precision: 2
                        },
                        area: 'data',
                        //runningTotal: 'row',
                        allowCrossGroupCalculation: true,
                    },
                    /* {   caption:'asas0',
                         dataField: 'name',
                         area: 'filter',
                         //filterValues: ['name'],
                     },*/

                ],
                store: r
            });
            setDataSrc1(dataSource);
            setLoader(false);
        } catch (e) {
            setLoader(false);
            notify(e.message, "error", 2000);
        }
    }

    useEffect(() => {
        loadData();
    }, [props.year, props.filterOrgText, props.selectedOrg, props.selectedMonth, props.isPivot])

    /*const onCellPreparedHandler = (e) => {
        if (e.area === 'column' && e.rowIndex === 0) {
            e.cellElement.wordWrapEnabled = true;
            e.cellElement.style.color = "#fff"
            e.cellElement.style.backgroundColor = `${paletteZarlaga[1]}`
        }
        if (e.area === 'column' && e.rowIndex === 1) {
            e.cellElement.wordWrapEnabled = true;
            e.cellElement.style.color = "#fff"
            e.cellElement.style.backgroundColor = "#76ade7"
        }
        if (e.area === 'column' && e.rowIndex === 2) {
            e.cellElement.wordWrapEnabled = true;
            e.cellElement.style.color = "#15186A"
            e.cellElement.style.backgroundColor = "#E3E7F1"
        }
    }*/
    const onExportingPivot = (e) => {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Main Sheet');

        worksheet.columns = [
            {width: 30}, {width: 20}, {width: 30}, {width: 30}, {width: 30}, {width: 30},
        ];

        exportPivotGrid({
            component: e.component,
            worksheet,
            topLeftCell: {row: 4, column: 1},
            mergeRowFieldValues: true,
            keepColumnWidths: true,
            customizeCell: ({pivotCell, excelCell}) => {
                /*  if (isDataCell(pivotCell) || isTotalCell(pivotCell)) {
                      const appearance = getConditionalAppearance(pivotCell);
                      Object.assign(excelCell, getExcelCellFormat(appearance));
                  }*/

                const borderStyle = {style: 'thin', color: {argb: 'FF7E7E7E'}};
                excelCell.border = {
                    bottom: borderStyle,
                    left: borderStyle,
                    right: borderStyle,
                    top: borderStyle,
                };
            },
        }).then((cellRange) => {
            // Header
            const headerRow = worksheet.getRow(2);
            headerRow.height = 30;

            const columnFromIndex = worksheet.views[0].xSplit + 6;
            const columnToIndex = columnFromIndex + 3;
            worksheet.mergeCells(2, columnFromIndex, 2, columnToIndex);

            const headerCell = headerRow.getCell(columnFromIndex);
            headerCell.value = (props.filterOrgText === 'ALL' ? 'БҮХ БАЙГУУЛЛАГЫН ' : props.filterOrgText + " ") + props.year + " ОНЫ ЦАЛИНГИЙН ТОВЧОО";
            headerCell.font = {bold: true, size: 16, underline: 'double'};
            headerCell.alignment = {horizontal: 'left', vertical: 'middle', wrapText: true};

        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer], {type: 'application/octet-stream'}),"OG-"+ props.year + '-' + props.filterOrgText +"-"+ formatDate(new Date()) + ".xlsx");
            });
        });
        e.cancel = true;
    };

    /*const onCellPrepared =(e)=> {
        //console.log('eeeeeeeeeee',e);
     //   if(e.columnIndex ===1 && e.area === "column")   console.log('eeeeeeeeeee',e);

        if(e.area === "column" && e.cell.path && e.cell.path[0] === null && e.cell.path[1] === null & e.cell.path[2] !== null ){
            console.log('eeeeeeeeeee',e);
            e.cell.path[0] = e.cell.path[0] ? e.cell.path[0] : e.cell.path[2];
            delete e.cell.path[1];
            delete e.cell.path[2]
            e.cell.path.length =1;
            delete e.rowIndex;
            e.rowIndex=1;
            //if(e.cell.path ===undefined)  e.cellElement.querySelector(".dx-pivotgrid-expanded").innerHTML="";

        }
        else   if(e.area === "column" && e.cell.path && e.cell.path[0] === null && e.cell.path[1] === null  ){
            delete e.prototype;
            delete e.cellElement;
            delete e.cell;
            delete e.area;
            delete e.component;
            delete e.element;
            delete e.rowIndex;
            delete e.columnIndex;

        }
        /!*if (e.area === "column" && !e.cell.path) {
            console.log('eeeeeeeeeee',e);
            delete e.prototype;
            delete e.cellElement;
            delete e.cell;
            delete e.area;
            delete e.component;
            delete e.element;
            delete e.rowIndex;
            delete e.columnIndex;
            console.log('after',e);
        }*!/


        /!*if(e.area === "column" && e.cell && e.cell.text === ""){
            e.cellElement.querySelector(".dx-expand-icon-container").innerHTML = "";
        }*!/
        /!*if (e.area !== "column" || !e.cell.path) return;
            console.log('eeeeeeeeeee',e);
            let item = groupValues.filter(i => i.key === e.cell.text)[0];
            console.log('item', item);
        if (item) {
                //cached value
                if (item.empty)
                    //e.cellElement.querySelector(".dx-expand-icon-container").innerHTML = "";
                {let cell1 = e.cellElement.querySelector(".dx-expand-icon-container");
                    if (cell1) cell1.innerHTML = "";
                    return;}
            }
            let drillDownDataSource = e.component
                .getDataSource()
                .createDrillDownDataSource(e.cell);
            // let fields = [];
            let fields = e.component
                .getDataSource()
                .getAreaFields("column")
                .map(f => f.dataField);

            drillDownDataSource.group(fields);
            drillDownDataSource.load().then(data => {
                let isEmpty = true;
                for (var i = 0; i < e.cell.path.length; i++) {
                    let groupKey = e.cell.path[i];
                    data = getItems(data, groupKey);
                }
                data.forEach(item => {
                    if (item.key !== undefined) isEmpty = false;
                });
                groupValues.push({ key: e.cell.text, empty: isEmpty });
                if (isEmpty) {
                    let cell = e.cellElement.querySelector(".dx-expand-icon-container");
                    if (cell) cell.innerHTML = "";
                }
            });*!/
            //if(e.path[2] ==='Үндсэн цалин') e.path[0] = e.path[2];
    }*/

    /*const getItems =(data, key)=> {
        let items = [];
        data.forEach(item => {
            if (item.key === key) items = item.items;
        });
        return items;
    }
    const onCellClick=(e)=> {
        /!*if(e.cell.text === "")
            e.cancel = true;*!/
        //prevent click on an empty field
        let item = groupValues.filter(i => i.key === e.cell.text)[0];
        if (groupValues.filter(i => i.key === e.cell.text).length > 0) {
            e.cancel = item.empty;
        }
    }*/
    const onCellPrepared = (e) => {
        if (e.area === 'column' && e.rowIndex < 3 && e.cell.type === 'D') {
            let cell = e.cellElement.querySelector(".dx-expand-icon-container");
            if (cell) cell.innerHTML = "";
            if (e.rowIndex === 0 && e.cell.text !== 'Нэмэгдэл') {
                e.cellElement.innerHTML = '';
                e.cellElement.style.borderBottomColor = "#fff"
            }
            if (e.rowIndex === 1 && (e.cell.text === 'Нэмэгдэл' || e.cell.text === 'Үндсэн' || e.cell.text === 'Нийт')) {
                e.cellElement.innerHTML = '';
                //e.cellElement.style.borderBottomColor = "#fff"
            }
        }
        /* if (e.area === 'column' && e.rowIndex === 0 && e.cell.type === 'D' && e.cell.text !== 'Нэмэгдэл') {
             console.log('e', e);
             e.cellElement.lastChild.remove();
             e.cellElement.innerHTML = '';
             e.cellElement.style.color = "#fff"
             e.cellElement.style.borderBottomColor = "#fff"

         }
         if (e.area === 'column' && e.rowIndex === 1 && e.cell.type === 'D' ) {
             let cell = e.cellElement.querySelector(".dx-expand-icon-container");
             if (cell) cell.innerHTML = "";

         }
         if (e.area === 'column' && e.rowIndex === 0 && e.cell.type === 'D' && e.cell.text === 'Нэмэгдэл') {
             let cell = e.cellElement.querySelector(".dx-expand-icon-container");
             if (cell) cell.innerHTML = "";
             e.cellElement.style.color = "#fff"
             e.cellElement.style.borderBottomColor = "#fff"

         }*/
    };
    const onCellClick = (e) => {
        if (e.area === 'column') {
            e.component.isRowExpanded(true);
        }
    }
    return (
        <div id="loadP">
            <LoadPanelComponent
                position={{of: '#loadP'}}
                visible={loader}
            />
            {(dataSrc1 && !loader) ? (
                    <PivotGrid
                        style={{fontFamily: "'Montserrat', sans-serif"}}
                        id="pivotgrid"
                        ref={_pivotGrid}
                        height={'50rem'}
                        dataSource={dataSrc1}
                        allowSortingBySummary={true}
                        sortingMethod={true}
                        allowFiltering={true}
                        showBorders={true}
                        showColumnGrandTotals={false}
                        showRowGrandTotals={true}
                        showRowTotals={true}
                        showColumnTotals={false}
                        onExporting={onExportingPivot}
                        onCellPrepared={onCellPrepared}
                        onCellClick={onCellClick}
                        //onOptionChanged={handleOptionChange}
                        fieldChooser={false}
                        allowExpandAll={true}
                        texts={{
                            noData: 'Дата байхгүй байна.',
                            collapseAll: 'Бүгд хураах',
                            expandAll: 'Бүгд дэлгэх',
                            showFieldChooser: 'Талбар сонголт харах',
                            exportToExcel: 'Excel файл татах',
                            grandTotal: 'Нийт дүн',
                            total: '{0}'
                        }}
                    >
                        <HeaderFilter
                            allowSearch={true}
                            showRelevantValues={true}
                            width={300}
                            height={400}
                        />
                        {/*<FieldChooser allowSearch={true} />*/}
                        <FieldPanel visible={true}/>
                        <Export enabled={true}/>
                    </PivotGrid>
                ) :
                <div style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    family: "'Montserrat', sans-serif"
                }}>
                    <EmptyData/>
                </div>
            }
        </div>
    )
}

export default PayrollReportPivotList;

import React, {useContext, useEffect, useState, useRef} from 'react';
import 'devextreme-react/text-area';
import DataGrid, {
    Editing,
    Pager,
    Paging,
    Column,
    FilterRow,
    Export, TotalItem, Summary
} from 'devextreme-react/data-grid';
import {Workbook} from 'exceljs';
import {saveAs} from 'file-saver-es';
import {exportDataGrid} from 'devextreme/excel_exporter';
import {LoadPanel} from "devextreme-react/load-panel";
import {UserContext} from "../../../hooks/UserContext";
import fcsService from "../../../services/api/fcs";
import Button from "devextreme-react/button";
import fcsServices from "../../../services/api/fcs";
import notify from "devextreme/ui/notify";
import LoadPanelComponent from '../../../components/LoadPanelComponent';

function CurrentMonthByAcctReport(props) {
    const userListPosition = {of: '#usersList'}
    const [dataSource, setDataSource] = useState([]);
    const [loader, setLoader] = useState(false);
    const myBabyRef = useRef(null);
    const [dataFcsMonth, setFcsMonth] = useState([]);
    useEffect(() => {
        getList();
    }, [])

    const getList = async () => {
        try {
            setLoader(true);
            const result = await fcsService.getFcsFaByAcctReport();
            setDataSource(result.data);
            const fcsMonth = await fcsService.getAllFcsMonth('FA');
            setFcsMonth(fcsMonth);
            return setLoader(false);
        } catch (e) {
            console.error(e);
        }
    }

    const onEditorPreparing = (e) => {
        // console.log('sdsd ', e);
        if (e.parentType === "dataRow" && e.dataField !== "password") {
            return;
        }
        if (e.row && e.row.isNewRow) {
            e.editorOptions.visible = true;
        }
        if (e.row && !e.row.isNewRow) {
            e.editorOptions.visible = false;
        }
    }
    const customAddRow = (grid) => {
        grid.current.instance.addRow();
    }
    const onInitNewRow = (e) => {

    }
    const setData = async (e) => {
    }
    const onExporting = (e) => {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Main sheet');

        exportDataGrid({
            component: e.component,
            worksheet,
            autoFilterEnabled: true,
        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer], {type: 'application/octet-stream'}), 'CurrentMonthByAcctReport.xlsx');
            });
        });
        e.cancel = true;
    }
    const customizeDate = (data) => {
        return Number(parseFloat(data.value).toFixed(2)).toLocaleString("en", {
            minimumFractionDigits: 2,
        });
    }
    const procFcsFaUpdate = async () => {
        setLoader(true);
        if (dataFcsMonth[0].year && dataFcsMonth[0].month) {
            try {
                const result = await fcsServices.procFcsFaUpdate(dataFcsMonth[0].year, dataFcsMonth[0].month);
                if (result) {
                    getList();
                    notify('Амжилттай элэгдэл бодогдлоо', "success", 2000);
                    return setLoader(false);
                } else {
                    return setLoader(false);
                }
            } catch (e) {
                notify(e.message, "error", 2000);
            }
        }
    };
    const style={
        text: {
            color: "#2E4765", fontWeight: "700", fontSize: ".875rem"
        }
    }
    return (
        <div style={{padding: "0.5rem"}}>
            <LoadPanelComponent
                shadingColor="rgba(0,0,0,0.4)"
                position={{of: '#password'}}
                message=''
                showPane={false}
            />
            <div className="col-12 row" style={{justifyContent: "center", gap: '2rem', padding: '5px 15px'}}>
            <div style={{marginLeft: 20, textTransform: "uppercase", marginTop: 10,...style.text}}><h4> Үндсэн хөрөнгийн нэгдсэн тайлан </h4></div>
            </div>

                <div className="col-12 row" style={{justifyContent: "space-between", gap: '2rem', padding: '5px 15px', marginTop: 10}}>
                           <span> {dataFcsMonth.map((item) => {
                               return <div><span style={{color : "#A3A3A3", }}>Тайлант он </span><span style={{fontWeight: "700"}}>{item.year} сар {item.month}</span></div>;
                           })}
                </span>

                    <Button text="Сарын элэгдэл бодуулах" onClick={() => procFcsFaUpdate()} width={200} type="default"
                            style={{alignment: "center", borderRadius: '7px'}}/>
                </div>

            <LoadPanelComponent
                shadingColor="rgba(0,0,0,0.4)"
                position={userListPosition}
                visible={loader}
                showPane={false}
                message=''
            />
            <div>
                <div className="card-body">
                    <DataGrid
                        onEditorPreparing={onEditorPreparing}
                        ref={myBabyRef}
                        dataSource={dataSource}
                        onSaved={setData}
                        wordWrapEnabled={true}
                        keyExpr={null}
                        onInitNewRow={onInitNewRow}
                        allowColumnResizing={true}
                        onExporting={onExporting}>
                       
                        <Pager
                            showPageSizeSelector={true}
                            // showInfo={true}
                            showNavigationButtons={true}
                        />
                        <Column
                            dataField="acct_chart_name"
                            caption="ҮХ-ийн данс"
                            allowEditing={false}
                            cssClass="custom"
                            minWidth={90}
                            alignment="center"
                            allowFiltering={true}
                        >
                        </Column>
                        <Column
                            cssClass="custom"
                            dataField="bbamt"
                            caption="Эхний үлдэгдэл"
                            minWidth={70}
                            alignment="center"
                            dataType="number" format="#,##0.00"
                        >
                        </Column>
                        <Column
                            cssClass="custom"
                            dataField="oramt"
                            minWidth={90}
                            alignment="center"
                            caption="Нэмэгдсэн"
                            dataType="number" format="#,##0.00"
                        >
                        </Column>
                        <Column
                            cssClass="custom"
                            dataField="zaramt"
                            minWidth={90}
                            alignment="center"
                            caption="Хасагдсан"
                            dataType="number" format="#,##0.00"
                        >
                        </Column>
                        <Column
                            cssClass="custom"
                            dataField="lastamt"
                            minWidth={85}
                            alignment="center"
                            caption="Эцсийн үлдэгдэл"
                            dataType="number" format="#,##0.00"
                        >
                        </Column>
                        <Column caption="Элэгдэл"
                        alignment="center"
                        >
                            <Column
                                cssClass="custom"
                                dataField="bbel"
                                minWidth={90}
                            alignment="center"
                                caption="Хуримтлагдсан"
                                dataType="number" format="#,##0.00"
                            >
                            </Column>
                            <Column
                                cssClass="custom"
                                dataField="orel"
                                minWidth={80}
                            alignment="center"
                                caption="Нэмэгдсэн хөрөнгийн"
                                dataType="number" format="#,##0.00"
                            >
                            </Column>
                            <Column
                                cssClass="custom"
                                dataField="sarel"
                                caption="Сарын"
                                minWidth={80}
                            alignment="center"
                                dataType="number" format="#,##0.00"
                            >
                            </Column>
                            <Column
                                cssClass="custom"
                                minWidth={80}
                            alignment="center"
                                dataField="zarel"
                                caption="Хассан хөрөнгийн"
                                dataType="number" format="#,##0.00"
                            >
                            </Column>
                            <Column
                                cssClass="custom"
                                dataField="elamt"
                                minWidth={90}
                            alignment="center"
                                caption="Элэгдлийн дүн"
                                dataType="number" format="#,##0.00"
                            >
                            </Column>
                        </Column>
                        <Column
                            cssClass="custom"
                            dataField="balcost"
                            minWidth={50}
                            alignment="center"
                            caption="Үлдэх өртөг"
                            dataType="number" format="#,##0.00"
                        >
                        </Column>
                        <Summary>
                            <TotalItem
                                column="bbamt"
                                minWidth={90}
                            alignment="center"
                                summaryType="sum"
                                customizeText={customizeDate}/>
                            <TotalItem
                                column="oramt"
                                summaryType="sum"
                                minWidth={90}
                            alignment="center"
                                customizeText={customizeDate}/>
                            <TotalItem
                                column="zaramt"
                                summaryType="sum"
                                minWidth={90}
                            alignment="center"
                                customizeText={customizeDate}/>
                            <TotalItem
                                column="lastamt"
                                summaryType="sum"
                                minWidth={100}
                            alignment="center"
                                customizeText={customizeDate}/>
                            <TotalItem
                                column="bbel"
                                minWidth={90}
                            alignment="center"
                                summaryType="sum"
                                customizeText={customizeDate}/>
                            <TotalItem
                                column="orel"
                                summaryType="sum"
                                minWidth={90}
                            alignment="center"
                                customizeText={customizeDate}/>
                            <TotalItem
                                column="sarel"
                                minWidth={90}
                            alignment="center"
                                summaryType="sum"
                                customizeText={customizeDate}/>
                            <TotalItem
                                column="zarel"
                                minWidth={90}
                            alignment="center"
                                summaryType="sum"
                                customizeText={customizeDate}/>
                            <TotalItem
                                column="elamt"
                                minWidth={90}
                            alignment="center"
                                summaryType="sum"
                                customizeText={customizeDate}/>
                            <TotalItem
                                column="balcost"
                                minWidth={90}
                            alignment="center"
                                summaryType="sum"
                                customizeText={customizeDate}/>
                        </Summary>
                        <Editing
                            mode="popup"
                            allowUpdating={false}
                            allowDeleting={false}
                            // allowAdding = {adding}
                            useIcons={true}
                            texts={{
                                cancelAllChanges: 'Болих бүгд',
                                cancelRowChanges: 'Болих',
                                confirmDeleteMessage: 'Энэ ангилалын мэдээллийг устгах уу?',
                                confirmDeleteTitle: 'Баталгаажуулах',
                                deleteRow: 'Устгах',
                                editRow: 'Өөрчлөх',
                                saveAllChanges: 'Хадгалах бүгд',
                                saveRowChanges: 'Хадгалах',
                                undeleteRow: 'Буцаах',
                                validationCancelChanges: 'Баталгаажуулах болих',
                            }}
                        >
                        </Editing>
                        <Paging enabled={true}/>
                        <Export enabled={true} allowExportSelectedData={false}/>
                    </DataGrid>
                </div>
            </div>
        </div>
    )
}

export default CurrentMonthByAcctReport;

import React, {useEffect, useState, useRef} from 'react';

import 'devextreme-react/text-area';
import 'devextreme-react/text-area';
import {
    Editing, Column, Lookup,
} from 'devextreme-react/tree-list';
import notify from 'devextreme/ui/notify';
import DataGrid, {
    FilterRow, Form, Item, Paging,
    Scrolling, Popup as DataGridPopup, RequiredRule
} from "devextreme-react/data-grid";
import LoadPanelComponent from '../../../components/LoadPanelComponent';
import FileUploader from "devextreme-react/file-uploader";
import {Button} from "devextreme-react/button";
import FileServices from "../../../services/api/file";
import strategy_icon from "../../../assets/imgs/structureIcon2.png";
import {urlServer} from "../../../shared/lib/request";
import ScrollView from "devextreme-react/scroll-view";
import Popup from 'devextreme-react/popup';
import RoleTagBoxComponent from "../components/RoleTagBoxComponent";
import RemainingService from "../../../services/api/remainingService";

const allowedFileExtensions = ['.pdf'];
function UserManual(props) {
    const [loader, setLoader] = useState(false);
    const [filePopUpModal, setFilePopUpModal] = useState(false);
    const [fileName, setFileName] = useState(null);
    const [fileData, setFileData] = useState([]);
    const[refRoles, setRefRoles] = useState([]);
    const fileRef = useRef();
    useEffect(() => {
        listFetch();
        getReferenceRoles();
    }, [])

    const listFetch = async () => {
        try {
            setLoader(true);
            const r = await FileServices.getFileByType(29);
            setFileData(r.data);
            return setLoader(false)
        } catch (e) {
            console.error(e);
        }
    }
    const getReferenceRoles = async () => {
        try {
            const result = await RemainingService.getReferenceRoles();
            // console.log('GET REFERENCE ROLES',result);
            return setRefRoles(result.data);
        } catch (e) {
            console.error(e);
        }
    }
    //Column custom renderings
    const onCellPreparedHandler = (e) => {
        if (e.rowType === 'header') {
            e.cellElement.style.color = "black"
            e.cellElement.style.backgroundColor = "#E8E8E8"
            // elem.style.setProperty('color', 'green');
        }
        if (e.rowType === 'data') {
            e.cellElement.style.color = "black"
            // elem.style.setProperty('color', 'green');
        }
    }
    const fileChangeHandler = (e) => {
        fileRef.current = e.value;
    };
    const fileSubmit = async (typeId, size) => {
        if (fileRef.current === undefined) {
            notify(`Файл сонгоогүй байна!`, "warning", 3000);
        }
        if (fileRef.current[0].size > size) {
            notify(`Оруулсан файлын хэмжээ  ${size / 1000000}MB ээс их байна`, "warning", 3000);
            return 0;
        }
        if (fileRef.current) {
            setLoader(true);
            let data = new FormData();
            data.append("file", fileRef.current[0]);
            const res = await FileServices.uploadFile(data, typeId);
            if (res.code === 200) {
                notify('Файл амжилттай хадгаллаа', "success", 2000);
                await listFetch();
              //  let result = await OrganizationService.getOrgWorkerFile(currentWorkerId);
               // setWorkerFileData(result.data);
                fileRef.current = undefined;
                setLoader(false);
            } else {
                notify('Алдаа гардаа', "error", 2000);
                setLoader(false);
            }
        }
    };
    const columnInfo = (data) => {
        setFileName(data.data.file_name);
        setFilePopUpModal(true);

    }
    const renderGridFile = (data) => {
        return data.text && <div style={{textAlign: 'center'}}>
            <Button
                //  style={{width: "100px", height: '25px'}}
                icon={strategy_icon}
                stylingMode="text"
                style={{
                    width: 'auto',
                    height: '35px',
                    opacity: 1
                }}
                onClick={() => {
                    columnInfo(data)
                }}
            />

        </div>
    }
    const setData = async (e) => {
        if (e.changes && e.changes.length !== 0) {
            if (e.changes[0].type === "update") {
                try {
                    setLoader(true);
                    // console.log('e.changes[0].data', e.changes[0].data);
                    e.changes[0].data.status = e.changes[0].data.status === true ? 1 : 0;
                    await FileServices.updateFile(e.changes[0].data);
                    if (e.changes[0].data.file_role && typeof e.changes[0].data.file_role[0] === 'number') {
                        const newInfo = {
                            file_id: e.changes[0].data.id,
                            role_id: e.changes[0].data.file_role
                        }
                        await FileServices.changeFileRole(newInfo);
                    }
                    await listFetch();
                    notify('Амжилттай хадгалагдлаа', "success", 2000);
                    setLoader(false);
                } catch (e) {
                    notify('Засварлалтыг хадгалах явцад алдаа гарлаа', "error", 2000);
                    setLoader(false);
                }
            }
        }
    }
    const cellTemplate = (container, options) => {
        let noBreakSpace = '\u00A0',
            text = (options.value || []).map(element => {
                return options.column.lookup.calculateCellValue(element);
            }).join(', ');
        container.textContent = text || noBreakSpace;
        container.title = text;
    }

    const calculateFilterExpression = (filterValue, selectedFilterOperation, target) => {
        if(target === 'filterRow' && typeof (filterValue) === 'number') {
            return ['file_role', 'contains', filterValue];
        }
        return function(data) {
            // console.log('dataaa', data);
            return (data.file_role || []).indexOf(filterValue) !== -1;
        };
    }
    return (
        <div>
            <LoadPanelComponent
                shadingColor="rgba(0,0,0,0.4)"
                position={{of: '#password'}}
                visible={loader}
                message=''
                showPane={false}
            />
            <ScrollView height="100%" width="100%">
            <div className="card-body">
            <div style={{flexDirection: 'row',flex:1,display:'flex', marginBottom: 20}}>
                <div style={{width: 150}}>
                <FileUploader
                    allowedFileExtensions={allowedFileExtensions}
                    onValueChanged={fileChangeHandler}
                    selectButtonText="PDF файл сонгох"
                    labelText=""
                    accept=".pdf"
                    uploadMode="useForm"
                />
                </div>
                <div style={{width: 200, marginLeft: 10}}>
                    <Button icon="save" onClick={() => fileSubmit( 29, 20000000)}
                            text="PDF файл хадгалах"/>
                </div>
            </div>

            <DataGrid
                dataSource={fileData}
                keyExpr="id"
                onCellPrepared={onCellPreparedHandler}
                rowAlternationEnabled={true}
                showBorders={true}
                showColumnLines={true}
                showRowLines={true}
                onSaved={setData}
            > <Scrolling mode="standard"/>
                <FilterRow
                    visible={true}
                />
                <Column caption="Эрэмбэ" dataField="order_no" dataType={"number"}/>
                <Column caption="Харагдах файлын нэр" dataField="content_name"/>
                <Column
                    dataField="file_name"
                    caption="Файл"
                    editCellTemplate={"file_name"}
                    cellRender={renderGridFile}
                />
                <Column caption="Файлын өргөтгөл" dataField="file_extention"/>
                <Column caption="Файлын хэмжээ" dataField="file_size_pretty"/>
                <Column caption="Үүссэн огноо" dataField="createdAt" dataType="date"
                        format="yyyy-MM-dd hh:mm:ss"/>
                <Column caption="Өөрчилсөн огноо" dataField="updatedAt" dataType="date"
                        format="yyyy-MM-dd hh:mm:ss"/>
                <Column caption="Харагдах эсэх" dataField="status" dataType="boolean">
                </Column>
                <Column
                    dataField="file_role"
                    caption="Системийн эрх"
                    allowFiltering={true}
                    editCellComponent={RoleTagBoxComponent}
                    cellTemplate={cellTemplate}
                    calculateFilterExpression={calculateFilterExpression}
                >
                    <Lookup
                        dataSource={refRoles}
                        displayExpr="name"
                        valueExpr="id"
                    />
                    <RequiredRule />
                </Column>
                <Editing
                    mode="popup"
                    allowUpdating={true}
                    useIcons={true}
                    texts={{
                        cancelAllChanges: 'Болих бүгд',
                        cancelRowChanges: 'Болих',
                        confirmDeleteMessage: 'Энэ албан тушаалыг устгах уу?',
                        confirmDeleteTitle: 'Баталгаажуулах',
                        deleteRow: 'Устгах',
                        editRow: 'Өөрчлөх',
                        saveAllChanges: 'Хадгалах бүгд',
                        saveRowChanges: 'Хадгалах',
                        undeleteRow: 'Буцаах',
                        validationCancelChanges: 'Баталгаажуулах болих',
                    }}
                >
                    <DataGridPopup title="Файл удирдах" showTitle={true} width={700} height={400} closeOnOutsideClick={true}/>
                    <Form>
                        <Item itemType="group" colCount={1} colSpan={2}>
                            <Item dataField="content_name"/>
                            <Item dataField="order_no"/>
                            <Item dataField="status"/>
                            <Item dataField="file_role"/>
                        </Item>
                    </Form>
                </Editing>
                <Paging enabled={true}/>
            </DataGrid>

                {fileName &&
                    <Popup
                        width={1200}
                        maxWidth={'100%'}
                        minHeight={'100%'}
                        height={'100%'}
                        title={fileName && fileName}
                        visible={filePopUpModal}
                        onHiding={() => {
                            setFilePopUpModal(false)
                        }}
                        dragEnabled={false}
                        hideOnOutsideClick={true}
                    >
                       <object width="100%" height="100%"
                                             data={urlServer + '/api/file/documents/' + fileName}
                                             type="application/pdf"/>

                    </Popup>}
            </div>

            </ScrollView>
        </div>
    )
}

export default UserManual;

export const tabs1 = [
    {
        id: 0,
        text: 'Төсвийн төсөл',
        icon: 'datafield',
        content: 'User tab content'
    },
    {
        id: 1,
        text: 'Ерөнхий мэдээлэл',
        icon: 'alignjustify',
        content: 'Comment tab content'
    },
    {
        id: 2,
        text: 'Хэлэлцүүлэг',
        icon: 'comment',
        content: 'Find tab content'
    },
    {
        id: 3,
        text: 'Төслийн түүх',
        icon: 'comment',
        content: 'Find tab history'
    },
];

export const tabs2 = [
    {
        id: 0,
        icon: 'money',
        text: 'Гадаад зээл тусламж',
        content: 'Loan tab content'
    },
    {
        id: 1,
        text: 'Ерөнхий мэдээлэл',
        icon: 'find',
        content: 'Comment tab content'
    },
    {
        id: 2,
        text: 'Хэлэлцүүлэг',
        icon: 'comment',
        content: 'Find tab content'
    },
];

export const tabs3 = [
    {
        id: 0,
        text: 'Байгууллага бүтэц график',
        icon: 'chart',
        content: 'Comment tab content'
    },
    {
        id: 1,
        text: 'Байгууллага газрын зураг',
        icon: 'map',
        content: 'User tab content'
    }
];

export const budgetProjectStatus = [
    {value: 1, name: 'ТШЗ-Боловсруулж байгаа'},
    {value: 2, name: 'ТЕЗ-д хүргүүлсэн'},
    {value: 3, name: 'ТТЗ-д хүргүүлсэн'},
    {value: 4, name: 'ТЕЗ-Татгалзсан'},
    {value: 5, name: 'ТТЗ-Татгалзсан'},
    {value: 6, name: 'ТЕЗ-Нэгтгэсэн'},
    {value: 7, name: 'ТТЗ-Нэгтгэсэн'},
    {value: 8, name: 'СЯ-руу хүргүүлсэн'},
    {value: 9, name: 'СЯ-Баталсан'},

];
export const budgetProjectStatusEnum = {
    CREATED: 1,
    SUBMITTED_TEZ: 2,
    SUBMITTED_TTZ: 3,
    DENIED_TEZ: 4,
    DENIED_TTZ: 5,
    ACCEPTED_TEZ: 6,
    ACCEPTED_TTZ: 7,
};
export const projectStatusIdEditAllowed = [1,4,5];

export function helperGroupByKey (arr, key) {
    const groupedArr = [];
    arr.forEach(el => {
        if(el[key] == null) return;
        let index = groupedArr.findIndex(a => a.key === el[key]);
        if (index === -1) groupedArr.push({key: el[key], data: []});
        index = groupedArr.findIndex(a => a.key === el[key]);
        groupedArr[index]['data'].push(el);

    });
    return groupedArr;
}
export function calculateDiff(curData, oldData){
    if (!oldData) return 0;
    return (curData - oldData) / oldData * 100;
}

export const nodes = [
    { id: 1, tags: ["company"],name: "Jack Hill", title: "Chairman and CEO", email: "amber@domain.com", img: "https://mofa.gov.mn/exp/images/uploads/contact/ZG_logo_mongol.jpg" },
    { id: 2, tags: ["company"],pid: 1, name: "Lexie Cole", title: "QA Lead", email: "ava@domain.com", img: "https://mofa.gov.mn/exp/images/uploads/contact/ZG_logo_mongol.jpg" },
    { id: 3, tags: ["company"],pid: 1, name: "Janae Barrett", title: "Technical Director", img: "https://mofa.gov.mn/exp/images/uploads/contact/ZG_logo_mongol.jpg" },
    { id: 4, tags: ["company"],pid: 1, name: "Aaliyah Webb", title: "Manager", email: "jay@domain.com", img: "https://mofa.gov.mn/exp/images/uploads/contact/ZG_logo_mongol.jpg" },
    { id: 5, tags: ["company"],pid: 2, name: "Elliot Ross", title: "QA", img: "https://mofa.gov.mn/exp/images/uploads/contact/ZG_logo_mongol.jpg" },
    { id: 6, tags: ["company"],pid: 2, name: "Anahi Gordon", title: "QA", img: "https://mofa.gov.mn/exp/images/uploads/contact/ZG_logo_mongol.jpg" },
    { id: 7, tags: ["company"],pid: 2, name: "Knox Macias", title: "QA", img: "https://mofa.gov.mn/exp/images/uploads/contact/ZG_logo_mongol.jpg" },
    { id: 8, tags: ["company"],pid: 3, name: "Nash Ingram", title: ".NET Team Lead", email: "kohen@domain.com", img: "https://mofa.gov.mn/exp/images/uploads/contact/ZG_logo_mongol.jpg" },
    { id: 9, tags: ["company"],pid: 3, name: "Sage Barnett", title: "JS Team Lead", img: "https://mofa.gov.mn/exp/images/uploads/contact/ZG_logo_mongol.jpg" },
    { id: 10,tags: ["company"], pid: 8, name: "Alice Gray", title: "Programmer", img: "https://mofa.gov.mn/exp/images/uploads/contact/ZG_logo_mongol.jpg" },
    { id: 11,tags: ["company"], pid: 8, name: "Anne Ewing", title: "Programmer", img: "https://mofa.gov.mn/exp/images/uploads/contact/ZG_logo_mongol.jpg" },
    { id: 12,tags: ["company"], pid: 9, name: "Reuben Mcleod", title: "Programmer", img: "https://mofa.gov.mn/exp/images/uploads/contact/ZG_logo_mongol.jpg" },
    { id: 13,tags: ["company"], pid: 9, name: "Ariel Wiley", title: "Programmer", img: "https://mofa.gov.mn/exp/images/uploads/contact/ZG_logo_mongol.jpg" },
    { id: 14,tags: ["company"], pid: 4, name: "Lucas West", title: "Marketer", img: "https://mofa.gov.mn/exp/images/uploads/contact/ZG_logo_mongol.jpg" },
    { id: 15,tags: ["company"], pid: 4, name: "Adan Travis", title: "Designer", img: "https://mofa.gov.mn/exp/images/uploads/contact/ZG_logo_mongol.jpg" },
    { id: 16,tags: ["company"], pid: 4, name: "Alex Snider", title: "Sales Manager", img: "https://mofa.gov.mn/exp/images/uploads/contact/ZG_logo_mongol.jpg" }
];
export const structureList = [
    {
        id: 144,
        tags: ["company"],
        name: "ХҮНС, ХӨДӨӨ АЖ АХУЙ, ХӨНГӨН ҮЙЛДВЭР",
        title: "САЙД",
        img: "https://mofa.gov.mn/exp/images/uploads/contact/ZG_logo_mongol.jpg",
        email: "mendsaikhan@mofa.gov.mn",
        phone1: "51-262204",
        phone2: "",
        address: "Баянзүрх дүүрэг, Энхтайваны өргөн чөлөө 16а, Засгийн газрын IXа байр",
        org_id: 144
    },
    {
        id: 9,
        pid: 144,
        tags: ["company"],
        name: "ХҮНС, ХӨДӨӨ АЖ АХУЙ, ХӨНГӨН ҮЙЛДВЭРИЙН ЯАМ",
        title: "ЯАМ",
        img: "https://mofa.gov.mn/exp/images/uploads/contact/ZG_logo_mongol.jpg",
        email: "mofa@mofa.gov.mn, info@mofa.gov.mn",
        phone1: "51-262271",
        address: "Баянзүрх дүүрэг, Энхтайваны өргөн чөлөө 16а, Засгийн газрын IXа байр",
        org_id: 9
    },
    {
        id: 146,
        pid: 9,
        tags: ["company"],
        name: "МАЛ ЭМНЭЛГИЙН ЕРӨНХИЙ ГАЗАР",
        title: "ЕРӨНХИЙ ГАЗАР",
        img: "http://vet.gov.mn/ebe82a4cd9bbab94dc2774df6c07aba8.png",
        email: "vet@vet.gov.mn",
        phone1: "51261635",
        phone2: "",
        address: "Баянзүрх дүүрэг, Энхтайвны өргөн чөлөө, Засгийн газрын IX байр,",
    },
    {
        id: 6,
        pid: 9,
        tags: ["sub level 0", "company"],
        name: "ЖИЖИГ, ДУНД ҮЙЛДВЭРИЙГ ГАЗАР",
        title: "ҮЙЛДВЭРИЙГ ГАЗАР",
        img: "https://smefund.gov.mn/upload/images/thumb/e91e1527dc763a5b0eb6249c4a631140.jpg",
        email: "info@sme.gov.mn",
        phone1: "7748-5555",
        phone2: "",
        address: "Баянзүрх дүүрэг, Энхтайваны өргөн чөлөө 16а, Засгийн газрын IX байр",
        org_id: 6
    },
    {
        id: 11,
        pid: 9,
        tags: ["sub level 1", "company"],
        name: "МАЛЫН УДМЫН САНГИЙН ҮНДЭСНИЙ ТӨВ",
        title: "ҮНДЭСНИЙ ТӨВ",
        img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSQZPV4JbZy8VQpDOrGC3PfDMHPlvDNVxD7REJk1F2XtFc1ZQmahks9a84tJval97v_zPo&usqp=CAU",
        email: "info@genebank.gov.mn",
        phone1: "11266059",
        phone2: "+2342 3433 5455",
        address: "Баянзүрх дүүрэг, Энхтайваны өргөн чөлөө 16а, Засгийн газрын IX байр",
        org_id: 11
    },
    {
        id: 18,
        pid: 9,
        tags: ["sub level 0", "company"],
        name: "АЙМАГ ДУНДЫН ОТРЫН БЭЛЧЭЭР АШИГЛАЛТЫН ЗАХИРГАА",
        title: "ЗАХИРГАА",
        img: "https://otor.gov.mn/backend/image/pictures/t7nXNjcU0AWwuJK.png",
        email: "info@otor.gov.mn",
        phone1: "77262638",
        phone2: "77262632",
        address: "Хан-Уул дүүрэг, Гуравдугаар хороо ажилчдын гудамж, Тариалан эрхлэлтийг дэмжих сангийн байр-2",
        org_id: 9
    },
    {
        id: 15,
        pid: 9,
        tags: ["sub level 1", "company"],
        name: "ХӨДӨӨ АЖ АХУЙГ ДЭМЖИХ САН",
        title: "САН",
        img: "http://www.mnb.mn/uploads/202106/news/thumb/95c5b1c0f7c8c92cc37b2b0422b63ec4_x3.jpg",
        email: "info@khaads.mn",
        phone1: "77238888 ",
        phone2: "",
        address: "Хан-Уул дүүрэг, 3-р хороо, Ажилчдын гудамж, Хөдөө Аж Ахуйг Дэмжих Сангийн төв байр",
        org_id: 9
    },
    {
        id: 20,
        pid: 9,
        tags: ["sub level 0", "company"],
        name: "ХАА-Н “ХАЛХ ГОЛ” ҮНДЭСНИЙ БҮТЭЭН БАЙГУУЛАЛТЫН БҮСИЙН ЗАХИРГАА",
        title: "БҮСИЙН ЗАХИРГАА",
        img: "https://www.shilendans.gov.mn/bundles/ftp/images/17b47d570a0265a76ea205229dfe17b0.png",
        email: "my@email.com",
        phone1: "+6465 6454 8787",
        phone2: "+2342 3433 5455",
        address: "T2",
        org_id: 9
    },
    {
        id: 21,
        pid: 9,
        tags: ["sub level 1", "company"],
        name: "ХХААХҮ-ИЙН СУДАЛГАА ХӨГЖЛИЙН ТӨВ",
        title: "ХӨГЖЛИЙН ТӨВ",
        img: "https://mofa.gov.mn/exp/images/uploads/contact/RDLogoApproved_en_comingsoon.png",
        email: "my@email.com",
        phone1: "+6465 6454 8787",
        phone2: "+2342 3433 5455",
        address: "T2",
        org_id: 9
    },
    {
        id: 238,
        pid: 9,
        tags: ["sub level 0", "company"],
        name: "ХӨДӨӨ АЖ АХУЙН БИРЖ ТӨХХК",
        title: "БИРЖ",
        img: "https://mofa.gov.mn/exp/images/uploads/contact/1-01.png",
        email: "info@mce.mn",
        phone1: "хоосон",
        phone2: "хоосон",
        address: "хоосон",
        org_id: 9
    },
    {
        id: 10,
        pid: 9,
        tags: ["sub level 1", "company"],
        name: "АЙМГИЙН ХҮНС, ХӨДӨӨ АЖ АХУЙН ГАЗАР",
        title: "АХУЙН ГАЗАР",
        img: "https://mofa.gov.mn/exp/images/uploads/contact/ZG_logo_mongol.jpg",
        email: "my@email.com",
        phone1: "+6465 6454 8787",
        phone2: "+2342 3433 5455",
        address: "T2",
        org_id: 9
    },
    {
        id: 19,
        pid: 146,
        tags: ["assistant", "company"],
        name: "УЛСЫН МАЛ ЭМНЭЛЭГ, АРИУН ЦЭВРИЙН ТӨВ ЛАБОРАТОРИ",
        title: "ТӨВ ЛАБОРАТОРИ",
        img: "http://vet.gov.mn/ebe82a4cd9bbab94dc2774df6c07aba8.png",
        email: "my@email.com",
        phone1: "+6465 6454 8787",
        phone2: "+2342 3433 5455",
        address: "T2",
        org_id: 9
    },
    {
        id: 13,
        pid: 146,
        tags: ["assistant", "company"],
        name: "МАЛ ЭМНЭЛГИЙН ЭМИЙН СОРИЛТ БАТАЛГААЖУУЛАЛТЫН УЛСЫН",
        title: "УЛСЫН ГАЗАР",
        img: "http://vet.gov.mn/ebe82a4cd9bbab94dc2774df6c07aba8.png",
        email: "my@email.com",
        phone1: "+6465 6454 8787",
        phone2: "+2342 3433 5455",
        address: "T2",
        org_id: 9
    },
    {
        id: 14,
        pid: 146,
        tags: ["assistant", "company"],
        name: "НИЙСЛЭЛИЙН ЭМНЭЛГИЙН ГАЗАР",
        title: "ЭМНЭЛГИЙН ГАЗАР",
        img: "http://vet.gov.mn/ebe82a4cd9bbab94dc2774df6c07aba8.png",
        email: "my@email.com",
        phone1: "+6465 6454 8787",
        phone2: "+2342 3433 5455",
        address: "T2",
        org_id: 9
    },
    {
        id: 16,
        pid: 146,
        tags: ["assistant", "company"],
        name: "АЙМГИЙН МАЛ ЭМНЭЛГИЙН ГАЗАР",
        title: "ЭМНЭЛГИЙН ГАЗАР",
        img: "http://vet.gov.mn/ebe82a4cd9bbab94dc2774df6c07aba8.png",
        email: "my@email.com",
        phone1: "+6465 6454 8787",
        phone2: "+2342 3433 5455",
        address: "T2",
        org_id: 9
    }
]

import React, { useState, useEffect, useRef } from "react"
import notify from "devextreme/ui/notify"
import BudgetService from "../../../../services/api/budget"
import {numberWithCommas } from "../../../../util"
import _ from "lodash"
import { Label, Legend, Series, Tooltip, Connector} from "devextreme-react/chart"
import PieChart from "devextreme-react/pie-chart"
import SelectBox from "devextreme-react/select-box"
import LoadPanelComponent from "../../../budget/components/loadPanel"
import { Button } from "devextreme-react"
import EmptyData from "../../../budget/components/empty"

const selectionist = [
  {
    id: 1,
    text: "1-р түвшин",
    value: "econ_category_name_1",
  },
  {
    id: 2,
    text: "2-р түвшин",
    value: "econ_category_name_2",
  },
  {
    id: 3,
    text: "3-р түвшин",
    value: "econ_category_name_3",
  },
  {
    id: 4,
    text: "4-р түвшин",
    value: "econ_category_name"
  },
]

const ExpensePivotGrid = (props) => {
  const [selector, setSelector] = useState(2)
  const [neverChangingData, setNeverChangingData] = useState([])
  const [doughnutData, setDoughnutData] = useState([])
  const [loader, setLoader] = useState(true)
  const [centerText, setTextCenter] = useState('Төсөв')
  const [backCount, setBackCount] = useState(1)
  const [titleArr, setTitleArr] = useState([])

  const loadPivotData = async () => {
    try {
      setLoader(true)
      let r = await BudgetService.getExpensePivotData(
        props.year,
        props.chosenValue,
        props.chosenMinistry,2,props.orgId
      )
      setNeverChangingData(r)
      let pp = _(r)
        .groupBy("econ_category_name_2")
        .map((row, key) => {
          return {
            name: key,
            amount: _.sumBy(row, "amount"),
          }
        })
        .value()
      setDoughnutData(pp)
    } catch (e) {
      notify(e.message, "error", 2000)
    } finally {
      setLoader(false)
    }
  }

  useEffect(() => {
    loadPivotData()
    setTextCenter('Төсөв')
    setBackCount(1)
    setSelector(2)
    setTitleArr([])
  }, [props.year, props.chosenValue, props.chosenMinistry,props.orgId])

  const cstmText = (e) => {
    return `<span style="color: #f7f7fa">${numberWithCommas(
      e.value / 1000000
    )}<br/>${(e.percent * 100).toFixed(2)}%</span>`
  }

  const customizeTooltip = (arg) => {
    return {
      html: `<span class="tooltip-series-name">${arg.argumentText}: </span> ${numberWithCommas(arg.value / 1000000)} /${(arg.percent * 100).toFixed(2)}%/`,
    }
  }

  const eventHandler = (e) => {
    setTextCenter('Төсөв')
    setSelector(e.value)
    setTitleArr([...titleArr, e.value])
    e.value === 4 ? setBackCount(4) : setBackCount(e.value-1)
    let r = _(neverChangingData)
      .groupBy(_.find(selectionist, { id: e.value }).value)
      .map((row, key) => {
        return {
          name: key,
          amount: _.sumBy(row, "amount"),
        }
      })
      .value()
    setDoughnutData(r)
  }

  if (doughnutData.length === 0 && !loader) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          family: "Segoe UI",
        }}
      >
        <EmptyData/>
      </div>
    )
  }

  const calculateTotal = (pieChart) => {
    let totalValue = pieChart.getAllSeries()[0].getAllPoints().reduce((s, p) => s + p.originalValue, 0);
    return `${numberWithCommas(totalValue / 1000000)}`;
  }

  const centerRender = (pieChart) => {
    return (
      <text textAnchor="middle" style={{ fontWeight: 600 }}>
        <tspan x="0" dy="-1em">{centerText}</tspan>
        <tspan x="0" dy="1em">{calculateTotal(pieChart)}</tspan>
      </text>
    );
  }

  const handlePieClick = (e) => {
    if(backCount < 3) {
      const arg = e.target.argument;
      setTitleArr([...titleArr, e.target.argument])
      arg === '2-Нийт зарлага ба цэвэр зээлийн дүн' ? setTextCenter('Төсөв') : setTextCenter(arg)
      const groupedData = neverChangingData.reduce((accumulator, currentItem) => {
        const categoryNames = [
          currentItem.econ_category_name_1,
          currentItem.econ_category_name_2,
          currentItem.econ_category_name_3,
          currentItem.econ_category_name,
        ];
        if (categoryNames.includes(arg)) {
          const key = categoryNames[categoryNames.indexOf(arg) + 1];
          setBackCount(categoryNames.indexOf(arg) + 1)
          if (key) {
            accumulator[key] = accumulator[key] || { name: key, amount: 0 };
            accumulator[key].amount += currentItem.amount;
          }
        }
        return accumulator
      }, {});
      const arr = Object.values(groupedData);
      setDoughnutData(arr)
    }
  }
  
  const handleBackData = () => {
    if (backCount < 3) {
      let pp = _(neverChangingData)
        .groupBy(`econ_category_name_${backCount}`)
        .map((row, key) => {
          return {
            name: key,
            amount: _.sumBy(row, "amount"),
          }
        })
        .value()
      setDoughnutData(pp)
      backCount < 3 ? setTextCenter('Төсөв') : setTextCenter(titleArr[backCount - 2])
    } else {
      const arg = titleArr[titleArr.length - 2]
      if(_.isNumber(arg) && arg < 4) {
        let pp = _(neverChangingData)
        .groupBy(`econ_category_name_${arg}`)
        .map((row, key) => {
          return {
            name: key,
            amount: _.sumBy(row, "amount"),
          }
        })
        .value()
      setDoughnutData(pp)
      setTextCenter('Төсөв')
      } else {
        const groupedData = neverChangingData.reduce((accumulator, currentItem) => {
          const categoryNames = [
            currentItem.econ_category_name_1,
            currentItem.econ_category_name_2,
            currentItem.econ_category_name_3,
            currentItem.econ_category_name,
          ];
          if (categoryNames.includes(arg)) {
            const key = categoryNames[categoryNames.indexOf(arg) + 1];
            if (key) {
              accumulator[key] = accumulator[key] || { name: key, amount: 0 };
              accumulator[key].amount += currentItem.amount;
            }
          }
          return accumulator
        }, {});
        const arr = Object.values(groupedData);
        setDoughnutData(arr)
        backCount > 0 ? setBackCount(backCount - 1) : setBackCount(0)
        backCount < 3 ? setTextCenter('Төсөв') : setTextCenter(titleArr[titleArr.length - 2])
      }
    }
  }
  
  return (
    <div
      id="load10"
      style={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
        justifyContent: "space-evenly",
        alignItems: "center",
        width: '100%',
        height: '100%'
      }}
    >
      <LoadPanelComponent position={{ of: "#load10" }} visible={loader} />
      <div style={{ position: 'absolute', display: 'flex', justifyContent: 'space-between', top: 0, width: '100%', padding: props.firstChartVisible === undefined ? '50px 20px 0px 20px' : '10px'}}>
        <Button className="button-container"
          text={`Буцах`}
          icon="chevronleft"
          visible={true}
          disabled={!titleArr.length ? true : backCount === 0 ? true : selector === 4 ? true : false}
          onClick={handleBackData}
          style={{ height: '2rem'}}
        />
        <SelectBox
          items={selectionist}
          label={'ЭЗА'}
          width={'10rem'}
          valueExpr="id"
          displayExpr="text"
          onValueChanged={eventHandler}
          defaultValue={selector}
          style={{ borderRadius: 10 }}
        />
      </div>
      <div style={{ width: '100%' }}>
        <PieChart
          type="doughnut"
          dataSource={doughnutData}
          resolveLabelOverlapping="hide"
          onPointClick={handlePieClick}
          onLegendClick={({ points }) => points[0].isVisible() ? points[0].hide() : points[0].show()}
          centerRender={centerRender}
        >
          <Series argumentField="name" valueField="amount" border={{ dashStyle: 'solid', width: 2, visible: true }}>
            <Label visible={true} customizeText={cstmText} >
              <Connector visible={true} width={0.5}/>
            </Label>
          </Series>
          <Legend
            orientation="horizontal"
            itemTextPosition="right"
            horizontalAlignment="center"
            verticalAlignment="bottom"
            columnItemSpacing={10}
            columnCount={4}
            rowItemSpacing={10}
            paddingLeftRight={0}
            paddingTopBottom={0}
            customizeItems={(items) => items.slice(0, 8)}
            customizeText={(items) => items.pointName.length > 50 ? items.pointName.slice(0, 50) + `...` : items.pointName}
          />
          <Tooltip
            zIndex={10000}
            enabled={true}
            customizeTooltip={customizeTooltip}
            font={{ color: '#fff' }} color="#555"
            cornerRadius={6} 
            border={{visible: false}}
            location="edge"
          ></Tooltip>
        </PieChart>
      </div>
    </div>
  )
}

export default ExpensePivotGrid

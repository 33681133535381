import React, {useContext, useEffect, useState, useRef} from 'react';
import 'devextreme-react/text-area';
import DataGrid, {
    Editing,
    Pager,
    Paging,
    Column,
    FilterRow,
    RequiredRule,
    PatternRule,
    Lookup, Export, Form, Item, Popup, GroupPanel, SearchPanel, Grouping
} from 'devextreme-react/data-grid';
import {Workbook} from 'exceljs';
import {saveAs} from 'file-saver-es';
import {exportDataGrid} from 'devextreme/excel_exporter';
import {LoadPanel} from "devextreme-react/load-panel";
import {UserContext} from "../../../hooks/UserContext";
import _, {concat} from "lodash";
import fcsService from "../../../services/api/fcs";
import Button from "devextreme-react/button";
import notify from "devextreme/ui/notify";
import LoadPanelComponent from '../../../components/LoadPanelComponent';

function FaReg(props) {
    const userListPosition = {of: '#usersList'}
    const {user} = useContext(UserContext);
    const [dataSource, setDataSource] = useState([]);
    const [loader, setLoader] = useState(false);
    const myBabyRef = useRef(null);
    const [dataFcsUnit, setFcsUnit] = useState([]);
    const [faType, setFaType] = useState([]);
    const [selectedFaType, setSelectedFaType] = useState([]);
    const calculateType=[{type:0,descr:'Элэгдэл тооцохгүй'},{type:1,descr:'Шулуун шугамын арга'},{type:2,descr:'Үлдэгдлийг бууруулах арга'},{type:3,descr:'Бүтээгдэхүүний нэгжийн арга'}]
    useEffect(() => {
        fcsFaList();
    }, [])

    const fcsFaList = async () => {
        try {
            setLoader(true);
            const result = await fcsService.getAllFcsFa();
            setDataSource(result)
            const fcsUnit = await fcsService.getAllFcsUnit();
            setFcsUnit(fcsUnit);
            const fcsFaType = await fcsService.getAllFcsFaType();
            setFaType(fcsFaType);
            return setLoader(false)
        } catch (e) {
            console.error(e);
        }
    }

    const onEditorPreparing = (e) => {
        if (e.parentType === "dataRow" && e.dataField !== "password") {
            return;
        }

        if (e.row && e.row.isNewRow) {
            e.editorOptions.visible = true;
        }
        if (e.row && !e.row.isNewRow) {
            e.editorOptions.visible = false;
        }
    }
    const customAddRow = (grid) => {
        grid.current.instance.addRow();
    }
    const onInitNewRow = (e) => {
    }
    /*    const setInvCat = (newData, value) => {
            newData.inv_category_id = value;
            let result = _.filter(dataFcsInvCat, {id: value});
            newData.acctcode = result[0].acct_code;
        }*/
    const setFa = async (e) => {
        if (e.changes && e.changes.length !== 0) {
            if (e.changes[0].type === "update") {
                try {
                    e.changes[0].data.updated_user_id = user.id;
                    await fcsService.editFcsFa(e.changes[0].data);
                    await fcsFaList();
                    notify('Амжилттай хадгалагдлаа', "success", 2000);
                } catch (e) {
                    notify('Засварлалтыг хадгалах явцад алдаа гарлаа', "error", 2000);
                }
            } else if (e.changes[0].type === "insert") {
                try {
                    const result =  _.filter(faType, {id: e.changes[0].data.fatype_id});
                    result.map(e => {
                        e.fa_number = Number(e.fa_number) + 1
                    })
                    delete e.changes[0].data.id;
                    e.changes[0].data.created_user_id = user.id;
                    e.changes[0].data.org_id = user.org_id;
                    await fcsService.addFcsFa(e.changes[0].data);
                    await fcsService.editFcsFaType(result[0]);
                    await fcsFaList();
                    notify('Амжилттай үүслээ', "success", 2000);
                } catch (e) {
                    notify('Шинээр үүсэх явцад алдаа гарлаа', "error", 2000);
                }

            }

        }

    }
    const onExporting = (e) => {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Main sheet');

        exportDataGrid({
            component: e.component,
            worksheet,
            autoFilterEnabled: true,
        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer], {type: 'application/octet-stream'}), 'FaReg.xlsx');
            });
        });
        e.cancel = true;
    }
    const setStateValue = (newData, value) => {
        newData.fatype_id = value;
        let result = _.filter(faType, {id: value});
        // console.log('result ', result[0]);
        newData.dep_acct = result[0].dep_acct;
        newData.acct_code = result[0].acct_code;
        newData.acder_acct = result[0].acder_acct;
        newData.fa_number =  Number(result[0].fa_number) + 1
    }
    const setDepAcct = () => {
        return selectedFaType.dep_acct;
    }
    const style={
        text: {
            color: "#2E4765", fontWeight: "600", fontSize: "18px"
        },
        box: {
            border: "none", backgroundColor: "#F6F6F6", borderRadius: ".5rem", padding: ".25rem .15rem", width: "100%"
        }
    }
    return (
        <div style={{padding: ".5rem"}}>
            <LoadPanelComponent
                shadingColor="rgba(0,0,0,0.4)"
                position={{of: '#password'}}
                message=''
                showPane={false}
            />
            <div className="col-12 row" style={{justifyContent: "center", gap: '2rem', padding: '5px 15px', marginBottom: 20}}>
            <div> <h4 style={{marginLeft: 20, textTransform: "uppercase", marginTop: 5,...style.text}}>
                    Үндсэн хөрөнгийн бүртгэл
                </h4>
                </div>
               
                {/* <Button icon="add" text="Нэмэх" onClick={() => customAddRow(myBabyRef)} width={110} type="default"
                        style={{alignment: "center", borderRadius: '7px'}}/> */}
            </div>
            <LoadPanelComponent
                shadingColor="rgba(0,0,0,0.4)"
                position={userListPosition}
                visible={loader}
                showPane={false}
                message=''
            />

                <div className="card-body">

                    <DataGrid
                        onEditorPreparing={onEditorPreparing}
                        ref={myBabyRef}
                        dataSource={dataSource}
                        onSaved={setFa}
                        keyExpr={null}
                        wordWrapEnabled={true}
                        onInitNewRow={onInitNewRow}
                        allowColumnResizing={true}
                        onExporting={onExporting}>

                        >
                        <GroupPanel visible={true}/>
                        <SearchPanel visible={true}/>
                        <Grouping autoExpandAll={true}/>
                        <FilterRow
                            visible={true}
                        />
                        <Pager
                            showPageSizeSelector={true}
                            // showInfo={true}
                            showNavigationButtons={true}
                        />

                        <Column
                            cssClass="custom"
                            dataField="fatype_id"
                            caption="Ангилал"
                            groupIndex={0}
                            minWidth={70}
                            alignment="center"
                            allowEditing={true}
                            allowFiltering={true}
                            setCellValue={setStateValue}
                        >
                            <RequiredRule
                                message='Ангилал оруулна уу'
                            />
                            <Lookup
                                valueExpr="id"
                                displayExpr={(item) => {
                                    return item && `${item.fatype} - ${item.name}`
                                }}
                                dataSource={faType}


                            />
                        </Column>

                        <Column
                            dataField="fa_number"
                            allowEditing={false}
                            caption="Хөрөнгийн дугаар"
                            allowEditing={false}
                            minWidth={90}
                            alignment="center"
                            cssClass="custom"
                            allowFiltering={true}
                        >
                            <RequiredRule
                                message='ҮХ-ын дугаар оруулна уу'
                            />
                        </Column>
                        <Column
                            cssClass="custom"
                            dataField="name"
                            caption="Үндсэн хөрөнгийн нэр"
                            minWidth={100}
                            alignment="center"
                            allowEditing={true}
                            allowFiltering={true}
                        >
                            <RequiredRule
                                message='Үндсэн хөрөнгийн нэр оруулна уу'
                            />
                        </Column>

                        <Column
                            visible={false}
                            dataField="acct_code"
                            caption="Үндсэн хөрөнгийн данс"
                            allowEditing={false}
                            minWidth={100}
                            alignment="center"
                            cssClass="custom"
                            allowFiltering={true}
                        >
                        </Column>
                        <Column
                            visible={false}
                            dataField="acder_acct"
                            caption="Хуримтлагдсан элэгдлийн данс"
                            allowEditing={false}
                            minWidth={100}
                            alignment="center"
                            cssClass="custom"
                            allowFiltering={true}
                        >
                        </Column>
                        <Column
                            visible={false}
                            dataField="dep_acct"
                            caption="Элэгдлийн зардлын данс"
                            allowEditing={false}
                            minWidth={100}
                            alignment="center"
                            cssClass="custom"
                            allowFiltering={true}
                        >
                        </Column>

                        <Column
                            cssClass="custom"
                            dataField="unit_id"
                            caption="Хэмжих нэгж"
                            minWidth={70}
                            alignment="center"
                            allowEditing={true}
                            allowFiltering={true}
                        >
                            <RequiredRule
                                message='Хэмжих нэгж оруулна уу'
                            />
                            <Lookup
                                valueExpr="id"
                                displayExpr={(item) => {
                                    return item && `${item.code} - ${item.name}`
                                }}
                                dataSource={dataFcsUnit}

                            />
                        </Column>
                        <Column
                            cssClass="custom"
                            dataField="date_acq"
                            caption="Ашиглалтанд авсан огноо"
                            allowEditing={true}
                            width={70}
                            alignment="center"
                            dataType="date"
                        >
                            <RequiredRule
                                message='Огноо оруулна уу'
                            />
                        </Column>
                        <Column
                            cssClass="custom"
                            dataField="rev_life"
                            caption="Ашиглах жил"
                            allowEditing={true}
                            width={70}
                            alignment="center"
                            allowFiltering={true}
                        >
                            <RequiredRule
                                message='Ашиглах жил оруулна уу'
                            />
                            <PatternRule pattern={/^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/}
                                         message='зөвхөн тоо оруулна уу!'/>
                        </Column>
                        <Column
                            cssClass="custom"
                            dataField="dep_rate"
                            caption="Сарын элэгдлийн хувь"
                            width={70}
                            alignment="center"
                            allowEditing={true}
                            allowFiltering={true}
                        >
                            <RequiredRule
                                message='Сарын элэгдлийн хувь оруулна уу'
                            />
                            <PatternRule pattern={/^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/}
                                         message='зөвхөн тоо оруулна уу!'/>
                        </Column>
                        <Column
                            cssClass="custom"
                            dataField="manuf"
                            caption="Үйлдвэрлэгч"
                            allowEditing={true}
                            minWidth={100}
                            alignment="center"
                            allowFiltering={true}
                        >
                            <RequiredRule
                                message='Үйлдвэрлэгч оруулна уу'
                            />
                        </Column>

                        <Column
                            cssClass="custom"
                            dataField="calculate_type"
                            caption="элэгдэл бодох арга"
                            minWidth={70}
                            alignment="center"
                            allowEditing={true}
                            allowFiltering={true}
                        >
                            <RequiredRule
                                message='элэгдэл бодох арга оруулах'
                            />
                            <Lookup
                                valueExpr="type"
                                displayExpr={(item) => {
                                    return item && `${item.type} - ${item.descr}`
                                }}
                                dataSource={calculateType}

                            />
                        </Column>



                        <Column
                            cssClass="custom"
                            dataField="location"
                            caption="Байршил"
                            allowEditing={true}
                            minWidth={90}
                            alignment="center"
                            allowFiltering={true}
                        >
                            <RequiredRule
                                message='Байршил оруулна уу'
                            />
                        </Column>

                        <Column
                            cssClass="custom"
                            dataField="user_dept"
                            caption="Цех, тасаг, хэсгийн нэр"
                            minWidth={100}
                            alignment="center"
                            allowEditing={true}
                            allowFiltering={true}
                        >
                            <RequiredRule
                                message='Цех, тасаг, хэсгийн нэр оруулна уу'
                            />
                        </Column>
                        <Column
                            cssClass="custom"
                            dataField="username"
                            caption="Эзэмшигч"
                            minWidth={100}
                            alignment="center"
                            allowEditing={true}
                            allowFiltering={true}
                        >
                            <RequiredRule
                                message='Эзэмшигч оруулна уу'
                            />
                        </Column>


                        <Editing
                            mode="popup"
                            allowUpdating={true}
                            allowDeleting={false}
                            allowAdding = {true}
                            useIcons={true}
                            texts={{
                                cancelAllChanges: 'Болих бүгд',
                                cancelRowChanges: 'Болих',
                                confirmDeleteMessage: 'Энэ үндсэн хөрөнгийн бүртгэл мэдээллийг устгах уу?',
                                confirmDeleteTitle: 'Баталгаажуулах',
                                deleteRow: 'Устгах',
                                editRow: 'Өөрчлөх',
                                saveAllChanges: 'Хадгалах бүгд',
                                saveRowChanges: 'Хадгалах',
                                undeleteRow: 'Буцаах',
                                validationCancelChanges: 'Баталгаажуулах болих',
                            }}
                        >
                            <Popup title="Үндсэн хөрөнгийн бүртгэл" showTitle={true} width={1000} height={500}/>
                            <Form>
                                <Item itemType="group" colCount={2} colSpan={2}>
                                    <Item dataField="fatype_id"/>
                                    <Item dataField="fa_number"/>
                                    <Item dataField="name"/>

                                    <Item dataField="acct_code"/>
                                    <Item dataField="dep_acct"/>
                                    <Item dataField="acder_acct"/>


                                    <Item dataField="unit_id"/>
                                    <Item dataField="date_acq"/>
                                    <Item dataField="rev_life"/>
                                    <Item dataField="dep_rate"/>
                                    <Item dataField="calculate_type"/>

                                    <Item dataField="manuf"/>
                                    <Item dataField="location"/>
                                    <Item dataField="user_dept"/>
                                    <Item dataField="username"/>
                                </Item>
                            </Form>
                        </Editing>
                        <Paging enabled={true}/>
                        <Export enabled={true} allowExportSelectedData={false}/>
                    </DataGrid>
                </div>

        </div>
    )
}

export default FaReg;

import request from '../../shared/lib/request'

const getNotificationUsers = (isSystem, page,limit) => {
    /*
    isSystem = 2 then all
    isSystem = 1 then system
    isSystem = 0 then user
     */
    return request({
        url: `/notificationUsers?isSystem=${isSystem}&page=${page}&limit=${limit}`,
        method: 'GET'
    })
}

const getByPopupNotificationUsers = () => {
    return request({
        url: '/notificationUsers/popup',
        method: 'GET'
    })
}

const getByUnseenNotificationUsers = () => {
    return request({
        url: '/notificationUsers?is_seen=0',
        method: 'GET'
    })
}

const getByIdNotificationUsers = (id) => {
    return request({
        url: `/notificationUsers/${id}`,
        method: 'GET'
    })
}

const addNotificationUser = (body) => {
    return request({
        url: '/notificationUsers/',
        method: 'POST',
        data: body
    })
}

const updateNotificationUser = (id, body) => {
    return request({
        url: `/notificationUsers/${id}`,
        method: 'PUT',
        data: body
    })
}

const addScheduleRequestNotification = (body) => {
    return request({
        url:`/notificationUsers/schedule-request`,
        method: 'POST',
        data: body
    })
}

const createRequestNotification = (body) => {
    return request({
        url:`/notificationUsers/request-notification`,
        method: 'POST',
        data: body
    })
}

const proposalNotification = (body) => {
    return request({
        url:`/notificationUsers/proposal-notification`,
        method: 'POST',
        data: body
    })
}


const notificationUserService = {
    getNotificationUsers,
    getByPopupNotificationUsers,
    getByUnseenNotificationUsers,
    getByIdNotificationUsers,
    updateNotificationUser,
    addNotificationUser,
    addScheduleRequestNotification,
    createRequestNotification,
    proposalNotification,
}

export default notificationUserService;
import React, { useState, useEffect, useRef } from "react";
import notify from "devextreme/ui/notify";
import BudgetService from "../../../../services/api/budget";
import PivotGridDataSource from "devextreme/ui/pivot_grid/data_source";
import { PivotGrid, Toolbar } from "devextreme-react";
import { Export } from "devextreme-react/pivot-grid";
import { formatDate, numberWithCommas } from "../../../../util";
import '../../static/budget.css'
import LoadPanelComponent from "../../../budget/components/loadPanel";
import { Popup } from "devextreme-react/popup";
import { budgetPalettes } from "../../../../util";
import _ from "lodash";
import { Workbook } from 'exceljs';
import { saveAs } from "file-saver-es";
import { exportPivotGrid } from "devextreme/excel_exporter";
import EmptyData from "../../../budget/components/empty";

const AgendaPivotGrid = (props) => {
    const [pivotSource, setPivotSource] = useState([]);
    const [neverChangingData, setNeverChangingData] = useState([]);
    const [loader, setLoader] = useState(false)
    const [data, setData] = useState()
    let _pivotRef = useRef(null);
    const [tier, setTier] = useState(1);
    const [searchText, setSearchText] = useState('Нийт зарлага ба цэвэр зээлийн дүн');
    const [titleHeaders, setTitleHeaders] = useState([]);
    const windowSize = useRef([window.innerWidth, window.innerHeight]);

    const loadPivotData = async () => {
        try {
            setLoader(true);
            let r = await BudgetService.getAgendaPivotData(props.year, props.chosenValue);
            console.log(r);
            setData(r)
            setNeverChangingData(r);
            const dataSource = new PivotGridDataSource({
                fields: [
                    {
                        caption: 'parent_agenda',
                        dataField: 'parent_agenda_name',
                        area: 'row',
                        width: windowSize.current[0] < 920 ? 'auto' : 500,
                        expanded: true,
                        sortBy: 'displayText',
                        sortOrder: 'asc',
                    },
                    {
                        caption: 'agenda',
                        dataField: 'agenda_name',
                        area: 'row',
                        width: windowSize.current[0] < 920 ? 'auto' : 500,
                        expanded: false,
                        sortBy: 'displayText',
                        sortOrder: 'asc',
                    },
                    {
                        caption: 'parent_event',
                        dataField: 'parent_event_name',
                        area: 'row',
                        width: windowSize.current[0] < 920 ? 'auto' : 500,
                        sortBy: 'displayText',
                        sortOrder: 'asc'
                    },
                    {
                        caption: 'event',
                        dataField: 'event_name',
                        area: 'row',
                        width: windowSize.current[0] < 920 ? 'auto' : 500,
                        sortBy: 'displayText',
                        sortOrder: 'asc'
                    },
                    {
                        caption: 'econ_1',
                        dataField: 'econ_category_name_1',
                        area: 'row',
                        width: windowSize.current[0] < 920 ? 'auto' : 500,
                        expanded: true,
                        sortBy: 'displayText',
                        sortOrder: 'asc'
                    },
                    {
                        caption: 'econ_2',
                        dataField: 'econ_category_name_2',
                        width: windowSize.current[0] < 920 ? 'auto' : 500,
                        area: 'row',
                        expanded: false,
                        sortBy: 'displayText',
                        sortOrder: 'asc'
                    }, {
                        caption: 'econ_3',
                        dataField: 'econ_category_name_3',
                        width: windowSize.current[0] < 920 ? 'auto' : 500,
                        area: 'row',
                        sortBy: 'displayText',
                        sortOrder: 'asc'
                    }, {
                        caption: 'econ_4',
                        dataField: 'econ_category_name_4',
                        width: windowSize.current[0] < 920 ? 'auto' : 500,
                        area: 'row',
                        sortBy: 'displayText',
                        sortOrder: 'asc'
                    }, {
                        caption: 'econ_6',
                        dataField: 'econ_category_name',
                        width: windowSize.current[0] < 920 ? 'auto' : 500,
                        area: 'row',
                        sortBy: 'displayText',
                        sortOrder: 'asc'
                    },
                    {
                        caption: 'Төсөв',
                        dataField: 'amount',
                        dataType: 'number',
                        summaryType: 'sum',
                        customizeText: function (e) {
                            return `${numberWithCommas(e.value / 1000000)}`
                        },
                        area: 'data',
                    },
                    {
                        caption: 'Ангилалд эзлэх хувь',
                        dataField: 'amount',
                        dataType: 'number',
                        width: 30,
                        summaryType: 'sum',
                        customizeText: function (e) {
                            return `${(e.value * 100).toFixed(2)}`
                        },
                        summaryDisplayMode: 'percentOfColumnTotal',
                        area: 'data',
                    },
                    {
                        caption: 'Нийт төсөвд эзлэх хувь',
                        dataField: 'amount',
                        dataType: 'number',
                        width: 30,
                        summaryType: 'sum',
                        customizeText: function (e) {
                            return `${(e.value * 100).toFixed(2)}`
                        },
                        summaryDisplayMode: 'percentOfColumnGrandTotal',
                        area: 'data',
                    }, 
                    {
                        caption: 'Гүйцэтгэл',
                        dataField: 'guitsetgel',
                        dataType: 'number',
                        summaryType: 'sum',
                        customizeText: function (e) {
                            return `${numberWithCommas(e.value/1000000)}`
                        },
                        area: 'data'
                    },
                    {
                        caption: 'Хувь',
                        dataField: 'guitsetgel',
                        dataType: 'number',
                        summaryType: 'sum',
                        customizeText: function (e) {
                            return `${(e.value * 100).toFixed(2)}`
                        },
                        summaryDisplayMode: 'percentOfColumnTotal',
                        area: 'data'
                    }
                ],
                store: r
            });
            setPivotSource(dataSource);
            setLoader(false);
        } catch (e) {
            setLoader(false);
            notify(e.message, "error", 2000);
        }
    }

    useEffect(() => {
        loadPivotData();
    }, [props.year, props.chosenValue, props.chosenMinistry, props.econType])

    const cellClickHandler = (event) => {
        if (event.cell.columnType === "GT") {
            setTier(event.cell.rowPath.length);
            setSearchText(event.cell.rowPath[event.cell.rowPath.length - 1]);
            setTitleHeaders(event.cell.rowPath);
        }
    }

    const onCellPrepared = (e) => {
        if (e.cell.text !== undefined && e.cell.text.substr(6, 1) === '-' || e.cell.text === "") {
            e.cell.expanded = true;
            let cell = e.cellElement.querySelector(".dx-expand-icon-container");
            if (cell) cell.innerHTML = "";
        }

        if (e.area === 'row') {
            e.cellElement.style.fontFamily = 'Segoe UI'
            e.cellElement.style.fontWeight = 'semibold'
            e.cellElement.style.color = "#15186A"
            e.cellElement.style.backgroundColor = "#EEF0F4"
        }
    }

    const onExportingPivot = (e) => {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Хөтөлбөр, арга хэмжээ-Эдийн засгийн ангиллаар');

        worksheet.columns = [
            { width: 30 }, { width: 20 }, { width: 30 }, { width: 30 }, { width: 30 }, { width: 30 },
        ];

        exportPivotGrid({
            component: e.component,
            worksheet,
            topLeftCell: { row: 4, column: 1 },
            mergeRowFieldValues: true,
            keepColumnWidths: true,
            customizeCell: ({ pivotCell, excelCell }) => {
                const borderStyle = { style: 'thin', color: { argb: 'FF7E7E7E' } };
                excelCell.border = {
                    bottom: borderStyle,
                    left: borderStyle,
                    right: borderStyle,
                    top: borderStyle,
                };
            },
        }).then((cellRange) => {
            // Header
            const headerRow = worksheet.getRow(2);
            headerRow.height = 30;

            const columnFromIndex = worksheet.views[0].xSplit;
            const columnToIndex = columnFromIndex;
            worksheet.mergeCells(2, columnFromIndex, 2, columnToIndex);

            const headerCell = headerRow.getCell(columnFromIndex);
            headerCell.value = 'Төсвийн тайлан /Эдийн засгийн ангиллаар/';
            headerCell.font = { name: 'Calibri', size: 16, bold: true };
            headerCell.alignment = { horizontal: 'left', vertical: 'middle', wrapText: true };
        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'OG-Эдийн засгийн ангиллаар-' + formatDate(new Date()) + ".xlsx");
            });
        });
        e.cancel = true;
    };
    return (
        <div id="load9" className="expenseOnlyPivotGrid">
            <LoadPanelComponent position={{ of: '#load9' }} visible={loader} />
            {(data && data.length > 0 && !loader) ? (
                <PivotGrid
                    ref={_pivotRef}
                    style={{ fontFamily: "Segoe UI" }}
                    dataSource={pivotSource}
                    allowSorting={true}
                    rowHeaderLayout={'tree'}
                    wordWrapEnabled={true}
                    showColumnTotals={false}
                    showRowGrandTotals={false}
                    showRowTotals={false}
                    texts={{
                        noData: 'Дата байхгүй байна.',
                        collapseAll: 'Бүгд хураах',
                        expandAll: 'Бүгд дэлгэх',
                        showFieldChooser: 'Талбар сонголт харах',
                        exportToExcel: 'Excel файл татах',
                        grandTotal: 'Төсөв, гүйцэтгэл',
                        total: '{0}'
                    }}
                    onCellPrepared={onCellPrepared}
                    onCellClick={cellClickHandler}
                    onExporting={onExportingPivot}
                    fieldChooser={false}
                >
                    <Export enabled={true} />
                </PivotGrid>
            ) : (!loader ?
                <div style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    family: "Segoe UI"
                }}>
                    <EmptyData />
                </div> : null)
            }
        </div>
    )
}

export default AgendaPivotGrid;





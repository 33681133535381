import request from '../../shared/lib/request'

const getFcsBbTran = (year,month,trtype) => {


    return request({

        url: '/fcs/getFcsBbTran/'+ year + '/' + month+ '/'+ trtype,
        method: 'GET'
    })
}
const getFcsBbTranReport = (year,month,bbclass,txnType) => {
    return request({
        url: '/fcs/getFcsBbTranReport/'+ year + '/' + month+ '/'+bbclass + '/' + txnType,
        method: 'GET'
    })
}
const addFcsBbTran = (body) => {
    return request({
        url: '/fcs/addFcsBbTran',
        method: 'POST',
        data: body,
    })
}

const editFcsBbTran = (body) => {
    return request({
        url: `/fcs/editFcsBbTran`,
        method: 'POST',
        data: body,
    })
}
const removeFcsBbTran = (id) => {
    return request({
        url: '/fcs/removeFcsBbTran/'+id,
        method: 'POST'
    })
}
const getAllFcsAptran = () => {
    return request({
        url: '/fcs/getAllFcsAptran',
        method: 'GET',
    })
}

const addFcsAptran = (body) => {
    return request({
        url: '/fcs/addFcsAptran',
        method: 'POST',
        data: body,
    })
}
const editFcsAptran = (body) => {
    return request({
        url: `/fcs/editFcsAptran`,
        method: 'POST',
        data: body,
    })
}
const removeFcsAptran = (id) => {
    return request({
        url: '/fcs/removeFcsAptran/'+id,
        method: 'POST'
    })
}


const getAllFcsApSelect = () => {
    return request({
        url: '/fcs/getAllFcsApSelect',
        method: 'GET',
    })
}

const addFcsApSelect = (body) => {
    return request({
        url: '/fcs/addFcsApSelect',
        method: 'POST',
        data: body,
    })
}
const editFcsApSelect = (body) => {
    return request({
        url: `/fcs/editFcsApSelect`,
        method: 'POST',
        data: body,
    })
}
const removeFcsApSelect = (id) => {
    return request({
        url: '/fcs/removeFcsApSelect/'+id,
        method: 'POST'
    })
}

const getFcsApSelectReport = (year,month,apacct) => {
    return request({
        url: '/fcs/getFcsApSelectReport/'+ year + '/' + month+ '/' + apacct,
        method: 'GET',
    })
}



const getAllFcsArSelect = () => {
    return request({
        url: '/fcs/getAllFcsArSelect',
        method: 'GET',
    })
}

const addFcsArSelect = (body) => {
    return request({
        url: '/fcs/addFcsArSelect',
        method: 'POST',
        data: body,
    })
}
const editFcsArSelect = (body) => {
    return request({
        url: `/fcs/editFcsArSelect`,
        method: 'POST',
        data: body,
    })
}
const removeFcsArSelect = (id) => {
    return request({
        url: '/fcs/removeFcsArSelect/'+id,
        method: 'POST'
    })
}

const getFcsArSelectReport = (year,month,acctdb) => {
    return request({
        url: '/fcs/getFcsArSelectReport/'+ year + '/' + month+ '/' + acctdb,
        method: 'GET',
    })
}







const getAllFcsChart = () => {
    return request({
        url: '/fcs/getAllFcsChart',
        method: 'GET',
    })
}
const getAllFcsChartEdit = () => {
    return request({
        url: '/fcs/getAllFcsChartEdit',
        method: 'GET',
    })
}

const getAllFcsSt4Desc = () => {
    return request({
        url: '/fcs/getAllFcsSt4Desc',
        method: 'GET',
    })
}



const getFcsChart = (c1,c2,c3,c4,c5) => {
    return request({
        url: '/fcs/getFcsChart/'+c1+'/'+c2+'/'+c3+'/'+c4+'/'+c5,
        method: 'GET',
    })
}



const getAllFcsCustOrgn = () => {
    return request({
        url: '/fcs/getAllFcsCustOrgn',
        method: 'GET',
    })
}
const getFcsCustOrgn = (orgCode) => {
    return request({
        url: '/fcs/getAllFcsCustOrgn/'+'?orgcode='+orgCode,
        method: 'GET',
    })
}



const getAllFcsApClass = (aptype) => {
    return request({
        url: '/fcs/getAllFcsApClass/'+aptype,
        method: 'GET',
    })
}
const getAllFcsMonth = (jr) => {
    return request({
        url: '/fcs/getAllFcsMonth/'+jr,
        method: 'GET',
    })
}
const editFcsMonth = (body) => {
    return request({
        url: `/fcs/editFcsMonth`,
        method: 'POST',
        data: body,
    })
}
const getAllFcsBBClass = () => {
    return request({
        url: '/fcs/getAllFcsBBClass',
        method: 'GET',
    })
}
const editBBClass = (body) => {
    return request({
        url: `/fcs/editBBClass`,
        method: 'POST',
        data: body,
    })
}
const getAllFcsUnit = () => {
    return request({
        url: '/fcs/getAllFcsUnit',
        method: 'GET',
    })
}
const getAllFcsInvCategory = () => {
    return request({
        url: '/fcs/getAllFcsInvCategory',
        method: 'GET',
    })
}
const getAllFcsInventory = () => {
    return request({
        url: '/fcs/getAllFcsInventory',
        method: 'GET',
    })
}


const addFcsInvCategory = (body) => {
    return request({
        url: '/fcs/addFcsInvCategory',
        method: 'POST',
        data: body,
    })
}
const editFcsInvCategory = (body) => {
    return request({
        url: '/fcs/editFcsInvCategory',
        method: 'POST',
        data: body,
    })
}
const removeFcsInvCategory = (id) => {
    return request({
        url: '/fcs/removeFcsInvCategory/'+id,
        method: 'POST'
    })
}


const addFcsInventory = (body) => {
    return request({
        url: '/fcs/addFcsInventory',
        method: 'POST',
        data: body,
    })
}
const editFcsInventory = (body) => {
    return request({
        url: '/fcs/editFcsInventory',
        method: 'POST',
        data: body,
    })
}
const getAllFcsLocation = () => {
    return request({
        url: '/fcs/getAllFcsLocation',
        method: 'GET',
    })
}
const getAllFcsInOrLin = (year,month) => {
    return request({
        url: '/fcs/getAllFcsInOrLin/'+ year + '/' + month,
        method: 'GET',
    })
}
const addFcsInOrLin = (body) => {
    return request({
        url: '/fcs/addFcsInOrLin',
        method: 'POST',
        data: body,
    })
}
const editFcsInOrLin = (body) => {
    return request({
        url: '/fcs/editFcsInOrLin',
        method: 'POST',
        data: body,
    })
}
const getAllFcsInvRecp = (txn_type,year,month) => {
    return request({
        url: '/fcs/getAllFcsInvRecp/'+txn_type+'/' + year + '/' + month,
        method: 'GET',
    })
}
const addFcsInvRecp = (body) => {
    return request({
        url: '/fcs/addFcsInvRecp',
        method: 'POST',
        data: body,
    })
}
const editFcsInvRecp = (body) => {
    return request({
        url: '/fcs/editFcsInvRecp',
        method: 'POST',
        data: body,
    })
}
const removeFcsInOrLin = (id) => {
    return request({
        url: '/fcs/removeFcsInOrLin/'+id,
        method: 'GET'
    })
}

const getFcsCurrentBalanceInv = (loctnId) => {
    return request({
        url: '/fcs/getFcsCurrentBalanceInv/'+loctnId,
        method: 'GET',
    })
}


const getFcsInvissBalance = (loctnId,year,month) => {
    return request({
        url: '/fcs/getFcsInvissBalance/'+loctnId +'/'+ year+ '/'+month,
        method: 'GET',
    })
}

const getFcsInvissBalanceFirst = (loctnId,year,month) => {
    return request({
        url: '/fcs/getFcsInvissBalanceFirst/'+loctnId +'/'+ year+ '/'+month,
        method: 'GET',
    })
}





const getAllFcsInvIss = (year,month) => {
    return request({
        url: '/fcs/getAllFcsInvIss/'+ year + '/' + month,
        method: 'GET',
    })
}
const addFcsInvIss = (body) => {
    return request({
        url: '/fcs/addFcsInvIss',
        method: 'POST',
        data: body,
    })
}
const editFcsInvIss = (body) => {
    return request({
        url: '/fcs/editFcsInvIss',
        method: 'POST',
        data: body,
    })
}
const removeFcsInvIss = (id) => {
    return request({
        url: '/fcs/removeFcsInvIss/'+id,
        method: 'GET'
    })
}
const getFcsInvReportByLocation = (pYear, pMonth) => {
    return request({
        url: '/fcs/getFcsInvReportByLocation/' + pYear + '/' + pMonth,
        method: 'GET'
    })
}
const getFcsInvReportByLocationDetail = (pYear, pMonth,loctnId) => {
    return request({
        url: '/fcs/getFcsInvReportByLocationDetail/' + pYear + '/' + pMonth + '/'+loctnId,
        method: 'GET'
    })
}
const getFcsGlMonthReport = (glType) => {
    return request({
        url: '/fcs/getFcsGlMonthReport/' + glType,
        method: 'GET',
    })
}
const getFcsInvBalMonth = (pyear,pmonth) => {
    //  url: '/fcs/getFcsInvBalMonth',

    return request({
        url: '/fcs/getFcsFirstEndMonth/'+ pyear+'/'+pmonth,
        method: 'GET',
    })
}
const getFcsFuncInvReportDetail = (pYear, pMonth,loctnId,invid) => {
    return request({
        url: '/fcs/getFcsFuncInvReportDetail/' + pYear + '/' + pMonth + '/'+loctnId + '/'+invid,
        method: 'GET'
    })
}
const addFcsGLTran = (body) => {
    return request({
        url: '/fcs/addFcsGLTran',
        method: 'POST',
        data: body,
    })
}
const getFcsGlTran = (year,month,jrkey) => {
    return request({
        url: '/fcs/getFcsGlTran/'+ year + '/' + month + '/'+jrkey,
        method: 'GET',
    })
}
const getFcsGlSum = (year,month) => {
    return request({
        url: '/fcs/getFcsGlTranSum/'+ year + '/' + month ,
        method: 'GET',
    })
}



const removeFcsInvRecp = (id) => {
    return request({
        url: '/fcs/removeFcsInvRecp/'+id,
        method: 'GET'
    })
}
const addFcsInvBal = (body) => {
    return request({
        url: '/fcs/addFcsInvBal',
        method: 'POST',
        data: body,
    })
}
const getFcsAptranReport = (year,month,apacct) => {
    return request({
        url: '/fcs/getFcsAptranReport/'+ year + '/' + month+ '/' + apacct,
        method: 'GET',
    })
}

const getFcsInOrLinByRecpId = (invRecpId) => {
    return request({
        url: '/fcs/getFcsInOrLinByRecpId/'+ invRecpId,
        method: 'GET',
    })
}
const getFcsInvIssByRecpId = (invRecpId) => {
    return request({
        url: '/fcs/getFcsInvIssByRecpId/'+ invRecpId,
        method: 'GET',
    })
}
const getAllFcsSt4New = (lang,head,type1) => {
    return request({
        url: '/fcs/getAllFcsSt4New/'+lang+'/'+head+'/'+type1 ,
        method: 'GET',
    })
}


const addFcsArtran = (body) => {
    return request({
        url: '/fcs/addFcsArtran',
        method: 'POST',
        data: body,
    })
}
const editFcsArtran = (body) => {
    return request({
        url: `/fcs/editFcsArtran`,
        method: 'POST',
        data: body,
    })
}
const removeFcsArtran = (id) => {
    return request({
        url: '/fcs/removeFcsArtran/'+id,
        method: 'POST'
    })
}
const getFcsArTranReport = (year,month,acctdb) => {
    return request({
        url: '/fcs/getFcsArTranReport/'+ year + '/' + month+ '/'+acctdb,
        method: 'GET'
    })
}

const getFcsArTranList = (year,month) => {
    return request({
        url: '/fcs/getFcsArTranList/'+ year + '/' + month,
        method: 'GET'
    })
}
const getAllFcsArClass = (artype) => {
    return request({
        url: '/fcs/getAllFcsArClass/'+artype,
        method: 'GET',
    })
}
const addFcsLocation = (body) => {
    return request({
        url: '/fcs/addFcsLocation',
        method: 'POST',
        data: body,
    })
}
const editFcsLocation = (body) => {
    return request({
        url: `/fcs/editFcsLocation`,
        method: 'POST',
        data: body,
    })
}
const removeFcsLocation = (id) => {
    return request({
        url: '/fcs/removeFcsLocation/'+id,
        method: 'POST'
    })
}
const addFcsUnit = (body) => {
    return request({
        url: '/fcs/addFcsUnit',
        method: 'POST',
        data: body,
    })
}
const editFcsUnit = (body) => {
    return request({
        url: `/fcs/editFcsUnit`,
        method: 'POST',
        data: body,
    })
}
const removeFcsUnit = (id) => {
    return request({
        url: '/fcs/removeFcsUnit/'+id,
        method: 'POST'
    })
}
const addFcsCustOrgn = (body) => {
    return request({
        url: '/fcs/addFcsCustOrgn',
        method: 'POST',
        data: body,
    })
}
const editFcsCustOrgn = (body) => {
    return request({
        url: `/fcs/editFcsCustOrgn`,
        method: 'POST',
        data: body,
    })
}
const removeFcsCustOrgn = (id) => {
    return request({
        url: '/fcs/removeFcsCustOrgn/'+id,
        method: 'POST'
    })
}
const addFcsChart = (body) => {
    return request({
        url: '/fcs/addFcsChart',
        method: 'POST',
        data: body,
    })
}

const addFcsSt4Desc = (body) => {
    return request({
        url: '/fcs/addFcsSt4Desc',
        method: 'POST',
        data: body,
    })
}



const editFcsChart = (body) => {
    return request({
        url: `/fcs/editFcsChart`,
        method: 'POST',
        data: body,
    })
}

const editFcsSt4Desc = (body) => {
    return request({
        url: `/fcs/editFcsSt4Desc`,
        method: 'POST',
        data: body,
    })
}
const removeFcsChart = (id) => {
    return request({
        url: '/fcs/removeFcsChart/'+id,
        method: 'POST'
    })
}
const addFcsMonth = (body) => {
    return request({
        url: '/fcs/addFcsMonth',
        method: 'POST',
        data: body,
    })
}
const removeFcsMonth = (id) => {
    return request({
        url: '/fcs/removeFcsMonth/'+id,
        method: 'POST'
    })
}
const getFcsMonth = () => {
    return request({
        url: '/fcs/getFcsMonth',
        method: 'GET',
    })
}
const addFcsBBClass = (body) => {
    return request({
        url: '/fcs/addFcsBBClass',
        method: 'POST',
        data: body,
    })
}
const removeFcsBBClass = (id) => {
    return request({
        url: '/fcs/removeFcsBBClass/'+id,
        method: 'POST'
    })
}

const addFcsSt4New = (body) => {
    return request({
        url: '/fcs/addFcsSt4New',
        method: 'POST',
        data: body,
    })
}
const editFcsSt4New = (body) => {
    return request({
        url: `/fcs/editFcsSt4New`,
        method: 'POST',
        data: body,
    })
}
const removeFcsSt4New = (id) => {
    return request({
        url: '/fcs/removeFcsSt4New/'+id,
        method: 'POST'
    })
}
const addFcsApClass = (body) => {
    return request({
        url: '/fcs/addFcsApClass',
        method: 'POST',
        data: body,
    })
}
const editFcsApClass = (body) => {
    return request({
        url: `/fcs/editFcsApClass`,
        method: 'POST',
        data: body,
    })
}
const removeFcsApClass = (id) => {
    return request({
        url: '/fcs/removeFcsApClass/'+id,
        method: 'POST'
    })
}
const getAllFcsGLBalOrg = (jr_key) => {
    return request({
        url: '/fcs/getAllFcsGLBalOrg/' + jr_key,
        method: 'GET',
    })
}
const addFcsGLBalOrg = (body) => {
    return request({
        url: '/fcs/addFcsGLBalOrg',
        method: 'POST',
        data: body,
    })
}
const editFcsGLBalOrg = (body) => {
    return request({
        url: `/fcs/editFcsGLBalOrg`,
        method: 'POST',
        data: body,
    })
}


const getAllFcsBBBalMon = () => {
    return request({
        url: '/fcs/getAllFcsBBBalMon',
        method: 'GET',
    })
}
const addFcsBBBalMon = (body) => {
    return request({
        url: '/fcs/addFcsBBBalMon',
        method: 'POST',
        data: body,
    })
}
const editFcsBBBalMon = (body) => {
    return request({
        url: `/fcs/editFcsBBBalMon`,
        method: 'POST',
        data: body,
    })
}


const getAllFcsGLBalMon = () => {
    return request({
        url: '/fcs/getAllFcsGLBalMon',
        method: 'GET',
    })
}
const addFcsGLBalMon = (body) => {
    return request({
        url: '/fcs/addFcsGLBalMon',
        method: 'POST',
        data: body,
    })
}
const editFcsGLBalMon = (body) => {
    return request({
        url: `/fcs/editFcsGLBalMon`,
        method: 'POST',
        data: body,
    })
}






const addFcsArClass = (body) => {
    return request({
        url: '/fcs/addFcsArClass',
        method: 'POST',
        data: body,
    })
}
const editFcsArClass = (body) => {
    return request({
        url: `/fcs/editFcsArClass`,
        method: 'POST',
        data: body,
    })
}
const removeFcsArClass = (id) => {
    return request({
        url: '/fcs/removeFcsArClass/'+id,
        method: 'POST'
    })
}
const getEventBudgetYear = (budget_year,agenda_id) => {
    return request({
        url: '/fcs/getEventBudgetYear/' + budget_year + '/' + agenda_id,
        method: 'GET',
    })
}
const getAgendaBudgetYear = (budget_year) => {
    return request({
        url: '/fcs/getAgendaBudgetYear/' + budget_year,
        method: 'GET',
    })
}
const getEconomicBudgetYear = (budget_year,agenda_id,event_id,econ_category_code_1) => {
    return request({
        url: '/fcs/getEconomicBudgetYear/' + budget_year + '/' + agenda_id + '/' + event_id + '/' + econ_category_code_1,
        method: 'GET',
    })
}
const getBudgetYear = (budget_year,agenda_id,event_id,econ_category_id) => {
    return request({
        url: '/fcs/getBudgetYear/' + budget_year + '/' + agenda_id + '/' + event_id + '/' + econ_category_id,
        method: 'GET',
    })
}


const getAllFcsFa = () => {
    return request({
        url: '/fcs/getAllFcsFa',
        method: 'GET',
    })
}

const addFcsFa = (body) => {
    return request({
        url: '/fcs/addFcsFa',
        method: 'POST',
        data: body,
    })
}
const editFcsFa = (body) => {
    return request({
        url: `/fcs/editFcsFa`,
        method: 'POST',
        data: body,
    })
}
const removeFcsFa = (id) => {
    return request({
        url: '/fcs/removeFcsFa/'+id,
        method: 'POST'
    })
}



const getAllFcsFaType = () => {
    return request({
        url: '/fcs/getAllFcsFaType',
        method: 'GET',
    })
}

const addFcsFaType = (body) => {
    return request({
        url: '/fcs/addFcsFaType',
        method: 'POST',
        data: body,
    })
}
const editFcsFaType = (body) => {
    return request({
        url: `/fcs/editFcsFaType`,
        method: 'POST',
        data: body,
    })
}
const removeFcsFaType = (id) => {
    return request({
        url: '/fcs/removeFcsFaType/'+id,
        method: 'POST'
    })
}

const getFcsFaOrReport = (year,month) => {
    return request({
        url: '/fcs/getFcsFaOrReport/'+ year + '/' + month,
        method: 'GET'
    })
}



const getAllFcsFaOr = () => {
    return request({
        url: '/fcs/getAllFcsFaOr',
        method: 'GET',
    })
}

const addFcsFaOr = (body) => {
    return request({
        url: '/fcs/addFcsFaOr',
        method: 'POST',
        data: body,
    })
}
const editFcsFaOr = (body) => {
    return request({
        url: `/fcs/editFcsFaOr`,
        method: 'POST',
        data: body,
    })
}
const removeFcsFaOr = (id) => {
    return request({
        url: '/fcs/removeFcsFaOr/'+id,
        method: 'POST'
    })
}



const getFcsFaZarReport = (year,month) => {
    return request({
        url: '/fcs/getFcsFaZarReport/'+ year + '/' + month,
        method: 'GET'
    })
}



const getAllFcsFaZar = () => {
    return request({
        url: '/fcs/getAllFcsFaZar',
        method: 'GET',
    })
}

const addFcsFaZar = (body) => {
    return request({
        url: '/fcs/addFcsFaZar',
        method: 'POST',
        data: body,
    })
}
const editFcsFaZar = (body) => {
    return request({
        url: `/fcs/editFcsFaZar`,
        method: 'POST',
        data: body,
    })
}
const removeFcsFaZar = (id) => {
    return request({
        url: '/fcs/removeFcsFaZar/'+id,
        method: 'POST'
    })
}

const getFcsFaByAcctReport = () => {
    return request({
        url: '/fcs/getFcsFaByAcctReport',
        method: 'GET',
    })
}

const procFcsFaUpdate = (pYear, pMonth) => {
    return request({
        url: '/fcs/procFcsFaUpdate/' + pYear + '/' + pMonth,
        method: 'GET'
    })
}
const procFcsSt1Update = (pYear, pMonth,pYear2, pMonth2) => {
    return request({
        url: '/fcs/procFcsSt1Update/' + pYear + '/' + pMonth+ '/' + pYear2+ '/' + pMonth2,
        method: 'GET'
    })
}

const procFcsSt1nUpdate = (pYear, pMonth,pYear2, pMonth2) => {
    return request({
        url: '/fcs/procFcsSt1nUpdate/' + pYear + '/' + pMonth+ '/' + pYear2+ '/' + pMonth2,
        method: 'GET'
    })
}


const procFcsSt2Update = (pYear, pMonth,pYear2, pMonth2) => {
    return request({
        url: '/fcs/procFcsSt2Update/' + pYear + '/' + pMonth+ '/' + pYear2+ '/' + pMonth2,
        method: 'GET'
    })
}

const procFcsSt2nUpdate = (pYear, pMonth) => {
    return request({
        url: '/fcs/procFcsSt2nUpdate/' + pYear + '/' + pMonth,
        method: 'GET'
    })
}



const procFcsSt3Update = (pYear, pMonth) => {
    return request({
        url: '/fcs/procFcsSt3Update/' + pYear + '/' + pMonth,
        method: 'GET'
    })
}

const procFcsSt4Update = (pYear, pMonth,pYear2, pMonth2) => {
    return request({
        url: '/fcs/procFcsSt4Update/' + pYear + '/' + pMonth+ '/' + pYear2+ '/' + pMonth2,
        method: 'GET'
    })
}



const getFcsFaBalMonth = () => {
    return request({
        url: '/fcs/getFcsFaBalMonth',
        method: 'GET',
    })
}

const getFcsSt1Bal = () => {
    return request({
        url: '/fcs/getFcsSt1Bal',
        method: 'GET',
    })
}

const getFcsSt2Bal = () => {
    return request({
        url: '/fcs/getFcsSt2Bal',
        method: 'GET',
    })
}

const getFcsSt4Bal = () => {
    return request({
        url: '/fcs/getFcsSt4Bal',
        method: 'GET',
    })
}


const getFcsSt3Bal = () => {
    return request({
        url: '/fcs/getFcsSt3Bal',
        method: 'GET',
    })
}



const addFcsFaBal = (body) => {
    return request({
        url: '/fcs/addFcsFaBal',
        method: 'POST',
        data: body,
    })
}

const getFcsFaMonthReport = (pYear, pMonth) => {
    return request({
        url: '/fcs/getFcsFaMonthReport/' + pYear + '/' + pMonth,
        method: 'GET'
    })
}

const fcsServices = {
    getFcsBbTran,
    getFcsBbTranReport,
    addFcsBbTran,
    editFcsBbTran,
    removeFcsBbTran,
    getAllFcsAptran,
    addFcsAptran,
    editFcsAptran,
    removeFcsAptran,
    getAllFcsChart,
    getAllFcsChartEdit,
    getAllFcsSt4Desc,

    getFcsChart,

    getAllFcsCustOrgn,
    getFcsCustOrgn,

    getAllFcsApSelect,
    addFcsApSelect,
    editFcsApSelect,
    removeFcsApSelect,
    getFcsApSelectReport,

    getAllFcsArSelect,
    addFcsArSelect,
    editFcsArSelect,
    removeFcsArSelect,
    getFcsArSelectReport,



    getAllFcsApClass,
    getAllFcsMonth,
    editFcsMonth,
    getAllFcsBBClass,
    editBBClass,
    getAllFcsUnit,
    getAllFcsInvCategory,
    getAllFcsInventory,
    addFcsInventory,
    editFcsInventory,
    getAllFcsLocation,
    getAllFcsInOrLin,
    addFcsInOrLin,
    editFcsInOrLin,
    getAllFcsInvRecp,
    addFcsInvRecp,
    editFcsInvRecp,
    removeFcsInOrLin,
    getAllFcsInvIss,
    addFcsInvIss,
    editFcsInvIss,
    getFcsCurrentBalanceInv,
    removeFcsInvIss,
    getFcsInvReportByLocation,
    getFcsInvReportByLocationDetail,
    addFcsInvCategory,
    editFcsInvCategory,
    removeFcsInvCategory,
    getFcsGlMonthReport,
    getFcsFuncInvReportDetail,
    addFcsGLTran,
    getFcsGlTran,
    getFcsGlSum,

    removeFcsInvRecp,
    getFcsInvBalMonth,
    addFcsInvBal,
    getFcsAptranReport,
    getFcsInOrLinByRecpId,
    getFcsInvIssByRecpId,
    getAllFcsSt4New,
    addFcsArtran,
    editFcsArtran,
    removeFcsArtran,
    getFcsArTranReport,
    getFcsArTranList,
    getAllFcsArClass,
    addFcsLocation,
    editFcsLocation,
    removeFcsLocation,
    addFcsUnit,
    editFcsUnit,
    removeFcsUnit,
    addFcsCustOrgn,
    editFcsCustOrgn,
    removeFcsCustOrgn,
    addFcsChart,
    addFcsSt4Desc,

    editFcsChart,
    editFcsSt4Desc,

    removeFcsChart,
    removeFcsMonth,
    addFcsMonth,
    getFcsMonth,
    addFcsBBClass,
    removeFcsBBClass,
    addFcsSt4New,
    editFcsSt4New,
    removeFcsSt4New,
    addFcsApClass,
    editFcsApClass,
    removeFcsApClass,
    getAllFcsGLBalOrg,
    addFcsGLBalOrg,
    editFcsGLBalOrg,
    getAllFcsBBBalMon,
    addFcsBBBalMon,
    editFcsBBBalMon,
    getAllFcsGLBalMon,
    addFcsGLBalMon,
    editFcsGLBalMon,
    addFcsArClass,
    editFcsArClass,
    removeFcsArClass,
    getEventBudgetYear,
    getAgendaBudgetYear,
    getEconomicBudgetYear,
    getBudgetYear,
    getAllFcsFa,
    addFcsFa,
    editFcsFa,
    removeFcsFa,
    getAllFcsFaType,
    addFcsFaType,
    editFcsFaType,
    removeFcsFaType,
    getAllFcsFaOr,
    addFcsFaOr,
    editFcsFaOr,
    removeFcsFaOr,
    getFcsFaOrReport,
    getAllFcsFaZar,
    addFcsFaZar,
    editFcsFaZar,
    removeFcsFaZar,
    getFcsFaZarReport,
    getFcsFaByAcctReport,
    procFcsFaUpdate,
    procFcsSt1Update,
    procFcsSt1nUpdate,
    procFcsSt2Update,
    procFcsSt2nUpdate,
    procFcsSt3Update,
    procFcsSt4Update,
    getFcsFaBalMonth,
    getFcsSt1Bal,
    getFcsSt2Bal,
    getFcsSt3Bal,
    getFcsSt4Bal,
    addFcsFaBal,

    getFcsFaMonthReport,
    getFcsInvissBalance,
    getFcsInvissBalanceFirst

}

export default fcsServices;

import React, {useContext, useEffect, useState, useRef} from 'react';
import 'devextreme-react/text-area';
import Button from "devextreme-react/button";
import Form, {ButtonItem, ButtonOptions, Label, SimpleItem} from "devextreme-react/form";
import DataGrid, {
    Editing,
    Paging,
    Column,
    FilterRow,
    RequiredRule,
    PatternRule,
    EmailRule,
    Lookup, GroupPanel, SearchPanel, Grouping, Export, Item,Popup as DataGridPopup,Form as DataGridForm,Item as DataGridItem
} from 'devextreme-react/data-grid';
import notify from 'devextreme/ui/notify';
import Popup from "devextreme-react/popup";

import {UserContext} from "../../../hooks/UserContext";
import AuthService from "../../../services/api/auth";
import OrganzationService from "../../../services/api/organization";
import LoadPanelComponent from "../../budget/components/loadPanel";
import OrganizationEditCellComponent from "../../../components/OrganizationEditCellComponent";
import OrganizationService from "../../../services/api/organization";
import WorkerDropDownBoxComponent from "../components/WorkerDropDownBoxComponent";
import {Workbook} from "exceljs";
import {exportDataGrid} from "devextreme/excel_exporter";
import {saveAs} from "file-saver-es";
import RemainingService from "../../../services/api/remainingService";
import RoleTagBoxComponent from "../components/RoleTagBoxComponent";
import FileServices from "../../../services/api/file";
import UserService from '../../../services/api/user';

const statusData = [
    {
        "status": 0,
        "statusName": "Идэвхгүй"
    },
    {
        "status": 1,
        "statusName": "Идэвхтэй"
    }];

function User(props) {
    const userListPosition = {of: '#usersList'}

    const {user} = useContext(UserContext);

    const [dataSource, setDataSource] = useState([]);
    const [loader, setLoader] = useState(false);
    const [orgList, setOrgList] = useState([]);
    const [passWordChangeInfo, setPassWordChangeInfo] = useState();
    const [passWordChangeLoader, setPasswordChangeLoader] = useState(false);
    const [workerData, setWorkerData] = useState([])
    const [newPassword, setNewPassword] = useState({
        actionType: 1,
        uniqueId: '',
        newPassword: '',
        confirmPassword: ''
    })
    const [popupVisible, setPopupVisible] = useState(false);
    const [pageIndex, setPageIndex] = useState(0);
    const myBabyRef = useRef(null);
    const[refRoles, setRefRoles] = useState([]);

    useEffect(() => {
        userFetch();
        fetchOrgList();
        getReferenceRoles();
    }, [])

    const fetchOrgList = async () => {
        try {
            const result = await OrganzationService.fetchOrgList();
            return setOrgList(result.data)
        } catch (e) {
            return notify(e.message);
        }
    }
    const getReferenceRoles = async () => {
        try {
            const result = await RemainingService.getRolesByOrg(user.organization.obt_id, user.start_of_ancestry);
            //console.log('GET REFERENCE ROLES',result);
            const r = result.data.filter(element => {
                return element.code !== '0';
            });
            return setRefRoles(user.roles[0].role_id === 0 ? result.data : r);
        } catch (e) {
            console.error(e);
        }
    }
    const userFetch = async () => {
        try {
            setLoader(true);
            const result = await OrganzationService.usersList();
            // console.log('DataGrid data ', result);
            result.data.map(e => {
                // console.log("test: ", e.status)
                if (e.status === 1) {
                    e.statusName = "Идэвхтэй";
                } else {
                    e.statusName = "Идэвхгүй";
                }
                // console.log("aaa: ", e.statusName);
            })
            const data = await OrganizationService.getOrgWorker(null,null,0,0,'');
            setWorkerData(data.data);
            setDataSource(result.data)
            setLoader(false)
        } catch (e) {
            setLoader(false)
            console.error(e);
        }
    }

    const setUser = async (e) => {
        const type = e.changes[0].type;
        setLoader(true);
        try {                 
            const data = await OrganizationService.getOrgUserAccessCount(e.changes[0].data.org_id) 
            const result1 = await UserService.checkOrgUserCount(e.changes[0].data.org_id)
            const count = parseInt(result1.data[0].count)
            if (count >= data.data[0].user_access_count && type !== 'update') {
                userFetch()
                setLoader(false);
                notify('Тухайн байгууллага дээр хэрэглэгчийн эрх шинээр үүсгэх боломжгүй', "error", 5000);
                return;
            }
            if (e.changes.length > 0) {
                let obj;

                type === 'insert' && delete e.changes[0].data.id;
                obj = {
                    data: type === 'remove' ? e.changes[0].key : e.changes[0].data,
                    actionType: type
                }

                let res;
                res = await AuthService.changeUserDetail(obj);

                if (e.changes[0].data && e.changes[0].data.user_role && typeof e.changes[0].data.user_role[0] === 'number' && type !== 'remove') {
                    const newInfo = {
                        user_id: type === 'insert' ? res.data.id : e.changes[0].data.id,
                        role_id: e.changes[0].data.user_role
                    }
                    await RemainingService.changeUserRole(newInfo);
                }
                await userFetch();
                setLoader(false);
                notify('Амжилттай хадгалагдлаа', "success", 2000);
            } else {
                setLoader(false);
            }

        } catch (e) {
            console.log('catche', e);
            type === 'remove' ? notify('Энэ хэрэглэгч системийн бусад мэдээлэл хүснэгт дээр холбоотой байна', "warning", 5000) :
                (type === 'insert' ? notify('Хэрэглэгчийн нэр системд бүртгэлтэй байна!', "warning", 5000) : notify('Засварлалтыг хадгалах явцад алдаа гарлаа', "error", 2000));
        setLoader(false);
        }
    }

    const customAddRow = (grid) => {
        grid.current.instance.addRow();
    }

    //Column custom renderings
    const statusRender = (data) => {
        if (data.displayValue && data.displayValue === "Идэвхтэй") {
            return <div style={{
                backgroundColor: '#50CB93',
                width: 'auto',
                color: 'white',
                borderRadius: '3px',
                margin: 'auto',
                textAlign: 'center'
            }}>Идэвхтэй</div>
        } else {
            return <div style={{
                backgroundColor: '#FF616D',
                width: 'auto',
                color: 'white',
                borderRadius: '3px',
                cursor: 'pointer',
                margin: 'auto',
                textAlign: 'center'
            }}>Идэвхгүй</div>
        }
    }

    const orderRender = (data) => {
        return <div style={{color: "#171010"}}>{pageIndex * 20 + data.rowIndex + 1}</div>;
    }

    const columnInfo = async (data) => {
        await setPassWordChangeInfo(data.data);
        await setNewPassword((prevObject) => {
                return {
                    ...prevObject,
                    uniqueId: data.data.id
                };
            }
        );
        setPasswordChangeLoader(true);
    }

    const passwordCell = (data) => {
        return <div style={{textAlign: 'center',alignItems:'center'}}>
            <Button
                width={'auto'}
                height={'auto'}
                type="default"
                icon='key'
                stylingMode="outlined"
                onClick={() => {
                    columnInfo(data)
                }}
            />
        </div>
    }

    const dateRender = (data) => {
        return <div style={{textAlign: 'center'}}>
            <div style={{color: '#FF616D'}}>{data.text}</div>
        </div>
    }

    const dateRenderStart = (data) => {
        return <div style={{textAlign: 'center'}}>
            <div style={{color: '#50CB93'}}>{data.text}</div>
        </div>
    }

    const onEditorPreparing = async (e) => {
        if (e.parentType === "dataRow" && e.dataField !== "password") {
            return;
        }
        if (e.row && e.row.isNewRow) {
            e.editorOptions.visible = true;
        }
        if (e.row && !e.row.isNewRow) {
            e.editorOptions.visible = false;
        }
    }

    const changePasswordProcedures = async (e) => {
        e.preventDefault();
        setPopupVisible(true);
        await setNewPassword(newPassword);
        //console.log('EEEEEEEEEEE', newPassword);
        try {
            await AuthService.changePassword(newPassword);
            setPasswordChangeLoader(false);
            setPopupVisible(false);
            notify('Энэнхүү хэрэглэгчийн нууц үг амжиллтай солигдлоо', "success", 2000);
        } catch (e) {
            console.error(e);
            notify('Нууц үг солих явцад алдаа гарлаа', "error", 2000);
        }
    }
    const handleOptionChange = (e) => {
        if (e.fullName === 'paging.pageIndex') {
            setPageIndex(e.value)
        }
    }
    const onExporting = (e) => {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Main sheet');

        exportDataGrid({
            component: e.component,
            worksheet,
            autoFilterEnabled: true,
        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer], {type: 'application/octet-stream'}), 'Users.xlsx');
            });
        });
        e.cancel = true;
    }
    const onCellPreparedHandler = (e) => {
        if (e.rowType === 'header') {
            e.cellElement.wordWrapEnabled = true;
            e.cellElement.style.fontFamily = 'Montserrat'
            e.cellElement.style.fontWeight = 'semibold'
            e.cellElement.style.color = "#15186A"
            e.cellElement.style.backgroundColor = "#EEF0F4"
        }
        if (e.rowType === 'data') {
            e.cellElement.style.fontFamily = 'Montserrat'
            e.cellElement.style.fontWeight = 'semibold'
            e.cellElement.style.color = "#000000"
            e.cellElement.style.verticalAlign = 'middle'
        }
    }
    const cellTemplate = (container, options) => {
        let noBreakSpace = '\u00A0',
            text = (options.value || []).map(element => {
                return options.column.lookup.calculateCellValue(element);
            }).join(', ');
        container.textContent = text || noBreakSpace;
        container.title = text;
    }
    const calculateFilterExpression = (filterValue, selectedFilterOperation, target) => {
        if(target === 'filterRow' && typeof (filterValue) === 'number') {
            return ['user_role', 'contains', filterValue];
        }
        return function(data) {
            //console.log('dataaa', data);
            return (data.user_role || []).indexOf(filterValue) !== -1;
        };
    }
    return (
        <div>
            <LoadPanelComponent position={{of: '#password'}} visible={popupVisible}/>

            {/*    <div className="card-header">
                <span>Ерөнхий мэдээлэл</span>
            </div>*/}
            <div className="col-12 row" style={{justifyContent: "flex-end", gap: '2rem', padding: '5px 15px'}}>

                {/*<TextBox placeholder = "Хэрэглэгчийн дугаар" style={{borderRadius: '7px', borderColor: '#1453B5'}}/>*/}
                {/*<TextBox placeholder = "Байгууллага" style={{borderRadius: '7px', borderColor: '#1453B5'}}/>*/}
                {/*<TextBox placeholder = "Хэрэглэгчийн эрх" style={{borderRadius: '7px', borderColor: '#1453B5'}}/>*/}
                {/*<Button disabled text = "Хайх" onClick={() => customAddRow(myBabyRef)}  width = {150} style = {{alignment:"center"}} style={{borderRadius: '7px', backgroundColor: '#1453B5', color: 'white'}}/>*/}

                {/* {user.roles[0].role_id === 0 && */}
                    <Button text="Шинэ хэрэглэгч үүсгэх" onClick={() => customAddRow(myBabyRef)} width={'auto'} type="success" icon={'user'}
                            style={{alignment: "center", borderRadius: '7px'}}/>
                            {/* } */}
            </div>
            <LoadPanelComponent position={userListPosition} visible={loader}/>

                <div className="card" style={{padding:10}}>
                    <DataGrid
                        onEditorPreparing={onEditorPreparing}
                        ref={myBabyRef}
                        dataSource={dataSource}
                        //wordWrapEnabled={true}
                        onSaved={setUser}
                        columnAutoWidth={true}
                        keyExpr="id"
                        onOptionChanged={handleOptionChange}
                        paging={{
                            pageSize: 20,
                        }}
                        showBorders={true}
                        showColumnLines={true}
                        showRowLines={true}
                        onCellPrepared={onCellPreparedHandler}
                        onExporting={onExporting}
                      
                        // onToolBarPreparing = {onToolbarPreparing()};
                        // onEditorPreparing = {onEditorPreparing}
                        allowColumnResizing={true}
                        // onRowRemoved={deleteUser}
                        // customizeColumns={(e) => {
                        //     console.log('EEEEEEEEEEEEEEEEEEEEEEEEEE',e);
                        //     e[7].caption = 'Шинэ нууц үг';
                        //     // if (e[7].dataField === 'password') {
                        //     //     e[7].visible = false;
                        //     // }
                        // }}

                        onEditingStart={e => {
                            console.log('qqqqqqqqqqqqqqqqq', e);
                        }}
                    >
                        <GroupPanel visible={true} emptyPanelText={"Хүссэн баганаа дээш зөөн бүлэглэх боломжтой"}/>
                        <SearchPanel visible={true}/>
                        <Grouping autoExpandAll={false}/>
                        <FilterRow
                            visible={true}
                        />
                        <Column
                            caption='№'
                            cssClass="custom"
                            alignment="center"
                            allowEditing={false}
                            //width={50}
                            cellRender={orderRender}
                        />
                        <Column
                            cssClass="custom"
                            dataField="username"
                            caption="Нэвтрэх нэр"
                            // allowEditing={user.roles[0].role_id === 0 ? true : false}
                            allowFiltering={true}
                            alignment="center"
                        >
                            <RequiredRule
                                message='Нэвтрэх нэрийг оруулна уу'
                            />
                        </Column>
                        <Column
                            dataField="last_name"
                            caption="Овог"
                            alignment="center"
                            allowEditing={true}
                            // editCellTemplate = {}
                            // cellRender = {customize}
                            cssClass="custom"
                            allowFiltering={true}
                            hidingPriority={4}
                        >
                         {/*   <RequiredRule
                                message='Овог оруулна уу'
                            />*/}
                        </Column>
                        <Column
                            cssClass="custom"
                            dataField="first_name"
                            caption="Нэр"
                            alignment="center"
                            allowEditing={true}
                            allowCollapsing={false}
                            allowFiltering={true}
                        >
                           {/* <RequiredRule
                                message='Нэр оруулна уу'
                            />*/}
                        </Column>
                        <Column
                            cssClass="custom"
                            dataField="email"
                            minWidth={120}
                            caption="Имэйл"
                            alignment="center"
                            allowFiltering={true}
                            hidingPriority={2}
                        >
                            {/*<RequiredRule
                                message='Имэйлийг оруулна уу'
                            />*/}
                            <EmailRule message={'Email оруулна уу!'}/>
                        </Column>
                        <Column
                            cssClass="custom"
                            dataField="phone"
                            caption="Утас"
                            minWidth={70}
                            alignment="center"
                            allowFiltering={true}
                            hidingPriority={3}
                        >
                           {/* <RequiredRule
                                message='Утас оруулна уу'
                            />*/}
                            <PatternRule pattern={/^\d+$/} message='зөвхөн тоо оруулна уу!'/>
                        </Column>
                        <Column
                            cssClass="custom"
                            dataField="org_id"
                            caption="Байгууллага"
                            minWidth={90}
                            alignment="center"
                            allowEditing={true}
                            allowFiltering={true}
                            editCellComponent={OrganizationEditCellComponent}
                        >
                            <Lookup
                                dataSource={orgList}
                                displayExpr={(item) => {
                                    return item && `${item.aimag_name} - ${item.organization_name}`
                                }}
                                valueExpr="id"
                            />
                            <RequiredRule message='Байгууллага сонгох шаардлагатай!'/>
                        </Column>
                        <Column
                            cssClass="custom"
                            dataField="start_date"
                            caption="Эрх өгөгдсөн огноо"
                            // allowEditing={user.roles[0].role_id === 0 ? true : false}
                            cellRender={dateRenderStart}
                            dataType="date"
                            hidingPriority={0}
                            // allowFiltering={true}
                        />
                        <Column
                            dataField="end_date"
                            caption="Эрх дуусах огноо"
                            // allowEditing={user.roles[0].role_id === 0 ? true : false}
                            cellRender={dateRender}
                            dataType="date"
                            hidingPriority={1}
                            // allowFiltering={true}
                        />

                        <Column
                            cssClass="custom"
                            dataField="status"
                            caption="Төлөв"
                            // allowEditing={user.roles[0].role_id === 0 ? true : false}
                            cellRender={statusRender}
                            width={80}
                            allowFiltering={true} >
                        <Lookup
                            allowClearing={true}
                            dataSource={statusData}
                            valueExpr="status"
                            displayExpr="statusName"/>
                            <RequiredRule message='Төлөв сонгох шаардлагатай!'/>
                    </Column>
                        {/* {(user.roles[0].role_id === 0 || user.roles[0].role_id === 1) && */}
                            <Column
                                dataField="user_role"
                                caption="Системийн эрх"
                                allowFiltering={true}
                                editCellComponent={RoleTagBoxComponent}
                                cellTemplate={cellTemplate}
                                calculateFilterExpression={calculateFilterExpression}
                            >
                                <Lookup
                                    dataSource={refRoles}
                                    displayExpr="name"
                                    valueExpr="id"
                                />
                                <RequiredRule/>
                            </Column>
                        {/* } */}
                        {/* {(user.roles[0].role_id === 0 || user.roles[0].role_id === 1) && */}
                            <Column
                                cssClass="custom"
                                dataField="password"
                                caption="Нууц үг"
                                allowEditing={true}
                                allowFiltering={false}
                                cellRender={passwordCell}
                            >
                                <RequiredRule message='Нууц үг оруулах шаардлагатай!'/>
                            </Column>
                        {/* } */}
                        <Column dataField="worker_id" caption="Албан хаагч" dataType="string"
                                editCellComponent={WorkerDropDownBoxComponent}>
                            <Lookup
                                valueExpr="wid"
                                displayExpr="first_name"
                                dataSource={workerData}
                            />
                        </Column>
                        <Editing
                            mode="popup"
                            allowUpdating={true}
                            // allowAdding={true}
                            // allowDeleting={user.roles[0].role_id === 0 ? true : false}
                            confirmDelete={true}
                            useIcons={true}
                            confirmSave={true}
                            texts={{
                                cancelAllChanges: 'Болих бүгд',
                                cancelRowChanges: 'Болих',
                                confirmDeleteMessage: 'Энэ хэрэглэгчийн мэдээллийг устгах уу?',
                                confirmDeleteTitle: 'Баталгаажуулах',
                                deleteRow: 'Устгах',
                                editRow: 'Өөрчлөх',
                                saveAllChanges: 'Хадгалах бүгд',
                                saveRowChanges: 'Хадгалах',
                                undeleteRow: 'Буцаах',
                                validationCancelChanges: 'Баталгаажуулах болих',
                            }}
                        >
                            <DataGridPopup title="Хэрэглэгчийн бүртгэл" showTitle={true} width={1200} height={450} closeOnOutsideClick={true}/>
                            <DataGridForm>
                                <DataGridItem itemType="group" colCount={2} colSpan={2}>
                                    <DataGridItem dataField="username"/>
                                    <DataGridItem dataField="org_id"/>
                                    <DataGridItem dataField="worker_id"/>
                                    {/* <DataGridItem dataField="last_name"/> */}
                                    {/* <DataGridItem dataField="first_name"/> */}
                                    {/* <DataGridItem dataField="email"/> */}
                                    {/* <DataGridItem dataField="phone"/> */}
                                    <DataGridItem dataField="status"/>
                                    <DataGridItem dataField="start_date"/>
                                    <DataGridItem dataField="end_date"/>
                                    {/* {user.roles[0].role_id === 0 &&  */}
                                    <DataGridItem dataField="password"/>
                                    {/* } */}
                                    {/* {(user.roles[0].role_id === 0 || user.roles[0].role_id === 1) &&  */}
                                    <DataGridItem dataField="user_role"/> 
                                    {/* } */}
                                </DataGridItem>
                            </DataGridForm>
                        </Editing>
                        <Export enabled={true} allowExportSelectedData={false}/>
                        <Paging enabled={true} pageIndex={pageIndex}/>
                    </DataGrid>
                </div>
            {
                passWordChangeLoader &&
                <Popup
                    id='passwordChangePopup'
                    visible={passWordChangeLoader}
                    onHiding={() => setPasswordChangeLoader(false)}
                    dragEnabled={false}
                    hideOnOutsideClick={true}
                    showTitle={true}
                    title={passWordChangeInfo != undefined && ` Овог:  ${passWordChangeInfo.last_name} - Нэр: ${passWordChangeInfo.first_name}`}
                    width={500}
                    // key={data.data.id}
                    // titleRender={renderTitle}
                    height='auto'
                >
                    <form onSubmit={changePasswordProcedures}>
                        <Form
                            formData={newPassword}
                        >
                            <SimpleItem dataField="newPassword" isRequired={true} editorType="dxTextBox"
                                        editorOptions={{mode: 'password'}}>
                                <Label text="Шинэ нууц үг"/>
                                <RequiredRule
                                    message='Шинэ нууц үгээ оруулна уу'
                                />
                            </SimpleItem>
                            <SimpleItem dataField="confirmPassword" isRequired={true} editorType="dxTextBox"
                                        editorOptions={{mode: 'password'}}>
                                <Label text="Шинэ нууц үг(Давтах)"/>
                                <RequiredRule
                                    message='Шинэ нууц үгээ давтана уу'
                                />
                            </SimpleItem>
                            <ButtonItem
                                horizontalAlignment="right"
                            >
                                <ButtonOptions
                                    type="submit"
                                    method='submit'
                                    useSubmitBehavior={true}
                                    text='Нууц үгийг солих'
                                />
                            </ButtonItem>
                        </Form>
                    </form>
                </Popup>
            }

        </div>
    )
}

export default User;

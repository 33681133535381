import React, {useEffect, useState} from "react"
import statsService from "../../../services/api/stat.service"
import notify from "devextreme/ui/notify"
import PieChart from "devextreme-react/pie-chart"
import {
  Connector,
  Export,
  Label,
  Legend,
  Series,
  Tooltip,
} from "devextreme-react/chart"
import {numberWithCommas, numberWithCommas11} from "../../../util"

const NumberThree = (props) => {
  const [chartData, setChartData] = useState([])

  const loadData = async (year) => {
    try {
      let data = await statsService.getDataSingleCode(
        year,
        props.list_id,
        props.tbl_id
      )
      setChartData(data)
    } catch (e) {
      notify(e.message)
    }
  }

  useEffect(async () => {
    await loadData(props.year)
  }, [props.year, props.list_id, props.tbl_id])

  const pointClickHandler = (e) => {
    // toggleVisibility(e.target);
    props.pivotGridHanlder()
  }

  const toggleVisibility = (item) => {
    item.isVisible() ? item.hide() : item.show()
  }

  const legendClickHandler = (e) => {
    const arg = e.target
    const item = e.component.getAllSeries()[0].getPointsByArg(arg)[0]

    toggleVisibility(item)
  }

  const cstmText = (e) => {
    return `<span style="color: #f7f7fa">${numberWithCommas11(e.value)}<br/>${(
      e.percent * 100
    ).toFixed(2)}%</span>`
  }
  if (chartData?.length === 0) return null
  return (
    <div style={{marginBottom: 10, flex: 0.5, width: "50%"}}>
      <div className="card" style={{height: "100%"}}>
        <div className="card-header">
          <span style={{fontWeight: "700"}}>3. СТАТИСТИК ҮЗҮҮЛЭЛТ</span>
        </div>
        <div className="card-body" style={{padding: "1.8rem 0"}}>
          <PieChart
            type="doughnut"
            dataSource={chartData}
            resolveLabelOverlapping="hide"
            onPointClick={pointClickHandler}
            onLegendClick={legendClickHandler}
            palette={["#003696", "#2C77FF", "#AAC8FF"]}
          >
            <Export enabled={true} />
            <Series argumentField="SCR_MN" valueField="sum">
              <Label visible={true} position="columns" customizeText={cstmText}>
                <Connector visible={true} width={0.5} />
              </Label>
            </Series>
            <Legend
              orientation="horizontal"
              itemTextPosition="right"
              horizontalAlignment="center"
              verticalAlignment="bottom"
            />
            <Tooltip
              zIndex={10000}
              enabled={true}
              customizeTooltip={(arg) => ({
                text: arg.argumentText + ": " + numberWithCommas11(arg.value),
              })}
              font={{family: "'Montserrat', sans-serif"}}
            ></Tooltip>
          </PieChart>
        </div>
      </div>
    </div>
  )
}

export default NumberThree

import React from 'react';

export default function EmployeeCell(options) {
  const employee = options.data;
  return (
    <React.Fragment>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <div>
          <img style={{ width: 'auto', height: 35, borderRadius: 32 }} src={employee.img}/>
        </div>
        &nbsp;
        <span className="name" style={{ marginLeft: 5 }}>{employee.name}</span>
      </div>
    </React.Fragment>
  );
}

import React, {useEffect, useState} from "react"
import Arbeginbal from "./arbeginbal"
import Arclassedit from "./arclassedit"
import Artranedit from "./Artranedit"
import Arselect from "./arselect"
import ArSelectEdit from "./ArSelectEdit"
import fcsServices from "../../../services/api/fcs"
import {Button, Popup, ScrollView} from "devextreme-react"
import {HiPlus} from "react-icons/hi"
import {FiSettings} from "react-icons/fi"
import DepositPopup from "../BankBook/DepositPopup"
import ArEntery from "./ArEntery"

function ArEdit() {
  const [dataFcsMonth, setFcsMonth] = useState([])
  const [yearValue, setYearValue] = useState(null)
  const [monthValue, setMonthValue] = useState(null)
  const [popup, setPopup] = useState(false)
  useEffect(() => {
    setDate()
  }, [])
  const setDate = async () => {
    const fcsMonth = await fcsServices.getAllFcsMonth("AR")
    setFcsMonth(fcsMonth)
    fcsMonth.map((item) => {
      setYearValue(item.year)
      setMonthValue(item.month)
    })
  }
  const initYearMN = {year: (new Date()).getFullYear(), month: (new Date()).getMonth() + 1, name: ""};
  const [yearMN, setYearMN] = useState({...initYearMN});
  const popupHandler = () => {
    setPopup(true)
  }
  return (
    <div>
      <Popup
        width={"75vw"}
        height={"90vh"}
        onHiding={() => setPopup(false)}
        resizeEnabled={true}
        dragEnabled={true}
        // hideOnOutsideClick
        showTitle={false}
        showCloseButton={false}
        visible={popup}
        className="BbEditPopup"
      >
        <ScrollView width="100%" height="100%">
          <DepositPopup
            children={[
              <ArEntery yearMN={yearMN}  year={yearValue} month={monthValue}/>,
              <Arselect/>,
            ]}
            setPopup={setPopup}
            title={["Борлуулалт", "Төлбөр"]}
          />
        </ScrollView>
      </Popup>
      <div
        className="bankbookToolbarContainer"
        style={{
          display: "flex",
          background: "white",
          padding: "1rem .5rem",
          alignItems: "center",
        }}
      >
        <div style={{display: "flex", marginRight: 20}}>
          <div
            style={{
              marginRight: 10,
              display: "flex",
              alignItems: "center",
            }}
          >
            <span style={{color: "#A3A3A3", marginRight: "1rem"}}>
              Тайлант он:
            </span>
            <span style={{fontWeight: "700"}}>{yearValue}</span>
          </div>
        </div>
        <div style={{display: "flex", marginRight: 20}}>
          <div
            style={{
              marginRight: 10,
              display: "flex",
              alignItems: "center",
            }}
          >
            <span style={{color: "#A3A3A3", marginRight: "1rem"}}>
              Тайлант сар:
            </span>
            <span style={{fontWeight: "700"}}>{monthValue}</span>
          </div>
        </div>
        <div style={{display: "flex", marginRight: 10}}>
          <button className="customAddButton" onClick={popupHandler}>
            <HiPlus style={{fontSize: "1.2rem"}} /> Нэмэх
          </button>
        </div>
        <div>
          <button className="customSettingsButton">
            <FiSettings style={{fontSize: "1.2rem"}} />
          </button>
        </div>
      </div>
      <Artranedit />
    </div>
  )
}

export default ArEdit

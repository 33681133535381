import { ScrollView } from 'devextreme-react'
import React from 'react'

export const PrivacyPolicy = () => {
    return (
        <ScrollView showScrollbar='always'>
            <div className='privacy-policy'>
                <div>
                    <b>OPENGOV ТӨСВИЙН НЭГДСЭН СИСТЕМИЙН НУУЦЛАЛЫН БОДЛОГО</b>
                </div>
                <p>Энэхүү Нууцлалын Бодлого нь "Даталанд технологи" ХХК (цаашид "Үйлчилгээ үзүүлэгч" гэх) төсвийн  мэдээллийг цуглуулах, боловсруулах, нэгтгэх, ашиглах, хамгаалах тухай эрх зүйн албан ёсны баримт бичиг юм.<br />
                    Үйлчилгээ үзүүлэгч нь энэхүү нууцлалын бодлогод тусгаснаас өөрөөр болон Монгол Улсын хуульд зааснаас бусад ямар ч тохиолдолд хэрэглэгчийн аливаа мэдээллийг гуравдагч этгээдэд дамжуулахгүй.<br />
                    Үйлчилгээ үзүүлэгч нь Нууцлалын бодлогод нэмэлт өөрчлөлт оруулах эрхтэй бөгөөд нэмэлт өөрчлөлт орсон тухай энэ бодлогын дээд хэсэгт байрлах Сүүлд шинэчилсэн огноог өөрчлөх болон бусад хэлбэрээр мэдэгдэнэ. Тухайлбал: системээр дамжуулан мэдэгдэл илгээх, постер байршуулах зэрэг хэлбэрээр байж болно.
                </p>
                <div>
                    <h5>Нэг. Мэдээлэл цуглуулах</h5>
                    <div>
                        <b>1.1. Төсөвтэй холбоотой мэдээлэл: </b>
                        <ul>
                            <li>Тухайн  жилийн төсвийн орлого бүрдлээр болон эдийн засгийн ангиллаар</li>
                            <li>Төсвийн зарлага бүрдлээр болон эдийн засгийн ангиллаар</li>
                            <li>Гадаад, дотоодын зээл тусламжаар хэрэгжүүлж буй төсөл хөтөлбөр, арга хэмжээ</li>
                        </ul>
                    </div>
                    <div>
                        <b>1.2. Агентлаг, байгууллагатай холбоотой мэдээлэл:</b>
                        <p>Агентлаг, байгууллагын эрх зүйн баримт бичгийн жагсаалт, төрийн чиг үүргийн хүрээнд авч хэрэгжүүлж буй Хөгжлийн бодлого төлөвлөлтийн баримт бичигт тусгагдсан арга хэмжээ, үйл ажиллагааны стратеги, зохион байгуулалтын бүтцийн өөрчлөлтийн хөтөлбөр, бүтэц орон тоо шинэчлэн баталсан бол хамгийн сүүлд баталсан тушаал, Стратеги төлөвлөгөө зэрэг баримт бичиг хамаарна.</p>
                    </div>
                    <div>
                        <b>1.3. Албан хаагчтай холбоотой мэдээлэл:</b>
                        <p>Бид тухайн байгууллагын удирдлага болон хүний нөөцийн мэдээллийг системд бүртгэдэг.<br />
                            Танаас дараах төрлийн мэдээллийг авч болно. Үүнд:</p>
                        <ul>
                            <li>Таны овог, нэр, албаны болон хувийн цахим шуудангийн хаяг, утасны дугаар, шуудангийн хаяг зэрэг холбоо барих мэдээлэл.</li>
                            <li>Тухайн байгууллагат эрхэлдэг албан тушаал түүний тодорхойлолт, хөдөлмөр эрхлэлтийн байдал.</li>
                            <li>Таны намтар, зураг, дипломын хуулбар, CV гэх мэт хувийн мэдээлэл хамаарна.</li>
                        </ul>
                    </div>
                    <div>
                        <b>1.4. Бусад эх сурвалжаас авсан мэдээлэл:</b>
                        <p>Төсвийн нэгдсэн систем төрийн бусад систем, эх сурвалжаас мэдээлэл авч цуглуулсан мэдээллээ нэгтгэн оруулж болно. Тухайлбал: Тухайн жилийн төсөв, сар бүрийн гүйцэтгэл, батлагдсан хуваарь, төсвийн жилд хэрэгжүүлэх хөтөлбөр, хөтөлбөрийн үр дүнгийн үзүүлэлт, гадаад, дотоодын зээл тусламжаар хэрэгжиж буй төсөл хөтөлбөр, төсвийн хөрөнгө оруулалтын төсөл, арга хэмжээ мэдээллийг авч болно.</p>
                    </div>
                    <div>
                        <b>1.5. Автоматаар цуглуулах мэдээлэл:</b>
                        <div>
                            Мөн төсвийн нэгдсэн систем дараах мэдээллийг автоматаар цуглуулна.<br />
                            Үүнд:
                            <ul>
                                <li>Сар бүрийн төсвийн гүйцэтгэл: Төсвийн нэгдсэн систем сангийн яамны и-хуулга системээс сард 3 удаа төсвийн гүйцэтгэлийн мэдээллийг автоматаар татна.</li>
                                <li>Төхөөрөмж ба Бүртгэлийн мэдээлэл: Системд хандсан төхөөрөмжийн мэдээллийг авах ба үүнд төхөөрөмжийн дугаар, мобайл сүлжээний мэдээлэл, үйлдлийн системийн төрөл, ашигладаг интернэт хөтчийн төрөл багтана. Мөн бид таны Платформын ашиглалт, хандалтын цаг хугацаа, үзсэн хуудас, IP хаяг, бусад стандарт веб хөтчийн өгөгдөл, мөн манай вебсайтад нэвтрэхээс өмнө болон нэвтэрсний дараа үзсэн хуудас зэрэг мэдээллийг бүртгэдэг.</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div>
                    <h5>Хоёр. Мэдээлэл ашиглах</h5>
                    <p>Мэдээллийг дараах байдлаар ашиглаж болно. Үүнд:</p>
                    <ul>
                        <li>Төсвийн ажиллагааг цахимжуулан, ил тод байдлын хангахад;</li>
                        <li>Төсвийн үр ашигтай, оновчтой зарцуулалтад дэмжлэг үзүүлэхийн тулд мэдээллийг бусад эх сурвалжийн мэдээлэлтэй холбох буюу нэгтгэхэд, харьяа дээд байгууллагат болон удирдлагад мэдээ илгээхэд;</li>
                        <li>Платформыг хэрэглэгчийн хэрэгцээнд тохируулан өөрчлөх, сайжруулахад;</li>
                        <li>Маргаан шийдвэрлэх, хураамж төлбөр авах, техникийн асуудлуудыг шийдэхэд;</li>
                        <li>Үйлчилгээний нөхцөлийг мөрдүүлэхэд;</li>
                    </ul>
                </div>
                <div>
                    <h5>Гурав. Мэдээлэл боловсруулах</h5>
                    <p>Төсвийн нэгдсэн систем нь байгууллагын төсвийн задгай, түүхэн мэдээллийг боловсруулах бөгөөд задгай мэдээллийг бусад этгээдэд шилжүүлэхгүй.</p>
                </div>
                <div>
                    <h5>Дөрөв. Мэдээлэл дамжуулах</h5>
                    <p>Системд бүртгэгдсэн төсвийн болон бусад мэдээллийг дараах тохиолдлуудад харьяа дээд байгууллага болон сангийн яам, эдийн засаг хөгжлийн яам, үндэсний аудитын газарт дамжуулж болно.</p>
                    <div>
                        <p>4.1. Төсвийн цаглабрын дагуу боловсруулсан төсвийн төслийг төсвийн төвлөрүүлэн захирагч, төсвийн ерөнхийлөн захирагч, сангийн яаманд хүргүүлэх</p>
                        <p>4.2. Батлагдсан төсвийн хуваарийн саналыг харьяа дээд төсвийн захирагч нарт хүргүүлэх</p>
                        <p>4.3. Агентлаг, байгууллагын эрх зүйн баримт бичгийн жагсаалт, төрийн чиг үүргийн хүрээнд авч хэрэгжүүлж буй Хөгжлийн бодлого төлөвлөлтийн баримт бичигт тусгагдсан арга хэмжээ, үйл ажиллагааны стратеги, зохион байгуулалтын бүтцийн өөрчлөлтийн хөтөлбөр, Стратеги төлөвлөгөө, зэрэг баримт бичгийг аудитын газарт</p>
                        <p>4.4. Хөгжлийн бодлого, төлөвлөлтийн баримт бичгийн хэрэгжилт, хууль тогтоомж, тогтоол шийдвэрийн биелэлт болон байгууллагын үйл ажиллагааны тайланг тайлагнах хугацаанд харьяалах байгууллагат дамжуулах</p>
                        <p>4.5. Нийтийн мэдээллийн ил тод байдлын тухай хуульд заасан салбарт хамааралтай байнга ил тод, нээлттэй байх мэдээллийг OpenWeb-ээр дамжуулан нийтэд мэдээлэх<br />Үүнд:</p>
                        <ul>
                            <li>Мэдээлэл хариуцагч өөрийн чиг үүрэг, үйл ажиллагааны стратеги, зохион байгуулалтын талаарх мэдээлэл</li>
                            <li>Мэдээлэл хариуцагч өөрийн хүний нөөцийн талаарх мэдээлэл</li>
                            <li>Мэдээлэл хариуцагч төсөв, санхүү, худалдан авах ажиллагааны талаарх мэдээлэл</li>
                            <li>Мэдээлэл хариуцагч хуульд заасан чиг үүргийн хүрээнд үзүүлж байгаа үйлчилгээний талаар мэдээлэл</li>
                            <li>Мэдээлэл хариуцагч дээрх мэдээллээс гадна өөрийн эрхлэх асуудлын хүрээнд хамаарах бусад мэдээлэл.</li>
                        </ul>
                    </div>
                </div>
                <div>
                    <h5>Тав. Аюулгүй байдал</h5>
                    <p>Бид мэдээллийн нууцлал, аюулгүй байдлыг хамгаалахын тулд аюулгүй байдлын зохих үйл ажиллагаа, дотоод журмыг хэрэгжүүлдэг. Мөн мэдээллийг биет, техникийн болон мэдээллийн удирдлагын бодлогын дагуу аюулгүй байдлын арга хэмжээг хэрэгжүүлэх замаар хамгаалдаг. Тухайлбал бүх ажилтан төсвийн нэгдсэн системд нэвтрэх эрхгүй бөгөөд байгууллагын удирдлага, санхүүгийн хэлтсийн дарга болон нягтлан, админ эрхээр мэдээлэл удирдах эрх бүхий албан тушаалтан системд хандах зэргээр журамлан зохицуулдаг.</p>
                </div>
                <div>
                    <h5>Зургаа. Холбоо барих</h5>
                    <p>Хэрэв танд энэхүү Нууцлалын Бодлогын талаар ямар нэг асуулт, санал хүсэлт байвал info@dataland.mn цахим шуудангаар бидэнтэй холбогдоно уу.</p>
                </div>
            </div>
        </ScrollView>
    )
}

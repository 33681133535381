import React, { useState } from "react";
import PerformanceGuide from "../../assets/imgs/PerformanceGuide.pdf";
import PlanGuide from "../../assets/imgs/PlanGuide.pdf";
import { Button } from "devextreme-react/button";
import { Popup } from "devextreme-react/popup";
import Accordion from "devextreme-react/accordion";
import "./AssistDrawer.css";

function AssistDrawer() {
  const [firstPopup, setFirstPopup] = useState(false);
  const [secondPopup, setSecondPopup] = useState(false);

  const accordionData = [
      { id: 1, title: "ТӨСӨВ -> ТӨСӨЛ цэс", file: PlanGuide },
    // { id: 1, title: "Гарын авлага", file: pdfFile },
    // { id: 2, title: "Төсвийн төсөл гарын авлага", file: pdfFile2 },
    // { id: 3, title: "Төслийн нэгтгэл цэс гарын авлага", file: pdfFile3 },
    // { id: 4, title: "Төслийн хуваарь оруулах гарын авлага", file: pdfFile4 },
  ];

    const accordionData1 = [
        { id: 1, title: "ТӨСӨВ -> ГҮЙЦЭТГЭЛ цэс", file: PerformanceGuide },
        // { id: 1, title: "Гарын авлага", file: pdfFile },
        // { id: 2, title: "Төсвийн төсөл гарын авлага", file: pdfFile2 },
        // { id: 3, title: "Төслийн нэгтгэл цэс гарын авлага", file: pdfFile3 },
        // { id: 4, title: "Төслийн хуваарь оруулах гарын авлага", file: pdfFile4 },
    ];


    const showGuide = (e) => {
    setFirstPopup(!firstPopup);
  };

    const showGuide1 = (e) => {
        setSecondPopup(!secondPopup);
    };

  const renderItems = (e) => {
    return (
      <object width="100%" height="90vh" style={{height: "70vh"}} data={e.file} type="application/pdf" />
    );
  };

  const renderTitles = (e) => {
    return <span>{e.title}</span>;
  };

  const assistDrawer = () => {
    return (
      <div className="assist-button-container"  style={{ height: 150}}>
        <p
          style={{
            fontSize: 12,
            color: "#160B3D",
            paddingTop: 10,
            paddingLeft: 20,

          }}
        >
          Заавар, гарын авлага
        </p>
        <div style={{ paddingTop: 10, paddingLeft: 10, paddingRight: 10 }}>
          {/*<Button className='assist-button' icon='rename' />*/}
          {/*<Button className='assist-button' icon='download' />*/}
          <Button
            className="assist-button"
            icon="pdffile"
            text="ТӨСӨЛ ЦЭС"
            style={{ width: '100%'}}
            onClick={showGuide}
          />
            <Button
                className="assist-button"
                icon="pdffile"
                text="ГҮЙЦЭТГЭЛ ЦЭС"
                style={{ width: '100%'}}
                onClick={showGuide1}
            />
        </div>
      </div>
    );
  };

  return (
    <div className="assist-button-class">
      {/*<Drawer*/}
      {/*  className="assist-drawer"*/}
      {/*  opened={opened}*/}
      {/*  openedStateMode={"shrink"}*/}
      {/*  position={"right"}*/}
      {/*  revealMode={"slide"}*/}
      {/*  component={assistDrawer}*/}
      {/*  height={opened ? 250 : 50}*/}
      {/*  width={opened ? 250 : 50}*/}
      {/*>*/}
      {/*  <div*/}
      {/*    id="content"*/}
      {/*    onClick={() => setOpened(!opened)}*/}
      {/*    className="dx-theme-background-color"*/}
      {/*  >*/}
      {/*    <div className="assist-drawer-button">*/}
      {/*      <Button width={50} height={50} style ={{backgroundColor: '#CDEBFF'}} icon="decreaseindent" />*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</Drawer>*/}


      <div className="popup">
        <Popup
          visible={firstPopup}
          title="Заавар, гарын авлага"
          showTitle={true}
          height="90vh"
          hideOnOutsideClick={true}
          onHiding={() => {
            setFirstPopup(false);
          }}
        >
          <Accordion
            dataSource={accordionData}
            collapsible={false}
            multiple={false}
            itemTitleRender={renderTitles}
            itemRender={renderItems}
          />
        </Popup>
      </div>


        <div className="popup">
            <Popup
                visible={secondPopup}
                title="Заавар, гарын авлага"
                showTitle={true}
                hideOnOutsideClick={true}
                height="90vh"
                onHiding={() => {
                    setSecondPopup(false);
                }}
            >
                <Accordion
                    dataSource={accordionData1}
                    collapsible={false}
                    multiple={false}
                    itemTitleRender={renderTitles}
                    itemRender={renderItems}
                    
                />
            </Popup>
        </div>
    </div>
  );
}

export default AssistDrawer;
